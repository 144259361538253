import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { AccountCodesPage, AccountStructurePage } from './index';
class AccountAdminHomePage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/administration/expense/account-admin') {
            this.props.history.push('/administration/expense/account-admin/account-codes')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/administration/expense/account-admin') {
            this.props.history.push('/administration/expense/account-admin/account-codes')
        }
    }
    render() {
        return (
            <div className="AccountCodesHomePage">
                <Switch>
                    <Route path="/administration/expense/account-admin/account-codes" component={AccountCodesPage}></Route>
                    <Route path="/administration/expense/account-admin/account-structure" component={AccountStructurePage}></Route>
                </Switch>
            </div>
        )
    }
}
export default AccountAdminHomePage;