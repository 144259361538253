import React, { Component } from 'react';
import LoginExternalPage from './login-external-html';
import SimpleReactValidator from 'simple-react-validator';
import { inject } from 'mobx-react';


@inject('authStore')
@inject('commonStore')
class LoginExternal extends Component {
    constructor(props) {
        super(props)
        // localStorage.clear();
        this.validator = new SimpleReactValidator();
        this.state = {
            email: '',
            password: '',
            loading:false
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            const { email, password, companyCode } = this.state;
            if (email && password) {
                this.setState({loading:true})
                let resp = await this.props.authStore.login({ email: email, password: password, companyCode: companyCode })
                if(!resp){
                    this.setState({loading:false})
                }
            }
        } else {
            this.validator.showMessages();
            alert(this.validator.showMessages())
            // rerender to show messages for the first time
            // console.log(this.validator.showMessages())
            this.setState({...this.state})
        }
    }
    responseGoogle = (googleUser) => {
        var profile = googleUser.getBasicProfile();
        // console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
        // console.log('Name: ' + profile.getName());
        // console.log('Image URL: ' + profile.getImageUrl());
        // console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
        // console.log(googleUser);
    }

    render() {
        return (
            <LoginExternalPage
                state={this.state}
                error={this.validator}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                responseGoogle={this.responseGoogle}
                loading={this.state.loading}
            />
        )
    }
}
export default LoginExternal;
