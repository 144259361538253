// @flow

import React, {
  Component,
  useReducer,
  useEffect,
  useCallback,
  useState,
  useContext
} from "react";
import { NavLink } from "react-router-dom";
import { useObserver } from "mobx-react-lite";
import classNames from "classnames";
import { Modal, Button, ModalBody } from "reactstrap";
import  CabBooking  from "../cab/components/CabBooking";
import PropTypes from "prop-types";

/*::
export type ModifyBookingModalPropsType = {
  linkTo: ?string,
  className: ?string,
  children: any,
  open: ?boolean
}

export type ModifyBookingModalStateType = {
  isOpen: ?boolean,
  unmountOnClose: boolean
}
*/

class ModifyBookingModal extends Component /*:: <ModifyBookingModalPropsType, ModifyBookingModalStateType> */ {
  static propTypes = {
    link: PropTypes.string,
    class: PropTypes.string,
    children: PropTypes.any,
    open: PropTypes.bool
  };

  state = {
    isOpen: this.props.open,
    unmountOnClose: true
  };

  toggle = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen
    }));
  };

  render() {
    const { isOpen } = this.state;
    const { linkTo, className, children } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        // toggle={this.toggle}
        className={className}
        unmountOnClose
        returnFocusAfterClose
      >
        <ModalBody>
          <div className="booking">
            <div className="booking-list modal-list">
              <NavLink to={linkTo}>
                <img
                  width="80"
                  height="80"
                  src="/assets/images/booking/car_booking.svg"
                  alt="Cab"
                />
              </NavLink>
              {children}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export const CabBookingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModifyModal = useCallback((open /*: ?boolean */) => {
    setIsModalOpen(isOpened => (open != null ? open : isOpened));
  });

  return (
    <div>
  {localStorage.getItem("440a28") === "5dc5197e1382a2538aa3a8ca"?<CabBooking />:<CabBooking />}
  </div>
  )
};

export default CabBookingPage;
