import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import CompanyLocationPage from './company-location/company-location';
import AddUpdateLocationPage from './add-update-location/add-update-location';
import PageNotFound from '../../../../page-not-found/page-not-found-html';

class LocationHomePage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/administration/company/location') {
            this.props.history.push('/administration/company/location/list')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/administration/company/location') {
            this.props.history.push('/administration/company/location/list')
        }
    }
    render() {
        return (
            <div>
                <Switch>
                    <Route path='/administration/company/location/list' component={CompanyLocationPage} />
                    <Route exact={true} path='/administration/company/location/add' component={AddUpdateLocationPage} />
                    <Route path='/administration/company/location/update/:id' component={AddUpdateLocationPage} />
                    <Route path='**' component={PageNotFound} />
                </Switch>
            </div>
        )
    }
}
export default LocationHomePage;