import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import CryptrDetail from "cryptr";
import cryptr from "../../../_helpers/crypty";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";
import { expenseReport } from "../../../_services/expenseReport";
import AsyncSelect from "react-select/lib/Async";
import Loader from "src/_components/common/Loader";
import ExpenseTypes from "./components/expenseDetail/expenseType";
import ItemizeExpenseTypes from "./components/expenseDetail/itemizeExpenseTypes";
import ItemizeExpenseTable from "./components/expenseDetail/itemizeExpenseTable";
import AddNewAttendee from "./components/expenseDetail/addNewAttendee";
import ReportHeaderTable from "./components/expenseDetail/reportHeaderTable";
import ExpenseReportWarning from "./components/expenseDetail/expenseReportWarning";
import ExpenseLeftSidebarSummary from "./components/expenseDetail/expenseLeftSidebarSummary";
import CreditDepositSummary from "./components/expenseDetail/creditDepositSummary";
import ReportTopHeader from "./components/expenseDetail/reportTopHeader";
import ReportHeaderUploadReceipt from "./components/expenseDetail/reportHeaderUploadReceipt";
import NewAttendeeList from "./components/expenseDetail/newAttendeeList";
//import AttendeeDetails from "./components/expenseDetail/attendeeDetails";
import FavouriteAttendeeList from "./components/expenseDetail/favouriteAttendeeList";
import ImportAttendeeModel from "./components/expenseDetail/importAttendeeModel";
import ReceiptDeclarationAttach from "./components/expenseDetail/receiptDeclarationAttach";
import ItemisationStatics from "./components/expenseDetail/itemisationStatics";
//import UploadItemisationReceipt from "./components/expenseDetail/uploadItemisationReceipt";
import DetailDropdownItem from "./detailDropdownItem";
import AllocatedGraphModal from "./components/expenseDetail/allocatedGraphModal";
//import ExpenseAllocationTable from "./components/expenseAllocation/expenseAllocationTable";
import moment from "moment";
import { expenseServices } from "src/_services";
import { companyService } from "src/_services";
import { mergeFormAndFields, mergeExpenseEntryMultyData } from "src/utils/util";
import { getValue } from "src/_components/common/lodsh";
import Moment from 'react-moment';
import { setValue } from "src/_components/common/lodsh";
@inject("expenseStore")
@observer
class ExpenseApprovalDetails extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      companyId: localStorage["440a28"],
      expenseType: [],
      removeItemization: false,
      selectedItemizeExp: "",
      pageName: "Approval",
      headerReceipt: "",
      addFavouriteEmployee: "",
      itemizeExpenseTable: [],
      itemization: 0,
      selectedExenseType: "",
      itemExpenseType: [],
      itemSelectedExenseType: "",
      itemizationFields: "",
      formFields: [],
      error: [],
      expenseEntryList: [],
      reportHeader: [],
      policyDetail: [],
      expenseEntryDetails: [],
      totoalExpense: 0,
      itemizationForm: [],
      totalItemiseExpenseAmount: 0,
      slectedExpenseEntryAmount: 0,
      allReceipt: [],
      userId: "",
      selectedReceiptIds: [],
      selectedReceiptImages: [],
      browseImage: [],
      fileName: [],
      browseAllImages: "",
      headerName: [],
      allAdvanceRequest: [],
      selectedCashAdvanceReceipt: [],
      remainingCashAdvanceReceipt: [],
      s3ReceiptUrl: [],
      maxImageViewReceipt: "",
      maxImageViewReceiptId: "",
      getAllAssignedCashAdavnce: [],
      selectedAssigendCashAdvance: [],
      headerAssignedCashAdavnce: [],
      selctedAssignedCashAdvance: [],
      selectedCashAdvanceReceiptIds: [],
      selectedCashAdvAttachReceiptIds: [],
      auditRuleValidationsError: [],
      actionButtons: [],
      totalExpenseAmount: 0,
      affidavateStatus: "",
      userBaseCurrency: "",
      cashAdvanceTotalAmount: "",
      totalReportAmounts: [],
      totalRequestAmount: 0,
      submitStatus: "",
      validationMessage: "",
      validationStatus: false,
      isExpenseLoading: false,
      isPageLoading: true,
      totalAmountWithOutConversion: "",
      isTotalLoading: true,
      expenseEntryCurrency: "",
      selectedItemisationDeleteEntry: "",
      isApproveClick: false,
      isFromWorkFlow: true,
      isMileageExpense: false,
      history: "",
      userIdRequestor: "",
      requestedUserInfo: [],
      selectedExpenseType: ""
    };
  }
  componentDidMount() {
    this.setState({ isPageLoading: true });
    sessionStorage.removeItem("from");
    localStorage.removeItem("expenseTypeName");
    localStorage.removeItem("itemSelectedExenseTypeName");
    localStorage.removeItem("remainigItemise");
    localStorage.removeItem("currency");
    localStorage.removeItem("expenseEntryId");
    this.getAllCreatedExpenses();
    let expenseApprovalEntryId = this.props.match.params.id;
    let currentUser = JSON.parse(
      cryptr.decrypt(localStorage.getItem("440a24"))
    );
    this.setState({
      userId: currentUser["id"],
      expenseApprovalEntryId: expenseApprovalEntryId
    });
  }

  getAllCreatedExpenses = async () => {
    this.setState({ isPageLoading: true });
    let response = await expenseReport.getApprovalForExpenseEntryInAppr(
      this.props.match.params.id
    );
    if (response) {
      if (response.result.EXPENSE_DATA.expenseReportEntryObj && response.result.EXPENSE_DATA.expenseReportEntryObj.length !== 0) {
        let array = response.result.EXPENSE_DATA.expenseReportEntryObj
        for (let i = 0; i < array.length; i++) {
          response.result.EXPENSE_DATA.expenseReportEntryObj[i].entryForm.forEach((data) => {
            if (data.fieldName === "History" && data.fieldValue) {
              if (data.fieldRolePermissions.length !== 0) {
                if ((data.fieldRolePermissions[1].permissionId !== "0")) {
                  setValue(response.result.EXPENSE_DATA.expenseReportEntryObj[i], "history", "true")
                }
              } else {
                setValue(response.result.EXPENSE_DATA.expenseReportEntryObj[i], "history", "true")
              }
            }
          })
        }

      } else {
        console.log("error")
      }
    } else {
      console.log("there is no proper data")
    }

    if (
      response &&
      response.result &&
      response.result.EXPENSE_DATA &&
      response.result.EXPENSE_DATA.headerObj
    ) {
      let approvalList = [];
      for (const key in response.result.info && response.result.info.steps) {
        approvalList.push(response.result.info.steps[key]);
      }

      let expenseEntryList = mergeExpenseEntryMultyData(
        response.result.EXPENSE_DATA.expenseReportEntryObj
      );
      let currentWorkFlowOrderNo =
        response.result.current && response.result.current.currentOrderNo;
      let workFlowStepsStatus =
        response.result.info && response.result.info.steps;
      let submitStatus = response.result.current.STATUS
        ? response.result.current.STATUS
        : workFlowStepsStatus &&
          workFlowStepsStatus[currentWorkFlowOrderNo].APPROVED
          ? "Approved"
          : workFlowStepsStatus &&
          workFlowStepsStatus[currentWorkFlowOrderNo].intialStatus;
      this.setState({
        reportId: response.result.EXPENSE_DATA.headerObj.reportId,
        requestId: response.result.EXPENSE_DATA.headerObj.requestId,

        userIdRequestor: response.result.userId,
        reportHeader: mergeFormAndFields(
          response.result.EXPENSE_DATA.headerObj.headerForm
        ),
        expenseEntryList: expenseEntryList,
        policyDetail: response.result.EXPENSE_DATA.headerObj.policyObj ? response.result.EXPENSE_DATA.headerObj.policyObj : response.result.EXPENSE_DATA.headerObj.policyId,
        totoalExpense:
          response.result.EXPENSE_DATA.expenseReportEntryObj.length,
        totalExpenseAmount: response.result.EXPENSE_DATA.totalAmount,
        userBaseCurrency: response.result.EXPENSE_DATA.baseCurrency,
        cashAdvanceTotalAmount:
          response.result.EXPENSE_DATA.cashAdvanceTotalAmount,
        // totalRequestAmount:
        //   response.result.EXPENSE_DATA.headerObj.amountDueEmployee,
        totalRequestAmount: response.result.EXPENSE_DATA.requestAmount,
        submitStatus: submitStatus,
        actionButtons: response.result.current.actionButton,
        policyId: response.result.EXPENSE_DATA.headerObj.policyObj
          ? response.result.EXPENSE_DATA.headerObj.policyObj._id : response.result.EXPENSE_DATA.headerObj.policyId.id,
        headerId: response.result.EXPENSE_DATA.headerObj.id,
        approvalList: approvalList,
        navigationPaths: response.result.EXPENSE_DATA.navigationPaths
      });
      this.setState({ isPageLoading: false });
      let validationStatus = true;
      let auditRuleValidationsError = [];
      response.expenseReportEntryObj &&
        response.expenseReportEntryObj.forEach(list => {
          validationStatus = !list.errorMessages.hardError && validationStatus;
          //auditRuleValidationsError.concat(list.errorMessages.errorMessages);
          auditRuleValidationsError = [
            ...list.errorMessages.errorMessages,
            ...auditRuleValidationsError
          ];
        });
      if (response.result.EXPENSE_DATA.expenseReportEntryObj.length > 0) {
        localStorage.setItem(
          "expenseEntryId",
          response.result.EXPENSE_DATA.expenseReportEntryObj[0].id
        );
        this.setState({
          selectExpenstEntryList:
            response.result.EXPENSE_DATA.expenseReportEntryObj[0].id
        });
        this.onSelectExpenstEntryList(
          response.result.EXPENSE_DATA.expenseReportEntryObj[0].id
        );
        this.setState({
          isMileageExpense: response.result.EXPENSE_DATA
            .expenseReportEntryObj[0].navigationPaths.length
            ? true
            : false,
          selectExpenstEntryList:
            response.result.EXPENSE_DATA.expenseReportEntryObj[0].id
        });
      }
      this.setState({
        validationStatus: validationStatus,
        auditRuleValidationsError: auditRuleValidationsError
      });

      //this.getAllexpenseTypes();
    } else {
      this.setState({ isPageLoading: false });
    }
  };
  getAllexpenseTypes = async () => {
    let policyId = this.props.policyDetail;
    let expenseTypeFilter = 0;
    let response = await expenseReport.getExpenseTypes(
      this.state.companyId,
      policyId,
      expenseTypeFilter
    );
    if (response && Object.keys(response.expenseTypesList).length > 0) {
      this.setState({
        expenseType: response.expenseTypesList
      });
    } else {
      this.setState({
        expenseType: []
      });
    }
  };
  redirect(e) {
    $(".close").click();
    $(".itemisationExpenseModal").close();
  }
  onClickCreateItemisationModal = async () => {
    let policyId = localStorage.getItem("policyId");
    let expenseTypeFilter = 1;
    let response = await expenseReport.getExpenseTypes(
      this.state.companyId,
      policyId,
      expenseTypeFilter
    );
    if (response && Object.keys(response.expenseTypesList).length > 0) {
      this.setState({
        itemExpenseType: response.expenseTypesList
      });
    } else {
      this.setState({
        itemExpenseType: []
      });
    }
  };
  onSearchExpenseType = async event => {
    let searchText = event.target.value;
    let policyId = localStorage.getItem("policyId");
    let expenseTypeFilter = 0;
    let response = await expenseReport.getSearchExpenseType(
      this.state.companyId,
      policyId,
      searchText,
      expenseTypeFilter
    );
    if (
      searchText !== "" &&
      Object.keys(response.expenseTypesList).length > 0
    ) {
      this.setState({ expenseType: response.expenseTypesList });
    } else if (searchText === "") {
      this.getAllexpenseTypes();
    } else {
      this.setState({ expenseType: [] });
    }
  };

  onRedirectHandler = event => {
    if (this.state.selectedExenseType) {
      $(".close").click();
      this.props.history.push("./types");
    }
  };
  //start create itemisation operations
  //this use for itemisation expensetype modal
  onItemSearchExpenseType = async event => {
    let searchText = event.target.value;
    let policyId = localStorage.getItem("policyId");
    let expenseTypeFilter = 1;
    let response = await expenseReport.getSearchExpenseType(
      this.state.companyId,
      policyId,
      searchText,
      expenseTypeFilter
    );
    if (
      searchText !== "" &&
      Object.keys(response.expenseTypesList).length > 0
    ) {
      this.setState({ itemExpenseType: response.expenseTypesList });
    } else if (searchText === "") {
      this.onClickCreateItemisationModal();
    } else {
      this.setState({ itemExpenseType: [] });
    }
  };

  onItemCancelHandler = () => {
    this.setState({ itemSelectedExenseType: "" });
  };
  onItemizeContinueHandler = event => {
    this.props.history.push("./create-itemisation");
  };
  //end create itemisation operations

  onSelectExpenstEntryList = expenseEntryId => {
    this.setState({
      isExpenseLoading: true,
      selectExpenstEntryList: expenseEntryId
    });
    if (expenseEntryId !== undefined && expenseEntryId !== "") {
      localStorage.setItem("expenseEntryId", expenseEntryId);
    }
    let expenseEntryList = JSON.parse(
      JSON.stringify(this.state.expenseEntryList)
    );
    let expenseDetail = expenseEntryList.find(
      expenses => expenses.id === expenseEntryId
    );
    let response = { ...expenseDetail };
    if (response) {
      this.setState({ isExpenseLoading: false });
      this.props.expenseStore.setEntryItemizationAvailable(response);
      let allReceipt = response.s3ReceiptUrl.filter(
        images => images.status === true
      );

      let totalItemisationAmount = 0;
      let index = 0;
      for (const key in response.itemizationForm) {
        totalItemisationAmount += response.itemizationForm[key][0]
          .itemiseAmountwithoutConversion
          ? response.itemizationForm[key][0].itemiseAmountwithoutConversion
          : 0;
      }

      for (const key in response.itemizationForm) {
        response.itemizationForm[key] = response.itemizationForm[key][index];
        response.itemizationForm[key].itemizationField = mergeFormAndFields(
          response.itemizationForm[key].itemizationField
        );
      }
      response.remainingAmount = response.amount - totalItemisationAmount;

      this.setState({
        itemization: response.expenseTypeObj.itemization,
        selectedExpenseType: response.expenseType,
        expenseEntryDetails: response.entryForm,
        itemizationForm: response.itemizationForm,
        s3ReceiptUrl: allReceipt,
        affidavateStatus: response.affidavateStatus,
        totalItemiseExpenseAmount: totalItemisationAmount,
        slectedExpenseEntryAmount: response.amount,
        validationMessage: response.validationArray,
        totalAmountWithOutConversion: totalItemisationAmount,
        expenseEntryCurrency: response.currency,
        navigationPaths: response.navigationPaths,
        isMileageExpense: response.navigationPaths.length ? true : false
      });

      //response.currency=response.currency;
      const detailCryptr = new CryptrDetail("expense@Details");
      const remainigItemiseAmount =
        response.remainingAmount != null &&
        response.remainingAmount !== undefined &&
        detailCryptr.encrypt(response.remainingAmount);
      const currency =
        response.currency != null &&
        response.currency !== undefined &&
        detailCryptr.encrypt(response.currency);
      localStorage.setItem("remainigItemise", remainigItemiseAmount);
      localStorage.setItem("currency", currency);
      if (allReceipt.length > 0) {
        this.setState({
          maxImageViewReceipt: allReceipt[0].uploadReceipt,
          maxImageViewReceiptId: allReceipt[0].id
        });
      } else {
        this.setState({
          maxImageViewReceipt: "",
          maxImageViewReceiptId: ""
        });
      }
    }
  };

  onExpenseEditClickHandler = () => {
    sessionStorage.setItem("from", "approval");
    localStorage.removeItem("from")
    localStorage.removeItem("formIdNew")
    localStorage.setItem("from", "approval")
    localStorage.setItem("policyId", this.state.policyId);
    localStorage.setItem("headerId", this.state.headerId);
    localStorage.setItem("formIdNew", this.props.match.params.id)
    if (!this.state.isMileageExpense) {
      this.props.history.push(
        `../../edit-expense-entry/${this.props.match.params.id}`
      );
    } else {
      this.props.history.push(
        `../../add-mileage/${this.props.match.params.id}`
      );
    }
  };
  onClickEditReportHeader = () => {
    let headerId = localStorage.getItem("headerId");
    this.props.history.push(`../../edit-report-header/${headerId}`);
  };

  onDeleteExpenseEntry = async () => {
    let selectExpenstEntryList = this.props.expenseStore.selectExpenstEntryList;
    if (selectExpenstEntryList) {
      let response = await expenseReport.deleteExpenseEntryForm(
        selectExpenstEntryList
      );
      if (response) {
        $(".close").click();
        this.getAllCreatedExpenses();
        localStorage.removeItem("expenseEntryId");
        this.props.expenseStore.setSelectExpenstEntryList("");
        toast.error(response.message);
      }
    }
  };
  selectItemizeExpense = imtemizeExpense => {
    if (imtemizeExpense === this.state.selectedItemizeExp) {
      this.setState({ selectedItemizeExp: "", removeItemization: false });
    } else {
      this.setState({
        selectedItemizeExp: imtemizeExpense,
        removeItemization: true
      });
    }
  };

  onBrowseHeaderReceipt = event => {
    let fileName = event.target.files[0];
    let browseImage = URL.createObjectURL(event.target.files[0]);
    this.setState({ headerReceipt: fileName, browseImage: browseImage });
  };
  uploadHeaderReceipt = event => {
    event.preventDefault();
    let fileName = this.state.headerReceipt;
    const formData = new FormData();
    formData.append("file", fileName);
  };

  onFavoriteHandler = favouriteEmployeeName => {
    if (favouriteEmployeeName === this.state.addFavouriteEmployee) {
      this.setState({ addFavouriteEmployee: "" });
    } else {
      this.setState({ addFavouriteEmployee: favouriteEmployeeName });
    }
  };
  onAddExpenseHandler = expenseType => {
    this.props.expenseStore.setExpenseType(expenseType);
  };

  onSelectExpenseType = (epxpenseType, id) => {
    if (this.state.selectedExenseType === id) {
      localStorage.removeItem("expenseType");
      localStorage.removeItem("expenseTypeName");
      this.setState({ selectedExenseType: "" });
    } else {
      localStorage.setItem("expenseType", id);
      this.setState({ selectedExenseType: id });
      localStorage.setItem("expenseTypeName", epxpenseType);
    }
  };

  onItemSelectExpenseType = async (epxpenseType, id) => {
    if (this.state.itemSelectedExenseType === id) {
      localStorage.removeItem("itemSelectedExenseType");
      localStorage.removeItem("itemSelectedExenseTypeName");
      this.setState({ itemSelectedExenseType: "" });
    } else {
      localStorage.setItem("itemSelectedExenseType", id);
      localStorage.setItem("itemSelectedExenseTypeName", epxpenseType);
      this.setState({ itemSelectedExenseType: id });
    }
  };

  onItemisationEditClickHandler = (expenseTypeId, itemization_id) => {
    let expenseApprovalEntryId = this.props.match.params.id;
    localStorage.setItem("imtmExpTypeId", expenseTypeId);
    localStorage.setItem("itemization_id", itemization_id);
    this.props.history.push(`../../edit-itemisation/${expenseApprovalEntryId}`);
  };

  onItemisationSelectedDeleteClickHandler = (expenseTypeId, itemization_id) => {
    this.setState({ selectedItemisationDeleteEntry: itemization_id });
  };
  onItemisationDeleteCancelHandler = () => {
    this.setState({ selectedItemisationDeleteEntry: "" });
  };
  onItemisationDeleteClickHandler = async () => {
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let response = await expenseReport.deleteItemizationExpenseType(
      expenseEntryId,
      this.state.selectedItemisationDeleteEntry
    );
    if (response) {
      $(".close").click();
      let headerId = localStorage.getItem("headerId");
      let expenseValidation = await expenseReport.getAllCreatedExpense(
        this.state.companyId,
        headerId
      );
      let validationStatus = true;
      expenseValidation.expenseReportEntryObj &&
        expenseValidation.expenseReportEntryObj.forEach((list, index) => {
          validationStatus = !list.validation && validationStatus;
        });
      this.setState({
        validationStatus: validationStatus,
        expenseEntryList: expenseValidation.expenseReportEntryObj
      });
      let response = await expenseReport.getExpenseEntryDetails(expenseEntryId);
      if (response) {
        this.setState({
          totalItemiseExpenseAmount: response.itemizedTotalAmount,
          validationMessage: response.validationArray,
          slectedExpenseEntryAmount: response.totalAmount
        });
      }
      this.onItemisaionCheckHandler();
      toast.error(response.message);
    }
  };

  onItemisaionCheckHandler = async () => {
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let response = await expenseReport.getExpenseEntryDetails(expenseEntryId);
    if (response) {
      this.props.expenseStore.setEntryItemizationAvailable(response);
      this.setState({
        itemizationForm: response.itemizationForm
      });
    }
  };

  onClickUploadReceiptStoreHandler = async () => {
    this.setState({ selectedReceiptIds: [], selectedReceiptImages: [] });
    let userId = this.state.userId;
    let companyId = this.state.companyId;
    let response = await expenseReport.fetchAllReportReceipt(userId, companyId);
    if (response) {
      this.setState({ allReceipt: response.result });
    }
  };

  onSelectReceiptHandler = (selectedReceiptId, selectedReceiptImage) => {
    $("#preview-img").attr("src", selectedReceiptImage);
    let selectedReceiptIds = this.state.selectedReceiptIds;
    let selectedReceiptImages = this.state.selectedReceiptImages;
    if ($.inArray(selectedReceiptId, selectedReceiptIds) !== -1) {
      selectedReceiptIds = selectedReceiptIds.filter(
        id => id !== selectedReceiptId
      );
      selectedReceiptImages = selectedReceiptImages.filter(
        id => id !== selectedReceiptImage
      );
    } else {
      selectedReceiptIds.push(selectedReceiptId);
      selectedReceiptImages.push(selectedReceiptImage);
    }
    this.setState({
      selectedReceiptImages: selectedReceiptImages,
      selectedReceiptIds: selectedReceiptIds
    });
  };
  onSubmitReceiptHandler = async () => {
    let selectedReceiptIds = this.state.selectedReceiptIds;
    let selectedReceiptImages = this.state.selectedReceiptImages;
    let allReceiptsArray = [];
    selectedReceiptIds.forEach((receipt, index) => {
      allReceiptsArray.push({
        id: selectedReceiptIds[index],
        uploadReceipt: selectedReceiptImages[index],
        status: true
      });
    });
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let companyId = this.state.companyId;

    let response = await expenseReport.uploadReceiptReport(
      allReceiptsArray,
      expenseEntryId,
      companyId
    );
    if (response) {
      let allReceipt = response.result.filter(images => images.status === true);
      toast.success(response.message);
      this.setState({ s3ReceiptUrl: allReceipt });
    }
  };

  onFileBrowse = event => {
    const browseFiles = Array.from(event.target.files);
    this.setState({ browseAllImages: browseFiles });
    let allImages = [];
    let allBrowseImage = [];
    for (const key in browseFiles) {
      let imageType = event.target.files[key].type;
      const types = ["image/png", "image/jpeg"];
      if (types.includes(imageType)) {
        let fileName = browseFiles[key].name;
        if (fileName && fileName !== "item") {
          let browseImage = URL.createObjectURL(event.target.files[key]);
          allImages.push(fileName);
          allBrowseImage.push(browseImage);
        }
      } else {
        toast.error(
          `You can not uplaod ${imageType.split("/")[1]} file format`
        );
      }
    }
    this.setState({ fileName: allImages, browseImage: allBrowseImage });
  };

  onFileUpload = async () => {
    $(".close").click();
    let formData = new FormData();
    let files = this.state.browseAllImages;
    files.forEach((file, i) => {
      formData.append("file", file);
    });
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let companyId = this.state.companyId;
    let userId = this.state.userId;
    let response = await expenseReport.uploadReceiptReportComputer(
      formData,
      expenseEntryId,
      companyId,
      userId
    );
    if (response) {
      toast.success(response.message);
      let allReceipt = response.result.filter(images => images.status === true);
      if (allReceipt.length > 0) {
        this.setState({
          maxImageViewReceipt: allReceipt[0].uploadReceipt,
          maxImageViewReceiptId: allReceipt[0].id,
          s3ReceiptUrl: allReceipt
        });
      }
      this.setState({ selectedReceiptIds: [], selectedReceiptImages: [] });
    }
  };

  getCashAdvanceListHandler = async () => {
    this.setState({
      selectedCashAdvanceReceipt: [],
      selectedCashAdvanceReceiptIds: []
    });
    let companyId = this.state.companyId;
    let pageLimit = 100;
    let pageNumber = 1;
    let search = "";
    let response = await expenseReport.getAllCashAdvanceCreatedList(
      companyId,
      pageNumber,
      pageLimit,
      search
    );
    if (response && response.count && response.count > 0) {
      this.setState({
        allAdvanceRequest: response.result.data,
        headerName: response.result.data[0].data,
        remainingCashAdvanceReceipt: response.result.data
      });
    } else {
      this.setState({
        allAdvanceRequest: [],
        headerName: [],
        remainingCashAdvanceReceipt: []
      });
    }
  };

  onCashAdvanceSelectHadnler = cashadvance => {
    let selectedCashAdvanceReceipt = this.state.selectedCashAdvanceReceipt;
    let selectedCashAdvanceReceiptIds = this.state
      .selectedCashAdvanceReceiptIds;
    if ($.inArray(cashadvance, selectedCashAdvanceReceipt) !== -1) {
      selectedCashAdvanceReceipt = selectedCashAdvanceReceipt.filter(
        id => id !== cashadvance
      );
      selectedCashAdvanceReceiptIds = selectedCashAdvanceReceiptIds.filter(
        id => id !== cashadvance.id
      );
    } else {
      cashadvance.assigned = "true";
      selectedCashAdvanceReceipt.push(cashadvance);
      selectedCashAdvanceReceiptIds.push(cashadvance.id);
    }
    this.setState({
      selectedCashAdvanceReceipt: selectedCashAdvanceReceipt,
      selectedCashAdvanceReceiptIds: selectedCashAdvanceReceiptIds
    });
  };
  onCashAdvanceAttachHandler = async () => {
    let selectedCashAdvanceReceipt = this.state.selectedCashAdvanceReceipt;
    let companyId = this.state.companyId;
    let headerId = localStorage.getItem("headerId");
    let data = {
      data: selectedCashAdvanceReceipt,
      headerId: headerId,
      companyId: companyId
    };
    let response = await expenseReport.attachCashAdvanceReceipt(data);
    if (response) {
      this.getAllCreatedExpenses();
      this.getCashAdvanceListHandler();
      this.setState({
        selectedCashAdvanceReceipt: [],
        selectedCashAdvanceReceiptIds: []
      });
      toast.success(response.message);
    }
  };
  getAllAttachedCashAdvance = async () => {
    this.setState({
      selctedAssignedCashAdvance: [],
      selectedCashAdvAttachReceiptIds: []
    });
    let companyId = this.state.companyId;
    let headerId = localStorage.getItem("headerId");
    let response = await expenseReport.getCashAdvanceReceiptHeader(
      companyId,
      headerId
    );
    if (response && response.count && response.count > 0) {
      this.setState({
        getAllAssignedCashAdavnce: response.result.data,
        headerAssignedCashAdavnce: response.result.data[0].data
      });
    } else {
      this.setState({
        getAllAssignedCashAdavnce: [],
        headerAssignedCashAdavnce: []
      });
    }
  };
  onLargeImageShowHandler = (image, id) => {
    this.setState({ maxImageViewReceipt: image, maxImageViewReceiptId: id });
  };
  onDeleteReceiptHandler = async () => {
    let maxImageViewReceipt = this.state.maxImageViewReceipt;
    let maxImageViewReceiptId = this.state.maxImageViewReceiptId;
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let companyId = this.state.companyId;
    let data = {
      id: maxImageViewReceiptId,
      uploadReceipt: maxImageViewReceipt
    };
    let response = await expenseReport.deleteExpenseReceipt(
      data,
      expenseEntryId,
      companyId
    );
    if (response) {
      let allReceipt = response.result.filter(
        images => images.status !== false
      );
      if (allReceipt.length > 0) {
        this.setState({
          maxImageViewReceipt: allReceipt[0].uploadReceipt,
          maxImageViewReceiptId: allReceipt[0].id,
          s3ReceiptUrl: allReceipt
        });
      } else {
        this.setState({
          maxImageViewReceipt: "",
          maxImageViewReceiptId: "",
          s3ReceiptUrl: []
        });
      }
      this.setState({ selctedAssignedCashAdvance: [] });
      toast.error(response.message);
    }
  };
  onSelectAttchedCashAdvHadnler = cashadvance => {
    let selctedAssignedCashAdvance = this.state.selctedAssignedCashAdvance;
    let selectedCashAdvAttachReceiptIds = this.state
      .selectedCashAdvAttachReceiptIds;
    if ($.inArray(cashadvance, selctedAssignedCashAdvance) !== -1) {
      selctedAssignedCashAdvance = selctedAssignedCashAdvance.filter(
        id => id !== cashadvance
      );
      selectedCashAdvAttachReceiptIds = selectedCashAdvAttachReceiptIds.filter(
        id => id !== cashadvance.id
      );
    } else {
      cashadvance.assigned = "false";
      selctedAssignedCashAdvance.push(cashadvance);
      selectedCashAdvAttachReceiptIds.push(cashadvance.id);
    }
    this.setState({
      selctedAssignedCashAdvance: selctedAssignedCashAdvance,
      selectedCashAdvAttachReceiptIds: selectedCashAdvAttachReceiptIds
    });
  };
  onRemoveAttchedCashAdvHadnler = async () => {
    let selctedAssignedCashAdvance = this.state.selctedAssignedCashAdvance;
    let companyId = this.state.companyId;
    let headerId = localStorage.getItem("headerId");
    let data = {
      data: selctedAssignedCashAdvance,
      headerId: headerId,
      companyId: companyId
    };
    let response = await expenseReport.attachCashAdvanceReceipt(data);
    if (response) {
      this.getAllCreatedExpenses();
      this.setState({
        selctedAssignedCashAdvance: [],
        selectedCashAdvAttachReceiptIds: []
      });
      this.getAllAttachedCashAdvance();
      toast.success(response.message);
    }
  };
  onAttachDetachClickHandler = async () => {
    let companyId = this.state.companyId;
    let affidavateStatus = !this.state.affidavateStatus;
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let response = await expenseReport.attachDettachAffidavate(
      companyId,
      expenseEntryId,
      affidavateStatus
    );
    if (response) {
      toast.success(response.message);
      this.setState({ affidavateStatus: affidavateStatus });
    }
  };
  onClickTotalReportHandler = async () => {
    let headerId = this.state.headerId;
    let response = await expenseReport.getTotalReportExpenses(
      this.state.companyId,
      headerId
    );
    if (response) {
      this.setState({ totalReportAmounts: response, isTotalLoading: false });
    } else {
      this.setState({ isTotalLoading: false });
    }
  };
  onClickTotalReportHandler1 = async () => {
    let userId = this.state.userIdRequestor
    if (userId) {
      var res = await companyService.getReuestedUserInfo(userId, this.state.companyId);
      if (res) {
        this.setState({ requestedUserInfo: res, isTotalLoading: false });
      } else {
        this.setState({ isTotalLoading: false });
      }
    }
  };
  onExpenseReportSubmitHandler = async () => {
    let validationStatus = this.state.validationStatus;
    if (validationStatus) {
      let companyId = this.state.companyId;
      let headerId = localStorage.getItem("headerId");
      let reportArray = this.state.reportHeader.map(res => res.columnName);
      let reportNameIndex = reportArray.indexOf("REPORT_NAME");
      let reportName = "";
      if (reportNameIndex >= 0) {
        reportName = this.state.reportHeader[reportNameIndex].fieldValue;
      } else {
        reportName = "";
      }
      let response = await expenseReport.submitExpenseReportData(
        companyId,
        headerId,
        reportName
      );
      if (response) {
        if (response) {
          localStorage.removeItem("itemSelectedExenseType");
          localStorage.removeItem("expenseEntryId");
          localStorage.removeItem("headerId");
          localStorage.removeItem("imtmExpTypeId");
          localStorage.removeItem("itemization_id");
          localStorage.removeItem("policyId");
          localStorage.removeItem("expenseType");
          toast.success(response.message);
          //   this.props.history.push("./home");
        } else {
          toast.error(response.message);
        }
      }
    } else {
      toast.error("Please remove all the warnings");
    }
  };
  showExpenseTab() {
    document.getElementById("expense-left").classList.toggle("show");
  }
  onExpenseApprovalSelectHandler = (key, action) => {
    this.setState({
      selectedAction: key,
      actionSelectedName: action,
      rejectionReason: ""
    });
  };
  //get cash advanceData on basis of action
  getCashAdvanceActionData = () => {
    let action = this.state.selectedAction;
    let expenseApprovalData;
    let rejectionReason = this.state.rejectionReason;
    if (action === "APPROVE") {
      expenseApprovalData = {
        data: {
          APPROVE: {
            comment: rejectionReason ? rejectionReason : ""
          }
        }
      };
    } else if (action === "SEND_BACK_EMPLOYEE") {
      expenseApprovalData = {
        data: {
          SEND_BACK_EMPLOYEE: {
            comment: rejectionReason ? rejectionReason : ""
          }
        }
      };
    } else if (action === "REJECT") {
      expenseApprovalData = {
        data: {
          REJECT: {
            comment: rejectionReason ? rejectionReason : ""
          }
        }
      };
    } else {
      expenseApprovalData = {
        data: {
          ADD_NEW_STEP: {
            userId: this.state.selectedApproval.value
          }
        }
      };
    }
    return expenseApprovalData;
  };
  //cash Advance dynamic button click handlig ACTION handler
  onCashAdvanceSubmitActionHandler = async () => {
    this.setState({ isApproveClick: true });
    let action = this.state.selectedAction;
    let expenseHeaderId = this.props.match.params.id;
    let expenseApprovalData = this.getCashAdvanceActionData();
    let response;
    if (action !== "APPROVE") {
      if (this.state.rejectionReason.length > 5) {
        response = await expenseReport.approveExpenseReport(
          expenseApprovalData,
          expenseHeaderId
        );
        if (response) {
          $(".close").click();
          this.props.history.push("../../../trips-approve/expense-reports");
          this.setState({
            rejectionReason: "",
            expenseHeaderId: "",
            selectedApproval: "",
            isApproveClick: false
          });
          toast.success(response.message);
        }
      } else if (action === "ADD_NEW_STEP") {
        response = await expenseReport.approveExpenseReport(
          expenseApprovalData,
          expenseHeaderId
        );
        if (response) {
          $(".close").click();
          this.getAllCreatedExpenses();
          this.setState({
            rejectionReason: "",
            expenseHeaderId: "",
            selectedApproval: "",
            isApproveClick: false
          });
          toast.success(response.message);
        }
      } else {
        this.setState({ formValidaionStatus: false });
      }
    } else {
      response = await expenseReport.approveExpenseReport(
        expenseApprovalData,
        expenseHeaderId
      );
      if (response) {
        $(".close").click();
        this.setState({
          rejectionReason: "",
          expenseHeaderId: "",
          selectedApproval: "",
          isApproveClick: false
        });
        toast.success(response.message);
        this.getAllCreatedExpenses();
      } else {
        this.setState({
          rejectionReason: "",
          expenseHeaderId: "",
          selectedApproval: "",
          isApproveClick: false
        });
      }
    }
  };
  onApprovalSelectHandler = event => {
    this.setState({ selectedApproval: event });
  };
  onReasonChangeHandler = event => {
    let message = event.target.value;
    this.setState({
      rejectionReason: event.target.value,
      formValidaionStatus: message.length > 1
    });
  };
  onApprovalSearchHandler = async newValue => {
    return expenseReport
      .getApprovalUserList(this.state.companyId, newValue)
      .then(data => {
        return data.result.map(list => {
          let firstNameIndex = list.fieldData.findIndex(
            fields => fields.columnName === "EMPLOYEE_FIRST_NAME"
          );
          let middleNameIndex = list.fieldData.findIndex(
            fields => fields.columnName === "MIDDLE_NAME"
          );
          let lastNameIndex = list.fieldData.findIndex(
            fields => fields.columnName === "EMPLOYEE_LAST_NAME"
          );
          let fullName =
            list.fieldData[firstNameIndex].fieldValue +
            " " +
            list.fieldData[middleNameIndex].fieldValue +
            " " +
            list.fieldData[lastNameIndex].fieldValue;
          return {
            label: list.email + "," + fullName,
            value: list.id
          };
        });
      });
  };

  handleAllocationDetails = async (expenseId, headerId) => {
    this.setState({ isGraphDataLoading: true });
    let companyId = this.state.companyId;
    // let headerId = localStorage.getItem("headerId");
    let response = await expenseServices.getAllocationDetails(
      companyId,
      headerId,
      expenseId
    );
    if (response && response.result) {
      this.setState({
        allocationDetails: response.result,
        isGraphDataLoading: false
      });
    } else {
      this.setState({ isGraphDataLoading: false });
    }
  };

  render() {
    return (
      <>
        <div>
          <div id="expense-left" className="expense-left">
            <DetailDropdownItem
              totoalExpense={this.state.totoalExpense}
              submitStatus={this.state.submitStatus}
              onClickTotalReportHandler={this.onClickTotalReportHandler}
              onClickTotalReportHandler1={this.onClickTotalReportHandler1}
              getAllAttachedCashAdvance={this.getAllAttachedCashAdvance}
              getCashAdvanceListHandler={this.getCashAdvanceListHandler}
            />
            {/**Listing of all the created expense types*/}
            <ExpenseLeftSidebarSummary
              selectExpenstEntryList={this.state.selectExpenstEntryList}
              expenseEntryList={this.state.expenseEntryList}
              onSelectExpenstEntryList={this.onSelectExpenstEntryList}
              handleAllocationDetails={this.handleAllocationDetails}
              history={this.state.history}
            />
            <CreditDepositSummary
              cashAdvanceTotalAmount={this.state.cashAdvanceTotalAmount}
              userBaseCurrency={this.state.userBaseCurrency}
              totalExpenseAmount={this.state.totalExpenseAmount}
              totalRequestAmount={this.state.totalRequestAmount}
              submitStatus={this.state.submitStatus}
            />
          </div>

          <div className="expense-right">
            <div className="head">
              {/**Top header Details inside this component */}
              {this.state.isApproveClick || this.state.isPageLoading ? (
                <Loader />
              ) : (
                  <ReportTopHeader
                    reportId={this.state.reportId}
                    requestId={this.state.requestId}
                    selectedExpenseType={this.state.selectedExpenseType}
                    actionButtons={this.state.actionButtons}
                    currentPageName={this.state.pageName}
                    submitStatus={this.state.submitStatus}
                    onDeleteExpenseEntry={this.onDeleteExpenseEntry}
                    onClickEditReportHeader={this.onClickEditReportHeader}
                    expenseHeader={this.state.reportHeader}
                    policyDetail={this.state.policyDetail}
                    onExpenseReportSubmitHandler={
                      this.onExpenseReportSubmitHandler
                    }
                    validationStatus={this.state.validationStatus}
                    totoalExpense={this.state.totoalExpense}
                    onExpenseApprovalSelectHandler={
                      this.onExpenseApprovalSelectHandler
                    }
                  />
                )}
            </div>
            {/**Entry form and itemization forms. If he selected any expense type then show the itemisation and entry form */}
            {this.state.expenseEntryList.length <= 0 &&
              this.state.isPageLoading === false && (
                <div className="text-center mt-5">
                  <img
                    width="400"
                    className="img-fluid mb-4"
                    alt="Fetching Reports"
                    src="/assets/images/no_expense.svg"
                  />
                  <h4>
                    <b>Your report is empty!</b>
                  </h4>
                  <p className="mb-3">Lets get started.</p>
                  <button
                    type="button"
                    className="btn"
                    data-toggle="modal"
                    disabled={this.state.submitStatus !== "Pending Submission"}
                    data-target="#itemisationExpenseAddModal"
                  >
                    Add Expense
                  </button>
                </div>
              )}

            {this.state.expenseEntryList.length >= 1 &&
              (localStorage.getItem("expenseEntryId") === null ||
                localStorage.getItem("expenseEntryId") === undefined) && (
                <>
                  <div className="p-padding">
                    <div id="expense-report">
                      <div className="row">
                        {this.state.auditRuleValidationsError &&
                          this.state.auditRuleValidationsError && (
                            <ExpenseReportWarning
                              auditRuleValidationsError={
                                this.state.auditRuleValidationsError
                              }
                              validationMessage={this.state.validationMessage}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <img
                      width="400"
                      className="img-fluid mb-4"
                      alt="Fetching Reports"
                      src="/assets/images/no_expense.svg"
                    />
                    <p>Lets get started.</p>
                    <h4 className="mt-2">
                      <b>Select an Expense</b>
                    </h4>
                  </div>
                </>
              )}
            {!this.state.isPageLoading &&
              localStorage.getItem("expenseEntryId") !== undefined &&
              localStorage.getItem("expenseEntryId") !== "" &&
              localStorage.getItem("expenseEntryId") !== null ? (
                <div className="p-padding">
                  <div id="expense-report">
                    <div className="row">
                      {(this.state.validationMessage ||
                        this.state.auditRuleValidationsError) &&
                        (this.state.validationMessage ||
                          this.state.auditRuleValidationsError) && (
                          <ExpenseReportWarning
                            auditRuleValidationsError={
                              this.state.auditRuleValidationsError
                            }
                            validationMessage={this.state.validationMessage}
                          />
                        )}
                    </div>
                    {!this.state.isExpenseLoading &&
                      localStorage.getItem("expenseEntryId") !== undefined &&
                      localStorage.getItem("expenseEntryId") !== "" ? (
                        <>
                          <div className="white-card report">
                            <div className="grey-head border-bottom">
                              <h6 className="sub-heading float-left place__middle">
                                EXPENSE ENTRY
                          </h6>
                              <div className="btn-group-margin float-right">
                                {/* {this.state.submitStatus === "Pending Submission" &&
                              <button
                                disabled={
                                  !this.props.expenseStore.selectExpenstEntryList
                                }

                                className="btn"
                                data-toggle="modal"
                                data-target="#deleteEntry"
                              >
                                {" "}
                                Remove Expense
                          </button>
                            } */}
                                {this.state.actionButtons.length > 0 && (
                                  <button
                                    onClick={this.onExpenseEditClickHandler}
                                    type="button"
                                    className="btn"
                                  >
                                    EDIT
                                  </button>
                                )}
                              </div>
                              <div className="clearfix" />
                            </div>
                            <div className="row">
                              <ReportHeaderTable
                                expenseEntryCurrency={
                                  this.state.expenseEntryCurrency
                                }
                                expenseEntryDetails={this.state.expenseEntryDetails}
                                navigationPaths={this.state.navigationPaths}
                              />

                              <ReportHeaderUploadReceipt
                                submitStatus="Approval"
                                allocationStatus={this.state.affidavateStatus}
                                s3ReceiptUrl={this.state.s3ReceiptUrl}
                                onFileUpload={this.onFileUpload}
                                onFileBrowse={this.onFileBrowse}
                                browseImage={this.state.browseImage}
                                allReceipt={this.state.allReceipt}
                                selectedReceiptId={this.state.selectedReceiptIds}
                                onClickUploadReceiptStoreHandler={
                                  this.onClickUploadReceiptStoreHandler
                                }
                                onSelectReceiptHandler={this.onSelectReceiptHandler}
                                onSubmitReceiptHandler={this.onSubmitReceiptHandler}
                                maxImageViewReceipt={this.state.maxImageViewReceipt}
                                onLargeImageShowHandler={
                                  this.onLargeImageShowHandler
                                }
                                onDeleteReceiptHandler={this.onDeleteReceiptHandler}
                                policyDetail={this.state.policyDetail}
                                isFromWorkFlow={this.state.isFromWorkFlow}
                              />
                            </div>
                          </div>
                          {(parseInt(this.state.itemization) === 0 ||
                            parseInt(this.state.itemization) === 1) &&
                            this.state.itemizationForm &&
                            Object.keys(this.state.itemizationForm).length > 0 ? (
                              <div className="white-card animation-target-left">
                                <div className="card switch-panel mb-0 p-0">
                                  <label
                                    className="p-4"
                                    data-toggle="collapse"
                                    data-target="#demo"
                                  >
                                    <div className="float-left">
                                      <div className="form-group mb-0">
                                        <label className="switch mr-2">
                                          <input
                                            type="checkbox"
                                          // onChange={this.onItemisaionCheckHandler}
                                          />
                                          <span className="slider round" />
                                        </label>
                                  ITEMISATION
                                </div>
                                    </div>
                                    <div className="float-right">
                                      <i className="material-icons">
                                        arrow_drop_down
                                </i>
                                    </div>
                                  </label>
                                </div>
                                {/* <div id="demo" className={'collapse fade-in' + this.state.itemization === 0 ||
                            this.state.itemization === 1? 'show___' : ''}></div> */}
                                <div id="demo" className="collapse">
                                  <div className="white-card report mb-0">
                                    {/**Statics of itemization */}
                                    <ItemisationStatics
                                      currencyEntry={this.state.expenseEntryCurrency}
                                      totalAmountWithOutConversion={
                                        this.state.totalAmountWithOutConversion
                                      }
                                      baseCurrency={this.state.userBaseCurrency}
                                      totalItemiseExpenseAmount={
                                        this.state.totalItemiseExpenseAmount
                                      }
                                      slectedExpenseEntryAmount={
                                        this.state.slectedExpenseEntryAmount
                                      }
                                    />
                                  </div>
                                  <div className="white-card report">
                                    <div className="row">
                                      {/**List of all created itemization */}
                                      <ItemizeExpenseTable
                                        actionButtons={this.state.actionButtons}
                                        currentPageName={this.state.pageName}
                                        submitStatus={this.state.submitStatus}
                                        expenseEntryCurrency={
                                          this.state.expenseEntryCurrency
                                        }
                                        onItemisationEditClickHandler={
                                          this.onItemisationEditClickHandler
                                        }
                                        onItemisationSelectedDeleteClickHandler={
                                          this.onItemisationSelectedDeleteClickHandler
                                        }
                                        itemizationForm={this.state.itemizationForm}
                                      />
                                      {/* <UploadItemisationReceipt /> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                        </>
                      ) : (
                        <div className="form-holder">
                          <div className="text-center">
                            <div className="loader-css" />
                            <h5 className="fw-sbold pb-5">
                              Please wait while expense entry is loading...
                        </h5>
                          </div>
                        </div>
                      )}

                    {/*  <AttendeeDetails /> */}
                    <div className="add-margin-bottom">
                      {/* <div className="card switch-panel mb-0">
                    <label data-toggle="collapse" data-target="#demo1">
                      <div className="float-left">
                        <div className="form-group mb-0">
                          <label className="switch mr-2">
                            <input type="checkbox" />
                            <span className="slider round" />
                          </label>
                          Allocation
                        </div>
                      </div>
                      <div className="float-right">
                        <i className="icon ion-md-arrow-dropdown ml-auto" />
                      </div>
                      <div className="clearfix" />
                    </label>
                  </div> */}

                      {/* <div id="demo1" className="collapse fade-in">
                    <div className="white-card report border border-top-0 mb-0">
                      <div className="px-2">
                        <div className="row">
                          <div className="col-md-7">
                            <div className="btn-group-margin">
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle allocate-btn"
                                  data-toggle="dropdown"
                                >
                                  Allocate By
                    </button>
                                <div className="dropdown-menu">
                                  <button className="dropdown-item w-100">
                                    Percentage
                      </button>
                                  <button className="dropdown-item w-100">Amount</button>
                                </div>
                              </div>
                              <button type="button" className="btn">
                                Add
                  </button>
                              <button type="button" className="btn">
                                Delete
                  </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <ExpenseAllocationTable />
                      </div>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              ) : null}
          </div>
          {/*Dynamic expense type modal of add expense*/}
          <ExpenseTypes
            onAddExpenseHandler={this.onAddExpenseHandler}
            expenseTypes={this.state.expenseType}
            onSearchExpenseType={this.onSearchExpenseType}
            onSelectExpenseType={this.onSelectExpenseType}
            onRedirectHandler={this.onRedirectHandler}
            selectedExenseType={this.state.selectedExenseType}
          />
          {/*Dynamic itemize expense type modal*/}
          <ItemizeExpenseTypes
            expenseTypes={this.state.itemExpenseType}
            onSearchExpenseType={this.onItemSearchExpenseType}
            onSelectExpenseType={this.onItemSelectExpenseType}
            selectedExenseType={this.state.itemSelectedExenseType}
            onItemCancelHandler={this.onItemCancelHandler}
            onItemizeContinueHandler={this.onItemizeContinueHandler}
          />
          {/** upload declaration affidavit modal */}
          <ReceiptDeclarationAttach
            onAttachDetachClickHandler={this.onAttachDetachClickHandler}
            policyDetail={this.state.policyDetail}
            isFromWorkFlow={this.state.isFromWorkFlow}
          />

          {/*Add attendee form in Attendees */}
          <AddNewAttendee />
          {/*All favourite attendee list in Attendees */}
          <FavouriteAttendeeList
            favouriteEmployeeList={this.state.itemizeExpenseTable}
            addFavouriteEmployee={this.state.addFavouriteEmployee}
            onFavoriteHandler={this.onFavoriteHandler}
          />

          <ImportAttendeeModel />

          <NewAttendeeList />

          <div
            className="modal fade"
            id="reportTotalModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="reportTotalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-head">
                  <h6 className="modal-subtitle">View</h6>
                  <h5 className="modal-title" id="reportTotalLabel">
                    REPORT TOTAL
                  </h5>
                </div>
                {!this.state.isTotalLoading ? (
                  <div className="modal-middle pm-30">
                    <div className="modal-scroll">
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th className="border-0">DIVISION</th>
                              <th className="border-0" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-0">Report Total</td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .reportTotal !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .reportTotal
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Less Personal Amount</td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .lessPersonalAmount !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .lessPersonalAmount
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td>Amount Claimed </td>
                              <td className="text-right">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .amountClaimed !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .amountClaimed
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Amount Rejected</td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .amountRejected !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .amountRejected
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Amount Approved</td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .amountApproved !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .amountApproved
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table  mb-0">
                          <thead>
                            <tr>
                              <th className="border-0">
                                Company Disbursements
                              </th>
                              <th className="border-0" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-0">Amount Due Employee</td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .amountDueEmployee !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .amountDueEmployee
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">
                                Cash Advance Utilised
                              </td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .cashAdvanceUtilized !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .cashAdvanceUtilized
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td>Company Paid to Vendor</td>
                              <td className="text-right">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .companyPaidtoCreditCard !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .companyPaidtoCreditCard
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>

                            <tr>
                              <td>Total Paid By Company</td>
                              <td className="text-right">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .totalPaidByCompany !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .totalPaidByCompany
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="border-0">
                                Employee Disbursements
                              </th>
                              <th className="border-0" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-0">Amount Due Company</td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .amountDueCompany !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .amountDueCompany
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">
                                Amount Due Credit Card
                              </td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .amountDueCreditCard !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .amountDueCreditCard
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">
                                Total Paid By Employee
                              </td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                  this.state.totalReportAmounts.totals &&
                                  this.state.totalReportAmounts.totals
                                    .totalPaidByEmployee !== null
                                  ? parseFloat(
                                    this.state.totalReportAmounts.totals
                                      .totalPaidByEmployee
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="modal-last btn-group-margin">
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                ) : (
                    <Loader />
                  )}
              </div>
            </div>
          </div>


          <div
            className="modal fade"
            id="reportTotalModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="reportTotalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-head">
                  {/* <h6 className="modal-subtitle">View</h6> */}
                  <h5 className="modal-title" id="reportTotalLabel">
                    Employee Details
                  </h5>
                </div>
                {!this.state.isTotalLoading ? (
                  <div className="modal-middle pm-30">
                    <div className="modal-scroll">
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th className="border-0">Personal Info</th>
                              <th className="border-0" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-0">Email</td>
                              <td className="text-right border-0">
                                {this.state.requestedUserInfo.email}
                              </td>
                            </tr>
                            {getValue(this.state, "requestedUserInfo.fieldData", []).map((item) => {
                              if (item.fieldValue !== "") {
                                switch (item.dataType) {
                                  case "text":
                                    return (
                                      <tr>
                                        <td className="border-0">{item.labelName}</td>
                                        <td className="text-right border-0">{item.fieldValue}</td>
                                      </tr>
                                    )
                                  case "date":
                                    return (
                                      <tr>
                                        <td className="border-0">{item.labelName}</td>
                                        <td className="text-right border-0">{item.fieldValue ? <Moment format='DD MMM YYYY'>{item.fieldValue}</Moment> : ""}</td>
                                      </tr>
                                    )
                                  case "different_list":
                                    return (
                                      <tr>
                                        <td className="border-0">{item.labelName}</td>
                                        <td className="text-right border-0">{item.differentListValue}</td>
                                      </tr>
                                    )
                                  case "connected_list":
                                    return (
                                      <tr>
                                        <td className="border-0">{item.labelName}</td>
                                        <td className="text-right border-0">{item.connectedFieldValue}</td>
                                      </tr>
                                    )
                                }
                              }
                            })}
                            {/* <td className="border-0">Less Personal Amount</td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                this.state.totalReportAmounts.totals &&
                                this.state.totalReportAmounts.totals
                                  .lessPersonalAmount !== null
                                  ? parseFloat(
                                      this.state.totalReportAmounts.totals
                                        .lessPersonalAmount
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr> */}
                            {/*
                            <tr>
                              <td>Amount Claimed </td>
                              <td className="text-right">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                this.state.totalReportAmounts.totals &&
                                this.state.totalReportAmounts.totals
                                  .amountClaimed !== null
                                  ? parseFloat(
                                      this.state.totalReportAmounts.totals
                                        .amountClaimed
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Amount Rejected</td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                this.state.totalReportAmounts.totals &&
                                this.state.totalReportAmounts.totals
                                  .amountRejected !== null
                                  ? parseFloat(
                                      this.state.totalReportAmounts.totals
                                        .amountRejected
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Amount Approved</td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                this.state.totalReportAmounts.totals &&
                                this.state.totalReportAmounts.totals
                                  .amountApproved !== null
                                  ? parseFloat(
                                      this.state.totalReportAmounts.totals
                                        .amountApproved
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                           */}
                          </tbody>
                        </table>
                        {/* <table className="table  mb-0">
                          <thead>
                            <tr>
                              <th className="border-0">
                                Company Disbursements
                              </th>
                              <th className="border-0" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-0">Amount Due Employee</td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                this.state.totalReportAmounts.totals &&
                                this.state.totalReportAmounts.totals
                                  .amountDueEmployee !== null
                                  ? parseFloat(
                                      this.state.totalReportAmounts.totals
                                        .amountDueEmployee
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">
                                Cash Advance Utilised
                              </td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                this.state.totalReportAmounts.totals &&
                                this.state.totalReportAmounts.totals
                                  .cashAdvanceUtilized !== null
                                  ? parseFloat(
                                      this.state.totalReportAmounts.totals
                                        .cashAdvanceUtilized
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td>Company Paid to Vendor</td>
                              <td className="text-right">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                this.state.totalReportAmounts.totals &&
                                this.state.totalReportAmounts.totals
                                  .companyPaidtoCreditCard !== null
                                  ? parseFloat(
                                      this.state.totalReportAmounts.totals
                                        .companyPaidtoCreditCard
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>

                            <tr>
                              <td>Total Paid By Company</td>
                              <td className="text-right">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                this.state.totalReportAmounts.totals &&
                                this.state.totalReportAmounts.totals
                                  .totalPaidByCompany !== null
                                  ? parseFloat(
                                      this.state.totalReportAmounts.totals
                                        .totalPaidByCompany
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="border-0">
                                Employee Disbursements
                              </th>
                              <th className="border-0" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-0">Amount Due Company</td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                this.state.totalReportAmounts.totals &&
                                this.state.totalReportAmounts.totals
                                  .amountDueCompany !== null
                                  ? parseFloat(
                                      this.state.totalReportAmounts.totals
                                        .amountDueCompany
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">
                                Amount Due Credit Card
                              </td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                this.state.totalReportAmounts.totals &&
                                this.state.totalReportAmounts.totals
                                  .amountDueCreditCard !== null
                                  ? parseFloat(
                                      this.state.totalReportAmounts.totals
                                        .amountDueCreditCard
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">
                                Total Paid By Employee
                              </td>
                              <td className="text-right border-0">
                                {this.state.userBaseCurrency}&nbsp;
                                {this.state.totalReportAmounts &&
                                this.state.totalReportAmounts.totals &&
                                this.state.totalReportAmounts.totals
                                  .totalPaidByEmployee !== null
                                  ? parseFloat(
                                      this.state.totalReportAmounts.totals
                                        .totalPaidByEmployee
                                    ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                          </tbody>
                        </table> */}
                      </div>
                    </div>
                    <div className="modal-last btn-group-margin">
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                ) : (
                    <Loader />
                  )}
              </div>
            </div>
          </div>





          <div
            className="modal fade"
            id="auditTrailModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="auditTrailLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content border-0">
                <div className="modal-head">
                  <h6 className="modal-subtitle">VIEW</h6>
                  <h5 className="modal-title" id="auditTrailLabel">
                    Audit Trail
                  </h5>
                </div>
                <div className="modal-middle pt-4">
                  <div className="border-bottom py-3 pm-30">
                    <div className="float-left user-name">J</div>
                    <div className="float-left ml-3">
                      <p className="mb-0">
                        <b>Jonathan Cook</b>
                      </p>
                    </div>
                    <div className="float-right">
                      <p className="mb-0">6 min ago</p>
                    </div>
                    <div className="float-left ml-3">
                      <p className="muted-p mb-0">
                        Added Expense Type{" "}
                        <span className="text-primary">
                          Car Rental (Attendee)
                        </span>
                      </p>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className="border-bottom py-3 pm-30">
                    <div className="float-left user-name">J</div>
                    <div className="float-left ml-3">
                      <p className="mb-0">
                        <b>Jonathan Cook</b>
                      </p>
                    </div>
                    <div className="float-right">
                      <p className="mb-0">6 min ago</p>
                    </div>
                    <div className="float-left ml-3">
                      <p className="muted-p mb-0">
                        Added Expense Type{" "}
                        <span className="text-primary">
                          Car Business Meal (Attendee)
                        </span>
                      </p>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="modal-last btn-group-margin pm-30">
                  <button type="button" className="btn" data-dismiss="modal">
                    close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* expense Approval list */}
          <div
            className="modal fade"
            id="approvalFlowModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="approvalFlowLable"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-head">
                  {/* <button
                    type="button"
                    className="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                  <h6 className="modal-subtitle">New</h6>
                  <h5 className="modal-title" id="approvalFlowLable">
                    Approval Flow
                  </h5>
                </div>
                <div className="modal-middle">
                  <div className="white-card">
                    {getValue(this.state, "approvalList", []) &&
                      getValue(this.state, "approvalList", []).map(approvals => {
                        let firstName, middleName, lastname, fullName;
                        firstName = !approvals.userDetails ? "" : approvals.userDetails.fieldData.find((userDetails) => userDetails.columnName === "EMPLOYEE_FIRST_NAME");
                        middleName = !approvals.userDetails ? "" : approvals.userDetails.fieldData.find((userDetails) => userDetails.columnName === "MIDDLE_NAME");
                        lastname = !approvals.userDetails ? "" : approvals.userDetails.fieldData.find((userDetails) => userDetails.columnName === "EMPLOYEE_LAST_NAME");

                        fullName =
                          firstName &&
                          middleName &&
                          middleName &&
                          firstName.fieldValue +
                          " " +
                          middleName.fieldValue +
                          " " +
                          lastname.fieldValue;
                        return (
                          <div
                            key={approvals.userId}
                            className="approver with-white-space-100"
                          >
                            {approvals.APPROVED ? (
                              <div className="right-icon approved">
                                <i className="material-icons">check_circle</i>
                              </div>
                            ) : approvals.REJECTED ? (
                              <div className="right-icon approved">
                                <i className="material-icons text-danger fs-20">
                                  thumb_down
                                </i>
                              </div>
                            ) : (
                                  <div className="right-icon waiting">
                                    <i className="material-icons">
                                      hourglass_empty
                                </i>
                                  </div>
                                )}
                            <div className="shape-name">
                              {approvals.userDetails &&
                                approvals.userDetails.profileImage ? (
                                  <div
                                    className="left img"
                                    style={{
                                      backgroundImage: `url(${approvals.userDetails.profileImage})`
                                    }}
                                  />
                                ) : (
                                  <div className="left icon">
                                    <i className="material-icons">person</i>
                                  </div>
                                )}
                              <div className="right">
                                <p className="label">
                                  Level {+approvals.orderNo + 1}
                                </p>
                                <p className="title">{fullName && fullName}</p>
                                <p >Email : {getValue(approvals, "userDetails.email", "")}</p>
                                {approvals.submittedAt ? <p>Submitted on : {approvals.submittedAt ? <Moment format='DD MMM YYYY, HH:mm'>{approvals.submittedAt}</Moment> : "     -"} </p> : ""}
                                {approvals.approvedAt ? <p>Approved on :{approvals.approvedAt ? <Moment format='DD MMM YYYY, HH:mm'>{approvals.approvedAt}</Moment> : "  Not Yet Approved"} </p> : ""}
                                <b>{getValue(approvals, "RESPONSE.comment", "")}</b>
                              </div>



                              <div className="clearfix" />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end expense approval user list */}
          <div
            className="modal fade"
            id="commentsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="commentsLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-head">
                  <h6 className="modal-subtitle">VIEW</h6>
                  <h5 className="modal-title" id="commentsLabel">
                    Comments
                  </h5>
                </div>
                <div className="modal-middle pt-4">
                  <div className="border-bottom py-3 pm-30">
                    <div className="float-left user-name">
                      <img
                        src="/assets/images/booking/user_11.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="float-left ml-3">
                      <p className="mb-0">
                        <b>Jonathan Cook</b>
                      </p>
                      <div className="float-left">
                        <p className="muted-p mb-0">
                          Hello, Please approve this on priority
                        </p>
                      </div>
                    </div>
                    <div className="float-right">
                      <p className="mb-0">6 min ago</p>
                    </div>

                    <div className="clearfix" />
                  </div>
                  <div className="border-bottom py-3 pm-30">
                    <div className="float-left user-name">
                      <img
                        src="/assets/images/booking/user_11.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="float-left ml-3">
                      <p className="mb-0">
                        <b>Louisa Boyd</b>
                      </p>
                      <div className="float-left">
                        <p className="muted-p mb-0">Checking!</p>
                      </div>
                    </div>
                    <div className="float-right">
                      <p className="mb-0">6 min ago</p>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className="border-bottom py-3 pm-30">
                    <div className="float-left user-name">
                      <img
                        src="/assets/images/booking/user_11.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="float-left ml-3">
                      <p className="mb-0">
                        <b>Doris Clark</b>
                      </p>
                      <div className="float-left">
                        <p className="muted-p mb-0">On it @Jonathan Cook</p>
                      </div>
                    </div>
                    <div className="float-right">
                      <p className="mb-0">6 min ago</p>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="modal-last btn-group-margin pm-30">
                  <button type="button" className="btn" data-dismiss="modal">
                    close
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="deleteEntry"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="submitModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="close text-white d-none"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="text-center pt-5">
                    <img src="/assets/images/confirmation_icon.svg" alt="" />
                    <h3 className=" mt-4 text-dark">Are You Sure?</h3>
                    <p>
                      By clicking on Delete, you agree to the <br />
                      expense policy and restrictions.
                    </p>
                  </div>
                  <div className="btn-group-margin text-center pb-5">
                    <button type="button" className="btn" data-dismiss="modal">
                      No
                    </button>
                    <button onClick={this.onDeleteExpenseEntry} className="btn">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="deleteItemisation"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="submitModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="close text-white d-none"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="text-center pt-5">
                    <img src="/assets/images/confirmation_icon.svg" alt="" />
                    <h3 className=" mt-4 text-dark">Are You Sure?</h3>
                    <p>
                      By clicking on submit, you agree to the <br />
                      expense policy and restrictions.
                    </p>
                  </div>
                  <div className="btn-group-margin text-center pb-5">
                    <button
                      type="button"
                      onClick={this.onItemisationDeleteCancelHandler}
                      className="btn"
                      data-dismiss="modal"
                    >
                      No
                    </button>
                    <button
                      onClick={this.onItemisationDeleteClickHandler}
                      className="btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="cashAdvAvailableModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="cashAdvAvailableLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-head">
                  <h6 className="modal-subtitle">View</h6>
                  <h5 className="modal-title" id="cashAdvAvailableLabel">
                    Cash Advances - Available
                  </h5>
                </div>
                <div className="modal-middle mt-5">
                  <div className="table-responsive">
                    <table className="table table-striped border">
                      <thead>
                        <tr>
                          <th />
                          <th>CASH ADVANCE NAME</th>
                          <th>REQUESTED DATE</th>
                          <th>AMOUNT</th>
                          {/* <th>EXCHANGE RATE</th> */}
                          <th>AVAILABLE BALANCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.allAdvanceRequest &&
                          this.state.allAdvanceRequest.map((result, index) => {
                            let theadArray = [];
                            theadArray = result.data.map(
                              header => header.columnName
                            );
                            let CASH_ADVANCE_NAME = theadArray.indexOf(
                              "CASH_ADVANCE_NAME"
                            );
                            let COMMENT = theadArray.indexOf("COMMENTS");
                            let AMOUNT = theadArray.indexOf("AMOUNT");
                            return (
                              <tr key={index}>
                                <td className="modal-px-ly py-3">
                                  <label className="checkbox-container m-0">
                                    <input
                                      type="checkbox"
                                      onChange={e =>
                                        this.onCashAdvanceSelectHadnler(result)
                                      }
                                      checked={this.state.selectedCashAdvanceReceiptIds.includes(
                                        result.id
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </td>
                                <td className="text-primary">
                                  {result.data[CASH_ADVANCE_NAME] &&
                                    result.data[CASH_ADVANCE_NAME].fieldValue}
                                </td>

                                <td>
                                  {result.data[index] &&
                                    result.data[index].createdAt &&
                                    moment(result.data[index].createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                </td>

                                <td>
                                  {result.data[AMOUNT] &&
                                    result.data[AMOUNT].currency}
                                  &nbsp;
                                  {result.data[AMOUNT] &&
                                    result.data[AMOUNT].fieldValue}
                                  <p>
                                    {result.data[AMOUNT] &&
                                      result.data[AMOUNT].baseCurrencyCode !==
                                      result.data[AMOUNT].currency
                                      ? result.data[AMOUNT].baseCurrencyCode +
                                      " " +
                                      parseFloat(
                                        result.data[AMOUNT].baseCurrency
                                      ).toFixed(2)
                                      : ""}
                                  </p>
                                </td>
                                {/* <td></td> */}
                                <td>
                                  {result.data[AMOUNT] &&
                                    result.data[AMOUNT].currency}
                                  &nbsp;
                                  {result.data[AMOUNT] &&
                                    result.data[AMOUNT].fieldValue}
                                  <p>
                                    {result.data[AMOUNT] &&
                                      result.data[AMOUNT].baseCurrencyCode !==
                                      result.data[AMOUNT].currency
                                      ? result.data[AMOUNT].baseCurrencyCode +
                                      " " +
                                      parseFloat(
                                        result.data[AMOUNT].baseCurrency
                                      ).toFixed(2)
                                      : ""}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                        {this.state.allAdvanceRequest.length !== 0 ? null : (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={5}>
                              No Records Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="modal-last btn-group-margin pm-30 ">
                      <button
                        data-dismiss="modal"
                        disabled={
                          this.state.selectedCashAdvanceReceiptIds.length <= 0
                        }
                        onClick={this.onCashAdvanceAttachHandler}
                        type="button"
                        className="btn"
                      >
                        ASSIGN CASH ADVANCE TO REPORT
                      </button>
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="cashAdvReportModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="cashAdvReportLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-xl modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-head">
                  <h6 className="modal-subtitle">View</h6>
                  <h5 className="modal-title" id="cashAdvReportLabel">
                    Cash Advances Assigned to Report
                  </h5>
                </div>
                <div className="modal-middle mt-5">
                  <div className="table-responsive">
                    <table className="table table-striped border">
                      <thead>
                        <tr>
                          <th />
                          <th>CASH ADVANCE NAME</th>
                          <th>REQUESTED DATE</th>
                          <th>AMOUNT</th>
                          {/* <th>EXCHANGE RATE</th> */}
                          <th>AVAILABLE BALANCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.getAllAssignedCashAdavnce
                          ? this.state.getAllAssignedCashAdavnce.map(
                            (result, index) => {
                              let theadArray = [];
                              theadArray = result.data.map(
                                header => header.columnName
                              );
                              let CASH_ADVANCE_NAME = theadArray.indexOf(
                                "CASH_ADVANCE_NAME"
                              );
                              let AMOUNT = theadArray.indexOf("AMOUNT");
                              return (
                                <tr key={index}>
                                  <td className="modal-px-ly py-3">
                                    <label className="checkbox-container m-0">
                                      <input
                                        onChange={e =>
                                          this.onSelectAttchedCashAdvHadnler(
                                            result
                                          )
                                        }
                                        type="checkbox"
                                        checked={this.state.selectedCashAdvAttachReceiptIds.includes(
                                          result.id
                                        )}
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </td>
                                  <td className="text-primary">
                                    {result.data[CASH_ADVANCE_NAME] &&
                                      result.data[CASH_ADVANCE_NAME]
                                        .fieldValue}
                                  </td>

                                  <td>
                                    {result.data[index] &&
                                      result.data[index].createdAt &&
                                      moment(
                                        result.data[index].createdAt
                                      ).format("DD/MM/YYYY")}
                                  </td>

                                  <td>
                                    {result.data[AMOUNT] &&
                                      result.data[AMOUNT].currency}
                                      &nbsp;
                                      {result.data[AMOUNT] &&
                                      result.data[AMOUNT].fieldValue}
                                    <p>
                                      {result.data[AMOUNT] &&
                                        result.data[AMOUNT].baseCurrencyCode !==
                                        result.data[AMOUNT].currency
                                        ? result.data[AMOUNT]
                                          .baseCurrencyCode +
                                        " " +
                                        parseFloat(
                                          result.data[AMOUNT].baseCurrency
                                        ).toFixed(2)
                                        : ""}
                                    </p>
                                  </td>
                                  {/* <td></td> */}
                                  <td>
                                    {result.data[AMOUNT] &&
                                      result.data[AMOUNT].currency}
                                      &nbsp;
                                      {result.data[AMOUNT] &&
                                      result.data[AMOUNT].fieldValue}
                                    <p>
                                      {result.data[AMOUNT] &&
                                        result.data[AMOUNT].baseCurrencyCode !==
                                        result.data[AMOUNT].currency
                                        ? result.data[AMOUNT]
                                          .baseCurrencyCode +
                                        " " +
                                        parseFloat(
                                          result.data[AMOUNT].baseCurrency
                                        ).toFixed(2)
                                        : ""}
                                    </p>
                                  </td>
                                </tr>
                              );
                            }
                          )
                          : ""}
                        {this.state.getAllAssignedCashAdavnce.length !==
                          0 ? null : (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan={5}>
                                No Records Found
                            </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                    <div className="modal-last btn-group-margin pm-30 ">
                      <button
                        data-dismiss="modal"
                        disabled={
                          this.state.selectedCashAdvAttachReceiptIds.length <= 0
                        }
                        onClick={this.onRemoveAttchedCashAdvHadnler}
                        type="button"
                        className="btn"
                      >
                        {" "}
                        DELETE FROM REPORT
                      </button>
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="importAttDoneModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="importAttDoneLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-head">
                  <h6 className="modal-subtitle">New</h6>
                  <h5 className="modal-title" id="importAttDoneLabel">
                    Import Attendee - Done
                  </h5>
                </div>
                <div className="modal-middle">
                  <div className="py-5 check-box text-center">
                    <i className="material-icons">check_circle</i>
                    <h5> 6 Attendees Successfully Imported </h5>
                  </div>
                </div>
                <div className="modal-last btn-group-margin pm-30 ">
                  <button type="button" className="btn" data-dismiss="modal">
                    Cancel
                  </button>
                  <button type="button" className="btn">
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* start approval modal process */}
          <div
            className="modal fade"
            id="cancelcashAdvanceModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="cancelcashAdvancLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-head">
                  <button
                    type="button"
                    className="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h6 className="modal-subtitle">New</h6>
                  <h5 className="modal-title" id="cancelcashAdvancLabel">
                    Reason for Rejection
                  </h5>
                </div>
                <div className="modal-middle pm-30 pb-0">
                  <div className="form-group mb-0 custom labelup textarea">
                    <textarea
                      data-gramm_editor="false"
                      spellCheck="false"
                      rows="2"
                      className="form-control "
                      onChange={this.onRejectionChangeHandler}
                      placeholder="Reason for Rejection"
                    />
                    <label>Reason</label>
                  </div>
                </div>
                {!this.state.isSubmit ? (
                  <div className="modal-last btn-group-margin pm-30">
                    <button
                      type="button"
                      className="btn btn-badge"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={this.onCashAdvanceSubmitActionHandler}
                      className="btn  "
                    >
                      Submit
                      <i className="material-icons">arrow_drop_down</i>
                    </button>
                  </div>
                ) : (
                    <div className="modal-last btn-group-margin pm-30">
                      <button type="button" className="btn btn-badge">
                        Please Wait...
                    </button>
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="submitModalRequest"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="submitModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <button
                  type="button"
                  className="close text-white d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {this.state.selectedAction !== "APPROVE" && (
                  <div className="modal-head">
                    <span
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    />
                    <h5 className="modal-title">
                      {this.state.actionSelectedName}
                    </h5>
                  </div>
                )}
                <div className="modal-middle pm-30">
                  <div className="row">
                    <div className="col-md-12">
                      {this.state.selectedAction === "ADD_NEW_STEP" && (
                        <div className="">
                          <div className="form-group custom">
                            <AsyncSelect
                              noOptionsMessage={({ inputValue }) =>
                                inputValue
                                  ? "No results."
                                  : "Start typing to search..."
                              }
                              placeholder="Search user"
                              loadOptions={this.onApprovalSearchHandler}
                              value={
                                this.state.selectedApproval &&
                                this.state.selectedApproval
                              }
                              onChange={event =>
                                this.onApprovalSelectHandler(event)
                              }
                            />
                            <span className="searc-icon-abs">
                              <i className="material-icons">search</i>
                            </span>
                          </div>
                          <div />
                        </div>
                      )}

                      <div>
                        {(this.state.selectedAction === "SEND_BACK_EMPLOYEE" ||
                          this.state.selectedAction === "REJECT") && (
                            <div className="form-group custom textarea">
                              <textarea
                                data-gramm_editor="false"
                                spellCheck="false"
                                className="form-control"
                                name="rejectReason"
                                value={this.state.rejectionReason}
                                onChange={this.onReasonChangeHandler}
                              />
                              <label>Message</label>
                              <div className="help-block">
                                {!this.state.formValidaionStatus
                                  ? "Please enter valid comments"
                                  : ""}
                              </div>
                            </div>
                          )}
                        {this.state.selectedAction === "APPROVE" && (
                          <div>
                            <div className="text-center pt-5">
                              <img
                                src="/assets/images/confirmation_icon.svg"
                                alt=""
                              />
                              <h3 className=" mt-4 text-dark">Are You Sure?</h3>
                              <p>
                                By clicking on submit, you agree to the <br />
                                expense policy and restrictions.
                              </p>
                            </div>
                            <div className="btn-group-margin text-center">
                              <button
                                className="btn"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                No
                              </button>
                              {!this.state.isSubmit ? (
                                this.state.actionFrom === "modal" ? (
                                  <button
                                    onClick={this.onApprovalClickHandler}
                                    className="btn"
                                    data-dismiss="modal"
                                  >
                                    Submit
                                  </button>
                                ) : (
                                    <button
                                      onClick={
                                        this.onCashAdvanceSubmitActionHandler
                                      }
                                      className="btn"
                                      data-dismiss="modal"
                                    >
                                      Submit
                                    </button>
                                  )
                              ) : (
                                  <button className="btn">Please Wait...</button>
                                )}
                            </div>
                          </div>
                        )}
                        {this.state.selectedAction !== "APPROVE" && (
                          <div className="modal-last btn-group-margin pm-30">
                            <button
                              type="button"
                              className="btn btn-badge"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                            {getValue(this.state, "selectedApproval.value") ?
                              // ""
                              //added by sethu
                              <button
                                onClick={this.onCashAdvanceSubmitActionHandler}
                                className="btn  "
                              >
                                Submit
                              <i className="material-icons">arrow_right</i>
                              </button>
                              //end
                              : <span>
                                {!this.state.isSubmit ? (
                                  <button
                                    onClick={this.onCashAdvanceSubmitActionHandler}
                                    className="btn  "
                                    data-dismiss="modal"

                                  >
                                    Submit
                                    <i className="material-icons">arrow_right</i>
                                  </button>
                                ) : (
                                    <button
                                      onClick={this.onCashAdvanceSubmitActionHandler}
                                      className="btn"
                                    >
                                      Please Wait...
                                    </button>
                                  )}
                              </span>}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AllocatedGraphModal
            userBaseCurrency={this.state.userBaseCurrency}
            allocationDetails={this.state.allocationDetails}
            isGraphDataLoading={this.state.isGraphDataLoading}
          />
          {/* end approval modal */}
        </div>
      </>
    );
  }
}
export default ExpenseApprovalDetails;
