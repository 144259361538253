import React from "react";
import { NavLink } from "react-router-dom";
import ExpenseTypesMenu from "../expense-types-menu";
const ExpenseTypeStep5Page = props => {
  // let data = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">R</div>
          <div className="right">
            <h6 className="heading">Expense Types</h6>
            <p className="text">Lorem Ipsum is simply dummy</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <ExpenseTypesMenu />
          <div className="">
            <div className="row">
              <div className="col-md-4">
                <div className="file-tree-container">
                  <div className="btn-group-search">
                    <button className="btn  ">New</button>
                    <div className="search-custom">
                      <input
                        type="text"
                        name=""
                        className="form-control"
                        placeholder="Search"
                      />
                      <span>
                      <i className="material-icons">search</i>
                      </span>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className="file-tree">
                    <ul>
                      <li>
                        <button
                          className="btn btn-nothing collapsed"
                          data-toggle="collapse"
                          data-target="#demo"
                        >
                          <img src="/assets/images/folder.svg" alt="" />
                          <span className="text">Global-1</span>
                        </button>
                        <ul id="demo" className="collapse">
                          <li>
                            <button
                              className="btn btn-nothing collapsed"
                              data-toggle="collapse"
                              data-target="#demo1"
                            >
                              <img src="/assets/images/subfolder.svg" alt="" />
                              <span className="text">Default</span>
                            </button>
                            <ul id="demo1" className="collapse">
                              <li>
                                <button
                                  className="btn btn-nothing collapsed no-child"
                                  data-toggle="collapse"
                                  data-target="#demo2"
                                >
                                  <img src="/assets/images/item.svg" alt="" />
                                  <span className="text">Default</span>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn btn-nothing collapsed no-child"
                                  data-toggle="collapse"
                                  data-target="#demo3"
                                >
                                  <img src="/assets/images/item.svg" alt="" />
                                  <span className="text">Default</span>
                                </button>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <button
                              className="btn btn-nothing collapsed"
                              data-toggle="collapse"
                              data-target="#demo4"
                            >
                              <img src="/assets/images/subfolder.svg" alt="" />
                              <span className="text">Default</span>
                            </button>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <button
                          className="btn btn-nothing collapsed"
                          data-toggle="collapse"
                          data-target="#demo5"
                        >
                          <img src="/assets/images/subfolder.svg" alt="" />
                          <span className="text">Default</span>
                        </button>
                        <ul id="demo5" className="collapse">
                          <li>
                            <button
                              className="btn btn-nothing collapsed"
                              data-toggle="collapse"
                              data-target="#demo6"
                            >
                              <img src="/assets/images/subfolder.svg" alt="" />
                              <span className="text">Default</span>
                            </button>
                            <ul id="demo6" className="collapse">
                              <li>
                                <button
                                  className="btn btn-nothing collapsed"
                                  data-toggle="collapse"
                                  data-target="#demo7"
                                >
                                  <img src="/assets/images/item.svg" alt="" />
                                  <span className="text">Default</span>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn btn-nothing collapsed"
                                  data-toggle="collapse"
                                  data-target="#demo8"
                                >
                                  <img src="/assets/images/item.svg" alt="" />
                                  <span className="text">Default</span>
                                </button>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <button
                              className="btn btn-nothing collapsed"
                              data-toggle="collapse"
                              data-target="#demo9"
                            >
                              <img src="/assets/images/subfolder.svg" alt="" />
                              <span className="text">Default</span>
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="table-container table-restricted">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr className="border-0">
                          <th>Policy Name</th>
                          <th>Amount Calculation formula</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border">
                          <td>India Expense Policy</td>
                          <td>Excel Formulas ?</td>
                        </tr>
                        <tr className="border">
                          <td>US Expense Policy</td>
                          <td />
                        </tr>
                        <tr className="border">
                          <td>UK Expense Policy</td>
                          <td />
                        </tr>
                        <tr className="border">
                          <td>China Expense Policy</td>
                          <td />
                        </tr>
                        <tr className="border">
                          <td>Expense Policy</td>
                          <td />
                        </tr>
                        <tr className="border">
                          <td>Domestic Expense Policy</td>
                          <td />
                        </tr>
                        <tr className="border">
                          <td>Domestic Expense Policy</td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-group-margin mt-3">
              <NavLink
                to="/administration/expense/expense-types-step5"
                className="btn btn-border-success lg-padding"
              >
                save
              </NavLink>
              <button className="btn btn-border-basic lg-padding">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpenseTypeStep5Page;
