import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
class IssueApproverPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <div className="p-padding">
                    <div className="white-card">
                        <div className="form-holder">
                            <div className="table-container">
                                <form>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="profile--info">
                                                    <div className="float-left">
                                                        <div className="left-icon">
                                                            <img src="/assets/images/booking/user_12.png" alt="Pic" className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <div className="float-left right-text ml-3">
                                                      <p className="font-weight-bold">Christine Willis</p>  
                                                      <p>Manager</p>                     
                                                    </div>
                                                    <div className="clearfix"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 offset-4">
                                            <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                                    Status
                                                    </button>
                                                <div className="dropdown-menu">
                                                    <button className="dropdown-item">Status</button>
                                                    <button className="dropdown-item">Status</button>
                                                    <button className="dropdown-item">Status</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="search-custom">
                                                <input type="text" name="" className="form-control" placeholder="Search" />
                                                <span><i className="material-icons">search</i></span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Cash Advance Name</th>
                                                <th>Requested Date</th>
                                                <th>Amount Requested</th>
                                                <th>Exchange Rate</th>
                                                <th>Starting Balance</th>
                                                <th>Unsubmitted Balance</th>
                                                <th>Available Balance</th>
                                                <th className="text-right">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><p className="td-padding">Stationary Request</p></td>
                                                <td><p className="td-padding">18/12/2017</p></td>
                                                <td><p className="td-padding">$100.00</p></td>
                                                <td><p className="td-padding">$1.000000</p></td>
                                                <td><p className="td-padding">$100.00</p></td>
                                                <td><p className="td-padding">$100.00</p></td>
                                                <td><p className="td-padding">$100.00</p></td>
                                                <td className="text-right"><p className="td-padding"><NavLink to="/cash-issued/cash-issued-for-trips" className="btn btn-danger">Pending</NavLink></p></td>
                                            </tr>
                                            <tr>
                                                <td><p className="td-padding">Stationary Request</p></td>
                                                <td><p className="td-padding">18/12/2017</p></td>
                                                <td><p className="td-padding">$100.00</p></td>
                                                <td><p className="td-padding">$1.000000</p></td>
                                                <td><p className="td-padding">$100.00</p></td>
                                                <td><p className="td-padding">$100.00</p></td>
                                                <td><p className="td-padding">$100.00</p></td>
                                                <td className="text-right"><p className="td-padding"><NavLink to="/cash-issued/cash-issued-for-trips" className="btn btn-border-success sm-padding">Issued</NavLink></p></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default IssueApproverPage