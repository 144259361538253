import React, { Component } from "react";
import AddCashAdvanceConfigPage from "./add-cash-advance-config-html";
import { expenseServices } from '../../../../../../_services/expense.service';
import { toast } from 'react-toastify';
import { serviceModules } from "src/_constants/serviceModule";
import { checkValidityBeforeSubmit } from "src/utils/util";

class AddCashAdvanceConfig extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state={
        configName:'',
        cashWorkFlow:'',
        cashAdvanceForm:'',
        appliesTo:[],
        editedby:[],
        cashWorkFlowId:'',
        cashAdvanceFormId:'',
        cashAdvanceFormList:[],
        cashWorkFlowList:[],
        appliesToList:[],
        editedbyList:[],
        companyId: localStorage["440a28"]

    }
  }
  componentDidMount(){
    this.getCashAdvanceForm();
    this.getCashAdvanceWorkflow();
    this.getAppliesTo();
  }
   getCashAdvanceWorkflow=async()=>{
    let response = await expenseServices.getCashAdvanceWorkflow(this.state.companyId);
    if(response){
      this.setState({cashWorkFlowList:response.filter(workflow => workflow.workflowType.master_value.service_module == serviceModules.CASH_ADMIN)});
    }
  }
  getCashAdvanceForm=async()=>{
    let response = await expenseServices.getCashAdvanceForm(this.state.companyId);
    if(response){
      this.setState({cashAdvanceFormList:response});
    }
  }
  getAppliesTo=async()=>{
    let response = await expenseServices.getAppliesTo(this.state.companyId);
    if(response){
      let editedbyList = response.map((list)=> ({'label':list.itemName,'value':list.id}));
      this.setState({appliesToList:editedbyList,editedbyList:editedbyList});
    }
  }
  onInputChangeHandler=(event)=>{
    this.setState({configName:event.target.value});
  }
  onWorkFlowHandler=(value,id)=>{
    this.setState({cashWorkFlow:value,cashWorkFlowId:id});
  }

  onAdvFormHandler=(value,id)=>{
    this.setState({cashAdvanceForm:value,cashAdvanceFormId:id});
  }

  onAppliesHandler=(value)=>{
    this.setState({appliesTo:value});
  }

  onEditedHandler=(value)=>{
    this.setState({editedby:value});
  }

  onSubmitHandler=async(event)=>{
    event.preventDefault();
    let appliesTo=[];
    let editedby=[];
    this.state.editedby.map((apply,index)=>editedby[index]=apply.value);
    this.state.appliesTo.map((apply)=>appliesTo.push({'name':apply.label,'groupId':apply.value}));
    // this.state.appliesTo.map((apply)=>appliesTo.push(apply.value));
    let data={
      companyId:this.state.companyId,
      configName:this.state.configName,
      cashAdvanceWorkflow:this.state.cashWorkFlowId,
      cashAdvanceForm:this.state.cashAdvanceFormId,
      editableBy:editedby,
      appliesTo:appliesTo
    }
    if(checkValidityBeforeSubmit(data)){
      let response = await expenseServices.createCashAdvanceConfig(data);
      if(response){
        toast.success(response.message);
        this.props.history.push('./cash-advance');
      }
    }else{
      toast.warn("Kindly fill all the fields")
    }

  }
  onCancelHandler=()=>{
    this.props.history.push('./cash-advance');
  }
  render() {
    return (
      <div>
        <AddCashAdvanceConfigPage
          {...this.state}
          onInputChangeHandler={this.onInputChangeHandler}
          onWorkFlowHandler={this.onWorkFlowHandler}
          onAdvFormHandler={this.onAdvFormHandler}
          onAppliesHandler={this.onAppliesHandler}
          onEditedHandler={this.onEditedHandler}
          cashAdvanceFormList={this.state.cashAdvanceFormList}
          cashWorkFlowList={this.state.cashWorkFlowList}
          appliesToList={this.state.appliesToList}
          editedbyList={this.state.editedbyList}
          onSubmitHandler={this.onSubmitHandler}
          onCancelHandler={this.onCancelHandler}
         />
      </div>
    );
  }
}
export default AddCashAdvanceConfig;
