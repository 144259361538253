import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import DatePicker from "react-datepicker";

import Moment from "react-moment";

const MultiCity = props => {
    const {
        keys,
        items,
        allList,
        searchMultiCity,
        handleRemoveCity,
        validateTrue,
        locationFromRef,
        locationToRef,
        dateFromRef,
        getFromMultiCity,
        listSearchCities,
        showMultiInputTo,
        activeMultiInputTo,
        popularCity,
        showMultiInputDepart,
        changeHandleMul,
        handleDayClickDepart,
        today,
        getToMultiCity,
        activeMultiInputDepart,
        handleMultiLocation,
        showMultiInputFrom,
        activeMultiInputFrom,
        showInputMultiClass,
        activeMultiInputClass,
        listClass,
        classTypeRef,
        changeClass,
        classType,
        loadingCities
    } = props;
    items.departureDate = (items.departureDate) ? new Date(items.departureDate) : null
    return (
        <div className="loop-start" key={keys}>
            <div
                className={
                    activeMultiInputFrom.indexOf(keys) === -1
                        ? "details multi-de from"
                        : "details multi-de from show-popup"
                }
                ref={locationFromRef}
            >
                <div
                    className={
                        validateTrue && items.cityNameFrom === ""
                            ? "details-margin not-valid"
                            : "details-margin"
                    }
                    onClick={e => showMultiInputFrom(1, keys)}
                >
                    <div className="d-table d-table__middle">
                        <label className="text-left m-label" htmlFor={"search" + keys}>
                            <p className="label">From</p>
                            <h6 className="place">
                                {items.cityNameFrom === ""
                                    ? "Where From?"
                                    : items.cityNameFrom}
                            </h6>
                            <p className="bottom">
                                {items.airportFrom === "" ? "" : items.airportFrom}
                            </p>
                        </label>
                    </div>
                </div>
                <div
                    onClick={e => changeHandleMul(keys)}
                    className={items.swap ? "swap" : "swap active"}
                >
                    <i className="material-icons">swap_horiz</i>
                </div>
                <div className="popup multi-popup">
                    <div className="typ">
                        <input
                            type="text"
                            name="cityNameFrom"
                            id={"search" + keys}
                            value={items.cityNameFrom}
                            onChange={e => handleMultiLocation(e, 1, keys)}
                            placeholder="Where From?"
                        />
                        <div className="pin">
                            <i className="material-icons">location_on</i>
                        </div>
                    </div>
                    {(!loadingCities) ?
                        <div>
                            {listSearchCities.length !== 0 && (
                                <ul className="scroll">
                                    <li>
                                        <button type="button">Suggest Cities</button>
                                    </li>
                                    {listSearchCities.map((items, key) => {
                                        if (items.code !== allList[keys].codeTo) {
                                            return (
                                                <li key={key}>
                                                    <button
                                                        type="button"
                                                        onClick={e => getFromMultiCity(items, keys)}
                                                    >
                                                        {items.city}&nbsp;{" "}
                                                        <small>
                                                            ({items.code}-{items.name})
                            </small>
                                                    </button>
                                                    <div className="pin">
                                                        <i className="material-icons">flight</i>
                                                    </div>
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            )}
                            {listSearchCities.length === 0 && (
                                <ul className="scroll">
                                    <li>
                                        <button type="button">Popular Cities</button>
                                    </li>
                                    {popularCity.map((items, key) => {
                                        if (items.code !== allList[keys].codeTo) {
                                            return (
                                                <li key={key}>
                                                    <button
                                                        type="button"
                                                        onClick={e => getFromMultiCity(items, keys)}
                                                    >
                                                        {items.city}&nbsp;
                            <small>
                                                            ({items.code}-{items.name})
                            </small>
                                                    </button>
                                                    <div className="pin">
                                                        <i className="material-icons">flight</i>
                                                    </div>
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            )}
                        </div>
                        :
                        <ul className="scroll">
                            <li className="popup__head">
                                <button type="button">Loading</button>
                            </li>
                        </ul>
                    }
                </div>
            </div>
            <div
                className={
                    activeMultiInputTo.indexOf(keys) === -1
                        ? "details multi-de to"
                        : "details multi-de to show-popup"
                }
                ref={locationToRef}
            >
                <div
                    className={
                        validateTrue && items.cityNameTo === ""
                            ? "details-margin not-valid"
                            : "details-margin"
                    }
                    onClick={e => showMultiInputTo(1, keys)}
                >
                    <div className="d-table d-table__middle">
                        <label htmlFor={"search2" + keys} className="text-left m-label">
                            <p className="label">To</p>
                            <h6 className="place">
                                {items.cityNameTo === "" ? "To Where ?" : items.cityNameTo}
                            </h6>
                            <p className="bottom">
                                {items.airportTo === "" ? "" : items.airportTo}
                            </p>
                        </label>
                    </div>
                </div>
                <div className="popup multi-popup resp-right">
                    <div className="typ">
                        <input
                            type="text"
                            name="cityNameTo"
                            id={"search2" + keys}
                            value={items.fromcityNameTo}
                            onChange={e => handleMultiLocation(e, 1, keys)}
                            placeholder="To Where?"
                        />
                        <div className="pin">
                            <i className="material-icons">location_on</i>
                        </div>
                    </div>
                    {(!loadingCities) ?
                        <div>
                            {listSearchCities.length !== 0 && (
                                <ul className="scroll">
                                    <li>
                                        <button type="button">Suggest Cities</button>
                                    </li>
                                    {listSearchCities.map((items, key) => {
                                        if (items.code !== allList[keys].codeFrom) {
                                            return (
                                                <li key={key}>
                                                    <button
                                                        type="button"
                                                        onClick={e => getToMultiCity(items, keys)}
                                                    >
                                                        {items.city}&nbsp;{" "}
                                                        <small>
                                                            ({items.code}-{items.name})
                                                    </small>
                                                    </button>
                                                    <div className="pin">
                                                        <i className="material-icons">flight</i>
                                                    </div>
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            )}
                            {listSearchCities.length === 0 && (
                                <ul className="scroll">
                                    <li>
                                        <button type="button">Popular Cities</button>
                                    </li>
                                    {popularCity.map((items, key) => {
                                        if (items.code !== allList[keys].codeFrom) {
                                            return (
                                                <li key={key}>
                                                    <button
                                                        type="button"
                                                        onClick={e => getToMultiCity(items, keys)}
                                                    >
                                                        {items.city}&nbsp;{" "}
                                                        <small>
                                                            ({items.code}-{items.name})
                                                    </small>
                                                    </button>
                                                    <div className="pin">
                                                        <i className="material-icons">flight</i>
                                                    </div>
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            )}
                        </div>
                        :
                        <ul className="scroll">
                            <li className="popup__head">
                                <button type="button">Loading</button>
                            </li>
                        </ul>
                    }
                </div>
            </div>
            <div
                className={
                    activeMultiInputDepart.indexOf(keys) === -1
                        ? "details multi-de depart"
                        : "details multi-de depart show-popup"
                }
                ref={dateFromRef}
            >
                <div
                    className={
                        validateTrue &&
                            (items.departureDate === "" || items.departureDate === null)
                            ? "details-margin not-valid"
                            : "details-margin"
                    }
                    onClick={e => showMultiInputDepart(1, keys)}
                >
                    <div className="d-table d-table__middle">
                        <div className="text-left">
                            <p className="label">DEPARTURE DATE</p>

                            <h6 className="place">
                                {items.departureDate ? (
                                    <Moment format="DD MMM YYYY">
                                        {items.departureDate}
                                    </Moment>
                                ) : (
                                        "-"
                                    )}
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="popup multi-popup date-picker">
                    {/* new date picker starts */}
                    <DatePicker
                        inline
                        selected={(items.departureDate) ? new Date(items.departureDate) : null}
                        openToDate={new Date((keys === 0) ? today : (allList && allList[keys - 1].departureDate) ? allList[keys - 1].departureDate : today)}
                        onChange={e => handleDayClickDepart(e, keys)}
                        minDate={(keys === 0) ? today : (allList && allList[keys - 1].departureDate) ? allList[keys - 1].departureDate : today}
                        monthsShown={2}
                        // showTimeSelect
                        timeFormat="HH:mm"
                        calendarClassName="double-datepicker"
                    />
                    {/* old date picker starts */}
                    {/* <DayPicker
                        numberOfMonths={2}
                        selectedDays={items.departureDate}
                        disabledDays={{ before: (keys === 0) ? today : (allList && allList[keys - 1].departureDate) ? allList[keys - 1].departureDate : today }}
                        onDayClick={e => handleDayClickDepart(e, keys)}
                    /> */}
                </div>
            </div>
            {keys === 0 && (
                <div
                    className={
                        activeMultiInputClass.indexOf(keys) === -1
                            ? "details class big"
                            : "details class big show-popup"
                    }
                    ref={classTypeRef}
                >
                    <div
                        className={
                            validateTrue &&
                                (items.classType === "" || items.classType === null)
                                ? "details-margin not-valid"
                                : "details-margin"
                        }
                        onClick={e => showInputMultiClass(1, keys)} >
                        <div className="d-table d-table__middle">
                            <div className="text-left">
                                <p className="label">Class</p>
                                <h6 className="place">{classType}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="popup left-popup classpop">
                        <ul>
                            {listClass.map((item, key) => {
                                return (
                                    <li key={key} onClick={e => changeClass(item, keys)}>
                                        <button type="button">{item.value}</button>
                                        <div className="pin">
                                            <i className="material-icons">hotel</i>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            )}
            {keys === 0 && (
                <button
                    onClick={searchMultiCity}
                    className="btn details search btn-blue d-none d-md-block"
                >
                    <i className="material-icons">search</i>
                </button>
            )}
            {keys >= 2 && (
                <button
                    type="button"
                    onClick={handleRemoveCity(keys)}
                    className="btn details search remove"
                >
                    <span>
                        <i className="material-icons">
                            remove
</i>
                    </span>
                </button>
            )}
            <div className="clearfix" />
        </div>
    );
};
export default MultiCity;
