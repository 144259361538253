import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Header from '../common/header';
class SupportComponent extends Component {
    constructor(props) {
        super(props);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    render() {
        return (
            <div>
                {/* <Header
                /> */}
                <div className="page-wrapper help-page">
                    <div className="bg-image bg-image5 ">
                    </div>
                    <div className="top__margin">
                        <div className="mark1 text-center mb-4">
                            {/* <div className="round-mark">
                                <i className="material-icons">contact_support</i>
                                <img src="/assets/images/help/support1.svg" />
                            </div> */}
                            <h4 className="text-white pt-2 text-black">Support </h4>
                            <p>Your data, activity, and preferences that help make curio services more useful to you</p>
                        </div>
                        <div className="px-padding">
                            <div className="form-holder border-light mb-5">
                                <div className="text-center">
                                    <h4 className="text-primary">Got a quick question?</h4>
                                    <p>Reach out to us for a quick resolve</p>
                                    <img src="/assets/images/help/support.svg" alt="" width="280" />
                                </div>
                                <div className="row">
                                    <div className="col-md-6 offset-md-3">
                                        <div className="form-holder light-shadow">
                                            <h5 className="sub-heading">Support</h5>
                                            <div className="form-group custom labelup">
                                                <label>Topic</label>
                                                <div className="dropdown">
                                                    <button
                                                        type="button"
                                                        className="btn dropdown-toggle"
                                                        data-toggle="dropdown"
                                                    >
                                                        Login Related
                                                </button>
                                                    <div className="dropdown-menu">
                                                        <button
                                                            className="dropdown-item"
                                                        >Login Related
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group custom">
                                                <input type="text" className="form-control" placeholder="&nbsp;" />
                                                <label>Email</label>
                                            </div>
                                            <div className="form-group custom">
                                                <input type="text" className="form-control" placeholder="&nbsp;" />
                                                <label>Phone</label>
                                            </div>
                                            <div className="form-group custom">
                                                <input type="text" className="form-control" placeholder="&nbsp;" />
                                                <label>Subject</label>
                                            </div>
                                            <div className="form-group custom textarea">
                                                <textarea className="form-control" placeholder="&nbsp;" />
                                                <label>Message</label>
                                            </div>
                                            <div className="mt-4">
                                                <button className="btn" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SupportComponent;