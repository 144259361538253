import React from 'react';
import { Select } from 'src/_components/common/_forms';

export default function SendEmail(props) {
  return (
    <div>
    <div className="form-group custom">
      <input type="text" className="form-control" placeholder="&nbsp;" onChange={props.emailChangeHandler} />
      <label>Email Address</label>
    </div>
    <div  className="form-group">
      <label  className="switch">
        <input type="checkbox" checked={props.emailDefaultApprover} onChange={props.emailDefaultApproverHandler} />
        <span  className="slider round"></span>
      </label>
      <span>Default Approver</span>
    </div>
    <div className="form-group custom labelup">
      <label>Email Notification</label>
      <Select
        placeholder={props.selectedEmailTemplate?.name ||  "Email Notification"} 
        options={props.allEmailTemplatesOptions}
        controlFunc={props.emailTemplateSelectHandler}
        keyName='name'
      />

      </div>
      </div>


  )
}
