import React, { Component } from 'react';
import WorkflowsStep2Page from './workflow-step2-html';
import { workflowService } from 'src/_services/workflow.service';
import { toast } from 'react-toastify';
import { alertConstants } from 'src/_constants';
import $ from 'jquery';
import { map } from 'lodash/fp';
import SimpleReactValidator from 'simple-react-validator';


class WorkflowsStep2 extends Component {

  state = {
    companyId: localStorage["440a28"],
    loading: false,
    workflow_id: '',
    workflowStepName: '',
    emailToEmployee: false,
    exitWithBlockingException: false,
    allWorkflowSteps: [],
    selectedStep: [],
    allApprovalOptions: [],
    selectedinitialState: {},
    // * for edit mode
    approvalActionOptions: [],
    editApprovalActionOptions: [],
    isEdit: false,
  }
  validator = new SimpleReactValidator();
  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (this.props.match.params.id !== undefined) {
      await this.setState({
        workflow_id: this.props.match.params.id
      });
    }
    await this.getAllExpenseApproval();
    this.getAllMasterOptionsForStep();
    this.getAllWorkFlowSteps();
  }
  // * Get all expense approval
  getAllExpenseApproval = async () => {
    let res = await workflowService.getAllExpenseApprovalWith(this.state.companyId);
    if (res) {
      this.setState({
        allExpenseApproval: res.result,
        totalCount: res.count
      });
    }
  }
  // * for getting all the masters Options for the step.
  getAllMasterOptionsForStep = async () => {
    let res = await workflowService.getMasterOptionsForStep();
    if (res && res.result != undefined) {
      await this.setState({
        allMasterOptions: res.result
      });
      this.setOptions();
    }
  }
  // * Set master options to the field
  setOptions = () => {
    this.state.allMasterOptions.forEach(item => {
      // * loop through all masterOptions and create options with master_name + 'Options'
      // * these options will be used for  creating the options for the select dropdowns
      let option = item['master_name'] + 'Options';
      this.setState({
        [option]: item
      });
      if (item['master_name'] == "approvalAction") {
        let allApprovalOptions = item.master_value.map(option => {
          return {
            value: option.master_value_id,
            label: option.master_name_value
          }
        });
        this.setState({ allApprovalOptions });
      }
    })
  }
  // * Get all the workflowSteps
  getAllWorkFlowSteps = async () => {
    this.setState({ loading: true })
    if (this.state.workflow_id) {
      let res = await workflowService.getAllWorkflowSteps(this.state.workflow_id);
      this.setState({ loading: false, allWorkflowSteps: [] })
      if (res) {
        this.setState({
          allWorkflowSteps: res.result
        });
      }
    }
  }
  // * for now its for dropdown change. and select, trying to make it reusable
  handleDropdownChange = (e, dropdownOptions, dropdownName) => {
    let selectedDropdown = 'selected' + dropdownName;
    let dropDownData = {
      master_name: dropdownName,
      master_id: dropdownOptions.master_id,
      master_value: e
    };
    // * set the selected dropdown value and store the data to be sent a request in the a state variable with same name as dropdownName
    this.setState({
      [selectedDropdown]: e.master_name_value,
      [dropdownName]: dropDownData
    });
  };
  // * For handling the input of workflow type, workflow name and expire after days input box
  handleInputChange = (e, inputName) => {
    this.setState({ [inputName]: e.target.value });
  };
  // * For handing the toggles in the page
  handleToggleChange = (e, toggleName) => {
    this.setState({
      [toggleName]: e.target.checked
    });
  }
  // * handling for approval which is a multi select
  handleApprovalChange = (item) => {
    let approvalAction = item.map(i => {
      return {
        master_name_value: i.label,
        master_value_id: i.value
      }
    });
    this.setState({
      approvalAction,
      editApprovalActionOptions: map('value', item)
    });
  }
  handleInitialStatus = (e) => {
    this.setState({
      selectedinitialState: e
    })
  }
  stepStatusToggleHandler = async (step) => {
    let newStatus = !step.workflowStatus;
    let payload = {
      workflowStatus: newStatus,
      workflow_id: this.state.workflow_id
    };
    let res = await workflowService.toggleStepStatus(step.workflowStep_id, payload);
    if (res) {
      this.getAllWorkFlowSteps();
      (newStatus) ?
        toast.info(alertConstants.ACTIVATED_SUCESSFULLY) :
        toast.info(alertConstants.DEACTIVATED_SUCCESSFULLY)
    }
  }
  stepSelectHandler = (step) => {
    this.setState({ isEdit: true })
    let selectedStep = [...this.state.selectedStep];
    let selectedIndex = selectedStep.indexOf(step);
    if (selectedIndex == -1) {
      selectedStep = [step];
      this.setState({
        workflowStepName: step.workflowStepName,
        approvalAction: step.approvalAction,
        initialState: step.initialState,
        Role: step.worlkflowRole,
        emailToEmployee: step.emailToEmployee,
        exitWithBlockingException: step.exitWithBlockingException,
        selectedRole: step.worlkflowRole.master_value.master_name_value,
        selectedinitialState: step.initialState,
        editApprovalActionOptions: step.approvalAction.map(item => item.master_value_id)
      })
    } else {
      selectedStep = [];
    }
    this.setState({ selectedStep });
  }
  // * move the step up or down
  moveStep = async (step, movement) => {
    let oldWorkflowOrderNo = step.workflowOrderNo;
    let workflowOrderNo = 0;
    if (movement == 'down') {
      workflowOrderNo = oldWorkflowOrderNo + 1;
    } else if (movement == 'up') {
      workflowOrderNo = oldWorkflowOrderNo - 1;
    }
    let payload = {
      workflow_id: this.state.workflow_id,
      oldWorkflowOrderNo,
      workflowOrderNo
    }
    let res = await workflowService.moveWorkflowStepDown(step.workflowStep_id, payload);
    if (res) {
      this.getAllWorkFlowSteps();
    }
  }
  removeWorkflowStep = async (e) => {
    e.preventDefault();
    let res = await workflowService.removeWorkflowStep(this.state.selectedStep[0].workflowStep_id);
    if (res) {
      toast.success(alertConstants.DELETED_SUCCESSFULLY);
      this.getAllWorkFlowSteps();
      this.handleReset();
    }
  }
  // * handle create Step
  handleCreate = async (e) => {
    e.preventDefault();
    let payload = {
      companyId: this.state.companyId,
      workflow_id: this.state.workflow_id,
      workflowStepName: this.state.workflowStepName,
      worlkflowRole: this.state.Role,
      initialState: this.state.selectedinitialState,
      approvalAction: this.state.approvalAction,
      emailToEmployee: this.state.emailToEmployee,
      exitWithBlockingException: this.state.exitWithBlockingException

    }
    if (this.validator.allValid()) {
      let res = await workflowService.createWorkflowStep(payload);
      if (res) {
        toast.success(alertConstants.CREATED_SUCCESSFULLY);
        $('.close').click();
        this.handleReset();
        this.getAllWorkFlowSteps();
      }
    } else {
      this.validator.showMessages();
      this.setState({ ...this.state })
    }
  }
  // * Update handler
  handleUpdate = async (e) => {
    let payload = {
      companyId: this.state.companyId,
      workflow_id: this.state.workflow_id,
      workflowStepName: this.state.workflowStepName,
      worlkflowRole: this.state.Role,
      initialState: this.state.selectedinitialState,
      approvalAction: this.state.approvalAction,
      emailToEmployee: this.state.emailToEmployee,
      exitWithBlockingException: this.state.exitWithBlockingException
    }
    let res = await workflowService.updateWorkflowStep(this.state.selectedStep[0].workflowStep_id, payload);
    if (res) {
      toast.success(alertConstants.UPDATED_SUCCESSFULLY);
      $('.close').click();
      this.handleReset();
      this.getAllWorkFlowSteps();
    }
  }
  handleReset = () => {
    this.setState({
      isEdit: false,
      selectedStep: [],
      workflowStepName: "",
      approvalAction: "",
      initialState: "",
      Role: "",
      emailToEmployee: false,
      exitWithBlockingException: false,
      selectedRole: "",
      selectedinitialState: "",
      editApprovalActionOptions: "",
    })
  }
  render() {
    return (
      <div>
        <WorkflowsStep2Page
          state={this.state}
          handleDropdownChange={this.handleDropdownChange}
          handleToggleChange={this.handleToggleChange}
          handleInputChange={this.handleInputChange}
          handleApprovalChange={this.handleApprovalChange}
          handleCreate={this.handleCreate}
          handleUpdate={this.handleUpdate}
          stepStatusToggleHandler={this.stepStatusToggleHandler}
          stepSelectHandler={this.stepSelectHandler}
          moveStep={this.moveStep}
          removeWorkflowStep={this.removeWorkflowStep}
          handleInitialStatus={this.handleInitialStatus}
          error={this.validator}
          reset={this.handleReset}
        />
      </div>
    )
  }
}
export default WorkflowsStep2;
