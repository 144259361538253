import React from "react";
import { NavLink } from "react-router-dom";
import Select from "../../../common/_forms/select";
const expenseFilter = props => {
  return (
    <form onSubmit={props.onSubmitHandler}>
      <div className="row">
        <div className="col-sm-9">
          <div className="btn-group-margin">
            <NavLink to="./reports" className="btn mb-2 mb-md-0">
              New Report
            </NavLink>
            {/* <div className="dropdown">
              <button
                type="button"
                className="btn dropdown-toggle"
                data-toggle="dropdown"
              >
                {props.selected}
              </button>
              <div className="dropdown-menu">
                <button
                  onClick={e => props.onSelectHandler("This Month")}
                  className="dropdown-item"
                >
                  This Month
                </button>
                <button
                  onClick={e => props.onSelectHandler("This Week")}
                  className="dropdown-item"
                >
                  This Week
                </button>
                <button
                  onClick={e => props.onSelectHandler("Today")}
                  className="dropdown-item"
                >
                  Today
                </button>
              </div>
            </div>
            <div className="dropdown">
              <button
                type="button"
                className="btn dropdown-toggle"
                data-toggle="dropdown"
              >
                Select
              </button>
              <div className="dropdown-menu">
                <button
                  type="button"
                  className="dropdown-item"
                >
                  Pending Submission
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                >
                  Rejected
                </button>
                <button
                  type="button"
                  className="dropdown-item"
                >
                  Approved
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-sm-3">
          <div className="search-custom">
            <input
             onChange={(evt)=>props.onSearchHandler(evt.target.value)}
              type="text"
              name="searchText"
              className="form-control"
              placeholder="Search"
            />
            <span >
              <i className="material-icons">search</i>
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default expenseFilter;
