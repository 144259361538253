import { Component } from "react";


class RentalPage extends Component {

  render() {
    return (
      <div>
        <div className="p-padding">
          <div className="summary active">
            <div className="d-inline-block text-center">
              <p>
                <i className="material-icons">
                  directions_car
              </i>
              </p>
            </div>
            <div className="d-inline-block">
              <p className="title">
                Bangalore to Mysore
            </p>
              <p className="label">100 results found</p>
            </div>
          </div>
          <div className="clearfix"></div>

          <div className="row">
            <div className="col-md-10">
              <div className="devision-container heading">
                <div className="devision">
                  <div className="details main">
                    Vehicle
                  <span>
                      <i className="material-icons sort">arrow_drop_down</i>
                      <i className="material-icons sort">arrow_drop_up</i>
                    </span>
                  </div>
                  <div className="details amenities-cab">Refund</div>
                  <div className="details date">
                    Pickup
                  <span>
                      <i className="material-icons sort">arrow_drop_down</i>
                      <i className="material-icons sort">arrow_drop_up</i>
                    </span>
                  </div>
                  <div className="details date">
                    Dropoff
                  <span>
                      <i className="material-icons sort">arrow_drop_down</i>
                      <i className="material-icons sort">arrow_drop_up</i>
                    </span>
                  </div>
                  <div className="details price text-right">
                    Price
                  <span>
                      <i className="material-icons sort">arrow_drop_down</i>
                      <i className="material-icons sort">arrow_drop_up</i>
                    </span>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>

              <div className="devision-container">
                <div className="top-heading">
                  <p className="float-left show-white border-right-white">Car Details</p>
                  <p className="right blue cursor-pointer">
                    <i className="material-icons fs-14 align-sub">
                      directions_car
                                      </i> Fare Details
                                  </p>
                  <div className="clearfix"></div>
                </div>
                <div className="devision">
                  <div className="abs-right abs-right-cab">
                    <i className="material-icons">arrow_drop_up</i>
                  </div>
                  <div>
                    <div>
                      <div className="div-pad cursor-pointer">
                        <div className="details main"><div className="name">
                          <div className="logo-cab">
                            <img src="/assets/images/booking/car.jpg" alt="car" />
                          </div>
                          <div className="info-cab pt-3">
                            <p className="text-uppercase"><b>Sedan</b></p>
                            <p className="timings">
                              <b>Volvo XC90 </b>
                            </p>
                            <p className="class">5 People | 4 Door | A/C</p>
                          </div>
                          <div className="clearfix">
                          </div>
                        </div>
                        </div>
                        <div className="details amenities-cab p-more">
                          <ul className="ul-bullets pt-3">
                            <li>
                              <p className="link">Refundable</p>
                            </li>
                          </ul>
                        </div>
                        <div className="details date p-more">
                          <div className="pt-3">
                            <b>Bangalore</b>
                            <br /> UB Tower, Vital Main Road 
                          </div>
                        </div>
                        <div className="details date p-more">
                          <div className="pt-3">
                            <b>Mysore</b>
                            <br />#48 3rd Cross Some Awesome Building
                          </div>
                        </div>
                        <div className="details price price-cab-top">
                          <div className="flight-price ">
                            <div className="cash">
                              <span>
                                <h5>INR 4476</h5>
                                <p>Per Day</p>
                              </span>
                            </div>
                            <div className="d-button">
                              <i className="material-icons">chevron_right</i>
                              Add
                                                          </div>
                          </div>
                        </div>
                        <div className="clearfix">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div id="filter-card" className="filter-card">
                <button
                  id="filter-toggle"
                  className="filter-toggle"
                >
                  <i className="material-icons">close</i>
                </button>
                <h6 className="heading grey">Advanced Settings</h6>

                <div className="cab-filter">
                  <div className="heading">
                    <h6 className="mb-0"><b>Preferred Vendor</b></h6>
                  </div>
                  <div className="bg-grey">
                    <ul>
                      <li>All</li>
                      <li>Zoom Car</li>
                      <li>Meru</li>
                      <li>Mega Cabs</li>
                      <li>Ola Rentals</li>
                    </ul>
                  </div>
                  <div className="heading">
                    <h6 className="mb-0"><b>Preferred Vendor</b></h6>
                  </div>
                  <div className="bg-grey">
                    <ul>
                      <li>All</li>
                      <li>Zoom Car</li>
                      <li>Meru</li>
                      <li>Mega Cabs</li>
                      <li>Ola Rentals</li>
                    </ul>
                  </div>
                  <div className="heading">
                    <h6 className="mb-0"><b>Preferred Vendor</b></h6>
                  </div>
                  <div className="bg-grey">
                    <ul>
                      <li>All</li>
                      <li>Zoom Car</li>
                      <li>Meru</li>
                      <li>Mega Cabs</li>
                      <li>Ola Rentals</li>
                    </ul>
                  </div>
                  <div className="heading">
                    <h6 className="mb-0"><b>Preferred Vendor</b></h6>
                  </div>
                  <div className="bg-grey">
                    <ul>
                      <li>All</li>
                      <li>Zoom Car</li>
                      <li>Meru</li>
                      <li>Mega Cabs</li>
                      <li>Ola Rentals</li>
                    </ul>
                  </div>
                  <div className="text-center py-3">
                    <button className="btn px-5">Apply</button>
                  </div>
                </div>

              </div>
            </div>
          </div>



        </div>
      </div>
    );
  }
}

export { RentalPage };
