import React,{Component} from 'react';
import Loader from 'src/_components/common/Loader';

class ExpenseReportUserInfoModel extends Component{
    render(){
        return(
            <div
            className="modal fade"
            id="reportTotalModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="reportTotalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-head">
                  <h6 className="modal-subtitle">View</h6>
                  <h5 className="modal-title" id="reportTotalLabel">
                    User Info
                  </h5>
                </div>
                {!this.props.isTotalLoading ?
                  <div className="modal-middle pm-30">
                    <div className="modal-scroll">
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th className="border-0"></th>
                              <th className="border-0" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-0">Report Total</td>
                              <td className="text-right border-0">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals.reportTotal !==
                                  null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals.reportTotal
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Less Personal Amount</td>
                              <td className="text-right border-0">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals
                                    .lessPersonalAmount !== null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals
                                      .lessPersonalAmount
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td>Amount Claimed </td>
                              <td className="text-right">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals.amountClaimed !==
                                  null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals
                                      .amountClaimed
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Amount Rejected</td>
                              <td className="text-right border-0">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals
                                    .amountRejected !== null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals
                                      .amountRejected
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Amount Approved</td>
                              <td className="text-right border-0">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals
                                    .amountApproved !== null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals
                                      .amountApproved
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table  mb-0">
                          <thead>
                            <tr>
                              <th className="border-0">Company Disbursements</th>
                              <th className="border-0" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-0">Amount Due Employee</td>
                              <td className="text-right border-0">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals
                                    .amountDueEmployee !== null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals
                                      .amountDueEmployee
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Cash Advance Utilised</td>
                              <td className="text-right border-0">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals
                                    .cashAdvanceUtilized !== null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals
                                      .cashAdvanceUtilized
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td>Company Paid to Vendor</td>
                              <td className="text-right">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals
                                    .companyPaidtoCreditCard !== null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals
                                      .companyPaidtoCreditCard
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>

                            <tr>
                              <td>Total Paid By Company</td>
                              <td className="text-right">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals
                                    .totalPaidByCompany !== null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals
                                      .totalPaidByCompany
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="border-0">Employee Disbursements</th>
                              <th className="border-0" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-0">Amount Due Company</td>
                              <td className="text-right border-0">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals
                                    .amountDueCompany !== null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals
                                      .amountDueCompany
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Amount Due Credit Card</td>
                              <td className="text-right border-0">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals
                                    .amountDueCreditCard !== null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals
                                      .amountDueCreditCard
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td className="border-0">Total Paid By Employee</td>
                              <td className="text-right border-0">
                                {this.props.userBaseCurrency}&nbsp;
                            {this.props.totalReportAmounts &&
                                  this.props.totalReportAmounts.totals &&
                                  this.props.totalReportAmounts.totals
                                    .totalPaidByEmployee !== null
                                  ? parseFloat(
                                    this.props.totalReportAmounts.totals
                                      .totalPaidByEmployee
                                  ).toFixed(2)
                                  : "0.00"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="modal-last btn-group-margin">
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        Close
                    </button>
                    </div>
                  </div>

                  :
                  <div className="p-padding">
                    <div className="form-holder">
                      <Loader />
                    </div>
                  </div>
                }

              </div>
            </div>
          </div>
        )
    }
}

export default ExpenseReportUserInfoModel;
