import React from 'react';
import DynamicFields from 'src/_components/common/dynamicFields';
const expenseEntryPdf =(props)=>{
    let entryColumns =  ["TRANSACTION_DATE", "PAYMENT_TYPE", "CURRENCY", "AMOUNT", "LOCATION_NAME", "PERSONAL_EXPENSE", "TAX_TYPE", "BUSINESS_DISTANCE", "VEHICLE_TYPE", "RECEIPT_AVAILABLE"]
    let thead = props.entryForm.filter((fields)=>entryColumns.includes(fields.columnName)===true);
    let currency = thead.find((fields)=>fields.columnName === "CURRENCY");
    return(
        <table style={{ width: "100%", fontSize: "12px", textAlign: "left", verticalAlign: "middle", marginTop: "15px" }}>
        <thead>
            <tr>
            {thead.map((fields,index)=>{
                     if(fields.columnName !== 'CURRENCY'){
                        return(<th key={fields.id+index}>
                            <p style={{ marginBottom: "8px", textTransform: "uppercase", color: "#a7a9c0", fontWeight: "400" }}>{fields.labelName}
                        </p>
                        </th>)
                     }
                   })
                     }
            </tr>
        </thead>
        <tbody>
            <tr>
                {thead.map((fields)=>{
                    if(fields.dataType === DynamicFields.DATA_TYPE_BOOLEAN || fields.dataType === DynamicFields.DATA_TYPE_CHECKBOX){
                        return(
                            <td key={fields.id}>
                                <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5" }}>
                                {fields.fieldValue?'Yes':'No'}
                                </p>
                            </td>
                        )
                    }else if(fields.columnName === "AMOUNT"){
                        return(
                            <td key={fields.id}>
                                <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5" }}>
                                {currency.fieldValue && currency.fieldValue}{" "} {(new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(fields.fieldValue?fields.fieldValue:0))}
                                </p>
                            </td>
                        )
                    }else if(fields.columnName !== "CURRENCY"){
                        return(
                           <td key={fields.id}>
                           <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5" }}>
                           {fields.fieldValue?fields.fieldValue:'-'}
                           </p>
                       </td>
                        )
                    }
            })
                }
            </tr>
        </tbody>
    </table>
    )
}

export default expenseEntryPdf;