import React from "react";
const ComboFlightExpand = props => {
  const {
    // changeWay,
    baggageInfo,
    // type,
    // items,
    getTimes,
    keys,
    airport,
    // viewDetail,
    // detailId,
    // arrowId,
    currency,
    from,
    // addToCart,
    // selectedItem,
    to,
    // tripType,
    index,
    list
  } = props;
  // return list.map((item, itemIndex) => {
  return (
    <div
      className="more-details collapse"
      key={index + keys}
      id={"collapseOne" + keys + index}
      aria-labelledby={"headingOne" + index + keys}
      data-parent={"#accordion"}
    >
      {/* {itemIndex === 0 && ( */}
      <ul id="myTab" role="tablist" className="nav nav-tabs">
        <li className="nav-item">
          <button
            id="Details-tab"
            data-toggle="tab"
            href={"#Details" + index + keys}
            role="tab"
            aria-controls="Details"
            aria-selected="true"
            className="btn nav-link active"
          >
            Details
          </button>
        </li>
        {/* <li>
          <button
            id="Fares-tab"
            data-toggle="tab"
            href={"#Fares" + index + keys}
            role="tab"
            aria-controls="Fares"
            aria-selected="true"
            className="btn nav-link"
          >
            Fare And Cancellation
          </button>
        </li>
        <li>
          <button
            id="Photos-tab"
            data-toggle="tab"
            href={"#Photos" + index + keys}
            role="tab"
            aria-controls="Photos"
            aria-selected="true"
            className="btn nav-link"
          >
            Photos
          </button>
        </li> */}
        {/* <li>
          <button
            id="Policy-tab"
            data-toggle="tab"
            href="#Policy"
            role="tab"
            aria-controls="Policy"
            aria-selected="true"
            className="btn nav-link"
          >
            Cancellation Policy
          </button>
        </li> */}
        {/* <li>
          <button
            id="Seat-tab"
            data-toggle="tab"
            href={"#Seat" + index + keys}
            role="tab"
            aria-controls="Seat"
            aria-selected="true"
            className="btn nav-link"
          >
            Seat Selection
          </button>
        </li> */}
      </ul>
      {/* )} */}
      <div className="tab-content" id="myTabContent">
        <div
          className="devision tab-pane fade show active"
          id={"Details" + index + keys}
          role="tabpanel"
          aria-labelledby="Details-tab"
        >
          {list.map((item, itemIndex) => {
            return (
              <div key={itemIndex}>
                <div className="devision-container">
                  <div className="details main">
                    <div className="name">
                      <div className="logo">
                        <img src={item.flight_image} alt="" />
                      </div>
                      <div className="info">
                        <p className="timings">
                          <b>
                            {item.flight_depature_time}-{" "}
                            {item.flight_arrival_time}|{" "}
                            <i className="material-icons fs-14 align-middle">
                              access_time
                            </i>{" "}
                            {getTimes(item.flight_duration)}
                          </b>
                        </p>
                        <p className="class">
                          {item && item.flight_operating_airline} -{" "}
                          {item && item.flight_airline}
                        </p>
                      </div>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="details total-time expand-timing">
                    <p>
                      <b>
                        {/* <i className="icon ion-md-time" /> 2:40m{" "} */}
                      </b>
                    </p>
                    <p>
                      <b>
                        {airport[item.flight_from_city] &&
                          airport[item.flight_from_city].c &&
                          airport[item.flight_from_city].c}{" "}
                        - {item.flight_from_city} - (
                        {airport[item.flight_from_city] &&
                          airport[item.flight_from_city].n &&
                          airport[item.flight_from_city].n}
                        )
                      </b>{" "}
                      to{" "}
                      <b>
                        {" "}
                        {airport[item.flight_to_city] &&
                          airport[item.flight_to_city].c &&
                          airport[item.flight_to_city].c}{" "}
                        - {item.flight_to_city} - (
                        {airport[item.flight_to_city] &&
                          airport[item.flight_to_city].n &&
                          airport[item.flight_to_city].n}
                        )
                      </b>
                    </p>
                  </div>
                  {/* <div className="details co2 p-more">
                            <i className="icon ion-md-cloudy" /> <br /> 2.5t
                          </div> */}
                  <div className="details economy text-right">
                    <p>{item.flight_depature_date_utc}</p>
                    <p>
                      <b>{item.class}</b>
                    </p>
                  </div>
                  <div className="clearfix" />
                  <div className="baggage">
                    <div className="row">
                      <div className="col-md-3">
                        <p className="icon-lg">
                          <i className="material-icons">work</i> Check In
                          Baggage{" "}
                          {baggageInfo.baggageInfo
                            ? baggageInfo.baggageInfo.check_in_baggae
                            : "- Unavailable"}
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p className="icon-lg">
                          <i className="material-icons">work</i> Baggage{" "}
                          {baggageInfo.baggageInfo
                            ? baggageInfo.baggageInfo.cabin_baggage
                            : "- Unavailable"}
                        </p>
                      </div>
                      {item.flight_departure_terminal && (
                        <div className="col-md-3">
                          <p className="icon-lg">
                            <i className="material-icons">flight</i> Departure
                            Terminal &nbsp; {item.flight_departure_terminal}
                          </p>
                        </div>
                      )}
                      {item.flight_arrival_terminal && (
                        <div className="col-md-3">
                          <p className="icon-lg">
                            <i className="material-icons rotate180">flight</i>{" "}
                            Arrival Terminal &nbsp;{" "}
                            {item.flight_arrival_terminal}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="amenities-more">
                    <h6>Amenities</h6>
                    <p
                      className="d-inline-block"
                      data-toggle="tooltip"
                      title="Average Leg Room for all flights"
                    >
                      <span className="available yes">
                        <i className="icon ion-md-walk" />
                      </span>{" "}
                      Standard Leg Room (76 cm)
                    </p>
                    <p
                      className="d-inline-block"
                      data-toggle="tooltip"
                      title="No Wifi Available"
                    >
                      <span className="available">
                        <i className="icon ion-md-wifi" />
                      </span>{" "}
                      No Wifi
                    </p>
                    <p
                      className="d-inline-block"
                      data-toggle="tooltip"
                      title="No Power Oulet"
                    >
                      <span className="available">
                        <i className="icon ion-md-power" />
                      </span>{" "}
                      No Power Outlet
                    </p>
                    <p
                      className="d-inline-block"
                      data-toggle="tooltip"
                      title="Standard Layout 3-3  Layout"
                    >
                      <span className="available yes">
                        <i className="icon ion-md-more" />
                      </span>{" "}
                      Standard 3-3 Layout
                    </p>
                    <p
                      className="d-inline-block"
                      data-toggle="tooltip"
                      title="No Entertainment"
                    >
                      <span className="available">
                        <i className="icon ion-md-tv" />
                      </span>{" "}
                      No Entertainment
                    </p>
                    <h6 className="mt-2">Ancillary Included</h6>
                    <div className="amen">
                      <button type="button" className="btn btn-border-basic">
                        <i className="icon ion-md-wifi" />
                      </button>
                      <button type="button" className="btn btn-border-basic">
                        <i className="icon ion-md-bonfire" />
                      </button>
                      <button type="button" className="btn btn-border-basic">
                        <i className="icon ion-md-cafe" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-border-basic btn-add"
                      >
                        <i className="icon ion-md-add" /> Add
                      </button>
                    </div>
                  </div>
                 */}
                </div>
                {list.length !== itemIndex + 1 && (
                  <div className="flight-divider">
                    <div>Change Flight</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div
          className="tab-pane fade"
          id={"Fares" + index + keys}
          role="tabpanel"
          aria-labelledby="Fares-tab"
        >
          <div className="devision p-3">
            <h5 className="sub-heading">
              Fare Rules{" "}
              <b>
                {from} - {to}{" "}
              </b>{" "}
              <small>All charges are per passenger</small>
            </h5>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>
                      Cancellation fee
                      <br />
                      (Airline Fee)
                    </th>
                    <th>
                      DATE CHANGE FEES
                      <br />
                      (Airline Fee + Fare Difference)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>0 hours to 24 hours</td>
                    <td>ADULT : Non Refundable</td>
                    <td>ADULT : Non Changeable</td>
                  </tr> */}
                  <tr>
                    <td>0 hours to 24 hours</td>
                    <td>
                      ADULT :
                      {list[index] && list[index].flight_rules
                        ? list[index].flight_rules.cancel_price &&
                          list[index].flight_rules.cancel_price
                          ? currency +
                            " " +
                            list[index].flight_rules.cancel_price
                            ? new Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(list[index].flight_rules.cancel_price)
                            : 0.0
                          : "Unavailable"
                        : "Unavailable"}
                    </td>
                    <td>
                      ADULT :
                      {list[index] && list[index].flight_rules
                        ? list[index].flight_rules.reschedule_price &&
                          list[index].flight_rules.reschedule_price
                          ? currency +
                            " " +
                            list[index].flight_rules.reschedule_price
                            ? new Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(
                                list[index].flight_rules.reschedule_price
                              )
                            : 0.0
                          : "Unavailable"
                        : "Unavailable"}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>4 hours to 24 hours</td>
                    <td>ADULT : {currency} 3,000 + {currency} 300</td>
                    <td>ADULT : {currency} 2,500 + {currency} 300 + Fare difference</td>
                  </tr> */}

                  {/* <tr>
                    <td>24 hours to 365 days</td>
                    <td>ADULT : {currency} 3,000 + {currency} 300</td>
                    <td>ADULT : {currency} 2,500 + {currency} 300 + Fare difference</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className="devision p-3 tab-pane fade"
          id={"Photos" + index + keys}
          role="tabpanel"
          aria-labelledby="Photos-tab"
        >
          <div className="bg-white text-center py-5 my-2">
            <h6 className="mb-0">
              There are no photos available. Kindly check airline website.
            </h6>
          </div>
          {/* <div className="row">
            <div className="col-md-3">
              <div className="images-text">
                <button>
                  <img src="/assets/images/booking/cabin_1.png" alt="" />
                </button>
                <h6>Enjoy the biggest seat on the A380</h6>
              </div>
            </div>
            <div className="col-md-3">
              <div className="images-text">
                <button>
                  <img src="/assets/images/booking/cabin_2.png" alt="" />
                </button>
                <h6>Tasty meals for your whole family</h6>
              </div>
            </div>
            <div className="col-md-3">
              <div className="images-text">
                <button>
                  <img src="/assets/images/booking/cabin_3.png" alt="" />
                </button>
                <h6>Enjoy our generous baggage allowance</h6>
              </div>
            </div>
            <div className="col-md-3">
              <div className="images-text">
                <button>
                  <img src="/assets/images/booking/cabin_4.png" alt="" />
                </button>
                <h6>Fly with our international Cabin Crew</h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="images-text">
                <button>
                  <img src="/assets/images/booking/cabin_5.png" alt="" />
                </button>
                <h6>Enjoy a wider screen in the A380 cabin</h6>
              </div>
            </div>
            <div className="col-md-3">
              <div className="images-text">
                <button>
                  <img src="/assets/images/booking/cabin_6.png" alt="" />
                </button>
                <h6>Boredom is grounded always</h6>
              </div>
            </div>
            <div className="col-md-3">
              <div className="images-text">
                <button>
                  <img src="/assets/images/booking/cabin_7.png" alt="" />
                </button>
                <h6>A regionally inspired multicourse meal</h6>
              </div>
            </div>
            <div className="col-md-3" />
          </div>
         */}
        </div>

        {/* <div
          className="devision p-3 tab-pane fade"
          id="Policy"
          role="tabpanel"
          aria-labelledby="Policy-tab"
        >
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="border-top-0">TIME</th>
                  <th className="border-top-0">CANCELLATION FEES </th>
                  <th className="border-top-0">DATE CHANGE FEES</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>24 HRS - 365 days</td>
                  <td>Adult: $1,838* + $108**</td>
                  <td>Adult: $538* + $48** + fare difference</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pl-2 mt-3">
            <p> *Airline Fee. | **Curio Cancellation Service Fee.</p>
          </div>
        </div> */}

        <div
          className="seat-selection tab-pane fade"
          id={"Seat" + index + keys}
          role="tabpanel"
          aria-labelledby="Seat-tab"
        >
          <div className="not-av">
            <div className="d-t">
              <div className="d-middle">
                Seat booking unavailable for this flight
              </div>
            </div>
          </div>
          <div className="devision">
            <div className="seat-info">
              <div className="portion">
                <img src="/assets/images/booking/plane.svg" alt="" />
                <button type="button" className="front" />
                <button type="button" className="middle" />
                <button type="button" className="back" />
              </div>
              <div className="info">
                <ul>
                  <li>
                    <button type="button" className="seat assigned">
                      <i className="icon ion-md-checkmark" />
                    </button>
                    <span className="name">Assigned</span>
                  </li>
                  <li>
                    <button type="button" className="seat free" />
                    <span className="name">Free</span>
                  </li>
                  <li>
                    <button type="button" className="seat occupied" />
                    <span className="name">occupied</span>
                  </li>
                  <li>
                    <button type="button" className="seat paid-xl" />
                    <span className="name">XL Paid Seat Rs. 800</span>
                  </li>
                  <li>
                    <button type="button" className="seat paid-lg" />
                    <span className="name">XL Paid Seat Rs. 600</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <button type="button" className="seat paid-md" />
                    <span className="name">Paid Seat Rs. 400</span>
                  </li>
                  <li>
                    <button type="button" className="seat paid-sm" />
                    <span className="name">Paid Seat Rs. 350</span>
                  </li>
                  <li>
                    <button type="button" className="seat paid-xs" />
                    <span className="name">Paid Seat Rs. 200</span>
                  </li>
                  <li>
                    <button type="button" className="seat non-rec" />
                    <span className="name">Non Reclining Seat</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="devision white pr-0">
            <div className="seat-container">
              <div className="seat-select">
                <div className="top">
                  <button className="seat nothing">F</button>
                  <button className="seat nothing">E</button>
                  <button className="seat nothing">D</button>
                </div>
                <div className="mid" />
                <div className="bottom">
                  <button className="seat nothing">C</button>
                  <button className="seat nothing">B</button>
                  <button className="seat nothing">A</button>
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat paid-sm"
                    data-toggle="tooltip"
                    title="Paid Seat Rs. 350"
                  >
                    $
                  </button>
                  <button
                    className="seat assigned"
                    data-toggle="tooltip"
                    title="Assigned"
                  />
                  <button
                    className="seat free"
                    data-toggle="tooltip"
                    title="free"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">1</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat paid-xl"
                    data-toggle="tooltip"
                    title="XL Paid Seat Rs. 800"
                  >
                    $
                  </button>
                  <button
                    className="seat paid-lg"
                    data-toggle="tooltip"
                    title="XL Paid Seat Rs. 600"
                  >
                    $
                  </button>
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat paid-md"
                    data-toggle="tooltip"
                    title="Paid Seat Rs. 400"
                  >
                    $
                  </button>
                  <button
                    className="seat paid-sm"
                    data-toggle="tooltip"
                    title="Paid Seat Rs. 350"
                  >
                    $
                  </button>
                  <button
                    className="seat paid-xs"
                    data-toggle="tooltip"
                    title="Paid Seat Rs. 200"
                  >
                    $
                  </button>
                </div>
                <div className="mid">
                  <button className="seat nothing">2</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied non-rec"
                    data-toggle="tooltip"
                    title="Non Reclining Seats"
                  />
                  <button
                    className="seat assigned non-rec"
                    data-toggle="tooltip"
                    title="Non Reclining Seats"
                  />
                  <button
                    className="seat paid-md non-rec"
                    data-toggle="tooltip"
                    title="Non Reclining Seats"
                  >
                    $
                  </button>
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">3</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">4</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">5</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">6</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">7</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">8</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">9</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">10</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">11</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">12</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">13</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">14</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">15</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">16</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">17</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">18</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">19</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">20</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">21</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">22</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">23</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">24</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">25</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">26</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">27</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">28</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">29</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">30</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">31</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">32</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">33</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
              <div className="seat-select">
                <div className="top">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
                <div className="mid">
                  <button className="seat nothing">34</button>
                </div>
                <div className="bottom">
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                  <button
                    className="seat occupied"
                    data-toggle="tooltip"
                    title="Occupied"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // });
};
export default ComboFlightExpand;
