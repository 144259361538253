import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

import ExpenseTypeLimitsPage from "./expense-type-limits-html";

import { expenseServices, masterService } from "../../../../../../../_services";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../../../_constants/alert.constants";
import $ from "jquery";
class ExpenseTypeLimits extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.validator = new SimpleReactValidator();
    this.state = {
      companyId: localStorage["440a28"],
      expenseTypeId: "",
      appliesToGroup: "",
      currencyCode: "",
      warningAmount: 0,
      blockingAmount: 0,
      receiptRequiredAmount: 0,
      mode: "",
      allExpenseType: [],
      allExpenseTypeLimits: [],
      allAppliesToList: [],
      allCurrencyCode: []
    };
    this.loading = false;
  }
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
  activePage = 1;
  itemsPerPage = 10;
  search = "";
  componentDidMount() {
    this.getAllExpenseTypeLimits();
    this.getExpenseType();
    // this.getCountry();
    this.getAppliesToList();
    this.getAllCurrencyCode();
    this.getExpenseCategory();

    //
  }

  // * get all expense type limits
  getAllExpenseTypeLimits = async () => {
    let resp = await expenseServices.getExpenseTypeLimit(
      this.state.companyId,
      this.itemsPerPage,
      this.activePage
    );
    if (resp) {
      this.setState({ allExpenseTypeLimits: resp.result });
    } else {
      this.setState({ allExpenseTypeLimits: [] });
    }
  };

  //  * end of expense type limits

  // * Get all applies to list
  getAppliesToList = async () => {
    let resp = await expenseServices.getAppliesToList(this.state.companyId);
    if (resp) {
      // console.log(resp.result);

      this.setState({
        allAppliesToList: resp.result
      });
    } else {
      console.log("Nothing was found.");
    }
  };

  //  * End of applies to

  // * Start of get alll currency code

  getAllCurrencyCode = async () => {
    let resp = await expenseServices.getAllCurrencyCode(this.state.companyId);
    if (resp) {
      this.setState({
        allCurrencyCode: resp.result
      });
    } else {
      this.setState({
        allCurrencyCode: [
          {
            level: 1,
            isAdmin: true,
            isVisible: true,
            isStatic: true,
            isFolder: false,
            activeStatus: true,
            companyId: "5c97871cdfc4ec08f1049ba0",
            parentId: "5c98738b79263f39678a38b0",
            itemCode: "EUR",
            itemName: "European Euro",
            id: "5c98740879263f39678a38b1"
          },
          {
            level: 1,
            isAdmin: true,
            isVisible: true,
            isStatic: true,
            isFolder: false,
            activeStatus: true,
            companyId: "5c97871cdfc4ec08f1049ba0",
            parentId: "5c98738b79263f39678a38b0",
            itemCode: "USD",
            itemName: "UnitedStates",
            id: "5c98745d79263f39678a38b2"
          }
        ]
      });
    }
  };

  //  * End of get all currency code

  listCountries = [];
  getCountry = async () => {
    var resp = await masterService.getCountries();
    if (resp) {

      this.listCountries = resp.result;
    } else {
      this.listCountries = [];
    }
  };
  // getListExpenseType = [];
  // * get all the expense types
  getExpenseType = async () => {
    var resp = await expenseServices.getExpenseTypesOne(this.state.companyId);
    if (resp) {
      // console.log("get expense type ", resp);

      this.setState({
        allExpenseType: resp.result
      });
    } else {
      this.setState({ allExpenseType: [] });
    }
  };

  // * End of all expense types

  // * Start of submit function
  addSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {

      // this.loading = true;
      let payload = {
        companyId: this.state.companyId,
        expenseTypeId: this.state.expenseTypeId,
        appliesToGroup: this.state.appliesToGroup,
        currencyCode: this.state.currencyCode,
        warningAmount: +this.state.warningAmount,
        blockingAmount: +this.state.blockingAmount,
        receiptRequiredAmount: +this.state.receiptRequiredAmount
      };
      // console.log("warning Amount ", payload.warningAmount);
      // console.log("blocking Amount ", payload.blockingAmount);

      if(payload.blockingAmount > payload.warningAmount) {

        var resp = await expenseServices.createExpenseTypeLimit(payload);
        if (resp) {
          toast.success(alertConstants.CREATED_SUCCESSFULLY);
          // this.loading = false;
          this.resetData();
          this.getAllExpenseTypeLimits();
          $(".close").click();
        }
      }else{
        toast.warn('Blocking Amount should be greater than Warning Amount ');
      }
    } else {
      // this.loading = false;
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  editSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.loading = true;
      let payload = {
        appliesToGroup: this.state.appliesToGroup,
        currencyCode: this.state.currencyCode,
        warningAmount: +this.state.warningAmount,
        blockingAmount: +this.state.blockingAmount,
        receiptRequiredAmount: +this.state.receiptRequiredAmount
      };
      var resp = await expenseServices.updateExpenseTypeLimit(
        this.choosedItem,
        payload
      );
      if (resp) {
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        this.loading = false;
        this.resetData();
        this.getAllExpenseTypeLimits();

        $(".close").click();
      }
    } else {
      this.loading = false;
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  // * Reset all the state data once the form is submitted
  resetData = () => {
    this.expenseName = "";
    this.currencyName = "";
    this.appliesName = "";
    this.setState({
      expenseTypeId: "",
      appliesToGroup: "",
      currencyCode: "",
      warningAmount: 0,
      blockingAmount: 0,
      receiptRequiredAmount: 0
    });
  };

  // * End of Reset function
  listExpenseCategory = [];
  getExpenseCategory = async () => {
    this.listExpenseCategory = [];
    this.count = 0;
    this.choosedItem = "";
    var resp = await expenseServices.getExpenseCategory(
      this.activePage,
      this.itemsPerPage,
      this.search,
      this.state.companyId
    );
    if (resp) {
      this.count = resp.count;
      this.listExpenseCategory = resp.result;
    }
    this.forceUpdate();
  };

  // * Stuff for pagination
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSearch = e => {
    this.search = e.target.value;
    this.getExpenseCategory();
  };
  handleItemChange = limit => {
    this.activePage = 1;
    this.itemsPerPage = limit["items"];
    this.getExpenseCategory();
  };
  handlePageChange = pageNumber => {
    this.activePage = pageNumber;
    this.getExpenseCategory();
  };
  handleStatusChange = async data => {
    let newStatus = !data.activeStatus;
    let payload = {
      activeStatus: newStatus
    };
    var resp = await expenseServices.statusExpenseTypeLimit(data.id, payload);
    toast.success(resp.message);
    this.getAllExpenseTypeLimits();
  };
  choosedItem = "";
  chooseEdit = e => {
    e === this.choosedItem ? (this.choosedItem = "") : (this.choosedItem = e);

    this.forceUpdate();
  };
  removeItem = async e => {

    var resp = await expenseServices.deleteExpenseTypeLimit(this.choosedItem);
    if (resp) {
      toast.info(resp.message);
      this.getAllExpenseTypeLimits();
    }
  };
  getData = () => {
    let allExpenseTypeLimits = [...this.state.allExpenseTypeLimits];
    allExpenseTypeLimits.forEach(item => {
      if (item.id === this.choosedItem) {

        this.expenseName = item.expenseTypeId.expenseTypeName;
        let matchedApplies = this.state.allAppliesToList.find(
          applyto => applyto.id == item.appliesToGroup
        );
        if (matchedApplies) {
          this.appliesName = matchedApplies.itemName;
        }

        let matchedCurrency = this.state.allCurrencyCode.find(
          currency => currency.id === item.currencyCode
        );
        if (matchedCurrency) {
          this.currencyName = matchedCurrency.itemCode;
        }

        // this.appliesName = item.itemName;
        // this.currencyName = item.itemName;

        this.setState({
          expenseTypeId: item.id,
          appliesToGroup: item.appliesToGroup,
          currencyCode: item.currencyCode,
          warningAmount: item.warningAmount,
          blockingAmount: item.blockingAmount,
          receiptRequiredAmount: item.receiptRequiredAmount
        });
      }
    });
  };
  close = () => {
    this.validator.hideMessages();
    this.choosedItem = "";
  };
  expenseName = "Expense Type";
  appliesName = "Applies To";
  currencyName = "Currency Code";
  handleSelectChange = (e, name) => {
    // console.log(e);
    // console.log(name);

    if (name === "expenseType") {
      this.expenseName = e.expenseTypeName;
      this.setState({
        expenseTypeId: e.id
      });
    }
    if (name === "appliesTo") {
      this.appliesName = e.itemName;
      this.setState({
        appliesToGroup: e.id
      });
    }
    if (name === "currency") {
      this.currencyName = e.itemName; //for now this is itemName, late rchange it
      this.setState({
        currencyCode: e.id
      });
    }

    // this.forceUpdate();
  };

  handleInputChange = (e, name) => {
    // console.log(typeof(e.target.value));
    // console.log(name);
    if (name === "warningAmount") {
      this.setState({
        warningAmount: e.target.value
      });
    }
    if (name === "blockingAmount") {
      this.setState({
        blockingAmount: e.target.value
      });
    }
    if (name === "receiptRequiredAmount") {
      this.setState({
        receiptRequiredAmount: e.target.value
      });
    }
  };
  render() {
    return (
      <div>
        <ExpenseTypeLimitsPage
          validator={this.validator}
          state={this.state}
          loading={this.loading}
          handleChange={this.handleChange}
          addSubmit={this.addSubmit}
          listExpenseCategory={this.listExpenseCategory}
          activePage={this.activePage}
          itemsPerPage={this.itemsPerPage}
          handleSearch={this.handleSearch}
          pageItemsList={this.pageItemsList}
          handleItemChange={this.handleItemChange}
          count={this.count}
          choosedItem={this.choosedItem}
          chooseEdit={this.chooseEdit}
          handleStatusChange={this.handleStatusChange}
          removeItem={this.removeItem}
          editSubmit={this.editSubmit}
          close={this.close}
          getData={this.getData}
          listCountries={this.listCountries}
          changeTypes={this.handleSelectChange}
          error={this.validator}
          currencyName={this.currencyName}
          expenseName={this.expenseName}
          appliesName={this.appliesName}
          handleInputChange={this.handleInputChange}
        />
      </div>
    );
  }
}
export default ExpenseTypeLimits;
