import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class Menu extends Component {
  render() {
    return (
      <div>
        <ul className="nav nav-tabs">
          <li>
            <NavLink to="/administration/expense/config" className="show">
              Configuration
            </NavLink>
          </li>
          <li>
            <NavLink to="/administration/expense/config-sets" className="show">
              Configuration Sets
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/administration/expense/hourly-allowance" className="show">
              Hourly Allowance
            </NavLink>
          </li> */}
        </ul>
      </div>
    );
  }
}
export default Menu;
