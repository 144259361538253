import { http } from "../_helpers";
import { config } from "../env";

const getConfigList = data => {
  return http
    .get(
      config.apiExpense +
        `allowance-configuration?companyId=${data.companyId}&limit=${
          data.limit
        }&page=${data.page}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getConfigSet = data => {
  return http
    .get(
      config.apiExpense +
        `allowance-configurationSet?companyId=${
          data.companyId
        }&configurationId=${data.configurationId}&limit=${data.limit}&page=${
          data.page
        }`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getEditableList = companyId => {
  return http
    .get(
      config.apiUrlCompany + `list-management?companyId=${companyId}&itemCode=GRP001`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getSelectedConfig = (companyId, id) => {
  return http
    .get(
      config.apiExpense +
        `allowance-configuration/${id}?companyId=${companyId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getCountriesList = () => {
  return http
    .get(config.apiUrlSuperAdmin + `countries`)
    .then(data => {
      return data ? data : "";
    });
};

const getStateList = (countryId) => {
  return http
    .get(config.apiUrlSuperAdmin + `states?countryId=${countryId}&isDependecyRequired=true`)
    .then(data => {
      return data ? data : "";
    });
};

const getCitiesList = (stateId,countryId) => {
  return http
    .get(config.apiUrlSuperAdmin + `cities?stateId=${stateId}&isDependecyRequired=true`)
    .then(data => {
      return data ? data : "";
    });
};

const getCurrencyList = companyId => {
  return http
    .get(
      config.apiUrlCompany + `list-management?companyId=${companyId}&itemCode=CUR001`
    )
    .then(data => {
      return data ? data : "";
    });
};

const searchConfig = data => {
  return http
    .get(
      config.apiExpense +
        `allowance-configuration/search/${data.search}?companyId=${
          data.companyId
        }&limit=${data.limit}&page=${data.page}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const searchConfigSet = data => {
  return http
    .get(
      config.apiExpense +
        `allowance-configurationSet?companyId=${
          data.companyId
        }&limit=${data.limit}&page=${data.page}&configurationId=${data.configurationId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const postConfig = data => {
  return http
    .post(config.apiExpense + "allowance-configuration", data)
    .then(data => {
      return data ? data : "";
    })
    .catch(() => "");
};

const postConfigSet = data => {
  return http
    .post(config.apiExpense + "allowance-configurationSet", data)
    .then(data => {
      return data ? data : "";
    })
    .catch(() => "");
};

const updateConfigStatus = (companyId, data) => {
  return http
    .patch(
      config.apiExpense +
        `allowance-configuration/active-status?companyId=${companyId}`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};

const updateConfigSetStatus = (companyId, data) => {
  return http
    .patch(
      config.apiExpense +
        `allowance-configurationSet/active-status?companyId=${companyId}`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};

const editConfig = data => {
  return http
    .patch(config.apiExpense + `allowance-configuration`, data)
    .then(data => {
      return data ? data : "";
    })
    .catch(() => "");
};
const editConfigSet = data => {
  return http
    .patch(config.apiExpense + "allowance-configurationSet", data)
    .then(data => {
      return data ? data : "";
    })
    .catch(() => "");
};

const deleteConfig=(id,data)=>{
  return http
  .patch(config.apiExpense + `allowance-configuration/delete?companyId=${id}`, data)
  .then(data => {
    return data ? data : "";
  })
}
const deleteConfigSet =(id,data)=>{
  return http
  .patch(config.apiExpense + `allowance-configurationSet/delete?companyId=${id}`, data)
  .then(data => {
    return data ? data : "";
  })

}
const createCongigurationSet=(data)=>{
  return http
    .post(config.apiExpense + "allowance-configurationSet", data)
    .then(data => {
      return data ? data : "";
    })
}
const createConfigurationAllowance=(data)=>{
  return http
  .post(config.apiExpense + "allowance-calculation-type", data)
  .then(data => {
    return data ? data : "";
  })
}
const getHourlyBasisAllowance=(companyId)=>{
  return http
    .get(
      config.apiExpense +`hourly-configuration?companyId=${companyId}`
    )
    .then(data => {
      return data ? data : "";
    });
}
const geteditCongigurationData=(confId,companyId)=>{
  return http
    .get(
      config.apiExpense +`allowance-configurationSet/${confId}?companyId=${companyId}`
    )
    .then(data => {
      return data ? data : "";
    });
}
const updateAllowanceConfig=(data)=>{
  return http
  .patch(config.apiExpense + `allowance-configurationSet`, data)
  .then(data => {
    return data ? data : "";
  })
}
export const expenseTravelAllowanceServise = {
  getConfigList,
  getConfigSet,
  postConfig,
  searchConfig,
  searchConfigSet,
  getEditableList,
  getSelectedConfig,
  editConfig,
  postConfigSet,
  getCountriesList,
  getCurrencyList,
  getCitiesList,
  updateConfigStatus,
  editConfigSet,
  deleteConfig,
  updateConfigSetStatus,
  deleteConfigSet,
  getStateList,
  createCongigurationSet,
  getHourlyBasisAllowance,
  createConfigurationAllowance,
  geteditCongigurationData,
  updateAllowanceConfig
};
