import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import ExpenseTypesMenu from "../expense-types-menu";
const ExpenseTypeStep3Page = props => {
  let data = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">R</div>
          <div className="right">
            <h6 className="heading">Expense Types</h6>
            <p className="text">Lorem Ipsum is simply dummy</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <ExpenseTypesMenu />
          <div className="table-container">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Policy Name</th>
                    <th>Amount Calculation formula </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>CA Expense Policy</td>
                    <td />
                  </tr>
                  <tr>
                    <td>standard central reconcolation policy</td>
                    <td />
                  </tr>
                  <tr>
                    <td>UK Expense Policy</td>
                    <td />
                  </tr>
                  <tr>
                    <td>IN Expense Policy</td>
                    <td />
                  </tr>
                  <tr>
                    <td>Expense Expense Policy</td>
                    <td />
                  </tr>
                  <tr>
                    <td>Domestic Expense Policy</td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          10
                        </button>
                        <div className="dropdown-menu">
                          <button type="button" className="dropdown-item">
                            10
                          </button>
                          <button type="button" className="dropdown-item">
                            20
                          </button>
                          <button type="button" className="dropdown-item">
                            30
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <Pagination
                    activePage={data.active}
                    itemsCountPerPage={10}
                    totalItemsCount={10}
                    pageRangeDisplayed={3}
                    onChange={data.handlePageChange}
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="btn-group-margin">
                  <NavLink
                    to="/administration/expense/expense-types-step4"
                    className="btn btn-border-success lg-padding"
                  >
                    Next
                  </NavLink>
                  <button className="btn btn-border-basic lg-padding">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpenseTypeStep3Page;
