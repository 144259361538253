import React, { Component } from 'react';
import ExpenseTypeStep3Page  from './expense-types-step3-html';
class ExpenseTypeStep3 extends Component {
    constructor(props){
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    render() {
        return(
            <div>
                <ExpenseTypeStep3Page
                />
            </div>
        )
    }
}
export default ExpenseTypeStep3;