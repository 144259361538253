import React, { Component } from 'react';
import { Input } from "src/_components/common/_forms";
import Select from 'react-select';
import DatePicker from 'react-datepicker';

export default class BasicProfile extends Component {

  render() {
    const {
      titleOptions,
      genderOptions,
      dialCodeOptions,
      firstName,
      middleName,
      lastName,
      email,
      mobile,
      selectedTitle,
      selectedGender,
      selectedDialCode,
      selectedDOB,
      validator,
      personalInfo,
      handleDateChange,
      handleModalCancel,
      handleInputChange,
      handleTitleSelect,
      handleGenderSelect,
      handleDialSelect,
      handleSubmit,
      isCancellable

    } = this.props;
    return (
      <div
        className="modal fade show"
        id="BasicProfileEditModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="BasicProfileEditModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="BasicProfileEditModalLabel">
                Update Information
                </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                {/* <span aria-hidden="true">&times;</span> */}
              </button>
            </div>
            <form>
              <div className="modal-middle pm-30">
                <p className="mb-5"><b>Important Note: </b>Please make sure your first, middle and last name updated here are identical to those on the photo identificaton that you will be presenting at the airport during checkin. Due to increased airport security, you may be turned away at the gate if the name on your identification does not match the name on the ticket.</p>
                <div className="form-group custom labelup">
                  <label>Title <span className="text-danger">*</span></label>
                  <Select
                    placeholder={
                      "Select Title"
                    }
                    value={selectedTitle}
                    options={titleOptions}
                    onChange={handleTitleSelect}
                  />
                  <div className="help-block">
                    {validator.message('Title', selectedTitle, 'required')}

                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Gender <span className="text-danger">*</span></label>
                  <Select
                    placeholder={
                      "Select Gender"
                    }
                    value={selectedGender}
                    options={genderOptions}
                    onChange={handleGenderSelect}
                  />
                  <div className="help-block">
                    {validator.message('Gender', selectedGender, 'required')}

                  </div>
                </div>
                <div className="form-group custom">
                  <Input
                    label="First Name"
                    type="text"
                    imp="true"
                    name="firstName"
                    value={firstName}
                    error={validator.message("FirstName", firstName, 'required')}
                    controlFunc={handleInputChange}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label="Middle Name"
                    type="text"
                    name="middleName"
                    value={middleName}
                    controlFunc={handleInputChange}


                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label="Last Name"
                    type="text"
                    imp="true"
                    name="lastName"
                    value={lastName}
                    error={validator.message("LastName", lastName, 'required')}
                    controlFunc={handleInputChange}
                  />
                </div>
                <div className="form-group custom labelup" style={{ "zIndex": "999" }}>
                  <label>Date Of Birth <span className="text-danger">*</span></label>
                  <DatePicker
                    dateFormat="dd MMM yyyy"
                    selected={selectedDOB}
                    onChange={handleDateChange}
                    maxDate={new Date()}
                    showYearDropdown
                    showMonthDropdown
                    placeholderText="DD MMM YYYY"
                    className="form-control"
                    disabledKeyboardNavigation
                  />
                  <div className="help-block">
                    {validator.message('Date Of Birth', selectedDOB, 'required')}

                  </div>
                </div>
                <div className="form-group custom">
                  <Input
                    label="Email"
                    type="email"
                    imp="true"
                    name="email"
                    value={email}
                    controlFunc={handleInputChange}
                    error={validator.message("Email", email, "required|email")}
                  />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group custom labelup">
                      <label>Country Code <span className="text-danger">*</span></label>
                      <Select
                        placeholder={
                          "Select"
                        }
                        value={selectedDialCode}
                        options={dialCodeOptions}
                        onChange={handleDialSelect}
                      />
                      <div className="help-block">
                        {validator.message('Gender', selectedDialCode, 'required')}

                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group custom">
                      <Input
                        label="Mobile Number"
                        type="text"
                        imp="true"
                        name="mobile"
                        value={mobile}
                        error={validator.message("Phone Number", mobile, 'required|numeric')}
                        controlFunc={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-last btn-group-margin">
                  {
                    // isCancellable &&
                    localStorage.getItem('personalInfo') == 2 &&
                    <button onClick={handleModalCancel} type="button" className="btn" data-dismiss="modal">
                      Cancel
                    </button>
                  }
                  {
                    personalInfo == 0 && (
                      <button
                        type="button"
                        className="btn"
                        onClick={e => handleSubmit(e, 0)}
                      >
                        Save
                      </button>
                    )
                  }

                  {
                    personalInfo == 1 && (
                      <button
                        type="button"
                        className="btn"
                        onClick={e => handleSubmit(e, 1)}
                      >
                        Update
                      </button>
                    )
                  }


                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


    )
  }
}
