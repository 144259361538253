import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from './routes';
import MasterMenu from './master-menu';
class MasterHomePage extends Component {
    render() {
        return (
            <div className="MasterHomePage">
                <Router>
                    <div>
                        <MasterMenu />
                        <Switch>
                            {routes.map((route, key) => {
                                return route.component ? (<Route key={key} path={route.path} exact={route.exact} name={route.name} render={props => (
                                    <route.component {...props} />
                                )} />) : (null);
                            },
                            )}
                        </Switch>
                    </div>
                </Router>
            </div>
        )
    }
}
export default MasterHomePage;