import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import {
  TravelPage,AddEditTemplatePage
} from './index';
import PageNotFound from '../../../../page-not-found/page-not-found-html'
class ReminderMainPage extends Component {
  constructor(props) {
    super(props)
    if (props.location.pathname === '/administration/company/reminder/travel') {
      this.props.history.push('/administration/company/reminder/travel/list')
    } else {
      this.props.history.push(props.location.pathname)
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === '/administration/company/reminder/travel') {
      this.props.history.push('/administration/company/reminder/travel/list')
    }
  }
  render() {
    return (
      <div className="ReminderMainPage">
        <Switch>
          <Route exact path="/administration/company/reminder/travel/list" component={TravelPage} />
          <Route exact path="/administration/company/reminder/add-edit-reminder/:id" exact component={AddEditTemplatePage} />
          <Route path="**" component={PageNotFound} />
        </Switch>
      </div>
    )
  }
}
export default ReminderMainPage;