import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class ExpenseDetailsForTripPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <div className="expense-left">
                    <div className="padx pady border-b">
                        <div className="row">
                            <div className="col-md-8">
                                <h6>Expense</h6>
                            </div>
                            <div className="col-md-4 text-right">
                                <h6>2</h6>
                            </div>
                        </div>
                    </div>
                    <div className="scroll">
                        <ul>
                            <li>
                                <div className="top">
                                    <div className="right-side">
                                        <i className="material-icons">
                                            error
</i>
                                    </div>
                                    <div className="shape-name lg">
                                        <div className="left icon bg-success">
                                            <i className="material-icons">directions_car</i>
                                        </div>
                                        <div className="right">
                                            <h6 className="heading">Car Rental</h6>
                                            <p className="text">18th Dec 2018</p>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="bottom">
                                    <div className="right-side text-left">
                                        <p className="label">Requested</p>
                                        <h6>$500</h6>
                                    </div>
                                    <div>
                                        <p className="label">Amount</p>
                                        <h6>$500</h6>
                                    </div>
                                </div>
                            </li>
                            <li className="active">
                                <div className="top">
                                    <div className="shape-name lg">
                                        <div className="left icon bg-secondary">
                                            <i className="material-icons">local_pizza</i>
                                        </div>
                                        <div className="right">
                                            <h6 className="heading">Business Lunch And Dinner</h6>
                                            <p className="text">18th Dec 2018</p>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="bottom">
                                    <div className="right-side">
                                        <p className="label">Requested</p>
                                        <h6>$300</h6>
                                    </div>
                                    <div>
                                        <p className="label">Amount</p>
                                        <h6>$500</h6>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <NavLink to="/trips-approve/expense-details-for-car">
                                    <div className="top">
                                        {/* <div className="right-side">
                                        <i className="material-icons">info</i>
                                    </div> */}
                                        <div className="shape-name lg">
                                            <div className="left icon bg-success">
                                            <i className="material-icons">directions_car</i>
                                            </div>
                                            <div className="right">
                                                <h6 className="heading">Company Car Milage</h6>
                                                <p className="text">18th Dec 2018</p>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="bottom">
                                        <div className="right-side text-left">
                                            <p className="label">Requested</p>
                                            <h6>$500</h6>
                                        </div>
                                        <div>
                                            <p className="label">Amount</p>
                                            <h6>$500</h6>
                                        </div>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <ul className="bottom-amount">
                        <li>
                            <h6>$100</h6>
                            <p>Outstanding Advance</p>
                        </li>
                        <li>
                            <h6>$400</h6>
                            <p>Total <br />Amount</p>
                        </li>
                        <li>
                            <h6>$500</h6>
                            <p>Total Requested</p>
                        </li>
                    </ul>
                </div>
                <div className="expense-right">

                    <div className="p-padding">
                        <div id="expense-report">
                            <div className="white-card shadow-light report animation-target-left">
                                <div className="grey-head border">
                                    <h6 className="sub-heading">REPORT HEADER</h6>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">

                                        <div className="border-right">
                                            <div className="p-2">
                                                <table className="table border-0 mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0 fs-small"><b className="mb-1">EXPENSE TYPE</b><br /><h5>Breakfast</h5></td>
                                                            <td className="border-0 fs-small"><b className="mb-1">TRANSACTION DATE</b> <br /><h5>14/12/2018</h5></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0 fs-small"><b className="mb-1">BUSINESS PURPOSE</b><br /><h5>Sales Meeting</h5></td>
                                                            <td className="border-0 fs-small"><b className="mb-1">PAYMENT TYPE</b><br /> <h5>Cash</h5></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0 fs-small"><b className="mb-1">VENDOR</b><br /><h5>Zomato</h5></td>
                                                            <td className="border-0">
                                                                <b className="mb-1">AMOUNT</b><br /> <h6>$182.83/ Rs.18,000</h6>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3" className="border-0 fs-small"><b className="mb-1">CITY</b><br /><h5>New York</h5></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <h6 className="sub-heading mt-3">Upload Receipts</h6>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="upload-receipt">
                                                <i className="material-icons">cloud_upload</i>
                                                    <h6>Affidavit</h6>
                                                </div>
                                                <button className="btn   mt-2 ">View Affidavit</button>
                                            </div>
                                            <div className="col-md-8"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add-margin-bottom">
                                <div className="card switch-panel mb-0 px-3">
                                    <label data-toggle="collapse" data-target="#demo">
                                        <div className="float-left">
                                            ITEMISATION
                                        </div>
                                        <div className="float-right">
                                        <i className="material-icons">arrow_drop_down</i>
                                        </div>
                                        <div className="clearfix"></div>
                                    </label>
                                </div>
                                <div id="demo" className="collapse fade-in">
                                    <div className="white-card shadow-light report">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className=" grey-head border-bottom">
                                                    <div className="place__middle float-left">
                                                        <h6 className="mb-0 sub-heading">Breakfast</h6></div>
                                                    <div className="float-right">
                                                        <div className="text-right btn-group-margin">
                                                            <button className="btn btn-danger sm-padding">Remove</button>
                                                            <button className="btn   sm-padding">Edit</button>
                                                        </div>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 ">
                                                <div className="p-3"><b>EXPENSE TYPE</b><br /><h5>Breakfast</h5></div>
                                            </div>
                                            <div className="col-md-3 ">
                                                <div className="p-3"><b>TRANSACTION DATE</b><br /><h5>14/12/2018</h5></div>
                                            </div>
                                            <div className="col-md-3 ">
                                                <div className="p-3"><b>BUSINESS PURPOSE</b><br /><h5>Sales Meeting</h5>
                                                </div></div>
                                            <div className="col-md-3">
                                                <div className="p-3"><b>PAYMENT TYPE</b><br /><h5>Cash</h5></div>
                                            </div>
                                            <div className="col-md-3 ">
                                                <div className="p-3"><b>VENDOR</b><br /><h5>Zomato</h5></div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="p-3"><b>CITY</b><br /><h5>New York</h5></div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="p-3"><b>AMOUNT</b><br /><h5>$182.83/ 18,000</h5></div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className=" grey-head border border-top border-bottom">
                                                    <div className="place__middle float-left">
                                                        <h6 className="mb-0 sub-heading">Lunch</h6></div>
                                                    <div className="float-right">
                                                        <div className="text-right btn-group-margin">
                                                            <button className="btn btn-danger sm-padding">Remove</button>
                                                            <button className="btn   sm-padding">Edit</button>
                                                        </div>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 ">
                                                <div className="p-3"><b>EXPENSE TYPE</b><br /><h5>Breakfast</h5></div>
                                            </div>
                                            <div className="col-md-3 ">
                                                <div className="p-3"><b>TRANSACTION DATE</b><br /><h5>14/12/2018</h5></div>
                                            </div>
                                            <div className="col-md-3 ">
                                                <div className="p-3"><b>BUSINESS PURPOSE</b><br /><h5>Sales Meeting</h5>
                                                </div></div>
                                            <div className="col-md-3">
                                                <div className="p-3"><b>PAYMENT TYPE</b><br /><h5>Cash</h5></div>
                                            </div>
                                            <div className="col-md-3 ">
                                                <div className="p-3"><b>VENDOR</b><br /><h5>Zomato</h5></div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="p-3"><b>CITY</b><br /><h5>New York</h5></div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="p-3"><b>AMOUNT</b><br /><h5>$182.83/ 18,000</h5></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ExpenseDetailsForTripPage
