import {http} from '../_helpers';
import { config } from '../env';

const getAllRuleClass = (companyId, limit, page ) => {

  let url = config.apiUrlTravel + 'travel/rule-class?companyId=' + companyId + '&limit='+limit + '&page='+page;
  return http.get(url)
    .then(res => res)

}

const getPolicyViolationByRuleClass = (companyId, ruleClassId, limit, page) => {
  let url = "";
  if(limit && page){
    url = config.apiUrlTravel + 'travel/policy-violation?ruleClassId=' + ruleClassId + '&companyId=' + companyId + '&limit=' + limit + '&page='+page;

  }else{
    url = config.apiUrlTravel + 'travel/policy-violation?ruleClassId=' + ruleClassId + '&companyId=' + companyId

  }
  return http.get(url)
    .then(res => res)
}

const addPolicyViolation = (payload) => {
  let url = config.apiUrlTravel + 'travel/policy-violation';
  return http.post(url, payload)
    .then(res => res)
}

const activeInactivePolicyViolation = (companyId, payload) => {
  let url = config.apiUrlTravel + 'travel/policy-violation/active-inactive';
  return http.put(url, payload)
    .then(res => res)
}

const getPolicyViolationById = (companyId,id) => {
  let url = config.apiUrlTravel + 'travel/policy-violation/' + id;
  return http.get(url)
    .then(res => res)
}

const editPOlicyViolation = (id, payload) => {
  let url = config.apiUrlTravel + 'travel/policy-violation/' + id;
  return http.patch(url, payload)
    .then(res => res)
}

const searchPolicyViolation = (companyId, code, limit, page) => {
  let url = config.apiUrlTravel + 'travel/search-policy-violation/' + code + '?companyId=' + companyId + '&limit=' + limit + '&page=' + page;
  return http.get(url)
    .then(res => res)
}

const deletePolicyViolation = (companyId, payload) => {
  let url = config.apiUrlTravel + 'travel/delete-policy-violation?companyId=' + companyId;
  return http.patch(url, payload)
    .then(res => res)
}

export const policyViolationService = {
  getAllRuleClass,
  getPolicyViolationByRuleClass,
  addPolicyViolation,
  activeInactivePolicyViolation,
  getPolicyViolationById,
  editPOlicyViolation,
  searchPolicyViolation,
  deletePolicyViolation

}