import React, { Component } from "react";
import TravelSettingsPage from "./travel-settings-html";
import { myAccountTravelSettingsServise } from "../../../_services";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { keys } from "mobx";
import $ from "jquery";
class TravelSettings extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.companyId = localStorage["440a28"];
    this.validator1 = new SimpleReactValidator();
    this.validator2 = new SimpleReactValidator();
    this.validator3 = new SimpleReactValidator();
    this.validator4 = new SimpleReactValidator({
      messages:{
        images3: 'testing something'
      }
    });
  }
  state = {
    seat: "Select Seat",
    meals: "Select Meals",
    frequentTravellerProgramme_Airline: [],
    passport: [],
    visa: [],
    brandList: [],
    res: { result: [] },
    countriesList: [],
    countriesIssueList: [],
    placeIssueList: [],
    airTravelPreferences: {},
    form1: {},
    form2: {},
    form3: {},
    form4: {},
    file: null,
    res: "",
    id: "",
    images: [],
    images2: [],
    images3: [],
    editPassport: false,
    selectedPassportIndex: "",
    selectedVisaIndex: "",
    selectedProgrammeIndex: "",
    selectedOtherIdIndex: "",
    editVisa: false,
    preferenceId: "",
    selectedImage: "",
    clicked1: false,
    clicked2: false,
    clicked3: false,
    clicked4: false,
    isLoading:false
  };

  componentDidMount() {
    this.getFormData();
    this.getBrandDetail();
    this.getCountriesList();
  }

  getFormData = async () => {
    let res = await myAccountTravelSettingsServise.getFormData();
    if (res) {
      this.setState({
        frequentTravellerProgramme_Airline:
          res.result.frequentTravellerProgramme_Airline,
        res: res,
        id: res.result.id,
        passport: res.result.passport,
        visa: res.result.visa,
        otherIdProofs: res.result.otherIdProofs,
        preferenceId:
          res.result.airTravelPreferences[0] !== undefined
            ? res.result.airTravelPreferences[0]._id
            : "",
        airTravelPreferences: res.result.airTravelPreferences[0]
      });
    }
  };

  getBrandDetail = async () => {
    var res = await myAccountTravelSettingsServise.getBrandDetail();
    if (res) {
      this.setState({ brandList: res.result });
    }
  };

  getCountriesList = async () => {
    var res = await myAccountTravelSettingsServise.getCountriesList(this.companyId);
    if (res) {
      this.setState({
        countriesList: res.result.map((data, index) => {
          return {
            issuedFor: data.name,
            key: "issuedFor",
            stateKey: "passport",
            value: data.name
          };
        }),
        countriesIssueList: res.result.map((data, index) => {
          return {
            countryOfIssue: data.name,
            key: "countryOfIssue",
            stateKey: "passport",
            value: data.name
          };
        }),
        countriesBirthList: res.result.map((data, index) => {
          return {
            countryOfBirth: data.name,
            key: "countryOfBirth",
            stateKey: "passport",
            value: data.name
          };
        })
      });
    }
  };

  getCitiesList = async value => {
    var res = await myAccountTravelSettingsServise.getCitiesList(value);
    if (res) {
      this.setState({
        placeIssueList: res.result.map((data, index) => {
          return {
            placeOfIssue: data.city,
            key: "placeOfIssue",
            stateKey: "passport",
            value: data.city
          };
        })
      });
    }
  };



  selectHandlerPreference = async (e, key) => {
    let a = { ...this.state.airTravelPreferences };
    a[key] = e[key];
    this.setState({ airTravelPreferences: a });
    if (this.state.id && this.state.id !== "") {
      a[
        this.state.res.result.airTravelPreferences[0] !== undefined
          ? "_id"
          : null
      ] =
        this.state.res.result.airTravelPreferences[0] !== undefined
          ? this.state.preferenceId
          : null;
      let data = {
        airTravelPreferences: a,
        companyId: this.companyId
      };
      let res = await myAccountTravelSettingsServise.updateFormData(
        this.state.id,
        data
      );
      if (res) {
        toast.success(res.message);
        $(".close").click();
        this.getFormData();
      }
    } else {
      let temp = [];
      temp.push(a);
      let data = {
        airTravelPreferences: temp,
        companyId: this.companyId
      };
      let res = await myAccountTravelSettingsServise.createFormData(
        this.companyId,
        data
      );
      if (res) {
        toast.success(res.message);
        $(".close").click();
        this.getFormData();
      }
    }
  };
  inputChangeHandlerForm1 = e => {
    let temp = { ...this.state.form1 };
    temp[e.target.name] = e.target.value;
    this.setState({ form1: temp });
  };
  selectHandlerForm1 = e => {
    let temp = { ...this.state.form1 };
    temp["brand"] = e;
    this.setState({ form1: temp });
  };
  inputChangeHandlerForm2 = (e, key) => {
    let temp = { ...this.state.form2 };
    temp[e.target.name] = e.target.value;
    this.setState({ form2: temp }, () => console.log("this.state.form2", this.state.form2));
  };

  selectHandlerForm2 = e => {
    let temp = { ...this.state.form2 };
    temp[e.key] = e.value;
    this.setState({ form2: temp }, () => console.log('form 2 ', this.state.form2));
    if (e.key === "countryOfBirth") {
      this.getCitiesList(e.value);
    }
  };

  inputChangeHandlerForm3 = (e, key) => {
    let temp = { ...this.state.form3 };
    temp[e.target.name] = e.target.value;
    this.setState({ form3: temp });
  };
  inputChangeHandlerForm4 = (e, key) => {
    let temp = { ...this.state.form4 };
    temp[e.target.name] = e.target.value;
    this.setState({ form4: temp });
  };
  selectHandlerForm3 = e => {
    let temp = { ...this.state.form3 };
    temp[e.key] = e.value;
    this.setState({ form3: temp }, () => console.log(' after selecting handler ', this.state.form3));
    if (e.key === "countryOfIssue") {
      this.getCitiesList(e.value);
    }
  };
  resetTheState = () => {
    this.setState({
      form1: {},
      form2: {},
      form3: {}
    })
  }
  addEditForm1 = async e => {
    e.preventDefault();
    if (this.validator1.allValid()) {
      if (this.state.id && this.state.id !== "") {
        let temp = { ...this.state.form1 };
        temp[
          this.state.frequentTravellerProgramme_Airline[
            this.state.selectedProgrammeIndex
          ] !== undefined
            ? "_id"
            : null
        ] =
          this.state.frequentTravellerProgramme_Airline[
            this.state.selectedProgrammeIndex
          ] !== undefined
            ? this.state.frequentTravellerProgramme_Airline[
              this.state.selectedProgrammeIndex
            ]._id
            : null;
        let data = {
          frequentTravellerProgramme_Airline: temp,
          companyId: this.companyId
        };
        let res = await myAccountTravelSettingsServise.updateFormData(
          this.state.id,
          data
        );
        if (res) {
          toast.success(res.message);
          $(".close").click();
          this.resetTheState();
          this.getFormData();
        }
      } else {
        let temp = [];
        temp.push(this.state.form1);
        let data = {
          frequentTravellerProgramme_Airline: temp,
          companyId: this.companyId
        };
        let res = await myAccountTravelSettingsServise.createFormData(
          this.companyId,
          data
        );
        if (res) {
          toast.success(res.message);
          $(".close").click();
          this.getFormData();
        }
      }
    } else {
      this.validator1.showMessages();
      this.getFormData();
    }
  };
  addEditForm2 = async e => {
    e.preventDefault();
    if (this.validator2.allValid()) {
      let temp = { ...this.state.form2 };
      temp["photoCopy"] = [...this.state.images];
      if (this.state.id && this.state.id !== "") {
        temp[
          this.state.passport[this.state.selectedPassportIndex] !== undefined
            ? "_id"
            : null
        ] =
          this.state.passport[this.state.selectedPassportIndex] !== undefined
            ? this.state.passport[this.state.selectedPassportIndex]._id
            : null;
        let data = {
          passport: temp,
          companyId: this.companyId
        };
        let res = await myAccountTravelSettingsServise.updateFormData(
          this.state.id,
          data
        );
        if (res) {
          toast.success(res.message);
          $(".close").click();
          this.resetTheState();
          this.getFormData();
        }
      } else {
        let temp2 = [];
        temp2.push(temp);
        let data = {
          passport: temp2,
          companyId: this.companyId
        };
        let res = await myAccountTravelSettingsServise.createFormData(
          this.companyId,
          data
        );
        if (res) {
          toast.success(res.message);
          $(".close").click();
          this.getFormData();
        }
      }
    } else {
      this.validator2.showMessages();
      this.forceUpdate();
    }
  };
  updatePassPort = (index, data) => {
    if (this.state.selectedPassportIndex !== index) {
      this.setState({
        selectedPassportIndex: index,
        form2: data,
        images: data.photoCopy,
        clicked2: true
      });
    } else {
      this.setState({ selectedPassportIndex: "", form2: {}, images: [], clicked2: false });
    }
  };

  frequentTravellerProgramme = (index, data) => {
    if (this.state.selectedProgrammeIndex !== index) {
      this.setState({ selectedProgrammeIndex: index, form1: data, clicked1: true });
    } else {
      this.setState({ selectedProgrammeIndex: "", form1: {}, clicked1: false });
    }
  };
  updateVisa = (index, data) => {
    if (this.state.selectedVisaIndex !== index) {
      this.setState({
        selectedVisaIndex: index,
        form3: data,
        images2: data.photoCopy,
        clicked3: true
      });
    } else {
      this.setState({ selectedVisaIndex: "", form3: {}, images2: [], clicked3: false });
    }
  };
  updateOtherIdProof = (index, data) => {
    if (this.state.selectedOtherIdIndex !== index) {
      this.setState({
        selectedOtherIdIndex: index,
        form4: data,
        images3: data.photoCopy,
        clicked4: true
      });
    } else {
      this.setState({ selectedOtherIdIndex: "", form4: {}, images3: [], clicked4: false });
    }
  };
  resetForm = () => {
    this.setState({ form2: {}, form1: {}, form3: {}, form4: {}, images: [], images2: [], images3: [] });
  };
  addEditForm3 = async e => {
    e.preventDefault();
    if (this.validator3.allValid()) {
      let temp = { ...this.state.form3 };
      temp["photoCopy"] = [...this.state.images2];
      if (this.state.id && this.state.id !== "") {
        temp[
          this.state.visa[this.state.selectedVisaIndex] !== undefined
            ? "_id"
            : null
        ] =
          this.state.visa[this.state.selectedVisaIndex] !== undefined
            ? this.state.visa[this.state.selectedVisaIndex]._id
            : null;
        let data = {
          visa: temp,
          companyId: this.companyId
        };
        let res = await myAccountTravelSettingsServise.updateFormData(
          this.state.id,
          data
        );
        if (res) {
          toast.success(res.message);
          $(".close").click();
          this.resetTheState();
          this.getFormData();
        }
      } else {
        let temp2 = [];
        temp2.push(this.state.form3);
        let data = {
          visa: temp2,
          companyId: this.companyId
        };
        let res = await myAccountTravelSettingsServise.createFormData(
          this.companyId,
          data
        );
        if (res) {
          toast.success(res.message);
          $(".close").click();
          this.getFormData();
        }
      }
    } else {
      this.validator3.showMessages();
      this.forceUpdate();
    }
  };
  addEditForm4 = async e => {
    e.preventDefault();
    if (this.validator4.allValid()) {
      let temp = { ...this.state.form4 };
      temp["photoCopy"] = [...this.state.images3];
      if (this.state.id && this.state.id !== "") {
        temp[
          this.state.otherIdProofs[this.state.selectedOtherIdIndex] !== undefined
            ? "_id"
            : null
        ] =
          this.state.otherIdProofs[this.state.selectedOtherIdIndex] !== undefined
            ? this.state.otherIdProofs[this.state.selectedOtherIdIndex]._id
            : null;
        let data = {
          otherIdProofs: temp,
          companyId: this.companyId
        };
        let res = await myAccountTravelSettingsServise.updateFormData(
          this.state.id,
          data
        );
        if (res) {
          toast.success(res.message);
          $(".close").click();
          this.resetTheState();
          this.getFormData();
        }
      } else {
        let temp2 = [];
        temp2.push(this.state.form4);
        let data = {
          otherIdProofs: temp2,
          companyId: this.companyId
        };
        let res = await myAccountTravelSettingsServise.createFormData(
          this.companyId,
          data
        );
        if (res) {
          toast.success(res.message);
          $(".close").click();
          this.getFormData();
        }
      }
    } else {
      this.validator4.showMessages();
      this.forceUpdate();
    }
  };
  uploadImageHandler = e => {
    let selectedImage = e.target.files[0];
    this.setState({isLoading : true})
    const types = ["image/png", "image/jpeg", "image/gif"];
    if (selectedImage != undefined) {
      if (types.every(type => selectedImage.type !== type)) {
        alert(`${selectedImage.type} is not supported`);
      } else {
        this.setState(
          {
            selectedImage
          },
          async () => {
            let fd = new FormData();
            fd.append("file", selectedImage);
            let res = await myAccountTravelSettingsServise.uploadImage(fd);
            if (res) {
              this.setState({isLoading:false})
              let temp = [...this.state.images];
              temp.push(res.result[0]);
              this.setState({ images: temp });
            }
          }
        );
      }
    }
  };
  uploadImageHandler2 = e => {
    let selectedImage = e.target.files[0];
    this.setState({isLoading : true})
    const types = ["image/png", "image/jpeg", "image/gif"];
    if (selectedImage != undefined) {
      if (types.every(type => selectedImage.type !== type)) {
        alert(`${selectedImage.type} is not supported`);
      } else {
        this.setState(
          {
            selectedImage
          },
          async () => {
            let fd = new FormData();
            fd.append("file", selectedImage);
            let res = await myAccountTravelSettingsServise.uploadImage(fd);
            if (res) {
              this.setState({isLoading:false})
              let temp = [...this.state.images2];
              temp.push(res.result[0]);
              this.setState({ images2: temp });
            }
          }
        );
      }
    }
  };
  uploadImageHandler3 = e => {
    let selectedImage = e.target.files[0];
    this.setState({isLoading : true})
    const types = ["image/png", "image/jpeg", "image/gif"];
    if (selectedImage != undefined) {
      if (types.every(type => selectedImage.type !== type)) {
        alert(`${selectedImage.type} is not supported`);
      } else {
        this.setState(
          {
            selectedImage
          },
          async () => {
            let fd = new FormData();
            fd.append("file", selectedImage);
            let res = await myAccountTravelSettingsServise.uploadImage(fd);
            if (res) {
              this.setState({isLoading:false})
              let temp = [...this.state.images3];
              temp.push(res.result[0]);
              this.setState({ images3: temp });
            }
          }
        );
      }
    }
  };
  removePhotoCopy = key => {
    let temp = this.state[this.state.selectedGalary];
    temp.splice(this.state.selectedImageIndex, 1);
    this.setState({ [this.state[this.state.selectedGalary]]: temp });

    // this.setState({selectedImage:data,selectedGalary:key,selectedImageIndex:index})
  };
  selectedImageHandler = (data, index, key) => {
    this.setState({
      selectedImage: data,
      selectedGalary: key,
      selectedImageIndex: index
    });
  };

  handleBirthDayChange = (date, form, key) => {
    let temp = { ...this.state[form] };
    temp[key] = new Date(date);
    this.setState({ [form]: temp });
  };

  handleDayChange = (date, form, key) => {
    let temp = { ...this.state[form] };
    temp[key] = date.toLocaleDateString();
    this.setState({ [form]: temp });
    // this.setState({ [`${form[key]}`]: date.toLocaleDateString() });
  };
  removeItem = async (id, type) => {
    let data = {
      type: type,
      _id: id
    }
    let res = await myAccountTravelSettingsServise.removeItem(this.state.id, data);
    if (res) {
      toast.success(res.message);
      this.getFormData();
    }

  }
  idTypeChange = (option, type) => {
    let temp = { ...this.state.form4 };
    temp[type] = option.value;
    this.setState({ form4: temp });
  }
  render() {
    return (
      <div>
        <TravelSettingsPage
          selectHandlerForm1={this.selectHandlerForm1}
          selectHandlerForm2={this.selectHandlerForm2}
          selectHandlerForm3={this.selectHandlerForm3}
          inputChangeHandlerForm1={this.inputChangeHandlerForm1}
          inputChangeHandlerForm2={this.inputChangeHandlerForm2}
          inputChangeHandlerForm3={this.inputChangeHandlerForm3}
          inputChangeHandlerForm4={this.inputChangeHandlerForm4}
          state={this.state}
          addAirlineProgramHandler={this.addAirlineProgramHandler}
          uploadImageHandler={this.uploadImageHandler}
          uploadImageHandler2={this.uploadImageHandler2}
          uploadImageHandler3={this.uploadImageHandler3}
          validator1={this.validator1}
          validator2={this.validator2}
          validator3={this.validator3}
          validator4={this.validator4}
          addEditForm1={this.addEditForm1}
          addEditForm2={this.addEditForm2}
          addEditForm3={this.addEditForm3}
          addEditForm4={this.addEditForm4}
          updatePassPort={this.updatePassPort}
          updateVisa={this.updateVisa}
          updateOtherIdProof={this.updateOtherIdProof}
          resetForm={this.resetForm}
          selectHandlerPreference={this.selectHandlerPreference}
          frequentTravellerProgramme={this.frequentTravellerProgramme}
          removePhotoCopy={this.removePhotoCopy}
          selectedImageHandler={this.selectedImageHandler}
          handleDayChange={this.handleDayChange}
          removeItem={this.removeItem}
          idTypeChange={this.idTypeChange}


          handleBirthDayChange={this.handleBirthDayChange}
        />
      </div>
    );
  }
}
export default TravelSettings;
