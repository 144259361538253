import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class Menu extends Component {
    render() {
        return (
            <div>
                {/* <ul className="nav nav-tabs">
                    <li><NavLink to="/administration/expense/tax-authority" className="show">Tax Authorities</NavLink></li>
                    <li><NavLink to="/administration/expense/tax-reclaim-group" className="show">Tax and Reclaim Groups</NavLink></li>
                    <li><NavLink to="/administration/expense/employee-relate-config" className="show">Employee Related Configurations</NavLink></li>
                </ul> */}
            </div>
        )
    }
}
export default Menu