import React from "react";
import Pagination from "react-js-pagination";
import TableRow from "./Table-row";
import { Select } from "../../../../../common/_forms";

const TravelPage = props => {
  let tableRow =
    props.dataList && props.dataList.length > 0 ? (
      props.dataList.map((data, index) => (
        <TableRow
          data={data}
          key={index}
          serviceModuleId={props.serviceModuleId}
          statusHandler={props.statusHandler}
          onSelecthandler={props.onSelecthandler}
          {...props}
        />
      ))
    ) : (
        <tr>
          <td>No data found</td>
        </tr>
      );

  const { navBar } = props;
  let isBranchSelected = props.selectedTemplateByIndex.length < 1 ? false : true;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">E</div>
            <div className="right">
              <h6 className="heading">Manage Email Templates</h6>
              <p className="text">
                You can use templates to replace with your own content and
                customise to your requirement.
              </p>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
      <div className="p-padding pb-0">{navBar}</div>
      <div className="py-padding pt-0">
        <div className="table-container">
          <form>
            <div className="row">
              <div className="col-md-9 col-sm-8">
                <div className="btn-group-margin">
                  <button
                    type="button"
                    className="btn"
                    onClick={e =>
                      props.onCopyDownClickHandler(props.selectedTemplateByIndex)
                    }
                    disabled={isBranchSelected ? false : true}
                  >
                    Duplicate
                  </button>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="search-custom">
                  <input
                    type="text"
                    name="search"
                    onChange={e => props.inputChangeHandler(e)}
                    className="form-control"
                    placeholder="Search"
                  />
                  <span>
                    <i className="material-icons">search</i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped table-selected">
              <thead>
                <tr>
                  <th>Name</th>
                  {/* <th>Sent To</th> */}
                  <th>Editable By</th>
                  <th>Default</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>{tableRow}</tbody>
            </table>
          </div>
          <div className="table-footer">
            <div className="row">
              <div className="col-sm-3">
                {props.totalCount && (
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <div className="dropdown" >
                        <Select
                          placeholder={props.dataPerPage}
                          keyName={"items"}
                          options={props.pageItemsList}
                          controlFunc={e => props.handleItemChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-sm-9">
                {props.totalCount && (
                  <Pagination
                    activePage={props.activePage}
                    itemsCountPerPage={props.dataPerPage}
                    totalItemsCount={props.totalCount}
                    pageRangeDisplayed={3}
                    onChange={props.pageClickHandler}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TravelPage;
