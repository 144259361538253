import React, { Component } from "react";
import { Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ProfileSettingsPage from "./profile-settings";
import ExpenseSettingsPage from "./expense-settings";
import TravelSettingsPage from "./travel-settings";
import CreditCardsPage from "./credit-card";
import EditUserProfilePage from "./edit-profile";
import EditUserPage from "./edit-profile1";
import InsuranceSettingsPage from "./insurance-settings";
import InsuranceSettingsSavePage from "./insurance-settings-save";
import EditUserProfilePageTemp from "./edit-profile-temp-html";
import SideMenu from "./side-menu";
import EditUserProfileTemp from "./edit-profile-temp";
import BasicProfilePage from "./basic-profile";
import menuStore from "src/_store/menu.store";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { userService } from "src/_services";

class MyAccountMainPage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/my-account") {
      this.props.history.push("/my-account/profile");
    } else {
      this.props.history.push(props.location.pathname);
    }
    this.state = {
      isOpen: true,
      notPopup: true
    };
    this.handleclick = this.handleclick.bind(this);
    this.handleclick2 = this.handleclick2.bind(this);
    this.getLogo();
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/my-account") {
      this.props.history.push("/my-account/profile");
    }
  }
  logoImg = "";
  getLogo = () => {
    var resp = userService.getLogoCompany();
    if (resp) {
      this.logoImg = resp.result; 
    }
  };
  handleclick() {
    this.setState({
      isOpen: !this.state.isOpen,
      notPopup: true
    });
  }
  handleclick2() {
    this.setState({
      notPopup: !this.state.notPopup,
      isOpen: true
    });
  }
  render() {
    return (
      <div className="MyAccountMainPage">
        <div className="page-content">
          <SideMenu />
          <div className="inner-wrapper">
            <Route
              path="/my-account/profile-settings"
              component={EditUserProfileTemp}
            />
            <Route path="/my-account/profile" component={EditUserPage} />
            <Route path="/my-account/basic-profile" component={BasicProfilePage} />

            <Route
              path="/my-account/expense-settings"
              component={ExpenseSettingsPage}
            />

            <Route
              path="/my-account/travel-settings"
              component={TravelSettingsPage}
            />
            <Route
              path="/my-account/credit-card"
              component={CreditCardsPage}
            />           
            {/* <Route
            path="/my-account/edit-profile"
            component={EditUserProfilePageTemp} //changed for now to temp later the original version will be back
          /> */}
            <Route
              path="/my-account/insurance-settings"
              component={InsuranceSettingsPage}
            />
            <Route
              path="/my-account/insurance-settings-save"
              component={InsuranceSettingsSavePage}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default MyAccountMainPage;
