import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class TravelDocumentPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  render() {
    return (
      <div>
        <div className="p-padding">
          <div className="main-box">
            <div className="bg-light heading">
              <h6>Passport, Visa & Health results for your itinerary</h6>
            </div>
            <div className="summary-div">
              <div className="float-left ">
                <div className="float-left close-icon">
                  <button type="button" className="btn btn-nothing text-danger">
                    {" "}
                    <i className="material-icons">close</i>
                  </button>
                </div>
                <div className="float-left ml-2">
                  <h5>Summary</h5>
                  <h6>
                    No, the documentation you hold is NOT sufficient for the
                    journey you have specified.
                  </h6>
                </div>
                <div className="clearfix" />
              </div>
              <div className="float-right right-text">
                <span className="count">1</span>
                <span className="ml-1">ISSUE</span>
              </div>
              <div className="clearfix" />
            </div>
            <div className="p-padding">
              <div className="main-content">
                <div className="note-bell">
                  <i className="material-icons">notifications</i>
                  <span>
                    <strong className="ml-2">Note:</strong> Below mentioned
                    information is not mandatory for booking, But you will have
                    to obtain the missing documents on or before travel date.
                  </span>
                </div>
                <div className="visa-details">
                  <div className="country-name">
                    <i className="material-icons">close</i>
                    <span>Singapore</span>
                  </div>
                  <div className="p-padding">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-3">
                            <img
                              src="/assets/images/booking/passport.svg"
                              alt=""
                            />
                          </div>
                          <div className="col-md-9">
                            <div className="info">
                              <span className="option cross-mark">
                                <i className="material-icons">close</i>
                              </span>
                              <span>Visa Required</span>
                            </div>
                            <h6 className="some-text">
                              Passengers must have a printed e-visa confirmation
                              which can be verified at
                              https://save.ica.gov.sg/save/index.xhtml
                            </h6>
                            <NavLink
                              to=""
                              className="btn btn-nothing read-more"
                            >
                              Read More
                            </NavLink>
                            <div className="btn-group-margin my-2">
                              <button
                                type="button"
                                className="btn btn-border-success"
                              >
                                Apply Now
                              </button>
                              <button
                                type="button"
                                className="btn btn-border-primary"
                              >
                                Update Visa
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-3">
                            <img
                              src="/assets/images/booking/visa_required.svg"
                              alt=""
                            />
                          </div>
                          <div className="col-md-9">
                            <div className="info">
                              <span className="option bg-success text-white">
                                <i className="material-icons">check_circle</i>
                              </span>
                              <span>Passport</span>
                            </div>
                            <h6 className="some-text">
                              Your passport should be valid for a minimum period
                              of 6 months from the date of entry into Singapore.
                            </h6>
                            <NavLink
                              to=""
                              className="btn btn-nothing read-more"
                            >
                              Read More
                            </NavLink>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-3">
                            <img
                              src="/assets/images/booking/health.svg"
                              alt=""
                            />
                          </div>
                          <div className="col-md-9">
                            <div className="info">
                              <span className="option bg-success text-white">
                                <i className="material-icons">check_circle</i>
                              </span>
                              <span>Health</span>
                            </div>
                            <h6 className="some-text">
                              Passengers arriving within 6 days after leaving or
                              transiting countries with risk of yellow fever
                              transmissi...
                            </h6>
                            <NavLink
                              to=""
                              className="btn btn-nothing read-more"
                            >
                              Read More
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="visa-details">
                  <div className="country-name">
                    <i className="material-icons">check_circle</i>{" "}
                    <span>USA</span>
                  </div>
                  <div className="p-padding">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-3">
                            <img
                              src="/assets/images/booking/passport.svg"
                              alt=""
                            />
                          </div>
                          <div className="col-md-9">
                            <div className="info">
                              <span className="option cross-mark">
                              <i className="material-icons">close</i>
                              </span>
                              <span>Visa Required</span>
                            </div>
                            <h6 className="some-text">
                              Passengers must have a printed e-visa confirmation
                              which can be verified at
                              https://save.ica.gov.sg/save/index.xhtml
                            </h6>
                            <NavLink
                              to=""
                              className="btn btn-nothing read-more"
                            >
                              Read More
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-3">
                            <img
                              src="/assets/images/booking/visa_required.svg"
                              alt=""
                            />
                          </div>
                          <div className="col-md-9">
                            <div className="info">
                              <span className="option bg-success text-white">
                              <i className="material-icons">check_circle</i>
                              </span>
                              <span>Passport</span>
                            </div>
                            <h6 className="some-text">
                              Your passport should be valid for a minimum period
                              of 6 months from the date of entry into Singapore.
                            </h6>
                            <NavLink
                              to=""
                              className="btn btn-nothing read-more"
                            >
                              Read More
                            </NavLink>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-3">
                            <img
                              src="/assets/images/booking/health.svg"
                              alt=""
                            />
                          </div>
                          <div className="col-md-9">
                            <div className="info">
                              <span className="option bg-success text-white">
                              <i className="material-icons">check_circle</i>
                              </span>
                              <span>Health</span>
                            </div>
                            <h6 className="some-text">
                              Passengers arriving within 6 days after leaving or
                              transiting countries with risk of yellow fever
                              transmissi...
                            </h6>
                            <NavLink
                              to=""
                              className="btn btn-nothing read-more"
                            >
                              Read More
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TravelDocumentPage;
