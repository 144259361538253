import React from "react";
import sample from './sampleFile/template.xls';
import { NavLink } from "react-router-dom";
import Select from 'react-select';

function ImportPage(props) {
  let {
    selectedFile,
    selectedImportType,
    totalCount,
    totalFailed,
    totalInserted,
    totalLogs,
    totalUpdated,
    importTypes,
    handleImportTypeSelect
  } = props;
  return (
    <div>
      <div className="">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Import/Export</h6>
              <p className="text">
                Use Import/Export section to Import or Export reports to CSV or XLS based on filters.
              </p>
            </div>
          </div>
        </div>
        <div className="p-padding importdata">
          <div className="bg-white">
            <div className="row">
              <div className="col-sm-3 import-left-items pr-0">
                <ul>
                  <li className="active"><NavLink to="#">Import</NavLink></li>
                  <li><NavLink to="/administration/company/export">Export</NavLink></li>
                  <li><NavLink to="/administration/company/history-log">History Logs</NavLink></li>
                </ul>
              </div>
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-md-6 offset-3">
                    <div className="import-box mb-0">
                      <div className="heading">
                        Import
                      </div>
                      <div className="inner-content">
                        <div className="form-group custom">
                          <Select
                            options={importTypes}
                            value={selectedImportType}
                            onChange={handleImportTypeSelect}
                          />
                        </div>
                        <div className="upload-input text-center">
                          {/* <NavLink to="/administration/company/error-object" className="text-grey"> */}
                          <input type="file" accept=".csv" onChange={props.onfileUploadHandler} />
                          <div className="py-4 check-box">
                            <i className="material-icons">cloud_upload</i>
                            <div>Drag and Drop to Upload</div>
                          </div>

                          {/* </NavLink> */}

                        </div>
                        <div>
                            {
                              selectedFile ? selectedFile.name : null
                            }
                          </div>
                      </div>
                    </div>
                    <div className="last-content text-center">
                        {
                          totalCount ? (<p>Total count {totalCount}</p>) : null
                        }
                        {
                          totalFailed ? (<p>Total failed {totalFailed}</p>) : null
                        }
                        {
                          totalInserted ? (<p>Total Inserted {totalInserted}</p>) : null
                        }
                        {
                          totalLogs ? (<p>Total Logs {totalLogs}</p>) : null
                        }
                        {
                          totalUpdated ? (<p>Total count {totalUpdated}</p>) : null
                        }
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportPage;