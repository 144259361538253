import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import FlightBookingPage from "../home/flight-components/flight-booking-page";
import HotelPageBook from "../home/hotel-components/hotel-booking";
import { inject } from "mobx-react";
import CabBookingPage from "../home/CabBookingPage";
import TrainBookingPage from "../home/train-components/train-booking";


@inject("serviceStore")
class AddNewModifyServicePage extends Component {
  render() {
    const { type } = this.props;
    const { serviceStore } = this.props;
    return (
      <div>
        <div
          className="modal fade without-bg"
          id="modifyModalNew"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modifyModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {(type === "oneway" ||
                (serviceStore && serviceStore.currentService) === "flight") && (
                <div>
                  <div className="close" data-dismiss="modal">
                    &times;
                  </div>
                  <div className="booking">
                    <div className="booking-list modal-list">
                      <NavLink to="/booking/flight">
                        <img
                          width="80"
                          height="80"
                          src="/assets/images/booking/flight_booking.svg"
                          alt="Flight"
                        />
                        <p>Flights</p>
                      </NavLink>
                    </div>
                  </div>
                  <FlightBookingPage
                    currentPageName={this.props.selectedFlightType}
                    cartCount={this.props.cartCount ? this.props.cartCount : 0}
                  />
                </div>
              )}
              {(type === "hotel" ||
                (serviceStore && serviceStore.currentService) === "hotel") && (
                <div>
                  <div className="close" data-dismiss="modal">
                    &times;
                  </div>
                  <div className="booking">
                    <div className="booking-list modal-list">
                      <NavLink to="#">
                        <img
                          width="80"
                          height="80"
                          src="/assets/images/booking/hotel_booking.svg"
                          alt="Hotel"
                        />
                        <p>Hotels</p>
                      </NavLink>
                    </div>
                  </div>
                  <HotelPageBook />
                </div>
              )}
              {(type === "cab" ||
                (serviceStore && serviceStore.currentService) === "cab") && (
                <div>
                  <div className="close" data-dismiss="modal">
                    &times;
                  </div>
                  <div className="booking">
                    <div className="booking-list modal-list">
                      <NavLink to="#">
                        <img
                          width="80"
                          height="80"
                          src="/assets/images/booking/car_booking.svg"
                          alt="Car"
                        />
                        <p>Car</p>
                      </NavLink>
                    </div>
                  </div>
                  <CabBookingPage />
                </div>
              )}
              {(type === "train" ||
                (serviceStore && serviceStore.currentService) === "train") && (
                <div>
                  <div className="close" data-dismiss="modal">
                    &times;
                  </div>
                  <div className="booking">
                    <div className="booking-list modal-list">
                      <NavLink to="#">
                        <img
                          width="80"
                          height="80"
                          src="/assets/images/booking/train_booking.svg"
                          alt="Car"
                        />
                        <p>Train</p>
                      </NavLink>
                    </div>
                  </div>
                  <TrainBookingPage />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddNewModifyServicePage;
