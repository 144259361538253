import PropTypes from "prop-types";

export const TripBar = ({ as = "div", tag: Tag = as, children, hidden }) => (
  <Tag style={{ display: hidden ? "none" : "block" }}>
    {children}
    <div className="clearfix" />
  </Tag>
);

TripBar.propTypes = {
  children: PropTypes.any.isRequired,
  hidden: PropTypes.bool
};
