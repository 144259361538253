import React, { Component } from "react";
import CarConfigStep4Page from "./config-step4-html";
import { carConfigService } from "src/_services/carConfigService";
import { toast } from "react-toastify";
import SimpleReactValidator from 'simple-react-validator';

class configStep4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form4Data: {},
            carId: '',
            formId: '',
            field2: '',
            field1: '',
            field3: '',
            field4: '',
            personalRate: '',
            ratePerPassenger: '',
            id: ''
        }
        window.scrollTo({ top: 0, behavior: "smooth" });

    }
    formValidator4 = new SimpleReactValidator();
    componentDidMount = () => {
        let carId = JSON.parse(localStorage.getItem("carId"));
        if (carId !== 'new') {
            this.setState({ carId });
            this.updateCarConfiguration(carId);
        } else {
            toast.success("Please complete the step 1");
            this.props.history.push(`/administration/expense/car-config/config-step1/${carId}`);
        }
    }
    updateCarConfiguration = async (carId) => {
        let res = await carConfigService.updateCarConfiguration(carId);
        if (res) {
            this.setState({ form4Data: res.result, formId: res.result.id });
        }
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    setCurrentEdit = (vehicle) => {
        this.setState({
            id: vehicle.id,
            vehicle: vehicle.vehicle,
            field2: vehicle.field2,
            field1: vehicle.field1,
            field3: vehicle.field3,
            field4: vehicle.field4,
            ratePerPassenger: vehicle.ratePerPassenger,
            personalRate: vehicle.personalRate
        })
    }
    saveChanges = async (e) => {
        let payLoad = {
            id: this.state.id,
            field2: this.state.field2,
            field1: this.state.field1,
            field3: this.state.field3,
            field4: this.state.field4,
            personalRate: this.state.personalRate,
            ratePerPassenger: this.state.ratePerPassenger
        }
        if (this.formValidator4.allValid()) {
            let res = await carConfigService.updateCarRates(this.state.formId, payLoad);
            if (res) {
                toast.success(res.message);
                this.updateCarConfiguration(this.state.carId);
            } else {
                toast.danger('Error');
            }
        }
        else {
            this.formValidator4.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        return (
            <div>
                <CarConfigStep4Page
                    state={this.state}
                    form4Data={this.state.form4Data}
                    handleChange={this.handleChange}
                    saveChanges={this.saveChanges}
                    setCurrentEdit={this.setCurrentEdit}
                    formValidator4={this.formValidator4}
                />
            </div>
        );
    }
}
export default configStep4;