import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class MasterMenu extends Component {
    render() {
        return (
            <div className="adminmenu">
                <div className="admin-nav">
                    <i className="icon ion-md-code"></i> Master
                </div>
                <ul className="nav-overflow">
                    <li className="heading custom-dropdown">
                        <NavLink to="/master/roles" className="btn btn-nothing">
                            Roles
                            <i className="icon ion-md-arrow"></i>
                        </NavLink>
                    </li>
                    <li className="heading custom-dropdown">
                        <NavLink to="/master/permission-type" className="btn btn-nothing">
                            Permission Type
                            <i className="icon ion-md-arrow"></i>
                        </NavLink>
                    </li>
                    <li className="heading custom-dropdown">
                        <NavLink to="/master/permission-list" className="btn btn-nothing">
                            Permission
                            <i className="icon ion-md-arrow"></i>
                        </NavLink>
                    </li>
                    <li className="heading custom-dropdown">
                        <NavLink to="/master/manage-menu" className="btn btn-nothing">
                            Manage Menu
                            <i className="icon ion-md-arrow"></i>
                        </NavLink>
                    </li>
                </ul>
            </div>
        )
    }
}

export default MasterMenu