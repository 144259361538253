import React from "react";
import Pagination from 'react-js-pagination';
import Loader from "src/_components/common/Loader";
import _ from 'lodash';

const AccountCodesPage = props => {
  const { state, handlePageChange, count, activePage, totalExpenseData, companyData, tableDataHandler, postData, globalGetData, setGlobal, setLedger, setHierarchy, setL1Hrcy, setL2Hrcy, isLoading,itemsPerPage } = props;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">A</div>
            <div className="right">
              <h6 className="heading">Account Codes</h6>
              <p className="text">Use the Account codes to connect Expense with the company's accounting system.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="table-container px-4">
            <div className="row">
              <div className="col-md-4">
                <div className="border p-3">
                  <div className="file-tree">
                    <ul>
                      <li>
                        <button
                          type="button"
                          className="btn btn-nothing collapsed"
                          data-toggle="collapse"
                          data-target="#demo"
                          onClick={setGlobal}
                        >
                          <img src="/assets/images/folder.svg" alt="" />
                          <span className="text">Global</span>
                        </button>
                        <ul id="demo" className="collapse">
                          {(companyData) ? companyData.map((item, key) => {
                            return (
                              <li key={key}>
                                <button
                                  type="button"
                                  className="btn btn-nothing collapsed"
                                  data-toggle="collapse"
                                  data-target={`#demo${key}`}
                                  onClick={e => setLedger(item.ledgerName, item.id)}
                                >
                                  <img src="/assets/images/subfolder.svg" alt="" />
                                  <span className="text">{item.ledgerName}</span>
                                </button>
                                <ul id={`demo${key}`} className="collapse">
                                  {props[item.ledgerName] ? props[item.ledgerName].map((data, idx) => {
                                    return (
                                      <li key={idx}>
                                        <button
                                          type="button"
                                          className="btn btn-nothing collapsed"
                                          data-toggle="collapse"
                                          data-target={`#demo1${idx}`}
                                          onClick={e => setHierarchy(data.id, item.id, data.itemName)}
                                        >
                                          <img
                                            src="/assets/images/subfolder.svg"
                                            alt=""
                                          />
                                          <span className="text">{data.itemName}</span>
                                        </button>
                                        <ul id={`demo1${idx}`} className="collapse">
                                          {(data.subData) ? data.subData.map((L1, index) => {
                                            return (
                                              <li key={index}>
                                                <button
                                                  type="button"
                                                  className="btn btn-nothing collapsed"
                                                  data-toggle="collapse"
                                                  data-target={`#demo2${index}`}
                                                  onClick={e => setL1Hrcy(L1.id, data.id, L1.itemName, item.id)}
                                                >
                                                  <img src="/assets/images/subfolder.svg" alt="" />
                                                  <span className="text">{L1.itemName}</span>
                                                </button>
                                                <ul id={`demo2${index}`} className="collapse">
                                                  {(L1.subData) ? L1.subData.map((L2, ind) => {
                                                    return (
                                                      <li key={ind}>
                                                        <button
                                                          type="button"
                                                          className="btn btn-nothing collapsed"
                                                          data-toggle="collapse"
                                                          data-target={`#demo3${ind}`}
                                                          onClick={e => setL2Hrcy(L2.id, L2.itemName, L1.id, data.id, item.id)}
                                                        >
                                                          <img src="/assets/images/subfolder.svg" alt="" />
                                                          <span className="text">{L2.itemName}</span>
                                                        </button>
                                                      </li>
                                                    );
                                                  }) : ""}
                                                </ul>
                                              </li>
                                            )
                                          }) : ''}
                                        </ul>
                                      </li>
                                    );
                                  }) : ""}

                                </ul>
                              </li>
                            );
                          }) : ""}

                        </ul>

                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <form className="p-0">
                  <div className="row">
                    <div className="col-md-12 text-right">
                      <button type="button" className="btn" onClick={e => postData(e)}>
                        Save
                      </button>
                    </div>
                  </div>
                </form>
                <div className="table-responsive table-restricted">
                  {isLoading ? (<Loader />) : (<table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Expense Type</th>
                        <th>Account Code</th>
                        <th>Inherited Code</th>
                        <th>Inherited Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (totalExpenseData.length) ? totalExpenseData.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{item.expenseTypeName}</td>
                              <td>
                                <div className="form-group custom mb-0">
                                  <input type="text" className="form-control" onChange={e => tableDataHandler(e, item.id)} value={props[item.id] ? props[item.id].accountCode !== undefined ? props[item.id].accountCode : '' : ''} />
                                </div>
                              </td>
                              <td>{props[item.id] ? props[item.id].inheritedCode : ''}</td>
                              <td>{props[item.id] ? props[item.id].inheritedLevel : ''}</td>
                            </tr>)
                        }) :
                          <tr>
                            <td colSpan="4" className="text-center">No Data found </td>
                          </tr>
                      }
                    </tbody>
                  </table>)}

                </div>
                <div className="table-footer px-0">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="text-right">
                        <Pagination
                          // activePage={activePage}
                          // itemsCountPerPage={100}
                          // totalItemsCount={count}
                          // pageRangeDisplayed={3}
                          // onChange={handlePageChange}
                          activePage={activePage}
                          itemsCountPerPage={itemsPerPage}
                          totalItemsCount={count}
                          pageRangeDisplayed={3}
                          onChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountCodesPage;
