import React from 'react';
import DynamicFields from 'src/_components/common/dynamicFields';
import {mergeFormAndFields} from 'src/utils/util';

const expenseItemisationPdf=(props)=>{
    const itemisationObejctToArray = [];
     for(const key in props.itemizationForm) {
        itemisationObejctToArray.push(props.itemizationForm[key]);
    }
    return(
        <div key={props.keyIndex} style={{ border: "1px solid #f1f1f2", padding: "10px 0", marginBottom: "5px" }}>
        
        {itemisationObejctToArray.length && itemisationObejctToArray.map((thead,index)=>{
            let fieldDetails = mergeFormAndFields(thead[0].itemizationField);
            let currency = fieldDetails.find((fields)=>fields.columnName === "CURRENCY");
            return(
                <>
                <p style={{ padding: "10px 20px", fontWeight: "600", margin: "0" }}>
                {thead[0]&& thead[0].expenseTypeId && thead[0].expenseTypeId.expenseTypeName}</p>
                    <div style={{ padding: "0 20px" }}>
             <table key={index} style={{ width: "100%", fontSize: "12px", textAlign: "left", verticalAlign: "middle", marginTop: "15px" }}>
             <thead>
                 <tr>
                 {fieldDetails.map((fields)=>{
                     if(fields.columnName !== 'CURRENCY'){
                        return(<th key={fields.id+index}>
                            <p style={{ marginBottom: "8px", textTransform: "uppercase", color: "#a7a9c0", fontWeight: "400" }}>{fields.labelName}
                        </p>
                        </th>)
                     }
                   })
                     }
                 </tr>
             </thead>
             <tbody>
                 <tr>
                     {fieldDetails.map((fields)=>{
                         if(fields.dataType === DynamicFields.DATA_TYPE_BOOLEAN || fields.dataType === DynamicFields.DATA_TYPE_CHECKBOX){
                             return(
                                 <td key={fields.id}>
                                     <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5" }}>
                                     {fields.fieldValue?'Yes':'No'}
                                     </p>
                                 </td>
                             )
                         }else if(fields.columnName=== "AMOUNT"){
                             return(
                                 <td key={fields.id}>
                                     <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5" }}>
                                     {currency.fieldValue && currency.fieldValue}{" "}
                                     {(new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(fields.fieldValue?fields.fieldValue:0))}
                                     
                                     </p>
                                 </td>
                             )
                         }else if(fields.columnName !== "CURRENCY"){
                             return(
                                <td key={fields.id}>
                                <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5" }}>
                                {fields.fieldValue?fields.fieldValue:'-'}
                                </p>
                            </td>
                             )
                         }
                 })
                     }
                 </tr>
             </tbody>
         </table>
         </div>
         </>
          )})
        }
       
        </div>
   
    )
}

export default expenseItemisationPdf;