import React, { Component } from 'react';
import ForgotPasswordPage from './forgot-password-html';
import SimpleReactValidator from 'simple-react-validator';
import { userService } from '../../_services';
import { toast } from 'react-toastify';
class ForgotPassword extends Component {
    constructor(props) {
        localStorage.clear();
        super(props)
        this.validator = new SimpleReactValidator();
        this.state = {
            companyCode: '',
            email: '',
        };
    }
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    loading = false
    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            const { email } = this.state;
            if (email) {
               let res =  await userService.forgotPassword(this.state);
               if(res != undefined){
                 this.props.history.push('/verify-code');
               }
              }

        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }
    }
    render() {
        return (
            <ForgotPasswordPage
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                state={this.state}
                error={this.validator}
                loading={this.loading} />
        )
    }
}
export default ForgotPassword;