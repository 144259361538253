import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { myAccountTravelSettingsServise } from "../../../_services";
import moment from 'moment';
import Loader from "src/_components/common/Loader";
import { getValue } from "src/_components/common/lodsh";
class EditUserPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userDetails: {},
      userInfo: [],
      firstName: '',
      isLoading: true
    };
  }

  componentDidMount() {
    this.getProfileDetails();
  }

  getFirstName = () => {
    if (this.state.userInfo.length) {
      let firstNameObject = this.state.userInfo.find(item => item.columnName == "EMPLOYEE_FIRST_NAME")
      if (firstNameObject != null) {
        return firstNameObject.fieldValue;
      } else {
        return ' - ';
      }
    } else {
      return ' - ';
    }
  }

  getProfileDetails = async () => {
    var res = await myAccountTravelSettingsServise.getProfileDetails();
    if (res) {
      this.setState({
        isLoading: false,
        userDetails: res.result
      });
      let userInformation = getValue(this.state, "userDetails.fieldData", []).map(item => {
        if (item.dataType === 'connected_list') {
          return {
            columnName: item.columnName,
            fieldValue: item.connectedFieldValue,
            labelName: item.labelName
          };
        } else if (item.dataType === 'different_list') {
          return {
            columnName: item.columnName,
            fieldValue: item.differentListValue,
            labelName: item.labelName
          };
        } else {
          return {
            columnName: item.columnName,
            fieldValue: item.fieldValue,
            labelName: item.labelName
          };
        }
      });
      this.setState({
        userInfo: userInformation
      });
    } else {
      this.setState({ isLoading: false })
    }
  };
  getReportManagerOne = (requiredColumn) => {
    if (this.state.userDetails && this.state.userDetails.reportManager1) {
      let firstNameObject = this.state.userDetails.reportManager1.fieldData.find(info => info.columnName == requiredColumn);
      if (firstNameObject != null) {
        return firstNameObject.fieldValue
      }

    } else {
      return " ";
    }
  }

  getReportManagerTwo = (requiredColumn) => {
    if (this.state.userDetails && this.state.userDetails.reportManager2) {
      let firstNameObject = this.state.userDetails.reportManager2.fieldData.find(info => info.columnName == requiredColumn);
      if (firstNameObject != null) {
        return firstNameObject.fieldValue
      }

    } else {
      return " ";
    }
  }

  getFieldDetalsAndRender = (item, key) => {
    if (item.columnName === 'DOB') {
      let dob = this.getDOBInRequiredFormat(item);
      return (
        <div className="col-lg-4 col-md-6" key={key}>
          <div className="shape-name mb-4">
            {/* <div className="left l-grey">{item.labelName[0]}</div> */}
            <div className="right ml-0">
              <p className="text op-less">
                {" "}
                {item.labelName.split("_").join(" ")}{" "}
              </p>
              <h6 className="heading fw-sbold">
                {dob ? dob : '-'}
              </h6>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="mb-dashed"></div>
        </div>
      )
    } else {
      return (
        <div className="col-lg-4 col-md-6" key={key}>
          <div className="shape-name mb-4">
            {/* <div className="left l-grey">{item.labelName[0]}</div> */}
            <div className="right ml-0">
              <p className="text op-less">
                {" "}
                {item.labelName.split("_").join(" ")}{" "}
              </p>
              <h6 className="heading fw-sbold">
                {item.fieldValue ? item.fieldValue : '-'}
              </h6>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="mb-dashed"></div>
        </div>
      )
    }
  }

  getDOBInRequiredFormat = (item) => {
    if (item && item.fieldValue) {
      let dob = moment(new Date(item.fieldValue)).format('DD MMM YYYY');
      return dob;
    }
  }

  render() {
    const path = `url(${this.state.userDetails.profileImage})`;
    const profImg = {
      backgroundImage: path
    };
    return (
      <div>
        {this.state.isLoading ? (
          <div className="profile-card bg-white mb-3">
            <div className="details">
              <Loader />
            </div>
          </div>
        ) :
          <div className="p-padding profile-bg-image fadeIn animated">
            <div className="user-profile user-1 mb-3">
              <div className="img" style={profImg}>
                {/* <img src={this.state.userDetails.profileImage}></img> */}
                {/* <button className="btn btn-nothing">Upload</button> */}
              </div>
            </div>
            <h3 className="text-black fs-22 fw-bold text-center mb-2"><b>Hi {this.getFirstName()}</b></h3>
            <p className="text-center mb-4">
              Manage your info, privacy, and security to make Curio work better for you
            </p>
            <div className="profile-card mb-3">
              <div className="details">
                {/* <NavLink
                  to="/my-account/profile-settings"
                  className="btn float-right"
                >
                  Edit
                  </NavLink> */}

                <h6 className="sub-heading fs-16 mb-5 mt-2">Company Information</h6>
                {

                  <div className="row">
                    {this.state.userInfo &&
                      this.state.userInfo.map((item, key) => {
                        return (
                          this.getFieldDetalsAndRender(item, key)
                        );
                      })}
                  </div>

                }


              </div>
            </div>
            <div className="profile-card">
              <div className="details">
                <h6 className="sub-heading fs-16">Managers</h6>
                <div className="row">
                  <div className="col-md-12">
                    <div className="manager shape-name mb-4">
                      {/* <div className="left l-grey">1</div> */}
                      <div className="right ml-0">
                        <p className="text mb-0 op-less">Level 1</p>
                        <h6 className="heading p-0 fw-sbold"> {this.state.userDetails?.reportManager1 && this.getReportManagerOne('EMPLOYEE_FIRST_NAME')} {" "} {this.state.userDetails?.reportManager1 && this.getReportManagerOne('EMPLOYEE_LAST_NAME')} </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-dashed"></div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="manager shape-name">
                      {/* <div className="left l-grey">2</div> */}
                      <div className="right ml-0">
                        <p className="text mb-0 op-less">Level 2</p>
                        <h6 className="heading p-0 fw-sbold"> {this.state.userDetails?.reportManager2 && this.getReportManagerTwo('EMPLOYEE_FIRST_NAME')} {" "} {this.state.userDetails?.reportManager2 && this.getReportManagerTwo('EMPLOYEE_LAST_NAME')} </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-card">
              <div className="details d-none" >
                {/* <NavLink
                  to="/my-account/profile-settings"
                  className="btn float-right"
                >
                  Add Delegate
                  </NavLink> */}
                <h6 className="sub-heading fs-16">Delegates</h6>
                <div className="clearfix"></div>
                {this.state.userDetails && this.state.userDetails.delegatedUser?.length === 0 &&
                  <p className="text-center mt-3 pt-0">
                    <i className="material-icons">
                      pages
                          </i>
                    <br />
                    There are no delegates added yet.
                          <br />
                    You can add one or more delegates.
                    </p>
                }
                {this.state.userDetails && this.state.userDetails.delegatedUser && this.state.userDetails.delegatedUser.map((list, key) => {
                  return (
                    <div className="row" key={key}>
                      <div className="col-md-12">
                        <div className="manager shape-name mb-4">
                          <div className="left l-grey">1</div>
                          <div className="right">
                            <h6 className="heading p-0 fw-sbold"> {list.parentUserId?.email} </h6>
                            <p className="text mb-0 op-less">{list.parentUserId?.fullName}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {/* <div className="profile-card">
            <div className="details">
              <div className="form-group  float-right">
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn dropdown-toggle "
                    data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"
                  >
                    By Group
                      </button>
                  <div className="dropdown-menu">
                    <button
                      type="button"
                      className="dropdown-item"
                    >
                      1
                        </button>
                    <button
                      type="button"
                      className="dropdown-item"
                    >
                      2
                        </button>
                  </div>
                </div>
              </div>
              <h6 className="sub-heading fs-16 pt-3">Proxy Access</h6>
              <div className="clearfix"></div>
              <div className="delegate bg-white">
                <div className="names">
                  <div className="search-custom">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Search User"
                    />
                    <span>
                      <i className="material-icons">search</i>
                    </span>
                  </div>
                  <div className="scroll">
                    <ul>
                      <li className="active">
                        <div className="shape-name">
                          <div className="left user-icon"><i className="material-icons">person</i></div>
                          <div className="right">
                            <h6 className="heading">Jobin John</h6>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="shape-name ">
                          <div className="left user-icon"><i className="material-icons">person</i></div>
                          <div className="right">
                            <h6 className="heading">John Martin</h6>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="shape-name ">
                          <div className="left user-icon"><i className="material-icons">person</i></div>
                          <div className="right">
                            <h6 className="heading">Brandon Brit</h6>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="shape-name">
                          <div className="left user-icon"><i className="material-icons">person</i></div>
                          <div className="right">
                            <h6 className="heading">Carlos Chairman</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="detail">
                  <h6 className="sub-heading light">Your Access</h6>
                  <div className="scroll">
                    <div className="all-detail">
                      <div className="shape-name">
                        <div className="left user-icon"><i className="material-icons">person</i></div>
                        <div className="right">
                          <h6 className="heading">Jobin John</h6>
                          <p className="text">Travel Manager</p>
                        </div>
                      </div>
                      <form>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="shape-name mb-4">
                              <div className="right">
                                <p className="op-less mb-1"> Access Type </p>
                                <h6 className="heading fw-sbold">User Proxy</h6>
                              </div>
                              <div className="clearfix">
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="shape-name mb-4">
                              <div className="right">
                                <p className="op-less mb-1"> Service </p>
                                <h6 className="heading fw-sbold">Travel, Expense</h6>
                              </div>
                              <div className="clearfix">
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="shape-name mb-4">
                              <div className="right">
                                <p className="op-less mb-1"> From </p>
                                <h6 className="heading fw-sbold">21st Oct 2018</h6>
                              </div>
                              <div className="clearfix">
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="shape-name mb-4">
                              <div className="right">
                                <p className="op-less mb-1"> To </p>
                                <h6 className="heading fw-sbold">3rd Jan 2019</h6>
                              </div>
                              <div className="clearfix">
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
           */}
            <div className="profile-card">
              <div className="details">
                <h6 className="sub-heading fs-16 mb-5">Additional Documents</h6>
                <div className="row">
                  {/* <div className="col-md-2 text-center">
                  <img width="50" src="/assets/images/excel.svg" alt="Excel" />
                  <h6 className="sub-heading fs-16 text-center mt-3 mb-0">Policy Details</h6>
                  <p className="text-center mb-0"><button className="btn btn-nothing text-primary">Download</button></p>
                </div> */}
                  <div className="col-xl-2 col-lg-3 col-md-4 text-center">
                    {/* <a href="/assets/travel-policy.pdf" target="_blank" download> */}
                    <img width="50" src="/assets/images/pdf.svg" alt="PDF" />
                    <h6 className="sub-heading fs-16 text-center mt-3 mb-0">Policy Details</h6>
                    {/* <p className="text-center mb-0"><button className="btn btn-nothing text-primary">Download</button></p> */}
                    {/* </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default EditUserPage;
