import React from "react";
import Pagination from "react-js-pagination";
import TableRow from "./Table-row";
import PropTypes from "prop-types";

const TravelPage = props => {
  const { navBar } = props;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">E</div>
            <div className="right">
              <h6 className="heading">Manage Email Reminder</h6>
              <p className="text">
                You can use email reminders to replace with your own content and
                customise to your requirement.
              </p>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
      <div className="p-padding pb-0">{navBar}</div>
      <div className="py-padding pt-0">
        <div className="table-container">
          <form>
            <div className="row">
              <div className="col-md-9 col-sm-8">
                {/* <div className="btn-group-margin">
                  <NavLink
                    to="/administration/company/reminder/add-edit-reminder"
                    className="btn  "
                  >
                    Add Reminder
                  </NavLink>
                </div> */}
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="search-custom">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    placeholder="Search"
                    onChange={e => props.onInputHandler(e)}
                  />
                  <span>
                    <i className="material-icons">search</i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped table-selected">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Reminder Type</th>
                  <th>If Older Than</th>
                  <th>Frequency</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <TableRow {...props} />
            </table>
          </div>
          <div className="table-footer">
            <div className="row">
              <div className="col-sm-3">
                <div className="page-count">
                  <div className="text">Items</div>
                  <div className="count">
                    <div className="dropdown" >
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        {props.itemsPerPage}
                      </button>
                      <div className="dropdown-menu">
                        <button
                          className="dropdown-item"
                          onClick={e => props.OnPageLimitHandler(10)}
                        >
                          10
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={e => props.OnPageLimitHandler(25)}
                        >
                          25
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={e => props.OnPageLimitHandler(50)}
                        >
                          50
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-9">
                <Pagination
                  activePage={props.currentPage}
                  itemsCountPerPage={props.itemsPerPage}
                  totalItemsCount={props.totalCount}
                  pageRangeDisplayed={3}
                  onChange={props.OnPageChangeHandler} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
TravelPage.propTypes = {
  onInputHandler: PropTypes.func,
  OnPageLimitHandler: PropTypes.func,
  OnPageChangeHandler: PropTypes.func,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  navBar: PropTypes.object
};

export default TravelPage;
