import React, { Component } from "react";
import BookingHomeCardPage from "../home-cards";
import BookingMenuPage from "../../components/booking-menu";
// import { NavLink } from "react-router-dom";
// import DayPicker, { DateUtils } from "react-day-picker";
// import { bookingServices } from "../../../../_services/booking.service";
import cryptr from "../../../../../_helpers/crypty";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
// import Moment from "react-moment";
import HotelPageBook from './hotel-booking'
class HotelPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      selectedDay: null,
      from: "",
      to: "",
      fromDate: null,
      toDate: null,
      rooms: 1,
      type: "Hotels",
      lat: "",
      lng: ""
    };
  }
  activeInput = 0;
  showInput = e => {
    this.activeInput = e;
    this.forceUpdate();
  };
  handleChange = from => {
    this.setState({ from });
  };

  handleSelect = from => {
    geocodeByAddress(from)
      .then(results => getLatLng(results[0]))
      .then(location => {
        this.setState({
          from: from,
          lat: JSON.stringify(location.lat),
          lng: JSON.stringify(location.lng)
        });
        this.activeInput = 0;
        this.forceUpdate();
      })
      .catch(error => console.error("Error", error));
  };
  popularCity = [
    { lat: "12.9716", lng: "77.5946", name: "Bengaluru" },
    { lat: "13.0827", lng: "80.2707", name: "Chennai" },
    { lat: "17.3850", lng: "78.4867", name: "Hyderabad" },
    { lat: "18.5204", lng: "73.8567", name: "Pune" },
    { lat: "15.2993", lng: "74.1240", name: "Goa" }
  ];
  rooms = [
    { id: 1, value: "Hotels" },
    { id: 2, value: "Cottage" },
    { id: 3, value: "Motels" },
    { id: 4, value: "Resorts" },
    { id: 5, value: "Country House" },
    { id: 6, value: "Apartments" },
    { id: 7, value: "Guest Accomodation" }
  ];
  changeRoom = e => {
    this.setState({
      rooms: e.id,
      type: e.value
    });
    this.activeInput = 0;
  };
  handleDayClick = (day, { selected }) => {
    this.setState({
      selectedDay: selected ? undefined : day,
      fromDate: selected ? undefined : day
    });
    this.activeInput = 0;
    this.forceUpdate();
  };
  handleDayClickTo = (day, { selected }) => {
    this.setState({
      toDate: selected ? undefined : day
    });
    this.activeInput = 0;
  };
  getCity = async e => {
    this.setState({
      from: e.name,
      lat: e.lat,
      lng: e.lng
    });
    this.activeInput = 0;
  };
  validationTrue = false;
  searchRecord = () => {
    this.validationTrue = true;
    const { toDate, rooms, lng, lat, fromDate, from } = this.state;
    if (toDate && rooms && lat && lng && fromDate && from) {
      var req = {
        toDate: toDate,
        rooms: rooms,
        lat: lat,
        lng: lng,
        fromDate: fromDate,
        from: from
      };
      var resp = cryptr.encrypt(JSON.stringify(req));
      this.props.history.push("/booking/hotel/list/" + resp);
    }
    this.forceUpdate();
  };
  render() {
    const { fromDate, toDate, selectedDay, rooms, from, type } = this.state;
    const today = new Date();
    return (
      <div className="p-padding">
        <div className="row">
          <div className="offset-xl-1 col-xl-10">
            <BookingMenuPage />
            <HotelPageBook />
            <BookingHomeCardPage />
          </div>
        </div>
      </div>
    );
  }
}
export default HotelPage;
