import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class SideMenu extends Component {
    render() {
        return (
            <div id="adminmenu" className="adminmenu">
                {/* <div className="admin-nav">
                    <i className="icon ion-md-settings"></i> &nbsp;
                    Profile Settings
                </div> */}
                <ul id="admin-accordion" className="nav-overflow">
                    <li id="partsomeId" className="heading custom-dropdown">
                        <button data-toggle="collapse" data-target="#someId" aria-expanded="true"
                            aria-controls="someId" className="btn btn-nothing active resp-not-icon">
                            <span className="resp-not-icon">Profile Settings</span>
                            <span className="resp-picon">
                                &nbsp;
                            </span>
                            <i className="material-icons">chevron_right</i>
                        </button>
                        <ul className="collapse inner-menu custom-dropdown-inner show" id="someId" data-parent="#partsomeId">
                            <li>
                                <NavLink to="/my-account/basic-profile" className="hover-v">
                                    <span className="resp-not-icon">Personal Information</span>
                                    <span className="resp-picon">
                                        <i className="material-icons">
                                            person
                                        </i>
                                    </span>
                                    <span className="hover-name top">Basic Profile</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/profile" className="hover-v">
                                    <span className="resp-not-icon">Company Information</span>
                                    <span className="resp-picon">
                                        <i className="material-icons">
                                            person
                                        </i>
                                    </span>
                                    <span className="hover-name top">Profile Information</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/travel-settings" className="hover-v">
                                    <span className="resp-not-icon">Travel Settings</span>
                                    <span className="resp-picon">
                                        <i className="material-icons">
                                            card_travel
                                        </i>
                                    </span>
                                    <span className="hover-name top">Travel Settings</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/insurance-settings" className="hover-v">
                                    <span className="resp-not-icon">Insurance Settings</span>
                                    <span className="resp-picon">
                                        <i className="material-icons">
                                            assignment
                                        </i>
                                    </span>
                                    <span className="hover-name top">Insurance Settings</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/credit-card" className="hover-v">
                                    <span className="resp-not-icon">Company Card</span>
                                    <span className="resp-picon">
                                        <i className="material-icons">
                                            credit_card
                                        </i>
                                    </span>
                                    <span className="hover-name top">Company Card</span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        )
    }
}
export default SideMenu