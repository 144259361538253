import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { TripBar } from "../../components";
import PlacesAutocomplete from "react-places-autocomplete";

class AddTrain extends Component {
  render() {
    const { from, to, activeInput, instructions } = this.props.car;
    return (
      <div>
        <div className="mb-3">
          <div>
            {/* From */}
            <div
              className={
                activeInput !== 1
                  ? "details from half"
                  : "details from half show-popup"
              }
              ref={this.locationFromRef}
            >
              <div
                className="details-margin"
                className={
                  this.props.validateTrue && this.props.car.from === ""
                  ? "details-margin not-valid"
                  : "details-margin"
                }
                onClick={e => this.props.showInput(1, this.props.index)}
              >
                <div className="d-table d-table__middle">
                  <label htmlFor="search1" className="text-left m-label">
                    <p className="label">Pickup From</p>
                    <h6 className="place">{from}</h6>
                  </label>
                </div>
              </div>
              <div className="popup">
                <PlacesAutocomplete
                  value={this.props.car.from}
                  onChange={d =>
                    this.props.handleChangeFrom(d, this.props.index)
                  }
                  onSelect={d =>
                    this.props.handleSelectFrom(d, this.props.index)
                  }
                  autofocus={true}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                  }) => (
                      <div>
                        <div className="typ">
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "",
                              autofocus: true
                            })}
                            id="search1"
                          />
                          <div className="pin">
                            <i className="material-icons">location_on</i>
                          </div>
                        </div>

                        {suggestions.length === 0 && (
                          <ul className="scroll">
                            {this.props.popularCity.map((items, key) => {
                              return (
                                <li key={key}>
                                  <button
                                    type="button"
                                    onClick={e =>
                                      this.props.getCityFrom(
                                        items,
                                        this.props.index
                                      )
                                    }
                                  >
                                    {/* {items.city}&nbsp;{" "}
                                    <small> */}
                                    {items.name}
                                    {/* </small> */}
                                  </button>
                                  <div className="pin">
                                    <i className="material-icons">location_on</i>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        <ul className="scroll autocomplete-dropdown-container">
                          {loading && (
                            <li>
                              <button>Loading...</button>
                            </li>
                          )}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer"
                              }
                              : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer"
                              };
                            return (
                              <li
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style
                                })}
                              >
                                <button>{suggestion.description}</button>
                                <div className="pin">
                                  <i className="material-icons">location_on</i>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                </PlacesAutocomplete>
              </div>
            </div>

            {/* To */}

            <div
              className={
                activeInput !== 2
                  ? "details from half"
                  : "details from half show-popup"
              }
              ref={this.props.locationToRef}
            >
              <div
                className="details-margin"
                className={
                  this.props.validateTrue && this.props.car.to === ""
                    ? "details-margin not-valid"
                    : "details-margin"
                }
                onClick={e => this.props.showInput(2, this.props.index)}
              >
                <div className="d-table d-table__middle">
                  <label htmlFor="search2" className="text-left m-label">
                    <p className="label">Drop To</p>
                    <h6 className="place">{to}</h6>
                  </label>
                </div>
              </div>
              <div className="popup">
                <PlacesAutocomplete
                  value={this.props.car.to}
                  onChange={d => this.props.handleChangeTo(d, this.props.index)}
                  onSelect={d => this.props.handleSelectTo(d, this.props.index)}
                  autofocus={true}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                  }) => (
                      <div>
                        <div className="typ">
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "",
                              autofocus: true
                            })}
                            id="search2"
                          />
                          <div className="pin">
                            <i className="material-icons">location_on</i>
                          </div>
                        </div>

                        {suggestions.length === 0 && (
                          <ul className="scroll">
                            {this.props.popularCity.map((items, key) => {
                              return (
                                <li key={key}>
                                  <button
                                    type="button"
                                    onClick={e =>
                                      this.props.getCityTo(
                                        items,
                                        this.props.index
                                      )
                                    }
                                  >
                                    {/* {items.city}&nbsp;{" "}
                                   <small> */}
                                    {items.name}
                                    {/* </small> */}
                                  </button>
                                  <div className="pin">
                                    <i className="material-icons">location_on</i>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        <ul className="scroll autocomplete-dropdown-container">
                          {loading && (
                            <li>
                              <button>Loading...</button>
                            </li>
                          )}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer"
                              }
                              : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer"
                              };
                            return (
                              <li
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style
                                })}
                              >
                                <button>{suggestion.description}</button>
                                <div className="pin">
                                  <i className="material-icons">location_on</i>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                </PlacesAutocomplete>
              </div>
            </div>

            {/* Date */}

            <div class="details depart full">
              <div class="details-margin" className={
                  this.props.validateTrue && this.props.car.startDate === ""
                  ? "details-margin not-valid"
                  : "details-margin"}>
                <div class="d-table d-table__middle">
                  {/* <p className="label">Pickup Time</p> */}
                  <DatePicker
                    selected={this.props.car.startDate}
                    onChange={date =>
                      this.props.handleDate(date, this.props.index)
                    }
                    minDate={this.props.car.today}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
              </div>
            </div>

            {/* Speacial instructions */}

            <div class="details to full" style={{ "max-width": "92%" }}>
              <div class="details-margin" className ={
                  this.props.validateTrue && this.props.car.instructions === ""
                  ? "details-margin not-valid"
                  : "details-margin"
                }>
                <div class="d-table d-table__middle">
                  <label for="search1" class="text-left m-label">
                    <p class="label">Special Instructions</p>
                    <p class="bottom">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          name="some"
                          value={instructions}
                          onChange={e =>
                            this.props.onInstructionChange(e, this.props.index)
                          }
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "#fff",
                            cursor: "context-menu",
                            "padding-left": "0"
                          }}
                        />
                      </div>
                    </p>
                  </label>
                </div>
              </div>
            </div>

            <TripBar>
              {this.props.index !== 0 && (
                <button
                  type="button"
                  onClick={() => this.props.handleRemoveCar(this.props.index)}
                  className="btn details search remove"
                >
                  <span>
                    <i className="material-icons">remove</i>
                  </span>
                </button>
              )}
            </TripBar>
          </div>
        </div>
      </div>
    );
  }
}

export default AddTrain;
