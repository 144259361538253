import { http } from '../_helpers';
import { config } from '../env';

//Hotel contract Api's
const getCityList = (city) => {
  let url = `${config.apiUrlTravel}contract/search/city?city=${city}`;
  return http.get(url)
    .then(res => res);
}
const getCountryCodeList = (country) => {
  let url = `${config.apiUrlTravel}country-dail-codes/search?country=${country}`;
  return http.get(url)
    .then(res => {
      return res
    });
}
const saveTheSelectedCity = (data) => {
  let url = `${config.apiUrlTravel}hotel-contract-city`;
  return http.post(url, data)
    .then(res => res)
}

const getHotelDetailsInCity = (data) => {
  let url = `${config.apiUrlTravel}hotel-contract-city/list-all-cities?companyId=${data.companyId}&limit=${data.limit}&page=${data.page}`;
  return http.get(url)
    .then(res => res)
}

const getAllHotelsData = (data) => {
  let url = `${config.apiUrlTravel}hotels/list-all-hotels?hotelContractCityId=${data.cityId}&companyId=${data.companyId}&limit=${data.limit}&page=${data.page}`;
  return http.get(url)
    .then(res => res)
}
const getCurrencyType = (companyId, itemCode) => {
  let url = `${config.apiUrlCompany}list-management?companyId=${companyId}&itemCode=${itemCode}`;
  return http.get(url)
    .then(res => {
      return res
    })
}

const getHotelRoomData = () => {
  let url = `${config.apiUrlTravel}hotel/get-master-data`;
  return http.get(url)
    .then(res => {
      return res
    }
    )
}
const getAddress = (lat, lng) => {
  let url =
    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=AIzaSyBWyyK5gkujBpxYNim55MUoSfo6cDWJM6w";
  const requestOptions = {
    method: "GET"
  };
  return fetch(url, requestOptions).then(response => response.json());
}

const updateHoteldata = (id, data) => {
  let url = `${config.apiUrlTravel}hotels/update-hotel/${id}`;
  return http.patch(url, data)
    .then(res => {
      return res;
    })
}
const addNewHotel = (payload) => {
  let url = `${config.apiUrlTravel}hotel`;
  return http.post(url, payload)
    .then(res => res)
}
const uploadHotelImages = (payload) => {
  let url = `${config.apiUrlTravel}hotel/upload-hotel-image`;
  return http.fileUpload(url, payload)
    .then(res => res)
}
const onStatusChangeHandler = (id, data) => {
  let url = `${config.apiUrlTravel}hotel-contract-city/active-inactive/${id}`;
  return http.patch(url, data)
    .then(res => {
      return res;
    })
}
const removeCityHandler = id => {
  let url = `${config.apiUrlTravel}hotel-contract-city/remove-city/${id}`;
  return http.patch(url)
    .then(res => res)
};

const removeHotelHandler = (id) => {
  let url = `${config.apiUrlTravel}hotels/remove-hotel/${id}`;
  return http.patch(url)
    .then(res => res)
}
const editCityName = (id, data) => {
  let url = `${config.apiUrlTravel}hotel-contract-city/update-city/${id}`;
  return http.patch(url, data)
    .then(res => res)

}
const getHotelDetailsSearch = (companyId, search) => {
  let url = `${config.apiUrlTravel}hotel-contract-city/list-all-cities?companyId=${companyId}&search=${search}`;
  return http.get(url)
    .then(res => res)
}
const getHotelListOnSearch = (cityId, companyId, search) => {
  let url = `${config.apiUrlTravel}hotels/list-all-hotels?hotelContractCityId=${cityId}&companyId=${companyId}&search=${search}`;
  return http.get(url)
    .then(res => {
      return res
    })
}



//Car contract Api's

const saveTheSelectedCityForCar = (data) => {
  let url = `${config.apiUrlTravel}car-contract-city`;
  return http.post(url, data)
    .then(res => res)
}
const getCarDetailsInCity = (data) => {
  let url = `${config.apiUrlTravel}car-contract-city/list-all-cities?companyId=${data.companyId}&limit=${data.limit}&page=${data.page}`;
  return http.get(url)
    .then(res => res)
}
const removeCityHandlerForCar = id => {
  let url = `${config.apiUrlTravel}car-contract-city/remove-city/${id}`;
  return http.patch(url)
    .then(res => res)
};

const getAllCarsData = (data) => {
  let url = `${config.apiUrlTravel}cars/list-all-cars?companyId=${data.companyId}&carContractCityId=${data.carContractCityId}&limit=${data.limit}&page=${data.page}`;
  return http.get(url)
    .then(res => res)
}
const onStatusChangeHandlerForCar = (id, data) => {
  let url = `${config.apiUrlTravel}car-contract-city/active-inactive/${id}`;
  return http.patch(url, data)
    .then(res => res
    )
}
const removeCarHandler = (id) => {
  let url = `${config.apiUrlTravel}cars/remove-car/${id}`;
  return http.patch(url)
    .then(res => res)
}

const getCarTypes = (companyId) => {
  let url = `${config.apiUrlCompany}list-management?companyId=${companyId}&itemCode=CAR564`;
  return http.get(url)
    .then(res => {
      return res
    })
}
const uploadCarImages = (payload) => {
  let url = `${config.apiUrlTravel}car/upload-car-image`;
  return http.fileUpload(url, payload)
    .then(res => res)
}
const getCountryTypes = () => {
  let url = `${config.apiUrlSuperAdmin}countries`;
  return http.get(url)
    .then(res => {

      return res
    })
}

const getStateTypes = (countryId) => {
  let url = `${config.apiUrlSuperAdmin}states?countryId=${countryId}&isDependecyRequired=true`;
  return http.get(url)
    .then(res => res);
}
const getCityTypes = (stateId) => {
  let url = `${config.apiUrlSuperAdmin}cities?stateId=${stateId}&isDependecyRequired=true`;
  return http.get(url)
    .then(res => res);
}
const getCarGroupTypes = (companyId) => {
  let url = `${config.apiUrlCompany}list-management?companyId=${companyId}&itemCode=2375823`;
  return http.get(url)
    .then(res => {
      return res
    })
}
const updateCarDetails = (id, payload) => {
  let url = `${config.apiUrlTravel}cars/update-car/${id}`;
  return http.patch(url, payload)
    .then(res => {
      return res;
    })
}
const addNewCarDetails = (payload) => {
  let url = `${config.apiUrlTravel}cars`;
  return http.post(url, payload)
    .then(res => res);
}
const getCarDetailsSearch = (companyId, search) => {
  let url = `${config.apiUrlTravel}car-contract-city/list-all-cities?companyId=${companyId}&search=${search}`;
  return http.get(url)
    .then(res => res)
}
const getCarListOnSearch = (carContractCityId, companyId, search) => {
  let url = `${config.apiUrlTravel}cars/list-all-cars?companyId=${companyId}&carContractCityId=${carContractCityId}&search=${search}`;
  return http.get(url)
    .then(res => res)
}

export const contractServices = {
  getCityList,
  saveTheSelectedCity,
  getHotelDetailsInCity,
  getAllHotelsData,
  getCurrencyType,
  getHotelRoomData,
  getAddress,
  addNewHotel,
  updateHoteldata,
  getCountryCodeList,
  uploadHotelImages,
  onStatusChangeHandler,
  removeCityHandler,
  removeHotelHandler,
  editCityName,
  saveTheSelectedCityForCar,
  getCarDetailsInCity,
  removeCityHandlerForCar,
  getAllCarsData,
  onStatusChangeHandlerForCar,
  removeCarHandler,
  getCarTypes,
  uploadCarImages,
  getCountryTypes,
  getStateTypes,
  getCityTypes,
  getCarGroupTypes,
  updateCarDetails,
  addNewCarDetails,
  getCarDetailsSearch,
  getHotelDetailsSearch,
  getCarListOnSearch,
  getHotelListOnSearch
}