import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class WorkFlowStepsMenu extends Component {
    render() {
        return (
            <div>
                <div className="audit-step">
                    <NavLink className="s1" to={(this.props.workflow_id) ? `/administration/company/workflows/workflow-step1/${this.props.workflow_id}` : "/administration/company/workflows/workflow-step1"}>
                        <span>1</span>
                        General
                    </NavLink>
                    <NavLink className="s1" to={(this.props.workflow_id) ? `/administration/company/workflows/workflow-step2/${this.props.workflow_id}` : "#"}>
                        <span>2</span>
                        Steps
                        </NavLink>
                    <NavLink className="s1" to={(this.props.workflow_id && this.props.count > 0) ? `/administration/company/workflows/workflow-step3/${this.props.workflow_id}` : "#"}>
                        <span>3</span>
                        Step rules
                    </NavLink>
                </div>
            </div>
        )
    }
}
export default WorkFlowStepsMenu