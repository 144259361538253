import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import Loader from './../../../../../common/Loader';

const CustomPage = props => {
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">A</div>
            <div className="right">
              <h6 className="heading">Audit Rules</h6>
              <p className="text">Audit rule is used to monitor information entered by Expense users and apply exceptions.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="table-container mx-0">
          <form className="mb-3">
            <div className="row">
              <div className="col-md-9">
                <div className="btn-group-margin">
                  <NavLink
                    to="/administration/company/audit-rules/step1"
                    className="btn"
                  >
                    New
                </NavLink>

                  {/* <button type="button" className="btn" disabled>
                    Copy
                </button>
                  <button type="button" className="btn" disabled>
                    Activate
                </button>
                  <button type="button" className="btn" disabled>
                    Deactivate
                </button> */}
                  <button onClick={props.onDeleteAuditRuleHandler} type="button" className="btn" disabled={props.selectedAuditRuleId.length <= 0}>
                    Remove
                </button>
                </div>
              </div>
              <div className="col-md-3">
                <div className="search-custom">
                  <input
                    type="text"
                    name=""
                    value={props.search}
                    onChange={(e) => props.onSearchTextChangeHandler(e)}
                    className="form-control"
                    placeholder="Search"
                  />
                  <span>
                    <i className="material-icons">search</i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped table-selected">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Exception Code</th>
                  <th>Editable By</th>
                  <th>Applies To</th>
                  <th>Message</th>
                  <th>Status</th>
                </tr>
              </thead>
              {!props.isLoading ?
                <tbody>
                  {props.AllAuditRules && props.AllAuditRules.length > 0 ? props.AllAuditRules.map((list) => {
                    return (
                      <tr className={props.selectedAuditRuleId.includes(list.id) ? 'active-tr' : ''}
                        key={list.id}>
                        <td>
                          <NavLink to={`/administration/company/audit-rules/step1/${list.id}`}>
                            {list.name}
                          </NavLink>
                        </td>
                        <td onClick={() => props.onSelectAuditRuleHandler(list.id)}>{list.exceptionId && list.exceptionId.exceptionCode}</td>
                        <td onClick={() => props.onSelectAuditRuleHandler(list.id)}>
                          {list.editableObj && list.editableObj.map((editLists, index) => {
                            if (list.editableBy.length - 1 > index) {
                              return editLists.itemName + ',';
                            } else {
                              return editLists.itemName;
                            }
                          })
                          }
                        </td>
                        <td onClick={() => props.onSelectAuditRuleHandler(list.id)}>
                          {list.appliesObj && list.appliesObj.map((editLists, index) => {
                            if (list.appliesTo.length - 1 > index) {
                              return editLists.itemName + ',';
                            } else {
                              return editLists.itemName;
                            }
                          })
                          }
                        </td>
                        <td>{list.exceptionId && list.exceptionId.message}</td>
                        <td >
                          <label className="switch">
                            <input
                              onChange={(e) => props.onStatusChangeHandler(e, list.activeStatus, list.id)}
                              value={list.activeStatus}
                              checked={list.activeStatus}
                              type="checkbox" />
                            <span className="slider round" />
                          </label>
                        </td>
                      </tr>
                    )
                  }) :
                    <tr><td colSpan={6} className="text-center">No Records Found.</td></tr>
                  }
                </tbody>
                :
                <tbody>
                  <tr><td colSpan={6}><Loader /></td></tr>
                </tbody>
              }
            </table>
          </div>
          <div className="table-footer">
            <div className="row">
              <div className="col-md-3">
                <div className="page-count">
                  <div className="text">Items</div>
                  <div className="count">
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        {props.limit}
                      </button>
                      <div className="dropdown-menu">
                        <button
                          onClick={() => props.onPageLimitChangeHandler(10)}
                          type="button" className="dropdown-item">
                          10
                        </button>
                        <button
                          onClick={() => props.onPageLimitChangeHandler(20)}
                          type="button" className="dropdown-item">
                          20
                        </button>
                        <button onClick={() => props.onPageLimitChangeHandler(30)} type="button" className="dropdown-item">
                          30
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <Pagination
                  activePage={props.pageNumber}
                  itemsCountPerPage={props.limit}
                  totalItemsCount={props.totalRules}
                  pageRangeDisplayed={5}
                  onChange={props.handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomPage;
