import React, { Component } from "react";
import $ from "jquery";
import CryptrDetail from "cryptr";
import cryptr from "../../../_helpers/crypty";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";
import { expenseReport } from "../../../_services/expenseReport";
import ExpenseTypes from "./components/expenseDetail/expenseType";
import ItemizeExpenseTypes from "./components/expenseDetail/itemizeExpenseTypes";
import ItemizeExpenseTable from "./components/expenseDetail/itemizeExpenseTable";
import AddNewAttendee from "./components/expenseDetail/addNewAttendee";
import ReportHeaderTable from "./components/expenseDetail/reportHeaderTable";
import ExpenseReportWarning from "./components/expenseDetail/expenseReportWarning";
import ExpenseLeftSidebarSummary from "./components/expenseDetail/expenseLeftSidebarSummary";
import CreditDepositSummary from "./components/expenseDetail/creditDepositSummary";
import ReportTopHeader from "./components/expenseDetail/reportTopHeader";
import ReportHeaderUploadReceipt from "./components/expenseDetail/reportHeaderUploadReceipt";
import NewAttendeeList from "./components/expenseDetail/newAttendeeList";
//import AttendeeDetails from "./components/expenseDetail/attendeeDetails";
import FavouriteAttendeeList from "./components/expenseDetail/favouriteAttendeeList";
import ImportAttendeeModel from "./components/expenseDetail/importAttendeeModel";
import ReceiptDeclarationAttach from "./components/expenseDetail/receiptDeclarationAttach";
import ItemisationStatics from "./components/expenseDetail/itemisationStatics";
//import UploadItemisationReceipt from "./components/expenseDetail/uploadItemisationReceipt";
import DetailDropdownItem from "./detailDropdownItem";
// import AuditTrail from "./components/audit-trail";
//import ExpenseAllocationTable from "./components/expenseAllocation/expenseAllocationTable";
import ExpenseDeleteModal from "./components/expenseDetail/expenseDeleteModal";
import AvailableCashadvnace from "./components/expenseDetail/availableCashadvnace";
import AssignedCashAdvnace from "./components/expenseDetail/assignedCashAdvance";
import ExpenseReportTotalModal from "./components/expenseDetail/expenseReportTotalModal";
import ExpenseReportUserInfoModel from "./components/expenseDetail/expenseReportUserInfoModel";
import WorkFlowApprovalSteps from "./components/expenseDetail/workFlowApprovalSteps";
import DeleteExpenseEntryModal from "./components/expenseDetail/deleteExpenseEntryModal";
import DeleteItemisationConfirmModal from "./components/expenseDetail/deleteItemisationConfirmModal";
import ExpenseSubmitConfirmModal from "./components/expenseDetail/expenseSubmitConfirmModal";
import RecallConfirmModal from "./components/expenseDetail/recallConfirmModal";
import AllocatedGraphModal from "./components/expenseDetail/allocatedGraphModal";
import AuditTrial from "./components/expenseDetail/auditTrial";
import { mergeFormAndFields, mergeExpenseEntryMultyData } from "src/utils/util";
import { expenseServices } from "src/_services";
import Loader from "../../common/Loader";
import ChatApp from "../chat-app/chatPage";
import SendBackToEmployee from "../administrator/company/workflows/Components/Modals/SendBackToEmployee";
import { getValue, setValue } from "src/_components/common/lodsh";
// import ExpenseEntryAllocationPage from './expenseEntryAllocation';

@inject("expenseStore")
@observer
class ExpenseDetailsPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      companyId: localStorage["440a28"],
      expenseType: [],
      pageName: "ExpenseDetail",
      removeItemization: false,
      selectedItemizeExp: "",
      headerReceipt: "",
      addFavouriteEmployee: "",
      itemizeExpenseTable: [],
      itemization: 0,
      selectedExenseType: "",
      itemExpenseType: [],
      itemSelectedExenseType: "",
      itemizationFields: "",
      formFields: [],
      error: [],
      expenseEntryList: [],
      reportHeader: [],
      policyDetail: [],
      expenseEntryDetails: [],
      totoalExpense: 0,
      itemizationForm: [],
      totalItemiseExpenseAmount: 0,
      slectedExpenseEntryAmount: 0,
      allReceipt: [],
      userId: "",
      selectedReceiptIds: [],
      selectedReceiptImages: [],
      browseImage: [],
      fileName: [],
      browseAllImages: "",
      headerName: [],
      allAdvanceRequest: [],
      selectedCashAdvanceReceipt: [],
      remainingCashAdvanceReceipt: [],
      s3ReceiptUrl: [],
      maxImageViewReceipt: "",
      maxImageViewReceiptId: "",
      getAllAssignedCashAdavnce: [],
      selectedAssigendCashAdvance: [],
      headerAssignedCashAdavnce: [],
      selctedAssignedCashAdvance: [],
      selectedCashAdvanceReceiptIds: [],
      selectedCashAdvAttachReceiptIds: [],
      auditRuleValidationsError: [],
      actionButtons: [],
      totalExpenseAmount: 0,
      affidavateStatus: "",
      userBaseCurrency: "",
      cashAdvanceTotalAmount: "",
      totalReportAmounts: [],
      totalRequestAmount: 0,
      submitStatus: "",
      validationMessage: [],
      validationStatus: false,
      validationStatusArray: [],
      isExpenseLoading: false,
      isPageLoading: true,
      totalAmountWithOutConversion: "",
      isTotalLoading: true,
      isSubmitClick: false,
      expenseEntryCurrency: "",
      selectedItemisationDeleteEntry: "",
      loadingItemisationExpType: false,
      isRecallClick: false,
      enablePrintTicket: false,
      rejectExpenseMessage: "",
      approvalList: [],
      workflowId: "",
      headerDetails: [],
      isFromWorkFlow: false,
      allocationDetails: [],
      isGraphDataLoading: true,
      isMileage: false,
      isMileageExpense: false,
      isItemisationOpen: false,
      ExpenseTypeHeader: "",
      selectedExpenseType: "",
      imageType: "",
      CommentsFromApprover: "",
      history: "",
      expenseUniqueCode: "",
      reportId: "",
      requestId: "",
      headerObj: [],
      isLoading: true
    };
  }
  page = 1;
  limit = 10;
  count = 0;

  componentDidMount() {
    localStorage.setItem("permissionStatus", "employee-role");
    sessionStorage.removeItem("from");
    let currentUser = JSON.parse(
      cryptr.decrypt(localStorage.getItem("440a24"))
    );
    let workflowId = this.props.match.params.workflowId;
    if (
      workflowId !== "" &&
      workflowId !== undefined &&
      workflowId !== null &&
      workflowId !== "details"
    ) {
      this.getCreatedExpensesFromWorkFlow();
      this.setState({ workflowId: workflowId });
    } else {
      this.setState({ userId: currentUser["id"] });
      this.getAllexpenseTypes();
      // v2 New APIs updates
      this.getLeftsidebarData();
      this.getAllCreatedExpenses();

      let expenseEntryId = localStorage.getItem("expenseEntryId");
      if (expenseEntryId) {
        this.getItemizationList(expenseEntryId);
      }
      if (
        expenseEntryId !== undefined &&
        expenseEntryId !== "" &&
        expenseEntryId !== null
      ) {
        this.onSelectExpenstEntryList(expenseEntryId);
      }
    }
  }

  closeAuditTrail() {
    $("#audit-trail-c").removeClass("show");
  }
  openAuditTrail() {
    $("#audit-trail-c").addClass("show");
  }
  checkApprovedDataAndIsuued = (
    cashAdvanceResult,
    workflowSteps,
    workflowCurrent
  ) => {
    let checkStatus = () => {
      return cashAdvanceResult.current.STATUS
        ? cashAdvanceResult.current.STATUS
        : false;
    };
    let checkWorkflowSteps = () => {
      return workflowCurrent && workflowCurrent.currentOrderNo
        ? workflowSteps[workflowCurrent.currentOrderNo] &&
          workflowSteps[workflowCurrent.currentOrderNo].intialStatus
          ? workflowSteps[workflowCurrent.currentOrderNo].intialStatus
          : ""
        : "";
    };
    let getCashDataStatus = () => {
      return cashAdvanceResult.EXPENSE_DATA &&
        cashAdvanceResult.EXPENSE_DATA.headerObj
        ? cashAdvanceResult.EXPENSE_DATA.headerObj.status
        : "Approved";
    };

    return checkStatus() ? getCashDataStatus() : checkWorkflowSteps();
  };

  // v2 itemizationForm API

  getItemizationList = async expenseEntryId => {
    let response = await expenseReport.getExpenseEntryItemizationDetails(
      expenseEntryId
    );
    if (response) {
      console.log(response, "------->v2 itemizationForm response");
      this.setState({
        isItemisationOpen:
          response.itemizationForm &&
          Object.keys(getValue(response, "itemizationForm", [])).length
            ? true
            : false,
        itemizationForm: getValue(response, "itemizationForm", [])
      });
      const detailCryptr = new CryptrDetail("expense@Details");

      const remainigItemiseAmount =
        response.remainingAmount != null &&
        response.remainingAmount !== undefined &&
        detailCryptr.encrypt(response.remainingAmount);
      localStorage.setItem("remainigItemise", remainigItemiseAmount);
    }
  };

  getCreatedExpensesFromWorkFlow = async () => {
    this.setState({ isPageLoading: true });
    let response = await expenseReport.getApprovalExpenseEntryDetails(
      this.props.match.params.workflowId
    );
    if (
      response &&
      response.result &&
      response.result.EXPENSE_DATA &&
      response.result.EXPENSE_DATA.headerObj
    ) {
      this.getLeftsidebarData();
      let approvalList = [];
      for (const key in response.result.info.steps) {
        approvalList.push(response.result.info.steps[key]);
      }
      let expenseEntryList = mergeExpenseEntryMultyData(
        response.result.EXPENSE_DATA.expenseReportEntryObj
      );
      let currentWorkFlowOrderNo = response.result.current;
      let workFlowStepsStatus = response.result.info.steps;
      let submitStatus = this.checkApprovedDataAndIsuued(
        response.result,
        workFlowStepsStatus,
        currentWorkFlowOrderNo
      );
      let rejectExpenseMessage =
        submitStatus === "Reject"
          ? getValue(response.result, "RESPONSE.comment", "")
          : "";
      this.setState({
        reportHeader: mergeFormAndFields(
          response.result.EXPENSE_DATA.headerObj.headerForm
        ),
        // expenseEntryList: expenseEntryList,
        navigationPaths: response.result.EXPENSE_DATA.navigationPaths,
        policyDetail: response.result.EXPENSE_DATA.headerObj.policyId,
        // totoalExpense:
        //   response.result.EXPENSE_DATA.expenseReportEntryObj.length,
        totalExpenseAmount: response.result.EXPENSE_DATA.totalAmount,
        userBaseCurrency: response.result.EXPENSE_DATA.baseCurrency,
        cashAdvanceTotalAmount:
          response.result.EXPENSE_DATA.cashAdvanceTotalAmount,
        totalRequestAmount:
          // response.result.EXPENSE_DATA.headerObj.amountDueEmployee,
          response.result.EXPENSE_DATA.requestAmount,
        submitStatus: submitStatus,
        rejectExpenseMessage: rejectExpenseMessage,
        actionButtons: response.result.current.actionButton,
        policyId: response.result.EXPENSE_DATA.headerObj.policyId.id,
        headerId: response.result.EXPENSE_DATA.headerObj.id,
        approvalList: approvalList,
        headerDetails: response.result.EXPENSE_DATA.headerObj,
        isFromWorkFlow: true
      });
      if (response.result.EXPENSE_DATA.expenseReportEntryObj > 0) {
        localStorage.setItem(
          "expenseEntryId",
          response.result.EXPENSE_DATA.expenseReportEntryObj[0].id
        );
        this.props.expenseStore.setSelectExpenstEntryList(
          response.result.EXPENSE_DATA.expenseReportEntryObj[0].id
        );
        this.setState({
          isMileageExpense: response.result.EXPENSE_DATA
            .expenseReportEntryObj[0].navigationPaths.length
            ? true
            : false
        });
      }
      // if (response.result.EXPENSE_DATA.expenseReportEntryObj.length > 0)
      //   localStorage.setItem(
      //     "expenseEntryId",
      //     response.result.EXPENSE_DATA.expenseReportEntryObj[0].id
      //   );
      this.setState({ isPageLoading: false });
      let expenseEntryId = localStorage.getItem("expenseEntryId");
      if (
        expenseEntryId !== undefined &&
        expenseEntryId !== "" &&
        expenseEntryId !== null
      ) {
        this.setState({ selectExpenstEntryList: expenseEntryId });
        this.onSelectExpenstEntryList(expenseEntryId);
      }
      //this.getAllexpenseTypes();
    } else {
      this.setState({ isPageLoading: false });
    }
  };

  showSelectedEntryFOrmDetail = async () => {
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    if (expenseEntryId) {
      let response = await expenseReport.getExpenseEntryDetails(expenseEntryId);
      if (response) {
        this.props.expenseStore.setEntryItemizationAvailable(response);
        this.setState({
          itemization: response.expenseType.itemisation,
          expenseEntryDetails: response.entryForm
        });
      }
    }
  };

  // v2 left sidebar API common api after delete
  getLeftsidebarDataRefetch = async () => {
    this.page = 1;
    let headerId = localStorage.getItem("headerId");
    if (headerId) {
      let response = await expenseReport.getAllCreatedExpenseNew(
        this.state.companyId,
        headerId,
        this.limit,
        this.page
      );
      console.log(response, "--->v2 for sidebar respnse refetch");
      // for setting state
      const { expenseEntryList } = this.state;
      if (response && response.result.expenseReportEntryObj) {
        this.setState({ isLoading: false });
        this.count = response.count;
        this.setState({
          expenseEntryList: response.result.expenseReportEntryObj,
          totoalExpense: response.result.expenseReportEntryObj.length,
          isLoading: false
        });
        // localStorage.setItem("headerId", response.result.expenseReportEntryObj[0].headerId)
        // this.onSelectExpenstEntryList(
        //   response.result.expenseReportEntryObj[0].id
        // );
        // validation starts:
        let validationStatus = true;
        let validationStatusArrays = [];
        response.result.expenseReportEntryObj &&
          response.result.expenseReportEntryObj.forEach(list => {
            validationStatus = list.validation;
            validationStatusArrays.push(list.validation);
          });
        this.setState({
          validationStatus: validationStatus,
          validationStatusArray: validationStatusArrays
        });
        // ends
        this.setState({ ...this.state });
        let expenseEntryId = localStorage.getItem("expenseEntryId");
        if (
          response.result.expenseReportEntryObj.length > 0 &&
          (expenseEntryId === "" ||
            expenseEntryId === undefined ||
            expenseEntryId === null)
        ) {
          localStorage.setItem(
            "expenseEntryId",
            response.result.expenseReportEntryObj[0].id
          );
          this.props.expenseStore.setSelectExpenstEntryList(
            response.result.expenseReportEntryObj[0].id
          );
          // this.onSelectExpenstEntryList(
          //   response.result.expenseReportEntryObj[0].id
          // );
        }
      } else {
        this.setState({
          isLoading: false,
          expenseEntryList: []
        });
      }
    }
  };

  // v2 left sidebar API
  getLeftsidebarData = async () => {
    let headerId = localStorage.getItem("headerId");
    if (headerId) {
      let response = await expenseReport.getAllCreatedExpenseNew(
        this.state.companyId,
        headerId,
        this.limit,
        this.page
      );
      console.log(response, "--->v2 for sidebar respnse");
      // for setting state
      const { expenseEntryList } = this.state;
      if (response && response.result.expenseReportEntryObj) {
        this.setState({ isLoading: false });
        this.count = response.count;
        if (expenseEntryList.length > 0) {
          this.setState({
            expenseEntryList: expenseEntryList.concat(
              response.result.expenseReportEntryObj
            ),
            totoalExpense: response.result.expenseReportEntryObj.length,
            isLoading: false
          });

          let expenseEntryId = localStorage.getItem("expenseEntryId");
          if (
            response.result.expenseReportEntryObj.length > 0 &&
            (expenseEntryId === "" ||
              expenseEntryId === undefined ||
              expenseEntryId === null)
          ) {
            localStorage.setItem(
              "expenseEntryId",
              response.result.expenseReportEntryObj[0].id
            );
            this.props.expenseStore.setSelectExpenstEntryList(
              response.result.expenseReportEntryObj[0].id
            );
            this.onSelectExpenstEntryList(
              response.result.expenseReportEntryObj[0].id
            );
          }
        } else {
          this.setState({
            expenseEntryList: response.result.expenseReportEntryObj,
            totoalExpense: response.result.expenseReportEntryObj.length,
            isLoading: false
          });
          // localStorage.setItem("headerId", response.result.expenseReportEntryObj[0].headerId)
          this.onSelectExpenstEntryList(
            response.result.expenseReportEntryObj[0].id
          );
          let validationStatus = true;
          let validationStatusArrays = [];
          response.result.expenseReportEntryObj &&
            response.result.expenseReportEntryObj.forEach(list => {
              validationStatus = list.validation;
              validationStatusArrays.push(list.validation);
            });
          this.setState({
            validationStatus: validationStatus,
            validationStatusArray: validationStatusArrays
          });
          this.setState({ ...this.state });
        }
      } else {
        this.setState({
          isLoading: false,
          expenseEntryList: []
        });
      }
    }
  };

  // v2 report header details API
  getAllCreatedExpenses = async () => {
    let headerId = localStorage.getItem("headerId");
    if (headerId) {
      let response = await expenseReport.getAllCreatedExpense(
        this.state.companyId,
        headerId
      );
      console.log(response, "v2----->for report headerdetails");
      if (response) {
        if (
          response.expenseReportEntryObj &&
          response.expenseReportEntryObj.length !== 0
        ) {
          let array = getValue(response, "expenseReportEntryObj", []);
          for (let i = 0; i < array.length; i++) {
            getValue(
              response,
              "expenseReportEntryObj[i].entryForm",
              []
            ).forEach(data => {
              if (data.fieldName === "History" && data.fieldValue) {
                if (data.fieldRolePermissions.length !== 0) {
                  if (data.fieldRolePermissions[0].permissionId !== "0") {
                    setValue(
                      response.expenseReportEntryObj[i],
                      "history",
                      "true"
                    );
                  }
                } else {
                  setValue(
                    response.expenseReportEntryObj[i],
                    "history",
                    "true"
                  );
                }
              }
            });
          }
        } else {
          console.log("error");
        }
      } else {
        console.log("there is no proper data");
      }
      if (response && response.headerObj) {
        this.setState({
          reportHeader: response.headerObj.headerForm,
          policyDetail: response.headerObj.policyId,
          reportId: response.headerObj.reportId,
          requestId: response.headerObj.requestId,
          // totoalExpense: response.expenseReportEntryObj.length,
          totalExpenseAmount: response.totalAmount,
          userBaseCurrency: response.baseCurrency,
          cashAdvanceTotalAmount: response.cashAdvanceTotalAmount,
          totalRequestAmount: response.requestAmount,
          submitStatus: response.headerObj.status,
          CommentsFromApprover: response.headerObj.additionalStatus,
          headerObj: response.headerObj
        });
        // let expenseEntryId = localStorage.getItem("expenseEntryId");
        // if (
        //   response.expenseReportEntryObj.length > 0 &&
        //   (expenseEntryId === "" ||
        //     expenseEntryId === undefined ||
        //     expenseEntryId === null)
        // ) {
        //   localStorage.setItem(
        //     "expenseEntryId",
        //     response.expenseReportEntryObj[0].id
        //   );
        //   this.props.expenseStore.setSelectExpenstEntryList(
        //     response.expenseReportEntryObj[0].id
        //   );
        //   this.onSelectExpenstEntryList(response.expenseReportEntryObj[0].id);
        // }
        this.setState({ isPageLoading: false });
        this.setState({ ...this.state });
      } else {
        this.setState({ isPageLoading: false });
      }
    } else {
      this.props.history.push("./home");
    }
  };

  getAllexpenseTypes = async () => {
    let policyId = localStorage.getItem("policyId");
    let expenseTypeFilter = 0;
    let response = await expenseReport.getExpenseTypes(
      this.state.companyId,
      policyId,
      expenseTypeFilter
    );
    if (response && Object.keys(response.expenseTypesList).length > 0) {
      this.setState({
        expenseType: response.expenseTypesList
      });
    } else {
      this.setState({
        expenseType: []
      });
    }
  };

  redirect(e) {
    $(".close").click();
    $(".itemisationExpenseModal").close();
  }
  onClickCreateItemisationModal = async () => {
    this.setState({ loadingItemisationExpType: true });
    let policyId = localStorage.getItem("policyId");
    let expenseTypeFilter = 1;
    let response = await expenseReport.getExpenseTypes(
      this.state.companyId,
      policyId,
      expenseTypeFilter
    );
    if (response && Object.keys(response.expenseTypesList).length > 0) {
      this.setState({
        itemExpenseType: response.expenseTypesList,
        loadingItemisationExpType: false
      });
    } else {
      this.setState({
        itemExpenseType: [],
        loadingItemisationExpType: false
      });
    }
  };
  onSearchExpenseType = async event => {
    let searchText = event.target.value;
    let policyId = localStorage.getItem("policyId");
    let expenseTypeFilter = 0;
    let response = await expenseReport.getSearchExpenseType(
      this.state.companyId,
      policyId,
      searchText,
      expenseTypeFilter
    );
    if (
      searchText !== "" &&
      Object.keys(response.expenseTypesList).length > 0
    ) {
      this.setState({ expenseType: response.expenseTypesList });
    } else if (searchText === "") {
      this.getAllexpenseTypes();
    } else {
      this.setState({ expenseType: [] });
    }
  };

  onRedirectHandler = event => {
    if (this.state.selectedExenseType) {
      if (this.state.isMileage) {
        $(".close").click();
        sessionStorage.setItem("from", "Expense");
        this.props.history.push("./add-mileage");
      } else {
        sessionStorage.setItem("from", "Expense");
        $(".close").click();
        this.props.history.push("./types");
      }
    }
  };
  //start create itemisation operations
  //this use for itemisation expensetype modal
  onItemSearchExpenseType = async event => {
    let searchText = event.target.value;
    let policyId = localStorage.getItem("policyId");
    let expenseTypeFilter = 1;
    let response = await expenseReport.getSearchExpenseType(
      this.state.companyId,
      policyId,
      searchText,
      expenseTypeFilter
    );
    if (
      searchText !== "" &&
      Object.keys(response.expenseTypesList).length > 0
    ) {
      this.setState({ itemExpenseType: response.expenseTypesList });
    } else if (searchText === "") {
      this.onClickCreateItemisationModal();
    } else {
      this.setState({ itemExpenseType: [] });
    }
  };

  onItemCancelHandler = () => {
    this.setState({ itemSelectedExenseType: "" });
  };

  onItemizeContinueHandler = event => {
    this.props.history.push("./create-itemisation");
  };
  //end create itemisation operations

  // v2 entryfield API
  onSelectExpenstEntryList = async (expenseEntryId, expenseTypeId) => {
    this.setState({ itemizationForm: [], isItemisationOpen: false });
    this.setState({ ...this.state.expenseEntryList });
    this.getAllCreatedExpenses();

    this.setState({ isExpenseLoading: true });
    if (expenseEntryId !== undefined && expenseEntryId !== "") {
      localStorage.setItem("expenseEntryId", expenseEntryId);
      localStorage.setItem("expenseType", expenseTypeId);
    }
    let companyId = this.state.companyId;
    if (expenseEntryId) {
      this.getItemizationList(expenseEntryId);
    } else {
      this.getItemizationList(localStorage.getItem("expenseEntryId"));
    }
    this.props.expenseStore.setSelectExpenstEntryList(expenseEntryId);
    let response = await expenseReport.getExpenseEntryDetails(
      companyId,
      expenseEntryId
    );
    if (response) {
      console.log(response, "---->v2 for selected entryfield");
      this.setState({ isExpenseLoading: false });
      this.props.expenseStore.setEntryItemizationAvailable(response);
      let allReceipt = getValue(
        response,
        "expenseReportEntryObj.s3ReceiptUrl",
        []
      ).filter(images => images.status === true);
      this.setState({
        // itemization:
        itemization: getValue(
          response,
          "expenseReportEntryObj.expenseType.itemization",
          ""
        ),
        // for entry
        expenseEntryDetails: getValue(
          response,
          "expenseReportEntryObj.entryForm",
          []
        ),
        s3ReceiptUrl: allReceipt,
        navigationPaths: getValue(
          response,
          "expenseReportEntryObj.navigationPaths",
          []
        ),
        affidavateStatus: getValue(
          response,
          "expenseReportEntryObj.affidavateStatus",
          false
        ),
        slectedExpenseEntryAmount: getValue(
          response,
          "expenseReportEntryObj.totalAmount",
          ""
        ),
        validationMessage: getValue(
          response,
          "expenseReportEntryObj.validationArray",
          []
        ),
        // totalAmountWithOutConversion: getValue(
        //   response,
        //   "expenseReportEntryObj.totalAmountWithOutConversion",
        //   ""
        // ),
        totalAmountWithOutConversion: getValue(
          response,
          "expenseReportEntryObj.amount",
          ""
        ),
        totalItemiseExpenseAmount: getValue(
          response,
          "expenseReportEntryObj.itemizedTotalAmount",
          ""
        ),

        expenseEntryCurrency: getValue(
          response,
          "expenseReportEntryObj.currency",
          ""
        ),
        isMileageExpense: getValue(
          response,
          "expenseReportEntryObj.navigationPaths.length"
        )
          ? true
          : false,
        ExpenseTypeHeader: getValue(
          response,
          "expenseReportEntryObj.expenseType.expenseTypeName"
        ),
        selectedExpenseType: getValue(
          response,
          "expenseReportEntryObj.expenseType"
        )
      });
      const detailCryptr = new CryptrDetail("expense@Details");
      const remainigItemiseAmount =
        response.expenseReportEntryObj.remainingAmount != null &&
        response.expenseReportEntryObj.remainingAmount !== undefined &&
        detailCryptr.encrypt(response.expenseReportEntryObj.remainingAmount);
      const currency =
        response.expenseReportEntryObj.currency != null &&
        response.expenseReportEntryObj.currency !== undefined &&
        detailCryptr.encrypt(response.expenseReportEntryObj.currency);
      localStorage.setItem("remainigItemise", remainigItemiseAmount);
      localStorage.setItem("currency", currency);
      localStorage.setItem(
        "expenseType",
        response.expenseReportEntryObj.expenseType.id
      );
      if (allReceipt.length > 0) {
        this.setState({
          maxImageViewReceipt: allReceipt[0].uploadReceipt,
          maxImageViewReceiptId: allReceipt[0].id
        });
      } else {
        this.setState({
          maxImageViewReceipt: "",
          maxImageViewReceiptId: ""
        });
      }
    }
  };

  onExpenseEditClickHandler = () => {
    sessionStorage.setItem("from", "Expense");
    if (
      this.props.match.params.workflowId !== undefined &&
      this.props.match.params.workflowId !== ""
    ) {
      this.props.history.push(
        `../edit-expense-entry/${this.props.match.params.workflowId}`
      );
    } else {
      const isMileageExpense = this.state.isMileageExpense;
      if (!isMileageExpense) {
        if (this.state.submitStatus === "Pending Submission") {
          localStorage.removeItem("permissionStatus");
          localStorage.setItem("permissionStatus", "employee-role");

          this.props.history.push(`./edit-expense-entry`);
        }
      } else {
        localStorage.removeItem("permissionStatus");
        localStorage.setItem("permissionStatus", "employee-role");
        const expenseEntryId = localStorage.getItem("expenseEntryId");
        this.props.history.push(`./add-mileage/${expenseEntryId}`);
      }
    }
  };
  onClickEditReportHeader = () => {
    this.props.history.push(`./edit-report-header`);
  };

  onDeleteExpenseEntry = async () => {
    let selectExpenstEntryList = this.props.expenseStore.selectExpenstEntryList;
    if (selectExpenstEntryList) {
      let response = await expenseReport.deleteExpenseEntryForm(
        selectExpenstEntryList
      );
      if (response) {
        $(".close").click();
        this.getLeftsidebarDataRefetch();
        localStorage.removeItem("expenseEntryId");
        this.props.expenseStore.setSelectExpenstEntryList("");
        toast.error(response.message);
      }
    }
  };
  selectItemizeExpense = imtemizeExpense => {
    if (imtemizeExpense === this.state.selectedItemizeExp) {
      this.setState({ selectedItemizeExp: "", removeItemization: false });
    } else {
      this.setState({
        selectedItemizeExp: imtemizeExpense,
        removeItemization: true
      });
    }
  };

  onBrowseHeaderReceipt = event => {
    let fileName = event.target.files[0];
    let browseImage = URL.createObjectURL(event.target.files[0]);
    this.setState({ headerReceipt: fileName, browseImage: browseImage });
  };
  uploadHeaderReceipt = event => {
    event.preventDefault();
    let fileName = this.state.headerReceipt;
    const formData = new FormData();
    formData.append("file", fileName);
  };

  onFavoriteHandler = favouriteEmployeeName => {
    if (favouriteEmployeeName === this.state.addFavouriteEmployee) {
      this.setState({ addFavouriteEmployee: "" });
    } else {
      this.setState({ addFavouriteEmployee: favouriteEmployeeName });
    }
  };
  onAddExpenseHandler = expenseType => {
    this.props.expenseStore.setExpenseType(expenseType);
  };

  onSelectExpenseType = (epxpenseType, id, isMileage) => {
    if (isMileage && isMileage.code) {
      localStorage.removeItem("expenseUniqueCode");
      localStorage.setItem("expenseUniqueCode", isMileage.code);
      if (this.state.selectedExenseType === id) {
        localStorage.removeItem("expenseType");
        localStorage.removeItem("expenseTypeName");
        this.setState({ selectedExenseType: "", isMileage: false });
      } else {
        localStorage.setItem("expenseType", id);

        this.setState({
          selectedExenseType: id,
          isMileage: isMileage
            ? isMileage.name === "Mileage"
              ? true
              : false
            : false
        });
        localStorage.setItem("expenseTypeName", epxpenseType);
      }
    } else {
      localStorage.removeItem("expenseUniqueCode");
      if (this.state.selectedExenseType === id) {
        localStorage.removeItem("expenseType");
        localStorage.removeItem("expenseTypeName");
        this.setState({ selectedExenseType: "", isMileage: false });
      } else {
        localStorage.setItem("expenseType", id);

        this.setState({
          selectedExenseType: id,
          isMileage: isMileage
            ? isMileage.name === "Mileage"
              ? true
              : false
            : false
        });
        localStorage.setItem("expenseTypeName", epxpenseType);
      }
    }
  };

  onItemSelectExpenseType = async (epxpenseType, id) => {
    if (this.state.itemSelectedExenseType === id) {
      localStorage.removeItem("itemSelectedExenseType");
      localStorage.removeItem("itemSelectedExenseTypeName");
      this.setState({ itemSelectedExenseType: "" });
    } else {
      localStorage.setItem("itemSelectedExenseType", id);
      localStorage.setItem("itemSelectedExenseTypeName", epxpenseType);
      this.setState({ itemSelectedExenseType: id });
    }
  };

  onItemisationEditClickHandler = (expenseTypeId, itemization_id) => {
    localStorage.setItem("imtmExpTypeId", expenseTypeId);
    localStorage.setItem("itemization_id", itemization_id);
    if (
      this.props.match.params.workflowId !== undefined &&
      this.props.match.params.workflowId === ""
    )
      this.props.history.push(`../edit-itemisation/${this.props.match.params}`);
    else this.props.history.push(`./edit-itemisation`);
  };

  onItemisationSelectedDeleteClickHandler = (expenseTypeId, itemization_id) => {
    this.setState({ selectedItemisationDeleteEntry: itemization_id });
  };

  onItemisationDeleteCancelHandler = () => {
    this.setState({ selectedItemisationDeleteEntry: "" });
  };

  onItemisationDeleteClickHandler = async () => {
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let responseItmDlete = await expenseReport.deleteItemizationExpenseType(
      expenseEntryId,
      this.state.selectedItemisationDeleteEntry
    );
    if (responseItmDlete) {
      $(".close").click();
      this.getAllCreatedExpenses();
      let expenseEntryId = localStorage.getItem("expenseEntryId");
      if (
        expenseEntryId !== undefined &&
        expenseEntryId !== "" &&
        expenseEntryId !== null
      ) {
        this.onSelectExpenstEntryList(expenseEntryId);
      }
      toast.error(responseItmDlete.message);
    }
  };

  // updated v2 itemization API
  onItemisaionCheckHandler = async () => {
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let response = await expenseReport.getExpenseEntryItemizationDetails(
      expenseEntryId
    );
    // let response = await expenseReport.getExpenseEntryDetails(expenseEntryId);
    if (response) {
      this.props.expenseStore.setEntryItemizationAvailable(response);
      this.setState({
        itemizationForm: getValue(response, "itemizationForm"),
        isItemisationOpen: !this.state.isItemisationOpen
      });
    } else {
      this.setState({
        isItemisationOpen:
          // false
          !this.state.isItemisationOpen
      });
    }
  };

  onClickUploadReceiptStoreHandler = async () => {
    this.setState({ selectedReceiptIds: [], selectedReceiptImages: [] });
    let userId = this.state.userId;
    let companyId = this.state.companyId;
    let response = await expenseReport.fetchAllReportReceipt(userId, companyId);
    if (response) {
      if (!this.state.validationStatus) {
        let validationMessage = [...this.state.validationMessage];
        validationMessage = validationMessage.filter(
          message => message != response.msg
        );
        this.setState({ validationMessage: validationMessage });
        // this.getAllCreatedExpenses();
        // this.getLeftsidebarDataRefetch();
      }
      this.setState({ allReceipt: response.result });
    }
  };

  onSelectReceiptHandler = (selectedReceiptId, selectedReceiptImage) => {
    $("#preview-img").attr("src", selectedReceiptImage);
    let selectedReceiptIds = this.state.selectedReceiptIds;
    let selectedReceiptImages = this.state.selectedReceiptImages;
    if ($.inArray(selectedReceiptId, selectedReceiptIds) !== -1) {
      selectedReceiptIds = selectedReceiptIds.filter(
        id => id !== selectedReceiptId
      );
      selectedReceiptImages = selectedReceiptImages.filter(
        id => id !== selectedReceiptImage
      );
    } else {
      selectedReceiptIds.push(selectedReceiptId);
      selectedReceiptImages.push(selectedReceiptImage);
    }
    this.setState({
      selectedReceiptImages: selectedReceiptImages,
      selectedReceiptIds: selectedReceiptIds
    });
  };
  onSubmitReceiptHandler = async () => {
    let selectedReceiptIds = this.state.selectedReceiptIds;
    let selectedReceiptImages = this.state.selectedReceiptImages;
    let allReceiptsArray = [];
    selectedReceiptIds.forEach((receipt, index) => {
      allReceiptsArray.push({
        id: selectedReceiptIds[index],
        uploadReceipt: selectedReceiptImages[index],
        status: true
      });
    });
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let companyId = this.state.companyId;

    let response = await expenseReport.uploadReceiptReport(
      allReceiptsArray,
      expenseEntryId,
      companyId
    );
    if (response) {
      let allReceipt = response.result.filter(images => images.status === true);
      this.onSelectExpenstEntryList(expenseEntryId); //added by sethu for removing receipt error message after added
      toast.success(response.message);
      this.setState({ s3ReceiptUrl: allReceipt });
      this.getLeftsidebarDataRefetch();
    }
  };

  onFileBrowse = event => {
    const browseFiles = Array.from(event.target.files);
    this.setState({ browseAllImages: browseFiles });
    let allImages = [];
    let allBrowseImage = [];
    for (const key in browseFiles) {
      let imageType = event.target.files[key].type;
      this.setState({ imageType: imageType });
      const types = ["image/png", "image/jpeg", "application/pdf"];
      if (types.includes(imageType)) {
        let fileName = browseFiles[key].name;
        if (fileName && fileName !== "item") {
          let browseImage = URL.createObjectURL(event.target.files[key]);
          allImages.push(fileName);
          allBrowseImage.push(browseImage);
        }
      } else {
        toast.error(
          `You can not uplaod ${imageType.split("/")[1]} file format`
        );
      }
    }
    this.setState({ fileName: allImages, browseImage: allBrowseImage });
  };

  onFileUpload = async () => {
    $(".close").click();
    let formData = new FormData();
    let files = this.state.browseAllImages;
    if (files && files.length) {
      files.forEach((file, i) => {
        formData.append("file", file);
      });
      let expenseEntryId = localStorage.getItem("expenseEntryId");
      let companyId = this.state.companyId;
      let userId = this.state.userId;
      let response = await expenseReport.uploadReceiptReportComputer(
        formData,
        expenseEntryId,
        companyId,
        userId
      );
      if (response) {
        // this.state.expenseEntryList.forEach((item, index) => {
        //   if (item.id === localStorage.getItem("expenseEntryId")) {
        //     setValue(item, "validation", false)
        //   }
        // })
        if (!this.state.validationStatus) {
          let validationMessage = [...this.state.validationMessage];
          validationMessage = validationMessage.filter(
            message => message != response.msg
          );

          this.setState({
            validationMessage: validationMessage,
            browseAllImages: "",
            fileName: "",
            browseImage: ""
          });
        }
        this.onSelectExpenstEntryList(getValue(localStorage,"expenseEntryId",""));

      }
      toast.success(response.message);
      let allReceipt = getValue(response, "result", []).filter(
        images => images.status === true
      );
      if (allReceipt.length > 0) {
        this.setState({
          maxImageViewReceipt: allReceipt[0].uploadReceipt,
          maxImageViewReceiptId: allReceipt[0].id,
          s3ReceiptUrl: allReceipt
        });
      }
      this.setState({ selectedReceiptIds: [], selectedReceiptImages: [] });
      this.getLeftsidebarDataRefetch();
    }
  };

  getCashAdvanceListHandler = async () => {
    this.setState({
      selectedCashAdvanceReceipt: [],
      selectedCashAdvanceReceiptIds: []
    });
    let companyId = this.state.companyId;
    let pageLimit = 100;
    let pageNumber = 1;
    let search = "";
    let response = await expenseReport.getAllCashAdvanceCreatedList(
      companyId,
      pageNumber,
      pageLimit,
      search
    );
    if (
      response &&
      response.count &&
      response.count > 0 &&
      response.result.data.length > 0
    ) {
      this.setState({
        allAdvanceRequest: response.result.data,
        headerName: response.result.data[0].data,
        remainingCashAdvanceReceipt: response.result.data
      });
    } else {
      this.setState({
        allAdvanceRequest: [],
        headerName: [],
        remainingCashAdvanceReceipt: []
      });
    }
  };

  onCashAdvanceSelectHadnler = cashadvance => {
    let selectedCashAdvanceReceipt = this.state.selectedCashAdvanceReceipt;
    let selectedCashAdvanceReceiptIds = this.state
      .selectedCashAdvanceReceiptIds;
    if ($.inArray(cashadvance, selectedCashAdvanceReceipt) !== -1) {
      selectedCashAdvanceReceipt = selectedCashAdvanceReceipt.filter(
        id => id !== cashadvance
      );
      selectedCashAdvanceReceiptIds = selectedCashAdvanceReceiptIds.filter(
        id => id !== cashadvance.id
      );
    } else {
      cashadvance.assigned = "true";
      selectedCashAdvanceReceipt.push(cashadvance);
      selectedCashAdvanceReceiptIds.push(cashadvance.id);
    }
    this.setState({
      selectedCashAdvanceReceipt: selectedCashAdvanceReceipt,
      selectedCashAdvanceReceiptIds: selectedCashAdvanceReceiptIds
    });
  };
  onCashAdvanceAttachHandler = async () => {
    let selectedCashAdvanceReceipt = this.state.selectedCashAdvanceReceipt;
    let companyId = this.state.companyId;
    let headerId = localStorage.getItem("headerId");
    let data = {
      data: selectedCashAdvanceReceipt,
      headerId: headerId,
      companyId: companyId
    };
    let response = await expenseReport.attachCashAdvanceReceipt(data);
    if (response) {
      this.getAllCreatedExpenses();
      this.getCashAdvanceListHandler();
      this.setState({
        selectedCashAdvanceReceipt: [],
        selectedCashAdvanceReceiptIds: []
      });
      toast.success(response.message);
    }
  };
  getAllAttachedCashAdvance = async () => {
    this.setState({
      selctedAssignedCashAdvance: [],
      selectedCashAdvAttachReceiptIds: []
    });
    let companyId = this.state.companyId;
    let headerId = localStorage.getItem("headerId");
    let response = await expenseReport.getCashAdvanceReceiptHeader(
      companyId,
      headerId
    );
    if (response && response.count && response.count > 0) {
      this.setState({
        getAllAssignedCashAdavnce: response.result.data,
        headerAssignedCashAdavnce: response.result.data[0].data
      });
    } else {
      this.setState({
        getAllAssignedCashAdavnce: [],
        headerAssignedCashAdavnce: []
      });
    }
  };
  onLargeImageShowHandler = (image, id) => {
    this.setState({ maxImageViewReceipt: image, maxImageViewReceiptId: id });
  };
  onDeleteReceiptHandler = async () => {
    let maxImageViewReceipt = this.state.maxImageViewReceipt;
    let maxImageViewReceiptId = this.state.maxImageViewReceiptId;
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let companyId = this.state.companyId;
    let data = {
      id: maxImageViewReceiptId,
      uploadReceipt: maxImageViewReceipt
    };
    let response = await expenseReport.deleteExpenseReceipt(
      data,
      expenseEntryId,
      companyId
    );

    if (response) {
      // if (this.state.validationStatus && response.msg) {
      // if (response.msg) {
      //   this.state.expenseEntryList.forEach((item) => {
      //     if (item.id === localStorage.getItem("expenseEntryId")) {
      //       setValue(item, "validation",true)
      //     }

      //   })
      //   this.getAllCreatedExpenses();
      // }
      let validationMessage = [...this.state.validationMessage];
      validationMessage.push(response.msg);
      this.setState({ validationMessage: validationMessage });

      let allReceipt = response.result.filter(
        images => images.status !== false
      );
      if (allReceipt.length > 0) {
        this.setState({
          maxImageViewReceipt: allReceipt[0].uploadReceipt,
          maxImageViewReceiptId: allReceipt[0].id,
          s3ReceiptUrl: allReceipt
        });
      } else {
        this.setState({
          maxImageViewReceipt: "",
          maxImageViewReceiptId: "",
          s3ReceiptUrl: []
        });
      }
      this.setState({ selctedAssignedCashAdvance: [] });
      toast.error(response.message);
      // this.getAllCreatedExpenses();
      this.getLeftsidebarDataRefetch();
    }
  };
  onSelectAttchedCashAdvHadnler = cashadvance => {
    let selctedAssignedCashAdvance = this.state.selctedAssignedCashAdvance;
    let selectedCashAdvAttachReceiptIds = this.state
      .selectedCashAdvAttachReceiptIds;
    if ($.inArray(cashadvance, selctedAssignedCashAdvance) !== -1) {
      selctedAssignedCashAdvance = selctedAssignedCashAdvance.filter(
        id => id !== cashadvance
      );
      selectedCashAdvAttachReceiptIds = selectedCashAdvAttachReceiptIds.filter(
        id => id !== cashadvance.id
      );
    } else {
      cashadvance.assigned = "false";
      selctedAssignedCashAdvance.push(cashadvance);
      selectedCashAdvAttachReceiptIds.push(cashadvance.id);
    }
    this.setState({
      selctedAssignedCashAdvance: selctedAssignedCashAdvance,
      selectedCashAdvAttachReceiptIds: selectedCashAdvAttachReceiptIds
    });
  };
  onRemoveAttchedCashAdvHadnler = async () => {
    let selctedAssignedCashAdvance = this.state.selctedAssignedCashAdvance;
    let companyId = this.state.companyId;
    let headerId = localStorage.getItem("headerId");
    let data = {
      data: selctedAssignedCashAdvance,
      headerId: headerId,
      companyId: companyId
    };
    let response = await expenseReport.attachCashAdvanceReceipt(data);
    if (response) {
      this.getAllCreatedExpenses();
      this.setState({
        selctedAssignedCashAdvance: [],
        selectedCashAdvAttachReceiptIds: []
      });
      this.getAllAttachedCashAdvance();
      toast.success(response.message);
    }
  };
  onAttachDetachClickHandler = async () => {
    let companyId = this.state.companyId;
    let affidavateStatus = !this.state.affidavateStatus;
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let response = await expenseReport.attachDettachAffidavate(
      companyId,
      expenseEntryId,
      affidavateStatus
    );
    if (response) {
      toast.success(response.message);
      this.setState({ affidavateStatus: affidavateStatus });
    }
  };
  onClickTotalReportHandler = async () => {
    let headerId = localStorage.getItem("headerId");
    let response = await expenseReport.getTotalReportExpenses(
      this.state.companyId,
      headerId
    );
    if (response) {
      this.setState({ isTotalLoading: false });
      this.setState({ totalReportAmounts: response });
    }
  };
  onExpenseReportSubmitHandler = async () => {
    // let validationStatus = this.state.validationStatus;

    if (!this.state.validationStatusArray.includes(true)) {
      $(".close").click();
      this.setState({ isSubmitClick: true });
      let companyId = this.state.companyId;
      let headerId = localStorage.getItem("headerId");
      let reportArray = this.state.reportHeader.map(res => res.columnName);
      let reportNameIndex = reportArray.indexOf("REPORT_NAME");
      let reportName = "";
      if (reportNameIndex >= 0) {
        reportName = this.state.reportHeader[reportNameIndex].fieldValue;
      } else {
        reportName = "";
      }
      let response = await expenseReport.submitExpenseReportData(
        companyId,
        headerId,
        reportName
      );
      if (response) {
        if (response) {
          this.setState({ isSubmitClick: false });
          this.removeLocalStorageData();
          toast.success(response.message);
          this.props.history.push("./home");
        } else {
          this.setState({ isSubmitClick: false });
          toast.error(response.message);
        }
      }
    } else {
      this.setState({ isSubmitClick: false });
      toast.error("Please remove all the warnings");
    }
  };
  showExpenseTab() {
    document.getElementById("expense-left").classList.toggle("show");
  }
  handleDeleteExepsneHeader = async () => {
    let response = await expenseReport.getDeleteExpenseEntryHeader(
      localStorage.getItem("headerId"),
      this.state.companyId
    );
    if (response) {
      $(".close").click();
      toast.success(response.message);
      let workflowId = this.state.workflowId;
      if (workflowId !== "" && workflowId !== undefined) {
        this.props.history.push("../../../expense/home");
      } else {
        this.props.history.push("../../expense/home");
      }
    }
  };
  onRecallExpenseHandler = async () => {
    let workFlowid = localStorage.getItem("workFlowid");
    let response = await expenseReport.getRecallExpenseReport(workFlowid);
    if (response) {
      $(".close").click();
      this.getAllexpenseTypes();
      this.getAllCreatedExpenses();
      this.removeLocalStorageData();
      this.props.history.push("../../expense/home");
    }
  };

  removeLocalStorageData = () => {
    localStorage.removeItem("workFlowid");
    localStorage.removeItem("itemSelectedExenseType");
    localStorage.removeItem("expenseEntryId");
    localStorage.removeItem("headerId");
    localStorage.removeItem("imtmExpTypeId");
    localStorage.removeItem("itemization_id");
    localStorage.removeItem("policyId");
    localStorage.removeItem("expenseType");
  };
  handleExpensePrint = () => {
    this.setState({ enablePrintTicket: !this.state.enablePrintTicket });
  };

  handleAllocationDetails = async (expenseId, headerId) => {
    this.setState({ isGraphDataLoading: true });
    let companyId = this.state.companyId;
    // let headerId = localStorage.getItem("headerId");
    let response = await expenseServices.getAllocationDetails(
      companyId,
      headerId,
      expenseId
    );
    if (response && response.result) {
      console.log(response, "----->allocation");
      this.setState({
        allocationDetails: response.result,
        isGraphDataLoading: false
      });
    } else {
      this.setState({ isGraphDataLoading: false });
    }
  };

  handleClickUploadFromCompt = () => {
    this.setState({ browseImage: [], fileName: [] });
  };

  fetchMoreData = async e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (this.state.expenseEntryList.length < this.count) {
        this.page = this.page + 1;
        this.getLeftsidebarData();
      } else {
        this.setState({ isLoading: false });
      }
    }
  };

  render() {
    const validationStatusArray = this.state.validationStatusArray.includes(
      true
    );
    return (
      <div>
        <AuditTrial closeAuditTrail={this.closeAuditTrail} />
        <div id="expense-left" className="expense-left">
          <DetailDropdownItem
            totoalExpense={this.state.totoalExpense}
            submitStatus={this.state.submitStatus}
            onClickTotalReportHandler={this.onClickTotalReportHandler}
            getAllAttachedCashAdvance={this.getAllAttachedCashAdvance}
            getCashAdvanceListHandler={this.getCashAdvanceListHandler}
            openAuditTrail={this.openAuditTrail}
          />

          <ExpenseLeftSidebarSummary
            fetchMoreData={this.fetchMoreData}
            selectExpenstEntryList={
              this.props.expenseStore.selectExpenstEntryList
            }
            isLoading={this.state.isLoading}
            expenseEntryList={this.state.expenseEntryList}
            onSelectExpenstEntryList={this.onSelectExpenstEntryList}
            handleAllocationDetails={this.handleAllocationDetails}
            history={this.state.history}
          />

          <CreditDepositSummary
            cashAdvanceTotalAmount={this.state.cashAdvanceTotalAmount}
            userBaseCurrency={this.state.userBaseCurrency}
            totalExpenseAmount={this.state.totalExpenseAmount}
            totalRequestAmount={this.state.totalRequestAmount}
            submitStatus={this.state.submitStatus}
          />
        </div>
        {this.state.isPageLoading ? (
          <center>
          <div className="expense-right">
            {/* <Loader /> */}
            Loading...
          </div>
          </center>
        ) : (
          <div className="expense-right">
            <div className="head">
              {/**Top header Details inside this component */}
              <ReportTopHeader
                selectedExpenseType={this.state.selectedExpenseType}
                reportId={this.state.reportId}
                requestId={this.state.requestId}
                enablePrintTicket={this.state.enablePrintTicket}
                rejectExpenseMessage={this.state.rejectExpenseMessage}
                actionButtons={this.state.actionButtons}
                currentPageName={this.state.pageName}
                isSubmitClick={this.state.isSubmitClick}
                submitStatus={this.state.submitStatus}
                onDeleteExpenseEntry={this.onDeleteExpenseEntry}
                onClickEditReportHeader={this.onClickEditReportHeader}
                expenseHeader={this.state.reportHeader}
                policyDetail={this.state.policyDetail}
                onExpenseReportSubmitHandler={this.onExpenseReportSubmitHandler}
                // validation
                validationStatus={this.state.validationStatus}
                validationMessage={this.state.validationMessage}
                validationStatusArray={validationStatusArray}
                // ends
                totoalExpense={this.state.totoalExpense}
                handleExpensePrint={this.handleExpensePrint}
                headerDetails={this.state.headerDetails}
                expenseEntryList={this.state.expenseEntryList}
                headerObj={this.state.headerObj}
                CommentsFromApprover={
                  this.state.CommentsFromApprover
                    ? this.state.CommentsFromApprover
                    : ""
                }
              />
            </div>
            {/**Entry form and itemization forms. If he selected any expense type then show the itemisation and entry form */}
            {this.state.expenseEntryList.length <= 0 &&
              // this.state.isPageLoading === false &&
               (
                <div className="text-center mt-5">
                  <img
                    width="400"
                    className="img-fluid mb-4"
                    alt="Fetching Reports"
                    src="/assets/images/no_expense.svg"
                  />
                  <h4>
                    <b>Your report is empty!</b>
                  </h4>
                  <p className="mb-3">Lets get started.</p>
                  <button
                    type="button"
                    className="btn"
                    data-toggle="modal"
                    disabled={this.state.submitStatus !== "Pending Submission"}
                    data-target="#itemisationExpenseAddModal"
                  >
                    Add Expense
                  </button>
                </div>
              )}
            {this.state.expenseEntryList.length >= 1 &&
              (localStorage.getItem("expenseEntryId") === null ||
                localStorage.getItem("expenseEntryId") === undefined) && (
                <>
                  <div className="p-padding pb-0">
                    <div id="expense-report">
                      <div className="row">
                        {this.state.validationStatus && (
                          <ExpenseReportWarning
                            validationMessage={this.state.validationMessage}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <img
                      width="400"
                      className="img-fluid mb-4"
                      alt="Fetching Reports"
                      src="/assets/images/no_expense.svg"
                    />
                    <p>Lets get started.</p>
                    <h4 className="mt-2">
                      <b>Select an Expense</b>
                    </h4>
                  </div>
                </>
              )}

            {this.props.expenseStore.selectExpenstEntryList !== "" &&
            localStorage.getItem("expenseEntryId") !== undefined &&
            localStorage.getItem("expenseEntryId") !== "" &&
            localStorage.getItem("expenseEntryId") !== null ? (
              <div className="p-padding pb-0">
                <div id="expense-report">
                  <div className="row">
                    {this.state.validationMessage && (
                      <ExpenseReportWarning
                        validationMessage={this.state.validationMessage}
                      />
                    )}
                  </div>
                  {!this.state.isExpenseLoading &&
                  localStorage.getItem("expenseEntryId") !== undefined &&
                  localStorage.getItem("expenseEntryId") !== "" ? (
                    <>
                      <div className="white-card report">
                        <div className="grey-head border-bottom">
                          <h6 className="sub-heading float-left place__middle pt-3">
                            {this.state.ExpenseTypeHeader}
                          </h6>
                          <div className="btn-group-margin float-right">
                            {this.state.submitStatus ===
                              "Pending Submission" && (
                              <button
                                disabled={
                                  !this.props.expenseStore
                                    .selectExpenstEntryList
                                }
                                className="btn"
                                data-toggle="modal"
                                data-target="#deleteEntry"
                              >
                                {" "}
                                Remove Expense
                              </button>
                            )}
                            {(this.state.submitStatus ===
                              "Pending Submission" ||
                              this.state.submitStatus ===
                                "SendBack to Employee") && (
                              <button
                                onClick={this.onExpenseEditClickHandler}
                                type="button"
                                className="btn"
                              >
                                EDIT
                              </button>
                            )}
                          </div>
                          <div className="clearfix" />
                        </div>
                        <div className="row">
                          <ReportHeaderTable
                            navigationPaths={this.state.navigationPaths}
                            expenseEntryCurrency={
                              this.state.expenseEntryCurrency
                            }
                            expenseEntryDetails={this.state.expenseEntryDetails}
                          />
                          <ReportHeaderUploadReceipt
                            imageType={this.state.imageType}
                            submitStatus={this.state.submitStatus}
                            allocationStatus={this.state.affidavateStatus}
                            s3ReceiptUrl={this.state.s3ReceiptUrl}
                            onFileUpload={this.onFileUpload}
                            onFileBrowse={this.onFileBrowse}
                            browseImage={this.state.browseImage}
                            allReceipt={this.state.allReceipt}
                            selectedReceiptId={this.state.selectedReceiptIds}
                            onClickUploadReceiptStoreHandler={
                              this.onClickUploadReceiptStoreHandler
                            }
                            onSelectReceiptHandler={this.onSelectReceiptHandler}
                            onSubmitReceiptHandler={this.onSubmitReceiptHandler}
                            maxImageViewReceipt={this.state.maxImageViewReceipt}
                            onLargeImageShowHandler={
                              this.onLargeImageShowHandler
                            }
                            onDeleteReceiptHandler={this.onDeleteReceiptHandler}
                            policyDetail={this.state.policyDetail}
                            isFromWorkFlow={this.state.isFromWorkFlow}
                            handleClickUploadFromCompt={
                              this.handleClickUploadFromCompt
                            }
                          />
                        </div>
                      </div>
                      {parseInt(this.state.itemization) === 0 ||
                      parseInt(this.state.itemization) === 1 ? (
                        this.state.submitStatus === "Pending Submission" ? (
                          <div className="white-card animation-target-left">
                            <div className="card switch-panel mb-0 p-0">
                              <label
                                className="p-4"
                                data-toggle="collapse"
                                data-target="#demo"
                              >
                                <div className="float-left">
                                  <div className="form-group mb-0">
                                    <label className="switch mr-2">
                                      <input
                                        type="checkbox"
                                        checked={this.state.isItemisationOpen}
                                        onChange={this.onItemisaionCheckHandler}
                                      />
                                      <span className="slider round" />
                                    </label>
                                    ITEMISATION
                                  </div>
                                </div>
                                <div className="float-right">
                                  <i className="material-icons">
                                    arrow_drop_down
                                  </i>
                                </div>
                              </label>
                            </div>
                            <div
                              id="demo"
                              className={
                                "collapse fade-in " +
                                (this.state.itemization >= 1 &&
                                this.state.isItemisationOpen
                                  ? "show"
                                  : "")
                              }
                            >
                              {/* </div>
                                      <div id="demo" className="collapse"> */}
                              <div className="white-card report mb-0">
                                {/**Statics of itemization */}
                                <ItemisationStatics
                                  currencyEntry={
                                    this.state.expenseEntryCurrency
                                  }
                                  totalAmountWithOutConversion={
                                    this.state.totalAmountWithOutConversion
                                  }
                                  baseCurrency={this.state.userBaseCurrency}
                                  totalItemiseExpenseAmount={
                                    this.state.totalItemiseExpenseAmount
                                  }
                                  slectedExpenseEntryAmount={
                                    this.state.slectedExpenseEntryAmount
                                  }
                                />
                              </div>
                              <div className="white-card report">
                                <div className="row">
                                  {/**List of all created itemization */}
                                  <ItemizeExpenseTable
                                    actionButtons={this.state.actionButtons}
                                    currentPageName={this.state.pageName}
                                    submitStatus={this.state.submitStatus}
                                    expenseEntryCurrency={
                                      this.state.expenseEntryCurrency
                                    }
                                    onItemisationEditClickHandler={
                                      this.onItemisationEditClickHandler
                                    }
                                    onItemisationSelectedDeleteClickHandler={
                                      this
                                        .onItemisationSelectedDeleteClickHandler
                                    }
                                    itemizationForm={this.state.itemizationForm}
                                    onClickCreateItemisationModal={
                                      this.onClickCreateItemisationModal
                                    }
                                  />
                                  {/* <UploadItemisationReceipt /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : this.state.itemizationForm &&
                          this.state.submitStatus !== "Pending Submission" ? (
                          <div className="white-card animation-target-left">
                            <div className="card switch-panel mb-0 p-0">
                              <label
                                className="p-4"
                                data-toggle="collapse"
                                data-target="#demo"
                              >
                                <div className="float-left">
                                  <div className="form-group mb-0">
                                    <label className="switch mr-2">
                                      <input
                                        type="checkbox"
                                        checked={this.state.isItemisationOpen}
                                        onChange={this.onItemisaionCheckHandler}
                                      />
                                      <span className="slider round" />
                                    </label>
                                    ITEMISATION
                                  </div>
                                </div>
                                <div className="float-right">
                                  <i className="material-icons">
                                    arrow_drop_down
                                  </i>
                                </div>
                              </label>
                            </div>
                            <div
                              id="demo"
                              className={
                                "collapse fade-in " +
                                (this.state.itemization >= 1 &&
                                this.state.isItemisationOpen
                                  ? "show"
                                  : "")
                              }
                            >
                              {/* </div>
                                            <div id="demo" className="collapse"> */}
                              <div className="white-card report mb-0">
                                {/**Statics of itemization */}
                                <ItemisationStatics
                                  currencyEntry={
                                    this.state.expenseEntryCurrency
                                  }
                                  totalAmountWithOutConversion={
                                    this.state.totalAmountWithOutConversion
                                  }
                                  baseCurrency={this.state.userBaseCurrency}
                                  totalItemiseExpenseAmount={
                                    this.state.totalItemiseExpenseAmount
                                  }
                                  slectedExpenseEntryAmount={
                                    this.state.slectedExpenseEntryAmount
                                  }
                                />
                              </div>
                              <div className="white-card report">
                                <div className="row">
                                  {/**List of all created itemization */}
                                  <ItemizeExpenseTable
                                    actionButtons={this.state.actionButtons}
                                    currentPageName={this.state.pageName}
                                    submitStatus={this.state.submitStatus}
                                    expenseEntryCurrency={
                                      this.state.expenseEntryCurrency
                                    }
                                    onItemisationEditClickHandler={
                                      this.onItemisationEditClickHandler
                                    }
                                    onItemisationSelectedDeleteClickHandler={
                                      this
                                        .onItemisationSelectedDeleteClickHandler
                                    }
                                    itemizationForm={this.state.itemizationForm}
                                    onClickCreateItemisationModal={
                                      this.onClickCreateItemisationModal
                                    }
                                  />
                                  {/* <UploadItemisationReceipt /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : null}
                    </>
                  ) : (
                    <div className="form-holder">
                      <div className="text-center">
                        <div className="loader-css" />
                        <h5 className="fw-sbold pb-5">
                          Please wait while expense entry is loading...
                        </h5>
                      </div>
                    </div>
                  )}

                  {/*  <AttendeeDetails />
                    <ExpenseAllocationTable />
                */}
                </div>
              </div>
            ) : null}
          </div>
        )}
        {/* allocation comppletion on basis of overAll */}
        {/* {this.state.expenseEntryList.length >= 1 &&
          (localStorage.getItem("expenseEntryId") !== null &&
            localStorage.getItem("expenseEntryId") !== undefined) &&
             <ExpenseEntryAllocationPage
            userBaseCurrency={this.state.userBaseCurrency}
            totalSelectesExpenseAmount={+this.state.slectedExpenseEntryAmount.toFixed(2)}
          />
        } */}
        {/*Dynamic expense type modal of add expense*/}
        <ExpenseTypes
          onAddExpenseHandler={this.onAddExpenseHandler}
          expenseTypes={this.state.expenseType}
          onSearchExpenseType={this.onSearchExpenseType}
          onSelectExpenseType={this.onSelectExpenseType}
          onRedirectHandler={this.onRedirectHandler}
          selectedExenseType={this.state.selectedExenseType}
        />
        {/*Dynamic itemize expense type modal*/}
        <ItemizeExpenseTypes
          loadingItemisationExpType={this.state.loadingItemisationExpType}
          expenseTypes={this.state.itemExpenseType}
          onSearchExpenseType={this.onItemSearchExpenseType}
          onSelectExpenseType={this.onItemSelectExpenseType}
          selectedExenseType={this.state.itemSelectedExenseType}
          onItemCancelHandler={this.onItemCancelHandler}
          onItemizeContinueHandler={this.onItemizeContinueHandler}
        />
        {/** upload declaration affidavit modal */}
        <ReceiptDeclarationAttach
          onAttachDetachClickHandler={this.onAttachDetachClickHandler}
          policyDetail={this.state.policyDetail}
          isFromWorkFlow={this.state.isFromWorkFlow}
        />
        <AddNewAttendee />
        <FavouriteAttendeeList
          favouriteEmployeeList={this.state.itemizeExpenseTable}
          addFavouriteEmployee={this.state.addFavouriteEmployee}
          onFavoriteHandler={this.onFavoriteHandler}
        />

        <ImportAttendeeModel />

        <NewAttendeeList />

        <ExpenseReportTotalModal
          userBaseCurrency={this.state.userBaseCurrency}
          totalReportAmounts={this.state.totalReportAmounts}
        />
        <ExpenseReportUserInfoModel
          userBaseCurrency={this.state.userBaseCurrency}
          totalReportAmounts={this.state.totalReportAmounts}
        />

        <WorkFlowApprovalSteps approvalList={this.state.approvalList} />
        <ExpenseDeleteModal
          handleDeleteExepsneHeader={this.handleDeleteExepsneHeader}
        />

        <DeleteExpenseEntryModal
          onDeleteExpenseEntry={this.onDeleteExpenseEntry}
        />
        <DeleteItemisationConfirmModal
          onItemisationDeleteClickHandler={this.onItemisationDeleteClickHandler}
          onItemisationDeleteCancelHandler={
            this.onItemisationDeleteCancelHandler
          }
        />
        <ExpenseSubmitConfirmModal
          onExpenseReportSubmitHandler={this.onExpenseReportSubmitHandler}
          isSubmitClick={this.state.isSubmitClick}
        />
        <AvailableCashadvnace
          allAdvanceRequest={this.state.allAdvanceRequest}
          selectedCashAdvanceReceiptIds={
            this.state.selectedCashAdvanceReceiptIds
          }
          onCashAdvanceAttachHandler={this.onCashAdvanceAttachHandler}
          onCashAdvanceSelectHadnler={this.onCashAdvanceSelectHadnler}
        />
        <AssignedCashAdvnace
          getAllAssignedCashAdavnce={this.state.getAllAssignedCashAdavnce}
          onSelectAttchedCashAdvHadnler={this.onSelectAttchedCashAdvHadnler}
          selectedCashAdvAttachReceiptIds={
            this.state.selectedCashAdvAttachReceiptIds
          }
          onRemoveAttchedCashAdvHadnler={this.onRemoveAttchedCashAdvHadnler}
        />
        <RecallConfirmModal
          onRecallExpenseHandler={this.onRecallExpenseHandler}
          isRecallClick={this.state.isRecallClick}
        />
        <AllocatedGraphModal
          userBaseCurrency={this.state.userBaseCurrency}
          allocationDetails={this.state.allocationDetails}
          isGraphDataLoading={this.state.isGraphDataLoading}
        />
        {/* <ChatApp
        // approvals={approvals}
        /> */}
      </div>
    );
  }
}
export default ExpenseDetailsPage;
