import React, { Component } from "react";
import AccountCodesPage from "./account-codes-html";
import { expenseServices } from "../../../../../../_services";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../../_constants/alert.constants";
import _ from "lodash";

class AccountCodes extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ Top: 0, behavior: "smooth" });
    this.state = {
      companyId: localStorage["440a28"],
      search: "",
      companyData: [],
      ledgerName: "global",
      count: "",
      activePage: 1,
      totalExpense: [],
      globalGetData: [],
      ledgerEditId: "",
      hierarchyLevel: [],
      parentId: "",
      hierarchyId: "",
      parent1: "",
      isLoading: true
    };
  }
  componentDidMount = async () => {
    await this.getListAccountStructure();
    await this.getListExpense(this.state.activePage);
  };

  getListAccountStructure = async () => {
    let companyId = this.state.companyId;
    let search = this.state.search;
    this.setState({ isLoading: true });
    let resp = await expenseServices.getAccountStructure(search, companyId);
    if (resp) {
      this.setState({ isLoading: false });
      this.setState(
        {
          companyData: resp.result
        },
        () => console.log(resp.result)
      );
    } else {
      this.setState({ isLoading: false });
    }
  };
  getListExpense = async pageNo => {
    let companyId = this.state.companyId;
    this.setState({ isLoading: true });
    let resp = await expenseServices.getListExpense(companyId, pageNo);
    if (resp) {
      this.setState({
        count: resp.count,
        totalExpense: resp.result
      });
      await this.getGlobalList();
      let totalExpense = [...this.state.totalExpense];
      let globalGetData = [...this.state.globalGetData];
      let myId = [];
      globalGetData.forEach(item => {
        myId.push(item.expenseTypeId);
      });

      totalExpense.forEach(item => {
        this.setState({ [item.id]: {} });
        if (myId.includes(item.id)) {
          let getindex = myId.indexOf(item.id);
          this.setState({
            [item.id]: {
              expenseTypeId: item.id,
              expenseTypeName: item.expenseTypeName,
              accountCode: globalGetData[getindex]
                ? globalGetData[getindex].accountCode
                : "",
              accountCodeName: this.state.ledgerName,
              id: globalGetData[getindex].id,
              inheritedCode: globalGetData[getindex].inheritedCode,
              inheritedLevel: globalGetData[getindex].inheritedLevel
            }
          });
        } else {
          this.setState({
            [item.id]: {
              expenseTypeId: item.id,
              expenseTypeName: item.expenseTypeName,
              accountCode: "",
              accountCodeName: this.state.ledgerName
            }
          });
        }
      });
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };
  getGlobalList = async () => {
    let allExpenseId = this.state.totalExpense.map(item => item.id);
    let resp = [];
    if (this.state.ledgerName === "global") {
      let myPostData = {
        expenseTypeId: allExpenseId,
        companyId: this.state.companyId
      };
      resp = await expenseServices.getAccountingCodeList(myPostData);
    } else {
      let myPostData = {
        expenseTypeId: allExpenseId,
        companyId: this.state.companyId,
        parentDetails: [
          {
            parentId: null,
            level: 1
          }
        ],
        ledgerId: this.state.ledgerEditId
      };
      resp = await expenseServices.getAccountingCodeList(myPostData);
    }
    if (resp) {
      this.setState({
        globalGetData: resp.result
      });
    }
  };

  handlePageChange = pageNumber => {
    this.setState(
      {
        activePage: pageNumber
      },
      () => {
        this.getListExpense(this.state.activePage);
      }
    );
  };
  tableDataHandler = (e, id) => {
    this.setState({
      [id]: {
        ...this.state[id],
        accountCode: e.currentTarget.value
      }
    });
  };

  postData = async e => {
    let totalExpense = [...this.state.totalExpense];
    let data = [];
    totalExpense.forEach(item => {
      if (
        this.state[item.id].id &&
        this.state[item.id].accountCode !== undefined
      ) {
        data.push({
          id: this.state[item.id].id,
          expenseTypeId: item.id,
          accountCode: this.state[item.id].accountCode,
          accountCodeName: this.state.ledgerName,
          inheritedCode: this.state[item.id].inheritedCode
        });
      } else if (
        this.state[item.id].accountCode !== "" &&
        this.state[item.id].accountCode !== undefined
      ) {
        data.push({
          id: this.state[item.id].id,
          expenseTypeId: item.id,
          accountCode: this.state[item.id].accountCode,
          accountCodeName: this.state.ledgerName,
          inheritedCode: this.state[item.id].inheritedCode
        });
      }
    });
    let myPostData = {};
    if (
      this.state.ledgerEditId === undefined ||
      this.state.ledgerEditId === ""
    ) {
      myPostData = {
        companyId: this.state.companyId,
        accountCodes: data,
        parentId: this.state.parentId,
        hierarchyId: this.state.hierarchyId
      };
    } else {
      myPostData = {
        companyId: this.state.companyId,
        ledgerId: this.state.ledgerEditId,
        accountCodes: data,
        parentId: this.state.parentId,
        hierarchyId: this.state.hierarchyId
      };
      if (this.state.hierarchyId !== "") {
        myPostData = {
          companyId: this.state.companyId,
          accountCodes: data,
          parentId: this.state.parentId,
          hierarchyId: this.state.hierarchyId
        };
      }
    }
    let resp = await expenseServices.addAccountingCode(myPostData);
    if (resp) {
      toast.success(alertConstants.CREATED_SUCCESSFULLY);
      this.getGlobalList();
      this.setState({ ledgerEditId: "" });
    }
  };
  setGlobal = async () => {
    this.setState({
      ledgerName: "global",
      ledgerEditId: "",
      hierarchyId: "",
      parentId: "",
      isLoading: true
    });
    this.getListExpense(this.state.activePage);
  };
  setLedger = async (name, id) => {
    await this.setState({
      ledgerName: name,
      ledgerEditId: id,
      parentId: "Global",
      isLoading: true
    });
    await this.getGlobalList();
    this.getListExpense(this.state.activePage);
    this.getHierarchy(name, id);

    this.setState({
      hierarchyId: ""
    });
  };

  getLedger = async id => {
    let allExpenseId = this.state.totalExpense.map(item => item.id);
    let myPostData = {
      expenseTypeId: allExpenseId,
      companyId: this.state.companyId,
      parentDetails: [
        {
          parentId: null,
          level: 1
        }
      ],
      ledgerId: id
    };
    let resp = await expenseServices.getAccountingCodeList(myPostData);
  };
  getHierarchy = async (ledgerName, id) => {
    this.setState({ hierarchyLevel: [] });
    let resp = await expenseServices.getHierarchyLevel(
      this.state.companyId,
      id
    );
    if (resp.result) {
      this.setState({
        [ledgerName]: resp.result
      });
    }
  };

  setHierarchy = async (hierarchy, ledger, name) => {
    this.setState({
      hierarchyId: hierarchy,
      parentId: ledger
    });
    let totalExpense = [...this.state.totalExpense];
    totalExpense.forEach(item => {
      this.setState({
        [item.id]: {},
        ledgerName: name
      });
    });

    let allExpenseId = this.state.totalExpense.map(item => item.id);

    let getData = {
      expenseTypeId: allExpenseId,
      companyId: this.state.companyId,
      parentDetails: [
        {
          parentId: ledger,
          level: 1
        },
        {
          parentId: null,
          level: 2
        }
      ],
      hierarchyId: hierarchy
    };
    let resp = await expenseServices.getAccountingCodeList(getData);
    let level1 = resp.result;

    allExpenseId.forEach(item => {
      level1.forEach(el => {
        if (el.expenseTypeId === item) {
          this.setState({
            [item]: {
              expenseTypeId: el.id,
              accountCode: el.accountCode,
              inheritedCode: el.inheritedCode,
              inheritedLevel: el.inheritedLevel
            }
          });
        }
      });
    });
  };

  setL1Hrcy = async (hierarchy, parent, name, parent1) => {

    this.setState({
      hierarchyId: hierarchy,
      parentId: parent,
      ledgerName: name
    });

    let totalExpense = [...this.state.totalExpense];
    totalExpense.forEach(item => {
      this.setState({
        [item.id]: {}
      });
    });

    let allExpenseId = this.state.totalExpense.map(item => item.id);
    let getData = {
      expenseTypeId: allExpenseId,
      companyId: this.state.companyId,
      parentDetails: [
        {
          parentId: parent,
          level: 1
        },
        {
          parentId: parent1,
          level: 2
        },
        {
          parentId: null,
          level: 3
        }
      ],
      hierarchyId: hierarchy
    };
    let resp = await expenseServices.getAccountingCodeList(getData);
    let level2 = resp.result;

    allExpenseId.forEach(item => {
      level2.forEach(el => {
        if (el.expenseTypeId === item) {
          this.setState({
            [item]: {
              expenseTypeId: el.id,
              accountCode: el.accountCode,
              inheritedCode: el.inheritedCode,
              inheritedLevel: el.inheritedLevel
            }
          });
        }
      });
    });
  };

  setL2Hrcy = async (hierarchy, name, parent1, parent2, parent3) => {

    this.setState({
      hierarchyId: hierarchy,
      ledgerName: name
    });

    let totalExpense = [...this.state.totalExpense];
    totalExpense.forEach(item => {
      this.setState({
        [item.id]: {}
      });
    });

    let allExpenseId = this.state.totalExpense.map(item => item.id);
    let getData = {
      expenseTypeId: allExpenseId,
      companyId: this.state.companyId,
      parentDetails: [
        {
          parentId: parent1,
          level: 1
        },
        {
          parentId: parent2,
          level: 2
        },
        {
          parentId: parent3,
          level: 3
        },
        {
          parentId: null,
          level: 4
        }
      ],
      hierarchyId: hierarchy
    };
    let resp = await expenseServices.getAccountingCodeList(getData);
    let level2 = resp.result;

    allExpenseId.forEach(item => {
      level2.forEach(el => {
        if (el.expenseTypeId === item) {
          this.setState({
            [item]: {
              expenseTypeId: el.id,
              accountCode: el.accountCode,
              inheritedCode: el.inheritedCode,
              inheritedLevel: el.inheritedLevel
            }
          });
        }
      });
    });
  };

  render() {
    let totalExpense = this.state.totalExpense;
    const totalExpenseData = _.orderBy(
      totalExpense,
      ["expenseTypeName"],
      ["asc"]
    );
    return (
      <div>
        <AccountCodesPage
          {...this.state}
          handlePageChange={this.handlePageChange}
          handleToggleEditable={this.handleToggleEditable}
          tableDataHandler={this.tableDataHandler}
          postData={this.postData}
          setGlobal={this.setGlobal}
          setLedger={this.setLedger}
          setHierarchy={this.setHierarchy}
          setL1Hrcy={this.setL1Hrcy}
          setL2Hrcy={this.setL2Hrcy}
          totalExpenseData={totalExpenseData}
        />
      </div>
    );
  }
}
export default AccountCodes;
