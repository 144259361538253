import React from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
const TaxAuthorityStep2Page = props => {
  let data = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">W</div>
          <div className="right">
            <h6 className="heading">Add New Tax Authority </h6>
            <p className="text">Has been the industry's standard dumm</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="audit-step">
            <NavLink
              className="s1"
              to="/administration/expense/tax-authority-step1"
            >
              <span>1</span>
              General
            </NavLink>
            <NavLink
              className="s1"
              to="/administration/expense/tax-authority-step2"
            >
              <span>2</span>
              Tax Rate Types
            </NavLink>
            <NavLink
              className="s1"
              to="/administration/expense/tax-authority-step3"
            >
              <span>3</span>
              Tax Rates
            </NavLink>
          </div>
          <div className="">
            <div className="table-container">
              <form className="pt-0">
                <div className="row">
                  <div className="col-md-6">
                    <div className="btn-group-margin">
                      <button
                        type="button"
                        className="btn   sm-padding"
                        data-toggle="modal"
                        data-target="#AddTemplateModal2"
                      >
                        New
                      </button>
                      <button
                        type="button"
                        className="btn   sm-padding disabled"
                      >
                        Modify
                      </button>
                    </div>
                  </div>
                  <div className="col-md-2 offset-4">
                    <div className="search-custom">
                      <input
                        type="text"
                        name=""
                        className="form-control"
                        placeholder="Search"
                      />
                      <span>
                      <i className="material-icons">search</i>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Rule Type</th>
                      <th />
                      <th>Calculation method</th>
                      <th>Action</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Standard</td>
                      <td />
                      <td>Percentage</td>
                      <td>
                        <button className="btn   sm-padding">
                          Edit
                        </button>
                      </td>
                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Standard</td>
                      <td />
                      <td>Percentage</td>
                      <td>
                        <button className="btn   sm-padding">
                          Edit
                        </button>
                      </td>
                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Standard</td>
                      <td />
                      <td>Percentage</td>
                      <td>
                        <button className="btn   sm-padding">
                          Edit
                        </button>
                      </td>
                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Standard</td>
                      <td />
                      <td>Percentage</td>
                      <td>
                        <button className="btn   sm-padding">
                          Edit
                        </button>
                      </td>
                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Standard</td>
                      <td />
                      <td>Percentage</td>
                      <td>
                        <button className="btn   sm-padding">
                          Edit
                        </button>
                      </td>
                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            10
                          </button>
                          <div className="dropdown-menu">
                            <button type="button" className="dropdown-item">
                              10
                            </button>
                            <button type="button" className="dropdown-item">
                              20
                            </button>
                            <button type="button" className="dropdown-item">
                              30
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={data.active}
                      itemsCountPerPage={10}
                      totalItemsCount={10}
                      pageRangeDisplayed={3}
                      onChange={data.handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-group-margin mt-4">
              <NavLink
                to="/administration/expense/tax-authority-step3"
                className="btn btn-border-success lg-padding"
              >
                Next
              </NavLink>
              <button className="btn btn-border-basic lg-padding">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddTemplateModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AddTemplateModalLabel">
                Add Tax Rate Types Configuration{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group custom">
                <input
                  type="text"
                  placeholder="&nbsp;"
                  className="form-control"
                />
                <label>Tax Rate Type Name</label>
              </div>
              <div className="form-group custom labelup">
                <label>Calculation Method</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    Percentage
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-border-success lg-padding"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-border-basic lg-padding"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaxAuthorityStep2Page;
