import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Route } from "react-router-dom";
import CashAdvancePage from "./list";
import CashForTripsPage from "./cash-for-trip";

class CashAdvanceMainPage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/my-trip") {
      this.props.history.push("/my-trip/home");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/my-trip") {
      this.props.history.push("/my-trip/home");
    }
  }
  render() {
    const activeNav = this.props.location.pathname.split("/")[2];
    return (
      <div className="BookingPage bg-light-blue">
        <div className="page-content">
          {/* <Route path="/my-trips/home" component={MyTripsPage} /> */}
          <Route
            path="/cash-advance/cash-for-trip"
            component={CashForTripsPage}
          />
          <Route path="/cash-advance/list" component={CashAdvancePage} />
        </div>
        <div
          className="modal fade"
          id="recallModal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">new</h6>
                <h5 className="modal-title" id="cashAdvanceLabel">
                  Recall Cash Advance Request
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="py-5">
                  <h4>Are you Sure?</h4>
                  <p className="text-muted">
                    You can resubmit when you are ready!
                  </p>
                </div>
                <div className="modal-last btn-group-margin">
                  <button
                    type="button"
                    className="btn btn-badge px-4"
                    data-dismiss="modal"
                  >
                    No
                  </button>

                  <button data-dismiss="modal" className="btn   px-4">
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="cancelcashadvanceModal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">new</h6>
                <h5 className="modal-title" id="cashAdvanceLabel">
                  Cancel Cash Advance request
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="py-5">
                  <h4>Are you Sure?</h4>
                  <p className="text-muted">This action cannot be undone</p>
                </div>
                <div className="modal-last btn-group-margin">
                  <button
                    type="button"
                    className="btn btn-badge px-4"
                    data-dismiss="modal"
                  >
                    No
                  </button>

                  <button data-dismiss="modal" className="btn   px-4">
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CashAdvanceMainPage;
