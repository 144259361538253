import React from 'react'

export default function Category(props) {
  const {
    allCategories,
    heading,
    clickHandler,
    selectedCategory
  } = props; 
  return (
    <>
      <div className="heading">
        <h6 className="sub-heading">{heading}</h6>
      </div>
      <div className="scroll">
        <ul>
          {allCategories && allCategories.map((category) => {
            return (
              <li key={category.id} className={selectedCategory === category ? 'selected' : ''} onClick={e => clickHandler(category)} ><button className="btn btn-nothing">{category.travelCategoryName}</button></li>
            )
          })}
        </ul>
      </div>
    </>
  )
}
