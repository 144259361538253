import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import SimpleReactValidator from 'simple-react-validator';
import { myAccountTravelSettingsServise } from "src/_services";
import { Input } from "src/_components/common/_forms";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { myAccountInsuranceService } from "src/_services/myAccountInsuranceService";
import { calculateAge } from "src/utils/util";
import { toast } from "react-toastify";
import { alertConstants } from "src/_constants";

class InsuranceSettingsSavePage extends Component {

  state = {
    firstName: '',
    lastName: '',
    age: '',
    userInfo: [],
    genderOptions: [
      { value: 0, label: 'MALE' },
      { value: 1, label: 'FEMALE' },
      { value: 2, label: 'OTHER' },
    ],
    nomineeOptions: [
      { value: 0, label: 'Spouse' },
      { value: 1, label: 'Parent' },
      { value: 2, label: 'Child' }
    ],
    selectedGender: { value: 0, label: 'Male' },
    selectedNominee: { value: 0, label: 'Spouse' },
    dateOfBirth: new Date(),
    passportIssueDate: new Date(),
    passportExpiryDate: new Date(),
    nationality: '',
    mobileNumber: '',
    gstNumber: '',
    address: '',
    nomineeFirstName: '',
    nomineeLastName: '',
    relationWithNominee: '',


  }
  userDetails = {};
  insuranceDetails = {};

  validator = new SimpleReactValidator();

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getProfileDetails();
    this.getInsuranceDetails();

  }

  getInsuranceDetails = async () => {
    try {
      let res = await myAccountInsuranceService.getInsuranceDetails();
      if (typeof res != undefined && (Object.entries(res.result).length !== 0 && res.result.constructor === Object)) {
        this.insuranceDetails = res.result;
        this.setState({
          address: this.insuranceDetails.address,
          dateOfBirth: new Date(this.insuranceDetails.dateOfBirth),
          gstNumber: this.insuranceDetails.gstNumber,
          mobileNumber: this.insuranceDetails.mobileNumber,
          nationality: this.insuranceDetails.nationality,
          nomineeFirstName: this.insuranceDetails.nomineeFirstName,
          nomineeLastName: this.insuranceDetails.nomineeLastName,
          age: calculateAge(new Date(this.insuranceDetails.dateOfBirth)),
          passportIssueDate: new Date(this.insuranceDetails.passportIssueDate),
          passportExpiryDate: new Date(this.insuranceDetails.passportExpiryDate)



        });
        let selectedGender = this.state.genderOptions.find(item => item.label == this.insuranceDetails.gender);
        if (selectedGender != null) {
          this.setState({ selectedGender });
        }
        let selectedNominee = this.state.nomineeOptions.find(item => item.label == this.insuranceDetails.relationWithNominee);
        if (selectedNominee != null) {
          this.setState({
            selectedNominee
          })
        }

        // console.log('the type of date of birth is ', typeof this.insuranceDetails.dateOfBirth);

      }
    } catch (err) {
      console.log(err);
    }
  }

  getProfileDetails = async () => {
    try {
      let res = await myAccountTravelSettingsServise.getProfileDetails();
      if (typeof res != undefined && res.result) {
        this.userDetails = res.result;

        let userInformation = this.userDetails.fieldData.map(item => {
          if (item.columnName == "DATE_OF_JOINING") {

            return {
              columnName: item.columnName,
              fieldValue: item.fieldValue,
              labelName: item.labelName
            };
          } else if (item.columnName == 'GROUP_NAME') {
            return {
              columnName: item.columnName,
              fieldValue: item.connectedFieldValue,
              labelName: item.labelName

            }
          }
          else if (item.columnName == 'REIMBURSEMENT_CURRENCY' ||
            item.columnName == 'CTRY_CODE' ||
            item.columnName == 'LEDGER_KEY') {
            return {
              columnName: item.columnName,
              fieldValue: item.differentListValue,
              labelName: item.labelName
            }
          }
          else if (
            item.columnName == "COUNTRY" ||
            item.columnName == "STATE" ||
            item.columnName == "CITY"
          ) {
            return {
              columnName: item.columnName,
              fieldValue: item.connectedFieldValue,
              labelName: item.labelName
            };
          } else {
            return {
              columnName: item.columnName,
              fieldValue: item.fieldValue,
              labelName: item.labelName
            };
          }
        });
        await this.setState({
          userInfo: userInformation
        });
        let firstName = this.getFieldValue('EMPLOYEE_FIRST_NAME');
        let lastName = this.getFieldValue('EMPLOYEE_LAST_NAME');
        this.setState({
          firstName,
          lastName
        });
      }

    } catch (err) {

    }
  }

  handleDateChange = (date, type) => {

    this.setState({ [type]: date })
  }

  handleInputChange = (e, type) => {
    this.setState({
      [type]: e.target.value
    })
  }

  getFieldValue = (requiredColumn) => {
    if (this.state.userInfo.length) {
      let firstNameObject = this.state.userInfo.find(info => info.columnName == requiredColumn);
      if (firstNameObject != null) {
        return firstNameObject.fieldValue
      }

    } else {
      return " ";
    }
  }
  handleDropdownChange = (selectedOption, type) => {
    switch (type) {
      case 'gender':
        this.setState({
          selectedGender: selectedOption
        })
        break;
      case 'nominee':
        this.setState({
          selectedNominee: selectedOption
        });
        break;

      default:
        break;
    }

  }

  verifyPassportDate = (issueDate, expiryDate) => {
    return new Date(expiryDate) > new Date(issueDate);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.verifyPassportDate(this.state.passportIssueDate, this.state.passportExpiryDate)) {
      let payload = {
        firstName: this.state.firstName,
        middleName: this.state.middleName,
        lastName: this.state.lastName,
        gender: this.state.selectedGender.label,
        dateOfBirth: this.state.dateOfBirth,
        passportIssueDate: this.state.passportIssueDate,
        passportExpiryDate: this.state.passportExpiryDate,
        nationality: this.state.nationality,
        nomineeFirstName: this.state.nomineeFirstName,
        nomineeLastName: this.state.nomineeLastName,
        relationWithNominee: this.state.selectedNominee.label,
        address: this.state.address,
        mobileNumber: this.state.mobileNumber,
        gstNumber: this.state.gstNumber
      }

      try {
        let res = await myAccountInsuranceService.saveInsuranceDetails(payload);
        if (res.message == 'Updated successfully') {
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
          this.props.history.push('insurance-settings');
        } else {
          toast.warn(res.message)
        }
      } catch (err) {

      }
    } else {
      if (this.verifyPassportDate() == false) {
        toast.warn('Passport Expiry Date can not be less than Passport Issue Date');
      }
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  redirect(e) {
    $(".close").click();
    $(".itemisationExpenseModal").close();
  }
  render() {
    return (
      <div>
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">T</div>
            <div className="right">
              <h6 className="heading">Edit Insurance Settings</h6>
              <p className="text">Edit the settings for Insurance here.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group custom labelup bg-light">
                  <Input
                    disabled={true}
                    type='text'
                    label='First Name'
                    value={this.state.firstName}
                    error={this.validator.message('First Name', this.state.firstName, 'required')}
                    controlFunc={e => this.handleInputChange(e, 'firstName')}
                  />

                </div>
              </div>
              {this.state.middleName ?
                <div className="col-md-4">
                  <div className="form-group custom labelup bg-light">
                    <Input
                      disabled={true}
                      type='text'
                      label='Middle Name'
                      value={this.state.middleName}
                      error={this.validator.message('Middle Name', this.state.middleName, 'required')}
                      controlFunc={e => this.handleInputChange(e, 'middleName')}
                    />
                  </div>
                </div>
                : ''
              }

              <div className="col-md-4">
                <div className="form-group custom labelup bg-light">
                  <Input
                    disabled={true}
                    type='text'
                    label='Last Name'
                    value={this.state.lastName}
                    error={this.validator.message('Last Name', this.state.lastName, 'required')}
                    controlFunc={e => this.handleInputChange(e, 'lastName')}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group custom labelup">
                  <Select
                    placeholder={this.state.selectedGender.label || "Select Gender"}
                    options={this.state.genderOptions}
                    onChange={selectedOption => this.handleDropdownChange(selectedOption, 'gender')}
                  />
                  <div className="help-block">{this.validator.message('Gender', this.state.selectedGender.label, 'required|alpha')}</div>

                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group custom labelup">
                  <Input
                    disabled
                    type='text'
                    label='Age'
                    value={this.state.age}
                    error={this.validator.message('Age', this.state.age, 'required|numeric')}
                    controlFunc={e => this.handleInputChange(e, 'age')}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group custom labelup" style={{ zIndex: '999' }}>
                  <DatePicker
                    label="Date Of Birth"
                    selected={this.state.dateOfBirth}
                    dateFormat='dd MMM yyyy'
                    showYearDropdown
                    maxDate={new Date()}
                    className="form-control"
                    onChange={date => this.handleDateChange(date, 'dateOfBirth')}
                  />
                  <label htmlFor="">Date Of Birth</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group custom labelup" style={{ zIndex: '999' }}>
                  <DatePicker
                    selected={this.state.passportIssueDate}
                    dateFormat='dd MMM yyyy'
                    maxDate={new Date()}
                    className="form-control"
                    onChange={date => this.handleDateChange(date, 'passportIssueDate')}
                  />
                  <label htmlFor="">Passport Issue Date</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group custom labelup" style={{ zIndex: '999' }}>
                  <DatePicker
                    selected={this.state.passportExpiryDate}
                    dateFormat='dd MMM yyyy'
                    onChange={date => this.handleDateChange(date, 'passportExpiryDate')}
                    className="form-control"
                  />
                  <label htmlFor="">Passport Expiry Date</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group custom labelup">
                  <Input
                    type='text'
                    label='Nationality'
                    value={this.state.nationality}
                    error={this.validator.message('Nationality', this.state.nationality, 'required')}
                    controlFunc={e => this.handleInputChange(e, 'nationality')}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group custom labelup">
                  <Input
                    type='text'
                    label='Mobile Number'
                    value={this.state.mobileNumber}
                    error={this.validator.message('Mobile Number', this.state.mobileNumber, 'required|numeric')}
                    controlFunc={e => this.handleInputChange(e, 'mobileNumber')}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group custom labelup">
                  <Input
                    type='text'
                    label='GST Number'
                    value={this.state.gstNumber}
                    error={this.validator.message('GST', this.state.gstNumber, 'required|min:15|max:15')}
                    controlFunc={e => this.handleInputChange(e, 'gstNumber')}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group custom labelup">
                  <Input
                    type='text'
                    label='Address'
                    value={this.state.address}
                    error={this.validator.message('Address', this.state.address, 'required')}
                    controlFunc={e => this.handleInputChange(e, 'address')}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group custom labelup">
                  <Input
                    type='text'
                    label='Nominee First Name'
                    value={this.state.nomineeFirstName}
                    error={this.validator.message('Nominee First Name', this.state.nomineeFirstName, 'required')}
                    controlFunc={e => this.handleInputChange(e, 'nomineeFirstName')}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group custom labelup">
                  <Input
                    type='text'
                    label='Nominee Last Name'
                    value={this.state.nomineeLastName}
                    error={this.validator.message('Nominee Last Name', this.state.nomineeLastName, 'required')}
                    controlFunc={e => this.handleInputChange(e, 'nomineeLastName')}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group custom labelup">
                  <Select
                    placeholder={this.state.selectedNominee.label || 'Select nominee '}
                    options={this.state.nomineeOptions}
                    onChange={selectedOption => this.handleDropdownChange(selectedOption, 'nominee')}

                  />

                  <label>Relation with Nominee</label>
                </div>
              </div>


            </div>
            <div className="btn-group-margin mt-4">
              <button
                onClick={this.handleSubmit}
                className="btn"
              >
                Save
              </button>
              <NavLink
                to="/my-account/insurance-settings"
                className="btn"
              >
                Cancel
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default InsuranceSettingsSavePage;
