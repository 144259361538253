import React from 'react';

const SortHead = (props) => (
    <th width={props.width} onClick={e => props.sortBy(props.feild)}>
        <div className="table-h">
            {props.name}
            {(props.column !== props.feild) ?
                <span className="table-sort">
                    <i className="material-icons">arrow_drop_down</i>
                    <i className="material-icons">arrow_drop_up</i>
                </span> : (props.column === props.feild && !props.isDesc) ?
                    <span className="table-sort">
                        <i className="material-icons">arrow_drop_down</i></span> :
                    <span className="table-sort"><i className="material-icons">arrow_drop_up</i></span>
            }
        </div>
    </th>
);

export default SortHead;  