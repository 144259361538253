import React, { Component } from "react";
import { cartServices, masterService } from "src/_services";
import Moment from "react-moment";
import Loader from "src/_components/common/Loader";

class TravelRequestDetailsSubmitPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      companyId: localStorage["440a28"],
      tripId: sessionStorage.getItem('32AWE'),
      fieldData: [],
      availablePassports: [],
      visa: [],
    }
    this.loading = true;
  }

  componentDidMount() {
    this.getTravelDetails()
    this.getVisaAndPass()
  }
  getVisaAndPass = async () => {
    const result = await cartServices.getAllPassports();
    if (result) {
      this.setState({
        availablePassports: result.passport,
        visa: result.visa
      });
    }
  }
  flag = ''
  getCountryName = async (name, type) => {
    if (this.flag === '') {
      const searchURL = 'https://restcountries.eu/rest/v2/name/';
      const url = searchURL + name;
      const response = await fetch(url);
      const responseData = await response.json();
      // console.log(responseData);
      var flag
      responseData.forEach(list => {
        if (list.name === name) {
          this.flag = list.flag
        }
      })
    }
  }
  flagVisa = ''
  getCountryVisa = async (name, type) => {
    if (this.flagVisa === '') {
      const searchURL = 'https://restcountries.eu/rest/v2/name/';
      const url = searchURL + name;
      const response = await fetch(url);
      const responseData = await response.json();
      // console.log(responseData);
      var flag
      responseData.forEach(list => {
        if (list.name === name || list.alpha3Code === name || list.alpha2Code === name) {
          this.flagVisa = list.flag
        }
      })
    }
  }
  formData = []
  requestList = [];
  allocationList = [];
  allocationType = '';
  personalDayOff = []
  noOfdays = 0;
  getTravelDetails = async () => {
    var resp = await cartServices.editTravelRequestFrom(this.state.companyId, this.state.tripId).catch(e => {
      this.loading = false;
      this.forceUpdate()
    })
    if (resp) {
      this.loading = false;
      this.formData = resp.data;
      (this.formData.REQUEST && this.formData.REQUEST['entryFields']) ? this.requestList = this.formData.REQUEST['entryFields'] : this.requestList = [];
      this.allocationType = this.formData.ALLOCATION && this.formData.ALLOCATION.allocatedIn;
      this.personalDayOff = this.formData.personalDayOff;
      (this.formData.ALLOCATION && this.formData.ALLOCATION['entryFields']) ? this.allocationList = this.formData.ALLOCATION['entryFields'] : this.allocationList = [];
      for (var i = 0; i < this.allocationList.length; i++) {
        await this.handleAddExpense()
        this.allocationList[i].map((item, key) => {
          this.state.fieldData[i][key]['fieldId'] = item.fieldId
          this.state.fieldData[i][key]['fieldValue'] = item.fieldValue
          this.state.fieldData[i][key]['columnName'] = item.columnName
          this.state.fieldData[i][key]['childColumnName'] = item.childColumnName
          this.state.fieldData[i][key]['parentId'] = (item.multiSelectParentField) ? item.multiSelectParentField : ''
          this.state.fieldData[i][key]['isDepended'] = (item.childColumnName) ? true : false;
          this.state.fieldData[i][key]['id'] = item.id
          if (item.childColumnName) {
            this.getListData(this.state.companyId, item.fieldValue, item.childColumnName)
          }
        })
      }
      this.forceUpdate()
    }
  }
  calcDays(from, to) {
    var date2 = new Date(to);
    var date1 = new Date(from);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return dayDifference + 1;
  }
  getListData = (companyId, parentId, child) => {
    masterService.getConnectedListData(this.state.companyId, parentId).then(resp => {
      if (resp) {
        this.tempItner = resp
        this.forceUpdate()
        this.state.fieldData.forEach(list => {
          list.forEach(item => {
            if (item.columnName == child) {
              item.connectedListData['listManagementData'] = this.tempItner;
              this.forceUpdate()
            }
          })
        })
      }
    })
  }
  handleAddExpense = () => {
    var temp = []
    this.allocationList[0] && this.allocationList[0].forEach(item => {
      var req = {
        fieldId: item.id,
        fieldValue: "",
        columnName: item.columnName,
        labelName: item.labelName,
        type: item.dataType,
        required: item.isMandatory,
        parentId: (item.multiSelectParentField) ? item.multiSelectParentField : '',
        isDepended: (item.childColumnName) ? true : false,
        connectedListData: (item.connectedListData) ? item.connectedListData : []
      }
      temp.push(req);
    })
    this.setState({
      fieldData: this.state.fieldData.concat([temp])
    });
  }
  render() {
    return (
      <div>
        <div className="p-padding top-48">
          {this.loading &&
            <Loader />
          }
          {!this.loading &&
            <div className="main-box">
              <div className="bg-light heading">
                <h6>Request Details</h6>
              </div>
              <div className="p-padding">
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      {this.requestList && this.requestList.map((list, index) => {

                        return (
                          <div className="col-md-6 mb-3" key={index}>
                            <p>{list.labelName}</p>
                            <h6 className="sub-heading">
                              {(list.connectedListData) &&
                                list.connectedListData.listManagementData ? list.connectedListData.listManagementData.map((main, ind) => {
                                  if (main.id == list.fieldValue) {
                                    return (
                                      <span key={ind}>{main.itemName}</span>
                                    )
                                  }
                                }) : list.fieldValue
                              }
                            </h6>
                          </div>
                        )
                      })}
                    </div>
                    <div className="form-group add-border mb-4">
                      {this.allocationList[0] &&
                        <label className="client">Allocation</label>}
                      <div className="table-container mx-0">
                        <div className="table-responsive">
                          <table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                {this.allocationList[0] && this.allocationList[0].map((list, index) => {
                                  return (
                                    <th key={index}>{(list.labelName) ? list.labelName : this.allocationType}</th>
                                  )
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.fieldData && this.state.fieldData.map((list, index) => {
                                return (
                                  <tr key={index}>
                                    {list.map((item, key) => {
                                      return (
                                        <td key={key}>
                                          {(item.connectedListData) &&
                                            item.connectedListData.listManagementData ? item.connectedListData.listManagementData.map((main, ind) => {
                                              if (main.id == item.fieldValue) {
                                                return (
                                                  <span key={ind}>{main.itemName}</span>
                                                )
                                              }
                                            }) : item.fieldValue
                                          }
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {this.personalDayOff &&
                      <div className="form-group add-border mb-4">
                        <label className="client">Personal Days Off</label>
                        <div className="table-container mx-0">
                          <div className="table-responsive">
                            <table className="table table-striped mb-0">
                              <thead>
                                <tr>
                                  <th>From</th>
                                  <th>To</th>
                                  <th>Day Count</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{this.personalDayOff.personalDayOffFrom}</td>
                                  <td>{this.personalDayOff.personalDayOffTO}</td>
                                  <td>{this.calcDays(this.personalDayOff.personalDayOffFrom, this.personalDayOff.personalDayOffTO)} Day</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mydivs ">
                      <div className="border-bottom">
                        <div className="float-left set-pad">
                          <h6>Passports</h6>
                        </div>
                        <div className="float-right px-3 py-2">
                        </div>
                        <div className="clearfix" />
                      </div>
                      {!this.formData.passportNumber && "No Passport linked with this trip"}
                      {this.state.availablePassports && this.state.availablePassports.map((list, index) => {
                        if (list._id === this.formData.passportNumber) {
                          this.getCountryName(list.countryOfIssue)
                          return (
                            <div className="country-passport" key={index}>
                              <div className="first-panel">
                                <span>{list.countryOfIssue} Passport</span>
                              </div>
                              <div className="second-panel">
                                <div className="float-left">
                                  <h6>Passport Number</h6>
                                  <h5>{list.passportNumber}</h5>
                                </div>
                                <div className="float-right">
                                  <img src={this.flag} alt="" className="img-fluid" width="20" />

                                  <h6>{list.countryOfIssue}</h6>
                                </div>
                                <div className="clearfix" />
                                <div className="dates">
                                  <div className="float-left">
                                    <h6>ISSUE DATE</h6>
                                    <h5>
                                      <Moment format="DD MMM YYYY" >
                                        {list.dateOfIssue}
                                      </Moment>
                                    </h5>
                                  </div>
                                  <div className="float-right">
                                    <h6>Expire Date</h6>
                                    <h5><Moment format="DD MMM YYYY" >
                                      {list.expiryDate}
                                    </Moment></h5>
                                  </div>
                                  <div className="clearfix" />
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}
                    </div>
                    <div className="form-group mydivs ">
                      <div className="border-bottom">
                        <div className="float-left set-pad">
                          <h6>Visa</h6>
                        </div>
                        <div className="float-right px-3 py-2">
                        </div>
                        <div className="clearfix" />
                      </div>
                      {this.state.visa && this.state.visa.map((list, index) => {
                        if (list._id === this.formData.visaNumber) {
                          this.getCountryVisa(list.issuedFor, 1)
                          return (
                            <div className="country-passport" key={index}>
                              <div className="first-panel">
                                <span>{list.issuedFor} Visa</span>
                              </div>
                              <div className="second-panel">
                                <div className="float-left">
                                  <h6>Visa Number</h6>
                                  <h5>{list.visaNumber}</h5>
                                </div>
                                <div className="float-right">
                                  <img src={this.flagVisa} alt="" className="img-fluid" width="20" />

                                  <h6>{list.issuedFor}</h6>
                                </div>
                                <div className="clearfix" />
                                <div className="dates">
                                  <div className="float-left">
                                    <h6>ISSUE DATE</h6>
                                    <h5>
                                      <Moment format="DD MMM YYYY" >
                                        {list.dateOfIssue}
                                      </Moment>
                                    </h5>
                                  </div>
                                  <div className="float-right">
                                    <h6>Expire Date</h6>
                                    <h5><Moment format="DD MMM YYYY" >
                                      {list.expiryDate}
                                    </Moment></h5>
                                  </div>
                                  <div className="clearfix" />
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}
                    </div>
                    <div className="form-group mydivs mb-0">
                      <div className="border-bottom">
                        <div className="set-pad ">
                          <h6>Approver Flow</h6>
                        </div>
                      </div>
                      <div className="approvers">
                        <div className="user-panel">
                          <div className="float-left">
                            <img
                              src="/assets/images/booking/user_11.png"
                              alt=""
                            />
                          </div>
                          <div className="float-left ml-2">
                            <h6>LEVEL 1</h6>
                            <h5>George Green</h5>
                            <h6>Finance</h6>
                          </div>
                          <div className="clearfix" />
                        </div>
                        <div className="user-panel bg-light">
                          <div className="float-left">
                            <img
                              src="/assets/images/booking/user_12.png"
                              alt=""
                            />
                          </div>
                          <div className="float-left ml-2">
                            <h6>LEVEL 2</h6>
                            <h5>Natasha McCoy</h5>
                            <h6>Approver</h6>
                          </div>
                          <div className="clearfix" />
                        </div>
                        <div className="user-panel">
                          <div className="float-left">
                            <img
                              src="/assets/images/booking/user_11.png"
                              alt=""
                            />
                          </div>
                          <div className="float-left ml-2">
                            <h6>LEVEL 3</h6>
                            <h5>Vincent Lawson</h5>
                            <h6>Manager</h6>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
export default TravelRequestDetailsSubmitPage;
