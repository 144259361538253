import debug from "debug";

/*::
type debug = {
  extend: string => debug
}
*/

const d = debug("app");

/**
 * A logger to show log message using `debug` module.
 * - Run the app using `DEBUG=app:*,-app:verbose:*` as env var
 * to show debug messages without verbose logging.
 *
 * Debug messages will appear as
 * `app:Service:getMain`
 *
 * @param {String} name Name of the Logger
 * @example
 * import { createLogger } from "src/_helpers";
 * const debugService = createLogger("Service");
 * const d = debugService.extend("getMain");
 * d('Fetching data...');
 *
 * @returns {Object} Instance of `debug` with `.extend` function.
 */
export const createLogger = (name /*: string */) /*: debug */ => d.extend(name);

/**
 * A logger to show log message using `debug` module
 * Use verbose logging to log large data.
 * - Run the app using `DEBUG=app:*,-app:verbose:*` as env var
 * to show debug messages without verbose logging.
 * - Run the app using `DEBUG=app:*` to show all debug messages.
 * - Run the app using `DEBUG=app:verbose:*` to show only
 * verbose debug message (useless in itself).
 *
 * Debug messages will appear as
 * `app:Service:getMain`
 * `app:verbose:Service:getMain`
 *
 * @param {String} name Name of the Logger
 * @example
 * import { createVerboseLogger } from "src/_helpers";
 * const verboseDebugService = createVerboseLogger("Service");
 * const vd = debugService.extend("getMain");
 * vd('Data: %O', data);
 *
 * @returns {Object} Instance of `debug` with `.extend` function.
 */
export const createVerboseLogger = (name /*: string */) /*: debug */ =>
  createLogger("verbose").extend(name);
