/* eslint-disable default-case */
import React, { Component } from "react";
import { map } from 'lodash/fp';
import WorkflowsStep1Page from "./workflow-step1-html";
import { workflowService } from "src/_services/workflow.service";
import { toast } from "react-toastify";
import { alertConstants } from "src/_constants";
import SimpleReactValidator from "simple-react-validator";

class WorkflowsStep1 extends Component {
  state = {
    companyId: localStorage["440a28"],
    loading: false,
    workflowDetails: [],
    // *  all the master options
    allMasterOptions: [],
    // * all Groups
    allGroups: [],
    allGroupOptions: [],
    selectedGroups: [],
    // * Service module id will be received after selecting workflow type, send the service module if to receive all the email templates
    service_module: '',

    workflowName: "",
    workflowType: "",
    paymentConfermation: false,
    holdForReciept: false,
    budgetApproval: false,
    expireAfter: 1,
    expirationEmail: "",
    notDispalySkip: false,
    stepsAddedBy: "",
    mailToEmployee: false,
    restrictAuthorizedApprover: "",
    restrictApproverForLimit: false,
    restrictApproverForException: false,
    editableBy: [],
    allowEmployeeReCall: false,
    skipAlreadyApprove: false,
    costObjectHierarchy: "",
    submitConfirmationAgreement: "",
    approvalConfirmationAgreement: "",
    approvalRequestNotification: "",
    stateChangeNotification: "",
    sentBackNotification: "",
    requestNotification: "",
    rejectNotification: "",
    addingNewApproverNotification: "",
    courtesyEmail: "",
    editMode: false,
    displayApproverAboveCurrentApprover: false,
    defaultApprovalLookup: false,
    // allowAdhoc: false,

    // * approval time expired action
    approvalTimeExpirestep: "",
    approvalTimeExpirestepOptions: {},
    selectedapprovalTimeExpirestep: '',

    // * Email Templates
    allEmailTemplates: [],

    // * Editable by options
    editableByOptions: [],

    // * workflowId
    workflow_id: null,

    allWorkflowSteps: [],
    isUpdate: false,

    selectedOption: "",
    startDate: new Date(),
    hours: "00",
    mins: "00"


  };
  validator = new SimpleReactValidator();
  // Show loading.. till loading is done
  loading = "pending";
  // componentWillUnmount = async () => {
  //   localStorage.setItem('staticIs','staticIsKey');
  // }
  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // * If edit mode is on get workflow details
    if (this.props.match.params.id !== undefined) {
      await this.setState({ editMode: true });
      this.getWorkflowGeneralDetails(this.props.match.params.id);
      // once have the data then only allow to 3rd step
      this.getAllWorkFlowSteps(this.props.match.params.id)

    }

    this.getMasterOptionsForGeneral();
    this.getAllGroups();

  }
  getAllWorkFlowSteps = async (id) => {
    let res = await workflowService.getAllWorkflowSteps(id);
    if (res) {
      this.setState({
        allWorkflowSteps: res.result
      });
    }
  }
  // * get all the groups from list management
  getAllGroups = async () => {
    this.loading = "pending";
    let res = await workflowService.getAllGroups(this.state.companyId);
    if (res.result != undefined) {
      this.loading = "done";
      await this.setState({
        allGroups: res.result
      });
      let allGroupOptions = this.state.allGroups.map(group => {
        return {
          value: group.id,
          label: group.itemName
        }
      });
      this.setState({ allGroupOptions });

    }
  }

  // * when editing mode the following will get the details
  getWorkflowGeneralDetails = async (id) => {
    this.loading = "pending";
    let res = await workflowService.getWorkflowGeneralDetails(id);
    console.log(res.result)
    if (res && res.result != undefined && res.result.length > 0) {
      this.loading = "done";
      // * set up all the values in the state
      let result = res.result[0];
      if(result.expireInHours){
        let hrs = result.expireInHours.split(":")
        this.setState({hours:hrs[0],mins:hrs[1]})
        console.log(hrs)
      }
      else if(result.expireAfter){
        this.setState({expireAfter:result.expireAfter})
      }
      await this.setState({
        service_module: result.workflowType.master_value.service_module,
        editableByOptions: result.editableBy.map(item => item.id)
      });
      this.getEmailTemplates(this.state.service_module);
      this.setState({
        allowEmployeeReCall: result.allowEmployeeReCall,
        approvalRequestNotification: result.approvalRequestNotification,
        approvalTimeExpirestep: result.approvalTimeExpirestep,
        budgetApproval: result.budgetApproval,
        costObjectHierarchy: result.costObjectHierarchy,
        addingNewApproverNotification: result.addingNewApproverNotification,
        editableBy: result.editableBy,
        expirationEmail: result.expirationEmail,
        expireAfter: result.expireAfter,
        holdForReciept: result.holdForReciept,
        mailToEmployee: result.mailToEmployee,
        notDispalySkip: result.notDispalySkip,
        paymentConfermation: result.paymentConfermation,
        restrictApproverForException: result.restrictApproverForException,
        restrictApproverForLimit: result.restrictApproverForLimit,
        sentBackNotification: result.sentBackNotification,
        rejectNotification: result.rejectNotification,
        requestNotification: result.requestNotification,
        skipAlreadyApprove: result.skipAlreadyApprove,
        stateChangeNotification: result.stateChangeNotification,
        workflowName: result.workflowName,
        workflowType: result.workflowType,
        workflow_id: result.workflow_id,

        selectedworkflowType: result.workflowType.master_value.master_name_value,
        selectedapprovalTimeExpirestep: result.approvalTimeExpirestep.master_value.master_name_value,
        selectedexpirationEmail: result.expirationEmail.name,
        selectedcostObjectHierarchy: result.costObjectHierarchy.master_value.master_name_value,
        selectedapprovalRequestNotification: result.approvalRequestNotification.name,
        selectedstateChangeNotification: result.stateChangeNotification.name,
        selectedsentBackNotification: result.sentBackNotification.name,
        selectedaddingNewApproverNotification: result.addingNewApproverNotification?.name,
        selectedcourtesyEmail: result.courtesyEmail?.name,
        selectedrequestNotification: result.requestNotification?.name,
        selectedrejectNotification: result.rejectNotification?.name,

      });
    }

  }

  // * For getting all the email templates

  getEmailTemplates = async (service_module) => {
    this.loading = "pending";
    let res = await workflowService.getEmailTemplates(this.state.companyId, service_module);
    if (res) {
      this.loading = "done";
      await this.setState({ allEmailTemplates: res.result });
      this.generateEmailTemplatesOptions();
    }
  }
  handleContentChanged = () => {
    this.setState({
      isUpdate: true
    })
  }

  // * Generate email options for dropdowns

  generateEmailTemplatesOptions = () => {
    // console.log("the generated email options are ", this.state.allEmailTemplates);
    if (this.state.allEmailTemplates.length != 0) {
      this.setState({
        allEmailTemplatesOptions: this.state.allEmailTemplates
      })
    }
  }

  // * handling email dropdown change
  handleEmailChange = (e, dropdownName) => {
    let selectedDropdown = 'selected' + dropdownName;
    this.setState({
      [dropdownName]: {
        name: e.name,
        serviceModuleId: e.serviceModuleId,
        emailTemplateId: e.id
      },
      [selectedDropdown]: e.name
    })
    this.handleContentChanged()
  }

  // * when editable by group changes, handle it here.


  handleGroupChange = (item) => {
    let group = item.map(i => {
      return {
        id: i.value,
        itemName: i.label
      }
    });
    this.setState({
      editableBy: group,
      editableByOptions: map('value', item)
    });
    this.handleContentChanged()
  }




  // * get all master data ie options for the dropdown
  getMasterOptionsForGeneral = async () => {
    this.loading = "pending";
    let res = await workflowService.getMasterOptionsForGeneral();
    if (res.result != undefined) {
      this.loading = "done";
      await this.setState({
        allMasterOptions: res.result
      });
      this.setOptions();
    }
  }

  // * Set master options to the field
  setOptions = () => {
    this.state.allMasterOptions.forEach(item => {
      // * loop through all masterOptions and create options with master_name + 'Options'
      // * these options will be used for  creating the options for the select dropdowns
      let option = item['master_name'] + 'Options';
      this.setState({
        [option]: item
      });
    })
  }

  // * for now its for dropdown change. and select, trying to make it reusable
  handleDropdownChange = (e, dropdownOptions, dropdownName) => {
    localStorage.setItem('staticIs', e.master_name_value);
    if (dropdownName == 'workflowType') {
      this.setState({
        service_module: e.service_module,
        // reseting previously choosed templates
        selectedaddingNewApproverNotification: "",
        selectedexpirationEmail: "",
        selectedrejectNotification: "",
        selectedrequestNotification: "",
        selectedsentBackNotification: "",
        selectedstateChangeNotification: "",
        selectedapprovalRequestNotification: "",

        addingNewApproverNotification: "",
        expirationEmail: "",
        rejectNotification: "",
        requestNotification: "",
        sentBackNotification: "",
        stateChangeNotification: "",
        approvalRequestNotification: "",

      }, () => {
        this.getEmailTemplates(this.state.service_module);
      })
    }


    let selectedDropdown = 'selected' + dropdownName;
    let dropDownData = {
      master_name: dropdownName,
      master_id: dropdownOptions.master_id,
      master_value: e
    };

    // * set the selected dropdown value and store the data to be sent a request in the a state variable with same name as dropdownName
    this.setState({
      [selectedDropdown]: e.master_name_value,
      [dropdownName]: dropDownData
    });
    this.handleContentChanged()
  };

  // * For handling the input of workflow type, workflow name and expire after days input box
  handleInputChange = (e, inputName) => {
    this.setState({ [inputName]: e.target.value });
    this.handleContentChanged()
  };

  // * For handing the toggles in the page
  handleToggleChange = (e, toggleName) => {
    this.setState({
      [toggleName]: e.target.checked
    });
  }

  // * on submitting form

  handleCreate = async (e) => {
    e.preventDefault();

    if (this.state.selectedOption.value === "days" && this.state.expireAfter !== "") {
      let payload = {
        expiryType: "days",
        expireAfter: this.state.expireAfter,


        companyId: this.state.companyId,
        workflowName: this.state.workflowName,
        workflowType: this.state.workflowType,
        paymentConfermation: this.state.paymentConfermation,
        holdForReciept: this.state.holdForReciept,
        budgetApproval: this.state.budgetApproval,
        approvalTimeExpirestep: this.state.approvalTimeExpirestep,
        expirationEmail: this.state.expirationEmail,
        notDispalySkip: this.state.notDispalySkip,
        mailToEmployee: this.state.mailToEmployee,
        restrictApproverForLimit: this.state.restrictApproverForLimit,
        restrictApproverForException: this.state.restrictApproverForException,
        editableBy: this.state.editableBy,
        allowEmployeeReCall: this.state.allowEmployeeReCall,
        skipAlreadyApprove: this.state.skipAlreadyApprove,
        costObjectHierarchy: this.state.costObjectHierarchy,
        approvalRequestNotification: this.state.approvalRequestNotification,
        stateChangeNotification: this.state.stateChangeNotification,
        addingNewApproverNotification: this.state.addingNewApproverNotification,
        courtesyEmail: this.state.courtesyEmail,
        // displayApproverAboveCurrentApprover: this.state.displayApproverAboveCurrentApprover,
        // defaultApprovalLookup: this.state.defaultApprovalLookup,
        sentBackNotification: this.state.sentBackNotification,
        rejectNotification: this.state.rejectNotification,
        requestNotification: this.state.requestNotification
        // allowAdhoc: this.state.allowAdhoc,


      };
      // console.log("the payload to be sent is ", payload);
      if (this.validator.allValid()) {
        this.setState({ loading: true })
        let res = await workflowService.createGeneralWorkflow(payload);
        this.setState({ loading: false })
        if (res) {
          if (res && res.result.WorkflowId) {
            this.setState({ workflow_id: res.result.WorkflowId });
          }
          toast.success(alertConstants.CREATED_SUCCESSFULLY);

          this.props.history.push(`/administration/company/workflows/workflow-step2/${this.state.workflow_id}`);
        }
      } else {
        this.validator.showMessages();
        this.setState({ ...this.state });
      }
    } else {
      let payload = {
        expiryType: "hours",
        expireInHours: this.state.hours.concat(":", this.state.mins),


        companyId: this.state.companyId,
        workflowName: this.state.workflowName,
        workflowType: this.state.workflowType,
        paymentConfermation: this.state.paymentConfermation,
        holdForReciept: this.state.holdForReciept,
        budgetApproval: this.state.budgetApproval,
        approvalTimeExpirestep: this.state.approvalTimeExpirestep,
        expireAfter: this.state.expireAfter,
        expirationEmail: this.state.expirationEmail,
        notDispalySkip: this.state.notDispalySkip,
        mailToEmployee: this.state.mailToEmployee,
        restrictApproverForLimit: this.state.restrictApproverForLimit,
        restrictApproverForException: this.state.restrictApproverForException,
        editableBy: this.state.editableBy,
        allowEmployeeReCall: this.state.allowEmployeeReCall,
        skipAlreadyApprove: this.state.skipAlreadyApprove,
        costObjectHierarchy: this.state.costObjectHierarchy,
        approvalRequestNotification: this.state.approvalRequestNotification,
        stateChangeNotification: this.state.stateChangeNotification,
        addingNewApproverNotification: this.state.addingNewApproverNotification,
        courtesyEmail: this.state.courtesyEmail,
        // displayApproverAboveCurrentApprover: this.state.displayApproverAboveCurrentApprover,
        // defaultApprovalLookup: this.state.defaultApprovalLookup,
        sentBackNotification: this.state.sentBackNotification,
        rejectNotification: this.state.rejectNotification,
        requestNotification: this.state.requestNotification
        // allowAdhoc: this.state.allowAdhoc,


      };
      // console.log("the payload to be sent is ", payload);
      if (this.validator.allValid()) {
        this.setState({ loading: true })
        let res = await workflowService.createGeneralWorkflow(payload);
        this.setState({ loading: false })
        if (res) {
          toast.success(alertConstants.CREATED_SUCCESSFULLY);
          this.setState({ workflow_id: res.result.WorkflowId });
          this.props.history.push(`/administration/company/workflows/workflow-step2/${this.state.workflow_id}`);
        }
      } else {
        this.validator.showMessages();
        this.setState({ ...this.state });
      }
    }
  }

  // Handle update for edit mode
  handleUpdate = async () => {
    if (this.state.selectedOption.value === "days" && this.state.expireAfter !== "") {
      let payload = {
        expiryType: "days",
        expireAfter: this.state.expireAfter,

        companyId: this.state.companyId,
        workflowName: this.state.workflowName,
        workflowType: this.state.workflowType,
        paymentConfermation: this.state.paymentConfermation,
        holdForReciept: this.state.holdForReciept,
        budgetApproval: this.state.budgetApproval,
        approvalTimeExpirestep: this.state.approvalTimeExpirestep,

        expirationEmail: this.state.expirationEmail,
        notDispalySkip: this.state.notDispalySkip,
        mailToEmployee: this.state.mailToEmployee,
        restrictApproverForLimit: this.state.restrictApproverForLimit,
        restrictApproverForException: this.state.restrictApproverForException,
        editableBy: this.state.editableBy,
        allowEmployeeReCall: this.state.allowEmployeeReCall,
        skipAlreadyApprove: this.state.skipAlreadyApprove,
        costObjectHierarchy: this.state.costObjectHierarchy,
        approvalRequestNotification: this.state.approvalRequestNotification,
        stateChangeNotification: this.state.stateChangeNotification,
        addingNewApproverNotification: this.state.addingNewApproverNotification,
        courtesyEmail: this.state.courtesyEmail,
        // displayApproverAboveCurrentApprover: this.state.displayApproverAboveCurrentApprover,
        // defaultApprovalLookup: this.state.defaultApprovalLookup,
        sentBackNotification: this.state.sentBackNotification,
        rejectNotification: this.state.rejectNotification,
        requestNotification: this.state.requestNotification
        // allowAdhoc: this.state.allowAdhoc,

      };
      if (this.validator.allValid()) {
        this.setState({ loading: true })
        let res = await workflowService.updateGeneralWorkflow(this.state.companyId, this.state.workflow_id, payload);
        this.setState({ loading: false })
        if (res) {
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
          this.props.history.push(`/administration/company/workflows/workflow-step2/${this.state.workflow_id}`);
        }
      } else {
        this.validator.showMessages();
        this.setState({ ...this.state })
      }
    } else {
      let payload = {
        expiryType: "hours",
        expireInHours: this.state.hours.concat(":", this.state.mins),


        companyId: this.state.companyId,
        workflowName: this.state.workflowName,
        workflowType: this.state.workflowType,
        paymentConfermation: this.state.paymentConfermation,
        holdForReciept: this.state.holdForReciept,
        budgetApproval: this.state.budgetApproval,
        approvalTimeExpirestep: this.state.approvalTimeExpirestep,
        expireAfter: this.state.expireAfter,
        expirationEmail: this.state.expirationEmail,
        notDispalySkip: this.state.notDispalySkip,
        mailToEmployee: this.state.mailToEmployee,
        restrictApproverForLimit: this.state.restrictApproverForLimit,
        restrictApproverForException: this.state.restrictApproverForException,
        editableBy: this.state.editableBy,
        allowEmployeeReCall: this.state.allowEmployeeReCall,
        skipAlreadyApprove: this.state.skipAlreadyApprove,
        costObjectHierarchy: this.state.costObjectHierarchy,
        approvalRequestNotification: this.state.approvalRequestNotification,
        stateChangeNotification: this.state.stateChangeNotification,
        addingNewApproverNotification: this.state.addingNewApproverNotification,
        courtesyEmail: this.state.courtesyEmail,
        // displayApproverAboveCurrentApprover: this.state.displayApproverAboveCurrentApprover,
        // defaultApprovalLookup: this.state.defaultApprovalLookup,
        sentBackNotification: this.state.sentBackNotification,
        rejectNotification: this.state.rejectNotification,
        requestNotification: this.state.requestNotification
        // allowAdhoc: this.state.allowAdhoc,

      };
      if (this.validator.allValid()) {
        this.setState({ loading: true })
        let res = await workflowService.updateGeneralWorkflow(this.state.companyId, this.state.workflow_id, payload);
        this.setState({ loading: false })
        if (res) {
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
          this.props.history.push(`/administration/company/workflows/workflow-step2/${this.state.workflow_id}`);
        }
      } else {
        this.validator.showMessages();
        this.setState({ ...this.state })
      }
    }
  }

  cancelHandler = (e) => {
    e.preventDefault();
    this.props.history.push('/administration/company/workflows/home');
  }

  handleChangeD = (selectedOption) => {
    // console.log(selectedOption);
    this.setState({ selectedOption: selectedOption })
  }

  render() {
    return (
      <div>
        <WorkflowsStep1Page
          handleDropdownChange={this.handleDropdownChange}
          handleInputChange={this.handleInputChange}
          state={this.state}
          handleToggleChange={this.handleToggleChange}
          handleEmailChange={this.handleEmailChange}
          handleCreate={this.handleCreate}
          handleUpdate={this.handleUpdate}
          handleGroupChange={this.handleGroupChange}
          cancelHandler={this.cancelHandler}
          error={this.validator}
          processId={this.props.match.params.id}
          handleChangeD={this.handleChangeD}
        />
      </div>
    );
  }
}
export default WorkflowsStep1;
