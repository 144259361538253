import React, { Component } from "react";
import ExpenseHeader from "./components/expenseHeader";
import CardtransactionFilter from "./components/cardTransactionFilter";
import CardTransactionRow from "./components/cardTransactionRow";
import CardTransactionHead from "./components/cardTransactionHead";
class CardTransactionPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      selected: "This Month",
      searchText: "",
      checked: {},
      checkedAll: 0,
      dataList: [
        {
          firstName: "Credit Card",
          lastName: "Hotel",
          age: 16
        }
      ]
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }
  toggleRow = firstName => {
    const newChecked = Object.assign({}, this.state.checked);
    newChecked[firstName] = !this.state.checked[firstName];
    this.setState({
      checked: newChecked,
      checkedAll: 2
    });
  };

  toggleSelectAll = () => {
    let newChecked = {};

    if (this.state.checkedAll === 0) {
      this.state.dataList.forEach(x => {
        newChecked[x.firstName] = true;
      });
    }

    this.setState({
      checked: newChecked,
      checkedAll: this.state.checkedAll === 0 ? 1 : 0
    });
    // console.log(this.state.checked);
  };

  onSubmitHandler = event => {
    event.preventDefault();
  };
  onSelectHandler = option => {
    this.setState({ selected: option });
  };
  onChangeHandler = event => {
    this.setState({ searchText: event.target.value });
  };
  onSearchHandler = () => {
    console.log(this.state.selected, this.state.searchText);
  };

  render() {
    const activeNav = this.props.location.pathname.split("/")[2];
    return (
      <div>
        <div className="p-padding">
          <ExpenseHeader activeNav={activeNav} />
          <div className="white-card">
            <div className="form-holder py-0">
              <div className="table-container">
                <CardtransactionFilter
                  onSubmitHandler={this.onSubmitHandler}
                  onSelectHandler={this.onSelectHandler}
                  onChangeHandler={this.onChangeHandler}
                  selected={this.state.selected}
                  onSearchHandler={this.onSearchHandler}
                />
                <div className="table-responsive">
                  <table className="table table-striped">
                    <CardTransactionHead
                      checkedAll={this.state.checkedAll}
                      toggleSelectAll={this.toggleSelectAll}
                    />
                    <tbody>
                      <CardTransactionRow
                        dataList={this.state.dataList}
                        toggleRow={this.toggleRow}
                        selected={this.state.selected}
                        checked={this.state.checked}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CardTransactionPage;
