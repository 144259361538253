/* eslint-disable no-fallthrough */
import React from "react";
import uuid from "uuid";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DatePicker from "react-datepicker";
import "react-day-picker/lib/style.css";
import _ from "lodash";
import DynamicFieldsType from 'src/_components/common/dynamicFields';
import AsyncSelect from "react-select/lib/Async";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import moment from 'moment';

const expenseCreateForm = props => {
  return props.policyForm.map((form, index) => {
    let formFieldName = form.fieldName;
    let userPermission = props.userPermission;
    let permissions = form.fieldRolePermissions && form.fieldRolePermissions;
    let newPermissionArray = permissions && permissions.map((permsList) => (permsList.roleId.roleKey));
    let permissionIndex = newPermissionArray && newPermissionArray.indexOf(userPermission);
    let accessPermission = 2;
    if (permissionIndex >= 0) {
      accessPermission = parseInt(permissions && permissions[permissionIndex].permissionId);
    } else {
      accessPermission = 2;
    }

    // eslint-disable-next-line default-case
    switch (form.dataType) {
      case DynamicFieldsType.DATA_TYPE_CONNECTED_LIST:
        let NewformFieldName = form.columnName;
        if (accessPermission > 0 && form.activeStatus) {
          if (form.fieldLevel > 1) {
            let selectedDropDown = '';
            formFieldName = _.toUpper(NewformFieldName);
            let originalField = _.startCase(_.camelCase(form.fieldName));
            return (
              <div key={index} className="">
                <div className="form-group custom labelup">
                  <label>{form.labelName}</label>
                  <div className="dropdown" key={uuid.v4()}>
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    // disabled={accessPermission === 1}
                    >
                      {props[formFieldName] && props[formFieldName].forEach(element => {
                        if (props[form.fieldName] === element.id) {
                          selectedDropDown = element.itemName;
                        }
                      })}
                      {selectedDropDown ? selectedDropDown : form.labelName}{!selectedDropDown ? form.isMandatory && <span className="text-danger">*</span> : ''}
                    </button>
                    <div className="dropdown-menu">
                      {props[formFieldName] && props[formFieldName].map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onConnectedListkHandler(form.fieldName, form.multiSelectParentField, opt.id, form.fieldLevel)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.itemName}
                            </button>
                          );
                        }
                      )}
                    </div>
                    {form.isMandatory ? (
                      <div className="help-block">
                        {props.validator.message(
                          form.labelName,
                          props[form.fieldName],
                          "required"
                        )}
                      </div>
                    ) : (
                        ""
                      )}
                  </div>
                </div>
              </div>
            );
          } else {
            let selectedDropDown = '';
            return (
              <div key={index} className="">
                <div className="form-group custom labelup">
                  <label>{form.labelName}</label>
                  <div className="dropdown" key={uuid.v4()}>
                    <button
                      disabled={accessPermission === 1 || (!(props.isEdit || form.columnName !== "GROUP_NAME"))}
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      {form.connectedListData && form.connectedListData.listManagementData.forEach(element => {
                        if (props[formFieldName] === element.id) {
                          selectedDropDown = element.itemName;

                        }
                      })}
                      {selectedDropDown ? selectedDropDown : form.labelName}{!selectedDropDown ? form.isMandatory && <span className="text-danger">*</span> : ''}
                    </button>
                    <div className="dropdown-menu">
                      {form.connectedListData && form.connectedListData.listManagementData.map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onClickHandler(form.fieldName, form.id, opt.id, form.fieldLevel)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.itemName}
                            </button>
                          );
                        }
                      )}
                    </div>
                    {form.isMandatory ? (
                      <div className="help-block">
                        {props.validator.message(
                          form.labelName,
                          props[form.fieldName],
                          "required"
                        )}
                      </div>
                    ) : (
                        ""
                      )}
                  </div>
                </div>
              </div>
            );
          }
        }
      case DynamicFieldsType.DATA_TYPE_TEXT:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="">
              <div className="form-group custom">
                <input
                  type="text"
                  readOnly={accessPermission === 1}
                  placeholder="&nbsp;"
                  name={form.fieldName}
                  maxLength={form.maxLength || 10000}
                  onChange={e => props.onChangeHandler(e, form.fieldName)}
                  value={props[form.fieldName] ? props[form.fieldName] : ""}
                  className="form-control"
                />
                <label>{form.labelName}{form.isMandatory && <span className="text-danger">*</span>}</label>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName],
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
          );
        }
      case DynamicFieldsType.DATA_TYPE_DATE:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="" >
              <div className="form-group custom labelup" style={{zIndex: 999}} >
                <label>{form.labelName}</label>
                {/* <DayPickerInput
                  format="DD MMM YYYY"
                  formatDate={formatDate}
                  inputProps={{ disabled: accessPermission === 1 }}
                  dayPickerProps={{ locale: 'en-gb', localeUtils: MomentLocaleUtils, disabledDays: { after: new Date() } }}
                  selectedDays={
                    moment(new Date(props[form.fieldName]), 'DD/MM/YYYY') ? moment(new Date(props[form.fieldName]), 'DD/MM/YYYY') : new Date()
                  }
                  format="LL"
                  onDayChange={e => props.onDatePickerHandler(e, form.fieldName)}
                  placeholder={props[form.fieldName] ? moment(new Date(props[form.fieldName])).format('DD MMM YYYY') : form.labelName}
                /> */}

                <DatePicker
                  dateFormat="dd MMM yyyy"
                  selected={new Date(props[form.fieldName])}
                  onChange={e => props.onDatePickerHandler(e, form.fieldName)}
                  maxDate={new Date()}
                  showYearDropdown
                  showMonthDropdown
                  placeholderText="DD MMM YYYY"
                  className="form-control"
                />
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName],
                      "required"
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
          );
        }
      case DynamicFieldsType.DATA_TYPE_TEXTAREA:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="">
              <div className="form-group custom textarea">
                <textarea
                  data-gramm_editor="false"
                  spellCheck="false"
                  rows="3"
                  name={form.fieldName}
                  readOnly={accessPermission === 1}
                  className="form-control"
                  maxLength={form.maxLength || 10000}
                  onChange={e => props.onChangeHandler(e, form.fieldName)}
                  value={props[form.fieldName] ? props[form.fieldName] : ""}
                  placeholder="&nbsp;"
                />
                <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName],
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
          );
        }
      case DynamicFieldsType.DATA_TYPE_CHECKBOX:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="col-md-6">
              <div className="form-group">
                <label className="switch">
                  <input
                    type="checkbox"
                    disabled={accessPermission === 1}
                    value={props[form.fieldName] ? props[form.fieldName] : false}
                    checked={props[form.fieldName] ? props[form.fieldName] : false}
                    onChange={e => props.onCheckboxHandler(form.fieldName)}
                  />
                  <span className="slider round" />
                </label>
                <span>{form.labelName}{form.isMandatory && <span className="text-danger">*</span>}</span>
              </div>
              <div className="help-block">{props.error[form.fieldName]}</div>
            </div>
          );
        }
      case DynamicFieldsType.DATA_TYPE_BOOLEAN:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="col-md-6">
              <div className="form-group">
                <label className="switch">
                  <input
                    disabled={accessPermission === 1}
                    type="checkbox"
                    value={props[form.fieldName] ? props[form.fieldName] : false}
                    checked={props[form.fieldName] ? props[form.fieldName] : false}
                    onChange={e => props.onCheckboxHandler(form.fieldName)}
                  />
                  <span className="slider round" />
                </label>
                <span>{form.labelName}{form.isMandatory && <span className="text-danger">*</span>}</span>
              </div>
              <div className="help-block">{props.error[form.fieldName]}</div>
            </div>
          );
        }
      case DynamicFieldsType.DATA_TYPE_INTEGER:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="col-md-6">
              <div className="form-group custom">
                <input
                  type="text"
                  placeholder="&nbsp;"
                  name={form.fieldName}
                  readOnly={accessPermission === 1}
                  maxLength={form.maxLength || 10000}
                  onChange={e => props.onIntegerHandler(e, form.fieldName)}
                  value={props[form.fieldName] ? props[form.fieldName] : ""}
                  className="form-control"
                />
                <label>{form.labelName}{form.isMandatory && <span className="text-danger">*</span>}</label>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName],
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
          );
        }
      case DynamicFieldsType.DATA_TYPE_AMOUNT:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="col-md-6">
              <div className="form-group custom">
                <input
                  type="text"
                  placeholder="&nbsp;"
                  name={form.fieldName}
                  readOnly={accessPermission === 1}
                  maxLength={form.maxLength || 10000}
                  onChange={e => props.onIntegerHandler(e, form.fieldName)}
                  value={props[form.fieldName] ? props[form.fieldName] : ""}
                  className="form-control"
                />
                <label>{form.labelName}{form.isMandatory && <span className="text-danger">*</span>}</label>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName],
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
          );
        }
      case DynamicFieldsType.DATA_TYPE_EMAIL:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="col-md-6">
              <div className="form-group custom">
                <input
                  type="text"
                  placeholder="&nbsp;"
                  name={form.fieldName}
                  readOnly={accessPermission === 1}
                  maxLength={form.maxLength || 10000}
                  onChange={e => props.onChangeHandler(e, form.fieldName)}
                  value={props[form.fieldName] ? props[form.fieldName] : ""}
                  className="form-control"
                />
                <label>{form.labelName}{form.isMandatory && <span className="text-danger">*</span>}</label>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName],
                      `required|email`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
          );
        }
      case DynamicFieldsType.DATA_TYPE_SEARCH:
        if (accessPermission > 0 && form.activeStatus) {
          let selectedDropDown = '';
          return (
            <div key={index} className="">
              <div className="">
                <div className="form-group  labelup custom mb-0">
                  <label>{form.labelName}{!selectedDropDown ? form.isMandatory && <span className="text-danger">*</span> : ''}</label>
                  <AsyncSelect
                    noOptionsMessage={({ inputValue }) => inputValue ? 'No results.' : "Start typing to search..."}
                    placeholder={form.labelName}
                    loadOptions={props.loadOptions}
                    value={props[form.fieldName] ? props[form.fieldName] : form.labelName}
                    onChange={(event) => props.onLocationChangekHandler(event, form.fieldName)}
                  />
                </div>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName] ?.value,
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
          );
        }
      case DynamicFieldsType.DATA_TYPE_SYSTEM_LIST:
        if (accessPermission > 0 && form.activeStatus) {
          let selectedDropDown = '';
          if (form.columnName === "PAYMENT_TYPE") {
            return (
              <div key={index} className="">
                <div className="form-group custom labelup">
                  <label>{form.labelName}</label>
                  <div className="dropdown" key={uuid.v4()}>
                    <button
                      disabled={accessPermission === 1 || (props.pageName == 'itemisation' && (form.columnName == "CURRENCY" || form.columnName == "PAYMENT_TYPE"))}
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      {form.paymentDetails && form.paymentDetails.forEach(element => {
                        if (props[formFieldName] === element.id) {
                          selectedDropDown = element.description;

                        }
                      })}
                      {selectedDropDown ? selectedDropDown : form.labelName}{!selectedDropDown ? form.isMandatory && <span className="text-danger">*</span> : ''}
                    </button>
                    <div className="dropdown-menu">
                      {form.paymentDetails && form.paymentDetails.map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onClickHandler(form.fieldName, form.id, opt.id, form.fieldLevel)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.description}
                            </button>
                          );
                        }
                      )}
                    </div>
                    {form.isMandatory ? (
                      <div className="help-block">
                        {props.validator.message(
                          form.labelName,
                          props[form.fieldName],
                          "required"
                        )}
                      </div>
                    ) : (
                        ""
                      )}
                  </div>
                </div>
              </div>
            );
          } else if (form.columnName === "CTRY_CODE") {
            return (
              <div key={index} className="">
                <div className="form-group custom">
                  <div className="dropdown" key={uuid.v4()}>
                    <button

                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      {form.countries && form.countries.forEach(element => {
                        if (props[formFieldName] === element.id) {
                          selectedDropDown = element.name;

                        }
                      })}
                      {selectedDropDown ? selectedDropDown : form.labelName}{!selectedDropDown ? form.isMandatory && <span className="text-danger">*</span> : ''}
                    </button>
                    <div className="dropdown-menu">
                      {form.countries && form.countries.map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onClickHandler(form.fieldName, form.id, opt.id, form.fieldLevel)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.name}
                            </button>
                          );
                        }
                      )}
                    </div>
                    {form.isMandatory ? (
                      <div className="help-block">
                        {props.validator.message(
                          form.labelName,
                          props[form.fieldName],
                          "required"
                        )}
                      </div>
                    ) : (
                        ""
                      )}
                  </div>
                </div>
              </div>
            );
          } else if (form.columnName === "LEDGER_KEY") {
            return (
              <div key={index} className="">
                <div className="form-group custom">
                  <div className="dropdown" key={uuid.v4()}>
                    <button

                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      {form.accountingLedger && form.accountingLedger.forEach(element => {
                        if (props[formFieldName] === element.id) {
                          selectedDropDown = element.ledgerName;

                        }
                      })}
                      {selectedDropDown ? selectedDropDown : form.labelName}{!selectedDropDown ? form.isMandatory && <span className="text-danger">*</span> : ''}
                    </button>
                    <div className="dropdown-menu">
                      {form.accountingLedger && form.accountingLedger.map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onClickHandler(form.fieldName, form.id, opt.id, form.fieldLevel)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.ledgerName}
                            </button>
                          );
                        }
                      )}
                    </div>
                    {form.isMandatory ? (
                      <div className="help-block">
                        {props.validator.message(
                          form.labelName,
                          props[form.fieldName],
                          "required"
                        )}
                      </div>
                    ) : (
                        ""
                      )}
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={index} className="">
                <div className="form-group custom labelup">
                  <label>{form.labelName}</label>
                  <div className="dropdown" key={uuid.v4()}>
                    <button
                      disabled={accessPermission === 1 || (props.pageName == 'itemisation' && (form.columnName == "CURRENCY" || form.columnName == "PAYMENT_TYPE"))}
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      {form.connectedListData && form.connectedListData.listManagementData.forEach(element => {
                        if (props[formFieldName] === element.id) {
                          selectedDropDown = element.itemName;

                        }
                      })}
                      {selectedDropDown ? selectedDropDown : form.labelName}{!selectedDropDown ? form.isMandatory && <span className="text-danger">*</span> : ''}
                    </button>
                    <div className="dropdown-menu">
                      {form.connectedListData && form.connectedListData.listManagementData.map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onClickHandler(form.fieldName, form.id, opt.id, form.fieldLevel)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.itemName}
                            </button>
                          );
                        }
                      )}
                    </div>
                    {form.isMandatory ? (
                      <div className="help-block">
                        {props.validator.message(
                          form.labelName,
                          props[form.fieldName],
                          "required"
                        )}
                      </div>
                    ) : (
                        ""
                      )}
                  </div>
                </div>
              </div>
            );
          }

        }
      default:
        return null;
    }
  });
};

export default expenseCreateForm;
