import React, { Component } from "react";
import CarConfigStep2Page from "./config-step2-html";
import SimpleReactValidator from 'simple-react-validator';
import { carConfigService } from "src/_services/carConfigService";
import { toast } from "react-toastify";

class configStep2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form2Data: {},
            carId: ''
        };
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    formValidator2 = new SimpleReactValidator();
    componentDidMount = () => {
        let carId = JSON.parse(localStorage.getItem("carId"));
        if (carId!=='new') {
            this.setState({ carId });
            this.updateCarConfiguration(carId);
        }
        else{
            toast.success("Please complete the step 1");
            this.props.history.push(`/administration/expense/car-config/config-step1/${carId}`);
        }
    }
    updateCarConfiguration = async (carId) => {
        let res = await carConfigService.updateCarConfiguration(carId);
        if (res) {
            this.setState({ form2Data: res.result });
        }
    }
    onValueChangeHandler = (e) => {
        this.setState({
            form2Data: {
                ...this.state.form2Data,
                [e.target.name]: e.target.value
            }

        });
    }
    onSubmitForm2Handler = async () => {
        let payLoad;
        payLoad = {
            id: this.state.form2Data.id,
            distanceLimits: {
                distanceLimit1: this.state.form2Data.distanceLimit1,
                distanceLimit2: this.state.form2Data.distanceLimit2,
                distanceLimit3: this.state.form2Data.distanceLimit3
            }
        }
        if (this.formValidator2.allValid()) {
            let res = await carConfigService.carConfigform(payLoad);
            if (res) {
                toast.success(res.message);
                this.props.history.push('/administration/expense/car-config/config-step3/');
            }
        }
        else {
            this.formValidator2.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        return (
            <div>
                <CarConfigStep2Page
                    carId={this.state.carId}
                    form2Data={this.state.form2Data}
                    formValidator2={this.formValidator2}
                    onValueChangeHandler={this.onValueChangeHandler}
                    onSubmitForm2Handler={this.onSubmitForm2Handler}
                />
            </div>
        );
    }
}
export default configStep2;