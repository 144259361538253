import Select from "react-select";
import React, { PureComponent as Component } from "react";
import PropTypes from "prop-types";

export class ExpenseTypePolicy extends Component {
  static propTypes = {
    expenseEntriesOptions: PropTypes.array,
    itemizedEntriesOptions: PropTypes.array,
    attendeeEntriesOptions: PropTypes.array,
    expenseTypePolicy: PropTypes.object,
    policy: PropTypes.object,
    edit: PropTypes.bool,
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    // * compare and assign the options from the policy
    let updatedExpenseEntryValue = this.props.expenseEntriesOptions.find(
      option => option.value === this.props.expenseTypePolicy?.expenseEntryFormId
    );
    let updatedItemizedEntryValue = this.props.itemizedEntriesOptions.find(
      option => option.value === this.props.expenseTypePolicy?.itemizedEntryFormId
    );
    let updatedAttendeeValue = this.props.attendeeEntriesOptions.find(
      option => option.value === this.props.expenseTypePolicy?.attendeeFormId
    );
    this.state = {
      itemEntryValue: updatedItemizedEntryValue || "",
      expenseEntryValue: updatedExpenseEntryValue || "",
      attendeeValue : updatedAttendeeValue || "",
      assign: this.props.expenseTypePolicy?.assign || false
    }; 
  }

  handleItemEntryHandler = selectedOption => {
    this.setState({
      itemEntryValue: selectedOption
    });
  };

  handleExpenseEntryHandler = selectedOption => {
    this.setState({
      expenseEntryValue: selectedOption
    });
  };

  handleAttendeeHandler = selectedOption => {
    this.setState({
      attendeeValue : selectedOption
    });
  }

  handleAssignChange = ev => {
    this.setState({
      assign: ev.target.checked
    },() =>{
      this.updateData()
    }); 
  };

  // * check if the data has changed from the previous render
  componentDidUpdate(prevProps, prevState) {
    // const data = {
    //   policyId: this.props.policy.id,
    //   expenseEntryFormId: this.state.expenseEntryValue.value,
    //   itemizedEntryFormId: this.state.itemEntryValue.value,
    //   attendeeFormId: this.state.attendeeValue.value,
    //   useVerdorList: "string",
    //   assign: this.state.assign,
    // };

    // console.log("from data ", data);

    // // if any of the value is changed in edit mode, then onChange is called
    // // if (
    // //   (prevState.itemEntryValue !== this.state.itemEntryValue ||
    // //     prevState.expenseEntryValue !== this.state.expenseEntryValue ||
    // //     prevProps.attendeeValue !== this.state.attendeeValue ||
    // //     prevState.assign !== this.state.assign) &&
    // //   this.props.edit
    // // ) {
    // //   this.props.onChange(data);
    // // } else if (prevState.assign !== this.state.assign && !this.props.edit) {
    // //   this.props.onChange(data);
    // // }
    // // this.props.onChange(data);?

  }
  updateData = () => {
    const data = {
      policyId: this.props.policy.id,
      expenseEntryFormId: this.state.expenseEntryValue.value,
      itemizedEntryFormId: this.state.itemEntryValue.value,
      attendeeFormId: this.state.attendeeValue.value,
      useVerdorList: "string",
      assign: this.state.assign,
    };
    this.props.onChange(data);
  }
 
  render() {
    const {
      expenseEntriesOptions,
      itemizedEntriesOptions,
      attendeeEntriesOptions,
      policy,
      onChange,
      name,
      edit, 
      ...props
    } = this.props;

    const { itemEntryValue, expenseEntryValue, attendeeValue, assign } = this.state;

    let updatedExpenseEntryValue = expenseEntriesOptions.find(
      option => option.value === expenseEntryValue.value
    );
    let updatedItemizedEntryValue = itemizedEntriesOptions.find(
      option => option.value === itemEntryValue.value
    );
    let updatedAttendeeValue = attendeeEntriesOptions.find(
      option => option.value === attendeeValue.value
    ); 
    return (
      <tr {...props}>
        <td>{name}</td>
        <td>
          <div className="form-group mb-0">
            {expenseEntriesOptions && (
              <Select
                defaultValue={
                  expenseEntryValue || {
                    value: "default",
                    label: "default"
                  }
                }
                options={expenseEntriesOptions}
                value={expenseEntryValue}
                onChange={this.handleExpenseEntryHandler}
              />
            )}
          </div>
        </td>
        <td>
          <div className="form-group mb-0">
            {itemizedEntriesOptions && (
              <Select
                defaultValue={
                  itemEntryValue || {
                    value: "default",
                    label: "default"
                  }
                }
                options={itemizedEntriesOptions}
                value={itemEntryValue}
                onChange={this.handleItemEntryHandler}
              />
            )}
          </div>
        </td>
        <td>
          <div className="form-group mb-0">
            {attendeeEntriesOptions && (
              <Select
                defaultValue={
                  attendeeValue || {
                    value: "default",
                    label: "default"
                  }
                }
                options={attendeeEntriesOptions}
                value={attendeeValue}
                onChange={this.handleAttendeeHandler}
              />
            )}
          </div>
        </td>
        <td>
        {(!this.props.hideSetting) ?
           <button onClick={e => this.updateData()} className="btn btn-sm"> Update </button> 
           :
           <button onClick={e => this.updateData()} className="btn btn-sm"> Create </button> 
        }
        </td>
        <td>
          {(!this.props.hideSetting) &&
          <label className="switch">
            <input
              type="checkbox"
              checked={assign}
              onChange={this.handleAssignChange}
            />
            <span className="slider round" />
          </label>}
        </td>
      </tr>
    );
  }
}
