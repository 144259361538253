import React, { Component } from 'react';
import PolicyViolationPage  from './policy-violation-html';
import { toast } from 'react-toastify';
import { alertConstants } from '../../../../../../_constants/alert.constants';
import { policyViolationService } from '../../../../../../_services/policy-violation.services';
import { travelRuleClassService } from '../../../../../../_services/travel-rule-class.service';

class PolicyViolation extends Component {

  state = {
    allRules: [],
    selectedRule: '',
    selectedRuleObject:{},
    companyId: localStorage['440a28'],
    isLoading: true,
    // companyId: "5c2c891c30566e1f00b24552",

    policyViolationsByRule: [],
    activePage: 1,
    itemsCountPerPage: 10,
    totalItemsCount: 0,
    searchInput: '',
    selectedPolicies: []

  }
  componentDidMount(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.getAllRules();


  }

  // On page load get all the rule names

  getAllRules = async () => {

    try{
      let res = await policyViolationService.getAllRuleClass(this.state.companyId, this.state.itemsCountPerPage, this.state.activePage);
      if(res != undefined && res.result.length){
        this.setState({
          allRules: res.result,
          isLoading: false
        })
      }
    }catch(err){
      this.setState({
        isLoading: false
      })
      toast.warn(alertConstants.ERROR);
    }


  }

  // Pagination - change page on click on pagination controls
  handlePageChange = (pageNumber) => {
    // console.log(pageNumber);
    this.setState({
      activePage: pageNumber
    }, () => {

      this.handleRuleClassSelection(this.state.selectedRuleObject);
    })

  }
  // Pagination -  Change limit of the number of data shown
  handleLimitChange = (limit) => {
    // console.log(limit);
    this.setState({
      itemsCountPerPage: limit
    }, () => {
      this.handleRuleClassSelection(this.state.selectedRuleObject);
    })

  }

  // Get all POlicy Violations once a Rule is selected

  handleRuleClassSelection = (rule) => {
    this.setState({
      isLoading: true
    })

    // console.log(rule);
    // console.log(this.state.itemsCountPerPage);

    // if there is no text in the search box get all the policy violations of the selected rule
    if(!this.state.searchInput){
    policyViolationService.getPolicyViolationByRuleClass(this.state.companyId,rule.id, this.state.itemsCountPerPage, this.state.activePage)
      .then(res => {
        this.setState({
          selectedRule: rule.className,

        })
        if(res && res.result.length){

          this.setState({
            isLoading: false,
            policyViolationsByRule: res.result,
            totalItemsCount: res.count,
          selectedPolicies: [], //if a different ruleclass loaded, then selected policies get deselected

        }, () => {
          this.setState({
            selectedRuleObject: rule
          })
        })
      }else{
        this.setState({
          policyViolationsByRule: [],
          isLoading: false
        })
      }
      },  error => {
        if (error.statusCode === 422) {
            let errors = error.message
            Object.keys(errors).map(key => {
                return (
                    toast.error(errors[key])
                );
            })
        } else {
            toast.error(error.message)
        }

      })
    }else {
      // if there is text in the search input, get all the policy violatons matching the search
      // if search input has text, we need to do pagination of search results
      policyViolationService.searchPolicyViolation(this.state.companyId, this.state.searchInput, this.state.itemsCountPerPage, this.state.activePage)
        .then(res => {
          // console.log(res);

          if(res != undefined && res.result){
            this.setState({
              policyViolationsByRule: res.result,
              totalItemsCount: res.count,
              isLoading: false
            })
          }else{
            this.setState({
              policyViolationsByRule: [],
              isLoading: false
            })
          }
        })

    }


  }

  // Toggle the status of any particular policy violation

  toggleViolationStatus = (policyViolation) => {

    let newStatus = !policyViolation.activeStatus;
    let payload = {
      id: policyViolation.id,
      activeStatus: newStatus
    }
    policyViolationService.activeInactivePolicyViolation(this.state.companyId, payload)
      .then(success => {
        // if the status change was successful, load the same rule class with the updated policy violations
        toast.success(success.message);
        policyViolationService.getPolicyViolationByRuleClass(this.state.companyId, policyViolation.ruleClassId, this.state.itemsCountPerPage, this.state.activePage)
          .then(res => {
            if(res && res.result){

              this.setState({
                policyViolationsByRule: res.result
              });
            }
          }, error => {
            if (error.statusCode === 422) {
              let errors = error.message
              Object.keys(errors).map(key => {
                  return (
                      toast.error(errors[key])
                  );
              })
          } else {
              toast.error(error.message)
          }
          })

      }, error => {
        if (error.statusCode === 422) {
          let errors = error.message
          Object.keys(errors).map(key => {
              return (
                  toast.error(errors[key])
              );
          })
      } else {
          toast.error(error.message)
      }

      })
  }

  onSearchHandler = (e) => {
    let searchInput = e.target.value;
    this.setState({
      searchInput: searchInput
    }, () => {
// if the searchinput is blank, reset itemsCountPerPage and get all rollnnames again and set policyviolations to empty array
    if(!searchInput.length){
      this.setState({
        itemsCountPerPage: 10
      }, () => {
        this.getAllRules();
        this.setState({policyViolationsByRule:[]})

      })
    }else{
      // if the search box has input then find the searched items, if they exist set policyviolations or set it to an empty array
      policyViolationService.searchPolicyViolation(this.state.companyId, this.state.searchInput, this.state.itemsCountPerPage, this.state.activePage)
        .then(res => {
          // console.log(res);
          if(res.result){


            this.setState({
              policyViolationsByRule: res.result,
              totalItemsCount: res.count
            })
          }else {
            this.setState({
              policyViolationsByRule: []
            })
          }
        })
    }

  })
  }

  // For selection of policies
  policySelectHandler = (policy) => {
    let selectedPolicies = [...this.state.selectedPolicies];
    let selectedIndex = selectedPolicies.indexOf(policy);
    if(selectedIndex === -1){
      selectedPolicies.push(policy)
    }else{
      selectedPolicies.splice(selectedIndex,1)
    }
    this.setState({
      selectedPolicies: selectedPolicies
    })

  }
  deletePolicyViolationHandler = (e) => {
    e.preventDefault();
    let idsToBeDeleted = this.state.selectedPolicies.map(policy => policy.id);
    // we will direct them to the rule class policy violations of the first policy violation selected, thats why we need the ruleclass
    let ruleClass = this.state.selectedPolicies[0].ruleClass;
    let payload = {
      id: idsToBeDeleted
    };


    policyViolationService.deletePolicyViolation(this.state.companyId, payload)
      .then(success => {
        // redirect the rule class and its policy violations
        policyViolationService.getPolicyViolationByRuleClass(this.state.companyId, ruleClass, this.state.itemsCountPerPage, this.state.activePage)
        .then(res => {
          this.setState({
            policyViolationsByRule: res.result,
            selectedPolicies: []
          });
          toast.success(alertConstants.DELETED_SUCCESSFULLY)
        }, error => {
          if (error.statusCode === 422) {
            let errors = error.message
            Object.keys(errors).map(key => {
                return (
                    toast.error(errors[key])
                );
            })
        } else {
            toast.error(error.message)
        }
        })
      }, error => {
        console.log(error)
      })
  }

  render() {
    return(
      <div>
        <PolicyViolationPage
          state={this.state}
          handleLimitChange={this.handleLimitChange}
          handlePageChange={this.handlePageChange}
          handleRuleClassSelection={this.handleRuleClassSelection}
          toggleViolationStatus={this.toggleViolationStatus}
          onSearchHandler={this.onSearchHandler}
          policySelectHandler={this.policySelectHandler}
          deletePolicyViolationHandler = {this.deletePolicyViolationHandler}

        />
      </div>
    )
  }
}
export default PolicyViolation;