import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import {
    AddEditTemplatePage, TravelPage
} from './index';
import PageNotFound from '../../../../page-not-found/page-not-found-html'
class RemindMainPage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/administration/company/email/travel') {
            this.props.history.push('/administration/company/email/travel/list')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/administration/company/email/travel') {
            this.props.history.push('/administration/company/email/travel/list')
        }
    }
    render() {
        return (
            <div className="RemindMainPage">
                <Switch>
                    <Route exact path="/administration/company/email/travel/list" component={TravelPage} />
                    <Route exact path="/administration/company/email/travel/:id" component={TravelPage} />
                    <Route exact={true} path="/administration/company/email/add-edit-template/list" component={AddEditTemplatePage} />
                    <Route path="**" component={PageNotFound} />
                </Switch>
            </div>
        )
    }
}
export default RemindMainPage;