import React from "react";

const receiptFilter = props => {
  return (
    <form onSubmit={props.onSubmitHandler}>
      <div className="row">
        <div className="col-md-9">
          <div className="btn-group-margin">
            <button
              type="button"
              className="btn"
              data-toggle="modal"
              data-target="#uploadReceiptsModal"
            >
              Upload
            </button>
            <button
              disabled={props.deleteReceiptSelected !== '' && props.deleteReceiptSelected !== undefined ? false : true}
              onClick={props.onDeleteReciptHandler}
              className="btn"
            >
              Remove
                </button>
            {/* <div className="dropdown">
              <button
                type="button"
                className="btn dropdown-toggle"
                data-toggle="dropdown"
              >
                {props.selected}
              </button>
              <div className="dropdown-menu">
                <button
                  onClick={e => props.onSelectHandler("This Month")}
                  className="dropdown-item"
                >
                  This Month
                </button>
                <button
                  onClick={e => props.onSelectHandler("This Week")}
                  className="dropdown-item"
                >
                  This Week
                </button>
                <button
                  onClick={e => props.onSelectHandler("Today")}
                  className="dropdown-item"
                >
                  Today
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-md-3 text-right">

        </div>
        {/* <div className="col-md-2">
          <div className="search-custom">
            <input
              onChange={props.onChangeHandler}
              type="text"
              name="searchText"
              className="form-control"
              placeholder="Search"
            />
            <span onClick={props.onSearchHandler}>
              <i className="material-icons">search</i>
            </span>
          </div>
        </div> */}
      </div>
    </form>
  );
};

export default receiptFilter;
