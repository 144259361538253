import React from 'react';
import Menu from '../menu';
import { Select, Input } from '../../../../../common/_forms';
import {Link} from 'react-router-dom';
import ToggleSlider from '../../../../../common/ToggleSlider/ToggleSlider';
import Category from '../travel-policy/components/Category';

const EditTravelPolicyPage = (props) => {

  const {
  editPolicyData,
  approvalType,
  type,
  activeStatus,
  allConditions,
  anyCondition,
  conditions,
  templateCategory

  } = props.state;

  const {
    approvalTypeHandler,
    typeHandler,
    toggleConditionHandler,
    saveEditedRuleHandler,
    allCheckedConditionsHandler,
    anyCheckedConditionsHandler,
    renderedFieldTextHandler,
    renderedFieldSelectHandler
  } = props;

  let approvalTypes = [
    {name: 'Request Approval'},
    {name: 'Hide'},
    {name: 'Allow'}
  ];

  let types = [
    {name: 'G - General'},
    {name: 'A - Air'},
    {name: 'T - Train'},
    {name: 'C - Car'},
    {name: 'H - Hotel'}
    ];
    let categories = ['Travel Itinerary', 'Flight', 'Car', 'Train', 'Hotel'];
  return (
    <div>
      <div className="admin-tabs">
        {/* <Menu /> */}
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">E</div>
            <div className="right">
              <h6 className="heading">Edit - {editPolicyData.ruleName}
              </h6>
              <p className="text">Modiy travel policy rule.</p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="tabs-container">
            <div className="left">
              <div className="heading">
                <h6 className="sub-heading">Policies</h6>
              </div>
              <div className="scroll">
                <ul> {
                  categories.map((category,idx) => {
                    return(
                      <Link to={`/administration/travel/travel-policy/${category}`} key={idx}>
                        <li className={templateCategory == category ? 'selected' : ''} ><button className="btn btn-nothing">{category}</button></li>
                      </Link>

                    )
                  })
                }
                  {/* <li><button className="btn btn-nothing">Flight</button></li>
                  <li><button className="btn btn-nothing">Car</button></li>
                  <li><button className="btn btn-nothing">Train</button></li>
                  <li><button className="btn btn-nothing">Hotel</button></li> */}
                </ul>
              </div>
            </div>
            <div className="right">
              <div className="heading">
              <ToggleSlider toggleSliderHandler={e => toggleConditionHandler(editPolicyData.activeStatus, editPolicyData.id)} value={activeStatus} checked={activeStatus}  />
                {/* <label className="switch"><input type="checkbox" value={editPolicyData.activeStatus} checked={editPolicyData.activeStatus} /><span className="slider round"></span></label> */}
                <div>
                  <h6 className="sub-heading">Edit - {editPolicyData.ruleName}</h6>
                  <p className="mb-0">Lorem Ipsum is simply dummy text.</p>
                </div>
              </div>
              <div className="scroll">
                <div className="scroll-content">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group custom labelup">
                        <label>Approval Type</label>
                        <Select
                          placeholder={approvalType ? approvalType.name : "Select Approval Type"}
                          keyName='name'
                          options={approvalTypes}
                          controlFunc={approvalTypeHandler}
                        />


                        {/* <div className="dropdown">
                          <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                            Manager
                            </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">Manager</button>
                            <button className="dropdown-item">Admin</button>
                            <button className="dropdown-item">User</button>
                          </div>
                        </div> */}
                      </div>
                      {/* <div className="form-group">
                        <label className="switch"><input type="checkbox" /><span className="slider round"></span>
                        </label>
                        <span>Consider Exception Flow</span>
                      </div> */}
                    </div>
                    {/* Start of Checkboxes */}
                    <div className="col-md-6">
                      <label className="checkbox-container"> All of the checked conditions are true
                        <input type="checkbox" onChange={e => allCheckedConditionsHandler(allConditions)} checked={allConditions} value={allConditions} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="checkbox-container"> Any of the checked conditions are true
                        <input type="checkbox" checked={anyCondition} value={anyCondition} onChange={e => anyCheckedConditionsHandler(anyCondition)} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    {
                      conditions && conditions.map((condition,idx) => {
                        let renderedField = '';
                        switch(condition.type){
                          case 'text':
                            renderedField = <Input
                              label={ condition.name}
                              value={condition.value}
                              name={condition.name}
                              type="text"
                              controlFunc={e => renderedFieldTextHandler(e, condition.id)}
                            />
                            break;
                          case 'select':
                            renderedField = (
                              <div className="form-group custom labelup">

                              <label>{condition.name}</label>
                              <Select
                              placeholder={condition.value}
                              keyName= 'listName'
                              options={condition.listOfValues}
                              controlFunc={e => renderedFieldSelectHandler(e, condition)}
                              />
                              </div>

                              )

                            // console.log(condition);
                            // renderedField = <Select
                            //   options={}
                            //   placeholder={condition.name}
                            // />



                        }
                        return (
                            <div key={idx} className="col-md-12">
                              <div className="form-group custom">
                                {renderedField}

                              </div>
                            </div>
                        )
                      })
                    }



                    {/* End Of Checkboxes */}
                    {/* <div className="col-md-7">
                      <div className="form-group custom labelup">
                        <label>Itinerary Booked</label>
                        <div className="dropdown">
                          <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                            Less Than
                            </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">Manager</button>
                            <button className="dropdown-item">Admin</button>
                            <button className="dropdown-item">User</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group custom">
                        <input className="form-control" placeholder="&nbsp;" type="text" name="" />
                        <label>Days in Advance</label>
                      </div>
                    </div> */}
                    {/* <div className="col-12">
                      <div className="form-group custom">
                        <input className="form-control" placeholder="&nbsp;" type="text" name="" />
                        <label>Message When Matched</label>
                      </div>
                    </div> */}
                    <div className="col-12">
                      <div className="form-group custom labelup">
                        <label>Type</label>
                        <Select
                          placeholder={type ? type.name : "Select Approval Type"}
                          keyName='name'
                          options={types}
                          controlFunc={typeHandler}
                        />
                        {/* <div className="dropdown">
                          <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                            G - General
                            </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">Manager</button>
                            <button className="dropdown-item">Admin</button>
                            <button className="dropdown-item">User</button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="btn-group-margin">
                        <button className="btn btn-border-primary sm-padding" onClick={saveEditedRuleHandler} >Save</button>
                        <Link to="/administration/travel/travel-policy" className="btn btn-border-basic sm-padding">cancel</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EditTravelPolicyPage;