import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { bookingServices } from "src/_services/booking.service";
import FlightCartDetail from "../checkout/item/flightDetail";
import { FlightCartItem } from "../checkout/item/flightItem";
import HotelCartDetail from "../checkout/item/hotelDetail";
import FlightComboCartItem from "../checkout/item/comboFlightItem";
import { HotelCartItem } from "../checkout/item/hotelItem";
import FlightCartComboDetail from "../checkout/item/comboFlightDetail";
import { userService } from "src/_services";
import { TextArea } from "src/_components/common/_forms";
import $ from "jquery";
import { toast } from "react-toastify";
import { alertConstants } from "src/_constants";
import NumberFormat from "react-number-format";
import Loader from "src/_components/common/Loader";
import ChatApp from "../../chat-app/chatPage";
import moment from "moment";
import { expenseReport } from "src/_services/expenseReport";
import { getFullUserName } from "src/utils/util";
import { getValue, setValue } from "src/_components/common/lodsh";
import { getConnectedList } from "src/_services";
import Moment from 'react-moment';
import CabCartItem from "../checkout/item/cabItem";
import CabCartDetail from "../checkout/item/cabCartDetails";
import { TrainCartItem } from "../checkout/item/trainItem";
import TrainCartDetail from "../checkout/item/trainDetail";


class TripTravelRequestPage extends Component {
  constructor(props) {
    super(props);
    sessionStorage.removeItem("TRIPID");
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      tripDetails: [],
      currentItem: [],
      history: [],
      type: "",
      selectedItem: "",
      flightDetailView: [],
      approvals: [],
      hotelDetailView: "",
      CabDetailView: [],
      trainDetailView: [],
      choosedStatus: "",
      choosedName: "Heading",
      userList: [],
      choosedUser: [],
      overAllPerDiem: 0,
      comments: "",
      showInvoiceTicket: false,
      eticket: false,
      invoice: false,
      invoiceInfo: [],
      companyId: localStorage["440a28"],
      loading: true,
      approveListId: props.match.params.id,
      receiverDetails: {},
      enableChat: false,
      expenseTripEnableStatus: true,
      expenseTripDisableStatus: true
    };
  }
  componentDidMount = async () => {
    await this.getDetails();
    await this.enableDisableExpenseTrip();
  };
  enableDisableExpenseTrip = async () => {
    let companyId = this.state.companyId;
    let enableExpenseTripInTravel = await expenseReport.enableExpenseTripInTravel(
      companyId,
      this.state.tripId
    );
    let disableExpenseTripInTravel = await expenseReport.disableExpenseTripInTravel(
      companyId,
      this.state.tripId
    );
    this.setState({
      expenseTripDisableStatus: disableExpenseTripInTravel.status,
      expenseTripEnableStatus: enableExpenseTripInTravel.status
    });
  };
  getDetails = async () => {
    var resp = await bookingServices.getWaitingForUserById(
      this.state.approveListId
    );
    if (resp) {
      resp.result && resp.result.TRIP_DATA
        ? this.setState({
          tripDetails: resp.result.TRIP_DATA,
          loading: false
        })
        : this.setState({
          tripDetails: [],
          loading: false
        });
      if (resp.result.info.steps) {
        var newArrayDataOfOjbect = Object.values(resp.result.info.steps);
        this.setState({
          currentItem: resp.result.current,
          approvals: newArrayDataOfOjbect
        });
      }
      await this.setState({
        currentItem: resp.result.current,
        tripId: resp?.result?.current?.TRIP_ID
      });
      if (this.state.tripDetails) {
        const { tripDetails } = this.state;
        if (
          tripDetails &&
          tripDetails.itenary &&
          tripDetails.itenary.data &&
          tripDetails.itenary.data.flightData &&
          tripDetails.itenary.data.flightData.length !== 0
        ) {
          this.setState({
            expenseTheTripDate: tripDetails.itenary.TRAVEL_DATE.TRAVEL_TO
          });
          if (tripDetails.itenary.data.flightData[0].fare) {
          } else {
            this.chooseFlight(
              tripDetails.itenary.data.flightData[0],
              "flight",
              `flight${0}`
            );
          }
        } else if (getValue(tripDetails, "itenary.data.hotelData", []).length !== 0) {
          this.setState({
            expenseTheTripDate: tripDetails.itenary.TRAVEL_DATE.TRAVEL_TO
          });
          this.chooseHotel(
            tripDetails.itenary.data.hotelData[0],
            "hotel",
            `hotel${0}`
          );
        }
        else if (getValue(tripDetails, "itenary.data.carData", []).length !== 0) {
          this.setState({
            expenseTheTripDate: getValue(tripDetails, "itenary.TRAVEL_DATE.TRAVEL_TO")
          });
          this.chooseCab(
            getValue(tripDetails, "itenary.data.carData[0]", []),
            "cab",
            `cab${0}`
          );
        } else if (getValue(tripDetails, "itenary.data.trainData", []).length !== 0) {
          this.setState({
            expenseTheTripDate: getValue(tripDetails, "itenary.TRAVEL_DATE.TRAVEL_TO")
          });
          this.chooseTrain(
            getValue(tripDetails, "itenary.data.trainData[0]", []),
            "train",
            `train${0}`
          );
        }
      }

      if (resp.result.TRIP_DATA.Expense && resp.result.TRIP_DATA.Expense[0]) {
        var item = 0;
        (getValue(resp, "result.TRIP_DATA.Expense[0].perDiemDetails", [])).forEach(element => {
          item += element.actualCurrencyAmount;
        });
        this.setState({
          overAllPerDiem: item
        });
      }
      if (!this.state.currentItem.currentOrderNo) {
        this.setState({
          showInvoiceTicket: true
        });
      }
    }
    this.setState({ loading: false });
  };
  getHotelTickets = async (trip, type, hotelId) => {
    this.setState({ eticketInfo: [], eticket: false });
    var resp = await bookingServices.getTicketHotelInfo(trip, type, hotelId);
    if (resp) {
      this.setState({
        eticketInfo: resp.result
      });
    }
  };
  getHotelInvoice = async (trip, type, hotelId) => {
    this.setState({ invoiceInfo: [], invoice: false });
    var resp = await bookingServices.getInvoiceHotelInfo(trip, type, hotelId);
    if (resp) {
      this.setState({
        invoiceInfo: resp.result
      });
    }
  };
  getFlightTickets = async (trip, type, unique_id) => {
    this.setState({ eticketInfo: [], eticket: false });
    var resp = await bookingServices.getTicketFlightInfo(trip, type, unique_id);
    if (resp) {
      this.setState({
        eticketInfo: resp.result
      });
    }
  };
  getFlightInvoice = async (trip, type, unique_id) => {
    this.setState({ invoiceInfo: [], invoice: false });
    var resp = await bookingServices.getInvoiceFlightInfo(
      trip,
      type,
      unique_id
    );
    if (resp) {
      this.setState({
        invoiceInfo: resp.result
      });
    }
  };
  updateStatus = async (data, currentItem) => {
    this.setState({
      userList: [],
      choosedUser: "",
      comments: "",
      choosedStatus: data.key,
      choosedName: data.button,
      currentItem: currentItem
    });
  };
  chooseUser = data => {
    this.setState({ userList: [], choosedUser: data });
  };
  getSearchUser = async e => {
    if (e.target.value) {
      var resp = await userService.getUserList(
        e.target.value,
        this.state.companyId
      );
      if (resp) {
        this.setState({
          userList: resp.result
        });
      }
    } else {
      this.setState({
        userList: []
      });
    }
  };
  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " m " : " m ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  chooseFlight = (e, type, key) => {
    this.setState({
      flightDetailView: e,
      type: type
    });
    if (this.state.selectedItem === key) {
      this.setState({
        selectedItem: "",
        flightDetailView: "",
        type: ""
      });
    } else {
      this.setState({
        flightDetailView: e,
        selectedItem: key
      });
    }

    this.getFlightInvoice(
      this.state.currentItem.TRIP_ID,
      "FLIGHT",
      e.fareContent.UNIQUE_ID=e.fareContent.UNIQUE_ID?e.fareContent.UNIQUE_ID:e.fareContent[0].UNIQUE_ID
    );
    this.getFlightTickets(
      this.state.currentItem.TRIP_ID,
      "FLIGHT",
      e.fareContent.UNIQUE_ID
    );
  };
  chooseHotel = (e, type, key) => {
    this.setState({
      hotelDetailView: e,
      type: type
    });
    if (this.state.selectedItem === key) {
      this.setState({
        selectedItem: "",
        hotelDetailView: "",
        type: ""
      });
    } else {
      this.setState({
        selectedItem: key
      });
    }
    this.getHotelInvoice(this.state.currentItem.TRIP_ID, "HOTEL", e.hotel_id);
    this.getHotelTickets(this.state.currentItem.TRIP_ID, "HOTEL", e.hotel_id);
  };
  chooseCab = (e, type, key) => {
    this.setState({
      CabDetailView: e,
      type: type
    });
    if (this.state.selectedItem === key) {
      this.setState({
        selectedItem: "",
        CabDetailView: "",
        type: ""
      });
    } else {
      this.setState({
        selectedItem: key
      });
    }
  }
  chooseTrain = (e, type, key) => {
    this.setState({
      trainDetailView: e,
      type: type
    });
    if (this.state.selectedItem === key) {
      this.setState({
        selectedItem: "",
        trainDetailView: "",
        type: ""
      });
    } else {
      this.setState({
        selectedItem: key
      });
    }
  }
  reset = () => {
    this.setState({
      choosedStatus: "",
      userList: [],
      choosedUser: [],
      comments: ""
    });
  };
  handleChange = e => {
    this.setState({
      comments: e.target.value
    });
  };
  submitReCall = async data => {
    var resp = await bookingServices.reCallTrip(
      data.TRIP_ID,
      this.state.approveListId
    );
    if (resp) {
      sessionStorage.TRIPID = data.TRIP_ID;
      sessionStorage.removeItem("32AWE");
      this.props.history.push("/booking/checkout/itinerary");
    }
  };
  submitRequest = async data => {
    var req = [];
    if (data === "APPROVE") {
      req = {
        [data]: {}
      };
    } else if (data === "ADD_NEW_STEP") {
      req = {
        [data]: {
          userId: this.state.choosedUser.id,
          comments: this.state.comments
        }
      };
    } else if (data === "REJECT") {
      req = {
        [data]: {
          comments: this.state.comments
        }
      };
    } else if (data === "SEND_BACK_EMPLOYEE") {
      req = {
        [data]: {
          comments: this.state.comments
        }
      };
    }
    var resp = await bookingServices.updateApproveList(
      this.state.approveListId,
      { data: req }
    );
    if (resp) {
      $(".close").click();
      this.getDetails();
      toast.success(alertConstants.UPDATED_SUCCESSFULLY);
    }
  };

  enableEticket = async () => {
    if (this.state.eticket) {
      this.setState({ eticket: false }, () => {
        this.setState({ eticket: true });
      });
    } else {
      this.setState({ eticket: true });
    }
  };
  enableInvoice = async () => {
    if (this.state.invoice) {
      this.setState({ invoice: false }, () => {
        this.setState({ invoice: true });
      });
    } else {
      this.setState({ invoice: true });
    }
  };
  closeWindow = () => {
    this.setState({ invoice: false, eticket: false });
  };
  checkArray = data => {
    return data && Array.isArray(data) && data.length;
  };
  eqipment = () => { };
  handleExpenseThisTrip = tripId => {
    sessionStorage.setItem("tripId", tripId);
    this.props.history.push("../../../../expense/reports");
  };

  chatWithApprover = () => {
    this.setState({ enableChat: true });
    $(".chat-head").addClass('d-none');
    $(".chat-head1").removeClass('d-none');
    $(".manager-list").removeClass('d-none');
  };
  getFormattedDate = (date) => {
    let formattedDate = moment(date, 'DD/MM/YYYY').format('DD/MMM/YYYY');
    return formattedDate;
    // return date
  }
  handleScroll = async (e, id, assignKey, page, count, totalLength, search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    setValue(this.state, "clientHeight", e.target.scrollHeight)
    if (bottom) {
      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {
          let mergeOld = getValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", []).concat(resp.result)
          setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", mergeOld)
          setValue(this.state.expenseForm[assignKey], "page", page + 1)
          this.setState({
            ...this.state
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }
    }
  }
  handleSearchManage = async (search, id, assignKey) => {
    if (id) {
      // if(search.length > 3){
      let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
      if (resp) {
        setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", resp.result)
      } else {
        setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", [])
      }
      setValue(this.state.expenseForm[assignKey], "search", search)
      this.setState({
        ...this.state
      })
      $(`#valueConnect${assignKey}`).attr('value', search)
      $(`#connect${assignKey}`).addClass("show")
      // }
    }
  }
  render() {

    const {
      tripDetails,
      currentItem,
      history,
      flightDetailView,
      hotelDetailView,
      type,
      approvals,
      userList,
      loading,
      receiverDetails
    } = this.state;
    return (
      <div>
        {loading && (
          <div className="p-padding">
            <div className="form-holder">
              <Loader />
            </div>
          </div>
        )}
        {!loading && (
          <div>
            {currentItem.TRIP_ID && (
              <div className="p-padding pb-0">
                <div className="request--cost white-card mb-0 p-3">
                  <div className="row">
                    <div className="col-xl-8 col-md-6">
                      {!currentItem.currentOrderNo &&
                        currentItem.STATUS === "Approved" ? (
                          <h6 className="sub-heading">Approved Request</h6>
                        ) : (
                          <h6 className="sub-heading">New Request</h6>
                        )}
                      <div className="text-left">
                        <div className="d-inline-block pr-4 mr-4 border-right">
                          <div className="shape-name lg round-trans">
                            <div className="left icon success">
                              <i className="material-icons">done</i>
                            </div>
                            <div className="right">
                              <p className="text">Requested Amount</p>
                              <h6 className="heading">
                                {tripDetails.itenary &&
                                  tripDetails.itenary.BASE_CURRENCY}
                                &nbsp;
                                <NumberFormat
                                  value={
                                    tripDetails.itenary &&
                                    parseInt(
                                      tripDetails.itenary.REQUESTED_AMOUNT
                                    ).toFixed(2)
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-inline-block pr-4 mr-4 border-right">
                          <div className="shape-name lg round-trans">
                            <div className="left icon orange">
                              <i className="material-icons">cached</i>
                            </div>
                            <div className="right">
                              <p className="text">Difference</p>
                              <h6 className="heading">
                                {tripDetails.itenary &&
                                  tripDetails.itenary.BASE_CURRENCY}
                                &nbsp;
                                <NumberFormat
                                  value={
                                    tripDetails.itenary &&
                                    parseInt(
                                      tripDetails.itenary.DIFFRENCE
                                    ).toFixed(2)
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-inline-block pr-4 mr-4">
                          <div className="shape-name lg round-trans">
                            <div className="left icon red">
                              <i className="material-icons">done</i>
                            </div>
                            <div className="right">
                              <p className="text">Total Amount</p>
                              <h6 className="heading">
                                {tripDetails.itenary &&
                                  tripDetails.itenary.BASE_CURRENCY}
                                &nbsp;
                                <NumberFormat
                                  value={
                                    tripDetails.itenary &&
                                    parseInt(
                                      tripDetails.itenary.TRIP_COST
                                    ).toFixed(2)
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-2 col-md-3">
                      <h6 className="sub-heading">Itinerary status</h6>
                      <button className="btn btn-nothing btn-overflow">
                        {!currentItem.STATUS &&
                          currentItem &&
                          approvals[
                          currentItem && currentItem.currentOrderNo
                          ] &&
                          approvals[currentItem && currentItem.currentOrderNo]
                            .intialStatus}
                      </button>
                      {currentItem.STATUS && currentItem.STATUS}
                    </div>
                    <div className="col-xl-2 col-md-3">
                      {currentItem.currentOrderNo ? (
                        <h6 className="sub-heading">Action</h6>
                      ) : (
                          ""
                        )}
                      <div className="btn-group-margin">
                        {this.state.expenseTripEnableStatus &&
                          !this.state.expenseTripDisableStatus
                          && (
                            <button
                              className="btn btn-approve mb-1"
                              onClick={() =>
                                this.handleExpenseThisTrip(currentItem.TRIP_ID)
                              }
                            >
                              Expense Trip
                            </button>
                          )}
                        {currentItem.actionButton.map((list, key) => {
                          if (list.key === "RECALL") {
                            return (
                              <button
                                onClick={e => this.updateStatus(list, currentItem)}
                                key={key}
                                data-target="#submitModalRequest"
                                className="btn btn-approve btn-color hover-v"
                                data-toggle="modal"
                              >
                                <i className="material-icons">
                                  check_circle_outline{" "}
                                </i>
                                <div className="hover-name with-br bottom">
                                  {list.button}
                                </div>
                              </button>
                            );
                          }
                          if (list.key === "SEND_BACK_EMPLOYEE") {
                            return (
                              <button
                                onClick={e => this.updateStatus(list)}
                                key={key}
                                className="btn btn-sendback btn-color hover-v"
                                data-target="#submitModalRequest"
                                data-toggle="modal"
                              >
                                <i className="material-icons">arrow_back </i>
                                <div className="hover-name with-br bottom">
                                  {list.button}
                                </div>
                              </button>
                            );
                          }
                          if (list.key === "REJECT") {
                            return (
                              <button
                                onClick={e => this.updateStatus(list)}
                                key={key}
                                className="btn btn-reject btn-color hover-v"
                                data-target="#submitModalRequest"
                                data-toggle="modal"
                              >
                                <i className="material-icons">
                                  not_interested{" "}
                                </i>
                                <div className="hover-name with-br bottom">
                                  {list.button}
                                </div>
                              </button>
                            );
                          }
                          if (list.key === "ADD_NEW_STEP") {
                            return (
                              <button
                                onClick={e => this.updateStatus(list)}
                                key={key}
                                className="btn btn-grey1 btn-color hover-v"
                                data-target="#submitModalRequest"
                                data-toggle="modal"
                              >
                                <i className="material-icons">add </i>
                                <div className="hover-name with-br bottom">
                                  {list.button}
                                </div>
                              </button>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="p-padding pb-0">
              <ul className="tabs-pil mb-0">
                <li>
                  <button className="btn active">New Itinerary</button>
                </li>
              </ul>
            </div>
            <div>
              <div className="p-padding pb-0">
                <div className="row">
                  <div className="col-md-6">
                    <div className="itinerary-scroll mx-itinerary-scroll">
                      <ul className="travel-tabs">
                        {/* Flight Config Starts */}
                        {tripDetails.itenary &&
                          tripDetails.itenary.data &&
                          tripDetails.itenary.data.flightData &&
                          tripDetails.itenary.data.flightData.map(
                            (list, key) => {
                              if (list.fare) {
                                return (
                                  <FlightComboCartItem
                                    isApprover={false}
                                    chooseFlight={this.chooseFlight}
                                    index={key}
                                    selectedItem={this.state.selectedItem}
                                    secondsToHms={this.secondsToHms}
                                    key={key}
                                    nonEdit={true}
                                    item={list}
                                    removeCart={this.removeFlightCart}
                                    baseCurrency={
                                      list?.flight_searchType?.disp_currency
                                    }
                                  />
                                );
                              } else {
                                return (
                                  <FlightCartItem
                                    isApprover={false}
                                    chooseFlight={this.chooseFlight}
                                    index={key}
                                    nonEdit={true}
                                    selectedItem={this.state.selectedItem}
                                    secondsToHms={this.secondsToHms}
                                    key={key}
                                    item={list}
                                    removeCart={this.removeFlightCart}
                                    baseCurrency={
                                      list?.flight_searchType?.disp_currency
                                    }
                                  />
                                );
                              }
                            }
                          )}
                        {/* Flight Config end */}
                        {/* hotells Config Starts */}
                        {tripDetails.itenary &&
                          tripDetails.itenary.data &&
                          tripDetails.itenary.data.hotelData &&
                          tripDetails.itenary.data.hotelData.map(
                            (list, key) => {
                              return (
                                <HotelCartItem
                                  isApprover={false}
                                  baseCurrency={list?.hotel_currency_code}
                                  chooseHotel={this.chooseHotel}
                                  index={key}
                                  nonEdit={true}
                                  removeHotelCart={this.removeHotelCart}
                                  selectedItem={this.state.selectedItem}
                                  secondsToHms={this.secondsToHms}
                                  key={key}
                                  item={list}
                                />
                              );
                            }
                          )}
                        {/* hotells Config end */}
                        {getValue(tripDetails, "itenary.data.carData", []).map((item, key) => (
                          <CabCartItem
                            item={item}
                            chooseCab={this.chooseCab}
                            index={key}
                            baseCurrency={this.state.baseCurrency}
                          />
                        ))}
                        {getValue(tripDetails, "itenary.data.trainData", []).map((item, key) => (
                          <TrainCartItem
                            item={item}
                            chooseCab={this.chooseTrain}
                            index={key}
                            baseCurrency={this.state.baseCurrency}
                            remove={false}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="itinerary-scroll mx-itinerary-scroll">
                      {type === "flight" && (
                        <FlightCartDetail
                          eticket={this.state.eticket}
                          eticketInfo={this.state.eticketInfo}
                          closeWindow={this.closeWindow}
                          invoiceInfo={this.state.invoiceInfo}
                          invoice={this.state.invoice}
                          enableEticket={this.enableEticket}
                          enableInvoice={this.enableInvoice}
                          ticketInvoice={this.state.showInvoiceTicket}
                          secondsToHms={this.secondsToHms}
                          item={flightDetailView}
                          currentItem={currentItem}
                          baseCurrency={
                            flightDetailView?.flight_searchType?.disp_currency
                          }
                        />
                      )}
                      {type === "combo" && (
                        <FlightCartComboDetail
                          eticket={this.state.eticket}
                          eticketInfo={this.state.eticketInfo}
                          closeWindow={this.closeWindow}
                          currentItem={currentItem}
                          invoiceInfo={this.state.invoiceInfo}
                          invoice={this.state.invoice}
                          enableEticket={this.enableEticket}
                          enableInvoice={this.enableInvoice}
                          ticketInvoice={this.state.showInvoiceTicket}
                          secondsToHms={this.secondsToHms}
                          item={flightDetailView}
                          baseCurrency={
                            flightDetailView?.flight_searchType?.disp_currency
                          }
                        />
                      )}
                      {type === "hotel" && (
                        <HotelCartDetail
                          eticket={this.state.eticket}
                          eticketInfo={this.state.eticketInfo}
                          closeWindow={this.closeWindow}
                          invoiceInfo={this.state.invoiceInfo}
                          currentItem={currentItem}
                          invoice={this.state.invoice}
                          enableEticket={this.enableEticket}
                          enableInvoice={this.enableInvoice}
                          ticketInvoice={this.state.showInvoiceTicket}
                          secondsToHms={this.secondsToHms}
                          item={hotelDetailView}
                          baseCurrency={hotelDetailView?.hotel_currency_code}
                        />
                      )}
                      {type === "cab" && (
                        <CabCartDetail
                          item={this.state.CabDetailView}
                        />
                      )}
                      {type === "train" && (
                        <TrainCartDetail
                          item={this.state.trainDetailView}

                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-padding">
              <h6 className="sub-heading">Requested By</h6>
              <div className="outdivision">
                <div className="profile-panel px-4">
                  <div className="row">
                    <div className="col-md-3">
                      {tripDetails.itenary && tripDetails.itenary.userDeatils && (
                        <div className="shape-name lg mb-0">
                          {tripDetails.itenary.userDeatils &&
                            tripDetails.itenary.userDeatils.profileImage ? (
                              <div
                                className="left img"
                                style={{
                                  backgroundImage: `url(${tripDetails.itenary
                                    .userDeatils &&
                                    tripDetails.itenary.userDeatils
                                      .profileImage})`
                                }}
                              />
                            ) : (
                              <div className="left">
                                {tripDetails.itenary.userDeatils.fieldData &&
                                  tripDetails.itenary.userDeatils.fieldData.map(
                                    (list, key) => {
                                      return (
                                        <span key={key}>
                                          {list.columnName ==
                                            "EMPLOYEE_FIRST_NAME" &&
                                            list.fieldValue.charAt(0)}
                                          {list.columnName ==
                                            "EMPLOYEE_LAST_NAME" &&
                                            list.fieldValue.charAt(0)}
                                        </span>
                                      );
                                    }
                                  )}
                              </div>
                            )}
                          <div className="right">
                            <h6 className="heading">
                              {tripDetails.itenary.userDeatils.fieldData.map(
                                (list, key) => {
                                  return (
                                    <span key={key}>
                                      {list.columnName ==
                                        "EMPLOYEE_FIRST_NAME" &&
                                        list.fieldValue}{" "}
                                      {list.columnName ==
                                        "EMPLOYEE_LAST_NAME" && list.fieldValue}
                                    </span>
                                  );
                                }
                              )}
                              {/* - {tripDetails.itenary.userDeatils.email} */}
                            </h6>
                            <p className="text">
                              {tripDetails.travelRequest &&
                                tripDetails.travelRequest.policy &&
                                tripDetails.travelRequest.policy.policyName}
                            </p>
                          </div>
                          <div className="clearfix" />
                        </div>
                      )}
                    </div>
                    <div className="col-md-4" />
                    <div className="col-md-5">
                      <div className="btn-group-margin text-right">
                        {/* <button className="btn mt-2">View Budget</button> */}
                        {/* <button className="btn">Edit Request</button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="innerdiv bg-white border-top-light py-3 px-4">
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="shape-name mb-4">
                        <div className="left l-grey">E</div>
                        <div className="right">
                          <p className="text op-less">Email</p>
                          <h6 className="heading fw-sbold">
                            {tripDetails.itenary &&
                              tripDetails.itenary.userDeatils &&
                              tripDetails.itenary.userDeatils.email}
                          </h6>
                        </div>
                      </div>
                    </div>
                    {currentItem.TRIP_ID && (
                      <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="shape-name mb-4">
                          <div className="left l-grey">T</div>
                          <div className="right">
                            <p className="text op-less">Trip Id</p>
                            <h6 className="heading fw-sbold">
                              {currentItem.TRIP_ID}
                            </h6>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="shape-name mb-4">
                        <div className="left l-grey">S</div>
                        <div className="right">
                          <p className="text op-less">Status</p>
                          <h6 className="heading fw-sbold">
                            {!currentItem.STATUS &&
                              currentItem.TRIP_ID &&
                              currentItem &&
                              approvals[
                              currentItem && currentItem.currentOrderNo
                              ] &&
                              approvals[
                                currentItem && currentItem.currentOrderNo
                              ].intialStatus}
                            {currentItem.STATUS && currentItem.STATUS}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="shape-name mb-4">
                        <div className="left l-grey">P</div>
                        <div className="right">
                          <p className="text op-less">Personal Days Off</p>
                          {tripDetails.travelRequest &&
                            tripDetails.travelRequest.data &&
                            tripDetails.travelRequest.data.personalDayOff &&
                            tripDetails.travelRequest.data.personalDayOff ? (
                              <h6 className="heading fw-sbold">
                                {
                                  tripDetails.travelRequest.data.personalDayOff
                                    .personalDayOffFrom
                                }{" "}
                                -{" "}
                                {
                                  tripDetails.travelRequest.data.personalDayOff
                                    .personalDayOffTO
                                }
                              </h6>
                            ) : (
                              "-"
                            )}
                        </div>
                      </div>
                    </div>
                    {tripDetails.travelRequest &&
                      tripDetails.travelRequest.data &&
                      tripDetails.travelRequest.data.REQUEST &&
                      tripDetails.travelRequest.data.REQUEST.entryFields &&
                      (getValue(tripDetails, "travelRequest.data.REQUEST.entryFields", [])).map(
                        (list, key) => {
                          return (
                            <div
                              className="col-xl-3 col-lg-4 col-md-6"
                              key={key}
                            >
                              <div className="shape-name mb-4">
                                <div className="left l-grey">
                                  {list.labelName && list.labelName.charAt(0)}
                                </div>
                                <div className="right">
                                  <p className="text op-less">
                                    {list.labelName}
                                  </p>
                                  <h6 className="heading fw-sbold">
                                    {list.dataType == "connected_list" ||
                                      list.dataType == "different_list"
                                      ? list.fieldItemName : list.fieldValue || ""}
                                    {/*
                                     {list.dataType == "connected_list" ||
                                      list.dataType == "different_list"
                                      ? list.connectedListData &&
                                      list.connectedListData
                                        .listManagementData &&
                                      list.connectedListData.listManagementData.map(
                                        (item, keyInt) => {
                                          if (
                                            item.id === list.fieldValue ||
                                            item.id === list.defaultValue
                                          ) {
                                            return (
                                              <span key={keyInt}>
                                                {item.itemName}
                                              </span>
                                            );
                                          }
                                        }
                                      )
                                      : list.fieldValue || ""} */}
                                  </h6>
                                </div>
                                <div className="clearfix" />
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
            {tripDetails.travelRequest &&
              tripDetails.travelRequest.data &&
              tripDetails.travelRequest.data.ALLOCATION && (
                <div className="p-padding pt-0">
                  <div className="main-box">
                    <div className="p-3 bg-light border-bottom-light">
                      <h6 className="sub-heading px-3 mb-0">Allocation</h6>
                      <div className="clearfix" />
                    </div>
                    <div className="table-container mx-0">
                      <div className="table-responsive">
                        <table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              {tripDetails.travelRequest.data &&
                                tripDetails.travelRequest.data.ALLOCATION &&
                                tripDetails.travelRequest.data.ALLOCATION
                                  .entryFields &&
                                tripDetails.travelRequest.data.ALLOCATION
                                  .entryFields[0] &&
                                tripDetails.travelRequest.data.ALLOCATION.entryFields[0].map(
                                  (value, headKey) => {
                                    if (
                                      headKey <
                                      tripDetails.travelRequest.data
                                        .ALLOCATION.entryFields[0].length -
                                      1 &&
                                      value.activeStatus !== false
                                    ) {
                                      return (
                                        <th key={headKey}>{value.fieldName}</th>
                                      );
                                    }
                                  }
                                )}
                              <th>
                                {tripDetails.travelRequest.data &&
                                  tripDetails.travelRequest.data.ALLOCATION &&
                                  tripDetails.travelRequest.data.ALLOCATION
                                    .allocatedIn}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {tripDetails.travelRequest &&
                              tripDetails.travelRequest.data &&
                              tripDetails.travelRequest.data.ALLOCATION &&
                              tripDetails.travelRequest.data.ALLOCATION
                                .entryFields &&
                              tripDetails.travelRequest.data.ALLOCATION.entryFields.map(
                                (value, key) => {
                                  return (
                                    <tr key={key}>
                                      {value.map(
                                        (allocationList, allocateKey) => {
                                          if (
                                            allocationList.activeStatus !==
                                            false
                                          ) {
                                            return (
                                              <td key={allocateKey}>
                                                {allocationList.dataType ==
                                                  "connected_list" ||
                                                  allocationList.dataType == "different_list"
                                                  ?
                                                  // allocationList.connectedListDatas &&
                                                  // allocationList.connectedListDatas &&
                                                  // allocationList
                                                  //   .connectedListDatas
                                                  //   .itemName
                                                  allocationList.fieldItemName
                                                  : allocationList.fieldValue ||
                                                  "-"}
                                                {/* {allocationList.fieldValue} */}
                                              </td>
                                            );
                                          }
                                        }
                                      )}
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {tripDetails.Expense &&
              tripDetails.Expense.map((list, key) => {
                return (
                  <div key={key}>
                    {list.perDiemDetails && list.perDiemDetails.length > 0 &&
                      <div className="p-padding pt-0">
                        <div className="main-box">
                          <h6 className="py-3 bg-light sub-heading px-4 border-bottom-light">
                            Travel Allowance/Per Diem
                        </h6>
                          <div className="table-container mx-0">
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th className="border-0">Journey</th>
                                    <th className="border-0">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  {list.perDiemDetails && (
                                    <tr>
                                      <td className="border-0">
                                        {list.noOfDaysJourney} Days
                                    </td>
                                      <td className="border-0">
                                        {
                                          list?.perDiemDetails?.[0]?.actualCurrencyType
                                        }
                                        &nbsp;
                                      {this.state.overAllPerDiem
                                          ? new Intl.NumberFormat("en-IN", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          }).format(this.state.overAllPerDiem)
                                          : 0}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div id="demo" className="collapse fade-in">
                              <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                  <thead>
                                    <tr>
                                      <th>Expense Type</th>
                                      <th>Stay Type</th>
                                      <th>Location</th>
                                      <th>Date</th>
                                      <th>Base Amount</th>
                                      <th>Actual Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {list.perDiemDetails.map((item, keyIndex) => {
                                      let items = [
                                        // {value:0,name:"Standard Rate",status:true},
                                        { value: 0, name: "Standard Rate" },
                                        { value: 1, name: "Guest House Rate" },
                                        { value: 2, name: "Stay At Friends Rate" },
                                      ]
                                      let placeholder = items.filter(resp => resp.value === item.limitType)
                                      return (
                                        <tr key={keyIndex}>
                                          <td>{item.expenseType}</td>
                                          <td>{getValue(placeholder[0], "name")}</td>
                                          <td>{item.location}</td>
                                          <td>{this.getFormattedDate(item.date)}</td>
                                          <td>
                                            {" "}
                                            {item.baseCurrencyType}{" "}
                                            {new Intl.NumberFormat("en-IN", {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            }).format(item.baseCurrencyAmount)}
                                          </td>
                                          <td>
                                            {" "}
                                            {item.actualCurrencyType}{" "}
                                            {new Intl.NumberFormat("en-IN", {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            }).format(item.actualCurrencyAmount)}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {this.state.overAllPerDiem !== 0 && (
                              <button
                                type="button"
                                className="btn btn-nothing bg-light btn-block py-2 show-more-btn collapsed text-primary btn-no-radius"
                                data-toggle="collapse"
                                data-target="#demo"
                              >
                                <span className="more">Show More</span>
                                <span className="less">Show Less</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    }
                    {list.additionalExpense && list.additionalExpense[0] && (
                      <div className="p-padding pt-0">
                        <div className="main-box">
                          <h6 className="py-3 sub-heading px-4 border-bottom-light bg-light">
                            Expense
                          </h6>
                          <div className="table-container mx-0">
                            <div className="table-responsive">
                              <table className="table table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th>Expense Type</th>
                                    {list.additionalExpense[0] &&
                                      list.additionalExpense[0].fieldData &&
                                      list.additionalExpense[0].fieldData.map(
                                        (expenseItem, key) => {
                                          return (
                                            <th key={key}>
                                              {expenseItem.labelName}
                                            </th>
                                          );
                                        }
                                      )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {list.additionalExpense &&
                                    list.additionalExpense.map(
                                      (expenseList, key) => {
                                        return (
                                          <tr>
                                            <td>
                                              {
                                                expenseList?.expenseObj
                                                  ?.expenseTypeName
                                              }
                                            </td>
                                            {expenseList &&
                                              expenseList.fieldData.map(
                                                (value, valueKey) => {
                                                  return (
                                                    <td key={valueKey}>
                                                      {value.dataType ==
                                                        "connected_list" ||
                                                        value.dataType ==
                                                        "different_list"
                                                        ? value.connectedListData &&
                                                        value
                                                          .connectedListData
                                                          .listManagementData &&
                                                        value.connectedListData.listManagementData.map(
                                                          (item, keyInt) => {
                                                            if (
                                                              item.id ===
                                                              value.fieldValue ||
                                                              item.id ===
                                                              value.defaultValue
                                                            ) {
                                                              return (
                                                                <span
                                                                  key={keyInt}
                                                                >
                                                                  {
                                                                    item.itemName
                                                                  }
                                                                </span>
                                                              );
                                                            }
                                                          }
                                                        )
                                                        : value.dataType ==
                                                          "search"
                                                          ? value.searchName
                                                          : value.fieldValue ||
                                                          "-"}
                                                    </td>
                                                  );
                                                }
                                              )}
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {list.cashAdvanceFormData && list.cashAdvanceFormData.length > 0 && (
                      <div className="p-padding pt-0">
                        <div className="main-box">
                          <h6 className="py-3 px-4 sub-heading bg-light">
                            Cash Advance
                          </h6>
                          <div className="table-container mx-0">
                            <div className="table-responsive">
                              <table className="table table-striped mb-0">
                                <thead>
                                  <tr>
                                    {list.cashAdvanceFormData[0] &&
                                      list.cashAdvanceFormData[0].fieldData &&
                                      list.cashAdvanceFormData[0].fieldData.map(
                                        (expenseCash, key) => {
                                          return (
                                            <th key={key}>
                                              {expenseCash.labelName}
                                            </th>
                                          );
                                        }
                                      )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {list.cashAdvanceFormData &&
                                    list.cashAdvanceFormData.map(
                                      (expenseList, key) => {
                                        return (
                                          <tr>
                                            {expenseList &&
                                              expenseList.fieldData.map(
                                                (value, valueKey) => {
                                                  return (
                                                    <td key={valueKey}>
                                                      {value.dataType ==
                                                        "connected_list" ||
                                                        value.dataType ==
                                                        "different_list"
                                                        ? value.connectedListData &&
                                                        value
                                                          .connectedListData
                                                          .listManagementData &&
                                                        value.connectedListData.listManagementData.map(
                                                          (item, keyInt) => {
                                                            if (
                                                              item.id ===
                                                              value.fieldValue ||
                                                              item.id ===
                                                              value.defaultValue
                                                            ) {
                                                              return (
                                                                <span
                                                                  key={keyInt}
                                                                >
                                                                  {
                                                                    item.itemName
                                                                  }
                                                                </span>
                                                              );
                                                            }
                                                          }
                                                        )
                                                        : value.dataType ==
                                                          "search"
                                                          ? value.searchName
                                                          : value.fieldValue ||
                                                          "-"}
                                                    </td>
                                                  );
                                                }
                                              )}
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            <div className="p-padding pt-0">
              <div className="white-card shadow-light">
                <div className="pad-sm">
                  <h6 className="sub-heading mb-0 float-left pt-3">
                    Approver Flow
                  </h6>
                  {/* {approvals.length !== 0 && (
                    <button
                      type="button"
                      className="btn float-right"
                      onClick={e => this.chatWithApprover(e)}
                    >
                      Start Chat
                    </button>
                  )} */}
                  <div className="clearfix" />
                </div>
                <div>
                  {approvals.length === 0 && (
                    <p className="text-center">All steps are auto approved.</p>
                  )}
                  {approvals &&
                    approvals.map((item, key) => {
                      return (
                        <div className="approver" key={key}>
                          {item.APPROVED ? (
                            <div className="right-icon approved">
                              <i className="material-icons">check_circle</i>
                            </div>
                          ) : (
                              <div className="right-icon waiting">
                                <i className="material-icons">hourglass_empty</i>
                              </div>
                            )}
                          <div className="shape-name">
                            {item.userDetails &&
                              item.userDetails.profileImage ? (
                                <div
                                  className="left img"
                                  style={{
                                    backgroundImage: `url(${item.userDetails &&
                                      item.userDetails.profileImage})`
                                  }}
                                />
                              ) : (
                                <div className="left">
                                  {item.userDetails.fieldData &&
                                    item.userDetails.fieldData.map(
                                      (list, key) => {
                                        return (
                                          <span key={key}>
                                            {list.columnName ==
                                              "EMPLOYEE_FIRST_NAME" &&
                                              list.fieldValue.charAt(0)}
                                            {list.columnName ==
                                              "EMPLOYEE_LAST_NAME" &&
                                              list.fieldValue.charAt(0)}{" "}
                                          </span>
                                        );
                                      }
                                    )}
                                </div>
                              )}
                            <div className="right">
                              <p className="label">Level {key + 1}</p>
                              <p className="title">
                                {item.userDetails.fieldData && (
                                  <span>
                                    {getFullUserName(
                                      item.userDetails.fieldData
                                    )}
                                  </span>
                                )}
                              </p>
                              <p className="desg">{item.userDetails.email}</p>
                              {approvals.submittedAt ? <p>Submitted on : {approvals.submittedAt ? <Moment format='DD MMM YYYY, HH:mm'>{approvals.submittedAt}</Moment> : "     -"} </p> : ""}
                              {approvals.approvedAt ? <p>Approved on :{approvals.approvedAt ? <Moment format='DD MMM YYYY, HH:mm'>{approvals.approvedAt}</Moment> : "  Not Yet Approved"} </p> : ""}

                            </div>
                          </div>

                          {/* <button type="button" className="btn" onClick={(e) => this.chatWithApprover(item)} >Start Chat</button> */}
                        </div>
                      );
                    })}
                  <div className="clearfix" />
                  {/* <button type="button" className="btn" onClick={(e) => this.chatWithApprover(e)}>Start Chat</button> */}
                </div>
              </div>
            </div>
            {/*this.state.enableChat ? (
              <ChatApp
                approvals={approvals}
                //receiverDetails={this.state.receiverDetails}
                enableChat={this.state.enableChat}
              />
            ) : (
                ""
              )*/}
            {/* <ChatApp
              approvals={approvals}
            /> */}
            <div
              className="modal fade"
              id="submitModalRequest"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="submitModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content border-0">
                  <div className="modal-head">
                    <span
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    />
                    <h5 className="modal-title">{this.state.choosedName}</h5>
                  </div>
                  <div className="modal-middle pm-30">
                    <div className="row">
                      <div className="col-md-12">
                        {this.state.choosedStatus === "ADD_NEW_STEP" && (
                          <div className="">
                            {this.state.choosedUser.length === 0 && (
                              <div className="form-group custom">
                                <input
                                  autocomplete="off"
                                  name="searchUser"
                                  onChange={e => this.getSearchUser(e)}
                                  placeholder="&nbsp;"
                                  className="form-control"
                                />
                                <label>Search &amp; Choose A User</label>
                                <span className="searc-icon-abs">
                                  <i className="material-icons">search</i>
                                </span>
                              </div>
                            )}
                            <div>
                              {this.state.choosedUser.length === 0 && userList && (
                                <ul className="search-list">
                                  {userList.map((item, key) => {
                                    return (
                                      <li
                                        key={key}
                                        onClick={e => this.chooseUser(item)}
                                      >
                                        {item.email}
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                              {this.state.choosedUser && (
                                <span>{this.state.choosedUser.email}</span>
                              )}
                            </div>
                          </div>
                        )}
                        <div>
                          {(this.state.choosedStatus == "SEND_BACK_EMPLOYEE" ||
                            this.state.choosedStatus == "REJECT" ||
                            this.state.choosedStatus == "ADD_NEW_STEP") && (
                              <div className="form-group">
                                <TextArea
                                  name="comments"
                                  value={this.state.comments}
                                  label={"Comment"}
                                  controlFunc={this.handleChange}
                                />
                              </div>
                            )}
                          {this.state.choosedStatus !== "APPROVE" && this.state.choosedStatus !== "RECALL" && (
                            <div className="btn-group-margin text-right">
                              <button
                                className="btn"
                                onClick={e =>
                                  this.submitRequest(this.state.choosedStatus)
                                }
                              >
                                Submit
                              </button>
                              <button
                                className="btn"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={e => this.reset()}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                          {this.state.choosedStatus === "APPROVE" && (
                            <div className="btn-group-margin text-center">
                              <h3>Are you Sure ?</h3>
                              <p> This action cannot be undone</p>
                              <div className="clear-fix" />
                              <button
                                className="btn"
                                onClick={e =>
                                  this.submitRequest(this.state.choosedStatus)
                                }
                              >
                                Yes
                              </button>
                              <button
                                className="btn"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={e => this.reset()}
                              >
                                No
                              </button>
                            </div>
                          )}
                          {this.state.choosedStatus === "RECALL" && (
                            <div className="btn-group-margin text-center">
                              <h3>Are you sure you want to Recall</h3>
                              <div className="clear-fix" />
                              <div className="mt-4">
                                <button
                                  // aria-label="Close"
                                  className="btn"
                                  onClick={e =>
                                    this.submitReCall(this.state.currentItem)
                                  }
                                  data-dismiss="modal"
                                >
                                  Yes
                              </button>
                                <button
                                  className="btn"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={e => this.reset()}
                                >
                                  No
                              </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default TripTravelRequestPage;
