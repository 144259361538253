import React, { Component } from 'react';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import FlightDetails from '../flightDetails';
import HotelDetails from '../hotelDetails';
import UserDetailsPage from '../userDetails';


const HistoryDetailsPage = (props) => {
  const { data, userDetails, pnrData } = props.state;

  var totalBaseFare = 0;
  data.flightData && data.flightData.flights.forEach((flight, i) => {
    totalBaseFare += flight.fareContent.fare.flight_base_price
  });
  var totalTaxOfAll = 0;
  data.flightData && data.flightData.flights.forEach((flight, i) => {
    totalTaxOfAll += flight.fareContent.fare.flight_total_tax
  });
  var totalPrice = 0;
  data.flightData && data.flightData.flights.forEach((flight, i) => {
    totalPrice += flight.fareContent.fare.flight_total_price
  });
  return (
    <div className="ProjectPage">
      <div className="page-header">
        <h6 className="heading">Trip Details</h6>
      </div>
      <div className="page-content">
        <div className="p-padding">
          {props.state.type === 'FLIGHT' &&
            <div className="traveller-details">
              <div className="table-responsive">
                <table className="table mb-0">
                  <tbody>
                    <tr>
                      <td>TRAVELER NAME <br /><b>{data.travellerName}</b></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-right">
                        <button className="btn" onClick={(e) => props.enablePopUp(e)} >User Details</button>
                      </td>
                    </tr>
                    <tr>
                      <td>BOOKED BY <br /><b>{data.travellerName}</b></td>
                      <td>EMAIL ID <br /><b>{data.email}</b></td>
                      <td>MOBILE NUMBER <br /><b>--</b></td>
                      <td>SEAT PREFERENCE <br /><b>{data.seatPreference ? data.seatPreference : '--'}</b></td>
                      <td>MEAL PREFERENCE <br /><b>{data.mealRequest}</b></td>
                    </tr>
                    {(data.flightData != undefined && Array.isArray(data.flightData.flights[0].fareContent)) ?
                      <tr>
                        <td>BASE FARE <br />
                          <b>
                            <NumberFormat
                              value={(Math.floor(data.flightData.flights[0].fare.flight_base_price * 1000) / 1000)}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={data.baseCurrency + ' '} />
                          </b>
                        </td>
                        <td>TOTAL TAX <br />
                          <b>
                            <NumberFormat
                              value={(Math.floor(data.flightData.flights[0].fare.flight_total_tax * 1000) / 1000)}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={data.baseCurrency + ' '}
                            />
                          </b>
                        </td>
                        <td>TOTAL FARE <br />
                          <b>
                            <NumberFormat
                              value={(Math.floor(data.flightData.flights[0].fare.flight_total_price * 1000) / 1000)}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={data.baseCurrency + ' '} />
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      :
                      <tr>
                        <td>BASE FARE <br />
                          <b>
                            <NumberFormat
                              value={(Math.floor(totalBaseFare * 1000) / 1000)}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={data.baseCurrency + ''} />
                          </b>
                        </td>
                        <td>TOTAL TAX <br />
                          <b>
                            <NumberFormat
                              value={(Math.floor(totalTaxOfAll * 1000) / 1000)}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={data.baseCurrency + ' '} />
                          </b>
                        </td>
                        <td>TOTAL FARE <br />
                          <b>
                            <NumberFormat
                              value={(Math.floor(totalPrice * 1000) / 1000)}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={data.baseCurrency + ' '} />
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    }

                  </tbody>
                </table>
              </div>
            </div>
          }
          {props.state.type === 'HOTEL' &&
            <div className="traveller-details">
              <div className="table-responsive">
                <table className="table mb-0">
                  <tbody>
                    <tr>
                      <td>TRAVELER NAME <br /><b>{data.travellerName}</b></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-right">
                        <button className="btn" onClick={(e) => props.enablePopUp(e)} >User Details</button>
                      </td>
                    </tr>
                    <tr>
                      <td>BOOKED BY <br /><b>{data.travellerName}</b></td>
                      <td>EMAIL ID <br /><b>{data.email}</b></td>
                      <td>MOBILE NUMBER <br /><b>--</b></td>
                      <td></td>
                      <td>MEAL PREFERENCE <br /><b>{data.mealRequest ? data.mealRequest : '--'}</b></td>
                    </tr>
                    <tr>
                      <td>AMOUNT <br />
                        <b>
                          <NumberFormat
                            value={(data.hotelData != undefined) ? (Math.floor(data.hotelData.hotel_room_info.hotel_room_price * 1000) / 1000) : ""} displayType={'text'} thousandSeparator={true} prefix={data.hotelData ? data.hotelData.hotel_room_info.hotel_room_rack_rate.currency + ' ' : ''} />
                        </b>
                      </td>
                      <td>TOTAL TAX <br />
                        <b>
                          <NumberFormat value={(data.hotelData && data.hotelData.hotel_room_info.hotel_taxes != undefined) ? (Math.floor(data.hotelData.hotel_room_info.hotel_taxes * 1000) / 1000) : "0"} displayType={'text'} thousandSeparator={true} prefix={data.hotelData ? data.hotelData.hotel_room_info.hotel_room_rack_rate.currency + ' ' : ''} />
                        </b>
                      </td>
                      <td>TOTAL AMOUNT <br />
                        <b>
                          <NumberFormat value={(data.hotelData != undefined) ? (Math.floor(data.hotelData.hotel_room_info.hotel_total_price * 1000) / 1000) + ' ' + (data.hotelData.hotel_currency_code ? data.hotelData.hotel_currency_code : data.hotelData.hotel_room_info.hotel_currency_code) : ""} displayType={'text'} thousandSeparator={true} prefix={data.hotelData ? data.hotelData.hotel_room_info.hotel_room_rack_rate.currency + ' ' : ''} />
                        </b>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          }
          <div className="py-padding">
            <div className="accordion" id="accordionExample">
              {props.state.type === 'FLIGHT' && (props.state.isLoading ?
                <div className="mx-auto text-center">
                  <img
                    width="200"
                    className="img-fluid "
                    alt="no flight"
                    src="/assets/images/loader.gif"
                  />
                  <p>Loading please wait...</p>
                </div>
                :
                <FlightDetails
                  data={data}
                  eTicket={true}
                  invoice={true}
                  pnrDetails={props.state.pnrDetails} />

              )}
            </div>
          </div>
          <div className="">
            <div className="accordion" id="accordionExample">
              {(props.state.isLoading) && props.state.type === 'HOTEL' ?
                <div className="mx-auto text-center">
                  <img
                    width="200"
                    className="img-fluid "
                    alt="no flight"
                    src="/assets/images/loader.gif"
                  />
                  <p>Loading please wait...</p>
                </div>
                :
                (props.state.type === 'HOTEL') &&
                <HotelDetails
                  data={data}
                  eTicket={true}
                  invoice={true} />
              }
            </div>
          </div>
        </div>

      </div>


      {props.state.popUp &&
        <UserDetailsPage
          popUp={props.state.popUp}
          userDetails={userDetails}
          closePopUp={props.closePopUp}
          savedPassVisa={props.state.savedPassVisa}
        />
      }

    </div>
  );
}


export default HistoryDetailsPage;
