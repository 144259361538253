import React from "react";
import AllocationDynamicForm from "./allocationDynamicForm";


const expenseAllocationTable = props => {
  return (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th width="5%" />
                  <th />
                </tr>
              </thead>
              <tbody>
                {props.expenseAllocationForm &&
                  props.expenseAllocationForm.map((forms, index) => {
                    return (
                      <tr key={index} className="bg-white">
                        <td className="">
                          <label className="checkbox-container pb-4">
                            <input
                              type="checkbox"
                              checked={
                                props.selectedIndex.includes(index)
                                  ? props.selectedIndex.includes(index)
                                  : false
                              }
                              onChange={e =>
                                props.onSelectedAllocationFieldHandler(index)
                              }
                            />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <div className="row">
                            <div className="col-md-4 my-3">
                              <div className="form-group custom mb-0">
                                <input
                                  value={
                                    props["amount" + index]
                                      ? props["amount" + index]
                                      : ""
                                  }
                                  onChange={e =>
                                    props.onAmountPercentageChangeHandler(
                                      e,
                                      "amount" + index,
                                      index
                                    )
                                  }
                                  type="text"
                                  placeholder="&nbsp;"
                                  className="form-control"
                                />
                                <label>
                                  {props.allocatedIn ? props.allocatedIn : ""}
                                </label>
                                <div className="help-block">
                                  {props.validator.message(
                                    "amount",
                                    props["amount" + index],
                                    "required"
                                  )}
                                </div>
                              </div>
                            </div>
                            <AllocationDynamicForm
                              {...props}
                              validator={props.validator}
                              onClickHandler={props.onClickHandler}
                              onChangeHandler={props.onChangeHandler}
                              onDatePickerHandler={props.onDatePickerHandler}
                              onCheckboxHandler={props.onCheckboxHandler}
                              onIntegerHandler={props.onIntegerHandler}
                              allocationForm={forms}
                              rowIndex={index}
                              onLocationChangekHandler={
                                props.onLocationChangekHandler
                              }
                              loadOptions={props.loadOptions}
                              handleScroll={props.handleScroll}
                              handleSearchManage={props.handleSearchManage}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
  );
};
export default expenseAllocationTable;
