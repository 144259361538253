import {http} from '../_helpers';
import { config } from '../env';


// Rule Class Api Calls
const createRuleClass = (data) => {
  let url = config.apiUrlTravel + 'travel/rule-class';
  return http.post(url, data)
    .then(res => res)
}

const activeInactiveRuleClass = (data) => {
  let url = config.apiUrlTravel + 'travel/rule-class/active-inactive';
  return http.put(url, data)
  .then(res => res)
}

const deleteRuleClass = (companyId, id) => {
  // check if you have to pass the companyId too in the url
  let url = config.apiUrlTravel + 'travel/rule-class/' + id;
  return http.delete(url)
   .then(res => res)

}

const getRuleClassById = (companyId, id) => {
  let url = config.apiUrlTravel + 'travel/rule-class/' + id + '?companyId=' + companyId;
  return http.get(url)
    .then(res => res)
}

const getAllRuleClass = (companyId, limit, page ) => {
let url = '';
  if(limit && page){
     url = config.apiUrlTravel + 'travel/rule-class?companyId=' + companyId + '&limit='+limit + '&page='+page;

  }else{
    url = config.apiUrlTravel + 'travel/rule-class?companyId=' + companyId;
  }

  return http.get(url)
    .then(res => res)

}

// * old search api - now defunct - use get all for search
// const searchRuleClass = (companyId, ruleClass, limit, page) => {
//   let url = config.apiUrlTravel + 'travel/search-rule-class/' + ruleClass + '?companyId=' + companyId + '&limit=' + limit + '&page=' + page;
//   return http.get(url)
//     .then(res => res)
// }

const searchRuleClass = (companyId, ruleClass, limit, page) => {
  let url = config.apiUrlTravel + 'travel/rule-class/?companyId=' + companyId + '&limit=' + limit + '&page=' + page + '&search=' + ruleClass;
  return http.get(url)
    .then(res => res)
}

const updateRuleClass  = (companyId, id, data) => {
  let url = config.apiUrlTravel + 'travel/rule-class/' + id;
  return http.patch(url, data)
    .then(res => res)
}









export const travelRuleClassService =  {
  createRuleClass,
  updateRuleClass,
  getAllRuleClass,
  getRuleClassById,
  activeInactiveRuleClass,
  searchRuleClass,
  deleteRuleClass
}