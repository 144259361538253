export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',
    CREATED_SUCCESSFULLY: 'Created Successfully',
    UPDATED_SUCCESSFULLY: 'Updated Successfully',
    DELETED_SUCCESSFULLY: 'Deleted Successfully',
    ACTIVATED_SUCESSFULLY: 'Activated Successfully',
    DEACTIVATED_SUCCESSFULLY: 'Deactivated Successfully',
    // Expense Policies constants
    POLICY_ACTIVATED_SUCCESSFULLY: 'Policy Activated Successfully',
    POLICY_DEACTIVATED_SUCCESSFULLY: 'Policy Deactivated Successfully',
    ARRAY_HAS_DUPLICATES: 'Please Provide Unique Sequence Order',

    // Travel Constants
    TRAVEL_ALLOWANCE_NOT_FOUND: "Travel Allowance Not Found",
    FILL_ALL_FIELDS: "Please Fill All The Fields",

    //approver alert
    APPROVER_ERROR:"Please Add Approver Before submission"

};