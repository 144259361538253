import React from "react";

const cardTransactionFilter = props => {
  return (
    <form onSubmit={props.onSubmitHandler}>
      <div className="row">
        <div className="col-md-9">
          <div className="btn-group-margin">
            <button type="button" disabled data-toggle="modal" data-target="#movetoModal" className="btn">
              Move To
            </button>
            <div className="dropdown">
              <button
                type="button"
                className="btn dropdown-toggle"
                data-toggle="dropdown"
              >
                {props.selected}
              </button>
              <div className="dropdown-menu">
                <button
                  onClick={e => props.onSelectHandler("This Month")}
                  className="dropdown-item"
                >
                  This Month
                </button>
                <button
                  onClick={e => props.onSelectHandler("This Week")}
                  className="dropdown-item"
                >
                  This Week
                </button>
                <button
                  onClick={e => props.onSelectHandler("Today")}
                  className="dropdown-item"
                >
                  Today
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="search-custom">
            <input
              onChange={props.onChangeHandler}
              type="text"
              name="searchText"
              className="form-control"
              placeholder="Search"
            />
            <span onClick={props.onSearchHandler}>
              <i className="material-icons">search</i>
            </span>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="movetoModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="movetoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h6 className="modal-subtitle">Transaction</h6>
              <h5 className="modal-title" id="movetoModalLabel">
                Move To
        </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom labelup">
                <label>Policy</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn bg-white dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Select
                  </button>
                  <div className="dropdown-menu">
                    <button
                      type="button"
                      className="dropdown-item"
                    >
                      Policy 1
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group custom labelup">
                <label>Expense</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn bg-white dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Select
                  </button>
                  <div className="dropdown-menu">
                    <button
                      type="button"
                      className="dropdown-item"
                    >
                      Expense 1
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-last pt-0 pm-30">
              <div className="btn-group-margin">
                <button
                  type="button"
                  className="btn"
                >
                  upload
                </button>
                <button type="button" className="btn close" data-dismiss="modal">
                  Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default cardTransactionFilter;
