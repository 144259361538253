import React, { Component } from 'react';
import GroupConfigPage from './group-config-html';
class GroupConfig extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <GroupConfigPage
                />
            </div>
        )
    }
}
export default GroupConfig;