import React, { Component } from 'react';
import DepartmentPage from './department-html';
class Department extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <DepartmentPage
                />
            </div>
        )
    }
}
export default Department;