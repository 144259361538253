import { http } from "../_helpers";
import { config } from "../env";

const getFinanceExpenseList = (companyId,limit,page,search,status) => {
    return http
      .get(
        `${config.apiExpense}listExpenseReports?companyId=${companyId}&limit=${limit}&page=${page}&search=${search}&status=${status}`)
      .then(data => {
        return data ? data : "";
      });
  };

  const expenseReportVeficatiom=(companyId,data)=>{
    return http
      .patch(
        `${config.apiExpense}finance-admin/status?companyId=${companyId}`,data)
      .then(data => {
        return data ? data : "";
      });
  }

  const getApprovedCashAdvnace = (companyId,limit,page,search) => {
    return http
      .get(
        `${config.apiExpense}listExpenseReports/verified?companyId=${companyId}&limit=${limit}&page=${page}&search=${search}`)
      .then(data => {
        return data ? data : "";
      });
  };

  export const financeAdminService = {
    getFinanceExpenseList,
    expenseReportVeficatiom,
    getApprovedCashAdvnace
  }