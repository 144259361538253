import React, { Component } from 'react';
import PolicyStep2Page  from './policy-step2-html';
class PolicyStep2 extends Component {
    constructor(props){
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    render() {
        return(
            <div>
                <PolicyStep2Page
                />
            </div>
        )
    }
}
export default PolicyStep2;