import React, { Component } from "react";
import WorkflowsPage from "./home-html";
import { workflowService } from "../../../../../../_services/workflow.service";
import { alertConstants } from "src/_constants";
import { toast } from "react-toastify";
class Workflows extends Component {
  state = {
    companyId: localStorage["440a28"],
    displayedWorkflows: [],
    activePage: 1,
    totalItemsCount: 0,
    itemsCountPerPage: 10,
    selectedWorkflow: [],
    allGroups: [],
    loading:true,
    search:''
  }; 
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }]; 

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" }); 
    this.getAllWorkflows();
    this.getAllGroups();
  }

  getAllGroups = async () => { 
    let res = await workflowService.getAllGroups(this.state.companyId);
    if(res){ 
      this.setState({ 
        allGroups: res.result,
        allGroupsNames: res.result.map(item => item.itemName)
      });
    }
  }

  getAllWorkflows = async () => {  
    const { companyId,itemsCountPerPage,activePage,search} = this.state;
      let res = await workflowService.getAllWorkflows(
        companyId,
        itemsCountPerPage,
        activePage,
        search
      ); 
      if (res.count > 0) { 
        this.setState({
          loading:false,
          displayedWorkflows: res.result,
          totalItemsCount: res.count
        });
      } else {
        this.setState({
          loading:false,
          displayedWorkflows: [],
          totalItemsCount: 0
        });
      } 
  };

  handlePageChange = pageNumber => { 
    this.setState({ activePage: pageNumber },
      () => {
        this.getAllWorkflows();
      }
    );
  };

  handleLimitChange = limit => { 
    this.setState({ itemsCountPerPage: limit },
      () => {
        this.getAllWorkflows();
      }
    );
  };

  handleToggleWorkflow = workflowId => { 
    let selectedWorkflow = [...this.state.selectedWorkflow];
    let index = selectedWorkflow.indexOf(workflowId);
    if (index == -1) {
      selectedWorkflow = [workflowId];
    } else {
      selectedWorkflow = [];
    }
    this.setState({ selectedWorkflow });
  };

  handleRemoveWorkflow = async (e) => {
    e.preventDefault();
    let workflowIdToBeRemoved = '';
    if(this.state.selectedWorkflow.length != 0){
      workflowIdToBeRemoved = this.state.selectedWorkflow[0];
    }
    let res = await workflowService.removeWorkflow(this.state.companyId, workflowIdToBeRemoved);
    if(res){
      toast.success(alertConstants.DELETED_SUCCESSFULLY);
      this.getAllWorkflows();
    }
  };

  handleAddDuplicate = async (e) => {
    e.preventDefault();
    let workflowIdToBeDuplicated = '';
    if(this.state.selectedWorkflow.length != 0){
      workflowIdToBeDuplicated = this.state.selectedWorkflow[0];
    }
    let res = await workflowService.duplicateWorkflow(this.state.companyId, workflowIdToBeDuplicated);
    if(res){
      toast.success(alertConstants.CREATED_SUCCESSFULLY);
      this.getAllWorkflows();
    }
  }

  handleSearch = (e) => {
    this.setState({
      search:e.target.value
    },()=>{
      this.getAllWorkflows()
    })
  }
 
  render() {
    return (
      <div>
        <WorkflowsPage
          allState={this.state}
          handlePageChange={this.handlePageChange}
          handleLimitChange={this.handleLimitChange}
          loading={this.state.loading}
          handleToggleWorkflow={this.handleToggleWorkflow}
          handleRemoveWorkflow={this.handleRemoveWorkflow}
          handleAddDuplicate={this.handleAddDuplicate}
          pageItemsList={this.pageItemsList}
          handleSearch={this.handleSearch}
        />
      </div>
    );
  }
}
export default Workflows;
