/* eslint-disable default-case */
import React from "react";
import { NavLink } from "react-router-dom";
import { Select, Input, TextArea } from "../../../../../common/_forms";
import SelectNew from 'react-select';
import RuleClassDropdown from "./components/RuleClassDropdown";
import Category from "./components/Category";
import AddTemplateModal from "./components/AddTemplateModal";
import ConditionContainer from "./components/ConditionContainer";
import TravelRenderedFields from "src/_components/common/TravelRenderedFields";
import Loader from "src/_components/common/Loader";
// import SelectSearch from 'react-select-search'
const TravelPolicyPage = props => {
  const {
    allRules,
    selectedRuleClass,
    selectedCategory,
    addTemplateModal,
    allTemplatesInSelectedCategory,
    selectedRuleTemplates,
    policiesByCategory,
    allCategories,
    fieldsToBeRendered,
    ruleTemplateName,
    violationCode,
    allConditions,
    anyCondition,
    availableRulesinCategory,
    selectedCheckboxes,
    messagePolicyHandler,
    message,
  } = props

  const {
    ruleClassDropdownHandler,
    categoryClickHandler,
    toggleAddTemplateModal,
    ruleTemplateSelectHandler,
    addRulePolicyHandler,
    fieldDateChangeHandler
  } = props;
  let categories = ["Travel Itinerary", "Flight", "Car", "Train", "Hotel"];
  let activeStyle = { background: "#377DFF", color: '#fff' };
  const getLLFDetails = (previouslySetLLF) => {
    if (previouslySetLLF.length == 0) {

      return (
        <div className="table-responsive">
          <table className="table table-striped">
            <tbody>
              <tr>

                <td className="align-middle">
                  <label className="switch"><input type="checkbox" checked="" /><span className="slider round"></span></label>
                </td>
                <td>
                  <small className="mb-0 text-uppercase">Condition</small>
                  <p className="mb-0"><b>Lowest Logical Fare(LLF) - Domestic </b></p>
                </td>
                <td>
                  <NavLink to="/administration/travel/lowest-logical-fare/lowest-logical-fare">
                    <button className="btn">Edit</button>
                  </NavLink>
                </td>
              </tr>
              <tr>

                <td className="align-middle">
                  <label className="switch"><input type="checkbox" checked="" /><span className="slider round"></span></label>
                </td>
                <td>
                  <small className="mb-0 text-uppercase">Condition</small>
                  <p className="mb-0"><b>Lowest Logical Fare(LLF) - International </b></p>
                </td>
                <td>
                  <NavLink to="/administration/travel/lowest-logical-fare/lowest-logical-fare">
                    <button className="btn">Edit</button>
                  </NavLink>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    } else if (previouslySetLLF.length == 1) {
      switch (previouslySetLLF[0].tripType) {
        case 0:
          return (
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  <tr>

                    <td className="align-middle">
                      <label className="switch"><input type="checkbox" checked="" /><span className="slider round"></span></label>
                    </td>
                    <td>
                      <small className="mb-0 text-uppercase">Condition</small>
                      <p className="mb-0"><b>Lowest Logical Fare(LLF) - Domestic </b></p>
                    </td>
                    <td>
                      <NavLink to={"/administration/travel/lowest-logical-fare/lowest-logical-fare/" + previouslySetLLF[0].id}>
                        <button className="btn">Edit</button>

                      </NavLink>
                    </td>
                  </tr>
                  <tr>

                    <td className="align-middle">
                      <label className="switch"><input type="checkbox" checked="" /><span className="slider round"></span></label>
                    </td>
                    <td>
                      <small className="mb-0 text-uppercase">Condition</small>
                      <p className="mb-0"><b>Lowest Logical Fare(LLF) - International </b></p>
                    </td>
                    <td>
                      <NavLink to="/administration/travel/lowest-logical-fare/lowest-logical-fare">
                        <button className="btn">Edit</button>

                      </NavLink>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          )
        case 1:
          return (
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td className="align-middle" width="10%">
                      <label className="switch"><input type="checkbox" checked="" /><span className="slider round"></span></label>
                    </td>
                    <td>
                      <small className="mb-0 text-uppercase">Condition</small>
                      <p className="mb-0 fw-sbold">Lowest Logical Fare(LLF) - Domestic</p>
                    </td>
                    <td>
                      <NavLink to="/administration/travel/lowest-logical-fare/lowest-logical-fare" className="btn btn-nothing hover-v">
                        <i className="material-icons fs-20">
                          edit
                                              </i>
                        <span className="hover-name right-top">Edit</span>
                      </NavLink>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle" width="10%">
                      <label className="switch"><input type="checkbox" checked="" /><span className="slider round"></span></label>
                    </td>
                    <td>
                      <small className="mb-0 text-uppercase">Condition</small>
                      <p className="mb-0 fw-sbold">Lowest Logical Fare(LLF) - International</p>
                    </td>
                    <td>
                      <NavLink to={"/administration/travel/lowest-logical-fare/lowest-logical-fare/" + previouslySetLLF[0].id} className="btn btn-nothing hover-v">
                        <i className="material-icons fs-20">
                          edit
                                              </i>
                        <span className="hover-name right-top">Edit</span>
                      </NavLink>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          )
      }
    } else {
      let domesticLLF = previouslySetLLF.find(item => item.tripType == 0);
      let internationalLLF = previouslySetLLF.find(item => item.tripType == 1);
      return (
        <div className="table-responsive">
          <table className="table table-striped">
            <tbody>
              <tr>

                <td className="align-middle" width="10%">
                  <label className="switch">
                    <input type="checkbox" checked={domesticLLF.activeStatus} onChange={e => props.onLLFToggle(domesticLLF)} />
                    <span className="slider round"></span>
                  </label>
                </td>
                <td>
                  <small className="mb-0 text-uppercase">Condition</small>
                  <p className="mb-0 fw-sbold">Lowest Logical Fare(LLF) - Domestic</p>
                </td>
                <td>
                  <NavLink to={"/administration/travel/lowest-logical-fare/lowest-logical-fare/" + domesticLLF.id}
                    className="btn btn-nothing hover-v">
                    <i className="material-icons fs-20">
                      edit
                                            </i>
                    <span className="hover-name right-top">Edit</span>
                  </NavLink>
                </td>
              </tr>
              <tr>

                <td className="align-middle" width="10%">
                  <label className="switch"><input type="checkbox" checked={internationalLLF.activeStatus} onChange={e => props.onLLFToggle(internationalLLF)} /><span className="slider round"></span></label>
                </td>
                <td>
                  <small className="mb-0 text-uppercase">Condition</small>
                  <p className="mb-0 fw-sbold">Lowest Logical Fare(LLF) - International</p>
                </td>
                <td>
                  <NavLink
                    to={"/administration/travel/lowest-logical-fare/lowest-logical-fare/" + internationalLLF.id}
                    className="btn btn-nothing hover-v">
                    <i className="material-icons fs-20">
                      edit
                                              </i>
                    <span className="hover-name right-top">Edit</span>

                  </NavLink>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


      )
    }
  }


  return (
    <div>
      <div className="admin-tabs">
        {/* <Menu /> */}
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">Travel Policy - Rules</h6>
              <p className="text">The Travel Policy & Rules feature allows administrators to manage company travel policy and configure violations.</p>
            </div>
          </div>
        </div>

        <div className="p-padding">
          <div className="form-holder">
            <div className="row">
              <div className="col-4">
                <div className="form-group custom labelup">
                  <label>Rule Class</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle btn-block"
                      data-toggle="dropdown"
                    >
                      {selectedRuleClass
                        ? selectedRuleClass.className
                        : "Select a Rule Class"}
                    </button>
                    <div className="dropdown-menu">
                      {allRules &&
                        allRules.map(ruleClass => {
                          return (
                            <RuleClassDropdown
                              key={ruleClass.id}
                              name={ruleClass.className}
                              id={ruleClass.id}
                              ruleClass={ruleClass}
                              dropdownClickHandler={ruleClassDropdownHandler}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs-container">
              <div className="left">
                <Category
                  allCategories={allCategories}
                  heading="Policies"
                  clickHandler={categoryClickHandler}
                  selectedCategory={selectedCategory}
                />
              </div>
              <div className="right">
                <div className="travel__policy">
                  <ul className="nav nav-tabs float-left p-0">
                    <li>
                      <button type="button" className={props.isCustomCondition ? "active" : ""} onClick={props.showCustomConditions}>Custom Condition</button>
                    </li>
                    {(selectedCategory.travelCategoryName === "Flight") &&
                      <li>
                        <button type="button" className={props.isCustomCondition ? "" : "active"} onClick={props.hideCustomConditions} >Advanced Conditions</button>
                      </li>
                    }
                  </ul>
                  <ul className="float-right">
                    <li className="py-2 px-3 text-right border-top-0 bg-white">
                      <button
                        disabled={(Object.keys(props.selectedCategory).length === 0 && props.selectedCategory.constructor === Object)}
                        onClick={toggleAddTemplateModal}
                        className="btn"
                      >
                        Add Template
                    </button></li>
                  </ul>
                  <div className="clearfix"></div>
                </div>

                <div className="px-padding scroll">
                  <div className="table-container">
                    <div className="table-responsive">
                      <table className="table table-striped table-selected">
                        <tbody>
                          {/* Place for conditions */}


                          {
                            props.isCustomCondition ?
                              (

                                props.isLoading ? <Loader />
                                  : (


                                    props.allTemplatesInSelectedCategory.length ? (props ?.allTemplatesInSelectedCategory[0] ?.customCondition ?.map((template, index) => {
                                      return (
                                        <tr key={index} >
                                          <td className="align-middle" width="10%">
                                            <label className="switch">
                                              <input type="checkbox" onChange={e => props.onRuleTemplateStatusToggle(e, template)} checked={template.activeStatus} value={template.activeStatus} /><span className="slider round"></span>
                                            </label>
                                          </td>
                                          <td>
                                            <small className="mb-0 text-uppercase">Condition</small>
                                            <p className="mb-0 s-bold">{template.ruleName}</p>
                                          </td>
                                          <td>
                                            <button
                                              className="btn btn-nothing hover-v"
                                              onClick={e => props.onEditRuleTemplate(template)}
                                              data-toggle="modal"
                                              data-target="#EditRuleTemplate"
                                            >

                                              <i className="material-icons fs-20">
                                                edit
                                              </i>
                                              <span className="hover-name right-top">Edit</span>
                                            </button>

                                          </td>
                                        </tr>

                                      )
                                    })) :
                                      (
                                        <tr><td colSpan="3" className="text-center">No Records Found</td></tr>
                                      )
                                  )

                              )
                              :
                              (getLLFDetails(props.previouslySetLLF))
                          }



                          {/* End of place for conditions */}
                          {/* <tr>
                        <td className="align-middle">
                          <label  className="switch"><input type="checkbox" checked="" /><span  className="slider round"></span></label>
                        </td>
                        <td>
                          <NavLink to="/administration/travel/travel-itinerary">
                            <small className="mb-0 text-uppercase">Condition</small>
                            <p className="mb-0"><b>Trip Duration Greater then 7 days</b> - Require Pre Approval</p>
                          </NavLink>
                        </td>
                        <td>
                          <NavLink to="/administration/travel/travel-itinerary">
                            <button  className="btn">Edit</button>
                          </NavLink>
                        </td>
                      </tr> */}


                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>


              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div >

      <AddTemplateModal
        isModalOpen={addTemplateModal}
        toggleModal={toggleAddTemplateModal}
        templates={props.availableRuleTemplates}
        ruleTemplateSelectHandler={ruleTemplateSelectHandler}
        selectedRuleTemplates={selectedRuleTemplates}
        submitHandler={addRulePolicyHandler}
      />

      {/* <div className="modal fade" id="AddTemplateModal2" tabIndex="-1" role="dialog"
                aria-labelledby="AddTemplateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="AddTemplateModalLabel">Travel Rule Template View</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <p className="mod-modal-body">Lorem ipsum is simply dummy text. Lorem ipsum is simply dummy text. Lorem ipsum is simply dummy text.</p>
                            <div className="tabs-container my-container">
                                <div className="setwidth">
                                    <div className="heading">
                                        <h6 className="sub-heading pl-2">Template Name</h6>
                                    </div>
                                    <div className="scroll">
                                        <ul>
                                            <li className="selected "><button className="btn btn-nothing"><span className="pl-2">Some Name</span></button></li>
                                            <li ><button className="btn btn-nothing"><span className="pl-2">Some Name1</span></button></li>
                                            <li ><button className="btn btn-nothing"><span className="pl-2">Some Name2</span></button></li>
                                            <li><button className="btn btn-nothing"><span className="pl-2">Some Name3</span></button></li>
                                            <li><button className="btn btn-nothing"><span className="pl-2">Some Name4</span></button></li>
                                            <li><button className="btn btn-nothing"><span className="pl-2">Some Name5</span></button></li>
                                            <li><button className="btn btn-nothing"><span className="pl-2">Some Name6</span></button></li>
                                            <li><button className="btn btn-nothing"><span className="pl-2">Some Name7</span></button></li>
                                            <li><button className="btn btn-nothing"><span className="pl-2">Some Name8</span></button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-border-success lg-padding">Save</button>
                            <button type="button" className="btn btn-border-basic lg-padding"
                                data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
         */}

      {/* Edit Modal Start*/}
      <div
        className="modal fade"
        id="EditRuleTemplate"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="EditRuleTemplateLabel"
        aria-hidden="true"
      >
        {
          props.isLoadingPop ? <Loader />
            : (
              <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-head">
                    <h6 className="modal-subtitle">Edit</h6>
                    <h5 className="modal-title" id="newcashAdvancLabel">
                      Policy Rule Template
                  </h5>
                    <button
                      onClick={props.cancelModalHandler}
                      type="button"
                      className="close d-none"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-middle pm-30">
                    <div className="">
                      {/* {availableRulesinCategory &&
                        availableRulesinCategory.map(condition => {
                          return (
                            <div className="form-group" key={condition.id}>
                              <label className="checkbox-container">
                                {" "}
                                {condition.travelCategoryRule}
                                <input
                                  type="checkbox"
                                  onChange={e => props.conditionCheckHandler(condition)}
                                  checked={selectedCheckboxes.includes(condition.id)}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          );
                        })} */}
                      <div>

                        {fieldsToBeRendered &&
                          fieldsToBeRendered.map((rule, idx) => {
                            return (
                              <div key={idx}>
                                <p>{rule.travelCategoryRule}</p>
                                <div className="row">
                                  <div className="clearfix"></div>
                                  {rule.ruleCondition.map((fieldItem, index) => {
                                    return (
                                      <div key={index} className="col-lg-3">
                                        {/* {fieldItem ?.dataTypeDetail ?.conditionID} */}
                                        <TravelRenderedFields
                                          type={fieldItem ?.dataTypeDetail ?.conditionID}
                                          fieldItem={fieldItem}
                                          fieldDateChangeHandler={(e, type) => fieldDateChangeHandler(e, rule, fieldItem, type)}
                                          // value={new Date() || fieldItem?.dataTypeValue}
                                          fieldChangeHandler={(e, type) => props.fieldChangeHandler(e, rule, fieldItem, type)}
                                          selectDifferentListChangeHandler={(e, type) => props.selectDifferentListChangeHandler(e, rule, fieldItem, type)}
                                          selectChangeHandler={(e, type) => props.selectChangeHandler(e, rule, fieldItem, type)}
                                          dataTypeValueDetail={fieldItem ?.dataTypeValueDetail}
                                          conditionalOptions={fieldItem ?.dataTypeDetail ?.conditionList}
                                          fieldValue={fieldItem ?.selectedValue}
                                          dropdownValue={fieldItem ?.selectedDropdown}
                                          loadOptions={props.loadOptions}
                                        // selectFieldValue={}

                                        />

                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )
                          })}
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="form-group custom mb-0">
                              <SelectNew
                                placeholder="Select Policy Violation"
                                value={Object.keys(props.selectedPolicyViolation).length ? props.selectedPolicyViolation : 'Select policy violation'}
                                options={props.policyViolationsByRuleClass || []}
                                onChange={props.violationPolicyHandler}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <TextArea
                              controlFunc={messagePolicyHandler}
                              name={"message"}
                              value={message}
                              label={"Message"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-last btn-group-margin pm-30 pt-0">
                    <button
                      onClick={props.saveRuleTemplateHandler}
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                    >
                      Update
                    </button>
                    <button
                      onClick={props.cancelModalHandler}
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
      </div>
    </div >
  );
};
export default TravelPolicyPage;
