import { http } from "../_helpers";
import { config } from "../env";

const getFormHeader = id => {
  return http
    .get(config.apiUrlCompany + `form-headers?serviceModuleId=${id}`)
    .then(data => {
      return data.result;
    });
};
// xx
const getFormHeadersValue = data => {
  return http
    .post(config.apiUrlCompany + `company-fields/get-header-fields`, data)
    .then(data => data);
};

const getExpenseReport = data => {
  return http
    .post(config.apiExpense + `reports/create`, data)
    .then(data => data);
};



const getAllReports = (limit,page,companyId) => {
  if (limit === undefined || page === undefined) {
  return http.get(config.apiExpense + `reports`).then(data => data);
  }else{
    return http.get(config.apiExpense + `reports?limit=${limit}&page=${page}&companyId=${companyId}`).then(data => data);
  }
};


const getSingleExpenseReport = id => {
  return http
    .get(config.apiExpense + `expense/reports/${id}/download`)
    .then(data => data);
};

const getSingleTravelReport = id => {
  return http
    .get(config.apiExpense + `travel/reports/${id}/download`)
    .then(data => data);
};

const getSingleCashAdvanceReport = id => {
  return http
    .get(config.apiExpense + `cash-advance/reports/${id}/download`)
    .then(data => data);
};

const scheduleReport = (reportId, data) => {
  return http
    .patch(config.apiExpense + `reports/${reportId}/schedule`, data)
    .then(data => data);
};

const getEmployeeForm = data => {
  return http
    .post(config.apiUrlCompany + `company-fields/list-all`, data)
    .then(data => data);
};

export const reportService = {
  getFormHeader,
  getFormHeadersValue,
  getExpenseReport,
  getAllReports,
  getSingleExpenseReport,
  getSingleTravelReport,
  getSingleCashAdvanceReport,
  scheduleReport,
  getEmployeeForm
};
