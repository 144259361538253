import React from "react";
const RequestPage = props => {
  const {
    userList,
    userName,
    choosedUser,
    searchUser,
    removeUser,
    listService,
    storeServiceModule,
    serviceModuleId,
    listOfItems,
    handleSearchAssign,
    selectedItem,
    selectFlag,
    chooseItem,
    selectAll,
    listOfItemsAssign,
    selectedItemAssign,
    selectFlagAssign,
    chooseItemAssign,
    selectAllAssign,
    handleSearch,
    moveLeft,
    moveRight
  } = props;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Permission Administrator</h6>
              <p className="text">
                The Permission Administrator feature allows administrators to
                search user and provide tool access used in Expense, Travel, and
                Request.
              </p>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="admin-tabs">
          <ul className="nav nav-tabs px-0">
            {listService &&
              listService.map((item, key) => {
                return (
                  <li key={key}>
                    <button
                      className={serviceModuleId === item.id ? "active" : ""}
                      onClick={e => storeServiceModule(item.id)}
                    >
                      {item.serviceModuleName}
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="">
          <div className="dragndrop min-height pt-3">
            <div className="top">
              <div className="row">
                <div className="col-md-6">
                  {!userName && (
                    <div className="form-group custom">
                      <input
                        autoComplete="off"
                        name="searchUser"
                        placeholder="&nbsp;"
                        onChange={searchUser}
                        className="form-control"
                      />
                      <label>Search & Choose A User</label>
                      <span className="searc-icon-abs"><i className="material-icons">search</i></span>
                    </div>
                  )}
                  {userName && (
                    <h5 className="mb-0">
                      <div className="mr-4 float-left cursor-pointer">
                        <i onClick={removeUser} className="material-icons close-icon">
                          close
                      </i>
                      </div>
                      {userName}{" "}
                    </h5>
                  )}
                  <div>
                    {userList.length !== 0 && (
                      <ul className="search-list">
                        {userList.map((item, key) => (
                          <li key={key} onClick={e => choosedUser(item)}>
                            {item.email}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {userName && (
              <div className="dnd">
                <div className="left">
                  <div className="search-custom">
                    <input
                      type="text"
                      onChange={handleSearch}
                      name="search"
                      className="form-control"
                      placeholder="Search Permissions"
                    />
                    <span>
                      <i className="material-icons">search</i>
                    </span>
                  </div>
                  {listOfItems.length !== 0 && (
                    <div>
                      <button
                        onClick={selectAll}
                        className="select-all cursor-pointer float-right"
                      >
                        {!selectFlag ? "Select All" : "Deselect All"}
                      </button>
                      <p className="pt-2 px-3 mb-0 fw-sbold">Permissions list</p>
                      <div className="clearfix"></div>
                    </div>
                  )}

                  <div className="scroll">
                    <ul>
                      {listOfItems &&
                        listOfItems.map((item, key) => {
                          return (
                            <li
                              key={key}
                              onClick={e => chooseItem(item.id)}
                              className={
                                selectedItem.indexOf(item.id) !== -1
                                  ? "selected"
                                  : ""
                              }
                            >
                              {item.menuName}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="center">
                  <div className="mid">
                    {selectedItem.length !== 0 ?
                      <div>
                        <button onClick={moveLeft} className="btn">
                          <i className="material-icons">
                            chevron_right
                        </i>
                        </button>
                      </div>
                      :
                      <div>
                        <button disabled className="btn btn-disabled">
                          <i className="material-icons">
                            chevron_right
                        </i>
                        </button>
                      </div>
                    }
                    {selectedItemAssign.length !== 0 ?
                      <div>
                        <button onClick={moveRight} className="btn">
                          <i className="material-icons">
                            chevron_left
                        </i>
                        </button>
                      </div>
                      :
                      <div>
                        <button disabled className="btn btn-disabled">
                          <i className="material-icons">
                            chevron_left
                        </i>
                        </button>
                      </div>
                    }
                  </div>
                </div>
                <div className="right same-scroll">
                  <div className="search-custom">
                    <input
                      type="text"
                      onChange={handleSearchAssign}
                      name="search"
                      className="form-control"
                      placeholder="Search Permissions"
                    />
                    <span>
                      <i className="material-icons">search</i>
                    </span>
                  </div>
                  {listOfItemsAssign.length !== 0 && (
                    <div>
                      <button
                        onClick={selectAllAssign}
                        className="select-all cursor-pointer float-right"
                      >
                        {!selectFlagAssign ? "Select All" : "Deselect All"}
                      </button>
                      <p className="pt-2 px-3 fw-sbold mb-0">Assigned Permissions</p>
                      <div className="clearfix"></div>
                    </div>
                  )}
                  <div className="scroll">
                    <ul>
                      {listOfItemsAssign &&
                        listOfItemsAssign.map((item, key) => {
                          return (
                            <li
                              key={key}
                              onClick={e => chooseItemAssign(item.id)}
                              className={
                                selectedItemAssign.indexOf(item.id) !== -1
                                  ? "selected"
                                  : ""
                              }
                            >
                              {item.menuId && item.menuId.menuName}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RequestPage;
