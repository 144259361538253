import React, { Component } from "react";
import io from "socket.io-client";
import $ from 'jquery';
import { chatService } from "src/_services"
import cryptr from "src/_helpers/crypty";


class ChatApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomId: "",
      hide: false,
      message: '',
      messages: [],
      receiverDetails: {},
      receiverId: ''
    };

    this.socket = io('http://192.168.0.159:3015');
    this.socket.on("RECEIVE_MESSAGE", (data) => {
      // console.log(data);
      this.receiveMessage(data);
    })
    this.url = "/assets/sounds/chat_tone.mp3";
    this.audio = new Audio(this.url);

  }
  senderDetails = JSON.parse(cryptr.decrypt(localStorage["440a24"]));

  componentDidMount() {
    this.createConnection();
  }

  createConnection = () => {
    this.socket.on("connection", (roomId) => {
      // console.log(roomId, "successfully connected");
      this.socket.emit('createroom', roomId);
    });
  }

  createRoom = (receiverId) => {
    this.socket.emit('room', {
      senderId: this.senderDetails.id,
      receiverId: receiverId
    });
    this.socket.on("roomId", roomId => {
      // console.log(roomId);

      this.setState({ roomId: roomId });
      this.getMessages(roomId);
    })
    this.setState({ receiverId: receiverId });
    // console.table({ userId: this.senderDetails.id, receiverId: receiverId });
    this.socket.emit('online', { userId: this.senderDetails.id, receiverId: receiverId });
  }
  getMessages = async (roomId) => {
    // console.log(roomId);
    let res = await chatService.getMessages(roomId);
    if (res) {
      // console.log(res.chatResponse);
      this.setState({ messages: res.chatResponse.reverse() })
    }
    this.scrollToBottom();
  }
  handleChangeMessage = (e) => {
    // console.log(e.target.value);
    this.setState({
      message: e.target.value
    })
  }

  sendMessage = (e) => {
    e.preventDefault();
    if (this.state.message !== '') {
      this.setState({ messages: [...this.state.messages, { message: this.state.message, senderId: this.senderDetails.id, createdAt: new Date().getHours() + ":" + new Date().getMinutes() }] });
      this.audio.play();
      this.socket.emit('SEND_MESSAGE',{
        message: this.state.message,
        roomId: this.state.roomId,
        senderId: this.senderDetails.id,
        recieverId: this.state.receiverId,
        createdAt: new Date().getHours() + ":" + new Date().getMinutes(),
        from: this.senderDetails.fieldData[0].fieldValue + ' ' + this.senderDetails.fieldData[1].fieldValue,
      })
      // console.log(this.state.message, this.senderDetails.id, this.state.receiverId);
      // console.log(this.state.roomId);
      this.scrollToBottom();
    }

    this.setState({ message: '' });
  }
  scrollToBottom = () => {
    let chat = document.getElementById("chatList");
    chat.scrollTop = chat.scrollHeight + 100;
  };
  receiveMessage = (data) => {
    this.setState({ messages: [...this.state.messages, data] });
    this.audio.play();
  }


  openChat() {
    $(".chat-head").addClass('d-none');
    $(".chat-head1").removeClass('d-none');
    $(".manager-list").removeClass('d-none');
  }
  closeChat() {
    $(".chat-head").removeClass('d-none');
    $(".chat-head1").addClass('d-none');
    $(".chat-head2").addClass('d-none');
    $(".manager-list").addClass('d-none');
  }
  startChat = (receiver) => {
    $(".manager-list").addClass('d-none');
    $(".chat-body").removeClass('d-none');
    $(".chat-head2").removeClass('d-none');
    $(".chat-head1").addClass('d-none');
    $(".chat-footer-container").removeClass('d-none');

    // console.log(receiver);
    this.setState({ receiverDetails: receiver });
    this.createRoom(receiver.userId);

  }
  closemember() {
    $(".chat-footer-container").addClass('d-none');
    $(".chat-head2").addClass('d-none');
    $(".chat-body").addClass('d-none');
    $(".manager-list").removeClass('d-none');
    $(".chat-head1").removeClass('d-none');
  }
  receiverLastName = '';
  receiverFirstName = '';
  getReceiverName = (receiver) => {
    receiver.userDetails.fieldData.forEach((item) => {
      if (item.columnName === "EMPLOYEE_LAST_NAME") {
        this.receiverLastName = item.fieldValue;
      }
      else if (item.columnName === "EMPLOYEE_FIRST_NAME") {
        this.receiverFirstName = item.fieldValue;
      }
    })
    // console.log(this.receiverFirstName + ' ' + this.receiverLastName);
    return this.receiverFirstName + ' ' + this.receiverLastName;
  }


  render() {
    const { approvals } = this.props;
    const { receiverDetails } = this.state;
    const senderDetails = this.senderDetails;
    // console.log(receiverDetails);
    // console.log(senderDetails);
    let senderLastName = '',
      senderFirstName = '';
    senderDetails.fieldData.forEach((item) => {
      if (item.columnName === "EMPLOYEE_LAST_NAME") {
        senderLastName = item.fieldValue;
      }
      else if (item.columnName === "EMPLOYEE_FIRST_NAME") {
        senderFirstName = item.fieldValue;
      }
    })

    return (
      <div className="chat-container">
        <audio id="playSound" src="/assets/images/quite-impressed.mp3"></audio>
        {/* 1st level */}
        <div className="chat-head" onClick={e => this.openChat()}>
          <div className="icon-div">
            <i className="material-icons">
              chat
              </i>
          </div>
          <div className="text-div">
            <p>Got Questions?</p>
            <h6>Chat with Approvers</h6>
          </div>
        </div>
        {/* 1st leve end */}
        {/* 2nd level */}
        <div className="chat-head chat-head1 d-none">
          <div className="icon-div goback" onClick={e => this.closeChat()}>
            <i className="material-icons">
              arrow_back
              </i>
          </div>
          <div className="text-div">
            <div className="float-left">
              <div className="float-left prof">
                <div className="prof" style={{ backgroundImage: `url(${senderDetails.profileImage})` }}></div>
              </div>
              <div className="name">
                <p>online</p>

                <h6>{senderFirstName + ' ' + senderLastName}</h6>


              </div>
              <div className="clearfix"></div>
            </div>
            {/* <div className="close float-right" >
              <i className="material-icons text-white">
                close
              </i>
            </div> */}
            <div className="clearfix"></div>
          </div>

        </div>
        {/* 2nd level end */}
        {/* 3rd level */}
        <div className="chat-head chat-head2 d-none">
          <div className="icon-div goback"
            onClick={e => this.closemember()}
          >
            <i className="material-icons">
              arrow_back
              </i>
          </div>
          <div className="text-div">
            {receiverDetails && receiverDetails.userDetails && receiverDetails.userDetails.profileImage ?
              <div className="prof" style={{ lineHeight: "30px" }}>
                <img src={receiverDetails.userDetails.profileImage} style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
              </div>
              :
              <div className="prof online" style={{ backgroundImage: `url(/assets/images/jenni.jpg)` }}></div>
            }

            {/* <div className="prof"
              style={{ backgroundImage: `url(/assets/images/jenni.jpg)` }}

            >
            </div> */}
            <div className="name">
              <p>online</p>
              <h6>
                {receiverDetails.userDetails && receiverDetails.userDetails.fieldData && this.getReceiverName(receiverDetails)}
              </h6>
            </div>
          </div>

        </div>
        {/* 3rd level end */}
        <div className="manager-list d-none">
          <ul>
            {approvals && approvals.map((approver, i) => {
              return (
                <li>
                  <div className="text-div user-pro" onClick={e => this.startChat(approver)}>
                    <div className="abs-right">5</div>
                    {approver.userDetails.profileImage ?
                      <div className="prof online" style={{ lineHeight: "30px" }}>
                        <img src={approver.userDetails.profileImage} style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
                        <span className="dot"></span>
                      </div>
                      :
                      <div className="prof online" style={{ backgroundImage: `url(/assets/images/jenni.jpg)` }}>
                        <span className="dot"></span>
                      </div>
                    }

                    <div className="name">
                      <p>Level {Number(approver.orderNo) + 1}</p>
                      <h6>
                        {this.getReceiverName(approver)}
                      </h6>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="chat-body d-none" >
          <div className="abs-bottom" id="chatList">
            {this.state.messages && this.state.messages.length ? this.state.messages.map((message, i) => {
              return (
                <>
                  {(message.senderId === senderDetails.id) ?
                    <div className="right">
                      <div className="chat-box">{message.message}
                        <span className="time">{message.createdAt}</span>
                      </div>
                    </div>
                    :
                    <div className="left">
                      <div className="chat-box">{message.message}
                        <span className="time">{message.createdAt}</span>
                      </div>
                    </div>
                  }

                </>
              )
            })
              :
              ''}

          </div>
        </div>
        <div className="chat-footer-container d-none">
          <form onSubmit={(e) => this.sendMessage(e)}>
            <div className="chat-footer">
              <input type="text"
                placeholder="Type a message..."
                value={this.state.message}
                onChange={(e) => this.handleChangeMessage(e)} />
              <button type="submit"><i className="material-icons">
                send
              </i></button>
              <div className="clearfix"></div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default ChatApp;  