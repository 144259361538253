import { http } from "../_helpers";
import { config } from "../env";

export const myAccountInsuranceService = {
  saveInsuranceDetails,
  getInsuranceDetails
};

function saveInsuranceDetails(payload) {
  return http
    .patch(config.apiUrlCompany + "insurance-settings/patchOne", payload)
    .then(res => res);
}

function getInsuranceDetails() {
  return http
    .get(config.apiUrlCompany + "insurance-settings/getOne")
    .then(data => {
      return data ? data : "";
    });
}
