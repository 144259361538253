import {http} from '../_helpers';
import { config } from '../env';


const getAllRuleClass = companyId => {
  let url = config.apiUrlTravel + 'travel/get-rule-class?companyId=' + companyId;
  return http.get(url)
    .then(res => res)
}

const getAllRuleTemplatesByCategory = (companyId, categoryId, ruleClassId) => {
  let url = `${config.apiUrlTravel}travel/travel-policy?companyId=${companyId}&ruleClassId=${ruleClassId}&travelCategoryId=${categoryId}`;
  return http.get(url)
    .then(res => res)

}

const addRulePolicy = (payload) => {
  let url = `${config.apiUrlTravel}travel/travel-policy`;
  return http.post(url,payload)
    .then(res => res)
}

const getPoliciesByCategory = (companyId, templateCategory, ruleClassId) => {
  let url = `${config.apiUrlTravel}travel/get-policy-rule?companyId=${companyId}&templateCategory=${templateCategory}&ruleClass=${ruleClassId}`;
  return http.get(url)
    .then(res => res)
}

const updatePolicy = (id, payload) => {
  let url = `${config.apiUrlTravel}travel/policy-rule/${id}`;
  return http.patch(url, payload)
    .then(res => res)
}

const togglePolicyStatus = (companyId, payload) => {
  let url = `${config.apiUrlTravel}travel/policy-rule/status?companyId=${companyId}`;
  return http.patch(url, payload)
    .then(res => res)
}

const getPolicyById = (companyId, rulePolicyId) => {
  let url = `${config.apiUrlTravel}travel/policy-rule/${rulePolicyId}?companyId=${companyId}`;
  return http.get(url)
    .then(res => res)
}

const getPreviouslySetRuleTemplateDetails = (ruleTemplateId) => {
  let url = `${config.apiUrlTravel}travel/travel-category-rule/policy?ruleTemplateId=${ruleTemplateId}`;
  return http.get(url)
    .then(res => res)
}

const createLowestLogicalFare = (payload) => {
  let url = `${config.apiUrlTravel}travel/lowest-logical-fare`;
  return http.post(url, payload)
    .then(res => res)
}

const updateLowestLogicalFare = (llfId, payload) => {
  let url = `${config.apiUrlTravel}travel/lowest-logical-fare/${llfId}`
  return http.patch(url,payload).then(res => res)
}

const getAllLowestLogicalFare = (companyId) => {
  let url = `${config.apiUrlTravel}travel/lowest-logical-fare?companyId=${companyId}`;
  return http.get(url).then(res => res)
}

const getIndividualLowestLogicalFare = (llfId) => {
  let url = `${config.apiUrlTravel}travel/lowest-logical-fare/${llfId}`;
  return http.get(url).then(res => res);
}



export const travelPolicyService = {
  getAllRuleClass,
  getAllRuleTemplatesByCategory,
  addRulePolicy,
  getPoliciesByCategory,
  togglePolicyStatus,
  getPolicyById,
  updatePolicy,
  getPreviouslySetRuleTemplateDetails,
  createLowestLogicalFare,
  getAllLowestLogicalFare,
  getIndividualLowestLogicalFare,
  updateLowestLogicalFare
}