import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
class CashIssuedDetailsPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  render() {
    let data = this.props;
    return (
      <div>
        <div className="p-padding">
          <div className="white-card">
            <div className="main-box">
              <div className="py-padding">
                <div className="table-container">
                  <form className="py-0 px-4">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="profile-flow-image">
                          <div className="float-left approver-image">
                            <img
                              src="/assets/images/booking/user_11.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="float-left approver-data ml-3">
                            <p className="font-weight-bold">
                              <b>Christine Willis</b>
                            </p>
                            <p className=" unmuted-p  lighter-p text-uppercase">
                              Manager
                            </p>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </div>
                      <div className="col-md-2 offset-4">
                        <div className="form-group minus-mr">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              Status
                            </button>
                            <div className="dropdown-menu">
                              <button className="dropdown-item">
                                Expense Entry Form
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="search-custom">
                          <input
                            type="text"
                            name=""
                            className="form-control"
                            placeholder="Search"
                          />
                          <span><i className="material-icons">search</i></span>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>CASH ADVANCE NAME</th>
                          <th>REQUEST DATE</th>
                          <th>AMOUNT REQUESTED</th>
                          <th>EXCHANGE RATE</th>
                          <th>STARTING BALANCE</th>
                          <th>UNSUBMITTED BALANCE</th>
                          <th>AVAILABLE BALANCE</th>
                          <th className="text-right">STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Stationery Request</td>
                          <td>10/12/2018</td>
                          <td>$100.00</td>
                          <td>1.0000000</td>
                          <td>$100.00</td>
                          <td>$100.00</td>
                          <td>$100.00</td>
                          <td className="text-right">
                            <NavLink
                              to="/cash-issued/cash-issued-for-trips"
                              className="btn btn-danger text-white"
                            >
                              Pending
                            </NavLink>
                          </td>
                        </tr>
                        <tr>
                          <td>Trip to London</td>
                          <td>10/12/2018</td>
                          <td>$100.00 </td>
                          <td>1.0000000</td>
                          <td>$100.00</td>
                          <td>$100.00</td>
                          <td>$100.00</td>
                          <td className="text-right">
                            <button
                              type="button"
                              className="btn btn-border-success text-success lg-padding"
                            >
                              issued
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table-footer">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="page-count">
                          <div className="text">Items</div>
                          <div className="count">
                            <div className="dropdown">
                              <button
                                type="button"
                                className="btn dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                10
                              </button>
                              <div className="dropdown-menu">
                                <button type="button" className="dropdown-item">
                                  10
                                </button>
                                <button type="button" className="dropdown-item">
                                  20
                                </button>
                                <button type="button" className="dropdown-item">
                                  30
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <Pagination
                          activePage={data.active}
                          itemsCountPerPage={10}
                          totalItemsCount={10}
                          pageRangeDisplayed={3}
                          onChange={data.handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CashIssuedDetailsPage;
