import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class Menu extends Component {
    render() {
        return (
            <ul className="nav nav-tabs">
                <li><NavLink to="/administration/travel/contracts">Hotel Contracts</NavLink></li>
                <li><NavLink to="/administration/travel/car-contracts">Car Contracts</NavLink></li>
            </ul>
        )
    }
}
export default Menu