import React from 'react';
import $ from 'jquery';


class CompanyNoteModal extends React.Component {

  convertStringToHTML = (message) => {
    document.getElementById('message').innerHTML = '';
    var $log = $("#message"),
      html = $.parseHTML(message)
    $log.append(html);
  }

  render() {
    return (
      <div
        className="modal fade"
        id="companyNoteModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="companyNoteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="companyNoteModalLabel">
                {this.props.heading}
              </h5>
            </div>
            <div className="modal-middle pm-30" id="note">
              {this.props.message && this.convertStringToHTML(this.props.message)}
              {/* {this.props.message && (this.props.message)} */}
              <div id="message"></div>
              <div className="mt-4 modal-last btn-group-margin">
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                >
                  Close
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CompanyNoteModal;