import React from 'react';

export default function TrainDetailLoader() {
    const createHtml = (msg) => {
    //  dangerouslySetInnerHTML={createHtml(props.msg)}> add in the call
    return {__html: msg};
  }
  return (
    <div className="text-center p-5 m-5">
        <div className="top-100">
            <div className="mt-5 form-holder pt-3">
            <div className="loader-css"></div>
            <p></p>
            <h5 className="fw-sbold pb-5">
              Please wait while we search for the best trains 
              based on your company policy.
            </h5>
            </div>
        </div>
    </div>
  )
}
