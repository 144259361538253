import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Header from '../common/header';
class FAQComponent extends Component {
    constructor(props) {
        super(props);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    render() {
        return (
            <div>
                {/* <Header
                /> */}
                <div className="page-wrapper help-page">
                    <div className="bg-image bg-image4 ">
                    </div>
                    <div className="top__margin">
                        <div className="mark1 text-center mb-4">
                            {/* <div className="round-mark">
                                <i className="material-icons">live_help</i>
                                <img src="/assets/images/help/help.svg" />
                            </div> */}
                            <h4 className="text-white pt-2 text-black">FAQ</h4>
                            <p>Here are a few of most frequently asked question from our users.</p>
                        </div>
                        <div className="px-padding">
                            <div className="border-light mb-5">
                                {/* <div className="legal-heading bg-white rounded-top">
                                    <h4 className="text-primary mb-1">Frequently Asked Questions</h4>
                                    <p className="mb-0">Here are a few of most frequently asked question from our users.</p>
                                </div> */}
                                <div className="form-holder rounded pm-30">
                                    <div className="mb-3" id="accordionExample">
                                        <div className="" id="headingOne">
                                            <h6 className="heading font-weight-bold cursor-pointer p-0 py-3 border-0 "
                                                data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <span><i className="material-icons">
                                                    keyboard_arrow_down
                                            </i></span>
                                                The Truth about Finding the Lowest Airfare!</h6>
                                        </div>
                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div className="card-body p-0">
                                                <p>Sometimes employees think they have found a lower fare than the one that your travel department or TMC (Travel Management Company) has quoted. When they tell you this, you begin to question whether you are, in fact, getting “the best fares.” By comparison, have they ever told you how many times they have searched and searched and the airfare was the same, or higher than what was found with your companyâ€™s travel department, TMC, or online booking tool? Probably not.</p>
                                                <p>The truth is that there is no one website or travel agency that has the “lowest fares.” If there were then all of the other sites and agencies would be out of business.</p>
                                                <p>Research has shown that booking through Travel Management Companies (TMCs) offers tangible benefits in terms of savings, service and security.* So what is behind those “lower airfares” that your employees find on the internet?</p>
                                                <p> Time: It takes a long time to plan a business trip, especially if you are being price sensitive. Shopping and comparing fares online can take valuable work time away from your employees. Is this how you want them to spend their time?</p>
                                                <p>Comparing Apples to Apples: Many times when people spend the time to find a lower airfare outside of your travel program it is not for the same exact itinerary that is being booked through your agent or online booking tool. As you read this white paper, you will understand the dynamic nature of airfares and see the importance of comparing apples to apples when it comes to examining itineraries.</p>

                                                <p>
                                                    Finding a fare and booking a fare can be two different things: Each online travel site functions differently. Just seeing a fare quoted online doesn’t mean that it is available. You don’t know what the airfare will actually be unless you book it, and with most public sites, you usually can’t book it without purchasing it (i.e. giving them your credit card). By the time you’ve gotten that far, they tell you that the fare that you were drawn to isn’t available for the flights that you’ve chosen.
                                                </p>
                                                <p>What your travel department or CURIO can do: When an employee believes that there is a lower airfare available encourage them to bring it to the attention of the t</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3" id="accordionExample">
                                        <div className="" id="heading2">
                                            <h6 className="heading font-weight-bold cursor-pointer p-0 py-3 border-0 "
                                                data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                                <span><i className="material-icons">
                                                    keyboard_arrow_down
                                            </i></span>Understanding Airfares</h6>
                                        </div>
                                        <div id="collapse2" className="collapse show" aria-labelledby="heading2" data-parent="#accordionExample">
                                            <div className="card-body p-0">
                                                <p>
                                                    Yield Management: It’s all about supply and demand. Airlines utilize sophisticated systems to monitor the supply and demand for seats on each flight in order to maximize their revenue. They also monitor what their competition is offering. Their aim is to fill every seat at the highest possible yield and therefore dynamically change the number of seats available for each fare type. Because airlines have numerous fare levels on a single flight, and then change the number of seats allocated for each fare level several times prior to departure, the availability seen via online booking tools or onscreen by your travel counselor can literally change within seconds.
                                            </p>
                                                <p>
                                                    The Fine Print: Many of the very cheap fares available on public/airline websites are designed for the leisure traveler and are not always conducive to business travel, or may not be in line with your corporate travel policy (i.e. nonrefundable seats or penalties for changes, restricted cancellation policies, requirements for a certain number of nights away, departure on a certain day, travel to include a Saturday night away, connecting rather than direct flights, etc.). Depending on your business commitments these may not suit your travel and won’t have been offered by your CURIO Expert, or may have been blocked in CURIO online booking tool.
                                             </p>
                                                <p>
                                                    Lead-in Fares: Sometimes the fares displayed on website homepages or travel agency shop windows are called lead-in fares: these are subject-to-availability marketing tools used to attract a traveler’s attention and are not actually available at the time of booking due to nominal allocation.
                                             </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3" id="accordionExample">
                                        <div className="" id="heading3">
                                            <h6 className="heading font-weight-bold p-0 cursor-pointer py-3 border-0 "
                                                data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                                <span><i className="material-icons">
                                                    keyboard_arrow_down
                                            </i></span>
                                                How Airfare Pricing Works</h6>
                                        </div>
                                        <div id="collapse3" className="collapse show" aria-labelledby="heading3" data-parent="#accordionExample">
                                            <div className="card-body p-0">
                                                <p>
                                                    Each day an airline is constantly making changes to their fares and rules. There are millions of fare and rule changes made each day.
                                                    </p>
                                                <p>
                                                    Approximately 90% of worldwide commercial airfare is distributed through a clearinghouse in Washington, D.C. called ATPCO (Airline Tariff Publishing Company). ATPCO is owned by over 20 airlines and holds and distributes (to a select few subscribers, including Hurley Travel Experts) airfares for over 500 airlines worldwide. Airlines participate in ATPCO to have a common format and distribution point for their airfares.
                                                    </p>
                                                <p>
                                                    Tickets are sold for a given airline through a variety of distribution channels including airline websites and call centers, travel agencies (leisure/business/online/offline), packaging and tour operators, airline partners, affinity group agencies (students, missionaries, alliances), and consolidators. Tickets for a given airline may be sold by thousands of different travel companies worldwide.
                                                    </p>
                                                <p>
                                                    Millions of fare and rule changes are made each day. Hundreds of thousands of fare price and rule changes flow continuously into ATPCO each day. In turn, fares are distributed at specified times later the same day to a handful of subscribers worldwide that then provide the data to reservation systems, including the airlines themselves.
                                                    </p>
                                                <p>
                                                    Once distributed, each vendor updates databases at different intervals: 2-4 hours for domestic fares, and 4-8 hours for international fares. The domestic 8 PM airfare feed is not loaded until after midnight on reservation systems. This explains why fares may vary by website and are not always consistent across the board.
                                                    </p>
                                                <p>
                                                    Airfares are Dynamic - Not Static! New distributions from the airlines don’t always mean lower airfares. The new data can be a higher fare, changes in the fare rules which may make the fare more or less restrictive, or a cancellation to a previously filed discounted airfare.
                                                    </p>
                                                <p>
                                                    Why Quoted Prices Change: Airlines, like any other business, want to maximize profits on each and every seat they sell, which is why you see so many daily airfare changes; they want to see how much YOU are willing to pay. Of course, this makes finding the cheapest airfare deal look a lot like rocket science, or at the very least look like following stock prices at any given moment in time.
                                                    </p>
                                                <p>
                                                    Why Cheap Airline Tickets are Elusive: Look at it this way: 100% of all air travelers want the cheapest price, but the reality is only about 10% will get that cheapest price. When an airline has a sale, they don’t put every seat on a plane on sale.
                                                   </p>
                                                <p>
                                                    How to Get a Cheap Airline Ticket: The key to being part of that lucky 10% is technology and flexibility. If you can be flexible on your itinerary and available to fly when prices are lowest you can get the best deals. This is rarely an option for corporate travelers who are at the mercy of their clients to travel at a certain time. Hurley Travel Experts will always research lowest logical airfares within a company’s travel policy to try and secure the lowest ticket price possible that does not jeopardize the integrity of the trip.
                                                    </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3" id="accordionExample">
                                        <div className="" id="heading4">
                                            <h6 className="heading font-weight-bold cursor-pointer p-0 py-3 border-0 "
                                                data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                                                <span><i className="material-icons">
                                                    keyboard_arrow_down
                                            </i></span>
                                                Some Important Factors need to be Considered before booking outside CURIO?</h6>
                                        </div>
                                        <div id="collapse4" className="collapse show" aria-labelledby="heading4" data-parent="#accordionExample">
                                            <div className="card-body p-0">
                                                <p>
                                                    Your company’s travel policy: When booking within your travel program, CURIO online booking tool works in compliance with your company’s travel policy. CURIO flag as either in or out of policy help to ensure that you have the information and to remain compliant. Public sites do not have such restrictions, and companies have no control over what the employee books.
                                                    </p>
                                                <p>
                                                    Well-intentioned employees trying to save money by booking online can cost you thousands of dollars: How much will it cost you if you don’t meet the requirements of your preferred vendor agreements, or lose the ability to negotiate future discounts? The difference is a lot more than the relatively few dollars saved by “booking on your own.”
                                                    </p>
                                                <p>
                                                    Diluting Your Negotiating Power: Trip data for travel not booked through CURIO online booking tool will not be included in your companyâ€™s consolidated data reporting, thus diluting your negotiating power with vendors in the future.
                                                    </p>
                                                <p>
                                                    “Duty of Care”: When employees book outside of their organization’s travel program there are security risks that must be considered. The employee’s travel information is not included in the CURIO data and therefore will not show up in any traveler tracking products utilized in the event of an emergency, world event, or weather disaster.
                                                    </p>
                                                <p>
                                                    Cost Savings through Voids: CURIO Experts has a 24-hour window of time to submit payment to most airline carriers after ticketing is complete. This allows us to “void” a transaction within a 24-hour time frame if your plans change. We save our clients thousands of dollars in change fees and whole ticket costs each year. When booking directly with an airline, or with online sites such as MMT , Cleartrip and Expedia tickets are instantly purchased and are subject to non-refundable rules and expensive change fees which drive up bottom line costs substantially.
                                                    </p>
                                                <p>
                                                    24/7/365 Traveler Emergency Services: When a traveler is stuck it is all too easy to break out the company credit card and pay whatever is asked to get to where they need to be. CURIO after-hours emergency call to CURIO Experts is a much less expensive option than booking another flight or car when in panic mode. This is an area of huge financial loss for companies due to non-management of costs associated with airline and routing changes, not to mention the stress your traveler undergoes when on their own.
                                                    </p>
                                                <p>
                                                    Traveler support: When an employee books airfare online for a lower fare the traveler is alone in dealing with any traveler emergencies, changes to itineraries, or failure of a supplier to provide the contracted service. The traveler is on his own for refunds, exchanges, credits, etc. In addition, this data is not included in your organization’s reporting for tracking unused electronic tickets and reusing them for future travel.
                                                    </p>
                                                <p>
                                                    Negates efficiencies of end-to-end solutions: Organizations are utilizing end-to-end solutions that not only help in efficiencies of process, but ensure policy compliance throughout the life cycle of the trip. From pre-trip to post-trip, expense reporting, auditing, and reimbursement processes, allowing travelers to go outside of the company’s travel program negates the goals of these solutions.
                                                    </p>
                                                <p>
                                                    Keeping the big picture in mind: A company cannot afford to run their travel program around a few tickets that someone says they can get cheaper someplace else.
                                                    </p>
                                                <p>
                                                    Resisting a managed program: Sometimes showing that they can get better pricing online is an employee’s way of resisting a managed travel program. Often employees are enrolled in frequent rewards programs and these may conflict with your organization’s preferred vendor programs. By effectively communicating your company’s Travel Policy to your employees, you will have a much higher adoption rate, not to mention happier travelers!
                                                        </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FAQComponent;