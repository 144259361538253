import { http } from "../_helpers";
import { config } from "../env";

const getServiceModuleId = () => {
  return http.get(config.apiUrlCompany + `/service-module`).then(data => {
    return data;
  });
};

const getAllEmailReminders = (serviceModuleId, companyId, page, limit) => {
  return http.get(config.apiUrlCompany +
        `email-remainder/list-all?serviceModuleId=${serviceModuleId}&companyId=${companyId}&page=${page}&limit=${limit}`)
    .then(data => {
      return data;
    });
};

const getFilterEmailTemplates = (serviceModuleId, companyId, page, limit, search) => {
  return http.get(config.apiUrlCompany +
        `email-remainder/list-all?serviceModuleId=${serviceModuleId}&companyId=${companyId}&page=${page}&limit=${limit}&search=${search}`)
    .then(data => {
      return data;
    });
};

const getEmailReminderDeatilsById = emailtemplateId => {
  return http.get(config.apiUrlCompany + `email-remainder/${emailtemplateId}`)
    .then(data => {
      return data;
    });
};

const editEmailTemplate = (emailtemplateId, data) => {
  return http.patch(config.apiUrlCompany + `email-remainder/${emailtemplateId}`, data)
    .then(data => {
      return data;
    });
};

const activeInactiveStatus = (id, payload) => {
  return http.patch(config.apiUrlCompany + `email-remainder/${id}`, payload)
    .then(data => {
      return data;
    });
};


export const companyEmailReminderServices = {
  getServiceModuleId,
  getAllEmailReminders,
  getEmailReminderDeatilsById,
  editEmailTemplate,
  activeInactiveStatus,
  getFilterEmailTemplates
};
