import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class ExpenseDetailsForCarMilagePage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <div className="expense-left">
                    <div className="padx pady border-b">
                        <div className="row">
                            <div className="col-md-4 text-right">
                                <h6>2</h6>
                            </div>
                        </div>
                    </div>
                    <div className="scroll">
                        <ul>
                            <li>
                                <div className="top">
                                    {/* <div className="right-side">
                                      <div className="col-md-8">
                                <h6>Expense</h6>
                            </div>
                              <i className="material-icons">info</i>
                                    </div> */}
                                    <div className="shape-name lg">
                                        <div className="left icon bg-success">
                                        <i className="material-icons">directions_car</i>
                                        </div>
                                        <div className="right">
                                            <h6 className="heading">Car Rental</h6>
                                            <p className="text">18th Dec 2018</p>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="bottom">
                                    <div className="right-side text-left">
                                        <p className="label">Requested</p>
                                        <h6>$500</h6>
                                    </div>
                                    <div>
                                        <p className="label">Amount</p>
                                        <h6>$500</h6>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <NavLink to="/trips-approve/expense-details-for-trips">
                                <div className="top">
                                    <div className="shape-name lg">
                                        <div className="left icon bg-secondary">
                                        <i className="material-icons">local_pizza</i>
                                        </div>
                                        <div className="right">
                                            <h6 className="heading">Business Lunch And Dinner</h6>
                                            <p className="text">18th Dec 2018</p>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="bottom">
                                    <div className="right-side">
                                        <p className="label">Requested</p>
                                        <h6>$300</h6>
                                    </div>
                                    <div>
                                        <p className="label">Amount</p>
                                        <h6>$500</h6>
                                    </div>
                                </div>
                                </NavLink>
                            </li>
                            <li className="active">
                                <div className="top">
                                    {/* <div className="right-side">
                                        <i className="material-icons">info</i>
                                    </div> */}
                                    <div className="shape-name lg">
                                        <div className="left icon bg-success">
                                        <i className="material-icons">directions_car</i>
                                        </div>
                                        <div className="right">
                                            <h6 className="heading">Company Car Milage</h6>
                                            <p className="text">18th Dec 2018</p>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="bottom">
                                    <div className="right-side text-left">
                                        <p className="label">Requested</p>
                                        <h6>$500</h6>
                                    </div>
                                    <div>
                                        <p className="label">Amount</p>
                                        <h6>$500</h6>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <ul className="bottom-amount">
                        <li>
                            <h6>$100</h6>
                            <p>Outstanding Advance</p>
                        </li>
                        <li>
                            <h6>$400</h6>
                            <p>Total <br />Amount</p>
                        </li>
                        <li>
                            <h6>$500</h6>
                            <p>Total Requested</p>
                        </li>
                    </ul>
                </div>
                <div className="expense-right">

                    <div className="p-padding">
                        <div id="expense-report">
                            <div className="white-card shadow-light report">
                                <div className="grey-head border">
                                    <h6 className="sub-heading">REPORT HEADER</h6>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 minus-mr">
                                        <div className="top mt-3 ml-3 minus-mr">
                                            <div className="shape-name lg">
                                                <div className="left icon bg-secondary">
                                                <i className="material-icons">directions_car</i>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9 minus-ml">
                                            <div className="p-2">
                                                <table className="table border-0 mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td className="border-0 fs-small"><b className="mb-1">EXPENSE TYPE</b><br /><h5>Breakfast</h5></td>
                                                            <td className="border-0 fs-small"><b className="mb-1">TRANSACTION DATE</b> <br /><h5>14/12/2018</h5></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0 fs-small"><b className="mb-1">BUSINESS PURPOSE</b><br /><h5>Sales Meeting</h5></td>
                                                            <td className="border-0 fs-small"><b className="mb-1">PAYMENT TYPE</b><br /> <h5>Cash</h5></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="border-0 fs-small"><b className="mb-1">VENDOR</b><br /><h5>Zomato</h5></td>
                                                            <td className="border-0">
                                                                <b className="mb-1">AMOUNT</b><br /> <h6>$182.83/ Rs.18,000</h6>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="3" className="border-0 fs-small"><b className="mb-1">CITY</b><br /><h5>New York</h5></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>
                            </div>
                            <div className="add-margin-bottom">
                                <div className="card switch-panel mb-0 px-3">
                                    <label data-toggle="collapse" data-target="#demo">
                                        <div className="float-left">
                                            Details
                                        </div>
                                        <div className="float-right">
                                        <i className="material-icons">arrow_drop_down</i>
                                        </div>
                                        <div className="clearfix"></div>
                                    </label>
                                </div>
                                <div id="demo" className="collapse fade-in bg-white">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="float-left">
                                                <div className="top mt-3 ml-3 minus-mr">
                                                    <div className="shape-name lg">
                                                        <div className="left icon bg-secondary">
                                                        <i className="material-icons">location_on</i>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="float-left ml-3">
                                            <div className="p-2">
                                                <table className="table border-0 mb-0">
                                                    <tbody>
                                                    <tr>
                                                        <td className="border-0 fs-small">
                                                            <b>FROM LOCATION</b>
                                                            <h5>#93 Dollers Colony, New Bell Road, Bangalore</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border-0 fs-small">
                                                            <b>TO LOCATION</b>
                                                            <h5>Bangalore Baptist Hospital, Bangalore</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border-0 fs-small">
                                                            <b>TOTAL DISCTANCE</b>
                                                            <h5>7KM</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border-0 fs-small">
                                                            <b>TRANSACTION AMOUNT</b>
                                                            <h5>USD 2/KM</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border-0 fs-small">
                                                            <b>VEHICLE NUMBER</b>
                                                            <h5>KA82 883</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border-0 fs-small">
                                                           <b>ODOMETER START</b>
                                                            <h5>12342</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border-0 fs-small">
                                                            <p className="mb-1"><b>ODOMETER END</b></p>
                                                            <h5>12352</h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border-0 fs-small">
                                                            <p className="mb-1"><b>PAYMENT TYPE</b></p>
                                                            <h5>Out of Pocket</h5>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="col-md-6">
                                            <iframe className="border-0"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.0676403140724!2d77.58276646413574!3d13.03136456709153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17bf7de0904b%3A0x937a4a11abb64022!2sKundamalli%2C+SBM+Colony%2C+Sanjaynagar%2C+Bengaluru%2C+Karnataka+560024!5e0!3m2!1sen!2sin!4v1556702137143!5m2!1sen!2sin"
                                                width="100%" height="100%"
                                                allowFullScreen/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ExpenseDetailsForCarMilagePage