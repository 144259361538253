import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom"; 
import UserManagementPage from './user-management';
import AddUserPage from '../add-user/add-user';
import PageNotFound from '../../../../page-not-found/page-not-found-html';
class ManagementHomePage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/administration/company/management') {
            this.props.history.push('/administration/company/management/list')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/administration/company/management') {
            this.props.history.push('/administration/company/management/list')
        }
    }
    render() {
        return (
            <div>
                <Switch>
                    <Route path='/administration/company/management/list' component={UserManagementPage} />
                    <Route path='/administration/company/management/add-user' component={AddUserPage} />
                    <Route path='/administration/company/management/edit-user/:id' component={AddUserPage} />
                    <Route path="**" component={PageNotFound} />
                </Switch>
            </div>
        )
    }
}
export default ManagementHomePage;