import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class Menu extends Component {
  render() {
    return (
      <div>
        <ul className="nav nav-tabs">
          <li>
            <NavLink to="/administration/expense/car-config" className="show">
              Configuration
            </NavLink>
          </li>
          <li>
            <NavLink to="#" className="show">
              Configuration Sets
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}
export default Menu;
