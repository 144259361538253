import React from "react";
import AllocationDynamicForm from './allocationDynamicForm';
const expenseAllocationTable = props => {
  let tableHead = [];
  tableHead = props.tableHead && props.tableHead.filter((form) => form.activeStatus == true);
  return (
    <div className="table-responsive" style={{ overflowX: "unset" }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th width="5%"></th>
            <th>
            </th>
            {/* {tableHead && tableHead.map((form, index) =>
              <th key={index} className="border-0" width="25%">{form.labelName}</th>
            )} */}
          </tr>
        </thead>
        <tbody>
          {props.expenseAllocationForm && props.expenseAllocationForm.map((forms, index) => {
            return (
              <tr key={index} className="bg-white">
                <td className="">
                  <label className="checkbox-container h-20 m-0">
                    <input type="checkbox"
                      checked={props.selectedIndex.includes(index) ? props.selectedIndex.includes(index) : false}
                      onChange={(e) => props.onSelectedAllocationFieldHandler(index)}
                    />
                    <span className="checkmark" />
                  </label>
                </td>
                <td>
                  <div className="row">
                    <div className="col-md-4 my-3">
                      <div className="form-group custom mb-0">
                        <input
                          value={props['allocamount' + index] ? props['allocamount' + index] : ''}
                          onChange={(e) => props.onAmountPercentageChangeHandler(e, 'allocamount' + index, index)}
                          type="text" placeholder="&nbsp;" className="form-control" />
                        <label>{props.allocatedIn ? props.allocatedIn : ''}</label>
                        <div className="help-block">
                          {props.validator.message(
                            'allocated in',
                            props['allocamount' + index],
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                    <AllocationDynamicForm
                      {...props}
                      validator={props.validator}
                      onClickHandler={props.onClickHandler}
                      onChangeHandler={props.onChangeHandler}
                      onDatePickerHandler={props.onDatePickerHandler}
                      onCheckboxHandler={props.onCheckboxHandler}
                      onIntegerHandler={props.onIntegerHandler}
                      allocationForm={forms}
                      rowIndex={index}
                      onLocationChangekHandler={props.onLocationChangekHandler}
                      onSearchTextHandler={props.onSearchTextHandler}
                    />
                  </div>
                </td>
              </tr>

            )
          })}
        </tbody>
      </table>
    </div>
  );
};
export default expenseAllocationTable;
