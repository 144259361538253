import React from "react";
import { NavLink } from "react-router-dom";
const TaxAuthorityStep1Page = props => {
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">W</div>
          <div className="right">
            <h6 className="heading">Add New Tax Authority </h6>
            <p className="text">Has been the industry's standard dumm</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="audit-step">
            <NavLink
              className="s1"
              to="/administration/expense/tax-authority-step1"
            >
              <span>1</span>
              General
            </NavLink>
            <NavLink
              className="s1"
              to="/administration/expense/tax-authority-step2"
            >
              <span>2</span>
              Tax Rate Types
            </NavLink>
            <NavLink
              className="s1"
              to="/administration/expense/tax-authority-step3"
            >
              <span>3</span>
              Tax Rates
            </NavLink>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-5">
                <form>
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                    />
                    <label>Tax Authority Name</label>
                  </div>
                  <div className="form-group custom labelup">
                    <label>Country</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Germany
                      </button>
                      <div className="dropdown-menu">
                        <button type="button" className="dropdown-item">
                          Save
                        </button>
                        <button type="button" className="dropdown-item">
                          Lorem Ipsum
                        </button>
                        <button type="button" className="dropdown-item">
                          Lorem Ipsum
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group custom labelup">
                    <label>State/Province</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Berlin
                      </button>
                      <div className="dropdown-menu">
                        <button type="button" className="dropdown-item">
                          Save
                        </button>
                        <button type="button" className="dropdown-item">
                          Lorem Ipsum
                        </button>
                        <button type="button" className="dropdown-item">
                          Lorem Ipsum
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                    />
                    <label>Tax Name</label>
                  </div>
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                    />
                    <label>Non Domestic Code</label>
                  </div>
                  <div className="form-group custom labelup">
                    <label>Credit Card Tax Fixed</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        None
                      </button>
                      <div className="dropdown-menu">
                        <button type="button" className="dropdown-item">
                          Save
                        </button>
                        <button type="button" className="dropdown-item">
                          Lorem Ipsum
                        </button>
                        <button type="button" className="dropdown-item">
                          Lorem Ipsum
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="btn-group-margin">
                    <NavLink
                      to="/administration/expense/tax-authority-step2"
                      className="btn btn-border-success lg-padding"
                    >
                      Next
                    </NavLink>
                    <button className="btn btn-border-basic lg-padding">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaxAuthorityStep1Page;
