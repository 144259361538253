import React from "react";
import EditAllocationDynamicForm from './editAllocationDynamicForm';
const editExpenseAllocationTable = props => {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th width="5%"></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.editAllocationForm && props.editAllocationForm.map((forms, index) => {
          return (
            <tr key={index} className="bg-white1">
              <td>
                <label className="checkbox-container pb-4">
                  <input type="checkbox"
                    checked={props.selectedIndex.includes(index) ? props.selectedIndex.includes(index) : false}
                    onChange={(e) => props.onEditSelectedAllocationFieldHandler(index, props.allocationEditIds[index])}
                  />
                  <span className="checkmark" />
                </label>
              </td>
              <td>
                <div className="row">
                  <div className="col-md-4 my-3">
                    <div className="form-group custom mb-0">
                      <input
                        value={
                          props["amount" + index]
                            ? props["amount" + index]
                            : ""
                        }
                        onChange={e =>
                          props.onAmountPercentageChangeHandler(
                            e,
                            "amount" + index,
                            index
                          )
                        }
                        type="text" placeholder="&nbsp;" className="form-control" />
                      <label>{props.allocatedIn ? props.allocatedIn : ''}</label>
                      <div className="help-block">
                        {props.validator1.message(
                          'amount',
                          props['amount' + index],
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                  <EditAllocationDynamicForm
                    {...props}
                    validator={props.validator1}
                    onClickHandler={props.onClickHandler}
                    onChangeHandler={props.onChangeHandler}
                    onDatePickerHandler={props.onDatePickerHandler}
                    onCheckboxHandler={props.onCheckboxHandler}
                    onIntegerHandler={props.onIntegerHandler}
                    allocationForm={forms}
                    rowIndex={index}
                    onLocationChangekHandler={props.onLocationChangekHandler}
                    loadOptions={props.loadOptions}
                  />
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  );
};
export default editExpenseAllocationTable;
