import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { cartServices } from "src/_services";
import { FlightCartItem } from "./item/flightItem";
import FlightCartDetail from "./item/flightDetail";
import HotelCartDetail from './item/hotelDetail';
import TrainCartDetail from './item/trainDetail';
import FlightComboCartItem from './item/comboFlightItem';
import { HotelCartItem } from "./item/hotelItem";
import { TrainCartItem } from "./item/trainItem";
import AddNewModifyServicePage from "../checkout/AddNewService";
import { inject } from "mobx-react";
import { MenuCartSubPage } from "./MenuCartSubmit";
import FlightCartComboDetail from "./item/comboFlightDetail";
import { getValue, setValue } from "src/_components/common/lodsh";
import CabCartItem from "./item/cabItem";
import CabCartDetail from "./item/cabCartDetails";
@inject("serviceStore")

class ItineraryPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getList();
    this.getDeatail();
    if (sessionStorage.getItem('TRIPID') == null) {
      this.props.history.push('/booking/home')
    }
    this.state = {
      loading: true,
      allowExpense: false,
      nextButtonVisibility: true,
      baseCurrency: '',
      selectedFlightType: '',
      tripID: sessionStorage.getItem('TRIPID')
    }
  }
  componentWillReceiveProps(nextState) {
    sessionStorage.setItem('TRIPID', this.state.tripID);
  }
  componentWillMount() {
    sessionStorage.setItem('TRIPID', this.state.tripID);
    sessionStorage.removeItem("tripType");
  }
  cartDetails = [];
  getDeatail = async () => {
    let tripID = sessionStorage.getItem('TRIPID');
    var resp = await cartServices.getCartUserList(tripID);
    if (resp) {
      // console.log(resp)
      sessionStorage.setItem('TRIPID', this.state.tripID);
      this.cartDetails = resp;
      if (this.cartDetails.flightData === 0) {
        sessionStorage.removeItem('tripType')
      }
      if (this.cartDetails.POLICY_ID) {
        this.setState({
          allowExpense: true
        })
      }
      this.setState({ ...this.state })
    }
  };
  checkItem = async () => {
    var resp = await cartServices.getCartDataExpense();
  };
  cartList = [];
  getList = async () => {
    let tripID = sessionStorage.getItem('TRIPID');
    var resp = await cartServices.getCartListDetail(tripID);
    if (resp) {
      sessionStorage.setItem('TRIPID', this.state.tripID);
      this.cartList = resp;
      this.setState({ baseCurrency: resp.BASE_CURRENCY })
      if (this.cartList && this.cartList.data.flightData.length !== 0) {
        let selectedFlightType = this.cartList.data.flightData[0].tripType;
        selectedFlightType = selectedFlightType === 1 ? 'oneway' : selectedFlightType === 2 ? 'round' : 'multi';
        this.setState({ selectedFlightType: selectedFlightType })
        if (this.cartList.data.flightData[0].fare) {
          this.chooseFlight(this.cartList.data.flightData[0], 'combo', `flight${0}`)
        } else {
          this.chooseFlight(this.cartList.data.flightData[0], 'flight', `flight${0}`)
        }
      } else if (this.cartList && this.cartList.data.hotelData.length !== 0) {
        this.chooseHotel(this.cartList.data.hotelData[0], "hotel", `hotel${0}`)
      } else if (this.cartList && this.cartList.data.carData.length !== 0) {
        this.chooseCab(this.cartList.data.carData[0], "cab", `cab${0}`)
      } else if (this.cartList && this.cartList.data.trainData.length !== 0) {
        this.chooseTrain(this.cartList.data.trainData[0], "train", `train${0}`)
      }
      sessionStorage.setItem('cartId', resp.id)
    }
    this.setState({
      loading: false
    })
  };
  chooseService = async e => {
    var resp = await this.props.serviceStore.getServiceType(e);
    this.setState({ ...this.state })
  };
  removeFlightCart = async (e) => {
    var req = {
      type: "flight",
      TRIP_ID: sessionStorage.getItem("TRIPID"),
      flight_fare_id: e
    }
    var resp = await cartServices.removeCartUserData(req);
    if (resp) {
      this.type = "";
      this.flightDetailView = [];
      this.selectedItem = "";
      this.getList();
      this.getDeatail()
    }
  }
  removeHotelCart = async (e) => {
    var req = {
      type: "hotel",
      TRIP_ID: sessionStorage.getItem("TRIPID"),
      room_id: e
    }
    var resp = await cartServices.removeCartUserData(req);
    if (resp) {
      this.type = "";
      this.hotelDetailView = [];
      this.selectedItem = "";
      this.getList();
      this.getDeatail()
    }
  }
  removeCab = async (e) => {
    var req = {
      type: "cab",
      TRIP_ID: sessionStorage.getItem("TRIPID"),
      room_id: e
    }
    var resp = await cartServices.removeCartUserData(req);
    if (resp) {
      this.type = "";
      this.CabDetailView = [];
      this.selectedItem = "";
      this.getList();
      this.getDeatail()
    }
  }
  removeTrain = async (e) => {
    var req = {
      type: "train",
      TRIP_ID: sessionStorage.getItem("TRIPID"),
      train_uniq_id: e
    }
    var resp = await cartServices.removeCartUserData(req);
    if (resp) {
      this.type = "";
      this.trainDetailView = [];
      this.selectedItem = "";
      this.getList();
      this.getDeatail()
    }
  }
  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " m " : " m ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  type = "";
  flightDetailView = [];
  selectedItem = "";
  chooseFlight = (e, type, key) => {
    this.type = type;
    this.flightDetailView = e;
    if (this.selectedItem === key) {
      this.selectedItem = "";
      this.flightDetailView = "";
      this.type = "";
    } else {
      this.selectedItem = key;
    }
    this.setState({ ...this.state })
  };
  hotelDetailView = [];
  chooseHotel = (e, type, key) => {
    this.type = type;
    this.hotelDetailView = e;
    if (this.selectedItem === key) {
      this.selectedItem = "";
      this.hotelDetailView = "";
      this.type = "";
    } else {
      this.selectedItem = key;
    }
    this.setState({ ...this.state })
  };
  CabDetailView = [];
  chooseCab = (e, type, key) => {
    this.type = type;
    this.CabDetailView = e;
    if (this.selectedItem === key) {
      this.selectedItem = "";
      this.CabDetailView = "";
      this.type = "";
    } else {
      this.selectedItem = key;
    }
    this.setState({ ...this.state })
  }
  trainDetailView = [];
  chooseTrain = (e, type, key) => {
    this.type = type;
    this.trainDetailView = e;
    if (this.selectedItem === key) {
      this.selectedItem = "";
      this.trainDetailView = "";
      this.type = "";
    } else {
      this.selectedItem = key;
    }
    this.setState({ ...this.state })
  }
  render() {
    return (
      <div>
        {this.cartDetails && <MenuCartSubPage nextButtonVisibility={this.state.nextButtonVisibility} checkItem={this.checkItem} allowToExpenseTab={this.state.allowExpense} submit={false} next="/booking/checkout/travel-request-details" cartList={this.cartDetails} />}
        <div className="p-padding top-48 pb-0">
          {this.state.loading &&
            <div className="text-center p-5 m-5">
              <div className="form-holder pt-3">
                <div className="loader-css"></div>
                <h5 className="fw-sbold pb-5">
                  Please wait ...
                        </h5>
              </div>
            </div>
          }
          <div className="row">
            <div className="col-md-6">
              <div className="itinerary-scroll">
                {this.state.loading ? "" :
                  <div className="white-card">
                    <div className="pad">
                      <h6 className="sub-heading">Add Additional Service</h6>
                      <ul className="service">
                        <li
                          onClick={e => this.chooseService("flight")}
                          data-target="#modifyModalNew"
                          data-toggle="modal"
                        >
                          <img
                            width="50"
                            height="50"
                            src="/assets/images/booking/flight_booking.svg"
                            alt="Flight"
                          />
                          <p>
                            <NavLink to="#">Add <span className="resp-1025">Flights</span></NavLink>
                          </p>
                        </li>
                        <li
                          onClick={e => this.chooseService("hotel")}
                          data-target="#modifyModalNew"
                          data-toggle="modal"
                        >
                          <img
                            width="50"
                            height="50"
                            src="/assets/images/booking/hotel_booking.svg"
                            alt="Hotel"
                          />
                          <p>
                            <NavLink to="#">Add <span className="resp-1025">Hotels</span></NavLink>
                          </p>
                        </li>
                        <li
                          onClick={e => this.chooseService("cab")}
                          data-target="#modifyModalNew"
                          data-toggle="modal">
                          <img
                            width="50"
                            height="50"
                            src="/assets/images/booking/car_booking.svg"
                            alt="Cab"
                          />
                          <p>
                            <NavLink to="#">Add <span className="resp-1025">Car</span></NavLink>
                          </p>
                        </li>
                        {/* <li>
                          <img
                            width="50"
                            height="50"
                            src="/assets/images/booking/bus_booking.svg"
                            alt="Bus"
                          />
                          <p>
                            <NavLink to="#">Add <span className="resp-1025">Bus</span></NavLink>
                          </p>
                        </li>*/}
                        <li
                          onClick={e => this.chooseService("train")}
                          data-target="#modifyModalNew"
                          data-toggle="modal"
                        >
                          <img
                            width="50"
                            height="50"
                            src="/assets/images/booking/train_booking.svg"
                            alt="Train"
                          />
                          <p>
                            <NavLink to="#">Add <span className="resp-1025">Train</span></NavLink>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                }
                <ul className="travel-tabs">
                  {/* Flight Config Starts */}
                  {this.cartList &&
                    this.cartList.data &&
                    this.cartList.data.flightData &&
                    this.cartList.data.flightData.map((list, key) => {
                      if (list.fare) {
                        return (
                          <FlightComboCartItem
                            chooseFlight={this.chooseFlight}
                            index={key}
                            selectedItem={this.selectedItem}
                            secondsToHms={this.secondsToHms}
                            key={key}
                            item={list}
                            removeCart={this.removeFlightCart}
                            baseCurrency={this.state.baseCurrency}
                          />
                        )
                      } else {
                        return (
                          <FlightCartItem
                            chooseFlight={this.chooseFlight}
                            index={key}
                            selectedItem={this.selectedItem}
                            secondsToHms={this.secondsToHms}
                            key={key}
                            item={list}
                            removeCart={this.removeFlightCart}
                            baseCurrency={this.state.baseCurrency}
                          />
                        );
                      }
                    })}
                  {/* Flight Config end */}
                  {/* hotells Config Starts */}
                  {this.cartList &&
                    this.cartList.data &&
                    this.cartList.data.hotelData &&
                    this.cartList.data.hotelData.map((list, key) => {
                      // console.log(list)
                      return (
                        <HotelCartItem
                          chooseHotel={this.chooseHotel}
                          index={key}
                          removeHotelCart={this.removeHotelCart}
                          selectedItem={this.selectedItem}
                          secondsToHms={this.secondsToHms}
                          key={key}
                          item={list}
                          // baseCurrency={list?.hotel_currency_code}
                          baseCurrency={this.state.baseCurrency}
                        />
                      );
                    })}
                  {getValue(this.cartList, "data.carData", []).map((item, key) => (
                    <CabCartItem
                      item={item}
                      chooseCab={this.chooseCab}
                      index={key}
                      baseCurrency={this.state.baseCurrency}
                    />
                  ))}
                  {getValue(this.cartList, "data.trainData", []).map((item, key) => (
                    <TrainCartItem
                      item={item}
                      chooseTrain={this.chooseTrain}
                      removeTrain={this.removeTrain}
                      index={key}
                      baseCurrency={this.state.baseCurrency}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="itinerary-scroll">
                {this.type === "flight" && (
                  <FlightCartDetail
                    secondsToHms={this.secondsToHms}
                    item={this.flightDetailView}
                    baseCurrency={this.state.baseCurrency}
                  />
                )}
                {this.type === "combo" && (
                  <FlightCartComboDetail
                    secondsToHms={this.secondsToHms}
                    item={this.flightDetailView}
                    baseCurrency={this.state.baseCurrency}
                  />
                )}
                {this.type === "hotel" && (
                  <HotelCartDetail
                    secondsToHms={this.secondsToHms}
                    item={this.hotelDetailView}
                    // baseCurrency={this.hotelDetailView?.hotel_currency_code}
                    baseCurrency={this.state.baseCurrency}
                  />
                )}
                {this.type === "cab" && (
                  <CabCartDetail
                    item={this.CabDetailView}
                  />
                )}
                {this.type === "train" && (
                  <TrainCartDetail item={this.trainDetailView} />
                )}
              </div>
            </div>
          </div>
        </div>
        <AddNewModifyServicePage
          selectedFlightType={this.state.selectedFlightType}
          cartCount={1}
        />
      </div>
    );
  }
}
export default ItineraryPage;
