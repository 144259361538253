import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery'; 
class HeadMenu extends Component {

    openDropdown(e) {
        $(e.currentTarget).toggleClass("active");
    }

    render() {
        const { menuItems } = this.props
        return (
            <div >
                {menuItems.children &&
                (menuItems.children[0].menuType === 1)?
                    <div className="admin-tabs">
                        <ul className="nav nav-tabs">
                            {menuItems.children &&
                                menuItems.children.map((item, key) => {
                                    if (item.menuType === 1) {
                                        return (
                                            <li key={key}><NavLink to={item.frontEndUrl} className="show">{item.menuName}</NavLink></li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>:""}
            </div>
        )
    }
}
export default HeadMenu