import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import FullDetailsPage from "../flight/_components/full-details";
import cryptr from "../../../../_helpers/crypty";
import { bookingServices } from "../../../../_services/booking.service";
import AmenitiesPage from "./_components/Amenities";
import { cartServices, masterService } from "src/_services";
import RoomListPage from "./_components/roomList";
import StarRatings from "react-star-ratings";
import GoogleMapReact from "google-map-react";
import AmenitiesRoomPage from './_components/RoomAmenities';
import Select from 'react-select';
import $ from "jquery";
import { toast } from "react-toastify";
import HotelLoader from "./_components/HotelLoader";
import { debounce } from 'lodash';
import { getValue } from "src/_components/common/lodsh";
const BgImageComponent = ({ urlLink }) => (
    <div
        className="hotel-image-holder"
        style={{
            backgroundImage: `url(${urlLink})`
        }}
    />
);
const AnyReactComponent = ({ text }) => (
    <i className="material-icons otle-hotel">hotel</i>
);
class HotelDetailsPage extends Component {
    constructor(props) {
        super(props);
        window.scrollTo({ top: 0, behavior: "smooth" });
        let item = JSON.parse(cryptr.decrypt(localStorage['1UC43']))
        this.currency = item.currencyCode;
        this.req = JSON.parse(cryptr.decrypt(props.hotelDetailsView.query));
        this.setState({
            query:props.hotelDetailsView.query
        })
        this.state = {
            count: 0,
            previewImg: "",
            isOpenPolicy: false,
            storePolicyCart: [],
            reasons: '',
            violationsId: '',
            listOfCurrency: [],
            cardList: [],
            loading: true,
            tripId: '',
            isAddingToCart: false,
            loadingUnique: '',
            query:""
        }
    }
    userId = "";
    componentDidMount = async () => {
        let tripId = sessionStorage.getItem('TRIPID');
        await this.setState({ tripId });

        sessionStorage.setItem('TRIPID', this.state.tripId);
        this.getHotelDetail();
        this.userId = JSON.parse(cryptr.decrypt(localStorage["440a24"]));
        this.getCardList();
        this.getCartDetail();
        this.getCurrencyCode();
    }
    loadImg = (img) => {
        this.setState({
            previewImg: img
        })
    }
    getCurrencyCode = async () => {
        var companyId = localStorage["440a28"];
        var resp = await masterService.getGroups(companyId, "CUR001");
        if (resp) {
            var arr = []
            resp.result.forEach(item => {
                arr.push({ label: item.itemName, value: item.itemCode })
            })
            this.setState({ listOfCurrency: arr })
        }
    };
    changeCurrency = e => {
        this.currency = e.value;
        this.getHotelDetail(1);
    };
    selectedItem = [];
    debouncedAddToCart = debounce((e) => {
        this.addToCart(e)
    }, 700);

    addToCart = async e => {
        if (this.state.isOpenPolicy) {
            if (this.state.violationsId === '') {
                return toast.error('Violations is required');
            } else if (this.state.reasons === '') {
                return toast.error('Reasons is required');
            }
        }
        var req = {
            type: "hotel",
            userId: this.userId.id,
            checkin: this.req.fromDate,
            checkout: this.req.toDate,
            data: {
                hotel_id: this.props.hotelDetailsView.hotel_id,
                hotel_name: this.props.hotelDetailsView.hotel_name,
                trip_duration: this.props.hotelDetailsView.tripDuration,
                room_id: e.roomId
            },
            TRIP_ID: sessionStorage.getItem('TRIPID'),
        };
        var remove = {
            TRIP_ID: sessionStorage.getItem('TRIPID'),
            type: "hotel",
            room_id: e.roomId
        };
        const int = this.selectedItem.indexOf(e.roomId);
        if (int === -1) {
            // out of policy code starts
            if (e.out_of_policy) {
                this.setState({
                    isOpenPolicy: true,
                    storePolicyCart: {
                        e: e,
                    }
                })
                if (this.state.reasons) {
                  $(".close").click();
                }
                if (!this.state.reasons) {
                // if (!this.state.isOpenPolicy) {
                    $('#openOutofPolicy').click();
                    return;
                } else {
                    this.setState({
                        isOpenPolicy: false,
                        storePolicyCart: {},
                        reasons: "",
                        violationsId: ""
                    })
                }
            }
            if (this.state.reasons !== '') {
                req.reasons = this.state.reasons
                req.violationsId = this.state.violationsId
                $('.close').click();
            }
            await this.setState({
                loadingUnique: e.roomId
            });
            this.selectedItem = [];
            this.selectedItem.push(e.roomId);
            // * code for showing Adding...

            this.addRemove(1, req);


        } else {
            await this.addRemove(2, remove);
            this.selectedItem.splice(int, 1);
            // await this.setState({isAddingToCart: false});
        }
        this.setState({ ...this.state })
    };
    addRemove = async (type, req) => {
        var resp = []
        if (type === 1) {
            resp = await cartServices.addCartUserData(req);
            if (resp) {

                await this.getCardList();
                this.setState({ loadingUnique: '' })
            }
        } else {
            resp = await cartServices.removeCartUserData(req);
            if (resp) {

                await this.getCardList();
                this.setState({ loadingUnique: '' })
            }
        }
        this.getCartDetail()
    };
    cancelPolicy = () => {
        this.setState({
            isOpenPolicy: false,
            storePolicyCart: {},
            reasons: "",
            violationsId: ""
        })
    }
    handleChangeReasonId = (e) => {
        this.setState({
            violationsId: e.target.value
        })
    }
    handleGetReason = (e) => {
        this.setState({
            reasons: e.target.value
        })
    }
    getCartDetail = async () => {
        let tripId = sessionStorage.getItem('TRIPID');
        var resp = await cartServices.getCartListDetail(tripId);
        if (resp) {
            let assume = resp.data.hotelData;
            assume.forEach((item, key) => {
                if (this.props.hotelDetailsView.hotel_id === item.hotel_id) {
                    const index = this.selectedItem.indexOf(
                        item.roomId
                    );
                    if (index === -1) {
                        this.selectedItem.push(item.roomId);
                    }
                }
            });
            let count = resp.data.flightData.length + resp.data.hotelData.length
            this.setState({ count })
        }
    };
    getCardList = async () => {
        let tripId = sessionStorage.getItem('TRIPID');
        var resp = await cartServices.getCartUserList(tripId);
        if (resp) {
            this.setState({ cardList: resp })
        }
    };
    hotelDetail = [];
    getHotelDetail = async () => {
        var id = this.props.hotelDetailsView.hotel_id;
        var name = this.props.hotelDetailsView.hotel_name;
        var duration = this.props.hotelDetailsView.tripDuration;
        // console.log("duration",duration)
        var req = {
            checkin: this.req.fromDate,
            checkout: this.req.toDate,
            no_of_rooms: "1",
            hotel_id: JSON.stringify(id),
            trip_duration: duration,
            hotel_name: name,
            currency: this.currency,
            vendor: this.req.vendor || "",
            latitude: this.req.lat,
            longitude: this.req.lng
        };
        var resp = await bookingServices.getHotelDetails(req)
        if (resp) {
            this.hotelDetail = resp;
            this.setState({previewImg : getValue(resp,"blockDetails.hotel_room_info[0].hotel_room_photos[0].url_max300")})
        }
        this.setState({ loading: false })
    };
    viewAllActive = false;
    viewAll = () => {
        this.viewAllActive = !this.viewAllActive;
        this.setState({ ...this.state })
    };
    viewAllClose = () => {
        this.viewAllActive = false
        this.setState({ ...this.state })
    }

    viewRoomActive = false;
    roomFacilities = [];
    roomName = 'Room Facilicity'
    viewRoomAll = (id) => {
        this.roomName = id.hotel_room_name
        this.roomFacilities = []
        this.hotelDetail.hotel_room_data.forEach(item => {
            if (id.hotel_room_id === item.hotel_room_id) {
                this.roomFacilities = item.hotel_room_facilities
            }
        })
        this.viewRoomActive = !this.viewRoomActive;
        this.setState({ ...this.state })
    }
    viewAllRoomClose = () => {
        this.viewRoomActive = false
        this.setState({ ...this.state })
    }
    render() {
        const result = this.hotelDetail;
        const rate = !(result.hotel_rating && parseInt(result.hotel_rating)) ? result.hotel_ranking > 5 ? 5 : 5 && parseInt(result.hotel_ranking > 5 ? 5 : 5) : result.hotel_rating && parseInt(result.hotel_rating)
        const { loading, cardList, count, listOfCurrency } = this.state
        // console.log(result)
        // console.log(result.hotel_photos)
        return (
            <div>
                <FullDetailsPage
                    from={this.req.from}
                    checkin={this.req.fromDate}
                    checkout={this.req.toDate}
                    rooms={this.req.rooms}
                    type="hotel"
                    cartCount={count}
                    cardList={cardList}
                    typeOfService="hotel"
                    hotelType={result.hotel_type?result.hotel_type:""}
                />
                {loading && (
                    <HotelLoader msg={"Please Wait..."} />
                )}
                {!loading && (
                    <div className="p-padding top-48">
                        <div className="white-card">
                            <div className="pad">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="hotel-header">
                                            <button
                                                // style={{"cursor":"pointer"}}
                                               onClick={() => this.props.enableRoom([])}
                                            >
                                                <div className="back">
                                                    <i className="material-icons">
                                                        arrow_back
                                                    </i>
                                                </div>
                                            </button>
                                            <div className="heading">
                                                <p className="label">Hotel</p>
                                                <h6 className="title">{result.hotel_name}</h6>
                                                {(result && result.hotel_class === 0) ? "" :
                                                    result.hotel_class === null ? "" : result.hotel_class + '"Star hotel"'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="hotel-rating">
                                            <h6 className="mb-1">
                                                {/* {result.hotel_rating} &nbsp; */}
                                                {/* <span className="fs-12">{result.hotel_rating_desc}</span> */}
                                            </h6>
                                            <div>
                                                <p className="rating rating1">
                                                    <span>Rating  <StarRatings
                                                        rating={rate}
                                                        starRatedColor="#f6e58d"
                                                        numberOfStars={5}
                                                        name="rating"
                                                    /></span>
                                                    <span className="rating-right">{rate}</span>
                                                    {/* <span className="reviews d-none">602 Reviews</span> */}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hotel-slider pad pt-0">
                                {(!result.hotel_photos || result.hotel_photos === "NA") && (
                                    <div className="carousel">
                                        <ul>
                                            <li>
                                                <img
                                                    src="/assets/images/booking/hotel_banner.png"
                                                    alt=""
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                {result.hotel_photos && (
                                    <div
                                        id="carouselExampleControls"
                                        className="carousel slide"
                                        data-ride="carousel"
                                    >
                                        <div className="carousel-inner">
                                            {result.hotel_photos &&
                                                result.hotel_photos.map((item, key) => {
                                                    return (
                                                        <div
                                                            className={
                                                                key === 0
                                                                    ? "carousel-item active"
                                                                    : "carousel-item"
                                                            }
                                                        >
                                                            <img
                                                                className="d-block w-100"
                                                                src={item}
                                                                alt="First slide"
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <a
                                            className="carousel-control-prev"
                                            href="#carouselExampleControls"
                                            role="button"
                                            data-slide="prev"
                                        >
                                            <span
                                                className="carousel-control-prev-icon"
                                                aria-hidden="true"
                                            />
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a
                                            className="carousel-control-next"
                                            href="#carouselExampleControls"
                                            role="button"
                                            data-slide="next"
                                        >
                                            <span
                                                className="carousel-control-next-icon"
                                                aria-hidden="true"
                                            />
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                )}
                                <div className="hotel-slider-right">
                                    <div className="row">
                                        {result.hotel_photos &&
                                            result.hotel_photos.map((item, key) => {
                                                return (
                                                    <div key={key} className="col-6 col-sm-4">
                                                        <BgImageComponent urlLink={item} />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                                {/* <div className="text-center mt-4"><button className="btn btn-border-primary">More Photos</button></div> */}
                            </div>
                            <div className="pad py-3 hotel-amen">
                                <div>
                                    <h6 className="sub-heading"><i className="material-icons align-middle">aspect_ratio</i>Amenities</h6>
                                    <button className="btn btn-nothing">
                                        <i className="material-icons">open_with</i>
                                        <p>AC</p>
                                    </button>
                                    <button className="btn btn-nothing">
                                        <i className="material-icons">network_wifi</i>
                                        <p>Free Wifi</p>
                                    </button>
                                    <button className="btn btn-nothing">
                                        <i className="material-icons">live_tv</i>
                                        <p>TV</p>
                                    </button>
                                    <button className="btn btn-nothing">
                                        <i className="material-icons">horizontal_split</i>
                                        <p>Fridge</p>
                                    </button>
                                    <button className="btn btn-nothing">
                                        <i className="material-icons">local_cafe</i>
                                        <p>Coffee</p>
                                    </button>
                                    <button className="btn btn-nothing">
                                        <i className="material-icons">local_dining</i>
                                        <p>Breakfast</p>
                                    </button>
                                    <button
                                        onClick={e => this.viewAll()}
                                        className="btn all"
                                    >
                                        View All
                </button>
                                </div>
                            </div>
                            <div className="room-header p-4" >
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-4">
                                        <div className="pt-2">
                                            <h5 className="m-0 sub-heading"><i className="material-icons align-middle">meeting_room</i>Rooms Available</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 offset-lg-3 col-md-6 col-8">
                                        {listOfCurrency && (
                                            <div className="ml-3">
                                                <div className="form-group custom labelup ml-5">
                                                    <label for="currency">Currency</label>
                                                    {listOfCurrency && (
                                                        <Select
                                                            isSearchable={true}
                                                            options={listOfCurrency}
                                                            value={{ label: this.currency }}
                                                            onChange={e => this.changeCurrency(e, "currency")}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <RoomListPage
                                currency={this.currency}
                                picture={result.hotel_photos && result.hotel_photos[0]}
                                duration={this.props.hotelDetailsView.tripDuration}
                                addToCart={this.debouncedAddToCart}
                                policy={result.blockDetails && result.blockDetails.hotel_policies}
                                selectedItem={this.selectedItem}
                                rooms={result.blockDetails && result.blockDetails.hotel_room_info}
                                viewAll={this.viewAll}
                                loadImg={this.loadImg}
                                previewImg={this.state.previewImg}
                                viewRoomAll={this.viewRoomAll}
                                isAddingToCart={this.state.isAddingToCart}
                                loadingUnique={this.state.loadingUnique}
                            />
                            {result && (
                                <div className="hotel-list-iframe">
                                    <GoogleMapReact
                                        center={{ lat: result.latitude, lng: result.longitude }}
                                        defaultZoom={11}
                                    >
                                        <AnyReactComponent
                                            lat={result.latitude}
                                            lng={result.longitude}
                                            text={result.hotel_name}
                                        />
                                    </GoogleMapReact>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div
                    className={
                        this.viewAllActive
                            ? "fixed-pop white-card"
                            : "fixed-pop white-card show"
                    }
                >
                    <AmenitiesPage
                        hotelDescription={this.hotelDetail}
                        items={result.hotel_facilities}
                        viewAll={this.viewAll}
                        viewAllClose={this.viewAllClose}
                        rules={this.hotelDetail.hotel_policies}
                    />
                </div>
                <div className={
                    this.viewRoomActive
                        ? "fixed-pop white-card"
                        : "fixed-pop white-card show"
                }>
                    <AmenitiesRoomPage
                        hotelDescription={this.hotelDetail}
                        roomFacilities={this.roomFacilities}
                        viewAllRoomClose={this.viewAllRoomClose}
                        viewRoomAll={this.viewRoomAll}
                        roomName={this.roomName}
                    />
                </div>
                <div id="openOutofPolicy"
                    data-target={"#travelPolicyModal"}
                    data-toggle="modal"
                    data-backdrop="static" data-keyboard="false"
                ></div>
                <div
                    className="modal fade"
                    id={"travelPolicyModal"}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="travelPolicyLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content border-0">
                            <div className="modal-head">
                                <h5 className="modal-title" id="travelPolicyLabel">
                                    Travel Policy Violation
                            </h5>
                                <span className="close" data-dismiss="modal"></span>
                            </div>
                            <div className="modal-middle">
                                <div className="travel_alert d-block py-4 pm-30">
                                    <div className="alert-detail">
                                        <p>
                                            <i className="material-icons">info</i> {" "}
                                            This Hotel is not in compliance with the following travel
                                            rule(s)
                                        </p>
                                    </div>
                                    {/* <p><span className="mr-2"><i className="material-icons">info</i></span>This flight is not in compliance with the following travel rule(s)</p> */}
                                </div>
                                <div className=" pm-30 bg-light">
                                    {this.state?.storePolicyCart?.e?.violations && this.state.storePolicyCart.e.violations.map((rule, ruleKey) => {
                                        return (
                                            <p key={ruleKey}><i className="material-icons">
                                                info
                                            </i> {rule.message}.</p>
                                        )
                                    })}
                                    <p className="mb-3">
                                        If you still want to submit, choose reason for selecting this lodging option, if more then one reason applies choose the most relevant option.
                                        <br /><span className="fw-bold">Note</span>: This reason will apply to this entire trip.
                                </p>
                                    <div className="form-group custom  mb-0">
                                        <div className="dropdown">

                                            <select className="form-control" onChange={e => this.handleChangeReasonId(e)}>
                                                <option value="">Select Reason</option>
                                                {this.state.storePolicyCart && this.state.storePolicyCart.e && this.state.storePolicyCart.e.violations && this.state.storePolicyCart.e.violations.map((itemList, keyItme) => {
                                                    return (
                                                        <option value={itemList.violationCode} key={keyItme}>
                                                            {itemList.policyViolation?.description} -
                                                            {itemList.violationCode}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="pm-30">
                                    <p className="mb-3">Please explain why you have chosen this flight</p>
                                    <div className="form-group custom textarea">
                                        <textarea
                                            data-gramm_editor="false"
                                            spellCheck="false"
                                            onChange={e => this.handleGetReason(e)}
                                            rows="2"
                                            name="reason"
                                            className="form-control"
                                            value={this.state.reasons}
                                            placeholder="&nbsp;"
                                        />
                                        <label>Comment</label>
                                    </div>
                                    <div className="modal-last btn-group-margin ">
                                        <button
                                            onClick={e => this.debouncedAddToCart(this.state.storePolicyCart.e)}
                                            type="button"
                                            className="btn"
                                        >
                                            Add
                                    </button>
                                        <button
                                            onClick={e => this.cancelPolicy()}
                                            type="button"
                                            className="btn"
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default HotelDetailsPage;
