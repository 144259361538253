import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ConnectedListsPage from "./connected-lists/connected-lists";
class ConnectedMainPage extends Component {
  constructor(props) {
    super(props);
    if (
      props.location.pathname ===
      "/administration/company/admin-forms/connected-lists"
    ) {
      this.props.history.push(
        "/administration/company/admin-forms/connected-lists/lists"
      );
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (
      this.props.location.pathname ===
      "/administration/company/admin-forms/connected-lists"
    ) {
      this.props.history.push(
        "/administration/company/admin-forms/connected-lists/lists"
      );
    }
  }
  render() {
    return (
      <div className="ConnectedMainPage">
        <Switch>
          <Route
            path="/administration/company/admin-forms/connected-lists/lists"
            component={ConnectedListsPage}
          />
        </Switch>
      </div>
    );
  }
}
export default ConnectedMainPage;
