import React, { Component } from "react";
import MileageMap from "./mileage-map";
import PlacesAutocomplete from "react-places-autocomplete";
import PropTypes from "prop-types";
import _ from "lodash";
const isEqual = require("react-fast-compare");

class wayPoints extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.wayPoints, nextProps.wayPoints) ||
      this.props.wayPoints.length !== nextProps.wayPoints.length ||
      this.props.totalDistanceInKM !== nextProps.totalDistanceInKM ||
      this.props.wayPoints !== nextProps.wayPoints
    );
  }

  render() {
    const labels = [
      ["A", "B"],
      ["C", "D"],
      ["E", "F"],
      ["G", "H"],
      ["I", "J"],
      ["K", "L"],
      ["M", "N"],
      ["O", "P"],
      ["Q", "R"],
      ["S", "T"],
      ["U", "V"],
      ["W", "X"],
      ["Y", "Z"],
      ["AB", "AC"],
      ["AD", "AE"]
    ];
    const { wayPoints } = this.props;
    //   handlers
    const {
      handleFromChange,
      handleFromSelect,
      handleToChange,
      handleToSelect,
      handleAddLocation,
      handleDeleteLocation,
      handleSelectRoute,
      handleOnDragEnd
    } = this.props;

    return (
      <div className="row">
        <div className="col-md-6 pr-0">
          <h6 className="sub-heading py-3 px-4 mb-0">Way Points</h6>
          {wayPoints.map(wayPoint => (
            <React.Fragment key={wayPoint.id}>
              <div className="row">
                <div className="col-md-2 text-right">
                  <i className="material-icons align-middle">location_on</i>
                </div>
                <div className="col-md-8">
                  <div className="position-relative mileage_info">
                    <div className="add-side-border">
                      <div className="form-group">
                        <PlacesAutocomplete
                          classNames="form-control"
                          value={wayPoint.fromAddress}
                          onChange={address =>
                            handleFromChange(address, wayPoint.id)
                          }
                          onSelect={address =>
                            handleFromSelect(address, wayPoint.id)
                          }
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading
                          }) => (
                            <div className="position-relative">
                              <input
                                {...getInputProps({
                                  placeholder: "Search Places ...",
                                  className:
                                    "location-search-input form-control"
                                })}
                              />
                              <ul className="autocomplete-dropdown-container">
                                {loading && <li>Loading...</li>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  return (
                                    <li
                                      {...getSuggestionItemProps(suggestion, {
                                        className
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </div>
                  </div>
                </div>
                {wayPoints && wayPoints.length > 1 && (
                  <div className="col-md-2">
                    <div
                      onClick={() => handleDeleteLocation(wayPoint.id)}
                      className="position-relative"
                    >
                      <div className="rounded__close">
                        <i className="material-icons align-middle">close</i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-2 text-right">
                  <i className="material-icons align-middle">location_on</i>
                </div>
                <div className="col-md-8">
                  <div className="form-group dashed-border-bottom pb-4">
                    <PlacesAutocomplete
                      classNames="form-control"
                      value={wayPoint.toAddress}
                      onChange={address => handleToChange(address, wayPoint.id)}
                      onSelect={address => handleToSelect(address, wayPoint.id)}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading
                      }) => (
                        <div className="position-relative">
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "location-search-input form-control"
                            })}
                          />
                          <ul className="autocomplete-dropdown-container">
                            {loading && <li>Loading...</li>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              return (
                                <li
                                  {...getSuggestionItemProps(suggestion, {
                                    className
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
              </div>
              <div id={`GoogleMapaccordion`} className="google-map-container">
                {wayPoint.geocodedWaypoints &&
                  wayPoint.geocodedWaypoints.length > 0 &&
                  wayPoint.geocodedWaypoints.map((points, index) => (
                    <React.Fragment key={index}>
                      <div id={"GoogleMapOne" + index}>
                        <label className="checkbox-container">
                          <input
                            type="radio"
                            name={`radio${wayPoint.id}`}
                            checked={points.summary === wayPoint.via}
                            onChange={() =>
                              handleSelectRoute(
                                wayPoint.id,
                                index,
                                points.legs[0].distance.value
                              )
                            }
                          />
                          <span className="checkmark" />
                        </label>
                        <h5
                          className="heading-h5 collapsed"
                          data-toggle="collapse"
                          data-target={"#GoogleMapcollapse" + index}
                          aria-expanded="true"
                          aria-controls={"collapse" + index}
                        >
                          {`Via ${points.legs && points.summary}
                      (${points.legs[0] &&
                        points.legs[0].distance &&
                        points.legs[0].distance.text})`}
                          <span className="totla-dis">
                            <span>
                              {points.legs[0] &&
                                points.legs[0].duration &&
                                points.legs[0].duration.text}
                            </span>
                          </span>
                        </h5>

                        <div className="clearfix" />
                      </div>
                      <div
                        id={"GoogleMapcollapse" + index}
                        className="collapse"
                        aria-labelledby={"GoogleMap" + index}
                        data-parent="#GoogleMapaccordion"
                      >
                        <div className="card-body">
                          <div className="pb-2">
                            <span className="circle-a">A</span>
                            {points.legs && points.legs[0].start_address}
                          </div>
                          <ul>
                            {points.legs[0] &&
                              points.legs[0].steps.map((step, stpIdx) => (
                                <li key={stpIdx + index}>
                                  <div className="li-top">
                                    {step.maneuver === "turn-left" ? (
                                      <i className="material-icons mr-2">
                                        arrow_back
                                      </i>
                                    ) : step.maneuver === "turn-right" ? (
                                      <i className="material-icons mr-2">
                                        arrow_forward
                                      </i>
                                    ) : (
                                      <i className="material-icons mr-2">
                                        arrow_upward
                                      </i>
                                    )}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: step.html_instructions
                                      }}
                                    />
                                  </div>
                                  <div className="li-bottom">
                                    <span>{`${step.distance.text}  ${step.duration.text}`}</span>
                                  </div>
                                </li>
                              ))}
                          </ul>
                          <div className="pt-2">
                            <span className="circle-a">B</span>
                            {points.legs[0] && points.legs[0].end_address}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
              {/* {wayPoint.geocodedWaypoints.map((points, index) => (
              <React.Fragment key={index}>
                <h6>{`Via ${points.legs &&
                  points.summary}(${totalDistanceFromTo(
                  points.legs[0].steps
                )})`}</h6>
                {points.legs[0] &&
                  points.legs[0].steps.map((step, stpIdx) => (
                    <li key={stpIdx + index}>
                      {step.maneuver === "turn-left" ? (
                        <i className="material-icons">arrow_back</i>
                      ) : step.maneuver === "turn-right" ? (
                        <i className="material-icons">arrow_forward</i>
                      ) : (
                            <i className="material-icons">arrow_upward</i>
                          )}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: step.html_instructions
                        }}
                      /> */}
              {/* {step.transit_details && step.transit_details.headsign
                        ? step.transit_details.headsign
                        : ""} */}
              {/* <br />
                      {`${step.distance.text}  ${step.duration.text}`}
                    </li>
                  ))}
                <ul />
              </React.Fragment>
            ))} */}
            </React.Fragment>
          ))}
          <div className="px-5">
            <button onClick={handleAddLocation} className="btn mb-5">
              Add
            </button>
          </div>
        </div>
        <div className="col-md-6 pl-0">
          <MileageMap
            wayPoints={wayPoints}
            labels={labels}
            handleOnDragEnd={handleOnDragEnd}
            travelMode="DRIVING"
          />
        </div>
      </div>
    );
  }
}
wayPoints.propTypes = {
  handleFromChange: PropTypes.func,
  handleFromSelect: PropTypes.func,
  handleToChange: PropTypes.func,
  handleToSelect: PropTypes.func,
  handleAddLocation: PropTypes.func,
  handleDeleteLocation: PropTypes.func,
  handleSelectRoute: PropTypes.func,
  handleOnDragEnd: PropTypes.func,
  wayPoint: PropTypes.array
};

export default wayPoints;
