import React, { Component } from "react";
import { Route } from "react-router-dom";
import WorkflowsPage from "./home/home";
import WorkflowsStep1Page from "./workflow-step1/workflow-step1";
import WorkflowsStep2Page from "./workflow-step2/workflow-step2";
import WorkflowsStep3Page from "./workflow-step3/workflow-step3";
import ApprovalStatusPage from "./approval-status/approval-status";
import AuthorisedApproversListPage from "./auth-approve/authorised-approvers-list/authorised-approvers-list";
class WorkflowsHomePage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/administration/company/workflows/") {
      this.props.history.push("/administration/company/workflows/home");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidMount() {
    // console.log("This is workflow");
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/administration/company/workflows") {
      this.props.history.push("/administration/company/workflows/home");
    }
  }
  render() {
    return (
      <div className="WorkflowHomePage">
        <Route
          path="/administration/company/workflows/home"
          component={WorkflowsPage}
        />
        <Route exact
          path="/administration/company/workflows/workflow-step1"
          component={WorkflowsStep1Page}
        />
        <Route exact
          path="/administration/company/workflows/workflow-step1/:id"
          component={WorkflowsStep1Page}
        />
        <Route exact
          path="/administration/company/workflows/workflow-step2"
          component={WorkflowsStep2Page}
        />
        <Route exact
          path="/administration/company/workflows/workflow-step2/:id"
          component={WorkflowsStep2Page}
        />

        <Route exact
          path="/administration/company/workflows/workflow-step3"
          component={WorkflowsStep3Page}
        />
        <Route exact
          path="/administration/company/workflows/workflow-step3/:id"
          component={WorkflowsStep3Page}
        />
        <Route
          path="/administration/company/workflows/approval-status"
          component={ApprovalStatusPage}
        />
        <Route
          path="/administration/company/workflows/authorised-approvers-list"
          component={AuthorisedApproversListPage}
        />
      </div>
    );
  }
}
export default WorkflowsHomePage;
