import React, { Component } from 'react';
import AddViolationPolicyPage  from './add-violation-policy-html';
import { policyViolationService } from '../../../../../../_services/policy-violation.services';
import { alertConstants } from '../../../../../../_constants/alert.constants';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator'


class AddViolationPolicy extends Component {

  state = {
    allRules: [],
    companyId: localStorage['440a28'],
    // companyId: "5c2c891c30566e1f00b24552",

    selectedRule: '',
    selectedRuleId: '',
    activePage: 1,
    itemsCountPerPage: 10,
    totalItemsCount: 0,
    type: '',
    code: '',
    description: '',
    thisConfiguration: false,
    allConfigurations: false,
    configurationAndSelected: false,
    activeStatus: false,
    editItemId: ''


  }
  validator = new SimpleReactValidator();
  componentDidMount(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.getAllRoles();

  }



  getAllRoles = () => {
    policyViolationService.getAllRuleClass(this.state.companyId, this.state.itemsCountPerPage, this.state.activePage)
    .then(res => {

      this.setState({
        allRules: res.result,
      }, () => {
        // If we are editing a policy violation, i am tryin to show old data in the inputs
        if(this.props.match.params.id){
          this.setState({
            editItemId: this.props.match.params.id
          }, () => {
            policyViolationService.getPolicyViolationById(this.state.companyId, this.state.editItemId)
              .then(res => {
                // console.log(this.state.allRules);
                // Find which rule class is being edited and setstate its details

                let ruleClassToBeEdited = this.state.allRules.find(rule => rule.id === res.result.ruleClassId);
                if(ruleClassToBeEdited){


                  this.setState({
                  selectedRule : ruleClassToBeEdited.className,
                  selectedRuleId: ruleClassToBeEdited.id,
                  type: res.result.type,
                  code: res.result.code,
                  description: res.result.description,
                  thisConfiguration: res.result.thisConfigurationOnly,
                  allConfigurations: res.result.allConfigurations,
                  configurationAndSelected: res.result.configurationAndSelected

                })
              }

              })
          })

        }
      })

    }, err => {
      toast.error(alertConstants.ERROR);
    })
  }
  // For handling selection of rule class dropdown
  handleRuleClassSelection = (rule) => {
    // policyViolationService.getPolicyViolationByRuleClass(this.state.companyId,rule.id)
    // .then(res => {
    //   console.log(res)
    // }, err => {
    //   console.log(err);

    // })
    this.setState({
      selectedRule: rule.className,
      selectedRuleId: rule.id
    })
  }

  // For handling input change of code and description
  inputChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })


  }

  // For handling violation type
  typeHandler = (name) => {

    this.setState({
      type: name
    });
  }

  toggleThisConfiguration = () => {
    this.setState({
      thisConfiguration: !this.state.thisConfiguration
    })

  }

  toggleAllConfigurations = () => {
    this.setState({
      allConfigurations: !this.state.allConfigurations
    })
  }

  toggleConfigurationAndSelected = () => {
    this.setState({
      configurationAndSelected: !this.state.configurationAndSelected
    })
  }

  addPolicyViolationHandler = () => {
    // data to be sent for creating/editing policy violation
    let payload = {
      companyId: this.state.companyId,
      ruleClassId: this.state.selectedRuleId,
      code: this.state.code,
      type: this.state.type,
      description: this.state.description,
      thisConfigurationOnly: this.state.thisConfiguration,
      allConfigurations: this.state.allConfigurations,
      configurationAndSelected: this.state.configurationAndSelected
    }
    // if edit item id exists we update it otherwise we create a new policyviolation
    if(this.state.editItemId){
      if(this.validator.allValid()){

        policyViolationService.editPOlicyViolation(this.state.editItemId, payload)
        .then(res => {
          this.props.history.push('/administration/travel/policy-violation');
          toast.success(alertConstants.UPDATED_SUCCESSFULLY)
        },  error => {
          if (error.statusCode === 422) {
            let errors = error.message
              Object.keys(errors).map(key => {
                return (
                  toast.error(errors[key])
                  );
                })
              } else {
                toast.error(error.message)
              }

            })
          }else{
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
          }
    }else{

      if(this.validator.allValid()){

        policyViolationService.addPolicyViolation(payload)
        .then(res => {
        this.props.history.push('/administration/travel/policy-violation');
        toast.success(alertConstants.CREATED_SUCCESSFULLY);
      },  error => {
        if (error.statusCode === 422) {
          let errors = error.message
            Object.keys(errors).map(key => {
                return (
                  toast.error(errors[key])
                  );
                })
              } else {
                toast.error(error.message)
              }

            })
      }else{
        this.validator.showMessages();
        this.forceUpdate();
      }

    }




  }

  render() {
    return(
      <div>
        <AddViolationPolicyPage
          state={this.state}
          error={this.validator}
          handleRuleClassSelection={this.handleRuleClassSelection}
          inputChangeHandler={this.inputChangeHandler}
          typeHandler={this.typeHandler}
          toggleThisConfiguration={this.toggleThisConfiguration}
          toggleAllConfigurations={this.toggleAllConfigurations}
          toggleConfigurationAndSelected={this.toggleConfigurationAndSelected}
          addPolicyViolationHandler={this.addPolicyViolationHandler}

        />
      </div>
    )
  }
}
export default AddViolationPolicy;