import React from 'react';

const auditTrial =(props)=>{
    return(
        <div className="audit-trail" id="audit-trail-c">
        <div className="bg-white trail-heading">
          <h6 className="mb-1">Expense Report</h6>
          <h5 className="mb-0 font-weight-bold">Audit Trail</h5>
          <button className="btn btn-nothing close-trail"
            onClick={e => props.closeAuditTrail()}
          >
            <i className="material-icons">close</i>
          </button>
          <div className="clearfix"></div>
        </div>
        <div className="trail-content">
          <div className="shape-name py-3 border-bottom">
            <div className="float-left">
              <div className="left l-grey">
                RG
                </div>
              <div className="right">
                <h6 className="heading font-weight-bold">
                  <span>Ravi Gangadhar</span>
                </h6>
                <p className="text muted-p">
                  Added expense type <span className="text-primary">car Rental (Attendee)</span>
                </p>
              </div>
            </div>
            <div className="float-right">
              <p className="mb-0">6 min ago</p>
            </div>

            <div className="clearfix" />
          </div>
          <div className="shape-name py-3 border-bottom">
            <div className="float-left">
              <div className="left l-grey">
                RG
                </div>
              <div className="right">
                <h6 className="heading font-weight-bold">
                  <span>Ravi Gangadhar</span>
                </h6>
                <p className="text muted-p">
                  Added expense type <span className="text-primary">car Rental (Attendee)</span>
                </p>
              </div>
            </div>
            <div className="float-right">
              <p className="mb-0">6 min ago</p>
            </div>

            <div className="clearfix" />
          </div>
          <div className="shape-name py-3 border-bottom">
            <div className="float-left">
              <div className="left l-grey">
                RG
                </div>
              <div className="right">
                <h6 className="heading font-weight-bold">
                  <span>Ravi Gangadhar</span>
                </h6>
                <p className="text muted-p">
                  Added expense type <span className="text-primary">car Rental (Attendee)</span>
                </p>
              </div>
            </div>
            <div className="float-right">
              <p className="mb-0">6 min ago</p>
            </div>

            <div className="clearfix" />
          </div>
        </div>
        <div className="text-center end-trail ">
          End of Trail
          </div>
      </div>

    )
}

export default auditTrial