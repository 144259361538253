import React, { Component } from "react";
import { cartServices } from "src/_services";
import { toast } from "react-toastify";
import { expenseReport } from "../../../../_services/expenseReport";
import _ from "lodash";
import $ from "jquery";
import moment from "moment";
import Loader from "src/_components/common/Loader";
import { MenuCartSubPage } from "./MenuCartSubmit";
import SimpleReactValidator from "simple-react-validator";
import RequestDynamicForm from "./travelRequestComponent/requestDynamicForm";
import ExpenseAllocationTable from "./travelRequestComponent/expenseAllocationTable";
import EditExpenseAllocationTable from "./travelRequestComponent/editAllocationTable";
import { myAccountTravelSettingsServise } from "src/_services";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import AddPassportModal from "./travelRequestComponent/addPassportModal";
import cryptr from "src/_helpers/crypty";
import { getValue, setValue } from "src/_components/common/lodsh";
import { getConnectedList } from "src/_services";


class TravelRequestDetailsPage extends Component {
  constructor(props) {
    super(props);
    if (
      sessionStorage.getItem("TRIPID") === undefined ||
      sessionStorage.getItem("TRIPID") === null
    ) {
      this.props.history.push("/booking/home");
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.validator = new SimpleReactValidator();
    this.validatorPersonalDayOff = new SimpleReactValidator();
    this.validator2 = new SimpleReactValidator();
    this.addRequestValidator = new SimpleReactValidator();
    this.editRequestValidator = new SimpleReactValidator();
    this.addAllocaValidator = new SimpleReactValidator();
    this.editAllocValidator = new SimpleReactValidator();
    this.state = {
      personalDayOffFromTemp: "",
      personalDayOffFromDatePickerTemp: "",
      personalDayOffFrom: "",
      personalDayOffTO: "",
      personalDayOffFromDatePicker: "",
      personalDayOffTODatePicker: "",
      requestFormHeaderId: "",
      requestFormId: "",
      requestFormName: "",
      requestId: "",
      allocationFormHeaderId: "",
      allocationFormId: "",
      allocationFormName: "",
      allocationId: "",
      selectedVisa: "",
      selectedPassport: "",
      userId: "",
      userBaseCurrency: "",
      userId: "",
      selectedPolicyId: "",
      baseCurrency: "",
      allocationStatus: false,
      prevAllocationStatus: false,
      isSubmit: false,
      personalDayOff: false,
      isLoading: false,
      totalAllocatedAmount: 0,
      totalSelectesExpenseAmount: 0,
      allocatedAmounts: 0,
      count: 0,
      companyId: localStorage["440a28"],
      userPermission: "employee-role",
      allocatedIn: "Percentage",
      tripId: sessionStorage.getItem("TRIPID"),
      tableHead: [],
      allocFormFields: [],
      allocFieldsObject: [],
      selectedIndex: [],
      editAllocationForm: [],
      allocationEditIds: [],
      deleteEditAllocatopnIds: [],
      connectedCreateListData: [],
      singleAllocationForm: [],
      expenseAllocationForm: [],
      error: [],
      connectedListData: [],
      editResponse: [],
      requestFormFields: [],
      requestFieldsObject: [],
      requestForm: [],
      alloconnectedListData: [],
      requestFormAccess: [],
      allocationFormAccess: [],
      availablePassports: [],
      images: [],
      images2: [],
      passport: [],
      form2: {},
      visa: [],
      policiesList: [],
      previousRequestStatus: false,
      previousEditAllocationForm: [],
      cartDetails: [],
      isPolicyChanged: false,
      isEdit: false,
      isInternationalFlight: false,
      passportModalLoading: false,
      search: [],
      loading: [],
      already: false
    };
  }
  componentDidMount() {
    this.getDeatail();
    this.getAllPolicies();
    this.getCountriesList();
    let currentUser = JSON.parse(
      cryptr.decrypt(localStorage.getItem("440a24"))
    );
    this.setState({ userId: currentUser["id"] });
  }
  componentWillUnmount() {
    this.state = {
      search: [],
      loading: []
    }
  }

  handlePassportAddClick = () => {
    this.setState({ form2: {}, images: [], images2: [] });
  };
  getDeatail = async () => {
    this.setState({ isLoading: true });
    var resp = await cartServices.getCartUserList(this.state.tripId);
    if (resp) {
      if (resp.POLICY_ID) {
        await this.setState({
          cartDetails: resp,
          selectedPolicyId: resp.POLICY_ID,
          isEdit: true
        });
        this.getAllFormList();
      } else {
        this.setState({ isLoading: false });
        await this.setState({ cartDetails: resp, baseCurrency: resp.currency });
      }
    }
  };

  //get all the policies
  getAllPolicies = async () => {
    let response = await myAccountTravelSettingsServise.getAllPolicies(
      this.state.companyId,
      this.state.tripId
    );
    if (response) {
      this.setState({ isLoading: false });
      this.setState({ policiesList: response.result });
    } else {
      this.setState({ isLoading: false });
    }
  };

  policySelectHandler = async policyId => {
    await this.setState({
      selectedPolicyId: policyId,
      isPolicyChanged: true,
      isLoading: true
    });
    this.getDeatail();
    this.getAllFormList();
    let tripId = this.state.tripId;
    if (tripId == null || tripId === undefined || tripId === "") {
      // this.props.history.push('./../home');
    }
  };

  getDataFromCopyDown = async (formField, newConnectedListDate) => {
    let copyDownObject = {
      companyId: this.state.companyId,
      userId: this.state.userId,
      fieldId: [formField.id]
    };
    let response = await expenseReport.postGetcopyDownData(copyDownObject);
    if (response && response.result) {
      if (+formField.fieldLevel === 1) {
        let newFieldLevel = formField.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter(
          listData =>
            listData.multiSelectParentField === formField.id &&
            listData.fieldLevel === newFieldLevel
        );
        if (existConnected.length > 0) {
          let companyId = this.state.companyId;
          let responseMult = await expenseReport.getConnectedListData(
            companyId,
            response.result[0].fieldValue
          );
          let childName = existConnected[0].columnName;
          if (responseMult) {
            this.setState({ [childName]: responseMult });
          }
        }
      } else if (formField.fieldLevel >= 2) {
        let newFieldLevel = formField.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter(
          listData =>
            listData.multiSelectParentField ===
            formField.multiSelectParentField &&
            listData.fieldLevel === newFieldLevel
        );
        if (
          existConnected.length > 0 &&
          formField.defaultValue !== undefined &&
          formField.defaultValue !== ""
        ) {
          let companyId = this.state.companyId;
          let responseMult = await expenseReport.getConnectedListData(
            companyId,
            response.result[0].fieldValue
          );
          let childName = existConnected[0].columnName;
          if (responseMult) {
            this.setState({ [childName]: responseMult });
          }
        }
      }
      return response.result[0].fieldValue;
    } else {
      return "";
    }
  };
  //check if any field has default value and that one is parent then check for child field if he has then call api for child
  checkDefaultValueHasChild = async (currentField, newConnectedListDate) => {
    if (+currentField.fieldLevel === 1) {
      let newFieldLevel = currentField.fieldLevel + 1;
      let existConnected = newConnectedListDate.filter(
        listData =>
          listData.multiSelectParentField === currentField.id &&
          listData.fieldLevel === newFieldLevel
      );
      if (existConnected.length > 0) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(
          companyId,
          currentField.defaultValue
        );
        let childName = existConnected[0].columnName;
        if (response) {
          this.setState({ [childName]: response });
        }
      }
    } else {
      let newFieldLevel = currentField.fieldLevel + 1;
      let existConnected = newConnectedListDate.filter(
        listData =>
          listData.multiSelectParentField ===
          currentField.multiSelectParentField &&
          listData.fieldLevel === newFieldLevel
      );
      if (
        existConnected.length > 0 &&
        currentField.defaultValue !== undefined &&
        currentField.defaultValue !== ""
      ) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(
          companyId,
          currentField.defaultValue
        );
        let childName = existConnected[0].columnName;
        if (response) {
          this.setState({ [childName]: response });
        }
      }
    }
  };
  setStateOfFormsAndFields = async (formAndFields, newConnectedListDate) => {
    let formFields = [];
    let fieldsObjects = [];
    let fieldArrayValues = [];
    fieldArrayValues = formAndFields.map(async formField => {
      formFields.push(formField.fieldName);
      fieldsObjects.push(formField);
      let fieldName = formField.fieldName;
      if (formField.dataType === "date") {
        let copyDown = formField.isCopyDown
          ? await this.getDataFromCopyDown(formField, newConnectedListDate)
          : formField.isDefaultValue
            ? moment(formField.defaultValue).format("DD MMM YYYY")
            : formField.fieldValue
              ? formField.fieldValue
              : "";
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      } else if (formField.dataType === "checkbox") {
        let copyDown = formField.isCopyDown
          ? await this.getDataFromCopyDown(formField, newConnectedListDate)
          : formField.isDefaultValue
            ? formField.defaultValue
            : formField.fieldValue
              ? formField.fieldValue
              : "";
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      } else {
        let copyDown;
        if (formField.isCopyDown)
          copyDown = await this.getDataFromCopyDown(
            formField,
            newConnectedListDate
          );
        else if (formField.isDefaultValue)
          copyDown = formField.isDefaultValue ? formField.defaultValue : "";
        else copyDown = formField.fieldValue ? formField.fieldValue : "";
        formField.fieldLevel >= 1 &&
          !formField.isCopyDown &&
          !formField.isCopyDown &&
          formField.isDefaultValue &&
          this.checkDefaultValueHasChild(formField, newConnectedListDate);
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      }
    });
    let fieldValues = await Promise.all(fieldArrayValues);
    var fieldValueObj = fieldValues.reduce(
      (obj, item) => ((obj[item.key] = item.value), obj),
      {}
    );
    this.setState({
      requestFormFields: formFields,
      requestFieldsObject: fieldsObjects,
      isLoading: false,
      ...fieldValueObj
    });
  };

  createDropdownForMultiLblConnectedList = newConnectedListDate => {
    newConnectedListDate &&
      newConnectedListDate.forEach(async fields => {
        if (fields.fieldLevel === 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField === fields.id &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 2) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField ===
              fields.multiSelectParentField &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }
      });
  };

  //get all dynamic form field of  form
  getAllFormList = async () => {
    this.setState({ isLoading: true });
    var response = await cartServices.getTravelRequestFrom(
      this.state.companyId,
      this.state.tripId,
      this.state.selectedPolicyId
    );
    if (response && getValue(response, "REQUEST.entryFields", [])) {
      await getValue(response, "REQUEST.entryFields", []).forEach(async item => {
        if (item.dataType === "connected_list") {
          if (item.connectedListId) {
            let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
            item.page = 1;
            item.connectedListDataCount = getValue(resp, "count", 0);
            item.connectedListData.listManagementData = resp.result;
            item.search = ''
          }
          this.setState({ expenseForm: response.result })
        }
      })
    }

    //get all dynamic form field for edit allocation and travel request detial
    var editResponse = await cartServices.editTravelRequestFrom(
      this.state.companyId,
      this.state.tripId,
      this.state.selectedPolicyId
    );
    if (
      response.TRIP_COST !== undefined &&
      response.TRIP_COST !== "" &&
      response.TRIP_COST !== null
    ) {
      this.setState({
        allocatedAmounts: Math.ceil(response.TRIP_COST * 100) / 100,
        userBaseCurrency: response.BASE_CURRENCY,
        isInternationalFlight: response.international
      });
    }
    if (response.international) {
      const result = await cartServices.getAllPassports();
      if (result)
        this.setState({
          availablePassports: result.passport,
          visa: result.visa
        });
    }

    if (response) {
      this.setState({
        totalSelectesExpenseAmount: response ? response.TRIP_COST : 0
      });
    }
    //if edit response is array then create travel-request detail
    if (Array.isArray(editResponse.data)) {
      if (
        response &&
        response.REQUEST &&
        response.REQUEST.entryFields &&
        response.REQUEST.entryFields
      ) {
        let getAllcolumnName = response.REQUEST.entryFields.map(
          fields => fields.columnName
        );
        let personalDayOffFromIndex = getAllcolumnName.indexOf(
          "TRAVEL_DATE_FROM"
        );
        let personalDayOffTOIndex = getAllcolumnName.indexOf("TRAVEL_DATE_TO");
        if (personalDayOffTOIndex >= 0 && personalDayOffFromIndex >= 0) {
          this.setState({
            personalDayOffFromDatePicker:
              response.REQUEST.entryFields[personalDayOffFromIndex].fieldValue,
            personalDayOffTODatePicker:
              response.REQUEST.entryFields[personalDayOffTOIndex].fieldValue
          });
        }

        this.setState({
          requestForm: response.REQUEST.entryFields,
          requestFormHeaderId: response.REQUEST.formHeaderId,
          requestFormId: response.REQUEST.formId,
          requestFormName: response.REQUEST.formName,
          requestId: response.REQUEST.id,
          requestFormAccess: response.REQUEST.formAccess,
          userId: response.userId
        });
        // let formFields = [];
        // let fieldsObjects = [];
        let newConnectedListDate = [];
        let dynamicFeilds = [];
        dynamicFeilds = _.filter(response.REQUEST.entryFields, function (
          result
        ) {
          return result.fieldLevel !== undefined && result.fieldLevel !== "";
        });
        dynamicFeilds.forEach(fields => {
          newConnectedListDate.push({
            columnName: fields.columnName,
            id: fields.id,
            multiSelectParentField: fields.multiSelectParentField
              ? fields.multiSelectParentField
              : "",
            fieldName: fields.fieldName,
            fieldLevel: fields.fieldLevel,
            prevFieldId: fields.prevFieldId
          });
        });
        this.setState({
          connectedListData: newConnectedListDate,
          isLoading: false
        });
        this.createDropdownForMultiLblConnectedList(newConnectedListDate);
        this.setStateOfFormsAndFields(
          response.REQUEST.entryFields,
          newConnectedListDate
        );
      } else {
        toast.success("No Data Found");
        this.setState({ isLoading: false });
      }
      // this.setState({ isLoading: false });
    }
    else {

      if (
        editResponse &&
        editResponse.data.REQUEST &&
        editResponse.data.REQUEST.entryFields &&
        editResponse.data.REQUEST.entryFields
      ) {
        let getAllcolumnName = editResponse.data.REQUEST.entryFields.map(
          fields => fields.columnName
        );
        let personalDayOffFromIndex = getAllcolumnName.indexOf(
          "TRAVEL_DATE_FROM"
        );
        let personalDayOffTOIndex = getAllcolumnName.indexOf("TRAVEL_DATE_TO");
        if (personalDayOffTOIndex >= 0 && personalDayOffFromIndex >= 0) {
          this.setState({
            personalDayOffFromDatePicker:
              editResponse.data.REQUEST.entryFields[personalDayOffFromIndex]
                .fieldValue,
            personalDayOffTODatePicker:
              editResponse.data.REQUEST.entryFields[personalDayOffTOIndex]
                .fieldValue
          });
        }


        //added for travel-request-details in mytrips

        getValue(editResponse, "data.REQUEST.entryFields", []).forEach(async item => {
          if (item.dataType === "connected_list") {
            if (item.connectedListId) {
              let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
              item.page = 1;
              item.connectedListDataCount = getValue(resp, "count", 0);
              item.connectedListData.listManagementData = resp.result;
              item.search = ''
            }
            this.setState({ expenseForm: response.result })
          }
        })

        //

        this.setState({
          requestForm: editResponse.data.REQUEST.entryFields,
          requestFormHeaderId: editResponse.data.REQUEST.formHeaderId,
          requestFormId: editResponse.data.REQUEST.formId,
          requestFormName: editResponse.data.REQUEST.formName,
          requestId: editResponse.data.REQUEST.id,
          requestFormAccess: editResponse.data.REQUEST.formAccess,
          userId: editResponse.userId,
          previousRequestStatus: true,
          selectedPassport: editResponse.data.passportNumber,
          selectedVisa: editResponse.data.visaNumber
        });
        let formFields = [];
        let fieldsObjects = [];
        let newConnectedListDate = [];
        let dynamicFeilds = [];
        dynamicFeilds = _.filter(
          editResponse.data.REQUEST.entryFields,
          function (result) {
            return result.fieldLevel !== undefined && result.fieldLevel !== "";
          }
        );
        dynamicFeilds.forEach(fields => {
          newConnectedListDate.push({
            columnName: fields.columnName,
            id: fields.id,
            multiSelectParentField: fields.multiSelectParentField
              ? fields.multiSelectParentField
              : "",
            fieldName: fields.fieldName,
            fieldLevel: fields.fieldLevel,
            prevFieldId: fields.prevFieldId
          });
        });
        this.setState({ connectedListData: newConnectedListDate });
        newConnectedListDate &&
          newConnectedListDate.forEach(async fields => {
            if (fields.fieldLevel === 1) {
              let newFieldLevel = fields.fieldLevel + 1;
              let existConnected = newConnectedListDate.filter(
                listData =>
                  listData.multiSelectParentField === fields.id &&
                  listData.fieldLevel === newFieldLevel
              );
              if (
                existConnected.length > 0 &&
                fields.prevFieldId !== undefined &&
                fields.prevFieldId !== ""
              ) {
                let companyId = this.state.companyId;
                let response = await expenseReport.getConnectedListData(
                  companyId,
                  fields.prevFieldId
                );
                let childName = existConnected[0].columnName;
                if (response) {
                  this.setState({ [childName]: response });
                }
              }
            } else if (fields.fieldLevel >= 1) {
              let newFieldLevel = fields.fieldLevel + 1;
              let existConnected = newConnectedListDate.filter(
                listData =>
                  listData.multiSelectParentField ===
                  fields.multiSelectParentField &&
                  listData.fieldLevel === newFieldLevel
              );
              if (
                existConnected.length > 0 &&
                fields.prevFieldId !== undefined &&
                fields.prevFieldId !== ""
              ) {
                let companyId = this.state.companyId;
                let response = await expenseReport.getConnectedListData(
                  companyId,
                  fields.prevFieldId
                );
                let childName = existConnected[0].columnName;
                if (response) {
                  this.setState({ [childName]: response });
                }
              }
            }
          });
        //set state after edit request details
        getValue(editResponse, "data.REQUEST.entryFields", []).forEach(async formField => {
          let fieldName = formField.fieldName;
          if (formField.dataType === "date") {
            this.setState({ [fieldName]: formField.fieldValue });
          } else if (formField.dataType === "checkbox") {
            this.setState({ [fieldName]: formField.fieldValue });
          } else if (formField.dataType === "search") {
            if (
              formField.prevFieldId !== undefined &&
              formField.prevFieldId !== ""
            ) {
              await this.setState({
                [fieldName]: {
                  label: formField.fieldValue,
                  value: formField.prevFieldId
                }
              });
            } else {
              this.setState({ [fieldName]: "" });
            }
          } else {
            this.setState({ [fieldName]: formField.fieldValue });
          }
          formFields.push(formField.fieldName);
          fieldsObjects.push({
            fieldId: formField.id,
            columnName: formField.columnName
          });
        });
        this.setState({ isLoading: false });
        this.setState({
          requestFormFields: formFields,
          requestFieldsObject: fieldsObjects
        });
      } else {
        toast.success("No Data Found");
        this.setState({ isLoading: false });
      }
      this.setState({ isLoading: false });
    }
    //if editResponse is not an array then create allocation other wise update allocation
    if (getValue(response, "ALLOCATION.entryFields", [])) {
      await getValue(response, "ALLOCATION.entryFields", []).forEach(async item => {
        if (item.connectedListId) {
          let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp, "count", 0);
          item.connectedListData.listManagementData = resp.result;
          item.search = ''
        }
        this.setState({ expenseForm: response.result })
      })
      let NewArray = [];
      NewArray[0] = response.ALLOCATION.entryFields;
      this.setState({
        expenseAllocationForm: NewArray,
        singleAllocationForm: response.ALLOCATION.entryFields,
        tableHead: response.ALLOCATION.entryFields,
        allocationFormHeaderId: response.ALLOCATION.formHeaderId,
        allocationFormId: response.ALLOCATION.formId,
        allocationFormName: response.ALLOCATION.formName,
        allocationId: response.ALLOCATION.id,
        allocationFormAccess: response.ALLOCATION.formAccess
      });
    }


    if (Array.isArray(editResponse.data)) {
      if (
        response &&
        response.ALLOCATION &&
        response.ALLOCATION.entryFields &&
        response.ALLOCATION.entryFields
      ) {
        let NewArray = [];
        NewArray[0] = response.ALLOCATION.entryFields;
        this.setState({
          expenseAllocationForm: NewArray,
          singleAllocationForm: response.ALLOCATION.entryFields,
          tableHead: response.ALLOCATION.entryFields,
          allocationFormHeaderId: response.ALLOCATION.formHeaderId,
          allocationFormId: response.ALLOCATION.formId,
          allocationFormName: response.ALLOCATION.formName,
          allocationId: response.ALLOCATION.id,
          allocationFormAccess: response.ALLOCATION.formAccess
        });
        let newConnectedListData = [];
        let dynamicFeilds = [];
        dynamicFeilds = _.filter(response.ALLOCATION.entryFields, function (
          result
        ) {
          return result.fieldLevel !== undefined && result.fieldLevel !== "";
        });
        dynamicFeilds.forEach(fields => {
          newConnectedListData.push({
            columnName: fields.columnName,
            id: fields.id,
            multiSelectParentField: fields.multiSelectParentField
              ? fields.multiSelectParentField
              : "",
            fieldName: fields.fieldName,
            fieldLevel: fields.fieldLevel,
            prevFieldId: fields.defaultValue ? fields.defaultValue : "",
            fieldValue: fields.defaultValue ? fields.defaultValue : ""
          });
        });
        this.setState({ alloconnectedListData: newConnectedListData });

        newConnectedListData &&
          newConnectedListData.forEach(async (fields, index) => {
            if (fields.fieldLevel === 1) {
              let newFieldLevel = fields.fieldLevel + 1;
              let existConnected = newConnectedListData.filter(
                listData =>
                  listData.multiSelectParentField === fields.id &&
                  listData.fieldLevel === newFieldLevel
              );
              if (
                existConnected.length > 0 &&
                fields.prevFieldId !== undefined &&
                fields.prevFieldId !== ""
              ) {
                let companyId = this.state.companyId;
                let response = await expenseReport.getConnectedListData(
                  companyId,
                  fields.prevFieldId
                );
                let childName = existConnected[0].columnName + index;
                if (response) {
                  this.setState({ [childName]: response });
                }
              }
            } else if (fields.fieldLevel >= 2) {
              let newFieldLevel = fields.fieldLevel + 1;
              let existConnected = newConnectedListData.filter(
                listData =>
                  listData.multiSelectParentField ===
                  fields.multiSelectParentField &&
                  listData.fieldLevel === newFieldLevel
              );
              if (
                existConnected.length > 0 &&
                fields.prevFieldId !== undefined &&
                fields.prevFieldId !== ""
              ) {
                let companyId = this.state.companyId;
                let response = await expenseReport.getConnectedListData(
                  companyId,
                  fields.prevFieldId
                );
                let childName = existConnected[0].columnName + index;
                if (response) {
                  this.setState({ [childName]: response });
                }
              }
            }
          });

        let formFields = [];
        let fieldsObjects = [];
        let index = 0;
        response.ALLOCATION.entryFields.map(async formField => {
          let fieldName = formField.fieldName + index;
          this.setState({ ["allocamount" + index]: 100 });

          if (formField.dataType === "date") {
            let todayDate = new Date();
            todayDate = moment(todayDate).format("DD MMM YYYY");
            if (formField.isDefaultValue) {
              this.setState({
                ["alloc" + fieldName]: moment(formField.defaultValue).format(
                  "DD MMM YYYY"
                )
              });
            } else {
              this.setState({ ["alloc" + fieldName]: todayDate });
            }
          } else if (formField.dataType === "checkbox") {
            if (formField.isDefaultValue) {
              this.setState({ ["alloc" + fieldName]: formField.defaultValue });
            } else {
              this.setState({ ["alloc" + fieldName]: false });
            }
          } else {
            if (formField.isDefaultValue) {
              this.setState({ ["alloc" + fieldName]: formField.defaultValue });
            } else {
              this.setState({ ["alloc" + fieldName]: "" });
            }
          }

          formFields.push(formField.fieldName);
          fieldsObjects.push({
            fieldId: formField.id,
            columnName: formField.columnName
          });
        });
        this.setState({
          allocFormFields: formFields,
          allocFieldsObject: fieldsObjects
        });
        this.setState({ isLoading: true });
      } else {
        toast.success("No Data Found");
        this.setState({ isLoading: false });
      }
      this.setState({ isLoading: false });
    } else {
      if (
        editResponse &&
        editResponse.data.personalDayOff &&
        editResponse.data.personalDayOff
      ) {
        this.setState({
          personalDayOff: editResponse.data.personalDayOff.personalDayOff,
          personalDayOffFrom:
            editResponse.data.personalDayOff.personalDayOffFrom,
          personalDayOffTO: editResponse.data.personalDayOff.personalDayOffTO
        });
      }

      if (editResponse && editResponse.data && editResponse.data.ALLOCATION && editResponse.data.ALLOCATION.entryFields) {
        for (let i = 0; i < editResponse.data.ALLOCATION.entryFields.length; i++) {
          editResponse.data.ALLOCATION.entryFields[i].forEach(async item => {
            if (item.connectedListId) {
              let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
              item.page = 1;
              item.connectedListDataCount = getValue(resp, "count", 0);
              item.connectedListData.listManagementData = resp.result;
              item.search = ''
            }
            this.setState({ expenseForm: response.result })
          })
        }
      }
      if (
        editResponse &&
        editResponse.data.ALLOCATION &&
        editResponse.data.ALLOCATION.entryFields
      ) {
        let editCount = 0;
        let newEditArray = [];
        let allocationEditIds = [];
        this.setState({
          editAllocationForm: editResponse.data.ALLOCATION.entryFields,
          previousEditAllocationForm: editResponse.data.ALLOCATION.entryFields,
          allocatedIn: editResponse.data.ALLOCATION.allocatedIn,
          tableHead: editResponse.data.ALLOCATION.entryFields[0],
          allocationStatus: editResponse.data.ALLOCATION.allocationStatus
        });
        this.setAutoPercentageAmountHandler(
          editResponse.data.ALLOCATION.allocatedIn,
          this.state.totalSelectesExpenseAmount,
          editResponse.data.ALLOCATION.entryFields.length
        );
        editCount =
          editResponse.data.ALLOCATION.entryFields.length > 0
            ? editResponse.data.ALLOCATION.entryFields.length
            : 0;
        getValue(editResponse, "data.ALLOCATION.entryFields", []).forEach((data, index) => {
          allocationEditIds.push(data.id);
          data.forEach(async form => {
            if (form.dataType === "search") {
              if (form.prevFieldId !== undefined && form.prevFieldId !== "") {
                await this.setState({
                  ["alloc" + form.fieldName + index]: {
                    label: form.fieldValue,
                    value: form.prevFieldId
                  }
                });
              } else {
                this.setState({ ["alloc" + form.fieldName + index]: "" });
              }
            } else {
              this.setState({
                ["alloc" + form.fieldName + index]: form.fieldValue
                  ? form.fieldValue
                  : ""
              });
            }
          });
          newEditArray[index] = data;
        });
        this.setState({ allocationEditIds: allocationEditIds });
        this.setState({ count: editCount - 1 });
        //edit expense allocation details
        let newConnectedListDate = [];
        let dynamicFeilds = [];
        let editExpenseDynamicForm = [];
        newEditArray.forEach(list => {
          dynamicFeilds = _.filter(list, function (result) {
            return result.fieldLevel !== undefined && result.fieldLevel !== "";
          });
          editExpenseDynamicForm.push(dynamicFeilds);
        });
        editExpenseDynamicForm.forEach((fields, index) => {
          newConnectedListDate[index] = fields.map(editFields => ({
            columnName: editFields.columnName,
            id: editFields.id,
            multiSelectParentField: editFields.multiSelectParentField
              ? editFields.multiSelectParentField
              : "",
            fieldName: editFields.fieldName,
            fieldLevel: editFields.fieldLevel,
            prevFieldId: editFields.prevFieldId,
            fieldValue: editFields.fieldValue
          }));
        });
        this.setState({ connectedListData: newConnectedListDate });
        // start set the state for connected list for dropdown

        newConnectedListDate &&
          newConnectedListDate.forEach((lists, index) => {
            lists &&
              lists.forEach(async fields => {
                if (fields.fieldLevel === 1) {
                  let newFieldLevel = fields.fieldLevel + 1;
                  let existConnected = lists.filter(
                    listData =>
                      listData.multiSelectParentField === fields.id &&
                      listData.fieldLevel === newFieldLevel
                  );
                  if (
                    existConnected.length > 0 &&
                    fields.fieldValue !== undefined &&
                    fields.fieldValue !== ""
                  ) {
                    let companyId = this.state.companyId;
                    let response = await expenseReport.getConnectedListData(
                      companyId,
                      fields.fieldValue
                    );
                    let childName =
                      "alloc" + existConnected[0].columnName + index;
                    if (response) {
                      this.setState({ [childName]: response });
                    }
                  }
                } else if (fields.fieldLevel >= 1) {
                  let newFieldLevel = fields.fieldLevel + 1;
                  let existConnected = lists.filter(
                    listData =>
                      listData.multiSelectParentField ===
                      fields.multiSelectParentField &&
                      listData.fieldLevel === newFieldLevel
                  );
                  if (
                    existConnected.length > 0 &&
                    fields.fieldValue !== undefined &&
                    fields.fieldValue !== ""
                  ) {
                    let companyId = this.state.companyId;
                    let response = await expenseReport.getConnectedListData(
                      companyId,
                      fields.fieldValue
                    );
                    let childName =
                      "alloc" + existConnected[0].columnName + index;
                    if (response) {
                      this.setState({ [childName]: response });
                    }
                  }
                }
              });
          });
        this.setState({ alloconnectedListData: newConnectedListDate[0] });
        // this.createDropdownForMultiLblConnectedList(newConnectedListDate);

        //check if response coming from api then go inside
        // console.log(response.ALLOCATION)
        // if (
        //   response &&
        //   response.ALLOCATION &&
        //   response.ALLOCATION.entryFields &&
        //   response.ALLOCATION.entryFields
        // ) {
        //   this.setState({
        //     singleAllocationForm: response.ALLOCATION.entryFields
        //   });
        //   console.log(response)
        //   let newConnectedListData = [];
        //   let dynamicFeilds = [];
        //   dynamicFeilds = _.filter(response.ALLOCATION.entryFields, function (
        //     result
        //   ) {
        //     return result.fieldLevel !== undefined && result.fieldLevel !== "";
        //   });
        //   console.log(dynamicFeilds)
        //   dynamicFeilds.forEach(fields => {
        //     newConnectedListData.push({
        //       columnName: fields.columnName,
        //       id: fields.id,
        //       multiSelectParentField: fields.multiSelectParentField
        //         ? fields.multiSelectParentField
        //         : "",
        //       fieldName: fields.fieldName,
        //       fieldLevel: fields.fieldLevel,
        //       prevFieldId: fields.defaultValue ? fields.defaultValue : ""
        //     });
        //   });

        //   this.setState({ alloconnectedListData: newConnectedListData });
        //   // start set the state for connected list for dropdown
        //   newConnectedListData &&
        //     newConnectedListData.forEach(async (fields, index) => {
        //       console.log(index)
        //       if (fields.fieldLevel === 1) {
        //         let newFieldLevel = fields.fieldLevel + 1;
        //         let existConnected = newConnectedListData.filter(
        //           listData =>
        //             listData.multiSelectParentField === fields.id &&
        //             listData.fieldLevel === newFieldLevel
        //         );
        //         if (
        //           existConnected.length > 0 &&
        //           fields.prevFieldId !== undefined &&
        //           fields.prevFieldId !== ""
        //         ) {
        //           let companyId = this.state.companyId;
        //           let response = await expenseReport.getConnectedListData(
        //             companyId,
        //             fields.prevFieldId
        //           );
        //           let childName = existConnected[0].columnName + index;
        //           if (response) {
        //             console.log(response)
        //             this.setState({ ["alloc" + childName]: response });
        //           }
        //         }
        //       } else if (fields.fieldLevel >= 2) {
        //         let newFieldLevel = fields.fieldLevel + 1;
        //         let existConnected = newConnectedListData.filter(
        //           listData =>
        //             listData.multiSelectParentField ===
        //             fields.multiSelectParentField &&
        //             listData.fieldLevel === newFieldLevel
        //         );
        //         if (
        //           existConnected.length > 0 &&
        //           fields.prevFieldId !== undefined &&
        //           fields.prevFieldId !== ""
        //         ) {
        //           let companyId = this.state.companyId;
        //           let response = await expenseReport.getConnectedListData(
        //             companyId,
        //             fields.prevFieldId
        //           );
        //           let childName = existConnected[0].columnName + index;
        //           if (response) {
        //             this.setState({ ["alloc" + childName]: response });
        //           }
        //         }
        //       }
        //     });

        let formFields = [];
        let fieldsObjects = [];
        let index = editCount;
        response.ALLOCATION.entryFields.map(async formField => {
          let fieldName = formField.fieldName + index;
          this.setState({ ["allocamount" + index]: "" });
          if (formField.dataType === "date") {
            let todayDate = new Date();
            todayDate = moment(todayDate).format("DD MMM YYYY");
            if (formField.isDefaultValue) {
              this.setState({
                ["alloc" + fieldName]: moment(formField.defaultValue).format(
                  "DD MMM YYYY"
                )
              });
            } else {
              this.setState({ ["alloc" + fieldName]: todayDate });
            }
          } else if (formField.dataType === "checkbox") {
            if (formField.isDefaultValue) {
              this.setState({
                ["alloc" + fieldName]: formField.defaultValue
              });
            } else {
              this.setState({ ["alloc" + fieldName]: false });
            }
          } else {
            if (formField.isDefaultValue) {
              this.setState({
                ["alloc" + fieldName]: formField.defaultValue
              });
            } else {
              this.setState({ ["alloc" + fieldName]: "" });
            }
          }
          formFields.push(formField.fieldName);
          fieldsObjects.push({
            fieldId: formField.id,
            columnName: formField.columnName
          });
        });
        this.setState({
          allocFormFields: formFields,
          allocFieldsObject: fieldsObjects
        });
        this.setState({ isLoading: true });

      } else {
        if (
          editResponse &&
          editResponse.data.REQUEST &&
          editResponse.data.REQUEST.entryFields &&
          editResponse.data.REQUEST.entryFields
        ) {
          let NewArray = [];


          await getValue(response, "ALLOCATION.entryFields", []).forEach(async item => {
            if (item.connectedListId) {
              let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
              item.page = 1;
              item.connectedListDataCount = getValue(resp, "count", 0);
              item.connectedListData.listManagementData = resp.result;
              item.search = ''
            }
            this.setState({ expenseForm: response.result })
          })

          NewArray[0] = response.ALLOCATION.entryFields;

          this.setState({
            expenseAllocationForm: NewArray,
            singleAllocationForm: response.ALLOCATION.entryFields,
            tableHead: response.ALLOCATION.entryFields,
            allocationFormHeaderId: response.ALLOCATION.formHeaderId,
            allocationFormId: response.ALLOCATION.formId,
            allocationFormName: response.ALLOCATION.formName,
            allocationId: response.ALLOCATION.id,
            prevAllocationStatus: true,
            allocationFormAccess: response.ALLOCATION.formAccess
          });
          let newConnectedListData = [];
          let dynamicFeilds = [];
          dynamicFeilds = _.filter(response.ALLOCATION.entryFields, function (
            result
          ) {
            return result.fieldLevel !== undefined && result.fieldLevel !== "";
          });
          dynamicFeilds.forEach(fields => {
            newConnectedListData.push({
              columnName: fields.columnName,
              id: fields.id,
              multiSelectParentField: fields.multiSelectParentField
                ? fields.multiSelectParentField
                : "",
              fieldName: fields.fieldName,
              fieldLevel: fields.fieldLevel,
              prevFieldId: fields.defaultValue ? fields.defaultValue : "",
              fieldValue: fields.defaultValue ? fields.defaultValue : ""
            });
          });
          this.setState({ alloconnectedListData: newConnectedListData });
          newConnectedListData &&
            newConnectedListData.forEach(async (fields, index) => {
              if (fields.fieldLevel === 1) {
                let newFieldLevel = fields.fieldLevel + 1;
                let existConnected = newConnectedListData.filter(
                  listData =>
                    listData.multiSelectParentField === fields.id &&
                    listData.fieldLevel === newFieldLevel
                );
                if (
                  existConnected.length > 0 &&
                  fields.prevFieldId !== undefined &&
                  fields.prevFieldId !== ""
                ) {
                  let companyId = this.state.companyId;
                  let response = await expenseReport.getConnectedListData(
                    companyId,
                    fields.prevFieldId
                  );
                  let childName = existConnected[0].columnName + index;
                  if (response) {
                    this.setState({ [childName]: response });
                  }
                }
              } else if (fields.fieldLevel >= 2) {
                let newFieldLevel = fields.fieldLevel + 1;
                let existConnected = newConnectedListData.filter(
                  listData =>
                    listData.multiSelectParentField ===
                    fields.multiSelectParentField &&
                    listData.fieldLevel === newFieldLevel
                );
                if (
                  existConnected.length > 0 &&
                  fields.prevFieldId !== undefined &&
                  fields.prevFieldId !== ""
                ) {
                  let companyId = this.state.companyId;
                  let response = await expenseReport.getConnectedListData(
                    companyId,
                    fields.prevFieldId
                  );
                  let childName = existConnected[0].columnName + index;
                  if (response) {
                    this.setState({ [childName]: response });
                  }
                }
              }
            });

          let formFields = [];
          let fieldsObjects = [];
          let index = 0;
          response.ALLOCATION.entryFields.map(async formField => {
            let fieldName = formField.fieldName + index;
            this.setState({ ["allocamount" + index]: 100 });

            if (formField.dataType === "date") {
              let todayDate = new Date();
              todayDate = moment(todayDate).format("DD MMM YYYY");
              if (formField.isDefaultValue) {
                this.setState({
                  ["alloc" + fieldName]: moment(formField.defaultValue).format(
                    "DD/MM/YYYY"
                  )
                });
              } else {
                this.setState({ ["alloc" + fieldName]: todayDate });
              }
            } else if (formField.dataType === "checkbox") {
              if (formField.isDefaultValue) {
                this.setState({
                  ["alloc" + fieldName]: formField.defaultValue
                });
              } else {
                this.setState({ ["alloc" + fieldName]: false });
              }
            } else {
              if (formField.isDefaultValue) {
                this.setState({
                  ["alloc" + fieldName]: formField.defaultValue
                });
              } else {
                this.setState({ ["alloc" + fieldName]: "" });
              }
            }

            formFields.push(formField.fieldName);
            fieldsObjects.push({
              fieldId: formField.id,
              columnName: formField.columnName
            });
          });
          this.setState({
            allocFormFields: formFields,
            allocFieldsObject: fieldsObjects
          });
          this.setState({ isLoading: true });
        }
      }
      this.setState({ isLoading: false });
    }
  };

  setAutoPercentageAmountHandler = async (allocatedIn, totalAmount, count) => {
    let totalAmountPercentage = 0;
    if (allocatedIn === "Percentage") {
      totalAmountPercentage = 100;
    } else {
      totalAmountPercentage = totalAmount;
    }
    let deviderInEquality = count;
    let previousEditAllocationForm = this.state.previousEditAllocationForm;
    let totalPercentage = 0;
    if (previousEditAllocationForm.length <= 0) {
      if (allocatedIn === "Amount") {
        for (let index = 0; index < deviderInEquality; index++) {
          if (deviderInEquality >= 2) {
            if (index === 0) {
              await this.setState({
                ["allocamount" + index]:
                  Math.ceil((totalAmountPercentage / deviderInEquality) * 100) /
                  100
              });
            } else {
              await this.setState({
                ["allocamount" + index]:
                  Math.floor(
                    (totalAmountPercentage / deviderInEquality) * 100
                  ) / 100
              });
            }
          } else {
            await this.setState({
              ["allocamount" + index]:
                Math.floor((totalAmountPercentage / deviderInEquality) * 100) /
                100
            });
          }
        }
      } else {
        for (let index = 0; index < deviderInEquality; index++) {
          if (deviderInEquality >= 2) {
            if (index === 0) {
              await this.setState({
                ["allocamount" + index]:
                  Math.ceil((totalAmountPercentage / deviderInEquality) * 100) /
                  100
              });
              totalPercentage =
                totalPercentage +
                Math.ceil((totalAmountPercentage / deviderInEquality) * 100) /
                100;
            } else {
              if (deviderInEquality - 1 > index) {
                await this.setState({
                  ["allocamount" + index]:
                    Math.floor(
                      (totalAmountPercentage / deviderInEquality) * 100
                    ) / 100
                });
                totalPercentage = +(
                  totalPercentage +
                  Math.floor(
                    (totalAmountPercentage / deviderInEquality) * 100
                  ) /
                  100
                ).toFixed(2);
              } else {
                await this.setState({
                  ["allocamount" + index]:
                    Math.floor((100 - totalPercentage) * 100) / 100
                });
                totalPercentage = +(
                  totalPercentage +
                  Math.floor((100 - totalPercentage) * 100) / 100
                ).toFixed(2);
              }
            }
          } else {
            await this.setState({
              ["allocamount" + index]:
                Math.floor((totalAmountPercentage / deviderInEquality) * 100) /
                100
            });
            totalPercentage = +(
              totalPercentage +
              Math.floor((totalAmountPercentage / deviderInEquality) * 100) /
              100
            ).toFixed(2);
          }
        }
      }
    } else {
      //added by sethu
      if (allocatedIn === "Amount") {
        for (let index = 0; index < deviderInEquality; index++) {
          if (deviderInEquality >= 2) {
            if (index === 0) {
              await this.setState({
                ["allocamount" + index]:
                  Math.ceil((totalAmountPercentage / deviderInEquality) * 100) /
                  100
              });
            } else {
              await this.setState({
                ["allocamount" + index]:
                  Math.floor(
                    (totalAmountPercentage / deviderInEquality) * 100
                  ) / 100
              });
            }
          } else {
            await this.setState({
              ["allocamount" + index]:
                Math.floor((totalAmountPercentage / deviderInEquality) * 100) /
                100
            });
          }
        }
      } else {
        for (let index = 0; index < deviderInEquality; index++) {
          if (deviderInEquality >= 2) {
            if (index === 0) {
              await this.setState({
                ["allocamount" + index]:
                  Math.ceil((totalAmountPercentage / deviderInEquality) * 100) /
                  100
              });
              totalPercentage =
                totalPercentage +
                Math.ceil((totalAmountPercentage / deviderInEquality) * 100) /
                100;
            } else {
              if (deviderInEquality - 1 > index) {
                await this.setState({
                  ["allocamount" + index]:
                    Math.floor(
                      (totalAmountPercentage / deviderInEquality) * 100
                    ) / 100
                });
                totalPercentage = +(
                  totalPercentage +
                  Math.floor(
                    (totalAmountPercentage / deviderInEquality) * 100
                  ) /
                  100
                ).toFixed(2);
              } else {
                await this.setState({
                  ["allocamount" + index]:
                    Math.floor((100 - totalPercentage) * 100) / 100
                });
                totalPercentage = +(
                  totalPercentage +
                  Math.floor((100 - totalPercentage) * 100) / 100
                ).toFixed(2);
              }
            }
          } else {
            await this.setState({
              ["allocamount" + index]:
                Math.floor((totalAmountPercentage / deviderInEquality) * 100) /
                100
            });
            totalPercentage = +(
              totalPercentage +
              Math.floor((totalAmountPercentage / deviderInEquality) * 100) /
              100
            ).toFixed(2);
          }
        }
      }
    }

    this.pecentageRemainigAmountHandler();
  };
  //calculate percentage
  pecentageRemainigAmountHandler = (amount, amountindex) => {
    let deviderInEquality = this.state.count + 1;
    let totalAllocatedAmount = 0;
    if (this.state.allocatedIn === "Percentage") {
      for (let index = 0; index < deviderInEquality; index++) {
        if (index !== amountindex || amountindex === "allow") {
          if (deviderInEquality >= 2) {
            if (index === 0) {
              totalAllocatedAmount =
                totalAllocatedAmount +
                Math.floor(this.state["allocamount" + index] * 100) / 100;
            } else {
              totalAllocatedAmount =
                totalAllocatedAmount +
                Math.floor(this.state["allocamount" + index] * 100) / 100;
            }
          } else {
            totalAllocatedAmount =
              totalAllocatedAmount +
              Math.floor(this.state["allocamount" + index] * 100) / 100;
          }
        } else if (index === amountindex) {
          totalAllocatedAmount = +(totalAllocatedAmount + +amount).toFixed(2);
        }
      }
      let percentageToAmount =
        (this.state.totalSelectesExpenseAmount * totalAllocatedAmount) / 100;
      if (!Number.isNaN(percentageToAmount))
        this.setState({
          totalAllocatedAmount: percentageToAmount,
          allocatedAmounts: percentageToAmount
        });
    } else {
      for (let index = 0; index < deviderInEquality; index++) {
        if (index !== amountindex || amountindex === "allow") {
          if (deviderInEquality >= 2) {
            if (index === 0) {
              totalAllocatedAmount =
                totalAllocatedAmount +
                Math.floor(this.state["allocamount" + index] * 100) / 100;
            } else {
              totalAllocatedAmount =
                totalAllocatedAmount +
                Math.floor(this.state["allocamount" + index] * 100) / 100;
            }
          } else {
            totalAllocatedAmount =
              totalAllocatedAmount +
              Math.floor(this.state["allocamount" + index] * 100) / 100;
          }
        } else if (index === amountindex) {
          totalAllocatedAmount = totalAllocatedAmount + +amount;
        }
        if (!Number.isNaN(totalAllocatedAmount))
          this.setState({
            totalAllocatedAmount: totalAllocatedAmount,
            allocatedAmounts: totalAllocatedAmount
          });
      }
    }
  };

  //on click dropdown selelct handler
  onClickHandler = async (fieldName, conId, fieldId, fieldLevel, assignKey) => {

    this.state.search[assignKey] = '';
    this.setState({ search: this.state.search })

    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };

  //on click dropdown selelct handler
  onAllocationClickHandler = async (
    fieldName,
    conId,
    fieldId,
    fieldLevel,
    assignkey,
    rowIndex
  ) => {

    this.state.search[assignkey] = '';
    this.setState({ search: this.state.search })

    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.alloconnectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = "alloc" + existConnected[0].columnName + rowIndex;
      let fieldName = "alloc" + existConnected[0].fieldName + rowIndex;
      if (response) {
        this.setState({ [childName]: response, [fieldName]: "" });
      }
    }
  };

  //on select connected list handler
  onAllocationConnectedListkHandler = async (
    fieldName,
    conId,
    fieldId,
    fieldLevel,
    rowIndex
  ) => {
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.alloconnectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = "alloc" + existConnected[0].columnName + rowIndex;
      let fieldName = "alloc" + existConnected[0].fieldName + rowIndex;
      if (response) {
        this.setState({ [childName]: response, [fieldName]: "" });
      }
    }
  };

  //on select connected list handler
  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onChangeHandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };
  onDatePickerHandler = (value, fieldName) => {
    if (!isNaN(value)) {
      this.setState({
        [fieldName]: moment(new Date(value)).format("DD MMM YYYY")
      });
    }
  };
  onCheckboxHandler = fieldName => {
    this.setState({ [fieldName]: !this.state[fieldName] });
  };

  onIntegerHandler = (event, fieldName) => {
    if (!isNaN(event.target.value)) {
      this.setState({ [fieldName]: +event.target.value });
    }
  };

  //select checkbox at the time of edit for delete

  onEditSelectedAllocationFieldHandler = (value, fieldId) => {
    let selectedIndex = [...this.state.selectedIndex];
    let deleteEditAllocatopnIds = [...this.state.deleteEditAllocatopnIds];
    if (fieldId !== "" && fieldId !== undefined) {
      if (deleteEditAllocatopnIds.includes(fieldId)) {
        deleteEditAllocatopnIds = deleteEditAllocatopnIds.filter(
          id => id !== fieldId
        );
      } else {
        deleteEditAllocatopnIds = deleteEditAllocatopnIds.concat(fieldId);
      }
      this.setState({
        deleteEditAllocatopnIds: deleteEditAllocatopnIds
      });
    }
    if (selectedIndex.includes(value)) {
      selectedIndex = selectedIndex.filter(id => id !== value);
    } else {
      selectedIndex = selectedIndex.concat(value);
    }
    this.setState({ selectedIndex: selectedIndex });
  };
  //onAdd click handler initialise default value
  initialiseConnectedListDefaultValue = async newCount => {
    let newConnectedListData = [...this.state.connectedCreateListData];
    newConnectedListData &&
      newConnectedListData.forEach(async fields => {
        if (fields.fieldLevel === 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListData.filter(
            listData =>
              listData.multiSelectParentField === fields.id &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName + newCount;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 2) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListData.filter(
            listData =>
              listData.multiSelectParentField ===
              fields.multiSelectParentField &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName + newCount;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }
      });
  };
  //at the time of edit add more allocation fields
  onAddEditClickHandler = async () => {
    let count = this.state.count;
    let newCount = count + 1;
    // let singleAllocationForm = [...this.state.singleAllocationForm];
    //added by sethu for getiing new connected list data for allocation second form
    let singleAllocationForm = await this.getFeildListSingleForm();
    //ended
    let editAllocationForm = [...this.state.editAllocationForm];
    if (singleAllocationForm !== undefined && singleAllocationForm.length > 0) {
      editAllocationForm[newCount] = singleAllocationForm;
      this.setState({ editAllocationForm: editAllocationForm });
      this.setState({ count: newCount });
      singleAllocationForm.forEach((formField, index) => {
        let fieldName = formField.fieldName + newCount;
        this.setState({ ["amount" + newCount]: "" });
        if (formField.dataType === "date") {
          let todayDate = new Date();
          todayDate = moment(todayDate).format("DD MMM YYYY");
          if (formField.isDefaultValue) {
            this.setState({
              ["alloc" + fieldName]: moment(formField.defaultValue).format(
                "DD MMM YYYY"
              )
            });
          } else {
            this.setState({ ["alloc" + fieldName]: todayDate });
          }
        } else if (formField.dataType === "checkbox") {
          if (formField.isDefaultValue) {
            this.setState({ ["alloc" + fieldName]: formField.defaultValue });
          } else {
            this.setState({ ["alloc" + fieldName]: false });
          }
        } else {
          if (formField.isDefaultValue) {
            this.setState({ ["alloc" + fieldName]: formField.defaultValue });
          } else {
            this.setState({ ["alloc" + fieldName]: "" });
          }
        }
      });
    }
    this.initialiseConnectedListDefaultValue(newCount);
    //added by sethu
    this.setAutoPercentageAmountHandler(
      this.state.allocatedIn,
      this.state.totalSelectesExpenseAmount,
      editAllocationForm.length
    );
      //end
  };
  //add more allocation fields
  onAddClickHandler = async () => {

    let count = this.state.count;
    let newCount = count + 1;
    // let singleAllocationForm = [...this.state.singleAllocationForm];

    //added by sethu for getiing new connected list data for allocation second form
    let singleAllocationForm = await this.getFeildListSingleForm();
    //ended
    let expenseAllocationForm = [...this.state.expenseAllocationForm];
    expenseAllocationForm[newCount] = expenseAllocationForm[
      newCount
    ] = singleAllocationForm;
    this.setState({ expenseAllocationForm: expenseAllocationForm });
    this.setState({ count: newCount });
    singleAllocationForm.forEach((formField, index) => {
      let fieldName = formField.fieldName + newCount;
      this.setState({ ["allocamount" + newCount]: "" });

      if (formField.dataType === "date") {
        let todayDate = new Date();
        todayDate = moment(todayDate).format("DD MMM YYYY");
        if (formField.isDefaultValue) {
          this.setState({
            ["alloc" + fieldName]: moment(formField.defaultValue).format(
              "DD MMM YYYY"
            )
          });
        } else {
          this.setState({ ["alloc" + fieldName]: todayDate });
        }
      } else if (formField.dataType === "checkbox") {
        if (formField.isDefaultValue) {
          this.setState({ ["alloc" + fieldName]: formField.defaultValue });
        } else {
          this.setState({ ["alloc" + fieldName]: false });
        }
      } else {
        if (formField.isDefaultValue) {
          this.setState({ ["alloc" + fieldName]: formField.defaultValue });
        } else {
          this.setState({ ["alloc" + fieldName]: "" });
        }
      }
    });
    this.initialiseConnectedListDefaultValue(newCount);
    this.setAutoPercentageAmountHandler(
      this.state.allocatedIn,
      this.state.totalSelectesExpenseAmount,
      expenseAllocationForm.length
    );
  };

  //method added by sethu
  getFeildListSingleForm = async () => {
    var response = await cartServices.getTravelRequestFrom(
      this.state.companyId,
      this.state.tripId,
      this.state.selectedPolicyId
    );

    if (response && getValue(response, "ALLOCATION.entryFields", [])) {
      await getValue(response, "ALLOCATION.entryFields", []).forEach(async item => {
        if (item.dataType === "connected_list") {
          if (item.connectedListId) {
            let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
            item.page = 1;
            item.connectedListDataCount = getValue(resp, "count", 0);
            item.connectedListData.listManagementData = resp.result;
            item.search = ''
          }
        }
      })
    }

    if (getValue(response, "ALLOCATION.entryFields", [])) {
      let newConnectedListData = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(getValue(response, "ALLOCATION.entryFields", []), function (
        result
      ) {
        return result.fieldLevel !== undefined && result.fieldLevel !== "";
      });
      dynamicFeilds.forEach(fields => {
        newConnectedListData.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField
            ? fields.multiSelectParentField
            : "",
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.defaultValue ? fields.defaultValue : "",
          fieldValue: fields.defaultValue ? fields.defaultValue : ""
        });
      });
      this.setState({ alloconnectedListData: newConnectedListData });
      this.createDropdownForMultiLblConnectedList(newConnectedListData);
      // this.setStateOfFormsAndFields(
      //   getValue(response, "ALLOCATION.entryFields", []),
      //   newConnectedListData
      // );
    }
    return getValue(response, "ALLOCATION.entryFields", []);
  }


  //--  end--- //

  //delete allocation field at the time of edit
  onEditDeleteAllocationField = async () => {
    let selectedIndex = [...this.state.selectedIndex];
    let editAllocationForm = [...this.state.editAllocationForm];
    let deleteArrLength = selectedIndex.length;
    _.pullAt(editAllocationForm, selectedIndex);
    let count = this.state.count;

    //added by sethu
    this.setState(
      { editAllocationForm: editAllocationForm, selectedIndex: [] },
      () => {
        this.setAutoPercentageAmountHandler(
          this.state.allocatedIn,
          this.state.totalSelectesExpenseAmount,
          editAllocationForm.length
        );
        this.pecentageRemainigAmountHandler(
          this.state.totalSelectesExpenseAmount,
          "allow"
        );
      }
    );
    //end

    this.setState({ count: count - deleteArrLength });
    this.setState({
      editAllocationForm: editAllocationForm,
      selectedIndex: []
    });


    editAllocationForm.forEach((fields, index) => {
      fields.forEach(formField => {
        this.setState({ ["allocamount" + index]: "" });
        let fieldName = formField.fieldName + index;
        if (formField.dataType === "date") {
          let todayDate = new Date();
          todayDate = moment(todayDate).format("DD MMM YYYY");
          this.setState({ ["alloc" + fieldName]: todayDate });
        } else if (formField.dataType === "checkbox") {
          this.setState({ ["alloc" + fieldName]: false });
        } else {
          if (
            formField.fieldValue !== undefined &&
            formField.fieldValue !== "" &&
            formField.fieldValue !== null
          ) {
            this.setState({ ["alloc" + fieldName]: formField.fieldValue });
          } else {
            this.setState({ ["alloc" + fieldName]: "" });
          }
        }
      });
    });
  };
  //delete allocation field at the time of creating allocation
  onDeleteAllocationField = () => {
    let count = this.state.count;
    let selectedIndex = [...this.state.selectedIndex];
    let expenseAllocationForm = [...this.state.expenseAllocationForm];
    let deleteArrLength = selectedIndex.length;
    let selectedLength = expenseAllocationForm.length;
    _.pullAt(expenseAllocationForm, selectedIndex);
    this.setState(
      { expenseAllocationForm: expenseAllocationForm, selectedIndex: [] },
      () => {
        this.setAutoPercentageAmountHandler(
          this.state.allocatedIn,
          this.state.totalSelectesExpenseAmount,
          expenseAllocationForm.length
        );
        this.pecentageRemainigAmountHandler(
          this.state.totalSelectesExpenseAmount,
          "allow"
        );
      }
    );
    this.setState({ count: count - deleteArrLength });
    expenseAllocationForm.forEach((fields, index) => {
      fields.forEach(formField => {
        this.setState({ ["amount" + selectedLength - 1]: "" });
        let fieldName = formField.fieldName + (selectedLength - 1);
        if (formField.dataType === "date") {
          let todayDate = new Date();
          todayDate = moment(todayDate).format("DD MMM YYYY");
          this.setState({ [fieldName]: todayDate });
        } else if (formField.dataType === "checkbox") {
          this.setState({ [fieldName]: false });
        } else {
          this.setState({ [fieldName]: "" });
        }
      });
    });
  };
  //on select checkbox for allocation handler
  onSelectedAllocationFieldHandler = value => {
    let selectedIndex = [...this.state.selectedIndex];
    if (selectedIndex.includes(value)) {
      selectedIndex = selectedIndex.filter(id => id !== value);
    } else {
      selectedIndex = selectedIndex.concat(value);
    }
    this.setState({ selectedIndex: selectedIndex });
  };
  onPersonalDayofFromHandler = value => {
    if (!isNaN(value)) {
      this.setState({
        //   personalDayOffFromTemp:moment(new Date(value)).format(
        //   "DD MMM YYYY"
        // ),
        // personalDayOffFromDatePickerTemp :moment(new Date(value)).format(
        //   "DD MMM YYYY"
        // ),
        personalDayOffFrom: moment(new Date(value)).format("DD MMM YYYY"),
        personalDayOffFromDatePicker: moment(new Date(value)).format(
          "DD MMM YYYY"
        )
      });
    }
  };
  onPersonalDayofToHandler = value => {
    if (!isNaN(value)) {
      this.setState({
        personalDayOffTO: moment(new Date(value)).format("DD MMM YYYY"),
        personalDayOffTODatePicker: moment(new Date(value)).format(
          "DD MMM YYYY"
        )
      });
    }
  };
  onAllocationInHandler = (event, allocatedIn) => {
    event.preventDefault();
    this.setState({ allocatedIn: allocatedIn }, () => {
      this.pecentageRemainigAmountHandler();
    });
    this.setAutoPercentageAmountHandler(
      allocatedIn,
      this.state.totalSelectesExpenseAmount,
      this.state.expenseAllocationForm.length
    );
  };
  onAllocationChangeHandler = async () => {
    await this.setState({ allocationStatus: !this.state.allocationStatus });
  };

  onLocationChangekHandler = (event, fieldName) => {
    this.setState({ [fieldName]: event });
  };

  loadOptions = async newValue => {
    return expenseReport.getAllLocationsName(newValue).then(data => {
      return data.result.map(list => {
        return {
          label: list.name + "," + list.countryId.name,
          value: list.id
        };
      });
    });
  };

  onSubmitHandler = async () => {
    let validationStatus = true;
    let isInternationalFlight = this.state.isInternationalFlight;
    let selectedPassport = this.state.selectedPassport;
    if (this.state.allocationStatus && this.state.personalDayOff) {
      validationStatus =
        this.addRequestValidator.allValid() &&
        this.addAllocaValidator.allValid() &&
        this.validatorPersonalDayOff.allValid();
    } else if (this.state.allocationStatus) {
      validationStatus =
        this.addRequestValidator.allValid() &&
        this.addAllocaValidator.allValid();
    } else if (this.state.personalDayOff) {
      validationStatus =
        this.addRequestValidator.allValid() &&
        this.validatorPersonalDayOff.allValid();
    } else {
      validationStatus = this.addRequestValidator.allValid();
    }
    if (isInternationalFlight) {
      validationStatus =
        selectedPassport !== "" &&
          selectedPassport !== undefined &&
          selectedPassport !== null
          ? true
          : false;
      if (!validationStatus) {
        toast.error("Please Add Passport");
      }
    }
    if (validationStatus) {
      this.setState({ isSubmit: true });
      let totalPercentage = 0;
      let policyObj = this.state.policiesList.filter(
        policyObj => policyObj.id === this.state.selectedPolicyId
      );
      let fieldsObjects = [...this.state.expenseAllocationForm];
      let allocationSubmitArray = [];
      for (let val in fieldsObjects) {
        let internalArray = [];
        for (let value in fieldsObjects[val]) {
          let val1 = fieldsObjects[val];
          let fieldId = val1[value].id;
          let columnName = val1[value].columnName;
          let fieldValue = "";

          if (columnName === "LOCATION_NAME") {
            fieldValue = this.state["alloc" + val1[value].fieldName + val]
              ? this.state["alloc" + val1[value].fieldName + val].value
              : "";
          } else if (columnName) {
            if (this.state[val1[value].fieldName + val]) {
              sessionStorage.setItem("trdId", this.state[val1[value].fieldName + val])
            }
            let data = fieldsObjects[val].filter((item, index) => item.fieldName === val1[value].fieldName)
            if (data[0].connectedListData) {
              let data1 = data[0].connectedListData.listManagementData
              let data2 = data1.filter((i) => sessionStorage.getItem("trdId") == i.id)
              let data3 = data2[0] ? data2[0].itemName : "";
              localStorage.setItem("conncted_list_name", data3)
            }

            fieldValue = this.state[val1[value].fieldName + val] ? this.state[val1[value].fieldName + val] : this.state["alloc" + val1[value].fieldName + val];
            let array1 = fieldsObjects[val].filter((item, index) => item.fieldName === val1[value].fieldName)
            if (!array1[0].connectedListData && fieldValue !== "") {
              let companyId = this.state.companyId;
              let response = await expenseReport.getConnectedListDataForSpecific(
                fieldValue
              );
              // console.log(response)
              sessionStorage.setItem("mtrdId", response.itemName)
            }

            if (fieldValue === undefined && columnName) {
              if (sessionStorage.getItem("trdId1") === fieldValue) {
                internalArray.push({
                  fieldId: fieldId,
                  fieldValue: "",
                  fieldItemName: localStorage.getItem("conncted_list_name") ? localStorage.getItem("conncted_list_name") : ""
                });
              } else {
                internalArray.push({
                  fieldId: fieldId,
                  fieldValue: "",
                  fieldItemName: sessionStorage.getItem("mtrdId") ? sessionStorage.getItem("mtrdId") : ""
                })
              }
            } else if (columnName) {
              if (sessionStorage.getItem("trdId") === fieldValue) {
                internalArray.push({
                  fieldId: fieldId,
                  fieldValue: fieldValue,
                  columnName: columnName,
                  fieldItemName: localStorage.getItem("conncted_list_name") ? localStorage.getItem("conncted_list_name") : ""
                });
              } else {
                internalArray.push({
                  fieldId: fieldId,
                  fieldValue: fieldValue,
                  columnName: columnName,
                  fieldItemName: sessionStorage.getItem("mtrdId") ? sessionStorage.getItem("mtrdId") : ""
                });
              }
            }

          }

        }
        internalArray.push({
          fieldName: "amount",
          fieldValue: this.state["allocamount" + val]
        });
        totalPercentage = +(
          totalPercentage + +this.state["allocamount" + val]
        ).toFixed(2);
        allocationSubmitArray.push(internalArray);
      }
      let requestFieldsObject = [...this.state.requestFieldsObject];
      let index = 0;
      for (const key of this.state.requestFormFields) {
        if (requestFieldsObject[index].columnName === "LOCATION_NAME") {
          requestFieldsObject[index]["fieldValue"] = this.state[key]
            ? this.state[key].value
            : "";
        } else {
          requestFieldsObject[index]["fieldValue"] = this.state[key];

          //added by sethu
          let FieldArray = requestFieldsObject.filter((item, index) =>{if(item.fieldValue !== "" && item.dataType === "connected_list") {return item.fieldValue === this.state[key]}})
          if (FieldArray[0]&&FieldArray[0].fieldValue !== "") {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListDataForSpecific(
              // companyId,
              FieldArray[0].fieldValue
            );
            setValue(FieldArray[0].fieldItemName =  response.itemName)
          }
          //end
        }
        index++;
      }

      requestFieldsObject.forEach((item) => {
        if (item.dataType === "connected_list") {
          if (item.fieldValue && getValue(item, "connectedListData.listManagementData", [])) {
            let data = getValue(item, "connectedListData.listManagementData", []).filter(resp => { return resp.id === item.fieldValue });
            setValue(item, "connectedListData.listManagementData", data)
          } else {
            setValue(item, "connectedListData.listManagementData", [])
          }
        }
      })

      let data = {};
      let allocationStatus = this.state.allocationStatus;
      let personalDayOff = this.state.personalDayOff;
      if (this.state.personalDayOff && allocationStatus) {
        data = {
          companyId: this.state.companyId,
          TRIP_ID: this.state.tripId,
          userId: this.state.userId,
          policyId: this.state.selectedPolicyId,
          policy: policyObj.length > 0 && policyObj[0],
          data: {
            ALLOCATION: {
              allocationStatus: allocationStatus,
              allocatedIn: this.state.allocatedIn,
              formAccess: this.state.allocationFormAccess,
              formHeaderId: this.state.allocationFormHeaderId,
              formId: this.state.allocationFormId,
              formName: this.state.allocationFormName,
              id: this.state.allocationId,
              entryFields: allocationSubmitArray
            },
            REQUEST: {
              formAccess: this.state.requestFormAccess,
              formHeaderId: this.state.requestFormHeaderId,
              formId: this.state.requestFormId,
              formName: this.state.requestFormName,
              id: this.state.requestId,
              entryFields: requestFieldsObject
            },
            personalDayOff: {
              personalDayOff: this.state.personalDayOff,
              personalDayOffFrom: this.state.personalDayOffFrom,
              personalDayOffTO: this.state.personalDayOffTO
            },
            visaNumber: this.state.selectedVisa,
            passportNumber: this.state.selectedPassport
          }
        };
      } else {
        if (allocationStatus) {
          data = {
            companyId: this.state.companyId,
            TRIP_ID: this.state.tripId,
            userId: this.state.userId,
            policyId: this.state.selectedPolicyId,
            policy: policyObj.length > 0 && policyObj[0],
            data: {
              ALLOCATION: {
                allocationStatus: allocationStatus,
                allocatedIn: this.state.allocatedIn,
                formAccess: this.state.allocationFormAccess,
                formHeaderId: this.state.allocationFormHeaderId,
                formId: this.state.allocationFormId,
                formName: this.state.allocationFormName,
                id: this.state.allocationId,
                entryFields: allocationSubmitArray
              },
              REQUEST: {
                formAccess: this.state.requestFormAccess,
                formHeaderId: this.state.requestFormHeaderId,
                formId: this.state.requestFormId,
                formName: this.state.requestFormName,
                id: this.state.requestId,
                entryFields: requestFieldsObject
              },
              visaNumber: this.state.selectedVisa,
              passportNumber: this.state.selectedPassport
            }
          };
        } else if (personalDayOff) {
          data = {
            companyId: this.state.companyId,
            TRIP_ID: this.state.tripId,
            userId: this.state.userId,
            policyId: this.state.selectedPolicyId,
            policy: policyObj.length > 0 && policyObj[0],
            data: {
              REQUEST: {
                formAccess: this.state.requestFormAccess,
                formHeaderId: this.state.requestFormHeaderId,
                formId: this.state.requestFormId,
                formName: this.state.requestFormName,
                id: this.state.requestId,
                entryFields: requestFieldsObject
              },
              personalDayOff: {
                personalDayOff: this.state.personalDayOff,
                personalDayOffFrom: this.state.personalDayOffFrom,
                personalDayOffTO: this.state.personalDayOffTO
              },
              visaNumber: this.state.selectedVisa,
              passportNumber: this.state.selectedPassport
            }
          };
        } else {
          data = {
            companyId: this.state.companyId,
            TRIP_ID: this.state.tripId,
            userId: this.state.userId,
            policyId: this.state.selectedPolicyId,
            policy: policyObj.length > 0 && policyObj[0],
            data: {
              REQUEST: {
                formAccess: this.state.requestFormAccess,
                formHeaderId: this.state.requestFormHeaderId,
                formId: this.state.requestFormId,
                formName: this.state.requestFormName,
                id: this.state.requestId,
                entryFields: requestFieldsObject
              },
              visaNumber: this.state.selectedVisa,
              passportNumber: this.state.selectedPassport
            }
          };
        }
      }
      if (
        this.state.allocationStatus &&
        this.state.allocatedIn === "Percentage"
      ) {
        if (totalPercentage === 100) {
          var response = await cartServices.postTravelRequestFrom(data);
          if (response) {
            localStorage.removeItem("conncted_list_name")
            this.props.history.push("./expense");
            toast.success(response.message);
          }
        } else {
          toast.error(
            "Total Allocation percentage Should not be greater or less than 100%"
          );
        }
      } else {
        let response = await cartServices.postTravelRequestFrom(data);
        if (response) {
          localStorage.removeItem("conncted_list_name")
          this.props.history.push("./expense");
          toast.success(response.message);
        }
      }
    } else {
      if (this.state.allocationStatus && this.state.personalDayOff) {
        this.addAllocaValidator.showMessages();
        this.addRequestValidator.showMessages();
        this.validatorPersonalDayOff.showMessages();
        this.forceUpdate();
      } else if (this.state.personalDayOff) {
        this.addRequestValidator.showMessages();
        this.validatorPersonalDayOff.showMessages();
        this.forceUpdate();
      } else if (this.state.allocationStatus) {
        this.addAllocaValidator.showMessages();
        this.addRequestValidator.showMessages();
        this.forceUpdate();
      } else {
        this.addRequestValidator.showMessages();
        this.forceUpdate();
      }
    }
  };

  onUpdateSubmitHandler = async () => {
    let validationStatus = true;
    let isInternationalFlight = this.state.isInternationalFlight;
    let selectedPassport = this.state.selectedPassport;
    if (this.state.personalDayOff && this.state.allocationStatus) {
      if (this.state.previousEditAllocationForm.length > 0) {
        validationStatus =
          this.editAllocValidator.allValid() &&
          this.editRequestValidator.allValid() &&
          this.validatorPersonalDayOff.allValid();
      } else {
        validationStatus =
          this.editAllocValidator.allValid() &&
          this.addAllocaValidator.allValid() &&
          this.validatorPersonalDayOff.allValid();
      }
    } else if (this.state.allocationStatus) {
      if (this.state.previousEditAllocationForm.length > 0) {
        validationStatus =
          this.editAllocValidator.allValid() &&
          this.editRequestValidator.allValid();
      } else {
        validationStatus =
          this.editAllocValidator.allValid() &&
          this.addAllocaValidator.allValid();
      }
    } else if (this.state.personalDayOff) {
      validationStatus =
        this.editAllocValidator.allValid() &&
        this.validatorPersonalDayOff.allValid();
    } else {
      validationStatus = this.editRequestValidator.allValid();
    }
    if (isInternationalFlight) {
      validationStatus =
        selectedPassport !== "" &&
          selectedPassport !== undefined &&
          selectedPassport !== null
          ? true
          : false;
      if (!validationStatus) {
        toast.error("Please Add Passport");
      }
    }
    if (validationStatus) {
      this.setState({ isSubmit: true });
      let totalPercentage = 0;
      let fieldsObjects = [];
      let policyObj = this.state.policiesList.filter(
        policyObj => policyObj.id === this.state.selectedPolicyId
      );
      if (this.state.prevAllocationStatus) {
        fieldsObjects = [...this.state.expenseAllocationForm];
      } else {
        fieldsObjects = [...this.state.editAllocationForm];
      }
      let allocationSubmitArray = [];
      for (let val in fieldsObjects) {
        let internalArray = [];
        // for (let value in fieldsObjects[val]) {
        //   let val1 = fieldsObjects[val];
        //   let fieldId = val1[value].id;
        //   let columnName = val1[value].columnName;
        //   let fieldValue = "";
        //   if (columnName === "LOCATION_NAME") {
        //     fieldValue = this.state["alloc" + val1[value].fieldName + val]
        //       ? this.state["alloc" + val1[value].fieldName + val].value
        //       : "";
        //   } else if (columnName) {
        //     // fieldValue = this.state["alloc" + val1[value].fieldName + val];
        //     fieldValue = this.state[val1[value].fieldName + val] ? this.state[val1[value].fieldName + val] : this.state["alloc" + val1[value].fieldName + val];

        //     if (this.state[val1[value].fieldName + val]) {
        //       sessionStorage.setItem("trdId", this.state[val1[value].fieldName + val])
        //     }
        //     let data = fieldsObjects[val].filter((item, index) => item.fieldName === val1[value].fieldName)
        //     if (data[0].connectedListData) {
        //       let data1 = data[0].connectedListData.listManagementData
        //       let data2 = data1.filter((i) => sessionStorage.getItem("trdId") == i.id)
        //       let data3 = data2[0] ? data2[0].itemName : "";
        //       localStorage.setItem("conncted_list_name", data3)
        //     }
        //   }
        //   if (fieldValue === undefined && columnName) {
        //     if (sessionStorage.getItem("trdId1") === fieldValue) {
        //       internalArray.push({
        //         fieldId: fieldId,
        //         fieldValue: "",
        //         fieldItemName: localStorage.getItem("conncted_list_name")
        //       });
        //     } else {
        //       internalArray.push({
        //         fieldId: fieldId,
        //         fieldValue: "",
        //       })
        //     }
        //   } else if (columnName) {
        //     if (sessionStorage.getItem("trdId") === fieldValue) {
        //       internalArray.push({
        //         fieldId: fieldId,
        //         fieldValue: fieldValue,
        //         columnName: columnName,
        //         fieldItemName: localStorage.getItem("conncted_list_name")
        //       });
        //     } else {
        //       internalArray.push({
        //         fieldId: fieldId,
        //         fieldValue: fieldValue,
        //         columnName: columnName,
        //       });
        //     }

        //   }
        // }
        for (let value in fieldsObjects[val]) {
          let val1 = fieldsObjects[val];
          let fieldId = val1[value].id;
          let columnName = val1[value].columnName;
          let fieldValue = "";

          if (columnName === "LOCATION_NAME") {
            fieldValue = this.state["alloc" + val1[value].fieldName + val]
              ? this.state["alloc" + val1[value].fieldName + val].value
              : "";
          } else if (columnName) {
            if (this.state[val1[value].fieldName + val]) {
              sessionStorage.setItem("trdId", this.state[val1[value].fieldName + val])
            }
            let data = fieldsObjects[val].filter((item, index) => item.fieldName === val1[value].fieldName)
            if (data[0].connectedListData) {
              let data1 = data[0].connectedListData.listManagementData
              let data2 = data1.filter((i) => sessionStorage.getItem("trdId") == i.id)
              let data3 = data2[0] ? data2[0].itemName : "";
              localStorage.setItem("conncted_list_name", data3)
            }

            fieldValue = this.state[val1[value].fieldName + val] ? this.state[val1[value].fieldName + val] : this.state["alloc" + val1[value].fieldName + val];
            let array1 = fieldsObjects[val].filter((item, index) => item.fieldName === val1[value].fieldName)
            if (!array1[0].connectedListData && fieldValue !== "") {

              let companyId = this.state.companyId;
              let response = await expenseReport.getConnectedListDataForSpecific(
                fieldValue
              );
              sessionStorage.setItem("mtrdId", response.itemName)
            }

            if (fieldValue === undefined && columnName) {
              if (sessionStorage.getItem("trdId1") === fieldValue) {
                internalArray.push({
                  fieldId: fieldId,
                  fieldValue: "",
                  fieldItemName: localStorage.getItem("conncted_list_name") ? localStorage.getItem("conncted_list_name") : ""
                });
              } else {
                internalArray.push({
                  fieldId: fieldId,
                  fieldValue: "",
                  fieldItemName: sessionStorage.getItem("mtrdId") ? sessionStorage.getItem("mtrdId") : ""
                })
              }
            } else if (columnName) {
              if (sessionStorage.getItem("trdId") === fieldValue) {
                internalArray.push({
                  fieldId: fieldId,
                  fieldValue: fieldValue,
                  columnName: columnName,
                  fieldItemName: localStorage.getItem("conncted_list_name") ? localStorage.getItem("conncted_list_name") : ""
                });
              } else {
                internalArray.push({
                  fieldId: fieldId,
                  fieldValue: fieldValue,
                  columnName: columnName,
                  fieldItemName: sessionStorage.getItem("mtrdId") ? sessionStorage.getItem("mtrdId") : ""
                });
              }
            }

          }

        }
        totalPercentage = +(
          totalPercentage + +this.state["allocamount" + val]
        ).toFixed(2);
        internalArray.push({
          fieldName: "amount",
          fieldValue: this.state["allocamount" + val]
        });
        allocationSubmitArray.push(internalArray);
      }
      let requestFieldsObject = [...this.state.requestFieldsObject];
      let index = 0;
      for (const key of this.state.requestFormFields) {
        if (requestFieldsObject[index].columnName === "LOCATION_NAME") {
          requestFieldsObject[index]["fieldValue"] = this.state[key]
            ? this.state[key].value
            : "";
        } else {
          requestFieldsObject[index]["fieldValue"] = this.state[key];
        }
        index++;
      }
      let data = {};
      let allocationStatus = this.state.allocationStatus;
      let personalDayOff = this.state.personalDayOff;
      if (this.state.personalDayOff && allocationStatus) {
        data = {
          companyId: this.state.companyId,
          TRIP_ID: this.state.tripId,
          userId: this.state.userId,
          policyId: this.state.selectedPolicyId,
          policy: policyObj.length > 0 && policyObj[0],
          data: {
            ALLOCATION: {
              allocationStatus: this.state.allocationStatus,
              allocatedIn: this.state.allocatedIn,
              formAccess: this.state.allocationFormAccess,
              formHeaderId: this.state.allocationFormHeaderId,
              formId: this.state.allocationFormId,
              formName: this.state.allocationFormName,
              id: this.state.allocationId,
              entryFields: allocationSubmitArray
            },
            REQUEST: {
              formAccess: this.state.requestFormAccess,
              formHeaderId: this.state.requestFormHeaderId,
              formId: this.state.requestFormId,
              formName: this.state.requestFormName,
              id: this.state.requestId,
              entryFields: requestFieldsObject
            },
            personalDayOff: {
              personalDayOff: this.state.personalDayOff,
              personalDayOffFrom: this.state.personalDayOffFrom,
              personalDayOffTO: this.state.personalDayOffTO
            },
            visaNumber: this.state.selectedVisa,
            passportNumber: this.state.selectedPassport
          }
        };
      } else {
        if (allocationStatus) {
          data = {
            companyId: this.state.companyId,
            TRIP_ID: this.state.tripId,
            userId: this.state.userId,
            policyId: this.state.selectedPolicyId,
            policy: policyObj.length > 0 && policyObj[0],
            data: {
              ALLOCATION: {
                allocationStatus: this.state.allocationStatus,
                allocatedIn: this.state.allocatedIn,
                formAccess: this.state.allocationFormAccess,
                formHeaderId: this.state.allocationFormHeaderId,
                formId: this.state.allocationFormId,
                formName: this.state.allocationFormName,
                id: this.state.allocationId,
                entryFields: allocationSubmitArray
              },
              REQUEST: {
                formAccess: this.state.requestFormAccess,
                formHeaderId: this.state.requestFormHeaderId,
                formId: this.state.requestFormId,
                formName: this.state.requestFormName,
                id: this.state.requestId,
                entryFields: requestFieldsObject
              },
              visaNumber: this.state.selectedVisa,
              passportNumber: this.state.selectedPassport
            }
          };
        } else if (personalDayOff) {
          data = {
            companyId: this.state.companyId,
            TRIP_ID: this.state.tripId,
            userId: this.state.userId,
            policyId: this.state.selectedPolicyId,
            policy: policyObj.length > 0 && policyObj[0],
            data: {
              REQUEST: {
                formAccess: this.state.requestFormAccess,
                formHeaderId: this.state.requestFormHeaderId,
                formId: this.state.requestFormId,
                formName: this.state.requestFormName,
                id: this.state.requestId,
                entryFields: requestFieldsObject
              },
              personalDayOff: {
                personalDayOff: this.state.personalDayOff,
                personalDayOffFrom: this.state.personalDayOffFrom,
                personalDayOffTO: this.state.personalDayOffTO
              },
              visaNumber: this.state.selectedVisa,
              passportNumber: this.state.selectedPassport
            }
          };
        } else {
          data = {
            companyId: this.state.companyId,
            TRIP_ID: this.state.tripId,
            userId: this.state.userId,
            policyId: this.state.selectedPolicyId,
            policy: policyObj.length > 0 && policyObj[0],
            data: {
              REQUEST: {
                formAccess: this.state.requestFormAccess,
                formHeaderId: this.state.requestFormHeaderId,
                formId: this.state.requestFormId,
                formName: this.state.requestFormName,
                id: this.state.requestId,
                entryFields: requestFieldsObject
              },
              visaNumber: this.state.selectedVisa,
              passportNumber: this.state.selectedPassport
            }
          };
        }
      }
      if (
        this.state.allocationStatus &&
        this.state.allocatedIn === "Percentage"
      ) {
        if (totalPercentage === 100) {
          var response = await cartServices.UpdateTravelRequestFrom(data);
          if (response) {
            localStorage.removeItem("conncted_list_name")
            this.props.history.push("./expense");
            toast.success(response.message);
          }
        } else {
          toast.error(
            "Total Allocation percentage Should not be greater or less than 100%"
          );
        }
      } else {
        let response = await cartServices.UpdateTravelRequestFrom(data);
        if (response) {
          localStorage.removeItem("conncted_list_name")
          this.props.history.push("./expense");
          toast.success(response.message);
        }
      }
    } else {
      if (this.state.allocationStatus && this.state.personalDayOff) {
        if (this.state.previousEditAllocationForm.length > 0) {
          this.editRequestValidator.showMessages();
          this.editAllocValidator.showMessages();
          this.validatorPersonalDayOff.showMessages();
          this.forceUpdate();
        } else {
          this.addAllocaValidator.showMessages();
          this.editRequestValidator.showMessages();
          this.validatorPersonalDayOff.showMessages();
          this.forceUpdate();
        }
      } else if (this.state.allocationStatus) {
        if (this.state.previousEditAllocationForm.length > 0) {
          this.editRequestValidator.showMessages();
          this.editAllocValidator.showMessages();
          this.forceUpdate();
        } else {
          this.addAllocaValidator.showMessages();
          this.editRequestValidator.showMessages();
          this.forceUpdate();
        }
      } else if (this.state.personalDayOff) {
        this.editRequestValidator.showMessages();
        this.validatorPersonalDayOff.showMessages();
        this.forceUpdate();
      } else {
        this.editRequestValidator.showMessages();
        this.forceUpdate();
      }
    }
  };
  onPersonalDayofHandler = () => {
    this.setState({ personalDayOff: !this.state.personalDayOff });
  };
  inputChangeHandlerForm2 = (e, key) => {
    let temp = { ...this.state.form2 };
    temp[e.target.name] = e.target.value;
    this.setState({ form2: temp });
  };

  selectHandlerForm2 = e => {
    let temp = { ...this.state.form2 };
    temp[e.key] = e.value;
    this.setState({ form2: temp });
    if (e.key === "countryOfBirth") {
      this.getCitiesList(e.value);
    }
  };

  addEditForm2 = async e => {
    e.preventDefault();
    if (this.validator2.allValid()) {
      let temp = { ...this.state.form2 };
      temp["photoCopy"] = [...this.state.images];
      let data = {
        passport: temp,
        companyId: this.state.companyId
      };
      let res = await myAccountTravelSettingsServise.addPassport(data);
      if (res) {
        toast.success(res.message);
        // var editResponse = await cartServices.editTravelRequestFrom(this.state.companyId, this.state.tripId)
        const result = await cartServices.getAllPassports();
        if (result) {
          this.setState({
            availablePassports: result.passport,
            visa: result.visa
          });
        }
        $(".close").click();
      }
    } else {
      this.validator2.showMessages();
      this.forceUpdate();
    }
  };

  updatePassPort = (index, data) => {
    if (this.state.selectedPassportIndex !== index) {
      this.setState({
        selectedPassportIndex: index,
        form2: data,
        images: data.photoCopy
      });
    } else {
      this.setState({ selectedPassportIndex: "", form2: {}, images: [] });
    }
  };

  uploadImageHandler2 = e => {
    let selectedImage = e.target.files[0];
    const types = ["image/png", "image/jpeg", "image/gif"];
    if (selectedImage !== undefined) {
      if (types.every(type => selectedImage.type !== type)) {
        alert(`${selectedImage.type} is not supported`);
      } else {
        this.setState(
          {
            selectedImage
          },
          async () => {
            let fd = new FormData();
            fd.append("file", selectedImage);
            let res = await myAccountTravelSettingsServise.uploadImage(fd);
            if (res) {
              let temp = [...this.state.images2];
              temp.push(res.result[0]);
              this.setState({ images2: temp });
            }
          }
        );
      }
    }
  };

  selectedImageHandler = (data, index, key) => {
    this.setState({
      selectedImage: data,
      selectedGalary: key,
      selectedImageIndex: index
    });
  };

  handleDayChange = (date, form, key) => {
    let temp = { ...this.state[form] };
    temp[key] = date.toLocaleDateString();
    this.setState({ [form]: temp });
  };

  uploadImageHandler = e => {
    let selectedImage = e.target.files[0];
    const types = ["image/png", "image/jpeg", "image/gif"];
    if (selectedImage !== undefined) {
      if (types.every(type => selectedImage.type !== type)) {
        alert(`${selectedImage.type} is not supported`);
      } else {
        this.setState(
          {
            selectedImage
          },
          async () => {
            let fd = new FormData();
            fd.append("file", selectedImage);
            let res = await myAccountTravelSettingsServise.uploadImage(fd);
            if (res) {
              let temp = [...this.state.images];
              temp.push(res.result[0]);
              this.setState({ images: temp });
            }
          }
        );
      }
    }
  };
  removePhotoCopy = key => {
    let temp = this.state[this.state.selectedGalary];
    temp.splice(this.state.selectedImageIndex, 1);
    this.setState({ [this.state[this.state.selectedGalary]]: temp });
    // this.setState({selectedImage:data,selectedGalary:key,selectedImageIndex:index})
  };
  selectedImageHandler = (data, index, key) => {
    this.setState({
      selectedImage: data,
      selectedGalary: key,
      selectedImageIndex: index
    });
  };

  getCountriesList = async () => {
    this.setState({ passportModalLoading: true });
    var res = await myAccountTravelSettingsServise.getCountriesList();
    if (res) {
      this.setState({
        countriesList: res.result.map((data, index) => {
          return {
            issuedFor: data.name,
            key: "issuedFor",
            stateKey: "passport",
            value: data.name
          };
        }),
        countriesIssueList: res.result.map((data, index) => {
          return {
            countryOfIssue: data.name,
            key: "countryOfIssue",
            stateKey: "passport",
            value: data.name
          };
        }),
        countriesBirthList: res.result.map((data, index) => {
          return {
            countryOfBirth: data.name,
            key: "countryOfBirth",
            stateKey: "passport",
            value: data.name
          };
        })
      });
    }
  };

  getCitiesList = async value => {
    var res = await myAccountTravelSettingsServise.getCitiesList(value);
    if (res) {
      this.setState({
        placeIssueList: res.result.map((data, index) => {
          return {
            placeOfIssue: data.city,
            key: "placeOfIssue",
            stateKey: "passport",
            value: data.city
          };
        })
      });
    }
  };

  onAmountPercentageChangeHandler = async (event, fieldName, index) => {
    if (!isNaN(event.target.value)) {
      let value = event.target.value;
      await this.setState({ [fieldName]: event.target.value });
      this.pecentageRemainigAmountHandler(value, index);
    } else {
      this.setState({ [fieldName]: 0 });
      this.pecentageRemainigAmountHandler(0, index);
    }
  };
  onVisaSelecteHandler = value => {
    this.setState({ selectedVisa: value });
  };
  onPassportSelecteHandler = value => {
    this.setState({ selectedPassport: value });
  };


  handleScroll = async (e, id, assignKey, page, count, totalLength, search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    setValue(this.state, "clientHeight", e.target.scrollHeight)
    if (bottom) {
      this.setState({
        already: true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
      })

      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {

          let mergeOld = getValue(this.state.requestForm[assignKey], "connectedListData.listManagementData", []).concat(resp.result)
          setValue(this.state.requestForm[assignKey], "connectedListData.listManagementData", mergeOld)
          setValue(this.state.policyForm[assignKey], "page", page + 1)
          this.setState({
            ...this.state
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }
    }
  }
  handleSearchManage = async (search, id, assignKey) => {

    this.state.loading[assignKey] = true;
    this.state.search[assignKey] = search;
    this.setState({ search: this.state.search }, () => {
      $(`#valueConnect${assignKey}`).focus();
    })

    let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
    if (resp) {
      setValue(this.state.requestForm[assignKey], "connectedListData.listManagementData", resp.result)
    } else {
      setValue(this.state.requestForm[assignKey], "connectedListData.listManagementData", [])
    }
    setValue(this.state.requestForm[assignKey], "search", search)

    this.state.loading[assignKey] = false;
    this.setState({
      ...this.state
    })
    $(`#valueConnect${assignKey}`).focus();
    $(`#valueConnect${assignKey}`).attr('value', search);
    $(`#connect${assignKey}`).addClass("show")

  }

  setAlready = () => {
    if (this.state.already === true) {
      this.setState({ already: false })
    }
  }

  handleScroll1 = async (e, id, assignKey, page, count, totalLength, search) => {
    if (totalLength < count) {
      this.setAlready();
    }
    if (this.state.editAllocationForm.length <= 0) {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      setValue(this.state, "clientHeight", e.target.scrollHeight)
      if (bottom && !this.state.already) {
        this.setState({
          already: true
        }, () => {
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        })

        if (totalLength < count) {
          let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
          if (resp) {
            let findIndex = assignKey.split("-")
            let mergeOld = getValue(this.state.expenseAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", []).concat(resp.result)
            setValue(this.state.expenseAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", mergeOld)
            setValue(this.state.expenseAllocationForm[findIndex[0]][findIndex[1]], "page", page + 1)
          }
          this.setState({
            already: false
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }

    } else {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      setValue(this.state, "clientHeight", e.target.scrollHeight)
      if (bottom && !this.state.already) {
        this.setState({
          already: true
        }, () => {
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        })

        if (totalLength < count) {
          let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
          if (resp) {
            let findIndex = assignKey.split("-")
            let mergeOld = getValue(this.state.editAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", []).concat(resp.result)
            setValue(this.state.editAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", mergeOld)
            setValue(this.state.editAllocationForm[findIndex[0]][findIndex[1]], "page", page + 1)
          }
          this.setState({
            already: false
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }
    }
  }
  handleSearchManage1 = async (search, id, assignKey) => {
    if (this.state.already === true) {
      this.setState({ already: false })
    }

    if (this.state.editAllocationForm.length <= 0) {
      let findIndex = assignKey.split("-");
      this.state.loading[assignKey] = true;
      this.state.search[assignKey] = search;
      this.setState({ search: this.state.search }, () => {
        $(`#valueConnect${assignKey}`).focus();
      })

      let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
      if (resp) {
        setValue(this.state.expenseAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", resp.result)
      } else {
        setValue(this.state.expenseAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", [])
      }
      setValue(this.state.expenseAllocationForm[findIndex[0]][findIndex[1]], "search", search)
      this.state.loading[assignKey] = false;
      this.setState({
        ...this.state
      })
      $(`#valueConnect${assignKey}`).focus();
      $(`#valueConnect${assignKey}`).attr('value', search);
      $(`#connect${assignKey}`).addClass("show")
    } else {
      let findIndex = assignKey.split("-");
      this.state.loading[assignKey] = true;
      this.state.search[assignKey] = search;
      this.setState({ search: this.state.search }, () => {
        $(`#valueConnect${assignKey}`).focus();
      })

      let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
      if (resp) {
        setValue(this.state.editAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", resp.result)
      } else {
        setValue(this.state.editAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", [])
      }
      setValue(this.state.editAllocationForm[findIndex[0]][findIndex[1]], "search", search)
      this.state.loading[assignKey] = false;
      this.setState({
        ...this.state
      })
      $(`#valueConnect${assignKey}`).focus();
      $(`#valueConnect${assignKey}`).attr('value', search);
      $(`#connect${assignKey}`).addClass("show")
    }
  }
  render() {
    let requestForm = [...this.state.requestForm];
    let travelStartDate = requestForm.findIndex(
      fields => fields.columnName === "TRAVEL_DATE_FROM"
    );
    let travelEndDate = requestForm.findIndex(
      fields => fields.columnName === "TRAVEL_DATE_TO"
    );
    let selectedPolicy =
      this.state.policiesList.length > 0 &&
      this.state.policiesList.filter(
        list => list.id === this.state.selectedPolicyId
      );
    return !this.state.isLoading ? (
      <div>
        <MenuCartSubPage
          allowToExpenseTab={this.state.previousRequestStatus}
          checkItem={this.checkItem}
          submit={false}
          next="/booking/checkout/expense"
          baseCurrency={this.state.userBaseCurrency}
          cartList={this.state.cartDetails}
          nextButtonVisibility={this.state.previousRequestStatus}
        />

        <div className="p-padding top-48">
          <div className="main-box">
            <div className="p-padding bg-white mb-3">
              <div className="row">
                <div className="col-md-4">
                  <div className="">
                    <div className="form-group mb-0 custom labelup">
                      <div className="dropdown">
                        <button
                          disabled={this.state.isEdit}
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          {selectedPolicy[0] && selectedPolicy[0].policyName
                            ? selectedPolicy[0].policyName
                            : "Select Policy"}
                        </button>
                        <div className="dropdown-menu">
                          {this.state.policiesList.length > 0 &&
                            this.state.policiesList.map(list => (
                              <button
                                key={list.id}
                                onClick={() =>
                                  this.policySelectHandler(list.id)
                                }
                                className="dropdown-item"
                              >
                                {list.policyName}
                              </button>
                            ))}
                        </div>
                      </div>
                      <label>Request Policy</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-8"></div>
              </div>
            </div>
            {this.state.selectedPolicyId !== "" &&
              this.state.selectedPolicyId !== undefined &&
              this.state.selectedPolicyId !== "" && (
                <>
                  <div className="bg-light heading">
                    <h6>Request Details</h6>
                  </div>
                  <div className="p-padding">
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="row">
                          {!this.state.previousRequestStatus ? (
                            <RequestDynamicForm
                              {...this.state}
                              validator={this.addRequestValidator}
                              policyForm={this.state.requestForm}
                              onClickHandler={this.onClickHandler}
                              onChangeHandler={this.onChangeHandler}
                              onDatePickerHandler={this.onDatePickerHandler}
                              onCheckboxHandler={this.onCheckboxHandler}
                              onIntegerHandler={this.onIntegerHandler}
                              userPermission={this.state.userPermission}
                              onConnectedListkHandler={
                                this.onConnectedListkHandler
                              }
                              onLocationChangekHandler={
                                this.onLocationChangekHandler
                              }
                              loadOptions={this.loadOptions}
                              handleScroll={this.handleScroll}
                              handleSearchManage={this.handleSearchManage}
                            />
                          ) : (
                              <RequestDynamicForm
                                {...this.state}
                                validator={this.editRequestValidator}
                                policyForm={this.state.requestForm}
                                onClickHandler={this.onClickHandler}
                                onChangeHandler={this.onChangeHandler}
                                onDatePickerHandler={this.onDatePickerHandler}
                                onCheckboxHandler={this.onCheckboxHandler}
                                onIntegerHandler={this.onIntegerHandler}
                                userPermission={this.state.userPermission}
                                onConnectedListkHandler={
                                  this.onConnectedListkHandler
                                }
                                onLocationChangekHandler={
                                  this.onLocationChangekHandler
                                }
                                loadOptions={this.loadOptions}
                                handleScroll={this.handleScroll}
                                handleSearchManage={this.handleSearchManage}
                              />
                            )}
                        </div>
                        {/* Allocation Code Starts */}
                        <div className="">
                          <div className=
                            {this.state.companyId == "5dc5197e1382a2538aa3a8ca" ? "d-none " :
                              // "d-none"}
                              "d-block form-group mt-4 mb-5 add-border"}
                          >
                            <label className="client">Allocation</label>

                            <div className="form-group mb-0 px-padding py-4">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={this.state.allocationStatus}
                                  onChange={this.onAllocationChangeHandler}
                                />
                                <span className="slider round" />
                              </label>
                              <span>Allocation</span>
                            </div>


                            {this.state.allocationStatus && (
                              <div className="table-container mx-0 ">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="btn-group-margin p-3">
                                      {/* <div className="dropdown">
                                        <button
                                          type="button"
                                          className="btn dropdown-toggle"
                                          data-toggle="dropdown"
                                        >
                                          Allocated By
                                    </button>
                                        <div className="dropdown-menu">
                                          <button
                                            onClick={(e) => this.onAllocationInHandler(e, 'Percentage')}
                                            className="dropdown-item">
                                            Percentage
                                      </button>
                                          <button
                                            onClick={(e) => this.onAllocationInHandler(e, 'Amount')}
                                            className="dropdown-item">
                                            Amount
                                      </button>
                                        </div>
                                      </div> */}

                                      {this.state.previousEditAllocationForm &&
                                        this.state.previousEditAllocationForm
                                          .length > 0 ? (
                                          <button
                                            onClick={this.onAddEditClickHandler}
                                            type="button"
                                            className="btn"
                                          >
                                            Add
                                          </button>
                                        ) : (
                                          <button
                                            onClick={this.onAddClickHandler}
                                            disabled={
                                              this.state.expenseAllocationForm
                                                .length <= 0
                                            }
                                            type="button"
                                            className="btn"
                                          >
                                            Add
                                          </button>
                                        )}
                                      {this.state.previousEditAllocationForm &&
                                        this.state.previousEditAllocationForm
                                          .length > 0 ? (
                                          <button
                                            disabled={
                                              !(
                                                this.state.selectedIndex.length >
                                                0 &&
                                                this.state.editAllocationForm
                                                  .length -
                                                this.state.selectedIndex
                                                  .length >
                                                0
                                              )
                                            }
                                            onClick={
                                              this.onEditDeleteAllocationField
                                            }
                                            type="button"
                                            className="btn"
                                          >
                                            Delete
                                          </button>
                                        ) : (
                                          <button
                                            disabled={
                                              !(
                                                this.state.selectedIndex.length >
                                                0 &&
                                                this.state.expenseAllocationForm
                                                  .length > 1
                                              )
                                            }
                                            onClick={this.onDeleteAllocationField}
                                            type="button"
                                            className="btn"
                                          >
                                            Delete
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="px-3">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="amount-show total-amount">
                                        <h6>Total</h6>
                                        <h5>
                                          {this.state.userBaseCurrency}{" "}
                                          {new Intl.NumberFormat("en-IN", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          }).format(
                                            (this.state
                                              .totalSelectesExpenseAmount *
                                              100) /
                                            100
                                          )}
                                          {/* {((this.state.totalSelectesExpenseAmount * 100) / 100)} */}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="amount-show total-itemised">
                                        <h6>Allocated</h6>
                                        {this.state.totalSelectesExpenseAmount -
                                          this.state.totalAllocatedAmount !==
                                          0 ? (
                                            <>
                                              <h5>
                                                {this.state.userBaseCurrency}
                                                &nbsp;
                                              {new Intl.NumberFormat("en-IN", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2
                                                }).format(
                                                  (this.state.allocatedAmounts *
                                                    100) /
                                                  100
                                                )}
                                                (
                                              {this.state.allocatedAmounts
                                                  ? new Intl.NumberFormat(
                                                    "en-IN",
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2
                                                    }
                                                  ).format(
                                                    (((this.state
                                                      .allocatedAmounts *
                                                      100) /
                                                      this.state
                                                        .totalSelectesExpenseAmount) *
                                                      100) /
                                                    100
                                                  ) + "%"
                                                  : "0%"}
                                                ){" "}
                                                {/* ({this.state.allocatedAmounts ? (((this.state.allocatedAmounts * 100) / this.state.totalSelectesExpenseAmount * 100) / 100) + '%' : '0%'}) */}
                                              </h5>
                                            </>
                                          ) : (
                                            <h5>
                                              {this.state.userBaseCurrency}
                                              {new Intl.NumberFormat("en-IN", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                              }).format(
                                                (this.state
                                                  .totalSelectesExpenseAmount *
                                                  100) /
                                                100
                                              ) + "(100%)"}
                                              {/* {((this.state.totalSelectesExpenseAmount * 100) / 100) + '(100%)'} */}
                                            </h5>
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="amount-show total-pending">
                                        <h6>Remaining</h6>
                                        <h5>
                                          {this.state.userBaseCurrency} {""}
                                          {this.state.totalAllocatedAmount > 0
                                            ? new Intl.NumberFormat("en-IN", {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            }).format(
                                              ((this.state
                                                .totalSelectesExpenseAmount -
                                                this.state
                                                  .totalAllocatedAmount) *
                                                100) /
                                              100
                                            )
                                            : 0.0}
                                          {/* {this.state.totalAllocatedAmount > 0 ? (((this.state.totalSelectesExpenseAmount - this.state.totalAllocatedAmount) * 100) / 100) : 0.00} */}{" "}
                                          (
                                          {this.state.totalAllocatedAmount
                                            ? Math.floor(
                                              (((this.state
                                                .totalSelectesExpenseAmount -
                                                this.state
                                                  .totalAllocatedAmount) *
                                                100) /
                                                this.state
                                                  .totalSelectesExpenseAmount) *
                                              100
                                            ) /
                                            100 +
                                            "%"
                                            : "0%"}
                                          )
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  {/* Check for previous allocation created or not */}
                                  {this.state.editAllocationForm &&
                                    this.state.editAllocationForm.length <= 0 ? (
                                      <ExpenseAllocationTable
                                        {...this.state}
                                        selectedIndex={this.state.selectedIndex}
                                        allocatedIn={this.state.allocatedIn}
                                        validator={this.addAllocaValidator}
                                        onClickHandler={
                                          this.onAllocationClickHandler
                                        }
                                        onChangeHandler={this.onChangeHandler}
                                        onDatePickerHandler={
                                          this.onDatePickerHandler
                                        }
                                        onCheckboxHandler={this.onCheckboxHandler}
                                        onIntegerHandler={this.onIntegerHandler}
                                        expenseAllocationForm={
                                          this.state.expenseAllocationForm
                                        }
                                        onSelectedAllocationFieldHandler={
                                          this.onSelectedAllocationFieldHandler
                                        }
                                        tableHead={this.state.tableHead}
                                        userPermission={this.state.userPermission}
                                        onConnectedListkHandler={
                                          this.onAllocationConnectedListkHandler
                                        }
                                        onAmountPercentageChangeHandler={
                                          this.onAmountPercentageChangeHandler
                                        }
                                        onLocationChangekHandler={
                                          this.onLocationChangekHandler
                                        }
                                        loadOptions={this.loadOptions}
                                        handleScroll={this.handleScroll1}
                                        handleSearchManage={this.handleSearchManage1}
                                      />
                                    ) : (
                                      <EditExpenseAllocationTable
                                        {...this.state}
                                        selectedIndex={this.state.selectedIndex}
                                        allocatedIn={this.state.allocatedIn}
                                        validator={this.editAllocValidator}
                                        onClickHandler={
                                          this.onAllocationClickHandler
                                        }
                                        onChangeHandler={this.onChangeHandler}
                                        onDatePickerHandler={
                                          this.onDatePickerHandler
                                        }
                                        onCheckboxHandler={this.onCheckboxHandler}
                                        onIntegerHandler={this.onIntegerHandler}
                                        expenseAllocationForm={
                                          this.state.expenseAllocationForm
                                        }
                                        onEditSelectedAllocationFieldHandler={
                                          this
                                            .onEditSelectedAllocationFieldHandler
                                        }
                                        tableHead={this.state.tableHead}
                                        onAmountPercentageChangeHandler={
                                          this.onAmountPercentageChangeHandler
                                        }
                                        userPermission={this.state.userPermission}
                                        onConnectedListkHandler={
                                          this.onAllocationConnectedListkHandler
                                        }
                                        editAllocationForm={
                                          this.state.editAllocationForm
                                        }
                                        allocationEditIds={
                                          this.state.allocationEditIds
                                        }
                                        onLocationChangekHandler={
                                          this.onLocationChangekHandler
                                        }
                                        loadOptions={this.loadOptions}
                                        handleScroll={this.handleScroll1}
                                        handleSearchManage={this.handleSearchManage1}
                                      />
                                    )}
                                </div>
                              </div>
                            )}
                            <p className="px-3">
                              The cost of this trip will be allocated to your
                              default cost center.
                            </p>
                          </div>
                          {travelStartDate >= 0 &&
                            travelEndDate >= 0 &&
                            moment(
                              requestForm[travelStartDate].fieldValue
                            ).format("DD MMM YYYY") !==
                            moment(
                              requestForm[travelEndDate].fieldValue
                            ).format("DD MMM YYYY") && (
                              <div className="form-group add-border">
                                <label className="client">
                                  Personal Day off
                                </label>
                                <div className="form-group mb-0 px-padding py-4">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={this.state.personalDayOff}
                                      onChange={this.onPersonalDayofHandler}
                                    />
                                    <span className="slider round" />
                                  </label>
                                  <span>Personal Day Off</span>
                                </div>
                                {this.state.personalDayOff && (
                                  <div className="px-padding">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group custom labelup">
                                          <DayPickerInput
                                            format="DD MMM YYYY"
                                            placeholder={
                                              this.state.personalDayOffFrom
                                                ? moment(
                                                  this.state
                                                    .personalDayOffFrom
                                                ).format("DD MMM YYYY")
                                                : "Select Personal Day Off From"
                                            }
                                            formatDate={formatDate}
                                            dayPickerProps={{
                                              locale: "en-gb",
                                              month: new Date(
                                                this.state.personalDayOffFromDatePicker
                                              ),
                                              localeUtils: MomentLocaleUtils,
                                              disabledDays: {
                                                after: new Date(
                                                  this.state.personalDayOffTODatePicker
                                                ),
                                                before: new Date(
                                                  this.state.personalDayOffFromDatePicker
                                                )
                                              }
                                            }}
                                            onDayChange={
                                              this.onPersonalDayofFromHandler
                                            }
                                          />
                                          <label>From</label>
                                          <div className="help-block">
                                            {this.validatorPersonalDayOff.message(
                                              "Select Personal Day Off From",
                                              this.state.personalDayOffFrom,
                                              "required"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group custom labelup">
                                          <DayPickerInput
                                            placeholder={
                                              this.state.personalDayOffTO
                                                ? this.state.personalDayOffTO
                                                : "Select Personal Day Off To"
                                            }
                                            formatDate={formatDate}
                                            format="DD MMM YYYY"
                                            dayPickerProps={{
                                              month: new Date(
                                                this.state.personalDayOffFromDatePicker
                                              ),
                                              locale: "en-gb",
                                              localeUtils: MomentLocaleUtils,
                                              disabledDays: {
                                                after: new Date(
                                                  this.state.personalDayOffTODatePicker
                                                ),
                                                before: new Date(
                                                  this.state.personalDayOffFromDatePicker
                                                )
                                              }
                                            }}
                                            onDayChange={
                                              this.onPersonalDayofToHandler
                                            }
                                          />
                                          <label>To</label>
                                          <div className="help-block">
                                            {this.validatorPersonalDayOff.message(
                                              "Select Personal Day Off To",
                                              this.state.personalDayOffTO,
                                              "required"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                        </div>



                        {!this.state.isSubmit ? (
                          <div className="btn-group-margin">
                            {this.state.previousEditAllocationForm &&
                              (this.state.previousEditAllocationForm.length <=
                                0 &&
                                !this.state.prevAllocationStatus) ? (
                                <button
                                  type="button"
                                  onClick={this.onSubmitHandler}
                                  disabled={this.state.isLoading}
                                  className="btn"
                                >
                                  Save
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  disabled={this.state.isLoading}
                                  onClick={this.onUpdateSubmitHandler}
                                  className="btn"
                                >
                                  Update
                                </button>
                              )}
                          </div>
                        ) : (
                            <div className="btn-group-margin">
                              <button
                                type="button"
                                className="btn btn-border-success lg-padding"
                              >
                                Please Wait ...
                            </button>
                            </div>
                          )}
                      </div>
                      {this.state.isInternationalFlight && (
                        <div className="col-md-3">
                          <div className="form-group mydivs ">
                            <div className="border-bottom">
                              <div className="float-left set-pad">
                                <h6>Passports</h6>
                              </div>
                              <div className="float-right px-3 py-2">
                                <button
                                  type="button"
                                  className="btn btn-nothing text-primary pt-2 px-2"
                                  data-toggle="modal"
                                  data-target="#addPassportModal"
                                  onClick={this.handlePassportAddClick}
                                >
                                  Add
                                </button>
                              </div>
                              <div className="clearfix" />
                            </div>
                            {this.state.availablePassports &&
                              this.state.availablePassports.map(
                                (details, index) => (
                                  <div
                                    key={details.id}
                                    className="country-passport"
                                  >
                                    <div className="first-panel">
                                      <label className="checkbox-container mb-0">
                                        <input
                                          type="radio"
                                          name="passport"
                                          onChange={() =>
                                            this.onPassportSelecteHandler(
                                              details.id
                                            )
                                          }
                                          checked={
                                            this.state.selectedPassport ===
                                            details.id
                                          }
                                        />
                                        <span className="checkmark" />
                                        <span>
                                          {details.countryOfIssue} Passport
                                        </span>
                                      </label>
                                    </div>
                                    <div className="second-panel">
                                      <div className="float-left">
                                        <h6>Passport Number</h6>
                                        <h5>{details.passportNumber}</h5>
                                      </div>
                                      <div className="clearfix" />
                                      <div className="dates">
                                        <div className="float-left">
                                          <h6>ISSUE DATE</h6>
                                          <h5>
                                            {details.dateOfIssue &&
                                              moment(
                                                details.dateOfIssue
                                              ).format("D MMM YYYY")}
                                          </h5>
                                        </div>
                                        <div className="float-right">
                                          <h6>Expire Date</h6>
                                          <h5>
                                            {details.expiryDate &&
                                              moment(details.expiryDate).format(
                                                "D MMM YYYY"
                                              )}
                                          </h5>
                                        </div>
                                        <div className="clearfix" />
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                          <div className="form-group mydivs ">
                            <div className="border-bottom">
                              <div className="float-left set-pad">
                                <h6>Visa</h6>
                              </div>
                              <div className="clearfix" />
                            </div>
                            {this.state.visa &&
                              this.state.visa.map((details, index) => (
                                <div
                                  key={details.id}
                                  className="country-passport"
                                >
                                  <div className="first-panel">
                                    <label className="checkbox-container mb-0">
                                      <input
                                        type="radio"
                                        name="visa"
                                        onChange={() =>
                                          this.onVisaSelecteHandler(details.id)
                                        }
                                        checked={
                                          this.state.selectedVisa === details.id
                                        }
                                      />
                                      <span className="checkmark" />
                                      <span>{details.countryOfIssue} Visa</span>
                                    </label>
                                  </div>
                                  <div className="second-panel">
                                    <div className="float-left">
                                      <h6>Visa Number</h6>
                                      <h5>{details.visaNumber}</h5>
                                    </div>
                                    <div className="clearfix" />
                                    <div className="dates">
                                      <div className="float-left">
                                        <h6>ISSUE DATE</h6>
                                        <h5>
                                          {details.dateOfIssue &&
                                            moment(details.dateOfIssue).format(
                                              "D MMM YYYY"
                                            )}
                                        </h5>
                                      </div>
                                      <div className="float-right">
                                        <h6>Expire Date</h6>
                                        <h5>
                                          {details.expiryDate &&
                                            moment(details.expiryDate).format(
                                              "D MMM YYYY"
                                            )}
                                        </h5>
                                      </div>
                                      <div className="clearfix" />
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            {this.state.isInternationalFlight && (
              <AddPassportModal
                state={this.state}
                addEditForm2={this.addEditForm2}
                validator2={this.validator2}
                selectHandlerForm2={this.selectHandlerForm2}
                uploadImageHandler2={this.uploadImageHandler2}
                handleDayChange={this.handleDayChange}
                selectedImageHandler={this.selectedImageHandler}
                inputChangeHandlerForm2={this.inputChangeHandlerForm2}
                uploadImageHandler={this.uploadImageHandler}
                removePhotoCopy={this.removePhotoCopy}
                passportModalLoading={this.state.passportModalLoading}
              />
            )}
          </div>
        </div>
      </div>
    ) : (
        <div className="p-padding">
          <div className="form-holder">
            <Loader />
          </div>
        </div>
      );
  }
}
export default TravelRequestDetailsPage;
