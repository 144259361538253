import React, { Component } from 'react';
import { Select } from 'src/_components/common/_forms';
import DefaultField from '../../../../company/forms/modify-form-fields2/components/DefaultField';
import { operators } from 'src/_components/common/auditWorkflowOperator';
import ReactSelect from 'react-select';
// import DifferentFieldList from "../../../company/audit-rules/custom-step2/components/differentFieldList";
import DifferentFieldList from './differentFieldList';
import dynamicFields from "src/_components/common/dynamicFields";
import _ from "lodash";
import { setValue, getValue } from 'src/_components/common/lodsh';
import { getConnectedList } from 'src/_services';
import $ from 'jquery';
import AuditSearchScroll from "./AuditSearchScroll"


export default class ExpressionComponent extends Component {

  constructor(props){
    super(props);
    this.state = {
      search : [],
      loading:[],
      already:false,
    }
  }
  componentWillUnmount(){
     this.state = {
      search : [],
      loading:[]
    }
  }

  componentDidMount(){
    this.getConnectedValue()
  }
  componentWillReceiveProps(){
    this.getConnectedValue()
  }
getConnectedValue = async () => {
    let parentFeild = getValue(this.props.expression,"value.parentsFields",[]);
    let type = getValue(this.props.expression,"value.selectedField.dataType");
    let fieldLevel = getValue(this.props.expression,"value.selectedField.fieldLevel");
    if(parentFeild.length !== 0){
      parentFeild.forEach(async item => {
        if(item.connectedListId && item.already === undefined){
          let resp = await getConnectedList(localStorage["440a28"],'',item.connectedListId,1,10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp,"count",0);
          item.connectedListData.listManagementData = resp.result;
          item.search=''
          item.already = true
          this.setState({...this.state});
        }
      })
    }else if (type === "connected_list" && fieldLevel === 1 &&  getValue(this.props.expression,"value.selectedField.already") === undefined){
      let connectedListId = getValue(this.props.expression,"value.selectedField.connectedListData.id");
      let resp = await getConnectedList(localStorage["440a28"],'',connectedListId,1,10);
      setValue(this.props.expression,"value.selectedField.connectedListDataCount",resp.count);
      setValue(this.props.expression,"value.selectedField.page",1);
      setValue(this.props.expression,"value.selectedField.search",'');
      setValue(this.props.expression,"value.selectedField.already",true);
      setValue(this.props.expression,"value.selectedField.connectedListDataCount",resp.count);
      setValue(this.props.expression,"value.selectedField.connectedListData.listManagementData",resp.result)
    }
    this.setState({...this.state});
  }
  handleScroll = async (e, id, assignKey, page, count, totalLength,search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    setValue(this.state,"clientHeight",e.target.scrollHeight)
    if (bottom && !this.state.already) {
      this.setState({
        already:true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight-25)
      })
      if (totalLength < count) {
        let resp = await getConnectedList(localStorage["440a28"], search, id, page + 1, 10)
        if (resp) {
          let parentFeild = getValue(this.props.expression,"value.parentsFields",[])
          let findIndex = assignKey.split("-")
          if(parentFeild.length !== 0) {
            let mergeOld = getValue(parentFeild[findIndex[0]],"connectedListData.listManagementData",[]).concat(resp.result)
            setValue(parentFeild[findIndex[0]],"connectedListData.listManagementData",mergeOld)
            setValue(parentFeild[findIndex[0]],"page",page+1)
          }else{
            let mergeOld = getValue(this.props.expression,"value.selectedField.connectedListData.listManagementData",[]).concat(resp.result)
            setValue(this.props.expression,"value.selectedField.page",page+1);
            setValue(this.props.expression,"value.selectedField.connectedListData.listManagementData",mergeOld)
          }
          this.setState({
            already:false
          })
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight-25)
        }
      }
    }
  }


  handleSearchManage = async (search, id, assignKey) => {
      this.state.loading[assignKey] = true;
      this.state.search[assignKey] = search;
      this.setState({search:this.state.search}, () => {
        $(`#valueConnect${assignKey}`).focus();
      })

      let resp = await getConnectedList(localStorage["440a28"], search, id, 1, 10)
      let parentFeild = getValue(this.props.expression,"value.parentsFields",[])
      let findIndex = assignKey.split("-")
      if(parentFeild.length !== 0) {
        if (resp) {
          setValue(parentFeild[findIndex[0]], "connectedListData.listManagementData", resp.result)
        } else {
          setValue(parentFeild[findIndex[0]], "connectedListData.listManagementData", [])
        }
        setValue(parentFeild[findIndex[0]], "search", search)
      }else{
        setValue(this.props.expression,"value.selectedField.connectedListData.listManagementData", resp.result)
        setValue(this.props.expression, "search", search)
      }
      this.state.loading[assignKey] = false;
      this.setState({
        ...this.state
      })
      $(`#valueConnect${assignKey}`).focus();
      // $(`#valueConnect${assignKey}`).attr('value', search);
      $(`#connect${assignKey}`).addClass("show")
  }

  getConnectedListData = (connectedListId) => {
    return  getConnectedList(localStorage["440a28"],'',connectedListId,1,10).then(data => {
      return data.result.map(list => {
      return {
        label: list.itemName,
        value: list.id,
        id: list.id
      };
    });
    })
  }
  updateProps = (e,id) => {
    this.getConnectedValue()
    this.props.handleFieldNameSelection(e, id, 'change')
  }
  handleChoose = (e,form,id,assignKey) => {
    this.props.fieldValueSelectHandler(e,id)
    this.state.search[assignKey] = '';
    this.setState({search:this.state.search})
  }
  handleSelectMulti = async (e,form,id,assignKey) => {
    await this.props.onMultiLblConnectHandler(e,form,id);
    this.state.search[assignKey] = '';
    this.setState({search:this.state.search})
  }


  render() {
    return (
      <div className="select-div">
        <div className="abs">
          <label className="checkbox-container">
            <input type="checkbox"
              onChange={e => this.props.expressionCheckboxHandler(e, this.props.expression.id)}
              checked={this.props.expression ?.value ?.isChecked || false}
            />
            <span className="checkmark" />
          </label>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group custom labelup">
              <label>Starting Parentheses</label>
              <Select
                placeholder={this.props.expression ?.value ?.firstBracket || 'Select Parentheses'}
                options={this.props.firstBracketOptions}
                keyName='value'
                controlFunc={e => this.props.handleParenthesesSelection(e, this.props.expression.id, '(')}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group custom labelup">
              <label>Data Object <span className="text-danger">*</span></label>
              <Select
                placeholder={this.props.expression ?.value ?.formHeader ?.formHeaderName || 'Select Form Header'}
                options={this.props.allFormHeaders}
                keyName='formHeaderName'
                controlFunc={e => this.props.handleFormHeaderSelection(e, this.props.expression.id)}
                error={this.props.validator.message('Form Header', this.props.expression ?.value ?.formHeader ?.formHeaderName, 'required')}
              />

            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="form-group custom labelup">
              <label>Field Name <span className="text-danger">*</span></label>
              <Select
                placeholder={this.props['isLoading' + this.props.expression.id] ? 'Loading...' : this.props.expression ?.value ?.selectedField ?.columnName || 'Select Field Name'}
                options={this.props ?.allFieldNames[this.props.expression ?.value ?.formHeader ?.id] || []}
                keyName='columnName'
                controlFunc={e => this.updateProps(e, this.props.expression.id)}
              />
              {/* <div className="help-block">
                {this.props.error.message(
                  "Field Value",
                  this.props.expression ?.value ?.selectedField ?.columnName,
                  "required"
                )}
              </div> */}
                  <div className="text-danger mb-4">
                     {this.props.validator.message(
                        "Field Value",
                        this.props.expression?.value?.selectedFieldValue,
                        "required"
                      )}
                    </div>
            </div>
          </div>
        </div>
        <div className="row">
          {this.props.expression.value ?.parentsFields && this.props.expression.value ?.parentsFields.map((parentsFields,index) => {
            let expressionId = this.props.expression.id;
            let selectedDropDown = getValue(this.props.expression,`value[${parentsFields.fieldName}].label`,'');
            if (parentsFields.fieldLevel === 1) {
              return (<div key={parentsFields.id} className="col-xl-4 col-lg-6">
                <div className="form-group custom labelup">
                  <label>{parentsFields.labelName}</label>
                  <AuditSearchScroll
                     options={parentsFields.connectedListData && parentsFields.connectedListData.listManagementData}
                     keyName={"itemName"}
                     refId={parentsFields.connectedListId}
                     assignKey={`${index}-${this.props.idx}`}
                     page={parentsFields.page}
                     form={parentsFields}
                     expressionId={expressionId}
                     handleSearch={this.handleSearchManage}
                     count={parentsFields.connectedListDataCount}
                     handleScroll={this.handleScroll}
                     loading={getValue(this.state.loading,[`${index}-${this.props.idx}`],false)}
                     onClickHandler={this.handleSelectMulti}
                     value={getValue(this.state.search,[`${index}-${this.props.idx}`],"")}
                     placeholder={selectedDropDown ? selectedDropDown : "Select an option"}
                  />
                  {/* <div className="help-block">
                    {this.props.error.message(
                      "Field Value",
                      this.props.expression.value[parentsFields.fieldName] ?.value,
                      "required"
                    )}
                  </div>
                   */}
                   <div className="text-danger mb-4">
                     {this.props.validator.message(
                        "Field Value",
                        this.props.expression?.value?.selectedFieldValue,
                        "required"
                      )}
                    </div>
                </div>
              </div>)
            } else if (parentsFields.fieldLevel > 1) {
              return (<div key={parentsFields.id} className="col-xl-4 col-lg-6">
                <div className="form-group custom labelup">
                  <label>{parentsFields.labelName}</label>
                  <ReactSelect
                    options={this.props.expression.value[parentsFields.columnName]}
                    value={this.props.expression.value[parentsFields.fieldName]}
                    onChange={(e) => this.props.onMultiLblConnectHandler(e, parentsFields, expressionId)}
                  />
            <div className="text-danger mb-4">
                     {this.props.validator.message(
                        "Field Value",
                        this.props.expression?.value?.selectedFieldValue,
                        "required"
                      )}
                    </div>
                </div>
              </div>)
            }
          }
          )}
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <div className="form-group custom labelup">
              <label>Operator <span className="text-danger">*</span></label>
              <Select
                placeholder={this.props.expression ?.value ?.operator ?.operatorName || 'Select a Operator'}
                options={operators.filter(operator => operator.dataType.includes(this.props.expression ?.value ?.selectedField ?.dataType)) || operators}
                keyName='operatorName'
                controlFunc={e => this.props.handleExpressionOperator(e, this.props.expression.id)}
              />
              <div className="text-danger mb-4">
                     {this.props.validator.message(
                        "Field Value",
                        this.props.expression?.value?.selectedFieldValue,
                        "required"
                      )}
                    </div>
            </div>
          </div>
        </div>

        <div className="row">
          {this.props.expression ?.value ?.selectedField && this.props.expression.value.selectedField.dataType === dynamicFields.DATA_TYPE_AMOUNT &&
            <div className="col-xl-4 col-lg-6">
              <div className="form-group custom labelup mb-0">
                <label>Select Currency</label>
                <ReactSelect
                  options={this.props.allCurrecies}
                  value={this.props.expression ?.value ?.currency}
                  onChange={(e) => this.props.onCurrencySelectHandler(e, this.props.expression.id)}
                />

                <div className="text-danger mb-4">
                     {this.props.validator.message(
                        "Field Value",
                        this.props.expression?.value?.selectedFieldValue,
                        "required"
                      )}
                    </div>
              </div>
            </div>
            }
          {this.props.expression ?.value ?.selectedField ?
            (<div className="col-xl-4 col-lg-6">
              {this.props.expression.value.selectedField.dataType !== "connected_list"  && this.props.expression.value.selectedField.dataType !== "different_list" || this.props.expression.value.selectedField.columnName === "REIMBURSEMENT_CURRENCY" || this.props.expression.value.selectedField.columnName === "CURRENCY" ?
                <div className="">
                  <DefaultField
                    type={this.props.expression ?.value ?.selectedField.dataType}
                    fieldName={_.camelCase(this.props.expression ?.value ?.selectedField.fieldName)}
                    value={this.props.expression.value ?.selectedFieldValue}
                    selectPlaceholder={this.props.expression ?.value ?.selectedFieldValue ?.itemName || "Select a Value"}
                    connectedListData={this.props.expression ?.value ?.selectedField ?.connectedListData}
                    defaultValueChangeHandler={e => this.props.fieldValueChangeHandler(e, this.props.expression.id, this.props.expression ?.value ?.selectedField.dataType)}
                    defaultSelectHandler={e => this.props.fieldValueSelectHandler(e, this.props.expression.id)}
                    loadOptions={this.props.loadOptions}
                  />
                  <div className="text-danger mb-4">
                     {this.props.validator.message(
                        "Field Value",
                        this.props.expression?.value?.selectedFieldValue,
                        "required"
                      )}
                    </div>
                </div>
                :(this.props.expression.value.selectedField.dataType === "connected_list") ?
                <div key={getValue(this.props.expression,"value.selectedField.id")} className="">
                <div className="form-group custom labelup">
                  <label>{getValue(this.props.expression,"value.selectedField.labelName")}</label>
                      <AuditSearchScroll
                      options={getValue(this.props.expression,"value.selectedField.connectedListData.listManagementData",[])}
                      keyName={"itemName"}
                      refId={getValue(this.props.expression,"value.selectedField.connectedListData.id")}
                      // assignKey={getValue(this.props.expression,"value.selectedField.id")}
                      // assignKey={uuid.v4()}
                      assignKey={`${getValue(this.props.expression,"value.selectedField.id")}-${this.props.idx}`}
                      page={getValue(this.props.expression,"value.selectedField.page")}
                      form={getValue(this.props.expression,"value.selectedField")}
                      expressionId={this.props.expression.id}
                      handleSearch={this.handleSearchManage}
                      count={getValue(this.props.expression,"value.selectedField.connectedListDataCount")}
                      handleScroll={this.handleScroll}
                      value={getValue(this.state.search,[`${getValue(this.props.expression,"value.selectedField.id")}-${this.props.idx}`],"")}
                      typeAll={true}
                     loading={getValue(this.state.loading,[`${getValue(this.props.expression,"value.selectedField.id")}-${this.props.idx}`],false)}

                      onClickHandler={this.handleChoose}
                      placeholder={getValue(this.props.expression,"value.selectedFieldValue.itemName",'') ? getValue(this.props.expression,`value.selectedFieldValue.itemName`,'') : "Select a value"}

                  />
             </div> </div>
                :
                <DifferentFieldList
                  expressionId={this.props.expression.id}
                  selectedFieldValue={this.props.expression ?.value ?.selectedFieldValue}
                  onChangeDifferentListHandler={e => this.props.fieldValueSelectHandler(e, this.props.expression.id)}
                  fieldDetails={this.props.expression ?.value ?.selectedField}
                />
              }
            </div>) :
            null
            }
          <div className="col-xl-4 col-lg-6">
            <div className="form-group mb-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group custom labelup">
                    <label>Ending Parentheses</label>
                    <Select
                      placeholder={this.props.expression ?.value ?.lastBracket || 'Select Parentheses'}
                      options={this.props.lastBracketOptions}
                      keyName='value'
                      controlFunc={e => this.props.handleParenthesesSelection(e, this.props.expression.id, ')', '')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Insert button */}
        <div className="btn-group-margin form-group">
          <button type="button" className="btn" onClick={e => this.props.createExpression(e, this.props.idx)} >
            Insert
          </button>
          <button disabled={this.props.expressionArrayLength == 2} type="button" className="btn" onClick={e => this.props.removeExpression(e, this.props.idx)} >
            Remove
          </button>
        </div>
        {/* End of Insert button */}
      </div>
    )
  }
}
