import { http } from "../_helpers";
import { config } from "../env";

const getCompanyCurrencyList = data => {
  return http
    .get(
      config.apiExpense +
        `currency-administation?companyId=${data.companyId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const searchCurrencyList = (companyId, data) => {
  // return http
  //   .get(
  //     config.apiExpense+`currency-administation?companyId=${companyId}`,
  //     data
  //   )
  //   .then(data => {
  //     return data ? data : "";
  //   });
};

const getAllCurrencyList = data => {
  return http
    .get(
      config.apiUrlCompany +
        `list-management?companyId=${data.companyId}&itemCode=CUR001`
    )
    .then(data => {
      return data ? data : "";
    });
};

const onStatusChangeHandler = (currencyName, data) => {
  return http
    .put(config.apiExpense + `currency-administation/${currencyName}`, data)
    .then(data => {
      return data ? data : "";
    });
};

const removeCurrencyHandler = id => {
  return http
    .delete(config.apiExpense + `currency-administation/${id}`)
    .then(data => {
      return data ? data : "";
    });
};

const addCompanyCurrency = (data, header) => {
  return http
    .post(config.apiExpense + `currency-administation`, data)
    .then(data => {
      return data ? data : "";
    });
};

export const expenseReimbursementServise = {
  onStatusChangeHandler,
  getCompanyCurrencyList,
  removeCurrencyHandler,
  addCompanyCurrency,
  searchCurrencyList,
  getAllCurrencyList
};
