import React, { Component } from 'react';
import { masterService } from '../../../_services';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";

import MasterMenu from "./master-menu";
import $ from 'jquery';
import { toast } from 'react-toastify';
import { alertConstants } from '../../../_constants'

class MasterRole extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.state = {
            activePage: 1,
            itemPerPage: 10,
            listRoles: [],
            addFields: {
                roleName: ''
            },
            addErrors: {
                roleName: ''
            },
            editFields: {
                roleName: '',
                id: ''
            },
            editErrors: {
                roleName: ''
            }
        };
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleItemChange = this.handleItemChange.bind(this)
    }
    loading = false;
    handleUpdateValidation() {
        let editFields = this.state.editFields;
        let editErrors = {};
        let formIsValid = true;
        if (editFields["roleName"] === '') {
            formIsValid = false;
            editErrors["roleName"] = "Role Name is required";
        } else if (typeof editFields["roleName"] !== "undefined") {
            if (!editFields["roleName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                editErrors["roleName"] = "Only letters allowed";
            }
        }
        this.setState({ editErrors: editErrors });
        return formIsValid;
    }

    handleEditChange(field, e) {
        let editFields = this.state.editFields;
        editFields[field] = e.target.value;
        this.setState({ editFields });
        this.setState({ editErrors: '' })
    }

    handleAddValidation() {
        let addFields = this.state.addFields;
        let addErrors = {};
        let formIsValid = true;
        if (addFields["roleName"] === '') {
            formIsValid = false;
            addErrors["roleName"] = "Role Name is required";
        } else if (typeof addFields["roleName"] !== "undefined") {
            if (!addFields["roleName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                addErrors["roleName"] = "Only letters allowed";
            }
        }
        this.setState({ addErrors: addErrors });
        return formIsValid;
    }

    addSubmit(e) {
        e.preventDefault();
        if (this.handleAddValidation()) {
            this.loading = true;
            masterService.addRoles(this.state.addFields)
                .then(
                    data => {
                        if (!toast.isActive(this.toastId)) {
                            this.toastId = toast.success(alertConstants.CREATED_SUCCESSFULLY)
                        }
                        this.getRole()
                        $('.close').click();
                    }, error => {
                        this.loading = false;
                        if (error.statusCode === 422) {
                            let errors = error.message
                            Object.keys(errors).map(key => {
                                return (
                                    toast.error(errors[key])
                                );
                            })
                        } else {
                            toast.error(error.message)
                        }
                    }
                ).finally(() => { this.loading = false; this.forceUpdate() });
        }
    }

    editSubmit(e) {
        e.preventDefault();
        if (this.handleUpdateValidation()) {
            this.loading = true;
            masterService.editRoles(this.state.editFields)
                .then(
                    data => {
                        if (!toast.isActive(this.toastId)) {
                            this.toastId = toast.success(alertConstants.UPDATED_SUCCESSFULLY)
                        }
                        this.getRole()
                        $('.close').click();
                    }, error => {
                        this.loading = false;
                        if (error.statusCode === 422) {
                            let errors = error.message
                            Object.keys(errors).map(key => {
                                return (
                                    toast.error(errors[key])
                                );
                            })
                        } else {
                            toast.error(error.message)
                        }
                    }
                ).finally(() => { this.loading = false; this.forceUpdate() });
        }
    }

    cancelAdd() {
        // this.setState({addErrors:'',addFields:''})
        // document.getElementById("create-form").();
        $('#create-form input').val('')
    }

    handleChange(field, e) {
        let addFields = this.state.addFields;
        addFields[field] = e.target.value;
        this.setState({ addFields });
        this.setState({ addErrors: '' })
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber }, () =>
            this.getRole());
    }
    handleItemChange(limit) {
        this.setState({ itemPerPage: limit, activePage: 1 }, () =>
            this.getRole());
    }

    componentDidMount() {
        this.getRole()
    }
    loader = false;
    count = 0;

    getRole() {
        // this.setState({ listRoles: [] })
        this.loader = true;
        masterService.getRolesByPage(this.state.activePage, this.state.itemPerPage)
            .then(
                data => {
                    if (data != null) {
                        this.count = data.count
                        this.setState({ listRoles: data.result })
                    }
                }
            ).finally(() => { this.loader = false; this.forceUpdate() });

    }

    changeStatus(id, status) {
        masterService.activeInactiveRoles(id, { activeStatus: status })
            .then(
                data => {
                    if (!toast.isActive(this.toastId)) {
                        this.toastId = toast.success(data.message)
                    }
                    this.getRole()
                }, error => {
                    this.loading = false;
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            ).finally(() => { this.loading = false; this.forceUpdate() });
    }

    doSearch(queryText) {
        //get query result
        var queryResult = [];
        this.props.data.forEach(function (person) {
            if (person.name.toLowerCase().indexOf(queryText) !== -1)
                queryResult.push(person);
        });

        this.setState({
            query: queryText,
            filteredData: queryResult
        })
    }

    editData(data) {
        let editFields = this.state.editFields;
        editFields['roleName'] = data.roleName;
        editFields['id'] = data.id;
        this.setState({ editFields });
        this.setState({ editErrors: '' })
    }

    render() {
        const { listRoles, itemPerPage } = this.state;
        return (

            <div className="master">
                <MasterMenu />
                <div className="inner-wrapper">
                    <NavLink to="" data-toggle="modal" data-target="#CreatUserModal"
                        className="add-button text-white">+</NavLink>
                    <div className="inner-header">
                        <div className="shape-header">
                            <div className="left">M</div>
                            <div className="right">
                                <h6 className="heading">Manage Roles</h6>
                                <p className="text">Lorem Ipsum is simply dummy</p>
                            </div>
                        </div>
                    </div>
                    <div className="py-padding">
                        <div className="table-container">
                            <form autoComplete="off">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="dropdown">
                                            <button type="button" className="btn dropdown-toggle"
                                                data-toggle="dropdown">
                                                Action
                                            </button>
                                            <div className="dropdown-menu">
                                                <button className="dropdown-item">Make Inactive</button>
                                                <button className="dropdown-item">Delete</button>
                                                <button className="dropdown-item">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 offset-md-8">
                                        <div className="search-custom">
                                            <input type="text" name="" className="form-control" placeholder="Search" />
                                            <span><i className="material-icons">search</i></span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Role</th>
                                            <th>Action</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.loader === true &&
                                            <tr>
                                                <td colSpan="3">Loading</td>
                                            </tr>
                                        }
                                        {!listRoles && this.loader === false &&
                                            <tr>
                                                <td colSpan="3" className="text-center">No Records Found</td>
                                            </tr>}
                                        {listRoles &&
                                            listRoles.map((item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{item.roleName}</td>
                                                        <td>
                                                            <button data-toggle="modal" onClick={e => this.editData(item)}
                                                                data-target="#EditUserModal"
                                                                className="btn btn-border-primary btn-sm sm-padding">Edit
                                                    </button>
                                                        </td>
                                                        <td>
                                                            {(item.activeStatus === true) ?
                                                                <div>
                                                                    <label className="switch">
                                                                        <input type="checkbox"
                                                                            onChange={e => this.changeStatus(item.id, "false")}
                                                                            defaultChecked={item.activeStatus === true} />
                                                                        <span className="slider round"></span>
                                                                    </label>

                                                                </div> :
                                                                <div>
                                                                    <label className="switch">
                                                                        <input type="checkbox"
                                                                            onChange={e => this.changeStatus(item.id, "true")} />
                                                                        <span className="slider round"></span>
                                                                    </label>

                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-footer">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="page-count">
                                            <div className="text">Items</div>
                                            <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    {itemPerPage}
                                                </button>
                                                <div className="dropdown-menu">
                                                    <button onClick={e => this.handleItemChange(10)} className="dropdown-item">10</button>
                                                    <button onClick={e => this.handleItemChange(20)} className="dropdown-item">20</button>
                                                    <button onClick={e => this.handleItemChange(30)} className="dropdown-item">30</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        {listRoles &&
                                            <Pagination
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemPerPage}
                                                totalItemsCount={this.count}
                                                pageRangeDisplayed={3}
                                                onChange={this.handlePageChange}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="CreatUserModal" tabIndex="-1" role="dialog"
                    aria-labelledby="CreateUserModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form autoComplete="off" id="create-form" onSubmit={this.addSubmit.bind(this)}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="CreateUserModalLabel">Create Role</h5>
                                    <button type="button" onClick={e => this.cancelAdd(e)} className="close"
                                        data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div
                                        className={(this.state.addErrors["roleName"]) ? 'has-error form-group' : 'form-group'}>
                                        <input className="form-control"
                                            type="text"
                                            name="roleName"
                                            onChange={this.handleChange.bind(this, "roleName")}
                                            value={this.state.addFields["roleName"]}
                                            placeholder="Name" />
                                        <div className="help-block">{this.state.addErrors["roleName"]}</div>
                                    </div>
                                </div>
                                {(this.loading === false) ?
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-border-success lg-padding">Save</button>
                                        <button type="button" onClick={this.cancelAdd()}
                                            className="btn btn-border-basic lg-padding" data-dismiss="modal">Cancel
                                        </button>
                                    </div> :
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-border-success lg-padding">Please Wait...</button>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditUserModal" tabIndex="-1" role="dialog"
                    aria-labelledby="EditUserModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form autoComplete="off" id="edit-form" onSubmit={this.editSubmit.bind(this)}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="EditUserModalLabel">Edit Role</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div
                                        className={(this.state.editErrors["roleName"]) ? 'has-error form-group' : 'form-group'}>
                                        <input className="form-control"
                                            type="text"
                                            name="roleName"
                                            onChange={this.handleEditChange.bind(this, "roleName")}
                                            value={this.state.editFields["roleName"]}
                                            placeholder="Name" />
                                        <div className="help-block">{this.state.editErrors["roleName"]}</div>
                                    </div>
                                </div>
                                {(this.loading === false) ?
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-border-success lg-padding">Save</button>
                                        <button type="button" onClick={this.cancelAdd()}
                                            className="btn btn-border-basic lg-padding" data-dismiss="modal">Cancel
                                        </button>
                                    </div> :
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-border-success lg-padding">Please Wait...</button>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default MasterRole