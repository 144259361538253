import { observable, action, runInAction, computed, reaction, toJS, flow, autorun } from 'mobx';
import { toast } from 'react-toastify';
import { persist, create } from "mobx-persist";
import { debounce } from 'lodash/fp';
import { trainService } from "src/_services/train.service.js";
import makeInspectable from "mobx-devtools-mst";
import moment from 'moment';
import { get, set, values } from 'mobx';

class TrainStore {
  @observable stationSearchResults = [];
  @observable totalTrainsFound = '';
  @observable trainResults = [];
  @observable trainRequest = [];
  @observable trainDetails = {};
  @observable selectedSeat = {
    details: "",
    trainCode: "",
    id: ""
  };

  @persist('object') @observable departureDate = "";
  @persist('object') @observable returnDate = "";

  @persist('object') @observable fromLocation = {
    name: '-',
    code: '-'
  };
  @persist('object') @observable toLocation = {
    name: '-',
    code: '-'
  };

  //   Loaders
  @observable loadingTrainResults = false;
  @observable loadingStations = false;
  @observable loadingTrainDetails = false;


  @observable railwayProvider = 1;
  @observable fromLocationDisplay = "";
  @observable toLocationDisplay = "";


  @observable activeInput = 0;
  @observable locationSwap = false;
  @observable stationAndDateValidation = false;


  @observable popularCities = [
    {
      "stationName": "BENGALURU CANT",
      "stationCode": "BNC",
      "stationCity": "BENGALURU"
    },
    {
      "stationName": "MUMBAI CENTRAL",
      "stationCode": "MMCT",
      "stationCity": "MUMBAI"
    },
    {
      "stationName": "NEW DELHI",
      "stationCode": "NDLS",
      "stationCity": "NEW DELHI"
    },
    {
      "stationName": "KOLKATA",
      "stationCode": "CP",
      "stationCity": "KOLKATA"
    },
    {
      "stationName": "MGR CHENNAI CTL",
      "stationCode": "MAS",
      "stationCity": "CHENNAI"
    }
  ]




  @computed get checkValidityBeforeSearch() {
    if (this.fromLocation.code !== '-' && this.fromLocation.code !== '' && this.toLocation.code !== '-' && this.toLocation.code !== '' && this.departureDate !== '') {
      return true;
    }
    return false;
  }

  @action changeActiveInput = (e) => {
    this.activeInput = e;
  }

  // @action handleLocationChange = async (e, type) => {
  //   let searchTerm = e.target.value;
  //   let res = await trainService.searchStation(searchTerm);
  //   if(res){
  //     console.log('results are ', res);
  //   }

  // }

  handleLocationChange = debounce(250, flow(function* (searchTerm, type) {


    if (searchTerm && searchTerm.length != 0) {

      this.loadingStations = true;
      try {
        let res = yield trainService.searchStation(searchTerm);
        if (res && res.result) {
          this.loadingStations = false;
          this.stationSearchResults = res.result;

        }
      } catch (err) {
        this.loadingStations = false;
        this.stationSearchResults = [];
      }
    }

  }));

  @action handleEnterKey = (charCode, type) => {

    if (this.stationSearchResults.length) {
      if (charCode == 13) {
        let item = this.stationSearchResults[0];
        this.handleLocationSelection(item, type);
      }
    }
  }


  @action handleLocationSelection = (item, type) => {
    this.changeActiveInput(0);
    switch (type) {
      case 'fromLocation':
        this.fromLocation.name = item.stationName;
        this.fromLocation.code = item.stationCode;
        this.fromLocation.city = item.stationCity;
        break;
      case 'toLocation':
        this.toLocation.name = item.stationName;
        this.toLocation.code = item.stationCode;
        this.toLocation.city = item.stationCity;
        break;
    }
    this.stationSearchResults = [];
  }
  @action resetStore = () => { 
    this.toLocation = {
      name: '-',
      code: '-'
    };
    this.fromLocation = {
      name: '-',
      code: '-'
    };
    this.departureDate = ""
    this.returnDate = ""
  }

  getTrains = flow(function* () {
    this.trainResults = [];
    this.trainDetails = {};
    this.loadingTrainResults = true;

    try {
      let res = yield trainService.searchTrains(this.fromLocation.code, this.toLocation.code, this.departureDate);
      if (res && res.result) {
        this.loadingTrainResults = false;
        this.trainResults = res;
        this.setInitialTrainDetails(this.trainResults);
        this.totalTrainsFound = res.total;
      }
    } catch (err) {
      this.loadingTrainResults = false;
      this.trainResults = [];
    }
  })
  getTrainsList = flow(function* (request) {
    this.trainRequest = request;
    this.trainResults = [];
    this.trainDetails = {};
    this.loadingTrainResults = true;
    try {
      let res = yield trainService.searchTrainsRList(request);
      if (res && res.result) {
        this.loadingTrainResults = false;
        this.trainResults = res;
        this.setInitialTrainDetails(this.trainResults);
        this.totalTrainsFound = res.count;
      }
    } catch (err) {
      this.loadingTrainResults = false;
      this.trainResults = [];
    }
  })

  getTrainDetails = flow(function* (payload) {
    this.loadingTrainDetails = true;
    try {
      let res = yield trainService.getTrainDetails(payload);
      this.loadingTrainDetails = false;
      // console.log('result received ', this.loadingTrainDetails);
      if (res) {
        set(this.trainDetails, {
          [payload.trainNo]: {
            ...this.trainDetails[payload.trainNo],
            result: res.result
          }
        });

      }
    } catch (err) {

    }
  })

  @action handleDepartureDate = (day) => {
    console.log(day)
    this.changeActiveInput(0);
    let formattedDate = moment(new Date(day));
    this.departureDate = formattedDate;
  }
  @action handleReturnDate = (day) => {
    this.changeActiveInput(0);
    let formattedDate = moment(new Date(day));
    this.returnDate = formattedDate;
  }

  // * For future use IRCTC and EuroRail
  @action changeRailwayProvider = (value) => {
    this.railwayProvider = value;
  }

  @action handleLocationSwap = () => {
    let obj = this.fromLocation;
    this.fromLocation = this.toLocation;
    this.toLocation = obj;
    this.locationSwap = !this.locationSwap;
  }

  @action changeValidStatus = (val) => {
    this.stationAndDateValidation = val;
  }

  @action setSearchData = (params) => {
    this.fromLocation.name = params.fromLocationName;
    this.fromLocation.code = params.fromLocationCode;
    this.toLocation.name = params.toLocationName;
    this.toLocation.code = params.toLocationCode;
    this.departureDate = params.departureDate;

  }
  @action setSearchDataForRequest = (params) => {
    this.fromLocation.name = params.train_from.stationName;
    this.fromLocation.city = params.train_from.stationCity;
    this.fromLocation.code = params.train_from.stationCode;
    this.toLocation.name = params.train_to.stationName;
    this.toLocation.city = params.train_to.stationCity;
    this.toLocation.code = params.train_to.stationCode;
    this.departureDate = params.train_start_timestamp;
    this.returnDate = params.train_return_timestamp;
  }

  @action handleClassClick = (coachType, trainCode) => {
    set(this.trainDetails, {
      [trainCode]: {
        ...this.trainDetails[trainCode],
        seatClass: coachType

      }
    });
  }

  @action handleQuotaSelect = (quota, trainCode) => {
    set(this.trainDetails, {
      [trainCode]: {
        ...this.trainDetails[trainCode],
        quota: quota.value

      }
    });
    console.log('-train details- ', this.trainDetails);
  }

  @action setInitialTrainDetails = (res) => {
    if (res?.result?.length) {
      res.result.forEach(train => {



        set(this.trainDetails, {
          [train.train_code]: {
            ...this.trainDetails[train.train_code],
            seatClass: train?.classes?.[0].coach_type || '',
            quota: 'GN'
          }
        });
      })
    }
  }

  @action handleSeatClick = (details, trainCode) => {
    this.selectedSeat = {
      details,
      trainCode,
      id: details.search_result_id
    }
    console.log('the selected seat is ', this.selectedSeat);
  }

}
// autorun(() => console.log(this.trainStore.fromLocation));

makeInspectable(trainStore);
const hydrate = create({});

export let trainStore = new TrainStore();
// hydrate('trainStore', trainStore).then(() => console.log('trainStore hyrated ', trainStore));
if (process.env.NODE_ENV !== "production") {
  trainStore = require("mobx-devtools-mst").default(trainStore);
}