import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
class TravelRequestPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <div className="p-padding">
                    <div className="outdivision">
                        <div className="row">
                            <div className="col-md-7 pr-0">
                                <div className="profile-panel">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="approver">
                                                <div className="name-desg prof-pic">
                                                    <div className="left">
                                                        <div className="pic">
                                                            <img src="/assets/images/booking/user_12.png" alt="Pic" />
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <p>Request by</p>
                                                        <h6>Craig Evans</h6>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="pt-3 text-success">
                                            <i className="material-icons mr-1">thumb_up</i>Policy Compliant
                                            </div>
                                        </div>
                                        <div className="col-md-3 offset-2">
                                            <div className="pt-3 ">
                                                <button type="button" className="btn btn-nothing text-danger">Cancel Request</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="innerdiv">
                                    <div className="box-shadow">
                                        <div className="table-responsive">
                                            <table className="table table-bordered mb-0">
                                                <tbody>
                                                <tr>
                                                    <td>Request Name<br /><h6>BLR -NYC</h6></td>
                                                    <td>Purpose <br /> Business Meeting</td>
                                                    <td>Proposed Travel date<br />28/09/2019</td>
                                                </tr>
                                                <tr>
                                                    <td>Chargeable to Client<br /><h6>Chargeable to Client</h6></td>
                                                    <td>Client Name<br /> <h6>Google</h6></td>
                                                    <td>Personal Day Off<br /><h6>5/10/2018 to 7th Nov 2018</h6></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">Travel Remarks<br /><h6>Travel Remarks</h6></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 pl-0">
                                <div className="row add-padding">
                                    <div className="col-md-5">
                                        <div className="amount">
                                            TOTAL - INR <span>83,488</span>
                                        </div>
                                    </div>
                                    <div className="col-md-7 p-0">
                                        <div className="row pr-3">
                                            <div className="col-md-2 p-0">
                                                <div className="left-glass">
                                                <i className="material-icons">timer</i>
                                                </div>
                                            </div>
                                            <div className="col-md-10 p-0">
                                                <div className="approval-time">
                                                    <p>Waiting for Approval</p>
                                                    <p>2 Day Ago</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="summary-box">
                                    <h6>Summary</h6>
                                    <p>Craig wants to travel from Bangalore to New York and back for a Business Meeting from 28th Oct 2018 to 7th Nov 2018, This is chargeable to Google Client, Craig also wants Personal day off in his Travel from 5th Nov 2018 to 7th Nov 2018</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-padding pt-0">
                    <div className="main-box">
                        <div className="bg-light heading">
                            <h6>Expense</h6>
                        </div>
                        <div className="p-padding">
                            <div className="row">
                                <div className="col-md-9">
                                    <h6 className="side-title">Per Diem</h6>
                                    <div className="box-shadow">
                                        <div className="table-responsive">
                                            <table className="table ">
                                                <thead>
                                                <tr>
                                                    <th className="border-0">Journey</th>
                                                    <th className="border-0">Per Diem</th>
                                                    <th className="border-0">Amount</th>
                                                    <th className="border-0">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="border-0">14 Days</td>
                                                    <td className="border-0">$ 20 International | $ 10 Domestic</td>
                                                    <td className="border-0">INR 18,298.00</td>
                                                    <td className="border-0">USD 250.00</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div id="demo" className="collapse fade-in">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th>Expense Type</th>
                                                        <th>Location</th>
                                                        <th>Date</th>
                                                        <th>Amount</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Per Diem</td>
                                                        <td>Bangalore</td>
                                                        <td>27th October 2018</td>
                                                        <td>INR 1000.00</td>
                                                        <td>USD 13.83</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Per Diem</td>
                                                        <td>Bangalore</td>
                                                        <td>27th October 2018</td>
                                                        <td>INR 1000.00</td>
                                                        <td>USD 13.83</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Per Diem</td>
                                                        <td>Bangalore</td>
                                                        <td>27th October 2018</td>
                                                        <td>INR 1000.00</td>
                                                        <td>USD 13.83</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Per Diem</td>
                                                        <td>Bangalore</td>
                                                        <td>27th October 2018</td>
                                                        <td>INR 1000.00</td>
                                                        <td>USD 13.83</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Per Diem</td>
                                                        <td>Bangalore</td>
                                                        <td>27th October 2018</td>
                                                        <td>INR 1000.00</td>
                                                        <td>USD 13.83</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Per Diem</td>
                                                        <td>Bangalore</td>
                                                        <td>27th October 2018</td>
                                                        <td>INR 1000.00</td>
                                                        <td>USD 13.83</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Per Diem</td>
                                                        <td>Bangalore</td>
                                                        <td>27th October 2018</td>
                                                        <td>INR 1000.00</td>
                                                        <td>USD 13.83</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Per Diem</td>
                                                        <td>Bangalore</td>
                                                        <td>27th October 2018</td>
                                                        <td>INR 1000.00</td>
                                                        <td>USD 13.83</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <button type="button" className="btn btn-nothing bg-light form-control collapsed text-primary" data-toggle="collapse" data-target="#demo"><span className="more">Show More</span><span className="less">Show Less</span></button>
                                    </div>
                                    <h6 className="side-title mt-3">Additional Expense</h6>
                                    <div className="box-shadow">
                                        <div className="table-responsive">
                                            <table className="table ">
                                                <thead>
                                                <tr>
                                                    <th className="border-0">Expense type</th>
                                                    <th className="border-0">Location</th>
                                                    <th className="border-0">Date</th>
                                                    <th className="border-0">Amount</th>
                                                    <th className="border-0">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="border-0">Business Expense</td>
                                                    <td className="border-0">Bangalore</td>
                                                    <td className="border-0">28th Nov 2018</td>
                                                    <td className="border-0">INR 18,298.00</td>
                                                    <td className="border-0">USD 250.00</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-danger lg-padding my-3" data-toggle="modal" data-target="#addExpenseModal">Add Expense</button>
                                    <h6 className="side-title">Cash Advance</h6>
                                    <div className="box-shadow">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th className="border-0">Required</th>
                                                    <th className="border-0">Mode</th>
                                                    <th className="border-0">Amount</th>
                                                    <th className="border-0">Percentage</th>
                                                    <th className="border-0">Date</th>
                                                    <th className="border-0">Comment</th>
                                                    <th className="border-0">Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="border-0"  data-toggle="modal" data-target="#removeCashModal">
                                                        <label className="switch">
                                                            <input type="checkbox" checked />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </td>
                                                    <td className="border-0">Cash</td>
                                                    <td className="border-0">USD 100.00</td>
                                                    <td className="border-0">40%</td>
                                                    <td className="border-0">28/9/2018</td>
                                                    <td className="border-0">I need it this way, Yo!</td>
                                                    <td className="border-0">
                                                        <button type="button" className="btn   sm-padding"
                                                                data-toggle="modal" data-target="#editCashModal">Edit</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0"  data-toggle="modal" data-target="#removeCashModal">
                                                        <label className="switch">
                                                            <input type="checkbox" checked />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </td>
                                                    <td className="border-0">Cash</td>
                                                    <td className="border-0">USD 100.00</td>
                                                    <td className="border-0">40%</td>
                                                    <td className="border-0">28/9/2018</td>
                                                    <td className="border-0">I need it this way, Yo!</td>
                                                    <td className="border-0">
                                                        <button type="button" className="btn   sm-padding" data-toggle="modal" data-target="#editCashModal">Edit</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border-0"  data-toggle="modal" data-target="#removeCashModal">
                                                        <label className="switch">
                                                            <input type="checkbox" checked />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </td>
                                                    <td className="border-0">Cash</td>
                                                    <td className="border-0">USD 100.00</td>
                                                    <td className="border-0">40%</td>
                                                    <td className="border-0">28/9/2018</td>
                                                    <td className="border-0">I need it this way, Yo!</td>
                                                    <td className="border-0">
                                                        <button type="button" className="btn   sm-padding" data-toggle="modal" data-target="#editCashModal">Edit</button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="box-shadow">
                                        <h6 className=" side-title border-bottom p-3">Expense Details</h6>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead>
                                                <tr>
                                                    <th className="border-0">CURRENCY</th>
                                                    <th className="border-0">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr className="bg-light">
                                                    <td className="border-0">Per Diem</td>
                                                    <td className="border-0">USD 2,500.00</td>
                                                </tr>
                                                <tr className="bg-light">
                                                    <td className="border-0">Business Expense</td>
                                                    <td className="border-0">USD 2,500.00</td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr className="bg-primary text-white">
                                                    <td className="border-0">TOTAL</td>
                                                    <td className="border-0">USD 5,000.00</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
                <div className="p-padding pt-0">
                    <div className="white-card shadow-light">
                        <div className="pad-sm">
                            <h6 className="sub-heading mb-0">Approver Flow</h6>
                        </div>
                        <div>
                            <div className="approver">
                                <div className="right-icon approved">
                                <i className="material-icons">check_circle</i>
                                </div>
                                <div className="name-desg">
                                    <div className="left">
                                        <div className="pic">
                                            <img src="/assets/images/booking/user_12.png" alt="Pic" />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <p className="label">Level 1</p>
                                        <p className="title">George Green</p>
                                        <p className="desg">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="approver">
                                <div className="right-icon">
                                <i className="material-icons">timer</i>
                                </div>
                                <div className="name-desg">
                                    <div className="left">
                                        <div className="pic">
                                            <img src="/assets/images/booking/user_12.png" alt="Pic" />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <p className="label">Level 2</p>
                                        <p className="title">Vincent Lawson</p>
                                        <p className="desg">Approver</p>
                                    </div>
                                </div>
                            </div>
                            <div className="approver">
                                <div className="right-icon">
                                <i className="material-icons">timer</i>
                                </div>
                                <div className="name-desg">
                                    <div className="left">
                                        <div className="pic">
                                            <img src="/assets/images/booking/user_12.png" alt="Pic" />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <p className="label">Level 3</p>
                                        <p className="title">John Snow</p>
                                        <p className="desg">Manager</p>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                <div className="p-padding pt-0">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="white-card">
                                <div className="pad">
                                    <h6 className="sub-heading">Add Additional Service</h6>
                                    <ul className="service">
                                        <li>
                                            <img width="50" height="50" src="/assets/images/booking/flight_booking.svg" alt="Flight" />
                                            <p>Add Flights</p>
                                        </li>
                                        <li>
                                            <img width="50" height="50" src="/assets/images/booking/hotel_booking.svg" alt="Hotel" />
                                            <p>Add Hotels</p>
                                        </li>
                                        <li>
                                            <img width="50" height="50" src="/assets/images/booking/car_booking.svg" alt="Cab" />
                                            <p>Add Cab</p>
                                        </li>
                                        <li>
                                            <img width="50" height="50" src="/assets/images/booking/bus_booking.svg" alt="Bus" />
                                            <p>Add Bus</p>
                                        </li>
                                        <li>
                                            <img width="50" height="50" src="/assets/images/booking/train_booking.svg" alt="Train" />
                                            <p>Add Train</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul className="travel-tabs">
                                <li className="active">
                                    <div className="arrow">
                                    <i className="material-icons">play</i>
                                    </div>
                                    <div className="destiny">
                                        <div className="left-destination car">
                                            <div className="abs-pin"></div>
                                            <div className="totalDistance sm">
                                                <div className="circle">
                                                <i className="material-icons">directions_car</i>
                                                </div>
                                            </div>
                                            <p className="title">#345, Vital malya Road, Bangalore</p>
                                            <div className="center">
                                                <p className="title">
                                                    <img src="/assets/images/booking/car_service_3.png" alt="Ola" /> <b>45min</b> by Ola
                                                </p>
                                                <p className="label">Rs. 300 - 400</p>
                                            </div>
                                            <p className="title">Kempegowda International Airport</p>
                                        </div>
                                    </div>
                                    <button className="btn btn-border-primary sm-padding">3 more Option</button>
                                </li>
                                <li>
                                    <div className="arrow">
                                    <i className="material-icons">play</i>
                                    </div>
                                    <div className="destiny">
                                        <div className="left-destination flight">
                                            <div className="abs-pin"></div>
                                            <div className="totalDistance sm">
                                                <div className="circle">
                                                <i className="material-icons">flight</i>
                                                </div>
                                            </div>
                                            <p className="title">Kempegowda International Airport</p>
                                            <div className="center">
                                                <p className="title">
                                                    <img src="/assets/images/booking/" alt="Indigo" /> <b>18hr 38m</b> by Plain
                                                </p>
                                                <p className="label">USD 1234.00 to USD 1500.00</p>
                                            </div>
                                            <p className="title">JFK International Airport</p>
                                        </div>
                                    </div>
                                    <button className="btn btn-border-primary sm-padding">40 more Option</button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="white-card shadow-light">
                                <div className="grey-head">
                                    <h6 className="sub-heading">Road Transport Options</h6>
                                </div>
                                <div className="pad">
                                    <div className="destiny">
                                        <div className="left-destination">
                                            <div className="abs-pin"><i className="material-icons">location_on</i></div>
                                            <div className="totalDistance">
                                                <div className="circle">
                                                    <p className="title">57 <span className="label">KM</span></p>
                                                </div>
                                            </div>
                                            <div className="white-card shadow-light">
                                                <div className="pad-sm">
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <p className="label p-left">Pick Up Location</p>
                                                            <p className="title">
                                                            <i className="material-icons">location_on</i>
                                                                #306, Vittal Malya Road, Bengaluru
                                                            </p>
                                                        </div>
                                                        <div className="col-md-3 text-right">
                                                            <button className="btn btn-nothing link h-100">Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="white-card border-blue">
                                                <label className="full" for="no_cab">
                                                    <div className="pad-sm">
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <p className="title">
                                                                    No Cab Required
                                                                </p>
                                                            </div>
                                                            <div className="col-md-2 text-right">
                                                                <div className="radio-container p-sm d-inline-block">
                                                                    <input type="radio" id="no_cab" name="radio" />
                                                                    <span className="checkmark"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="white-card border-blue">
                                                <label className="full" for="ola">
                                                    <div className="pad-sm">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <p className="title">
                                                                    <img src="/assets/images/booking/car_service_3.png" alt="Ola" /> 25min
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p className="title text-right">
                                                                    USD 300.00 - USD 400.00
                                                                </p>
                                                            </div>
                                                            <div className="col-md-2 text-right">
                                                       className="  <div className="radio-container p-sm d-inline-block">
                                                                    <input id="ola" type="radio" name="radio" />
                                                                    <span className="checkmark"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="white-card border-blue">
                                                <label className="full" for="uber">
                                                    <div className="pad-sm">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <p className="title">
                                                                    <img src="/assets/images/booking/" alt="Uber" /> 45min
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p className="title text-right">
                                                                    USD 250.00 - USD 350.00
                                                                </p>
                                                                <p className="label text-right green"><i className="material-icons">arrow_drop_down</i> USD 50.00</p>
                                                            </div>
                                                            <div className="col-md-2 text-right">
                                                                <div className="radio-container p-sm d-inline-block">
                                                                    <input type="radio" id="uber" name="radio" />
                                                                    <span className="checkmark"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <button className="more-options">
                                                + 2 More Options
                                            </button>
                                            <div className="white-card">
                                                <button className="btn btn-success btn-block btn-no-radius">Modify/Change Flight</button>
                                            </div>
                                            <div className="white-card border-blue">
                                                <label className="full" for="bmtc">
                                                    <div className="pad-sm">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <p className="title">
                                                                    <img src="/assets/images/booking/" alt="BMTC" /> 2h 30M
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p className="title text-right">
                                                                    USD 25.00 - USD 50.00
                                                                </p>
                                                            </div>
                                                            <div className="col-md-2 text-right">
                                                                <div className="radio-container p-sm d-inline-block">
                                                                    <input id="bmtc" type="radio" name="radio" />
                                                                    <span className="checkmark"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="white-card shadow-light">
                                                <div className="pad-sm">
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <p className="label p-left">Drop Off Location</p>
                                                            <p className="title">
                                                            <i className="material-icons">location_on</i>
                                                                Kempegowda International Airport
                                                            </p>
                                                        </div>
                                                        <div className="col-md-3 text-right">
                                                            <button className="btn btn-nothing link h-100">Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-card mt-4">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button className="btn btn-border-danger btn-block">Cancel FLight</button>
                                                </div>
                                                <div className="col-md-6">
                                                    <button className="btn btn-border-basic btn-block">Reschedule FLight</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="foot">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <p><i className="material-icons">calendar_today</i> 28th Oct 2018 10:20 AM</p>
                                        </div>
                                        <div className="col-md-3 text-right">
                                            <button className="btn btn-nothing">Edit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="addExpenseModal" tabIndex="-1" role="dialog" aria-labelledby="addExpenseLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-head">
                                <h5 className="modal-title" id="addExpenseLabel">Add Expense</h5>
                            </div>
                            <div className="modal-middle pm-30">
                                <div className="form-group custom labelup">
                                    <label>Expense Type</label>
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                            Expense Type
                                        </button>
                                        <div className="dropdown-menu">
                                            <button className="dropdown-item">1</button>
                                            <button className="dropdown-item">2</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group custom">
                                    <input type="text" placeholder="&nbsp;" className="form-control" />
                                    <label>Location</label>
                                    <span className="icon-custom">
                                    <i className="material-icons">location_on</i>
                                    </span>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-4 px-0">
                                            <div className="form-group custom labelup">
                                                <label>Amount</label>
                                                <div className="dropdown">
                                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                                        USD
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <button className="dropdown-item">1</button>
                                                        <button className="dropdown-item">2</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 pr-0">
                                            <div className="form-group custom">
                                                <input type="text" placeholder="&nbsp;" className="form-control" />
                                                <label>2000</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-last btn-group-margin">
                                    <button type="button" className="btn  ">Add expense
                                    </button>
                                    <button type="button" className="btn btn-badge" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="editCashModal" tabIndex="-1" role="dialog" aria-labelledby="editCashLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-head">
                                <h5 className="modal-title" id="editCashLabel">Edit Cash</h5>
                            </div>
                            <div className="modal-middle pm-30">
                                <div className="form-group custom labelup">
                                    <label>Mode</label>
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-border-basic dropdown-toggle btn-block bg-light" data-toggle="dropdown">
                                            Cash
                                        </button>
                                        <div className="dropdown-menu">
                                            <button className="dropdown-item">1</button>
                                            <button className="dropdown-item">2</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group custom">
                                    <input type="text" placeholder="&nbsp;" className="form-control" />
                                    <label>Date</label>
                                    <span className="icon-custom">
                                    <i className="material-icons">calendar_today</i>
                                    </span>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-3 px-0">
                                            <div className="form-group custom labelup">
                                                <label>Currency</label>
                                                <div className="dropdown">
                                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                                        USD
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <button className="dropdown-item">1</button>
                                                        <button className="dropdown-item">2</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pr-0">
                                            <div className="form-group custom">
                                                <input type="text" placeholder="&nbsp;" className="form-control" />
                                                <label>Amount</label>
                                            </div>
                                        </div>
                                        <div className="col-md-1 px-0 pr-0">
                                            <p className="text-center pt-2">or</p>
                                        </div>
                                        <div className="col-md-4 px-0 pr-0">
                                            <div className="form-group custom">
                                                <input type="text" placeholder="&nbsp;" className="form-control" />
                                                <label>Percentage</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-group custom textarea">
                                    <textarea
                                    data-gramm_editor="false"
                                    spellCheck="false"
                                    rows="2" className="form-control" placeholder="&nbsp;"></textarea>
                                    <label>Comment</label>
                                </div>
                                <div className="form-group">
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                    <span>Required</span>
                                </div>
                                <div className="modal-last btn-group-margin">
                                    <button type="button" className="btn  ">Add expense
                                    </button>
                                    <button type="button" className="btn btn-badge" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default TravelRequestPage