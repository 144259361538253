import React from 'react'

export default function Loader() {
  return (
    <div className="text-center">
      <div className="loader-css"></div>
      <h5 className="fw-sbold pb-5">Please wait...</h5>
    </div>
  )
}
