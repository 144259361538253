import React from "react";
import { Link } from "react-router-dom";
import { Input } from "../../../../../common/_forms";
const AddRuleClassPage = props => {
  // let data = props;
  const { onChangeHandler, onSaveHandler, state, error } = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">R</div>
          <div className="right">
            <h6 className="heading">
              {state.editRoleId ? "Edit Role Id" : "Add Class Rule"}
            </h6>
            <p className="text">Add/Modify Travel Class Rule</p>
          </div>
          <div  className="clearfix"></div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group custom">
                <Input
                  label="Travel Class Name"
                  type="text"
                  imp="true"
                  name="travelClassName"
                  error={error.message(
                    "Rule Class Name",
                    state.travelClassName,
                    "required"
                  )}
                  value={state.travelClassName}
                  controlFunc={e => onChangeHandler(e, "locationName")}
                />
              </div>
              {/* <div className="form-group custom labelup">
                            <label>Travel Config</label>
                            <div className="dropdown">
                                <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                    Standard
                                    </button>
                                <div className="dropdown-menu">
                                    <button className="dropdown-item">Action 1</button>
                                    <button className="dropdown-item">Action 1</button>
                                    <button className="dropdown-item">Action 1</button>
                                </div>
                            </div>
                        </div> */}
              {/* <div className="form-group custom labelup">
                            <label>Property Config</label>
                            <div className="dropdown">
                                <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                    Default Configuration
                                    </button>
                                <div className="dropdown-menu">
                                    <button className="dropdown-item">Action 1</button>
                                    <button className="dropdown-item">Action 1</button>
                                    <button className="dropdown-item">Action 1</button>
                                </div>
                            </div>
                        </div> */}
              <div className="btn-group-margin">
                <button
                  onClick={onSaveHandler}
                  className="btn"
                >
                  {state.editRoleId ? "Update" : "Save"}
                </button>
                <Link
                  to="/administration/travel/rule-class"
                  className="btn"
                >
                  Cancel
                </Link>
              </div>
              {/* <div className="col-md-8">
                        <div className="profile-card p-4 grey">
                            <h6 className="sub-heading">Approvals</h6>
                            <p>You can either set approval flow or allow the system to auto approve or reject.</p>
                            <div className="btn-group-margin mb-4">
                                <label className="radio-container"> Configure Approval Flow
                                        <input type="radio" name="radio" />
                                    <span className="checkmark"></span>
                                </label>
                                <label className="radio-container"> Auto Approve
                                        <input type="radio" name="radio" />
                                    <span className="checkmark"></span>
                                </label>
                                <label className="radio-container"> Auto Reject
                                        <input type="radio" name="radio" />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group custom labelup white">
                                        <label>Flow</label>
                                        <div className="dropdown">
                                            <button type="button" className="btn dropdown-toggle btn-block" data-toggle="dropdown">Managers
                                                </button>
                                            <div className="dropdown-menu">
                                                <button className="dropdown-item">Action 1</button>
                                                <button className="dropdown-item">Action 1</button>
                                                <button className="dropdown-item">Action 1</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group custom white">
                                        <div className="dropdown">
                                            <button type="button" className="btn dropdown-toggle btn-block" data-toggle="dropdown">1
                                                </button>
                                            <div className="dropdown-menu">
                                                <button className="dropdown-item">Action 1</button>
                                                <button className="dropdown-item">Action 1</button>
                                                <button className="dropdown-item">Action 1</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddRuleClassPage;
