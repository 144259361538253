export const operators = [
  {
    operator: ">",
    operatorName: "greater than",
    dataType: ["integer", "amount"]
  },
  {
    operator: ">=",
    operatorName: "equal to or greater than",
    dataType: ["integer", "amount"]
  },
  {
    operator: "<",
    operatorName: "less than",
    dataType: ["integer", "amount"]
  },
  {
    operator: "<=",
    operatorName: "equal to or less than",
    dataType: ["integer", "amount"]
  },
  {
    operator: "!=",
    operatorName: "not equal to",
    dataType: [
      "text",
      "integer",
      "email",
      "connected_list",
      "amount",
      "different_list",
      "search"
    ]
  },
  {
    operator: "==",
    operatorName: "equal to",
    dataType: [
      "text",
      "textarea",
      "integer",
      "email",
      "connected_list",
      "file",
      "amount",
      "boolean",
      "checkbox",
      "different_list",
      "search"
    ]
  },
  {
    operator: "true",
    operatorName: "true",
    dataType: []
  },
  {
    operator: "false",
    operatorName: "false",
    dataType: []
  },
  {
    operator: "=>",
    operatorName: "is in",
    dataType: ["text"]
  },
  {
    operator: "!=>",
    operatorName: "is not in",
    dataType: ["text", "textarea"]
  },
  {
    operator: "!@",
    operatorName: "does not contain",
    dataType: ["text", "textarea"]
  },
  {
    operator: "@",
    operatorName: "contain",
    dataType: ["text", "textarea"]
  },
  {
    operator: "*",
    operatorName: "any",
    dataType: ["text", "textarea"]
  },
  {
    operator: "#",
    operatorName: "every",
    dataType: ["text", "textarea"]
  },
  {
    operator: "isBefore",
    operatorName: "isBefore",
    dataType: ["date"]
  },
  {
    operator: "isAfter",
    operatorName: "isAfter",
    dataType: ["date"]
  }
];
