import React, { Component } from 'react'; 

export default class TreeNode extends Component {

    constructor(props) {
        super(props);
        this.state = { collapsed: false };
    }
    onClick() {

        this.setState({
            collapsed: !this.state.collapsed
        });

    }

    render() {

        let subtree = null;
        if (this.props.data.children) {
            subtree = this.props.data.children.map(function (child) {
                return <TreeNode key={child.id} data={child} />;
            }.bind(this));
        }

        var arrowClassName = 'btn btn-nothing ';
        var containerClassName = 'tree-node-children ml-4 mt-2';
        if (this.state.collapsed) {
            arrowClassName = 'btn btn-nothing collapsed';
            containerClassName += ' tree-node-children-collapsed';
        }

        if (subtree) {
            return (
                <ul className="tree-node striped">
                    <li>
                        {/* <Arrow arrowClassName={arrowClassName}  /> */}
                        <button className={arrowClassName} onClick={this.onClick.bind(this)} data-id={this.props.data.id} >
                            <img src="/assets/images/folder.svg" alt="" />
                            <span className="text">Global-1</span>
                        </button>
                        <div className="right-buttons"><button className="btn   sm-padding">+</button></div>
                        <ul className={containerClassName}>
                            <li>
                                {subtree}
                            </li>
                        </ul>
                    </li>
                </ul>

            );
        }
        else {
            return (
                <div className="sub">
                    <button className="btn btn-nothing" data-id={this.props.data.id} >
                        <img src="/assets/images/item.svg" alt="" />
                        <span className="text">Global-1</span>
                    </button>
                    <div className="right-buttons">
                        <button className="btn   sm-padding">+</button>
                    </div>
                </div>
            );
        }


    }
};
