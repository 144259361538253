import React, { Component } from 'react';
import moment from 'moment';
import Moment from 'react-moment'

class CashAdvnaceDetailModal extends Component {
  render() {
    const getUserFullName = (userFirstName, userFirstMiddleName, userLastName) => {
      return userFirstName.fieldValue + ' ' + userFirstMiddleName.fieldValue + ' ' + userLastName.fieldValue;
    }
    let cashAdvnaceName, userDetails, userFirstName, userFirstMiddleName, userLastName, userFullName, userBaseCurrency,amountRequested,currencyRequedted;
    let response = this.props.cashAdvnaceDetailData, approvalListArray, approvalListValues;
    let cashAdvanceFormAndFields = this.props.cashAdvnaceDetailData.CASH_DATA.data.data;
    cashAdvnaceName = cashAdvanceFormAndFields.find((fields) => fields.columnName === "CASH_ADVANCE_NAME");
    userDetails = this.props.cashAdvnaceDetailData.CASH_DATA.data.userDeatils.fieldData;
    userFirstName = userDetails.find((userFields) => userFields.columnName === "EMPLOYEE_FIRST_NAME");
    userFirstMiddleName = userDetails.find((userFields) => userFields.columnName === "MIDDLE_NAME");
    userLastName = userDetails.find((userFields) => userFields.columnName === "EMPLOYEE_LAST_NAME");
    userFullName = userFirstName && userFirstMiddleName && userLastName && getUserFullName(userFirstName, userFirstMiddleName, userLastName);
    userBaseCurrency = userDetails.find((userFields) => userFields.columnName === "REIMBURSEMENT_CURRENCY");
    approvalListArray = response.info && Object.keys(response.info.steps);
    approvalListValues = response.info && Object.values(response.info.steps);
    let currentStatus = response.current.STATUS ? response.current.STATUS : response.info.steps[response.current.currentOrderNo].intialStatus;
    amountRequested = cashAdvanceFormAndFields.find((fields) => fields.columnName === "AMOUNT");
    currencyRequedted = cashAdvanceFormAndFields.find((fields) => fields.columnName === "CURRENCY");
    currencyRequedted = currencyRequedted.connectedListData.listManagementData.filter((currency)=>currency.id===currencyRequedted.fieldValue);
    currencyRequedted = currencyRequedted && currencyRequedted[0].itemCode?currencyRequedted[0].itemCode:'';
    return (
      this.props.cashAdvnaceDetailData && this.props.cashAdvnaceDetailData.CASH_DATA && this.props.cashAdvnaceDetailData.CASH_DATA.data.data.length > 0 ?
        <>
          <div className="modal-content border-0">
            <div className="modal-head">
              <button
                type="button"
                className="close d-none text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h6 className="modal-subtitle">Recall</h6>
              <h5 className="modal-title" id="newcashAdvancLabel">
                Cash Advance
                  </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Cash Advance ID</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{response.current.cashAdvanceId}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Cash Advance Name</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{cashAdvnaceName && cashAdvnaceName.fieldValue}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Request ID</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{response.current.requestId ? response.current.requestId : '-'}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Request Date</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{response.CASH_DATA.data.createdAt && moment(response.CASH_DATA.data.createdAt).format("DD MMM YYYY hh:mm a")}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Employee Email</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{response.CASH_DATA.data.userDeatils.email}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1 ">Employee Name</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{userFullName && userFullName}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Amount Requested (Base Currency)</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{userBaseCurrency && userBaseCurrency.fieldValue} {" "}
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(response.CASH_DATA.data.baseTotalAmount))}</p>
                </div>

                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Amount Requested</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{currencyRequedted && currencyRequedted} {" "}
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amountRequested && amountRequested.fieldValue?amountRequested.fieldValue:0))}</p>
                </div>


                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Amount Approved</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{response.CASH_DATA.data.amountApproved && userBaseCurrency.fieldValue}
                    {response.CASH_DATA.data.amountApproved ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(response.CASH_DATA.data.amountApproved ? response.CASH_DATA.data.amountApproved : 0)) : '-'}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Amount Issued</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{response.CASH_DATA.data.amountIssued && userBaseCurrency.fieldValue}
                    {response.CASH_DATA.data.amountIssued ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(response.CASH_DATA.data.amountIssued ? response.CASH_DATA.data.amountIssued : 0)) : '-'}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Exchange Rate</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">
                    {response.CASH_DATA.data.exchangeRate ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(response.CASH_DATA.data.exchangeRate)) : '-'}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Mode</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{response.CASH_DATA.data.modeName ? response.CASH_DATA.data.modeName : '-'}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Issue Date</p>
                  <p className="font-weight-bold mb-0 border-bottom-light pb-4">{currentStatus==='Approved'?response.CASH_DATA.data.createdAt && moment(response.CASH_DATA.data.createdAt).format('DD MMM YYYY hh:ss a'):'-'}</p>
                </div>
                <div className="col-md-4 mb-4">
                  <p className="lighter-p mb-1">Status</p>
                  <p className="font-weight-bold mb-0">{currentStatus}</p>
                </div>
              </div>
              <div className="white-card">
                <h5 className="sub-heading">Approver Flow</h5>
                {approvalListArray && approvalListArray.length > 0 && approvalListArray.map((index) => {
                  let userFirstName, userFirstMiddleName, userLastName, userFullName,email;
                  userDetails = approvalListValues[index].userDetails.fieldData;
                  userFirstName = userDetails.find((userFields) => userFields.columnName === "EMPLOYEE_FIRST_NAME");
                  userFirstMiddleName = userDetails.find((userFields) => userFields.columnName === "MIDDLE_NAME");
                  userLastName = userDetails.find((userFields) => userFields.columnName === "EMPLOYEE_LAST_NAME");
                  userFullName = userFirstName && userFirstMiddleName && userLastName && getUserFullName(userFirstName, userFirstMiddleName, userLastName);
                  email = approvalListValues[index].userDetails.email;
                  {console.log(approvalListValues[index])}
                  return (
                    <div className="approver with-white-space-100" key={index}>
                      {approvalListValues[index].APPROVED ?
                        <div className="right-icon approved">
                          <i className="material-icons">check_circle</i>
                        </div>
                        : approvalListValues[index].REJECTED ?
                          <div className="right-icon approved">
                            <i className="material-icons text-danger fs-20">thumb_down</i>
                          </div>
                          :
                          <div className="right-icon waiting">
                            <i className="material-icons">
                              hourglass_empty
                          </i>
                          </div>
                      }
                      <div className="shape-name">
                        {approvalListValues[index].userDetails.profileImage ?
                          <div className="left img"
                            style={{ backgroundImage: `url(${approvalListValues[index].userDetails && approvalListValues[index].userDetails.profileImage})` }}></div>
                          :
                          <div className="left icon">
                            <i className="material-icons">person</i>
                          </div>
                        }
                        <div className="right">
                          {/* <p className="label">Level {key + 1}</p> */}
                          <p className="title">{userFullName}</p>
                          <p className="desg">{email}</p>
                          {approvalListValues[index].submittedAt?<p>Submitted on : {approvalListValues[index].submittedAt?<Moment format='DD MMM YYYY, HH:mm'>{approvalListValues[index].submittedAt}</Moment>:"-"} </p>:""}
                          {approvalListValues[index].approvedAt?<p>Approved on :{approvalListValues[index].approvedAt?<Moment format='DD MMM YYYY, HH:mm'>{approvalListValues[index].approvedAt}</Moment>:"-"} </p>:""}

                        </div>
                        <div className="clearfix"></div>
                      </div>
                      {/*  */}
                    </div>)
                })
                }
                {/* for approval flow */}
              </div>
            </div>
            {!this.props.isRecallClikced ?
              <div className="modal-last btn-group-margin pm-30">
                {/* {currentStatus !== 'Approved'&&
                <button
                  type="button"
                  className="btn float-left"
                >
                  Delete
                </button>
                } */}
                {currentStatus !== 'Approved' && currentStatus !== 'Reject' &&
                  <button className="btn" onClick={() => this.props.onRecallHandler(response.id)}>
                    Recall
                </button>
                }
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div> :
              <div className="modal-last btn-group-margin pm-30">
                <button className="btn">
                  Please Wait...
                </button>
              </div>
            }
          </div>
        </>
        : null
    )
  }
}

export default CashAdvnaceDetailModal;
