import React from "react";
import Pagination from "react-js-pagination";
import Menu from "../menu";
import { NavLink } from "react-router-dom";
const CarConfigPage = props => {
  const { itemsCountPerPage, handleLimitChange, handlePageChange, activePage, totalItemsCount } = props;
  return (
    <div>
      <div className="admin-tabs">
        <Menu />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">A</div>
            <div className="right">
              <h6 className="heading">Car Configuration</h6>
              <p className="text">This page is used to maintain mileage amount defined by the company policy.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-padding">
        <div className="table-container">
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="btn-group-margin">
                  <NavLink
                    to={`/administration/expense/car-config/config-step1/${"new"}`}
                    className="btn sm-padding"
                  >
                    Add
                </NavLink>
                  <button
                    type="button"
                    className="btn "
                    disabled={!props.removeButton}
                    onClick={props.removeItemHandler}
                  >
                    Remove
                    </button>
                </div>
              </div>
              <div className="col-md-2 text-right" />
              <div className="col-md-2 text-right">
                {/* <button className="btn btn-upload"><i className="material-icons">cloud_upload</i></button> */}
              </div>
              <div className="col-md-2">
                <div className="search-custom">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder="Search"
                    value={props.search}
                    onChange={(e) => props.onSearchTextChangeHandler(e)}
                  />
                  <span>
                    <i className="material-icons align-middle">
                      search
                      </i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped table-selected">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Car type</th>
                  <th>Editable by</th>
                  <th>Applies to</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {(props.configsData.length > 0) ? props.configsData.map((car, i) => {
                  return (
                    <tr key={i}
                      className={
                        props.removeItem === car.id ? "active-tr" : ""
                      }>
                      <td><NavLink
                        to={`/administration/expense/car-config/config-step1/${car.id}`}>{car.configurationName}</NavLink></td>
                      <td onClick={e => props.onDeleteHandler(car.id)}>{car.carTypeId.itemName}</td>
                      <td onClick={e => props.onDeleteHandler(car.id)}>Global</td>
                      <td onClick={e => props.onDeleteHandler(car.id)}>{car.appliesToData.map((item) => item + ', ')}</td>
                      <td><NavLink className="btn "
                        to={`/administration/expense/car-config/config-step1/${car.id}`} >Edit</NavLink></td>
                      <td>
                        <label className="switch">
                          <input type="checkbox"
                            checked={car.activeStatus}
                            onChange={e =>
                              props.onStatusChangeHandler(
                                car.id,
                                car.activeStatus
                              )
                            } />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                  )
                }) :
                  <tr >
                    <td colSpan="6" className="text-center">"No data found"</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div className="table-footer">
            <div className="row">
              <div className="col-md-3">
                <div className="page-count">
                  <div className="text">Items</div>
                  <div className="count">
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        {itemsCountPerPage}
                      </button>
                      <div className="dropdown-menu">
                        <button
                          onClick={e => handleLimitChange(10)}
                          className="dropdown-item"
                        >
                          10
                            </button>
                        <button
                          onClick={e => handleLimitChange(20)}
                          className="dropdown-item"
                        >
                          20
                            </button>
                        <button
                          onClick={e => handleLimitChange(30)}
                          className="dropdown-item"
                        >
                          30
                            </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={3}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
};
export default CarConfigPage;
