import React, { Component } from "react";
import AllocationLeftSidebar from "./components/allocation/allocationLeftSidebar";
import $ from "jquery";
import { inject, observer } from "mobx-react";
import ExpenseAllocationTable from "./components/expenseAllocation/expenseAllocationTable";
import EditExpenseAllocationTable from "./components/expenseAllocation/editExpenseAllocationTable";
import { expenseReport } from "../../../_services/expenseReport";
import { getConnectedList } from "src/_services"
import moment from "moment";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import Loader from "./../../../_components/common/Loader";
import { getValue, setValue } from "src/_components/common/lodsh";


@inject("expenseStore")
@observer
class ExpenseAllocationPage extends Component {
  constructor(props) {
    super(props);
    this.validator1 = new SimpleReactValidator();
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      checked: {},
      checkedAll: 0,
      reportHeader: [],
      expenseEntryList: [],
      companyId: localStorage["440a28"],
      totoalExpense: 0,
      singleAllocationForm: [],
      expenseAllocationForm: [],
      count: 0,
      fieldsObject: [],
      selectedIndex: [],
      tableHead: [],
      allocatedIn: "Percentage",
      selectedExpenseEntry: [],
      cashAdvanceTotalAmount: 0,
      totalRequestAmount: 0,
      totalExpenseAmount: 0,
      validationStatus: false,
      connectedListData: [],
      userPermission: "employee-role",
      editAllocationForm: [],
      allocationEditIds: [],
      deleteEditAllocatopnIds: [],
      isLoading: false,
      connectedCreateListData: [],
      totalSelectesExpenseAmount: 0,
      totalAllocatedAmount: 0,
      allocatedAmounts: 0,
      isSubmitClick: false,
      auditRuleValidationError: [],
      search : [],
      loading:[],
      already:false
    };
  }
  componentWillUnmount(){
    this.state = {
     search : [],
     loading:[]
   }
 }
  componentDidMount() {
    this.getAllCreatedExpenses();
    this.getAddExpenseFormField();
    this.getLeftsidebarDataRefetch()
  }
  redirect(e) {
    $(".close").click();
    $(".itemisationExpenseModal").close();
  }


  // v2 left sidebar API common api after delete
  getLeftsidebarDataRefetch = async () => {
    let headerId = localStorage.getItem("headerId");
    if (headerId) {
      let response = await expenseReport.getAllCreatedExpenseNew(
        this.state.companyId,
        headerId,
        10,
        1
      );
      console.log(response, "--->v2 for sidebar respnse refetch");
      // for setting state
      const { expenseEntryList } = this.state;
      if (response && response.result.expenseReportEntryObj) {
        this.setState({ isLoading: false });
        this.count = response.count;
        this.setState({
          expenseEntryList: response.result.expenseReportEntryObj,
          totoalExpense: response.result.expenseReportEntryObj.length,
          isLoading: false
        });

        // validation starts:
        let validationStatus = true;
        let validationStatusArrays = []
        response.result.expenseReportEntryObj &&
          response.result.expenseReportEntryObj.forEach(list => {
            validationStatus = list.validation;
            validationStatusArrays.push(list.validation)
          });
        this.setState({
          validationStatus: validationStatus,
          validationStatusArray: validationStatusArrays
        });
        // ends
      } else {
        this.setState({
          isLoading: false,
          expenseEntryList: []
        });
      }
    }
  }

  getAllCreatedExpenses = async () => {
    let headerId = localStorage.getItem("headerId");
    if (headerId) {
      let response = await expenseReport.getAllCreatedExpense(
        this.state.companyId,
        headerId
      );
      if (response) {
        this.setState({
          reportHeader: response.headerObj.headerForm,
          // expenseEntryList: response.expenseReportEntryObj,
          // totoalExpense: response.expenseReportEntryObj.length,
          totalExpenseAmount: response.totalAmount,
          userBaseCurrency: response.baseCurrency,
          cashAdvanceTotalAmount: response.cashAdvanceTotalAmount,
          totalRequestAmount: response.requestAmount
        });
        // let validationStatus = true;
        // response.expenseReportEntryObj &&
        //   response.expenseReportEntryObj.forEach((list, index) => {
        //     validationStatus = !list.validation && validationStatus;
        //   });
        // this.setState({ validationStatus: validationStatus });
      }
    }
  };

  setAutoPercentageAmountHandler = async (allocatedIn, totalAmount, count) => {
    let totalAmountPercentage = 0;
    if (allocatedIn === "Percentage") {
      totalAmountPercentage = 100;
    } else {
      totalAmountPercentage = totalAmount;
    }
    let deviderInEquality = count;
    if (allocatedIn === "Amount") {
      if (this.state.selectedExpenseEntry.length > 0) {
        for (let index = 0; index < deviderInEquality; index++) {
          if (deviderInEquality >= 2) {
            if (index === 0) {
              await this.setState({
                ["amount" + index]:
                  Math.ceil((totalAmountPercentage / deviderInEquality) * 100) /
                  100
              });
            } else if (
              Math.floor(totalAmountPercentage * 100) % deviderInEquality ===
              0
            ) {
              await this.setState({
                ["amount" + index]:
                  Math.ceil((totalAmountPercentage / deviderInEquality) * 100) /
                  100
              });
            } else {
              await this.setState({
                ["amount" + index]:
                  Math.floor(
                    (totalAmountPercentage / deviderInEquality) * 100
                  ) / 100
              });
            }
          } else {
            await this.setState({
              ["amount" + index]:
                Math.floor((totalAmountPercentage / deviderInEquality) * 100) /
                100
            });
          }
        }
      } else {
        for (let index = 0; index < deviderInEquality; index++) {
          await this.setState({ ["amount" + index]: "0" });
        }
      }
    } else {
      for (let index = 0; index < deviderInEquality; index++) {
        if (deviderInEquality >= 2) {
          if (index === 0) {
            await this.setState({
              ["amount" + index]:
                Math.ceil((totalAmountPercentage / deviderInEquality) * 100) /
                100
            });
          } else {
            await this.setState({
              ["amount" + index]:
                Math.floor((totalAmountPercentage / deviderInEquality) * 100) /
                100
            });
          }
        } else {
          await this.setState(
            {
              ["amount" + index]:
                Math.floor((totalAmountPercentage / deviderInEquality) * 100) /
                100
            },
            () => { }
          );
        }
      }
    }
    this.percentageRemainingAmountHandler();
  };
  percentageRemainingAmountHandler = (amount, amountindex) => {
    let deviderInEquality = this.state.count + 1;
    let totalAllocatedAmount = 0;
    if (this.state.selectedExpenseEntry.length > 0) {
      if (this.state.allocatedIn === "Percentage") {
        for (let index = 0; index < deviderInEquality; index++) {
          if (index !== amountindex || amountindex === "allow") {
            if (deviderInEquality >= 2) {
              if (index === 0) {
                totalAllocatedAmount =
                  totalAllocatedAmount +
                  Math.floor(this.state["amount" + index] * 100) / 100;
              } else {
                totalAllocatedAmount =
                  totalAllocatedAmount +
                  Math.floor(this.state["amount" + index] * 100) / 100;
              }
            } else {
              totalAllocatedAmount =
                totalAllocatedAmount +
                Math.floor(this.state["amount" + index] * 100) / 100;
            }
          } else if (index === amountindex) {
            totalAllocatedAmount = totalAllocatedAmount + +amount;
          }
        }
        let percentageToAmount =
          (this.state.totalSelectesExpenseAmount * totalAllocatedAmount) / 100;
        this.setState({
          totalAllocatedAmount: percentageToAmount,
          allocatedAmounts: percentageToAmount
        });
      } else {
        for (let index = 0; index < deviderInEquality; index++) {
          if (index !== amountindex || amountindex === "allow") {
            if (deviderInEquality >= 2) {
              if (index === 0) {
                totalAllocatedAmount =
                  totalAllocatedAmount +
                  Math.floor(this.state["amount" + index] * 100) / 100;
              } else {
                totalAllocatedAmount =
                  totalAllocatedAmount +
                  Math.floor(this.state["amount" + index] * 100) / 100;
              }
            } else {
              totalAllocatedAmount =
                totalAllocatedAmount +
                Math.floor(this.state["amount" + index] * 100) / 100;
            }
          } else if (index === amountindex) {
            totalAllocatedAmount = totalAllocatedAmount + +amount;
          }
          this.setState({
            totalAllocatedAmount: totalAllocatedAmount,
            allocatedAmounts: totalAllocatedAmount
          });
        }
      }
    } else {
      this.setState({ totalAllocatedAmount: 0 });
    }
  };

  getAddExpenseFormField = async () => {
    let policyId = localStorage.getItem("policyId");

    let response = await expenseReport.getAddAllocationFormField(
      this.state.companyId,
      policyId
    );
    let count = 0;

    if (response && response.allocationForm.result) {
      await response.allocationForm.result.forEach(async item => {
        if (item.connectedListId) {
          let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp, "count", 0);
          item.connectedListData.listManagementData = resp.result;
          item.search = ''
        }
        this.setState({ expenseForm: response.result })
      })
    }
    if (response && response.allocationForm && response.allocationForm.result) {
      let NewArray = [];
      NewArray[0] = response.allocationForm.result;
      this.setState({
        expenseAllocationForm: NewArray,
        singleAllocationForm: response.allocationForm.result,
        tableHead: response.allocationForm.result
      });

      let newConnectedListData = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.allocationForm.result, function (
        result
      ) {
        return result.fieldLevel !== undefined && result.fieldLevel !== "";
      });
      dynamicFeilds.forEach(fields => {
        newConnectedListData.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField
            ? fields.multiSelectParentField
            : "",
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.defaultValue ? fields.defaultValue : "",
          fieldValue: fields.defaultValue ? fields.defaultValue : ""
        });
      });
      this.setState({ connectedCreateListData: newConnectedListData });
      this.createDropdownForMultiLblConnectedList(newConnectedListData);
      this.setTheStateOfFormsAndFields(
        response.allocationForm.result,
        count,
        newConnectedListData
      );
    } else {
      this.setState({ isLoading: true });
      toast.success("No Data Found");
    }
  };

  getFeildList = async () => {
    let policyId = localStorage.getItem("policyId");

    let response = await expenseReport.getAddAllocationFormField(
      this.state.companyId,
      policyId
    );
    if (response && response.allocationForm.result) {
      await response.allocationForm.result.forEach(async item => {
        if (item.connectedListId) {
          let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp, "count", 0);
          item.connectedListData.listManagementData = resp.result;
          item.search = ''
        }
      })
    }
    if (response && response.allocationForm && response.allocationForm.result) {
      this.setState({
        tableHead: response.allocationForm.result
      });

      let newConnectedListData = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.allocationForm.result, function (
        result
      ) {
        return result.fieldLevel !== undefined && result.fieldLevel !== "";
      });
      dynamicFeilds.forEach(fields => {
        newConnectedListData.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField
            ? fields.multiSelectParentField
            : "",
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.defaultValue ? fields.defaultValue : "",
          fieldValue: fields.defaultValue ? fields.defaultValue : ""
        });
      });
      this.setState({ connectedCreateListData: newConnectedListData });
      this.createDropdownForMultiLblConnectedList(newConnectedListData);
      this.setTheStateOfFormsAndFields(
        response.allocationForm.result,
        0,
        newConnectedListData
      );
    }
    return getValue(response,"allocationForm.result",[]);
  }

  getDataFromCopyDown = async (formField, newConnectedListDate, index) => {
    let copyDownObject = {
      companyId: this.state.companyId,
      headerId: localStorage.getItem("headerId"),
      fieldId: [formField.id]
    };
    let response = await expenseReport.postGetcopyDownData(copyDownObject);
    if (response && response.result) {
      if (+formField.fieldLevel === 1) {
        let newFieldLevel = formField.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter(
          listData =>
            listData.multiSelectParentField === formField.id &&
            listData.fieldLevel === newFieldLevel
        );
        if (existConnected.length > 0) {
          let companyId = this.state.companyId;
          let responseMult = await expenseReport.getConnectedListData(
            companyId,
            response.result[0].fieldValue
          );
          let childName = existConnected[0].columnName + index;
          if (responseMult) {
            this.setState({ [childName]: responseMult });
          }
        }
      } else if (formField.fieldLevel >= 2) {
        let newFieldLevel = formField.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter(
          listData =>
            listData.multiSelectParentField ===
            formField.multiSelectParentField &&
            listData.fieldLevel === newFieldLevel
        );
        if (
          existConnected.length > 0 &&
          formField.defaultValue !== undefined &&
          formField.defaultValue !== ""
        ) {
          let companyId = this.state.companyId;
          let responseMult = await expenseReport.getConnectedListData(
            companyId,
            response.result[0].fieldValue
          );
          let childName = existConnected[0].columnName + index;
          if (responseMult) {
            this.setState({ [childName]: responseMult });
          }
        }
      }
      return response.result[0].fieldValue;
    } else {
      return "";
    }
    //return '';
  };

  checkDefaultValueHasChild = async (
    currentField,
    newConnectedListDate,
    index
  ) => {
    if (+currentField.fieldLevel === 1) {
      let newFieldLevel = currentField.fieldLevel + 1;
      let existConnected = newConnectedListDate.filter(
        listData =>
          listData.multiSelectParentField === currentField.id &&
          listData.fieldLevel === newFieldLevel
      );
      if (existConnected.length > 0) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(
          companyId,
          currentField.defaultValue
        );
        let childName = existConnected[0].columnName + index;
        if (response) {
          this.setState({ [childName]: response });
        }
      }
    } else if (currentField.fieldLevel >= 2) {
      let newFieldLevel = currentField.fieldLevel + 1;
      let existConnected = newConnectedListDate.filter(
        listData =>
          listData.multiSelectParentField ===
          currentField.multiSelectParentField &&
          listData.fieldLevel === newFieldLevel
      );
      if (
        existConnected.length > 0 &&
        currentField.defaultValue !== undefined &&
        currentField.defaultValue !== ""
      ) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(
          companyId,
          currentField.defaultValue
        );
        let childName = existConnected[0].columnName + index;
        if (response) {
          this.setState({ [childName]: response });
        }
      }
    }
  };

  setTheStateOfFormsAndFields = async (
    formAndFields,
    editCount,
    connectedListData
  ) => {
    // let formFields = [];
    // let fieldsObjects = [];
    // let index = editCount;
    // formAndFields.map(async (formField) => {
    //   let fieldName = formField.fieldName + index;
    //   if (formField.dataType === "date") {
    //     if (formField.isDefaultValue) this.setState({ [fieldName]: moment(formField.defaultValue).format("DD MMM YYYY") });
    //     else this.setState({ [fieldName]: "" });
    //   } else if (formField.dataType === "checkbox") {
    //     if (formField.isDefaultValue) this.setState({ [fieldName]: formField.defaultValue });
    //     else this.setState({ [fieldName]: false });
    //   } else if (formField.dataType === "search") {
    //     this.setState({ ['search' + fieldName]: [] });
    //     if (formField.isDefaultValue) this.setState({ [fieldName]: formField.defaultValue });
    //     else this.setState({ [fieldName]: "" });
    //   } else {
    //     if (formField.isDefaultValue) this.setState({ [fieldName]: formField.defaultValue });
    //     else this.setState({ [fieldName]: "" });
    //   }
    //   formFields.push(formField.fieldName);
    //   fieldsObjects.push({ fieldId: formField.id, columnName: formField.columnName });
    // });
    // this.setState({ formFields: formFields, fieldsObject: fieldsObjects });
    // this.setState({ isLoading: true })

    let formFields = [];
    let fieldsObjects = [];
    let fieldArrayValues = [];
    let index = editCount;
    fieldArrayValues = formAndFields.map(async formField => {
      let fieldName = formField.fieldName + index;
      formFields.push(formField.fieldName);
      fieldsObjects.push(formField);
      if (formField.dataType === "date") {
        let copyDown = formField.isCopyDown
          ? await this.getDataFromCopyDown(formField, connectedListData, index)
          : formField.isDefaultValue
            ? moment(formField.defaultValue).format("DD MMM YYYY")
            : "";
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      } else if (formField.dataType === "checkbox") {
        let copyDown = formField.isCopyDown
          ? await this.getDataFromCopyDown(formField, connectedListData, index)
          : formField.isDefaultValue
            ? formField.defaultValue
            : "";
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      } else {
        let copyDown;
        if (formField.isCopyDown)
          copyDown = await this.getDataFromCopyDown(
            formField,
            connectedListData,
            index
          );
        else if (formField.isDefaultValue)
          copyDown = formField.isDefaultValue ? formField.defaultValue : "";
        else copyDown = "";
        formField.fieldLevel >= 1 &&
          !formField.isCopyDown &&
          !formField.isCopyDown &&
          formField.isDefaultValue &&
          this.checkDefaultValueHasChild(formField, connectedListData, index);
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      }
    });
    let fieldValues = await Promise.all(fieldArrayValues);
    var fieldValueObj = fieldValues.reduce(
      (obj, item) => ((obj[item.key] = item.value), obj),
      {}
    );
    this.setState({
      formFields: formFields,
      fieldsObject: fieldsObjects,
      isLoading: false,
      ...fieldValueObj,
      isLoading: true
    });
  };

  createDropdownForMultiLblConnectedList = newConnectedListData => {
    newConnectedListData &&
      newConnectedListData.forEach(async (fields, index) => {
        if (fields.fieldLevel === 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListData.filter(
            listData =>
              listData.multiSelectParentField === fields.id &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName + index;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 2) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListData.filter(
            listData =>
              listData.multiSelectParentField ===
              fields.multiSelectParentField &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName + index;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }
      });
  };

  onAddClickHandler = async () => {
    let count = this.state.count;
    let newCount = count + 1;
    let singleAllocationForm = await this.getFeildList();
    // console.log(singleAllocationForm)
    let expenseAllocationForm = [...this.state.expenseAllocationForm];
    expenseAllocationForm.push(singleAllocationForm);
    this.setState({
      expenseAllocationForm: expenseAllocationForm,
      count: newCount
    });
    let newConnectedListData = [...this.state.connectedCreateListData];
    this.setTheStateOfFormsAndFields(
      singleAllocationForm,
      newCount,
      newConnectedListData
    );
    this.setAutoPercentageAmountHandler(
      this.state.allocatedIn,
      this.state.totalSelectesExpenseAmount,
      expenseAllocationForm.length
    );
  };

  onAddEditClickHandler = () => {
    let count = this.state.count;
    let newCount = count + 1;
    let singleAllocationForm = [...this.state.singleAllocationForm];
    let editAllocationForm = [...this.state.editAllocationForm];
    if (singleAllocationForm !== undefined && singleAllocationForm.length > 0) {
      editAllocationForm[newCount] = singleAllocationForm;
      this.setState({
        editAllocationForm: editAllocationForm,
        count: newCount
      });
      let newConnectedListData = [...this.state.connectedCreateListData];
      this.setTheStateOfFormsAndFields(
        singleAllocationForm,
        newCount,
        newConnectedListData
      );
      this.setAutoPercentageAmountHandler(
        this.state.allocatedIn,
        this.state.totalSelectesExpenseAmount,
        editAllocationForm.length
      );
    }
  };

  returnExpenseAllocationForm = async () => {
    let expenseTypeId = localStorage.getItem("expenseType");
    let response = await expenseReport.getAddExpenseFormField(
      this.state.companyId,
      expenseTypeId
    );
    if (response.entryFields) {
      return response.entryFields.result;
    }
  };

  onSubmitHandler = async () => {
    if (this.validator.allValid()) {
      this.setState({ isSubmitClick: true });
      let fieldsObjects = [...this.state.expenseAllocationForm];
      let headerId = localStorage.getItem("headerId");
      let id= localStorage.getItem("expenseEntryId")//added by sethu
      let submitArray = [];
      for (let val in fieldsObjects) {
        let internalArray = [];
        for (let value in fieldsObjects[val]) {
          let val1 = fieldsObjects[val];
          let fieldId = val1[value].id;
          let columnName = val1[value].columnName;
          let fieldValue = "";
          if (columnName === "LOCATION_NAME") {
            fieldValue =
              this.state[val1[value].fieldName + val] !== ""
                ? this.state[val1[value].fieldName + val].value
                : "";
          } else {
            fieldValue = this.state[val1[value].fieldName + val];
          }

          if (fieldValue === undefined) {
            internalArray.push({ fieldId: fieldId, fieldValue: "" });
          } else {
            internalArray.push({
              fieldId: fieldId,
              fieldValue: fieldValue,
              columnName: columnName
            });
          }
        }
        submitArray.push(internalArray);
      }
      let policyId = localStorage.getItem("policyId");
      let newSubmitArray = [];
      let totalPercentage = 0;

      let selectedExpenseEntry = [...this.state.selectedExpenseEntry];

      submitArray.forEach((data, index) => {
        let entryObjs = {};
        if (this.state.allocatedIn === "Percentage") {
          selectedExpenseEntry.forEach((entry, index) => {
            entryObjs[selectedExpenseEntry[index]] = {
              amount: 0,
              percentage: this.state["amount" + index],
              expenseAmount: this.state.expenseEntryList.find(
                entry => entry.id === selectedExpenseEntry[index]
              ).baseAmount
            };
          });
        } else {
          selectedExpenseEntry.forEach((entry, index) => {
            entryObjs[selectedExpenseEntry[index]] = {
              amount: this.state["amount" + index],
              percentage: 0,
              expenseAmount: this.state.expenseEntryList.find(
                entry => entry.id === selectedExpenseEntry[index]
              ).baseAmount
            };
          });
        }
        if (data.length > 0) {
          totalPercentage = totalPercentage + +this.state["amount" + index];
          let createExpensePolicy = {
            companyId: this.state.companyId,
            headerId: headerId,
            // id: localStorage.getItem("expenseEntryId"),//added by sethu
            amountOrPercentage: {
              fieldName: this.state.allocatedIn,
              fieldValue: this.state["amount" + index]
            },
            policyId: policyId,
            reportEntryId: [...this.state.selectedExpenseEntry],
            allocationForm: data,
            entryObjs: entryObjs,
            baseAmount: this.state.totalSelectesExpenseAmount
          };
          newSubmitArray.push(createExpensePolicy);
        }
      });
      if (this.state.allocatedIn === "Percentage") {
        if (totalPercentage === 100) {
          if (selectedExpenseEntry.length > 0) {
            let response = await expenseReport.addExpenseAllocation(
              newSubmitArray
            );
            if (
              response.result &&
              response.result.errorMessages &&
              response.result.errorMessages.errorMessages
            ) {
              this.setState({
                isSubmitClick: false,
                auditRuleValidationError:
                  response.result.errorMessages.errorMessages
              });
            } else {
              this.setState({ isSubmitClick: false });
              this.setState({
                singleAllocationForm: [],
                expenseAllocationForm: [],
                fieldsObject: [],
                selectedIndex: [],
                tableHead: [],
                selectedExpenseEntry: []
              });
              toast.success(response.message);


              let formIdNew = localStorage.getItem("formIdNew")
              let prevPage = localStorage.getItem("from")
              if (formIdNew && prevPage !== "finance" && prevPage !== "Expense") {
                this.props.history.push(`../expense/details/approval/${formIdNew}`);
              } else {
                this.props.history.push("./details");
              }

            }
          } else {
            this.setState({ isSubmitClick: false });
            toast.error("Select At least One Expense Entry");
          }
        } else {
          this.setState({ isSubmitClick: false });
          toast.error(
            "Total allocation percentage not less than or more than 100 %"
          );
        }
      } else {
        let response = await expenseReport.addExpenseAllocation(newSubmitArray);
        if (
          response.result &&
          response.result.errorMessages &&
          response.result.errorMessages.errorMessages
        ) {
          this.setState({
            isSubmitClick: false,
            auditRuleValidationError:
              response.result.errorMessages.errorMessages
          });
        } else {
          this.setState({
            singleAllocationForm: [],
            expenseAllocationForm: [],
            fieldsObject: [],
            selectedIndex: [],
            tableHead: [],
            isSubmitClick: false
          });
          toast.success(response.message);

          let formIdNew = localStorage.getItem("formIdNew")
          let prevPage = localStorage.getItem("from")
          if (formIdNew && prevPage !== "finance" && prevPage !== "Expense") {
            this.props.history.push(`../expense/details/approval/${formIdNew}`);
          } else {
            this.props.history.push("./details");
          }
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  onSelectedAllocationFieldHandler = value => {
    let selectedIndex = [...this.state.selectedIndex];
    if (selectedIndex.includes(value)) {
      selectedIndex = selectedIndex.filter(id => id !== value);
    } else {
      selectedIndex = selectedIndex.concat(value);
    }
    this.setState({ selectedIndex: selectedIndex });
  };
  onEditSelectedAllocationFieldHandler = (value, fieldId) => {
    let selectedIndex = [...this.state.selectedIndex];
    let deleteEditAllocatopnIds = [...this.state.deleteEditAllocatopnIds];
    if (fieldId !== "" && fieldId !== undefined) {
      if (deleteEditAllocatopnIds.includes(fieldId)) {
        deleteEditAllocatopnIds = deleteEditAllocatopnIds.filter(
          id => id !== fieldId
        );
      } else {
        deleteEditAllocatopnIds = deleteEditAllocatopnIds.concat(fieldId);
      }
      this.setState({
        deleteEditAllocatopnIds: deleteEditAllocatopnIds
      });
    }
    if (selectedIndex.includes(value)) {
      selectedIndex = selectedIndex.filter(id => id !== value);
    } else {
      selectedIndex = selectedIndex.concat(value);
    }
    this.setState({ selectedIndex: selectedIndex });
  };
  onDeleteAllocationField = () => {
    let selectedIndex = [...this.state.selectedIndex];
    let expenseAllocationForm = [...this.state.expenseAllocationForm];
    let deleteArrLength = selectedIndex.length;
    let selectedLength = expenseAllocationForm.length;
    _.pullAt(expenseAllocationForm, selectedIndex);
    this.setState(
      { expenseAllocationForm: expenseAllocationForm, selectedIndex: [] },
      () => {
        this.setAutoPercentageAmountHandler(
          this.state.allocatedIn,
          this.state.totalSelectesExpenseAmount,
          expenseAllocationForm.length
        );
        this.percentageRemainingAmountHandler(
          this.state.totalSelectesExpenseAmount,
          "allow"
        );
      }
    );
    let count = this.state.count;
    this.setState({ count: count - deleteArrLength });
    expenseAllocationForm.forEach((fields, index) => {
      fields.forEach(formField => {
        this.setState({ ["amount" + index]: 100 });
        let fieldName = formField.fieldName + (selectedLength - 1);
        if (formField.dataType === "date") {
          let todayDate = new Date();
          todayDate = moment(todayDate).format("DD MMM YYYY");
          this.setState({ [fieldName]: todayDate });
        } else if (formField.dataType === "checkbox") {
          this.setState({ [fieldName]: false });
        } else {
          this.setState({ [fieldName]: "" });
        }
      });
    });
  };
  onEditDeleteAllocationField = async () => {
    let selectedIndex = [...this.state.selectedIndex];
    let editAllocationForm = [...this.state.editAllocationForm];
    let deleteArrLength = selectedIndex.length;
    _.pullAt(editAllocationForm, selectedIndex);
    this.setState({
      editAllocationForm: editAllocationForm,
      selectedIndex: []
    });
    let count = this.state.count;
    this.setState({ count: count - deleteArrLength });
    editAllocationForm.forEach((fields, index) => {
      fields.forEach(formField => {
        this.setState({ ["amount" + index]: 0 });
        let fieldName = formField.fieldName + index;
        if (formField.dataType === "date") {
          let todayDate = new Date();
          todayDate = moment(todayDate).format("DD MMM YYYY");
          this.setState({ [fieldName]: todayDate });
        } else if (formField.dataType === "checkbox") {
          this.setState({ [fieldName]: false });
        } else {
          if (
            formField.fieldValue !== undefined &&
            formField.fieldValue !== "" &&
            formField.fieldValue !== null
          ) {
            this.setState({ [fieldName]: formField.fieldValue });
          } else {
            this.setState({ [fieldName]: "" });
          }
        }
      });
    });
    let deleteEditAllocatopnIds = [...this.state.deleteEditAllocatopnIds];
    let data = {
      id: deleteEditAllocatopnIds,
      companyId: this.state.companyId
    };
    let response = await expenseReport.deleteAllocationEntries(data);
    if (response) {
      toast.error(response.message);
      this.getAddExpenseFormField();
      if (
        response.result &&
        response.result.reportAllocationObjs &&
        response.result.reportAllocationObjs.length <= 0
      ) {
        this.setState({ isLoading: false });
      }
    }
  };
  onClickAllocateHandler = (e, value) => {
    e.preventDefault();
    this.setState({ allocatedIn: value }, () => {
      this.percentageRemainingAmountHandler();
    });
    this.setAutoPercentageAmountHandler(
      value,
      this.state.totalSelectesExpenseAmount,
      this.state.expenseAllocationForm.length
    );
  };
  onCancelClickHandler = () => {

    let formIdNew = localStorage.getItem("formIdNew")
    let prevPage = localStorage.getItem("from")
    if (formIdNew && prevPage !== "finance" && prevPage !== "Expense") {
      this.props.history.push(`../expense/details/approval/${formIdNew}`);
    } else {
      this.props.history.push("./details");
    }
  };
  onClickHandler = async (fieldName, conId, fieldId, fieldLevel, assignKey, rowIndex) => {

    this.state.search[assignKey] = '';
    this.setState({search:this.state.search})

    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedCreateListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName + rowIndex;
      let fieldName = existConnected[0].fieldName + rowIndex;
      if (response) {
        this.setState({ [childName]: response, [fieldName]: "" });
      }
    }
  };
  onConnectedListkHandler = async (
    fieldName,
    conId,
    fieldId,
    fieldLevel,
    rowIndex
  ) => {
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedCreateListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName + rowIndex;
      let fieldName = existConnected[0].fieldName + rowIndex;
      if (response) {
        this.setState({ [childName]: response, [fieldName]: "" });
      }
    }
  };
  onChangeHandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };
  onDatePickerHandler = (value, fieldName) => {
    if (!isNaN(value)) {
      this.setState({
        [fieldName]: moment(new Date(value)).format("DD MMM YYYY")
      });
    }
  };
  onCheckboxHandler = fieldName => {
    this.setState({ [fieldName]: !this.state[fieldName] });
  };

  onIntegerHandler = (event, fieldName) => {
    // eslint-disable-next-line no-self-compare
    if (Number(event.target.value) === Number(event.target.value)) {
      this.setState({ [fieldName]: +event.target.value });
    }
  };

  onLocationChangekHandler = (event, fieldName) => {
    this.setState({ [fieldName]: event });
  };

  loadOptions = async newValue => {
    return expenseReport.getAllLocationsName(newValue).then(data => {
      return data.result.map(list => {
        return {
          label: list.name + "," + list.countryId.name,
          value: list.id
        };
      });
    });
  };
  onAmountPercentageChangeHandler = (event, fieldName, index) => {
    // eslint-disable-next-line no-self-compare
    if (Number(event.target.value) === Number(event.target.value)) {
      this.setState({ [fieldName]: event.target.value });
      this.percentageRemainingAmountHandler(event.target.value, index);
    } else {
      // this.setState({ [fieldName]: 0 });
      this.percentageRemainingAmountHandler(0, index);
    }
  };

  onSlectedExpenseEntry = expenseId => {
    let selectedExpenseEntry = [...this.state.selectedExpenseEntry];
    if (selectedExpenseEntry.includes(expenseId)) {
      selectedExpenseEntry = selectedExpenseEntry.filter(
        id => id !== expenseId
      );
    } else {
      selectedExpenseEntry.push(expenseId);
    }
    this.setState({ selectedExpenseEntry: selectedExpenseEntry }, () => {
      this.percentageRemainingAmountHandler(0, "allow");
      this.calculateSelctedExpenseAmounts(selectedExpenseEntry);
    });
  };

  calculateSelctedExpenseAmounts = selectedExpenseEntry => {
    let expenseEntryList = [...this.state.expenseEntryList];
    if (selectedExpenseEntry.length > 0) {
      expenseEntryList = expenseEntryList.filter(
        list => selectedExpenseEntry.includes(list.id) === true
      );
      if (expenseEntryList.length > 0) {
        let totalAmount = 0;
        expenseEntryList.forEach(list => {
          totalAmount = totalAmount + list.baseAmount;
        });
        totalAmount = Math.floor(totalAmount * 100) / 100;
        this.setState({ totalSelectesExpenseAmount: totalAmount }, () => {
          this.percentageRemainingAmountHandler(0, "allow");
        });
        this.setAutoPercentageAmountHandler(
          this.state.allocatedIn,
          totalAmount,
          this.state.expenseAllocationForm.length
        );
      } else {
        this.setState({ totalSelectesExpenseAmount: 0 }, () => {
          this.setAutoPercentageAmountHandler(
            this.state.allocatedIn,
            this.state.totalSelectesExpenseAmount,
            this.state.expenseAllocationForm.length
          );
        });
      }
    } else {
      this.setState({ totalSelectesExpenseAmount: 0 }, () => {
        this.setAutoPercentageAmountHandler(
          this.state.allocatedIn,
          this.state.totalSelectesExpenseAmount,
          this.state.expenseAllocationForm.length
        );
      });
    }
  };

  onUpdateExpenseSubmitHandler = async () => {
    if (this.validator1.allValid()) {
      this.setState({ isSubmitClick: true });
      let fieldsObjects = [...this.state.editAllocationForm];
      let headerId = localStorage.getItem("headerId");
      let submitArray = [];
      for (let val in fieldsObjects) {
        let internalArray = [];
        for (let value in fieldsObjects[val]) {
          let val1 = fieldsObjects[val];
          let fieldId = val1[value].id;
          let columnName = val1[value].columnName;
          let fieldValue = "";
          if (columnName === "LOCATION_NAME") {
            fieldValue =
              this.state[val1[value].fieldName + val] !== ""
                ? this.state[val1[value].fieldName + val].value
                : "";
          } else {
            fieldValue = this.state[val1[value].fieldName + val];
          }
          if (fieldValue === undefined) {
            internalArray.push({ fieldId: fieldId, fieldValue: "" });
          } else {
            internalArray.push({
              fieldId: fieldId,
              fieldValue: fieldValue,
              columnName: columnName
            });
          }
        }
        submitArray.push(internalArray);
      }
      let policyId = localStorage.getItem("policyId");
      let newSubmitArray = [];
      let allocationEditIds = [...this.state.allocationEditIds];
      let totalPercentage = 0;
      submitArray.forEach((data, index) => {
        if (data.length > 0) {
          totalPercentage = totalPercentage + +this.state["amount" + index];
          let createExpensePolicy = [];
          if (
            allocationEditIds[index] !== undefined &&
            allocationEditIds[index] !== ""
          ) {
            createExpensePolicy = {
              companyId: this.state.companyId,
              headerId: headerId,
              id: allocationEditIds[index],
              amountOrPercentage: {
                fieldName: this.state.allocatedIn,
                fieldValue: this.state["amount" + index]
              },
              policyId: policyId,
              reportEntryId: [...this.state.selectedExpenseEntry],
              allocationForm: data
            };
          } else {
            createExpensePolicy = {
              companyId: this.state.companyId,
              headerId: headerId,
              amountOrPercentage: {
                fieldName: this.state.allocatedIn,
                fieldValue: this.state["amount" + index]
              },
              policyId: policyId,
              reportEntryId: [...this.state.selectedExpenseEntry],
              allocationForm: data
            };
          }
          newSubmitArray.push(createExpensePolicy);
        }
      });
      let selectedExpenseEntry = [...this.state.selectedExpenseEntry];
      if (this.state.allocatedIn === "Percentage") {
        if (totalPercentage === 100) {
          if (selectedExpenseEntry.length > 0) {
            let response = await expenseReport.addExpenseAllocation(
              newSubmitArray
            );
            if (
              response.result &&
              response.result.errorMessages &&
              response.result.errorMessages.errorMessages
            ) {
              this.setState({
                isSubmitClick: false,
                auditRuleValidationError:
                  response.result.errorMessages.errorMessages
              });
            } else {
              this.setState({
                singleAllocationForm: [],
                expenseAllocationForm: [],
                fieldsObject: [],
                selectedIndex: [],
                tableHead: []
              });
              toast.success(response.message);

              let formIdNew = localStorage.getItem("formIdNew")
              let prevPage = localStorage.getItem("from")
              if (formIdNew && prevPage !== "finance" && prevPage !== "Expense") {
                this.props.history.push(`../expense/details/approval/${formIdNew}`);
              } else {
                this.props.history.push("./details");
              }
            }
          } else {
            this.setState({ isSubmitClick: false });
            toast.error("Select Atleast One Expense Entry");
          }
        } else {
          this.setState({ isSubmitClick: false });
          toast.error(
            "Total allocation percentage not less than or more than 100 %"
          );
        }
      } else {
        let response = await expenseReport.addExpenseAllocation(newSubmitArray);
        if (
          response.result &&
          response.result.errorMessages &&
          response.result.errorMessages.errorMessages
        ) {
          this.setState({
            isSubmitClick: false,
            auditRuleValidationError:
              response.result.errorMessages.errorMessages
          });
        } else {
          this.setState({
            singleAllocationForm: [],
            expenseAllocationForm: [],
            fieldsObject: [],
            selectedIndex: [],
            tableHead: [],
            isSubmitClick: false
          });
          toast.success(response.message);

          let formIdNew = localStorage.getItem("formIdNew")
          let prevPage = localStorage.getItem("from")
          if (formIdNew && prevPage !== "finance" && prevPage !== "Expense") {
            this.props.history.push(`../expense/details/approval/${formIdNew}`);
          } else {
            this.props.history.push("./details");
          }
        }
      }
    } else {
      this.validator1.showMessages();
      this.forceUpdate();
    }
  };
  onCancelClickHandler = () => {

    let formIdNew = localStorage.getItem("formIdNew")
    let prevPage = localStorage.getItem("from")
    if (formIdNew && prevPage !== "finance" && prevPage !== "Expense") {
      this.props.history.push(`../expense/details/approval/${formIdNew}`);
    } else {
      this.props.history.push("./details");
    }
  };
  openAuditTrail() { }

  setAlready = () =>{
    if(this.state.already === true){
    this.setState({ already: false})
    }
  }

  handleScroll = async (e, id, assignKey, page, count, totalLength, search) => {
    if(totalLength < count){
      this.setAlready();
      }
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    setValue(this.state, "clientHeight", e.target.scrollHeight)
    if (bottom && !this.state.already) {
      this.setState({
        already:true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight-25)
      })

      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {
          let findIndex = assignKey.split("-")
          let mergeOld = getValue(this.state.expenseAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", []).concat(resp.result)
          setValue(this.state.expenseAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", mergeOld)
          setValue(this.state.expenseAllocationForm[findIndex[0]][findIndex[1]], "page", page + 1)
        }
        this.setState({
          already:false
        })
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
      }
    }
  }
  handleSearchManage = async (search, id, assignKey) => {
    if(this.state.already === true){
      this.setState({already:false})
    }
          this.state.loading[assignKey] = true;
          this.state.search[assignKey] = search;
          this.setState({search:this.state.search}, () => {
            $(`#valueConnect${assignKey}`).focus();
          })
          let findIndex = assignKey.split("-")
          let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
          if (resp) {
            setValue(this.state.expenseAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", resp.result)

          } else {
            setValue(this.state.expenseAllocationForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", [])
          }
          setValue(this.state.expenseAllocationForm[assignKey], "search", search)
          this.state.loading[assignKey] = false;
          this.setState({
            ...this.state
          })
          $(`#valueConnect${assignKey}`).focus();
          $(`#valueConnect${assignKey}`).attr('value', search);
          $(`#connect${assignKey}`).addClass("show")

}
  render() {
    return (
      <div>
        <AllocationLeftSidebar
          onSubmitHandler={this.onSubmitHandler}
          totoalExpense={this.state.totoalExpense}
          expenseEntryList={this.state.expenseEntryList}
          selectedExpenseEntry={this.state.selectedExpenseEntry}
          onSlectedExpenseEntry={this.onSlectedExpenseEntry}
          selectExpenstEntryList={
            this.props.expenseStore.selectExpenstEntryList
          }
          isSubmitClick={this.state.isSubmitClick}
          cashAdvanceTotalAmount={this.state.cashAdvanceTotalAmount}
          userBaseCurrency={this.state.userBaseCurrency}
          totalExpenseAmount={this.state.totalExpenseAmount}
          totalRequestAmount={this.state.totalRequestAmount}
          validationStatus={this.state.validationStatus}
          editAllocation={this.state.editAllocationForm}
          onUpdateExpenseSubmitHandler={this.onUpdateExpenseSubmitHandler}
          openAuditTrail={this.openAuditTrail}

        />
        <div className="expense-right">
          <div className="py-padding">
            {/* <p>Allocated</p> */}
            <div className="table-container animation-target-left min-height-medium">
              <form>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="btn-group-margin mb-2">
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          Allocate By
                        </button>
                        <div className="dropdown-menu">
                          <button
                            onClick={e =>
                              this.onClickAllocateHandler(e, "Percentage")
                            }
                            className="dropdown-item w-100"
                          >
                            Percentage
                          </button>
                          <button
                            onClick={e =>
                              this.onClickAllocateHandler(e, "Amount")
                            }
                            className="dropdown-item w-100"
                          >
                            Amount
                          </button>
                        </div>
                      </div>

                      {this.state.editAllocationForm &&
                        this.state.editAllocationForm.length > 0 ? (
                          <button
                            onClick={this.onAddEditClickHandler}
                            type="button"
                            className="btn"
                          >
                            Add
                          </button>
                        ) : (
                          <button
                            disabled={
                              this.state.expenseAllocationForm.length <= 0
                            }
                            onClick={this.onAddClickHandler}
                            type="button"
                            className="btn"
                          >
                            Add
                          </button>
                        )}
                      {this.state.editAllocationForm &&
                        this.state.editAllocationForm.length > 0 ? (
                          <button
                            disabled={
                              this.state.selectedIndex.length === 0 ||
                              (this.state.editAllocationForm.length >= 1 &&
                                this.state.selectedIndex.length &&
                                this.state.selectedIndex.length ===
                                this.state.editAllocationForm.length)
                            }
                            onClick={this.onEditDeleteAllocationField}
                            type="button"
                            className="btn mt-2 mt-md-0"
                          >
                            Delete
                          </button>
                        ) : (
                          <button
                            disabled={
                              this.state.selectedIndex.length === 0 ||
                              (this.state.expenseAllocationForm.length >= 1 &&
                                this.state.selectedIndex.length &&
                                this.state.selectedIndex.length ===
                                this.state.expenseAllocationForm.length)
                            }
                            onClick={this.onDeleteAllocationField}
                            type="button"
                            className="btn mt-2 mt-md-0"
                          >
                            Delete
                          </button>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-5 text-lg-right" />
                </div>
                {this.state.editAllocationForm &&
                  this.state.editAllocationForm.length <= 0 && (
                    <div className="row mt-3 report">
                      <div className="col-lg-4">
                        <div className="amount-show total-amount">
                          <h6>Total</h6>
                          <h5>
                            {this.state.userBaseCurrency}
                            {/* {((this.state.totalSelectesExpenseAmount * 100) / 100)} */}{" "}
                            {new Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }).format(
                              (this.state.totalSelectesExpenseAmount * 100) /
                              100
                            )}
                          </h5>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="amount-show total-itemised">
                          <h6>Allocated</h6>
                          <h5>
                            {this.state.userBaseCurrency}{" "}
                            {this.state.totalSelectesExpenseAmount > 0
                              ? new Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(
                                (this.state.allocatedAmounts * 100) / 100
                              )
                              : 0.0}
                            ({" "}
                            {this.state.totalSelectesExpenseAmount > 0
                              ? Math.floor(
                                ((this.state.allocatedAmounts * 100) /
                                  this.state.totalSelectesExpenseAmount) *
                                100
                              ) /
                              100 +
                              "%"
                              : "0%"}
                            )
                          </h5>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="amount-show total-pending">
                          <h6>Remaining</h6>
                          <h5>
                            {this.state.userBaseCurrency}{" "}
                            {this.state.totalAllocatedAmount > 0
                              ? new Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(
                                (((this.state.totalSelectesExpenseAmount *
                                  100) /
                                  100 -
                                  (this.state.totalAllocatedAmount * 100) /
                                  100) *
                                  100) /
                                100
                              )
                              : 0.0}{" "}
                            (
                            {this.state.totalAllocatedAmount
                              ? Math.floor(
                                (((Math.floor(
                                  this.state.totalSelectesExpenseAmount * 100
                                ) /
                                  100 -
                                  Math.floor(
                                    this.state.totalAllocatedAmount * 100
                                  ) /
                                  100) *
                                  100) /
                                  this.state.totalSelectesExpenseAmount) *
                                100
                              ) /
                              100 +
                              "%"
                              : "0%"}
                            )
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
              </form>
              <div className="table-responsive">
                {this.state.isLoading ? (
                  this.state.editAllocationForm &&
                    this.state.editAllocationForm.length <= 0 ? (
                      <ExpenseAllocationTable
                        {...this.state}
                        selectedIndex={this.state.selectedIndex}
                        allocatedIn={this.state.allocatedIn}
                        validator={this.validator}
                        onClickHandler={this.onClickHandler}
                        onChangeHandler={this.onChangeHandler}
                        onDatePickerHandler={this.onDatePickerHandler}
                        onCheckboxHandler={this.onCheckboxHandler}
                        onIntegerHandler={this.onIntegerHandler}
                        expenseAllocationForm={this.state.expenseAllocationForm}
                        onSelectedAllocationFieldHandler={
                          this.onSelectedAllocationFieldHandler
                        }
                        tableHead={this.state.tableHead}
                        userPermission={this.state.userPermission}
                        onConnectedListkHandler={this.onConnectedListkHandler}
                        onAmountPercentageChangeHandler={
                          this.onAmountPercentageChangeHandler
                        }
                        onLocationChangekHandler={this.onLocationChangekHandler}
                        loadOptions={this.loadOptions}
                        handleScroll={this.handleScroll}
                        handleSearchManage={this.handleSearchManage}
                      />
                    ) : (
                      <EditExpenseAllocationTable
                        {...this.state}
                        selectedIndex={this.state.selectedIndex}
                        allocatedIn={this.state.allocatedIn}
                        validator1={this.validator1}
                        onClickHandler={this.onClickHandler}
                        onChangeHandler={this.onChangeHandler}
                        onDatePickerHandler={this.onDatePickerHandler}
                        onCheckboxHandler={this.onCheckboxHandler}
                        onIntegerHandler={this.onIntegerHandler}
                        expenseAllocationForm={this.state.expenseAllocationForm}
                        onEditSelectedAllocationFieldHandler={
                          this.onEditSelectedAllocationFieldHandler
                        }
                        tableHead={this.state.tableHead}
                        userPermission={this.state.userPermission}
                        onConnectedListkHandler={this.onConnectedListkHandler}
                        editAllocationForm={this.state.editAllocationForm}
                        allocationEditIds={this.state.allocationEditIds}
                        onLocationChangekHandler={this.onLocationChangekHandler}
                        loadOptions={this.loadOptions}
                        handleScroll={this.handleScroll}
                        handleSearchManage={this.handleSearchManage}
                      />
                    )
                ) : (
                    <Loader />
                  )}
              </div>
            </div>
            {this.state.auditRuleValidationError &&
              this.state.auditRuleValidationError.map(
                (validationError, index) => (
                  <p className="text-danger" key={index}>
                    {validationError}
                  </p>
                )
              )}
            <div className="p-padding py-0">
              <div className="form-holder">
                <div className="btn-group-margin">
                  {!this.isSubmitClick ? (
                    <div className="btn-group-margin">
                      {this.state.editAllocationForm &&
                        this.state.editAllocationForm.length > 0 ? (
                          <div className="btn-group-margin">
                            <NavLink to
                              onClick={this.onUpdateExpenseSubmitHandler}
                              className="btn"
                            >
                              Update
                          </NavLink>
                            <button
                              onClick={this.onCancelClickHandler}
                              type="button"
                              className="btn"
                            >
                              Cancel
                          </button>
                          </div>
                        ) : (
                          <div className="btn-group-margin">
                            <NavLink to
                              onClick={this.onSubmitHandler}
                              className="btn"
                            >
                              Done
                          </NavLink>
                            <button
                              onClick={this.onCancelClickHandler}
                              type="button"
                              className="btn"
                            >
                              Cancel
                          </button>
                          </div>
                        )}
                    </div>
                  ) : (
                      <NavLink to onClick={this.onSubmitHandler} className="btn">
                        Please Wait...
                      </NavLink>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ExpenseAllocationPage;
