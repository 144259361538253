import React from 'react'

export default function TypeDropdown(props) {
  const {
    name,
    handleTypeClick

  } = props;
  return (
    <button type="button" onClick={e => handleTypeClick(name)} className="dropdown-item">{name} </button>
  )
}
