const columnNames = {
  LOCATION_NAME: "LOCATION_NAME",
  CURRENCY: "CURRENCY",
  AMOUNT: "AMOUNT",
  COMMENT_PARAMS: "COMMENT_PARAMS",
  PURPOSE: "PURPOSE",
  REQUESTED_DISBURSEMENT_DATE: "REQUESTED_DISBURSEMENT_DATE",
  EMPLOYEE_FIRST_NAME: "EMPLOYEE_FIRST_NAME",
  MIDDLE_INITIAL: "MIDDLE_INITIAL",
  MIDDLE_NAME: "MIDDLE_NAME",
  EMPLOYEE_LAST_NAME: "EMPLOYEE_LAST_NAME",
  GROUP_NAME: "GROUP_NAME",
  EMP_ID: "EMP_ID",
  REIMBURSEMENT_CURRENCY: "REIMBURSEMENT_CURRENCY",
  LEDGER_KEY: "LEDGER_KEY",
  CTRY_CODE: "CTRY_CODE",
  ORG_UNIT_1: "ORG_UNIT_1",
  ORG_UNIT_2: "ORG_UNIT_2",
  ORG_UNIT_3: "ORG_UNIT_3",
  ORG_UNIT_4: "MIDDLE_NAME",
  ORG_UNIT_5: "ORG_UNIT_5",
  ORG_UNIT_6: "ORG_UNIT_6",
  EMAIL_ADDRESS: "EMAIL_ADDRESS",
  VIRTUAL_CUUID: "VIRTUAL_CUUID",
  REPORT_NAME: "REPORT_NAME",
  REPORT_DATE: "REPORT_DATE",
  CUSTOM1: "CUSTOM1",
  CUSTOM2: "CUSTOM2",
  CUSTOM3: "CUSTOM3",
  CUSTOM4: "CUSTOM4",
  CUSTOM5: "CUSTOM5",
  CUSTOM6: "CUSTOM6",
  CUSTOM7: "CUSTOM7",
  CUSTOM8: "CUSTOM8",
  CUSTOM9: "CUSTOM9",
  CUSTOM10: "CUSTOM10",
  PAYMENT_TYPE: "PAYMENT_TYPE",
  TRANSACTION_DATE: "TRANSACTION_DATE",
  PERSONAL_EXPENSE: "PERSONAL_EXPENSE",
  TAX_TYPE: "TAX_TYPE",
  BUSINESS_DISTANCE: "BUSINESS_DISTANCE",
  VEHICLE_TYPE: "VEHICLE_TYPE",
  VENDOR_NAME: "VENDOR_NAME",
  RECEIPT_AVAILABLE: "RECEIPT_AVAILABLE",
  APPROVED_AMOUNT: "APPROVED_AMOUNT",
  VIRTUAL_ATTENDEES: "VIRTUAL_ATTENDEES",
  TRANSACTION_AMOUNT: "TRANSACTION_AMOUNT",
  DESCRIPTION: "DESCRIPTION",
  ARRIVAL_DATE: "ARRIVAL_DATE",
  ARRIVAL_TIME: "ARRIVAL_TIME",
  DEPARTURE_DATE: "DEPARTURE_DATE",
  DEPARTURE_TIME: "DEPARTURE_TIME",
  TO_LOCATION_DETAIL: "TO_LOCATION_DETAIL",
  PNR: "PNR",
  FROM_LOCATION_DETAIL: "FROM_LOCATION_DETAIL",
  CARD_VALIDITY: "CARD_VALIDITY",
  ATN_TYPE_KEY: "ATN_TYPE_KEY",
  ATTENDEE_NAME: "ATTENDEE_NAME",
  TITLE: "TITLE",
  ITINERARY_NAME: "ITINERARY_NAME",
  TRAVEL_DATE_FROM: "TRAVEL_DATE_FROM",
  TRAVEL_DATE_TO: "TRAVEL_DATE_TO",
  END_TIME: "END_TIME",
  START_TIME: "START_TIME",
  LAST_NAME: "LAST_NAME",
  CREATION_DATE: "CREATION_DATE",
  END_DATE_OF_CIRCULATION: "END_DATE_OF_CIRCULATION",
  ENERGY: "ENERGY",
  ENGINE_SIZE: "ENGINE_SIZE",
  COMP_FIRST_DATE_OF_CIRCULATION: "COMP_FIRST_DATE_OF_CIRCULATION",
  FIRST_DATE_OF_CIRCULATION: "FIRST_DATE_OF_CIRCULATION",
  IS_PREFERRED: "IS_PREFERRED",

  TAX_RATE: "TAX_RATE",
  TAX_TYPES: "TAX_TYPES",
  IGST: "IGST",
  CGST: "CGST",
  SGST_UTGST: "SGST_UTGST",

  ACCOMMODATION_TYPE:"ACCOMMODATION_TYPE"
};

export default columnNames;
