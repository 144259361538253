import React, { Component } from 'react';
import EmployeeRelateConfigPage from './employee-relate-config-html';
class EmployeeRelateConfig extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <EmployeeRelateConfigPage
                />
            </div>
        )
    }
}
export default EmployeeRelateConfig;