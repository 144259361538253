import React from 'react';
import { NavLink } from "react-router-dom";
const AddEmployeeRelateConfigPage = (props) => {
    return (
        <div>
            <div className="inner-header">
                <div className="shape-header">
                    <div className="left">R</div>
                    <div className="right">
                        <h6 className="heading">Add Employee Related Configuration</h6>
                        <p className="text">Has been the industry's standard dumm</p>
                    </div>
                </div>
            </div>
            <div className="p-padding">
                <div className="row">
                    <div className="col-md-5">
                        <form>
                            <div className="form-group custom">
                                <input type="text" placeholder="&nbsp;" className="form-control" />
                                <label>Configuration Name</label>
                            </div>
                            <div className="form-group custom labelup">
                                <label>Taxa Data on User Form</label>
                                <div className="dropdown">
                                    <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                        Read Only
                                            </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Save</button>
                                        <button type="button" className="dropdown-item">Lorem Ipsum</button>
                                        <button type="button" className="dropdown-item">Lorem Ipsum</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group custom labelup">
                                <label>Receipt Status Options</label>
                                <div className="dropdown">
                                    <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                        No Receipt/Receipt
                                            </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Save</button>
                                        <button type="button" className="dropdown-item">Lorem Ipsum</button>
                                        <button type="button" className="dropdown-item">Lorem Ipsum</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group custom labelup">
                                <label>Receipt Status Default Options</label>
                                <div className="dropdown">
                                    <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                        No Default
                                            </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Save</button>
                                        <button type="button" className="dropdown-item">Lorem Ipsum</button>
                                        <button type="button" className="dropdown-item">Lorem Ipsum</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                                <span>Only Calculate Domestic</span>
                            </div>
                            <div className="form-group">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                                <span>Reclaim Domestic Tax</span>
                            </div>
                            <div className="form-group custom labelup">
                                <label>Extraction factors</label>
                                <div className="dropdown">
                                    <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                        Not Used
                                            </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Save</button>
                                        <button type="button" className="dropdown-item">Lorem Ipsum</button>
                                        <button type="button" className="dropdown-item">Lorem Ipsum</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                                <span>Copy Company card's Location to Entry Applies to Organizations</span>
                            </div>
                            <div className="mb-4">
                                <button type="button" className="btn   btn-border-basic btn-block lg-padding">Tax Organizations for configurations</button>
                            </div>
                            <div className="btn-group-margin">
                                <button className="btn btn-border-success lg-padding">Save</button>
                                <button className="btn btn-border-basic lg-padding">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddEmployeeRelateConfigPage;