import React, { Component } from 'react';
import ExpenseTypeStep4Page  from './expense-types-step4-html';
class ExpenseTypeStep4 extends Component {
    constructor(props){
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    render() {
        return(
            <div>
                <ExpenseTypeStep4Page
                />
            </div>
        )
    }
}
export default ExpenseTypeStep4;