import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import {
    PolicyStep1Page, PolicyStep2Page, ExpenseTypePage,
    ExpenseCategoryPage,
    ExpenseTypeStep1Page, ExpenseTypeStep2Page, ExpenseType3Page, ExpenseTypeStep3Page, ExpenseTypeStep4Page,
    ExpenseTypeLimitsPage,ItemizationWizardPage
} from '../../index';
import ItemizationMainPage from './itemization'
import ExMainPage from './types/manage'
class ExpenseTypesMainPage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/administration/expense/ex') {
            this.props.history.push('/administration/expense/ex/types')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/administration/expense/ex') {
            this.props.history.push('/administration/expense/ex/types')
        }
    }
    render() {
        return (
            <div className="ExpenseTypeHomePage">
                <Switch>
                    <Route path="/administration/expense/ex/types" component={ExMainPage} />
                    <Route path="/administration/expense/ex/itemization" component={ItemizationMainPage} />
                    <Route path="/administration/expense/ex/expense-category" component={ExpenseCategoryPage} />
                    <Route path="/administration/expense/ex/expense-type-limits" component={ExpenseTypeLimitsPage} />
                </Switch>
            </div>
        )
    }
}
export default ExpenseTypesMainPage;