import React, { useState } from "react";
import ExpenseSettlementPdfPage from "../expense-settlement-pdf";
import { getValue } from "src/_components/common/lodsh";

const reportTopHeader = props => {
  const [selectedRejectionComment, setSelectedRejectionComment] = useState("")
  const handleCancelRejectModal = () => {
    setSelectedRejectionComment("")
  };
  const handleRejectModalClick = comments => {
    if (comments) {
      setSelectedRejectionComment(comments)
    }
  };
  return (
    <div className="form-holder py-2">
      <div className="row">
        <div className="col-md-5">
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-nothing dark-blue dropdown-toggle"
              data-toggle="dropdown"
            >
              Report header
            </button>

            <div className="dropdown-menu set-width text-center no-scroll resp-report-header">
              <div className="text-left flex-fix">
                {/* {(props.submitStatus === "Pending Submission" || (props.actionButtons.length > 0 && props.currentPageName === 'Approval')) &&( */}
                {props.submitStatus === "Pending Submission" && (
                  <button
                    type="button"
                    onClick={props.onClickEditReportHeader}
                    className="btn"
                  >
                    Edit Header
                  </button>
                )}
                <h6 className="pt-3">Details</h6>
              </div>
              <div className="table-responsive px-4">
                <table className="table mb-0 text-left ">
                  <tbody>
                    <tr>
                      <td className="border-0 text-uppercase  text-muted p-0">
                        Policy
                      </td>
                      <td className="border-0 text-black-50 p-0">
                        : &nbsp;&nbsp;&nbsp;{" "}
                        <span className="font-weight-bold text-default text-capitalize">
                          {getValue(props, "policyDetail.policyName", "") === "" ? getValue(props, "headerDetails.policyName", "") : getValue(props, "policyDetail.policyName", "")}
                        </span>
                      </td>
                    </tr>
                    {props.expenseHeader ? (
                      props.expenseHeader.map((detail, index) => {
                        // console.log(detail);

                        if (detail.activeStatus) {
                          return (
                            <tr key={index}>
                              <td className="border-0 text-uppercase  text-muted p-0">
                                {detail.labelName}
                              </td>

                              <td className="border-0 text-black-50 p-0">
                                : &nbsp;&nbsp;&nbsp;{" "}
                                <span className="font-weight-bold text-default text-capitalize">
                                  {
                                    detail.dataType === "connected_list" ? detail.fieldItemName : (
                                      detail.dataType !== "date"
                                        ? detail.fieldValue
                                          ? detail.fieldValue
                                          : "-"
                                        :
                                        detail.fieldValue !== "" &&
                                          detail.fieldValue !== undefined &&
                                          detail.fieldValue !== null
                                          ? detail.fieldValue
                                          : "")}
                                </span>
                              </td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                        <tr />
                      )}
                    <tr>
                      <td className="border-0 text-uppercase  text-muted p-0">
                        Status
                      </td>
                      <td className="border-0 text-black-50 p-0">
                        : &nbsp;&nbsp;&nbsp;{" "}
                        <span className="font-weight-bold text-default text-capitalize">
                          {props.submitStatus}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td className="border-0 text-uppercase  text-muted p-0">
                        Report No
                      </td>
                      <td className="border-0 text-black-50 p-0">
                        : &nbsp;&nbsp;&nbsp;{" "}
                        <span className="font-weight-bold text-default text-capitalize">
                          {props.reportId}
                        </span>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {props.requestId &&
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-nothing dropdown-toggle"
                data-toggle="dropdown"
              >
                {props.requestId && <i className="material-icons">flight</i>}
              </button>
              <div className="dropdown-menu set-width text-center no-scroll">
                <div className="table-responsive px-4">
                  <table className="table mb-0 text-left ">
                    <tbody>
                      <tr>
                        <td className="border-0 text-uppercase  text-muted p-0">
                          Report Number
                      </td>
                        <td className="border-0 text-black-50 p-0">
                          : &nbsp;&nbsp;&nbsp;{" "}
                          <span className="font-weight-bold text-default text-capitalize">
                            {props.reportId}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0 text-uppercase  text-muted p-0">
                          Associated to Trip
                      </td>
                        <td className="border-0 text-black-50 p-0">
                          : &nbsp;&nbsp;&nbsp;{" "}
                          <span className="font-weight-bold text-default text-capitalize">
                            {props.requestId}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          }


          {getValue(props.headerObj, "status", "") !== "Pending Approval" && props.CommentsFromApprover ? (<div
            className="cursor-pointer"
            onClick={e =>
              handleRejectModalClick(
                props.CommentsFromApprover && props.CommentsFromApprover.comments ? props.CommentsFromApprover.comments : ""
              )
            }
            data-target="#RejectStatusCommentModal"
            data-toggle="modal"
          >
            <button className="btn btn-nothing text-danger text-left">
              <i className="material-icons fs-14 align-middle">
                thumb_down
                          </i>{" "}
              {props.CommentsFromApprover && props.CommentsFromApprover.statusType ? props.CommentsFromApprover.statusType : ""}
            </button>
            <p className="text-danger fs-12 top__text w-100px">
              <i className="material-icons fs-18 mr-1">
                error
                          </i>
              {props.CommentsFromApprover && props.CommentsFromApprover.comments ? props.CommentsFromApprover.comments : ""}
            </p>
          </div>) : ""
          }
          {props.currentPageName === "ExpenseDetail" && props.submitStatus === "Verified" &&
            <>
              {/* <button className="btn hover-v"
                onClick={() => props.handleExpensePrint()}
              >
                <i className="material-icons">print</i>
                <span className="hover-name bottom">Print Expense Settlement</span>
              </button> */}
              {(props.enablePrintTicket) &&
                <ExpenseSettlementPdfPage
                  headerDetails={props.headerDetails}
                  expenseEntryList={props.expenseEntryList}
                />
              }
            </>
          }
        </div>
        {props.actionButtons.length >= 0 &&
          props.currentPageName === "Approval" ? (
            <div className="col-md-7 text-md-right">
              <div className="btn-group-margin">
                {props.actionButtons.length > 0 ? (
                  props.actionButtons.map(action => (
                    <button
                      key={action.key}
                      onClick={() =>
                        props.onExpenseApprovalSelectHandler(
                          action.key,
                          action.button
                        )
                      }
                      className={
                        action.key === "APPROVE"
                          ? "btn btn-approve mb-2"
                          : action.key === "SEND_BACK_EMPLOYEE"
                            ? "btn btn-sendback mb-2"
                            : action.key === "REJECT"
                              ? "btn btn-reject mb-2"
                              : "btn btn-grey1 mb-2 mr-2"
                      }
                      data-toggle="modal"
                      data-target="#submitModalRequest"
                    >
                      {action.key === "APPROVE" ? (
                        <i className="material-icons">check_circle_outline </i>
                      ) : action.key === "SEND_BACK_EMPLOYEE" ? (
                        <i className="material-icons">arrow_back</i>
                      ) : action.key === "REJECT" ? (
                        <i className="material-icons">not_interested</i>
                      ) : (
                              <i className="material-icons">add</i>
                            )}{" "}
                      {action.button}
                    </button>
                  ))
                ) : (
                    <button className="btn btn-nothing pt-2 my-1">
                      {props.submitStatus}
                    </button>
                  )}
              </div>
            </div>
          ) : props.currentPageName !== "financeAdmin" ? (
            <div className="col-md-7 text-md-right">
              {!props.isSubmitClick
                ? props.totoalExpense >= 0 && (
                  <div className="btn-group-margin">
                    {(props.submitStatus === "Pending Approval" ||
                      props.submitStatus !== "Approved") &&
                      props.submitStatus !== "Verified" &&
                      props.submitStatus !== "Pending Submission" &&
                      props.submitStatus !== "Reject" && props.submitStatus !== '' && props.submitStatus !== null ? (
                        <button
                          className="btn btn-sendback"
                          data-toggle="modal"
                          data-target="#recallExpenseEntry"
                        >
                          <i className="material-icons">arrow_back </i>
                          Recall
                        </button>
                      ) : (
                        <>
                          {(props.submitStatus === "Reject") ?
                            <button className="btn btn-nothing text-danger pt-2 my-1 hover-v">
                              <i className="material-icons fs-12">thumb_down</i>{" "}
                              {props.submitStatus}
                              <span className="hover-name right-bottom with-br-big">{props.rejectExpenseMessage}</span>
                            </button>
                            : (props.submitStatus === "Approved") ?
                              <button className="btn btn-nothing text-success pt-2 my-1">
                                <i className="material-icons fs-12">thumb_up</i>{" "}
                                {props.submitStatus}
                              </button>
                              : <span className="mr-2 align-middle d-block d-md-inline mb-2 mb-md-0">{props.submitStatus}</span>
                          }
                        </>
                      )}
                    {props.submitStatus === "Pending Submission" && (
                      <button
                        disabled={
                          (
                            props.validationStatusArray
                            // &&
                            // !props.totoalExpense > 0
                          )
                        }
                        data-toggle="modal"
                        data-target="#submitExpenseEntry"
                        type="button"
                        className="btn"
                      >
                        Submit
                      </button>
                    )}
                    {props.submitStatus === "Pending Submission" &&
                      <button
                        className="btn"
                        data-toggle="modal"
                        data-target="#deleteReportHeader"
                        type="button"
                      >
                        Delete
                    </button>
                    }
                  </div>
                )
                : props.totoalExpense > 0 && (
                  <div className="btn-group-margin">
                    <button className="btn btn-nothing pt-2 my-1">
                      Please Wait...
                    </button>
                  </div>
                )}
            </div>
          ) : (
              <div className="col-md-7 text-md-right">
                {!props.isSubmitClick && props.totoalExpense > 0 ? (
                  <div className="btn-group-margin">
                    {props.submitStatus !== "Pending Submission" &&
                      props.submitStatus !== "Verified" ? (
                        <>
                          <button
                            className="btn btn-approve"
                            data-toggle="modal"
                            data-target="#submitExpenseEntry"
                          >
                            <i className="material-icons">check_circle_outline </i>{" "}
                            Verify
                    </button>

                          <button
                            className="btn btn-sendback"
                            data-toggle="modal"
                            data-target="#recallExpenseEntry"
                          >
                            <i className="material-icons">arrow_back </i> Send Back to
                            Employee
                    </button>
                        </>
                      ) : (
                        <button className="btn btn-nothing pt-2 my-1">
                          {props.rejectExpenseMessage} {props.submitStatus}
                        </button>
                      )}
                    {props.submitStatus === "Pending Submission" && (
                      <button
                        disabled={
                          (
                            // !props.validationStatusArray.includes(true) === true &&
                            props.validationStatusArray
                            // &&
                            // !props.totoalExpense > 0
                          )
                        }
                        data-toggle="modal"
                        data-target="#submitExpenseEntry"
                        type="button"
                        className="btn"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                ) : (
                    props.totoalExpense > 0 && (
                      <div className="btn-group-margin">
                        <button className="btn btn-nothing pt-2 my-1">
                          Please Wait...
                  </button>
                      </div>
                    )
                  )}
              </div>
            )}
        <div
          className="modal fade"
          id="RejectStatusCommentModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="RejectStatusCommentModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="RejectStatusCommentModalLabel">
                  Approver Comment
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <p>{selectedRejectionComment}</p>
              </div>
              <div className="modal-last btn-group-margin pm-30 pt-0">
                <button
                  type="button"
                  onClick={handleCancelRejectModal}
                  className="btn close"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default reportTopHeader;
