import React, { Component } from "react";
import AddEditTemplatePage from "./add-edit-template-html";
import { companyEmailServices } from "../../../../../../_services";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { withRouter } from 'react-router-dom';

class AddEditTemplate extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.companyId = localStorage["440a28"];
  }

  state = {
    name: "",
    subject: "",
    copyToEmail: "",
    content: "",
    isEditable: false,
    loading: false,
    serviceModuleId: "",
    notificationBoxContent: ""
  };

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.getListAll();
      this.setState({ isEditable: true, serviceModuleId: this.props.match.params.serviceModuleId });
    }
  }

  getListAll = async () => {
    var res = await companyEmailServices.getDetailEmailTemplate(
      this.props.match.params.id,
      this.companyId,
      this.serviceModuleId
    );
    if (res) {
      this.setState({
        name: res.result.name,
        subject: res.result.subject,
        copyToEmail: res.result.sendTo.toString(),
        content: res.result.content,
        notificationBoxContent: res.result.notificationContent
      });
    }
  };

  addEditTemplate = async e => {
    e.preventDefault();
    let isEmailIsValid = this.checkingCCEmailsValidation(this.state.copyToEmail);
    if (isEmailIsValid !== true) {
      toast.error("Invalid email")
      return false;
    }

    let sendTo = this.state.copyToEmail
    if (typeof (this.state.copyToEmail) !== "String") {
      sendTo = sendTo.toString()
    }
    this.setState({ loading: true });
    let data = {
      companyId: this.companyId,
      serviceModuleId: this.state.serviceModuleId,
      name: this.state.name,
      subject: this.state.subject,
      sendTo: sendTo,
      content: this.state.content,
      notificationContent: this.state.notificationBoxContent
    }
    if (this.validator.allValid()) {
      if (this.state.isEditable) {
        var res = await companyEmailServices.updateDetailEmailTemplate(
          this.props.match.params.id,
          data
        ).catch(e => {
          this.setState({ loading: false });
        });
        if (res) {
          toast.success(res.message);
          this.setState({ loading: false });
          this.props.history.push(`/administration/company/email/travel/${this.props.match.params.serviceModuleId}`)
        }
      } else {
        var res = await companyEmailServices.postDetailEmailTemplate(data).catch(e => {
          this.setState({ loading: false });
        })
        if (res) {
          this.setState({ loading: false });
          toast.success(res.message);
          this.props.history.push(`/administration/company/email/travel/${this.props.match.params.serviceModuleId}`)
        }
      }
    } else {
      this.validator.showMessages();
      this.setState({ loading: false });
      this.forceUpdate();
    }
  };

  inputChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  emailContentHandler = data => {
    this.setState({ content: data });
  };

  notificationBoxContenthandler = data => {
    this.setState({
      notificationBoxContent: data.target.value
    })
  }

  inputChangeHandlerForCCEmails = (emails) => {
    // console.log(emails.target.value, 'value')
    const listOfEmails = emails.target.value;
    this.setState({
      copyToEmail: listOfEmails
    })
  }

  checkingCCEmailsValidation = (CCEmails) => {
    const regexForEmails = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    let listOfCCEmails = CCEmails.split(",").map(item => item.trim());
    if (listOfCCEmails.length === 0) {
      return false;
    }
    for (var item = 0; item < listOfCCEmails.length; item++) {
      if (!regexForEmails.test(listOfCCEmails[item])) {
        return false;
      }
    }
    return true;
  };

  render() {
    return (
      <AddEditTemplatePage
        listUser={this.userList}
        notificationBoxContenthandler={this.notificationBoxContenthandler}
        inputChangeHandler={this.inputChangeHandler}
        submitHandler={this.addEditTemplate}
        emailContentHandler={this.emailContentHandler}
        state={this.state}
        validator={this.validator}
        id={this.props.match.params.serviceModuleId}
        inputChangeHandlerForCCEmails={this.inputChangeHandlerForCCEmails}
      />
    );
  }
}
export default withRouter(AddEditTemplate);