import React, { Component } from 'react';
import { Route } from "react-router-dom";
import RequestPolicyPage from "./request-policies/request-policies";
import EditPolicyStep1Page from './edit-policy-step1/edit-policy-step1'
import PolicyStep1 from "./policy-step1/policy-step1";
import { inject } from "mobx-react";
@inject('menuStore')
class RequestHomePage extends Component {
    constructor(props) {
        super(props)
        this.props.menuStore.menuItems && this.props.menuStore.menuItems.forEach(list => {
            if (this.props.menuStore.parentId === list.id) {
                if (props.location.pathname === list.children[0].frontEndUrl) {
                    this.props.history.push(list.children[0].children[0].frontEndUrl)
                } else {
                    this.props.history.push(props.location.pathname)
                }
            }
        })
    }
    render() {
        return (
            <div className="">
                <Route
                    path="/administration/travel/request/policy"
                    component={RequestPolicyPage}
                />
                <Route
                    path="/administration/travel/request/policy-step1"
                    component={PolicyStep1}
                />
                <Route
                    path="/administration/travel/request/edit-policy/:id"
                    component={EditPolicyStep1Page}
                />
            </div>
        )
    }
}
export default RequestHomePage;