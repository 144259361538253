import React, {Component} from "react";
import { NavLink } from "react-router-dom";
import { importExportService } from "src/_services";
import Loader from "src/_components/common/Loader";
import { getFormattedDateFromIsoString } from "src/utils/util";
import {Link} from 'react-router-dom';
import Pagination from 'react-js-pagination';
import Select from 'react-select';

class  HistoryLogsPage  extends Component {

  state = {
    logs: [],
    count: 0,
    isLoading: false,
    limit: 10,
    activePage: 1,
    sort: "",
    itemsPerPage: [
      {label: 10, value: 10},
      {label: 20, value: 20},
      {label:30, value: 30}
    ],
    selectedLimit: {label: 10, value: 10},
  }
  componentDidMount(){
    this.getHistory();
  }



  getHistory = async (limit=10, activePage=1, sort="desc") => {
    this.setState({isLoading: true});
    let response = await importExportService.getHistory(limit, activePage, sort);
    this.setState({isLoading: false});
      if(response  && response.result && response.result.length){
        this.setState({
          logs: response.result,
          count: response.count
        });
      }
  }

  // handleFileDownload = (path) => {
  //   window.location.href = path;
  // }

  handlePageChange = async (activePage) => {
    await this.setState({activePage});
    this.getHistory(this.state.limit, this.state.activePage);
  }

  handleItemsPerPageChange = async (selectedValue) => {
    await this.setState({
      limit : selectedValue.value,
      selectedLimit: selectedValue
    });
    this.getHistory(this.state.limit, this.state.activePage);
  }

  render(){

  return (
    <div>
      <div className="">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Import/Export</h6>
              <p className="text">
                Use Import/Export section to Import or Export reports to CSV or XLS based on filters.
              </p>
            </div>
          </div>
        </div>
        <div className="p-padding importdata">
          <div className="bg-white">
            <div className="row">
              <div className="col-sm-3 import-left-items pr-0">
                <ul>
                  <li><NavLink to="/administration/company/import">Import</NavLink></li>
                  <li><NavLink to="/administration/company/export">Export</NavLink></li>
                  <li className="active"><NavLink to="#">History Logs</NavLink></li>
                </ul>
              </div>
              <div className="col-sm-9 pl-0">
                <div className="py-padding history-log">
                  <h6 className="heading">Import/Export Logs</h6>
                  <div className="table-container m-0">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Modified By</th>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Total</th>
                            <th>Inserted</th>
                            <th>Updated</th>
                            <th>Failed</th>
                            <th>Error Logs</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.isLoading && (
                              <tr>
                                <td colSpan="5">
                                <Loader />
                                </td>
                              </tr>
                            )
                          }
                          {
                            !this.state.isLoading && this.state.logs.length === 0 &&
                            <tr> <td>No Logs Found</td></tr>
                          }
                          {
                            !this.state.isLoading && this.state.logs.length > 0 &&
                            this.state.logs.map(log => {
                              return (
                                <tr key={log.id}>
                                  <td><span>{log.fullName}</span></td>
                                  <td>{getFormattedDateFromIsoString(log.createdAt)}</td>
                                  <td>{log.importType}</td>
                                  <td>
                                    {
                                      log?.totalRows?.count > 0
                                        ? (
                                          <>
                                        <span> {log?.totalRows?.count }
                                        </span>
                                        <a href={log?.totalRows?.path} className="text-primary" > Download</a>
                                        </>
                                        )
                                        : log?.totalRows?.count

                                    }
                                    </td>
                                    <td>
                                    {
                                      log?.insertedRows?.count > 0
                                        ? (<>
                                          <span className="text-success"> {log?.insertedRows?.count }</span>
                                          <a className="text-primary" href={log?.insertedRows?.path} download> Download</a>
                                          </>
                                        )
                                        : log?.insertedRows?.count

                                    }
                                    </td>
                                    <td>
                                    {
                                      log?.updatedRows?.count > 0
                                        ? (
                                          <>
                                        <span className="text-primary"> {log?.updatedRows?.count }</span>
                                        <a className="text-primary" href={log?.updatedRows?.path} download> Download</a>
                                        </>
                                        )
                                        : log?.updatedRows?.count

                                    }

                                  </td>
                                  <td>
                                    {
                                      log?.failedRows?.count > 0
                                        ? (
                                          <>
                                        <span className="text-danger"> {log?.failedRows?.count }</span>
                                        <a className="text-primary" href={log?.failedRows?.path} download> Download</a>
                                        </>
                                        )
                                        : log?.failedRows?.count

                                    }

                                  </td>
                                  <td>
                                    {
                                      log?.errorLogs?.count > 0
                                        ? (
                                          <>
                                        <span className="text-warning"> {log?.errorLogs?.count }</span>
                                        <a className="text-primary" href={log?.errorLogs?.path} download> Download</a>
                                        </>
                                        )
                                        : log?.errorLogs?.count

                                    }

                                  </td>
                                </tr>
                              )
                            })
                          }


                        </tbody>
                      </table>
                      { !this.state.isLoading && this.state.logs.length > 0 &&
                        <>
                        <div className="col-sm-3">
                          <Select
                              options={this.state.itemsPerPage}
                              value={this.state.selectedLimit}
                              onChange={this.handleItemsPerPageChange}
                              />
                        </div>
                        <div className="col-sm-2">

                          <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.limit}
                          totalItemsCount={this.state.count}
                          pageRangeDisplayed={3}
                          onChange={this.handlePageChange}
                          />
                        </div>
                        </>
                      }

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
};
export default HistoryLogsPage;
