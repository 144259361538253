import React, { Component } from "react";
import ModifyFormsFieldsPage2 from "./modify-form-fields2-html";
import { companyAdmin } from "../../../../../../_services/companyAdmin";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { alertConstants } from "../../../../../../_constants";

class ModifyFormsFields2 extends Component {
  state = {
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,
    sort: "asc",
    fields: [],
    companyId: localStorage["440a28"],
    selectedItemId: [],
    selectedField: [],
    dataTypes: [
      { id: 1, dataType: "text" },
      { id: 2, dataType: "integer" },
      { id: 3, dataType: "connected_list" },
      { id: 4, dataType: "file" },
      { id: 5, dataType: "amount" },
      { id: 6, dataType: "boolean" },
      { id: 7, dataType: "checkbox" },
      { id: 8, dataType: "date" },
      { id: 9, dataType: "email" }
    ],
    displayTypes: [
      { id: 1, displayType: "text" },
      { id: 2, displayType: "number" },
      { id: 3, displayType: "email" },
      { id: 4, displayType: "fileUpload" },
      { id: 5, displayType: "profileUpload" },
      { id: 6, displayType: "select" },
      { id: 7, displayType: "checkbox" },
      { id: 8, displayType: "amount" },
      { id: 9, displayType: "boolean" },
      { id: 10, displayType: "date" }
    ],
    permissionCodes: [
      { id: "0", permissionName: "Hide" },
      { id: 1, permissionName: "Read Only" },
      { id: 2, permissionName: "Write" }
    ],
    //-------- create fields--------
    serviceModuleId: localStorage.getItem("serviceModuleId"),
    formHeaderId: sessionStorage.getItem("fieldsFormHeaderId"),
    redirectionPath: sessionStorage.getItem("redirectModify"),

    fieldName: "",
    labelName: "",
    columnName: "",
    tableName: "",
    //multilevel
    isMultiLevel:"",
    mlFlields:[],
    parentMLFields:[],
    parentOptionsDisplay:[],
    parentSelectId:'',
    //multilevel-end
    dataType: "",
    displayType: "",
    maxLength: "",
    isAdmin: true,
    isMandatory: null,
    customField: null,
    isFormField: null,

    isPiiEncrpt: null, //is pi encrypt and ispinoencryt is not same, ie, if it is true, other is false,
    isPiiNotEncrypt: null,
    isNotPii: null, // also is this will be false if either of the above is true

    results: [],
    permission: "",

    roles: [],
    edit: false,
    employeeRole: '',
    approverRole: '',
    reviewerRole: '',
    allRoles: [],
    fieldRolePermissions: [],

    // * CopyDown
    copyDownStatus:'',
    isCopyDown:false,
    listFormHeaders:[],
    selectHeader: '',
    listFormFields:[],
    selectField: '',

    // *default value
    isDefaultValue: false,
    defaultValue: null,
    selectPlaceholder: ''



  };
  validator = new SimpleReactValidator();

  // ------------------Handle Form -------------------------------------------

  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  // ------------------------------------------------------------------

  // ------------------Handle checked -------------------------------------------

  onisMandatory = () =>
    this.setState({ ...this.state, isMandatory: !this.state.isMandatory });
  onisPiiEncrypt = () => {
    this.setState({ ...this.state, isPiiEncrpt: !this.state.isPiiEncrpt });
  };
  onisPiiNotEncrypt = () =>
    this.setState({
      ...this.state,
      isPiiNotEncrypt: !this.state.isPiiNotEncrypt
    });
  onisNotPii = () =>
    this.setState({ ...this.state, isNotPii: !this.state.isNotPii });

  // onisNotPii = () => this.setState({...this.state, isNotPii:!this.state.isNotPii})
  oncustomField = () => this.setState({ customField: !this.state.customField });
  onisFormField = () =>
    this.setState({ ...this.state, isFormField: !this.state.isFormField });

  // ------------------------------------------------------------------------
  // ---------------- creating forms --------------------------

  handleSubmit = async event => {
    event.preventDefault();
    let rolePermissionsData = {}
    if (this.validator.allValid()) {
      if(this.state.fieldRolePermissions.length){
        rolePermissionsData = {
          isCreate: false,
          rolePermission: this.state.allRoles.map(role => {
            return {
              roleId: role.roleId,
              permissionId: role.permissionId,
              rolePermissionFieldId: role.rolePermissionFieldId

            }
          })
        }
      }else{
        rolePermissionsData = {
          isCreate: true,
          rolePermission: this.state.allRoles.map(role => {
            return {
              roleId: role.roleId,
              permissionId: role.permissionId
            }
          })
        }
      }
      var mlFieldsList = []
      if(this.state.mlFlields){
        await this.state.mlFlields.forEach(item => {
          mlFieldsList.push(item.conditionValue)
        })
      }
      let data = {
        ...rolePermissionsData,
        companyId: this.state.companyId,
        fieldName: this.state.fieldName,
        labelName: this.state.labelName,
        columnName: this.state.columnName,
        tableName: this.state.tableName,
        dataType: this.state.dataType,
        displayType: this.state.displayType,
        maxLength: this.state.maxLength,
        isMandatory: this.state.isMandatory,
        customField: this.state.customField,
        isFormField: this.state.isFormField,
        isPiiEncrpt: this.state.isPiiEncrpt,
        isPiiNotEncrypt: this.state.isPiiNotEncrypt,
        isNotPii: this.state.isNotPii,
        // * for default values
        isDefaultValue: this.state.isDefaultValue,
        defaultValue: this.state.defaultValue,
        isCopyDown:this.state.isCopyDown,
        copyDownDetails:{
          formHeaderId:this.state.selectHeader?.id,
          columnName:this.state.selectField?.columnName
        },
        mlFields:mlFieldsList
      };
      // console.log(data);
      if (this.props.match.params.id) {
        // console.log(data);
        let resp = await companyAdmin.modifyFields(this.props.match.params.id, data)
        if(resp){
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
          this.redirect();
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  // ---------------------------------------------------

  // ---------------- select datatype -------------------

  handleDataType = dataType => {
    // if (this.state.dataType !== dataType) {

    // } else {
    //   toast.error(alertConstants.ERROR);
    // }
    this.setState({
      dataType: dataType
    });
  };

  // ---------------- ------------------- -------------------
  // ---------------- select display -------------------

  handleDisplayType = (id, displayType) => {
    // if (this.state.displayType !== displayType) {
    //   this.setState({
    //     ...this.state,
    //     displayType
    //   });
    // } else {
    //   toast.error(alertConstants.ERROR);
    // }
    this.setState({
      ...this.state,
      displayType
    });
  };

  // * Default Check Toggle
  defaultValueCheckHandler = () => {
    let newValue = !this.state.isDefaultValue;
    if(newValue === false){
      this.setState({defaultValue: null})
    }
    this.setState({
      isDefaultValue: newValue
    })
  }

  // * default value change handler
  defaultValueChangeHandler = (e, type) => {
    if(type === 'text' || type === 'email'){
      this.setState({
        defaultValue: e.target.value
      });
    }
  }

  // * default select change handler
  defaultSelectHandler = (selectedValue) => {
    this.setState({
      defaultValue: selectedValue.id,
      selectPlaceholder: selectedValue.itemName
    })
  }

  // ---------------- ------------------- -------------------

  // ---------------- ------------------- -------------------
  // ---------------- get roles------------------- -------------------
  getRoles = () => {
    companyAdmin.getRoles().then(res => {
      this.setState({
        roles: res.result
      });
    });
  };
  // ------------Cancel------------------------------
  redirect = () => {
    if (this.state.redirectionPath === "fields") {
      this.props.history.push(
        "/administration/company/admin-forms/fields/list"
      );
    } else {
      this.props.history.push(
        "/administration/company/admin-forms/forms-fields/list"
      );
    }
  };

  getFieldDetails = async () => {
    companyAdmin
      .getFieldsDetails(this.props.match.params.id, this.state.companyId)
      .then(async (res) => {
        // * if the response has default value, get the matching dropdown value associated with the id
        if(res.result && res.result.defaultValue){
          this.getSelectDropdownValue(res.result.defaultValue, res.result.connectedListData);
        }

       await this.setState({
          serviceModuleId: res.result.serviceModuleId,
          formHeaderId: res.result.formHeaderId,
          fieldName: res.result.fieldName,
          labelName: res.result.labelName,
          columnName: res.result.columnName,
          //multi-level
          isMultiLevel: res.result.isMultiLevel,
          mlFlields: res.result.mlFlields,
          //muti-level-end
          tableName: res.result.tableName,
          dataType: res.result.dataType,
          displayType: res.result.displayType,
          maxLength: res.result.maxLength,
          isMandatory: res.result.isMandatory,
          customField: res.result.customField,
          isFormField: res.result.isFormField,
          isPiiEncrpt: res.result.isPiiEncrpt,
          isPiiNotEncrypt: res.result.isPiiNotEncrypt,
          isNotPii: res.result.isNotPii,
          fieldRolePermissions: res.result.fieldRolePermissions,
          isDefaultValue: res.result.isDefaultValue,
          defaultValue: res.result.defaultValue,
          connectedListData: res.result.connectedListData,
          isCopyDown:res.result.isCopyDown,
          formHeaderId:res.result?.copyDownDetails?.formHeaderId,
          columnName:res.result?.copyDownDetails?.columnName
        });
        if(this.state.isCopyDown){
          await this.getFormHeaderByModify();
          let header = this.state.listFormHeaders.find(item=> item.id === this.state.formHeaderId)
          this.selectHeader(header)
          await this.getCopyDownFields(header.id);
          let field = this.state.listFormFields.find(item => item.columnName === res.result?.copyDownDetails?.columnName)
          this.selectField(field)
          // await this.
          this.setState({
            copyDownStatus:"CopyDown",
          })
        }
        this.setAllRolesFromFieldRolePermissions(this.state.fieldRolePermissions);
        if(res.result.isMultiLevel){
          this.getMultiLevelConnectedList();
        }
        if(this.state.mlFlields){
          this.state.mlFlields.forEach(list=>{
            // console.log(list.connectedListData.listManagementData)
            var placeholder = list.connectedListData && list.connectedListData.listManagementData.find(item => list.defaultValue === item.id)
            if(list.defaultValue &&  list.fieldLevel === 1){
              if(placeholder){
                  this.selectMultilevel({value:placeholder.id,label:placeholder.itemName},list)
              }
            }else if(list.defaultValue && list.fieldLevel > 0){
              // var findItem = this.state.mlFlields.find(item => item.id === list.multiSelectParentField)
              this.onMultiLevelSelectHandler({value:list.defaultValue}, list.fieldName + list.fieldLevel, list.id, list.multiSelectParentField, list.fieldLevel, list.fieldName)
            }
          })
        }
      })
  };

  getSelectDropdownValue = (defaultValue, connectedListData) => {
    if(connectedListData?.listManagementData){
      let matchedListItem = connectedListData?.listManagementData.find(item => item.id == defaultValue);
      if(matchedListItem){
        this.setState({
          selectPlaceholder: matchedListItem.itemName
        })
      }
    }
  }

  setAllRolesFromFieldRolePermissions = (fieldRolePermissions) => {
    let allRoles = [...this.state.allRoles];
    fieldRolePermissions.forEach(rolePermission => {
      let permisionNameIndex = this.state.permissionCodes.findIndex(code => code.id == rolePermission.permissionId);
      let permissionName = this.state.permissionCodes[permisionNameIndex]['permissionName'];
      allRoles.push({
        rolePermissionFieldId: rolePermission.id,
        roleId: rolePermission.roleId,
        permissionId: rolePermission.permissionId,
        permissionName: permissionName

      })
    })
    this.setState({allRoles});
  }



  handleRoleChange = (e,roleItem) => {
    let allRoles = [...this.state.allRoles];
    if(this.state.fieldRolePermissions.length){
      let iSRoleItemPresent = allRoles.findIndex(role => roleItem.id == role.roleId);
      let [deletedItem] = allRoles.splice(iSRoleItemPresent,1, {
        roleId: roleItem.id,
        permissionId: e.id,
        permissionName: e.permissionName,

      });
      allRoles[iSRoleItemPresent].rolePermissionFieldId= deletedItem.rolePermissionFieldId;

    }else{

      let iSRoleItemPresent = allRoles.findIndex(role => roleItem.id == role.roleId);

        if(iSRoleItemPresent === -1){
          allRoles.push({
          roleId: roleItem.id,
          permissionId: e.id,
          permissionName: e.permissionName

        })
      }else{

        allRoles.splice(iSRoleItemPresent,1, {
          roleId: roleItem.id,
          permissionId: e.id,
          permissionName: e.permissionName
        });

    }
  }

    this.setState({allRoles}, () => console.log(this.state.allRoles));
  }
//-------------------- CopyDown Status Check ------------------------->

  handleCopyDownChange = (e) => {
    this.setState({
      copyDownStatus : e.label,
    });
    if(e.label === "CopyDown"){
      this.getFormHeaderByModify();
      this.setState({
        isCopyDown :true,
      });
    }
    else if(e.label === "None"){
      this.setState({
        listFormHeaders:[],
        selectHeader: '',
        listFormFields:[],
        selectField: '',
        isCopyDown :false,
      });
    }
  }

  getFormHeaderByModify = async () => {
  let resp = await  companyAdmin.getFormHeaderByModify();
    this.setState({
      listFormHeaders: resp.result
    });
  }

  selectHeader = (e) =>{
    this.setState({
      selectHeader: e,
      selectField:'',
      columnName:'',
    });
    this.getCopyDownFields(e.id);
  }

  getCopyDownFields = async (headerId) =>{
    let fieldId = this.props.match.params.id;
    let companyId = this.state.companyId;
    let resp = await companyAdmin.getCopyDownFields(fieldId,companyId,headerId);
    this.setState({
      listFormFields: resp.result,
    });
  }
  selectField = (e) =>{
    this.setState({
      selectField: e,
    });
  }

  // ------------------------------ End of CopyDown ------------------------------------//


  //------------------------------ MultiSelect  ---------------------------------------//
  getMultiLevelConnectedList =() => {
    let mlFields = this.state.mlFlields;
    if(mlFields !== undefined || mlFields !==[]  ){
      mlFields.forEach(item=>{
        if(item.fieldLevel === 1){
          this.setState({
            parentMLFields: item.connectedListData.listManagementData
          })
        }
        else{
          this.setState({
            [item.fieldName]: '',
            [item.columnName+item.fieldLevel]:[]
          })
        }
      });
      this.createMultiSelectParentOptions();
    }
  }

  createMultiSelectParentOptions = () =>{
   let parentMLFields = this.state.parentMLFields;
   let parentOptions = [];
    parentMLFields.map(item=>{
      return (parentOptions.push({value: item.id, label: item.itemName}))
    })
    this.setState({
      parentOptionsDisplay: parentOptions
    })
  }

  selectMultilevel = async(e,item)=>{
    let mlFields = this.state.mlFlields
    let newFieldLevel = item.fieldLevel + 1;
    let resp = await companyAdmin.getChildrenMultilevel(this.state.companyId ,e.value);
    let myOption = [];
    if(resp){
      resp.result.map(item=>{
        return (
          myOption.push({value: item.id, label: item.itemName})
        )
      })
    }
    mlFields.forEach(el=>{
      if(el.fieldLevel === newFieldLevel){
        this.setState({
          [el.columnName+newFieldLevel]:  myOption,
          [el.fieldName] :[]
        })
      }
      if(el.id === item.id){
        el.conditionValue = {
          fieldId:item.id,
          defaultValue:e.value
        }
      }
    })
  }
  onMultiLevelSelectHandler = async (e,combineName,id, multiSelectParentField, fieldLevel, fieldNameEl) =>{
    // console.log(e)
    this.setState({[fieldNameEl]:[e]})
    let mlFields = [...this.state.mlFlields];
    let newFieldLevel = fieldLevel + 1;
    let resp = await companyAdmin.getChildrenMultilevel(this.state.companyId ,e.value);
    let myOption = [];
    if(resp){
      resp.result.map(item=>{
        return (
          myOption.push({value: item.id, label: item.itemName})
        )
      })
    }
    mlFields.forEach(el=>{
      if(el.fieldLevel === newFieldLevel){
        this.setState({
        [el.columnName+newFieldLevel]:  myOption,
        [el.fieldName] :[]
        })
      }
      if(el.id === id){
        el.conditionValue = {
          fieldId:id,
          defaultValue:e.value
        }
      }
    })
  }

  //-------------------------- end MultiSelect ---------------------------------------//
  componentDidMount() {
    this.getFieldDetails();
    this.getRoles();
    if(this.props.match.params.id !== undefined){
      this.setState({
        edit: true
      })
    }
  }
  render() {
    return (
      <div>
        <ModifyFormsFieldsPage2
          modifyFields={this.state}
          formNameHandler={this.formNameHandler}
          handleSubmit={this.handleSubmit}
          onisMandatory={this.onisMandatory}
          oncustomField={this.oncustomField}
          onisFormField={this.onisFormField}
          onisPiiEncrypt={this.onisPiiEncrypt}
          onisPiiNotEncrypt={this.onisPiiNotEncrypt}
          onisNotPii={this.onisNotPii}
          handleDataType={this.handleDataType}
          handleDisplayType={this.handleDisplayType}
          redirect={this.redirect}
          validator={this.validator}
          handleRoleChange={this.handleRoleChange}
          defaultValueCheckHandler={this.defaultValueCheckHandler}
          defaultValueChangeHandler={this.defaultValueChangeHandler}
          defaultSelectHandler={this.defaultSelectHandler}
          handleCopyDownChange={this.handleCopyDownChange}
          selectHeader={this.selectHeader}
          selectField={this.selectField}
          selectMultilevel={this.selectMultilevel}
          onMultiLevelSelectHandler={this.onMultiLevelSelectHandler}
         // {...this.state}
        />
      </div>
    );
  }
}
export default ModifyFormsFields2;
