import React, { Component } from 'react';
import BookingHomeCardPage from './home-cards';
import BookingMenuPage from '../components/booking-menu';

import $ from 'jquery';
import BasicProfile from './BasicProfile';
import SimpleReactValidator from 'simple-react-validator';
import { personalInfoService } from 'src/_services/personalInfoService';
import { toast } from 'react-toastify';
import { alertConstants } from 'src/_constants';
import moment from 'moment';

class BookingHomePage extends Component {
  state = {
  titleOptions : [
    {
    label: "Mr",
    value: 1
    },
    {
    label: "Ms",
    value: 2
    },{
    label: 'Mrs',
    value: 3
    }
  ],
  genderOptions: [
    {
    label: "Male",
    value: 1
    },
    {
    label: "Female",
    value: 2
    },
    {
    label: "Others",
    value: 3
    }
  ],
  dialCodeOptions: [],
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  mobile: "",
  selectedTitle: "",
  selectedGender: "",
  selectedDialCode: "",
  selectedDOB: "",
  personalInfo: 2,

  loadingPersonalInfo: false,

  }
  validator = new SimpleReactValidator();

  componentDidMount() {
  sessionStorage.removeItem('TRIPID');
  localStorage.setItem('sso',localStorage.getItem('sso'))

  let personalInfo = localStorage.getItem('personalInfo');
  if(personalInfo != null){
    // toast.success(alertConstants.LOGIN_SUCCESS);
    this.setState({personalInfo});
    this.getDialCodes();
    if(personalInfo == 0){
      $('.profileBtn').click();
    }else if(personalInfo == 1){
      $('.profileBtn').click();
    let cpiId = localStorage.getItem('cpiId');
    this.setState({cpiId});
    this.getPersonalInfo(cpiId);
    }
  }
  }

  getDialCodes = async () => {
    let res = await personalInfoService.getDialCodes();
    if(res) {
      this.setState({
        dialCodeOptions: res.map(item => {
          return {
            label: item.countryName + ' - ' + item.dialCode,
            value: item.dialCode
          }
        })
      })
    }
  }

  getPersonalInfo = async (cpiId) => {
      this.setState({
        loadingPersonalInfo: true
      })
    let result = await personalInfoService.getPersonalInfo(cpiId);
    if(result){
        this.setState({loadingPersonalInfo: false});
        let dob = "";
        if(result.dob){
          dob = new Date(result.dob);

        }
        this.setState({
          firstName: result.firstName,
          lastName: result.lastName,
          middleName: result.middleName,
          email: result.email,
          selectedTitle: result.title,
          selectedGender: result.gender,
          mobile: result.mobile,
          selectedDialCode: this.state.dialCodeOptions.find(item => item.value == result.dialCode),
          selectedDOB: dob

        });
    }

  }

  handleTitleSelect = (selectedTitle) => {
  this.setState({selectedTitle});

  }
  handleGenderSelect = (selectedGender) => {
  this.setState({selectedGender});
  }

  handleDialSelect = (selectedDialCode) => {
  this.setState({selectedDialCode})
  }

  handleInputChange = (e) => {
  this.setState({[e.target.name] : e.target.value});
  }

  handleDateChange = (selectedDate) => {
    this.setState({
      selectedDOB: selectedDate
    })
  }

  handleModalCancel = () => {
  $('.close').click();
  }

  handleSubmit = async (e, type) => {
  // * Type 1 for create and 2 is for update
  let payload = {};
  if(this.validator.allValid()){
    let dob = moment(this.state.selectedDOB, 'DD MMM YYYY').format('YYYY-MM-DD');

     let payload = {
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			middleName: this.state.middleName,
			title: this.state.selectedTitle,
			gender: this.state.selectedGender,
			dialCode: this.state.selectedDialCode.value,
			mobile: this.state.mobile,
      email: this.state.email,
      dob:dob
    }
    // console.log('--- payload ', payload);
    switch(type){
    case 0:

      try {
      let res = await personalInfoService.createPersonalInfo(payload);
      if(res){
        localStorage.setItem("personalInfo",2);
        localStorage.setItem("cpiId",res?.result?.cpiId);
				toast.success(alertConstants.UPDATED_SUCCESSFULLY);
				this.setState({personalInfo: 2})
        $('.close').click();
      }
		}catch(err){

          }
      break;

			case 1:
				try {
					let res = await personalInfoService.updatePersonalInfo(this.state.cpiId,payload);
					if(res){
						localStorage.setItem("personalInfo",2);
						this.setState({personalInfo: 2})
						toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        $('.close').click();
      }

      }catch(err){

      }

    }
  }else{
    this.validator.showMessages();
    this.forceUpdate();
  }
  }


  render() {
  return (
    <div className="p-padding">
    <div className="row">
      <div className="offset-xl-1 col-xl-10">
      <BookingMenuPage />
      <BookingHomeCardPage />
      <BasicProfile
        {...this.state}
        validator={this.validator}
        handleModalCancel={this.handleModalCancel}
        handleDialSelect={this.handleDialSelect}
        handleGenderSelect={this.handleGenderSelect}
        handleTitleSelect={this.handleTitleSelect}
        handleInputChange={this.handleInputChange}
        handleDateChange={this.handleDateChange}
        handleSubmit={this.handleSubmit}
        isCancellable={false}
      />
      <button className="d-none profileBtn" data-target="#BasicProfileEditModal" data-toggle="modal" >

       </button>
      </div>
    </div>

    {/*  */}

    {/*  */}
    </div>
  )
  }
}
export default BookingHomePage
