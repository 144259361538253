import { http } from "../_helpers";
import { config } from "../env";

const getDetailEmailTemplate = (id, companyId, serviceModuleId) => {
  return http
    .get(
      config.apiUrlCompany +
        `email-administration/${id}?companyId=${companyId}&serviceModuleId=${serviceModuleId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getServiceModuleId = () => {
  return http.get(config.apiUrlCompany + `/service-module`)
        .then(data => {
          return data ? data : "";
       });
};

const postDetailEmailTemplate = data => {
  return http
    .post(config.apiUrlCompany + "email-administration", data)
    .then(data => {
      return data ? data : "";
    });
};

const updateDetailEmailTemplate = (id, data) => {
  return http
    .patch(config.apiUrlCompany + `email-administration/${id}`, data)
    .then(data => {
      return data ? data : "";
    });
};

const updateStatusEmailTemplate = (id, data) => {
  return http
    .patch(
      config.apiUrlCompany + `email-administration/active-inactive/${id}`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};

const createDuplicateEmailTemplate = (data) => {
  return http.post(config.apiUrlCompany + `email-template/duplicate` , data)
}

export const companyEmailServices = {
  getServiceModuleId,
  getDetailEmailTemplate,
  postDetailEmailTemplate,
  updateDetailEmailTemplate,
  updateStatusEmailTemplate,
  createDuplicateEmailTemplate
};
