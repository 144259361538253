import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { personalInfoService } from 'src/_services/personalInfoService';
import { myAccountTravelSettingsServise, companyService } from "../../../../../../_services";
import cryptr from "src/_helpers/crypty";
import CabAddForm from "./CabAddForm";

class CabForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      country: "",
      popularCity: [],
      fromDate: "",
      toDate: "",
      selectedDay: "",
      mobile: "",
      grade: "",
      costCenter: "",
      employeeId: "",
      department: "",
      companyName: "",
      dm1fName: "",
      dm1lName: "",
      companyId: localStorage["440a28"],
      userId: "",
      dfManager1: "",
      location: "",
      emailLocationManager: "",
      carDetails: [
        {
          from: "",
          to: "",
          activeInput: 0,
          routine: false,
          validationTrue: false,
          latFrom: "",
          lngFrom: "",
          latTo: "",
          lngTo: "",
          instructions: "",
          startDate: new Date(),
          today: new Date(),
        }
      ]
    };
  }
  locationFromRef = React.createRef();
  locationToRef = React.createRef();
  locationInputRef = React.createRef();
  dateFromRef = React.createRef();
  dateToRef = React.createRef();
  listRef = React.createRef();

  componentDidMount() {
    let cpiId = localStorage.getItem('cpiId');
    let userId = localStorage.getItem("440a1")
    this.setState({ userId })
    this.setState({ cpiId });
    this.getPersonalInfo(cpiId);
    this.getProfileDetails()
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getDefaultManagers()
  }
  getDefaultManagers = async () => {
    let id = localStorage.getItem("440a1")
    if (id) {
      var res = await companyService.getDefaultManagers(id, this.state.companyId);
      if (res) {
        // console.log(res)
        this.setState({
          dfManager1: res.reportManager1.email,
        })
        res.fieldData.filter((item) => {
          if (item.columnName === "CUSTOM9") {
            this.setState({ location: item.connectedFieldValue })
            this.setLocationMangage()
          }
        })
      }
    }
  }

  setLocationMangage = () => {
    if (this.state.location === "Delhi") {
      this.setState({ emailLocationManager: ["transportforu@dalmiabharat.com", "kumar.avnish@dalmiabharat.com"] })
      // ,kumar.avnish@dalmiabharat.com
    }
    else if (this.state.location === "Connaught Place") {
      this.setState({ emailLocationManager: ["transportforu@dalmiabharat.com", "kumar.avnish@dalmiabharat.com"] })
      // ; kumar.avnish@dalmiabharat.com
    }
    else if (this.state.location === "Ramgarh") {
      this.setState({ emailLocationManager: "tiwari.kamlesh@dalmiasugar.com" })
    }
    else if (this.state.location === "Jawaharpur") {
      this.setState({ emailLocationManager: "singh.sanjay5@dalmiasugar.com" })
    }
    else if (this.state.location === "Nigohi") {
      this.setState({ emailLocationManager: "singh.anjanikumar@dalmiasugar.com" })
    }
    else if (this.state.location === "Kolhapur Plant") {
      this.setState({ emailLocationManager: "singh.bikram@dalmiasugar.com" })
    }
    else if (this.state.location === "Ninaidevi") {
      this.setState({ emailLocationManager: "patil.shivaji@dalmiasugar.com" })
    }
    else if (this.state.location === "Khambhalia") {
      this.setState({ emailLocationManager: "nakum.dhiraj@dalmiaocl.com" })
    }
    else if (this.state.location === "Katni") {
      this.setState({ emailLocationManager: "m.jitendrakumar@dalmiaseven.com" })
    }
    else if (this.state.location === "Belgaum") {
      this.setState({ emailLocationManager: "pillai.sureshkumar@dalmiacement.com" })
    }
    else if (this.state.location === "Dalmiapuram") {
      this.setState({ emailLocationManager: "c.rameshbabu@dalmiacement.com" })
    }
    else if (this.state.location === "Ariyalur") {
      this.setState({ emailLocationManager: "kp.paramasivam@dalmiacement.com" })
    }
    else if (this.state.location === "Rajgangpur") {
      this.setState({ emailLocationManager: "dwibedy.pankaj@dalmiacement.com" })
    }
    else if (this.state.location === "Kalyanpur") {
      this.setState({ emailLocationManager: "raikwar.dharmendra@dalmiacement.com" })
    }
    else if (this.state.location === "Bokaro Plant") {
      this.setState({ emailLocationManager: "facilities4u.bokaro@dalmiabharat.in" })
    }
    else if (this.state.location === "Kapilas") {
      this.setState({ emailLocationManager: "mahanty.abhiram@dalmiacement.com" })
    }
    else if (this.state.location === "Medinipur") {
      this.setState({ emailLocationManager: "Maity.Avishek@dalmiacement.com" })
    }
    else if (this.state.location === "Umrongso") {
      this.setState({ emailLocationManager: "borah.sarat@dalmiacement.com" })
    }
    else if (this.state.location === "Lanka") {
      this.setState({ emailLocationManager: "mipun.depen@dalmiacement.com" })
    }
    else if (this.state.location === "Jagiroad") {
      this.setState({ emailLocationManager: "kalita.lakshi@dalmiacement.com" })
    }
    else if (this.state.location === "Lumshnong") {
      this.setState({ emailLocationManager: "traveldesk.lumshnong@dalmiabharat.in" })
    }
    else if (this.state.location === "Chennai") {
      this.setState({ emailLocationManager: "hrchennai@dalmiacement.com" })
    }
    else if (this.state.location === "Guwahati") {
      this.setState({ emailLocationManager: "traveldesk.guwahati@dalmiacement.com" })
    }
    else if (this.state.location === "Kolkata") {
      this.setState({ emailLocationManager: "Traveldesk.East@dalmiacement.com" })
    }
    else if (this.state.location === "Kadapa Plant") {
      this.setState({ emailLocationManager: "reddy.vvmunibhaskar@dalmiacement.com" })
    }
    else if (this.state.location === "Noida") {
      this.setState({ emailLocationManager: ["transportforu@dalmiabharat.com", "kumar.avnish@dalmiabharat.com"] })
      // ; kumar.avnish@dalmiabharat.com
    }
    else if (this.state.location === "Muppandal") {
      this.setState({ emailLocationManager: "hrchennai@dalmiacement.com" })
    }
    else if (this.state.location === "Karungulam") {
      this.setState({ emailLocationManager: "hrchennai@dalmiacement.com" })
    }
    else if (this.state.location === "Bhilai Client") {
      this.setState({ emailLocationManager: "accounts@gsb-group.co.in" })
    }

  }


  getProfileDetails = async () => {
    var res = await myAccountTravelSettingsServise.getProfileDetails();
    if (res) {
      // console.log(res)
      // this.setState({userId : res.result.id })
      res.result.fieldData.filter((data) => {

        if (data.labelName === "Cost Center") {
          this.setState({ costCenter: data.connectedFieldValue })
        }
        else if (data.labelName === "Grade") {
          this.setState({ grade: data.connectedFieldValue })
        }
        else if (data.labelName === "Employee ID") {
          this.setState({ employeeId: data.fieldValue })
        }
        else if (data.labelName === "Department") {
          this.setState({ department: data.connectedFieldValue })
        }
        else if (data.labelName === "Legal Entity") {
          this.setState({ companyName: data.connectedFieldValue })
        }
      })
      res.result.reportManager1.fieldData.filter((data) => {
        if (data.columnName === "EMPLOYEE_FIRST_NAME") {
          this.setState({ dm1fName: data.fieldValue })
        }
        else if (data.columnName === "EMPLOYEE_LAST_NAME") {
          this.setState({ dm1lName: data.fieldValue })
        }
      })
    };
  }

  // getReportManagerOne = (requiredColumn) => {
  //   if (this.state.userDetails && this.state.userDetails.reportManager1) {
  //     let firstNameObject = this.state.userDetails.reportManager1.fieldData.find(info => info.columnName == requiredColumn);
  //     if (firstNameObject != null) {
  //       return firstNameObject.fieldValue
  //     }

  //   } else {
  //     return " ";
  //   }
  // }
  getPersonalInfo = async (cpiId) => {
    this.setState({
      loadingPersonalInfo: true
    })
    let result = await personalInfoService.getPersonalInfo(cpiId);
    if (result) {

      this.setState({
        firstName: result.firstName,
        lastName: result.lastName,
        email: result.email,
        mobile: result.mobile,
        userId: result.id
      });

    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = e => {
    const locationFromPopup = this.locationFromRef.current;
    const locationToPopup = this.locationToRef.current;

    const { activeInput } = this.state;
    if (
      activeInput === 1 &&
      this.locationFromRef &&
      !locationFromPopup.contains(e.target)
    ) {
      this.setState({ activeInput: 0 });
      return;
    }
    if (
      activeInput === 2 &&
      this.locationToRef &&
      !locationToPopup.contains(e.target)
    ) {
      this.setState({ activeInput: 0 });
      return;
    }
  };

  showInput = (e, index) => {
    const carDetails = this.state.carDetails;
    carDetails[index] = {
      ...carDetails[index],
      ...{ activeInput: e }
    };
    this.setState({ carDetails });
  };

  getCountryCode = results => {
    if (results[0] && results[0].address_components) {
      // console.log(results[0]);
      // console.log('----',results[0].address_components);
      let countryComponent = results[0].address_components.find(item =>
        item.types.includes("country")
      );
      // console.log('country component found ', countryComponent);
      if (countryComponent != undefined) {
        let shortCountryCode = countryComponent.short_name;
        // let isoCountryCode = 'ISO 3166-2:'+ shortCountryCode;
        this.setState({
          country: shortCountryCode
        });
      }
    }
  };

  getCityCode = async results => {
    if (results[0] && results[0].address_components) {
      let formattedAddress = results[0].formatted_address;
      let splitFormattedAddress = formattedAddress.split(",");
      // console.log('----formatted address ', splitFormattedAddress);
      if (splitFormattedAddress && splitFormattedAddress.length)
        this.setState({
          cityName: splitFormattedAddress[0]
        });
    }
  };

  handleSelectFrom = (from, index) => {
    geocodeByAddress(from)
      .then(results => {
        this.getCityCode(results);
        this.getCountryCode(results);
        return getLatLng(results[0]);
      })
      .then(location => {
        const carDetails = this.state.carDetails;
        carDetails[index] = {
          ...carDetails[index],
          ...{
            from: from,
            latFrom: JSON.stringify(location.lat),
            lngFrom: JSON.stringify(location.lng),
            activeInput: 0
          }
        };
        this.setState({ carDetails });
      })
      .catch(error => console.error("Error", error));
  };

  handleSelectTo = (to, index) => {
    geocodeByAddress(to)
      .then(results => {
        this.getCityCode(results);
        this.getCountryCode(results);
        return getLatLng(results[0]);
      })
      .then(location => {
        const carDetails = this.state.carDetails;
        carDetails[index] = {
          ...carDetails[index],
          ...{
            to: to,
            latTo: JSON.stringify(location.lat),
            lngTo: JSON.stringify(location.lng),
            activeInput: 0
          }
        };
        this.setState({
          carDetails
        });
      })
      .catch(error => console.error("Error", error));
  };

  handleDate = (date, index) => {
    const carDetails = this.state.carDetails;
    carDetails[index] = {
      ...carDetails[index],
      ...{ startDate: date }
    };
    this.setState({
      carDetails
    });
  };

  validateTrue = false;

  clearForm = () => {
    this.setState({
      carDetails: [
        {
          from: "",
          to: "",
          address: "",
          activeInput: 0,
          routine: false,
          validationTrue: false,
          latFrom: "",
          lngFrom: "",
          latTo: "",
          lngTo: "",
          country: "",
          popularCity: [],
          fromDate: "",
          toDate: "",
          selectedDay: "",
          instructions: "",
          startDate: new Date(),
          today: new Date()
        }
      ]
    });
  };

  popularCity = [];
  getCityFrom = async (e, index) => {
    const carDetails = this.state.carDetails;
    carDetails[index] = {
      ...carDetails[index],
      ...{
        from: e.name,
        latFrom: e.lat,
        lngFrom: e.lng,
        country: e.country,
        cityName: e.cityName,
        activeInput: 0
      }
    };
    this.setState({ carDetails });
  };

  getCityTo = async (e, index) => {
    const carDetails = this.state.carDetails;
    carDetails[index] = {
      ...carDetails[index],
      ...{
        to: e.name,
        latTo: e.lat,
        lngTo: e.lng,
        country: e.country,
        cityName: e.cityName,
        activeInput: 0
      }
    };
    this.setState({ carDetails });
  };

  handleChangeFrom = (from, index) => {
    const carDetails = this.state.carDetails;
    carDetails[index] = {
      ...carDetails[index],
      ...{
        from
      }
    };
    this.setState({ carDetails });
  };

  handleChangeTo = (to, index) => {
    const carDetails = this.state.carDetails;
    carDetails[index] = {
      ...carDetails[index],
      ...{
        to
      }
    };
    this.setState({ carDetails });
  };

  handleRemoveCar = id => {
    this.setState({
      carDetails: this.state.carDetails.filter((s, index) => id !== index)
    });
  };
  validateTrue = false;
  handleSubmit = async () => {
    this.validateTrue = true;
    if (this.isEmpty(this.state.carDetails)) {
      let url = `https://bigappcompany.co.in/demos/cms/contact`;
      for (let i = 0; i < this.state.carDetails.length; i++) {
        let { from, to, startDate, instructions } = this.state.carDetails[i];
        let payload = new FormData();
        if (typeof (this.state.location) !== "string") {
          let findIndex = this.state.location.split(",")
          let str = `from_email=shreyas@curiotechnologies.com&to_email=${findIndex[0]},${findIndex[1]}&to_cc=${this.state.dfManager1},${this.state.email}
        &subject=Car Trip : Contact Details&
      Passenger Name = ${this.state.firstName}${" "}${this.state.lastName}&
      Pickup Address=${from}&
      Drop Address=${to}&
      Starting Date/Time=${startDate}&
      Supervisor Name=${this.state.dm1fName.concat(" ", this.state.dm1lName)}&
      Contact Number=${this.state.mobile}&
      Employee ID = ${this.state.employeeId}&
      Department=${this.state.department}&
      Company Name=${this.state.companyName}&
      Grade=${this.state.grade}&
      Cost Centre=${this.state.costCenter}&
      Email = ${this.state.email}&
      Special instruction =${instructions}
      `;
          payload.append("product_name", str);

          axios
            .post(url, payload)
            .then(res => {
              if (res.status == 200) {
                this.clearForm();
              }
              this.setState({ isSubmitting: false });
            })
            .catch(err => {
              this.setState({ isSubmitting: false });
            });

        } else {
          let str = `from_email=shreyas@curiotechnologies.com&to_email=${this.state.emailLocationManager}&to_cc=${this.state.dfManager1},${this.state.email}
        &subject=Car Trip : Contact Details&
      Passenger Name = ${this.state.firstName}${" "}${this.state.lastName}&
      Pickup Address=${from}&
      Drop Address=${to}&
      Starting Date/Time=${startDate}&
      Supervisor Name=${this.state.dm1fName.concat(" ", this.state.dm1lName)}&
      Contact Number=${this.state.mobile}&
      Employee ID = ${this.state.employeeId}&
      Department=${this.state.department}&
      Company Name=${this.state.companyName}&
      Grade=${this.state.grade}&
      Cost Centre=${this.state.costCenter}&
      Email = ${this.state.email}&
      Special instruction =${instructions}
      `;
          payload.append("product_name", str);

          axios
            .post(url, payload)
            .then(res => {
              if (res.status == 200) {
                this.clearForm();
              }
              this.setState({ isSubmitting: false });
            })
            .catch(err => {
              this.setState({ isSubmitting: false });
            });
        }
      }
    }
    this.setState({ ...this.state })
  };


  isEmpty = arr => {
    let arrValues = arr.map(item => {
      return [...Object.values(item)];
    });
    var myNewArray = [].concat(...arrValues);
    if (myNewArray.indexOf("") === -1 && myNewArray.indexOf(null) === -1) {
      return true;
    } else {
      return false;
    }
  };
  onInstructionChange = (e, index) => {
    const carDetails = this.state.carDetails;
    carDetails[index] = {
      ...carDetails[index],
      ...{
        instructions: e.target.value
      }
    };
    this.setState({ carDetails });
  };

  handleAddCar = () => {
    if (this.state.carDetails.length > 4) {
      toast.error("You reached the max limit");
    } else {
      this.setState({
        carDetails: this.state.carDetails.concat([
          {
            from: "",
            to: "",
            address: "",
            activeInput: 0,
            routine: false,
            validationTrue: false,
            latFrom: "",
            lngFrom: "",
            latTo: "",
            lngTo: "",
            country: "",
            popularCity: [],
            fromDate: "",
            toDate: "",
            selectedDay: "",
            instructions: "",
            startDate: new Date(),
            today: new Date()
          }
        ])
      });
    }
  };

  render() {

    return (
      <div>
        <div className="book-details">
          {this.state.carDetails.map((item, id) => {
            return (
              <div key={id}>
                <CabAddForm
                  {...this.state}
                  index={id}
                  car={item}
                  showInput={this.showInput}
                  handleRemoveCar={this.handleRemoveCar}
                  onInstructionChange={this.onInstructionChange}
                  handleDate={this.handleDate}
                  handleChangeTo={this.handleChangeTo}
                  handleSelectTo={this.handleSelectTo}
                  handleChangeFrom={this.handleChangeFrom}
                  handleSelectFrom={this.handleSelectFrom}
                  locationToRef={this.locationToRef}
                  getCityFrom={this.getCityFrom}
                  validateTrue={this.validateTrue}
                />
              </div>
            );
          })}
          <div className="d-flex">
            {this.state.carDetails.length <= 4 && (
              <button
                onClick={this.handleAddCar}
                type="button"
                className="btn btn-nothing add-city ml-1 text-primary d-block"
              >
                Add Car
              </button>
            )}
            <button
              data-toggle="modal"
              data-target="#submitModal"
              className="btn details search btn-blue d-md-block px-4 ml-auto"
              style={{ lineHeight: "65px", "max-width": "10%" }}
            >
              Submit
            </button>
          </div>
        </div>
        <div
          className="modal fade"
          id="submitModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="submitModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close text-white d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="text-center pt-5">
                  <img src="/assets/images/confirmation_icon.svg" alt="" />
                  <h3 className=" my-4 text-dark">
                    Do you want to submit
                  <br />
                   car details ?
                  </h3>
                </div>
              </div>
              {/* <div className="modal-middle pm-30">
                  <h2 className="text-center mt-5 mb-4">Do you want to submit
                  <br/>
                   cab details ?</h2>
                </div> */}
              <div className="text-center modal-last mb-5 btn-group-margin">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="btn btn-border-success px-4 py-1 mr-3"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#thanksModal"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-border-basic px-4 py-1"
                  data-dismiss="modal"
                >
                  No
              </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="thanksModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="thanksModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close text-white d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="text-center pt-5">
                  <img src="/assets/images/confirmation_icon.svg" alt="" />
                  <h3 className=" my-4 text-dark">
                    Thank you
                  </h3>
                </div>
              </div>
              <div className="text-center modal-last pm-30  pt-0 btn-group-margin">
                <button
                  type="button"
                  className="btn btn-border-success px-4"
                  data-dismiss="modal"
                >
                  Okay
                    </button>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default CabForm;
