import React, { Component } from 'react';

class CashadvanceRejection extends Component {
  render() {
    return (
      <div
        className="modal fade"
        id="rejectcashAdvanceModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="rejectcashAdvanceLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h6 className="modal-subtitle">New</h6>
              <h5 className="modal-title" id="rejectcashAdvanceLabel">
                Reason for Rejection
                </h5>
            </div>
            <div className="modal-middle">
              <div className="pm-30 pb-0">
                <div className="form-group mb-0 custom labelup textarea">
                  <textarea
                    data-gramm_editor="false"
                    spellCheck="false"
                    rows="3" className="form-control" placeholder="Reason for Rejection" />
                  <label>Reason</label>
                </div>
              </div>

            </div>
            <div className="modal-last btn-group-margin pm-30">
              <button
                type="button"
                className="btn btn-badge"
                data-dismiss="modal"
              >
                Cancel
                </button>
              <button className="btn  ">Submit <i className="material-icons">arrow_right</i></button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CashadvanceRejection;