import React, { Component } from "react";
import TrainDetails from './trainDetails';
import TrainHeaderTab from './trainHeaderTab';
import { inject, observer } from 'mobx-react';
import TrainCard from './trainCard';
import TrainLoader from './trainLoader';
import NoResults from './NoResults';
import TrainDetailLoader from './TrainDetailLoader';
import { get } from 'mobx';

@inject('trainStore')
@observer
class TrainList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleTrainDetail: false,
            selectedTrainCode: '',
        }
    }
    type = 1;
    changeWay(type) {
        this.type = type;
        this.forceUpdate();
    }
    componentDidMount() {

    }
    handleTrainDetailToggle = (trainCode, trainInfo) => {
        if (this.state.selectedTrainCode === trainCode) {
            this.setState({
                toggleTrainDetail: false,
                selectedTrainCode: ''
            })
        } else {

            this.setState({
                toggleTrainDetail: true,
                selectedTrainCode: trainCode
            });
            let payloadForTrainDetails = {
                trainNo: trainInfo.train_code,
                fromStation: trainInfo.from_stn,
                toStation: trainInfo.to_stn,
                dateOfJourney: this.props.trainStore.departureDate,
                quota: this.props.trainStore.trainDetails[trainInfo.train_code].quota || 'GN',
                seatClass: this.props.trainStore.trainDetails[trainInfo.train_code].seatClass || ""
            }
            this.props.trainStore.getTrainDetails(payloadForTrainDetails);
        }
    }
    handleClassClick = (e, coachType, trainCode, trainInfo) => {
        e.stopPropagation();
        this.props.trainStore.handleClassClick(coachType, trainCode);
        let payloadForTrainDetails = {
            trainNo: trainInfo.train_code,
            fromStation: trainInfo.from_stn,
            toStation: trainInfo.to_stn,
            dateOfJourney: this.props.trainStore.departureDate,
            quota: this.props.trainStore.trainDetails[trainInfo.train_code].quota || 'GN',
            seatClass: this.props.trainStore.trainDetails[trainInfo.train_code].seatClass || ""
        }

        if (this.state.toggleTrainDetail && this.state.selectedTrainCode == trainCode) {
            this.props.trainStore.getTrainDetails(payloadForTrainDetails);
        }
    }

    handleQuotaSelect = (val, trainCode) => {
        this.props.trainStore.handleQuotaSelect(val, trainCode);
    }

    handleSeatClick = (seatDetails, trainCode) => {
        this.props.trainStore.handleSeatClick(seatDetails, trainCode);
    }

    render() {
        return (
            <div>

                {
                    !this.props.trainStore.loadingTrainResults && this.props.trainStore.trainResults?.result?.length ? (
                        this.props.trainStore.trainResults.result.map((result, key) => {
                            return (
                                <div className="devision-container" key={key}>
                                    <TrainHeaderTab />
                                    <TrainCard
                                        item={result}
                                        {...this.props}
                                        handleTrainDetailToggle={this.handleTrainDetailToggle}
                                        handleClassClick={this.handleClassClick}
                                        handleQuotaSelect={this.handleQuotaSelect}
                                        trainDetails={this.props.trainStore.trainDetails}
                                        addToCart={this.props.addToCart}
                                    />
                                    {
                                        this.state.toggleTrainDetail && !this.props.trainStore.loadingTrainDetails && (this.state.selectedTrainCode === result.train_code) && <TrainDetails
                                            trainClassInfo={result.classes}
                                            trainDetails={get(this.props.trainStore.trainDetails, result.train_code)}
                                            trainCode={result.train_code}
                                            selectedSeatId={this.props.trainStore.selectedSeat.id}
                                            handleSeatClick={this.handleSeatClick}

                                        />

                                    }
                                    {
                                        this.state.toggleTrainDetail && this.props.trainStore.loadingTrainDetails && (this.state.selectedTrainCode === result.train_code) && <TrainDetailLoader />
                                    }
                                </div>

                            )
                        })
                    ) : null

                }
                {
                    this.props.trainStore.loadingTrainResults && (
                        <TrainLoader />
                    )
                }

                {
                    !this.props.trainStore.loadingTrainResults && this.props.trainStore.totalTrainsFound === 0 && (
                        <NoResults msg="No Trains Found" />
                    )
                }



            </div>

        );
    }
}

export default TrainList;
