import React, { Component } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import uuid from "uuid";
import moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";
import ExpenseCreateForm from "./components/addExpenseType/expenseCreateForm";
import Loader from "./../../../_components/common/Loader";
import LeftSidebar from "./components/addExpenseType/leftSidebar";
import SimpleReactValidator from "simple-react-validator";
import WayPoints from "./components/mileage/waypoints";
import { mileageService } from "src/_services/mileage.service";
import { expenseReport } from "../../../_services/expenseReport";
import DynamicFields from "src/_components/common/dynamicFields";
import ColumnNames from "src/_components/common/columnNames";
import debounce from "src/_components/common/debounce";
import { getValue, setValue } from "src/_components/common/lodsh";
import { getConnectedList } from "src/_services";
import $ from "jquery";

class AddMilagePage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      wayPoints: [
        {
          id: uuid.v4(),
          fromAddress: "",
          toAddress: "",
          fromLat: 0,
          fromLng: 0,
          toLat: 0,
          toLng: 0,
          distance: 0,
          meters: 0,
          via: "",
          geocodedWaypoints: []
        }
      ],
      companyId: localStorage["440a28"],
      expenseForm: [],
      error: [],
      formFields: [],
      fieldsObject: [],
      expenseEntryList: [],
      totoalExpense: 0,
      cashAdvanceTotalAmount: 0,
      totalRequestAmount: 0,
      totalExpenseAmount: 0,
      validationStatus: false,
      userPermission: "employee-role",
      connectedListData: [],
      isLoading: true,
      pageName: "expenseEntry",
      closeDropdown: false,
      isSubmitClick: false,
      isMileageEdit: false,
      totalDistanceInKM: 0,
      auditRuleValidationError: [],
      displayTaxFields: false,
      displayCSGST: false,
      displayIGST: false,
      taxAmount: 0,
      search: [],
      loading: []
    };
  }
  componentWillUnmount() {
    this.state = {
      search: [],
      loading: []
    }
  }
  componentDidMount() {
    if (this.props.match.params.expenseId) {
      // this.getEditExpenseFormField();
      this.getEditExpenseDetails();
      this.getLeftsidebarDataRefetch()
    } else {
      this.getAddExpenseFormField();
    }
    this.getAllCreatedExpenses();
  }



  // Map operation start
  handleFromChange = (address, wayPointId) => {
    let wayPoints = [...this.state.wayPoints];
    let wayPoint = wayPoints.find(point => point.id === wayPointId);
    wayPoint.fromAddress = address;
    this.setState({
      wayPoints
    });
  };

  handleToChange = (address, wayPointId) => {
    let wayPoints = [...this.state.wayPoints];
    let wayPoint = wayPoints.find(point => point.id === wayPointId);
    wayPoint.toAddress = address;
    this.setState({
      wayPoints
    });
  };

  handleFromSelect = (address, wayPointId) => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        let wayPoints = [...this.state.wayPoints];
        let wayPoint = wayPoints.find(point => point.id === wayPointId);
        wayPoint.fromAddress = address;
        wayPoint.fromLat = latLng.lat;
        wayPoint.fromLng = latLng.lng;
        this.setState({
          wayPoints
        });
        if (
          wayPoint.toAddress !== undefined &&
          wayPoint.toAddress !== "" &&
          wayPoint.toAddress !== null &&
          address
        ) {
          // this.getAllRoutes(address, wayPoint.toAddress, wayPointId);
          this.getAllRoutes(
            `${latLng.lat},${latLng.lng}`,
            `${wayPoint.toLat},${wayPoint.toLng}`,
            wayPointId
          );
        }
      })
      .catch(error => console.error("Error", error));
  };
  handleToSelect = (address, wayPointId) => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        let wayPoints = [...this.state.wayPoints];
        let wayPoint = wayPoints.find(point => point.id === wayPointId);
        wayPoint.toAddress = address;
        wayPoint.toLat = latLng.lat;
        wayPoint.toLng = latLng.lng;
        this.setState({
          wayPoints
        });
        if (
          wayPoint.fromAddress !== undefined &&
          wayPoint.fromAddress !== "" &&
          wayPoint.fromAddress !== null &&
          address
        ) {
          this.getAllRoutes(
            `${wayPoint.fromLat},${wayPoint.fromLng}`,
            `${latLng.lat},${latLng.lng}`,
            wayPointId
          );
        }
      })
      .catch(error => console.error("Error", error));
  };

  handleAddLocation = () => {
    let wayPoints = [...this.state.wayPoints];
    const wayPoint = {
      id: uuid.v4(),
      fromAddress: "",
      toAddress: "",
      fromLat: 0,
      fromLng: 0,
      toLat: 0,
      toLng: 0,
      via: "",
      distance: 0,
      meters: 0,
      geocodedWaypoints: []
    };
    wayPoints.push(wayPoint);
    this.setState({ wayPoints: wayPoints });
  };

  handleDeleteLocation = async locationId => {
    let wayPoints = [...this.state.wayPoints];
    wayPoints = wayPoints.filter(point => point.id !== locationId);
    let totalDistance = 0;
    wayPoints.forEach(point => {
      totalDistance = totalDistance + point.distance;
    });
    const fieldsObject = [...this.state.fieldsObject];
    let distanceFieldObj = fieldsObject.find(
      field => field.columnName === ColumnNames.BUSINESS_DISTANCE
    );
    const fieldName = distanceFieldObj.fieldName;
    await this.setState({
      wayPoints: wayPoints,
      totalDistanceInKM: totalDistance,
      [fieldName]: totalDistance
    });
    this.handleOnRouteAndDeleteLocation();
  };

  getAllRoutes = async (from, to, wayPointId) => {
    let data = await mileageService.getAllDifferentRoute(from, to);
    if (data.status === "OK" && data.routes.length) {
      let wayPoints = [...this.state.wayPoints];
      let wayPoint = wayPoints.find(point => point.id === wayPointId);
      wayPoints.geocodedWaypoints = [];
      wayPoint.geocodedWaypoints = data.routes;
      this.setState({
        wayPoints
      });
    } else {
      let wayPoints = [...this.state.wayPoints];
      let wayPoint = wayPoints.find(point => point.id === wayPointId);
      wayPoints.geocodedWaypoints = [];
      wayPoint.geocodedWaypoints = [];
      this.setState({
        wayPoints
      });
    }
  };
  editAllRoutes = async (from, to) => {
    let data = await mileageService.getAllDifferentRoute(from, to);
    if (data.status === "OK" && data.routes.length) {
      return data.routes;
    } else {
      return [];
    }
  };

  handleOnDragEnd = (data, wayPointId) => { };
  handleSelectRoute = (wayPointId, index, distance) => {

    let wayPoints = [...this.state.wayPoints];
    let wayPoint = wayPoints.find(point => point.id === wayPointId);
    wayPoint.distance = distance / 1000;
    const via = wayPoint.geocodedWaypoints.find(
      (point, pointIndex) => pointIndex === index
    );
    wayPoint.via = via.summary;
    const fieldsObject = [...this.state.fieldsObject];
    let distanceFieldObj = fieldsObject.find(
      field => field.columnName === ColumnNames.BUSINESS_DISTANCE
    );
    const fieldName = distanceFieldObj.fieldName;
    let totalDistance = 0;
    for (let index = 0; index < wayPoints.length; index++) {
      totalDistance += wayPoints[index].distance;
    }
    this.setState({
      totalDistanceInKM: totalDistance,
      [fieldName]: Number(totalDistance.toFixed("2")),
      wayPoints: wayPoints
    });
    this.handleOnRouteAndDeleteLocation();
  };
  // Map operation end
  // Expense Addition start here

  getAllCreatedExpenses = async () => {
    let headerId = localStorage.getItem("headerId");
    if (headerId) {
      let response = await expenseReport.getAllCreatedExpense(
        this.state.companyId,
        headerId
      );
      if (response) {
        this.setState({
          reportHeader: response.headerObj.headerForm,
          // expenseEntryList: response.expenseReportEntryObj,
          // totoalExpense: response.expenseReportEntryObj.length,
          totalExpenseAmount: response.totalAmount,
          userBaseCurrency: response.baseCurrency,
          cashAdvanceTotalAmount: response.cashAdvanceTotalAmount,
          totalRequestAmount: response.requestAmount
        });
        // let validationStatus = true;
        // response.expenseReportEntryObj &&
        //   response.expenseReportEntryObj.forEach((list, index) => {
        //     validationStatus = !list.validation && validationStatus;
        //   });
        // this.setState({ validationStatus: validationStatus });
      }
    }
  };

  getAddExpenseFormField = async () => {
    let expenseTypeId = localStorage.getItem("expenseType");
    let response = await expenseReport.getAddExpenseFormField(
      this.state.companyId,
      expenseTypeId
    );
    if (response && response.entryFields && response.entryFields.result) {
      await (getValue(response, "entryFields.result", [])).forEach(async item => {
        if (item.connectedListId) {
          let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp, "count", 0);
          item.connectedListData.listManagementData = resp.result;
          item.search = ''
        }
        // this.setState({ policyForm: response.result })
      })
    }


    if (response && response.entryFields && response.entryFields.result) {
      this.setState({ expenseForm: response.entryFields.result });
      let newConnectedListDate = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.entryFields.result, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== "";
      });
      dynamicFeilds.forEach(fields => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField
            ? fields.multiSelectParentField
            : "",
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.defaultValue ? fields.defaultValue : ""
        });
      });
      this.setState({ connectedListData: newConnectedListDate });
      this.createDropdownForMultiLblConnectedList(newConnectedListDate);
      this.setStateOfFormsAndFields(
        response.entryFields.result,
        newConnectedListDate
      );
    } else {
      toast.success("No Data Found");
      this.setState({ isLoading: false });
    }
  };

  /**edit expense entry form on basis of expeseEntryId*/

  // v2 left sidebar API common api 
  getLeftsidebarDataRefetch = async () => {
    let headerId = localStorage.getItem("headerId");
    if (headerId) {
      let response = await expenseReport.getAllCreatedExpenseNew(
        this.state.companyId,
        headerId,
        10,
        1
      );
      console.log(response, "--->v2 for sidebar respnse refetch");
      // for setting state
      const { expenseEntryList } = this.state;
      if (response && response.result.expenseReportEntryObj) {
        this.setState({ isLoading: false });
        this.count = response.count;
        this.setState({
          expenseEntryList: response.result.expenseReportEntryObj,
          totoalExpense: response.result.expenseReportEntryObj.length,
          isLoading: false
        });
      } else {
        this.setState({
          isLoading: false,
          expenseEntryList: []
        });
      }
    }
  }

  getEditExpenseDetails = async () => {
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let resp = await expenseReport.editExpenseEntryList(this.state.companyId, expenseEntryId);

    if (resp && resp) {
      await resp.forEach(async item => {
        if (item.connectedListId) {
          let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp, "count", 0);
          item.connectedListData.listManagementData = resp.result;
          item.search = ''
        }
        // this.setState({ policyForm: response.result })
      })
    }

    let response = await expenseReport.getExpenseEntryDetails(this.state.companyId, expenseEntryId)
    if (response) {
      console.log(response)
      let wayPoints = [];
      console.log(response)
      if (response && response.expenseReportEntryObj && response.expenseReportEntryObj) {
        this.setState({ isMileageEdit: true });
        for (let i = 0; i < response.expenseReportEntryObj.navigationPaths.length; i++) {
          let editWaypoints = response.expenseReportEntryObj.navigationPaths[i];
          let routes = await this.editAllRoutes(
            `${editWaypoints.fromLat},${editWaypoints.fromLng}`,
            `${editWaypoints.toLat},${editWaypoints.toLng}`
          );
          wayPoints[i] = {
            id: uuid.v4(),
            fromAddress: editWaypoints.fromAddress,
            toAddress: editWaypoints.toAddress,
            fromLat: editWaypoints.fromLat,
            fromLng: editWaypoints.fromLng,
            toLat: editWaypoints.toLat,
            toLng: editWaypoints.toLng,
            distance: editWaypoints.distance,
            via: editWaypoints.via,
            geocodedWaypoints: routes
          };
        }
        this.setState({ wayPoints: wayPoints });
        let newConnectedListDate = [];
        let dynamicFeilds = [];
        console.log(response.expenseReportEntryObj.entryForm)
        dynamicFeilds = _.filter(resp, function (result) {
          return result.fieldLevel !== undefined && result.fieldLevel !== "";
        });
        console.log(dynamicFeilds)
        const totalDistanceInKM = resp.find(
          form => form.columnName === ColumnNames.BUSINESS_DISTANCE
        )?.fieldValue;
        console.log(totalDistanceInKM)
        dynamicFeilds.forEach(fields => {
          newConnectedListDate.push({
            columnName: fields.columnName,
            id: fields.id,
            multiSelectParentField: fields.multiSelectParentField
              ? fields.multiSelectParentField
              : "",
            fieldName: fields.fieldName,
            fieldLevel: fields.fieldLevel,
            prevFieldId: fields.prevFieldId

          });
        });
        this.setState({
          connectedListData: newConnectedListDate,
          expenseForm: resp,
          totalDistanceInKM: totalDistanceInKM
        });
        this.createDropdownForMultiLblConnectedList(newConnectedListDate);
        this.setTheStateOfFormsAndFields(resp);
      }

      // let validationStatus = true;
      // response.expenseReportEntryObj &&
      //   response.expenseReportEntryObj.forEach((list, index) => {
      //     validationStatus = !list.validation && validationStatus;
      //   });
      // this.setState({ validationStatus: validationStatus });
    }
  }


  getEditExpenseFormField = async () => {
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    // let response = await expenseReport.editExpenseEntryForm(expenseEntryId);
    let response = await expenseReport.editExpenseEntryList(this.state.companyId, expenseEntryId);
    console.log(response)
    // let wayPoints = [];
    if (response && response) {
      //   this.setState({
      //     expenseEntryList: response,
      //     totoalExpense: response.length,
      // })
      response.forEach(async (item) => {
        if (item.connectedListId) {
          let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp, "count", 0);
          item.connectedListData.listManagementData = resp.result;
          item.search = ''
        }
        // this.setState({ expenseForm: response.result })
      })
    }
  };
  setTheStateOfFormsAndFields = formAndFields => {
    let formFields = [];
    let fieldsObjects = [];
    formAndFields.forEach(async formField => {
      let fieldName = formField.fieldName;
      if (formField.dataType === "date") {
        let todayDate = formField.fieldValue;
        this.setState({ [fieldName]: todayDate });
      } else if (formField.dataType === "checkbox") {
        this.setState({ [fieldName]: formField.fieldValue });
      } else if (formField.dataType === "search") {
        if (
          formField.prevFieldId !== undefined &&
          formField.prevFieldId !== ""
        ) {
          await this.setState({
            [fieldName]: {
              label: formField.fieldItemName,
              value: formField.prevFieldId
            }
          });
          this.checkGstForCities(formField.prevFieldId);
        } else {
          this.setState({ [fieldName]: "" });
        }
      } else {
        if (
          formField.prevFieldId !== undefined &&
          formField.prevFieldId !== ""
        ) {
          this.setState({ [fieldName]: formField.prevFieldId });
        } else {
          this.setState({ [fieldName]: formField.fieldValue });
        }
        this.enableCgstTOrIgstField(
          formField.fieldName,
          formField.prevFieldId,
          formAndFields
        );
      }
      formFields.push(formField.fieldName);
      fieldsObjects.push(formField);
    });
    this.setState({ isLoading: false });
    this.setState({ formFields: formFields, fieldsObject: fieldsObjects });
  };

  getDataFromCopyDown = async (formField, newConnectedListDate) => {
    let copyDownObject = {
      companyId: this.state.companyId,
      headerId: localStorage.getItem("headerId"),
      fieldId: [formField.id]
    };
    let response = await expenseReport.postGetcopyDownData(copyDownObject);
    if (response && response.result) {
      if (+formField.fieldLevel === 1) {
        let newFieldLevel = formField.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter(
          listData =>
            listData.multiSelectParentField === formField.id &&
            listData.fieldLevel === newFieldLevel
        );
        if (existConnected.length > 0) {
          let companyId = this.state.companyId;
          let responseMult = await expenseReport.getConnectedListData(
            companyId,
            response.result[0].fieldValue
          );
          let childName = existConnected[0].columnName;
          if (responseMult) {
            this.setState({ [childName]: responseMult });
          }
        }
      } else if (formField.fieldLevel >= 2) {
        let newFieldLevel = formField.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter(
          listData =>
            listData.multiSelectParentField ===
            formField.multiSelectParentField &&
            listData.fieldLevel === newFieldLevel
        );
        if (
          existConnected.length > 0 &&
          formField.defaultValue !== undefined &&
          formField.defaultValue !== ""
        ) {
          let companyId = this.state.companyId;
          let responseMult = await expenseReport.getConnectedListData(
            companyId,
            response.result[0].fieldValue
          );
          let childName = existConnected[0].columnName;
          if (responseMult) {
            this.setState({ [childName]: responseMult });
          }
        }
      }
      return response.result[0].fieldValue;
    } else {
      return "";
    }
  };

  //tax calculation start
  checkGstForCities = async cityId => {
    let response = await mileageService.getGstAvailable(cityId);
    if (response) {
      this.setState({ displayTaxFields: true });
    } else {
      this.setState({
        displayCSGST: false,
        displayIGST: false,
        displayTaxFields: false
      });
    }
  };
  enableCgstTOrIgstField = (fieldName, fieldId, entryForm = []) => {
    let fieldsObject = [...this.state.fieldsObject];
    fieldsObject = fieldsObject.length ? fieldsObject : entryForm;
    let fields = fieldsObject.find(field => field.fieldName === fieldName);
    if (fields && fields.columnName === ColumnNames.TAX_TYPES) {
      let selectedTaxType = fields.connectedListData.listManagementData.find(
        listData => listData.id === fieldId
      );
      if (selectedTaxType && selectedTaxType.itemName === ColumnNames.IGST) {
        this.setState({ displayCSGST: false, displayIGST: true });
      } else if (
        selectedTaxType &&
        selectedTaxType.itemName === "CGST & SGST/UGST"
      ) {
        this.setState({ displayCSGST: true, displayIGST: false });
      }
    }
  };

  showCgstIgstExchangeAmountRate = _.debounce((fieldName, fieldId) => {
    let fieldsObject = [...this.state.fieldsObject];
    let fields = fieldsObject.find(
      field => field.fieldName === fieldName && this.state.displayTaxFields
    );
    if (fields && fields.columnName === ColumnNames.TAX_RATE) {
      let taxRate = this.state[ColumnNames.TAX_RATE];
      let amountFields = fieldsObject.find(
        field => field.columnName === ColumnNames.AMOUNT
      );
      const amount = this.state[amountFields.fieldName];
      let rate = taxRate.find(rate => rate.id === fieldId);
      this.setPercentageOfCgstIgst(
        rate ? rate.itemName : 0,
        amount ? amount : 0
      );
    } else if (
      fields &&
      fields.columnName === ColumnNames.AMOUNT &&
      this.state.displayTaxFields
    ) {
      let fields = fieldsObject.find(
        field => field.columnName === ColumnNames.TAX_RATE
      );
      let amountFields = fieldsObject.find(
        field => field.columnName === ColumnNames.AMOUNT
      );
      let taxRate = this.state[ColumnNames.TAX_RATE];
      if (taxRate) {
        const amount = this.state[amountFields.fieldName];
        const taxFieldId = this.state[fields.fieldName];
        let rate =
          taxRate &&
          taxRate.length &&
          taxRate.find(rate => rate.id === taxFieldId);
        this.setPercentageOfCgstIgst(
          rate ? rate.itemName : 0,
          amount ? amount : 0
        );
      }
    }
  }, 250);

  setPercentageOfCgstIgst = async (rate, amount) => {
    if (rate > 0 && amount > 0) {
      let response = await mileageService.getTotalTaxPercentage(rate, amount);
      if (response) {
        let fieldsObject = [...this.state.fieldsObject];
        const igst = fieldsObject.find(
          field => field.columnName === ColumnNames.IGST
        );
        const cgst = fieldsObject.find(
          field => field.columnName === ColumnNames.CGST
        );
        const sgst = fieldsObject.find(
          field => field.columnName === ColumnNames.SGST_UTGST
        );
        if (this.state.displayCSGST) {
          this.setState({
            [cgst.fieldName]: response / 2,
            [sgst.fieldName]: response / 2,
            [igst.fieldName]: 0,
            taxAmount: response
          });
        } else {
          this.setState({
            [igst.fieldName]: response,
            [cgst.fieldName]: 0,
            [sgst.fieldName]: 0,
            taxAmount: response
          });
        }
      }
    }
  };
  //tax calculation end

  //check if any field has default value and that one is parent then check for child field if he has then call api for child
  checkDefaultValueHasChild = async (currentField, newConnectedListDate) => {
    if (+currentField.fieldLevel === 1) {
      let newFieldLevel = currentField.fieldLevel + 1;
      let existConnected = newConnectedListDate.filter(
        listData =>
          listData.multiSelectParentField === currentField.id &&
          listData.fieldLevel === newFieldLevel
      );
      if (existConnected.length > 0) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(
          companyId,
          currentField.defaultValue
        );
        let childName = existConnected[0].columnName;
        if (response) {
          this.setState({ [childName]: response });
        }
      }
    } else {
      let newFieldLevel = currentField.fieldLevel + 1;
      let existConnected = newConnectedListDate.filter(
        listData =>
          listData.multiSelectParentField ===
          currentField.multiSelectParentField &&
          listData.fieldLevel === newFieldLevel
      );
      if (
        existConnected.length > 0 &&
        currentField.defaultValue !== undefined &&
        currentField.defaultValue !== ""
      ) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(
          companyId,
          currentField.defaultValue
        );
        let childName = existConnected[0].columnName;
        if (response) {
          this.setState({ [childName]: response });
        }
      }
    }
  };
  setStateOfFormsAndFields = async (formAndFields, newConnectedListDate) => {
    let formFields = [];
    let fieldsObjects = [];
    let fieldArrayValues = [];
    fieldArrayValues = formAndFields.map(async formField => {
      formFields.push(formField.fieldName);
      fieldsObjects.push(formField);
      let fieldName = formField.fieldName;
      if (formField.dataType === "date") {
        let copyDown = formField.isCopyDown
          ? await this.getDataFromCopyDown(formField, newConnectedListDate)
          : formField.isDefaultValue
            ? moment(formField.defaultValue).format("DD MMM YYYY")
            : "";
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      } else if (formField.dataType === "checkbox") {
        let copyDown = formField.isCopyDown
          ? await this.getDataFromCopyDown(formField, newConnectedListDate)
          : formField.isDefaultValue
            ? formField.defaultValue
            : "";
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      } else {
        let copyDown;
        if (formField.isCopyDown)
          copyDown = await this.getDataFromCopyDown(
            formField,
            newConnectedListDate
          );
        else if (formField.isDefaultValue)
          copyDown = formField.isDefaultValue ? formField.defaultValue : "";
        else copyDown = "";
        formField.fieldLevel >= 1 &&
          !formField.isCopyDown &&
          !formField.isCopyDown &&
          formField.isDefaultValue &&
          this.checkDefaultValueHasChild(formField, newConnectedListDate);
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      }
    });
    let fieldValues = await Promise.all(fieldArrayValues);
    var fieldValueObj = fieldValues.reduce(
      (obj, item) => ((obj[item.key] = item.value), obj),
      {}
    );
    this.setState({
      formFields: formFields,
      fieldsObject: fieldsObjects,
      isLoading: false,
      ...fieldValueObj
    });
  };

  createDropdownForMultiLblConnectedList = newConnectedListDate => {
    console.log(newConnectedListDate)
    newConnectedListDate &&
      newConnectedListDate.forEach(async fields => {
        if (fields.fieldLevel === 1) {
          console.log(fields)
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField === fields.id &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            console.log(response)
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 2) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField ===
              fields.multiSelectParentField &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }
      });
  };

  handleOnRouteAndDeleteLocation = async () => {
    let fieldsObject = [...this.state.fieldsObject];
    let vehicleTypeObj = fieldsObject.find(
      field => field.columnName === ColumnNames.VEHICLE_TYPE
    );
    let currencyField = fieldsObject.find(
      field => field.columnName === ColumnNames.CURRENCY
    );
    let amountField = fieldsObject.find(
      field => field.columnName === ColumnNames.AMOUNT
    );
    let selectedVehicleTypeObj = fieldsObject.find(
      field => field.columnName === ColumnNames.VEHICLE_TYPE
    );

    if (
      vehicleTypeObj.columnName === ColumnNames.VEHICLE_TYPE ||
      currencyField.columnName === ColumnNames.CURRENCY
    ) {
      let currencyFieldName = currencyField.fieldName;
      let selectedCurrency = this.state[currencyFieldName]
        ? this.state[currencyFieldName]
        : "";
      let vehicleFieldName = selectedVehicleTypeObj.fieldName;
      let selectedVehicle = this.state[vehicleFieldName]
        ? this.state[vehicleFieldName]
        : "";
      if (this.state.totalDistanceInKM > 0) {
        let totalAmountPayType = "";
        if (selectedVehicle) {
          totalAmountPayType = await mileageService.getTotalMileageAmount(
            selectedVehicle,
            this.state.totalDistanceInKM,
            selectedCurrency ? selectedCurrency : ""
          );
        } else if (selectedVehicle) {
          totalAmountPayType = await mileageService.getTotalMileageAmount(
            selectedVehicle,
            this.state.totalDistanceInKM,
            selectedCurrency ? selectedCurrency : ""
          );
        }

        if (totalAmountPayType && totalAmountPayType.amount) {
          this.setState({
            [amountField.fieldName]: totalAmountPayType.amount,
            [currencyField.fieldName]: totalAmountPayType.currency
          });
        }
      }
    }
  };

  onClickHandler = async (fieldName, conId, fieldId, fieldLevel, assignKey) => {
    sessionStorage.setItem("formSubmission", "pending")
    this.state.search[assignKey] = '';
    this.setState({ search: this.state.search })

    let fieldsObject = [...this.state.fieldsObject];
    this.enableCgstTOrIgstField(fieldName, fieldId);
    let vehicleTypeObj = fieldsObject.find(field => field.id === conId);

    let currencyField = fieldsObject.find(
      field => field.columnName === ColumnNames.CURRENCY
    );
    let amountField = fieldsObject.find(
      field => field.columnName === ColumnNames.AMOUNT
    );
    let selectedVehicleTypeObj = fieldsObject.find(
      field => field.columnName === ColumnNames.VEHICLE_TYPE
    );
    if (
      vehicleTypeObj.columnName === ColumnNames.VEHICLE_TYPE ||
      currencyField.columnName === ColumnNames.CURRENCY
    ) {
      let currencyFieldName = currencyField.fieldName;
      let selectedCurrency = this.state[currencyFieldName]
        ? this.state[currencyFieldName]
        : "";
      let vehicleFieldName = selectedVehicleTypeObj.fieldName;
      let selectedVehicle = this.state[vehicleFieldName]
        ? this.state[vehicleFieldName]
        : "";
      if (this.state.totalDistanceInKM > 0) {
        let totalAmountPayType = "";
        if (vehicleTypeObj.columnName === ColumnNames.VEHICLE_TYPE && fieldId) {
          totalAmountPayType = await mileageService.getTotalMileageAmount(
            fieldId,
            this.state.totalDistanceInKM,
            selectedCurrency ? selectedCurrency : ""
          );
        } else if (
          selectedVehicle &&
          vehicleTypeObj.columnName === ColumnNames.CURRENCY
        ) {
          totalAmountPayType = await mileageService.getTotalMileageAmount(
            selectedVehicle,
            this.state.totalDistanceInKM,
            fieldId ? fieldId : ""
          );
        }

        if (totalAmountPayType && totalAmountPayType.amount) {
          this.setState({
            [amountField.fieldName]: totalAmountPayType.amount,
            [currencyField.fieldName]: totalAmountPayType.currency
          });
        }
      }

    }
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    sessionStorage.setItem("formSubmission", "pending")
    this.showCgstIgstExchangeAmountRate(fieldName, fieldId);
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onChangeHandler = (event, key) => {
    sessionStorage.setItem("formSubmission", "pending")
    this.setState({ [key]: event.target.value });
  };
  onDatePickerHandler = (value, fieldName) => {
    sessionStorage.setItem("formSubmission", "pending")
    if (!isNaN(value)) {
      this.setState({
        [fieldName]: moment(new Date(value)).format("DD MMM YYYY")
      });
    }
  };
  onCheckboxHandler = fieldName => {
    sessionStorage.setItem("formSubmission", "pending")
    this.setState({ [fieldName]: !this.state[fieldName] });
  };

  onUpdateDistance = _.debounce(async distance => {
    sessionStorage.setItem("formSubmission", "pending")
    this.handleOnRouteAndDeleteLocation();
  }, 450);

  onIntegerHandler = (event, fieldName) => {
    sessionStorage.setItem("formSubmission", "pending")
    let fieldsObject = [...this.state.fieldsObject];
    let BusinessDistance = fieldsObject.find(
      field => field.fieldName === fieldName
    );
    if (
      BusinessDistance &&
      BusinessDistance.columnName === ColumnNames.BUSINESS_DISTANCE
    ) {
      this.setState({ totalDistanceInKM: event.target.value });
      this.onUpdateDistance(event.target.value);
    }
    if (event.target.value >= 0 && !isNaN(event.target.value)) {
      this.setState({ [fieldName]: +event.target.value });
    } else {
      this.setState({ [fieldName]: 0 });
    }
    this.showCgstIgstExchangeAmountRate(fieldName);
  };

  onLocationChangekHandler = async (event, fieldName) => {
    sessionStorage.setItem("formSubmission", "pending")
    let response = await mileageService.getGstAvailable(event.value);
    if (response) {
      this.setState({ displayTaxFields: true });
    } else {
      this.setState({
        displayCSGST: false,
        displayIGST: false,
        displayTaxFields: false
      });
    }
    this.setState({ [fieldName]: event });
  };

  loadOptions = async newValue => {

    return expenseReport.getAllLocationsName(newValue).then(data => {
      return data.result.map(list => {
        return {
          label: list.name + "," + list.countryId.name,
          value: list.id
        };
      });
    });
  };
  amountGreaterThanZeroValidation = amount => {
    return amount > 0 ? true : false;
  };
  onSubmitHandler = async event => {
    if (this.validator.allValid()) {
      this.setState({ isSubmitClick: true });
      let fieldsObjects = this.state.fieldsObject;
      let amountValidation = true;
      let index = 0;
      for (const key of this.state.formFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          fieldsObjects[index].fieldValue = this.state[key]
            ? this.state[key].value
            : "";
          fieldsObjects[index].searchName = this.state[key]
            ? this.state[key].label
            : "";
        } else if (
          fieldsObjects[index].fieldLevel >= 2 &&
          fieldsObjects[index].dataType === "connected_list"
        ) {
          fieldsObjects[index].fieldValue = this.state[key];
          fieldsObjects[index].connectedListData = {};
          fieldsObjects[
            index
          ].connectedListData.listManagementData = this.state[
            fieldsObjects[index].columnName
            ];
        } else if (
          (fieldsObjects[index].dataType === DynamicFields.DATA_TYPE_INTEGER ||
            fieldsObjects[index].dataType === DynamicFields.DATA_TYPE_AMOUNT) &&
          ColumnNames.AMOUNT === fieldsObjects[index].columnName
        ) {
          amountValidation =
            amountValidation &&
            this.amountGreaterThanZeroValidation(this.state[key]);
          fieldsObjects[index].fieldValue = this.state[key];
        } else {
          fieldsObjects[index].fieldValue = this.state[key];
        }
        index++;
      }
      fieldsObjects.forEach((item) => {
        if (item.dataType === "connected_list") {
          if (item.fieldValue) {
            let data = getValue(item, "connectedListData.listManagementData", []).filter(resp => { return resp.id === item.fieldValue });
            setValue(item, "connectedListData.listManagementData", data)
          }
        }
      })
      let wayPoints = [...this.state.wayPoints];
      let newWaypoints = wayPoints.map(point => {
        return {
          fromAddress: point.fromAddress,
          toAddress: point.toAddress,
          fromLat: point.fromLat,
          fromLng: point.fromLng,
          toLat: point.toLat,
          toLng: point.toLng,
          distance: point.distance,
          meters: point.distance * 1000,
          via: point.via,
          type: "km"
        };
      });
      let expenseTypeId = localStorage.getItem("expenseType");
      let headerId = localStorage.getItem("headerId");
      let expenseForm = [...this.state.expenseForm];
      let createExpensePolicy = {
        companyId: this.state.companyId,
        policyId: localStorage.getItem("policyId"),
        headerId: headerId,
        expenseType: expenseTypeId,
        entryForm: fieldsObjects,
        entryFormHeaderId: expenseForm[0].formHeaderId,
        navigationPaths: newWaypoints,
        taxAmount: this.state.taxAmount
      };

      if (amountValidation) {
        let response = await expenseReport.addExpenseType(createExpensePolicy);
        if (
          response.result &&
          response.result.errorMessages &&
          response.result.errorMessages.errorMessages
        ) {
          this.setState({
            isSubmitClick: false,
            auditRuleValidationError:
              response.result.errorMessages.errorMessages
          });
        } else {
          sessionStorage.setItem("formSubmission", "completed")
          this.setState({ isSubmitClick: false });
          localStorage.setItem("expenseEntryId", response.result);
          toast.success(response.message);
          this.props.history.push("./details");
        }
      } else {
        toast.error("Amount should be greater than zero");
        this.setState({ isSubmitClick: false });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleMileageUpdate = async () => {
    if (this.validator.allValid()) {
      this.setState({ isSubmitClick: true });
      let fieldsObjects = this.state.fieldsObject;

      let index = 0;
      for (const key of this.state.formFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          fieldsObjects[index].fieldValue = this.state[key]
            ? this.state[key].value
            : "";
          fieldsObjects[index].searchName = this.state[key]
            ? this.state[key].label
            : "";
        } else if (
          fieldsObjects[index].fieldLevel >= 2 &&
          fieldsObjects[index].dataType === "connected_list"
        ) {
          fieldsObjects[index].fieldValue = this.state[key];
          fieldsObjects[index].connectedListData = {};
          fieldsObjects[
            index
          ].connectedListData.listManagementData = this.state[
            fieldsObjects[index].columnName
            ];
        } else {
          fieldsObjects[index].fieldValue = this.state[key];
        }
        index++;
      }

      fieldsObjects.forEach((item) => {
        if (item.dataType === "connected_list") {
          if (item.fieldValue && getValue(item, "connectedListData.listManagementData", [])) {
            let data = getValue(item, "connectedListData.listManagementData", []).filter(resp => { return resp.id === item.fieldValue });
            setValue(item, "connectedListData.listManagementData", data)
          }
        }
      })
      let wayPoints = [...this.state.wayPoints];
      let newWaypoints = wayPoints.map(point => {
        return {
          fromAddress: point.fromAddress,
          toAddress: point.toAddress,
          fromLat: point.fromLat,
          fromLng: point.fromLng,
          toLat: point.toLat,
          toLng: point.toLng,
          distance: point.distance,
          meters: point.distance * 1000,
          via: point.via,
          type: "km"
        };
      });
      let expenseEntryId = localStorage.getItem("expenseEntryId");
      let editEntryForm = [...this.state.expenseForm];
      let updateEntryForm = {
        id: expenseEntryId,
        policyId: localStorage.getItem("policyId"),
        entryForm: fieldsObjects,
        entryFormHeaderId: editEntryForm[0].formHeaderId,
        navigationPaths: newWaypoints,
        taxAmount: this.state.taxAmount
      };
      let response = await expenseReport.updateExpenseEntryForm(
        updateEntryForm
      );
      if (
        response.result &&
        response.result.errorMessages &&
        response.result.errorMessages.errorMessages
      ) {
        this.setState({
          submitButtonStatus: false,
          auditRuleValidationError: response.result.errorMessages.errorMessages,
          isSubmitClick: false
        });
      } else {
        sessionStorage.setItem("formSubmission", "completed")
        this.setState({ isSubmitClick: false });
        let prevPage = sessionStorage.getItem("from");
        let headerId = this.props.match.params.expenseId;
        if (prevPage === "approval")
          this.props.history.push(`../details/approval/${headerId}`);
        else if (prevPage === "finance")
          this.props.history.push(
            `../../expense/details/fincapproval/${headerId}`
          );
        else if (headerId && prevPage === "Expense")
          this.props.history.push(`../details`);
        else {
          if (headerId) this.props.history.push(`../details`);
          else this.props.history.push(`../details`);
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  onCancelClickHandler = () => {
    let prevPage = sessionStorage.getItem("from");
    let headerId = this.props.match.params.expenseId;
    if (prevPage === "approval")
      this.props.history.push(`../details/approval/${headerId}`);
    else if (prevPage === "finance")
      this.props.history.push(`../../expense/details/fincapproval/${headerId}`);
    else if (headerId && prevPage === "Expense") {
      if (headerId) this.props.history.push(`../details`);
      else this.props.history.push(`./details`);
    } else {
      this.props.history.push(`./details`);
    }
  };

  handleScroll = async (e, id, assignKey, page, count, totalLength, search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    setValue(this.state, "clientHeight", e.target.scrollHeight)
    if (bottom) {
      this.setState({
        already: true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
      })
      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {
          let mergeOld = getValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", []).concat(resp.result)
          setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", mergeOld)
          setValue(this.state.expenseForm[assignKey], "page", page + 1)
          this.setState({
            ...this.state
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }
    }
  }
  handleSearchManage = async (search, id, assignKey) => {

    this.state.loading[assignKey] = true;
    this.state.search[assignKey] = search;
    this.setState({ search: this.state.search }, () => {
      $(`#valueConnect${assignKey}`).focus();
    })
    let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
    if (resp) {
      setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", resp.result)
    } else {
      setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", [])
    }
    setValue(this.state.expenseForm[assignKey], "search", search)
    this.state.loading[assignKey] = false;
    this.setState({
      ...this.state
    })
    $(`#valueConnect${assignKey}`).focus();
    $(`#valueConnect${assignKey}`).attr('value', search);
    $(`#connect${assignKey}`).addClass("show")

  }
  render() {
    return (
      <div>
        <LeftSidebar
          currentPage={this.state.pageName}
          expenseEntryList={this.state.expenseEntryList}
          totoalExpense={this.state.totoalExpense}
          cashAdvanceTotalAmount={this.state.cashAdvanceTotalAmount}
          userBaseCurrency={this.state.userBaseCurrency}
          totalExpenseAmount={this.state.totalExpenseAmount}
          totalRequestAmount={this.state.totalRequestAmount}
          validationStatus={this.state.validationStatus}
          openAuditTrail={this.openAuditTrail}
          selectExpenstEntryList={localStorage.getItem("expenseEntryId")}
        />{" "}
        <div className="expense-right">
          <div className="p-padding">
            <div id="expense-report">
              <div className="white-card report">
                <div className="grey-head border-bottom">
                  <h6 className="sub-heading pt-0">Add Mileage</h6>
                </div>
                {!this.state.isLoading ? (
                  <>
                    <WayPoints
                      wayPoints={this.state.wayPoints}
                      totalDistanceInKM={this.state.totalDistanceInKM}
                      handleFromChange={this.handleFromChange}
                      handleToChange={this.handleToChange}
                      handleFromSelect={this.handleFromSelect}
                      handleToSelect={this.handleToSelect}
                      handleAddLocation={this.handleAddLocation}
                      handleDeleteLocation={this.handleDeleteLocation}
                      handleOnDragEnd={this.handleOnDragEnd}
                      handleSelectRoute={this.handleSelectRoute}
                    />

                    {/* <div className="expense-right p-padding"> */}

                    <div>
                      <div className="white-card p-padding animation-target-left">
                        <h5 className="sub-heading" id="expenseTypeLabel">
                          Create New Expense (
                          {localStorage.getItem("expenseTypeName")})
                        </h5>
                        <div className="">
                          <>
                            <div className="row">
                              {/**create add expense form dynamically */}
                              <ExpenseCreateForm
                                {...this.state}
                                validator={this.validator}
                                policyForm={this.state.expenseForm}
                                onClickHandler={this.onClickHandler}
                                onChangeHandler={this.onChangeHandler}
                                onDatePickerHandler={this.onDatePickerHandler}
                                onCheckboxHandler={this.onCheckboxHandler}
                                onIntegerHandler={this.onIntegerHandler}
                                userPermission={this.state.userPermission}
                                onConnectedListkHandler={
                                  this.onConnectedListkHandler
                                }
                                onLocationChangekHandler={
                                  this.onLocationChangekHandler
                                }
                                loadOptions={this.loadOptions}
                                handleScroll={this.handleScroll}
                                handleSearchManage={this.handleSearchManage}
                              />
                            </div>
                            {this.state.auditRuleValidationError &&
                              this.state.auditRuleValidationError.map(
                                (validationError, index) => (
                                  <p className="text-danger" key={index}>
                                    {validationError}
                                  </p>
                                )
                              )}
                            <div className="modal-last text-left btn-group-margin">
                              <button
                                onClick={this.onCancelClickHandler}
                                type="button"
                                className="btn"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              {!this.state.isSubmitClick ? (
                                !this.state.isMileageEdit ? (
                                  <button
                                    onClick={this.onSubmitHandler}
                                    type="button"
                                    disabled={
                                      this.state.expenseForm &&
                                      this.state.expenseForm.length <= 0
                                    }
                                    className="btn"
                                    data-dismiss="modal"
                                  >
                                    Add
                                  </button>
                                ) : (
                                    <button
                                      onClick={this.handleMileageUpdate}
                                      type="button"
                                      disabled={
                                        this.state.expenseForm &&
                                        this.state.expenseForm.length <= 0
                                      }
                                      className="btn"
                                      data-dismiss="modal"
                                    >
                                      Update
                                    </button>
                                  )
                              ) : (
                                  <button
                                    type="button"
                                    className="btn"
                                    data-dismiss="modal"
                                  >
                                    Please Wait...
                                  </button>
                                )}
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                    <Loader />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddMilagePage;
