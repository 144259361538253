import React from "react";
const HotelDiscountsPage = props => {
  const { OnCancelBtnHandler } = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">R</div>
          <div className="right">
            <h6 className="heading">Add Hotel Discounts</h6>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group custom">
              <input className="form-control" placeholder="&nbsp;" />
              <label>City/State</label>
            </div>
            <div className="form-group custom labelup">
              <label>Vendor <span className="text-danger">*</span></label>
              <div className="dropdown">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search.."
                  data-toggle="dropdown"
                />
                <div className="dropdown-menu">
                  <button className="dropdown-item">Code 1</button>
                  <button className="dropdown-item">Code 1</button>
                  <button className="dropdown-item">Code 1</button>
                </div>
              </div>
            </div>
            <div className="form-group custom">
              <input className="form-control" placeholder="&nbsp;" />
              <label>Discount Code <span className="text-danger">*</span></label>
            </div>
            <div>
              <div className="btn-group-margin">
                <button className="btn">
                  Save
                </button>
                <button
                  className="btn"
                  onClick={OnCancelBtnHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HotelDiscountsPage;
