import React, { Component } from "react";
import ExceptionPage from "./exception-html";
import { expensePolicies } from "../../../../../_services";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import $ from 'jquery';
import { checkValidityBeforeSubmit } from "src/utils/util";
class Exception extends Component {
  state = {
    limit: 10,
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,
    companyId: localStorage["440a28"],
    exceptions: [],
    exceptionCode: null,
    exceptionLevel: null,
    editableBy: [],
    editableId: [],
    message: null,
    selectedException: [],
    parentList: [],
    searchInput: "",
    isClickSubmit:false
  };

  validator = new SimpleReactValidator();
  ModifyValidator = new SimpleReactValidator();
  componentDidMount() {
    this.getExceptions();
    this.getParentList();
  }
  // ----------------get exceptions------------------------
  getExceptions = async () => {
    if (!this.state.searchInput) {
      try {
        const response = await expensePolicies.getExceptions(
          this.state.companyId,
          this.state.limit,
          this.state.activePage
        );
        if(response && response.result){
          this.setState({
            exceptions: response.result,
            totalItemsCount: response.count
          });
        }else{
          this.setState({
            exceptions: [],
            totalItemsCount: 0
          });
        }

      } catch (error) {
      }
    } else {
      try {
        const searchResponse = await expensePolicies.searchException(
          this.state.searchInput,
          this.state.companyId,
          this.state.itemPerPage,
          this.state.activePage
        );
        if (searchResponse.result) {
          this.setState({
            exceptions: searchResponse.result,
            totalItemsCount: searchResponse.count
          });
        } else {
          this.setState({
            exceptions: []
          });
        }
      } catch (error) {
      }
    }
  };
  // -------------------------------------------------------------------

  // ----------------------------Search Hanlder-------------------------
  onSearchHandler = e => {
    let searchInput = e.target.value;
    this.setState(
      {
        searchInput
      },
      async () => {
        if (!searchInput.length) {
          this.setState({ itemsCountPerPage: 10 }, () => {
            this.getExceptions();
          });
        } else {
          try {
            const searchResult = await expensePolicies.searchException(
              this.state.searchInput,
              this.state.companyId,
              this.state.itemPerPage,
              this.state.activePage
            );
            if (searchResult.result) {
              this.setState({
                exceptions: searchResult.result,
                totalItemsCount: searchResult.count
              });
            } else {
              this.setState({
                exceptions: []
              });
            }
          } catch (error) {
          }
        }
      }
    );
  };

  // -------------------------------------------------------------------

  // -------------------create Exceptions ----------------------
  createException = async () => {
    if (this.validator.allValid()) {

      let data = {
        companyId: this.state.companyId,
        exceptionCode: this.state.exceptionCode,
        exceptionLevel: Number(this.state.exceptionLevel),
        editableBy: this.state.editableId.map((editedBy)=>editedBy.value),
        message: this.state.message
      };

      if(checkValidityBeforeSubmit(data)){
        this.setState({isClickSubmit:true});
        try {
          let response = await expensePolicies.createException(data);
          if(response){
           $('.close').click()
             this.getExceptions();
             toast.success(response.message);
             this.setState({
               exceptions: [],
               exceptionCode: '',
               exceptionLevel: '',
               editableBy: [],
               editableId:[],
               message: null,
               isClickSubmit:false
             });
          }else{
           this.setState({isClickSubmit:false});
          }
         } catch (error) {
         }
         $('.close').click();
      }else{
        toast.warn('All the fields are mandatory');
      }


    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };
  // ------------------------------------------------------------
  checkArray = (data) => {
    return data && Array.isArray(data) && data.length>0;
}
  // ---------------get Exception--------------------------
  getSingleException = async () => {
    let exceptionId = this.state.selectedException[0].id;
    try {
      let exception = await expensePolicies.getSingleException(
        exceptionId,
        this.state.companyId
      );
      if(this.checkArray(exception.result.editableBy)){
        let editByArray = [];
        exception.result.editableBy.forEach((editId)=>{
         let editBy = this.state.parentList.find((edit)=>edit.id===editId);
         editBy && editByArray.push({label:editBy.itemName,value:editBy.id});
        })
        this.setState({
          exceptionCode: exception.result.exceptionCode,
          exceptionLevel: exception.result.exceptionLevel,
          editableId:editByArray,
          message: exception.result.message
        });
      }else{
        this.setState({
          exceptionCode: '',
          exceptionLevel: '',
          editableId:[],
          message: exception.result.message
        });
      }
    } catch (error) {
    }
  };

  // ------------------------------------------------------------

  // -------------------Delete Exceptions ----------------------
  deleteException = async exception => {
    let id = exception[0].id;
    let data = {
      id: [id]
    };
    try {
     let response = await expensePolicies.deleteException(this.state.companyId, data);
     if(response){
       this.setState({selectedException:[]})
        toast.success(response.message);
        this.getExceptions();
     }
    } catch (error) {
    }
  };

  // ------------------------------------------------------------

  // ---------------------modify Exception---------------------------
  modifyException = async () => {
    if (this.ModifyValidator.allValid()) {
      let data = {
        companyId: this.state.companyId,
        id: this.state.selectedException[0].id,
        exceptionCode: this.state.exceptionCode,
        exceptionLevel: Number(this.state.exceptionLevel),
        editableBy: this.state.editableId.map((editedBy)=>editedBy.value),
        message: this.state.message
      };

      if(checkValidityBeforeSubmit(data)){
        this.setState({isClickSubmit:true});
        try {
          let response = await expensePolicies.modifyException(data);
          if(response){
           toast.success(response.message);
           this.setState({
             exceptions: [],
             exceptionCode: '',
             exceptionLevel: '',
             editableBy: [],
             message: null,
             isClickSubmit:false
           });
           $('.close').click();
           this.getExceptions();
          }else  this.setState({isClickSubmit:false});

         } catch (err) {
           this.setState({isClickSubmit:false});
         }
      }else{
        toast.warn('Kindly fill all the fields');
      }


    } else {
      this.ModifyValidator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };
  // ------------------------------------------------------------
  // ------------------Get the editable list-----------------------
  getParentList = async () => {
    try {
      const parent = await expensePolicies.parentList(this.state.companyId);
      if(parent && parent.result.length>0) this.setState({ parentList:parent.result });
      else this.setState({ parentList: [] });
    } catch (err) {
    }
  };

  // -----------------Form Handlers-----------------------
  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleEditableBy = editable => {
    this.setState({ editableId: editable });
  };
  // ------------------------------------------------------------

  // --------------Pagination--------------------------------

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, () => {
      this.getExceptions();
    });
  };
  handleItemChange = limit => {
    this.setState({ itemPerPage: limit }, () => {
      this.getExceptions();
    });
  };
  // ------------------------------------------------------------

  // -----------------on click select Policies-----------------------

  toggleExceptionSelect = exception => {
    let id = exception.id;
    let itemSelected = [...this.state.selectedException];
    let indexSelected = itemSelected.indexOf(exception);
    if (indexSelected === -1) {
      itemSelected = [exception];
    } else {
      itemSelected = [];
    }
    this.setState({ selectedException: itemSelected });
  };
  // ------------------------------------------------------------

  handleModalCancel = () => {
    this.setState({
      selectedException: [],
      exceptionCode: null,
      exceptionLevel: null,
      editableId: [],
      message: null,
    });
  }


  render() {
    return (
      <div>
        <ExceptionPage
          validator={this.validator}
          ModifyValidator={this.ModifyValidator}
          exceptionsData={this.state}
          isClickSubmit={this.state.isClickSubmit}
          editableId={this.state.editableId}
          createException={this.createException}
          onSearchHandler={this.onSearchHandler}
          modifyException={this.modifyException}
          getSingleException={this.getSingleException}
          toggleExceptionSelect={this.toggleExceptionSelect}
          handlePageChange={this.handlePageChange}
          handleItemChange={this.handleItemChange}
          deleteException={this.deleteException}
          handleEditableBy={this.handleEditableBy}
          formNameHandler={this.formNameHandler}
          onModalCancel={this.handleModalCancel}
        />
      </div>
    );
  }
}
export default Exception;
