import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import CashAdvanceEditData from '../../cash-advance/component/editCashadvanceFormModal';

const cashAdvanceModal = (props) => {
    let editableOrNot = false;
    if (!Array.isArray(props.cashAdvanceEditFormData)) {
        var data = props.cashAdvanceEditFormData.CASH_DATA;
        var cashAdvNameIndex, purposeIndex, requestedId, createdAt, tHead, columnNames, formAndFields, userFirstNameIdx, userMiddleNameIdx, userLastNameIdx, cashAdvanceId, userFullName;
        cashAdvanceId = props.cashAdvanceEditFormData && props.cashAdvanceEditFormData.current && props.cashAdvanceEditFormData.current.cashAdvanceId;
        formAndFields = props.cashAdvanceEditFormData.CASH_DATA.data;
        createdAt = data.createdAt;
        tHead = formAndFields.map((fields) => { if (fields.activeStatus) return fields.labelName });
        columnNames = formAndFields.map((fields) => fields.columnName);
        cashAdvNameIndex = columnNames.indexOf("CASH_ADVANCE_NAME");
        purposeIndex = columnNames.indexOf('COMMENTS');
        requestedId = data.requestId;
        userFirstNameIdx = data.userDeatils.fieldData.findIndex((fields) => fields.columnName === "EMPLOYEE_FIRST_NAME");
        userMiddleNameIdx = data.userDeatils.fieldData.findIndex((fields) => fields.columnName === "MIDDLE_NAME");
        userLastNameIdx = data.userDeatils.fieldData.findIndex((fields) => fields.columnName === "EMPLOYEE_LAST_NAME");
        userFullName = data.userDeatils.fieldData[userFirstNameIdx].fieldValue + ' ' + data.userDeatils.fieldData[userMiddleNameIdx].fieldValue + ' ' + data.userDeatils.fieldData[userLastNameIdx].fieldValue;
         editableOrNot = props.cashAdvanceEditFormData.current&&props.cashAdvanceEditFormData.current.actionButton&&props.cashAdvanceEditFormData.current.actionButton.length;
    }

    return (
        <div
            className="modal fade"
            id="newcashAdvanceModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="newcashAdvancLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                {!Array.isArray(props.cashAdvanceEditFormData) &&
                    <div className="modal-content">
                        <div className="modal-head">
                            <h6 className="modal-subtitle">New</h6>
                            <h5 className="modal-title" id="newcashAdvancLabel">
                                Cash Advance
                       </h5>
                        </div>
                        <div className="modal-middle pm-30 pb-0">
                            <div className="modal-scroll">
                                <div className="row">
                                    <div className="col-md-4 mb-4">
                                        <p className="lighter-p mb-1">Cash Advance ID</p>
                                        <p className="fw-sbold mb-0 border-bottom-light pb-4">{cashAdvanceId && cashAdvanceId}</p>
                                    </div>
                                    {/* <div className="col-md-4 mb-4">
                                        <p className="lighter-p mb-1">Cash Advance Name</p>
                                        <p className="fw-sbold mb-0 border-bottom-light pb-4">{cashAdvNameIndex >= 0 && formAndFields[cashAdvNameIndex].fieldValue}</p>
                                    </div> */}
                                    <div className="col-md-4 mb-4">
                                        <p className="lighter-p mb-1">Request ID</p>
                                        <p className="fw-sbold mb-0 border-bottom-light pb-4">{requestedId ? requestedId : '-'}</p>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <p className="lighter-p mb-1">Request Date</p>
                                        <p className="fw-sbold mb-0 border-bottom-light pb-4">{moment(createdAt).format('DD MMM YYYY')}</p>
                                    </div>
                                    {/* <div className="col-md-4 mb-4">
                                        <p className="lighter-p mb-1">Cash Advance Purpose</p>
                                        <p className="fw-sbold mb-0 border-bottom-light pb-4">{purposeIndex >= 0 ? formAndFields[purposeIndex].fieldValue.length > 0 ? formAndFields[purposeIndex].fieldValue.length : '-' : '-'}</p>
                                    </div> */}
                                    <div className="col-md-4 mb-4">
                                        <p className="lighter-p mb-1 ">Employee Name</p>
                                        <p className="fw-sbold mb-0 border-bottom-light pb-4">{userFullName && userFullName}</p>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <p className="lighter-p mb-1">Employee Email</p>
                                        <p className="fw-sbold mb-0 border-bottom-light pb-4">{data.userDeatils.email}</p>
                                    </div>
                                </div>
                                <div className="py-3">
                                    <div className="row">
                                        <CashAdvanceEditData
                                            {...props}
                                            cashAdvanceField={formAndFields}
                                            validator={props.validator}
                                            userPermission={props.userPermission}
                                            onClickCancelHandler={props.onClickCancelHandler}
                                            onClickHandler={props.onClickHandler}
                                            onConnectedListkHandler={props.onConnectedListkHandler}
                                            onChangeHandler={props.onChangeHandler}
                                            onDatePickerHandler={props.onDatePickerHandler}
                                            onCheckboxHandler={props.onCheckboxHandler}
                                            onIntegerHandler={props.onIntegerHandler}
                                            onLocationChangekHandler={props.onLocationChangekHandler}
                                            loadOptions={props.loadOptions}
                                            editableOrNot={editableOrNot}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-last btn-group-margin pm-30">
                            {props.cashAdvanceEditFormData.current.actionButton && props.cashAdvanceEditFormData.current.actionButton.map((action) =>
                                <button key={action.key}
                                    onClick={() => props.onCashAdvanceSelectActionHandler(action.key, props.cashAdvanceEditFormData.id, action.button, 'modal',props.selectedCashAdvanceId)}
                                    className={action.key === 'APPROVE'
                                        ? "btn btn-approve mb-1"
                                        : action.key === 'SEND_BACK_EMPLOYEE'
                                            ? "btn btn-sendback mb-1"
                                            : action.key === 'REJECT'
                                                ? "btn btn-reject mb-1"
                                                : "btn btn-grey1 mb-1"
                                    }
                                >
                                    {action.key === 'APPROVE'
                                        ? <i className="material-icons align-middle">check_circle_outline </i>
                                        : action.key === 'SEND_BACK_EMPLOYEE'
                                            ? <i className="material-icons align-middle">arrow_back  </i>
                                            : action.key === 'REJECT'
                                                ? <i className="material-icons align-middle">not_interested  </i>
                                                : <i className="material-icons align-middle">add  </i>
                                    }
                                    {action.button}
                                </button>
                            )}
                            <button data-toggle="modal" data-target="#submitModalRequest" className="open d-none"></button>
                            <button className="close d-none" data-dismiss="modal"></button>
                        </div>
                    </div>
                }
            </div>
        </div>)
}

cashAdvanceModal.propTypes = {
    cashAdvanceEditFormData: PropTypes.object
}

export default cashAdvanceModal;
