import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from 'jquery';
import NumberFormat from 'react-number-format';
class CartPage extends Component {
  closeCart() {
    var cart = document.getElementById("cart");
    cart.classList.remove("show");
  }
  openFilter() {
    $("#filter-card").addClass("show");
  }
  render() {
    const { cardList, currency, typeOfService, hotelType, cartCount } = this.props;
    return (
      <div id="cart" className="cart">
        <div className="cart-head">
          <button
            type="button"
            onClick={e => this.closeCart()}
            className="btn btn-nothing close close_center"
          >
            <i className="material-icons">
              close
            </i>
          </button>
          <h5 className="pt-1">Your Cart</h5>
          <div className="clearfix" />
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Services</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="shape-name">
                    <div className="left">
                      <img
                        src="/assets/images/booking/flight_booking.svg"
                        alt=""
                        width="50"
                        height="50"
                      />
                    </div>
                    <div className="right">
                      <h6 className="heading">Flight
                      {/* <i className="material-icons bg-for-icon" href="#" id="TooltipExample">
                        info
                      </i>
                        <span className="hover-name">The cart will show the highest price among the different flights selected for the same destination.</span> */}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  {(cardList.flightData === 0) ?
                    <div>
                      {(this.props.typeOfService !== "flight") ?
                        <div>
                          {/* <p className="mb-1">No Flight Added</p> */}
                          <button className="btn"
                            data-target="#addServiceModal"
                            data-toggle="modal"
                          >Add Flight</button>
                        </div>
                        :
                        <div>{currency}{" "}
                          <NumberFormat value={cardList.flightData && cardList.flightData.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </div>
                      }
                    </div>
                    :
                    <div>{currency}{" "}
                      <NumberFormat value={cardList.flightData && cardList.flightData.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                    </div>
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <div className="shape-name">
                    <div className="left">
                      <img
                        src="/assets/images/booking/hotel_booking.svg"
                        alt=""
                        className="img-fluid"
                        width="50"
                        height="50"
                      />
                    </div>
                    <div className="right">
                      <h6 className="heading">Hotel
                      {/* <i className="material-icons bg-for-icon" href="#" id="TooltipExample">
                          info
                      </i>
                        <span className="hover-name">The cart will show the highest price among the different flights selected for the same destination.</span> */}
                      </h6>
                    </div>
                  </div>
                </td>
                <td>
                  {(cardList.hotelData === 0) ?
                    <div>
                      {(this.props.typeOfService !== "hotel") ?
                        <div>
                          {/* <p className="mb-1">No Hotel Added</p> */}
                          <button className="btn"
                            data-target="#addServiceModal"
                            data-toggle="modal"
                          >Add Hotel</button>
                        </div>
                        : <div>{currency}{" "}
                          <NumberFormat value={cardList.hotelData && cardList.hotelData.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </div>}
                    </div>
                    : <div>
                      {currency}{" "}
                      <NumberFormat value={cardList.hotelData && cardList.hotelData.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                    </div>
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <div className="shape-name">
                    <div className="left">
                      <img
                        src="/assets/images/booking/car_booking.svg"
                        alt=""
                        className="img-fluid"
                        width="50"
                        height="50"
                      />
                    </div>
                    <div className="right">
                      <h6 className="heading">Cab
                      {/* <i className="material-icons bg-for-icon" href="#" id="TooltipExample">
                          info
                      </i>
                        <span className="hover-name">The cart will show the highest price among the different flights selected for the same destination.</span> */}
                      </h6>
                    </div>
                  </div>
                </td>
                <td>
                  {(cardList.carData === 0) ?
                    <div>
                      {(this.props.typeOfService !== "cab") ?
                        <div>
                          {/* <p className="mb-1">No Hotel Added</p> */}
                          <button className="btn"
                            data-target="#addServiceModal"
                            data-toggle="modal"
                          >Add Cab</button>
                        </div>
                        : <div>{currency}{" "}
                          <NumberFormat value={cardList.carData && cardList.carData.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </div>}
                    </div>
                    : <div>
                      {currency}{" "}
                      <NumberFormat value={cardList.carData && cardList.carData.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                    </div>
                  }
                </td>
              </tr>
              {/* <tr>
                <td>
                  <div className="shape-name">
                    <div className="left">
                      <img
                        src="/assets/images/booking/bus_booking.svg"
                        alt=""
                        className="img-fluid"
                        width="50"
                        height="50"
                      />
                    </div>
                    <div className="right">
                      <h6 className="heading">Bus
                      //comment this afetr uncommenting this tr
                      <i className="material-icons bg-for-icon" href="#" id="TooltipExample">
                          info
                      </i>
                        <span className="hover-name">The cart will show the highest price among the different flights selected for the same destination.</span>
                        //comment ends
                      </h6>
                    </div>
                  </div>
                </td>
                <td>{currency}{" "}0</td>
              </tr> */}
              <tr>
                <td>
                  <div className="shape-name">
                    <div className="left">
                      <img
                        src="/assets/images/booking/train_booking.svg"
                        alt=""
                        className="img-fluid"
                        width="50"
                        height="50"
                      />
                    </div>
                    <div className="right">
                      <h6 className="heading">Train
                      </h6>
                    </div>
                  </div>
                </td>
                <td>
                  {(cardList.trainData === 0) ?
                    <div>
                      {(this.props.typeOfService !== "train") ?
                        <div>
                          {/* <p className="mb-1">No Hotel Added</p> */}
                          <button className="btn"
                            data-target="#addServiceModal"
                            data-toggle="modal"
                          >Add Train</button>
                        </div>
                        : <div>{currency}{" "}
                          <NumberFormat value={cardList.trainData && cardList.trainData.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </div>}
                    </div>
                    : <div>
                      {currency}{" "}
                      <NumberFormat value={cardList.trainData && cardList.trainData.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                    </div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="abs-bottom">
          <div className="total">
            <div className="row">
              <div className="col-6">Total</div>
              <div className="col-6 text-right">{currency}{" "}
                <NumberFormat value={cardList.totalPrice && cardList.totalPrice.toFixed(2)} displayType={'text'} thousandSeparator={true} />

              </div>
            </div>
          </div>
          {(hotelType === "GH" && cartCount > 0) ? (<NavLink
            to="/booking/checkout/itinerary"
            className="btn btn-block"
          >
            Go to REQUEST
          </NavLink>) : (cardList.totalPrice !== 0 && cardList.selectFlight) &&
            <NavLink
              to="/booking/checkout/itinerary"
              className="btn btn-block"
            >
              Go to REQUEST
          </NavLink>
          }
        </div>
      </div>
    );
  }
}
export default CartPage;
