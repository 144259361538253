import PropTypes from "prop-types";
import { forwardRef } from "react";
import classNames from "classnames";

let TripBarItemFooter = forwardRef(
  ({ as = "p", tag: Tag = as, className, children, ...props }, ref) => (
    <Tag
      className={classNames("bottom", "toolbarItem-footer", className)}
      ref={ref}
      {...props}
    >
      {children}
    </Tag>
  )
);

TripBarItemFooter.propTypes = {
  as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  className: PropTypes.string,
  children: PropTypes.any
};

export { TripBarItemFooter };
