/* eslint-disable default-case */
// import pMap from 'p-map';
import React, { Component } from "react";
import RuleTemplatePage from "./rule-template-html";
import { travelRuleTemplateServices } from "../../../../../../_services/travel-rule-template.services";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../../_constants/alert.constants";
import $ from 'jquery';
import { getDataWithActiveStatus, executePromisesConcurrently } from "src/utils/util";
import travelFieldConstants from "src/_components/common/travelFieldConstants";

class RuleTemplate extends Component {
  state = {
    companyId: localStorage['440a28'],
    allTemplateCategories: [],
    displayedRuleTemplates: [],
    selectedCategory: "",
    selectedRuleTemplates: [],
    itemsCountPerPage: 10,
    activePage: 1,
    searchInput:'',
    totalItemsCount: 0,
    ruleTemplateCheckboxes: [],
    violationCode: '',
    ruleTemplateName: '',
    ruleTemplateId: '',
    allConditions: false,
    anyCondition: true,
    conditionsId: [],
    editedConditionsData: '',
    selectedCheckboxes: [],
    fieldsToBeRendered: [],
    fieldsData: [],

    // * New travel rule template state
    availableRulesinCategory: [],
    differentListId: null,
    differentListName: null,
    isEdit: false,
    isLoading: true
  };
  validator = new SimpleReactValidator();

  componentWillMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }



  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    await this.getTravelCategories();
    if(this.mounted){

      if (!this.state.selectedCategory) {
        await this.setState( { selectedCategory: this.state.allTemplateCategories[0] },
          () => this.getTravelRulesByTemplateCategory(this.state.selectedCategory)
          );
          this.getRuleTemplatesByCategories();
        }
      }
  }

  getTravelCategories = async () => {
    let res = await travelRuleTemplateServices.getTemplateCategories();
    if(res != undefined && res.result){
      await this.setState({
        allTemplateCategories: getDataWithActiveStatus(res.result)
      });
    }
  }

  getRuleTemplatesByCategories = async () => {
    this.setState({
      displayedRuleTemplates:[],
      totalItemsCount:0
    })
    let res = await travelRuleTemplateServices.getRuleTemplatesByCategories(this.state.companyId, this.state.selectedCategory.id,this.state.activePage,this.state.itemsCountPerPage,this.state.searchInput);
    if(res != undefined && res.result){
      this.setState({
        displayedRuleTemplates: res.result,
        totalItemsCount: res.count,
      });
    }
  }

  // * on template category change get, already set rule templates and the rules.

  changeTemplateCategory = async (e) => {
    await this.setState({
      selectedCategory: e
    });
    this.getTravelRulesByTemplateCategory(this.state.selectedCategory);
    this.getRuleTemplatesByCategories();
  };

  // *  on add rule template, get the options

  getTravelRulesByTemplateCategory = async (selectedCategory) => {
    if (!this.state.searchInput && selectedCategory?.id) {
      this.setState({isLoading: true});
      let res = await travelRuleTemplateServices.getTravelRulesByTemplateCategory(selectedCategory.id)
      if(res) {
        if(res != null && res.result){
          this.setState({
            isLoading: false,
            fieldsToBeRendered:[],
            availableRulesinCategory: res.result,
            selectedRuleTemplates: [] // * if a different category is loaded then all previously selected items get deselected
          });
        }else{
          this.setState({
            isLoading: false
          })
        }
      }
      this.setState({
        isLoading: false
      })
    } else {
      // if there is text in the search box, get all the rule templates matching the search
      this.setState({isLoading: true})
      const {companyId,searchInput,itemsCountPerPage,activePage} = this.state;
      let res = await travelRuleTemplateServices.searchTravelRule(companyId,searchInput,itemsCountPerPage,activePage)
      if(res){
        if ( res != null && res.result.length) {
          this.setState({
            isLoading: false,
            displayedRuleTemplates: res.result,
            totalItemsCount: res.count
          });
        } else {
          this.setState({
            isLoading: false,
            displayedRuleTemplates: []
          });
        }
      }
      this.setState({
        isLoading: false
      })
    }
  };

  toggleRuleTemplate = async (ruleTemplate) => {
    let newStatus = !ruleTemplate.activeStatus;
    let payload = {
      id: ruleTemplate.id,
      activeStatus: newStatus
    };
    var res = await travelRuleTemplateServices.activeStatusTravelRule(this.state.companyId, payload)
    if(res){
      this.getTravelRulesByTemplateCategory(this.state.selectedCategory);
      toast.success(alertConstants.UPDATED_SUCCESSFULLY);
    }
  };

  selectRuleTemplateHandler = ruleTemplate => {
    let selectedRuleTemplates = [...this.state.selectedRuleTemplates];
    let selectedRuleTemplateIndex = selectedRuleTemplates.indexOf(ruleTemplate);
    if (selectedRuleTemplateIndex === -1) {
      selectedRuleTemplates.push(ruleTemplate);
    } else {
      selectedRuleTemplates.splice(selectedRuleTemplateIndex, 1);
    }
    this.setState({ selectedRuleTemplates });
  };

  // for deletion has to be checked again as new api hasnt been updated to the server

  deleteRuleTemplateHandler = async (e) => {
    e.preventDefault();
    let idsToBeDeleted = this.state.selectedRuleTemplates.map(
      ruleTemplate => ruleTemplate.id
    );
    let payload = {
      id: idsToBeDeleted
    };
    let resp = await travelRuleTemplateServices.deleteTravelRules(payload)
    if(resp){
      this.getTravelRulesByTemplateCategory(this.state.selectedCategory);
      this.getRuleTemplatesByCategories(this.state.selectedCategory);
      this.setState({
        fieldsToBeRendered: [],
        selectedCheckboxes: []
      })
    }
  };

  pageChangeHandler = pageNumber => {
    this.setState({ activePage: pageNumber }, () => {
      this.getRuleTemplatesByCategories(this.state.selectedCategory);
    });
  };

  limitChangeHandler = limit => {
    this.setState({ itemsCountPerPage: limit }, () => {
      this.getRuleTemplatesByCategories(this.state.selectedCategory);
    });
  }

  searchHandler = async e => {
    let searchInput = e.target.value;
    this.setState({
        searchInput: searchInput
      },
      async () => {
        // if the search input is blank, reset displayedRuleTemplates
        if (!searchInput.length) {
          this.getRuleTemplatesByCategories(this.state.selectedCategory);
        } else {
            const res = await travelRuleTemplateServices.searchTravelRule(
              this.state.companyId,
              searchInput,
              this.state.itemsCountPerPage,
              this.state.activePage
            );
            if (res.result.length) {
              this.setState({
                displayedRuleTemplates: res.result,
                totalItemsCount: res.count
              });
            } else {
              this.setState({
                displayedRuleTemplates: []
              });
            }
        }
      }
    );
  };

  addRuleTemplateHandler = () => {
    // travelRuleTemplateServices
    //   .getConditionsByTemplateCategory(
    //     this.state.companyId,
    //     this.state.selectedCategory
    //   )
    //   .then(res => {
    //     // console.log(' adding new Rule template ',res);
    //     // all the condition checkboxes to be stored in ruleTemplateCheckboxes
    //     if(res != undefined && res.result){

    //       this.setState({
    //         ruleTemplateCheckboxes: res.result
    //       });
    //     }
    //   });
  };
  resetRuleTemplate = () => {
    this.setState({
      selectedCheckboxes: [],
      fieldsToBeRendered: [],
    })
  }
  conditionCheckHandler = async (condition, ruleTemplate = {}) => {
    let selectedCheckboxes = [...this.state.selectedCheckboxes];
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let index = selectedCheckboxes.indexOf(condition.id);
    if(index === -1){
    selectedCheckboxes.push(condition.id);
    if(condition.ruleCondition && condition.ruleCondition.length){
        condition.ruleCondition.forEach(item =>{
          if(item.dataTypeDetail.conditionID === "DIFFERENT-LIST"){
            let foundDifferentList =  item;
            item.displayType=item?.dataTypeValueDetail?.[0]?.conditionID;
            // if(foundDifferentList != null){
            //   this.setState({
            //     differentListId: foundDifferentList?.dataTypeValueDetail?.[0]?._id,
            //     differentListName: foundDifferentList?.dataTypeValueDetail?.[0]?.conditionID
            //   })
            // }
          }
        })
        fieldsToBeRendered.push(condition);
    }
    }else {
      // if checkbox already selected, then remove it and remove the fields from fieldsToBeRendered
      fieldsToBeRendered =  fieldsToBeRendered.filter(field => field.id !== condition.id)
      selectedCheckboxes.splice(index, 1);
    }
    await new Promise(resolve => this.setState({
      selectedCheckboxes,
      fieldsToBeRendered
    }, resolve));

    if(this.state.isEdit){
      this.populateSetFields(this.state.fieldsToBeRendered, ruleTemplate);
    }
  };

  // * For setting up the fields in the edit mode
  populateSetFields = (fieldsToBeRendered, ruleTemplate) => {
    if(ruleTemplate && ruleTemplate.travelCategoryRule){
      ruleTemplate.travelCategoryRule.forEach(rule => {
        let matchedField = fieldsToBeRendered.find(item => item.id == rule.travelCategoryRuleId);
        if(matchedField){
          rule.ruleCondition.forEach(cond => {
            let matchedCondition = matchedField.ruleCondition.find(field => field._id == cond.dataType)
            if(matchedCondition){
              switch(matchedCondition.dataTypeDetail.conditionID){
                case travelFieldConstants.DATA_TYPE_CONDITIONAL_DROP_DOWN:
                case travelFieldConstants.DATA_TYPE_CHEAPEST_DROPDOWN:
                case travelFieldConstants.DATA_TYPE_REFUNDABLE_NON_REFUNDABLE_DROP_DOWN:
                case travelFieldConstants.DATA_TYPE_AMOUNT_PERCENTAGE_DROP_DOWN:
                case travelFieldConstants.DATA_TYPE_CONDITIONAL_NUMBER:
                case travelFieldConstants.DATA_TYPE_MILE_KM_DROP_DOWN:
                  matchedCondition.selectedDropdown = matchedCondition.dataTypeDetail.conditionList.find(condition => condition._id == cond.fieldValue[0]);
                  break;
                case travelFieldConstants.DATA_TYPE_CONDITIONAL_DATE:
                case travelFieldConstants.DATA_TYPE_LIST_MANAGEMENT:
                case travelFieldConstants.DATA_TYPE_SUPER_ADMIN_LIST:
                  matchedCondition.selectedDropdown = matchedCondition.dataTypeValueDetail.find(condition => condition.id == cond.fieldValue[0]);
                  break;
                case travelFieldConstants.DATA_TYPE_AMOUNT :
                case travelFieldConstants.DATA_TYPE_NUMBER_OF_DAYS :
                case travelFieldConstants.DATA_TYPE_NUMBER :
                case travelFieldConstants.DATA_TYPE_TEXT:
                case travelFieldConstants.DATA_TYPE_NUMBER_OF_HOURS:
                  matchedCondition.selectedValue = cond.fieldValue[0];
                  break;
                case travelFieldConstants.DATA_TYPE_DIFFERENT_LIST:
                  matchedCondition.selectedDropdown = []
                  cond.fieldValue.forEach(keyItem =>{
                      let itemOfList = rule.differentListValues.find(condition => condition.value === keyItem);
                      matchedCondition.selectedDropdown.push(itemOfList)
                  })
                  break;
                case travelFieldConstants.DATA_TYPE_DATE:
                  matchedCondition.selectedValue = cond.fieldValue[0]
                  break;
              }
            }
          })
        }
      })
    }
    this.setState({fieldsToBeRendered});
  }

  // * For changing the field

  fieldChangeHandler = (e,  rule, fieldItem, type) => {
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let matchedRule = this.state.fieldsToBeRendered.find(item => item == rule);
    let matchedCondition = matchedRule.ruleCondition.find(item => item == fieldItem);
    matchedCondition.selectedValue = e.target.value;
    this.setState({fieldsToBeRendered});
  }

  // for changing date

  fieldDateChangeHandler = (e,rule, fieldItem,type) => {
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let matchedRule = this.state.fieldsToBeRendered.find(item => item == rule);
    let matchedCondition = matchedRule.ruleCondition.find(item => item == fieldItem);
    matchedCondition.selectedValue = e.toUTCString();
    this.setState({fieldsToBeRendered});
  }

  // * For changing select box

  selectChangeHandler = (e, rule, fieldItem, type) => {
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let matchedRule = this.state.fieldsToBeRendered.find(item => item == rule);
    let matchedCondition = matchedRule.ruleCondition.find(item => item == fieldItem);
    if(matchedCondition?.dataTypeDetail?.conditionID === travelFieldConstants.DATA_TYPE_LIST_MANAGEMENT || matchedCondition?.dataTypeDetail?.conditionID === travelFieldConstants.DATA_TYPE_SUPER_ADMIN_LIST){
      matchedCondition.selectedDropdown = {
        _id: e.id,
        itemName: e.itemName,
        itemCode: e.itemCode
      }
    }else{
      matchedCondition.selectedDropdown = e;
    }
    this.setState({fieldsToBeRendered});
  }

  loadOptions = async (newValue,type) => {
    return travelRuleTemplateServices.differentListSearchPolicy(type,newValue, this.state.companyId).then((data) => {
      return data.result.map(list => {
        return {
          label: list.name,
          value: list.id
        };
      });
    })
  }

  selectDifferentListChangeHandler = (e,rule,fieldItem, type) => {
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let matchedRule = this.state.fieldsToBeRendered.find(item => item == rule);
    let matchedCondition = matchedRule.ruleCondition.find(item => item == fieldItem);
    matchedCondition.selectedDropdown = e;
    this.setState({fieldsToBeRendered});
  }

  fieldValueChangeHandler = (e, rule, fieldItem, type) => {
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let matchedRule = this.state.fieldsToBeRendered.find(item => item == rule);
    let matchedCondition = matchedRule.ruleCondition.find(item => item == fieldItem);
    matchedCondition.dataTypeValue = e.target.value;
    this.setState({fieldsToBeRendered});
  }

  renderedFieldSelectHandler = (e, checkbox) => {
    let fieldsData = [...this.state.fieldsToBeRendered];
    fieldsData.forEach(condition => {
      if(condition.id === checkbox.id && condition.type === 'select' && condition.listId === checkbox.listId){
        condition.value = e.listName;
      }
    })
    this.setState({fieldsData});
  }

  renderedFieldTextHandler = (e, checkboxId) => {
    // console.log(key)
    let fieldsData = [...this.state.fieldsToBeRendered];
    fieldsData.forEach(condition => {
      if(condition.id === checkboxId && condition.type === 'text'){
        condition.value = e.target.value;
      }
    });
    this.setState({fieldsData});
  }

  renderedFieldCheckboxHandler = (e, checkboxId) => {
    let fieldsData = [...this.state.fieldsToBeRendered];
    fieldsData.forEach(condition => {
      if(condition.id === checkboxId && condition.type === 'checkbox'){
        condition.value = e.target.checked;
      }
    });
    this.setState({fieldsData});
  }

  allCheckedConditionsHandler = (value) => {
    let newValue = !value;
    this.setState({allConditions: newValue, anyCondition: value})
  }

  anyCheckedConditionsHandler = (value) => {
    let newValue = !value;
    this.setState({anyCondition: newValue, allConditions: value});
  }

  ruleTemplateNameHandler = (e) => {
    this.setState({ruleTemplateName: e.target.value})

  }
  violationCodeHandler = (e) => {
    this.setState({violationCode: e.target.value})
  }

  getFieldValue = (condition) => {
    if(!condition){
      return [""]
    }else if(condition.selectedValue){
      return [condition.selectedValue]
    }else if(condition?.selectedDropdown?._id){
      return [condition?.selectedDropdown?._id]
    }else if(condition?.selectedDropdown?.id){
      return [condition?.selectedDropdown?.id]
    }else if(condition?.selectedDropdown){
      let values = []
      condition.selectedDropdown && condition.selectedDropdown.forEach((key) =>{
        values.push(key.value)
      })
      return values;
    }else{
      return ""
    }
  }

  saveRuleTemplateHandler = async (e) => {
    e.preventDefault();
    let travelCategoryRule = this.state.fieldsToBeRendered.map(item => {
      return {
        travelCategoryRuleId: item.id,
        ruleCondition: item.ruleCondition.map(condition => {
          return {
            dataType: condition._id,
            dataTypeKey: condition.dataTypeDetail.conditionID,
            fieldValue: this.getFieldValue(condition),
            selectedDropdown:(condition?.dataTypeDetail?.conditionID === "DIFFERENT-LIST")? condition.selectedDropdown:[],
          }
        })
      }
    })
    let newRuleTemplate = {
      companyId: this.state.companyId,
      travelCategoryId: this.state.selectedCategory.id,
      violationCode: this.state.violationCode,
      ruleName: this.state.ruleTemplateName,
      isAllTrue: this.state.anyCondition ? false : true,
      // conditionsId: this.state.selectedCheckboxes,
      travelCategoryRule: travelCategoryRule
    };
    let values =  travelCategoryRule.map(item => {
      return {
        condition: item.ruleCondition
      }
    })
    // console.log(' value before sendin is ', values);
    const isFieldValid = values.every(({
      condition
  }) => condition.every(obj => obj.fieldValue || obj.selectedDropdown.length));
  if(!isFieldValid){
    toast.warn('Kindly fill all the fields.');
    this.cancelModalHandler();
    return;
  }
  // Save and update
    if(this.state.isEdit){
        if(this.validator.allValid()){
          let res = await travelRuleTemplateServices.updateTravelRuleTemplate(this.state.ruleTemplateId, newRuleTemplate);
          if(res){
            this.setState({
              ruleTemplateName: '',
              violationCode: '',
              selectedCheckboxes: [],
              fieldsToBeRendered: [],
              isEdit: false
            });
            this.getRuleTemplatesByCategories();
            $('.close').click();
          }
        }else{
          this.validator.showMessages();
          this.forceUpdate();
        }
    }else{
      if(this.validator.allValid()){
        let res =  await travelRuleTemplateServices.createTravelRuleTemplate(newRuleTemplate);
        if(res){
           this.setState({
            ruleTemplateName: '',
            violationCode: '',
            selectedCheckboxes: [],
            fieldsToBeRendered: [],
          });
          this.getRuleTemplatesByCategories();
          $('.close').click();
      }
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  // ENd if save and update
  }

  editModalHandler = async (ruleTemplate) => {
    await this.setState({
      isEdit: true
    })
    this.setState({
      ruleTemplateName: ruleTemplate.ruleName,
      violationCode: ruleTemplate.violationCode,
      allConditions: ruleTemplate.isAllTrue ? true : false,
      anyCondition: ruleTemplate.isAllTrue ? false : true,
      ruleTemplateId: ruleTemplate.id,
      selectedRuleTemplates:[]
    });
    let alreadySetConditions = ruleTemplate.travelCategoryRule.map(item => item.travelCategoryRuleId);
    if(alreadySetConditions){
      let matchedConditions = this.state.availableRulesinCategory.filter(item => alreadySetConditions.includes(item.id));
      executePromisesConcurrently(matchedConditions, (condition) =>
         this.conditionCheckHandler(condition, ruleTemplate), { concurrency: 1 })
    }
  }

  copyModalHandler = (ruleTemplate) => {
    this.setState({
      ruleTemplateId: ruleTemplate.id,
      ruleTemplateName: ruleTemplate.ruleName,
      violationCode: ruleTemplate.violationCode,
      allConditions: ruleTemplate.allConditions,
      anyCondition: ruleTemplate.anyCondition,
      conditionsId: ruleTemplate.conditionsId,
      templateCategory: ruleTemplate.templateCategory,
      fieldsToBeRendered: [],
    }, () => {
      travelRuleTemplateServices
      .getConditionsByTemplateCategory(
        this.state.companyId,
        this.state.selectedCategory
      )
      .then(res => {
        this.setState({
          ruleTemplateCheckboxes: res.result
        });
      });
    });
  }

  copyRuleTemplateHandler = (e) => {
    e.preventDefault();
    let copiedRuleTemplate = {
      companyId: this.state.companyId,
      templateCategory: this.state.selectedCategory,
      violationCode: this.state.violationCode,
      ruleName: this.state.ruleTemplateName,
      allConditions: this.state.allConditions,
      anyCondition: this.state.anyCondition,
      conditionsId: this.state.selectedCheckboxes,
      conditions:this.state.fieldsData
    };

    travelRuleTemplateServices.createTravelRuleTemplate(copiedRuleTemplate)
      .then(res => {
        this.getTravelRulesByTemplateCategory(this.state.selectedCategory)
      })

  }

  cancelModalHandler = () => {
    this.setState({
      selectedCheckboxes: [],
      fieldsToBeRendered: [],
      ruleTemplateCheckboxes: [],
      isEdit: false,
      violationCode: '',
      ruleTemplateName: '',
      fieldsToBeRendered:[]
    })
    this.getTravelRulesByTemplateCategory(this.state.selectedCategory)
    this.resetRuleTemplate()
  }

  newMethod(fieldsToBeRendered, index) {
    fieldsToBeRendered.splice(index, 1);
  }

  render() {
    return (
      <div>
        <RuleTemplatePage
          state={this.state}
          error={this.validator}
          changeTemplateCategory={this.changeTemplateCategory}
          toggleRuleTemplate={this.toggleRuleTemplate}
          selectRuleTemplateHandler={this.selectRuleTemplateHandler}
          deleteRuleTemplateHandler={this.deleteRuleTemplateHandler}
          pageChangeHandler={this.pageChangeHandler}
          limitChangeHandler={this.limitChangeHandler}
          searchHandler={this.searchHandler}
          addRuleTemplateHandler={this.addRuleTemplateHandler}
          conditionCheckHandler={this.conditionCheckHandler}
          renderedFieldSelectHandler = {this.renderedFieldSelectHandler}
          renderedFieldTextHandler={this.renderedFieldTextHandler}
          allCheckedConditionsHandler={this.allCheckedConditionsHandler}
          anyCheckedConditionsHandler={this.anyCheckedConditionsHandler}
          saveRuleTemplateHandler={this.saveRuleTemplateHandler}
          editModalHandler={this.editModalHandler}
          copyModalHandler={this.copyModalHandler}
          copyRuleTemplateHandler={this.copyRuleTemplateHandler}
          renderedFieldCheckboxHandler={this.renderedFieldCheckboxHandler}
          ruleTemplateNameHandler={this.ruleTemplateNameHandler}
          violationCodeHandler={this.violationCodeHandler}
          cancelModalHandler={this.cancelModalHandler}
          fieldValueChangeHandler={this.fieldValueChangeHandler}
          fieldChangeHandler={this.fieldChangeHandler}
          selectChangeHandler={this.selectChangeHandler}
          selectDifferentListChangeHandler={this.selectDifferentListChangeHandler}
          loadOptions={this.loadOptions}
          resetRuleTemplate={this.resetRuleTemplate}
          fieldDateChangeHandler={this.fieldDateChangeHandler}
        />
      </div>
    );
  }
}
export default RuleTemplate;
