import React from "react";
import AndOrComponent from "./components/AndOrComponent";
import ExpressionComponent from "./components/ExpressionComponent";
import RuleSaveClose from './components/RuleSaveClose';
import AuditRulesMenu from "../audit-rules-menu";
import Loader from 'src/_components/common/Loader';

const ItemizationWizardPage = props => {
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Audit Rules</h6>
              <p className="text">Add/Modify Audit Rules.</p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <AuditRulesMenu
              auditRule_id={props.auditRule_id}
              gotoNextMenu={props.gotoNextMenu}
            />
            {props.isLoading ?
              <Loader /> :
              <form>
                <div className="profile-card">
                  {
                    props.expressionArray && props.expressionArray.map((expression, idx) => {
                      let expressionLength = props.expressionArray.length - 2;
                      return (
                        <div key={expression.id}>
                          {expression.type === 'operand' ?

                            <ExpressionComponent
                              {...props}
                              expression={expression}
                              allCurrecies={props.allCurrecies}
                              expressionArrayLength={props.expressionArray.length}
                              idx={idx}
                              expressionLetter={props.expressionLetter}
                              validator={props.validator}
                              allFormHeaders={props.allFormHeaders}
                              allFieldNames={props.allFieldNames}
                              createExpression={props.createExpression}
                              removeExpression={props.removeExpression}
                              firstBracketOptions={props.firstBracketOptions}
                              lastBracketOptions={props.lastBracketOptions}
                              handleFormHeaderSelection={props.handleFormHeaderSelection}
                              handleFieldNameSelection={props.handleFieldNameSelection}
                              handleParenthesesSelection={props.handleParenthesesSelection}
                              handleExpressionOperator={props.handleExpressionOperator}
                              expressionCheckboxHandler={props.expressionCheckboxHandler}
                              fieldValueSelectHandler={props.fieldValueSelectHandler}
                              fieldValueChangeHandler={props.fieldValueChangeHandler}
                              onSearchTextHandler={props.onSearchTextHandler}
                              onCheckboxChnageHandler={props.onCheckboxChnageHandler}
                              onMultiLblConnectHandler={props.onMultiLblConnectHandler}
                              onCheckboxChangeHandler={props.onCheckboxChangeHandler}
                              onCurrencySelectHandler={props.onCurrencySelectHandler}
                              loadOptions={props.loadOptions}
                            /> : props.isEdit ?
                              expressionLength > idx &&
                              <AndOrComponent
                                idx={idx}
                                expression={expression}
                                handleRadioButtonChange={props.handleRadioButtonChange}
                              />
                              :
                              expressionLength > idx &&
                              <AndOrComponent
                                idx={idx}
                                expression={expression}
                                handleRadioButtonChange={props.handleRadioButtonChange}
                              />
                          }
                        </div>
                      )
                    })
                  }
                </div>
                <RuleSaveClose
                  isLoading={props.isLoading}
                  isSubmitClick={props.isSubmitClick}
                  updateRuleHandler={props.createRuleHandler}
                  onCancelClickHanler={props.onCancelClickHanler}
                />
              </form>
            }
          </div>
        </div>
      </div>
    </div >
  );
};
export default ItemizationWizardPage;
