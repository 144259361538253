import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";

let CustomDateInput = forwardRef(
  (
    {
      as = "h6",
      tag: Tag = as,
      className,
      value,
      children = value,
      onClick,
      ...props
    },
    ref
  ) => (
    <Tag
      className={classNames("place", className)}
      ref={ref}
      onClick={onClick}
      {...props}
    >
      {children}
    </Tag>
  )
);

CustomDateInput.propTypes = {
  as: PropTypes.any
};

// ----------------------------------------

let CustomDatePicker = forwardRef(
  (
    {
      defaultDate = new Date(),
      selected = defaultDate,
      format = "dd MM yyyy | h:mm aa",
      isOpen,
      onChange,
      onSelect,
      ...props
    },
    ref
  ) => {
    const [selectedDate, setSelected] = useState(new Date(selected));
    const [isOpenDatePicker, setIsOpenDatePicker] = useState(isOpen);

    if (isOpen != null) {
      props.open = isOpenDatePicker;
    }

    if (isOpen != null && isOpenDatePicker !== isOpen) {
      setIsOpenDatePicker(isOpen);
    }

    onChange = onChange || (date => setSelected(date));

    return (
      <DatePicker
        customInput={<CustomDateInput />}
        dateFormat={format}
        selected={selectedDate}
        ref={ref}
        onChange={onChange}
        onSelect={onSelect}
        popperPlacement="top-end"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "5px, 10px"
          },
          preventOverflow: {
            enabled: true,
            // force popper to stay in viewport (even when input is scrolled out of view)
            escapeWithReference: false,
            boundariesElement: "viewport"
          }
        }}
        {...props}
      />
    );
  }
);

CustomDatePicker.propTypes = {
  defaultDate: PropTypes.oneOf([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.string
  ]),
  format: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  isOpen: PropTypes.bool,
  customInput: PropTypes.element
};

export { CustomDatePicker, CustomDateInput };
