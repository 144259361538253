import React, { Component } from "react";
import { Route } from "react-router-dom";
import {
  MidOfficeHistoryPage,
  HistoryDetailsPage,
  FlightDetails,
  HotelDetails,
  UserDetailsPage,
  FlightSwitch,
  HotelSwitch
} from "./index";
class MidOfficeHistoryHomePage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/administration/company/mid-office-history/") {
      this.props.history.push("/administration/company/mid-office-history/mid-office-history");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (
      this.props.location.pathname === "/administration/company/mid-office-history"
    ) {
      this.props.history.push("/administration/company/mid-office-history/mid-office-history");
    }
  }
  render() {
    return (
      <div className="">
        <Route
          path="/administration/company/mid-office-history/mid-office-history"
          component={MidOfficeHistoryPage}
        />
        <Route
          path="/administration/company/mid-office-history/history-details/:id/:type"
          exact
          component={HistoryDetailsPage}
        />
        <Route
          path="/administration/company/mid-office-history/flightDetails"
          exact
          component={FlightDetails}
        />
        <Route
          path="/administration/company/mid-office-history/hotelDetails"
          exact
          component={HotelDetails}
        />
        <Route
          path="/administration/company/mid-office-history/userDetails"
          exact
          component={UserDetailsPage}
        />
        <Route
          path="/administration/company/mid-office-history/flightSwitch/:type1/:tripId/:journeyId/:type"
          exact
          component={FlightSwitch}
        />
        <Route
          path="/administration/company/mid-office-history/hotelSwitch/:tripId/:bookingType/:hotelId/:type"
          exact
          component={HotelSwitch}
        />
      </div>
    );
  }
}
export default MidOfficeHistoryHomePage;
