import React, { Component } from 'react'

export default class AndOrComponent extends Component {
  render() {
    return (
      <div className="and-or">
        <label className="radio-container">
          And
          <input
            type="radio"
            checked={this.props.expression?.value?.operator == 'and'}
            name={`andOr${this.props.idx}`}
            value="and"
            onChange={e => this.props.handleRadioButtonChange(e, this.props.expression.id)}
          />
          <span className="checkmark" />
        </label>
        <label className="radio-container">
          Or
          <input
            type="radio"
            checked={this.props.expression?.value?.operator == 'or'}
            name={`andOr${this.props.idx}`}
            value="or"
            onChange={e => this.props.handleRadioButtonChange(e, this.props.expression.id)}

          />
          <span className="checkmark" />
        </label>
      </div>
    )
  }
}
