import React, { Component } from 'react';
class CashAdvanceIssuedRejectModalTable extends Component {
  render() {
    let cashadvanceData, requestedAmountIndex, selectedCurrency, cashMode;
    cashadvanceData = this.props.selectedCashadvanceData;
    requestedAmountIndex = cashadvanceData.data.findIndex((fields) => fields.columnName === 'AMOUNT');
    selectedCurrency = this.props.cashAdvanceCurrency;
    cashMode = this.props.cashAdvanceModes.filter((cashModes) => cashadvanceData.mode === cashModes.value);
    return (
      <div>
        <div className="row">
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Amount Requested</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">
              {selectedCurrency.length > 0 && selectedCurrency[0].itemCode} {requestedAmountIndex !== -1 && cashadvanceData.data[requestedAmountIndex].fieldValue}</p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Amount Approved</p>
            <p className="font-weight-bold mb-0">
            {cashadvanceData.amountApproved && selectedCurrency[0].itemCode}&nbsp;{cashadvanceData.amountApproved ?parseFloat(cashadvanceData.amountApproved).toFixed(2):'-'}</p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Issued Amount</p>
            <p className="font-weight-bold mb-0">
              {cashadvanceData.amountIssued && cashadvanceData.issuedCurrency}&nbsp;{cashadvanceData.amountIssued ?parseFloat(cashadvanceData.amountIssued).toFixed(2):'-'}
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Exchange Rate</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">{parseFloat(cashadvanceData.exchangeRate).toFixed(2)}</p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Mode</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">
              {cashMode.length > 0 ? cashMode[0].label : '-'}
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Voucher</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">
              {cashadvanceData.voucher ? cashadvanceData.voucher : '-'}
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Comment</p>
            <p className="font-weight-bold mb-0">
              {cashadvanceData.comment ? cashadvanceData.comment : '-'}
            </p>
          </div>
          {this.props.uploadedVouchers.map((images) =>
            <div className="col-md-4 mb-4">
              <img
                className="img-fluid"
                src={images}
              />
            </div>
          )
          }
        </div>
      </div>
    )
  }
}

export default CashAdvanceIssuedRejectModalTable;