import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class ExpenseTypesMenu extends Component {
  constructor(props){
    super(props);
  }
  render() {
    return (
      <div className="audit-step">
         <NavLink
            className="s1"
            to={(this.props.expenseTypeId)?`/administration/expense/ex/types/expense-types-step1/${this.props.expenseTypeId}`:`/administration/expense/ex/types/expense-types-step1`}
          >
            <span>1</span>
            General
          </NavLink>
          <NavLink
            className="s1"
            to={(this.props.expenseTypeId)?`/administration/expense/ex/types/expense-types-step2/${this.props.expenseTypeId}`:`#`} 
          >
            <span>2</span>
            Policies
          </NavLink>
          <NavLink
            className="s1"
            to={(this.props.expenseTypeId)?`/administration/expense/ex/types/expense-types-3/${this.props.expenseTypeId}`:`#`}  
          >
            <span>3</span>
            Request Policy
          </NavLink> 
      </div>
    );
  }
}
export default ExpenseTypesMenu;
