import React from "react";
import FlightExpand from "./FlightExpand";
import NumberFormat from "react-number-format";
import TaxBreakUpFlight from "./tax-break-up";
import { getValue } from "src/_components/common/lodsh";
import { FILGHT_CONSTANT } from "src/_components/common/constants/FLIGHT_CONSTANTS";
const FlightList = props => {
  const {
    changeWay,
    baggageInfo,
    handleGetReason,
    handleChangeReasonId,
    type,
    items,
    getTimes,
    uniqueId,
    changeMode,
    keys,
    airport,
    viewDetail,
    detailId,
    arrowId,
    from,
    addToCart,
    selectedItem,
    to,
    currency,
    tripType,
    cancelPolicy,
    state,
    classType
  } = props;

  const taxBreakUp = tax_break_up => {
    Reflect.ownKeys(tax_break_up).forEach((i, index) => {
      // console.log(tax_break_up[i]);
    });
  };
  return (
    <div className="devision-container" key={keys}>
      <div className="top-heading">
        <p className="float-left show-white border-right-white bg-white">
          Flight Details
        </p>
        {items.out_of_policy ? (
          <div className="p-to-head right float-left show-white border-right-white out-of-policy hover-v">
            <i className="material-icons fs-14 align-middle">error</i>{" "}
            <span>Out of policy</span>
            <div className="hover-name bottom out-of-p">
              <p>
                This option breaks one or more company travel rules that prevent
                it from being selected.
              </p>
              {items.violations &&
                items.violations.map((rule, ruleKey) => {
                  return (
                    <span key={ruleKey} className="d-block">
                      <i className="material-icons">warning</i> {rule.message}.
                    </span>
                  );
                })}
              {/* <p>
                            <i  className="material-icons">
                                warning
                    </i> 2 or more company employees in the same flight.</p> */}
            </div>
          </div>
        ) : (
            ""
          )}
        <p
          className="right blue cursor-pointer"
          data-target={"#itineraryInfoModal" + keys}
          data-toggle="modal"
        >
          <i className="material-icons fs-14 align-sub">flight</i> Fare Details
        </p>
        {(items.fare && items.fare.next_day_arrive) ||
          (items.CORP && items.CORP.next_day_arrive) ? (
            <p className="right float-left white border-right-white next-day-arrival">
              <i className="material-icons fs-14 align-middle">wb_sunny</i>
              <span>
                {items.choosed_flight_fare_id.arriveTime === 1 &&
                  " Next Day Arrival"}
                {items.choosed_flight_fare_id.arriveTime === 2 &&
                  " Second Day Arrival"}
                {items.choosed_flight_fare_id.arriveTime === 3 &&
                  " Third Day Arrival"}
                {items.choosed_flight_fare_id.arriveTime === 4 &&
                  " Fourth Day Arrival"}
                {items.choosed_flight_fare_id.arriveTime === 5 &&
                  " Fifth Day Arrival"}
              </span>
            </p>
          ) : (
            ""
          )}
        {(items.fare && items.fare.red_eye) ||
          (items.CORP && items.CORP.red_eye) ? (
            <p className="right float-left show-white border-right-white hover-v">
              <i className="material-icons fs-14 align-middle">visibility</i>
              <span className="hover-name bottom">Red Eye Flight</span>
            </p>
          ) : (
            ""
          )}
        {items.cheapest ? (
          <p className="suggestion cheap border-right-white">
            <i className="material-icons fs-14 align-middle">trending_down</i>{" "}
            Cheapest
          </p>
        ) : items.fastest ||
          (items.fare && items.fare.fastest) ||
          (items.CORP && items.CORP.fastest) ? (
              <p className="suggestion fast border-right-white">
                <i className="material-icons fs-14 align-middle">trending_up</i>{" "}
                Fastest
              </p>
            ) : (
              ""
            )}
        <div className="clearfix" />
      </div>
      <div className="devision animation-target-left">
        {items &&
          items.content.map((item, index) => {
            return (
              <div key={index}>
                {index === 0 && (
                  <div>
                    <div className="div-pad cursor-pointer new-devision-container">
                      <div
                        onClick={e =>
                          viewDetail(index, keys, items.choosed_flight_fare_id)
                        }
                        className={
                          arrowId === keys ? "abs-right blu" : "abs-right"
                        }
                      >
                        <i className="material-icons">
                          {arrowId === keys
                            ? "arrow_drop_up"
                            : "arrow_drop_down"}
                        </i>
                      </div>
                      <div
                        className="details airline"
                        onClick={e =>
                          viewDetail(index, keys, items.choosed_flight_fare_id)
                        }
                      >
                        <div className="name">
                          <div className="logo">
                            <img
                              src={
                                items.fare && items.fare.flight_image
                                  ? items.fare.flight_image
                                  : items.CORP
                                    ? items.CORP.flight_image
                                    : "/assets/images/booking/flight-dummy.png"
                              }
                              alt="flight"
                            />
                          </div>
                          <div className="info">
                            <p className="timings">
                              <b>{items.fare
                                ? items.fare.flight_airline
                                : items.CORP
                                  ? items.CORP.flight_airline
                                  : ""}</b>
                            </p>
                            <p className="class">
                              {items.content
                                ? items.content[0].flight_airline
                                : " "}
                              -{" "}
                              {items.content
                                ? items.content[0].flight_number
                                : ""}
                            </p>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </div>
                      <div className="details departure"
                        onClick={e =>
                          viewDetail(index, keys, items.choosed_flight_fare_id)
                        }
                      >
                        <p>
                          {tripType === "depart"
                            ? from
                            : tripType === "return"
                              ? to
                              : from}
                        </p>
                        <p>
                          <b>{items.fare
                            ? items.fare.flight_depature_time
                            : items.CORP
                              ? items.CORP.flight_depature_time
                              : ""}</b>
                        </p>
                      </div>
                      <div className="details arrival"
                        onClick={e =>
                          viewDetail(index, keys, items.choosed_flight_fare_id)
                        }
                      >
                        <p>
                          {tripType === "depart"
                            ? to
                            : tripType === "return"
                              ? from
                              : to}
                        </p>
                        <p>
                          <b>{items.fare
                            ? items.fare.flight_arrival_time
                            : items.CORP
                              ? items.CORP.flight_arrival_time
                              : ""}</b>
                        </p>
                      </div>
                      <div className="details duration"
                        onClick={e =>
                          viewDetail(index, keys, items.choosed_flight_fare_id)
                        }
                      >
                        <p>
                          <b><i className="material-icons fs-14 align-sub">
                            access_time
                                </i>{" "}
                            {items.fare
                              ? getTimes(
                                items.fare.flight_onward_total_duration
                              )
                              : items.CORP
                                ? getTimes(
                                  items.CORP.flight_onward_total_duration
                                )
                                : ""}</b>
                        </p>
                        {/* <p className="text-success">
                          <i className="material-icons fs-14 align-sub">
                            airline_seat_recline_normal
                          </i>
                          {" "}9 Seats Left
                          </p> */}
                      </div>
                      <div
                        className="details stops p-more"
                        onClick={e =>
                          viewDetail(index, keys, items.choosed_flight_fare_id)
                        }
                      >
                        <p>
                          <b>
                            {items.content.length === 1 ? (
                              "Non-Stop"
                            ) : (
                                <span>
                                  {index === 0 &&
                                    items.content.length - 1 + " Stop"}
                                </span>
                              )}
                          </b>
                        </p>
                        <p>
                          {index === 0 &&
                            items.content &&
                            items.content.length > 1 &&
                            items.choosed_flight_fare_id.flight_layoverTime +
                            " Layover"}
                        </p>
                      </div>
                      {/* <div
                        className="details amenities p-more"
                        onClick={e =>
                          viewDetail(index, keys, items.choosed_flight_fare_id)
                        }
                      >
                        <ul className="ul-bullets">
                          {items.fare && !items.CORP && (
                            <li>
                              {items.fare.flight_refundable ? (
                                <p className="link">Refundable</p>
                              ) : (
                                  <p className="text-danger">Non-Refundable</p>
                                )}
                            </li>
                          )}
                          {!items.fare && items.CORP && (
                            <li>
                              {items.CORP.flight_refundable ? (
                                <p className="link">Refundable</p>
                              ) : (
                                  <p className="text-danger">Non-Refundable</p>
                                )}
                            </li>
                          )}
                          {items.fare && items.CORP && (
                            <li>
                              {items &&
                                items.choosed_flight_fare_id &&
                                items.choosed_flight_fare_id.type ==
                                "Corporate Fare" ? (
                                  items.CORP.flight_refundable ? (
                                    <p className="link">Refundable</p>
                                  ) : (
                                      <p className="text-danger">Non-Refundable</p>
                                    )
                                ) : items.fare.flight_refundable ? (
                                  <p className="link">Refundable</p>
                                ) : (
                                    <p className="text-danger">Non-Refundable</p>
                                  )}
                            </li>
                          )}
                        </ul>
                      </div> */}
                      <div className="details price">
                        <div
                          className={
                            uniqueId.indexOf(items.UNIQUE_ID) === -1
                              ? "flight-price mb-0  "
                              : "flight-price mb-0  added"
                          }
                        >
                          <div className="cash">
                            {items.choosed_flight_fare_id && (
                              <span>
                                <h5>
                                  {" "}
                                  {currency}{" "}
                                  <NumberFormat
                                    value={items.choosed_flight_fare_id.flight_total_price.toFixed(
                                      2
                                    )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </h5>
                                <p>{items.choosed_flight_fare_id.type}</p>
                              </span>
                            )}
                          </div>
                          {items.priceList.length <= 1 && (
                            <div
                              className="d-button"
                              onClick={e =>
                                addToCart(
                                  items.choosed_flight_fare_id,
                                  tripType,
                                  items.UNIQUE_ID
                                )
                              }
                            >
                              <i className="material-icons">
                                {selectedItem.indexOf(
                                  items.choosed_flight_fare_id.flight_fare_id
                                ) === -1
                                  ? "chevron_right"
                                  : "done"}
                              </i>{" "}
                              {state.loadingUniqueId === items.UNIQUE_ID
                                ? "Adding"
                                : selectedItem.indexOf(
                                  items.choosed_flight_fare_id.flight_fare_id
                                ) === -1
                                  ? "Add"
                                  : "Added"}
                            </div>
                          )}
                          {items.priceList.length > 1 && (
                            <div className="d-button dropup double-d">
                              <button
                                type="button"
                                data-toggle="dropdown"
                                className="btn dropdown-toggle add_cart_dropdown"
                              >
                                {state.loadingUniqueId === items.UNIQUE_ID
                                  ? "Adding.."
                                  : selectedItem.indexOf(
                                    items.choosed_flight_fare_id
                                      .flight_fare_id
                                  ) === -1
                                    ? "Options"
                                    : "Added"}
                                <i className="material-icons abs">
                                  expand_more
                                </i>
                              </button>
                              {items.priceList && (
                                <div className="dropdown-menu">
                                  {items.priceList.map((item, keyInd) => {
                                    return (
                                      <button
                                        onClick={e => changeMode(keys, item)}
                                        className={
                                          selectedItem.indexOf(
                                            item.flight_fare_id
                                          ) === -1
                                            ? "dropdown-item"
                                            : "dropdown-item added"
                                        }
                                        key={keyInd}
                                      >
                                        <button
                                          onClick={e =>
                                            addToCart(
                                              item,
                                              tripType,
                                              items.UNIQUE_ID
                                            )
                                          }
                                          className="add"
                                        >
                                          {selectedItem.indexOf(
                                            item.flight_fare_id
                                          ) === -1
                                            ? "Add"
                                            : "Added"}
                                        </button>
                                        <p>{item.type}</p>
                                        <h5>
                                          {currency}{" "}
                                          {item.flight_total_price.toFixed(2)}
                                        </h5>
                                      </button>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )}
                          <div className="clearfix" />
                        </div>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <FlightExpand
                      key={index}
                      className={
                        detailId === keys
                          ? "more-details"
                          : "more-details d-none"
                      }
                      baggageInfo={baggageInfo}
                      type={type}
                      list={items.content}
                      changeWay={changeWay}
                      index={index}
                      currency={currency}
                      airport={airport}
                      keys={keys}
                      fare={items.fare}
                      getTimes={getTimes}
                      from={from}
                      to={to}
                      classType={classType}
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div
        className="modal fade"
        id={"itineraryInfoModal" + keys}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="itineraryInfoLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="itineraryInfoLabel">
                Itinerary Information
              </h5>
            </div>
            <div className="modal-middle unmuted-p bg-light pm-30">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <div className="bg-white mb-3 ml-15">
                      <div className="heading text-bold">
                        <p className="float-left">
                          {props.items.fare.flight_from} - {props.items.fare.flight_to}
                        </p>
                        <p className="float-right">
                          Journey Time :{" "}
                          {items.choosed_flight_fare_id &&
                            getTimes(items.choosed_flight_fare_id.journeyTime)}
                        </p>
                        <div className="clearfix" />
                      </div>
                      <div className="flight-detail">
                        <div className="itinerary devision-container">
                          {items.content &&
                            items.content.map((list, key) => {
                              return (
                                <div className="" key={key}>
                                  <div className="flight-info">
                                    <div className="details main min-h-auto">
                                      <div className="name">
                                        <div className="logo">
                                          <img
                                            className="h-auto"
                                            src={
                                              list.flight_image
                                                ? list.flight_image
                                                : "/assets/images/booking/flight-dummy.png"
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div className="info">
                                          <p className="text-bold pt-3">
                                            {items.choosed_flight_fare_id &&
                                              items.choosed_flight_fare_id
                                                .airLine}
                                            {items.content
                                              ? "  " +
                                              items.content[0].flight_airline
                                              : " "}
                                            {items.content
                                              ? " - " +
                                              items.content[0].flight_number
                                              : " "}
                                            {/* <span className="muted-p">
                                            Flight AA6656 in Economy class
                                          </span> */}
                                          </p>
                                          {/* <p>
                                          (operated by Vistara as Flight BA118)
                                        </p> */}
                                        </div>
                                        <div className="clearfix" />
                                      </div>
                                    </div>

                                    <div className="clearfix" />
                                  </div>
                                  <div className="flight-info">
                                    <div className="main-p m-0">
                                      <div className="info details main pr-3 min-h-auto">
                                        <p className="text-bold">
                                          Departs at &nbsp;
                                          {list.flight_from_city}{" "}
                                          {airport[list.flight_from_city] &&
                                            airport[list.flight_from_city].c &&
                                            airport[list.flight_from_city].c}
                                          ,{" "}
                                          {airport[list.flight_from_city] &&
                                            airport[list.flight_from_city].n &&
                                            airport[list.flight_from_city].n}
                                          {list.flight_departure_terminal &&
                                            "IN at Terminal " +
                                            list.flight_departure_terminal}
                                        </p>
                                      </div>
                                      <div className="details co2 min-h-auto">
                                        <p className="text-bold">
                                          <span className="muted-p"> On </span>{" "}
                                          {list.flight_depature_date_utc}
                                        </p>
                                      </div>
                                      <div className="details economy text-right min-h-auto">
                                        <p className="text-bold">
                                          <span className="muted-p"> at </span>{" "}
                                          {list.flight_depature_time}
                                        </p>
                                      </div>
                                      <div className="clearfix" />
                                    </div>
                                  </div>
                                  <div className="flight-info">
                                    <div className="main-p m-0 border-0">
                                      <div className="info details main pr-3 min-h-auto">
                                        <p className="text-bold">
                                          Arrives{" "}at{" "}
                                          {airport[list.flight_to_city] &&
                                            airport[list.flight_to_city].c &&
                                            airport[list.flight_to_city].c}
                                          ,{" "}
                                          {airport[list.flight_to_city] &&
                                            airport[list.flight_to_city].n &&
                                            airport[list.flight_to_city].n}{" "}
                                          {list.flight_arrival_terminal &&
                                            "Terminal " +
                                            list.flight_arrival_terminal}
                                        </p>
                                      </div>
                                      <div className="details co2 min-h-auto">
                                        <p className="text-bold">
                                          <span className="muted-p">On</span>{" "}
                                          {list.flight_arrival_date_utc}
                                        </p>
                                      </div>
                                      <div className="details min-h-auto economy text-right">
                                        <p className="text-bold">
                                          <span className="muted-p"> at </span>{" "}
                                          {list.flight_arrival_time}
                                        </p>
                                      </div>
                                      <div className="clearfix" />
                                    </div>
                                  </div>
                                  {items.content.length !== key + 1 && (
                                    <div className="flight-divider pb-4 muted-p">
                                      {/* <div>Layover at Delhi, 1h 20m</div> */}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 mr-15">
                    <table className="table itinerary-table">
                      <thead>
                        <tr className="muted-p border-0">
                          <th className="border-0">Fare Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.choosed_flight_fare_id && (
                          <tr className="text-bold">
                            <td className="border-0 pb-4 ">
                              {props.items.fare.flight_from} - {props.items.fare.flight_to}
                            </td>
                            <td className="border-0 text-right">
                              {currency} &nbsp;
                              <NumberFormat
                                value={
                                  items.choosed_flight_fare_id &&
                                  items.choosed_flight_fare_id.fareInfo.totalOverAllPrice.toFixed(
                                    2
                                  )
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                              />{" "}
                              - {items.choosed_flight_fare_id.type}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <table className="table itinerary-table">
                      <thead>
                        <tr className="muted-p border-0">
                          <th className="border-0">
                            Tax Details - {items.choosed_flight_fare_id.type}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border-0">Base Fare</td>
                          <td className="border-0 text-right">
                            {currency} &nbsp;
                            <NumberFormat
                              value={
                                items.choosed_flight_fare_id.fareInfo &&
                                items.choosed_flight_fare_id.fareInfo.flightAdultFare.toFixed(
                                  2
                                )
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                        </tr>
                        {items.choosed_flight_fare_id.fareInfo.serviceFee !==
                          0 && (
                            <tr>
                              <td className="border-0">Service Fee</td>
                              <td className="border-0 text-right">
                                {currency} &nbsp;
                              <NumberFormat
                                  value={items.choosed_flight_fare_id.fareInfo.serviceFee.toFixed(
                                    2
                                  )}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </td>
                            </tr>
                          )}
                        <tr>
                          <td className="border-0">Taxes and Fees</td>
                          <td className="border-0 text-right">
                            {currency} &nbsp;
                            <NumberFormat
                              value={
                                items.choosed_flight_fare_id.fareInfo &&
                                items.choosed_flight_fare_id.fareInfo.gst.toFixed(
                                  2
                                )
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                        </tr>
                        {/* <tr>
                          <td className="border-0">GST</td>
                          {items.fare.flight_gst !== 0 ?
                          <td className="border-0 text-right">
                            {currency} &nbsp;
                            <NumberFormat
                              value={
                                items.fare.flight_gst
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>:"-"}
                        </tr> */}
                      </tbody>
                    </table>
                    <div className="accordion mb-4" id="accordionExample">
                      <div className="card">
                        <div
                          id="collapseOne"
                          className="collapse show border-bottom"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            {/*
                                                        {items.choosed_flight_fare_id && items.choosed_flight_fare_id.fareInfo && items.choosed_flight_fare_id.fareInfo.taxBreakUp &&
                                                            Object.keys(items.choosed_flight_fare_id.fareInfo.taxBreakUp[0]).map((key, index) => {
                                                                if (items.choosed_flight_fare_id.fareInfo.taxBreakUp[0][key] != null)
                                                                    return (
                                                                        <div key={index} className="row">
                                                                            <div className="col-6">
                                                                                <p className="float-left text-capitalize py-2">

                                                                                    {key.split("_").join(" ")}
                                                                                </p>
                                                                            </div>
                                                                            <div className=" col-6">
                                                                                <p className="float-right text-capitalize py-2">
                                                                                    {currency} &nbsp;
                                                                            <NumberFormat value={items.choosed_flight_fare_id.fareInfo.taxBreakUp[0][key].toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            })
                                                        }
                                                         */}
                            {/* Added by sethu */}
                            {(items.fare.flight_gst !== 0) ?
                              <div key={props.index} className="row">
                                <div className="col-6">
                                  <p className="float-left text-capitalize py-2">GST</p>
                                </div>


                                <div className=" col-6">
                                  <p className="float-right text-capitalize py-2">
                                    {currency} &nbsp;
                                <NumberFormat
                                      value={
                                        items.fare.flight_gst
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </p>
                                </div>
                              </div> : ""
                            }
                            {/* End */}

                            {items.fare &&
                              items.fare.tax_break_up &&
                              Reflect.ownKeys(items.fare.tax_break_up).map(
                                (taxBreakKey, index) => (
                                  <TaxBreakUpFlight
                                    key={taxBreakKey}
                                    taxBreakKey={taxBreakKey}
                                    taxBreakValue={Number(
                                      items.fare.tax_break_up[taxBreakKey]
                                    )}
                                    index={index}
                                    currency={currency}
                                  />

                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="total-amt">
                      <p className="float-left">
                        Total for 1 Adult Passenger -{" "}
                        {items.choosed_flight_fare_id.type}
                      </p>
                      <p className="float-right text-bold">
                        {currency} &nbsp;
                        <NumberFormat
                          value={
                            items.choosed_flight_fare_id &&
                            items.choosed_flight_fare_id.fareInfo.totalOverAllPrice.toFixed(
                              2
                            )
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </p>
                      <div className="clearfix" />
                    </div>
                    <br />

                    <p className="pt-3">
                      {/* (as of Tue Nov 06 2018 15:59:05 GMT+0530 (India Standard
                      Time)) */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-last pm-30 text-right">
              <button
                type="button"
                className="btn btn-badge"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="openOutofPolicy"
        data-target={"#travelPolicyModal"}
        data-toggle="modal"
        data-backdrop="static"
        data-keyboard="false"
      />
      <div
        className="modal fade"
        id={"travelPolicyModal"}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="travelPolicyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="travelPolicyLabel">
                Travel Policy Violation
              </h5>
              <span className="close" data-dismiss="modal" />
            </div>
            <div className="modal-middle">
              <div className="travel_alert d-block py-4 pm-30">
                <div className="alert-detail">
                  <p>
                    <i className="material-icons">info</i> This flight is not in
                    compliance with the following travel rule(s)
                  </p>
                </div>
                {/* <p><span className="mr-2"><i className="material-icons">info</i></span>This flight is not in compliance with the following travel rule(s)</p> */}
              </div>
              <div className=" pm-30 bg-light">
                {/* {getValue(state,FILGHT_CONSTANT.VIOLATIONS).map((rule, ruleKey) => { */}

                {getValue(state, FILGHT_CONSTANT.VIOLATION, []).map((rule, ruleKey) => {


                  // {state ?.storePolicyCart ?.e ?.violations &&
                  //   state ?.storePolicyCart ?.e ?.violations.map((rule, ruleKey) => {
                  return (
                    <p key={ruleKey} className="mb-2">
                      <i className="material-icons">warning</i> {rule.message}
                      .
                    </p>
                  );
                })}
                <p className="my-3">
                  Please choose the reason for selecting this travel option, if
                  more Than one reason applies, choose the most applicable. This
                  reason Applies to this entire trip.
                </p>
                <div className="form-group custom  mb-0">
                  <div className="dropdown">
                    <select
                      className="form-control"
                      onChange={e => handleChangeReasonId(e)}
                    >
                      <option value="">Select Reason</option>
                      {state.storePolicyCart &&
                        state.storePolicyCart.e &&
                        state.storePolicyCart.e.violations &&
                        state.storePolicyCart.e.violations.map(
                          (itemList, keyItme) => {
                            return (
                              <option
                                value={itemList.violationCode}
                                key={keyItme}
                              >
                                {itemList.policyViolation?.description} -
                                {itemList.violationCode}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="pm-30">
                <p className="mb-3">
                  Please explain why you have chosen this flight
                </p>
                <div className="form-group custom textarea">
                  <textarea
                    data-gramm_editor="false"
                    spellCheck="false"
                    onChange={e => handleGetReason(e)}
                    rows="2"
                    name="reason"
                    className="form-control"
                    value={state.reasons}
                    placeholder="&nbsp;"
                  />
                  <label>Comment</label>
                </div>
                <div className="modal-last btn-group-margin ">
                  <button
                    onClick={e =>
                      addToCart(
                        state.storePolicyCart.e,
                        state.storePolicyCart.tripType,
                        state.storePolicyCart.uniqueId
                      )
                    }
                    type="button"
                    className="btn"
                  >
                    Add
                  </button>
                  <button
                    onClick={e => cancelPolicy()}
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FlightList;
