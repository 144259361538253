import React, { Component } from "react";
import EditFlightDiscountsPage from "./edit-air-discounts-html";
import { travelDiscountServices } from "../../../../../_services";
import { toast } from "react-toastify";

const filterAirline = inputValue => {
  return this.state.allAirlines.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};
const loadOptions = (inputValue, callback) => {
  setTimeout(() => {
    callback(filterAirline(inputValue));
  }, 1000);
};
class EditAirDiscounts extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      travelCategoryId: localStorage["travelCategoryId"],
      allTravelCategories: [],
      matchedCategory: {},
      allAirlines: [],
      selectedAirline: "",
      discountCode: "",
      configId: "",
      editDiscountCodeDetails: {},
      editDiscountCodeDetailId: {}
    };
  }
 async componentDidMount() {
    this.gettravelCategory();
    this.getAirlines();
    // console.log(this.props.match.params, "this.props.match.params");
    this.setState({
      configId: this.props.match.params.id,
      editDiscountCodeDetails: this.props.match.params,
      selectedAirline: {
        value: this.props.match.params.vendorId,
        label: this.props.match.params.vendor
      }
      
    });
  }

  handleInputChange = newValue => {
     this.setState({
      selectedAirline: newValue,
    });
    return newValue;
  };

  handleDiscountInputHandler = event => {
    this.setState({
     editDiscountCodeDetails: {
        ...this.state.editDiscountCodeDetails,
        discountCode: event.target.value
      }
     
    });
  };

  getAirlines = async () => {
    try {
      let res = await travelDiscountServices.getAllAirlines();
      this.setState({
        allAirlines: res ? res.result.map(item => ({
          label: item.name, 
          value: item.id

        })): null
      });
      let vendorId=this.state.editDiscountCodeDetails.vendorId;
      let vendorIdObject = [];
       vendorIdObject = res.result.filter((item) => {
       if(item.id===vendorId) return({label: item.name,value: item.id})
      })
      this.setState({editDiscountCodeDetailId:{label:vendorIdObject[0].name,value:vendorIdObject[0].id}})
    } catch (err) {
      // console.log(err);
    }
  };

  gettravelCategory = async () => {
    try {
      const { id: productId } = this.props.match.params;
      const response = await travelDiscountServices.getTravelCategories();
      const allTravelCategories = response.result;
      const travelCategory = allTravelCategories.find(
        category => category.id === this.state.travelCategoryId
      );
      this.setState({
        matchedCategory: travelCategory,
        configId: productId,
        allTravelCategories
      });
    } catch (e) {
      // console.log(e)
    }
  };

  UpdateAirdiscount = async () => {
    const payload = {
      id : this.state.editDiscountCodeDetails.itemId,
      travelCategoryId: this.state.travelCategoryId,
      vendor: this.state.selectedAirline.value,
      discountCode: this.state.editDiscountCodeDetails.discountCode
    };
    let res = await travelDiscountServices.UpdateTravelDiscount(
      this.state.configId,
      payload
    );
    if (res) {
      toast.success(res.message);
      this.props.history.push(
        "/administration/travel/add-travel-discount/" + this.state.configId
      );
    }
  };

  onCancelDiscounthandler = async() => {
    await this.props.history.push(
      "/administration/travel/add-travel-discount/" + this.state.configId
    )
  }

  render() {
    return (
      <div>
        <EditFlightDiscountsPage
          {...this.state}
          handleInputChange={this.handleInputChange}
          handleDiscountInputHandler={this.handleDiscountInputHandler}
          UpdateAirdiscount={this.UpdateAirdiscount}
          onCancelDiscounthandler={this.onCancelDiscounthandler}
          
        />
      </div>
    );
  }
}
export default EditAirDiscounts;
