/* eslint-disable default-case */
import React, { Component } from "react";
import { Input, Select } from "./_forms";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import "react-day-picker/lib/style.css";
import travelFieldConstants from "./travelFieldConstants";
import AsyncSelect from "react-select/lib/Async";
import moment from 'moment';

export default class TravelRenderedFields extends Component {
  renderField = type => {
    switch (type) {
      case travelFieldConstants.DATA_TYPE_AMOUNT:
        return (
          <div className="form-group custom">
            <Input
              type="number"
              placeholder={type}
              label={this.props.fieldItem.name}
              value={this.props.fieldValue ? this.props.fieldValue : ""}
              // error={this.props.error.message(
              //   this.props.fieldItem.name,
              //   this.props.fieldValue,
              //   "required"
              // )}
              controlFunc={e => this.props.fieldChangeHandler(e, type)}
            />
          </div>
        );

      case travelFieldConstants.DATA_TYPE_TEXT:
        return (
          <div className="form-group custom">
            <Input
              type="text"
              placeholder={type}
              label={this.props.fieldItem.name}
              // error={this.props.error.message(
              //   this.props.fieldItem.name,
              //   this.props.fieldValue,
              //   "required"
              // )}
              value={this.props.fieldValue ? this.props.fieldValue : ""}
              controlFunc={e => this.props.fieldChangeHandler(e, type)}
            />
          </div>
        );

      case travelFieldConstants.DATA_TYPE_LIST_MANAGEMENT:
        return (
          <div className="form-group custom labelup textarea">
            <label> {this.props?.fieldItem?.name || "Select DropDown"} </label>
            <Select
              placeholder={
                this.props?.dropdownValue?.itemName || "Select a group"
              }
              keyName="itemName"
              options={this.props.dataTypeValueDetail}
              controlFunc={e => this.props.selectChangeHandler(e, type)}
            />
             {/* {this.props.error.message(
                this.props.fieldItem.name,
                this.props.fieldValue,
                "required"
              )} */}
          </div>
        );

      case travelFieldConstants.DATA_TYPE_NUMBER:
        return (
          <div className="form-group custom">
            <Input
              type="number"
              placeholder={type}
              label={this.props.fieldItem.name}
              value={this.props.fieldValue ? this.props.fieldValue : ""}
              controlFunc={e => this.props.fieldChangeHandler(e, type)}
            />
          </div>
        );

      case travelFieldConstants.DATA_TYPE_NUMBER_OF_DAYS:
        return (
          <div className="form-group custom">
            <Input
              type="number"
              placeholder={type}
              value={this.props.fieldValue ? this.props.fieldValue : ""}
              controlFunc={e => this.props.fieldChangeHandler(e, type)}
              label={this.props.fieldItem.name}
            />
          </div>
        );
      case travelFieldConstants.DATA_TYPE_NUMBER_OF_MINUTES:
        return (
          <div className="form-group custom">
            <Input
              type="number"
              placeholder={type}
              value={this.props.fieldValue ? this.props.fieldValue : ""}
              controlFunc={e => this.props.fieldChangeHandler(e, type)}
              label={this.props.fieldItem.name}
            />
          </div>
        );
      case travelFieldConstants.DATA_TYPE_NUMBER_OF_HOURS:
        return (
          <div className="form-group custom">
            <Input
              type="number"
              placeholder={type}
              label={this.props.fieldItem.name}
              value={this.props.fieldValue ? this.props.fieldValue : ""}
              controlFunc={e => this.props.fieldChangeHandler(e, type)}
            />
          </div>
        );
      case travelFieldConstants.DATA_TYPE_DATE:
        return (
          <div>
            <DayPickerInput
              formatDate={formatDate}
              dayPickerProps={{ locale: 'en-gb', localeUtils: MomentLocaleUtils, disabledDays: { after: new Date() } }}
              onDayChange={e => this.props.fieldDateChangeHandler(e, type)}
              // selectedDays={
              //   moment(new Date(this.props.fieldValue),'DD/MM/YYYY')? new Date(this.props.fieldValue): new Date()
              // }
              format="LL"
              placeholder={(this.props.fieldValue)? moment(this.props.fieldValue).format('DD MMM YYYY'): "DD MMM YYYY"}
            />
          </div>
        );
      case travelFieldConstants.DATA_TYPE_SEARCH:
        return (
          <div className="form-group custom">
            <Input
              type="text"
              placeholder={type}
              label={this.props.fieldItem.name}
              value={this.props.value ? this.props.value : 0}
              controlFunc={e => this.props.fieldSearchHandler(e, type)}
            />
          </div>
        );

      case travelFieldConstants.DATA_TYPE_CONDITIONAL_DATE:
        return (
          <div className="form-group custom labelup textarea">
            <label> {this.props?.fieldItem?.name || "Select Date"} </label>
            <Select
              placeholder={this.props.selectedPlaceholder || "Select a group"}
              keyName="condition"
              options={this.props.dataTypeValueDetail}
              controlFunc={e => this.props.selectChangeHandler(e, type)}
            />
          </div>
        );

      case travelFieldConstants.DATA_TYPE_CONDITIONAL_NUMBER:
        return (
          <div className="form-group custom labelup textarea">
            <label> {this.props?.fieldItem?.name || "Select Number"} </label>
            <Select
              placeholder={
                this.props?.dropdownValue?.condition || "Select a group"
              }
              keyName="condition"
              options={this.props?.fieldItem?.dataTypeDetail?.conditionList}
              controlFunc={e => this.props.selectChangeHandler(e, type)}
            />
          </div>
        );

      case travelFieldConstants.DATA_TYPE_CONDITIONAL_DROP_DOWN:
      case travelFieldConstants.DATA_TYPE_CHEAPEST_DROPDOWN:
      case travelFieldConstants.DATA_TYPE_REFUNDABLE_NON_REFUNDABLE_DROP_DOWN:
        return (
          <div className="form-group custom labelup textarea">
            <label> {this.props?.fieldItem?.name || "Select DropDown"} </label>
            <Select
              placeholder={
                this.props?.dropdownValue?.condition ||
                "Select Conditional Value"
              }
              keyName="condition"
              options={this.props.conditionalOptions || []}
              controlFunc={e => this.props.selectChangeHandler(e, type)}
            />
          </div>
        );

      case travelFieldConstants.DATA_TYPE_AMOUNT_PERCENTAGE_DROP_DOWN:
        return (
          <div className="form-group custom labelup textarea">
            <label>
              {" "}
              {this.props?.fieldItem?.name || "Select Percentage Dropdown"}{" "}
            </label>
            <Select
              placeholder={
                this.props?.dropdownValue?.condition || "Select percentage"
              }
              keyName="condition"
              options={this.props?.fieldItem?.dataTypeDetail?.conditionList}
              controlFunc={e => this.props.selectChangeHandler(e, type)}
            />
          </div>
        );

      case travelFieldConstants.DATA_TYPE_MILE_KM_DROP_DOWN:
        return (
          <div className="form-group custom labelup textarea">
            <label> {this.props?.fieldItem?.name || "Select DropDown"} </label>
            <Select
              placeholder={
                this.props?.dropdownValue?.condition ||
                "Select Mile Km Dropdown"
              }
              keyName="condition"
              options={this.props?.fieldItem?.dataTypeDetail?.conditionList}
              controlFunc={e => this.props.selectChangeHandler(e, type)}
            />
          </div>
        );

      case travelFieldConstants.DATA_TYPE_DIFFERENT_LIST:
        return (
          <div>
            <div className="form-group custom labelup textarea">
              <label> {this.props?.fieldItem?.name || "Select DropDown"} </label>
              <AsyncSelect
                noOptionsMessage={({ inputValue }) =>
                  inputValue ? "No results." : "Start typing to search..."
                }
                placeholder={
                  this.props?.fieldItem?.selectedDropdown
                    ? this.props?.fieldItem?.selectedDropdown
                    : "Select"
                }
                isMulti
                loadOptions={e => this.props.loadOptions(e,this.props?.fieldItem?.displayType)}
                value={
                  this.props?.fieldItem?.selectedDropdown
                    ? this.props?.fieldItem?.selectedDropdown
                    : "nothing"
                }
                onChange={e =>
                  this.props.selectDifferentListChangeHandler(
                    e,
                    this.props?.fieldItem?.dataTypeValueDetail[0]?.conditionID
                  )
                }
              />
            </div>
          </div>
        );

      case travelFieldConstants.DATA_TYPE_SUPER_ADMIN_LIST:
        return (
          <div className="form-group custom labelup textarea">
            <label> {this.props?.fieldItem?.name || "Select DropDown"} </label>
            <Select
              placeholder={
                this.props?.dropdownValue?.itemName || "Select a group"
              }
              keyName="itemName"
              options={this.props?.dataTypeValueDetail}
              controlFunc={e => this.props.selectChangeHandler(e, type)}
            />
          </div>
        );

      default:
        return null;
    }
  };

  render() {
    return <div>{this.renderField(this.props.type)}</div>;
  }
}
