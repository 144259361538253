import React, { Component } from "react";
import { NavLink } from "react-router-dom";

const StaticMenu = props => {
  return (

    <ul className="nav nav-tabs px-0">
      <li>
        <NavLink
          className="show"
          to="/administration/company/admin-forms/forms/list"
        >
          Forms
          </NavLink>
      </li>
      <li>
        <NavLink
          className="show"
          to="/administration/company/admin-forms/forms-fields/list"
          aria-current="page"
        >
          Forms Fields
          </NavLink>
      </li>
      <li>
        <NavLink
          className="show"
          to="/administration/company/admin-forms/fields/list"
        >
          Fields
          </NavLink>
      </li>
      <li>
        <NavLink
          className="show"
          to="/administration/company/admin-forms/connected-lists/lists"
        >
          List Creation
          </NavLink>
      </li>
    </ul>
  );
};

export default StaticMenu;
