import React, { Component, createRef } from "react";
import Loader from "react-loader-spinner";
import { companyIdKey } from "src/_constants";
import { VisaModal } from "../visa";
import { Visa } from "src/_services";
import { createLogger } from "src/_helpers";
import { toast } from "react-toastify";
// import $ from "jquery";

export const visaPageLogger = createLogger("VisaPage");

class VisaPage extends Component {
  state = {
    allCountries: [],
    popularCountries: [],
    fetchingCountries: false,
    companyId: window.localStorage.getItem(companyIdKey) || null,
    showMore: false,
    error: null,
    isApplyVisaModalOpen: false
  };

  visa = new Visa();

  modalRef = createRef();

  fetchCountryListLogger = visaPageLogger.extend("fetchCountryList");
  fetchCountryList = async () => {
    const { companyId } = this.state;

    if (!companyId) {
      return;
    }

    this.fetchCountryListLogger("Fetching list of countries...");

    this.setState({
      fetchingCountries: true,
      error: null
    });

    try {
      const allCountries = await Visa.getCountries({ companyId });
      const popularCountries =
        allCountries.length > Visa.defaultPopularCountries.length
          ? allCountries.filter(country =>
            Visa.defaultPopularCountries.some(
              c => c.itemName === country.itemName
            )
          )
          : allCountries;

      this.fetchCountryListLogger("Fetched countries: %O", allCountries);

      this.setState({
        allCountries,
        popularCountries,
        fetchingCountries: false,
        error: null
      });
    } catch (error) {
      this.setState({
        fetchingCountries: false,
        error
      });
      toast.error("Could not load countries");
    }
  };

  updateCompanyId = ev => {
    const { key, newValue, oldValue } = ev;

    if (key === companyIdKey && oldValue !== this.state.companyId) {
      this.setState({
        companyId: newValue
      });
    }
  };

  handleSubmit = async visaInfo => {
    try {
      const savedVisaInfo = await this.visa.applyNew(visaInfo);
      // TODO: do something with the savedVisaInfo
      return savedVisaInfo;
    } catch (error) {
      toast.error("Could not apply visa");
      return { error };
    }
  };

  componentWillMount() {
    visaPageLogger("Mounting VisaPage...");

    window.addEventListener("storage", this.updateCompanyId);
    this.fetchCountryList();
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    /* $("#applyforvisaModal")
      .on("hidden.bs.modal", this.toggleIsOpenApplyVisaModal(false))
      .on("shown.bs.modal", this.toggleIsOpenApplyVisaModal(true)); */
  }

  toggleIsOpenApplyVisaModal = isOpen => () => {
    this.setState(isApplyVisaModalOpen => ({
      isApplyVisaModalOpen: isOpen != null ? isOpen : !isApplyVisaModalOpen
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.companyId !== this.state.companyId) {
      this.fetchCountryList();
    }

    /* if (prevState.isApplyVisaModalOpen !== this.state.isApplyVisaModalOpen) {
      $("#applyforvisaModal").modal(
        this.state.isApplyVisaModalOpen ? "show" : "hide"
      );
    } */
  }

  componentWillUnmount() {
    visaPageLogger("Unmounting VisaPage...");

    window.removeEventListener("storage", this.updateCompanyId);
    /* $("#applyforvisaModal")
      .off("hidden.bs.modal")
      .off("shown.bs.modal")
      .modal("dispose"); */
  }

  toggleShowMore = ev => {
    ev.preventDefault();
    this.setState(({ showMore }) => ({
      showMore: !showMore
    }));
  };

  render() {
    const {
      allCountries,
      fetchingCountries,
      showMore,
      isApplyVisaModalOpen
    } = this.state;
    const { popularCountries } = this.state;

    return (
      <div className="p-padding">
        <div  className="form-holder">
          <button
            type="button"
            className="btn float-right"
            data-toggle="modal"
            data-target="#applyforvisaModal"
          >
            Apply for visa
                      </button>
          <h6  className="sub-heading pt-2 mb-4">Popular Countries</h6>
          <div  className="table-container">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>
                      Popular Countries
                    </th>
                    <th>
                      Download
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>United States</td>
                    <td>
                      <div className="btn-group-margin">
                        <a className="mr-3" href="/assets/required-documnets.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              cloud_download
                            </i>
                            <span className="hover-name right-top">Required Documnets</span>
                          </button>
                        </a>
                        <a href="/assets/visa-form.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              picture_as_pdf
                            </i>
                            <span className="hover-name right-top">Application Form</span>
                          </button>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Hong Kong</td>
                    <td>
                      <div className="btn-group-margin">
                        <a className="mr-3" href="/assets/required-documnets.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              cloud_download
                            </i>
                            <span className="hover-name right-top">Required Documnets</span>
                          </button>
                        </a>
                        <a href="/assets/visa-form.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              picture_as_pdf
                            </i>
                            <span className="hover-name right-top">Application Form</span>
                          </button>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>New Zealand</td>
                    <td>
                      <div className="btn-group-margin">
                        <a className="mr-3" href="/assets/required-documnets.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              cloud_download
                            </i>
                            <span className="hover-name right-top">Required Documnets</span>
                          </button>
                        </a>
                        <a href="/assets/visa-form.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              picture_as_pdf
                            </i>
                            <span className="hover-name right-top">Application Form</span>
                          </button>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Australia</td>
                    <td>
                      <div className="btn-group-margin">
                        <a className="mr-3" href="/assets/required-documnets.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              cloud_download
                            </i>
                            <span className="hover-name right-top">Required Documnets</span>
                          </button>
                        </a>
                        <a href="/assets/visa-form.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              picture_as_pdf
                            </i>
                            <span className="hover-name right-top">Application Form</span>
                          </button>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Canada</td>
                    <td>
                      <div className="btn-group-margin">
                        <a className="mr-3" href="/assets/required-documnets.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              cloud_download
                            </i>
                            <span className="hover-name right-top">Required Documnets</span>
                          </button>
                        </a>
                        <a href="/assets/visa-form.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              picture_as_pdf
                            </i>
                            <span className="hover-name right-top">Application Form</span>
                          </button>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Europe</td>
                    <td>
                      <div className="btn-group-margin">
                        <a className="mr-3" href="/assets/required-documnets.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              cloud_download
                            </i>
                            <span className="hover-name right-top">Required Documnets</span>
                          </button>
                        </a>
                        <a href="/assets/visa-form.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              picture_as_pdf
                            </i>
                            <span className="hover-name right-top">Application Form</span>
                          </button>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>India</td>
                    <td>
                      <div className="btn-group-margin">
                        <a className="mr-3" href="/assets/required-documnets.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              cloud_download
                            </i>
                            <span className="hover-name right-top">Required Documnets</span>
                          </button>
                        </a>
                        <a href="/assets/visa-form.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              picture_as_pdf
                            </i>
                            <span className="hover-name right-top">Application Form</span>
                          </button>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>China</td>
                    <td>
                      <div className="btn-group-margin">
                        <a className="mr-3" href="/assets/required-documnets.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              cloud_download
                            </i>
                            <span className="hover-name right-top">Required Documnets</span>
                          </button>
                        </a>
                        <a href="/assets/visa-form.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              picture_as_pdf
                            </i>
                            <span className="hover-name right-top">Application Form</span>
                          </button>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Philippines</td>
                    <td>
                      <div className="btn-group-margin">
                        <a className="mr-3" href="/assets/required-documnets.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              cloud_download
                            </i>
                            <span className="hover-name right-top">Required Documnets</span>
                          </button>
                        </a>
                        <a href="/assets/visa-form.pdf" download>
                          <button className="btn btn-nothing hover-v">
                            <i className="material-icons fs-20">
                              picture_as_pdf
                            </i>
                            <span className="hover-name right-top">Application Form</span>
                          </button>
                        </a>
                      </div>
                    </td>
                  </tr>
                  {/* {fetchingCountries && (
                    <tr>
                      <td>
                        <Loader
                          type="ThreeDots"
                          color="#somecolor"
                          height={80}
                          width={80}
                        />
                      </td>
                    </tr>
                  )} */}
                  {/* {popularCountries.map(country => {
                    return (
                      <tr key={country.id}>
                        <td>
                          <p>
                            <span>
                              <img
                                src="/assets/images/booking/flag_2.png"
                                alt="icon"
                              />
                            </span>
                            <span className="pl-4">{country.itemName}</span>
                          </p>
                        </td>
                      </tr>
                    );
                  })} */}
                  {/* {Math.abs(allCountries.length - popularCountries.length) &&
                    Math.abs(allCountries.length - popularCountries.length) >
                    Visa.defaultPopularCountries.length ? (
                      <tr>
                        <td>
                          <button onClick={this.toggleShowMore}>
                            {`show ${showMore ? "-" : "+"}${Math.abs(
                              allCountries.length - popularCountries.length
                            )} ${showMore ? "less" : "more"}`}
                          </button>
                        </td>
                      </tr>
                    ) : null}
                  {showMore &&
                    allCountries.map(country => {
                      return (
                        <tr key={country.id}>
                          <td>
                            <p>
                              <span>
                                <img
                                  src="/assets/images/booking/flag_2.png"
                                  alt="icon"
                                />
                              </span>
                              <span className="pl-4">{country.itemName}</span>
                            </p>
                          </td>
                        </tr>
                      );
                    })} */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Apply for Visa (modal) */}
        <VisaModal
          modalRef={this.modalRef}
          open={isApplyVisaModalOpen}
          onSubmit={this.handleSubmit}
          countries={allCountries.length ? allCountries : popularCountries}
        />
      </div>
    );
  }
}
export default VisaPage;
