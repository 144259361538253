import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { inject, observer } from "mobx-react";
import menuStore from "../../../../_store/menu.store"; 
@inject("menuStore")
@observer
class TreeNode extends Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: false };
  }
  onClick() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  storeParent = id => {
    localStorage.setItem("4cab8c", id);
    var results = this.searchTree(this.props.data, id);
    menuStore.subMenu(results);
  };
  searchTree = (element, matchingTitle) => {
    if (element.id === matchingTitle) {
      return element;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], matchingTitle);
      }
      return result;
    }
    return null;
  };

  render() {
    // const { getParent } = this.props;
    let subtree = null;
    if (
      this.props.data.children &&
      this.props.data.children[0].menuType === 0
    ) {
      subtree = this.props.data.children.map(
        function (child) {
          return (
            <TreeNode
              location={this.props.location}
              key={child.id}
              data={child}
            />
          );
        }.bind(this)
      );
    }

    if (subtree) {
      return (
        <li
          className="heading custom-dropdown"
          key={this.props.data.id}
          id={"part" + this.props.data.id}
        >
          <button
            data-toggle="collapse"
            data-target={"#id" + this.props.data.id}
            aria-expanded="true"
            aria-controls={"id" + this.props.data.id}
            className={
              this.props.location.search(this.props.data.frontEndUrl) !== -1
                ? "btn btn-nothing active"
                : "btn btn-nothing collapsed"
            }
          >
            {this.props.data.menuName}
            <i className="material-icons">
              chevron_right
            </i>
          </button>
          <ul
            className={
              this.props.location.search(this.props.data.frontEndUrl) !== -1
                ? "collapse inner-menu custom-dropdown-inner show"
                : "collapse inner-menu custom-dropdown-inner"
            }
            id={"id" + this.props.data.id}
            data-parent={"#part" + this.props.data.id}
          >
            {subtree}
          </ul>
        </li>
      );
    } else {
      return (
        <li key={this.props.data.id}>
          <NavLink
            onClick={e => this.storeParent(this.props.data.id)}
            to={this.props.data.frontEndUrl}
          >
            {this.props.data.menuName}
          </NavLink>
        </li>
      );
    }
  }
}
export default TreeNode;
