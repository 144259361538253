import React from 'react';
import { Input } from 'src/_components/common/_forms';

export default function ChangeApprover(props) {
  return (
    <div>
      <div className="form-group custom mb-0">
        <Input
          label="Search Approver"
          type="text"
          name="searchApprover"
          value={props.selectedApprover.name}
          controlFunc={e => props.handleChangeApprover(e)}
        />

      </div>

      {/* <div className="form-group custom labelup">
        <input type="text" className="form-control" placeholder="Search User" />
        <label>Approver Name</label>
      </div> */}
      {props.isSearchUserListOpen && (
        props.searchedUsers.length ?
          (<ul className="scroll-ul">
            {props.searchedUsers.map(user => {
              return (
                <li key={user.id} onClick={e => props.selectChangeApprover(user)} >
                  {user.name}
                </li>
              )
            })}
          </ul>) :
          (<div className="mb-4">
          </div>))
      }
    </div>
  )
}
