import React from 'react'; 
import { NavLink } from "react-router-dom";
const AuthApproveMenu = (props) => {
    const { changeListType,type } = props;
    return (
        <div className="audit-step"> 
            <button className={type === "AA"? "s1 active":"s1"} onClick={() => changeListType("AA")}> Authorized Approver list
            </button>
            <button className={type === "COA"? "s1 active":"s1"} onClick={() => changeListType("COA")}> Cost Object Approver list
            </button>
        </div>
    )
}
export default AuthApproveMenu;