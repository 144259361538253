import React, { Component } from 'react'

export default class AddToCart extends Component {
  render() {
    return (
      <div className="col-md-4">
      <div
          // added
          className="flight-price mt-4 mr-3"
      >
          <div className="cash">
              <span>
                  <h5>INR{" "}
                      2122
                  </h5>
                  <p>SL</p>
              </span>
          </div>
          <div
              className="d-button"
          >
              <i className="material-icons">chevron_right
                  {/* done */}
              </i>{" "} Add
          {/* Added to \n cart */}
          </div>
          <div className="clearfix"></div>
      </div>
  </div>
    )
  }
}
