export const ADMIN_CONSTANT = {
    COMPANY: {
        //admin-> company if sub section is their create accordingly
        WORKFLOW:{
            APPROVERLIST:"selectedRule[0].data.action.action_value.approvers"
        }
    },
    EXPENSE: {
        //admin-> expense
    },
    TRAVEL : {
        //admin -> travel 
    }
}