import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
const creditDepositSummary = props => {
  useEffect(()=>{
    localStorage.removeItem("from")
    localStorage.removeItem("formIdNew")
  },[])
  return (
    <div className="resp-btn-allocate">
    <ul className="bottom-amount">
      <li>
        <h6><span>Outstanding Advance</span> {" "}:{" "}{props.userBaseCurrency && props.userBaseCurrency}&nbsp;{props.cashAdvanceTotalAmount !== null ? (new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(props.cashAdvanceTotalAmount)) : '0.00'}</h6>
      </li>
      <li>
        <h6><span>Total Amount</span> : {props.userBaseCurrency && props.userBaseCurrency}&nbsp;{props.totalExpenseAmount !== null ? (new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(props.totalExpenseAmount)):'0.00'}</h6>
      </li>
      <li>
        <h6><span>Total Requested</span> : {props.userBaseCurrency && props.userBaseCurrency}&nbsp;{props.totalRequestAmount !== null ? (new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(props.totalRequestAmount)): '0.00'}</h6>
      </li>
    </ul>
    {localStorage.getItem("440a28") === "5dc5197e1382a2538aa3a8ca" ?"":
    <div className="padx py-2">
              {props.submitStatus === "Pending Submission" &&
                <NavLink to="/expense/allocation"
                  className="btn btn-block"
                >
                  Allocate
              </NavLink>
              }
            </div>
    // ""
}
          </div>

  );
};

export default creditDepositSummary;
