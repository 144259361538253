import React from "react";
import $ from "jquery";
import ReceiptImageModal from "../../components/receiptImageModal";
import { NavLink } from 'react-router-dom';


const reportHeaderUploadReceipt = props => {
  return (
    <div className="col-lg-3">
      {/* <div className="dtable">
        <div className="text-center dtablecell">
          <img src="/assets/images/booking/no-receipt.svg" />
          <p className="mt-2 text-black ">No Receipt</p>
        </div>
      </div>       */}
      {(props.submitStatus === "Pending Submission" ||
        props.submitStatus === "Approval" ||
        props.isFromWorkFlow) && (
          <div className="px-15 py-3">
            {!props.isFromWorkFlow ? (
              !props.allocationStatus ? (
                <h6 className="sub-heading mt-3">Upload Receipts</h6>
              ) : null
            ) : (
                props.s3ReceiptUrl.length > 0 && (
                  <h6 className="sub-heading mt-3">Uploaded Receipts</h6>
                )
              )}
            <div className="row">
              {props.policyDetail.missingReceiptAfidivate &&
                props.s3ReceiptUrl.length <= 0 &&
                props.allocationStatus ? (
                  <div className="col-12">
                    <div className="upload-receipt">
                      <i className="material-icons">cloud_upload</i>
                      <h6>Affidavit</h6>
                    </div>
                  </div>
                ) : null}
              {/* {props.s3ReceiptUrl.length>0?props.s3ReceiptUrl.map((image,index)=>
        <div key={index} className="col-md-2">
          <div className="upload-receipt">
           <img style={{width: '70px',height: '38px'}} src={image.uploadReceipt} alt="" />
          </div>
        </div>
        ):null} */}
              {!props.isFromWorkFlow && (
                <div className="col-12">
                  {!props.allocationStatus ? (
                    <div
                      className="upload-input"
                      data-toggle="dropdown"
                      onChange={props.onBrowseHeaderReceipt}
                    >
                      {/* <input
                  type="file"
                  name="uploadHeaderReceipt"
                /> */}
                      <div className="check-box">
                        <i className="material-icons">cloud_upload</i>
                        <div>Upload</div>
                      </div>
                    </div>
                  ) : null}

                  <div className="dropdown-menu dropdown-menu-right">
                    <button
                      type="button"
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#availableReciptModal"
                      onClick={props.onClickUploadReceiptStoreHandler}
                    >
                      Upload from receipt store
                  </button>

                    {!props.allocationStatus ? (
                      <button
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#uploadReceiptsModal"
                        onClick={props.handleClickUploadFromCompt}
                      >
                        Upload receipt from computer
                      </button>
                    ) : null}
                    {props.policyDetail.missingReceiptAfidivate &&
                      props.s3ReceiptUrl.length <= 0 &&
                      !props.allocationStatus ? (
                        <button
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#attachAffidavit"
                        >
                          Attach Affidavit
                        </button>
                      ) : null}
                  </div>
                </div>
              )}

              <div className="col-md-6" />
            </div>

            {props.s3ReceiptUrl.length > 0 ? (
              <button
                className="btn btn-block"
                data-toggle="modal"
                data-target="#hotelReceiptModal"
              >
                View All files
              </button>
            ) : (
                ""
              )}
            {/* {props.policyDetail.missingReceiptAfidivate && props.s3ReceiptUrl.length <= 0 && (props.allocationStatus || props.isFromWorkFlow)? */}
            {props.policyDetail.missingReceiptAfidivate &&
              props.s3ReceiptUrl.length <= 0 ? (
                props.isFromWorkFlow && props.allocationStatus ? (
                  <button
                    className="btn"
                    data-toggle="modal"
                    data-target="#createReceiptModal"
                  >
                    View Affidavit
                  </button>
                ) : (
                    props.allocationStatus &&
                    !props.isFromWorkFlow && (
                      <button
                        className="btn"
                        data-toggle="modal"
                        data-target="#createReceiptModal"
                      >
                        Detach Affidavit
                      </button>
                    )
                  )
              ) : null}
          </div>
        )}

      <div
        className="modal fade"
        id="hotelReceiptModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="hotelReceiptModal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content border-0">
            <div className="modal-head">
              <h6 className="modal-subtitle">New</h6>
              <h5 className="modal-title" id="availableReciptLable">
                Attached Receipts
              </h5>
            </div>
            <div className="modal-middle pm-30 bg-light">
              <div className="row">
                <div className="col-md-7">
                  <ul className="expense-reports receipts modal-receipt">
                    {props.s3ReceiptUrl.length > 0
                      ? props.s3ReceiptUrl.map((image, index) => {
                        let format = image.uploadReceipt.split(".");
                        let fileType = format[format.length - 1];
                        return (
                          <li
                            onClick={e =>
                              props.onLargeImageShowHandler(
                                image.uploadReceipt,
                                image.id
                              )
                            }
                            key={index}
                            className=""
                          >
                            {props.maxImageViewReceipt ===
                              image.uploadReceipt ? (
                                <div className="abs">
                                  <i className="material-icons">check_circle</i>
                                </div>
                              ) : (
                                ""
                              )}
                            <button type="button">
                              {(fileType === "pdf") ?
                                <div>
                                  <object data={image.uploadReceipt} type="application/pdf" width="100%" height="200">
                                    alt : <a href={image.uploadReceipt}>Attached Receipts.pdf</a>
                                  </object>
                                  <NavLink to={`//${image.uploadReceipt.replace(/^https?:\/\//, '')}`} target='_blank'>Open in New Tab </NavLink>
                                </div> :
                                <div
                                  className="image"
                                  style={{
                                    backgroundImage: `url(${image.uploadReceipt})`
                                  }}
                                />}
                              {/* <p className="desc">19 Apr, 2018</p> */}
                            </button>
                          </li>
                        )
                      })
                      : ""}
                  </ul>
                </div>
                {props.s3ReceiptUrl.length > 0 ? (
                  <div className="col-md-5">
                    <div className="expense-preview">
                      <h6 className="sub-heading">Preview</h6>
                      <img
                        src={props.maxImageViewReceipt}
                        className="img-fluid"
                        alt=""
                      />
                      {props.submitStatus !== "Approval" &&
                        !props.isFromWorkFlow && (
                          <button
                            onClick={props.onDeleteReceiptHandler}
                            type="button"
                            data-dismiss="modal"
                            className="btn mr-auto delete-button"
                          >
                            <i className="material-icons">delete</i>
                          </button>
                        )}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="btn-group-margin mt-4">
                <button className="btn" type="button" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="availableReciptModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="availableReciptLable"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h6 className="modal-subtitle">New</h6>
              <h5 className="modal-title" id="availableReciptLable">
                Available Receipts
              </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="row">
                <div className="col-md-7">
                  <ul className="expense-reports receipts modal-receipt">
                    {props.allReceipt
                      ? props.allReceipt.length &&
                      props.allReceipt.map((images, index) => {
                        return (
                          <li
                            key={index}
                            className={
                              $.inArray(
                                images.id,
                                props.selectedReceiptId
                              ) !== -1
                                ? "active"
                                : ""
                            }
                            onClick={e =>
                              props.onSelectReceiptHandler(
                                images.id,
                                images.s3ReceiptUrl
                              )
                            }
                          >
                            {$.inArray(images.id, props.selectedReceiptId) !==
                              -1 ? (
                                <div className="abs">
                                  <i className="material-icons">check_circle</i>
                                </div>
                              ) : (
                                ""
                              )}
                            <button type="button">
                              <div
                                className="image"
                                style={{
                                  backgroundImage: `url(${images.s3ReceiptUrl})`
                                }}
                              />
                              <p className="desc">19 Apr, 2018</p>
                            </button>
                          </li>
                        );
                      })
                      : null}
                  </ul>
                </div>
                <div className="col-md-5">
                  {props.allReceipt && props.allReceipt.length ? (
                    <div className="expense-preview">
                      <h6 className="sub-heading">Preview</h6>
                      <img
                        className="img-fluid"
                        id="preview-img"
                        src=""
                        alt=""
                      />
                    </div>
                  ) : (
                      <div className="expense-preview">
                        <h6 className="sub-heading">No Receipt Available.</h6>
                        <img
                          className="img-fluid"
                          id="preview-img"
                          src=""
                          alt=""
                        />
                      </div>
                    )}
                </div>
              </div>
              <div className="btn-group-margin mt-4">
                <button
                  data-dismiss="modal"
                  disabled={!props.selectedReceiptId.length > 0}
                  onClick={props.onSubmitReceiptHandler}
                  className="btn"
                >
                  Add
                </button>
                <button data-dismiss="modal" type="button" className="btn">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="uploadReceiptsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="uploadReceiptsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <ReceiptImageModal
            imageType={props.imageType}
            onFileUpload={props.onFileUpload}
            onFileBrowse={props.onFileBrowse}
            browseImage={props.browseImage}
          />
        </div>
      </div>
    </div>
  );
};

export default reportHeaderUploadReceipt;
