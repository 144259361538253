import React, { useState, useEffect } from "react";
import axios from 'axios';
import NumberFormat from "react-number-format";
import ETicketPage from "src/_components/e-ticket";
import InvoicePage from "src/_components/invoice";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import { getValue } from "src/_components/common/lodsh";
import { travelDiscountServices } from "../../../../../_services";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { FILGHT_CONSTANT } from "src/_components/common/constants/FLIGHT_CONSTANTS";
import  toast  from "react-toastify";

export const FlightCartDetail = props => {
  const {
    item,
    secondsToHms,
    removeCart,
    currentItem,
    nonEdit,
    ticketInvoice,
    eticket,
    enableEticket,
    eticketInfo,
    enableInvoice,
    invoice,
    invoiceInfo,
    closeWindow,
  } = props;
  let curreny = props.baseCurrency;
  let setup = item.fareContent ? item.fareContent.content[0].length : undefined;
  let baggage =
    item.baggageInfo && item.baggageInfo[0] ? item.baggageInfo[0] : [];

  // * find departure and arrival places
  let arrivalPlace = "";
  let destinationPlace = "";

  if (item?.flight_airport && item?.fareContent?.fare) {
    arrivalPlace =
      item?.flight_airport[item.fareContent.fare.from] ||
      item?.flight_airport[item?.flight_from];
    destinationPlace =
      item?.flight_airport[item.fareContent.fare.to] ||
      item?.flight_airport[item?.flight_to];
  } else if (item?.flight_from && item?.flight_to) {
    arrivalPlace = item?.flight_airport[item?.flight_from];
    destinationPlace = item?.flight_airport[item?.flight_to];
  }

  const [text, setText] = useState("");
  const [personalDayOffFrom, setPersonalDayOffFrom] = useState("");
  const [editDiscountCodeDetailId, setEditDiscountCodeDetailId] = useState({})
  const [allAirlines, setAllAirlines] = useState([])
  const [selectedAirline, setSelectedAirline] = useState("");
  const [listClass] = useState([
    { value: 1, label: "Economy" },
    { value: 2, label: "Premium Economy" },
    { value: 4, label: "Business" },
    { value: 5, label: "First Class" }
  ])
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState("");
  const [toDate, setToDate] = useState("");
  const today = new Date();
  const handleSubmit = (e) => {
    e.preventDefault();
    let url = `https://bigappcompany.co.in/demos/cms/contact`;
    let payload = new FormData()
    payload.append("product_name",`from_email=support@curiotechnologies.com&to_email=madhuri@bigappcompany.in&reason=${text}&subject=Cancel Trip`);
    axios.post(url, payload)
      .then(res => {
        if (res.status == 200) {
          // toast.success(res.data.message);
        }
      })
      .catch(err => {
        // toast.success(err);
        console.log('the error is ', err)
      })
  }


  let FlightConst = 'farecontent.fare.cancelprice';

  // console.log(
  //   props.item,
  //   "---flight detail---",
  //   arrivalPlace,
  //   "---",
  //   destinationPlace
  // );

  useEffect(() => {
    getAirlines();
  }, [])




  //------------Get all Air Lines ----------------

  const getAirlines = async () => {
    try {
      let res = await travelDiscountServices.getAllAirlines();
      setAllAirlines(res ? res.result.map(item => ({
        label: item.name,
        value: item.id
      })) : null
      );
      let vendorId = this.state.editDiscountCodeDetails.vendorId;
      let vendorIdObject = [];
      vendorIdObject = res.result.filter((item) => {
        if (item.id === vendorId) return ({ label: item.name, value: item.id })
      })
      setEditDiscountCodeDetailId({ label: vendorIdObject[0].name, value: vendorIdObject[0].id })
    } catch (err) {
      // console.log(err);
    }
  };

  //-------------------------------------------------------------


  //------------change selected airline ----------------


  const handleInputChange = newValue => {
    setSelectedAirline(newValue);
    return newValue;
  };


  return (
    <div className="white-card shadow-light mb-0">
      <div className="bg-light p-3">
        <div className="row">
          <div className="col-md-4">Employee Preferred</div>
          <div className="col-md-8">
            <div className="btn-group-margin text-right">
              {ticketInvoice && (
                <div className="d-inline-block">
                  <button
                    data-toggle="modal"
                    data-target="#cancelTripModal"
                    className="btn btn-nothing px-3"
                  >
                    Cancel
                </button>
                  {/* <button
                    data-toggle="modal"
                    data-target="#rescheduleTripModal"
                    className="btn btn-nothing px-3 border-left"
                  >
                    Reschedule
                </button> */}

                  <button
                    onClick={() => enableEticket()}
                    className="btn btn-nothing px-3 border-left"
                  >
                    E-Ticket
                </button>
                  <button
                    onClick={() => enableInvoice()}
                    className="btn btn-nothing px-3 border-left"
                  >
                    Invoice
                </button>
                  {eticket && (
                    <ETicketPage
                      closeWindow={closeWindow}
                      eticketInfo={eticketInfo}
                    />
                  )}
                  {invoice && (
                    <InvoicePage
                      closeWindow={closeWindow}
                      invoiceInfo={invoiceInfo}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="devision-container flight-iti pb-0">
        <div className="details main">
          <div className="name">
            <div className="logo">
              <img
                src={
                  item.fareContent.fare.flight_image
                    ? item.fareContent.fare.flight_image
                    : "/assets/images/booking/flight-dummy.png"
                }
                alt=""
              />
            </div>
            <div className="info">
              <p>
                <b>
                  {/* {item.flight_from} */}
                  {arrivalPlace ? arrivalPlace.n + ", " + arrivalPlace.c : ""}
                </b>{" "}
                to{" "}
                <b>
                  {/* {item.flight_to} */}
                  {destinationPlace
                    ? destinationPlace.n + ", " + destinationPlace.c
                    : ""}
                </b>
              </p>
              <p className="timings">
                {item.fareContent.fare.flight_arrival_date_utc} {" | "}{" "}
                {item.fareContent.fare.flight_depature_time} -{" "}
                {item.fareContent.fare.flight_arrival_time} |{" "}
                <i className="material-icons fs-14 align-middle">access_time</i>{" "}
                {secondsToHms(item.fareContent.content[0].flight_duration)}
              </p>
              <p className="class">
                {item.fareContent.content[0].flight_operating_airline} -{" "}
                {item.fareContent.fare.flight_airline_code} -{" "}
                {item.fareContent.content[0].flight_number}
              </p>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <div className="clearfix" />
        <div className="baggage mt-4">
          <div className="row">
            <div className="col-md-6">
              <p className="icon-lg">
                <i className="material-icons">work</i> Check In Baggage{" "}
                {getValue(item, FILGHT_CONSTANT.BAGGAGE_INFO, "- Unavailable")}
              </p>
            </div>
            <div className="col-md-6">
              <p className="icon-lg">
                <i className="material-icons">work</i> Cabin Baggage{" "}
                {getValue(item, FILGHT_CONSTANT.CABIN_BAGGAGE_INFO, "- Unavailable")}
              </p>
            </div>
            {item.flight_departure_terminal && (
              <div className="col-md-6 mt-3">
                <p className="icon-lg">
                  <i className="material-icons">flight</i> Departure Terminal
                  &nbsp; {item.flight_departure_terminal}
                </p>
              </div>
            )}
            {item.flight_arrival_terminal && (
              <div className="col-md-6 mt-3">
                <p className="icon-lg">
                  <i className="material-icons rotate180">flight</i> Arrival
                  Terminal &nbsp; {item.flight_arrival_terminal}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="white-card mb-0">
        <div className="pad">
          <div className="white-card border-only mb-0">
            <h6 className="sub-heading heading-blue">Fare Details</h6>
            <div className="pad-sm-sm">
              <div className="table-responsive">
                <table className="table table-borderless mb-0">
                  {(item.fareContent.fare || item.fareContent.fare) && (
                    <tbody>
                      <tr>
                        <td>
                          <p className="label">Base Fare</p>
                        </td>
                        <td className="text-right">
                          {curreny} &nbsp;
                          {/* {item.fareContent.fare.flight_adult_base_price && item.fareContent.fare.flight_adult_base_price} */}
                          <NumberFormat
                            value={
                              item.fareContent.fare.flight_base_price &&
                              item.fareContent.fare.flight_base_price.toFixed(2)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="label">Surcharges</p>
                        </td>
                        <td className="text-right">
                          {curreny} &nbsp;
                          <NumberFormat
                            value={
                              item.fareContent.fare.flight_total_tax
                                ? item.fareContent.fare.flight_total_tax.toFixed(
                                  2
                                )
                                : item.fareContent.fare.flight_service_fee.toFixed(
                                  2
                                )
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Total (1 Adults)</td>
                        <td className="text-right">
                          {curreny} &nbsp;
                          <NumberFormat
                            value={
                              item.fareContent.fare.flight_total_price &&
                              item.fareContent.fare.flight_total_price.toFixed(
                                2
                              )
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          {currentItem &&
            !currentItem.currentOrderNo &&
            currentItem.STATUS === "Approved" ? (
              <div>
                {/* <h6 className="sub-heading mt-4">Downloads</h6>
              <p className="mb-3"><a href="/assets/images/bg-img.jpg" download><i className="material-icons fs-18 mr-3">
                cloud_download
              </i> Boarding_pass.pdf</a></p>
              <p><a href="/assets/images/bg-img.jpg" download><i className="material-icons fs-18 mr-3">
                cloud_download
              </i> Meal_voucher.pdf</a></p> */}
              </div>
            ) : (
              ""
            )}
        </div>
      </div>

      <div
        className="modal fade"
        id="cancelTripModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancelTripModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document">
          <div className="modal-content border-0">
            <div className="modal-head">
              <button
                type="button"
                className="close text-white d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 className="modal-title" id="cancelTripModalLabel">
                Cancel Trip
                </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="row">
                <div className="col-md-6">
                  <div className="inner-header p-0 pb-5 mb-2">
                    <div className="shape-header">
                      <div className="left"></div>
                      <div className="right">
                        <h6 className="heading bg-transparent"> {item.fareContent.content[0].flight_operating_airline} -{" "}
                          {item.fareContent.fare.flight_airline_code} -{" "}
                          {item.fareContent.content[0].flight_number}</h6>
                        <p className="text"><b>{item.flight_from}</b> to <b>{item.flight_to}</b></p>
                        {/* <p className="text">Confirmed: <b>KC2345HD</b></p> */}
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  {/* <div className="table-container m-0">
                    <div className="table-responsive">
                      <table className="table table-striped table-selected">
                        <thead>
                          <tr>
                            <th>FARE CHARGES</th>
                            <th className="text-right"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Cancellation Charges</td> */}
                            {/* <td className="text-right"> */}
                              {/* {getValue(item,FlightConst,"Not Available")} */}
                              {/* {item.fareContent.fare.cancel_price ? item.fareContent.fare.cancel_price : "Not available"}{" "}{item.fareContent.fare.cancel_price ? item.CurrencyType : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>Date Change Charges</td>
                            <td className="text-right">
                              3000 INR
                          </td>
                          </tr>
                          <tr>
                            <td>Rescheduling Charges</td>
                            <td className="text-right">
                              {item.fareContent.fare.reschedule_price ? item.fareContent.fare.reschedule_price : "Not Available"} {""}{item.fareContent.fare.reschedule_price ? item.CurrencyType : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>*/}
                </div>
                <div className="col-md-6">
                  <div className="form-group custom textarea">
                    <textarea className="form-control"
                      data-gramm_editor="false"
                      spellCheck="false"
                      placeholder="&nbsp;"
                      required
                      name="text"
                      value={text}
                      onChange={e => setText(e.target.value)} />
                    <label>Reason <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-group custom textarea mt-5 mb-1">
                    <textarea className="form-control"
                      data-gramm_editor="false"
                      spellCheck="false"
                      placeholder="&nbsp;"
                      required />
                    <label>Your Remarks <span className="text-danger">*</span></label>
                  </div>
                  <p>Reason for doing activity behalf of traveler</p>
                </div>
              </div>
              <p className="pt-4"><b>Note:</b> Cancellation or Flight change indicative. Curio will stop accepting cancellation or change request 3 hours before flight departure. If the scheduled travel departure is within 3 hours Please contact the respective airline directly for any changes or cancellation.</p>

              <div className="modal-last btn-group-margin pt-5">

                <button
                  type="button"
                  className="btn"
                  onClick={handleSubmit}>

                  Cancel Trip
                  </button>
                <button
                  data-dismiss="modal"
                  type="button"
                  className="btn close">
                  Close
                  </button>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="rescheduleTripModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="rescheduleTripModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document">
          <div className="modal-content border-0">
            <div className="modal-head">
              <button
                type="button"
                className="close text-white d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 className="modal-title" id="rescheduleTripModal">
                Reschedule Flight
                </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="row">
                <div className="col-md-6">
                  <p className="mb-4"><b>Sector :</b> {" "}
                    {arrivalPlace ? arrivalPlace.n + ", " + arrivalPlace.c : ""}
                    {" "}
                    -{" "}

                    {destinationPlace
                      ? destinationPlace.n + ", " + destinationPlace.c
                      : ""}
                  </p>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group custom labelup">
                    <label>Reschedule Type</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-border-basic dropdown-toggle btn-block"
                        data-toggle="dropdown"
                      >
                        Select
                  </button>
                      <div className="dropdown-menu">
                        <button type="button" className="dropdown-item">Option 1</button>
                        <button type="button" className="dropdown-item">Option 2</button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group custom labelup">
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      showTimeSelect
                      minDate={today}
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                    <label>From</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group custom labelup">
                    <label>airline <span className="text-danger">*</span></label>
                    <Select
                      value={selectedAirline}
                      options={allAirlines}
                      loadOptions={allAirlines}
                      onChange={handleInputChange}
                      placeholder="Search And Select"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group custom labelup">
                    <label>Class <span className="text-danger">*</span></label>
                    <Select
                      value={listClass.value}
                      options={listClass}
                      loadOptions={listClass}
                      // onChange={handleInputChange}
                      placeholder="Search And Select"
                    />
                  </div>
                </div>
              </div>
              <div className="lower-part border-top mt-2">
                <p className="py-4">Reschedule Charges are applicable</p>
                {/* <div classNmae="row">
                  <div classname="col-md-6">
                    <div className="table-container m-0">
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            <tr className="border-0">
                              <td className="border-0 pl-0">Reschedule Charge:</td>
                              <td className="border-0 pl-0">
                                <b>{item.fareContent.fare.reschedule_price ? item.fareContent.fare.reschedule_price : "Not available"}{" "}{item.fareContent.fare.reschedule_price ? item.CurrencyType : ""}
                                </b>
                              </td>
                            </tr>
                            <tr className="border-0">
                              <td className="border-0 pl-0">Date Change Charge:</td>
                              <td className="border-0 pl-0">
                                <b>2,250.00 Per Sector</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> */}

                <p className="mb-2 mt-3">Reschedule/Date Change rule:</p>
                <p>Incase if tickets is non-refundable/non-changable, complete fare will be charged.</p>
                <p>If you are selecting different airlines for Reschedule/Date Change then system does not allow to quote with your existing itinerary. So it has to be treated as fresh ticket  with additional cancellation cost.</p>
              </div>

              <div className="modal-last btn-group-margin pt-5">

                <button
                  type="button"
                  className="btn">

                  Send
                  </button>
                <button
                  data-dismiss="modal"
                  type="button"
                  className="btn close">
                  Cancel
                  </button>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
export default FlightCartDetail;
