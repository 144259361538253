import React, { Component } from "react";
import AddTravelDiscountPage from "./add-travel-discount-html";
import { travelDiscountServices } from "../../../../../_services";
import { toast } from "react-toastify";

class AddTravelDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: localStorage["440a28"],
      discounts: [],
      isLoading: false,
      travelCategories: [],
      travelCategoryName: "",
      ConfigId: ""
    };
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getTravelCategory();
  }

  // get all travel Category
  getTravelCategory = async () => {
    this.setState({ isLoading: true });
    let res = await travelDiscountServices.getTravelCategories();

    this.setState({
      travelCategories: res ? res.result : [],
      isLoading: false
    });
  };

  // getting discount according to travel category
  getDiscountCodesByCategoryHandler = async (value, categoryName) => {
    localStorage.setItem("travelCategoryId", value);
    let id = this.props.match.params.id;
    this.setState({ 
      travelCategoryName: categoryName,
      ConfigId: this.props.match.params.id
   });
    if (id) {
      this.setState({ isLoading: true });
      const res = await travelDiscountServices.getDiscountCodes(value, id);
      this.setState({
        discounts: res ? res.result.discountCodes : [],
        isLoading: false
      });
    }
  };
  // delete travel discount
  deleteTraveDiscount = async discountId => {
    const res = await travelDiscountServices.deleteTravelDiscount(
      this.state.ConfigId,
      discountId
    );
    if(res){
      toast.success(res.massage);
      this.getDiscountCodesByCategoryHandler();
    }else {
      toast.error(res.massage);
    }
  }
    

  render() {
    return (
      <div>
        <AddTravelDiscountPage
          {...this.state}
          travelCategoryId={localStorage.getItem("travelCategoryId")}
          getDiscountCodesByCategoryHandler={this.getDiscountCodesByCategoryHandler}
          deleteTraveDiscount={this.deleteTraveDiscount}
        />
      </div>
    );
  }
}
export default AddTravelDiscount;
