import React, { Component } from "react";
import PolicyStep1Page from "./policy-step1-html";
import { expensePolicies } from "../../../../../_services";
import { toast } from "react-toastify";
import { alertConstants } from "src/_constants";
import { serviceModules } from "src/_constants/serviceModule";

class PolicyStep1 extends Component {
  state = {
    companyId: localStorage["440a28"],

    policyName: null,
    selectedExpenseReportForm: null,
    selectedExpenseAllocationForm: null,
    selectedExpenseReportWorkFlow: null,
    editableBy: [],
    appliesTo: [],
    scanConfiguration: true,
    missingReceiptAfidivate: false,
    afidivateStatement: "",
    policyId: "5c7f6c190c3b31720f5edc67",
    serviceModuleId: "5c74ed3d77addf3b07acb014",
    limit: 10,
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,
    expensePolicies: [],
    selectedExpensePolicy: [],
    expenseReportFormId: "5c974daabcde0b0ab7812830", //Expense Report Form
    expenseAllocationFormId: "5c989f8562b0f2517b2f841a", //Expense Allocation Form
    expenseReportForms: [],
    expenseAllocationForms: [],
    workFlows: [],
    parentList: [],
    editableByList: [],
    appliesToList: []
  };
  // ------------------Handle Form -------------------------------------------

  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleERF = ERF => {
    this.setState({ selectedExpenseReportForm: ERF.value });
  };

  handleEAF = EAF => {
    this.setState({ selectedExpenseAllocationForm: EAF.value });
  };

  handleEWF = EWF => {
    this.setState({ selectedExpenseReportWorkFlow: EWF.value });
  };

  handleEditableBy = editable => {
    let editBy = [];
    editable.forEach(element => {
      editBy.push(element.value);
    });
    this.setState({ editableBy: editBy });
  };

  handleAppliesTo = appliesTo => {
    let editBy = [];
    appliesTo.forEach(element => {
      editBy.push(element.value);
    });
    this.setState({ appliesTo: editBy });
  };

  handleSC = SC => {
    this.setState({ scanConfiguration: SC.value });
  };

  missingReceiptAfidivate = event => {
    let newStatus = event.target.checked;
    this.setState({
      missingReceiptAfidivate: newStatus
    });
    if(newStatus == false){
      this.setState({
        afidivateStatement: ""
      })
    }
  };

  // * Check for validity of all the fields
  checkValidity = (payload) => {
    // console.log(payload);

    for (var key in payload){
      // console.log(payload[key])
      if(payload[key] === null || payload[key] === ''){
        return false;

      }if(Array.isArray(payload[key]) == true && payload[key].length == 0 ){
        return false;
      }
    }
    return true;
  }

  //  * End of check validity of all fields

  // * for creating the policy
  createPolicy = () => {
    let allValid = false;
    var data  = []
    if(this.state.missingReceiptAfidivate){
      data = {
        companyId: this.state.companyId,
        expenseAllocationForm: this.state.selectedExpenseAllocationForm,
        expenseReportForm: this.state.selectedExpenseReportForm,
        expenseReportWorkFlow: this.state.selectedExpenseReportWorkFlow,
        editableBy: this.state.editableBy,
        appliesTo: this.state.appliesTo,
        policyName: this.state.policyName,
        scanConfiguration: this.state.scanConfiguration,
        missingReceiptAfidivate: this.state.missingReceiptAfidivate,
        afidivateStatement: this.state.afidivateStatement
      };
    }else{
      data = {
        companyId: this.state.companyId,
        expenseAllocationForm: this.state.selectedExpenseAllocationForm,
        expenseReportForm: this.state.selectedExpenseReportForm,
        expenseReportWorkFlow: this.state.selectedExpenseReportWorkFlow,
        editableBy: this.state.editableBy,
        appliesTo: this.state.appliesTo,
        policyName: this.state.policyName,
        scanConfiguration: this.state.scanConfiguration,
        missingReceiptAfidivate: this.state.missingReceiptAfidivate,
      };
    }

    // console.log("||||Data", data);

     allValid = this.checkValidity(data);



    if(allValid){

      // alert('Everything is valid');

      expensePolicies
      .createPolicy(data)
      .then(res => {
        toast.success(res.message);
        this.props.history.push('/administration/expense/expense-policies');
      })
      .catch(err => console.log("error", err));
    }else{
      toast.warn('Please fill all the fields');
    }
  };
  componentDidMount() {
    expensePolicies
      .getExpenseReportForms(
        this.state.activePage,
        this.state.limit,
        "5c74ed2977addf3b07acb012",
        this.state.expenseReportFormId,
        this.state.companyId
      )
      .then(res => {
        this.setState({
          expenseReportForms: res.result
        });
      })
      .catch(err => console.log("err", err));

    expensePolicies
      .getExpenseAllocationForms(
        this.state.activePage,
        this.state.limit,
        this.state.serviceModuleId,
        this.state.expenseAllocationFormId,
        this.state.companyId
      )
      .then(res => {

        this.setState({
          expenseAllocationForms: res.result
        });
      })
      .catch(err => console.log("err", err));

    expensePolicies.workFlow().then(res => {
      if(res != undefined && res.result){

        this.setState({
          workFlows: res.result.filter(workflow => workflow.workflowType.master_value.service_module == serviceModules.EXPENSE)
        });
      }
    });

    expensePolicies.parentList(this.state.companyId).then(res => {
      if (res) {
        this.setState({
          parentList: res.result
        });
      }
    });

    expensePolicies.getGroups(this.state.companyId)
    .then(res => {
      if(res){

        this.setState({
          editableByList: res.result,
          appliesToList: res.result
        })
      }
    })

  }

  render() {
    return (
      <div>
        <PolicyStep1Page
          createPolicyData={this.state}
          formNameHandler={this.formNameHandler}
          handleERF={this.handleERF}
          handleEAF={this.handleEAF}
          handleEWF={this.handleEWF}
          handleEditableBy={this.handleEditableBy}
          handleAppliesTo={this.handleAppliesTo}
          handleSC={this.handleSC}
          missingReceiptAfidivate={this.missingReceiptAfidivate}
          createPolicy={this.createPolicy}
        />
      </div>
    );
  }
}
export default PolicyStep1;
