import React, { Component } from 'react';
import BookingHomeCardPage from '../home-cards';
import BookingMenuPage from '../../components/booking-menu';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { withRouter } from 'react-router-dom';
import TrainAmount from './TrainAmount';
import TrainCurrency from './TrainCurrency';
import { masterService } from 'src/_services';
import TrainClassType from './train-class';
import { bookingServices } from 'src/_services/booking.service';
import $ from "jquery";
import { getValue } from 'src/_components/common/lodsh';

@inject('trainStore')
@observer
class TrainBookingPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.state = {
            train_booking_type: 0,
            train_trip_type: 0,
            listOfCurrency: [],
            train_booking_currency: '',
            train_booking_price: '',
            train_class: 'AC First Class',
            trainRequest: '',
            isVisble: false
        }
    }
    createRequestData = (decodeParams) => {
        let bookingInfo = getValue(decodeParams, "[7]").split('&');
        let request = {
            train_booking_type: parseInt(getValue(bookingInfo, '[0]')),
            train_trip_type: parseInt(getValue(bookingInfo, '[1]')),
            train_booking_currency: getValue(bookingInfo, '[2]'),
            train_booking_price: getValue(bookingInfo, '[3]'),
            page: 1,
            limit: 2,
            train_class: getValue(bookingInfo, '[4]'),
            train_from: {
                stationCode: getValue(decodeParams, "[0]"),
                stationCity: getValue(decodeParams, "[1]"),
                stationName: getValue(decodeParams, "[2]"),
            },
            train_to: {
                stationCode: getValue(decodeParams, "[3]"),
                stationCity: getValue(decodeParams, "[4]"),
                stationName: getValue(decodeParams, "[5]"),
            },
            train_start_timestamp: new Date(getValue(decodeParams, "[6]"))
        }
        if (request.train_trip_type === 1) {
            request.train_return_timestamp = new Date(getValue(decodeParams, "[8]"));
        } else {
            request.train_return_timestamp = '';
        }
        this.setState({
            trainRequest: request,
        });
        this.setDefaultValueOnModify(request);
    }

    locationFromRef = React.createRef();
    locationToRef = React.createRef();
    departureDateRef = React.createRef();
    returnDateRef = React.createRef();
    today = new Date();



    componentDidMount = async () => {
        await this.getCurrencyCode();
        if (this.props.match.path === "/booking/train/request-list/:TrainData") {
            this.props.trainStore.changeValidStatus(false);
            let { params } = this.props.match;
            let decodeParams = params.TrainData.split("|");
            this.createRequestData(decodeParams)
        } else {
            this.setState({
                isVisble: true
            })
        }
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    setDefaultValueOnModify(trainRequest) {
        if (typeof trainRequest === "object") {
            this.props.trainStore.setSearchDataForRequest(trainRequest)
            this.setState({
                train_booking_type: trainRequest.train_booking_type,
                train_trip_type: trainRequest.train_trip_type,
                train_booking_currency: trainRequest.train_booking_currency,
                train_booking_price: trainRequest.train_booking_price,
                train_class: trainRequest.train_class,
                isVisble: true
            });
        }
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        const locationFromPopup = this.locationFromRef.current;
        const locationToPopup = this.locationToRef.current;
        const departureDatePopup = this.departureDateRef.current;
        const returnDatePopup = this.returnDateRef.current;
        const { activeInput } = this.props.trainStore;
        if (activeInput === 1 && this.locationFromRef && !locationFromPopup.contains(e.target)) {
            this.changeActiveInput(0)
            return;
        }
        if (activeInput === 2 && this.locationToRef && !locationToPopup.contains(e.target)) {
            this.changeActiveInput(0)
            return;
        }
        if (activeInput === 3 && this.departureDateRef && !departureDatePopup.contains(e.target)) {
            this.changeActiveInput(0);
            return;
        }
        if (activeInput === 4 && this.returnDateRef && !returnDatePopup.contains(e.target)) {
            this.changeActiveInput(0);
            return;
        }
    }

    changeActiveInput = (val) => {
        this.props.trainStore.changeActiveInput(val);
    }

    handleLocationChange = (e, type) => {
        let { value } = e.target;
        this.props.trainStore.handleLocationChange(value, type);

    }

    handleEnterKey = (e, type) => {
        let { charCode } = e;
        this.props.trainStore.handleEnterKey(charCode, type);
    }

    handleLocationSwap = () => {
        this.props.trainStore.handleLocationSwap();
    }

    handleLocationSelection = (item, type) => {
        this.props.trainStore.handleLocationSelection(item, type);
    }

    handleDepartureDate = (day) => {
        this.props.trainStore.handleDepartureDate(day);
    }
    handleReturnDate = (day) => {
        this.props.trainStore.handleReturnDate(day);
    }
    searchLocation = async () => {
        this.props.trainStore.changeValidStatus(true);
        if (this.state.train_booking_type === 0) {
            let isSearchValid = this.checkValidityBeforeSearch();
            if (isSearchValid) {
                const { train_booking_type, train_trip_type, train_booking_currency, train_booking_price, train_class } = this.state;
                let types = `${train_booking_type}&${train_trip_type}&${train_booking_currency}&${train_booking_price}&${train_class}`
                let returnPart = train_trip_type === 1 ? this.props.trainStore.returnDate : ""
                let url = `${this.props.trainStore.fromLocation.name}|${this.props.trainStore.fromLocation.code}|${this.props.trainStore.fromLocation.city}|${this.props.trainStore.toLocation.name}|${this.props.trainStore.toLocation.code}|${this.props.trainStore.toLocation.city}|${this.props.trainStore.departureDate}|${types}|${returnPart}`
                if (!sessionStorage.TRIPID) {
                    let newReq = await bookingServices.getTripId();
                    sessionStorage.setItem("TRIPID", newReq.TRIP_ID);
                } else {
                    sessionStorage.setItem("modify", true);
                    $(".close").click();
                }
                this.props.history.push(`/booking/train/request-list/${url}`);
            }
        } else {
            let isSearchValid = this.checkValidityBeforeSearch();
            if (isSearchValid) {
                let params = {
                    fromLocationName: this.props.trainStore.fromLocation.name,
                    fromLocationCode: this.props.trainStore.fromLocation.code,
                    toLocationName: this.props.trainStore.toLocation.name,
                    toLocationCode: this.props.trainStore.toLocation.code,
                    departureDate: this.props.trainStore.departureDate
                }
                let encodedParams = this.getEncodedUrl(params);
                let url = `/${encodedParams.fromLocationName}/${encodedParams.fromLocationCode}/${encodedParams.toLocationName}/${encodedParams.toLocationCode}/${encodedParams.departureDate}`;
                this.props.history.push(`/booking/train/list${url}`);
            }
        }
    };

    isValidTrainRequest = () => {
        // if (this.state)
    }
    handleAmount = (e) => {
        this.setState({
            train_booking_price: e.target.value
        });
    }
    getCurrencyCode = async () => {
        var companyId = localStorage["440a28"];
        var resp = await masterService.getGroups(companyId, "CUR001");
        if (resp) {
            let customCurrency = [];
            resp.result.forEach(item => {
                customCurrency.push({ value: item.itemCode, label: item.itemCode })
            })
            this.setState({
                listOfCurrency: customCurrency
            })
        }
    };
    changeCurrency = (e) => {
        this.setState({
            train_booking_currency: e.label
        })
    };

    changeClass = (e) => {
        this.setState({
            train_class: e.label
        })
    };
    getEncodedUrl = (params) => {
        let copiedParams = { ...params };
        for (let key in copiedParams) {
            if (copiedParams.hasOwnProperty(key)) {
                copiedParams[key] = encodeURIComponent(copiedParams[key]);
            }
        }
        return copiedParams;

    }


    checkValidityBeforeSearch = () => {
        return this.props.trainStore.checkValidityBeforeSearch;
    }
    getFormattedDate = () => {
        let formattedDate = moment(this.props.trainStore.departureDate, 'DD-MM-YYYY').format('DD MMM YYYY');
        return formattedDate;
    }
    getReturnFormattedDate = () => {
        let formattedDate = moment(this.props.trainStore.returnDate, 'DD-MM-YYYY').format('DD MMM YYYY');
        return formattedDate;
    }

    handleType = (value) => {
        this.setState({
            train_booking_type: value
        })
    }
    handleTripType = (value) => {
        this.setState({
            train_trip_type: value
        })
    }


    render() {
        return (
            <div className="book-details">
                <ul className="trip-way">
                    <li>
                        <button
                            type="button"
                            className={this.state.train_booking_type == 0 ? "btn active" : "btn"}
                            onClick={() => this.handleType(0)}
                        >
                            Request
                        </button>
                    </li>
                    {/* <li>
                        <button
                            type="button"
                            className={this.state.train_booking_type == 1 ? "btn active" : "btn"}
                            onClick={() => this.handleType(1)}
                        >
                            Online
                        </button>
                    </li> */}
                </ul>

                <label className="radio-container cab-radio">
                    Onway
                    <input
                        type="radio"
                        name="radio1"
                        checked={this.state.train_trip_type === 0 ? true : false}
                        onClick={() => this.handleTripType(0)}
                    />
                    <span className="checkmark" />
                </label>
                <label className="radio-container cab-radio" >
                    Round Trip
                    <input
                        type="radio"
                        name="radio1"
                        checked={this.state.train_trip_type === 1 ? true : false}
                        onClick={() => this.handleTripType(1)}

                    />
                    <span className="checkmark" />
                </label>

                <div>
                    <div className={
                        this.props.trainStore.activeInput !== 1
                            ? "details from"
                            : "details from show-popup"
                    }
                        ref={this.locationFromRef}
                    >
                        <div
                            className={
                                this.props.trainStore.stationAndDateValidation && this.props.trainStore.fromLocation.code === '-'
                                    ? "details-margin not-valid"
                                    : "details-margin"
                            }
                            onClick={e => this.changeActiveInput(1)}
                        >
                            <div className="d-table d-table__middle">
                                <div className="text-left">
                                    <p className="label">From</p>
                                    <h6 className="place">
                                        {
                                            this.props.trainStore.fromLocation.code === '-'
                                                ? "Where from?"
                                                : this.props.trainStore.fromLocation.code
                                        }
                                    </h6>
                                    <p className="bottom">
                                        {
                                            this.props.trainStore.fromLocation.code === '-'
                                                ? ""
                                                : this.props.trainStore.fromLocation.name
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={this.handleLocationSwap}
                            className={this.props.trainStore.locationSwap ? "swap" : "swap active"}
                        >
                            <i className="material-icons">swap_horiz</i>
                        </div>
                        <div className="popup">
                            <div className="typ">
                                <input type="text" autoFocus placeholder="Where From?" onKeyPress={e => this.handleEnterKey(e, 'fromLocation')} onChange={e => this.handleLocationChange(e, 'fromLocation')} />
                                <div className="pin"><i className="material-icons">place</i></div>
                            </div>
                            {
                                (!this.props.trainStore.loadingStations)
                                    ? (
                                        <div>
                                            {/* Show default choices */}
                                            {this.props.trainStore.stationSearchResults.length === 0 && (
                                                <ul className="scroll">
                                                    <li className="popup__head">
                                                        <button type="button">Popular Cities</button>
                                                    </li>
                                                    {this.props.trainStore.popularCities.map((item, index) => {
                                                        if (item.code !== this.props.trainStore.toLocation.code) {
                                                            return (
                                                                <li key={item.code}>
                                                                    <button
                                                                        type="button"
                                                                        onClick={e => this.handleLocationSelection(item, 'fromLocation')}

                                                                    >
                                                                        {item.stationCity}&nbsp;{" "}
                                                                        <span className="small__size">
                                                                            ({item.stationCode}-{item.stationName})
                                                                </span>
                                                                    </button>
                                                                    <div className="pin">
                                                                        <i className="material-icons">train</i>
                                                                    </div>
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            )}

                                            {/* Show search results */}
                                            {
                                                this.props.trainStore.stationSearchResults.length !== 0 &&
                                                (
                                                    <ul className="scroll">
                                                        {
                                                            this.props.trainStore.stationSearchResults.map((item, index) => {
                                                                if (item.code !== this.props.trainStore.toLocation.code) {
                                                                    return (
                                                                        <li key={item.code}>
                                                                            <button
                                                                                type="button"
                                                                                onClick={e => this.handleLocationSelection(item, 'fromLocation')}
                                                                            >
                                                                                {item.stationCity}&nbsp;
                                                                    <small>
                                                                                    ({item.stationCode}-{item.stationName})
                                                                    </small>
                                                                            </button>
                                                                            <div className="pin">
                                                                                <i className="material-icons">train</i>
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                }
                                                            })
                                                        }

                                                    </ul>

                                                )

                                            }
                                        </div>
                                    )
                                    : (<ul className="scroll">
                                        <li className="popup__head">
                                            <button type="button">Loading...</button>
                                        </li>
                                    </ul>
                                    )
                            }
                        </div>
                    </div>
                    <div className={
                        this.props.trainStore.activeInput !== 2
                            ? "details to"
                            : "details to show-popup"
                    }
                        ref={this.locationToRef}>
                        <div
                            className={
                                this.props.trainStore.stationAndDateValidation && this.props.trainStore.toLocation.code === '-'
                                    ? "details-margin not-valid"
                                    : "details-margin"
                            }
                            onClick={e => this.changeActiveInput(2)}
                        >
                            <div className="d-table d-table__middle">
                                <div className="text-left">
                                    <p className="label">To</p>
                                    <h6 className="place">
                                        {
                                            this.props.trainStore.toLocation.code === '-'
                                                ? "Where to?"
                                                : this.props.trainStore.toLocation.code
                                        }
                                    </h6>
                                    <p className="bottom">
                                        {
                                            this.props.trainStore.toLocation.code === '-'
                                                ? ""
                                                : this.props.trainStore.toLocation.name
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="popup">
                            <div className="typ">
                                <input autoFocus type="text" placeholder="Where To?"
                                    onChange={e => this.handleLocationChange(e, 'toLocation')}
                                    onKeyPress={e => this.handleEnterKey(e, 'toLocation')}
                                />
                                <div className="pin"><i className="material-icons">place</i></div>
                            </div>

                            {
                                (!this.props.trainStore.loadingStations)
                                    ? (
                                        <div>
                                            {/* Show default choices */}
                                            {this.props.trainStore.stationSearchResults.length === 0 && (
                                                <ul className="scroll">
                                                    <li className="popup__head">
                                                        <button type="button">Popular Cities</button>
                                                    </li>
                                                    {this.props.trainStore.popularCities.map((item, index) => {
                                                        if (item.code !== this.props.trainStore.fromLocation.code) {
                                                            return (
                                                                <li key={item.code}>
                                                                    <button
                                                                        type="button"
                                                                        onClick={e => this.handleLocationSelection(item, 'toLocation')}
                                                                    >
                                                                        {item.stationCity}&nbsp;{" "}
                                                                        <span className="small__size">
                                                                            ({item.stationCode}-{item.stationName})
                                                                </span>
                                                                    </button>
                                                                    <div className="pin">
                                                                        <i className="material-icons">train</i>
                                                                    </div>
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            )}

                                            {/* Show search results */}
                                            {
                                                this.props.trainStore.stationSearchResults.length !== 0 &&
                                                (
                                                    <ul className="scroll">
                                                        {
                                                            this.props.trainStore.stationSearchResults.map((item, index) => {
                                                                if (item.code !== this.props.trainStore.fromLocation.code) {
                                                                    return (
                                                                        <li key={item.code}>
                                                                            <button
                                                                                type="button"
                                                                                onClick={e => this.handleLocationSelection(item, 'toLocation')}
                                                                            >
                                                                                {item.stationCity}&nbsp;
                                                                    <small>
                                                                                    ({item.stationCode}-{item.stationName})
                                                                    </small>
                                                                            </button>
                                                                            <div className="pin">
                                                                                <i className="material-icons">train</i>
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                }
                                                            })
                                                        }

                                                    </ul>

                                                )

                                            }
                                        </div>
                                    )
                                    : (<ul className="scroll">
                                        <li className="popup__head">
                                            <button type="button">Loading...</button>
                                        </li>
                                    </ul>
                                    )
                            }
                        </div>
                    </div>

                    <div
                        className={
                            this.props.trainStore.activeInput !== 3
                                ? "details half"
                                : "details half show-popup"
                        }
                        ref={this.departureDateRef}
                    >
                        <div
                            className="details-margin"
                            onClick={e => this.changeActiveInput(3)}
                        >
                            <div className="d-table d-table__middle">
                                <div className="text-left">
                                    <p className="label">Date</p>
                                    <h6 className="place">
                                        {this.props.trainStore.departureDate ? (
                                            this.getFormattedDate()
                                        ) : (
                                                "Add departure"
                                            )}
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className="popup date-picker">
                            <p className="date__head">Departure date</p>
                            <DatePicker
                                inline
                                selected={this.props.trainStore.departureDate ? new Date(this.props.trainStore.departureDate) : null}
                                onChange={this.handleDepartureDate}
                                minDate={this.today}
                                monthsShown={2}
                                // showTimeSelect
                                // timeFormat="HH:mm"
                                calendarClassName="double-datepicker react-datepicker__day--outside-month"
                            />
                        </div>
                    </div>

                    {this.state.train_booking_type == 1 || this.state.train_trip_type === 1 ?
                        <div>
                            <div
                                className={
                                    this.props.trainStore.activeInput !== 4
                                        ? "details half"
                                        : "details half show-popup"
                                }
                                ref={this.returnDateRef}
                            >
                                <div
                                    className="details-margin"
                                    onClick={e => this.changeActiveInput(4)}
                                >
                                    <div className="d-table d-table__middle">
                                        <div className="text-left">
                                            <p className="label">Return</p>
                                            <h6 className="place">
                                                {this.props.trainStore.returnDate ? (
                                                    this.getReturnFormattedDate()
                                                ) : (
                                                        "Add Return"
                                                    )}
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="popup date-picker">
                                    <p className="date__head">Return date</p>
                                    <DatePicker
                                        inline
                                        selected={this.props.trainStore.returnDate ? new Date(this.props.trainStore.returnDate) : null}
                                        onChange={this.handleReturnDate}
                                        minDate={this.props.trainStore.departureDate ? new Date(this.props.trainStore.departureDate) : this.today}
                                        monthsShown={2}
                                        // showTimeSelect
                                        // timeFormat="HH:mm"
                                        calendarClassName="double-datepicker react-datepicker__day--outside-month"
                                    />
                                </div>
                            </div>

                            <div className="clearfix"></div>
                        </div> : ""
                    }

                    {this.state.train_booking_type === 0 &&
                        <div>
                            <TrainClassType
                                isSubmitted={this.props.trainStore.stationAndDateValidation}
                                request={this.state}
                                changeClass={this.changeClass}
                            />
                            {this.state.isVisble &&
                                <TrainCurrency
                                    request={this.state}
                                    listOfCurrency={this.state.listOfCurrency}
                                    isSubmitted={this.props.trainStore.stationAndDateValidation}
                                    changeCurrency={this.changeCurrency} />}
                            <TrainAmount
                                request={this.state}
                                isSubmitted={this.props.trainStore.stationAndDateValidation}
                                handleAmount={this.handleAmount}
                            />
                        </div>
                    }

                    <button
                        onClick={this.searchLocation}
                        className="btn details search"
                    >
                        <i className="material-icons">search</i>
                    </button>

                    <div className="clearfix"></div>
                </div>


            </div>
        )
    }
}
export default withRouter(TrainBookingPage)
