import React from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import AsyncSelect from "react-select";
import PropTypes from "prop-types";
import Loader from "src/_components/common/Loader";

const TravelDiscountPage = props => {
  const {
    selectedCompanyByIndex,
    OnCompanyDeleteHandler,
    OnCopyDownClickHandler,
    OnCompanySearchHandler,
    search,
    allTravelCompanyData,
    OnCompanySelectHandler,
    isLoading,
    OnStatusChangeHandler,
    itemsPerPage,
    OnPageLimitHandler,
    currentPage,
    totalCountItem,
    OnPageChangeHandler,
    OnCompanyCreateHandler,
    OnSubmitHandler,
    selectedCompany,
    OnUpdateHandler,
    appliesToData
  } = props;

  let isBranchSelected = selectedCompanyByIndex.length < 1 ? false : true;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">L</div>
          <div className="right">
            <h6 className="heading">Travel Discounts</h6>
            <p className="text">View travel company discounts</p>
          </div>
          <div className="clearfix" />
        </div>
      </div>
      <div className="py-padding">
        <div className="table-container">
          <form>
            <div className="row">
              <div className="col-sm-9">
                <div className="btn-group-margin">
                  <button
                    className="btn"
                    type="button"
                    data-toggle="modal"
                    data-target="#createConfigModal"
                  >
                    Add Config
                  </button>
                  <button
                    type="button"
                    className="btn"
                    onClick={e =>
                      OnCompanyDeleteHandler(selectedCompanyByIndex)
                    }
                    disabled={isBranchSelected ? false : true}
                  >
                    Remove
                  </button>
                  <button
                    type="button"
                    className="btn"
                    onClick={e =>
                      OnCopyDownClickHandler(selectedCompanyByIndex)
                    }
                    disabled={isBranchSelected ? false : true}
                  >
                    Duplicate
                  </button>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="search-custom">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder="Search"
                    value={search}
                    onChange={OnCompanySearchHandler}
                  />
                  <span>
                    <i className="material-icons">search</i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            {isLoading === true ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
                <table className="table table-striped table-selected">
                  <thead>
                    <tr>
                      <th>Company/config</th>
                      <th>Applies To</th>
                      <th>Config Id</th>
                      <th>Action</th>
                      <th>status</th>
                    </tr>
                  </thead>
                  {allTravelCompanyData &&
                    allTravelCompanyData.length <= 0 ? (
                      <tbody>
                        <tr>
                          <td colSpan="4" className="text-center">
                            No Result found
                      </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {allTravelCompanyData &&
                          allTravelCompanyData.map((branch, key) => (
                            <tr
                              onClick={e =>
                                OnCompanySelectHandler(branch.id, branch)
                              }
                              key={key}
                              className={
                                branch.id === selectedCompanyByIndex
                                  ? "active-tr"
                                  : ""
                              }
                            >
                              <td>{branch.configName}</td>
                              <td>
                                {branch.appliesToData && branch.appliesToData.map((item, key) => {
                                  return (
                                    <span key={item.id}>{item.itemName}, </span>
                                  )
                                })}
                              </td>
                              <td>{branch.configId}</td>
                              <td>
                                <div className="btn-group-margin">
                                  <NavLink
                                    to={`add-travel-discount/${branch.id}`}
                                    className="btn btn-nothing hover-v"
                                  >
                                    <i className="material-icons fs-20">
                                      remove_red_eye
                                </i>
                                    <span className="hover-name right-top">View</span>
                                  </NavLink>
                                  <button
                                    className="btn btn-nothing hover-v"
                                    data-target="#editTravelDiscount"
                                    data-toggle="modal"
                                  >
                                    <i className="material-icons fs-20">edit</i>
                                    <span className="hover-name right-top">Edit</span>
                                  </button>
                                </div>
                              </td>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    onChange={e =>
                                      OnStatusChangeHandler(branch)
                                    }
                                    value={branch.activeStatus}
                                    checked={branch.activeStatus}
                                  />
                                  <span className="slider round" />
                                </label>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    )}
                </table>
              )}
          </div>
          <div className="table-footer">
            <div className="row">
              <div className="col-sm-3">
                <div className="page-count">
                  <div className="text">Items</div>
                  <div className="count">
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        {itemsPerPage}
                      </button>
                      <div className="dropdown-menu">
                        <button
                          className="dropdown-item"
                          onClick={e => OnPageLimitHandler(10)}
                        >
                          10
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={e => OnPageLimitHandler(25)}
                        >
                          25
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={e => OnPageLimitHandler(50)}
                        >
                          50
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-9">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalCountItem}
                  pageRangeDisplayed={3}
                  onChange={OnPageChangeHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add config Modal */}
      <div
        className="modal"
        id="createConfigModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="createConfigModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="createConfigModalLabel">
                Create Config
              </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom">
                <input
                  type="text"
                  className="form-control"
                  name="configName"
                  placeholder="&nbsp;"
                  onChange={OnCompanyCreateHandler}
                />
                <label>Config Name <span className="text-danger">*</span></label>
              </div>
              <div className="form-group custom">
                <input
                  type="text"
                  className="form-control"
                  name="configId"
                  placeholder="&nbsp;"
                  onChange={OnCompanyCreateHandler}
                />
                <label>Config Code <span className="text-danger">*</span></label>
              </div>
              <div className="form-group custom labelup">
                <label>Applies To</label>
                <AsyncSelect
                  isMulti
                  options={props.getAllCompanyGroups}
                  loadOptions={props.getAllCompanyGroups}
                  onChange={props.OnGroupsSelectHandler}
                />
              </div>
              <div className="modal-last btn-group-margin">
                <button
                  className="btn"
                  data-dismiss="modal"
                  onClick={OnSubmitHandler}
                >
                  Submit
                </button>
                <button className="btn close" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="editTravelDiscount"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editTravelDiscountLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="editTravelDiscountLabel">
                Edit Config
              </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom">
                <input
                  type="text"
                  className="form-control"
                  value={selectedCompany.configName || ""}
                  name="configName"
                  placeholder="&nbsp;"
                  onChange={OnCompanyCreateHandler}
                />
                <label>Config Name <span className="text-danger">*</span></label>
              </div>
              <div className="form-group custom">
                <input
                  type="text"
                  className="form-control"
                  value={selectedCompany.configId || ""}
                  name="configId"
                  placeholder="&nbsp;"
                  onChange={OnCompanyCreateHandler}
                />
                <label>Config Code <span className="text-danger">*</span></label>
              </div>
              <div className="form-group custom labelup">
                <label>Applies To </label>
                <AsyncSelect
                  value={appliesToData || 'please Select'}
                  isMulti
                  options={props.getAllCompanyGroups}
                  loadOptions={props.getAllCompanyGroups}
                  onChange={props.OnUpdateGroupsSelectHandler}
                />
              </div>
              <div className="modal-last btn-group-margin">
                <button
                  className="btn"
                  data-dismiss="modal"
                  onClick={OnUpdateHandler}
                >
                  Update
                </button>
                <button className="btn close" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TravelDiscountPage.propTypes = {
  OnUpdateHandler: PropTypes.func,
  OnCompanyCreateHandler: PropTypes.func,
  OnSubmitHandler: PropTypes.func,
  OnPageChangeHandler: PropTypes.func,
  OnPageLimitHandler: PropTypes.func,
  OnStatusChangeHandler: PropTypes.func,
  OnCompanySelectHandler: PropTypes.func,
  OnCompanySearchHandler: PropTypes.func,
  selectedCompanyByIndex: PropTypes.string,
  OnCopyDownClickHandler: PropTypes.func,
  OnCompanyDeleteHandler: PropTypes.func,
  allTravelCompanyData: PropTypes.array,
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  totalCountItem: PropTypes.any,
  selectedCompany: PropTypes.object
};

export default TravelDiscountPage;
