import React, { Component } from 'react';
import CustomStep1Page  from './custom-step1-html';
import { auditRuleService } from '../../../../../../_services/auditRule.service';
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';

class CustomStep1 extends Component {
    constructor(props){
        super(props)
        this.validator = new SimpleReactValidator();
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.state={
            name:'',
            auditRuleId:"",
            isEdit:false,
            isLoading:false,
            isSubmitClick:false,
            status:false,
            companyId: localStorage["440a28"],
            editedBy:[],
            appliesTo:[],
            editedAppliesDropdown:[],
            gotoNextMenu:false,
            selectedAuditRuleType:[],
            auditRuleType:[
                {label:'Expense',value:'5c74ed2977addf3b07acb012'},
                {label:'Cash Advance',value:'5c74ed3677addf3b07acb013'},
                {label:'Travel',value:'5c74ed2177addf3b07acb011'}
                
           ]
        }
    }
    componentDidMount(){
        localStorage.removeItem('auditRuleId');
        this.getEditedAndAppliesto();
        if(this.props.match.params.id !==undefined && this.props.match.params.id !=="" && this.props.match.params.id !==null){
            this.setState({auditRuleId:this.props.match.params.id,isEdit:true},()=>{
                this.getEditAuditRules();
            });
        }
    }
    
    getEditAuditRules = async()=>{
        this.setState({isLoading:true});
        let companyId = this.state.companyId;
        let auditRuleId = this.state.auditRuleId;
        let response = await auditRuleService.getAuditRuleByid(companyId,auditRuleId);
        if(response){
            let appliesTo = [],editedBy=[];
             response.result.appliesObj&&response.result.appliesObj.forEach((list)=>{appliesTo.push({value:list.id,label:list.itemName})});
             response.result.editableObj&&response.result.editableObj.forEach((list)=>{editedBy.push({value:list.id,label:list.itemName})});
            let selectedAuditRuleType=this.state.auditRuleType.filter((types)=>types.value===response.result.RuleType);
            this.setState({
                editedBy:editedBy,
                appliesTo:appliesTo,
                status:response.result.activeStatus,
                name:response.result.name,
                selectedAuditRuleType:selectedAuditRuleType[0],
                isLoading:false
            })
            if(response.result&&response.result.applyRules&&response.result.applyRules.rules&&response.result.applyRules.rules.length){
                this.setState({gotoNextMenu:true});
            }
        }else{
            this.setState({isLoading:false});
        }
    }

    getEditedAndAppliesto=async()=>{
        let response = await auditRuleService.getGroupType(this.state.companyId);
        if(response && response.result && response.result.length>0){
            let editedAppliesDropdown = [];
            response.result.forEach((list)=>{
                editedAppliesDropdown.push({label:list.itemName,value:list.id});
            })
            this.setState({editedAppliesDropdown:editedAppliesDropdown});
        }
    }
    onNameChangeHandler=(event)=>{
        this.setState({name:event.target.value})
    }
    onEditableByChangeHandler=(editedBy)=>{
        this.setState({editedBy:editedBy});
    }
    onAppliesToChangeHandler=(appliesTo)=>{
        this.setState({appliesTo:appliesTo});
    }
    onStatusChangeHandler=()=>{
        this.setState({status:!this.state.status});
    }
    onChnageAuditRuleTypeHnadler=(selectedAuditRuleType)=>{
        this.setState({selectedAuditRuleType:selectedAuditRuleType});
    }
    onSubmitHandler=async(event)=>{
        event.preventDefault();
        if (this.validator.allValid()) {
            this.setState({isSubmitClick:true});
            let  data={
                companyId:this.state.companyId,
                name:this.state.name,
                editableBy:this.state.editedBy.map((list)=>list.value),
                appliesTo:this.state.appliesTo.map((list)=>list.value),
                RuleType:this.state.selectedAuditRuleType.value
            }
            let response = await auditRuleService.createAuditRuleStep1(data);
            if(response){
                toast.success(response.message);
                let auditRuleId = response.result;
                if(auditRuleId){
                    localStorage.setItem('auditRuleId',auditRuleId);
                    this.props.history.push(`./step2/${auditRuleId}`);
                }
            }
        }else{
            this.validator.showMessages();
            this.forceUpdate();
        }
        
    }
    onUpdateHandler=async(event)=>{
        event.preventDefault();
        if (this.validator.allValid()) {
            this.setState({isSubmitClick:true});
            let  data={
                    id:this.state.auditRuleId,
                    companyId:this.state.companyId,
                    name:this.state.name,
                    editableBy:this.state.editedBy.map((list)=>list.value),
                    appliesTo:this.state.appliesTo.map((list)=>list.value),
                    RuleType:this.state.selectedAuditRuleType.value
            }
            let response = await auditRuleService.updateAuditRule(data);
            if(response){
                toast.success(response.message);
                this.props.history.push(`../step2/${this.state.auditRuleId}`);
            }
        }else{
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    onClickCancelHandler=()=>{
        if(this.state.isEdit){
            this.props.history.push('../custom');
        }else{
            this.props.history.push('./custom');
        }
        
    }
    render() {
        return(
            <div>
                <CustomStep1Page
                    {...this.state}
                    onNameChangeHandler={this.onNameChangeHandler}
                    onEditableByChangeHandler={this.onEditableByChangeHandler}
                    onAppliesToChangeHandler={this.onAppliesToChangeHandler}
                    onClickCancelHandler={this.onClickCancelHandler}
                    onSubmitHandler={this.onSubmitHandler}
                    onStatusChangeHandler={this.onStatusChangeHandler}
                    validator={this.validator}
                    onUpdateHandler={this.onUpdateHandler}
                    onChnageAuditRuleTypeHnadler={this.onChnageAuditRuleTypeHnadler}
                />
            </div>
        )
    }
}
export default CustomStep1;