import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { financeAdminService } from '../../../_services/financeAdmin.service';
import moment from 'moment';
import Loader from "src/_components/common/Loader";
import { toast } from "react-toastify";
class FinanceAdminPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.state = {
            companyId: localStorage["440a28"],
            pageNumber: 1,
            perPage: 10,
            totalCount: 0,
            allExpenseReport: [],
            selectedExpenses: [],
            search: '',
            isLoading: true,
            cashAdvanceStatus: 'Approved'
        }
    }
    componentDidMount() {
        this.getAllExpenseReports();
    }
    getAllExpenseReports = async () => {
        let limit = this.state.perPage;
        let page = this.state.pageNumber;
        let search = this.state.search;
        let cashAdvanceStatus = this.state.cashAdvanceStatus;
        let response = await financeAdminService.getFinanceExpenseList(this.state.companyId, limit, page, search, cashAdvanceStatus);
        if (response && response.result) {
            this.setState({ isLoading: false });
            this.setState({ allExpenseReport: response.result, totalCount: response.count });
        } else {
            this.setState({ isLoading: false,allExpenseReport:[],totalCount:0 });
        }
    }
    onExpenseSelectHandler = (expenseId) => {
        let selectedExpenses = [...this.state.selectedExpenses];
        if (selectedExpenses.includes(expenseId)) {
            selectedExpenses = selectedExpenses.filter((id) => id !== expenseId);
        } else {
            selectedExpenses.push(expenseId);
        }
        this.setState({ selectedExpenses: selectedExpenses });
    }
    handlePageChange = async (pageNumber) => {
        await this.setState({ pageNumber: pageNumber });
        this.getAllExpenseReports();
    }
    onChangePageLimitHandler = async (perPage) => {
        await this.setState({ perPage: perPage, pageNumber: 1 });
        this.getAllExpenseReports();
    }
    onSearchChangeHandler = async (e) => {
       await this.setState({ search: e.target.value, pageNumber: 1 });
        this.getAllExpenseReports();
    }
    onVerifyClickHandler = async () => {
        let selectedExpenses = { id: [...this.state.selectedExpenses] };
        let response = await financeAdminService.expenseReportVeficatiom(this.state.companyId, selectedExpenses);
        if (response) {
            this.setState({ selectedExpenses: [] });
            toast.success(response.message);
            this.getAllExpenseReports();
        }
    }
    onSingleVerifyClickHandler = async (expenseId) => {
        let selectedExpenses = { id: [expenseId] };
        let response = await financeAdminService.expenseReportVeficatiom(this.state.companyId, selectedExpenses);
        if (response) {
            this.setState({ selectedExpenses: [] });
            toast.success(response.message);
            this.getAllExpenseReports();
        }
    }
    redirectToExpenseHandler = (detail) => {
        localStorage.setItem('headerId', detail.id);
        localStorage.setItem('policyId', detail.policyObj._id);
        this.props.history.push(`../expense/details/fincapproval/${detail.id}`)

    }
    render() {
        return (
            <div>
                <div className="p-padding">
                    <ul className="expense-tabs">
                        <li className="active">
                            <NavLink to="/finance-main/finance-admin">
                                Pending Review
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/finance-main/verified">
                                Verified
                            </NavLink>
                        </li>
                    </ul>
                    <div className="white-card">
                        <div className="main-box">
                            <div className="py-padding">
                                <div className="table-container mx-0">
                                    <form className="py-0 px-4">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="btn-group-margin">
                                                    <button type="button"
                                                        onClick={this.onVerifyClickHandler}
                                                        className="btn  ">
                                                        Verify Selected
                                                    </button>
                                                    {/* <div className="dropdown">
                                                        <button
                                                            type="button"
                                                            className="btn dropdown-toggle"
                                                            data-toggle="dropdown"
                                                        >
                                                            This Week
                                                        </button>
                                                        <div className="dropdown-menu">
                                                            <button className="dropdown-item">
                                                                Make Inactive
                                                            </button>
                                                            <button className="dropdown-item">Delete</button>
                                                            <button className="dropdown-item">Edit</button>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>

                                            <div className="col-md-2 offset-6">
                                                <div className="search-custom">
                                                    <input onChange={this.onSearchChangeHandler} type="text" name="" className="form-control" placeholder="Search" />
                                                    <span><i className="material-icons">search</i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="table-responsive">
                                        {!this.state.isLoading ?
                                            <table className="table table-striped table-selected">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>EXPENSE REPORT ID</th>
                                                        <th>REQUESTED ID</th>
                                                        <th>REPORT DATE</th>
                                                        <th>EMPLOYEE NAME</th>
                                                        <th>AMOUNT DUE EMPLOYEE</th>
                                                        <th>AMOUNT DUE COMPANY</th>
                                                        <th>REPORT TOTAL AMOUNT</th>
                                                        <th>STATUS</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.state.totalCount > 0 ?
                                                        this.state.allExpenseReport.map((expenses) => {
                                                            let headerForm = expenses.headerForm.map((lists) => lists.columnName);
                                                            let reportIndex = headerForm.indexOf("REPORT_NAME");
                                                            return (

                                                                <tr key={expenses.id}  >
                                                                    <td>

                                                                        <label className="checkbox-container mb-4">
                                                                            <input type="checkbox"
                                                                                onChange={() => this.onExpenseSelectHandler(expenses.id)}
                                                                                checked={this.state.selectedExpenses.includes
                                                                                    (expenses.id) ? true : false}
                                                                                name="checkbox1" />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </td>

                                                                    <td onClick={() => this.redirectToExpenseHandler(expenses)}>
                                                                        {/* <NavLink to="#">{expenses.reportId}</NavLink> */}
                                                                        <a href={"javascript:void(0)"}>{expenses.reportId}</a>
                                                                        <br />{reportIndex >= 0 && expenses.headerForm[reportIndex].fieldValue}</td>
                                                                    <td ></td>
                                                                    <td >{moment(expenses.createdAt).format('D MMM YYYY')}</td>
                                                                    <td className="text-primary"><button className="btn btn-nothing text-primary">{expenses.userName}</button></td>
                                                                    <td>{expenses.baseCurrency}&nbsp;
                                                                    { (new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(expenses.amountDueEmployee))}</td>
                                                                    <td className="font-weight-bold">
                                                                        {expenses.baseCurrency}&nbsp;
                                                                        { (new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(expenses.amountDueCompany))}
                                                                       </td>
                                                                    <td className="font-weight-bold">{expenses.baseCurrency}&nbsp;
                                                                    { (new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(expenses.totalAmount))}
                                                                   </td>
                                                                    <td className="text-right">
                                                                        {expenses.status !== 'Verified' ?
                                                                            <button onClick={() => this.onSingleVerifyClickHandler(expenses.id)} type="button" className="btn">Verify</button>
                                                                            : <button type="button" className="btn btn-success text-white">{expenses.status}</button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) :
                                                        <tr>
                                                            <td colSpan={8} className="text-center">No Records Found</td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                            : <Loader />}
                                    </div>
                                    <div className="table-footer pb-0">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {this.state.totalCount > 0 &&
                                                    <div className="page-count">
                                                        <div className="text">Items</div>
                                                        <div className="count">
                                                            <div className="dropdown">
                                                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                                                    {this.state.perPage}
                                                                </button>
                                                                <div className="dropdown-menu">
                                                                    <button onClick={() => this.onChangePageLimitHandler(10)} type="button" className="dropdown-item">10</button>
                                                                    <button onClick={() => this.onChangePageLimitHandler(20)} type="button" className="dropdown-item">20</button>
                                                                    <button onClick={() => this.onChangePageLimitHandler(30)} type="button" className="dropdown-item">30</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-md-9">
                                                {this.state.totalCount > 0 &&
                                                    <Pagination
                                                        activePage={this.state.pageNumber}
                                                        itemsCountPerPage={this.state.perPage}
                                                        totalItemsCount={this.state.totalCount}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChange}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FinanceAdminPage
