import { http } from "../_helpers";
import { config } from "../env";

const getCashAdvanceForm= () => {
  return http
    .get(`${config.apiExpense}cash-advance/form`)
    .then(data => {
      return data ? data.result : "";
    });
};

const createCashAdvanceForm=(data)=>{
    return http
    .post(`${config.apiExpense}cashAdvance/create`,data)
    .then(data => {
      return data ? data : "";
    });
}

const getCashAdvanceFormList=(comapnyId,pagenumber,limit,search)=>{
  if(search!=='' && search !=='undefined'){
    return http
    .get(`${config.apiExpense}cashAdvance?companyId=${comapnyId}&page=${pagenumber}&limit=${limit}&search=${search}`)
    .then(data => {
      return data ? data : "";
    });
  }else{
    return http
    .get(`${config.apiExpense}cashAdvance?companyId=${comapnyId}&page=${pagenumber}&limit=${limit}`)
    .then(data => {
      return data ? data : "";
    });
  }
}

const editCashAdvanceFormList=(fieldId,comapnyId)=>{
  return http
  .get(`${config.apiExpense}cashAdvance/${fieldId}?companyId=${comapnyId}`)
  .then(data => {
    return data ? data : "";
  });
}

const updateCashAdvanceForm=(data,id)=>{
  return http
  .patch(`${config.apiExpense}cashAdvance/update/${id}`,data)
  .then(data => {
    return data ? data : "";
  });
}

//cash advance approval flow
const getCashAdvanceRequestList =(pageNumber,limit,search,pageType)=>{
  return http
  .get(`${config.apiExpense}workflow/process?type=${pageType}&page=${pageNumber}&limit=${limit}&search=${search}`)
  .then(data => {
    return data ? data : "";
  });
}

const editCashAdvanceApproval =(cashAdvanceId)=>{
  return http
  .get(`${config.apiExpense}workflow/process?id=${cashAdvanceId}`)
  .then(data => {
    return data ? data : "";
  });
}

const approveCashAdvanceApproval =(data,cashAdvanceId)=>{
  return http
  .patch(`${config.apiExpense}workflow/process/update?workflowProcessId=${cashAdvanceId}`,data)
  .then(data => {
    return data ? data : "";
  });
}

const getApprovalUserList = async(companyId,search) =>{
  return http
  .get(`${config.apiUrlCompany}company-users/${search}?companyId=${companyId}&isApprover=true&search=${search}`)
  .then(data => {
    return data ? data : "";
  });
}
//cashAdvance admin start
const getIssedCashAdvanceList = (cashAdvanceAction,companyId,activePageNumber,perPageLimit,search)=>{
  return http
  .get(`${config.apiExpense}listCashAdmin/${cashAdvanceAction}?companyId=${companyId}&page=${activePageNumber}&limit=${perPageLimit}&search=${search}`)
  .then(data => {
    return data ? data : "";
  });
}

const getCashadvanceDetailById =(companyId,cashadvanceId)=>{
  return http
  .get(`${config.apiExpense}cashAdmin?companyId=${companyId}&id=${cashadvanceId}`)
  .then(data => {
    return data ? data : "";
  });
}

const getCashadvanceModeList =(companyId)=>{
  return http
  .get(`${config.apiUrlCompany}list-management?companyId=${companyId}&itemCode=MODE123`)
  .then(data => {
    return data ? data : "";
  });
}

const cashAdvanceApproveHandler=(companyId,data,cashAdvanceId)=>{
  return http
  .patch(`${config.apiExpense}cashAdmin?companyId=${companyId}&id=${cashAdvanceId}`,data)
  .then(data => {
    return data ? data : "";
  });
}

const cashAdvanceVoucherImageUpload =(data)=>{
    return http
    .fileUpload(`${config.apiExpense}cash-admin-receipt`, data)
    .then(data => {
      return data ? data : "";
    });
}

const getCurrencyList = (companyId)=>{
  return http
  .get(`${config.apiUrlCompany}list-management?companyId=${companyId}&itemCode=CUR001`)
  .then(data => {
    return data ? data : "";
  });
}
const getConvertedCurrency = (selectedCurrency)=>{
  return http
  .get(`${config.apiUrlCompany}companies/507f191e810c19729de860ea/currency-exchange?base=${selectedCurrency}`)
  .then(data => {
    return data ? data : "";
  });
}

const patchRecallCashAdvance =(cashAdvanceId)=>{
  return http
  .get(`${config.apiExpense}workflow/process/recall/user?id=${cashAdvanceId}`)
  .then(data => {
    return data ? data : "";
  });
}
//cashAdvance admin end
export const cashadvanceService = {
    getCashAdvanceForm,
    createCashAdvanceForm,
    getCashAdvanceFormList,
    editCashAdvanceFormList,
    updateCashAdvanceForm,
    getCashAdvanceRequestList,
    editCashAdvanceApproval,
    approveCashAdvanceApproval,
    getIssedCashAdvanceList,
    getApprovalUserList,
    getCashadvanceDetailById,
    getCashadvanceModeList,
    cashAdvanceApproveHandler,
    cashAdvanceVoucherImageUpload,
    getCurrencyList,
    getConvertedCurrency,
    patchRecallCashAdvance
}