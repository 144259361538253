import React, { Component } from 'react';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NavLink } from "react-router-dom";
import { userService } from 'src/_services';
import { toast } from 'react-toastify';
import { alertConstants } from 'src/_constants';
class CompanyNoteUpdatePage extends Component {
  constructor(props) {
    super(props)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.state = {
      companyId: localStorage["440a28"],
      isEdit: false,
      fetchId: '',
      loading: false,
      message: '',
      heading: ''
    }
  }
  componentDidMount() {
    let fetchId = this.props.match.params.id;
    if (fetchId !== 'new') {
      this.setState({ fetchId, isEdit: true });
      this.getNote(fetchId);
    }
    else {
      this.setState({ isEdit: false })
    }
  }
  getNote = async (id) => {
    let resp = await userService.getEachCompanyNote(id);
    if (resp) {
      this.setState({ message: resp.result.message, heading: resp.result.heading });
    }
  }
  createNote = async (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    var req = {
      heading: this.state.heading,
      message: this.state.message,
      companyId: this.state.companyId
    }
    let resp = await userService.postCompanyNote(req)
    if (resp) {
      toast.success(resp.message);
      // this.getNote(this.state.fetchId);
      this.props.history.push('/administration/company/company-note');
    }
    this.setState({ loading: false })
  }
  updateNote = async (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    var req = {
      heading: this.state.heading,
      message: this.state.message,
    }
    let resp = await userService.updateCompanyNote(req, this.state.fetchId)
    if (resp) {
      toast.success(resp.message);
      this.getNote(this.state.fetchId);
      this.props.history.push('/administration/company/company-note');
    }
    this.setState({ loading: false })
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleEditor = (e) => {
    this.setState({
      message: e
    });
  }
  render() {
    const { isEdit, loading, message, heading } = this.state;

    return (
      <div>
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">C</div>
            <div className="right">
              <h6 className="heading">
                {(!isEdit) ? "Add" : "Update"} Company Note
              </h6>
              <p className="text">Reach out to your employees with important updates and policy changes.</p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <div className="row">
              <div className="col-sm-7">
                <form autoComplete="off" onSubmit={!isEdit ? this.createNote : this.updateNote}>
                  <div className="form-group custom">
                    <input className="form-control"
                      type="text"
                      name="heading"
                      value={heading}
                      onChange={e => this.handleChange(e)}
                      placeholder="&nbsp;"
                    />
                    <label>Heading</label>
                  </div>
                  <div>
                    <h6 className="sub-heading">Message</h6>
                    <CKEditor
                      editor={ClassicEditor}
                      data={message}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.handleEditor(data);
                      }}
                    />
                  </div>
                  {!loading &&
                    <div className="btn-group-margin mt-4">
                      <button
                        type="submit"
                        className="btn"
                      >
                        Save
                    </button>
                      <NavLink to="/administration/company/company-note/list"
                        className="btn"
                      >
                        CANCEL
                    </NavLink>
                    </div>}
                  {loading &&
                    <div className="btn-group-margin mt-4">
                      <button
                        type="button"
                        className="btn"
                      >
                        Please Wait...
                    </button>
                    </div>}
                </form>
              </div>
              <div className="col-sm-5">
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
};
export default CompanyNoteUpdatePage;
