import {http} from '../_helpers';
import { config } from '../env';

export const cabSearch = (payload) => {
    let url = `${config.apiUrlTravel}car/search`;
    return http.post(url, payload).then(res => res)
}
export const cabAddToCart = (payload) => {
    let url = `${config.apiUrlTravel}add/cart`;
    return http.post(url, payload).then(res => res)
}
export const cabRemoveToCart = (payload) => {
    let url = `${config.apiUrlTravel}remove/cart`;
    return http.post(url, payload).then(res => res)
}