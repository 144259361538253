import React from "react";
import { NavLink } from "react-router-dom";
import ExpensePoliciesMenu2 from "../expense-policies-menu-2";
import Select from "react-select";
import { intersection, isEqual } from 'lodash';
const EditPolicyStep1Page = props => {
  const {
    createPolicyData,
    formNameHandler,
    handleERF,
    handleEAF,
    handleEWF,
    handleEditableBy,
    handleAppliesTo,
    handleSC,
    missingReceiptAfidivate,
    createPolicy,
    editableByList,
    appliesToList,
    updatePolicy
  } = props;

console.log(createPolicyData)

  let expenseReportForms = [];
  if (createPolicyData && createPolicyData.expenseReportForms) {
    expenseReportForms = createPolicyData.expenseReportForms.map(el => {
      return { value: el.id, label: el.formName };
    });

  }
  let expenseAllocationForms = createPolicyData.expenseAllocationForms && createPolicyData.expenseAllocationForms.map(
    el => {
      return { value: el.id, label: el.formName };
    }
  );

  let workFlows = createPolicyData.workFlows && createPolicyData.workFlows.map(el => {
    return {
      value: el.workflow_id,
      label: el.workflowName
    };
  });

  let editableByOptions = createPolicyData.editableByList && createPolicyData.editableByList.map(el => {
    return {
      value: el.id,
      label: el.itemName
    };
  });

  let appliesToOptions = createPolicyData.appliesToList && createPolicyData.appliesToList.map(el => {
    return {
      value: el.id,
      label: el.itemName
    };
  });



  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">R</div>
          <div className="right">
            <h6 className="heading">New Policy</h6>
            <p className="text">Add/Modify Request Policy</p>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          {/* <ExpensePoliciesMenu2 /> */}
          <div className="">
            <form>
              <div className="row">
                <div className="col-xl-5 col-lg-6">
                  <div className="form-group custom">
                    <input
                      className="form-control"
                      placeholder="&nbsp;"
                      type="text"
                      name="policyName"
                      onChange={formNameHandler}
                      value={createPolicyData.policyName || ""}
                    />
                    <label>Name</label>
                  </div>

                  <div className="form-group custom labelup">
                    <label>Expense Report Form</label>
                    <Select
                      value={expenseReportForms ? expenseReportForms.filter(item => item.value == createPolicyData.selectedExpenseReportForm) : ''}
                      options={expenseReportForms}
                      name="selectedExpenseReportForm"
                      onChange={handleERF}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup">
                    <label>Expense Allocation Form</label>
                    <Select
                      value={expenseAllocationForms ? expenseAllocationForms.filter(item => item.value == createPolicyData.selectedExpenseAllocationForm) : ''}
                      options={expenseAllocationForms}
                      name="selectedExpenseAllocationForm"
                      onChange={handleEAF}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup">
                    <label>Expense Report Workflow</label>
                    <Select
                      value={workFlows ? workFlows.filter(item => item.value == createPolicyData.selectedExpenseReportWorkFlow) : ''}
                      options={workFlows}
                      name="selectedExpenseReportWorkFlow"
                      onChange={handleEWF}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup textarea">
                    <label>Editable By</label>
                    <Select
                      value={editableByOptions ? editableByOptions.filter(item => createPolicyData.editableBy.includes(item.value)) : ""}
                      options={editableByOptions}
                      isMulti
                      name="editableBy"
                      onChange={handleEditableBy}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup textarea">
                    <label>Applies to</label>
                    <Select
                      value={appliesToOptions ? appliesToOptions.filter(item => createPolicyData.appliesTo.includes(item.value)) : ""}
                      options={appliesToOptions}
                      isMulti
                      name="appliesTo"
                      onChange={handleAppliesTo}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  {/* <label>Scan Configaration</label>
                  <div className="form-group custom labelup">
                    <Select
                      options={[
                        { value: true, label: "True" },
                        { value: false, label: "False" }
                      ]}
                      name="scanConfiguration"
                      onChange={handleSC}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div> */}

                  <h6 className="sub-heading">Missing Receipt Affidavit</h6>
                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={e => missingReceiptAfidivate(e)}
                        checked={createPolicyData.missingReceiptAfidivate}
                        value={createPolicyData.missingReceiptAfidivate}
                      />
                      <span className="slider round" />
                    </label>{" "}
                    <span>
                      {createPolicyData.missingReceiptAfidivate
                        ? "True"
                        : "False"}
                    </span>
                  </div>
                  {createPolicyData.missingReceiptAfidivate ?
                    <div className="form-group custom textarea">
                      <textarea
                        rows="3"
                        data-gramm_editor="false"
                      spellCheck="false"
                        className="form-control"
                        placeholder="&nbsp;"
                        type="text"
                        name="afidivateStatement"
                        onChange={formNameHandler}
                        value={createPolicyData.afidivateStatement || ""}
                      />
                      <label>Affidavit Statement/Acceptance</label>
                    </div>
                    : ""}
                  <div className="btn-group-margin mt-4">
                    {/* <NavLink
                      to="/administration/expense/policy-step2"
                      className="btn btn-border-success lg-padding">
                      Next Next
                    </NavLink> */}
                    <button
                      type="button"
                      className="btn"
                      onClick={updatePolicy}>
                      update policy
                    </button>
                    <NavLink to="/administration/expense/expense-policies"
                      className="btn">
                      Cancel
                    </NavLink>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditPolicyStep1Page;
