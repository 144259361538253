import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { userService, companyService } from "src/_services";
import cryptr from "../../_helpers/crypty";
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { toJS } from "mobx";
import Moment from "react-moment";
import $ from 'jquery';

@inject('menuStore')
@inject('authStore')
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      notPopup: true,
      notPopup1: true,
      limit: 10,
      page: 1,
      count: 0,
      listNotify: [],
      delecatedUser: [],
      userImg: '',
      pageName: '',
      companyId: localStorage["440a28"],
    };
    this.handleclick = this.handleclick.bind(this);
    this.handleclick2 = this.handleclick2.bind(this);
    this.handleclick4 = this.handleclick4.bind(this);
    this.handlePopup = this.handlePopup.bind(this);
    this.userDetails = JSON.parse(cryptr.decrypt(localStorage["440a24"]));
    this.getLogo();
    // this.getUserInfo()
    this.getNote()
    this.getProfileImage()
    this.pageName = '';
    this.url = '';
    this.MyApprovalSession()
  }
  MyApprovalSession = () =>{
    if(!sessionStorage.getItem("isCheckedTrip") && !sessionStorage.getItem("isCheckedExpense") && !sessionStorage.getItem("isCheckedCashAdvance")){
      sessionStorage.setItem("isCheckedTrip","true");
      sessionStorage.setItem("isCheckedExpense","true");
      sessionStorage.setItem("isCheckedCashAdvance","true");
    }
  }
  // getUserInfo = async () => {
  //   var resp = await companyService.getProfileDetails()
  //   console.log(resp)
  //   if (resp) {
  //     this.setState({
  //       userImg: resp.result && resp.result.profileImage
  //     })
  //   }
  // }
  getProfileImage = async () => {
    let id = localStorage.getItem("440a1")
    if (id) {
      var res = await companyService.getProfileImg(id, this.state.companyId);
      if (res) {
        this.setState({ userImg: res.profileImage })
      }
    }
  }


  getFirstLastUserName = (fieldData) => {
    if (fieldData && Array.isArray(fieldData) && fieldData.length > 0) {
      let firstName = (fieldData.find(field => field.columnName === "EMPLOYEE_FIRST_NAME")).fieldValue;
      let lastName = (fieldData.find(field => field.columnName === "EMPLOYEE_LAST_NAME")).fieldValue;
      return firstName + ' ' + lastName;
    } else {
      return '';
    }
  }
  componentDidMount() {
    var myItem = localStorage.getItem('sso');
    localStorage.setItem('sso',myItem);
    setTimeout(() => this.getMenu(), 1000)
    this.getNotificationList()
    this.getNotifyCount()
    if (this.userDetails.id !== undefined) {
      // console.log(this.userDetails)
      this.getDelUser(this.userDetails.id)
    }
  }
  getNotifyCount = async () => {
    var resp = await userService.getNotifications()
    if (resp) {

      this.setState({
        count: resp.count
      })
    }
  }
  getNotificationList = async () => {
    var resp = await userService.getAllNotificationList(this.state.limit, this.state.page, '')
    if (resp) {
      this.setState({
        listNotify: resp.result
      })
    }
  }
  getDelUser = async (id) => {
    var resp = await userService.getDelecatedUser(id)
    if (resp) {
      this.setState({
        delecatedUser: resp.result
      })
    }
  }
  getMenu = async () => {
    var menu = toJS(this.props.menuStore.menuItems);
    menu && menu.forEach(item => {
      if (this.props.menuStore.menuItems) {
        var dummy = this.searchTree(item, this.props.location.pathname);
        if (dummy !== null) {
          this.url = dummy.frontEndUrl;
          this.setState({
            pageName: dummy.menuName
          })
        }
      }
    })
  }
  closeNote() {
    $(".company-note-container").removeClass("show");
  }
  switchUser = (req, status) => {
    if (status) {
      localStorage.removeItem('43k32');
    }
    this.props.authStore.delecateLogin(req, status)
    // userService.switchDelUser(req, status)
  }
  getNote = async () => {
    let resp = await userService.getCompanyNote(localStorage["440a28"]);
    if (resp) {
      this.setState({
        heading: resp.heading,
        message: resp.message,
      })
    }
  }

  componentWillUpdate() {
    if (this.url !== window.location.pathname) {
      this.getMenu()
    }
  }
  searchTree = (element, matchingTitle) => {
    if (element.frontEndUrl == matchingTitle) {
      return element;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], matchingTitle);
      }
      return result;
    }
    return null;
  };
  clear() {
    this.handlePopup();

  //   var key;
  //   let array =[];
  //   for (var i = 0; i < localStorage.length; i++){
  //    key = localStorage.key(i);
  //    array.push(key)
  // }
  // let data = array.filter((item)=>{return item !== 'sso'})
  // for(let i=0;i<data.length;i++){
  //   localStorage.removeItem(data[i])
  // }
    var myItem = localStorage.getItem('sso');
    localStorage.clear();
    localStorage.setItem('sso',myItem);
    window.location.reload();
  }
  logoImg = "";
  getLogo = async () => {
    var resp = await userService.getLogoCompany();
    if (resp) {
      this.logoImg = resp.result;
      this.forceUpdate();
    }
  };
  handlePopup() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  handleclick() {
    this.setState({
      isOpen: !this.state.isOpen,
      notPopup: true
    });
  }
  handleclick2() {
    this.setState({
      notPopup: !this.state.notPopup,
      isOpen: true
    });
  }
  handleclick4() {
    this.setState({
      notPopup1: !this.state.notPopup1,
      isOpen: true
    });
  }
  openCompanyNote() {
    $(".company-note-container").toggleClass("show");
  }
  checkNotification = async (list) => {
    this.handleclick2()
    var type = list.navigateTo;
    (type === "Finace_Admin") ?
      this.goFinaceAdmin(list.userLink) :
      (type === "Approvals_Trips") ?
        this.goApproves(list.userLink) :
        (type === "Visa_Request") ?
          this.goVisaRequest(list.userLink) :
          (type === "Expense_Report") ?
            this.goExpenseReport(list.userLink) :
            (type === "Approvals_ExpenseReport") ?
              this.goApprovesExpense(list.userLink) :
              (type === "Approvals_CashAdvance") ?
                this.goApprovesCash(list.userLink) :
                (type === "My_Trips") ?
                  this.goTrips(list.userLink) :
                  (type === "Cash_Advance") ?
                    this.goCashAdvance(list.userLink) :
                    (type === "Cash_Admin") ?
                      this.goCashAdmin(list.userLink) : this.load();
    var resp = await userService.readNotification(list.id)
    if (resp) {
      this.getNotificationList()
      this.getNotifyCount()
    }
  }
  goFinaceAdmin = () => {
    this.props.history.push('/finance-main/finance-admin')
  }
  goApproves = (userLink) => {
    this.props.history.push((userLink) ? `/trips-approve/trip-travel-request/${userLink}` : `/trips-approve/trips`)
    // this.props.history.push((userLink) ? `/trips-approve/trip-travel-request/${userLink}` : `/trips-approve/v2/trips`)
  }
  goApprovesExpense = (userLink) => {
    this.props.history.push((userLink) ? `/expense/details/approval/${userLink}` : `/trips-approve/expense-reports`)
  }
  goApprovesCash = (userLink) => {
    this.props.history.push((userLink) ? `/trips-approve/cashadvance${userLink}` : `/trips-approve/cashadvance`)
    // this.props.history.push('/trips-approve/cashadvance')
  }
  goExpenseReport = (userLink) => {
    this.props.history.push((userLink) ? `/expense/details/${userLink}` : `/expense/home`)
  }
  goCashAdmin = () => {
    this.props.history.push('/cash-issued/cashadvance')
  }
  goCashAdvance = (userLink) => {
    this.props.history.push('/cash-advance/list')
  }
  goVisaRequest = () => {
    this.props.history.push('/booking/visa')
  }
  goTrips = (userLink) => {
    this.props.history.push((userLink) ? `/booking/submit/trip-details/${userLink}` : `/my-trips/list`)
  }
  load() { }

  render() {
    const { openRespMenu } = this.props;
    return (
      <div className="page-header">
        <div className="resp-toggle-menu"
          onClick={e => openRespMenu()}
        >
          <i className="material-icons">menu</i>
        </div>
        <div className="row">
          <div className="col-4">
            <h6 className="heading">{this.state.pageName}</h6>
          </div>
          <div className="col-8">
            <ul className="right-items">
              <li>
                {this.userDetails.isDelegatedUser && this.userDetails.delegateUser &&
                  <div className="act-as">
                    {this.userDetails.delegateUser.parentUserId &&
                      this.userDetails.delegateUser.parentUserId &&
                      <button
                        onClick={e => this.switchUser(this.userDetails.delegateUser.parentUserId.id, true)}
                        className="btn float-right">
                        <span className="act-text">Switch Back</span>
                        <i className="material-icons act-icon">
                          not_interested
                        </i>
                      </button>
                    }
                    <div className="shape-name">
                      <div className="left img" style={{ backgroundImage: `url(/assets/images/booking/room2.png)` }}>
                      </div>
                      <div className="right">
                        <p className="text">Parent User</p>
                        <h6 className="heading">
                          {this.userDetails.delegateUser &&
                            (this.userDetails.delegateUser.parentUserId) ?
                            <span>
                              {this.userDetails.delegateUser.parentUserId.fullName}
                              {this.userDetails.delegateUser.parentUserId.email}
                            </span>
                            :
                            ""}
                        </h6>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                }
              </li>
              <li>
                <div className="logo-holder d-none d-sm-block">
                  {(this.logoImg) ?
                    <img height="32" src={this.logoImg} alt="Client" />
                    :
                    <img height="32" src="/assets/images/google_logo.png" alt="Client" />
                  }
                </div>
              </li>
              <li>
                <div className="notification-bell" onClick={this.handleclick2}>
                  <div className="position-relative">
                    {this.state.count ?
                      <span className="round__notification">{(this.state.count > 100) ? "99" : this.state.count}</span> :
                      ""
                    }
                    <i className="material-icons">notifications_none</i>
                  </div>
                </div>
                <span className="hover-name bottom">Notifications</span>
                <div
                  className={
                    "notification-msg " +
                    (this.state.notPopup === true ? "d-none" : "")
                  }
                >
                  <div className="notification_head pb-0">
                    {/* <button className="btn btn-nothing link float-right">Clear All</button> */}
                    <div className="sub-heading mb-0">Notifications</div>
                  </div>
                  <div className="notification-body">
                    {this.state.listNotify.length === 0 ?
                      <div className="d-table d-table__middle">
                        <div>
                          <img src="/assets/images/empty-box.svg" alt="" />
                          <p className="mt-3 sub-heading">Nothing new to show</p>
                        </div>
                      </div>
                      :
                      <div>
                        {
                          this.state.listNotify.map((list, key) => {
                            // console.log(this.state.listNotify)
                            return (
                              <div key={key} className={(list.isReaded) ? "shape-name cursor-pointer" : "shape-name unread cursor-pointer"} onClick={e => this.checkNotification(list)}>
                                <NavLink to={"#"}>
                                  <div className="left l-grey l-grey-icon">
                                    <i className="material-icons">
                                      notifications_none
                                    </i>
                                  </div>
                                  <div className="right">
                                    <p className="mb-0 text-grey op-less fs-12">
                                      <Moment format="MMM DD YYYY, hh:mm a">
                                        {list.sentAt}
                                      </Moment>
                                    </p>
                                    <p className="text text-grey">
                                      {list.displayMessage}
                                    </p>
                                  </div>
                                  <div className="clearfix"></div>
                                </NavLink>
                              </div>
                            )
                          })
                        }
                      </div>
                    }
                  </div>
                  {this.state.listNotify.length === 0 ?
                    ""
                    :
                    <div className="notification-foot">
                      <NavLink onClick={e => this.handleclick2()} to="/trips-approve/notification">View All</NavLink>
                    </div>
                  }
                  {/* <div className="company-note"><button
                    onClick={e => this.openCompanyNote()}
                    className="btn btn-nothing"><i className="material-icons fs-14">keyboard_arrow_left</i> Company Note</button>
                  </div> */}
                </div>
                {/* <div className={(this.state.notPopup === true ? "company-note-container" : "company-note-container show")}>
                  <div className="top">
                    <button className="btn btn-nothing float-right"
                      onClick={e => this.closeNote()}
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <p>Company Note</p>
                    <h4>{this.state.heading}</h4>
                  </div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: this.state.message }}></div>
                </div> */}
              </li>
              <li>
                <div className="help-image" onClick={this.handleclick4}>
                  <i className="material-icons">help_outline</i>
                </div>
                <span className="hover-name bottom">Help</span>
                <div
                  className={
                    "help-msg " +
                    (this.state.notPopup1 === true ? "d-none" : "d-block")
                  }
                >
                  <ul>
                    <li>
                      <NavLink to="/help"><i className="material-icons">help_outline</i> Help</NavLink>
                    </li>
                    <li>
                      <NavLink to="/faq"><i className="material-icons">live_help</i> FAQ</NavLink>
                    </li>
                    <li>
                      <NavLink to="/support"><i className="material-icons">contact_support</i> Support</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about"><i className="material-icons">business</i> About</NavLink>
                    </li>
                    <li>
                      <NavLink to="/legal"><i className="material-icons">report_off</i> Legal</NavLink>
                    </li>
                    <li>
                      <NavLink to="/terms-conditions"><i className="material-icons">assignment_turned_in</i> Terms & Conditions</NavLink>
                    </li>
                    <li>
                      <NavLink to="/privacy-policy"><i className="material-icons">lock</i> Privacy Policy</NavLink>
                    </li>
                    <li data-toggle="modal" data-target="#sendFeedbackModal">
                      <NavLink to="#"><i className="material-icons">
                        feedback
                          </i> Send Feedback</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              {/* <li>
                <div className="more-image">
                  <i className="material-icons">
                    more_vert
                  </i>
                </div>
                <span className="hover-name bottom">Send Feedback</span>
              </li> */}
              <li>
                <div className="user-image" onClick={this.handleclick}>
                  {(this.state && this.state.userImg) ?
                    <div className="left img" style={{ backgroundImage: `url(${this.state && this.state.userImg})` }}>
                    </div>
                    :
                    <i className="material-icons">account_circle</i>}
                </div>
                <span className="hover-name bottom more">Account</span>
                <div
                  className={
                    "delegates-popup " +
                    (this.state.isOpen === true ? "d-none" : "d-block")
                  }
                >
                  <div className="user-info">
                    <div className="shape-name">

                      {(this.userDetails && this.state.userImg) ?
                        <div className="left img" style={{ backgroundImage: `url(${this.state && this.state.userImg})` }}>
                        </div>
                        :
                        <div className="left">
                          {this.userDetails.fieldData &&
                            this.userDetails.fieldData.map((list, key) => {
                              return (
                                <span key={key}>
                                  {list.columnName == "EMPLOYEE_FIRST_NAME" &&
                                    list.fieldValue.charAt(0)}
                                  {/* {list.columnName == "EMPLOYEE_LAST_NAME" &&
                                    list.fieldValue.charAt(0)} */}
                                </span>
                              );
                            })}
                        </div>}
                      <div className="right">
                        <h6 className="heading">
                          {this.getFirstLastUserName(this.userDetails.fieldData)}
                        </h6>
                        <p className="text text-lowercase">{this.userDetails.email}</p>
                      </div>
                      <div className="clearfix" />
                    </div>
                  </div>
                  {(this.state.delecatedUser.length !== 0) &&
                    <div className="act-as1">
                      <p>Delegate User</p>
                    </div>}
                  <div className="delegetes-list">
                    {this.state.delecatedUser && this.state.delecatedUser.map((item, key) => {
                      return (
                        <div key={key}>
                          <div className="shape-name" onClick={e => this.switchUser(item.delegatedUserId.id, false)}>
                            {(item.delegatedUserId && item.delegatedUserId.profileImage) ?
                              <div className="left img" style={{ backgroundImage: `url(${item.delegatedUserId && item.delegatedUserId.profileImage})` }}>
                              </div>
                              :
                              <div className="left icon"><i className="material-icons">account_circle</i></div>
                            }
                            {item.delegatedUserId &&
                              <div className="right">
                                <h6 className="heading">{item.delegatedUserId.fullName}</h6>
                                <p className="text text-lowercase">{item.delegatedUserId.email}</p>
                              </div>
                            }
                          </div>
                        </div>
                      )
                    })
                    }
                  </div>
                  <div className="btns">
                    <button
                      onClick={e => this.clear()}
                      className="float-right btn"
                    >
                      LOGOUT
                    </button>
                    <NavLink
                      to="/my-account/basic-profile"
                      className="btn"
                      onClick={this.handlePopup}
                    >
                      PROFILE SETTINGS
                    </NavLink>
                  </div>
                </div>
              </li>
            </ul>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Header);
