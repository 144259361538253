import React from "react";
import Loader from 'src/_components/common/Loader';
const itemizeExpenseTypes = props => {
  return (
    <div
      className="modal fade"
      id="itemisationExpenseModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="itemisationExpenseLable"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-head">
            <h6 className="modal-subtitle">New</h6>
            <h5 className="modal-title" id="itemisationExpenseLable">
              {" "}
              Expense Type
            </h5>
          </div>
          <div className="modal-middle pm-30">
            <div className="search-custom form-group">
              <input
                type="text"
                onChange={props.onSearchExpenseType}
                name=""
                className="form-control"
                placeholder="Search"
              />
              <span>
              <i className="material-icons">search</i>
              </span>
            </div>
          {!props.loadingItemisationExpType?
            <div className="mt-3 mb-5">
            {Object.keys(props.expenseTypes).length > 0 &&
                Object.keys(props.expenseTypes).map((categoryName,index)=>{
                 return (
                   <div key={index} className="mb-3">
                  <h6 className="sub-heading mb-2">{categoryName}</h6>
                  <ul className="modal-lists custom-ul">
                 {props.expenseTypes[categoryName].map((expense, index) => {
                    return (
                      <li
                        className={
                          props.selectedExenseType === expense.id
                            ? "active"
                            : null
                        }
                        key={index}
                        onClick={e =>
                          props.onSelectExpenseType(
                            expense.expenseTypeName,
                            expense.id
                          )
                        }
                      >
                        {expense.expenseTypeName}
                      </li>
                    );
                  })}
                  </ul>
                  </div>

               )
               
               })}
                {Object.keys(props.expenseTypes).length === 0 ? "No Records Found" : null}
              </div>
            :
            <div className="mt-3 mb-5">
              <Loader />
            </div>
          }
            <div className="modal-last btn-group-margin">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={props.onItemCancelHandler}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn"
                data-toggle="modal"
                data-target="#itemisationExpense2Modal"
                data-dismiss="modal"
                disabled={props.selectedExenseType !== "" ? false : true}
                onClick={props.onItemizeContinueHandler}
              >
                continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default itemizeExpenseTypes;
