import React from "react";
import { NavLink } from "react-router-dom";
import AmenitiesPage from "./Amenities";
import cryptr from '../../../../../_helpers/crypty';
import NumberFormat from 'react-number-format';

const HotelList = props => {
  const {
    items,
    index,
    tripDuration,

    viewAllActive,
    hotelDescription,
    viewAll,
    viewAllClose,
    currenyType,
    hotelDetail,
    loadingDetail,
    getInfo
  } = props;

  let { query } = props;
  // console.log(items)

  const itemRate = [];
  for (var i = 0; i < items.hotel_rating; i++) {
    itemRate.push(<i key={i} className="material-icons fs-14 star-rat align-sub">
      star_rate
    </i>);
  }

  // * code for adding to query
  if (query && items) {
    let decryptedQuery = JSON.parse(cryptr.decrypt(query));
    decryptedQuery.vendor = items.vendor;
    // console.log('-decrypted query ', decryptedQuery);
    query = cryptr.encrypt(JSON.stringify(decryptedQuery));
    // console.log('-decrypted query ', query);

  }
  // console.log(props.items)
  return (
    <div className="devision-container" key={index}>
      <div className="top-heading">

        <div className="right" onClick={e => getInfo(items.hotel_id, items.hotel_name, items.vendor)}>
          {/* <p className="right blue cursor-pointer" onClick={e => viewAll(index)}>
            View Facilities
          </p> */}
          {/* <p className="right blue cursor-pointer" onClick={e => viewAll(index)}>
            <p className="right blue cursor-pointer" data-toggle="modal"
              data-target={"#exampleModal" + index}>
            Hotel Rules
          </p> */}
          <p className="right blue cursor-pointer" onClick={e => viewAll(index)}>
            {/* <p className="right blue cursor-pointer" data-toggle="modal"
              onClick={e => getInfo(items.hotel_id, items.hotel_name)}
              data-target={"#exampleModal1" + index}> */}
            Hotel Description
          </p>
        </div>
        <p className="float-left show-white border-right-white bg-white">Accomodation Details</p>
        {(items.out_of_policy) &&
          <div className="p-to-head right float-left show-white border-right-white out-of-policy hover-v"
          >
            <i className="material-icons fs-14 align-middle">
              error
            </i>{" "}
            <span>Out of yinyang</span>
            <div className="hover-name bottom out-of-p">
              <p>This option breaks one or more company travel rules that prevent it from being selected.</p>
              {items.violations && items.violations.map((rule, ruleKey) => {
                return (
                  <p key={ruleKey}><i className="material-icons">
                    warning
                    </i> {rule.message}.</p>
                )
              })}
            </div>
          </div>
        }
        <div className="clearfix"></div>
      </div>
      <ul>
        <li className="hotel-options pad-sm">
          <div className="image">
            {items.hotel_photos &&
              <img src={items.hotel_photos} alt="" />
            }
            {!items.hotel_photos || items.hotel_photos === "NA" &&
              <div className="hotel-no-img">
                <div className="cen">
                  <i className="material-icons">
                    photo_size_select_actual
                </i>
                  <p>No Image</p>
                </div>
              </div>
            }
          </div>
          <div className="hotel-details">
            <div className="right">
              <div className="flight-price hotel-price room__price text-left ">
                <div className="cash">
                  <span>
                    <h5>{items.hotel_currency_code}  &nbsp;
                      <NumberFormat value={items?.total_price?.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                    </h5>
                    <p className="label">Lowest per night </p>
                  </span>
                </div>
                {/* <button onClick={() => props.enableRoom({hotel_id:items.hotel_id,hotel_name:items.hotel_name,tripDuration:tripDuration,query:query})} className="text-white"
                  > */}
                    <div className="d-button" onClick={() => props.enableRoom({hotel_id:items.hotel_id,hotel_name:items.hotel_name,tripDuration:tripDuration,query:query})}>
                      <i className="material-icons">chevron_right</i> Choose room
                    </div>
                  {/* </button> */}
              </div>
              <div className="clearfix" />
            </div>
            {items.hotel_rating &&
              <p className="mb-1">
                <span>{itemRate} Star Hotel</span>
              </p>
            }

            {/* <NavLink
              to={`/booking/hotel/details/${items.hotel_id}/${
                items.hotel_name
                }/${tripDuration}/${query}`}
            > */}
              <h5 className="title">
                <span className="under-line">{items.hotel_name}</span>{" "}
                <small>
                  -{" "}
                  {items.hotel_room_details &&
                    items.hotel_room_details[0] &&
                    items.hotel_room_details[0].hotel_room_available}{" "}
                  Room Available
                </small>
              </h5>
            {/* </NavLink> */}
            {/* {items.hotel_class} Star Hotel */}
            <div className="location-map">
              <span>
                <i className="material-icons fs-14 align-middle">
                  location_on
                </i>
                &nbsp; {items.hotel_address}
              </span>
              <button type="button">
                <NavLink
                  title={items.hotel_name}
                  onClick={e => window.open(`https://www.google.com/maps/search/${items.hotel_name}`)}
                  to={"#"}
                  className="link"
                >
                  - Show On Map
                  <i className="material-icons">
                    open_in_new
                  </i>
                </NavLink>
              </button>
            </div>
            <div className="rules">
              <span className="mr-3">
                {items.hotel_room_details &&
                  items.hotel_room_details[0] &&
                  items.hotel_room_details[0].hotel_deposit_require
                  ? <span> <i className="material-icons fs-14 align-sub">attach_money</i> Prepayment required</span>
                  : <span><i className="material-icons fs-14 align-sub">money_off</i> No prepayment required</span>}
              </span>
              <span>
                {items.hotel_room_details &&
                  items.hotel_room_details[0] &&
                  items.hotel_room_details[0].hotel_refundable
                  ? <span> <i className="material-icons fs-14 align-middle">layers</i> Refundable</span>
                  : <span><i className="material-icons fs-14 align-middle">layers_clear</i> Non-Refundable</span>
                }
              </span>
            </div>
            <div className="clearfix" />
          </div>
          <div className="clearfix" />
        </li>
      </ul>
      <div
        className={
          viewAllActive[index]
            ? "fixed-pop white-card"
            : "fixed-pop white-card show"
        }
      >
        <AmenitiesPage
          hotelDescription={hotelDetail}
          rules={items.hotel_policies}
          items={items.hotel_amenities}
          viewAllClose={viewAllClose}
          loadingDetail={loadingDetail}
          index={index}
        />
      </div>
      <div
        className="modal fade"
        id={"exampleModal" + index}
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-head"><h6 className="modal-subtitle">Hotel</h6><h5 className="modal-title" id="exampleModalLabel">Rules</h5></div>
            <div className="modal-middle pm-30">
              <ul className="list-style" dangerouslySetInnerHTML={{ __html: items.hotel_policies }}></ul>
            </div>
            <div className="modal-last btn-group-margin pt-0 pm-30">
              <button type="button" className="btn btn-badge" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id={"exampleModal1" + index}
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-head"><h6 className="modal-subtitle">Hotel</h6><h5 className="modal-title" id="exampleModalLabel">Description</h5></div>
            <div className="modal-middle pm-30">
              {loadingDetail ? "loading" :
                hotelDetail.hotel_description
              }
            </div>
            <div className="modal-last btn-group-margin pt-0 pm-30">
              <button type="button" className="btn btn-badge" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
export default HotelList;
