import React, { Component } from "react";
import BookingHomeCardPage from "./home-cards";
import BookingMenuPage from "../components/booking-menu";
// import CabBookingPage from "./CabBookingPage";
import CabBooking from "../cab/components/CabBooking";
class CabPage extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  render() {
    return (
      <div className="p-padding">
        <div className="row">
          <div className="offset-lg-1 col-lg-10">
            <BookingMenuPage />
            {/* <CabBookingPage /> */}
            <CabBooking/>
            <BookingHomeCardPage />
          </div>
        </div>
      </div>
    );
  }
}
export default CabPage;
