import React from "react";
import { NavLink } from "react-router-dom";
import ExpensePoliciesMenu from "../expense-policies-menu";
import Select from "react-select";
const PolicyStep1Page = props => {
  const {
    createPolicyData,
    formNameHandler,
    handleERF,
    handleEAF,
    handleEWF,
    handleEditableBy,
    handleAppliesTo,
    handleSC,
    missingReceiptAfidivate,
    createPolicy
  } = props;


  let expenseReportForms = [];
  let expenseAllocationForms = [];
  let workFlows = [];
  let editableBy = [];
  let appliesTo = [];

  if (createPolicyData && props.createPolicyData.expenseReportForms) {
    expenseReportForms = createPolicyData.expenseReportForms.map(el => {
      return { value: el.id, label: el.formName };
    });
    // console.log('--------------------' , props.createPolicyData.expenseAllocationForms,'---------------')
    if (createPolicyData && props.createPolicyData.expenseAllocationForms.length > 0) {
      expenseAllocationForms = createPolicyData.expenseAllocationForms.map(
        el => {
          return { value: el.id, label: el.formName };
        }
      );
    }

    if (createPolicyData && createPolicyData.workFlows) {
      workFlows = createPolicyData.workFlows.map(el => {
        return {
          value: el.workflow_id,
          label: el.workflowName
        };
      });
    }
  }

  if (createPolicyData && createPolicyData.editableByList) {
    editableBy = createPolicyData.editableByList.map(el => {
      return {
        value: el.id,
        label: el.itemName
      };
    });
  }

  if (createPolicyData && createPolicyData.appliesToList) {
    appliesTo = createPolicyData.appliesToList.map(el => {
      return {
        value: el.id,
        label: el.itemName
      };
    });
  }



  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">New Policy</h6>
              <p className="text">Add/Modify Policy Information</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            {/* <ExpensePoliciesMenu /> */}
            <div className="">
              <form>
                <div className="row">
                  <div className="col-xl-5 col-lg-6">
                    <div className="form-group custom">
                      <input
                        className="form-control"
                        placeholder="&nbsp;"
                        type="text"
                        name="policyName"
                        onChange={formNameHandler}
                        value={createPolicyData.policyName || ""}
                      />
                      <label>Name <span class="text-danger">*</span></label>
                    </div>

                    <div className="form-group custom labelup">
                      <label>Expense Report Form <span class="text-danger">*</span></label>
                      <Select
                        options={expenseReportForms}
                        name="selectedExpenseReportForm"
                        onChange={handleERF}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>

                    <div className="form-group custom labelup">
                      <label>Expense Allocation Form <span class="text-danger">*</span></label>
                      <Select
                        options={expenseAllocationForms}
                        name="selectedExpenseAllocationForm"
                        onChange={handleEAF}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>

                    <div className="form-group custom labelup">
                      <label>Expense Report Workflow <span class="text-danger">*</span></label>
                      <Select
                        options={workFlows}
                        name="selectedExpenseReportWorkFlow"
                        onChange={handleEWF}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>

                    <div className="form-group custom labelup textarea">
                      <label>Editable By <span class="text-danger">*</span></label>
                      <Select
                        options={editableBy}
                        isMulti
                        name="editableBy"
                        onChange={handleEditableBy}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>

                    <div className="form-group custom labelup textarea">
                      <label>Applies to <span class="text-danger">*</span></label>
                      <Select
                        options={appliesTo}
                        isMulti
                        name="appliesTo"
                        onChange={handleAppliesTo}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>

                    {/* <label>Scan Configaration</label>
                    <div className="form-group custom labelup">
                      <Select
                        options={[
                          { value: true, label: "True" },
                          { value: false, label: "False" }
                        ]}
                        name="scanConfiguration"
                        onChange={handleSC}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div> */}

                    <h6 className="sub-heading">Missing Receipt Affidavit <span class="text-danger">*</span></h6>
                    <div className="form-group">
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={e => missingReceiptAfidivate(e)}
                          checked={createPolicyData.missingReceiptAfidivate}
                          value={createPolicyData.missingReceiptAfidivate}
                        />
                        <span className="slider round" />
                      </label>{" "}
                      <span>
                        {createPolicyData.missingReceiptAfidivate
                          ? "True"
                          : "False"}
                      </span>
                    </div>

                    {
                      createPolicyData.missingReceiptAfidivate ?
                      <div className="form-group custom textarea">
                      <textarea
                        rows="3"
                        data-gramm_editor="false"
                        spellCheck="false"
                        className="form-control"
                        placeholder="&nbsp;"
                        type="text"
                        name="afidivateStatement"
                        onChange={formNameHandler}
                        value={createPolicyData.afidivateStatement || ""}
                        />
                      <label>Affidavit Statement/Acceptance <span class="text-danger">*</span></label>
                    </div> : ""
                      }
                    <div className="btn-group-margin mt-4">
                      {/* <NavLink
                        to="/administration/expense/policy-step2"
                        className="btn btn-border-success lg-padding"
                      >
                        Next Next
                      </NavLink> */}
                      <button
                        type="button"
                        className="btn"
                        onClick={createPolicy}
                      >
                        create Policy
                      </button>
                      <NavLink
                        to="/administration/expense/expense-policies"
                        className="btn"
                      >
                        Cancel
                      </NavLink>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PolicyStep1Page;
