import { http } from "../_helpers";
import { config } from "../env";

const getEmailTemplate = data => {
  return http
    .get(
      config.apiUrlCompany +
        `email-administration/list-all?companyId=${
          data.companyId
        }&serviceModuleId=${data.serviceModuleId}&limit=${data.limit}&page=${
          data.page
        }&search=${data.search}`
    )
    .then(res => {
      return res ? res : "";
    });
};

export const companyEmailTravelServices = { getEmailTemplate };
