import React from "react";
import { getValue } from "src/_components/common/lodsh";
import Moment from "react-moment";

export const TrainCartDetail = props => {
  return (
    <div className="white-card shadow-light mb-0">
      <div className="bg-light p-3">
        <div className="row">
          <div className="col-md-6">
            {/* Employee Preferred */}
          </div>
          <div className="col-md-6">
            <div className="btn-group-margin text-right">
              {/* <button className="btn btn-nothing px-3 border-right">E-Ticket</button>
              <button className="btn btn-nothing px-3 border-right">Invoice</button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="devision-container flight-iti pb-0">
        <div className="details main">
          <div className="name">
            <div className="logo">
              <img
                src="/assets/images/booking/Indian_Railway.png"
                alt=""
              />
            </div>
            <div className="info">
              <p>
                <b>
                  {getValue(props, "item.train_from.stationCity")} - {getValue(props, "item.train_to.stationCity")}
                </b><br />
                <b>
                  {getValue(props, "item.train_from.stationName")} - {getValue(props, "item.train_to.stationName")}
                </b>
              </p>
              <p className="timings">
                <Moment format="DD MMM YYYY hh:mm a" >
                  {getValue(props, "item.train_start_timestamp")}
                </Moment>
                {/* 12:00 PM - 4:00PM | <i className="material-icons fs-14 align-middle">
                  access_time
                    </i> {" "} 22H 22M */}
              </p>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <div className="clearfix" />
        <div className="baggage mt-4">
          <div className="row">
            <div className="col-md-6">
              <p className="icon-lg">
                <i className="material-icons">
                  work
                </i> Check In Baggage
              </p>
            </div>
            <div className="col-md-6">
              <p className="icon-lg">
                <i className="material-icons">
                  work
                </i> Cabin Baggage
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="white-card mb-0">
        <div className="pad">
          <div className="white-card border-only mb-0">
            <h6 className="sub-heading heading-blue">Fare Details</h6>
            <div className="pad-sm-sm">
              <div className="table-responsive">
                <table className="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td>
                        <p className="label">Base Fare</p>
                      </td>
                      <td className="text-right">INR 2222
                        </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="label">Surcharges</p>
                      </td>
                      <td className="text-right">INR 121
                        </td>
                    </tr>
                    <tr>
                      <td>Total (1 Adults)</td>
                      <td className="text-right">INR 2320
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default TrainCartDetail;
