import React from "react";
const FlightExpand = props => {
  const {
    className,
    changeWay,
    type,
    index,
    keys,
    from,
    to,
    list,
    fare,
    airport,
    currency,
    getTimes,
    baggageInfo
  } = props;
  return (
    <div className={className} key={keys}>
      <ul>
        <li>
          <button
            className={type === 1 ? "btn active" : "btn"}
            onClick={e => changeWay(1, index, keys)}
          >
            {" "}
            Details
          </button>
        </li>
        {/* <li>
          <button
            className={type === 2 ? "btn active" : "btn"}
            onClick={e => changeWay(2, index, keys)}
          >
            {" "}
            Fare and Cancellation
          </button>
        </li>
        <li>
          <button
            className={type === 3 ? "btn active" : "btn"}
            onClick={e => changeWay(3, index, keys)}
          >
            {" "}
            Photos
          </button>
        </li>
        <li>
          <button
            className={type === 5 ? "btn active" : "btn"}
            onClick={e => changeWay(5, index, keys)}
          >
            {" "}
            Seat Selection
          </button>
        </li> */}
      </ul>
      <div>
        {type === 1 && (
          <div className="devision">
            {list.map((item, keyInd) => {
              return (
                <div key={keyInd}>
                  <div className="devision-container">
                    <div className="details main">
                      <div className="name">
                        <div className="logo">
                          <img
                            src={
                              item.flight_image
                                ? item.flight_image
                                : "/assets/images/booking/flight-dummy.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="info">
                          <p className="timings">
                            <b>
                              {item.flight_depature_time} -{" "}
                              {item.flight_arrival_time} |
                              <i className="material-icons fs-14 align-middle">
                                access_time
                              </i>{" "}
                              {getTimes(item.flight_duration)}
                            </b>
                          </p>
                          <p className="class">
                            {fare && item.flight_operating_airline} -{" "}
                            {fare && item.flight_airline} -{" "}
                            {fare && item.flight_number}
                          </p>
                        </div>
                        <div className="clearfix" />
                      </div>
                    </div>
                    <div className="details total-time expand-timing">
                      <p>
                        <b>
                          {airport[item.flight_from_city] &&
                            airport[item.flight_from_city].c &&
                            airport[item.flight_from_city].c}{" "}
                          - {item.flight_from_city} - (
                          {airport[item.flight_from_city] &&
                            airport[item.flight_from_city].n &&
                            airport[item.flight_from_city].n}
                          )
                        </b>{" "}
                        to{" "}
                        <b>
                          {" "}
                          {airport[item.flight_to_city] &&
                            airport[item.flight_to_city].c &&
                            airport[item.flight_to_city].c}{" "}
                          - {item.flight_to_city} - (
                          {airport[item.flight_to_city] &&
                            airport[item.flight_to_city].n &&
                            airport[item.flight_to_city].n}
                          )
                        </b>
                      </p>
                    </div>
                    <div className="details economy text-right">
                      <p>{item.flight_depature_date_utc}</p>
                      <p>
                        <b>{item.classType?item.classType:item.class}</b>
                      </p>
                    </div>
                    <div className="clearfix" />
                    <div className="baggage">
                      <div className="row">
                        <div className="col-xl-3 col-sm-6">
                          <p className="icon-lg">
                            <i className="material-icons">work</i> Check In
                            Baggage -{" "}
                            {item.flight_bagageInfo ? (
                              <span>
                                {item.flight_bagageInfo.length !== 0
                                  ? item.flight_bagageInfo
                                  : "Unavailable"}
                              </span>
                            ) : (
                              <span>
                                {baggageInfo.baggageInfo &&
                                baggageInfo.baggageInfo.check_in_baggae
                                  ? baggageInfo.baggageInfo.check_in_baggae
                                  : "- Unavailable"}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="col-xl-3 col-sm-6">
                          <p className="icon-lg">
                            <i className="material-icons">work</i> Cabin Baggage
                            -{" "}
                            {baggageInfo.baggageInfo &&
                            baggageInfo.baggageInfo.cabin_baggage
                              ? baggageInfo.baggageInfo.cabin_baggage
                              : "Unavailable"}
                          </p>
                        </div>
                        {item.flight_departure_terminal && (
                          <div className="col-xl-3 col-sm-6">
                            <p className="icon-lg">
                              <i className="material-icons">flight</i> Departure
                              Terminal &nbsp; {item.flight_departure_terminal}
                            </p>
                          </div>
                        )}
                        {item.flight_arrival_terminal && (
                          <div className="col-xl-3 col-sm-6">
                            <p className="icon-lg">
                              <i className="material-icons rotate180">flight</i>{" "}
                              Arrival Terminal &nbsp;{" "}
                              {item.flight_arrival_terminal}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="amenities-more">
                      <h6>Amenities</h6>
                      <p className="d-inline-block">
                        <span className="available yes">
                          <i className="material-icons">calendar_today</i>
                        </span>{" "}
                        Standard Leg Room (76 cm)
                      </p>
                      <p className="d-inline-block">
                        <span className="available">
                          <i className="material-icons">wifi</i>
                        </span>{" "}
                        No Wifi
                      </p>
                      <p className="d-inline-block">
                        <span className="available">
                          <i className="material-icons">power</i>
                        </span>{" "}
                        No Power Outlet
                      </p>
                      <p className="d-inline-block">
                        <span className="available yes">
                          <i className="material-icons">calendar_today</i>
                        </span>{" "}
                        Standard 3-3 Layout
                      </p>
                      <p className="d-inline-block">
                        <span className="available">
                          <i className="material-icons">tv</i>
                        </span>{" "}
                        No Entertainment
                      </p>
                    </div> */}
                  </div>
                  {list.length !== keyInd + 1 && (
                    <div className="flight-divider">
                      <div>Change Flight</div>
                      {keyInd !== 0 &&
                        item.flight_arrival_terminal !==
                          list[keyInd - 1].flight_arrival_terminal && (
                          <p>Airport Change Terminal</p>
                        )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        {type === 2 && (
          <div className="devision p-3">
            <h5 className="sub-heading">
              Fare Rules{" "}
              <b>
                {from} - {to}{" "}
              </b>{" "}
              <small>All charges are per passenger</small>
            </h5>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>
                      Cancellation fee
                      <br />
                      (Airline Fee)
                    </th>
                    <th>
                      DATE CHANGE FEES
                      <br />
                      (Airline Fee + Fare Difference)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>24 hours to 365 days</td>
                    <td>
                      ADULT :{" "}
                      {fare.cancel_price ? (
                        <span>
                          {" "}
                          {currency}{" "}
                          {fare.cancel_price
                            ? new Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(fare.cancel_price)
                            : 0.00}
                          
                        </span>
                      ) : (
                        "Unavailable"
                      )}{" "}
                    </td>
                    <td>
                      ADULT :{" "}
                      {fare.reschedule_price ? (
                        <span>
                          {currency}{" "}
                          {fare.reschedule_price
                            ? new Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(fare.reschedule_price)
                            : 0.0}
                        </span>
                      ) : (
                        "Unavailable"
                      )}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {type === 3 && (
          <div className="devision p-3">
            <div className="bg-white text-center py-5 my-2">
              <h6 className="mb-0">
                There are no photos available. Kindly check airline website.
              </h6>
            </div>
            {/* <div className="row">
              <div className="col-md-3">
                <div className="images-text">
                  <button>
                    <img src="/assets/images/booking/cabin_1.png" alt="" />
                  </button>
                  <h6>Enjoy the biggest seat on the A380</h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="images-text">
                  <button>
                    <img src="/assets/images/booking/cabin_2.png" alt="" />
                  </button>
                  <h6>Tasty meals for your whole family</h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="images-text">
                  <button>
                    <img src="/assets/images/booking/cabin_3.png" alt="" />
                  </button>
                  <h6>Enjoy our generous baggage allowance</h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="images-text">
                  <button>
                    <img src="/assets/images/booking/cabin_4.png" alt="" />
                  </button>
                  <h6>Fly with our international Cabin Crew</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="images-text">
                  <button>
                    <img src="/assets/images/booking/cabin_5.png" alt="" />
                  </button>
                  <h6>Enjoy a wider screen in the A380 cabin</h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="images-text">
                  <button>
                    <img src="/assets/images/booking/cabin_6.png" alt="" />
                  </button>
                  <h6>Boredom is grounded always</h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="images-text">
                  <button>
                    <img src="/assets/images/booking/cabin_7.png" alt="" />
                  </button>
                  <h6>A regionally inspired multicourse meal</h6>
                </div>
              </div>
              <div className="col-md-3" />
            </div> */}
          </div>
        )}
        {/* {type === 4 && (
          <div className="devision p-3">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="border-top-0">TIME</th>
                    <th className="border-top-0">CANCELLATION FEES </th>
                    <th className="border-top-0">DATE CHANGE FEES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>24 HRS - 365 days</td>
                    <td>Adult: $1,838* + $108**</td>
                    <td>Adult: $538* + $48** + fare difference</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="pl-2 mt-3">
              <p> *Airline Fee. | **Curio Cancellation Service Fee.</p>
            </div>
          </div>
        )} */}
        {type === 5 && (
          <div className="seat-selection">
            <div className="not-av">
              <div className="d-t">
                <div className="d-middle">
                  Seat booking unavailable for this flight
                </div>
              </div>
            </div>
            <div className="devision">
              <div className="seat-info">
                <div className="portion">
                  <img src="/assets/images/booking/plane.svg" alt="" />
                  <button type="button" className="front" />
                  <button type="button" className="middle" />
                  <button type="button" className="back" />
                </div>
                <div className="info">
                  <ul>
                    <li>
                      <button type="button" className="seat assigned">
                        <i className="material-icons">check_circle</i>
                      </button>
                      <span className="name">Assigned</span>
                    </li>
                    <li>
                      <button type="button" className="seat free" />
                      <span className="name">Free</span>
                    </li>
                    <li>
                      <button type="button" className="seat occupied" />
                      <span className="name">occupied</span>
                    </li>
                    <li>
                      <button type="button" className="seat paid-xl" />
                      <span className="name">XL Paid Seat Rs. 800</span>
                    </li>
                    <li>
                      <button type="button" className="seat paid-lg" />
                      <span className="name">XL Paid Seat Rs. 600</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <button type="button" className="seat paid-md" />
                      <span className="name">Paid Seat Rs. 400</span>
                    </li>
                    <li>
                      <button type="button" className="seat paid-sm" />
                      <span className="name">Paid Seat Rs. 350</span>
                    </li>
                    <li>
                      <button type="button" className="seat paid-xs" />
                      <span className="name">Paid Seat Rs. 200</span>
                    </li>
                    <li>
                      <button type="button" className="seat non-rec" />
                      <span className="name">Non Reclining Seat</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="devision white pr-0">
              <div className="seat-container">
                <div className="seat-select">
                  <div className="top">
                    <button className="seat nothing">F</button>
                    <button className="seat nothing">E</button>
                    <button className="seat nothing">D</button>
                  </div>
                  <div className="mid" />
                  <div className="bottom">
                    <button className="seat nothing">C</button>
                    <button className="seat nothing">B</button>
                    <button className="seat nothing">A</button>
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat paid-sm"
                      data-toggle="tooltip"
                      title="Paid Seat Rs. 350"
                    >
                      $
                    </button>
                    <button
                      className="seat assigned"
                      data-toggle="tooltip"
                      title="Assigned"
                    />
                    <button
                      className="seat free"
                      data-toggle="tooltip"
                      title="free"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">1</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat paid-xl"
                      data-toggle="tooltip"
                      title="XL Paid Seat Rs. 800"
                    >
                      $
                    </button>
                    <button
                      className="seat paid-lg"
                      data-toggle="tooltip"
                      title="XL Paid Seat Rs. 600"
                    >
                      $
                    </button>
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat paid-md"
                      data-toggle="tooltip"
                      title="Paid Seat Rs. 400"
                    >
                      $
                    </button>
                    <button
                      className="seat paid-sm"
                      data-toggle="tooltip"
                      title="Paid Seat Rs. 350"
                    >
                      $
                    </button>
                    <button
                      className="seat paid-xs"
                      data-toggle="tooltip"
                      title="Paid Seat Rs. 200"
                    >
                      $
                    </button>
                  </div>
                  <div className="mid">
                    <button className="seat nothing">2</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied non-rec"
                      data-toggle="tooltip"
                      title="Non Reclining Seats"
                    />
                    <button
                      className="seat assigned non-rec"
                      data-toggle="tooltip"
                      title="Non Reclining Seats"
                    />
                    <button
                      className="seat paid-md non-rec"
                      data-toggle="tooltip"
                      title="Non Reclining Seats"
                    >
                      $
                    </button>
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">3</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">4</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">5</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">6</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">7</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">8</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">9</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">10</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">11</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">12</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">13</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">14</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">15</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">16</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">17</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">18</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">19</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">20</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">21</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">22</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">23</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">24</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">25</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">26</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">27</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">28</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">29</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">30</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">31</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">32</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">33</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
                <div className="seat-select">
                  <div className="top">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                  <div className="mid">
                    <button className="seat nothing">34</button>
                  </div>
                  <div className="bottom">
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                    <button
                      className="seat occupied"
                      data-toggle="tooltip"
                      title="Occupied"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FlightExpand;
