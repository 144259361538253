import React from "react";
import NumberFormat from 'react-number-format';

export const FlightCartItem = props => {
  const { index, item, secondsToHms, removeCart, nonEdit, chooseFlight, selectedItem, className, isApprover } = props;
  let currencyType = props.baseCurrency
  return (
    <li key={index}
      className={(selectedItem !== `flight${index}`) ? "itinerary-heading" : "itinerary-heading active"}
      onClick={e => chooseFlight(item, 'flight', `flight${index}`)}>
      {/* {(item.approval_status.new_request) ? <p  className="new">New Request</p> : ""}
      {(item.approval_status.approved) ? <p  className="new">Approved</p> : ""}
      {(item.approval_status.reschedule_request) ? <p  className="new">Re-schedule request</p> : ""}
      {(item.approval_status.cancel_request) ? <p  className="new">Cancel request</p> : ""} */}
      {item?.reasons
        ? (
          <div className="text-danger fs-12 px-4 py-2 border-bottom-light"> <i className="material-icons align-middle mr-1 fs-14">error</i> {item.reasons ? item.reasons : ''}</div>
        )
        :
        (
          ""
        )


      }
      {/* {item.fareContent && item.fareContent.fare && item.fareContent.fare.flight_policy_violated && (
        <p className="out-o hover-v">
          Out of policy
        <div className="hover-name bottom out-of-p">
            <p>This option breaks one or more company travel rules.</p>
            <p><i className="material-icons">
              warning
                    </i> This option is over the policy limit.</p>
            <p>User Reason - {" "}
              <b>{item.reasons}</b>
            </p>
          </div>
        </p>
      )} */}
      <div className="f-container">
        {(nonEdit) ? "" :
          <button className="btn btn-nothing remove hover-v" onClick={e => removeCart(item.fareContent.fare.flight_fare_id)}>
            Remove
        </button>
        }
        <div className="left-f">
          <div className="left-f-div">
            <div className="f-cont f-logo">
              {item.fareContent && item.fareContent.fare && (
                <img
                  width="50"
                  src={
                    item.fareContent.fare.flight_image
                      ? item.fareContent.fare.flight_image
                      : "/assets/images/booking/flight-dummy.png"
                  }
                  alt="images"
                />
              )}
            </div>
            <div className="f-cont f-time">
              <b>{item.fareContent.fare.flight_depature_time ? item.fareContent.fare.flight_depature_time : item.fareContent.content[0].flight_depature_time}{" "}
                - {" "}
                {item.fareContent.fare.flight_arrival_time}
              </b>
              <br />
              {item.fareContent && item.fareContent.fare &&
                <span>{item.fareContent.fare.flight_airline}</span>
              }
            </div>
            <div className="f-cont f-stop">
              <b>{item.fareContent.content.length - 1} Stop {" "}</b>
            </div>
            <div className="f-cont f-total">
              {item.fareContent && (
                <b>
                  {item.fareContent.fare
                    ? secondsToHms(
                      item.fareContent.fare.flight_onward_total_duration
                    )
                    : ""}
                </b>
              )}
              <br />
              {item.flight_from} - {item.flight_to}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="right-f single">
          <h5>
            {currencyType} &nbsp;
          <NumberFormat value={item.fareContent ? item.fareContent.fare.flight_total_price && item.fareContent.fare.flight_total_price.toFixed(2) : ""} displayType={'text'} thousandSeparator={true} />
          </h5>
          <button className="btn btn-nothing">View Details <i className="material-icons">
            arrow_forward_ios
        </i></button>
        </div>
        {/*<div class="dropdown more-dropdown show flight-drop">
          <button class="btn btn-nothing dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <i class="material-icons">more_vert</i>
          </button>

           <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" x-placement="bottom-end" style={{ "right": "0", "left": "auto" }}>
            {(nonEdit) ? "" :
              <button className="dropdown-item hover-v" onClick={e => removeCart(item.fareContent.fare.flight_fare_id)}>
                Remove
              </button>
            }
            <button class="dropdown-item" type="button" onClick={'javascript.void(0)'}>Reschedule</button>
            <button class="dropdown-item" type="button" onClick={'javascript.void(0)'}>Cancel</button>
          </div>

        </div>*/}
        <div className="clearfix"></div>
        {(className && className === "show-fluctuation") &&
          <div className="border-top priceleft_padding">
            <p className="mb-0 d-block pb-0">Price Fluctuation</p>
            <p className="d-block pt-0">
              <span><b>{currencyType} &nbsp;
          <NumberFormat value={item.fareContent ? item.fareContent.fare.flight_total_price && item.fareContent.fare.flight_total_price.toFixed(2) : ""} displayType={'text'} thousandSeparator={true} /> </b></span>
              <span><i class="material-icons">arrow_right_alt</i></span>
              <span className="text-danger"><b> {currencyType} &nbsp;
          <NumberFormat value={item.fareContent ? item.fareContent.fare.flight_total_price && item.fareContent.fare.flight_total_price.toFixed(2) : ""} displayType={'text'} thousandSeparator={true} /></b></span>
            </p>
          </div>
        }
      </div>
      {/* <div className="abs-right-side">
        INR{" "}
        {item.fareContent ? item.fareContent.fare.flight_total_price : ""}
      </div>
      <div className="logos-flight">
        {item.fareContent && item.fareContent.content && item.fareContent.content.map((item, key) => {
          return (
            <span className="fl-img"><img width="24" src={item.flight_image} alt="filghtLogo" /></span>
          )
        })}
        {item.fareContent && item.fareContent.content && item.fareContent.content.map((item, key) => {
          return (
            <span className="fl-name">{item.flight_operating_airline}</span>
          )
        })}
      </div>
      <div className="arrow">
        View Details <i className="material-icons">
          arrow_forward_ios
        </i>
      </div>
      <div className="destiny">
        <div className="left-destination flight">
          <div className="abs-pin" />
           <div className="totalDistance sm">
            <div className="circle">
              <i className="material-icons rotate180">
                flight
                </i>
            </div>
          </div>
           <p className="title">
            <b>{item.flight_airport[item.flight_from].n} (
            {item.flight_airport[item.flight_from].c})</b>
            <br />
            <span className="fs-12">{item.fareContent.fare.flight_depature_date_utc}{" "}
              {item.fareContent.fare.flight_depature_time}</span>
          </p>
          <div className="center">
            <p className="title">
               {item.fareContent && item.fareContent.fare && (
                <img
                  width="50"
                  src={
                    item.fareContent.fare.flight_image
                      ? item.fareContent.fare.flight_image
                      : "/assets/images/booking/flight-dummy.png"
                  }
                  alt="images"
                  className="mr-3"
                />
              )}

              {item.fareContent && (
                <b>
                  {item.fareContent.fare
                    ? secondsToHms(
                      item.fareContent.fare.flight_onward_total_duration
                    )
                    : ""}
                </b>
              )}
               by {item.fareContent ? item.fareContent.fare.flight_airline : ""}
            </p>
          </div>
          <p className="title">
            <b>{item.flight_airport[item.flight_to].n} (
            {item.flight_airport[item.flight_to].c})</b>
            <br />
            <span className="fs-12">{item.fareContent.fare.flight_arrival_date_utc}{" "}
              {item.fareContent.fare.flight_arrival_time}</span>
          </p>
        </div>
      </div>
       */}
      {/* <button className="btn btn-border-primary sm-padding">
        3 more Option
      </button> */}
    </li >
  );
};
export default FlightCartItem;
