import React from "react";

const itemisationStatics = props => {
  return (
    <div className="px-3 ">
      <div className="row">
        <div className="col-lg-4">
          <div className="amount-show total-amount">
            <h6>Amount</h6>
            <h5>{props.currencyEntry}&nbsp;
            {props.totalAmountWithOutConversion?(new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(props.totalAmountWithOutConversion)):0.00}
            </h5>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="amount-show total-itemised">
            <h6>Itemised</h6>
            <h5>{props.currencyEntry}&nbsp;
            {(new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(props.totalItemiseExpenseAmount?props.totalItemiseExpenseAmount:0))}
            </h5>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="amount-show total-pending">
            <h6>Remaining</h6>
            {+(props.totalItemiseExpenseAmount)>0?
              <h5>{props.currencyEntry}&nbsp;
              {props.totalAmountWithOutConversion?(new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format((props.totalAmountWithOutConversion-props.totalItemiseExpenseAmount))):0.00}
              </h5>
            :<h5>{props.currencyEntry}&nbsp;0.00</h5>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default itemisationStatics;
