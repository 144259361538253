import React from "react";
import { Link } from "react-router-dom";
import RulesDropdown from "../policy-violation/components/RulesDropdown";
import TypeDropdown from "../policy-violation/components/TypeDropdown";
import { Input, TextArea } from "../../../../../common/_forms";

const AddViolationPolicyPage = props => {
  // let data = props;
  const {
    allRules,
    type,
    code,
    description,
    selectedRule,
    editItemId
  } = props.state;

  const {
    inputChangeHandler,
    handleRuleClassSelection,
    typeHandler,
    addPolicyViolationHandler,
    error
  } = props;
  let types = ["G - General", "A - Air", "T - Train", "C - Car", "H - Hotel"];
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">R</div>
          <div className="right">
            <h6 className="heading">
              {editItemId
                ? "Edit Travel Violation Reason"
                : "Add Travel Violation Reason"}{" "}
            </h6>
            <p className="text">Travel Policy Violations</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="row">
            <div className="col-xl-4 col-md-6">
              <div className="form-group custom labelup">
                <label>Rule CLass</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    {selectedRule ? selectedRule : "Select a Rule"}
                  </button>
                  <div className="dropdown-menu">
                    {allRules
                      ? allRules.map(rule => {
                          return (
                            <RulesDropdown
                              handleButtonClick={handleRuleClassSelection}
                              key={rule.id}
                              name={rule.className}
                              rule={rule}
                            />
                          );
                        })
                      : "No rules found"}
                  </div>
                </div>
              </div>
              <div className="form-group custom">
                <Input
                  label="Code"
                  name="code"
                  type="text"
                  imp="true"
                  value={code}
                  error={error.message("Code", code, "required")}
                  controlFunc={e => inputChangeHandler(e)}
                />
              </div>

              <div className="form-group custom labelup">
                <label>Type</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    {type ? type : "Select a Type"}
                  </button>
                  <div className="dropdown-menu">
                    {types.map(type => (
                      <TypeDropdown
                        key={type}
                        handleTypeClick={typeHandler}
                        name={type}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <TextArea
                label="Description"
                name="description"
                value={description}
                imp="true"
                error={error.message("description", description, "required")}
                controlFunc={e => inputChangeHandler(e)}
              />
              {/* <div className="form-group custom textarea">
        <textarea 
        data-gramm_editor="false"
                      spellCheck="false"
        name="description" onChange={e => inputChangeHandler(e)} type="text" className="form-control" value={description} placeholder="&nbsp;"></textarea>
        <label>Description</label>
      </div> */}
              {/* <div className="form-group">
                <ToggleSlider
                  toggleSliderHandler={toggleThisConfiguration}
                  value={thisConfiguration}
                  checked={thisConfiguration}
                />

                <span>This Travel Configuration Only</span>
              </div>
              <div className="form-group">
                <ToggleSlider
                  toggleSliderHandler={toggleAllConfigurations}
                  value={allConfigurations}
                  checked={allConfigurations}
                />
                <span>All Travel Configurations</span>
              </div>
              <div className="form-group">
                <ToggleSlider
                  toggleSliderHandler={toggleConfigurationAndSelected}
                  value={configurationAndSelected}
                  checked={configurationAndSelected}
                />
                <span>This Configuration and Selected</span>
              </div> */}
            </div>
            <div className="col-12">
              <div className="btn-group-margin">
                <button
                  type="button"
                  onClick={addPolicyViolationHandler}
                  className="btn btn-border-success lg-padding"
                >
                  Save
                </button>
                <Link
                  to="/administration/travel/policy-violation"
                  className="btn btn-border-basic lg-padding"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddViolationPolicyPage;
