import React from "react";
import { getValue } from "src/_components/common/lodsh";
import Moment from "react-moment";
export const TrainCartItem = props => {
  return (
    <li
      className="active"
    > 
      <div className="f-container">
        {props.remove &&
        <button
          className="btn btn-nothing remove hover-v"
          data-target="#deleteTrip"
          data-toggle="modal"
          onClick={() => props.removeTrain(props.item.train_uniq_id)}
        >
          Remove
          </button>}
        <div className="left-f">
          <div className="left-f-div">
            <div className="f-cont f-logo">
              {/* when no image available */}
              {/* <div className="image">
                <div className="hotel-no-img">
                  <div className="cen">
                    <i className="material-icons">photo_size_select_actual</i>
                    <p>No Image</p>
                  </div>
                </div>
              </div> */}
              <img
                src="/assets/images/booking/Indian_Railway.png"
                alt="Train Image"
                width="50"
              />
            </div>
            <div className="f-cont f-train">
              <h6 className="mb-1">
                {/* <b>1222 - Yeswantpur Express</b> */}
              </h6>
              <p className="fs-12 addrss mb-1">{getValue(props, "item.train_from.stationCity")} - {getValue(props, "item.train_to.stationCity")}</p>
              <p className="fs-12 mb-0">
                <Moment format="DD MMM YYYY hh:mm a" >
                  {getValue(props, "item.train_start_timestamp")}
                </Moment> 
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="right-f single hotel-right-f">
          <div>
            <h5 className="mb-1">
              {getValue(props,"item.train_package_details.currency")} {getValue(props,"item.train_package_details.price")}
            </h5>
            {/* <p className="fs-12 mb-0">
              <span><i className="material-icons fs-14 align-middle">
                access_time
                </i>
              </span> 22H 11m
            </p> */}
            <button className="btn btn-nothing">
              View Details <i className="material-icons">arrow_forward_ios</i>
            </button>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </li>
  );
};
