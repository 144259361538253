import React from 'react';
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import { Input, Select } from '../../../../common/_forms';
const ItemizationWizardPage = (props) => {
    const { addSubmit, state, validator, handleChange, loading, listItemization, handleSearch, itemsPerPage, activePage, count, pageItemsList, handleItemChange, handlePageChange, choosedItem, chooseEdit, handleStatusChange, removeItem, editSubmit, getData, close } = props
    return (
        <div>
            <div className="admin-tabs">
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">@</div>
                        <div className="right">
                            <h6 className="heading">Expense Types</h6>
                            <p className="text">Manage expenses that an employee can incur that appears when an employee first creates an expense report.</p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="py-padding">
                    <div className="table-container">
                        <form>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="btn-group-margin">
                                        <NavLink to="/administration/expense/ex/itemization/itemization-step1" className="mr-2 pr-1"><button className="btn">New</button></NavLink>
                                        {(choosedItem === '') ?
                                            <button type="button" className="btn" disabled>Modify</button>
                                            :
                                            <NavLink className="mr-2 pr-1" to={"/administration/expense/ex/itemization/itemization-step1/" + choosedItem}>
                                                <button type="button" onClick={getData} className="btn">Modify</button>
                                            </NavLink>}
                                        {(choosedItem === '') ?
                                            <button type="button" className="btn" disabled>Remove</button>
                                            : <button type="button" onClick={removeItem} className="btn">Remove</button>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="search-custom">
                                        <input type="text" name="search" onChange={handleSearch} className="form-control" placeholder="Search" />
                                        <span><i className="material-icons">search</i></span>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="table-responsive">
                            <table className="table table-striped table-selected">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Secondary Expense type label</th>
                                        <th>status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listItemization && (listItemization.length === 0) &&
                                        <tr>
                                            <td colSpan="3" className="text-center">No Records Found</td>
                                        </tr>
                                    }
                                    {listItemization && listItemization.map((items, key) => {
                                        return (
                                            <tr key={key} className={(choosedItem === items.id) ? "active-tr" : ""}>
                                                <td className="cursor-pointer" onClick={e => chooseEdit(items.id)}>{items.itemizationName}</td>
                                                <td>{items.secondaryExpenseTypeLable}</td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox"
                                                            onChange={e => handleStatusChange(items)}
                                                            value={items.activeStatus}
                                                            checked={items.activeStatus} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {(count !== 0) &&
                            <div className="table-footer">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="page-count">
                                            <div className="text">Items</div>
                                            <div className="count">
                                                {pageItemsList &&
                                                    <Select
                                                        placeholder={itemsPerPage}
                                                        keyName={'items'}
                                                        options={pageItemsList}
                                                        controlFunc={e => handleItemChange(e)}
                                                    />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={itemsPerPage}
                                            totalItemsCount={count}
                                            pageRangeDisplayed={3}
                                            onChange={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div >
        </div>
    )
}
export default ItemizationWizardPage;