import React, { Component } from "react";

class TravelRequestDetailsSubmitPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  render() {
    return (
      <div>
        <div className="p-padding">
          <div className="main-box">
            <div className="bg-light heading">
              <h6>Request Details</h6>
            </div>
            <div className="p-padding">
              <div className="row">
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <p>Request Name</p>
                      <h6 className="sub-heading">BLR-NYC-BLR Trip</h6>
                    </div>
                    <div className="col-md-6 mb-3">
                      <p>Purpose</p>
                      <h6 className="sub-heading">Business Meeting</h6>
                    </div>
                    <div className="col-md-6 mb-3">
                      <p>Purposed Travel Date: From</p>
                      <h6 className="sub-heading">28/09/2019</h6>
                    </div>
                    <div className="col-md-6 mb-3">
                      <p>Purposed Travel Date: To</p>
                      <h6 className="sub-heading">7/10/2019</h6>
                    </div>
                  </div>
                  <h6 className="sub-heading">Travel Remarks</h6>
                  <p>Lorem Ipsum is simply dummy text.</p>
                  <p className="mb-4">Lorem Ipsum is simply dummy text.</p>

                  <div className="form-group add-border mb-4">
                    <label className="client">Allocation</label>
                    <div className="table-container mx-0">
                      <div className="table-responsive">
                        <table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th>Percentage</th>
                              <th>company</th>
                              <th>department</th>
                              <th>cost center</th>
                              <th>project</th>
                              <th>code</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>50%</td>
                              <td>20 (India)</td>
                              <td>(300) Sales</td>
                              <td>(2020) Manager</td>
                              <td>Project 1</td>
                              <td>2422</td>
                            </tr>
                            <tr>
                              <td>50%</td>
                              <td>20 (India)</td>
                              <td>(300) Sales</td>
                              <td>(2020) Manager</td>
                              <td>Project 1</td>
                              <td>2422</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="form-group add-border mb-4">
                    <label className="client">Personal Days Off</label>
                    <div className="table-container mx-0">
                      <div className="table-responsive">
                        <table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th>From</th>
                              <th>To</th>
                              <th>Day Count</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>28/02/2019</td>
                              <td>29/02/2019</td>
                              <td>1 Day</td>
                            </tr>
                            <tr>
                              <td>28/02/2019</td>
                              <td>29/02/2019</td>
                              <td>1 Day</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mydivs ">
                    <div className="border-bottom">
                      <div className="float-left set-pad">
                        <h6>Passports</h6>
                      </div>
                      <div className="float-right px-3 py-2">
                        <button
                          type="button"
                          className="btn btn-border-primary py-1 px-2"
                        >
                          Add
                        </button>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="country-passport">
                      <div className="first-panel">
                        <label className="checkbox-container mb-0">
                          <input type="checkbox" />
                          <span className="checkmark" />
                          <span>Indian Passport</span>
                        </label>
                      </div>
                      <div className="second-panel">
                        <div className="float-left">
                          <h6>Passport Number</h6>
                          <h5>HSJSHU82783</h5>
                        </div>
                        <div className="float-right">
                          <img src="/assets/images/booking/flag_1.png" alt="" />
                          <h6>INDIA</h6>
                        </div>
                        <div className="clearfix" />
                        <div className="dates">
                          <div className="float-left">
                            <h6>ISSUE DATE</h6>
                            <h5>01/08/2015</h5>
                          </div>
                          <div className="float-right">
                            <h6>Expire Date</h6>
                            <h5>01/08/2015</h5>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </div>
                    </div>
                    <div className="country-passport">
                      <div className="first-panel">
                        <label className="checkbox-container mb-0">
                          <input type="checkbox" />
                          <span className="checkmark" />
                          <span>US PASSPORT</span>
                        </label>
                      </div>
                      <div className="second-panel">
                        <div className="float-left">
                          <h6>Passport Number</h6>
                          <h5>HSJSHU82783</h5>
                        </div>
                        <div className="float-right">
                          <img src="/assets/images/booking/flag_2.png" alt="" />
                          <h6>USA</h6>
                        </div>
                        <div className="clearfix" />
                        <div className="dates">
                          <div className="float-left">
                            <h6>ISSUE DATE</h6>
                            <h5>01/08/2015</h5>
                          </div>
                          <div className="float-right">
                            <h6>Expire Date</h6>
                            <h5>01/08/2015</h5>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mydivs mb-0">
                    <div className="border-bottom">
                      <div className="set-pad ">
                        <h6>Approver Flow</h6>
                      </div>
                    </div>
                    <div className="approvers">
                      <div className="user-panel">
                        <div className="float-left">
                          <img
                            src="/assets/images/booking/user_11.png"
                            alt=""
                          />
                        </div>
                        <div className="float-left ml-2">
                          <h6>LEVEL 1</h6>
                          <h5>George Green</h5>
                          <h6>Finance</h6>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="user-panel bg-light">
                        <div className="float-left">
                          <img
                            src="/assets/images/booking/user_12.png"
                            alt=""
                          />
                        </div>
                        <div className="float-left ml-2">
                          <h6>LEVEL 2</h6>
                          <h5>Natasha McCoy</h5>
                          <h6>Approver</h6>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="user-panel">
                        <div className="float-left">
                          <img
                            src="/assets/images/booking/user_11.png"
                            alt=""
                          />
                        </div>
                        <div className="float-left ml-2">
                          <h6>LEVEL 3</h6>
                          <h5>Vincent Lawson</h5>
                          <h6>Manager</h6>
                        </div>
                        <div className="clearfix" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TravelRequestDetailsSubmitPage;
