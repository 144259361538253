import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { TravelBudget1Page, TravelBudget2Page, TravelBudget3Page } from './travel-budget';
import { DepartmentPage } from './departments';
import { ProjectsPage } from './projects';
class BudgetHomePage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/administration/budget') {
            this.props.history.push('/administration/travel/travel-budget')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/administration/budget') {
            this.props.history.push('/administration/travel/travel-budget')
        }
    }
    render() {
        return (
            <div className="">
                <Route path="/administration/budget/travel-budget1" component={TravelBudget1Page} />
                <Route path="/administration/budget/travel-budget1/:id" exact={true} component={TravelBudget1Page} />
                <Route path="/administration/budget/travel-budget2" component={TravelBudget2Page} />
                <Route path="/administration/budget/travel-budget3" component={TravelBudget3Page} />
                <Route path="/administration/budget/department" component={DepartmentPage} />
                <Route path="/administration/budget/project" component={ProjectsPage} />
            </div>
        )
    }
}
export default BudgetHomePage;