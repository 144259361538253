import { observable, action } from 'mobx';
// import agent from '../agent';

class UserStore {

  @observable currentUser;  

  @action pullUser(data) {
    this.loadingUser = true;
    this.currentUser = data; 
  }

}

export default new UserStore();
