import React, { Component } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import InputRange from "react-input-range";
import StarRatings from "react-star-ratings";
import Select from 'react-select';
import 'react-input-range/lib/css/index.css';
const KeyCodes = {
  comma: 188,
  enter: 13
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

const HotelFilterList = props => {
  const {
    handleAddition,
    handleDelete,
    changeRating,
    storeValue,
    currenyType,
    closeFilter,
    completeRange,
    changeCurrency,
    listOfCurrency
  } = props;
  let { tags, value, rate, minPrice, maxPrice } = props.state;
  // console.log('value is ', value);
  // if(minPrice && maxPrice){
  //   minPrice = parseInt(Math.round(minPrice));
  //   maxPrice = parseInt(Math.round(maxPrice));
  //   console.log('---- max ', maxPrice)
  // }
  return (
    <div className="col-xl-2 col-lg-3">
      <div className="custom-card">
        <div>
          <div id="filter-card" className="filter-card">
            <button
              id="filter-toggle"
              className="filter-toggle"
              onClick={e => closeFilter()}
            >
              <i className="material-icons">close</i>
            </button>
            <h6 className="heading grey">Filter</h6>
            <div className="hotel-filter-panel border-b pb-2">
              <div className="inner-content py-2">
                <div className="form-group custom labelup mb-0">
                  <label>Currency</label>
                  {listOfCurrency && (
                    <Select
                      isSearchable={true}
                      options={listOfCurrency}
                      value={{label:currenyType}}
                      onChange={e => changeCurrency(e, "currency")}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="hotel-filter-panel mb-2 pb-2 border-b">
              <h6 className="heading pb-0">Price Range {currenyType}</h6>
              <div className="inner-content mt-3 mb-3 px-4">
                <div className="">
                  <InputRange
                  draggableTrack={true}
                    maxValue={Math.ceil(maxPrice)}
                    minValue={Math.ceil(minPrice)}
                    // formatLabel={value => `${value}  ${currenyType}`}
                    value={value}
                    onChange={e => storeValue(e)}
                    onChangeComplete={value => completeRange(value)}
                  />
                  {/* <InputRange
                    draggableTrack={true}
                    maxValue={100}
                    minValue={0}
                    value={value2}
                    onChange={e => storeValue2(e)}
                    onChangeComplete={value2 => console.log('change complete value ', value2)}
                  />  */}
                </div>
              </div>
            </div>
            <div className="hotel-filter-panel border-b pb-3">
              <h6 className="heading ">Preferred Hotel</h6>
              <div className="inner-content">
                <ReactTags
                  tags={tags}
                  inputFieldPosition="top"
                  placeholder="Search Hotel Name"
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  allowDragDrop={false}
                  delimiters={delimiters}
                />
                <div className="clearfix" />
              </div>
            </div>
            <div className="hotel-filter-panel pb-4 border-b">
              <h6 className="heading ">Hotel Rating</h6>
              <div className="inner-content">
                <StarRatings
                  rating={rate}
                  starRatedColor="yellow"
                  changeRating={changeRating}
                  numberOfStars={5}
                  name="rating"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HotelFilterList;
