import React from "react";
import { NavLink } from "react-router-dom";
import { Input } from "../../../../common/_forms";

const ItemizationStep1Page = props => {
  // let data = props;
  const {
    addSubmit,
    handleChange,
    state,
    error,
    close,
    isEdit,
    updateSubmit
  } = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">R</div>
          <div className="right">
            {isEdit === false ? (
              <h6 className="heading">Add Itemization</h6>
            ) : (
              <h6 className="heading">Edit Itemization</h6>
            )}
            <p className="text">Lorem Ipsum is simply dummy</p>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="audit-step">
            <button className="s1 active">
              <span>1</span>
              General
            </button>
            <button className="s1">
              <span>2</span>
              Available Expense Types
            </button>
          </div>
          <form
            autoComplete="off"
            onSubmit={isEdit === false ? addSubmit : updateSubmit}
          >
            <div className="row">
              <div className="col-md-4">
                <div className="form-group custom">
                  <Input
                    label="Name"
                    type="text"
                    name="itemizationName"
                    className="bg-white"
                    error={error.message(
                      "itemizationName",
                      state.itemizationName,
                      "required"
                    )}
                    value={state.itemizationName}
                    controlFunc={handleChange}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label="Secondary Expense type Label"
                    type="text"
                    name="secondaryExpenseTypeLable"
                    className="bg-white"
                    error={error.message(
                      "secondaryExpenseTypeLable",
                      state.secondaryExpenseTypeLable,
                      "required"
                    )}
                    value={state.secondaryExpenseTypeLable}
                    controlFunc={handleChange}
                  />
                </div>
                <div>
                  <div className="btn-group-margin">
                    <button
                      type="submit"
                      className="btn"
                    >
                      Next
                    </button>
                    <button
                      onClick={close}
                      className="btn"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ItemizationStep1Page;
