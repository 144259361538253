import React from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Select, Input } from "../../../../common/_forms";
import Moment from "react-moment";
import Loader from "src/_components/common/Loader";
import ChangePasswordModal from "./change-password-modal";

const UserManagementPage = props => {
  const {
    handleStatusChange,
    handlePageChange,
    handleItemChange,
    pageItemsList,
    searchHandler,
    addGroupHandler,
    validator,
    closeModal,
    groupSubmitHandler
  } = props;
  const {
    groupName,
    loading,
    allGroups,
    userList,
    count,
    page,
    limit,
    selectedEmail
  } = props.state;
  const getFullName = fieldData => {
    if (fieldData.length) {
      let firstName = "";
      let middleName = "";
      let lastName = "";
      let test1 = fieldData.find(
        field => field.columnName == "EMPLOYEE_FIRST_NAME"
      );
      let test2 = fieldData.find(field => field.columnName == "MIDDLE_NAME");
      let test3 = fieldData.find(
        field => field.columnName == "EMPLOYEE_LAST_NAME"
      );
      if (test1 !== undefined) {
        firstName = test1.fieldValue;
      }
      if (test2 !== undefined) {
        middleName = test2.fieldValue;
      }
      if (test3 !== undefined) {
        lastName = test3.fieldValue;
      }
      return firstName + " " + middleName + " " + lastName;
    }
  };
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">E</div>
          <div className="right">
            <h6 className="heading">User Management</h6>
            <p className="text">
              The User Management feature allows administrators to add and
              manage employees.
            </p>
          </div>
        </div>
      </div>
      <div className="py-padding">
        <div className="table-container">
          <form>
            <div className="row">
              <div className="col-sm-9">
                <div className="btn-group-margin">
                  <button
                    type="button"
                    data-target="#addGroupModal"
                    data-toggle="modal"
                    className="btn"
                  >
                    Add User
                  </button>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="search-custom">
                  <input
                    type="text"
                    name="searchInput"
                    onChange={e => searchHandler(e)}
                    className="form-control"
                    placeholder="Search"
                  />
                  <span>
                    <i className="material-icons">search</i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Group Name</th>
                  <th>Created On</th>
                  <th>Action</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {userList.length === 0 && (
                  <tr>
                    <td colSpan="5" className="text-center">
                      {loading ? <Loader /> : "No Records Found"}
                    </td>
                  </tr>
                )}
                {userList.length !== 0 &&
                  userList.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <b className="fw-sbold">{item.fullName}</b>
                          <br />
                          {item.email}
                        </td>
                        <td>{item.group}</td>
                        <td>
                          <Moment format="DD-MMM-YYYY">{item.createdAt}</Moment>
                        </td>
                        <td>
                          <NavLink
                            to={
                              "/administration/company/management/edit-user/" +
                              item.id
                            }
                            className="btn btn-nothing hover-v"
                          >
                            <i className="material-icons fs-20">edit</i>
                            <span className="hover-name right-top">Edit</span>
                          </NavLink>
                        </td>
                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={e => handleStatusChange(item)}
                              value={item.activeStatus}
                              checked={item.activeStatus}
                            />
                            <span className="slider round" />
                          </label>
                        </td>
                        <td>
                          <div class="dropdown more-dropdown">
                            <button
                              class="btn btn-nothing dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="material-icons">more_vert</i>
                            </button>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button
                                onClick={() =>
                                  props.handleChangePasswordClick(item)
                                }
                                class="dropdown-item"
                                data-target="#changePasswordModal"
                                data-toggle="modal"
                                type="button"
                              >
                                Change Password
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {count !== 0 && (
            <div className="table-footer">
              <div className="row">
                <div className="col-sm-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      {pageItemsList && (
                        <Select
                          placeholder={limit}
                          keyName={"items"}
                          options={pageItemsList}
                          controlFunc={e => handleItemChange(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-9">
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={limit}
                    totalItemsCount={count}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Add Group Modal */}
      <div
        className="modal fade"
        id="addGroupModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="CreateUserModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form
              autoComplete="off"
              id="create-form"
              onSubmit={groupSubmitHandler}
            >
              <div className="modal-head">
                <h5 className="modal-title" id="CreateUserModalLabel">
                  Add Group
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom labelup">
                  <label>
                    Group <span className="text-danger">*</span>
                  </label>
                  <Select
                    label="Group Name"
                    placeholder={groupName || "Select a Group"}
                    name="groupName"
                    options={allGroups || []}
                    keyName="itemName"
                    className="bg-white"
                    error={validator.message(
                      "groupName",
                      groupName,
                      "required"
                    )}
                    value={groupName}
                    controlFunc={e => addGroupHandler(e)}
                  />
                </div>
                {loading === false ? (
                  <div className="modal-last btn-group-margin">
                    <button
                      type="submit"
                      className="btn"
                      onClick={groupSubmitHandler}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={closeModal}
                      className="btn close"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div className="modal-last">
                    <button type="button" className="btn">
                      Please Wait...
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* End of Add Group Modal */}
      <div
        className="modal fade"
        id="upoadModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="upoadModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="upoadModalLabel">
                Add Group
              </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group">
                <div className="upload-input">
                  <div className="upload">
                    <input type="file" />
                    <i className="material-icons">cloud_upload</i>
                    <h5>Upload</h5>
                  </div>
                </div>
              </div>
              <div className="modal-last btn-group-margin">
                <button type="submit" className="btn">
                  Save
                </button>
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChangePasswordModal
        selectedEmail={selectedEmail}
        handleChangePassword={props.handleChangePassword}
      />
    </div>
  );
};
export default UserManagementPage;
