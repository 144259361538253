import { http } from "../_helpers";
import { config } from "../env";

function getCompanyLocation(company, page, limit) {
  return http
    .get(
      config.apiUrlCompany +
        "get-location?companyId=" +
        company +
        "&page=" +
        page +
        "&limit=" +
        limit
    )
    .then(data => {
      return data;
    });
}
function createCompanyLoction(data) {
  return http
    .post(config.apiUrlCompany + "create-location", data)
    .then(data => {
      return data;
    });
}
function getCompanyLocationDetails(id, company) {
  return http
    .get(config.apiUrlCompany + "get-location/" + id + "/?companyId=" + company)
    .then(data => {
      return data;
    });
}
function companyProfile(id) {
  return http.get(config.apiUrlSuperAdmin + "project/" + id).then(data => {
    return data;
  });
}
function updateCompanyLoction(id, data) {
  return http
    .patch(config.apiUrlCompany + "update-location", data)
    .then(data => {
      return data;
    });
}
function activeInactiveCompany(id, data) {
  return http
    .patch(
      config.apiUrlCompany + "active-inactive-location?companyId=" + id,
      data
    )
    .then(data => {
      return data;
    });
}
function searchCompany(data, company, page, limit) {
  return http
    .get(
      config.apiUrlCompany +
        "search-location/" +
        data +
        "?companyId=" +
        company +
        "&page=" +
        page +
        "&limit=" +
        limit
    )
    .then(data => {
      return data;
    });
}
function updateCompanyProfile(data) {
  return http
    .patch(config.apiUrlSuperAdmin + "projects/update", data)
    .then(data => {
      return data;
    });
}

// * below is obsolete api

function getFormDetails(companyId) {
  return http
    .get(
      `${
        config.apiUrlCompany
      }/form-headers/forms?formHeaderName=employee-form&companyId=${companyId}`
    )
    .then(res => res);
}

// * New api  for getting form fields
// * this api is common for a lot of stuff and uses different payload for sending back different results
function getFormData(payload) {
  return http
    .post(`${config.apiUrlCompany}company-fields/list-all`, payload)
    .then(data => data);
}
function getListUser(data) {
  return http
    .post(config.apiUrlCompany + "company-users/list-all", data)
    .then(data => {
      return data;
    });
}
function getGroups(companyId) {
  return http
    .get(
      `${
        config.apiUrlCompany
      }list-management?companyId=${companyId}&itemCode=GRP001`
    )
    .then(res => res);
}
function searchUser(searchTerm, companyId, limit, page, sort) {
  return http
    .get(
      config.apiUrlCompany +
        `company-users/${searchTerm}?limit=${limit}&page=${page}&sort=${sort}&companyId=${companyId}`
    )
    .then(data => {
      return data;
    });
}
function createUserCompany(data) {
  return http.post(config.apiUrlCompany + "company-users", data).then(data => {
    return data;
  });
}

function activeInactiveUserManage(id, data) {
  return http
    .patch(
      config.apiUrlCompany + "company-users/" + id + "/activate-status",
      data
    )
    .then(data => {
      return data;
    });
}

// * The following is for usage by admin for editing a person's details
function getUserDetail(id) {
  return http
    .get(config.apiUrlCompany + "company-users/details?userId=" + id)
    .then(data => {
      return data ? data.result : "";
    });
}

//  * The follwoing is for usage by a user to change his profile details

function getProfileDetails() {
  return http
    .get(config.apiUrlCompany + "company-users/details")
    .then(res => res);
}

function updateProfileDetails(payload) {
  return http
    .patch(config.apiUrlCompany + "company-users/update", payload)
    .then(res => res);
}

// * Get Location
function getLocation(companyId, value) {
  return http
    .get(
      `${
        config.apiUrlCompany
      }list-management?companyId=${companyId}&parentId=${value}`
    )
    .then(res => res);
}

//  * End of get location

// * End of change profile details by a user

function updateUserCompany(id, data) {
  return http
    .patch(config.apiUrlCompany + "company-users/update?userId=" + id, data)
    .then(data => {
      return data;
    });
}

function getUserPermission(id, search, service) {
  return http
    .get(
      config.apiUrlCompany +
        "company-user-permissions?userId=" +
        id +
        "&search=" +
        search +
        "&serviceModuleId=" +
        service
    )
    .then(data => {
      return data ? data.result : "";
    });
}

function createPermission(data) {
  return http
    .post(config.apiUrlCompany + "company-user-permissions", data)
    .then(data => {
      return data ? data : "";
    });
}

function removePermission(data) {
  return http
    .patch(config.apiUrlCompany + "company-user-permissions/delete", data)
    .then(data => {
      return data ? data : "";
    });
}

function updatePermission(id, data) {
  return http
    .patch(config.apiUrlCompany + "company-user-permissions/" + id, data)
    .then(data => {
      return data ? data.result : "";
    });
}

function getCompanyService(id) {
  return http
    .get(config.apiUrlCompany + `company-users/${id}/company-service`)
    .then(data => {
      return data ? data.result : "";
    });
}
function getUserList(search, id) {
  return http
    .get(
      config.apiUrlCompany +
        `company-users/${search}?limit=10&page=1&companyId=${id}`
    )
    .then(data => {
      return data ? data : "";
    });
}

function removeDelegatedUser(id) {
  return http
    .patch(`${config.apiUrlCompany}delegated-users/${id}/delete`)
    .then(data => data);
}

function uploadProfilePhoto(selectedImage) {
  return http
    .fileUpload(`${config.apiUrlCompany}upload-profile-photo`, selectedImage)
    .then(res => res);
}

// 5020

function getTravelClass(companyId) {
  return http
    .get(`${config.apiUrlTravel}travel/rule-class?companyId=${companyId}`)
    .then(res => res);
}

// 5030
function getTravelAllowance(companyId) {
  return http
    .get(`${config.apiExpense}allowance-configuration?companyId=${companyId}`)
    .then(res => res);
}

function getTravelWorkflow(companyId) {
  return http
    .get(`${config.apiExpense}workflow/general?companyId=${companyId}`)
    .then(res => res);
}

function searchUsers(searchTerm, companyId) {
  return http
    .get(
      `${
        config.apiUrlCompany
      }company-users/${searchTerm}?limit=10&companyId=${companyId}&isApprover=true`
    )
    .then(res => res);
}

function getUserDetailOnGroupChange(id, groupId) {
  return http
    .get(
      `${
        config.apiUrlCompany
      }company-users/details?userId=${id}&groupId=${groupId}`
    )
    .then(data => {
      return data ? data.result : "";
    });
}

function passwordReset(data) {
  return http
    .post(config.apiUrlCompany + "set-password-by-admin", data)
    .then(data => {
      return data ? data : "";
    });
}

function getDefaultManagers (userId,companyId){
  return http
  .get(
    `${
      config.apiUrlCompany
    }company-users/min-details?userId=${userId}&companyId=${companyId}&fieldData=${true}&isCustom9=${true}&reportManager1=${true}&profileImg=${false}&reportManager2=${false}&connectectList=${true}`
  )
  .then(data => {
    return data ? data.result : "";
  });
}

function getReuestedUserInfo (userId,companyId){
  return http
  .get(
    `${
      config.apiUrlCompany
    }company-users/min-details?userId=${userId}&companyId=${companyId}&connectectList=${true}&fieldData=${true}&reportManager1=${false}&profileImg=${false}&reportManager2=${false}`
  )
  .then(data => {
    return data ? data.result : "";
  });
}


function getProfileImg (userId,companyId){
  return http
  .get(
    `${
      config.apiUrlCompany
    }company-users/min-details?userId=${userId}&companyId=${companyId}&profileImg=${true}`
  )
  .then(data => {
    return data ? data.result : "";
  });
}



export const getConnectedList = (companyId,search,connectedListId,page,limit) => {
  // console.log(companyId,search,connectedListId,page,limit,"service")
  return http
  .get(`${config.apiUrlCompany}list-management/by-connected-list/list?companyId=${companyId}&search=${search}&connectedListId=${connectedListId}&page=${page}&limit=${limit}`)
    .then(data => {
      // console.log(data,"res from getConnectedList")
      return data ? data : '';
    });
}






export const companyService = {
  getCompanyLocation,
  createCompanyLoction,
  getCompanyLocationDetails,
  companyProfile,
  updateCompanyLoction,
  activeInactiveCompany,
  removePermission,
  searchCompany,
  updateCompanyProfile,
  uploadProfilePhoto,
  getFormDetails,
  getFormData,
  getListUser,
  getGroups,
  searchUser,
  createUserCompany,
  activeInactiveUserManage,
  getUserDetail,
  getUserDetailOnGroupChange,
  getProfileDetails,
  updateProfileDetails,
  removeDelegatedUser,
  updateUserCompany,
  getUserPermission,
  createPermission,
  updatePermission,
  getCompanyService,
  getUserList,
  getTravelClass,
  getTravelAllowance,
  getTravelWorkflow,
  getLocation,
  searchUsers,
  passwordReset,
  getDefaultManagers,
  getReuestedUserInfo,
  getProfileImg
};
