import React from 'react'

export default function ToggleSlider(props) {
  const {
    toggleSliderHandler,
    value,
    checked
  } = props;
  return (
    <label className="switch">
      <input type="checkbox" onChange={toggleSliderHandler} value={value} checked={checked} />
      <span className="slider round"></span>
    </label>
  )
}
