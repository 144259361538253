import React, { Component } from "react";
import { Route } from "react-router-dom";
import { CashAdvanceConfigPage, 
          AddCashAdvanceConfigPage ,
          EditCashAdvanceConfig
        } from "./index";
class CashAdvanceConfigHomePage extends Component {
  constructor(props) {
    super(props);
    if (
      props.location.pathname === "/administration/expense/cash-advance-config/"
    ) {
      this.props.history.push(
        "/administration/expense/cash-advance-config/cash-advance-config"
      );
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (
      this.props.location.pathname ===
      "/administration/expense/cash-advance-config"
    ) {
      this.props.history.push(
        "/administration/expense/cash-advance-config/cash-advance-config"
      );
    }
  }
  render() {
    return (
      <div className="CashAdvanceConfigHomePage">
        <Route
          path="/administration/expense/cash-advance-config/cash-advance"
          component={CashAdvanceConfigPage}
        />
        <Route
          path="/administration/expense/cash-advance-config/add-cash-advance-config"
          component={AddCashAdvanceConfigPage}
        />
         <Route
          path="/administration/expense/cash-advance-config/edit-cash-advance-config"
          component={EditCashAdvanceConfig}
        />
      </div>
    );
  }
}
export default CashAdvanceConfigHomePage;
