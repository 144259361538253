import React from "react";
import { NavLink } from "react-router-dom";
import ConfigRulesMenu from "../config-rules-menu";
import Select from 'react-select';
import { Input } from 'src/_components/common/_forms';

const CarConfigStep2Page = props => {
  const { form2Data, formValidator2, onValueChangeHandler } = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">A</div>
          <div className="right">
            <h6 className="heading">Add Car Configuration</h6>
            <p className="text">This page is used to maintain mileage amount defined by the company policy.</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <ConfigRulesMenu />
          <div className="">
            <div className="row">
              <div className="col-md-4">
                <form>
                  {/* <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      onChange={props.onNameChangeHandler}
                    />
                    <label>Distance Limit 1</label>
                  </div> */}




                  <div className="form-group custom">
                    <Input
                      label="Distance Limit 1"
                      type="text"
                      imp="true"
                      name="distanceLimit1"
                      value={form2Data.distanceLimit1}
                      error={formValidator2.message('Distance Limit 1', form2Data.distanceLimit1, 'required')}
                      controlFunc={(e) => onValueChangeHandler(e)}
                    />
                  </div>
                  <div className="form-group custom">
                    <Input
                      label="Distance Limit 2"
                      type="text"
                      name="distanceLimit2"
                      value={form2Data.distanceLimit2}
                      error={formValidator2.message('Distance Limit 2', form2Data.distanceLimit2, 'required')}
                      controlFunc={(e) => onValueChangeHandler(e)}
                    />
                  </div>
                  <div className="form-group custom">
                    <Input
                      label="Distance Limit 3"
                      type="text"
                      name="distanceLimit3"
                      value={form2Data.distanceLimit3}
                      error={formValidator2.message('Distance Limit 3', form2Data.distanceLimit3, 'required')}
                      controlFunc={(e) => onValueChangeHandler(e)}
                    />
                  </div>












                  {/* <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      onChange={props.onNameChangeHandler}
                    />
                    <label>Distance Limit 2</label>
                  </div>
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      onChange={props.onNameChangeHandler}
                    />
                    <label>Distance Limit 3</label>
                  </div> */}
                  {/* <div className="form-group custom labelup textarea">
                    <label>Period for Distance Limit</label>
                      <Select
                        value={props.selectedEditedBy}
                        onChange={props.onEditableByChangeHandler}
                        options={props.editedAppliesDropdown}
                        name="editedBy"
                        isMulti 
                      />
                  </div>                  
                  <div className="form-group custom labelup textarea">
                    <label>Starting Month</label>
                      <Select
                        value={props.selectedEditedBy}
                        onChange={props.onEditableByChangeHandler}
                        options={props.editedAppliesDropdown}
                        name="editedBy"
                        isMulti 
                      />
                  </div> */}
                  {/* <div className="form-group custom labelup textarea">
                    <label>Starting Day</label>
                    <Select
                        value={props.selectedAppliesBy}
                        onChange={props.onAppliesToChangeHandler}
                        options={props.editedAppliesDropdown}
                        name="appliesTo"
                        isMulti
                      />
                  </div> */}

                  <div className="btn-group-margin">
                    <button type="button"
                      //to="/administration/expense/audit-rules/step2"
                      //to={(formValidator2.allValid()) ? `/administration/expense/car-config/config-step3/` : formValidator2.showMessages()}
                      onClick={(e) => props.onSubmitForm2Handler(e)}
                      className="btn btn-border-success lg-padding"
                    >
                      Next
                    </button>
                    <NavLink
                      to="/administration/expense/car-config/car-configuration"
                      className="btn btn-border-basic lg-padding"
                    >
                      Cancel
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CarConfigStep2Page;
