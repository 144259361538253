import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { PrivateRoute, history } from "./_helpers";
import { ToastContainer } from "react-toastify";
import './_assets/toast.css'
import "react-datepicker/dist/react-datepicker.css";
import "react-day-picker/lib/style.css";
import "./_assets/main.min.css";
import {
  ForgotPassword,
  PageNotFound,
  HomePage,
  LoginExternalPage,
  VerifyCode,
  ResetPassword,
  SsoLoginPage,
  InviteCreatePassword
} from "./_components";
import ETicketPage from "./_components/e-ticket";
import InvoicePage from "./_components/invoice";
import SuperAdminAuth from './super-admin';
import NetworkDetector from "./NetworkDetector";
import $ from "jquery";
import SsoUrl from "./_components/sso-login/sso-login-url";

class App extends Component {
  constructor(props) {
    super(props)
    localStorage.setItem("INET", false);
    $(".fc-widget-normal").css("display", "none");
  }
  render() {
    return (
      <div className="App">
        <Router history={history}>
          <div className="routes">
            <ToastContainer
              position="bottom-left"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange={false}
              draggable
              pauseOnHover
            />
            <Switch>
              <Route exact path="/sso-login" component={SsoLoginPage} />
              <Route exact path="/sso-callback" component={SsoLoginPage} />
              <Route exact path="/login" component={LoginExternalPage} />
              <Route exact path="/sso-login/:sso" component={SsoUrl} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route path="/verify-code" component={VerifyCode} />
              <Route path="/invite/:id" component={InviteCreatePassword} />
              <Route path="/super-admin/:companyId/:token" component={SuperAdminAuth} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/e-ticket" component={ETicketPage} />
              <Route path="/invoice" component={InvoicePage} />
              <PrivateRoute path="/" component={HomePage} />
              <Route path="**" component={PageNotFound} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}
//export default NetworkDetector(App);
export default App;
