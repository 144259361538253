import React, { Component } from 'react';
//import { TestHistoryServices, midOfficeHistoryService } from "../../../_services";
import { midOfficeHistoryService } from "../../../../../../_services";
import _ from 'lodash';
import HistoryDetailsPage from "./history-details-html";

class HistoryDetails extends Component {
  state = {
    data: [],
    type: '',
    popUp: false,
    isLoading: true,
    pnrDetails: [],
    savedPassVisa: {}
  }
  componentDidMount = () => {
    let data = {
      id: this.props.match.params.id,
      type: this.props.match.params.type
    }
    this.setState({ type: data.type });
    this.getTripData(data.id);
    if (data.type === 'FLIGHT') {
      this.getPnrData(data.id);
    }
  }
  getTripData = async (id) => {
    this.setState({ isLoading: true });
    let res = await midOfficeHistoryService.getTripData(id);
    if (res) {
      this.setState({ data: res.result, isLoading: false, userId: res.result.userId })
      this.getUserDetails();
      this.getChosenPassportVisa();
    }
  }
  enablePopUp = () => {
    this.setState({ popUp: true })
  }
  closePopUp = () => {
    this.setState({ popUp: false })
  }
  getUserDetails = async () => {
    let res = await midOfficeHistoryService.getUserDetails(this.state.userId);
    if (res) {
      this.setState({ userDetails: res.result })
    }
  }
  getPnrData = async (id) => {
    var res = await midOfficeHistoryService.getPnrdata(id)
    if (res) {
      this.setState({ pnrDetails: res.result });
    }
  }

  getChosenPassportVisa = async () => {
    let res = await midOfficeHistoryService.getChosenPassportVisa(this.state.data.tripId, this.state.data.userId);
    if (res) {
      this.setState({ savedPassVisa: res.result });
    }
  }

  render() {
    return (
      <HistoryDetailsPage
        state={this.state}
        getChosenPassportVisa={this.getChosenPassportVisa}
        getUserDetails={this.getUserDetails}
        closePopUp={this.closePopUp}
        enablePopUp={this.enablePopUp}
      />
    );
  }
}

export default HistoryDetails;
