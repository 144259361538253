import React from "react";

const currencyModalList = props => {
  const handleCheck = val => {
    return props.addCurrency.some(item => val === item);
  };
  return (
    <div
      className="modal fade"
      id="EditFormModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="EditFormModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        role="document"
      >
        <div className="modal-content border-0">
          <div className="modal-head">
            <h5 className="modal-title" id="EditFormModalLabel">
              Currency List
            </h5>
            {/* <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button> */}
          </div>
          {/* <div className="search-custom set-border">
            <input
              type="text"
              name=""
              className="add-border form-control bg-light"
              placeholder="Search"
              onChange={props.searchCurrencyList}
            />
            <span>
              <i className="material-icons">search</i>
            </span>
          </div> */}
          <div className="modal-middle">
            <div className="table-container mx-0 modal-scroll">
              <div className="table-responsive">
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th className="">Currency Name</th>
                      <th />
                      <th>Currency Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.currencyList.map((currency, index) => {
                      return (
                        <tr key={index}>
                          <td className="">
                            <label className="checkbox-container mb-0">
                              <input
                                value={currency.id}
                                type="checkbox"
                                checked={handleCheck(currency.id)}
                                onChange={e =>
                                  props.onAddCurrencyHandler(currency.id)
                                }
                              />
                              <span className="checkmark" />
                              <span>{currency.itemName}</span>
                            </label>
                          </td>
                          <td />
                          <td>{currency.itemCode}</td>
                        </tr>
                      );
                    })}

                    {props.currencyList.length <= 0 ? (
                      <tr>
                        <td colSpan={3} className="text-center">
                          No Records Found
                      </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-last pm-30 btn-group-margin">
            <button
              type="button"
              className="btn"
              onClick={props.addCompanyCurrency}
            >
              ADD
            </button>
            <button
              type="button"
              className="btn close"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default currencyModalList;
