import React from 'react';
import Pagination from "react-js-pagination";
import { NavLink } from 'react-router-dom';
import Select from "react-select";
import AuditRulesMenu from '../audit-rules-menu';
import Loader from 'src/_components/common/Loader';

const CustomStep3Page = (props) => {
  let submitButton = props.selectedExpense.length === 1 && (props.exceptionFilter && props.exceptionFilter.value !== '' && props.exceptionFilter.value !== undefined);
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">A</div>
          <div className="right">
            <h6 className="heading">Audit Rules</h6>
            <p className="text">Add/Modify Audit Rules.</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <AuditRulesMenu
            auditRule_id={props.auditRule_id}
            gotoNextMenu={props.gotoNextMenu}
          />
          <form>
            <div className="row">
              <div className="col-xl-4 col-lg-6">
                <div className="form-group custom labelup mb-0">
                  <label>Exception Visibility</label>
                  <Select
                    options={props.exeptionVisibility}
                    name="exceptionFIlter"
                    value={props.exceptionFilter}
                    onChange={props.onExceptionChangeFilterHandler}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-10">
                  <div className="btn-group-margin">
                    <button type="button"
                      className="btn"
                      data-toggle="modal"
                      onClick={props.onClickNewHandler}
                      data-target="#newExceptions">
                      New
                                    </button>
                    <button
                      onClick={(e) => props.onEditClickHandler(e)}
                      disabled={props.selectedExpense.length === 1 ? false : true}
                      className="btn"
                      data-toggle="modal"
                      data-target="#newExceptions">
                      Modify
                                    </button>
                    <button
                      onClick={props.onDeleteExceptionHandler}
                      disabled={!props.selectedExpense.length > 0}
                      type="button" className="btn ">
                      Remove
                                    </button>
                  </div>
                </div>
                <div className="col-md-2">
                </div>
              </div>
            </form>
            <div className="px-padding">
              <div className="p-4 bg-light mt-3">
                <h6 className="sub-heading"><b>Previous Selected</b></h6>
                <p className="mb-1">{props.exceptionVisbility && `Exception Visibility: ${props.exceptionVisbility}`}</p>
                <p className="mb-0">{props.auditRuleMessage && `Message: ${props.auditRuleMessage}`}</p>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Level</th>
                    {/* <th>Product Code</th> */}
                    <th>Editable By</th>
                    <th>Message</th>
                  </tr>
                </thead>
                {props.isLoading ?
                  <Loader /> :
                  <tbody>
                    {props.getAllException.length > 0 ? props.getAllException.map((list) => {
                      return (
                        <tr className={props.selectedExpense.includes(list.id) ? 'active-tr' : ''}
                          key={list.id} onClick={() => props.onSelectExceptionHandler(list.id)}>
                          <td>{list.exceptionCode}</td>
                          <td>{list.exceptionLevel}</td>
                          {/* <td>REQ</td> */}
                          <td>{
                            list.editableObj && list.editableObj.result && list.editableObj.result.length > 0 &&
                            list.editableObj.result.map((lists, index) => {
                              if (list.editableObj.result.length - 1 > index) {
                                return lists.itemName + ',';
                              } else {
                                return lists.itemName;
                              }
                            })

                          }</td>
                          <td>{list.message}</td>

                        </tr>
                      )
                    }) : <tr><td colSpan={4} className="text-center">No Records Found.</td></tr>
                    }
                  </tbody>
                }
              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                          10
                                            </button>
                        <div className="dropdown-menu">
                          <button
                            onClick={() => props.onLimitChangeHandler(10)}
                            type="button"
                            className="dropdown-item">
                            10
                                                </button>
                          <button
                            onClick={() => props.onLimitChangeHandler(20)}
                            type="button" className="dropdown-item">20
                                                 </button>
                          <button
                            onClick={() => props.onLimitChangeHandler(30)}
                            type="button" className="dropdown-item">
                            30
                                                </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <Pagination
                    activePage={props.pageNumber}
                    itemsCountPerPage={props.limit}
                    totalItemsCount={props.totalCount}
                    pageRangeDisplayed={5}
                    onChange={props.onPageChangeHandler}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="newExceptions"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="AddTemplateModalLabel"
            aria-hidden="true">
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document">
              <div className="modal-content border-0">
                <div className="modal-head">
                  <h5 className="modal-title" id="AddTemplateModalLabel">
                    New Exceptions
                </h5>
                  <button
                    type="button"
                    className="close text-white d-none"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-middle pm-30">
                  <div className="form-group custom">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      name="exceptionCode"
                      onChange={props.onExceptionCodeChangeHandler}
                      value={props.exceptionCode}
                    />
                    <label>Exception Code <span className="text-danger">*</span></label>
                    <div className="help-block">{props.validator.message(
                      "exceptionCode",
                      props.exceptionCode,
                      "required|numeric"
                    )}
                    </div>
                  </div>
                  <div className="form-group custom">
                    <input
                      className="form-control"
                      placeholder="&nbsp;"
                      type="text"
                      name="exceptionLevel"
                      onChange={props.onExceptionLevelChangeHandler}
                      value={props.exceptionLevel}
                    />
                    <label>Exception Level <span className="text-danger">*</span></label>
                    <div className="help-block">{props.validator.message(
                      "exceptionCode",
                      props.exceptionLevel,
                      "required|numeric"
                    )}
                    </div>
                  </div>
                  <div className="form-group custom labelup textarea">
                    <label>Editable By <span className="text-danger">*</span></label>
                    <Select
                      isMulti
                      options={props.editedByList}
                      name="editableBy"
                      placeholder="Search And Select"
                      value={props.editedBy}
                      onChange={props.onEditableChangeHandler}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                    <div className="help-block">{props.validator.message(
                      "exceptionCode",
                      props.editedBy[0] ? props.editedBy[0].value : '',
                      "required"
                    )}</div>
                  </div>
                  <div className="form-group custom textarea">
                    <textarea
                      rows="2"
                      data-gramm_editor="false"
                      spellCheck="false"
                      className="form-control"
                      placeholder="&nbsp;"
                      type="text"
                      name="message"
                      onChange={props.onChangeMessageHandler}
                      value={props.message}
                    />
                    <label>Message <span className="text-danger">*</span></label>
                    <div className="help-block">{props.validator.message(
                      "exceptionCode",
                      props.message,
                      "required"
                    )}
                    </div>
                  </div>
                  <div className="modal-last btn-group-margin">
                    {
                      props.isEdit ?
                        <button
                          type="button"
                          data-dismiss="modal"
                          aria-label="Close"
                          className="btn btn-border-success lg-padding"
                          onClick={props.onUpdateExceptionHandler}
                        >
                          {" "}
                          Update
                  </button> :
                        <button
                          type="button"
                          className="btn"
                          onClick={props.onSubmitExceptionHandler}
                        >
                          {" "}
                          Save
                </button>
                    }
                    <button
                      type="button"
                      className="btn"
                      data-dismiss="modal">
                      {" "}
                      Cancel
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-group-margin mt-4">
            <button
              disabled={!submitButton}
              onClick={props.onSubmitExeptionHandler}
              type="button"
              className="btn"
              data-dismiss="modal">
              {" "}
              Submit
              </button>
            <NavLink to="/administration/company/audit-rules/custom" className="btn">Cancel</NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomStep3Page;