import React from "react"; 
import { getValue } from "src/_components/common/lodsh";
import { Select } from "src/_components/common/_forms";

const VechileType = props => (
  <div>
    <div className="details to">
      <div className={(getValue(props.request,"car_type") === '' && props.isSubmitted)?"details-margin not-valid":"details-margin"}>
        <div className="d-table d-table__middle new-dropdown">
          <label className="text-left m-label" htmlFor="search2">
            <p className="label">Vechile type</p>
            <Select
              options={props.vechileTypes}
              placeholder={getValue(props.request, "car_type") !== '' ? getValue(props.request, "car_type") : "Select One"}
              controlFunc={(e) => props.handleSelectedValue(e.label, "car_type")}
              keyName="label"
            />
          </label>
        </div>
      </div>
    </div>
  </div>

)
export default VechileType;