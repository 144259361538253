import { observable, action } from 'mobx'; 
class LoaderStore {
    @observable loading = false; 
    @action loadingTrue() {
        this.loading = true;
    }
    @action loadingFalse() {
        this.loading = false;
    }
}

export default new LoaderStore();
