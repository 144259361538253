import React, { Component } from 'react';
import CarListPage from './car-list-html';
import { contractServices } from '../../../../../../_services';
import { toast } from "react-toastify";

// import Pagination from 'react-js-pagination';

class CarList extends Component {
  state = {
    allCars: [],
    removeButton: false,
    removeCar: "",
    carContractCityId: "",
    companyId: '',
    isLoading: true,

    limit: 10,
    totalCount: "",
    search: "",
    page: 1,
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    let data = {
      carContractCityId: this.props.match.params.carContractCityId,
      companyId: this.props.match.params.companyId,
      limit: this.props.match.params.limit,
      page: this.props.match.params.page
    }
    this.setState({ carContractCityId: data.carContractCityId, companyId: data.companyId, limit: data.limit, page: data.page });
    this.getAllCarsData(data);
  }
  getAllCarsData = async (data) => {
    var res = await contractServices.getAllCarsData(data);
    if (res) {
      this.setState({ isLoading: false })
      this.setState({ allCars: res.result, totalCount: res.count })
    }
    else {
      console.log('No cars added in the city. Add a car');
    }
  }
  removeCarHandler = async () => {
    let deleteId = this.state.removeCar;
    var res = await contractServices.removeCarHandler(deleteId);
    if (res) {
      let data = {
        companyId: this.props.match.params.companyId,
        carContractCityId: this.props.match.params.carContractCityId,
        limit: this.props.match.params.limit,
        page: this.props.match.params.page
      }
      this.getAllCarsData(data);
      this.setState({ removeCar: "", removeButton: false });
      toast.success(res.message);
    }
  };
  onDeleteHandler = car => {
    let removeCar = this.state.removeCar;
    if (removeCar === car) {
      this.setState({ removeCar: "", removeButton: false });
    } else {
      this.setState({ removeCar: car, removeButton: true });
    }
  };
  onSearchTextChangeHandler = async (event) => {
    let search = event.target.value;
    if (search !== '' && search !== '') {
      await this.setState({ search: event.target.value });
      let response = await contractServices.getCarListOnSearch(this.state.carContractCityId, this.state.companyId, search);
      if (response.count > 0) {
        this.setState({
          allCars: response.result,
          totalCount: response.count
        });
      } else {
        this.setState({
          allCars: []
        });
      }
    } else {
      this.setState({ search: '' });
      let data = {
        carContractCityId: this.state.carContractCityId,
        companyId: this.state.companyId,
        limit: this.state.limit,
        page: this.state.page
      }
      this.getAllCarsData(data);
    }
  }
  render() {
    return (
      <div>
        <CarListPage
          {...this.state}
          removeCarHandler={this.removeCarHandler}
          onDeleteHandler={this.onDeleteHandler}
          onSearchTextChangeHandler={this.onSearchTextChangeHandler}
        />
      </div>
    )
  }
}
export default CarList;