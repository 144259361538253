import React from 'react';

const exenseSummaryPdf =(props)=>{
    return(
        <div style={{ padding: "0 20px" }}>
        <table style={{ width: "100%", fontSize: "12px", textAlign: "left", verticalAlign: "middle", marginTop: "15px" }}>
            <thead>
                <tr>
                    <th width="8%">
                        <p style={{ marginBottom: "8px", textTransform: "uppercase", color: "#a7a9c0", fontWeight: "400" }}>SL. NO
                    </p>
                    </th>
                    <th width="62%">
                        <p style={{ marginBottom: "8px", textTransform: "uppercase", color: "#a7a9c0", fontWeight: "400" }}>Description
                    </p>
                    </th>
                    <th width="15%"></th>
                    <th width="15%">
                        <p style={{ marginBottom: "8px", textTransform: "uppercase", color: "#a7a9c0", textAlign: "right", fontWeight: "400" }}>
                            Amount
                    </p>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5" }}>1</p>
                    </td>
                    <td>
                        <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5" }}>{props.reportName}</p>
                    </td>
                    <td></td>
                    <td>
                        <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5", textAlign: "right" }}> {props.baseCurrency && props.baseCurrency}{" "}
              {new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(props.headerDetails.totalAmount)}</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="2"></td>
                    <td>
                        <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5" }}>Total</p>
                    </td>
                    <td>
                        <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5", textAlign: "right" }}>
                        {props.baseCurrency && props.baseCurrency}{" "}
              {new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(props.headerDetails.totalAmount)}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td colspan="2"></td>
                    <td>
                        <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5" }}>Cash
                        Advance Taken</p>
                    </td>
                    <td>
                        <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5", textAlign: "right" }}>
                        {props.baseCurrency && props.baseCurrency}{" "}
              {new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(props.headerDetails.amountDueCompany)}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td colspan="2"></td>
                    <td>
                        <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5" }}>
                            Difference</p>
                    </td>
                    <td>
                        <p style={{ color: "#334681", marginTop: "0", fontWeight: "600", opacity: "0.5", textAlign: "right" }}>
                        {props.baseCurrency && props.baseCurrency}{" "}
              {new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(props.headerDetails.amountDueEmployee)}
                       </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    )
}

export default exenseSummaryPdf;