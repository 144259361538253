import { http } from "../_helpers";
import { config } from "../env";

const getExpenseTypes = (companyId, policyIds, expenseTypeFilter) => {
  return http
    .get(
      `${config.apiExpense}expense-reports/policies?companyId=${companyId}&policyId=${policyIds}&expenseTypeFilter=${expenseTypeFilter}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

const getSearchExpenseType = (
  companyId,
  policyId,
  searchText,
  expenseTypeFilter
) => {
  return http
    .get(
      `${config.apiExpense}expense-reports/policies?companyId=${companyId}&policyId=${policyId}&search=${searchText}&expenseTypeFilter=${expenseTypeFilter}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

const getAddExpenseFormField = (companyId, expenseType) => {
  return http
    .get(
      `${config.apiExpense}expense-reports/expenseType?companyId=${companyId}&expenseTypeId=${expenseType}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

const getPoliciesTypes = companyId => {
  return http
    .get(`${config.apiExpense}expense-reports/policies?companyId=${companyId}`)
    .then(data => {
      return data ? data.result : "";
    });
};

const createExpensePolicy = data => {
  return http
    .post(`${config.apiExpense}expense-report/headerForm`, data)
    .then(data => {
      return data ? data : "";
    });
};

const getAllCreatedExpenseOldAPI = (companyId, headerId) => {
  return http
    .get(
      `${config.apiExpense}expense-report?companyId=${companyId}&headerId=${headerId}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

// v2 report header
const getAllCreatedExpense = (companyId, headerId) => {
  return http
    .get(
      `${
        config.apiExpense
      }v2/expense-report?companyId=${companyId}&headerId=${headerId}&reqAllInfo=${false}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

// v2 expenseentry edit
const editExpenseEntryList = (companyId, entryId) => {
  return http
    .get(
      `${config.apiExpense}expense/listformfields?companyId=${companyId}&entryId=${entryId}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

//v2  leftsidebar
const getAllCreatedExpenseNew = (companyId, headerId, limit, page) => {
  return http
    .get(
      `${config.apiExpense}expense-report/entryForm/list?companyId=${companyId}&headerId=${headerId}&limit=${limit}&page=${page}`
    )
    .then(data => {
      return data ? data : "";
    });
};

// for report entry

const getExpenseEntryDetailsOldAPI = entryId => {
  return http
    .get(`${config.apiExpense}expense-report-entry/${entryId}`)
    .then(data => {
      return data ? data.result : "";
    });
};

// v2  report entry

const getExpenseEntryDetails = (companyId, entryId) => {
  return http
    .get(
      `${config.apiExpense}expense-report/entryForm/list?companyId=${companyId}&entryId=${entryId}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

// v2 for itemization

const getExpenseEntryItemizationDetails = entryId => {
  return http
    .get(
      `${config.apiExpense}expense-report/itemization/list?entryId=${entryId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const addExpenseType = data => {
  return http
    .post(`${config.apiExpense}expense-report/entryForm`, data)
    .then(data => {
      return data ? data : "";
    });
};

const createItemizationExpenseType = data => {
  return http
    .post(`${config.apiExpense}expense-report/itemization`, data)
    .then(data => {
      return data ? data : "";
    });
};

// old API for edit expense entry
// const editExpenseEntryForm = entryId => {
//   return http
//     .get(`${config.apiExpense}expense-report-entry/${entryId}`)
//     .then(data => {
//       return data ? data : "";
//     });
// };

// for version 2

// old
// const getEditHeaderReportForm = (companyId, headerId) => {
//   return http
//     .get(
//       `${config.apiExpense}expense-report?companyId=${companyId}&headerId=${headerId}`
//     )
//     .then(data => {
//       return data ? data.result : "";
//     });
// };

const getEditHeaderReportFormNew = (companyId, headerId) => {
  return http
    .get(
      `${config.apiExpense}expense/listformfields?companyId=${companyId}&headerId=${headerId}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

// end

const updateExpenseEntryForm = data => {
  return http
    .patch(`${config.apiExpense}expense-report/entryForm`, data)
    .then(data => {
      return data ? data : "";
    });
};

const updateHeaderENtryForm = data => {
  return http
    .patch(`${config.apiExpense}expense-report/headerForm`, data)
    .then(data => {
      return data ? data : "";
    });
};

const deleteExpenseEntryForm = headerId => {
  return http
    .delete(`${config.apiExpense}expense-report/delete?entryId=${headerId}`)
    .then(data => {
      return data ? data : "";
    });
};

const getAllExpenseReports = (companyId, page, limit, search) => {
  return http
    .get(
      `${
        config.apiExpense
      }v2/expense-report?companyId=${companyId}&limit=${limit}&page=${page}&search=${search}&reqAllInfo=${false}`
      // `${config.apiExpense}expense-report?companyId=${companyId}&limit=${limit}&page=${page}&search=${search}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const uploadAvailableReceipt = (data, companyId, userId) => {
  return http
    .fileUpload(
      `${config.apiExpense}receipt?companyId=${companyId}&userId=${userId}`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};
const fetchAllReportReceipt = (userId, companyId, limit, page) => {
  if (limit === undefined || page === undefined || userId === undefined) {
    return http
      .get(
        `${config.apiExpense}receipt?companyId=${companyId}&userId=${userId}`
      )
      .then(data => {
        return data ? data : "";
      });
  } else {
    return http
      .get(
        `${config.apiExpense}receipt?companyId=${companyId}&userId=${userId}&limit=${limit}&page=${page}`
      )
      .then(data => {
        return data ? data : "";
      });
  }
};

const editItemisation = (entryId, itemizationId) => {
  return http
    .get(
      `${config.apiExpense}expense-report/itemization?entryId=${entryId}&itemizationId=${itemizationId}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};
const updateItemizationExpenseType = data => {
  return http
    .patch(`${config.apiExpense}expense-report/itemization`, data)
    .then(data => {
      return data ? data : "";
    });
};

const deleteItemizationExpenseType = (entryId, itemizationFieldId) => {
  return http
    .delete(
      `${config.apiExpense}expense-report/delete?entryId=${entryId}&itemizationFieldId=${itemizationFieldId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const uploadReceiptReport = (data, reportEntryId, companyId) => {
  return http
    .post(
      `${config.apiExpense}expense-report/uploadReceipt?reportEntryId=${reportEntryId}&companyId=${companyId}`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};

const uploadReceiptReportComputer = (
  data,
  reportEntryId,
  companyId,
  userId
) => {
  return http
    .fileUpload(
      `${config.apiExpense}expense-report/uploadFromComputer?reportEntryId=${reportEntryId}&companyId=${companyId}&userId=${userId}`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};

const attachCashAdvanceReceipt = data => {
  return http
    .patch(`${config.apiExpense}cashAdvance/assign`, data)
    .then(data => {
      return data ? data : "";
    });
};

const getCashAdvanceReceiptHeader = (companyId, headerId) => {
  return http
    .get(
      `${config.apiExpense}cashAdvance/assign?companyId=${companyId}&headerId=${headerId}`
    )
    .then(data => {
      return data ? data : "";
    });
};
const deleteExpenseReceipt = (data, expenseEntryId, companyId) => {
  return http
    .patch(
      `${config.apiExpense}expense-report/deleteReceipt?reportEntryId=${expenseEntryId}&companyId=${companyId}`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};
const getAllCashAdvanceCreatedList = (
  comapnyId,
  pagenumber,
  limit,
  search,
  headerId
) => {
  return http
    .get(
      `${config.apiExpense}cashAdvance/assign?companyId=${comapnyId}&page=${pagenumber}&limit=${limit}`
    )
    .then(data => {
      return data ? data : "";
    });
};
const attachDettachAffidavate = (companyId, entryId, affidavateStatus) => {
  return http
    .get(
      `${config.apiExpense}expense-report/affidavate?companyId=${companyId}&entryId=${entryId}&affidavateStatus=${affidavateStatus}`
    )
    .then(data => {
      return data ? data : "";
    });
};
const getAddAllocationFormField = (companyId, policyId) => {
  return http
    .get(
      `${config.apiExpense}expense-reports/policies?companyId=${companyId}&policyId=${policyId}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

const addExpenseAllocation = data => {
  return http
    .post(`${config.apiExpense}expense-report/allocation`, data)
    .then(data => {
      return data ? data : "";
    });
};

const getEditAllocationFormField = (companyId, headerId) => {
  return http
    .get(
      `${config.apiExpense}expense-report-allocation?companyId=${companyId}&headerId=${headerId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getConnectedListData = (companyId, parentId) => {
  return http
    .get(
      `${config.apiUrlCompany}list-management?companyId=${companyId}&parentId=${parentId}`
    )
    .then(data => {
      return data ? data.result : [];
    });
};

const getConnectedListDataForSpecific = childId => {
  return http
    .get(`${config.apiUrlCompany}list-management/details?id=${childId}`)
    .then(data => {
      return data ? data.result : [];
    });
};

const getPerdiem = data => {
  return http.post(`${config.apiExpense}get-perdiem`, data).then(data => {
    return data ? data.result : [];
  });
};

const getTotalReportExpenses = (companyId, headerId) => {
  return http
    .get(
      `${config.apiExpense}expense-report/total?companyId=${companyId}&headerId=${headerId}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

const submitExpenseReportData = (companyId, headerId, reportName) => {
  return http
    .get(
      `${config.apiExpense}expense-report/submit?companyId=${companyId}&headerId=${headerId}&reportName=${reportName}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const deleteAllocationEntries = data => {
  return http
    .patch(`${config.apiExpense}expense-report/deleteAllocation`, data)
    .then(data => {
      return data ? data : "";
    });
};

const deleteExpenseReportReceipt = receiptId => {
  return http.delete(`${config.apiExpense}/receipt/${receiptId}`).then(data => {
    return data ? data : "";
  });
};

const getAllLocationsName = search => {
  return http
    .get(
      `${config.apiUrlSuperAdmin}cities?isDependecyRequired=false&page=1&limit=20&search=${search}`
    )
    .then(data => {
      return data ? data : "";
    });
};

//all the below services used in travel expense

const getTravelExpenseTypes = (companyId, policyId) => {
  return http
    .get(
      `${config.apiExpense}request-expense-policy?companyId=${companyId}&policyId=${policyId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getTravelExpenseReportForm = data => {
  return http
    .post(`${config.apiUrlCompany}company-fields/list-all`, data)
    .then(data => {
      return data ? data : "";
    });
};

const createExpenseEntryTravel = (data, id) => {
  return http
    .post(
      `${config.apiUrlTravel}travel/expense-request/additional-advance/${id}`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};

const createCashAdvanceTravelExpense = (data, id) => {
  return http
    .post(
      `${config.apiUrlTravel}travel/expense-request/cash-advance/${id}`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};

const EditCashAdvanceDataList = TRIP_ID => {
  return http
    .get(config.apiUrlTravel + `travel/expenseCashAdvance/${TRIP_ID}`)
    .then(data => {
      return data;
    });
};

const updateTravelExpense = (data, id) => {
  return http
    .patch(
      `${config.apiUrlTravel}travel/expense-request/update-additional-advance/${id}`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};

const deleteTravelExpenseEntry = (id, additionalAdvanceId) => {
  return http
    .patch(
      `${config.apiUrlTravel}travel/expense-request/delete-additional-advance/${id}?additionalAdvanceId=${additionalAdvanceId}`
    )
    .then(data => {
      return data ? data : "";
    });
};
const getApprovalExpenseEntryDetails = entryId => {
  return http
    .get(`${config.apiExpense}workflow/process/user?id=${entryId}`)
    .then(data => {
      return data ? data : "";
    });
};
const getApprovalForExpenseEntryInAppr = entryId => {
  return http
    .get(`${config.apiExpense}workflow/process?id=${entryId}`)
    .then(data => {
      return data ? data : "";
    });
};
const approveExpenseReport = (data, cashAdvanceId) => {
  return http
    .patch(
      `${config.apiExpense}workflow/process/update?workflowProcessId=${cashAdvanceId}`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};
const getApprovalUserList = async (companyId, search) => {
  return http
    .get(
      `${config.apiUrlCompany}company-users/${search}?companyId=${companyId}&isApprover=true&search=${search}`
    )
    .then(data => {
      return data ? data : "";
    });
};
const getCashAdvanceDetails = cashAdvanceId => {
  return http
    .get(
      `${config.apiExpense}workflow/process/user?id=${cashAdvanceId}&type=cash`
    )
    .then(data => {
      return data ? data : "";
    });
};
const getRecallExpenseReport = expenseEntryId => {
  return http
    .get(
      `${config.apiExpense}workflow/process/recall/user?id=${expenseEntryId}&type=expense`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getFinanceApprovalExpenseEntryDetails = (companyId, headerId) => {
  return http
    .get(
      `${config.apiExpense}expense-report?companyId=${companyId}&headerId=${headerId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getFinanceRecallExpenseEntryDetails = (companyId, headerId, comments) => {
  return http
    .patch(
      `${config.apiExpense}expense-report/callback?companyId=${companyId}&headerId=${headerId}`,
      comments
    )
    .then(data => {
      return data ? data : "";
    });
};

const patchFinanceApprove = (companyId, headerId, reportName) => {
  return http
    .get(
      `${config.apiExpense}expense-report/submit?companyId=${companyId}&headerId=${headerId}&reportName=${reportName}&status=Verified`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getCreateExpenseTripForTravel = (companyId, tripId) => {
  return http
    .get(
      `${config.apiExpense}expenseTrip?companyId=${companyId}&tripId=${tripId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const postGetcopyDownData = data => {
  return http
    .patch(`${config.apiUrlCompany}company-fields/update-copy-down`, data)
    .then(data => {
      return data ? data : "";
    });
};

const getCartDetailsList = tripID => {
  return http
    .get(config.apiUrlTravel + `cart/all/price?TRIP_ID=${tripID}`)
    .then(data => {
      return data ? data : "";
    });
};
const getDeleteExpenseEntryHeader = (headerId, companyId) => {
  return http
    .get(
      config.apiExpense +
        `expense-report/delete?headerId=${headerId}&companyId=${companyId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const disableExpenseTripInTravel = (companyId, requestId) => {
  return http
    .get(
      config.apiExpense +
        `expenseTripStatus?requestId=${requestId}&companyId=${companyId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const enableExpenseTripInTravel = (companyId, requestId) => {
  return http
    .get(
      config.apiExpense +
        `expenseTripEnable?requestId=${requestId}&companyId=${companyId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

export const updatePerDiemDetails = (payload, perDiemId) => {
  return http
    .post(
      config.apiUrlTravel +
        `travel/expense-request/update-per-diem/${perDiemId}`,
      payload
    )
    .then(data => {
      return data ? data : "";
    });
};

export const expenseReport = {
  getExpenseTypes,
  getSearchExpenseType,
  getAddExpenseFormField,
  getPoliciesTypes,
  createExpensePolicy,
  getAllCreatedExpense,
  addExpenseType,
  getExpenseEntryDetails,
  createItemizationExpenseType,
  // editExpenseEntryForm,
  // getEditHeaderReportForm,
  updateExpenseEntryForm,
  updateHeaderENtryForm,
  deleteExpenseEntryForm,
  getAllExpenseReports,
  uploadAvailableReceipt,
  fetchAllReportReceipt,
  editItemisation,
  updateItemizationExpenseType,
  deleteItemizationExpenseType,
  uploadReceiptReport,
  uploadReceiptReportComputer,
  attachCashAdvanceReceipt,
  getCashAdvanceReceiptHeader,
  deleteExpenseReceipt,
  getAllCashAdvanceCreatedList,
  attachDettachAffidavate,
  getAddAllocationFormField,
  addExpenseAllocation,
  getConnectedListData,
  getTotalReportExpenses,
  submitExpenseReportData,
  getEditAllocationFormField,
  deleteAllocationEntries,
  deleteExpenseReportReceipt,
  getAllLocationsName,
  getTravelExpenseTypes,
  getTravelExpenseReportForm,
  createExpenseEntryTravel,
  createCashAdvanceTravelExpense,
  EditCashAdvanceDataList,
  updateTravelExpense,
  deleteTravelExpenseEntry,
  getApprovalExpenseEntryDetails,
  approveExpenseReport,
  getApprovalUserList,
  getCashAdvanceDetails,
  getRecallExpenseReport,
  getFinanceApprovalExpenseEntryDetails,
  getFinanceRecallExpenseEntryDetails,
  patchFinanceApprove,
  getCreateExpenseTripForTravel,
  postGetcopyDownData,
  getCartDetailsList,
  getApprovalForExpenseEntryInAppr,
  getDeleteExpenseEntryHeader,
  enableExpenseTripInTravel,
  disableExpenseTripInTravel,
  getConnectedListDataForSpecific,
  getPerdiem,

  // added newly
  getEditHeaderReportFormNew,
  getAllCreatedExpenseNew,
  editExpenseEntryList,
  getExpenseEntryItemizationDetails,
  getAllCreatedExpenseOldAPI,
  getExpenseEntryDetailsOldAPI
};
