import React from 'react';
import Menu from '../menu';
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
const ItinerarySearchPage = (props) => {
    let data = props;
    return (
        <div>
            <div className="admin-tabs">
                <Menu />
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">C</div>
                        <div className="right">
                            <h6 className="heading">Itinerary Sets</h6>
                            <p className="text">Lorem Ipsum is simply dummy text.</p>
                        </div>
                    </div>
                </div>
                <div className="py-padding">
                    <div className="table-container">
                        <form>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="search-custom">
                                        <input type="text" name="" className="form-control" placeholder="Date" />
                                        <span><i className="material-icons">calendar_today</i></span>
                                    </div>
                                </div>
                                <div className="col-md-2 offset-8">
                                    <div className="search-custom">
                                        <input type="text" name="" className="form-control" placeholder="Search" />
                                        <span><i className="material-icons">search</i></span>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Departure City</th>
                                        <th>Departure Date/Time</th>
                                        <th>Arrival City</th>
                                        <th>Arrival Date/Time</th>
                                        <th>Arrival Rate Location </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="add-minus"></span>Mexico City
                                            </td>
                                        <td>09/08/2019</td>
                                        <td>San Francisco</td>
                                        <td>10/10/2019</td>
                                        <td> </td>
                                    </tr>
                                    <tr className="">
                                        <td colSpan="8">
                                            <div className="table-container">
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-movable">
                                                        <thead>
                                                            <tr>
                                                                <th>Travel Date</th>
                                                                <th>Vendor</th>
                                                                <th>Report </th>
                                                                <th>Submit Date</th>
                                                                <th>Expense Type</th>
                                                                <th>Claimed Amount</th>
                                                                <th>Approved Amount</th>
                                                                <th>Approval Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>08/03/2018</td>
                                                                <td>Terry Raid</td>
                                                                <td>Training For</td>
                                                                <td>08/02/2019
                                                                    </td>
                                                                <td>Fixed Meals</td>
                                                                <td>957$</td>
                                                                <td>957$</td>
                                                                <td>
                                                                    <label className="switch">
                                                                        <input type="checkbox" checked />
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>08/03/2018</td>
                                                                <td>Terry Raid</td>
                                                                <td>Training For</td>
                                                                <td>08/02/2019
                                                                    </td>
                                                                <td>Fixed Meals</td>
                                                                <td>957$</td>
                                                                <td>957$</td>
                                                                <td>
                                                                    <label className="switch">
                                                                        <input type="checkbox" checked />
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>08/03/2018</td>
                                                                <td>Terry Raid</td>
                                                                <td>Training For</td>
                                                                <td>08/02/2019
                                                                    </td>
                                                                <td>Fixed Meals</td>
                                                                <td>957$</td>
                                                                <td>957$</td>
                                                                <td>
                                                                    <label className="switch">
                                                                        <input type="checkbox" checked />
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="add-minus"></span>Mexico City
                                            </td>
                                        <td>09/08/2019</td>
                                        <td>San Francisco</td>
                                        <td>10/10/2019</td>
                                        <td> </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="add-minus"></span>Mexico City
                                            </td>
                                        <td>09/08/2019</td>
                                        <td>San Francisco</td>
                                        <td>10/10/2019</td>
                                        <td> </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="add-minus"></span>Mexico City
                                            </td>
                                        <td>09/08/2019</td>
                                        <td>San Francisco</td>
                                        <td>10/10/2019</td>
                                        <td> </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="add-minus"></span>Mexico City
                                            </td>
                                        <td>09/08/2019</td>
                                        <td>San Francisco</td>
                                        <td>10/10/2019</td>
                                        <td> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-footer">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="page-count">
                                        <div className="text">Items</div>
                                        <div className="dropdown">
                                            <button type="button" className="btn dropdown-toggle"
                                                data-toggle="dropdown">
                                                10
                                            </button>
                                            <div className="dropdown-menu">
                                                <button type="button" className="dropdown-item">10</button>
                                                <button type="button" className="dropdown-item">20</button>
                                                <button type="button" className="dropdown-item">30</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <Pagination
                                        activePage={data.active}
                                        itemsCountPerPage={10}
                                        totalItemsCount={10}
                                        pageRangeDisplayed={3}
                                        onChange={data.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="EditFormModal" tabIndex="-1" role="dialog" aria-labelledby="EditFormModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="EditFormModalLabel">Add Delegate</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="search-custom p-3">
                            <input type="text" name="" className="form-control" placeholder="Search" />
                            <span className="p-3"><i className="material-icons">search</i></span>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="table-responsive">
                                    <table className="table table-striped table-movable">
                                        <tbody>
                                            <tr>
                                                <td>Chnadler Bing</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Ross Geller</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Joey Tribiani</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Pheobe Buffay</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Monika Geller</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Rachel Green</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Pheobe Buffay</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Monika Geller</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Rachel Green</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div >
                        <div className="modal-footer">
                            <button type="button" className="btn btn-border-success lg-padding">ADD</button>
                            <button type="button" className="btn btn-border-basic lg-padding" data-dismiss="modal">Close</button>
                        </div>
                    </div >
                </div >
            </div >
            <div className="modal fade" id="EditFormModal2" tabIndex="-1" role="dialog" aria-labelledby="EditFormModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="EditFormModalLabel">Settings</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="inner-header px-2">
                                <div className="shape-header">
                                    <div className="left">M</div>
                                    <div className="right">
                                        <h6 className="heading">Mia</h6>
                                        <p className="text">mia@me.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="audit-step ">
                                <button type="button" className="s1 btn-default active">Expense</button>
                                <button type="button" className="s1 btn-default">Booking</button>
                                <button type="button" className="s1 btn-default">Report</button>
                                <button type="button" className="s1 btn-default">Approvel</button>
                            </div>
                            <div className="step-1 d-none">
                                <div className="container">
                                    <div className="row">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-movable border-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Can Submit Requests</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" checked />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Can View Receipts</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="step-2 ">
                                <div className="container">
                                    <div className="row">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-movable border-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Can Prepare</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" checked />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Can Travel</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="step-3 d-none">
                                <div className="container">
                                    <div className="row">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-movable border-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Can Submit Reports</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" checked />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Can View Receipts</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Can Use Reporting</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="step-4 d-none">
                                <div className="container">
                                    <div className="row">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-movable border-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Can Approve</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" checked />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Can Approve temporary </td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Can Preview for Approver</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Receives Approve Emails</td>
                                                        <td>
                                                            <label className="switch">
                                                                <input type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="modal-footer">
                            <button type="button" className="btn btn-border-success lg-padding" data-dismiss="modal">Save</button>
                            <button type="button" className="btn btn-border-basic lg-padding" data-dismiss="modal">Close</button>
                        </div>
                    </div >
                </div >
            </div >
        </div >
    )
}
export default ItinerarySearchPage;