import React from "react";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { Input } from 'src/_components/common/_forms';
const ApproverPage = props => {
  const {
    approversData,
    onSearchHandler,
    deleteApprover,
    handlePageChange,
    toggleApproverSelect,
    handleItemChange,
    formNameHandler,
    onModalCancel,
    createApprover,
    modifyApproverData,
    validator,
    ModifyValidator,
    handleChangeApprover,
    isSearchUserListOpen,
    getSingleApprover,
    changeCurrency,
    listOfCurrency

  } = props

  let customCurrency = []
  approversData.listOfCurrency.forEach(item => {
    customCurrency.push({ value: item.itemName, label: item.itemCode })
  })

  let isItemSelected = props.approversData.selectedApprover.length;
  let ApproverData = approversData.approverData && approversData.approverData.map((approver) => {
    return (
      <tr
        key={approver.id}
        className={
          approversData.selectedApprover.some(i => i.id === approver.id)
            ? "active-tr"
            : null
        }
      >
        <td onClick={toggleApproverSelect.bind(this, approver)}>
          {approver.name}
        </td>

        <td onClick={toggleApproverSelect.bind(this, approver)}>
          {approver.level}
        </td>
        {/* <td></td> */}
        <td onClick={toggleApproverSelect.bind(this, approver)}>
          {/* {approver.editableObj && approver.editableObj.result.map(el => (
          <>{el.itemName} </>
        ))} */}
          {approver.amount}
        </td>
      </tr>
    );
  })


  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">A</div>
            <div className="right">
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h6 className="heading">Additional Approver</h6>
              <p className="text">Add Approvers</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-9">
                  <div className="btn-group-margin">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#newApprover">
                      Add Approver
                    </button>
                    <button
                      type="button"
                      className="btn"
                      data-target="#modifyApprover"
                      data-toggle="modal"
                      onClick={getSingleApprover}
                      disabled={
                        isItemSelected &&
                          (ApproverData && ApproverData)
                          ? false
                          : true
                      }>

                      Modify
                    </button>
                    <button
                      type="button"
                      className="btn"

                      onClick={e =>
                        deleteApprover(approversData.selectedApprover)
                      }
                      disabled={
                        isItemSelected &&
                          (ApproverData && ApproverData)
                          ? false
                          : true
                      }>
                      Remove
                    </button>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="search-custom">
                    <input
                      type="text"
                      // onChange={e => onSearchHandler(e)}
                      name="searchInput"
                      className="form-control"
                      placeholder="Search"
                    />
                    <span><i className="material-icons">search</i></span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Level</th>
                    {/* <th>Currency</th> */}
                    <th>Amount</th>
                    {/* <th className="text-right">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {approversData &&
                    approversData.approverData.length !== 0 ? (
                      ApproverData
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Approvers found</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>

            {ApproverData ?
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown">
                            {approversData.itemPerPage}
                          </button>
                          <div className="dropdown-menu">
                            <button
                              onClick={handleItemChange.bind(this, 10)}
                              className="dropdown-item">
                              10
                            </button>
                            <button
                              onClick={handleItemChange.bind(this, 20)}
                              className="dropdown-item">
                              20
                            </button>
                            <button
                              onClick={handleItemChange.bind(this, 30)}
                              className="dropdown-item">
                              30
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={approversData.activePage}
                      itemsCountPerPage={approversData.itemPerPage}
                      totalItemsCount={approversData.totalItemsCount}
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div> : ""}
          </div>
        </div>
        {/* Add New Approver */}
        <div
          className="modal fade"
          id="newApprover"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddApproverModalLabel"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <button
                  type="button"
                  className="close text-white d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5 className="modal-title" id="AddApproverModalLabel">
                  Add Approver
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom">
                  <Input
                    label="Approver Name"
                    type="text"
                    name="searchApprover"
                    defaultValue="Approver Name"
                    value={props.selectedApproverD.name || ""}
                    controlFunc={e => handleChangeApprover(e)}
                  // error={this.validator.message('Approver', this.state.level, 'required|approver')}
                  />

                  {/* <div className="help-block">{validator.message(
                    "name",
                    approversData.name,
                    "required|name"
                  )}</div> */}
                </div>
                {approversData.isSearchUserListOpen && (
                  approversData.searchedUsers.length ?
                    (<ul className="scroll-ul">
                      {approversData.searchedUsers.map(user => {
                        return (
                          <li key={user.id} onClick={e => props.selectChangeApprover(user)} >
                            {user.name}
                          </li>
                        )
                      })}
                    </ul>) :
                    (<div className="mb-4">
                    </div>))
                }
                {/* <div className="help-block"></div> */}

                <div className="form-group custom">
                  <input
                    className="form-control"
                    placeholder="&nbsp;"
                    type="text"
                    name="level"
                    onChange={formNameHandler}
                    value={props.level || ""}

                  />
                  <label>Level <span className="text-danger">*</span></label>
                  <div className="help-block">{validator.message(
                    "Level",
                    approversData.level,
                    "required|Level"
                  )}</div>
                  <div className="help-block"></div>
                </div>
                <div className="form-group custom labelup">
                  <div className="type-panel-out">
                    <div className="inner-body">
                      {/* <div className="form-group custom labelup mb-0"> */}
                      {customCurrency && (
                        <Select
                          isSearchable={true}
                          options={customCurrency}
                          placeholder={customCurrency.value}
                          onChange={e => changeCurrency(e, "currency")}
                        />
                      )}
                      <label>Currency <span className="text-danger">*</span></label>
                      <div className="help-block">{validator.message(
                        "currency",
                        approversData.currency,
                        "required|Currency"
                      )}</div>
                      <div className="help-block"></div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="form-group custom">
                  <input
                    className="form-control"
                    placeholder="&nbsp;"
                    type="text"
                    name="amount"
                    onChange={formNameHandler}
                    value={props.amount || ""}

                  />
                  <label>Amount <span className="text-danger">*</span></label>
                  <div className="help-block">{validator.message(
                    "amount",
                    approversData.amount,
                    "required|amount"
                  )}</div>
                  <div className="help-block"></div>
                </div>
                <div className="modal-last btn-group-margin">
                  {!props.isClickSubmit ?
                    <>
                      <button
                        type="button"
                        className="btn"
                        onClick={createApprover}>
                        {" "}
                        Save
                  </button>
                      <button
                        type="button"
                        className="btn close"
                        onClick={props.onModalCancel}
                        data-dismiss="modal">
                        {" "}
                        Cancel
                  </button>
                    </>
                    :
                    <button
                      type="button"
                      className="btn"
                    >
                      {" "}
                      Please Wait...
                </button>
                  }


                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------------------Modify approver details -------------------------------- */}
        <div
          className="modal fade"
          id="modifyApprover"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ModifyApproverModalLabel"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <button
                  type="button"
                  className="close text-white d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5 className="modal-title" id="ModifyApproverModalLabel">
                  Modify Approver
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom">
                  <Input
                    label="Approver Name"
                    type="text"
                    name="searchApprover"
                    defaultValue="Approver Name"
                    value={approversData.selectedApproverD.name ? approversData.selectedApproverD.name : ""}
                    controlFunc={e => handleChangeApprover(e)}
                  // error={this.validator.message('Approver', this.state.level, 'required|approver')}
                  />

                  {/* <div className="help-block">{validator.message(
                    "name",
                    approversData.name,
                    "required|name"
                  )}</div> */}
                </div>
                {approversData.isSearchUserListOpen && (
                  approversData.searchedUsers.length ?
                    (<ul className="scroll-ul">
                      {approversData.searchedUsers.map(user => {
                        return (
                          <li key={user.id} onClick={e => props.selectChangeApprover(user)} >
                            {user.name}
                          </li>
                        )
                      })}
                    </ul>) :
                    (<div className="mb-4">
                    </div>))
                }
                <div className="form-group custom">
                  <input
                    className="form-control"
                    placeholder="&nbsp;"
                    type="text"
                    name="level"
                    onChange={formNameHandler}
                    value={approversData.level}
                  />
                  <label>Level <span className="text-danger">*</span></label>
                  <div className="help-block">
                    {ModifyValidator.message(
                      "level",
                      approversData.level,
                      "required|level"
                    )}
                  </div>
                </div>

                {/* <div className="form-group custom labelup">
                  <div className="type-panel-out">
                    <div className="inner-body"> */}
                {/* <div className="form-group custom labelup mb-0"> */}
                {/* {customCurrency && (
                        <Select
                          isSearchable={true}
                          options={customCurrency}
                          placeholder={customCurrency.value}
                          onChange={e => changeCurrency(e, "currency")}
                        />
                      )}
                      <label>Currency <span className="text-danger">*</span></label>
                      <div className="help-block">{ModifyValidator.message(
                        "currency",
                        approversData.currency,
                        "required|Currency"
                      )}</div>
                      <div className="help-block"></div> */}
                {/* </div> */}
                {/* </div>
                  </div>
                </div> */}

                <div className="form-group custom">
                  <input
                    className="form-control"
                    placeholder="&nbsp;"
                    type="text"
                    name="amount"
                    onChange={formNameHandler}
                    value={approversData.amount}

                  />
                  <label>Amount <span className="text-danger">*</span></label>
                  <div className="help-block">
                    {ModifyValidator.message(
                      "amount",
                      approversData.amount,
                      "required|amount"
                    )}
                  </div>
                </div>

                <div className="modal-last btn-group-margin">
                  <button
                    type="button"
                    className="btn"
                    onClick={modifyApproverData}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn close"
                    onClick={props.onModalCancel}
                    data-dismiss="modal">
                    {" "}
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApproverPage;