import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import CompanyProfilePage from './company-profile/company-profile';
import EditCompanyProfilePage from './edit-company-profile/edit-company-profile';
import PageNotFound from '../../../../page-not-found/page-not-found-html';

class ProfileHomePage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/administration/company/profile') {
            this.props.history.push('/administration/company/profile/list')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/administration/company/profile') {
            this.props.history.push('/administration/company/profile/list')
        }
    }
    render() {
        return (
            <div>
                <Switch>
                    <Route path='/administration/company/profile/list' component={CompanyProfilePage} />
                    <Route path='/administration/company/profile/edit' component={EditCompanyProfilePage} />
                    <Route path='**' component={PageNotFound} />
                </Switch>
            </div>
        )
    }
}
export default ProfileHomePage;