import React from "react";
import uuid from "uuid";
const MultiSelect = props => (
  <div>
    <div>
      {props.selected &&
        props.selected.map((item, key) => {
          return (
            <div className="file-output-close" key={key}>
              {item.airLine}
              <span
                className="file-close"
                onClick={e => props.getAirline(item.code, item.airLine)}
              >
                &times;
              </span>
            </div>
          );
        })}
    </div>
    <div className="form-group custom labelup bg-white mb-0">
      <label>{props.placeholder}</label>
      <div className="dropdown" key={uuid.v4()}>
        <button
          type="button"
          className="btn dropdown-toggle"
          data-toggle="dropdown"
        >
          {props.placeholder}
        </button>
        <div className="dropdown-menu">
          {props.list &&
            Object.keys(props.list).map(key => {
              return (
                <button
                  key={key}
                  onClick={e => props.getAirline(key, props.list[key])}
                //   class={props.perfered.indexOf(key) === -1?"dropdown-item":"dropdown-item active"}
                >
                  {props.list[key]}
                </button>
              );
            })}
        </div>
        <div className="help-block">{props.error}</div>
      </div>
    </div>
  </div>
);

export default MultiSelect;
