import React from "react";
import { NavLink } from "react-router-dom";
const ErrorObjectPage = () => {

  return (
    <div>
      <div className="">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Import/Export</h6>
              <p className="text">
              Use Import/Export section to Import or Export reports to CSV or XLS based on filters.
              </p>
            </div>
          </div>
        </div>
        <div className="p-padding importdata">
          <div className="bg-white">
            <div className="row">
              <div className="col-md-3 import-left-items pr-0">
                <ul>
                  <li className="active"><NavLink to="#">Import</NavLink></li>
                  <li><NavLink to="/administration/company/export">Export</NavLink></li>
                  <li><NavLink to="/administration/company/history-log">History Logs</NavLink></li>
                </ul>
              </div>
              <div className="col-md-9 pl-0">
                <div className="p-padding history-log main-box">
                  <h6 className="heading p-0">Upload Complete with Errors</h6>
                  <div className="form-group add-border mb-0">
                    <label className="client font-weight-bold text-danger">7 Errors Found</label>
                    <div className="py-padding">
                      <div className="px-3 pb-3">
                        <div className="float-left">
                          <div className="position-relative">
                            <div className="rounded__close">
                              <i className="material-icons align-middle fs-14">
                                close
                          </i>
                            </div>
                          </div>
                        </div>
                        <div className="right">
                          <p className="mb-0">Line 1: Employee Type Header Is Missing, Please make sure the header Exists.</p>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="px-3 pb-3">
                        <div className="float-left">
                          <div className="position-relative">
                            <div className="rounded__close">
                              <i className="material-icons align-middle fs-14">
                                close
                          </i>
                            </div>
                          </div>
                        </div>
                        <div className="right">
                          <p className="mb-0">Line 1: Employee Type Header Is Missing, Please make sure the header Exists.</p>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="px-3">
                        <div className="float-left right">Show More</div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="float-left">
                      <h6 className="heading pl-0 pb-0 mb-0">Error Objects</h6>
                    </div>
                    <div className="float-right">
                      <button className="btn">Download Error Objects</button>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="table_head_bg">
                        <tr className="dashed-border-bottom">
                          <th className="border-0 py-3 text-uppercase">Employee Name</th>
                          <th className="border-0 py-3 text-uppercase">Id</th>
                          <th className="border-0 py-3 text-uppercase">Dob</th>
                          <th className="border-0 py-3 text-uppercase">Phone Number</th>
                          <th className="border-0 py-3 text-uppercase">Desigantion</th>
                          <th className="border-0 py-3 text-uppercase">DEP</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-left border-right">
                          <td className="align-middle">Jose Wills</td>
                          <td className="align-middle">18/01/2018</td>
                          <td className="align-middle">Attendee</td>
                          <td className="align-middle">10,000</td>
                          <td className="align-middle">Manager</td>
                          <td>DEP1</td>
                        </tr>
                        <tr className="border-left border-right">
                          <td className="align-middle">Jose Wills</td>
                          <td className="align-middle">18/01/2018</td>
                          <td className="align-middle">Attendee</td>
                          <td className="align-middle">10,000</td>
                          <td className="align-middle">Manager</td>
                          <td>DEP1</td>
                        </tr>
                        <tr className="border-left border-right">
                          <td className="align-middle">Jose Wills</td>
                          <td className="align-middle">18/01/2018</td>
                          <td className="align-middle">Attendee</td>
                          <td className="align-middle">10,000</td>
                          <td className="align-middle">Manager</td>
                          <td>DEP1</td>
                        </tr>
                        <tr className="border-bottom border-left border-right ">
                          <td className="align-middle">Jose Wills</td>
                          <td className="align-middle">18/01/2018</td>
                          <td className="align-middle">Attendee</td>
                          <td className="align-middle">10,000</td>
                          <td className="align-middle">Manager</td>
                          <td>DEP1</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="py-3 px-3 note-box">
                    <div className="float-left pt-1">
                    <i className="material-icons">
                      speaker_notes
                      </i>
                    </div>
                    <div className="right">
                      <p className="mb-0">Note: The file has been updated (apart from the above rows) you can 
                      <a href="#" className="text-primary"> download </a>
                          above file and Upload it back with relevant modifications to update</p>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ErrorObjectPage;
