import React, { Component } from "react";
import { Route } from "react-router-dom";
import {
  CustomPage,
  CustomStep1Page,
  CustomStep2Page,
  CustomStep3Page
} from "./index";
class AuditRulesHomePage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/administration/company/audit-rules/") {
      this.props.history.push("/administration/company/audit-rules/custom");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (
      this.props.location.pathname === "/administration/company/audit-rules"
    ) {
      this.props.history.push("/administration/company/audit-rules/custom");
    }
  }
  render() {
    return (
      <div className="AuditHomePage">
        <Route
          path="/administration/company/audit-rules/custom"
          component={CustomPage}
        />
        <Route
          path="/administration/company/audit-rules/step1"
          exact
          component={CustomStep1Page}
        />
        <Route
          path="/administration/company/audit-rules/step1/:id"
          exact
          component={CustomStep1Page}
        />
        <Route
          path="/administration/company/audit-rules/step2"
          exact
          component={CustomStep2Page}
        />
        <Route
          path="/administration/company/audit-rules/step2/:id"
          exact
          component={CustomStep2Page}
        />
        <Route
          path="/administration/company/audit-rules/step3"
          exact
          component={CustomStep3Page}
        />
        <Route
          path="/administration/company/audit-rules/step3/:id"
          exact
          component={CustomStep3Page}
        />
      </div>
    );
  }
}
export default AuditRulesHomePage;
