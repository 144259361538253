import DatePicker from "react-datepicker";
import { getValue } from "src/_components/common/lodsh";
const CabDatePicker = props => (
  <div>
    <div className="details to date-car">
      <div className={(getValue(props.request, props.name) === '' && props.isSubmitted && props.isRequired) ? "details-margin not-valid" : "details-margin"}>
        <div className="d-table d-table__middle">
          <label className="text-left m-label" htmlFor="search2">
            <p className="label">{props.label} Date</p>
            <h6 className="place">
              <DatePicker
                selected={(props.request[props.name]) ? new Date(props.request[props.name]) : null}
                onChange={date =>
                  props.handleDate(date, props.name)
                }
                minDate={new Date()}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
                disabledDays={{ after: props.carPickupDate }}
              />
            </h6>
          </label>
        </div>
      </div>
    </div>
  </div>
)
export default CabDatePicker;
