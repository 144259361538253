import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { companyService, masterService } from "src/_services";
import SimpleReactValidator from "simple-react-validator";
import { alertConstants } from "src/_constants/alert.constants";
import $ from "jquery";
import { string } from "prop-types";
import { Object } from "es6-shim";
class ProfileSettingsPage extends Component {

  state = {

  };
  componentDidMount() {

  }

  render() {
    const path = "url(/assets/images/jenni.jpg)";
    const profImg = {
      backgroundImage: path
    };



    return (
      <div>
        <div className="p-padding">
          <div className="cover-picture">
            <img src="/assets/images/cover_pic.png" alt="cover picture" />
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="ml-4">
                <div className="user-profile">
                  <div className="img" style={profImg}>
                    <div className="upload">
                      Upload
                          <input type="file" />
                    </div>
                  </div>
                </div>
                <div className="user-class">
                  <h6>User Access</h6>
                  <div className="form-group mb-0">
                    <span>Test User</span>
                    <label className="switch float-right">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="user-class">
                  <h6>Travel Class</h6>
                  <p className="mb-0">VIP Prime</p>
                </div>
                <div className="user-class">
                  <h6>Managers</h6>
                  <div className="employee-info">
                    <div className="float-left">
                      <div className="left-icon">
                        <img
                          src="/assets/images/booking/user_2.png"
                          alt="Pic"
                        />
                      </div>
                    </div>
                    <div className="float-left right-text">
                      <h6>Level 1</h6>
                      <h6>Patricia Ford</h6>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="mr-4">
                <div className="profile-card my-card">
                  <div className="details">
                    <div className="float-right">
                      <NavLink
                        to="/my-account/edit-profile"
                        className="btn btn-nothing text-primary float-right ml-auto"
                      >
                        Edit Profile
                          </NavLink>
                    </div>
                    <div className="float-left">
                      <h6 className="value float-left">
                        Personal Information
                          </h6>
                    </div>
                    <div className="clearfix" />
                    <div className="px-4">
                      <div className="row add-dashed-border">
                        <div className="col-md-4">
                          <div className="shape-name lg">
                            <div className="left icon">
                            <i className="material-icons">person</i>
                            </div>
                            <div className="right">
                              <p className="text">First Name</p>
                              <h6 className="heading">Brandon</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="shape-name lg">
                            <div className="left icon">
                            <i className="material-icons">person</i>
                            </div>
                            <div className="right">
                              <p className="text">Middle Name</p>
                              <h6 className="heading">Evans</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="shape-name lg">
                            <div className="left">T</div>
                            <div className="right">
                              <p className="text">Last Name</p>
                              <h6 className="heading">Bran</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row add-dashed-border">
                        <div className="col-md-4">
                          <div className="shape-name lg">
                            <div className="left icon">
                            <i className="material-icons">call</i>
                            </div>
                            <div className="right">
                              <p className="text">Personal Phone</p>
                              <h6 className="heading">+91 8373738339</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="shape-name lg">
                            <div className="left icon">
                            <i className="material-icons">email</i>
                            </div>
                            <div className="right">
                              <p className="text">Email</p>
                              <h6 className="heading">Brandon@me.com</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="shape-name lg">
                            <div className="left">D </div>
                            <div className="right">
                              <p className="text">Date of Birth</p>
                              <h6 className="heading">31/08/1994</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row add-dashed-border">
                        <div className="col-md-4">
                          <div className="shape-name lg">
                            <div className="left">#</div>
                            <div className="right">
                              <p className="text">Employee Code</p>
                              <h6 className="heading">38377</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 ">
                          <div className="shape-name lg">
                            <div className="left">DE</div>
                            <div className="right">
                              <p className="text">Designation</p>
                              <h6 className="heading">Designer</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="shape-name lg">
                            <div className="left">T</div>
                            <div className="right">
                              <p className="text">Title</p>
                              <h6 className="heading">UI/UX Designer</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row add-dashed-border">
                        <div className="col-md-4">
                          <div className="shape-name lg">
                            <div className="left">DP</div>
                            <div className="right">
                              <p className="text">Department</p>
                              <h6 className="heading">IT</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="shape-name lg">
                            <div className="left icon">
                            <i className="material-icons">location_on</i>
                            </div>
                            <div className="right">
                              <p className="text">Work Location</p>
                              <h6 className="heading">Bangalore</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="shape-name lg">
                            <div className="left">T</div>
                            <div className="right">
                              <p className="text">Grades</p>
                              <h6 className="heading">Grade 1 </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div className="shape-name lg">
                            <div className="left icon">
                            <i className="material-icons">location_on</i>
                            </div>
                            <div className="right">
                              <p className="text">Work Address</p>
                              <h6 className="heading">
                                Greenfield Auto Services, 201 Sussex Ave,
                                Newark, NJ 07103, USA
                                  </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="shape-name lg">
                            <div className="left icon">
                            <i className="material-icons">location_on</i>
                            </div>
                            <div className="right">
                              <p className="text">Home Address</p>
                              <h6 className="heading">
                                #333, Sector 4, BDA Layout, Bangalore 560102{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile-card my-card">
                  <div className="details pb-0">
                    <h6 className="value">Vehicle Information</h6>
                  </div>
                  <div className="table-container">
                    <div className="table-responsive">
                      <table className="table table-striped m-0">
                        <tbody>
                          <tr>
                            <td>2 Wheeler</td>
                            <td>KA34r44</td>
                          </tr>
                          <tr>
                            <td>4 Wheeler</td>
                            <td>KA34r44</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="padding">
                    <button className="btn btn-nothing text-primary">
                      Add
                        </button>
                  </div>
                </div>
                <div className="profile-card my-card">
                  <div className="details pb-0">
                    <h6 className="value">Delegate</h6>
                  </div>
                  <div className="delegate bg-white">
                    <div className="names">
                      <div className="search-custom">
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="Search"
                        />
                        <span>
                        <i className="material-icons">search</i>
                        </span>
                      </div>
                      <div className="scroll">
                        <ul>
                          <li className="active">
                            <div className="shape-name xs">
                              <div className="left img" style={profImg} />
                              <div className="right">
                                <h6 className="heading">Jobin John</h6>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="shape-name xs">
                              <div className="left img" style={profImg} />
                              <div className="right">
                                <h6 className="heading">John Martin</h6>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="shape-name xs">
                              <div className="left img" style={profImg} />
                              <div className="right">
                                <h6 className="heading">Brandon Brit</h6>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="shape-name xs">
                              <div className="left img" style={profImg} />
                              <div className="right">
                                <h6 className="heading">Carlos Chairman</h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="detail">
                      <h6 className="sub-heading">Delegated</h6>
                      <div className="scroll">
                        <div className="all-detail">
                          <div className="shape-name lg">
                            <div className="left img" style={profImg} />
                            <div className="right">
                              <h6 className="heading">Vishwa Reddy</h6>
                              <p className="text">Travel Manager</p>
                            </div>
                          </div>
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group custom labelup">
                                  <label>Access Type</label>
                                  <div className="dropdown">
                                    <button
                                      type="button"
                                      className="btn bg-white dropdown-toggle btn-block"
                                      data-toggle="dropdown"
                                    >
                                      User Access
                                        </button>
                                    <div className="dropdown-menu">
                                      <button className="dropdown-item">
                                        Access 1
                                          </button>
                                      <button className="dropdown-item">
                                        Access 2
                                          </button>
                                      <button className="dropdown-item">
                                        Access 3
                                          </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group custom labelup">
                                  <label>Service</label>
                                  <div className="dropdown">
                                    <button
                                      type="button"
                                      className="btn bg-white dropdown-toggle btn-block"
                                      data-toggle="dropdown"
                                    >
                                      Travel, Expense
                                        </button>
                                    <div className="dropdown-menu">
                                      <button className="dropdown-item">
                                        Access 1
                                          </button>
                                      <button className="dropdown-item">
                                        Access 2
                                          </button>
                                      <button className="dropdown-item">
                                        Access 3
                                          </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group custom bg-white">
                                  <input
                                    type="text"
                                    placeholder="&nbsp;"
                                    className="form-control"
                                  />
                                  <label>From</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group custom bg-white">
                                  <input
                                    type="text"
                                    placeholder="&nbsp;"
                                    className="form-control"
                                  />
                                  <label>To</label>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
};
export default ProfileSettingsPage;
