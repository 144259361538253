import React from "react";
import { NavLink } from "react-router-dom";
import { Input } from "../../../../../common/_forms";
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import uuid from "uuid";

const AddpaymentTypesPage = props => {
  let { inputChangeHandler,
    state,
    validator,
    submitHandler,
    selectHandler } = props
  let paymentType = '';
  props.state.paymentTypeList.forEach((list) => {
    if (state.selectedPaymentType === list.value) {
      paymentType = list.payment;
    }
  }
  );
  const getSelect = () => {
    return <div className="dropdown" key={uuid.v4()}>
      <button
        type="button"
        className="btn dropdown-toggle"
        data-toggle="dropdown"
      >
        {paymentType !== "" ? paymentType : "Select Payment Type"}
      </button>
      <div className="dropdown-menu">
        {props.state.paymentTypeList.map((opt, index) => {
          return (
            <button
              type="button"
              onClick={e => selectHandler(opt)}
              className="dropdown-item"
              key={index}
            >
              {opt["payment"]}
            </button>
          );
        })}
      </div>
      <div className="help-block">{validator.message(
        "Payment type",
        state.selectedPaymentType,
        "required"
      )}</div>
    </div>
  }
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">R</div>
          <div className="right">
            <h6 className="heading">{state.isEditable ? "Edit payment Types" : "Add payment Types"}</h6>
            <p className="text">Lorem Ipsum is simply dummy text.</p>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="row">
            <div className="col-md-5">
              <form onSubmit={submitHandler}>
                <div className="form-group custom labelup">
                  <label>
                    Payment Type <span class="text-danger">*</span>
                  </label>
                  {getSelect()}
                </div>
                <div className="form-group custom">
                  <Input
                    label={"Description"}
                    name="description"
                    type="text"
                    controlFunc={e => inputChangeHandler(e)}
                    value={state.description}
                    className="form-control"
                    error={validator.message(
                      "Description",
                      state.description,
                      "required"
                    )}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label={"Payment Type Code"}
                    name="paymentTypeCode"
                    type="text"
                    controlFunc={e => inputChangeHandler(e)}
                    value={state.paymentTypeCode}
                    className="form-control"
                    error={validator.message(
                      "Payment Type Code",
                      state.description,
                      "required"
                    )}
                  />
                </div>
                <div className="form-group custom labelup textarea">
                  <label>Applies to <span class="text-danger">*</span></label>
                  <Select
                    closeMenuOnSelect={true}
                    components={makeAnimated()}
                    name="selectedGroup"
                    value={state.selectedGroup}
                    isMulti
                    options={state.groupList}
                    onChange={(e) => selectHandler(e, "selectedGroup")}
                  />
                  <small style={{ color: "red" }}>{state.groupError ? "Applies To field is required" : ""}</small>
                </div>
                <div className="btn-group-margin">
                  <button
                    type="submit"
                    className="btn"
                  >
                    Save
                </button>
                  <NavLink to={"/administration/expense/payment-types"}
                    className="btn">
                    Cancel
                </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddpaymentTypesPage;
