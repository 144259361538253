import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
// import DragSortableList from "react-drag-sortable";
import Menu from "../Menu";
import StaticMenu from "../staticMenu";
import { Select, Input } from "src/_components/common/_forms";
import Loader from "src/_components/common/Loader";

const ConnectedListsPage = props => {
  const {
    changeModule,
    currentModule,
    fieldItem,
    handleFeild,
    handleItemChange,
    handlePageChange,
    pageItemsList,
    state,
    loading,
    connectedList,
    onSort,
    selectedListItem,
    listService,
    prev,
    handleChange,
    choosedFeilds,
    validFeild,
    handleStatusChange,
    count,
    type,
    reset,
    manageList,
    fieldList,
    fetchMoreData,
    formHeaderList,
    selectedFormHeader,
    validList,
    hasMore,
    levelOneOpt,
    updateList,
    handleHeader,
    page,
    limit,
    setDefaultHandler
  } = props;
  return (
    <div>
      <div className="admin-tabs">
        <Menu
          changeModule={changeModule}
          currentModule={currentModule}
          listService={listService}
        />
      </div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">F</div>
          <div className="right">
            <h6 className="heading">List Creation</h6>
            <p className="text">Create single level and multilevel custom lists.</p>
          </div>
        </div>
      </div>
      <div className="p-padding pb-0">
        <div className="form-holder">
          <div className="row">
            <div className="col-4">
              <div className="form-group custom labelup mb-0">
                <label>Form Type</label>
                {formHeaderList && (
                  <Select
                    keyName={"formHeaderName"}
                    placeholder={selectedFormHeader}
                    controlFunc={handleHeader}
                    options={formHeaderList}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-padding">
        <div className="admin-tabs px-padding">
          <StaticMenu />
        </div>
        <div className="table-container">
          <form>
            <div className="row">
              <div className="col-md-9">
                <div className="btn-group-margin">
                  <button
                    type="button"
                    className="btn"
                    data-toggle="modal"
                    data-target="#AddFormModaltype"
                  // onClick={getListManagement}
                  >
                    New List
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>List Name</th>
                  <th>Levels</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading && count === 0 ? (
                  <tr>
                    <td colSpan="4">
                      <Loader />
                    </td>
                  </tr>
                ) : !loading && count === 0 ? (
                  <tr>
                    <td colSpan="4">No Records Found</td>
                  </tr>
                ) : (
                      <tr />
                    )}
                {connectedList &&
                  connectedList.map((item, key) => {
                    return (
                      <tr key={key} >
                        <td>{item.listName}</td>
                        <td>{item.level}</td>
                        <td>
                          {/* <button
                            data-toggle="modal"
                            data-target="#default"
                            className="btn"
                            onClick={(e) => setDefaultHandler(item, e)}>Set Default</button> */}
                          <button className="btn btn-nothing hover-v"
                            data-toggle="modal"
                            data-target="#AddFormModaltype"
                            type="button"
                           
                          >
                            <i className="material-icons fs-20">edit</i>
                            <div className="hover-name right-top">Edit</div>
                          </button>
                        </td>
                        <td>
                          <p className="">
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={e => handleStatusChange(item)}
                                checked={item.activeStatus}
                                value={item.activeStatus}
                              />
                              <span className="slider round" />
                            </label>
                            {/* <span>
                              {item.activeStatus ? "Active" : "Inactive"}
                            </span> */}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {count !== 0 && (
            <div className="table-footer">
              <div className="row">
                <div className="col-sm-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      {pageItemsList && (
                        <Select
                          placeholder={limit}
                          keyName={"items"}
                          options={pageItemsList}
                          controlFunc={e => handleItemChange(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-9">
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={limit}
                    totalItemsCount={count}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="AddFormModaltype"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddFormModaltypeLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AddFormModaltypeLabel">
                Available Lists
              </h5>
            </div>
            <div className="modal-nav">
              <div className="audit-step">
                <button className={type === 1 ? "s1 active" : "s1"}>
                  <span>1</span>
                  List
                  </button>
                <button className={type === 2 ? "s1 active" : "s1"}>
                  <span>2</span>
                  Fields
                  </button>
                <button className={type === 3 ? "s1 active" : "s1"}>
                  <span>3</span>
                  Configure
                  </button>
              </div>
            </div>
            {!state.connectListLoading ?
              <div>
                <div className="overflow-modal-step">
                  {type === 1 ? (
                    <div className="step1">
                      <div className="table-container mx-0">
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 table-selected">
                            <thead>
                              <tr>
                                <th>List Name</th>
                                <th>Levels</th>
                              </tr>
                            </thead>
                            <tbody>
                              {manageList &&
                                manageList.map((item, key) => {
                                  if (item.childCount > 0) {
                                    return (
                                      <tr
                                        key={key}
                                        onClick={e => levelOneOpt(item)}
                                        className={
                                          selectedListItem === item.id
                                            ? "active-tr"
                                            : ""
                                        }
                                      >
                                        <td>{item.itemName}</td>
                                        <td>{item.childCount}</td>
                                      </tr>
                                    );
                                  }
                                })}
                            </tbody>
                          </table>
                          <div className="has-error text-danger text-center pb-3">
                            {validList.message(
                              "List Management",
                              state.listManagementId,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : type === 2 ? (
                    <div className="step2">
                      <div className="search-list-div">
                        <div className="has-error text-danger text-center pb-3">
                          {validFeild.message(
                            "Field Management",
                            state.selectedFeilds,
                            "required"
                          )}
                        </div>
                        <h6 className="table-head">Field Name</h6>
                        {/* {fieldList.length} */}
                        <InfiniteScroll
                          dataLength={fieldList.length}
                          next={fetchMoreData}
                          hasMore={hasMore}
                          loader={
                            <h4 style={{ textAlign: "center" }}>Loading...</h4>
                          }
                          endMessage={
                            <p className="text-center pt-3">
                              {/* There are no custom fields available, Contact IT team for more information. */}
                            </p>
                          }
                        >
                          {fieldList &&
                            fieldList.map((item, key) => {
                              return (
                                <div
                                  onClick={e => handleFeild(item)}
                                  className={
                                    fieldItem.indexOf(item.id) !== -1
                                      ? "selected list"
                                      : " list"
                                  }
                                  key={key}
                                >
                                  {item.columnName}({item.labelName})
                                </div>
                              );
                            })}
                        </InfiniteScroll>
                      </div>
                    </div>
                  ) : type === 3 ? (
                    <div className="step3 px-padding">
                      <div className="row">
                        <div className="col-md-6">
                          <h6 className="sub-heading mt-4 px-0">Select Level Properties</h6>
                          <div className="custom-dnd">
                            {/* <DragSortableList
                              items={choosedFeilds}
                              onSort={onSort}
                              dropBackTransitionDuration={0.3}
                              type="vertical"
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : type === 4 ? (
                    <div className="step4 p-5">
                      <div className="form-group custom labelup">
                        <label>Designation</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                          />
                          <div className="dropdown-menu">
                            <button type="button" className="dropdown-item">
                              Lorem Ipsum
                          </button>
                            <button type="button" className="dropdown-item">
                              Lorem Ipsum
                          </button>
                            <button type="button" className="dropdown-item">
                              Lorem Ipsum
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : type === 5 ? (
                    <div className="step5">
                      <div className="table-responsive modal-table">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>List Name</th>
                              <th>Levels</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="selected">
                              <td>Lorem Ipsum</td>
                              <td>1</td>
                            </tr>
                            <tr>
                              <td>Lorem Ipsum</td>
                              <td>1</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="dark">
                        <div className="form-group">
                          <label>List Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="List Name"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                              ""
                            )}
                </div>
                <div>
                  <div className="dark">
                    <div className="form-group mb-0">
                      <button
                        onClick={e => props.enableInput()}
                        class={type !== 1 ? "btn btn-nothing float-right" : "d-none"}>Edit</button>
                      <label>List Name <span className="text-danger">*</span></label>
                      <input
                        id="connectedL"
                        onChange={handleChange}
                        className="form-control"
                        name="listName"
                        disabled={type !== 1}
                        type="text"
                        placeholder="&nbsp;"
                      />
                      <span className="text-danger has-error"> {validList.message(
                        "List Name",
                        state.listName,
                        "required"
                      )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="modal-last pm-30">
                  {manageList && (
                    <div className="btn-group-margin">
                      {type !== 1 && (
                        <button
                          type="button"
                          onClick={prev}
                          className="btn float-left"
                        >
                          Prev
                        </button>
                      )}
                      {type < 3 && (
                        <button
                          type="button"
                          onClick={updateList}
                          className="btn"
                        >
                          Next
                        </button>
                      )}
                      {type === 3 && (
                        <button
                          type="button"
                          onClick={updateList}
                          className="btn"
                        >
                          Done
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={reset}
                        className="btn close"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>

                  )}
                </div>
              </div>
              : <Loader />}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="default"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="defaultLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="defaultLabel">
                Set Defaults
              </h5>
              {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            <div className="modal-middlle pm-30">
              <div className="form-group custom">
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    None of the steps
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">
                      Lorem Ipsum
                    </button>
                    <button type="button" className="dropdown-item">
                      Lorem Ipsum
                    </button>
                    <button type="button" className="dropdown-item">
                      Lorem Ipsum
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group custom">
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    None of the steps
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">
                      Lorem Ipsum
                    </button>
                    <button type="button" className="dropdown-item">
                      Lorem Ipsum
                    </button>
                    <button type="button" className="dropdown-item">
                      Lorem Ipsum
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group custom">
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    None of the steps
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">
                      Lorem Ipsum
                    </button>
                    <button type="button" className="dropdown-item">
                      Lorem Ipsum
                    </button>
                    <button type="button" className="dropdown-item">
                      Lorem Ipsum
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-last pt-2">
                <div className="btn-group-margin">
                  <button
                    type="button"
                    className="btn"
                  >Save</button>
                  <button
                    type="button"
                    className="btn close"
                    data-dismiss="modal"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConnectedListsPage;
