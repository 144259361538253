import React, { Component } from "react";
import { companyAdmin } from "../../../../../../_services/companyAdmin";
import { masterService, expensePolicies } from "../../../../../../_services";

import { toast } from "react-toastify";
import FormsFieldsPage from "../forms-fields/forms-fields-html";
import { alertConstants } from "../../../../../../_constants";
import $ from "jquery";

class FormsFields extends Component {
  state = {
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,

    closeModal: false,
    editModal: false,
    // Add form header
    selectedItemId: [],
    formName: "",
    formHeaderId: null,
    formId: "",
    mappedFields: [],
    loadingInit: true,
    serviceModuleId: localStorage.getItem("serviceModuleId"),
    companyId: localStorage["440a28"],
    formAccess: "Global",
    selectedFormHeader: "",
    selectedForm: [],
    selectedFormField: [],
    selectedField: [],
    formHeaderName: [],
    forms: [],
    formFields: [],
    customFields: [],
    selectedItem: null,
    formType: "",
    isEnabletrue: true,
    pathCheck: sessionStorage.setItem("redirectModify", "formFields"),
    sequenceData: {},
    sequenceOrder: [],
    allGroupsList: [],
    isGroupRequired: false,

    formPreviewName: ""
  };
  count = 0;
  loading = false;
  // * get all groups for populating access
  getAllGroups = async () => {
    let res = await expensePolicies.getGroups(this.state.companyId);
    if (res !== null && res !== undefined) {
      this.setState({
        allGroupsList: res.result
      });
    }
  }



  setSession = () => {
    if (typeof Storage !== "undefined") {
      sessionStorage.setItem("formsFieldsHeaderId", this.state.formHeaderId);
    }
  };

  // ------------------event handler-------------------------
  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  // ---------------- creating forms --------------------------

  handleSubmit = async event => {
    event.preventDefault();
    let data = {
      formName: this.state.formName,
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      formAccess: this.state.formAccess
    };
    let res = await companyAdmin
      .createForms(data)
      .catch(e => (this.loading = false));
    if (res) {
      this.loading = false;
      this.getForms();
      toast.success(alertConstants.SUCCESS);
    }
  };

  // ---------------- get forms ------------------------------
  getForms = async (id = null) => {
    this.setState({ loadingInit: true })
    let res = await companyAdmin
      .listFormsPLSF(
        this.state.activePage,
        this.state.itemPerPage,
        this.state.serviceModuleId,
        this.state.formHeaderId,
        this.state.companyId
      )
    if (res) {
      this.setState({
        forms: res.result,
        totalItemsCount: res.count
      });
    }
    this.setState({ loadingInit: false })
  };

  // ----------------------------------------------------------

  // -----------------get form fields-----------------------------

  getFormFields = () => {
    let data = {
      page: this.state.activePage,
      limit: this.state.itemPerPage,
      formId: this.state.formId,
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      companyId: this.state.companyId,
      sort: "asc"
    };
    companyAdmin.getFormFields(data).then(res => {
      this.setState({
        formFields: res.result
      });
    });
  };

  // ---------------custom fields for mapping--------------------
  onlyCustomFields = () => {
    let data = {
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      // customField: true,
      // isAdmin: true,
      // isFormField: false,
      // * form id to be sent as per new API spec(venkatesh)
      toInsertFormId: this.state.formId,
      page: 1,
      limit: 50,
      sort: "asc",
      companyId: this.state.companyId
    };
    companyAdmin
      .customFieldsAre(data)
      .then(res => {
        this.setState({
          customFields: res.result
        });
      })
  };

  // -----------------------------------------------------

  getMappedFields = async (form) => {
    this.setState({
      formId: form,
      sequenceOrder: [],
      isEnabletrue: true
    });
    let data = {
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      companyId: this.state.companyId,
      formId: form,
      page: 1,
      limit: 50,
      sort: "asc"
    };
    let resp = await companyAdmin.getMappedFields(data);
    if (resp) {
      this.setState({
        mappedFields: resp.result
      });
    }
  };
  // ---------------Map fields to form--------------------
  mapFieldsToForm = async () => {
    let data = {
      formId: this.state.formId,
      fieldIds: this.state.selectedField,
      companyId: this.state.companyId
    };
    await companyAdmin.mapFieldsToForm(data);
    $(".close").click();
    toast.success(alertConstants.SUCCESS);
    // this.getMappedFields();

    // * Modified code by Om for fixing mapped data after assigning.
    let data1 = {
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      companyId: this.state.companyId,
      formId: this.state.formId,
      page: 1,
      limit: 50,
      sort: "asc"
    };
    companyAdmin
      .getMappedFields(data1)
      .then(res => {
        this.setState({
          mappedFields: res.result
        });
      })
  };
  // * End of bug fix by Om

  // -----------------------------------------------------

  // -----------------------------------------------------

  removeFields = async () => {
    let id = this.state.selectedFormField[0].id;
    let companyId = {
      companyId: this.state.companyId
    };
    await companyAdmin.deleteFields(id, companyId);
    toast.success(alertConstants.SUCCESS);
    this.getMappedFields(this.state.formId);
  };

  // -----------------------------------------------------

  // -------------------change status ---------------------------

  handleStatusChange = form => {
    let newStatus = !form.activeStatus;
    let status = {
      activeStatus: JSON.stringify(newStatus),
      companyId: this.state.companyId
    };
    companyAdmin.changeFormStatus(form.id, status).then(
      res => {
        newStatus
          ? toast.success(alertConstants.UPDATED_SUCCESSFULLY)
          : toast.error(alertConstants.DEACTIVATED_SUCCESSFULLY);
        let newItems = this.state.forms.map(forms => {
          if (forms.id === form.id) {
            forms.activeStatus = newStatus;
          }
          return forms;
        });
        this.setState({
          forms: newItems
        });
      },
    );
  };

  handleMappedMandatoryChange = mappedField => {
    let newStatus = !mappedField.isMandatory;
    let status = {
      companyId: this.state.companyId,
      isMandatory: newStatus
    };
    companyAdmin.modifyFields(mappedField.id, status).then(
      res => {
        newStatus
          ? toast.success(alertConstants.ACTIVATED_SUCESSFULLY)
          : toast.error(alertConstants.DEACTIVATED_SUCCESSFULLY);
        let newItems = this.state.mappedFields.map(mappedFields => {
          if (mappedFields.id === mappedField.id) {
            mappedFields.isMandatory = newStatus;
          }
          return mappedFields;
        });
        this.setState({
          mappedFields: newItems
        });
      },
    );
  };

  handleMappedStatusChange = mappedField => {
    let newStatus = !mappedField.activeStatus;
    let status = {
      activeStatus: newStatus,
      companyId: this.state.companyId
    };
    companyAdmin.changeFieldStatus(mappedField.id, status).then(
      res => {
        newStatus
          ? toast.success(alertConstants.UPDATED_SUCCESSFULLY)
          : toast.error(alertConstants.DEACTIVATED_SUCCESSFULLY);
        let newItems = this.state.mappedFields.map(mappedFields => {
          if (mappedFields.id === mappedField.id) {
            mappedFields.activeStatus = newStatus;
          }
          return mappedFields;
        });
        this.setState({
          mappedFields: newItems
        });
      },
    );
  };

  //-------------------------------------------------------------

  // ---------------- get forms headers -------------------
  getFormHeader = () => {
    companyAdmin.getFormHeader(this.state.serviceModuleId).then(res => {
      if (this.state.serviceModuleId === localStorage.serviceModule) {
        var name = ''
        if (sessionStorage['F12V2'] !== undefined) {
          res.result.forEach(item => {
            if (item.id === sessionStorage['F12V2']) {
              name = item.formHeaderName
            }
          })
        }
        this.setState(
          {
            ...this.state,
            formHeaderName: res.result && res.result,
            selectedFormHeader: (name) ? name : res.result && res.result[0].formHeaderName,
            isGroupRequired: res.result && res.result[0].isGroupRequired,
            formHeaderId: (sessionStorage['F12V2']) ? sessionStorage['F12V2'] : res.result && res.result[0].id
          },
          () => {
            if (res) {
              this.getForms();
              this.setSession();
            }
          }
        );
      } else {
        this.setState(
          {
            formHeaderName: res.result && res.result,
            selectedFormHeader: res.result && res.result[0].formHeaderName,
            formHeaderId: res.result && res.result[0].id,
            isGroupRequired: res.result && res.result[0].isGroupRequired
          },
          () => {
            if (res) {
              this.getForms();
              this.setSession();
            }
          }
        );
      }
    });
  };

  // --------------Pagination--------------------------------

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, () => {
      this.getForms();
    });
  };
  handleItemChange = limit => {
    this.setState({ itemPerPage: limit }, () => {
      this.getForms();
    });
  };

  // ---------------- Create form headers -------------------

  handleHeaderButtonClick = (id, name) => {
    if (this.state.formHeaderId !== id) {
      $('#ejectId').click()
      sessionStorage.setItem('F12V2', id)
      this.setState(
        {
          selectedFormHeader: name,
          isEnabletrue: false,
          formHeaderId: id
        },
        () => {
          this.getForms();
        }
      );
    }
    this.setSession();
  };

  // -------------------selectedForm select--------------------

  toggleFormSelect = field => {
    let id = field.id;
    let itemSelected = [...this.state.selectedForm];
    let indexSelected = itemSelected.indexOf(field);
    if (indexSelected === -1) {
      itemSelected = [field];
    } else {
      itemSelected = [];
    }
    this.setState({ selectedForm: itemSelected });
  };

  // selected form field
  toggleFormFieldSelect = field => {
    let itemSelected = [...this.state.selectedFormField];
    let indexSelected = itemSelected.indexOf(field);
    if (indexSelected === -1) {
      itemSelected = [field];
    } else {
      itemSelected = [];
    }
    this.setState({ selectedFormField: itemSelected });
  };

  // selected  field
  toggleFieldSelect = field => {
    let id = field.id;
    let itemSelected = [...this.state.selectedField];
    let indexSelected = itemSelected.indexOf(field.id);
    if (indexSelected === -1) {
      itemSelected.push(id);
    } else {
      itemSelected.splice(indexSelected, 1);
    }
    this.setState({ selectedField: itemSelected });
  };

  // -----------------------------------------------------------------

  componentDidMount() {
    this.getService();
    this.getAllGroups();
  }
  currentModule = "";
  getService = async () => {
    var resp = await masterService.getServiceModuleListFl();
    if (resp) {
      this.listService = resp.result;
      if (localStorage.serviceModule != undefined) {
        this.currentModule = localStorage.serviceModule;
        this.setState({
          serviceModuleId: localStorage.serviceModule
        }, () => {
          // * code added for fixing async issue error
          this.getFormHeader();
        });
      } else {
        this.currentModule = resp.result && resp.result[0].id;
        this.setState({
          serviceModuleId: resp.result && resp.result[0].id
        }, () => {
          // * code added to fix async issue error
          this.getFormHeader();
        });
      }
    }
  };

  closeToggleHandler = () => {
    this.setState({
      ...this.state,
      closeModal: !this.state.closeModal
    });
  };

  editToggle = () => {
    this.setState({
      ...this.state,
      closeModal: !this.state.editModal
    });
  };
  resetSeletedField = () => {
    this.setState({
      selectedField: []
    });
  };
  changeModule = type => {
    sessionStorage.removeItem('F12V2');
    $('#ejectId').click()
    this.setState({
      serviceModuleId: type,
      isEnabletrue: false
    },
      () => {
        this.currentModule = type;
        localStorage.setItem("serviceModule", type);
        this.getFormHeader();
      }
    );
  };

  // * Code for handling sequence change
  sequenceChangeHandler = (item, e) => {

    let sequenceNumber = +e.target.value;

    let mappedFields = [...this.state.mappedFields];
    let matchedIndex = mappedFields.findIndex(mappedField => item.id === mappedField.id);
    let matchedField = { ...item, sequence: sequenceNumber };

    mappedFields.splice(matchedIndex, 1, matchedField);
    this.setState({ mappedFields });


    let sequenceData = {};
    let sequenceOrder = [...this.state.sequenceOrder];

    sequenceData.formId = this.state.formId;
    let preExistingFormField = sequenceOrder.findIndex(sequence => sequence.id == item.id);

    let sequenceToBeAdded = {
      id: item.id,
      sequence: sequenceNumber
    };

    if (preExistingFormField != -1) {
      sequenceOrder.splice(preExistingFormField, 1, sequenceToBeAdded);
    } else {
      sequenceOrder.push(sequenceToBeAdded)
    }

    this.setState({ sequenceOrder }, () => console.log("----", this.state.sequenceOrder))

  }

  checkDuplicatesInArray = (allSequenceNumbers) => {
    var counts = [];
    for (var i = 0; i <= allSequenceNumbers.length; i++) {
      if (counts[allSequenceNumbers[i]] === undefined) {
        counts[allSequenceNumbers[i]] = 1;
      } else {
        return true;
      }
    }
    return false;
  }

  submitSequenceHandler = async () => {
    let allSequenceNumbers = this.state.mappedFields.map(mappedField => mappedField.sequence || 0);
    let hasDuplicates = this.checkDuplicatesInArray(allSequenceNumbers);
    if (hasDuplicates) {
      toast.warn(alertConstants.ARRAY_HAS_DUPLICATES);
    } else {
      let payload = {
        formId: this.state.formId,
        companyId: this.state.companyId,
        sequenceOrder: this.state.sequenceOrder
      };
      let res = await companyAdmin.updateSequenceOrder(payload);
      if (res) {
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
      }
    }
  }

  /* Setting Name for which form is preview*/
  setFormPreviewName = (name) => {
    this.setState({ formPreviewName: name });
  }


  // * End of code for handling sequence change
  render() {
    return (
      <div>
        <FormsFieldsPage
          // state
          fullFormData={this.state}
          // event handlers
          handlePageChange={this.handlePageChange}
          handleStatusChange={this.handleStatusChange}
          handleMappedStatusChange={this.handleMappedStatusChange}
          handleMappedMandatoryChange={this.handleMappedMandatoryChange}
          closeToggleHandler={this.closeToggleHandler}
          handleItemChange={this.handleItemChange}
          pageRangeDisplayed={3}
          editToggleHandler={this.editToggle}
          formNameHandler={this.formNameHandler}
          handleSubmit={this.handleSubmit}
          setFormType={this.setFormType}
          handleHeaderButtonClick={this.handleHeaderButtonClick}
          toggleFormSelect={this.toggleFormSelect}
          toggleFormFieldSelect={this.toggleFormFieldSelect}
          getMappedFields={this.getMappedFields}
          getFormFields={this.getFormFields}
          toggleFieldSelect={this.toggleFieldSelect}
          removeFields={this.removeFields}
          mapFieldsToForm={this.mapFieldsToForm}
          resetSeletedField={this.resetSeletedField}
          onlyCustomFields={this.onlyCustomFields}
          changeModule={this.changeModule}
          listService={this.listService}
          currentModule={this.currentModule}
          loading={this.loading}
          loadingInit={this.state.loadingInit}
          sequenceChangeHandler={this.sequenceChangeHandler}
          submitSequenceHandler={this.submitSequenceHandler}

          setFormPreviewName={this.setFormPreviewName}
        />
      </div>
    );
  }
}
export default FormsFields;
