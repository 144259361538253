import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import { Input, Select } from "../../../../../common/_forms";
import Loader from "src/_components/common/Loader";

const AccountStructurePage = props => {
  const { error, state, handleChange, addSubmit, listOfAccountStruture, handleSearch, storeId, customId, getData, removeItem, updateSubmit, cancelAll, apiHierarchyData, createNewHierarchy, selectHandler, hierarchyLedgerArray, removeHierarchy, fieldData, selectFieldHandler, moveStep, saveHierarchy, modifyHierarchy, selectHierarchy, storeHierarchyId,hierarchyId } = props;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">A</div>
            <div className="right">
              <h6 className="heading">Account Structure</h6>
              <p className="text">Use Account Structure to manage ledger information and account code hierarchy.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-padding">
        <div className="table-container">
          <form>
            <div className="row">
              <div className="col-md-9">
                <div className="btn-group-margin">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#AddTemplateModal"
                    className="btn"
                  >
                    New
                    </button>
                  {(customId) ?
                    <button type="button" onClick={getData} data-toggle="modal" data-target="#editTemplateModal" className="btn">Edit</button> :
                    <button type="button" className="btn" disabled>Edit</button>
                  }
                  {(customId) ?
                    <button type="button" onClick={removeItem} className="btn">Remove</button> :
                    <button type="button" className="btn" disabled>Remove</button>
                  }
                  {(customId) ?
                    <button type="button"
                      onClick={modifyHierarchy}
                      data-toggle="modal"
                      data-target="#AddTemplateModal2" className="btn">
                      Modify Hierarchy
                    </button>
                    :
                    <button type="button" className="btn" disabled>
                      Modify Hierarchy
                    </button>}
                </div>
              </div>
              <div className="col-md-3">
                <div className="search-custom">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                  <span>
                    <i className="material-icons">search</i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            {
              props.state.isLoading ? (<Loader />) : (<table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>Ledger name</th>
                    <th>Ledger code</th>
                    <th>Expense account code hierarchy</th>
                  </tr>
                </thead>
                <tbody>
                  {listOfAccountStruture && (listOfAccountStruture.length === 0) &&
                    <tr>
                      <td colSpan="3" className="text-center">No Records Found</td>
                    </tr>
                  }
                  {listOfAccountStruture &&
                    listOfAccountStruture.map((item, key) => {
                      return (
                        <tr key={key} onClick={e => storeId(item.id)} className={(customId === item.id) ? "active-tr" : ""}>
                          <td>{item.ledgerName}</td>
                          <td>{item.ledgerCode}</td>
                          <td>
                            -
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
              )
            }

          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="editTemplateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="AddTemplateModalLabel">
                Edit Ledger
              </h5>
            </div>
            <div className="modal-middle pm-30">
              <form onSubmit={updateSubmit} autoComplete="off">
                <div className="form-group custom">
                  <Input
                    label="Ledger Name"
                    type="text"
                    name="ledgerName"
                    value={state.ledgerName}
                    error={error.message('ledgerName', state.ledgerName, 'required')}
                    controlFunc={e => handleChange(e)}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label="Ledger Code"
                    type="text"
                    name="ledgerCode"
                    value={state.ledgerCode}
                    error={error.message('ledgerCode', state.ledgerCode, 'required')}
                    controlFunc={e => handleChange(e)}
                  />
                </div>
                <div className="modal-last btn-group-margin">
                  <button
                    type="submit"
                    className="btn"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    onClick={cancelAll}
                    className="btn close"
                    data-dismiss="modal"
                  >
                    Cancel
              </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AddTemplateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="AddTemplateModalLabel">
                New Ledger
              </h5>
            </div>
            <div className="modal-middle pm-30">
              <form className="px-3" onSubmit={addSubmit} autoComplete="off">
                <div className="form-group custom">
                  <Input
                    label="Ledger Name"
                    type="text"
                    name="ledgerName"
                    value={state.ledgerName}
                    error={error.message('ledgerName', state.ledgerName, 'required')}
                    controlFunc={e => handleChange(e)}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label="Ledger Code"
                    type="text"
                    name="ledgerCode"
                    value={state.ledgerCode}
                    error={error.message('ledgerCode', state.ledgerCode, 'required')}
                    controlFunc={e => handleChange(e)}
                  />
                </div>
                <div className="modal-last btn-group-margin">
                  <button
                    type="submit"
                    className="btn"
                  >
                    Save
              </button>
                  <button
                    type="button"
                    onClick={cancelAll}
                    className="btn close"
                    data-dismiss="modal"
                  >
                    Cancel
              </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddTemplateModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="AddTemplateModalLabel">
                Account code hierarchy for Ledger - Default
              </h5>
            </div>
            <div className="modal-middle pb-0 border">
              <div className="table-container mx-0">
                <form>
                  <div className="btn-group-margin mb-3">
                    <button type="button" onClick={createNewHierarchy} className="btn">New</button>
                    {
                      (state.hierarchyId !== undefined && state.hierarchyId !== '') ? <button type="button" className="btn" onClick={e => removeHierarchy(state.hierarchyId)}> Remove</button> :
                        <button type="button" className="btn" disabled>Remove</button>
                    }
                  </div>
                </form>
                <div className="table-responsive">
                  <table className="table table-striped table-selected">
                    <thead>
                      <tr>
                        <th width="10%"></th>
                        <th width="30%">Level</th>
                        <th width="30%">Segment Name</th>
                        <th width="30%">Order</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(hierarchyLedgerArray.length) ? hierarchyLedgerArray.map((item, idx) => {
                        return (
                          <tr key={idx} >
                            <td><label className="checkbox-container sm">
                              <input type="checkbox" onClick={e => { selectHierarchy(item.id); storeHierarchyId(item.id) }} defaultChecked={(state.hierarchyId===item.id)?true:false} />
                              <span className="checkmark" />
                            </label></td>
                            <td>
                              <div className="form-group custom mb-0">
                                <Select
                                  className="bg-white"
                                  placeholder={item ?.formHeader ?.formHeaderName || 'Select a dropdown'}
                                  options={apiHierarchyData ? apiHierarchyData : []}
                                  keyName={'formHeaderName'}
                                  controlFunc={e => selectHandler(e, item.id)}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="form-group custom labelup mb-0">
                                <Select
                                  className="bg-white"
                                  placeholder={item ?.formFieldName ?.columnName || "Select a dropdown" }
                                  options={fieldData[item ?.formHeader ?.id] || []}
                                  keyName={'columnName'}
                                  controlFunc={e => selectFieldHandler(e, item.id)}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="btn-group-margin">
                                <button type="button" disabled={item.order === 1} className={(hierarchyLedgerArray.length > 1 && hierarchyLedgerArray.length !== 1) ? "btn btn-arrow primary" : "d-none"} onClick={e => moveStep("up", item)} ><i className="material-icons">arrow_drop_up</i></button>

                                <button disabled={item.order === hierarchyLedgerArray.length} onClick={e => moveStep("down", item)} type="button" className="btn btn-arrow primary"><i className="material-icons">arrow_drop_down</i></button>
                              </div>
                            </td>
                          </tr>
                        )
                      }) :
                        <tr>
                          <td colSpan="4" className="text-center">No Hierarchy found! Click New to Add </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-last btn-group-margin pm-30">
              <button
                type="button"
                className="btn"
                onClick={saveHierarchy}
              >
                Save
              </button>
              <button
                type="button"
                className="btn close"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountStructurePage;
