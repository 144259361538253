import { http } from '../_helpers';
import { config } from '../env';


// get project details
const getProject = data => http.post(config.apiUrlCompany+'projects/listAll',data)
.then(data => data)

// create a new  project
const createProject = data => http.post(config.apiUrlCompany+'projects',data)
.then(data => data)

// get-order-types
const getOrderTypes = () => http.post(config.apiUrlCompany+'list-order-type')

// get countries 
const getCountries = () => http.get(config.apiUrlSuperAdmin+'countries')

// get Billing details
function getBillings(){
    return http.get(config.apiUrlCompany+'billingcycles')
        .then(data=>data)
}

// // edit project details
// function editProject(){
//     return http.patch(config.apiUrlCompany+)
// }


// exporting the functions
export const projectServices =  {
    getProject,
    createProject,
    getOrderTypes,
    getCountries,
    getBillings

}