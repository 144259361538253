import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { myAccountTravelSettingsServise } from "../../../_services";
import { myAccountInsuranceService } from "src/_services/myAccountInsuranceService";
import { calculateAge } from "src/utils/util";
import Moment from "react-moment";
import Loader from "src/_components/common/Loader";
class InsuranceSettingsPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      userDetails: {},
      userInfo: [],
      firstName: "",
      middleName: "",
      lastName: "",
      address: "",
      dateOfBirth: "",
      gstNumber: "",
      mobileNumber: "",
      nationality: "",
      nomineeFirstName: "",
      nomineeLastName: "",
      age: "",
      passportIssueDate: "",
      passportExpiryDate: "",
      relationWithNominee: "",
      loading: true
    };
  }
  redirect(e) {
    $(".close").click();
    $(".itemisationExpenseModal").close();
  }

  componentDidMount() {
    this.getProfileDetails();
    this.getInsuranceDetails();
  }

  getInsuranceDetails = async () => {
    try {
      let res = await myAccountInsuranceService.getInsuranceDetails();
      if (!res) {
        this.setState({ loading: false });
      } else if (
        typeof res != undefined &&
        (Object.entries(res.result).length !== 0 &&
          res.result.constructor === Object)
      ) {
        this.insuranceDetails = res.result;
        this.setState({
          loading: false,
          firstName: this.insuranceDetails.firstName,
          lastName: this.insuranceDetails.lastName,
          address: this.insuranceDetails.address,
          dateOfBirth: new Date(this.insuranceDetails.dateOfBirth),
          gstNumber: this.insuranceDetails.gstNumber,
          gender: this.insuranceDetails.gender,
          mobileNumber: this.insuranceDetails.mobileNumber,
          nationality: this.insuranceDetails.nationality,
          nomineeFirstName: this.insuranceDetails.nomineeFirstName,
          nomineeLastName: this.insuranceDetails.nomineeLastName,
          age: calculateAge(new Date(this.insuranceDetails.dateOfBirth)),
          passportIssueDate: new Date(this.insuranceDetails.passportIssueDate),
          passportExpiryDate: new Date(
            this.insuranceDetails.passportExpiryDate
          ),
          relationWithNominee: this.insuranceDetails.relationWithNominee
        });
        let selectedGender = this.state.genderOptions.find(
          item => item.label == this.insuranceDetails.gender
        );
        if (selectedGender != null) {
          this.setState({ selectedGender });
        }
        let selectedNominee = this.state.nomineeOptions.find(
          item => item.label == this.insuranceDetails.relationWithNominee
        );
        if (selectedNominee != null) {
          this.setState({
            selectedNominee
          });
        }

        // console.log('the type of date of birth is ', typeof this.insuranceDetails.dateOfBirth);
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getProfileDetails = async () => {
    var res = await myAccountTravelSettingsServise.getProfileDetails();
    if (res) {
      this.setState({
        userDetails: res.result
      });
      let userInformation = this.state.userDetails.fieldData.map(item => {
        if (item.columnName == "DATE_OF_JOINING") {
          return {
            columnName: item.columnName,
            fieldValue: item.fieldValue,
            labelName: item.labelName
          };
        } else if (item.columnName == "MIDDLE_NAME") {
          return {
            columnName: item.columnName,
            fieldValue: item.fieldValue,
            labelName: item.fieldName
          }
        }
        else if (item.columnName == "GROUP_NAME") {
          return {
            columnName: item.columnName,
            fieldValue: item.connectedFieldValue,
            labelName: item.labelName
          };
        } else if (
          item.columnName == "REIMBURSEMENT_CURRENCY" ||
          item.columnName == "CTRY_CODE" ||
          item.columnName == "LEDGER_KEY"
        ) {
          return {
            columnName: item.columnName,
            fieldValue: item.differentListValue,
            labelName: item.labelName
          };
        } else if (
          item.columnName == "COUNTRY" ||
          item.columnName == "STATE" ||
          item.columnName == "CITY"
        ) {
          return {
            columnName: item.columnName,
            fieldValue: item.connectedFieldValue,
            labelName: item.labelName
          };
        } else {
          return {
            columnName: item.columnName,
            fieldValue: item.fieldValue,
            labelName: item.labelName
          };
        }
      });
      this.setState({
        userInfo: userInformation
      });
    }
  };

  getFieldValue = requiredColumn => {
    if (this.state.userInfo.length) {
      let firstNameObject = this.state.userInfo.find(
        info => info.columnName == requiredColumn
      );
      if (firstNameObject != null) {
        return firstNameObject.fieldValue;
      }
    } else {
      return " ";
    }
  };

  render() {
    return (
      <div>
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">T</div>
            <div className="right">
              <h6 className="heading">Insurance Settings</h6>
              <p className="text">Change the settings for Insurance here.</p>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        {this.state.loading ? (
          <Loader />
        ) : (
            <div className="p-padding">
              <div className="form-holder">
                <div className="float-right">
                  <NavLink
                    to="/my-account/insurance-settings-save"
                    className="btn"
                  >
                    Edit
                </NavLink>
                </div>
                <h6 className="sub-heading pt-2 mb-4">Insurance</h6>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">F</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">First Name</p>
                        <h6 className="heading fw-sbold">
                          {/* {this.state.userInfo.length && this.getFieldValue('EMPLOYEE_FIRST_NAME')} */}
                          {this.state.firstName || "-"}
                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  {this.state.middleName ?
                    <div className="col-lg-4 col-md-6">
                      <div className="shape-name mb-4">
                        {/* <div className="left l-grey">L</div> */}
                        <div className="right ml-0">
                          <p className="text op-less">Middle Name</p>
                          <h6 className="heading fw-sbold">
                            {this.state.middleName || "-"}
                          </h6>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="mb-dashed" />
                    </div>
                    : ''
                  }
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">L</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">Last Name</p>
                        <h6 className="heading fw-sbold">
                          {this.state.lastName || "-"}
                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">G</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">Gender</p>
                        <h6 className="heading fw-sbold">
                          {this.state.gender || "-"}
                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">D</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">Date of Birth</p>
                        <h6 className="heading fw-sbold">
                          {this.state.dateOfBirth ?
                            <Moment format="DD MMM YYYY">
                              {this.state.dateOfBirth}
                            </Moment>
                            :
                            "-"}

                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">P</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">Passport Issue Date</p>
                        <h6 className="heading fw-sbold">
                          {(this.state.passportIssueDate) ?
                            <Moment format="DD MMM YYYY">
                              {(this.state.passportIssueDate)}
                            </Moment>
                            : '-'
                          }
                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">P</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">Passport Expire Date</p>
                        <h6 className="heading fw-sbold">
                          {this.state.passportExpiryDate ?
                            <Moment format="DD MMM YYYY">
                              {this.state.passportExpiryDate || "-"}
                            </Moment>
                            : '-'
                          }

                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">N</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">Nationality</p>
                        <h6 className="heading fw-sbold">
                          {this.state.nationality || "-"}
                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">N</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">Nominee First Name</p>
                        <h6 className="heading fw-sbold">
                          {this.state.nomineeFirstName || '-'}
                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">N</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">Nominee last Name</p>
                        <h6 className="heading fw-sbold">
                          {this.state.lastName || "-"}
                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">R</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">Relationship with Nominee</p>
                        <h6 className="heading fw-sbold">
                          {this.state.relationWithNominee || "-"}
                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">A</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">Address</p>
                        <h6 className="heading fw-sbold">
                          {this.state.address || "-"}
                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">M</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">Mobile Number</p>
                        <h6 className="heading fw-sbold">
                          {this.state.mobileNumber || '-'}
                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                    <div className="mb-dashed" />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="shape-name mb-4">
                      {/* <div className="left l-grey">G</div> */}
                      <div className="right ml-0">
                        <p className="text op-less">GST Number of the Company</p>
                        <h6 className="heading fw-sbold">
                          {this.state.gstNumber || '-'}
                        </h6>
                      </div>
                      <div className="clearfix" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
export default InsuranceSettingsPage;
