import React from "react";

const receiptDeclarationAttach = props => {
  return (
    <>
      <div
        className="modal fade"
        id="createReceiptModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="createReceiptLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h6 className="modal-subtitle">New</h6>
              <h5 className="modal-title" id="createReceiptLabel">
                Create Receipt Declaration
            </h5>
            </div>
            <div className="modal-middle receipt_declaration pm-30">

              <p className="mb-5">
                {props.policyDetail && props.policyDetail.afidivateStatement}
              </p>

              <div className="modal-last btn-group-margin">
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  Cancel
              </button>
                {!props.isFromWorkFlow && props.policyDetail.afidivateStatement &&
                  <button
                    data-dismiss="modal"
                    onClick={props.onAttachDetachClickHandler}
                    type="button" className="btn">
                    DETACH
              </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="attachAffidavit"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="createReceiptLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h6 className="modal-subtitle">New</h6>
              <h5 className="modal-title" id="createReceiptLabel">
                Create Receipt Declaration
            </h5>
            </div>
            <div className="modal-middle receipt_declaration pm-30">

              <p className="mb-5">
                {props.policyDetail && props.policyDetail.afidivateStatement}
              </p>

              <div className="modal-last btn-group-margin">
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  Cancel
              </button>
                <button type="button"
                  data-dismiss="modal"
                  onClick={props.onAttachDetachClickHandler}
                  className="btn">
                  ACCEPT AND ATTACH
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default receiptDeclarationAttach;
