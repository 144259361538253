import React, { Component } from "react";
import TravelPage from "./travel-html";
import { companyEmailTravelServices } from "../../../../../../_services";
import { companyEmailServices } from "../../../../../../_services";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

class Travel extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.companyId = localStorage["440a28"];
    this.serviceModuleId = "";
    this.state ={
      selectedTemplateByIndex : '',
      selectedTemplate : {}
    }
  }

  search = "";
  dataPerPage = 10;
  selectedPage = 1;
  dataList = [];
  totalCount = "";
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
  serviceModuleId = "";
  serviceModule = []
  

  componentDidMount() {
    this.getServiceId();
   
  }
 
  getServiceId = async () => {
    var res = await companyEmailServices.getServiceModuleId()
    if (res) {
      this.serviceModule = res.result
      this.serviceModuleId = this.props.match.params.id&&this.props.match.params.id!=="list"?this.props.match.params.id:res.result.find(o=>o.serviceModuleName==="Travel").id
      this.forceUpdate()
      this.getTemplateLists();
    }
  }

  getTemplateLists = async () => {
    let data = {
      companyId: this.companyId,
      serviceModuleId: this.serviceModuleId,
      limit: this.dataPerPage,
      page: this.selectedPage,
      search: this.search
    };
      var res = await companyEmailTravelServices.getEmailTemplate(data);
    if (res) {
      this.dataList = res.result;
      this.totalCount = res.count;
      this.forceUpdate();
    } else {
      this.dataList = [];
      this.totalCount = "";
      this.forceUpdate();
    }
  };

  onSelecthandler = (emailTemplateId , emailTemplete) => {
    let selectedTemplateByIndex = this.state.selectedTemplateByIndex;
    if (selectedTemplateByIndex == emailTemplateId)
      this.setState({ selectedTemplateByIndex: "", selectedTemplate: emailTemplete });
    else this.setState({ selectedTemplateByIndex: emailTemplateId, selectedTemplate: emailTemplete });
  }

  inputChangeHandler = e => {
    this.search = e.target.value;
    this.getTemplateLists();
  };

  handleItemChange = limit => {
    this.selectedPage = 1;
    this.dataPerPage = limit["items"];
    this.getTemplateLists();
  };

  pageClickHandler = page => {
    this.selectedPage = page;
    this.getTemplateLists();
  };

  serviceModuleHandler=data=>{
    this.serviceModuleId=data
    this.forceUpdate();
    this.getTemplateLists()

  }

  onCopyDownClickHandler = async (emailTemplateId) => {
    const requestBody = {
      "id": emailTemplateId
    }
    const res = await companyEmailServices.createDuplicateEmailTemplate(requestBody);
    if (res){
    toast.success("Created Successfully");
    this.getTemplateLists();
    } else {
      toast.error(res);
    }
  }

  statusHandler = async (id,value) => {
    let data = {
      companyId: this.companyId,
      serviceModuleId: this.serviceModuleId,
      activeStatus: value
    };
    var res = await companyEmailServices.updateStatusEmailTemplate(
      id,
      data
    );
    if (res) {
      toast.success(res.message);
      this.getTemplateLists()
    }
  }

  render() {
    let navBar = <div className="admin-tabs">
      <ul className="nav nav-tabs px-0" >
        {this.serviceModule.map((data, index) =>
          <li key={index}>
            <NavLink
              className={this.serviceModuleId === data.id ? "show active" : "show"}
              to={`#`}
              onClick={() => this.serviceModuleHandler(data.id)}
            >{data.serviceModuleName}
            </NavLink>
          </li>)}
      </ul>
    </div>


    return (
      <div> 
        <TravelPage
        {...this.state}
          navBar = {navBar}
          dataList={this.dataList}
          inputChangeHandler={this.inputChangeHandler}
          handleItemChange={this.handleItemChange}
          pageClickHandler={this.pageClickHandler}
          activePage={this.selectedPage}
          totalCount={this.totalCount}
          dataPerPage={this.dataPerPage}
          pageItemsList={this.pageItemsList}
          serviceModuleId={this.serviceModuleId}
          statusHandler={this.statusHandler}
          onSelecthandler={this.onSelecthandler}
          onCopyDownClickHandler={this.onCopyDownClickHandler}
        />
      </div>
    );
  }
}
export default Travel;
