import React from 'react'

export default function FlightNoResults(props) {
  return (
    <div className="text-center p-5">
      <img
        className="img-fluid"
        width="400"
        alt="no bus"
        src="/assets/images/no_results.png"
      />
      {/* <h5>Oops!</h5> */}
      <p>
        {props.msg}
            </p>
      <button
        className="btn"
        type="button"
        data-target="#modifyModal"
        data-toggle="modal"
      >
        Modify Search
            </button>
    </div>
  )
}
