import React from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { getValue } from "src/_components/common/lodsh";
moment.suppressDeprecationWarnings = true;

const expenseCard = props => {
  var newArray = getValue(props, "expenseReport", []).filter(
    value => Object.keys(value).length !== 0
  );
  return (
    <ul className="expense-reports">
      {newArray &&
        newArray.map((report, index) => {
          if (!(report && report.EXPENSE_DATA)) {
            let image = "";
            let imageArray = [];
            imageArray = report.image && report.image[0];
            if (Array.isArray(imageArray)) {
              let newImageArray = imageArray.filter(
                images => images.status === true
              );
              image = newImageArray[0] && newImageArray[0].uploadReceipt;
            }
            let theadArray = [];
            theadArray =
              report.headerForm &&
              report.headerForm.map(header => header.columnName);
            let REPORT_NAME = theadArray
              ? theadArray.indexOf("REPORT_NAME")
              : -1;

            return (
              <li
                key={index}
                onClick={e =>
                  props.onClickNavHandler(
                    report.id,
                    report.policyId.id,
                    "",
                    report
                  )
                }
              >
                <NavLink to className="pending">
                  <div
                    className="image"
                    style={
                      image
                        ? { backgroundImage: `url(${image})` }
                        : {
                            backgroundImage: `url(/assets/images/no_receipt.svg)`
                          }
                    }
                  />
                  <div className="amount">
                    <p>
                      {newArray[index] &&
                      newArray[index].reportTotal &&
                      newArray[index].reportTotal.baseCurrencyName
                        ? newArray[index].reportTotal.baseCurrencyName
                        : props.baseCurrency}
                      &nbsp;
                      {newArray[index] &&
                      newArray[index].reportTotal &&
                      newArray[index].reportTotal.totalAmount
                        ? parseFloat(
                            newArray[index].reportTotal.totalAmount
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                    <p className="right">
                      <i className="material-icons">access_time</i>{" "}
                      {/* {props.expenseReport[index] &&
                          props.expenseReport[index].status} */}
                      {newArray[index] && newArray[index].additionalStatus
                        ? newArray[index].additionalStatus.statusType
                        : newArray[index] && newArray[index].status}
                    </p>
                    <div className="clearfix" />
                  </div>
                  <div className="desc">
                    <h6>{report.reportId}</h6>
                    <h6>
                      {report && report.requestId && report.requestId !== "" ? (
                        <i className="material-icons">flight</i>
                      ) : (
                        <i className="material-icons">attach_money</i>
                      )}
                      &nbsp;{" "}
                      {REPORT_NAME !== -1 &&
                        report.headerForm[REPORT_NAME] &&
                        report.headerForm[REPORT_NAME].columnName ===
                          "REPORT_NAME" &&
                        report.headerForm[REPORT_NAME].fieldValue}
                    </h6>
                    <p>
                      {report.createdAt &&
                        moment(report.createdAt).format("D MMM YYYY")}
                    </p>
                  </div>
                </NavLink>
              </li>
            );
          } else {
            let checkApprovedDataAndIsuued = (
              cashAdvanceResult,
              workflowSteps,
              workflowCurrent
            ) => {
              let checkStatus = () => {
                return cashAdvanceResult.current.STATUS
                  ? cashAdvanceResult.current.STATUS.toString
                  : false;
              };
              let checkWorkflowSteps = () => {
                return workflowCurrent && !workflowCurrent.currentOrderNo
                  ? workflowSteps[workflowCurrent.currentOrderNo] &&
                    workflowSteps[workflowCurrent.currentOrderNo].intialStatus
                    ? workflowSteps[workflowCurrent.currentOrderNo].intialStatus
                    : ""
                  : workflowCurrent && workflowCurrent.currentOrderNo.toString()
                  ? workflowSteps[workflowCurrent.currentOrderNo] &&
                    workflowSteps[workflowCurrent.currentOrderNo].intialStatus
                  : "";
              };
              let getCashDataStatus = () => {
                return cashAdvanceResult.EXPENSE_DATA &&
                  cashAdvanceResult.EXPENSE_DATA.headerObj
                  ? cashAdvanceResult.EXPENSE_DATA.headerObj.status
                  : "Approved";
              };

              return checkStatus() ? getCashDataStatus() : checkWorkflowSteps();
            };

            report["headerForm"] =
              report.EXPENSE_DATA && report.EXPENSE_DATA.headerObj.headerForm;
            report["reportId"] =
              report.EXPENSE_DATA && report.EXPENSE_DATA.headerObj.reportId;
            let image = "";
            let imageArray = [];
            imageArray = report.image && report.image[0];

            let currentWorkFlowOrderNo = report.current;
            let workFlowStepsStatus = report.info.steps
              ? report.info.steps
              : report.info;
            let submitStatus = checkApprovedDataAndIsuued(
              report,
              workFlowStepsStatus,
              currentWorkFlowOrderNo
            );

            // report.current.STATUS? report.current.STATUS: workFlowStepsStatus[currentWorkFlowOrderNo].intialStatus ? workFlowStepsStatus[currentWorkFlowOrderNo].intialStatus:'Pending Approval';

            if (Array.isArray(imageArray)) {
              let newImageArray = imageArray.filter(
                images => images.status === true
              );
              image = newImageArray[0] && newImageArray[0].uploadReceipt;
            }
            let theadArray = [];
            theadArray = report.headerForm.map(header => header.columnName);
            let REPORT_NAME = theadArray.indexOf("REPORT_NAME");
            return (
              <li
                key={index}
                onClick={e =>
                  props.onClickNavHandler(
                    report.EXPENSE_DATA.headerObj.id,
                    report.EXPENSE_DATA.headerObj.policyId.id,
                    report.id,
                    report
                  )
                }
              >
                <NavLink to className="pending">
                  <div
                    className="image"
                    style={
                      image
                        ? { backgroundImage: `url(${image})` }
                        : {
                            backgroundImage: `url(/assets/images/no_receipt.svg)`
                          }
                    }
                  />
                  <div className="amount">
                    <p>
                      {report.EXPENSE_DATA && report.EXPENSE_DATA.baseCurrency}
                      &nbsp;
                      {report.EXPENSE_DATA
                        ? parseFloat(report.EXPENSE_DATA.totalAmount).toFixed(2)
                        : "0.00"}
                    </p>
                    <p className="right">
                      <i className="material-icons">access_time</i>{" "}
                      {submitStatus}
                    </p>
                    <div className="clearfix" />
                  </div>
                  <div className="desc">
                    <h6>{report.reportId}</h6>
                    <h6>
                      {report.EXPENSE_DATA &&
                      report.EXPENSE_DATA.requestId &&
                      report.EXPENSE_DATA.requestId !== "" ? (
                        <i className="material-icons">flight</i>
                      ) : (
                        <i className="material-icons">attach_money</i>
                      )}
                      &nbsp;{" "}
                      {report.headerForm[REPORT_NAME] &&
                        report.headerForm[REPORT_NAME].columnName ===
                          "REPORT_NAME" &&
                        report.headerForm[REPORT_NAME].fieldValue}
                    </h6>
                    <p>
                      {report.createdAt &&
                        moment(report.createdAt).format("D MMM YYYY")}
                    </p>
                  </div>
                </NavLink>
              </li>
            );
          }
        })}
    </ul>
  );
};

export default expenseCard;
