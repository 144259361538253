import React, { Component } from 'react';
import CarContractPage from './car-contracts-html';
import { contractServices } from '../../../../../../_services';
import $ from "jquery";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
// import Pagination from 'react-js-pagination';

class CarContract extends Component {
  constructor(props) {
    super(props)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.companyId = localStorage["440a28"];
    this.validator = new SimpleReactValidator();
  }
  state = {
    limit: 10,
    selectedPage: 1,
    selectedId: "",
    paymentTypeList: [],
    totalCount: "",
    pageItemsList: [{ items: 10 }, { items: 20 }, { items: 30 }],
    search: "",

    companyId: localStorage["440a28"],
    page: 1,
    cityList: [],
    isSearchCityListOpen: true,
    selectedCity: {
      name: "",
      id: ""
    },
    cityCars: [],
    removeCity: "",
    removeButton: false,
    editCity: false,
    isLoading: true,
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    let cityData = {
      companyId: this.state.companyId,
      limit: this.state.limit,
      page: this.state.page
    }
    this.getCarDetailsInCity(cityData);
  }
  getCarDetailsInCity = async (data) => {
    var res = await contractServices.getCarDetailsInCity(data);
    if (res) {
      this.setState({ isLoading: false })
      this.setState({ cityCars: res.result, totalCount: res.count })
    }
    this.forceUpdate();
  }
  pageClickHandler = page => {
    this.setState({ selectedPage: page })
    let data = {
      companyId: this.state.companyId,
      limit: this.state.limit,
      page: this.state.page
    }
    this.getCarDetailsInCity(data);
  };
  handleItemChange = limit => {
    this.setState({ limit: limit["items"], selectedPage: 1 })
    let data = {
      companyId: this.state.companyId,
      limit: limit["items"],
      page: this.state.selectedPage
    }
    this.getCarDetailsInCity(data)
  };

  handleChangeCity = async (e) => {
    let searchTerm = e.target.value;
    try {
      if (searchTerm != '') {
        // * if the user is typing show the matched search users.
        this.setState({
          isSearchCityListOpen: true,
          selectedCity: {
            name: e.target.value
          }
        });

        let res = await contractServices.getCityList(searchTerm);

        if (res && res.count) {
          this.setState({
            cityList: res.result
          })

        }
      } else {
        this.setState({ cityList: [], selectedCity: {} });
      }

    } catch (err) {
      console.log('some error occurred.');
    }

  }

  selectChangeCity = (city) => {
    let selectedCity = {
      name: city.city,
      id: city.id
    }
    this.setState({
      selectedCity,
      isSearchCityListOpen: false
    });
  }
  saveTheSelectedCity = async (e) => {
    e.preventDefault();
    let data = {
      cityId: this.state.selectedCity.id,
      cityName: this.state.selectedCity.name,
      companyId: this.state.companyId
    }

    if (this.validator.allValid()) {
      var resp = await contractServices.saveTheSelectedCityForCar(data);
      if (resp) {
        $(".close").click();
        toast.success(resp.message);
        let cityData = {
          companyId: this.state.companyId,
          limit: this.state.limit,
          page: this.state.page
        }
        this.getCarDetailsInCity(cityData);
      }

    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  onDeleteHandler = city => {
    let removeCity = this.state.removeCity;
    if (removeCity === city) {
      this.setState({ removeCity: "", removeButton: false });
    } else {
      this.setState({ removeCity: city, removeButton: true });
    }
  };

  removeCityHandler = async () => {
    let deleteId = this.state.removeCity;
    var res = await contractServices.removeCityHandlerForCar(deleteId);
    if (res) {
      let cityData = {
        companyId: this.state.companyId,
        limit: this.state.limit,
        page: this.state.page
      }
      this.getCarDetailsInCity(cityData);
      this.setState({ removeCity: "", removeButton: false });
      toast.success(res.message);
    }
  };
  onStatusChangeHandler = async (id, activeStatus) => {
    let data = {
      activeStatus
    }
    var res = await contractServices.onStatusChangeHandlerForCar(id, data);
    if (res) {
      let cityData = {
        companyId: this.state.companyId,
        limit: this.state.limit,
        page: this.state.page
      }
      this.getCarDetailsInCity(cityData);
      toast.success(res.message);
    }
  };


  onSearchTextChangeHandler = async (event) => {
    let search = event.target.value;
    if (search !== '' && search !== '') {
      await this.setState({ search: event.target.value });
      let response = await contractServices.getCarDetailsSearch(this.state.companyId, search);
      if (response.count > 0) {
        this.setState({
          cityCars: response.result,
          totalCount: response.count
        });
      } else {
        this.setState({
          cityCars: []
        });
      }
    } else {
      this.setState({ search: '' });
      let cityData = {
        companyId: this.state.companyId,
        limit: this.state.limit,
        page: this.state.page
      }
      this.getCarDetailsInCity(cityData);
    }
  }

  render() {
    return (
      <div>
        <CarContractPage
          {...this.state}
          state={this.state}
          onStatusChangeHandler={this.onStatusChangeHandler}
          handleChangeCity={this.handleChangeCity}
          selectChangeCity={this.selectChangeCity}
          saveTheSelectedCity={this.saveTheSelectedCity}
          validator={this.validator}
          onDeleteHandler={this.onDeleteHandler}
          removeCityHandler={this.removeCityHandler}
          pageClickHandler={this.pageClickHandler}
          handleItemChange={this.handleItemChange}
          onSearchTextChangeHandler={this.onSearchTextChangeHandler}
        />
      </div>
    )
  }
}
export default CarContract;