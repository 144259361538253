import React, { Component } from "react";
import { Route } from "react-router-dom";
import CashAdvance2Page from "./cashadvance";
import ExpenseReportsPage from "./expense-reports";
import TripsApprovalPage from "./trips";
import CashForTripsPage from "./cash-for-trips";
import ExpenseDetailsForTripPage from "./expense-details-for-trip";
import NotificationPage from "./notification";
import CompanyNotePage from "./company-note";
import ExpenseDetailsForCarMilagePage from "./expense-details-for-car";
import BudgetDashboardPage from "./budget-dashboard";
import TripTravelRequestPage from "./trip-travel-request";

class TripsApprovePage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/expense") {
      this.props.history.push("/expense/home");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/expense") {
      this.props.history.push("/expense/home");
    }
  }
  render() {
    return (
      <div className="BookingPage bg-light-blue">
        <div className="page-content">
          <Route
            path="/trips-approve/cashadvance"
            component={CashAdvance2Page}
          />
          <Route
            path="/trips-approve/expense-reports"
            component={ExpenseReportsPage}
          />
          <Route path="/trips-approve/trips" component={TripsApprovalPage} />
          <Route
            path="/trips-approve/cash-for-trips"
            component={CashForTripsPage}
          />
          <Route
            path="/trips-approve/expense-details-for-trips"
            component={ExpenseDetailsForTripPage}
          />
          <Route
            path="/trips-approve/notification"
            component={NotificationPage}
          />
          <Route
            path="/trips-approve/company-note"
            component={CompanyNotePage}
          />
          <Route
              path="/trips-approve/expense-details-for-car"
              component={ExpenseDetailsForCarMilagePage}
          />
          <Route
              path="/trips-approve/trip-travel-request/:id"
              component={TripTravelRequestPage}
          />
          <Route
              path="/trips-approve/budget-dashboard"
              component={BudgetDashboardPage}
          />
        </div>
      </div>
    );
  }
}
export default TripsApprovePage;
