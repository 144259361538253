import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import Loader from "../../../../../common/Loader";
import { toast } from "react-toastify";

const TableRow = props => {
  const { emailReminderData, navigationHandler } = props;
  return (
    <>
      {props.isLoading === true ? (
        <tbody>
          <tr>
            <td className="text-center" colSpan="6">
              <Loader />
            </td>
          </tr>
        </tbody>
      ) : !emailReminderData.length ? (
        <tbody>
          <tr>
            <td className="text-center" colSpan="6">
              No Records Found
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {emailReminderData &&
            emailReminderData.map((item, key) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.type}</td>
                <td>
                  {item.olderThan} {item.olderThanType}
                </td>
                <td>{item.frequency}</td>
                <td>
                  {/* <NavLink
                    to={
                      item.isSet === true
                        ? `/administration/company/reminder/add-edit-reminder/${
                            item.id
                          }`
                        : "#"
                    }
                    className="btn btn-nothing hover-v"
                  >
                    <i className="material-icons fs-20">edit</i>
                    <span className="hover-name right-top">Edit</span>
                  </NavLink> */}
                  <button
                    onClick={e => navigationHandler(item.isSet, item.id)}
                    className="btn btn-nothing hover-v"
                  >
                    <i className="material-icons fs-20">edit</i>
                    <span className="hover-name right-top">Edit</span>
                  </button>
                </td>
                <td>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={item.isSet}
                      onChange={() => props.OnStatusHandler(item)}
                    />
                    <span className="slider round" />
                  </label>
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </>
  );
};
TableRow.propTypes = {
  OnStatusHandler: PropTypes.func,
  emailReminderData: PropTypes.array,
  isLoading: PropTypes.bool
};
export default TableRow;
