import React, { Component } from 'react';
import AddpaymentTypesPage from './add-payment-types-html';
import { expensePaymentTypeService } from '../../../../../../_services';
import SimpleReactValidator from "simple-react-validator";
import { withRouter } from 'react-router-dom';


import { toast } from "react-toastify";
class AddpaymentTypes extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.companyId = localStorage["440a28"];
  }
  state = {
    limit: 10,
    selectedPage: 1,
    isEditable: false,
    description: "",
    paymentTypeCode: "",
    selectedPaymentType: "",
    selectedGroup: [],
    paymentTypeList: [],
    groupError: false,
    groupList: []

  }


  componentDidMount() {
    if (this.props.match.params.id) {
      this.getPaymentType()
      this.getSelectedPaymentType()
    } else {
      this.getPaymentType()
      this.getGroupType()
    }

  }

  inputChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  getSelectedPaymentType = async () => {
    await this.getGroupType();
    var res = await expensePaymentTypeService.getSelectedPaymentType(this.props.match.params.id);
    if (res) {
      let selectedGroup = [];
      this.state.groupList.forEach((data, index) => {
        if (res.result.appliesTo.includes(data.value)) {
          selectedGroup.push({ label: data.label, value: data.value, index: index });
        }
      });
      this.setState({
        isEditable: true,
        description: res.result.description,
        paymentTypeCode: res.result.paymentTypeCode,
        selectedPaymentType: res.result.paymentType,
        selectedGroup: selectedGroup
      })
    }

  }

  selectHandler = (e, key) => {
    if (e) {
      if (key === "selectedGroup") {
        this.setState({ groupError: false })
        let temp = this.state.selectedGroup.slice()
        this.setState({ [key]: e })
      } else {
        this.setState({ selectedPaymentType: e.value })
      }
    }
  }


  getPaymentType = async () => {
    let data = {
      companyId: this.companyId,
      limit: this.state.limit,
      page: this.state.selectedPage
    }
    var res = await expensePaymentTypeService.getPaymentType(data)
    if (res) {
      this.setState({
        paymentTypeList: res.result.map((data, index) => {
          return {
            payment: data.itemName,
            label: data.itemName,
            value: data.id
          }
        })
      })
    }
  }

  getGroupType = async () => {
    let data = {
      companyId: this.companyId,
      limit: this.state.limit,
      page: this.state.selectedPage
    }
    var res = await expensePaymentTypeService.getGroupType(data)
    if (res) {
      this.setState({
        groupList: res.result.map((data, index) => {
          return {
            label: data.itemName,
            value: data.id,
            index: index
          }
        })
      })
    }
  }

  submitHandler = async (e) => {
    e.preventDefault();
    let appliesTo = this.state.selectedGroup.map((data) => data.value);
    let data =
    {
      companyId: this.companyId,
      paymentType: this.state.selectedPaymentType,
      description: this.state.description,
      appliesTo: appliesTo,
      paymentTypeCode: this.state.paymentTypeCode
    }
    if (this.validator.allValid() && this.state.selectedGroup.length > 0) {
      if (this.state.isEditable) {
        var res = await expensePaymentTypeService.editSelectedPaymentType(
          this.props.match.params.id,
          data
        ).catch(this.setState({ loading: false }))
        if (res) {
          toast.success(res.message);
          this.setState({ loading: false });
          this.props.history.push(`/administration/expense/payment-types`)
        }

      } else {
        var res = await expensePaymentTypeService.postPaymentType(this.companyId, data).catch(this.setState({ loading: false }));
        if (res) {
          this.setState({ loading: false });
          toast.success(res.message);
          this.props.history.push(`/administration/expense/payment-types`)
        }
      }
    } else {
      this.validator.showMessages();
      this.setState({ loading: false });
      if (this.state.selectedGroup.length === 0) {
        this.setState({ groupError: true })
      }
      this.forceUpdate();
    }
  }

  render() {
    return (
      <div>
        <AddpaymentTypesPage
          inputChangeHandler={this.inputChangeHandler}
          submitHandler={this.submitHandler}
          state={this.state}
          validator={this.validator}
          selectHandler={this.selectHandler}
        />
      </div>
    )
  }
}
export default withRouter(AddpaymentTypes);