import React, { Component } from "react";
import LeftSidebar from "./components/addExpenseType/leftSidebar";
import { inject, observer } from "mobx-react";
import SimpleReactValidator from "simple-react-validator";
import { expenseReport } from "../../../_services/expenseReport";
import ExpenseCreateForm from "./components/addExpenseType/expenseCreateForm";
import { toast } from "react-toastify";
import _ from "lodash";
import CryptrDetail from "cryptr";
import moment from "moment";
import Loader from "./../../../_components/common/Loader";
import ColumnNames from "src/_components/common/columnNames";
import { mileageService } from "../../../_services/mileage.service";

@inject("expenseStore")
@observer
class EditItemisation extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      companyId: localStorage["440a28"],
      expenseForm: [],
      error: [],
      formFields: [],
      fieldsObject: [],
      expenseEntryList: [],
      totoalExpense: 0,
      auditRuleValidationError: [],
      cashAdvanceTotalAmount: 0,
      totalRequestAmount: 0,
      totalExpenseAmount: 0,
      validationStatus: false,
      isSubmitClick: false,
      userPermission: "employee-role",
      connectedListData: [],
      isLoading: true,
      pageName: "itemisation",
      remainigItemiseAmount: 0,
      currency: "",
      isApproval: false,
      displayTaxFields: false,
      displayCSGST: false,
      displayIGST: false,
      taxAmount: 0
    };
  }
  componentDidMount() {
    this.getAddExpenseFormField();
    this.getAllCreatedExpenses();
    const detailCryptr = new CryptrDetail("expense@Details");
    const remainigItemiseAmount = detailCryptr.decrypt(
      localStorage.getItem("remainigItemise")
    );
    const currency = detailCryptr.decrypt(localStorage.getItem("currency"));
    this.setState({
      remainigItemiseAmount: remainigItemiseAmount,
      currency: currency
    });
  }

  getAllCreatedExpenses = async () => {
    let headerId = localStorage.getItem("headerId");
    if (headerId) {
      let response = await expenseReport.getAllCreatedExpenseOldAPI(
        this.state.companyId,
        headerId
      );
      console.log(response)
      if (response) {
        this.setState({
          reportHeader: response.headerObj.headerForm,
          totoalExpense: response.expenseReportEntryObj.length,
          expenseEntryList: response.expenseReportEntryObj,
          totalExpenseAmount: response.totalAmount,
          userBaseCurrency: response.baseCurrency,
          cashAdvanceTotalAmount: response.cashAdvanceTotalAmount,
          totalRequestAmount: response.requestAmount
        });
        let validationStatus = true;
        response.expenseReportEntryObj &&
          response.expenseReportEntryObj.forEach((list, index) => {
            validationStatus = !list.validation && validationStatus;
          });
        this.setState({ validationStatus: validationStatus });
      }
    }
  };

  getAddExpenseFormField = async () => {
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let itemization_id = localStorage.getItem("itemization_id");
    let response = await expenseReport.editItemisation(
      expenseEntryId,
      itemization_id
    );
    console.log(response)
    let expenseApprovalEntryId = this.props.match.params.id;
    if (
      expenseApprovalEntryId !== undefined &&
      expenseApprovalEntryId !== "" &&
      expenseApprovalEntryId !== null
    ) {
      if (response && response) {
        response = response[0];
        this.setState({
          expenseForm: response.itemizationField,
          itemisationExpenseTypeName: response.expenseTypeId.expenseTypeName
        });

        let newConnectedListDate = [];
        let dynamicFeilds = [];
        dynamicFeilds = _.filter(response.itemizationField, function(result) {
          return result.fieldLevel !== undefined && result.fieldLevel !== "";
        });
        dynamicFeilds.forEach(fields => {
          newConnectedListDate.push({
            columnName: fields.columnName,
            id: fields.id,
            multiSelectParentField: fields.multiSelectParentField
              ? fields.multiSelectParentField
              : "",
            fieldName: fields.fieldName,
            fieldLevel: fields.fieldLevel,
            prevFieldId: fields.prevFieldId
          });
        });
        this.setState({
          connectedListData: newConnectedListDate,
          isApproval: true
        });
        this.createDropdownForMultiLblConnectedList(newConnectedListDate);
        this.setTheStateOfFormsAndFields(response.itemizationField);
      }
    } else {
      if (response && response[itemization_id]) {
        this.setState({
          expenseForm: response[itemization_id].itemizationField,
          itemisationExpenseTypeName:
            response[itemization_id].expenseTypeId.expenseTypeName
        });

        let newConnectedListDate = [];
        let dynamicFeilds = [];
        dynamicFeilds = _.filter(
          response[itemization_id].itemizationField,
          function(result) {
            return result.fieldLevel !== undefined && result.fieldLevel !== "";
          }
        );
        dynamicFeilds.forEach(fields => {
          newConnectedListDate.push({
            columnName: fields.columnName,
            id: fields.id,
            multiSelectParentField: fields.multiSelectParentField
              ? fields.multiSelectParentField
              : "",
            fieldName: fields.fieldName,
            fieldLevel: fields.fieldLevel,
            prevFieldId: fields.prevFieldId
          });
        });
        this.setState({ connectedListData: newConnectedListDate });
        this.createDropdownForMultiLblConnectedList(newConnectedListDate);
        this.setTheStateOfFormsAndFields(
          response[itemization_id].itemizationField
        );
      }
    }
  };
  setTheStateOfFormsAndFields = formAndFields => {
    let formFields = [];
    let fieldsObjects = [];
    formAndFields.forEach(async formField => {
      let fieldName = formField.fieldName;
      if (formField.dataType === "date") {
        this.setState({ [fieldName]: formField.fieldValue });
      } else if (formField.dataType === "checkbox") {
        this.setState({ [fieldName]: formField.fieldValue });
      } else if (formField.dataType === "search") {
        if (
          formField.prevFieldId !== undefined &&
          formField.prevFieldId !== ""
        ) {
          await this.setState({
            [fieldName]: {
              label: formField.fieldValue,
              value: formField.prevFieldId
            }
          });
          this.checkGstForCities(formField.prevFieldId);
        } else {
          this.setState({ [fieldName]: "" });
        }
      } else {
        this.setState({ [fieldName]: formField.fieldValue });
        this.enableCgstTOrIgstField(
          formField.fieldName,
          formField.fieldValue,
          formAndFields
        );
      }
      formFields.push(formField.fieldName);
      this.setState({ submitButtonStatus: false });
      fieldsObjects.push(formField);
    });
    console.log(fieldsObjects)
    this.setState({ isLoading: false });
    this.setState({ formFields: formFields, fieldsObject: fieldsObjects });
  };
  createDropdownForMultiLblConnectedList = newConnectedListDate => {
    newConnectedListDate &&
      newConnectedListDate.forEach(async fields => {
        if (fields.fieldLevel === 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField === fields.id &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 2) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField ===
                fields.multiSelectParentField &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }
      });
  };
  onSubmitHandler = async () => {
    if (this.validator.allValid()) {
      this.setState({ isSubmitClick: true });
      let fieldsObjects = this.state.fieldsObject;
      let index = 0;
      for (const key of this.state.formFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          fieldsObjects[index].fieldValue = this.state[key]
            ? this.state[key].value
            : "";
          fieldsObjects[index].searchName = this.state[key]
            ? this.state[key].label
            : "";
        } else if (
          fieldsObjects[index].fieldLevel >= 2 &&
          fieldsObjects[index].dataType === "connected_list"
        ) {
          fieldsObjects[index].fieldValue = this.state[key];
          fieldsObjects[index].connectedListData = {};
          fieldsObjects[
            index
          ].connectedListData.listManagementData = this.state[
            fieldsObjects[index].columnName
          ];
        } else {
          fieldsObjects[index].fieldValue = this.state[key];
        }
        index++;
      }
      let expenseType = localStorage.getItem("imtmExpTypeId");
      let itemization_id = localStorage.getItem("itemization_id");
      let entryId = localStorage.getItem("expenseEntryId");
      let createExpensePolicy = {
        itemization_id: itemization_id,
        policyId: localStorage.getItem("policyId"),
        entryId: entryId,
        itemiseAmount: this.state.Amount,
        expenseType: expenseType,
        itemizationField: fieldsObjects,
        taxAmount: this.state.taxAmount
      };
      let response = await expenseReport.updateItemizationExpenseType(
        createExpensePolicy
      );
      if (response) {
        if (response.result !== "error") {
          if (
            response.result &&
            response.result.errorMessages &&
            response.result.errorMessages.errorMessages
          ) {
            this.setState({
              submitButtonStatus: false,
              auditRuleValidationError:
                response.result.errorMessages.errorMessages,
              isSubmitClick: false
            });
          } else {
            this.setState({ isSubmitClick: false });
            localStorage.removeItem("imtmExpTypeId");
            localStorage.removeItem("itemization_id");
            let prevPage = sessionStorage.getItem("from");
            toast.success(response.message);
            if (
              this.state.isApproval &&
              prevPage !== "finance" &&
              prevPage !== "Expense"
            )
              this.props.history.push(
                `../details/approval/${this.props.match.params.id}`
              );
            else if (prevPage === "finance")
              this.props.history.push(
                `../../expense/details/fincapproval/${this.props.match.params.id}`
              );
            else if (this.props.match.params.id && prevPage === "Expense")
              this.props.history.push(
                `../details/${this.props.match.params.id}`
              );
            else this.props.history.push(`./details`);
          }
        } else {
          this.setState({ isSubmitClick: false });
          toast.error(response.message);
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  onCancelClickHandler = () => {
    let prevPage = sessionStorage.getItem("from");
    let headerId = this.props.match.params.id;
    if (headerId && prevPage !== "finance" && prevPage !== "Expense")
      this.props.history.push(`../details/approval/${headerId}`);
    else if (prevPage === "finance")
      this.props.history.push(`../../expense/details/fincapproval/${headerId}`);
    else if (headerId && prevPage === "Expense")
      this.props.history.push(`../details/${headerId}`);
    else this.props.history.push(`./details`);
  };

  onClickHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.enableCgstTOrIgstField(fieldName, fieldId);
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.showCgstIgstExchangeAmountRate(fieldName, fieldId);
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };

  onChangeHandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };
  onDatePickerHandler = (value, fieldName) => {
    if (!isNaN(value)) {
      this.setState({
        [fieldName]: moment(new Date(value)).format("DD MMM YYYY")
      });
    }
  };
  onCheckboxHandler = fieldName => {
    this.setState({ [fieldName]: !this.state[fieldName] });
  };
  onIntegerHandler = (event, fieldName) => {
    if (!isNaN(event.target.value)) {
      this.setState({ [fieldName]: +event.target.value });
      this.showCgstIgstExchangeAmountRate(fieldName);
    }
  };

  onLocationChangekHandler = async (event, fieldName) => {
    let response = await mileageService.getGstAvailable(event.value);
    if (response) {
      this.setState({ displayTaxFields: true });
    } else {
      this.setState({
        displayCSGST: false,
        displayIGST: false,
        displayTaxFields: false
      });
    }
    this.setState({ [fieldName]: event });
  };

  loadOptions = async newValue => {
    return expenseReport.getAllLocationsName(newValue).then(data => {
      return data.result.map(list => {
        return {
          label: list.name + "," + list.countryId.name,
          value: list.id
        };
      });
    });
  };

  //tax calculation start here
  enableCgstTOrIgstField = (fieldName, fieldId, entryForm = []) => {
    let fieldsObject = [...this.state.fieldsObject];
    fieldsObject = fieldsObject.length ? fieldsObject : entryForm;
    let fields = fieldsObject.find(field => field.fieldName === fieldName);
    if (fields && fields.columnName === ColumnNames.TAX_TYPES) {
      let selectedTaxType = fields.connectedListData.listManagementData.find(
        listData => listData.id === fieldId
      );
      if (selectedTaxType && selectedTaxType.itemName === ColumnNames.IGST) {
        this.setState({ displayCSGST: false, displayIGST: true });
      } else if (
        selectedTaxType &&
        selectedTaxType.itemName === "CGST & SGST/UGST"
      ) {
        this.setState({ displayCSGST: true, displayIGST: false });
      }
    }
  };

  showCgstIgstExchangeAmountRate = _.debounce((fieldName, fieldId) => {
    let fieldsObject = [...this.state.fieldsObject];
    let fields = fieldsObject.find(field => field.fieldName === fieldName);
    if (
      fields &&
      fields.columnName === ColumnNames.TAX_RATE &&
      this.state.displayTaxFields
    ) {
      let taxRate = this.state[ColumnNames.TAX_RATE];
      let amountFields = fieldsObject.find(
        field => field.columnName === ColumnNames.AMOUNT
      );
      const amount = this.state[amountFields.fieldName];
      let rate = taxRate.find(rate => rate.id === fieldId);
      this.setPercentageOfCgstIgst(
        rate ? rate.itemName : 0,
        amount ? amount : 0
      );
    } else if (
      fields &&
      fields.columnName === ColumnNames.AMOUNT &&
      this.state.displayTaxFields
    ) {
      let fields = fieldsObject.find(
        field => field.columnName === ColumnNames.TAX_RATE
      );
      let amountFields = fieldsObject.find(
        field => field.columnName === ColumnNames.AMOUNT
      );
      let taxRate = this.state[ColumnNames.TAX_RATE];
      if (taxRate) {
        const amount = this.state[amountFields.fieldName];
        const taxFieldId = this.state[fields.fieldName];
        let rate =
          taxRate &&
          taxRate.length &&
          taxRate.find(rate => rate.id === taxFieldId);
        this.setPercentageOfCgstIgst(
          rate ? rate.itemName : 0,
          amount ? amount : 0
        );
      }
    }
  }, 250);

  setPercentageOfCgstIgst = async (rate, amount) => {
    if (rate > 0 && amount > 0) {
      let response = await mileageService.getTotalTaxPercentage(rate, amount);
      if (response) {
        let fieldsObject = [...this.state.fieldsObject];
        const igst = fieldsObject.find(
          field => field.columnName === ColumnNames.IGST
        );
        const cgst = fieldsObject.find(
          field => field.columnName === ColumnNames.CGST
        );
        const sgst = fieldsObject.find(
          field => field.columnName === ColumnNames.SGST_UTGST
        );
        if (this.state.displayCSGST) {
          this.setState({
            [cgst.fieldName]: response / 2,
            [sgst.fieldName]: response / 2,
            [igst.fieldName]: 0,
            taxAmount: response
          });
        } else {
          this.setState({
            [igst.fieldName]: response,
            [cgst.fieldName]: 0,
            [sgst.fieldName]: 0,
            taxAmount: response
          });
        }
      }
    }
  };

  checkGstForCities = async cityId => {
    let response = await mileageService.getGstAvailable(cityId);
    if (response) {
      this.setState({ displayTaxFields: true });
    } else {
      this.setState({
        displayCSGST: false,
        displayIGST: false,
        displayTaxFields: false
      });
    }
  };
  //tax calculation end here

  render() {
    return (
      <div>
        <LeftSidebar
          expenseEntryList={this.state.expenseEntryList}
          totoalExpense={this.state.totoalExpense}
          selectExpenstEntryList={
            localStorage.getItem("expenseEntryId") ||
            this.props.expenseStore.selectExpenstEntryList
          }
          cashAdvanceTotalAmount={this.state.cashAdvanceTotalAmount}
          userBaseCurrency={this.state.userBaseCurrency}
          totalExpenseAmount={this.state.totalExpenseAmount}
          totalRequestAmount={this.state.totalRequestAmount}
          validationStatus={this.state.validationStatus}
        />
        <div className="expense-right  p-padding">
          <div className="modal-content pm-30 border-0">
            <h5 className="modal-title" id="expenseTypeLabel">
              Update Itemisation Expense Type (
              {this.state.itemisationExpenseTypeName})
            </h5>
            <div className="mb-3 row">
              <div className="col-lg-4">
                <div className="amount-show total-amount">
                  <h6>Remaining Amount</h6>
                  <h5>
                    {this.state.currency}&nbsp;
                    {new Intl.NumberFormat("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(this.state.remainigItemiseAmount)}
                  </h5>
                </div>
              </div>
            </div>
            {!this.state.isLoading ? (
              <div className="modal-middle ">
                <div className="row">
                  <ExpenseCreateForm
                    {...this.state}
                    validator={this.validator}
                    policyForm={this.state.expenseForm}
                    onClickHandler={this.onClickHandler}
                    onChangeHandler={this.onChangeHandler}
                    onDatePickerHandler={this.onDatePickerHandler}
                    onCheckboxHandler={this.onCheckboxHandler}
                    onIntegerHandler={this.onIntegerHandler}
                    onConnectedListkHandler={this.onConnectedListkHandler}
                    userPermission={this.state.userPermission}
                    onLocationChangekHandler={this.onLocationChangekHandler}
                    loadOptions={this.loadOptions}
                  />
                </div>
                <div className="modal-last text-left btn-group-margin">
                  <button
                    onClick={this.onCancelClickHandler}
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {!this.state.isSubmitClick ? (
                    <button
                      onClick={this.onSubmitHandler}
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                    >
                      Update
                    </button>
                  ) : (
                    <button type="button" className="btn" data-dismiss="modal">
                      Please Wait...
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default EditItemisation;
