import React from "react";
import NumberFormat from 'react-number-format';
import { getValue } from "src/_components/common/lodsh";
import Moment from "react-moment";

export const CabCartDetail = (props) => {
  let tripTypes = [
    { label: "Oneway", value: 0 },
    { label: "Round trip", value: 1 },
    { label: "Airport Pickup", value: 2 },
    { label: "Airport Drop", value: 3 },
  ];
  let vechileTypes = [
    { label: "Hatchback - economy", image: "/assets/images/hatchback_new.png" },
    { label: "Hatchback - premium", image: "/assets/images/hatchback_new.png" },
    { label: "Sedan - economy", image: "/assets/images/indigo_new.png" },
    { label: "Sedan - premium", image: "/assets/images/indigo_new.png" },
    { label: "SUV - economy", image: "/assets/images/xylo_new.png" },
    { label: "SUV - premium", image: "/assets/images/xylo_new.png" }
  ]; 
  let imageCar = vechileTypes.filter(resp => resp.label === props.item.car_type)
  let rideType = tripTypes.filter(resp => resp.value === props.item.car_ride_type)
  return (
    <div className="white-card shadow-light mb-0">

      <div className="b-b1">
        <div className="devision-container flight-iti pb-0 mb-0">
          <div className="details main">
            <div className="name">
              <div className="logo">
                <img
                  className="img-fluid"
                  src={getValue(imageCar[0], "image")}
                  alt="images"
                />
              </div>
              <div className="info">
                <p>
                  <b>
                    {getValue(props, "item.car_pickup_location.city")}
                            
                          </b>
                  {" "}to{" "}
                  <b>
                    {getValue(props, "item.car_drop_location.city")}
                  </b>
                </p>
                <p className="timings">
                  <i className="material-icons fs-14 align-middle">
                    access_time
                          </i>
                  <Moment format="DD MMM YYYY hh:mm a" >
                    {getValue(props, "item.car_pickup_timestamp", new Date())}
                  </Moment> |
                                <Moment format="DD MMM YYYY hh:mm a" >
                    {getValue(props, "item.car_drop_timestamp", new Date())}</Moment>
                  {" "}
                </p>
                <p className="class">
                  {getValue(props, "item.car_type")} -  {getValue(rideType[0], "label")}
                </p>
              </div>
              <div className="clearfix" />
            </div>
          </div>
          <div className="clearfix" />

        </div>

      </div>

      <div className="white-card mb-0">
        <div className="pad">
          <div className="white-card border-only mb-0">
            <h6 className="sub-heading heading-blue">Fare Details</h6>
            <div className="pad-sm-sm">
              <div className="table-responsive">
                <table className="table table-borderless mb-0">
                  <tbody>

                    <tr>
                      <td>
                        <p className="label">Base Fare</p>
                      </td>
                      <td className="text-right">
                        {getValue(props, "item.car_package_details.currency", "")} &nbsp;
                        <NumberFormat value={getValue(props, "item.car_package_details.price", 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                      </td>
                    </tr>
                    {/* 
                      <tr>
                        <td>
                          <p className="label">SURCHARGES</p>
                        </td>
                        <td className="text-right">
                          INR 00
                        </td>
                      </tr> */}

                    <tr>
                      <td>
                        <p className="label">Total</p>
                      </td>
                      <td className="text-right">
                      {getValue(props, "item.car_package_details.currency", "")} &nbsp;
          <NumberFormat value={getValue(props, "item.car_package_details.price", 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                     
                        </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CabCartDetail;
