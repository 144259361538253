import React, { Component } from 'react';
import ApprovalStatusPage  from './approval-status-html';
import { workflowService } from 'src/_services/workflow.service';
import SimpleReactValidator from 'simple-react-validator';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { alertConstants } from 'src/_constants';
import { Input } from 'src/_components/common/_forms';



class ApprovalStatus extends Component {

  state = {
    companyId: localStorage["440a28"],
    allExpenseApproval: [],
    totalCount: null,
    allGroups: [],
    status_approval: '',
    actionText: '',
    actionText_desc: '',
    editable_by_group: '',
    selectedGroup: [],
    selectedExpenseApproval: [],
    editMode: false,
    count:0,
    page:1,
    limit:10,
    loading:true
  }

  componentDidMount = async () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    await this.getAllGroups();
    this.getAllExpenseApproval();

  }

  validator = new SimpleReactValidator();
  getAllGroups = async () => {
    try {
      let res = await workflowService.getAllGroups(this.state.companyId);
      if(res){
        await this.setState({
          allGroups: res.result,
        });
        this.setState({
          allGroupOptions: this.state.allGroups.map(item => {
            return {
              value: item.id,
              label: item.itemName
            }
          })
        })
      }
    }catch(err){
      console.log(err);
    }
  }

  getAllExpenseApproval = async () => {
      this.setState({loading:true}) 
      let res = await workflowService.getAllExpenseApproval(this.state.page,this.state.limit);
      this.setState({loading:false}) 
      if(res){ 
        this.setState({
          allExpenseApproval: res.result,
          totalCount: res.count,
          count:res.count
        }); 
      }
  }

  onGroupChange = (selectedGroup) => {
    this.setState({selectedGroup});
  }

  inputChangeHandler = (e, type) => {
      this.setState({
        [e.target.name]: e.target.value
      })
  }

  saveApprovalStatus = async () => {
    let payload = {
      companyId: this.state.companyId,
      status_approval: this.state.status_approval,
      actionText: this.state.actionText,
      actionText_desc: this.state.actionText_desc,
      editable_by_group: this.state.selectedGroup.map(item => item.value)
    }
    // console.log('payload for creation is ', payload);
    if(this.validator.allValid()){

      let res = await workflowService.createApprovalStatus(this.state.companyId, payload);
      if(res){
        toast.success(alertConstants.CREATED_SUCCESSFULLY);
        $('.close').click();
        this.getAllExpenseApproval();
        this.setState({selectedExpenseApproval:[]})
        this.removeApprovalStateData()
      }
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }

  }

  onApprovalSelectHandler = (expenseApproval) => { 
    let selectedExpenseApproval = [...this.state.selectedExpenseApproval];
    let selectedExpenseApprovalIndex = selectedExpenseApproval.indexOf(expenseApproval);
    if(selectedExpenseApprovalIndex == -1){
      selectedExpenseApproval = [expenseApproval];
      this.setState({
        status_approval: expenseApproval.status_approval,
        actionText: expenseApproval.actionText,
        actionText_desc: expenseApproval.actionText_desc,
        editable_by_group: expenseApproval.editable_by_group,
        selectedGroup: this.state.allGroupOptions.filter(option => expenseApproval.editable_by_group.includes(option.value))
      })
    }else{
      selectedExpenseApproval = [];
      this.removeApprovalStateData();
    }

    this.setState({selectedExpenseApproval});

  }

  onRemoveEExpenseApprovalHandler = async () => {
    let res = workflowService.removeApprovalStatus(this.state.companyId, this.state.selectedExpenseApproval[0].status_approval_id);
    if(res){
      toast.success(alertConstants.DELETED_SUCCESSFULLY);
      this.getAllExpenseApproval(); 
      this.setState({selectedExpenseApproval:[]})
      this.removeApprovalStateData();
    } 
  }
  onEditExpenseHandler = () => {
    if(this.state.selectedExpenseApproval.length){
      this.setState({editMode: true});
    }
  }

  updateApprovalStatus = async () => {
    let payload = {
      companyId: this.state.companyId,
      status_approval: this.state.status_approval,
      actionText: this.state.actionText,
      actionText_desc: this.state.actionText_desc,
      editable_by_group: this.state.selectedGroup.map(item => item.value)
    } 
    if(this.validator.allValid()){

      let res = await workflowService.updateApprovalStatus(this.state.selectedExpenseApproval[0].status_approval_id, payload);
      if(res){
        toast.success(alertConstants.CREATED_SUCCESSFULLY);
        $('.close').click();
        this.setState({selectedExpenseApproval:[]})
        this.removeApprovalStateData();
        this.getAllExpenseApproval(); 
      }else{
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  }
  handleItemChange = limit => {
    this.setState({
      page:1,
      count:0,
      limit:limit["items"]
    },() => {
      this.getAllExpenseApproval();
    })   
  }; 
  handlePageChange = (pageNumber) => { 
    this.setState({
      page:pageNumber
    },() => {
      this.getAllExpenseApproval();
    })  
  } 
  cancelApprovalStatus = () => {
    $('.close').click();
    this.setState({
      editMode: false,
    });
    this.setState({selectedExpenseApproval:[]})
    this.removeApprovalStateData();
  }

  removeApprovalStateData = () => {
    this.setState({
      status_approval: '',
      actionText: '',
      actionText_desc: '',
      selectedGroup: [],
    })
  }
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }]; 




  render() {
    return(
      <div>
        <ApprovalStatusPage
          {...this.state}
          onGroupChange={this.onGroupChange}
          inputChangeHandler={this.inputChangeHandler}
          saveApprovalStatus={this.saveApprovalStatus}
          cancelApprovalStatus={this.cancelApprovalStatus}
          onApprovalSelectHandler={this.onApprovalSelectHandler}
          onRemoveEExpenseApprovalHandler={this.onRemoveEExpenseApprovalHandler}
          onEditExpenseHandler={this.onEditExpenseHandler}
          updateApprovalStatus={this.updateApprovalStatus}
          error={this.validator}
          handleItemChange={this.handleItemChange}
          handlePageChange={this.handlePageChange}
          pageItemsList={this.pageItemsList}
        />
      </div>
    )
  }
}
export default ApprovalStatus;