import React, { Component } from 'react';
import ItinerarySearchPage from './itinerary-search-html';
class ItinerarySearch extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <ItinerarySearchPage
                />
            </div>
        )
    }
}
export default ItinerarySearch;