import React, { Component } from "react";
import cryptr from "../../../_helpers/crypty";
import ReceiptList from "./components/receiptList";
import ReceiptImageModal from "./components/receiptImageModal";
import ExpenseHeader from "./components/expenseHeader";
import ReceiptFilter from "./components/receiptFilter";
import { inject, observer } from "mobx-react";
import { expenseReport } from "../../../_services/expenseReport";
import $ from "jquery";
import Loader from "src/_components/common/Loader";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";

@inject("authStore")
@observer
class ReceiptsPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      selected: "This Month",
      searchText: "",
      browseImage: [],
      fileName: [],
      companyId: localStorage["440a28"],
      userId: "",
      browseAllImages: "",
      allReceipt: [],
      deleteReceiptSelected: "",
      pageLimit: 12,
      totalCount: 0,
      pageNumber: 1,
      imageTypeDiffrent: true
    };
  }
  componentDidMount() {
    let currentUser = JSON.parse(
      cryptr.decrypt(localStorage.getItem("440a24"))
    );
    this.setState({ userId: currentUser["id"] });
    this.fetchAllReportReceipt();
  }

  fetchAllReportReceipt = async () => {
    this.setState({ isLoading: true });
    let currentUser = JSON.parse(
      cryptr.decrypt(localStorage.getItem("440a24"))
    );
    this.setState({ userId: currentUser["id"] });
    let userId = currentUser["id"];
    let companyId = this.state.companyId;
    let response = await expenseReport.fetchAllReportReceipt(
      userId,
      companyId,
      this.state.pageLimit,
      this.state.pageNumber
    );
    if (response) {
      this.setState({ isLoading: false });
      this.setState({
        allReceipt: response.result,
        totalCount: response.count
      });
    } else {
      this.setState({ isLoading: false, totalCount: 0 });
    }
  };

  onSubmitHandler = event => {
    event.preventDefault();
  };
  onSelectHandler = option => {
    this.setState({ selected: option });
  };
  onChangeHandler = event => {
    this.setState({ searchText: event.target.value });
  };
  onSearchHandler = () => {
    //console.log(this.state.selected, this.state.searchText);
  };

  onFileBrowse = event => {

    const browseFiles = Array.from(event.target.files);
    this.setState({ browseAllImages: browseFiles });
    let allImages = [];
    let allBrowseImage = [];
    for (const key in browseFiles) {
      let imageType = event.target.files[key].type;
      const types = ["image/png", "image/jpeg"];
      if (types.includes(imageType)) {
        let fileName = browseFiles[key].name;
        if (fileName && fileName !== "item") {
          let browseImage = URL.createObjectURL(event.target.files[key]);
          allImages.push(fileName);
          allBrowseImage.push(browseImage);
        }
        this.setState({ imageTypeDiffrent: true });
      } else {
        // toast.error(`You can not uplaod ${imageType.split('/')[1]} file format`);
        this.setState({ imageTypeDiffrent: false });
      }
    }
    this.setState({ fileName: allImages, browseImage: allBrowseImage });
  };
  onFileUpload = async () => {
    let browsedImages = [...this.state.browseImage];
    if (browsedImages.length > 0) {
      if (this.state.imageTypeDiffrent) {
        $(".close").click();
        let formData = new FormData();
        let files = this.state.browseAllImages;
        files.forEach((file, i) => {
          formData.append("file", file);
        });

        let response = await expenseReport.uploadAvailableReceipt(
          formData,
          this.state.companyId,
          this.state.userId
        );
        if (response) {
          this.setState({ fileName: "", browseImage: [] });
          this.fetchAllReportReceipt();
          toast.success(response.message);
        }
      } else {
        toast.error("Please Select png and jpeg only");
        this.setState({ fileName: "", browseImage: [] });
      }
    } else {
      toast.error("Please Select images");
    }
  };
  onSelectExpenseHandler = value => {
    if (value !== this.state.deleteReceiptSelected) {
      this.setState({ deleteReceiptSelected: value });
    } else {
      this.setState({ deleteReceiptSelected: "" });
    }
  };
  onDeleteReciptHandler = async () => {
    let deleteReceiptSelected = this.state.deleteReceiptSelected;
    if (deleteReceiptSelected) {
      let response = await expenseReport.deleteExpenseReportReceipt(
        deleteReceiptSelected
      );
      if (response) {
        this.fetchAllReportReceipt();
        toast.success(response.message);
        this.setState({ deleteReceiptSelected: "" });
      }
    }
  };
  onPageChangeHandler = async page => {
    this.setState({ isLoading: true });
    this.setState({ pageNumber: page });
    let companyId = this.state.companyId;
    let response = await expenseReport.fetchAllReportReceipt(
      this.state.userId,
      companyId,
      this.state.pageLimit,
      page
    );
    if (response) {
      this.setState({ isLoading: false });
      this.setState({
        allReceipt: response.result,
        totalCount: response.count
      });
    } else {
      this.setState({
        allReceipt: [],
        totalCount: 0
      });
      this.setState({ isLoading: false });
    }
  };
  render() {
    const activeNav = this.props.location.pathname.split("/")[2];
    return (
      <div>
        <div className="p-padding">
          <ExpenseHeader activeNav={activeNav} />
          <div className="white-card">
            <div className="pad">
              <div className="table-container mx-0 less-pad">
                <ReceiptFilter
                  onSubmitHandler={this.onSubmitHandler}
                  onSelectHandler={this.onSelectHandler}
                  onChangeHandler={this.onChangeHandler}
                  selected={this.state.selected}
                  onSearchHandler={this.onSearchHandler}
                  onDeleteReciptHandler={this.onDeleteReciptHandler}
                  deleteReceiptSelected={this.state.deleteReceiptSelected}
                />
              </div>
              {this.state.isLoading === false ? (
                <ReceiptList
                  allReceipt={this.state.allReceipt}
                  onSelectExpenseHandler={this.onSelectExpenseHandler}
                  deleteReceiptSelected={this.state.deleteReceiptSelected}
                />
              ) : (
                <Loader />
              )}
              {this.state.totalCount <= 0 && this.state.isLoading === false && (
                <div className="text-center">
                  <img
                    width="200"
                    className="img-fluid"
                    alt="Fetching Receipts"
                    src="/assets/images/no_reports.svg"
                  />
                  <h4>
                    <b>Oh!</b>
                  </h4>
                  <p className="mb-3">There are no receipts to show.</p>
                  <button
                    type="button"
                    className="btn"
                    data-toggle="modal"
                    data-target="#uploadReceiptsModal"
                  >
                    New Receipt
                  </button>
                </div>
              )}
            </div>
            <div className="table-container">
              <div className="table-footer px-0">
                <div className="row">
                  <div className="col-12">
                    {this.state.totalCount > 0 && (
                      <Pagination
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={this.state.pageLimit}
                        totalItemsCount={this.state.totalCount}
                        pageRangeDisplayed={5}
                        onChange={this.onPageChangeHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="uploadReceiptsModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="uploadReceiptsLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <ReceiptImageModal
              onFileUpload={this.onFileUpload}
              onFileBrowse={this.onFileBrowse}
              browseImage={this.state.browseImage}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ReceiptsPage;
