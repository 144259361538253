import React from "react";
import DetailDropdownItem from "../../detailDropdownItem";

const allocationLeftSidebar = props => {
  return (
    <div className="expense-left">
      <DetailDropdownItem
        openAuditTrail={props.openAuditTrail}
      />
      <div className="scroll">
        <ul>
          {props.expenseEntryList
            ? props.expenseEntryList.map((list, index) => {
              return (
                <li
                  className={
                    props.selectExpenstEntryList === list.id ? "active" : ""
                  }
                  key={index}
                >
                  <div className="top">
                    <div className="right-side m-less-checkbox">
                      {list && list.validation &&
                        <i className="material-icons mr-2">info</i>
                      }
                      <label className="checkbox-container small checkbox-index">
                        <input type="checkbox"
                          checked={props.selectedExpenseEntry.includes(list.id)}
                          onChange={(e) => props.onSlectedExpenseEntry(list.id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="shape-name lg">
                      {/* <div className="left icon bg-success">
                          <i className="icon ion-md-car" />
                        </div> */}
                      <div className="right ml-0">
                        <h6 className="heading">
                          {list.expenseType.expenseTypeName}
                        </h6>
                        {list.entryForm.map((date, index) => {
                          return (
                            <p key={index} className="text">
                              {date.dataType === "date"
                                ? date.fieldValue
                                : ""}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>

                  {list.entryForm.map((amount, index) => {
                    let theadArray = [];
                    theadArray = list.entryForm.map((header) => header.columnName);
                    let AMOUNT = theadArray.indexOf("AMOUNT");

                    return (
                      amount.dataType === 'amount' && amount.columnName === 'AMOUNT' && (amount.fieldValue) ?
                        <div key={index} className="bottom">
                          <div className="right-side">
                            <p className="label">Requested</p>
                            <h6>
                              {list.currency}{" "}{list.entryForm[AMOUNT].fieldValue && parseFloat(list.entryForm[AMOUNT].fieldValue).toFixed(2)}
                              {list.currency !== list.baseCurrency && '/' + list.baseCurrency + ' ' + parseFloat(list.baseAmount).toFixed(2)}
                            </h6>
                            {/* <h6>
                              {list.currency !== list.baseCurrency && '/' + list.baseCurrency + parseFloat(list.baseAmount).toFixed(2)}
                            </h6> */}
                          </div>
                          <div >
                            <p className="label">{amount.labelName}</p>
                            <h6>{list.currency}{parseFloat(amount.fieldValue).toFixed(2)}</h6>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                        : '')
                  })}

                </li>
              );
            })
            : ""}
        </ul>
      </div>
      <div className="resp-btn-allocate">
        <ul className="bottom-amount">
          <li>
            <h6><span>Outstanding Advance</span>{" "}:{" "}{props.userBaseCurrency && props.userBaseCurrency}{" "}{props.cashAdvanceTotalAmount !== null ? parseFloat(props.cashAdvanceTotalAmount).toFixed(2) : '0.00'}</h6>
          </li>
          <li>
            <h6><span>Total
              Amount</span>{" "}:{" "}{props.userBaseCurrency && props.userBaseCurrency}{" "}{props.totalExpenseAmount !== null ? parseFloat(props.totalExpenseAmount).toFixed(2) : '0.00'}</h6>
          </li>
          <li>
            <h6><span>Total Requested</span>{" "}:{" "}{props.userBaseCurrency && props.userBaseCurrency}{" "}{props.totalRequestAmount !== null ? parseFloat(props.totalRequestAmount).toFixed(2) : '0.00'}</h6>
          </li>
        </ul>
        {/* {!props.isSubmitClick ?
          <div className="padx py-2">
            {props.editAllocation && props.editAllocation.length > 0 ?
              <NavLink
                onClick={props.onUpdateExpenseSubmitHandler}
                className="btn"
              >
                Update
            </NavLink>
              : <NavLink
                onClick={props.onSubmitHandler}
                className="btn btn-block"
              >
                Done
        </NavLink>}
          </div>
          : <div className="padx py-2">
            <NavLink
              onClick={props.onSubmitHandler}
              className="btn btn-block"
            >
              Please Wait...
          </NavLink>
          </div>
        } */}
      </div>
    </div>
  );
};

export default allocationLeftSidebar;
