import React from "react";
import Menu from "../menu";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import Loader from '../../../../../common/Loader';
import { Select } from "src/_components/common/_forms";
const WorkflowsPage = props => {
  let {
    displayedWorkflows,
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    selectedWorkflow,
    allGroups,
    allGroupsNames
  } = props.allState;

  let {
    handlePageChange,
    handleLimitChange,
    loading,
    handleToggleWorkflow,
    handleRemoveWorkflow,
    handleAddDuplicate,
    pageItemsList,
    handleSearch
  } = props;



  let selectedWorkflowStyle = {
    background: "#377DFF",
    color: "white"
  };
  let activeButtonClass = "";
  let disabledClass = "";
  let selectedWorkflowId = "";

  if (selectedWorkflow.length > 0) {
    selectedWorkflowId = selectedWorkflow[0];
  }
  if (selectedWorkflowId) {
    activeButtonClass = "btn";
  } else {
    activeButtonClass = "btn";
    disabledClass = "disabled"
  }

  return (
    <div>
      <div className="admin-tabs">
        <Menu />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">W</div>
            <div className="right">
              <h6 className="heading">Workflow </h6>
              <p className="text">The Workflow allows to design expense and cash advance workflows, manage the steps and rules associated with them.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      <div className="py-padding">
        <div className="table-container">
          <form>
            <div className="row">
              <div className="col-md-9">
                <div className="btn-group-margin">
                  <NavLink
                    to="/administration/company/workflows/workflow-step1"
                    className="btn"
                  >
                    New
                  </NavLink>
                  <NavLink
                    to={`/administration/company/workflows/workflow-step1/${selectedWorkflow[0]}`}
                    className="mr-2 pr-1"
                  >
                    <button
                      className={activeButtonClass}
                      disabled={disabledClass}
                    >
                      Modify
                    </button>
                  </NavLink>
                  <button type="button" className={activeButtonClass} disabled={disabledClass} onClick={handleAddDuplicate}>
                    Add Duplicate
                  </button>
                  {/* <button type="button" className={activeButtonClass} disabled={disabledClass} onClick={handleRemoveWorkflow} >
                    Remove
                  </button> */}
                </div>
              </div>
              <div className="col-md-3">
                <div className="search-custom">
                  <input
                    type="text"
                    onChange={e => handleSearch(e)}
                    name=""
                    className="form-control"
                    placeholder="Search"
                  />
                  <span>
                    <i className="material-icons">search</i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped table-selected">
              <thead>
                <tr>
                  <th>Workflow Name</th>
                  <th>Workflow type</th>
                  {/* <th>steps can be added by</th> */}
                  <th>editable by</th>
                </tr>
              </thead>
              <tbody>
                {(displayedWorkflows.length === 0) &&
                  <tr>
                    <td colSpan="3" className="text-center">
                      {(loading) ?
                        <Loader /> :
                        "No Records Found"}
                    </td>
                  </tr>
                }
                {!loading ? (
                  displayedWorkflows ? (
                    displayedWorkflows.map(workflow => {
                      return (
                        <tr
                          key={workflow.workflow_id}
                          onClick={() =>
                            handleToggleWorkflow(workflow.workflow_id)
                          }
                          className={
                            selectedWorkflowId === workflow.workflow_id
                              ? "active-tr"
                              : null
                          }
                        >
                          <td>{workflow.workflowName}</td>
                          <td>{workflow.workflowType?.master_value?.master_name_value}</td>
                          {/* <td>{workflow.stepsAddedBy}</td> */}
                          {/* <td>{workflow.editableBy.join(' ')}</td> */}
                          {/* <td>{(workflow.editableBy.map(item => item ?.itemName)).join(' ')}</td> */}
                          <td>{workflow.editableBy.map(item => {
                            return (
                              <span className="mr-2">{item.itemName}</span>
                            )
                          })}</td>
                        </tr>
                      );
                    })
                  ) : (
                      <tr>
                        <td colSpan="3" className="text-center">No Data Found</td>
                      </tr>
                    )
                ) : (
                    <tr>
                      <td colSpan="3" className="text-center">Loading...</td>
                    </tr>
                  )}
              </tbody>

            </table>
          </div>
          {(displayedWorkflows.length !== 0) &&
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      {pageItemsList && (
                        <Select
                          placeholder={itemsCountPerPage}
                          keyName={"items"}
                          options={pageItemsList}
                          controlFunc={e => handleLimitChange(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
export default WorkflowsPage;
