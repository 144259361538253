import React from "react";
const detailDropdownItem = props => {
  return (
    <>
      <div className="padx pb-2 pt-3 border-b resp-pady">
        <div className="row">
          <div className="col-8">
            <h6>Expense</h6>
          </div>
          <div className="col-4 text-right">
            {/* <h6>{props.totoalExpense}</h6> */}
          </div>
        </div>
      </div>
      <div className="padx py-2 resp-pady">
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              disabled={props.submitStatus !== "Pending Submission"}
              className="btn"
              data-toggle="modal"
              data-target="#itemisationExpenseAddModal"
            >
              Add Expense
            </button>
          </div>
          <div className="col-6 text-right">
            <div className="dropdown">
              <button
                type="button"
                className="btn dropdown-toggle"
                data-toggle="dropdown"
              >
                Details
              </button>
              <div className="dropdown-menu set-minwidth">
                <h6 className="px-3 text-secondary custom-details">Report</h6>
                {localStorage.getItem("permissionStatus") !== "employee-role"?
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#reportTotalModal1"
                  onClick={props.onClickTotalReportHandler1}
                >
                  User Info
                </button>
                :""}
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#reportTotalModal"
                  onClick={props.onClickTotalReportHandler}
                >
                  Totals
                </button>
                {/* <button
                  type="button"
                  className="dropdown-item"
                  onClick={e => props.openAuditTrail()}
                >
                  Audit Trail
                </button> */}
                <button
                  type="button"
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#approvalFlowModal"
                >
                  Approval Flow
                </button>
                {/* <button
                      type="button"
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#commentsModal"
                    >
                      Comments
                    </button> */}
                {props.submitStatus === "Pending Submission" && (
                  <>
                    <h6 className="px-3 pt-3 text-secondary custom-details fs-12">
                      Cash Advance
                    </h6>
                    <button
                      onClick={props.getCashAdvanceListHandler}
                      type="button"
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#cashAdvAvailableModal"
                    >
                      Available
                    </button>
                    <button
                      onClick={props.getAllAttachedCashAdvance}
                      type="button"
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#cashAdvReportModal"
                    >
                      Assigned
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default detailDropdownItem;
