import React, { Component } from 'react';
import { Route } from "react-router-dom"; 
import { RequestPage } from '.';
class PermissionHomePage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/administration/company/permission') {
            this.props.history.push('/administration/company/permission/list')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/administration/company/permission') {
            this.props.history.push('/administration/company/permission/list')
        }
    }
    render() {
        return (
            <div className="PermissionHomePage"> 
                <Route path="/administration/company/permission/list" component={RequestPage}></Route>  
            </div>
        )
    }
}
export default PermissionHomePage;