import React from "react";
import Pagination from "react-js-pagination";
import ReactSelect from "react-select";
//import { Select } from "../_common/_forms";
import { Select } from 'src/_components/common/_forms';
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import { NavLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';

const MidOfficeHistoryPage = (props) => {
  const {
    getDownloadHistory,
    handlePageChange,
    handleItemChange,
    handleDayChange,
    handleDayChange2,
    changeType,
    searchHandler
  } = props;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">E</div>
            <div className="right">
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h6 className="heading">Bookings</h6>
              <p className="text">This page will give you data of all bookings across the company</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-2">
                  <div className="btn-group-margin">
                    {props.typeList && (
                      <Select
                        placeholder={props.state.typeName}
                        keyName={"type"}
                        options={props.typeList}
                        myClass={"w-100"}
                        controlFunc={e =>
                          changeType(e)
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group custom labelup border-0" style={{ height: "34px", backgroundColor: "#f7f7f7" }}>
                    <DayPickerInput
                      placeholder='From Date'
                      formatDate={formatDate}
                      parseDate={parseDate}
                      numberOfMonths={2}
                      format="LL"
                      value={
                        props.state.fromDate
                          ? `${formatDate(
                            new Date(props.state.fromDate),
                            "LL",
                            "it"
                          )}`
                          : ""
                      }
                      dayPickerProps={{
                        locale: 'it',
                        localeUtils: MomentLocaleUtils,
                        disabledDays: { after: new Date() }
                      }}
                      onDayChange={date =>
                        handleDayChange(
                          date,
                          "fromDate"
                        )
                      }

                    />
                    <span className="icon-custom " style={{ top: 0 }}>
                      <i className="material-icons">calendar_today</i>
                    </span>
                    {/* <div style={{ fontSize: "12px", color: "#ff7979" }}>
                        {this.validator.message(
                          "Booking Date",
                          this.state.bookingDate,
                          "required"
                        )}
                      </div> */}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group custom labelup bg-light border-0" style={{ height: "34px", backgroundColor: "#f7f7f7" }}>
                    {/* <label>To Date</label> */}
                    <DayPickerInput
                      placeholder='To Date'
                      formatDate={formatDate}
                      parseDate={parseDate}
                      format="LL"
                      value={
                        props.state.toDate
                          ? `${formatDate(
                            new Date(props.state.toDate),
                            "LL",
                            "it"
                          )}`
                          : ""
                      }
                      dayPickerProps={{
                        locale: 'it',
                        localeUtils: MomentLocaleUtils,
                        disabledDays: { after: new Date() }
                      }}
                      onDayChange={date =>
                        handleDayChange2(
                          date,
                          "toDate"
                        )
                      }

                    />
                    <span className="icon-custom" style={{ top: 0 }}>
                      <i className="material-icons">calendar_today</i>
                    </span>

                  </div>
                </div>

                <div className="col-md-2">
                  <button type="button" className="btn"
                    data-toggle="tooltip" data-placement="bottom" title="Download history"
                    onClick={getDownloadHistory}>
                    <a href={props.state.downloadHistoryLink} download={props.state.downloadHistoryLink ? "download" : ''}>
                      <i class="material-icons">
                        cloud_download</i>
                    </a>
                  </button>
                </div>


                <div className="col-md-2 offset-2">
                  <div className="search-custom">
                    <input type="text" className="form-control" placeholder="Search"
                      onChange={(e) => searchHandler(e)} />
                    <span><i className="material-icons align-middle">search</i></span>
                  </div>
                </div>
              </div>
            </form>

            <div className="table-responsive">
              {
                (props.state.isLoading) ?
                  <div className="mx-auto text-center">
                    <img
                      width="200"
                      className="img-fluid "
                      alt="no flight"
                      src="/assets/images/loader.gif"
                    />
                    <p>Loading please wait...</p>
                  </div>
                  :
                  <table className="table table-striped">
                    {(props.state.bookingType === 'FLIGHT') ?
                      (<thead>
                        <tr>
                          <th>CURIO REF NO</th>
                          <th>TRAVELER NAME</th>
                          <th>TRAVELLING DATE</th>
                          <th>RBD</th>
                          <th>AIRLINE</th>
                          <th>SECTOR</th>
                          <th>PAYMENT TYPE</th>
                          <th>COMPANY</th>
                          <th>FARE TYPE</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      ) : (props.state.bookingType === 'HOTEL' ? (
                        <thead>
                          <tr>
                            <th>CURIO REF NO</th>
                            <th>NAME</th>
                            <th>HOTEL NAME</th>
                            <th>ROOM CATEGORY</th>
                            <th>TOTAL AMOUNT</th>
                            <th>BOOKING DATE</th>
                            <th>COMPANY</th>
                            <th>LOCATION</th>
                          </tr>
                        </thead>
                      ) : '')}
                    <tbody>
                      {
                        (props.state.bookingType === 'FLIGHT') &&
                        props.state.allTypeData && props.state.allTypeData.length > 0 && props.state.allTypeData.map((data, index) => {
                          return <tr key={index}>
                            <td className="text-primary"><NavLink to={`/administration/company/mid-office-history/history-details/${data.id}/${props.state.bookingType}`}>{data.tripId}</NavLink></td>
                            <td>{data.travellerName}</td>
                            <td>{data.travellingDate}</td>
                            <td className="text-primary"><NavLink to={`/administration/company/mid-office-history/history-details/${data.id}/${props.state.bookingType}`}>
                              {data.rbd.map((rbd, i) =>
                                (i === 0) ? '' + rbd : ', ' + rbd
                              )}
                            </NavLink>
                            </td>
                            <td> {data.airLine.map((airline, i) => (i === 0) ? '' + airline : ', ' + airline)}</td>
                            <td>{(data.flightData != undefined) ? data.flightData.flights.map((flight, i) => <p key={i}>{flight.flight_from + " - " + flight.flight_to + ', '}</p>
                            ) : ""}</td>
                            <td>{data.paymentType}</td>
                            <td>{data.company}</td>
                            <td>{data.fareType}</td>
                            <td>{data.statusType}</td>
                          </tr>
                        })
                      }
                    </tbody>
                    <tbody>
                      {
                        (props.state.bookingType === 'HOTEL') &&
                        props.state.allTypeData && props.state.allTypeData.length > 0 && props.state.allTypeData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-primary"><NavLink to={`/administration/company/mid-office-history/history-details/${item.id}/${props.state.bookingType}`}>{item.tripId}</NavLink></td>
                              <td>{item.travellerName}</td>
                              <td>{item.hotelData.hotel_name}</td>
                              <td>{item.hotelData.hotel_room_info.hotel_room_name}</td>
                              <td>
                                <NumberFormat
                                  value={(Math.floor(item.hotelData.hotel_room_info.hotel_total_price * 100) / 100)}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={item.hotelData.hotel_room_info.hotel_currency_code + ' '} />
                              </td>
                              <td>{item.bookingDate}</td>
                              <td>{item.company}</td>
                              <td>{item.hotelData.hotel_city}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
              }
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  {props.state.totalCount && (
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <Select
                            placeholder={props.state.limit}
                            keyName={"items"}
                            options={props.state.pageItemsList}
                            controlFunc={e => handleItemChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-9 text-right">
                  {props.state.totalCount && <Pagination
                    activePage={props.state.page}
                    itemsCountPerPage={props.state.limit}
                    totalItemsCount={props.state.totalCount}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
export default MidOfficeHistoryPage;
