import { observable, action, reaction } from 'mobx';
// import agent from '../agent';

class CommonStore {

  @observable appName = 'Curio';
  @observable token = localStorage.getItem('token');
  @observable appLoaded = false;

  @observable errors = undefined;
  constructor() {
    reaction(
      () => this.token,
      token => {
        if (token) {
          localStorage.setItem('token', token);
        } else {
          // localStorage.removeItem('token');
        }
      }
    );
  }

  @action setToken(token) {
    this.token = token;
  }

  @action setAppLoaded() {
    this.appLoaded = true;
  }

}

export default new CommonStore();
