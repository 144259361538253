import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';

import ItemizationWizardPage from './itemization-wizard-html';

import { expenseServices } from '../../../../../_services';
import { toast } from 'react-toastify';
class ItemizationWizard extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.validator = new SimpleReactValidator();
        this.state = {
            companyId: localStorage['440a28'],
        }
        this.loading = false
    }
    pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
    activePage = 1;
    itemsPerPage = 10;
    search = ''
    componentDidMount() {
        this.getItemization()
    }
    listItemization = []
    getItemization = async () => {
        this.listItemization = [];
        this.count = 0;
        this.choosedItem = ''
        var resp = await expenseServices.getItemization(this.activePage, this.itemsPerPage, this.search, this.state.companyId)
        if (resp) {
            this.count = resp.count
            this.listItemization = resp.result;
        }
        this.forceUpdate()
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSearch = (e) => {
        this.search = e.target.value;
        this.getItemization()
    }
    handleItemChange = (limit) => {
        this.activePage = 1;
        this.itemsPerPage = limit['items'];
        this.getItemization();
    }
    handlePageChange = (pageNumber) => {
        this.activePage = pageNumber
        this.getItemization()
    }
    handleStatusChange = async (data) => {
        let newStatus = !data.activeStatus;
        let payload = {
            activeStatus: newStatus
        }
        var resp = await expenseServices.statusExpenseItemization(data.id, payload)
        toast.info(resp.message)
        this.getItemization()
    }
    choosedItem = ''
    chooseEdit = (e) => {
        this.choosedItem = (e === this.choosedItem) ? '' : e
        this.forceUpdate()
    }
    removeItem = async (e) => {
        var resp = await expenseServices.deleteExpenseTypeItemization(this.choosedItem)
        if (resp) {
            toast.info(resp.message)
            this.getItemization()
        }
    }
    getData = () => {
        this.listItemization.forEach(item => {
            if (item.id === this.choosedItem) {
                this.setState(
                    { expenseTypeName: item.expenseTypeName }
                )
            }
        })
    }

    render() {
        return (
            <div>
                <ItemizationWizardPage
                    validator={this.validator}
                    state={this.state}
                    loading={this.loading}
                    handleChange={this.handleChange}
                    addSubmit={this.addSubmit}
                    listItemization={this.listItemization}
                    activePage={this.activePage}
                    itemsPerPage={this.itemsPerPage}
                    handleSearch={this.handleSearch}
                    pageItemsList={this.pageItemsList}
                    handleItemChange={this.handleItemChange}
                    count={this.count}
                    choosedItem={this.choosedItem}
                    chooseEdit={this.chooseEdit}
                    handleStatusChange={this.handleStatusChange}
                    removeItem={this.removeItem}
                    editSubmit={this.editSubmit}
                    close={this.close}
                    getData={this.getData}
                    handlePageChange={this.handlePageChange}
                />
            </div>
        )
    }
}
export default ItemizationWizard;