import { http } from "../_helpers";
import { config } from "../env";

const getSuggestCities = city => {
  return http
    .get(config.apiUrlTravel + `city/search?city=${city}`)
    .then(data => {
      return data ? data.result : "";
    });
};
const getFlightsList = data => {
  return http.post(config.apiUrlTravel + `flight/search`, data).then(data => {
    return data ? data : "";
  });
};
const getLocation = search_text => {
  let url = `https://maps.googleapis.com/maps/api/geocode/json?address==${search_text}&key=AIzaSyBWyyK5gkujBpxYNim55MUoSfo6cDWJM6w&libraries=places`;

  const requestOptions = {
    method: "GET"
  };
  return fetch(url, requestOptions).then(response => response.json());
};

const getHotelList = req => {
  return http.post(config.apiUrlTravel + `hotel/search`, req).then(data => {
    return data ? data : "";
  });
};
const getHotelDetails = (req) => {
  return http
    .post(
      config.apiUrlTravel +
        `hotel/info`,req
    )
    .then(data => {
      return data ? data.result : "";
    });
};
const getMultiCityFlight = req => {
  return http
    .post(`${config.apiUrlTravel}multi/city/flight/search`, req)
    .then(data => {
      // console.log(data)
      return data ? data.result : "";
    });
};
const getTripId = () =>{
  return http
    .get(`${config.apiUrlTravel}get/trip`)
    .then(data => {
      return data ? data.result : "";
    });
}
const getBaggage = (req)=>{
  return http
    .post(`${config.apiUrlTravel}flight/extra/info`, req)
    .then(data => {
      return data ? data.result : "";
    });
}
const getMyTrips = (search,page,limit) =>{
  return http
    .get(`${config.apiUrlTravel}all/mytrip?search=${search}&page=${page}&limit=${limit}`)
    .then(data => {
      return data ? data : "";
    });
}
const overallCount = () =>{
  return http
    .get(`${config.apiExpense}workflow/process/count`)
    .then(data => {
      return data ? data : "";
    });
}
const getWaitingForApprove = (search, page,limit,type) =>{
  return http
  .get(`${config.apiExpense}workflow/process?search=${search}&page=${page}&limit=${limit}&type=${type}`)
  .then(data => {
    return data ? data : "";
  });
}

const getWaitingForApproveById = (id) =>{
  return http
  .get(`${config.apiExpense}workflow/process?id=${id}`)
  .then(data => {
    return data ? data : "";
  });
}

const updateApproveList = (id,data) =>{
  return http
  .patch(`${config.apiExpense}workflow/process/update?workflowProcessId=${id}`,data)
  .then(data => {
    return data ? data : "";
  });
}

const getWaitingForUserById = (id) =>{
  return http
  .get(`${config.apiExpense}workflow/process/user?id=${id}`)
  .then(data => {
    return data ? data : "";
  });
}

const reCallTrip = (tripId,id) =>{
  return http
  .get(`${config.apiUrlTravel}recall-user-cart?TRIP_ID=${tripId}&workFlowProcessid=${id}`)
  .then(data => {
    return data ? data : "";
  });
}

const getInvoiceHotelInfo = (tripId,type,hotelId) =>{
  return http
  .get(`${config.apiMidOffice}journey/invoice?tripId=${tripId}&bookingType=${type}&hotelId=${hotelId}`)
  .then(data => {
    return data ? data : "";
  });
}
const getTicketHotelInfo = (tripId,type,hotelId) =>{
  return http
  .get(`${config.apiMidOffice}journey/eticket?tripId=${tripId}&bookingType=${type}&hotelId=${hotelId}`)
  .then(data => {
    return data ? data : "";
  });
}
const getInvoiceFlightInfo = (tripId,type,journeyId) =>{
  return http
  .get(`${config.apiMidOffice}journey/invoice?tripId=${tripId}&bookingType=${type}&journeyId=${journeyId}`)
  .then(data => {
    return data ? data : "";
  });
}
const getTicketFlightInfo = (tripId,type,journeyId) =>{
  return http
  .get(`${config.apiMidOffice}journey/eticket?tripId=${tripId}&bookingType=${type}&journeyId=${journeyId}`)
  .then(data => {
    return data ? data : "";
  });
}
const getPlaceFromPlaceId = (placeId) => {
  return http.get(`https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=AIzaSyBWyyK5gkujBpxYNim55MUoSfo6cDWJM6w`)
    .then(data => {
      return data ? data : ""
    });

}
export const bookingServices = {
  getSuggestCities,
  getFlightsList,
  getLocation,
  getHotelList,
  getHotelDetails,
  getMultiCityFlight,
  getTripId,
  getBaggage,
  getMyTrips,
  getWaitingForApprove,
  getWaitingForApproveById,
  updateApproveList,
  getWaitingForUserById,
  reCallTrip,
  getInvoiceHotelInfo,
  getTicketHotelInfo,
  getTicketFlightInfo,
  getInvoiceFlightInfo,
  overallCount,
  getPlaceFromPlaceId
};
