import React from "react";
import { NavLink } from "react-router-dom";
import Select from "react-select";
const PolicyStep1Page = props => {
  const {
    createPolicyData,
    formNameHandler,
    handleERF,
    handleEAF,
    handleEWF,
    handleEditableBy,
    handleAppliesTo,
    handleSC,
    missingReceiptAfidivate,
    handleCashAdvanceFormSelection,
    createPolicy,
    handleToggleChange
  } = props;

  // console.log(createPolicyData)
  let expenseReportForms = [];
  let expenseAllocationForms = [];
  let cashAdvanceForms = [];
  let workFlows = [];
  let editableBy = [];
  let appliesTo = [];

  if (createPolicyData && props.createPolicyData.expenseReportForms) {
    expenseReportForms = createPolicyData.expenseReportForms.map(el => {
      return { value: el.id, label: el.formName };
    });
    if (createPolicyData && props.createPolicyData.expenseAllocationForms.length > 0) {
      expenseAllocationForms = createPolicyData.expenseAllocationForms.map(
        el => {
          return { value: el.id, label: el.formName };
        }
      );
    }

    if (createPolicyData && createPolicyData.workFlows) {
      workFlows = createPolicyData.workFlows.map(el => {
        return {
          value: el.workflow_id,
          label: el.workflowName
        };
      });
    }
  }

  if (createPolicyData && createPolicyData.editableByList) {
    editableBy = createPolicyData.editableByList.map(el => {
      return {
        value: el.id,
        label: el.itemName
      };
    });
  }

  if (createPolicyData && createPolicyData.appliesToList) {
    appliesTo = createPolicyData.appliesToList.map(el => {
      return {
        value: el.id,
        label: el.itemName
      };
    });
  }
  if (createPolicyData && createPolicyData.cashAdvanceForms) {
    cashAdvanceForms = createPolicyData.cashAdvanceForms.map(el => {
      return {
        value: el.id,
        label: el.formName
      };
    });
  }
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">New Policy</h6>
              <p className="text">Add Request Policy</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <form>
              <div className="row">
                <div className="col-xl-5 col-lg-6">
                  <div className="form-group custom">
                    <input
                      className="form-control"
                      placeholder="&nbsp;"
                      type="text"
                      name="policyName"
                      onChange={formNameHandler}
                      value={createPolicyData.policyName || ""}
                    />
                    <label>Name <span className="text-danger">*</span></label>
                  </div>

                  <div className="form-group custom labelup">
                    <label>Request Report Form <span className="text-danger">*</span></label>
                    <Select
                      options={expenseReportForms}
                      name="selectedExpenseReportForm"
                      onChange={handleERF}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup">
                    <label>Request Allocation Form <span className="text-danger">*</span></label>
                    <Select
                      options={expenseAllocationForms}
                      name="selectedExpenseAllocationForm"
                      onChange={handleEAF}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup">
                    <label>Request Report Workflow <span className="text-danger">*</span></label>
                    <Select
                      options={workFlows}
                      name="selectedExpenseReportWorkFlow"
                      onChange={handleEWF}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  {/* Cash Advance */}

                  <div className="form-group custom labelup">
                    <label>Cash Advance <span className="text-danger">*</span></label>
                    <Select
                      options={cashAdvanceForms}
                      name="selectedExpenseReportWorkFlow"
                      onChange={handleCashAdvanceFormSelection}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup textarea">
                    <label>Editable By <span className="text-danger">*</span></label>
                    <Select
                      options={editableBy}
                      isMulti
                      name="editableBy"
                      onChange={handleEditableBy}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup textarea">
                    <label>Applies to <span className="text-danger">*</span></label>
                    <Select
                      options={appliesTo}
                      isMulti
                      name="appliesTo"
                      onChange={handleAppliesTo}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <h6 className="sub-heading">Travel Allowance</h6>
                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={e => handleToggleChange(e, 'travel')}
                        checked={createPolicyData.isTravelAllowance}
                        value={createPolicyData.isTravelAllowance}
                      />
                      <span className="slider round" />
                    </label>{" "}
                    <span>
                      {/* {createPolicyData.isTravelAllowance
                        ? "Active"
                        : "Inactive"} */}
                    </span>
                  </div>

                  <h6 className="sub-heading">Expense</h6>
                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={e => handleToggleChange(e, 'expense')}
                        checked={createPolicyData.isExpense}
                        value={createPolicyData.isExpense}
                      />
                      <span className="slider round" />
                    </label>{" "}
                    <span>
                      {/* {createPolicyData.isExpense
                        ? "Active"
                        : "Inactive"} */}
                    </span>
                  </div>

                  <h6 className="sub-heading">CashAdvance</h6>
                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={e => handleToggleChange(e, 'isCashAdvance')}
                        checked={createPolicyData.isCashAdvance}
                        value={createPolicyData.isCashAdvance}
                      />
                      <span className="slider round" />
                    </label>{" "}
                    <span>
                      {/* {createPolicyData.isCashAdvance
                        ? "Active"
                        : "Inactive"} */}
                    </span>
                  </div>


                  <h6 className="sub-heading">Is Violation</h6>
                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={e => handleToggleChange(e, 'isViolation')}
                        checked={createPolicyData.isViolation}
                        value={createPolicyData.isViolation}
                      />
                      <span className="slider round" />
                    </label>{" "}
                    <span>
                      {/* {createPolicyData.isCashAdvance
                        ? "Active"
                        : "Inactive"} */}
                    </span>
                  </div>


                  <div className="btn-group-margin mt-4">
                    <button
                      type="button"
                      className="btn"
                      onClick={createPolicy}
                    >
                      create Policy
                      </button>
                    <NavLink
                      to="/administration/travel/request/policy"
                      className="btn"
                    >
                      Cancel
                      </NavLink>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PolicyStep1Page;
