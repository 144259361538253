import Select from "react-select";
import { getValue } from "src/_components/common/lodsh";

const TrainClassType = props => (
    <div> 
        <div className="details to">
            <div className={(getValue(props.request, "train_class") === '' && props.isSubmitted) ? "details-margin not-valid" : "details-margin"}>
                <div className="d-table d-table__middle new-dropdown">
                    <label className="text-left m-label" htmlFor="search2">
                        <p className="label">Class</p>  
                            <Select
                                isSearchable={true}
                                options={[
                                    { value: "AC First Class", label: "AC First Class" },
                                    { value: "Sleeper", label: "Sleeper" }
                                ]}
                                defaultValue={{ value: getValue(props.request, "train_class"), label: getValue(props.request, "train_class") }} 
                                onChange={e => props.changeClass(e, "currency")}
                            /> 
                    </label>
                </div>
            </div>
        </div>
    </div>
)
export default TrainClassType;