import React from "react";
import FormItems from "./components/Forms";
const ListManagementPage = props => {
  const {
    title,
    className,
    error,
    state,
    handleChange,
    type,
    btnType,
    handleSubmit,
    listOfItems,
    chooseFolder,
    parentStack,
    handleDoubleClick,
    handleUpdateSubmit,
    detailView,
    breadCum,
    navNew,
    homeNav,
    sideNavOpt,
    pastedItems,
    parentId,
    pasteHere,
    destId,
    // handleSearch,
    isStatic
  } = props;
  return (
    <div>
      <div className="">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">List Management</h6>
              <p className="text">
                The List Management feature allows administrators to create,
                modify, or remove single and multiple-level lists.
              </p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="folder-container">
            <div className="heading">
              {type !== "" && type < 4 && (
                <FormItems
                  title={title}
                  className={className}
                  handleChange={handleChange}
                  error={error}
                  state={state}
                  handleUpdateSubmit={handleUpdateSubmit}
                  handleSubmit={handleSubmit}
                  btnType={btnType}
                  isStatic={isStatic}
                  type={type}
                />
              )}
              <div className="row">
                <div className="col-md-12">
                  {isStatic ? (
                    <div className="btn-group-margin">
                      {parentStack.length === 0 ? (
                        <button
                          onClick={e => btnType(1)}
                          className="btn hover-v"
                        >
                          New
                          <span className="hover-name right-top more">New</span>
                        </button>
                      ) : (
                          <button disabled className="btn">
                            New
                        </button>
                        )}
                      {parentStack.length !== 1 ? (
                        <button className="btn"
                          disabled
                        >
                          <i className="material-icons">add</i>
                        </button>
                      ) : (
                          <button
                            onClick={e => btnType(2)}
                            className="btn hover-v"
                          >
                            <i className="material-icons">add</i>
                            <span className="hover-name right-top more">Add</span>
                          </button>
                        )}
                      {parentStack.length !== 1 ? (
                        <button className="btn"
                          disabled>
                          <i className="material-icons">edit</i>
                        </button>
                      ) : (
                          <button
                            onClick={e => btnType(3)}
                            className="btn hover-v"
                          >
                            <i className="material-icons">edit</i>
                            <span className="hover-name right-top more">Edit</span>
                          </button>
                        )}
                      <button className="btn" disabled>
                        <i className="material-icons">delete</i>
                      </button>
                      <button className="btn" disabled>
                        <i className="material-icons">text_rotation_down</i>
                      </button>
                      <button className="btn" disabled>
                        <i className="material-icons">file_copy</i>
                      </button>
                    </div>
                  ) : (
                      <div className="btn-group-margin">
                        {parentStack.length === 0 ? (
                          <button
                            onClick={e => btnType(1)}
                            className="btn hover-v"
                          >
                            New
                            <span className="hover-name right-top more">New</span>
                          </button>
                        ) : (
                            <button disabled className="btn">
                              New
                            </button>
                          )}
                        {parentStack.length !== 1 ? (
                          <button className="btn" disabled>
                            <i className="material-icons">add</i>
                          </button>
                        ) : (
                            <button
                              onClick={e => btnType(2)}
                              className="btn hover-v"
                            >
                              <i className="material-icons">add</i>
                              <span className="hover-name right-top more">Add</span>
                            </button>
                          )}
                        {parentStack.length !== 1 ? (
                          <button className="btn" disabled>
                            <i className="material-icons">edit</i>
                          </button>
                        ) : (
                            <button
                              onClick={e => btnType(3)}
                              className="btn hover-v"
                            >
                              <i className="material-icons">edit</i>
                              <span className="hover-name right-top more">Edit</span>
                            </button>
                          )}
                        {parentStack.length !== 1 ? (
                          <button className="btn" disabled>
                            <i className="material-icons">delete</i>
                          </button>
                        ) : (
                            <button
                              onClick={e => btnType(4)}
                              className="btn hover-v"
                            >
                              <i className="material-icons">delete</i>
                              <span className="hover-name right-top more">Delete</span>
                            </button>
                          )}
                        {parentStack.length < 1 ? (
                          <button className="btn" disabled>
                            <i className="material-icons">text_rotation_down</i>
                          </button>
                        ) : parentStack.length === listOfItems.length &&
                          parentId === "" ? (
                              <button className="btn" disabled>
                                <i className="material-icons">text_rotation_down</i>
                              </button>
                            ) : (
                              <button
                                onClick={e => btnType(5)}
                                className="btn hover-v"
                              >
                                <i className="material-icons">text_rotation_down</i>
                                <span className="hover-name right-top more">Cut</span>
                              </button>
                            )}
                        {parentStack.length < 1 ? (
                          <button className="btn" disabled>
                            <i className="material-icons">file_copy</i>
                          </button>
                        ) : parentStack.length === listOfItems.length &&
                          parentId === "" ? (
                              <button className="btn" disabled>
                                <i className="material-icons">file_copy</i>
                              </button>
                            ) : (
                              <button
                                onClick={e => btnType(6)}
                                className="btn hover-v"
                              >
                                <i className="material-icons">file_copy</i>
                                <span className="hover-name right-top more">Copy</span>
                              </button>
                            )}
                      </div>
                    )}
                </div>
                {/* <div className="col-md-4 offset-md-2">
                  <div className="search-custom">
                    <input
                      type="text"
                      name=""
                      onChange={e => handleSearch(e)}
                      className="form-control"
                      placeholder="Search"
                    />
                    <span><i className="material-icons">search</i></span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="path">
              <button className="btn btn-nothing" onClick={homeNav}>
                <i className="material-icons">home</i>
              </button>
              {breadCum &&
                breadCum.map((item, key) => {
                  return (
                    <button
                      key={key}
                      onClick={e => navNew(item, key)}
                      className="btn btn-nothing"
                    >
                      <img src="/assets/images/subfolder.svg" alt="" />
                      <span>
                        {item.itemName} ({item.itemCode})
                      </span>
                    </button>
                  );
                })}
            </div>
            <div className="folder-list">
              <div className="row">
                <div className="col-8">
                  <div className="column-reset">
                    {listOfItems.length === 0 && (
                      <p className="text-center mt-3">No Items Found</p>
                    )}
                    {listOfItems &&
                      listOfItems.map((item, key) => {
                        return (
                          <div
                            className={
                              parentStack.indexOf(item.id) === -1
                                ? "folder"
                                : "folder selected"
                            }
                            onDoubleClick={e =>
                              handleDoubleClick(item.id, item.isFolder, item)
                            }
                            onClick={e => chooseFolder(item.id, item)}
                            key={key}
                          >
                            <img
                              src={
                                item.isFolder && !item.isStatic
                                  ? "/assets/images/folder.svg"
                                  : item.isStatic
                                    ? "/assets/images/folder_locked.svg"
                                    : "/assets/images/item.svg"
                              }
                              alt=""
                            />
                            <p className="name">{item.itemName}</p>
                            <p className="count">({item.itemCode})</p>
                          </div>
                        );
                      })}
                    {pastedItems.length > 0 && (
                      <div className="buttons-set">
                        <button className="btn">
                          {pastedItems.length} Item(s) in Clipboard
                        </button>
                        {destId !== parentId && (
                          <button
                            onClick={pasteHere}
                            className="btn"
                          >
                            Paste here
                          </button>
                        )}
                        <button className="btn">
                          cancel
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="folder-info">
                    {!detailView && (
                      <div className="empty">
                        <span>
                          <i className="material-icons">close</i>
                        </span>
                        Nothing Selected
                      </div>
                    )}

                    {listOfItems.length !== 0 && detailView && (
                      <div>
                        <div className="folder-heading">
                          <img
                            src={
                              detailView.children.length !== 0
                                ? "/assets/images/folder.svg"
                                : "/assets/images/item.svg"
                            }
                            alt=""
                          />
                          <div className="right">
                            <h6 className="heading">{detailView.itemName}</h6>
                            <p>ItemCode : {detailView.itemCode}</p>
                            {detailView.children.length !== 0 && (
                              <p className="text">Parent Folder</p>
                            )}
                            {/* {detailView.children.length === 0 && (
                              <p className="text mb-2">
                                Item Code {detailView.itemCode}
                              </p>
                            )} */}
                          </div>
                        </div>
                        <div className="custom-dropdown">
                          {detailView.children.length !== 0 && (
                            <button className="btn btn-nothing">
                              Children
                            </button>
                          )}
                          <div className="custom-dropdown-inner white d-block">
                            {detailView.children.map((items, key) => {
                              return (
                                <div key={key} className="folder-heading">
                                  <div>
                                    <img
                                      src={
                                        items.isFolder
                                          ? "/assets/images/folder.svg"
                                          : "/assets/images/item.svg"
                                      }
                                      alt=""
                                    />
                                    <div className="right">
                                      {items.isFolder ? (
                                        <h6
                                          onClick={e =>
                                            sideNavOpt(
                                              items.id,
                                              items.isFolder,
                                              items,
                                              detailView
                                            )
                                          }
                                          className="heading cursor-pointer"
                                        >
                                          {items.itemName}
                                        </h6>
                                      ) : (
                                          <h6 className="heading cursor-pointer">
                                            {items.itemName}
                                          </h6>
                                        )}
                                      {items.children.length !== 0 && (
                                        <>
                                        <p> ItemCode : {items.itemCode} </p>
                                        <p className="text">
                                          {items.children.length} Items{" "}
                                        </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListManagementPage;
