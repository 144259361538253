import React, { Component } from 'react';
import BookingHomeCardPage from '../home-cards';
import BookingMenuPage from '../../components/booking-menu';
import FlightBookingPage from './flight-booking-page';
class FlightPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div className="p-padding">
                <div className="row">
                    <div className="offset-xl-1 col-xl-10">
                        <BookingMenuPage />
                        <FlightBookingPage />
                        <BookingHomeCardPage />
                    </div>
                </div>
            </div>
        )
    }
}
export default FlightPage