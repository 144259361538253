import MasterRole from './master-roles';
import MasterPermissionType from './permission-type';
import MasterPermissionList from './permission-list';
import MasterMenuManage from './menu-creation';
import  PageNotFoundPage  from '../../page-not-found/page-not-found-html'; 
const masterRoutes = [  
    // master router not working 
    { path: '/master/roles', exact:true,name:'MasterRole',component:MasterRole},
    { path: '/master/permission-list', exact:true, name:'MasterPermission',component:MasterPermissionList},
    { path: '/master/permission-type', exact:true,name:'MasterPermissionType',component:MasterPermissionType},
    { path: '/master/manage-menu', exact:true,name:'MasterMenuManage',component:MasterMenuManage},
    { path: '**', exact:true, name: '', component: PageNotFoundPage },
];
export default masterRoutes;