import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import CompanyNotePage from './company-note';
import CompanyNoteUpdatePage from './company-note-update';


class CompanyNoteMain extends Component {
    constructor(props) {
        super(props);
        if (props.location.pathname === "/administration/company/company-note") {
            this.props.history.push("/administration/company/company-note/list");
        } else {
            this.props.history.push(props.location.pathname);
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === "/administration/company/company-note") {
            this.props.history.push("/administration/company/company-note/list");
        }
    }
    render() {
        return (
            <div>
                <Switch>
                    <Route
                        path="/administration/company/company-note/list"
                        component={CompanyNotePage}
                    />
                    <Route
                        path="/administration/company/company-note/update/:id"
                        component={CompanyNoteUpdatePage}
                    />
                </Switch>
            </div>
        )
    }
}
export default CompanyNoteMain;