import React, { Component } from 'react';
import ToggleSlider from 'src/_components/common/ToggleSlider/ToggleSlider';
import Loader from 'src/_components/common/Loader';

export default class WorkflowStepTable extends Component {
  render() {
    const { loading, typeofname } = this.props;
    let totalNumberOfSteps = 0;
    if (this.props.data.length) {
      totalNumberOfSteps = this.props.data.length;
    }
    return (
      <div className="table-responsive">
        <table className="table table-striped table-selected">
          <thead>
            <tr>
              <th>Step Name</th>
              <th>Role</th>
              <th>Display Order</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {(typeofname === 'Travel') ?
                  "Itinerary Submitted"
                  : (typeofname === 'Expense') ?
                    "Report Submitted"
                    : "Request Submitted"
                }
              </td>
              <td>{(typeofname === 'Travel') ?
                "System"
                : (typeofname === 'Expense') ?
                  "System"
                  : "System"
              }</td>
              <td></td>
              <td>
                <label className="switch">
                  <input type="checkbox" checked />
                  <span className="slider round"></span>
                </label>
              </td>
            </tr>
            {totalNumberOfSteps === 0 &&
              <tr>
                <td colSpan="4" className="text-center">
                  {(loading) ?
                    <Loader />
                    : "No Records Found"}
                </td>
              </tr>}
            {
              this.props.data && this.props.data.map((step, index) => {
                return (
                  <tr key={step.workflowStep_id} className={
                    this.props.selectedStep.some(i => i.id === step.id)
                      ? "active-tr"
                      : null
                  } >
                    <td onClick={e => this.props.stepSelectHandler(step)} >{step.workflowStepName}</td>
                    <td>{step.worlkflowRole.master_value.master_name_value}</td>
                    <td>
                      <div className="btn-group-margin">
                        <button disabled={step.workflowOrderNo == 1} className="btn btn-swap" onClick={e => this.props.moveStep(step, 'up')} >
                          <i className="material-icons">keyboard_arrow_up</i>
                        </button>
                        <button disabled={step.workflowOrderNo == totalNumberOfSteps} className="btn btn-swap" onClick={e => this.props.moveStep(step, 'down')}>
                          <i className="material-icons">keyboard_arrow_down</i>
                        </button>
                      </div>

                    </td>
                    <td>
                      <ToggleSlider
                        toggleSliderHandler={e => this.props.stepStatusToggleHandler(step)}
                        checked={step.workflowStatus}
                        value={step.workflowStatus}
                      />
                    </td>
                  </tr>
                )
              })
            }
            <tr>
              <td>
                {(typeofname === 'Travel') ?
                  "System Processing"
                  : (typeofname === 'Expense') ?
                    "Approval for Processing"
                    : "Approval for Issuance"
                }
              </td>
              <td>
                {(typeofname === 'Travel') ?
                  "System"
                  : (typeofname === 'Expense') ?
                    "Expense Processor"
                    : "Cash Advance Admin"
                }
              </td>
              <td></td>
              <td>
                <label className="switch">
                  <input type="checkbox" checked />
                  <span className="slider round"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
