import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
class CashIssuedForTripsPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <div className="p-padding">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="main-box">
                                <div className="bg-light heading">
                                    <h6>CASH FOR TRIP</h6>
                                </div>
                                <div className="employee-card">
                                    <div className="employee-details">
                                        <div className="float-left">
                                            <div className="left-icon">
                                            <i className="material-icons">person</i>
                                            </div>
                                        </div>
                                        <div className="float-left right-text">
                                            <h6>Employee Name</h6>
                                            <h6>Francine Nelson</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-details">
                                        <div className="float-left">
                                            <div className="left-icon">
                                            <i className="material-icons">money</i>
                                            </div>
                                        </div>
                                        <div className="float-left right-text">
                                            <h6>Amount Requested</h6>
                                            <h6>$200.00</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-details">
                                        <div className="float-left">
                                            <div className="left-icon">
                                            <i className="material-icons">money</i>
                                            </div>
                                        </div>
                                        <div className="float-left right-text">
                                            <h6>Available Balance</h6>
                                            <h6>$185.00</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-details">
                                        <div className="float-left">
                                            <div className="left-icon">
                                            <i className="material-icons">calendar_today</i>
                                            </div>
                                        </div>
                                        <div className="float-left right-text">
                                            <h6>Date Issued</h6>
                                            <h6>21/12/2018</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="main-box">
                                <div className="bg-light heading">
                                    <h6>AUDIT TRAIL</h6>
                                </div>
                                <div className="employee-profile-card">
                                    <div className="employee-info">
                                        <div className="float-left">
                                            <div className="float-left">
                                                <div className="left-icon">
                                                    <img src="/assets/images/booking/user_14.png" alt="Pic" />
                                                </div>
                                            </div>
                                            <div className="float-left right-text">
                                                <h6>Marie Baker</h6>
                                                <h6>Level 1 Approver</h6>
                                                <div className="comment-box">
                                                    <h6>COMMENT</h6>
                                                    <p className="mb-0">In at iaculis lorem. Praesent tempor dictum Duis eu nisl ut ligula cursus. </p>
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="float-right">
                                            <h6 className="text-danger ml-auto">Sent Back</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-info">
                                        <div className="float-left">
                                            <div className="float-left">
                                                <div className="left-icon">
                                                    <img src="/assets/images/booking/user_14.png" alt="Pic" />
                                                </div>
                                            </div>
                                            <div className="float-left right-text">
                                                <h6>Mary Sanders</h6>
                                                <h6>Employee</h6>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="float-right">
                                            <h6 className="text-warning ml-auto">Resubmitted</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-info">
                                        <div className="float-left">
                                            <div className="float-left">
                                                <div className="left-icon">
                                                    <img src="/assets/images/booking/user_14.png" alt="Pic" />
                                                </div>
                                            </div>
                                            <div className="float-left right-text">
                                                <h6>Mary Sanders</h6>
                                                <h6>Employee</h6>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="float-right">
                                            <h6 className="text-warning ml-auto">Resubmitted</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-info">
                                        <div className="float-left">
                                            <div className="float-left">
                                                <div className="left-icon">
                                                    <img src="/assets/images/booking/user_14.png" alt="Pic" />
                                                </div>
                                            </div>
                                            <div className="float-left right-text">
                                                <h6>Mary Sanders</h6>
                                                <h6>Employee</h6>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="float-right">
                                            <h6 className="text-warning ml-auto">Resubmitted</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-info">
                                        <div className="float-left">
                                            <div className="float-left">
                                                <div className="left-icon">
                                                    <img src="/assets/images/booking/user_14.png" alt="Pic" />
                                                </div>
                                            </div>
                                            <div className="float-left right-text">
                                                <h6>Mary Sanders</h6>
                                                <h6>Employee</h6>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="float-right">
                                            <h6 className="text-warning ml-auto">Resubmitted</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-padding pt-0">
                    <div className="main-box">
                        <div className="bg-light heading">
                            <h6>EXPENSE DETAILS</h6>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped mb-0">
                                <thead>
                                    <tr>
                                        <th>DATE</th>
                                        <th>REPORT NAME</th>
                                        <th>EXPENSE TYPE</th>
                                        <th>AMOUNT CLAIMED</th>
                                        <th>AMOUNT UTILIZED</th>
                                        <th>BALANCE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>18/12/2018</td>
                                        <td>Starting Balance</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>$200.00</td>
                                    </tr>
                                    <tr>
                                        <td>18/12/2018</td>
                                        <td>Returned to Company</td>
                                        <td></td>
                                        <td></td>
                                        <td>$50.00</td>
                                        <td>$150.00</td>
                                    </tr>
                                    <tr>
                                        <td>18/12/2018</td>
                                        <td>Cash for Trip</td>
                                        <td>Taxi</td>
                                        <td>$20.00</td>
                                        <td>$20.00</td>
                                        <td>$130.00</td>
                                    </tr>
                                    <tr>
                                        <td>18/12/2018</td>
                                        <td>Cash for Trip</td>
                                        <td>Taxi</td>
                                        <td>$20.00</td>
                                        <td>$20.00</td>
                                        <td>$130.00</td>
                                    </tr>
                                    <tr>
                                        <td>18/12/2018</td>
                                        <td>Cash for Trip</td>
                                        <td>Taxi</td>
                                        <td>$20.00</td>
                                        <td>$20.00</td>
                                        <td>$130.00</td>
                                    </tr>
                                    <tr>
                                        <td>18/12/2018</td>
                                        <td>Cash for Trip</td>
                                        <td>Taxi</td>
                                        <td>$20.00</td>
                                        <td>$20.00</td>
                                        <td>$130.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div >
                <div className="p-padding pt-0">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="white-card shadow-light">
                                <div className="grey-head">
                                    <h6 className="sub-heading text-uppercase">Comments</h6>
                                </div>
                                <div className="bg-light m-15  shadow-light">
                                    <div className="pad-sm">
                                        <div className="profile-image">
                                            <img src="/assets/images/booking/user_11.png" className="img-fluid" alt="">
                                            </img>
                                        </div>
                                        <div className="profile-data ml-3">
                                            <p className="mb-1"><b>Christine Willis</b></p>
                                            <p className="muted-p">Approval Manager - 18th Dec 2018 - 10:30PM</p>
                                            <p className="mb-0mb-0">In at iaculis lorem. Present tempor dictum tellus ut molestie.Sed sed ullamcorper lorem Duis eu nisl ut ligula cursus molestie at at dolor.Nulla est justo, pellentesque vel lectus.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-light m-15  shadow-light">
                                    <div className="pad-sm">
                                        <div className="profile-image">
                                            <img src="/assets/images/booking/user_11.png" className="img-fluid" alt="">
                                            </img>
                                        </div>
                                        <div className="profile-data ml-3">
                                            <p className="mb-1"><b>Roy Fox</b></p>
                                            <p className="muted-p">Employee - 18th Dec 2018 - 10:30PM</p>
                                            <p className="mb-0mb-0">In at iaculis lorem. Praesent tempor dictum tellus ut molestie. Sed sed ullamcorper. Duis eu nisl ut ligula cursus molestie at at dolor.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-light m-15  shadow-light">
                                    <div className="pad-sm">
                                        <div className="profile-image">
                                            <img src="/assets/images/booking/user_11.png" className="img-fluid" alt="">
                                            </img>
                                        </div>
                                        <div className="profile-data ml-3">
                                            <p className="mb-1"><b>Christine Willis</b></p>
                                            <p className="muted-p">Approval Manager - 18th Dec 2018 - 10:30PM</p>
                                            <p className="mb-0mb-0">In at iaculis lorem. Present tempor dictum tellus ut molestie.Sed sed ullamcorper lorem Duis eu nisl ut ligula cursus molestie at at dolor.Nulla est justo, pellentesque vel lectus.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="no-show pad-sm pt-0">
                                    <div className="l-text"><input type="text" className="form-control" placeholder="Comment" /></div>
                                    <span className="bg-primary text-white">Post</span>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="white-card shadow-light">
                                <div className="grey-head">
                                    <h6 className="sub-heading text-uppercase">APPROVER FLOW</h6>
                                </div>
                                <div className="pad-sm">
                                    <div className="pad-sm approver-flow">
                                        <div className="float-left approver-image">
                                            <img src="/assets/images/booking/user_11.png" className="img-fluid" alt="">
                                            </img>
                                        </div>
                                        <div className="float-left approver-data ml-3">
                                            <p className=" unmuted-p  lighter-p text-uppercase">Level 1</p>
                                            <p className="font-weight-bold"><b>Marie Baker</b></p>

                                        </div>
                                        <div className="float-right">
                                            <button className="btn change-btn btn-light">
                                                CHANGE
                                        </button>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                                <div className="pad-sm pt-0">
                                    <div className="pad-sm approver-flow">
                                        <div className="float-left approver-image">
                                            <img src="/assets/images/booking/user_11.png" className="img-fluid" alt="">
                                            </img>
                                        </div>
                                        <div className="float-left approver-data ml-3">
                                            <p className=" unmuted-p  lighter-p text-uppercase">Level 2</p>
                                            <p className="font-weight-bold"><b>Marie Baker</b></p>

                                        </div>
                                        <div className="float-right">
                                            <button className="btn change-btn btn-light">
                                                CHANGE
                                        </button>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                                <div className="pad-sm pt-0">
                                    <div className="pad-sm approver-flow">
                                        <div className="float-left approver-image">
                                            <span className="add-icon">
                                            <i className="material-icons">add</i>
                                            </span>
                                        </div>
                                        <div className="float-left approver-data ml-3">
                                            <p className=" unmuted-p  lighter-p text-uppercase">Level 3</p>
                                            <p className="font-weight-bold text-primary"><b>ADD APPROVER</b></p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        )
    }
}
export default CashIssuedForTripsPage