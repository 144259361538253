import Select from "react-select";
import { getValue } from "src/_components/common/lodsh";

const TrainCurrency = props => (
    <div>
        <div className="details to">  
            <div className={(getValue(props.request, "train_booking_currency") === '' && props.isSubmitted) ? "details-margin not-valid" : "details-margin"}>
                <div className="d-table d-table__middle new-dropdown">
                    <label className="text-left m-label" htmlFor="search2">
                        <p className="label">Currency</p>
                        {props.listOfCurrency && (
                            <Select
                                isSearchable={true}
                                options={props.listOfCurrency}
                                placeholder={getValue(props.request, "train_booking_currency")}
                                defaultValue={{ value: getValue(props.request, "train_booking_currency"), label: getValue(props.request, "train_booking_currency") }}
                                onChange={e => props.changeCurrency(e, "currency")}
                            />
                        )}
                    </label>
                </div>
            </div>
        </div>
    </div>
)
export default TrainCurrency;