import React from "react";
import { NavLink } from "react-router-dom";
import ConfigRulesMenu from "../config-rules-menu";
import Select from 'react-select';
import { Input } from 'src/_components/common/_forms';

const CarConfigStep1Page = props => {
  const { formValidator, carId } = props;
  let carTypeOptions = [];
  let countryTypeOptions = [];
  let currencyTypeOptions = [];

  props.carTypesList && props.carTypesList.forEach((item) => {
    carTypeOptions.push({ label: item.itemName, value: item.id });
  });
  props.countryTypesList && props.countryTypesList.forEach((item) => {
    countryTypeOptions.push({ label: item.name, value: item.id });
  });
  props.currencyTypesList && props.currencyTypesList.forEach((item) => {
    currencyTypeOptions.push({ label: item.itemName, value: item.id });
  });

  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">A</div>
          <div className="right">
            <h6 className="heading">Add Car Configuration</h6>
            <p className="text">This page is used to maintain mileage amount defined by the company policy.</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <ConfigRulesMenu />
          <div className="">
            <div className="row">
              <div className="col-md-4">
                <form>
                  <div className="form-group custom">
                    <Input
                      label="Configuaration Name"
                      type="text"
                      imp="true"
                      name="configName"
                      value={props.configName}
                      error={formValidator.message('Config Name', props.configName, 'required')}
                      controlFunc={(e) => props.onNameChangeHandler(e)}
                    />
                  </div>
                  <div className="form-group custom labelup">
                    <label>Car Type <span className="text-danger">*</span></label>
                    <Select
                      value={props.carTypeId}
                      options={carTypeOptions}
                      onChange={(e) => props.onCarTypeChangeHandler(e)}
                    />
                  </div>
                  <div className="form-group custom labelup textarea">
                    <label>Editable By <span className="text-danger">*</span></label>
                    <Select
                      name="editedBy"
                      isMulti
                      isSearchable
                      value={props.editableBy}
                      options={props.editableTypeOptions}
                      onChange={(e) => props.onEditableByChangeHandler(e)}
                    />
                  </div>
                  <div className="form-group custom labelup textarea">
                    <label>Applies To <span className="text-danger">*</span></label>
                    <Select
                      name="appliesTo"
                      isMulti
                      value={props.appliesTo}
                      onChange={(e) => props.onAppliesToChangeHandler(e)}
                      options={props.editableTypeOptions}
                    />
                  </div>
                  <div className="form-group custom labelup">
                    <label>Country <span className="text-danger">*</span></label>
                    <Select
                      value={props.country}
                      options={countryTypeOptions}
                      onChange={props.onCountryTypeChangeHandler}
                    />
                  </div>
                  <div className="form-group custom labelup">
                    <label>Currency <span className="text-danger">*</span></label>
                    <Select
                      isSearchable={true}
                      value={props.currency}
                      onChange={props.onCurrencyTypeChangeHandler}
                      options={currencyTypeOptions}
                    />
                  </div>
                  <div className="btn-group-margin">
                    <button type="button"
                      to={(formValidator.allValid) ? `/administration/expense/car-config/config-step2/` : formValidator.showMessages()}
                      onClick={(e) => props.onSubmitForm1(e)}
                      className="btn btn-border-success lg-padding"
                    >
                      Next
                    </button>
                    <NavLink
                      to="/administration/expense/car-config/car-configuration"
                      className="btn btn-border-basic lg-padding"
                    >
                      Cancel
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CarConfigStep1Page;
