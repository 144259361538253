import React, { Component } from "react";
import AccountStructurePage from "./account-structure-html";
import SimpleReactValidator from "simple-react-validator";
import { expenseServices } from "../../../../../../_services";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../../_constants/alert.constants";
import $ from "jquery";
import uuid from "uuid";
class AccountStructure extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    window.scrollTo({ Top: 0, behavior: "smooth" });
    this.state = {
      ledgerName: "",
      ledgerCode: "",
      id: "",
      companyId: localStorage["440a28"],
      apiHierarchyData: [],
      hierarchyLevel: [],
      hierarchyLedgerArray: [],
      fieldData: {},
      hierarchyGetData: [],
      hierarchyId: "",
      isLoading: true
    };
  }

  search = "";
  componentDidMount() {
    this.getListAccountStruture();
    this.getListHierarchy();
  }

  listOfAccountStruture = [];
  getListAccountStruture = async e => {
    this.listOfAccountStruture = [];
    var resp = await expenseServices.getAccountStructure(
      this.search,
      this.state.companyId
    );
    this.setState({ isLoading: false });
    if (resp) {
      this.listOfAccountStruture = resp.result;
      this.forceUpdate();
      this.setState({
        hierarchyGetData: resp.result
      });
    } else {
      this.listOfAccountStruture = [];
      this.forceUpdate();
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSearch = e => {
    this.search = e.target.value;
    this.getListAccountStruture();
  };
  customId = "";
  getData = e => {
    this.listOfAccountStruture.forEach(item => {
      if (item.id === this.customId) {
        this.setState({
          ledgerName: item.ledgerName,
          ledgerCode: item.ledgerCode,
          id: item.id
        });
      }
    });
  };
  removeItem = async () => {
    var resp = await expenseServices.deleteAccountStructure(this.customId);
    if (resp) {
      toast.info(alertConstants.DELETED_SUCCESSFULLY);
      this.customId = "";
      this.getListAccountStruture();
    }
  };

  modifyHierarchy = async () => {
    this.state.hierarchyGetData.forEach(async item => {
      if (item.id === this.customId) {
        let apiHierarchyData = this.state.apiHierarchyData;
        const fields = await Promise.all(
          apiHierarchyData.map(data => this.getField(data.id))
        );
        let myResp = item.hierarchyData;
        let fieldData = { ...this.state.fieldData };
        let newHeader = myResp.map(item => {
          let curr = apiHierarchyData.find(
            expression => expression.id === item.hierarchyLevel
          );
          let test = fieldData[curr.id].find(
            field => field.id === item.hierarchySegment
          );

          let modifiedLedger = {
            id: item.id,
            formHeader: curr,
            formFieldName: test,
            order: item.order
          };
          return modifiedLedger;
        });
        this.setState({
          hierarchyLedgerArray: newHeader
        });
      }
    });
  };

  storeId = e => {
    if (this.customId === e) {
      this.customId = "";
    } else {
      this.customId = e;
    }
    this.forceUpdate();
  };

  storeHierarchyId = e => {
    if (this.state.hierarchyId === e) {
      this.setState({ hierarchyId: "" });
    } else {
      this.setState({ hierarchyId: e });
    }
  };

  addSubmit = async e => {
    // passing and object as a parameter in addAccountStructure function
    let myNewList = {
      ledgerName: this.state.ledgerName,
      ledgerCode: this.state.ledgerCode,
      id: this.state.id,
      companyId: this.state.companyId
    };
    e.preventDefault();
    if (this.validator.allValid()) {
      var resp = await expenseServices.addAccountStructure(myNewList);
      if (resp) {
        toast.success(alertConstants.CREATED_SUCCESSFULLY);
        this.cancelAll();
        this.validator.hideMessages();
        $(".close").click();
        this.forceUpdate();
        this.getListAccountStruture();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  updateSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let updateData = {
        ledgerName: this.state.ledgerName,
        ledgerCode: this.state.ledgerCode,
        id: this.state.id,
        companyId: this.state.companyId
      };
      var resp = await expenseServices.updateAccountStructure(
        this.state.id,
        updateData
      );
      if (resp) {
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        this.cancelAll();
        this.validator.hideMessages();
        $(".close").click();
        this.forceUpdate();
        this.getListAccountStruture();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  cancelAll = () => {
    this.setState({
      ledgerName: "",
      ledgerCode: "",
      id: "",
      hierarchyLedgerArray: []
    });
    this.customId = "";
  };

  //to call the API to store the data for header option
  getListHierarchy = async e => {
    let resp = await expenseServices.getHierarchyStructure(
      "5c74ed2977addf3b07acb012"
    ); // FIXME
    if (resp) {
      let apiData = resp.result;
      let filterData = apiData.filter(
        data =>
          data.formHeaderKey !== "report-itemization" &&
          data.formHeaderKey !== "report-attendee"
      );
      this.setState({
        apiHierarchyData: filterData
      });
    }
  };
  //create an empty object to store the header value and header field with order
  createNewHierarchy = e => {
    e.preventDefault();
    let hierarchyLedgerArray = [...this.state.hierarchyLedgerArray];
    let order = hierarchyLedgerArray.length ? hierarchyLedgerArray.length : 0;
    let newValue = {
      id: uuid.v4(),
      formHeader: {},
      formFieldName: {},
      order: order + 1
    };
    hierarchyLedgerArray.push(newValue);
    this.setState({ hierarchyLedgerArray });
  };

  //to select the form header options
  selectHandler = (e, id) => {
    let hierarchyLedgerArray = [...this.state.hierarchyLedgerArray];
    let currentExpression = hierarchyLedgerArray.find(
      expression => expression.id === id
    );
    let currentIndex = hierarchyLedgerArray.findIndex(
      expression => expression.id === id
    );
    if (currentExpression) {
      currentExpression = {
        ...currentExpression,
        formHeader: e
      };
    }

    hierarchyLedgerArray.splice(currentIndex, 1, currentExpression);
    this.setState({
      hierarchyLedgerArray
    });
    this.getField(e.id); //calling an API to for to store the field value //
  };

  // to select the form header fields
  selectFieldHandler = (e, id) => {
    let hierarchyLedgerArray = [...this.state.hierarchyLedgerArray];
    let currentExpression = hierarchyLedgerArray.find(
      expression => expression.id === id
    );

    let currentIndex = hierarchyLedgerArray.findIndex(
      expression => expression.id === id
    );
    if (currentExpression) {
      currentExpression = {
        ...currentExpression,
        formFieldName: e
      };
    }
    hierarchyLedgerArray.splice(currentIndex, 1, currentExpression);
    this.setState({
      hierarchyLedgerArray
    });
  };
  // to get the field name value //
  getField = async id => {
    let resp = await expenseServices.getHierarchyField(
      this.state.companyId,
      id
    );
    if (resp) {
      let res = resp.result;
      let fltrLst = res.filter(
        item =>
          item.dataType === "connected_list" ||
          item.dataType === "different_list"
      );

      this.setState({
        fieldData: {
          ...this.state.fieldData,
          [id]: fltrLst
        }
      });
    }
  };

  // UP and DOWN order
  moveStep = (dir, item) => {
    let hierarchyLedgerArray = [...this.state.hierarchyLedgerArray];
    let curr = hierarchyLedgerArray.find(i => i.id === item.id);
    let index = hierarchyLedgerArray.indexOf(curr);

    // down order
    if (
      dir === "down" &&
      hierarchyLedgerArray.length > 1 &&
      hierarchyLedgerArray.length !== index + 1
    ) {
      [hierarchyLedgerArray[index], hierarchyLedgerArray[index + 1]] = [
        hierarchyLedgerArray[index + 1],
        hierarchyLedgerArray[index]
      ];
      [
        hierarchyLedgerArray[index].order,
        hierarchyLedgerArray[index + 1].order
      ] = [
        hierarchyLedgerArray[index + 1].order,
        hierarchyLedgerArray[index].order
      ];
      this.setState({
        hierarchyLedgerArray
      });
    }
    // Up order
    else if (
      dir === "up" &&
      hierarchyLedgerArray.length > 1 &&
      hierarchyLedgerArray.length !== 1
    ) {
      [hierarchyLedgerArray[index], hierarchyLedgerArray[index - 1]] = [
        hierarchyLedgerArray[index - 1],
        hierarchyLedgerArray[index]
      ];
      [
        hierarchyLedgerArray[index].order,
        hierarchyLedgerArray[index - 1].order
      ] = [
        hierarchyLedgerArray[index - 1].order,
        hierarchyLedgerArray[index].order
      ];
      this.setState({
        hierarchyLedgerArray
      });
    }
  };
  selectHierarchy = id => {
    if (this.state.hierarchyId === id) {
      this.setState({
        hierarchyId: ""
      });
    } else {
      this.setState({
        hierarchyId: id
      });
    }
  };

  removeHierarchy = async id => {
    let hierarchyLedgerArray = [...this.state.hierarchyLedgerArray];
    let resp = await expenseServices.deleteHierarchyStructure(id);
    if (resp) {
      toast.info(alertConstants.DELETED_SUCCESSFULLY);
      $(".close").click();
      this.setState({ hierarchyId: "" });
      this.getListAccountStruture();
      let filterArray = hierarchyLedgerArray.filter(item => item.id !== id);
      let newArr = filterArray.map((item, index) => {
        return {
          ...item,
          order: index + 1
        };
      });
      this.setState({
        hierarchyLedgerArray: newArr
      });
      await this.changeOrder();
      this.getListAccountStruture();
    }
  };

  changeOrder = async () => {
    let hierarchyLedgerArray = [...this.state.hierarchyLedgerArray];
    let change = hierarchyLedgerArray.map(item => {
      return {
        id: item.id,
        order: item.order
      };
    });
    let myOrder = {
      order: change
    };
    let resp = await expenseServices.changeHierarchyOrderStructure(myOrder);
    if (resp) {
      // console.log("order changed");
    }
  };

  saveHierarchy = async e => {
    let hierarchyLedgerArray = [...this.state.hierarchyLedgerArray];
    let newArr = hierarchyLedgerArray.map(item => {
      let id = item.id.includes("-") ? "" : item.id;
      if (id) {
        return {
          hierarchyLevel: item.formHeader.id,
          hierarchySegment: item.formFieldName.id,
          order: item.order,
          id: id
        };
      } else {
        return {
          hierarchyLevel: item.formHeader.id,
          hierarchySegment: item.formFieldName.id,
          order: item.order
        };
      }
    });

    let filterArr = newArr.filter(item => {
      return (
        item.hierarchyLevel !== undefined &&
        item.hierarchyLevel !== "" &&
        item.hierarchySegment !== undefined &&
        item.hierarchySegment !== ""
      );
    });
    let myhierarchyPostData = {
      companyId: this.state.companyId,
      ledgerId: this.customId,
      hirearchyData: filterArr
    };

    e.preventDefault();

    let resp = await expenseServices.addHierarchyStructure(myhierarchyPostData);
    if (resp) {
      toast.success(alertConstants.CREATED_SUCCESSFULLY);
      this.getListAccountStruture();
      $(".close").click();
    }
  };

  render() {
    return (
      <div>
        <AccountStructurePage
          state={this.state}
          error={this.validator}
          handleChange={this.handleChange}
          addSubmit={this.addSubmit}
          listOfAccountStruture={this.listOfAccountStruture}
          handleSearch={this.handleSearch}
          customId={this.customId}
          storeId={this.storeId}
          getData={this.getData}
          removeItem={this.removeItem}
          updateSubmit={this.updateSubmit}
          cancelAll={this.cancelAll}
          apiHierarchyData={this.state.apiHierarchyData}
          createNewHierarchy={this.createNewHierarchy}
          selectHandler={this.selectHandler}
          hierarchyLedgerArray={this.state.hierarchyLedgerArray}
          removeHierarchy={this.removeHierarchy}
          fieldData={this.state.fieldData}
          selectFieldHandler={this.selectFieldHandler}
          moveStep={this.moveStep}
          saveHierarchy={this.saveHierarchy}
          modifyHierarchy={this.modifyHierarchy}
          selectHierarchy={this.selectHierarchy}
          storeHierarchyId={this.storeHierarchyId}
        />
      </div>
    );
  }
}
export default AccountStructure;
