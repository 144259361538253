import { PropTypes } from "prop-types";
import { Component } from "react";
import { isFunction } from "lodash/fp";
import classNames from "classnames";
import PlacesAutocomplete from "react-places-autocomplete";
import { SearchResults, SearchResultItem } from "./SearchResults";
import { createLogger } from "src/_helpers";

const locationSearchLogger = createLogger("LocationSearch");

export class LocationSearch extends Component {
  static propTypes = {
    location: PropTypes.string,
    isInputShown: PropTypes.bool,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    inputProps: PropTypes.object,
    children: PropTypes.any.isRequired
  };

  state = {
    address: this.props.location || ""
  };

  changeHandler = address => {
    locationSearchLogger("Change: %s", address);
    this.setState({ address });
  };

  selectHandler = address => {
    locationSearchLogger("Select: %s", address);
    this.setState({ address });
    if (isFunction(this.props.onSelect)) {
      locationSearchLogger("selecting...");
      this.props.onSelect(address);
    }
  };

  render() {
    const { children, inputProps = {} } = this.props;
    const { address } = this.state;

    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.changeHandler}
        onSelect={this.selectHandler}
        highlightFirstSuggestion
        debounce={300}
        shouldFetchSuggestions={address.length > 3}
        // {...props}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          locationSearchLogger("Suggestions: %O", suggestions);

          return (
            <>
              {children}
              <div
                className="popup"
                style={{ visibility: "visible", opacity: 1 }}
              >
                <div className="typ">
                  <input
                    {...getInputProps({
                      autoFocus: true,
                      placeholder: "Search Places ...",
                      ...inputProps,
                      className: classNames(
                        "location-search-input",
                        inputProps.className
                      )
                    })}
                  />
                  <div className="pin">
                    <i className="material-icons">directions_car</i>
                  </div>
                </div>

                <SearchResults>
                  {loading ? <li>Loading...</li> : null}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    return (
                      <SearchResultItem
                        key={suggestion.id}
                        {...getSuggestionItemProps(suggestion, {
                          className
                        })}
                      >
                        <button type="button">{suggestion.description}</button>
                        <div className="pin">
                        <i className="material-icons">directions_car</i>
                        </div>
                      </SearchResultItem>
                    );
                  })}
                </SearchResults>
              </div>
            </>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}
