import React from "react";
import { NavLink } from "react-router-dom";
const ExportPage = () => {

  return (
    <div>
      <div className="">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Import/Export</h6>
              <p className="text">
              Use Import/Export section to Import or Export reports to CSV or XLS based on filters.
              </p>
            </div>
          </div>
        </div>
        <div className="p-padding importdata">
          <div className="bg-white">
            <div className="row">
              <div className="col-sm-3 import-left-items pr-0">
                <ul>
                  <li><NavLink to="/administration/company/import">Import</NavLink></li>
                  <li className="active"><NavLink to="#">Export</NavLink></li>
                  <li><NavLink to="/administration/company/history-log">History Logs</NavLink></li>
                </ul>
              </div>
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-md-6 offset-3">
                    <div className="import-box">
                      <div className="heading">
                        Export
                      </div>
                      <div className="inner-content">
                        <div className="form-group custom">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false"
                            >
                              Attendee Type
                              </button>
                            <div className="dropdown-menu">
                              <button
                                type="button"
                                className="dropdown-item"
                              >
                                Attendee
                            </button>
                              <button
                                type="button"
                                className="dropdown-item"
                              >
                                Employee
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group custom ">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Start Date</label>
                        </div>
                        <div className="form-group custom ">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>End Date</label>
                        </div>
                        <button className="btn text-center w-100">Download</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExportPage;
