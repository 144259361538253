import React from "react";
import { Input } from "../../../../../common/_forms";
const FormItems = props => {
  const {
    error,
    state,
    title,
    isStatic,
    className,
    handleChange,
    handleSubmit,
    btnType,
    type,
    handleUpdateSubmit
  } = props;
  return (
    <div className={className}>
      <div className="box-head">
        <h6>
          <img src="/assets/images/item.svg" />
          <b>{title}</b>
        </h6>
      </div>
      <div className="box-content">
        <div>
          <div className="form-group custom bg-white">
            <Input
              type="text"
              label="Item Name"
              name="itemName"
              imp="true"
              value={state.itemName}
              error={error.message("itemName", state.itemName, "required")}
              controlFunc={e => handleChange(e)}
            />
          </div>
        </div>
        <div>
          
          {isStatic && title === "Edit Item" ? 
            ""
           : 
           <div>    
             <div className="form-group custom bg-white">
               <Input
                 type="text"
                 label="Item Code"
                 imp="true"
                 name="itemCode"
                 value={state.itemCode}
                 error={error.message("itemCode", state.itemCode, "required")}
                 controlFunc={e => handleChange(e)}
               />
             </div>
            </div>
          }
          </div> 
        <div>
          <div className="btn-group-margin">
            <button
              onClick={type === 3 ? handleUpdateSubmit : handleSubmit}
              className="btn save-button"
            >
              {type === 3 ? "Update" : "Save"}
            </button>
            <button onClick={btnType} className="btn">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormItems;
