import React from "react";
import { Input } from "../../../../../common/_forms";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NavLink } from "react-router-dom";

const AddEditTemplatePage = props => {
  const {
    notificationBoxContenthandler,
    inputChangeHandler,
    submitHandler,
    emailContentHandler,
    validator,
    state
  } = props;

  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">E</div>
          <div className="right">
            <h6 className="heading">
              {state.isEditable ? "Edit Template" : "New Template"}
            </h6>
            <p className="text">You can edit templates with your own content and customise to your requirement.</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="row">
            <div className="col-sm-7">
              <form onSubmit={e => submitHandler(e)} id="form">
                <div className="form-group custom">
                  <Input
                    label={"Name"}
                    name="name"
                    type="text"
                    imp="true"
                    controlFunc={e => inputChangeHandler(e)}
                    value={state.name}
                    className="form-control"
                    error={validator.message("name", state.name, "required")}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label={"Subject"}
                    name="subject"
                    type="text"
                    imp="true"
                    controlFunc={e => inputChangeHandler(e)}
                    value={state.subject}
                    className="form-control"
                    error={validator.message(
                      "subject",
                      state.subject,
                      "required"
                    )}
                  />
                </div>
                <div className="form-group custom mb-1">
                  <Input
                    label={"Copy to email"}
                    name="copyToEmail"
                    type="text"
                    controlFunc={e => props.inputChangeHandlerForCCEmails(e)}
                    value={state.copyToEmail}
                    className="form-control"
                  />
                </div>
                <p className="mb-4">Add multiple email address separated by comma (,).</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={state.content}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    emailContentHandler(data);
                  }}
                />
                <div className="form-group custom textarea mt-4">
                  <textarea className="form-control"
                    name="notificationBoxContent"
                    value={state.notificationBoxContent ? state.notificationBoxContent : ''}
                    onChange={e => notificationBoxContenthandler(e)}
                    placeholder="&nbsp;"
                  />
                  <label>Notification</label>
                  {/* <Input
                    name="notificationBoxContent"
                    type="text"
                    controlFunc={e => notificationBoxContenthandler(e)}
                    value={state.notificationBoxContent ? state.notificationBoxContent : ''}
                    className="form-control"
                    label="Notification"
                  /> */}
                </div>
                <div className="btn-group-margin mt-4">
                  <button
                    type="submit"
                    className="btn"
                    disabled={state.loading}
                  >
                    {state.loading === true
                      ? "Please Wait"
                      : state.isEditable
                        ? "UPDATE"
                        : "SAVE"}
                  </button>
                  <NavLink
                    to={`/administration/company/email/travel/${props.id}`}
                    className="btn"
                  >
                    CANCEL
                  </NavLink>
                </div>
              </form>
            </div>
            <div className="col-sm-5">
              <h6 className="sub-heading">
                <i className="material-icons">find_in_page</i> Preview
              </h6>
              <div className="template-output">
                <div dangerouslySetInnerHTML={{ __html: state.content }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddEditTemplatePage;
