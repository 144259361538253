import React from "react";

const cardTransactionHead = props => {
  return (
    <thead>
      <tr>
        <th>
          <label className="checkbox-container small d-inline-block">
            <input
              type="checkbox"
              checked={props.checkedAll === 1}
              // ref={input => {
              //     if (input) {
              //         input.indeterminate = props.checkedAll === 2;
              //     }
              // }}
              onChange={e => props.toggleSelectAll()}
            />
            <span className="checkmark" />
          </label>
          <div className="d-inline-block">Receipt</div>
        </th>
        <th>Payment Type</th>
        <th>Expense Type</th>
        <th>Vendor Details</th>
        <th>Date</th>
        <th>Amount</th>
      </tr>
    </thead>
  );
};

export default cardTransactionHead;
