import React from 'react';

const deleteItemisationConfirmModal =(props)=>{
    return(
        <div
        className="modal fade"
        id="deleteItemisation"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="submitModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close text-white d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="text-center pt-5">
                <img src="/assets/images/confirmation_icon.svg" alt="" />
                <h3 className=" mt-4 text-dark">Are You Sure?</h3>
                <p>By clicking on submit, you are approving this request.</p>
              </div>
              <div className="btn-group-margin text-center mb-4">
                <button
                  type="button"
                  onClick={props.onItemisationDeleteCancelHandler}
                  className="btn"
                  data-dismiss="modal"
                >
                  No
              </button>
                <button
                  onClick={props.onItemisationDeleteClickHandler}
                  className="btn"
                >
                  Submit
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default deleteItemisationConfirmModal;