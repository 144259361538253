import React, { Component } from "react";
import AddAirDiscountsPage from "./add-air-discounts-html";
import { travelDiscountServices } from "../../../../../_services";
import { toast } from "react-toastify";

const filterAirline = inputValue => {
  return this.state.allAirlines.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};
const loadOptions = (inputValue, callback) => {
  setTimeout(() => {
    callback(filterAirline(inputValue));
  }, 1000);
};
class AddAirDiscounts extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      travelCategoryId: localStorage["travelCategoryId"],
      allTravelCategories: [],
      matchedCategory: {},
      allAirlines: [],
      selectedAirline: "",
      discountCode: "",
      configId: "",
      editDiscountCodeDetails: {},
      editDiscountCodeDetailId: {}
    };
  }
  async componentDidMount() {
    this.getTravelCategory();
    this.getAirlines();
    this.setState({
      configId: this.props.match.params.id,
      editDiscountCodeDetails: this.props.match.params
    });
  }

  OnAirlineSelectHandler = async newValue => {
    await this.setState({ 
      selectedAirline: newValue
    });
    return newValue;
  };

  OnDiscountInputHandler = event => {
    this.setState({
      editDiscountCodeDetails: {
        ...this.state.editDiscountCodeDetails,
        discountCode: event.target.value
      }
    });
  };

  getAirlines = async () => {
    try {
      let res = await travelDiscountServices.getAllAirlines();
      this.setState({
        allAirlines: res.result.map(item => ({
          label: item.name,
          value: item.id
        }))
      });
      let vendorId = this.state.editDiscountCodeDetails.vendorId;
      let vendorIdObject = [];
      vendorIdObject = res.result.filter(item => {
        if (item.id === vendorId) return { label: item.name, value: item.id };
      });
      this.setState({
        editDiscountCodeDetailId: {
          label: vendorIdObject[0].name,
          value: vendorIdObject[0].id
        }
      });
    } catch (err) {
      // console.log(err);
    }
  };

  getTravelCategory = async () => {
    try {
      const { id: productId } = this.props.match.params;
      const response = await travelDiscountServices.getTravelCategories();
      const allTravelCategories = response ? response.result : [];
      const travelCategory = allTravelCategories.find(category => category.id === this.state.travelCategoryId);
      this.setState({
        matchedCategory: travelCategory,
        configId: productId,
        allTravelCategories
      });
    } catch (e) {
      // console.log(e)
    }
  };

  OnSubmitHandler = async () => {
    const payload = {
      travelCategoryId: this.state.travelCategoryId,
      vendor: this.state.selectedAirline.value,
      discountCode: this.state.editDiscountCodeDetails.discountCode
    };
    let emptyDiscountCodeMsg = {
      vendor:"Vendor can not be empty or blanked",
      discountCode:"Discount Code can not be empty or blanked"
    }
    let createTravelDiscount=async ()=>{
      let res = await travelDiscountServices.createTravelDiscount(
        this.state.configId,
        payload
      );
      if (res) {
        toast.success(res.message);
        this.props.history.push(
          `/administration/travel/add-travel-discount/${this.state.configId}/${this.state.travelCategoryId}`
        );
      }
    }
    return payload.vendor?payload.discountCode? await createTravelDiscount(): toast.warn(emptyDiscountCodeMsg.discountCode):toast.warn(emptyDiscountCodeMsg.vendor)
   
   
  };

  OnCancelBtnHandler = async () => {
    await this.props.history.push(
      "/administration/travel/add-travel-discount/" + this.state.configId
    );
  };

  render() {
    return (
      <div>
        <AddAirDiscountsPage
           {...this.state}
          OnAirlineSelectHandler={this.OnAirlineSelectHandler}
          OnDiscountInputHandler={this.OnDiscountInputHandler}
          OnSubmitHandler={this.OnSubmitHandler}
          OnCancelBtnHandler={this.OnCancelBtnHandler}
         
        />
      </div>
    );
  }
}
export default AddAirDiscounts;
