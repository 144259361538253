import React, { Component } from 'react';
import CarInfoPage from './car-info-html';
import { contractServices } from 'src/_services';
import { resolveSoa } from 'dns';
import $ from "jquery";
import { toast } from 'react-toastify';
import SimpleReactValidator from "simple-react-validator";

// import Pagination from 'react-js-pagination';

class CarInfo extends Component {
  state = {
    companyId: localStorage["440a28"],
    carId: "",
    capacityTypes: [],
    baggageTypes: [],
    deleteImage: false,
    uploading: false,
    countryTypes: [],
    stateTypes: [],
    acTypes: [],
    transmissionTypes: [],
    fuelTypes: [],
    limit: 5,
    page: 2,
    carContractCityId: "",
    photos: [],
    isSearchCountryCodeListOpen: false,
    selectedCountryCode: {
      country: '',
      dailCode: '',
      countryId: ''
    },
    countryCodeList: [],
    isSearchCountryCodeListOpen2: false,
    selectedCountryCode2: {
      country: '',
      dailCode: '',
      countryId: ''
    },
    countryCodeList2: [],
    editIsTrue: false,
    validator: new SimpleReactValidator(),

    carGroup: {},
    carType: {},
    capacity: {},
    baggage: {},
    airConditionType: {},
    transmissionType: {},
    fuelType: {},
    country: {},
    state: {},
    city: {}
  }

  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    await this.getCarTypes();
    let data = {
      index: this.props.match.params.index,
      carContractCityId: this.props.match.params.carContractCityId
    }
    this.setState({ carId: data.carId, carContractCityId: data.carContractCityId });
    if (data.index >= 0) {
      this.updateCarData(data.index);
    }
    else {
      this.addNewCarData();
    }

    this.getCarGroupTypes();
    this.getAllStaticTypes();
    this.getCountryTypes();
  }
  updateCarData = (index) => {
    let carsData = JSON.parse(localStorage.getItem('carsData'));
    let carData = carsData[index];
    this.setState({
      carAssistantNumber: carData.carAssistantNumber,
      carTypeCode: carData.carTypeCode,
      pricePerDay: carData.pricePerDay,
      pricePerKm: carData.pricePerKm,
      carAmenities: carData.carAmenities,
      photos: carData.photos,
      fuelPolicy: carData.fuelPolicy,
      fuelType: carData.fuelType,
      personName: carData.personName,
      contactNumber: carData.contactNumber,
      alternativeNumber: carData.alternativeNumber,
      email: carData.email,
      cancelationPolicy: carData.cancelationPolicy,
      carContractCityId: carData.carContractCityId,
      editIsTrue: true,
      carId: carData.id,
      carType: { label: carData.carTypeData.itemName, value: carData.carTypeData.id },
      nameOfCar: carData.carTypeData.itemName,
      carGroup: { label: carData.carGroupData.itemName, value: carData.carGroupData.id },
      capacity: { label: carData.capacity, value: carData.capacity },
      baggage: { label: carData.baggage, value: carData.baggage },
      airConditionType: { label: carData.airConditionType, value: carData.airConditionType },
      transmissionType: { label: carData.transmissionType, value: carData.transmissionType },
      fuelType: { label: carData.fuelType, value: carData.fuelType },
      fuelPolicy: { label: carData.fuelPolicy, value: carData.fuelPolicy },
      country: { label: carData.countryData.name, value: carData.countryData.id },
      state: { label: carData.stateData.name, value: carData.stateData.id },
      city: { label: carData.cityData.name, value: carData.cityData.id },
      selectedCountryCode2: {
        country: carData.contactNumberCode.country, dailCode: carData.contactNumberCode.dailCode, countryId: carData.contactNumberCode.id
      },
      selectedCountryCode: {
        country: carData.alternativeNumberCode.country, dailCode: carData.alternativeNumberCode.dailCode, countryId: carData.alternativeNumberCode.id
      }
    })
  }
  updateCar = async () => {
    let payLoad = {
      carContractCityId: this.state.carContractCityId,
      carGroup: this.state.carGroup.value,
      carType: this.state.carType.value,
      nameOfCar: this.state.nameOfCar,
      carTypeCode: this.state.carTypeCode,
      carAssistantNumber: this.state.carAssistantNumber,
      capacity: this.state.capacity.value,
      baggage: this.state.baggage.value,
      pricePerDay: this.state.pricePerDay,
      pricePerKm: this.state.pricePerKm,
      carAmenities: this.state.carAmenities,
      airConditionType: this.state.airConditionType.value,
      transmissionType: this.state.transmissionType.value,
      fuelType: this.state.fuelType.value,
      fuelPolicy: this.state.fuelPolicy.value,
      cancelationPolicy: this.state.cancelationPolicy,
      country: this.state.country.value,
      state: this.state.state.value,
      city: this.state.city.value,
      contactNumberCode: this.state.selectedCountryCode2.countryId,
      alternativeNumberCode: this.state.selectedCountryCode.countryId,
      companyId: this.state.companyId,
      personName: this.state.personName,
      contactNumber: this.state.contactNumber,
      alternativeNumber: this.state.alternativeNumber,
      email: this.state.email,
      photos: this.state.photos,
    }

    if (this.state.validator.allValid()) {
      let resp = await contractServices.updateCarDetails(this.state.carId, payLoad);
      if (resp) {
        toast.success(resp.message);
        $(".close").click();
      }

    } else {
      this.state.validator.showMessages();
      this.forceUpdate();
    }
  }

  addNewCar = async () => {
    let payLoad = {
      carContractCityId: this.state.carContractCityId,
      carGroup: this.state.carGroup.value,
      carType: this.state.carType.value,
      nameOfCar: this.state.nameOfCar,
      carTypeCode: this.state.carTypeCode,
      carAssistantNumber: this.state.carAssistantNumber,
      capacity: this.state.capacity.value,
      baggage: this.state.baggage.value,
      pricePerDay: this.state.pricePerDay,
      pricePerKm: this.state.pricePerKm,
      carAmenities: this.state.carAmenities,
      airConditionType: this.state.airConditionType.value,
      transmissionType: this.state.transmissionType.value,
      fuelType: this.state.fuelType.value,
      fuelPolicy: this.state.fuelPolicy.value,
      cancelationPolicy: this.state.cancelationPolicy,
      country: this.state.country.value,
      state: this.state.state.value,
      city: this.state.city.value,
      contactNumberCode: this.state.selectedCountryCode2.countryId,
      alternativeNumberCode: this.state.selectedCountryCode.countryId,
      companyId: this.state.companyId,
      personName: this.state.personName,
      contactNumber: this.state.contactNumber,
      alternativeNumber: this.state.alternativeNumber,
      email: this.state.email,
      photos: this.state.photos,
    }
    if (this.state.validator.allValid()) {
      let resp = await contractServices.addNewCarDetails(payLoad);
      if (resp) {
        toast.success(resp.message);
        $(".close").click();
      }
    } else {
      this.state.validator.showMessages();
      this.forceUpdate();
    }
  }
  addNewCarData = () => {
    this.setState({
      carAssistantNumber: null,
      carTypeCode: null,
      pricePerDay: null,
      pricePerKm: null,
      carAmenities: null,
      photos: [],
      fuelPolicy: { label: "Select.." },
      fuelType: null,
      personName: null,
      contactNumber: null,
      alternativeNumber: null,
      email: null,
      cancelationPolicy: null,
      carGroup: { label: "Select.." },
      carType: { label: "Select..", value: '' },
      capacity: { label: "Select..", value: '' },
      baggage: { label: "Select..", value: '' },
      airConditionType: { label: "Select..", value: '' },
      transmissionType: { label: "Select..", value: '' },
      fuelType: { label: "Select..", value: '' },
      country: { label: "Select..", value: '' },
      state: { label: "Select..", value: '' },
      city: { label: "Select..", value: '' }
    })
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  getCarTypes = async () => {
    let res = await contractServices.getCarTypes(this.state.companyId);
    if (res) {
      this.setState({ carTypes: res.result });
    } else {
      console.log("No response from API");
    }

  }
  getCarGroupTypes = async () => {
    let res = await contractServices.getCarGroupTypes(this.state.companyId);
    if (res) {
      this.setState({ carGroupTypes: res.result })
    }
  }
  changeCarGroupTypes = (val) => {
    this.setState({ carGroup: val });
  }
  getCountryTypes = async () => {
    let res = await contractServices.getCountryTypes();
    if (res) {
      this.setState({ countryTypes: res.result })
    }
  }

  carTypeChange = (val) => {
    this.setState({ carType: val });
    this.setState({ nameOfCar: val.label })
  }
  getAllStaticTypes = () => {
    let capacityTypes = [{ label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }];
    let baggageTypes = [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }];
    let acTypes = [{ label: "AC", value: "AC" }, { label: "Non AC", value: "Non AC" }];
    let transmissionTypes = [{ label: "Automatic", value: "Automatic" }, { label: "Semi-Automatic", value: "Semi-Automatic" }];
    let fuelTypes = [{ label: "Petrol", value: "Petrol" }, { label: "Diesel", value: "Diesel" }, { label: "Gas", value: "Gas" }, { label: "Electric", value: "Electric" }];
    let fuelPolicyTypes = [{ label: "Has Policy", value: "Has Policy" }, { label: "Doesn’t have Policy/Policy Not Required", value: "Doesn’t have Policy/Policy Not Required" }]
    this.setState({ capacityTypes, baggageTypes, acTypes, transmissionTypes, fuelTypes, fuelPolicyTypes })
  }
  changeCapacityType = (val) => {
    this.setState({ capacity: val });
  }
  changeBaggageType = (val) => {
    this.setState({ baggage: val })
  }
  changeCountryType = (val) => {
    let countryId = val.value;
    this.setState({ country: val });
    this.getStateTypes(countryId);
  }
  getStateTypes = async (countryId) => {
    let res = await contractServices.getStateTypes(countryId);
    if (res) {
      this.setState({ stateTypes: res.result })
    }
  }
  changeStateTypes = (val) => {
    let stateId = val.value;
    this.setState({ state: val });
    this.getCityTypes(stateId);
  }
  getCityTypes = async (stateId) => {
    let res = await contractServices.getCityTypes(stateId);
    if (res) {
      this.setState({ cityTypes: res.result });
    }
  }
  changeCityTypes = (val) => {
    this.setState({ city: val });
  }
  changeAcTypes = (val) => {
    this.setState({ airConditionType: val });
  };
  changeTransmissionTypes = (val) => {
    this.setState({ transmissionType: val })
  }
  changeFuelTypes = (val) => {
    this.setState({ fuelType: val })
  }
  changeFuelPolicyTypes = (val) => {
    this.setState({ fuelPolicy: val });
  }
  onImageBrowse = (e) => {
    let files = Array.from(e.target.files);
    this.setState({ uploading: true });

    const formData = new FormData();
    files.forEach((file, i) => {
      formData.append(i, file);
    })
    this.uploadCarImages(formData);
  }
  uploadCarImages = async (payLoad) => {
    let res = await contractServices.uploadCarImages(payLoad);
    if (res) {
      this.setState({ photos: res.result });
    }
  }
  deleteImagefunction = () => {
    this.setState({ deleteImage: !this.state.deleteImage })
  }
  removeFromList = (i) => {
    let filetredPhotos = this.state.photos.filter((photo, index) => {
      if (index !== i) { return photo }
    })
    this.setState({ photos: filetredPhotos });
  }
  handleChangeCountryCode = async (e) => {
    let searchTerm = e.target.value;
    try {
      if (searchTerm != '') {
        // * if the user is typing show the matched search users.
        this.setState({
          isSearchCountryCodeListOpen: true,
          selectedCountryCode: {
            country: e.target.value
          }
        });

        let res = await contractServices.getCountryCodeList(searchTerm);
        if (res && res.count) {
          this.setState({
            countryCodeList: res.result
          })

        }
      } else {
        this.setState({ countryCodeList: [], selectedCountryCode: {} });
      }

    } catch (err) {
      console.log('some error occurred.');
    }

  }
  selectChangeCountryCode = (country) => {
    let selectedCountryCode = {
      country: country.country,
      dailCode: country.dailCode,
      countryId: country.id,
    }
    this.setState({
      selectedCountryCode,
      isSearchCountryCodeListOpen: false
    });
  }
  handleChangeCountryCode2 = async (e) => {
    let searchTerm = e.target.value;
    try {
      if (searchTerm != '') {
        // * if the user is typing show the matched search users.
        this.setState({
          isSearchCountryCodeListOpen2: true,
          selectedCountryCode2: {
            country: e.target.value
          }
        });

        let res = await contractServices.getCountryCodeList(searchTerm);
        if (res && res.count) {
          this.setState({
            countryCodeList2: res.result
          })

        }
      } else {
        this.setState({ countryCodeList2: [], selectedCountryCode2: {} });
      }

    } catch (err) {
      console.log('some error occurred.');
    }

  }
  selectChangeCountryCode2 = (country) => {
    let selectedCountryCode2 = {
      country: country.country,
      dailCode: country.dailCode,
      countryId: country.id,
    }
    this.setState({
      selectedCountryCode2,
      isSearchCountryCodeListOpen2: false
    });
  }
  componentWillUnmount = () => {
    localStorage.removeItem('carsData');
  }

  render() {
    return (
      <div>
        <CarInfoPage
          {...this.state}
          updateCar={this.updateCar}
          addNewCar={this.addNewCar}
          handleChange={this.handleChange}
          carTypeChange={this.carTypeChange}
          changeCapacityType={this.changeCapacityType}
          changeBaggageType={this.changeBaggageType}
          onImageBrowse={this.onImageBrowse}
          deleteImagefunction={this.deleteImagefunction}
          removeFromList={this.removeFromList}
          changeCountryType={this.changeCountryType}
          changeStateTypes={this.changeStateTypes}
          changeCityTypes={this.changeCityTypes}
          changeAcTypes={this.changeAcTypes}
          changeTransmissionTypes={this.changeTransmissionTypes}
          changeFuelTypes={this.changeFuelTypes}
          changeFuelPolicyTypes={this.changeFuelPolicyTypes}
          changeCarGroupTypes={this.changeCarGroupTypes}
          handleChangeCountryCode={this.handleChangeCountryCode}
          selectChangeCountryCode={this.selectChangeCountryCode}
          handleChangeCountryCode2={this.handleChangeCountryCode2}
          selectChangeCountryCode2={this.selectChangeCountryCode2}
        />
      </div>
    )
  }
}

export default CarInfo;