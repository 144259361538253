import React, { Component } from "react";
import TravelPage from "./travel-html";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import { companyEmailReminderServices } from "../../../../../../_services";

class Travel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: localStorage["440a28"],
      serviceModuleData: [],
      emailReminderData: [],
      serviceModuleId: "",
      search: "",
      currentPage: 1,
      itemsPerPage: 10,
      totalCount: "",
      isLoading: false
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getAllServiceModuleId();
  }

  // getting current page
  OnPageChangeHandler = pageNumber => {
    const CURRENT_PAGE = 1;
    pageNumber = pageNumber ? pageNumber : CURRENT_PAGE;

    const { itemsPerPage, search } = this.state;

    this.setState({ currentPage: pageNumber });

    if (!search) {
      this.getAllEmailReminder(pageNumber, itemsPerPage, "");
    } else {
      this.onfilterTempltes(pageNumber, itemsPerPage, search);
    }
  };

  // pagination getting limit on items according to user
  OnPageLimitHandler = itemsPerPage => {
    const PAGE_SIZE = 10;
    if (itemsPerPage !== this.state.itemsPerPage) {
      itemsPerPage = itemsPerPage ? itemsPerPage : PAGE_SIZE;

      const { currentPage, search } = this.state;

      this.setState({ itemsPerPage: itemsPerPage });

      if (!this.state.search) {
        this.getAllEmailReminder(currentPage, itemsPerPage, "");
      } else {
        this.onfilterTempltes(currentPage, itemsPerPage, search);
      }
    }
  };

  // get all services modules id's
  getAllServiceModuleId = async () => {
    const res = await companyEmailReminderServices.getServiceModuleId();

    this.setState({
      serviceModuleData: res ? res.result : [],
      serviceModuleId: res
        ? res.result.find(service => service.serviceModuleName === "Travel").id
        : ""
    });

    const { serviceModuleId, currentPage, itemsPerPage } = this.state;

    localStorage.setItem("serviceModuleId", serviceModuleId);
    this.getAllEmailReminder(currentPage, itemsPerPage, "");
  };

  // get all email reminder
  getAllEmailReminder = async (currentPage, itemsPerPage, id) => {
    this.setState({ isLoading: true });
    const res = await companyEmailReminderServices.getAllEmailReminders(
      id ? id : this.state.serviceModuleId,
      this.state.companyId,
      currentPage,
      itemsPerPage
    );
    this.setState({
      emailReminderData: res ? res.result : [],
      totalCount: res ? res.count : "",
      isLoading: false
    });
  };

  // Branch Status Active / Inactive handler
  OnStatusHandler = async status => {
    const payload = { isSet: !status.isSet };
    let res = await companyEmailReminderServices.activeInactiveStatus(
      status.id,
      payload
    );
    if (res) {
      toast.success("Status Updated Successfully");

      let emailReminderData = [...this.state.emailReminderData];
      emailReminderData[
        emailReminderData.findIndex(item => item.id === status.id)
      ].isSet = !status.isSet;

      this.setState({ emailReminderData });
    }
  };

  // service module id handler
  getServiceModuleId = id => {
    this.setState({ serviceModuleId: id });
    const { currentPage, itemsPerPage } = this.state;

    this.getAllEmailReminder(currentPage, itemsPerPage, id);
  };

  // search input handler
  onInputHandler = e => {
    this.setState({ search: e.target.value });
    const { currentPage, itemsPerPage } = this.state;
    this.onfilterTempltes(currentPage, itemsPerPage, e.target.value);
  };

  // get filter data from email reminder templates
  onfilterTempltes = (currentPage, itemsPerPage, search) => {
    let timeOut;
    clearTimeout(timeOut);
    timeOut = setTimeout(async () => {
      const response = await companyEmailReminderServices.getFilterEmailTemplates(
        this.state.serviceModuleId,
        this.state.companyId,
        currentPage,
        itemsPerPage,
        search
      );
      this.setState({
        emailReminderData: response ? response.result : []
      });
    }, 250);
  };

  //navigate URL
  navigationHandler = (set, id) => {
    if (set === true) {
      this.props.history.push(
        `/administration/company/reminder/add-edit-reminder/${id}`
      );
    } else {
      toast.warn("Plese Activate the current status to make this edit");
    }
  };

  render() {
    let navBar = (
      <div className="admin-tabs">
        <ul className="nav nav-tabs px-0">
          {this.state.serviceModuleData &&
            this.state.serviceModuleData.map((value, key) => (
              <li key={value.id}>
                <NavLink
                  className={
                    this.state.serviceModuleId === value.id
                      ? "show active"
                      : "show"
                  }
                  onClick={() => this.getServiceModuleId(value.id)}
                  to={`#`}
                >
                  {value.serviceModuleName}
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
    );

    return (
      <div>
        <TravelPage
          {...this.state}
          navBar={navBar}
          OnStatusHandler={this.OnStatusHandler}
          onInputHandler={this.onInputHandler}
          OnPageChangeHandler={this.OnPageChangeHandler}
          OnPageLimitHandler={this.OnPageLimitHandler}
          navigationHandler={this.navigationHandler}
        />
      </div>
    );
  }
}
export default React.memo(Travel);
