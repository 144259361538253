import PageNotFoundPage from "./_components/page-not-found/page-not-found-html";
import HelpComponent from "./_components/common/help";
import FAQComponent from "./_components/common/faq";
import SupportComponent from "./_components/common/support";
import AboutComponent from "./_components/common/about";
import LegalComponent from "./_components/common/legal";
import TermsComponent from "./_components/common/terms-conditions";
import PolicyComponent from "./_components/common/privacy-policy";
import FeedbackComponent from "./_components/common/feedback";
import MasterMain from "./_components/pages/master/master-main";
import BookingPage from "./_components/pages/booking/booking-main";
import ExpensePage from "./_components/pages/expense/expense-main";
import MyTripsPage from "./_components/pages/my-trips/mytrips-main";
import AdminMainPage from "./_components/pages/administrator/common/admin-main";
import CashAdvanceMainPage from "./_components/pages/cash-advance/cash-advance-main";
import TripsApprovePage from "./_components/pages/trips-approve/trips-approve-main";
import CashIssuedMainPage from "./_components/pages/cash-issued/cash-issued-main";
import FinanceMainPage from "./_components/pages/finance-admin/finance-main";
import MyAccountMainPage from "./_components/pages/my-account/my-account-main";
import ReportsHomePage from './_components/pages/reports/reports';
import ChatApp from "./_components/pages/chat-app/chatPage";

const routes = [
  { path: "/", exact: true, name: "Booking", component: BookingPage },
  // master router
  { path: "/master", name: "Master", component: MasterMain },
  // booking router
  { path: "/booking", name: "BookingMain", component: BookingPage },
  { path: "/reports", name: "ReportsHome", component: ReportsHomePage },
  { path: "/help", name: "HelpComponent", component: HelpComponent },
  { path: "/faq", name: "FAQComponent", component: FAQComponent },
  { path: "/support", name: "SupportComponent", component: SupportComponent },
  { path: "/about", name: "AboutComponent", component: AboutComponent },
  { path: "/legal", name: "LegalComponent", component: LegalComponent },
  { path: "/privacy-policy", name: "PolicyComponent", component: PolicyComponent },
  { path: "/terms-conditions", name: "TermsComponent", component: TermsComponent },
  { path: "/feedback", name: "FeedbackComponent", component: FeedbackComponent },
  { path: "/expense", name: "ExpenseMain", component: ExpensePage },
  { path: "/my-trips", name: "MyTripsMain", component: MyTripsPage }, 
  {
    path: "/cash-advance",
    name: "CashAdvanceMain",
    component: CashAdvanceMainPage
  },
  {
    path: "/trips-approve",
    name: "CashAdvanceMain",
    component: TripsApprovePage
  },
  {
    path: "/cash-issued",
    name: "CashIssuedMain",
    component: CashIssuedMainPage
  },
  {
    path: "/my-account",
    name: "MyAccountMain",
    component: MyAccountMainPage
  },
  {
    path: "/finance-main",
    name: "FinanceMainPage",
    component: FinanceMainPage
  },
  { path: "/administration", name: "AdminMain", component: AdminMainPage },
  { path: "/chat", name: "ChatApp", component: ChatApp },
  { path: "**", exact: true, name: "", component: PageNotFoundPage }
];
export default routes;
