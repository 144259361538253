/* eslint-disable default-case */
import React, {Component} from "react";
import { NavLink } from "react-router-dom";
import { Select } from "src/_components/common/_forms";
// import TimePicker from "rc-time-picker";
import moment from 'moment';
// import 'rc-time-picker/assets/index.css';
import { travelPolicyService } from "src/_services/travel-travel-policy.services";
import { toast } from "react-toastify";
import { alertConstants } from "src/_constants";
import SimpleReactValidator from 'simple-react-validator';

class LowestLogicalFarePage extends Component {

  state = {
    companyId: localStorage["440a28"],
    tripType: [
      {
        label: 'Domestic',
        value: 0
      },
      {
        label: 'International',
        value: 1
      }
    ],
    refundability: [
      {
        label: 'Does Not Matter',
        value: 0
      },{
        label: 'Refundable Only',
        value: 1
      },{
        label: 'Non-Refundable',
        value: 2
      }
    ],
    noOfStop: [
      {
        label: '0 Stops',
        value:0
      },{
        label: '1 Stop',
        value: 1
      },{
        label: '2 Stops',
        value: 2
      },{
        label: '2+ Stops',
        value: 3
      }
    ],
    noOfTime : [
      { label:'Does Not Matter', value:0 },
      { label:'Flights within user`s time window', value:1 },
      { label:'Flights within company config time window', value:2 }
    ],
    selectedNoOfStop: {
      label: '0 Stops',
      value:0
    },
    selectedNoOfTime:
      { label:'Does Not Matter', value:0 },

    selectedRefundability: {
      label: 'Refundable Only',
      value: 1
    },
    selectedTripType: {
      label: 'Domestic',
      value: 0
    },
    format: 'hh:mm',
    fromTime: moment(),
    toTime: moment(),
    llfId: '',
    llf: '',
    isEdit: false

  }

  llfToBeEdited = '';
  validator = new SimpleReactValidator();

  componentDidMount() {
    if(this.props.match.params.id != undefined){
      let llfId = this.props.match.params.id;
      this.setState({
        llfId: llfId,
        isEdit: true
      });
      this.getLLFDetails(llfId);
    }
  }

  getLLFDetails = async (llfId) => {
    let res = await travelPolicyService.getIndividualLowestLogicalFare(llfId);
    if(res != undefined && res.result){
      this.llfToBeEdited = res.result;
      this.setState({
        llf: this.llfToBeEdited,
        selectedTripType: this.state.tripType.find(item => item.value === this.llfToBeEdited.tripType),
        selectedNoOfStop: this.state.noOfStop.find(item => item.value === this.llfToBeEdited.noOfStop),
        selectedRefundability: this.state.refundability.find(item => item.value === this.llfToBeEdited.refundability),
        selectedNoOfTime: this.state.noOfTime.find(item => item.value == this.llfToBeEdited?.timeWindow),
      })
    }
  }

  handleDropdown =(e, type) => {
    // console.log(e, type);
    switch(type){
      case 'tripType':
        this.setState({
          selectedTripType: e
        });
        break;
      case 'refundability':
        this.setState({
          selectedRefundability: e
        });
        break;
      case 'noOfStop':
        this.setState({
          selectedNoOfStop: e
        });
        break;
      case 'noOfTime':
        this.setState({
          selectedNoOfTime:e
        })
        break;
    }
  }
  handleTimeChange = (changedTime, timeType) => {

    switch(timeType){
      case 'fromTime':
        this.setState({
          fromTime: changedTime
        }, () => console.log(this.state.fromTime))
        break;
        case 'toTime':
        this.setState({
          toTime: changedTime
        })
    }

  }


  handleCancel = () => {
    this.props.history.push('/administration/travel/travel-policy');
  }

  handleLLFSave = async e => {
    e.preventDefault();
    let payload = {
      companyId: this.state.companyId,
      tripType: this.state.selectedTripType.value,
      refundability: this.state.selectedRefundability.value,
      noOfStop: this.state.selectedNoOfStop.value,
      timeWindow :this.state.selectedNoOfTime.value
    };

    if(this.state.isEdit){
      if(this.validator.allValid()){
        try {
          let res = await travelPolicyService.updateLowestLogicalFare(this.state.llfId, payload);
          if(res != undefined){
            toast.success(res.message);
            this.props.history.push('/administration/travel/travel-policy');
          }

        }catch(err){
          console.log(err)
        }
      }else{
        this.validator.showMessages();
        this.forceUpdate();
      }

    }else{

      if(this.validator.allValid()){
        try {
          let res = await travelPolicyService.createLowestLogicalFare(payload);
          if(res != undefined){
            toast.success(res.message);
            this.props.history.push('/administration/travel/travel-policy');
          }


        }catch(err){
          console.log(err)
        }
      }else{
        this.validator.showMessages();
        this.forceUpdate();
      }



    }
  }


  render(){

  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">L</div>
            <div className="right">
              <h6 className="heading">Lowest Logical Fare (LLF)</h6>
              <p className="text">The Travel Policy & Rules feature allows administrators to manage company travel policy and configure violations.</p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <div className="row">
              <div className="col-6">
                <div className="form-group custom labelup">
                  <label>International/Domestic</label>
                    <Select
                      disabled={this.state.isEdit}
                      placeholder={this.state.selectedTripType.label || "Select Trip Type"}
                      options={this.state.tripType}
                      keyName="label"
                      controlFunc={e => this.handleDropdown(e,'tripType')}

                    />
                </div>

                <div className="form-group custom labelup">
                  <label>Refundability</label>
                  <Select
                      placeholder={this.state.selectedRefundability.label || "Select Refundability"}
                      options={this.state.refundability}
                      keyName="label"
                      controlFunc={e => this.handleDropdown(e,'refundability')}

                    />


                </div>
                {/* <div className="form-group custom labelup">
                  <label>Has Contract</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle btn-block"
                      data-toggle="dropdown"
                    >
                      Does Not Matter
                    </button>
                    <div className="dropdown-menu">
                      <button className="dropdown-item"> Does Not Matter</button>
                      <button className="dropdown-item"> Contract Carriers Only</button>
                    </div>
                  </div>
                </div> */}
                <div className="form-group custom labelup">
                  <label>Number of Stops</label>
                  <Select
                    placeholder={this.state.selectedNoOfStop.label || "Select Number Of Stops"}
                    options={this.state.noOfStop}
                    keyName="label"
                    controlFunc={e => this.handleDropdown(e,'noOfStop')}
                  />

                </div>
                <div className="form-group custom labelup">
                  <label>Time window</label>
                  <Select
                    placeholder={this.state.selectedNoOfTime?.label || "Select Time window"}
                    options={this.state.noOfTime}
                    keyName="label"
                    controlFunc={e => this.handleDropdown(e,'noOfTime')}
                  />

                </div>
                {/* <div className="form-group custom labelup">
                  <label>From Time</label>
                  <div>


                    <TimePicker
                        format={this.state.format}
                        value = {this.state.fromTime}
                        onChange={e => this.handleTimeChange(e,'fromTime')}
                        use12Hours={false}
                        className="timepicker-css"
                        showSecond={false}
                      />
                      <div className="help-block">{this.validator.message('From time ', this.state.fromTime, 'required')}</div>
                    </div>
                </div>
                <div className="form-group custom labelup"> */}
                  {/* <label>To Time</label>
                  <div>


                    <TimePicker
                      format={this.state.format}
                      value= {this.state.toTime}
                      onChange={e => this.handleTimeChange(e,'toTime')}
                      use12Hours
                      className="timepicker-css"
                      showSecond={false}

                      />
                      <div className="help-block">{this.validator.message('To time ', this.state.toTime, 'required')} </div>
                    </div>
                </div> */}
                {/* <div className="form-group custom labelup">
                  <label>Airport</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle btn-block"
                      data-toggle="dropdown"
                    >
                      Does Not Matter
                    </button>
                    <div className="dropdown-menu">
                      <button className="dropdown-item"> Does Not Matter</button>
                      <button className="dropdown-item"> Flights to/from user's preferred Airport</button>
                    </div>
                  </div>
                </div> */}

                {/* <div  className="form-group">
                  <label  className="switch">
                    <input type="checkbox" value="false" checked={} />
                    <span  className="slider round"></span>
                  </label>
                  <span className="font-weight-bold">Run Dedicated LLF Shop</span>
                </div> */}

                <div  className="btn-group-margin mt-4">
                  <button type="button" onClick={this.handleLLFSave}  className="btn">Save</button>
                  <button type="button" onClick={this.handleCancel}  className="btn">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
              }
};
export default LowestLogicalFarePage;
