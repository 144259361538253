import React from 'react';
import { Input } from '../common/_forms';
import {NavLink} from "react-router-dom";
const SsoLoginPage = (props) => {
    const { state, handleChange, error, handleSubmit } = props;
    return (
        <div className="login-ext">
            <div className="logo">
                <img src="/assets/images/left-menu-logo1.png" alt="Logo" />
            </div>
            <div className="d-table d-table__middle">
                <div>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="center-content">
                            <h2>SSO Login</h2>
                            <p><b>Company Email</b></p>
                            <div className="form-group custom">
                                <Input
                                    label="Email"
                                    type="email"
                                    name="email"
                                    value={state.email}
                                    error={error.message('email', state.email, 'required|email')}
                                    controlFunc={e => handleChange(e)}
                                />
                            </div>
                            <button type="submit" className="btn   btn-block mb-4">Login</button>
                            <p className="link">Back to <NavLink to="/login"> Login Page</NavLink></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default SsoLoginPage;