/* eslint-disable default-case */
import React, { Component } from 'react';
import WorkflowsStep3Page from './workflow-step3-html';
import { workflowService } from 'src/_services/workflow.service';
import uuid from 'uuid';
import { toast } from 'react-toastify';
import { alertConstants } from 'src/_constants';
import $ from 'jquery';
import { actionNameConstants } from 'src/_constants';
import SimpleReactValidator from 'simple-react-validator';
import { getDataWithActiveStatus } from 'src/utils/util';
import dynamicFields from 'src/_components/common/dynamicFields';
import { expenseReport } from "src/_services/expenseReport";
import { auditRuleService } from "src/_services/auditRule.service";
import { approverService, getConnectedList } from "../../../../../../_services";
import moment from "moment";
import _ from 'lodash';
import { setValue, getValue } from 'src/_components/common/lodsh';
class WorkflowsStep3 extends Component {

  state = {
    companyId: localStorage["440a28"],
    workflow_id: '',
    workflowRuleName: '',
    workflowRule_id: '',
    workflowStep_id: '',
    selectedStep: [],
    selectedRule: [],
    allWorkflowSteps: [],
    expressionArray: [],
    allFormHeaders: [],
    allWorkflowRules: [],
    allFieldNames: {},
    firstBracketOptions: [
      {
        value: '('
      }, {
        value: 'Not Selected'
      }
    ],
    lastBracketOptions: [
      {
        value: ')'
      },
      {
        value: 'Not Selected'
      }
    ],
    showRule: false,
    editRule: false,
    allActionOptions: [],
    selectedAction: {},
    action_id: '',
    action_value: '',
    searchUserList: [],
    searchedUsers: [],
    isSearchUserListOpen: false,
    selectedApprover: {
      name: '',
      id: ''
    },
    allEmailTemplates: [],
    selectedEmailTemplate: null,
    selectedEmail: null,
    emailDefaultApprover: false,
    allExceptions: [],
    page: 1,
    limit: 10,
    selectedExceptionVisibilty: null,
    selectedException: [],
    options: [
      { value: 1, label: 'User' },
      { value: 2, label: 'Approver' },
      { value: 3, label: 'Processor' },
      { value: 4, label: 'User And Approver' },
      { value: 5, label: 'User, Approver and Processor' },
    ],
    expressionOfRules: '',
    remaiminStacks: [],
    validAndOrAudit: true,
    isLoading: false,
    isSubmitClick: false,
    level: '',
    amount: '',
    approvers: [],
    allActionOptionsCondition: [],
    activePage: 1,
    approverData: []
  }

  validator = new SimpleReactValidator();

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.createExpression();
    if (this.props.match.params.id !== undefined && this.props.match.params.id !== '' && this.props.match.params.id !== null) {
      let workflow_id = this.props.match.params.id;
      this.setState({
        workflow_id: workflow_id
      });
      this.getWorkflowGeneralDetails(workflow_id);
      this.getApproverData();
    }
  }
  getApproverData = async () => {
    const response = await approverService.getApproverList(
      this.state.companyId,
      this.state.limit,
      this.state.activePage
    );
    if (response && response.result) {
      this.setState({
        approverData: response.result,
      });
    } else {
      this.setState({
        approverData: [],
      });
    }
  }
  getWorkflowGeneralDetails = async (workflow_id) => {
    this.setState({ isLoading: true });
    let res = await workflowService.getWorkflowGeneralDetails(workflow_id);
    let result = null;
    let service_module;
    if (res && res.result) {
      result = res.result[0];
      service_module = result.workflowType.master_value.service_module;
      this.setState({
        service_module: service_module
      });
      this.getAllFormHeaders(service_module);
    }
    this.getAllWorkFlowSteps();
    this.getWorkflowRules();
    this.getAllActionOptions();
    this.getEmailTemplates(service_module);
    this.getAllExceptions();
    this.getAllcurrencyList();
  }
  getAllcurrencyList = async () => {
    let response = await auditRuleService.getCurrencyList(this.state.companyId);
    if (response.result.length > 0) {
      this.setState({
        allCurrecies: response.result.map((currencies) => ({ value: currencies.id, label: currencies.itemCode }))
      })
    } else {
      this.setState({ allCurrecies: [] });
    }
  }
  getAllExceptions = async () => {

    let res = await workflowService.getAllExceptions(this.state.companyId);
    if (res) {
      this.setState({
        allExceptions: res.result,
        isLoading: false
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  getWorkflowRules = async () => {
    let res = await workflowService.getWorkflowRules(this.state.workflow_id);
    if (res) {
      this.setState({
        allWorkflowRules: res.result
      });
    }
  }

  getEmailTemplates = async (service_module) => {
    let res = await workflowService.getEmailTemplates(this.state.companyId, service_module);
    if (res.result != undefined) {
      await this.setState({ allEmailTemplates: res.result });
      this.generateEmailTemplatesOptions();
    }
  }

  // * Generate email options for dropdowns

  generateEmailTemplatesOptions = () => {
    if (this.state.allEmailTemplates.length !== 0) {
      this.setState({
        allEmailTemplatesOptions: this.state.allEmailTemplates
      })
    }
  }

  getAllFormHeaders = async (service_module) => {
    let serviceModuleArray = ['5c74ed3d77addf3b07acb014'];
    serviceModuleArray.push(service_module);
    let payload = {
      serviceModuleId: serviceModuleArray
    }
    let res = await workflowService.getFormHeaders(payload);
    if (res && res.result) {
      this.setState({
        allFormHeaders: res.result
      })
    }
  }

  // * Get all the workflowSteps
  getAllWorkFlowSteps = async () => {
    if (this.state.workflow_id) {
      let res = await workflowService.getAllWorkflowSteps(this.state.workflow_id);
      if (res) {
        this.setState({
          allWorkflowSteps: res.result
        });
      }
    }
  }

  getFormFieldsByFormHeader = async (formHeaderId, id = '') => {

    let response = await workflowService.getFieldNames(this.state.companyId, formHeaderId);
    if (response) {
      this.setState({ ['isLoading' + id]: false });
      this.setState(({ allFieldNames }) => ({
        allFieldNames: {
          ...allFieldNames,
          [formHeaderId]: (response.result)
        }
      }));
    } else {
      this.setState({ ['isLoading' + id]: false });
    }
  }

  createExpression = (e, idx = 0) => {


    // * for the first case e will not exist
    // eslint-disable-next-line no-unused-expressions
    e?.preventDefault?.();
    let expressionArray = [...this.state.expressionArray];
    let newExpression = [{
      id: uuid.v4(),
      type: 'operand',
      value: {},
    }, {

      id: uuid.v4(),
      type: 'operator',
      value: {}
    }];
    // * New expression length is being added to to idx, because insertion will take place at idx + 2 (previous expression + AND/OR)
    expressionArray.splice(idx + newExpression.length, 0, ...newExpression);
    // expressionArray.push(...newExpression)
    this.setState({ expressionArray });
  }

  handleFormHeaderSelection = (selectedHeader, id) => {

    this.setState({ ['isLoading' + id]: true });
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id == id);
    if (currentExpression) {
      currentExpression.value = {
        ...currentExpression.value,
        formHeader: selectedHeader,
        selectedField: "",
        selectedFieldValue: "",
        operator: ""
      }
    }
    this.setState({
      expressionArray
    })
    this.getFormFieldsByFormHeader(selectedHeader.id, id);
  }

  handleParenthesesSelection = (selectedBracket, id, bracketType) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id == id);
    if (currentExpression) {
      if (bracketType == '(') {
        currentExpression.value = {
          ...currentExpression.value,
          // firstBracket: selectedBracket.value
          firstBracket: selectedBracket.value === "Not Selected"?"":selectedBracket.value
        }
      } else if (bracketType == ')') {
        currentExpression.value = {
          ...currentExpression.value,
          // lastBracket: selectedBracket.value
          lastBracket: selectedBracket.value === "Not Selected"?"":selectedBracket.value
        }
      }
    }
    this.setState({
      expressionArray
    })
  }

  handleRuleNameChange = (e) => {
    this.setState({
      workflowRuleName: e.target.value
    });
  }

  stepSelectHandler = (step) => {
    // * when step is selected, deselect rule
    this.setState({ selectedRule: [] });
    let selectedStep = [...this.state.selectedStep];
    let selectedIndex = selectedStep.indexOf(step);
    if (selectedIndex == -1) {
      selectedStep = [step];
    } else {
      selectedStep = [];
    }
    this.setState({ selectedStep });
  }

  // * When user selects a step and clicks on new it will show the rule creation component
  toggleRuleCreationComponent = () => {
    let idx = 0
    let expressionArray = [];
    let newExpression = [{
      id: uuid.v4(),
      type: 'operand',
      value: {},
    }, {
      id: uuid.v4(),
      type: 'operator',
      value: {}
    }];
    // * New expression length is being added to to idx, because insertion will take place at idx + 2 (previous expression + AND/OR)
    expressionArray.splice(idx + newExpression.length, 0, ...newExpression);
    // expressionArray.push(...newExpression)
    this.setState({ expressionArray, workflowRuleName: '' });
    this.setState(({ showRule }) => ({
      showRule: !showRule,
      editRule: false
    })
    )
  }

  hideRuleComponentHandler = () => {
    this.setState({
      showRule: false
    })
  }

  removeExpression = (e, id) => {
    e.preventDefault();
    let expressionArray = [...this.state.expressionArray];
    expressionArray.splice(id, 2);
    this.setState({ expressionArray })
  }

  expressionCheckboxHandler = (e, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      currentExpression.value = {
        ...currentExpression.value,
        isChecked: e.target.checked
      }
    }
    this.setState({ expressionArray });
  }

  handleRadioButtonChange = (e, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      currentExpression.value = {
        operator: e.target.value
      }
    }
    this.setState({ expressionArray });
  }

  handleFieldNameSelection = (selectedField, id, change) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    let fieldNames = { ...this.state.allFieldNames };
    let formHeaderId = selectedField.formHeaderId ? selectedField.formHeaderId ? selectedField.formHeaderId : selectedField.value.formHeader.id : '';
    let isSystemField = selectedField.systemField ? true : false;
    if (selectedField.type !== dynamicFields.DATA_TYPE_SYSTEM_LIST && formHeaderId) {
      if (currentExpression) {
        if (change === 'change') {
          let parentFields = fieldNames[formHeaderId].filter((formFields) => (formFields.id === selectedField.multiSelectParentField && formFields.fieldLevel < selectedField.fieldLevel) || (formFields.multiSelectParentField === selectedField.multiSelectParentField && formFields.fieldLevel < selectedField.fieldLevel));
          parentFields = this.sortArrayOfObjectBasisOfFieldLbl(parentFields);
          let fields = parentFields.length > 0 && parentFields.map((fields) => ({ fieldName: [fields.fieldName], columnName: [fields.columnName] }));
          let resetParentFieldState = {};
          if (fields.length > 0) {
            resetParentFieldState = this.resetStateOfMultiLabelField(fields, id)
          }
          currentExpression.value = {
            ...currentExpression.value,
            selectedField: selectedField,
            selectedFieldValue: '',
            operator: '',
            isSystemField: isSystemField,
            ...resetParentFieldState,
            parentsFields: parentFields.length > 0 ? parentFields : [],
            isMultiSelected: parentFields.length > 0 ? true : false,
            currency: ''
          }
        } else {
          currentExpression.value = {
            ...currentExpression.value,
            isSystemField: isSystemField,
            selectedField: selectedField,
            selectedFieldValue: ''
          }
        }
      } else {
        currentExpression.value = {
          ...currentExpression.value,
          isSystemField: isSystemField,
          selectedField: [],
          parentsFields: [],
          selectedFieldValue: '',
        }
      }
    } else {
      if (currentExpression) {
        if (change === 'change') {
          currentExpression.value = {
            ...currentExpression.value,
            selectedField: selectedField,
            selectedFieldValue: '',
            operator: '',
            isMultiSelected: false,
            isSystemField: isSystemField,
            parentsFields: [],
            currency: ''
          }
        } else {
          currentExpression.value = {
            ...currentExpression.value,
            selectedField: selectedField,
            isSystemField: isSystemField,
            selectedFieldValue: ''
          }
        }

      } else {
        currentExpression.value = {
          ...currentExpression.value,
          isMultiSelected: false,
          isSystemField: isSystemField,
          selectedField: [],
          parentsFields: [],
        }
      }
    }
    this.setState({ expressionArray });
  }
  resetStateOfMultiLabelField = (fields) => {
    let expressionArray = [];
    fields.forEach((formFields) => {
      let state = [
        [formFields.columnName],
        [formFields.fieldName]
      ]
      expressionArray = [...expressionArray, ...state];
    })
    let data = expressionArray.flat(2);
    let fieldsObject = {};
    data.forEach((data, index) => {
      if (index % 2) {
        fieldsObject[data] = '';
      } else {
        fieldsObject[data] = [];
      }
    })
    return fieldsObject;
  }
  onMultiLblConnectHandler = async (value, selectedFieldDetails, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    let selectedFieldColumnName = currentExpression.value.selectedField.columnName;
    let selectedField = { ...currentExpression.value.selectedField };
    selectedField.connectedListData = {}
    let newFieldLevel = selectedFieldDetails.fieldLevel + 1;
    let fieldNames = { ...this.state.allFieldNames };
    let formHeaderId = selectedFieldDetails.formHeaderId ? selectedFieldDetails.formHeaderId : selectedFieldDetails.value.formHeader.id;
    if (selectedFieldDetails.fieldLevel === 1) {
      let existConnected = fieldNames[formHeaderId].filter((formFields) => selectedFieldDetails.id === formFields.multiSelectParentField && formFields.fieldLevel === newFieldLevel);
      if (existConnected.length > 0) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(companyId, value.value);
        let childName = existConnected[0].columnName;
        let fieldValue = existConnected[0].fieldName;
        if (response && selectedFieldColumnName === childName) {
          selectedField.connectedListData.listManagementData = response;
          currentExpression.value = {
            ...currentExpression.value, [selectedFieldDetails.fieldName]: value,
            selectedField: selectedField, selectedFieldValue: ''
          }
        } else {
          currentExpression.value = {
            ...currentExpression.value, [selectedFieldDetails.fieldName]: value,
            [fieldValue]: '', [childName]: response.map((list) => ({ label: list.itemName, value: list.id, columnName: selectedFieldDetails.childColumnName }))
          }
        }
      }
    } else if (selectedFieldDetails.fieldLevel > 1) {
      let existConnected = fieldNames[formHeaderId].filter((formFields) => selectedFieldDetails.multiSelectParentField === formFields.multiSelectParentField && formFields.fieldLevel === newFieldLevel);
      if (existConnected.length > 0) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(companyId, value.value);
        let childName = existConnected[0].columnName;
        let fieldValue = existConnected[0].fieldName;
        if (response && selectedFieldColumnName === childName) {
          selectedField.connectedListData.listManagementData = response;
          currentExpression.value = {
            ...currentExpression.value, [selectedFieldDetails.fieldName]: value,
            selectedField: selectedField, selectedFieldValue: ''
          }
        } else {
          currentExpression.value = {
            ...currentExpression.value, [selectedFieldDetails.fieldName]: value, [fieldValue]: '', [childName]: response.map((list) => ({ label: list.itemName, value: list.id, columnName: selectedField.childColumnName })),
          };
        }
      }
    }
    this.setState({ expressionArray });
  }

  handleExpressionOperator = (selectedOperator, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id == id);
    if (currentExpression) {
      currentExpression.value = {
        ...currentExpression.value,
        operator: selectedOperator
      }
    }
    this.setState({ expressionArray });
  }
  onCurrencySelectHandler = (currency, id) => {

    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      currentExpression.value = {
        ...currentExpression.value,
        currency: currency
      }
    }
    this.setState({ expressionArray });
  }
  sortArrayOfObjectBasisOfFieldLbl = (formAndFields) => {
    formAndFields.sort(function (fieldObject1, fieldObject2) {
      var keyA = new Date(fieldObject1.fieldLevel),
        keyB = new Date(fieldObject2.fieldLevel);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return formAndFields;
  }

  fieldValueSelectHandler = (selectedFieldValue, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id == id);
    if (currentExpression) {
      currentExpression.value = {
        ...currentExpression.value,
        selectedFieldValue: selectedFieldValue
      }
    }
    this.setState({ expressionArray });
  }

  fieldValueChangeHandler = (e, id, dataType) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (dataType === dynamicFields.DATA_TYPE_INTEGER || dataType === dynamicFields.DATA_TYPE_AMOUNT) {
      if (currentExpression && !isNaN(e.target.value)) {
        currentExpression.value = {
          ...currentExpression.value,
          selectedFieldValue: e.target.value
        }
      }
    } else if (dataType === dynamicFields.DATA_TYPE_BOOLEAN || dataType === dynamicFields.DATA_TYPE_CHECKBOX) {
      currentExpression.value = {
        ...currentExpression.value,
        selectedFieldValue: !currentExpression.value.selectedFieldValue
      }
    } else if (dataType === dynamicFields.DATA_TYPE_DATE) {
      if (!isNaN(e)) {
        currentExpression.value = {
          ...currentExpression.value,
          selectedFieldValue: moment(e).format("DD MMM YYYY")
        }
      }
    } else if (dataType === dynamicFields.DATA_TYPE_SEARCH) {
      currentExpression.value = {
        ...currentExpression.value,
        selectedFieldValue: e
      }
    } else {
      if (currentExpression) {
        currentExpression.value = {
          ...currentExpression.value,
          selectedFieldValue: e.target.value
        }
      }
    }
    this.setState({ expressionArray });
  }


  // * All rule handlers

  // * Select a rule

  ruleSelectHandler = (rule) => {
    // console.log(rule)
    let data = this.state.allWorkflowSteps.filter((data) => {
      return data.workflowStep_id === rule.workflowStep_id
    })

    if ((data[0].worlkflowRole.master_value.master_name_value === "Authorized Approver") || (data[0].worlkflowRole.master_value.master_name_value === "Cost Object Approver")) {
      var data1 = (this.state.allActionOptions.filter((data) => {
        return data.master_name_value == "Add Approvers"
      }))
      this.setState({
        allActionOptionsCondition: data1
      })
    } else {
      var data2 = (this.state.allActionOptions.filter((data) => {
        return data.master_name_value !== "Add Approvers"
      }))
      this.setState({
        allActionOptionsCondition: data2
      })
    }
    this.reset()
    let selectedRule = [...this.state.selectedRule];

    let selectedRuleIndex = selectedRule.indexOf(rule);
    // let expressionArray = [...this.state.expressionArray];

    if (selectedRuleIndex === -1) {
      // * from selected rule get workflow step and rule id
      this.setState({
        workflowRule_id: rule.workflowRule_id,
        workflowStep_id: rule.workflowStep_id,
        workflowRuleName: rule.worlflowRuleName
      })
      selectedRule = [rule];
      if (rule.data.action) {
        this.setActionsInState(rule.data.action);
      } else {
      }
      let expressionFromSelectedRule = rule.data.rules;
      let expressionArray = expressionFromSelectedRule.map((expression, idx) => {
        if (idx % 2 === 0) {
          this.getFormFieldsByFormHeader(expression.value.dataObjectName.id, expression.id);
          if (!expression.value.isMultiSelected) {
            return {
              id: expression.id,
              type: expression.type,
              value: {
                firstBracket: expression.value.firstBracket,
                lastBracket: expression.value.lastBracket,
                isChecked: expression.value.isChecked,
                operator: expression.value.operator,
                formHeader: expression.value.dataObjectName,
                selectedField: expression.value.dataObjectFieldName,
                selectedFieldValue: expression.value.dataObjectFieldValue,
                currency: expression.value.currency ? expression.value.currency : '',
                isSystemField: expression.value.isSystemField ? expression.value.isSystemField : false
              }
            }
          } else {
            return expression;
          }

        } else {
          return expression
        }
      });
      // * For getting the last operator for edit mode
      expressionArray.push({
        id: uuid.v4(),
        type: 'operator',
        value: {}
      });

      // * Test code check and verify plz
      this.setState({ expressionArray });

    } else {
      selectedRule = [];
      this.reset()
    }
    this.setState({ selectedRule });
  }


  setActionsInState = (action) => {
    switch (action.action_name.master_name_value) {
      case actionNameConstants.REJECT:
        this.setState({
          selectedAction: action.action_name,
          action_value: action.action_value
        })
        break;

      case actionNameConstants.SKIP_STEP:
        this.setState({
          selectedAction: action.action_name,
          action_value: action.action_value
        });
        break;

      case actionNameConstants.SEND_BACK_TO_EMPLOYEE:
        this.setState({
          selectedAction: action.action_name,
          action_value: action.action_value
        });
        break;

      case actionNameConstants.CHANGE_APPROVER:
        this.setState({
          selectedAction: action.action_name,
          selectedApprover: action.action_value
        });
        break;

      case actionNameConstants.SEND_EMAIL:
        this.setState({
          selectedAction: action.action_name,
          email: action?.action_value?.email,
          emailDefaultApprover: action?.action_value?.emailDefaultApprover,
          emailTemplate: action?.action_value?.emailTemplate,
        });
        break;

      case actionNameConstants.GENERATE_EXCEPTION:
        this.setState({
          selectedAction: action.action_name,
          selectedException: action.action_value.exception,
          selectedExceptionVisibilty: this.state.options.find(item => item.value === action.action_value.exceptionVisibilty)
        });

      case actionNameConstants.ADD_APPROVER:
        this.setState({
          selectedAction: action.action_name,
          action_value: action.action_value
        })
      default:
        return null;

    }
  }

  modifyRuleHandler = () => {
    this.setState(({ showRule }) => ({
      showRule: !showRule,
      editRule: true
    })
    );
  }

  reset = () => {
    this.setState({
      selectedStep: [],
      selectedRule: [],
      workflowRule_id: '',
      workflowStep_id: '',
      workflowRuleName: '',
      selectedAction: '',
      selectedException: [],
      selectedExceptionVisibilty: ''
    })
  }

  // * Create and submit new rule

  createRuleHandler = async (e) => {
    e.preventDefault();
    let remaiminStacks = [];
    let validAndOrAudit = this.state.validAndOrAudit;
    if (this.validator.allValid()) {
      this.setState({ isSubmitClick: true });
      let lengthOfExpressionArray = this.state.expressionArray.length;
      let expressionArray = this.state.expressionArray.slice(0, lengthOfExpressionArray - 1);
      let expression2 = expressionArray.map((expression, idx) => {
        if (idx % 2 == 0) {
          let firstBracket = expression.value.firstBracket !== undefined ? expression.value.firstBracket : '';
          let lastBracket = expression.value.lastBracket !== undefined ? expression.value.lastBracket : '';
          if (firstBracket !== undefined && firstBracket !== "" && firstBracket === "(") {
            remaiminStacks.push(firstBracket);
          }
          if (lastBracket !== undefined && lastBracket !== "" && lastBracket === ")") {
            if (remaiminStacks.length > 0) {
              remaiminStacks.pop();
            } else {
              validAndOrAudit = validAndOrAudit && false;
            }

          }
          if (expression.value.isMultiSelected) {
            return {
              id: expression.id,
              type: expression.type,
              value: {
                ...expression.value,
                dataObjectFieldName: expression.value.selectedField,
                dataObjectName: expression.value.formHeader,
                dataObjectFieldValue: expression.value.selectedFieldValue,
                isSystemField: expression.value.isSystemField
              }
            }
          } else {
            return {
              id: expression.id,
              type: expression.type,
              value: {
                firstBracket: expression.value.firstBracket ? expression.value.firstBracket : "",
                dataObjectName: expression.value.formHeader,
                isChecked: expression.value.isChecked ? expression.value.isChecked : false,
                lastBracket: expression.value.lastBracket ? expression.value.lastBracket : "",
                operator: expression.value.operator,
                dataObjectFieldName: expression.value.selectedField,
                dataObjectFieldValue: expression.value.selectedFieldValue,
                check: expression.value.check ? expression.value.check : false,
                isMultiSelected: false,
                currency: expression.value.currency ? expression.value.currency : '',
                isSystemField: expression.value.isSystemField
              }
            }
          }
        } else {
          if (expression.value.operator !== undefined && expression.value.operator !== "" && expression.value.operator !== null) {
            validAndOrAudit = validAndOrAudit && true;
          } else {
            validAndOrAudit = validAndOrAudit && false;
          }
          return expression
        }
      })

      // remove unwanted list mangement data
      expression2.forEach(item => {
        if (item.type === "operand") {
          if (getValue(item, "value.parentsFields", []).length !== 0) {
            getValue(item, "value.parentsFields", []).forEach(list => {
              let removeId = getValue(item, `value.${list.labelName}`, '')
              let remain = getValue(list, "connectedListData.listManagementData", []).filter(resp => { return resp.id === removeId.value });
              setValue(list, "connectedListData.listManagementData", remain)
            })
          } else {
            let removeId = getValue(item, "value.selectedFieldValue")
            let remain = getValue(item, "value.selectedField.connectedListData.listManagementData", []).filter(resp => { return resp.id === removeId.value });
            setValue(item, "value.selectedField.connectedListData.listManagementData", remain)
            setValue(item,"value.dataObjectFieldName.connectedListData.listManagementData",[])
          }
        }
      })
      // setValue(expression2[0].value.dataObjectFieldName, "connectedListData.listManagementData", [])
      let payload = {
        companyId: this.state.companyId,
        workflow_id: this.state.workflow_id,
        workflowStep_id: this.state.selectedStep[0].workflowStep_id,
        worlflowRuleName: this.state.workflowRuleName,
        data: {
          rules: expression2

        }
      }
      // console.log(payload)
      if (remaiminStacks.length > 0 || !validAndOrAudit) {
        toast.error('Your expression not valid.');
        this.setState({ isSubmitClick: false })
      } else {
        let res = await workflowService.createRuleForWorkflow(payload);
        if (res) {
          toast.success(alertConstants.CREATED_SUCCESSFULLY);
          this.reset()
          this.setState({
            showRule: false,
            isSubmitClick: false
          })
          this.getWorkflowRules();
        } else {
          this.setState({ isSubmitClick: false })
          toast.warn('Not created.');
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  updateRuleHandler = async (e) => {
    e.preventDefault();
    let remaiminStacks = [];
    let validAndOrAudit = this.state.validAndOrAudit;
    if (this.validator.allValid()) {
      this.setState({ isSubmitClick: true });
      let lengthOfExpressionArray = this.state.expressionArray.length;
      // let expressionArray = [...this.state.expressionArray];
      let expressionArray = this.state.expressionArray.slice(0, lengthOfExpressionArray - 1);
      let expression2 = expressionArray.map((expression, idx) => {
        if (idx % 2 == 0) {
          let firstBracket = expression.value.firstBracket !== undefined ? expression.value.firstBracket : '';
          let lastBracket = expression.value.lastBracket !== undefined ? expression.value.lastBracket : '';
          if (firstBracket !== undefined && firstBracket !== "" && firstBracket == "(") {
            remaiminStacks.push(firstBracket);
          }
          if (lastBracket !== undefined && lastBracket !== "" && lastBracket == ")") {
            if (remaiminStacks.length > 0) {
              remaiminStacks.pop();
            } else {
              validAndOrAudit = validAndOrAudit && false;
            }
          }
          if (expression.value.isMultiSelected) {
            return {
              id: expression.id,
              type: expression.type,
              value: {
                ...expression.value,
                dataObjectFieldName: expression.value.selectedField,
                dataObjectName: expression.value.formHeader,
                dataObjectFieldValue: expression.value.selectedFieldValue,
                isSystemField: expression.value.isSystemField
              }
            }
          } else {
            return {
              id: expression.id,
              type: expression.type,
              value: {
                firstBracket: expression.value.firstBracket ? expression.value.firstBracket : "",
                dataObjectName: expression.value.formHeader,
                isChecked: expression.value.isChecked ? expression.value.isChecked : false,
                lastBracket: expression.value.lastBracket ? expression.value.lastBracket : "",
                operator: expression.value.operator,
                dataObjectFieldName: expression.value.selectedField,
                dataObjectFieldValue: expression.value.selectedFieldValue,
                check: expression.value.check ? expression.value.check : false,
                isMultiSelected: false,
                currency: expression.value.currency ? expression.value.currency : '',
                isSystemField: expression.value.isSystemField
              }
            }
          }
        } else {
          if (expression.value.operator !== undefined && expression.value.operator !== "" && expression.value.operator !== null) {
            validAndOrAudit = validAndOrAudit && true;
          } else {
            validAndOrAudit = validAndOrAudit && false;
          }
          return expression
        }
      });
      // remove unwanted list mangement data

      expression2.forEach(item => {
        if (item.type === "operand") {
          if (getValue(item, "value.parentsFields", []).length !== 0) {
            getValue(item, "value.parentsFields", []).forEach(list => {
              let removeId = getValue(item, `value.${list.labelName}`, '')
              let remain = getValue(list, "connectedListData.listManagementData", []).filter(resp => { return resp.id === removeId.value });
              setValue(list, "connectedListData.listManagementData", remain)
            })
          } else {
            let removeId = getValue(item, "value.selectedFieldValue")
            let remain = getValue(item, "value.selectedField.connectedListData.listManagementData", []).filter(resp => { return resp.id === removeId.value });
            setValue(item, "value.selectedField.connectedListData.listManagementData", remain)
            setValue(item,"value.dataObjectFieldName.connectedListData.listManagementData",[])
          }
        }
      })


      let payload = {
        companyId: this.state.companyId,
        workflow_id: this.state.workflow_id,
        workflowStep_id: this.state.workflowStep_id,
        worlflowRuleName: this.state.workflowRuleName,
        data: {
          rules: expression2

        }
      }
      // console.log(payload)
      if (remaiminStacks.length > 0 || !validAndOrAudit) {
        toast.error('Your expression not valid.');
        this.setState({ isSubmitClick: false })
      } else {
        let res = await workflowService.updateWorkflowRules(this.state.workflowRule_id, payload);
        if (res) {
          // this.getAllWorkFlowSteps();
          this.reset()
          this.getWorkflowRules();
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
          this.setState({ showRule: false, isSubmitClick: false });
        } else {
          this.setState({ isSubmitClick: false })
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    // let expressionArray = this.state.expressionArray.slice(0, lengthOfExpressionArray - 1);
  }

  // * Actions related  handlers

  getAllActionOptions = async () => {
    let res = await workflowService.getMasterOptionsForRules();
    if (res?.result?.length) {
      let actionName = res.result.find(master => master.master_name === 'action_name');
      let actionOptions = actionName.master_value;
      this.setState({ allActionOptions: actionOptions, actionName });
    }
  }

  handleActionSelection = (selectedAction) => {
    this.setState({
      selectedAction,
      action_id: uuid.v4()
    });

  }

  skipStepMessageHandler = (e) => {
    this.setState({
      action_value: {
        message: e.target.value
      }
    });

  }

  rejectStepMessageHandler = (e) => {
    this.setState({
      action_value: {
        message: e.target.value
      }
    })
  }

  // * create action handler
  handleActionCreationForRule = async (e, modalType) => {
    e.preventDefault();
    let payload = {};

    switch (modalType) {
      case actionNameConstants.SKIP_STEP:
        payload = {
          workflow_id: this.state.workflow_id,
          companyId: this.state.companyId,
          data: {
            action: {
              action_name: this.state.selectedAction,
              action_id: this.state.action_id,
              action_value: this.state.action_value
            }
          }
        }
        break;
      case actionNameConstants.CHANGE_APPROVER:
        payload = {
          workflow_id: this.state.workflow_id,
          companyId: this.state.companyId,
          data: {
            action: {
              action_name: this.state.selectedAction,
              action_id: this.state.action_id,
              action_value: this.state.selectedApprover
            }
          }
        }
        break;
      case
        actionNameConstants.REJECT:
        payload = {
          workflow_id: this.state.workflow_id,
          companyId: this.state.companyId,
          data: {

            action: {
              action_name: this.state.selectedAction,
              action_id: this.state.action_id,
              action_value: this.state.action_value
            }
          }
        }
        break;
      case actionNameConstants.SEND_BACK_TO_EMPLOYEE:
        payload = {
          workflow_id: this.state.workflow_id,
          companyId: this.state.companyId,
          data: {
            action: {
              action_name: this.state.selectedAction,
              action_id: this.state.action_id,
              action_value: this.state.action_value
            }
          }
        }
        break;
      case actionNameConstants.SEND_EMAIL:
        let emailData = {
          email: this.state.selectedEmail,
          emailDefaultApprover: this.state.emailDefaultApprover,
          emailTemplate: this.state.selectedEmailTemplate
        }
        payload = {
          workflow_id: this.state.workflow_id,
          companyId: this.state.companyId,
          data: {
            action: {
              action_name: this.state.selectedAction,
              actions_id: this.state.action_id,
              action_value: emailData
            }
          }
        };
        break;
      case actionNameConstants.GENERATE_EXCEPTION:
        let exceptionData = {
          exceptionVisibilty: this.state?.selectedExceptionVisibilty?.value,
          exception: this.state?.selectedException
        }
        if (exceptionData.exceptionVisibilty && exceptionData.exception) {
          payload = {
            workflow_id: this.state.workflow_id,
            companyId: this.state.companyId,
            data: {
              action: {
                action_name: this.state.selectedAction,
                actions_id: this.state.action_id,
                action_value: exceptionData
              }
            }
          };
        } else {
          payload = {
            workflow_id: this.state.workflow_id,
            companyId: this.state.companyId,
          }
        }
        break;

      case actionNameConstants.ADD_APPROVER:
        let approverss = this.state.approvers
        let approvers = approverss
        payload = {
          workflow_id: this.state.workflow_id,
          companyId: this.state.companyId,
          data: {
            action: {
              action_name: this.state.selectedAction,
              actions_id: this.state.action_id,
              action_value: { approvers }
            }
          }

        }
        break;

      default:
        return null;
    }
    if (modalType === actionNameConstants.ADD_APPROVER) {
      if (Object.keys(payload.data.action.action_value.approvers).length !== 0) {
        let res = await workflowService.createAndUpdateAction(this.state.workflowRule_id, payload);
        if (res) {
          this.reset()
          this.setState({
            approvers: []
          })
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
          $('.close').click();
          this.getWorkflowRules();
        }
      } else {
        toast.warn(alertConstants.APPROVER_ERROR);
      }
    } else {
      let res = await workflowService.createAndUpdateAction(this.state.workflowRule_id, payload);
      if (res) {
        this.reset()
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        $('.close').click();
        this.getWorkflowRules();
      }
    }
  }
  convertUserListToNames = (users) => {
    let searchedUsers = users.map(user => {
      let cleanUser = {
        id: user.id
      }
      if (user.fieldData.length) {
        let firstName = (user.fieldData.find(field => field.columnName == "EMPLOYEE_FIRST_NAME")).fieldValue;
        let middleName = (user.fieldData.find(field => field.columnName == "MIDDLE_NAME")).fieldValue;
        let lastName = (user.fieldData.find(field => field.columnName == "EMPLOYEE_LAST_NAME")).fieldValue;

        cleanUser.name = firstName + ' ' + middleName + ' ' + lastName;
      } else {
        cleanUser.name = user.email
      }
      return cleanUser;
    });
    this.setState({
      searchedUsers
    });
  }


  handleChangeApprover = async (e) => {
    let searchTerm = e.target.value;
    this.setState({
      selectedApprover: {
        name: e.target.value
      }
    });
    if (searchTerm != '') {
      // * if the user is typing show the matched search users.
      this.setState({
        isSearchUserListOpen: true,
        selectedApprover: {
          name: e.target.value
        }
      });
      let approver = {
        "isApprover": true
      }
      let res = await workflowService.changeApprover(this.state.companyId, searchTerm, approver);
      if (res && res.count) {
        this.convertUserListToNames(res.result);
      }
    } else {
      this.setState({ searchUserList: [], searchedUsers: [] });
    }
  }

  handleApprovalCancelClick = () => {
    this.reset()
    this.setState({
      isSearchUserListOpen: false,
      selectedApprover: {
        name: ''
      },
      searchUserList: [],
      searchedUsers: []
    });
  }
  selectChangeApprover = (user) => {
    let selectedApprover = {
      name: user.name,
      id: user.id
    }
    this.setState({
      selectedApprover,
      isSearchUserListOpen: false
    });
  }

  emailTemplateSelectHandler = (e) => {
    this.setState({
      selectedEmailTemplate: e
    })
  }

  emailChangeHandler = (e) => {
    this.setState({
      selectedEmail: e.target.value
    });
  }

  emailDefaultApproverHandler = e => {
    this.setState({
      emailDefaultApprover: e.target.checked
    })
  }

  sendBackToEmployeeHandler = e => {
    this.setState({
      action_value: {
        message: e.target.value
      }
    });
  }

  handleExceptionSelection = (exception) => {
    let selectedException = [...this.state.selectedException];
    let selectedExceptionIndex = selectedException.indexOf(exception);

    if (selectedExceptionIndex === -1) {
      selectedException = [exception];
    } else {
      selectedException = [];
    }
    this.setState({
      selectedException
    })
  }

  handleApproveSubmission = async (approvers) => {
    let response = await approverService.createApprover(approvers);
    if (response) {
      $('.close').click()
      this.getApproverData();
      toast.success(response.message);
      this.setState({
        approverData: [],
        level: '',
        amount: '',
      });
    } else {
      this.setState({ isClickSubmit: false });
    }

    // var approvers = { approvers }
    // this.setState({
    //   approvers: approvers
    // })
  }

  approverChangeCheckbox = (id, name, level, amount) => {
    if (!this.state.selectedRule[0].data.action) {
      var approverss = [{ id, name, level, amount }]
      this.state.approvers.push(approverss[0])
    } else {

      var approvers = this.state.selectedRule[0].data.action.action_value.approvers;
      let selectedRuleNew = [];
      this.state.approverData.forEach(o1 => {
        let newRule = approvers.filter(function (o2) {
          return o1.approverId === o2.id;
        })
        if (newRule.length > 0) {
          selectedRuleNew.push(newRule[0])
        }
      })
      this.state.selectedRule[0].data.action.action_value.approvers.splice(0, this.state.selectedRule[0].data.action.action_value.approvers.length, ...selectedRuleNew)
      let exist = approvers.findIndex(item => {
        return item.id === id;
      });
      if (exist === -1) {
        approvers.push({
          id, name, level, amount
        })
      }
      else {
        approvers.splice(exist, 1);
      }
      // var approvers = { approvers }
      this.setState({
        approvers: approvers
      })
    }
  }


  handleExceptionVisibilty = (selectedExceptionVisibilty) => {
    this.setState({
      selectedExceptionVisibilty
    });
  }

  removeRuleActionHandler = async () => {
    let selectedRule = [...this.state.selectedRule];
    if (selectedRule[0].data.action) {
      delete selectedRule[0].data.action;
      let payload = {
        workflow_id: this.state.workflow_id,
        companyId: this.state.companyId,
        data: {
          deleteAction: true
        }
      }
      let res = await workflowService.createAndUpdateAction(this.state.workflowRule_id, payload);
      if (res) {
        this.reset();
        toast.success(alertConstants.DELETED_SUCCESSFULLY);
        this.getWorkflowRules();
      } else {
        toast.info(alertConstants.ERROR);
        this.getWorkflowRules();
      }
    }
  }

  removeRuleHandler = async (e) => {
    e.preventDefault();
    if (this.state.selectedRule) {
      let res = await workflowService.removeRule(this.state.companyId, this.state.selectedRule[0].workflowRule_id);
      if (res) {
        this.reset()
        toast.success(alertConstants.DELETED_SUCCESSFULLY);
        this.getWorkflowRules();
      } else {
        toast.warn(alertConstants.ERROR);
        this.getWorkflowRules();
      }
    }
  }
  loadOptions = async (newValue) => {
    return expenseReport.getAllLocationsName(newValue).then((data) => {
      return data.result.map(list => {
        return {
          label: list.name + ',' + list.countryId.name,
          value: list.id,
          id: list.id
        };
      });
    })
  }

  render() {
    return (
      <div>
        <WorkflowsStep3Page
          {...this.state}
          createExpression={this.createExpression}
          removeExpression={this.removeExpression}
          handleFormHeaderSelection={this.handleFormHeaderSelection}
          handleFieldNameSelection={this.handleFieldNameSelection}
          handleParenthesesSelection={this.handleParenthesesSelection}
          handlePageChange={this.handlePageChange}
          handleRuleNameChange={this.handleRuleNameChange}
          stepSelectHandler={this.stepSelectHandler}
          toggleRuleCreationComponent={this.toggleRuleCreationComponent}
          hideRuleComponentHandler={this.hideRuleComponentHandler}
          expressionCheckboxHandler={this.expressionCheckboxHandler}
          handleRadioButtonChange={this.handleRadioButtonChange}
          handleExpressionOperator={this.handleExpressionOperator}
          fieldValueSelectHandler={this.fieldValueSelectHandler}
          fieldValueChangeHandler={this.fieldValueChangeHandler}
          createRuleHandler={this.createRuleHandler}
          ruleSelectHandler={this.ruleSelectHandler}
          modifyRuleHandler={this.modifyRuleHandler}
          updateRuleHandler={this.updateRuleHandler}
          // * Action Handlers
          handleActionSelection={this.handleActionSelection}
          skipStepMessageHandler={this.skipStepMessageHandler}
          handleActionCreationForRule={this.handleActionCreationForRule}
          handleChangeApprover={this.handleChangeApprover}
          selectChangeApprover={this.selectChangeApprover}
          rejectStepMessageHandler={this.rejectStepMessageHandler}
          emailTemplateSelectHandler={this.emailTemplateSelectHandler}
          emailChangeHandler={this.emailChangeHandler}
          emailDefaultApproverHandler={this.emailDefaultApproverHandler}
          sendBackToEmployeeHandler={this.sendBackToEmployeeHandler}
          handleExceptionSelection={this.handleExceptionSelection}
          handleExceptionVisibilty={this.handleExceptionVisibilty}
          removeRuleActionHandler={this.removeRuleActionHandler}
          removeRuleHandler={this.removeRuleHandler}
          onMultiLblConnectHandler={this.onMultiLblConnectHandler}
          onCurrencySelectHandler={this.onCurrencySelectHandler}
          handleApprovalCancelClick={this.handleApprovalCancelClick}
          loadOptions={this.loadOptions}
          handleApproveSubmission={this.handleApproveSubmission}
          approverChangeCheckbox={this.approverChangeCheckbox}
          // error validator
          error={this.validator}
        />
      </div>
    )
  }
}
export default WorkflowsStep3;
