import React from 'react';

const SendFeedback = () => {
    return (
        <div
            className="modal"
            id="sendFeedbackModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="sendFeedbackModalLabel"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-md"
                role="document"
            >
                <div className="modal-content border-0">
                    <div className="modal-head">
                        <h5 className="modal-title" id="sendFeedbackModalLabel">
                            Send Feedback
                                </h5>
                    </div>
                    <div className="modal-middle pm-30">
                        <div className="form-group custom textarea mb-3">
                            <textarea
                                data-gramm_editor="false"
                                spellCheck="false"
                                rows="3" className="form-control " placeholder="&nbsp;" />
                            <label className="transform-none">Let us know of your issue in detail or let  us know of your ideas</label>
                        </div>
                        <h6 className="mb-4">Attach Screenshot</h6>
                        <div className=" mb-3 upload-input mb-4 text-center">
                            <input
                                type="file"
                            />
                            <div className="py-4 check-box">
                                <i className="material-icons">cloud_upload</i>
                                <div>Drag and Drop to Upload</div>
                            </div>
                        </div>
                        <div className="modal-last btn-group-margin">
                            <button className="btn" data-dismiss="modal">Submit</button>
                            <button className="btn close" data-dismiss="modal" >Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SendFeedback;