import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { cartServices } from "src/_services";
import { FlightCartItem } from "../checkout/item/flightItem";
import FlightCartDetail from "../checkout/item/flightDetail";
import HotelCartDetail from '../checkout/item/hotelDetail';
import FlightComboCartItem from '../checkout/item/comboFlightItem';
import { HotelCartItem } from "../checkout/item/hotelItem";
import AddNewModifyServicePage from "../checkout/AddNewService";
import { inject } from "mobx-react";
import { MenuCartSubPage } from "../checkout/MenuCartSubmit";
import FlightCartComboDetail from "../checkout/item/comboFlightDetail";
@inject("serviceStore")
class ItineraryPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getList();
  }
  cartList = [];
  getList = async () => {
    let tripID = sessionStorage['32AWE'];
    var resp = await cartServices.getCartListDetail(tripID);
    if (resp) {
      this.cartList = resp;
      sessionStorage.setItem('cartId', resp.id)
      this.forceUpdate();
    }
  };
  chooseService = async e => {
    var resp = await this.props.serviceStore.getServiceType(e);
    this.forceUpdate();
  };
  removeFlightCart = async (e) => {
    var req = {
      type: "flight",
      TRIP_ID: sessionStorage.TRIPID,
      flight_fare_id: e
    }
    var resp = await cartServices.removeCartUserData(req);
    if (resp) {
      this.type = "";
      this.flightDetailView = [];
      this.selectedItem = "";
      this.getList();

      window.location.reload();
    }
  }
  removeHotelCart = async (e) => {
    var req = {
      type: "hotel",
      TRIP_ID: sessionStorage.TRIPID,
      room_id: e
    }
    var resp = await cartServices.removeCartUserData(req);
    if (resp) {
      this.type = "";
      this.flightDetailView = [];
      this.selectedItem = "";
      this.getList();
      window.location.reload();
    }
  }
  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " m " : " m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  type = "";
  flightDetailView = [];
  selectedItem = "";
  chooseFlight = (e, type, key) => {
    this.type = type;
    this.flightDetailView = e;
    if (this.selectedItem === key) {
      this.selectedItem = "";
      this.flightDetailView = "";
      this.type = "";
    } else {
      this.selectedItem = key;
    }
    this.forceUpdate();
  };
  hotelDetailView = [];
  chooseHotel = (e, type, key) => {
    this.type = type;
    this.hotelDetailView = e;
    if (this.selectedItem === key) {
      this.selectedItem = "";
      this.hotelDetailView = "";
      this.type = "";
    } else {
      this.selectedItem = key;
    }
    this.forceUpdate();
  };

  render() {
    return (
      <div>
        {/* {this.cartDetails && <MenuCartSubPage cartList={this.cartDetails} />} */}
        <div className="p-padding top-48 pb-0">
          <div className="row">
            <div className="col-md-6">
              <div className="itinerary-scroll">
                <ul className="travel-tabs">
                  {/* Flight Config Starts */}
                  {this.cartList &&
                    this.cartList.data &&
                    this.cartList.data.flightData &&
                    this.cartList.data.flightData.map((list, key) => {
                      if (list.fare) {
                        return (
                          <FlightComboCartItem
                            chooseFlight={this.chooseFlight}
                            index={key}
                            selectedItem={this.selectedItem}
                            secondsToHms={this.secondsToHms}
                            key={key}
                            item={list}
                            nonEdit={true}
                            removeCart={this.removeFlightCart}
                          />
                        )
                      } else {
                        return (
                          <FlightCartItem
                            chooseFlight={this.chooseFlight}
                            index={key}
                            selectedItem={this.selectedItem}
                            secondsToHms={this.secondsToHms}
                            key={key}
                            item={list}
                            nonEdit={true}
                            removeCart={this.removeFlightCart}
                          />
                        );
                      }
                    })}
                  {/* Flight Config end */}
                  {/* hotells Config Starts */}
                  {this.cartList &&
                    this.cartList.data &&
                    this.cartList.data.hotelData &&
                    this.cartList.data.hotelData.map((list, key) => {
                      return (
                        <HotelCartItem  
                          nonEdit={true}
                          chooseHotel={this.chooseHotel}
                          index={key}
                          removeHotelCart={this.removeHotelCart}
                          selectedItem={this.selectedItem}
                          secondsToHms={this.secondsToHms}
                          key={key}
                          item={list}
                        />
                      );
                    })}
                  {/* hotells Config end */}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="itinerary-scroll">
                {this.type === "flight" && (
                  <FlightCartDetail
                    secondsToHms={this.secondsToHms}
                    item={this.flightDetailView}
                  />
                )}
                {this.type === "combo" && (
                  <FlightCartComboDetail
                    secondsToHms={this.secondsToHms}
                    item={this.flightDetailView}
                  />
                )}
                {this.type === "hotel" && (
                  <HotelCartDetail
                    secondsToHms={this.secondsToHms}
                    item={this.hotelDetailView}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <AddNewModifyServicePage />
      </div>
    );
  }
}
export default ItineraryPage;
