import React, { Component } from 'react';
import HotelListPage from './hotel-list-html';
import $ from "jquery";
import { toast } from "react-toastify";
import { contractServices } from '../../../../../../_services';
// import Pagination from 'react-js-pagination';

class HotelList extends Component {
  state = {
    allHotels: [],
    hotelContractCityId: '',
    newCityId: '',
    removeHotel: "",
    removeButton: false,
    cityId: '',
    companyId: '',
    isLoading: true,

    limit: 10,
    totalCount: "",
    search: "",
    page: 1,
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  
    let data = {
      cityId: this.props.match.params.cityId,
      companyId: this.props.match.params.companyId,
      limit: this.props.match.params.limit,
      page: this.props.match.params.page
    }
    this.setState({
      newCityId: this.props.match.params.newCityId,
      hotelContractCityId: data.cityId,
      cityId: data.cityId,
      companyId: data.companyId
    })
    this.getAllHotelsData(data);
  }

  getAllHotelsData = async (data) => {
    this.setState({ isLoading: true });
    var res = await contractServices.getAllHotelsData(data);
    if (res) {
      this.setState({ isLoading: false });
      this.setState({ allHotels: res.result, totalCount: res.count })
    }
    else {
      console.log("No hotels are added in this city. Add a Hotel");
    }
  }
  removeHotelHandler = async () => {
    let deleteId = this.state.removeHotel;
    var res = await contractServices.removeHotelHandler(deleteId);
    if (res) {
      let data = {
        cityId: this.props.match.params.cityId,
        companyId: this.props.match.params.companyId,
        limit: this.props.match.params.limit,
        page: this.props.match.params.page
      }
      this.getAllHotelsData(data);
      this.setState({ removeHotel: "", removeButton: false });
      toast.success(res.message);
    }
  };
  onDeleteHandler = hotel => {
    let removeHotel = this.state.removeHotel;
    if (removeHotel === hotel) {
      this.setState({ removeHotel: "", removeButton: false });
    } else {
      this.setState({ removeHotel: hotel, removeButton: true });
    }
  };
  onSearchTextChangeHandler = async (event) => {
    let search = event.target.value;
    if (search !== '' && search !== '') {
      await this.setState({ search: event.target.value });
      let response = await contractServices.getHotelListOnSearch(this.state.cityId, this.state.companyId, search);
      if (response.count > 0) {
        this.setState({
          allHotels: response.result,
          totalCount: response.count
        });
      } else {
        this.setState({
          allHotels: []
        });
      }
    } else {
      this.setState({ search: '' });
      let data = {
        cityId: this.state.cityId,
        companyId: this.state.companyId,
        limit: this.state.limit,
        page: this.state.page
      }
      this.getAllHotelsData(data);
    }
  }
  render() {
    return (
      <div>
        <HotelListPage
          state={this.state}
          removeHotelHandler={this.removeHotelHandler}
          onDeleteHandler={this.onDeleteHandler}
          onSearchTextChangeHandler={this.onSearchTextChangeHandler}
        />
      </div>
    )
  }
}
export default HotelList;