import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Input } from "../../../../../common/_forms";

const AddEditTemplatePage = props => {
  const {
    onInputChangeHandlerForOlderThanType,
    onInputHandlerForEmailContent,
    onEmailFrequencyChangeHandler,
    onInputChangeHandler,
    inputChangeHandlerForCCEmails,
    onAfterExpiryNotificationHandler,
    onNotificationContentHandler,
    onAfterExpiryContentHandler,
    onSubmitHandler,
    name,
    subject,
    content,
    olderThan,
    olderThanType,
    frequency,
    type,
    notificationContent,
    isLoading,
    validator
  } = props;

  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">E</div>
          <div className="right">
            <h6 className="heading">Edit Reminder</h6>
            <p className="text">
              You can edit email reminders and replace with your own content.
            </p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="row">
            <div className="col-sm-7">
              <form>
                <div className="form-group custom">
                  <Input
                    label={"Name"}
                    name="name"
                    type="text"
                    imp="true"
                    value={name || ""}
                    className="form-control"
                    controlFunc={e => onInputChangeHandler(e)}
                    error={validator.message("name", name, "required")}
                  />
                </div>
                <div className="form-group custom labelup">
                  <textarea
                    type="text"
                    name="type"
                    className="form-control"
                    value={type || ""}
                    disabled
                  />
                  <label>Reminder Type</label>
                </div>
                {/* <div className="form-group custom labelup textarea">
                  <label>Include Unsubmitted</label>
                  <Select placeholder="No" />
                </div> */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group custom">
                      <input
                        name="olderThan"
                        className="form-control"
                        value={olderThan || ""}
                        placeholder="&nbsp;"
                        onChange={e => onInputChangeHandler(e)}
                      />
                      <label>Trigger if Older than</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group custom labelup">
                      <label>Day(s)</label>
                      <div className="dropdown">
                        <button
                          name="olderThanType"
                          type="button"
                          className="btn dropdown-toggle "
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {olderThanType || ""}
                        </button>
                        <div className="dropdown-menu">
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={e => onInputChangeHandlerForOlderThanType("Days")}
                          >
                            Days
                          </button>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={e => onInputChangeHandlerForOlderThanType("Hours")}
                          >
                            Hours
                          </button>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={e => onInputChangeHandlerForOlderThanType("Minutes")}
                          >
                            Minutes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Frequency</label>
                  <div className="dropdown">
                    <button
                      name="frequency"
                      type="button"
                      className="btn dropdown-toggle "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {props.displayfrequency
                        ? props.displayfrequency
                        : props.frequency
                          ? props.frequency
                          : "Select..."}
                    </button>
                    <div className="dropdown-menu">
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={e => onEmailFrequencyChangeHandler("DAILY", "DAILY")}
                      >
                        DAILY
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={e =>
                          onEmailFrequencyChangeHandler(
                            "DAILY (WEEKDAY ONLY)",
                            "DAILY_WEEKDAY_ONLY"
                          )
                        }
                      >
                        DAILY (WEEKDAY ONLY)
                      </button>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={e =>
                          onEmailFrequencyChangeHandler(
                            "SPECIFIC DAY OF MONTH",
                            "SPECIFIC_DAY_OF_MONTH"
                          )
                        }
                      >
                        SPECIFIC DAY OF MONTH
                      </button>
                    </div>
                    <div className="text-danger fs-12">
                      {props.validator.message(
                        "frequency",
                        frequency,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                {props.frequency === "SPECIFIC_DAY_OF_MONTH" ? (
                  <div className="form-group custom labelup">
                    <label>Date</label>
                    <DatePicker
                      selected={props.startDate}
                      onChange={props.onSelectedDateChangeHandler}
                    />
                  </div>
                ) : (
                    ""
                  )}
                <div className="form-group custom">
                  <Input
                    name="subject"
                    label={"Subject"}
                    type="text"
                    imp="true"
                    className="form-control"
                    value={subject || ""}
                    controlFunc={e => onInputChangeHandler(e)}
                    error={validator.message("subject", subject, "required")}
                  />
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  data={content}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    onInputHandlerForEmailContent(data);
                  }}
                />
                <div className="text-danger fs-12">
                  {props.validator.message("Content", content, "required")}
                </div>
                {props.type === "PASSPORT_EXPIRY" ||
                  props.type === "VISA_EXPIRY" ? (
                    <div>
                      <h6 className="sub-heading mt-4">After expiry</h6>
                      <CKEditor
                        editor={ClassicEditor}
                        data={props.contentAfterExpiry}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onAfterExpiryContentHandler(data);
                        }}
                      />
                      <div className="text-danger fs-12">
                        {props.validator.message(
                          "After Expiry",
                          props.contentAfterExpiry,
                          "required"
                        )}
                      </div>
                    </div>
                  ) : null}
                <div className="form-group custom textarea mt-4">
                  <textarea
                    name="notificationContent"
                    className="form-control"
                    value={notificationContent || ""}
                    onChange={e => onNotificationContentHandler(e)}
                    error={validator.message("Notification", notificationContent, "required")}
                  />
                  <label>Notification</label>
                </div>
                {props.type === "PASSPORT_EXPIRY" || props.type === "VISA_EXPIRY" ? (
                  <div>
                    <h6 className="sub-heading">Notification After expiry</h6>
                    <div className="form-group custom textarea">
                      <textarea
                        name="afterExpiryNotificationContent"
                        className="form-control"
                        value={props.afterExpiryNotificationContent || ""}
                        onChange={e => onAfterExpiryNotificationHandler(e)}
                        error={validator.message("After Expiry Notification", props.afterExpiryNotificationContent, "required")}
                      />
                    </div>
                  </div>
                ) : null}
                {props.copyToApproval === true ? (
                  <div className="form-group custom">
                    <Input
                      name="copyEmails"
                      label={"Copy to email address"}
                      type="email"
                      className="form-control"
                      value={props.copyEmails}
                      controlFunc={e => inputChangeHandlerForCCEmails(e)}
                    />
                  </div>
                ) : null}
                <p>Add multiple email address separated by comma (,).</p>
                <div className="btn-group-margin mt-4">
                  <button
                    type="button"
                    className="btn"
                    disabled={isLoading === true}
                    onClick={onSubmitHandler}
                  >
                    Update
                  </button>
                  <NavLink
                    to="/administration/company/reminder/travel/list"
                    className="btn"
                  >
                    CANCEL
                  </NavLink>
                </div>
              </form>
            </div>
            <div className="col-sm-5">
              <h6 className="sub-heading">
                <i className="material-icons">find_in_page</i> Preview
              </h6>
              <div className="template-output">
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddEditTemplatePage.propTypes = {
  onInputChangeHandlerForOlderThanType: PropTypes.func,
  onInputChangeHandlerForEmailFrequency: PropTypes.func,
  onInputChangeHandler: PropTypes.func,
  onSubmitHandler: PropTypes.func,
  onInputHandlerForEmailContent: PropTypes.func,
  isLoading: PropTypes.bool
};
export default AddEditTemplatePage;
