import React, { Component } from "react";
import ConfigurationPage from "./config-html";
import SimpleReactValidator from "simple-react-validator";
import { expenseTravelAllowanceServise } from "../../../../../../_services";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../../_constants";
import $ from "jquery";
class Configuration extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.validator = new SimpleReactValidator();
    this.state.companyId = localStorage["440a28"];
    this.serviceModuleId = "5c62a3fe83d4ef6fb672607c";
  }
  state = {
    search: "",
    dataPerPage: 10,
    pageItemsList: [{ items: 10 }, { items: 20 }, { items: 30 }],
    selectedPage: 1,
    configList: [],
    totalCount: "",
    configName: "",
    configEditableBy: "Global",
    editableBy: [],
    editableByList: [],
    configId: "",
    editableByLabel: "",
    editConfigName: "",
    editEditableBy: [],
    editEditableByLabel: [],
    configError: "",
    editableByError: "",
    loading: false,
    submitted:false,
    allValidated:false,
    isLoading:false,
    guestActive:false,
    personalActive:false,
    maxLimit:"",
    maxPersonalLimit:"0",
    maxGuestLimit:"0",
    selectedConfigId: [],
    companyId : localStorage["440a28"],
    editConfigSelect: [],
    allowanceCalculationType:[
      {
        label:'Time of Day',
        value:0
      },
      {
        label:'Time of Band',
        value:1
      }
    ],
    tripStartEndTypes:[{
      label:'Arrival Time',
      value:0
    },{
      label:'Departure Time',
      value:1
    }],
    allowanceTypeList:[
      { label: 'Fixed', value:0 }, 
      { label: "Actual with limit", value:1 },
      { label: "Actual Over Limit", value:2 }
    ],
    allowanceSelectedType:[],
    oneWayStartSelected:[],
    onWardStartSelected:[],
    onWardReturnSelected:[],
    multiCityStartSelected:[],
    selectedCurrency:[],
    globalAmounts:"",
    allowanceType:[],
    checkGuest: false,
  }
  componentDidMount() {
    this.getConfigList();
  }
  getCurrencyList = async () => {
    var res = await expenseTravelAllowanceServise.getCurrencyList(
      this.state.companyId
    );
    if (res) {
      let temp = res.result;
      let currenciesList = [];
      temp.forEach(element => {
        currenciesList.push({ label: element.itemName, value: element.id });
      });
      this.setState({ currenciesList: currenciesList });
    }
  };
  getEditableList = async () => {
    var res = await expenseTravelAllowanceServise.getEditableList(
      this.state.companyId
    );
    if (res) {
      let temp = res.result;
      let editableByList = [];
      editableByList = temp.map(element => {
        return {
          'label': element.itemName,
          'value': element.id
        }
      })
      this.setState({ editableByList: editableByList });
    }
  };
  getConfigList = async () => {
    this.setState({isLoading:true});
    let data = {
      companyId: this.state.companyId,
      limit: this.state.dataPerPage,
      page: this.state.selectedPage
    };
    var res = await expenseTravelAllowanceServise.getConfigList(data);
    if (res) {
      this.setState({ configList: res.result,id: [],totalCount: res.count ,isLoading:false});
      this.getEditableList();
      this.getCurrencyList();
    }else{
      this.setState({isLoading:false});
      this.getEditableList();
      this.getCurrencyList();
    }
  };
  inputChangeHandler = async e => {
    this.setState({ [`${e.target.name}`]: e.target.value });
    if (e.target.name === "search" && e.target.value !== "") {
      let data = {
        companyId: this.state.companyId,
        limit: this.state.dataPerPage,
        page: this.state.selectedPage,
        search: e.target.value
      };
      var res = await expenseTravelAllowanceServise.searchConfig(data);
      if (res) {
        this.setState({ configList: res.result });
        this.setState({ totalCount: res.count });
      } else {
        this.setState({ configList: [] });
        this.setState({ totalCount: 0 });
      }
    } else {
      if (e.target.name === "search") {
        this.getConfigList();
      }
    }
  };
  pageClickHandler = async(page) => {
    await this.setState({ selectedPage: page });
    this.getConfigList();
  };
  allowanceCalculationTypeHandler=(value)=>{
    this.setState({allowanceSelectedType:value})
   
  }
  onOneWayChangeTripHandler=(value)=>{
    this.setState({oneWayStartSelected:value})
   
  }
  onRoundWayChangeTripHandler=(value)=>{
    this.setState({onWardStartSelected:value})
   
  }
  onRoundReturnChangeTripHandler=(value)=>{
    this.setState({onWardReturnSelected:value})
   
  }
  onMultiCityChangeTripHandler=(value)=>{
    this.setState({multiCityStartSelected:value})
  }
  checkValidity = async() => {
    let editableBy = this.state.editableBy.length>0;
    let allowanceSelectedType = [this.state.allowanceSelectedType].length>0;
    let oneWayStartSelected = [this.state.oneWayStartSelected].length>0;
    let onWardStartSelected = [this.state.onWardStartSelected].length>0;
    let onWardReturnSelected = [this.state.onWardReturnSelected].length>0;
    let multiCityStartSelected = [this.state.multiCityStartSelected].length>0;
    let configName = this.state.configName.length>0;
    let selectedCurrency = [this.state.selectedCurrency].length>0;
    let maxLimit = parseInt(this.state.maxLimit)>0&&this.state.maxLimit.length>0; 
    let allowanceType = [this.state.allowanceType].length>0;
    if(editableBy&&allowanceSelectedType && oneWayStartSelected && onWardStartSelected && onWardReturnSelected && multiCityStartSelected && configName && selectedCurrency && maxLimit &&  allowanceType){
      await this.setState({submitted:true,allValidated:true});
    }else{
     await this.setState({submitted:true,allValidated:false});
    }
  }
  checkUpdateValidity = async() => {
    let editableBy = this.state.editableBy.length>0;
    let allowanceSelectedType = [this.state.allowanceSelectedType].length>0;
    let oneWayStartSelected = [this.state.oneWayStartSelected].length>0;
    let onWardStartSelected = [this.state.onWardStartSelected].length>0;
    let onWardReturnSelected = [this.state.onWardReturnSelected].length>0;
    let multiCityStartSelected = [this.state.multiCityStartSelected].length>0;
    let configName = this.state.configName.length>0;
    if(editableBy&&allowanceSelectedType && oneWayStartSelected && onWardStartSelected && onWardReturnSelected && multiCityStartSelected && configName){
      await this.setState({submitted:true,allValidated:true});
    }else{
     await this.setState({submitted:true,allValidated:false});
    }
  }
  
  addConfigHandler = async e => {
  e.preventDefault();
  await this.checkValidity();
  let validationStatus = this.state.allValidated;
  if (validationStatus) {
      this.setState({loading : true});
      let data = {
        companyId: this.state.companyId,
        configurationName: this.state.configName,
        editableBy:this.state.editableBy.map((list)=>list.value),
        allowanceCalculationType:this.state.allowanceSelectedType.value,
        oneWayStartOn:this.state.oneWayStartSelected.value,
        roundTripOnward:this.state.onWardStartSelected.value,
        roundTripReturn:this.state.onWardReturnSelected.value,
        multiCityStartOn:this.state.multiCityStartSelected.value,
        allowanceType:this.state.allowanceType.value,
        currency:this.state.selectedCurrency.value,
        guestActive:this.state.guestActive,
        personalActive:this.state.personalActive,
        maxLimit:this.state.maxLimit,
        maxGuestLimit:this.state.guestActive ? this.state.maxGuestLimit:"",
        maxPersonalLimit:this.state.personalActive ? this.state.maxPersonalLimit:"",
        activeStatus: true, 
      };
      var res = await expenseTravelAllowanceServise.postConfig(data)
      if (res) {
        this.getConfigList();
        toast.success(alertConstants.CREATED_SUCCESSFULLY);
        $(".close").click();
        this.setState({loading : false});
      } else {  
        this.setState({loading : false});
      }
    }
  };
  editHandler = data => {
    this.setState({selectedConfigId:[]});
    let editableBy = data.editableBy;
    let editableByList = [...this.state.editableByList];
    let editedByLists = [];
    editableByList.forEach((list)=>{
      if(editableBy.includes(list.value)){
        editedByLists.push(list);
      }
    }); 
    this.setState({
      configId:data.id,
      editableBy:editedByLists,
      personalActive:data.personalActive,
      guestActive:data.guestActive,
      configName:data.configurationName,
      allowanceSelectedType:this.state.allowanceCalculationType[data.allowanceCalculationType],
      oneWayStartSelected:this.state.tripStartEndTypes[data.oneWayStartOn],
      onWardStartSelected:this.state.tripStartEndTypes[data.roundTripOnward],
      onWardReturnSelected:this.state.tripStartEndTypes[data.roundTripReturn],
      multiCityStartSelected:this.state.tripStartEndTypes[data.multiCityStartOn], 
    })
  };
  changeConfigEditableBy = (e) => {
    e.forEach((element, index) => {
      if (!this.editEditableBy.includes(element.value)) {
        this.editEditableBy.push(element.value)
        this.editConfigSelect.push({ 'label': element.label, 'value': element.value })
      }
    })
  }
  changeEditableBy = (editableBy) => {
    this.setState({editableBy:editableBy});
  };
  handleItemChange = limit => {
    this.setState({ selectedPage: 1 });
    this.setState({ dataPerPage: limit["items"] });
    this.getConfigList();
  };
  resetForm = () => {
    this.setState({ loading: false });
    this.setState({ configName: "" });
    this.setState({ editableBy: [],
      submitted:false,
      allowanceSelectedType:[],
      oneWayStartSelected:[],
      onWardStartSelected:[],
      onWardReturnSelected:[],
      multiCityStartSelected:[],
      allowanceType:[],
      selectedCurrency:[],
      globalAmounts:'',
      globalAmounts1:'',
      globalAmounts2:'',
      checkGuest:false,
      guestActive:false,
      personalActive:false
     });
  };
  deletehandler = async () => {
    let data = {
      id: [...this.state.selectedConfigId]
    }
    var res = await expenseTravelAllowanceServise.deleteConfig(this.state.companyId, data);
    if (res) {
      toast.success(res.message)
      let data = {
        companyId: this.state.companyId,
        limit: this.state.dataPerPage,
        page: this.state.selectedPage
      };
      var response = await expenseTravelAllowanceServise.getConfigList(data);
      if (response) {
        this.setState({ configList: response.result });
        this.setState({ totalCount: response.count });
        this.setState({ selectedConfigId:[] });
      } else {
        this.setState({ configList: [] });
        this.setState({ totalCount: 0 });
        this.setState({ selectedConfigId: [] });
      }
    }
  }
  selectedDeleteHandler = (data) => {
    let selectedConfigId = [...this.state.selectedConfigId];
    if (selectedConfigId.includes(data)) {
      selectedConfigId = selectedConfigId.map((list)=>list.id!==data);
    } else {
      selectedConfigId.push(data);
    }
    this.setState({selectedConfigId:selectedConfigId});
  }
  submitHandler = async (e) => {
    e.preventDefault();
    await this.checkUpdateValidity();
    let validationStatus = this.state.allValidated;
    if (validationStatus) {
        this.setState({loading : true});
        let data = {
          id: this.state.configId,
          companyId: this.state.companyId,
          configurationName: this.state.configName,
          editableBy:this.state.editableBy.map((list)=>list.value),
          allowanceCalculationType:this.state.allowanceSelectedType.value,
          oneWayStartOn:this.state.oneWayStartSelected.value,
          roundTripOnward:this.state.onWardStartSelected.value,
          roundTripReturn:this.state.onWardReturnSelected.value,
          multiCityStartOn:this.state.multiCityStartSelected.value,
          activeStatus: true,
          guestActive:this.state.guestActive,
          personalActive:this.state.personalActive
        };
        var res = await expenseTravelAllowanceServise.editConfig(data)
        if (res) {
          $(".close").click();
          this.getConfigList();
          toast.success(res.message);
          this.setState({loading : false});
        } else {
          this.setState({loading : false});
        }
      } 
  };
  onIntegerHandler = (event) => {
    if(!isNaN(event.target.value)) 
    {
      this.setState({ globalAmounts: (event.target.value) });
    }
  };
  onIntegerHandler1 = (event) => {
    if(!isNaN(event.target.value)) 
    {
      this.setState({ globalAmounts1: (event.target.value) });
    }
  };
  onIntegerHandler2 = (event) => {
    if(!isNaN(event.target.value)) 
    {
      this.setState({ globalAmounts2: (event.target.value) });
    }
  };
  enableTypeRate = (name,e) =>{  
    this.setState({ [name]: !e }); 
  }
  onCurrencyChangeHandler=(currency)=>{
    this.setState({ selectedCurrency: currency });
  }
  onAllowanceChangeHandler=(value)=>{
    this.setState({allowanceType:value});
  }
  statusHandler = async (value, id) => {
    this.setState({selectedConfigId:[]});
    if (this.state.activeStatus === "") {
      this.setState({activeStatus : !this.props.data.activeStatus});
    } else {
      this.setState({activeStatus : !this.activeStatus});
    }
    let data = {
      id: id,
      activeStatus: !value
    };
    var res = await expenseTravelAllowanceServise.updateConfigStatus(
      this.state.companyId,
      data
    );
    if (res) {
      toast.success(res.message);
    }
  };
  render() {
    return (
      <div>
        <ConfigurationPage
          {...this.state}
          selectedDeleteHandler={this.selectedDeleteHandler}
          deletehandler={this.deletehandler}
          inputChangeHandler={this.inputChangeHandler}
          pageClickHandler={this.pageClickHandler}
          handleItemChange={this.handleItemChange}
          changeEditableBy={this.changeEditableBy}
          changeConfigEditableBy={this.changeConfigEditableBy}
          resetForm={this.resetForm}
          editHandler={this.editHandler}
          addConfigHandler={this.addConfigHandler}
          getConfigList={this.getConfigList}
          validator={this.validator}
          submitHandler={this.submitHandler}
          statusHandler={this.statusHandler}
          allowanceCalculationTypeHandler={this.allowanceCalculationTypeHandler}
          onOneWayChangeTripHandler={this.onOneWayChangeTripHandler}
          onRoundWayChangeTripHandler={this.onRoundWayChangeTripHandler}
          onRoundReturnChangeTripHandler={this.onRoundReturnChangeTripHandler}
          onMultiCityChangeTripHandler={this.onMultiCityChangeTripHandler}
          onCurrencyChangeHandler={this.onCurrencyChangeHandler}
          onAllowanceChangeHandler={this.onAllowanceChangeHandler}
          onIntegerHandler={this.onIntegerHandler}
          onIntegerHandler1={this.onIntegerHandler1}
          onIntegerHandler2={this.onIntegerHandler2}
          enableTypeRate={this.enableTypeRate}
        />
      </div>
    );
  }
}
export default Configuration;
