import React, { Component } from "react";
import { Route } from "react-router-dom";
import CashAdvance3Page from "./cashadvance";
import IssueAdvancePage from "./issue-advance";
import CashIssuedForTripsPage from "./cash-issued-for-trips";
import CashIssuedDetailsPage from "./cash-issued-details";
import IssueApproverPage from "./issue-approver";
import IssuedPage from "./issued";
import RejectedPage from "./rejected";

class CashIssuedMainPage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/expense") {
      this.props.history.push("/expense/home");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/expense") {
      this.props.history.push("/expense/home");
    }
  }
  render() {
    return (
      <div className="BookingPage bg-light-blue">
        <div className="page-content">
          <Route path="/cash-issued/cashadvance" component={CashAdvance3Page} />
          <Route
            path="/cash-issued/issue-advance"
            component={IssueAdvancePage}
          />
          <Route
            path="/cash-issued/issue-approver"
            component={IssueApproverPage}
          />
          <Route
            path="/cash-issued/cash-issued-for-trips"
            component={CashIssuedForTripsPage}
          />
          <Route
            path="/cash-issued/cash-issued-details"
            component={CashIssuedDetailsPage}
          />
          <Route
              path="/cash-issued/issued"
              component={IssuedPage}
          />
          <Route
              path="/cash-issued/rejected"
              component={RejectedPage}
          />
        </div>
      </div>
    );
  }
}
export default CashIssuedMainPage;
