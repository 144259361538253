import React, { Component } from "react";
import CashAdvanceConfigPage from "./cash-advance-html";
import { expenseServices } from '../../../../../../_services/expense.service';
import { toast } from 'react-toastify';

class CashAdvanceConfig extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state={
      cashAdvanceList:[],
      companyId: localStorage["440a28"],
      selectedConfig:'',
      enableModify:false,
      enabelRemove:false,
      totalCount:0,
      activePage:1,
      pageLimit:10,
      loading: true
    }
  }
  componentDidMount(){
    this.getAllCashAdvanceCreated();
  }
  
  
  getAllCashAdvanceCreated=async()=>{
    let companyId = this.state.companyId;
    let pageLimit = this.state.pageLimit;
    let activePage = this.state.activePage;
    let response = await expenseServices.getAllCashAdvanceCreated(companyId,pageLimit,activePage);
    if(response){
      this.setState({cashAdvanceList:response.result,totalCount:response.count});
      this.setState({loading:false})
    }else{
      this.setState({cashAdvanceList:[],totalCount:0});
      this.setState({loading:false})
    }
  }
  onSelectedConfig=(id)=>{
    if(this.state.selectedConfig===id){
      this.setState({selectedConfig:'',enableModify:false,enabelRemove:false});
    }else{
      this.setState({selectedConfig:id,enableModify:true,enabelRemove:true});
    }
  }
  onChangeStatusHandler=async(id,status)=>{
    let data={
      id:id,
      activeStatus:!status
    }
    let response = await expenseServices.changeCashAdvanceCashStatus(this.state.companyId,data);
    if(response){
      this.getAllCashAdvanceCreated();
      toast.success(response.message);
    }
  }
  onDeleteClickHandler=async()=>{
    let data={id:[this.state.selectedConfig]};
    let response = await expenseServices.deleteAdvanceConfig(this.state.companyId,data);
    if(response){
      this.setState({enableModify:false,enabelRemove:false});
      this.getAllCashAdvanceCreated();
      toast.success(response.message);
    }
  }
  onModifyClickHandler=()=>{
    let selectedConfigId=this.state.selectedConfig;
    localStorage.setItem('selectedConfigId',selectedConfigId);
    if(selectedConfigId){
      this.props.history.push('./edit-cash-advance-config');
    }
  }
  onPageChangeHandler=async(page)=>{
    this.setState({activePage:page});
    let companyId = this.state.companyId;
    let pageLimit = this.state.pageLimit;
    let activePage = page;
    let response = await expenseServices.getAllCashAdvanceCreated(companyId,pageLimit,activePage);
    if(response){
      this.setState({cashAdvanceList:response.result,totalCount:response.count});
    }
  }
  onLimitChangeHandler=async(page)=>{
    this.setState({pageLimit:page,activePage:1});
    let companyId = this.state.companyId;
    let pageLimit = page;
    let activePage = 1;
    let response = await expenseServices.getAllCashAdvanceCreated(companyId,pageLimit,activePage);
    if(response){
      this.setState({cashAdvanceList:response.result,totalCount:response.count});
    }
  }
  render() {
    return (
      <div>
        <CashAdvanceConfigPage 
          cashAdvanceList={this.state.cashAdvanceList}
          onSelectedConfig={this.onSelectedConfig}
          loading={this.state.loading}
          selectedConfig={this.state.selectedConfig}
          enableModify={this.state.enableModify}
          enabelRemove={this.state.enabelRemove}
          onChangeStatusHandler={this.onChangeStatusHandler}
          onDeleteClickHandler={this.onDeleteClickHandler}
          onModifyClickHandler={this.onModifyClickHandler}
          totalCount={this.state.totalCount}
          onPageChangeHandler={this.onPageChangeHandler}
          pageLimit={this.state.pageLimit}
          activePage={this.state.activePage}
          onLimitChangeHandler={this.onLimitChangeHandler}
        />
      </div>
    );
  }
}
export default CashAdvanceConfig;
