import React, { Component } from "react";
import ExpensePolicyPage from "./expense-policies-html";
import { toast } from "react-toastify";
import { expensePolicies } from "../../../../../_services";
import SimpleReactValidator from "simple-react-validator";
import { alertConstants } from "src/_constants";

class ExpensePolicy extends Component {
  state = {
    companyId: localStorage["440a28"],
    policyId: "5c7f6c190c3b31720f5edc67",
    serviceModuleId: "5c74ed3d77addf3b07acb014",
    limit: 10,
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,
    searchInput: "",
    expensePolicies: [],
    selectedExpensePolicy: [],
    expenseReportForm: "5c974daabcde0b0ab7812830", //Expense Report Form
    expenseAllocationForm: "5c989f8562b0f2517b2f841a", //Expense Allocation Form
    expenseReportForms: [],
    expenseAllocationForms: []
  };

  validator = new SimpleReactValidator();

  // ------------Get All expense policies ------------------------
  expensePolicies = () => {
    if (!this.state.searchInput) {
      expensePolicies
        .getPolicies(
          this.state.companyId,
          this.state.itemPerPage,
          this.state.activePage
        )
        .then(res => {
          this.setState({
            expensePolicies: res.result,
            totalItemsCount: res.count
          });
        })
        .catch(err => console.log(err));
    } else {
      expensePolicies
        .searchPolicy(
          this.state.searchInput,
          this.state.companyId,
          this.state.itemPerPage,
          this.state.activePage
        )
        .then(res => {
          if (res.result) {
            this.setState({
              expensePolicies: res.result,
              totalItemsCount: res.count
            });
          } else {
            this.setState({
              expensePolicies: []
            });
          }
        })
        .catch(err => console.log(err));
    }
  };

  onSearchHandler = e => {
    let searchInput = e.target.value;
    this.setState(
      {
        searchInput
      },
      () => {
        if (!searchInput.length) {
          this.setState({ itemsCountPerPage: 10 }, () => {
            this.expensePolicies();
          });
        } else {
          expensePolicies
            .searchPolicy(
              this.state.searchInput,
              this.state.companyId,
              this.state.itemPerPage,
              this.state.activePage
            )
            .then(
              res => {
                if (res.result) {
                  this.setState({
                    expensePolicies: res.result,
                    totalItemsCount: res.count
                  });
                } else {
                  this.setState({
                    expensePolicies: []
                  });
                }
              },
              err => {
                console.log(err);
              }
            );
        }
      }
    );
  };
  // -----------------on click select Policies-----------------------

  toggleExpensePolicySelect = field => {
    // let id = field.id;
    let itemSelected = [...this.state.selectedExpensePolicy];
    let indexSelected = itemSelected.indexOf(field);
    if (indexSelected === -1) {
      itemSelected = [field];
    } else {
      itemSelected = [];
    }
    this.setState({ selectedExpensePolicy: itemSelected }, () =>
      console.log(this.state.selectedExpensePolicy)
    );
  };

  // --------------delete selected policy----------------------------

  deleteSelectedExpencePolicy = expensePolicy => {
    let id = expensePolicy[0].id;
    let data = {
      id: [id]
    };
    expensePolicies
      .deletePolicy(this.state.companyId, data)
      .then(res => {
        this.setState({ selectedExpensePolicy: [] });
        toast.success("Policy Removed");
        this.expensePolicies();
      })
      .catch(err => console.log(err, "error"));
  };

  // -------------------change status ---------------------------

  handleConnectedListStatus = expensePolicy => {
    let newStatus = !expensePolicy.activeStatus;
    let data = {
      id: expensePolicy.id,
      activeStatus: newStatus
    };

    expensePolicies.changeStatus(this.state.companyId, data).then(
      res => {

        newStatus
          ? toast.success(alertConstants.POLICY_ACTIVATED_SUCCESSFULLY)
          : toast.error(alertConstants.POLICY_DEACTIVATED_SUCCESSFULLY);

        let newItems = this.state.expensePolicies.map(expensePolicies => {
          if (expensePolicies.id === expensePolicy.id) {
            expensePolicies.activeStatus = newStatus;
          }
          return expensePolicies;
        });
        this.setState({
          expensePolicies: newItems
        });
      },
      err => {
        toast.error("Some error occured. Unable to change status");
      }
    );
  };
  // --------------Pagination--------------------------------

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, () => {
      this.expensePolicies();
    });
  };
  handleItemChange = limit => {
    this.setState({ itemPerPage: limit, activePage: 0 }, () => {
      this.expensePolicies();
    });
  };

  componentDidMount() {
    this.expensePolicies();
    // this.getAllEditableBy()
  }

  render() {
    return (
      <div>
        <ExpensePolicyPage
          validator={this.validator}
          expensePolicyData={this.state}
          handlePageChange={this.handlePageChange}
          handleItemChange={this.handleItemChange}
          toggleExpensePolicySelect={this.toggleExpensePolicySelect}
          deleteSelectedExpencePolicy={this.deleteSelectedExpencePolicy}
          handleConnectedListStatus={this.handleConnectedListStatus}
          onSearchHandler={this.onSearchHandler}
        />
      </div>
    );
  }
}
export default ExpensePolicy;
