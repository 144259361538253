import React from "react";

const SortHeadFlight = props => {
  const { sortItem, feildName, sortType, name, className } = props;
  return (
    <div className={className} onClick={e => sortItem(feildName)}>
      {name}{" "}
      {sortType === "desc" ? (
        <i className="material-icons sort">
          arrow_drop_down
        </i>
      ) : sortType === "asc" ? (
        <i className="material-icons sort">
          arrow_drop_up
</i>
      ) : (
            <span>
              <i className="material-icons sort">
                arrow_drop_down
</i>
              <i className="material-icons sort">
                arrow_drop_up
</i>
            </span>
          )}
    </div>
  );
};

export default SortHeadFlight;
