import React from "react";

const currencyList = props => {
  return (
    <table className="table table-striped table-selected">
      <thead>
        <tr>
          <th>Currency Name</th>
          <th>Currency Code</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {props.companyCurrencyList
          ? props.companyCurrencyList.map((currency, index) => {
            return (
              <tr
                key={index}
                className={
                    props.removeCurrency === currency.id ? "active-tr" : ""
                }
              >
                <td onClick={e => props.onDeleteHandler(currency.id)}>
                  {currency.currencyDetails.itemName}
                </td>
                <td
                  key={currency.id}
                  onClick={e => props.onDeleteHandler(currency.id)}
                >
                  {currency.currencyDetails.itemCode}
                </td>
                <td>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={currency.activeStatus}
                      onChange={e =>
                        props.onStatusChangeHandler(
                          currency.id,
                          currency.activeStatus
                        )
                      }
                    />
                    <span className="slider round" />
                  </label>
                </td>
              </tr>
            );
          })
          : ""}
        {props.companyCurrencyList.length <= 0 ? (
          <tr>
            <td colSpan={3} className="text-center">
              No Records Found
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
};

export default currencyList;
