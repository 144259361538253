import React from "react";
import NumberFormat from "react-number-format";
export const HotelCartItem = props => {
  const {
    index,
    item,
    secondsToHms,
    removeHotelCart,
    nonEdit,
    chooseHotel,
    selectedItem,
    baseCurrency
  } = props;
  return (
    <li
      key={index}
      className={selectedItem !== `hotel${index}` ? "" : "active"}
      onClick={e => chooseHotel(item, "hotel", `hotel${index}`)}
    >
      {item.reasons ? <div className="text-danger fs-12 px-4 py-2 border-bottom-light">
        {" "}
        <i className="material-icons align-middle mr-1 fs-14">error</i>{" "}
        {item.reasons ? item.reasons : ""}
      </div> : ""}
      <div className="f-container">
        {nonEdit ? (
          ""
        ) : props.totalFlightHotel !== 1 ? (
          <button
            className="btn btn-nothing remove hover-v"
            onClick={e => removeHotelCart(item.roomId)}
          >
            Remove
          </button>
        ) : (
              <button
                className="open"
                data-target="#deleteTrip"
                data-toggle="modal"
                onClick={() => props.handleDeleteClick("hotel")}
              >
                Remove
              </button>
            )}
        <div className="left-f hotel-left-f">
          <div className="hotel-left">
            {!item.hotel_photos ? (
              <div className="image">
                <div className="hotel-no-img">
                  <div className="cen">
                    <i className="material-icons">photo_size_select_actual</i>
                    <p>No Image</p>
                  </div>
                </div>
              </div>
            ) : (
                item.hotel_photos && (
                  <div className="image">
                    <img
                      src={
                        item.hotel_photos[0]
                          ? item.hotel_photos[0]
                          : ""
                      }
                      alt=""
                    />
                  </div>
                )
              )}
          </div>
          <div className="hotel-right">
            <h6>
              <b>{item.hotel_name}</b>
            </h6>
            <p className="fs-12 addrss">{item.hotel_address}</p>
            <p className="fs-12">
              {item.hotel_from} - {item.hotel_to}
            </p>
          </div>
        </div>
        <div className="right-f single hotel-right-f">
          <div>
            <h5>
              {baseCurrency}
              {/* {item.hotel_currency_code} */}{" "}
              <NumberFormat
                value={item.hotel_room_info?.hotel_total_price?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
              />
            </h5>
            <p className="fs-12 mb-0">{item.trip_duration} Night</p>
            <button className="btn btn-nothing">
              View Details <i className="material-icons">arrow_forward_ios</i>
            </button>
          </div>
        </div>
        {/* <div class="dropdown more-dropdown show hotel-drop" style={{ "top": "-64px", "float": "right" }}>
          <button class="btn btn-nothing dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <i class="material-icons">more_vert</i>
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" x-placement="bottom-end" style={{ "right": "0", "left": "auto" }}>
            <button class="dropdown-item" type="button" onClick={'javascript.void(0)'}>Reschedule</button>
            <button class="dropdown-item" type="button" onClick={'javascript.void(0)'}>Cancel</button>
          </div>
        </div> */}
        <div className="clearfix" />
      </div>
      {/* <div className="arrow">
        <i className="material-icons">
          arrow_forward_ios
                </i>
      </div>
      <div className="destiny">
        <div className="left-destination hotel">
          <div className="abs-pin" />
          <div className="totalDistance sm">
            <div className="circle">
              <i className="material-icons">
                hotel
                </i>
            </div>
          </div>
          <p className="title">{item.hotel_city}</p>
          <div className="center">
            <p className="title">
              <b>{item.hotel_name}</b>
            </p>
            <p className="label">{item.hotel_address}</p>
          </div>
          <p className="title">
            {item.hotel_from} - {item.hotel_to}
          </p>
        </div>
      </div> */}
    </li>
  );
};
