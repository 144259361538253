import React from 'react';
import Menu from '../menu';
import Select from 'react-select';
import { Input } from 'src/_components/common/_forms';
import { history } from '../../../../../../_helpers';


const CarInfoPage = (props) => {
  let carTypesOptions = [];
  let countryTypesOptions = [];
  let stateTypesOptions = [];
  let cityTypesOptions = [];
  let carGroupTypesOptions = [];
  props.carTypes && props.carTypes.forEach((type) => {
    carTypesOptions.push({ label: type.itemName, value: type.id })
  });
  props.countryTypes && props.countryTypes.forEach((country) => {
    countryTypesOptions.push({ label: country.name, value: country.id })
  });
  props.stateTypes && props.stateTypes.forEach((state) => {
    stateTypesOptions.push({ label: state.name, value: state.id })
  });
  props.cityTypes && props.cityTypes.forEach((city) => {
    cityTypesOptions.push({ label: city.name, value: city.id })
  });
  props.carGroupTypes && props.carGroupTypes.forEach((car) => {
    carGroupTypesOptions.push({ label: car.itemName, value: car.id })
  });


  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">Add - Car Contract</h6>
              <p className="text">Add/Modify Car information</p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="p-padding">
            <div className="form-holder">
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group custom labelup">
                    <label>Car Group</label>
                    {carGroupTypesOptions &&
                      <Select
                        value={props.carGroup || ''}
                        options={carGroupTypesOptions}
                        onChange={(e) => props.changeCarGroupTypes(e)}
                      />
                    }
                    {props.validator.message(
                      "Car Group",
                      props.carGroup,
                      "required"
                    )}
                  </div>
                  <div className="form-group custom labelup">
                    <label>Car Type</label>
                    <Select
                      value={props.carType || ''}
                      options={carTypesOptions}
                      onChange={(e) => props.carTypeChange(e)}
                    />
                    {props.validator.message(
                      "Car Type",
                      props.carType,
                      "required"
                    )}
                  </div>
                  <div className="form-group custom">
                    <input type="text" placeholder="&nbsp;" className="form-control"
                      name="carTypeCode"
                      value={props.carTypeCode}
                      onChange={(e) => props.handleChange(e)}
                    />
                    {props.validator.message(
                      "Car Type code",
                      props.carTypeCode,
                      "required|alpha_num_space"
                    )}
                    <label>Car Type Code</label>
                  </div>
                  <div className="form-group custom">
                    <input type="text" placeholder="&nbsp;" className="form-control"
                      name="carAssistantNumber"
                      value={props.carAssistantNumber}
                      onChange={(e) => props.handleChange(e)}
                    />
                    {props.validator.message(
                      "Car Assistatnt number",
                      props.carAssistantNumber,
                      "required|integer"
                    )}
                    <label>Car Assistance Number</label>
                  </div>
                  <div className="form-group custom labelup">
                    <label>Capacity</label>
                    <Select
                      value={props.capacity || ''}
                      options={props.capacityTypes}
                      onChange={(e) => props.changeCapacityType(e)}
                    />
                    {props.validator.message(
                      "Capacity",
                      props.capacity,
                      "required"
                    )}
                  </div>
                  <div className="form-group custom labelup">
                    <label>Baggage</label>
                    <Select
                      value={props.baggage || ''}
                      options={props.baggageTypes}
                      onChange={(e) => props.changeBaggageType(e)}
                    />
                    {props.validator.message(
                      "Baggage",
                      props.baggage,
                      "required"
                    )}
                  </div>
                  <div className="form-group custom">
                    <input type="text" placeholder="&nbsp;" className="form-control"
                      name="pricePerDay"
                      value={props.pricePerDay}
                      onChange={(e) => props.handleChange(e)}
                    />
                    {props.validator.message(
                      "Price per day",
                      props.pricePerDay,
                      "required|numeric"
                    )}
                    <label>Price/Day</label>
                  </div>
                  <div className="form-group custom">
                    <input type="text" placeholder="&nbsp;" className="form-control"
                      name="pricePerKm"
                      value={props.pricePerKm}
                      onChange={(e) => props.handleChange(e)}
                    />
                    {props.validator.message(
                      "Price per KM",
                      props.pricePerKm,
                      "required|numeric"
                    )}
                    <label>Price/KM</label>
                  </div>
                  <h5 className="sub-heading">Car Amenities</h5>
                  <div className="form-group custom textarea mb-2">
                    <textarea type="text" row="4" placeholder="&nbsp;" className="form-control"
                      name="carAmenities"
                      data-gramm_editor="false"
                      spellCheck="false"
                      value={props.carAmenities}
                      onChange={(e) => props.handleChange(e)} />
                    <label>Car Amenities</label>
                  </div>
                  {props.validator.message(
                    "car Amenities",
                    props.carAmenities,
                    "required|string"
                  )}
                  <h5 className="sub-heading">Image</h5>
                  <div className="upload-input  form-group text-center">
                    <div className="upload">
                      <input type="file" accept="image/*" multiple
                        onChange={(e) => props.onImageBrowse(e)}
                      />
                      Drag and Drop to Upload
                    </div>
                  </div>
                  <div className="small-img mb-4">
                    {props.photos.length > 0 ? props.photos.map((photo, i) => {
                      return (
                        <div key={i} className="small-icon mb-3" style={{ "position": "relative" }}>
                          {(props.deleteImage) ? <i className="material-icons" style={{ "position": "absolute", "top": 0, "right": 0, "backgroundColor": "#fff", "color": "red", "cursor": "pointer" }}
                            onClick={(e) => props.removeFromList(i)}
                          >close</i> : ""}
                          <img src={photo} alt="" onClick={(e) => props.deleteImagefunction(e)} />
                        </div>
                      )
                    }) : ""}
                  </div>
                  <div className="form-group custom labelup">
                    <label>Air Condition Type</label>
                    {props.acTypes &&
                      <Select
                        value={props.airConditionType || ''}
                        options={props.acTypes}
                        onChange={(e) => props.changeAcTypes(e)}
                      />
                    }
                    {props.validator.message(
                      "Air Condition Type",
                      props.airConditionType,
                      "required"
                    )}
                  </div>
                  <div className="form-group custom labelup">
                    <label>Transmission Type</label>
                    {props.transmissionTypes &&
                      <Select
                        value={props.transmissionType || ''}
                        options={props.transmissionTypes}
                        onChange={(e) => props.changeTransmissionTypes(e)}
                      />
                    }
                    {props.validator.message(
                      "Transmission Type",
                      props.transmissionType,
                      "required"
                    )}
                  </div>
                  <div className="form-group custom labelup">
                    <label>Fuel Type</label>
                    {props.fuelTypes &&
                      <Select
                        value={props.fuelType || ''}
                        options={props.fuelTypes}
                        onChange={(e) => props.changeFuelTypes(e)}
                      />
                    }
                    {props.validator.message(
                      "Fuel Type",
                      props.fuelType,
                      "required"
                    )}
                  </div>

                  <div className="form-group custom labelup ">
                    <label>Fuel Policy</label>
                    {props.fuelPolicyTypes &&
                      <Select
                        value={props.fuelPolicy || ''}
                        options={props.fuelPolicyTypes}
                        onChange={(e) => props.changeFuelPolicyTypes(e)}
                      />
                    }
                    {props.validator.message(
                      "Fuel Policy",
                      props.fuelPolicy,
                      "required"
                    )}
                  </div>
                  <div className="form-group custom textarea ">
                    <textarea type="text" row="4" placeholder="&nbsp;" className="form-control"
                      name="cancelationPolicy"
                      value={props.cancelationPolicy}
                      data-gramm_editor="false"
                      spellCheck="false"
                      onChange={(e) => props.handleChange(e)}
                    />
                    <label>Cancellation Policy</label>
                  </div>
                  {props.validator.message(
                    "cancelation Policy",
                    props.cancelationPolicy,
                    "required"
                  )}
                  <div className="form-group custom labelup mt-3">
                    <label>Country</label>
                    {countryTypesOptions &&
                      <Select
                        value={props.country || ''}
                        options={countryTypesOptions}
                        onChange={(e) => props.changeCountryType(e)}
                      />
                    }
                    {props.validator.message(
                      "Country",
                      props.country,
                      "required"
                    )}
                  </div>
                  <div className="form-group custom labelup">
                    <label>State</label>
                    {stateTypesOptions &&
                      <Select
                        value={props.state || ''}
                        options={stateTypesOptions}
                        onChange={(e) => props.changeStateTypes(e)}
                      />
                    }
                    {props.validator.message(
                      "State",
                      props.state,
                      "required"
                    )}
                  </div>

                  <div className="form-group custom labelup">
                    <label>City</label>
                    {cityTypesOptions &&
                      <Select
                        value={props.city || ''}
                        options={cityTypesOptions}
                        onChange={(e) => props.changeCityTypes(e)}
                      />
                    }
                    {props.validator.message(
                      "City",
                      props.city,
                      "required"
                    )}
                  </div>
                  <h5 className="sub-heading">Vender Details</h5>
                  <div className="form-group custom">
                    <input type="text" placeholder="&nbsp;" className="form-control"
                      name="personName"
                      value={props.personName}
                      onChange={(e) => props.handleChange(e)}
                    />
                    {props.validator.message(
                      "Name",
                      props.personName,
                      "required|alpha_space"
                    )}
                    <label>Person Name</label>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group custom mb-0">
                        <Input
                          label="Code"
                          type="text"
                          name="searchCountryCode"
                          value={props.selectedCountryCode2.dailCode}
                          placeholder={props.selectedCountryCode2.dailCode}
                          controlFunc={e => props.handleChangeCountryCode2(e)}
                        />
                      </div>
                      {props.isSearchCountryCodeListOpen2 && (
                        props.countryCodeList2.length ?
                          (<ul className="scroll-ul">
                            {props.countryCodeList2.map(country => {
                              return (
                                <li key={country.id} onClick={e => props.selectChangeCountryCode2(country)} >
                                  {country.dailCode + ' ' + country.country}
                                </li>
                              )
                            })}
                          </ul>) :
                          (<div className="mb-4"></div>))
                      }
                    </div>
                    <div className="col-md-9">
                      <div className="form-group custom minus-ml">
                        <input type="text" placeholder="&nbsp;" className="form-control"
                          name="contactNumber"
                          value={props.contactNumber}
                          onChange={(e) => props.handleChange(e)} />
                        {props.validator.message(
                          "contact number",
                          props.contactNumber,
                          "required|phone"
                        )}
                        <label>Contact Number</label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group custom mb-0">
                        <Input
                          label="Code"
                          type="text"
                          name="searchCountryCode"
                          value={props.selectedCountryCode.dailCode}
                          placeholder={props.selectedCountryCode.dailCode}
                          controlFunc={e => props.handleChangeCountryCode(e)}
                        />
                      </div>
                      {props.isSearchCountryCodeListOpen && (
                        props.countryCodeList.length ?
                          (<ul className="scroll-ul">
                            {props.countryCodeList.map(country => {
                              return (
                                <li key={country.id} onClick={e => props.selectChangeCountryCode(country)} >
                                  {country.dailCode + ' ' + country.country}
                                </li>
                              )
                            })}
                          </ul>) :
                          (<div className="mb-4"></div>))
                      }
                    </div>
                    <div className="col-md-9">
                      <div className="form-group custom minus-ml">
                        <input type="text" placeholder="&nbsp;" className="form-control"
                          name="alternativeNumber"
                          value={props.alternativeNumber}
                          onChange={(e) => props.handleChange(e)}
                        />
                        {props.validator.message(
                          "alternative Number",
                          props.alternativeNumber,
                          "required|phone"
                        )}
                        <label>Alternative Number</label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group custom">
                    <input type="text" placeholder="&nbsp;" className="form-control"
                      name="email"
                      value={props.email}
                      onChange={(e) => props.handleChange(e)}
                    />
                    {props.validator.message(
                      "email",
                      props.email,
                      "required|email"
                    )}
                    <label>Email</label>
                  </div>
                </div>
                <div className="col-md-7">
                </div>

                <div className="col-12">
                  <div className="btn-group-margin">
                    <button type="submit" className="btn btn-border-success lg-padding"
                      onClick={
                        (props.editIsTrue) ? (e) => props.updateCar(e) : (e) => props.addNewCar(e)}
                    >Save</button>
                    <button className="btn btn-border-basic lg-padding" onClick={history.goBack}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CarInfoPage;