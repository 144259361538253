import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import WorkFlowStepsMenu from '../workflow-steps-menu';
import AndOrComponent from "../Components/AndOrComponent";
import ExpressionComponent from "../Components/ExpressionComponent";
import uuid from "uuid";
import WorkflowRuleTable from "../Components/WorkflowRuleTable";
import RuleSaveClose from "../Components/RuleSaveClose";
import AddTemplateModal from "../Components/Modals/AddTemplateModal";
import ChangeApproverModal from "../Components/Modals/ChangeApproverModal";
import SendBackToEmployeeModal from "../Components/Modals/SendBackToEmployeeModal";
import SendEmailModal from "../Components/Modals/SendEmailModal";
import GenerateExceptionModal from "../Components/Modals/GenerateExceptionModal";
import ActionModal from "../Components/Modals/ActionModal";
import Loader from 'src/_components/common/Loader';
import Input from 'src/_components/common/_forms/input';

const ItemizationWizardPage = props => {

  let data = props;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">W</div>
            <div className="right">
              <h6 className="heading">Workflows - Steps Rules</h6>
              <p className="text">Add/Modify Workflow Information</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <WorkFlowStepsMenu workflow_id={props.workflow_id} count={1} />
            {props.isLoading ?
              <Loader /> :
              !props.showRule ?
                <WorkflowRuleTable
                  allActionOptions={props.allActionOptions}
                  handlePageChange={props.handlePageChange}
                  allWorkflowSteps={props.allWorkflowSteps}
                  allWorkflowRules={props.allWorkflowRules}
                  stepSelectHandler={props.stepSelectHandler}
                  ruleSelectHandler={props.ruleSelectHandler}
                  selectedStep={props.selectedStep}
                  selectedRule={props.selectedRule}
                  selectedAction={props.selectedAction}
                  toggleRuleCreationComponent={props.toggleRuleCreationComponent}
                  modifyRuleHandler={props.modifyRuleHandler}
                  updateRuleHandler={props.updateRuleHandler}
                  removeRuleActionHandler={props.removeRuleActionHandler}
                  removeRuleHandler={props.removeRuleHandler}

                /> :
                null
            }
          </div>
        </div>
        {props.showRule &&
          <div className="p-padding">
            <div className="form-holder">
              <form>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group custom labelup textarea">
                      <textarea
                        placeholder="&nbsp;"
                        className="form-control"
                        value={props.workflowRuleName}
                        onChange={props.handleRuleNameChange}
                      />
                      <label>Name <span className="text-danger">*</span></label>
                    </div>
                  </div>
                </div>
                <div className="profile-card">
                  {
                    props.expressionArray && props.expressionArray.map((expression, idx) => {
                      let expressionLength = props.expressionArray.length - 2;
                      return (
                        <div key={expression.id}>
                          {expression.type === 'operand' ?
                            <ExpressionComponent
                              {...props}
                              expression={expression}
                              expressionArrayLength={props.expressionArray.length}
                              idx={idx}
                              allFormHeaders={props.allFormHeaders}
                              allFieldNames={props.allFieldNames}
                              createExpression={props.createExpression}
                              removeExpression={props.removeExpression}
                              firstBracketOptions={props.firstBracketOptions}
                              lastBracketOptions={props.lastBracketOptions}
                              handleFormHeaderSelection={props.handleFormHeaderSelection}
                              handleFieldNameSelection={props.handleFieldNameSelection}
                              handleParenthesesSelection={props.handleParenthesesSelection}
                              handleExpressionOperator={props.handleExpressionOperator}
                              expressionCheckboxHandler={props.expressionCheckboxHandler}
                              fieldValueSelectHandler={props.fieldValueSelectHandler}
                              fieldValueChangeHandler={props.fieldValueChangeHandler}
                              error={props.error}
                              onMultiLblConnectHandler={props.onMultiLblConnectHandler}
                              onCurrencySelectHandler={props.onCurrencySelectHandler}
                              loadOptions={props.loadOptions}

                            /> :
                            (expressionLength > idx &&
                              <AndOrComponent
                                expression={expression}
                                idx={idx}
                                handleRadioButtonChange={props.handleRadioButtonChange}

                              />)
                          }
                        </div>
                      )
                    })
                  }
                  {/* <AndOrComponent /> */}
                </div>
              </form>
              {/* Rule Save Close Component */}
              {
                <RuleSaveClose
                  hideRuleComponentHandler={props.hideRuleComponentHandler}
                  createRuleHandler={props.createRuleHandler}
                  modifyRuleHandler={props.modifyRuleHandler}
                  editRule={props.editRule}
                  updateRuleHandler={props.updateRuleHandler}
                  isSubmitClick={props.isSubmitClick}
                />
              }
              {/* End of Rule Save Component */}
            </div>
          </div>
        }
      </div>

      <AddTemplateModal
      />
      <ChangeApproverModal />
      <SendBackToEmployeeModal />
      <SendEmailModal />
      <GenerateExceptionModal />
      <ActionModal
        allActionOptions={props.allActionOptions}
        allActionOptionsCondition={props.allActionOptionsCondition}
        allExceptions={props.allExceptions}
        handleExceptionSelection={props.handleExceptionSelection}
        handleExceptionVisibilty={props.handleExceptionVisibilty}
        allEmailTemplatesOptions={props.allEmailTemplatesOptions}
        handleActionSelection={props.handleActionSelection}
        handleActionCreationForRule={props.handleActionCreationForRule}
        handleChangeApprover={props.handleChangeApprover}
        isSearchUserListOpen={props.isSearchUserListOpen}
        selectedAction={props.selectedAction}
        selectedException={props.selectedException}
        selectedExceptionVisibilty={props.selectedExceptionVisibilty}
        skipStepMessageHandler={props.skipStepMessageHandler}
        searchedUsers={props.searchedUsers}
        selectedEmailTemplate={props.selectedEmailTemplate}
        selectChangeApprover={props.selectChangeApprover}
        selectedApprover={props.selectedApprover}
        rejectStepMessageHandler={props.rejectStepMessageHandler}
        sendBackToEmployeeHandler={props.sendBackToEmployeeHandler}
        emailTemplateSelectHandler={props.emailTemplateSelectHandler}
        emailChangeHandler={props.emailChangeHandler}
        emailDefaultApproverHandler={props.emailDefaultApproverHandler}
        emailDefaultApprover={props.emailDefaultApprover}
        action_value={props.action_value}
        handleApprovalCancelClick={props.handleApprovalCancelClick}
        selectedApproverId={props.selectedApprover.id}
        workflow_id={props.workflow_id}
        handleApproveSubmission={props.handleApproveSubmission}
        error={props.error}
        allWorkflowSteps={props.allWorkflowSteps}
        selectedRule={props.selectedRule}
        approverData={props.approverData}
        approverChangeCheckbox={props.approverChangeCheckbox}
      />
    </div>
  );
};
export default ItemizationWizardPage;
