import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import Menu from "../menu";
import RulesDropdown from "./components/RulesDropdown";
import ToggleSlider from "../../../../../common/ToggleSlider/ToggleSlider";
import PolicyViolation from "./policy-violation";
import Loader from "src/_components/common/Loader";
const PolicyViolationPage = props => {
  const {
    activePage,
    itemsCountPerPage,
    totalItemsCount,
    allRules,
    selectedRule,
    policyViolationsByRule,
    selectedPolicies
  } = props.state;

  const {
    handlePageChange,
    handleLimitChange,
    handleRuleClassSelection,
    toggleViolationStatus,
    onSearchHandler,
    policySelectHandler,
    deletePolicyViolationHandler
  } = props;

  let isPolicySelected = !!selectedPolicies.length;
  let actionClasses = ["btn", "dropdown-toggle"];
  if (isPolicySelected) {
    actionClasses.push("active");
  }
  return (
    <div>
      <div className="admin-tabs">
        {/* <Menu /> */}
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">Travel Policy - Rules</h6>
              <p className="text">Add or Edit all policy violations</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <div className="">
              <div className="row">
                <div className="col-4">
                  <div className="form-group custom labelup mb-0">
                    <label>Rule Class</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        {selectedRule ? selectedRule : "Select a Rule Class"}
                      </button>
                      <div className="dropdown-menu">
                        {/* Show all rules on dropdown click */}
                        {allRules
                          ? allRules.map(rule => {
                            return (
                              <RulesDropdown
                                handleButtonClick={handleRuleClassSelection}
                                key={rule.id}
                                name={rule.className}
                                rule={rule}
                              />
                            );
                          })
                          : "No rules found"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-container">
              <form>
                <div className="row">
                  <div className="col-sm-9">
                    <div className="btn-group-margin">
                      <NavLink
                        to="/administration/travel/add-violation-policy"
                        className="btn"
                      >
                        Add Violation Reason
                      </NavLink> 
                    </div>
                  </div>
                  <div className="col-sm-3">
                    {/* <div className="search-custom">
                      <input
                        onChange={e => onSearchHandler(e)}
                        type="text"
                        name=""
                        className="form-control"
                        placeholder="Search"
                      />
                      <span>
                        <i className="material-icons">search</i>
                      </span>
                    </div> */}
                  </div>
                </div>  
              </form>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Code</th>
                      <th>Description</th>
                      <th>Type</th>
                      <th>Action</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      props.state.isLoading ? (
                        <tr>
                          <td colSpan="6" className="text-center"><Loader /></td>
                        </tr>
                      )
                        : (
                          policyViolationsByRule &&
                          policyViolationsByRule.map(policyViolation => {
                            return (
                              <tr key={policyViolation.id}>
                                <td>
                                  <input
                                    onChange={e =>
                                      policySelectHandler(policyViolation)
                                    }
                                    type="checkbox"
                                    name=""
                                    id=""
                                  />
                                </td>
                                <td>{policyViolation.code}</td>
                                <td>{policyViolation.description}</td>
                                <td>{policyViolation.type}</td>
                                <td>
                                  <NavLink
                                    to={
                                      "/administration/travel/add-violation-policy/" +
                                      policyViolation.id
                                    }
                                    className="btn btn-nothing hover-v"
                                  >
                                    <i className="material-icons fs-20">
                                      edit
                                    </i>
                                    <span className="hover-name right-top">Edit</span>
                                  </NavLink>
                                </td>
                                <td>
                                  <ToggleSlider
                                    value={policyViolation.activeStatus}
                                    checked={policyViolation.activeStatus}
                                    toggleSliderHandler={e =>
                                      toggleViolationStatus(policyViolation)
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })
                        )
                    }
                  </tbody>
                </table>
              </div>
              {policyViolationsByRule && policyViolationsByRule.length > 0 ? (
                <div className="table-footer">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="page-count">
                        <div className="text">Items</div>
                        <div className="count">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              {itemsCountPerPage}
                            </button>
                            <div className="dropdown-menu">
                              <button
                                className="dropdown-item"
                                onClick={e => handleLimitChange(10)}
                              >
                                10
                              </button>
                              <button
                                className="dropdown-item"
                                onClick={e => handleLimitChange(20)}
                              >
                                20
                              </button>
                              <button
                                className="dropdown-item"
                                onClick={e => handleLimitChange(30)}
                              >
                                30
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-9">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                  <h6 className="text-center">No Records Found</h6>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PolicyViolationPage;
