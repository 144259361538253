import React from "react";
import Menu from "../menu";
import { NavLink } from "react-router-dom";

const TravelItineraryPage = props => {
  return (
    <div>
      <div className="admin-tabs">
        {/* <Menu /> */}
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">Edit - Trip Duration Greater then 7 days</h6>
              <p className="text">The Travel Policy & Rules feature allows administrators to manage company travel policy and configure violations.</p>
            </div>
          </div>
        </div>

        <div className="p-padding">
          <div className="form-holder">
            <div className="tabs-container">
              <div className="left">
                <div className="heading">
                  <h6 className="sub-heading">Policies</h6>
                </div>
                <div className="scroll">
                  <ul>
                    <li className="selected"><button className="btn btn-nothing">Travel Itinery</button></li>
                    <li><button className="btn btn-nothing">Flights</button></li>
                    <li><button className="btn btn-nothing">Train</button></li>
                    <li><button className="btn btn-nothing">Car</button></li>
                    <li><button className="btn btn-nothing">Hotel</button></li>
                    <li><button className="btn btn-nothing">Bus</button></li>
                  </ul>
                </div>
              </div>
              <div className="right">

                <div className="travel__head py-3 border-bottom">
                  <div className="float-left">
                    <h6 className="font-weight-bold mb-1">Edit - Trip Duration Greater then 7 days</h6>
                    <p  className="mb-0">Has been the  industry's standard dummy text</p>
                  </div>
                  <div className="float-right">
                    <label  className="switch">
                      <input type="checkbox" checked="" />
                      <span  className="slider round"></span>
                    </label>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="form-holder">
                  <div className="row">
                    <div className="col">
                      <div className="form-group custom">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle btn-block"
                            data-toggle="dropdown"
                          >
                            Require Approval
                          </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item"> Require Approval</button>
                            <button className="dropdown-item"> Hide Results (Flight, Hotel, etc)</button>
                            <button className="dropdown-item"> Allow</button>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-7">
                          <div className="form-group custom labelup">
                            <label>Itinerary Booked</label>
                            <div className="dropdown">
                              <button
                                type="button"
                                className="btn dropdown-toggle btn-block"
                                data-toggle="dropdown"
                              >Less then
                              </button>
                              <div className="dropdown-menu">
                                <button className="dropdown-item">Less then</button>
                                <button className="dropdown-item"> Less then</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group custom labelup">
                            <input className="form-control" placeholder="7" />
                            <label>Days In Advance</label>
                          </div>
                        </div>
                      </div>


                      <div className="form-group custom labelup">
                        <label>Type</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle btn-block"
                            data-toggle="dropdown"
                          >
                            G - General
                          </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">G - General</button>
                            <button className="dropdown-item">G - General</button>
                          </div>
                        </div>
                      </div>

                      <div  className="btn-group-margin mt-4">
                        <button type="button"  className="btn">Save</button>
                        <button type="button"  className="btn">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
export default TravelItineraryPage;
