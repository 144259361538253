import React from 'react';
import Menu from '../../menu';
import Pagination from "react-js-pagination";
import { Input, Select } from 'src/_components/common/_forms';
import AuthApproveMenu from '../AuthApproveMenu';
import TreeView from '../../Components/TreeView';
import Loader from 'src/_components/common/Loader';
const AuthorisedApproversListPage = (props) => {
    const { allCurrencies,
        changeCurrency,
        currenyType,
        handleChange,
        error,
        chooseGroup,
        removeGroup,
        searchUser,
        userList,
        choosedUser,
        handlePageChange,
        handleItemChange,
        handleUpdateSubmit,
        changeListType,
        handleSaveSubmit } = props;
    return (
        <div>
            <div className="admin-tabs">
                <Menu />
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">A</div>
                        <div className="right">
                            <h6 className="heading">Authorised Approvers List</h6>
                            <p className="text">User Authorised Approvers to manage Authorised Approvers list and Cost Object Approvers list.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-padding">
                <div className="form-holder">
                    <AuthApproveMenu type={props.authorized_approver_type} changeListType={changeListType} />
                    <div className="">
                        <div className="table-container">
                            <div className="row">
                                <div className="col-md-4 border-right">
                                    <div className="px-padding pr-0">
                                        <TreeView
                                            chooseGroup={chooseGroup}
                                            renderedGroups={props.renderedGroups}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <form className="p-0">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="btn-group-margin">
                                                    {(props.activeItem === '' && props.authorized_approver_group) &&
                                                        <button type="button" data-toggle="modal" data-target="#AddTemplateModal" className="btn">New</button>}
                                                    {(props.activeItem !== '') && <button type="button" data-toggle="modal" data-target="#AddTemplateModal" className="btn">Modify</button>}
                                                </div>
                                            </div>
                                            {props.authorized_approver_group &&
                                                <div className="col-md-4 float-right">
                                                    <button type="button" className="btn">{props.authorized_approver_group.itemName}</button>
                                                    <button onClick={() => removeGroup()} type="button" className="btn">X</button>
                                                </div>}
                                        </div>
                                    </form>
                                    {
                                        <div className="table-scroll-y">
                                            <div className="table-responsive y-hidden">
                                                <table className="table table-striped table-selected">
                                                    <thead>
                                                        <tr>
                                                            <th>Group</th>
                                                            <th>Approver</th>
                                                            <th>Authorized Currency</th>
                                                            <th>Approver Limit</th>
                                                            <th>Level</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(props.count === 0) &&
                                                            <tr>
                                                                <td colSpan="6" className="text-center">
                                                                    {(props.loading) ?
                                                                        <Loader />
                                                                        : "No Records Found"}
                                                                </td>
                                                            </tr>
                                                        }
                                                        {props.listAll && props.listAll.map((item, key) => {
                                                            return (
                                                                <tr key={key} onClick={() => props.modifyItem(item, key)} className={(props.activeItem === key) ? "active-tr" : ""}>
                                                                    <td>{item.authorized_approver_group.itemName}</td>
                                                                    <td>{item.approver_name.email}</td>
                                                                    <td>{item.currency.currencyDetails.itemName}</td>
                                                                    <td>{item.amount}</td>
                                                                    <td>{item.authorized_approver_level}</td>
                                                                    {/* <td>{(item.approver_can_approv_exception) ? "Yes" : "No"}</td> */}
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                                {props.listAll.length !== 0 && (
                                                    <div className="table-footer">
                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <div className="page-count">
                                                                    <div className="text">Items</div>
                                                                    <div className="count">
                                                                        {props.pageItemsList && (
                                                                            <Select
                                                                                placeholder={props.limit}
                                                                                keyName={"items"}
                                                                                options={props.pageItemsList}
                                                                                controlFunc={e => handleItemChange(e)}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <Pagination
                                                                    activePage={props.page}
                                                                    itemsCountPerPage={10}
                                                                    totalItemsCount={props.count}
                                                                    pageRangeDisplayed={3}
                                                                    onChange={handlePageChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="AddTemplateModal" tabIndex="-1" role="dialog"
                aria-labelledby="AddTemplateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content border-0">
                        <div className="modal-head">
                            <h5 className="modal-title" id="AddTemplateModalLabel">{(props.activeItem !== '') ? "Update" : "New"} Authorised Approver</h5>
                        </div>
                        <form onSubmit={(props.activeItem === '') ? e => handleSaveSubmit(e) : e => handleUpdateSubmit(e)}>
                            <div className="modal-middle pm-30 dragndrop">
                                <div className="form-group custom bg-light">
                                    <input type="text" placeholder="&nbsp;" disabled className="form-control " />
                                    <label>{props.authorized_approver_group.itemName}</label>
                                </div>
                                <div className="form-group custom">
                                    <div className="index_class">
                                        <input autoComplete="off" type="text" placeholder="&nbsp;" value={props.typing} name="username" onChange={e => searchUser(e)} className="form-control" ></input>
                                        <label>Search Users <span className="text-danger">*</span></label>
                                        {userList.length !== 0 && (
                                            <ul className="search-list">
                                                {props.loadingUser && <li>loading</li>}
                                                {userList.map((item, key) => (
                                                    <li key={key} onClick={e => choosedUser(item)}>
                                                        {item.email}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        <div className="help-block">{(props.userErr) && "The approver field is required."}</div>
                                    </div>
                                </div>

                                <div className="form-group custom">
                                    <Input
                                        label="Level"
                                        type="number"
                                        name="authorized_approver_level"
                                        imp="true"
                                        value={props.authorized_approver_level}
                                        error={error.message('Level', props.authorized_approver_level, 'required|integer')}
                                        controlFunc={e => handleChange(e)}
                                    />
                                </div>
                                <h6 className="sub-heading"><b>Approval Limit</b></h6>
                                <div className="form-group custom labelup bg-white">
                                    <label>Currency</label>
                                    {allCurrencies && (
                                        <div className="dropdown">
                                            <button
                                                type="button"
                                                className={"btn dropdown-toggle "}
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"
                                            >
                                                {currenyType}
                                            </button>
                                            <div className="dropdown-menu">
                                                {allCurrencies && allCurrencies.map((opt, index) => {
                                                    return (
                                                        <button
                                                            type="button"
                                                            onClick={e => changeCurrency(opt)}
                                                            className="dropdown-item"
                                                            key={index}
                                                        >
                                                            {opt.currencyDetails.itemName}
                                                        </button>
                                                    );
                                                })}
                                                {!allCurrencies &&
                                                    <button
                                                        type="button"
                                                        className="dropdown-item"
                                                    >
                                                        No Records Found
                                        </button>
                                                }
                                            </div>
                                            <div className="help-block">
                                                {error.message('currency', props.currency, 'required')}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group custom bg-white">
                                    <Input
                                        label="Amount"
                                        type="number"
                                        name="amount"
                                        imp="true"
                                        value={props.amount}
                                        error={error.message('Amount', props.amount, 'required|integer')}
                                        controlFunc={e => handleChange(e)}
                                    />
                                </div>
                                {(props.formLoading) ?
                                    <div className="modal-last btn-group-margin">
                                        <button type="button" className="btn btn-border-basic lg-padding">Please wait... </button>
                                    </div>
                                    :
                                    <div className="modal-last btn-group-margin">
                                        <button type="Submit" className="btn btn-border-success lg-padding">{(props.activeItem !== '') ? "Update" : "Save"}</button>
                                        <button type="button" onClick={e => props.reset(e)} className="close btn btn-border-basic lg-padding"
                                            data-dismiss="modal">Cancel </button>
                                    </div>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default AuthorisedApproversListPage;