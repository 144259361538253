import {http} from '../_helpers';
import { config } from '../env';



function getTravelAdminDetails() {
  return http.get(`${config.apiUrlTravel}travel/admin`)
    .then(res=> res)
}

function saveTravelAdminDetails(payload){
  // return http.post(`${config.apiUrlTravel}travel/admin`, payload, {}, true)
  //   .then(res =>({statusCode: res.status, data: res.json()}))
  return http.post(`${config.apiUrlTravel}travel/admin`, payload)
    .then(res => res)

}

function updateTravelDetails(payload) {
  return http.patch(`${config.apiUrlTravel}travel/admin`, payload)
    .then(res => res)
}


export const travelAdminService = {
  saveTravelAdminDetails,
  getTravelAdminDetails,
  updateTravelDetails

}