import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import Loader from "src/_components/common/Loader";
const CashAdvanceConfigPage = props => {
  const { loading } = props;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">A</div>
            <div className="right">
              <h6 className="heading">Cash Advance Configuration</h6>
              <p className="text">Use configuration to map workflow, form and cash advance process.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      <div className="py-padding">
        <div className="table-container">
          <form>
            <div className="row">
              <div className="col-md-9">
                <div className="btn-group-margin">
                  <NavLink
                    to="/administration/expense/cash-advance-config/add-cash-advance-config"
                    className="btn"
                    disabled={props.enableModify ? "disabled" : ''}
                  >
                    New
                  </NavLink>
                  <button type="button"
                    onClick={props.onModifyClickHandler}
                    className="btn"
                    disabled={!props.enableModify ? "disabled" : ''}
                  >
                    Modify
                  </button>
                  <button type="button"
                    onClick={props.onDeleteClickHandler}
                    className="btn"
                    disabled={props.enabelRemove ? '' : 'disabled'}
                  >
                    Remove
                  </button>
                </div>
              </div>
              <div className="col-md-3">
                {/* <div className="search-custom">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder="Search"
                  />
                  <span>
                    <i className="material-icons">search</i>
                  </span>
                </div> */}
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped table-selected">
              <thead>
                <tr>
                  <th>Config Name</th>
                  <th>Applies To</th>
                  <th>Editable By</th>
                  <th>Status</th>
                </tr>
              </thead>
              {
                loading
                  ?
                 ( <tr className="bg-white">
                    <td colSpan={4} className="text-center"><Loader /></td>
                  </tr>)
                  : 
                  <tbody>{props.cashAdvanceList ? props.cashAdvanceList.map((list, index) => {
                    return (
                      <tr key={index} className={props.selectedConfig === list.id ? 'active-tr' : ''} >
                        <td onClick={(e) => props.onSelectedConfig(list.id)}>{list.configName}</td>
                        <td onClick={(e) => props.onSelectedConfig(list.id)}>{
                          // list.appliesObj && list.appliesObj.result && list.appliesObj.result.map((applies, index) => applies.itemName + ', ')
                          list.appliesObj && list.appliesObj.result && list.appliesObj.result.map((applies, index) => (index ? ', ' : '') + applies.itemName)
                        }</td>
                        <td onClick={(e) => props.onSelectedConfig(list.id)}>
                          {
                            list.editableObj && list.editableObj.result && list.editableObj.result.map((applies, index) => (index ? ', ' : '') + applies.itemName)
                          }</td>
                        <td>
                          <label className="switch">
                            <input onChange={(e) => props.onChangeStatusHandler(list.id, list.activeStatus)} checked={list.activeStatus} type="checkbox" />
                            <span className="slider round" />
                          </label>
                        </td>
                      </tr>
                    )
                  }) : null
                  }
                    {
                      props.cashAdvanceList.length == 0 ? <tr><td colSpan={4} className="text-center">No Records Found</td></tr> : null
                    }

                  </tbody>
              }

            </table>
          </div>
          {props.totalCount && props.totalCount > 0 ?
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          {props.pageLimit}
                        </button>
                        <div className="dropdown-menu">
                          <button onClick={(e) => props.onLimitChangeHandler(10)}
                            type="button" className="dropdown-item">
                            10
                        </button>
                          <button onClick={(e) => props.onLimitChangeHandler(20)}
                            type="button" className="dropdown-item">
                            20
                        </button>
                          <button onClick={(e) => props.onLimitChangeHandler(30)}
                            type="button" className="dropdown-item">
                            30
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <Pagination
                    activePage={props.activePage}
                    itemsCountPerPage={props.pageLimit}
                    totalItemsCount={props.totalCount}
                    pageRangeDisplayed={5}
                    onChange={props.onPageChangeHandler}
                  />
                </div>
              </div>
            </div>
            : null}
        </div>
      </div>
    </div>
  );
};
export default CashAdvanceConfigPage;
