import React, { Component } from "react";
import RequestPage from "./request-html";
import { masterService, companyService } from "../../../../../../_services";

class Request extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      serviceModuleId: "",
      companyId: localStorage["440a28"]
    };
  }
  componentDidMount() {
    this.getService();
  }
  listOfItems = [];
  selectedItem = [];
  search = '';
  searchAssign = '';
  getMenuPermission = async () => {
    this.listOfItems = [];
    this.selectFlag = false;
    this.selectedItem = []
    var resp = await masterService.menuPermission(this.state.companyId, this.userId, this.state.serviceModuleId, this.search);
    if (resp) {
      this.listOfItems = resp;
    }
    this.forceUpdate();
  }
  handleSearch = (e) => {
    this.search = e.target.value;
    this.getMenuPermission()
  }
  handleSearchAssign = (e) => {
    this.searchAssign = e.target.value;
    this.getRightMenu()
  }
  listService = [];
  getService = async () => {
    var resp = await masterService.getServiceModuleListFl();
    if (resp) {
      this.listService = resp.result;
      this.setState({ serviceModuleId: resp.result[0].id })
      this.forceUpdate();
    }
  };
  userList = [];
  searchUser = async e => {
    this.userList = [];
    var val = e.target.value;
    if (val.length > 1) {
      var resp = await companyService.getUserList(e.target.value,this.state.companyId);
      if (resp) {
        this.userList = resp.result;
      }
    }
    this.forceUpdate()
  };
  userId = "";
  choosedUser = e => {
    this.userId = e.id;
    this.userName = e.email;
    this.userList = [];
    this.getMenuPermission()
    this.getRightMenu()
    this.forceUpdate();
  };
  removeUser = () => {
    this.userId = "";
    this.userName = "";
    this.forceUpdate();
  };
  storeServiceModule = e => {
    this.setState({
      serviceModuleId: e
    }, () => {
      if (this.userId) {
        this.getMenuPermission()
        this.getRightMenu()
        this.forceUpdate()
      }
    });
  };
  selectFlag = false;
  selectAll = () => {
    this.selectFlag = !this.selectFlag;
    if (this.selectedItem.length !== this.listOfItems.length) {
      this.selectedItem = []
      if (this.selectFlag === false) {
        this.forceUpdate()
        return;
      }
    }
    this.listOfItems.forEach(item => {
      const main = this.selectedItem.indexOf(item.id)
      if (main === -1) {
        this.selectedItem.push(item.id)
      } else {
        this.selectedItem.splice(main, 1)
      }
    })
    this.forceUpdate()
  }
  chooseItem = (id) => {
    const index = this.selectedItem.indexOf(id)
    if (index === -1) {
      this.selectedItem.push(id)
    } else {
      this.selectedItem.splice(index, 1)
    }
    this.forceUpdate()
  }
  moveLeft = async () => {
    var req = {
      serviceModuleId: this.state.serviceModuleId,
      userId: this.userId,
      menuId: this.selectedItem
    }
    var resp = await companyService.createPermission(req);
    if (resp) {
      this.getMenuPermission()
      this.getRightMenu()
    }
  }

  // right side menu creation

  getRightMenu = async () => {
    this.listOfItemsAssign = [];
    this.selectFlagAssign = false;
    this.selectedItemAssign = []
    var resp = await companyService.getUserPermission(this.userId, this.searchAssign, this.state.serviceModuleId);
    if (resp) {
      this.listOfItemsAssign = resp;
    }
    this.forceUpdate();
  }
  selectFlagAssign = false;
  selectedItemAssign = []
  selectAllAssign = () => {
    this.selectFlagAssign = !this.selectFlagAssign;
    if (this.selectedItemAssign.length !== this.listOfItemsAssign.length) {
      this.selectedItemAssign = []
      if (this.selectFlagAssign === false) {
        this.forceUpdate()
        return;
      }
    }
    this.listOfItemsAssign.forEach(item => {
      const main = this.selectedItemAssign.indexOf(item.id)
      if (main === -1) {
        this.selectedItemAssign.push(item.id)
      } else {
        this.selectedItemAssign.splice(main, 1)
      }
    })
    this.forceUpdate()
  }
  chooseItemAssign = (id) => {
    const index = this.selectedItemAssign.indexOf(id)
    if (index === -1) {
      this.selectedItemAssign.push(id)
    } else {
      this.selectedItemAssign.splice(index, 1)
    }
    this.forceUpdate()
  }
  moveRight = async () => {
    var resp = await companyService.removePermission({ id: this.selectedItemAssign });
    if (resp) {
      this.getMenuPermission()
      this.getRightMenu()
    }
  }
  render() {
    return (
      <div>
        <RequestPage
          searchUser={this.searchUser}
          userList={this.userList}
          choosedUser={this.choosedUser}
          userName={this.userName}
          listService={this.listService}
          removeUser={this.removeUser}
          storeServiceModule={this.storeServiceModule}
          serviceModuleId={this.state.serviceModuleId}
          listOfItems={this.listOfItems}
          handleSearch={this.handleSearch}
          selectFlag={this.selectFlag}
          selectedItem={this.selectedItem}
          selectAll={this.selectAll}
          chooseItem={this.chooseItem}
          moveLeft={this.moveLeft}
          moveRight={this.moveRight}
          listOfItemsAssign={this.listOfItemsAssign}
          handleSearchAssign={this.handleSearchAssign}
          selectFlagAssign={this.selectFlagAssign}
          selectedItemAssign={this.selectedItemAssign}
          selectAllAssign={this.selectAllAssign}
          chooseItemAssign={this.chooseItemAssign}
        />
      </div>
    );
  }
}
export default Request;
