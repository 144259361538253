import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import moment from 'moment';
import { Select, Input } from "../../common/_forms";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import ReactSelect from 'react-select';
import DatePicker from "react-datepicker";
import Loader from '../../common/Loader';
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import Moment from "react-moment";

class TravelSettingsPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  redirect(e) {
    $(".close").click();
    $(".itemisationExpenseModal").close();
  }
  render() {
    const countriesBirthList = [];
    this.props.state.countriesBirthList && this.props.state.countriesBirthList.forEach((item) => {
      countriesBirthList.push({ label: item.countryOfBirth, value: item.countryOfBirth, key: item.key });
    })
    const countriesIssueList = [];
    this.props.state.countriesIssueList && this.props.state.countriesIssueList.forEach((item) => {
      countriesIssueList.push({ label: item.countryOfIssue, value: item.countryOfIssue, key: item.key });
    })
    const countriesList = [];
    this.props.state.countriesList && this.props.state.countriesList.forEach((item) => {
      countriesList.push({ label: item.issuedFor, value: item.value, key: item.key });
    })
    let frequentTravellerProgram_Airline =
      this.props.state.frequentTravellerProgramme_Airline[0] !== undefined &&
        this.props.state.frequentTravellerProgramme_Airline[0].programmeName ? (
          this.props.state.frequentTravellerProgramme_Airline.map(
            (data, index) => {
              return (
                <tr
                  key={index}
                  onClick={() =>
                    this.props.frequentTravellerProgramme(index, data)
                  }
                  className={
                    this.props.state.selectedProgrammeIndex === index
                      ? "active-tr"
                      : ""
                  }
                >
                  <td>
                    {data.travellerName}
                  </td>
                  <td>
                    {data.brand.name}
                  </td>
                  <td>
                    <p
                      className={
                        this.props.state.selectedProgrammeIndex === index
                          ? "text-uppercase"
                          : "text-uppercase text-primary"
                      }
                    >
                      {data.programmeName}
                    </p>
                  </td>
                  <td>
                    {data.frequentFlyerNumber}
                  </td>
                  <td>
                    <button type="button"
                      className="btn btn-nothing hover-v"
                      disabled={!this.props.state.clicked1}
                      onClick={(e) => this.props.removeItem(data._id, "FrequentTravellerProgramme")}>
                      <i className="material-icons">
                        delete
                      </i>
                      <span className="hover-name right-top">Remove</span>
                    </button>
                  </td>
                </tr >
              );
            }
          )
        ) : (
          <tr><td className="text-center" colSpan="5">No Frequent Traveller Programme Added</td></tr>
        );

    let passportDetail =
      this.props.state.passport[0] !== undefined &&
        this.props.state.passport[0].passportNumber ? (
          this.props.state.passport.map((data, index) => {
            return (
              <tr key={index}
                onClick={() => this.props.updatePassPort(index, data)}
                className={
                  this.props.state.selectedPassportIndex === index
                    ? "active-tr"
                    : ""
                }
              >
                <td
                >
                  {data.title + " " + data.lastName + " " + data.firstName}
                </td>
                <td>
                  <p
                    className={
                      this.props.state.selectedPassportIndex === index
                        ? "text-uppercase"
                        : "text-uppercase text-primary"
                    }
                  >
                    {data.countryOfIssue}
                  </p>
                </td>
                <td>{data.passportNumber}
                </td>
                <td><Moment format="DD MMM YYYY">{data.expiryDate}</Moment>
                </td>
                <td>
                  <button type="button" className="btn btn-nothing hover-v"
                    disabled={!this.props.state.clicked2}
                    onClick={(e) => this.props.removeItem(data._id, "Passport")}>
                    <i className="material-icons">
                      delete
                      </i>
                    <span className="hover-name right-top">Remove</span>
                  </button>
                </td>
              </tr>
            );
          })
        ) : (
          <tr><td className="text-center" colSpan="5">No Passport Added</td></tr>
        );


    let visaDetail =
      this.props.state.visa[0] !== undefined &&
        this.props.state.visa[0].visaNumber ? (
          this.props.state.visa.map((data, index) => {
            return (
              <tr
                key={index}
                onClick={() => this.props.updateVisa(index, data)}
                className={
                  this.props.state.selectedVisaIndex === index
                    ? "active-tr"
                    : ""
                }
              >
                <td>
                  {data.issuedFor}
                </td>
                <td>
                  <p
                    className={
                      this.props.state.selectedVisaIndex === index
                        ? ""
                        : "text-primary"
                    }
                  >
                    {data.countryOfIssue}
                  </p>
                </td>
                <td>
                  {data.visaNumber}
                </td>
                <td><Moment format="DD MMM YYYY">{data.expiryDate}</Moment>
                </td>
                <td>
                  <button type="button" className="btn btn-nothing hover-v"
                    disabled={!this.props.state.clicked3}
                    onClick={(e) => this.props.removeItem(data._id, "Visa")}>
                    <i className="material-icons">
                      delete
                      </i>
                    <span className="hover-name right-top">Remove</span>
                  </button>
                </td>
              </tr>
            );
          })
        ) : (
          <tr><td className="text-center" colSpan="5">No Visa Added</td></tr>
        );

    let otherIdProofs =
      this.props.state.otherIdProofs && this.props.state.otherIdProofs.length > 0 ?
        this.props.state.otherIdProofs.map((data, index) => {
          return (
            <tr
              key={index}
              onClick={() => this.props.updateOtherIdProof(index, data)}
              className={
                this.props.state.selectedOtherIdIndex === index
                  ? "active-tr"
                  : ""
              }
            >
              <td className="text-primary">{data.idName}</td>
              <td>{data.nameOfId}</td>
              <td>{data.issuer}</td>
              <td><Moment format="DD MMM YYYY">{data.validTill}</Moment></td>
              <td className="text-primary">{data.idNumber}</td>
              {/* <td>
                <button type="button" className="btn btn-nothing hover-v" data-toggle="modal" data-target="#PassportModal"><i className="material-icons fs-20">attach_file</i><span className="hover-name right-top">Attach Photo</span></button>
              </td> */}
              {/* <td>
                <button type="button" className="btn btn-nothing hover-v" data-toggle="modal" data-target="#addIDModal"><i className="material-icons fs-20">edit</i><span className="hover-name right-top">Edit</span></button>
              </td> */}
              <td>
                <button type="button" className="btn btn-nothing hover-v"
                  disabled={!this.props.state.clicked4}
                  onClick={(e) => this.props.removeItem(data._id, "OtherIdProofs")}
                ><i className="material-icons">delete</i><span className="hover-name right-top">Remove</span></button>
              </td>
            </tr >

          )
        }) : (
          <tr><td className="text-center" colSpan="6">No Other Id Proofs Added</td></tr>
        )

    return (
      <div>
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">T</div>
            <div className="right">
              <h6 className="heading">Travel Settings</h6>
              <p className="text">Change the settings for travel here.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="p-padding">
          <div className="admin-tabs">
            <ul id="nav-tab123" role="tablist" className="nav nav-tabs px-0" >
              <li>
                <button className="nav-item nav-link active"
                  id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true"
                >Travel Preferences</button>
              </li>
              <li>
                <button className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Passport</button>
              </li>
              <li>
                <button className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Visa</button>
              </li>
              <li>
                <button className="nav-item nav-link" id="nav-id-proof-tab" data-toggle="tab" href="#nav-id-proof" role="tab" aria-controls="nav-id-proof" aria-selected="false">Other ID Proofs</button>
              </li>
            </ul>
          </div>
        </div>
        <div className="p-padding pt-0">
          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              <div className="form-holder mb-4">
                <h6 className="sub-heading">Air Travel Preferences</h6>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="form-group custom labelup mb-0">
                      <label>Seat</label>
                      <Select
                        placeholder={
                          this.props.state.airTravelPreferences ? this.props.state.airTravelPreferences.seat !=
                            undefined
                            ? this.props.state.airTravelPreferences.seat
                            : "Select" : ""
                        }
                        keyName={"seat"}
                        options={[
                          { seat: "Aisle", value: 1 },
                          { seat: "Window", value: 2 },
                          { seat: "Doesn't Matter", value: 3 }
                        ]}
                        controlFunc={e =>
                          this.props.selectHandlerPreference(e, "seat")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="form-group custom labelup minus-ml mb-0">
                      <label>Meals</label>
                      <Select
                        placeholder={
                          this.props.state.airTravelPreferences ?
                            this.props.state.airTravelPreferences.specialMeals !== undefined
                              ? this.props.state.airTravelPreferences
                                .specialMeals
                              : "Select" : ""
                        }
                        keyName={"specialMeals"}
                        options={[
                          { specialMeals: "Vegetarian Indian Meal", value: 1 },
                          { specialMeals: " Baby Meal", value: 2 },
                          { specialMeals: " Bland Meal", value: 3 },
                          { specialMeals: " Child Meal", value: 4 },
                          { specialMeals: " Diabetic Meal", value: 5 },
                          { specialMeals: " Fruit Platter Meal", value: 6 },
                          { specialMeals: " Gluten Intolerant Meal", value: 7 },
                          { specialMeals: " Hindu Meal", value: 8 },
                          { specialMeals: " Kosher Meal", value: 9 },
                          { specialMeals: " Low Calorie Meal", value: 10 },
                          { specialMeals: " Low Fat Meal", value: 11 },
                          { specialMeals: " Low Salt Meal", value: 12 },
                          { specialMeals: " Moslem Meal", value: 13 },
                          { specialMeals: " Low Lactose Meal", value: 14 },
                          { specialMeals: " No Meal", value: 15 },
                          { specialMeals: " Vegetarian Raw Meal", value: 16 },
                          { specialMeals: " Sea Food Meal", value: 17 },
                          { specialMeals: " Special Meal", value: 18 },
                          { specialMeals: " Vegetarian Vegan Meal", value: 19 },
                          { specialMeals: " Vegetarian Jain Meal", value: 20 },
                          { specialMeals: " Vegetarian Lacto-ovo Meal", value: 21 },
                          { specialMeals: " Special Meal", value: 22 },
                          { specialMeals: " Vegetarian Vegan Meal", value: 23 },
                          { specialMeals: " Vegetarian Jain Meal", value: 24 },
                          { specialMeals: " Vegetarian Lacto-ovo Meal", value: 25 },
                          { specialMeals: " Vegetarian Oriental Meal", value: 26 }
                        ]}
                        controlFunc={e =>
                          this.props.selectHandlerPreference(
                            e,
                            "specialMeals"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-holder">
                <div className="float-right">
                  <button
                    className="btn"
                    data-toggle="modal"
                    data-target="#addProgramAirline"
                  >
                    {this.props.state.selectedProgrammeIndex === ""
                      ? "Add Programme"
                      : "Edit Programme"}
                  </button>
                </div>
                <h6 className="sub-heading pt-2 mb-4">Frequent Traveller Programme - Airline</h6>
                <div className="table-container">
                  <div className="table-responsive">
                    <table className="table table-striped table-selected">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Program</th>
                          <th>Frequent Flyer Number</th>
                          <th>Remove Programme</th>
                        </tr>
                      </thead>
                      <tbody>
                        {frequentTravellerProgram_Airline}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="confirmation p-0">
                  <i className="material-icons">event_note</i>
                  <span>
                    Kindly ensure your Name matches in the programme and other
                    ID proofs
                    </span>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <div className="form-holder">
                <div className="float-right">
                  <button
                    className="btn"
                    data-toggle="modal"
                    data-target="#addPassportModal"
                    onClick={
                      this.props.state.selectedPassportIndex === ""
                        ? this.props.resetForm
                        : ""
                    }
                  >
                    {this.props.state.selectedPassportIndex === ""
                      ? "Add a Passport"
                      : "Edit Passport"}
                  </button>
                </div>
                <h6 className="sub-heading pt-2 mb-4">Passport</h6>
                <div className="table-container">
                  <div className="table-responsive">
                    <table className="table table-striped table-selected">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Country of Issue</th>
                          <th>Passport Number</th>
                          <th>Expiry Date</th>
                          <th>Delete passport</th>
                        </tr>
                      </thead>
                      <tbody>
                        {passportDetail}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
              <div className="form-holder">
                <div className="float-left">
                  <h6 className="sub-heading">Visa</h6>
                </div>
                <div className="float-right">
                  <button
                    className="btn"
                    data-toggle="modal"
                    data-target="#addVisaModal"
                    onClick={
                      this.props.state.selectedVisaIndex === ""
                        ? this.props.resetForm
                        : ""
                    }
                  >
                    {this.props.state.selectedVisaIndex === ""
                      ? "Add Visa"
                      : "Edit Visa"}
                  </button>
                </div>
                <div className="clearfix"></div>
                <div className="table-container">
                  <div className="table-responsive">
                    <table className="table table-striped table-selected">
                      <thead>
                        <tr>
                          <th>Issued for(Country)</th>
                          <th>Country of Issue</th>
                          <th>Visa Number</th>
                          <th>Expiry Date</th>
                          <th>Remove visa</th>
                        </tr>
                      </thead>
                      <tbody>
                        {visaDetail}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="nav-id-proof" role="tabpanel" aria-labelledby="nav-id-proof-tab">
              <div className="form-holder">
                <div className="float-right">
                  {/* <button className="btn" data-toggle="modal" data-target="#addIDModal">Add Id</button> */}

                  <button
                    className="btn"
                    data-toggle="modal"
                    data-target="#addIDModal"
                    onClick={
                      this.props.state.selectedOtherIdIndex === ""
                        ? this.props.resetForm
                        : ""
                    }
                  >
                    {this.props.state.selectedOtherIdIndex === ""
                      ? "Add ID"
                      : "Edit ID"}
                  </button>



                </div>
                <h6 className="sub-heading pt-2 mb-4">Id Proofs</h6>
                <div className="clearfix"></div>
                <div className="table-container">
                  <div className="table-responsive">
                    <table className="table table-striped table-selected">
                      <thead>
                        <tr>
                          <th>Id Name</th>
                          <th>Name on Idss</th>
                          <th>Issuer</th>
                          <th>Valid Till </th>
                          <th>ID Number</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {otherIdProofs}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div
          className="modal fade"
          id="addProgramAirline"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addProgramAirlineLable"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">New</h6>
                <h5 className="modal-title" id="addProgramAirlineLable">
                  Add Programme - Airline
                </h5>
              </div>
              <form onSubmit={e => this.props.addEditForm1(e)}>
                <div className="modal-middle pm-30">
                  <div className="form-group custom">
                    <Input
                      label="Traveler Name"
                      type="text"
                      name="travellerName"
                      value={
                        this.props.state.form1.travellerName !== undefined
                          ? this.props.state.form1.travellerName
                          : ""
                      }
                      imp="true"
                      error={this.props.validator1.message(
                        "Traveler Name",
                        this.props.state.form1.travellerName,
                        "required"
                      )}
                      controlFunc={e => this.props.inputChangeHandlerForm1(e)}
                    />
                  </div>
                  <div className="form-group custom labelup">
                    <Select
                      label={"Brand"}
                      placeholder={
                        this.props.state.form1.brand
                          ? this.props.state.form1.brand.name
                          : "Select an option"
                      }
                      keyName={"name"}
                      options={this.props.state.brandList}
                      controlFunc={e => this.props.selectHandlerForm1(e)}
                      error={this.props.validator1.message(
                        "Brand",
                        this.props.state.form1.brand,
                        "required"
                      )}
                    />
                    <label>Airline <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-group custom">
                    <Input
                      label="Programme Name"
                      type="text"
                      name="programmeName"
                      imp="true"
                      value={
                        this.props.state.form1.programmeName !== undefined
                          ? this.props.state.form1.programmeName
                          : ""
                      }
                      error={this.props.validator1.message(
                        "Programme Name",
                        this.props.state.form1.programmeName,
                        "required"
                      )}
                      controlFunc={e => this.props.inputChangeHandlerForm1(e)}
                    />
                  </div>
                  <div className="form-group custom">
                    <Input
                      label="Frequent Flyer Number"
                      type="text"
                      imp="true"
                      name="frequentFlyerNumber"
                      value={
                        this.props.state.form1.frequentFlyerNumber !== undefined
                          ? this.props.state.form1.frequentFlyerNumber
                          : ""
                      }
                      error={this.props.validator1.message(
                        "Frequent Flyer Number",
                        this.props.state.form1.frequentFlyerNumber,
                        "required"
                      )}
                      controlFunc={e => this.props.inputChangeHandlerForm1(e)}
                    />
                  </div>
                  <div className="modal-last btn-group-margin">
                    <button type="submit" className="btn">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn close"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="addPassportModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addPassportLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">New</h6>
                <h5 className="modal-title" id="addPassportLabel">
                  {this.props.state.selectedPassportIndex !== ""
                    ? "Edit Passport"
                    : "Add Passport"}
                </h5>
              </div>
              <form onSubmit={e => this.props.addEditForm2(e)}>
                <div className="modal-middle pm-30">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-md-4 px-2">
                            <div className="form-group custom labelup">
                              <label>Title <span className="text-danger">*</span></label>
                              <div className="dropdown">
                                <Select
                                  placeholder={
                                    this.props.state.form2.title
                                      ? this.props.state.form2.title
                                      : "Title"
                                  }
                                  keyName={"title"}
                                  options={[
                                    {
                                      title: "Mr",
                                      value: "Mr",
                                      stateKey: "passport",
                                      key: "title"
                                    },
                                    {
                                      title: "Mrs",
                                      value: "Mrs",
                                      stateKey: "passport",
                                      key: "title"
                                    },
                                    {
                                      title: "Ms",
                                      value: "Ms",
                                      stateKey: "passport",
                                      key: "title"
                                    }
                                  ]}
                                  controlFunc={e =>
                                    this.props.selectHandlerForm2(e, "title")
                                  }
                                  error={this.props.validator2.message(
                                    "Title",
                                    this.props.state.form2.title,
                                    "required"
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8 px-2">
                            <div className="form-group custom">
                              <Input
                                label="Given Name"
                                type="text"
                                name="firstName"
                                imp="true"
                                value={
                                  this.props.state.form2.firstName !== undefined
                                    ? this.props.state.form2.firstName
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "Last Name",
                                  this.props.state.form2.firstName,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col px-2">
                            <div className="form-group custom">
                              <Input
                                label="Last Name"
                                type="text"
                                name="lastName"
                                imp="true"
                                value={
                                  this.props.state.form2.lastName !== undefined
                                    ? this.props.state.form2.lastName
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "First Name",
                                  this.props.state.form2.lastName,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup" style={{ zIndex: '999' }}>
                              <label>Date of Birth <span className="text-danger">*</span></label>
                              {<DatePicker
                                dateFormat="dd MMM yyyy"
                                selected={this.props.state.form2.dateOfBirth ? new Date(this.props.state.form2.dateOfBirth) : new Date()}
                                onChange={date => this.props.handleBirthDayChange(
                                  date,
                                  "form2",
                                  "dateOfBirth"
                                )}
                                maxDate={new Date()}
                                showYearDropdown
                                placeholderText="DD MMM YYYY"
                                className="form-control"
                              />}

                              {/* <DayPickerInput
                                placeholder="DD MMM YYYY"
                                dayPickerProps={{ disabledDays: { after: new Date() } }}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                format="LL"
                                value={
                                  this.props.state.form2.dateOfBirth
                                    ? `${formatDate(
                                      new Date(this.props.state.form2.dateOfBirth),
                                      "LL",
                                      "it"
                                    )}`
                                    : ""
                                }
                                onDayChange={date =>
                                  this.props.handleDayChange(
                                    date,
                                    "form2",
                                    "dateOfBirth"
                                  )
                                }
                              /> */}
                              <span className="icon-custom">
                                <i className="material-icons">calendar_today</i>
                              </span>
                            </div>

                          </div>
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <label>Gender <span className="text-danger">*</span></label>
                              <div className="dropdown">
                                <Select
                                  placeholder={
                                    this.props.state.form2.gender
                                      ? this.props.state.form2.gender
                                      : "Select Gender"
                                  }
                                  keyName={"gender"}
                                  options={[
                                    {
                                      gender: "Male",
                                      value: "Male",
                                      stateKey: "passport",
                                      key: "gender"
                                    },
                                    {
                                      gender: "Female",
                                      value: "Female",
                                      stateKey: "passport",
                                      key: "gender"
                                    }
                                  ]}
                                  controlFunc={e =>
                                    this.props.selectHandlerForm2(e)
                                  }
                                  error={this.props.validator2.message(
                                    "Gender",
                                    this.props.state.form2.gender,
                                    "required"
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 px-2">
                            <div className="form-group custom">
                              <Input
                                label="Place Of Birth"
                                type="text"
                                name="placeOfBirth"
                                imp="true"
                                value={
                                  this.props.state.form2.placeOfBirth !==
                                    undefined
                                    ? this.props.state.form2.placeOfBirth
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "Place Of Birth",
                                  this.props.state.form2.placeOfBirth,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <label>Country Of Birth <span className="text-danger">*</span></label>
                              <ReactSelect
                                value={{
                                  label: this.props.state.form2.countryOfBirth
                                  , value: this.props.state.form2.countryOfBirth
                                }}
                                onChange={e =>
                                  this.props.selectHandlerForm2(e)}
                                options={countriesBirthList}
                                isSearchable={true}
                              />
                              <div className="text-danger" style={{ fontSize: "12px" }}>
                                {this.props.validator2.message(
                                  "Country Of Birth",
                                  this.props.state.form2.countryOfBirth,
                                  "required"
                                )}
                              </div>
                              {/* <div className="dropdown">
                                <Select
                                  placeholder={
                                    this.props.state.form2.countryOfBirth
                                      ? this.props.state.form2.countryOfBirth
                                      : "Select"
                                  }
                                  keyName={"countryOfBirth"}
                                  options={this.props.state.countriesBirthList}
                                  controlFunc={e =>
                                    this.props.selectHandlerForm2(e)
                                  }
                                  error={this.props.validator2.message(
                                    "Country Of Birth",
                                    this.props.state.form2.countryOfBirth,
                                    "required"
                                  )}
                                />
                              </div> */}
                            </div>
                          </div>
                          <div className="col px-2">
                            <div className="form-group custom">
                              <Input
                                label="Passport Number"
                                type="text"
                                name="passportNumber"
                                imp="true"
                                value={
                                  this.props.state.form2.passportNumber !==
                                    undefined
                                    ? this.props.state.form2.passportNumber
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "Passport Number",
                                  this.props.state.form2.passportNumber,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 px-2 ">
                            <div className="form-group custom">
                              <Input
                                label="Place Of Issue"
                                type="text"
                                name="placeOfIssue"
                                imp="true"
                                value={
                                  this.props.state.form2.placeOfIssue !==
                                    undefined
                                    ? this.props.state.form2.placeOfIssue
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "Place Of Issue",
                                  this.props.state.form2.placeOfIssue,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <label>Country Of Issue <span className="text-danger">*</span></label>

                              <ReactSelect
                                value={{
                                  label: this.props.state.form2.countryOfIssue
                                  , value: this.props.state.form2.countryOfIssue
                                }}
                                onChange={e =>
                                  this.props.selectHandlerForm2(e)}
                                options={countriesIssueList}
                                isSearchable={true}
                              />
                              <div className="text-danger" style={{ fontSize: "12px" }}>
                                {this.props.validator2.message(
                                  "countryOfIssue",
                                  this.props.state.form2.countryOfIssue,
                                  "required"
                                )}
                              </div>





                              {/* <div className="dropdown">
                                <Select
                                  placeholder={
                                    this.props.state.form2.countryOfIssue
                                      ? this.props.state.form2.countryOfIssue
                                      : "Select"
                                  }
                                  keyName={"countryOfIssue"}
                                  options={this.props.state.countriesIssueList}
                                  controlFunc={e =>
                                    this.props.selectHandlerForm2(e)
                                  }
                                  error={this.props.validator2.message(
                                    "countryOfIssue",
                                    this.props.state.form2.countryOfIssue,
                                    "required"
                                  )}
                                />
                              </div> */}
                            </div>
                          </div>
                          <div className="col px-2">
                            <div className="form-group custom">
                              <Input
                                label="Issue Authority"
                                type="text"
                                imp="true"
                                name="issueAuthority"
                                className="form-control"
                                value={
                                  this.props.state.form2.issueAuthority !==
                                    undefined
                                    ? this.props.state.form2.issueAuthority
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "issueAuthority",
                                  this.props.state.form2.issueAuthority,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <label>Date of Issue <span className="text-danger">*</span></label>
                              <DayPickerInput
                                placeholder="DD-MMM-YYYY"
                                dayPickerProps={{ disabledDays: { after: new Date() } }}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                format="LL"
                                onDayChange={date =>
                                  this.props.handleDayChange(
                                    date,
                                    "form2",
                                    "dateOfIssue"
                                  )
                                }
                                value={
                                  this.props.state.form2.dateOfIssue
                                    ? `${formatDate(
                                      new Date(this.props.state.form2.dateOfIssue),
                                      "LL",
                                      "it"
                                    )}`
                                    : ""
                                }
                              />
                              <span className="icon-custom">
                                <i className="material-icons">calendar_today</i>
                              </span>
                              <div className="text-danger" style={{ fontSize: "12px" }}>
                                {this.props.validator2.message(
                                  "dateOfIssue",
                                  this.props.state.form2.dateOfIssue,
                                  "required"
                                )}
                              </div>
                            </div>

                          </div>
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <label>Expiry Date <span className="text-danger">*</span></label>
                              <DayPickerInput
                                placeholder="DD-MMM-YYYY"
                                dayPickerProps={{ disabledDays: { before: new Date() } }}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                format="LL"
                                onDayChange={date =>
                                  this.props.handleDayChange(
                                    date,
                                    "form2",
                                    "expiryDate"
                                  )
                                }
                                value={
                                  this.props.state.form2.expiryDate
                                    ? `${formatDate(
                                      new Date(this.props.state.form2.expiryDate),
                                      "LL",
                                      "it"
                                    )}`
                                    : ""
                                }
                              />

                              <span className="icon-custom">
                                <i className="material-icons">calendar_today</i>
                              </span>
                              <div className="text-danger" style={{ fontSize: "12px" }}>
                                {this.props.validator2.message(
                                  "expiryDate",
                                  this.props.state.form2.expiryDate,
                                  "required"
                                )}
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="bg-light photocopy py-3 px-3">
                          <p className="mb-0">Passport Photocopy <span className="text-danger">*</span></p>
                        </div>
                        <div className="upload-input mb-3 text-center">
                          <input
                            type="file"
                            onChange={(e)=>this.props.uploadImageHandler(e)}
                            
                          />
                          {(this.props.state.isLoading === true)?<Loader />:null}
                          <div className="py-4 check-box">
                          <i className="material-icons">cloud_upload</i>
                            <div>Drag and Drop to Upload</div>
                          </div>
                          <small style={{ color: "red" }}>
                            {this.props.validator2.message(
                              "Passport Photocopy",
                              this.props.state.images[0],
                              "required"
                            )}
                          </small>
                        </div>
                        {this.props.state.images.map((data, index) => (
                          <img
                            src={data}
                            width="100"
                            height="100"
                            key={index}
                            className="m-3"
                            data-target="#hotelReceiptModal"
                            data-toggle="modal"
                            onClick={() =>
                              this.props.selectedImageHandler(
                                data,
                                index,
                                "images"
                              )
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="modal-last btn-group-margin">
                    <button type="submit" className="btn">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn close"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="addVisaModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addVisaLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">New</h6>
                <h5 className="modal-title" id="addVisaLabel">
                  Add Visa
                </h5>
              </div>
              <form onSubmit={e => this.props.addEditForm3(e)}>
                <div className="modal-middle pm-30">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="row">
                          <div className="col px-2">
                            <div className="form-group custom">
                              <Input
                                label="Visa Number"
                                type="text"
                                name="visaNumber"
                                imp="true"
                                error={this.props.validator3.message(
                                  "Visa Number",
                                  this.props.state.form3.visaNumber,
                                  "required"
                                )}
                                value={
                                  this.props.state.form3.visaNumber != undefined
                                    ? this.props.state.form3.visaNumber
                                    : ""
                                }
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm3(e, "visa")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 px-2 ">
                            <div className="form-group custom">
                              <Input
                                label="Place Of Issue"
                                type="text"
                                name="placeOfIssue"
                                imp="true"
                                className="form-control"
                                error={this.props.validator3.message(
                                  "Place Of Issue",
                                  this.props.state.form3.placeOfIssue,
                                  "required"
                                )}
                                value={
                                  this.props.state.form3.placeOfIssue
                                    ? this.props.state.form3.placeOfIssue
                                    : ""
                                }
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm3(e, "visa")
                                }
                              />
                              {/* <div className="dropdown">
                                                        <Select
                                                          placeholder={this.props.state.form3.placeOfIssue?this.props.state.form3.placeOfIssue:"Select"}
                                                          keyName={"placeOfIssue"}
                                                          options={this.props.state.placeIssueList}
                                                          controlFunc={e =>this.props.selectHandlerForm3(e)}
                                                          error={this.props.validator3.message(
                                                            "Place Of Issue",
                                                            this.props.state.form3.placeOfIssue,
                                                            "required"
                                                          )}
                                                         />
                                                        </div> */}
                            </div>
                          </div>
                          <div className="col-md-12 px-2">
                            <div className="form-group custom labelup">
                              <label>Country Of Issue <span class="text-danger">*</span></label>


                              <ReactSelect
                                value={{
                                  label: this.props.state.form3.countryOfIssue
                                  , value: this.props.state.form3.countryOfIssue
                                }}
                                onChange={e =>
                                  this.props.selectHandlerForm3(e)}
                                options={countriesIssueList}
                                isSearchable={true}
                              />
                              <div className="text-danger" style={{ fontSize: "12px" }}>
                                {this.props.validator3.message(
                                  "countryOfIssue",
                                  this.props.state.form3.countryOfIssue,
                                  "required"
                                )}
                              </div>




                              {/* 
                              <div className="dropdown">
                                <Select
                                  placeholder={
                                    this.props.state.form3.countryOfIssue
                                      ? this.props.state.form3.countryOfIssue
                                      : "Country Of Issue"
                                  }
                                  keyName="countryOfIssue"
                                  options={this.props.state.countriesIssueList.length != 0 && this.props.state.countriesIssueList}
                                  controlFunc={e =>
                                    this.props.selectHandlerForm3(e)
                                  }
                                  error={this.props.validator3.message(
                                    "countryOfIssue",
                                    this.props.state.form3.countryOfIssue,
                                    "required"
                                  )}
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <label>Date of Issue <span class="text-danger">*</span></label>
                              <DayPickerInput
                                placeholder="DD-MMM-YYYY"
                                dayPickerProps={{ disabledDays: { after: new Date() } }}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                format="LL"
                                onDayChange={date =>
                                  this.props.handleDayChange(
                                    date,
                                    "form3",
                                    "dateOfIssue"
                                  )
                                }
                                value={
                                  this.props.state.form3.dateOfIssue
                                    ? `${formatDate(
                                      new Date(this.props.state.form3.dateOfIssue),
                                      "LL",
                                      "it"
                                    )}`
                                    : ""
                                }
                              />
                              <span className="icon-custom">
                                <i className="material-icons">calendar_today</i>
                              </span>
                              <div className="text-danger" style={{ fontSize: "12px" }}>
                                {this.props.validator3.message(
                                  "dateOfIssue",
                                  this.props.state.form3.dateOfIssue,
                                  "required"
                                )}
                              </div>
                            </div>
                            
                          </div>
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <label>Expiry Date <span class="text-danger">*</span></label>
                              <DayPickerInput
                                placeholder="DD-MMM-YYYY"
                                dayPickerProps={{ disabledDays: { before: new Date() } }}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                format="LL"
                                onDayChange={date =>
                                  this.props.handleDayChange(
                                    date,
                                    "form3",
                                    "expiryDate"
                                  )
                                }
                                value={
                                  this.props.state.form3.expiryDate
                                    ? `${formatDate(
                                      new Date(this.props.state.form3.expiryDate),
                                      "LL",
                                      "it"
                                    )}`
                                    : ""
                                }
                              />
                              <span className="icon-custom">
                                <i className="material-icons">calendar_today</i>
                              </span>
                              <div className="text-danger" style={{ fontSize: "12px" }}>
                                {this.props.validator3.message(
                                  "expiryDate",
                                  this.props.state.form3.expiryDate,
                                  "required"
                                )}
                              </div>
                            </div>
                           
                          </div>
                        </div>
                        <div className="row">
                          <div className="col px-2">
                            <div className="form-group custom labelup">
                              <label>Issued For <span class="text-danger">*</span></label>
                              <ReactSelect
                                value={{
                                  label: this.props.state.form3.issuedFor
                                  , value: this.props.state.form3.issuedFor
                                }}
                                onChange={e =>
                                  this.props.selectHandlerForm3(e)}
                                options={countriesList}
                                isSearchable={true}
                              />
                              <div className="text-danger" style={{ fontSize: "12px" }}>
                                {this.props.validator3.message(
                                  "issuedFor",
                                  this.props.state.form3.issuedFor,
                                  "required"
                                )}
                              </div>



                              {/* <Select
                                placeholder={
                                  this.props.state.form3.issuedFor
                                    ? this.props.state.form3.issuedFor
                                    : "Issued for (Country)"
                                }
                                keyName="issuedFor"
                                options={this.props.state.countriesList.length != 0 && this.props.state.countriesList}
                                controlFunc={e =>
                                  this.props.selectHandlerForm3(e)
                                }
                                error={this.props.validator3.message(
                                  "issuedFor",
                                  this.props.state.form3.issuedFor,
                                  "required"
                                )}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="bg-light photocopy py-3 px-3">
                          <p className="mb-0">Visa Photocopy <span class="text-danger">*</span></p>
                        </div>
                        <div className="upload-input mb-3 text-center">
                          <input
                            type="file"
                            onChange={(e)=>this.props.uploadImageHandler2(e)}
                          />
                           {(this.props.state.isLoading === true)?<Loader />:null}
                          <div className="py-4 check-box">
                            <i className="material-icons">cloud_upload</i>
                            <div>Drag and Drop to Upload</div>
                          </div>
                          <p className="text-danger">
                            {this.props.validator3.message(
                              "Visa Photocopy",
                              this.props.state.images2[0],
                              "required"
                            )}
                          </p>
                        </div>
                        {this.props.state.images2.map((data, index) => (
                          <img
                            src={data}
                            width="100"
                            height="100"
                            key={index}
                            className="m-3"
                            data-target="#hotelReceiptModal"
                            data-toggle="modal"
                            onClick={() =>
                              this.props.selectedImageHandler(
                                data,
                                index,
                                "images2"
                              )
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="modal-last btn-group-margin">
                    <button type="submit" className="btn">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn close"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="hotelReceiptModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="hotelReceiptModal"
          aria-hidden="true"
          style={{ zIndex: 10001 }}
          // style={{ zIndex: 1055 }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-head" />
              <div className="modal-middle pm-30">
                <div className="">
                  <div className="">
                    <div className="main-receipt">
                      <button
                        type="button"
                        onClick={() => this.props.removePhotoCopy()}
                        data-dismiss="modal"
                        className="btn mb-3"
                      >
                        <i className="material-icons">delete</i>
                      </button>
                      <img
                        src={this.props.state.selectedImage}
                        className="img-fluid w-100"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-last pm-30 pt-0">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn close"
                >
                  close
                    </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="addIDModal"
          tabIndex="-10"
          role="dialog"
          aria-labelledby="addIDLable"
          aria-hidden="true"
          // style={{ zIndex: 1050 }}
        >
          <div className="modal-dialog  modal-lg modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <button className="close d-none" data-dismiss="modal"></button>
                <h5 className="modal-title" id="addIDLable">
                  Add ID
                </h5>
              </div>
              <form onSubmit={e => this.props.addEditForm4(e)}>
                <div className="modal-middle pm-30">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="form-group custom labelup">
                        <label> Id Name <span class="text-danger">*</span> </label>
                        {console.log(this.props.state.form4)}
                        <ReactSelect
                          value={
                            this.props.state.form4.idName
                              ? { value: this.props.state.form4.idName, label: this.props.state.form4.idName }
                              : "Select"
                          }
                          isSearchable={true}
                          onChange={(e) => this.props.idTypeChange(e, "idName")}
                          options={
                            [
                              { value: 'Aadhar Card', label: 'Aadhar Card' },
                              { value: 'PAN Card', label: 'PAN Card' },
                              { value: 'Driving License', label: 'Driving License' },
                              { value: 'Birth Certificate', label: 'Birth Certificate' }
                            ]
                          }
                        />
                        <p class="help-block">{this.props.validator4.message(
                          "id Name",
                          this.props.state.form4.idName,
                          "required"
                        )}</p>


                      </div>
                      <div className="form-group custom ">
                        <Input
                          label="Name on ID"
                          type="text"
                          name="nameOfId"
                          className="form-control"
                          imp="true"
                          error={this.props.validator4.message(
                            "Name on id",
                            this.props.state.form4.nameOfId,
                            "required"
                          )}
                          value={
                            this.props.state.form4.nameOfId
                              ? this.props.state.form4.nameOfId
                              : ""
                          }
                          controlFunc={e =>
                            this.props.inputChangeHandlerForm4(e, "otherId")
                          }
                        />
                      </div>
                      <div className="form-group custom">
                        <Input
                          label="Issuer"
                          type="text"
                          name="issuer"
                          imp="true"
                          className="form-control"
                          error={this.props.validator4.message(
                            "Issuer",
                            this.props.state.form4.issuer,
                            "required"
                          )}
                          value={
                            this.props.state.form4.issuer
                              ? this.props.state.form4.issuer
                              : ""
                          }
                          controlFunc={e =>
                            this.props.inputChangeHandlerForm4(e, "otherId")
                          }
                        />
                      </div>

                      <div className="form-group custom labelup">
                        <label>Valid Till <span class="text-danger">*</span></label>
                        <DayPickerInput
                          placeholder="DD-MMM-YYYY"
                          dayPickerProps={{ disabledDays: { before: new Date() } }}
                          formatDate={formatDate}
                          parseDate={parseDate}
                          format="LL"
                          onDayChange={date =>
                            this.props.handleDayChange(
                              date,
                              "form4",
                              "validTill"
                            )
                          }
                          value={
                            this.props.state.form4.validTill
                              ? `${formatDate(
                                new Date(this.props.state.form4.validTill),
                                "LL",
                                "it"
                              )}`
                              : ""
                          }
                          error={this.props.validator4.message(
                            "Expiring Date",
                            this.props.state.form4.validTill,
                            "required"
                          )}
                        />
                        <span className="icon-custom">
                          <i className="material-icons">calendar_today</i>
                        </span>
                        <div className="help-block">{this.props.validator4.message(
                          "Expiring Date",
                          this.props.state.form4.validTill,
                          "required"
                        )}</div>
                      </div>

                      <div className="form-group custom">
                        <Input
                          label="ID Number"
                          type="text"
                          name="idNumber"
                          imp="true"
                          className="form-control"
                          error={this.props.validator4.message(
                            "id Number",
                            this.props.state.form4.idNumber,
                            "required"
                          )}
                          value={
                            this.props.state.form4.idNumber
                              ? this.props.state.form4.idNumber
                              : ""
                          }
                          controlFunc={e =>
                            this.props.inputChangeHandlerForm4(e, "otherId")
                          }
                        />

                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="bg-light photocopy py-3 px-3">
                        <p className="mb-0"> ID Photocopy <span className="text-danger">*</span></p>
                      </div>
                      <div className="upload-input mb-3 text-center">
                        <input
                          type="file"
                          onChange={(e) => this.props.uploadImageHandler3(e)}
                        />
                        {(this.props.state.isLoading === true)?<Loader />:null}
                        <div className="py-4 check-box">
                          <i className="material-icons">cloud_upload</i>
                          <div>Drag and Drop to Upload</div>
                        </div>
                        <p className="text-danger fs-12">
                          {this.props.validator4.message(
                            "",
                            this.props.state.images3[0],
                            "required"
                          )}
                        </p>
                      </div>
                      {this.props.state.images3.map((data, index) => (
                        <img
                          src={data}
                          width="100"
                          height="100"
                          key={index}
                          className="m-3"
                          data-target="#hotelReceiptModal"
                          data-toggle="modal"
                          onClick={() =>
                            this.props.selectedImageHandler(
                              data,
                              index,
                              "images3"
                            )
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <div className="modal-last btn-group-margin">
                    <button type="button" className="btn" data-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="PassportModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="PassportLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-xl modal-dialog-centered"
            role="document"
          >
            <div className="modal-content border-0">
              <div className="modal-head">
                <h5 className="modal-title" id="PassportLabel">
                  Photos
              </h5>
              </div>
              <div className="modal-middle pm-30 bg-light">
                <div className="row">
                  <div className="col-md-7">
                    <ul className="expense-reports receipts modal-receipt">

                      <li className="">
                        <div className="abs">
                          <i className="material-icons">
                            check_circle
                         </i>
                        </div>

                        <button type="button">
                          <div className="image" style={{ backgroundImage: 'url(/assets/images/booking/bill.jpg)' }}>
                          </div>
                        </button>
                      </li>
                      <li className="">
                        <div className="abs">
                          <i className="material-icons">
                            check_circle
                         </i>
                        </div>

                        <button type="button">
                          <div className="image" style={{ backgroundImage: 'url(/assets/images/booking/bill.jpg)' }}>
                          </div>
                        </button>
                      </li>

                    </ul>
                  </div>

                  <div className="col-md-5">
                    <div className="expense-preview">
                      <h6 className="sub-heading">Preview</h6>
                      <img
                        src="/assets/images/booking/bill.jpg"
                        className="img-fluid"
                        alt=""
                      />
                      <button type="button" data-dismiss="modal" className="btn mr-auto delete-button">
                        <i className="material-icons">delete</i>
                      </button>
                    </div>
                  </div>

                </div>
                <div className="btn-group-margin mt-4">
                  <button className="btn" type="button" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}
export default TravelSettingsPage;
