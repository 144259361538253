import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class ExpensePoliciesMenu extends Component {
    render() {
        return (
            <div>
                <div className="audit-step">
                    <NavLink className="s1" to="/administration/expense/policy-step1">
                        <span>1</span>General</NavLink>
                    {/* <NavLink className="s1" to="/administration/expense/policy-step2">
                        <span>2</span>Expense Types for Policy</NavLink> */}
                </div>
            </div>
        )
    }
}
export default ExpensePoliciesMenu