import React, { Component } from "react";
import { Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ReportsPage from "./reports";
import ReceiptsPage from "./receipts";
import CardTransactionPage from "./card-transactions";
import ExpenseDetailsPage from "./expense-details";
import NewExpenseReportPage from "./expense-report";
import ExpenseApprovalDetails from "./expenseApprovalDetails";
import AddExpenseTypePage from "./addexpense-type";
import ExpenseAllocationPage from "./expense-allocation";
import AddMilagePage from "./add-mileage";
// import ProfileSettingsPage from "./profile-settings";
// import ExpenseSettingsPage from "./expense-settings";
// import TravelSettingsPage from "./travel-settings";
// import EditUserProfilePage from "./edit-profile";
import CreateItemisation from "./createItemisation";
import EditExpenseTypeForm from "./editExpenseEntryForm";
import EditReportHeader from "./editReportHeader";
import EditItemisation from "./editItemisation";
import FinanceAdminApproval from "./financeAdminApproval";
import CreateReportsPage from "./create-report";


class ExpensePage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/expense") {
      this.props.history.push("/expense/home");
    } else {
      this.props.history.push(props.location.pathname);
    }
    this.state = {
      isOpen: true,
      notPopup: true
    };
    this.handleclick = this.handleclick.bind(this);
    this.handleclick2 = this.handleclick2.bind(this);
  }
  handleclick() {
    this.setState({
      isOpen: !this.state.isOpen,
      notPopup: true
    });
  }
  handleclick2() {
    this.setState({
      notPopup: !this.state.notPopup,
      isOpen: true
    });
  }
  render() {
    const activeNav = this.props.location.pathname.split("/")[2];
    return (
      <div className="BookingPage bg-light-blue">
        <div className="page-content">
          <Route
            path="/expense/edit-expense-entry"
            exact
            component={EditExpenseTypeForm}
          />
          <Route
            path="/expense/edit-expense-entry/:id"
            exact
            component={EditExpenseTypeForm}
          />
          <Route
            path="/expense/edit-report-header"
            component={EditReportHeader}
          />

          <Route
            path="/expense/edit-report-header/:id"
            exact
            component={EditReportHeader}
          />

          <Route path="/expense/home" exact component={ReportsPage} />
          <Route path="/expense/list" exact component={CreateReportsPage} />
          <Route path="/expense/receipts" component={ReceiptsPage} />
          <Route
            path="/expense/card-transactions"
            component={CardTransactionPage}
          />
          <Route
            path="/expense/details/:workflowId"
            exact
            component={ExpenseDetailsPage}
          />
          <Route path="/expense/details" exact component={ExpenseDetailsPage} />
          <Route
            path="/expense/details/approval/:id"
            exact
            component={ExpenseApprovalDetails}
          />
          <Route
            path="/expense/details/fincapproval/:id"
            exact
            component={FinanceAdminApproval}
          />
          <Route
            path="/expense/create-itemisation"
            component={CreateItemisation}
          />
          <Route path="/expense/reports" component={NewExpenseReportPage} />
          <Route path="/expense/types" component={AddExpenseTypePage} />
          <Route path="/expense/allocation" component={ExpenseAllocationPage} />
          <Route
            path="/expense/edit-itemisation"
            exact
            component={EditItemisation}
          />
          <Route
            path="/expense/edit-itemisation/:id"
            exact
            component={EditItemisation}
          />
          <Route path="/expense/add-mileage" exact component={AddMilagePage} />
          <Route
            path="/expense/add-mileage/:expenseId"
            exact
            component={AddMilagePage}
          />

          {/* <Route
            path="/expense/profile-settings"
            component={ProfileSettingsPage}
          />
          <Route
            path="/expense/expense-settings"
            component={ExpenseSettingsPage}
          />
          <Route
            path="/expense/travel-settings"
            component={TravelSettingsPage}
          />
          <Route path="/expense/edit-profile" component={EditUserProfilePage} /> */}
        </div>
      </div>
    );
  }
}
export default ExpensePage;
