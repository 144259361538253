import React, { Component } from 'react';
import Moment from 'react-moment'
import { getValue } from 'src/_components/common/lodsh';


class WorkFlowApprovalSteps extends Component {
  render() {
    return (
      <div className="modal fade" id="approvalFlowModal"
        tabIndex="-1" role="dialog" aria-labelledby="approvalFlowLable" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content  border-0">
            <div className="modal-head">
              <h6 className="modal-subtitle">New</h6>
              <h5 className="modal-title" id="approvalFlowLable">
                Approval Flow
                  </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="white-card">
                {this.props.approvalList.length > 0 ? this.props.approvalList.map((approvals) => {
                  let firstName, middleName, lastname, fullName;
                  firstName = !approvals.userDetails?"":approvals.userDetails.fieldData.find((userDetails) => userDetails.columnName === "EMPLOYEE_FIRST_NAME");
                  middleName = !approvals.userDetails?"":approvals.userDetails.fieldData.find((userDetails) => userDetails.columnName === "MIDDLE_NAME");
                  lastname = !approvals.userDetails?"":approvals.userDetails.fieldData.find((userDetails) => userDetails.columnName === "EMPLOYEE_LAST_NAME");
                  fullName = firstName && middleName && middleName && firstName.fieldValue + ' ' + middleName.fieldValue + ' ' + lastname.fieldValue;

                  return (
                    <div key={approvals.workflowStep_id} className="approver with-white-space-100 px-0">
                      {approvals.APPROVED ?
                        <div className="right-icon approved">
                          <i className="material-icons">check_circle</i>
                        </div>
                        : (approvals.REJECTED) ?
                          <div className="right-icon approved">
                            <i className="material-icons text-danger fs-20">thumb_down</i>
                          </div>
                          : <div className="right-icon waiting">
                            <i className="material-icons">
                              hourglass_empty
                              </i>
                          </div>
                      }
                      <div className="shape-name">
                        {approvals.userDetails && approvals.userDetails.profileImage
                          ?
                          <div className="left img"
                            style={{ backgroundImage: `url(${approvals.userDetails.profileImage})` }}
                          />
                          :
                          <div className="left icon">
                            <i className="material-icons fs-20">person</i>
                          </div>
                        }
                        <div className="right">
                          <p className="label">Level {+approvals.orderNo + 1}</p>
                          <p className="title">
                            {fullName && fullName}
                          </p>
                               <p >Email : {approvals.userDetails.email}</p>
                               {approvals.submittedAt?<p>Submitted on : {approvals.submittedAt?<Moment format='DD MMM YYYY, HH:mm'>{approvals.submittedAt}</Moment>:"     -"} </p>:""}
                              {approvals.approvedAt?<p>Approved on :{approvals.approvedAt?<Moment format='DD MMM YYYY, HH:mm'>{approvals.approvedAt}</Moment>:"  Not Yet Approved"} </p>:""}
                              <b>{getValue(approvals,"RESPONSE.comment","")}</b>
                        </div>
                        <div className="clearfix" />
                      </div>
                    </div>
                  )
                })
                  :
                  <div className="text-center">
                    Approval flow will be updated after submission
                      </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WorkFlowApprovalSteps;
