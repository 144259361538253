import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import FormsFields from "./forms-fields/forms-fields";
import ModifyFormsFields2Page from "./modify-form-fields2/modify-form-fields2";

class FormsFeildsMainPage extends Component {
  constructor(props) {
    super(props);
    if (
      props.location.pathname ===
      "/administration/company/admin-forms/forms-fields"
    ) {
      this.props.history.push(
        "/administration/company/admin-forms/forms-fields/list"
      );
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (
      this.props.location.pathname ===
      "/administration/company/admin-forms/forms-fields"
    ) {
      this.props.history.push(
        "/administration/company/admin-forms/forms-fields"
      );
    }
  }
  render() {
    return (
      <div className="FFMainPage">
        <Switch>
          <Route
            path="/administration/company/admin-forms/forms-fields/list"
            component={FormsFields}
          />
          <Route
            path="/administration/company/admin-forms/forms-fields/modify-form-fields/:id"
            component={ModifyFormsFields2Page}
          />
        </Switch>
      </div>
    );
  }
}
export default FormsFeildsMainPage;
