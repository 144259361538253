import React from 'react';
import ReactSelect from 'react-select';
const differentFieldList = (props) => {
    return (
        props.fieldDetails.columnName === "PAYMENT_TYPE" ?
            (<ReactSelect options={props.fieldDetails && props.fieldDetails.paymentDetails.map((list) => ({ label: list.description, value: list.id }))}
                value={props.selectedFieldValue}
                onChange={(e) => props.onChangeDifferentListHandler(e, props.expressionId)}
            />) : props.fieldDetails.columnName === "CTRY_CODE" ?
                (<ReactSelect options={props.fieldDetails && props.fieldDetails.countries.map((list) => ({ label: list.name, value: list.id, columnName: props.fieldDetails.columnName }))}
                    value={props.selectedFieldValue}
                    onChange={(e) => props.onChangeDifferentListHandler(e, props.expressionId)}
                />) : props.fieldDetails.columnName === "LEDGER_KEY" ?
                    <ReactSelect options={props.fieldDetails && props.fieldDetails.accountingLedger.map((list) => ({ label: list.ledgerName, value: list.id, columnName: props.fieldDetails.columnName }))}
                        value={props.selectedFieldValue}
                        onChange={(e) => props.onChangeDifferentListHandler(e, props.expressionId)}
                    />
                    : ''

    )
}
export default differentFieldList;