import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import FieldsPage from "./fields/fields";
import CreateFormsFieldsPage from "./create-form-fields/create-form-fields";
import ModifyFormsFields2Page from "./modify-form-fields2/modify-form-fields2";
class FeildsMainPage extends Component {
  constructor(props) {
    super(props);
    if (
      props.location.pathname === "/administration/company/admin-forms/fields"
    ) {
      this.props.history.push(
        "/administration/company/admin-forms/fields/list"
      );
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (
      this.props.location.pathname ===
      "/administration/company/admin-forms/fields"
    ) {
      this.props.history.push("/administration/company/admin-forms/fields");
    }
  }
  render() {
    return (
      <div className="FFMainPage">
        <Switch>
          <Route
            path="/administration/company/admin-forms/fields/list"
            component={FieldsPage}
          />
          <Route
            path="/administration/company/admin-forms/fields/create-form-fields/:id"
            component={CreateFormsFieldsPage}
          />
          <Route
            path="/administration/company/admin-forms/forms-fields/modify-form-fields/:id"
            component={ModifyFormsFields2Page}
          />
        </Switch>
      </div>
    );
  }
}
export default FeildsMainPage;
