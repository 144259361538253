import { config } from "../env";
import { http } from "../_helpers";

//------------Approvers ------------------

const getApproverList = (companyId, limit, page) => {
    if (companyId === undefined || limit === undefined || page === undefined) {
        return http
            .get(
                config.apiExpense +
                `approvers?companyId=${companyId}`
            )
            .then(data => data);
    } else {
        return http
            .get(
                config.apiExpense +
                `approvers?companyId=${companyId}&limit=${limit}&page=${page}`
            )
            .then(data => data);
    }
};

const searchApproverList = (searchKeyword, companyId, limit, page) => {
    return http
        .get(
            config.apiExpense +
            `approvers/${searchKeyword}?companyId=${companyId}&limit=${limit}&page=${page}`
        )
        .then(data => data);
};

const deleteApprover = (companyId, data) => {
    return http
        .patch(
            config.apiExpense + `approvers/delete?companyId=${companyId}`,
            data
        )
        .then(data => data);
};

const modifyApprover = data => {
    return http
        .patch(config.apiExpense + `approvers`, data)
        .then(data => data);
};

const createApprover = data => {
    return http
        .post(config.apiExpense + `approvers`, data)
        .then(data => data);
};

const changeApprover = (companyId, searchUser) => {
    let url = `${config.apiUrlCompany}company-users/${searchUser}?limit=10&companyId=${companyId}`;
    return http.get(url).then(res => res);
}


const getSingleApprover = (approverId, companyId) => {
    return http
        .get(
            config.apiExpense + `approvers/details/${approverId}?companyId=${companyId}`
        )
        .then(data => data);
};

// ---------------------------------------------------------------------------------------

export const approverService = {
    getApproverList,
    searchApproverList,
    deleteApprover,
    modifyApprover,
    createApprover,
    changeApprover,
    getSingleApprover

};
