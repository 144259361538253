import React, { Component } from "react";
import TravelAdminPage from "./travel-admin-html";
import SimpleReactValidator from 'simple-react-validator';
import { travelAdminService } from "src/_services/travelAdmin.service";
import { toast } from "react-toastify";
import { alertConstants } from "src/_constants";

class TravelAdmin extends Component {

  state = {
    timeOptions: [
      {label: '9:00 AM', value: '09:00'},
      {label: '10:00 AM', value: '10:00'} ,
      {label: '11:00 AM', value: '11:00'},
      {label: '12:00 PM', value: '12:00'},

    ],
    timePresets: [
      {label: 3},
      {label: 4},
      {label: 5},
      {label: 6}
    ],
    domesticVariance: '',
    internationalVariance: '',
    employeeMinVariance: '',
    employeeMaxVariance: '',
    presetsActiveStatus: false,
    departureTime: '',
    returnTime: '',
    isEdit: false


  }

  validator = new SimpleReactValidator();

  componentDidMount(){
    this.getTravelAdminDetails();
  }

  getTravelAdminDetails = async () => {
    try{
      let res = await travelAdminService.getTravelAdminDetails()
      if(typeof res != undefined && res.result){
        this.setState({
          domesticVariance: this.state.timePresets.find(item => item.label == res.result?.domesticVariance),
          internationalVariance: this.state.timePresets.find(item => item.label == res.result?.internationalVariance),
          employeeMinVariance: this.state.timePresets.find(item => item.label == res.result.employeeMinVariance),
          employeeMaxVariance: this.state.timePresets.find(item => item.label == res.result.employeeMaxVariance),
          presetsActiveStatus: res.result.presetsActiveStatus,
          departureTime: this.state.timeOptions.find(item => item.value == res.result?.departureTime),
          returnTime: this.state.timeOptions.find(item => item.value == res.result?.returnTime),
          isEdit: true

        })
      }else{
        this.setState({
          isEdit: false
        })
      }
    }catch(err){

    }
  }
  handleTimeWindowClick = (e, type) => {

    this.setState({[type]: e})
  }

  handleSliderToggle = (e) => {
    this.setState({presetsActiveStatus: e.target.checked});
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      departureTime: this.state.departureTime.value,
      returnTime: this.state.departureTime.value,
      domesticVariance: this.state.domesticVariance.label,
      internationalVariance: this.state.internationalVariance.label,
      employeeMinVariance: this.state.employeeMinVariance.label,
      employeeMaxVariance: this.state.employeeMaxVariance.label,
      presetsActiveStatus: this.state.presetsActiveStatus
    }

    if(this.validator.allValid()){
      let res = '';
      if(this.state.isEdit){
        res = await travelAdminService.updateTravelDetails(payload);
      }else{
        res = await travelAdminService.saveTravelAdminDetails(payload);
      }

      if(res){
        toast.success(res.message);
      }


    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }

  }





  render() {
    return (
      <div>
        <TravelAdminPage
        {...this.state}
        OnTimeWindowClick = {this.handleTimeWindowClick}
        validator={this.validator}
        onSubmit={this.handleSubmit}
        onSliderToggle={this.handleSliderToggle}
        />
      </div>
    );
  }
}
export default TravelAdmin;
