import React from 'react'

export default function SendBackToEmployeeModal() {
  return (
    <div>
      <div
        className="modal fade"
        id="SendBacktoEmployeeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="SendBacktoEmployeeLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="ChangeApproverLabel">
                Edit Action
                  <button
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom labelup">
                <label>Action Name</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    Send Back to Employee
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">Change Approver</button>
                    <button type="button" className="dropdown-item">Generate Exception</button>
                    <button className="dropdown-item disabled">Reject (cash advance workflows only)</button>
                    <button className="dropdown-item">Send Back to Employee</button>
                    <button className="dropdown-item">Send Email</button>
                    <button className="dropdown-item ">Skip Step</button>
                  </div>
                </div>
              </div>
              <div className="form-group custom">
                <input type="text" className="form-control" placeholder="&nbsp;" />
                <label>Message</label>
              </div>
              <div className=" modal-last btn-group-margin">
                <button
                  type="button"
                  className="btn btn-border-success"
                >
                  Save
              </button>
                <button
                  type="button"
                  className="btn btn-border-basic"
                  data-dismiss="modal"
                >
                  Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}
