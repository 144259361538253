import React, { Component } from 'react'; 
import $ from 'jquery';
import TreeNode from './TreeNode'; 
class SideMenu extends Component {

    openDropdown(e) {
        $(e.currentTarget).toggleClass("active");
    }
    render() {
        const { menuItems } = this.props  
        return (
            <div id="adminmenu" className="adminmenu">
                <ul id="admin-accordion" className="nav-overflow">
                    {menuItems.children &&
                        menuItems.children.map((list, key) => {
                            return (
                                <TreeNode
                                    key={key}
                                    data={list}
                                    location={this.props.location}
                                />
                            )
                        })
                    } 
                </ul>
            </div >
        )
    }
}
export default SideMenu