import { http } from '../_helpers';
import { config } from '../env';



const getCommonData = (data) => {
  return http.get(config.apiMidOffice + `trips/history?bookingType=${data.bookingType}&search=${data.search}&page=${data.page}&limit=${data.limit}&companyId=${data.companyId ? data.companyId : ''}`)
    .then(data => {
      return data ? data : "";
    });
};
const getClientList = () => {
  let url = `${config.apiMidOffice}all-clients`;
  return http.get(url)
    .then(res => res);
}
const getDownloadHistory = (data) => {
  let url = `${config.apiMidOffice}mid-office/hystery-excel?fromDate=${new Date(data.fromDate)}&toDate=${new Date(data.toDate)}&bookingType=${data.bookingType}&companyId=${data.companyId}`;
  return http.get(url)
    .then(res => res);
}
const getTripData = (id) => {
  let url = `${config.apiMidOffice}bookings/get-one-trip/${id}`;
  return http.get(url)
    .then(res => res);
}
const getPnrdata = (id) => {
  return http
    .get(config.apiMidOffice + `flight-pnr/get-pnr/${id}`)
    .then(data => {
      return data ? data : "";
    })
}
const getUserDetails = (id) => {
  let url = `${config.apiMidOffice}user/details?userId=${id}`;
  return http.get(url)
    .then(data => data);
}
const getChosenPassportVisa = (tripId, userId) => {
  let url = `${config.apiUrlTravel}travel-request/get-trip?userId=${userId}&tripId=${tripId}`;
  return http.get(url)
    .then(res => res)
}
const getFlightETicketnInvoice = (data) => {
  let url = `${config.apiMidOffice}journey/${data.type1}?tripId=${data.tripId}&journeyId=${data.journeyId}&bookingType=${data.type2}`;
  return http.get(url)
    .then(res => {
      return res
    })
}
const getETicket = (data) => {
  let url = `${config.apiMidOffice}journey/${data.type}?tripId=${data.tripId}&bookingType=${data.bookingType}&hotelId=${data.hotelId}`;
  return http.get(url)
    .then(res => {
      return res
    });
}

// const searchFlights = payload => {
//   let url = `${config.apiUrlTravel}flight/search`;
//   return http.post(url, payload)
//     .then(res => res)
// }


export const midOfficeHistoryService = {
  getCommonData,
  getClientList,
  getDownloadHistory,
  getTripData,
  getPnrdata,
  getUserDetails,
  getChosenPassportVisa,
  getFlightETicketnInvoice,
  getETicket
}