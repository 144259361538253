import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class FeedbackComponent extends Component {
    constructor(props) {
      super(props);
    }
    render() {
        return (
            <div>Feedback Page works</div>
        )
    }
}
export default FeedbackComponent;