import React from "react";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { intersection, isEqual } from 'lodash';
const EditPolicyStep1Page = props => {
  const {
    createPolicyData,
    formNameHandler,
    handleERF,
    handleEAF,
    handleEWF,
    handleEditableBy,
    handleAppliesTo,
    handleSC,
    missingReceiptAfidivate,
    createPolicy,
    editableByList,
    appliesToList,
    updatePolicy,
    handleCashAdvanceFormSelection,
    handleToggleChange

  } = props;

  console.log(":::;afidivateStatement ", createPolicyData);


  let expenseReportForms = [];
  if (createPolicyData && createPolicyData.expenseReportForms) {
    expenseReportForms = createPolicyData.expenseReportForms.map(el => {
      return { value: el.id, label: el.formName };
    });

  }
  let expenseAllocationForms = createPolicyData.expenseAllocationForms && createPolicyData.expenseAllocationForms.map(
    el => {
      return { value: el.id, label: el.formName };
    }
  );

  let workFlows = createPolicyData.workFlows && createPolicyData.workFlows.map(el => {
    return {
      value: el.workflow_id,
      label: el.workflowName
    };
  });
  let cashAdvanceForms = createPolicyData.cashAdvanceForms.map(el => {
    return {
      value: el.id,
      label: el.formName
    };
  });

  let editableByOptions = createPolicyData.editableByList && createPolicyData.editableByList.map(el => {
    return {
      value: el.id,
      label: el.itemName
    };
  });

  let appliesToOptions = createPolicyData.appliesToList && createPolicyData.appliesToList.map(el => {
    return {
      value: el.id,
      label: el.itemName
    };
  });
  // console.log("applies by options ", appliesToOptions);
  // console.log("getting during edit ", createPolicyData.appliesTo);



  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">R</div>
          <div className="right">
            <h6 className="heading">Edit Policy</h6>
            <p className="text">Edit required request policies.</p>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          {/* <ExpensePoliciesMenu2 /> */}
          <div className="">
            <form>
              <div className="row">
                <div className="col-xl-5 col-lg-6">
                  <div className="form-group custom">
                    <input
                      className="form-control"
                      placeholder="&nbsp;"
                      type="text"
                      name="policyName"
                      onChange={formNameHandler}
                      value={createPolicyData.policyName || ""}
                    />
                    <label>Name <span className="text-danger">*</span></label>
                  </div>

                  <div className="form-group custom labelup">
                    <label>Request Report Form <span className="text-danger">*</span></label>
                    <Select
                      value={expenseReportForms ? expenseReportForms.filter(item => item.value == createPolicyData.selectedExpenseReportForm) : ''}
                      options={expenseReportForms}
                      name="selectedExpenseReportForm"
                      onChange={handleERF}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup">
                    <label>Request Allocation Form <span className="text-danger">*</span></label>
                    <Select
                      value={expenseAllocationForms ? expenseAllocationForms.filter(item => item.value == createPolicyData.selectedExpenseAllocationForm) : ''}
                      options={expenseAllocationForms}
                      name="selectedExpenseAllocationForm"
                      onChange={handleEAF}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup">
                    <label>Request Report Workflow <span className="text-danger">*</span></label>
                    <Select
                      value={workFlows ? workFlows.filter(item => item.value == createPolicyData.selectedExpenseReportWorkFlow) : ''}
                      options={workFlows}
                      name="selectedExpenseReportWorkFlow"
                      onChange={handleEWF}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <div className="form-group custom labelup">
                    <label>Cash Advance Form <span className="text-danger">*</span></label>
                    <Select
                      value={cashAdvanceForms ? cashAdvanceForms.filter(item => item.value == createPolicyData.selectedCashAdvanceForm) : ''}
                      options={cashAdvanceForms}
                      name="selectedCashAdvanceForm"
                      onChange={handleCashAdvanceFormSelection}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup textarea">
                    <label>Editable By <span className="text-danger">*</span></label>
                    <Select
                      value={editableByOptions ? editableByOptions.filter(item => createPolicyData.editableBy.includes(item.value)) : ""}
                      options={editableByOptions}
                      isMulti
                      name="editableBy"
                      onChange={handleEditableBy}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <div className="form-group custom labelup textarea">
                    <label>Applies to <span className="text-danger">*</span></label>
                    <Select
                      value={appliesToOptions ? appliesToOptions.filter(item => createPolicyData.appliesTo.includes(item.value)) : ""}
                      options={appliesToOptions}
                      isMulti
                      name="appliesTo"
                      onChange={handleAppliesTo}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <h6 className="sub-heading">Travel Allowance</h6>
                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={e => handleToggleChange(e, 'travel')}
                        checked={createPolicyData.isTravelAllowance}
                        value={createPolicyData.isTravelAllowance}
                      />
                      <span className="slider round" />
                    </label>{" "}
                    <span>
                      {/* {createPolicyData.isTravelAllowance
                        ? "True"
                        : "False"} */}
                    </span>
                  </div>

                  <h6 className="sub-heading">Expense</h6>
                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={e => handleToggleChange(e, 'expense')}
                        checked={createPolicyData.isExpense}
                        value={createPolicyData.isExpense}
                      />
                      <span className="slider round" />
                    </label>{" "}
                    <span>
                      {/* {createPolicyData.isExpense
                        ? "True"
                        : "False"} */}
                    </span>
                  </div>

                  <h6 className="sub-heading">CashAdvance</h6>
                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={e => handleToggleChange(e, 'isCashAdvance')}
                        checked={createPolicyData.isCashAdvance}
                        value={createPolicyData.isCashAdvance}
                      />
                      <span className="slider round" />
                    </label>{" "}
                    <span>
                      {/* {createPolicyData.isCashAdvance
                        ? "Active"
                        : "Inactive"} */}
                    </span>
                  </div>

                  <h6 className="sub-heading">Is Violation</h6>
                  <div className="form-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={e => handleToggleChange(e, 'isViolation')}
                        checked={createPolicyData.isViolation}
                        value={createPolicyData.isViolation}
                      />
                      <span className="slider round" />
                    </label>{" "}
                    <span>
                      {/* {createPolicyData.isCashAdvance
                        ? "Active"
                        : "Inactive"} */}
                    </span>
                  </div>

                  <div className="btn-group-margin mt-4">
                    <button
                      type="button"
                      className="btn"
                      onClick={updatePolicy}>
                      update policy
                    </button>
                    <NavLink to="/administration/travel/request/policy"
                      className="btn">
                      Cancel
                    </NavLink>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditPolicyStep1Page;
