import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getValue } from 'src/_components/common/lodsh';

class CashAdvanceList extends React.PureComponent {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr className="text-uppercase">
              <th>CASH ADVANCE ID</th>
              <th>Employee Name</th>
              <th>Requested Date </th>
              <th>Request Amount</th>
              <th>Approved Amount</th>
              <th>Status</th>
              {/* {this.props.pageName!=='expenseReport' &&
                              <th className="text-right">Action</th>
                           } */}
            </tr>
          </thead>
          <tbody>
            {this.props.cashAdvanceApproval && this.props.cashAdvanceApproval.length > 0 ? this.props.cashAdvanceApproval.map((list) => {
              // let cashId = list.current.cashId;
              // list.CASH_DATA = list.CASH_DATA.data;
              // let amountIndex, cashAdvIndex, currencyIndex, formAndFields, userDetailFields, userFirstNameIdx, userLastNameIdx, userMiddleNameIdx, userFullName, workFlowStepStatus;
              // userDetailFields = list.CASH_DATA.userDeatils && list.CASH_DATA.userDeatils.fieldData;
              let workFlowStepStatus = getValue(list, "current.STATUS") ? getValue(list, "current.STATUS") : getValue(list, "intialStatus")
              // // userFirstNameIdx = userDetailFields.findIndex((fields) => fields.columnName === "EMPLOYEE_FIRST_NAME");
              // // userMiddleNameIdx = userDetailFields.findIndex((fields) => fields.columnName === "MIDDLE_NAME");
              // // userLastNameIdx = userDetailFields.findIndex((fields) => fields.columnName === "EMPLOYEE_LAST_NAME");
              // // userFullName = userDetailFields[userFirstNameIdx].fieldValue + ' ' + userDetailFields[userMiddleNameIdx].fieldValue + ' ' + userDetailFields[userLastNameIdx].fieldValue;
              // if (list.CASH_DATA.data && list.CASH_DATA.data.length > 0) {
              //     formAndFields = list.CASH_DATA.data && list.CASH_DATA.data;
              //     amountIndex = formAndFields.findIndex((fields) => fields.columnName === 'AMOUNT');
              //     cashAdvIndex = formAndFields.findIndex((fields) => fields.columnName === 'CASH_ADVANCE_NAME');
              //     currencyIndex = formAndFields.findIndex((fields) => fields.columnName === 'CURRENCY');
              //     // commentIndex = formAndFields.findIndex((fields) => fields.columnName === 'COMMENT');
              return (
                <tr key={list.id}
                  data-toggle="modal" data-target="#newcashAdvanceModal"
                  onClick={() => this.props.onCashAdvanceApprovalEditClickHandler(list.id, getValue(list, "current.cashId"))}
                >
                  <td >
                    <button
                      className="btn btn-nothing mb-2 text-primary">
                      {getValue(list, "current.cashAdvanceId")} </button>
                    <p>{getValue(list, "CASH_DATA.data.cashAdvanceName")}</p>
                  </td>
                  <td className="text-primary"><button className="btn btn-nothing text-primary">
                                <p>{getValue(list, "name", '')}</p>
                              </button>
                               <p style={{color:"gray"}}>  {getValue(list,"empId","")}</p>
                              </td>
                  <td>
                    {moment(list.createdAt).format('DD MMM YYYY hh:mm a')}
                  </td>
                  <td>
                    {getValue(list, "CASH_DATA.data.requestedAmountCurrencyValue")}
                                             &nbsp;
                                            {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(getValue(list, "CASH_DATA.data.requestedAmount", 0)))}
                  </td>
                  <td>
                    {getValue(list, "CASH_DATA.data.requestedAmountCurrencyValue")}
                    {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(getValue(list, "CASH_DATA.data.totalAmount", 0)))}

                  </td>
                  <td>
                    {(workFlowStepStatus === "Approved") ?
                      <button className="btn btn-nothing text-success"><i className="material-icons fs-12">thumb_up</i> {workFlowStepStatus}</button>
                      : (workFlowStepStatus === "Pending Submission")
                        ? <button className="btn btn-nothing"><i className="material-icons fs-12">
                          error
        </i> {workFlowStepStatus}</button>
                        : (workFlowStepStatus === "Pending Approval")
                          ? <button className="btn btn-nothing"><i className="material-icons fs-12">
                            hourglass_empty
            </i> {workFlowStepStatus}</button>
                          : (workFlowStepStatus === "Issued")
                            ? <button className="btn btn-nothing text-success-light"><i className="material-icons fs-12">
                              check_circle
            </i> {workFlowStepStatus}</button>
                            : (workFlowStepStatus === "SendBack to Employee") ?
                              <button className="btn btn-nothing text-danger"><i className="material-icons fs-12">warning</i> {workFlowStepStatus}</button>
                              : (workFlowStepStatus === "Reject") ?
                                <button className="btn btn-nothing text-danger"><i className="material-icons fs-12">thumb_down</i> {workFlowStepStatus}</button>
                                : <span>{workFlowStepStatus}</span>
                    }


                    {/* {workFlowStepStatus} */}
                  </td>
                  {/* <td className="text-right">
                                            {this.props.pageName!=='expenseReport' &&
                                            <div className="btn-group-margin">
                                            {list.current.actionButton.map((action) =>
                                                <button key={action.key}
                                                    onClick={()=>this.props.onCashAdvanceSelectActionHandler(action.key,list.id,action.button,'List')}
                                                    className="btn hover-v"
                                                    data-toggle="modal" data-target="#submitModalRequest">
                                                        {action.key==='APPROVE'? <i  className="material-icons">check_circle_outline </i>
                                                        :action.key==='SEND_BACK_EMPLOYEE'?<i  className="material-icons">arrow_back  </i>:
                                                        action.key==='REJECT'?<i  className="material-icons">not_interested  </i>
                                                        :<i  className="material-icons">add  </i>
                                                        }
                                                        <span className="hover-name bottom with-br">{action.button}</span>
                                                    </button>
                                                )}
                                            </div>
                                            }
                                        </td> */}
                </tr>
              )
            }
            ) : <tr><td colSpan={5} className="text-center">No Records Found</td></tr>
            }
          </tbody>
        </table>
      </div>

    )
  }
}
CashAdvanceList.propTypes = {
  cashAdvanceApproval: PropTypes.array,
  pageName: PropTypes.string
}
export default CashAdvanceList;
