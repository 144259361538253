import { http } from "../_helpers";
import { config } from "../env";

const getCartUserList = (tripID) => {
  return http.get(config.apiUrlTravel + `cart/all/price?TRIP_ID=${tripID}`).then(data => {
    return data ? data.result : "";
  });
};

const getCartListDetail = (tripID) => {
  return http.get(config.apiUrlTravel + `cart/all?TRIP_ID=${tripID}`).then(data => {
    return data ? data.result : "";
  });
};

const addCartUserData = data => {
  return http.post(config.apiUrlTravel + `add/cart`, data).then(data => {
    return data ? data : "";
  });
};

const removeCartUserData = data => {
  return http.post(config.apiUrlTravel + `remove/cart`, data).then(data => {
    return data ? data : "";
  });
};

const getTravelRequestFrom = (companyId, TRIPID, policyId) => {
  return http.get(config.apiUrlTravel + `travelRequestForm?companyId=${companyId}&TRIP_ID=${TRIPID}&policyId=${policyId}`).then(data => {
    return data ? data.result : "";
  });
}

const getCartDataExpense = () => {
  const TRIPID = sessionStorage.getItem('TRIPID');
  let companyId = localStorage["440a28"];
  return http.get(config.apiUrlTravel + `travel/expense-request?TRIP_ID=${TRIPID}&companyId=${companyId}`).then(data => {
    return data ? data : "";
  });
};
const getDataExpense = () => {
  const TRIPID = sessionStorage.getItem('TRIPID');
  return http.get(config.apiUrlTravel + `travel/expense/${TRIPID}`).then(data => {
    return data ? data : "";
  });
};

const getDataExpenseSub = () => {
  let TRIPID = sessionStorage['32AWE'];
  return http.get(config.apiUrlTravel + `travel/expense/${TRIPID}`).then(data => {
    return data ? data : "";
  });
};

const getCartPolicy = (companyId) => {
  return http.get(config.apiExpense + `policies?companyId=${companyId}`).then(data => {
    return data ? data.result : "";
  });
};

const getCartExpenseType = (companyId) => {
  return http.get(config.apiExpense + `expense-type?companyId=${companyId}`).then(data => {
    return data ? data.result : "";
  });
};

const addCartAddition = (id, data) => {
  return http.post(config.apiUrlTravel + `travel/expense-request/additional-advance/${id}`, data).then(data => {
    return data ? data : "";
  });
}

const postTravelRequestFrom = (data) => {
  return http.post(config.apiUrlTravel + `travelRequestFormEntry`, data).then(data => {
    return data ? data : "";
  });
}

const editTravelRequestFrom = (companyId, TRIPID, policyId) => {
  return http.get(config.apiUrlTravel + `travelRequestForm/details?companyId=${companyId}&TRIP_ID=${TRIPID}&policyId=${policyId}`).then(data => {
    return data ? data.result : "";
  });
}

const UpdateTravelRequestFrom = (data) => {
  return http.patch(config.apiUrlTravel + `travelRequestFormEntry`, data).then(data => {
    return data ? data : "";
  });
}
const getAllPassports = () => {
  return http.get(config.apiUrlCompany + `passport-info`).then(data => {
    return data ? data.result : "";
  });
}
const getFormsFeilds = (id, formName) => {
  return http.get(config.apiUrlCompany + `company-forms/get-form-fields-by-form-header?companyId=${id}&formHeaderKey=${formName}`).then(data => {
    return data ? data : "";
  });
}

const addExpenseService = (data, id) => {
  return http.post(config.apiUrlTravel + `/travel/expense-request/cash-advance/${id}`, data).then(data => {
    return data ? data : "";
  });
}
const submitFinalRequest = () => {
  let tripID = sessionStorage.TRIPID;
  return http.get(config.apiUrlTravel + `submit-user-cart?TRIP_ID=${tripID}`).then(data => {
    return data ? data : "";
  });
}

export const cartServices = {
  getCartUserList,
  removeCartUserData,
  addCartUserData,
  getCartListDetail,
  getTravelRequestFrom,
  getCartDataExpense,
  getCartPolicy,
  getCartExpenseType,
  addCartAddition,
  getDataExpense,
  postTravelRequestFrom,
  editTravelRequestFrom,
  UpdateTravelRequestFrom,
  getAllPassports,
  getFormsFeilds,
  addExpenseService,
  submitFinalRequest,
  getDataExpenseSub
};
