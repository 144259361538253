import React from "react";
import { NavLink } from "react-router-dom";
import DetailDropdownItem from "../../detailDropdownItem";
import moment from "moment";
moment.suppressDeprecationWarnings = true;

const leftSidebar = props => {
  const toDate = selector => {
    if (selector !== undefined && selector !== "") {
      var from = selector.split(/\//);
      return new Date(from[2], from[0] - 1, from[1]);
    }
  };
  return (
    <div className="expense-left">
      <DetailDropdownItem openAuditTrail={props.openAuditTrail} />
      <div className="scroll">
        <ul>
          {props.expenseEntryList
            ? props.expenseEntryList.map((list, index) => {
                return (
                  <li
                    className={
                      props.selectExpenstEntryList === list.id ? "active" : ""
                    }
                    key={index}
                  >
                    <div className="top">
                      <div className="right-side">
                        {/* <p className="hover-v mb-0 d-inline-block mt-2">
                        <img src="/assets/images/itemised.svg" alt="" /> */}
                        {/* <img src="/assets/images/partially-itemised.svg" alt="" /> */}
                        {/* <img src="/assets/images/not-itemised.svg" alt="" /> */}
                        {/* <span className="hover-name right-bottom less">Itemised</span>
                      </p>
                      <p className="hover-v mb-0 d-inline-block ml-3 mt-2">
                        <img src="/assets/images/allocated.svg" alt="" /> */}
                        {/* <img src="/assets/images/not-allocated.svg" alt="" /> */}
                        {/* <span className="hover-name right-bottom less">Allocated</span>
                      </p> */}
                      </div>
                      <div className="shape-name lg">
                        {/* <div className="left icon bg-success">
                          <i className="icon ion-md-car" />
                        </div> */}
                        <div className="right ml-0">
                          <h6 className="heading">
                            {list && list.validation && (
                              <i className="material-icons fs-14 text-danger">
                                error
                              </i>
                            )}{" "}
                        {list.history === "true"?<img src={require("../../../../../_assets/images/warning.png")} style={{ "width": "15px" }} /> : ""}
                            {list.expenseType.expenseTypeName ||
                              list.expenseTypeObj.expenseTypeName}
                          </h6>
                          {list.entryForm.map((date, index) => {
                            return (
                              <p key={index} className="text">
                                {date.dataType === "date"
                                  ? date.fieldValue
                                  : ""}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                      <div className="clearfix" />
                    </div>

                    {list.entryForm.map((amount, index) => {
                      let theadArray = [];
                      theadArray = list.entryForm.map(
                        header => header.columnName
                      );
                      let AMOUNT = theadArray.indexOf("AMOUNT");

                      return amount.dataType === "amount" &&
                        amount.columnName === "AMOUNT" &&
                        amount.fieldValue ? (
                        <div key={index} className="bottom">
                          <div className="right-side">
                            <p className="label">Requested</p>
                            <h6>
                              {list.currency}{" "}
                              {list.entryForm[AMOUNT].fieldValue &&
                                parseFloat(
                                  list.entryForm[AMOUNT].fieldValue
                                ).toFixed(2)}
                              {list.currency !== list.baseCurrency &&
                                "/" +
                                  list.baseCurrency +
                                  " " +
                                  parseFloat(list.baseAmount).toFixed(2)}
                            </h6>
                            {/* <h6>
                              {list.currency !== list.baseCurrency && '/' + list.baseCurrency + parseFloat(list.baseAmount).toFixed(2)}
                            </h6> */}
                          </div>
                          <div>
                            <p className="label">{amount.labelName}</p>
                            <h6>
                              {list.currency}
                              {parseFloat(amount.fieldValue).toFixed(2)}
                            </h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  </li>
                );
              })
            : ""}
        </ul>
      </div>
      <ul className="bottom-amount">
        <li>
          <h6>
            <span>Outstanding Advance</span> :{" "}
            {props.userBaseCurrency && props.userBaseCurrency}&nbsp;
            {props.cashAdvanceTotalAmount !== null
              ? parseFloat(props.cashAdvanceTotalAmount).toFixed(2)
              : "0.00"}
          </h6>
        </li>
        <li>
          <h6>
            <span>Total Amount</span> :{" "}
            {props.userBaseCurrency && props.userBaseCurrency}&nbsp;
            {props.totalExpenseAmount !== null
              ? parseFloat(props.totalExpenseAmount).toFixed(2)
              : "0.00"}
          </h6>
        </li>
        <li>
          <h6>
            <span>Total Requested</span> :{" "}
            {props.userBaseCurrency && props.userBaseCurrency}&nbsp;
            {props.totalRequestAmount !== null
              ? parseFloat(props.totalRequestAmount).toFixed(2)
              : "0.00"}
          </h6>
        </li>
      </ul>
      <div className="padx py-2">
      {localStorage.getItem("440a28") === "5dc5197e1382a2538aa3a8ca" ?"":
        <NavLink to="/expense/allocation" className="btn btn-block">
          Allocate
        </NavLink>
}
      </div>
    </div>
  );
};

export default leftSidebar;
