import React, { Component } from "react";
import AddEditTemplatePage from "./add-edit-template-html";
import { companyEmailReminderServices } from "../../../../../../_services";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
// import { withRouter } from "react-router-dom";

class AddEditTemplate extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      companyId: localStorage["440a28"],
      serviceModuleId: localStorage["serviceModuleId"],
      emailTemplateId: "",
      emailTemplateData: {},
      name: "",
      olderThan: "",
      olderThanType: "",
      frequency: "",
      displayFrequency: "",
      frequencyValue: "",
      subject: "",
      content: "",
      type: "",
      copyToApproval: "",
      isLoading: false,
      startDate: new Date(),
      time: "",
      copyEmails: [],
      error: "",
      notificationContent: "",
      contentAfterExpiry: "",
      isEditable: true,
      afterExpiryNotificationContent : ''
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState({
      emailTemplateId: this.props.match.params.id,
      time: this.state.startDate.toLocaleString().split("/")[0]
    });
    this.getEmailReminderDeatilsById();
  }
  // date picker handle change
  onSelectedDateChangeHandler = (date) => {
    let selectedDate = date.toLocaleString().split("/")[0];
    this.setState({
      startDate: date,
      frequencyValue: selectedDate
    });
  };

  // get email template details notificationContent
  getEmailReminderDeatilsById = async () => {
      const response = await companyEmailReminderServices.getEmailReminderDeatilsById(
        this.props.match.params.id
      );
      this.setState({
        name: response ? response.result.name : "",
        olderThan: response ? response.result.olderThan : "",
        olderThanType: response ? response.result.olderThanType: "",
        frequency: response ? response.result.frequency : "",
        subject: response ? response.result.subject : "",
        content: response ? response.result.content : "",
        type: response ? response.result.type : "",
        copyToApproval: response ? response.result.copyToApproval: "",
        copyEmails: response ? response.result.copyEmails.toString() : "",
        displayEmail: response ? response.result.copyEmails : "",
        contentAfterExpiry: response ? response.result.contentAfterExpiry: "",
        emailTemplateData: response ? response.result: [],
        notificationContent : response ?response.result.notificationContent: "",
        afterExpiryNotificationContent: response ? response.result.notificationContentAfterExpiry:""
      });

      if (response.result.frequency === "SPECIFIC_DAY_OF_MONTH") {
        let frequencyUpdatedDate = this.state.startDate.toString()
        .replace(this.state.time,response.result.frequencyValue);
        frequencyUpdatedDate = new Date(frequencyUpdatedDate);
        this.setState({
          startDate: response ? frequencyUpdatedDate : ""
        });
      } else {
        this.setState({
          frequencyValue: response.result.frequencyValue ? response.result.frequencyValue : ""
        });
      }
  };

  inputChangeHandlerForCCEmails = (data) => {
    const listOfEmails = data.target.value;
    this.setState({
      copyEmails: listOfEmails
    });
  };

  onInputChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onInputChangeHandlerForOlderThanType = (type) => {
    this.setState({
      olderThanType: type
    });
  };

  onInputHandlerForEmailContent = (data) => {
    this.setState({
      content: data
    });
  };

  onEmailFrequencyChangeHandler = (displayFrequency, frequency) => {
    this.setState({
      frequency: frequency,
      displayFrequency: displayFrequency
    });
  };

  onNotificationContentHandler = (notificationBoxContent) => {
    this.setState({
      notificationContent: notificationBoxContent.target.value
    });
  };

  onAfterExpiryContentHandler = (contentAfterExpiry) => {
    this.setState({
      contentAfterExpiry: contentAfterExpiry
    });
  };

  onAfterExpiryNotificationHandler = (data) => {
    this.setState({
      afterExpiryNotificationContent : data.target.value
    })
  }

  checkingCCEmailsValidation = (copyEmails) => {
    const regexForEmails = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    let listOfCCEmails = copyEmails.split(",").map(item => item.trim());
    if (listOfCCEmails.length === 0) {
      return false;
    }
    for (var item = 0; item < listOfCCEmails.length; item++) {
      if (!regexForEmails.test(listOfCCEmails[item])) {
        return false;
      }
    }
    return true;
  };
  // edit details updating here
  onSubmitHandler = async () => {
    let isEmailIsValid = this.checkingCCEmailsValidation(this.state.copyEmails);
    if (isEmailIsValid !== true) {
      toast.error("Invalid email")
      return false;
    }
    try {
      if (this.validator.allValid()) {
        const payload = {
          name: this.state.name,
          subject: this.state.subject,
          content: this.state.content,
          olderThan: +this.state.olderThan,
          olderThanType: this.state.olderThanType,
          frequency: this.state.frequency,
          notificationContent: this.state.notificationContent,
          contentAfterExpiry: this.state.contentAfterExpiry,
          frequencyValue: this.state.frequencyValue? this.state.frequencyValue: "",
          copyEmails: this.state.copyEmails.split(',').map(item => item.trim()),
          notificationContentAfterExpiry : this.state.afterExpiryNotificationContent,
          isSet: true
        };
        this.setState({ isLoading: true });
        const responseOfSubmitTemplate = await companyEmailReminderServices.editEmailTemplate(
          this.state.emailTemplateId,
          payload
        );
        this.setState({
          isLoading: false
        });
        toast.success(responseOfSubmitTemplate.message);
        this.props.history.push(`/administration/company/reminder/travel/list`);
      } else {
        this.validator.showMessages();
        this.setState({ loading: false });
      }
    } catch (err) {
      // console.log(err);
      this.setState({ loading: false });
    }
  };
  render() {
    return (
      <div>
        <AddEditTemplatePage
          {...this.state}
          onInputChangeHandlerForOlderThanType={this.onInputChangeHandlerForOlderThanType}
          onInputHandlerForEmailContent={this.onInputHandlerForEmailContent}
          onEmailFrequencyChangeHandler={this.onEmailFrequencyChangeHandler}
          onInputChangeHandler={this.onInputChangeHandler}
          inputChangeHandlerForCCEmails={this.inputChangeHandlerForCCEmails}
          onSubmitHandler={this.onSubmitHandler}
          validator={this.validator}
          onSelectedDateChangeHandler={this.onSelectedDateChangeHandler}
          onNotificationContentHandler={this.onNotificationContentHandler}
          onAfterExpiryContentHandler={this.onAfterExpiryContentHandler}
          onAfterExpiryNotificationHandler={this.onAfterExpiryNotificationHandler}
        />
      </div>
    );
  }
}
export default AddEditTemplate;
