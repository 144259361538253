import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import {
    ItemizationWizardPage, ItemizationStep1Page, ItemizationStep2Page
} from '../../index';
class ItemizationMainPage extends Component {
    constructor(props) {
        super(props)
        if (props.location.pathname === '/administration/expense/ex/itemization') {
            this.props.history.push('/administration/expense/ex/itemization/wizard')
        } else {
            this.props.history.push(props.location.pathname)
        }
    }
    componentDidUpdate() {
        if (this.props.location.pathname === '/administration/expense/ex/itemization') {
            this.props.history.push('/administration/expense/ex/itemization/wizard')
        }
    }
    render() {
        return (
            <div className="ItemizationHomePage">
                <Switch>
                    <Route path="/administration/expense/ex/itemization/wizard" component={ItemizationWizardPage} />                    
                    <Route exact={true} path="/administration/expense/ex/itemization/itemization-step1" component={ItemizationStep1Page} />
                    <Route path="/administration/expense/ex/itemization/itemization-step1/:id" component={ItemizationStep1Page} /> 
                    <Route path="/administration/expense/ex/itemization/itemization-step2/:id" component={ItemizationStep2Page} /> 
                </Switch>
            </div>
        )
    }
}
export default ItemizationMainPage;