import classNames from "classnames";

export const SearchResults = ({
  as = "ul",
  tag: Tag = as,
  className,
  children,
  ...props
}) => (
  <Tag
    className={classNames("autocomplete-dropdown-container", className)}
    {...props}
  >
    {children}
  </Tag>
);
