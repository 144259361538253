import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { TripBar } from "../../components";
import PlacesAutocomplete from "react-places-autocomplete";
import { NavLink } from "react-router-dom";

class AddOnlineCar extends Component {

  state = {
    type: 1
  };
 
  render() {
    return (
      <div>
        Not Available
      </div>
    );
  }
}

export default AddOnlineCar;
