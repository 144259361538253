import React from 'react';
import Menu from '../menu';
import Pagination from "react-js-pagination";
import Select from 'react-select';
import { Input, Select as SelectForm } from 'src/_components/common/_forms';
import Loader from 'src/_components/common/Loader';
const ApprovalStatusPage = (props) => {
    const getGroupNameFromGroupId = (groupIds) => {
        let groupNames = props.allGroupOptions.filter(option => groupIds.includes(option.value));
        return groupNames;
    }
    return (
        <div>
            <div className="admin-tabs">
                <Menu />
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">W</div>
                        <div className="right">
                            <h6 className="heading">Expense Approval Status</h6>
                            <p className="text">Use Expense Approval Status to manage Expense Approval Status for company approvers.</p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            <div className="py-padding">
                <div className="table-container">
                    <form>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="btn-group-margin">
                                    <button type="button" data-toggle="modal" data-target="#AddTemplateModal" className="btn" disabled={props.selectedExpenseApproval.length}>New</button>
                                    <button type="button" data-toggle="modal" data-target="#AddTemplateModal" onClick={props.onEditExpenseHandler} className="btn" disabled={!props.selectedExpenseApproval.length}>Modify</button>
                                    <button type="button" className="btn" disabled={!props.selectedExpenseApproval.length}
                                        onClick={props.onRemoveEExpenseApprovalHandler}

                                    >Remove</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="table-responsive">
                        <table className="table table-striped table-selected">
                            <thead>
                                <tr>
                                    <th>Report status name</th>
                                    <th>Approval actions</th>
                                    <th>description</th>
                                    <th>editable by group(s)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.allExpenseApproval.length === 0 &&
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            {(props.loading) ?
                                                <Loader /> :
                                                "No Records Found"}
                                        </td>
                                    </tr>}
                                {
                                    props.allExpenseApproval.length ? props.allExpenseApproval.map(expenseApproval => {
                                        return (
                                            <tr
                                                className={
                                                    props.selectedExpenseApproval.some(i => i.id === expenseApproval.id)
                                                        ? "active-tr"
                                                        : null
                                                }

                                                onClick={e => props.onApprovalSelectHandler(expenseApproval)}

                                            >
                                                <td>{expenseApproval.status_approval}</td>
                                                <td>{expenseApproval.actionText}</td>
                                                <td>{expenseApproval.actionText_desc}</td>
                                                <td>{expenseApproval.editable_by_group.length ? getGroupNameFromGroupId(expenseApproval.editable_by_group).map(item => {
                                                    return (
                                                        <span>{item.label + ' '}</span>
                                                    )
                                                }) : <span>Global</span>}</td>
                                            </tr>
                                        )
                                    }) : null
                                }


                            </tbody>
                        </table>
                    </div>
                    <div className="table-footer">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="page-count">
                                    <div className="text">Items</div>
                                    <div className="count">
                                        {props.pageItemsList && (
                                            <SelectForm
                                                placeholder={props.limit}
                                                keyName={"items"}
                                                options={props.pageItemsList}
                                                controlFunc={e => props.handleItemChange(e)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <Pagination
                                    activePage={props.page}
                                    itemsCountPerPage={props.limit}
                                    totalItemsCount={props.count}
                                    pageRangeDisplayed={3}
                                    onChange={props.handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="AddTemplateModal" tabIndex="-1" role="dialog"
                aria-labelledby="AddTemplateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content border-0">
                        <div className="modal-head">
                            <h5 className="modal-title" id="AddTemplateModalLabel">Report Status</h5>
                        </div>
                        <div className="modal-middle pm-30">
                            <div className="form-group custom">
                                <Input
                                    name='status_approval'
                                    label="Status"
                                    type="text"
                                    imp="true"
                                    controlFunc={e => props.inputChangeHandler(e, 'status_approval')}
                                    value={props.status_approval}
                                    error={props.error.message('status', props.status_approval, 'required')}
                                />
                            </div>
                            <div className="form-group custom">
                                <Input name="actionText"
                                    type="text"
                                    label="Action Text"
                                    imp="true"
                                    placeholder="Action Text"
                                    value={props.actionText}
                                    className="form-control"
                                    controlFunc={e => props.inputChangeHandler(e, 'actionText')}
                                    error={props.error.message('status', props.actionText, 'required')}
                                />
                            </div>
                            <div className="form-group custom">
                                <Input
                                    name="actionText_desc"
                                    value={props.actionText_desc}
                                    label="Description"
                                    imp="true"
                                    type="text" placeholder="Description"
                                    className="form-control"
                                    controlFunc={e => props.inputChangeHandler(e, 'actionText_desc')}
                                    error={props.error.message('actionText_desc', props.actionText_desc, 'required')}
                                />
                            </div>
                            <div className="form-group custom labelup textarea">
                                <label>Editable by Group(s)</label>
                                <Select
                                    value={props.selectedGroup || ''}
                                    options={props.allGroupOptions || []}
                                    onChange={props.onGroupChange}
                                    isMulti
                                    error={props.error.message('selectedGroup', props.selectedGroup, 'required')}
                                />
                            </div>
                            <div className="modal-last btn-group-margin">
                                {
                                    props.editMode ? (
                                        <button className="btn" onClick={props.updateApprovalStatus}>Update</button>
                                    ) : (
                                            <button className="btn" onClick={props.saveApprovalStatus}>Save</button>
                                        )
                                }
                                <button className="btn close" data-dismiss="modal" onClick={props.cancelApprovalStatus} >Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ApprovalStatusPage;