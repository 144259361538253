import React, { Component } from 'react';
import UnusedTicketPage  from './unused-ticket-html';
class UnusedTicket extends Component {
    constructor(props){
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    render() {
        return(
            <div>
                <UnusedTicketPage
                />
            </div>
        )
    }
}
export default UnusedTicket;