import React from "react";
const ExpenseSettingsPage = props => {
  const path = "url(/assets/images/user_1.png)";
  const profImg = {
    backgroundImage: path
  };
  return (
    
    <div className="p-padding">
      <div className="profile-card my-card">
        <div className="details">
          <div className="float-right">
            <button className="btn btn-nothing text-primary float-right ml-auto">
              Edit
                    </button>
          </div>
          <div className="float-left">
            <h6 className="value float-left">
              Bank Details - Reimbursement Account
                    </h6>
          </div>
          <div className="clearfix" />
          <div className="px-4">
            <div className="row add-dashed-border">
              <div className="col-md-4">
                <div className="shape-name">
                  <div className="left">B</div>
                  <div className="right">
                    <p className="text">Bank Name</p>
                    <h6 className="heading">HSBC Bank Limited</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="shape-name ">
                  <div className="left">B</div>
                  <div className="right">
                    <p className="text">Bank Location</p>
                    <h6 className="heading">Hong Kong</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="shape-name ">
                  <div className="left">I</div>
                  <div className="right">
                    <p className="text">IFSC/Routing Number</p>
                    <h6 className="heading">HSBC029002</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row add-dashed-border border-0">
              <div className="col-md-4">
                <div className="shape-name ">
                  <div className="left">A</div>
                  <div className="right">
                    <p className="text">Account Type</p>
                    <h6 className="heading">
                      Checking/Savings Account
                            </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="shape-name ">
                <div className="left">A</div>
                  <div className="right">
                    <p className="text">Account Number</p>
                    <h6 className="heading">028192810921298</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="shape-name">
                  <div className="left">G</div>
                  <div className="right">
                    <p className="text">Account Holder Name</p>
                    <h6 className="heading">Brandon Evans</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-card my-card">
        <div className="details pb-0">
          <h6 className="value">Delegate</h6>
        </div>
        <div className="delegate bg-white">
          <div className="names">
            <div className="search-custom">
              <input
                type="text"
                name=""
                className="form-control"
                placeholder="Search"
              />
              <span>
                <i className="icon ion-md-search" />
              </span>
            </div>
            <div className="scroll">
              <ul>
                <li className="active">
                  <div className="shape-name xs">
                    <div className="left img" style={profImg} />
                    <div className="right">
                      <h6 className="heading">Jobin John</h6>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="shape-name xs">
                    <div className="left img" style={profImg} />
                    <div className="right">
                      <h6 className="heading">John Martin</h6>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="shape-name xs">
                    <div className="left img" style={profImg} />
                    <div className="right">
                      <h6 className="heading">Brandon Brit</h6>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="shape-name xs">
                    <div className="left img" style={profImg} />
                    <div className="right">
                      <h6 className="heading">Carlos Chairman</h6>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="detail">
            <h6 className="sub-heading">Delegated</h6>
            <div className="scroll">
              <div className="all-detail">
                <div className="shape-name lg">
                  <div className="left img" style={profImg} />
                  <div className="right">
                    <h6 className="heading">Vishwa Reddy</h6>
                    <p className="text">Travel Manager</p>
                  </div>
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group custom labelup">
                        <label>Access Type</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn bg-white dropdown-toggle btn-block"
                            data-toggle="dropdown"
                          >
                            User Access
                                  </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">
                              Access 1
                                    </button>
                            <button className="dropdown-item">
                              Access 2
                                    </button>
                            <button className="dropdown-item">
                              Access 3
                                    </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group custom labelup">
                        <label>Service</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn bg-white dropdown-toggle btn-block"
                            data-toggle="dropdown"
                          >
                            Travel, Expense
                                  </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">
                              Access 1
                                    </button>
                            <button className="dropdown-item">
                              Access 2
                                    </button>
                            <button className="dropdown-item">
                              Access 3
                                    </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group custom bg-white">
                        <input
                          type="text"
                          placeholder="&nbsp;"
                          className="form-control"
                        />
                        <label>From</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group custom bg-white">
                        <input
                          type="text"
                          placeholder="&nbsp;"
                          className="form-control"
                        />
                        <label>To</label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </div>
  );
};
export default ExpenseSettingsPage;
