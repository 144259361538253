import React, { Component } from 'react'
import {NavLink} from 'react-router-dom';
import ToggleSlider from '../../../../../../common/ToggleSlider/ToggleSlider';
import { travelPolicyService } from '../../../../../../../_services/travel-travel-policy.services';


export default class ConditionContainer extends Component {

  state = {
    companyId: "5c2c891c30566e1f00b24552"
  };



  render() {
    const {
      policiesByCategory,
      toggleConditionHandler
    } = this.props;
  return (
    <div className="scroll">
    <ul>
        { policiesByCategory ? policiesByCategory.map(policyByCategory => {
          return (
            <li key={policyByCategory.id} >
              <div className="condition-container">

                <div className="left-toggle">
                  <ToggleSlider toggleSliderHandler={e => toggleConditionHandler(policyByCategory.activeStatus, policyByCategory.id)} value={policyByCategory.activeStatus} checked={policyByCategory.activeStatus}  />
                </div>
                <div className="right-edit">
                  <NavLink to={`/administration/travel/edit-travel-policy/${policyByCategory.id}`} className="btn   sm-padding">Edit</NavLink>
                </div>
                <div clas="center-content">
                  <p className="label">Condition</p>
                  <p>
                    <b>{policyByCategory.ruleName}</b> - Require Pre Approval
                    </p>
                </div>
              </div>
            </li>
          )
        }) : "No assigned Rules Found"
        }
      {/* <li>
        <div className="condition-container">
          <div className="left-toggle">
            <label className="switch"><input type="checkbox" /><span className="slider round"></span></label>
          </div>
          <div className="right-edit">
            <NavLink to="/administration/travel/edit-travel-policy" className="btn   sm-padding">Edit</NavLink>
          </div>
          <div clas="center-content">
            <p className="label">Condition</p>
            <p>
              <b>Trip Duration Greater then 7 days</b> - Require Pre Approval
              </p>
          </div>
        </div>
      </li> */}
      {/* <li>
        <div className="condition-container">
          <div className="left-toggle">
            <label className="switch"><input type="checkbox" /><span className="slider round"></span></label>
          </div>
          <div className="right-edit">
            <NavLink to="/administration/travel/edit-travel-policy" className="btn   sm-padding">Edit</NavLink>
          </div>
          <div clas="center-content">
            <p className="label">Condition</p>
            <p>
              <b>Trip Duration Greater then 7 days</b> - Require Pre Approval
              </p>
          </div>
        </div>
      </li> */}

    </ul>
  </div>
  )
  }
}
