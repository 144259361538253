import { observable, action, runInAction, computed, reaction } from "mobx";

class ExpenseStore {
  // define global variables
  @observable reportHeader = "";
  @observable policyId = "";
  @observable expenseTypeId = "";
  @observable itemizeExpenseTypeId = "";
  @observable selectExpenstEntryList = "";
  @observable itemizationEntryAvail = "";
  @observable editHeaderId = "";
  @observable submissionStatus='';

  @action setPolicyId(policyId) {
    this.policyId = policyId;
  }

  @action setExpenseType(expenseType) {
    this.expenseTypeId = expenseType;
  }
  /**store expense type after clicking on itemisation*/
  @action setItemizeExpenseTypeId(itemizeExpenseTypeId) {
    this.itemizeExpenseTypeId = itemizeExpenseTypeId;
  }
  /** After successfull creation of expense report store the response for show in header report */
  @action setReportHeader(reportHeaderContent) {
    this.reportHeader = reportHeaderContent.result;
  }
  /**set the expenseENtryId when ever he click on list */
  @action setSelectExpenstEntryList = entryId => {
    this.selectExpenstEntryList = entryId;
  };

  @action setEntryItemizationAvailable = entryItemization => {
    this.itemizationEntryAvail = entryItemization;
  };

  @action setEditHeaderId = editHeaderId => {
    this.editHeaderId = editHeaderId;
  };
  @action expenseReportSubmittable =(status)=>{
    this.submissionStatus=status;
  }
}

export default new ExpenseStore();
