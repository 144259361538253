import { http } from "../_helpers";
import { config } from "../env";
import cryptr from "../_helpers/crypty";
import menuStore from "src/_store/menu.store";
import { toast } from "react-toastify";
import { alertConstants } from "src/_constants";
export const userService = {
  login,
  logout,
  getUserList,
  changeUserStatus,
  getUserDetails,
  createUser,
  editUser,
  forgotPassword,
  deleteUser,
  verifyCodePassword,
  updatePassword,
  verifyInvitationCode,
  createPassword,
  adminAuth,
  getLogoCompany,
  getDelecatedUser,
  getNotifications,
  getAllNotificationList,
  readNotification,
  switchDelUser,
  getSearchUserList,
  getCompanyNote,
  postCompanyNote,
  updateCompanyNote,
  updateCompanyNoteStatus,
  getEachCompanyNote,
  getAllNotificationListOnlyCount
};


function getEachCompanyNote(id) {
  return http
    .get(config.apiUrlCompany + `/company-note/get-one/?id=${id}`)
    .then(data => {
      return data;
    });
}
function updateCompanyNoteStatus(id, data) {
  let url = config.apiUrlCompany + "company-note/active-inactive/" + id;
  return http
    .patch(url, data)
    .then(data => data);
}

function login(data) {
  return http.post(config.apiUrlCompany + "company-login", data).then(data => {
    // login successful if there's a jwt token in the response
    if (data.result.token) {
      // store user details and jwt token in local storage to keep user logged in between page
      // user data
      localStorage.setItem(
        "440a24",
        cryptr.encrypt(JSON.stringify(data.result))
      );
      // currency
      localStorage.setItem("1UC43", cryptr.encrypt(JSON.stringify(data.result.currency)));
      // token
      localStorage.setItem("440a21", JSON.stringify(data.result.token));
      // company data
      localStorage.setItem("440a28", data.result.companyId);
    }

    return data;
  });
}
function adminAuth(companyId, token) {
  return http.get(config.apiUrlSuperAdmin + `/projects/${companyId}/console?token=${token}`).then(data => {
    // login successful if there's a jwt token in the response
    if (data.result && data.result.token) {
      // store user details and jwt token in local storage to keep user logged in between page
      // user data
      localStorage.setItem(
        "440a24",
        cryptr.encrypt(JSON.stringify(data.result))
      );
      // token
      localStorage.setItem("f1222a", true)
      localStorage.setItem("440a21", JSON.stringify(data.result.token));
      // company data
      localStorage.setItem("440a28", data.result.companyId);
      menuStore.getMenuList()
    }

    return data;
  });
}
function logout() {
  localStorage.removeItem("personalInfo");
  localStorage.removeItem("440a24");
}


function getUserList(search, companyId) {
  return http
    .get(config.apiUrlCompany + `/company-users/${search}?limit=${10}&page=${1}&companyId=${companyId}`)
    .then(data => {
      return data;
    });
}

function getSearchUserList(page, items) {
  return http
    .get(config.apiUrlCompany + "users?page=" + page + "&limit=" + items)
    .then(data => {
      return data;
    });
}

function getLogoCompany() {
  let id = localStorage['440a28'];
  return http
    .get(config.apiUrlSuperAdmin + `projects/${id}/get-logo`)
    .then(data => {
      return data;
    });
}

function forgotPassword(params) {
  return http.post(config.apiUrlCompany + "forgot-password", params).then(data => {
    return data;
  });
}

function verifyCodePassword(data) {
  return http
    .post(config.apiUrlCompany + "check-verification-code", data)
    .then(data => {
      return data;
    });
}

function updatePassword(params) {
  return http.post(config.apiUrlCompany + "reset-password", params).then(data => {
    return data;
  });
}
function changeUserStatus(user, newStatus) {
  let url = config.apiUrlCompany + "users/" + user.id;
  return http
    .patch(url, { activeStatus: JSON.stringify(newStatus) })
    .then(data => data);
}

function getUserDetails(userId) {
  let url = config.apiUrlCompany + "users/" + userId;
  return http.get(url).then(data => data);
}

function createUser(userDetails) {
  let url = config.apiUrlCompany + "users";
  return http.post(url, userDetails).then(data => data);
}

function deleteUser(userId) {
  let url = config.apiUrlCompany + "users/" + userId;
  return http.patch(url, { deleteStatus: "true" }).then(data => data);
}

function editUser(user) {
  let url = config.apiUrlCompany + "users/" + user.id;
  return http.patch(url, { ...user }).then(data => data);
}

// * call this api after you get the verification code from the url bar

function verifyInvitationCode(payload) {
  let url = `${config.apiUrlCompany}verify-invitation-code`;
  return http.post(url, payload)
    .then(res => res)
}

// * call this api for creating passwords

function createPassword(payload) {
  let url = `${config.apiUrlCompany}set-password`;
  return http.post(url, payload)
    .then(res => res)
}

function getDelecatedUser(id) {
  return http
    .get(config.apiUrlCompany + `delegated-users?parentUserId=${id}`)
    .then(data => {
      return data;
    });
}
function getNotifications() {
  return http
    .get(config.apiUrlCompany + `/notifications/updates`)
    .then(data => {
      return data;
    });
}
function getAllNotificationList(limit, page) {
  return http
    .get(config.apiUrlCompany + `/notifications/recentUpdates?limit=${limit}&page=${page}`)
    .then(data => {
      return data;
    });
}
function getAllNotificationListOnlyCount(limit, page) {
  return http
    .get(config.apiUrlCompany + `/notifications/recentUpdates?isCount=${true}`)
    .then(data => {
      console.log(data)
      return data;
    });
}

// function getAllNotificationList(limit, page, search) {
//   return http
//     .get(config.apiUrlCompany + `/notifications/recentUpdates`)
//     .then(data => {
//       return data;
//     });
// }
function readNotification(id) {
  return http
    .patch(config.apiUrlCompany + `notifications/change-read-status/${id}`)
    .then(data => {
      return data;
    });
}

function getCompanyNote(companyId) {
  return http
    .get(config.apiUrlCompany + `company-note?companyId=${companyId}`)
    .then(data => {
      return data ? data.result : '';
    });
}
function updateCompanyNote(req, id) {
  return http
    .patch(config.apiUrlCompany + `company-note/${id}`, req)
    .then(data => {
      return data;
    });
}
function postCompanyNote(req) {
  return http
    .post(config.apiUrlCompany + `company-note`, req)
    .then(data => {
      return data;
    });
}


function switchDelUser(id, status) {
  return http
    .get(config.apiUrlCompany + `/delegated-users/act-as?delegatedUserId=${id}&switchParentUser=${status}`)
    .then(data => {
      if (data && data.result) {
        localStorage.setItem(
          "440a24",
          cryptr.encrypt(JSON.stringify(data.result))
        );
        // currency
        if (data.result.currency) {
          localStorage.setItem("1UC43", cryptr.encrypt(JSON.stringify(data.result.currency)));
        }
        // delecatedUser
        if (!status) {
          localStorage.setItem("43k32", true)
        }
        // token
        localStorage.setItem("440a21", JSON.stringify(data.result.token));
        // company data
        localStorage.setItem("440a28", data.result.companyId);
      }
      return data;
    });
}

export const getSSOUserDetails = () => {
  return http
    .get(config.apiUrlCompany + `get-sso-user-details`)
    .then(data => {
      return data ? data : '';
    });
}

export const getSSOURL = (data) => {
  // return http.get(config.apiUrlCompany + ``)
  return http.post(config.apiUrlCompany + `check-sso`, data)
    .then(data => {
      return data ? data : '';
    });
}

