import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class Menu extends Component {
    render() {
        return (
            <div>
                <ul className="nav nav-tabs">
                    <li><NavLink to="/administration/company/workflows/home">Workflows</NavLink></li>
                    <li><NavLink to="/administration/company/workflows/approval-status">Expense Approval Status</NavLink></li>
                    <li><NavLink to="/administration/company/workflows/authorised-approvers-list">Authorised Approvers</NavLink></li>
                </ul>
            </div>
        )
    }
}
export default Menu