import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import ExpensePoliciesMenu from "../expense-policies-menu";
const ExpensePolicyPage = props => {
  let data = props;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="header-right">
            <button className="btn btn-nothing">Booking Type?</button>
          </div>
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">New Policy</h6>
              <p className="text">Add/Modify Request Policy.</p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <ExpensePoliciesMenu />
            <div className="table-container">
              {/* <form>
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="btn-group-margin">
                                        <button type="button" className="btn  ">Activate</button>
                                        <button type="button" className="btn  ">Preview Form</button>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="search-custom">
                                        <input type="text" name="" className="form-control" placeholder="Search" />
                                        <span><i className="material-icons">search</i></span>
                                    </div>
                                </div>
                            </div>
                        </form> */}
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Expense Type</th>
                      <th />
                      <th />
                      <th />
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Airfare</td>
                      <td />
                      <td />
                      <td />

                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Airfare Ticket Tack</td>
                      <td />
                      <td />
                      <td />

                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Awards</td>
                      <td />
                      <td />
                      <td />

                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Bank fees</td>
                      <td />
                      <td />
                      <td />

                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Business Meal(attendies)</td>
                      <td />
                      <td />
                      <td />

                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Breakfast</td>
                      <td />
                      <td />
                      <td />

                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            10
                          </button>
                          <div className="dropdown-menu">
                            <button type="button" className="dropdown-item">
                              10
                            </button>
                            <button type="button" className="dropdown-item">
                              20
                            </button>
                            <button type="button" className="dropdown-item">
                              30
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={data.active}
                      itemsCountPerPage={10}
                      totalItemsCount={10}
                      pageRangeDisplayed={3}
                      onChange={data.handlePageChange}
                    />
                  </div>
                </div>
                {/* <div className="mt-4">
                                <div className="btn-group-margin">
                                    <NavLink to="/administration/expense/policy-step3" className="btn btn-border-success lg-padding">Next</NavLink>
                                    <button className="btn btn-border-basic lg-padding">Cancel</button>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpensePolicyPage;
