import { http, createLogger } from "src/_helpers";
import { map, pickAll } from "lodash/fp";
import { config } from "src/env.jsx";
import { toast } from "react-toastify";

const visaServiceLogger = createLogger("VisaService");

export class Visa {
  static baseUrl = config.apiUrlCompany;
  static newVisaRequestUrl = `${Visa.baseUrl}visa-request`;

  static defaultPopularCountries = [
    { id: 1, itemName: "India" },
    { id: 2, itemName: "Canada" },
    { id: 3, itemName: "UK" },
    { id: 4, itemName: "Japan" },
    { id: 5, itemName: "US" },
    { id: 6, itemName: "France" },
    { id: 7, itemName: "Germany" },
    { id: 8, itemName: "Brazil" },
    { id: 9, itemName: "Italy" },
    { id: 10, itemName: "South Korea" },
    { id: 11, itemName: "Australia" },
    { id: 12, itemName: "China" }
  ];

  applyNew = async data => {
    try {
      const response = await http.post(
        Visa.newVisaRequestUrl,
        data,
        {
          Accept: "applicantion/json"
        },
        true
      );
      if (response.ok) {
        const { message, result: visaInfo } = (await response.json()) || {};
        toast.success(message);
        return visaInfo;
      } else {
        let body = await response.text();
        try {
          body = JSON.parse(body);
          const { message } = body || {};
          toast.warn(message);
          return { error: { message } };
        } catch (error) {
          if (response.status === 304) {
            const message = "Visa already applied.";
            toast.warn(message);
            return { error: { message } };
          }
          throw new Error("No Result");
        }
      }
    } catch (error) {
      toast.error(error.message);

      return {
        error
      };
    }
  };
  // http://13.234.165.138:5000/countries
  static getCountriesLogger = visaServiceLogger.extend("getCountries");
  static getCountries = async ({ companyId }) => {
    const countriesUrl = `${config.apiUrlSuperAdmin}countries`;
    const { result } = await http.get(countriesUrl);
    const countries = map(pickAll(["id", "name"]), result);
    Visa.getCountriesLogger("Countries: %O", countries);
    return countries;
  };
}
