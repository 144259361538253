import React, { Component } from 'react';
import SsoLoginPage from './sso-login-html';
import SimpleReactValidator from 'simple-react-validator';
import errorStore from '../../_store/error.store'
import { inject, observer } from 'mobx-react';
import { getSSOUserDetails, getSSOURL } from 'src/_services';
import { toast } from 'react-toastify';
import cryptr from 'src/_helpers/crypty';
import menuStore from "../../_store/menu.store"

@inject('authStore')
@inject('commonStore')
@observer
class SsoLogin extends Component {
  constructor(props) {
    super(props)
    // localStorage.clear();
    this.validator = new SimpleReactValidator();
    this.state = {
      email: '',
      ssoUrl: ''
    }
    var success = this.getUrlVars()['success']
    var token = this.getUrlVars()["token"];
    var ssoURL = this.getUrlVars()["ssoURL"]
    if (success === "true" && token && ssoURL) {
      // localStorage.setItem('440a24', token);
        localStorage.setItem('sso', ssoURL)
      localStorage.setItem(
        "440a24",
        cryptr.encrypt(JSON.stringify({ token: token }))
      );
      this.getUserDetails();
    }
  }
  getUserDetails = async () => {
    // replace updated user details based on token url
    let resp = await getSSOUserDetails()
    if (resp) {
      localStorage.setItem(
        "440a24",
        cryptr.encrypt(JSON.stringify(resp.result))
      );
      // currency
      localStorage.setItem("1UC43", cryptr.encrypt(JSON.stringify(resp.result.currency)));
      // token
      localStorage.setItem("440a21", JSON.stringify(resp.result.token));
      // company data
      localStorage.setItem("440a28", resp.result.companyId);
      localStorage.setItem('personalInfo', resp?.result?.userPersonalInfo);
      if (resp && resp.result && resp.result.cpiId) {
        localStorage.setItem('cpiId', resp.result.cpiId);
      } else { }
      // console.log('---- login data  ', data.result);
      menuStore.getMenuList();
      return resp;

      this.props.history.push('/')
    }
  }
  getUrlVars() {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {

      var base_url = window.location.origin;
      let data={
        email  : this.state.email,
        relayState : base_url
      }
      let resp = await getSSOURL(data);
      if (resp.result) {
        this.setState({
          ssoUrl: resp.result.ssoURL
        })
      } else if (resp.error) {
        toast.error(resp.error.message)
      }

      window.location.href = `${this.state.ssoUrl}`
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first timehttps://us04web.zoom.us/j/78757715872?pwd=QXdDYXc1M1BFWDhMVWJ0ZFVURTFwZz09
      this.forceUpdate();
    }
  }
  render() {
    return (
      <SsoLoginPage
        state={this.state}
        error={this.validator}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    )
  }
}
export default SsoLogin;
