import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import { Input, Select } from "../../../../common/_forms";

const ItemizationStep2Page = props => {
  const {
    isEdit,
    count,
    itemsPerPage,
    pageItemsList,
    activePage,
    handleItemChange,
    handlePageChange,
    listExpenseType,
    handleStatusChange
  } = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">R</div>
          <div className="right">
            {isEdit === false ? (
              <h6 className="heading">Add Itemization</h6>
            ) : (
                <h6 className="heading">Edit Itemization</h6>
              )}
            {/* <p className="text">Lorem Ipsum is simply dummy</p> */}
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="audit-step">
            <button className="s1">
              <span>1</span>
              General
            </button>
            <button className="s1 active">
              <span>2</span>
              Available Expense Types
            </button>
          </div>

          <div className="table-container">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Policy Name</th>
                    <th>Required</th>
                    <th>Default to personal</th>
                    <th>Must be personal</th>
                  </tr>
                </thead>
                <tbody>
                  {listExpenseType && listExpenseType.length === 0 && (
                    <tr>
                      <td colSpan="4" className="text-center">No Records Found</td>
                    </tr>
                  )}
                  {listExpenseType &&
                    listExpenseType.map((items, key) => {
                      return (
                        <tr key={key}>
                          <td>{items.expenseTypeName}</td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={e =>
                                  handleStatusChange(items, "required")
                                }
                                value={items.required}
                                checked={items.required}
                              />
                              <span className="slider round" />
                            </label>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={e =>
                                  handleStatusChange(items, "defaultToPersonl")
                                }
                                value={items.defaultToPersonl}
                                checked={items.defaultToPersonl}
                              />
                              <span className="slider round" />
                            </label>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={e =>
                                  handleStatusChange(items, "mustBePersonl")
                                }
                                value={items.mustBePersonl}
                                checked={items.mustBePersonl}
                              />
                              <span className="slider round" />
                            </label>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {count !== 0 && (
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        {pageItemsList && (
                          <Select
                            placeholder={itemsPerPage}
                            keyName={"items"}
                            options={pageItemsList}
                            controlFunc={e => handleItemChange(e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={count}
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
                <div className="btn-group-margin mt-4">
                  <NavLink to="/administration/expense/ex/itemization" className="btn"> Done </NavLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddTemplateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AddTemplateModalLabel">
                Available Expense Types
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group custom labelup">
                <label>Expense Type</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    Mobile Phone
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round" />
                </label>
                <span>Required</span>
              </div>
              <div className="form-group">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round" />
                </label>
                <span>Default to Personal</span>
              </div>
              <div className="form-group">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round" />
                </label>
                <span>Must be Personal</span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-border-success lg-padding"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-border-basic lg-padding"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ItemizationStep2Page;
