import React from 'react'

export default function SendEmailModal() {
  return (
    <div>
       <div
        className="modal fade"
        id="SendEmailModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="SendBacktoEmployeeLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="ChangeApproverLabel">
                Edit Action
                  <button
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom labelup">
                <label>Action Name</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    Send Email
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">Change Approver</button>
                    <button type="button" className="dropdown-item">Generate Exception</button>
                    <button className="dropdown-item disabled">Reject (cash advance workflows only)</button>
                    <button className="dropdown-item">Send Back to Employee</button>
                    <button className="dropdown-item">Send Email</button>
                    <button className="dropdown-item ">Skip Step</button>
                  </div>
                </div>
              </div>
              <div className="form-group custom">
                <input type="text" className="form-control" placeholder="&nbsp;" />
                <label>Email Address</label>
              </div>
              <div className="form-group">
                <label className="switch">
                  <input type="checkbox"  />
                  <span className="slider round"></span>
                </label>
                <span>Default Approver</span>
              </div>
              <div className="form-group custom labelup">
                <label>Email Notification</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    Custom Email
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">Custom Email</button>
                    <button className="dropdown-item ">Custom Email</button>
                  </div>
                </div>
              </div>
              <div className="form-group custom">
                <input type="text" className="form-control" placeholder="&nbsp;" />
                <label>Email Subject</label>
              </div>
              <div className="form-group custom textarea">
                <textarea
                  rows="3"
                  data-gramm_editor="false"
                      spellCheck="false"
                  className="form-control"
                  placeholder="&nbsp;"
                />
                <label>Message</label>
              </div>
              <div className=" modal-last btn-group-margin">
                <button
                  type="button"
                  className="btn btn-border-success"
                >
                  Save
              </button>
                <button
                  type="button"
                  className="btn btn-border-basic"
                  data-dismiss="modal"
                >
                  Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}
