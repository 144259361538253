import React from 'react'

export default function Reject(props) {
  return (
    <div>
      <textarea
        rows="3"
        data-gramm_editor="false"
                      spellCheck="false"
        className="form-control"
        placeholder="&nbsp;"
        value={props.action_value?.message}
        onChange={props.rejectStepMessageHandler}
      />
      <label>Message</label>
    </div>
  )
}
