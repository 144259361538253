import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

import ExpenseCategoryPage from "./expense-category-html";
import { expenseServices } from "../../../../../../../_services";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../../../_constants/alert.constants";
import $ from "jquery";
class ExpenseCategory extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.validator = new SimpleReactValidator();
    this.state = {
      companyId: localStorage["440a28"],
      expenseCategoryName: "",
      expenseCategoryId: "",
      loading2: true
    };
    this.loading = false;
  }
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
  activePage = 1;
  itemsPerPage = 10;
  search = "";
  componentDidMount() {
    this.getExpenseCategory();
  }
  addSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.loading = true; 
      let newObj = {
        companyId: this.state.companyId,
        expenseCategoryName: this.state.expenseCategoryName
      };

      var resp = await expenseServices.createExpenseCategory(newObj);
      if(resp){
        this.close();
        toast.success(alertConstants.CREATED_SUCCESSFULLY);
        this.loading = false;
        this.getExpenseCategory();
        $(".close").click();
      }
      
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  editSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.loading = true;
      var resp = await expenseServices.updateExpenseCategory(this.state);
      if(resp){
        this.close();
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        this.loading = false;
        this.getExpenseCategory();
        $(".close").click();
      } 
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  listExpenseCategory = [];
  getExpenseCategory = async () => {
    this.listExpenseCategory = [];
    this.count = 0;
    this.choosedItem = "";
    this.setState({loading2 : true})

    var resp = await expenseServices.getExpenseCategory(
      this.activePage,
      this.itemsPerPage,
      this.search,
      this.state.companyId
    );
    if (resp) { 
      this.count = resp.count;
      this.listExpenseCategory = resp.result;
      this.setState({loading2 : false})
    }
    else if(!resp){
      this.setState({loading2 : false})
    }
    this.forceUpdate();
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSearch = e => {
    this.search = e.target.value;
    this.getExpenseCategory();
  };
  handleItemChange = limit => {
    this.activePage = 1;
    this.itemsPerPage = limit["items"];
    this.getExpenseCategory();
  };

  handlePageChange = pageNumber => { 
    this.activePage = pageNumber;
    this.getExpenseCategory();
  };
  handleStatusChange = async data => {
    let newStatus = !data.activeStatus;
    let payload = {
      // id: data.id,
      activeStatus: newStatus
    };
    var resp = await expenseServices.statusExpenseCategory(data.id, payload);
    toast.success(resp.message);
    this.getExpenseCategory();
  };
  choosedItem = "";
  chooseEdit = e => {
    e === this.choosedItem ? (this.choosedItem = "") : (this.choosedItem = e);
    this.forceUpdate();
  };
  removeItem = async e => {
    var resp = await expenseServices.deleteExpenseCategory(this.choosedItem);
    if (resp) {
      toast.info(resp.message);
      this.getExpenseCategory();
    }
  };
  getData = () => {
    this.listExpenseCategory.forEach(item => {
      if (item.id === this.choosedItem) {
        this.setState({
          expenseCategoryName: item.expenseCategoryName,
          expenseCategoryId: item.id
        });
      }
    });
  };
  close = () => {
    this.validator.hideMessages();
    this.setState({
      expenseCategoryName: "",
      expenseCategoryId: ""
    });
    this.choosedItem = "";
  };
  render() {
    return (
      <div>
        <ExpenseCategoryPage
          validator={this.validator}
          state={this.state}
          loading={this.loading}
          loading2={this.state.loading2}
          handleChange={this.handleChange}
          addSubmit={this.addSubmit}
          listExpenseCategory={this.listExpenseCategory}
          activePage={this.activePage}
          itemsPerPage={this.itemsPerPage}
          handleSearch={this.handleSearch}
          pageItemsList={this.pageItemsList}
          handleItemChange={this.handleItemChange}
          count={this.count}
          choosedItem={this.choosedItem}
          chooseEdit={this.chooseEdit}
          handleStatusChange={this.handleStatusChange}
          removeItem={this.removeItem}
          editSubmit={this.editSubmit}
          close={this.close}
          getData={this.getData}
          handlePageChange={this.handlePageChange}
        />
      </div>
    );
  }
}
export default ExpenseCategory;
