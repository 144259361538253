import PlacesAutocomplete from "react-places-autocomplete";
import { getValue } from "src/_components/common/lodsh";

const FromSearchInput = props => (
    <div
        ref={props.locationFromRef}
        className={props.loadInput === "fromLoc" ? "details from show-popup" : "details from"}>
        <div className={(getValue(props.request, "car_pickup_location.city") === '' && props.isSubmitted) ? "details-margin not-valid" : "details-margin"}>
            <div className="d-table d-table__middle" onClick={() => props.enableInput("fromLoc")}>
                <label htmlFor="search1" className="text-left m-label">
                    <p className="label">From </p>
                    <h6 className="place">{getValue(props.request, "car_pickup_location.city") ? getValue(props.request, "car_pickup_location.city") : "Where From ?"}</h6>
                </label>
            </div>
        </div>
        <div className="popup">
            <PlacesAutocomplete
                value={getValue(props.request, "car_pickup_location.city")}
                onChange={e => props.handleLocationSelection(e, 2, 1)}
                onSelect={e => props.handleLocationSelection(e, 3, 1)}
                autofocus={true}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                }) => (
                        <div>
                            <div className="typ">
                                <input
                                    {...getInputProps({
                                        placeholder: "Search Places ...",
                                        className: "",
                                        autofocus: true
                                    })}
                                    id="search1"
                                />
                                <div className="pin">
                                    <i className="material-icons">
                                        location_on
</i>
                                </div>
                            </div>

                            {suggestions.length === 0 && (
                                <ul className="scroll">
                                    <li>
                                        <button type="button">Popular Cities</button>
                                    </li>
                                    {getValue(props, "popularCity", []).map((items, key) => {
                                        return (
                                            <li key={key}>
                                                <button
                                                    type="button"
                                                    onClick={(e) => props.handleLocationSelection(items, 1, 1)}
                                                >
                                                    {items.name}
                                                </button>
                                                <div className="pin">
                                                    <i className="material-icons">
                                                        location_on
</i>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                            <ul className="scroll autocomplete-dropdown-container">
                                {loading && (
                                    <li>
                                        <button>Loading...</button>
                                    </li>
                                )}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                    const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer"
                                        }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer"
                                        };
                                    return (
                                        <li
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style
                                            })}
                                        >
                                            <button>
                                                {suggestion.description}
                                            </button>
                                            <div className="pin">
                                                <i className="material-icons">
                                                    location_on
</i>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
            </PlacesAutocomplete>
        </div>
    </div>
)
export default FromSearchInput;