import React from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import DropdownButton from "./components/DropDownButton";
import Menu from "../Menu";
import StaticMenu from "../staticMenu";
import Loader from "src/_components/common/Loader";

const FormsFieldsPage = props => {
  const {
    fullFormData,
    closeModal,
    closeToggleHandler,
    editToggleHandler,
    formName,
    formNameHandler,
    handleSubmit,
    handlePageChange,
    handleItemChange,
    handleStatusChange,
    handleHeaderButtonClick,
    toggleFormSelect,
    toggleFormFieldSelect,
    getMappedFields,
    handleMappedStatusChange,
    handleMappedMandatoryChange,
    toggleFieldSelect,
    removeFields,
    mapFieldsToForm,
    resetSeletedField,
    onlyCustomFields,
    changeModule,
    loading,
    listService,
    currentModule,
    sequenceChangeHandler,
    submitSequenceHandler,
    setFormPreviewName,
    loadingInit
  } = props;

  // * destructure from state
  const {
    allGroupsList
  } = props.fullFormData;



  // select form field
  let activeStyle = ["active-tr"];
  let noStyle = [""];
  let isItemSelected = fullFormData.selectedFormField.length;
  let removeButtonStyle = ["btn"];
  if (isItemSelected) {
    removeButtonStyle.push("disabled");
  }

  // select field

  let fieldSelected = fullFormData.selectedField.length;
  let removeFieldStyle = ["btn"];
  if (fieldSelected) {
    removeFieldStyle.push("disabled");
  }

  const closeBtn = (
    <button className="close" onClick={closeToggleHandler}>
      &times;
    </button>
  );

  const sortedMappedFields = fullFormData && fullFormData.mappedFields && fullFormData.mappedFields.sort((a, b) => (a.sequence - b.sequence));




  // * On clicking on form field name and insert field, the columns come from here
  let getCustomFields =
    fullFormData.customFields &&
    fullFormData.customFields.map(field => {
      return (
        <tr
          key={field.id}
          className={
            fullFormData.selectedField.includes(field.id) ? activeStyle : null
          }
        >
          <td onClick={toggleFieldSelect.bind(this, field)}>
            {field.fieldName}
          </td>
          <td onClick={toggleFieldSelect.bind(this, field)}>
            {field.columnName}
          </td>
          <td onClick={toggleFieldSelect.bind(this, field)}>
            {JSON.stringify(field.isMandatory)}
          </td>

        </tr>
      );
    });

  // * Create groupOptions

  let allGroupsOptions = allGroupsList && allGroupsList.map(item => {
    return {
      value: item.id,
      label: item.itemName
    }
  })

  // * Get group Names from Ids

  const getGroupNamesFromId = (groupIds) => {
    // console.log(groupIds)
    let groupNames = groupIds.map(id => {

      const matchedname = allGroupsOptions.find(option => option.value == id);
      if (matchedname) {
        return matchedname.label
      }
    });
    if (groupNames.length) {

      return groupNames.join(' ');
    } else {
      return 'Global';
    }

  }

  // * End of group Names from Ids


  // * when form name edit is being clicked this is being shown
  let mappedFieldsAre =
    fullFormData.mappedFields &&
    fullFormData.mappedFields.map(mappedField => {
      return (
        <tr
          key={mappedField.id}
          className={
            fullFormData.selectedFormField.some(i => i.id === mappedField.id)
              ? activeStyle
              : "cursor-pointer"
          }
        >
          <td onClick={toggleFormFieldSelect.bind(this, mappedField)}>
            {mappedField.labelName}
          </td>
          <td onClick={toggleFormFieldSelect.bind(this, mappedField)}>
            {mappedField.dataType}
            {(mappedField.childColumnName) ?
              " - MS" : (mappedField.multiSelectParentField) ? " - MS" : ""
            }
          </td>
          <td onClick={toggleFormFieldSelect.bind(this, mappedField)}>
            {mappedField.dataType == "connected_list" ? mappedField.connectedListSource : '-'}
          </td>
          <td onClick={toggleFormFieldSelect.bind(this, mappedField)}>
            {mappedField.columnName}

          </td>



          <td>
            <p className="td-padding">
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={e => handleMappedMandatoryChange(mappedField)}
                  checked={mappedField.isMandatory}
                  value={mappedField.isMandatory}
                />
                <span className="slider round" />
              </label>
            </p>
          </td>
          <td>
            <p className="td-padding">
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={e => handleMappedStatusChange(mappedField)}
                  checked={mappedField.activeStatus}
                  value={mappedField.activeStatus}
                />
                <span className="slider round" />
              </label>
            </p>
          </td>
          <td>
            <input className="form-control w-50" value={mappedField.sequence} onChange={(e) => sequenceChangeHandler(mappedField, e)} type="number" required min="0" />
          </td>
        </tr>
      );
    });

  // * Shows all the form name rows

  let modifyForm =
    fullFormData.forms &&
    fullFormData.forms.map(form => {
      return (
        <>
          <tr
            key={form.id}
            className={
              fullFormData.selectedForm.some(i => i.id === form.id)
                ? noStyle
                : null
            }
          >
            <td onClick={toggleFormSelect.bind(this, form)}>
              <span className="add-minus" />
              {form.formName}
            </td>
            {fullFormData.isGroupRequired ? <td>{form.formAccess ? getGroupNamesFromId(form.formAccess) : ''}</td> : null}
            <td>
              {(form.activeStatus) ?
                <button
                  className="btn btn-nothing hover-v"
                  id="ejectId"
                  data-toggle="collapse"
                  data-target={"#demo" + form.id}
                  onClick={getMappedFields.bind(this, form.id)}
                  aria-expanded="true"
                  aria-controls={"#demo" + form.id}
                >
                  <i className="material-icons fs-20">
                    edit
                  </i>
                  <span className="hover-name right-top">Edit</span>
                </button> : "-"}
            </td>
            <td>
              <p className="td-padding">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={e => handleStatusChange(form)}
                    checked={form.activeStatus}
                    value={form.activeStatus}
                  />
                  <span className="slider round" />
                </label>
              </p>
            </td>
          </tr>
          {(props.fullFormData.isEnabletrue) ?
            <tr
              className="collapse"
              id={"demo" + form.id}
              aria-labelledby={"heading" + form.id}
              data-parent="#accordion"
            >
              <td colSpan="4" className="bg-table">
                <div className="table-container">
                  <form>
                    <div className="btn-group-margin">
                      <NavLink
                        to={
                          fullFormData.selectedFormField.length
                            ? `/administration/company/admin-forms/forms-fields/modify-form-fields/${
                            fullFormData.selectedFormField[0].id
                            }`
                            : `javascript:void(0)`
                        }
                      >
                        <button
                          className="btn mr-2"
                          disabled={isItemSelected ? false : true}
                        >
                          Modify
                      </button>{" "}
                      </NavLink>

                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#submitModel"
                        className="btn"
                        // onClick={removeFields}
                        disabled={isItemSelected ? false : true}
                      >
                        Remove Form Field
                    </button>
                      <button
                        type="button"
                        onClick={onlyCustomFields}
                        data-toggle="modal"
                        data-target="#insertField"
                        className="btn"
                      >
                        Insert Field
                    </button>

                      {/* Hidden Preview Form 04- May */}
                      {/* <button
                      type="button"
                      data-toggle="modal"
                      data-target="#fieldPreview"
                      className="btn"
                      onClick={" "}
                    disabled={isItemSelected ? false : true}
                    >
                      Preview Form
                    </button> */}
                      <button
                        type="button"

                        className="btn"
                        onClick={() => submitSequenceHandler()}

                      >
                        Update Sequence
                    </button>
                      <button
                        type="button"
                        data-toggle="modal"
                        className="btn"
                        data-target="#previewForm"
                        onClick={(e) => setFormPreviewName(form.formName)}

                      >
                        Preview
                    </button>

                    </div>
                  </form>

                  <div className="table-responsive">
                    <table className="table table-striped table-selected">
                      <thead>
                        <tr>
                          <th>Field Name</th>
                          <th>Data Type</th>
                          <th>List Source</th>
                          <th>Column Name</th>
                          <th>Mandatory</th>
                          <th>Status</th>
                          <th>Sequence</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mappedFieldsAre ? (
                          mappedFieldsAre
                        ) : (
                            <tr>
                              <td colSpan="5" className="text-center">No data found</td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            : ""}
        </>
      );
    });

  return (
    <div>
      <div className="admin-tabs">
        <Menu
          changeModule={changeModule}
          currentModule={currentModule}
          listService={listService}
        />
      </div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">F</div>
          <div className="right">
            <h6 className="heading">Forms Fields</h6>
            <p className="text">Manage fields assigned to forms.</p>
          </div>
        </div>
      </div>
      <div className="p-padding pb-0">
        <div className="form-holder">
          <div className="row">
            <div className="col-4">
              <div className="form-group custom labelup mb-0">
                <label>Form Type</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    {fullFormData.selectedFormHeader
                      ? fullFormData.selectedFormHeader
                      : "Select Form Type"}
                  </button>
                  <div className="dropdown-menu">
                    {fullFormData.formHeaderName
                      ? fullFormData.formHeaderName.map(formhead => {
                        return (
                          <DropdownButton
                            handleHeaderButtonClick={handleHeaderButtonClick}
                            key={formhead.id}
                            id={formhead.id}
                            name={formhead.formHeaderName}
                          />
                        );
                      })
                      : "No Headers Found"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-padding">
        <div className="admin-tabs px-padding">
          <StaticMenu />
        </div>
        <div className="table-container" id="accordion">
          {fullFormData.selectedFormHeader && (
            <>
              <form>
                <div className="row">
                  <div className="col-sm-9" />
                  <div className="col-sm-3">
                    <div className="search-custom">
                      <input
                        type="text"
                        name=""
                        className="form-control"
                        placeholder="Search"
                        disabled={
                          fullFormData.selectedFormHeader ? false : true
                        }
                      />
                      <span>
                        <i className="material-icons">search</i>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive" display="none">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Form Name</th>
                      {fullFormData.isGroupRequired ? <th>Access</th> : null}
                      <th>Action</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(loadingInit) ?
                      <tr>
                        <td colSpan="4">
                          <Loader />
                        </td>
                      </tr> : ""}
                    {modifyForm ? (
                      modifyForm
                    ) : (
                        <tr>
                          <td colSpan="4" className="text-center">No data found</td>
                        </tr>
                      )}
                    {/* {formDatas} */}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {fullFormData.forms && !loadingInit && (
            <div className="table-footer">
              <div className="row">
                <div className="col-sm-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          {fullFormData.itemPerPage}
                        </button>
                        <div className="dropdown-menu">
                          <button
                            onClick={handleItemChange.bind(this, 10)}
                            className="dropdown-item"
                          >
                            10
                          </button>
                          <button
                            onClick={handleItemChange.bind(this, 20)}
                            className="dropdown-item"
                          >
                            20
                          </button>
                          <button
                            onClick={handleItemChange.bind(this, 30)}
                            className="dropdown-item"
                          >
                            30
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-9">
                  <Pagination
                    activePage={fullFormData.activePage}
                    itemsCountPerPage={fullFormData.itemsCountPerPage}
                    totalItemsCount={fullFormData.totalItemsCount}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Insert field */}
      <div
        className="modal fade"
        id="insertField"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="insertFieldLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="insertFieldLabel">
                Add File to Form
              </h5>
              {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            {/* <div className="search-custom">
                <input
                  type="text"
                  name=""
                  className="form-control"
                  placeholder="Search Permissions"
                />
                <span>
                  <i className="material-icons">search</i>
                </span>
              </div> */}
            <div className="modal-middle">
              <div className="table-container mx-0 modal-scroll">
                <div className="table-responsive">
                  <table className="table table-striped mb-0 table-selected">
                    <thead>
                      <tr>
                        <th>Form Field Name</th>
                        <th>Column Name</th>
                        <th>Mandatory</th>
                      </tr>
                    </thead>
                    <tbody>{getCustomFields}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-last pm-30 btn-group-margin">
              <button
                type="button"
                className="btn"
                disabled={fieldSelected ? false : true}
                onClick={mapFieldsToForm}
              >
                Assign
              </button>
              <button
                type="button"
                className="btn close"
                data-dismiss="modal"
                onClick={resetSeletedField}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Preview Form */}
      <div
        className="modal fade"
        id="previewForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="previewFormLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="previewFormLabel">
                {fullFormData.formPreviewName} Preview
              </h5>
              {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            {/* <div className="search-custom">
                <input
                  type="text"
                  name=""
                  className="form-control"
                  placeholder="Search Permissions"
                />
                <span>
                  <i className="material-icons">search</i>
                </span>
              </div> */}
            <div className="modal-middle">
              <div className="table-container mx-0 modal-scroll">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <tbody>
                      {
                        sortedMappedFields.map(mappedField => {
                          return (
                            <tr
                              key={mappedField.id}
                              className="border-0"
                            >
                              <td className="border-0">
                                {(mappedField.dataType === "boolean") ?
                                  <div className="p-2">
                                    {mappedField.labelName}{mappedField.isMandatory ? <i class="material-icons text-danger" style={{ fontSize: "8px", paddingBottom: "12px" }}>
                                      star_rate</i> : <i className="pl-5"></i>}
                                    <label className="switch pl-0">
                                      <input
                                        type="checkbox"
                                        checked={mappedField.activeStatus}
                                      />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                  :
                                  <div className="border p-2">
                                    {mappedField.labelName}
                                    {mappedField.isMandatory ? <i class="material-icons text-danger" style={{ fontSize: "8px", paddingBottom: "12px" }}>
                                      star_rate</i> : ''}
                                    <div className={(mappedField.dataType === "different_list" || mappedField.dataType === "connected_list") ? "d-block float-right" : "d-none"}>
                                      <i class="material-icons ">
                                        arrow_drop_down</i>
                                    </div>
                                    <div className={(mappedField.dataType === "date") ? "d-block float-right" : "d-none"}>
                                      <i class="material-icons">
                                        date_range</i>
                                    </div>
                                    <div className="clearfix"></div>
                                  </div>
                                }



                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-last pm-30 btn-group-margin">
              {/* <button
                type="button"
                className="btn"
                disabled={fieldSelected ? false : true}
                onClick={mapFieldsToForm}
              >
                Assign
              </button> */}
              <button
                type="button"
                className="btn close"
                data-dismiss="modal"
              //onClick={resetSeletedField}
              >
                Close Preview
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Preview Field Details */}
      <div
        className="modal fade"
        id="fieldPreview"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="fieldPreviewModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="fieldPreviewModalLabel">
                Preview Form
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].fieldName
                      }
                    />
                    <label>Field Name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].labelName
                      }
                    />
                    <label>Label Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].columnName
                      }
                    />
                    <label>Column Name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].tableName
                      }
                    />
                    <label>Table Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      className="form-control"
                      placeholder="&nbsp;"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].maxLength
                      }
                    />
                    <label>Max Length</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        fullFormData.selectedFormField[0].tableName
                      }
                    />
                    <label>Table Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        JSON.stringify(
                          fullFormData.selectedFormField[0].isMandatory
                        )
                      }
                    />
                    <label>Is Mandatory</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group custom">
                    <input
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                      disabled
                      value={
                        fullFormData.selectedFormField.length &&
                        JSON.stringify(
                          fullFormData.selectedFormField[0].activeStatus
                        )
                      }
                    />
                    <label>Active status</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* added by sethu for showing popup when we try to remove field */}
      <div
        data-toggle="modal"
        data-keyboard="false"
        className="modal fade"
        id="submitModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="travelPolicyLabel"
        aria-hidden="true"
        data-backdrop="false"

      >
        <div
          className="modal-dialog modal-dialog-top modal-md"
          role="document"

        >
          <div className="modal-content">
            <div className="modal-body" style={{ padding: "0rem" }}>
              <button
                type="button"
                className="close text-white d-none"
                data-dismiss="modal"
                aria-label="Close"

              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="text-center pt-5">
                <h5 className=" my-4 text-dark">
                  <img src="/assets/images/confirmation_icon.svg" alt="" />&nbsp;&nbsp;
                  Are you sure you want to remove the field?
                  </h5>

                {/* <div className="modal-middle pm-30">
                  <h2 className="text-center mt-5 mb-4">Do you want to submit
                  <br/>
                   cab details ?</h2>
                </div> */}
                <div className="text-center modal-last mb-5 btn-group-margin">

                  <button
                    type="button"
                    className="btn btn-border-success px-4 py-1 mr-3"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#thanksModal"
                    onClick={removeFields}
                    style={{ backgroundColor: "#f2f2f2", borderRadius: "5px", color: "black" }}
                  >
                    Yes
              </button>
                  <button
                    type="button"
                    className="btn btn-border-basic px-4 py-1"
                    data-dismiss="modal"
                    style={{ backgroundColor:"#6a9eff", borderRadius: "5px", color: "white" }}
                  >
                    No
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormsFieldsPage;
