import React, { Component } from "react";
import { Input } from "../../../../../common/_forms";
import Select from "react-select";

class TableRow extends Component {
  render() {
    let { configurationName, editableObj } = this.props.data;
    let activeStatus = this.props.data.activeStatus; 
    return (
      <tr
        key={this.props.data.id}
        className={
          this.props.selectedConfigId.includes(this.props.data.id)
            ? "active-tr"
            : ""
        }
      >
        <td
          onClick={() => this.props.selectedDeleteHandler(this.props.data.id)}
          style={{ cursor: "pointer" }}
        >
          {configurationName}
        </td>
        <td
          onClick={() => this.props.selectedDeleteHandler(this.props.data.id)}
          style={{ cursor: "pointer" }}
        >
          {editableObj.result &&
            editableObj.result.map((data, index) => {
              return (
                <span key={data.id} className="mr-2">
                  {editableObj.result.length - 1 > index
                    ? data.itemName
                    : data.itemName}
                </span>
              );
            })}
        </td>
        <td>
          <button
            type="button"
            data-toggle="modal"
            data-target="#EditTemplateModal"
            onClick={() => this.props.editHandler(this.props.data)}
            className="btn btn-nothing hover-v"
          >
            <i className="material-icons fs-20">edit</i>
            <span className="hover-name right-top">Edit</span>
          </button>
        </td>
        <td>
          <label className="switch">
            <input
              type="checkbox"
              defaultChecked={activeStatus}
              onClick={() =>
                this.props.statusHandler(activeStatus, this.props.data.id)
              }
            />
            <span className="slider round" />
          </label>
        </td>
        <td
          className="modal fade"
          id="EditTemplateModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="EditTemplateModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-head">
                <button
                  type="button"
                  className="close text-white d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5 className="modal-title" id="EditTemplateModalLabel">
                  Edit Configuration
                </h5>
                <button
                  type="button"
                  className="close d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-middle pm-30">
                <div className="modal-scroll pt-3">
                  <div className="form-group custom">
                    <Input
                      label={"Configuration Name"}
                      name="configName"
                      type="text"
                      imp="true"
                      controlFunc={e => this.props.inputChangeHandler(e)}
                      value={this.props.configName}
                      className="form-control"
                    />
                    <div>
                      <p style={{ color: "red", marginLeft: 90 }}>
                        {this.props.submitted &&
                        this.props.configName.length <= 0
                          ? "The Configuration Name field is required."
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="form-group custom labelup textarea">
                    <label>
                      Editable By <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="editableBy"
                      isMulti
                      value={this.props.editableBy}
                      options={this.props.editableByList}
                      onChange={this.props.changeEditableBy}
                      required
                    />
                    <p style={{ color: "red", marginLeft: 90 }}>
                      {this.props.submitted && this.props.editableBy.length <= 0
                        ? "The Editable by field is required."
                        : ""}
                    </p>
                  </div>

                  {/* <div className="form-group custom labelup">
                    <label>Allowance Calculation Type <span className="text-danger">*</span></label>
                    <Select
                      isDisabled={true}
                      value={this.props.allowanceSelectedType}
                      name="allowanceSelectedType"
                      options={this.props.allowanceCalculationType}
                      onChange={this.props.allowanceCalculationTypeHandler}
                      required
                    />
                    <p style={{ color: "red", marginLeft: 90 }}>
                      {this.props.submitted && this.props.allowanceSelectedType.length <= 0 ? 'The Allowance Calculation Type field is required.' : ''}
                    </p>
                  </div> */}
                  {/* <h6 className="sub-heading mb-4">For One Way Trips</h6>
                  <div className="form-group custom labelup">
                    <label>Start On <span className="text-danger">*</span></label>
                    <Select
                      value={this.props.oneWayStartSelected}
                      name="oneWayStartSelected"
                      options={this.props.tripStartEndTypes}
                      onChange={this.props.onOneWayChangeTripHandler}
                    />
                    <p style={{ color: "red", marginLeft: 90 }}>
                      {this.props.submitted && this.props.oneWayStartSelected.length <= 0 ? 'The Start On field is required.' : ''}
                    </p>
                  </div> */}
                  <h6 className="sub-heading mb-4">For Round Way Trips</h6>
                  <div className="form-group custom labelup">
                    <label>
                      OnWard <span className="text-danger">*</span>
                    </label>
                    <Select
                      value={this.props.onWardStartSelected}
                      name="onWardStartSelected"
                      options={this.props.tripStartEndTypes}
                      onChange={this.props.onRoundWayChangeTripHandler}
                    />
                    <p style={{ color: "red", marginLeft: 90 }}>
                      {this.props.submitted &&
                      this.props.onWardStartSelected.length <= 0
                        ? "The OnWard field is required."
                        : ""}
                    </p>
                  </div>

                  <div className="form-group custom labelup">
                    <label>
                      Return <span className="text-danger">*</span>
                    </label>
                    <Select
                      value={this.props.onWardReturnSelected}
                      name="onWardReturnSelected"
                      options={this.props.tripStartEndTypes}
                      onChange={this.props.onRoundReturnChangeTripHandler}
                    />
                    <p style={{ color: "red", marginLeft: 90 }}>
                      {this.props.submitted &&
                      this.props.onWardReturnSelected.length <= 0
                        ? "The Return field is required."
                        : ""}
                    </p>
                  </div>
                  <h6 className="sub-heading mb-4">For Multi City Trips</h6>
                  <div className="form-group custom labelup mb-0">
                    <label>
                      Start On <span className="text-danger">*</span>
                    </label>
                    <Select
                      value={this.props.multiCityStartSelected}
                      name="multiCityStartSelected"
                      options={this.props.tripStartEndTypes}
                      onChange={this.props.onMultiCityChangeTripHandler}
                    />
                    <p style={{ color: "red", marginLeft: 90 }}>
                      {this.props.submitted &&
                      this.props.multiCityStartSelected.length <= 0
                        ? "The Start On field is required."
                        : ""}
                    </p>
                  </div> 
                  <br />
                  <div className="form-group">
                    <label className="switch">
                      <input 
                        type="checkbox"
                        value={this.props.guestActive}
                        checked={this.props.guestActive}
                        onChange={e => this.props.enableTypeRate("guestActive",this.props.guestActive)}
                      />
                      <span className="slider round" />
                    </label>
                    <span>Enable Guest House Rate</span>
                  </div> 
                  <div className="form-group">
                    <label className="switch">
                    <input 
                        type="checkbox"
                        value={this.props.personalActive}
                        checked={this.props.personalActive}
                        onChange={e => this.props.enableTypeRate("personalActive",this.props.personalActive)}
                      />
                      <span className="slider round" />
                    </label>
                    <span>Enable Stay at Friends Rate</span>
                  </div> 
              
                </div>
                <div className="modal-last pm-30 pt-0 btn-group-margin">
                  <button
                    type="button"
                    className="btn"
                    onClick={e => this.props.submitHandler(e)}
                  >
                    {this.loading == true ? "Please wait" : "Save"}
                  </button>
                  <button type="button" className="btn" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}
export default TableRow;
