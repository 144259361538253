import React from "react";
import uuid from "uuid";
const WorkFlowSelectSearch = props => (
  console.log(props.assignKey),
    <div className={"dropdown" + ' ' + props.myClass} key={uuid.v4()}>
    <input
      disabled={props.disabled || false}
      type="text"
      autoComplete="off"
      id={`valueConnect${props.assignKey}`}
      value={props.value ? props.value :""}
      onChange={e => props.handleSearch(e.target.value,props.refId,props.assignKey)}
      placeholder={props.value ? props.value : props.placeholder}
      className={"dropdown-toggle " + props.myClass + ' ' + props.className}
      data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false"
      style={{"border":"none","background":"transparent","padding":"0px 0px 0px 13px","width":"100%"}}
    >
    </input>
    {props.loading ?
      <img
      style={{"float":"left","right":"0","position": "absolute"}}
      src="https://www.glamood.com/images/loading2.1b52efc6.gif" width="30" />
      :
      <div className="dropdown-menu"  id={`connect${props.assignKey}`}  onScroll={e => props.handleScroll(e,props.refId,props.assignKey,props.page,props.count,props.options.length,props.form.search)} style={{    "max-height": "100px",
    "overflow-y": "scroll"}}>
      {props.options && props.options.map((opt, index) => {
        return (
          <button
            type="button"
            onClick={e =>
                props.onClickHandler(props.typeAll?opt:{value:opt.id,label:opt[props.keyName]}, props.form,
                  props.expressionId,props.assignKey
                )
              }
            className="dropdown-item"
            key={index}
          >
            {opt[props.keyName]}
          </button>
        );
      })}
      {props.options.length === 0 &&
        <button
          type="button"
          className="dropdown-item"
        >
          No Records Found
    </button>
      }
    </div>
    }
    <div className="help-block">{props.error}</div>
  </div>
);

export default WorkFlowSelectSearch;
