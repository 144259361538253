import React from "react";
import NumberFormat from 'react-number-format';
import ETicketComboPage from "src/_components/e-ticket-combo";
import InvoicePage from "src/_components/invoice-combo";

export const FlightCartComboDetail = props => {
  const {
    item,
    currentItem,
    secondsToHms,
    removeCart,
    nonEdit,
    ticketInvoice,
    enableEticket,
    enableInvoice,
    eticket,
    closeWindow,
    eticketInfo,
    invoice,
    invoiceInfo

  } = props;

  let curreny = props.baseCurrency;
  let setup = 0;
  let baggage = (item.baggageInfo && item.baggageInfo[0]) ? item.baggageInfo[0] : [];

  let arrivalPlace = "";
  let destinationPlace = "";



  return (
    <div className="white-card shadow-light mb-0">
      <div className="bg-light p-3">
        <div className="row">
          <div className="col-md-4">Employee Preferred</div>
          <div className="col-md-8">
            <div className="btn-group-margin text-right">
              {ticketInvoice && (
                <div className="d-inline-block">
                  <button
                    data-toggle="modal"
                    data-target="#cancelTripModal"
                    className="btn btn-nothing px-3"
                  >
                    Cancel
                </button>
                  {/* <button
                    data-toggle="modal"
                    data-target="#rescheduleTripModal"
                    className="btn btn-nothing px-3 border-left"
                  >
                    Reschedule
                </button> */}

                  <button
                    onClick={() => enableEticket()}
                    className="btn btn-nothing px-3 border-left"
                  >
                    E-Ticket
                </button>
                  <button
                    onClick={() => enableInvoice()}
                    className="btn btn-nothing px-3 border-left"
                  >
                    Invoice
                </button>
                  {eticket && (
                    <ETicketComboPage
                      closeWindow={closeWindow}
                      eticketInfo={eticketInfo}
                    />
                  )}
                  {invoice && (
                    <InvoicePage
                      closeWindow={closeWindow}
                      invoiceInfo={invoiceInfo}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {item.fareContent.map((list, key) => {
        return (
          <div className="b-b1">
            {list.content.map((combo, comboKey) => {
              return (
                <div className="devision-container flight-iti pb-0 mb-0">
                  <div className="details main">
                    <div className="name">
                      <div className="logo">
                        {combo && (
                          <img
                            className="img-fluid"
                            src={
                              combo.flight_image
                                ? combo.flight_image
                                : "/assets/images/booking/flight-dummy.png"
                            }
                            alt="images"
                          />
                        )}
                      </div>
                      <div className="info">
                        <p>
                          <b>
                            {/* {combo.flight_from_city} */}
                            {
                              arrivalPlace = item?.flight_airport[combo.flight_from_city],
                              arrivalPlace ? arrivalPlace.n + ', ' + arrivalPlace.c : ''
                            }
                          </b>
                          {" "}to{" "}
                          <b>
                            {/* {combo.flight_to_city} */}
                            {destinationPlace = item?.flight_airport[combo.flight_to_city],
                              destinationPlace ? destinationPlace.n + ', ' + destinationPlace.c : ''}
                          </b>
                        </p>
                        <p className="timings">
                          {combo.flight_depature_date_utc} {" | "} {combo.flight_depature_time} - {combo.flight_arrival_time} |{" "}
                          <i className="material-icons fs-14 align-middle">
                            access_time
                          </i> {" "}
                          {secondsToHms(combo.flight_duration)}
                        </p>
                        <p className="class">
                          {combo.flight_operating_airline}  -  {combo.flight_airline}  -  {combo.flight_number}
                        </p>
                      </div>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="clearfix" />
                  {list &&
                    list.content &&
                    list.content.length !== comboKey + 1 && (
                      <div className="flight-divider my-2 mx-5">
                        <div>Change Flight</div>
                      </div>
                    )}
                </div>
              )
            })}
          </div>
        )
      })}
      <div className="white-card mb-0">
        <div className="pad">
          <div className="white-card border-only mb-0">
            <h6 className="sub-heading heading-blue">Fare Details</h6>
            <div className="pad-sm-sm">
              <div className="table-responsive">
                <table className="table table-borderless mb-0">
                  {(item.fare || item.fare) &&
                    <tbody>

                      <tr>
                        <td>
                          <p className="label">Base Fare</p>
                        </td>
                        <td className="text-right">
                          {curreny}  &nbsp;
                        {/* {item.fareContent.fare.flight_adult_base_price && item.fareContent.fare.flight_adult_base_price} */}
                          <NumberFormat value={item.fare.flight_base_price && item.fare.flight_base_price.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="label">Surcharges</p>
                        </td>
                        <td className="text-right">{curreny} &nbsp;
                          <NumberFormat value={item.fare.flight_total_tax && item.fare.flight_total_tax.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </td>
                      </tr>
                      <tr>
                        <td>Total (1 Adults)</td>
                        <td className="text-right">{curreny} &nbsp;
                        <NumberFormat value={item.fare.flight_total_price && item.fare.flight_total_price.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </td>
                      </tr>
                      {/* <tr>
                        <td colSpan="2">Fare Rules</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="white-card dark-grey mb-0">
                            <div className="pad">
                              <p className="title">BLR - BOM</p>
                              <p className="label">Time</p>
                              <p className="title">24 hrs - 365 days</p>
                              <p className="label">Cancelation Fees</p>
                              <p className="title">
                                Adult: $ 16,065.00* + $ 649*
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr> */}
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
          {(currentItem && !currentItem.currentOrderNo && currentItem.STATUS === "Approved") ?
            <div>
              {/* <h6 className="sub-heading mt-4">Downloads</h6>
              <p className="mb-3"><a href="/assets/images/bg-img.jpg" download><i className="material-icons fs-18 mr-3">
                cloud_download
              </i> Boarding_pass.pdf</a></p>
              <p><a href="/assets/images/bg-img.jpg" download><i className="material-icons fs-18 mr-3">
                cloud_download
              </i> Meal_voucher.pdf</a></p> */}
            </div>
            : ""
          }
        </div>
      </div>
    </div>
  );
};
export default FlightCartComboDetail;
