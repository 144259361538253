import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import { cashadvanceService } from '../../../_services/cashadvance.service';
import { expenseReport } from "../../../_services/expenseReport";
import CreateDynamicFormModal from './component/createDynamicFormModal';
import EditCashadvanceFormModal from './component/editCashadvanceFormModal';
import SimpleReactValidator from "simple-react-validator";
import debounce from 'src/_components/common/debounce';
import CashAdvnaceDetailModal from './component/cashAdvnaceDetailModal';
import Loader from 'src/_components/common/Loader';
import NumberFormat from 'react-number-format';
import { getValue, setValue } from "src/_components/common/lodsh";
import { getConnectedList } from "src/_services";

class CashAdvancePage extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      companyId: localStorage["440a28"],
      cashAdvanceName: "",
      cashAdvanceAmount: "",
      purpose: "",
      comment: "",
      baseCurrency: '',
      currencyName: "",
      currencyId: "",
      currencyList: [],
      allAdvanceRequest: [],
      pageLimit: 10,
      pageNumber: 1,
      totalCount: "",
      cashAdvanceField: [],
      error: [],
      headerName: [],
      editCashAdvance: [],
      editCashAdvanceId: '',
      search: '',
      updateValidationStatus: true,
      isCashAdvanceSubmit: false,
      isLoading: true,
      isEditLoading: true,
      userPermission: 'employee-role',
      connectedListData: [],
      cashAdvnaceDetailData: [],
      isRecallClikced: false,
      search: [],
      loading: []
    };
  }

  componentWillUnmount() {
    this.state = {
      search: [],
      loading: []
    }
  }
  componentDidMount() {
    this.getAddExpenseFormField();
    this.getCashAdvanceFormList();
  }
  shouldComponentUpdate(nextProps, nextState) {
    //return this.state.nextState !== nextState;
    return true;
  }

  getCashAdvanceFormList = async () => {
    let companyId = this.state.companyId;
    let pageLimit = this.state.pageLimit;
    let pageNumber = this.state.pageNumber;
    let search = this.state.search;
    this.setState({ isLoading: true });
    let response = await cashadvanceService.getCashAdvanceFormList(companyId, pageNumber, pageLimit, search);
    if (response.count > 0) {

      this.setState({
        isLoading: false,
        totalCount: response.count,
        baseCurrency: response.result.baseCurrency,
        allAdvanceRequest: response.result.data
      });
    } else {
      this.setState({
        totalCount: 0,
        isLoading: false,
        allAdvanceRequest: []
      });
    }
  }

  editCashAdvanceFormList = async (fieldId) => {
    this.setState({ editCashAdvanceId: fieldId, isEditLoading: true });
    let response = await cashadvanceService.editCashAdvanceFormList(fieldId, this.state.companyId);
    if (response && response.result && response.result.data && response.result.data.data) {
      let newConnectedListDate = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.result.data.data, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== '';
      });
      dynamicFeilds.forEach((fields) => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField ? fields.multiSelectParentField : '',
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          fieldValue: fields.fieldValue
        });
      })
      this.setState({ connectedListData: newConnectedListDate });
      newConnectedListDate && newConnectedListDate.forEach(async (fields) => {
        if (fields.fieldLevel === 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.id && listData.fieldLevel === newFieldLevel);
          if (existConnected.length > 0 && fields.fieldValue !== undefined && fields.fieldValue !== '') {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(companyId, fields.fieldValue);
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.multiSelectParentField && listData.fieldLevel === newFieldLevel);
          if (existConnected.length > 0 && fields.fieldValue !== undefined && fields.fieldValue !== '') {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(companyId, fields.fieldValue);
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }
      });

      this.setState({ editCashAdvance: response.result.data.data });

      let formFields = [];
      let fieldsObjects = [];
      response.result.data.data.map(formField => {
        let fieldName = formField.fieldName;
        if (formField.dataType === "date") {
          let todayDate = formField.fieldValue;
          this.setState({ [fieldName]: todayDate });
        } else if (formField.dataType === "checkbox") {
          this.setState({ [fieldName]: formField.fieldValue });
        } else {
          this.setState({ [fieldName]: formField.fieldValue });
        }
        formFields.push(formField.fieldName);
        fieldsObjects.push(formField);
      });
      this.setState({ formFields: formFields, fieldsObject: fieldsObjects, isEditLoading: false });
    } else {
      this.setState({ isEditLoading: false })
    }
  };

  onPerPageLimit = async (perPage) => {
    this.setState({ pageNumber: 1, isLoading: true, });
    this.setState({ pageLimit: perPage });
    let companyId = this.state.companyId;
    let pageLimit = perPage;
    let pageNumber = 1;
    let search = this.state.search;
    let response = await cashadvanceService.getCashAdvanceFormList(companyId, pageNumber, pageLimit, search);
    if (response) {
      this.setState({
        isLoading: false,
        totalCount: response.count,
        allAdvanceRequest: response.result.data
      });
    } else {
      this.setState({
        isLoading: false,
        totalCount: 0,
        allAdvanceRequest: []
      });
    }
  };

  cashAdvanceNameHandler = event => {
    this.setState({ cashAdvanceName: event.target.value });
  };

  cashAdvanceAmountHandler = event => {
    this.setState({ cashAdvanceAmount: event.target.value });
  };

  onPurposeHandler = event => {
    this.setState({ purpose: event.target.value });
  };

  onCommentHandler = event => {
    this.setState({ comment: event.target.value });
  };

  getAddExpenseFormField = async () => {
    this.setState({ isEditLoading: true });
    let response = await cashadvanceService.getCashAdvanceForm();
    if (response && response.headerForm && response.headerForm) {
      await (getValue(response, "headerForm.result", [])).forEach(async item => {
        if (item.connectedListId) {
          let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp, "count", 0);
          item.connectedListData.listManagementData = resp.result;
          item.search = ''
        }
        this.setState({ expenseForm: response.result })
      })
    }
    if (response && response.headerForm && response.headerForm) {
      let newConnectedListDate = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.headerForm.result, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== '';
      });
      dynamicFeilds.forEach((fields) => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField ? fields.multiSelectParentField : '',
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.defaultValue ? fields.defaultValue : ''
        });
      })
      this.setState({ connectedListData: newConnectedListDate });

      newConnectedListDate && newConnectedListDate.forEach(async (fields) => {
        if (fields.fieldLevel === 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.id && listData.fieldLevel === newFieldLevel);
          if (existConnected.length > 0 && fields.prevFieldId !== undefined && fields.prevFieldId !== '') {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(companyId, fields.prevFieldId);
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 2) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.multiSelectParentField && listData.fieldLevel === newFieldLevel);
          if (existConnected.length > 0 && fields.prevFieldId !== undefined && fields.prevFieldId !== '') {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(companyId, fields.prevFieldId);
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }
      });

      this.setState({ cashAdvanceField: response.headerForm.result });
      let formFields = [];
      let fieldsObjects = [];
      response.headerForm.result.map(async (formField) => {
        let fieldName = formField.fieldName;
        if (formField.dataType === "date") {
          let todayDate = new Date();
          todayDate = moment(todayDate).format("DD MMM YYYY");
          if (formField.isDefaultValue) {
            this.setState({ [fieldName]: moment(formField.defaultValue).format("DD MMM YYYY hh:mm a") });
          } else {
            this.setState({ [fieldName]: todayDate });
          }
        } else if (formField.dataType === "checkbox") {
          if (formField.isDefaultValue) {
            this.setState({ [fieldName]: formField.defaultValue });
          } else {
            this.setState({ [fieldName]: false });
          }
        } else {
          if (formField.isDefaultValue) {
            this.setState({ [fieldName]: formField.defaultValue });
          } else {
            this.setState({ [fieldName]: "" });
          }
        }
        formFields.push(formField.fieldName);
        fieldsObjects.push(formField);
      });
      this.setState({ formFields: formFields, fieldsObject: fieldsObjects, isEditLoading: false });
    } else {
      this.setState({ isEditLoading: false });
    }
  };
  onSubmitHandler = async () => {
    if (this.validator.allValid()) {
      this.setState({ isCashAdvanceSubmit: true });
      let fieldsObjects = this.state.fieldsObject;
      let index = 0;
      for (const key of this.state.formFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          fieldsObjects[index]["fieldValue"] = this.state[key] ? this.state[key].value : "";
        } else {
          fieldsObjects[index]["fieldValue"] = this.state[key];
        }
        index++;
      }

      fieldsObjects.forEach((item) => {
        if (item.dataType === "connected_list") {
          if (item.fieldValue) {
            let data = getValue(item, "connectedListData.listManagementData", []).filter(resp => { return resp.id === item.fieldValue });
            setValue(item, "connectedListData.listManagementData", data)
          }
        }
      })
      let updateEntryForm = {
        companyId: this.state.companyId,
        data: fieldsObjects
      };


      let response = await cashadvanceService.createCashAdvanceForm(updateEntryForm);
      if (response) {
        $('.close').click();
        this.getCashAdvanceFormList();
        toast.success(response.message);
        this.setState({ isCashAdvanceSubmit: false })
      }
    } else {
      this.validator.showMessages();
      toast.error('Please Validate all the mandatory fields');
      this.forceUpdate();
    }
  };
  onCancelClickHandler = () => {
    this.props.history.push("./details");
  };

  onClickHandler = async (fieldName, conId, fieldId, fieldLevel, assignKey) => {

    this.state.search[assignKey] = '';
    this.setState({ search: this.state.search })

    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter((listData) => listData.multiSelectParentField === conId && listData.fieldLevel === newFieldLevel);
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(companyId, fieldId);
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter((listData) => listData.multiSelectParentField === conId && listData.fieldLevel === newFieldLevel);
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(companyId, fieldId);
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onChangeHandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };
  onDatePickerHandler = (value, fieldName) => {
    this.setState({ [fieldName]: moment(value).format("DD MMM YYYY hh:mm a") });
  };
  onCheckboxHandler = fieldName => {
    this.setState({ [fieldName]: !this.state[fieldName] });
  };
  onIntegerHandler = (event, fieldName) => {
    this.setState({ [fieldName]: parseInt(event.target.value) });
  };

  onSearchTextHandler = async (event, fieldName) => {
    this.setState({ closeDropdown: true });
    let search = event.target.value;
    this.setState({ [fieldName]: search })
    let response = await expenseReport.getAllLocationsName(search);
    let locationNames = [];
    if (response) {
      (getValue(response, "result", [])).forEach((list) => {
        locationNames.push({ label: list.name, value: list.id, fieldName: 'search' + fieldName, country: list.countryId.name });
      });
      this.setState({ ['search' + fieldName]: locationNames });
    } else {
      this.setState({ ['search' + fieldName]: [] });
    }

  }

  onUpdateHandler = async () => {
    if (this.validator.allValid()) {
      this.setState({ isCashAdvanceSubmit: true });
      let fieldsObjects = this.state.fieldsObject;
      let index = 0;
      for (const key of this.state.formFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          fieldsObjects[index]["fieldValue"] = this.state[key] ? this.state[key].value : "";
        } else {
          fieldsObjects[index]["fieldValue"] = this.state[key];
        }
        index++;
      }
      let updateEntryForm = {
        companyId: this.state.companyId,
        data: fieldsObjects
      };
      let editCashAdvanceId = this.state.editCashAdvanceId;
      let response = await cashadvanceService.updateCashAdvanceForm(updateEntryForm, editCashAdvanceId);
      if (response) {
        $('.close').click();
        this.getCashAdvanceFormList();
        toast.success(response.message);
        this.setState({ isCashAdvanceSubmit: false });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      toast.error('Please Validate all the mandatory fields');
    }
  }
  onPageChangeHandler = async (page) => {
    this.setState({ pageNumber: page, isLoading: true });
    let companyId = this.state.companyId;
    let pageLimit = this.state.pageLimit;
    let pageNumber = page;
    let search = this.state.search;
    let response = await cashadvanceService.getCashAdvanceFormList(companyId, pageNumber, pageLimit, search);
    if (response) {
      this.setState({
        isLoading: false,
        totalCount: response.count,
        allAdvanceRequest: response.result.data,
        headerName: response.result.data[0].data
      });
    } else {
      this.setState({
        isLoading: false,
        totalCount: 0,
        allAdvanceRequest: [],
        headerName: []
      });
    }
  }

  onNewCashAdvanceHandler = () => {
    this.getAddExpenseFormField();
  }

  onLocationChangekHandler = (event, fieldName) => {
    this.setState({ [fieldName]: event });
  }

  loadOptions = async (newValue) => {
    return expenseReport.getAllLocationsName(newValue).then((data) => {
      return data.result.map(list => {
        return {
          label: list.name + ',' + list.countryId.name,
          value: list.id
        };
      });
    })
  }
  onRecallHandler = async (cashAdvanceId) => {
    this.setState({ isRecallClikced: true });
    let response = await cashadvanceService.patchRecallCashAdvance(cashAdvanceId);
    if (response) {
      $('.close').click();
      this.setState({ isRecallClikced: false });
      toast.success(response.message);
      this.getCashAdvanceFormList();
    } else {
      this.setState({ isRecallClikced: false });
    }
  }
  getCashAdvanceDetailsById = async (cashAdvanceId) => {
    this.setState({ isCashAdvnaceDetailLoading: true });
    let response = await expenseReport.getCashAdvanceDetails(cashAdvanceId);
    if (response) {
      this.setState({ cashAdvnaceDetailData: response.result, isCashAdvnaceDetailLoading: false });
    } else {
      this.setState({ isCashAdvnaceDetailLoading: false });
    }
  }
  onSearchHandler = debounce(async (search) => {
    this.setState({ search: search });
    let companyId = this.state.companyId;
    let pageLimit = this.state.pageLimit;
    let pageNumber = 1;
    let response = await cashadvanceService.getCashAdvanceFormList(companyId, pageNumber, pageLimit, search);
    if (response) {
      this.setState({
        isLoading: false,
        totalCount: response.count,
        allAdvanceRequest: response.result.data
      });
    } else {
      this.setState({
        isLoading: false,
        totalCount: 0,
        allAdvanceRequest: []
      });
    }
  });
  getCurrencyFromUserDetails = (item) => {
    if (item && item.userDeatils && item.userDeatils.fieldData) {
      let reimbursementCurrency = item.userDeatils.fieldData.find(item => item.columnName === 'REIMBURSEMENT_CURRENCY');
      if (reimbursementCurrency != null) {
        return reimbursementCurrency.fieldValue + ' ' + (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.baseTotalAmount))
      } else {
        return null;
      }
    }
  }
  getCurrencyFromUserDetailsWithFullData = (item) => {
    if (item && item.CASH_DATA && item.CASH_DATA.data && item.CASH_DATA.data.userDeatils && item.CASH_DATA.data.userDeatils.fieldData) {
      let reimbursementCurrency = item.CASH_DATA.data.userDeatils.fieldData.find(item => item.columnName === 'REIMBURSEMENT_CURRENCY');
      if (reimbursementCurrency != null) {
        return reimbursementCurrency.fieldValue + ' ' + (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.CASH_DATA.data.baseTotalAmount))
      } else {
        return null;
      }
    }
  }
  handleScroll = async (e, id, assignKey, page, count, totalLength, search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    setValue(this.state, "clientHeight", e.target.scrollHeight)
    if (bottom) {
      this.setState({
        already: true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
      })
      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {
          let mergeOld = getValue(this.state.cashAdvanceField[assignKey], "connectedListData.listManagementData", []).concat(resp.result)
          setValue(this.state.cashAdvanceField[assignKey], "connectedListData.listManagementData", mergeOld)
          setValue(this.state.cashAdvanceField[assignKey], "page", page + 1)
          this.setState({
            ...this.state
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }
    }
  }
  handleSearchManage = async (search, id, assignKey) => {

    this.state.loading[assignKey] = true;
    this.state.search[assignKey] = search;
    this.setState({ search: this.state.search }, () => {
      $(`#valueConnect${assignKey}`).focus();
    })
    let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
    if (resp) {
      setValue(this.state.cashAdvanceField[assignKey], "connectedListData.listManagementData", resp.result)
    } else {
      setValue(this.state.cashAdvanceField[assignKey], "connectedListData.listManagementData", [])
    }
    setValue(this.state.cashAdvanceField[assignKey], "search", search)
    this.state.loading[assignKey] = false;
    this.setState({
      ...this.state
    })
    $(`#valueConnect${assignKey}`).focus();
    $(`#valueConnect${assignKey}`).attr('value', search);
    $(`#connect${assignKey}`).addClass("show")
  }

  render() {

    return (
      <div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-9">
                  <div className="btn-group-margin">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#cashAdvanceModal"
                      onClick={this.onNewCashAdvanceHandler}
                    >
                      NEW CASH ADVANCE
                    </button>
                    {/* <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            Select
              </button>
                          <div className="dropdown-menu">
                            <button
                              type="button"
                              className="dropdown-item"
                            >
                              Pending Submission
                </button>
                            <button
                              type="button"
                              className="dropdown-item"
                            >
                              Rejected
                </button>
                            <button
                              type="button"
                              className="dropdown-item"
                            >
                              Approved
                </button>
                          </div>
                        </div>    */}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="search-custom">
                    <input
                      type="text"
                      name="search"
                      onChange={(event) => this.onSearchHandler(event.target.value)}
                      className="form-control"
                      placeholder="Search"
                    />
                    <span><i className="material-icons">search</i></span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>CASH ADVANCE<br /> NAME</th>
                    <th>RequestId</th>
                    <th>REQUESTED<br /> DATE</th>
                    {/* <th>STATUS</th> */}
                    <th>AMOUNT<br /> REQUESTED</th>
                    <th>Approved <br />Amount</th>
                    <th>Issued<br /> Amount</th>
                    <th>Assigned<br /> Amount</th>
                    <th>Unassigned<br /> Amount</th>
                    <th>EXCHANGE<br /> RATE</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                {!this.state.isLoading ?
                  <tbody>
                    {this.state.allAdvanceRequest && this.state.allAdvanceRequest.map((result, index) => {
                      let theadArray = [];
                      if (Object.keys(result).length !== 0) {
                        if (!(result && result.CASH_DATA && result.CASH_DATA.data)) {
                          theadArray = result.data.map((header) => header.columnName);
                          let CASH_ADVANCE_NAME = theadArray.indexOf('CASH_ADVANCE_NAME');
                          let AMOUNT = theadArray.indexOf("AMOUNT");
                          let CURRENCY = theadArray.indexOf("CURRENCY");
                          CURRENCY = CURRENCY >= 0 && result.data[CURRENCY].connectedListData.listManagementData.filter((list) => list.id === result.data[CURRENCY].fieldValue);
                          return (
                            <tr key={result.id}  >
                              <td>
                                {/* <p>{result.CASH_DATA.data && result.CASH_DATA.data.cashAdvanceId}</p> */}
                                <button className="btn btn-nothing text-left text-primary" >
                                  {result.cashAdvanceId ? result.cashAdvanceId : '-'}
                                </button>
                                <p>{result.data[CASH_ADVANCE_NAME] && result.data[CASH_ADVANCE_NAME].fieldValue}</p>
                              </td>
                              <td>{result.requestId ? result.requestId : '-'}</td>
                              <td>{result.createdAt && moment(result.createdAt).format("DD MMM YYYY")}</td>
                              {/* <td>{result.status}</td> */}

                              <td>
                                {CURRENCY.length > 0 && CURRENCY[0].itemCode}&nbsp;
                              {result.data[AMOUNT] && (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.data[AMOUNT].fieldValue))}

                                {result.data[AMOUNT] && result.baseTotalAmount && result.baseTotalAmount !== result.data[AMOUNT].fieldValue &&
                                  <p>
                                    {this.getCurrencyFromUserDetails(result)}
                                  </p>
                                }
                                {/* <p>
                                {CURRENCY.length > 0 && CURRENCY[0].itemCode !== this.state.baseCurrency ? this.state.baseCurrency + ' ' + (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.baseTotalAmount)) : ''}
                              </p> */}
                              </td>

                              <td>
                                -
                            </td>

                              <td>
                                {result.issuedCurrency && result.issuedCurrency + " "}
                                {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.amountIssued ? result.amountIssued : 0))}
                              </td>

                              <td>
                                {result?.CASH_DATA?.data?.issuedCurrency || result?.issuedCurrency} {" "}
                                {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.takenAmount ? result.takenAmount : 0))}
                              </td>

                              <td> {result.issuedCurrency && result.issuedCurrency + " "}
                                {result.issuedCurrency ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.balance ? result.balance : 0)) : '-'}
                              </td>
                              <td><b>
                                {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.exchangeRate))}</b></td>
                              <td>
                                {result.status !== 'Approved'
                                  ? <>
                                    {result.status === 'Pending Submission' ?
                                      <button
                                        onClick={e => this.editCashAdvanceFormList(result.id)}
                                        className="btn"
                                        data-toggle="modal"
                                        data-target="#editcashAdvanceModal"
                                      >Edit
                              </button>
                                      :
                                      (result.status === 'Issued')
                                        ? <button className="btn btn-nothing text-success-light"><i className="material-icons fs-12">check_circle</i> {result.status}</button>
                                        : (result.status === 'Reject') ?
                                          <button className="btn btn-nothing text-danger"><i className="material-icons fs-12">thumb_down</i> {result.status}</button>
                                          : <button className="btn btn-nothing">{result.status}</button>
                                    }
                                  </>
                                  : <button className="btn btn-nothing text-success"><i className="material-icons fs-12">thumb_up</i> {result.status}</button>
                                }
                              </td>
                            </tr>
                          )
                        }
                        else {
                          let checkApprovedDataAndIsuued = (cashAdvanceResult, workflowSteps, workflowCurrent) => {
                            let checkStatus = () => {
                              return cashAdvanceResult.current.STATUS ? cashAdvanceResult.current.STATUS : false;
                            }
                            let checkWorkflowSteps = () => {
                              return workflowCurrent && workflowCurrent.currentOrderNo ? workflowSteps[workflowCurrent.currentOrderNo] && workflowSteps[workflowCurrent.currentOrderNo].intialStatus ? workflowSteps[workflowCurrent.currentOrderNo].intialStatus : "" : ""
                            }
                            let getCashDataStatus = () => {
                              return cashAdvanceResult.CASH_DATA && cashAdvanceResult.CASH_DATA.data ? cashAdvanceResult.CASH_DATA.data.status : 'Approved';
                            }

                            return checkStatus() ? getCashDataStatus() : checkWorkflowSteps();
                          }
                          result['data'] = result.CASH_DATA.data.data;
                          theadArray = result.data.map((header) => header.columnName);
                          let workflowCurrent = result.current;
                          let workflowSteps = result.info && result.info.steps ? result.info.steps : {}
                          let workFlowStepIntialStatus = checkApprovedDataAndIsuued(result, workflowSteps, workflowCurrent);
                          let CASH_ADVANCE_NAME = theadArray.indexOf('CASH_ADVANCE_NAME');
                          let AMOUNT = theadArray.indexOf("AMOUNT");
                          let CURRENCY = theadArray.indexOf("CURRENCY");
                          CURRENCY = CURRENCY >= 0 && result.data[CURRENCY].connectedListData.listManagementData.filter((list) => list.id === result.data[CURRENCY].fieldValue);
                          let issuedCurrency = result.CASH_DATA.data.issuedCurrency && result.CASH_DATA.data.issuedCurrency;
                          const requestedAmountCurrencyValue = result.CASH_DATA.data.requestedAmountCurrencyValue && result.CASH_DATA.data.requestedAmountCurrencyValue;

                          return (
                            <tr key={result.id + index}  >
                              <td onClick={() => this.getCashAdvanceDetailsById(result.id)} >

                                <button className="btn btn-nothing text-primary text-left"
                                  data-toggle="modal" data-target="#newcashAdvanceModal"
                                >{result.CASH_DATA.data.cashAdvanceId}
                                </button>
                                <p>{result.data[CASH_ADVANCE_NAME] && result.data[CASH_ADVANCE_NAME].fieldValue}</p>
                              </td>
                              <td> {'-'}</td>
                              <td>{result.createdAt && moment(result.createdAt).format("DD MMM YYYY hh:mm a")}</td>
                              {/* <td>{workFlowStepIntialStatus}</td> */}
                              <td>
                                {/* {CURRENCY.length > 0 && CURRENCY[0].itemCode} */}

                                {requestedAmountCurrencyValue}

                                {" "}
                                {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.data[AMOUNT] ? result.data[AMOUNT].fieldValue : 0))}

                                {result.CASH_DATA && result.CASH_DATA.data && result.CASH_DATA.data.baseTotalAmount !== result.data[AMOUNT].fieldValue &&
                                  <p>
                                    {this.getCurrencyFromUserDetailsWithFullData(result)}
                                  </p>
                                }
                                {/* <p>
                                {CURRENCY.length > 0 && CURRENCY[0].itemCode !== this.state.baseCurrency && this.state.baseCurrency + ' ' +
                                  (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.CASH_DATA.data.baseTotalAmount))}

                              </p> */}
                              </td>

                              <td>{requestedAmountCurrencyValue && requestedAmountCurrencyValue}{" "}
                                {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.CASH_DATA.data.amountApproved ? result.CASH_DATA.data.amountApproved : 0))}
                              </td>


                              <td>{issuedCurrency && issuedCurrency}{" "}
                                {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(issuedCurrency ? result.CASH_DATA.data.amountIssued : 0))}
                              </td>

                              <td>
                                {/* {CURRENCY.length>0 && CURRENCY[0].itemCode}{" "} */}
                                {issuedCurrency && issuedCurrency}{" "}
                                {(new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.CASH_DATA.data.takenAmount ? result.CASH_DATA.data.takenAmount : 0))}
                              </td>
                              <td> {issuedCurrency && issuedCurrency}{" "}
                                {issuedCurrency && result.CASH_DATA.data.balance > 0 ? (new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.CASH_DATA.data.balance)) : '-'}
                              </td>
                              <td>
                                <b> {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(result.CASH_DATA.data.exchangeRate ? result.CASH_DATA.data.exchangeRate : 0)}</b>
                              </td>
                              {!this.state.isRecallClikced
                                ? <td>

                                  {workFlowStepIntialStatus && (workFlowStepIntialStatus === "Approved") ?
                                    <button className="btn btn-nothing text-success"><i className="material-icons fs-12">thumb_up</i> {workFlowStepIntialStatus}</button>
                                    : (workFlowStepIntialStatus === "Pending Submission")
                                      ? <button className="btn btn-nothing"><i className="material-icons fs-12">
                                        error
                          </i> {workFlowStepIntialStatus}</button>
                                      : (workFlowStepIntialStatus === "Pending Approval")
                                        ? <button className="btn btn-nothing"><i className="material-icons fs-12">
                                          hourglass_empty
                            </i> {workFlowStepIntialStatus}</button>
                                        : (workFlowStepIntialStatus === "Issued")
                                          ? <button className="btn btn-nothing text-success-light"><i className="material-icons fs-12">
                                            check_circle
                              </i> {workFlowStepIntialStatus}</button>
                                          : (workFlowStepIntialStatus === "Reject") ?
                                            <button className="btn btn-nothing text-danger"><i className="material-icons fs-12">thumb_down</i> {workFlowStepIntialStatus}</button>
                                            : <button className="btn btn-nothing">{workFlowStepIntialStatus}</button>
                                  }
                                  {/* {workFlowStepIntialStatus !== 'Approved'? result.current.actionButton&&result.current.actionButton.map((action) =>
                            <button key={action.key}
                                onClick={() => this.onRecallHandler(result.id)}
                                className="btn"
                            >{action.button}
                            </button>
                          ):workFlowStepIntialStatus} */}

                                </td>
                                : <td>
                                  <button
                                    className="btn hover-v">
                                    Please Wait...
                                </button>
                                </td>
                              }
                            </tr>
                          )
                        }
                      }
                    })}
                    {
                      this.state.totalCount <= 0 &&
                      <tr>
                        <td className="text-center" colSpan={9}>
                          No Records Found
                        </td>
                      </tr>
                    }
                  </tbody>
                  : <tbody>
                    <tr>
                      <td colSpan="9">
                        <Loader />
                      </td>
                    </tr>
                  </tbody>
                }
              </table>
            </div>
            {this.state.totalCount > 0 ?
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            {this.state.pageLimit}
                          </button>
                          <div className="dropdown-menu">
                            <button
                              onClick={e => this.onPerPageLimit(10)}
                              className="dropdown-item"
                            >
                              10
                              </button>
                            <button
                              onClick={e => this.onPerPageLimit(20)}
                              className="dropdown-item"
                            >
                              20
                              </button>
                            <button
                              onClick={e => this.onPerPageLimit(30)}
                              className="dropdown-item"
                            >
                              30
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={this.state.pageNumber}
                      itemsCountPerPage={this.state.pageLimit}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={5}
                      onChange={this.onPageChangeHandler}
                    />
                  </div>
                </div>
              </div>
              : null}
          </div>
        </div>
        <div
          className="modal fade"
          id="cashAdvanceModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="cashAdvanceLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">

              <div className="modal-head">
                <button
                  type="button"
                  className="close d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h6 className="modal-subtitle">new</h6>
                <h5 className="modal-title" id="cashAdvanceLabel">
                  Cash Advance
                </h5>
              </div>
              {!this.state.isEditLoading ?
                <div className="modal-middle pm-30">
                  <div className="row">
                    <CreateDynamicFormModal
                      {...this.state}
                      validator={this.validator}
                      cashAdvanceField={this.state.cashAdvanceField}
                      onClickHandler={this.onClickHandler}
                      onChangeHandler={this.onChangeHandler}
                      onDatePickerHandler={this.onDatePickerHandler}
                      onCheckboxHandler={this.onCheckboxHandler}
                      onIntegerHandler={this.onIntegerHandler}
                      userPermission={this.state.userPermission}
                      onConnectedListkHandler={this.onConnectedListkHandler}
                      onLocationChangekHandler={this.onLocationChangekHandler}
                      loadOptions={this.loadOptions}
                      handleScroll={this.handleScroll}
                      handleSearchManage={this.handleSearchManage}
                    />
                  </div>
                  <div className="modal-last btn-group-margin">
                    <button
                      type="button"
                      id="submit"
                      className="btn btn-badge"
                      data-dismiss="modal"
                    >
                      Cancel
                  </button>
                    {this.state.isCashAdvanceSubmit ?
                      <button
                        type="button"
                        className="btn"
                        aria-label="Close"
                      >
                        Please Wait...
                      </button>
                      : <button
                        type="button"
                        className="btn"
                        aria-label="Close"
                        disabled={this.state.cashAdvanceField.length <= 0}
                        onClick={this.onSubmitHandler}
                      >
                        Submit
                      </button>}

                  </div>

                </div>
                : <div className="text-center m-5 p-5">
                  <div className="mt-5 form-holder pt-3">
                    <div className="loader-css"></div>
                    <h5 className="fw-sbold pb-5">Please wait...</h5>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>


        <div
          className="modal fade"
          id="editcashAdvanceModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="cashAdvanceLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <button
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h6 className="modal-subtitle">new</h6>
                <h5 className="modal-title" id="cashAdvanceLabel">
                  Cash Advance
                </h5>
              </div>
              {!this.state.isEditLoading ?
                <div className="modal-middle pm-30">
                  <div className="row">
                    <EditCashadvanceFormModal
                      {...this.state}
                      editableOrNot={true}
                      validator={this.validator}
                      cashAdvanceField={this.state.editCashAdvance}
                      onClickHandler={this.onClickHandler}
                      onChangeHandler={this.onChangeHandler}
                      onDatePickerHandler={this.onDatePickerHandler}
                      onCheckboxHandler={this.onCheckboxHandler}
                      onIntegerHandler={this.onIntegerHandler}
                      userPermission={this.state.userPermission}
                      onConnectedListkHandler={this.onConnectedListkHandler}
                      onLocationChangekHandler={this.onLocationChangekHandler}
                      loadOptions={this.loadOptions}
                    />
                  </div>
                  {!this.state.isCashAdvanceSubmit ?
                    <div className="modal-last btn-group-margin">
                      <button
                        type="button"
                        id="submit"
                        className="btn btn-badge"
                        data-dismiss="modal"
                      >
                        Cancel
                  </button>

                      <button
                        className="btn"
                        onClick={this.onUpdateHandler}
                      >
                        Submit

                    </button>
                    </div>
                    :
                    <div className="modal-last btn-group-margin">
                      <button
                        className="btn">
                        Please Wait...
                        </button>
                    </div>
                  }
                </div>
                : <div className="text-center m-5 p-5">
                  <div className="mt-5 form-holder pt-3">
                    <div className="loader-css"></div>
                    <h5 className="fw-sbold pb-5">Please wait...</h5>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="commentsModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="commentsLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content border-0">
              <div className="modal-head">
                <h6 className="modal-subtitle">VIEW</h6>
                <h5 className="modal-title" id="commentsLabel">
                  Cash Advance - Approver flow
                      </h5>
              </div>
              <div className="modal-middle modal-px-30 pt-3">
                <div className="border-bottom py-3">
                  <div className="float-left user-name">
                    <img
                      src="/assets/images/booking/user_11.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="float-left ml-3">
                    <p className="mb-0">
                      <b>Jonathan Cook</b>
                    </p>
                    <div className="float-left">
                      <p className="muted-p mb-0">
                        Level 1
                            </p>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="border-bottom py-3">
                  <div className="float-left user-name">
                    <img
                      src="/assets/images/booking/user_11.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="float-left ml-3">
                    <p className="mb-0">
                      <b>Louisa Boyd</b>
                    </p>
                    <div className="float-left">
                      <p className="muted-p mb-0">Level 2</p>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className=" py-3">
                  <div className="float-left user-name">
                    <img
                      src="/assets/images/booking/user_11.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="float-left ml-3">
                    <p className="mb-0">
                      <b>Doris Clark</b>
                    </p>
                    <div className="float-left">
                      <p className="muted-p mb-0">Level 3</p>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="modal-last btn-group-margin pm-30">
                <button
                  type="button"
                  className="btn btn-badge"
                  data-dismiss="modal"
                >
                  Okay
                      </button>
              </div>
            </div>
          </div>
        </div>


        <div
          className="modal fade"
          id="cashAdvncApprovalFlowModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="approvalFlowLable"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <h6 className="modal-subtitle">New</h6>
                <h5 className="modal-title" id="approvalFlowLable">
                  Cash Advance - Approver Flow
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round" />
                  </label>
                  <span>Edit Approvers</span>
                </div>
                <div className="form-group custom labelup">
                  <label>Level 1 Approver</label>
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder="Approver"
                  />
                  <span className="icon-custom">
                    <i className="material-icons">search</i>
                  </span>
                </div>
                <div className="form-group custom">
                  <input
                    type="text"
                    placeholder="&nbsp;"
                    className="form-control"
                  />
                  <label>Level 2 Manager</label>
                </div>
                <div className="form-group custom">
                  <input
                    type="text"
                    placeholder="&nbsp;"
                    className="form-control"
                  />
                  <label>Level 3 manager</label>
                </div>

                <div className="modal-last btn-group-margin">
                  <button
                    type="button"
                    className="btn btn-badge"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="button" className="btn  ">
                    Save

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="newcashAdvanceModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="newcashAdvancLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            {this.state.cashAdvnaceDetailData && this.state.cashAdvnaceDetailData.CASH_DATA &&
              <CashAdvnaceDetailModal
                cashAdvnaceDetailData={this.state.cashAdvnaceDetailData}
                onRecallHandler={this.onRecallHandler}
                isRecallClikced={this.state.isRecallClikced}
              />
            }
          </div>
        </div>
        <div
          className="modal fade"
          id="cancelcashAdvanceModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="cancelcashAdvancLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <h6 className="modal-subtitle">New</h6>
                <h5 className="modal-title" id="cancelcashAdvancLabel">
                  Cancel - Cash Advance Request
                </h5>
              </div>
              <div className="modal-middle">
                <div className="pm-30 pb-0">
                  <h3>Are you sure?</h3>
                  <p className="lighter-p mb-0">This action cannot be undone</p>
                </div>

              </div>
              <div className="modal-last btn-group-margin pm-30">
                <button
                  type="button"
                  className="btn btn-badge"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button className="btn  ">Yes</button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="recallcashAdvanceModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="recallcashAdvanceLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <h6 className="modal-subtitle">New</h6>
                <h5 className="modal-title" id="recallcashAdvanceLabel">
                  Recall - Cash Advance Request
                </h5>
              </div>
              <div className="modal-middle">
                <div className="pm-30 pb-0">
                  <h3>Are you sure?</h3>
                  <p className="lighter-p mb-0">You can resubmit when you are ready!</p>
                </div>

              </div>
              <div className="modal-last btn-group-margin pm-30">
                <button
                  type="button"
                  className="btn btn-badge"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button className="btn  ">Yes</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default CashAdvancePage;
