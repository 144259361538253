import React, { Component } from "react";
import { userService } from "src/_services";
class CompanyNotePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companyId: localStorage["440a28"],
            companyNotes: []
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    componentDidMount() {
        this.getNote();
    }
    getNote = async () => {
        let resp = await userService.getCompanyNote(this.state.companyId);
        if(resp && resp.length)
        this.setState({
            companyNotes: resp.filter(note => note.activeStatus != false)
        })
    }
    render() {
        const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return (
            <div>
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">R</div>
                        <div className="right">
                            <h6 className="heading">Company Note</h6>
                            <p className="text">All the company notes are shown here.</p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="p-padding">
                    <div className="promo-card white comp-note mt-0">
                        <h6 className="heading pt-2">Company Notes</h6>
                        {this.state.companyNotes && this.state.companyNotes.map((note, i) => {
                            return (
                                <div key={i} className="shape-name">
                                    <div className="left date">
                                        <span>{new Date(note.createdAt).getDate()}</span>
                                        <span>{month[new Date(note.createdAt).getMonth()]}</span>
                                    </div>
                                    <div className="right">
                                        <p className="mb-0 text-primary">
                                            <span className={i === 0 ? "new-style" : "d-none"}>New</span> {note.heading}
                                        </p>
                                        <p className="text fw-normal op-less text-grey limit-text less-limit limitflow">
                                            {note.message.replace(/<[^>]*>?/gm, '')}</p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>

                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
export default CompanyNotePage