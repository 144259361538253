import React from "react";
import Select from 'react-select';
import AuditRulesMenu from "../audit-rules-menu";
import Loader from 'src/_components/common/Loader';

const CustomStep1Page = props => {
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">A</div>
          <div className="right">
            <h6 className="heading">Audit Rules</h6>
            <p className="text">Add/Modify Audit Rules.</p>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <AuditRulesMenu
            gotoNextMenu={props.gotoNextMenu}
            auditRule_id={props.auditRuleId}
          />
          <div className="">
            <div className="row">
              <div className="col-xl-4 col-lg-6">
                {props.isLoading ?
                  <Loader /> :
                  <form>
                    <div className="form-group custom labelup textarea">
                      <label>Audit Rule Type <span className="text-danger">*</span></label>
                      <Select
                        value={props.selectedAuditRuleType}
                        onChange={props.onChnageAuditRuleTypeHnadler}
                        options={props.auditRuleType}
                        name="Audit Rule Type"
                        placeholder="Select Type"
                        isDisabled={props.isEdit?props.isEdit:false}
                      />
                      <div className="help-block">
                        {props.validator.message(
                          "Audit Rule Type",
                          props.selectedAuditRuleType ? props.selectedAuditRuleType.value : "",
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group custom">
                      <input
                        type="text"
                        value={props.name}
                        placeholder="&nbsp;"
                        className="form-control"
                        onChange={props.onNameChangeHandler}
                      />
                      <label>Name <span className="text-danger">*</span></label>
                      <div className="help-block">
                        {props.validator.message(
                          "Name",
                          props.name,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group custom labelup textarea">
                      <label>Editable By <span className="text-danger">*</span></label>
                      <Select
                        value={props.editedBy}
                        onChange={props.onEditableByChangeHandler}
                        options={props.editedAppliesDropdown}
                        name="editedBy"
                        isMulti
                        placeholder="Search Groups"
                      />
                      <div className="help-block">
                        {props.validator.message(
                          "Edited By",
                          props.editedBy.length > 0 ? props.editedBy[0].value : "",
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="form-group custom labelup textarea">
                      <label>Applies To <span className="text-danger">*</span></label>
                      <Select
                        value={props.appliesTo}
                        onChange={props.onAppliesToChangeHandler}
                        options={props.editedAppliesDropdown}
                        name="appliesTo"
                        isMulti
                        placeholder="Search Groups"
                      />
                      <div className="help-block">
                        {props.validator.message(
                          "Applies To",
                          props.appliesTo.length > 0 ? props.appliesTo[0].value : '',
                          "required"
                        )}
                      </div>
                    </div>
                    {!props.isSubmitClick ?
                      <div className="btn-group-margin">
                        {props.isEdit ?
                          <button
                            type="button"
                            onClick={props.onUpdateHandler}
                            className="btn btn-border-basic lg-padding"
                          >
                            Next
                          </button>
                          :
                          <button
                            type="button"
                            onClick={props.onSubmitHandler}
                            className="btn btn-border-basic lg-padding"
                          >
                            Next
                        </button>
                        }
                        <button
                          type="button"
                          onClick={props.onClickCancelHandler}
                          className="btn btn-border-basic lg-padding"
                        >
                          Cancel
                    </button>
                      </div>
                      :
                      <div className="btn-group-margin">
                        <button
                          type="button"
                          className="btn btn-border-basic lg-padding"
                        >
                          Please Wait...
                    </button>
                      </div>
                    }
                  </form>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
export default CustomStep1Page;
