import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Forms from "./forms/forms";
class FormsMainPage extends Component {
  componentDidMount() {
    if (
      this.props.location.pathname ===
      "/administration/company/admin-forms/forms"
    ) {
      this.props.history.push("/administration/company/admin-forms/forms/list");
    } else {
      this.props.history.push(this.props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (
      this.props.location.pathname ===
      "/administration/company/admin-forms/forms"
    ) {
      this.props.history.push("/administration/company/admin-forms/forms/list");
    }
  }
  render() {
    return (
      <div className="FFMainPage">
        <Switch>
          <Route
            path="/administration/company/admin-forms/forms/list"
            component={Forms}
          />
        </Switch>
      </div>
    );
  }
}
export default FormsMainPage;
