import React from "react";

const receiptImageModal = props => {
  return (
    <div className="modal-content">
      <div className="modal-head">
        <h6 className="modal-subtitle">New</h6>
        <h5 className="modal-title" id="uploadReceiptsLabel">
          Upload Receipts
        </h5>
      </div>
      <div className="modal-middle pm-30">
        <div className="upload-input text-center">
          <input type="file" onChange={props.onFileBrowse} multiple />
          <div className="py-4 check-box">
            <i className="material-icons">cloud_upload</i>
            <div>Drag and Drop to Upload</div>
          </div>
        </div>
        <div className="show-upload-img">
        {props.imageType !== "application/pdf"
            ? props.browseImage &&
              props.browseImage.length > 0 &&
              props.browseImage.map((image, index) => (
                <div key={index} className="img">
                  <img key={index} alt="" src={image} />
                </div>
              ))
            : props.browseImage &&
              props.browseImage.length > 0 &&
              props.browseImage.map((image, index) => (
                <div key={index} className="img">
                 <img key={index} alt="" src={require("../../../../_assets/images/pdficon.png")} />
                </div>
              ))}
        </div>
      </div>
      <div className="modal-last btn-group-margin pt-0 pm-30">
        <button type="button" className="btn" data-dismiss="modal">
          Cancel
        </button>
        <button
          onClick={props.onFileUpload}
          type="button"
          data-dismiss="modal"
          className="btn"
          disabled={!props.browseImage.length}
        >
          upload
        </button>
      </div>
    </div>
  );
};

export default receiptImageModal;
