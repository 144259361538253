import React from "react";
import MyMapComponentWrapper from "../../../../../common/map-picker";
import { Select, Input } from "../../../../../common/_forms";
const AddUpdateLocationPage = props => {
  const {
    latitude,
    longitude,
    state,
    error,
    updateForm,
    submitForm,
    countries,
    countryName,
    stateList,
    stateName,
    isEdit,
    cityList,
    cityName
  } = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">L</div>
          <div className="right">
            {isEdit === false ? (
              <h6 className="heading">Add Company Location</h6>
            ) : (
              <h6 className="heading">Edit Company Location</h6>
            )}
            <p className="text">Lorem Ipsum is simply dummy</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <form
            onSubmit={
              isEdit === false ? e => submitForm(e) : e => updateForm(e)
            }
            autoComplete="off"
          >
            <div className="row">
              <div className="col-md-6">
                <h5 className="sub-heading">Address Details</h5>
                <div className="form-group custom">
                  <Input
                    label="Location Name"
                    type="text"
                    name="locationName"
                    error={error.message(
                      "locationName",
                      state.locationName,
                      "required|alpha"
                    )}
                    value={state.locationName}
                    controlFunc={e => props.handleChange(e, "locationName")}
                  />
                </div>
                <div className="form-group custom">
                  <input
                    type="text"
                    placeholder="&nbsp;"
                    className="form-control"
                  />
                  <label>Legal Name</label>
                </div>
                <div className="form-group custom">
                  <Input
                    label="Address Line 1"
                    type="text"
                    name="addressLine1"
                    error={error.message(
                      "addressLine 1",
                      state.addressLine1,
                      "required"
                    )}
                    value={state.addressLine1}
                    controlFunc={e => props.handleChange(e, "addressLine1")}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label="Address Line 2"
                    type="text"
                    name="addressLine2"
                    error={error.message(
                      "addressLine 2",
                      state.addressLine2,
                      "required"
                    )}
                    value={state.addressLine2}
                    controlFunc={e => props.handleChange(e, "addressLine2")}
                  />
                </div>
                <div className="form-group custom labelup">
                  <label>{countryName}</label>
                  {countries && (
                    <Select
                      placeholder={countryName}
                      keyName={"itemName"}
                      options={countries}
                      controlFunc={e => props.changeCountry(e, "country")}
                      error={error.message(
                        "country",
                        state.country,
                        "required"
                      )}
                    />
                  )}
                </div>
                <div className="form-group custom labelup">
                  <label>State/Province</label>
                  {stateList && (
                    <Select
                      placeholder={stateName}
                      keyName={"itemName"}
                      options={stateList}
                      controlFunc={e => props.changeState(e, "state")}
                      error={error.message("state", state.state, "required")}
                    />
                  )}
                </div>
                <div className="form-group custom">
                {cityList && (
                    <Select
                      placeholder={cityName}
                      keyName={"itemName"}
                      options={cityList}
                      controlFunc={e => props.changeCity(e, "city")}
                      error={error.message("city", state.city, "required")}
                    />
                  )}
                </div>
                <h5 className="sub-heading">Contact Details</h5>
                <div className="form-group custom">
                  <Input
                    label="Phone Number"
                    type="text"
                    name="phoneNumber"
                    error={error.message(
                      "phoneNumber",
                      state.phoneNumber,
                      "required"
                    )}
                    value={state.phoneNumber}
                    controlFunc={e => props.handleChange(e, "phoneNumber")}
                  />
                </div>
                <div className="form-group custom">
                  <input
                    type="text"
                    placeholder="&nbsp;"
                    className="form-control"
                  />
                  <label>Alternate Phone Number</label>
                </div>
                <div className="form-group custom">
                  <Input
                    label="Email"
                    type="email"
                    name="email"
                    error={error.message(
                      "email",
                      state.email,
                      "required|email"
                    )}
                    value={state.email}
                    controlFunc={e => props.handleChange(e, "email")}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label="Fax Number"
                    type="text"
                    name="faxNumber"
                    error={error.message("faxNumber", state.email, "required")}
                    value={state.faxNumber}
                    controlFunc={e => props.handleChange(e, "faxNumber")}
                  />
                </div>
                {/* <div className="form-group">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                  <span>Allow Delivery/Collection of rental cars</span>
                </div> */}
              </div>
              <div className="col-md-6">
                <h5 className="sub-heading">Get Geo Locations</h5>
                <div className="geo-location">
                  <MyMapComponentWrapper
                    getLocation={props.getLocation}
                    latitude={latitude}
                    longitude={longitude}
                  />
                  <div className="values">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Latitude</label>
                        <h6>{latitude}</h6>
                      </div>
                      <div className="col-md-6">
                        <label>Longitude</label>
                        <h6>{longitude}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 className="sub-heading pt-4">Tax Settings</h5>
                <div className="form-group custom">
                  <Input
                    label="VAT/GST Number"
                    type="text"
                    name="gstNumber"
                    error={error.message(
                      "gstNumber",
                      state.gstNumber,
                      "required"
                    )}
                    value={state.gstNumber}
                    controlFunc={e => props.handleChange(e, "gstNumber")}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label="Tax Percentage"
                    type="text"
                    name="taxPercentage"
                    error={error.message(
                      "taxPercentage",
                      state.taxPercentage,
                      "required|integer"
                    )}
                    value={state.taxPercentage}
                    controlFunc={e => props.handleChange(e, "taxPercentage")}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="btn-group-margin">
                  <button
                    type="submit"
                    className="btn"
                  >
                    Save
                  </button>
                  <button className="btn">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddUpdateLocationPage;
