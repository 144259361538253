import "react-app-polyfill/ie9"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import 'raf/polyfill';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import * as serviceWorker from "./serviceWorker";
import authStore from "./_store/auth.store";
import commonStore from "./_store/common.store";
import userStore from "./_store/user.store";
import menuStore from "./_store/menu.store";
import travelStore from "./_store/travel.store";
import flightStore from "./_store/flight.store"; 
import expenseStore from "./_store/expense.store";
import serviceStore from './_store/service.store';
import { Provider } from "mobx-react";
import loaderStore from './_store/loader.store';
import ErrorBoundary from "./ErrorBountry.js";
import { trainStore } from "./_store/train.store.js";
// import 'src/_helpers/logger/warn';

const stores = {
  authStore,
  commonStore,
  userStore,
  menuStore,
  travelStore,
  flightStore, 
  expenseStore,
  serviceStore,
  loaderStore,
  trainStore
};

ReactDOM.render(
  <Provider {...stores}>
  <ErrorBoundary>
    <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
