import React, { Component } from 'react';
import TreeChildComponent from './TreeChildComponent';

export default class TreeView extends Component {
  render() {
    return (
      <div className="file-tree file-tree-x">
        <ul>
          {
            this.props.renderedGroups ? this.props.renderedGroups.map((group) => {
              let toggleId = `GROUPID-${group.id}`

              return (
                <li key={group.id}>
                  <button
                    className="btn btn-nothing collapsed"
                    data-target={`#${toggleId}`}
                    data-toggle="collapse">
                  </button>
                  <span onClick={e => this.props.chooseGroup(group)} >
                    <img src="/assets/images/folder.svg" alt="" height="20" className="mr-3" />
                    <span className="text align-middle">{group.itemName}</span>
                  </span>
                  {
                    group.isFolder ?
                      (<TreeChildComponent chooseGroup={this.props.chooseGroup} toggleId={toggleId} parentId={group.id} />)
                      : (null)
                  }

                </li>
              )
            }) : "No groups"

          }
        </ul>

      </div>
    )
  }
}
