import React, { Component } from 'react';
import ItemizationStep2Page from './itemization-step2-html';
import SimpleReactValidator from 'simple-react-validator';
import { expenseServices } from '../../../../../_services';
import { toast } from 'react-toastify';
import { alertConstants } from '../../../../../_constants/alert.constants';
class ItemizationStep2 extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.state = {
            companyId: localStorage['440a28'],
        }
        this.validator = new SimpleReactValidator()
    }
    componentDidMount() {
        if (this.props.match.params.id !== undefined) {
            this.isEdit = true;
            this.getItemizationById(this.props.match.params.id)
        }
    }
    pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
    activePage = 1;
    itemsPerPage = 10;
    count = 0
    listExpenseType = []
    getExpenseTypes = async () => {
        var resp = await expenseServices.getExpenseTypes(this.activePage, this.itemsPerPage, '', this.state.companyId)
        if (resp) {
            await resp.result.forEach((item) => { 
                item.required = false;
                item.defaultToPersonl = false;
                item.mustBePersonl = false;
                this.getDetails.availableExpenseTypes.forEach(list =>{
                    if(item.id === list.expenseTypeId){
                        item.required = list.required;
                        item.defaultToPersonl = list.defaultToPersonl;
                        item.mustBePersonl = list.mustBePersonl;
                    }
                })
            })
            this.listExpenseType = resp.result
            this.count = resp.count
            this.forceUpdate()
        }
    }
    handleItemChange = (limit) => {
        this.activePage = 1;
        this.itemsPerPage = limit['items'];
        this.getExpenseTypes();
    }
    handlePageChange = (pageNumber) => {
        this.activePage = pageNumber
        this.getExpenseTypes()
    }
    getDetails = []
    getItemizationById = async (id) => {
        var resp = await expenseServices.getItemById(id, this.state.companyId)
        if (resp) {
            this.getDetails = resp.result
            this.getExpenseTypes()
        }
    }
    handleStatusChange = async (e, name) => { 
        var required
        var defaultToPersonl 
        var mustBePersonl
        (name === 'required')? required = !e.required:required = e.required;
        (name === 'defaultToPersonl')? defaultToPersonl = !e.defaultToPersonl:defaultToPersonl = e.defaultToPersonl;
        (name === 'mustBePersonl')? mustBePersonl = !e.mustBePersonl:mustBePersonl = e.mustBePersonl;
        var req = {
            expenseTypeId: e.id,
            required: required,
            defaultToPersonl: defaultToPersonl,
            mustBePersonl: mustBePersonl
        } 
        var resp = await expenseServices.statusAvailExpenseItemization(this.props.match.params.id, req) 
        this.getItemizationById(this.props.match.params.id) 
    }
    isEdit = false;
    render() {
        return (
            <div>
                <ItemizationStep2Page
                    pageItemsList={this.pageItemsList}
                    activePage={this.activePage}
                    listExpenseType={this.listExpenseType}
                    itemsPerPage={this.itemsPerPage}
                    handleItemChange={this.handleItemChange}
                    handlePageChange={this.handlePageChange}
                    count={this.count}
                    isEdit={this.isEdit}
                    handleStatusChange={this.handleStatusChange}
                />
            </div>
        )
    }
}
export default ItemizationStep2;