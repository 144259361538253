import React from 'react'

export default function FlightLoader() {
  return (
    <div className="text-center p-5 m-5">
        <div className="top-100">
            <div className="mt-5 form-holder pt-3">
            <div className="loader-css"></div>
            <p></p>
            <h5 className="fw-sbold pb-5">
                Please wait while we search for the <br /> best flight options based on
                your company policy.
            </h5>
            </div>
        </div>
    </div>
  )
}
