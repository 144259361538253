import React, { Component } from "react";
import ListManagementPage from "./list-management-html"; 
import SimpleReactValidator from "simple-react-validator";
import { expenseServices } from "../../../../../_services";  

class ListManagement extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.validator = new SimpleReactValidator();
    this.state = {
      companyId: localStorage["440a28"],
      itemName: "",
      itemCode: "",
      parentId: ""
    };
  }
  componentDidMount() {
    this.getList();
  }
  type = "";
  className = "";
  title = "";
  search = "";
  parentId = "";
  mainId = "";
  btnType = e => {
    this.setState({
      itemName: "",
      itemCode: "",
      parentId: ""
    });
    this.validator.hideMessages();
    if (this.type === e && this.type <= 3) {
      this.type = "";
      this.className = "";
      this.title = "";
      this.forceUpdate();
      return;
    } else {
      this.type = "";
      this.className = "";
      this.title = "";
      if (e === 1) {
        this.type = e;
        this.className = "add-new-popup";
        this.title = "Add New";
      } else if (e === 2) {
        this.type = e;
        this.className = "add-new-popup plus";
        this.title = "Add New Child";
      } else if (e === 3) {
        this.type = e;
        this.className = "add-new-popup edit";
        this.title = "Edit Item";
        this.setValue();
      } else if (e === 4) {
        this.type = e;
        this.deleteItem();
      } else if (e === 5 || e === 6) {
        this.type = e;
        this.cutCopy(e);
      }
    }
    this.forceUpdate();
  };
  deleteItem = async () => {
    var resp = await expenseServices.deleteListItem(this.parentId);
    if (resp) {
      if (this.parentId) {
        if (this.detailView.parentId) {
          this.parentId = this.detailView.parentId;
        } else {
          this.parentId = "";
        }
      }
      this.getList();
    }
  };
  pastedItems = [];
  destId = "";
  cutCopy = e => {
    this.pastedItems = this.parentStack;
    this.destId = this.mainId;
    this.forceUpdate();
  };
  pasteHere = async () => {
    var req = {
      source: this.pastedItems,
      destination: this.mainId
    };
    if (this.type === 5) {
      var resp = await expenseServices.cutListItem(this.state.companyId, req);
      if (resp) {
        this.pastedItems = [];
        this.getList();
        this.btnType();
      }
    } else if (this.type === 6) {
      var resp = await expenseServices.copyListItem(this.state.companyId, req);
      if (resp) {
        this.pastedItems = [];
        this.getList();
        this.btnType();
      }
    }
  };
  setValue = () => {
    this.listOfItems.forEach(item => {
      if (item.id === this.parentId) {
        this.state.itemName = item.itemName;
        this.state.itemCode = item.itemCode;
        this.state.id = item.id;
        if (item.parentId) {
          this.state.parentId = item.parentId;
          this.parentId = item.parentId;
        } else {
          this.state.parentId = "";
          this.parentId = "";
        }
      }
    });
    this.setState({
      ...this.state
    });
    this.forceUpdate();
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = async e => {
    if (this.validator.allValid()) {
      if (this.parentId) {
        var req = {
          companyId: localStorage["440a28"],
          itemName: this.state.itemName,
          itemCode: this.state.itemCode,
          parentId: this.parentId
        };
      } else {
        var req = {
          companyId: localStorage["440a28"],
          itemName: this.state.itemName,
          itemCode: this.state.itemCode
        };
      }
      var resp = await expenseServices.createListItem(req);
      if (resp) {
        if (this.parentId) {
          this.lanchBreadCum(this.detailView);
        }
        this.btnType();
        this.getList();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  listOfItems = [];
  getList = async () => {
    this.listOfItems = [];
    this.mainId = this.parentId;
    var resp = await expenseServices.getListManagement(
      this.state.companyId,
      this.search,
      this.parentId
    );
    if (resp) {
      this.parentStack = [];
      this.detailView = "";
      this.listOfItems = resp;
    }
    this.forceUpdate();
  };
  choosedItem = [];
  parentStack = [];
  isStatic = false;
  chooseFolder = (e, item) => {
    const index = this.parentStack.indexOf(e);
    if (item.isStatic === true) {
      this.isStatic = true;
      if (index === -1) {
      } else {
        if (this.parentStack.length < 1) {
          this.parentStack = [];
        }
      }
    } else {
      if (this.isStatic === true) {
        this.parentStack = [];
      }
      this.isStatic = false;
    }
    this.parentStack.length === 0 ? (this.parentId = e) : (this.parentId = "");
    this.parentStack.length === 0
      ? this.setState({ parentId: e })
      : this.setState({ parentId: "" });
    if (this.parentId) this.getParentDetail(this.parentId);
    if (index === -1) {
      if (this.parentStack.length > 0) {
        this.detailView = "";
      }
      this.parentStack.push(e);
    } else {
      this.detailView = "";
      this.parentStack.splice(index, 1);
      if (this.parentStack.length === 1) {
        this.parentId = this.parentStack[0];
        this.setState({ parentId: this.parentStack[0] });
        this.getParentDetail(this.parentId);
      } else if (this.parentStack.length === 0) {
        this.parentId = "";
        this.setState({
          parentId: ""
        });
      }
    }
    this.forceUpdate();
  };
  detailView = "";
  getParentDetail = async () => {
    var resp = await expenseServices.getParentDetailById(this.parentId);
    if (resp) {
      this.detailView = resp;
      this.forceUpdate();
    }
  };

  handleDoubleClick = (e, isFolder, item) => {
    if (isFolder) {
      this.parentId = e;
      this.getList();
      this.lanchBreadCum(item);
    }
  };
  breadCum = [];
  lanchBreadCum = item => {
    var req = {
      itemName: item.itemName,
      itemCode: item.itemCode,
      id: item.id,
      isFolder: item.isFolder,
      parentId: item.parentId
    };
    const inx = this.breadCum.indexOf(req);
    if (inx === -1) {
      this.breadCum.push(req);
    } else {
      this.breadCum.splice(inx, 1);
    }
    this.forceUpdate();
  };
  navNew = async (e, key) => {
    var items = this.breadCum;
    this.breadCum = [];
    await items.forEach((item, indexKey) => {
      if (indexKey < key) {
        this.breadCum.push(item);
      } else {
        return false;
      }
    });
    this.handleDoubleClick(e.id, e.isFolder, e);
  };
  homeNav = () => {
    this.parentId = "";
    this.setState({
      parentId: ""
    });
    this.breadCum = [];
    this.getList();
  };
  sideNavOpt = (e, isFolder, item, detailView) => {
    this.lanchBreadCum(detailView);
    this.handleDoubleClick(e, isFolder, item);
  };
  handleUpdateSubmit = async () => {
    if (this.validator.allValid()) {
      if (this.parentId) {
        var req = {
          companyId: localStorage["440a28"],
          itemName: this.state.itemName,
          itemCode: this.state.itemCode,
          parentId: this.parentId
        };
      } else {
        var req = {
          companyId: localStorage["440a28"],
          itemName: this.state.itemName,
          itemCode: this.state.itemCode
        };
      }
      var resp = await expenseServices.updateListItem(
        this.state.id,
        this.state.companyId,
        req
      );
      if (resp) {
        if (this.parentId) {
          this.parentId = this.detailView.parentId;
        }
        this.btnType();
        this.getList();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleSearch = async e => {
    this.search = e.target.value;
    this.getList();
  };
  render() {
    return (
      <div>
        <ListManagementPage
          error={this.validator}
          state={this.state}
          type={this.type}
          btnType={this.btnType}
          className={this.className}
          title={this.title}
          isStatic={this.isStatic}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          listOfItems={this.listOfItems}
          choosedItem={this.choosedItem}
          chooseFolder={this.chooseFolder}
          parentStack={this.parentStack}
          handleDoubleClick={this.handleDoubleClick}
          detailView={this.detailView}
          breadCum={this.breadCum}
          navNew={this.navNew}
          homeNav={this.homeNav}
          sideNavOpt={this.sideNavOpt}
          handleUpdateSubmit={this.handleUpdateSubmit}
          pastedItems={this.pastedItems}
          parentId={this.mainId}
          destId={this.destId}
          handleSearch={this.handleSearch}
          pasteHere={this.pasteHere}
        />
      </div>
    );
  }
}
export default ListManagement;
