import React from 'react';
import Pagination from "react-js-pagination";
const RatesStep1Page = (props) => {
    let data = props;
    return (
        <div>
            <div className="admin-tabs">
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">E</div>
                        <div className="right">
                            <h6 className="heading">Rates</h6>
                            <p className="text">Lorem Ipsum is simply dummy text.</p>
                        </div>
                    </div>
                </div>
                <div className="py-padding">
                    <div className="table-container">
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="btn-group-margin">
                                        <button type="button" className="btn   sm-padding">Remove</button>
                                        <button type="button" className="btn   sm-padding" data-toggle="modal" data-target="#AddTemplateModal">Add Location map</button>
                                    </div>
                                </div>
                                <div className="col-md-2 offset-4">
                                    <div className="search-custom">
                                        <input type="text" name="" className="form-control" placeholder="Search" />
                                        <span><i className="material-icons">search</i></span>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Location Name</th>
                                        <th>State/Provinance</th>
                                        <th>Country</th>
                                        <th>Location Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td>BR-RJ</td>
                                        <td>BR</td>
                                        <td>BRADR</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td>BR-RJ</td>
                                        <td>BR</td>
                                        <td>BRADR</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td>BR-RJ</td>
                                        <td>BR</td>
                                        <td>BRADR</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td>BR-RJ</td>
                                        <td>BR</td>
                                        <td>BRADR</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="px-padding">
                    <div className="btn-group-margin">
                        <button className="btn btn-border-basic lg-padding">Back</button>
                        <button className="btn btn-border-success lg-padding">Save</button>
                        <button className="btn btn-border-basic lg-padding">Close</button>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="AddTemplateModal" tabIndex="-1" role="dialog"
                aria-labelledby="AddTemplateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="AddTemplateModalLabel">Add Location Map</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group custom labelup">
                                <label>Country</label>
                                <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                        Brazil
                                    </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group custom labelup">
                                <label>Subdivision</label>
                                <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                        SELECT ONE
                                    </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group custom labelup">
                                <label>Location</label>
                                <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">SELECT ONE
                                    </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-border-success lg-padding">Save</button>
                            <button type="button" className="btn btn-border-basic lg-padding"
                                data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default RatesStep1Page;