/* eslint-disable default-case */
import React, { Component } from "react";
import PolicyStep1Page from "./policy-step1-html";
import { requestPolicies } from "../../../../../../_services";
import { toast } from "react-toastify";
import { serviceModules } from "src/_constants/serviceModule";

class PolicyStep1 extends Component {
  state = {
    companyId: localStorage["440a28"],

    policyName: null,
    selectedExpenseReportForm: null,
    selectedExpenseAllocationForm: null,
    selectedExpenseReportWorkFlow: null,
    editableBy: [],
    appliesTo: [],
    scanConfiguration: true,
    missingReceiptAfidivate: false,
    afidivateStatement: "",
    policyId: "5c7f6c190c3b31720f5edc67",
    serviceModuleId: "5c74ed3d77addf3b07acb014",
    limit: 10,
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,
    expensePolicies: [],
    selectedExpensePolicy: [],
    expenseReportFormId: "5c974daabcde0b0ab7812830", //Expense Report Form
    expenseAllocationFormId: "5c989f8562b0f2517b2f841a", //Expense Allocation Form
    expenseReportForms: [],
    cashAdvanceForms: [],
    selectedCashAdvanceForm: "",
    expenseAllocationForms: [],
    workFlows: [],
    parentList: [],
    editableByList: [],
    appliesToList: [],
    isTravelAllowance: false,
    isExpense: false,
    isCashAdvance: false,
    isViolation:false
  };
  // ------------------Handle Form -------------------------------------------

  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleERF = ERF => {
    this.setState({ selectedExpenseReportForm: ERF.value });
  };

  handleEAF = EAF => {
    this.setState({ selectedExpenseAllocationForm: EAF.value });
  };

  handleEWF = EWF => {
    this.setState({ selectedExpenseReportWorkFlow: EWF.value });
  };

  handleEditableBy = editable => {
    let editBy = [];
    editable.forEach(element => {
      editBy.push(element.value);
    });
    this.setState({ editableBy: editBy });
  };

  handleAppliesTo = appliesTo => {
    let editBy = [];
    appliesTo.forEach(element => {
      editBy.push(element.value);
    });
    this.setState({ appliesTo: editBy });
  };

  handleSC = SC => {
    this.setState({ scanConfiguration: SC.value });
  };

  missingReceiptAfidivate = event => {
    let newStatus = event.target.checked;
    this.setState({
      missingReceiptAfidivate: newStatus
    });
  };

  // * Check for validity of all the fields
  checkValidity = payload => {
    // console.log(payload);

    for (var key in payload) {
      // console.log(payload[key])
      if (payload[key] === null || payload[key] === "") {
        return false;
      }
      if (Array.isArray(payload[key]) == true && payload[key].length == 0) {
        return false;
      }
    }
    return true;
  };

  //  * End of check validity of all fields

  // * for creating the policy
  createPolicy = () => {
    let allValid = false;
    let data = {
      companyId: this.state.companyId,
      expenseAllocationForm: this.state.selectedExpenseAllocationForm,
      expenseReportForm: this.state.selectedExpenseReportForm,
      expenseReportWorkFlow: this.state.selectedExpenseReportWorkFlow,
      editableBy: this.state.editableBy,
      appliesTo: this.state.appliesTo,
      policyName: this.state.policyName,
      cashAdvanceForm: this.state.selectedCashAdvanceForm,
      isTravelAllowance: this.state.isTravelAllowance,
      isExpense: this.state.isExpense,
      isCashAdvance: this.state.isCashAdvance,
      isViolation:this.state.isViolation
      // scanConfiguration: this.state.scanConfiguration,
      // missingReceiptAfidivate: this.state.missingReceiptAfidivate,
      // afidivateStatement: this.state.afidivateStatement
    };

    allValid = this.checkValidity(data);

    if (allValid) {
      // alert('Everything is valid');

      requestPolicies
        .createPolicy(data)
        .then(res => {
          toast.success(res.message);
          this.props.history.push("/administration/travel/request/policy");
        })
        .catch(err => console.log("error", err));
    } else {
      toast.warn("Please fill all the fields");
    }
  };
  componentDidMount() {
    requestPolicies
      .getExpenseReportForms(
        this.state.activePage,
        this.state.limit,
        "5c74ed2977addf3b07acb012",
        this.state.expenseReportFormId,
        this.state.companyId
      )
      .then(res => {
        this.setState({
          expenseReportForms: res.result
        });
      })
      .catch(err => console.log("err", err));

    requestPolicies
      .getExpenseAllocationForms(
        this.state.activePage,
        this.state.limit,
        this.state.serviceModuleId,
        this.state.expenseAllocationFormId,
        this.state.companyId
      )
      .then(res => {
        this.setState({
          expenseAllocationForms: res.result
        });
      })
      .catch(err => console.log("err", err));

    requestPolicies
      .getCashAdvanceForms(
        this.state.activePage,
        this.state.limit,
        this.state.serviceModuleId,
        this.state.expenseAllocationFormId,
        this.state.companyId
      )
      .then(res => {

        this.setState({
          cashAdvanceForms: res.result
        });
      })
      .catch(err => console.log("err", err));

    requestPolicies.workFlow().then(res => {
      if (res != undefined && res.result) {
        this.setState({
          workFlows: res.result.filter(
            workflow =>
              workflow?.workflowType?.master_value?.service_module ==
              serviceModules.TRAVEL
          )
        });
      }
    });

    requestPolicies.parentList(this.state.companyId).then(res => {
      if (res) {
        this.setState({
          parentList: res.result
        });
      }
    });

    requestPolicies.getGroups(this.state.companyId).then(res => {
      if (res) {

        this.setState({
          editableByList: res.result,
          appliesToList: res.result
        });
      }
    });
  }

  handleCashAdvanceFormSelection = cashAdvance => {
    this.setState({ selectedCashAdvanceForm: cashAdvance.value });
  };

  handleToggleChange = (e, type) => {
    switch (type) {
      case "travel":
        this.setState({
          isTravelAllowance: e.target.checked
        });
        break;
      case "expense":
        this.setState({
          isExpense: e.target.checked
        });
        break;
      case "isCashAdvance":
        this.setState({ isCashAdvance: e.target.checked });
    break;
    case "isViolation":
        this.setState({ isViolation: e.target.checked });
    }
  }


  render() {
    return (
      <div>
        <PolicyStep1Page
          createPolicyData={this.state}
          formNameHandler={this.formNameHandler}
          handleERF={this.handleERF}
          handleEAF={this.handleEAF}
          handleEWF={this.handleEWF}
          handleEditableBy={this.handleEditableBy}
          handleAppliesTo={this.handleAppliesTo}
          handleToggleChange={this.handleToggleChange}
          handleSC={this.handleSC}
          handleCashAdvanceFormSelection={this.handleCashAdvanceFormSelection}
          missingReceiptAfidivate={this.missingReceiptAfidivate}
          createPolicy={this.createPolicy}
        />
      </div>
    );
  }
}
export default PolicyStep1;
