import moment from "moment";

// * For converting seconds into hours and munutes
const pad = num => {
  return ("0" + num).slice(-2);
};
export const hhmmss = secs => {
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)}h ${pad(minutes)}m`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
};

// End of seconds to hours
// * For getting name

export const getFullNameFromFieldData = response => {
  if (response.reportManager1 && response.reportManager1.fieldData.length) {
    let firstName = response.reportManager1.fieldData.find(
      field => field.columnName == "EMPLOYEE_FIRST_NAME"
    ).fieldValue;
    let middleName = response.reportManager1.fieldData.find(
      field => field.columnName == "MIDDLE_NAME"
    ).fieldValue;
    let lastName = response.reportManager1.fieldData.find(
      field => field.columnName == "EMPLOYEE_LAST_NAME"
    ).fieldValue;
    return firstName + " " + middleName + " " + lastName;
  }
  if (response.reportManager2 && response.reportManager2.fieldData.length) {
    let firstName = response.reportManager2.fieldData.find(
      field => field.columnName == "EMPLOYEE_FIRST_NAME"
    ).fieldValue;
    let middleName = response.reportManager2.fieldData.find(
      field => field.columnName == "MIDDLE_NAME"
    ).fieldValue;
    let lastName = response.reportManager2.fieldData.find(
      field => field.columnName == "EMPLOYEE_LAST_NAME"
    ).fieldValue;
    return firstName + " " + middleName + " " + lastName;
  }
};

export const getDataWithActiveStatus = arr => {
  return arr.filter(item => item.activeStatus == true);
};

// * For calculating age from date of birth
export const calculateAge = birthday => {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

// * For executing Promises one at a time
/**
 * @param {iterable} iterable any iterable like array
 * @param {function} mapper a function
 * @param {Object} options an object specifying options, use concurrency for specifying number of execution at each go
 */
export const executePromisesConcurrently = (iterable, mapper, options) =>
  new Promise((resolve, reject) => {
    options = Object.assign(
      {
        concurrency: Infinity
      },
      options
    );

    if (typeof mapper !== "function") {
      throw new TypeError("Mapper function is required");
    }

    const { concurrency } = options;

    if (!(typeof concurrency === "number" && concurrency >= 1)) {
      throw new TypeError(
        `Expected \`concurrency\` to be a number from 1 and up, got \`${concurrency}\` (${typeof concurrency})`
      );
    }

    const ret = [];
    const iterator = iterable[Symbol.iterator]();
    let isRejected = false;
    let isIterableDone = false;
    let resolvingCount = 0;
    let currentIndex = 0;

    const next = () => {
      if (isRejected) {
        return;
      }

      const nextItem = iterator.next();
      const i = currentIndex;
      currentIndex++;

      if (nextItem.done) {
        isIterableDone = true;

        if (resolvingCount === 0) {
          resolve(ret);
        }

        return;
      }

      resolvingCount++;

      Promise.resolve(nextItem.value)
        .then(element => mapper(element, i))
        .then(
          value => {
            ret[i] = value;
            resolvingCount--;
            next();
          },
          error => {
            isRejected = true;
            reject(error);
          }
        );
    };

    for (let i = 0; i < concurrency; i++) {
      next();

      if (isIterableDone) {
        break;
      }
    }
  });

export const getFullUserName = fieldData => {
  if (fieldData && Array.isArray(fieldData) && fieldData.length > 0) {
    let firstName = fieldData.find(
      field => field.columnName === "EMPLOYEE_FIRST_NAME"
    ).fieldValue;
    let middleName = fieldData.find(field => field.columnName === "MIDDLE_NAME")
      .fieldValue;
    let lastName = fieldData.find(
      field => field.columnName === "EMPLOYEE_LAST_NAME"
    ).fieldValue;
    return firstName + " " + middleName + " " + lastName;
  } else {
    return "";
  }
};

export const getFirstLastName = fieldData => {
  if (fieldData && Array.isArray(fieldData) && fieldData.length > 0) {
    let firstName = fieldData.find(
      field => field.columnName === "EMPLOYEE_FIRST_NAME"
    ).fieldValue;
    let lastName = fieldData.find(
      field => field.columnName === "EMPLOYEE_LAST_NAME"
    ).fieldValue;
    return firstName + " " + lastName;
  } else {
    return "";
  }
};

export const getUserBaseCurrency = fieldData => {
  if (fieldData.length) {
    let baseCurrency = fieldData.find(
      field => field.columnName === "REIMBURSEMENT_CURRENCY"
    ).fieldValue;
    return baseCurrency;
  } else {
    return "";
  }
};
export const mergeFormAndFields = formAndFields => {
  let additionalExpense = formAndFields;
  formAndFields.forEach((formFields, fieldIndex) => {
    if (formFields.dataType === "connected_list") {
      additionalExpense[fieldIndex]["fieldValue"] = formFields.fieldValue;
      let fieldValue =
        formFields.connectedListData &&
        formFields.connectedListData.listManagementData.find(
          list => list.id === formFields.fieldValue
        );
      additionalExpense[fieldIndex]["fieldValue"] =
        fieldValue && fieldValue.itemName ? fieldValue.itemName : "";
    } else if (formFields.dataType === "different_list") {
      if (formFields.columnName === "PAYMENT_TYPE") {
        additionalExpense[fieldIndex]["fieldValue"] = formFields.fieldValue;
        let fieldValue =
          formFields.paymentDetails &&
          formFields.paymentDetails.find(
            list => list.id === formFields.fieldValue
          );

        additionalExpense[fieldIndex]["fieldValue"] =
          fieldValue && fieldValue.description ? fieldValue.description : "";
      } else {
        additionalExpense[fieldIndex]["fieldValue"] = formFields.fieldValue;
        let fieldValue =
          formFields.connectedListData &&
          formFields.connectedListData.listManagementData.find(
            list => list.id === formFields.fieldValue
          );
        additionalExpense[fieldIndex]["fieldValue"] =
          fieldValue && fieldValue.itemName ? fieldValue.itemName : "";
      }
    } else if (formFields.dataType === "search") {
      additionalExpense[fieldIndex]["fieldValue"] = formFields.searchName
        ? formFields.searchName
        : "";
    } else {
      additionalExpense[fieldIndex] = formFields;
    }
  });
  return additionalExpense;
};

export const mergeExpenseEntryMultyData = data => {
  let additionalExpense = [];
  data &&
    data.length &&
    data.forEach((list, index) => {
      additionalExpense[index] = list;
      list &&
        list.entryForm.length &&
        list.entryForm.forEach((formFields, fieldIndex) => {
          if (formFields.dataType === "connected_list") {
            additionalExpense[index]["entryForm"][fieldIndex]["fieldValue"] =
              formFields.fieldValue;
            let fieldValue = formFields.connectedListData &&
            formFields.connectedListData.listManagementData.length &&
              formFields.connectedListData.listManagementData.find(
                list => list.id === formFields.fieldValue
              );
            additionalExpense[index]["entryForm"][fieldIndex]["fieldValue"] =
              fieldValue && fieldValue.itemName ? fieldValue.itemName : "-";
            additionalExpense[index]["entryForm"][fieldIndex]["fieldValue"] =
              fieldValue && fieldValue.itemName ? fieldValue.itemName : "";
          } else if (formFields.dataType === "different_list") {
            if (formFields.columnName === "PAYMENT_TYPE") {
              additionalExpense[index]["entryForm"][fieldIndex]["fieldValue"] =
                formFields.fieldValue;
              let fieldValue =
                formFields.paymentDetails &&
                formFields.paymentDetails.find(
                  list => list.id === formFields.fieldValue
                );
              additionalExpense[index]["entryForm"][fieldIndex]["fieldValue"] =
                fieldValue && fieldValue.description
                  ? fieldValue.description
                  : "-";
            } else {
              additionalExpense[index]["entryForm"][fieldIndex]["fieldValue"] =
                formFields.fieldValue;
              let fieldValue =
                formFields.connectedListData &&
                formFields.connectedListData.listManagementData.find(
                  list => list.id === formFields.fieldValue
                );
              additionalExpense[index]["entryForm"][fieldIndex]["fieldValue"] =
                fieldValue && fieldValue.itemName ? fieldValue.itemName : "-";
              additionalExpense[index]["entryForm"][fieldIndex]["fieldValue"] =
                fieldValue && fieldValue.itemName ? fieldValue.itemName : "-";
            }
          } else if (formFields.dataType === "search") {
            additionalExpense[index]["entryForm"][fieldIndex]["fieldValue"] =
              formFields.searchName;
          } else {
            additionalExpense[index]["entryForm"][fieldIndex] = formFields;
          }
        });
    });
  return additionalExpense;
};

// * Calculate difference in days
/**
 * @param {date1} date first date object
 * @param {date2} date second date object
 * @param {format} string string in the for like "10/05/2019", "2019-12-01" etc
 */
export const differenceInDays = (date1, date2, format) => {
  let date1InMoment = moment(date1, format);
  let date2InMoment = moment(date2, format);
  if (date1InMoment && date2InMoment) {
    return date1InMoment.diff(date2InMoment, "days") + 1;
  } else {
    return "---";
  }
};

export const checkIsArray = data => {
  if (data && Array.isArray(data) && data.length > 0) return true;
  else return false;
};

export const differenceInDaysNative = (date1, date2) => {
  var ONE_DAY = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  var date1_ms = new Date(date1).getTime();
  var date2_ms = new Date(date2).getTime();

  // Calculate the difference in milliseconds
  var difference_ms = Math.abs(date1_ms - date2_ms);

  // Convert back to days and return
  return Math.round(difference_ms / ONE_DAY);
};

export const minutesToHoursAndMinutes = n => {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + " hour(s) and " + rminutes + " minute(s).";
};

export const checkValidityBeforeSubmit = payload => {

  for (var key in payload) {
    if (payload[key] === null || payload[key] === "") {
      return false;
    }
    if (Array.isArray(payload[key]) == true && payload[key].length == 0) {
      return false;
    }
  }
  return true;
};

export const checkObjectHasProperties = (obj) => {
  return !(Object.keys(obj).length == 0 && obj.constructor === Object)
}

export const getFormattedDateFromIsoString = (date) => {
  if (date != null) {
    let formattedDate = moment(date).format('DD MMM YYYY');
    return formattedDate;
  }
  return " "
}


export const getFormattedDateFromDDMMYYYYFormat = (date) => {
  if (date != null) {
    let formattedDate = moment(date, 'DD-MM-YYYY').format('DD MMM YYYY');
    return formattedDate;
  }
  return " "
}
