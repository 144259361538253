import React, { Component } from "react";
import FullDetailsPage from "./_components/full-details";
import Loader from "react-loader-spinner";
import cryptr from "../../../../_helpers/crypty";
import { bookingServices } from "../../../../_services/booking.service";
import InfiniteScroll from "react-infinite-scroll-component";
import FlightList from "./_components/FlightList";
import SortHeadFlight from "./_components/sortHeadFlight";
import FilterFlight from "./_components/FilterFlight";
import { cartServices, masterService } from "src/_services";
import $ from "jquery";
import { toast } from "react-toastify";
import FlightNoResults from "./_components/NoResults";
import FlightLoader from "./_components/FlightLoader";
import { getFormattedDateFromIsoString } from "src/utils/util";
import _ from "lodash";

class FlightOneWayPage extends Component {
  constructor(props) {
    super(props);

    var resp = localStorage["15c059"];
    this.req = JSON.parse(cryptr.decrypt(this.props.match.params.data));
    let item = JSON.parse(cryptr.decrypt(localStorage["1UC43"]));
    this.currency = item.currencyCode;
  }
  // creating variables for this page starts
  state = {
    value: {
      start: "00:00",
      end: "23:00"
    },
    valueLand: {
      min: "0:00",
      max: "23:00"
    },
    loadingUniqueId: "",
    isOpenPolicy: false,
    storePolicyCart: [],
    reasons: "",
    violationsId: "",
    cartCount: 0,
    listOfFlights: [],
    loading: true,
    loading1: false,
    sortStop: "",
    sortDate: "",
    sortPrice: "",
    sortFlight: "",
    hasMore: true
  };
  userId = "";
  page = 1;
  limit = 10;
  allItem = [];
  listOfFlights = [];
  count = 0;
  maxPage = 1;
  stop = "";
  preferredAirline = [];
  layoverTime = "";
  preferredAirport = [];
  fromTime = "";
  toTime = "";
  airportList = [];
  airLineList = [];
  selectedAirLine = [];
  cardList = [];
  filterSet = false;
  type = 1;
  detailId = "";
  arrowId = "";
  baggageInfo = [];
  selectedOption = false;
  selectedOptionRefund = false;
  refundable = "";
  sortList = [
    { type: "sortPrice" },
    { type: "sortStop" },
    { type: "sortDate" },
    { type: "sortFlight" }
  ];
  // creating variables for this page ends

  closeFilter() {
    $("#filter-card").removeClass("show");
  }
  componentDidMount() {
    sessionStorage.removeItem("modify");
    this.userId = JSON.parse(cryptr.decrypt(localStorage["440a24"]));
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getCardList();
    this.getFlights();
    this.getCartDetail();
    this.getCurrencyCode();

  }

  componentWillUpdate() {
    if (sessionStorage.modify !== undefined && sessionStorage.modify) {
      this.req = JSON.parse(cryptr.decrypt(sessionStorage.req));
      this.url = this.props.match.params.data;
      this.getCardList();
      this.getFlights(1);
      this.getCartDetail();
      sessionStorage.removeItem("modify");
      sessionStorage.removeItem("req");
    }
  }
  listOfCurrency = [];
  getCurrencyCode = async () => {
    var companyId = localStorage["440a28"];
    var resp = await masterService.getGroups(companyId, "CUR001");
    if (resp) {
      this.listOfCurrency = resp.result;
    }
    this.setState({ ...this.state });
  };


  changeCurrency = e => {
    this.currency = e.value;
    this.forceUpdate();
    this.getFlights(1);
  };
  handleGetReason = e => {
    this.setState({
      reasons: e.target.value
    });
  };
  selectedItem = [];
  uniqueId = [];

  addToCartDebounce = async (e, trip, uniqueId) => {
    if (this.state.isOpenPolicy) {
      if (this.state.violationsId === "") {
        return toast.error("Violations is required");
      } else if (this.state.reasons === "") {
        return toast.error("Reasons is required");
      }
    }
    var req = {
      type: "flight",
      userId: this.userId.id,
      TRIP_ID: sessionStorage.getItem("TRIPID"),
      data: {
        flight_fare_id: e.flight_fare_id,
        adults: this.req.adults,
        departureDate: this.req.departureDate,
        from: this.req.from,
        classType: this.req.classType,
        to: this.req.to
      }
    };

    var remove = {
      type: "flight",
      TRIP_ID: sessionStorage.getItem("TRIPID"),
      flight_fare_id: e.flight_fare_id
    };
    const int = this.selectedItem.indexOf(e.flight_fare_id);
    if (int === -1) {
      // to hide below two line get multi select
      if (e.out_of_policy) {
        this.setState({
          isOpenPolicy: true,
          storePolicyCart: {
            e: e,
            tripType: trip,
            uniqueId: uniqueId
          }
        });
        if (this.state.reasons) {
          $(".close").click();
        }
        if (!this.state.reasons) {
          $("#openOutofPolicy").click();
          return;
        } else {
          this.setState({
            isOpenPolicy: false,
            storePolicyCart: {},
            reasons: "",
            violationsId: ""
          });
        }
      }
      if (this.state.reasons !== "") {
        req.reasons = this.state.reasons;
        req.violationsId = this.state.violationsId;
        $(".close").click();
      }
      this.setState({ loadingUniqueId: uniqueId });
      this.selectedItem = [];
      this.uniqueId = [];
      sessionStorage.setItem("tripType", "oneway");
      let assume = await this.addRemove(1, req);
      if (assume) {
        this.selectedItem.push(e.flight_fare_id);
        this.uniqueId.push(uniqueId);
      }
    } else {
      sessionStorage.removeItem("tripType");
      let assume = await this.addRemove(2, remove);
      if (assume) {
        this.selectedItem.splice(int, 1);
        this.uniqueId.splice(int, 1);
      }
    }
  };

  addToCart = _.debounce((e, trip, uniqueId) => {
    this.addToCartDebounce(e, trip, uniqueId);
  }, 700);
  cancelPolicy = () => {
    this.setState({
      isOpenPolicy: false,
      storePolicyCart: {},
      reasons: "",
      violationsId: ""
    });
  };
  handleChangeReasonId = e => {
    this.setState({
      violationsId: e.target.value
    });
  };
  changeMode = (key, item) => {
    const { listOfFlights } = this.state;
    listOfFlights[key].choosed_flight_fare_id = item;
    this.setState({ ...this.state });
  };
  addRemove = async (type, req) => {
    if (type === 1) {
      var resp = await cartServices.addCartUserData(req);
      this.setState({ loadingUniqueId: "" });
      if (resp) {
        this.getCardList();
        this.getCartDetail();
        return true;
      } else {
        return false;
      }
    } else {
      var resp = await cartServices.removeCartUserData(req);
      this.setState({ loadingUniqueId: "" });
      if (resp) {
        this.getCardList();
        this.getCartDetail();
        return true;
      } else {
        return false;
      }
    }
  };
  getCartDetail = async () => {
    let tripID = sessionStorage.getItem("TRIPID");
    var resp = await cartServices.getCartListDetail(tripID);
    if (resp) {
      // this.selectedItem = resp;
      if (resp.data.flightData) {
        let assume = resp.data.flightData;
        await assume.forEach((item, key) => {
          if (item.fareContent && item.fareContent.fare) {
            const index = this.selectedItem.indexOf(
              item.fareContent.fare.flight_fare_id
            );
            if (index === -1) {
              this.selectedItem.push(item.fareContent.fare.flight_fare_id);
              this.uniqueId.push(item.fareContent.UNIQUE_ID);
            }
          }
        });
        let count = resp.data.flightData.length + resp.data.hotelData.length;
        this.setState({
          cartCount: count
        });
      }
    }
  };
  getCardList = async () => {
    let tripID = sessionStorage.getItem("TRIPID");
    var resp = await cartServices.getCartUserList(tripID);
    if (resp) {
      this.cardList = resp;
    }
    this.setState({ ...this.state });
  };
  getFlights = async type => {
    if (type === 1) {
      this.filterSet = true;
      this.page = 1;
      this.limit = 10;
      this.count = 0;
      this.setState({ listOfFlights: [], loading: true, hasMore: true });
    }
    var req = {
      ...this.req,
      page: this.page,
      limit: this.limit,
      stop:
        this.stop === 0
          ? "0"
          : this.stop === ""
            ? ""
            : JSON.stringify(this.stop),
      preferred_airline: this.preferredAirline,
      layover:
        this.layoverTime === 0
          ? "0"
          : this.layoverTime === ""
            ? ""
            : JSON.stringify(this.layoverTime),
      refundable: this.refundable,
      preferred_airport: this.preferredAirport,
      fromTime: this.fromTime,
      toTime: this.toTime,
      currency: this.currency,
      sort: {
        stop: this.state.sortStop,
        date: this.state.sortDate,
        price: this.state.sortPrice,
        flight: this.state.sortFlight
      }
    };
    var resp = await bookingServices.getFlightsList(req);
    if (resp) {
      this.count = resp.count;
      this.maxPage = Math.floor((this.count + this.limit - 1) / this.limit);
      this.airportList = resp.result.flight_airport;
      this.airLineList = resp.result.flight_airline_names;
      this.allItem = resp.result;
      resp.result.fareContent.forEach((list, index) => {
        list.priceList = [];
        if (list.fare) {
          let result = this.customizeResult("fare", list, "Retail Fare");
          list.priceList.push(result);
        }
        if (list.CORP) {
          let result = this.customizeResult("CORP", list, "Corporate Fare");
          list.priceList.push(result);
        }
        list.choosed_flight_fare_id = list.priceList && list.priceList[0];
      });
      const { listOfFlights } = this.state;
      if (listOfFlights.length > 0) {
        this.setState({
          listOfFlights: listOfFlights.concat(resp.result.fareContent)
        });
      } else {
        this.setState({
          listOfFlights: resp.result.fareContent
        });
      }
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  };
  customizeResult(type, list, typeName) {
    var price = [];
    price = {
      flight_fare_id: list[type].flight_fare_id,
      flight_total_price: list[type].flight_total_price,
      flight_layoverTime: list[type].flight_layoverTime,
      arriveTime: list[type].arrivin_day,
      airLine: list[type].flight_airline,
      journeyTime: list[type].flight_onward_total_duration,
      type: typeName,
      violations: list.violations,
      out_of_policy: list.out_of_policy,
      fareInfo: {
        totalOverAllPrice: list[type].flight_total_price,
        flightAdultFare: list[type].flight_base_price,
        serviceFee: list[type].flight_service_fee
          ? Number(list[type].flight_service_fee)
          : 0,
        gst: list[type].flight_total_tax,
        taxBreakUp: [
          {
            flight_agency_transaction_fee:
              list[type].flight_agency_transaction_fee,
            flight_convinience_fee: list[type].flight_convinience_fee,
            flight_agency_commission: list[type].flight_agency_commission,
            flight_cute_fee: list[type].flight_cute_fee,
            flight_service_fee: list[type].flight_service_fee
              ? Number(list[type].flight_service_fee)
              : 0,
            flight_gst: list[type].flight_gst
              ? Number(list[type].flight_gst)
              : 0,
            flight_other_charges: list[type].flight_other_charges,
            flight_congestion_fee: list[type].flight_congestion_fee,
            flight_fuel_surcharge: list[type].flight_fuel_surcharge,
            flight_agency_service_fee: list[type].flight_agency_service_fee,
            flight_air_dev_fee: list[type].flight_air_dev_fee,
            flight_airport_development_fee:
              list[type].flight_airport_development_fee
          }
        ]
      }
    };
    return price;
  }
  stopFilter = e => {
    if (this.stop === e) {
      this.stop = "";
      this.getFlights(1);
    } else {
      this.stop = e;
      this.getFlights(1);
    }
  };
  layoverTimeFilter = e => {
    if (this.layoverTime === e) {
      this.layoverTime = "";
      this.getFlights(1);
    } else {
      this.layoverTime = e;
      this.getFlights(1);
    }
  };

  fetchMoreData = () => {
    if (this.state.listOfFlights.length >= this.count) {
      this.setState({ hasMore: false });
      return;
    }
    this.page = this.page + 1;
    this.getFlights();
  };
  getAirline = (e, airline) => {
    const int = this.preferredAirline.indexOf(airline);
    if (int === -1) {
      this.preferredAirline.push(airline);
      this.selectedAirLine.push({ code: e, airLine: airline });
    } else {
      this.preferredAirline.splice(int, 1);
      this.selectedAirLine.splice(int, 1);
    }
    this.setState({ ...this.state });
    this.getFlights(1);
  };
  getAirPort = (e, airline) => {
    const int = this.preferredAirport.indexOf(airline);
    if (int === -1) {
      this.preferredAirport.push(airline);
    } else {
      this.preferredAirport.splice(int, 1);
    }
    this.setState({ ...this.state });
    this.getFlights(1);
  };
  sortItem = categoryName => {
    if (this.state[categoryName] === "") {
      this.state[categoryName] = "asc";
    } else {
      this.state[categoryName] === "asc"
        ? (this.state[categoryName] = "desc")
        : (this.state[categoryName] = "asc");
    }
    this.sortList.forEach(list => {
      if (list.type !== categoryName) {
        this.state[list.type] = "";
      }
    });
    this.setState({ ...this.state });
    this.getFlights(1);
  };
  changeInfoWay = (type, detail, arrow) => {
    this.type = type;
    this.setState({ ...this.state });
  };
  storeValue = e => {
    this.setState({
      value: e
    });
  };
  completeRange = e => {
    this.fromTime = this.state.value.start;
    this.toTime = this.state.value.end;
    if (this.state.value.start == "0:00") {
      this.fromTime = "0:00";
    } else if (this.state.value.end == "23:00") {
      this.toTime = "23:00";
    }
    if (this.state.value.start) {
      let split = this.state.value.start.split(":");
      if (split[0] <= 9) {
        var time =
          ("0" + split[0]).slice(-2) + ":" + ("0" + split[1]).slice(-2);
        this.fromTime = time;
      }
    }
    if (this.state.value.end) {
      let split = this.state.value.end.split(":");
      if (split[0] <= 9) {
        var time =
          ("0" + split[0]).slice(-2) + ":" + ("0" + split[1]).slice(-2);
        this.toTime = time;
      }
    }
    this.setState({ ...this.state });
    this.getFlights(1);
  };
  typeTime = 1;
  takeland = type1 => {
    this.typeTime = type1;
  };
  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " m " : " m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  viewDetail = async (id, arrow, e) => {
    this.baggageInfo = [];
    this.detailId === arrow ? (this.detailId = "") : (this.detailId = arrow);
    this.arrowId === arrow ? (this.arrowId = "") : (this.arrowId = arrow);
    var req = {
      type: "flight",
      userId: this.userId.id,
      TRIPID: sessionStorage.getItem("TRIPID"),
      classType: localStorage.getItem("classType"),//added by sethu
      data: {
        flight_fare_id: e.flight_fare_id,
        adults: this.req.adults,
        departureDate: this.req.departureDate,
        from: this.req.from,
        to: this.req.to
      }
    };
    var resp = await bookingServices.getBaggage(req);
    if (resp) {
      this.baggageInfo = resp;
    }
    this.setState({ ...this.state });
  };
  enableConnect = () => {
    this.selectedOption = !this.selectedOption;
    this.setState({ ...this.state });
    if (this.selectedOption === false) {
      this.preferredAirport = [];
      this.getFlights(1);
    }
  };
  onlyRefund = () => {
    this.setState({ loading: true });
    this.selectedOptionRefund = !this.selectedOptionRefund;
    this.refundable = this.selectedOptionRefund;
    this.getFlights(1);
  };
  render() {
    var dataId = (localStorage.getItem("440a28"));
    const { loading, listOfFlights, hasMore } = this.state;

    return (
      <div>
        {this.count !== 0 && (
          <button
            data-target="#addServiceModal"
            data-toggle="modal"
            className="add-button text-white hover-v position-fixed"
          >
            +<span className="hover-name bottom fs-12">Add Service</span>
          </button>
        )}
        <FullDetailsPage
          from={this.req.from}
          to={this.req.to}
          depart={this.req.departureDate}
          cardList={this.cardList}
          cartCount={this.state.cartCount}
          type="oneway"
          currency={this.currency}
          typeOfService="flight"
        />
        <div className="flight-margin">
          <div className="p-padding">
            {!loading && this.count !== 0 && (
              <div className="summary active">
                <div className="d-inline-block text-center">
                  <p>
                    <i className="material-icons">flight</i>
                  </p>
                </div>
                <div className="d-inline-block">
                  <p className="title">
                    {this.req.fromCity} ({this.req.from}) to {this.req.toCity}{" "}
                    ({this.req.to})
                  </p>
                  <p className="label">{getFormattedDateFromIsoString(this.req.departureDate)}{" "}|{" "}{this.count} flights found</p>
                </div>
              </div>
            )}
            <div className="clearfix" style={{ "clear": "both" }}></div>
            <div className="row">
              <div
                className={
                  loading && !this.filterSet
                    ? "col-lg-12"
                    : "col-xl-10 col-lg-9 "
                }
              >
                {!loading && this.count !== 0 && (
                  <div>
                    <div className="clearfix" />
                    {/* header code starts here */}
                    <div className="devision-container heading">
                      <div className="devision">
                        <div className="new-devision-container">
                          <SortHeadFlight
                            sortItem={this.sortItem}
                            feildName={"sortFlight"}
                            name="Airline"
                            sortType={this.state.sortFlight}
                            className="details airline"
                          />
                          <div className="details departure">
                            Departure <span>
                              <i className="material-icons sort">
                                arrow_drop_down
                            </i>
                              <i className="material-icons sort">
                                arrow_drop_up
                            </i>
                            </span>
                          </div>
                          <div className="details arrival">
                            Arrival <span>
                              <i className="material-icons sort">
                                arrow_drop_down
                            </i>
                              <i className="material-icons sort">
                                arrow_drop_up
                            </i>
                            </span>
                          </div>
                          <SortHeadFlight
                            sortItem={this.sortItem}
                            feildName={"sortDate"}
                            name="Duration"
                            sortType={this.state.sortDate}
                            className="details duration"
                          />
                          <SortHeadFlight
                            sortItem={this.sortItem}
                            feildName={"sortStop"}
                            name="Stops"
                            sortType={this.state.sortStop}
                            className="details stops"
                          />
                          <SortHeadFlight
                            sortItem={this.sortItem}
                            feildName={"sortPrice"}
                            name="Price"
                            sortType={this.state.sortPrice}
                            className="details price text-right"
                          />
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="devision-container heading">
                      <div className="devision">
                        <SortHeadFlight
                          sortItem={this.sortItem}
                          feildName={"sortFlight"}
                          name="Airline"
                          sortType={this.state.sortFlight}
                          className="details main"
                        />
                        <SortHeadFlight
                          sortItem={this.sortItem}
                          feildName={"sortStop"}
                          name="Stops"
                          sortType={this.state.sortStop}
                          className="details stops"
                        />
                        <SortHeadFlight
                          sortItem={this.sortItem}
                          feildName={"sortDate"}
                          name="Duration"
                          sortType={this.state.sortDate}
                          className="details amenities"
                        />
                        <div className="details date">Date</div>
                        <SortHeadFlight
                          sortItem={this.sortItem}
                          feildName={"sortPrice"}
                          name="Price"
                          sortType={this.state.sortPrice}
                          className="details price text-right"
                        />
                        <div className="clearfix" />
                      </div>
                    </div> */}
                    {/* header code ends here */}
                    {/* Flight results starts here */}
                    <div className={dataId == "5dc5197e1382a2538aa3a8ca" ? "d-block mb-2" : "d-none"} style={{ background: "#FFF4CB" }}>
                      <div style={{ padding: "6px 10px" }}>
                        <p className="float-left mb-0">
                          <i className="material-icons fs-14" style={{ color: "#AB810A" }}>
                            info
                          </i>
                        </p>
                        <p className="float-left mb-0" style={{ paddingTop: "1px", marginLeft: "10px", color: "#AB810A", fontWeight: "600" }} >Price may differ at the time of booking</p>
                        <p className="clearfix mb-0"></p>
                      </div>
                    </div>
                    {listOfFlights.length !== 0 && (
                      <InfiniteScroll
                        dataLength={listOfFlights.length}
                        next={this.fetchMoreData}
                        hasMore={hasMore}
                        loader={
                          <p style={{ textAlign: "center" }}>Loading...</p>
                        }
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>No More Search Results Available</b>
                          </p>
                        }
                      >
                        {listOfFlights.map((i, index) => (
                          <FlightList
                            key={index}
                            state={this.state}
                            currency={this.currency}
                            keys={index}
                            handleGetReason={this.handleGetReason}
                            changeMode={this.changeMode}
                            cancelPolicy={this.cancelPolicy}
                            items={i}
                            airport={this.airportList}
                            addToCart={this.addToCart}
                            detailId={this.detailId}
                            viewDetail={this.viewDetail}
                            baggageInfo={this.baggageInfo}
                            changeWay={this.changeInfoWay}
                            selectedItem={this.selectedItem}
                            uniqueId={this.uniqueId}
                            type={this.type}
                            arrowId={this.arrowId}
                            getTimes={this.secondsToHms}
                            from={this.req.from}
                            to={this.req.to}
                            handleChangeReasonId={this.handleChangeReasonId}
                            classType={this.req.classType}
                          />
                        ))}
                      </InfiniteScroll>
                    )}
                    {/* flight results ends here */}
                  </div>
                )}
                {/* loader */}
                {loading && <FlightLoader />}
                {!loading && this.count === 0 && (
                  <FlightNoResults
                    msg={
                      !loading && this.count === 0 && this.filterSet
                        ? "No flight found that matches your filter criteria"
                        : "There are no flights found in this route for the selected date of journey. Please try a different route or date"
                    }
                  />
                )}
              </div>
              {((!loading && listOfFlights.length !== 0 && this.count !== 0) ||
                (this.filterSet === true && this.count === 0)) && (
                  <div className="col-xl-2 col-lg-3">
                    <FilterFlight
                      closeFilter={this.closeFilter}
                      type1={this.typeTime}
                      stop={this.stop}
                      stopFilter={this.stopFilter}
                      layoverTime={this.layoverTime}
                      layoverTimeFilter={this.layoverTimeFilter}
                      takeland={this.takeland}
                      preferredAirline={this.preferredAirline}
                      airLineList={this.airLineList}
                      airportList={this.airportList}
                      value={this.state.value}
                      valueLand={this.state.valueLand}
                      getAirline={this.getAirline}
                      enableConnect={this.enableConnect}
                      onlyRefund={this.onlyRefund}
                      selectedOptionRefund={this.selectedOptionRefund}
                      handelAirport={this.getAirPort}
                      preferredAirport={this.preferredAirport}
                      selectedAirLine={this.selectedAirLine}
                      selectedOption={this.selectedOption}
                      from={this.req.from}
                      listOfCurrency={this.listOfCurrency}
                      to={this.req.to}
                      changeCurrency={this.changeCurrency}
                      completeRange={this.completeRange}
                      storeValue={this.storeValue}
                      currency={this.currency}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FlightOneWayPage;
