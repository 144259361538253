import React from "react";

const AmenitiesRoomPage = props => {
  const { viewAllRoomClose, index, hotelDescription, rules, loadingDetail, roomFacilities, roomName } = props;
  console.log(typeof(roomFacilities))
  return (
    <div className="inner-div">
      <h5 className="hotel_info_head text-white left">{roomName} <span className="right" onClick={e => viewAllRoomClose(index)}>
        &times;
    </span></h5>
      <div className="clearfix" />
      <div className="pm-30">
        <h6 className="sub-heading">Hotel Description</h6>
        <div className="mb-5">
          {(loadingDetail) ? "loading" :
            hotelDescription.hotel_description ? hotelDescription.hotel_description : "Additional information not available"
          }
        </div>
        <h6 className="sub-heading">Most Popular facilities</h6>
        <div className="mb-5">
          {/* <h5 className="mb-1 mt-1">
          General <i className="material-icons fs-14 mr-2">info</i>
        </h5> */}
          {(Object.keys(roomFacilities).length!== 0) ? (roomFacilities &&
            roomFacilities.map((list, key) => (
              <p className="half" key={key}>
                <i className="material-icons fs-12 mr-2 text-primary">
                  done
              </i>
                {list}
              </p>
            ))) : "Additional information not available"}
        </div>
      </div>
    </div>
  );
};
export default AmenitiesRoomPage;
