import classNames from "classnames";

export const SearchResultItem = ({
  as = "li",
  tag: Tag = as,
  children,
  className,
  ...props
}) => (
  <Tag className={classNames(className)} {...props}>
    {children}
  </Tag>
);
