export const importTypes = [
  // {
  //   label: 'Attendee',
  //   value: 'ATTENDEE'
  // },
  {
    label: 'Employee',
    value: 'EMPLOYEE'
  }
  // ,{
  //   label: "Exchange Rate",
  //   value: "EXCHANGE RATE"
  // }
];