import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import BookingHomePage from "./home/home";
import FlightOneWayPage from "./flight/oneway";
import TravelRequestDetailsPage from "./checkout/travel-request-details";
import FlightRoundPage from "./flight/round";
import FlightMultiPage from "./flight/multi";
import FlightPage from "./home/flight-components/flight";
import BusPage from "./home/bus";
import CabPage from "./home/CabPage";
import TrainPage from "./home/train-components/train";
import HotelPage from "./home/hotel-components/hotel";
import HotelListPage from "./hotel/hotel";
import HotelDetailsPage from "./hotel/hotel-details";
import SubmitPage from "./checkout/submit";
import { CabListPage, RentalPage } from "./cab";
import BusListPage from "./bus/bus";
import TrainListPage from "./train/train";
import VisaPage from "./home/visa";
import CheckoutManiPage from "./checkout/checkout-main";
import SubmitManiPage from "./submit/submit-main";
import { PageNotFound } from "../..";

class BookingPage extends Component {
  constructor(props) {
    super(props);
    if (
      props.location.pathname === "/booking" ||
      props.location.pathname === "/"
    ) {
      this.props.history.push("/booking/home");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/booking") {
      this.props.history.push("/booking/home");
    }
  }
  render() {
    return (
      <div className="BookingPage bg-light-blue">
        <div className="page-content">
          <Switch>
            <Route path="/booking/home" component={BookingHomePage} />
            <Route exact path="/booking/flight" component={FlightPage} />
            <Route exact path="/booking/bus" component={BusPage} />
            <Route exact path="/booking/cab" component={CabPage} />
            <Route exact path="/booking/train" component={TrainPage} />
            <Route exact path="/booking/hotel" component={HotelPage} />
            <Route
              path="/booking/flight/oneway/:data"
              component={FlightOneWayPage}
            />

            <Route path="/booking/checkout" component={CheckoutManiPage} />
            <Route path="/booking/submit" component={SubmitManiPage} />
            <Route
              path="/booking/checkout/travel-request-details"
              component={TravelRequestDetailsPage}
            />
            {/* <Route path="/booking/checkout/itinerary" component={ItineraryPage} />
            <Route
              path="/booking/checkout/travel-request-details"
              component={TravelRequestDetailsPage}
            />
            <Route path="/booking/checkout/expense" component={ExpensePage} />
            <Route
              path="/booking/checkout/travel-document"
              component={TravelDocumentPage}
            />
            <Route path="/booking/checkout/submit" component={SubmitPage} /> */}
            <Route
              path="/booking/flight/round/:data"
              component={FlightRoundPage}
            />
            <Route
              path="/booking/flight/multi/:data"
              component={FlightMultiPage}
            />
            <Route path="/booking/hotel/list/:data" component={HotelListPage} />
            <Route
              exact
              path="/booking/hotel/details/:id/:name/:duration/:query"
              component={HotelDetailsPage}
            />
            <Route
              exact
              path="/booking/hotel/details/:id"
              component={HotelDetailsPage}
            />
            <Route path="/booking/cab/find-cab/:request" component={CabListPage} /> 
            <Route path="/booking/cab/rental-cab" component={RentalPage} />
            <Route path="/booking/bus/list" component={BusListPage} />
            <Route exact path="/booking/train/list/:fromLocationName/:fromLocationCode/:toLocationName/:toLocationCode/:departureDate" component={TrainListPage} />
            <Route path="/booking/train/request-list/:TrainData" component={TrainListPage} />
            <Route path="/booking/visa" component={VisaPage} />
            <Route path="**" component={PageNotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}
export default BookingPage;
