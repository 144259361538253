import React, { Component } from 'react';
import AddToCart from './AddToCart';

export default class trainDetails extends Component {

    type = 1;
    getFormattedDate = (date) => {
        try{

            let splittedDate = date.split(' ');
            return splittedDate[0] + " " + splittedDate[1] + " " + splittedDate[2] + " " + splittedDate[3]
        }catch(err){

        }
    }
    render() {
        const {trainClassInfo, trainDetails, trainCode, selectedSeatId, handleSeatClick} = this.props;
        return (
            <div className="more-details">
                <ul>
                    <li>
                        <button
                            className={this.type === 1 ? "btn active" : "btn"}
                            onClick={e => this.changeWay(1)}
                        >
                            {" "}
                            Details
                  </button>
                    </li>
                    {/* <li>
                        <button
                            className={this.type === 2 ? "btn active" : "btn"}
                            onClick={e => this.changeWay(2)}
                        >
                            {" "}
                            Fare Details
                        </button>
                    </li> */}
                </ul>
                {this.type === 1 && (
                    <div className="devision">
{/*
                        <div className="white-card border-only train-seats">
                            <div className="form-group custom mb-0">
                                <div className="dropdown">
                                    <button
                                        type="button"
                                        className="btn dropdown-toggle"
                                        data-toggle="dropdown"
                                    >
                                        General
                              </button>
                                    <div className="dropdown-menu">
                                        <button className="dropdown-item">General</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="white-card shadow-light mb-0">
                            <div className="seat-prices">
                                <div className="row">
                                    <div className="col-md-8">
                                    {
                                            trainDetails &&  trainDetails.result && trainDetails.result.length && trainDetails.result[0].code ? (
                                                trainDetails.result.map(item => {
                                                  return (
                                                    <div>
                                                        {item.message}
                                                    </div>
                                                  )
                                              })
                                            ) : null

                                        }
                                        {
                                            trainDetails &&  trainDetails.result && trainDetails.result.length && !trainDetails.result[0].code ? (
                                                trainDetails.result.map(item => {
                                                  return (
                                                    <button onClick={e => handleSeatClick(item, trainCode)} key={item.search_result_id} className={selectedSeatId == item.search_result_id ? "btn active" : "btn"}>
                                                        <p className="fs-18">
                                                            <b> INR {item.total_fare}</b>
                                                        </p>
                                                        <p>{this.getFormattedDate(item.date)}</p>
                                                        {
                                                            item.availability
                                                        }
                                                        {/* <p className="text-danger">{seatDetails.availability.AVBL}</p> */}
                                                    </button>
                                                  )
                                              })
                                            ) : null

                                        }
                                        </div>
                                        {
                                            trainDetails &&  trainDetails.result && trainDetails.result.length && !trainDetails.result[0].code &&
                                    <AddToCart />
                                        }
                                        {/* <button className="btn">
                                            <p className="fs-18">
                                                <b>INR 20</b>
                                            </p>
                                            <p>Mon, 6th Nov</p>
                                            <p className="text-danger">AVBL-0001</p>
                                        </button> */}
                                        {/* <button className="btn active">
                                            <p className="fs-18">
                                                <b>INR 20</b>
                                            </p>
                                            <p>Mon, 6th Nov</p>
                                            <p className="text-danger">AVBL-0001</p>
                                        </button> */}



                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.type === 2 && (
                    <div className="devision">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="border-right">
                                    <h6 className="sub-heading">Super Saver Fare</h6>

                                    <ul className="locations border-bottom-0 pl-0 bus-more-details">
                                        <li>
                                            This ticket cannot be refunded or exchanged.
                  </li>
                                        <li>
                                            Super Saver fares also can be used to travel
                                            on regional trains.
                  </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <div className="alert">
                                        <strong>!</strong> This ticket does not
                                        guarantee you a seat on the train
                </div>
                                    <div className="row">
                                        <div className="col-md-9">
                                            <p>
                                                Reserve a seat for your 6 h 30 m journey.
                                                This fee is not refundable.
                    </p>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="text-right">
                                                <span className="mr-2">INR2.00</span>
                                                <label className="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>



        )
    }
}
