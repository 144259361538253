import React, { Component } from 'react';
import Select from 'react-select'

class CashadvanceTableModal extends Component {
  render() {
    let cashadvanceData, requestedAmountIndex, approvedAmount, selectedCurrency;
    cashadvanceData = this.props.selectedCashadvanceData;
    requestedAmountIndex = cashadvanceData.data.findIndex((fields) => fields.columnName === 'AMOUNT');
    approvedAmount = cashadvanceData.amountApproved;
    selectedCurrency = this.props.cashAdvanceCurrency;
    return (
      <div className="table-container mx-0">
        <div className="row">
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Amount Requested</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">
              {/* {selectedCurrency.length > 0 && selectedCurrency[0].itemCode} {requestedAmountIndex !== -1 && cashadvanceData.data[requestedAmountIndex].fieldValue} */}
              {selectedCurrency.length > 0 && selectedCurrency[0].itemCode}&nbsp;
              {
                cashadvanceData && cashadvanceData.requestedAmount
              }
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Amount Issued In Base Currency</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">{this.props.baseCurrency}&nbsp;{this.props.amountIssue ? parseFloat(this.props.amountIssue * this.props.exchnageRateAmount).toFixed(2) : 0}</p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Amount Issued in Requested Currency</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">{selectedCurrency.length > 0 && selectedCurrency[0].itemCode} &nbsp;{parseFloat(this.props.amountIssue * this.props.exchangeRateToSelcetdCurr).toFixed(2)}</p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Amount Approved</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">{approvedAmount && selectedCurrency.length > 0 && selectedCurrency[0].itemCode}&nbsp;{approvedAmount ? approvedAmount : '-'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="form-group custom labelup mb-0">
              <label>Currency  <span className="text-danger">*</span></label>
              <Select
                isClearable
                // placeholder="Select Cash Mode"
                value={this.props.selectedCurrency}
                onChange={this.props.onCurrencyChnageHandler}
                options={this.props.currencyList} />
            </div>
            <div className="text-danger fs-12">
              {this.props.validator.message("Currency", this.props.selectedCurrency ?.value, "required")}
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="form-group custom mb-0">
              <input type="number"
                value={this.props.amountIssue}
                className="form-control"
                onChange={this.props.onChnageApprovalAmountHandler}
                placeholder="&nbsp;"
              />
              <label>Amount Issued  <span className="text-danger">*</span></label>
            </div>
            <div className="text-danger fs-12">
              {this.props.validator.message("Amount Issued", this.props.amountIssue, "required")}
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="form-group custom mb-0">
              <input type="number"
                placeholder="&nbsp;"
                onChange={this.props.onChnageExchangeChangeHandler}
                value={this.props.exchangeRateToSelcetdCurr}
                className="form-control" />
              <label>Exchange Rate</label>
            </div>
            <div className="text-danger fs-12">
              {this.props.validator.message("Exchange Rate", this.props.exchangeRateToSelcetdCurr, "required")}
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="form-group custom mb-0">
              <input type="number"
                placeholder="&nbsp;"
                onChange={this.props.handleBaseCurrencyExchangeHandler}
                value={this.props.baseExchangeRate}
                className="form-control" />
              <label>Exchange Rate In BaseCurrency</label>
            </div>
            <div className="text-danger fs-12">
              {this.props.validator.message("Exchange Rate", this.props.baseExchangeRate, "required")}
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="form-group custom labelup mb-0">
              <label>Mode  <span className="text-danger">*</span></label>
              <Select
                isClearable
                placeholder="Select Cash Mode"
                value={this.props.cashadvanceModeValue}
                onChange={this.props.onModeChnageHandler}
                options={this.props.cashAdvanceModes} />
            </div>
            <div className="text-danger fs-12">
              {this.props.validator.message("Cash Mode", this.props.cashadvanceModeValue ?.value, "required")}
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="form-group custom mb-0">
              <input type="text"
                Placeholder="&nbsp;"
                onChange={this.props.onVoucherChnageHandler}
                value={this.props.cashVoucher}
                className="form-control" />
              <label>Voucher</label>
            </div>
          </div>
        </div >
        <div className="row">
          <div className="col-md-8">
            <div className="form-group mb-0 custom textarea">
              <textarea rows="2"
                data-gramm_editor="false"
                spellCheck="false"
                onChange={this.props.onCommentChnageHandler}
                value={this.props.comment}
                className="form-control" placeholder="&nbsp;" />
              <label>Comment</label>
            </div>
          </div>
          <div className="col-md-4 minus-mr">
            <div className="upload-input p-0 text-center">
              <input type="file" multiple onChange={(e) => this.props.onFileBrowse(e)} />
              <div className="py-3 check-box">
                <i className="material-icons">cloud_upload</i>
                <div>Upload Voucher</div>
              </div>

            </div>
            {
              this.props.uploadReceiptUrl.map((images, index) => <div
                key={index}
                className="uploadimage mt-2"
                style={{ backgroundImage: `url(${images})` }}
              />)
            }
          </div>

        </div>
      </div >
    )
  }
}

export default CashadvanceTableModal;