import React from "react";
import { Input, Select } from "../../../../../common/_forms";
import DatePicker from "react-datepicker";
const EditCompanyProfilePage = props => {
  let path = "url(/assets/images/logo.png)";
  let path1 = "url(/assets/images/favicon.png)";
  const profileImg = {
    backgroundImage: path
  };
  const profileImg1 = {
    backgroundImage: path1
  };
  const {
    getFormatList,
    state,
    error,
    decimalSeparatorName,
    decimalSeperator,
    dateFormatName,
    languageList,
    languageName,
    getCountry,
    currencyName,
    updateProfile,
    goBack
  } = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">E</div>
          <div className="right">
            <h6 className="heading">Edit - Company Profile</h6>
            <p className="text">Lorem ipsum is simply dummy text.</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <h5 className="sub-heading">Basic Details</h5>
          <form onSubmit={updateProfile} autoComplete="off">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group custom">
                  <Input
                    label="Company Name"
                    type="text"
                    name="companyName"
                    error={error.message(
                      "companyName",
                      state.companyName,
                      "required"
                    )}
                    value={state.companyName}
                    controlFunc={e => props.handleChange(e, "companyName")}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label="Company Phone"
                    type="text"
                    name="companyPhone"
                    error={error.message(
                      "companyPhone",
                      state.companyPhone,
                      "required"
                    )}
                    value={state.companyPhone}
                    controlFunc={e => props.handleChange(e, "companyPhone")}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label="Company Email"
                    type="email"
                    name="companyEmail"
                    error={error.message(
                      "companyEmail",
                      state.companyEmail,
                      "required"
                    )}
                    value={state.companyEmail}
                    controlFunc={e => props.handleChange(e, "companyEmail")}
                  />
                </div>
                {/* <div className="form-group custom">
                                <Input
                                    label="Company Fax"
                                    type="text"
                                    name="companyFax"
                                    error={error.message('companyFax', state.companyFax, 'required')}
                                    value={state.companyEmail}
                                    controlFunc={e => props.handleChange(e, "companyFax")}
                                />
                            </div> */}
                {/* <h5 className="sub-heading">Defaults</h5>
                <div className="form-group custom labelup">
                  <label>Decimal Separator</label>
                  {decimalSeperator && (
                    <Select
                      placeholder={decimalSeparatorName}
                      keyName={"decimalSeparator"}
                      options={decimalSeperator}
                      controlFunc={e => props.changeData(e, "decimalSeparator")}
                      error={error.message(
                        "decimalSeparator",
                        state.decimalSeparator,
                        "required"
                      )}
                    />
                  )}
                </div>
                <div className="form-group custom labelup">
                  <label>Default Currency</label>
                  {getCountry && (
                    <Select
                      placeholder={currencyName}
                      keyName={"currency"}
                      options={getCountry}
                      controlFunc={e => props.changeData(e, "defaultCurrency")}
                      error={error.message(
                        "defaultCurrency",
                        state.defaultCurrency,
                        "required"
                      )}
                    />
                  )}
                </div>
                <div className="form-group custom labelup">
                  <DatePicker
                    className="form-control"
                    placeholderText="&nbsp;"
                    selected={state.fiscalYearDate}
                    dateFormat="yyyy/MM/dd"
                    onChange={e => props.handleChangeDate(e, "fiscalYearDate")}
                  />
                  <label>Fiscal Year Start Date</label>
                  <div className="help-block">
                    {error.message(
                      "fiscalYearDate",
                      state.fiscalYearDate,
                      "required"
                    )}
                  </div> */}
                {/* <Input
                                    label="Fiscal Year Start Date"
                                    type="date"
                                    name="fiscalYearDate"
                                    error={error.message('fiscalYearDate', state.fiscalYearDate, 'required')}
                                    value={state.fiscalYearDate}
                                    controlFunc={e => props.handleChange(e, "fiscalYearDate")}
                                /> */}
                {/* </div>
                <div className="form-group custom labelup">
                  <label>Date Format</label>
                  {getFormatList && (
                    <Select
                      placeholder={dateFormatName}
                      keyName={"dateFormat"}
                      options={getFormatList}
                      controlFunc={e => props.changeData(e, "dateFormat")}
                      error={error.message(
                        "dateFormat",
                        state.dateFormat,
                        "required"
                      )}
                    />
                  )}
                </div>
                <div className="form-group custom labelup">
                  <label>Default Language</label>
                  {languageList && (
                    <Select
                      placeholder={languageName}
                      keyName={"languageName"}
                      options={languageList}
                      controlFunc={e => props.changeData(e, "defaultLanguage")}
                      error={error.message(
                        "defaultLanguage",
                        state.dateFormat,
                        "required"
                      )}
                    />
                  )}
                </div> */}
              </div>
              <div className="col-md-5">
                {/* <div className="upload-input1">
                            <div className="uploaded" style={profileImg}>
                                <span className="d-none">Nothing Uploaded</span>
                            </div>
                            <div className="upload">
                                <input type="file" />
                                Upload Logo
                                </div>
                            <p>Drag and drop here to upload</p>
                        </div> */}
                {/* <div className="upload-input1">
                            <div className="uploaded" style={profileImg1}>
                                <span className="d-none">Nothing Uploaded</span>
                            </div>
                            <div className="upload">
                                <input type="file" />
                                Upload Logo
                                </div>
                            <p>Drag and drop here to upload</p>
                        </div> */}
              </div>
              <div className="col-12">
                <div className="btn-group-margin">
                  <button
                    type="submit"
                    className="btn btn-border-success lg-padding"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={goBack}
                    className="btn btn-border-basic lg-padding"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EditCompanyProfilePage;
