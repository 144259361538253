import React from "react";
import Select from 'react-select';

const AddCashAdvanceConfigPage = props => {
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">A</div>
          <div className="right">
            <h6 className="heading">Add Cash Advance Config</h6>
            <p className="text">Add/Modify Cash Advance Configuration.</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="">
            <div className="row">
              <div className="col-lg-6 col-xl-4">
                <form>
                  <div className="form-group custom">
                    <input
                      onChange={props.onInputChangeHandler}
                      type="text"
                      placeholder="&nbsp;"
                      className="form-control"
                    />
                    <label>Config Name <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-group custom labelup">
                    <label>Cash Advance Workflow <span className="text-danger">*</span></label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        {props.cashWorkFlow?props.cashWorkFlow:'Select...'}
                      </button>
                      <div className="dropdown-menu">
                      {props.cashWorkFlowList?props.cashWorkFlowList.map((list,index)=>{
                        return(
                        <button key={index} type="button" onClick={(e)=>props.onWorkFlowHandler(list.workflowName,list.workflow_id)}  className="dropdown-item">
                          {list.workflowName}
                        </button>
                        )}):''
                      }
                      </div>
                    </div>
                  </div>
                  <div className="form-group custom labelup">
                    <label>Cash Advance Form <span className="text-danger">*</span></label>
                    <div className="dropdown">

                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                      {props.cashAdvanceForm?props.cashAdvanceForm:'Select...'}
                      </button>
                      <div className="dropdown-menu">
                      {props.cashAdvanceFormList.length?props.cashAdvanceFormList.map((list,index)=>{
                        return(
                        <button key={index} type="button" onClick={(e)=>props.onAdvFormHandler(list.formName,list.id)}  className="dropdown-item">
                          {list.formName}
                        </button>
                        )}):''
                      }
                    </div>

                    </div>
                  </div>
                  <div className="form-group custom labelup textarea">
                    <label>Applies To <span className="text-danger">*</span></label>
                    <Select
                        value={props.appliesTo}
                        onChange={props.onAppliesHandler}
                        options={props.appliesToList}
                        isMulti
                    />
                  </div>
                  <div className="form-group custom labelup textarea">
                    <label>Editable By <span className="text-danger">*</span></label>
                    <Select
                        value={props.editedby}
                        onChange={props.onEditedHandler}
                        options={props.editedbyList}
                        isMulti
                    />
                  </div>
                  <div className="btn-group-margin">
                    <button
                    onClick={props.onSubmitHandler}
                    className="btn">
                      Save
                    </button>
                    <button
                    onClick={props.onCancelHandler}
                    className="btn">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddCashAdvanceConfigPage;
