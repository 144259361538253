import { toast } from "react-toastify";
import { Component } from "react";
import PropTypes from "prop-types";
import {
  withGoogleMap,
  GoogleMap as GoogleMapReact,
  DirectionsRenderer
} from "react-google-maps";
import { createLogger } from "src/_helpers";
import { withProps, compose } from "recompose";

const googleMapLogger = createLogger("GoogleMap");

let GoogleMap = class extends Component {
  static propTypes = {
    origin: PropTypes.object,
    destination: PropTypes.object,
    defaultCenter: PropTypes.object
  };

  state = {
    directions: null
  };

  findRouteLogger = googleMapLogger.extend("findRoute");
  findRoute = () => {
    const {
      origin,
      destination,
      travelMode = google.maps.TravelMode.DRIVING
    } = this.props;

    if (!origin || !destination) {
      this.setState({
        directions: null
      });
      return;
    }

    this.findRouteLogger(
      "Redirecting using new (origin: {lat: %s, lng: %s}) and (destination: {lat: %s, lng: %s}) using (travel mode - %s)...",
      origin.lat(),
      origin.lng(),
      destination.lat(),
      destination.lng(),
      travelMode
    );

    const DirectionsService = new google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode
      },
      (result, status) => {
        this.findRouteLogger("Result: %O", result);
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result
          });
        } else {
          toast.error(`Error fetching directions ${result}`);
        }
      }
    );
  };

  componentDidMount() {
    googleMapLogger("Mounting GoogleMap...");
    this.findRoute();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.origin !== this.props.origin ||
      prevProps.destination !== this.props.destination
    ) {
      googleMapLogger("Updating GoogleMap using new origin and destination...");
      this.findRoute();
    }
  }

  render() {
    const { children } = this.props;
    const { directions } = this.state;

    return (
      <GoogleMapReact
        defaultZoom={8}
        defaultCenter={{ lat: -34.397, lng: 150.644 }}
      >
        {directions && <DirectionsRenderer directions={directions} />}
        {children}
      </GoogleMapReact>
    );
  }
};

GoogleMap = compose(
  withProps({
    containerElement: <div style={{ height: `100vh` }} />,
    mapElement: <div style={{ height: `100vh` }} />
  }),
  withGoogleMap
)(GoogleMap);

export { GoogleMap };
