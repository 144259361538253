import React, { Component } from "react";
import { userService } from "./_services";

class SuperAdminAuth extends React.Component {
    constructor(props) {
      super(props); 
      localStorage.clear()
      this.companyId = props.match.params.companyId;
      this.token =  props.match.params.token; 
    }
    componentDidMount(){
        userService.adminAuth(this.companyId,this.token)
        .then(
            data =>{
                this.props.history.push('/');
            },error =>{
                this.props.history.push('/login');
            }
        )
    }
    render() { 
        return(
            <div>Please Wait</div>
        )
    }  
  }
  export default SuperAdminAuth;
