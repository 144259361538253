import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { NavLink } from 'react-router-dom';
import { userService } from '../../_services';
import { toast } from 'react-toastify';
import { Input } from '../common/_forms';

class VerifyCode extends Component {
    constructor(props) {
        localStorage.clear();
        super(props)
        this.validator = new SimpleReactValidator();
        this.state = {
            companyCode: '',
            email: '',
            verifyCode: ''
        };
    }
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    loading = false
    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            const { email, verifyCode } = this.state;
            // sessionStorage.setItem('resetDataEmail',email);
            if (email && verifyCode) {
              let res = await userService.verifyCodePassword(this.state);
              if(res != undefined){

                sessionStorage.setItem('resetDataEmail',email);
                this.props.history.push('/reset-password');
              }

            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }
    }
    render() {

        return (
            <div className="login-ext">
                <div className="logo">
                    <img src="/assets/images/left-menu-logo1.png" alt="Logo" />
                </div>
                <div className="d-table d-table__middle">
                    <div>
                        <form name="loginForm" autoComplete="off" onSubmit={this.handleSubmit}>
                            <div className="center-content">
                                <h2>Verify Account</h2>
                                <p><b>Please Check your mail for the verification code.</b></p>
                                {/* <div className="form-group custom">
                                    <Input
                                        label="Customer Code"
                                        type="text"
                                        name="companyCode"
                                        value={this.state.customerCode}
                                        error={this.validator.message('companyCode', this.state.companyCode, 'required')}
                                        controlFunc={e => this.handleChange(e)}
                                    />
                                </div> */}
                                <div className="form-group custom">
                                    <Input
                                        label="Enter Email"
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        error={this.validator.message('email', this.state.email, 'required|email')}
                                        controlFunc={e => this.handleChange(e)}
                                    />
                                </div>
                                <div className="form-group custom">
                                    <Input
                                        label="Enter Verify Code"
                                        type="text"
                                        name="verifyCode"
                                        value={this.state.verifyCode}
                                        error={this.validator.message('verifyCode', this.state.verifyCode, 'required')}
                                        controlFunc={e => this.handleChange(e)}
                                    />
                                </div>
                                <button type="submit" className="btn btn-block mb-4">Verify</button>
                                <p className="link">or <NavLink to="/login">Login Here</NavLink></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default VerifyCode;