import React from "react";
import uuid from "uuid";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate
} from 'react-day-picker/moment';
import _ from "lodash";
import DynamicFieldsType from '../../../../common/dynamicFields';
import AsyncSelect from "react-select/lib/Async";
import SelectSearch from "src/_components/common/_forms/SelectSearchMulti"
import { getValue, setValue } from "src/_components/common/lodsh";

const allocationDynamicForm = props => {
  return props.allocationForm ? props.allocationForm.map((form, index) => {
    let formFieldName = form.fieldName;
    let userPermission = props.userPermission;
    let permissions = form.fieldRolePermissions && form.fieldRolePermissions;
    let newPermissionArray = permissions && permissions.map((permsList) => (permsList.roleId.roleKey));
    let permissionIndex = newPermissionArray && newPermissionArray.indexOf(userPermission);
    let accessPermission = 2;
    if (permissionIndex >= 0) {
      accessPermission = parseInt(permissions && permissions[permissionIndex].permissionId);
    } else {
      accessPermission = 2;
    }
    switch (form.dataType) {
      case DynamicFieldsType.DATA_TYPE_CONNECTED_LIST:
        let NewformFieldName = form.columnName;
        if (accessPermission > 0 && form.activeStatus) {
          if (form.fieldLevel > 1) {
            let selectedDropDown = '';
            formFieldName = _.toUpper(NewformFieldName);
            let originalField = _.startCase(_.camelCase(form.fieldName));
            return (
              <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
                <div className="form-group custom" key={uuid.v4()}>
                  <div className="dropdown" >
                    <button
                      type="button"
                      className="btn dropdown-toggle bg-white table-btn"
                      data-toggle="dropdown"
                      disabled={accessPermission === 1}
                    >
                      {props[formFieldName + props.rowIndex] && props[formFieldName + props.rowIndex].forEach(element => {
                        if (props[originalField + props.rowIndex] === element.id) {
                          selectedDropDown = element.itemName;

                        }
                      })}
                      {selectedDropDown ? selectedDropDown : form.labelName}
                    </button>
                    <div className="dropdown-menu">
                      {props[formFieldName + props.rowIndex] && props[formFieldName + props.rowIndex].map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onConnectedListkHandler(originalField + props.rowIndex, form.multiSelectParentField, opt.id, form.fieldLevel, props.rowIndex)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.itemName}
                            </button>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName + props.rowIndex],
                      "required|string"
                    )}
                  </div>
                ) : (
                    ""
                  )}

              </td>
            );
          } else {
            let selectedDropDown = '';
            return (
              <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
                <div className="form-group custom" key={uuid.v4()}>
                {form.connectedListData &&
                        form.connectedListData.listManagementData.forEach(
                          element => {
                            if (props[formFieldName] === element.id) {
                              selectedDropDown = element.itemName;
                            }
                          }
                        )}
                      {}
                  {form.connectedListData && form.connectedListId &&

                  <SelectSearch
                    options={form.connectedListData.listManagementData}
                    keyName={"itemName"}
                    refId={form.connectedListId}
                    assignKey={index}
                    page={form.page}
                    form={form}
                    handleSearch={props.handleSearchManage}
                    count={form.connectedListDataCount}
                    handleScroll={props.handleScroll}
                    onClickHandler={props.onClickHandler}
                    placeholder={selectedDropDown ? selectedDropDown : "Select an option"}
                    disabled={
                      accessPermission === 1 ||
                      !(props.isEdit || form.columnName !== "GROUP_NAME")
                    }
                  />
          }

                  </div>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName + props.rowIndex],
                      "required|string"
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </td>
            );
          }
        }break;
      case DynamicFieldsType.DATA_TYPE_TEXT:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
              <div className="form-group custom mb-0">
                <input
                  type="text"
                  placeholder="&nbsp;"
                  readOnly={accessPermission === 1}
                  name={form.fieldName + props.rowIndex}
                  onChange={e => props.onChangeHandler(e, form.fieldName + props.rowIndex)}
                  value={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : ""}
                  className="form-control"
                />
              </div>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName + props.rowIndex],
                    `required|string|max:${form.maxLength || 10000}`
                  )}
                </div>
              ) : (
                  ""
                )}
            </td>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_DATE:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
              <div className="form-group  mb-0">
                <DayPickerInput
                  format="DD MMM YYYY"
                  formatDate={formatDate}
                  inputProps={{ locale: 'en-gb', localeUtils: MomentLocaleUtils, disabled: accessPermission === 1 }}
                  dayPickerProps={{ disabledDays: { after: new Date() } }}
                  selectedDays={
                    props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : new Date()
                  }
                  onDayChange={e => props.onDatePickerHandler(e, form.fieldName + props.rowIndex)}
                  placeholder={form.labelName}
                />
              </div>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName + props.rowIndex],
                    "required"
                  )}
                </div>
              ) : (
                  ""
                )}
            </td>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_TEXTAREA:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
              <div className="form-group  mb-0">
                <textarea
                  data-gramm_editor="false"
                  spellCheck="false"
                  rows="3"
                  name={form.fieldName + props.rowIndex}
                  className="form-control"
                  readOnly={accessPermission === 1}
                  onChange={e => props.onChangeHandler(e, form.fieldName + props.rowIndex)}
                  value={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : ""}
                  placeholder="&nbsp;"
                />
              </div>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName + props.rowIndex],
                    `required|string|max:${form.maxLength || 10000}`
                  )}
                </div>
              ) : (
                  ""
                )}
            </td>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_CHECKBOX:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <>
              <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
                <label className="switch">
                  <input
                    type="checkbox"
                    disabled={accessPermission === 1}
                    value={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : false}
                    checked={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : false}
                    onChange={e => props.onCheckboxHandler(form.fieldName + props.rowIndex)}
                  />
                  <span className="slider round" />
                </label>
                <span>{form.labelName}</span>
              </td>
              <div className="help-block">{props.error[form.fieldName + props.rowIndex]}</div>
            </>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_BOOLEAN:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <>
              <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
                <label className="switch">
                  <input
                    disabled={accessPermission === 1}
                    type="checkbox"
                    value={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : false}
                    checked={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : false}
                    onChange={e => props.onCheckboxHandler(form.fieldName + props.rowIndex)}
                  />
                  <span className="slider round" />
                </label>
                <span>{form.labelName}</span>
              </td>
              <div className="help-block">{props.error[form.fieldName + props.rowIndex]}</div>
            </>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_INTEGER:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
              <div className="form-group  mb-0">
                <input
                  type="text"
                  placeholder="&nbsp;"
                  readOnly={accessPermission === 1}
                  name={form.fieldName + props.rowIndex}
                  onChange={e => props.onIntegerHandler(e, form.fieldName + props.rowIndex)}
                  value={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : ""}
                  className="form-control"
                />
              </div>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName + props.rowIndex],
                    `required|integer|max:${form.maxLength || 10000}`
                  )}
                </div>
              ) : (
                  ""
                )}
            </td>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_AMOUNT:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
              <div className="form-group custom mb-0">
                <input
                  type="text"
                  placeholder="&nbsp;"
                  readOnly={accessPermission === 1}
                  name={form.fieldName + props.rowIndex}
                  onChange={e => props.onIntegerHandler(e, form.fieldName + props.rowIndex)}
                  value={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : ""}
                  className="form-control"
                />
              </div>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName],
                    `required|integer|max:${form.maxLength || 10000}`
                  )}
                </div>
              ) : (
                  ""
                )}
            </td>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_EMAIL:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
              <div className="form-group custom mb-0">
                <input
                  type="text"
                  placeholder="&nbsp;"
                  readOnly={accessPermission === 1}
                  name={form.fieldName + props.rowIndex}
                  onChange={e => props.onChangeHandler(e, form.fieldName + props.rowIndex)}
                  value={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : ""}
                  className="form-control"
                />
              </div>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName],
                    `required|email|max:${form.maxLength || 10000}`
                  )}
                </div>
              ) : (
                  ""
                )}
            </td>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_SEARCH:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <td key={index} className="pt-3 pb-1 align-top">
              <div className="">
                <div className="form-group labelup custom mb-0">
                  <AsyncSelect
                    noOptionsMessage={({ inputValue }) => inputValue ? 'No results.' : "Start typing to search..."}
                    placeholder={form.fieldName}
                    loadOptions={props.loadOptions}
                    value={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : form.labelName}
                    onChange={(event) => props.onLocationChangekHandler(event, form.fieldName + props.rowIndex)}
                  />
                </div>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName + props.rowIndex]?.value,
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </td>
          );
        }break;

      case DynamicFieldsType.DATA_TYPE_SYSTEM_LIST:
        if (accessPermission > 0 && form.activeStatus) {
          let selectedDropDown = '';
          if (form.columnName === "PAYMENT_TYPE") {
            return (
              <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
                <div className="from-group custom mb-0" key={uuid.v4()}>
                  <div className="dropdown" >
                    <button
                      type="button"
                      className="btn dropdown-toggle bg-white table-btn"
                      data-toggle="dropdown"
                      disabled={accessPermission === 1}
                    >
                      {form.paymentDetails && form.paymentDetails.forEach(element => {
                        if (props[form.fieldName + props.rowIndex] === element.id) {
                          selectedDropDown = element.description;

                        }
                      })}
                      {selectedDropDown ? selectedDropDown : form.labelName}
                    </button>
                    <div className="dropdown-menu">
                      {form.paymentDetails && form.paymentDetails.map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onClickHandler(form.fieldName + props.rowIndex, form.id, opt.id, form.fieldLevel, props.rowIndex)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.description}
                            </button>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName + props.rowIndex],
                      "required|string"
                    )}
                  </div>
                ) : (
                    ""
                  )}

              </td>
            );
          } else {
            return (
              <td key={form.id + props.rowIndex} className="pt-3 pb-1 align-top">
                <div className="form-group custom" key={uuid.v4()}>
                  <div className="dropdown" >
                    <button
                      type="button"
                      className="btn dropdown-toggle bg-white table-btn"
                      data-toggle="dropdown"
                      disabled={accessPermission === 1}
                    >
                      {form.connectedListData && getValue(form,"connectedListData.listManagementData",[]).forEach(element => {

                        if (props[form.fieldName + props.rowIndex] === element.id) {
                          selectedDropDown = element.itemName;

                        }
                      })}
                      {selectedDropDown ? selectedDropDown : form.labelName}
                    </button>
                    <div className="dropdown-menu">
                      {form.connectedListData && form.connectedListData.listManagementData.map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onClickHandler(form.fieldName + props.rowIndex, form.id, opt.id, form.fieldLevel, props.rowIndex)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.itemName}
                            </button>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName + props.rowIndex],
                      "required|string"
                    )}
                  </div>
                ) : (
                    ""
                  )}

              </td>
            );
          }
        }break;
      default:
        return null;
    }
  }) : ''
};

export default allocationDynamicForm;
