
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import cryptr from "../../../../_helpers/crypty";
class BookingMenuPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: JSON.parse(cryptr.decrypt(localStorage["440a24"])),
        }
    }
    getFirstLastUserName = (fieldData) => {
        if (fieldData && Array.isArray(fieldData) && fieldData.length > 0) {
            let firstName = (fieldData.find(field => field.columnName === "EMPLOYEE_FIRST_NAME")).fieldValue;
            let lastName = (fieldData.find(field => field.columnName === "EMPLOYEE_LAST_NAME")).fieldValue;
            return firstName + ' ' + lastName;
        } else {
            return '';
        }
    }
    render() {
        return (
            <div>
                <h3 className="text-black fs-22 fw-bold text-center mt-4 mb-2">Hello,{" "}
                    {this.getFirstLastUserName(this.state.user.fieldData)}
                </h3>
                <p className="text-center">Search to book a Flight, Train, Car, Guest House and/or Hotel reservations.</p>
                <div className="booking">
                    <div className="booking-list">
                        <NavLink to="/booking/flight">
                            <img width="80" height="80" src="/assets/images/booking/flight_booking.svg" alt="Flight" />
                            <p>Flights</p>
                        </NavLink>
                        <NavLink to="/booking/hotel">
                            <img width="80" height="80" src="/assets/images/booking/hotel_booking.svg" alt="Hotel" />
                            <p>Hotels</p>
                        </NavLink>
                        <NavLink to="/booking/cab">
                            <img width="80" height="80" src="/assets/images/booking/car_booking.svg" alt="Cab" />
                            <p>Car</p>
                        </NavLink>
                        {/* {localStorage.getItem("440a28") !== "5dc5197e1382a2538aa3a8ca" &&
                        <NavLink to="/booking/bus">
                            <img width="80" height="80" src="/assets/images/booking/bus_booking.svg" alt="Bus" />
                            <p>Bus</p>
                        </NavLink>
                        } */}
                        <NavLink to="/booking/train">
                            <img width="80" height="80" src="/assets/images/booking/train_booking.svg" alt="Train" />
                            <p>Train</p>
                        </NavLink>


                        {/* <NavLink to="/booking/visa">
                            <img width="80" height="80" src="/assets/images/booking/visa.svg" alt="Visa" />
                            <p>Visa</p>
                        </NavLink> */}
                    </div>
                </div>
            </div>
        )
    }
}
export default BookingMenuPage
