import React from 'react'

export default function RuleClassDropdown(props) {
  const {
    name,
    id,
    dropdownClickHandler,
    ruleClass
  }  = props;
  return (
    <button onClick={e => dropdownClickHandler(id,name, ruleClass)} className="dropdown-item">
      {name}
    </button>
  )
}
