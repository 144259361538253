import React, { Component } from "react";
import { Route } from "react-router-dom";
import FinanceAdminPage from "./finance-admin";
import VerifiedPage from "./verified";

class FinanceMainPage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/expense") {
      this.props.history.push("/expense/home");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/expense") {
      this.props.history.push("/expense/home");
    }
  }
  render() {
    return (
      <div className="FinancePage bg-light-blue">
        <div className="page-content">
          <Route
            path="/finance-main/finance-admin"
            component={FinanceAdminPage}
          />
          <Route
            path="/finance-main/verified"
            component={VerifiedPage}
          />
        </div>
      </div>
    );
  }
}
export default FinanceMainPage;
