import React from "react";
import Menu from "../menu";
const TravelVendorExclusionPage = props => {
  return (
    <div>
      <div className="admin-tabs">
        {/* <Menu /> */}
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">T</div>
            <div className="right">
              <h6 className="heading">Travel Policy - Rules</h6>
              <p className="text">The Travel Policy & Rules feature allows administrators to manage company travel policy and configure violations.</p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <div className="col-search">
              <div className="column">
                <h6 className="sub-heading">Air Carriers</h6>
                <div className="search-custom">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder="Search Air Carriers"
                  />
                  <span><i className="material-icons">search</i></span>
                </div>
                <div className="scroll">
                  <ul>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Indigo(6E)</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Jet airways</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Air India</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>British Airways</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Emrites</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Etihad Airways</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Go Air</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Lufthansa</span>
                    </li>
                  </ul>
                </div>
                <div className="footer">
                  <ul>
                    <li>
                      <label className="switch grey">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Check All</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <h6 className="sub-heading">Hotel Chains</h6>
                <div className="search-custom">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder="Search Hotel Chains"
                  />
                  <span><i className="material-icons">search</i></span>
                </div>
                <div className="scroll">
                  <ul>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Hilton</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Sheraton</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Westin</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Four Seasons</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Ritz Carlton</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Hyatt</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Renaissance</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Embassy Suits</span>
                    </li>
                  </ul>
                </div>
                <div className="footer">
                  <ul>
                    <li>
                      <label className="switch grey">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Check All</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column last">
                <h6 className="sub-heading">Car Vendors</h6>
                <div className="search-custom">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder="Search Car Vendors"
                  />
                  <span><i className="material-icons">search</i></span>
                </div>
                <div className="scroll">
                  <ul>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Westin</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Seasons</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Carlton</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Hyatt</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Westin</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Seasons</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Carlton</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Seasons</span>
                    </li>
                    <li>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Carlton</span>
                    </li>
                  </ul>
                </div>
                <div className="footer">
                  <ul>
                    <li>
                      <label className="switch grey">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      <span>Check All</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="clearfix" />
            </div>
            <div className="btn-group-margin mt-4">
              <button className="btn btn-border-success lg-padding">
                Save
              </button>
              <button className="btn btn-border-basic lg-padding">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TravelVendorExclusionPage;
