import React, {Component} from "react";
import {NavLink} from 'react-router-dom';
class EditUserProfilePage extends Component {


  render(){
    const path = "url(/assets/images/jenni.jpg)";
    const profImg = {
      backgroundImage: path
    };

  return (
    <div>
      <div className="p-padding">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="col-md-6 offset-3">
              <div className="user-profile">
                <div className="img" style={profImg}>
                  <div className="upload">
                    Upload
                      <input type="file" />
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-card my-card add-margin">
              <div className="details">
                <h6 className="value">Personal Information</h6>
                <div className="form-group custom labelup">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Brandon"
                  />
                  <label>First Name</label>
                </div>
                <div className="form-group custom labelup">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Evans"
                  />
                  <label>Middle Name</label>
                </div>
                <div className="form-group custom labelup">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Evans"
                  />
                  <label>Last Name</label>
                </div>
                <div className="form-group custom labelup">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="+1 928 828 7388"
                  />
                  <label>Personal Phone</label>
                </div>
                <div className="form-group custom labelup">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Brandon@me.com"
                  />
                  <label>Email</label>
                </div>
                <div className="form-group custom labelup">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="10/12/1989"
                  />
                  <label>Date of Birth</label>
                </div>
                <div className="form-group custom labelup">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Greenfield Auto Services, 201 Sussex Ave, Newark, NJ 07103, USA"
                  />
                  <label>Home Address</label>
                </div>
                <div className="form-group custom labelup">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="38377"
                  />
                  <label>Employee Code</label>
                </div>
                <div className="form-group custom labelup">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Designer"
                  />
                  <label>Designation</label>
                </div>
                <div className="form-group custom labelup">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="UI/UX Designer"
                  />
                  <label>Title</label>
                </div>
                <div className="form-group custom labelup">
                  <label>Department</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      IT
                      </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                        </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                        </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                        </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Work Location</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Bangalore
                      </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                        </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                        </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                        </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Grades</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Grade 1
                      </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                        </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                        </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                        </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Greenfield Auto Services, 201 Sussex Ave, Newark, NJ 07103, USA"
                  />
                  <label>Work Address</label>
                </div>
              </div>
            </div>
            <div className="profile-card my-card">
              <div className="details">
                <h6 className="value">Vehicle Information</h6>
                <div className="form-group custom labelup">
                  <label>Vehicle Type</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      2 Wheeler
                      </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                        </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                        </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                        </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup mb-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="KA982829"
                  />
                  <label>Vehicle Number</label>
                </div>
              </div>
              <div className="padding">
                <button className="btn btn-nothing text-primary">
                  Add Vehicle
                  </button>
              </div>
            </div>
            <div className="profile-card my-card">
              <div className="details">
                <h6 className="value">Travel Class</h6>
                <div className="form-group custom labelup mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Evans"
                  />
                  <label>Level 1 Manager</label>
                </div>
              </div>
            </div>
            <div className="profile-card my-card">
              <div className="details">
                <h6 className="value">Managers</h6>
                <div className="form-group custom labelup mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Evans"
                  />
                  <label>Level 1 Manager</label>
                </div>
              </div>
            </div>
            <div className="profile-card my-card">
              <div className="details">
                <h6 className="value">Assign to Group</h6>
                <div className="form-group custom labelup mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Manager Group"
                  />
                  <label>Groups</label>
                </div>
              </div>
              <div className="btn-group-margin text-right">
                <NavLink
                  type="button"
                  className="btn bg-secondary btn-border-secondary text-white"
                >
                  CANCEL
                </NavLink>
                <button type="button" className="btn  ">
                  Save
                </button>
              <button type="button" className="btn  ">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
};
export default EditUserProfilePage;
