import React from "react";
import { NavLink } from "react-router-dom";
import WorkFlowStepsMenu from "../workflow-steps-menu";
import Select from "../../../../../common/_forms/select";
import Input from "../../../../../common/_forms/input";
import SelectNew from 'react-select';
const WorkflowsStep1Page = props => {

  const {
    state,
    handleEmailChange,
    handleDropdownChange,
    handleInputChange,
    handleGroupChange,
    handleCreate,
    handleUpdate,
    cancelHandler,
    processId,
    handleChangeD

  } = props;

  const {
    approvalTimeExpirestepOptions,
    selectedapprovalTimeExpirestep,

    workflowTypeOptions,
    selectedworkflowType,

    costObjectHierarchyOptions,
    selectedcostObjectHierarchy,

    allEmailTemplatesOptions,
    selectedexpirationEmail,

    selectedapprovalRequestNotification,
    selectedstateChangeNotification,
    selectedsentBackNotification,
    selectedaddingNewApproverNotification,
    selectedcourtesyEmail,
    selectedrequestNotification,
    selectedrejectNotification,

    allGroupOptions,
    editableByOptions,

    editMode,
    loading,
    allWorkflowSteps,
    selectedOption,
    days,
    hours,
    mins

  } = props.state;

  let optionsd = [
    { value: 'days', label: 'days' },
    { value: 'hours', label: 'hours' }
  ];
  let expirationEmailOptions = [
    { expirationEmail: "None" },
    {
      expirationEmail:
        "Copy of Default Expense Report Status Change Notification"
    },
    {
      expirationEmail:
        "Default Expense Report payment confirmation Notification"
    },
    { expirationEmail: "Default Expense Report Pending Notification" },
    { expirationEmail: "Default Expense Report Status Change Notification" }
  ];

  // console.log(selectedOption.value)
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">F</div>
          <div className="right">
            <h6 className="heading">Workflows - General</h6>
            <p className="text">Add/Modify Workflow Information</p>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <WorkFlowStepsMenu workflow_id={state.workflow_id} count={allWorkflowSteps.length} />
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div className="form-group custom labelup">
                <label>Workflow Type <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedworkflowType || "select an option"}
                  keyName={"master_name_value"}
                  options={workflowTypeOptions ? workflowTypeOptions['master_value'] : []}
                  controlFunc={e => handleDropdownChange(e, workflowTypeOptions, 'workflowType')}
                  error={props.error.message('workflow type', selectedworkflowType, 'required')}
                />
              </div>
              <div className="form-group custom">
                <Input
                  type="text"
                  value={state.workflowName}
                  placeholder="&nbsp;"
                  className="form-control"
                  name="workflowName"
                  imp="true"
                  label="Workflow Name"
                  controlFunc={e => handleInputChange(e, 'workflowName')}
                  error={props.error.message('workflow name', state.workflowName, 'required')}
                />
              </div>
              <div className="form-group custom labelup">
                <label>Approval Time Expired Action <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedapprovalTimeExpirestep || "select an option"}
                  keyName={"master_name_value"}
                  options={approvalTimeExpirestepOptions ? approvalTimeExpirestepOptions['master_value'] : []}
                  controlFunc={e => handleDropdownChange(e, approvalTimeExpirestepOptions, 'approvalTimeExpirestep')}
                  error={props.error.message('approval Time Expire step ', selectedapprovalTimeExpirestep, 'required')}
                />
              </div>


            <div className="form-group custom labelup textarea">
               <label>Select Days/Hours <span className="text-danger">*</span></label>
              <SelectNew
                  placeholder="Select Days or hours"
                  options={optionsd}
                  onChange={handleChangeD}
                  error={props.error.message('Expiration Type', selectedOption, 'required')}
                />
              </div>
              {selectedOption.value === "days"?
              <div className="form-group custom labelup">
                <Input
                  type="number"
                  value={state.expireAfter}
                  name="expireAfter"
                  placeholder="&nbsp;"
                  className="form-control"
                  label="Expire after this many days"
                  imp="true"
                  controlFunc={e => handleInputChange(e, 'expireAfter')}
                  error={props.error.message('Expire after ', state.expireAfter, 'required|numeric')}
                />
              </div>:""}

              {selectedOption.value === "hours"?
              <div className="form-group custom labelup without">
                <label>Select Hours/mins <span className="text-danger">*</span></label>
               <input
                type="number"
                value={hours}
                name="hours"
                className="form-control"
                placeholder="hours"
                onChange={e => handleInputChange(e, 'hours')}
                error={props.error.message('Expire after ', state.expireAfter, 'required|numeric')}
                style={{"width":"50%","display":"inline-block"}}
                />
                <input
                type="number"
                value={mins}
                name="mins"
                className="form-control"
                placeholder="mins"
                onChange={e => handleInputChange(e, 'mins')}
                error={props.error.message('Expire after ', state.expireAfter, 'required|numeric')}
                style={{"width":"50%","display":"inline-block","borderLeft":"1px solid #d6dbdf"}}
                />
              </div>:""}


              <div className="form-group custom labelup">
                <label>Expiration Email To Approver <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedexpirationEmail || "Select an Option"}
                  keyName={"name"}
                  options={allEmailTemplatesOptions}
                  controlFunc={e => handleEmailChange(e, 'expirationEmail')}
                  error={props.error.message('Expiration Email', selectedexpirationEmail, 'required')}
                />
              </div>
              <div className="form-group custom labelup textarea">
                <label>Editable By Groups</label>
                <SelectNew
                  placeholder="Search Groups"
                  value={allGroupOptions ? allGroupOptions.filter(item => editableByOptions.includes(item.value)) : 'Search Groups'}
                  options={allGroupOptions}
                  onChange={handleGroupChange}
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
              <div className="form-group custom labelup">
                <label>Cost Object Heirarchy Type <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedcostObjectHierarchy || "select an option"}
                  keyName={"master_name_value"}
                  options={costObjectHierarchyOptions ? costObjectHierarchyOptions['master_value'] : []}
                  controlFunc={e => handleDropdownChange(e, costObjectHierarchyOptions, 'costObjectHierarchy')}
                  error={props.error.message('Cost Object Heirarchy', selectedcostObjectHierarchy, 'required')}

                />
              </div>
              <div className="form-group custom labelup">
                <label>Request Sent Notification <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedrequestNotification || "Select an Option"}
                  keyName={"name"}
                  options={allEmailTemplatesOptions}
                  controlFunc={e => handleEmailChange(e, 'requestNotification')}
                  error={props.error.message('Request Sent Notification', selectedrequestNotification, 'required')}
                />
              </div>
              <div className="form-group custom labelup">
                <label>Approval Request Notification <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedapprovalRequestNotification || "Select an Option"}
                  keyName={"name"}
                  options={allEmailTemplatesOptions}
                  controlFunc={e => handleEmailChange(e, 'approvalRequestNotification')}
                  error={props.error.message('Approval Request Notification', selectedapprovalRequestNotification, 'required')}
                />
              </div>
              <div className="form-group custom labelup">
                <label>Reject Request Notification <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedrejectNotification || "Select an Option"}
                  keyName={"name"}
                  options={allEmailTemplatesOptions}
                  controlFunc={e => handleEmailChange(e, 'rejectNotification')}
                  error={props.error.message('Reject Request Notification', selectedrejectNotification, 'required')}
                />
              </div>

              <div className="form-group custom labelup">
                <label>Status Change Notification <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedstateChangeNotification || "Select an Option"}
                  keyName={"name"}
                  options={allEmailTemplatesOptions}
                  controlFunc={e => handleEmailChange(e, 'stateChangeNotification')}
                  error={props.error.message('Status Change Notification', selectedstateChangeNotification, 'required')}
                />
              </div>

              <div className="form-group custom labelup">
                <label>Sent Back Notification <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedsentBackNotification || "Select an Option"}
                  keyName={"name"}
                  options={allEmailTemplatesOptions}
                  controlFunc={e => handleEmailChange(e, 'sentBackNotification')}
                  error={props.error.message('Sent Back Notification', selectedsentBackNotification, 'required')}
                />
              </div>

              <div className="form-group custom labelup">
                <label>Approve and Forward Notification <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedaddingNewApproverNotification || "Select an Option"}
                  keyName={"name"}
                  options={allEmailTemplatesOptions}
                  controlFunc={e => handleEmailChange(e, 'addingNewApproverNotification')}
                  error={props.error.message('New Approver Notification', selectedaddingNewApproverNotification, 'required')}
                />
              </div>
              <div className="form-group custom labelup">
                <label>Courtesy Email <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedcourtesyEmail || "Select an Option"}
                  keyName={"name"}
                  options={allEmailTemplatesOptions}
                  controlFunc={e => handleEmailChange(e, 'courtesyEmail')}
                  error={props.error.message('Courtesy Email Notification', selectedcourtesyEmail, 'required')}
                />
              </div>
              {/* Submit and cancel button */}
              {(loading) ?
                <div className="btn-group-margin">
                  <button
                    className="btn btn-border-success lg-padding"
                    onClick={handleCreate}
                  >
                    Please wait...
                </button>
                </div>
                :
                <div className="">
                  {editMode ?
                    <div className="btn-group-margin">
                      {(state.isUpdate) ?
                        <button
                          className="btn"
                          onClick={handleUpdate}
                        >
                          Update & Next
                        </button> :
                        <NavLink className="btn mr-3" to={`/administration/company/workflows/workflow-step2/${processId}`}>Next</NavLink>
                      }
                      <button className="btn" onClick={cancelHandler}>
                        Cancel
                  </button>
                    </div>
                    :
                    <div className="btn-group-margin">
                      <button
                        className="btn"
                        onClick={handleCreate}
                      >
                        Save & Next
                      </button>
                      <button className="btn" onClick={cancelHandler}>
                        Cancel
                  </button>
                    </div>
                  }
                </div>
              }
              {/* End of submit and cancel button */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WorkflowsStep1Page;
