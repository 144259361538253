import {http} from '../_helpers';
import { config } from '../env';



const getAirport = (location) => {
  let url = `${config.apiUrlTravel}flight/city?city=${location}`;
  return http.get(url)
    .then(res => res)
}

const searchFlights = payload => {
  let url = `${config.apiUrlTravel}flight/search`;
  return http.post(url, payload)
    .then(res => res)
}


export const flightService = {
  getAirport,
  searchFlights
}