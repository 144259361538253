import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { personalInfoService } from 'src/_services/personalInfoService';
import { myAccountTravelSettingsServise } from "../../../../../_services";
import AddCar from "./AddCar";
import AddOnlineCar from "./AddOnlineCar";

class CabBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
        };
    } 

    render() {
        return (
            <div>
                <div className="book-details">
                    <div> 
                        <AddCar
                            requestType={this.state.type}
                            cabRequest={this.props.cabRequest}
                        />
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
}

export default CabBooking;
