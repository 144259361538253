import React, { Component } from "react";
import ConfigurationSetsPage from "./config-sets-html";
import { expenseTravelAllowanceServise } from "../../../../../../_services";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";
import _ from "lodash";
import {checkIsArray} from "src/utils/util";

class ConfigurationSets extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.validator1 = new SimpleReactValidator();
    this.validatorHourly = new SimpleReactValidator();  
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.companyId = localStorage["440a28"];
    this.serviceModuleId = "5c62a3fe83d4ef6fb672607c";
  }

  state = {
    pageItemsList: [{ items: 10 }, { items: 20 }, { items: 30 }],
    totalCount: 0,
    configName: "",
    configList: [],
    search: "",
    companyId:localStorage["440a28"],
    selectedPage: 1,
    dataPerPage: 10,
    selectedCountry: "",
    selectedCity: "",
    allowanceType: "",
    maxLimit: "",
    maxGuestLimit: "",
    maxPersonalLimit:"",
    selectedCurrency: "",
    editId: "",
    countriesList: [],
    citiesList: [],
    currenciesList: [],
    isEdit: false,
    loading: false,
    id:[],
    stateList:[],
    allocationCalculationType:'',
    editSelectedCountry: [],
    editSlectedState:[],
    editSelectedCity: [],
    editAllowanceType: [],
    editSelectedCurrency:[],
    editLimit:0,
    editGuestLimit:0,
    editPersonalLimit:0,
    configLists:[],
    isLoading:false,
    count: 2,
    arrayCount: [0, 1],
    selectedRow: [],
    status: false,
    editAllowanceConfig: [],
    hourlyConfigId:'',
    allowanceTypeList:[
      { label: 'Fixed', value:0 }, 
      { label: "Actual with limit", value:1 },
      { label: "Actual Over Limit", value:2 }
    ],
  };

  componentDidMount() {
    this.getConfigs();
    this.getCurrencyList();
    this.getCountriesList();
  }

  getCitiesList = async (country, value) => {
    var res = await expenseTravelAllowanceServise.getCitiesList(country, value);
    if (res&&res.result&&res.result) {
      let temp = res.result;
      let citiesList = [];
      temp.forEach(element => {
        citiesList.push({ city: element.city, value: element.id });
      });
      this.setState({ citiesList: citiesList });
    }
  };

  getCountriesList = async value => {
    var res = await expenseTravelAllowanceServise.getCountriesList(value);
    if (res&&res.result&&res.result) {
      let temp = res.result;
      let countriesList = [];
      temp.forEach(element => {
        countriesList.push({
          label: element.name,
          value: element.id
        });
      });
      this.setState({ countriesList: countriesList });
    }
  };
  onCurrencyChangeHandler=(currency)=>{
    this.setState({selectedCurrency:currency});
  }
  onCountryChangeHandler = async (country) => {
    this.setState({ selectedCountry: country });
    var res = await expenseTravelAllowanceServise.getStateList(country.value);
    if (res&&res.result&&res.result) {
      let temp = res.result;
      let stateList = [];
      temp.forEach(element => {
        stateList.push({
          label: element.name,
          value: element.id
        });
      });
      this.setState({ stateList: stateList,selectedState:'',selectedCity:'' });
    }
  };
  onStateChangeHandler=async (state)=>{
    this.setState({ selectedState: state });
    var res = await expenseTravelAllowanceServise.getCitiesList(state.value,this.state.selectedCountry.value);
    if (res&&res.result&&res.result) {
      let temp = res.result;
      let citiesList = [];
      temp.forEach(element => {
        citiesList.push({
          label: element.name,
          value: element.id
        });
      });
      this.setState({ citiesList: citiesList,selectedCity:'' });
    }
  }
  onAllowanceChangeHandler=(value)=>{
    this.setState({editAllowanceType:value});
  }
  onCityChangeHandler=async (city)=>{
    this.setState({ selectedCity: city });
  }

  getCurrencyList = async () => {
    var res = await expenseTravelAllowanceServise.getCurrencyList(
      this.companyId
    );
    if (res) {
      let temp = res.result;
      let currenciesList = [];
      temp.forEach(element => {
        currenciesList.push({ label: element.itemName, value: element.id });
      });
      this.setState({ currenciesList: currenciesList });
    }
  };

  getConfigs = async () => {
  
    let data = {
      companyId: this.companyId,
      limit: 100,
      page: this.state.selectedPage
    };
    var res = await expenseTravelAllowanceServise.getConfigList(data);
    if (res) {
      let temp = res.result;
      let configList = [];
      temp.forEach(element => {
        configList.push({
          label: element.configurationName,
          value: element.id
        });
      });
      this.setState({ configList: configList,configLists :res.result,isLoading:false  });
    }else{
      
    }
  };

  getConfigSet = async data => {
    
    var res = await expenseTravelAllowanceServise.getConfigSet(data);
    if (res) { 
      this.setState({
        configSetList: res.result,
        personalActive: res.personalActive,
        guestActive: res.guestActive,
        totalCount: res.count,
        selectedCity: "",
        selectedCountry: "",
        allowanceType: "",
        limit: "",
        selectedCurrency: "",
        loading: false
      });
    } else {
      this.setState({ configSetList: [], totalCount: "", loading: false });
      $(".close").click();
    }
  };

  pageClickHandler = page => {
    this.setState({ selectedPage: page });
    let data = {
      companyId: this.companyId,
      configurationId: this.state.configurationId,
      limit: this.state.dataPerPage,
      page: page
    };
    this.getConfigSet(data);
  };
  onAddAllowanceChangeHandler=(allowance)=>{
    this.setState({allowanceType:allowance});
  }
  handleItemChange = limit => {
    this.setState({ dataPerPage: limit.items, selectedPage: 1 });
    let data = {
      companyId: this.companyId,
      configurationId: this.state.configurationId,
      limit: limit.items,
      page: 1
    };
    this.getConfigSet(data);
  };
  onIntegerHandler = (event) => {
    if(!isNaN(event.target.value)) 
    {
      this.setState({ [event.target.name]: event.target.value });
    }
  }; 
  onConfigHandler = async (config) => {
    this.setState({isLoading:true});
    let configLists=[...this.state.configLists];
    let allocationCalculationType = '';
    configLists.forEach((list)=>{
        if(config.value===list.id){
          allocationCalculationType = list.allowanceCalculationType;
        }
    });
    this.setState({ configurationId: config.value,allocationCalculationType:allocationCalculationType });
    let data = {
      companyId: this.companyId,
      configurationId: config.value,
      limit: this.state.dataPerPage,
      page: this.state.selectedPage
    };
    var res = await expenseTravelAllowanceServise.searchConfigSet(data);
    if (res.count>0) {
      this.setState({ configSetList: res.result,guestActive:res.guestActive,personalActive:res.personalActive, totalCount: res.count,isLoading:false });
    } else {
      this.setState({ configSetList: [], totalCount: "",isLoading:false });
    }
  };

  editHandler = async(data) => {
    this.setState({id:[]});
    this.setState({editId: data.id});
    var response = await expenseTravelAllowanceServise.geteditCongigurationData(data.id,this.state.companyId);
    if(response.result && response.result.countryObj!==''&&response.result.countryObj!==undefined)
    {
      this.setState({
        editAllowanceType: this.state.allowanceTypeList[response.result.allowanceType],
        editSelectedCurrency:({label:response.result.currencyObj.itemName,value:response.result.currencyObj.id}),
        editLimit:response.result.maxLimit,
        editGuestLimit:response.result.maxGuestLimit,
        editPersonalLimit:response.result.maxPersonalLimit
      })
      this.setState({editSelectedCountry: ({label:response.result.countryObj[0].name,value:response.result.countryObj[0].id})});
      var res = await expenseTravelAllowanceServise.getStateList(response.result.countryObj[0].id);
      if (res&&res.result&&res.result) {
        let temp = res.result;
        let stateList = [];
        temp.forEach(element => {
          stateList.push({
            label: element.name,
            value: element.id
          });
        });
        this.setState({ stateList: stateList,selectedState:'',selectedCity:'' });
      }
    }else{
      this.setState({
        editAllowanceType: this.state.allowanceTypeList[response.result.allowanceType],
        editSelectedCurrency:({label:response.result.currencyObj.itemName,value:response.result.currencyObj.id}),
        editLimit:response.result.maxLimit,
        editGuestLimit:response.result.maxGuestLimit,
        editPersonalLimit:response.result.maxPersonalLimit
      })
    }
    if(response.result && response.result.cityObj!=='')
    {
      this.setState({editSelectedCity: ({label:response.result.cityObj[0].name,value:response.result.cityObj[0].id})})
    }
    if(response.result && response.result.stateObj!==''&&response.result.stateObj!==undefined)
    {
      this.setState({editSlectedState: ({label:response.result.stateObj[0].name,value:response.result.stateObj[0].id})});
      var responseCity = await expenseTravelAllowanceServise.getCitiesList(response.result.stateObj[0].id);
      if (responseCity&&responseCity.result&&responseCity.result) {
        let temp = responseCity.result;
        let citiesList = [];
        temp.forEach(element => {
          citiesList.push({
            label: element.name,
            value: element.id
          });
        });
        this.setState({ citiesList: citiesList,selectedCity:'' });
      }
    }
  };

  onAddClickHandler = () => {
    this.setState({
      selectedCountry: "",
      selectedCity: "",
      allowanceType: "",
      maxLimit: "",
      maxGuestLimit:"",
      maxPersonalLimit:"",
      selectedCurrency: "",
      selectedState:"",
      isEdit: false,
      loading: false,
      stateList:[],
      citiesList:[]
    });
    this.validator1.errorMessages={};
  };

  getAllAllowance=async()=>{
    let data = {
      companyId: this.companyId,
      configurationId: this.state.configurationId,
      limit: this.state.dataPerPage,
      page: this.state.selectedPage
    };
    var res = await expenseTravelAllowanceServise.searchConfigSet(data);
    if (res.count>0) {
      this.setState({ configSetList: res.result, totalCount: res.count });
    } else {
      this.setState({ configSetList: [], totalCount: "" });
    }
  }
  deletehandler= async() => {
    let data={
      id:this.state.id
    }
    var res = await expenseTravelAllowanceServise.deleteConfigSet(this.companyId,data);
    if (res) {
      this.setState({id:[]});
      toast.success(res.message)
      this.getAllAllowance();
    }
  }

  selectedDeleteHandler=(data)=>{
      let id=[...this.state.id]
      if(this.state.id.includes(data)){
        id.splice(this.state.id.indexOf(data),1)
        this.setState({id:id})
      }else{
        id.push(data)
        this.setState({id:id})
      }  
  }
  inputChangeHandler=(event)=>{
    this.setState({[event.target.name]:event.target.value})
  }
  saveConfigSet=async(event)=>{
    event.preventDefault();
    if (this.validator1.allValid()) {
      let data={};
      let selectedCountry = this.state.selectedCountry;
      let selectedState = this.state.selectedState;
      let selectedCity = this.state.selectedCity;
      let allowanceType = this.state.allowanceType;
      let selectedCurrency = this.state.selectedCurrency;
      let maxLimit = parseInt(this.state.maxLimit);
      let maxGuestLimit = parseInt(this.state.maxGuestLimit);
      let maxPersonalLimit = parseInt(this.state.maxPersonalLimit);
      if(selectedState.value===''){
        data={
          companyId: this.companyId,
          configurationId: this.state.configurationId,
          country: selectedCountry.value,
          allowanceType: allowanceType.value,
          maxLimit: maxLimit,
          maxGuestLimit: maxGuestLimit,
          maxPersonalLimit: maxPersonalLimit,
          currency: selectedCurrency.value
        }
      }else if(selectedCity.value===''||selectedCity.value===undefined){
        data={
          companyId: this.companyId,
          configurationId: this.state.configurationId,
          country: selectedCountry.value,
          state: selectedState.value,
          allowanceType: allowanceType.value,
          maxLimit:maxLimit,
          maxGuestLimit: maxGuestLimit,
          maxPersonalLimit:maxPersonalLimit,
          currency: selectedCurrency.value
        }
      }else if((selectedState.value!==''||selectedState.value!==undefined)&&(selectedCity.value!==''||selectedCity.value!==undefined)){
        data={
          companyId: this.companyId,
          configurationId: this.state.configurationId,
          country:selectedCountry.value,
          state: selectedState.value,
          city:selectedCity.value,
          allowanceType: allowanceType.value,
          maxLimit: maxLimit,
          maxGuestLimit: maxGuestLimit,
          maxPersonalLimit:maxPersonalLimit,
          currency:  selectedCurrency.value
        }
      }
      let response = await expenseTravelAllowanceServise.createCongigurationSet(data);
      if(response){
        $(".close").click();
       this.getAllAllowance();
        toast.success(response.message);
     }
    } else {
      this.validator1.showMessages();
      this.forceUpdate();
    }
  }

  onEditCountryChangeHandler = async (country) => {
    this.setState({ editSelectedCountry: country });
    var res = await expenseTravelAllowanceServise.getStateList(country.value);
    if (res&&res.result&&res.result) {
      let temp = res.result;
      let stateList = [];
      temp.forEach(element => {
        stateList.push({
          label: element.name,
          value: element.id
        });
      });
      this.setState({ stateList: stateList,selectedState:'',selectedCity:'' });
    }
  };

  onEditStateChangeHandler=async (state)=>{
    this.setState({ editSlectedState: state });
    var res = await expenseTravelAllowanceServise.getCitiesList(state.value);
    if (res&&res.result&&res.result) {
      let temp = res.result;
      let citiesList = [];
      temp.forEach(element => {
        citiesList.push({
          label: element.name,
          value: element.id
        });
      });
      this.setState({ citiesList: citiesList,selectedCity:'' });
    }
  }
  onEditCityChangeHandler=async (city)=>{
    this.setState({ editSelectedCity: city });
  }
  onEditCurrencyChangeHandler=(currency)=>{
    this.setState({ editSelectedCurrency: currency });
  }
  onUpdateHandler=async(e)=>{
    e.preventDefault();
    let editSelectedCountry = this.state.editSelectedCountry;
    let editSlectedState = this.state.editSlectedState;
    let editSelectedCity = this.state.editSelectedCity;
    let editAllowanceType = this.state.editAllowanceType;
    let editSelectedCurrency = this.state.editSelectedCurrency;
    let editLimit = parseInt(this.state.editLimit);
    let editGuestLimit = parseInt(this.state.editGuestLimit);
    let editPersonalLimit = parseInt(this.state.editPersonalLimit);
    let editId = this.state.editId;
    if (this.validator.allValid()) {
      let data={};
      if(editSelectedCountry.length<=0){
        data={
          id:editId,
          country:'Global',
          companyId: this.state.companyId,
          configurationId: this.state.configurationId,
          allowanceType: editAllowanceType.value,
          maxLimit: editLimit,
          maxGuestLimit: editGuestLimit,
          maxPersonalLimit: editPersonalLimit,
          currency: editSelectedCurrency.value
        }
      }
     else if(editSlectedState.value===''||editSlectedState.value===undefined){
        data={
          id:editId,
          companyId: this.state.companyId,
          configurationId: this.state.configurationId,
          country: editSelectedCountry.value,
          allowanceType: editAllowanceType.value,
          maxLimit: editLimit,
          maxGuestLimit: editGuestLimit,
          maxPersonalLimit: editPersonalLimit,
          currency: editSelectedCurrency.value
        }
      }else if((editSlectedState.value!==''||editSlectedState.value!==undefined)&&(editSelectedCity.value==''||editSelectedCity.value===undefined)){
        data={
          id:editId,
          companyId: this.state.companyId,
          configurationId: this.state.configurationId,
          country: editSelectedCountry.value,
          state: editSlectedState.value,
          allowanceType: editAllowanceType.value,
          maxLimit: editLimit,
          maxGuestLimit: editGuestLimit,
          maxPersonalLimit: editPersonalLimit,
          currency:editSelectedCurrency.value
        }
      }else if(editSelectedCity.value!==''&&editSelectedCity.value!==undefined){
        data={
          id:editId,
          companyId: this.state.companyId,
          configurationId: this.state.configurationId,
          country: editSelectedCountry.value,
          state: editSlectedState.value,
          city:editSelectedCity.value,
          allowanceType: editAllowanceType.value,
          maxLimit: editLimit,
          maxGuestLimit: editGuestLimit,
          maxPersonalLimit: editPersonalLimit,
          currency:editSelectedCurrency.value
        }
      }
      let response = await expenseTravelAllowanceServise.updateAllowanceConfig(data);
      if(response){
        $(".close").click();
        this.getAllAllowance();
        toast.success(response.message);
      }else{
        toast.error(response.message);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  
  onEditIntegerHandler = (event) => {
    if(!isNaN(event.target.value)) 
    {
      this.setState({ [event.target.name]: event.target.value });
    }
  }; 

  getHourlyAllowanceDetails =  async(configurations) => {
    let arrayCount = [];
    if (configurations.length>0) {
      this.setState({ editAllowanceConfig: configurations});
      configurations.forEach((list, index) => {
        this.setState({
          ['from' + index]: list['from' + index],
          ['to' + index]: list['to' + index],
          ['rate' + index]: list['rate' + index]
        });
        arrayCount.push(index);
        let fromDropdown = [];
        let toDropdown = [];
        if (index === 0) {
          for (let fromIndex = 0; fromIndex < 25; fromIndex++) {
            fromDropdown.push(fromIndex);
          }
        } else {
          for (let fromIndex = list['from' + index]; fromIndex < 25; fromIndex++) {
            fromDropdown.push(fromIndex);
          }
        }
        for (let toIndex = list['to' + index]; toIndex < 25; toIndex++) {
          toDropdown.push(toIndex);
        }
        this.setState({ ['todropdown' + index]: toDropdown, ['fromdropdown' + index]: fromDropdown });
      });
     await this.setState({
        count: configurations.length,
        arrayCount: arrayCount
      });
    } else {
      for (let arrayIndex = 0; arrayIndex < this.state.count; arrayIndex++) {
        this.setState({
          ['from' + arrayIndex]: 0,
          ['to' + arrayIndex]: 0
        });
        let fromDropdown = [];
        let toDropdown = [];
        for (let index = 0; index < 25; index++) {
          fromDropdown.push(index);
          toDropdown.push(index);
        }
        await this.setState({ ['todropdown' + arrayIndex]: toDropdown, ['fromdropdown' + arrayIndex]: fromDropdown,['rate' + arrayIndex]:'' });
      }
    }
  }

  onAddTimeofDayClickHandler = () => {
    let count = this.state.count + 1;
    this.setState({ count: count });
    let arrayCount = [];
    let startDropdown = this.state['to'+parseInt(this.state.count-1)];
    for (let index = 0; index < count; index++) {
      arrayCount.push(index);
        this.setState({
          ['from' + index]: this.state['from' + index] ? this.state['from' + index] : 0,
          ['to' + index]: this.state['to' + index] ? this.state['to' + index] : 0
        });
    let fromDropdown =[];
    let toDropdown = [];
    for (let fromIndex = startDropdown; fromIndex < 25; fromIndex++) {
      fromDropdown.push(fromIndex);
    }
    for (let toIndex = startDropdown; toIndex < 25; toIndex++) {
      toDropdown.push(toIndex);
    }
    this.setState({ ['from'+ (parseInt(this.state.count))]:startDropdown,
                    ['to'+ (parseInt(this.state.count))]:startDropdown,
                    ['todropdown' + (parseInt(this.state.count))]: toDropdown, 
                    ['fromdropdown' + (parseInt(this.state.count))]: fromDropdown });
    }
    this.setState({ arrayCount: arrayCount });
  }
  onEditAddClickHandler = () => {
    let count = this.state.count + 1;
    this.setState({ count: count });
    let startDropdown = this.state['to'+parseInt(this.state.count-1)];
    let arrayCount = [];
    for (let index = 0; index < count; index++) {
      arrayCount.push(index);
        this.setState({
          ['from' + index]: this.state['from' + index] ? this.state['from' + index] : 0,
          ['to' + index]: this.state['to' + index] ? this.state['to' + index] : 0
        });
    }
    let fromDropdown =[];
    let toDropdown = [];
    for (let fromIndex = startDropdown; fromIndex < 25; fromIndex++) {
      fromDropdown.push(fromIndex);
    }
    for (let toIndex = startDropdown; toIndex < 25; toIndex++) {
      toDropdown.push(toIndex);
    }
    this.setState({ ['from'+ (parseInt(this.state.count))]:startDropdown,
                    ['to'+ (parseInt(this.state.count))]:startDropdown,
                    ['todropdown' + (parseInt(this.state.count))]: toDropdown, 
                    ['fromdropdown' + (parseInt(this.state.count))]: fromDropdown });
    this.setState({ arrayCount: arrayCount });
  }
  onSelectHandler = (value) => {
    let selectedRow = [...this.state.selectedRow];
    if (!selectedRow.includes(value)) {
      selectedRow.push(value);
    } else {
      selectedRow = selectedRow.filter((index) => index !== value);
    }
    this.setState({ selectedRow: selectedRow });
  }
  onRemoveHandler = () => {
    let selectedRow = [...this.state.selectedRow];
    let arrayCount = [...this.state.arrayCount];
    _.pullAt(arrayCount, selectedRow);
    this.setState({ arrayCount: arrayCount, selectedRow: [],count:arrayCount.length });
  }
  onDropdownSelectHandler = (fieldName, value, index) => {
    this.setState({ [fieldName]: value, ['to' + index]: value });
    let toDropdown = [];
    for (let toIndex = parseInt(value); toIndex < 25; toIndex++) {
      toDropdown.push(toIndex);
    }
    this.setState({ ['todropdown' + index]: toDropdown });
  }
  onDropdownToSelectHandler = (fieldName, value, index) => {
    this.setState({ [fieldName]: value, ['from' + (parseInt(index) + 1)]: value, ['to' + (parseInt(index) + 1)]: value });
    let fromDropdown = [];
    let toDropdown = [];
    for (let fromIndex = value; fromIndex < 25; fromIndex++) {
      fromDropdown.push(fromIndex);
    }
    for (let toIndex = value; toIndex < 25; toIndex++) {
      toDropdown.push(toIndex);
    }
    this.setState({ ['todropdown' + (parseInt(index) + 1)]: toDropdown, ['fromdropdown' + (parseInt(index) + 1)]: fromDropdown });
  }
  onRateChangeHandler = (fieldName, event) => {
    if(!isNaN(event.target.value)) 
    {
     this.setState({ [fieldName]: event.target.value });
    }
  }
  onRateChangeHandler1 = (fieldName, event) => {
    if(!isNaN(event.target.value)) 
    {
     this.setState({ [fieldName]: event.target.value });
    }
  }
  onRateChangeHandler2 = (fieldName, event) => {
    if(!isNaN(event.target.value)) 
    {
     this.setState({ [fieldName]: event.target.value });
    }
  }
  onEditRemoveHandler = () => {
    let selectedRow = [...this.state.selectedRow];
    let arrayCount = [...this.state.arrayCount];
    _.pullAt(arrayCount, selectedRow);
    this.setState({ arrayCount: arrayCount, selectedRow: [],count:arrayCount.length });
  }
  onHourlySubmitHandler = async () => {
  if (this.validatorHourly.allValid()) {
      let arrayCount = [...this.state.arrayCount];
      let data = [];
      arrayCount.forEach((index, indexes) => {
        data.push({
          'from': this.state['from' + index],
          'to': this.state['to' + index],
          'rate': this.state['rate' + index]
        });
      });
      let allowanceCalculationType = {
        status: this.state.status,
        configurations: data
      }
      let submitData = {
        id:this.state.hourlyConfigId,
        allowanceCalculationType:allowanceCalculationType
      }
      let response = await expenseTravelAllowanceServise.createConfigurationAllowance(submitData);
      if (response) {
        toast.success(response.message);
        $(".close").click();
        this.getAllAllowance();
      }
    } else {
      this.validatorHourly.showMessages();
      this.forceUpdate();
    }
  }
  onStatusChangeHandler = () => {
    this.setState({ status: !this.state.status });
  }

  onHourlyClickHandler=(data)=>{
    let configurations=[];
    checkIsArray(data.allowanceCalculationType.configurations) && data.allowanceCalculationType.configurations.forEach((congiguration,index)=>{
      configurations.push({ 
      ['from'+index]:congiguration.from,
      ['to'+index]:  congiguration.to,
      ['rate'+index]:congiguration.rate})
    })
    let configCounts =checkIsArray(data.allowanceCalculationType.configurations)&&data.allowanceCalculationType.configurations.length>=2?data.allowanceCalculationType.configurations.length:2;
    let configCountArray = [];
     for (let index = 0; index < configCounts; index++) {
      configCountArray.push(index);
     }
    let status =data.allowanceCalculationType&& data.allowanceCalculationType.status;
    this.setState({hourlyConfigId:data.id,status:status,arrayCount:configCountArray});
    this.getHourlyAllowanceDetails(configurations);
  }
  render() {
    return (
      <div>
        <ConfigurationSetsPage
          {...this.state}
          pageClickHandler={this.pageClickHandler}
          handleItemChange={this.handleItemChange}
          inputChangeHandler={this.inputChangeHandler}

          selectHandler={this.selectHandler}
          selectedCurrency={this.state.selectedCurrency}
          editHandler={this.editHandler}
          saveConfigSet={this.saveConfigSet}
          resetForm={this.resetForm}
          validator={this.validator}
          validator1={this.validator1}
          selectedDeleteHandler={this.selectedDeleteHandler}
          deletehandler={this.deletehandler}
          onCityChangeHandler={this.onCityChangeHandler}
          onStateChangeHandler={this.onStateChangeHandler}
          onCountryChangeHandler={this.onCountryChangeHandler}
          onIntegerHandler={this.onIntegerHandler}
          onIntegerHandler1={this.onIntegerHandler1}
          onIntegerHandler2={this.onIntegerHandler2}
          onCurrencyChangeHandler={this.onCurrencyChangeHandler}
          onConfigHandler={this.onConfigHandler}
          onEditCityChangeHandler={this.onEditCityChangeHandler}
          onEditStateChangeHandler={this.onEditStateChangeHandler}
          onEditCountryChangeHandler={this.onEditCountryChangeHandler}
          onEditCurrencyChangeHandler={this.onEditCurrencyChangeHandler}
          onUpdateHandler={this.onUpdateHandler}
          onEditIntegerHandler={this.onEditIntegerHandler}
          onEditIntegerHandler1={this.onEditIntegerHandler1}
          onEditIntegerHandler2={this.onEditIntegerHandler2}
          onAllowanceChangeHandler={this.onAllowanceChangeHandler}
          onAddAllowanceChangeHandler={this.onAddAllowanceChangeHandler}
          onAddClickHandler={this.onAddClickHandler}
          onAddTimeofDayClickHandler={this.onAddTimeofDayClickHandler}
         
          onSelectHandler={this.onSelectHandler}
          onRemoveHandler={this.onRemoveHandler}
          onDropdownSelectHandler={this.onDropdownSelectHandler}
          onDropdownToSelectHandler={this.onDropdownToSelectHandler}
          onHourlySubmitHandler={this.onHourlySubmitHandler}
          onRateChangeHandler={this.onRateChangeHandler}
          onRateChangeHandler1={this.onRateChangeHandler1}
          onRateChangeHandler2={this.onRateChangeHandler2}
          onStatusChangeHandler={this.onStatusChangeHandler}
         
          onEditRemoveHandler={this.onEditRemoveHandler}
          onEditAddClickHandler={this.onEditAddClickHandler}
          onHourlyClickHandler={this.onHourlyClickHandler}
          validatorHourly={this.validatorHourly} 
        />
      </div>
    );
  }
}
export default ConfigurationSets;
