import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class SubmitPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  render() {
    return (
      <div>
        <div className="p-padding pb-0">
          <div className="white-card shadow-light">
            <div className="pad-sm">
              <h6 className="sub-heading mb-0">Approver Flow</h6>
            </div>
            <div>
              <div className="approver">
                <div className="right-icon approved">
                <i className="material-icons">check_circle</i>
                </div>
                <div className="name-desg">
                  <div className="left">
                    <div className="pic">
                      <img src="/assets/images/booking/user_12.png" alt="Pic" />
                    </div>
                  </div>
                  <div className="right">
                    <p className="label">Level 1</p>
                    <p className="title">George Green</p>
                    <p className="desg">Finance</p>
                  </div>
                </div>
              </div>
              <div className="approver">
                <div className="right-icon">
                <i className="material-icons">timer</i>
                </div>
                <div className="name-desg">
                  <div className="left">
                    <div className="pic">
                      <img src="/assets/images/booking/user_12.png" alt="Pic" />
                    </div>
                  </div>
                  <div className="right">
                    <p className="label">Level 2</p>
                    <p className="title">Vincent Lawson</p>
                    <p className="desg">Approver</p>
                  </div>
                </div>
              </div>
              <div className="approver">
                <div className="right-icon">
                <i className="material-icons">timer</i>
                </div>
                <div className="name-desg">
                  <div className="left">
                    <div className="pic">
                      <img src="/assets/images/booking/user_12.png" alt="Pic" />
                    </div>
                  </div>
                  <div className="right">
                    <p className="label">Level 3</p>
                    <p className="title">John Snow</p>
                    <p className="desg">Manager</p>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
        <div className="px-padding">
          <div className="row">
            <div className="col-md-6">
              <div className="white-card">
                <div className="pad">
                  <h6 className="sub-heading">Add Additional Service</h6>
                  <ul className="service">
                    <li>
                      <img
                        width="50"
                        height="50"
                        src="/assets/images/booking/flight_booking.svg"
                        alt="Flight"
                      />
                      <p>Add Flights</p>
                    </li>
                    <li>
                      <img
                        width="50"
                        height="50"
                        src="/assets/images/booking/hotel_booking.svg"
                        alt="Hotel"
                      />
                      <p>Add Hotels</p>
                    </li>
                    <li>
                      <img
                        width="50"
                        height="50"
                        src="/assets/images/booking/car_booking.svg"
                        alt="Cab"
                      />
                      <p>Add Cab</p>
                    </li>
                    <li>
                      <img
                        width="50"
                        height="50"
                        src="/assets/images/booking/bus_booking.svg"
                        alt="Bus"
                      />
                      <p>Add Bus</p>
                    </li>
                    <li>
                      <img
                        width="50"
                        height="50"
                        src="/assets/images/booking/train_booking.svg"
                        alt="Train"
                      />
                      <p>Add Train</p>
                    </li>
                  </ul>
                </div>
              </div>
              <ul className="travel-tabs">
                <li className="active">
                  <div className="arrow">
                  <i className="material-icons">play</i>
                  </div>
                  <div className="destiny">
                    <div className="left-destination car">
                      <div className="abs-pin" />
                      <div className="totalDistance sm">
                        <div className="circle">
                        <i className="material-icons">destinations_car</i>
                        </div>
                      </div>
                      <p className="title">#345, Vital malya Road, Bangalore</p>
                      <div className="center">
                        <p className="title">
                          <img
                            src="/assets/images/booking/car_service_3.png"
                            alt="Ola"
                          />{" "}
                          <b>45min</b> by Ola
                        </p>
                        <p className="label">Rs. 300 - 400</p>
                      </div>
                      <p className="title">Kempegowda International Airport</p>
                    </div>
                  </div>
                  <button className="btn btn-border-primary sm-padding">
                    3 more Option
                  </button>
                </li>
                <li>
                  <div className="arrow">
                  <i className="material-icons">play</i>
                  </div>
                  <div className="destiny">
                    <div className="left-destination flight">
                      <div className="abs-pin" />
                      <div className="totalDistance sm">
                        <div className="circle">
                        <i className="material-icons">flight</i>
                        </div>
                      </div>
                      <p className="title">Kempegowda International Airport</p>
                      <div className="center">
                        <p className="title">
                          <img src="/assets/images/booking/" alt="Indigo" />{" "}
                          <b>18hr 38m</b> by Plain
                        </p>
                        <p className="label">USD 1234.00 to USD 1500.00</p>
                      </div>
                      <p className="title">JFK International Airport</p>
                    </div>
                  </div>
                  <button className="btn btn-border-primary sm-padding">
                    40 more Option
                  </button>
                </li>
                <li>
                  <div className="arrow">
                  <i className="material-icons">play</i>
                  </div>
                  <div className="destiny">
                    <div className="left-destination bus">
                      <div className="abs-pin" />
                      <div className="totalDistance sm">
                        <div className="circle">
                        <i className="material-icons">destinations_bus</i>
                        </div>
                      </div>
                      <p className="title">Terminal A</p>
                      <div className="center">
                        <p className="title">
                          <img
                            src="/assets/images/booking/"
                            alt="MegaBus.com"
                          />{" "}
                          <b>18hr 30m</b> by Bus
                        </p>
                        <p className="label">Rs. 1500</p>
                      </div>
                      <p className="title">Rits Carlton Hotel</p>
                    </div>
                  </div>
                  <button className="btn btn-border-primary sm-padding">
                    700 more Option
                  </button>
                </li>
                <li>
                  <div className="arrow">
                  <i className="material-icons">play</i>
                  </div>
                  <div className="destiny">
                    <div className="left-destination hotel">
                      <div className="abs-pin" />
                      <div className="totalDistance sm">
                        <div className="circle">
                        <i className="material-icons">hotel</i>
                        </div>
                      </div>
                      <p className="title">Rits Carlton Hotel</p>
                      <div className="center">
                        <p className="title">
                          <b>Grand Cocogoose</b>
                        </p>
                        <p className="label">Presidential Suite</p>
                      </div>
                      <p className="title">Rits Carlton Hotel</p>
                    </div>
                  </div>
                  <button className="btn btn-border-primary sm-padding">
                    700 more Option
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <div className="white-card shadow-light">
                <div className="grey-head">
                  <h6 className="sub-heading">Road Transport Options</h6>
                </div>
                <div className="pad">
                  <div className="destiny">
                    <div className="left-destination">
                      <div className="abs-pin">
                      <i className="material-icons">location_on</i>
                      </div>
                      <div className="totalDistance">
                        <div className="circle">
                          <p className="title">
                            57 <span className="label">KM</span>
                          </p>
                        </div>
                      </div>
                      <div className="white-card shadow-light">
                        <div className="pad-sm">
                          <div className="row">
                            <div className="col-md-9">
                              <p className="label p-left">Pick Up Location</p>
                              <p className="title">
                              <i className="material-icons">location_on</i>
                                #306, Vittal Malya Road, Bengaluru
                              </p>
                            </div>
                            <div className="col-md-3 text-right">
                              <button className="btn btn-nothing link h-100">
                                Edit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="white-card border-blue">
                        <label className="full" for="no_cab">
                          <div className="pad-sm">
                            <div className="row">
                              <div className="col-md-10">
                                <p className="title">No Cab Required</p>
                              </div>
                              <div className="col-md-2 text-right">
                                <div className="radio-container p-sm d-inline-block">
                                  <input
                                    type="radio"
                                    id="no_cab"
                                    name="radio"
                                  />
                                  <span className="checkmark" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="white-card border-blue">
                        <label className="full" for="ola">
                          <div className="pad-sm">
                            <div className="row">
                              <div className="col-md-4">
                                <p className="title">
                                  <img
                                    src="/assets/images/booking/car_service_3.png"
                                    alt="Ola"
                                  />{" "}
                                  25min
                                </p>
                              </div>
                              <div className="col-md-6">
                                <p className="title text-right">
                                  USD 300.00 - USD 400.00
                                </p>
                              </div>
                              <div className="col-md-2 text-right">
                                <div className="radio-container p-sm d-inline-block">
                                  <input id="ola" type="radio" name="radio" />
                                  <span className="checkmark" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="white-card border-blue">
                        <label className="full" for="uber">
                          <div className="pad-sm">
                            <div className="row">
                              <div className="col-md-4">
                                <p className="title">
                                  <img
                                    src="/assets/images/booking/"
                                    alt="Uber"
                                  />{" "}
                                  45min
                                </p>
                              </div>
                              <div className="col-md-6">
                                <p className="title text-right">
                                  USD 250.00 - USD 350.00
                                </p>
                                <p className="label text-right green">
                                <i className="material-icons">arrow_drop_down</i> USD
                                  50.00
                                </p>
                              </div>
                              <div className="col-md-2 text-right">
                                <div className="radio-container p-sm d-inline-block">
                                  <input type="radio" id="uber" name="radio" />
                                  <span className="checkmark" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="white-card border-blue">
                        <label className="full" for="ola">
                          <div className="pad-sm">
                            <div className="row">
                              <div className="col-md-1">
                                <img
                                  src="/assets/images/booking/lufthansa.png"
                                  alt="flight"
                                />
                              </div>
                              <div className="col-md-5 ml-3">
                                <h6 className="title text-muted">
                                  LPFHG2 | BLR - DLH
                                </h6>
                                <span>45min -0 stops, 0 Layovers</span>
                              </div>
                              <div className="col-md-1 text-center">
                                <span className="flight-co2">
                                <i className="material-icons">cloud</i> <br /> 2.5t</span>
                              </div>
                              <div className="col-md-4 ml-4">
                                <h6 className="text-danger"><strike><span className="text-muted">USD 65,000.00</span></strike> +10%</h6>
                                <h6>USD 70,000.00</h6>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <button className="more-options">+ 2 More Options</button>
                      <div className="white-card">
                        <button className="btn btn-success btn-block btn-no-radius">
                          Modify/Change Flight
                        </button>
                      </div>
                      <div className="white-card border-blue">
                        <label className="full" for="bmtc">
                          <div className="pad-sm">
                            <div className="row">
                              <div className="col-md-4">
                                <p className="title">
                                  <img
                                    src="/assets/images/booking/"
                                    alt="BMTC"
                                  />{" "}
                                  2h 30M
                                </p>
                              </div>
                              <div className="col-md-6">
                                <p className="title text-right">
                                  USD 25.00 - USD 50.00
                                </p>
                              </div>
                              <div className="col-md-2 text-right">
                                <div className="radio-container p-sm d-inline-block">
                                  <input id="bmtc" type="radio" name="radio" />
                                  <span className="checkmark" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="white-card shadow-light">
                        <div className="pad-sm">
                          <div className="row">
                            <div className="col-md-9">
                              <p className="label p-left">Drop Off Location</p>
                              <p className="title">
                              <i className="material-icons">location_on</i>
                                Kempegowda International Airport
                              </p>
                            </div>
                            <div className="col-md-3 text-right">
                              <button className="btn btn-nothing link h-100">
                                Edit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="white-card mt-4">
                      <div className="row">
                        <div className="col-md-6">
                          <button type="button" className="btn btn-border-danger btn-block" data-toggle="modal" data-target="#RequestCancellationModal">
                            Cancel FLight
                          </button>
                        </div>
                        <div className="col-md-6">
                          <button className="btn btn-border-basic btn-block" data-toggle="modal" data-target="#RescheduleRequestModal">
                            Reschedule FLight
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="foot">
                  <div className="row">
                    <div className="col-md-9">
                      <p>
                      <i className="material-icons">calendar_today</i> 28th Oct 2018
                        10:20 AM
                      </p>
                    </div>
                    <div className="col-md-3 text-right">
                      <button className="btn btn-nothing">Edit</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="white-card shadow-light">
                <div className="grey-head">
                  <h6 className="sub-heading">Flight Details</h6>
                </div>
                <div className="pad">
                  <div className="row">
                    <div className="col-md-2">
                      <img
                        src="/assets/images/booking"
                        alt="Logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-md-4">
                      <h6>Indigo</h6>
                      <p>
                        A383 <span>Non Refundable</span>
                      </p>
                    </div>
                    <div className="col-md-2 text-center">
                      <span className="flight-co2">
                      <i className="material-icons">cloud</i> <br /> 2.5t
                      </span>
                    </div>
                    <div className="col-md-4">
                      <p className="link">USD 200.00</p>
                      <p>Baggage Fare</p>
                    </div>
                  </div>
                </div>
                <div className="pad shadow-light">
                  <div className="from-to">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="left">
                          <div className="top">
                            <h6 className="title">Bengaluru</h6>
                            <p className="small">Bengaluru International</p>
                          </div>
                          <div className="middle">
                            <div className="dot" />
                          </div>
                          <div className="bottom">
                            <p className="small">28th Oct 2018 10:34 PM</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="center">
                          <div className="top">
                            <p className="sub-title">- 2h 20m -</p>
                          </div>
                          <div className="middle">
                            <div className="circle">
                            <i className="material-icons">flight</i>
                            </div>
                          </div>
                          <div className="bottom">
                            <p className="small">1 Stop 22h 40m</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="right">
                          <div className="top">
                            <h6 className="title">Mumbai</h6>
                            <p className="small">
                              Chatrapati Shivaji Intenational
                            </p>
                          </div>
                          <div className="middle">
                            <div className="dot" />
                          </div>
                          <div className="bottom">
                            <p className="small">30th Oct 2018 04:34 PM</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="baggage">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="icon-lg">
                      <i className="material-icons">work</i> Baggage 46kg
                        (2x23 Kgs each)
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="icon-lg">
                      <i className="material-icons">work</i> Cabin Baggage
                        7kg
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pad">
                  <h6 className="sub-heading">Amenities</h6>
                  <div className="amenities-list">
                    <div className="row">
                      <div className="col-md-6">
                        <p>
                          <span className="available yes">
                          <i className="material-icons">airline_seat_legroom_extra</i>
                          </span>{" "}
                          Standard Leg Room (76 cm)
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <span className="available">
                          <i className="material-icons">wifi</i>
                          </span>{" "}
                          No Wifi
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="amenities-list">
                    <div className="row">
                      <div className="col-md-6">
                        <p>
                          <span className="available">
                          <i className="material-icons">power</i>
                          </span>{" "}
                          No Power Outlet
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <span className="available yes">
                          <i className="material-icons">wifi</i>
                          </span>{" "}
                          Standard 3-3 Layout
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="amenities-list">
                    <div className="row">
                      <div className="col-md-6">
                        <p>
                          <span className="available">
                          <i className="material-icons">tv</i>
                          </span>{" "}
                          No Entertainment
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="change-of-plane">
                  <p>Change of Plane at Mumbai</p>
                </div>
                <div className="pad">
                  <div className="row">
                    <div className="col-md-2">
                      <img
                        src="/assets/images/booking"
                        alt="Logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-md-4">
                      <h6>Indigo</h6>
                      <p>
                        A383 <span>Non Refundable</span>
                      </p>
                    </div>
                    <div className="col-md-2 text-center">
                    <i className="material-icons">cloud</i> <br /> 2.5t
                    </div>
                    <div className="col-md-4">
                      <p className="link">USD 200.00</p>
                      <p>Baggage Fare</p>
                    </div>
                  </div>
                </div>
                <div className="pad shadow-light">
                  <div className="from-to">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="left">
                          <div className="top">
                            <h6 className="title">Bengaluru</h6>
                            <p className="small">Bengaluru International</p>
                          </div>
                          <div className="middle">
                            <div className="dot" />
                          </div>
                          <div className="bottom">
                            <p className="small">28th Oct 2018 10:34 PM</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="center">
                          <div className="top">
                            <p className="sub-title">- 2h 20m -</p>
                          </div>
                          <div className="middle">
                            <div className="circle">
                            <i className="material-icons">flight</i>
                            </div>
                          </div>
                          <div className="bottom">
                            <p className="small">1 Stop 22h 40m</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="right">
                          <div className="top">
                            <h6 className="title">Mumbai</h6>
                            <p className="small">
                              Chatrapati Shivaji Intenational
                            </p>
                          </div>
                          <div className="middle">
                            <div className="dot" />
                          </div>
                          <div className="bottom">
                            <p className="small">30th Oct 2018 04:34 PM</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="baggage">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="icon-lg">
                      <i className="material-icons">wok</i> Baggage 46kg
                        (2x23 Kgs each)
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="icon-lg">
                      <i className="material-icons">work</i> Cabin Baggage
                        7kg
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pad">
                  <h6 className="sub-heading">Amenities</h6>
                  <div className="amenities-list">
                    <div className="row">
                      <div className="col-md-6">
                        <p>
                          <span className="available yes">
                          <i className="material-icons">wifi</i>
                          </span>{" "}
                          Standard Leg Room (76 cm)
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <span className="available">
                          <i className="material-icons">wifi</i>
                          </span>{" "}
                          No Wifi
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="amenities-list">
                    <div className="row">
                      <div className="col-md-6">
                        <p>
                          <span className="available">
                          <i className="material-icons">power</i>
                          </span>{" "}
                          No Power Outlet
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <span className="available yes">
                          <i className="material-icons">wifi</i>
                          </span>{" "}
                          Standard 3-3 Layout
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="amenities-list">
                    <div className="row">
                      <div className="col-md-6">
                        <p>
                          <span className="available">
                          <i className="material-icons">tv</i>
                          </span>{" "}
                          No Entertainment
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="white-card mb-0">
                  <div className="pad">
                    <div className="white-card grey mb-0">
                      <h6 className="sub-heading heading-grey">Fare Details</h6>
                      <div className="pad">
                        <div className="table-responsive">
                          <table className="table table-borderless mb-0">
                            <tbody>
                              <tr>
                                <td>Total (1 Adults)</td>
                                <td className="text-right">USD 62,000.00</td>
                              </tr>
                              <tr>
                                <td>
                                  <p className="label">Base Fare</p>
                                  Adults (1 x 10,179)
                                </td>
                                <td className="text-right">USD 10,179.00</td>
                              </tr>
                              <tr>
                                <td>
                                  <p className="label">Surcharges</p>
                                  Adults (1 x 50,179)
                                </td>
                                <td className="text-right">USD 50,179.00</td>
                              </tr>
                              <tr>
                                <td>Discount</td>
                                <td className="text-right">USD 3,000.00</td>
                              </tr>
                              <tr>
                                <td colSpan="2">Fare Rules</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="white-card dark-grey mb-0">
                                    <div className="pad">
                                      <p className="title">BLR - BOM</p>
                                      <p className="label">Time</p>
                                      <p className="title">24 hrs - 365 days</p>
                                      <p className="label">Cancelation Fees</p>
                                      <p className="title">
                                        Adult: $ 16,065.00* + $ 649*
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="white-card dark-grey mb-0">
                                    <div className="pad">
                                      <p className="title">BOM - NYC</p>
                                      <p className="label">Time</p>
                                      <p className="title">24 hrs - 365 days</p>
                                      <p className="label">Cancelation Fees</p>
                                      <p className="title">
                                        Adult: $ 16,065.00* + $ 649*
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="foot">
                  <div className="row">
                    <div className="col-md-9">
                      <p>
                      <i className="material-icons">calendar_today</i> 28th Oct 2018
                        10:20 AM
                      </p>
                    </div>
                    <div className="col-md-3 text-right">
                      <button className="btn btn-nothing">Edit</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="white-card shadow-light">
                <div className="grey-head">
                  <h6 className="sub-heading">Hotel Options</h6>
                </div>
                <div className="hotel-img">
                  <img
                    src="/assets/images/booking/hotel_banner.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="pad">
                  <div className="white-card shadow-light">
                    <div className="pad-sm">
                      <div className="row">
                        <div className="col-md-9">
                          <p className="label p-left">Location</p>
                          <p className="title">
                          <i className="material-icons">location_on</i>
                            Manhattan, NYC, US
                          </p>
                        </div>
                        <div className="col-md-3 text-right">
                          <button className="btn btn-nothing link h-100">
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="white-card border-blue">
                    <label className="full" for="no_hotel">
                      <div className="pad-sm">
                        <div className="row">
                          <div className="col-md-10">
                            <p className="title">No Hotel Required</p>
                          </div>
                          <div className="col-md-2 text-right">
                            <div className="radio-container p-sm d-inline-block">
                              <input type="radio" id="no_hotel" name="radio" />
                              <span className="checkmark" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div>
                  <h6 className="sub-heading pad py-0">Recomended Options</h6>
                  <ul>
                    <li className="hotel-options active">
                      <div className="out-of-policy">Out Of Policy</div>
                      <div className="select-hotel">
                        <label className="radio-container">
                          <input type="radio" name="radio" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="image">
                        <img src="/assets/images/booking/room_8.png" alt="" />
                      </div>
                      <div className="hotel-details">
                        <div className="rating">
                          <img src="/assets/images/booking/12.jpg" alt="" />
                        </div>
                        <div className="right">
                          <h6 className="title">$ 200</h6>
                          <p className="label">Per Night</p>
                        </div>
                        <h5 className="title">Grand Cocogoose</h5>
                        <p className="type">Presidential Suite</p>
                        <p className="label">3.5 Miles from Destination</p>
                        <p className="desc">
                          Lorem ipsum is simly dummy text used by designers.
                          Lorem ipsum is simly dummy text.
                        </p>
                        <div className="clearfix" />
                      </div>
                      <div className="clearfix" />
                    </li>
                    <li className="hotel-options">
                      <div className="out-of-policy">Out Of Policy</div>
                      <div className="select-hotel">
                        <label className="radio-container">
                          <input type="radio" name="radio" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="image">
                        <img src="/assets/images/booking/room_8.png" alt="" />
                      </div>
                      <div className="hotel-details">
                        <div className="rating">
                          <img src="/assets/images/booking/12.jpg" alt="" />
                        </div>
                        <div className="right">
                          <h6 className="title">$ 200</h6>
                          <p className="label">Per Night</p>
                        </div>
                        <h5 className="title">Grand Cocogoose</h5>
                        <p className="type">Presidential Suite</p>
                        <p className="label">3.5 Miles from Destination</p>
                        <p className="desc">
                          Lorem ipsum is simly dummy text used by designers.
                          Lorem ipsum is simly dummy text.
                        </p>
                        <div className="clearfix" />
                      </div>
                      <div className="clearfix" />
                    </li>
                    <li className="hotel-options">
                      <div className="out-of-policy">Out Of Policy</div>
                      <div className="select-hotel">
                        <label className="radio-container">
                          <input type="radio" name="radio" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="image">
                        <img src="/assets/images/booking/room_8.png" alt="" />
                      </div>
                      <div className="hotel-details">
                        <div className="rating">
                          <img src="/assets/images/booking/12.jpg" alt="" />
                        </div>
                        <div className="right">
                          <h6 className="title">$ 200</h6>
                          <p className="label">Per Night</p>
                        </div>
                        <h5 className="title">Grand Cocogoose</h5>
                        <p className="type">Presidential Suite</p>
                        <p className="label">3.5 Miles from Destination</p>
                        <p className="desc">
                          Lorem ipsum is simly dummy text used by designers.
                          Lorem ipsum is simly dummy text.
                        </p>
                        <div className="clearfix" />
                      </div>
                      <div className="clearfix" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="RescheduleRequestModal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">Request</h6>
                <h5 className="modal-title" id="approvalFlowLable">
                  Raise a Reschedule Request for one or more items
                </h5>
              </div>
              <div className="modal-middle">
                <div className="border-bottom">
                  <div className="pm-30 pb-0">
                    <div className="row">
                      <div className="col-md-3">
                        <label>
                          <ul className="trans-opt">
                            <li>
                              <label className="checkbox-container mt-2">
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li className="mr-0 text-right">
                              <div className="flight-icon">
                              <i className="material-icons">flight</i>
                              </div>
                            </li>
                          </ul>
                        </label>
                      </div>
                      <div className="col-md-9">
                        <div className="add-side-border">
                          <div className="float-left">
                            <h5 className="text-primary">IndiGo</h5>
                            <h6 className="text-dark">Bangalore to Delhi</h6>
                            <h6 className="text-muted">18th Feb 2019</h6>
                          </div>
                          <div className="float-right">
                            <h6 className="text-dark text-right">USD 200.00</h6>
                            <p className="text-muted text-right">Corporate Fare</p>
                          </div>
                          <div className="clearfix"></div>
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td className="text-left pl-0">Rebooking Fare</td>
                                  <td className="text-right pr-0">USD $40.00</td>
                                </tr>
                                <tr>
                                  <td className="text-left pl-0">Difference</td>
                                  <td className="text-right pr-0">USD $10.00</td>
                                </tr>
                                <tr>
                                  <td className="text-left pl-0">GST</td>
                                  <td className="text-right pr-0">USD $10.00</td>
                                </tr>
                                <tr className="font-weight-bold">
                                  <td className="text-left pl-0">Total Charge</td>
                                  <td className="text-right pr-0">USD 50.00</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-bottom">
                  <div className="pm-30 py-3">
                    <div className="row">
                      <div className="col-md-3">
                        <label>
                          <ul className="trans-opt">
                            <li>
                            </li>
                            <li className="mr-0 text-right">
                              <div className="flight-icon">
                              <i className="material-icons">flight</i>
                              </div>
                            </li>
                          </ul>
                        </label>
                      </div>
                      <div className="col-md-9">
                        <NavLink to="/booking/flight/oneway" type="button" className="btn  ">Pick a Flight</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-bottom">
                  <div className="pm-30 py-3">
                    <div className="row">
                      <div className="col-md-3">
                        <label>
                          <ul className="trans-opt">
                            <li>
                              <label className="checkbox-container mt-2">
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li className="mr-0 text-right">
                              <div className="flight-icon bus-bg">
                              <i className="material-icons">directions_bus</i>
                              </div>
                            </li>
                          </ul>
                        </label>
                      </div>
                      <div className="col-md-9">
                        <div className="float-left">
                          <h5 className="text-primary">megaBus.com</h5>
                          <h6 className="text-dark">Delhi to Agra</h6>
                          <h6 className="text-muted">18th Feb 2019</h6>
                        </div>
                        <div className="float-right">
                          <h6 className="text-dark text-right">USD 200.00</h6>
                          <p className="text-muted text-right">Sleeper Fare</p>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-bottom">
                  <div className="pm-30 py-3">
                    <div className="row">
                      <div className="col-md-3">
                        <label>
                          <ul className="trans-opt">
                            <li>
                              <label className="checkbox-container mt-2">
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li className="mr-0 text-right">
                              <div className="flight-icon car-bg">
                              <i className="material-icons">directions_bus</i>
                              </div>
                            </li>
                          </ul>
                        </label>
                      </div>
                      <div className="col-md-9">
                        <div className="float-left">
                          <h5 className="text-primary">OLA</h5>
                          <h6 className="text-dark">Location to Airport</h6>
                          <h6 className="text-muted">18th Feb 2019</h6>
                        </div>
                        <div className="float-right">
                          <h6 className="text-dark text-right">USD 200.00</h6>
                          <p className="text-muted text-right">Sleeper Fare</p>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-last btn-group-margin pm-30">
                  <button
                    type="button"
                    className="btn btn-badge"
                    data-dismiss="modal"
                  >
                    Dismiss
                  </button>
                  <button type="button" className="btn btn-danger disabled">
                    Request Cancellation
                    <i className="material-icons">arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="RequestCancellationModal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">Request</h6>
                <h5 className="modal-title" id="approvalFlowLable">
                  Request Cancellation for one or more Items
                </h5>
              </div>
              <div className="modal-middle">
                <div className="border-bottom">
                  <div className="pm-30 pb-0">
                    <div className="row">
                      <div className="col-md-3">
                        <label>
                          <ul className="trans-opt">
                            <li>
                              <label className="checkbox-container mt-2">
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li className="mr-0 text-right">
                              <div className="flight-icon">
                              <i className="material-icons">flight</i>
                              </div>
                            </li>
                          </ul>
                        </label>
                      </div>
                      <div className="col-md-9">
                        <div className="float-left">
                          <h5 className="text-primary">IndiGo</h5>
                          <h6 className="text-dark">Bangalore to Delhi</h6>
                          <h6 className="text-muted">18th Feb 2019</h6>
                        </div>
                        <div className="float-right">
                          <h6 className="text-dark text-right">USD 200.00</h6>
                          <p className="text-muted text-right">Corporate Fare</p>
                        </div>
                        <div className="clearfix"></div>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td className="text-left pl-0">Cancellation fee</td>
                                <td className="text-right pr-0">USD $40.00</td>
                              </tr>
                              <tr>
                                <td className="text-left pl-0">GST</td>
                                <td className="text-right pr-0">USD $10.00</td>
                              </tr>
                              <tr className="font-weight-bold">
                                <td className="text-left pl-0">Total Charge</td>
                                <td className="text-right pr-0">USD 50.00</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-bottom">
                  <div className="pm-30 py-3">
                    <div className="row">
                      <div className="col-md-3">
                        <label>
                          <ul className="trans-opt">
                            <li>
                              <label className="checkbox-container mt-2">
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li className="mr-0 text-right">
                              <div className="flight-icon bus-bg">
                              <i className="material-icons">directions_bus</i>
                              </div>
                            </li>
                          </ul>
                        </label>
                      </div>
                      <div className="col-md-9">
                        <div className="float-left">
                          <h5 className="text-primary">megaBus.com</h5>
                          <h6 className="text-dark">Delhi to Agra</h6>
                          <h6 className="text-muted">18th Feb 2019</h6>
                        </div>
                        <div className="float-right">
                          <h6 className="text-dark text-right">USD 200.00</h6>
                          <p className="text-muted text-right">Sleeper Fare</p>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-bottom">
                  <div className="pm-30 py-3">
                    <div className="row">
                      <div className="col-md-3">
                        <label>
                          <ul className="trans-opt">
                            <li>
                              <label className="checkbox-container mt-2">
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li className="mr-0 text-right">
                              <div className="flight-icon car-bg">
                              <i className="material-icons">directions_bus</i>
                              </div>
                            </li>
                          </ul>
                        </label>
                      </div>
                      <div className="col-md-9">
                        <div className="float-left">
                          <h5 className="text-primary">OLA</h5>
                          <h6 className="text-dark">Location to Airport</h6>
                          <h6 className="text-muted">18th Feb 2019</h6>
                        </div>
                        <div className="float-right">
                          <h6 className="text-dark text-right">USD 200.00</h6>
                          <p className="text-muted text-right">Sleeper Fare</p>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-last btn-group-margin pm-30">
                  <button
                    type="button"
                    className="btn btn-badge"
                    data-dismiss="modal"
                  >
                    Dismiss
                  </button>
                  <button type="button" className="btn btn-danger">
                    Request Cancellation
                    <i className="material-icons">arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SubmitPage;
