import { observable, action } from "mobx"; 
import { flightService } from "../_services/flight.service";
import { toast } from "react-toastify";
import { debounce } from "lodash";

class FlightStore {
  @observable onewayToolbarData = {
    flightClass: "Economy",
    departureDate: new Date()
  };
  @observable airportResults = [];
  @observable searchFlightData = {};
  @observable loadingFlights = "loading";
  @observable flightResults = {};

  @action showTest() {
    console.log("flight store is initialized");
  }

  @action setOnewayToolbarData(name, value) {
    this.onewayToolbarData[name] = value;
  }

  @action setLocationFrom(locationDetails) {
    this.onewayToolbarData.locationFrom = locationDetails;
  }

  @action setLocationTo(locationDetails) {
    this.onewayToolbarData.locationTo = locationDetails;
  }

  // will receive locaiton from component and pass to debounce getAllAirport function
  @action getAirport(location) {
    if (location) {
      this.getAllAirport(location);
    } else {
      this.airportResults = [];
    }
  }

  // for debounce calls
  getAllAirport = debounce(
    action(location => {
      this.airportResults = [];
      flightService.getAirport(location).then(
        action(res => {
          if (res.result && res.result.length > 0) {
            this.airportResults = res.result;
          } else {
            this.airportResults = [];
          }
        }),
        action(error => {
          if (error.statusCode === 422) {
            let errors = error.message;
            Object.keys(errors).map(key => {
              return toast.error(errors[key]);
            });
          } else {
            toast.error(error.message);
          }
        })
      );
    }),
    500
  );

  // Swapping locationFrom and locationTo
  @action swapLocations() {
    let temp = {};
    temp = this.onewayToolbarData.locationFrom;
    this.onewayToolbarData.locationFrom = this.onewayToolbarData.locationTo;
    this.onewayToolbarData.locationTo = temp;
  }

  // function for searching flights and saving data present in the flightdata toolbar
  @action getFlights(searchData) {
    this.searchFlightData = searchData;
    localStorage.setItem("flightData", JSON.stringify(this.searchFlightData));
    // console.log(this.searchFlightData);
    flightService.searchFlights(this.searchFlightData).then(
      action(res => {
        this.loadingFlights = "done";
        this.flightResults = res.result;
      }),
      action(error => {
        console.log(error);
      })
    );
  }
}

export default new FlightStore();
