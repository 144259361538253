import React from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";

const receiptList = props => {
  return (
    <ul className="expense-reports receipts">
      {props.allReceipt
        ? props.allReceipt.map((list, index) => {
          return (
            <li key={index} className={props.deleteReceiptSelected == list.id ? 'active' : ''}
              onClick={(e) => props.onSelectExpenseHandler(list.id)}>
              {props.deleteReceiptSelected == list.id ?
                <div  className="abs">
                  <i  className="material-icons">
                    check_circle
                  </i>
                </div>
                : ""}
              <NavLink to="#">
                <div
                  className="image"
                  style={{ backgroundImage: `url(${list.s3ReceiptUrl})` }}
                />
                <p className="desc">{moment(list.createdAt).format("D MMM YYYY")}</p>
              </NavLink>
            </li>
          );
        })
        : ""}
    </ul>
  );
};

export default receiptList;
