import React from 'react'

export default function GenerateExceptionModal() {
  return (
    <div>
      <div
        className="modal fade"
        id="GenerateExceptionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="GenerateExceptionLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="ChangeApproverLabel">
                Edit Action
                  <button
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </h5>
            </div>
            <div className="modal-middle">
              <div className="pm-30 pb-0">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group custom labelup">
                      <label>Action Name</label>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn btn-border-basic dropdown-toggle btn-block"
                          data-toggle="dropdown"
                        >
                          Send Email
                        </button>
                        <div className="dropdown-menu">
                          <button type="button" className="dropdown-item">Change Approver</button>
                          <button type="button" className="dropdown-item">Generate Exception</button>
                          <button className="dropdown-item disabled">Reject (cash advance workflows only)</button>
                          <button className="dropdown-item">Send Back to Employee</button>
                          <button className="dropdown-item">Send Email</button>
                          <button className="dropdown-item ">Skip Step</button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group custom labelup">
                      <label>Exception Visibility</label>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn btn-border-basic dropdown-toggle btn-block"
                          data-toggle="dropdown"
                        >
                          Traveler, Approver and Expense Processor
                        </button>
                        <div className="dropdown-menu">
                          <button type="button" className="dropdown-item">Action 1</button>
                          <button className="dropdown-item ">Action 2</button>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
              <div className="table-container m-0 ">
                <form className="modal-px-30 py-0">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="btn-group-margin">
                        <button type="button" className="btn  ">New
                      </button>
                        <button className="btn   disabled">
                          Modify
                        </button>
                        <button className="btn   disabled">
                          Remove
                        </button>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="search-custom mb-0">
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="Search"
                        />
                        <span>
                        <i className="material-icons">search</i>
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead className="text-uppercase">
                      <tr>
                        <th>Code</th>
                        <th>Level</th>
                        <th>Product Code</th>
                        <th>Editable By</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>AACITY</td>
                        <td>1</td>
                        <td>REQ</td>
                        <td>Global</td>
                        <td>Aliquam faucibus, odio nec commodo aliquam, neque felis placerat</td>
                      </tr>
                      <tr>
                        <td>AACITYLMT</td>
                        <td>2</td>
                        <td>REQ</td>
                        <td>Global</td>
                        <td>Aliquam faucibus, odio nec commodo aliquam, neque felis placerat</td>
                      </tr>
                      <tr>
                        <td>PADCITY</td>
                        <td>99</td>
                        <td>REQ</td>
                        <td>Global</td>
                        <td>Aliquam faucibus, odio nec commodo aliquam, neque felis placerat</td>
                      </tr>
                      <tr>
                        <td>CITYCLAS</td>
                        <td>27</td>
                        <td>REQ</td>
                        <td>Global</td>
                        <td>Aliquam faucibus, odio nec commodo aliquam, neque felis placerat</td>
                      </tr>
                      <tr>
                        <td>DML25</td>
                        <td>7282</td>
                        <td>REQ</td>
                        <td>Global</td>
                        <td>Aliquam faucibus, odio nec commodo aliquam, neque felis placerat</td>
                      </tr>
                      <tr>
                        <td>DML30</td>
                        <td>7261</td>
                        <td>REQ</td>
                        <td>Global</td>
                        <td>Aliquam faucibus, odio nec commodo aliquam, neque felis placerat</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className=" modal-last btn-group-margin pm-30 pt-2">
                <button
                  type="button"
                  className="btn btn-border-success"
                >
                  Save
              </button>
                <button
                  type="button"
                  className="btn btn-border-basic"
                  data-dismiss="modal"
                >
                  Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}
