import React from 'react';
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import WorkFlowStepsMenu from '../workflow-steps-menu';
import { Select, Input } from 'src/_components/common/_forms';
import WorkflowStepTable from '../Components/WorkflowStepTable';
import SelectNew from 'react-select';
const WorkflowsStep2 = (props) => {

  let {
    selectedRole,
    RolesOptions,

    initialStateOptions,
    selectedinitialState,

    selectedapprovalAction,
    allWorkflowSteps,
    allApprovalOptions,
    selectedStep,
    editApprovalActionOptions,
    allExpenseApproval,
    loading,
    isEdit
  } = props.state;

  let {
    state,
    data,
    active,
    handleDropdownChange,
    handlePageChange,
    handleInputChange,
    handleToggleChange,
    handleCreate,
    stepStatusToggleHandler,
    stepSelectHandler,
    moveStep,
    removeWorkflowStep,
    handleApprovalChange,
    handleUpdate,
    handleInitialStatus,
    reset,
  } = props;

  let activeButtonClass = "";
  if (selectedStep.length > 0) {
    activeButtonClass = 'btn';
  } else {
    activeButtonClass = 'btn disabled';
  }
  let typeofname = localStorage['staticIs'];
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Workflows - Steps</h6>
              <p className="text">Add/Modify Workflow Information</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <WorkFlowStepsMenu workflow_id={state.workflow_id} count={allWorkflowSteps.length} />
            <div className="table-container">
              <form>
                <div className="row">
                  <div className="col-md-9">
                    <div className="btn-group-margin">
                      <button type="button" onClick={e => reset()} data-toggle="modal" data-target="#AddTemplateModal" className="btn">New</button>
                      <button type='button' disabled={!selectedStep.length} className={activeButtonClass} data-toggle='modal' data-target='#AddTemplateModal' >Modify</button>
                      <button disabled={!selectedStep.length} className={activeButtonClass} onClick={removeWorkflowStep} >Remove</button>
                    </div>
                  </div>
                  <div className="col-md-3">
                  </div>
                </div>
              </form>
              {/* Workflow Step Component */}
              <WorkflowStepTable
                data={allWorkflowSteps}
                stepStatusToggleHandler={stepStatusToggleHandler}
                stepSelectHandler={stepSelectHandler}
                selectedStep={selectedStep}
                moveStep={moveStep}
                loading={loading}
                typeofname={typeofname}
              />
              {(allWorkflowSteps.length === -1) &&
                <div className="table-footer">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="page-count">
                        <div className="text">Items</div>
                        <div className="count">
                          <div className="dropdown">
                            <button type="button" className="btn dropdown-toggle"
                              data-toggle="dropdown">
                              10
                      </button>
                            <div className="dropdown-menu">
                              <button type="button" className="dropdown-item">10</button>
                              <button type="button" className="dropdown-item">20</button>
                              <button type="button" className="dropdown-item">30</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Pagination
                        activePage={active}
                        itemsCountPerPage={10}
                        totalItemsCount={10}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                  {(allWorkflowSteps.length !== 0) && <div className="btn-group-margin mt-4">
                    <NavLink to={`/administration/company/workflows/workflow-step3/${props.state.workflow_id}`} className="btn">Next</NavLink>

                  </div>}
                </div>
              }
              <div className="">
                {(allWorkflowSteps.length !== 0) && <div className="btn-group-margin mt-4 ml-4">
                  <NavLink to={`/administration/company/workflows/workflow-step3/${props.state.workflow_id}`} className="btn">Next</NavLink>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="AddTemplateModal" tabIndex="-1" role="dialog"
        aria-labelledby="AddTemplateModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="AddTemplateModalLabel">{(isEdit) ? "Update" : "Add"} Workflow Step</h5>
              {/* using this line only after save it ll get close so dont remove */}
              <span className="close" data-dismiss="modal" aria-label="Close"></span>
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom">
                <Input
                  type="text"
                  value={state.workflowStepName}
                  placeholder="&nbsp;"
                  className="form-control"
                  name="workflowStepName"
                  label="Step Name"
                  imp="true"
                  controlFunc={e => handleInputChange(e, 'workflowStepName')}
                  error={props.error.message('Workflow Name', state.workflowStepName, 'required')}
                />
              </div>
              <div className="form-group custom labelup">
                <label>Step Role <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedRole || "select an option"}
                  keyName={"master_name_value"}
                  options={RolesOptions ? RolesOptions['master_value'] : []}
                  controlFunc={e => handleDropdownChange(e, RolesOptions, 'Role')}
                  error={props.error.message('Role', selectedRole, 'required')}
                />
              </div>

              {/* <div className={(selectedRole == "Authorized Approver" || selectedRole == "Cost Object Approver" ) ? "d-block" : "d-none"}>
                <div className="form-group custom">
                  <Input
                    type="number"
                    placeholder="&nbsp;"
                    className="form-control"
                    name="workflowStepName"
                    label="Level"
                  />
                </div> */}
                {/* <div className="row">
                  <div className="col-md-6 ">
                    <div className="form-group custom labelup">
                      <label>Form Type <span className="text-danger">*</span></label>
                      <Select
                        placeholder={selectedRole || "select an option"}
                        keyName={"master_name_value"}
                        options={RolesOptions ? RolesOptions['master_value'] : []}
                        controlFunc={e => handleDropdownChange(e, RolesOptions, 'Role')}
                        error={props.error.message('Role', selectedRole, 'required')}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group custom labelup">
                      <label>Field Name <span className="text-danger">*</span></label>
                      <Select
                        placeholder={selectedRole || "select an option"}
                        keyName={"master_name_value"}
                        options={RolesOptions ? RolesOptions['master_value'] : []}
                        controlFunc={e => handleDropdownChange(e, RolesOptions, 'Role')}
                        error={props.error.message('Role', selectedRole, 'required')}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="form-group custom labelup">
                <label>Initial Status <span className="text-danger">*</span></label>
                <Select
                  placeholder={selectedinitialState?.status_approval || "select an option"}
                  keyName={"status_approval"}
                  options={allExpenseApproval ? allExpenseApproval : []}
                  controlFunc={e => handleInitialStatus(e)}
                  error={props.error.message('Status Approval', selectedinitialState?.status_approval, 'required')}
                />
              </div>
              {/* Multi Select Approval */}
              <div className="form-group custom labelup textarea">
                <label>Approval Actions</label>
                <SelectNew
                  value={allApprovalOptions ? allApprovalOptions.filter(item => editApprovalActionOptions.includes(item.value)) : 'Search Actions'}
                  options={allApprovalOptions}
                  onChange={handleApprovalChange}
                  placeholder="Search Actions"
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                {/* <div className="help-block">{props.error.message('Approval', props.approvalAction?.length > 0?props.approvalAction[0].value : '', 'required' )}</div> */}
              </div>
              {/* End of Multi Select Approval */}
              <div className="modal-last btn-group-margin">
                <button type="submit" className="btn" onClick={(isEdit) ? handleUpdate : handleCreate} >
                  {(isEdit) ? "Update" : "Save"}
                </button>
                <button type="button" onClick={() => reset()} className="btn close"
                  data-dismiss="modal">Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default WorkflowsStep2;