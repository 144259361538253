import React, { Component } from 'react';
import RatesStep1Page from './rates-step-1-html';
class RatesStep1 extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <RatesStep1Page />
            </div>
        )
    }
}
export default RatesStep1;