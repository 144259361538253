import { http } from "../_helpers";
import { config } from "../env";

const getAllTaxDetails = (companyId) => {
  let url = `${config.apiUrlCompany}list-all-tax-details/${companyId}`;
  try{
    return http.get(url).then(res => {
      return (res && res.result) ? res.result : ""
    });
  }catch(err){

  }
}

const toggleTaxNumberStatus = (companyTaxDocumentId, payload) => {
  let url = `${config.apiUrlCompany}tax-details/change-status/${companyTaxDocumentId}`;
  try {
    return http.patch(url, payload).then(res => res)
  }catch(err){

  }
}

const toggleDefault = (companyTaxDocumentId, payload) => {
  let url = `${config.apiUrlCompany}tax-details/make-as-default/${companyTaxDocumentId}`;
  try {
    return http.patch(url, payload).then(res => res)
  }catch(err){

  }
}

const deleteTaxNumber = (companyTaxDocumentId, payload) => {
  let url = `${config.apiUrlCompany}tax-details/remove-tax/${companyTaxDocumentId}`;
  try {
    return http.patch(url, payload).then(res => res)
  }catch(err){

  }
}

const updateSettings = payload => {
  let url = `${config.apiUrlCompany}tax-details/add-settings`;
  try {
    return http.post(url, payload).then(res => res)
  }catch(err){

  }

}

const getAllCountries = () => {
  let url = `${config.apiUrlSuperAdmin}countries`;
  try {
    return http.get(url).then(res => res)
  }catch(err){

  }
}

const getAllStates = (countryId) => {
  let url = `${config.apiUrlSuperAdmin}states?countryId=${countryId}&isDependecyRequired=true`;
  try {
    return http.get(url).then(res => res)
  }catch(err){

  }
}

const getAllLocations = (stateId) => {
  let url = `${config.apiUrlSuperAdmin}cities?stateId=${stateId}&isDependecyRequired=true`;
  try {
    return http.get(url).then(res => res)
  }catch(err){

  }
}

const getAllCategories = () => {
  let url = `${config.apiUrlTravel}travel/travel-category`;
  try {
    return http.get(url).then(res => res)
  }catch(err){

  }
}

const addTaxNumber = (payload) => {
  let url = `${config.apiUrlCompany}add-tax-details`;
  try {
    return http.post(url, payload).then(res => res)
  }catch(err){

  }
}

const updateTaxNumber = (payload, companyTaxDocumentId) => {
  let url = `${config.apiUrlCompany}edit-tax-details/${companyTaxDocumentId}`;
  try {
    return http.patch(url, payload).then(res => res)
  }catch(err){

  }
}

export const taxNumberService = {
  getAllTaxDetails,
  toggleTaxNumberStatus,
  deleteTaxNumber,
  updateSettings,
  getAllCountries,
  getAllStates,
  getAllLocations,
  getAllCategories,
  addTaxNumber,
  updateTaxNumber,
  toggleDefault
}