import React, { Component } from 'react'

export default class trainHeaderTab extends Component {
  render() {
    return (
       <div className="top-heading">
                    <p className="float-left show-white border-right-white bg-white">Train Details</p>
                    <div className="p-to-head right float-left show-white border-right-white out-of-policy hover-v"
                    >
                        {/* <i className="material-icons fs-14 align-middle">
                            error
                            </i>{" "}
                        <span>
                            Out of policy
                        </span> */}
                        <div className="hover-name bottom out-of-p">
                            <p>This option breaks one or more company travel rules that prevent it from being selected.</p>
                            <span className="d-block">
                                <i className="material-icons">
                                    warning
                                </i>
                                2 or more company employees in the same flight.
                            </span>
                        </div>
                    </div>

                </div>

    )
  }
}
