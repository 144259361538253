import React, { Component } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { cartServices, masterService, expenseServices } from "src/_services";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";
import { MenuCartSubPage } from "./MenuCartSubmit";
import Moment from "react-moment";
import moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";
import ExpenseCreateForm from "./../../expense/components/addExpenseType/expenseCreateForm";
import { expenseReport, updatePerDiemDetails } from "./../../../../_services/expenseReport";
import CashAdvanceDynamicForm from "./../../../pages/expense/components/expenseAllocation/allocationDynamicForm";
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import "moment/locale/en-gb";
import { getValue, setValue } from "src/_components/common/lodsh";
import { getConnectedList } from "src/_services";
import { Select } from "src/_components/common/_forms";

class ExpensePage extends Component {
  constructor(props) {
    super(props);
    this.cashAdvanceValidator = new SimpleReactValidator();
    this.expenseReportValidate = new SimpleReactValidator();
    this.state = {
      today: new Date(),
      overAllTotal: 0,
      travelDays: "",
      expenseFormFields: [],
      expenseFieldsObject: [],
      singleCashForm: [],
      allPolicies: [],
      allExpenseTypes: [],
      allCurrencies: [],
      expenseForm: [],
      expenseDetails: [],
      cartDetails: [],
      cashAdvanceForm: [],
      connectedCreateListData: [],
      connectedListData: [],
      additionalExpense: [],
      companyId: localStorage["440a28"],
      pageName: "travelExpense",
      count: 0,
      cashAdvConnectedListData: [],
      selectedIndex: [],
      policyId: "",
      maxDate: "",
      expenseEntryFormId: "",
      selectedExpenseTypeId: "",
      editCashAdvance: [],
      isEdit: false,
      tripId: sessionStorage.TRIPID,
      isCashAdvanceEdit: false,
      selectedExpenseDelete: "",
      isLoading: false,
      cashAdvanceRequestedDate: "",
      totalBusinessExpense: 0,
      totalBaseCurrencyAmount: 0,
      totalItineraryAmount: 0,
      totalCashAdvanceAmount: 0,
      startTripDate: "",
      endTripDate: "",
      enableCashAdvnaceToggle: false,
      isCashAdvance: false,
      isExpense: false,
      getAllTravelDetailsData: [],
      isRequired: false,
      search: [],
      Loading: [],
      already: false
    }
  }

  componentDidMount() {
    this.getStatusOfApi();
    this.getCurrency();
  }
  componentWillUnmount() {
    this.state = {
      search: [],
      Loading: []
    }
  }

  // code for submit the request
  submitFinal = async () => {
    this.setState({
      loading: true
    });
    var resp = await cartServices.submitFinalRequest();
    if (resp) {
      let tripID = sessionStorage.TRIPID;
      sessionStorage.setItem("32AWE", tripID);
      toast.info("Request Submitted");
      $(".close").click();
      this.setState({
        loading: false
      });
      this.props.history.push(`/booking/submit/trip-details/${resp.processId}`);
    }
  };

  getExpenseType = async () => {
    const response = await expenseReport.getTravelExpenseTypes(
      this.state.companyId,
      this.state.policyId
    );
    if (response) {
      this.setState({ allExpenseTypes: response.result });
    }
  };

  getCurrency = async () => {
    const response = await masterService.getGroups(
      this.state.companyId,
      "CUR001"
    );
    if (response) {
      this.setState({ allCurrencies: response });
    }
  };
  //if api api status code is 200 then you need to proceed
  getStatusOfApi = async () => {
    this.setState({ isLoading: true });
    var response = await cartServices.getCartDataExpense();
    if (response.message === "Data Found") {
      await this.getDeatail();
      await this.getAllTravelDetails();
    } else {
      toast.error("Please complete travel request detail.");
      this.props.history.push("./travel-request-details");
    }
  };

  getOnlyStatusOfApi = async () => {
    await this.getDeatail();
    await this.getAllTravelDetails();
  };

  toDate = selector => {
    if (selector !== undefined && selector !== "") {
      var from = selector.split(/\//);
      return new Date(from[2], from[1] - 1, from[0]);
    }
  };
  mergeConnectedDataFromApi = data => {
    let additionalExpense = [];
    data.forEach((list, index) => {
      additionalExpense[index] = list;
      list.fieldData.forEach((formFields, fieldIndex) => {
        if (
          formFields.dataType === "connected_list" ||
          formFields.dataType === "different_list"
        ) {
          additionalExpense[index]["fieldData"][fieldIndex]["prevFieldId"] =
            formFields.fieldValue;
          let fieldValue = formFields.connectedListData.listManagementData.map(
            list => {
              if (list.id === formFields.fieldValue) return list.itemName;
            }
          );
          additionalExpense[index]["fieldData"][fieldIndex]["fieldValue"] =
            fieldValue[0];
        } else if (formFields.dataType === "search") {
          let fieldValue = formFields.location.locationName;
          additionalExpense[index]["fieldData"][fieldIndex][
            "fieldValue"
          ] = fieldValue;
          additionalExpense[index]["fieldData"][fieldIndex]["prevFieldId"] =
            formFields.fieldValue;
        } else if (formFields.dataType === "date") {
          additionalExpense[index]["fieldData"][fieldIndex][
            "fieldValue"
          ] = moment(this.toDate(formFields.fieldValue)).format("MMM D YYYY");
        } else {
          additionalExpense[index]["fieldData"][fieldIndex] = formFields;
        }
      });
    });
    return additionalExpense;
  };

  getAllTravelDetails = async () => {
    this.setState({ isLoading: true });
    const response = await cartServices.getDataExpense();

    let data = response.result.perDiemDetails.filter((item) => {
      if (item.isFlight == item.isHotel) {
        return item
      }
    })
    let data1 = data.filter((data) => {
      if (data.limitType === 0) {
        return data
      }
    })
    if (data1.length === 0) {
      this.setState({
        isRequired: true
      })
    }

    this.setState({ getAllTravelDetailsData: response.result.perDiemDetails })
    if (response && response.result) {
      await this.setState({
        expenseDetails: response.result,
        policyId: response.result.policyId._id,
        travelExpenseId: response.result.id,
        additionalExpense: response.result.additionalExpense,
        cashAdvanceRequestedDate: response.result.cashAdvanceDateOfIssue,
        totalBusinessExpense: response.result.totalExpenseAmount,
        isCashAdvance: response.result.policyId.isCashAdvance,
        isExpense: response.result.policyId.isExpense,
        totalCashAdvanceAmount: response.result.totalCashAdvanceAmount,
        // standardRateActive : getValue(response,"result.standardActive",false),
        guestActive: getValue(response, "result.guestActive", false),
        personalActive: getValue(response, "result.personalActive", false)
      });
      this.getExpenseType();
      if (response.result.travelFromDate) {
        this.setState({
          maxDate: this.convertDate(response.result.travelFromDate)
        });
      }
      if (response.result.cashAdvanceDateOfIssue) {
        var temp = new Date(response.result.cashAdvanceDateOfIssue);
        var date =
          temp.getDate() +
          "/" +
          (temp.getMonth() + 1) +
          "/" +
          temp.getFullYear();
        this.state.cashAdvanceDateOfIssue = temp;
        this.setState({ travelDays: date });
      }
      this.getFormsFeilds();
      this.getAllInfo();
      //calling this api to get the start and end date of travel because we need to show in cash advance date and expense date
      const trvStratDate = await cartServices.editTravelRequestFrom(
        this.state.companyId,
        this.state.tripId,
        this.state.selectedPolicyId
      );
      if (
        trvStratDate.data &&
        trvStratDate.data.REQUEST &&
        trvStratDate.data.REQUEST.entryFields &&
        trvStratDate.data.REQUEST.entryFields
      ) {
        let getAllcolumnName = trvStratDate.data.REQUEST.entryFields.map(
          fields => fields.columnName
        );
        let startTripDate = getAllcolumnName.indexOf("TRAVEL_DATE_FROM");
        let endTripDate = getAllcolumnName.indexOf("TRAVEL_DATE_TO");
        let personalDayOffFrom =
          trvStratDate.data.personalDayOff &&
          trvStratDate.data.personalDayOff.personalDayOff &&
          trvStratDate.data.personalDayOff.personalDayOffFrom;
        let personalDayOffTO =
          trvStratDate.data.personalDayOff &&
          trvStratDate.data.personalDayOff.personalDayOff &&
          trvStratDate.data.personalDayOff.personalDayOffTO;
        if (startTripDate >= 0 && endTripDate >= 0) {
          this.setState({
            startTripDate:
              trvStratDate.data.REQUEST.entryFields[startTripDate].fieldValue,
            endTripDate:
              trvStratDate.data.REQUEST.entryFields[endTripDate].fieldValue,
            personalDayOffFrom: personalDayOffFrom,
            personalDayOffTO: personalDayOffTO
          });
        }
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  getDeatail = async () => {
    let tripID = sessionStorage.TRIPID;
    const resp = await expenseReport.getCartDetailsList(tripID);
    if (resp.result)
      this.setState({
        cartDetails: resp.result,
        totalItineraryAmount: +resp.result.flightData + +resp.result.hotelData,
        isLoading: false,
        selectedPolicyId: resp.result.POLICY_ID,
        baseCurrency: resp.currency
      });
    else this.setState({ isLoading: false });
  };

  //added by sethu //need to handle when it have multi connected list arrays
  getSingleForm = async () => {
    var response = await masterService.getFieldList({
      companyId: this.state.companyId,
      formId: this.state.expenseDetails.cashAdvanceFormId
    });

    await (getValue(response, "result", [])).forEach(async item => {
      if (item.connectedListId) {
        let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
        item.page = 1;
        item.connectedListDataCount = getValue(resp, "count", 0);
        item.connectedListData.listManagementData = resp.result;
        item.search = ''
      }
      this.setState({ singleCashForm: response.result })
    })
    if (response && response.result) {
      return getValue(response, "result", [])
    }

  }


  //get cash Advance form and fields
  getFormsFeilds = async () => {
    this.setState({ isLoading: true });
    var response = await masterService.getFieldList({
      companyId: this.state.companyId,
      formId: this.state.expenseDetails.cashAdvanceFormId
    });

    //for getting connected list data added by sethu
    await (getValue(response, "result", [])).forEach(async item => {
      if (item.connectedListId) {
        let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
        item.page = 1;
        item.connectedListDataCount = getValue(resp, "count", 0);
        item.connectedListData.listManagementData = resp.result;
        item.search = ''
      }
    })

    //after submit edit cash Advnace request
    let editResponse = await expenseReport.EditCashAdvanceDataList(
      this.state.tripId
    );


    //for getting connected list data after edit added by sethu
    if (editResponse && editResponse.result && editResponse.result.cashAdvanceFormData) {
      for (let i = 0; i < editResponse.result.cashAdvanceFormData.length; i++) {
        editResponse.result.cashAdvanceFormData[i].forEach(async item => {
          if (item.connectedListId) {
            let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
            item.page = 1;
            item.connectedListDataCount = getValue(resp, "count", 0);
            item.connectedListData.listManagementData = resp.result;
            item.search = ''
          }
        })
      }
    }


    let cashAdvnaceResponse = editResponse;
    editResponse =
      editResponse &&
      editResponse.result &&
      editResponse.result.cashAdvanceFormData;
    let editCount = editResponse && editResponse.length;
    if (
      editResponse &&
      editResponse.length > 0 &&
      Array.isArray(editResponse)
    ) {

      if (editResponse) {
        this.setState({
          totalCashAdvanceAmount:
            cashAdvnaceResponse.result.totalCashAdvanceAmount,
        });

        let newEditArray = [];
        let allocationEditIds = [];
        editCount = editCount ? editCount : 0;
        editResponse &&
          editResponse.forEach((data, index) => {
            data.forEach(async form => {
              if (form.dataType === "search") {
                if (form.fieldValue !== "" && form.fieldValue !== undefined) {
                  this.setState({
                    [form.fieldName + index]: {
                      label: form.fieldValue,
                      value: form.prevFieldId
                    }
                  });
                } else {
                  this.setState({ [form.fieldName + index]: [] });
                }
              } else if (
                form.dataType === "connected_list" ||
                form.dataType === "different_list"
              ) {
                this.setState({
                  [form.fieldName + index]: form.prevFieldId
                    ? form.prevFieldId
                    : ""
                });
              } else {
                this.setState({
                  [form.fieldName + index]: form.fieldValue
                    ? form.fieldValue
                    : ""
                });
              }
            });
            newEditArray[index] = data;
          });
        this.setState({
          editCashAdvance: newEditArray,
          allocationEditIds: allocationEditIds,
          isCashAdvanceEdit: true,
          enableCashAdvnaceToggle: true
        });
        this.setState({ count: editCount - 1 });
        //edit expense allocation details
        let newConnectedListDate = [];
        let dynamicFeilds = [];
        let editExpenseDynamicForm = [];
        newEditArray.forEach(list => {
          dynamicFeilds = _.filter(list, function (result) {
            return result.fieldLevel !== undefined && result.fieldLevel !== "";
          });
          editExpenseDynamicForm.push(dynamicFeilds);
        });
        editExpenseDynamicForm.forEach((fields, index) => {
          newConnectedListDate[index] = fields.map(editFields => ({
            columnName: editFields.columnName,
            id: editFields.id,
            multiSelectParentField: editFields.multiSelectParentField
              ? editFields.multiSelectParentField
              : "",
            fieldName: editFields.fieldName,
            fieldLevel: editFields.fieldLevel,
            prevFieldId: editFields.prevFieldId,
            fieldValue: editFields.fieldValue
          }));
        });
        this.setState({ connectedListData: newConnectedListDate });
        // start set the state for connected list for dropdown

        newConnectedListDate &&
          newConnectedListDate.forEach((lists, index) => {
            lists &&
              lists.forEach(async fields => {
                if (fields.fieldLevel === 1) {
                  let newFieldLevel = fields.fieldLevel + 1;
                  let existConnected = lists.filter(
                    listData =>
                      listData.multiSelectParentField === fields.id &&
                      listData.fieldLevel === newFieldLevel
                  );
                  if (
                    existConnected.length > 0 &&
                    fields.fieldValue !== undefined &&
                    fields.fieldValue !== ""
                  ) {
                    let companyId = this.state.companyId;
                    let response = await expenseReport.getConnectedListData(
                      companyId,
                      fields.fieldValue
                    );
                    let childName = existConnected[0].columnName + index;
                    if (response) {
                      this.setState({ [childName]: response });
                    }
                  }
                } else if (fields.fieldLevel >= 1) {
                  let newFieldLevel = fields.fieldLevel + 1;
                  let existConnected = lists.filter(
                    listData =>
                      listData.multiSelectParentField ===
                      fields.multiSelectParentField &&
                      listData.fieldLevel === newFieldLevel
                  );
                  if (
                    existConnected.length > 0 &&
                    fields.fieldValue !== undefined &&
                    fields.fieldValue !== ""
                  ) {
                    let companyId = this.state.companyId;
                    let response = await expenseReport.getConnectedListData(
                      companyId,
                      fields.fieldValue
                    );
                    let childName = existConnected[0].columnName + index;
                    if (response) {
                      this.setState({ [childName]: response });
                    }
                  }
                }
              });
          });
        //end connected list edit
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });
    }

    //for getting connected list data added by sethu
    if (cashAdvnaceResponse && cashAdvnaceResponse.result && cashAdvnaceResponse.result.cashAdvanceFormData.length !== 0) {
      let length = cashAdvnaceResponse.result.cashAdvanceFormData.length
      for (let i = 0; i < length; i++) {
        cashAdvnaceResponse.result.cashAdvanceFormData[i].forEach(async (item) => {
          if (item.connectedListId) {
            let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
            item.page = 1;
            item.connectedListDataCount = getValue(resp, "count", 0);
            item.connectedListData.listManagementData = resp.result;
            item.search = ''
          }
        })
      }
    }
    //--end ---//
    if (response.count > 0) {
      const NewArray = [];
      NewArray[0] = response.result;
      this.setState({
        singleCashForm: response.result,
        // cashAdvanceForm:  NewArray,
        //for setting connected list data into field added by sethu
        cashAdvanceForm: cashAdvnaceResponse && cashAdvnaceResponse.result && cashAdvnaceResponse.result.cashAdvanceFormData.length !== 0 ? cashAdvnaceResponse.result.cashAdvanceFormData : NewArray,
        cashFormHeaderId: response.result[0].formHeaderId,
        cashFormId: response.result[0].formId
      });

      let cashAdvConnectedListData = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.result, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== "";
      });

      dynamicFeilds.forEach(fields => {
        cashAdvConnectedListData.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField
            ? fields.multiSelectParentField
            : "",
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.defaultValue ? fields.defaultValue : "",
          fieldValue: fields.defaultValue ? fields.defaultValue : ""
        });
      });
      this.setState({ cashAdvConnectedListData: cashAdvConnectedListData });

      cashAdvConnectedListData &&
        cashAdvConnectedListData.forEach(async (fields, index) => {
          if (fields.fieldLevel === 1) {
            let newFieldLevel = fields.fieldLevel + 1;
            let existConnected = cashAdvConnectedListData.filter(
              listData =>
                listData.multiSelectParentField === fields.id &&
                listData.fieldLevel === newFieldLevel
            );
            if (
              existConnected.length > 0 &&
              fields.prevFieldId !== undefined &&
              fields.prevFieldId !== ""
            ) {
              let companyId = this.state.companyId;
              let response = await expenseReport.getConnectedListData(
                companyId,
                fields.prevFieldId
              );
              let childName = existConnected[0].columnName + index;
              if (response) {
                this.setState({ [childName]: response });
              }
            }
          } else if (fields.fieldLevel >= 2) {
            let newFieldLevel = fields.fieldLevel + 1;
            let existConnected = cashAdvConnectedListData.filter(
              listData =>
                listData.multiSelectParentField ===
                fields.multiSelectParentField &&
                listData.fieldLevel === newFieldLevel
            );
            if (
              existConnected.length > 0 &&
              fields.prevFieldId !== undefined &&
              fields.prevFieldId !== ""
            ) {
              let companyId = this.state.companyId;
              let response = await expenseReport.getConnectedListData(
                companyId,
                fields.prevFieldId
              );
              let childName = existConnected[0].columnName + index;
              if (response) {
                this.setState({ [childName]: response });
              }
            }
          }
        });
      let formFields = [];
      let fieldsObjects = [];
      let index = editCount;
      response.result.map(async formField => {
        let fieldName = formField.fieldName + index;
        if (formField.dataType === "date") {
          if (formField.isDefaultValue) {
            this.setState({
              [fieldName]: moment(formField.defaultValue).format("DD MMM YYYY")
            });
          } else {
            this.setState({ [fieldName]: null });
          }
        } else if (formField.dataType === "checkbox") {
          if (formField.isDefaultValue) {
            this.setState({ [fieldName]: formField.defaultValue });
          } else {
            this.setState({ [fieldName]: false });
          }
        } else if (formField.dataType === "search") {
          this.setState({ ["search" + fieldName]: [] });

          if (formField.isDefaultValue) {
            this.setState({ [fieldName]: formField.defaultValue });
          } else {
            this.setState({ [fieldName]: "" });
          }
        } else {
          if (formField.isDefaultValue) {
            this.setState({ [fieldName]: formField.defaultValue });
          } else {
            this.setState({ [fieldName]: "" });
          }
        }
        formFields.push(formField.fieldName);
        fieldsObjects.push({
          fieldId: formField.id,
          columnName: formField.columnName
        });
      });
      this.setState({ formFields: formFields, fieldsObject: fieldsObjects });
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  convertDate(date) {
    var from = date.split("/");
    var f = new Date(from[2], from[1] - 1, from[0]);
    return f;
  }

  onExpenseSubmitHandler = async () => {
    if (this.expenseReportValidate.allValid()) {
      let fieldsObjects = this.state.expenseFieldsObject;

      let index = 0;
      for (const key of this.state.expenseFormFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          fieldsObjects[index]["fieldValue"] = this.state[key]
            ? this.state[key].value
            : "";
        }
        else {
          fieldsObjects[index]["fieldValue"] = this.state[key]
        }
        // else if(fieldsObjects[index].columnName ===  "CUSTOM1" &&
        //   fieldsObjects[index].dataType=== "connected_list"){
        //   fieldsObjects[index]["fieldValue"] = this.state[key]["id"];
        //   fieldsObjects[index]["fieldItemName"] = this.state[key]["name"];
        // }
        // else if(fieldsObjects[index].dataType === "connected_list"){
        //   fieldsObjects[index]["fieldValue"] = this.state[key]["id"];
        //   fieldsObjects[index]["fieldItemName"] = this.state[key]["name"];
        // }
        // else {
        // if (this.state[key] && (this.state[key]["name"] || this.state[key]["id"])) {
        //   fieldsObjects[index]["fieldValue"] = this.state[key]["id"];
        //   fieldsObjects[index]["fieldItemName"] = this.state[key]["name"];
        // }

        // }
        index++;
      }
      let data = {
        additionalExpense: [
          {
            formEntryId: this.state.formEntryId,
            policyId: this.state.policyId,
            expenseTypeId: this.state.selectedExpenseTypeId,
            fieldData: fieldsObjects
          }
        ]
      };
      let response = await expenseReport.createExpenseEntryTravel(
        data,
        this.state.travelExpenseId
      );
      if (response) {
        this.setState({
          expenseTypeId: "",
          selectedExpenseName: "",
          expenseForm: [],
          selectedExpenseTypeId: ""
        });
        $(".close").click();
        toast.success(response.message);
        this.getAllTravelDetails();
        this.getDeatail();
      }
    } else {
      this.expenseReportValidate.showMessages();
      this.forceUpdate();
    }
  };

  getAllInfo() {
    var newItem = 0;
    let totalBaseCurrencyAmount = 0;
    if (
      this.state.expenseDetails &&
      this.state.expenseDetails.perDiemDetails.length > 0
    ) {
      this.state.expenseDetails.perDiemDetails.map((list, key) => {
        if (list) {
          newItem += +list.actualCurrencyAmount;
          totalBaseCurrencyAmount += +list.baseCurrencyAmount;
        }
      });
      this.setState({
        overAllTotal: newItem,
        totalBaseCurrencyAmount: totalBaseCurrencyAmount,
        baseCurrency: this.state.expenseDetails.perDiemDetails[0]
          .baseCurrencyType
      });
    }
  }

  onSelectExpenseType = async (selectedExpenseType, expenseEntryFormId) => {
    this.setState({
      expenseEntryFormId: expenseEntryFormId,
      selectedExpenseName: selectedExpenseType.expenseTypeName,
      selectedExpenseTypeId: selectedExpenseType.id
    });
    let data = {
      companyId: this.state.companyId,
      formId: expenseEntryFormId
    };
    const response = await expenseReport.getTravelExpenseReportForm(data);
    if (response && getValue(response, "result", [])) {
      response && (getValue(response, "result", [])).forEach(async (item) => {
        if (item.dataType === "connected_list") {
          if (item.connectedListId) {
            let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
            item.page = 1;
            item.connectedListDataCount = getValue(resp, "count", 0);
            item.connectedListData.listManagementData = resp.result;
            item.search = ''
          }
          this.setState({ expenseForm: response.result })
        }
      })
    }

    if (response && response.result) {
      this.setState({
        expenseForm: response.result,
        formEntryId: response.result[0].formId
      });
      let formFields = [];
      let fieldsObjects = [];
      let newConnectedListDate = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.result, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== "";
      });
      dynamicFeilds.forEach(fields => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField
            ? fields.multiSelectParentField
            : "",
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.defaultValue ? fields.defaultValue : ""
        });
      });
      this.setState({ connectedListData: newConnectedListDate });

      newConnectedListDate &&
        newConnectedListDate.forEach(async fields => {
          if (fields.fieldLevel === 1) {
            let newFieldLevel = fields.fieldLevel + 1;
            let existConnected = newConnectedListDate.filter(
              listData =>
                listData.multiSelectParentField === fields.id &&
                listData.fieldLevel === newFieldLevel
            );
            if (
              existConnected.length > 0 &&
              fields.prevFieldId !== undefined &&
              fields.prevFieldId !== ""
            ) {
              let companyId = this.state.companyId;
              let response = await expenseReport.getConnectedListData(
                companyId,
                fields.prevFieldId
              );
              let childName = existConnected[0].columnName;
              if (response) {
                this.setState({ [childName]: response });
              }
            }
          } else if (fields.fieldLevel >= 2) {
            let newFieldLevel = fields.fieldLevel + 1;
            let existConnected = newConnectedListDate.filter(
              listData =>
                listData.multiSelectParentField ===
                fields.multiSelectParentField &&
                listData.fieldLevel === newFieldLevel
            );
            if (
              existConnected.length > 0 &&
              fields.prevFieldId !== undefined &&
              fields.prevFieldId !== ""
            ) {
              let companyId = this.state.companyId;
              let response = await expenseReport.getConnectedListData(
                companyId,
                fields.prevFieldId
              );
              let childName = existConnected[0].columnName;
              if (response) {
                this.setState({ [childName]: response });
              }
            }
          }
        });

      response.result.forEach(async formField => {
        let fieldName = formField.fieldName;
        if (formField.dataType === "date") {
          if (formField.isDefaultValue) {
            this.setState({
              [fieldName]: moment(formField.defaultValue).format("DD MMM YYYY")
            });
          } else {
            this.setState({ [fieldName]: "" });
          }
        } else if (formField.dataType === "checkbox") {
          if (formField.isDefaultValue) {
            this.setState({ [fieldName]: formField.defaultValue });
          } else {
            this.setState({ [fieldName]: false });
          }
        } else {
          if (formField.isDefaultValue) {
            this.setState({ [fieldName]: formField.defaultValue });
          } else {
            this.setState({ [fieldName]: "" });
          }
        }
        formFields.push(formField.fieldName);
        fieldsObjects.push({
          fieldId: formField.id,
          columnName: formField.columnName
        });
      });
      this.setState({ isLoading: false });
      this.setState({
        expenseFormFields: formFields,
        expenseFieldsObject: fieldsObjects
      });
    } else {
      toast.success("No Data Found");
      this.setState({ isLoading: false });
    }
  };

  onCancelClickHandler = () => {
    this.props.history.push("./details");
  };

  onClickHandler = async (fieldName, conId, fieldId, fieldLevel, assignKey) => {
    if (assignKey) {
      this.state.search[assignKey] = '';
      this.setState({ search: this.state.search })
    } else { }
    this.setState({ [fieldName]: fieldId });
    // this.setState({ [fieldName]: {"id":fieldId,"name": fieldItemName }});

    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onClickHandler1 = async (fieldName, conId, fieldId, fieldLevel, fieldItemName) => {
    // this.setState({ [fieldName]: fieldId });
    this.setState({ [fieldName]: { "id": fieldId, "name": fieldItemName } });

    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };

  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.setState({ [fieldName]: fieldId });
    // this.setState({ [fieldName]: {"id":fieldId,"name": fieldItemName }});
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };

  onConnectedListkHandler1 = async (fieldName, conId, fieldId, fieldLevel, fieldItemName) => {
    // this.setState({ [fieldName]: fieldId });
    this.setState({ [fieldName]: { "id": fieldId, "name": fieldItemName } });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  //click handler for cash advance
  onCashClickHandler = async (
    fieldName,
    conId,
    fieldId,
    fieldLevel,
    assignKey,
    rowIndex
  ) => {

    this.state.search[assignKey] = '';
    this.setState({ search: this.state.search })

    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.cashAdvConnectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName + rowIndex;
      let fieldName = existConnected[0].fieldName + rowIndex;
      if (response) {
        this.setState({ [childName]: response, [fieldName]: "" });
      }
    }
  };
  //connected list handler for cash advance
  onCashAdvConnectedListkHandler = async (
    fieldName,
    conId,
    fieldId,
    fieldLevel,
    rowIndex
  ) => {
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedCreateListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName + rowIndex;
      let fieldName = existConnected[0].fieldName + rowIndex;
      if (response) {
        this.setState({ [childName]: response, [fieldName]: "" });
      }
    }
  };

  onChangeHandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };
  onDatePickerHandler = (value, fieldName) => {
    if (!isNaN(value)) {
      this.setState({
        [fieldName]: moment(new Date(value)).format("DD MMM YYYY")
      });
    }
  };
  onCheckboxHandler = fieldName => {
    this.setState({ [fieldName]: !this.state[fieldName] });
  };

  onIntegerHandler = (event, fieldName) => {
    if (!isNaN(event.target.value)) {
      this.setState({ [fieldName]: +event.target.value });
    }
  };

  onLocationChangekHandler = (event, fieldName) => {
    this.setState({ [fieldName]: event });
  };

  loadOptions = async newValue => {
    return expenseReport.getAllLocationsName(newValue).then(data => {
      return data.result.map(list => {
        return {
          label: list.name + "," + list.countryId.name,
          value: list.id
        };
      });
    });
  };

  //cash Advance subnit handler
  onCashAdvanceSubmitHandler = async event => {
    localStorage.removeItem("conncted_list_name")
    if (this.cashAdvanceValidator.allValid()) {
      this.setState({ isLoading: true });
      let fieldsObjects = [];
      if (this.state.isCashAdvanceEdit)
        fieldsObjects = [...this.state.editCashAdvance];
      else fieldsObjects = [...this.state.cashAdvanceForm];
      let cashAdvanceFormData = [];
      let submitArray = [];
      for (let val in fieldsObjects) {
        let internalArray = [];
        for (let value in fieldsObjects[val]) {
          let val1 = fieldsObjects[val];
          let fieldId = val1[value].id;
          let columnName = val1[value].columnName;
          let fieldValue = "";
          if (columnName === "LOCATION_NAME") {
            fieldValue =
              this.state[val1[value].fieldName + val] !== ""
                ? this.state[val1[value].fieldName + val].value
                : "";
          } else {
            fieldValue = this.state[val1[value].fieldName + val];
            let data = fieldsObjects[val].filter((item, index) => item.fieldName === val1[value].fieldName)
            if (data[0].connectedListData) {
              if (this.state[val1[value].fieldName + val]) {
                sessionStorage.setItem("trdId", this.state[val1[value].fieldName + val])
              }
              let data1 = data[0].connectedListData.listManagementData
              let data2 = data1.filter((i) => sessionStorage.getItem("trdId") == i.id)
              let data3 = data2[0] ? data2[0].itemName : "";
              localStorage.setItem("conncted_list_name", data3)
            }
          }

          // if (fieldValue === undefined) {
          //   internalArray.push({ fieldId: fieldId, fieldValue: "" });
          // } else {
          //   internalArray.push({
          //     fieldId: fieldId,
          //     fieldValue: fieldValue,
          //     columnName: columnName
          //   });
          // }

          if (fieldValue === undefined && columnName) {
            if (sessionStorage.getItem("trdId1") === fieldValue) {
              internalArray.push({
                fieldId: fieldId,
                fieldValue: "",
                fieldItemName: localStorage.getItem("conncted_list_name")
              });
            } else {
              internalArray.push({
                fieldId: fieldId,
                fieldValue: "",
              })
            }
          } else if (columnName) {
            if (sessionStorage.getItem("trdId") === fieldValue) {
              internalArray.push({
                fieldId: fieldId,
                fieldValue: fieldValue,
                columnName: columnName,
                fieldItemName: localStorage.getItem("conncted_list_name")
              });
            } else {
              internalArray.push({
                fieldId: fieldId,
                fieldValue: fieldValue,
                columnName: columnName,
              });
            }

          }
        }
        submitArray.push(internalArray);
        cashAdvanceFormData.push({ fieldData: internalArray });
      }
      let data = {
        cashAdvanceDateOfIssue: this.state.cashAdvanceRequestedDate,
        cashAdvanceFormData: cashAdvanceFormData
      };
      console.log(data);
      let response = await expenseReport.createCashAdvanceTravelExpense(
        data,
        this.state.travelExpenseId
      );
      if (response) {
        localStorage.removeItem("conncted_list_name")
        this.getFormsFeilds();
        // this.getDeatail();
        toast.success(response.message);
        // this.props.history.push("./expense");
        //refreshing issue causing
        window.location.href = "./expense"
      }
    } else {
      this.cashAdvanceValidator.showMessages();
      this.forceUpdate();
    }
  };

  onAddClickHandler = async () => {
    let count = this.state.count;
    let newCount = count + 1;
    // let singleCashForm = [... this.state.singleCashForm];
    //for getting new connectedlist in second form
    let singleCashForm = await this.getSingleForm();
    if (this.state.isCashAdvanceEdit) {
      let editCashAdvance = [...this.state.editCashAdvance];
      editCashAdvance[newCount] = singleCashForm;
      this.setState({ editCashAdvance: editCashAdvance });
    } else {
      let cashAdvanceForm = [...this.state.cashAdvanceForm];
      cashAdvanceForm[newCount] = singleCashForm;
      this.setState({ cashAdvanceForm: cashAdvanceForm });
    }
    this.setState({ count: newCount });
    singleCashForm.forEach(async (formField, index) => {
      let fieldName = formField.fieldName + newCount;
      if (formField.dataType === "date") {
        let todayDate = new Date();
        todayDate = moment(todayDate).format("DD MMM YYYY");
        if (formField.isDefaultValue) {
          this.setState({
            [fieldName]: moment(formField.defaultValue).format("DD MMM YYYY")
          });
        } else {
          this.setState({ [fieldName]: todayDate });
        }
      } else if (formField.dataType === "checkbox") {
        if (formField.isDefaultValue) {
          this.setState({ [fieldName]: formField.defaultValue });
        } else {
          this.setState({ [fieldName]: false });
        }
      } else if (formField.dataType === "search") {
        this.setState({ ["search" + fieldName]: [] });
        if (formField.isDefaultValue) {
          this.setState({ [fieldName]: formField.defaultValue });
        } else {
          this.setState({ [fieldName]: "" });
        }
      } else {
        if (formField.isDefaultValue) {
          this.setState({ [fieldName]: formField.defaultValue });
        } else {
          this.setState({ [fieldName]: "" });
        }
      }
    });
    let newConnectedListData = [...this.state.connectedCreateListData];
    newConnectedListData &&
      newConnectedListData.forEach(async fields => {
        if (fields.fieldLevel === 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListData.filter(
            listData =>
              listData.multiSelectParentField === fields.id &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName + newCount;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 2) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListData.filter(
            listData =>
              listData.multiSelectParentField ===
              fields.multiSelectParentField &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName + newCount;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }
      });
  };

  onSelectedAllocationFieldHandler = value => {
    let selectedIndex = [...this.state.selectedIndex];
    if (selectedIndex.includes(value)) {
      selectedIndex = selectedIndex.filter(id => id !== value);
    } else {
      selectedIndex = selectedIndex.concat(value);
    }
    this.setState({ selectedIndex: selectedIndex });
  };

  onDeleteAllocationField = () => {
    let selectedIndex = [...this.state.selectedIndex];
    let cashAdvanceForm = [];
    let isEdit = false;
    if (this.state.isCashAdvanceEdit) {
      cashAdvanceForm = [...this.state.editCashAdvance];
      let deleteArrLength = selectedIndex.length;
      _.pullAt(cashAdvanceForm, selectedIndex);
      this.setState({ editCashAdvance: cashAdvanceForm, selectedIndex: [] });
      let count = this.state.count;
      this.setState({ count: count - deleteArrLength });
      isEdit = true;
    } else {
      cashAdvanceForm = [...this.state.cashAdvanceForm];
      let deleteArrLength = selectedIndex.length;
      _.pullAt(cashAdvanceForm, selectedIndex);
      this.setState({ cashAdvanceForm: cashAdvanceForm, selectedIndex: [] });
      let count = this.state.count;
      this.setState({ count: count - deleteArrLength });
      isEdit = false;
    }
    cashAdvanceForm.forEach((fields, index) => {
      //this is for after creation of cash advance
      if (isEdit) {
        fields.forEach(async formField => {
          let fieldName = formField.fieldName + index;
          if (formField.dataType === "date") {
            this.setState({
              [fieldName]: moment(formField.fieldValue).format("DD MMM YYYY")
            });
          } else if (
            formField.dataType === "connected_list" ||
            formField.dataType === "different_list"
          ) {
            this.setState({
              [fieldName]: formField.prevFieldId ? formField.prevFieldId : ""
            });
          } else if (formField.dataType === "search") {
            if (
              formField.prevFieldId !== undefined &&
              formField.prevFieldId !== ""
            ) {
              await this.setState({
                [fieldName]: {
                  label: formField.fieldValue,
                  value: formField.prevFieldId
                }
              });
            } else {
              this.setState({ [fieldName]: "" });
            }
          } else {
            this.setState({ [fieldName]: formField.fieldValue });
          }
        });
        //before cash advance creation
      } else {
        fields.forEach(async formField => {
          let fieldName = formField.fieldName + index;
          if (formField.dataType === "date") {
            this.setState({
              [fieldName]: moment(this.state[fieldName]).format("DD MMM YYYY")
            });
          } else {
            this.setState({ [fieldName]: this.state[fieldName] });
          }
        });
      }
    });
  };
  onCashAdvDateChangeHanler = value => {
    if (!isNaN(value)) {
      this.setState({
        cashAdvanceRequestedDate: moment(value).format("DD MMM YYYY")
      });
    }
  };
  onClickCloseModal = () => {
    this.setState({
      expenseTypeId: "",
      selectedExpenseName: "",
      expenseForm: [],
      selectedExpenseTypeId: ""
    });
    $(".close").click();
  };
  onEditExpenseClickHandler = value => {
    //  getValue(value,"fieldData",[]).forEach(async (item) => {
    //    if (item.dataType === "connected_list") {
    //      if (item.connectedListId) {
    //        let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
    //        item.page = 1;
    //        item.connectedListDataCount = getValue(resp, "count", 0);
    //        item.connectedListData.listManagementData = resp.result;
    //        item.search = ''
    //      }
    //      // this.setState({ expenseForm: response.result })
    //    }
    //  })

    let selectedExpenseName = this.state.allExpenseTypes.map(expense => {
      if (expense.expenseTypeId.id === value.expenseTypeId)
        return expense.expenseTypeId.expenseTypeName;
    });
    this.setState({
      selectedExpenseName: selectedExpenseName,
      editExpenseId: value._id,
      formEntryId: value.fieldData[0].formId,
      isEdit: true
    });
    if (value) {
      this.setState({
        expenseForm: value.fieldData,
        selectedExpenseTypeId: value.expenseTypeId
      });
      let formFields = [];
      let fieldsObjects = [];
      let newConnectedListDate = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(value.fieldData, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== "";
      });
      dynamicFeilds.forEach(fields => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField
            ? fields.multiSelectParentField
            : "",
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.defaultValue ? fields.defaultValue : ""
        });
      });
      this.setState({ connectedListData: newConnectedListDate });

      newConnectedListDate &&
        newConnectedListDate.forEach(async fields => {
          if (fields.fieldLevel === 1) {
            let newFieldLevel = fields.fieldLevel + 1;
            let existConnected = newConnectedListDate.filter(
              listData =>
                listData.multiSelectParentField === fields.id &&
                listData.fieldLevel === newFieldLevel
            );
            if (
              existConnected.length > 0 &&
              fields.prevFieldId !== undefined &&
              fields.prevFieldId !== ""
            ) {
              let companyId = this.state.companyId;
              let response = await expenseReport.getConnectedListData(
                companyId,
                fields.prevFieldId
              );
              let childName = existConnected[0].columnName;
              if (response) {
                this.setState({ [childName]: response });
              }
            }
          } else if (fields.fieldLevel >= 2) {
            let newFieldLevel = fields.fieldLevel + 1;
            let existConnected = newConnectedListDate.filter(
              listData =>
                listData.multiSelectParentField ===
                fields.multiSelectParentField &&
                listData.fieldLevel === newFieldLevel
            );
            if (
              existConnected.length > 0 &&
              fields.prevFieldId !== undefined &&
              fields.prevFieldId !== ""
            ) {
              let companyId = this.state.companyId;
              let response = await expenseReport.getConnectedListData(
                companyId,
                fields.prevFieldId
              );
              let childName = existConnected[0].columnName;
              if (response) {
                this.setState({ [childName]: response });
              }
            }
          }
        });

      value.fieldData.forEach(async formField => {
        let fieldName = formField.fieldName;
        if (formField.dataType === "date") {
          this.setState({
            [fieldName]: moment(formField.fieldValue).format("DD MMM YYYY")
          });
        } else if (
          formField.dataType === "connected_list" ||
          formField.dataType === "different_list"
        ) {
          this.setState({
            [fieldName]: formField.prevFieldId ? formField.prevFieldId : ""
          });
        } else if (formField.dataType === "search") {
          if (
            formField.prevFieldId !== undefined &&
            formField.prevFieldId !== ""
          ) {
            await this.setState({
              [fieldName]: {
                label: formField.fieldValue,
                value: formField.prevFieldId
              }
            });
          } else {
            this.setState({ [fieldName]: "" });
          }
        } else {
          this.setState({ [fieldName]: formField.fieldValue });
        }
        formFields.push(formField.fieldName);
        fieldsObjects.push({
          fieldId: formField.id,
          columnName: formField.columnName
        });
      });
      this.setState({ isLoading: false });
      this.setState({
        expenseFormFields: formFields,
        expenseFieldsObject: fieldsObjects
      });
    }
  };
  onUpdateExpenseHandler = async () => {
    if (this.expenseReportValidate.allValid()) {
      let fieldsObjects = this.state.expenseFieldsObject;
      let index = 0;
      for (const key of this.state.expenseFormFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          fieldsObjects[index]["fieldValue"] = this.state[key]
            ? this.state[key].value
            : "";
        } else {
          fieldsObjects[index]["fieldValue"] = this.state[key];
        }
        index++;
      }
      let data = {
        additionalExpense: {
          formEntryId: this.state.formEntryId,
          id: this.state.editExpenseId,
          policyId: this.state.policyId,
          expenseTypeId: this.state.selectedExpenseTypeId,
          fieldData: fieldsObjects
        }
      };
      let response = await expenseReport.updateTravelExpense(
        data,
        this.state.travelExpenseId
      );
      if (response) {
        this.setState({
          expenseTypeId: "",
          selectedExpenseName: "",
          expenseForm: [],
          selectedExpenseTypeId: "",
          isEdit: false
        });
        $(".close").click();
        toast.success(response.message);
        this.getAllTravelDetails();
      }
    } else {
      this.expenseReportValidate.showMessages();
      this.forceUpdate();
    }
  };

  onDeleteExpenseHandler = async () => {
    let response = await expenseReport.deleteTravelExpenseEntry(
      this.state.travelExpenseId,
      this.state.selectedExpenseDelete
    );
    if (response) {
      $(".close").click();
      toast.success(response.message);
      this.setState({ selectedExpenseDelete: "" });
      this.getAllTravelDetails();
    }
  };
  onExpenseDeleteCancelHandler = () => {
    this.setState({ selectedExpenseDelete: "" });
  };
  onDeleteExpenseSelectHandler = list => {
    this.setState({ selectedExpenseDelete: list._id });
  };
  onAddExpenseClickHandler = () => {
    this.setState({
      expenseTypeId: "",
      selectedExpenseName: "",
      expenseForm: [],
      selectedExpenseTypeId: "",
      isEdit: false
    });
  };
  handleEnableCashAdvnaceToggle = () => {
    this.setState({
      enableCashAdvnaceToggle: !this.state.enableCashAdvnaceToggle
    });
  };
  updatePerDetails = async (e, list) => {
    let payload = {
      TRIP_ID: this.state.tripId,
      limitType: e.value,
      location: list.location
    }
    let resp = await updatePerDiemDetails(payload, list._id);
    if (resp) {
      list.baseCurrencyAmount = getValue(resp, "result.perDiem.baseCurrencyAmount");
      list.actualCurrencyAmount = getValue(resp, "result.perDiem.actualCurrencyAmount");
      list.limitType = getValue(resp, "result.perDiem.limitType");
      this.setState({
        totalBaseCurrencyAmount: getValue(resp, "result.totalPerdiemAmount")
      });
      let listItem = this.state.getAllTravelDetailsData.filter(list => {
        if (list.isFlight === list.isHotel && list.limitType === 0) {
          return list;
        }
      });
      (listItem.length === 0) ? this.setState({ isRequired: true }) : this.setState({ isRequired: false });
    }
  }

  handleScroll = async (e, id, assignKey, page, count, totalLength, search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    setValue(this.state, "clientHeight", e.target.scrollHeight)
    if (bottom) {
      this.setState({
        already: true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
      })
      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {
          let mergeOld = getValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", []).concat(resp.result)
          setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", mergeOld)
          setValue(this.state.expenseForm[assignKey], "page", page + 1)
          this.setState({
            ...this.state
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }
    }
  }
  handleSearchManage = async (search, id, assignKey) => {



    this.state.Loading[assignKey] = true;
    this.state.search[assignKey] = search;
    this.setState({ search: this.state.search }, () => {
      $(`#valueConnect${assignKey}`).focus();
    })

    let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
    if (resp) {
      setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", resp.result)
    } else {
      setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", [])
    }
    setValue(this.state.expenseForm[assignKey], "search", search)
    this.state.Loading[assignKey] = false;
    this.setState({
      ...this.state
    })
    $(`#valueConnect${assignKey}`).focus();
    $(`#valueConnect${assignKey}`).attr('value', search);
    $(`#connect${assignKey}`).addClass("show")


  }

  handleScroll1 = async (e, id, assignKey, page, count, totalLength, search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    let findIndex = assignKey.split("-")
    setValue(this.state, "clientHeight", e.target.scrollHeight)
    if (bottom && !this.state.already) {
      this.setState({
        already: true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
      })
      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {
          let mergeOld = getValue(this.state.cashAdvanceForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", []).concat(resp.result)
          setValue(this.state.cashAdvanceForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", mergeOld)
          setValue(this.state.cashAdvanceForm[assignKey], "page", page + 1)
          this.setState({
            already: false
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }
    }
  }
  handleSearchManage1 = async (search, id, assignKey) => {
    this.state.Loading[assignKey] = true;
    this.state.search[assignKey] = search;
    this.setState({ search: this.state.search }, () => {
      $(`#valueConnect${assignKey}`).focus();
    })
    let findIndex = assignKey.split("-")
    let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)

    if (resp) {
      setValue(this.state.cashAdvanceForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", resp.result)
    } else {
      setValue(this.state.cashAdvanceForm[findIndex[0]][findIndex[1]], "connectedListData.listManagementData", [])
    }
    setValue(this.state.cashAdvanceForm[assignKey], "search", search)
    this.state.Loading[assignKey] = false;
    this.setState({
      ...this.state
    })
    $(`#valueConnect${assignKey}`).focus();
    $(`#valueConnect${assignKey}`).attr('value', search);
    $(`#connect${assignKey}`).addClass("show")

  }

  render() {
    return (
      <div>
        {!this.state.isLoading && (
          <MenuCartSubPage
            baseCurrency={this.state.baseCurrency}
            loading={this.state.loading}
            submitFinal={this.submitFinal}
            allowToExpenseTab={true}
            submit={true}
            pathName={this.props.location.pathname}
            cartList={this.state.cartDetails}
            isRequired={this.state.isRequired}
          />
        )}
        <div className="p-padding top-48">
          {this.state.isLoading ? (
            <div className="text-center p-5 m-5">
              <div className="mt-5 form-holder pt-3">
                <div className="loader-css" />
                <h5 className="fw-sbold pb-5">Please wait...</h5>
              </div>
            </div>
          ) : (
              <div className="main-box">
                <div className="bg-light heading">
                  <h6>Expense</h6>
                </div>
                <div className="p-padding expense-page">
                  <div className="row">
                    <div className="col-md-9">
                      {/* {(this.state.overAllTotal > 0 || localStorage.getItem("440a28") === "5e67a0715d8d0b25d245fae1") && ( */}
                      {this.state.overAllTotal > 0 && (

                        <div className="form-group mt-4 mb-5 add-border">
                          <label className="client">Per Diem</label>
                          <div className="table-container pt-4 mx-0 even-pad">
                            <div className="table-responsive">
                              <table className="table table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th>Journey</th>
                                    <th>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p>
                                        {
                                          this.state.expenseDetails
                                            .noOfDaysJourney
                                        }{" "}
                                        Days
                                    </p>
                                      {this.state.personalDayOffTO !== "" &&
                                        this.state.personalDayOffTO !==
                                        undefined && (
                                          <h6 className="sub-heading">
                                            Personal Day{" "}
                                            <span>
                                              {this.state.personalDayOffFrom ===
                                                this.state.personalDayOffTO ? (
                                                  <span>
                                                    ({this.state.personalDayOffTO})
                                                  </span>
                                                ) : (
                                                  <span>
                                                    ({this.state.personalDayOffFrom}{" "}
                                                    to {this.state.personalDayOffTO}
                                                    )
                                                  </span>
                                                )}
                                            </span>
                                          </h6>
                                        )}
                                    </td>
                                    <td>
                                      {this.state.baseCurrency}&nbsp;
                                    {new Intl.NumberFormat("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      }).format(
                                        this.state.totalBaseCurrencyAmount
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div id="demo" className="collapse fade-in">
                              <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                  <thead>
                                    <tr>
                                      <th>Expense Type</th>
                                      <th>Rate Type</th>
                                      <th>Location</th>
                                      <th>Date</th>
                                      <th>Amount</th>
                                      <th>Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.expenseDetails.perDiemDetails &&
                                      this.state.expenseDetails.perDiemDetails.map(
                                        (list, key) => {
                                          let items = [
                                            // {value:0,name:"Standard Rate",status:true},
                                            { value: 0, name: "Standard Rate", status: true },
                                            { value: 1, name: "Guest House Rate", status: getValue(this.state, "guestActive") },
                                            { value: 2, name: "Stay At Friends Rate", status: getValue(this.state, "personalActive") },
                                          ]
                                          let placeholder = items.filter(resp => resp.value === list.limitType)
                                          return (
                                            <tr key={key}>
                                              <td>{list.expenseType}</td>
                                              <td>
                                                {/* {((!list.isHotel && !list.isFlight) || (!list.isHotel && !list.isFlight && !getValue(this.state, "guestActive") && getValue(this.state, "guestActive"))) ? */}
                                                {(!list.isHotel) ?
                                                  <Select
                                                    options={items.filter(resp => resp.status === true)}
                                                    keyName="name"
                                                    controlFunc={e => this.updatePerDetails(e, list)}
                                                    placeholder={getValue(placeholder, "[0].name", "Select Rate Type")}
                                                  /> : getValue(placeholder, "[0].name")}
                                              </td>
                                              <td>{list.location}</td>
                                              <td>
                                                <Moment format="DD MMM YYYY">
                                                  {this.convertDate(list.date)}
                                                </Moment>
                                              </td>
                                              <td>
                                                {list.actualCurrencyType}{" "}
                                                {new Intl.NumberFormat("en-IN", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2
                                                }).format(
                                                  list.actualCurrencyAmount
                                                )}
                                              </td>
                                              <td>
                                                {list.baseCurrencyType}{" "}
                                                {new Intl.NumberFormat("en-IN", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2
                                                }).format(
                                                  list.baseCurrencyAmount
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* <button
                              type="button"
                              className="btn btn-nothing bg-light form-control collapsed stay-error text-primary btn-no-radius show-more-btn"
                              data-toggle="collapse"
                              data-target="#demo"
                            >
                              <span className="error-span">
                                <i className="material-icons fs-14 mx-2">
                            info</i><span className="update">Update Stay Type</span></span>
                            </button> */}
                            {!this.state.isRequired ?
                              <button
                                type="button"
                                className="btn btn-nothing bg-light form-control stay-error  collapsed text-primary btn-no-radius show-more-btn"
                                data-toggle="collapse"
                                data-target="#demo"
                              >
                                <span className="error-span">
                                  <i className="material-icons fs-14 mx-2">
                                    info</i><span className="update">Update Stay Type</span>
                                </span>
                                <span className="less">Show Less</span>
                              </button> :
                              <button
                                type="button"
                                className="btn btn-nothing bg-light form-control collapsed text-primary btn-no-radius show-more-btn"
                                data-toggle="collapse"
                                data-target="#demo"
                              >
                                <span className="more">Show More</span>

                                <span className="less">Show Less</span>
                              </button>}
                          </div>
                        </div>
                      )
                      }
                      {this.state.isExpense && (
                        <div className="form-group mt-4 mb-5 add-border">
                          <label className="client">Additional Expense</label>
                          <div className="table-container pt-4 mx-0 even-pad">
                            {this.state.additionalExpense.length > 0 && (
                              <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                  <thead>
                                    <tr>
                                      <th>Expense Type</th>
                                      {this.state.additionalExpense.length &&
                                        this.state.additionalExpense[0].fieldData.map(
                                          (list, index) => {
                                            return (
                                              <th key={index}>
                                                {list.labelName}
                                              </th>
                                            );
                                          }
                                        )}
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.additionalExpense.length &&
                                      this.state.additionalExpense.map(
                                        (list, index) => (

                                          <tr key={list._id}>
                                            <td>
                                              {this.state.allExpenseTypes.map(
                                                expenseTypes => {
                                                  if (
                                                    expenseTypes.expenseTypeId
                                                      .id === list.expenseTypeId
                                                  )
                                                    return expenseTypes
                                                      .expenseTypeId
                                                      .expenseTypeName;
                                                }
                                              )}
                                            </td>
                                            {list.fieldData.map(fieldData => {
                                              return fieldData.dataType !==
                                                "date" ? (
                                                  <td key={fieldData.id}>
                                                    {fieldData.fieldItemName ? fieldData.fieldItemName : fieldData.fieldValue}
                                                  </td>
                                                ) : (
                                                  <td key={fieldData.id}>
                                                    {fieldData.fieldItemName
                                                      ? fieldData.fieldItemName
                                                      : fieldData.fieldValue ? fieldData.fieldValue : ""}
                                                  </td>
                                                );
                                            })}
                                            {this.state.additionalExpense.length >
                                              0 && (
                                                <td>
                                                  <div className="btn-group-margin">
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        this.onEditExpenseClickHandler(
                                                          list
                                                        )
                                                      }
                                                      data-toggle="modal"
                                                      data-target="#addExpenseModal"
                                                      className="btn"
                                                    >
                                                      Edit
                                                </button>
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        this.onDeleteExpenseSelectHandler(
                                                          list
                                                        )
                                                      }
                                                      data-toggle="modal"
                                                      data-target="#deleteExpense"
                                                      className="btn"
                                                    >
                                                      Delete
                                                </button>
                                                  </div>
                                                </td>
                                              )}
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            )}
                            <button
                              type="button"
                              className="btn mx-3 my-3"
                              data-toggle="modal"
                              data-target="#addExpenseModal"
                              onClick={this.onAddExpenseClickHandler}
                            >
                              Add Expense
                          </button>
                          </div>
                        </div>
                      )}
                      {this.state.isCashAdvance && (
                        <div className="form-group mt-4 mb-5 add-border">
                          <label className="client">Cash Advance</label>
                          <div className="mx-3 my-4 mb-0">
                            <label class="switch">
                              <input
                                type="checkbox"
                                checked={this.state.enableCashAdvnaceToggle}
                                onChange={this.handleEnableCashAdvnaceToggle}
                              />
                              <span class="slider round" />
                            </label>
                            <span>Cash Advance</span>
                          </div>
                          {/* add class "d-none" to below div */}
                          {this.state.enableCashAdvnaceToggle && (
                            <div className="table-container min-height-less mx-0 even-pad">
                              <form>
                                <div className="form-group custom mb-0 mr-3 d-inline-block align-middle">
                                  <DayPickerInput
                                    format="DD MMM YYYY"
                                    formatDate={formatDate}
                                    dayPickerProps={{
                                      locale: "en-gb",
                                      localeUtils: MomentLocaleUtils,
                                      disabledDays: {
                                        before: new Date(),
                                        after: new Date(this.state.startTripDate)
                                      }
                                    }}
                                    placeholder={
                                      this.state.cashAdvanceRequestedDate
                                        ? this.state.cashAdvanceRequestedDate
                                        : "Date of Issue"
                                    }
                                    onDayChange={this.onCashAdvDateChangeHanler}
                                  />
                                  <span className="abs">
                                    <i className="material-icons">
                                      calendar_today
                                  </i>
                                  </span>
                                  <div className="text-danger fs-12">
                                    {this.cashAdvanceValidator.message(
                                      "Date of Issue",
                                      this.state.cashAdvanceRequestedDate,
                                      "required"
                                    )}
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  onClick={this.onAddClickHandler}
                                  className="btn mr-3"
                                >
                                  Add
                              </button>
                                {this.state.editCashAdvance.length <= 0 ? (
                                  <button
                                    type="button"
                                    disabled={
                                      this.state.cashAdvanceForm.length > 1 &&
                                        this.state.selectedIndex.length > 1
                                        ? !(
                                          this.state.selectedIndex.length <
                                          this.state.cashAdvanceForm.length
                                        )
                                        : this.state.selectedIndex.length <= 0 ||
                                        !(
                                          this.state.selectedIndex.length <
                                          this.state.cashAdvanceForm.length
                                        )
                                    }
                                    onClick={this.onDeleteAllocationField}
                                    className="btn"
                                  >
                                    Delete
                                  </button>
                                ) : (
                                    <button
                                      type="button"
                                      disabled={
                                        this.state.editCashAdvance.length > 1 &&
                                          this.state.selectedIndex.length > 1
                                          ? !(
                                            this.state.selectedIndex.length <
                                            this.state.editCashAdvance.length
                                          )
                                          : this.state.selectedIndex.length <= 0 ||
                                          !(
                                            this.state.selectedIndex.length <
                                            this.state.editCashAdvance.length
                                          )
                                      }
                                      onClick={this.onDeleteAllocationField}
                                      className="btn"
                                    >
                                      Delete
                                    </button>
                                  )}
                              </form>
                              <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                  <thead>
                                    <tr>
                                      <th width="5%" />
                                      <th />
                                    </tr>
                                  </thead>
                                  {this.state.editCashAdvance.length <= 0 ? (
                                    <tbody>
                                      {this.state.cashAdvanceForm &&
                                        this.state.cashAdvanceForm.map(
                                          (list, index) => (
                                            <tr key={index}>
                                              <td>
                                                <label className="checkbox-container">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      this.state.selectedIndex.includes(
                                                        index
                                                      )
                                                        ? this.state.selectedIndex.includes(
                                                          index
                                                        )
                                                        : false
                                                    }
                                                    onChange={e =>
                                                      this.onSelectedAllocationFieldHandler(
                                                        index
                                                      )
                                                    }
                                                  />
                                                  <span className="checkmark" />
                                                </label>
                                              </td>
                                              <td className="px-4">
                                                <div className="row">
                                                  <CashAdvanceDynamicForm
                                                    {...this.state}
                                                    rowIndex={index}
                                                    selectedIndex={
                                                      this.state.selectedIndex
                                                    }
                                                    validator={
                                                      this.cashAdvanceValidator
                                                    }
                                                    onClickHandler={
                                                      this.onCashClickHandler
                                                    }
                                                    onChangeHandler={
                                                      this.onChangeHandler
                                                    }
                                                    onDatePickerHandler={
                                                      this.onDatePickerHandler
                                                    }
                                                    onCheckboxHandler={
                                                      this.onCheckboxHandler
                                                    }
                                                    onIntegerHandler={
                                                      this.onIntegerHandler
                                                    }
                                                    allocationForm={list}
                                                    onSelectedAllocationFieldHandler={
                                                      this
                                                        .onSelectedAllocationFieldHandler
                                                    }
                                                    tableHead={
                                                      this.state.tableHead
                                                    }
                                                    userPermission={
                                                      this.state.userPermission
                                                    }
                                                    onConnectedListkHandler={
                                                      this
                                                        .onCashAdvConnectedListkHandler
                                                    }
                                                    onAmountPercentageChangeHandler={
                                                      this
                                                        .onAmountPercentageChangeHandler
                                                    }
                                                    onLocationChangekHandler={
                                                      this
                                                        .onLocationChangekHandler
                                                    }
                                                    loadOptions={this.loadOptions}
                                                    handleScroll={this.handleScroll1}
                                                    handleSearchManage={this.handleSearchManage1}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  ) : (
                                      <tbody>
                                        {this.state.editCashAdvance &&
                                          this.state.editCashAdvance.map(
                                            (list, index) => (
                                              <tr key={index}>
                                                <td>
                                                  <label className="checkbox-container">
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        this.state.selectedIndex.includes(
                                                          index
                                                        )
                                                          ? this.state.selectedIndex.includes(
                                                            index
                                                          )
                                                          : false
                                                      }
                                                      onChange={e =>
                                                        this.onSelectedAllocationFieldHandler(
                                                          index
                                                        )
                                                      }
                                                    />
                                                    <span className="checkmark" />
                                                  </label>
                                                </td>
                                                <td className="px-4">
                                                  <div className="row">
                                                    <CashAdvanceDynamicForm
                                                      {...this.state}
                                                      rowIndex={index}
                                                      selectedIndex={
                                                        this.state.selectedIndex
                                                      }
                                                      validator={
                                                        this.cashAdvanceValidator
                                                      }
                                                      onClickHandler={
                                                        this.onCashClickHandler
                                                      }
                                                      onChangeHandler={
                                                        this.onChangeHandler
                                                      }
                                                      onDatePickerHandler={
                                                        this.onDatePickerHandler
                                                      }
                                                      onCheckboxHandler={
                                                        this.onCheckboxHandler
                                                      }
                                                      onIntegerHandler={
                                                        this.onIntegerHandler
                                                      }
                                                      allocationForm={list}
                                                      onSelectedAllocationFieldHandler={
                                                        this
                                                          .onSelectedAllocationFieldHandler
                                                      }
                                                      tableHead={
                                                        this.state.tableHead
                                                      }
                                                      userPermission={
                                                        this.state.userPermission
                                                      }
                                                      onConnectedListkHandler={
                                                        this
                                                          .onCashAdvConnectedListkHandler
                                                      }
                                                      onAmountPercentageChangeHandler={
                                                        this
                                                          .onAmountPercentageChangeHandler
                                                      }
                                                      onLocationChangekHandler={
                                                        this
                                                          .onLocationChangekHandler
                                                      }
                                                      loadOptions={this.loadOptions}
                                                      handleScroll={this.handleScroll1}
                                                      handleSearchManage={this.handleSearchManage1}
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </tbody>
                                    )}
                                </table>
                                {this.state.isCashAdvanceEdit ? (
                                  <button
                                    className="btn m-3"
                                    onClick={this.onCashAdvanceSubmitHandler}
                                  >
                                    Update Cash Advance
                                  </button>
                                ) : (
                                    <button
                                      className="btn m-3"
                                      onClick={this.onCashAdvanceSubmitHandler}
                                    >
                                      Save Cash Advance
                                    </button>
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-md-3">
                      <div className="box-shadow">
                        <div className="box-header-item">
                          {/* <h6 className=" side-title">Expense Details</h6> */}
                          <h6 className=" side-title">Estimated Trip Cost</h6>
                        </div>
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th>Currency</th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="bg-light">
                                <td className="">Itinerary Amount</td>
                                <td className=" text-right">
                                  {this.state.baseCurrency}{" "}
                                  {new Intl.NumberFormat("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  }).format(
                                    this.state.totalItineraryAmount
                                      ? this.state.totalItineraryAmount
                                      : 0
                                  )}
                                </td>
                              </tr>
                              <tr className="bg-light">
                                <td className="">Per Diem</td>
                                <td className=" text-right">
                                  {this.state.baseCurrency}{" "}
                                  {new Intl.NumberFormat("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  }).format(
                                    this.state.totalBaseCurrencyAmount
                                      ? this.state.totalBaseCurrencyAmount
                                      : 0
                                  )}
                                </td>
                              </tr>
                              {this.state.isExpense ?
                                <tr className="bg-light">
                                  <td className="">Business Expense</td>
                                  <td className=" text-right">
                                    {this.state.baseCurrency}{" "}
                                    {new Intl.NumberFormat("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }).format(
                                      this.state.totalBusinessExpense
                                        ? this.state.totalBusinessExpense
                                        : 0
                                    )}
                                  </td>
                                </tr> : ""}
                              {this.state.isCashAdvance ?
                                <tr className="bg-light">
                                  <td className="">Cash Advance Amount</td>
                                  <td className=" text-right">
                                    {this.state.baseCurrency}{" "}
                                    {new Intl.NumberFormat("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }).format(
                                      this.state.totalCashAdvanceAmount
                                        ? this.state.totalCashAdvanceAmount
                                        : 0
                                    )}
                                  </td>
                                </tr> : ""}
                            </tbody>
                            <tfoot>
                              <tr className="bg-primary text-white">
                                <td className="">TOTAL </td>
                                <td className=" text-right">
                                  {this.state.baseCurrency}{" "}
                                  {+this.state.totalBaseCurrencyAmount +
                                    +this.state.totalBusinessExpense +
                                    +this.state.totalItineraryAmount >
                                    0
                                    ? new Intl.NumberFormat("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }).format(
                                      this.state.totalCashAdvanceAmount +
                                      +this.state.totalBaseCurrencyAmount +
                                      +this.state.totalBusinessExpense +
                                      +this.state.totalItineraryAmount
                                    )
                                    : 0}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>

        <div
          className="modal fade"
          id="addExpenseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addExpenseLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="addExpenseLabel">
                  Add Expense
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom labelup">
                  <label>Expense Type</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.state.selectedExpenseName
                        ? this.state.selectedExpenseName
                        : "Select Expense"}
                    </button>
                    <div className="dropdown-menu">
                      {this.state.allExpenseTypes &&
                        this.state.allExpenseTypes.map((opt, index) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                this.onSelectExpenseType(
                                  opt.expenseTypeId,
                                  opt.expenseEntryFormId
                                )
                              }
                              className="dropdown-item"
                              key={index}
                            >
                              {opt.expenseTypeId &&
                                opt.expenseTypeId.expenseTypeName &&
                                opt.expenseTypeId.expenseTypeName}
                            </button>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <ExpenseCreateForm
                      {...this.state}
                      pageName={this.state.pageName}
                      validator={this.expenseReportValidate}
                      policyForm={this.state.expenseForm}
                      onClickHandler={this.onClickHandler}
                      onClickHandler1={this.onClickHandler1}
                      onChangeHandler={this.onChangeHandler}
                      onDatePickerHandler={this.onDatePickerHandler}
                      onCheckboxHandler={this.onCheckboxHandler}
                      onIntegerHandler={this.onIntegerHandler}
                      userPermission={this.state.userPermission}
                      onConnectedListkHandler={this.onConnectedListkHandler}
                      onConnectedListkHandler1={this.onConnectedListkHandler1}
                      onLocationChangekHandler={this.onLocationChangekHandler}
                      loadOptions={this.loadOptions}
                      endTripDate={this.state.endTripDate}
                      handleScroll={this.handleScroll}
                      handleSearchManage={this.handleSearchManage}
                    />
                  </div>
                </div>
                {!this.state.isLoading ? (
                  <div className="modal-last btn-group-margin">
                    {!this.state.isEdit ? (
                      <button
                        disabled={this.state.expenseForm.length <= 0}
                        type="button"
                        onClick={this.onExpenseSubmitHandler}
                        className="btn"
                      >
                        Add expense
                      </button>
                    ) : (
                        <button
                          disabled={this.state.expenseForm.length <= 0}
                          type="button"
                          onClick={this.onUpdateExpenseHandler}
                          className="btn"
                        >
                          Update expense
                        </button>
                      )}
                    <button
                      type="button"
                      className="btn btn-badge close"
                      data-dismiss="modal"
                      onClick={this.onClickCloseModal}
                    >
                      Cancel{" "}
                    </button>
                  </div>
                ) : (
                    <div className="modal-last btn-group-margin">
                      <button
                        type="button"
                        className="btn btn-badge"
                        data-dismiss="modal"
                      >
                        Please Wait...
                    </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="editCashModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editCashLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="editCashLabel">
                  Edit Cash
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom labelup">
                  <label>Mode</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-border-basic dropdown-toggle btn-block bg-light"
                      data-toggle="dropdown"
                    >
                      Cash
                    </button>
                    <div className="dropdown-menu">
                      <button className="dropdown-item">1</button>
                      <button className="dropdown-item">2</button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom">
                  <input
                    type="text"
                    placeholder="&nbsp;"
                    className="form-control"
                  />
                  <label>Date</label>
                  <span className="icon-custom">
                    <i className="material-icons">calendar_today</i>
                  </span>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 px-0">
                      <div className="form-group custom labelup">
                        <label>Currency</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn btn-border-basic dropdown-toggle btn-block"
                            data-toggle="dropdown"
                          >
                            USD
                          </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">1</button>
                            <button className="dropdown-item">2</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0">
                      <div className="form-group custom">
                        <input
                          type="text"
                          placeholder="&nbsp;"
                          className="form-control"
                        />
                        <label>Amount</label>
                      </div>
                    </div>
                    <div className="col-md-1 px-0 pr-0">
                      <p className="text-center pt-2">or</p>
                    </div>
                    <div className="col-md-4 px-0 pr-0">
                      <div className="form-group custom">
                        <input
                          type="text"
                          placeholder="&nbsp;"
                          className="form-control"
                        />
                        <label>Percentage</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group custom textarea">
                  <textarea
                    data-gramm_editor="false"
                    spellCheck="false"
                    rows="2"
                    className="form-control"
                    placeholder="&nbsp;"
                  />
                  <label>Comment</label>
                </div>
                <div className="form-group">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round" />
                  </label>
                  <span>Required</span>
                </div>
                <div className="modal-last btn-group-margin">
                  <button type="button" className="btn">
                    Add expense
                  </button>
                  <button
                    type="button"
                    className="btn btn-badge"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="removeCashModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="removeCashLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="removeCashLabel">
                  Remove Cash
                </h5>
              </div>
              <div className="modal-middle">
                <div className="row total-amount-info border py-3 modal-px-30">
                  <div className="col-md-4">
                    <p className="font-weight-bold">Total Amount</p>
                    <p>USD 5,000.00</p>
                  </div>
                  <div className="col-md-4">
                    <p className="font-weight-bold">Unassigned Amount</p>
                    <p>USD 2,000.00</p>
                  </div>
                  <div className="col-md-4">
                    <p className="font-weight-bold">Percentage</p>
                    <p>40%</p>
                  </div>
                </div>
                <div className="py-3 border-bottom modal-px-30">
                  <p className="mb-4 font-weight-bold lighter-p">
                    Corporate Card
                  </p>
                  <div className="row">
                    <div className="col-md-3 pr-0">
                      <div className="form-group custom labelup mb-1">
                        <label>Currency</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn btn-border-basic dropdown-toggle btn-block"
                            data-toggle="dropdown"
                          >
                            USD
                          </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">1</button>
                            <button className="dropdown-item">2</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0">
                      <div className="  form-group custom labelup mb-1">
                        <input
                          type="text"
                          placeholder="2,000.00"
                          className="form-control"
                        />
                        <label>Amount</label>
                      </div>
                    </div>
                    <div className="col-md-1 px-0 pr-0">
                      <p className="text-center pt-2">or</p>
                    </div>
                    <div className="col-md-4 px-0 pr-3">
                      <div className="form-group custom labelup mb-1">
                        <input
                          type="text"
                          placeholder="40%"
                          className="form-control"
                        />
                        <label>Percentage</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-3 border-bottom modal-px-30">
                  <p className="mb-4 font-weight-bold lighter-p">
                    Corporate Card
                  </p>
                  <div className="row">
                    <div className="col-md-3 pr-0">
                      <div className="form-group custom labelup mb-1">
                        <label>Currency</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn btn-border-basic dropdown-toggle btn-block"
                            data-toggle="dropdown"
                          >
                            USD
                          </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">1</button>
                            <button className="dropdown-item">2</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0">
                      <div className="form-group custom labelup mb-1">
                        <input
                          type="text"
                          placeholder="1,000.00"
                          className="form-control"
                        />
                        <label>Amount</label>
                      </div>
                    </div>
                    <div className="col-md-1 px-0 pr-0">
                      <p className="text-center pt-2">or</p>
                    </div>
                    <div className="col-md-4 px-0 pr-3">
                      <div className="form-group custom labelup mb-1">
                        <input
                          type="text"
                          placeholder="20%"
                          className="form-control"
                        />
                        <label>Percentage</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-last btn-group-margin pm-30 ">
                <button type="button" className="btn">
                  Add expense
                </button>
                <button
                  type="button"
                  className="btn btn-badge"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="deleteExpense"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="submitModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close text-white d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="text-center pt-5">
                  <img src="/assets/images/confirmation_icon.svg" alt="" />
                  <h3 className=" my-4 text-dark">
                    Are you sure you want to delete this expense?
                  </h3>
                  {/* <p>By clicking on submit, you are approving this request.</p> */}
                </div>
                <div className="btn-group-margin text-center mb-4">
                  <button
                    type="button"
                    onClick={this.onExpenseDeleteCancelHandler}
                    className="btn"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button onClick={this.onDeleteExpenseHandler} className="btn">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ExpensePage;
