import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "../../../page-not-found/page-not-found-html";
import {
  ExpensePolicyPage,
  PolicyStep1Page,
  PolicyStep2Page,
  EditPolicyStep1Page,
  EditPolicyStep2Page,
  ReimbursementPage,
  DelegatePage,
  ConfigurationPage,
  EmployeeRelatedConfigPage,
  HourlyAllowancePage,
  ExpenseTypesMainPage,
  RatesPage,
  ConfigurationSetsPage,
  ItinerarySearchPage,
  CalculationPropsPage,
  ConfigurationStep1Page,
  RatesStep1Page,
  TaxAuthoritiesPage,
  TaxReclaimGroupsPage,
  EmployeeRelateConfigPage,
  TaxAuthorityStep1Page,
  TaxAuthorityStep2Page,
  TaxAuthorityStep3Page,
  AddEmployeeRelateConfigPage,
  PaymentTypesPage,
  AddpaymentTypesPage,
  GroupConfigPage
} from "./";
import CarConfigMainPage from "./car-config/car-config-main"
import TaxConfigMainPage from "./tax-config/tax-config-main"
import CashAdvanceConfigHomePage from "./cash-advance-config/cash-advance-config";
import AccountAdminHomePage from "./accounting-admin/account-admin";
import { inject } from "mobx-react";  
@inject('menuStore')
class ExpenseHomePage extends Component {
  constructor(props) {
    super(props);
    this.props.menuStore.menuItems && this.props.menuStore.menuItems.forEach(list => {
      if (this.props.menuStore.parentId === list.id) { 
          if (props.location.pathname === list.children[0].frontEndUrl) { 
              this.props.history.push(list.children[0].children[0].frontEndUrl)
          } else {
              this.props.history.push(props.location.pathname)
          }
      }
    })
  }
  componentDidUpdate() {
    // if (this.props.location.pathname === "/administration/expense") {
    //   this.props.history.push("/administration/expense/expense-policies");
    // }
  }
  render() {
    return (
      <div className="ExpenseHomePage">
        <Switch>
          <Route
            path="/administration/expense/expense-policies"
            component={ExpensePolicyPage}
          />
          <Route
            path="/administration/expense/policy-step1"
            component={PolicyStep1Page}
          />
          <Route
            path="/administration/expense/policy-step2"
            component={PolicyStep2Page}
          />
          <Route
            path="/administration/expense/car-config"
            component={CarConfigMainPage}
          />
          <Route
            path="/administration/expense/tax-config"
            component={TaxConfigMainPage}
          />
          <Route
            path="/administration/expense/ex"
            component={ExpenseTypesMainPage}
          />
          <Route
            path={`/administration/expense/edit-policy-step1/:id`}
            component={EditPolicyStep1Page}
          />
          <Route
            path="/administration/expense/edit-policy-step2"
            component={EditPolicyStep2Page}
          />
          <Route
            path="/administration/expense/cash-advance-config"
            component={CashAdvanceConfigHomePage}
          />
          <Route
            path="/administration/expense/account-admin"
            component={AccountAdminHomePage}
          />
          <Route
            path="/administration/expense/reimbursement"
            component={ReimbursementPage}
          />
          <Route
            path="/administration/expense/delegates"
            component={DelegatePage}
          />
          <Route
            path="/administration/expense/config"
            component={ConfigurationPage}
          />
          <Route
            path="/administration/expense/employees-config"
            component={EmployeeRelatedConfigPage}
          />
          <Route path="/administration/expense/rates" component={RatesPage} />
          <Route
            path="/administration/expense/config-sets"
            component={ConfigurationSetsPage}
          />
          <Route
            path="/administration/expense/hourly-allowance"
            component={HourlyAllowancePage}
          />
          <Route
            path="/administration/expense/itinerary-search"
            component={ItinerarySearchPage}
          />
          <Route
            path="/administration/expense/calculation-props"
            component={CalculationPropsPage}
          />
          <Route
            path="/administration/expense/config-step-1"
            component={ConfigurationStep1Page}
          />
          <Route
            path="/administration/expense/rates-step-1"
            component={RatesStep1Page}
          />
          <Route
            path="/administration/expense/tax-authority"
            component={TaxAuthoritiesPage}
          />
          <Route
            path="/administration/expense/tax-reclaim-group"
            component={TaxReclaimGroupsPage}
          />
          <Route
            path="/administration/expense/employee-relate-config"
            component={EmployeeRelateConfigPage}
          />
          <Route
            path="/administration/expense/tax-authority-step1"
            component={TaxAuthorityStep1Page}
          />
          <Route
            path="/administration/expense/tax-authority-step2"
            component={TaxAuthorityStep2Page}
          />
          <Route
            path="/administration/expense/tax-authority-step3"
            component={TaxAuthorityStep3Page}
          />
          <Route
            path="/administration/expense/add-employee-relate-config"
            component={AddEmployeeRelateConfigPage}
          />
          <Route
            path="/administration/expense/payment-types"
            component={PaymentTypesPage}
          />
          <Route
            exact
            path="/administration/expense/add-payment-types"
            component={AddpaymentTypesPage}
          />
          <Route
            exact
            path="/administration/expense/add-payment-types/:id"
            component={AddpaymentTypesPage}
          />
          <Route
            path="/administration/expense/group-config"
            component={GroupConfigPage}
          /> 
          <Route path="**" component={PageNotFound} />
        </Switch>
      </div>
    );
  }
}
export default ExpenseHomePage;
