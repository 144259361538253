import React, { Component } from "react";
// import AddUserPage from "./add-user-html";
import { toast } from "react-toastify";
import { companyService, masterService } from "src/_services";
import { expenseReport } from "src/_services/expenseReport";
import SimpleReactValidator from "simple-react-validator";
import { alertConstants } from "src/_constants/alert.constants";
import $ from "jquery";
import { string } from "prop-types";
import { Object } from "es6-shim";
import EditUserProfilePageTemp from "./edit-profile-temp-html";
import menuStore from "src/_store/menu.store";
import Header from 'src/_components/common/header';
import { inject, observer } from "mobx-react";
import * as mobx from 'mobx';
import _ from 'lodash';
import moment from 'moment';

// !! The difference between edit user management and this profil page is in thus page you call the userdetails first and then all the formdetails, so it had to be done after the setState of formHeaderId, serviceModule Id which I get from userdetail and then I am calling the formList, awaiting it and populating it.

//  !! This module should be rewritten from beginning with a very streamlined approach or it may lead to bugs in the future.


class EditUserProfileTemp extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      serviceModuleId: localStorage.getItem('serviceModuleId'),
      companyId: localStorage["440a28"],
      formHeaderId: localStorage.getItem('formHeaderId'),
      formId: "",
      formIdForGettingUsers: "",
      fullName: "",
      email: "",
      id: "",
      selectedImage: null,
      activeStatus: false,
      invite: false,
      isTestUser: false,
      fieldData: [],
      allUsers:[],
      approver: "",
      travel: {
        travelClass: "",
        travelWorkflow: "",
        travelAllowance: ""
      },
      expense: {
        cashAdvanceWorkflow: "5c77d189f88fd5361c70537a"
      },
      delegatedUser: {
        delegatedUserId: "",
        serviceModule: "",
        startDate: "",
        endDate: "",
        accessType: "",
        service: "",
      },
      isDelegateUserExists: "",
      allTravelClass: [],
      allTravelAllowance: [],
      allTravelWorkflow: [],
      selectedTravelClass: "",
      selectedTravelAllowance: "",
      selectedTravelWorkflow: "",
      isVerifiedInvitation: false,
      allDelegatedUsers: [],
      groupName: localStorage.getItem('groupName'),
      groupId: localStorage.getItem('groupId'),
      profileImage: "",
      userPermission: "employee-role",
      expenseForm: [],
      connectedListData: [],
      service: [],
      isLoading:true,
      serviceList: [],
    };
  }

  // !! Todo - while updating or creating add profile image to payload
  dummuyValue = [{ id: "0", value: "Dummy1" }, { id: "1", value: "Dummy2" }];
  isEdit = false;
  componentDidMount() {
    // this.getFormList();
    this.getServiceMode();
    // this.getUserList();
    // * something new
    // this.getTravelRuleClassPromise = this.getTravelRuleClass();
    this.getTravelAllowancePromise = this.getTravelAllowance();
    this.getTravelWorkflowPromise = this.getTravelWorkflow();
    this.isEdit = true;
    this.getUserDetails();
  }

  handleSelectedOption = (service) => {
    this.setState({ service: service })
  }
  openDelegate() {
    $("#delegate-btn").addClass("d-none");
    $(".delegate-switch").toggleClass("d-none");
  }
  getDynamicFormFields = async () => {
    // console.log('-----------------------------')
    let payload = {
      companyId: this.state.companyId,
      formHeaderId: this.state.formHeaderId,
      serviceModuleId: this.state.serviceModuleId,
      isFormGroup: true,
      groupId: this.state.groupId,

    }


    let response = await companyService.getFormData(payload);
    // console.log(response, '---------------');
    if (response && response.result) {
      this.setState({ expenseForm: response.result, formId: response.result[0].formId });
      let formFields = [];
      let fieldsObjects = [];
      let newConnectedListDate = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.result, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== '';
      });
      dynamicFeilds.forEach((fields) => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField ? fields.multiSelectParentField : '',
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel
        });
      })
      this.setState({ connectedListData: newConnectedListDate });
      response.result.forEach(formField => {
        let fieldName = formField.fieldName;
        if (formField.displayType === "date") {
          let todayDate = new Date();

          todayDate = moment(todayDate).format("DD/MM/YYYY");
          this.setState({ [fieldName]: todayDate });
          //  * If error happens with date try uncommenting the following line
          // this.setState({ [fieldName]: formField.fieldValue?formField.fieldValue:null });
        } else if (formField.displayType === "checkbox") {
          this.setState({ [fieldName]: false });
        } else {
          this.setState({ [fieldName]: "" });
        }
        formFields.push(formField.fieldName);
        fieldsObjects.push({ fieldId: formField.id, columnName: formField.columnName });
      });
      this.setState({ isLoading: false });
      this.setState({ formFields: formFields, fieldsObject: fieldsObjects });
    }
  };

  onSubmitHandler = async event => {
    if (this.validator.allValid()) {
      let fieldsObjects = this.state.fieldsObject;
      let index = 0;
      for (const key of this.state.formFields) {
        fieldsObjects[index]["fieldValue"] = this.state[key];
        index++;
      }
      // * request to be sent
      if (this.state.allDelegatedUsers.length > 0) {
        var req = {
          serviceModuleId: this.state.serviceModuleId,
          companyId: localStorage["440a28"],
          formHeaderId: this.state.formHeaderId,
          formId: this.state.formId,
          email: this.state.email,
          fieldData: fieldsObjects,
          invite: this.state.invite,
          isTestUser: this.state.isTestUser,
          travel: {
            travelClass: this.state.travel.travelClass,
            travelWorkflow: this.state.travel.travelWorkflow,
            travelAllowance: this.state.travel.travelAllowance
          },
          expense: {
            cashAdvanceWorkflow: "5c77d189f88fd5361c70537a"
          },
          delegatedUser: this.state.allDelegatedUsers
        };
      } else {
        var req = {
          serviceModuleId: this.state.serviceModuleId,
          companyId: localStorage["440a28"],
          formHeaderId: this.state.formHeaderId,
          formId: this.state.formId,
          email: this.state.email,
          fieldData: fieldsObjects,
          invite: this.state.invite,
          isTestUser: this.state.isTestUser,
          travel: {
            travelClass: this.state.travel.travelClass,
            travelWorkflow: this.state.travel.travelWorkflow,
            travelAllowance: this.state.travel.travelAllowance
          },
          expense: {
            cashAdvanceWorkflow: "5c77d189f88fd5361c70537a"
          },
        };
      }




      companyService.createUserCompany(req).then(data => {
        this.props.history.push("/administration/company/management");
        toast.success(alertConstants.CREATED_SUCCESSFULLY);
      });

      // * End of request to be sent


    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  onCancelClickHandler = () => {
    this.props.history.push("./details");
  };
  onClickHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter((listData) => listData.multiSelectParentField === conId && listData.fieldLevel === newFieldLevel);
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(companyId, fieldId);
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter((listData) => listData.multiSelectParentField === conId && listData.fieldLevel === newFieldLevel);
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(companyId, fieldId);
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onChangeHandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };
  onDatePickerHandler = (value, fieldName) => {
    if (!isNaN(value)) {
      this.setState({ [fieldName]: moment(new Date(value)).format("DD MMM YYYY") });
    }
  };
  onCheckboxHandler = fieldName => {
    this.setState({ [fieldName]: !this.state[fieldName] });
  };

  onIntegerHandler = (event, fieldName) => {
    if (!isNaN(event.target.value)) {
      this.setState({ [fieldName]: (event.target.value) });
    }
  };

  // * Get all the travel class rules
  // getTravelRuleClass = async () => {
  //   let res = await companyService.getTravelClass(this.state.companyId);
  //   if (res) {
  //     // console.log(res.result);

  //     this.setState({
  //       allTravelClass: res.result
  //     });

  //     return res.result;
  //   }
  // };

  // * End of travel class rules

  // * Get all travel allowance
  getTravelAllowance = async () => {
    let res = await companyService.getTravelAllowance(this.state.companyId);
    if (res) {
      // console.log(res);
      this.setState({
        allTravelAllowance: res.result
      });
      return res.result;
    } else {
      toast.info(alertConstants.TRAVEL_ALLOWANCE_NOT_FOUND);
    }
  };

  // * End of all travel allowance

  // * Get all travel Workflow

  getTravelWorkflow = async () => {
    let res = await companyService.getTravelWorkflow(this.state.companyId);
    if (res) {
      // console.log(res);
      this.setState({
        allTravelWorkflow: res.result
      });
      return res.result;
    }
  };
  // * End of travel workflow

  // * Has more is used for chekcing if more users exists ?
  hasMore = true;
  // For delegation stuff
  delegatedItem = [];
  delegatedView = [];

  // * for infinite scroll in userList
  fetchMoreData = () => {
    if (this.userList.length >= this.count) {
      this.hasMore = false;
      this.forceUpdate();
      return;
    }
    this.activePage = this.activePage + 1;
    this.getUserList();
  };



  // * get service Mode
  getServiceMode = async () => {
    var resp = await companyService.getCompanyService(this.state.companyId);
    if (resp) {
      // console.log(resp);
      this.setState({ serviceList: resp })
    }
  };
  userList = [];
  itemsPerPage = 10;
  activePage = 1;
  count = 0;
  search = "";

  // * For getting the list of all the users ?
  getUserList = async () => {

    // console.log("at least I am coming here");


    // * NEw code for getting UserList. Calling two APIs

    // * Code for getting form ID
    let formDetails = await companyService.getFormDetails(this.state.companyId);
    if (formDetails !== null && formDetails !== "") {
      // console.log("formDetails ", formDetails);
      this.setState({
        formIdForGettingUsers: formDetails.result[0]['id'],
      }, () => {

        var req = {
          companyId: localStorage["440a28"],
          formId: this.state.formIdForGettingUsers,
          sort: "asc",
          search:this.search,
          page:this.activePage,
          limit:this.itemsPerPage,
        };
        companyService.getListUser(req).then(
          data => {
            if (data !== null && data !== "") {
              this.count = data.count;

              this.userList = data.result.userData;
              this.setState({
                allUsers: this.userList
              });
            }
          }).catch('error');



      })

    }
    // * End of code getting form ID


    // End of new code for getting userList
    // var req = {
    //   pageType: "user-management",
    //   companyId: this.state.companyId,
    //   page: this.activePage,
    //   sort: "asc",
    //   // limit: this.itemsPerPage,
    //   limit: 100,
    //   search: this.search
    // };
    // var resp = await companyService.getListUser(req);
    // if (resp) {
    //   this.count = resp.count;
    //   // console.log(resp.result)
    //   // console.log("total userlist ", resp.result);
    //   if (this.userList.length > 0) {
    //     this.userList = this.userList.concat(resp.result.userData);
    //     this.userList = resp.result.userData;
    //     // console.log(this.userList);

    //   } else {
    //     this.userList = resp.result.userData;
    //     console.log(this.userList);

    //   }
    // } else {
    //   this.userList = [];
    // }
    // this.forceUpdate();
  };


  userDetail = [];
  //  * get userdetails for edit
  getUserDetails1 = async id => {

    this.userDetail = await companyService.getUserDetail(id);


    //  * code for checking if delegated user exists in userDetail
    if (this.userDetail && this.userDetail.delegatedUser.length > 0) {
      // console.log(this.userDetail.delegatedUser);
      // await this.getUserList();
      // console.log(this.userList);
      let allDelegatedUsers = [...this.userDetail.delegatedUser];



      // console.log("list of delegated users ", allDelegatedUsers);
      // * Match all delegated users from the userlist and add fullName to it
      let allDelegatedUsersWithName = allDelegatedUsers.map(dgu => {
        const user = this.userList.find(u => u.id == dgu.delegatedUserId);
        if (!user) {
          return dgu;
        }
        return {
          ...dgu,
          email: user.email
        }
      })




      this.setState(
        {
          delegatedUser: this.userDetail.delegatedUser[0],
          allDelegatedUsers: allDelegatedUsersWithName,
          isDelegateUserExists: true
        },
        async () => {

          this.forceUpdate();
        }
      );
    } else {
      this.setState({
        isDelegateUserExists: false
      });
    }
    // console.log('-------', this.state, '++++++++')
  };





  submitUpdateHandle = () => {
    if (this.validator.allValid()) {
      let fieldsObjects = this.state.fieldsObject;
      let index = 0;
      for (const key of this.state.formFields) {
        fieldsObjects[index]["fieldValue"] = this.state[key];
        index++;
      }




      // let tempFieldData = this.state.fieldData.map(item => {
      //   return {
      //     fieldId: item.fieldId,
      //     fieldValue: item.fieldValue
      //   };
      // });

      // let payload = {};



      // let payload = {
      //   fieldData: fieldsObjects,
      //   isTestUser: this.state.isTestUser,
      //   invite: this.state.invite,
      //   profileImage: this.state.profileImage
      // };
      let payload = {};

      // * if is verified Information is true, dont send invite
      if(this.state.isVerifiedInvitation){
        payload = {
          fieldData: fieldsObjects,
          isTestUser: this.state.isTestUser,
          // * for edit case dont send invite
          // invite: this.state.invite,
          isApprover: this.state.isApprover,
          profileImage: this.state.profileImage
        };
      }else{
        payload = {
          fieldData: fieldsObjects,
          isTestUser: this.state.isTestUser,
          invite: this.state.invite,
          isApprover: this.state.isApprover,
          profileImage: this.state.profileImage
        };
      }



      if (this.state.allDelegatedUsers.length > 0) {
        payload.delegatedUser = this.state.allDelegatedUsers;
        payload.isDelegateUserExists = this.state.isDelegateUserExists;

      }
      if (this.state.travel.travelClass != '' && this.state.travel.travelWorkflow != '' && this.state.travel.travelAllowance != '') {
        payload.travel = this.state.travel;

      }
      // console.log(fieldsObjects);
      // console.log(payload);
      companyService
        .updateUserCompany(this.state.id, payload)
        .then(data => {
          // this.props.history.push("/my-account/profile");
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };
  //  * for handling email
  staticHandle = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleStatusChange = (id, activeStatus) => {
    let newStatus = !activeStatus;
    let payload = {
      id: id,
      activeStatus: newStatus
    };
    companyService.activeInactiveUserManage(id, payload).then(
      data => {
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        this.setState({
          activeStatus: newStatus
        });
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  };

  // * Test user handler
  testUserHandler = status => {
    let newStatus = !status;
    this.setState({
      isTestUser: newStatus
    });
  };

  // * Invite Handler

  inviteHandler = status => {
    let newStatus = !status;
    this.setState({
      invite: newStatus
    });
  };

  // * travel selectors here
  handleTravelSelector = (e, type) => {
    if (type == "travelClass") {
      // console.log(e);
      this.setState(
        {
          selectedTravelClass: e.className,
          travel: {
            ...this.state.travel,
            travelClass: e.id
          }
        });
    }
    if (type == "travelAllowance") {
      this.setState({
        selectedTravelAllowance: e.configurationName,
        travel: {
          ...this.state.travel,
          travelAllowance: e.id
        }
      });
    }
    if (type == "travelWorkflow") {
      this.setState({
        selectedTravelWorkflow: e.workflowName,
        travel: {
          ...this.state.travel,
          travelWorkflow: e.id
        }
      });
    }
  };

  // * End of travel selectors
  // submit handler when adding a user


  handleChangeDelegate = e => {
    // this.state.delegatedUser[e.target.name] = e.target.value;
    this.setState({
      delegatedUser: {
        ...this.state.delegatedUser,
        [e.target.name]: e.target.value
      }
    });
    // this.setState({
    //   ...this.state
    // });
  };

  serviceName = "Service";
  accessTypeName = "Access Type";


  searchUser = e => {
    this.search = e.target.value;
    this.delegatedView = "";
    this.delegatedItem = "";
    if(this.search){
      this.getUserList();
    }else{
      this.userList = []
      this.setState({
        allUsers: this.userList
      });
    }
  };

  // * Choose Delegate Handler

  chooseDelegate = selectedUser => {
    //  * check if selected user is there in the delegated list
    let allDelegatedUsers = [...this.state.allDelegatedUsers];
    let selectedIndex = allDelegatedUsers.find(o => o.delegatedUserId == selectedUser.id);
    this.delegatedItem = selectedUser.id;
    //  * if the user is not there, add her
    if (selectedIndex == undefined) {
      let tempObj = {
        email: selectedUser.email,
        delegatedUserId: selectedUser.id,
        startDate: '',
        endDate: '',
        accessType: "0",
        serviceModule: []
      }
      allDelegatedUsers.unshift(tempObj);
      this.setState({
        allDelegatedUsers
      })

    } else {
      toast.warn("User already exists in delegated list");
    }


  };

  // * Remove Delegate Handler

  removeDelegateHandler = async (item) => {
    //  ` console.log("item to be removed ", item);
    //   console.log(this.isEdit);`

    if (this.isEdit && item.id) {
      let res = await companyService.removeDelegatedUser(item.id);
      if (res) {
        toast.success(alertConstants.DELETED_SUCCESSFULLY);
      }
    }
    let allDelegatedUsers = [...this.state.allDelegatedUsers];
    // * matching delegate Id and deleting
    let tempArray = allDelegatedUsers.filter(o => o.delegatedUserId !== item.delegatedUserId)
    this.setState({ allDelegatedUsers: tempArray })


  };

  // * End of Remove Delegate Handler

  // * Delegate fields Change handler
  changeData = (e, type, item) => {

    let allDelegatedUsers = [...this.state.allDelegatedUsers];
    // * match which delegated user details are changing
    let matcheduser = allDelegatedUsers.find(o => o.delegatedUserId == item.delegatedUserId);
    // * match which index delegated user details are changing
    let matchedIndex = allDelegatedUsers.findIndex(o => o.delegatedUserId == item.delegatedUserId);

    if (type === "service") {

      // * change the value
      let service = [];
      service = e.map((list) => list.value);
      matcheduser.serviceModule = service;
      // * Replace the old object with the new one
      allDelegatedUsers.splice(matchedIndex, 1, matcheduser);
      this.setState({
        allDelegatedUsers
      }, () => console.log(" after change ", this.state.allDelegatedUsers));


    } else if (type === "accessType") {
      let accessType = [];
      accessType = e.map((list) => list.value);
      matcheduser.accessType = accessType;
      allDelegatedUsers.splice(matchedIndex, 1, matcheduser);
      this.setState({
        allDelegatedUsers
      }, () => console.log(" after change ", this.state.allDelegatedUsers));

    }
  };

  dayChangeHandler = (day, type, item) => {

    let allDelegatedUsers = [...this.state.allDelegatedUsers];
    // * match which delegated user details are changing
    let matcheduser = allDelegatedUsers.find(o => o.delegatedUserId == item.delegatedUserId);
    // * match which index delegated user details are changing
    let matchedIndex = allDelegatedUsers.findIndex(o => o.delegatedUserId == item.delegatedUserId);
    // console.log(type);
    if (type == 'startDate') {
      matcheduser.startDate = day;
      allDelegatedUsers.splice(matchedIndex, 1, matcheduser);
      this.setState({
        allDelegatedUsers
      }, () => console.log(" after change ", this.state.allDelegatedUsers));

    } else if (type == 'endDate') {

      matcheduser.endDate = day;
      allDelegatedUsers.splice(matchedIndex, 1, matcheduser);
      this.setState({
        allDelegatedUsers
      }, () => console.log(" after change ", this.state.allDelegatedUsers));

    }

  }

  // * End of Fields Change handler

  // * Handle Image Change
  imageSelectedHandler = e => {
    // console.log(e.target.files);
    let selectedImage = e.target.files[0];
    const types = ['image/png', 'image/jpeg', 'image/gif'];

    // * extra checks for later

    // else if(selectedImage.size > 150000){
    //   alert('Image is too large, please pick a smaller file')
    // }
    // console.log(selectedImage, '--------------------');
    // * Check is the uploaded file is an image
    if (types.every(type => selectedImage.type !== type)) {
      alert(`${selectedImage.type} is not supported`);
    } else {
      this.setState({
        selectedImage
      }, async () => {
        let fd = new FormData();
        fd.append('file', selectedImage);


        let res = await companyService.uploadProfilePhoto(fd);
        if (res) {
          this.setState({ profileImage: res.result.profileImage });
        } else {
          // console.log(res);

        }
      })
    }


  }

  // * Editing the user code from rohit
  getUserDetails = async () => {
    let res = await companyService.getProfileDetails();

    if (res.result) {


      var response = res.result;

      //  * For travelRuleClass TravelAllowance, TravelWorkflow
      // * For handling travel settings
      const [
        tempTravelRuleClass,
        tempTravelAllowance,
        tempTravelWorkflow
      ] = await Promise.all([
        // this.getTravelRuleClassPromise,
        this.getTravelAllowancePromise,
        this.getTravelWorkflowPromise
      ]);
      let selectedTravelClassOptionName = "";
      let selectedTravelAllowanceOptionName = "";
      let selectedTravelWorkflowOptionName = "";


      if (tempTravelRuleClass != undefined) {

        if (response.travel && response.travel.travelClass) {
          let selectedTravelClassOption = tempTravelRuleClass.find(
            item => item.id == response.travel.travelClass
          );
          if (selectedTravelClassOption != -1) {

            selectedTravelClassOptionName = selectedTravelClassOption ?.className;
          }
        }
      }

      if (tempTravelAllowance != undefined) {

        if (response.travel && response.travel.travelAllowance) {
          let selectedTravelAllowanceOption = tempTravelAllowance.find(
            item => item.id == response.travel.travelAllowance
          );
          if (selectedTravelAllowanceOption != -1) {

            selectedTravelAllowanceOptionName =
              selectedTravelAllowanceOption ?.configurationName;
          }
        }
      }

      if (tempTravelWorkflow != undefined) {


        if (response.travel && response.travel.travelWorkflow) {
          let selectedTravelWorkflowOption = tempTravelWorkflow.find(
            item => item.id == response.travel.travelWorkflow
          );
          if (selectedTravelWorkflowOption != -1) {

            selectedTravelWorkflowOptionName =
              selectedTravelWorkflowOption ?.workflowName;
          }
        }
      }
      this.setState({
        travel: {
          ...this.state.travel,
          travelClass: response.travel ?.travelClass || '',
          travelAllowance: response.travel ?.travelAllowance || '',
          travelWorkflow: response.travel ?.travelWorkflow || ''
        },
        email: response.email,
        id: response.id,
        activeStatus: response.activeStatus,

        selectedTravelClass: selectedTravelClassOptionName,
        selectedTravelAllowance: selectedTravelAllowanceOptionName,
        selectedTravelWorkflow: selectedTravelWorkflowOptionName,
        isVerifiedInvitation: response.isVerifiedInvitation,
        invite: response.isVerifiedInvitation,
        isTestUser: response.isTestUser,
        serviceModuleId: response.serviceModuleId,
        formHeaderId: response.formHeaderId,
        formId: response.formId,
        profileImage: response.profileImage

      })



      // *End of handling travel settings

      // *End of travelRuleClass, TravelAllowance and TravelWorkflow
      // console.log(response,'response++++++++++');
      // * for delegated Users
      if (response && response.delegatedUser && response.delegatedUser.length > 0) {
        // console.log(this.userDetail.delegatedUser);
        // await this.getUserList();
        // console.log(this.userList);
        let allDelegatedUsers = [...response.delegatedUser];



        // console.log("list of delegated users ", allDelegatedUsers);
        // * Match all delegated users from the userlist and add fullName to it
        let allDelegatedUsersWithName = allDelegatedUsers.map(dgu => {
          const user = this.userList.find(u => u.id == dgu.delegatedUserId);
          if (!user) {
            return dgu;
          }
          return {
            ...dgu,
            email: user.email
          }
        })




        this.setState(
          {
            // delegatedUser: this.userDetail.delegatedUser[0],
            allDelegatedUsers: allDelegatedUsersWithName,
            isDelegateUserExists: true
          },
          async () => {

            this.forceUpdate();
          }
        );
      } else {
        this.setState({
          isDelegateUserExists: false
        });
      }

      // * End of delegated Users

      // * Start of dynamic stuff
      if (response && response.fieldData) {
        this.setState({
          expenseForm: response.fieldData,
          email: response.email
        }, () => {
          // console.log('after setting state ', this.state)
        });

        let newConnectedListDate = [];
        let dynamicFeilds = [];
        dynamicFeilds = _.filter(response.fieldData, function (result) {
          return result.fieldLevel !== undefined && result.fieldLevel !== '';
        });
        dynamicFeilds.forEach((fields) => {
          newConnectedListDate.push({
            columnName: fields.columnName,
            id: fields.id,
            multiSelectParentField: fields.multiSelectParentField ? fields.multiSelectParentField : '',
            fieldName: fields.fieldName,
            fieldLevel: fields.fieldLevel,
            fieldValue: fields.fieldValue
          });
        })
        this.setState({ connectedListData: newConnectedListDate });
        newConnectedListDate && newConnectedListDate.forEach(async (fields) => {
          if (fields.fieldLevel === 1) {
            let newFieldLevel = fields.fieldLevel + 1;
            let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.id && listData.fieldLevel === newFieldLevel);
            if (existConnected.length > 0 && fields.fieldValue !== undefined && fields.fieldValue != '') {
              let companyId = this.state.companyId;
              let response = await expenseReport.getConnectedListData(companyId, fields.fieldValue);
              let childName = existConnected[0].columnName;
              if (response) {
                this.setState({ [childName]: response });
              }
            }
          } else if (fields.fieldLevel >= 1) {
            let newFieldLevel = fields.fieldLevel + 1;
            let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.multiSelectParentField && listData.fieldLevel === newFieldLevel);
            if (existConnected.length > 0 && fields.fieldValue !== undefined && fields.fieldValue != '') {
              let companyId = this.state.companyId;
              let response = await expenseReport.getConnectedListData(companyId, fields.fieldValue);
              let childName = existConnected[0].columnName;
              if (response) {
                this.setState({ [childName]: response });
              }
            }
          }

        });
        let formFields = [];
        let fieldsObjects = [];
        response.fieldData.forEach(async (formField, index) => {
          let fieldName = formField.fieldName;

          if (formField.displayType === "date") {
            this.setState({ [fieldName]: formField.fieldValue?formField.fieldValue:null });
          } else if (formField.displayType === "checkbox") {
            this.setState({ [fieldName]: formField.fieldValue });
          } else {
            this.setState({ [fieldName]: formField.fieldValue });
          }
          formFields.push(formField.fieldName);
          this.setState({ submitButtonStatus: false });
          fieldsObjects.push({ fieldId: formField.id, columnName: formField.columnName });
        });
        this.setState({ isLoading: false });
        this.setState({ formFields: formFields, fieldsObject: fieldsObjects });
      }
    }
    else {
      console.log("No data found");
    }
  };

  //  * End of editing of user code from rohit



  render() {
    return (
      <EditUserProfilePageTemp
        fieldData={this.listOfForm}
        error={this.validator}
        openDelegate={e => this.openDelegate()}
        state={this.state}
        handleChange={this.handleChange}
        staticHandle={this.staticHandle}
        onSubmitHandler={this.onSubmitHandler}
        isEdit={this.isEdit}
        submitUpdateHandle={this.submitUpdateHandle}
        dummuyValue={this.dummuyValue}
        userList={this.userList}
        handleSelectChange={this.handleSelectChange}
        delegatedItem={this.delegatedItem}
        delegatedView={this.delegatedView}
        chooseDelegate={this.chooseDelegate}
        serviceList={this.serviceList}
        changeData={this.changeData}
        handleChangeDelegate={this.handleChangeDelegate}
        accessTypeName={this.accessTypeName}
        serviceName={this.serviceName}
        fetchMoreData={this.fetchMoreData}
        hasMore={this.hasMore}
        searchUser={this.searchUser}
        search={this.search}
        count={this.count}
        handleStatusChange={this.handleStatusChange}
        removeDelegateHandler={this.removeDelegateHandler}
        testUserHandler={this.testUserHandler}
        inviteHandler={this.inviteHandler}
        handleTravelSelector={this.handleTravelSelector}
        dayChangeHandler={this.dayChangeHandler}
        imageSelectedHandler={this.imageSelectedHandler}


        {...this.state}
        validator={this.validator}
        policyForm={this.state.expenseForm}
        onClickHandler={this.onClickHandler}
        onChangeHandler={this.onChangeHandler}
        onDatePickerHandler={this.onDatePickerHandler}
        onCheckboxHandler={this.onCheckboxHandler}
        onIntegerHandler={this.onIntegerHandler}
        userPermission={this.state.userPermission}
        onConnectedListkHandler={this.onConnectedListkHandler}
        handleSelectedOption={this.handleSelectedOption}


      />
    );
  }
}
export default EditUserProfileTemp;
