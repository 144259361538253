import React from "react";
import PropTypes from "prop-types";

import FlightDiscountsPage from "./flight-discounts-html";
import HotelDiscountsPage from "./hotel-discounts-html";

const AddAirDiscountsPage = props => {
  const {
    matchedCategory,
    OnCancelBtnHandler
  } = props;
  
  switch (matchedCategory.travelCategoryName) {
    case "Flight":
      return (
        <FlightDiscountsPage
          {...props}
        />
      );
    default:
      return (
        <HotelDiscountsPage OnCancelBtnHandler={OnCancelBtnHandler} />
      );
  }
};

AddAirDiscountsPage.propTypes = {
  matchedCategory: PropTypes.object,
  OnCancelBtnHandler: PropTypes.func,
};

export default AddAirDiscountsPage;
