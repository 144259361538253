import PropTypes from "prop-types";
import classNames from "classnames";
import { TripBarItemHeader } from "./TripBarItemHeader";
import { TripBarItemBody } from "./TripBarItemBody";
import { TripBarItemFooter } from "./TripBarItemFooter";
import { modifyDisplayNameInnerPropAccess } from "src/_helpers";

let TripBarItem = ({
  children,
  className,
  as = "div",
  tag: Tag = as,
  ...props
}) => (
  <Tag className={classNames("details", className)} {...props}>
    <div className={"details-margin"}>
      <div className="d-table d-table__middle">
        <div className="text-left">{children}</div>
      </div>
    </div>
  </Tag>
);

TripBarItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired
};

TripBarItem.Header = TripBarItemHeader;
TripBarItem.Body = TripBarItemBody;
TripBarItem.Footer = TripBarItemFooter;

// const proxiedTripBarItem = modifyDisplayNameInnerPropAccess(TripBarItem);

// export { proxiedTripBarItem as TripBarItem };
export { TripBarItem };
