import React, { Component } from "react";
import ImportPage from "./import-html";
import { importExportService } from "../../../../../_services";
import { toast } from "react-toastify";
import { importTypes } from "./optionsConstant";
import { authHeader } from "src/_helpers/auth-header";
import Axios from "axios";
import { config } from "src/env";
import cryptr from "src/_helpers/crypty";


class ImportFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      companyId: localStorage["440a28"],
      totalCount: "",
      totalFailed: "",
      totalInserted: "",
      totalLogs: "",
      totalUpdated: "",
      selectedImportType:   {
        label: 'Employee',
        value: 'EMPLOYEE'
      }
    };
  }

  componentDidMount() {
    //   Why componentDidMount if no need?
  }

  handleImportTypeSelect = (selectedImportType) => {
    this.setState({selectedImportType});
  }

  checkFileTypes = (files) => {
    let err = '';
   const types = ['text/csv'];
         if (types.every(type => files.type !== type)) {
         err += files.type+' is not a supported format\n';
       }

   if (err !== '') {
         return false;
    }
   return true;

  }



  onfileUploadHandler = async event => {
    //   variable which are retrieve through props or state
    // should be retrived on the top at once
    let isCSV = this.checkFileTypes(event.target.files[0]);
    if (event.target.files[0] && isCSV) {
      this.setState({
        selectedFile: event.target.files[0],
        totalCount: "",
        totalFailed: "",
        totalInserted: "",
        totalLogs: "",
        totalUpdated: "",

      });

      const selectedFileData = new FormData();
      selectedFileData.append("file", event.target.files[0]);

      let response = await importExportService.uploadCSV(selectedFileData);

      // * Axios magic try
      // let response = await this.axiosFileUpload(selectedFileData);



      // * End of axis magic try


      if(response && response.result && response.result.filePath){
        let selectedImportTypeValue = this.state.selectedImportType.value;
        let result  = await importExportService.uploadCSVToDatabase(response.result.filePath,selectedImportTypeValue);
        if(result && result.message && result.result){

          this.setState({
            totalCount: result.result.totalCount,
            totalFailed: result.result.totalFailed,
            totalInserted: result.result.totalInserted,
            totalLogs: result.result.totalLogs,
            totalUpdated: result.result.totalUpdated,
            selectedFile:null
          })
        }
      }


    }else{
      toast.warn('Only CSV files supported.');
      this.setState({
        selectedFile: null
      })
    }
  };

  axiosFileUpload = async (selectedFileData)=> {
    let userData = localStorage.getItem("440a24");
      var user = JSON.parse(cryptr.decrypt(userData));
      const headers = {
        "x-api-key": "G0OndZH93PuXhhZk",
        Authorization: "Bearer " + user.token
      };
      let response = await Axios.post(config.apiUrlCompany, selectedFileData, headers);
  }

  render() {
    return (
      <div>
        <ImportPage
          {...this.state}
          importTypes={importTypes}
          onfileUploadHandler={this.onfileUploadHandler}
          handleImportTypeSelect={this.handleImportTypeSelect}

          />
      </div>
    );
  }
}
export default ImportFileUpload;
