import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { getValue } from "src/_components/common/lodsh";

class ExpenseApprovalList extends React.PureComponent {
  componentDidMount() {
    localStorage.removeItem("permissionStatus");
    localStorage.setItem("permissionStatus", "aprover-role");
  }
  render() {
    console.log(this.props.expenseApproval);
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr className="text-uppercase">
              <th width="20%">EXPENSE REPORT ID</th>
              <th>REQUEST ID</th>
              <th>REPORT DATE</th>
              <th>EMPLOYEE NAME</th>
              <th>AMOUNT DUE EMPLOYEE</th>
              <th>AMOUNT DUE COMPANY</th>
              <th>REPORT TOTAL AMOUNT</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {this.props.expenseApproval &&
            this.props.expenseApproval.length > 0 ? (
              this.props.expenseApproval.map(list => {
                // let checkApprovedDataAndIsuued = (cashAdvanceResult, workflowSteps, workflowCurrent) => {
                //     let checkStatus = () => {
                //         return cashAdvanceResult.current.STATUS ? cashAdvanceResult.current.STATUS : false;
                //     }
                //     let checkWorkflowSteps = () => {
                //         return workflowCurrent && workflowCurrent.currentOrderNo.toString() ? workflowSteps[workflowCurrent.currentOrderNo] && workflowSteps[workflowCurrent.currentOrderNo].intialStatus ? workflowSteps[workflowCurrent.currentOrderNo].intialStatus : "" : ""
                //     }
                //     let getCashDataStatus = () => {
                //         return cashAdvanceResult.EXPENSE_DATA && cashAdvanceResult.EXPENSE_DATA.headerObj ? cashAdvanceResult.EXPENSE_DATA.headerObj.status : 'Approved';
                //     }

                //     return checkStatus() ? getCashDataStatus() : checkWorkflowSteps();
                // }
                let workFlowStepStatus = getValue(list, "current.STATUS")
                  ? getValue(list, "current.STATUS")
                  : getValue(list, "intialStatus");

                // let headerFormData, expenseReportId, userDetails, firstNameFIled, middleNameFIled, lastNameFIled, userFullName, reportDate, reportNameField, requestId, currency;
                // headerFormData = list.EXPENSE_DATA.headerObj.headerForm;
                // currency = list.EXPENSE_DATA.baseCurrency;
                // expenseReportId = list.EXPENSE_DATA.headerObj.reportId;
                // userDetails = list.EXPENSE_DATA.headerObj.user.fieldData;
                // firstNameFIled = userDetails.find((userFields) => userFields.columnName === "EMPLOYEE_FIRST_NAME");
                // middleNameFIled = userDetails.find((userFields) => userFields.columnName === "MIDDLE_NAME");
                // lastNameFIled = userDetails.find((userFields) => userFields.columnName === "EMPLOYEE_LAST_NAME");
                // userFullName = firstNameFIled && middleNameFIled && lastNameFIled && firstNameFIled.fieldValue + ' ' + middleNameFIled.fieldValue + ' ' + lastNameFIled.fieldValue;
                // reportNameField = headerFormData.find((fields) => fields.columnName === "REPORT_NAME");
                // reportDate = list.EXPENSE_DATA.headerObj.createdAt;
                // requestId = list.EXPENSE_DATA.headerObj.requestId;
                // let currentAppovalStatus = checkApprovedDataAndIsuued(list, list.info.steps, list.current)
                // //  list.current.STATUS==='Approved'?list.current.STATUS :list.info && list.info.steps[list.current&&list.current.currentOrderNo].intialStatus;
                return (
                  <tr key={list.id} data-toggle="modal">
                    <NavLink to={`/expense/details/approval/${list.id}`}>
                      <td>
                        {getValue(list, "current.reportId")}
                        <br />
                        {getValue(list, "EXPENSE_DATA.reportName")}
                      </td>
                    </NavLink>
                    <td>
                      {getValue(list, "EXPENSE_DATA.headerObj.requestId", "-")}
                    </td>
                    <td>{moment(list.createdAt).format("DD MMM YYYY")}</td>
                    <td className="text-primary">
                      <button className="btn btn-nothing text-primary">
                        <p>{getValue(list, "name", "")}</p>
                      </button>
                      <p style={{ color: "gray" }}>
                        {" "}
                        {getValue(list, "empId", "")}
                      </p>
                    </td>
                    <td>
                      {getValue(list, "EXPENSE_DATA.baseCurrency")}&nbsp;
                      {parseInt(
                        getValue(
                          list,
                          "EXPENSE_DATA.headerObj.amountDueEmployee",
                          ""
                        )
                      ).toFixed(2) !== "NaN"
                        ? parseInt(
                            getValue(
                              list,
                              "EXPENSE_DATA.headerObj.amountDueEmployee",
                              ""
                            )
                          ).toFixed(2)
                        : "0.00"}
                    </td>
                    <td>
                      {getValue(list, "EXPENSE_DATA.baseCurrency")}&nbsp;
                      {parseInt(
                        getValue(
                          list,
                          "EXPENSE_DATA.headerObj.amountDueCompany",
                          ""
                        )
                      ).toFixed(2) !== "NaN"
                        ? parseInt(
                            getValue(
                              list,
                              "EXPENSE_DATA.headerObj.amountDueCompany",
                              ""
                            )
                          ).toFixed(2)
                        : "0.00"}
                    </td>
                    <td>
                      {getValue(list, "EXPENSE_DATA.baseCurrency")}&nbsp;
                      {parseInt(
                        getValue(list, "EXPENSE_DATA.headerObj.totalAmount", "")
                      ).toFixed(2) !== "NaN"
                        ? parseInt(
                            getValue(
                              list,
                              "EXPENSE_DATA.headerObj.totalAmount",
                              ""
                            )
                          ).toFixed(2)
                        : "0.00"}
                    </td>
                    <td>
                      {workFlowStepStatus && workFlowStepStatus ? (
                        <div>
                          {workFlowStepStatus === "Approved" ? (
                            <button className="btn btn-nothing text-success">
                              <i className="material-icons fs-12">thumb_up</i>{" "}
                              {workFlowStepStatus}
                            </button>
                          ) : workFlowStepStatus === "Pending Submission" ||
                            workFlowStepStatus === "Pending Approval" ? (
                            <span>{workFlowStepStatus}</span>
                          ) : workFlowStepStatus === "Issued" ? (
                            <button className="btn btn-nothing text-success-light">
                              <i className="material-icons fs-12">
                                check_circle
                              </i>{" "}
                              {workFlowStepStatus}
                            </button>
                          ) : workFlowStepStatus === "Reject" ? (
                            <button className="btn btn-nothing text-danger">
                              <i className="material-icons fs-12">thumb_down</i>{" "}
                              {workFlowStepStatus}
                            </button>
                          ) : (
                            <span>{workFlowStepStatus}</span>
                          )}
                        </div>
                      ) : (
                        <span>{}</span>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  No Records Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
ExpenseApprovalList.propTypes = {
  expenseApproval: PropTypes.array
};
export default withRouter(ExpenseApprovalList);
