import React from "react";
import uuid from "uuid";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, { formatDate } from 'react-day-picker/moment';
import _ from "lodash";
import DynamicFieldsType from '../../../../common/dynamicFields';
import AsyncSelect from "react-select/lib/Async";
import  SelectSearch from "src/_components/common/_forms/SelectSearchMulti";
import { getValue, setValue } from "src/_components/common/lodsh";

const allocationDynamicForm = props => {
  return  getValue(props,"allocationForm",[]) && getValue(props,"allocationForm",[]) ? getValue(props,"allocationForm",[]).map((form, index) => {
    let formFieldName = form.fieldName;
    let userPermission = props.userPermission;
    let permissions = form.fieldRolePermissions && form.fieldRolePermissions;
    let newPermissionArray = permissions && permissions.map((permsList) => (permsList.roleId.roleKey));
    let permissionIndex = newPermissionArray && newPermissionArray.indexOf(userPermission);
    let accessPermission = 2;
    if (permissionIndex >= 0) {
      accessPermission = parseInt(permissions && permissions[permissionIndex].permissionId);
    } else {
      accessPermission = 2;
    }

    // eslint-disable-next-line default-case
    switch (form.dataType) {
      case DynamicFieldsType.DATA_TYPE_CONNECTED_LIST:
        let NewformFieldName = form.columnName;
        if (accessPermission > 0 && form.activeStatus) {
          if (form.fieldLevel > 1) {
            let selectedDropDown = '';
            formFieldName = _.toUpper(NewformFieldName);
            let originalField = _.startCase(_.camelCase(form.fieldName));
            return (
              <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                <div className="form-group custom labelup mb-0" key={uuid.v4()}>
                  <label>{form.labelName}</label>
                  <div className="dropdown" >
                    <button
                      type="button"
                      className="btn dropdown-toggle bg-white table-btn pr-4"
                      data-toggle="dropdown"
                    >
                      {props['alloc' + formFieldName + props.rowIndex] && props['alloc' + formFieldName + props.rowIndex].forEach(element => {
                        if (props['alloc' + form.fieldName + props.rowIndex] === element.id) {
                          selectedDropDown = element.itemName;
                        }
                      })}
                      {selectedDropDown ? selectedDropDown : "Select an option"}
                    </button>
                    <div className="dropdown-menu">
                      {props['alloc' + formFieldName + props.rowIndex] && props['alloc' + formFieldName + props.rowIndex].map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onConnectedListkHandler('alloc' + form.fieldName + props.rowIndex, form.multiSelectParentField, opt.id, form.fieldLevel, props.rowIndex)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.itemName}
                            </button>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props['alloc' + form.fieldName + props.rowIndex],
                      "required|string"
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          } else {
            let selectedDropDown = '';
            return (
              <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                <div className="form-group custom labelup mb-0" key={uuid.v4()}>
                  <label>{form.labelName}</label>
                  <div className="dropdown" key={uuid.v4()}>
                  {form.connectedListData &&
                        form.connectedListData.listManagementData.forEach(
                          element => {
                            if (props[formFieldName+props.rowIndex] === element.id) {
                              selectedDropDown = element.itemName;
                            }
                          }
                        )}
                      {}
                  {form.connectedListData && form.connectedListId &&

                        <SelectSearch
                        options={form.connectedListData.listManagementData}
                        keyName={"itemName"}
                        refId={form.connectedListId}
                        assignKey={`${props.rowIndex}-${index}`}
                        page={form.page}
                        form={form}
                        rowIndex={props.rowIndex}
                        handleSearch={props.handleSearchManage}
                        count={form.connectedListDataCount}
                        handleScroll={props.handleScroll}
                        onClickHandler={props.onClickHandler}

                        loading={getValue(props.loading,[`${props.rowIndex}-${index}`],false)}
                        value={getValue(props.search,[`${props.rowIndex}-${index}`],"")}

                        placeholder={selectedDropDown ? selectedDropDown : form.fieldItemName?form.fieldItemName: "Select an option"}
                        disabled={
                        accessPermission === 1 ||
                        !(props.isEdit || form.columnName !== "GROUP_NAME")
                        }
                        />
          }

                    </div>
                  </div>

                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props['alloc' + form.fieldName + props.rowIndex],
                      "required|string"
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          }
        }break;
      case DynamicFieldsType.DATA_TYPE_TEXT:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={form.id + props.rowIndex} className="col-md-4 my-3">
              <div className="form-group custom mb-0">
                <input
                  type="text"
                  placeholder="&nbsp;"
                  maxLength={form.maxLength || 10000}
                  readOnly={accessPermission === 1}
                  name={'alloc' + form.fieldName + props.rowIndex}
                  onChange={e => props.onChangeHandler(e, 'alloc' + form.fieldName + props.rowIndex)}
                  value={props['alloc' + form.fieldName + props.rowIndex] ? props['alloc' + form.fieldName + props.rowIndex] : ""}
                  className="form-control"
                />
                <label>{form.labelName}</label>
              </div>
              {form.isMandatory ? (
                <div className="text-danger fs-12">
                  {props.validator.message(
                    form.labelName,
                    props['alloc' + form.fieldName + props.rowIndex],
                    `required`
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_DATE:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={form.id + props.rowIndex} className="col-md-4 my-3">
              <div className="form-group custom labelup mb-0">
                <label>{form.labelName}</label>
                <DayPickerInput
                  format="DD MMM YYYY"
                  formatDate={formatDate}
                  dayPickerProps={{ locale: 'en-gb', localeUtils: MomentLocaleUtils, disabledDays: { after: new Date() } }}
                  selectedDays={
                    props['alloc' + form.fieldName + props.rowIndex] ? props['alloc' + form.fieldName + props.rowIndex] : new Date()
                  }
                  onDayChange={e => props.onDatePickerHandler(e, 'alloc' + form.fieldName + props.rowIndex)}
                  placeholder={"DD MMM YYYY"}
                />
              </div>
              {form.isMandatory ? (
                <div className="text-danger fs-12">
                  {props.validator.message(
                    form.labelName,
                    props['alloc' + form.fieldName + props.rowIndex],
                    "required"
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_TEXTAREA:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={form.id + props.rowIndex} className="col-md-4 my-3">
              <div className="form-group custom mb-0">
                <textarea
                  rows="3"
                  data-gramm_editor="false"
                  spellCheck="false"
                  name={'alloc' + form.fieldName + props.rowIndex}
                  className="form-control"
                  maxLength={form.maxLength || 10000}
                  readOnly={accessPermission === 1}
                  onChange={e => props.onChangeHandler(e, form.fieldName + props.rowIndex)}
                  value={props['alloc' + form.fieldName + props.rowIndex] ? props['alloc' + form.fieldName + props.rowIndex] : ""}
                  placeholder="&nbsp;"
                />
                <label>{form.labelName}</label>
              </div>
              {form.isMandatory ? (
                <div className="text-danger fs-12">
                  {props.validator.message(
                    form.labelName,
                    props['alloc' + form.fieldName + props.rowIndex],
                    `required`
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_CHECKBOX:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div className="col-md-4 my-3">
              <div key={form.id + props.rowIndex} className="align-top">
                <label className="switch">
                  <input
                    type="checkbox"
                    value={props['alloc' + form.fieldName + props.rowIndex] ? props['alloc' + form.fieldName + props.rowIndex] : false}
                    checked={props['alloc' + form.fieldName + props.rowIndex] ? props['alloc' + form.fieldName + props.rowIndex] : false}
                    onChange={e => props.onCheckboxHandler('alloc' + form.fieldName + props.rowIndex)}
                  />
                  <span className="slider round" />
                </label>
                <span>{form.labelName}</span>
              </div>
              <div className="text-danger fs-12">{props.error['alloc' + form.fieldName + props.rowIndex]}</div>
            </div>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_BOOLEAN:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div className="col-md-4 my-3">
              <div key={form.id + props.rowIndex} className="pt-3 ">
                <label className="switch">
                  <input
                    type="checkbox"
                    value={props['alloc' + form.fieldName + props.rowIndex] ? props['alloc' + form.fieldName + props.rowIndex] : false}
                    checked={props['alloc' + form.fieldName + props.rowIndex] ? props['alloc' + form.fieldName + props.rowIndex] : false}
                    onChange={e => props.onCheckboxHandler('alloc' + form.fieldName + props.rowIndex)}
                  />
                  <span className="slider round" />
                </label>
                <span>{form.labelName}</span>
              </div>
              <div className="text-danger fs-12">{props.error['alloc' + form.fieldName + props.rowIndex]}</div>
            </div>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_INTEGER:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={form.id + props.rowIndex} className="col-md-4 my-3">
              <div className="form-group custom mb-0">
                <input
                  type="text"
                  maxLength={form.maxLength || 10000}
                  placeholder="&nbsp;"
                  readOnly={accessPermission === 1}
                  name={'alloc' + form.fieldName + props.rowIndex}
                  onChange={e => props.onIntegerHandler(e, 'alloc' + form.fieldName + props.rowIndex)}
                  value={props['alloc' + form.fieldName + props.rowIndex] ? props['alloc' + form.fieldName + props.rowIndex] : ""}
                  className="form-control"
                />
                <label>{form.labelName}</label>
              </div>
              {form.isMandatory ? (
                <div className="text-danger fs-12">
                  {props.validator.message(
                    form.labelName,
                    props['alloc' + form.fieldName + props.rowIndex],
                    `required`
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_AMOUNT:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={form.id + props.rowIndex} className="col-md-4 my-3">
              <div className="form-group custom mb-0">
                <input
                  type="text"
                  maxLength={form.maxLength || 10000}
                  placeholder="&nbsp;"
                  readOnly={accessPermission === 1}
                  name={'alloc' + form.fieldName + props.rowIndex}
                  onChange={e => props.onIntegerHandler(e, 'alloc' + form.fieldName + props.rowIndex)}
                  value={props['alloc' + form.fieldName + props.rowIndex] ? props['alloc' + form.fieldName + props.rowIndex] : ""}
                  className="form-control"
                />
                <label>{form.labelName}</label>
              </div>
              {form.isMandatory ? (
                <div className="text-danger fs-12">
                  {props.validator.message(
                    form.labelName,
                    props['alloc' + form.fieldName + props.rowIndex],
                    `required`
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }break;
      case DynamicFieldsType.DATA_TYPE_EMAIL:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={form.id + props.rowIndex} className="col-md-4 my-3">
              <div className="form-group custom mb-0">
                <input
                  type="text"
                  placeholder="&nbsp;"
                  readOnly={accessPermission === 1}
                  maxLength={form.maxLength || 10000}
                  name={'alloc' + form.fieldName + props.rowIndex}
                  onChange={e => props.onChangeHandler(e, form.fieldName + props.rowIndex)}
                  value={props['alloc' + form.fieldName + props.rowIndex] ? props['alloc' + form.fieldName + props.rowIndex] : ""}
                  className="form-control"
                />
                <label>{form.labelName}</label>
              </div>
              {form.isMandatory ? (
                <div className="text-danger fs-12">
                  {props.validator.message(
                    form.labelName,
                    props['alloc' + form.fieldName + props.rowIndex],
                    `required`
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }break;

      case DynamicFieldsType.DATA_TYPE_SEARCH:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div className="col-md-4 my-3">
              <div key={index}>
                <div className="form-group custom labelup mb-0">
                  <label>{form.labelName}</label>
                  <AsyncSelect
                    noOptionsMessage={({ inputValue }) => inputValue ? 'No results.' : "Start typing to search..."}
                    placeholder={"Search and select"}
                    loadOptions={props.loadOptions}
                    value={props['alloc' + form.fieldName + props.rowIndex] ? props['alloc' + form.fieldName + props.rowIndex] : form.labelName}
                    onChange={(event) => props.onLocationChangekHandler(event, 'alloc' + form.fieldName + props.rowIndex)}
                  />
                </div>
                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props['alloc' + form.fieldName + props.rowIndex] ?.value,
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
          );
        }break;

      case DynamicFieldsType.DATA_TYPE_SYSTEM_LIST:
        if (accessPermission > 0 && form.activeStatus) {
          let selectedDropDown = '';
          if (form.columnName === "PAYMENT_TYPE") {
            return (
              <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                <div className="form-group custom labelup mb-0">
                  <label>{form.labelName}</label>
                  <div className="dropdown" key={uuid.v4()}>
                    <button
                      type="button"
                      className="btn dropdown-toggle bg-white table-btn"
                      data-toggle="dropdown"
                      disabled={accessPermission === 1}
                    >
                      {form.paymentDetails && form.paymentDetails.forEach(element => {
                        if (props['alloc' + form.fieldName + props.rowIndex] === element.id) {
                          selectedDropDown = element.description;

                        }
                      })}
                      {selectedDropDown ? selectedDropDown : "Select an option"}
                    </button>
                    <div className="dropdown-menu">
                      {form.paymentDetails && form.paymentDetails.map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onClickHandler(form.fieldName + props.rowIndex, form.id, opt.id, form.fieldLevel, props.rowIndex)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.description}
                            </button>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props['alloc' + form.fieldName + props.rowIndex],
                      "required|string"
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          } else {
            return (
              <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                <div className="form-group custom labelup mb-0">
                  <label>{form.labelName}</label>
                  <div className="dropdown" key={uuid.v4()}>
                    <button
                      type="button"
                      className="btn dropdown-toggle bg-white table-btn"
                      data-toggle="dropdown"
                      disabled={accessPermission === 1}
                    >
                      {form.connectedListData && form.connectedListData.listManagementData.forEach(element => {

                        if (props['alloc' + form.fieldName + props.rowIndex] === element.id) {
                          selectedDropDown = element.itemName;

                        }
                      })}
                      {selectedDropDown ? selectedDropDown : "Select an option"}
                    </button>
                    <div className="dropdown-menu">
                      {form.connectedListData && form.connectedListData.listManagementData.map(
                        (opt) => {
                          return (
                            <button
                              type="button"
                              onClick={e =>
                                props.onClickHandler(form.fieldName + props.rowIndex, form.id, opt.id, form.fieldLevel, props.rowIndex)
                              }
                              className="dropdown-item"
                              key={opt.id}
                            >
                              {opt.itemName}
                            </button>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props['alloc' + form.fieldName + props.rowIndex],
                      "required|string"
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          }
        }break;
      default:
        return null;
    }
  }) : ''
};

export default allocationDynamicForm;
