import React from "react";
import moment from 'moment';

const LatestHotel = props => {
const { item } = props;

// * The following function is not required anymore
function parseDate(str) {
  var mdy = str.split('-');
    // year,month,date
  return new Date(mdy[0],mdy[1]-1,mdy[2]);
}

// * the following function is not requiured anymore
function datediff(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((first-second)/(1000*60*60*24));
}
function differenceInDays(departureDate, currentDate){
  let departureDateInMoment = moment(departureDate, 'YYYY-MM-DD');
  let currentDateInMoment = moment(currentDate, 'YYYY-MM-DD');
  if(departureDateInMoment && currentDateInMoment){
    return departureDateInMoment.diff(currentDateInMoment, 'days') + 1;

  }else{
    return '---'
  }
}
// const noOfDays = datediff(parseDate(item.from), new Date());
const noOfDays = differenceInDays(item?.from, new Date())
  return (
    <div key={props.keyIndex } className={(props.keyIndex === 0 )?"carousel-item active":"carousel-item"}>
    <div className="carousel-innerdiv">
      <div className="p-3">
        <h6 className="mb-2 heading">Up Next</h6>
        <div className="py-2">
          <div className="float-left">
            <div className="first-circle">
              <img src={(item?.hotel_photos?.[0])?item?.hotel_photos?.[0]:"/assets/images/booking/room1.png"} />
            </div>
            <div className="second-circle bg-white">
             <div className="main-center">
              {(noOfDays !== 0)?
                <div className="line">
                  <h5 className="text-black text-center mb-0">{noOfDays}</h5>
                  <p className="text-black text-center mb-0">{(noOfDays === 1) ?
                                                    "Day"
                                                    :
                                                    "Days"
                                                }</p>
                </div>
              :
                <div className="line">
                  <h5 className="text-black text-center mb-0">Today</h5>
                </div>
              }
              </div>
            </div>
          </div>
          <div className="float-left">
            <div className="shape-name">
              <div className="right ml-3">
                <span className="text mb-1"><i className="material-icons fs-14 align-sub">local_hotel</i> Check In</span>
                <h6 className="heading bg-white">{item?.hotel_name} | {item?.hotel_city}</h6>
                <span className="text"> Check in {item.hotel_from} - Check out {item.hotel_to}</span>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
      <div className="bg-table p-3">
        <div className="float-left">
          <h6 className="heading mb-0">Coming Up</h6>
          <p className="text-muted fs-12">Take a look at whats's next</p>
        </div>
        <div className="float-right">
            {item.hotel_photos.map((item,ley) =>{
                if(ley <4){
                    return(
                        <div key={ley} className="small-second-round">
                            <img src={item} />
                        </div>
                    )
                }
            })}
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  </div>
  );
};
export default LatestHotel;
