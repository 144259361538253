import { http } from "../_helpers";
import { config } from "../env";


const getAllWorkflows = (companyId, limit, page,search) => { 
  let url = `${
    config.apiExpense
  }workflow/general?companyId=${companyId}&limit=${limit}&page=${page}&search=${search}`;
  return http.get(url).then(res => res);
};

const removeWorkflow = (companyId, workflowId) => {
  let url = `${config.apiExpense}workflow/general/remove?workflow_id=${workflowId}&companyId=${companyId}`;
  return http.get(url).then(res => res);
};

const getEditableByList = (companyId, itemCode) => {
  return;
};

const getMasterOptionsForGeneral = () => {
  let url = `${config.apiExpense}workflow/masters/general/listall`;
  return http.get(url).then(res => res);
}

const getWorkflowGeneralDetails = (id) => {
  let url = `${config.apiExpense}workflow/general/${id}`;
  return http.get(url).then(res => res);
}
const getEmailTemplates = (companyId, serviceModule) => {
  let url = `${config.apiUrlCompany}email-administration/list-all?companyId=${companyId}&serviceModuleId=${serviceModule}`;
  return http.get(url).then(res => res);
}

const getAllGroups = (companyId) => {
  let url = `${config.apiUrlCompany}list-management?companyId=${companyId}&itemCode=GRP001`;
  return http.get(url).then(res => res);
}

const createGeneralWorkflow = (payload) => {
  let url = `${config.apiExpense}workflow/general`;
  return http.post(url, payload)
    .then(res => res)
}

const updateGeneralWorkflow = (companyId, id, payload) => {
  let url = `${config.apiExpense}workflow/general/${id}?companyId=${companyId}`;
  return http.patch(url, payload).then(res => res);
}

const duplicateWorkflow = (companyId, workflowId) => {
  let url = `${config.apiExpense}workflow/copy?workflow_id=${workflowId}&companyId=${companyId}`;
  return http.get(url).then(res => res);
}

// * Workflow services for Steps

const getMasterOptionsForStep = () => {
  let url = `${config.apiExpense}workflow/masters/steps/listall`;
  return http.get(url).then(res => res);
}

const createWorkflowStep = (payload) => {
  let url = `${config.apiExpense}workflow/steps`;
  return http.post(url, payload).then(res => res);
}

const updateWorkflowStep = (stepId, payload) => {
  let url = `${config.apiExpense}workflow/steps/${stepId}`;
  return http.patch(url, payload).then(res => res);
}

const getAllWorkflowSteps = (id) => {
  let url = `${config.apiExpense}workflow/steps/${id}`;
  return http.get(url).then(res => res);

}

const toggleStepStatus = (stepId, payload) => {
  let url = `${config.apiExpense}workflow/steps/${stepId}`;
  return http.patch(url, payload).then(res => res);
}

const moveWorkflowStepDown = (stepId, payload) => {
  let url = `${config.apiExpense}workflow/steps/${stepId}`;
  return http.patch(url, payload).then(res => res);
}

const removeWorkflowStep = (stepId) => {
  let url = `${config.apiExpense}workflow/steps/${stepId}`;
  return http.delete(url).then(res => res);
}

// * Workflow services for Rules

const getFormHeaders = (serviceModuleArray) => {
  let url = `${config.apiUrlCompany}form-headers/list-all`;
  return http.post(url,serviceModuleArray).then(res => res);
}

const getFieldNames = (companyId, formHeaderId) => {
  let url = `${config.apiUrlCompany}company-fields/get-form-header-fields?companyId=${companyId}&formHeaderId=${formHeaderId}`;
  return http.get(url).then(res => res);
}

const createRuleForWorkflow = (payload) => {
  let url = `${config.apiExpense}workflow/rules`;
  return http.post(url, payload).then(res => res)
}

const getWorkflowRules = (workflowId) => {
  let url = `${config.apiExpense}workflow/rules/${workflowId}`;
  return http.get(url).then(res => res);

}

const updateWorkflowRules = (ruleId, payload) => {
  let url = `${config.apiExpense}workflow/rules/${ruleId}`;
  return http.patch(url,payload).then(res => res);
}

// * Workflow Services for Actions

const getMasterOptionsForRules = () => {
  let url = `${config.apiExpense}workflow/masters/rules/listall`;
  return http.get(url).then(res => res);
}

const createAndUpdateAction = (workflowRuleId, payload) => {
  let url = `${config.apiExpense}workflow/rules/${workflowRuleId}`;
  return http.patch(url, payload).then(res => res);
}

const changeApprover = (companyId, searchUser) => {
  let url = `${config.apiUrlCompany}company-users/${searchUser}?limit=10&companyId=${companyId}`;
  return http.get(url).then(res => res);
}

const getAllExceptions = (companyId) => {
  let url = `${config.apiExpense}exceptions?companyId=${companyId}`;
  return http.get(url).then(res => res);
}

const getAllExpenseApproval = (page,limit) => {
  let url = `${config.apiExpense}workflow/approval/status/listAll?page=${page}&limit=${limit}`;
  return http.get(url).then(res => res);
}
const getAllExpenseApprovalWith = (page,limit) => {
  let url = `${config.apiExpense}workflow/approval/status/listAll`;
  return http.get(url).then(res => res);
}

const createApprovalStatus = (companyId, payload) => {
  let url = `${config.apiExpense}workflow/approval/status?companyId=${companyId}`;
  return http.post(url, payload).then(res => res);
}
const updateApprovalStatus = (approvalId, payload) => {
  let url = `${config.apiExpense}workflow/approval/status/${approvalId}`;
  return http.patch(url, payload).then(res => res);
}

const removeApprovalStatus = (companyId, approvalId) => {
  let url = `${config.apiExpense}workflow/approval/status/${approvalId}?companyId=${companyId}`;
  return http.delete(url).then(res => res);
}

const removeRule = (companyId, workflowRuleId) => {
  let url = `${config.apiExpense}workflow/rules/${workflowRuleId}?companyId=${companyId}`;
  return http.delete(url).then(res => res);
}

const getAllRootGroups = (companyId) => {
  let url = `${config.apiUrlCompany}list-management?companyId=${companyId}&search=&parentId=`;
  return http.get(url).then(res => res);
}

const getAllCurrencies = companyId => {
  let url = `${config.apiExpense}currency-administation?companyId=${companyId}&activeStatus=true`;
  return http.get(url).then(res => res);
}

const saveAuthApprove = (payload) => {
  let url = `${config.apiExpense}workflow/authorized/approver`;
  return http.post(url, payload).then(res => res);
}

const updateAuthApprove = (payload,id) => {
  let url = `${config.apiExpense}workflow/authorized/approver/${id}`;
  return http.patch(url, payload).then(res => res);
}

const getAuthList = (req) => {
  const { companyId,type,page,limit } = req;
  let url = `${config.apiExpense}workflow/authorized/approver/listall?companyId=${companyId}&type=${type}&page=${page}&limit=${limit}`;
  return http.get(url).then(res => res);
}




export const workflowService = {
  getAllWorkflows,
  removeWorkflow,
  getMasterOptionsForGeneral,
  getWorkflowGeneralDetails,
  getEmailTemplates,
  getAllGroups,
  createGeneralWorkflow,
  updateGeneralWorkflow,
  duplicateWorkflow,

  // workflow step
  getMasterOptionsForStep,
  createWorkflowStep,
  updateWorkflowStep,
  getAllWorkflowSteps,
  toggleStepStatus,
  moveWorkflowStepDown,
  removeWorkflowStep,

  // Workflow Rule
  getFormHeaders,
  getFieldNames,
  createRuleForWorkflow,
  getWorkflowRules,
  updateWorkflowRules,
  removeRule,

  // * Workflow Actions
  getMasterOptionsForRules,
  createAndUpdateAction,
  changeApprover,
  getAllExceptions,

  // * Expense Approver Status
  getAllExpenseApproval,
  createApprovalStatus,
  removeApprovalStatus,
  updateApprovalStatus,

  // * workflow approver list
  getAllRootGroups,
  getAllCurrencies,
  saveAuthApprove,
  updateAuthApprove,
  getAuthList,
  getAllExpenseApprovalWith,
 
};
