import React, { Component } from "react";
import CartPage from "../../components/cart";
import RespCartFilter from "../../components/resp-cart-filter";
import ModifyServicePage from "../../components/modifyService";
import AddServicePage from "../../components/addService";
import cryptr from "../../../../../_helpers/crypty";
import NumberFormat from 'react-number-format';
class FullDetailsPage extends Component {
    openCart() {
        var cart = document.getElementById("cart");
        cart.classList.add("show");
    }
    constructor(props) {
        super(props)
        let item = JSON.parse(cryptr.decrypt(localStorage['1UC43']));
        this.currencyType = item.currencyCode;
    }

    render() {
        const {
            from,
            to,
            depart,
            returnDate,
            type,
            typeOfService,
            currency,
            // rooms,
            checkout,
            checkin,
            cardList,
            multiReq,
            cartCount,
            selectedItem,
            hotelType
        } = this.props;
        return (
            <div>
                <div className="full-details">
                    {/* use this when you need swapping button */}
                    {/* <div className="details swap-div"> */} 
                        <div className="left text-right">
                            <button
                                type="button"
                                data-target="#addServiceModal"
                                data-toggle="modal"
                                className="details-btn modify-btn-cart light icon-lg"
                            >
                                <i className="material-icons align-sub">
                                    add
                                </i>{" "}
                                Add Service
                            </button>
                            <button
                                type="button"
                                data-target="#modifyModal"
                                data-toggle="modal"
                                className="details-btn modify-btn-cart light icon-lg"
                            >
                                <i className="material-icons align-sub">
                                    edit
                                </i>{" "}
                                <span className="d-none d-md-inline-block">Modify search</span>
                            </button>
                        </div> 
                    <div className="right">
                        <button
                            type="button"
                            onClick={e => this.openCart()}
                            className=" details-btn light cartprice border-right-0"
                        >
                            <span className="position-relative">
                                <span className="round__notification">{cartCount}</span>
                                <i className="material-icons align-sub fs-16 mr-3">
                                    shopping_cart
                            </i>
                            </span>
                            <span className="fs-16 fw-sbold">{this.currencyType}</span> {" "} <span className="fs-16 fw-sbold">
                                <NumberFormat value={cardList.totalPrice && cardList.totalPrice.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                <span><i className="material-icons fs-14 ml-1 align-middle margin-t-minus text-success">arrow_forward</i></span>
                            </span>
                        </button>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <ModifyServicePage
                    from={from}
                    to={to}
                    depart={depart}
                    returnDate={returnDate}
                    type={this.props.type}
                    cartCount={cartCount}
                    cabRequest={this.props.cabRequest}
                    trainRequest={this.props.trainRequest}
                />
                <AddServicePage type={type} />
                <CartPage
                    hotelType={hotelType}
                    cardList={cardList}
                    currency={this.currencyType}
                    typeOfService={this.props.typeOfService}
                    selectedItem={this.props.selectedItem}
                    cartCount={cartCount}
                />
                <RespCartFilter
                    cardList={cardList}
                    currency={this.currencyType}
                />
            </div>
        );
    }
}

export default FullDetailsPage;
