import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { cartServices, masterService } from "src/_services";
import { NavLink } from "react-router-dom";
import ItinerarySubmitPage from "./itinerary-submit";
import ExpenseSubmitPage from "./expense-submit";
import TravelRequestDetailsSubmitPage from "./travel-request-details-submit";
import TripDetailsPage from './trip-details';

class SubmitManiPage extends Component {
  render() {
    return (
      <div className="CheckoutPage">
        <Switch>
          <Route path="/booking/submit/itinerary" component={ItinerarySubmitPage} />
          <Route path="/booking/submit/expense-submit" component={ExpenseSubmitPage} />
          <Route path="/booking/submit/trip-details/:id" component={TripDetailsPage} />
          <Route path="/booking/submit/travel-request-submit" component={TravelRequestDetailsSubmitPage} />
        </Switch>
      </div >
    );
  }
}
export default SubmitManiPage;
