import React from "react";
import Menu from "../menu";
import { NavLink } from "react-router-dom";
import Loader from "src/_components/common/Loader";
const CarListPage = props => {
  localStorage.setItem('carsData', JSON.stringify(props.allCars));
  let carsData = JSON.parse(localStorage.getItem('carsData'));
  return (
    <div>
      <div className="admin-tabs">
        <Menu />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">Cars</h6>
              <p className="text">List of all the cars with contracts for the selected City</p>
            </div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-10">
                  <div className="btn-group-margin">
                    <NavLink
                      to={`/administration/travel/car-info/${-1}/${props.carContractCityId}`}
                      className="btn  "
                    >
                      Add
                    </NavLink>
                    <button type="button"
                      className="btn  "
                      disabled={!props.removeButton}
                      onClick={props.removeCarHandler}>Remove</button>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Action
                      </button>
                      <div className="dropdown-menu">
                        <button className="dropdown-item">Make Inactive</button>
                        <button className="dropdown-item">Delete</button>
                        <button className="dropdown-item">Edit</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="search-custom">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Search"
                      value={props.search}
                      onChange={(e) => props.onSearchTextChangeHandler(e)}
                    />
                    <span>
                      <i className="material-icons align-middle">
                        search
                      </i>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="px-padding pt-3">
              <div className="row">
                {(props.isLoading) ?
                  <div className="mx-auto">
                    <Loader />
                  </div>
                  :
                  props.allCars ? props.allCars.map((car, i) => {
                    return (
                      <div key={i} className="col-md-4 mb-5"
                        onClick={e => props.onDeleteHandler(car.id)}>
                        <div className="hotel-info" style={props.removeCar === car.id ? { "border": "1px solid red" } : {}}>
                          <div className="hotel-img">
                            <img src={car.photos[0]} alt="" />
                          </div>
                          <div className="hotel-detail pm-15">
                            <h6 className="mb-0">
                              <NavLink
                                to={`/administration/travel/car-info/${i}/${car.id}`}
                                className="btn btn-nothing"
                              ><b>{car.carTypeData.itemName}</b> </NavLink>
                            </h6>
                            <p className="lighter-p unmuted-p mb-0">Auto</p>
                          </div>
                          <div className="hotel-cost">
                            <div className="py-2 px-3 text-right bg-white">
                              <p className="text-right mb-0 font-weight-bold">
                                {car.pricePerKm}&nbsp;/KM
                              </p>
                              <p className="unmuted-p mb-0 lighter-p">Per Trip</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    )
                  }) : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CarListPage;
