import React, { Component } from "react";
import { Select, Input } from "../../../../common/_forms";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';


class AddPassportModal extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  render() {
    return (
      <>
        <div
          className="modal fade"
          id="addPassportModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addPassportLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">New</h6>
                <h5 className="modal-title" id="addPassportLabel">
                  Add Passport
              </h5>
              </div>
              
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" />
              </button>
              <form onSubmit={e => this.props.addEditForm2(e)}>
                <div className="modal-middle pm-30">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-md-4 px-2">
                            <div className="form-group custom labelup">
                              <label>Title</label>
                              <div className="dropdown">
                                <Select
                                  placeholder={
                                    this.props.state.form2.title
                                      ? this.props.state.form2.title
                                      : "Title"
                                  }
                                  keyName={"title"}
                                  options={[
                                    {
                                      title: "Mr",
                                      value: "Mr",
                                      stateKey: "passport",
                                      key: "title"
                                    },
                                    {
                                      title: "Mrs",
                                      value: "Mrs",
                                      stateKey: "passport",
                                      key: "title"
                                    },
                                    {
                                      title: "Ms",
                                      value: "Ms",
                                      stateKey: "passport",
                                      key: "title"
                                    }
                                  ]}
                                  controlFunc={e =>
                                    this.props.selectHandlerForm2(e, "title")
                                  }
                                  error={this.props.validator2.message(
                                    "Title",
                                    this.props.state.form2.title,
                                    "required"
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 px-2">
                            <div className="form-group custom">
                              <Input
                                label="Last Name"
                                type="text"
                                name="lastName"
                                value={
                                  this.props.state.form2.lastName !== undefined
                                    ? this.props.state.form2.lastName
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "First Name",
                                  this.props.state.form2.lastName,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col px-2">
                            <div className="form-group custom">
                              <Input
                                label="Given Name"
                                type="text"
                                name="firstName"
                                value={
                                  this.props.state.form2.firstName !== undefined
                                    ? this.props.state.form2.firstName
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "Last Name",
                                  this.props.state.form2.firstName,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                format="DD MMM YYYY"
                                placeholder={this.props.state.form2.dateOfBirth?this.props.state.form2.dateOfBirth:'Select Date Of Birth'}
                                onDayChange={date =>
                                  this.props.handleDayChange(
                                    date,
                                    "form2",
                                    "dateOfBirth"
                                  )
                                }
                              />
                              <span className="icon-custom">
                              <i className="material-icons">calendar_today</i>
                              </span>
                              <label>Date of Birth</label>
                            </div>
                          </div>
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <label>Gender</label>
                              <div className="dropdown">
                                <Select
                                  placeholder={
                                    this.props.state.form2.gender
                                      ? this.props.state.form2.gender
                                      : "Select Gender"
                                  }
                                  keyName={"gender"}
                                  options={[
                                    {
                                      gender: "Male",
                                      value: "Male",
                                      stateKey: "passport",
                                      key: "gender"
                                    },
                                    {
                                      gender: "Female",
                                      value: "Female",
                                      stateKey: "passport",
                                      key: "gender"
                                    }
                                  ]}
                                  controlFunc={e =>
                                    this.props.selectHandlerForm2(e)
                                  }
                                  error={this.props.validator2.message(
                                    "Gender",
                                    this.props.state.form2.gender,
                                    "required"
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 px-2">
                            <div className="form-group custom">
                              <Input
                                label="Place Of Birth"
                                type="text"
                                name="placeOfBirth"
                                value={
                                  this.props.state.form2.placeOfBirth !==
                                    undefined
                                    ? this.props.state.form2.placeOfBirth
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "Place Of Birth",
                                  this.props.state.form2.placeOfBirth,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <label>Country Of Birth</label>
                              <div className="dropdown">
                                <Select
                                  placeholder={
                                    this.props.state.form2.countryOfBirth
                                      ? this.props.state.form2.countryOfBirth
                                      : "Select"
                                  }
                                  keyName={"countryOfBirth"}
                                  options={this.props.state.countriesBirthList}
                                  controlFunc={e =>
                                    this.props.selectHandlerForm2(e)
                                  }
                                  error={this.props.validator2.message(
                                    "Country Of Birth",
                                    this.props.state.form2.countryOfBirth,
                                    "required"
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col px-2">
                            <div className="form-group custom">
                              <Input
                                label="Passport Number"
                                type="text"
                                name="passportNumber"
                                value={
                                  this.props.state.form2.passportNumber !==
                                    undefined
                                    ? this.props.state.form2.passportNumber
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "Passport Number",
                                  this.props.state.form2.passportNumber,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 px-2 ">
                            <div className="form-group custom">
                              {/* <label>Place Of Issue</label> */}

                              <Input
                                label="Place Of Issue"
                                type="text"
                                name="placeOfIssue"
                                value={
                                  this.props.state.form2.placeOfIssue !==
                                    undefined
                                    ? this.props.state.form2.placeOfIssue
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "Place Of Issue",
                                  this.props.state.form2.placeOfIssue,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                              {/* <Select
                                                        placeholder={this.props.state.form2.placeOfIssue?this.props.state.form2.placeOfIssue:"Select"}
                                                        keyName={"placeOfIssue"}
                                                        options={this.props.state.placeIssueList}
                                                        controlFunc={e =>this.props.selectHandlerForm2(e)}
                                                        error={this.props.validator2.message(
                                                          "Place Of Issue",
                                                          this.props.state.form2.placeOfIssue,
                                                          "required"
                                                        )}
                                                      /> */}
                            </div>
                          </div>
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <label>Country Of Issue</label>
                              <div className="dropdown">
                                <Select
                                  placeholder={
                                    this.props.state.form2.countryOfIssue
                                      ? this.props.state.form2.countryOfIssue
                                      : "Select"
                                  }
                                  keyName={"countryOfIssue"}
                                  options={this.props.state.countriesIssueList}
                                  controlFunc={e =>
                                    this.props.selectHandlerForm2(e)
                                  }
                                  error={this.props.validator2.message(
                                    "countryOfIssue",
                                    this.props.state.form2.countryOfIssue,
                                    "required"
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col px-2">
                            <div className="form-group custom labelup">
                              <Input
                                label="Issue Authority"
                                type="text"
                                name="issueAuthority"
                                className="form-control"
                                value={
                                  this.props.state.form2.issueAuthority !==
                                    undefined
                                    ? this.props.state.form2.issueAuthority
                                    : ""
                                }
                                error={this.props.validator2.message(
                                  "issueAuthority",
                                  this.props.state.form2.issueAuthority,
                                  "required"
                                )}
                                controlFunc={e =>
                                  this.props.inputChangeHandlerForm2(
                                    e,
                                    "passport"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                format="DD MMM YYYY"
                                placeholder={this.props.state.form2.dateOfIssue?this.props.state.form2.dateOfIssue:'Select Date Of Issue'}
                                dayPickerProps={{ locale: 'en-gb', localeUtils: MomentLocaleUtils,disabledDays: { after: this.props.state.form2.expiryDate ? new Date(this.props.state.form2.expiryDate) : new Date() } }}
                                onDayChange={date =>
                                  this.props.handleDayChange(
                                    date,
                                    "form2",
                                    "dateOfIssue"
                                  )
                                }
                              />
                             {this.props.validator2.message(
                                  "dateOfIssue",
                                  this.props.state.form2.dateOfIssue,
                                  "required"
                                )}
                              <span className="icon-custom">
                              <i className="material-icons">calendar_today</i>
                              </span>
                              <label>Date of Issue</label>
                              
                            </div>
                          </div>
                          <div className="col-md-6 px-2">
                            <div className="form-group custom labelup">
                              <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                format="DD MMM YYYY"
                                dayPickerProps={{ locale: 'en-gb', localeUtils: MomentLocaleUtils,disabledDays: { before: this.props.state.form2.dateOfIssue && new Date(this.props.state.form2.dateOfIssue) } }}
                                placeholder={this.props.state.form2.expiryDate?this.props.state.form2.expiryDate:'Select Expiry Date'}
                                onDayChange={date =>
                                  this.props.handleDayChange(
                                    date,
                                    "form2",
                                    "expiryDate"
                                  )
                                }
                              />
                              {this.props.validator2.message(
                                  "expiryDate",
                                  this.props.state.form2.expiryDate,
                                  "required"
                                )}
                              <span className="icon-custom">
                              <i className="material-icons">calendar_today</i>
                              </span>
                              <label>Expiry Date</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="bg-light photocopy py-3 px-3">
                          <p className="mb-0">Passport Photocopy</p>
                        </div>
                        <div className="upload-input mb-3 text-center">
                          <input
                            type="file"
                            onChange={this.props.uploadImageHandler}
                          />
                          <div className="py-4 check-box">
                          <i className="material-icons">cloud_upload</i>
                            <div>Drag and Drop to Upload</div>
                          </div>
                          <small style={{ color: "red" }}>
                            {this.props.validator2.message(
                              "Passport Photocopy",
                              this.props.state.images[0],
                              "required"
                            )}
                          </small>
                        </div>
                        {this.props.state.images.map((data, index) => (
                          <img
                            src={data}
                            width="100"
                            height="100"
                            key={index}
                            className="m-3"
                            data-target="#hotelReceiptModal"
                            data-toggle="modal"
                            onClick={() =>
                              this.props.selectedImageHandler(
                                data,
                                index,
                                "images"
                              )
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="modal-last btn-group-margin">
                    <button
                      type="button"
                      className="btn btn-badge"
                      data-dismiss="modal"
                    >
                      Cancel
                  </button>
                    <button type="submit" className="btn  ">
                      Save
                      <i className="material-icons">arrow_right</i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div >
        <div
          className="modal fade"
          id="hotelReceiptModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="hotelReceiptModal"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-head" />
              <div className="modal-middle pm-30 bg-light">
                <div className="row">
                  <div className="col-md-8">
                    <div className="main-receipt">
                      <img
                        src={this.props.state.selectedImage}
                        className="img-fluid w-100"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-danger"
                    >
                      close
              </button>
                  </div>

                  <div className="col-md-4">
                    <div className="all-receipts">
                      <div className="row">
                        <div>
                          <div>
                            <button
                              type="button"
                              onClick={() => this.props.removePhotoCopy()}
                              data-dismiss="modal"
                              className="btn btn-danger"
                            >
                              <i className="material-icons">delete</i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default AddPassportModal;