import { Component } from "react";
import classNames from "classnames";
import { CabMiniInfoBar } from "./components/index";
import { GoogleMap } from "../components";
import { toast } from "react-toastify";
import { TripTypes } from "src/_constants";
import { Input } from 'src/_components/common/_forms';
import FullDetailsPage from "src/_components/pages/booking/flight/_components/full-details";
import {
  TripBarItem,
  TripBar,
  LocationSearch,
  TripTypeBar,
  CustomDatePicker,
  BookingSearchBar
} from "../components";
import { createLogger } from "src/_helpers";
import { inject, observer, propTypes as MobxPropTypes } from "mobx-react";
import PropTypes from "prop-types";
import cryptr from "src/_helpers/crypty";
import { cabSearch, cartServices, cabAddToCart, cabRemoveToCart } from "src/_services";
import { getValue } from "src/_components/common/lodsh";
/*:: import type { CabStore } from "src/_store"; */


class CabListPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cabRequest: JSON.parse(cryptr.decrypt(props.match.params.request)),
      listOfCabs: [],
      totalCount: 0,
      cardList: [],
      cartCount: 0,
      car_uniq_id: "",
      carAdding: false,
      vechileTypes: [
        { label: "Hatchback - economy", image: "/assets/images/hatchback_new.png" },
        { label: "Hatchback - premium", image: "/assets/images/hatchback_new.png" },
        { label: "Sedan - economy", image: "/assets/images/indigo_new.png" },
        { label: "Sedan - premium", image: "/assets/images/indigo_new.png" },
        { label: "SUV - economy", image: "/assets/images/xylo_new.png" },
        { label: "SUV - premium", image: "/assets/images/xylo_new.png" }
      ],
      cabArray: []//added for store updated cart of cab
    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getCabSearchRequest();
    this.getCartDetail();
    this.getCardList();
  }
  componentDidUpdate() {
    if (sessionStorage.modify !== undefined && sessionStorage.modify) {
      this.getCabSearchRequest();
      this.getCartDetail();
      this.getCardList();
      sessionStorage.removeItem("modify");
    }
  }

  getCabSearchRequest = async () => {
    let resp = await cabSearch(this.state.cabRequest)
    if (resp) {
      this.setState({
        listOfCabs: getValue(resp, "result", []),
        totalCount: getValue(resp, "count", 0)
      });
    }
  }
  getCartDetail = async () => {
    let tripID = sessionStorage.getItem('TRIPID');
    var resp = await cartServices.getCartListDetail(tripID);
    if (resp) {
      let count = getValue(resp, "data.flightData", []).length + getValue(resp, "data.hotelData", []).length + getValue(resp, "data.carData", []).length;
      let cabData = getValue(resp, "data.carData", []);
      this.setState({ cartCount: count, car_uniq_id: getValue(cabData[0], "car_uniq_id", "") });
      let cabArrayNew = []
      for (let i = 0; i < cabData.length; i++) {
        cabArrayNew.push(cabData[i].car_uniq_id)
      }
      this.setState({ cabArray: cabArrayNew })

    }
  };
  getCardList = async () => {
    let tripID = sessionStorage.getItem('TRIPID');

    var resp = await cartServices.getCartUserList(tripID);
    if (resp) {
      this.setState({ cardList: resp })
    }
  };
  removeToCart = async (uniqueId) => {
    let request = {
      "TRIP_ID": sessionStorage.getItem('TRIPID'),
      "type": "car",
      "car_uniq_id": uniqueId
    }
    let resp = await cabRemoveToCart(request);
    if (resp) {
      this.setState({ car_uniq_id: "" })
      this.getCartDetail();
      this.getCardList();
    }
  }

  addToCart = async (uniqueId) => {
    console.log(uniqueId)
    this.setState({ carAdding: uniqueId });
    let addPackage = this.state.cabRequest;
    addPackage.car_uniq_id = uniqueId;
    delete addPackage.car_booking_currency;
    delete addPackage.car_booking_price;
    let userId = JSON.parse(cryptr.decrypt(localStorage["440a24"]));
    let request = {
      TRIP_ID: sessionStorage.getItem('TRIPID'),
      type: "car",
      data: addPackage,
      userId: userId.id
    }


    // if (this.state.car_uniq_id == uniqueId) {
    //   this.removeToCart(uniqueId)
    // }
    if (this.state.cabArray.indexOf(uniqueId) !== -1) {
      this.removeToCart(uniqueId)
    }
    else {
      // if (this.state.car_uniq_id !== '') {
      //   this.removeToCart(uniqueId)
      // }
      if (this.state.cabArray.indexOf(uniqueId) !== -1) {
        this.removeToCart(uniqueId)
      }
      let resp = await cabAddToCart(request)
      if (resp) {
        this.setState({
          car_uniq_id: uniqueId
        });
        this.getCartDetail();
        this.getCardList();
      }
    }
    this.setState({ carAdding: '' })
  }

  render() {

    return (
      <div>
        <FullDetailsPage
          cartCount={this.state.cartCount}
          cardList={this.state.cardList}
          cabRequest={this.state.cabRequest}
          typeOfService="cab"
          type="cab" />

        <div class="flight-margin">
          <div className="p-padding">

            <div className="summary active">
              <div className="d-inline-block text-center">
                <p>
                  <i className="material-icons">
                    directions_car
              </i>
                </p>
              </div>
              <div className="d-inline-block">
                <p className="title">
                  {getValue(this.state.cabRequest, "car_pickup_location.city")} to {getValue(this.state.cabRequest, "car_drop_location.city")}
                </p>
                <p className="label">{this.state.listOfCabs.length === 0 ? "No" : this.state.totalCount} results found</p>
              </div>
            </div>
            <div className="clearfix"></div>




            <div className="row">
              {this.state.listOfCabs.length !== 0 ?
                <div className="col-md-10">
                  <div className="devision-container heading">
                    <div className="devision">
                      <div className="details main" style={{ "max-width": "20%" }}>
                        Car type
                  <span>
                          <i className="material-icons sort">arrow_drop_down</i>
                        </span>
                      </div>
                      <div className="details amenities-cab" style={{ "max-width": "20%" }}>Date and Time</div>
                      <div className="details date-cab">
                        From
                  <span>
                          <i className="material-icons sort">arrow_drop_down</i>
                        </span>
                      </div>
                      <div className="details date-cab">
                        To
                  <span>
                          <i className="material-icons sort">arrow_drop_down</i>
                        </span>
                      </div>
                      <div className="details price text-right">
                        Price
                  <span>
                          <i className="material-icons sort">arrow_drop_down</i>
                        </span>
                      </div>
                      <div className="clearfix" />
                    </div>
                  </div>
                  {this.state.listOfCabs.map((list, cabIndex) => {
                    let imageCar = this.state.vechileTypes.filter(resp => resp.label === list.car_type)
                    return (
                      <div className="devision-container" key={cabIndex}>
                        <div className="top-heading">
                          <p className="float-left show-white border-right-white">Car Details</p>
                          <p className="float-left show-white border-right-white">OS - {list.name}</p>
                          <div className="clearfix"></div>
                        </div>
                        <div className="devision">
                          <div className="abs-right abs-right-cab">
                            <i className="material-icons">arrow_drop_up</i>
                          </div>
                          <div>
                            <div>
                              <div className="div-pad cursor-pointer">
                                <div className="details main" style={{ "max-width": "20%" }}>
                                  <div className="name">
                                    <div className="logo-cab">
                                      <img src={getValue(imageCar[0], "image")} alt="car" />
                                    </div>
                                    <div className="info-cab pt-3">
                                      <p className="timings">
                                        <b>
                                          {getValue(this.state.cabRequest, "car_type")} </b>
                                      </p>
                                    </div>
                                    <div className="clearfix">
                                    </div>
                                  </div>
                                </div>
                                <div className="details amenities-cab p-more">
                                  <div className="pt-3">
                                    <b>

                                    </b>
                                  </div>
                                </div>
                                <div className="details amenities-cab p-more">
                                  <div className="pt-3">
                                    <b>10:30 PM</b>
                                  </div>
                                </div>
                                <div className="details date-cab p-more">
                                  <div className="pt-3">
                                    <b>{getValue(this.state.cabRequest, "car_pickup_location.city")} </b>
                                  </div>
                                </div>
                                <div className="details date-cab">
                                  <div className="pt-3">
                                    <b>{getValue(this.state.cabRequest, "car_drop_location.city")}</b>
                                  </div>
                                </div>
                                <div className="details price price-cab price-cab-top">
                                  <div className="flight-price ">
                                    <div className="cash">
                                      <span>
                                        <h5>{getValue(list, "car_package_details.currency")} {getValue(list, "car_package_details.price")}</h5>
                                        {/* <p>Per KM</p> */}
                                      </span>
                                    </div>
                                    <div className="d-button" onClick={() => this.addToCart(getValue(list, "car_uniq_id"))}>
                                      <i className="material-icons">
                                        {
                                          this.state.cabArray.indexOf(getValue(list, "car_uniq_id")) === -1
                                            ? "chevron_right"
                                            : "done"}
                                        {/* above line Added for display icons icons based on condition */}
                                            chevron_right
                                        </i>

                                      {(this.state.carAdding === getValue(list, "car_uniq_id")) ?
                                        //  "Adding" : (this.state.carAdding === '' && this.state.car_uniq_id === getValue(list, "car_uniq_id")) ?
                                        "Adding" : (this.state.cabArray.indexOf(getValue(list, "car_uniq_id")) !== -1) ?
                                          "Added" : "Add"}
                                    </div>
                                  </div>
                                </div>
                                <div className="clearfix">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </div>
                :
                <div className="col-md-10">
                  <div className="devision-container">
                    No cab found that matches your filter criteria
                </div>
                </div>
              }
              <div className="col-md-2">
                <div id="filter-card" className="filter-card">
                  <button
                    id="filter-toggle"
                    className="filter-toggle"
                  >
                    <i className="material-icons">close</i>
                  </button>
                  {/* <h6 className="heading grey">Why Book With Us</h6> */}

                  <div className="cab-filter">
                    <div className="heading">
                      {/* <h6 className="mb-0"><b>Free Cancellation</b></h6> */}
                      <h6 className="mb-0"><b>Why Book With Us</b></h6>
                    </div>
                    <div className="bg-grey">
                      <ul style={{ "border": "transparent" }}>
                        <li><b>Free Cancellation</b></li>
                        <li>Free Cancellation before 18 Jun 2020, 11:30 AM IST </li>
                      </ul>
                    </div>
                    <div className="bg-grey">
                      <ul style={{ "border": "transparent" }}>
                        <li><b>Pay 15% to book</b></li>
                        <li>Pay 15% now & rest to driver or pay full amount online.</li>
                      </ul>
                    </div>
                    <div className="bg-grey">
                      <ul style={{ "border": "transparent" }}>
                        <li><b>Best Prices</b></li>
                        <li>No need to compare on other sites, we offer best!</li>
                      </ul>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export { CabListPage };
