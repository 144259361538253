import ConnectedListsPage from "./connected-lists-html";
import React, { Component } from "react";
import { companyAdmin } from "../../../../../../_services/companyAdmin";
import { masterService } from "../../../../../../_services";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { alertConstants } from "../../../../../../_constants";
import $ from "jquery";
class ConnectedLists extends Component {
  constructor(props) {
    super(props);
    this.validList = new SimpleReactValidator();
    this.validFeild = new SimpleReactValidator();
    this.validConfig = new SimpleReactValidator();
    this.state = {
      serviceModuleId: "",
      formHeaderId: "",
      companyId: localStorage["440a28"],
      listManagementId: "",
      fieldData: [],
      listName: "",
      level: "",
      selectedFeilds:"",
      connectListLoading:false,
    };
  }
  enableInput(){
    $("#connectedL").removeAttr("disabled");
  }
  type = 1;
  componentDidMount() {
    this.loading = true;
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getService();
    this.listManagement();
  }
  currentModule = "";
  changeModule = type => {
    sessionStorage.removeItem('F12V2')
    this.setState(
      {
        serviceModuleId: type
      },
      () => {
        this.currentModule = type;
        localStorage.setItem("serviceModule", type);
        this.getFormHeader();
      }
    );
  };
  listService = [];
  getService = async () => {
    var resp = await masterService.getServiceModuleListFl();
    if (resp) {
      this.listService = resp.result;
      if (localStorage.serviceModule != undefined) {
        this.setState({
          serviceModuleId: localStorage.serviceModule
        });
        this.currentModule = localStorage.serviceModule;
      } else {
        this.setState({
          serviceModuleId: resp.result && resp.result[0].id
        });
        this.currentModule = resp.result && resp.result[0].id;
      }
      this.forceUpdate();
      this.getFormHeader();
    }
  };
  formHeaderList = [];
  selectedFormHeader = "Select Form Header";
  getFormHeader = () => {
    companyAdmin.getFormHeader(this.state.serviceModuleId).then(res => {
      this.formHeaderList = res.result && res.result;
      this.selectedFormHeader = res.result && res.result[0].formHeaderName;
      if(sessionStorage['F12V2'] !== undefined){
        res.result.forEach(item=>{
          if(item.id === sessionStorage['F12V2']){ 
            this.selectedFormHeader = item.formHeaderName
          }
        })
      }  
      this.setState(
        { 
          formHeaderId: (sessionStorage['F12V2'])? sessionStorage['F12V2'] : res.result && res.result[0].id 
        },
        () => {
          if (res) {
            this.getConnectedList();
            this.getFields();
            // this.setSession();
          }
        }
      );
    });
  };
  // popup pre defined data collecting
  manageList = [];
  listManagement = async () => {
    this.setState({
      connectListLoading:true
    })
    var resp = await masterService.getListManagement(this.state.companyId);
    if (resp) {
      this.manageList = resp.result;
    }
    this.setState({
      connectListLoading:false
    }) 
  };
  fieldList = [];
  fieldPage = 1;
  fieldItem = 100;
  fieldCount = 0;
  hasMore = true;
  getFields = async () => {
    var req = {
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      isFieldForConnectedList: true,
      companyId: this.state.companyId,
      page: this.fieldPage,
      limit: this.fieldItem,
      sort: "asc"
    };
    var resp = await masterService.getFieldList(req);
    if (resp) { 
      this.fieldCount = resp.count;
      this.fieldList = resp.result;
    }
    this.forceUpdate();
  };
  fetchMoreData = () => { 
    if (this.fieldList.length >= this.fieldCount) {
      this.hasMore = false;
      this.forceUpdate();
      return;
    }
    this.fieldPage = this.fieldPage + 1;
    this.getFields();
  };
  next = () => {
    if (this.type <= 2) {
      this.type = this.type + 1;
      this.forceUpdate();
    }
  };
  prev = () => {
    if (this.type > 1) {
      this.type = this.type - 1;
      this.forceUpdate();
      $("#connectedL").attr("disabled","disabled");
    }
  };
  handleHeader = e => {
    this.selectedFormHeader = e.formHeaderName;
    sessionStorage.setItem('F12V2',e.id)   
    this.setState({
      formHeaderId: e.id
    }, () => {
      this.getConnectedList();
      this.getFields();
    });

  };
  selectedList = [];
  selectedListItem = "";
  levelOneOpt = e => {
    if (this.selectedList === e) {
      this.selectedList = "";
      this.selectedList = [];
      this.setState({
        listManagementId: ""
      });
    } else {
      this.selectedList = e;
      this.selectedListItem = e.id;
      this.setState({
        listManagementId: e.id,
        level: e.childCount
      });
    }
    this.forceUpdate();
  };
  updateList = async () => {
    if (this.type === 1) {
      if (this.validList.allValid()) {
        this.maxLevel = this.selectedList.childCount;
        this.next();
      } else {
        this.validList.showMessages();
        this.forceUpdate();
      }
    } else if (this.type === 2) {
      if (this.validFeild.allValid()) {
        this.choosedFeilds = [];
        this.selectedFeilds.forEach((item, key) => {
          this.choosedFeilds.push({
            content: (
              <div>
                <button className="">
                  <p className="float-right">Level {key + 1}</p>
                  <h6>{item.fieldName} ({item.columnName} {item.labelName}) </h6>
                </button>
              </div>
            ),
            fieldId: item.id
          });
        });
        this.next();
        $("#connectedL").attr("disabled","disabled");
      } else {
        this.validFeild.showMessages();
        this.forceUpdate();
      }
    } else if (this.type === 3) {
      if (this.validConfig.allValid()) {
        var req = [];
        await this.choosedFeilds.forEach((item, key) => {
          req.push({ fieldLevel: key + 1, fieldId: item.fieldId });
        });
        this.setState(
          {
            fieldData: req
          },
          () => {
            this.handleSubmit();
          }
        );
      } else {
        this.validConfig.showMessages();
        this.forceUpdate();
      }
    }
  };
  handleSubmit = async () => {
    var resp = await companyAdmin.createConnectedList(this.state);
    if (resp) {
      toast.success(alertConstants.CREATED_SUCCESSFULLY);
      $(".close").click();
      this.reset();
      this.getService()
    }
    this.forceUpdate();
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  page = 1;
  limit = 10;
  count = 0;
  loading = false;
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
  connectedList = [];
    choosedFeilds = [];
  selectedFeilds = [];
  fieldItemId = [];
  maxLevel = 0;
  getConnectedList = async () => {
    this.connectedList = [];
    this.count = 0;
    var resp = await companyAdmin
      .getConnectedList(
        this.page,
        this.limit,
        this.state.serviceModuleId,
        this.state.formHeaderId,
        this.state.companyId
      )
      .catch(e => {
        this.loading = false;
        this.forceUpdate();
      });
    this.loading = false;
    if (resp) {
      this.count = resp.count;
      this.connectedList = resp.result;
    }
    this.forceUpdate();
  };
  handleItemChange = limit => {
    this.page = 1;
    this.items = limit["items"];
    this.getCompany();
  };
  handlePageChange = pageNumber => {
    this.page = pageNumber;
    this.getCompany();
  };



  handleFeild = e => {
    const index = this.selectedFeilds.indexOf(e);
    if (index === -1) {
      if (this.maxLevel > this.selectedFeilds.length) {
        this.selectedFeilds.push(e);
        this.fieldItemId.push(e.id);
      } else {
        toast.error("You reached the max limit");
        return;
      }
    } else {
      this.selectedFeilds.splice(index, 1);
      this.fieldItemId.splice(index, 1);
    }
    if(this.selectedFeilds.length === 0){
      this.setState({
        selectedFeilds:""
      })
    }else{
      this.setState({selectedFeilds:this.selectedFeilds})
    } 
  };


  onSort = e => {
    this.choosedFeilds = e;
    this.forceUpdate();
  };


  reset = () => {
    this.setState({
      serviceModuleId: "",
      formHeaderId: "",
      companyId: localStorage["440a28"],
      listManagementId: "",
      fieldData: [],
      listName: "",
      level: ""
    });
    this.type = 1;
    this.forceUpdate();
  };

  handleStatusChange = async e => {
    let newStatus = !e.activeStatus;
    let status = {
      activeStatus: newStatus,
      companyId: this.state.companyId
    };
    var resp = await companyAdmin.connectedListStatusChange(e.id, status);
    if (resp) {
      toast.info(resp.message)
      this.getConnectedList()
    }
  };

  // * set default handler
  setDefaultHandler = (item, e) => {
    // console.log("the row data is ", item);
    // console.log("the value of e is ", e);
  }

  // * End default Handler
  render() {
    return (
      <ConnectedListsPage
        currentModule={this.currentModule}
        state={this.state}
        changeModule={this.changeModule}
        listService={this.listService}
        next={this.next}
        prev={this.prev}
        type={this.type}
        manageList={this.manageList}
        reset={this.reset}
        formHeaderList={this.formHeaderList}
        handleHeader={this.handleHeader}
        levelOneOpt={this.levelOneOpt}
        fieldList={this.fieldList}
        validConfig={this.validConfig}
        validFeild={this.validFeild}
        selectedFormHeader={this.selectedFormHeader}
        updateList={this.updateList}
        validList={this.validList}
        handleChange={this.handleChange}
        connectedList={this.connectedList}
        count={this.count}
        choosedFeilds={this.choosedFeilds}
        selectedListItem={this.selectedListItem}
        loading={this.loading}
        page={this.page}
        handleStatusChange={this.handleStatusChange}
        limit={this.limit}
        onSort={this.onSort}
        pageItemsList={this.pageItemsList}
        handleItemChange={this.handleItemChange}
        handlePageChange={this.handlePageChange}
        handleFeild={this.handleFeild}
        fieldItem={this.fieldItemId}
        enableInput={e => this.enableInput()}
        setDefaultHandler={this.setDefaultHandler}
        validFeild={this.validFeild}
      />
    );
  }
}
export default ConnectedLists;
