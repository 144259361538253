import React, { Component } from "react";
import CarConfigPage from "./car-configuration-html";
import { carConfigService } from "src/_services/carConfigService";
import $ from "jquery";
import { toast } from "react-toastify";

class CarConfig extends Component {
  state = {
    companyId: localStorage["440a28"],
    configsData: [],
    search: '',
    page: 1,
    removeButton: false,
    removeItem: "",
    activePage: 1,
    totalItemsCount: 0,
    itemsCountPerPage: 10,
  }
  componentDidMount = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.carConfigData();
  }
  carConfigData = async () => {
    let res = await carConfigService.carConfigData(this.state.companyId, this.state.totalItemsCount, this.state.page);
    if (res) {
      this.setState({ configsData: res.result, totalItemsCount: res.count })
    }
  }
  onStatusChangeHandler = async (id, activeStatus) => {
    let status = {
      activeStatus: activeStatus
    }
    let res = await carConfigService.onStatusChangeHandler(id, status);
    if (res) {
      toast.success(res.message);
      this.carConfigData();
    }
  }
  onSearchTextChangeHandler = async (event) => {
    let search = event.target.value;
    if (search !== '') {
      await this.setState({ search: event.target.value });
      let response = await carConfigService.carConfigsSearch(this.state.companyId, search);
      if (response.count > 0) {
        this.setState({
          configsData: response.result,
          totalCount: response.count
        });
      } else {
        this.setState({
          configsData: []
        });
      }
    } else {
      this.setState({ search: '' });
      this.carConfigData();
    }
  }
  removeItemHandler = async () => {
    let deleteId = this.state.removeItem;
    var res = await carConfigService.removeItemHandler(deleteId);
    if (res) {
      toast.success(res.message);
      this.carConfigData();
      this.setState({ removeItem: "", removeButton: false });
    }
  };
  onDeleteHandler = item => {
    let removeItem = this.state.removeItem;
    if (removeItem === item) {
      this.setState({ removeItem: "", removeButton: false });
    } else {
      this.setState({ removeItem: item, removeButton: true });
    }
  };
  handleLimitChange = (limit) => {
    this.setState({
      totalCount: limit
    }, () => {
      this.carConfigData();
    })
  }
  // Pagination - change page on click on pagination controls
  handlePageChange = (pageNumber) => {
    this.setState({
      activePage: pageNumber
    }, () => {
      this.carConfigData();
    })

  }

  render() {
    return (
      <div>
        <CarConfigPage
          {...this.state}
          itemsCountPerPage={this.state.itemsCountPerPage}
          activePage={this.state.activePage}
          totalItemsCount={this.state.totalItemsCount}
          onStatusChangeHandler={this.onStatusChangeHandler}
          onSearchTextChangeHandler={this.onSearchTextChangeHandler}
          removeItemHandler={this.removeItemHandler}
          onDeleteHandler={this.onDeleteHandler}
          handleLimitChange={this.handleLimitChange}
          handlePageChange={this.handlePageChange}
        />
      </div>
    );
  }
}
export default CarConfig;
