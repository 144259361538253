import React from 'react';

const recallConfirmModal=(props)=>{
    return(
        <div
        className="modal fade"
        id="recallExpenseEntry"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="recallExpenseEntry"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close text-white d-none"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <div className="text-center pt-5">
                <img src="/assets/images/confirmation_icon.svg" alt="" />
                <h3 className=" mt-4 text-dark">Are You Sure?</h3>
                <p>By clicking on recall, you agree to the <br />expense policy and restrictions.</p>
              </div>
            </div>
            <div className="btn-group-margin text-center pb-5">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                No
              </button>
              {
                !props.isRecallClick ?
                  <button
                  onClick={props.onRecallExpenseHandler}
                  className="btn"
                  data-dismiss="modal"
                   >Recall</button>
                  : <button className="btn" >Please Wait...</button>
              }
            </div>
          </div>
        </div>
      </div>
    )
}
export default recallConfirmModal;
