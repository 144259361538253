import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import FormsMainPage from "./forms";
import FormsFeildsMainPage from "./forms-fields";
import FeildsMainPage from "./feilds";
import ConnectedMainPage from "./connected-list"; 

class FFMainPage extends Component {
  componentDidMount() {
    if (
      this.props.location.pathname === "/administration/company/admin-forms"
    ) {
      this.props.history.push("/administration/company/admin-forms/forms");
    } else {
      this.props.history.push(this.props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (
      this.props.location.pathname === "/administration/company/admin-forms"
    ) {
      this.props.history.push("/administration/company/admin-forms/forms");
    }
  }
  render() {
    return (
      <div className="FFMainPage">
        <Switch>
          <Route
            path="/administration/company/admin-forms/forms"
            component={FormsMainPage}
          />
          <Route
            path="/administration/company/admin-forms/forms-fields"
            component={FormsFeildsMainPage}
          />
          <Route
            path="/administration/company/admin-forms/fields"
            component={FeildsMainPage}
          />{" "}
          <Route
            path="/administration/company/admin-forms/connected-lists"
            component={ConnectedMainPage}
          />
        </Switch>
      </div>
    );
  }
}
export default FFMainPage;
