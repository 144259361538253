import React,{Component} from 'react';
import moment from 'moment';
class AvailableCashadvnace extends Component{
    render(){
      // console.log(this.props.allAdvanceRequest)
        return(
            <div
              className="modal fade"
              id="cashAdvAvailableModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="cashAdvAvailableLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-head">
                    <h6 className="modal-subtitle">View</h6>
                    <h5 className="modal-title" id="cashAdvAvailableLabel">
                      Cash Advances - Available
                    </h5>
                  </div>
                  <div className="modal-middle mt-5">
                    <div className="table-responsive">
                      <table className="table table-striped border">
                        <thead>
                          <tr>
                            <th />
                            <th>CASH ADVANCE NAME</th>
                            <th>REQUESTED DATE</th>
                            <th>AMOUNT</th>
                            {/* <th>EXCHANGE RATE</th> */}
                            <th>AVAILABLE BALANCE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.allAdvanceRequest &&
                            this.props.allAdvanceRequest.map((result, index) => {
                              let theadArray = [];
                              theadArray = result.data.map(
                                header => header.columnName
                              );
                              let CASH_ADVANCE_NAME = theadArray.indexOf(
                                "CASH_ADVANCE_NAME"
                              );
                              let COMMENT = theadArray.indexOf("COMMENTS");
                              let AMOUNT = theadArray.indexOf("AMOUNT");
                              return (
                                <tr key={index}>
                                  <td className="modal-px-ly py-3">
                                    <label className="checkbox-container m-0">
                                      <input
                                        type="checkbox"
                                        onChange={e =>
                                          this.props.onCashAdvanceSelectHadnler(result)
                                        }
                                        checked={this.props.selectedCashAdvanceReceiptIds.includes(
                                          result.id
                                        )}
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </td>
                                  <td className="text-primary">
                                    {result.data[CASH_ADVANCE_NAME] &&
                                      result.data[CASH_ADVANCE_NAME].fieldValue}
                                  </td>

                                  <td>
                                    {/* {result.data[index] &&
                                      result.data[index].createdAt &&
                                      moment(result.data[index].createdAt).format(
                                        "DD MMM YYYY"
                                      )} */}
                                       {result.createdAt &&
                                      result.createdAt &&
                                      moment(result.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                  </td>

                                  <td>
                                    {result.issuedCurrency}
                                    &nbsp;
                                    {(new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(result.amountIssued))}
                                  </td>
                                  {/* <td></td> */}
                                  <td>
                                    {result.issuedCurrency}
                                    &nbsp;
                                    {(new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(result.balance))}
                                  </td>
                                </tr>
                              );
                            })}
                          {this.props.allAdvanceRequest.length !== 0 ? null : (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan={5}>
                                No Records Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="modal-last btn-group-margin pm-30 ">
                        <button
                          data-dismiss="modal"
                          disabled={
                            this.props.selectedCashAdvanceReceiptIds.length <= 0
                          }
                          onClick={this.props.onCashAdvanceAttachHandler}
                          type="button"
                          className="btn"
                        >
                          ASSIGN CASH ADVANCE TO REPORT
                        </button>
                        <button
                          type="button"
                          className="btn"
                          data-dismiss="modal"
                        >
                          CLOSE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>)
    }
}
export default AvailableCashadvnace;
