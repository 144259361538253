import {http} from '../_helpers';
import { config } from '../env';

const getPlaces = (search_text) => {
  // let url = 'https://jsonplaceholder.typicode.com/users';
  let url = `https://cors.io/?https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${search_text}&key=AIzaSyBcZqWoshw_ctUGl2nJeK2o7tjprJLgpMg`
  return http.get(url)
    .then(res => res)
}

const getAllHotels = (payload) => {
  let url = `${config.apiUrlTravel}hotel/search`;
  return http.post(url, payload)
  .then(res => res)
}

const getIndividualData = (hotelId, hotelName, trip_duration) => {
  let url = `${config.apiUrlTravel}hotel/info?hotel_id=${hotelId}&hotel_name=${hotelName}&trip_duration=${trip_duration}`;
  return http.get(url)
    .then(res => res)

}

export const hotelService = {
  getAllHotels,
  getPlaces,
  getIndividualData
}