import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { TripBar } from "../../components";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { NavLink, withRouter } from "react-router-dom";
import CabSearchInput from "./RequestType/CabSearchInput";
import LocalStation from "./RequestType/Local";
import AirportCabStation from "./RequestType/VechileType";
import { setValue, getValue } from "src/_components/common/lodsh";
import customData from './RequestType/cabSetup';
import { bookingServices } from "src/_services/booking.service";
import cryptr from "src/_helpers/crypty";
import $ from "jquery";
import { masterService } from "src/_services";
class AddCar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      type: "0",
      loadInput: "",
      listOfCurrency: [],
      returnDateIsRequired: false,
      typeOfCab: customData,
      vechileTypes: [
        { label: "Hatchback - economy" },
        { label: "Hatchback - premium" },
        { label: "Sedan - economy" },
        { label: "Sedan - premium" },
        { label: "SUV - economy" },
        { label: "SUV - premium" }
      ],
      tripTypes: [
        { label: "Oneway", value: 0 },
        { label: "Round trip", value: 1 },
        { label: "Airport Pickup", value: 2 },
        { label: "Airport Drop", value: 3 },
      ],
      tripTypesOutStation: [
        { label: "Oneway", value: 0 },
        { label: "Round trip", value: 1 }
      ],
      tripTypesAirport: [
        { label: "Airport Pickup", value: 2 },
        { label: "Airport Drop", value: 3 }
      ],
      popularCity: [
        { lat: "12.9716", lng: "77.5946", name: "Bengaluru, Karnataka, India", cityName: "Bangalore", country: "IN" },
        { lat: "13.0827", lng: "80.2707", name: "Chennai, TamilNadu, India", cityName: "Chennai", country: "IN" },
        { lat: "17.3850", lng: "78.4867", name: "Hyderabad, Telangana, India", cityName: "Hyderabad", country: "IN" },
        { lat: "18.5204", lng: "73.8567", name: "Pune, Maharashtra, India", cityName: "Pune", country: "IN" },
        { lat: "15.2993", lng: "74.1240", name: "Goa, India", cityName: "Goa", country: "IN" }
      ],
      isSubmitted: false,
      cabRequest: props.cabRequest ? props.cabRequest : this.intiCab
    }
  }
  intiCab = {
    car_rental_type: 0,
    car_ride_type: '',
    car_pickup_location: {
      address: "",
      city: ""
    },
    car_pickup_geo: {
      latitude: "",
      longitude: ""
    },
    car_pickup_timestamp: "",
    car_drop_location: {
      address: "",
      city: ""
    },
    car_drop_geo: {
      latitude: "",
      longitude: ""
    },
    car_return_timestamp: "",
    car_booking_type: localStorage.getItem("440a28") === "5dc5197e1382a2538aa3a8ca" ? 2 : 0,
    car_type: "",
    car_booking_price: "",
    car_booking_currency: ''
  }
  componentWillReceiveProps() {
    // console.log(this.props)
  }

  enableInput = (value) => {
    let updatedValue = '';
    this.state.loadInput === value ? updatedValue = '' : updatedValue = value
    this.setState({ loadInput: updatedValue })
  }

  handleSelectedValue = (e, path) => {
    setValue(this.state.cabRequest, path, e);
    this.setState({ ...this.state });
    if ((getValue(this.state.cabRequest, "car_rental_type") == 1 || getValue(this.state.cabRequest, "car_ride_type") === 1)) {
      this.setState({
        returnDateIsRequired: this.state.cabRequest.car_return_timestamp ? false : true
      })
    } else {
      this.setState({
        returnDateIsRequired: false
      })
    }
  }

  isValidCheck = () => {
    const { car_rental_type, car_booking_type, car_type, car_ride_type, car_pickup_location, car_drop_location } = this.state.cabRequest;
    if (car_rental_type !== '' && car_booking_type !== '' && car_type !== '' && car_ride_type !== '' && this.isEmpty([car_pickup_location]) && this.isEmpty([car_drop_location]) && !this.state.returnDateIsRequired && this.isRequestTypeValid()) {
      return true;
    } else {
      return false;
    }
  }

  isRequestTypeValid() {
    const { car_booking_currency, car_booking_price, car_booking_type } = this.state.cabRequest;
    if (car_booking_type === 0) {
      if (car_booking_price !== '' && car_booking_currency !== '') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  handleSearchCab = async () => {
    this.setState({ isSubmitted: true })
    if (this.isValidCheck()) {
      if (!sessionStorage.TRIPID) {
        let newReq = await bookingServices.getTripId();
        sessionStorage.setItem("TRIPID", newReq.TRIP_ID);
      } else {
        sessionStorage.setItem("modify", true);
        $(".close").click();
      }
      var resp = cryptr.encrypt(JSON.stringify(this.state.cabRequest));
      this.props.history.push("/booking/cab/find-cab/" + resp);
    } else {
      return false;
    }
  }

  handleAmount = (e) => {
    setValue(this.state, "cabRequest.car_booking_price", e.target.value)
    this.setState({
      ...this.state
    });
  }
  getCurrencyCode = async () => {
    var companyId = localStorage["440a28"];
    var resp = await masterService.getGroups(companyId, "CUR001");
    if (resp) {
      let customCurrency = [];
      resp.result.forEach(item => {
        customCurrency.push({ value: item.itemName, label: item.itemCode })
      })
      this.setState({
        listOfCurrency: customCurrency
      })
    }
  };
  changeCurrency = (e) => {
    setValue(this.state, "cabRequest.car_booking_currency", e.label)
    this.setState({
      ...this.state
    })
  };
  isEmpty = arr => {
    let arrValues = arr.map(item => {
      return [...Object.values(item)];
    });
    var myNewArray = [].concat(...arrValues);
    if (myNewArray.indexOf("") === -1 && myNewArray.indexOf(null) === -1) {
      return true;
    } else {
      return false;
    }
  };

  locationFromRef = React.createRef();
  locationToRef = React.createRef();
  componentDidMount() {
    this.getCurrencyCode();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = e => {
    const locationFromPopup = this.locationFromRef.current;
    const locationToPopup = this.locationToRef.current;
    const { loadInput } = this.state;
    if (loadInput === "fromLoc" && this.locationFromRef && !locationFromPopup.contains(e.target)) {
      this.setState({ loadInput: '' })
      return;
    } else if (loadInput === "toLoc" && this.locationToRef && !locationToPopup.contains(e.target)) {
      this.setState({ loadInput: '' })
      return;
    }
  };
  handleLocationSelection = async (e, type, requestType) => {
    if (type === 1) {
      this.updateLocationDetails(requestType, e)
    } else if (type === 2) {
      if (requestType === 1) {
        setValue(this.state, "cabRequest.car_pickup_location.city", e);
      } else if (requestType === 2) {
        setValue(this.state, "cabRequest.car_drop_location.city", e);
      }
      this.setState({ ...this.state });
    } else if (type === 3) {
      geocodeByAddress(e)
        .then(results => {
          return getLatLng(results[0]);
        })
        .then((response) => {
          let cityName = this.getCityName(e);
          let data = {
            name: e,
            cityName: cityName,
            lat: response.lat,
            lng: response.lng
          }
          this.updateLocationDetails(requestType, data)
        })
        .catch(error => console.error("Error", error));
    }
  }
  getCityName = (results) => {
    if (results) {
      let splitFormattedAddress = results.split(',');
      if (splitFormattedAddress && splitFormattedAddress.length)
        return splitFormattedAddress[0];
    } else {
      return '';
    }
  }
  updateLocationDetails = (req, e) => {
    if (req === 1) {
      setValue(this.state, "cabRequest.car_pickup_location.address", e.name);
      setValue(this.state, "cabRequest.car_pickup_location.city", e.cityName);
      setValue(this.state, "cabRequest.car_pickup_geo.latitude", e.lat);
      setValue(this.state, "cabRequest.car_pickup_geo.longitude", e.lng);
    } else if (req === 2) {
      setValue(this.state, "cabRequest.car_drop_location.address", e.name);
      setValue(this.state, "cabRequest.car_drop_location.city", e.cityName);
      setValue(this.state, "cabRequest.car_drop_geo.latitude", e.lat);
      setValue(this.state, "cabRequest.car_drop_geo.longitude", e.lng);
    }
    this.setState({ loadInput: "" });
  }
  handleDate = (value, name) => {
    setValue(this.state, `cabRequest.${name}`, value);
    if (name === "car_return_timestamp") {
      setValue(this.state, "returnDateIsRequired", false);
    }
    this.setState({ ...this.state });
  }
  handleCabSection = (value) => {
    setValue(this.state, "cabRequest.car_booking_type", value)
    this.setState({
      type: value
    })
  }
  render() {
    const { type, typeOfCab, vechileTypes, cabRequest, tripTypes, popularCity, loadInput,tripTypesOutStation,tripTypesAirport } = this.state;
    return (
      <div>
        {localStorage.getItem("440a28") !== "5dc5197e1382a2538aa3a8ca" &&
          <ul class="trip-way">
            <li>
              <button
                type="button"
                className={this.state.type == 0 ? "btn active" : "btn"}
                onClick={() => this.handleCabSection(0)}
              >
                Request
             </button>
            </li>
            <li>
              <button
                type="button"
                className={this.state.type == 1 ? "btn active" : "btn"}
                onClick={() => this.handleCabSection(1)}
              >
                Online
             </button>
            </li>

          </ul>
        }


        <div className="mb-3">

          {this.state.cabRequest.car_booking_type !== 2 ?
            <div>
              {typeOfCab.map((item, indexKey) => (
                <label key={indexKey} className="radio-container ml-1 cab-radio text-dark">
                  {item.label}
                  <input
                    type="radio"
                    checked={(getValue(cabRequest, "car_rental_type", 0) == item.value) ? true : false}
                    name="radio1"
                    value={item.value}
                    onChange={(e) => this.handleSelectedValue(e.target.value, "car_rental_type")}
                  />
                  <span className="checkmark" />
                </label>
              ))
              }
            </div>
            : ""}

          <CabSearchInput
            cabType={type}
            permission={getValue(typeOfCab[0], "initAttr")}
            vechileTypes={vechileTypes}
            request={cabRequest}
            popularCity={popularCity}
            tripTypes={tripTypes}

            tripTypesOutStation={tripTypesOutStation}
            tripTypesAirport={tripTypesAirport}

            loadInput={loadInput}
            enableInput={this.enableInput}
            handleSelectedValue={this.handleSelectedValue}
            handleLocationSelection={this.handleLocationSelection}
            locationFromRef={this.locationFromRef}
            locationToRef={this.locationToRef}
            listOfCurrency={this.state.listOfCurrency}
            handleDate={this.handleDate}
            isSubmitted={this.state.isSubmitted}
            returnDateIsRequired={this.state.returnDateIsRequired}
            requestType={this.state.cabRequest.car_booking_type}
            handleAmount={this.handleAmount}
            changeCurrency={this.changeCurrency}

            carPickupDate={this.state.cabRequest.car_pickup_timestamp}
          />
          {this.state.cabRequest.car_booking_type !== 2 && <>
            <button
              className="btn btn-blue details search" onClick={() => this.handleSearchCab()} style={{ "maxWidth": "4%" }}
            >
              <i className="material-icons">search</i>
            </button>
            <div className="clearfix"></div></>}
        </div>
      </div>
    );
  }
}

export default withRouter(AddCar);
