import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
class AddNewAttendee extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      firstName: "",
      lastName: "",
      attendeeTitle: "",
      company: "",
      bussinessGuest: "",
      companyName: "",
      companyId: localStorage["440a28"]
    };
  }
  onBusinnessHandler = bussinessGuest => {
    this.setState({ bussinessGuest: bussinessGuest });
  };
  onFirstNameHandler = event => {
    this.setState({ firstName: event.target.value });
  };
  onLastNameHandler = event => {
    this.setState({ lastName: event.target.value });
  };
  onAttendeeHandler = event => {
    this.setState({ attendeeTitle: event.target.value });
  };
  onCompanyHandler = event => {
    this.setState({ companyName: event.target.value });
  };
  onSubmitHandler = event => {
    event.preventDefault();
  };
  onSearchHandler = event => {};
  onSelectHandler = event => {};
  render() {
    return (
      <div
        className="modal fade"
        id="addnewAttendeeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addnewAttendeeLable"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h6 className="modal-subtitle">New</h6>
              <h5 className="modal-title" id="addnewAttendeeLable">
                Add New Attendee
              </h5>
            </div>
            <div className="attendee-search bg-light pm-30">
              <div className="search-custom  ">
                <input
                  type="text"
                  onChange={this.onSearchHandler}
                  name=""
                  className="form-control bg-light"
                  placeholder="Search"
                />
                <span>
                <i className="material-icons">search</i>
                </span>
              </div>
            </div>
            <div className="attendee-or">
              <span className="px-2 py-1 text-center bg-white">OR</span>
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom labelup">
                <label>Attendee Type</label>
                <div className="dropdown">
                  <button
                    onSelect={this.onSelectHandler}
                    type="button"
                    className="btn dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    Business Guest
                  </button>
                  <div className="dropdown-menu">
                    <button
                      onSelect={this.onSelectHandler}
                      className="dropdown-item"
                    >
                      Mr.
                    </button>
                    <button
                      onSelect={this.onSelectHandler}
                      className="dropdown-item"
                    >
                      Mrs.
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group custom">
                <input
                  value={this.state.firstName}
                  onChange={this.onFirstNameHandler}
                  type="text"
                  name="firstName"
                  placeholder="&nbsp;"
                  className="form-control"
                />
                <label>First Name</label>
                {this.validator.message(
                  "firstName",
                  this.state.firstName,
                  "required|alpha"
                )}
              </div>
              <div className="form-group custom">
                <input
                  onChange={this.onLastNameHandler}
                  name="lastName"
                  value={this.state.lastName}
                  type="text"
                  placeholder="&nbsp;"
                  className="form-control"
                />
                <label>Last Name</label>
                {this.validator.message(
                  "lastName",
                  this.state.lastName,
                  "required|alpha"
                )}
              </div>
              <div className="form-group custom">
                <input
                  onChange={this.onAttendeeHandler}
                  name="attendeeTitle"
                  type="text"
                  value={this.state.attendeeTitle}
                  placeholder="&nbsp;"
                  className="form-control"
                />
                <label>Attendee Title</label>
                {this.validator.message(
                  "attendeeTitle",
                  this.state.attendeeTitle,
                  "required|alpha"
                )}
              </div>
              <div className="form-group custom textarea">
                <input
                  onChange={this.onCompanyHandler}
                  name="companyName"
                  value={this.state.companyName}
                  type="text"
                  placeholder="&nbsp;"
                  className="form-control"
                />
                <label>Compnay</label>
                {this.validator.message(
                  "companyName",
                  this.state.companyName,
                  "required|alpha"
                )}
              </div>

              <div className="modal-last btn-group-margin">
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  onClick={this.onSubmitHandler}
                  type="submit"
                  className="btn"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddNewAttendee;
