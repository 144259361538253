import React, { Component } from "react";
import { expenseTravelAllowanceServise } from "../../../../../../_services";
import { toast } from "react-toastify";
class TableRow extends Component {
  state = {
    activeStatus: "",
    allowanceCalculationType: [
      {
        label: 'Time of Day',
        value: 0
      },
      {
        label: 'Time of Band',
        value: 1
      }
    ],
    companyId: localStorage["440a28"],
  };
  // companyId = localStorage["440a28"];
  // serviceModuleId = "5c62a3fe83d4ef6fb672607c";

  statusHandler = async (data, value) => {
    this.setState({ activeStatus: !value });
    let dataSend = {
      configurationId: data.configurationId,
      id: data.id,
      activeStatus: !value
    };
    var res = await expenseTravelAllowanceServise.updateConfigSetStatus(
      this.state.companyId,
      dataSend
    );
    if (res) {
      toast.success(res.message);
    }
  };

  render() { 
    
    let activeStatus =
      this.state.activeStatus === ""
        ? this.props.data.activeStatus
        : this.state.activeStatus;
    return (
      <tr key={this.props.data.id} className={this.props.id.includes(this.props.data.id) ? "active-tr" : ""}>
        <td onClick={() => this.props.selectedDeleteHandler(this.props.data.id)}>

          {this.props.data.cityName !== '' && this.props.data.cityName !== undefined ?
            <>
              <h6 className="mb-1 text-uppercase"><small>City</small></h6>
              <h5><b>{this.props.data.cityName}</b></h5>  </>
            : this.props.data.stateName !== '' && this.props.data.stateName !== undefined ?
              <><h6 className="mb-1 text-uppercase"><small>State</small></h6>
                <h5><b>{this.props.data.stateName}</b></h5>  </>
              : <> <h6 className="mb-1 text-uppercase"><small>Country</small></h6>
                <h5><b>{this.props.data.countryName != null && this.props.data.countryName != undefined ? this.props.data.countryName : 'Global'}</b></h5>   </>}


        </td>
        <td>{this.props.allowanceTypeList[this.props.data.allowanceType].label}</td>
        <td>{this.props.data.currencyName}</td>
        <td>{this.props.data.maxLimit}</td>
        <td>{(this.props.data.maxGuestLimit && this.props.guestActive)?this.props.data.maxGuestLimit:"-"}</td>
        <td>{(this.props.data.maxPersonalLimit  && this.props.personalActive)?this.props.data.maxPersonalLimit:"-"}</td>
        <td>
          <button data-target="#timeOfDayModal"
            onClick={() => this.props.onHourlyClickHandler(this.props.data)}
            data-toggle="modal" className="btn">{this.state.allowanceCalculationType[this.props.allocationCalculationType].label}</button>
        </td>
        <td>
          <button
            type="button"
            className="btn btn-nothing hover-v"
            data-toggle="modal"
            data-target="#EditTemplateModal"
            onClick={() => this.props.editHandler(this.props.data)}
          >
            <i className="material-icons fs-20">
              edit
              </i>
            <span className="hover-name right-top">Edit</span>
          </button>
        </td>
        <td>
          <label className="switch">
            <input
              type="checkbox"
              defaultChecked={activeStatus}
              onClick={() => {
                this.statusHandler(this.props.data, activeStatus);
              }}
            />
            <span className="slider round" />
          </label>
        </td>
      </tr>
    );
  }
}
export default TableRow;
