import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import ExpenseTypesMenu from "../expense-types-menu";
import { Select } from '../../../../../../common/_forms';
// import Select from "react-select";
import { ExpenseTypePolicy } from "./components";
import { reject } from "lodash/fp";
import Loader from "src/_components/common/Loader";

const ExpenseType3Page = props => {
  const {
    listPolicies,
    activePage,
    itemsPerPage,
    handlePageChange,
    count,
    pageItemsList,
    handleItemChange,
    requestEntries,
    onCreateExpenseTypePolicy,
    onEditExpenseTypePolicy,
    expenseTypeId,
  } = props;
  const { allSetPolicies,loading } = props.allState;

  let requestEntriesOptions = [];

  // * create options for requestEntries
  if (requestEntries) {
    requestEntriesOptions = requestEntries.map(item => {
      return {
        value: item.id,
        label: item.formName
      };
    });
  }

  let restPolicies = reject(
    policy =>
      allSetPolicies.some(setPolicy => setPolicy.policyId === policy.id),
    listPolicies
  );

  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left"> E </div>
          <div className="right">
            <h6 className="heading"> Expense Types </h6>
            <p className="text">Add/Modify Expense Type</p>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <ExpenseTypesMenu
            expenseTypeId={expenseTypeId}
          />
          <div className="table-container min-height-p">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th> Enabled for these<br /> policy names </th>
                    <th> For a request entry<br /> use this form <span class="text-danger">*</span> </th>
                    <th />
                    <th />
                    <th> Action </th>
                    <th> Assign </th>
                  </tr>
                </thead>
                <tbody>
                  {listPolicies && listPolicies.length === 0 && (
                    <tr>
                      <td colSpan="5" className="text-center">
                      {(loading)?
                        <Loader /> :
                        "No Records Found"}
                      </td>
                    </tr>
                  )}
                  {/* <tr><td>Set Policies</td></tr> */}
                  {/* Start of all set policies */}
                  {allSetPolicies &&
                    allSetPolicies.map(policy => { 
                      return (
                        <ExpenseTypePolicy
                          requestEntriesOptions={requestEntriesOptions}
                          key={policy.id}
                          edit={true}
                          expenseTypePolicy={policy}
                          policy={policy.policyId}
                          name={(listPolicies.find(item => item.id === policy.policyId) ?.policyName)}
                          hideSetting={false}
                          onChange={data =>
                            // before this was policy?.id
                            onEditExpenseTypePolicy(policy.id, data)
                          }
                        />
                      );
                    })}

                  {/* <tr><td>Remaining Policies</td></tr> */}

                  {/* End of all Set Policies */}
                  {restPolicies &&
                    restPolicies.map(policy => (
                      <ExpenseTypePolicy
                        requestEntriesOptions={requestEntriesOptions}
                        key={policy.id}
                        policy={policy}
                        name={policy.policyName}
                        onChange={onCreateExpenseTypePolicy}
                        hideSetting={true}
                      />
                    ))}
                </tbody>
              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  <div className="page-count">
                    <div className="text"> Items </div>
                    <div className="count">
                      {pageItemsList && (
                        <Select
                          placeholder={itemsPerPage}
                          keyName={"items"}
                          options={pageItemsList}
                          controlFunc={e => handleItemChange(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={count}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
              <div className="btn-group-margin mt-4">
                <NavLink to="/administration/expense/ex/types/list" className="btn">
                  Go to Expense Types List
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpenseType3Page;
