import React, { Component } from 'react';
import EditCompanyProfilePage from './edit-company-profile-html';
import { companyService, masterService } from '../../../../../../_services';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { alertConstants } from '../../../../../../_constants';


class EditCompanyProfile extends Component {
    constructor(props) {
        super(props)
        this.validator = new SimpleReactValidator();
        window.scrollTo({ top: 0, behavior: 'smooth' })
        var companyId = localStorage['440a28']
        this.companyId = companyId
        this.state = {
            companyName: '',
            companyPhone: '',
            companyEmail: '',
            companyFax: '',
            decimalSeparator: '',
            defaultCurrency: '',
            defaultLanguage: '',
            dateFormat: '',
            fiscalYearDate: ''
        }
    }
    componentDidMount() {
        this.getProfileDetail(this.companyId)
        this.getDateFormat()
        this.getDecimalSep()
        this.getLanguage()
        this.getCountries()
    }
    decimalSeparatorName = 'Decimal Format'
    decimalSeperator = [];
    getDecimalSep() {
        masterService.getDecimalSep()
            .then(
                data => {
                    if (data != null) {
                        this.decimalSeperator = data.result
                        this.forceUpdate()
                    }
                }, error => {
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            )
    }
    dateFormatName = 'Date Format'
    getFormatList = [];
    getDateFormat() {
        masterService.getDateFormet()
            .then(
                data => {
                    if (data != null) {
                        this.getFormatList = data.result
                        this.forceUpdate()
                    }
                }, error => {
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            )
    }
    companyDetails = '';
    getProfileDetail(id) {
        companyService.companyProfile(id)
            .then(
                data => {
                    if (data != null) {
                        this.companyDetails = data.result;
                        this.updateOldData(this.companyDetails)
                        this.forceUpdate()
                    }
                }, error => {
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            )
    }
    languageName = 'Language Name'
    languageList = []
    getLanguage() {
        masterService.getLanguage()
            .then(
                data => {
                    if (data != null) {
                        this.languageList = data.result
                        this.forceUpdate()
                    }
                }, error => {
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            )
    }
    getCountry = [];
    currencyName = 'Curreny name'
    getCountries() {
        masterService.getCountries()
            .then(
                data => {
                    if (data != null) {
                        this.getCountry = data.result
                        this.forceUpdate()
                    }
                }, error => {
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            )
    }
    handleChange = (e) => {
        var value = e.target.value;
        var name = e.target.name;
        this.state[name] = value;
        this.forceUpdate()
    }
    handleChangeDate = (e, feild) => {
        var value = e;
        var name = feild;
        this.state[name] = value;
        this.forceUpdate()
    }
    changeData = (e, feild) => {
        this.state[feild] = e.id;
        if (feild === 'decimalSeparator') {
            this.decimalSeparatorName = e.decimalSeparator
        } else if (feild === 'defaultCurrency') {
            this.currencyName = e.currency
        } else if (feild === 'dateFormat') {
            this.dateFormatName = e.dateFormat
        } else if (feild === 'defaultLanguage') {
            this.languageName = e.languageName
        }
        this.forceUpdate()
    }
    updateOldData(e) {
        // this.state[feild] = e.id; 
        if(e.decimalSeparator !== null) {
            var decimalSeparator = e.decimalSeparator.id
        }else{
            var decimalSeparator  = '';
        } 
        if(e.countryId !== null) {
            var countryId = e.countryId.id
        }else{
            var countryId  = '';
        } 
        if(e.defaultLanguage !== null) {
            var defaultLanguage = e.defaultLanguage.id
        }else{
            var defaultLanguage  = '';
        } 
        if(e.dateFormat !== null) {
            var dateFormat = e.dateFormat.id
        }else{
            var dateFormat  = '';
        } 
        this.setState({
            id: e.id,
            companyName: e.companyName,
            companyPhone: e.contactInfo.phone,
            companyEmail: e.contactInfo.email,
            companyFax: e.companyFax,
            decimalSeparator: decimalSeparator,
            defaultCurrency: countryId,
            defaultLanguage: defaultLanguage,
            dateFormat: dateFormat,
            // fiscalYearDate: e.fiscalYearDate
        })
        if (e.decimalSeparator)
            this.decimalSeparatorName = e.decimalSeparator.decimalSeparator
        if (e.countryId)
            this.currencyName = e.countryId.currency
        if (e.dateFormat)
            this.dateFormatName = e.dateFormat.dateFormat
        if (e.defaultLanguage)
            this.languageName = e.defaultLanguage.languageName
        this.forceUpdate()
    }
    updateProfile = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            this.loading = true;
            companyService.updateCompanyProfile(this.state)
                .then(
                    data => {
                        this.loading = false;
                        toast.success(alertConstants.UPDATED_SUCCESSFULLY)
                        this.props.history.push('/administration/company/profile/list')
                    }, error => {
                        this.loading = false;
                        if (error.statusCode === 422) {
                            let errors = error.message
                            Object.keys(errors).map(key => {
                                return (
                                    toast.error(errors[key])
                                );
                            })
                        } else {
                            toast.error(error.message)
                        }
                    }
                )
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }
    }
    goBack = (e) => {
        this.props.history.push('/administration/company/profile/list');
    }
    render() {
        return (
            <div>
                <EditCompanyProfilePage
                    getFormatList={this.getFormatList}
                    state={this.state}
                    error={this.validator}
                    // decimalSeparatorName={this.decimalSeparatorName}
                    // decimalSeperator={this.decimalSeperator}
                    // dateFormatName={this.dateFormatName}
                    // languageList={this.languageList}
                    // languageName={this.languageName}
                    // currencyName={this.currencyName}
                    // getCountry={this.getCountry}
                    changeData={this.changeData}
                    handleChange={this.handleChange}
                    handleChangeDate={this.handleChangeDate}
                    updateProfile={this.updateProfile}
                    goBack={this.goBack}
                />
            </div>
        )
    }
}
export default EditCompanyProfile;