import React, { Component } from 'react';
import CustomPage from './custom-html';
import { auditRuleService } from '../../../../../../_services/auditRule.service';
import { toast } from 'react-toastify';


class Custom extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.state = {
            search: '',
            isLoading: false,
            limit: 10,
            pageNumber: 1,
            totalRules: 0,
            AllAuditRules: [],
            selectedAuditRuleId: [],
            companyId: localStorage["440a28"]
        }
    }
    componentDidMount() {
        localStorage.removeItem('auditRuleId');
        this.getCreateAuditRules();
    }
    getCreateAuditRules = async () => {
        this.setState({ isLoading: true });
        let pageNumber = this.state.pageNumber;
        let limit = this.state.limit;
        let companyId = this.state.companyId;
        let response = await auditRuleService.getAllAuditRules(companyId, limit, pageNumber);
        if (response.count > 0) {
            this.setState({
                AllAuditRules: response.result,
                totalRules: response.count,
                isLoading: false
            });
        } else {
            this.setState({
                isLoading: false,
                AllAuditRules: []
            });
        }
    }
    onPageLimitChangeHandler = async (limit) => {
        await this.setState({ limit: limit, pageNumber: 1 });
        this.getCreateAuditRules();
    }
    onStatusChangeHandler = async (e, status, auditRuleId) => {
        e.preventDefault();
        let data = {
            activeStatus: !status,
            id: auditRuleId
        }
        let response = await auditRuleService.changeStatusOfRule(this.state.companyId, data);
        if (response) {
            this.getCreateAuditRules();
            toast.success(response.message);
        }
    }

    onSelectAuditRuleHandler = (auditRuleId) => {
        let selectedAuditRuleId = [...this.state.selectedAuditRuleId];
        if (selectedAuditRuleId.includes(auditRuleId)) {
            selectedAuditRuleId = selectedAuditRuleId.filter((ruleId) => ruleId !== auditRuleId);
        } else {
            selectedAuditRuleId.push(auditRuleId);
        }
        this.setState({ selectedAuditRuleId: selectedAuditRuleId });
    }
    handlePageChange = async (page) => {
        await this.setState({ pageNumber: page });
        this.getCreateAuditRules();
    }
    onDeleteAuditRuleHandler = async () => {
        let selectedAuditRuleId = [...this.state.selectedAuditRuleId];
        let data = {
            deleteStatus: true,
            id: selectedAuditRuleId
        }
        let response = await auditRuleService.deleteAuditRule(this.state.companyId, data);
        if (response) {
            this.getCreateAuditRules();
            toast.error(response.message);
        }
    }

    onSearchTextChangeHandler = async (event) => {
        let search = event.target.value;
        if (search !== '' && search !== '') {
            await this.setState({ search: event.target.value });
            let response = await auditRuleService.auditRuleSearch(this.state.companyId, search);
            if (response.count > 0) {
                this.setState({
                    AllAuditRules: response.result,
                    totalRules: response.count
                });
            } else {
                this.setState({
                    AllAuditRules: []
                });
            }
        } else {
            this.setState({ search: '' });
            this.getCreateAuditRules();
        }
    }


    render() {
        return (
            <div>
                <CustomPage
                    {...this.state}
                    onPageLimitChangeHandler={this.onPageLimitChangeHandler}
                    onStatusChangeHandler={this.onStatusChangeHandler}
                    onSelectAuditRuleHandler={this.onSelectAuditRuleHandler}
                    handlePageChange={this.handlePageChange}
                    onDeleteAuditRuleHandler={this.onDeleteAuditRuleHandler}
                    onSearchTextChangeHandler={this.onSearchTextChangeHandler}
                />
            </div>
        )
    }
}
export default Custom;