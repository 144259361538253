import React from "react";
import Pagination from "react-js-pagination";
import Menu from "../menu";
import { Select, Input } from "../../../../../common/_forms";
import ToggleSlider from "../../../../../common/ToggleSlider/ToggleSlider";
// import DefaultField from "../../../company/forms/modify-form-fields2/components/DefaultField";
import TravelRenderedFields from "src/_components/common/TravelRenderedFields";
import Moment from 'react-moment';
import Loader from "src/_components/common/Loader";

const RuleTemplatePage = props => {
  // let data = props;
  const {
    displayedRuleTemplates,
    selectedRuleTemplates,
    selectedCategory,
    itemsCountPerPage,
    activePage,
    totalItemsCount,
    ruleTemplateCheckboxes,
    allConditions,
    anyCondition,
    ruleTemplateName,
    fieldsToBeRendered,
    fieldsData,
    selectedCheckboxes,
    violationCode,
    availableRulesinCategory,
  } = props.state;
  const {
    error,
    changeTemplateCategory,
    toggleRuleTemplate,
    selectRuleTemplateHandler,
    deleteRuleTemplateHandler,
    pageChangeHandler,
    limitChangeHandler,
    searchHandler,
    addRuleTemplateHandler,
    conditionCheckHandler,
    renderedFieldSelectHandler,
    renderedFieldTextHandler,
    allCheckedConditionsHandler,
    anyCheckedConditionsHandler,
    saveRuleTemplateHandler,
    renderedFieldCheckboxHandler,
    ruleTemplateNameHandler,
    violationCodeHandler,
    editModalHandler,
    copyModalHandler,
    cancelModalHandler,
    copyRuleTemplateHandler,
    fieldDateChangeHandler
  } = props;
  let templateCategories = [
    { name: "Travel Itinerary" },
    { name: "Flight" },
    { name: "Train" },
    { name: "Car" },
    { name: "Hotel" }
  ];

  let isItemSelected = !!selectedRuleTemplates.length;
  let removeClasses = ["btn", " "];
  if (isItemSelected) {
    removeClasses.push("active");
  } 

  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">Travel Policy - Rules</h6>
              <p className="text">Add or modify travel rules template.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <div className="">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group custom labelup mb-0">
                    <label>Select Rule Template Category </label>
                    <Select
                      placeholder={
                        props.state.selectedCategory
                          ? props.state.selectedCategory ?.travelCategoryName
                            : "Select a Category"
                      }
                      keyName="travelCategoryName"
                      options={props.state.allTemplateCategories || []}
                      controlFunc={e => changeTemplateCategory(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="table-container">
              <form>
                <div className="row">
                  <div className="col-md-9">
                    <div className="btn-group-margin">
                      <button
                        onClick={cancelModalHandler}
                        type="button"
                        disabled={isItemSelected}
                        data-toggle="modal"
                        data-target="#AddRuleTemplate"
                        className="btn"
                      >
                        Add Rule Template
                      </button>
                      <button
                        onClick={deleteRuleTemplateHandler}
                        disabled={!isItemSelected}
                        className="btn"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {/* <div className="search-custom">
                      <input
                        onChange={e => searchHandler(e)}
                        type="text"
                        name=""
                        className="form-control"
                        placeholder="Search"
                      />
                      <span>
                        <i className="material-icons">
                          search
                          </i>
                      </span>
                    </div> */}
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-striped table-selected">
                  <thead>
                    <tr>
                      <th>Rule Name</th>
                      <th>Violation code</th>
                      <th>last modified date</th>
                      <th>Action</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      props.state.isLoading ?
                        (<tr>
                          <td colSpan="5">
                            <Loader />
                          </td>
                        </tr>) :
                        (!props.state.isLoading && displayedRuleTemplates.length === 0) ?
                          (<tr>
                            <td colSpan="5">
                              No records found
                          </td>
                          </tr>) :
                          (displayedRuleTemplates &&
                            displayedRuleTemplates.map(ruleTemplate => {
                              return (
                                <tr key={ruleTemplate.id}>
                                  <td>
                                    <div className="d-inline-block">
                                      <label className="checkbox-container small">
                                        <input
                                          type="checkbox"
                                          checked={(selectedRuleTemplates.indexOf(ruleTemplate) !== -1) ? true : false}
                                          onChange={e =>
                                            selectRuleTemplateHandler(ruleTemplate)
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                    </div>
                                    <p className="d-inline-block">
                                      {ruleTemplate.ruleName}
                                    </p>
                                  </td>
                                  <td>{ruleTemplate.violationCode}</td>
                                  <td>
                                    <Moment format="DD-MMM-YYYY">
                                      {ruleTemplate.updatedAt}

                                    </Moment>
                                  </td>
                                  <td>
                                    <div className="btn-group-margin">
                                      <button
                                        className="btn btn-nothing hover-v"
                                        data-toggle="modal"
                                        data-target="#EditRuleTemplate"
                                        type="button"
                                        onClick={e => editModalHandler(ruleTemplate)}
                                      >
                                        <i className="material-icons fs-20">edit</i>
                                        <div className="hover-name right-top">Edit</div>
                                      </button>
                                    </div>
                                  </td>
                                  <td>
                                    <ToggleSlider
                                      value={ruleTemplate.activeStatus}
                                      checked={ruleTemplate.activeStatus}
                                      toggleSliderHandler={e =>
                                        toggleRuleTemplate(ruleTemplate)
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            }))
                    }
                  </tbody>
                </table>
              </div>

              {displayedRuleTemplates && displayedRuleTemplates.length !== 0 && !props.state.isLoading && (
                <div className="table-footer">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="page-count">
                        <div className="text">Items</div>
                        <div className="count">
                          <div className="dropdown">
                          <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          {itemsCountPerPage}
                        </button>
                        <div className="dropdown-menu">
                            <button
                              onClick={e => limitChangeHandler(10)}
                              className="dropdown-item"
                            >
                              10
                              </button>
                            <button
                              onClick={e => limitChangeHandler(20)}
                              className="dropdown-item"
                            >
                              20
                              </button>
                            <button
                              onClick={e => limitChangeHandler(30)}
                              className="dropdown-item"
                            >
                              30
                              </button>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={3}
                        onChange={pageChangeHandler}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Add New Rule Template Modal */}
      <div
        className="modal fade"
        id="AddRuleTemplate"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddRuleTemplateLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AddRuleTemplateLabel">
                Create Rule Template
              </h5>
              <button
                onClick={cancelModalHandler}
                type="button"
                className="close d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-middle pm-30">
              <div className="modal-scroll-lg px-1">
                <div className="row">
                  <div className="col-md-6 pt-2">
                    <div className="form-group custom ">
                      <Input
                        name="ruleName"
                        label="Rule Name"
                        type="text"
                        imp="true"
                        value={ruleTemplateName}
                        error={error.message(
                          "Rule Template Name",
                          ruleTemplateName,
                          "required"
                        )}
                        controlFunc={e => ruleTemplateNameHandler(e)}
                      />
                      {/* <input className="form-control" placeholder="&nbsp;" type="text" name="ruleName" />
                    <label>Rule Name</label> */}
                    </div>
                  </div>
                  <div className="col-md-6 pt-2">
                    <div className="form-group custom">
                      <Input
                        name="violationCode"
                        label="Violation Code"
                        type="text"
                        value={violationCode}
                        controlFunc={e => violationCodeHandler(e)}
                      />
                    </div>
                    {/* <input className="form-control" placeholder="&nbsp;" type="text" name="violationCode" />
                    <label>Violation Code</label> */}
                  </div>
                  <div className="col-md-6">
                    <label className="checkbox-container">
                      {" "}
                      All of the checked conditions are true
                    <input
                        type="checkbox"
                        onChange={e => allCheckedConditionsHandler(allConditions)}
                        checked={allConditions}
                        value={allConditions}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="checkbox-container">
                      {" "}
                      Any of the checked conditions are true
                    <input
                        type="checkbox"
                        checked={anyCondition}
                        value={anyCondition}
                        onChange={e => anyCheckedConditionsHandler(anyCondition)}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>

                  {availableRulesinCategory &&
                    availableRulesinCategory.map(condition => {
                      return (
                        <div className="col-12" key={condition.id}>
                          <label className="checkbox-container">
                            {" "}
                            {condition.travelCategoryRule}
                            <input
                              checked={selectedCheckboxes.includes(condition.id)}
                              onChange={e => conditionCheckHandler(condition)}
                              type="checkbox"
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      );
                    })}
                </div>
                <hr></hr>
                <div className="mt-3">
                  {fieldsToBeRendered &&
                    fieldsToBeRendered.map((rule, idx) => { 
                      return (
                        <div key={idx}>
                          <h6 className="sub-heading">{rule.travelCategoryRule}</h6>
                          <div className="row">
                            {rule.ruleCondition.map((fieldItem, index) => {
                              return (
                                <div key={index} className="col-lg-3">
                                  <TravelRenderedFields
                                    type={fieldItem ?.dataTypeDetail ?.conditionID}
                                    fieldItem={fieldItem}
                                    fieldDateChangeHandler={fieldDateChangeHandler}
                                    error={error}
                                    fieldChangeHandler={(e, type) => props.fieldChangeHandler(e, rule, fieldItem, type)}
                                    selectDifferentListChangeHandler={(e, type) => props.selectDifferentListChangeHandler(e, rule, fieldItem, type)}
                                    selectChangeHandler={(e, type) => props.selectChangeHandler(e, rule, fieldItem, type)}
                                    dataTypeValueDetail={fieldItem ?.dataTypeValueDetail}
                                    conditionalOptions={fieldItem ?.dataTypeDetail ?.conditionList}
                                    fieldValue={fieldItem ?.selectedValue}
                                    dropdownValue={fieldItem ?.selectedDropdown}
                                    loadOptions={props.loadOptions}
                                  />
                                </div>
                              )
                            })}
                            <hr></hr>
                          </div>
                        </div>
                      )
                    }
                    )}
                </div>
              </div>
              <div className="modal-last pt-3 btn-group-margin">
                <button
                  onClick={saveRuleTemplateHandler}
                  type="button"
                  className="btn"
                >
                  Save
                </button>
                <button
                  onClick={cancelModalHandler}
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* Add Modal End*/}

      {/* Edit Modal Start*/}
      <div
        className="modal fade"
        id="EditRuleTemplate"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="EditRuleTemplateLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AddRuleTemplateLabel">
                Edit Rule Template
              </h5>
              <button
                onClick={cancelModalHandler}
                type="button"
                className="close d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-middle pm-30">
              <div className="modal-scroll-lg">
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <div className="form-group custom">
                      <Input
                        name="ruleName"
                        label="Rule Name"
                        type="text"
                        value={ruleTemplateName}
                        controlFunc={e => ruleTemplateNameHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pt-1">
                    <div className="form-group custom">
                      <Input
                        name="violationCode"
                        label="Violation Code"
                        type="text"
                        value={violationCode}
                        controlFunc={e => violationCodeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="checkbox-container">
                      All of the checked conditions are true
                    <input
                        type="checkbox"
                        onChange={e => allCheckedConditionsHandler(allConditions)}
                        checked={allConditions}
                        value={allConditions}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="checkbox-container">
                      Any of the checked conditions are true
                    <input
                        type="checkbox"
                        checked={anyCondition}
                        value={anyCondition}
                        onChange={e => anyCheckedConditionsHandler(anyCondition)}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>

                  {/* {ruleTemplateCheckboxes &&
                  ruleTemplateCheckboxes.map(condition => {
                    return (
                      <div className="col-12" key={condition.id}>
                        <label className="checkbox-container">
                          {" "}
                          {condition.conditionName}
                          <input
                            onClick={e => conditionCheckHandler(condition)}
                            type="checkbox"
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    );
                  })} */}
                  {availableRulesinCategory &&
                    availableRulesinCategory.map(condition => {
                      return (
                        <div className="col-12" key={condition.id}>
                          <label className="checkbox-container">
                            {condition.travelCategoryRule}
                            <input
                              type="checkbox"
                              onChange={e => conditionCheckHandler(condition)}
                              checked={selectedCheckboxes.includes(condition.id)}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      );
                    })}
                </div>
                <div className="mt-3">

                  {fieldsToBeRendered &&
                    fieldsToBeRendered.map((rule, idx) => {
                      return (
                        <div key={idx}>
                          <h6 className="sub-heading">{rule.travelCategoryRule}</h6>
                          <div className="row">
                            {rule.ruleCondition.map((fieldItem, index) => {
                              return (
                                <div key={index} className="col-lg-3">
                                  {/* {fieldItem ?.dataTypeDetail ?.conditionID} */}
                                  <TravelRenderedFields
                                    type={fieldItem ?.dataTypeDetail ?.conditionID}
                                    fieldItem={fieldItem}
                                    fieldDateChangeHandler={(e, type) => fieldDateChangeHandler(e, rule, fieldItem, type)}
                                    // value={new Date() || fieldItem?.dataTypeValue}
                                    fieldChangeHandler={(e, type) => props.fieldChangeHandler(e, rule, fieldItem, type)}
                                    selectDifferentListChangeHandler={(e, type) => props.selectDifferentListChangeHandler(e, rule, fieldItem, type)}
                                    selectChangeHandler={(e, type) => props.selectChangeHandler(e, rule, fieldItem, type)}
                                    dataTypeValueDetail={fieldItem ?.dataTypeValueDetail}
                                    conditionalOptions={fieldItem ?.dataTypeDetail ?.conditionList}
                                    fieldValue={fieldItem ?.selectedValue}
                                    dropdownValue={fieldItem ?.selectedDropdown}
                                    loadOptions={props.loadOptions}
                                  // selectFieldValue={}

                                  />

                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    }
                    )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={saveRuleTemplateHandler}
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                Update
              </button>
              <button
                onClick={cancelModalHandler}
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* End of Edit Modal */}

      {/* Copy Modal Start*/}
      <div
        className="modal fade"
        id="copyModalTemplate"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="copyModalTemplate"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AddRuleTemplateLabel">
                Copy Rule Template
              </h5>
              <button
                onClick={cancelModalHandler}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group custom">
                    <Input
                      name="ruleName"
                      label="Rule Name"
                      type="text"
                      value={ruleTemplateName}
                      controlFunc={e => ruleTemplateNameHandler(e)}
                    />
                    {/* <input className="form-control" placeholder="&nbsp;" type="text" name="ruleName" />
            <label>Rule Name</label> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group custom">
                    <Input
                      name="violationCode"
                      label="Violation Code"
                      type="text"
                      value={violationCode}
                      controlFunc={e => violationCodeHandler(e)}
                    />
                  </div>
                  {/* <input className="form-control" placeholder="&nbsp;" type="text" name="violationCode" />
            <label>Violation Code</label> */}
                </div>
                <div className="col-md-6">
                  <label className="checkbox-container">
                    {" "}
                    All of the checked conditions are true
                    <input
                      type="checkbox"
                      onChange={e => allCheckedConditionsHandler(allConditions)}
                      checked={allConditions}
                      value={allConditions}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="checkbox-container">
                    {" "}
                    Any of the checked conditions are true
                    <input
                      type="checkbox"
                      checked={anyCondition}
                      value={anyCondition}
                      onChange={e => anyCheckedConditionsHandler(anyCondition)}
                    />
                    <span className="checkmark" />
                  </label>
                </div>

                {ruleTemplateCheckboxes &&
                  ruleTemplateCheckboxes.map(condition => {
                    return (
                      <div className="col-12" key={condition.id}>
                        <label className="checkbox-container">
                          {" "}
                          {condition.conditionName}
                          <input
                            onClick={e => conditionCheckHandler(condition)}
                            type="checkbox"
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    );
                  })}
              </div>
              <div>
                {fieldsToBeRendered &&
                  fieldsToBeRendered.map((fieldItem, idx) => {
                    let renderedField = "";
                    let selectedOption = "";

                    // if(fieldItem.type === 'select'){
                    //   selectedOption = fieldsData.map(fieldData => {
                    //     if(fieldData.id === fieldItem.id){
                    //       return fieldData.value

                    //     }
                    //   })
                    // }
                    // console.log("selected option ", selectedOption);

                    switch (fieldItem.type) {
                      case "select":
                        let tempObj = [
                          { name: "option1" },
                          { name: "option2" }
                        ];
                        renderedField = (
                          <div className="form-group custom labelup">
                            <label>{fieldItem.name}</label>
                            <Select
                              placeholder={
                                fieldItem.value
                                  ? fieldItem.value
                                  : "select an option"
                              }
                              keyName="listName"
                              options={fieldItem.listOfValues}
                              controlFunc={e =>
                                renderedFieldSelectHandler(e, fieldItem)
                              }
                            />
                          </div>
                        );
                        break;
                      case "text":
                        renderedField = (
                          <Input
                            label={fieldItem.name}
                            value={fieldItem.value}
                            name={fieldItem.name}
                            type="text"
                            controlFunc={e =>
                              renderedFieldTextHandler(e, fieldItem.id)
                            }
                          />
                        );
                        break;
                      case "checkbox":
                        renderedField = (
                          <Input
                            placeholder="checkbox name"
                            name={fieldItem.name}
                            type="checkbox"
                            controlFunc={e =>
                              renderedFieldCheckboxHandler(e, fieldItem.id)
                            }
                          />
                        );
                    }
                    return (
                      <div key={idx}>
                        <div className="form-group custom">{renderedField}</div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={copyRuleTemplateHandler}
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                Save
              </button>
              <button
                onClick={cancelModalHandler}
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Copy Modal End */}
    </div>
  );
};
export default RuleTemplatePage;
