import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import $ from 'jquery';
import { toast } from 'react-toastify';
import CashadvanceApproveDataList from './cashadvanceComponent/cashadvanceApproveDataList';
import CashadvanceUserDetail from './cashadvanceComponent/cashadvanceUserDetail';
//import ApprovalAmountDetail from './cashadvanceComponent/approvalAmountDetails';
import CashadvanceTableModal from './cashadvanceComponent/cashadvanceTableModal';
import CashadvanceRejection from './cashadvanceComponent/cashadvanceRejection'
import { cashadvanceService } from './../../../_services/cashadvance.service';
import SimpleReactValidator from "simple-react-validator";
import Loader from 'src/_components/common/Loader';

// import _ from 'lodash';
class CashAdvance3Page extends Component {
  constructor(props) {
    super(props)
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.validator = new SimpleReactValidator();
    this.state = {
      exchnageRateAmount: '',
      amountIssue: '',
      cashVoucher: '',
      comment: '',
      search:'',
      baseCurrency: 'INR',
      totalCount: 0,
      perPageLimit: 10,
      activePageNumber: 1,
      cashadvanceModeValue: {},
      selectedCashadvanceId: '',
      isLoading: true,
      isModalLoading: true,
      selectedCurrency: {},
      pageItemsList: [{ items: 10 }, { items: 20 }, { items: 30 }],
      currencyList: [],
      uploadedVouchers: [],
      approveCashAdvance: [],
      browseAllImages: [],
      cashAdvanceModes: [],
      cashAdvanceCurrency: [],
      exchangeRateToSelcetdCurr: 1,
      companyId: localStorage["440a28"],
      pageName: 'Approve',
      uploadReceiptUrl:[]
    }
  }

  componentDidMount() {
    this.getIssedCashAdvanceList();
  }

  getIssedCashAdvanceList = async () => {
    let response = await cashadvanceService.getIssedCashAdvanceList('Approved', this.state.companyId, this.state.activePageNumber, this.state.perPageLimit,this.state.search);
    if (response.count > 0) {
      this.setState({
        approveCashAdvance: response.result,
        isLoading: false,
        totalCount: response.count
      });
    } else {
      this.setState({ approveCashAdvance: [], isLoading: false, totalCount: 0 });
    }
  }

  onPageChangeHandler = async (page) => {
    await this.setState({ activePageNumber: page });
    this.getIssedCashAdvanceList();
  }
  onPerPageLimitChangeHandler = async (perPage) => {
    await this.setState({ perPageLimit: perPage.items, activePageNumber: 1 });
    this.getIssedCashAdvanceList();
  }

  getAllCurrencies = async () => {
    let response = await cashadvanceService.getCurrencyList(this.state.companyId);
    if (response) {
      let currencyList = response.result.map((currencies) => ({ label: currencies.itemCode, value: currencies.id }));
      this.setState({ currencyList: currencyList });
    }
  }

  getCashadvanceModeList = async () => {
    let response = await cashadvanceService.getCashadvanceModeList(this.state.companyId);
    if (response) {
      let cashAdvanceModes = response.result.map((modes) => ({ label: modes.itemName, value: modes.id }));
      this.setState({ cashAdvanceModes: cashAdvanceModes });
    } else {
      this.setState({ cashAdvanceModes: response.result });
    }
  }

  onSelectCashAdvanceHandler = async (cashadvanceId) => {
    this.setState({
      exchnageRateAmount: '',
      amountIssue: '',
      cashVoucher: '',
      comment: '',
      cashadvanceModeValue: {},
      selectedCashadvanceId: ''
    });
    this.getAllCurrencies();
    this.setState({ selectedCashadvanceId: cashadvanceId });
    let response = await cashadvanceService.getCashadvanceDetailById(this.state.companyId, cashadvanceId);
    this.getCashadvanceModeList();
    let cashadvanceData = response.result;
    let currencyIndex = cashadvanceData.data.findIndex((fields) => fields.columnName === 'CURRENCY');
    let cashAdvanceCurrency = cashadvanceData.data[currencyIndex].connectedListData.listManagementData.filter((currencies) => currencies.id === cashadvanceData.data[currencyIndex].fieldValue);
    let baseCurrencyIndex = cashadvanceData.userDeatils && cashadvanceData.userDeatils.fieldData.findIndex((fields) => fields.columnName === "REIMBURSEMENT_CURRENCY");
    if (response.result && response.result.data.length > 0) {
      this.setState({
        selectedCashadvanceData: response.result,
        isModalLoading: false,
        exchnageRateAmount: response.result.exchangeRate,
        cashAdvanceCurrency: cashAdvanceCurrency,
        baseCurrency: cashadvanceData.userDeatils && cashadvanceData.userDeatils.fieldData[baseCurrencyIndex].fieldValue,
      });
    } else {
      this.setState({ selectedCashadvanceData: [], isModalLoading: false });
    }
  }
  onChnageExchangeChangeHandler = (event) => {
    if (!isNaN(event.target.value)) this.setState({ exchangeRateToSelcetdCurr: +event.target.value });
  }

  onModeChnageHandler = (value) => {
    this.setState({ cashadvanceModeValue: value });
  }

  onChnageApprovalAmountHandler = (event) => {
    if (!isNaN(event.target.value)) this.setState({ amountIssue: +event.target.value });
  }

  onVoucherChnageHandler = (event) => {
    this.setState({ cashVoucher: event.target.value });
  }

  onCommentChnageHandler = (event) => {
    this.setState({ comment: event.target.value });
  }
  onCurrencyChnageHandler = async (selectedCurrency) => {
    let cashAdvanceCurrency = this.state.cashAdvanceCurrency;
    this.setState({ selectedCurrency: selectedCurrency });
    let response = await cashadvanceService.getConvertedCurrency(selectedCurrency.label);
    if (response) {

      let baseCurrency = this.state.baseCurrency;
      let exchnageRateAmount = response.data.rates[baseCurrency]
      this.setState({ exchnageRateAmount: exchnageRateAmount });
    }
    if (selectedCurrency.label) {
      let cashAdvanceCurrencyName = cashAdvanceCurrency[0].itemCode;
      let exchnageRateAmount = response.data.rates[cashAdvanceCurrencyName]
      this.setState({ exchangeRateToSelcetdCurr: exchnageRateAmount });
    }
  }
  onApproveClickCashadvanceHandler = async () => {
    let data = [{
      companyId: this.state.companyId,
      data: this.state.selectedCashadvanceData.data,
      requestId: this.state.selectedCashadvanceData.requestId ? this.state.selectedCashadvanceData.requestId : '',
      voucher: this.state.cashVoucher,
      mode: this.state.cashadvanceModeValue.value,
      //requestCashAdvanceId:this.state.selectedCashadvanceData.cashAdvanceId,
      requestCashAdvanceId: '',
      amountIssued: this.state.amountIssue,
      comment: this.state.comment,
      issuedCurrency: this.state.selectedCurrency.label,
      exchangeRate: this.state.exchangeRateToSelcetdCurr,
      status: this.state.status,
      baseExchangeRate:this.state.exchnageRateAmount,
      uploadReceiptUrl:this.state.uploadReceiptUrl
    }];
    let response = await cashadvanceService.cashAdvanceApproveHandler(this.state.companyId, data, this.state.selectedCashadvanceId);
    if (response) {
      toast.success(response.message);
      this.getIssedCashAdvanceList();
      this.setState({
        exchnageRateAmount: '',
        amountIssue: '',
        cashVoucher: '',
        comment: '',
        cashadvanceModeValue: {},
        selectedCashadvanceId: '',
        issuedCurrency:''
      });
      $(".close").click();
    }
  }

  onApproveIssueClickHandler = (status) => {
    this.setState({ status: status });
    if (status !== 'Rejected') {
      if (this.validator.allValid()) {
        $(".close").click();
        $(".open").click();
      } else {
        //$(".close").click();
        this.validator.showMessages();
        this.forceUpdate();
      }
    } else {
      $(".close").click();
      $(".open").click();
    }
  }


  onFileBrowse = async (event) => {
    const browseFiles = Array.from(event.target.files);
    this.setState({ browseAllImages: browseFiles });
    let allImages = [];
    let allBrowseImage = [];
    for (const key in browseFiles) {
      let fileName = browseFiles[key].name;
      if (fileName && fileName !== "item") {
        let browseImage = URL.createObjectURL(event.target.files[key]);
        allImages.push(fileName);
        allBrowseImage.push(browseImage);
      }
    }
    let formData = new FormData();
    browseFiles.forEach((file, i) => {
      formData.append("file", file);
    });

    let response = await cashadvanceService.cashAdvanceVoucherImageUpload(formData);
    if (response && response.imageUrl && response.imageUrl.length) {
      let uploadReceiptUrl = [...this.state.uploadReceiptUrl];
      uploadReceiptUrl.push(response.imageUrl[0])
      this.setState({ browseAllImages: [] });
      this.setState({ uploadReceiptUrl: uploadReceiptUrl });
      toast.success(response.message);
    }
  };

  handleBaseCurrencyExchangeHandler = (event) => {
    if (!isNaN(event.target.value)) this.setState({ exchnageRateAmount: +event.target.value });
  }
  onSearchHandler= (async(searchText) => {
     await this.setState({ search: searchText,isLoading: true ,page:1});
     this.getIssedCashAdvanceList();
  });

  render() {
    return (

      <div>
        <div className="py-padding">
          <ul className="expense-tabs px-padding">
            <li className="active">
              <NavLink to="/cash-issued/cashadvance">
                Approved
                </NavLink>
            </li>
            <li >
              <NavLink to="/cash-issued/issued">
                Issued
                </NavLink>
            </li>
            <li>
              <NavLink to="/cash-issued/rejected">
                Rejected
                </NavLink>
            </li>
          </ul>


            <div className="table-container">
              <form>
                <div className="row">
                  <div className="col-md-9">
                  </div>
                  <div className="col-md-3">
                    <div className="search-custom">
                      <input
                        type="text"
                        name="search"
                        onChange={(e)=>this.onSearchHandler(e.target.value)}
                        value={this.state.search}
                        className="form-control"
                        placeholder="Search"
                      />
                      <span><i className="material-icons">search</i></span>
                    </div>
                  </div>
                </div>
              </form>
              <CashadvanceApproveDataList
                {...this.state}
                approveCashAdvance={this.state.approveCashAdvance}
                onSelectCashAdvanceHandler={this.onSelectCashAdvanceHandler}
                onPageChangeHandler={this.onPageChangeHandler}
                onPerPageLimitChangeHandler={this.onPerPageLimitChangeHandler}
              />
            </div>

        </div>

        <div className="modal fade cash_advance" id="newcashAdvanceModal" tabIndex="-1"
          role="dialog" aria-labelledby="newcashAdvancLabel" aria-hidden="true" >
          <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <h6 className="modal-subtitle">New</h6>
                <h5 className="modal-title" id="newcashAdvancLabel">
                  Cash Advance
                  </h5>
              </div>
              {this.state.isModalLoading ? <Loader /> :
                <div className="modal-scroll-lg">
                  <div className="modal-middle pm-30 pb-0">
                    <CashadvanceUserDetail
                      selectedCashadvanceData={this.state.selectedCashadvanceData}
                    />
                    <CashadvanceTableModal
                      {...this.state}
                      validator={this.validator}
                      onChnageExchangeChangeHandler={this.onChnageExchangeChangeHandler}
                      onVoucherChnageHandler={this.onVoucherChnageHandler}
                      onModeChnageHandler={this.onModeChnageHandler}
                      onChnageApprovalAmountHandler={this.onChnageApprovalAmountHandler}
                      onCommentChnageHandler={this.onCommentChnageHandler}
                      onFileBrowse={this.onFileBrowse}
                      onCurrencyChnageHandler={this.onCurrencyChnageHandler}
                      handleBaseCurrencyExchangeHandler={this.handleBaseCurrencyExchangeHandler}
                      baseExchangeRate={this.state.exchnageRateAmount}
                    />
                    <CashadvanceRejection
                      selectedCashadvanceData={this.state.selectedCashadvanceData}
                    />
                  </div>
                </div>
              }
              <div className="modal-last btn-group-margin">
                <button
                  onClick={() => this.onApproveIssueClickHandler('Rejected')}
                  className="btn ">Reject</button>
                <button
                  onClick={() => this.onApproveIssueClickHandler('Issued')}
                  className="btn " >Issue</button>
                <button
                  className="open d-none"
                  data-target="#cashadvanceApproval"
                  data-toggle="modal"
                ></button>
                <button
                  onClick={this.onCancelApproveHandle}
                  type="button" className="btn btn-badge close" data-dismiss="modal">
                  Close
                  </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="cashadvanceApproval"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="submitModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="close d-none"
                data-dismiss="modal"
              >
                Close
              </button>
              <div className="modal-body">
                <div className="text-center pt-5">
                  <img src="/assets/images/confirmation_icon.svg" alt="" />
                  <h3 className=" mt-4 text-dark">Are You Sure?</h3>
                  <p>By clicking on submit, you agree to issue the amount.</p>
                </div>
              </div>
              <div className="btn-group-margin text-center pb-5">
                <button
                  type="button"
                  onClick={this.onCancelApproveHandle}
                  className="btn"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  onClick={this.onApproveClickCashadvanceHandler}
                  className="btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
export default CashAdvance3Page