import React from "react";
import StarRatings from "react-star-ratings";
import GoogleMapReact from "google-map-react";
import NumberFormat from "react-number-format";
import ETicketPage from "src/_components/e-ticket";
import InvoicePage from "src/_components/invoice";
import { getValue } from "src/_components/common/lodsh";

export const HotelCartDetail = props => {
  const {
    item,
    ticketInvoice,
    currentItem,
    eticket,
    enableEticket,
    eticketInfo,
    enableInvoice,
    invoice,
    invoiceInfo,
    closeWindow,
    baseCurrency
  } = props;
  // const rate = item.hotel_rating && parseInt(item.hotel_rating);
  const rate = !(item.hotel_rating && parseInt(item.hotel_rating)) ? item.hotel_ranking > 5 ? 5 : 5 && parseInt(item.hotel_ranking > 5 ? 5 : 5) : item.hotel_rating && parseInt(item.hotel_rating)
  const AnyReactComponent = () => (
    <i className="material-icons otle-hotel">hotel</i>
  );
  // console.log(item)
  return (
    <div className="white-card mb-0">
      <div className="bg-light p-3">
        <div className="row">
          <div className="col-md-6">Employee Preferred</div>
          <div className="col-md-6">
            <div className="btn-group-margin text-right">
              {/* <button
                data-toggle="modal"
                data-target="#cancelTripModal"
                className="btn btn-nothing px-3"
              >
                Cancel
                </button>
              <button
                data-toggle="modal"
                data-target="#rescheduleTripModal"
                className="btn btn-nothing px-3 border-left"
              >
                Reschedule
                </button> */}
              {ticketInvoice && (

                <div className="d-inline-block">
                  <button
                    data-toggle="modal"
                    data-target="#cancelTripModal"
                    className="btn btn-nothing px-3"
                  >
                    Cancel
                 </button>
                  {/* <button
                 data-toggle="modal"
                 data-target="#rescheduleTripModal"
                 className="btn btn-nothing px-3 border-left"
               >
                 Reschedule
                 </button> */}
                  <button
                    onClick={() => enableEticket()}
                    className="btn btn-nothing px-3 border-right"
                  >
                    Voucher
                </button>
                  {/* <button
                    onClick={() => enableInvoice()}
                    className="btn btn-nothing px-3 border-right"
                  >
                    Invoice
                </button> */}
                  {eticket && (
                    <ETicketPage
                      closeWindow={closeWindow}
                      eticketInfo={eticketInfo}
                    />
                  )}
                  {/* {invoice && (
                    <InvoicePage
                      closeWindow={closeWindow}
                      invoiceInfo={invoiceInfo}
                    />
                  )} */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pad pb-0">
        <div className="row">
          <div className="col-7">
            <div className="hotel-header">
              <div className="heading d-block">
                <p className="label">Hotel</p>
                {/* <h6 className="title">{item.hotel_name}</h6>
                {item && item.hotel_class !== 0
                  ? item.hotel_class + " Star hotel"
                  : ""} */}
                <h6 className="title">{item.hotel_name}</h6>
                {(item && item.hotel_class === 0) ? "" :
                  // item.hotel_class === null ? "" : item.hotel_class + '"Star hotel"'}
                  (!item.hotel_rating ?( item.hotel_ranking > 5 ? 5 : 5) : (item.hotel_rating > 5 ? 5 : 5))+ 'Star hotel'}
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="hotel-rating">
              {/* <h6 className="mb-1">
                {item.hotel_rating} {" "}
                <span className="fs-12">{item.hotel_rating_desc}</span>
              </h6> */}
              <div>
                <p className="rating rating1">
                  <span>
                    Rating{" "}
                    <StarRatings
                      rating={rate}
                      starRatedColor="#f6e58d"
                      numberOfStars={5}
                      name="rating"
                    />
                  </span>
                  <span className="hide-1200 rating-right">
                    {!item.hotel_rating ? item.hotel_ranking > 5 ? 5 : 5 : item.hotel_rating > 5 ? 5 : 5}
                  </span>
                  {/* <span className="pb-1 hide-1200">{rate}</span> */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className="top__text">
          {item.hotel_address}, {item.hotel_city}
        </p>
      </div>
      <div>
        <ul className="hotel-page mt-4">
          {/* New code after dj changedthe api */}
          {item && item.hotel_photos ? (
            <li>
              <div className="hotel-options">
                {item.hotel_photos && (
                  <div className="image">
                    <img
                      src={
                        item.hotel_photos[0]
                          ? item.hotel_photos[0]
                          : ""
                      }
                      alt=""
                    />
                  </div>
                )}
                {!item.hotel_photos[0] && (
                  <div className="image">
                    <div className="hotel-no-img">
                      <div className="cen">
                        <i className="material-icons">
                          photo_size_select_actual
                        </i>
                        <p>No Image</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="hotel-details">
                  <div className="right">
                    <h6 className="title">
                      {baseCurrency} &nbsp;
                      <NumberFormat
                        value={item.hotel_room_info?.hotel_total_price?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </h6>
                    <p className="label">{item.trip_duration} Night</p>
                  </div>
                  <h5 className="title mb-0">
                    {item.hotel_room_info.hotel_room_name}
                  </h5>
                  <p>
                    {baseCurrency}{" "}
                    <NumberFormat
                      value={Math.round(
                        item.hotel_room_info.hotel_total_price /
                        item.trip_duration
                      ).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />{" "}
                    Per night
                  </p>
                  <p>
                    {item.hotel_from} - {item.hotel_to}
                  </p>
                  <div className="rules-extra">
                    <p>
                      <i className="material-icons fs-12">person</i>{" "}
                      {item.hotel_room_info.hotel_room_max_occupancy} Max Person
                    </p>
                    {item.hotel_room_info.hotel_room_breakfast && (
                      <p>
                        <i className="material-icons fs-12">fastfood</i>{" "}
                        Breakfast
                      </p>
                    )}
                    {item.hotel_room_info.hotel_room_dinner_include && (
                      <p>
                        <i className="material-icons fs-12">local_dining</i>{" "}
                        Dinner
                      </p>
                    )}
                    {item.hotel_room_info.hotel_room_refundable && (
                      <p>
                        <i className="material-icons fs-12">money</i> Refundable
                      </p>
                    )}
                  </div>
                  <p>{item.hotel_room_info.hotel_room_description}</p>
                  <div className="clearfix" />
                  {/* <div className="btn-group-margin mt-2"> */}
                  {/* <button
                            // onClick={e => viewRoomAll(list)}
                            className="btn"
                          >
                            View Facilities
                          </button> */}
                  {/* <button
                      data-toggle="modal"
                      data-target={"#exampleModal"}
                      className="btn"
                    >
                      Hotel Policy
                    </button>
                  </div> */}
                </div>
                <div className="clearfix" />
                <div className="hotel-list-iframe hotel-list-iframe1 mt-4">
                  <GoogleMapReact
                    center={{ lat: item.latitude, lng: item.longitude }}
                    defaultZoom={11}
                  >
                    <AnyReactComponent
                      lat={item.latitude}
                      lng={item.longitude}
                      text={item.hotel_name}
                    />
                  </GoogleMapReact>
                </div>
              </div>

              <div
                className="modal fade"
                id={"exampleModal"}
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content border-0">
                    <div className="modal-head">
                      <h6 className="modal-subtitle">Hotel</h6>
                      <h5 className="modal-title" id="exampleModalLabel">
                        Cancellation Policy
                      </h5>
                    </div>
                    <div className="modal-middle pm-30">
                      <ul className="list-style">
                        {item.hotel_policies.map((list, key) => {
                          return <li key={key}>{list.content}</li>;
                        })}
                      </ul>
                    </div>
                    <div className="modal-last btn-group-margin pt-0 pm-30">
                      <button
                        type="button"
                        className="btn btn-badge"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ) : null}

          {/* The below is the old code */}
        </ul>
        {currentItem &&
          !currentItem.currentOrderNo &&
          currentItem.STATUS === "Approved" ? (
            <div className="pad pt-0">
              {/* <h6 className="sub-heading mt-4">Downloads</h6>
            <p className="mb-3"><a href="/assets/images/bg-img.jpg" download><i className="material-icons fs-18 mr-3">
              cloud_download
              </i> Boarding_pass.pdf</a></p>
            <p><a href="/assets/images/bg-img.jpg" download><i className="material-icons fs-18 mr-3">
              cloud_download
              </i> Meal_voucher.pdf</a></p> */}
            </div>
          ) : (
            ""
          )}
      </div>


      <div
        className="modal fade"
        id="cancelTripModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancelTripModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document">
          <div className="modal-content border-0">
            <div className="modal-head">
              <button
                type="button"
                className="close text-white d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 className="modal-title" id="cancelTripModalLabel">
                Cancel
                </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="row">
                <div className="col-md-6">
                  <div className="inner-header p-0 pb-5 mb-2">
                    <div className="shape-header">
                      <div className="left"></div>
                      <div className="right">
                        <h6 className="heading bg-transparent">{item.hotel_name}</h6>
                        <p className="text">Confirmed</p>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  {/* <div className="table-container m-0">
                    <div className="table-responsive">
                      <table className="table table-striped table-selected">
                        <thead>
                          <tr>
                            <th>FARE CHARGES</th>
                            <th className="text-right"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Cancellation Charges</td>
                            <td className="text-right">
                              3000 INR
                          </td>
                          </tr>
                          <tr>
                            <td>Date Change Charges</td>
                            <td className="text-right">
                              3000 INR
                          </td>
                          </tr>
                          <tr>
                            <td>Rescheduling Charges</td>
                            <td className="text-right">
                              3000 INR
                          </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> */}
                </div>
                <div className="col-md-6">
                  <div className="form-group custom textarea">
                    <textarea className="form-control"
                      data-gramm_editor="false"
                      spellCheck="false"
                      placeholder="&nbsp;"
                      required />
                    <label>Reason <span className="text-danger">*</span></label>
                  </div>
                  <div className="form-group custom textarea mt-5 mb-1">
                    <textarea className="form-control"
                      data-gramm_editor="false"
                      spellCheck="false"
                      placeholder="&nbsp;"
                      required />
                    <label>Your Remarks <span className="text-danger">*</span></label>
                  </div>
                  <p>Reason for doing activity behalf of traveler</p>
                </div>
              </div>
              <p className="pt-4"><b>Note:</b> Cancellation or Hotel change indicative. Curio will stop accepting cancellation or change request 3 hours before check in time.</p>

              <div className="modal-last btn-group-margin pt-5">

                <button
                  type="button"
                  className="btn">

                  Cancel Booking
                  </button>
                <button
                  data-dismiss="modal"
                  type="button"
                  className="btn close">
                  Close
                  </button>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="rescheduleTripModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="rescheduleTripModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document">
          <div className="modal-content border-0">
            <div className="modal-head">
              <button
                type="button"
                className="close text-white d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 className="modal-title" id="rescheduleTripModal">
                Reschedule Hotel
                </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="row">
                <div className="col-md-6">
                  <p><b>Hotel: </b> {item.hotel_name}, &nbsp;
                    {item && item.hotel_class !== 0
                      ? item.hotel_class + " Star hotel"
                      : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="form-group custom labelup">
                    <label>Reschedule Type</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-border-basic dropdown-toggle btn-block"
                        data-toggle="dropdown"
                      >
                        Select
                  </button>
                      <div className="dropdown-menu">
                        <button type="button" className="dropdown-item">Option 1</button>
                        <button type="button" className="dropdown-item">Option 2</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group custom labelup">
                    <label>Date</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-border-basic dropdown-toggle btn-block"
                        data-toggle="dropdown"
                      >
                        Select
                      </button>
                      <div className="dropdown-menu">
                        <button type="button" className="dropdown-item">Option 1</button>
                        <button type="button" className="dropdown-item">Option 2</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group custom labelup">
                    <label>Airline</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-border-basic dropdown-toggle btn-block"
                        data-toggle="dropdown"
                      >
                        Select
                      </button>
                      <div className="dropdown-menu">
                        <button type="button" className="dropdown-item">Option 1</button>
                        <button type="button" className="dropdown-item">Option 2</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group custom labelup">
                    <label>Class</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-border-basic dropdown-toggle btn-block"
                        data-toggle="dropdown"
                      >
                        Select
                      </button>
                      <div className="dropdown-menu">
                        <button type="button" className="dropdown-item">Option 1</button>
                        <button type="button" className="dropdown-item">Option 2</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group custom labelup">
                    <label>Time</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-border-basic dropdown-toggle btn-block"
                        data-toggle="dropdown"
                      >
                        Select
                      </button>
                      <div className="dropdown-menu">
                        <button type="button" className="dropdown-item">Option 1</button>
                        <button type="button" className="dropdown-item">Option 2</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lower-part border-top mt-2">
                <p className="py-4">Vistara (UK) Fare Policy</p>
                <div classNmae="row">
                  <div classname="col-md-6">
                    <div className="table-container m-0">
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            <tr className="border-0">
                              <td className="border-0 pl-0">Reschedule Charge:</td>
                              <td className="border-0 pl-0">
                                <b>2,250.00 Per Sector</b>
                              </td>
                            </tr>
                            <tr className="border-0">
                              <td className="border-0 pl-0">Date Change Charge:</td>
                              <td className="border-0 pl-0">
                                <b>2,250.00 Per Sector</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <p className="mb-2 mt-3">Reschedule/Date Change rule:</p>
                <p>In case if amount is non-refundable/non-changable, complete fare will be charged.</p>
                <p>If you are selecting different hotels for Reschedule/Date Change then system does not allow to quote with your existing itinerary. So it has to be treated as fresh ticket  with additional cancellation cost.</p>
              </div>

              <div className="modal-last btn-group-margin pt-5">

                <button
                  type="button"
                  className="btn">

                  Search
                  </button>
                <button
                  data-dismiss="modal"
                  type="button"
                  className="btn close">
                  Cancel
                  </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HotelCartDetail;
