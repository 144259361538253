import React, { Component } from 'react';
import { masterService } from '../../../_services';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { Select, Input } from '../../common/_forms'
import MasterMenu from "./master-menu";
import $ from 'jquery';
import { toast } from 'react-toastify';
import { alertConstants } from '../../../_constants'

class MasterPermissionList extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.state = {
            activePage: 1,
            itemPerPage: 10,
            listData: [],
            listPermissionType: [],
            addFields: {
                permissionTypeId: '',
                functionName: '',
                permissionName: ''
            },
            addErrors: {
                permissionTypeId: '',
                functionName: '',
                permissionName: ''
            },
            editFields: {
                permissionTypeId: '',
                functionName: '',
                permissionName: '',
                id: ''
            },
            editErrors: {
                permissionTypeId: '',
                functionName: '',
                permissionName: '',
            }
        };
    }
    handleChange(e, field) {
        let addFields = this.state.addFields;
        addFields[field] = e.target.value;
        this.setState({ addFields });
        this.setState({ addErrors: '' })
    }
    handleUpdateValidation() {
        let editFields = this.state.editFields;
        let editErrors = {};
        let formIsValid = true;
        if (editFields["functionName"] === '') {
            formIsValid = false;
            editErrors["functionName"] = "Function Name is required";
        } else if (typeof editFields["functionName"] !== "undefined") {
            if (!editFields["functionName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                editErrors["functionName"] = "Only letters allowed";
            }
        }
        if (editFields["permissionName"] === '') {
            formIsValid = false;
            editErrors["permissionName"] = "Permission Name is required";
        } else if (typeof editFields["permissionName"] !== "undefined") {
            if (!editFields["permissionName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                editErrors["permissionName"] = "Only letters allowed";
            }
        }
        if (editFields["permissionTypeId"] === '') {
            formIsValid = false;
            editErrors["permissionTypeId"] = "Permission Type is required";
        }
        this.setState({ editErrors: editErrors });
        return formIsValid;
    }

    handleEditChange(field, e) {
        let editFields = this.state.editFields;
        editFields[field] = e.target.value;
        this.setState({ editFields });
        this.setState({ editErrors: '' })
    }

    handleAddValidation() {
        let addFields = this.state.addFields;
        let addErrors = {};
        let formIsValid = true;
        if (addFields["functionName"] === '') {
            formIsValid = false;
            addErrors["functionName"] = "Function Name is required";
        } else if (typeof addFields["functionName"] !== "undefined") {
            if (!addFields["functionName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                addErrors["functionName"] = "Only letters allowed";
            }
        }
        if (addFields["permissionName"] === '') {
            formIsValid = false;
            addErrors["permissionName"] = "Permission Name is required";
        } else if (typeof addFields["permissionName"] !== "undefined") {
            if (!addFields["permissionName"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                addErrors["permissionName"] = "Only letters allowed";
            }
        }
        if (addFields["permissionTypeId"] === '') {
            formIsValid = false;
            addErrors["permissionTypeId"] = "Permission Type is required";
        }
        this.setState({ addErrors: addErrors });
        return formIsValid;
    }

    addSubmit(e) {
        e.preventDefault();
        if (this.handleAddValidation()) {
            this.loading = true;
            masterService.addPermission(this.state.addFields)
                .then(
                    data => {
                        if (!toast.isActive(this.toastId)) {
                            this.toastId = toast.success(alertConstants.CREATED_SUCCESSFULLY)
                        }
                        this.getList()
                        $('.close').click();
                    }, error => {
                        this.loading = false;
                        if (error.statusCode === 422) {
                            let errors = error.message
                            Object.keys(errors).map(key => {
                                return (
                                    toast.error(errors[key])
                                );
                            })
                        } else {
                            toast.error(error.message)
                        }
                    }
                ).finally(() => { this.loading = false; this.forceUpdate() });
        }
    }

    editSubmit(e) {
        e.preventDefault();
        if (this.handleUpdateValidation()) {
            this.loading = true;
            masterService.editPermission(this.state.editFields)
                .then(
                    data => {
                        if (!toast.isActive(this.toastId)) {
                            this.toastId = toast.success(alertConstants.UPDATED_SUCCESSFULLY)
                        }
                        this.getList()
                        $('.close').click();
                    }, error => {
                        this.loading = false;
                        if (error.statusCode === 422) {
                            let errors = error.message
                            Object.keys(errors).map(key => {
                                return (
                                    toast.error(errors[key])
                                );
                            })
                        } else {
                            toast.error(error.message)
                        }
                    }
                ).finally(() => { this.loading = false; this.forceUpdate() });
        }
    }

    cancelAdd() {
        // this.setState({addErrors:'',addFields:''})
        // document.getElementById("create-form").();
        $('#create-form input').val('')
    }
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber }, () =>
            this.getList());
    }
    handleItemChange(limit) {
        this.setState({ itemPerPage: limit, activePage: 1 }, () =>
            this.getList());
    }

    componentDidMount() {
        this.getList()
        this.permissionList()
    }
    permissionList() {
        masterService.getPermissionType()
            .then(
                data => {
                    if (data != null) {
                        this.setState({ listPermissionType: data.result })
                    }
                }
            ).finally(() => { this.loader = false; this.forceUpdate() });
    }
    loader = false;
    count = 0;
    getList() {
        this.setState({ listData: [] })
        this.loader = true;
        masterService.getPermissionByPage(this.state.activePage, this.state.itemPerPage)
            .then(
                data => {
                    if (data != null) {
                        this.count = data.count
                        this.setState({ listData: data.result })
                    }
                }
            ).finally(() => { this.loader = false; this.forceUpdate() });

    }

    changeStatus(id, status) {
        masterService.activeInactivePermission(id, { activeStatus: status })
            .then(
                data => {
                    if (!toast.isActive(this.toastId)) {
                        this.toastId = toast.success(data.message)
                    }
                    this.getRole()
                }, error => {
                    this.loading = false;
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            ).finally(() => { this.loading = false; this.forceUpdate() });
    }

    doSearch(queryText) {
        //get query result
        var queryResult = [];
        this.props.data.forEach(function (person) {
            if (person.name.toLowerCase().indexOf(queryText) !== -1)
                queryResult.push(person);
        });

        this.setState({
            query: queryText,
            filteredData: queryResult
        })
    }
    permissionTypeNameUpdate = 'Permission Type Name';
    editData(data) {
        let editFields = this.state.editFields;
        editFields['permissionName'] = data.permissionName;
        editFields['permissionTypeId'] = data.permissionTypeId;
        editFields['functionName'] = data.functionName;
        editFields['id'] = data.id;
        this.setState({ editFields });
        this.setState({ editErrors: '' })
        this.state.listPermissionType.forEach(item => {
            if (item.id === data.permissionTypeId) {
                this.permissionTypeNameUpdate = item.permissionTypeName
                // console.log(this.permissionTypeNameUpdate)
            }
        })
    }
    permissionTypeName = 'Permission Type Name'
    changePermissionTypeName(val) {
        this.permissionTypeName = val.permissionTypeName;
        let addFields = this.state.addFields;
        addFields['permissionTypeId'] = val.id;
        this.setState({ addFields });
        this.setState({ addErrors: '' })
        this.forceUpdate()
    }
    changePermissionTypeNameUpdate(val) {
        // console.log(val)
        this.permissionTypeNameUpdate = val.permissionTypeName;
        let editFields = this.state.editFields;
        editFields['permissionTypeId'] = val.id;
        this.setState({ editFields });
        this.setState({ editErrors: '' })
        this.forceUpdate()
    }
    render() {
        const { listData, itemPerPage } = this.state;
        return (

            <div className="master">
                <MasterMenu />
                <div className="inner-wrapper">
                    <NavLink to="" data-toggle="modal" data-target="#CreatUserModal"
                        className="add-button text-white">+</NavLink>
                    <div className="inner-header">
                        <div className="shape-header">
                            <div className="left">M</div>
                            <div className="right">
                                <h6 className="heading">Manage Permission</h6>
                                <p className="text">Lorem Ipsum is simply dummy</p>
                            </div>
                        </div>
                    </div>
                    <div className="py-padding">
                        <div className="table-container">
                            <form autoComplete="off">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="dropdown">
                                            <button type="button" className="btn dropdown-toggle"
                                                data-toggle="dropdown">
                                                Action
                                                </button>
                                            <div className="dropdown-menu">
                                                <button className="dropdown-item">Make Inactive</button>
                                                <button className="dropdown-item">Delete</button>
                                                <button className="dropdown-item">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 offset-md-8">
                                        <div className="search-custom">
                                            <input type="text" name="" className="form-control" placeholder="Search" />
                                            <span><i className="material-icons">search</i></span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Permission</th>
                                            <th>Action</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.loader === true &&
                                            <tr>
                                                <td colSpan="3">Loading</td>
                                            </tr>
                                        }
                                        {!listData && this.loader === false &&
                                            <tr>
                                                <td colSpan="3" className="text-center">No Records Found</td>
                                            </tr>}
                                        {listData &&
                                            listData.map((item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{item.permissionName}</td>
                                                        <td>
                                                            <button data-toggle="modal" onClick={e => this.editData(item)}
                                                                data-target="#EditUserModal"
                                                                className="btn btn-border-primary btn-sm sm-padding">Edit
                                                        </button>
                                                        </td>
                                                        <td>
                                                            {(item.activeStatus === true) ?
                                                                <div>
                                                                    <label className="switch">
                                                                        <input type="checkbox"
                                                                            onChange={e => this.changeStatus(item.id, "false")}
                                                                            defaultChecked={item.activeStatus === true} />
                                                                        <span className="slider round"></span>
                                                                    </label>

                                                                </div> :
                                                                <div>
                                                                    <label className="switch">
                                                                        <input type="checkbox"
                                                                            onChange={e => this.changeStatus(item.id, "true")} />
                                                                        <span className="slider round"></span>
                                                                    </label>

                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-footer">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="page-count">
                                            <div className="text">Items</div>
                                            <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    {itemPerPage}
                                                </button>
                                                <div className="dropdown-menu">
                                                    <button onClick={e => this.handleItemChange(10)} className="dropdown-item">10</button>
                                                    <button onClick={e => this.handleItemChange(20)} className="dropdown-item">20</button>
                                                    <button onClick={e => this.handleItemChange(30)} className="dropdown-item">30</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        {listData &&
                                            <Pagination
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.itemPerPage}
                                                totalItemsCount={this.count}
                                                pageRangeDisplayed={3}
                                                onChange={this.handlePageChange}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="CreatUserModal" tabIndex="-1" role="dialog"
                    aria-labelledby="CreateUserModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form autoComplete="off" id="create-form" onSubmit={this.addSubmit.bind(this)}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="CreateUserModalLabel">Create Permission Type</h5>
                                    <button type="button" onClick={e => this.cancelAdd(e)} className="close"
                                        data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div
                                        className={(this.state.addErrors["permissionName"]) ? 'has-error form-group custom' : 'form-group custom'}>
                                        <Input
                                            label="Permission Name"
                                            type="text"
                                            name="permissionName"
                                            error={this.state.addErrors["permissionName"]}
                                            value={this.state.addFields["permissionName"]}
                                            controlFunc={e => this.handleChange(e, "permissionName")}
                                        />
                                    </div>
                                    <div className={(this.state.addErrors["functionName"]) ? 'has-error form-group custom' : 'form-group custom'}>
                                        <Input
                                            label="Function Name"
                                            type="text"
                                            name="functionName"
                                            error={this.state.addErrors["functionName"]}
                                            value={this.state.addFields["functionName"]}
                                            controlFunc={e => this.handleChange(e, "functionName")}
                                        />
                                    </div>
                                    <div
                                        className={(this.state.addErrors["permissionTypeId"]) ? 'has-error form-group custom' : 'form-group custom'}>
                                        {this.state.listPermissionType &&
                                            <Select
                                                placeholder={this.permissionTypeName}
                                                keyName={'permissionTypeName'}
                                                options={this.state.listPermissionType}
                                                controlFunc={e => this.changePermissionTypeName(e)}
                                                error={this.state.addErrors["permissionTypeId"]}
                                            />}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-border-success lg-padding">Save</button>
                                    <button type="button" onClick={e => this.cancelAdd(e)}
                                        className="btn btn-border-basic lg-padding" data-dismiss="modal">Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="EditUserModal" tabIndex="-1" role="dialog"
                    aria-labelledby="EditUserModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form autoComplete="off" id="edit-form" onSubmit={this.editSubmit.bind(this)}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="EditUserModalLabel">Edit Permission Type</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div
                                        className={(this.state.editErrors["permissionName"]) ? 'has-error form-group custom' : 'form-group custom'}>
                                        <Input
                                            label="Permission Name"
                                            type="text"
                                            name="permissionName"
                                            error={this.state.editErrors["permissionName"]}
                                            value={this.state.editFields["permissionName"]}
                                            controlFunc={e => this.handleEditChange(e, "permissionName")}
                                        />
                                    </div>
                                    <div
                                        className={(this.state.editErrors["functionName"]) ? 'has-error form-group custom' : 'form-group custom'}>
                                        <Input
                                            label="Functional Name"
                                            type="text"
                                            name="functionName"
                                            error={this.state.editErrors["functionName"]}
                                            value={this.state.editFields["functionName"]}
                                            controlFunc={e => this.handleEditChange(e, "functionName")}
                                        />
                                    </div>
                                    <div
                                        className={(this.state.editErrors["permissionTypeId"]) ? 'has-error form-group custom' : 'form-group custom'}>
                                        {this.state.listPermissionType &&
                                            <Select
                                                placeholder={this.permissionTypeNameUpdate}
                                                keyName={'permissionTypeName'}
                                                options={this.state.listPermissionType}
                                                controlFunc={e => this.changePermissionTypeNameUpdate(e)}
                                                error={this.state.editErrors["permissionTypeId"]}
                                            />}

                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-border-success lg-padding">Update</button>
                                    <button type="button" onClick={this.cancelAdd()}
                                        className="btn btn-border-basic lg-padding" data-dismiss="modal">Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default MasterPermissionList