import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import ExpenseTypesMenu from "../expense-types-menu";
import { Select } from '../../../../../../common/_forms';
// import Select from "react-select";
import { ExpenseTypePolicy } from "./components";
import { reject } from "lodash/fp";
import Loader from "src/_components/common/Loader";

const ExpenseTypeStep2Page = props => {
  const {
    listPolicies,
    activePage,
    itemsPerPage,
    handlePageChange,
    count,
    pageItemsList,
    handleItemChange,
    expenseEntries,
    itemizedEntries,
    attendeeEntries,
    onCreateExpenseTypePolicy,
    onEditExpenseTypePolicy,
    expenseTypeId,
    loading,
  } = props;
  const { allSetPolicies } = props.allState;
  let expenseEntriesOptions = [];
  let itemizedEntriesOptions = [];
  let attendeeEntriesOptions = [];

  // * create options for expenseEntries
  if (expenseEntries) {
    expenseEntriesOptions = expenseEntries.map(item => {
      return {
        value: item.id,
        label: item.formName
      };
    });
  }

  // * create options for ItemEntries
  if (itemizedEntries) {
    itemizedEntriesOptions = itemizedEntries.map(item => {
      return {
        value: item.id,
        label: item.formName
      };
    });
  }

  // * create options for Attendee
  if (attendeeEntries) {
    attendeeEntriesOptions = attendeeEntries.map(item => {
      return {
        value: item.id,
        label: item.formName
      };
    });
  }

  let restPolicies = reject(
    policy =>
      allSetPolicies.some(setPolicy => setPolicy.policyId.id === policy.id),
    listPolicies
  );

  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left"> E </div>
          <div className="right">
            <h6 className="heading"> Expense Types </h6>
            <p className="text">Add/Modify Expense Type</p>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <ExpenseTypesMenu
            expenseTypeId={expenseTypeId} />
          <div className="table-container min-height-p">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th> Enabled for these<br /> policy names </th>
                    <th> For an expense entry<br /> use this form <span class="text-danger">*</span> </th>
                    <th> For an itemized entry<br /> use this form <span class="text-danger">*</span> </th>
                    {/* <th> Use this <br/>Vendor list </th> */}
                    <th> Select Attendee <br /> form <span class="text-danger">*</span></th>
                    <th>Action</th>
                    <th> Assign </th>
                  </tr>
                </thead>
                <tbody>
                  {listPolicies && listPolicies.length === 0 && (
                    <tr>
                      <td colSpan="5" className="text-center">
                      {(loading)?
                        <Loader />:
                          "No Records Found"}
                      </td>
                    </tr>
                  )}
                  {/* {(allSetPolicies.length !== 0 ) && 
                  <tr><td colSpan="5" className="fw-sbold fs-14">Set Policies</td></tr>} */}
                  {/* Start of all set policies */}
                  {(allSetPolicies) &&
                    allSetPolicies.map(policy => { 
                      return (
                        <ExpenseTypePolicy
                          expenseEntriesOptions={expenseEntriesOptions}
                          itemizedEntriesOptions={itemizedEntriesOptions}
                          attendeeEntriesOptions={attendeeEntriesOptions}
                          key={policy.id}
                          edit={true}
                          expenseTypePolicy={policy}
                          policy={policy.policyId}
                          name={policy ?.policyId ?.policyName}
                          hideSetting={false}
                          onChange={data =>
                            // before this was policy?.id
                            onEditExpenseTypePolicy(policy.id, data)
                          }
                        />
                      );
                    })}
                  {/* {(restPolicies.length !== 0) && 
                  <tr><td colSpan="5" className="fw-sbold fs-14">Remaining Policies</td></tr>} */}

                  {/* End of all Set Policies */}
                  {restPolicies &&
                    restPolicies.map(policy => (
                      <ExpenseTypePolicy
                        expenseEntriesOptions={expenseEntriesOptions}
                        itemizedEntriesOptions={itemizedEntriesOptions}
                        attendeeEntriesOptions={attendeeEntriesOptions}
                        key={policy.id}
                        policy={policy}
                        name={policy.policyName}
                        onChange={onCreateExpenseTypePolicy}
                        hideSetting={true}
                      />
                    ))}
                </tbody>
              </table>
            </div>
           
            <div className="table-footer">
              {listPolicies && listPolicies.length !== 0 && (
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text"> Items </div>
                      <div className="count">
                        {pageItemsList && (
                          <Select
                            placeholder={itemsPerPage}
                            keyName={"items"}
                            options={pageItemsList}
                            controlFunc={e => handleItemChange(e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={count}
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
              <div className="btn-group-margin mt-4">
                <NavLink to={`/administration/expense/ex/types/expense-types-3/${expenseTypeId}`} className="btn">
                  Next
                  </NavLink>
                <NavLink to="/administration/expense/ex/types/list" className="btn">
                  Cancel
                </NavLink>
              </div>
              {/* <div className="mt-4">
              <div className="btn-group-margin">
                <NavLink to="/administration/expense/expense-types-step3" className="btn btn-border-success lg-padding">Save</NavLink>
                <button className="btn btn-border-basic lg-padding">Cancel</button>
              </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpenseTypeStep2Page;
