import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from 'jquery';
import NumberFormat from 'react-number-format';
class RespCartFilter extends Component {
  openFilter() {
    $("#filter-card").addClass("show");
  }
  openCart() {
    var cart = document.getElementById("cart");
    cart.classList.add("show");
  }
  render() {
    const { cardList, currency } = this.props;
    return (
      <div className="resp-cart-filter">
        <button
          className="filter-resp"
          onClick={e => this.openFilter()}
        >
          <p>
            <i className="flaticon-adjust" />
            Filter
            </p>
        </button>
        <button className="cart-resp-val">
          <div className="float-left">
            <i className="material-icons">shopping_cart</i>
          </div>
          <div className="right">
            <p className="label">Total</p>
            <h6 className="heading">{currency}{" "}
              <NumberFormat value={cardList.flightData && cardList.flightData.toFixed(2)} displayType={'text'} thousandSeparator={true} />
            </h6>
          </div>
        </button>
        <button className="cart-resp-open" onClick={e => this.openCart()}>
          <div className="float-right">
            <i className="material-icons">arrow_forward</i>
          </div>
          <p className="label">Open</p>
          <h6 className="heading">Cart</h6>
        </button>
      </div>
    );
  }
}
export default RespCartFilter;
