import React, { Component } from 'react';
import CustomStep2Page from './custom-step2-html';
import uuid from 'uuid';
import { toast } from 'react-toastify';
import SimpleReactValidator from "simple-react-validator";
import { expenseReport } from './../../../../../../_services/expenseReport';
import { auditRuleService } from "../../../../../../_services/auditRule.service";
import dynamicFields from 'src/_components/common/dynamicFields';
import moment from 'moment';
import { setValue, getValue } from 'src/_components/common/lodsh';
import { getConnectedList } from "src/_services";

class CustomStep2 extends Component {
  constructor(props) {
    super(props)
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.validator = new SimpleReactValidator();
    this.state = {
      expressionLetter: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF"],
      companyId: localStorage["440a28"],
      workflow_id: '',
      auditRuleName: '',
      auditRule_id: '',
      isSubmitClick: false,
      isLoading: false,
      selectedStep: [],
      selectedRule: [],
      expressionArray: [],
      allFormHeaders: [],
      allFieldNames: {

      },
      firstBracketOptions: [
        {
          value: '('
        }, {
          value: 'Not Selected'
        }
      ],
      lastBracketOptions: [
        {
          value: ')'
        },
        {
          value: 'Not Selected'
        }
      ],
      isEdit: false,
      expressionOfRules: '',
      remaiminStacks: [],
      allCurrecies: [],
      validAndOrAudit: true,
      gotoNextMenu: false
    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.createExpression();
    let auditRule_id = localStorage.getItem('auditRuleId');
    let auditRuleEditId = this.props.match.params.id;
    if ((auditRule_id === undefined || auditRule_id === null || auditRule_id === "") && (auditRuleEditId === undefined || auditRuleEditId === null || auditRuleEditId === "")) {
      if (auditRule_id === undefined || auditRule_id === null || auditRule_id === "") {
        this.props.history.push('./custom');
      } else {
        this.props.history.push('../custom');
      }
    }
    if (auditRule_id !== undefined && auditRule_id !== null && auditRule_id !== "") {
      this.setState({ auditRule_id: auditRule_id });
    }
    if (this.props.match.params.id !== undefined && this.props.match.params.id !== "" && this.props.match.params.id !== null) {
      this.setState({ auditRule_id: this.props.match.params.id, isEdit: true }, () => {
        this.getEditAuditRules();
      });
    }
    this.getAllcurrencyList();
  }

  getAllFormHeaders = async (serviceModuleIdes) => {
    this.setState({ isLoading: true });
    let serviceModuleId = ["5c74ed3d77addf3b07acb014"];
    serviceModuleId.push(serviceModuleIdes);
    let request = {
      serviceModuleId: serviceModuleId
    }
    let res = await auditRuleService.getFormHeader(request);
    if (res && res.result) {
      this.setState({
        allFormHeaders: res.result, isLoading: false
      })
    } else {
      this.setState({ isLoading: false });
    }
  }

  getFormFieldsByFormHeader = async (formHeaderId, id) => {
    this.setState({ ['isLoading' + id]: true });
    let response = await auditRuleService.getFieldNames(this.state.companyId, formHeaderId);
    if (response) {
      this.setState(({ allFieldNames }) => ({
        allFieldNames: {
          ...allFieldNames,
          [formHeaderId]: response.result
        }
      }));
      this.setState({ ['isLoading' + id]: false });
    } else {
      this.setState({ ['isLoading' + id]: false });
    }
  }

  getEditAuditRules = async () => {
    this.setState({ isLoading: true });
    let companyId = this.state.companyId;
    let auditRule_id = this.state.auditRule_id;
    let response = await auditRuleService.getAuditRuleByid(companyId, auditRule_id);
    if (response) {
      this.getAllFormHeaders(response.result.RuleType);
      if (response.result?.applyRules?.rules) {
        let expressionArray = response.result.applyRules.rules;
        await this.setState({ expressionArray: expressionArray });
        response.result.applyRules.rules.forEach(async (listData, index) => {
          if (index % 2 === 0 && listData.value && listData.value.dataObjectName !== undefined && listData.value.dataObjectName !== '') {
            await this.handleFormHeaderSelection(listData.value && listData.value.dataObjectName, listData.id, '');
            await this.handleFieldNameSelection(listData.value.dataObjectFieldName, listData.id);
            await this.editFieldValueChangeHandler(listData.value.dataObjectFieldValue, listData.id);
          }
        });
        expressionArray.push({
          id: uuid.v4(),
          type: 'operator',
          value: {}
        });
        await this.setState({ expressionArray: expressionArray, isLoading: false, gotoNextMenu: true });
      } else {
        this.setState({ isLoading: false });
      }

    } else {
      this.setState({ isLoading: false, gotoNextMenu: false });
    }
  }

  getAllcurrencyList = async () => {
    let response = await auditRuleService.getCurrencyList(this.state.companyId);
    if (response.result.length > 0) {
      this.setState({
        allCurrecies: response.result.map((currencies) => ({ id: currencies.id, value: currencies.id, label: currencies.itemCode }))
      })
    } else {
      this.setState({ allCurrecies: [] });
    }
  }

  editFieldValueChangeHandler = (values, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      currentExpression.value = {
        ...currentExpression.value,
        selectedFieldValue: values
      }
    }

    this.setState({ expressionArray });
  }

  createExpression = (e, idx = 0) => {

    // * for the first case e will not exist
    // eslint-disable-next-line no-unused-expressions
    e?.preventDefault?.();
    let expressionArray = [...this.state.expressionArray];
    let newExpression = [{
      id: uuid.v4(),
      type: 'operand',
      value: {},
    }, {
      id: uuid.v4(),
      type: 'operator',
      value: {}
    }];
    // * New expression length is being added to to idx, because insertion will take place at idx + 2 (previous expression + AND/OR)
    expressionArray.splice(idx + newExpression.length, 0, ...newExpression);
    // expressionArray.push(...newExpression)
    this.setState({ expressionArray: expressionArray });
  }
  removeExpression = (e, id) => {
    e.preventDefault();
    let expressionArray = [...this.state.expressionArray];
    expressionArray.splice(id, 2);
    this.setState({ expressionArray })
  }


  handleFormHeaderSelection = (selectedHeader, id, change) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      if (change === 'change') {
        currentExpression.value = {
          ...currentExpression.value,
          formHeader: selectedHeader,
          selectedField: '',
          selectedFieldValue: '',
          operator: '',
          parentsFields: []
        }
      } else {
        currentExpression.value = {
          ...currentExpression.value,
          formHeader: selectedHeader,
          selectedField: '',
          selectedFieldValue: ''
        }
      }
    }
    this.setState({
      expressionArray
    });
    this.getFormFieldsByFormHeader(selectedHeader.id, id !== undefined ? id : '');
  }
  sortArrayOfObjectBasisOfFieldLbl = (formAndFields) => {
    formAndFields.sort(function (fieldObject1, fieldObject2) {
      var keyA = new Date(fieldObject1.fieldLevel),
        keyB = new Date(fieldObject2.fieldLevel);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return formAndFields;
  }
  resetStateOfMultiLabelField = (fields) => {
    let expressionArray = [];
    fields.forEach((formFields) => {
      let state = [
        [formFields.columnName],
        [formFields.fieldName]
      ]
      expressionArray = [...expressionArray, ...state];
    })
    let data = expressionArray.flat(2);
    let fieldsObject = {};
    data.forEach((data, index) => {
      if (index % 2) {
        fieldsObject[data] = '';
      } else {
        fieldsObject[data] = [];
      }
    })
    return fieldsObject;
  }
  handleFieldNameSelection = (selectedField, id, change) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    let fieldNames = { ...this.state.allFieldNames };
    let formHeaderId = selectedField.formHeaderId ? selectedField.formHeaderId ? selectedField.formHeaderId : selectedField.value.formHeader.id : '';
    let isSystemField = selectedField.systemField ? true : false;
    if (selectedField.type !== dynamicFields.DATA_TYPE_SYSTEM_LIST && formHeaderId) {
      if (currentExpression) {
        if (change === 'change') {
          let parentFields = fieldNames[formHeaderId].filter((formFields) => (formFields.id === selectedField.multiSelectParentField && formFields.fieldLevel < selectedField.fieldLevel) || (formFields.multiSelectParentField === selectedField.multiSelectParentField && formFields.fieldLevel < selectedField.fieldLevel));
          parentFields = this.sortArrayOfObjectBasisOfFieldLbl(parentFields);
          let fields = parentFields.length > 0 && parentFields.map((fields) => ({ fieldName: [fields.fieldName], columnName: [fields.columnName] }));
          let resetParentFieldState = {};
          if (fields.length > 0) {
            resetParentFieldState = this.resetStateOfMultiLabelField(fields, id)
          }
          currentExpression.value = {
            ...currentExpression.value,
            selectedField: selectedField,
            selectedFieldValue: '',
            isSystemField: isSystemField,
            operator: '',
            ...resetParentFieldState,
            parentsFields: parentFields.length > 0 ? parentFields : [],
            isMultiSelected: parentFields.length > 0 ? true : false,
            currency: ''
          }
        } else {
          currentExpression.value = {
            ...currentExpression.value,
            selectedField: selectedField,
            isSystemField: isSystemField,
            selectedFieldValue: ''
          }
        }
      } else {
        currentExpression.value = {
          ...currentExpression.value,
          isSystemField: isSystemField,
          selectedField: [],
          parentsFields: [],
          selectedFieldValue: '',
        }
      }
    } else {
      if (currentExpression) {
        if (change === 'change') {
          currentExpression.value = {
            ...currentExpression.value,
            selectedField: selectedField,
            selectedFieldValue: '',
            operator: '',
            currency: '',
            isSystemField: isSystemField,
            isMultiSelected: false,
            parentsFields: []
          }
        } else {
          currentExpression.value = {
            ...currentExpression.value,
            selectedField: selectedField,
            selectedFieldValue: '',
            isSystemField: isSystemField,
          }
        }

      } else {
        currentExpression.value = {
          ...currentExpression.value,
          isMultiSelected: false,
          selectedField: [],
          parentsFields: []
        }
      }
    }

    this.setState({ expressionArray });
  }

  onMultiLblConnectHandler = async (value, selectedFieldDetails, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    let selectedFieldColumnName = currentExpression.value.selectedField.columnName;
    let selectedField = { ...currentExpression.value.selectedField };
    selectedField.connectedListData = {}
    let newFieldLevel = selectedFieldDetails.fieldLevel + 1;
    let fieldNames = { ...this.state.allFieldNames };
    let formHeaderId = selectedFieldDetails.formHeaderId ? selectedFieldDetails.formHeaderId : selectedFieldDetails.value.formHeader.id;
    if (selectedFieldDetails.fieldLevel === 1) {
      let existConnected = fieldNames[formHeaderId].filter((formFields) => selectedFieldDetails.id === formFields.multiSelectParentField && formFields.fieldLevel === newFieldLevel);
      if (existConnected.length > 0) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(companyId, value.value);
        let childName = existConnected[0].columnName;
        let fieldValue = existConnected[0].fieldName;
        if (response && selectedFieldColumnName === childName) {
          selectedField.connectedListData.listManagementData = response;
          currentExpression.value = {
            ...currentExpression.value, [selectedFieldDetails.fieldName]: value,
            selectedField: selectedField, selectedFieldValue: ''
          }
        } else {
          currentExpression.value = {
            ...currentExpression.value, [selectedFieldDetails.fieldName]: value,
            [fieldValue]: '', [childName]: response.map((list) => ({ label: list.itemName, value: list.id, id: list.id, columnName: selectedFieldDetails.childColumnName }))
          }
        }
      }
    } else if (selectedFieldDetails.fieldLevel > 1) {
      let existConnected = fieldNames[formHeaderId].filter((formFields) => selectedFieldDetails.multiSelectParentField === formFields.multiSelectParentField && formFields.fieldLevel === newFieldLevel);
      if (existConnected.length > 0) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(companyId, value.value);
        let childName = existConnected[0].columnName;
        let fieldValue = existConnected[0].fieldName;
        if (response && selectedFieldColumnName === childName) {
          selectedField.connectedListData.listManagementData = response;
          currentExpression.value = {
            ...currentExpression.value, [selectedFieldDetails.fieldName]: value,
            selectedField: selectedField, selectedFieldValue: ''
          }
        } else {
          currentExpression.value = {
            ...currentExpression.value, [selectedFieldDetails.fieldName]: value, [fieldValue]: '', [childName]: response.map((list) => ({ label: list.itemName, value: list.id, id: list.id, columnName: selectedField.childColumnName })),
          };
        }
      }
    }
    this.setState({ expressionArray });
  }

  handleParenthesesSelection = (selectedBracket, id, bracketType, expressionLetter) => {
    let expressionArray = [...this.state.expressionArray];
    let remaiminStacks = [...this.state.remaiminStacks];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      if (bracketType === '(') {
        currentExpression.value = {
          ...currentExpression.value,
          // firstBracket: selectedBracket.value
          firstBracket: selectedBracket.value === "Not Selected"?"":selectedBracket.value
        }
        remaiminStacks.push(bracketType);
        this.setState({ remaiminStacks: remaiminStacks });
        //this.createExpressionForAudit();
      } else if (bracketType === ')') {
        currentExpression.value = {
          ...currentExpression.value,
          // lastBracket: selectedBracket.value
          lastBracket: selectedBracket.value === "Not Selected"?"":selectedBracket.value
        }
        let remaiminStacksLength = remaiminStacks.length;
        if (remaiminStacks[remaiminStacksLength - 1] === '(') {
          remaiminStacks.pop();
        }
        this.setState({ remaiminStacks: remaiminStacks });
        //this.createExpressionForAudit();
      }
    }
    this.setState({
      expressionArray
    })
  }
  //its use for show created expense of audit rules we implement it later
  createExpressionForAudit = () => {
    // let expressionArray = [...this.state.expressionArray];
    // let expressionOfRules = this.state.expressionOfRules;
    // for (let index = 0; index < expressionArray.length; index+2) {
    // }
  }

  handleRuleNameChange = (e) => {
    this.setState({
      auditRuleName: e.target.value
    });
  }
  expressionCheckboxHandler = (e, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      currentExpression.value = {
        ...currentExpression.value,
        isChecked: e.target.checked
      }
      this.createExpressionForAudit();
    }
    this.setState({ expressionArray });

  }
  handleRadioButtonChange = (e, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      currentExpression.value = {
        operator: e.target.value
      }

    }
    this.setState({ expressionArray });
  }
  handleExpressionOperator = (selectedOperator, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      currentExpression.value = {
        ...currentExpression.value,
        operator: selectedOperator
      }
    }
    this.setState({ expressionArray });
  }

  onCheckboxChangeHandler = (event, id, values) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      currentExpression.value = {
        ...currentExpression.value,
        check: !values
      }
    }
    this.setState({ expressionArray });
  }

  fieldValueSelectHandler = (selectedFieldValue, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      currentExpression.value = {
        ...currentExpression.value,
        selectedFieldValue: selectedFieldValue
      }
    }
    this.setState({ expressionArray });
  }
  onCurrencySelectHandler = (currency, id) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (currentExpression) {
      currentExpression.value = {
        ...currentExpression.value,
        currency: currency
      }
    }
    this.setState({ expressionArray });
  }

  fieldValueChangeHandler = (e, id, dataType) => {
    let expressionArray = [...this.state.expressionArray];
    let currentExpression = expressionArray.find(expression => expression.id === id);
    if (dataType === dynamicFields.DATA_TYPE_INTEGER || dataType === dynamicFields.DATA_TYPE_AMOUNT) {
      if (currentExpression && !isNaN(e.target.value)) {
        currentExpression.value = {
          ...currentExpression.value,
          selectedFieldValue: +e.target.value
        }
      }
    } else if (dataType === dynamicFields.DATA_TYPE_BOOLEAN || dataType === dynamicFields.DATA_TYPE_CHECKBOX) {
      currentExpression.value = {
        ...currentExpression.value,
        selectedFieldValue: !currentExpression.value.selectedFieldValue
      }
    } else if (dataType === dynamicFields.DATA_TYPE_DATE) {
      if (!isNaN(e)) {
        currentExpression.value = {
          ...currentExpression.value,
          selectedFieldValue: moment(e).format("DD MMM YYYY")
        }
      }
    } else if (dataType === dynamicFields.DATA_TYPE_SEARCH) {
      currentExpression.value = {
        ...currentExpression.value,
        selectedFieldValue: e
      }
    }
    else {
      if (currentExpression) {
        currentExpression.value = {
          ...currentExpression.value,
          selectedFieldValue: e.target.value
        }
      }
    }
    this.setState({ expressionArray });
  }

  // * Create and submit new rule

  createRuleHandler = async (e) => {
    e.preventDefault();
    let remaiminStacks = [];
    let validAndOrAudit = this.state.validAndOrAudit;
    if (this.validator.allValid()) {
      this.setState({ isSubmitClick: true });
      let lengthOfExpressionArray = this.state.expressionArray.length;
      let expressionArray = this.state.expressionArray.slice(0, lengthOfExpressionArray - 1);
      let expression2 = expressionArray.map((expression, idx) => {
        if (idx % 2 === 0) {
          let firstBracket = expression.value.firstBracket !== undefined ? expression.value.firstBracket : '';
          let lastBracket = expression.value.lastBracket !== undefined ? expression.value.lastBracket : '';
          if (firstBracket !== undefined && firstBracket !== "" && firstBracket === "(") {
            remaiminStacks.push(firstBracket);
          }
          if (lastBracket !== undefined && lastBracket !== "" && lastBracket === ")") {
            if (remaiminStacks.length > 0) {
              remaiminStacks.pop();
            } else {
              validAndOrAudit = validAndOrAudit && false;
            }

          }
          if (expression.value.isMultiSelected) {
            return {
              id: expression.id,
              type: expression.type,
              value: {
                ...expression.value,
                dataObjectFieldName: expression.value.selectedField,
                dataObjectName: expression.value.formHeader,
                dataObjectFieldValue: expression.value.selectedFieldValue
              }
            }
          } else {
            return {
              id: expression.id,
              type: expression.type,
              value: {
                firstBracket: expression.value.firstBracket ? expression.value.firstBracket : "",
                dataObjectName: expression.value.formHeader,
                isChecked: expression.value.isChecked ? expression.value.isChecked : false,
                lastBracket: expression.value.lastBracket ? expression.value.lastBracket : "",
                operator: expression.value.operator,
                dataObjectFieldName: expression.value.selectedField,
                dataObjectFieldValue: expression.value.selectedFieldValue,
                check: expression.value.check ? expression.value.check : false,
                isMultiSelected: false,
                currency: expression.value.currency ? expression.value.currency : '',
                isSystemField: expression.value.isSystemField
              }
            }
          }

        } else {
          if (expression.value.operator !== undefined && expression.value.operator !== "" && expression.value.operator !== null) {
            validAndOrAudit = validAndOrAudit && true;
          } else {
            validAndOrAudit = validAndOrAudit && false;
          }
          return expression
        }
      })
      // remove unwanted list mangement data
      expression2.forEach(item => {
        if (item.type === "operand") {
          if (getValue(item, "value.parentsFields", []).length !== 0) {
            getValue(item, "value.parentsFields", []).forEach(list => {
              let removeId = getValue(item, `value.${list.labelName}`, '')
              let remain = getValue(list, "connectedListData.listManagementData", []).filter(resp => { return resp.id === removeId.value });
              setValue(list, "connectedListData.listManagementData", remain)
            })
          } else {
            let removeId = getValue(item, "value.selectedFieldValue")
            let remain = getValue(item, "value.selectedField.connectedListData.listManagementData", []).filter(resp => { return resp.id === removeId.value });
            setValue(item, "value.selectedField.connectedListData.listManagementData", remain)
            setValue(item,"value.dataObjectFieldName.connectedListData.listManagementData",[])

          }
        }
      })
      // setValue(expression2[0].value.dataObjectFieldName, "connectedListData.listManagementData", [])

      let payload = {
        companyId: this.state.companyId,
        id: this.state.auditRule_id,
        applyRules: {
          rules: expression2

        }
      }

      if (remaiminStacks.length > 0 || !validAndOrAudit) {
        toast.error('Your expression not valid.');
        this.setState({ isSubmitClick: false });
      } else {
        let res = await auditRuleService.updateAuditRule(payload);
        if (res) {
          if (this.state.isEdit) {
            this.props.history.push(`../step3/${this.state.auditRule_id}`);
          } else {
            this.props.history.push(`./step3/${this.state.auditRule_id}`);
          }
          toast.success(res.message);
        } else {
          this.setState({ isSubmitClick: false });
          toast.warn('Not created.');
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }

  }

  // * All rule handlers

  // * Select a rule

  ruleSelectHandler = async (rule) => {
    // * when rule is selected, deselect step

    if (getValue(rule, "data.rules[0].value.dataObjectFieldName")) {
      let resp = await getConnectedList(this.state.companyId, '', rule.data.rules[0].value.dataObjectFieldName.connectedListId, 1, 10)
      rule.data.rules[0].value.dataObjectFieldName.page = 1;
      rule.data.rules[0].value.dataObjectFieldName.connectedListDataCount = getValue(resp, "count", 0);
      rule.data.rules[0].value.dataObjectFieldName.connectedListData.listManagementData = resp.result;
      rule.data.rules[0].value.dataObjectFieldName.search = ''
    }

    this.setState({
      selectedStep: [],

    })
    let selectedRule = [...this.state.selectedRule];
    let selectedRuleIndex = selectedRule.indexOf(rule);

    // let expressionArray = [...this.state.expressionArray];

    if (selectedRuleIndex === -1) {
      // * from selected rule get workflow step and rule id
      this.setState({
        workflowRule_id: rule.workflowRule_id,
        auditRuleName: rule.auditRuleName
      })
      selectedRule = [rule];
      let expressionFromSelectedRule = rule.data.rules;
      let expressionArray = expressionFromSelectedRule.map((expression, idx) => {
        if (idx % 2 === 0) {
          this.getFormFieldsByFormHeader(expression.value.dataObjectName.id, expression.id);

          return {
            id: expression.id,
            type: expression.type,
            value: {
              firstBracket: expression.value.firstBracket,
              lastBracket: expression.value.lastBracket,
              isChecked: expression.value.isChecked,
              operator: expression.value.operator,
              formHeader: expression.value.dataObjectName,
              selectedField: expression.value.dataObjectFieldName,
              selectedFieldValue: expression.value.dataObjectFieldValue
            }
          }
        } else {
          return expression
        }
      });
      this.setState({ expressionArray });

    } else {
      selectedRule = [];
      this.setState({
        auditRule_id: '',
        auditRuleName: ''
      })

    }
    this.setState({ selectedRule });
  }


  onCancelClickHanler = (event) => {
    event.preventDefault();
    if (this.state.isEdit) {
      this.props.history.push('../custom');
    } else {
      this.props.history.push('./custom');
    }
  }
  loadOptions = async (newValue) => {
    return expenseReport.getAllLocationsName(newValue).then((data) => {
      return data.result.map(list => {
        return {
          label: list.name + ',' + list.countryId.name,
          value: list.id,
          id: list.id
        };
      });
    })
  }
  render() {
    return (
      <div>
        <CustomStep2Page
          {...this.state}
          validator={this.validator}
          createExpression={this.createExpression}
          removeExpression={this.removeExpression}
          handleFormHeaderSelection={this.handleFormHeaderSelection}
          handleFieldNameSelection={this.handleFieldNameSelection}
          handleParenthesesSelection={this.handleParenthesesSelection}
          handleRuleNameChange={this.handleRuleNameChange}
          expressionCheckboxHandler={this.expressionCheckboxHandler}
          handleRadioButtonChange={this.handleRadioButtonChange}
          handleExpressionOperator={this.handleExpressionOperator}
          fieldValueSelectHandler={this.fieldValueSelectHandler}
          fieldValueChangeHandler={this.fieldValueChangeHandler}
          createRuleHandler={this.createRuleHandler}
          ruleSelectHandler={this.ruleSelectHandler}
          modifyRuleHandler={this.modifyRuleHandler}
          updateRuleHandler={this.updateRuleHandler}
          onCancelClickHanler={this.onCancelClickHanler}
          onCheckboxChangeHandler={this.onCheckboxChangeHandler}
          onMultiLblConnectHandler={this.onMultiLblConnectHandler}
          onCurrencySelectHandler={this.onCurrencySelectHandler}
          onLocationChangekHandler={this.onLocationChangekHandler}
          loadOptions={this.loadOptions}
        />
      </div>
    )
  }
}
export default CustomStep2;
