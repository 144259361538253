import { http } from "../_helpers";
import { config } from "../env";

const carConfigData = (companyId, limit, page) => {
    let url = `${config.apiExpense}car-configuration/all-configs?companyId=${companyId}&limit=${limit}&page=${page}`
    return http.get(url)
        .then(res => res)
}

const onStatusChangeHandler = (id, status) => {
    let url = `${config.apiExpense}car-config/changestatus/${id}`;
    return http.patch(url, status)
        .then(res => res)
}
const carConfigsSearch = (companyId, search) => {
    let url = `${config.apiExpense}car-configuration/all-configs?companyId=${companyId}&search=${search}`
    return http.get(url)
        .then(res => res)
}

const removeItemHandler = (deleteId) => {
    let url = `${config.apiExpense}car-config/remove/${deleteId}`;
    return http.patch(url)
        .then(res => res)
}
const updateCarConfiguration = (carId) => {
    let url = `${config.apiExpense}car-config/get-one-config/${carId}`;
    return http.get(url)
        .then(res => {
            return res;
        })
}

const getCarTypes = (companyId) => {
    let url = `${config.apiUrlCompany}list-management?companyId=${companyId}&itemCode=CAR564`
    return http.get(url)
        .then(res => res)
}
const getCountryTypes = () => {
    let url = `${config.apiUrlSuperAdmin}countries`
    return http.get(url)
        .then(res => res)
}
const getCurrencyTypes = (companyId) => {
    let url = `${config.apiUrlCompany}list-management?companyId=${companyId}&itemCode=CUR001`
    return http.get(url)
        .then(res => res)
}

const getEditableTypes = (companyId) => {
    let url = `${config.apiUrlCompany}list-management?companyId=${companyId}&itemCode=GRP001`
    return http.get(url)
        .then(res => res)
}

const setStatus = (data, id) => {
    let url = `${config.apiExpense}car-config/vehicle-active-inactive/${id}`
    return http.put(url, data)
        .then(res => res)
}
const carConfigform = (data) => {
    let url = `${config.apiExpense}car-configuration/add-configuration`
    return http.post(url, data)
        .then(res => res)
}

const updateCarRates = (id, data) => {
    let url = `${config.apiExpense}car-configuration/add-rates/${id}`;
    return http.patch(url, data)
        .then(res => res)
}
export const carConfigService = {
    carConfigData,
    onStatusChangeHandler,
    carConfigsSearch,
    removeItemHandler,
    updateCarConfiguration,
    getCarTypes,
    getCountryTypes,
    getCurrencyTypes,
    getEditableTypes,
    setStatus,
    carConfigform,
    updateCarRates
}