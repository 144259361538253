import React, { Component } from "react";
import HourlyAllowancePage from "./hourly-allowance-html";
import _ from "lodash";
import { expenseTravelAllowanceServise } from "src/_services";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";

class HourlyAllowance extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.validator = new SimpleReactValidator();
    this.state = {
      count: 2,
      arrayCount: [0, 1],
      selectedRow: [],
      status: false,
      companyId: localStorage["440a28"],
      editAllowanceConfig: []
    }
  }
  componentDidMount() {
    this.getHourlyAllowanceDetails();
  }
  getHourlyAllowanceDetails = async () => {
    let arrayCount = [];
    let response = await expenseTravelAllowanceServise.getHourlyBasisAllowance(this.state.companyId);
    if (response.count > 0 && response.result[0].configurations&&response.result[0].configurations.length>0) {
      this.setState({ editAllowanceConfig: response.result[0].configurations});
      response.result[0].configurations.forEach((list, index) => {
        this.setState({
          ['from' + index]: list['from' + index],
          ['to' + index]: list['to' + index],
          ['rate' + index]: list['rate' + index]
        });
        arrayCount.push(index);
        let fromDropdown = [];
        let toDropdown = [];
        if (index === 0) {
          for (let fromIndex = 0; fromIndex < 25; fromIndex++) {
            fromDropdown.push(fromIndex);
          }
        } else {
          for (let fromIndex = list['from' + index]; fromIndex < 25; fromIndex++) {
            fromDropdown.push(fromIndex);
          }
        }
        for (let toIndex = list['to' + index]; toIndex < 25; toIndex++) {
          toDropdown.push(toIndex);
        }
        this.setState({ ['todropdown' + index]: toDropdown, ['fromdropdown' + index]: fromDropdown });
      });
     await this.setState({
        status: response.result[0].status,
        count: response.result[0].configurations.length,
        arrayCount: arrayCount
      });
    } else {
      for (let arrayIndex = 0; arrayIndex < this.state.count; arrayIndex++) {
        let fromDropdown = [];
        let toDropdown = [];
        for (let index = 0; index < 25; index++) {
          fromDropdown.push(index);
          toDropdown.push(index);
        }
        await this.setState({ ['todropdown' + arrayIndex]: toDropdown, ['fromdropdown' + arrayIndex]: fromDropdown });
      }
    }
  }

  onAddClickHandler = () => {
    let count = this.state.count + 1;
    this.setState({ count: count });
    let arrayCount = [];
    let startDropdown = this.state['to'+parseInt(this.state.count-1)];
    for (let index = 0; index < count; index++) {
      arrayCount.push(index);
        this.setState({
          ['from' + index]: this.state['from' + index] ? this.state['from' + index] : 0,
          ['to' + index]: this.state['to' + index] ? this.state['to' + index] : 0
        });
    let fromDropdown =[];
    let toDropdown = [];
    for (let fromIndex = startDropdown; fromIndex < 25; fromIndex++) {
      fromDropdown.push(fromIndex);
    }
    for (let toIndex = startDropdown; toIndex < 25; toIndex++) {
      toDropdown.push(toIndex);
    }
    this.setState({ ['from'+ (parseInt(this.state.count))]:startDropdown,
                    ['to'+ (parseInt(this.state.count))]:startDropdown,
                    ['todropdown' + (parseInt(this.state.count))]: toDropdown, 
                    ['fromdropdown' + (parseInt(this.state.count))]: fromDropdown });
    }
    this.setState({ arrayCount: arrayCount });
  }
  onEditAddClickHandler = () => {
    let count = this.state.count + 1;
    this.setState({ count: count });
    let startDropdown = this.state['to'+parseInt(this.state.count-1)];
    let arrayCount = [];
    for (let index = 0; index < count; index++) {
      arrayCount.push(index);
        this.setState({
          ['from' + index]: this.state['from' + index] ? this.state['from' + index] : 0,
          ['to' + index]: this.state['to' + index] ? this.state['to' + index] : 0
        });
    }
    let fromDropdown =[];
    let toDropdown = [];
    for (let fromIndex = startDropdown; fromIndex < 25; fromIndex++) {
      fromDropdown.push(fromIndex);
    }
    for (let toIndex = startDropdown; toIndex < 25; toIndex++) {
      toDropdown.push(toIndex);
    }
    this.setState({ ['from'+ (parseInt(this.state.count))]:startDropdown,
                    ['to'+ (parseInt(this.state.count))]:startDropdown,
                    ['todropdown' + (parseInt(this.state.count))]: toDropdown, 
                    ['fromdropdown' + (parseInt(this.state.count))]: fromDropdown });
    this.setState({ arrayCount: arrayCount });
  }
  onSelectHandler = (value) => {
    let selectedRow = [...this.state.selectedRow];
    if (!selectedRow.includes(value)) {
      selectedRow.push(value);
    } else {
      selectedRow = selectedRow.filter((index) => index !== value);
    }
    this.setState({ selectedRow: selectedRow });
  }
  onRemoveHandler = () => {
    let selectedRow = [...this.state.selectedRow];
    let arrayCount = [...this.state.arrayCount];
    var pulled = _.pullAt(arrayCount, selectedRow);
    this.setState({ arrayCount: arrayCount, selectedRow: [],count:arrayCount.length });
  }
  onDropdownSelectHandler = (fieldName, value, index) => {
    this.setState({ [fieldName]: value, ['to' + index]: value });
    let toDropdown = [];
    for (let toIndex = parseInt(value); toIndex < 25; toIndex++) {
      toDropdown.push(toIndex);
    }
    this.setState({ ['todropdown' + index]: toDropdown });
  }
  onDropdownToSelectHandler = (fieldName, value, index) => {
    this.setState({ [fieldName]: value, ['from' + (parseInt(index) + 1)]: value, ['to' + (parseInt(index) + 1)]: value });
    let fromDropdown = [];
    let toDropdown = [];
    for (let fromIndex = value; fromIndex < 25; fromIndex++) {
      fromDropdown.push(fromIndex);
    }
    for (let toIndex = value; toIndex < 25; toIndex++) {
      toDropdown.push(toIndex);
    }
    this.setState({ ['todropdown' + (parseInt(index) + 1)]: toDropdown, ['fromdropdown' + (parseInt(index) + 1)]: fromDropdown });
  }
  onRateChangeHandler = (fieldName, event) => {
    if(!isNaN(event.target.value)) 
    {
     this.setState({ [fieldName]: event.target.value });
    }
  }
  onEditRemoveHandler = () => {
    let selectedRow = [...this.state.selectedRow];
    let arrayCount = [...this.state.arrayCount];
    var pulled = _.pullAt(arrayCount, selectedRow);
    this.setState({ arrayCount: arrayCount, selectedRow: [],count:arrayCount.length });
  }
  onSubmitHandler = async () => {
  if (this.validator.allValid()) {
      let arrayCount = [...this.state.arrayCount];
      let data = [];
      arrayCount.forEach((index, indexes) => {
        data.push({
          ['from' + indexes]: this.state['from' + index],
          ['to' + indexes]: this.state['to' + index],
          ['rate' + indexes]: this.state['rate' + index]
        });
      });
      let submitData = {
        companyId: this.state.companyId,
        status: this.state.status,
        configurations: data
      }
      let response = await expenseTravelAllowanceServise.createConfigurationAllowance(submitData);
      if (response) {
        toast.success(response.message);
        this.props.history.push('./config');
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  onStatusChangeHandler = () => {
    this.setState({ status: !this.state.status });
  }
  render() {
    return (
      <div>
        <HourlyAllowancePage
          onAddClickHandler={this.onAddClickHandler}
          arrayCount={this.state.arrayCount}
          selectedRow={this.state.selectedRow}
          onSelectHandler={this.onSelectHandler}
          onRemoveHandler={this.onRemoveHandler}
          onDropdownSelectHandler={this.onDropdownSelectHandler}
          onDropdownToSelectHandler={this.onDropdownToSelectHandler}
          onSubmitHandler={this.onSubmitHandler}
          onRateChangeHandler={this.onRateChangeHandler}
          onStatusChangeHandler={this.onStatusChangeHandler}
          editAllowanceConfig={this.state.editAllowanceConfig}
          onEditRemoveHandler={this.onEditRemoveHandler}
          onEditAddClickHandler={this.onEditAddClickHandler}
          validator={this.validator}
          {...this.state}
        />
      </div>
    );
  }
}
export default HourlyAllowance;
