import React, { Component } from "react";
import CarConfigStep3Page from "./config-step3-html";
import { carConfigService } from "src/_services/carConfigService";
import { toast } from "react-toastify";
class configStep3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form3Data: {},
            carId: ''
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    componentDidMount = () => {
        let carId = JSON.parse(localStorage.getItem("carId"));
        if (carId!=='new') {
            this.setState({ carId });
            this.updateCarConfiguration(carId);
        }
        else {
            toast.success("Please complete the step 1");
            this.props.history.push(`/administration/expense/car-config/config-step1/${carId}`);
        }
    }
    onStatusChangeHandler = async (id, status) => {
        let payLoad = {
            id: id,
            vehicleStatus: status
        };
        let res = await carConfigService.setStatus(payLoad, this.state.form3Data.id);
        if (res) {
            toast.success(res.message);
            this.updateCarConfiguration(this.state.carId);
        }
    }
    updateCarConfiguration = async (carId) => {
        let res = await carConfigService.updateCarConfiguration(carId);
        if (res) {
            this.setState({ form3Data: res.result });
        }
    }
    render() {
        return (
            <div>
                <CarConfigStep3Page
                    carId={this.state.carId}
                    form3Data={this.state.form3Data}
                    onStatusChangeHandler={this.onStatusChangeHandler}
                />
            </div>
        );
    }
}
export default configStep3;