import { http } from "../_helpers";
import { config } from "../env";

const getGroupType = companyId => {
    return http
      .get(config.apiUrlCompany +`list-management?companyId=${companyId}&itemCode=GRP001`
      )
      .then(data => {
        return data ? data : "";
      });
  };

const getFormHeader =(data)=>{
  return http
  .post(config.apiUrlCompany +`form-headers/list-all`,data)
  .then(data => {
    return data ? data : "";
  });
}

const getFieldNames = (companyId,formHeaderId) =>{
  return http
  .get(config.apiUrlCompany +`company-fields/get-form-header-fields?companyId=${companyId}&formHeaderId=${formHeaderId}`
  )
  .then(data => {
    return data ? data : "";
  });
}

const createAuditRuleStep1 = (data)=>{
  return http
  .post(config.apiExpense +`auditRule`,data)
  .then(data => {
    return data ? data : "";
  });
}

const updateAuditRule = (data)=>{
  return http
  .patch(config.apiExpense +`auditRule`,data)
  .then(data => {
    return data ? data : "";
  });
}

const getAllAuditRules = (companyId,limit,page)=>{
  return http
  .get(config.apiExpense +`auditRules?companyId=${companyId}&limit=${limit}&page=${page}`
  )
  .then(data => {
    return data ? data : "";
  });
}

const changeStatusOfRule = (companyId,data)=>{
  return http
  .patch(config.apiExpense +`auditRule/active-status?companyId=${companyId}`,data)
  .then(data => {
    return data ? data : "";
  });
}

const getAuditRuleByid = (companyId,auditRuleId)=>{
  return http
  .get(config.apiExpense +`auditRule/${auditRuleId}?companyId=${companyId}`)
  .then(data => {
    return data ? data : "";
  });
}

const deleteAuditRule = (companyId,data)=>{
  return http
  .patch(config.apiExpense +`auditRule/deactivate?companyId=${companyId}`,data)
  .then(data => {
    return data ? data : "";
  });
}

const getAllException = (companyId,limit,page)=>{
  return http
    .get(config.apiExpense +`exceptions?companyId=${companyId}&limit=${limit}&page=${page}`)
    .then(data => {
      return data ? data : "";
  });
}

const createException = (data)=>{
  return http
  .post(config.apiExpense +`exception`,data)
  .then(data => {
    return data ? data : "";
  });
}

const editException = (companyId,exceptionId)=>{
  return http
    .get(config.apiExpense +`exception/${exceptionId}?companyId=${companyId}`)
    .then(data => {
       return data ? data : "";
  });
}

const updateException=(data)=>{
  return http
  .patch(config.apiExpense +`exception`,data)
  .then(data => {
    return data ? data : "";
  });
}

const deleteException=(companyId,data)=>{
  return http
  .patch(config.apiExpense +`exception/delete?companyId=${companyId}`,data)
  .then(data => {
    return data ? data : "";
  });
}

const exceptionFilter = (companyId,exceptionVisibility)=>{
  return http
    .get(config.apiExpense +`exception/search/${exceptionVisibility}?companyId=${companyId}`)
    .then(data => {
       return data ? data : "";
  });
}

const auditRuleSearch = (companyId,search)=>{
    return http
    .get(config.apiExpense +`auditRule/search/${search}?companyId=${companyId}`)
    .then(data => {
      return data ? data : "";
  });
}
const getCurrencyList = companyId => {
  return http
    .get(
      config.apiUrlCompany + `list-management?companyId=${companyId}&itemCode=CUR001`
    )
    .then(data => {
      return data ? data : "";
    });
};
export const auditRuleService={
    getGroupType,
    getFormHeader,
    getFieldNames,
    createAuditRuleStep1,
    updateAuditRule,
    getAllAuditRules,
    changeStatusOfRule,
    getAuditRuleByid,
    deleteAuditRule,
    getAllException,
    createException,
    editException,
    updateException,
    deleteException,
    exceptionFilter,
    auditRuleSearch,
    getCurrencyList
}
