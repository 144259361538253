import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Header from '../common/header';
class AboutComponent extends Component {
    constructor(props) {
        super(props);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    render() {
        return (
            <div>
                {/* <Header
                /> */}
                <div className="page-wrapper help-page">
                    <div className="bg-image bg-image6 ">
                    </div>
                    <div className="top__margin">
                        <div className="mark1 text-center mb-4">
                            {/* <div className="round-mark">
                                <i className="material-icons">business</i>
                                <img src="/assets/images/help/checkbox-pen-outline.svg" />                               
                            </div> */}
                            <h4 className="text-white pt-2 text-black">About </h4>
                            <p>Your data, activity, and preferences that help make curio services more useful to you</p>
                        </div>
                        <div className="px-padding">
                            <div className="form-holder border-light mb-5 pm-30">
                                <div className="text-center">
                                    {/* <h4 className="text-primary">Got a quick question?</h4>
                                    <p>Reach out to us for a quick resolve</p> */}
                                    {/* <img src="/assets/images/help/about.svg" alt="" width="700" /> */}
                                </div>
                                <div className="about__page">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="border-about">
                                                <div className="about-heading">
                                                    <h5 className="sub-heading font-weight-bold mb-1">Corporate Travel</h5>
                                                    <p className="mb-0">Corporate travel done right.</p>
                                                </div>
                                                <div className="form-holder">
                                                    <p>Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim neque. Aenean enim sem, pharetra et magna sit amet, luctus aliquet nibh. Curabitur auctor leo et libero consectetur gravida. Morbi gravida et sem dictum varius. Proin eget viverra sem, non euismod est. Maecenas facilisis urna in lectus aliquet venenatis. Etiam et metus nec mauris condimentum vulputate. Aenean volutpat odio quis egestas tempus. Fusce tempor vulputate luctus. Pellentesque vulputate viverra ex eget elementum. Aliquam ut feugiat felis.</p>
                                                    <p>Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim neque. Aenean enim sem, pharetra et magna sit amet, luctus aliquet nibh. Curabitur auctor leo et libero consectetur gravida. Morbi gravida et sem dictum varius. Proin eget viverra sem, non euismod est. Maecenas facilisis urna in lectus aliquet venenatis. Etiam et metus nec mauris condimentum vulputate. Aenean volutpat odio quis egestas tempus. Fusce tempor vulputate luctus. Pellentesque vulputate viverra ex eget elementum. Aliquam ut feugiat felis.</p>
                                                    <h6 className="sub-heading font-weight-bold ">Deep Integration</h6>
                                                    <p className="mb-0">Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim neque. Aenean enim sem, pharetra et magna sit amet, luctus aliquet nibh. Curabitur auctor leo et libero consectetur gravida. Morbi gravida et sem dictum varius. Proin eget viverra sem, non euismod est. Maecenas facilisis urna in lectus aliquet venenatis. Etiam et metus nec mauris condimentum vulputate. Aenean volutpat odio quis egestas tempus. Fusce tempor vulputate luctus. Pellentesque vulputate viverra ex eget elementum. Aliquam ut feugiat felis.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="border-about">
                                                <div className="about-heading">
                                                    <h5 className="sub-heading mb-1 font-weight-bold ">Travel Expense</h5>
                                                    <p className="mb-0">Beautiful Marriage or Travel and Expense.</p>
                                                </div>
                                                <div className="form-holder ">
                                                    <p>Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim neque. Aenean enim sem, pharetra et magna sit amet, luctus aliquet nibh. Curabitur auctor leo et libero consectetur gravida. Morbi gravida et sem dictum varius. Proin eget viverra sem, non euismod est. Maecenas facilisis urna in lectus aliquet venenatis. Etiam et metus nec mauris condimentum vulputate. Aenean volutpat odio quis egestas tempus. Fusce tempor vulputate luctus. Pellentesque vulputate viverra ex eget elementum. Aliquam ut feugiat felis.</p>
                                                    <p>Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim neque. Aenean enim sem, pharetra et magna sit amet, luctus aliquet nibh. Curabitur auctor leo et libero consectetur gravida. Morbi gravida et sem dictum varius. Proin eget viverra sem, non euismod est. Maecenas facilisis urna in lectus aliquet venenatis. Etiam et metus nec mauris condimentum vulputate. Aenean volutpat odio quis egestas tempus. Fusce tempor vulputate luctus. Pellentesque vulputate viverra ex eget elementum. Aliquam ut feugiat felis.</p>
                                                    <h6 className="sub-heading font-weight-bold ">Expense Across the World</h6>
                                                    <p className="mb-0">Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim neque. Aenean enim sem, pharetra et magna sit amet, luctus aliquet nibh. Curabitur auctor leo et libero consectetur gravida. Morbi gravida et sem dictum varius. Proin eget viverra sem, non euismod est. Maecenas facilisis urna in lectus aliquet venenatis. Etiam et metus nec mauris condimentum vulputate. Aenean volutpat odio quis egestas tempus. Fusce tempor vulputate luctus. Pellentesque vulputate viverra ex eget elementum. Aliquam ut feugiat felis.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <h5 className="sub-heading mb-1 font-weight-bold">Vision</h5>
                                    <p>Corporate Travel Done Right</p>
                                    <p>Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim neque. Aenean enim sem, pharetra et magna sit amet, luctus aliquet nibh. Curabitur auctor leo et libero consectetur gravida. Morbi gravida et sem dictum varius. Proin eget viverra sem, non euismod est. Maecenas facilisis urna in lectus aliquet venenatis. Etiam et metus nec mauris condimentum vulputate. Aenean volutpat odio quis egestas tempus. Fusce tempor vulputate luctus. Pellentesque vulputate viverra ex eget elementum. Aliquam ut feugiat felis.</p>
                                    <h5 className="sub-heading mb-1 font-weight-bold">Mission</h5>
                                    <p>Corporate Travel Done Right</p>
                                    <p>Duis mauris augue, efficitur eu arcu sit amet, posuere dignissim neque. Aenean enim sem, pharetra et magna sit amet, luctus aliquet nibh. Curabitur auctor leo et libero consectetur gravida. Morbi gravida et sem dictum varius. Proin eget viverra sem, non euismod est. Maecenas facilisis urna in lectus aliquet venenatis. Etiam et metus nec mauris condimentum vulputate. Aenean volutpat odio quis egestas tempus. Fusce tempor vulputate luctus. Pellentesque vulputate viverra ex eget elementum. Aliquam ut feugiat felis.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AboutComponent;