import React, { Component } from 'react';
import moment from 'moment';
class CashadvanceUserDetail extends Component {
  render() {
    let cashAdvanceId, cashAdvanceName, requestedId, columnNames, cashadvanceNameIndex, requestedDate, userFirstNameIndex, userLastNameIndex, userMiddleNameIndex, userFullName, userDetails;
    userDetails = this.props.selectedCashadvanceData.userDeatils;
    columnNames = this.props.selectedCashadvanceData.data.map((list) => list.columnName);
    cashadvanceNameIndex = columnNames.indexOf("CASH_ADVANCE_NAME");
    cashAdvanceName = cashadvanceNameIndex !== -1 ? this.props.selectedCashadvanceData.data[cashadvanceNameIndex].fieldValue : '';
    cashAdvanceId = this.props.selectedCashadvanceData.cashAdvanceId;
    requestedId = this.props.selectedCashadvanceData.requestId;
    requestedDate = moment(this.props.selectedCashadvanceData.createdAt).format('DD MMM YYYY');
    userFirstNameIndex = userDetails ? userDetails.fieldData.findIndex((userFields) => userFields.columnName === "EMPLOYEE_FIRST_NAME") : -1;
    userMiddleNameIndex = userDetails ? userDetails.fieldData.findIndex((userFields) => userFields.columnName === "MIDDLE_NAME") : -1;
    userLastNameIndex = userDetails ? userDetails.fieldData.findIndex((userFields) => userFields.columnName === "EMPLOYEE_LAST_NAME") : -1;
    userFullName = (userFirstNameIndex !== -1 && userMiddleNameIndex !== -1 && userLastNameIndex !== -1) ? userDetails.fieldData[userFirstNameIndex].fieldValue + ' ' + userDetails.fieldData[userMiddleNameIndex].fieldValue + ' ' + userDetails.fieldData[userLastNameIndex].fieldValue : '-';

    return (
      <div>
        <div className="row">
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Cash Advance ID</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">{cashAdvanceId}</p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Cash Advance Name</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">{cashAdvanceName}</p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Request ID</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">{requestedId ? requestedId : '-'}</p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Request Date</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">{requestedDate}</p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1 ">Employee Name</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">{userFullName}</p>
          </div>
          <div className="col-md-4 mb-4">
            <p className="lighter-p mb-1">Employee Email</p>
            <p className="font-weight-bold mb-0 border-bottom-light pb-4">{this.props.selectedCashadvanceData.userDeatils ? this.props.selectedCashadvanceData.userDeatils.email : '-'}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default CashadvanceUserDetail;
