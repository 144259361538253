import { NavLink } from 'react-router-dom';

const TRIPMENU = () => {
    return (
        <ul className="expense-tabs">
            <li className={window.location.pathname === "/trips-approve/trips"?"active":""} >
                <NavLink to="/trips-approve/trips">
                    Trips
        </NavLink>
            </li>
            <li  className={window.location.pathname === "/trips-approve/expense-reports"?"active":""}>
                <NavLink to="/trips-approve/expense-reports">
                    Expense Reports
        </NavLink>
            </li>
            <li  className={window.location.pathname === "/trips-approve/cashadvance"?"active":""} >
                <NavLink to="/trips-approve/cashadvance">
                    Cash Advance
        </NavLink>
            </li>
        </ul>
    )
};
export default TRIPMENU;
