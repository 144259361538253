import { http } from "../_helpers";
import { config } from "../env";
import { color } from "@storybook/addon-knobs";

export const chatService = {
  getMessages,
  getReceiverDetails
};

// const getMessages = (roomId) => {
//   let url = `http://192.168.0.159:3001/api/getchatbyroomid/${roomId}/?pageNo=1&size=10`;
//   return http
//     .get(url)
//     .then(data => data);
// }

function getMessages(roomId) {
  // return http
  //   .get(
  //     `http://192.168.0.159:3015/api/getchatbyroomid/${roomId}/?pageNo=1&size=10`
  //   )
  //   .then(data => {
  //     return data;
  //   });
  return;
}
function getReceiverDetails(receiverId) {
  return http
    .get(
      `${config.apiUrlCompany}/company-users/details?userId=${receiverId}`
    )
    .then(data => {
      return data;
    });
}