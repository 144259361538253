import React from 'react';
import Menu from '../menu';
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
const RatesPage = (props) => {
    let data = props;
    return (
        <div>
            <div className="admin-tabs">
                <Menu />
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">E</div>
                        <div className="right">
                            <h6 className="heading">Rates</h6>
                            <p className="text">Lorem Ipsum is simply dummy text.</p>
                        </div>
                    </div>
                </div>
                <div className="py-padding">
                    <div className="table-container">
                        <div className="px-padding">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group custom labelup">
                                        <label>Advanced Search</label>
                                        <div className="dropdown">
                                            <button type="button" className="btn bg-white btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                                Rate Feed</button>
                                            <div className="dropdown-menu">
                                                <button type="button" className="dropdown-item">Action 1</button>
                                                <button type="button" className="dropdown-item">Action 1</button>
                                                <button type="button" className="dropdown-item">Action 1</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group custom ">
                                        <div className="dropdown">
                                            <button type="button" className="btn bg-white btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                                Country</button>
                                            <div className="dropdown-menu">
                                                <button type="button" className="dropdown-item">Action 1</button>
                                                <button type="button" className="dropdown-item">Action 1</button>
                                                <button type="button" className="dropdown-item">Action 1</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group custom ">
                                        <div className="dropdown">
                                            <button type="button" className="btn bg-white btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                                Currency</button>
                                            <div className="dropdown-menu">
                                                <button type="button" className="dropdown-item">Action 1</button>
                                                <button type="button" className="dropdown-item">Action 1</button>
                                                <button type="button" className="dropdown-item">Action 1</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2 offset-1 text-right">
                                    <button className="btn   ">Search</button>
                                </div>
                            </div>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="btn-group-margin">
                                        <NavLink to="/administration/expense/rates-step-1" className="btn   sm-padding ">Modify</NavLink>
                                        <button type="button" className="btn   sm-padding" data-toggle="modal" data-target="#AddTemplateModal">Add Location map</button>
                                        <button type="button" className="btn   sm-padding ">Location List</button>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="search-custom">
                                        <input type="text" name="" className="form-control" placeholder="Search" />
                                        <span><i className="material-icons">search</i></span>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Rate Location Name</th>
                                        <th>Administration Region</th>
                                        <th>Country</th>
                                        <th>Effective date</th>
                                        <th>Meals</th>
                                        <th>Lodging</th>
                                        <th>Rate Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td></td>
                                        <td>Lithuania</td>
                                        <td>04/09/2019</td>
                                        <td>47$</td>
                                        <td>862$</td>
                                        <td>Q</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td></td>
                                        <td>Lithuania</td>
                                        <td>04/09/2019</td>
                                        <td>47$</td>
                                        <td>862$</td>
                                        <td>Q</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td></td>
                                        <td>Lithuania</td>
                                        <td>04/09/2019</td>
                                        <td>47$</td>
                                        <td>862$</td>
                                        <td>Q</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td></td>
                                        <td>Lithuania</td>
                                        <td>04/09/2019</td>
                                        <td>47$</td>
                                        <td>862$</td>
                                        <td>Q</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td></td>
                                        <td>Lithuania</td>
                                        <td>04/09/2019</td>
                                        <td>47$</td>
                                        <td>862$</td>
                                        <td>Q</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td></td>
                                        <td>Lithuania</td>
                                        <td>04/09/2019</td>
                                        <td>47$</td>
                                        <td>862$</td>
                                        <td>Q</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-footer">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="page-count">
                                        <div className="text">Items</div>
                                        <div className="count">
                                            <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    10
                                            </button>
                                                <div className="dropdown-menu">
                                                    <button type="button" className="dropdown-item">10</button>
                                                    <button type="button" className="dropdown-item">20</button>
                                                    <button type="button" className="dropdown-item">30</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <Pagination
                                        activePage={data.active}
                                        itemsCountPerPage={10}
                                        totalItemsCount={10}
                                        pageRangeDisplayed={3}
                                        onChange={data.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="AddTemplateModal" tabIndex="-1" role="dialog"
                aria-labelledby="AddTemplateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="AddTemplateModalLabel">Add Location Map</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group custom labelup">
                                <label>Country</label>
                                <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                        Brazil
                                    </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group custom labelup">
                                <label>Subdivision</label>
                                <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                        SELECT ONE
                                    </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group custom labelup">
                                <label>Location</label>
                                <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">SELECT ONE
                                    </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-border-success lg-padding">Save</button>
                            <button type="button" className="btn btn-border-basic lg-padding"
                                data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default RatesPage;