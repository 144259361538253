import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { RuleClassPage, AddRuleClassPage, TravelPolicyPage, LowestLogicalFarePage, EditTravelPolicyPage, TravelItineraryPage, PolicyViolationPage, AddViolationPolicyPage, RuleTemplatePage, TravelVendorExclusionPage } from './policy';
import { HotelContractPage, CarContractPage, HotelListPage, HotelInfoPage, CarListPage, CarInfoPage } from './contracts';
import { UnusedTicketPage, TravelDiscountPage, AddTravelDiscountPage, AddAirDiscountsPage, EditAirDiscounts } from './';
import TravelAdminPage from "./travel-admin/travel-admin";
import RequestHomePage from "./request/request";
import { inject } from "mobx-react"; 
@inject('menuStore')
class TravelHomePage extends Component {
    constructor(props) {
        super(props)
        this.props.menuStore.menuItems && this.props.menuStore.menuItems.forEach((list, key) => {
            if (this.props.menuStore.parentId === list.id) {
                list.children.forEach((item, keyItem) => {
                    if (props.location.pathname === item.frontEndUrl) {
                        // window.location.href = item.children[0].children[0].frontEndUrl
                        this.props.history.push(item.children[0].children[0].frontEndUrl)
                    } else {
                        this.props.history.push(props.location.pathname)
                    }
                })
            }
        })
    }
    render() {
        return (
            <div className="">
                <Route path="/administration/travel/rule-class" component={RuleClassPage} />
                <Route path="/administration/travel/request" component={RequestHomePage} />
                <Route path="/administration/travel/add-rule-class" exact={true} component={AddRuleClassPage} />
                <Route path="/administration/travel/add-rule-class/:id" exact={true} component={AddRuleClassPage} />
                <Route path="/administration/travel/travel-policy" component={TravelPolicyPage} />
                <Route path="/administration/travel/travel-itinerary" component={TravelItineraryPage} />
                <Route exact path="/administration/travel/lowest-logical-fare/lowest-logical-fare" component={LowestLogicalFarePage} />
                <Route exact path="/administration/travel/lowest-logical-fare/lowest-logical-fare/:id" component={LowestLogicalFarePage} />
                <Route path="/administration/travel/edit-travel-policy" exact={true} component={EditTravelPolicyPage} />
                <Route path="/administration/travel/edit-travel-policy/:id" exact={true} component={EditTravelPolicyPage} />
                <Route path="/administration/travel/policy-violation" component={PolicyViolationPage} />
                <Route path="/administration/travel/add-violation-policy" exact={true} component={AddViolationPolicyPage} />
                <Route path="/administration/travel/add-violation-policy/:id" exact={true} component={AddViolationPolicyPage} />
                <Route path="/administration/travel/rule-template" component={RuleTemplatePage} />
                <Route path="/administration/travel/travel-discount" exact component={TravelDiscountPage} />
                <Route path="/administration/travel/travel-admin" exact component={TravelAdminPage} />
                <Route path="/administration/travel/add-travel-discount" exact component={AddTravelDiscountPage} />
                <Route path="/administration/travel/add-travel-discount/:id" component={AddTravelDiscountPage} />
                <Route path="/administration/travel/edit-air-discounts/:id/:itemId/:discountCode/:vendor/:vendorId" component={EditAirDiscounts} />
                <Route path="/administration/travel/add-air-discounts/:id" exact component={AddAirDiscountsPage} />
                <Route path="/administration/travel/unused-tickets" component={UnusedTicketPage} />
                <Route path="/administration/travel/travel-vendor-exclusion" component={TravelVendorExclusionPage} />
                <Route path="/administration/travel/contracts" component={HotelContractPage} />
                <Route path="/administration/travel/car-contracts" component={CarContractPage} />
                <Route path="/administration/travel/hotel-list/:cityId/:companyId/:limit/:page/:newCityId" component={HotelListPage} />
                <Route path="/administration/travel/hotel-info/:i/:hotelContractCityId/:newCityId" component={HotelInfoPage} />
                <Route path="/administration/travel/car-list/:carContractCityId/:companyId/:limit/:page" component={CarListPage} />
                <Route path="/administration/travel/car-info/:index/:carContractCityId" component={CarInfoPage} />
            </div>
        )
    }
}
export default TravelHomePage;