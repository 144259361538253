import React, { Component } from "react";
import SortHeadFlight from "../../flight/_components/sortHeadFlight";
class HeadingFilter extends Component {
    sortItem() {

    }
    render() {
        return (
            <div className="devision-container heading">
                <div className="devision p-less">
                    <div className="">
                        <SortHeadFlight
                            sortItem={this.sortItem}
                            feildName={"Train"}
                            name="Train"
                            sortType={"desc"}
                            className="details"
                        />
                        <SortHeadFlight
                            sortItem={this.sortItem}
                            feildName={"Duration"}
                            name="Duration"
                            sortType={"desc"}
                            className="details bus-timing"
                        />
                        <SortHeadFlight
                            sortItem={this.sortItem}
                            feildName={"Price"}
                            name="Price"
                            sortType={"desc"}
                            className="details bus-price text-right"
                        />
                        <div className="clearfix" />
                    </div>
                </div>
            </div>
        );
    }
}

export default HeadingFilter;