import React, { Component } from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import Moment from "react-moment";
import NumberFormat from 'react-number-format';
import ModalImage from 'react-modal-image';


class HotelDetails extends Component {
    getTime = (time) => {
        let type = 'AM'
        let hours = parseInt(time / 60);
        const minutes = parseInt(time) % 60;
        if (hours > 12) {
            hours = hours - 12;
            type = "PM";
        }
        return hours + ' : ' + minutes + ' ' + type;
    }
    render() {
        const { data, eTicket, invoice } = this.props;
        return (
            <div>
                {(data.hotelData != undefined) ?
                    <div className="card border-bottom">
                        <div className="card-header pm-15 bg-white" id="headingOne">
                            <h5 className="mb-0">
                                <div className="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <div className="float-left">{data.hotelData.hotel_name}<br />
                                        <div className="location-map font-size-13 opacity-7 mt-2">
                                            <span className="mt-2">
                                                <i className="material-icons font-size-13 align-middle">
                                                    location_on</i>
                                                &nbsp;{data.hotelData.hotel_address}
                                            </span>
                                            <button type="button" className="btn btn-nothing" >
                                                <NavLink
                                                    title={data.hotelData.hotel_name}
                                                    onClick={e => window.open(`https://www.google.com/maps/search/${data.hotelData.hotel_name}`)}
                                                    to={"#"}
                                                    className="link "
                                                >
                                                    - Show On Map</NavLink>
                                            </button>
                                        </div></div>
                                    <div className="float-right">
                                        {eTicket && invoice && <>
                                            <NavLink to={`/administration/company/mid-office-history/hotelSwitch/${data.tripId}/${data.bookingType}/${data.hotelData.hotel_id}/${"eticket"}`} target="_blank" className="btn mr-4">E-Voucher</NavLink>
                                            <NavLink to={`/administration/company/mid-office-history/hotelSwitch/${data.tripId}/${data.bookingType}/${data.hotelData.hotel_id}/${"invoice"}`} target="_blank" className="btn mr-2">Invoice</NavLink>
                                        </>
                                        }
                                        <i className="icon ion-md-arrow-dropdown icon-margin"></i>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </h5>
                        </div>
                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body pm-30">
                                <div className="border pm-15 mb-3">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className="bg-light text-uppercase">
                                                <th colSpan="2">check-in</th>
                                                <th className="text-success">Duration</th>
                                                <th colSpan="2">check-out</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="2" className="lighter-p">
                                                    {this.getTime(data.hotelData.checkin_from)}
                                                </td>
                                                <td rowSpan="2" >
                                                    <p className="text-success">{data.hotelData.trip_duration}
                                                        &nbsp;Day(s)
                                                                        </p>
                                                </td>
                                                <td colSpan="2" className="">
                                                    {this.getTime(data.hotelData.checkout_to)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="lighter-p">
                                                    {data.hotelData.hotel_from}
                                                </td>
                                                <td colSpan="2" className="">{data.hotelData.hotel_to}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h6 className="font-weight-bold mb-3">Additional Information</h6>
                                    <table className="table table-bordered mb-0">
                                        <thead>
                                            <tr className="bg-light text-uppercase">
                                                <th>Breakfast</th>
                                                <th>Lunch</th>
                                                <th>Pre-Payment</th>
                                                <th>Refundability</th>
                                                <th>Cancellation</th>
                                                <th>Max People</th>
                                                <th>Per Night</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{(data.hotelData.hotel_room_info.hotel_room_breakfast !== false) ? "Included" : "Not Included"}
                                                </td>
                                                <td>{(data.hotelData.hotel_room_info.hotel_room_lunch !== false) ? "Included" : "Not Included"}
                                                </td>
                                                <td>{data.hotelData.hotel_room_info.hotel_pre_payment ? data.hotelData.hotel_room_info.hotel_pre_payment : "--"}</td>
                                                <td>{data.hotelData.hotel_room_info.hotel_room_refundable ? "YES" : "NO"}</td>
                                                <td>
                                                    <Moment format="DD-MMM-YYYY">{data.hotelData.hotel_room_info.hotel_refudable_untill ?
                                                        data.hotelData.hotel_room_info.hotel_refudable_untill : "--"}</Moment></td>
                                                <td>{data.hotelData.hotel_room_info.hotel_room_max_occupancy}</td>
                                                <td>
                                                    <NumberFormat
                                                        value={(Math.floor(data.hotelData.hotel_room_info.hotel_room_price * 100) / 100)}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={data.baseCurrency + ' '}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6 className="font-weight-bold mb-3">Photos</h6>
                                <div className="hotel-room-photos px-15">
                                    <div className="row mb-3 overflow">{data.hotelData.blockDetails.hotel_room_info[0].hotel_room_photos.map((photo, key) => {
                                        return (
                                            <div key={key} className="col-md-1 mb-3">
                                                <ModalImage
                                                    small={photo.url_original}
                                                    className="w-100 h-100"
                                                    large={photo.url_original}
                                                    alt="Room Picture"
                                                    hideDownload={true}
                                                    hideZoom={true}
                                                />
                                            </div>
                                        )
                                    })}
                                    </div>
                                </div>
                                <h6 className="font-weight-bold mb-3">Hotel facilities/amenities</h6>

                                <div className="row px-15 my-3">

                                    {_.chunk(data.hotelData.hotel_facilities, 3)
                                        .map((arrays, i) => {
                                            return (
                                                arrays.map((item, key) => {
                                                    return (
                                                        <div key={key} className="col-md-3">
                                                            <ul className="d-inline-block mb-2">
                                                                <li className="py-1"><img src="/assets/images/green-check.jpg" width="auto" height="20px" className="mr-2" />{item}</li>
                                                            </ul>
                                                        </div>
                                                    )
                                                })
                                            )

                                        })
                                    }

                                </div>
                                <h6 className="font-weight-bold mb-3">Tax Breakup</h6>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr className="text-uppercase">
                                            <td className="font-weight-bold bg-light">MSC : <br /></td>
                                            <td>cgst:-- </td>
                                            <td>psf:--</td>
                                            <td>sgst:--</td>
                                            <td>udf:--</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6 className="font-weight-bold mb-3">Hotel Policy</h6>
                                {(data.hotelData.hotel_policies.map((policy, i) => {
                                    return (
                                        <p key={i}>- {policy.content}</p>
                                    )
                                }))}
                            </div>
                        </div>
                    </div>
                    : "No Data is Available"}
            </div>
        )
    }
}
export default HotelDetails;