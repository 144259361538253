import React from "react";
import NumberFormat from "react-number-format";
import _ from "lodash";

const taxBreakUpFlight = props => {
  return (
    <div key={props.index} className="row">
      <div className="col-6">
        <p className="float-left text-capitalize py-2">
          {/* {_.startCase(_.camelCase(props.taxBreakKey))} */}
          {props.taxBreakKey.split("_").join(" ")}
        </p>
      </div>
      <div className=" col-6">
        <p className="float-right text-capitalize py-2">
          {props.currency} &nbsp;
          <NumberFormat
            value={props.taxBreakValue.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
          />
        </p>
      </div>
    </div>
  );
};

export default taxBreakUpFlight;
