import React, { Component } from "react";
import AddUserPage from "./add-user-html";
import { toast } from "react-toastify";
import { expenseReport } from "../../../../../_services/expenseReport";
import SimpleReactValidator from "simple-react-validator";
import { alertConstants } from "../../../../../_constants/alert.constants";
import $ from "jquery";
import { Object } from "es6-shim";
import _ from 'lodash';
import moment from 'moment';
import { getFullUserName } from 'src/utils/util';
import { getValue, setValue } from "src/_components/common/lodsh";
import { companyService, travelDiscountServices, getConnectedList } from "src/_services";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      serviceModuleId: localStorage.getItem('serviceModuleId'),
      companyId: localStorage["440a28"],
      formHeaderId: localStorage.getItem('formHeaderId'),
      formId: "",
      formIdForGettingUsers: "",
      fullName: "",
      email: "",
      id: "",
      selectedImage: null,
      activeStatus: false,
      invite: false,
      approver: false,
      isTestUser: false,
      fieldData: [],
      isApprover: false,
      travel: {
        travelClass: "",
        travelWorkflow: "",
        travelAllowance: ""
      },
      expense: {
        cashAdvanceWorkflow: "5c77d189f88fd5361c70537a"
      },
      delegatedUser: {
        parentUserId: "",
        serviceModule: "",
        startDate: "",
        endDate: "",
        accessType: []
      },
      isDelegateUserExists: "",
      allTravelClass: [],
      allTravelAllowance: [],
      allTravelWorkflow: [],
      selectedTravelClass: "",
      selectedTravelAllowance: "",
      selectedTravelWorkflow: "",
      selectedTravelDiscount: "",
      isVerifiedInvitation: false,
      allDelegatedUsers: [],
      groupName: localStorage.getItem('groupName'),
      groupId: localStorage.getItem('groupId'),
      profileImage: "",
      userPermission: "reviewer-role",
      expenseForm: [],
      connectedListData: [],

      // * storing the userlist in the state for default manager
      allUsers: [],
      searchedUsers: [],
      searchedUsers2: [],
      defaultManager1: {},
      defaultManager2: {},
      isGroupChanged: false,
      oldGroupId: '',
      isDropdownHidden: false,
      isLoading: true,
      discountList: [],
      startDate: '',
      search : [],
      loading:[]
    };
  }

  componentWillUnmount(){
    this.state = {
     search : [],
     loading:[]
   }
 }
  openDelegate() {
    $("#delegate-btn").addClass("d-none");
    $(".delegate-switch").toggleClass("d-none");
  }
  // !! Todo - while updating or creating add profile image to payload
  dummuyValue = [{ id: "0", value: "Dummy1" }, { id: "1", value: "Dummy2" }];
  isEdit = false;
  componentDidMount() {
    this.getServiceMode();
    this.getAllTravelDiscounts()
    this.getTravelRuleClassPromise = this.getTravelRuleClass();
    this.getTravelAllowancePromise = this.getTravelAllowance();
    this.getTravelWorkflowPromise = this.getTravelWorkflow();
    if (this.props.match.params.id !== undefined) {
      this.isEdit = true;
      this.getUserDetails(this.props.match.params.id);
    } else {
      this.isEdit = false;
      this.getDynamicFormFields();
    }
    // this.getUserList();

  }
  getAllTravelDiscounts = async () => {
    let resp = await travelDiscountServices.getAllWithoutLimitTravelDiscounts(this.state.companyId)
    if (resp) {
      this.setState({
        discountList: resp.result
      })
    }
  }

  // * Tinker carefully

  getDynamicFormFields = async () => {
    let payload = {
      companyId: this.state.companyId,
      formHeaderId: this.state.formHeaderId,
      serviceModuleId: this.state.serviceModuleId,
      isFormGroup: true,
      groupId: this.state.groupId,
    }
    let response = await companyService.getFormData(payload);



    //added for getting dropdown data in connected list :sethu
    if (response && response.result) {
      await (getValue(response,"result",[])).forEach(async item => {
        if (item.connectedListId) {
          let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp, "count", 0);
          item.connectedListData.listManagementData = resp.result;
          item.search = ''
        }
        this.setState({ expenseForm: response.result })
      })
      this.setState({ formId: response.result[0].formId });




      let formFields = [];
      let fieldsObjects = [];
      let newConnectedListDate = [];
      let dynamicFeilds = [];
      // * if the formfields have level they are connected list and are dynamic
      dynamicFeilds = _.filter(response.result, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== '';
      });
      dynamicFeilds.forEach((fields) => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField ? fields.multiSelectParentField : '',
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel
        });
      })
      this.setState({ connectedListData: newConnectedListDate });
      response.result.forEach(formField => {
        let fieldName = formField.fieldName;
        if (formField.displayType === "date") {
          let todayDate = new Date();
          todayDate = moment(todayDate).format("DD MMM YYYY");
          this.setState({ [fieldName]: todayDate });
        } else if (formField.displayType === "checkbox") {
          this.setState({ [fieldName]: false });
        } else {
          this.setState({ [fieldName]: "" });
        }
        // * modification for adding group name
        if (formField.columnName === 'GROUP_NAME') {
          this.setState({ [fieldName]: this.state.groupId });
        }
        // * end modification for adding group name
        formFields.push(formField.fieldName);
        fieldsObjects.push({ fieldId: formField.id, columnName: formField.columnName });
      });
      this.setState({ isLoading: false });
      this.setState({ formFields: formFields, fieldsObject: fieldsObjects });
    }
  };

  onSubmitHandler = async event => {
    if (this.validator.allValid()) {
      let fieldsObjects = this.state.fieldsObject;
      let index = 0;
      for (const key of this.state.formFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          let selectedLocation = [];
          selectedLocation = this.state['search' + key] && this.state['search' + key].find((list) => this.state[key] == list.value);
          if (selectedLocation && selectedLocation.value) {
            fieldsObjects[index]["fieldValue"] = selectedLocation.value;
          } else {
            fieldsObjects[index]["fieldValue"] = "";
          }
        } else {
          fieldsObjects[index]["fieldValue"] = this.state[key];
        }
        index++;
      }
      var req = {
        serviceModuleId: this.state.serviceModuleId,
        companyId: localStorage["440a28"],
        formHeaderId: this.state.formHeaderId,
        formId: this.state.formId,
        email: this.state.email,
        fieldData: fieldsObjects,
        invite: this.state.invite,
        isTestUser: this.state.isTestUser,
        isApprover: this.state.isApprover,
        expense: {
          cashAdvanceWorkflow: "5c77d189f88fd5361c70537a"
        }
      };
      // * request to be sent
      if (this.state.allDelegatedUsers.length > 0) {
        req.delegatedUser = this.state.allDelegatedUsers;
      }
      // if (this.state.travel.travelClass != '' && this.state.travel.travelWorkflow != '' && this.state.travel.travelAllowance != '') {
      req.travel = {
        travelClass: this.state.travel.travelClass,
        travelWorkflow: this.state.travel.travelWorkflow,
        travelAllowance: this.state.travel.travelAllowance,
        travelDiscount: this.state.travel.travelDiscount
      }
      // }
      let created = await companyService.createUserCompany(req);
      if (created) {
        toast.success(alertConstants.CREATED_SUCCESSFULLY);
        this.props.history.push("/administration/company/management");
      }
    } else {
      this.validator.showMessages();
      this.setState({
        ...this.state
      })
    }
  };
  onCancelClickHandler = () => {
    this.props.history.push("./details");
  };

  onCountryChangeHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    //* On Group Change, get New Dynamic Form Fields
    if (fieldName == 'GroupName') {
      this.setState({
        groupId: fieldId,
        isGroupChanged: true,
        oldGroupId: fieldId
      }, () => {
        this.getUserDetails(this.props.match.params.id, this.state.groupId);
      })
    }
    // * End of Group Change Code
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter((listData) => listData.multiSelectParentField === conId && listData.fieldLevel === newFieldLevel);
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(companyId, fieldId);
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };

  // * handler for dropdown
  onClickHandler = async (fieldName, conId, fieldId, fieldLevel,assignKey) => {

    this.state.search[assignKey] = '';
    this.setState({search:this.state.search})

    // * On Group Change, get New Dynamic Form Fields
    if (fieldName == 'GroupName') {
      this.setState({
        groupId: fieldId,
        isGroupChanged: true,
        oldGroupId: fieldId
      }, () => {
        this.getUserDetails(this.props.match.params.id, this.state.groupId);
      })
    }
    // * End of Group Change Code
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter((listData) => listData.multiSelectParentField === conId && listData.fieldLevel === newFieldLevel);
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(companyId, fieldId);
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  // * connectedList handler
  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter((listData) => listData.multiSelectParentField === conId && listData.fieldLevel === newFieldLevel);
    let setConnect = connectedListData.filter((item) => item.fieldId === fieldId)

    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(companyId, fieldId);
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onChangeHandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };
  newDatePickerHandler = (value, fieldName) => {
    this.setState({
      startDate: value,
      [fieldName]: new Date(value)
    });
  };


  onDatePickerHandler = (value, fieldName) => {
    if (!isNaN(value)) {
      this.setState({ [fieldName]: moment(new Date(value)).format("DD MMM YYYY") });
    }
  };
  onCheckboxHandler = fieldName => {
    this.setState({ [fieldName]: !this.state[fieldName] });
  };
  onIntegerHandler = (event, fieldName) => {
    if (!isNaN(event.target.value)) {
      this.setState({ [fieldName]: (event.target.value) });
    }
  };
  // * New handlers for new data types
  onLocationChangekHandler = (event, fieldName) => {
    this.setState({ [fieldName]: event });
  }
  loadOptions = async (newValue) => {
    return expenseReport.getAllLocationsName(newValue).then((data) => {
      return data.result.map(list => {
        return {
          label: list.name + ',' + list.countryId.name,
          value: list.id
        };
      });
    })
  }
  setConnectListID = (id) => {
    this.state.connectedListId = id
  }
  loadOptionsNew = async (newValue) => {
    return getConnectedList(this.state.companyId, newValue, this.state.connectedListId, 1, 10).then((data) => {
      return data.result.map(list => {
        return {
          label: list.itemName + ',' + list.itemCode,
          value: list.id
        };
      });
    })
  }
  onSearchTextHandler = async (event, fieldName) => {
    // this.setState({closeDropdown:true});
    if (event.target.value == '') {
      this.setState({ isDropdownHidden: true })
    } else {
      this.setState({ isDropdownHidden: false });
    }
    let search = event.target.value;
    this.setState({ [fieldName]: search })
    let response = await expenseReport.getAllLocationsName(search);
    let locationNames = [];
    if (response) {
      (getValue(response,"result",[])).forEach((list) => {
        locationNames.push({ label: list.name, value: list.id, fieldName: 'search' + fieldName, country: list.countryId.name });
      });
      this.setState({ ['search' + fieldName]: locationNames });
    } else {
      this.setState({ ['search' + fieldName]: [] });
    }
  }
  getTravelRuleClass = async () => {
    let res = await companyService.getTravelClass(this.state.companyId);
    if (res) {
      this.setState({
        allTravelClass: res.result
      });
      return res.result;
    }
  };
  getTravelAllowance = async () => {
    let res = await companyService.getTravelAllowance(this.state.companyId);
    if (res) {
      this.setState({
        allTravelAllowance: res.result
      });
      return res.result;
    } else {
      toast.info(alertConstants.TRAVEL_ALLOWANCE_NOT_FOUND);
    }
  };
  getTravelWorkflow = async () => {
    let res = await companyService.getTravelWorkflow(this.state.companyId);
    if (res) {
      // console.log(res);
      this.setState({
        allTravelWorkflow: res.result
      });
      return res.result;
    }
  };
  hasMore = true;
  delegatedItem = [];
  delegatedView = [];

  // * for infinite scroll in userList
  fetchMoreData = () => {
    if (this.userList.length >= this.count) {
      this.hasMore = false;
      this.forceUpdate();
      return;
    }
    this.activePage = this.activePage + 1;
    this.getUserList();
  };
  serviceList = [];

  // * get service Mode
  getServiceMode = async () => {
    var resp = await companyService.getCompanyService(this.state.companyId);
    if (resp) {
      this.serviceList = resp;
      this.forceUpdate();
    }
  };
  userList = [];
  itemsPerPage = 10;
  activePage = 1;
  count = 0;
  search = "";

  // * For getting the list of all the users ?
  getUserList = async () => {

    // console.log("at least I am coming here");


    // * NEw code for getting UserList. Calling two APIs

    // * Code for getting form ID
    let formDetails = await companyService.getFormDetails(this.state.companyId);
    if (formDetails !== null && formDetails !== "") {
      // console.log("formDetails ", formDetails);
      this.setState({
        formIdForGettingUsers: formDetails.result[0]['id'],
      }, () => {

        var req = {};

        //  * if edit mode  then send user id with the request.

        if (this.isEdit == true) {
          req = {
            companyId: localStorage["440a28"],
            // formId: this.state.formIdForGettingUsers,
            activeStatus: true,
            parentUserId: this.props.match.params.id,
            isDelegate: true,
            sort: "asc",
            search: this.search,
            page: this.activePage,
            limit: this.itemsPerPage,
          };
        } else {
          req = {
            companyId: localStorage["440a28"],
            isDelegate: true,
            // formId: this.state.formIdForGettingUsers,
            activeStatus: true,
            sort: "asc",
            search: this.search,
            page: this.activePage,
            limit: this.itemsPerPage,
          }

        }
        companyService.getListUser(req).then(
          data => {
            if (data !== null && data !== "") {
              this.count = data.count;

              this.userList = data.result.userData;
              this.setState({
                allUsers: this.userList
              });
            }
          }).catch('error');
      })
    }

    // * Convert list of users to list of searchable default manager

    // * End of code getting form ID
  };

  getFullNameFromFieldData = (response, managerType) => {
    if (managerType == 'defaultManager1') {

      if (response.reportManager1 && response.reportManager1.fieldData.length) {
        let firstName = (response.reportManager1.fieldData.find(field => field.columnName == "EMPLOYEE_FIRST_NAME")).fieldValue;
        let middleName = (response.reportManager1.fieldData.find(field => field.columnName == "MIDDLE_NAME")).fieldValue;
        let lastName = (response.reportManager1.fieldData.find(field => field.columnName == "EMPLOYEE_LAST_NAME")).fieldValue;
        return firstName + ' ' + middleName + ' ' + lastName;
      }
    }
    if (managerType == 'defaultManager2') {
      if (response.reportManager2 && response.reportManager2.fieldData.length) {
        let firstName = (response.reportManager2.fieldData.find(field => field.columnName == "EMPLOYEE_FIRST_NAME")).fieldValue;
        let middleName = (response.reportManager2.fieldData.find(field => field.columnName == "MIDDLE_NAME")).fieldValue;
        let lastName = (response.reportManager2.fieldData.find(field => field.columnName == "EMPLOYEE_LAST_NAME")).fieldValue;
        return firstName + ' ' + middleName + ' ' + lastName;
      }
    }
  }

  convertUserlistToManagerList = (users) => {
    let searchedUsers = users.map(user => {
      let cleanUser = {
        id: user.id
      }
      if (user.fieldData.length) {
        let firstName = (user.fieldData.find(field => field.columnName == "EMPLOYEE_FIRST_NAME")).fieldValue;
        let middleName = (user.fieldData.find(field => field.columnName == "MIDDLE_NAME")).fieldValue;
        let lastName = (user.fieldData.find(field => field.columnName == "EMPLOYEE_LAST_NAME")).fieldValue;

        cleanUser.name = firstName + ' ' + middleName + ' ' + lastName;
      } else {
        cleanUser.name = user.email
      }
      return cleanUser;
    });
    this.setState({
      searchedUsers
    }, () => console.log('lalalal', this.state.searchedUsers));
  }
  convertUserlistToManagerList2 = (users) => {
    let searchedUsers = users.map(user => {
      let cleanUser = {
        id: user.id
      }
      if (user.fieldData.length) {
        let firstName = (user.fieldData.find(field => field.columnName == "EMPLOYEE_FIRST_NAME")).fieldValue;
        let middleName = (user.fieldData.find(field => field.columnName == "MIDDLE_NAME")).fieldValue;
        let lastName = (user.fieldData.find(field => field.columnName == "EMPLOYEE_LAST_NAME")).fieldValue;

        cleanUser.name = firstName + ' ' + middleName + ' ' + lastName;
      } else {
        cleanUser.name = user.email
      }
      return cleanUser;
    });
    this.setState({
      searchedUsers2: searchedUsers
    });
  }
  userDetail = [];
  //  * get userdetails for edit
  getUserDetails1 = async id => {

    this.userDetail = await companyService.getUserDetail(id);


    //  * code for checking if delegated user exists in userDetail
    if (this.userDetail && this.userDetail.delegatedUser.length > 0) {
      // console.log(this.userDetail.delegatedUser);
      await this.getUserList();
      // console.log(this.userList);
      let allDelegatedUsers = [...this.userDetail.delegatedUser];

      // console.log("list of delegated users ", allDelegatedUsers);
      // * Match all delegated users from the userlist and add fullName to it
      let allDelegatedUsersWithName = allDelegatedUsers.map(dgu => {
        const user = this.userList.find(u => u.id == dgu.parentUserId);
        if (!user) {
          return dgu;
        }
        return {
          ...dgu,
          email: user.email
        }
      })
      this.setState(
        {
          delegatedUser: this.userDetail.delegatedUser[0],
          allDelegatedUsers: allDelegatedUsersWithName,
          isDelegateUserExists: true
        },
        async () => {
          this.forceUpdate();
        }
      );
    } else {
      this.setState({
        isDelegateUserExists: false
      });
    }
  };


  submitUpdateHandle = () => {
    if (this.validator.allValid()) {
      let fieldsObjects = this.state.fieldsObject;
      let index = 0;
      for (const key of this.state.formFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          let selectedLocation = [];
          selectedLocation = this.state['search' + key] && this.state['search' + key].find((list) => this.state[key] == list.value);
          if (selectedLocation && selectedLocation.value) {
            fieldsObjects[index]["fieldValue"] = selectedLocation.value;
          } else {
            fieldsObjects[index]["fieldValue"] = "";
          }

        } else {
          fieldsObjects[index]["fieldValue"] = this.state[key] ? this.state[key] : "";
        }
        index++;
      }


      let payload = {};

      // * if is verified Information is true, dont send invite
      if (this.state.isVerifiedInvitation) {
        payload = {
          fieldData: fieldsObjects,
          isTestUser: this.state.isTestUser,
          // * for edit case dont send invite
          // invite: this.state.invite,
          isApprover: this.state.isApprover,
          profileImage: this.state.profileImage
        };
      } else {
        payload = {
          fieldData: fieldsObjects,
          isTestUser: this.state.isTestUser,
          invite: this.state.invite,
          isApprover: this.state.isApprover,
          profileImage: this.state.profileImage
        };
      }



      // * Default Manager Stuff
      if (this.state.defaultManager1 && this.state.defaultManager1.value) {
        payload.reportManager1 = this.state.defaultManager1.value;
      }
      if (this.state.defaultManager2 && this.state.defaultManager2.value) {
        payload.reportManager2 = this.state.defaultManager2.value;
      }

      if (this.state.allDelegatedUsers.length > 0) {
        payload.delegatedUser = this.state.allDelegatedUsers;
        payload.isDelegateUserExists = this.state.isDelegateUserExists;

      } else {
        payload.delegatedUser = [];
      }
      // if (this.state.travel.travelClass != '' && this.state.travel.travelWorkflow != '' && this.state.travel.travelAllowance != '') {
      payload.travel = this.state.travel;

      // }


      companyService
        .updateUserCompany(this.props.match.params.id, payload)
        .then(data => {
          this.props.history.push("/administration/company/management");
          // console.log(alertConstants.UPDATED_SUCCESSFULLY,"+++++++++++")
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };
  //  * for handling email
  staticHandle = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleStatusChange = (id, activeStatus) => {
    let newStatus = !activeStatus;
    let payload = {
      id: id,
      activeStatus: newStatus
    };
    companyService.activeInactiveUserManage(id, payload).then(
      data => {
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        this.setState({
          activeStatus: newStatus
        });
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  };

  // * Test user handler
  testUserHandler = status => {
    let newStatus = !status;
    this.setState({
      isTestUser: newStatus
    });
  };

  // * Invite Handler

  inviteHandler = status => {
    let newStatus = !status;
    this.setState({
      invite: newStatus
    });
  };

  inviteHandlerApprover = (status) => {
    let newStatus = !status;
    this.setState({
      isApprover: newStatus
    });
  }

  // * travel selectors here
  handleTravelSelector = (e, type) => {
    if (type == "travelClass") {
      // console.log(e);
      this.setState(
        {
          selectedTravelClass: e.className,
          travel: {
            ...this.state.travel,
            travelClass: e.id
          }
        });
    }
    if (type == "travelAllowance") {
      this.setState({
        selectedTravelAllowance: e.configurationName,
        travel: {
          ...this.state.travel,
          travelAllowance: e.id
        }
      });
    }
    if (type == "travelWorkflow") {
      this.setState({
        selectedTravelWorkflow: e.workflowName,
        travel: {
          ...this.state.travel,
          travelWorkflow: e.workflow_id
        }
      });
    }
    if (type == "travelDiscount") {
      this.setState({
        selectedTravelDiscount: e.configName,
        travel: {
          ...this.state.travel,
          travelDiscount: e.id
        }
      });
    }
  };

  // * End of travel selectors
  // submit handler when adding a user


  handleChangeDelegate = e => {
    // this.state.delegatedUser[e.target.name] = e.target.value;
    this.setState({
      delegatedUser: {
        ...this.state.delegatedUser,
        [e.target.name]: e.target.value
      }
    });
    // this.setState({
    //   ...this.state
    // });
  };

  serviceName = "Service";
  accessTypeName = "Access Type";


  searchUser = e => {
    this.search = e.target.value;
    this.delegatedView = "";
    this.delegatedItem = "";
    if (this.search) {
      this.getUserList();
    } else {
      this.userList = []
      this.setState({
        allUsers: this.userList
      });
    }
  };

  // * Choose Delegate Handler

  chooseDelegate = selectedUser => {
    //  * check if selected user is there in the delegated list
    let allDelegatedUsers = [...this.state.allDelegatedUsers];
    let selectedIndex = allDelegatedUsers.find(o => o.parentUserId == selectedUser.id);
    this.delegatedItem = selectedUser.id;
    //  * if the user is not there, add her
    if (selectedIndex == undefined) {
      let tempObj = {
        email: selectedUser.email,
        parentUserId: selectedUser.id,
        startDate: "",
        endDate: "",
        accessType: "0",
        serviceModule: [],
        profileImage: selectedUser.profileImage,
        fullName: selectedUser.fullName
      }
      allDelegatedUsers.unshift(tempObj);
      this.setState({
        allDelegatedUsers
      })

    } else {
      toast.warn("User already exists in delegated list");
    }


  };

  // * Remove Delegate Handler

  removeDelegateHandler = async (item) => {
    //  ` console.log("item to be removed ", item);
    //   console.log(this.isEdit);`

    if (this.isEdit && item._id) {
      let res = await companyService.removeDelegatedUser(item._id);
      if (res) {
        toast.success(alertConstants.DELETED_SUCCESSFULLY);
      }
    }
    let allDelegatedUsers = [...this.state.allDelegatedUsers];
    // * matching delegate Id and deleting
    let tempArray = allDelegatedUsers.filter(o => o.parentUserId !== item.parentUserId)
    this.setState({ allDelegatedUsers: tempArray })


  };

  // * End of Remove Delegate Handler

  // * Delegate fields Change handler
  changeData = (e, type, item) => {



    let allDelegatedUsers = [...this.state.allDelegatedUsers];
    // * match which delegated user details are changing
    let matcheduser = allDelegatedUsers.find(o => o.parentUserId == item.parentUserId);
    // * match which index delegated user details are changing
    let matchedIndex = allDelegatedUsers.findIndex(o => o.parentUserId == item.parentUserId);

    if (type === "service") {

      // * change the value
      let service = [];
      service = e.map((list) => list.value);
      matcheduser.serviceModule = service;
      // * Replace the old object with the new one
      allDelegatedUsers.splice(matchedIndex, 1, matcheduser);
      this.setState({
        allDelegatedUsers
      }, () => console.log(" after change ", this.state.allDelegatedUsers));


    } else if (type === "accessType") {
      let accessType = [];
      accessType = e.map((list) => list.value);
      matcheduser.accessType = accessType;
      allDelegatedUsers.splice(matchedIndex, 1, matcheduser);
      this.setState({
        allDelegatedUsers
      }, () => console.log(" after change ", this.state.allDelegatedUsers));

    }

  };

  dayChangeHandler = (day, type, item) => {
    let allDelegatedUsers = [...this.state.allDelegatedUsers];
    // * match which delegated user details are changing
    let matcheduser = allDelegatedUsers.find(o => o.parentUserId == item.parentUserId);
    // * match which index delegated user details are changing
    let matchedIndex = allDelegatedUsers.findIndex(o => o.parentUserId == item.parentUserId);
    if (type == 'startDate') {
      matcheduser.startDate = "";
      matcheduser.startDate = day;
      allDelegatedUsers.splice(matchedIndex, 1, matcheduser);
      this.setState({
        allDelegatedUsers
      });
    } else if (type == 'endDate') {
      matcheduser.endDate = day;
      allDelegatedUsers.splice(matchedIndex, 1, matcheduser);
      this.setState({
        allDelegatedUsers
      });
    }
  }

  // * End of Fields Change handler

  // * Handle Image Change
  imageSelectedHandler = e => {
    let selectedImage = e.target.files[0];
    const types = ['image/png', 'image/jpeg', 'image/gif'];

    // * extra checks for later

    // else if(selectedImage.size > 150000){
    //   alert('Image is too large, please pick a smaller file')
    // }
    // * Check is the uploaded file is an image
    if (types.every(type => selectedImage.type !== type)) {
      alert(`${selectedImage.type} is not supported`);
    } else {
      this.setState({
        selectedImage
      }, async () => {
        let fd = new FormData();
        fd.append('file', selectedImage);
        $("#notimg").addClass("d-none");

        let res = await companyService.uploadProfilePhoto(fd);
        if (res) {
          this.setState({ profileImage: res.result.profileImage });
        } else {

        }
      })
    }


  }

  // * Editing the user code from rohit
  getUserDetails = async (id, groupId = '') => {
    let response;
    if (groupId) {

      response = await companyService.getUserDetailOnGroupChange(id, groupId);

    } else {
      response = await companyService.getUserDetail(id);
    }

    //  * For travelRuleClass TravelAllowance, TravelWorkflow
    // * For handling travel settings
    const [
      tempTravelRuleClass,
      tempTravelAllowance,
      tempTravelWorkflow
    ] = await Promise.all([
      this.getTravelRuleClassPromise,
      this.getTravelAllowancePromise,
      this.getTravelWorkflowPromise
    ]);
    let selectedTravelClassOptionName = "";
    let selectedTravelAllowanceOptionName = "";
    let selectedTravelWorkflowOptionName = "";
    var selectedDiscountOptionName = "";

    if (tempTravelRuleClass != undefined) {

      if (response.travel && response.travel.travelClass) {
        let selectedTravelClassOption = tempTravelRuleClass.find(
          item => item.id == response.travel.travelClass
        );
        if (selectedTravelClassOption != -1) {

          selectedTravelClassOptionName = selectedTravelClassOption?.className;
        }
      }
    }

    if (tempTravelAllowance != undefined) {

      if (response.travel && response.travel.travelAllowance) {
        let selectedTravelAllowanceOption = tempTravelAllowance.find(
          item => item.id == response.travel.travelAllowance
        );
        if (selectedTravelAllowanceOption != -1) {

          selectedTravelAllowanceOptionName =
            selectedTravelAllowanceOption?.configurationName;
        }
      }
    }

    if (tempTravelWorkflow != undefined) {


      if (response.travel && response.travel.travelWorkflow) {
        let selectedTravelWorkflowOption = tempTravelWorkflow.find(
          item => item.workflow_id == response.travel.travelWorkflow
        );
        if (selectedTravelWorkflowOption != -1) {

          selectedTravelWorkflowOptionName =
            selectedTravelWorkflowOption?.workflowName;
        }
      }
    }
    if (response.travel && response.travel.travelDiscount) {
      selectedDiscountOptionName = this.state.discountList.find(
        item => item.id == response.travel.travelDiscount
      );
    }
    this.setState({
      travel: {
        ...this.state.travel,
        travelClass: response.travel?.travelClass || '',
        travelAllowance: response.travel?.travelAllowance || '',
        travelWorkflow: response.travel?.travelWorkflow || '',
        travelDiscount: response.travel?.travelDiscount || ''
      },
      selectedTravelClass: selectedTravelClassOptionName,
      selectedTravelAllowance: selectedTravelAllowanceOptionName,
      selectedTravelWorkflow: selectedTravelWorkflowOptionName,
      selectedTravelDiscount: selectedDiscountOptionName?.configName,
      isVerifiedInvitation: response.isVerifiedInvitation,
      defaultManager1: { label: getFullUserName(response.reportManager1 && response.reportManager1.fieldData ? response.reportManager1.fieldData : []), value: response.reportManager1 && response.reportManager1.id },
      defaultManager2: { label: getFullUserName(response.reportManager2 && response.reportManager2.fieldData ? response.reportManager2.fieldData : []), value: response.reportManager2 && response.reportManager2.id },
      invite: response.isVerifiedInvitation,
      isApprover: response.isApprover,
      isTestUser: response.isTestUser,
      serviceModuleId: response.serviceModuleId,
      formHeaderId: response.formHeaderId,
      formId: response.formId,
      profileImage: response.profileImage
    })



    // *End of handling travel settings

    // *End of travelRuleClass, TravelAllowance and TravelWorkflow
    // console.log(response,'response++++++++++');
    // * for delegated Users
    if (response && response.delegatedUser.length > 0) {
      // console.log(this.userDetail.delegatedUser);
      // await this.getUserList();
      // console.log(this.userList);
      let allDelegatedUsers = [...response.delegatedUser];

      // console.log("list of delegated users ", allDelegatedUsers);
      // * Match all delegated users from the userlist and add fullName to it
      let allDelegatedUsersWithName = allDelegatedUsers.map(dgu => {
        const user = this.userList.find(u => u.id == dgu.parentUserId);
        if (!user) {
          return dgu;
        }
        return {
          ...dgu,
          email: user.email
        }
      })
      this.openDelegate()
      this.setState({
        // delegatedUser: this.userDetail.delegatedUser[0],
        allDelegatedUsers: allDelegatedUsersWithName,
        isDelegateUserExists: true
      },
      );
    } else {
      this.setState({
        isDelegateUserExists: false
      });
    }

    // * End of delegated Users

    //  * Start of Rohit Code
    if (response && response.fieldData) {
      await response.fieldData.forEach(async item => {
        if (item.connectedListId) {
          let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp, "count", 0);
          item.connectedListData.listManagementData = resp.result;
          item.search = ''
        }
        this.setState({ expenseForm: response.fieldData })
      })
      this.setState({
        email: response.email
      });

      let newConnectedListDate = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.fieldData, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== '';
      });
      dynamicFeilds.forEach((fields) => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField ? fields.multiSelectParentField : '',
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          fieldValue: fields.fieldValue
        });
      })
      this.setState({ connectedListData: newConnectedListDate });
      newConnectedListDate && newConnectedListDate.forEach(async (fields) => {
        if (fields.fieldLevel === 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.id && listData.fieldLevel === newFieldLevel);
          if (existConnected.length > 0 && fields.fieldValue !== undefined && fields.fieldValue != '') {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(companyId, fields.fieldValue);
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.multiSelectParentField && listData.fieldLevel === newFieldLevel);
          if (existConnected.length > 0 && fields.fieldValue !== undefined && fields.fieldValue != '') {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(companyId, fields.fieldValue);
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }

      });
      let formFields = [];
      let fieldsObjects = [];
      response.fieldData.forEach(async (formField, index) => {
        let fieldName = formField.fieldName;

        if (fieldName == 'GroupName' && this.state.isGroupChanged) {
          this.setState({ [fieldName]: this.state.oldGroupId }, () => console.log('new group change ', this.state));
        }

        if (formField.displayType === "date") {
          this.setState({ [fieldName]: formField.fieldValue ? formField.fieldValue : null });
        } else if (formField.displayType === "checkbox") {
          this.setState({ [fieldName]: formField.fieldValue });
        } else if (formField.dataType === "search") {
          if (formField.fieldValue !== undefined && formField.fieldValue !== '') {
            await this.setState({ [fieldName]: { label: formField.location.locationName, value: formField.fieldValue } });
          } else {
            this.setState({ [fieldName]: "" });
          }
        }
        else {
          this.setState({ [fieldName]: formField.fieldValue });
        }
        // * if the groupName has been chamged, set the new groupname field data to the changed groupName
        //  * from server we will receive the old data, thats why we do it.
        if (fieldName == 'GroupName' && this.state.isGroupChanged) {
          this.setState({ [fieldName]: this.state.oldGroupId });
        }
        // if (formField.dataType === "search") {
        //   let response = await expenseReport.getAllLocationsName('');
        //   let locationNames = [];
        //   response.result.forEach((list)=>{
        //     locationNames.push({label:list.name,value:list.id,fieldName:'search'+fieldName});
        //   })
        //     this.setState({ ['search'+fieldName]:locationNames  });

        //     if(formField.isDefaultValue){
        //       this.setState({ [fieldName]: formField.defaultValue });
        //     }else{
        //       this.setState({ [fieldName]: "" });
        //     }
        // }
        formFields.push(formField.fieldName);
        this.setState({ submitButtonStatus: false });
        fieldsObjects.push({ fieldId: formField.id, columnName: formField.columnName });
      });
      this.setState({ isLoading: false });
      this.setState({ formFields: formFields, fieldsObject: fieldsObjects });
    }
  };

  //  * End of editing of user code from rohit

  // * start of code for search manager
  searchManager = async (e) => {
    let searchTerm = e.target.value;
    this.setState({ defaultManager1: searchTerm });
    if (searchTerm !== '') {
      let res = await companyService.searchUsers(searchTerm, this.state.companyId);
      if (res && res.count > 0) {
        this.convertUserlistToManagerList(res.result);
      }
    } else {
      this.setState({ searchedUsers: [] });
    }
  }

  searchManager2 = async (e) => {
    let searchTerm = e.target.value;
    this.setState({ defaultManager2: searchTerm });
    if (searchTerm !== '') {
      let res = await companyService.searchUsers(searchTerm, this.state.companyId);
      if (res && res.count > 0) {
        this.convertUserlistToManagerList2(res.result);
      }
    } else {
      this.setState({ searchedUsers2: [] });
    }

  }

  selectDefaultManager = (user, managerType) => {
    if (managerType === 'defaultManager1') {
      this.setState({ defaultManager1: user });
    }
    if (managerType === 'defaultManager2') {
      this.setState({ defaultManager2: user });
    }
  }
  onFocusDropdownHandler = (event, fieldName) => {
    if (this.state[fieldName] === '') {
      this.setState({ isDropdownHidden: true });
    }
  }

  handleDefaultSearchManager = async (searchManager) => {
    return companyService.searchUsers(searchManager, this.state.companyId).then((data) => {
      return data.result.map(list => {
        return {
          label: getFullUserName(list.fieldData) + ' ' + list.email,
          value: list.id
        };
      });
    })
  }

  //added for  connected list data:sethu

  handleScroll = async (e, id, assignKey, page, count, totalLength, search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    setValue(this.state, "clientHeight", e.target.scrollHeight)
    if (bottom) {
      this.setState({
        already:true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight-25)
      })
      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {
          let mergeOld = getValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", []).concat(resp.result)
          setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", mergeOld)
          setValue(this.state.expenseForm[assignKey], "page", page + 1)
          this.setState({
            ...this.state
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }
    }
  }
  handleSearchManage = async (search, id, assignKey) => {


    this.state.loading[assignKey] = true;
    this.state.search[assignKey] = search;
    this.setState({search:this.state.search}, () => {
      $(`#valueConnect${assignKey}`).focus();
    })

      let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
      if (resp) {
        setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", resp.result)
      } else {
        setValue(this.state.expenseForm[assignKey], "connectedListData.listManagementData", [])
      }
      setValue(this.state.expenseForm[assignKey], "search", search)

      this.state.loading[assignKey] = false;
      this.setState({
        ...this.state
      })
      $(`#valueConnect${assignKey}`).focus();
      $(`#valueConnect${assignKey}`).attr('value', search);
      $(`#connect${assignKey}`).addClass("show")

}
  render() {
    return (
      <AddUserPage
        openDelegate={e => this.openDelegate()}
        fieldData={this.listOfForm}
        error={this.validator}
        state={this.state}
        handleChange={this.handleChange}
        staticHandle={this.staticHandle}
        onSubmitHandler={this.onSubmitHandler}
        isEdit={this.isEdit}
        submitUpdateHandle={this.submitUpdateHandle}
        dummuyValue={this.dummuyValue}
        userList={this.userList}
        handleSelectChange={this.handleSelectChange}
        delegatedItem={this.delegatedItem}
        delegatedView={this.delegatedView}
        chooseDelegate={this.chooseDelegate}
        inviteHandlerApprover={this.inviteHandlerApprover}
        serviceList={this.serviceList}
        changeData={this.changeData}
        handleChangeDelegate={this.handleChangeDelegate}
        accessTypeName={this.accessTypeName}
        serviceName={this.serviceName}
        fetchMoreData={this.fetchMoreData}
        hasMore={this.hasMore}
        searchUser={this.searchUser}
        search={this.search}
        count={this.count}
        handleStatusChange={this.handleStatusChange}
        removeDelegateHandler={this.removeDelegateHandler}
        testUserHandler={this.testUserHandler}
        inviteHandler={this.inviteHandler}
        handleTravelSelector={this.handleTravelSelector}
        dayChangeHandler={this.dayChangeHandler}
        imageSelectedHandler={this.imageSelectedHandler}
        loadOptions={this.loadOptions}
        loadOptionsNew={this.loadOptionsNew}
        handleScroll={this.handleScroll.bind(this)}
        handleSearchManage={this.handleSearchManage}


        {...this.state}
        validator={this.validator}
        policyForm={this.state.expenseForm}
        onClickHandler={this.onClickHandler}
        onCountryChangeHandler={this.onCountryChangeHandler}
        onChangeHandler={this.onChangeHandler}
        onDatePickerHandler={this.onDatePickerHandler}
        newDatePickerHandler={this.newDatePickerHandler}
        onCheckboxHandler={this.onCheckboxHandler}
        onIntegerHandler={this.onIntegerHandler}
        userPermission={this.state.userPermission}
        onConnectedListkHandler={this.onConnectedListkHandler}
        searchManager={this.searchManager}
        searchManager2={this.searchManager2}
        selectDefaultManager={this.selectDefaultManager}
        onSearchTextHandler={this.onSearchTextHandler}
        onLocationChangekHandler={this.onLocationChangekHandler}
        onFocusDropdownHandler={this.onFocusDropdownHandler}
        handleDefaultSearchManager={this.handleDefaultSearchManager}
      />
    );
  }
}

export default AddUser;
