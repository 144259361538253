import React, { useState, Component } from 'react';
import Pagination from "react-js-pagination";
import { Input } from 'src/_components/common/_forms';
import { withRouter } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { isEmpty, getValue } from 'src/_components/common/lodsh';
import { ADMIN_CONSTANT } from 'src/_components/common/constants/ADMIN_CONSTANTS';
import { approverService } from "../../../../../../../_services";
import { toast } from 'react-toastify';
import $ from 'jquery';
import Select from "react-select";
import { checkValidityBeforeSubmit } from "src/utils/util";
import { masterService } from "src/_services";

class ApproverPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      level: '',
      amount: '',
      limit: 10,
      activePage: 1,
      itemPerPage: 10,
      totalItemsCount: 0,
      addApprovelArray: [],
      companyId: localStorage["440a28"],
      activePage: 1,
      limit: 10,
      approverData: [],
      listOfCurrency: [],
      currency: ''
    }
  }
  validator = new SimpleReactValidator();
  componentDidMount() {
    this.getApproverData();
    this.getCurrencyCode();
  }


  // --------------Get Currency code--------------------------------
  getCurrencyCode = async () => {
    var companyId = localStorage["440a28"];
    var resp = await masterService.getGroups(companyId, "CUR001");
    if (resp) {
      this.setState({
        listOfCurrency: resp.result
      })
    }
  };
  // ------------------------------------------------------------


  // --------------Pagination--------------------------------

  handlePageChange = pageNumber => {
    console.log(pageNumber)
    this.setState({ activePage: pageNumber }, () => {
      this.getApproverData();
    });
  };
  handleItemChange = limit => {
    this.setState({ itemPerPage: limit }, () => {
      this.getApproverData();
    });
  };
  // ------------------------------------------------------------
  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let levela = parseInt(
        this.state.level
      )
      let amounta = parseInt(
        this.state.amount
      )
      var data = {
        companyId: this.state.companyId,
        approverId: this.props.selectedApprover.id,
        name: this.props.selectedApprover.name,
        level: levela,
        amount: amounta,
        currency: this.state.currency
      }

      let response = await approverService.createApprover(data);
      if (response) {
        toast.success(response.message);
        this.getApproverData();
        this.forceUpdate();
      } else {
        this.setState({ isClickSubmit: false });
      }

      this.setState({
        amount: '',
        level: '',
        currency: ''
      })
      this.props.selectedApprover.name = ""
    }
    else {
      this.validator.showMessages();
      this.setState({ ...this.state })
    }
  }


  // --------------Get Approver data--------------------------------

  getApproverData = async () => {
    const response = await approverService.getApproverList(
      this.state.companyId,
      this.state.limit,
      this.state.activePage
    );
    if (response && response.result) {
      let assigned = [];
      let unAssinged = [];
      response.result.forEach(item => {
        let filterItem = []
        filterItem = getValue(this.props, ADMIN_CONSTANT.COMPANY.WORKFLOW.APPROVERLIST, []).filter(resp => item.approverId === resp.id);
        if (filterItem.length > 0) {
          assigned.push(item)
        } else {
          unAssinged.push(item)
        }
      })
      let mergeResult = assigned.concat(unAssinged);
      this.setState({
        approverData: mergeResult,
        totalItemsCount: response.count
      });
    } else {
      this.setState({
        approverData: [],
        totalItemsCount: 0
      });
    }
  }
  // ------------------------------------------------------------

  // --------------Change currency--------------------------------

  changeCurrency = (e) => {
    let currencyArray = []
    var ApproverCurrency = this.state.listOfCurrency.filter((data, i) => {
      if (data.itemCode == e.label) {
        return currencyArray.push({
          label: data.itemCode,
          value: data.id
        })
      }
    })
    this.setState({
      currency: currencyArray[0]
    })
  };
  // ------------------------------------------------------------

  render() {
    let customCurrency = []
    this.state.listOfCurrency.forEach(item => {
      customCurrency.push({ value: item.itemName, label: item.itemCode })
    })
    return (
      <div>
        <div className="modal-middle p-0">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group custom">
                <Input
                  label="Approver Name"
                  type="text"
                  name="searchApprover"
                  defaultValue="Approver Name"
                  value={this.props.selectedApprover.name}
                  controlFunc={e => this.props.handleChangeApprover(e)}
                // error={this.validator.message('Approver', this.state.level, 'required|approver')}
                />
                <p style={{ color: "#ec7373" }}>{this.validator.message('Approver', this.state.level, 'required|approver')}</p>
              </div>
              {this.props.isSearchUserListOpen && (
                this.props.searchedUsers.length ?
                  (<ul className="scroll-ul">
                    {this.props.searchedUsers.map(user => {
                      return (
                        <li key={user.id} onClick={e => this.props.selectChangeApprover(user)} >
                          {user.name}
                        </li>
                      )
                    })}
                  </ul>) :
                  (<div className="mb-4">
                  </div>))
              }
              <div className="help-block"></div>

            </div>
            <div className="col-md-6">
              <div className="form-group custom">
                <input
                  className="form-control"
                  placeholder="&nbsp;"
                  type="number"
                  name="level"
                  value={this.state.level}
                  onChange={e => this.setState({ level: e.target.value })}
                />
                <label>Level <span className="text-danger">*</span></label>
                <div className="help-block">
                  {this.validator.message('level', this.state.level, 'required|level')}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group custom labelup">
                {/* <div className="form-group custom labelup mb-0"> */}
                {customCurrency && (
                  <Select
                    isSearchable={true}
                    options={customCurrency}
                    placeholder={customCurrency.value}
                    onChange={e => this.changeCurrency(e, "currency")}
                  />
                )}
                <label>Currency <span className="text-danger">*</span></label>
                {/* <div className="help-block">{this.validator.message(
                  "currency",
                  this.state.currency,
                  "required|Currency"
                )}</div> */}
                <div className="help-block"></div>
                {/* </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group custom">
                <input
                  className="form-control"
                  placeholder="&nbsp;"
                  type="number"
                  name="amount"
                  value={this.state.amount}
                  onChange={e => this.setState({ amount: e.target.value })}
                />
                <label>Amount <span className="text-danger">*</span></label>
                <div className="help-block">
                  {this.validator.message('level', this.state.amount, 'required|amount')}
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <button
                type="button"
                className="btn py-1"
                onClick={this.handleSubmit.bind(this)}
              >
                Add
              </button>
            </div>
          </div>
          <br></br>
          <div className="form-group search-custom ">
            <input
              autoComplete="off"
              name="searchUser"
              placeholder="Search Approver"
              className="form-control"
            />
            <span className="searc-icon-abs"><i className="material-icons">search</i></span>
          </div>

          <div className="table-container m-0">
            <div className="table-responsive">
              <table className="table">
                <thead className="text-uppercase">
                  <tr>
                    <th className="border-0"></th>
                    <th className="border-0">Name</th>
                    <th className="border-0">Level</th>
                    <th className="border-0">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {getValue(this.props, ADMIN_CONSTANT.COMPANY.WORKFLOW.APPROVERLIST, []).map((item, index) => { */}
                  {/* {this.props.selectedRule[0].data.action.action_value.approvers.map((item,index)=>{ */}

                  {this.state.approverData.map((item, index) => {

                    return (
                      <tr>
                        <td key={item.approverId}>
                          <label className="checkbox-container mb-0">
                            <input
                              type="checkbox"
                              defaultChecked={(getValue(this.props, ADMIN_CONSTANT.COMPANY.WORKFLOW.APPROVERLIST, []).filter(temp => {
                                return temp.id == item.approverId
                              }).length > 0 ? true : false)}
                              onChange={() => { this.props.approverChangeCheckbox(item.approverId, item.name, item.level, item.amount) }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </td>
                        <td className="align-middle">{item.name}</td>
                        <td className="align-middle">{item.level}</td>
                        <td className="align-middle">{item.amount}</td>
                      </tr>
                    )
                  }
                  )}
                </tbody>

              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-5">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown">
                          {this.state.itemPerPage}
                        </button>
                        <div className="dropdown-menu">
                          <button
                            onClick={this.handleItemChange.bind(this, 10)}
                            className="dropdown-item">
                            10
                            </button>
                          <button
                            onClick={this.handleItemChange.bind(this, 20)}
                            className="dropdown-item">
                            20
                            </button>
                          <button
                            onClick={this.handleItemChange.bind(this, 30)}
                            className="dropdown-item">
                            30
                            </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemPerPage}
                    totalItemsCount={this.state.totalItemsCount}
                    pageRangeDisplayed={3}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>

            <div className="modal-last mt-4 btn-group-margin">
              <span class="custom-margin" style={{ "position": "absolute", "left": "48px", "bottom": "31px" }}>
                Total approvers <b>{this.state.totalItemsCount}</b></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ApproverPage)
