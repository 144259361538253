import React, { Component } from 'react';

class CashForTripsPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <div className="p-padding">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="main-box">
                                <div className="bg-light heading">
                                    <h6>CASH FOR TRIP</h6>
                                </div>
                                <div className="employee-card">
                                    <div className="employee-details">
                                        <div className="float-left">
                                            <div className="left-icon">
                                            <i className="material-icons">person</i>
                                            </div>
                                        </div>
                                        <div className="float-left right-text">
                                            <h6>Employee Name</h6>
                                            <h6>Francine Nelson</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-details">
                                        <div className="float-left">
                                            <div className="left-icon">
                                            <i className="material-icons">cash</i>
                                            </div>
                                        </div>
                                        <div className="float-left right-text">
                                            <h6>Amount Requested</h6>
                                            <h6>$200.00</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-details">
                                        <div className="float-left">
                                            <div className="left-icon">
                                            <i className="material-icons">cash</i>
                                            </div>
                                        </div>
                                        <div className="float-left right-text">
                                            <h6>Available Balance</h6>
                                            <h6>$185.00</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-details">
                                        <div className="float-left">
                                            <div className="left-icon">
                                            <i className="material-icons">calendar_today</i>
                                            </div>
                                        </div>
                                        <div className="float-left right-text">
                                            <h6>Date Issued</h6>
                                            <h6>21/12/2018</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="main-box">
                                <div className="bg-light heading">
                                    <h6>AUDIT TRAIL</h6>
                                </div>
                                <div className="employee-profile-card">
                                    <div className="employee-info">
                                        <div className="float-left">
                                            <div className="float-left">
                                                <div className="left-icon">
                                                    <img src="/assets/images/booking/user_14.png" alt="Pic" />
                                                </div>
                                            </div>
                                            <div className="float-left right-text">
                                                <h6>Marie Baker</h6>
                                                <h6>Level 1 Approver</h6>
                                                <div className="comment-box">
                                                    <h6>COMMENT</h6>
                                                    <p className="mb-0">In at iaculis lorem. Praesent tempor dictum Duis eu nisl ut ligula cursus. </p>
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="float-right">
                                            <h6 className="text-danger ml-auto">Sent Back</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-info">
                                        <div className="float-left">
                                            <div className="float-left">
                                                <div className="left-icon">
                                                    <img src="/assets/images/booking/user_14.png" alt="Pic" />
                                                </div>
                                            </div>
                                            <div className="float-left right-text">
                                                <h6>Mary Sanders</h6>
                                                <h6>Employee</h6>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="float-right">
                                            <h6 className="text-warning ml-auto">Resubmitted</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-info">
                                        <div className="float-left">
                                            <div className="float-left">
                                                <div className="left-icon">
                                                    <img src="/assets/images/booking/user_14.png" alt="Pic" />
                                                </div>
                                            </div>
                                            <div className="float-left right-text">
                                                <h6>Mary Sanders</h6>
                                                <h6>Employee</h6>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="float-right">
                                            <h6 className="text-warning ml-auto">Resubmitted</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-info">
                                        <div className="float-left">
                                            <div className="float-left">
                                                <div className="left-icon">
                                                    <img src="/assets/images/booking/user_14.png" alt="Pic" />
                                                </div>
                                            </div>
                                            <div className="float-left right-text">
                                                <h6>Mary Sanders</h6>
                                                <h6>Employee</h6>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="float-right">
                                            <h6 className="text-warning ml-auto">Resubmitted</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="employee-info">
                                        <div className="float-left">
                                            <div className="float-left">
                                                <div className="left-icon">
                                                    <img src="/assets/images/booking/user_14.png" alt="Pic" />
                                                </div>
                                            </div>
                                            <div className="float-left right-text">
                                                <h6>Mary Sanders</h6>
                                                <h6>Employee</h6>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="float-right">
                                            <h6 className="text-warning ml-auto">Resubmitted</h6>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-padding pt-0">
                    <div className="white-card shadow-light">
                        <div className="pad-sm">
                            <h6 className="sub-heading mb-0">Approver Flow</h6>
                        </div>
                        <div>
                            <div className="approver">
                                <div className="right-icon approved">
                                <i className="material-icons">check_circle</i>
                                </div>
                                <div className="name-desg">
                                    <div className="left">
                                        <div className="pic">
                                            <img src="/assets/images/booking/user_12.png" alt="Pic" />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <p className="label">Level 1</p>
                                        <p className="title">George Green</p>
                                        <p className="desg">Finance</p>
                                    </div>
                                </div>
                            </div>
                            <div className="approver">
                                <div className="right-icon">
                                <i className="material-icons">timer</i>
                                </div>
                                <div className="name-desg">
                                    <div className="left">
                                        <div className="pic">
                                            <img src="/assets/images/booking/user_12.png" alt="Pic" />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <p className="label">Level 2</p>
                                        <p className="title">Vincent Lawson</p>
                                        <p className="desg">Approver</p>
                                    </div>
                                </div>
                            </div>
                            <div className="approver">
                                <div className="right-icon">
                                <i className="material-icons">timer</i>
                                </div>
                                <div className="name-desg">
                                    <div className="left">
                                        <div className="pic">
                                            <img src="/assets/images/booking/user_12.png" alt="Pic" />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <p className="label">Level 3</p>
                                        <p className="title">John Snow</p>
                                        <p className="desg">Manager</p>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CashForTripsPage
