import React from 'react';
import {NavLink} from 'react-router-dom';

export default function RuleSaveClose(props) {
  return (
    <>
    {!props.isSubmitClick?
    <div className="btn-group-margin mt-4">
      {
        props.editRule ?
        (<button
          className="btn btn-border-success lg-padding"
          onClick={props.updateRuleHandler}
          >
          Update
        </button>

        )
        :


        (<button
          className="btn btn-border-success lg-padding"
          onClick={props.createRuleHandler}
        >
        Save
      </button>)
      }

      <button className="btn btn-border-basic lg-padding" onClick={props.hideRuleComponentHandler} >
        Close
      </button>
    </div>
    : <div className="btn-group-margin mt-4">
        <button
          className="btn btn-border-success lg-padding"
          onClick={props.updateRuleHandler}
          >
          Please Wait...
        </button>
     </div>
    }
    </>
  )
}
