import React, { Component } from 'react';
import BookingHomeCardPage from './home-cards';
import BookingMenuPage from '../components/booking-menu';
import { NavLink } from 'react-router-dom';
class BusPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.state = {
            type: 1
        }
    }
    searchLocation = async () => {
        window.location.href = "/booking/bus/list";
    };

    handleType = () => {
        this.setState({
            type: 2
        })
    }

    handleType1 = () => {
        this.setState({
            type: 1
        })
    }
    render() {
        return (

            <div>
                <button
                    data-target="#addServiceModal"
                    data-toggle="modal"
                    className="add-button text-white"
                >
                    +
        </button>
                {/* <FullDetailsPage /> */}

                <div className="p-padding">
                    <div className="row">
                        <div className="offset-lg-1 col-lg-10">
                            <BookingMenuPage />
                            <div className="book-details">
                                <ul class="trip-way">
                                    <li>
                                        <button
                                            type="button"
                                            className={this.state.type == 1 ? "btn active" : "btn"}
                                            onClick={this.handleType1}
                                        >
                                            Request
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            type="button"
                                            className={this.state.type == 2 ? "btn active" : "btn"}
                                            onClick={this.handleType}
                                        >
                                            Online
                                        </button>
                                    </li>
                                </ul>
                                <label className="radio-container cab-radio">
                                    Onway
                                    <input
                                        type="radio"
                                        name="radio1"
                                        checked="checked"
                                        onClick={this.handleType1}
                                    />
                                    <span className="checkmark" />
                                </label>
                                <label className="radio-container cab-radio" >
                                    Round Trip
                                    <input
                                        type="radio"
                                        name="radio1"
                                        onClick={this.handleType2}
                                    />
                                    <span className="checkmark" />
                                </label>
                                <div>
                                    <div className="details from">
                                        <div className="details-margin">
                                            <div className="d-table d-table__middle">
                                                <div className="text-left">
                                                    <p className="label">From</p>
                                                    <h6 className="place">Delhi</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="popup">
                                            <div className="typ">
                                                <input type="text" placeholder="Where From?" />
                                                <div className="pin"><i className="icon ion-md-pin"></i></div>
                                            </div>
                                            <ul>
                                                <li>
                                                    <button type="button">Search Something</button>
                                                    <div className="pin"><i className="icon ion-md-airplane"></i></div>
                                                </li>
                                                <li>
                                                    <button type="button">Bangalore</button>
                                                    <div className="pin"><i className="icon ion-md-airplane"></i></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="details to">
                                        <div className="details-margin">
                                            <div className="d-table d-table__middle">
                                                <div className="text-left">
                                                    <p className="label">To</p>
                                                    <h6 className="place">Bangalore</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="popup">
                                            <div className="typ">
                                                <input type="text" placeholder="Where To?" />
                                                <div className="pin"><i className="icon ion-md-pin"></i></div>
                                            </div>
                                            <ul>
                                                <li>
                                                    <button type="button">Search Something</button>
                                                    <div className="pin"><i className="icon ion-md-airplane"></i></div>
                                                </li>
                                                <li>
                                                    <button type="button">Bangalore</button>
                                                    <div className="pin"><i className="icon ion-md-airplane"></i></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="details depart half">
                                        <div className="details-margin">
                                            <div className="d-table d-table__middle">
                                                <div className="text-left">
                                                    <p className="label">Departure</p>
                                                    <h6 className="place">28th Oct 2019</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.type == 2 ?
                                        <div className="details depart half">
                                            <div className="details-margin">
                                                <div className="d-table d-table__middle">
                                                    <div className="text-left">
                                                        <p className="label">Return</p>
                                                        <h6 className="place">28th Oct 2019</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''
                                    }
                                    <div className="details to">
                                        <div className="details-margin">
                                            <div className="d-table d-table__middle new-dropdown">
                                                <label className="text-left m-label" htmlFor="search2">
                                                    <p className="label">seat type</p>
                                                    <div className="dropdown">
                                                        <button
                                                            type="button"
                                                            className="btn dropdown-toggle"
                                                            data-toggle="dropdown">
                                                            Sleeper
                                                        </button>
                                                        <div className="dropdown-menu">
                                                            <button
                                                                className="dropdown-item">
                                                                Sleeper
                                                            </button>
                                                            <button
                                                                className="dropdown-item">
                                                                Semi Sleeper
                                                            </button>
                                                            <button
                                                                className="dropdown-item">
                                                                Standard
                                                            </button>
                                                        </div>
                                                    </div>

                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details to">
                                        <div className="details-margin">
                                            <div className="d-table d-table__middle new-dropdown">
                                                <label className="text-left m-label" htmlFor="search2">
                                                    <p className="label">Currency</p>
                                                    <div className="dropdown">
                                                        <button
                                                            type="button"
                                                            className="btn dropdown-toggle"
                                                            data-toggle="dropdown">
                                                            INR
                          </button>
                                                        <div className="dropdown-menu">
                                                            <button
                                                                className="dropdown-item">
                                                                INR
                            </button>
                                                            <button
                                                                className="dropdown-item">
                                                                USD
                            </button>
                                                        </div>
                                                    </div>

                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details to">
                                        <div
                                            className="details-margin"
                                        >
                                            <div className="d-table d-table__middle new-thing">
                                                <label className="text-left m-label" htmlFor="search2">
                                                    <p className="label">Estimated Amount</p>
                                                    <h6 className="place">
                                                        <input class="form-control" placeholder="&nbsp;" value="1000" />

                                                    </h6>
                                                    <p className="bottom">
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <button
                                        onClick={this.searchLocation}
                                        className="btn details search"
                                    >
                                        <i className="material-icons">search</i>
                                    </button>
                                    {/* <NavLink to="/booking/bus/list" className="btn details search">
                                    <i className="material-icons">search</i>
                                </NavLink> */}
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <BookingHomeCardPage />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BusPage
