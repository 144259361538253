import React, { Component } from 'react';
import CompanyLocationPage from './company-location-html';
import { companyService } from '../../../../../../_services/company.service';
import { alertConstants } from '../../../../../../_constants/alert.constants';
import { toast } from 'react-toastify';
class CompanyLocation extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    itemsPerPage = 10;
    activePage = 1
    count = 0;
    companyList = [];
    pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
    loading = false;
    componentDidMount() {
        this.getCompany();
    }
    handlePageChange = (pageNumber) => {
        this.activePage = pageNumber
        this.getCompany()
    }
    getCompany() {
        this.loading = true;
        var companyId = localStorage['440a28']
        companyService.getCompanyLocation(companyId, this.activePage, this.itemsPerPage)
            .then(
                data => {
                    this.loading = false;  
                    if (data !== null && data !== '') {
                        this.count = data.count;
                        this.companyList = data.result; 
                    } else {
                        this.count = 0;
                    }
                    this.forceUpdate()
                }, error => {
                    this.loading = false;
                    if (error.statusCode === 422) {
                        let errors = error.message
                        Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                    } else {
                        toast.error(error.message)
                    }
                }
            )
    }
    sortBy = (e) => {
        let arrayCopy = this.companyList;
        arrayCopy.sort(this.compareBy(e));
        this.companyList = arrayCopy;
        this.forceUpdate()
    }
    column = ''
    isDesc = ''
    compareBy(property) {
        this.isDesc = !this.isDesc; //change the direction   
        this.column = property;
        let direction = this.isDesc ? 1 : -1;
        return function (a, b) {
            if (a[property] < b[property]) {
                return -1 * direction;
            }
            else if (a[property] > b[property]) {
                return 1 * direction;
            }
            else {
                return 0;
            }
        };
    }
    handleSearch = (e) => {
        var value = e.target.value;
        if (value === '') {
            this.getCompany()
        } else {
            this.loading = true;
            companyService.searchCompany(value, '5c62a3fe83d4ef6fb672607c', this.activePage, this.itemsPerPage)
                .then(
                    data => {
                        this.loading = false;
                        this.count = 0;
                        if (data !== null && data !== '') {
                            this.count = data.count;
                            this.companyList = data.result;
                            this.forceUpdate()
                        } else {
                            this.companyList = [];
                            this.forceUpdate()
                        }
                    }, error => {
                        this.loading = false;
                        if (error.statusCode === 422) {
                            let errors = error.message
                            Object.keys(errors).map(key => {
                                return (
                                    toast.error(errors[key])
                                );
                            })
                        } else {
                            toast.error(error.message)
                        }
                    }
                )
        }
    }
    handleStatusChange = (data) => {
        let newStatus = !data.activeStatus;
        let payload = {
            id: data.id,
            activeStatus: newStatus
        }
        companyService.activeInactiveCompany(data.companyId, payload)
            .then(data => {
                toast.success(alertConstants.UPDATED_SUCCESSFULLY)
                this.getCompany();
            }, error => {
                if (error.statusCode === 422) {
                    let errors = error.message
                    Object.keys(errors).map(key => {
                        return (
                            toast.error(errors[key])
                        );
                    })
                } else {
                    toast.error(error.message)
                }
            })

    }
    handleItemChange = (limit) => {
        this.activePage = 1;
        this.itemsPerPage = limit['items'];
        this.getCompany();
    }
    render() {
        return (
            <div>
                <CompanyLocationPage
                    companyList={this.companyList}
                    sortBy={this.sortBy}
                    isDesc={this.isDesc}
                    column={this.column}
                    itemsPerPage={this.itemsPerPage}
                    count={this.count}
                    activePage={this.activePage}
                    handleStatusChange={this.handleStatusChange}
                    handleItemChange={this.handleItemChange}
                    pageItemsList={this.pageItemsList}
                    loading={this.loading}
                    handlePageChange={this.handlePageChange}
                    handleSearch={this.handleSearch}
                />
            </div>
        )
    }
}
export default CompanyLocation;