import { http } from "../_helpers";
import { config } from "../env";

const getFormData = () => {//apiUrlCompany
    return http.get(config.apiUrlCompany + "travel-settings")
        .then(data => {
            return data ? data : ""
        })
}

const getBrandDetail = () => {
    return http.get(config.apiUrlTravel + "airline/search?search=")
        .then(data => {
            return data ? data : ""
        })
}

const getCountriesList = () => {
    return http
        .get(config.apiUrlSuperAdmin + `countries`)
        .then(data => {
            return data ? data : "";
        });
}

const getCitiesList = (value) => {
    return http
        .get(config.apiUrlTravel + `city/search?city=${value}`)
        .then(data => {
            return data ? data : "";
        });
}

const createFormData = (companyId, data) => {
    return http.post(config.apiUrlCompany + `travel-settings`, data)
        .then(data => {
            return data ? data : ""
        })
}
const updateFormData = (id, data) => {
    return http.patch(config.apiUrlCompany + `travel-settings/${id}`, data)
        .then(data => {
            return data ? data : ""
        })
}
const removeItem = (id, data) => {
    return http.patch(config.apiUrlCompany + `travel-settings/remove/${id}`, data)
        .then(res => res)
}

function uploadImage(selectedImage) {
    return http.fileUpload(`${config.apiUrlTravel}uploadImage`, selectedImage)
        .then(res => res)
}

const getProfileDetails = () => {
    return http.get(config.apiUrlCompany + `company-users/details`)
        .then(data => {
            return data ? data : "";
        })
}

const addPassport = (data) => {
    return http.post(config.apiUrlCompany + `travel-settings/add-passport`, data)
        .then(data => {
            return data ? data : ""
        })
}

const getAllPolicies = (companyId,TRIP_ID) => {
    return http.get(config.apiUrlTravel + `travel-request/policies?companyId=${companyId}&TRIP_ID=${TRIP_ID}`)
        .then(data => {
            return data ? data : ""
        })
}

const getPoliciesForm = (companyId, policyId) => {
    return http.get(config.apiUrlTravel + `travel-request/policies?companyId=${companyId}&policyId=${policyId}`)
        .then(data => {
            return data ? data : ""
        })
}

export const myAccountTravelSettingsServise = {
    getFormData,
    createFormData,
    getBrandDetail,
    getCountriesList,
    getCitiesList,
    uploadImage,
    updateFormData,
    getProfileDetails,
    addPassport,
    getAllPolicies,
    getPoliciesForm,
    removeItem
};