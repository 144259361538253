import cryptr from "./crypty";
import { createLogger, createVerboseLogger } from "./logger/logger";

const authLogger = createLogger("authHeader");
const authVerboseLogger = createVerboseLogger("authHeader");

export function authHeader() {
  // return authorization header with jwt token
  let data = localStorage.getItem("440a24");
  authVerboseLogger("Local Storage Data: %s", data);
  if (data !== null) {
    var user = JSON.parse(cryptr.decrypt(data));
    authLogger("User: %O", user);
  }

  if (user && user.token) {
    return {
      "x-api-key": "G0OndZH93PuXhhZk",
      "content-type": "application/json",
      "url":window.location.pathname,
      Authorization: "Bearer " + user.token
    };
  } else {
    return {
      "x-api-key": "G0OndZH93PuXhhZk",
      "content-type": "application/json"
    };
  }
}
