import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import FlightBookingPage from "../home/flight-components/flight-booking-page";
import HotelPageBook from "../home/hotel-components/hotel-booking";
import { checkIsArray } from 'src/utils/util';
import CabBooking from "../cab/components/CabBooking";
import TrainBookingPage from "../home/train-components/train-booking";

class AddServicePage extends Component {
  componentDidMount() {
    const { type } = this.props;
    if (type === 'hotel' || type === 'cab' || type === 'train') {
      this.currentActive = "flight"
    } else if (type === 'oneway' || type === 'round' || type === 'multi') {
      this.currentActive = 'hotel'
    }
    this.forceUpdate()
  }
  getBookType = type => {
    this.currentActive = type;
    this.forceUpdate();
  };
  componentDidUpdate
  
  (){
    console.log(this.props)
  }
  currentActive = "";
  render() {
    const { type } = this.props;
    const pathName = this.props.location.pathname;
    let currentPageName = pathName.split('/');
    currentPageName = checkIsArray(currentPageName) && currentPageName.length > 3 ? currentPageName[2] : ''; 
    return (
      <div
        className="modal fade without-bg"
        id="addServiceModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addServiceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div>
              <div className="booking">
                <div className="booking-list modal-list">
                  <div
                    className="close "
                    data-dismiss="modal"
                    aria-label="Close"
                  >&times;</div>
                  {currentPageName !== 'flight' &&
                    <NavLink
                      to="#"
                      className={
                        this.currentActive === "flight" ? "active" : ""
                      }
                      onClick={e => this.getBookType("flight")}
                    >
                      <img
                        width="80"
                        height="80"
                        src="/assets/images/booking/flight_booking.svg"
                        alt="Flight"
                      />
                      <p>Flights</p>
                    </NavLink>
                  }
                  {currentPageName !== 'hotel' &&
                    <NavLink
                      to="#"
                      className={this.currentActive === "hotel" ? "active" : ""}
                      onClick={e => this.getBookType("hotel")}
                    >
                      <img
                        width="80"
                        height="80"
                        src="/assets/images/booking/hotel_booking.svg"
                        alt="Hotel"
                      />
                      <p>Hotels</p>
                    </NavLink>
                  }
                  <NavLink
                    to="#"
                    className={this.currentActive === "cab" ? "active" : ""}
                    onClick={e => this.getBookType("cab")}
                  >
                    <img
                      width="80"
                      height="80"
                      src="/assets/images/booking/car_booking.svg"
                      alt="Cab"
                    />
                    <p>Cab</p>
                  </NavLink>
                  {/* {currentPageName !== 'bus' &&
                    <NavLink
                      to="#"
                      className={this.currentActive === "bus" ? "active" : ""}
                    // onClick={e => this.getBookType("bus")}
                    >
                      <img
                        width="80"
                        height="80"
                        src="/assets/images/booking/bus_booking.svg"
                        alt="Bus"
                      />
                      <p>Bus</p>
                    </NavLink>
                  } */}
                  <NavLink
                    to="#"
                    className={this.currentActive === "train" ? "active" : ""}
                    onClick={e => this.getBookType("train")}
                  >
                    <img
                      width="80"
                      height="80"
                      src="/assets/images/booking/train_booking.svg"
                      alt="Train"
                    />
                    <p>Train</p>
                  </NavLink>
                  {/* {currentPageName !== 'visa' &&
                    <NavLink
                      to="/booking/visa"
                      className={this.currentActive === "visa" ? "active" : ""}
                      // onClick={e => this.getBookType("visa")}
                      data-dismiss="modal"
                    >
                      <img
                        width="80"
                        height="80"
                        src="/assets/images/booking/visa.svg"
                        alt="Visa"
                      />
                      <p>Visa</p>
                    </NavLink>
                  } */}
                </div>
              </div>
              {this.currentActive === "flight" && <FlightBookingPage />}
              {this.currentActive === "hotel" && <HotelPageBook />}
              {this.currentActive === "cab" && <CabBooking />}
              {this.currentActive === "train" && <TrainBookingPage renderType="AddService" />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(AddServicePage);
