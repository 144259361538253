import React, { Component } from "react";
import TravelDiscountPage from "./travel-discount-html";
import { travelDiscountServices } from "../../../../../_services";
import { toast } from "react-toastify";

class TravelDiscount extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      companyId: localStorage["440a28"],
      allTravelCompanyData: [],
      search: "",
      currentPage: 1,
      isLoading: false,
      configId: "",
      configName: "",
      selectedCompanyByIndex: "",
      totalCountItem: "",
      itemsPerPage: 10,
      selectedCompany: {},
      getAllCompanyGroups: [],
      selectedGroup: []
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getAllTravelCompanyData();
    this.getAllCompanyGroups();
  }

  // get All groups Based on Company
  getAllCompanyGroups = async () => {
    const response = await travelDiscountServices.getAllCompanyGroups(this.state.companyId);
    this.setState({
      getAllCompanyGroups : response ? response.result.map(item => ({
          label: item.itemName, 
          value: item.id
      })) : []
    })

  }

  // get selected groups by user
  OnGroupsSelectHandler = (selectedGroup) => {
    this.setState({
      selectedGroup: selectedGroup.map(e => (e.value))
    })
  }

  OnUpdateGroupsSelectHandler = (selectedGroup) => {
    this.setState({
      appliesToData: selectedGroup
    })

  }

  // Pagination getting current page
  OnPageChangeHandler = pageNumber => {
    pageNumber = pageNumber ? pageNumber : 1;
    this.setState({
       currentPage: pageNumber
        }, () => {
        this.getAllTravelCompanyData();
      });
  };


  // pagination getting Items limit per page
  OnPageLimitHandler = async itemsPerPage => {
    itemsPerPage = itemsPerPage ? itemsPerPage : 10;
    await this.setState({
        itemsPerPage: itemsPerPage
      },() => {
        this.getAllTravelCompanyData();
      });
  };

  // filter get All branch Data
  OnCompanySearchHandler = async event => {
    this.setState({
      search: event.target.value
    });
    if (this.state.search.length >= 0) {
      let timeOut;
      clearTimeout(timeOut);
      timeOut = setTimeout(async () => {
        const response = await travelDiscountServices.getFilterCompanyData(
          this.state.companyId,
          this.state.search
        );
        this.setState({
          allTravelCompanyData: response ? response.result : []
        });
      }, 250);
    } else {
      this.getAllTravelCompanyData();
    }};

  // Branch Status Active / Inactive handler
  OnStatusChangeHandler = async status => {
    const payload = {
      activeStatus: status.activeStatus
    };
    let res = await travelDiscountServices.activeInactiveStatus(status.id, payload);
    if (res) {
      toast.success('Status Updated Successfully');
      let allTravelCompanyData = [...this.state.allTravelCompanyData]; 
      // allTravelCompanyData.forEach(a =>
      //   (a === status) ? a.activeStatus = !status.activeStatus : status.activeStatus
      // );
      allTravelCompanyData[
        allTravelCompanyData.findIndex(item => item.id === status.id)
      ].activeStatus = !status.activeStatus;
      this.setState({ allTravelCompanyData });
    }
  };

  // get all branches data
  getAllTravelCompanyData = async () => {
    let limit = this.state.itemsPerPage;
    limit = !limit ? 10 : limit;
    this.setState({ isLoading: true });
    let res = await travelDiscountServices.getAllTravelDiscounts(
      this.state.companyId,
      this.state.currentPage,
      limit
    );
    this.setState({
      allTravelCompanyData: res ? res.result : [],
      isLoading: false,
      totalCountItem: res ? res.count : "",
    });
  };

  // New branch value Handler and set to state
  OnCompanyCreateHandler = event => {
    this.setState({
      selectedCompany: {
        ...this.state.selectedCompany,
        [event.target.name]: event.target.value,
      }
    })
  };


  // create new branch Id
  OnSubmitHandler = async () => {
    let payload = {
      companyId: this.state.companyId,
      configName: this.state.selectedCompany.configName,
      configId: this.state.selectedCompany.configId,
      appliesTo: this.state.selectedGroup
    };
    let emptyTravelBranchFieldsMsg = {
      configName:"Config Name can not be empty or blanked",
      configId:" Config Id can not be empty or blanked"
    }
    let createTravelBranchField=async ()=>{
      const res = await travelDiscountServices.createNewCompany(payload);
      if (res) {
        toast.success(res.message);
        this.getAllTravelCompanyData();
      }
    }
    return payload.configName? payload.configId? await createTravelBranchField(): toast.warn(emptyTravelBranchFieldsMsg.configId):toast.warn(emptyTravelBranchFieldsMsg.configName)

  };
  // update company name or id
  OnUpdateHandler = async () => {
    const companyId = this.state.selectedCompany.id
    let payload = {
      configName: this.state.selectedCompany.configName,
      configId: this.state.selectedCompany.configId,
      appliesTo: this.state.appliesToData.map(item => (item.value))
    }
    const res = await travelDiscountServices.updateCompanyId(companyId , payload);
    if (res) {
      toast.success(res.message);
      this.getAllTravelCompanyData();
    }
  }

  // branch select and deselect handler
  OnCompanySelectHandler = (branchId , branch) => {
    let selectedCompanyByIndex = this.state.selectedCompanyByIndex;
    if (selectedCompanyByIndex === branchId)
      this.setState({ selectedCompanyByIndex: "", selectedCompany: branch });
    else this.setState({ selectedCompanyByIndex: branchId, selectedCompany: branch });
    this.setState({
        appliesToData : branch.appliesToData.map(item => ({ 
          label: item.itemName, 
          value: item.id}))
      })
   };

  // Detele selected Branch
  OnCompanyDeleteHandler = async configId => {
    const res = await travelDiscountServices.deleteSelectedBranch(configId);
    if (res) {
      toast.success("Deleted Successfully");
      this.setState({
        selectedCompanyByIndex: ""
      });
      this.getAllTravelCompanyData(); 
    } else {
      // console.log(res);
    }
  };

  // will create copy of selected branch
  OnCopyDownClickHandler = async (configId) => {
    const payload = {
      "id": configId
    }
    const res = await travelDiscountServices.createCopyOfSelectedBranch(payload);
    if (res) {
      toast.success("Created Successfully");
      this.setState({
        selectedCompanyByIndex: ""
      });
      this.getAllTravelCompanyData();
    } else {
      // console.log(res);
    }
  }

  render() {
    return (
      <div>
        <TravelDiscountPage
          {...this.state}
          OnCompanySearchHandler={this.OnCompanySearchHandler}
          OnPageChangeHandler={this.OnPageChangeHandler}
          OnStatusChangeHandler={this.OnStatusChangeHandler}
          OnCompanyCreateHandler={this.OnCompanyCreateHandler}
          OnSubmitHandler={this.OnSubmitHandler}
          OnCompanyDeleteHandler={this.OnCompanyDeleteHandler}
          OnCompanySelectHandler={this.OnCompanySelectHandler}
          OnPageLimitHandler={this.OnPageLimitHandler}
          OnCopyDownClickHandler={this.OnCopyDownClickHandler}
          OnUpdateHandler={this.OnUpdateHandler}
          OnGroupsSelectHandler={this.OnGroupsSelectHandler}
          OnUpdateGroupsSelectHandler={this.OnUpdateGroupsSelectHandler}
          
        />
      </div>
    );
  }
}
export default TravelDiscount;
