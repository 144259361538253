import React from "react";
import AllocationDynamicForm from './allocationDynamicForm';;
const EditAllocationTable = props => {
  let tableHead = [];
  tableHead = props.tableHead && props.tableHead.filter((form) => form.activeStatus == true);
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th width="5%"></th>
          <th>
          </th>
        </tr>
      </thead>
      <tbody>
        {props.editAllocationForm && props.editAllocationForm.map((forms, index) => {
          return (
            <tr key={index}>
              <td>
                <label className="checkbox-container">
                  <input type="checkbox"
                    checked={props.selectedIndex.includes(index) ? props.selectedIndex.includes(index) : false}
                    onChange={(e) => props.onEditSelectedAllocationFieldHandler(index, props.allocationEditIds[index])}
                  />
                  <span className="checkmark" />
                </label>
              </td>
              <td>
                <div className="row">
                  <div className="col-md-4 my-3">
                    <div className="form-group custom mb-0">
                      <input
                        value={props['allocamount' + index] ? props['allocamount' + index] : ''}
                        onChange={(e) => props.onAmountPercentageChangeHandler(e, 'allocamount' + index, index)}
                        type="text" placeholder="&nbsp;" className="form-control" />
                      <label>{props.allocatedIn ? props.allocatedIn : ''}</label>
                      <div className="help-block">
                        {props.validator.message(
                          'alloctated in',
                          props['allocamount' + index],
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                  <AllocationDynamicForm {...props}
                    validator={props.validator}
                    onClickHandler={props.onClickHandler}
                    onChangeHandler={props.onChangeHandler}
                    onDatePickerHandler={props.onDatePickerHandler}
                    onCheckboxHandler={props.onCheckboxHandler}
                    onIntegerHandler={props.onIntegerHandler}
                    allocationForm={forms}
                    rowIndex={index}
                    handleScroll={props.handleScroll}
                    handleSearchManage={props.handleSearchManage}
                  />
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>

    </table>
  );
};
export default EditAllocationTable;
