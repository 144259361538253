import React from 'react';
import { NavLink } from "react-router-dom";
import DayPicker, { DateUtils } from "react-day-picker";
import Pagination from "react-js-pagination";
const TravelBudget2Page = (props) => {
    let data = props;
    return (
        <div>
            <div className="inner-header">
                <div className="shape-header">
                    <div className="left">W</div>
                    <div className="right">
                        <h6 className="heading">Travel Budget</h6>
                        <p className="text">Lorem Ipsum is simply dummy</p>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="p-padding pb-0">
                    <div className="row">
                        <div className="col-md-2">
                            <h6 className="text-uppercase font-weight-bold">Financial Year</h6>
                            <p>2019-2020</p>
                        </div>
                        <div className="col-md-2">
                            <h6 className="text-uppercase font-weight-bold">Budget Type</h6>
                            <p>Monthly</p>
                        </div>
                        <div className="col-md-3 offset-4 ">
                            <h6 className="text-uppercase font-weight-bold">Total travel budget</h6>
                            <p>USD 120.00</p>
                        </div>
                        <div className="col-md-1 text-right">
                            <button type="button" className="btn" data-toggle="modal" data-target="#AddTemplateModal">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-padding">
                <div className="table-container">
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="btn-group-margin">
                                    <NavLink to="/administration/budget/travel-budget3" className="btn">Edit Amount</NavLink>
                                </div>
                            </div>
                            <div className="col-md-2 offset-4">
                                <div className="search-custom">
                                    <input type="text" name="" className="form-control" placeholder="Search" />
                                    <span><i className="material-icons">search</i></span>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Month Name</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Flight</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>USD 10,000.00</td>
                                </tr>
                                <tr>
                                    <td>Hotel</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>USD 10,000.00</td>
                                </tr>
                                <tr>
                                    <td>cab</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>USD 10,000.00</td>
                                </tr>
                                <tr>
                                    <td>Flight</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>USD 10,000.00</td>
                                </tr>
                                <tr>
                                    <td>Flight</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>USD 10,000.00</td>
                                </tr>
                                <tr>
                                    <td>Flight</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>USD 10,000.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-footer">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="page-count">
                                    <div className="text">Items</div>
                                    <div className="count">
                                        <div className="dropdown">
                                            <button type="button" className="btn dropdown-toggle"
                                                data-toggle="dropdown">
                                                10
                                            </button>
                                            <div className="dropdown-menu">
                                                <button className="dropdown-item">10</button>
                                                <button className="dropdown-item">20</button>
                                                <button className="dropdown-item">30</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <Pagination
                                    activePage={data.active}
                                    itemsCountPerPage={10}
                                    totalItemsCount={10}
                                    pageRangeDisplayed={3}
                                    onChange={data.handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="AddTemplateModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="AddTemplateModalLabel"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-md"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="AddTemplateModalLabel">
                                Edit Budget settings </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form>
                            <div className="modal-body">
                                <div className="form-group custom labelup">
                                    <label>Financial Year start</label>
                                    <div className="popup date-picker">
                                        <DayPicker />
                                    </div>
                                </div>
                                <div className="form-group custom labelup">
                                    <label>Financial Year End</label>
                                    <div className="popup date-picker">
                                        <DayPicker />
                                    </div>
                                </div>
                                <div className="form-group custom labelup">
                                    <label>Budget Type</label>
                                    <div className="dropdown">
                                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                            Monthly
                                        </button>
                                        <div className="dropdown-menu">
                                            <button className="dropdown-item">Save</button>
                                            <button className="dropdown-item">Lorem Ipsum</button>
                                            <button className="dropdown-item">Lorem Ipsum</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group custom labelup">
                                    <label>Currency</label>
                                    <div className="dropdown">
                                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                            Monthly
                                        </button>
                                        <div className="dropdown-menu">
                                            <button className="dropdown-item">Save</button>
                                            <button className="dropdown-item">Lorem Ipsum</button>
                                            <button className="dropdown-item">Lorem Ipsum</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group custom labelup">
                                    <label>Amount</label>
                                    <div className="dropdown">
                                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                            Monthly
                                        </button>
                                        <div className="dropdown-menu">
                                            <button className="dropdown-item">Save</button>
                                            <button className="dropdown-item">Lorem Ipsum</button>
                                            <button className="dropdown-item">Lorem Ipsum</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="submit"
                                    className="btn btn-border-success lg-padding"
                                >Save
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-border-basic lg-padding"
                                    data-dismiss="modal"
                                >
                                    Cancel
                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TravelBudget2Page;