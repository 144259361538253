import React, { Component } from 'react';
import { toast } from 'react-toastify';
import $ from 'jquery';
import SimpleReactValidator from "simple-react-validator";
import CustomStep3Page  from './custom-step3-html';
import { auditRuleService } from "../../../../../../_services/auditRule.service";

class CustomStep3 extends Component {
    constructor(props){
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.validator = new SimpleReactValidator();
        this.state={
            exceptionCode:'',
            exceptionLevel:'',
            message:'',
            search:'',
            auditRule_id:'',
            exceptionVisbility:'',
            auditRuleMessage:'',
            isLoading:false,
            isEdit:false,
            isValidated:false,
            pageNumber:1,
            limit:10,
            gotoNextMenu:true,
            totalCount:0,
            selectedExpense:[],
            getAllException:[],
            editedByList:[],
            editedBy:[],
            exceptionFilter:[],
            companyId: localStorage["440a28"],
            exeptionVisibility:[
                {value: 1, label: 'Expense Processor'},
                {value: 2, label: 'Approver and Expense Processor'},
                {value: 3, label: 'Traveler, Approver, and Expense Processor'}
            ]
        }
    }

    componentDidMount(){
        this.getAllExeptions();
        this.getEditedAndAppliesto();
        let auditRuleEditId = this.props.match.params.id;
        if(auditRuleEditId === undefined || auditRuleEditId === null || auditRuleEditId ===""){
            this.props.history.push('../custom');
        }
        if(this.props.match.params.id !==undefined && this.props.match.params.id !=="" && this.props.match.params.id !==null){
            this.setState({auditRule_id:this.props.match.params.id});
            this.getEditAuditRules(this.props.match.params.id);
          }
    }
    
    getEditAuditRules = async(auditRule_id)=>{
        let exeptionVisibility=this.state.exeptionVisibility;
        this.setState({isLoading:true});
         let companyId = this.state.companyId;
          let response = await auditRuleService.getAuditRuleByid(companyId,auditRule_id);
          if(response && response.result && response.result.exceptionId){
            let exceptionVisbility = exeptionVisibility.find((exception)=>exception.value===response.result.excptionVisibility)
            this.setState({auditRuleMessage:response.result.exceptionId.message,exceptionVisbility:exceptionVisbility.label});
          }else{
            this.setState({isLoading:false});
          }
      }
    getEditedAndAppliesto=async()=>{
        let response = await auditRuleService.getGroupType(this.state.companyId);
        if(response && response.result && response.result.length>0){
            let editedByList = [];
            response.result.forEach((list)=>{
                editedByList.push({label:list.itemName,value:list.id});
            })
            this.setState({editedByList:editedByList});
        }
    }

    getAllExeptions = async()=>{
        this.setState({isLoading:true});
        let companyId = this.state.companyId;
        let limit = this.state.limit;
        let pageNumber = this.state.pageNumber;
        let response = await auditRuleService.getAllException(companyId,limit,pageNumber);
        if(response.count>0){
            this.setState({
                totalCount:response.count,
                getAllException:response.result,
                isLoading:false
            })
        }else{
            this.setState({getAllException:[],totalCount:0,pageNumber:1,isLoading:false});
        }
    }

    onExceptionCodeChangeHandler=(event)=>{
        if (!isNaN(event.target.value)) {
            this.setState({exceptionCode:(event.target.value)});
        }
    }
    onExceptionLevelChangeHandler=(event)=>{
        if (!isNaN(event.target.value)) {
            this.setState({exceptionLevel:+(event.target.value)});
        }
    }

    onEditableChangeHandler = (value)=>{
        this.setState({editedBy:value});
    }

    onChangeMessageHandler = (event)=>{
        this.setState({message:event.target.value});
    }
    onSubmitExceptionHandler = async() =>{
        if (this.validator.allValid()) {
            this.setState({isValidated:true});
            let data = {
                companyId: this.state.companyId,
                exceptionCode: this.state.exceptionCode,
                exceptionLevel: this.state.exceptionLevel,
                message: this.state.message,
                editableBy: this.state.editedBy.map((list)=>list.value)
            }
            let response = await auditRuleService.createException(data);
            if(response){
                $(".close").click();
                toast.success(response.message);
                this.setState({
                    exceptionCode: "",
                    exceptionLevel: "",
                    message: "",
                    editedBy: []
                });
                this.getAllExeptions();
            }
        }else{
           await this.setState({isValidated:false});
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    onUpdateExceptionHandler = async() =>{
        if (this.validator.allValid()) {
            this.setState({isValidated:true});
            let selectedExpense = [...this.state.selectedExpense];
            let data = {
                id:selectedExpense[0],
                companyId: this.state.companyId,
                exceptionCode: this.state.exceptionCode,
                exceptionLevel: this.state.exceptionLevel,
                message: this.state.message,
                editableBy: this.state.editedBy.map((list)=>list.value)
            }
            let response = await auditRuleService.updateException(data);
            if(response){
                $(".close").click();
                toast.success(response.message);
                this.setState({
                    exceptionCode: "",
                    exceptionLevel: "",
                    message: "",
                    isEdit:false,
                    editedBy: [],
                    selectedExpense:[]
                });
                this.getAllExeptions();
            }
        }else{
            this.setState({isValidated:false});
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    onSelectExceptionHandler=(value)=>{
        let selectedExpense = [...this.state.selectedExpense];
        if(selectedExpense.includes(value)){
            selectedExpense=selectedExpense.filter((list)=>list!==value);
        }else{
            selectedExpense.push(value);
        }
        this.setState({selectedExpense:selectedExpense})
    }

    onEditClickHandler =async(event)=>{
        event.preventDefault();
        let selectedExpense = [...this.state.selectedExpense];
        let response = await auditRuleService.editException(this.state.companyId,selectedExpense[0]);
        if(response){
            this.setState({
                isEdit:true,
                exceptionCode: response.result.exceptionCode,
                exceptionLevel: response.result.exceptionLevel,
                message: response.result.message,
                editedBy: response.result.editableObj.result.map((list)=>{return{label:list.itemName,value:list.id}})
            });
        }
    }
    onDeleteExceptionHandler=async()=>{
        let selectedExpense = [...this.state.selectedExpense];
        let data = {
            deleteStatus:true,
            id:selectedExpense
        }
        let response = await auditRuleService.deleteException(this.state.companyId,data);
        if(response){
            this.setState({selectedExpense:[]});
            toast.error(response.message);
            this.getAllExeptions();
        }
    }
    onPageChangeHandler = async(pageNumber)=>{
        await this.setState({pageNumber:pageNumber});
        this.getAllExeptions();
    }

    onLimitChangeHandler = async(limit)=>{
        await this.setState({limit:limit,pageNumber:1});
        this.getAllExeptions();
    }

    onExceptionChangeFilterHandler = (exceptionFilter)=>{
        this.setState({exceptionFilter:exceptionFilter});
    }

    onSearchTextChangeHandler = async(event)=>{
       let search = event.target.value;
       await this.setState({search:event.target.value});
       if(search!==''){
        let response = await auditRuleService.exceptionFilter(this.state.companyId,search);
        if(response.count>0){
            this.setState({
                totalCount:response.count,
                getAllException:response.result
            })
        }else{
            this.setState({getAllException:[],totalCount:0,pageNumber:1});
        }
       }else{
        this.getAllExeptions();
       }
       
    }

    onSubmitExeptionHandler = async()=>{
      let selectedExpense = [...this.state.selectedExpense];
      let  data ={
            id:this.state.auditRule_id,
            companyId:this.state.companyId,
            exceptionId:selectedExpense[0],
            excptionVisibility:parseInt(this.state.exceptionFilter.value)
        }
      let response = await auditRuleService.updateAuditRule(data);
      if(response){
          toast.success(response.message);
          this.props.history.push('../custom');
      }
    }
onClickNewHandler=()=>{
    this.setState({
        exceptionCode: "",
        exceptionLevel: "",
        message: "",
        isEdit:false,
        isValidated:true,
        editedBy: [],
        selectedExpense:[]
    });
}
    render() {
        return(
            <div>
                <CustomStep3Page
                    {...this.state}
                    validator={this.validator}
                    onExceptionCodeChangeHandler={this.onExceptionCodeChangeHandler}
                    onExceptionLevelChangeHandler={this.onExceptionLevelChangeHandler}
                    onEditableChangeHandler={this.onEditableChangeHandler}
                    onChangeMessageHandler={this.onChangeMessageHandler}
                    onSubmitExceptionHandler={this.onSubmitExceptionHandler}
                    onSelectExceptionHandler={this.onSelectExceptionHandler}
                    onEditClickHandler={this.onEditClickHandler}
                    onUpdateExceptionHandler={this.onUpdateExceptionHandler}
                    onDeleteExceptionHandler={this.onDeleteExceptionHandler}
                    onPageChangeHandler={this.onPageChangeHandler}
                    onLimitChangeHandler={this.onLimitChangeHandler}
                    onExceptionChangeFilterHandler={this.onExceptionChangeFilterHandler}
                    onSearchTextChangeHandler={this.onSearchTextChangeHandler}
                    onStatusChangeHandler={this.onStatusChangeHandler}
                    onSubmitExeptionHandler={this.onSubmitExeptionHandler}
                    onClickNewHandler={this.onClickNewHandler}
                    gotoNextMenu={this.state.gotoNextMenu}
                />
            </div>
        )
    }
}
export default CustomStep3;