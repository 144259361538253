import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from '../../routes';
// import LeftMenu from '../common/left-menu';
import LeftMenu from '../common/left-menu-old';
import Header from '../common/header';
import { history } from '../../_helpers';
import SendFeedback from '../common/send-feedback';

class HomePage extends Component {
    componentDidMount() {

        history.listen((location, action) => {

        });
    }

    openRespMenu() {
        document.getElementById("sidebar").className += " is--extended";
    }
    render() {
        return (
            <div className="holder">
                <Router>
                    <div className="container-fluid p-0 holder-inner">
                        <LeftMenu />
                        <div className="wrapper">
                            <Header
                                openRespMenu={e => this.openRespMenu()}
                            />
                            <Switch>
                                {routes.map((route, key) => {
                                    return route.component ? (
                                        <Route key={key} path={route.path}
                                            exact={route.exact} name={route.name}
                                            children={route.children}
                                            render={props => (
                                                <route.component {...props} />
                                            )} />) : (null);
                                },
                                )}
                            </Switch>
                            {/* <ReceiverChatApp /> */}
                        </div>
                    </div>
                </Router>
                <SendFeedback />

            </div>
        )
    }
}
export default HomePage;
