import React from 'react'

export default function AddTemplateModal() {
  return (
    <div>
      <div
        className="modal fade"
        id="AddTemplateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="ChangeApproverLabel">
                Edit Action
                  <button
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </h5>
            </div>
            <div className="modal-middle pm-30">
              <div className="form-group custom labelup">
                <label>Action Name</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    Skip Step
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item" data-dismiss="modal" data-toggle="modal" data-target="#ChangeApproverModal">Change Approver</button>
                    <button type="button" className="dropdown-item" data-dismiss="modal" data-toggle="modal" data-target="#GenerateExceptionModal">Generate Exception</button>
                    <button className="dropdown-item disabled" data-dismiss="modal">Reject (cash advance workflows only)</button>
                    <button className="dropdown-item" data-dismiss="modal" data-toggle="modal" data-target="#SendBacktoEmployeeModal">Send Back to Employee</button>
                    <button className="dropdown-item" data-dismiss="modal" data-toggle="modal" data-target="#SendEmailModal">Send Email</button>
                    <button className="dropdown-item" data-dismiss="modal" data-toggle="modal" data-target="#AddTemplateModal">Skip Step</button>
                  </div>
                </div>
              </div>
              <div className="form-group custom textarea">
                <textarea
                  rows="3"
                  data-gramm_editor="false"
                      spellCheck="false"
                  className="form-control"
                  placeholder="&nbsp;"
                />
                <label>Message</label>
              </div>
              <div className=" modal-last btn-group-margin">
                <button
                  type="button"
                  className="btn btn-border-success"
                >
                  Save
              </button>
                <button
                  type="button"
                  className="btn btn-border-basic close"
                  data-dismiss="modal"
                >
                  Cancel
              </button>
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
  )
}
