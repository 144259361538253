import { config } from "../env";
import { http } from "../_helpers";

//------------list form headers------------------

const getFormHeader = serviceModuleId => {
  return http
    .get(config.apiUrlCompany + `form-headers?serviceModuleId=${serviceModuleId}`)
    .then(data => data);
};


// ----------------------------------------------

// ------ CopyDown ----------------//

const getFormHeaderByModify = () => {
  return http
  .get(config.apiUrlCompany + `form-headers`)
  .then(data => data);
}

const getCopyDownFields = (fieldId, companyId, formHeaderId) => {
  return http
  .get(config.apiUrlCompany + `company-fields/get-copy-down-fields?fieldId=${fieldId}&companyId=${companyId}&formHeaderId=${formHeaderId}`)
  .then(data => data);
}

// -------list form headers with service module-----

const getFormHeadersbyServices = id => {
  return http
    .get(config.apiUrlCompany + `form-headers/?serviceModuleId=${id}`)
    .then(data => data);
};

// -------------------------------------------------

//----------------create form Headers--------------

const createFormHeader = data => {
  return http.post(config.apiUrlCompany + `forms`, data).then(data => data);
};
// ------------------------------------------------

// ------------list all service module -------------

const getServiceModule = () => {
  return http.get(config.apiUrlCompany + `service-modules`).then(data => data);
};

// -------------------------------------------------

//----------------- create service Modules--------------

const createServiceModule = data => {
  return http.post(config.apiUrlCompany + `form-headers`, data).then(data => data);
};

// -----------------------------------------FORMS--------------------------------------

// ------------------------Create Forms--------------------------------

const createForms = data => {
  return http.post(config.apiUrlCompany + `forms`, data).then(data => data);
};

//  list forms with paginaion ,limit, servicetype

const listFormsPLSF = (
  page,
  limit,
  serviceModuleId,
  formHeaderId,
  companyId
) => {
  return http
    .get(
      config.apiUrlCompany +
        `forms?page=${page}&limit=${limit}&serviceModuleId=${serviceModuleId}&formHeaderId=${formHeaderId}&companyId=${companyId}`
    )
    .then(data => data);
};

//  list forms with paginaion ,limit

const listFormsPL = (page, limit) => {
  return http
    .get(config.apiUrlCompany + `forms?page=${page}&limit=${limit}`)
    .then(data => data);
};

//  list forms with paginaion

const listFormsAll = page => {
  return http.get(config.apiUrlCompany + `forms?page=${page}`).then(data => data);
};

// * Create duplicate forms
const duplicateForm = (payload) => {
  return http.post(`${config.apiUrlCompany}company-forms/duplicate-form`, payload).then(data => data);
}

const updateGroups = (id, payload) => {

  return http.patch(`${config.apiUrlCompany}company-forms/${id}/update-group`,payload).then(data => data);
}

// -----------------------------------------FORMS ENDS--------------------------------------

//  -----------------------------------FORMS and FIELDS ---------------------------------------------

const getFormFields = data => {
  return http
    .post(config.apiUrlCompany + `company-fields/list-all`, data)
    .then(data => data);
};

const getMappedFields = data => {
  return http
    .post(config.apiUrlCompany + `company-fields/list-all`, data)
    .then(data => data);
};
const mapFieldsToForm = data => {
  return http.post(config.apiUrlCompany + `company-fields/add-form-fields`, data).then(data => data);
};

const createFields = data => {
  return http.post(config.apiUrlCompany + `fields`, data).then(data => data);
};

const deleteFields = (id, data) => {
  return http
    .patch(config.apiUrlCompany + `company-fields/${id}/delete`, data)
    .then(data => data);
};

const modifyFields = (id, data) => {
  return http.patch(config.apiUrlCompany + `company-fields/${id}/update`, data);
};
const getFieldsDetails = (id, companyId) => {
  return http
    .get(config.apiUrlCompany + `company-fields/${id}/${companyId}/details`)
    .then(data => data);
};
// change form status
const changeFormStatus = (formId, payload) => {

  return http
    .patch(config.apiUrlCompany + `company-forms/${formId}/active-status`, payload)
    .then(data => data);
};
// edit form name
const editFormDetail = (formId, data) => {
  return http.patch(config.apiUrlCompany + `forms/${formId}`, data).then(data => data);
};
// change field status
const changeFieldStatus = (formId, status) => {
  return http
    .patch(config.apiUrlCompany + `company-fields/${formId}/active-status`, status)
    .then(data => data);
};

const getRoles = () => {
  return http.get(config.apiUrlCompany + `roles`).then(data => data);
};

const customFieldsAre = data => {
  return http
    .post(config.apiUrlCompany + `company-fields/list-all`, data)
    .then(data => data);
};
// ---------------------------------------------------------------------------------------

// ------------------------- Connected List ---------------------------------

const createConnectedList = data => {
  return http
    .post(config.apiUrlCompany + "company-connected-lists/add", data)
    .then(data => {
      return data;
    });
};
const getConnectedList = (
  page,
  limit,
  serviceModuleId,
  formHeaderId,
  companyId
) => {
  return http
    .get(
      config.apiUrlCompany +
        `company-connected-lists?page=${page}&limit=${limit}&serviceModuleId=${serviceModuleId}&formHeaderId=${formHeaderId}&companyId=${companyId}`
    )
    .then(data => data);
};

const connectedListStatusChange = (id, newStatus) => {
  return http
    .patch(config.apiUrlCompany + `company-connected-lists/${id}/activate-status`,newStatus)
    .then(data => data);
};

const listManagement = id => {
  return http
    .get(config.apiUrlCompany + `list-management?companyId=${id}`)
    .then(data => data);
};

const getListManagement = company => {
  return http
    .get(config.apiUrlCompany + `list-management?companyId=${company}`)
    .then(data => data);
};

const customFields = data => {
  return http
    .post(config.apiUrlCompany + `company-fields/list-all`, data)
    .then(data => data);
};

const getChildrens = (parentId, companyId) => {
  return http
    .get(
      config.apiUrlCompany + `get-list-parent?id=${parentId}&companyId=${companyId}`
    )
    .then(data => data);
};
// ---------------------------------------------------------------------------------------

// Sequence Order Functions
const updateSequenceOrder = (payload) => {
  return http.put(`${config.apiUrlCompany}update-field-sequence`, payload).then(data => data);
}

// multi-select

const getChildrenMultilevel = (companyId ,parentId) => {
  return http.get(
    config.apiUrlCompany+`/list-management?companyId=${companyId}&parentId=${parentId}`
  )
  .then(data=>data);
};

export const companyAdmin = {
  createFields,
  getFormHeader,
  createFormHeader,
  getServiceModule,
  createForms,
  duplicateForm,
  updateGroups,
  listFormsAll,
  listFormsPL,
  listFormsPLSF,
  createServiceModule,
  getFormHeadersbyServices,
  getFormFields,
  changeFormStatus,
  editFormDetail,
  changeFieldStatus,
  deleteFields,
  modifyFields,
  getFieldsDetails,
  getRoles,

  getFormHeaderByModify,
  getCopyDownFields,

  // forms and fields
  getMappedFields,
  mapFieldsToForm,
  customFieldsAre,

  // connected List
  createConnectedList,
  getConnectedList,
  connectedListStatusChange,
  listManagement,
  getListManagement,
  customFields,
  getChildrens,
  //multi-select
  getChildrenMultilevel,

  // sequence Order stuff
  updateSequenceOrder
};
