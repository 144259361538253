import React from 'react';

const deleteExpenseEntryModal =(props)=>{
    return(      
    <div
        className="modal fade"
        id="deleteEntry"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="submitModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close text-white d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="text-center pt-5">
                <img src="/assets/images/confirmation_icon.svg" alt="" />
                <h3 className=" mt-4 text-dark">Are You Sure?</h3>
                <p>By clicking on delete, you are removing this request.</p>
              </div>
              <div className="modal-footer text-center">
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  No
              </button>
                <button
                  onClick={props.onDeleteExpenseEntry}
                  className="btn"
                >
                  Delete
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>)
}

export default deleteExpenseEntryModal;