import React, { Component } from 'react';
import { expenseServices } from 'src/_services';

export default class TreeChildComponent extends Component {

  state = {
    renderedChildren: [],
    isOpen: this.props.open || false
  }



  getChildren = async () => {
    try {
      let res = await expenseServices.getParentDetailById(this.props.parentId);
      // console.log(res);
      if (res != null) {
        return res.children
      }
    } catch (err) {
      return []
    }
  }

  componentDidMount = async () => {
    try {
      let children = await this.getChildren();
      this.setState({
        renderedChildren: children
      })

    } catch (err) {
      this.setState({
        renderedChildren: []
      })
    }
  }

  toggleCollapse = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
  }

  render() {

    return (

      <ul id={this.props.toggleId} className="collapse">
        {this.state.renderedChildren && this.state.renderedChildren.map(child => {
          let toggleId = `GROUPID-${child.id}`;
          return (
            <li key={child.id}>
              {(child.isFolder) ? <button className="btn btn-nothing collapsed" data-toggle="collapse" data-target={`#${toggleId}`} onClick={this.toggleCollapse}>
              </button> : ""}
              <span onClick={e => this.props.chooseGroup(child)} >
                {(child.isFolder) ?
                  <img className="mr-3" src="/assets/images/folder.svg" alt="" height="20" />
                  : <img className="mr-3" src="/assets/images/item.svg" alt="" height="20" />}
                <span className="text">{child.itemName}</span>
              </span>
              {
                child.isFolder ? (<TreeChildComponent chooseGroup={this.props.chooseGroup} toggleId={toggleId} open={this.state.isOpen} parentId={child.id} />) : (null)
              }
            </li>

          )
        })}
      </ul>
    )

  }
}
