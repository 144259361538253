import React from 'react';

export default function RuleSaveClose(props) {
  return (
    <>{!props.isSubmitClick?
    <div className="btn-group-margin mt-4">
      <button
          className="btn btn-border-success lg-padding"
          onClick={(e)=>props.updateRuleHandler(e)}
          disabled={props.isLoading}
        >
        Save
      </button>
      <button className="btn btn-border-basic lg-padding" onClick={props.onCancelClickHanler} >
        Close
      </button>
    </div>
    :<div>
        <button
          className="btn btn-border-success lg-padding"
        >
        Please Wait...
      </button>
    </div>
    }
    </>
  )
}
