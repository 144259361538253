import { decorate, observable, configure, action, computed } from "mobx"
class ErrorStore {
  error = [];

  clearList() {
    this.error = []
  }
  getError(e) {
    this.error = e 
  }
  get errMap() {     
    return this.error
  }
  
}
decorate(ErrorStore, {
  error: observable,
  clearList: action,
  getError: action,
  errMap: computed
})

const errorStore = new ErrorStore()
export default errorStore;