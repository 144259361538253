import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { reportService } from "../../../_services/report.service";
import moment from "moment";
import { toast } from "react-toastify";
//import { alertConstants } from "../../_constants/alert.constants";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";

class ReportsPage extends Component {
  constructor(props) {
    super(props);

    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      companyId: localStorage.getItem("440a28"),
      allReports: [],
      radioOption: "WEEKLY",
      emailRecipient: "",
      multiEmail: [],
      dateRemind: "",
      isScheduled: false,
      reportId: "",
//pagination
      totalItemsCount: 0,
      limit: 10,
      activePage: 1,
      itemPerPage: 10,
    };
  }
  validator = new SimpleReactValidator();

  componentDidMount() {
    this.getAllReports();
  }

  getAllReports = async () => {
    let resp = await reportService.getAllReports(
      this.state.limit,
      this.state.activePage,
      this.state.companyId
    );
    if (resp) {
      this.setState({
        allReports: resp.data,
        totalItemsCount: resp.count
      });
    }else{
      this.setState({
        allReports: [],
        totalItemsCount: 0
      });
    }
  };

  handlePageChange = (pageNumber) =>{
    this.setState({ activePage: pageNumber }, () => {
      this.getAllReports();
    });
  }


  downloadAction = async (e, id, reportType) => {
    e.preventDefault();
    // let resp = await reportService.getSingleReport(id);
    // if (resp) {
    //   window.location.href = resp.link;
    // }
    if (reportType === "EXPENSE") {
      let resp = await reportService.getSingleExpenseReport(id);
      if (resp) {
        window.location.href = resp.link;
      }
    }
    if (reportType === "TRAVEL") {
      let resp = await reportService.getSingleTravelReport(id);
      if (resp) {
        window.location.href = resp.link;
      }
    }
    if (reportType === "CASH_ADVANCE") {
      let resp = await reportService.getSingleCashAdvanceReport(id);
      if (resp) {
        window.location.href = resp.link;
      }
    }
  };

  setScheduleReports = (e, id) => {
    e.preventDefault();
    this.setState({
      reportId: id
    });
  };

  radioHandler = e => {
    this.setState({
      radioOption: e.target.value
    });
  };
  emailHandler = e => {
    this.setState({
      emailRecipient: e.target.value
    });
  };
  multiEmailHandler = e => {
    this.setState({
      multiEmail: [e.target.value]
    });
  };
  DateHandler = e => {
    this.setState({
      dateRemind: e
    });
  };

  changeHandler = async (e, id) => {
    if (e.target.checked) {
      let payload = {
        isScheduled: true
      };
      let resp = await reportService.scheduleReport(id, payload);
      if (resp) {
        $(".close").click();
        toast.success("Report Scheduled Successfully");
        this.getAllReports();
      }
    } else {
      let payload = {
        isScheduled: false
      };
      let resp = await reportService.scheduleReport(id, payload);
      if (resp) {
        $(".close").click();
        toast.warning("Report Scheduling Cancelled");
        this.getAllReports();
      }
    }
  };

  sendScheduleReport = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let payload = {
        olderThan: moment(this.state.dateRemind).format("YYYY-MM-DD"),
        frequencyType: this.state.radioOption,
        emailTo: this.state.emailRecipient,
        emailReceiptients: this.state.multiEmail
      };
      let resp = await reportService.scheduleReport(
        this.state.reportId,
        payload
      );
      if (resp) {
        $(".close").click();
        toast.success("Report Scheduled Successfully");
        this.getAllReports();
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  };
  render() {
    return (
      <div>
        <div className="page-content">
          <div className="inner-header">
            <div className="shape-header">
              <div className="left">E</div>
              <div className="right">
                <h6 className="heading">Reports</h6>
                <p className="text">
                  The User Management feature allows administrators to add and
                  manage employees.
              </p>
              </div>
            </div>
          </div>
          <div className="py-padding">
            <div className="table-container">
              <form>
                <div className="row">
                  <div className="col-md-9">
                    <div className="btn-group-margin">
                      <NavLink
                        to="/reports/new-report"
                        className="btn"
                      >
                        New Report
                      </NavLink>
                      <a href="http://travelmanager.curiotechnologies.in/" class="btn" target="_blank" >
                        Analytics
                      </a>
                      {/* <div className="dropdown">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          All Clients
                        </button>
                        <div className="dropdown-menu">
                          <button className="dropdown-item">1</button>
                          <button className="dropdown-item">2</button>
                        </div>
                      </div>
                     */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="search-custom">
                      <input
                        type="text"
                        name=""
                        className="form-control"
                        placeholder="Search"
                      />
                      <span>
                        <i className="material-icons align-middle">search</i>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Report Name</th>
                      <th>Report Description</th>
                      <th>Report Type</th>
                      <th>Creation date</th>
                      <th>Last Used</th>
                      {/* <th>Schedule</th>
                      <th>Scheduling</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.allReports.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.description}</td>
                          <td>{item.reportType}</td>
                          <td>
                            {moment(item.createdAt).format("DD MMM YYYY")}
                          </td>
                          <td>
                            {moment(item.updatedAt).format("DD MMM YYYY")}
                          </td>
                          {/* <td>
                            <button
                              className="btn"
                              data-toggle="modal"
                              data-target="#scheduleReportModal"
                              onClick={e => this.setScheduleReports(e, item.id)}
                            >
                              Schedule Reports
                            </button>
                          </td> */}
                          {/* <td>
                            <div className="form-group">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="form-control"
                                  checked={
                                    item.isScheduled ? item.isScheduled : false
                                  }
                                  onChange={e => this.changeHandler(e, item.id)}
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                          </td> */}
                          <td>
                            <button
                              className="btn"
                              onClick={e =>
                                this.downloadAction(e, item.id, item.reportType)
                              }
                            >
                              Download
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="dropdown d-inline-block">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          10
                        </button>
                        <div className="dropdown-menu">
                          <button className="dropdown-item">10</button>
                          <button className="dropdown-item">20</button>
                          <button className="dropdown-item">30</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemPerPage}
                      totalItemsCount={this.state.totalItemsCount}
                      pageRangeDisplayed={3}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="scheduleReportModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="scheduleReportModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content border-0">
              <div className="modal-head">
                <h5
                  className="modal-title text-white"
                  id="scheduleReportModalLabel"
                >
                  Schedule Report
                </h5>
                <button
                  type="button"
                  className="close d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-middle pm-30">
                <div className="row">
                  <div className="col-md-4">
                    <h6 className="sub-heading">Schedule Frequency</h6>
                    <div className="form-group">
                      <label className="radio-container checkbox-padding mb-1 mt-2">
                        Weekly
                        <input
                          type="radio"
                          name="radio"
                          value="WEEKLY"
                          onChange={e => this.radioHandler(e)}
                          defaultChecked
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="radio-container checkbox-padding mb-1 mt-2">
                        Quarterly
                        <input
                          type="radio"
                          name="radio"
                          value="QUARTERLY"
                          onChange={e => this.radioHandler(e)}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="radio-container checkbox-padding mb-1 mt-2">
                        Monthly
                        <input
                          type="radio"
                          name="radio"
                          value="MONTHLY"
                          onChange={e => this.radioHandler(e)}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="radio-container checkbox-padding mb-1 mt-2">
                        Yearly
                        <input
                          type="radio"
                          name="radio"
                          value="YEARLY"
                          onChange={e => this.radioHandler(e)}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group custom labelup">
                      <label>
                        Start Date & Time <span className="text-danger">*</span>
                      </label>
                      <DayPickerInput format="DD MMM YYYY"
                        onDayChange={e => this.DateHandler(e)}
                      />
                      <div className="help-block">
                        {this.validator.message(
                          "start",
                          this.state.dateRemind,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <h6 className="sub-heading">Recipient Details</h6>
                <div className="form-group custom">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="&nbsp;"
                    onChange={e => this.emailHandler(e)}
                  />
                  <label>
                    Email Recipients <span className="text-danger">*</span>
                  </label>
                  <div className="help-block">
                    {this.validator.message(
                      "email",
                      this.state.emailRecipient,
                      "required"
                    )}
                  </div>
                </div>
                <div className="form-group custom textarea mb-1">
                  <textarea
                    className="form-control"
                    placeholder="&nbsp;"
                    onChange={e => this.multiEmailHandler(e)}
                  />
                  <label>Email Recipients</label>
                </div>
                <p className="mb-4">
                  Enter multiple email addresses, separated by commas.
                </p>
                <button
                  className="btn"
                  onClick={e => this.sendScheduleReport(e)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ReportsPage;
