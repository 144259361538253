import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import FlightBookingPage from "../home/flight-components/flight-booking-page";
import HotelPageBook from "../home/hotel-components/hotel-booking";
import { withRouter } from 'react-router-dom';
import { checkIsArray } from 'src/utils/util';
import CabBooking from "../cab/components/CabBooking";
import TrainBookingPage from "../home/train-components/train-booking";
class ModifyServicePage extends Component {
  render() {
    const pathName = this.props.location.pathname;
    let currentPageName = pathName.split('/');
    currentPageName = checkIsArray(currentPageName) && currentPageName.length > 3 ? currentPageName[3] : '';
    const { type } = this.props;
    return (
      <div>
        <div
          className="modal fade without-bg"
          id="modifyModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modifyModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {type === "oneway" && (
                <div>
                  <div className="booking">
                    <div className="booking-list modal-list">
                      <div
                        className="close "
                        data-dismiss="modal"
                        aria-label="Close"
                      >&times;</div>
                      <NavLink to="/booking/flight">
                        <img
                          width="80"
                          height="80"
                          src="/assets/images/booking/flight_booking.svg"
                          alt="Flight"
                        />
                        <p>Flights</p>
                      </NavLink>
                    </div>
                  </div>
                  <FlightBookingPage
                    currentPageName={currentPageName}
                    cartCount={this.props.cartCount ? this.props.cartCount : 0}
                  />
                </div>
              )}
              {type === "round" && (
                <div>
                  <div
                    className="close "
                    data-dismiss="modal"
                    aria-label="Close"
                  >&times;</div>
                  <div className="booking">
                    <div className="booking-list modal-list">
                      <NavLink to="/booking/flight">
                        <img
                          width="80"
                          height="80"
                          src="/assets/images/booking/flight_booking.svg"
                          alt="Flight"
                        />
                        <p>Flights</p>
                      </NavLink>
                    </div>
                  </div>
                  <FlightBookingPage
                    currentPageName={currentPageName}
                    cartCount={this.props.cartCount ? this.props.cartCount : 0}
                  />
                </div>
              )}
              {type === "multi" && (
                <div>
                  <div
                    className="close "
                    data-dismiss="modal"
                    aria-label="Close"
                  >&times;</div>
                  <div className="booking">
                    <div className="booking-list modal-list">
                      <NavLink to="/booking/flight">
                        <img
                          width="80"
                          height="80"
                          src="/assets/images/booking/flight_booking.svg"
                          alt="Flight"
                        />
                        <p>Flights</p>
                      </NavLink>
                    </div>
                  </div>
                  <FlightBookingPage
                    currentPageName={currentPageName}
                    cartCount={this.props.cartCount ? this.props.cartCount : 0}
                  />
                </div>
              )}
              {type === "hotel" && (
                <div>
                  <div
                    className="close "
                    data-dismiss="modal"
                    aria-label="Close"
                  >&times;</div>
                  <div className="booking">
                    <div className="booking-list modal-list">
                      <NavLink to="/booking/flight">
                        <img
                          width="80"
                          height="80"
                          src="/assets/images/booking/hotel_booking.svg"
                          alt="Flight"
                        />
                        <p>Hotels</p>
                      </NavLink>
                    </div>
                  </div>
                  <HotelPageBook
                    currentPageName={currentPageName}
                    cartCount={this.props.cartCount ? this.props.cartCount : 0}
                  />
                </div>
              )}
              {type === "cab" && (
                <div>
                  <div
                    className="close "
                    data-dismiss="modal"
                    aria-label="Close"
                  >&times;</div>
                  <div className="booking">
                    <div className="booking-list modal-list">
                      <NavLink to="/booking/cab">
                        <img
                          width="80"
                          height="80"
                          src="/assets/images/booking/car_booking.svg"
                          alt="Car"
                        />
                        <p>Cab</p>
                      </NavLink>
                    </div>
                  </div>
                  <CabBooking
                    cabRequest={this.props.cabRequest}
                  />
                </div>
              )}
              {type === "train" && (
                <div>
                  <div
                    className="close "
                    data-dismiss="modal"
                    aria-label="Close"
                  >&times;</div>
                  <div className="booking">
                    <div className="booking-list modal-list">
                      <NavLink to="/booking/train">
                        <img
                          width="80"
                          height="80"
                          src="/assets/images/booking/train_booking.svg"
                          alt="Car"
                        />
                        <p>Train</p>
                      </NavLink>
                    </div>
                  </div>
                  <TrainBookingPage
                    renderType="ModifyService"
                    trainRequest={this.props.trainRequest}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ModifyServicePage);
