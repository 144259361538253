import { http } from "../_helpers";
import { config } from "../env";


export const importExportService = {
    uploadFile,
    uploadCSV,
    uploadCSVToDatabase,
    getHistory
}

function uploadFile (companyId, data) {
    return http
      .fileUpload(config.apiUrlCompany + `companies/${companyId}/currency-exchange` , data)
      .then (data => data);

  }

  function uploadCSV(data){
      return http
        .fileUpload(config.apiUrlCompany + `upload-file`, data)
        .then(data => data)
  }

  function uploadCSVToDatabase(filePath, importType){
      let payload = {
          path: filePath,
          importType
      };
      let url = `${config.apiUrlCompany}company-users/import`;
    return http.post(url,payload)
      .then(data => data)
  }

  function getHistory(limit=10, page=1, sort ="desc"){
    //   let limit = 10;
    //   let sort="desc";

      let url = `${config.apiUrlCompany}import-history/listall?limit=${limit}&sort=${sort}`;
      return http.get(url).then(res => res);

  }