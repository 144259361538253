import { http } from "../_helpers";
import { config } from "../env";

const getExpenseCategory = (page, limit, search, company) => {
  return http
    .get(
      config.apiExpense +
        `expense-category?page=${page}&limit=${limit}&search=${search}&companyId=${company}`
    )
    .then(data => {
      return data ? data : "";
    });
};
const getExpensesCategory = company => {
  return http
    .get(config.apiExpense + `expense-category?companyId=${company}`)
    .then(data => {
      return data ? data : "";
    });
};
const createExpenseCategory = data => {
  return http.post(config.apiExpense + `expense-category`, data).then(data => {
    return data ? data : "";
  });
};
const updateExpenseCategory = payload => {
  let data = {
    companyId: payload.companyId,
    expenseCategoryName: payload.expenseCategoryName
  };
  let id = payload.expenseCategoryId;
  return http
    .patch(config.apiExpense + `expense-category/${id}`, data)
    .then(data => {
      return data ? data : "";
    });
};
const statusExpenseCategory = (id, data) => {
  return http
    .put(config.apiExpense + `expense-category/${id}`, data)
    .then(data => {
      return data ? data : "";
    });
};
const deleteExpenseCategory = id => {
  return http
    .delete(config.apiExpense + `expense-category/${id}`)
    .then(data => {
      return data ? data : "";
    });
};
// expense types
const getExpenseTypesOne = company => {
  return http
    .get(config.apiExpense + `expense-type?companyId=${company}`)
    .then(data => {
      return data ? data : "";
    });
};

const getExpenseTypesDetail = (id, company) => {
  return http
    .get(config.apiExpense + `expense-type/${id}?companyId=${company}`)
    .then(data => {
      return data ? data : "";
    });
};

const getExpenseTypes = (page, limit, search, company) => {
  return http
    .get(
      config.apiExpense +
        `expense-type?page=${page}&limit=${limit}&search=${search}&companyId=${company}`
    )
    .then(data => {
      return data ? data : "";
    });
};
const createExpenseTypes = data => {
  return http.post(config.apiExpense + `expense-type`, data).then(data => {
    return data ? data : "";
  });
};
const updateExpenseTypes = (id, data) => {
  return http
    .patch(config.apiExpense + `expense-type/${id}`, data)
    .then(data => {
      return data ? data : "";
    });
};
const statusExpenseTypes = (id, data) => {
  return http.put(config.apiExpense + `expense-type/${id}`, data).then(data => {
    return data ? data : "";
  });
};
const deleteExpenseTypes = id => {
  return http.delete(config.apiExpense + `expense-type/${id}`).then(data => {
    return data ? data : "";
  });
};

const getPolicies = (page, limit, company) => {
  return http
    .get(
      config.apiExpense +
        `policies?page=${page}&limit=${limit}&companyId=${company}`
    )
    .then(data => {
      return data ? data : "";
    });
};

// Om modifications

// End of Om modifications

const getAccountStructure = (search, company) => {
  return http
    .get(
      config.apiExpense +
        `accounting-structure?search=${search}&companyId=${company}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const addAccountStructure = data => {
  return http
    .post(config.apiExpense + `accounting-structure`, data)
    .then(data => {
      return data ? data : "";
    });
};

const deleteAccountStructure = id => {
  return http
    .delete(config.apiExpense + `accounting-structure/${id}`)
    .then(data => {
      return data ? data : "";
    });
};

const updateAccountStructure = (id, data) => {
  return http
    .patch(config.apiExpense + `accounting-structure/${id}`, data)
    .then(data => {
      return data ? data : "";
    });
};

//*  Account hierarchy structure
const getHierarchyStructure = headerId => {
  return http
    .get(config.apiUrlCompany + `form-headers?serviceModuleId=${headerId}`)
    .then(data => {
      return data ? data : "";
    });
};
const getHierarchyField = (companyId, formHeaderId) => {
  return http
    .get(
      config.apiUrlCompany +
        `company-fields/get-form-header-fields?companyId=${companyId}&formHeaderId=${formHeaderId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const addHierarchyStructure = data => {
  return http.post(config.apiExpense + `add-hirearchy/`, data).then(data => {
    return data ? data : "";
  });
};
const getHierarchyStructureField = companyId => {
  return http
    .get(config.apiExpense + `/accounting-structure?companyId=${companyId}`)
    .then(data => {
      return data ? data : "";
    });
};

const deleteHierarchyStructure = id => {
  return http
    .delete(config.apiExpense + `accounting-structure/${id}`)
    .then(data => {
      return data ? data : "";
    });
};

const changeHierarchyOrderStructure = data => {
  return http.post(config.apiExpense + `hirearchy/order`, data).then(data => {
    return data ? data : "";
  });
};

// * End Account Hierarchy structure

//* Account codes

const getAccountingCode = (companyId, ledgerId, hierarchyId) => {
  return http
    .get(
      config.apiExpense +
        `/accounting-code?companyId=${companyId},ledgerId=${ledgerId},hierarchyId=${hierarchyId}`
    )
    .then(data => {
      return data ? data : "";
    });
};
const getAccountingCodeList = data => {
  return http
    .post(config.apiExpense + `accounting-code/get`, data)
    .then(data => {
      return data ? data : "";
    });
};

const getListExpense = (companyId, pageNo) => {
  return http
    .get(
      config.apiExpense +
        `/expense-type?companyId=${companyId}&page=${pageNo}&limit=10`
    )
    .then(data => {
      return data ? data : "";
    });
};

const addAccountingCode = data => {
  return http.post(config.apiExpense + `/accounting-code`, data).then(data => {
    return data ? data : "";
  });
};

const getHierarchyLevel = (companyId, ledgerId) => {
  return http
    .get(
      config.apiExpense +
        `/accounting-structure/accounting-code?companyId=${companyId}&ledgerId=${ledgerId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

//* End Account codes

// * Expense Type Policies

const getExpenseEntryData = companyId => {
  let url = `${
    config.apiUrlCompany
  }form-headers/forms?formHeaderName=report-entry&companyId=${companyId}`;
  return http.get(url).then(res => res);
};

const getItemizedEntryData = companyId => {
  let url = `${
    config.apiUrlCompany
  }form-headers/forms?formHeaderName=report-itemization&companyId=${companyId}`;
  return http.get(url).then(res => res);
};
const getAttendeeFormData = companyId => {
  let url = `${
    config.apiUrlCompany
  }form-headers/forms?formHeaderName=report-attendee&companyId=${companyId}`;
  return http.get(url).then(res => res);
};
const getRequestEntryData = companyId => {
  let url = `${
    config.apiUrlCompany
  }form-headers/forms?formHeaderName=request-entry&companyId=${companyId}`;
  return http.get(url).then(res => res);
};

const createExpenseTypePolicy = payload => {
  let url = `${config.apiExpense}expense-type-policy`;
  return http.post(url, payload).then(res => res);
};

const createRequestExpenseTypePolicy = payload => {
  let url = `${config.apiExpense}request-expense-policy`;
  return http.post(url, payload).then(res => res);
};

const editExpenseTypePolicy = (expenseTypePolicyId, payload) => {
  let url = `${config.apiExpense}expense-type-policy/${expenseTypePolicyId}`;
  return http.patch(url, payload);
};

const editRequestExpenseTypePolicy = (expenseTypePolicyId, payload) => {
  let url = `${config.apiExpense}request-expense-policy/${expenseTypePolicyId}`;
  return http.patch(url, payload);
};

const getExpenseTypePolicy = (companyId, expenseTypeId, policyId) => {
  let url = `${
    config.apiExpense
  }expense-type-policy?companyId=${companyId}&expenseTypeId=${expenseTypeId}`;
  return http.get(url).then(res => res);
};

const getRequestExpenseTypePolicy = (companyId, expenseTypeId, policyId) => {
  let url = `${
    config.apiExpense
  }request-expense-policy?companyId=${companyId}&expenseTypeId=${expenseTypeId}`;
  return http.get(url).then(res => res);
};

const getRequestExpensePolicy = (page, limit, companyId) => {
  return http
    .get(
      config.apiExpense +
        `request-policies?companyId=${companyId}&limit=${limit}&page=${page}`
    )
    .then(data => {
      return data ? data : "";
    });
};

// * End Of expense Type Policies

// * expense type limits

const getExpenseTypeLimit = (companyId, limit, page) => {
  return http
    .get(
      config.apiExpense +
        `expense-type-limit?companyId=${companyId}&limit=${limit}&page=${page}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const createExpenseTypeLimit = payload => {
  return http
    .post(config.apiExpense + `expense-type-limit`, payload)
    .then(data => {
      return data ? data : "";
    });
};

const updateExpenseTypeLimit = (id, payload) => {
  return http
    .patch(config.apiExpense + `expense-type-limit/${id}`, payload)
    .then(data => {
      return data ? data : "";
    });
};

const deleteExpenseTypeLimit = id => {
  return http
    .delete(config.apiExpense + `expense-type-limit/${id}`)
    .then(data => {
      return data ? data : "";
    });
};

const statusExpenseTypeLimit = (id, payload) => {
  return http
    .put(config.apiExpense + `expense-type-limit/${id}`, payload)
    .then(data => {
      return data ? data : "";
    });
};

const getAppliesToList = companyId => {
  return http
    .get(
      config.apiUrlCompany +
        `list-management?companyId=${companyId}&itemCode=GRP001`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getAllCurrencyCode = companyId => {
  return http
    .get(
      config.apiUrlCompany +
        `list-management?companyId=${companyId}&itemCode=CUR001`
    )
    .then(data => {
      return data ? data : "";
    });
};

// * End of expense Type Limits

// * Start of Expense type Itemization

const createItemization = data => {
  return http
    .post(config.apiExpense + `expense-type-itemization`, data)
    .then(data => {
      return data ? data : "";
    });
};

const updateItemization = (id, data) => {
  return http
    .patch(config.apiExpense + `expense-type-itemization/${id}`, data)
    .then(data => {
      return data ? data : "";
    });
};

const deleteExpenseTypeItemization = id => {
  return http
    .delete(config.apiExpense + `expense-type-itemization/${id}`)
    .then(data => data);
};

const getItemization = (page, limit, search, company) => {
  return http
    .get(
      config.apiExpense +
        `expense-type-itemization?page=${page}&limit=${limit}&search=${search}&companyId=${company}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getItemizations = company => {
  return http
    .get(config.apiExpense + `expense-type-itemization?companyId=${company}`)
    .then(data => {
      return data ? data : "";
    });
};
const statusExpenseItemization = (id, data) => {
  return http
    .put(config.apiExpense + `expense-type-itemization/${id}`, data)
    .then(data => {
      return data ? data : "";
    });
};

const statusAvailExpenseItemization = (id, data) => {
  return http
    .put(
      config.apiExpense + `expense-type-itemization/${id}/expense-type`,
      data
    )
    .then(data => {
      return data ? data : "";
    });
};

const getItemById = (id, company) => {
  return http
    .get(
      config.apiExpense + `expense-type-itemization/${id}?companyId=${company}`
    )
    .then(data => {
      return data ? data : "";
    });
};

// list management api calls starts

const cloneListManagement = companyId => {
  return http
    .get(
      config.apiUrlCompany +
        `list-management/clone-list-management?companyId=${companyId}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getListManagement = (company, search, parent) => {
  return http
    .get(
      config.apiUrlCompany +
        `/list-management?companyId=${company}&search=${search}&parentId=${parent}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

const createListItem = data => {
  return http
    .post(config.apiUrlCompany + `/list-management`, data)
    .then(data => {
      return data;
    });
};

const getParentDetailById = id => {
  return http
    .get(config.apiUrlCompany + `/list-management/${id}`)
    .then(data => {
      return data ? data.result : "";
    });
};

const updateListItem = (id, company, data) => {
  return http
    .patch(
      config.apiUrlCompany + `list-management/${id}?companyId=${company}`,
      data
    )
    .then(data => {
      return data;
    });
};

const deleteListItem = id => {
  return http
    .delete(config.apiUrlCompany + `/list-management/${id}`)
    .then(data => {
      return data;
    });
};

const copyListItem = (company, data) => {
  return http
    .patch(
      config.apiUrlCompany + `list-management/copy?companyId=${company}`,
      data
    )
    .then(data => {
      return data;
    });
};
const cutListItem = (company, data) => {
  return http
    .patch(
      config.apiUrlCompany + `list-management/cut?companyId=${company}`,
      data
    )
    .then(data => {
      return data;
    });
};

const getCashAdvanceForm = companyId => {
  return http
    .get(
      config.apiUrlCompany +
        `form-headers/forms?formHeaderName=cash-advance&companyId=${companyId}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

const getCashAdvanceWorkflow = companyId => {
  return http
    .get(config.apiExpense + `workflow/general?companyId=${companyId}`)
    .then(data => {
      return data ? data.result : "";
    });
};

const getAppliesTo = companyId => {
  return http
    .get(
      config.apiUrlCompany +
        `list-management?companyId=${companyId}&itemCode=GRP001`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

const createCashAdvanceConfig = data => {
  return http.post(config.apiExpense + `cashAdvanceConfig`, data).then(data => {
    return data;
  });
};

const getAllCashAdvanceCreated = (companyId, pageLimit, activePage) => {
  return http
    .get(
      config.apiExpense +
        `cashAdvanceConfig?companyId=${companyId}&page=${activePage}&limit=${pageLimit}`
    )
    .then(data => {
      return data ? data : "";
    });
};

const changeCashAdvanceCashStatus = (company, data) => {
  return http
    .patch(
      config.apiExpense +
        `cashAdvanceConfig/active-status?companyId=${company}`,
      data
    )
    .then(data => {
      return data;
    });
};
const deleteAdvanceConfig = (companyId, data) => {
  return http
    .patch(
      config.apiExpense + `cashAdvanceConfig/delete?companyId=${companyId}`,
      data
    )
    .then(data => {
      return data;
    });
};

const editCashAdvanceConfig = (id, companyId) => {
  return http
    .get(config.apiExpense + `cashAdvanceConfig/${id}?companyId=${companyId}`)
    .then(data => {
      return data;
    });
};
const updateCashAdvanceConfig = data => {
  return http
    .patch(config.apiExpense + `cashAdvanceConfig`, data)
    .then(data => {
      return data;
    });
};

const getTravelCategory = () => {
  return http
    .get(config.apiUrlTravel + "travel/travel-category")
    .then(data => data);
};
const getSytemExpense = () => {
  return http
    .get(config.apiExpense + "system-sub-field?code=SYS001")
    .then(data => data);
};

const getTaxConfigList = (companyId, page, limit, search) => {
  return http
    .get(
      config.apiExpense +
        `tax-configurations?companyId=${companyId}&page=${page}&limit=${limit}&search=${search}`
    )
    .then(data => data);
};
const getDetailTaxConfig = (companyId, id) => {
  return http
    .get(config.apiExpense + `tax-configuration/${id}?companyId=${companyId}`)
    .then(data => data);
};
const addTaxConfig = data => {
  return http
    .post(config.apiExpense + `tax-configuration`, data)
    .then(data => data);
};
const updateTaxConfig = data => {
  return http
    .patch(config.apiExpense + `tax-configuration`, data)
    .then(data => data);
};
const removeTaxItem = (data, companyId) => {
  return http
    .patch(
      config.apiExpense + `/tax-configuration/delete?companyId=${companyId}`,
      data
    )
    .then(data => data);
};
const toggleTaxStatus = (data, companyId) => {
  return http
    .patch(
      config.apiExpense +
        `tax-configuration/active-status?companyId=${companyId}`,
      data
    )
    .then(data => {
      return data;
    });
};

const getAllocationDetails = (companyId, headerId, expenseId) => {
  return http
    .get(
      config.apiExpense +
        `expense-report-allocation?companyId=${companyId}&headerId=${headerId}&entryId=${expenseId}`
    )
    .then(data => {
      return data;
    });
};

export const expenseServices = {
  getExpenseCategory,
  getExpensesCategory,
  getTravelCategory,
  createExpenseCategory,
  updateExpenseCategory,
  statusExpenseCategory,
  deleteExpenseCategory,
  getExpenseTypes,
  createExpenseTypes,
  updateExpenseTypes,
  deleteExpenseTypes,
  statusExpenseTypes,
  getPolicies,
  getExpenseEntryData,
  getItemizedEntryData,
  getAttendeeFormData,
  getRequestEntryData,
  getRequestExpensePolicy,
  getRequestExpenseTypePolicy,
  createExpenseTypePolicy,
  createRequestExpenseTypePolicy,
  editExpenseTypePolicy,
  editRequestExpenseTypePolicy,
  getExpenseTypePolicy,
  addAccountStructure,
  getAccountStructure,
  deleteAccountStructure,
  updateAccountStructure,
  getHierarchyStructure,
  addHierarchyStructure,
  deleteHierarchyStructure,
  getHierarchyField,
  getHierarchyStructureField,
  changeHierarchyOrderStructure,
  getAccountingCode,
  getListExpense,
  addAccountingCode,
  getAccountingCodeList,
  getHierarchyLevel,
  getAppliesToList,
  getAllCurrencyCode,
  getExpenseTypeLimit,
  createExpenseTypeLimit,
  updateExpenseTypeLimit,
  statusExpenseTypeLimit,
  getExpenseTypesOne,
  deleteExpenseTypeLimit,
  createItemization,
  getItemization,
  getItemizations,
  statusExpenseItemization,
  deleteExpenseTypeItemization,
  getItemById,
  updateItemization,
  statusAvailExpenseItemization,
  getExpenseTypesDetail,
  cloneListManagement,
  getListManagement,
  createListItem,
  getParentDetailById,
  updateListItem,
  deleteListItem,
  copyListItem,
  cutListItem,
  getCashAdvanceForm,
  getCashAdvanceWorkflow,
  getAppliesTo,
  createCashAdvanceConfig,
  getAllCashAdvanceCreated,
  changeCashAdvanceCashStatus,
  deleteAdvanceConfig,
  editCashAdvanceConfig,
  updateCashAdvanceConfig,
  // tax config api call
  getTaxConfigList,
  addTaxConfig,
  getDetailTaxConfig,
  toggleTaxStatus,
  updateTaxConfig,
  removeTaxItem,
  getSytemExpense,
  getAllocationDetails
};
