import React from "react";
import Menu from "../menu";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
const TaxAuthoritiesPage = props => {
  let data = props;
  return (
    <div>
      <div className="admin-tabs">
        <Menu />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">W</div>
            <div className="right">
              <h6 className="heading">Tax Authorities</h6>
              <p className="text">Lorem Ipsum is simply dummy text.</p>
            </div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="btn-group-margin">
                    <NavLink
                      to="/administration/expense/tax-authority-step1"
                      className="btn   sm-padding"
                    >
                      New
                    </NavLink>
                    <button
                      type="button"
                      className="btn   sm-padding disabled"
                      data-toggle="modal"
                      data-target="#AddTemplateModal"
                    >
                      Modify
                    </button>
                    <button
                      type="button"
                      className="btn   sm-padding disabled"
                      data-toggle="modal"
                      data-target="#AddTemplateModal"
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <div className="col-md-2 offset-4">
                  <div className="search-custom">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Search"
                    />
                    <span>
                    <i className="material-icons">search</i>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Tax Authority</th>
                    <th>Country</th>
                    <th>State/Province</th>
                    <th>Tax Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Govt of India</td>
                    <td>IN </td>
                    <td>Karnataka</td>
                    <td>SGST</td>
                  </tr>
                  <tr>
                    <td>Govt of India</td>
                    <td>IN </td>
                    <td>Karnataka</td>
                    <td>SGST</td>
                  </tr>
                  <tr>
                    <td>Govt of India</td>
                    <td>IN </td>
                    <td>Karnataka</td>
                    <td>SGST</td>
                  </tr>
                  <tr>
                    <td>Govt of India</td>
                    <td>IN </td>
                    <td>Karnataka</td>
                    <td>SGST</td>
                  </tr>
                  <tr>
                    <td>Govt of India</td>
                    <td>IN </td>
                    <td>Karnataka</td>
                    <td>SGST</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          10
                        </button>
                        <div className="dropdown-menu">
                          <button type="button" className="dropdown-item">
                            10
                          </button>
                          <button className="dropdown-item">20</button>
                          <button className="dropdown-item">30</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <Pagination
                    activePage={data.active}
                    itemsCountPerPage={10}
                    totalItemsCount={10}
                    pageRangeDisplayed={3}
                    onChange={data.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="AddTemplateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AddTemplateModalLabel">
                Modify Calculation Properties
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group custom labelup">
                <label>Name</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    Text Box
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group custom labelup">
                <label>Country</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    SELECT ONE
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group custom labelup">
                <label>Type</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn btn-border-basic dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    SELECT ONE
                  </button>
                  <div className="dropdown-menu">
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                    <button type="button" className="dropdown-item">
                      Action 1
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="switch">
                  <input type="checkbox" checked />
                  <span className="slider round" />
                </label>
                <span>Can Approve Exception</span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-border-success lg-padding"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-border-basic lg-padding"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaxAuthoritiesPage;
