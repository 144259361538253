import * as yup from "yup";

export const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

yup.addMethod(yup.string, "phone", function() {
  // eslint-disable-next-line no-template-curly-in-string
  return this.test("phone", "${path} must be a valid phone number.", value =>
    rePhoneNumber.test(value)
  );
});
