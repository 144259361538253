import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Select } from "src/_components/common/_forms";
import { bookingServices } from "src/_services/booking.service";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import Loader from "src/_components/common/Loader";
import { getValue } from "src/_components/common/lodsh";

class MyTripListPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    sessionStorage.removeItem("TRIPID");
    this.state = {
      loading: false,
      tripList: [],
      selectedRejectionComment: ""
    };
  }
  componentDidMount() {
    this.getMyTrips();
  }
  itemsPerPage = 10;
  activePage = 1;
  count = 0;
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
  search = "";
  loading = false;
  sortBy = e => {
    let arrayCopy = this.tripList;
    arrayCopy.sort(this.compareBy(e));
    this.setState({ tripList: arrayCopy });
  };
  column = "";
  isDesc = "";
  compareBy(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    return function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    };
  }

  handleItemChange = limit => {
    this.activePage = 1;
    this.count = 0;
    this.itemsPerPage = limit["items"];
    this.setState({ loading: true });
    this.getMyTrips();
  };
  handlePageChange = pageNumber => {
    this.count = 0;
    this.activePage = pageNumber;
    this.setState({ loading: true });
    this.getMyTrips();
  };
  getMyTrips = async () => {
    this.count = 0;
    this.setState({ loading: true, tripList: [] });
    var resp = await bookingServices.getMyTrips(
      this.search,
      this.activePage,
      this.itemsPerPage
    );
    if (resp) {
      this.count = resp.count;
      this.setState({ tripList: resp.result });
    }
    this.setState({ loading: false });
  };
  handleSearch = e => {
    this.activePage = 1;
    this.search = e.target.value;
    this.activePage = 1;
    this.count = 0;
    this.getMyTrips();
  };
  storeSession = id => {
    sessionStorage.setItem("TRIPID", id);
  };
  storeSubSession = id => {
    // submit trip id
    sessionStorage.setItem("32AWE", id);
  };

  getUpdatedTripName = list => {
    if (
      list &&
      list.current &&
      list.TRIP_DATA &&
      list.TRIP_DATA.travelRequest &&
      list.TRIP_DATA.travelRequest.data &&
      list.TRIP_DATA.travelRequest.data.REQUEST &&
      list.TRIP_DATA.travelRequest.data.REQUEST.entryFields
    ) {
      let updatedTripName = list.TRIP_DATA.travelRequest.data.REQUEST.entryFields.find(
        item => item.columnName == "ITINERARY_NAME"
      );
      if (updatedTripName != undefined) {
        return updatedTripName.fieldValue;
      } else {
        return "";
      }
    }
  };

  handleRejectModalClick = comments => {
    if (comments) {
      this.setState({
        selectedRejectionComment: comments
      });
    }
  };
  handleCancelRejectModal = () => {
    this.setState({
      selectedRejectionComment: ""
    });
  };
  render() {
    // console.log(this.state.tripList)
    // let data = this.props;
    const { loading } = this.state;
    return (
      <div>
        <div className="p-padding">
          <div className="main-box">
            <div className="py-padding pb-0">
              <div className="table-container mx-0">
                <form className="py-0 px-4">
                  <div className="row">
                    <div className="col-6 col-md-9">
                      <div className="btn-group-margin">
                        <NavLink to="/booking/flight" className="btn">
                          Book Itinerary
                        </NavLink>
                        {/* <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            Select
                      </button>
                          <div className="dropdown-menu">
                            <button
                              type="button"
                              className="dropdown-item"
                            >
                              Pending Submission
                      </button>
                            <button
                              type="button"
                              className="dropdown-item"
                            >
                              Rejected
                       </button>
                            <button
                              type="button"
                              className="dropdown-item"
                            >
                              Approved
                        </button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      {/* {this.count !== 0 && */}
                      <div className="search-custom">
                        <input
                          onChange={e => this.handleSearch(e)}
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="Search"
                        />
                        <span>
                          <i className="material-icons">search</i>
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>REQUEST DETAILS</th>
                        <th>REQUEST DATE</th>
                        <th>TRAVEL DATE</th>
                        {/* <th>PENDING ACTION BY</th> */}
                        <th>SERVICE BOOKED</th>
                        <th>ESTIMATED TRIP COST</th>
                        <th width="20%">STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && this.count === 0 && (
                        <tr>
                          <td colSpan="7" className="text-center">
                            <Loader />
                          </td>
                        </tr>
                      )}
                      {!loading && this.count === 0 && (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No Records Found
                          </td>
                        </tr>
                      )}
                      {this.state.tripList.map((list, key) => {
                        if (
                          list.current ||
                          (list.TRAVEL_DATE && list.TRAVEL_DATE.STATUS)
                        ) {
                          return (
                            <tr key={key}>
                              <td>
                                {list.current && (
                                  <NavLink
                                    to={`/booking/submit/trip-details/${list.id}`}
                                  >
                                    {list.current
                                      ? list.current.TRIP_ID
                                      : list.TRIP_ID}
                                  </NavLink>
                                )}
                                {list.TRAVEL_DATE && list.TRAVEL_DATE.STATUS && (
                                  <NavLink
                                    onClick={e =>
                                      this.storeSession(list.TRIP_ID)
                                    }
                                    to="/booking/checkout/itinerary"
                                  >
                                    {list.TRIP_ID}
                                  </NavLink>
                                )}
                                <br />
                                {list.TRAVEL_DATE && list.TRAVEL_DATE.TRIP_NAME}
                                {this.getUpdatedTripName(list) ||
                                  (list.current &&
                                    list.TRIP_DATA &&
                                    list.TRIP_DATA.itenary &&
                                    list.TRIP_DATA.itenary.TRAVEL_DATE &&
                                    list.TRIP_DATA.itenary.TRAVEL_DATE
                                      .TRIP_NAME)}
                              </td>

                              <td>
                                {list.TRAVEL_DATE?.REQUESTED_DATE ? (
                                  <Moment format="DD MMM YYYY hh:mm a">
                                    {list.TRAVEL_DATE &&
                                      list.TRAVEL_DATE.REQUESTED_DATE}
                                  </Moment>
                                ) : list.TRIP_DATA?.itenary?.TRAVEL_DATE
                                  ?.REQUESTED_DATE ? (
                                      <Moment format="DD MMM YYYY hh:mm a">
                                        {
                                          list.TRIP_DATA?.itenary?.TRAVEL_DATE
                                            ?.REQUESTED_DATE
                                        }
                                      </Moment>
                                    ) : (
                                      "-"
                                    )}
                                {/* <Moment format="DD MMM YYYY hh:mm a">
                                  {list.current && list.TRIP_DATA && list.TRIP_DATA.itenary && list.TRIP_DATA.itenary.TRAVEL_DATE &&
                                    list.TRIP_DATA.itenary.TRAVEL_DATE.REQUESTED_DATE
                                  }
                                </Moment> */}
                              </td>
                              <td>
                                {list.TRAVEL_DATE &&
                                  list.TRAVEL_DATE.TRAVEL_FROM}
                                {list.current &&
                                  list.TRIP_DATA &&
                                  list.TRIP_DATA.itenary &&
                                  list.TRIP_DATA.itenary.TRAVEL_DATE &&
                                  list.TRIP_DATA.itenary.TRAVEL_DATE
                                    .TRAVEL_FROM}{" "}
                                -{" "}
                                {list.TRAVEL_DATE && list.TRAVEL_DATE.TRAVEL_TO}
                                {list.current &&
                                  list.TRIP_DATA &&
                                  list.TRIP_DATA.itenary &&
                                  list.TRIP_DATA.itenary.TRAVEL_DATE &&
                                  list.TRIP_DATA.itenary.TRAVEL_DATE.TRAVEL_TO}
                              </td>
                              {/* <td> - </td> */}
                              <td>
                                {list.TRAVEL_DATE && (
                                  <div>
                                    {list.data.flightData &&
                                      list.data.flightData.length !== 0 && (
                                        <i className="material-icons mr-3">
                                          flight
                                        </i>
                                      )}
                                    {list.data.hotelData &&
                                      list.data.hotelData.length !== 0 && (
                                        <i className="material-icons">hotel</i>
                                      )}
                                  </div>
                                )}
                                {list.current && list.TRIP_DATA && (
                                  <div>
                                    {getValue(list, "TRIP_DATA.itenary.data.flightData").length !== 0 && (
                                      <i className="material-icons">flight</i>
                                    )}
                                    {getValue(list, "TRIP_DATA.itenary.data.hotelData").length !== 0 && (
                                      <i className="material-icons">hotel</i>
                                    )}
                                    {getValue(list, "TRIP_DATA.itenary.data.carData").length !== 0 && (
                                      <i className="material-icons">directions_car</i>
                                    )}
                                    {getValue(list, "TRIP_DATA.itenary.data.trainData").length !== 0 && (
                                      <i className="material-icons">train</i>
                                    )}
                                  </div>
                                )}
                              </td>
                              <td>
                                {console.log(list)}
                                {list.TRIP_COST
                                  ? list.BASE_CURRENCY
                                  : list.TRIP_DATA &&
                                  list.TRIP_DATA.itenary &&
                                  list.TRIP_DATA.itenary.BASE_CURRENCY}
                                &nbsp;
                                {list.TRIP_COST ? (
                                  <NumberFormat
                                    value={
                                      list.TRIP_COST &&
                                      parseInt(list.TRIP_COST).toFixed(2)
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                ) : (
                                    <NumberFormat
                                      value={
                                        list.current &&
                                        list.TRIP_DATA &&
                                        list.TRIP_DATA.itenary &&
                                        list.TRIP_DATA.itenary.TRIP_COST &&
                                        parseInt(
                                          list.TRIP_DATA.itenary.TRIP_COST
                                        ).toFixed(2)
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  )}
                              </td>
                              <td>
                                {list.current && list.current.STATUS ? (
                                  <div>
                                    {list.current.STATUS === "Reject" ? (
                                      <div
                                        className="cursor-pointer"
                                        onClick={e =>
                                          this.handleRejectModalClick(
                                            list.RESPONSE.comments
                                          )
                                        }
                                        data-target="#RejectStatusCommentModal"
                                        data-toggle="modal"
                                      >
                                        <button className="btn btn-nothing text-danger text-left">
                                          <i className="material-icons fs-14 align-middle">
                                            thumb_down
                                          </i>{" "}
                                          {list.current.STATUS}
                                        </button>
                                        <p className="text-danger fs-12 top__text w-100px">
                                          <i className="material-icons fs-18 mr-1">
                                            error
                                          </i>
                                          {list.RESPONSE.comments}
                                        </p>
                                      </div>
                                    ) : list.current.STATUS === "Approved" ? (
                                      <button className="btn btn-nothing text-success">
                                        <i className="material-icons fs-14 align-middle">
                                          thumb_up
                                        </i>{" "}
                                        {list.current.STATUS}
                                      </button>
                                    ) : (
                                          list.current.STATUS
                                        )}
                                  </div>
                                ) : list.current &&
                                  list.current?.currentOrderNo !== undefined ? (
                                      list.info?.steps[list.current?.currentOrderNo]
                                        .intialStatus
                                    ) : (list.data && list.data.additionalStatus) ?
                                      (
                                        <div
                                          className="cursor-pointer"
                                          onClick={e =>
                                            this.handleRejectModalClick(
                                              list.data.additionalStatus.comments
                                            )
                                          }
                                          data-target="#RejectStatusCommentModal"
                                          data-toggle="modal"
                                        >
                                          <button className="btn btn-nothing text-danger text-left">
                                            <i className="material-icons fs-14 align-middle">
                                              thumb_down
                                          </i>{" "}
                                            {list.data.additionalStatus.statusType}
                                          </button>
                                          <p className="text-danger fs-12 top__text w-100px">
                                            <i className="material-icons fs-18 mr-1">
                                              error
                                          </i>
                                            {list.data.additionalStatus.comments}
                                          </p>
                                        </div>
                                      )
                                      : (list.TRAVEL_DATE ? list.TRAVEL_DATE.STATUS : "No Status")}
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
                {this.count !== 0 && (
                  <div className="table-footer">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="page-count">
                          <div className="text">Items</div>
                          <div className="count">
                            {this.pageItemsList && (
                              <Select
                                placeholder={this.itemsPerPage}
                                keyName={"items"}
                                options={this.pageItemsList}
                                controlFunc={e => this.handleItemChange(e)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <Pagination
                          activePage={this.activePage}
                          itemsCountPerPage={this.limit}
                          totalItemsCount={this.count}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="RejectStatusCommentModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="RejectStatusCommentModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="RejectStatusCommentModalLabel">
                  Approver Comment
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <p>{this.state.selectedRejectionComment}</p>
              </div>
              <div className="modal-last btn-group-margin pm-30 pt-0">
                <button
                  type="button"
                  onClick={this.handleCancelRejectModal}
                  className="btn close"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MyTripListPage;

