import React, { Component } from "react";
import DayPicker from "react-day-picker";
import { bookingServices } from "../../../../../_services/booking.service";
import cryptr from "../../../../../_helpers/crypty";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId
} from "react-places-autocomplete";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { differenceInDaysNative } from "src/utils/util";
import { toast } from "react-toastify";
import moment from "moment";

class HotelPageBook extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (
      this.props.match.path === "/booking/hotel/list/:data" ||
      this.props.match.path ===
      "/booking/hotel/details/:id/:name/:duration/:query"
    ) {
      if (this.props.match.path === "/booking/hotel/list/:data") {
        this.req = JSON.parse(cryptr.decrypt(this.props.match.params.data));
      } else if (
        this.props.match.path ===
        "/booking/hotel/details/:id/:name/:duration/:query"
      ) {
        this.req = JSON.parse(cryptr.decrypt(this.props.match.params.query));
      }
      this.state = {
        selectedDay: this.req.fromDate,
        from: this.req.from,
        to: "",
        fromDate: this.req.fromDate,
        toDate: this.req.toDate,
        rooms: 1,
        type: "Hotels",
        lat: this.req.lat,
        lng: this.req.lng,
        country: this.req.country,
        cityName: this.req.cityName
      };
      let date = new Date(this.req.toDate);
      this.tomorrow = new Date(this.state.fromDate);
      this.tomorrow = new Date(this.tomorrow.setDate(this.tomorrow.getDate() + 1));
      this.dayAfterTomorrow = new Date(this.tomorrow.setDate(this.tomorrow.getDate() + 1));
      this.maxData = new Date(this.maxData.setDate(date.getDate() + 30))

    } else {
      if (this.props.match.path === "/booking/hotel") {
        // sessionStorage.removeItem("tripType");
        // sessionStorage.removeItem("TRIPID");
        this.tomorrow = new Date();
        this.tomorrow = new Date(this.tomorrow.setDate(this.tomorrow.getDate() + 1));
        this.dayAfterTomorrow = new Date(this.tomorrow.setDate(this.tomorrow.getDate() + 1));

      }
      this.state = {
        selectedDay: null,
        from: "",
        to: "",
        fromDate: null,
        toDate: null,
        rooms: 1,
        type: "Hotels",
        lat: "",
        lng: "",
        activeInput: 0,
        country: '',
        cityName: ''
      };
    }

  }






  showInput = e => {
    this.setState({ activeInput: e });

  };
  handleChange = from => {
    this.setState({ from });
  };
  locationFromRef = React.createRef();
  locationInputRef = React.createRef();
  dateFromRef = React.createRef();
  dateToRef = React.createRef();
  listRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = e => {
    const locationFromPopup = this.locationFromRef.current;
    const dateFromPopup = this.dateFromRef.current;
    const dateToPopup = this.dateToRef.current;
    const listPopup = this.listRef.current;
    const { activeInput } = this.state;
    if (activeInput === 1 && this.locationFromRef && !locationFromPopup.contains(e.target)) {
      this.setState({ activeInput: 0 })
      return;
    }
    if (activeInput === 3 && this.dateFromRef && !dateFromPopup.contains(e.target)) {
      this.setState({ activeInput: 0 })
      return;
    }
    if (activeInput === 4 && this.dateToRef && !dateToPopup.contains(e.target)) {
      this.setState({ activeInput: 0 })
      return;
    }
    if (activeInput === 5 && this.listRef && !listPopup.contains(e.target)) {
      this.setState({ activeInput: 0 })
      return;
    }
  };

  getCountryCode = (results) => {
    if (results[0] && results[0].address_components) {
      // console.log(results[0]);
      // console.log('----',results[0].address_components);
      let countryComponent = results[0].address_components.find(item => item.types.includes('country'));
      // console.log('country component found ', countryComponent);
      if (countryComponent != undefined) {
        let shortCountryCode = countryComponent.short_name;
        // let isoCountryCode = 'ISO 3166-2:'+ shortCountryCode;
        this.setState({
          country: shortCountryCode
        });

      }
    }
  }



  getCityCode = async (results) => {
    if (results[0] && results[0].address_components) {

      let formattedAddress = results[0].formatted_address;
      let splitFormattedAddress = formattedAddress.split(',');
      // console.log('----formatted address ', splitFormattedAddress);
      if (splitFormattedAddress && splitFormattedAddress.length)
        this.setState({
          cityName: splitFormattedAddress[0]
        });


    }
  }

  handleSelect = from => {
    geocodeByAddress(from)
      .then(results => {
        this.getCityCode(results);
        this.getCountryCode(results);
        return getLatLng(results[0]);
      })
      .then(location => {
        this.setState({
          from: from,
          lat: JSON.stringify(location.lat),
          lng: JSON.stringify(location.lng),
          activeInput: 0
        });
      })
      .catch(error => console.error("Error", error));
  };





  popularCity = [
    { lat: "12.9716", lng: "77.5946", name: "Bengaluru, Karnataka, India", cityName: "Bangalore", country: "IN" },
    { lat: "13.0827", lng: "80.2707", name: "Chennai, TamilNadu, India", cityName: "Chennai", country: "IN" },
    { lat: "17.3850", lng: "78.4867", name: "Hyderabad, Telangana, India", cityName: "Hyderabad", country: "IN" },
    { lat: "18.5204", lng: "73.8567", name: "Pune, Maharashtra, India", cityName: "Pune", country: "IN" },
    { lat: "15.2993", lng: "74.1240", name: "Goa, India", cityName: "Goa", country: "IN" }
  ];
  rooms = [
    { id: 1, value: "Hotels" },
    { id: 2, value: "Cottage" },
    { id: 3, value: "Motels" },
    { id: 4, value: "Resorts" },
    { id: 5, value: "Country House" },
    { id: 6, value: "Apartments" },
    { id: 7, value: "Guest Accomodation" }
  ];
  changeRoom = e => {
    this.setState({
      rooms: e.id,
      type: e.value, activeInput: 0
    });
  };
  maxData = new Date()
  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    this.setState({
      selectedDay: modifiers.selected ? undefined : day,
      fromDate: modifiers.selected ? undefined : day,
      toDate: ''
    }, () => {
      // this.state.selectedDay = this.state.fromDate
      let date = day;
      this.todayValidate = new Date(date);
      this.tomorrow = new Date(this.todayValidate);
      this.tomorrow.setDate(this.todayValidate.getDate() + 1);
      this.dayAfterTomorrow = new Date(this.tomorrow);
      this.dayAfterTomorrow.setDate(this.tomorrow.getDate() + 1);


      // this.maxData = new Date(date.setDate(date.getDate() + 30))
      // console.log(this.maxData)
      // console.log(this.state.selectedDay)
      // console.log(date.getDate() + 30)
    });
    this.setState({ activeInput: 0 })
  };
  handleDayClickTo = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    this.setState({
      toDate: modifiers.selected ? undefined : day
    });
    this.setState({ activeInput: 0 })
  };
  getCity = async e => {
    this.setState({
      from: e.name,
      lat: e.lat,
      lng: e.lng,
      country: e.country,
      cityName: e.cityName
    });
    this.setState({ activeInput: 0 })
  };
  compareCheckInAndCheckOutDate = (fromDate, toDate) => {
    let toDateInMoment = moment(toDate, 'DD/MM/YYYY');
    let fromDateInMoment = moment(fromDate, 'DD/MM/YYYY');
    return toDateInMoment.isSame(fromDateInMoment);
  }
  validationTrue = false;
  searchRecord = async () => {

    this.validationTrue = true;
    const { toDate, rooms, lng, lat, fromDate, from, country, cityName } = this.state;
    if (toDate && rooms && lat && lng && fromDate && from && country && cityName) {

      let isCheckInAndCheckOutSameDate = this.compareCheckInAndCheckOutDate(fromDate, toDate);

      if (isCheckInAndCheckOutSameDate) {
        toast.warn('Check-in and Check-out Dates cant be same.')
      } else {

        let trip_duration = differenceInDaysNative(toDate, fromDate);
        var req = {
          toDate: toDate,
          rooms: rooms,
          lat: lat,
          lng: lng,
          fromDate: fromDate,
          from: from,
          country: country,
          cityName: cityName,
          trip_duration: trip_duration
        };
        // console.log('the req before sending is ', req);
        if (sessionStorage["TRIPID"] === undefined) {
          let newReq = await bookingServices.getTripId();
          sessionStorage.setItem("TRIPID", newReq.TRIP_ID);
        } else {
          sessionStorage.setItem("modify", true);
          sessionStorage.setItem("req", cryptr.encrypt(JSON.stringify(req)));
          $(".close").click();
        }
        var resp = cryptr.encrypt(JSON.stringify(req));
        $(".close").click();
        this.props.history.push("/booking/hotel/list/" + resp);
      }
    }
    this.setState({ ...this.state })
  };
  render() {
    const { fromDate, toDate, selectedDay, rooms, from, type, activeInput } = this.state;
    let today = new Date();
    today = new Date(today);
    today.setDate(today.getDate() + 1);
    return (
      <div className="book-details">
        <div>
          <div
            className={
              activeInput !== 1
                ? "details from full-hotel"
                : "details from full-hotel show-popup"
            }
            ref={this.locationFromRef}
          >
            <div
              className="details-margin"
              className={
                this.validationTrue && this.state.from === ""
                  ? "details-margin not-valid"
                  : "details-margin"
              }
              onClick={e => this.showInput(1)}
            >
              <div className="d-table d-table__middle">
                <label htmlFor="search1" className="text-left m-label">
                  <p className="label">Location</p>
                  <h6 className="place">{from}</h6>
                </label>
              </div>
            </div>
            <div className="popup">
              <PlacesAutocomplete
                value={this.state.from}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                autofocus={true}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                    <div>
                      <div className="typ">
                        <input
                          {...getInputProps({
                            placeholder: "Search Places ...",
                            className: "",
                            autofocus: true
                          })}
                          id="search1"
                        />
                        <div className="pin">
                          <i className="material-icons">
                            location_on
</i>
                        </div>
                      </div>

                      {suggestions.length === 0 && (
                        <ul className="scroll">
                          <li>
                            <button type="button">Popular Cities</button>
                          </li>
                          {this.popularCity.map((items, key) => {
                            return (
                              <li key={key}>
                                <button
                                  type="button"
                                  onClick={e => this.getCity(items)}
                                >
                                  {/* {items.city}&nbsp;{" "}
                            <small> */}
                                  {items.name}
                                  {/* </small> */}
                                </button>
                                <div className="pin">
                                  <i className="material-icons">
                                    location_on
</i>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      <ul className="scroll autocomplete-dropdown-container">
                        {loading && (
                          <li>
                            <button>Loading...</button>
                          </li>
                        )}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer"
                            }
                            : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer"
                            };
                          return (
                            <li
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style
                              })}
                            >
                              <button>
                                {suggestion.description}
                              </button>
                              <div className="pin">
                                <i className="material-icons">
                                  location_on
</i>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
              </PlacesAutocomplete>
            </div>
          </div>

          <div
            ref={this.dateFromRef}
            className={
              activeInput !== 3
                ? "details depart"
                : "details depart show-popup"
            }
          >
            <div
              className={
                this.validationTrue &&
                  (this.state.selectedDay === "" ||
                    this.state.selectedDay === null)
                  ? "details-margin not-valid"
                  : "details-margin"
              }
              onClick={e => this.showInput(3)}
            >
              <div className="d-table d-table__middle">
                <div className="text-left">
                  <p className="label">Checkin</p>

                  <h6 className="place">
                    {this.state.selectedDay ? (
                      <Moment format="DD MMM YYYY">
                        {this.state.selectedDay}
                      </Moment>
                    ) : (
                        "-"
                      )}
                  </h6>
                </div>
              </div>
            </div>
            <div className="popup date-picker">
              <DayPicker
                numberOfMonths={2}
                selectedDays={this.state.fromDate}
                disabledDays={{ before: today }}
                onDayClick={this.handleDayClick}
              />
            </div>
          </div>

          <div
            className={
              activeInput !== 4
                ? "details return "
                : "details return  show-popup"
            }
            ref={this.dateToRef}
          >
            <div
              className={
                this.validationTrue &&
                  (this.state.toDate === "" || this.state.toDate === null)
                  ? "details-margin not-valid"
                  : "details-margin"
              }
              onClick={e => this.showInput(4)}
            >
              <div className="d-table d-table__middle">
                <div className="text-left">
                  <p className="label">Checkout</p>

                  <h6 className="place">
                    {this.state.toDate ? (
                      <span>
                        <Moment format="DD MMM YYYY">
                          {this.state.toDate}
                        </Moment>
                      </span>
                    ) : (
                        "-"
                      )}
                  </h6>
                </div>
              </div>
            </div>
            <div className="popup date-picker left-popup">

              <DayPicker
                numberOfMonths={2}
                month={this.state.fromDate ? new Date(this.state.fromDate) : new Date()}
                selectedDays={this.state.toDate}
                disabledDays={{ before: this.tomorrow }}
                onDayClick={this.handleDayClickTo}
              />
            </div>
          </div>

          <div
            className={
              activeInput !== 5
                ? "details class "
                : "details class show-popup"
            }
            ref={this.listRef}
          >
            <div className="details-margin" onClick={e => this.showInput(5)}>
              <div className="d-table d-table__middle">
                <div className="text-left">
                  <p className="label">Type</p>
                  <h6 className="place">{type}</h6>
                </div>
              </div>
            </div>
            <div className="popup left-popup">
              <ul>
                {this.rooms.map((items, key) => {
                  return (
                    <li key={key}>
                      <button
                        onClick={e => this.changeRoom(items)}
                        type="button"
                      >
                        {items.value}
                      </button>
                      <div className="pin" />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <button
            onClick={this.searchRecord}
            className="btn details search btn-blue"
          >
            <i className="material-icons">
              search
            </i>
          </button>
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}
export default withRouter(HotelPageBook);
