import React from "react";
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { getValue } from "src/_components/common/lodsh";


export const CabCartItem = props => {
    const { index, item, removeCart, nonEdit, selectedItem, className, isApprover } = props;
    let currencyType = props.baseCurrency 
    let tripTypes = [
        { label: "Oneway", value: 0 },
        { label: "Round trip", value: 1 },
        { label: "Airport Pickup", value: 2 },
        { label: "Airport Drop", value: 3 },
    ];
    let vechileTypes = [
        { label: "Hatchback - economy", image: "/assets/images/hatchback_new.png" },
        { label: "Hatchback - premium", image: "/assets/images/hatchback_new.png" },
        { label: "Sedan - economy", image: "/assets/images/indigo_new.png" },
        { label: "Sedan - premium", image: "/assets/images/indigo_new.png" },
        { label: "SUV - economy", image: "/assets/images/xylo_new.png" },
        { label: "SUV - premium", image: "/assets/images/xylo_new.png" }
    ];
    let imageCar = vechileTypes.filter(resp => resp.label === item.car_type)
    let rideType = tripTypes.filter(resp => resp.value === item.car_ride_type)
    return (
        <li key={index}
            className={(selectedItem !== `cab${index}`) ? "itinerary-heading" : "itinerary-heading active"}
            onClick={e => props.chooseCab(item, 'cab', `cab${index}`)}>
            <div className="f-container">
                {/* {(nonEdit) ? "" :
                    <button className="btn btn-nothing remove hover-v" onClick={e => removeCart(item.fareContent.fare.flight_fare_id)}>
                        Remove
                    </button>
                } */}
                <div className="left-f">
                    <div className="left-f-div">
                        <div className="f-cont f-logo">
                            <img src={getValue(imageCar[0], "image")} alt="cabimg" />
                        </div>
                        <div className="f-cont f-time">
                            <b> {item.car_type} -  {getValue(rideType[0], "label")}
                            </b>
                            <br />
                            <span>

                            </span>
                        </div>
                        <div className="f-total">
                            {item.car_pickup_location.city} - {item.car_drop_location.city}
                            <br />
                            <Moment format="DD MMM YYYY hh:mm a" >
                                {item.car_pickup_timestamp}</Moment>
                           
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="right-f single">
                    <h5>
                        {getValue(item, "car_package_details.currency", "")} &nbsp;
          <NumberFormat value={getValue(item, "car_package_details.price", 0).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                    </h5>
                    <button className="btn btn-nothing">View Details <i className="material-icons">
                        arrow_forward_ios
        </i></button>
                </div>
                <div className="clearfix"></div>
            </div>
        </li >
    );
};
export default CabCartItem;
