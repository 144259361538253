import React ,{ Component } from 'react';
import moment from "moment";
class AssignedCashAdvnace extends Component{
    render(){
        return(
            <div
            className="modal fade"
            id="cashAdvReportModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="cashAdvReportLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-xl modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-head">
                  <h6 className="modal-subtitle">View</h6>
                  <h5 className="modal-title" id="cashAdvReportLabel">
                    Cash Advances Assigned to Report
                  </h5>
                </div>
                <div className="modal-middle mt-5">
                  <div className="table-responsive">
                    <table className="table table-striped border">
                      <thead>
                        <tr>
                          <th />
                          <th>CASH ADVANCE NAME</th>
                          <th>REQUESTED DATE</th>
                          <th>ISSUED AMOUNT</th>
                          {/* <th>EXCHANGE RATE</th> */}
                          <th>AVAILABLE BALANCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.getAllAssignedCashAdavnce
                          ? this.props.getAllAssignedCashAdavnce.map(
                            (result, index) => {
                              let theadArray = [];
                              theadArray = result.data.map(
                                header => header.columnName
                              );
                              let CASH_ADVANCE_NAME = theadArray.indexOf(
                                "CASH_ADVANCE_NAME"
                              );
                              let AMOUNT = theadArray.indexOf("AMOUNT");
                              return (
                                <tr key={index}>
                                  <td className="modal-px-ly py-3">
                                    <label className="checkbox-container m-0">
                                      <input
                                        onChange={e =>
                                          this.props.onSelectAttchedCashAdvHadnler(
                                            result
                                          )
                                        }
                                        type="checkbox"
                                        checked={this.props.selectedCashAdvAttachReceiptIds.includes(
                                          result.id
                                        )}
                                      />
                                      <span className="checkmark" />
                                    </label>
                                  </td>
                                  <td className="text-primary">
                                    {result.data[CASH_ADVANCE_NAME] &&
                                      result.data[CASH_ADVANCE_NAME].fieldValue}
                                  </td>

                                  <td>
                                    {result.data[index] &&
                                      result.data[index].createdAt &&
                                      moment(
                                        result.data[index].createdAt
                                      ).format("DD MMM YYYY")}
                                  </td>

                                  <td>
                                    {result.issuedCurrency}
                                    &nbsp;
                                    {(new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(result.amountIssued))}
                                  </td>
                                  {/* <td></td> */}
                                  <td>
                                    {result.issuedCurrency}
                                    &nbsp;
                                    {(new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(result.balance))}
                                  </td>
                                </tr>
                              );
                            }
                          )
                          : ""}
                        {this.props.getAllAssignedCashAdavnce.length !==
                          0 ? null : (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan={5}>
                                No Records Found
                            </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                    <div className="modal-last btn-group-margin pm-30 ">
                      <button
                        data-dismiss="modal"
                        disabled={
                          this.props.selectedCashAdvAttachReceiptIds.length <= 0
                        }
                        onClick={this.props.onRemoveAttchedCashAdvHadnler}
                        type="button"
                        className="btn"
                      >
                        {" "}
                        DELETE FROM REPORT
                      </button>
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default AssignedCashAdvnace;
