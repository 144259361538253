import React from "react";
import { NavLink } from "react-router-dom";
import DynamicForm from "./DynamicForm";
import SelectNew from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import DayPickerInput from "react-day-picker/DayPickerInput";
import  {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import { Input, Select } from "src/_components/common/_forms";
import Loader from "src/_components/common/Loader";
import AsyncSelect from "react-select/lib/Async";

const AddUserPage = props => {
  const accessList = [
    { label: "User Access", value: 1 },
    { label: "Approver Access", value: 2 },

  ];
  const {
    formData,
    openDelegate,
    fetchMoreData,
    hasMore,
    searchUser,
    search,
    error,
    state,
    count,
    handleChange,
    staticHandle,
    isEdit,
    submitUpdateHandle,
    dummuyValue,
    travelClass,
    userList,
    handleSelectChange,
    chooseDelegate,
    delegatedItem,
    delegatedView,
    handleChangeDelegate,
    serviceList,
    serviceName,
    accessTypeName,
    handleStatusChange,
    removeDelegateHandler,
    inviteHandler,
    testUserHandler,
    handleTravelSelector,
    dayChangeHandler,
    imageSelectedHandler,
    searchManager,
    searchManager2,
    inviteHandlerApprover,
    selectDefaultManager,
    onLoadingOptions
  } = props;

  const {
    delegatedUser,
    activeStatus,
    id,
    isTestUser,
    invite,
    removedDelegate,
    allTravelAllowance,
    allTravelClass,
    selectedTravelClass,
    selectedTravelAllowance,
    selectedTravelWorkflow,
    selectedTravelDiscount,
    allTravelWorkflow,
    isVerifiedInvitation,
    allDelegatedUsers,
    profileImage,
    isApprover,
    discountList
  } = props.state;
  const path = `url(${profileImage})`;
  const profImg = {
    backgroundImage: path
  };
  let travelClassOptions = [],
    travelAllowanceOptions = [];



  // * Create options for travel class
  if (allTravelClass) {
    travelClassOptions = allTravelClass.map(item => {
      return {
        value: item.id,
        label: item.className
      };
    });
  }

  // * Create options for travel allowance

  if (allTravelAllowance) {
    travelAllowanceOptions = allTravelAllowance.map(item => {
      return {
        value: item.id,
        label: item.configurationName
      };
    });
  }

  //  * the following function sets the value of access type during edit mode

  const getAccessListValues = (accessType) => {
    if (accessType.length) {
      var accessValues = accessList.filter(item => {

        return accessType.includes(item.value);
      });
    }
    return accessValues;
  }


  const getServiceListValue = service => {
    if (serviceList) {
      let mappedServiceList = serviceList.map(item => {
        return {
          value: item.id,
          label: item.serviceModuleName
        }
      })
      var res = mappedServiceList.filter(item => service.includes(item.value));
    }
    return res
  };
  let serviceOptions = [];
  serviceList && serviceList.forEach((service) => {
    serviceOptions.push({ label: service.serviceModuleName, value: service.id })
  });
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">A</div>
          <div className="right">
            {isEdit === false ? (
              <h6 className="heading">Add User</h6>
            ) : (
                <h6 className="heading">Edit User</h6>
              )}
            <p className="text">Edit User Details.</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        {(state.isLoading) ?
          <div className="form-holder">
            <Loader />
          </div>
          :
          <div className="form-holder mb-3 zoomIn animated">
            <div className="row">
              <div className="col-xl-6 offset-xl-3">
                <div className="user-profile w-200">
                  <div className="img"
                    style={profImg}
                  >
                    {isEdit === false ? (<i id="notimg" className="material-icons">
                      person
                      </i>) : ""}
                    <div className="upload">
                      <span>Edit</span>
                      <input
                        type="file"
                        placeholder="Upload"
                        onChange={imageSelectedHandler}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group custom labelup textarea">
                  <label>Travel Class</label>
                  {allTravelClass && (
                    <Select
                      placeholder={
                        selectedTravelClass ? selectedTravelClass : "Select Rule"
                      }
                      keyName={"className"}
                      options={allTravelClass}
                      controlFunc={e => handleTravelSelector(e, "travelClass")}
                    />
                  )}
                </div>
                <div className="form-group custom labelup textarea">
                  <label>Travel Discount </label>
                  {discountList && (
                    <Select
                      placeholder={
                        selectedTravelDiscount
                          ? selectedTravelDiscount
                          : "Select Discount"
                      }
                      keyName={"configName"}
                      options={discountList}
                      controlFunc={e => handleTravelSelector(e, "travelDiscount")}
                    />
                  )}
                </div>
                <div className="form-group custom labelup textarea">
                  <label>Travel Allowance</label>
                  {allTravelAllowance && (
                    <Select
                      placeholder={
                        selectedTravelAllowance
                          ? selectedTravelAllowance
                          : "Select Travel Allowance"
                      }
                      keyName={"configurationName"}
                      options={allTravelAllowance}
                      controlFunc={e =>
                        handleTravelSelector(e, "travelAllowance")
                      }
                    />
                  )}
                </div>
                {/* Default Manager */}
                <div className="form-group custom labelup">
                  <label>Default Manager1</label>
                <AsyncSelect
                    isClearable={true}
                    noOptionsMessage={({ inputValue }) => inputValue ? 'No results.' : "Start typing to search..."}
                    loadOptions={props.handleDefaultSearchManager}
                    value={state.defaultManager1}
                    placeholder="Search And Select"
                    onChange={(event) => selectDefaultManager(event, 'defaultManager1')}
                  />
                </div>
                <div className="form-group custom labelup">
                <label>Default Manager2</label>
                <AsyncSelect
                    isClearable={true}
                    noOptionsMessage={({ inputValue }) => inputValue ? 'No results.' : "Start typing to search..."}
                    loadOptions={props.handleDefaultSearchManager}
                    value={state.defaultManager2}
                    placeholder="Search And Select"
                    onChange={(event) => selectDefaultManager(event,  'defaultManager2')}
                  />
                </div>
                <div className="form-group custom">
                  <Input
                    label="Email"
                    type="email"
                    name="email"
                    imp="true"
                    value={state.email}
                    error={error.message("email", state.email, "required|email")}
                    controlFunc={e => staticHandle(e)}
                  />
                </div>
                <DynamicForm
                  {...props}
                  validator={props.validator}
                  policyForm={props.expenseForm}
                  onClickHandler={props.onClickHandler}
                  onChangeHandler={props.onChangeHandler}
                  onDatePickerHandler={props.onDatePickerHandler}
                  onCheckboxHandler={props.onCheckboxHandler}
                  onIntegerHandler={props.onIntegerHandler}
                  userPermission={props.userPermission}
                  onConnectedListkHandler={props.onConnectedListkHandler}
                  onLoadingOptions={props.onLoadingOptions}
                />
                <div className="content mb-4">
                  <div className="right">
                    <label className="switch">
                      <input
                        disabled={isVerifiedInvitation}
                        type="checkbox"
                        value={invite}
                        checked={invite}
                        onChange={e => inviteHandler(invite)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  <span>Send invite email</span>
                </div>
                <div className="content">
                  <div className="right">
                    <label className="switch">
                      <input
                        type="checkbox"
                        value={isApprover}
                        checked={isApprover}
                        onChange={e => inviteHandlerApprover(isApprover)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  <span>Is Approver</span>
                </div>
              </div>
            </div>
            <div className={(allDelegatedUsers.length !== 0) ? "delegate-switch" : "delegate-switch d-none"}>
              <h5 className="sub-heading mt-5">Delegate</h5>
              <div className="delegate">
                <div className="names">
                  <div className="search-custom">
                    <input
                      type="text"
                      name="searchUser"
                      onChange={searchUser}
                      className="form-control"
                      placeholder="Search User"
                    />
                    <span><i className="material-icons">search</i></span>
                  </div>
                  <div className="scroll">
                    <ul>
                      {userList && (
                        userList.map((item, key) => {
                          return (
                            <li
                              key={key}
                              className={
                                delegatedItem === item.id ? "active" : ""
                              }
                              onClick={() => chooseDelegate(item)}
                            >
                              <div className="shape-name">
                                <div>
                                  {(item.profileImage) ?
                                    <div className="left img" style={{ backgroundImage: `url(${item.profileImage})` }}></div>
                                    :
                                    <div className="left user-icon">
                                      <i className="material-icons"> person </i>
                                    </div>
                                  }
                                </div>
                                <div className="right">
                                  {/* !! Will be used once the fieldData gets fixed */}
                                  {/* <h6 className="heading">{item.fieldData[0].fieldValue + item.fieldData[1].fieldValue}</h6> */}
                                  <h6 className="heading">{item.email}</h6>
                                  <p className="text">{item.fullName}</p>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                            </li>
                          );
                        })
                      )}
                    </ul>
                  </div>
                </div>
                <InfiniteScroll className="detail">
                  <div className="">
                    <h6 className="sub-heading light">Delegated</h6>
                    <div className="scroll">
                      {allDelegatedUsers.length ?
                        allDelegatedUsers.map((item, idx) => {
                          return (
                            <div className="all-detail" key={item.parentUserId}>
                              <div className="shape-name lg">
                                <div>
                                  {item.profileImage && item.profileImage ?
                                    <div className="left img" style={{ backgroundImage: `url(${item.profileImage})` }}></div>
                                    :
                                    <div className="left user-icon" >
                                      <i className="material-icons"> person </i>
                                    </div>
                                  }
                                </div>
                                <div className="right">
                                  <button
                                    onClick={() => removeDelegateHandler(item)}
                                    className="btn btn-nothing fs-20 float-right"
                                  >
                                    &times;
                                  </button>
                                  <h6 className="heading">{(item.parentUserId) ? item.parentUserId.email : item.email}</h6>
                                  <p className="text">{(item.parentUserId) ? item.parentUserId.fullName : item.fullName}</p>
                                </div>
                                <div className="clearfix"></div>
                              </div>
                              <form className="b-1">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group custom labelup white  textarea">
                                      <label>Access Type</label>
                                      {
                                        accessList && (
                                          <SelectNew
                                            value={accessList ? getAccessListValues(item.accessType) : ""}
                                            name="accessType"
                                            isMulti
                                            isSearchable
                                            options={accessList}
                                            onChange={(e) => props.changeData(e, "accessType", item)}
                                            required
                                          />
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group custom labelup white textarea">
                                      <label>Service</label>
                                      {
                                        serviceOptions && (
                                          <SelectNew
                                            value={item.serviceModule ? getServiceListValue(item.serviceModule) : ''}
                                            name="service"
                                            isMulti
                                            isSearchable
                                            options={serviceOptions}
                                            onChange={(e) => props.changeData(e, "service", item)}
                                            required
                                          />
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group custom labelup">
                                      <label>Start date</label>
                                      <DayPickerInput
                                        dayPickerProps={{
                                          disabledDays: {
                                            before: new Date(),
                                          },
                                        }}
                                        selectedDays={item.startDate}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        value={
                                          item.startDate
                                            ? `${formatDate(
                                              new Date(item.startDate),
                                              "LL",
                                              "it"
                                            )}`
                                            : ""
                                        }
                                        placeholder="DD MM YYYY"
                                        onDayChange={day =>
                                          dayChangeHandler(day, "startDate", item)
                                        }
                                        format="DD-MM-YYYY"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group custom labelup">
                                      <label>End date</label>
                                      <DayPickerInput
                                        dayPickerProps={{
                                          disabledDays: {
                                            before: (item.startDate) ? new Date(item.startDate) : new Date(),
                                          },
                                        }}
                                        selectedDays={item.endDate}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        value={
                                          item.endDate
                                            ? `${formatDate(
                                              new Date(item.endDate),
                                              "LL",
                                              "it"
                                            )}`
                                            : ""
                                        }
                                        placeholder="DD MM YYYY"
                                        onDayChange={day =>
                                          dayChangeHandler(day, "endDate", item)
                                        }
                                        format="DD-MM-YYYY"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          );
                        }) : (
                          <p className="text-center mt-5 pt-5">
                            <i className="material-icons">
                              pages
                          </i>
                            <br />
                            <br />
                            There are no delegates added yet.
                          <br />
                            You can add one or more delegates.
                    </p>
                        )}
                      {/* @nd One for test */}
                      {/* End of second one */}
                    </div>
                  </div>
                </InfiniteScroll>
                <div className="clearfix" />
              </div>
            </div>
            <div className="btn-group-margin mt-5">
              <div className="row">
                <div className="col-xl-6 offset-xl-3 text-center">
                  {/* <button
                    className={(allDelegatedUsers.length === 0) ? "btn" : "btn d-none"}
                    id="delegate-btn"
                    onClick={e => props.openDelegate()}
                  >
                    Add Delegate
                </button> */}
                  {isEdit === false ? (
                    <button
                      onClick={props.onSubmitHandler}
                      className="btn"
                    >
                      Save
              </button>
                  ) : (
                      <button
                        onClick={submitUpdateHandle}
                        className="btn"
                      >
                        Update
              </button>
                    )}
                  <NavLink to="/administration/company/management/list">
                    {" "}
                    <button className="btn">
                      Cancel
              </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};
export default AddUserPage;
