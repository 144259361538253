import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "../../page-not-found/page-not-found-html";
import ReportsPage from "./list";
import NewReportPage from "./new-report-html"
import CustomReportPage from "./custom-report"
class ReportsHomePage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/reports") {
      this.props.history.push("/reports/list");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/reports") {
      this.props.history.push("/reports/list");
    }
  }
  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/reports/list"
            component={ReportsPage}
          />
          <Route
            path="/reports/new-report"
            component={NewReportPage}
          />
          <Route path='/reports/custom-report/:expenseType' component={CustomReportPage} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
    );
  }
}
export default ReportsHomePage;
