import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import CompanyHomePage from "../company/company";
import TravelHomePage from "../travel/travel";
// import RequestHomePage from "../request/request";
import TravelBudgetHomePage from "../budget/budget";
import ExpenseHomePage from "../expense/expense";
import SideMenu from "../common/side-menu";
import HeadMenu from "../common/head-menu";

// import { NavLink } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import PageNotFoundPage from "../../../page-not-found/page-not-found-html";
import { toJS } from "mobx";
import cryptr from "src/_helpers/crypty";

@inject("menuStore")
@observer
class AdminMainPage extends Component {
  constructor(props) {
    super(props);
    if (localStorage["619caa9c"] !== undefined)
    // load default administration menu
    this.props.menuStore.tempStore(localStorage["619caa9c"]); 
    let menu  = JSON.parse(cryptr.decrypt(localStorage["619caa9c"]))  
    menu.forEach(list=>{ 
      if(list.frontEndUrl === '/administration'){ 
        localStorage.setItem('5c6fac',list.id)
        this.props.menuStore.getParent(list.id);
      }
    }) 
    // end
    if (localStorage["619caams"] !== undefined)
      this.props.menuStore.tempSubStore(localStorage["619caams"]);
    if (localStorage["4cab8c"] !== undefined)
      this.props.menuStore.getCustom(localStorage["4cab8c"]);
    this.state = {
      mainUrl: '',
      srcUrl:''
    }
  }
  componentWillMount() {
    this.redirectSpecficPage() 
  }
  redirectSpecficPage = () =>{ 
    if (this.props.menuStore.menuItems !== undefined) {
      this.props.menuStore.menuItems.forEach(list => {
        if (this.props.menuStore.parentId === list.id) {
          this.setState({
            srcUrl:list.frontEndUrl
          })
          if (this.props.location.pathname === list.frontEndUrl) {
            this.props.history.push(list.children[0].frontEndUrl)
            this.setState({
              mainUrl: list.children[0].frontEndUrl
            })
          } else {
            this.props.history.push(this.props.location.pathname)
          }
        }
      })
    }
  }   
  componentWillUpdate(){  
    if(this.state.srcUrl === window.location.pathname){
      this.props.history.push(this.state.mainUrl)
    }
  }

  render() {
    const { menuStore } = this.props;
    var menuItem = [];
    var subMenu = [];
    menuStore.menuItems &&
      menuStore.menuItems.forEach(element => {
        if (menuStore.parentId == element.id) {
          menuItem = element;
        }
      });
    menuStore.subMenuItem &&
      (menuStore.subMenuItem !== "" && menuStore.subMenuItem !== undefined)
      ? (subMenu = menuStore.subMenuItem)
      : (subMenu = "");
    return (
      <div className="AdminPage">
        {/* <div className="admin-resp-show">
          <h2>Please use Desktop to Access Admin settings.</h2>
        </div> */}
        {menuItem && (
          <SideMenu
            menuItems={menuItem}
            location={this.props.location.pathname}
          />
        )}
        <div className="inner-wrapper">
          <div className="page-content">
            <HeadMenu menuItems={subMenu} />
            <div className="resp-scr">
              <Switch>
                <Route
                  path="/administration/company/"
                  component={CompanyHomePage}
                />
                <Route
                  path="/administration/travel/"
                  component={TravelHomePage}
                />
                <Route
                  path="/administration/travel/:name"
                  component={TravelHomePage}
                />
                {/* <Route
                  path="/administration/request/"
                  component={RequestHomePage}
                /> */}
                {/* <Route
                  path="/administration/request/:name"
                  component={RequestHomePage}
                /> */}
                <Route
                  path="/administration/budget/"
                  component={TravelBudgetHomePage}
                />
                <Route
                  path="/administration/expense/"
                  component={ExpenseHomePage}
                />
                <Route
                  path="/administration/expense/:name"
                  component={ExpenseHomePage}
                />
                <Route path="**" component={PageNotFoundPage} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminMainPage;
