import React, { Component } from "react";
import { Route } from "react-router-dom";
import MyTripListPage from "./list";
import TravelRequestPage from "./travel-request";

class MyTripsPage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/my-trip") {
      this.props.history.push("/my-trip/list");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/my-trip") {
      this.props.history.push("/my-trip/list");
    }
  }
  render() {
    return (
      <div className="BookingPage bg-light-blue">
        <div className="page-content">
          <Route path="/my-trips/list" component={MyTripListPage} />
          <Route
            path="/my-trips/travel-request"
            component={TravelRequestPage}
          />
        </div>
      </div>
    );
  }
}
export default MyTripsPage;
