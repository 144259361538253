import React, { Component } from "react";
import FullDetailsPage from "../flight/_components/full-details";
import TrainSummary from './components/trainsummary';
import HeadingFilter from './components/headingfilter';
import { inject, observer } from 'mobx-react';
import TrainList from './components/trainlist';
import TrainFilter from './components/trainfilter';
import moment from 'moment';
import { getFormattedDateFromIsoString } from "src/utils/util";
import { getValue } from "src/_components/common/lodsh";
import { cartServices } from "src/_services";
import cryptr from "src/_helpers/crypty";
// import queryString from 'query-string';
@inject('trainStore')
@observer
class TrainListPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      trainRequest: [],
      cartCount: 0,
      cardList: [],
      train_uniq_id: '',
      trainAdding: '',
      isVisble: false
    }
  }
  componentDidMount() {
    // const parsed = queryString.parse(this.props.location.search);
    let { params } = this.props.match;
    if (!params.TrainData) {
      let decodedParams = this.getDecodedParams(params);
      let areParamsValid = this.checkParamsValidity(decodedParams);
      if (areParamsValid) {
        this.getTrains();
      }
    } else {
      let decodeParams = params.TrainData.split("|");
      this.createRequestData(decodeParams)
    }
    this.getCartDetail();
    this.getCardList();
  }
  componentDidUpdate() {
    if (sessionStorage.modify !== undefined && sessionStorage.modify) {
      let { params } = this.props.match;
      if (params.TrainData) {
        let decodeParams = params.TrainData.split("|");
        this.createRequestData(decodeParams)
      }
      this.getCartDetail();
      this.getCardList();
      sessionStorage.removeItem("modify");
    }
  }
  addToCart = async (item) => {
    this.setState({ trainAdding: item.train_uniq_id });
    let userId = JSON.parse(cryptr.decrypt(localStorage["440a24"]));
    let request = {
      TRIP_ID: sessionStorage.getItem('TRIPID'),
      type: "train",
      data: item,
      userId: userId.id
    }
    if (this.state.train_uniq_id == item.train_uniq_id) {
      this.removeToCart(item.train_uniq_id)
    } else {
      if (this.state.train_uniq_id !== '') {
        this.removeToCart(item.train_uniq_id)
      }
      let resp = await cartServices.addCartUserData(request);
      if (resp) {
        this.setState({
          train_uniq_id: item.train_uniq_id
        });
        this.getCartDetail();
        this.getCardList();
      }
    }
    this.setState({ trainAdding: '' })
  }
  removeToCart = async (uniqueId) => {
    let request = {
      "TRIP_ID": sessionStorage.getItem('TRIPID'),
      "type": "train",
      "train_uniq_id": uniqueId
    }
    let resp = await cartServices.removeCartUserData(request);
    if (resp) {
      this.setState({ train_uniq_id: "" })
      this.getCartDetail();
      this.getCardList();
    }
  }
  getCartDetail = async () => {
    let tripID = sessionStorage.getItem('TRIPID');
    var resp = await cartServices.getCartListDetail(tripID);
    if (resp) {
      let count = getValue(resp, "data.flightData", []).length + getValue(resp, "data.hotelData", []).length + getValue(resp, "data.carData", []).length + getValue(resp, "data.trainData", []).length;
      let trainData = getValue(resp, "data.trainData", []);
      this.setState({ cartCount: count, train_uniq_id: getValue(trainData[0], "train_uniq_id", "") });
    }
  };
  getCardList = async () => {
    let tripID = sessionStorage.getItem('TRIPID');

    var resp = await cartServices.getCartUserList(tripID);
    if (resp) {
      this.setState({ cardList: resp })
    }
  };
  createRequestData = (decodeParams) => {
    let bookingInfo = getValue(decodeParams, "[7]").split('&');
    let request = {
      train_booking_type: parseInt(getValue(bookingInfo, '[0]')),
      train_trip_type: parseInt(getValue(bookingInfo, '[1]')),
      train_booking_currency: getValue(bookingInfo, '[2]'),
      train_booking_price: getValue(bookingInfo, '[3]'),
      page: 1,
      limit: 2,
      train_class: getValue(bookingInfo, '[4]'),
      train_from: {
        stationCode: getValue(decodeParams, "[0]"),
        stationCity: getValue(decodeParams, "[1]"),
        stationName: getValue(decodeParams, "[2]"),
      },
      train_to: {
        stationCode: getValue(decodeParams, "[3]"),
        stationCity: getValue(decodeParams, "[4]"),
        stationName: getValue(decodeParams, "[5]"),
      },
      train_start_timestamp: new Date(getValue(decodeParams, "[6]"))
    }
    if (request.train_trip_type === 1) {
      request.train_return_timestamp = new Date(getValue(decodeParams, "[8]"));
    } else {
      request.train_return_timestamp = '';
    }
    this.props.trainStore.setSearchDataForRequest(request)
    this.props.trainStore.getTrainsList(request);
    this.setState({
      trainRequest: request,
      isVisble: true
    });
  }

  getDecodedParams = (params) => {
    let copiedParams = { ...params };
    try {

      for (let key in params) {
        if (params.hasOwnProperty(key)) {
          copiedParams[key] = decodeURIComponent(params[key]);
        }
      }
      return copiedParams;

    } catch (err) {
      return params;
    }
  }

  checkParamsValidity = (params) => {

    if (params.fromLocationName && params.fromLocationCode && params.toLocationName && params.toLocationCode && params.departureDate) {
      this.props.trainStore.setSearchData(params);
      return true;
    } else {
      this.props.history.push('/booking/train');
    }
  }

  getTrains = () => {
    this.props.trainStore.getTrains();
  }

  render() {
    return (
      <div>
        <FullDetailsPage
          cartCount={this.state.cartCount}
          cardList={this.state.cardList}
          trainRequest={this.state.trainRequest}
          typeOfService="train"
          type="train" />
        <br />
        <div className="p-padding mt-4">
          {this.state.isVisble &&
            <TrainSummary trainRequest={this.state.trainRequest}
              count={this.props.trainStore?.trainResults?.count} />}
          <div className="clearfix" style={{ clear: "both" }} />
          <div className="row">
            <div className="col-md-10">
              <HeadingFilter />
              <TrainList addToCart={this.addToCart}
                train_uniq_id={this.state.train_uniq_id}
                trainAdding={this.state.trainAdding}
                bookType={(getValue(this.state, "trainRequest.train_booking_type") === 0) ? "Request" : "Online"}
                tripType={(getValue(this.state, "trainRequest.train_trip_type") === 0) ? "Oneway" : "Round"}
              />
            </div>
            <div className="col-md-2">
              <TrainFilter />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TrainListPage;
