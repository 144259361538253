import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import DayPicker, { DateUtils } from "react-day-picker";
import DatePicker from "react-datepicker";
import { bookingServices } from "../../../../../_services/booking.service";
import cryptr from "../../../../../_helpers/crypty";
import { toast } from "react-toastify";
import MultiCity from "./multiAddRemove";
import $ from "jquery";
import Moment from "react-moment";
import moment from 'moment';
class FlightBookingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      req: (this.props.match.params.data) ? JSON.parse(cryptr.decrypt(this.props.match.params.data)) : [],
      selectedDay: null,
      from: "",
      to: "",
      classType: "Economy",
      fromDate: null,
      toDate: null,
      airdetails: [],
      loadingCities: false,
      type: this.props.currentPageName === 'multi' ? 3 : this.props.currentPageName === 'round' ? 2 : 1,
      flightType: this.props.currentPageName,
      routine: false,
      activeInput: 0,
      companyId: localStorage["440a28"]
    };
  }

  airdetails = [{
    cityNameFrom: "", codeFrom: "", airportFrom: "", cityNameTo: "", codeTo: "", airportTo: "",
    from: "", to: "", departureDate: null, classType: "Economy", swap: true, count: 0
  }, {
    cityNameFrom: "", codeFrom: "", airportFrom: "", cityNameTo: "", codeTo: "", airportTo: "",
    from: "", to: "", departureDate: null, classType: "Economy", swap: true, count: 0
  }
  ]
  listClass = [
    { id: 1, value: "Economy" },
    { id: 2, value: "Premium Economy" },
    { id: 4, value: "Business" },
    { id: 5, value: "First Class" }
  ];
  popularCity = [
    {
      code: "BLR",
      cityCode: "BLR",
      city: "Bengaluru",
      country: "IN",
      name: "Kempegowda Intl Arpt",
      id: "5c92438a3b4065092b532c4b"
    },
    {
      code: "PNQ",
      cityCode: "PNQ",
      city: "Pune",
      country: "IN",
      name: "Lohegaon Arpt",
      id: "5c92438b3b4065092b533b0a"
    },
    {
      code: "DEL",
      cityCode: "DEL",
      city: "Delhi",
      country: "IN",
      name: "Delhi Indira Gandhi Intl",
      id: "5c92438a3b4065092b532f5b"
    },
    {
      code: "MAA",
      cityCode: "MAA",
      city: "Chennai",
      country: "IN",
      name: "Chennai Arpt",
      id: "5c92438a3b4065092b532e00"
    }
  ];
  changeWay(type) {
    this.setState({ type: type })
    // console.log(this.state)
    if (type === 3) {
      this.setState({
        airdetails: this.airdetails,
        flightType: 'multi'
      })
    } else if (type === 2) {
      this.setState({ routine: true, flightType: 'round' })
    } else {
      this.setState({ flightType: 'oneway' })
    }
    // this.toLocation = "-";
    // this.formLocation = "-";
    // this.setState({
    //   selectedDay: null,
    //   from: "",
    //   to: "",
    //   classType: "Economy",
    //   fromDate: null,
    //   toDate: null,
    // })
  }
  showInput = e => {
    const { routine } = this.state;
    if (!routine && e === 4) {
      return;
    } else {
      this.setState({ activeInput: e })
    }
    $("#search" + e).focus();
  };
  locationFromRef = React.createRef();
  locationToRef = React.createRef();
  dateFromRef = React.createRef();
  dateToRef = React.createRef();
  classTypeRef = React.createRef();

  componentDidMount = async () => {
    let type = this.props.currentPageName === 'multi' ? 3 : this.props.currentPageName === 'round' ? 2 : 1;
    this.setState({ type: type, flightType: this.props.currentPageName });
    this.props.currentPageName === 'multi' && this.changeWay(3);
    if (this.props.match.path === "/booking/flight/round/:data" ||
      this.props.match.path === "/booking/flight/oneway/:data" ||
      this.props.match.path === "/booking/flight/multi/:data") {
      if (this.props.match.path === "/booking/flight/round/:data") {
        await this.setState({ routine: true, type: 2 })
      } else if (this.props.match.path === "/booking/flight/oneway/:data") {
        await this.setState({ type: 1 })
      } else if (this.props.match.path === "/booking/flight/multi/:data") {
        await this.setState({ type: 3 })
      }
      this.updateState(JSON.parse(cryptr.decrypt(this.props.match.params.data)))
    } else {
      sessionStorage.removeItem("tripType");
      // sessionStorage.removeItem("TRIPID");
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillReceiveProps(nextState) {
    let type = nextState.currentPageName === 'multi' ? 3 : nextState.currentPageName === 'round' ? 2 : 1;
    if (nextState.currentPageName !== undefined && nextState.currentPageName !== '' && nextState.currentPageName !== '') {
      this.setState({ type: type, flightType: nextState.currentPageName });
      nextState.currentPageName === 'multi' && this.changeWay(3);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  updateState = (req) => {
    this.formLocation = {
      code: req.from,
      city: req.fromCity,
      name: req.from
    };
    this.toLocation = {
      code: req.to,
      city: req.toCity,
      name: req.to
    };
    this.setState({
      req: req,
      selectedDay: req.departureDate,
      from: req.fromCity,
      to: req.toCity,
      fromDate: req.departureDate,
      toDate: req.returnDate,
      classType: req.classType,
      airdetails: (this.state.type === 3) ? req : this.airdetails,
      loadingCities: false,
      routine: false,
      activeInput: 0
    })
  }

  handleClickOutside = e => {
    const locationFromPopup = this.locationFromRef.current;
    const locationToPopup = this.locationToRef.current;
    const dateFromPopup = this.dateFromRef.current;
    const dateToPopup = this.dateToRef.current;
    const classPopup = this.classTypeRef.current;
    const { activeInput } = this.state;
    if (activeInput === 1 && this.locationFromRef && !locationFromPopup.contains(e.target)) {
      this.activeMultiInputFrom = [];
      this.setState({ activeInput: 0 })
      return;
    }
    if (activeInput === 2 && this.locationToRef && !locationToPopup.contains(e.target)) {
      this.activeMultiInputTo = []
      this.setState({ activeInput: 0 })
      return;
    }
    if (activeInput === 3 && this.dateFromRef && !dateFromPopup.contains(e.target)) {
      this.activeMultiInputDepart = [];
      this.setState({ activeInput: 0 })
      return;
    }
    if (activeInput === 4 && this.dateToRef && !dateToPopup.contains(e.target)) {
      this.setState({ activeInput: 0 })
      return;
    }
    if (activeInput === 5 && this.classTypeRef && !classPopup.contains(e.target)) {
      this.setState({ activeInput: 0 })
      return;
    }
  };

  // End of Om modifications
  toDateValid = new Date();
  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    this.setState({
      selectedDay: modifiers.selected ? undefined : day,
      fromDate: modifiers.selected ? undefined : day,
      toDate: ''
    });
    this.toDateValid = day
    this.setState({ activeInput: 0 })
    if (this.toDateValid != null && this.formLocation === "-" && this.toLocation === "-") {
      this.showInput(1);
    }
    else if (this.toDateValid != null && this.formLocation != "-" && this.toLocation === "-") {
      this.showInput(2);
    }
    else if (this.state.type == 2 && this.toDateValid != null && this.formLocation != "-" && this.toLocation != "-" && this.state.toDate == null) {
      this.showInput(4);
    }
  };

  handleDayClickTo = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    this.setState({
      toDate: modifiers.selected ? undefined : day
    });
    this.setState({ activeInput: 0 })
    if (this.state.fromDate != null && this.formLocation != "-" && this.toLocation != "-" && this.state.toDate != null) {
      // this.showInput(5);
    }
  };

  changeClass = e => {
    localStorage.setItem("classType",e.value)
    this.setState({
      classType: e.value,
      activeInput: 0
    });
  };

  listSearchCities = [];
  handleLocation = async (e, type) => {
    this.listSearchCities = [];
    if (type === 1) {
      this.setState({
        to: e.target.value
      });
    } else {
      this.setState({
        from: e.target.value
      });
    }
    var val = e.target.value;
    if (val !== "" && val.length > 2) {
      this.setState({ loadingCities: true })
      var resp = await bookingServices.getSuggestCities(val);
      if (resp) {
        this.listSearchCities = resp;
      }
      this.setState({ loadingCities: false })
    }
  };
  formLocation = "-";
  getFromCity = e => {
    this.setState({ activeInput: 0 })
    this.listSearchCities = [];
    if (this.toLocation.code === e.code) {
      return;
    }
    this.formLocation = e;
    this.setState({
      from: e.city
    });
    if (this.toLocation === "-") {
      this.showInput(2);
    }
    if (this.toLocation !== "-" && this.state.selectedDay == null) {
      this.showInput(3);
    }
  };
  toLocation = "-";
  getToCity = e => {
    this.setState({
      activeInput: 0
    })
    this.listSearchCities = [];
    if (this.formLocation.code === e.code) {
      return;
    }
    this.toLocation = e;
    this.setState({
      to: e.city
    });
    if (this.formLocation === "-") {
      this.showInput(1);
    }
    if (this.formLocation != "-" && this.toLocation != "-" && this.state.selectedDay == null) {
      this.showInput(3);
    }
  };
  swap = false;
  changeHandle = () => {
    this.swap = !this.swap;
    var from = this.formLocation;
    var to = this.toLocation;
    this.formLocation = to;
    this.toLocation = from;
    this.setState({
      from: this.formLocation.city,
      to: this.toLocation.city
    });
  };
  addReturn = () => {
    this.setState({
      type: 2, routine: true
    })
  };
  routine = false;
  removeReturn = () => {
    this.setState({
      toDate: null
    });
    this.setState({
      routine: false,
      type: 1,
      activeInput: 0
    })
  };
  validateTrip = false;
  searchLocation = async () => {
    this.setState({ loading: true });
    localStorage.setItem("classType",this.state.classType)
    this.validateTrip = true;
    const { from, to, fromDate, toDate, type } = this.state;
    if (this.toLocation === "-" || this.formLocation === "-") {
      if (this.toLocation === "-") {
        this.setState({ to: '' })
      }
      if (this.formLocation === "-") {
        this.setState({ from: '' })
      }
      return
    }
    if (type === 1) {
      if (from && to && fromDate) {
        let newFromDate = new Date(fromDate);
        let modifiedFromDate = newFromDate.setTime(newFromDate.getTime() + 1000 * 60);
        var req = {
          from: this.formLocation.code,
          to: this.toLocation.code,
          fromCity: this.formLocation.city,
          toCity: this.toLocation.city,
          departureDate: moment(modifiedFromDate).utc().local().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          classType: this.state.classType,
          adults: "1"
        };
        if (sessionStorage.getItem('TRIPID') == null) {
          let newReq = await bookingServices.getTripId();
          sessionStorage.setItem("TRIPID", newReq.TRIP_ID);
        } else {
          sessionStorage.setItem("modify", true);
          sessionStorage.setItem("req", cryptr.encrypt(JSON.stringify(req)));
          $(".close").click();
        }
        var resp = cryptr.encrypt(JSON.stringify(req));
        localStorage.setItem("15c059", resp);

        $(".close").click();
        this.props.history.push("/booking/flight/oneway/" + resp);
      }
    } else if (type === 2) {
      if (from && to && fromDate && toDate) {
        let newFromDate = new Date(fromDate);
        let modifiedFromDate = newFromDate.setTime(newFromDate.getTime() + 1000 * 60);
        var req = {
          from: this.formLocation.code,
          to: this.toLocation.code,
          fromCity: this.formLocation.city,
          toCity: this.toLocation.city,
          classType: this.state.classType,
          departureDate: moment(modifiedFromDate).utc().local().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          returnDate: moment(toDate).utc().local().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          adults: "1"
        };
        if (sessionStorage["TRIPID"] === undefined) {
          let newReq = await bookingServices.getTripId();
          sessionStorage.setItem("TRIPID", newReq.TRIP_ID);
        } else {
          sessionStorage.setItem("modify", true);
          sessionStorage.setItem("req", cryptr.encrypt(JSON.stringify(req)));
          $(".close").click();
        }
        var resp = cryptr.encrypt(JSON.stringify(req));
        localStorage.setItem("15c059", resp);
        $(".close").click();
        this.props.history.push("/booking/flight/round/" + resp);
      }
    }
    this.setState({ ...this.state })
  };
  // multi city code starts
  handleAddCity = () => {
    if (this.state.airdetails.length > 4) {
      toast.error("You reached the max limit");
    } else {
      this.setState({
        airdetails: this.state.airdetails.concat([
          {
            cityNameFrom: "",
            codeFrom: "",
            airportFrom: "",
            cityNameTo: "",
            codeTo: "",
            airportTo: "",
            from: "",
            to: "",
            classType: "Economy",
            departureDate: null,
            swap: true,
            count: 0
          }
        ])
      });
    }
  };

  handleRemoveCity = idx => () => {
    this.setState({
      airdetails: this.state.airdetails.filter((s, sidx) => idx !== sidx)
    });
    if (this.state.airdetails.length === 2) {
      this.setState({ type: 1 })
    }
  };
  activeMultiInputFrom = [];
  showMultiInputFrom = (e, key) => {
    this.activeMultiInputFrom = [key];
    this.activeMultiInputTo = [];
    this.activeMultiInputDepart = [];
    this.setState({ ...this.state })
  };
  activeMultiInputTo = [];
  showMultiInputTo = (e, key) => {
    this.activeMultiInputTo = [key];
    this.activeMultiInputFrom = [];
    this.activeMultiInputDepart = [];
    this.setState({ ...this.state })
  };
  handleNameChange = idx => evt => {
    const newShareholders = this.state.airdetails.map((condition, sidx) => {
      if (idx !== sidx) return condition;
      return { ...condition, [evt.target.name]: evt.target.value };
    });
    this.setState({ condition: newShareholders });
  };
  handleMultiLocation = async (e, type, idx) => {
    const newShareholders = this.state.airdetails.map((condition, sidx) => {
      if (idx !== sidx) return condition;
      return { ...condition, [e.target.name]: e.target.value };
    });
    this.setState({ airdetails: newShareholders });
    var val = e.target.value;
    if (val !== "" && val.length > 2) {
      this.setState({ loadingCities: true })
      var resp = await bookingServices.getSuggestCities(val);
      if (resp) {
        this.listSearchCities = resp;
      }
      this.setState({ loadingCities: false })
    }
  };
  getFromMultiCity = (e, key) => {
    this.activeMultiInputFrom = [];
    this.listSearchCities = [];
    this.state.airdetails[key]["cityNameFrom"] = e.city;
    this.state.airdetails[key]["from"] = e.code;
    this.state.airdetails[key]["airportFrom"] = e.name;
    this.state.airdetails[key]["codeFrom"] = e.code;
    this.setState({
      ...this.state
    }, () => {
    });
  };
  getToMultiCity = (e, key) => {
    this.activeMultiInputTo = [];
    this.listSearchCities = [];
    this.state.airdetails[key]["cityNameTo"] = e.city;
    this.state.airdetails[key]["to"] = e.code;
    this.state.airdetails[key]["airportTo"] = e.name;
    this.state.airdetails[key]["codeTo"] = e.code;
    if (this.state.airdetails.length - 1 > key) {
      this.state.airdetails[key + 1]["cityNameFrom"] = e.city;
      this.state.airdetails[key + 1]["from"] = e.code;
      this.state.airdetails[key + 1]["airportFrom"] = e.name;
      this.state.airdetails[key + 1]["codeFrom"] = e.code;
    }
    this.setState({
      ...this.state
    });
  };
  changeClassMulti = (e, key) => {
    this.activeMultiInputClass = [];
    localStorage.setItem("classType",e.value)
    this.setState({
      classType: e.value
    })
  }
  activeMultiInputDepart = [];
  showMultiInputDepart = (e, key) => {
    this.activeMultiInputDepart = [key];
    this.activeMultiInputFrom = [];
    this.activeMultiInputTo = [];
    this.setState({ ...this.state })
  };
  activeMultiInputClass = [];
  showInputMultiClass = (e, key) => {
    this.activeMultiInputClass = [key];
    this.setState({ ...this.state });
  }
  handleDayClickDepart = (day, key) => {
    this.state.airdetails[key]["departureDate"] = moment(day).format();
    this.setState({
      ...this.state
    });
    this.activeMultiInputDepart = [];
    this.setState({ ...this.state })
  };
  validateTrue = false;
  searchMultiCity = async () => {
    this.validateTrue = true;
    if (this.isEmpty(this.state.airdetails)) {
      // Object is not empty (Would return true in this example)
      this.state.airdetails.find(cont => cont.classType = this.state.classType ? this.state.classType : 'Economy');
      var resp = cryptr.encrypt(JSON.stringify(this.state.airdetails));
      localStorage.setItem("15r359", resp);
      if (sessionStorage["TRIPID"] === undefined) {
        let newReq = await bookingServices.getTripId();
        sessionStorage.setItem("TRIPID", newReq.TRIP_ID);
      } else {
        sessionStorage.setItem("modify", true);
        sessionStorage.setItem("req", cryptr.encrypt(JSON.stringify(this.state.airdetails)));
        sessionStorage.setItem('multicitySearch', resp);
        $(".close").click();
      }
      $(".close").click();
      this.props.history.push("/booking/flight/multi/" + resp);
    }
    this.setState({ ...this.state })
  };
  isEmpty = arr => {
    let arrValues = arr.map(item => {
      return [...Object.values(item)];
    });
    var myNewArray = [].concat(...arrValues);
    if (myNewArray.indexOf("") === -1 && myNewArray.indexOf(null) === -1) {
      return true;
    } else {
      return false;
    }
  };
  changeHandleMul = key => {
    const { airdetails } = this.state;
    airdetails[key].swap = !airdetails[key].swap;
    var from = airdetails[key].to;
    var to = airdetails[key].from;
    var cityNameFrom = airdetails[key].cityNameTo;
    var codeFrom = airdetails[key].codeTo;
    var airportFrom = airdetails[key].airportTo;
    var cityNameTo = airdetails[key].cityNameFrom;
    var codeTo = airdetails[key].codeFrom;
    var airportTo = airdetails[key].airportFrom;
    const obj = {}
    obj.airdetails[key].from = from;
    obj.airdetails[key].to = to;
    obj.airdetails[key].cityNameFrom = cityNameFrom;
    obj.airdetails[key].codeFrom = codeFrom;
    obj.airdetails[key].airportFrom = airportFrom;
    obj.airdetails[key].cityNameTo = cityNameTo;
    obj.airdetails[key].codeTo = codeTo;
    obj.airdetails[key].airportTo = airportTo;
    this.setState({
      ...obj
    });
  };
  render() {
    const { fromDate, toDate, selectedDay, classType, activeInput, type } = this.state;
    const today = new Date();
    const typeItem = null;
    return (
      <div className="book-details">
        <ul className="trip-way">
          {this.props.cartCount <= 0 || this.props.cartCount === '' || this.props.cartCount === undefined ?
            <>
              {(typeItem === 'oneway' || typeItem === undefined || typeItem === null) &&
                <li>
                  <button
                    type="button"
                    className={type === 1 ? "btn active" : "btn"}
                    onClick={e => this.changeWay(1)}
                  >
                    One Way
            </button>
                </li>}
              {(typeItem === 'round' || typeItem === undefined || typeItem === null) &&
                <li>
                  <button
                    type="button"
                    className={type === 2 ? "btn active" : "btn"}
                    onClick={e => this.changeWay(2)}
                  >
                    {" "}
                    Round Trip
                  </button>
                </li>
              }
              {localStorage.getItem("440a28") !== "5dc5197e1382a2538aa3a8ca" ? ((typeItem === 'multi' || typeItem === undefined || typeItem === null) &&
                <li>
                  <button
                    type="button"
                    className={type === 3 ? "btn active" : "btn"}
                    onClick={e => this.changeWay(3)}
                  >
                    {" "}
                    Multi City
            </button>
                </li>
              ):""}
            </>
            :
            <>
              {this.state.flightType === 'oneway' &&
                <li>
                  <button
                    type="button"
                    className={type === 1 ? "btn active" : "btn"}
                    onClick={e => this.changeWay(1)}
                  >
                    One Way
            </button>
                </li>}
              {this.state.flightType === 'round' &&
                <li>
                  <button
                    type="button"
                    className={type === 2 ? "btn active" : "btn"}
                    onClick={e => this.changeWay(2)}
                  >
                    {" "}
                    Round Trip
            </button>
                </li>
              }
              {/* {localStorage.getItem("440a28") !== "5dc5197e1382a2538aa3a8ca" ? ((typeItem === 'multi' || typeItem === undefined || typeItem === null) &&
                <li>
                  <button
                    type="button"
                    className={type === 3 ? "btn active" : "btn"}
                    onClick={e => this.changeWay(3)}
                  >
                    {" "}
                    Multi City
            </button>
                </li>
              ):""} */}
            </>
          }
        </ul>
        {(type === 1 || type === 2) && (
          <div>
            {/* search setup starts here */}
            <div
              className={
                activeInput !== 1
                  ? "details from"
                  : "details from show-popup"
              }
              ref={this.locationFromRef}
            >
              <div
                className={
                  this.validateTrip && this.state.from === ""
                    ? "details-margin not-valid"
                    : "details-margin"
                }
                onClick={e => this.showInput(1)}
              >
                <div className="d-table d-table__middle">
                  <label htmlFor="search1" className="text-left m-label">
                    <p className="label">From</p>
                    <h6 className="place">
                      {this.formLocation === "-"
                        ? "Where From?"
                        : this.formLocation.city}
                    </h6>
                    <p className="bottom">
                      {this.formLocation === "-" ? "" : this.formLocation.name}
                    </p>
                  </label>
                </div>
              </div>
              <div
                onClick={this.changeHandle}
                className={this.swap ? "swap" : "swap active"}
              >
                <i className="material-icons">swap_horiz</i>
              </div>
              <div className="popup">
                <div className="typ">
                  <input
                    id="search1"
                    type="text"
                    name="from"
                    autoFocus
                    value={this.state.from}
                    onChange={e => this.handleLocation(e, 2)}
                    placeholder="Where from?"
                  />
                  <div className="pin">
                    <i className="material-icons">place</i>
                  </div>
                </div>
                {(!this.state.loadingCities) ?
                  <div>
                    {this.listSearchCities.length !== 0 && (
                      <ul className="scroll">
                        {(this.toLocation.code !== this.listSearchCities[0].code) &&
                          <li className="popup__head">
                            <button type="button">Suggested Cities</button>
                          </li>
                        }
                        {this.listSearchCities.map((items, key) => {
                          if (items.code !== this.toLocation.code) {
                            return (
                              <li key={key}>
                                <button
                                  type="button"
                                  onClick={e => this.getFromCity(items)}
                                >
                                  {items.city}&nbsp;
                                <small>
                                    ({items.code}-{items.name})
                                </small>
                                </button>
                                <div className="pin">
                                  <i className="material-icons">flight</i>
                                </div>
                              </li>
                            );
                          }
                        })}
                        {(this.listSearchCities.length === 1 && this.toLocation.code === this.listSearchCities[0].code) &&
                          <li className="popup__head">
                            <button type="button">No results found!</button>
                          </li>
                        }
                      </ul>
                    )}
                    {this.listSearchCities.length === 0 && (
                      <ul className="scroll">
                        <li className="popup__head">
                          <button type="button">Popular Cities</button>
                        </li>
                        {this.popularCity.map((items, key) => {
                          if (items.code !== this.toLocation.code) {
                            return (
                              <li key={key}>
                                <button
                                  type="button"
                                  onClick={e => this.getFromCity(items)}
                                >
                                  {items.city}&nbsp;{" "}
                                  <span className="small__size">
                                    ({items.code}-{items.name})
                                </span>
                                </button>
                                <div className="pin">
                                  <i className="material-icons">flight</i>
                                </div>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    )}
                  </div>
                  :
                  <ul className="scroll">
                    <li className="popup__head">
                      <button type="button">Loading</button>
                    </li>
                  </ul>
                }
              </div>
            </div>

            <div
              className={
                activeInput !== 2 ? "details to" : "details to show-popup"
              }
              ref={this.locationToRef}
            >
              <div
                className={
                  this.validateTrip && this.state.to === ""
                    ? "details-margin not-valid"
                    : "details-margin"
                }
                onClick={e => this.showInput(2)}
              >
                <div className="d-table d-table__middle">
                  <label className="text-left m-label" htmlFor="search2">
                    <p className="label">To</p>
                    <h6 className="place">
                      {this.toLocation === "-"
                        ? "Where To?"
                        : this.toLocation.city}
                    </h6>
                    <p className="bottom">
                      {this.toLocation === "-" ? "" : this.toLocation.name}
                    </p>
                  </label>
                </div>
              </div>
              <div className="popup resp-right">
                <div className="typ">
                  <input
                    type="text"
                    name="to"
                    id="search2"
                    value={this.state.to}
                    onChange={e => this.handleLocation(e, 1)}
                    placeholder="Where to?"
                  />
                  <div className="pin">
                    <i className="material-icons">location_on</i>
                  </div>
                </div>
                {(!this.state.loadingCities) ?
                  <div>
                    {this.listSearchCities.length !== 0 && (
                      <ul className="scroll">
                        {(this.formLocation.code !== this.listSearchCities[0].code) &&
                          <li className="popup__head">
                            <button type="button">Suggested Cities</button>
                          </li>
                        }
                        {this.listSearchCities.map((items, key) => {
                          if (items.code !== this.formLocation.code) {
                            return (
                              <li key={key}>
                                <button
                                  type="button"
                                  onClick={e => this.getToCity(items)}
                                >
                                  {items.city}&nbsp;
                                  <small>
                                    ({items.code}-{items.name})
                                  </small>
                                </button>
                                <div className="pin">
                                  <i className="material-icons">flight</i>
                                </div>
                              </li>
                            );
                          }
                        })}
                        {(this.listSearchCities.length === 1 && this.formLocation.code === this.listSearchCities[0].code) &&
                          <li className="popup__head">
                            <button type="button">No results found!</button>
                          </li>
                        }
                      </ul>
                    )}
                    {this.listSearchCities.length === 0 && (
                      <ul className="scroll">
                        <li className="popup__head">
                          <button type="button">Popular Cities</button>
                        </li>
                        {this.popularCity.map((items, key) => {
                          if (items.code !== this.formLocation.code) {
                            return (
                              <li key={key}>
                                <button
                                  type="button"
                                  onClick={e => this.getToCity(items)}
                                >
                                  {items.city}&nbsp;{" "}
                                  <span className="small__size">
                                    ({items.code}-{items.name})
                                  </span>
                                </button>
                                <div className="pin">
                                  <i className="material-icons">flight</i>
                                </div>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    )}
                  </div>
                  :
                  <ul className="scroll">
                    <li className="popup__head">
                      <button type="button">Loading</button>
                    </li>
                  </ul>
                }
              </div>
            </div>

            <div
              ref={this.dateFromRef}
              className={
                activeInput !== 3
                  ? "details depart"
                  : "details depart show-popup"
              }
            >
              <div
                className={
                  this.validateTrip &&
                    (this.state.selectedDay === "" ||
                      this.state.selectedDay === null)
                    ? "details-margin not-valid"
                    : "details-margin"
                }
                onClick={e => this.showInput(3)}
              >
                <div className="d-table d-table__middle">
                  <div className="text-left">
                    <p className="label">DEPARTURE DATE</p>

                    <h6 className="place">
                      {this.state.selectedDay ? (
                        <Moment format="DD MMM YYYY">
                          {this.state.selectedDay}
                        </Moment>
                      ) : (
                          "Add departure"
                        )}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="popup date-picker">
                <p className="date__head">Departure date</p>
                {/* new date picker code */}
                <DatePicker
                  inline
                  selected={(selectedDay) ? new Date(selectedDay) : null}
                  onChange={this.handleDayClick}
                  minDate={today}
                  monthsShown={2}
                  // showTimeSelect
                  timeFormat="HH:mm"
                  calendarClassName="double-datepicker react-datepicker__day--outside-month"
                />
                {/* old date picker format */}
                {/* <DayPicker
                  numberOfMonths={2}
                  // month={new Date(selectedDay)}
                  selectedDays={(selectedDay) ? new Date(selectedDay) : null}
                  disabledDays={{ before: today }}
                  onDayClick={this.handleDayClick}
                /> */}
                <div className="p-3">
                  {/* <Select />  */}
                </div>
              </div>
            </div>

            <div
              className={
                activeInput !== 4
                  ? "details return"
                  : "details return show-popup"
              }
              ref={this.dateToRef}
            >
              <div
                className={
                  type === 1
                    ? "details-margin"
                    : this.validateTrip &&
                      (this.state.toDate === "" || this.state.toDate === null)
                      ? "details-margin not-valid"
                      : "details-margin"
                }
                onClick={e => this.showInput(4)}
              >
                <div className="d-table d-table__middle">
                  <div className="text-left">
                    <p className="label">RETURN DATE</p>

                    <h6 className="place">
                      {type === 1 && (
                        <p className="mb-0" onClick={this.addReturn}>
                          -
                        </p>
                      )}
                      {type === 2 && (
                        <p className="mb-0" onClick={this.addReturn}>
                          {this.state.toDate ? (
                            <span>
                              <Moment format="DD MMM YYYY">
                                {this.state.toDate}
                              </Moment>
                              {(typeItem !== 'round' || typeItem === undefined || typeItem === null) &&
                                <i className="material-icons"
                                  onClick={this.removeReturn}
                                >close</i>
                              }
                            </span>
                          ) : (
                              "Add return"
                            )}
                        </p>
                      )}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="popup left-popup date-picker">
                <p className="date__head">Return date</p>
                {/* new date picker */}
                <DatePicker
                  inline
                  selected={(this.state.toDate) ? new Date(this.state.toDate) : null}
                  openToDate={new Date(selectedDay)}
                  onChange={this.handleDayClickTo}
                  minDate={this.toDateValid}
                  monthsShown={2}
                  // showTimeSelect
                  timeFormat="HH:mm"
                  calendarClassName="double-datepicker"
                />
                {/* old date picker  */}
                {/* <DayPicker
                  numberOfMonths={2}
                  month={new Date(selectedDay)}
                  selectedDays={(this.state.toDate) ? new Date(this.state.toDate) : null}
                  disabledDays={{ before: this.toDateValid }}
                  onDayClick={this.handleDayClickTo}
                /> */}
                <div className="p-3">
                  {/* <Select /> */}
                </div>
              </div>
            </div>

            {/* search setup ends here */}

            <div
              className={
                activeInput !== 5
                  ? "details return"
                  : "details return show-popup"
              }
              ref={this.classTypeRef}
            >
              <div className="details-margin" onClick={e => this.showInput(5)}>
                <div className="d-table d-table__middle">
                  <div className="text-left">
                    <p className="label">Class</p>
                    <h6 className="place">{classType}</h6>
                  </div>
                </div>
              </div>
              <div className="popup left-popup classpop">
                <ul>
                  {this.listClass.map((item, key) => {
                    return (
                      <li key={key} onClick={e => this.changeClass(item)}>
                        <button type="button">{item.value}</button>
                        <div className="pin">
                          <i className="material-icons">hotel</i>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <button
              onClick={this.searchLocation}
              className="btn btn-blue details search"
            >
              <i className="material-icons">search</i>
            </button>
            <div className="clearfix" />
          </div>
        )}
        {(type === 3 || this.state.flightType === 'multi') && (
          <div>
            {this.state.airdetails.map((items, idx) => {
              return (
                <div key={idx}>
                  <MultiCity
                    items={items}
                    allList={this.state.airdetails}
                    keys={idx}
                    locationFromRef={this.locationFromRef}
                    locationToRef={this.locationToRef}
                    dateFromRef={this.dateFromRef}
                    showInput={this.showInput}
                    handleRemoveCity={this.handleRemoveCity}
                    activeInput={activeInput}
                    listSearchCities={this.listSearchCities}
                    popularCity={this.popularCity}
                    handleMultiLocation={this.handleMultiLocation}
                    handleNameChange={this.handleNameChange}
                    showMultiInputFrom={this.showMultiInputFrom}
                    activeMultiInputFrom={this.activeMultiInputFrom}
                    getFromMultiCity={this.getFromMultiCity}
                    getToMultiCity={this.getToMultiCity}
                    activeMultiInputTo={this.activeMultiInputTo}
                    showMultiInputTo={this.showMultiInputTo}
                    handleDayClickDepart={this.handleDayClickDepart}
                    today={today}
                    activeMultiInputDepart={this.activeMultiInputDepart}
                    showMultiInputDepart={this.showMultiInputDepart}
                    searchMultiCity={this.searchMultiCity}
                    validateTrue={this.validateTrue}
                    listClass={this.listClass}
                    changeHandleMul={this.changeHandleMul}
                    showInputMultiClass={this.showInputMultiClass}
                    activeMultiInputClass={this.activeMultiInputClass}
                    changeClass={this.changeClassMulti}
                    classType={this.state.classType}
                    loadingCities={this.state.loadingCities}
                  />
                </div>
              );
            })}
            {this.state.airdetails.length <= 4 &&
              <button
                onClick={this.handleAddCity}
                type="button"
                className="btn btn-nothing add-city ml-1"
              >
                Add flight
            </button>
            }

          </div>
        )}
      </div>
    );
  }
}
export default withRouter(FlightBookingPage);
