// @flow

import { authHeader } from "./auth-header";
import { toast } from "react-toastify";
import { createLogger } from "./logger";
import cryptr from "./crypty";
import axios from 'axios';


const httpLogger = createLogger("http");

export const http = {
  get,
  post,
  put,
  patch,
  delete: remove,
  remove,
  fileUpload
};

const getLogger = httpLogger.extend("get");
function get(
  url /*: string */,
  headers /*: any */,
  raw /*: ?boolean */ = false
) {
  const requestOptions = {
    method: "GET",
    headers: headers ? headers : { ...authHeader() }
  };

  getLogger("URL: %s\nOptions: %O", url, requestOptions);
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

const postLogger = httpLogger.extend("post");
function post(
  url /*: string */,
  data /*: mixed */,
  headers = {} /*: any */,
  raw /*: boolean */ = false
) {
  headers = Object.assign({}, authHeader(), headers);
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data)
  };

  postLogger("URL: %s\nOptions: %O", url, requestOptions);
  let responsePromise = fetch(url, requestOptions);
  if (raw) {
    return responsePromise;
  }
  return responsePromise.then(handleResponse).catch(handleError);
}

const putLogger = httpLogger.extend("put");
function put(
  url /*: string */,
  data /*: mixed */,
  headers /*: any */,
  raw /*: ?boolean */ = false
) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader() },
    body: JSON.stringify(data)
  };

  putLogger("URL: %s\nOptions: %O", url, requestOptions);
  let responsePromise = fetch(url, requestOptions);
  if (raw) {
    return responsePromise;
  }
  return responsePromise.then(handleResponse).catch(handleError);
}

const patchLogger = httpLogger.extend("patch");
function patch(
  url /*: string */,
  data /*: mixed */,
  headers /*: any */,
  raw /*: ?boolean */ = false
) {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader() },
    body: JSON.stringify(data)
  };

  patchLogger("URL: %s\nOptions: %O", url, requestOptions);
  let responsePromise = fetch(url, requestOptions);
  if (raw) {
    return responsePromise;
  }
  return responsePromise.then(handleResponse).catch(handleError);
}

const removeLogger = httpLogger.extend("remove");
function remove(
  url /*: string */,
  headers /*: any */,
  raw /*: ?boolean */ = false
) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };

  removeLogger("URL: %s\nOptions: %O", url, requestOptions);
  let responsePromise = fetch(url, requestOptions);
  if (raw) {
    return responsePromise;
  }
  return responsePromise.then(handleResponse).catch(handleError);
}

function fileUpload(
  url /*: string */,
  data /*: mixed */,
  raw /*: ?boolean */ = false
) {
  let userData = localStorage.getItem("440a24");
  if (data !== null) {
    var user = JSON.parse(cryptr.decrypt(userData));
  }
  const requestOptions = {
    method: "POST",
    headers: {
      "x-api-key": "G0OndZH93PuXhhZk",
      Authorization: "Bearer " + user.token
    },
    body: data
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}


function handleError(response) {
  var toastId;
  if (response == "TypeError: Failed to fetch") {
    // to prevent duplicate "internet connection" message setting INET in local.
    if (localStorage['INET'] === "false" && localStorage['SERVER'] === "false") {
      toastId = toast.error("Sorry! Software Update is in Progress");
      localStorage.setItem('SERVER',true);
    }
    return;
  } else if (response.statusCode === 500) {
    if (!toast.isActive(toastId)) {
      toastId = toast.error("Internal server error contact API");
    }
    return;
  } else if (response.statusCode === 401) {
    // auto logout if 401 response returned from api
    localStorage.clear();
    window.location.reload(true);
    return toast.error("Session Expired");
  } else if (response.statusCode === 422) {
    let errors = response;
    if (errors) {
      if (errors.details) {
        // eslint-disable-next-line array-callback-return
        errors.details.map(items => {
          if (!toast.isActive(toastId)) {
            toastId = toast.error(items.code + " " + items.message);
          }
        });
      } else if (errors.message) {
        if (typeof errors.message === "string") {
          if (!toast.isActive(toastId)) {
            toastId = toast.error(errors.message);
          }
        } else {
          Object.keys(errors.message).map(key => {
            if (!toast.isActive(toastId)) {
              return (toastId = toast.error(errors.message[key]));
            }
          });
        }
      } else {
        if (!toast.isActive(toastId)) {
          toastId = toast.error(errors.message);
        }
      }
    }
  } else if (response.statusCode === 404) {
    let errors = response;
    if (!toast.isActive(toastId)) {
      toastId = toast.error(errors.message);
    }
  } else if (response.statusCode === 400) {
    if (response) {
      if (!toast.isActive(toastId)) {
        toast.error(response.message);
      }
    }
  }
}
function handleResponse(response) {
  return response.text().then(text => {
    const data /*: data */ = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }
    localStorage.setItem('SERVER',false);
    return data;
  });
}


