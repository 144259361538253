import { http } from "../_helpers";
import { config } from "../env";

const getPaymentType = data => {
  return http
    .get(config.apiUrlCompany +`list-management?companyId=${data.companyId}&itemCode=PAY001`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getGroupType = data => {
  return http
    .get(config.apiUrlCompany +`list-management?companyId=${data.companyId}&itemCode=GRP001`
    )
    .then(data => {
      return data ? data : "";
    });
};

const getPaymentTypeList=(companyId,data)=>{
  return http
    .get(config.apiExpense+`list-all-payment-types?companyId=${companyId}&page=${data.page}&limit=${data.limit}`)
    .then(data => {
      return data ? data : "";
    });
};

const getSearchList=(companyId,data)=>{
  return http
    .get(config.apiExpense+`list-all-payment-types?companyId=${companyId}&page=${data.page}&limit=${data.limit}&search=${data.search}`)
    .then(data => {
      return data ? data : "";
    });
};

const deletePaymentType=(id)=>{
  return http
    .delete(config.apiExpense+`payment-types/${id}`)
    .then(data => {
      return data ? data : "";
    });
}

const getSelectedPaymentType =(id)=>{ 
  return http
  .get(config.apiExpense+`get-payment-type/${id}`)
  .then(data => {
    return data ? data : "";
  });
}

const editSelectedPaymentType =(id,data)=>{ 
  return http
  .patch(config.apiExpense+`payment-type/${id}`,data)
  .then(data => {
    return data ? data : "";
  });
}

const changeStatus=(id,data)=>{
  return http
  .put(config.apiExpense+`payment-types/active-inactive/${id}`,data)
  .then(data => {
    return data ? data : "";
  });
}

const postPaymentType = (companyId,data )=>{
  return http
    .post(config.apiExpense +`add-payment-type?companyId=${companyId}`,data)
    .then(data => {
      return data ? data : "";
    });
}

export const expensePaymentTypeService = {
  editSelectedPaymentType,
  getSelectedPaymentType,
  getGroupType,
  changeStatus,
    getPaymentType,
    postPaymentType,
    deletePaymentType,
    getPaymentTypeList,
    getSearchList
};
