import React from "react";
import uuid from "uuid";
const Select = props => (
  <div className={"dropdown" + ' ' + props.myClass} key={uuid.v4()}>
    <button
      disabled={props.disabled || false}
      type="button"
      className={"btn dropdown-toggle " + props.myClass + ' ' + props.className}
      data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false"
    >
      {props.placeholder}
    </button>
    <div className="dropdown-menu">
      {props.options && props.options.map((opt, index) => {
        return (
          <button
            type="button"
            onClick={e => props.controlFunc(opt)}
            className="dropdown-item"
            key={index}
          >
            {opt[props.keyName]}
          </button>
        );
      })}
      {!props.options &&
        <button
          type="button"
          className="dropdown-item"
        >
          No Records Found
    </button>
      }
    </div>
    <div className="help-block">{props.error}</div>
  </div>
);

export default Select;
