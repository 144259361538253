import Select from "react-select";
import React, { PureComponent as Component } from "react";
import PropTypes from "prop-types";

export class ExpenseTypePolicy extends Component {
  static propTypes = {
    
    requestEntriesOptions: PropTypes.array,
    expenseTypePolicy: PropTypes.object,
    policy: PropTypes.object,
    edit: PropTypes.bool,
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    // * compare and assign the options from the policy
   

    let updatedRequestEntryValue = this.props.requestEntriesOptions.find(
      option => option.value === this.props.expenseTypePolicy?.expenseEntryFormId
    );

    this.state = {
      requestEntryValue: updatedRequestEntryValue || "",
      assign: this.props.expenseTypePolicy?.assign || false
    };

  };

  handleRequestEntryHandler = selectedOption => {
    this.setState({
      requestEntryValue : selectedOption
    });
  };

  handleAssignChange = ev => {
    this.setState({
      assign: ev.target.checked
    });
    this.updateData()
  };
  // * check if the data has changed from the previous render
  componentDidUpdate(prevProps, prevState) {
    // const data = {
    //   policyId: this.props.policy.id,
    //   expenseEntryFormId: this.state.requestEntryValue.value,
    //   // useVerdorList: "string",
    //   assign: this.state.assign,
    // }; 
    // // if any of the value is changed in edit mode, then onChange is called
    // if (
    //   (
    //     prevState.requestEntryValue !== this.state.requestEntryValue ||
    //     prevState.assign !== this.state.assign) &&
    //   this.props.edit
    // ) {
    //   this.props.onChange(data);
    // } else if (prevState.assign !== this.state.assign && !this.props.edit) {
    //   this.props.onChange(data);
    // }
  }

  updateData = () =>{
    const data = {
      policyId: this.props.policy.id,
      expenseEntryFormId: this.state.requestEntryValue.value,
      // useVerdorList: "string",
      assign: this.state.assign,
    }; 
    this.props.onChange(data); 
  }

  render(){
    const {
      expenseEntriesOptions,
      itemizedEntriesOptions,
      requestEntriesOptions,
      policy,
      onChange,
      name,
      edit,
      ...props
    } = this.props;

  

    const {  requestEntryValue, assign } = this.state;
    let updatedRequestEntryValue = requestEntriesOptions.find(
      option => option.value === requestEntryValue.value
    );

    return (
      <tr {...props}>
        <td>{name}</td>
        <td>
          <div className="form-group mb-0">
            {requestEntriesOptions && (
              <Select
                defaultValue={
                  requestEntryValue || {
                    value: "default",
                    label: "default"
                  }
                }
                options={requestEntriesOptions}
                value={requestEntryValue}
                onChange={this.handleRequestEntryHandler}
              />
            )}
          </div>
        </td>
        <td/>
        <td/>
        <td>
        {(!this.props.hideSetting) ?
           <button onClick={e => this.updateData()} className="btn btn-sm"> Update </button> 
           :
           <button onClick={e => this.updateData()} className="btn btn-sm"> Create </button> 
        }
        </td>
        <td>
          {(!this.props.hideSetting) &&
            <label className="switch">
              <input
                type="checkbox"
                checked={assign}
                onChange={this.handleAssignChange}
              />
              <span className="slider round" />
            </label>
          }
        </td>
      </tr>
    );
  }
}
