import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from 'react-toastify';
import { expenseReport } from "../../../../_services/expenseReport";
import cryptr from "../../../../_helpers/crypty";
import { withRouter } from 'react-router-dom';
import { masterService, userService } from "src/_services";
import { bookingServices } from "src/_services/booking.service";
import NumberFormat from 'react-number-format';
import LatestFlight from "./lastest/latestFlight";
import LatestHotel from "./lastest/latestHotel";
import CompanyNote from '../components/company-note';
import CompanyNoteModal from '../components/company-note-modal';
import moment from 'moment';
class BookingHomeCardPage extends Component {
  state = {
    getDetails: [],
    approvedData: [],
    listApporved: [],
    totalTrip: 0,
    baseCurreny: '',
    companyId: localStorage["440a28"],
    heading: '',
    message: '',
    companyNote: [],
    currentNote: {},
    isCompanyNoteActive: false
  };
  componentDidMount() {
    let currentUser = JSON.parse(
      cryptr.decrypt(localStorage.getItem("440a24"))
    );
    if (currentUser.currency) {
      this.setState({
        baseCurreny: currentUser.currency.currencyCode
      })
    }
    this.setState({ userId: currentUser["id"] });
    this.getInfo()
    this.getMyTrips()
    this.getNote()
  }
  getNote = async () => {
    let resp = await userService.getCompanyNote(this.state.companyId);
    if(resp){
      this.setState({
        companyNote: resp,
        isCompanyNoteActive: resp.find(item => item.activeStatus === true)
      })
    }

  }
  getCurrentNote = (note) => {
    this.setState({ currentNote: note });
  }
  getMyTrips = async () => {
    this.loading = true;
    var resp = await bookingServices.overallCount()
    if (resp) {
      await this.setState({
        totalTrip: resp,
        approvedData: (resp ?.trip ?.approvedData) ? resp ?.trip ?.approvedData : []
      })
      var list = [];
      this.state.approvedData && this.state.approvedData.length &&
        this.state.approvedData.forEach((item, key) => {
          if (list.length < 3 || key == this.state.approvedData.length) {
            if (item.data.flightData) {
              item.data.flightData.forEach(listItem => {
                let momentA = moment(listItem ?.fareContent ?.fare ?.flight_depature_date, 'DD/MM/YYYY');
                let momentB = moment(new Date(), 'DD/MM/YYYY');
                let isUpcomingTravel = Number(momentA.toDate().getTime()) - Number(momentB.toDate().getTime());
                if (list.length < 3 && isUpcomingTravel > 0) {
                  listItem.typeOfItem = "FLIGHT";
                  listItem.departureDateForSorting = momentA.toDate().getTime();
                  list.push(listItem);
                }
              })
            }
            if (item.data.hotelData) {
              item.data.hotelData.forEach(listItem => {
                let momentA = moment(listItem ?.from, 'YYYY-MM-DD');
                let momentB = moment(new Date(), 'YYYY-MM-DD');
                let isUpcomingTravel = Number(momentA.toDate().getTime()) - Number(momentB.toDate().getTime());
                if (list.length < 3 && isUpcomingTravel > 0) {
                  listItem.typeOfItem = "HOTEL";
                  listItem.departureDateForSorting = momentA.toDate().getTime();
                  list.push(listItem)
                }
              })
            }
          }
        })

      // list.forEach(item => console.log('----', item));
      // * Sort itinerary based on travelDate
      list.sort((a, b) => a.departureDateForSorting - b.departureDateForSorting);


      this.setState({ listApporved: list })
    }
  }

  getInfo = async () => {
    var resp = await masterService.getHomeInfo(this.state.companyId)
    if (resp) {
      this.setState({
        getDetails: resp.result
      })
    }
  }
  onFileBrowse = async (event) => {
    const browseFiles = Array.from(event.target.files);
    this.setState({ browseAllImages: browseFiles });
    let allImages = [];
    let allBrowseImage = [];
    for (const key in browseFiles) {
      let fileName = browseFiles[key].name;
      if (fileName && fileName !== "item") {
        let browseImage = URL.createObjectURL(event.target.files[key]);
        allImages.push(fileName);
        allBrowseImage.push(browseImage);
      }
    }
    let formData = new FormData();
    browseFiles.forEach((file, i) => {
      formData.append("file", file);
    });

    let response = await expenseReport.uploadAvailableReceipt(
      formData,
      this.state.companyId,
      this.state.userId
    );
    if (response) {
      toast.success(response.message);
    }
  };
  redirect = () => {
    this.props.history.push('/my-trips/list')
  }
  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " m " : " m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-6">
          <div className="promo-card white resp-height">
              <NavLink to="/expense/home" className="btn btn-blue float-right" >Go to reports</NavLink>
              <h6 className="heading pt-2">Reports Summary</h6>
              <div className="pending">
                <div className="outl-penidn">
                  <div className="row">
                    <div className="col-6">
                      <p className="fw-normal fs-14 text-uppercase">Unsubmitted</p>
                      <h2 className="mt-2">
                        {/* {(this.state.getDetails)?this.state.getDetails.incompleteSubmissionAmount:0}<sup>{this.state.baseCurreny}</sup> */}
                        {this.state.baseCurreny}{" "}<span className="fs-20">{(this.state.getDetails) ?(this.state.getDetails.length === 0)?"Loading...":
                         (<NumberFormat value={parseInt(this.state.getDetails.incompleteSubmissionAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} />)
                          : 0}</span>
                      </h2>
                    </div>
                    <div className="col-6">
                      <p className="fw-normal fs-14 text-uppercase">Submitted</p>
                      <h2 className="text-danger mt-2">
                        {/* {(this.state.getDetails)?this.state.getDetails.pendingApprovalAmount:0}<sup>{this.state.baseCurreny}</sup> */}
                        {this.state.baseCurreny}{" "}<span className="fs-20">{(this.state.getDetails) ?(this.state.getDetails.length === 0)?"Loading...":
                          <NumberFormat value={parseInt(this.state.getDetails.pendingApprovalAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} /> : 0}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="promo-card yellow">
              <div className="d-table d-table__middle">
                <div>
                  <div className="float-right px-4">
                    <img src="/assets/images/airplane.svg" alt="" />
                  </div>
                  <div className="text-right">
                    <p className="mb-0 text-right text-black op-less-sm">Corporate</p>
                    <h6 className="heading text-right my-2 fs-18 fw-bold">Curio Door to Door</h6>
                    <button className="btn text-right btn-nothing text-black op-less">Coming Soon</button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="col-lg-6">
            <div className={(this.state.listApporved.length === 0) ? "promo-card  bg-white p-0 home-c" : "promo-card  bg-white p-0"} >
              {/* if no data */}
              {(this.state.listApporved.length === 0) ?
                <div className="d-table d-table__middle">
                  <div>
                    <div className="float-left pl-5 pr-4">
                      <img src="/assets/images/destination-icon.svg" alt="" />
                    </div>
                    <div className="text-left">
                      <p className="mb-0">Upcoming</p>
                      <h6 className="heading my-2 fs-18 fw-bold">No Active Itinerary</h6>
                      <NavLink to="/booking/flight" className="btn btn-nothing text-primary">Create Now</NavLink>
                    </div>
                  </div>
                </div>
                :
                <div className="">
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <ol className="carousel-indicators" >
                      {this.state.listApporved.map((item, key) => {
                        return (
                          <li
                            key={key}
                            data-target="#carouselExampleIndicators"
                            data-slide-to={key}
                            className={(key === 0) ? "active" : ""}
                          />
                        )
                      })}
                    </ol>
                    <div className="carousel-inner">
                      {this.state.listApporved.map((item, key) => {
                        // console.log(item?.fareContent?.fare?.flight_depature_date)
                        // console.log('item date', (moment(new Date(item?.fareContent?.fare?.flight_depature_date)).format( 'MM/DD/YYYY')));
                        // console.log(new Date());

                        // console.log('ok ', item, ' this ', isUpcomingTravel)
                        if (item.typeOfItem === "FLIGHT" && !item.flight_searchType.multiCity) {
                          return (
                            <LatestFlight item={item} secondsToHms={this.secondsToHms} key={key} keyIndex={key} />
                          )
                        } else if (item.typeOfItem === "HOTEL") {
                          return (
                            <LatestHotel item={item} secondsToHms={this.secondsToHms} key={key} keyIndex={key} />
                          )
                        }
                      })}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          {this.state.companyNote.length > 0 && this.state.isCompanyNoteActive != undefined
           ?(<CompanyNote
              companyNote={this.state.companyNote}
              getCurrentNote={this.getCurrentNote}
            />)
            : null
          }
          {/* <div className="col-lg-6">
            <div className="promo-card white">
              <h6 className="float-left heading pt-2">Update Receipts</h6>
              <NavLink to="/expense/receipts" className="btn btn-blue float-right">
                Go to Receipt
              </NavLink>
              <div className="clearfix" />
              <div className="upload-input receipt text-center">
                <input type="file" onChange={(e) => this.onFileBrowse(e)} />
                <div className="py-1 check-box">
                  <i className="material-icons">cloud_upload</i>
                  <div className="upload">UPLOAD RECEIPT</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
          <div className="promo-card yellow">
              <div className="d-table d-table__middle">
                <div>
                  <div className="float-right px-4">
                    <img src="/assets/images/airplane.svg" alt="" />
                  </div>
                  <div className="text-right">
                    <p className="mb-0 text-right text-black op-less-sm">Corporate</p>
                    <h6 className="heading text-right my-2 fs-18 fw-bold">Curio Door to Door</h6>
                    <button className="btn text-right btn-nothing text-black op-less">Coming Soon</button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <h5 className="heading mb-3 mt-4">Expense Reports</h5>
        <div className="row">
          <div className="col-sm-4">
            <NavLink to="/expense/home">
              <div className="createpanel mt-0">
                <div className="firstdiv" >
                  <h1>{(this.state.totalTrip) ? this.state.totalTrip ?.expense ?.notSubmitted : 0}</h1>
                  <h6>Requests</h6>
                </div>
                <div className="seconddiv">
                  <h5>Unsubmitted</h5>
                  <h6>List of your expense reports pending for submission.</h6>
                </div>
                <div className="clearfix"></div>
              </div>
            </NavLink>
          </div>
          <div className="col-sm-4">
            <NavLink to="/expense/home">
              <div className="createpanel mt-0">
                <div className="firstdiv" >
                  <h1>{(this.state.totalTrip) ? this.state.totalTrip ?.expense ?.submitted : 0}</h1>
                  <h6>Requests</h6>
                </div>
                <div className="seconddiv">
                  <h5>Submitted</h5>
                  <h6>List of your expense submissions pending for approval.</h6>
                </div>
                <div className="clearfix"></div>
              </div>
            </NavLink>
          </div>
          <div className="col-sm-4">
            <NavLink to="/my-trips/list">
              <div className="createpanel mt-0">
                <div className="firstdiv" >
                  <h1>{(this.state.totalTrip) ? this.state.totalTrip ?.expense ?.pendingApproval : 0}</h1>
                  <h6>Requests</h6>
                </div>
                <div className="seconddiv">
                  <h5>Pending Approval</h5>
                  <h6>List of expenses awaiting your approval.</h6>
                </div>
                <div className="clearfix"></div>
              </div>
            </NavLink>
          </div>
        </div>
        <h5 className="heading mb-3 mt-4">Itinerary</h5>
        <div className="row">
          <div className="col-sm-4">
            <NavLink to="/expense/home">
              <div className="createpanel mt-0">
                <div className="firstdiv" >
                  <h1>{(this.state.totalTrip) ? this.state.totalTrip ?.trip ?.notSubmitted : 0}</h1>
                  <h6>Requests</h6>
                </div>
                <div className="seconddiv">
                  <h5>Unsubmitted</h5>
                  <h6>List of your trip requests pending for submission.</h6>
                </div>
                <div className="clearfix"></div>
              </div>
            </NavLink>
          </div>
          <div className="col-sm-4">
            <NavLink to="/expense/home">
              <div className="createpanel mt-0">
                <div className="firstdiv" >
                  <h1>{(this.state.totalTrip) ? this.state.totalTrip ?.trip ?.submitted : 0}</h1>

                  <h6>Requests</h6>
                </div>
                <div className="seconddiv">
                  <h5>Submitted</h5>
                  <h6>List of your trip requests pending for approval.</h6>
                </div>
                <div className="clearfix"></div>
              </div>
            </NavLink>
          </div>
          <div className="col-sm-4">
            <NavLink to="/my-trips/list">
              <div className="createpanel mt-0">
                <div className="firstdiv" >
                  {/* <h1>{(this.state.totalTrip) ? this.state.totalTrip : 0}</h1> */}
                  <h1>{(this.state.totalTrip) ? this.state.totalTrip ?.trip ?.pendingApproval : 0}</h1>
                  <h6>Requests</h6>
                </div>
                <div className="seconddiv">
                  <h5>Pending Approval</h5>
                  <h6>List of Trips awaiting your approval for further processing.</h6>
                </div>
                <div className="clearfix"></div>
              </div>
            </NavLink>
          </div>
        </div>
        <h5 className="heading mb-3 mt-4">Cash Advance</h5>
        <div className="row">
          <div className="col-sm-4">
            <NavLink to="/expense/home">
              <div className="createpanel mt-0">
                <div className="firstdiv" >
                  <h1>{(this.state.totalTrip) ? this.state.totalTrip ?.cash ?.notSubmitted : 0}</h1>
                  <h6>Requests</h6>
                </div>
                <div className="seconddiv">
                  <h5>Unsubmitted</h5>
                  <h6>List of your cash advance requests pending for submission.</h6>
                </div>
                <div className="clearfix"></div>
              </div>
            </NavLink>
          </div>
          <div className="col-sm-4">
            <NavLink to="/expense/home">
              <div className="createpanel mt-0">
                <div className="firstdiv" >
                  <h1>{(this.state.totalTrip) ? this.state.totalTrip ?.cash ?.submitted : 0}</h1>

                  <h6>Requests</h6>
                </div>
                <div className="seconddiv">
                  <h5>Submitted</h5>
                  <h6>List of your cash advance requests pending for approval.</h6>
                </div>
                <div className="clearfix"></div>
              </div>
            </NavLink>
          </div>
          <div className="col-sm-4">
            <NavLink to="/my-trips/list">
              <div className="createpanel mt-0">
                <div className="firstdiv" >
                  {/* <h1>{(this.state.totalTrip) ? this.state.totalTrip : 0}</h1> */}
                  <h1>{(this.state.totalTrip) ? this.state.totalTrip ?.cash ?.pendingApproval : 0}</h1>
                  <h6>Requests</h6>
                </div>
                <div className="seconddiv">
                  <h5>Pending Approval</h5>
                  <h6>List of cash advance awaiting your approval for further processing.</h6>
                </div>
                <div className="clearfix"></div>
              </div>
            </NavLink>
          </div>
        </div>
        {this.state.currentNote &&
          <CompanyNoteModal
            heading={this.state.currentNote.heading}
            message={this.state.currentNote.message}
          />
        }

      </div>
    );
  }
}
export default withRouter(BookingHomeCardPage);
