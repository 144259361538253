import React, { Component } from "react";
import FullDetailsPage from "../flight/_components/full-details";
class BusListPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  type = 1;
  changeWay(type) {
    this.type = type;
    this.forceUpdate();
  }
  type1 = 1;
  takeland(type1) {
    this.type1 = type1;
    this.forceUpdate();
  }
  render() {
    return (
      <div>
        {/* <div className="full-details">
          <div className="left text-right">
            <button
              type="button"
              data-target="#addServiceModal"
              data-toggle="modal"
              className="details-btn modify-btn-cart light icon-lg"
            >
              <i className="material-icons align-sub">
                add
                                </i>{" "}
              Add Service
                            </button>
            <button
              type="button"
              data-target="#modifyModal"
              data-toggle="modal"
              className="details-btn modify-btn-cart light icon-lg"
            >
              <i className="material-icons align-sub">
                edit
              </i>{" "}
              Modify search
                            </button>
          </div>
          <div className="right">
            <button
              type="button"
              className=" details-btn light cartprice border-right-0"
            >
              <span className="position-relative">
                <span className="round__notification">0</span>
                <i className="material-icons align-sub fs-16 mr-3">
                  shopping_cart
                            </i>
              </span>
              <span className="fs-16 fw-sbold">INR</span> {" "} <span className="fs-16 fw-sbold">
                <span><i className="material-icons fs-14 ml-1 align-middle margin-t-minus text-success">arrow_forward</i></span>
              </span>
            </button>
          </div>
          <div className="clearfix"></div>
        </div> */}

        <div className="">
          <div className="p-padding">
            <div className="row">
              <div className="col-md-10">
                <div className="summary active">
                  <div className="d-inline-block text-center">
                    <p>
                      <i className="material-icons">directions_bus</i>
                    </p>
                  </div>
                  <div className="d-inline-block">
                    <p className="title">
                      Bangalore BLR to New Delhi - 30th Oct 2018
                  </p>
                    <p className="label">5 options found</p>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="devision-container heading">
                  <div className="devision p-less">
                    <div className="details vendor">
                      Vender
                <i className="material-icons">arrow_drop_down</i>
                    </div>
                    <div className="details bus-timing">
                      Timings
                <i className="material-icons">arrow_drop_down</i>
                    </div>
                    <div className="details bus-price text-right">
                      Price
                <i className="material-icons">arrow_drop_down</i>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="devision-container">
                  <div className="top-heading">
                    <p className="right">
                      <i className="material-icons fs-14">info</i> Out of Policy
                  </p>
                    <p>Bus Details</p>
                  </div>
                  <div className="devision p-less">
                    <div className="abs-right">
                      <i className="material-icons">arrow_drop_down</i>
                    </div>
                    <div className="details vendor">
                      <div className="name">
                        <div className="bus-image">
                          <img src="/assets/images/booking/bus_1.png" alt="" />
                        </div>
                        <div className="bus-info">
                          <p className="rating">
                            <span>
                              <i className="material-icons fs-14">star</i>
                            </span>
                            <span>4.0</span>
                          </p>
                          <p>
                            <b>1001 Travels</b>
                          </p>
                          <p className="class">
                            <span className="green-text">
                              {" "}
                              7 Seats Left - 2 Window Seats
                          </span>
                          </p>
                          <div className="amen">
                            <button
                              type="button"
                              className="btn btn-border-basic"
                            >
                              <i className="material-icons">wifi</i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-border-basic"
                            >
                              <i className="material-icons">whatshot</i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-border-basic"
                            >
                              <i className="material-icons">local_cafe</i>
                            </button>
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                    </div>
                    <div className="details bus-timing">
                      <p>
                        <b>10:20 AM to 06:00 PM</b>
                      </p>
                      <p>8h 20m</p>
                      <p className="mt-2">
                        <button type="button" className="btn btn-nothing link">
                          <i className="material-icons fs-14">stop</i> 1 Rest Stop
                      </button>
                      </p>
                      <p className="mt-1">
                        <button type="button" className="btn btn-nothing">
                          <i className="material-icons fs-14">history</i> Reschedulable
                      </button>
                      </p>
                    </div>
                    <div className="details bus-price">
                      <div className="mr">
                        <div className="light-shadow">
                          <button className="btn btn1 active">
                            <p>
                              <b>20$</b>
                            </p>
                            <p>Sleeper</p>
                          </button>
                          <button className="btn btn1 large">
                            <p>
                              <b>30$</b>
                            </p>
                            <p>Semi Sleeper</p>
                          </button>
                          <button className="btn btn1">
                            <p>
                              <b>10$</b>
                            </p>
                            <p>Normal</p>
                          </button>
                        </div>
                        <p className="text-center sm">
                          <i className="material-icons fs-14">directions_bus</i> Volvo Multi Axle Semi
                            Sleeper I-Shift
                      </p>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className="more-details">
                    <ul>
                      <li>
                        <button
                          className={this.type === 1 ? "btn active" : "btn"}
                          onClick={e => this.changeWay(1)}
                        >
                          {" "}
                          Pickup & Drop
                      </button>
                      </li>
                      <li>
                        <button
                          className={this.type === 2 ? "btn active" : "btn"}
                          onClick={e => this.changeWay(2)}
                        >
                          {" "}
                          Policies
                      </button>
                      </li>
                    </ul>
                    <div>
                      {this.type === 1 && (
                        <div className="devision">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="">
                                <h6 className="sub-heading">PICK UP LOCATIONS</h6>
                                <ul className="locations  border-bottom-0 bus-more-details pl-0">
                                  <li>
                                    <strong>06:00 AM</strong> - Marathalli (Opp
                                    Kalamandir Tin Factory)
                                </li>
                                  <li>
                                    <strong>06:10 AM</strong> - Btm Layout (Opp
                                    Karur Vysya Bank)
                                </li>
                                  <li>
                                    <strong>06:20 AM</strong> - Jayanagar 9th
                                    Block (Opp Axis Bank)
                                </li>
                                  <li>
                                    <strong>06:30 AM</strong> - Jayanagar 4th
                                    Block (Opp Leon Grill )
                                </li>
                                  <li>
                                    <strong>06:40 AM</strong>- Indiranagar (Near
                                    Rto Office)
                                </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="">
                                <h6 className="sub-heading">DROP OFF LOCATION</h6>
                                <ul className="locations  border-bottom-0 bus-more-details pl-0">
                                  <li>
                                    <strong>06:00 AM</strong> - Lonawala Desi
                                  Food Point{" "}
                                  </li>
                                  <li>
                                    <strong>06:10 AM</strong> - Kharghar Near
                                    Hotel Three Star
                                </li>
                                  <li>
                                    <strong>06:20 AM</strong> - Vashi Old Tol-Naka
                                  Near Mgm Ground{" "}
                                  </li>
                                  <li>
                                    <strong>06:40 AM</strong>- Sion(Chuna
                                    Batti)-(Opp Highway Apartment)
                                </li>
                                  <li>
                                    <strong>06:50 AM</strong> - Borivali Gokul
                                    Hotel Near Chamunda Circle
                                </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.type === 2 && (
                        <div className="devision">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="border-right">
                                <div className="row">
                                  <div className="col-md-9">
                                    <h6 className="sub-heading">
                                      TIME OF CANCELLATION
                                  </h6>
                                    <ul className="locations border-bottom-0 bus-more-details pl-0">
                                      <li>0 to 4 hr before travel</li>
                                      <li>4 to 12 hr before travel </li>
                                      <li>12 to 24 hr before travel</li>
                                      <li>More then 24 hrs before travel</li>
                                    </ul>
                                  </div>
                                  <div className="col-md-3">
                                    <h6 className="sub-heading">CHARGE</h6>
                                    <ul className="locations  border-bottom-0 bus-more-details pl-0">
                                      <li>100 % </li>
                                      <li>25 % </li>
                                      <li>20 % </li>
                                      <li>15 %</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="">
                                <h6 className="sub-heading"> OTHER CONDITIONS</h6>
                                <ul className="locations  border-bottom-0 bus-more-details pl-0">
                                  <li>
                                    * Cancellation charges will be applicable on
                                  total ticket value.{" "}
                                  </li>
                                  <li>
                                    * Refundable amount is calculated based on
                                    scheduled bus departure time from the first
                                    boarding time.
                                </li>
                                  <li>
                                    * Partial cancellation is not allowed for this
                                    ticket. Ticket cannot be cancelled after bus
                                  departure time{" "}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="row">
                  <div className="custom-card">
                    <div>
                      <div className="filter-card pb-2 mb-4">
                        <h6 className="heading grey">Advanced Settings</h6>
                        <div className="hotel-filter-panel">
                          <h6 className="heading ">Price Range</h6>
                          <div className="inner-content" />
                        </div>
                        <div className="hotel-filter-panel">
                          <h6 className="heading ">Preferred Vendor</h6>
                          <div className="inner-content">
                            <div className="form-group custom bg-white mb-0">
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  Preferred Bus
                              </button>
                                <div className="dropdown-menu">
                                  <button type="button" className="dropdown-item">
                                    GLobal
                                </button>
                                  <button type="button" className="dropdown-item">
                                    GLobal
                                </button>
                                  <button type="button" className="dropdown-item">
                                    GLobal
                                </button>
                                </div>
                              </div>
                            </div>
                            <div className="btn-group-margin">
                              <button type="button" className="btn py-0">
                                1001
                              <i className="material-icons fs-14">close</i>
                              </button>
                              <button className="btn py-0">
                                Rainbow
                              <i className="material-icons fs-14">close</i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="hotel-filter-panel">
                          <h6 className="heading ">Preferred Chain</h6>
                          <div className="inner-content">
                            <div className="form-group custom bg-white mb-0">
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  Preferred Chain
                              </button>
                                <div className="dropdown-menu">
                                  <button type="button" className="dropdown-item">
                                    GLobal
                                </button>
                                  <button type="button" className="dropdown-item">
                                    GLobal
                                </button>
                                  <button type="button" className="dropdown-item">
                                    GLobal
                                </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hotel-filter-panel">
                          <h6 className="heading ">Rating</h6>
                          <div className="inner-content">
                            <img
                              className="ratings"
                              src="/assets/images/booking/12.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="hotel-filter-panel">
                          <h6 className="heading ">Type</h6>
                          <div className="inner-content">
                            <div className="form-group custom bg-white mb-0">
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  Sleeper
                              </button>
                                <div className="dropdown-menu">
                                  <button type="button" className="dropdown-item">
                                    GLobal
                                </button>
                                  <button type="button" className="dropdown-item">
                                    Lorem Ipsum
                                </button>
                                  <button type="button" className="dropdown-item">
                                    Lorem Ipsum
                                </button>
                                </div>
                              </div>
                            </div>
                            <button className="btn btn-border-primary bg-white btn-block apply-filter">
                              Apply
                          </button>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div className="filter-card px-3 pt-1 pb-4 mb-4">
                        <div className="type-panel-out">
                          <h6 className="heading">Currency</h6>
                          <div className="inner-body">
                            <div className="form-group custom bg-white mb-0">
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  USD
                            </button>
                                <div className="dropdown-menu">
                                  <button type="button" className="dropdown-item">
                                    USD
                              </button>
                                  <button type="button" className="dropdown-item">
                                    USD
                              </button>
                                  <button type="button" className="dropdown-item">
                                    USD
                              </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default BusListPage;
