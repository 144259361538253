import React, { Component } from 'react';
//import Pagination from 'src/_directives/pagination';
import { NavLink } from "react-router-dom";

export default class WorkflowRuleTable extends Component {
  render() {
    let activeStyle = { background: "#377DFF", color: '#fff', padding: "5px" };
    let activeStepClass = "";
    if (this.props.selectedStep.length > 0) {
      activeStepClass = 'btn';
    } else {
      activeStepClass = 'btn';
    }
    // Style for active rule class
    let activeRuleClass = this.props.selectedRule.length ? 'btn' : 'btn';

    return (
      <div className="table-container">
        <form>
          <div className="row">
            <div className="col-md-10">
              <div className="btn-group-margin">
                <button
                  disabled={!this.props.selectedStep.length}
                  className="btn"
                  type="button"
                  onClick={this.props.toggleRuleCreationComponent}
                >New
                </button>
                <button
                  disabled={!this.props.selectedRule.length} className="btn"
                  type="button"
                  onClick={this.props.modifyRuleHandler}>
                  Modify
                </button>
                <button
                  className="btn"
                  disabled={!this.props.selectedRule.length}
                  onClick={this.props.removeRuleHandler}

                >
                  Remove
                </button>
                {(Object.keys(this.props.selectedAction).length === 0) &&
                  <button
                    disabled={!this.props.selectedRule.length}
                    className="btn"
                    type="button"
                    data-toggle="modal"
                    data-target="#ActionModal"
                  >
                    Apply Rule Action
                </button>
                }
                {(Object.keys(this.props.selectedAction).length !== 0) &&
                  <button
                    disabled={!this.props.selectedRule.length}
                    type="button"
                    className="btn"
                    data-toggle="modal"
                    data-target="#ActionModal"
                  >
                    Modify Rule Action
                  </button>
                }
                {(Object.keys(this.props.selectedAction).length !== 0) &&
                  <button
                    onClick={this.props.removeRuleActionHandler}
                    disabled={!this.props.selectedRule.length}
                    type="button" className="btn">
                    Remove Rule Action
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
        <div className="table-responsive">
          <table className="table table-striped table-selected">
            <thead>
              <tr>
                <th>Step Name</th>
                <th>Rule Name
                    <span className="float-right">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                this.
                  props.allWorkflowSteps && this.props.allWorkflowSteps.map(step => {
                    let rules = [];
                    if (this.props?.allWorkflowRules?.length) {
                      rules = this.props.allWorkflowRules.filter(rule => {
                        return rule.workflowStep_id === step.workflowStep_id
                      });
                    }
                    return (
                      <tr key={step.workflowStep_id}
                        className={
                          this.props.selectedStep.some(i => i.id === step.id)
                            ? "active-tr"
                            : null
                        }
                      >
                        <td onClick={e => this.props.stepSelectHandler(step)} >
                          {step.workflowStepName}
                        </td>
                        <td>
                          {rules ? rules.map(rule => {

                            return (
                              <div key={rule.id} className="d-flex justify-content-between">
                                <span
                                  onClick={e => this.props.ruleSelectHandler(rule)}
                                  className={
                                    this.props.selectedRule.some(i => i.id === rule.id)
                                      ? "active-td active px-3 py-1 mb-2"
                                      : "active-td px-3 py-1 mb-2"
                                  }
                                >
                                  {rule.worlflowRuleName}
                                </span>
                                <span>{rule?.data?.action?.action_name?.master_name_value || "-"}</span>
                              </div>
                            )
                          }) : 'No rules'
                          }
                        </td>
                      </tr>
                    )
                  })
              }
            </tbody>
          </table>
        </div>
        <div className="btn-group-margin mt-4 ml-4">
          <NavLink to={`/administration/company/workflows/home`} className="btn">Done</NavLink>
        </div>
      </div>
    )
  }
}
