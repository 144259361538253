import React from "react";
import ComboFlightExpand from "./comboExpand";
import NumberFormat from "react-number-format";
import TaxBreakUpFlight from "./tax-break-up";

const ComboFlightList = props => {
  const {
    // changeWay,
    baggageInfo,
    type,
    items,
    getTimes,
    keys,
    airport,
    viewDetail,
    detailId,
    arrowId,
    from,
    uniqueId,
    changeMode,
    addToCart,
    currency,
    selectedItem,
    to,
    req,
    tripType,
    state,
    cancelPolicy,
    handleGetReason,
    handleChangeReasonId
  } = props;
  let fareDetails = items ?.data[0] ?.fare;
  let corpDetails = items ?.data[0] ?.CORP;
  return (
    <div className="devision-container" key={keys}>
      <div className="top-heading">
        <p className="float-left show-white border-right-white bg-white">
          Flight Details
        </p>
        {items.out_of_policy && (
          <div className="p-to-head right float-left show-white border-right-white out-of-policy hover-v">
            <i className="material-icons fs-14 align-middle">error</i>{" "}
            <span>Out of policy</span>
            <div className="hover-name bottom out-of-p">
              <p>
                This option breaks one or more company travel rules that prevent
                it from being selected.
              </p>
              {items.violations &&
                items.violations.map((rule, ruleKey) => {
                  return (
                    <p key={ruleKey} className="d-block">
                      <i className="material-icons">warning</i> {rule.message}.
                    </p>
                  );
                })}
              {/* <p>
                              <i  className="material-icons">
                                  warning
                      </i> 2 or more company employees in the same flight.</p> */}
            </div>
          </div>
        )}
        <p
          className="right blue cursor-pointer"
          data-target={"#itineraryInfoModal" + keys}
          data-toggle="modal"
        >
          <i className="material-icons fs-14 align-middle">flight</i> Fare
          Details
        </p>
        {(fareDetails && fareDetails.next_day_arrive) ||
          (corpDetails && corpDetails.next_day_arrive) ? (
            <p className="right float-left white border-right-white next-day-arrival">
              <i className="material-icons fs-14 align-middle">wb_sunny</i>
              <span>
                {fareDetails.arriveTime === 1 && "Next Day Arrival"}
                {fareDetails.arriveTime === 2 && "Second Day Arrival"}
                {fareDetails.arriveTime === 3 && "Third Day Arrival"}
                {fareDetails.arriveTime === 4 && "Fourth Day Arrival"}
                {fareDetails.arriveTime === 5 && "Fifth Day Arrival"}
              </span>
            </p>
          ) : (
            ""
          )}
        {(fareDetails && fareDetails.red_eye) ||
          (corpDetails && corpDetails.red_eye) ? (
            <p className="right float-left show-white border-right-white hover-v">
              <i className="material-icons fs-14 align-middle">visibility</i>
            </p>
          ) : (
            ""
          )}
        {(fareDetails && fareDetails.cheapest) ||
          (corpDetails && corpDetails.cheapest) ? (
            <p className="suggestion cheap border-right-white">
              <i className="material-icons fs-14 align-middle">trending_down</i>{" "}
              Cheapest
          </p>
          ) : (fareDetails && fareDetails.fastest) ||
            (corpDetails && corpDetails.fastest) ? (
              <p className="suggestion fast border-right-white">
                <i className="material-icons fs-14 align-middle">trending_up</i>{" "}
                Fastest
          </p>
            ) : (
              ""
            )}
        <div className="clearfix" />
      </div>

      <div id="">
        {items ?.data &&
          items ?.data.map((list, index) => {
            return (
              <div key={index}>
                <div
                  className="devision division-multi"
                  data-toggle="collapse"
                  data-target={"#collapseOne" + keys + index}
                  aria-expanded="true"
                  aria-controls={"#collapseOne" + keys + index}
                onClick={e => viewDetail(index, keys, list.choosed_flight_fare_id)}
                >
                  <div className="new-devision-container">
                    <div className="abs-right">
                      <i className="material-icons">
                        {arrowId === keys ? "arrow_drop_up" : "arrow_drop_down"}
                      </i>
                    </div>
                    <div className="details airline">
                      <div className="name">
                        <div className="logo">
                          <img
                            src={
                              list.fare
                                ? list.fare.flight_image
                                : list.CORP
                                  ? list.CORP.flight_image
                                  : "/assets/images/booking/flight-dummy.png"
                            }
                            alt="flight"
                          />
                        </div>
                        <div className="info">
                          <p className="timings">
                            <b>
                              {list.fare
                                ? list.fare.flight_airline
                                : list.CORP
                                  ? list.CORP.flight_airline
                                  : ""}
                            </b>
                          </p>
                          <p>{list.fare
                            ? list.fare.flight_airline_code
                            : list.CORP
                              ? list.CORP.flight_airline_code
                              : ""}</p>
                        </div>
                        <div className="clearfix" />
                      </div>
                    </div>
                    <div className="details departure">
                      <p>
                        {req !== undefined &&
                          typeof req === "object" &&
                          req[index].from}
                        {index === 0 && typeof req !== "object" && from}
                        {index === 1 && typeof req !== "object" && to}
                      </p>
                      <p>
                        <b>{list.fare
                          ? list.fare.flight_depature_time
                          : list.CORP
                            ? list.CORP.flight_depature_time
                            : ""}</b>
                      </p>
                    </div>
                    <div className="details arrival">
                      <p>
                        {req !== undefined &&
                          typeof req === "object" && req[index].to}
                        {index === 0 && typeof req !== "object" && to}
                        {index === 1 && typeof req !== "object" && from}
                      </p>
                      <p>
                        <b>
                          {list.fare
                            ? list.fare.flight_arrival_time
                            : list.CORP
                              ? list.CORP.flight_arrival_time
                              : ""}
                        </b>
                      </p>
                      {/* <ul className="ul-bullets">
                        {list.fare && !list.CORP && (
                          <li>
                            {list.fare.flight_refundable ? (
                              <p className="link">Refundable</p>
                            ) : (
                                <p className="text-danger">Non-Refundable</p>
                              )}
                          </li>
                        )}
                        {!list.fare && list.CORP && (
                          <li>
                            {list.CORP.flight_refundable ? (
                              <p className="link">Refundable</p>
                            ) : (
                                <p className="text-danger">Non-Refundable</p>
                              )}
                          </li>
                        )}
                        {list.fare && list.CORP && (
                          <li>
                            {list &&
                              list.choosed_flight_fare_id &&
                              list.choosed_flight_fare_id.type ==
                              "Corporate Fare" ? (
                                list.CORP.flight_refundable ? (
                                  <p className="link">Refundable</p>
                                ) : (
                                    <p className="text-danger">Non-Refundable</p>
                                  )
                              ) : list.fare.flight_refundable ? (
                                <p className="link">Refundable</p>
                              ) : (
                                  <p className="text-danger">Non-Refundable</p>
                                )}
                          </li>
                        )}
                      </ul> */}
                    </div>
                    <div className="details duration">
                      <p>
                        <b>
                          <i className="material-icons fs-14 align-middle">
                            access_time
                            </i>{" "}
                          {list.fare
                            ? getTimes(list.fare.flight_onward_total_duration)
                            : list.CORP
                              ? getTimes(list.CORP.flight_onward_total_duration)
                              : ""}
                        </b>
                      </p>
                      {/* <p className="text-success">
                        <i className="material-icons fs-14 align-sub">
                          airline_seat_recline_normal
                          </i>
                          {" "}9 Seats Left
                      </p> */}
                    </div>
                    <div className="details stops">
                      <p>
                        <b>
                          {list.content.length === 1 ? (
                            "Non-Stop"
                          ) : (
                              <span>{list.content.length - 1 + " Stop"}</span>
                            )}
                        </b>
                      </p>
                      <p>
                        {list.content &&
                          list.content.length > 1 &&
                          list.choosed_flight_fare_id.flight_layoverTime +
                          " Layover"}
                      </p>
                    </div>
                    {index === 0 && (
                      <div className="details price">
                        <div
                          className="flight-price "
                          className={
                            uniqueId.indexOf(list.UNIQUE_ID) === -1
                              ? "flight-price "
                              : "flight-price added"
                          }
                        >
                          <div className="cash">
                            {list.choosed_flight_fare_id && (
                              <span>
                                <p>{list.choosed_flight_fare_id.type}</p>
                                <h5>
                                  {" "}
                                  {currency}{" "}
                                  <NumberFormat
                                    value={list.choosed_flight_fare_id.flight_total_price.toFixed(
                                      0
                                    )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </h5>
                              </span>
                            )}
                          </div>
                          {list.priceList.length <= 1 && (
                            <div
                              className="d-button"
                              onClick={e =>
                                addToCart(
                                  list.choosed_flight_fare_id,
                                  tripType,
                                  list.UNIQUE_ID
                                )
                              }
                            >
                              <i className="material-icons">
                                {selectedItem.indexOf(
                                  list.choosed_flight_fare_id.flight_fare_id
                                ) === -1
                                  ? "chevron_right"
                                  : "done"}
                              </i>
                              {state.loadingUniqueId === list.UNIQUE_ID
                                ? "Please wait"
                                : selectedItem.indexOf(
                                  list.choosed_flight_fare_id.flight_fare_id
                                ) === -1
                                  ? "Add"
                                  : "Added"}
                            </div>
                          )}
                          {list.priceList.length > 1 && (
                            <div className="d-button dropup">
                              <button
                                type="button"
                                data-toggle="dropdown"
                                className="btn dropdown-toggle"
                              >
                                {state.loadingUniqueId === list.UNIQUE_ID
                                  ? "Please wait"
                                  : selectedItem.indexOf(
                                    list.choosed_flight_fare_id.flight_fare_id
                                  ) === -1
                                    ? "Options"
                                    : "Added"}
                                <i className="material-icons abs">expand_more</i>
                              </button>
                              {list.priceList && (
                                <div className="dropdown-menu">
                                  {items.priceList.map((item, keyInd) => {
                                    return (
                                      <button
                                        key={keyInd}
                                        onClick={e => changeMode(keys, item)}
                                        className={
                                          selectedItem.indexOf(
                                            item.flight_fare_id
                                          ) === -1
                                            ? "dropdown-item"
                                            : "dropdown-item added"
                                        }
                                        key={keyInd}
                                      >
                                        <button
                                          onClick={e =>
                                            addToCart(
                                              item,
                                              tripType,
                                              list.UNIQUE_ID
                                            )
                                          }
                                          className="add"
                                        >
                                          {selectedItem.indexOf(
                                            item.flight_fare_id
                                          ) === -1
                                            ? "Add"
                                            : "Added"}
                                        </button>
                                        <p>{item.type}</p>
                                        <h5>
                                          {currency}{" "}
                                          {item.flight_total_price.toFixed(0)}
                                        </h5>
                                      </button>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )}
                          <div className="clearfix" />
                        </div>
                      </div>
                    )}
                    <div className="clearfix" />
                  </div>
                </div>
                <ComboFlightExpand
                  key={keys + index}
                  type={type}
                  keys={keys}
                  airport={airport}
                  index={index}
                  getTimes={getTimes}
                  baggageInfo={baggageInfo}
                  list={list.content}
                  currency={currency}
                  from={from}
                  to={to}
                />
              </div>
            );
          })}
      </div>
      <div
        id="openOutofPolicy"
        data-target={"#travelPolicyModal"}
        data-toggle="modal"
        data-backdrop="static"
        data-keyboard="false"
      />
      <div
        className="modal fade"
        id={"travelPolicyModal"}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="travelPolicyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="travelPolicyLabel">
                Travel Policy Violation
              </h5>
              <span className="close" data-dismiss="modal" />
            </div>
            <div className="modal-middle">
              <div className="travel_alert d-block py-4 pm-30">
                <div className="alert-detail">
                  <p>
                    <i className="material-icons">info</i> This flight is not in
                    compliance with the following travel rule(s)
                  </p>
                </div>
                {/* <p><span className="mr-2"><i className="material-icons">info</i></span>This flight is not in compliance with the following travel rule(s)</p> */}
              </div>
              <div className="pm-30 bg-light">
                {state ?.storePolicyCart ?.e ?.violations &&
                  state ?.storePolicyCart ?.e ?.violations.map((rule, ruleKey) => {
                    return (
                      <p key={ruleKey} className="mb-2">
                        <i className="material-icons">info</i> {rule.message}.
                      </p>
                    );
                  })}
                <p className="my-3">
                  Please choose the reason for selecting this travel option, if
                  more Than one reason applies, choose the most applicable. This
                  reason Applies to this entire trip.
                </p>
                <div className="form-group custom  mb-0">
                  <div className="dropdown">
                    <select
                      className="form-control"
                      onChange={e => handleChangeReasonId(e)}
                    >
                      <option value="">Select Reason</option>
                      {state.storePolicyCart &&
                        state.storePolicyCart.e &&
                        state.storePolicyCart.e.violations &&
                        state.storePolicyCart.e.violations.map(
                          (itemList, keyItme) => {
                            return (
                              <option
                                value={itemList.violationCode}
                                key={keyItme}
                              >
                                {itemList.policyViolation ?.description} -
                                {itemList.violationCode}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="pm-30">
                <p className="mb-3">
                  Please explain why you have chosen this flight
                </p>
                <div className="form-group custom textarea">
                  <textarea
                    data-gramm_editor="false"
                    spellCheck="false"
                    onChange={e => handleGetReason(e)}
                    rows="2"
                    name="reason"
                    className="form-control"
                    value={state.reasons}
                    placeholder="&nbsp;"
                  />
                  <label>Comment</label>
                </div>
                <div className="modal-last btn-group-margin ">
                  <button
                    onClick={e =>
                      addToCart(
                        state.storePolicyCart.e,
                        state.storePolicyCart.tripType,
                        state.storePolicyCart.uniqueId
                      )
                    }
                    type="button"
                    className="btn"
                  >
                    Add
                  </button>
                  <button
                    onClick={e => cancelPolicy()}
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id={"itineraryInfoModal" + keys}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="itineraryInfoLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="itineraryInfoLabel">
                Itinerary Information
              </h5>
            </div>
            <div className="modal-middle unmuted-p bg-light pm-30">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    {items ?.data &&
                      items ?.data.map((main, key) => {
                        return (
                          <div key={key} className="bg-white mb-3 ml-15">
                            <div className="heading text-bold">
                              <p className="float-left">
                                {from} - {to}
                              </p>
                              <p className="float-right">
                                Journey Time :{" "}
                                {main.choosed_flight_fare_id &&
                                  getTimes(
                                    main.choosed_flight_fare_id.journeyTime
                                  )}
                              </p>
                              <div className="clearfix" />
                            </div>
                            <div className="flight-detail">
                              <div className="itinerary devision-container">
                                {main &&
                                  main.content.map((list, key) => {
                                    return (
                                      <div className="" key={key}>
                                        <div className="flight-info">
                                          <div className="details main min-h-auto">
                                            <div className="name">
                                              <div className="logo">
                                                <img
                                                  className="h-auto"
                                                  src={
                                                    list.flight_image
                                                      ? list.flight_image
                                                      : "/assets/images/booking/flight-dummy.png"
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div className="info">
                                                <p className="text-bold pt-3">
                                                  {items.fare &&
                                                    items.fare
                                                      .flight_airline}{" "}
                                                  {items.CORP &&
                                                    items.CORP.flight_airline}
                                                </p>
                                              </div>
                                              <div className="clearfix" />
                                            </div>
                                          </div>

                                          <div className="clearfix" />
                                        </div>
                                        <div className="flight-info">
                                          <div className="main-p m-0">
                                            <div className="info details main pr-3 min-h-auto">
                                              <p className="text-bold">
                                                Departs at{" "}
                                                {airport[
                                                  list.flight_from_city
                                                ] &&
                                                  airport[list.flight_from_city]
                                                    .c &&
                                                  airport[list.flight_from_city]
                                                    .c}
                                                ,{" "}
                                                {airport[
                                                  list.flight_from_city
                                                ] &&
                                                  airport[list.flight_from_city]
                                                    .n &&
                                                  airport[list.flight_from_city]
                                                    .n}{" "}
                                                {list.flight_departure_terminal &&
                                                  " IN at Terminal " +
                                                  list.flight_departure_terminal}
                                              </p>
                                            </div>
                                            <div className="details co2 min-h-auto">
                                              <p className="text-bold">
                                                <span className="muted-p">
                                                  On
                                                </span>{" "}
                                                {list.flight_depature_date_utc}
                                              </p>
                                            </div>
                                            <div className="details economy text-right min-h-auto">
                                              <p className="text-bold">
                                                <span className="muted-p">
                                                  at
                                                </span>{" "}
                                                {list.flight_depature_time}
                                              </p>
                                            </div>
                                            <div className="clearfix" />
                                          </div>
                                        </div>
                                        <div className="flight-info">
                                          <div className="main-p m-0 border-0">
                                            <div className="info details main min-h-auto pr-3">
                                              <p className="text-bold">
                                                Arrives{" "}at{" "}
                                                {airport[list.flight_to_city] &&
                                                  airport[list.flight_to_city]
                                                    .c &&
                                                  airport[list.flight_to_city]
                                                    .c}
                                                ,{" "}
                                                {airport[list.flight_to_city] &&
                                                  airport[list.flight_to_city]
                                                    .n &&
                                                  airport[list.flight_to_city]
                                                    .n}{" "}
                                                at
                                                {list.flight_arrival_terminal &&
                                                  " Terminal " +
                                                  list.flight_arrival_terminal}
                                              </p>
                                            </div>
                                            <div className="details co2 min-h-auto">
                                              <p className="text-bold">
                                                <span className="muted-p">
                                                  On
                                                </span>{" "}
                                                {list.flight_arrival_date_utc}
                                              </p>
                                            </div>
                                            <div className="details min-h-auto economy text-right">
                                              <p className="text-bold">
                                                <span className="muted-p">
                                                  at
                                                </span>{" "}
                                                {list.flight_arrival_time}
                                              </p>
                                            </div>
                                            <div className="clearfix" />
                                          </div>
                                        </div>
                                        {main.content.length !== key + 1 && (
                                          <div className="flight-divider pb-4 muted-p">
                                            {/* <div>Layover at Delhi, 1h 20m</div> */}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {items && items.data && items.data[0] && (
                    <div className="col-md-5 mr-15">
                      <table className="table itinerary-table">
                        <thead>
                          <tr className="muted-p border-0">
                            <th className="border-0">Fare Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.data[0].choosed_flight_fare_id && (
                            <tr className="text-bold">
                              <td className="border-0 pb-4 ">
                                {from} - {to}
                              </td>
                              <td className="border-0 text-right">
                                {currency} &nbsp;{" "}
                                {items.data[0].choosed_flight_fare_id &&
                                  items.data[0].choosed_flight_fare_id.fareInfo.totalOverAllPrice.toFixed(
                                    2
                                  )}{" "}
                                - {items.data[0].choosed_flight_fare_id.type}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <table className="table itinerary-table">
                        <thead>
                          <tr className="muted-p border-0">
                            <th className="border-0">
                              Tax Details -{" "}
                              {items.data[0].choosed_flight_fare_id.type}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td className="border-0">Base Fare</td>
                            <td className="border-0 text-right">
                              {currency} &nbsp;{" "}
                              {items.data[0].choosed_flight_fare_id.fareInfo &&
                                items.data[0].choosed_flight_fare_id.fareInfo.flightAdultFare.toFixed(
                                  0
                                )}
                            </td>
                          </tr>
                          {items.data[0].choosed_flight_fare_id.fareInfo
                            .serviceFee !== 0 && (
                              <tr>
                                <td className="border-0">Service Fee</td>
                                <td className="border-0 text-right">
                                  {currency} &nbsp;
                              </td>
                              </tr>
                            )}
                          <tr>
                            <td className="border-0">Taxes and Fees</td>
                            <td className="border-0 text-right">
                              {currency} &nbsp;{" "}
                              {items.data[0].choosed_flight_fare_id.fareInfo &&
                                items.data[0].choosed_flight_fare_id.fareInfo.gst.toFixed(
                                  0
                                )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="accordion mb-4" id="accordionExample">
                        <div className="card">
                          {/* <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <button className="btn btn-nothing" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Tax Breakpoint
                                                        </button>
                            </h5>
                          </div> */}
                          <div className="accordion mb-4" id="accordionExample">
                            <div className="card">
                              <div
                                id="collapseOne"
                                className="collapse show border-bottom"
                                aria-labelledby="headingOne"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  {/*
                                  {items.data[0].choosed_flight_fare_id && items.data[0].choosed_flight_fare_id.fareInfo && items.data[0].choosed_flight_fare_id.fareInfo.taxBreakUp &&
                                    Object.keys(items.data[0].choosed_flight_fare_id.fareInfo.taxBreakUp[0]).map((key, index) => {
                                      if (items.data[0].choosed_flight_fare_id.fareInfo.taxBreakUp[0][key] != null)
                                        return (
                                          <div key={index} className="row">
                                            <div className="col-6">
                                              <p className="float-left text-capitalize py-2">

                                                {key.split("_").join(" ")}
                                              </p>
                                            </div>
                                            <div className=" col-6">
                                              <p className="float-right text-capitalize py-2">
                                                {currency} &nbsp;
                                                                            <NumberFormat value={items.data[0].choosed_flight_fare_id.fareInfo.taxBreakUp[0][key].toFixed(2)} displayType={'text'} thousandSeparator={true} />
                                              </p>
                                            </div>
                                          </div>
                                        )
                                    })
                                  } */}

                                  {items.data[0] &&
                                    items.data[0].fare &&
                                    items.data[0].fare.tax_break_up &&
                                    Reflect.ownKeys(
                                      items.data[0].fare.tax_break_up
                                    ).map((taxBreakKey, index) => (
                                      <TaxBreakUpFlight
                                        key={taxBreakKey}
                                        taxBreakKey={taxBreakKey}
                                        taxBreakValue={Number(
                                          items.data[0].fare.tax_break_up[
                                          taxBreakKey
                                          ]
                                        )}
                                        index={index}
                                        currency={currency}
                                      />
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div id="collapseOne" className="collapse border-bottom" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body">
                              {items.choosed_flight_fare_id && items.choosed_flight_fare_id.fareInfo && items.choosed_flight_fare_id.fareInfo.taxBreakUp &&
                                Object.keys(items.choosed_flight_fare_id.fareInfo.taxBreakUp[0]).map((key, index) => {
                                  if (items.choosed_flight_fare_id.fareInfo.taxBreakUp[0][key] != null)
                                    return (
                                      <div key={index} className="row">
                                        <div className="col-lg-6">
                                          <p className="float-left text-capitalise  py-2">

                                            {key.split("_").join(" ")}
                                          </p>
                                        </div>
                                        <div className="col-lg-6  text-capitalise py-2">
                                          <p className="float-right">
                                            {currency} &nbsp;
                                                                            <NumberFormat value={items.choosed_flight_fare_id.fareInfo.taxBreakUp[0][key].toFixed(0)} displayType={'text'} thousandSeparator={true} />
                                          </p>
                                        </div>
                                      </div>
                                    )
                                })
                              }
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="total-amt">
                        <p className="float-left">
                          Total for 1 Adult Passenger -{" "}
                          {items.data[0].choosed_flight_fare_id.type}
                        </p>
                        <p className="float-right text-bold">
                          {currency} &nbsp;{" "}
                          {items.data[0].choosed_flight_fare_id &&
                            items.data[0].choosed_flight_fare_id.fareInfo.totalOverAllPrice.toFixed(
                              2
                            )}
                        </p>
                        <div className="clearfix" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="modal-last pm-30 text-right">
              <button
                type="button"
                className="btn btn-badge"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ComboFlightList;
