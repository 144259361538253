import React from "react";
import Menu from "../menu";
import Pagination from "react-js-pagination";
import { Select, Input } from "../../../../../common/_forms";
import TableRow from "./Table-row";
import Selects from 'react-select';
import Loader from 'src/_components/common/Loader';

const ConfigurationSetsPage = props => {
  const {
    pageClickHandler,
    handleItemChange,
    pageItemsList,
    totalCount,
    configSetList,
    allowanceType,
    maxLimit,
    selectedCurrency,
    selectedPage,
    dataPerPage,
    saveConfigSet,
    isEdit,
    validator,
    validator1,
    loading,
    id } = props;

  let tableRow = configSetList ? (
    configSetList.length > 0 ? (
      configSetList.map((data, index) => (
        <TableRow key={index} data={data}
          onHourlyClickHandler={props.onHourlyClickHandler}
          allocationCalculationType={props.allocationCalculationType}
          editHandler={props.editHandler}
          allowanceTypeList={props.allowanceTypeList}
          guestActive={props.guestActive}
          personalActive={props.personalActive}
          selectedDeleteHandler={props.selectedDeleteHandler} id={id} />
      ))
    ) : (
        <tr>
          <td colSpan={6}>No data found</td>
        </tr>
      )
  ) : (
      <tr />
    );    
    
  return (
    <div>
      <div className="admin-tabs">
        <Menu />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">C</div>
            <div className="right">
              <h6 className="heading">Configuration Sets</h6>
              <p className="text">Use Configuration Sets to set reimbursement rates depending on location.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group custom labelup">
                    <label>Configuration <span className="text-danger">*</span></label>
                    <Selects
                      name="configuration"
                      options={props.configList}
                      onChange={props.onConfigHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="btn-group-margin">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      onClick={props.onAddClickHandler}
                      data-target="#AddTemplateModal"
                      disabled={props.configurationId !== '' && props.configurationId !== undefined ? false : true}>
                      Add
                    </button>
                    <button
                      type="button"
                      className="btn"
                      onClick={() => props.deletehandler()}
                      disabled={id.length > 0 ? false : true}
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>Location</th>
                    <th>Allowance type</th>
                    <th>Currency</th>
                    <th>Standard Rate</th>
                    <th>Guest House Rate</th>
                    <th>Stay at Friends Rate</th>
                    <th>Allowance Calculation Type</th>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {props.isLoading ?
                  <tbody>
                  {
                    <tr>
                      <td colSpan={7} className="text-center">
                        {<Loader/>}
                      </td>
                    </tr>
                  }
                </tbody> :
                  <tbody>
                    {totalCount > 0 ?
                      tableRow
                      :
                      <tr>
                        <td colSpan={9} className="text-center">Please Select Configuration.</td>
                      </tr>
                    }
                  </tbody>
                }
              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  {totalCount > 0 && (
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <Select
                            placeholder={dataPerPage}
                            keyName={"items"}
                            options={pageItemsList}
                            controlFunc={e => handleItemChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-9">
                  {totalCount > 0 && (
                    <Pagination
                      activePage={selectedPage}
                      itemsCountPerPage={dataPerPage}
                      totalItemsCount={totalCount}
                      pageRangeDisplayed={5}
                      onChange={pageClickHandler}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddTemplateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="AddTemplateModalLabel">
                {isEdit ? "Edit Configuration Set" : "Add Configuration Set"}
              </h5>
              <button
                type="button"
                className="close d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={saveConfigSet}>
              <div className="modal-middle pm-30">
                <div
                  className="form-group custom labelup"
                >
                  <label>Country</label>
                  <Selects
                    name="country"
                    value={props.selectedCountry}
                    options={props.countriesList}
                    onChange={props.onCountryChangeHandler}
                  />

                </div>
                <div
                  className="form-group custom labelup"
                >
                  <label>State</label>
                  <Selects
                    name="country"
                    value={props.selectedState}
                    options={props.stateList}
                    onChange={props.onStateChangeHandler}
                  />
                </div>
                <div
                  className="form-group custom labelup"
                >
                  <label>City</label>

                  <Selects
                    name="city"
                    value={props.selectedCity}
                    options={props.citiesList}
                    onChange={props.onCityChangeHandler}
                  />
                </div>
                <div
                  className="form-group custom labelup"
                >
                  <label>Allowance Type <span className="text-danger">*</span></label>

                  <Selects
                    name="allowanceType"
                    value={props.allowanceType}
                    options={props.allowanceTypeList}
                    onChange={props.onAddAllowanceChangeHandler}
                  />
                  <p className="text-danger fs-12">
                    {validator1.message(
                      "Allowance Type",
                      allowanceType,
                      "required"
                    )}
                  </p>
                </div>
                <div className="form-group custom">
                  <Input
                    label={"Standard Rate"}
                    name="maxLimit"
                    type="text"
                    imp="true"
                    controlFunc={props.onIntegerHandler}
                    value={maxLimit}
                    className="form-control"
                    error={props.validator1.message(
                      "Max limit",
                      maxLimit,
                      "required"
                    )}
                  />
                </div>
                {props.guestActive && 
                <div className="form-group custom">
                  <Input
                    label={"Guest House Rate"}
                    name="maxGuestLimit"
                    type="text"
                    imp="false"
                    controlFunc={props.onIntegerHandler}
                    value={props.maxGuestLimit}
                    className="form-control"
                    error={props.validator1.message(
                      "Guest House Rate",
                      props.maxGuestLimit,
                      "required"
                    )}
                  />
                </div>}
                {props.personalActive && 
                  <div className="form-group custom">
                    <Input
                      label={"Stay at Friends Rate"}
                      name="maxPersonalLimit"
                      type="text"
                      imp="false"
                      controlFunc={props.onIntegerHandler}
                      value={props.maxPersonalLimit}
                      className="form-control"
                    />
                  </div>}
                <div
                  className="form-group custom labelup"
                >
                  <label>Currency <span className="text-danger">*</span></label>

                  <Selects
                    name="currency"
                    value={props.selectedCurrency}
                    options={props.currenciesList}
                    onChange={props.onCurrencyChangeHandler}
                  />

                  <p className="text-danger fs-12">
                    {validator1.message(
                      "Currency",
                      selectedCurrency,
                      "required"
                    )}
                  </p>
                </div>
              </div>
              <div className="modal-last pm-30 pt-0 btn-group-margin">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn"
                >
                  {loading === true ? "Please Wait" : "Save"}
                </button>
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="EditTemplateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="AddTemplateModalLabel">
                Edit Configuration Set
              </h5>
              <button
                type="button"
                className="close d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={props.onUpdateHandler}>
              <div className="modal-middle pm-30">
                {props.editSelectedCountry.value !== '' && props.editSelectedCountry.value !== null && props.editSelectedCountry.value !== undefined ?
                  <>
                    <div
                      className="form-group custom labelup"
                    >
                      <label>Country</label>
                      <Selects
                        name="editSelectedCountry"
                        value={props.editSelectedCountry}
                        options={props.countriesList}
                        onChange={props.onEditCountryChangeHandler}
                      />

                    </div>
                    <div
                      className="form-group custom labelup"
                    >
                      <label>State <span className="text-danger">*</span></label>
                      <Selects
                        name="editSlectedState"
                        value={props.editSlectedState}
                        options={props.stateList}
                        onChange={props.onEditStateChangeHandler}
                      />
                    </div>
                    <div
                      className="form-group custom labelup"
                    >
                      <label>City <span className="text-danger">*</span></label>

                      <Selects
                        name="editSelectedCity"
                        value={props.editSelectedCity}
                        options={props.citiesList}
                        onChange={props.onEditCityChangeHandler}
                      />
                    </div>
                  </>
                  :
                  <div className="form-group custom">
                    <input className="form-control"
                      name="Global"
                      type="text"
                      value="Global"
                      readOnly
                    />
                    <label>Country <span className="text-danger">*</span></label>
                  </div>}
                <div
                  className="form-group custom labelup"
                >
                  <label>Allowance Type <span className="text-danger">*</span></label>
                  <Selects
                    name="editAllowanceType"
                    value={props.editAllowanceType}
                    options={props.allowanceTypeList}
                    onChange={props.onAllowanceChangeHandler}
                  />

                  <p className="fs-12 text-danger">
                    {validator.message(
                      "Allowance Type",
                      props.editAllowanceType,
                      "required"
                    )}
                  </p>
                </div>
                <div className="form-group custom">
                  <Input
                    label={"Standard Rate"}
                    name="editLimit"
                    type="text"
                    imp="true"
                    controlFunc={props.onEditIntegerHandler}
                    value={props.editLimit}
                    className="form-control"
                    error={props.validator.message(
                      "Max limit",
                      props.editLimit,
                      "required"
                    )}
                  />
                </div>
                {props.guestActive && 
                <div className="form-group custom">
                  <Input
                    label={"Guest House Rate"}
                    name="editGuestLimit"
                    type="text"
                    imp="false"
                    controlFunc={props.onEditIntegerHandler}
                    value={props.editGuestLimit}
                    className="form-control"
                  />

                </div>}
                {props.personalActive && 
                  <div className="form-group custom">
                    <Input
                      label={"Stay at Friends Rate"}
                      name="editPersonalLimit"
                      type="text"
                      imp="false"
                      controlFunc={props.onEditIntegerHandler}
                      value={props.editPersonalLimit}
                      className="form-control"
                    />
                  </div>
                }
                <div
                  className="form-group custom labelup"
                >
                  <label>Currency <span className="text-danger">*</span></label>

                  <Selects
                    name="currency"
                    value={props.editSelectedCurrency}
                    options={props.currenciesList}
                    onChange={props.onEditCurrencyChangeHandler}
                  />

                  <p className="text-danger fs-12">
                    {validator.message(
                      "Currency",
                      props.editSelectedCurrency,
                      "required"
                    )}
                  </p>
                </div>
              </div>
              <div className="modal-last pm-30 pt-0 btn-group-margin">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn"
                >
                  {loading === true ? "Please Wait" : "Save"}
                </button>
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="timeOfDayModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="timeOfDayModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="timeOfDayModalLabel">
                Time Of Day
              </h5>
              <button
                type="button"
                className="close d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-middle pm-30 pb-0">
              <div className="form-group mb-0">
                <label className="switch">
                  <input
                    checked={props.status ? true : false}
                    onChange={props.onStatusChangeHandler}
                    type="checkbox"
                  />
                  <span className="slider round" />
                </label>
                <span>Enable Time of Day</span>
              </div>
            </div>
            <div className="table-container min-height-medium mx-0">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="btn-group-margin">
                      {props.editAllowanceConfig.length > 0 ?
                        <button
                          type="button"
                          className="btn"
                          onClick={props.onEditAddClickHandler}
                        >
                          Add
                    </button>
                        : <button
                          type="button"
                          className="btn"
                          onClick={props.onAddTimeofDayClickHandler}
                        >
                          Add
                </button>}

                      {props.editAllowanceConfig.length > 0 ?
                        <button
                          onClick={props.onRemoveHandler}
                          type="button"
                          disabled={!props.selectedRow.length > 0}
                          className="btn"
                        >
                          Remove
                    </button>
                        :
                        <button
                          onClick={props.onEditRemoveHandler}
                          type="button"
                          disabled={!props.selectedRow.length > 0}
                          className="btn"
                        >
                          Remove
                </button>
                      }
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>From</th>
                      <th>To</th>
                      <th>Standard Rate <span className="text-danger">*</span></th>
                      {props.guestActive && 
                      <th>Guest Rate <span className="text-danger">*</span></th>}
                     {props.personalActive && 
                      <th>Stay at Friends Rate <span className="text-danger">*</span></th>}

                    </tr>
                  </thead>
                  <tbody>
                    {props.arrayCount.map((index) =>
                      <tr key={index} >
                        <td>
                          <label className="checkbox-container m-0 d-inline-block v-align-top">
                            <input type="checkbox"
                              onChange={(e) => props.onSelectHandler(index)}
                              checked={props.selectedRow.includes(index)} />
                            <span className="checkmark"></span>
                          </label>
                        </td>
                        <td>
                          <div className="form-group custom mb-0">
                            <div className="dropdown">
                              <button
                                type="button"
                                className="btn dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                {props['from' + index] ? props['from' + index] : '0'}
                              </button>
                              <div className="dropdown-menu">
                                {props['fromdropdown' + index] && props['fromdropdown' + index].map((list) => {
                                  return (
                                    <button
                                      key={list}
                                      type="button"
                                      className="dropdown-item"
                                      onClick={(e) => props.onDropdownSelectHandler('from' + index, list, index)}
                                    >{list}
                                    </button>
                                  )
                                })
                                }
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group custom mb-0">
                            <div className="dropdown">
                              <button
                                type="button"
                                className="btn dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                {props['to' + index] ? props['to' + index] : '0'}
                              </button>
                              <div className="dropdown-menu">
                                {props['todropdown' + index] && props['todropdown' + index].map((list) => {
                                  return (
                                    <button
                                      key={list}
                                      type="button"
                                      onClick={(e) => props.onDropdownToSelectHandler('to' + index, list, index)}
                                      className="dropdown-item"
                                    >{list}
                                    </button>
                                  )
                                })
                                }
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group custom mb-0">
                            <input className="form-control"
                              onChange={(e) => props.onRateChangeHandler('rate' + index, e)}
                              value={props['rate' + index] ? props['rate' + index] : ''} placeholder="&nbsp;" type="text" />
                          </div>
                            <p className="text-danger fs-12">
                              {props.validatorHourly.message(
                                "rate",
                                props['rate' + index],
                                "required"
                              )}
                            </p>
                        </td>
                        {props.guestActive && 
                        <td>
                          <div className="form-group custom mb-0">
                            <input className="form-control"
                              onChange={(e) => props.onRateChangeHandler('rateGuest' + index, e)}
                              value={props['rateGuest' + index] ? props['rateGuest' + index] : ''} placeholder="&nbsp;" type="text" />
                          </div>
                            <p className="text-danger fs-12">
                              {props.validatorHourly.message(
                                "rateGuest",
                                props['rateGuest' + index],
                                "required"
                              )}
                            </p>
                        </td>}
                        {props.personalActive && 
                        <td>
                          <div className="form-group custom mb-0">
                            <input className="form-control"
                              onChange={(e) => props.onRateChangeHandler('ratePersonal' + index, e)}
                              value={props['ratePersonal' + index] ? props['ratePersonal' + index] : ''} placeholder="&nbsp;" type="text" />
                          </div>
                            <p className="text-danger fs-12">
                              {props.validatorHourly.message(
                                "ratePersonal",
                                props['ratePersonal' + index],
                                "required"
                              )}
                            </p>
                        </td>}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-last pm-30 btn-group-margin">
              <button
                type="button"
                className="btn btn-border-success lg-padding"
                onClick={props.onHourlySubmitHandler}
              >
                {props.editAllowanceConfig.length > 0 ? 'Update' : 'Submit'}
              </button>

              <button
                type="button"
                className="btn close"
                data-dismiss="modal"
              >
                Cancel
                </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="CalculationModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="CalculationModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="CalculationModalLabel">
                Allowance Calculation Start
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5 className="sub-heading">For Oneway Trips</h5>
              <div className="form-group custom labelup">
                <label>Start on</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Arrival time
                          </button>
                  <div className="dropdown-menu">
                    <button
                      type="button"
                      className="dropdown-item"
                    >Arrival time
                              </button>
                  </div>
                </div>
              </div>
              <h5 className="sub-heading">For Round Trips</h5>
              <div className="form-group custom labelup">
                <label>Onward</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Arrival time
                          </button>
                  <div className="dropdown-menu">
                    <button
                      type="button"
                      className="dropdown-item"
                    >Arrival time
                              </button>
                  </div>
                </div>
              </div>
              <div className="form-group custom labelup">
                <label>Return</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Departure time
                          </button>
                  <div className="dropdown-menu">
                    <button
                      type="button"
                      className="dropdown-item"
                    >Arrival time
                              </button>
                  </div>
                </div>
              </div>
              <h5 className="sub-heading">For Multi City</h5>
              <div className="form-group custom labelup">
                <label>Start On</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Arrival time
                          </button>
                  <div className="dropdown-menu">
                    <button
                      type="button"
                      className="dropdown-item"
                    >Arrival time
                              </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn"
              >Save
              </button>
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                Cancel
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfigurationSetsPage;
