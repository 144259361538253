import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class AddTemplateModal extends Component {

  render() {
    const {
      isModalOpen,
      toggleModal,
      templates,
      ruleTemplateSelectHandler,
      selectedRuleTemplates,
      submitHandler
    } = this.props;

    return (
      <div>
        <Modal isOpen={isModalOpen} toggle={toggleModal} contentClassName="border-0">
          <ModalHeader className="modal-head">
            Add Travel Rule Template
          </ModalHeader>
          <ModalBody className="modal-middle">
            <p className="mod-modal-body">Select all the rule templates to be added.</p>
            <div className="heading py-3 px-4 bg-light">
              <h6 className="sub-heading mb-0">Template Name</h6>
            </div>
            <div className="modal-scroll">
              <ul className="search-list-div">
                {templates.length ? templates.map(template => {

                  return (
                    <li className={selectedRuleTemplates.some(i => i.id == template.id) ? "list selected" : "list"}
                      key={template.id} onClick={e => ruleTemplateSelectHandler(template)} >{template.ruleName}</li>
                  )
                }) : (
                    <li className="list text-center">No Templates found</li>
                  )}
              </ul>
            </div>
          </ModalBody>
          <ModalFooter className="modal-last border-top-0 pm-30 pt-3 btn-group-margin">
            <button type="button" className="btn" onClick={submitHandler} >Save</button>
            <button type="button" className="btn"
              onClick={toggleModal} >Cancel
            </button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
