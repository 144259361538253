import React from "react";
import NumberFormat from 'react-number-format';

export const FlightComboCartItem = props => {
  const { index, item, secondsToHms, removeCart, nonEdit, chooseFlight, selectedItem, className } = props;
  let currencyType = props.baseCurrency
  return (
    <li key={index}
      className={(selectedItem !== `flight${index}`) ? "itinerary-heading" : "itinerary-heading active"}
      onClick={e => chooseFlight(item, 'combo', `flight${index}`)}>
      {item?.fareContent?.fare?.flight_policy_violated
        ? (
          <div className="text-danger fs-12 px-4 py-2 border-bottom-light"> <i className="material-icons align-middle mr-1 fs-14">error</i> {item.reasons ? item.reasons : ''}</div>
        )
        : ("")
      }
      {/* <div className="text-danger fs-12 px-4 py-2 border-bottom-light"> <i className="material-icons align-middle mr-1 fs-14">error</i> {item.reasons ? item.reasons : ''}</div> */}
      {item.fareContent && item.fareContent.fare && item.fareContent.fare.out_of_policy && (
        <p className="out-o hover-v">
          Out of policy
          <div className="hover-name bottom out-of-p">
            <p>This option breaks one or more company travel rules.</p>
            <p><i className="material-icons">
              warning
              </i> This option is over the policy limit.</p>
            {/* <p>
            <i  className="material-icons">
              warning
            </i> 2 or more company employees in the same flight.</p> */}
            <p>User Reason - {" "}
              <b>{item.reasons}</b>
            </p>
          </div>
        </p>
      )}
      <div className="f-container">
        {(nonEdit) ? "" :
          <button className="btn btn-nothing remove hover-v" onClick={e => removeCart(item.fare.flight_fare_id)}>
            Remove
                </button>
        }
        <div className="left-f">
          {item.fareContent.map((flightList, keyItem) => {
            return (
              <div className="left-f-div" key={keyItem}>
                <div className="f-cont f-logo">
                  {flightList.fare && flightList.fare && (
                    <img
                      width="50"
                      src={
                        flightList.fare.flight_image
                          ? flightList.fare.flight_image
                          : "/assets/images/booking/flight-dummy.png"
                      }
                      alt="images"
                    />
                  )}
                </div>
                <div className="f-cont f-time">
                  <b>{flightList.fare.flight_depature_time}{" "}
                                        - {" "}
                    {flightList.fare.flight_arrival_time}
                  </b>
                  <br />
                  <span>{flightList.fare.flight_airline}</span>
                </div>
                <div className="f-cont f-stop">
                  <b>{flightList.content.length - 1} Stop {" "}</b>
                </div>
                <div className="f-cont f-total">
                  {flightList && (
                    <b>
                      {flightList.fare
                        ? secondsToHms(
                          flightList.fare.flight_onward_total_duration
                        )
                        : ""}
                    </b>
                  )}
                  <br />
                  {flightList.fare.from} - {flightList.fare.to}
                </div>
                <div className="clearfix"></div>
              </div>
            )
          })}
        </div>
        {item.fareContent.map((flightList, keyItem) => {
          return (
            <div key={keyItem} className={(keyItem === 0) ? "right-f right-f1" : "right-f"}>
              {
                (keyItem === 0) &&
                <div>
                  <h5>
                    {currencyType} &nbsp;
                                        <NumberFormat value={item ? item.fare.flight_total_price && item.fare.flight_total_price.toFixed(2) : "0"} displayType={'text'} thousandSeparator={true} />
                  </h5>
                  <button className="btn btn-nothing">View Details <i className="material-icons">
                    arrow_forward_ios
                                    </i></button>
                </div>
              }
            </div>
          )
        })}
        {/* <div class="dropdown more-dropdown show flight-drop">
                    <button class="btn btn-nothing dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <i class="material-icons">more_vert</i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" x-placement="bottom-end" style={{ "right": "0", "left": "auto" }}>
                        <button class="dropdown-item" type="button" onClick={'javascript.void(0)'}>Reschedule</button>
                        <button class="dropdown-item" type="button" onClick={'javascript.void(0)'}>Cancel</button>
                    </div>
                </div> */}
        <div className="clearfix"></div>
        {(className && className === "show-fluctuation") &&
          <div>
            {item.fareContent.map((flightList, keyItem) => {
              return (
                <div key={keyItem}>
                  {
                    (keyItem === 0) &&
                    <div className="border-top priceleft_padding">
                      <p className="mb-0 d-block pb-0">Price Fluctuation</p>
                      <p className="d-block pt-0">
                        <span><b>{currencyType}{" "}<NumberFormat value={item ? item.fare.flight_total_price && item.fare.flight_total_price.toFixed(2) : "0"} displayType={'text'} thousandSeparator={true} /> </b></span>
                        <span><i class="material-icons">arrow_right_alt</i></span>
                        <span className="text-danger"><b> {currencyType}{" "}<NumberFormat value={item ? item.fare.flight_total_price && item.fare.flight_total_price.toFixed(2) : "0"} displayType={'text'} thousandSeparator={true} /></b></span>
                      </p>
                    </div>
                  }
                </div>
              )
            })
            }
          </div>
        }
      </div>

      {/* <div className="arrow">
                <i className="material-icons">
                    arrow_forward_ios
                </i>
            </div>
            <div className="destiny">
                {item.fareContent.map((flightList, keyItem) => {
                    return (
                        <div key={keyItem} className="left-destination flight mb-2">
                            <div className="abs-pin" />
                            <div className="totalDistance sm">
                                <div className="circle">
                                    <i className="material-icons">
                                        flight
                                     </i>
                                </div>
                            </div>
                            <div />
                            <p className="title">
                                {item.flight_airport[item.flight_from].n} (
                            {item.flight_airport[item.flight_from].c})
                        </p>
                            <div className="center">
                                <p className="title">
                                    {flightList.fare && flightList.fare && (
                                        <img
                                            width="50"
                                            src={
                                                flightList.fare.flight_image
                                                    ? flightList.fare.flight_image
                                                    : "/assets/images/booking/flight-dummy.png"
                                            }
                                            alt="images"
                                            className="mr-3"
                                        />
                                    )}
                                    {item.fareContent && (
                                        <b>
                                            {item.fareContent.fare
                                                ? secondsToHms(
                                                    item.fareContent.fare.flight_onward_total_duration
                                                )
                                                : ""}
                                        </b>
                                    )}
                                    by {item.fareContent ? item.fare.flight_airline : ""}
                                </p>
                                {(keyItem === 0) &&
                                    <p className="label">
                                        Rs.{" "}
                                        {item.fareContent ? item.fare.flight_total_price : ""}
                                    </p>}
                            </div>
                            <p className="title">
                                {item.flight_airport[item.flight_to].n} (
                        {item.flight_airport[item.flight_to].c})
                    </p>
                        </div>
                    )
                })}
            </div> */}
    </li>
  );
};
export default FlightComboCartItem;
