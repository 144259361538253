import React, { Component } from "react";
import io from "socket.io-client";
import $ from 'jquery';
import { chatService } from "src/_services"
import cryptr from "src/_helpers/crypty";


class ReceiverChatApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomId: "",
      hide: false,
      message: '',
      messages: [],
      receiverDetails: {},
      receiverId: ''
    };

    this.socket = io('http://192.168.0.159:3015');
    this.socket.on("connection", (roomId) => {
      this.setState({ roomId: roomId });
      this.getMessages(roomId);
    });
    this.socket.emit('online', { userId: this.senderDetails.id, receiverId: this.state.receiverId });
    this.socket.on("RECEIVE_MESSAGE", (data) => {
      this.setState({
        roomId: data.roomId,
        receiverId: data.senderId,
        senderId: data.receiverId,
        createdAt: data.createdAt
      })
      this.receiveMessage(data);
    })
    this.url = "/assets/sounds/chat_tone.mp3";
    this.audio = new Audio(this.url);

  }
  senderDetails = JSON.parse(cryptr.decrypt(localStorage["440a24"]));
  componentDidMount = () => {
    this.getReceiverDetails();
  }

  getReceiverDetails = async () => {
    let receiverId = "5d20a48f31326c157c08c748";
    let res = await chatService.getReceiverDetails(receiverId);
    if (res) {
      this.setState({ receiverDetails: res.result })
    }
  }

  getMessages = async (roomId) => {
    let res = await chatService.getMessages(roomId);
    if (res) {
      this.setState({ messages: res.chatResponse.reverse() })
    }
    this.scrollToBottom();
  }
  handleChangeMessage = (e) => {
    this.setState({
      message: e.target.value
    })
  }

  sendMessage = (e) => {
    e.preventDefault();
    if (this.state.message !== '') {
      this.audio.play();
      this.setState({ messages: [...this.state.messages, { message: this.state.message, senderId: this.senderDetails.id, createdAt: new Date().getHours() + ":" + new Date().getMinutes() }] });

      this.socket.emit('SEND_MESSAGE', {
        message: this.state.message,
        roomId: this.state.roomId,
        senderId: this.senderDetails.id,
        recieverId: this.state.receiverId,
        createdAt: new Date().getHours() + ":" + new Date().getMinutes()
      })
      this.scrollToBottom();
    }
    this.setState({ message: '' });
  }
  scrollToBottom = () => {
    let chat = document.getElementById("chatList");
    chat.scrollTop = chat.scrollHeight + 100;
  };
  receiveMessage = (data) => {
    this.setState({ messages: [...this.state.messages, data] });
    this.audio.play();
  }
  openChat2() {
    $(".chat-head-receiver").addClass('d-none');
    $(".chat-body-receiver").removeClass('d-none');
    $(".chat-footer-container-receiver").removeClass('d-none');
    $(".chat-head2-receiver").removeClass('d-none');
  }
  closeChat2() {
    $(".chat-footer-container-receiver").addClass('d-none');
    $(".chat-head2-receiver").addClass('d-none');
    $(".chat-body-receiver").addClass('d-none');
    $(".chat-footer-container-receiver").addClass('d-none');
  }
  render() {
    const { receiverDetails } = this.state;
    return (
      <div className="chat-container-receiver d-none">
        <div className="chat-head-receiver" onClick={e => this.openChat2()}>
          <div className="icon-div">
            <i className="material-icons">
              chat
            </i>
          </div>
          <div className="text-div">
            <p>Hey you have new messages.</p>
            <h6>Chat with Travellers</h6>
          </div>
        </div>
        <div className="chat-head-receiver chat-head2-receiver d-none">
          <div className="icon-div goback"
            onClick={e => this.closeChat2()}
          >
            <i className="material-icons">
              close
              </i>
          </div>
          <div className="text-div">
            <div className="float-left">
              {receiverDetails.profileImage ?
                <img src={receiverDetails.profileImage} style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
                :
                <div className="prof online" style={{ backgroundImage: `url(/assets/images/jenni.jpg)` }}></div>
              }
            </div>
            <div className="float-left">
              <div className="name ml-2">
                <p>online</p>
                <h6>
                  {receiverDetails && receiverDetails.fieldData ? (receiverDetails.fieldData[0].fieldValue + ' ' + receiverDetails.fieldData[1].fieldValue + ' ' + receiverDetails.fieldData[2].fieldValue) : '--'}
                </h6>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>

        <div className="chat-body-receiver d-none" >
          <div className="abs-bottom" id="chatList">
            {this.state.messages && this.state.messages.length ? this.state.messages.map((message, i) => {
              return (
                <>
                  {(message.senderId === this.senderDetails.id) ?
                    <div className="right">
                      <div className="chat-box">{message.message}
                        <span className="time">{message.createdAt}</span>
                      </div>
                    </div>
                    :
                    <div className="left">
                      <div className="chat-box">{message.message}
                        <span className="time">{message.createdAt}</span>
                      </div>
                    </div>
                  }
                </>
              )
            })
              :
              ''}
          </div>
        </div>
        <div className="chat-footer-container-receiver d-none">
          <form onSubmit={(e) => this.sendMessage(e)}>
            <div className="chat-footer">
              <input type="text"
                placeholder="Type a message..."
                value={this.state.message}
                onChange={(e) => this.handleChangeMessage(e)} />
              <button type="submit"><i className="material-icons">
                send
              </i></button>
              <div className="clearfix"></div>
            </div>
          </form>
        </div>
      </div>

    );
  }
}
export default ReceiverChatApp;  