import React, { Component } from 'react';
import { masterService } from '../../../_services';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";

import MasterMenu from "./master-menu";
import $ from 'jquery';
import { toast } from 'react-toastify';
import './Tree/tree-view.css';
import mockData from './Tree/mockData';
import TreeNode from './Tree/TreeNode';
import { alertConstants } from '../../../_constants'

class MasterMenuManage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.state = {
            activePage: 1,
            itemPerPage: 10,
            listMenu: [],
            addFields: {
                roleName: ''
            },
            addErrors: {
                roleName: ''
            },
            editFields: {
                roleName: '',
                id: ''
            },
            editErrors: {
                roleName: ''
            }
        };
    }
    componentDidMount() {
        this.getList()
    } 
    addSubmit(e) {
        e.preventDefault();
        if (this.handleAddValidation()) {
            this.loading = true;
            masterService.addRoles(this.state.addFields)
                .then(
                    data => {
                        if (!toast.isActive(this.toastId)) {
                            this.toastId = toast.success(alertConstants.CREATED_SUCCESSFULLY)
                        }
                        this.getRole()
                        $('.close').click();
                    }, error => {
                        this.loading = false;
                        if (error.statusCode === 422) {
                            let errors = error.message
                            Object.keys(errors).map(key => {
                                return (
                                    toast.error(errors[key])
                                );
                            })
                        } else {
                            toast.error(error.message)
                        }
                    }
                ).finally(() => { this.loading = false; this.forceUpdate() });
        }
    }
    getList() {
        this.setState({ listMenu: [] })
        this.loader = true;
        masterService.getMenu()
            .then(
                data => {
                    if (data != null) {
                        this.count = data.count
                        this.setState({ listMenu: data.result })
                    }
                }
            ).finally(() => { this.loader = false; this.forceUpdate() });

    }

    render() {
        const { listMenu, itemPerPage } = this.state;
        let tree1 = mockData.mock1.map(function (child) {
            return <TreeNode key={child.id} data={child} />;
        }.bind(this));
        return (
            <div className="inner-wrapper">
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">T</div>
                        <div className="right">
                            <h6 className="heading">Category Tree</h6>
                            <p className="text">Lorem Ipsum is simply dummy</p>
                        </div>
                    </div>
                </div>
                <div className="p-padding">
                    <div className='file-tree no-scroll'>
                        {tree1}
                    </div>
                </div>
            </div>
        )
    }
}


export default MasterMenuManage