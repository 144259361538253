import React from "react";
import PropTypes from "prop-types";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const CabCompanyList = ({
  children,
  dropdownOnClick,
  toggleOnClick,
  ...props
}) => {
  return (
    <ButtonDropdown {...props}>
      <DropdownToggle onClick={toggleOnClick} caret>
        Button Dropdown
      </DropdownToggle>
      <DropdownMenu>
        {/* {props.companies.map(companyInfo => <Child {...companyInfo} />)} */}
        {children}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

CabCompanyList.propTypes = {
  toggleOnClick: PropTypes.func
};

export const CabCompanyListItem = ({ imgSrc, children, name, ...props }) => (
  <DropdownItem>
    {props.imgSrc ? <img src={props.imgSrc} alt="" /> : null}
    {children}
  </DropdownItem>
);

CabCompanyListItem.propTypes = {
  imgSrc: PropTypes.string,
  name: PropTypes.string
};

CabCompanyList.Item = CabCompanyListItem;

export default CabCompanyList;
