import React from "react";
import { NavLink } from "react-router-dom";
import ExpenseTypesMenu from "../expense-types-menu";
import { Input, Select } from "../../../../../../common/_forms";

const ExpenseTypeStep1Page = props => {
  const {
    state,
    listCategory,
    categoryName,
    handleChange,
    clickRadio,
    availableFor,
    itemisation,
    handleChangeText,
    error,
    addSubmit,
    listItemzations,
    itemizationName,
    isEdit,
    updateSubmit,
    systemExpenseTypes,
    handleSystemExpenseType,
    expenseTypeId,
    editContent,
  } = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">E</div>
          <div className="right">
            <h6 className="heading">Expense Types</h6>
            <p className="text">Add/Modify Expense Type</p>
          </div>
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <ExpenseTypesMenu
            expenseTypeId={expenseTypeId}
          />
          <div className="">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <form
                  onSubmit={isEdit === false ? addSubmit : updateSubmit}
                  autoComplete="off"
                >
                  <div className="form-group custom">
                    <Input
                      label="Name"
                      type="text"
                      name="expenseTypeName"
                      className="bg-white"
                      error={error.message(
                        "expenseTypeName",
                        state.expenseTypeName,
                        "required"
                      )}
                      value={state.expenseTypeName}
                      controlFunc={handleChangeText}
                    />
                  </div>
                  <h6 className="sub-heading">Available for <span class="text-danger">*</span> </h6>
                  <div className="form-group">
                    {availableFor &&
                      availableFor.map((item, key) => {
                        return (
                          <label key={key} className="radio-container">
                            {item.name}
                            <input
                              type="radio"
                              value={item.id}
                              onChange={e =>
                                clickRadio(item.id, "availableFor")
                              }
                              checked={item.id === state.availableFor}
                              name="radio"
                            />
                            <span className="checkmark" />
                          </label>
                        );
                      })}
                    <div className="help-block">
                      {error.message(
                        "AvailableFor",
                        state.availableFor,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="form-group custom labelup">
                    <label>Has this Parent Category <span class="text-danger">*</span></label>
                    {listCategory && (
                      <Select
                        placeholder={categoryName}
                        keyName={"expenseCategoryName"}
                        options={listCategory}
                        controlFunc={e =>
                          handleChange(e, "hasThisParentCategory")
                        }
                        error={error.message(
                          "hasThisParentCategory",
                          state.hasThisParentCategory,
                          "required"
                        )}
                      />
                    )}
                  </div>
                  {/* Start of System Expense Type */}

                  <div className="form-group custom labelup">
                    <label>System Expense Type </label>
                    {listCategory && (
                      <Select
                        placeholder={state.systemExpenseType ?.name || 'Select an expense type'}
                        keyName={"name"}
                        options={state.systemExpenseTypes}
                        controlFunc={handleSystemExpenseType}
                        error={error.message(
                          "systemExpenseType",
                          state.systemExpenseType,
                          "required"
                        )}
                      />
                    )}
                  </div>

                  {/* End of System Expense Type */}
                  <h6 className="sub-heading">Itemization <span class="text-danger">*</span></h6>
                  <div className="form-group">
                    {/* Om Code */}
                    {/* {
                      state.availableFor == 1 && (
                        <label className="radio-container">
                          Not Allowed
                            <input
                            type="radio"
                            checked={state.itemization === 2}
                            value={state.itemization}
                            onChange={e => clickRadio(2, "itemization")}
                            name="radio1"
                          />
                          <span className="checkmark" />
                        </label>
                      )
                    } */}
                    {
                      (state.availableFor == 0 || state.availableFor == 1 )&& (
                        <>
                         <label className="radio-container">
                          Not Allowed
                            <input
                            type="radio"
                            checked={state.itemization === 2}
                            value={state.itemization}
                            onChange={e => clickRadio(2, "itemization")}
                            name="radio1"
                          />
                          <span className="checkmark" />
                        </label>
                          <label className="radio-container">
                            Required
                            <input
                              type="radio"
                              checked={state.itemization === 0}
                              value={state.itemization}
                              onChange={e => clickRadio(0, "itemization")}
                              name="radio1"
                            />
                            <span className="checkmark" />
                          </label>
                          <label className="radio-container">
                            Optional
                      <input
                              type="radio"
                              checked={state.itemization === 1}
                              value={state.itemization}
                              onChange={e => clickRadio(1, "itemization")}
                              name="radio1"
                            />
                            <span className="checkmark" />
                          </label>
                        </>
                      )
                    }

                    {
                      state.availableFor == 2 && (
                        <label className="radio-container">
                          Required
                      <input
                            type="radio"
                            checked={state.itemization === 0}
                            value={state.itemization}
                            onChange={e => clickRadio(0, "itemization")}
                            name="radio1"
                          />
                          <span className="checkmark" />
                        </label>
                      )
                    }

                    {/* End of Om code for restricting choices for itemization */}

                    {/* Below code is original code by dinesh. */}
                    {/* {itemisation &&
                      itemisation.map((item, key) => {
                        return (
                          <label key={key} className="radio-container">
                            {item.name}
                            <input
                              type="radio"
                              checked={item.id === state.itemization}
                              value={item.id}
                              onChange={e => clickRadio(item.id, "itemization")}
                              name="radio1"
                            />
                            <span className="checkmark" />
                          </label>
                        );
                      })} */} 
                      <div className="help-block">
                        {error.message(
                          "itemization",
                          state.itemization,
                          "required"
                        )}
                      </div> 
                  </div>
                  {/* {state.itemization !== 2 ? (
                    <div>
                      <h6 className="sub-heading">Selection Controls</h6>
                      <div className="form-group custom labelup mb-2">
                        <label>Itemization Wizard</label>
                        {listItemzations && (
                          <Select
                            placeholder={itemizationName}
                            keyName={"itemizationName"}
                            options={listItemzations}
                            controlFunc={e =>
                              handleChange(e, "itemizationWizard")
                            }
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                  {/* <div className="mb-4">
                    <button
                      type="button"
                      className="btn   btn-border-basic btn-block lg-padding"
                    >
                      Expense Types Available for Itemization
                    </button>
                  </div> */}

                  {(isEdit === false) ?
                    <div className="btn-group-margin mt-4">
                      <button
                        type="submit"
                        className="btn btn-border-success lg-padding" >
                        Next
                      </button>
                      <NavLink
                        to="/administration/expense/ex/types/list"
                        className="btn btn-border-basic lg-padding"
                      >
                        Cancel
                    </NavLink>
                    </div>
                    :
                    <div className="btn-group-margin mt-4">
                      {(isEdit === true && editContent) ?
                        <button
                          type="submit"
                          className="btn btn-border-success lg-padding">
                          Update & Next
                          </button>
                        :
                        <NavLink
                          to={`/administration/expense/ex/types/expense-types-step2/${expenseTypeId}`}
                          className="btn btn-border-success lg-padding">
                          Next
                          </NavLink>
                      }
                      <NavLink
                        to="/administration/expense/ex/types/list"
                        className="btn btn-border-basic lg-padding"
                      >
                        Cancel
                      </NavLink>
                    </div>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
export default ExpenseTypeStep1Page;
