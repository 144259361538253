import React, { Component } from "react";
import { getFormattedDateFromDDMMYYYYFormat } from "src/utils/util";

class TrainSummary extends Component {
    render() {
        return (
            <div className="summary active"> 
                <div className="d-inline-block text-center">
                    <p><i className="material-icons">train</i></p>
                </div>
                <div className="d-inline-block">
                    <p className="title">{this.props.trainRequest.train_from.stationCity + ' (' + this.props.trainRequest.train_from.stationCode + ') to ' + this.props.trainRequest.train_to.stationCity + ' (' + this.props.trainRequest.train_to.stationCode + ')'}</p>

                    <p className="label">{getFormattedDateFromDDMMYYYYFormat(this.props.trainRequest.train_start_timestamp)}{" "}|{" "}{this.props.count} train(s) found</p>

                </div>
            </div>
        );
    }
}

export default TrainSummary;
