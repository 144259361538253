import React, { Component } from "react";
import FullDetailsPage from "./_components/full-details";
import cryptr from "../../../../_helpers/crypty";
import { bookingServices } from "../../../../_services/booking.service";
import InfiniteScroll from "react-infinite-scroll-component";
import FlightList from "./_components/FlightList";
import { cartServices, masterService } from "src/_services";
import FilterFlight from "./_components/FilterFlight";
import ComboFlightList from './_components/comboFlightList';
import SortHeadFlight from "./_components/sortHeadFlight";
import $ from 'jquery';
import { toast } from "react-toastify";
import FlightLoader from "./_components/FlightLoader";
import FlightNoResults from "./_components/NoResults";
import { getFormattedDateFromIsoString } from "src/utils/util";
import _ from 'lodash';
class FlightRoundPage extends Component {
  constructor(props) {
    super(props);
    var req = this.props.match.params.data;
    var resp = localStorage["15c059"];
    let item = JSON.parse(cryptr.decrypt(localStorage['1UC43']))
    if (req === resp) {
      this.req = JSON.parse(cryptr.decrypt(this.props.match.params.data));
    }
    this.state = {
      value: {
        start: "0:00",
        end: "23:00"
      },
      cartCount: 0,
      loadingUniqueId: '',
      loading: false,
      displayType: 0,
      listOfFlights: [],
      listOfFlightsReturn: [],
      comboFlightList: [],
      selectedOption: false,
      isOpenPolicy: false,
      storePolicyCart: [],
      reasons: '',
      violationsId: '',
      cardList: [],
      hasMore: true,
      hasMoreReturn: true,
      typeOfView: 1,
      baggageInfo: [],
      listOfCurrency: [],
      currency: item.currencyCode,
      fromTime: "",
      toTime: "",
      landFrom: this.req.from,
      airportListFilter: [],
      airLineListFilter: [],
      isCombo: false,
      sortStop: "",
      sortDate: "",
      sortPrice: "",
      sortFlight: "",
      isIntl: true,
    };
  }
  valid = true;
  userId = "";
  sortList = [
    { type: "sortPrice" },
    { type: "sortStop" },
    { type: "sortDate" },
    { type: "sortFlight" },
  ]
  closeFilter() {
    $("#filter-card").removeClass("show");
  }
  componentDidMount() {
    sessionStorage.removeItem("modify");
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.userId = JSON.parse(cryptr.decrypt(localStorage["440a24"]));
    this.loadFlightForDepartAndReturn();
    this.getCardList();
    this.getCartDetail();
    this.getCurrencyCode();
  }
  componentWillUpdate() {
    if (sessionStorage.modify !== undefined && sessionStorage.modify) {
      this.setState({ loading: true });
      this.listOfFlightsReturn = [];
      this.listOfFlights = [];
      this.req = JSON.parse(cryptr.decrypt(sessionStorage.req));
      this.url = this.props.match.params.data;
      this.filterSet = false;
      this.resetFilter()
      this.loadFlightForDepartAndReturn();
      this.getCardList();
      this.getCartDetail();
      sessionStorage.removeItem("modify");
      sessionStorage.removeItem("req");
    }
  }

  loadFlightForDepartAndReturn = async () => {
    await this.getFlightsDepart(1);
    this.getFlightsReturn(1);
  }

  handleGetReason = (e) => {
    this.setState({
      reasons: e.target.value
    })
  }
  selectedItem = [];
  uniqueId = [];
  addToCart = _.debounce(async (e, tripType, uniqueId) => {
    if (this.state.isOpenPolicy) {
      if (this.state.violationsId === '') {
        return toast.error('Violations is required');
      } else if (this.state.reasons === '') {
        return toast.error('Reasons is required');
      }
    }
    var req = []
    if (tripType === 'combo') {
      req = {
        type: "flight",
        userId: this.userId.id,
        TRIP_ID: sessionStorage.getItem('TRIPID'),
        // tripType:"round",//added for  add/cart api
        data: {
          tripFlight: tripType,
          flight_fare_id: e.flight_fare_id,
          adults: this.req.adults,
          classType: this.req.classType,
          departureDate: this.req.departureDate,
          returnDate: this.req.returnDate,
          from: this.req.from,
          to: this.req.to
        }
      };
    } else {
      req = {
        type: "flight",
        userId: this.userId.id,
        tripType: "round",
        TRIP_ID: sessionStorage.getItem('TRIPID'),
        data: {
          tripFlight: tripType,
          flight_fare_id: e.flight_fare_id,
          adults: this.req.adults,
          classType: this.req.classType,
          departureDate: this.req.departureDate,
          returnDate: this.req.returnDate,
          from: this.req.from,
          to: this.req.to
        }
      };
    }
    var remove = {
      TRIP_ID: sessionStorage.getItem('TRIPID'),
      type: "flight",
      flight_fare_id: e.flight_fare_id
    };
    if (tripType === 'combo') {
      const int = this.selectedItem.indexOf(e.flight_fare_id);
      if (int === -1) {
        // out of policy code starts
        if (e.out_of_policy) {
          this.setState({
            isOpenPolicy: true,
            storePolicyCart: {
              e: e,
              tripType: tripType,
              uniqueId: uniqueId
            }
          })
          // if (!this.state.isOpenPolicy) {
            if (this.state.reasons) {
              $(".close").click();
            }
            if (!this.state.reasons) {
              $("#openOutofPolicy").click();
              return;
            } else {
            this.setState({
              isOpenPolicy: false,
              storePolicyCart: {},
              reasons: "",
              violationsId: ""
            })
          }
        }
        // if (this.state.reasons !== '') {
        //   req.reasons = this.state.reasons
        //   req.violationsId = this.state.violationsId

        //   $('.close').click()
        // }
        if (this.state.reasons !== "") {
          req.reasons = this.state.reasons;
          req.violationsId = this.state.violationsId;
          $(".close").click();
        }
        // to hide below two line get multi select
        this.selectedItem = []
        this.uniqueId = []
        sessionStorage.setItem('tripType', 'round')
        let assume = await this.addRemove(1, req)
        if (assume) {
          this.selectedItem.push(e.flight_fare_id);
          this.uniqueId.push(uniqueId);
        }
      } else {
        sessionStorage.removeItem('tripType')
        let assume = await this.addRemove(2, remove);
        if (assume) {
          this.selectedItem.splice(int, 1);
          this.uniqueId.splice(int, 1)
        }
      }
    } else {
      const intl = this.selectedItem.indexOf(e.flight_fare_id);
      if (intl === -1) {
        // out of policy code starts
        if (e.out_of_policy) {
          this.setState({
            isOpenPolicy: true,
            storePolicyCart: {
              e: e,
              tripType: tripType,
              uniqueId: uniqueId
            }
          })
          // if (!this.state.isOpenPolicy) {

            if (this.state.reasons) {
              $(".close").click();
            }
            if (!this.state.reasons) {
              $("#openOutofPolicy").click();
              return;
            } else {
            this.setState({
              isOpenPolicy: false,
              storePolicyCart: {},
              reasons: "",
              violationsId: ""
            })
          }
        }
        if (this.state.reasons !== '') {
          req.reasons = this.state.reasons
          req.violationsId = this.state.violationsId

          $('.close').click()
        }
        this.setState({ loadingUniqueId: uniqueId })
        // to hide below two line get multi select
        this.selectedItem = []
        this.uniqueId = []
        sessionStorage.setItem('tripType', 'round')
        let assume = await this.addRemove(1, req)
        if (assume) {
          this.selectedItem.push(e.flight_fare_id);
          this.uniqueId.push(uniqueId)
          if (this.state.displayType === 0) {
            this.setState({
              displayType: 1
            })
          }
        }
      } else {
        sessionStorage.removeItem('tripType')
        let assume = await this.addRemove(2, remove);
        if (assume) {
          this.selectedItem.splice(intl, 1);
          this.uniqueId.splice(intl, 1)
        }
      }
    }
  },700);
  handleChangeReasonId = (e) => {
    this.setState({
      violationsId: e.target.value
    })
  }
  cancelPolicy = () => {
    this.setState({
      isOpenPolicy: false,
      storePolicyCart: {},
      reasons: "",
      violationsId: ""
    })
  }
  addRemove = async (type, req) => {
    if (type === 1) {
      var resp = await cartServices.addCartUserData(req);
      this.setState({ loadingUniqueId: '' })
      if (resp) {
        this.getCardList();
        this.getCartDetail();
        return true;
      } else {
        return false;
      }
    } else {
      var response = await cartServices.removeCartUserData(req);
      this.setState({ loadingUniqueId: '' })
      if (response) {
        this.getCardList();
        this.getCartDetail();
        return true;
      } else {
        return false;
      }
    }
  };
  filterSet = false;
  stopFilter = e => {
    this.filterSet = true;
    (this.stop === e) ? this.stop = "" : this.stop = e;
    (this.state.displayType === 0) ? this.getFlightsDepart(1) : this.getFlightsReturn(1)
  };

  layoverTimeFilter = e => {
    this.filterSet = true;
    (this.layoverTime === e) ? this.layoverTime = "" : this.layoverTime = e;
    (this.state.displayType === 0) ? this.getFlightsDepart(1) : this.getFlightsReturn(1)
  };
  getAirline = (e, airline) => {
    const int = this.preferredAirline.indexOf(airline);
    if (int === -1) {
      this.preferredAirline.push(airline);
      this.selectedAirLine.push({ code: e, airLine: airline });
    } else {
      this.preferredAirline.splice(int, 1);
      this.selectedAirLine.splice(int, 1);
    }
    this.filterSet = true;
    (this.state.displayType === 0) ? this.getFlightsDepart(1) : this.getFlightsReturn(1)
  };
  getAirPort = (e, airline) => {
    const int = this.preferredAirport.indexOf(airline);
    if (int === -1) {
      this.preferredAirport.push(airline);
    } else {
      this.preferredAirport.splice(int, 1);
    }
    this.filterSet = true;
    (this.state.displayType === 0) ? this.getFlightsDepart(1) : this.getFlightsReturn(1)
  };
  getCartDetail = async () => {
    let tripID = sessionStorage.getItem('TRIPID');
    var resp = await cartServices.getCartListDetail(tripID);
    if (resp) {
      // this.selectedItem = resp;
      let assume = resp.data.flightData;
      if (assume) {
        await assume.forEach((item, key) => {
          if (item.fareContent && item.fareContent.fare) {
            const index = this.selectedItem.indexOf(
              item.fareContent.fare.flight_fare_id
            );
            if (index === -1) {
              this.selectedItem.push(item.fareContent.fare.flight_fare_id);
              this.uniqueId.push(item.fareContent.UNIQUE_ID)
            }
          }
        });
        let count = resp.data.flightData.length + resp.data.hotelData.length;
        this.setState({ cartCount: count })
      }
    }
  };
  getCardList = async () => {
    let tripID = sessionStorage.getItem('TRIPID');
    var resp = await cartServices.getCartUserList(tripID);
    if (resp) {
      this.setState({ cardList: resp })
    }
  };
  page = 1;
  limit = 10;
  allItem = [];
  listOfFlights = [];
  count = 0;
  maxPage = 1;
  stop = "";
  preferredAirline = [];
  layoverTime = "";
  preferredAirport = [];
  airportList = [];
  airLineList = [];
  selectedAirLine = [];
  changeDisplay = e => {
    this.resetFilter()
    if (this.filterSet) {
      this.filterSet = false;
      if (e === 0) {
        this.setState({
          airportListFilter: this.airportList,
          airLineListFilter: this.airLineList,
        })
      } else {
        this.setState({
          airportListFilter: this.airportListReturn,
          airLineListFilter: this.airLineListReturn,
        })
      }
      let item = JSON.parse(cryptr.decrypt(localStorage['1UC43']))
      this.setState({ loading: true, currency: item.currencyCode, selectedOption: false })
    }
    (e === 1) ? this.getFlightsReturn(1) : this.getFlightsDepart(1);
    this.setState({ displayType: e, landFrom: (e === 0) ? this.req.from : this.req.to })
  };
  resetFilter() {
    this.stop = ""
    this.preferredAirline = [];
    this.layoverTime = "";
    this.preferredAirport = [];
    this.sortStop = "";
    this.sortDate = "";
    this.sortPrice = "";
    this.sortFlight = "";
    this.refundable = "";
    this.selectedOptionRefund = false;
    this.selectedAirLine = []
    this.setState({
      value: {
        start: "0:00",
        end: "23:00",
      }, sortDate: "", sortFlight: "", sortPrice: "", sortStop: ""
    })
  }

  getFlightsDepart = async type => {
    if (type !== 2) {
      this.setState({ loading: true })
    }
    if (type === 1) {
      this.listOfFlights = [];
      this.comboFlightList = [];
      this.count = 0;
      this.page = 1;
      this.limit = 10;
      this.setState({
        listOfFlights: [],
        comboFlightList: [],
        hasMore: true,
        isCombo: false
      })
    }
    var req = {
      ...this.req,
      page: this.page,
      type: "depart",
      limit: this.limit,
      preferred_airline: this.preferredAirline,
      refundable: this.refundable,
      layover: (this.layoverTime === 0) ? "0" : (this.layoverTime === '') ? "" : JSON.stringify(this.layoverTime),
      preferred_airport: this.preferredAirport,
      fromTime: this.state.fromTime,
      currency: this.state.currency,
      toTime: this.state.toTime,
      stop: (this.stop === 0) ? "0" : (this.stop === '') ? "" : JSON.stringify(this.stop),
      sort: {
        stop: this.state.sortStop,
        date: this.state.sortDate,
        price: this.state.sortPrice,
        flight: this.state.sortFlight
      }
    };
    var resp = await bookingServices.getFlightsList(req);
    if (resp) {
      if (resp.result.flight_searchType && resp.result.flight_searchType.isIntl) {
        this.setState({
          listOfFlights: [],
          isIntl: true
        });
      } else {
        this.setState({ comboFlightList: [], isIntl: false })
      }
      this.setState({ loading: false })
      this.count = (resp.count) ? resp.count : 0;
      this.airportList = resp.result.flight_airport;
      this.airLineList = resp.result.flight_airline_names;
      this.maxPage = Math.floor((this.count + this.limit - 1) / this.limit);
      this.allItem = resp.result;
      if (resp.result.journyFirst) {
        this.setState({ isCombo: false });
        resp.result.journyFirst.forEach((list, index) => {
          list.priceList = []
          if (list.fare) {
            let result = this.customizeFlightData('fare', list, "Retail Fare")
            list.priceList.push(result)
          }
          if (list.CORP) {
            let result = this.customizeFlightData('CORP', list, "Corporate Fare")
            list.priceList.push(result)
          }
          list.choosed_flight_fare_id = list.priceList && list.priceList[0]
        })
        if (this.listOfFlights.length > 0) {
          this.listOfFlights = this.listOfFlights.concat(resp.result.journyFirst);
          this.setState({
            listOfFlights: this.listOfFlights
          })
        } else {
          this.listOfFlights = resp.result.journyFirst;
          this.setState({
            listOfFlights: this.listOfFlights,
            airportListFilter: this.airportList,
            airLineListFilter: this.airLineList,
          })
        }
        if (this.listOfFlights.length !== 0) {
          if (this.state.displayType === 0 && type !== 1) {
            //this.getFlightsReturn(type);
          } else {
            this.setState({ loading: false })
          }
        } else {
          this.setState({ loading: false })
        }
      } else {
        if (resp.result.comboFlight) {
          this.setState({ isCombo: true });
          resp.result.comboFlight.forEach((item, index) => {
            item.data && item.data.forEach(list => {
              list.priceList = [];
              if (list.fare) {
                let result = this.customizeFlightData('fare', list, "Retail Fare")
                list.priceList.push(result)
              }
              if (list.CORP) {
                let result = this.customizeFlightData('CORP', list, "Corporate Fare")
                list.priceList.push(result)
              }
              list.choosed_flight_fare_id = list.priceList && list.priceList[0]
            })
          })
        }
        if (this.comboFlightList.length > 0) {
          this.comboFlightList = this.comboFlightList.concat(resp.result.comboFlight);
          this.setState({
            comboFlightList: this.comboFlightList
          })
        } else {
          this.comboFlightList = resp.result.comboFlight;
          this.setState({
            comboFlightList: this.comboFlightList,
            airportListFilter: this.airportList,
            airLineListFilter: this.airLineList,
            isCombo: true
          })
        }
        this.setState({ loading: false })
      }
      this.forceUpdate();
    } else {
      this.setState({ loading: false })
    }
  };
  fetchMoreData = () => {
    if (
      this.listOfFlights.length >= this.count ||
      this.comboFlightList.length >= this.count
    ) {
      this.setState({ hasMore: false })
      return;
    }
    this.page = this.page + 1;
    this.getFlightsDepart(2);
  };
  sortItem = (categoryName) => {
    if (this.state[categoryName] === "") {
      this.state[categoryName] = "asc";
    } else {
      (this.state[categoryName] === "asc") ?
        this.state[categoryName] = "desc" : this.state[categoryName] = "asc";
    }
    this.sortList.forEach(list => {
      if (list.type !== categoryName) {
        this.state[list.type] = "";
      }
    })
    this.setState({ ...this.state })
    if (this.state.displayType === 0) { this.getFlightsDepart(1) } else { this.getFlightsReturn(1) }
  };
  changeWay = (type, detail, arrow) => {
    this.setState({ typeOfView: type })
  }
  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " m " : " m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  detailId = "";
  arrowId = "";
  viewDetail = async (id, arrow, e) => {
    this.setState({ baggageInfo: [] })
    this.detailId === arrow ? (this.detailId = "") : (this.detailId = arrow);
    this.arrowId === arrow ? (this.arrowId = "") : (this.arrowId = arrow);
    var tripType = 'depart';
    (this.comboFlightList.length !== 0) ? tripType = "combo" : (this.state.displayType === 0) ? tripType = "depart" : tripType = "return";
    if (tripType === 'combo') {
      var req = {
        type: "flight",
        tripType: "round",
        userId: this.userId.id,
        TRIP_ID: sessionStorage.getItem('TRIPID'),
        classType:localStorage.getItem("classType"),
        data: {
          tripFlight: tripType,
          flight_fare_id: e.flight_fare_id,
          adults: this.req.adults,
          departureDate: this.req.departureDate,
          returnDate: this.req.returnDate,
          from: this.req.from,
          to: this.req.to
        }
      };
    } else {
      var req = {
        type: "flight",
        userId: this.userId.id,
        tripType: "round",
        TRIP_ID: sessionStorage.getItem('TRIPID'),
        classType:localStorage.getItem("classType"),
        data: {
          tripFlight: tripType,
          flight_fare_id: e.flight_fare_id,
          adults: this.req.adults,
          departureDate: this.req.departureDate,
          returnDate: this.req.returnDate,
          from: this.req.from,
          to: this.req.to
        }
      };
    }
    var resp = await bookingServices.getBaggage(req);
    if (resp) {
      this.setState({ baggageInfo: resp })
    }
  };
  getCurrencyCode = async () => {
    var companyId = localStorage["440a28"];
    var resp = await masterService.getGroups(companyId, "CUR001");
    if (resp) {
      this.setState({
        listOfCurrency: resp.result
      })
    }
  };
  changeCurrency = (e) => {
    this.setState({
      currency: e.value
    }, () => {
      (this.state.displayType === 0) ? this.getFlightsDepart(1) : this.getFlightsReturn(1)
    })
  };
  changeMode = (key, item) => {
    if (this.state.displayType === 1) {
      this.listOfFlightsReturn[key].choosed_flight_fare_id = item;
    } else {
      this.listOfFlights[key].choosed_flight_fare_id = item;
    }
    this.setState({
      listOfFlightsReturn: this.listOfFlightsReturn,
      listOfFlights: this.listOfFlights
    })
  }
  // return code starts here
  pageReturn = 1;
  limitReturn = 10;
  allItemReturn = [];
  listOfFlightsReturn = [];
  countReturn = 0;
  airportListReturn = [];
  airLineListReturn = [];
  maxPageReturn = 1;
  getFlightsReturn = async type => {
    if (type === 1) {
      this.listOfFlightsReturn = [];
      this.countReturn = 0;
      this.pageReturn = 1;
      this.limitReturn = 10;
      this.setState({
        listOfFlightsReturn: [],
        hasMoreReturn: true,
        loading: true,
      })
    }

    var req = {
      ...this.req,
      page: this.pageReturn,
      type: "return",
      limit: this.limitReturn,
      preferred_airline: this.preferredAirline,
      refundable: this.refundable,
      layover: (this.layoverTime === 0) ? "0" : (this.layoverTime === '') ? "" : JSON.stringify(this.layoverTime),
      preferred_airport: this.preferredAirport,
      fromTime: this.state.fromTime,
      currency: this.state.currency,
      toTime: this.state.toTime,
      stop: (this.stop === 0) ? "0" : (this.stop === '') ? "" : JSON.stringify(this.stop),
      sort: {
        stop: this.state.sortStop,
        date: this.state.sortDate,
        price: this.state.sortPrice,
        flight: this.state.sortFlight
      }
    };
    var resp = await bookingServices.getFlightsList(req);
    if (resp) {
      this.countReturn = (resp.count) ? resp.count : 0;
      this.airportListReturn = resp.result.flight_airport;
      this.airLineListReturn = resp.result.flight_airline_names;
      this.maxPageReturn = Math.floor(
        (this.countReturn + this.limitReturn - 1) / this.limitReturn
      );
      this.allItemReturn = resp.result;

      if (resp.result.journyReturn) {
        resp.result.journyReturn.forEach((list, index) => {
          list.priceList = []
          if (list.fare) {
            let result = this.customizeFlightData('fare', list, "Retail Fare")
            list.priceList.push(result)
          }
          if (list.CORP) {
            let result = this.customizeFlightData('CORP', list, "Corporate Fare")
            list.priceList.push(result)
          }
          list.choosed_flight_fare_id = list.priceList && list.priceList[0]
        })
        if (this.listOfFlightsReturn.length > 0) {
          this.listOfFlightsReturn = this.listOfFlightsReturn.concat(
            resp.result.journyReturn
          );
          this.setState({
            listOfFlightsReturn: this.listOfFlightsReturn
          })
        } else {
          this.listOfFlightsReturn = resp.result.journyReturn;
          this.setState({
            listOfFlightsReturn: this.listOfFlightsReturn
          })
        }
      }
      this.setState({ loading: false })
    } else {
      this.listOfFlightsReturn = [];
      this.setState({
        listOfFlightsReturn: []
      })
      this.setState({ loading: false })
    }
  };
  comboFlightList = [];
  storeValue = e => {
    this.filterSet = true;
    this.setState({
      value: e
    });
  };
  completeRange = e => {
    this.setState({ fromTime: this.state.value.start, toTime: this.state.value.end })
    if (this.state.value.start == "0:00") {
      this.setState({ fromTime: "0:00" })
    } else if (this.state.value.end == "23:00") {
      this.setState({ toTime: "23:00" })
    }
    if (this.state.value.start) {
      let split = this.state.value.start.split(":")
      if (split[0] <= 9) {
        var time = ('0' + split[0]).slice(-2) + ':' + ('0' + split[1]).slice(-2);
        this.setState({ fromTime: time })
      }
    }
    if (this.state.value.end) {
      let split = this.state.value.end.split(":")
      if (split[0] <= 9) {
        var time = ('0' + split[0]).slice(-2) + ':' + ('0' + split[1]).slice(-2);
        this.setState({ toTime: time })
      }
    }
    this.setState({ ...this.state }, () => {
      (this.state.displayType === 0) ? this.getFlightsDepart(1) : this.getFlightsReturn(1)
    })
  };
  fetchMoreDataReturn = () => {
    if (this.listOfFlightsReturn.length >= this.countReturn) {
      this.setState({ hasMoreReturn: false })
      return;
    }
    this.pageReturn = this.pageReturn + 1;
    this.getFlightsReturn(2);
  };
  enableConnect = () => {
    this.setState({ selectedOption: !this.state.selectedOption }, () => {
      if (this.state.selectedOption === false) {
        this.preferredAirport = [];
        (this.state.displayType === 0) ? this.getFlightsDepart(1) : this.getFlightsReturn(1)
      }
    })
  };
  selectedOptionRefund = false;
  refundable = ''
  onlyRefund = () => {
    this.filterSet = true;
    this.setState({ loading: true })
    this.selectedOptionRefund = !this.selectedOptionRefund;
    this.refundable = this.selectedOptionRefund;
    (this.state.displayType === 0) ? this.getFlightsDepart(1) : this.getFlightsReturn(1)
  };
  customizeFlightData = (type, list, typeName) => {
    var price = []
    price = {
      flight_fare_id: list[type].flight_fare_id,
      flight_total_price: list[type].flight_total_price,
      flight_layoverTime: list[type].flight_layoverTime,
      arriveTime: list[type].arrivin_day,
      airLine: list[type].flight_airline,
      journeyTime: list[type].flight_onward_total_duration,
      type: typeName,
      violations: list.violations,
      out_of_policy: list.out_of_policy,
      fareInfo: {
        totalOverAllPrice: list[type].flight_total_price,
        flightAdultFare: list[type].flight_base_price,
        serviceFee: list[type].flight_service_fee ? Number(list[type].flight_service_fee) : 0,
        gst: list[type].flight_total_tax,
        taxBreakUp: [{
          flight_agency_transaction_fee: list[type].flight_agency_transaction_fee,
          flight_convinience_fee: list[type].flight_convinience_fee,
          flight_agency_commission: list[type].flight_agency_commission,
          flight_cute_fee: list[type].flight_cute_fee,
          flight_service_fee: list[type].flight_service_fee ? Number(list[type].flight_service_fee) : 0,
          flight_gst: list[type].flight_gst ? Number(list[type].flight_gst) : 0,
          flight_other_charges: list[type].flight_other_charges,
          // flight_total_tax: list[type].flight_total_tax ? Number(list[type].flight_total_tax) : 0,
          flight_congestion_fee: list[type].flight_congestion_fee,
          flight_fuel_surcharge: list[type].flight_fuel_surcharge,
          flight_agency_service_fee: list[type].flight_agency_service_fee,
          flight_air_dev_fee: list[type].flight_air_dev_fee,
          flight_airport_development_fee: list[type].flight_airport_development_fee
        }]
      }
    }
    return price;
  }
  render() {
      var dataId =(localStorage.getItem("440a28"));
    const { loading, displayType, listOfFlightsReturn, listOfFlights, isCombo, comboFlightList, selectedOption } = this.state;
    return (
      <div>
        {this.count !== 0 && (
          <button
            data-target="#addServiceModal"
            data-toggle="modal"
            className="add-button text-white hover-v position-fixed"
          >
            +
            <span className="hover-name bottom fs-12">Add Service</span>
          </button>
        )}
        <FullDetailsPage
          from={this.req.from}
          to={this.req.to}
          returnDate={this.req.returnDate}
          cardList={this.state.cardList}
          depart={this.req.departureDate}
          type="round"
          cartCount={this.state.cartCount}
          enable={this.enableCheckout}
          currency={this.state.currency}
          typeOfService={"flight"}
        />
        <div className="flight-margin">
          <div className="p-padding">
            {!loading && (listOfFlights.length !== 0 || listOfFlightsReturn.length !== 0 || comboFlightList.length !== 0) && (
              <div className="">
                {!this.state.isIntl ? (
                  <div>
                    <div
                      onClick={e => this.changeDisplay(0)}
                      className={
                        displayType === 0 ? "summary active" : "summary"
                      }
                    >
                      <div className="d-inline-block text-center">
                        <p>
                          <i className="material-icons">
                            flight
                      </i>
                        </p>
                      </div>
                      <div className="d-inline-block">
                        <p className="title">
                          {this.req.fromCity} ({this.req.from}) to {this.req.toCity} ({this.req.to})
                        </p>
                        <p className="label"> {getFormattedDateFromIsoString(this.req.departureDate)}{" "}|{" "}{this.count && this.count} flights found</p>
                      </div>
                    </div>
                    <div
                      onClick={e => this.changeDisplay(1)}
                      className={
                        displayType === 1 ? "summary active" : "summary"
                      }
                    >
                      <div className="d-inline-block text-center">
                        <p>
                          <i className="material-icons rotate180">
                            flight
                      </i>
                        </p>
                        {/*<p>Return</p>*/}
                      </div>
                      <div className="d-inline-block">
                        <p className="title">
                          {this.req.toCity} ({this.req.to}) to  {this.req.fromCity}  ({this.req.from})
                        </p>
                        <p className="label">
                          {getFormattedDateFromIsoString(this.req.returnDate)}{" "}|{" "}{this.countReturn} flights found
                  </p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                ) : (
                    <div>
                      <div className="summary active">
                        <div className="d-inline-block text-center">
                          <p>
                            <i className="material-icons">
                              flight
                    </i>
                          </p>
                          {/*<p>Depart & Return</p>*/}
                        </div>
                        <div className="d-inline-block">
                          <p className="title">
                            {this.req.fromCity} ({this.req.from}) to {this.req.toCity} ({this.req.to})
                          </p>
                          <p className="label">
                            {getFormattedDateFromIsoString(this.req.departureDate)}
                            {" "}-{" "}
                            {getFormattedDateFromIsoString(this.req.returnDate)}
                            |{" "}{this.count} flights found</p>
                        </div>
                      </div>
                      <div className="clearfix" style={{ clear: 'both' }}></div>
                    </div>
                  )}
              </div>
            )}
            <div className="clearfix" style={{"clear":"both"}}></div>
            <div className="row">
              <div className={(loading && !this.filterSet) ? "col-lg-12" : (listOfFlights.length === 0 && listOfFlightsReturn.length === 0 && comboFlightList.length === 0 && !this.filterSet) ? "col-lg-12" : "col-xl-10 col-lg-9"}>
                {(loading && (listOfFlights.length === 0 || listOfFlightsReturn.length === 0 || this.countReturn === 0 || comboFlightList.length === 0)) && (
                  <FlightLoader />
                )}
                {!loading && this.count !== 0 && (this.countReturn !== 0 || comboFlightList.length !== 0) && (
                  <div>
                    <div className="clearfix" />
                    <div className="devision-container heading">
                      <div className="devision">
                        <div className="new-devision-container">
                          <SortHeadFlight
                            sortItem={this.sortItem}
                            feildName={"sortFlight"}
                            name="Airline"
                            sortType={this.state.sortFlight}
                            className="details airline"
                          />
                          <div className="details departure">
                            Departure <span>
                              <i className="material-icons sort">
                                arrow_drop_down
                            </i>
                              <i className="material-icons sort">
                                arrow_drop_up
                            </i>
                            </span>
                          </div>
                          <div className="details arrival">
                            Arrival <span>
                              <i className="material-icons sort">
                                arrow_drop_down
                            </i>
                              <i className="material-icons sort">
                                arrow_drop_up
                            </i>
                            </span>
                          </div>
                          <SortHeadFlight
                            sortItem={this.sortItem}
                            feildName={"sortDate"}
                            name="Duration"
                            sortType={this.state.sortDate}
                            className="details duration"
                          />
                          <SortHeadFlight
                            sortItem={this.sortItem}
                            feildName={"sortStop"}
                            name="Stops"
                            sortType={this.state.sortStop}
                            className="details stops"
                          />
                          <SortHeadFlight
                            sortItem={this.sortItem}
                            feildName={"sortPrice"}
                            name="Price"
                            sortType={this.state.sortPrice}
                            className="details price text-right"
                          />
                          <div className="clearfix" />
                        </div>
                      </div>
                    </div>
                    <div className={dataId === "5dc5197e1382a2538aa3a8ca" ? "d-block mb-2": "d-none"} style={{background:"#FFF4CB"}}>
                      <div style={{ padding: "6px 10px" }}>
                        <p className="float-left mb-0">
                          <i className="material-icons fs-14" style={{color:"#AB810A"}}>
                            info
                          </i>
                        </p>
                        <p className="float-left mb-0" style={{paddingTop:"1px",marginLeft:"10px",color:"#AB810A",fontWeight:"600"}} >Price may differ at the time of booking</p>
                        <p className="clearfix mb-0"></p>
                      </div>
                    </div>
                    {/* depart flight code starts */}
                    {(displayType === 0 && listOfFlights.length !== 0) && (
                      <InfiniteScroll
                        dataLength={listOfFlights.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        loader={
                          <p style={{ textAlign: "center" }}>
                            Loading...
                            </p>
                        }
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>No More Search Results Available</b>
                          </p>
                        }
                      >

                        {this.state.listOfFlights && this.state.listOfFlights.length && this.state.listOfFlights.map((i, index) => (
                          <FlightList
                            key={index}
                            keys={index}
                            state={this.state}
                            items={i}
                            cancelPolicy={this.cancelPolicy}
                            changeMode={this.changeMode}
                            baggageInfo={this.state.baggageInfo}
                            airport={this.airportList}
                            detailId={this.detailId}
                            viewDetail={this.viewDetail}
                            changeWay={this.changeWay}
                            addToCart={this.addToCart}
                            selectedItem={this.selectedItem}
                            uniqueId={this.uniqueId}
                            type={this.state.typeOfView}
                            arrowId={this.arrowId}
                            getTimes={this.secondsToHms}
                            from={this.req.from}
                            currency={this.state.currency}
                            handleGetReason={this.handleGetReason}
                            handleChangeReasonId={this.handleChangeReasonId}
                            to={this.req.to}
                            classType={this.req.classType}
                            tripType="depart"
                          />
                        ))}
                      </InfiniteScroll>
                    )}
                    {/* depart flight code ends */}
                    {/* return flight code starts */}
                    {(displayType === 1 &&
                      listOfFlightsReturn.length !== 0) && (
                        <InfiniteScroll
                          dataLength={listOfFlightsReturn.length}
                          next={this.fetchMoreDataReturn}
                          hasMore={this.state.hasMoreReturn}
                          loader={
                            <p style={{ textAlign: "center" }}>
                              Loading...
                              </p>
                          }
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>No More Search Results Available</b>
                            </p>
                          }
                        >
                          {listOfFlightsReturn && listOfFlightsReturn.length && listOfFlightsReturn.map((i, index) => (
                            <FlightList
                              key={index}
                              state={this.state}
                              currency={this.state.currency}
                              keys={index}
                              cancelPolicy={this.cancelPolicy}
                              items={i}
                              airport={this.airportList}
                              detailId={this.detailId}
                              viewDetail={this.viewDetail}
                              changeWay={this.changeWay}
                              type={this.state.typeOfView}
                              arrowId={this.arrowId}
                              changeMode={this.changeMode}
                              getTimes={this.secondsToHms}
                              handleGetReason={this.handleGetReason}
                              handleChangeReasonId={this.handleChangeReasonId}
                              uniqueId={this.uniqueId}
                              addToCart={this.addToCart}
                              selectedItem={this.selectedItem}
                              from={this.req.from}
                              to={this.req.to}
                              classType={this.req.classType}
                              baggageInfo={this.state.baggageInfo}
                              tripType="return"
                            />
                          ))}
                        </InfiniteScroll>
                      )}
                    {/* return flight code ends */}
                    {/* combo flight code starts */}
                    {(comboFlightList && comboFlightList.length !== 0) && (
                      <InfiniteScroll
                        dataLength={comboFlightList.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        loader={
                          <p style={{ textAlign: "center" }}>Loading...</p>
                        }
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>No More Search Results Available</b>
                          </p>
                        }
                      >
                        <div id="accordion">
                          {comboFlightList && comboFlightList.length && comboFlightList.map((i, index) => (
                            <ComboFlightList
                              key={index}
                              keys={index}
                              items={i}
                              currency={this.state.currency}
                              state={this.state}
                              uniqueId={this.uniqueId}
                              airport={this.airportList}
                              detailId={this.detailId}
                              viewDetail={this.viewDetail}
                              changeWay={this.changeWay}
                              type={this.state.typeOfView}
                              arrowId={this.arrowId}
                              getTimes={this.secondsToHms}
                              addToCart={this.addToCart}
                              cancelPolicy={this.cancelPolicy}
                              handleGetReason={this.handleGetReason}
                              handleChangeReasonId={this.handleChangeReasonId}
                              selectedItem={this.selectedItem}
                              from={this.req.from}
                              to={this.req.to}
                              baggageInfo={this.state.baggageInfo}
                              tripType="combo"
                            />
                          ))}
                        </div>
                      </InfiniteScroll>
                    )}
                    {/* combo flight code ends */}
                  </div>
                )}
                {/* show empty for depart message */}
                {!loading && this.state.displayType === 0 &&
                  listOfFlights.length === 0 && !isCombo && (
                    <FlightNoResults
                      msg={(!loading && this.count === 0 && this.filterSet) ? "No flight found that matches your filter criteria" : "There are no flights found in this route for the selected date of journey. Please try a different route or date"} chect
                    />
                  )}
                {/* show empty for return message */}
                {!loading && this.state.displayType === 1 &&
                  listOfFlightsReturn.length === 0 && !isCombo && (
                    <FlightNoResults
                      msg={(!loading && this.countReturn === 0 && this.filterSet) ? "No flight found that matches your filter criteria" : "There are no flights found in this route for the selected date of journey. Please try a different route or date"}
                    />
                  )}
                {/* combo flight show empty */}
                {!loading && comboFlightList.length === 0 && isCombo &&
                  <FlightNoResults
                    msg={(!loading && this.count === 0 && this.filterSet) ? "No flight found that matches your filter criteria" : "There are no flights found in this route for the selected date of journey. Please try a different route or date"} test
                  />
                }

              </div>
              {((!loading && this.countReturn !== 0 && this.count !== 0) || (!loading && this.count !== 0) ||
                this.filterSet) && (
                  <div className="col-xl-2 col-lg-3">
                    <FilterFlight
                      closeFilter={this.closeFilter}
                      stop={this.stop}
                      stopFilter={this.stopFilter}
                      layoverTime={this.layoverTime}
                      layoverTimeFilter={this.layoverTimeFilter}
                      onlyRefund={this.onlyRefund}
                      selectedOptionRefund={this.selectedOptionRefund}
                      preferredAirline={this.preferredAirline}
                      airLineList={this.state.airLineListFilter}
                      airportList={this.state.airportListFilter}
                      value={this.state.value}
                      getAirline={this.getAirline}
                      enableConnect={this.enableConnect}
                      handelAirport={this.getAirPort}
                      preferredAirport={this.preferredAirport}
                      selectedAirLine={this.selectedAirLine}
                      selectedOption={this.state.selectedOption}
                      from={this.state.landFrom}
                      storeValue={this.storeValue}
                      completeRange={this.completeRange}
                      listOfCurrency={this.state.listOfCurrency}
                      currency={this.state.currency}
                      changeCurrency={this.changeCurrency}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FlightRoundPage;
