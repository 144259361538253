import React from "react";
import { NavLink } from "react-router-dom";
const ConfigurationStep1Page = props => {
  let data = props;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">C</div>
            <div className="right">
              <h6 className="heading">Configuration</h6>
              <p className="text">Lorem Ipsum is simply dummy text.</p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <div className="row">
              <div className="col-md-5">
                <div className="form-group custom labelup">
                  <label>Configuration Name</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Text Box
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Configuration Code</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Text Box
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Calculation Method Code</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Text Box
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Rate Feed Code</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Text Box
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Rate Type</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      &nbsp;
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>External URL</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Text Box
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>External URL's Text</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Text Box
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Meals Type</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      &nbsp;
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Lodging Type</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      &nbsp;
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Display Wizard</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      &nbsp;
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Wizard Prompt Text</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Text Box
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Same Day</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      &nbsp;
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Exchange rate Day</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      &nbsp;
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom labelup">
                  <label>Over Limit Distribution Method</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      &nbsp;
                    </button>
                    <div className="dropdown-menu">
                      <button type="button" className="dropdown-item">
                        Save
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                      <button type="button" className="dropdown-item">
                        Lorem Ipsum
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round" />
                  </label>
                  <span>Show Room Itinerary check</span>
                </div>
                <div className="form-group">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round" />
                  </label>
                  <span>Use Overnight</span>
                </div>
                <div className="form-group">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round" />
                  </label>
                  <span>Use system Exception</span>
                </div>
                <div className="form-group">
                  <label className="switch">
                    <input type="checkbox" checked />
                    <span className="slider round" />
                  </label>
                  <span>Use Entry of extended trip</span>
                </div>
                <div className="form-group">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round" />
                  </label>
                  <span>Display a Quick Itinerary Wizard</span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="profile-card bg-light px-3 pt-4">
                  <h6 className="sub-heading font-weight-bold">
                    Optional Information
                  </h6>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>Combine Meals and Lodging Rates</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" checked />
                      <span className="slider round" />
                    </label>
                    <span>Display Base Meals Rate</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>Display Base Meals rate Currency</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>Display Company and Government Limit Comparison</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>User Entry of Rate Location</span>
                  </div>
                </div>
                <div className="profile-card bg-light px-3 pt-4 mt-3">
                  <h6 className="sub-heading font-weight-bold">
                    Meals Options
                  </h6>
                  <div className="row">
                    <div className="col-md-7">
                      <div className="form-group custom labelup">
                        <label>Meals Deduction List</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            Text Box
                          </button>
                          <div className="dropdown-menu">
                            <button type="button" className="dropdown-item">
                              Save
                            </button>
                            <button type="button" className="dropdown-item">
                              Lorem Ipsum
                            </button>
                            <button type="button" className="dropdown-item">
                              Lorem Ipsum
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>Deduct for Breakfast</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" checked />
                      <span className="slider round" />
                    </label>
                    <span>Deduct for Lunch</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>Deduct for Dinner</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>Default Breakfast to Provided</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" checked />
                      <span className="slider round" />
                    </label>
                    <span>Default Lunch to Provided</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>Default Dinner to Provided</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" checked />
                      <span className="slider round" />
                    </label>
                    <span>Itemize fixed Meals</span>
                  </div>
                </div>
                <div className="profile-card bg-light px-3 pt-4 mt-3">
                  <h6 className="sub-heading font-weight-bold">
                    Country Specific Options
                  </h6>
                  <div className="form-group ">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>User Boded Cross Time</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>User Lodging Type</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>User Percent Rule</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>User Short Distance</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>User Trip Length List</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>User Entry of Breakfast Amount</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                    <span>User Entry of Meals Amount</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" checked />
                      <span className="slider round" />
                    </label>
                    <span>User Entry of Muncipality</span>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input type="checkbox" checked />
                      <span className="slider round" />
                    </label>
                    <span>Create Itinerary Rows Based on Air </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-group-margin">
              <button className="btn btn-border-basic lg-padding">Back</button>
              <button className="btn btn-border-success lg-padding">
                Next
              </button>
              <button className="btn btn-border-basic lg-padding">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfigurationStep1Page;
