import React from 'react';
import { NavLink } from 'react-router-dom';
import { Input } from '../common/_forms';
import { GoogleLogin } from 'react-google-login';
const LoginExternalPage = (props) => {
    const { state, handleChange, error, handleSubmit, loading } = props;
    const onClick = () =>{
      window.location.href = localStorage.getItem('sso')
    }
    return (
        <div className="login-ext">
            <div className="logo">
                {/* <img src="/assets/images/left-menu-logo1.png" alt="Logo" />*/}
                <img src="/assets/images/left-menu-logo1.png" alt="Logo" />

            </div>
            <div className="d-table d-table__middle">
                <div>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="center-content">
                            <h2 className="mb-5">Login to your Curio Account</h2>
                            {/* <p><b>Login Details</b></p> */}
                            <div className="form-group custom">
                                <Input
                                    label="Email"
                                    type="email"
                                    name="email"
                                    value={state.email}
                                    error={error.message('email', state.email, 'required|email')}
                                    controlFunc={e => handleChange(e)}
                                />
                            </div>
                            <div className="form-group custom">
                                <Input
                                    label="Password"
                                    type="password"
                                    name="password"
                                    value={state.password}
                                    error={error.message('password', state.password, 'required')}
                                    controlFunc={e => handleChange(e)}
                                />
                            </div>
                            {loading ?
                                <button type="button" className="btn btn-block mb-0">Please wait</button> :
                                <button type="submit" className="btn btn-primary btn-block mb-0">Login</button>}
                            {/* <NavLink to="/sso-login" className="btn btn-block mb-4 ">Login with SSO</NavLink> */}
                            {/* <p className="link"><NavLink to="/forgot-password">Forgot Password?</NavLink></p> */}
                            <p className="link-new float-left"><NavLink to="/forgot-password">Forgot Password?</NavLink></p>



                            {localStorage.getItem('sso') && localStorage.getItem('sso') !== "null" ?
                            <p className="link-new float-right" onClick={onClick} style={{cursor:"pointer",color:"#377DFF"}}>SSO Login</p>
                             :
                            <p className="link-new float-right"><NavLink to="/sso-login">SSO Login</NavLink></p>
                             }



                            <p className="clearfix"></p>
                            {/* <GoogleLogin
                                clientId="8846958030-b579pqoo0cbn4kvoaguelbf32uv8bl9n.apps.googleusercontent.com"
                                buttonText="Login with Google"
                                onSuccess={props.responseGoogle}
                                onFailure={props.responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            /> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default LoginExternalPage;
