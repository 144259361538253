import {http} from '../_helpers';
import { config } from '../env';


const searchStation  = (searchTerm) => {
  let url = `${config.apiUrlTravel}train/stations/search?$query=${searchTerm}`;
  return http.get(url).then(res => {
    return res ? res : ""
  })
}

const searchTrains = (fromStation, toStation, departureDate) => {
  let url = `${config.apiUrlTravel}train/search?from_station=${fromStation}&to_station=${toStation}&dep_date_from=${departureDate}`;
  return http.get(url).then(res => {
    return res ? res : ""
  })
}

const searchTrainsRList = (request) => {
  let url = `${config.apiUrlTravel}train/search`;
  return http.post(url,request).then(res => {
    return res ? res : ""
  })
}
const  getEncodedUrl = (params) => {
        let copiedParams = {...params};
        for(let key in copiedParams){
            if(copiedParams.hasOwnProperty(key)){
                copiedParams[key] = encodeURIComponent(copiedParams[key]);
            }
        }
        return copiedParams;

    }

const getTrainDetails = async (data) => {
  let payload = getEncodedUrl(data);

  let url = `${config.apiUrlTravel}train/seat/availability?trainNo=${payload.trainNo}&fromStation=${payload.fromStation}&toStation=${payload.toStation}&dateOfJourney=${payload.dateOfJourney}&quota=${payload.quota}&seatClass=${payload.seatClass}`;
//   let temp = {
//     "message": "Data Found",
//     "result": [
//         {
//             "date": "Wed Sep 11 2019 00:00:00 GMT+0530",
//             "seat_class": "SL",
//             "availability": {
//                 "AVBL": 168
//             },
//             "total_fare": 210,
//             "ticket_link": "https://agents-stage.railyatri.in/tbs-booking?boarding_from=KMT&boarding_to=SC&train_no=12727&class=SL&quota=GN&boarding_date=2019-09-11&avail=AVBL-0168&is_cached=false&status=AVBL&seat_number=0168",
//             "search_result_id": 122744
//         },
//         {
//             "date": "Thu Sep 12 2019 00:00:00 GMT+0530",
//             "seat_class": "SL",
//             "availability": {
//                 "AVBL": 212
//             },
//             "total_fare": 210,
//             "ticket_link": "https://agents-stage.railyatri.in/tbs-booking?boarding_from=KMT&boarding_to=SC&train_no=12727&class=SL&quota=GN&boarding_date=2019-09-12&avail=AVBL-0212&is_cached=false&status=AVBL&seat_number=0212",
//             "search_result_id": 122745
//         },
//         {
//             "date": "Fri Sep 13 2019 00:00:00 GMT+0530",
//             "seat_class": "SL",
//             "availability": {
//                 "AVBL": 284
//             },
//             "total_fare": 210,
//             "ticket_link": "https://agents-stage.railyatri.in/tbs-booking?boarding_from=KMT&boarding_to=SC&train_no=12727&class=SL&quota=GN&boarding_date=2019-09-13&avail=AVBL-0284&is_cached=false&status=AVBL&seat_number=0284",
//             "search_result_id": 122746
//         },
//         {
//             "date": "Sat Sep 14 2019 00:00:00 GMT+0530",
//             "seat_class": "SL",
//             "availability": {
//                 "AVBL": 205
//             },
//             "total_fare": 210,
//             "ticket_link": "https://agents-stage.railyatri.in/tbs-booking?boarding_from=KMT&boarding_to=SC&train_no=12727&class=SL&quota=GN&boarding_date=2019-09-14&avail=AVBL-0205&is_cached=false&status=AVBL&seat_number=0205",
//             "search_result_id": 122747
//         },
//         {
//             "date": "Sun Sep 15 2019 00:00:00 GMT+0530",
//             "seat_class": "SL",
//             "availability": {
//                 "AVBL": 206
//             },
//             "total_fare": 210,
//             "ticket_link": "https://agents-stage.railyatri.in/tbs-booking?boarding_from=KMT&boarding_to=SC&train_no=12727&class=SL&quota=GN&boarding_date=2019-09-15&avail=AVBL-0206&is_cached=false&status=AVBL&seat_number=0206",
//             "search_result_id": 122748
//         },
//         {
//             "date": "Mon Sep 16 2019 00:00:00 GMT+0530",
//             "seat_class": "SL",
//             "availability": {
//                 "AVBL": 5
//             },
//             "total_fare": 210,
//             "ticket_link": "https://agents-stage.railyatri.in/tbs-booking?boarding_from=KMT&boarding_to=SC&train_no=12727&class=SL&quota=GN&boarding_date=2019-09-16&avail=AVBL-0005&is_cached=false&status=AVBL&seat_number=0005",
//             "search_result_id": 122749
//         }
//     ]
// }
//   await new Promise(resolve => setTimeout(resolve, 2000));
//    return temp;
  return http.get(url).then(res => {
    return res ? res : ""
  })
}

export const trainService = {
  searchStation,
  searchTrains,
  getTrainDetails,
  searchTrainsRList
}