import { http } from "../_helpers";
import { config } from "../env";
import cryptr from "../_helpers/crypty"; 
// import { func } from "@interactjs/utils/is";

export const masterService = {
  getMenu,
  getCountries,
  getState,
  getCity,
  getDateFormet,
  getDecimalSep,
  getLanguage,
  getAddress,
  menuPermission,
  menuSubPermission,
  getServiceModuleListFl,
  getFieldList,
  getGroups,
  getFormPageType,
  getListManagement,
  getConnectedListData,
  getSearchResult,
  getHomeInfo
};

// menu creation
function getMenu(user,id) { 
  let superUser = localStorage['f1222a'];
  let delUser = localStorage['43k32'];
  let userDetails = JSON.parse(cryptr.decrypt(localStorage["440a24"]));
  var data = []
  if(superUser !== undefined && superUser){
    data= {
      companyId:id
    } 
  }else if(delUser  !== undefined && delUser){ 
    const indexUser  = userDetails.delegateUser && userDetails.delegateUser.accessType.indexOf(1)
    const indexApprove  = userDetails.delegateUser && userDetails.delegateUser.accessType.indexOf(2)   
    data = {
      companyId:id,
      delegateUser:true,
      userId:user,
      serviceModuleId:userDetails.delegateUser && userDetails.delegateUser.serviceModule,
      delegateApprover:(indexApprove === -1)?false:true,
      delegateUser:(indexUser === -1)?false:true,
    } 
  }else{
    data= {
      companyId:id, 
      userId:user
    }  
  } 
  return http.post(config.apiUrlCompany + "user/menus",data).then(data => {
    return data;
  });
}
function getCountries() {
  return http
    .get(config.apiUrlCompany + "list-management?itemCode=CTRY001")
    .then(data => {
      return data;
    });
}
function getState(id) {
  return http.get(config.apiUrlCompany + "state/" + id).then(data => {
    return data;
  });
}
function getCity(id) {
  return http.get(config.apiUrlCompany + "city/" + id).then(data => {
    return data;
  });
}
function getDateFormet() {
  return http.get(config.apiUrlSuperAdmin + "date-formats").then(data => {
    return data;
  });
}
function getDecimalSep() {
  return http.get(config.apiUrlSuperAdmin + "decimal-separators").then(data => {
    return data;
  });
}
function getLanguage() {
  return http.get(config.apiUrlSuperAdmin + "languages").then(data => {
    return data;
  });
}
function getAddress(lat, lng) {
  let url =
    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
    lat +
    "," +
    lng +
    "&key=AIzaSyBWyyK5gkujBpxYNim55MUoSfo6cDWJM6w";
  const requestOptions = {
    method: "GET"
  };
  return fetch(url, requestOptions).then(response => response.json());
}

function menuPermission(company,user,serviceModuleId,search) {
  return http.get(config.apiUrlCompany + `menus/function?userId=${user}&companyId=${company}&serviceModuleId=${serviceModuleId}&search=${search}`).then(data => {
    return data ? data.result : "";
  });
}
function menuSubPermission(id) {
  return http
    .get(config.apiUrlCompany + "menus/function?parentId=" + id)
    .then(data => {
      return data;
    });
}
function getServiceModuleListFl() {
  return http.get(config.apiUrlCompany + "service-module").then(data => {
    return data;
  });
}
function getFormPageType(serviceModuleId, headerId) {
  return http
    .get(
      config.apiUrlCompany +
        `form-page-type?serviceModuleId=${serviceModuleId}&formHeaderId=${headerId}`
    )
    .then(data => {
      return data;
    });
}
function getGroups(companyId, itemCode) {
  return http
    .get(
      config.apiUrlCompany +
        "list-management?companyId=" +
        companyId +
        "&itemCode=" +
        itemCode
    )
    .then(data => {
      return data;
    });
}

function getConnectedListData(companyId,parentId){
  return http
    .get(`${config.apiUrlCompany}list-management?companyId=${companyId}&parentId=${parentId}`)
    .then(data => {
      return data ? data.result : "";
    });
}

function getListManagement(companyId) {
  return http
    .get(config.apiUrlCompany + "list-management/child/count?companyId=" + companyId)
    .then(data => {
      return data;
    });
}

function getFieldList(data) {
  return http
    .post(config.apiUrlCompany + "company-fields/list-all", data)
    .then(data => {
      return data;
    });
}

function getSearchResult (e) {
  return http
    .get(config.apiUrlSuperAdmin + `cities?search=${e}&isDependecyRequired=false&page=1&limit=10`)
    .then(data => {
      return data;
    });
}

function getHomeInfo (e) { 
  return http
    .get(config.apiExpense + `home?companyId=${e}`)
    .then(data => {
      return data;
    });
}

