import React from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import { Select } from "src/_components/common/_forms";

const TravelAdminPage = props => {
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">L</div>
          <div className="right">
            <h6 className="heading">Travel Admin</h6>
            <p>Travel time setting</p>
          </div>
          <div className="clearfix" />
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="row">
            <div className="col-xl-5 col-md-6">
              <h6 className="sub-heading">Time Window</h6>
              <div className="form-group">
                <div className="switch-right">
                  <div className="right">
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="form-control"
                        checked={props.presetsActiveStatus}
                        value={props.presetsActiveStatus}
                        onClick={props.onSliderToggle}

                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  <p className="mr-5">Show Morning, Afternoon, Evening and anytime as flight time options.</p>
                </div>
              </div>
              <div className="form-group custom labelup">
                <label>Default Departure Hour</label>
                <Select
                  placeholder={props?.departureTime?.label || 'Select Time'}
                  options={props.timeOptions}
                  keyName="label"
                  controlFunc={e => props.OnTimeWindowClick(e, 'departureTime')}
                  error={props.validator.message('Departure Hour ', props.departureTime, 'required')}
                />
              </div>
              <div className="form-group custom labelup">
                <label>Default Return Hour</label>
                <Select
                  placeholder={props?.returnTime?.label || 'Select Time'}
                  options={props.timeOptions}
                  keyName="label"
                  controlFunc={e => props.OnTimeWindowClick(e, 'returnTime')}
                  error={props.validator.message('Return Hour', props.returnTime, 'required')}
                />

              </div>
              <h6 className="sub-heading">Air Search Options</h6>
              <div className="form-group custom labelup">
                <label>Default Search Window (Domestic)</label>
                <Select
                  placeholder={props?.domesticVariance?.label || 'Select Search Window'}
                  options={props.timePresets}
                  keyName="label"
                  controlFunc={e => props.OnTimeWindowClick(e, 'domesticVariance')}
                  error={props.validator.message('Default Search Window', props.domesticVariance, 'required')}


                />

              </div>
              <div className="form-group custom labelup">
                <label>Default Search Window (International)</label>
                <Select
                  placeholder={props?.internationalVariance?.label || 'Select Search Window'}
                  options={props.timePresets}
                  keyName="label"
                  controlFunc={e => props.OnTimeWindowClick(e, 'internationalVariance')}
                  error={props.validator.message('Default Search Window', props.internationalVariance, 'required')}
                />

              </div>
              <div className="form-group custom labelup">
                <label>Minimum Window</label>
                <Select
                  placeholder={props?.employeeMinVariance?.label || 'Select Search Window'}
                  options={props.timePresets}
                  keyName="label"
                  controlFunc={e => props.OnTimeWindowClick(e, 'employeeMinVariance')}
                  error={props.validator.message('Minimum Window', props.employeeMinVariance, 'required')}
                />
              </div>
              <div className="form-group custom labelup">
                <label>Maximum Window</label>
                <Select
                  placeholder={props?.employeeMaxVariance?.label || 'Select Search Window'}
                  options={props.timePresets}
                  keyName="label"
                  controlFunc={e => props.OnTimeWindowClick(e, 'employeeMaxVariance')}
                  error={props.validator.message('Minimum Window', props.employeeMaxVariance, 'required')}
                />

              </div>
            </div>
            <div className="col-12">
              <div className="btn-group-margin">
                {
                  props.isEdit ? (
                  <button
                    type="button"
                    className="btn"
                    onClick={props.onSubmit}
                >
                  Update
                </button>
                  ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={props.onSubmit}
                >
                  Save
                </button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelAdminPage;
