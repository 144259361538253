import React from "react";
import { NavLink } from "react-router-dom";

const TableRow = props => {
  const { name, sendTo, editableBy, activeStatus, id } = props.data;

  return (
    <tr
      key={id}
      onClick={e => props.onSelecthandler(id, props.data)}
      className={id === props.selectedTemplateByIndex ? "active-tr" : ""}
    >
      <td>{name}</td>
      {/* <td>
        {sendTo.map((data, index) => (
          <p key={index}>{data}</p>
        ))}
      </td> */}
      <td>{editableBy}</td>
      <td>{props.data.default ? "YES" : "NO"}</td>
      <td>
        <NavLink
          to={`/administration/company/email/add-edit-template/${
            props.serviceModuleId
            }/${id}`}
          className="btn btn-nothing hover-v"
        >
          <i className="material-icons fs-20">edit</i>
          <span className="hover-name right-top">Edit</span>
        </NavLink>
      </td>
      <td>
        <label className="switch">
          <input
            type="checkbox"
            checked={activeStatus}
            onClick={() => props.statusHandler(id, activeStatus)}
          />
          <span className="slider round" />
        </label>
      </td>
    </tr>
  );
};
export default TableRow;
