import React, { Component } from 'react';
import EditTravelPolicyPage  from './edit-travel-policy-html';
import { travelPolicyService } from '../../../../../../_services/travel-travel-policy.services';
import { toast } from 'react-toastify';
import { alertConstants } from '../../../../../../_constants/alert.constants';
class EditTravelPolicy extends Component {

  state = {
    companyId: localStorage['440a28'],
    // companyId: "5c2c891c30566e1f00b24552",

    editPolicyId: '',
    editPolicyData: [],
    approvalType: '',
    type: '',
    activeStatus: false,
    templateCategory: '',
    conditionsId: [],
    conditions: [],
    allConditions: '',
    anyCondition: false

  }



    componentDidMount() {
      window.scrollTo({top: 0, behavior: 'smooth'});

      this.setState({
        editPolicyId: this.props.match.params.id
      }, () => {
        this.getPolicyById(this.state.companyId, this.state.editPolicyId);
      })


    }

    getPolicyById = (companyId, editPolicyId) => {
      travelPolicyService.getPolicyById(companyId,editPolicyId)
      .then(res => {
        this.setState({
          editPolicyData: res.result,
          activeStatus: res.result.activeStatus,
          templateCategory: res.result.templateCategory,
          conditionsId: res.result.conditionsId,
          conditions: res.result.conditions,
          allConditions: res.result.allConditions,
          anyCondition: res.result.anyCondition,
          approvalType: {
            name: res.result.approvalType
          },
          type: {
            name: res.result.type
          }

        }, () => {
          console.log(this.state)
        })

      },error => {

      })
    }

    approvalTypeHandler = (approvalType) => {
      // console.log(approvalType);
      this.setState({approvalType})

    }
    typeHandler = (type) => {
      // console.log(type);
      this.setState({type});

    }
    toggleConditionHandler = (status, id) => {
      let newStatus = !status;
      let payload = {
        id: id,
        activeStatus: newStatus
      }
      travelPolicyService.togglePolicyStatus(this.state.companyId, payload)
        .then(res => {
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
          this.setState({
            activeStatus: !this.state.activeStatus
          })
          // this.getPoliciesByCategory(this.state.companyId, this.state.selectedCategory);
        }, error => {
          console.log(error);

        })

    }

    saveEditedRuleHandler = (e) => {
      e.preventDefault();
      let editedPolicy = {
        approvalType: this.state.approvalType.name,
        activeStatus: this.state.activeStatus,
        companyId: this.state.companyId,
        templateCategory: this.state.templateCategory,
        type: this.state.type.name,
        conditions: this.state.conditions,
        conditionsId:this.state.conditionsId,
        anyCondition: this.state.anyCondition,
        allConditions: this.state.allConditions
      }
      travelPolicyService.updatePolicy(this.state.editPolicyId, editedPolicy)
        .then(res => {
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
          this.props.history.push('/administration/travel/travel-policy');

        }, error =>{
          console.log(error);

        })

    }

    allCheckedConditionsHandler = (value) => {
      let newValue = !value;
      this.setState({allConditions: newValue, anyCondition: value})
    }
    anyCheckedConditionsHandler = (value) => {
      let newValue = !value;
      this.setState({anyCondition: newValue, allConditions: value});
    }
    renderedFieldTextHandler = (e,id) => {
      // console.log(id);
      let conditions = [...this.state.conditions];
      // if the input id matches the conditions input id, update the value of the text field
      conditions.forEach(condition => {
        if(condition.id === id && condition.type === 'text'){
          condition.value = e.target.value
        }
      });
      this.setState({conditions}, () => console.log(this.state.conditions))


    }
    renderedFieldSelectHandler = (e, condition) => {
      let conditions = [...this.state.conditions];
      conditions.forEach(selectCondition => {
        if(condition.id === selectCondition.id && selectCondition.type === 'select' && condition.listId === selectCondition.listId){
          condition.value = e.listName;
        }
      });
      this.setState({conditions}, () => console.log(this.state.conditions));

    }
    renderedFieldCheckboxHandler = (e, condition) => {
      console.log(e);

    }

    render() {
    return(
      <div>
        <EditTravelPolicyPage
          state={this.state}
          approvalTypeHandler={this.approvalTypeHandler}
          typeHandler={this.typeHandler}
          toggleConditionHandler={this.toggleConditionHandler}
          saveEditedRuleHandler={this.saveEditedRuleHandler}
          allCheckedConditionsHandler={this.allCheckedConditionsHandler}
          anyCheckedConditionsHandler={this.anyCheckedConditionsHandler}
          renderedFieldTextHandler={this.renderedFieldTextHandler}
          renderedFieldSelectHandler={this.renderedFieldSelectHandler}

        />
      </div>
    )
  }
}
export default EditTravelPolicy;