import React, { Component } from 'react';
import TaxAuthoritiesPage from './tax-authority-html';
class TaxAuthorities extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <TaxAuthoritiesPage
                />
            </div>
        )
    }
}
export default TaxAuthorities;