import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import DropdownButton from "./components/DropDownButton";
import Menu from "../Menu";
import StaticMenu from "../staticMenu";
import Loader from "src/_components/common/Loader";

const FieldsPage = props => {
  const {
    fields,
    handlePageChange,
    handleItemChange,
    handleStatusChange,
    closeToggleHandler,
    toggleFieldSelect,
    handleHeaderButtonClick,
    changeModule,
    listService,
    loading,
    currentModule
  } = props;
  const closeBtn = (
    <button className="close" onClick={closeToggleHandler}>
      &times;
    </button>
  );

  let activeStyle = ["active-tr"];
  let isItemSelected = fields.selectedField.length;
  let removeButtonStyle = ["btn"];
  if (isItemSelected) {
    removeButtonStyle.push("disabled");
  }
  // if(fields.selectedItemId[0]){

  let fieldsData =
    fields.fields &&
    fields.fields.map(field => {
      return (
        <>
          <tr
            key={field.id}
            className={
              fields.selectedField.some(i => i.id === field.id)
                ? activeStyle
                : "cursor-pointer"
            }
          >
            <td onClick={toggleFieldSelect.bind(this, field)}>
              {field.labelName}
            </td>
            <td>{field.dataType}</td>
            <td>{field.dataType == 'connected_list' ? field.connectedListSource : '-'}</td>
            <td onClick={toggleFieldSelect.bind(this, field)}>
              {field.columnName}
            </td>
            <td>{field.isMandatory === false ? "NO" : "YES"}</td>
            {/* <td>{field.tableName}</td> */}
            <td>
              <p className="td-padding">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={e => handleStatusChange(field)}
                    checked={field.activeStatus}
                    value={field.activeStatus}
                  />
                  <span className="slider round" />
                </label>
                {/* <span>{field.activeStatus ? "Active" : "Inactive"}</span> */}
              </p>
            </td>
          </tr>
        </>
      );
    });
  return (
    <div>
      <div className="admin-tabs">
        <Menu
          changeModule={changeModule}
          currentModule={currentModule}
          listService={listService}
        />
      </div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">F</div>
            <div className="right">
              <h6 className="heading">Fields</h6>
              <p className="text">Manage fields associated with the selected header.</p>
            </div>
          </div>
        </div>
        <div className="p-padding pb-0">
          <div className="form-holder">
            <div className="row">
              <div className="col-4">
                <div className="form-group custom labelup mb-0">
                  <label>Form Type</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-border-basic dropdown-toggle btn-block"
                      data-toggle="dropdown"
                    >
                      {fields.selectedFormHeader
                        ? fields.selectedFormHeader
                        : "Select Form Type"}
                    </button>
                    <div className="dropdown-menu">
                      {fields.formHeaderName
                        ? fields.formHeaderName.map(formhead => {
                          return (
                            <DropdownButton
                              handleHeaderButtonClick={
                                handleHeaderButtonClick
                              }
                              key={formhead.id}
                              id={formhead.id}
                              name={formhead.formHeaderName}
                            />
                          );
                        })
                        : "No Headers Found"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-padding">
          <div className="admin-tabs px-padding">
            <StaticMenu />
          </div>
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-sm-9">
                  <div className="btn-group-margin">
                    <NavLink
                      to={
                        fields.selectedField.length
                          ? `/administration/company/admin-forms/forms-fields/modify-form-fields/${
                          fields.selectedField[0].id
                          }`
                          : `#`
                      }
                    >
                      <button
                        className="btn"
                        disabled={isItemSelected ? false : true}
                      >
                        Modify Field
                      </button>{" "}
                    </NavLink>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="search-custom">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Search"
                    />
                    <span>
                      <i className="material-icons">search</i>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Data Type</th>
                    <th>List Source</th>
                    <th>Column Name</th>
                    <th>Mandatory</th>
                    {/* <th>Table Name</th> */}
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                {(loading)?
                  <tr>
                    <td colSpan="6">
                      <Loader />
                    </td>
                  </tr>
                  :""}
                  {fieldsData ? (
                    fieldsData
                  ) : (
                      <tr key="noDataFound">
                        <td colSpan="6">No data found</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
            {fields.fields && !loading && (
              <div className="table-footer">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            {fields.itemPerPage}
                          </button>
                          <div className="dropdown-menu">
                            <button
                              onClick={handleItemChange.bind(this, 10)}
                              className="dropdown-item"
                            >
                              10
                            </button>
                            <button
                              onClick={handleItemChange.bind(this, 20)}
                              className="dropdown-item"
                            >
                              20
                            </button>
                            <button
                              onClick={handleItemChange.bind(this, 30)}
                              className="dropdown-item"
                            >
                              30
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9">
                    <Pagination
                      activePage={fields.activePage}
                      itemsCountPerPage={fields.itemPerPage}
                      totalItemsCount={fields.totalItemsCount}
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FieldsPage;
