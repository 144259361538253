import React, { Component } from "react";
import CarConfigStep1Page from "./config-step1-html";
import { carConfigService } from "../../../../../../_services/carConfigService";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";

class configStep1 extends Component {
    state = {
        carId: '',
        companyId: localStorage["440a28"],
        editableBy: [],
        appliesTo: [],
        editableTypeOptions: [],
        isEdit: false,
        configName: '',
    }
    formValidator = new SimpleReactValidator();
    componentDidMount = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        let carId = this.props.match.params.carId;
        if (carId !== 'new') {
            this.setState({ carId });
            this.setState({
                isEdit: true
            })
            localStorage.setItem("carId", JSON.stringify(carId));
            this.updateCarConfiguration(carId);
        } else {
            this.setState({ carId });
            localStorage.setItem("carId", JSON.stringify(carId));
        }
        this.getCarTypes();
        this.getCountryTypes();
        this.getCurrencyTypes();
        this.getEditableTypes();
    }
    updateCarConfiguration = async (carId) => {
        let res = await carConfigService.updateCarConfiguration(carId);
        if (res) {
            let editableBy = [];
            (res.result && res.result.editableBy.forEach((id) => {
                return (
                    this.state.editableTypeOptions.forEach((item) => {
                        if (item.value === id) {
                            editableBy.push(item);
                        }
                    })
                )
            }));
            let appliesTo = [];
            (res.result && res.result.appliesTo.forEach((id) => {
                return (
                    this.state.editableTypeOptions.forEach((item) => {
                        if (item.value === id) {
                            appliesTo.push(item);
                        }
                    })
                )
            }))
            this.setState({
                generalData: res.result,
                configName: res.result.configurationName,
                carTypeId: { label: res.result.carTypeId.itemName, value: res.result.carTypeId.id },
                country: { label: res.result.country, value: res.result.countryId },
                currency: { label: res.result.currency, value: res.result.countryId },
                editableBy: editableBy,
                appliesTo: appliesTo,
            })
        }
        

    }
    onNameChangeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    getCarTypes = async () => {
        let res = await carConfigService.getCarTypes(this.state.companyId);
        if (res) {
            this.setState({ carTypesList: res.result });
        }
    }
    onCarTypeChangeHandler = (val) => {
        this.setState({ carTypeId: val });
    }
    getCountryTypes = async () => {
        let res = await carConfigService.getCountryTypes();
        if (res) {
            this.setState({ countryTypesList: res.result });
        }
    }
    onCountryTypeChangeHandler = (val) => {
        this.setState({ country: val });
    }
    getCurrencyTypes = async () => {
        let res = await carConfigService.getCurrencyTypes(this.state.companyId);
        if (res) {
            this.setState({ currencyTypesList: res.result });
        }
    }
    onCurrencyTypeChangeHandler = (val) => {
        this.setState({ currency: val });
    }
    getEditableTypes = async () => {
        let res = await carConfigService.getEditableTypes(this.state.companyId);
        let editableTypeOptions = [];
        res.result && res.result.forEach((item) => {
            editableTypeOptions.push({ label: item.itemName, value: item.id });
        });
        this.setState({ editableTypeOptions })
        if (res) {
            this.setState({ editableTypesList: res.result });
        }
    }
    onEditableByChangeHandler = (event) => {
        let editableBy = [];
        editableBy = (event.map((val) => val));
        this.setState({ editableBy })
    }
    onAppliesToChangeHandler = (event) => {
        let appliesTo = [];
        appliesTo = (event.map((val) => val));
        this.setState({ appliesTo })
    }
    onSubmitForm1 = async () => {
        let payLoad
        if (this.state.carId === 'new') {
            payLoad = {
                "general": {
                    "companyId": this.state.companyId,
                    "configurationName": this.state.configName,
                    "carTypeId": this.state.carTypeId.value,
                    "editableBy": this.state.editableBy.map((item) => item.value),
                    "appliesTo": this.state.appliesTo.map((item) => item.value),
                    "countryId": this.state.country.value,
                    "currencyId": this.state.currency.value
                }
            }
        }
        else {
            payLoad = {
                id: this.state.generalData.id,
                "general": {
                    "companyId": this.state.companyId,
                    "configurationName": this.state.configName,
                    "carTypeId": this.state.carTypeId.value,
                    "editableBy": this.state.editableBy.map((item) => item.value),
                    "appliesTo": this.state.appliesTo.map((item) => item.value),
                    "countryId": this.state.country.value,
                    "currencyId": this.state.currency.value
                }
            }

        }

        if (this.formValidator.allValid()) {
            let res = await carConfigService.carConfigform(payLoad);
            if (res) {
                toast.success(res.message);
                localStorage.setItem("carId", JSON.stringify(res.result.id));
                this.props.history.push('/administration/expense/car-config/config-step2/');
            }
        }
        else {
            this.formValidator.showMessages();
            this.forceUpdate();
        }

    }
    render() {
        return (
            <div>
                <CarConfigStep1Page
                    {...this.state}
                    onNameChangeHandler={this.onNameChangeHandler}
                    onCarTypeChangeHandler={this.onCarTypeChangeHandler}
                    onCountryTypeChangeHandler={this.onCountryTypeChangeHandler}
                    onCurrencyTypeChangeHandler={this.onCurrencyTypeChangeHandler}
                    onEditableByChangeHandler={this.onEditableByChangeHandler}
                    onAppliesToChangeHandler={this.onAppliesToChangeHandler}
                    onSubmitForm1={this.onSubmitForm1}
                    formValidator={this.formValidator}
                />
            </div>
        );
    }
}
export default configStep1;