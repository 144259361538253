import React, { Component } from 'react';
import moment from 'moment';
import Pagination from "react-js-pagination";
import Select from "./../../../../_components/common/_forms/select";
import Loader from 'src/_components/common/Loader';

class CashadvanceApproveDataList extends Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-striped table-selected">
          <thead>
            <tr>
              <th>CASH ADVANCE ID</th>
              <th>Request ID</th>
              <th>Employee Name</th>
              <th>Date of Issue</th>
              <th>Request Date</th>
              <th>Request Amount</th>
              <th>Approved Amount</th>
              <th>Issued Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          {this.props.isLoading ?
            <tbody>
            <td colSpan="9" className="text-center">
              <Loader />
            </td>
          </tbody>:
            <tbody>
              {this.props.approveCashAdvance.length > 0 ?
                this.props.approveCashAdvance.map((cashAdvanceList) => {
                  let cashAdvanceNameIndex, amountIndex, currencyIndex, selectedCurrency, cashAdvanceData;
                  cashAdvanceData = cashAdvanceList.data;
                  cashAdvanceNameIndex = cashAdvanceData.findIndex((cashDataList) => cashDataList.columnName === "CASH_ADVANCE_NAME");
                  amountIndex = cashAdvanceData.findIndex((cashDataList) => cashDataList.columnName === "AMOUNT");
                  currencyIndex = cashAdvanceData.findIndex((cashDataList) => cashDataList.columnName === "CURRENCY");
                  // commentIndex = cashAdvanceData.findIndex((cashDataList) => cashDataList.columnName === "COMMENT");
                  selectedCurrency = cashAdvanceList.data[currencyIndex].connectedListData.listManagementData.filter((currency) => currency.id === cashAdvanceList.data[currencyIndex].fieldValue);
                  return (
                    <tr key={cashAdvanceList.id} data-toggle="modal" data-target="#newcashAdvanceModal" onClick={() => this.props.onSelectCashAdvanceHandler(cashAdvanceList.id)}>
                      <td >
                        <p className="link">{cashAdvanceList.cashAdvanceId}</p>
                        <p>{cashAdvanceNameIndex !== -1 ? cashAdvanceList.data[cashAdvanceNameIndex].fieldValue : '-'}</p>
                      </td>
                      <td>
                        {cashAdvanceList.requestId}
                      </td>
                      <td className="text-primary">
                        <p className="link">
                          {cashAdvanceList.userName}
                        </p>
                      </td>
                      <td>
                        {cashAdvanceList.dateOfIssue ? moment(cashAdvanceList.dateOfIssue).format('DD MMM YYYY h:mm a') : '-'}
                      </td>
                      <td>
                        {moment(cashAdvanceList.createdAt).format('DD MMM YYYY h:mm a')}</td>

                      <td>
                        <p>{selectedCurrency.length && selectedCurrency[0].itemCode}{" "}</p>
                        {/* {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amountIndex !== -1 && cashAdvanceList.data[amountIndex].fieldValue ? cashAdvanceList.data[amountIndex].fieldValue : 0)} */}
                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cashAdvanceList.requestedAmount ? cashAdvanceList.requestedAmount : 0)}
                      </td>

                      <td>
                        {selectedCurrency.length && selectedCurrency[0].itemCode}{" "}
                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cashAdvanceList.amountApproved ? cashAdvanceList.amountApproved : 0)}
                      </td>
                      <td>
                        {cashAdvanceList.issuedCurrency}{" "}
                        {cashAdvanceList.amountIssued ? new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cashAdvanceList.amountIssued ? cashAdvanceList.amountIssued : 0) : '-'}
                      </td>
                      <td>
                        {cashAdvanceList && (cashAdvanceList.status === "Approved") ?
                          <button className="btn btn-nothing text-success"><i className="material-icons fs-12">thumb_up</i> {cashAdvanceList.status}</button>
                          : (cashAdvanceList.status === "Pending Submission")
                            ? <button className="btn btn-nothing"><i className="material-icons fs-12">
                              error
                          </i> {cashAdvanceList.status}</button>
                            : (cashAdvanceList.status === "Pending Approval")
                              ? <button className="btn btn-nothing"><i className="material-icons fs-12">
                                hourglass_empty
                            </i> {cashAdvanceList.status}</button>
                              : (cashAdvanceList.status === "Issued")
                                ? <button className="btn btn-nothing text-success-light"><i className="material-icons fs-12">
                                  check_circle
                              </i> {cashAdvanceList.status}</button>
                                : <button className="btn btn-nothing text-danger"><i className="material-icons fs-12">thumb_down</i> {cashAdvanceList.status}</button>
                        }
                      </td>
                    </tr>
                  )
                }) : <tr><td colSpan={8} className="text-center">No Records Found</td></tr>
              }
            </tbody>
          }
        </table>

        <div className="table-footer">
          <div className="row">
            <div className="col-md-3">
              {this.props.totalCount > 0 && (
                <div className="page-count">
                  <div className="text">Items</div>
                  <div className="count">
                    <div className="dropdown" >
                      <Select
                        placeholder={this.props.perPageLimit}
                        keyName={"items"}
                        options={this.props.pageItemsList}
                        controlFunc={e => this.props.onPerPageLimitChangeHandler(e)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-9">
              {this.props.totalCount > 0 && (
                <Pagination
                  activePage={this.props.activePageNumber}
                  itemsCountPerPage={this.props.perPageLimit}
                  totalItemsCount={this.props.totalCount}
                  pageRangeDisplayed={5}
                  onChange={this.props.onPageChangeHandler}
                />
              )}

            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default CashadvanceApproveDataList;