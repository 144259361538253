import React, { Component } from 'react';
import PaymentTypesPage from './payment-types-html';
import { expensePaymentTypeService } from '../../../../../../_services';
import { toast } from "react-toastify";

class PaymentTypes extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.companyId = localStorage["440a28"];
    }
    state = {
        limit: 10,
        selectedPage: 1,
        selectedId: "",
        paymentTypeList: [],
        totalCount: "",
        pageItemsList: [{ items: 10 }, { items: 20 }, { items: 30 }],
        search: ""
    }
    componentDidMount() {
        let data = {
            limit: this.state.limit,
            page: this.state.selectedPage
        }
        this.getPaymentTypeList(data)
    }

    handleItemChange = limit => {
        this.setState({ limit: limit["items"], selectedPage: 1 })
        let data = {
            companyId: this.companyId,
            limit: limit["items"],
            page: this.state.selectedPage
        }
        this.getPaymentTypeList(data)
    };

    pageClickHandler = page => {
        this.setState({ selectedPage: page })
        let data = {
            companyId: this.companyId,
            limit: this.state.limit,
            page: page
        }
        this.getPaymentTypeList(data)
    };


    getPaymentTypeList = async (data) => {
        var res = await expensePaymentTypeService.getPaymentTypeList(this.companyId, data)
        if (res) {
            this.setState({ paymentTypeList: res.result, totalCount: res.count })
        }
    }

    selectedPaymentTypeHandler = (data) => {
        if (this.state.selectedId === data.id) {
            this.setState({ selectedId: "" })
        } else {
            this.setState({ selectedId: data.id })
        }
    }
    deletePaymentTypeHandler = async () => {
        var res = await expensePaymentTypeService.deletePaymentType(this.state.selectedId)
        if (res) {
            toast.success(res.message);
            let data = {
                limit: this.state.limit,
                page: this.state.selectedPage
            }
            this.getPaymentTypeList(data);
            this.forceUpdate();
        }
    }

    statusHandler = async (id, value) => {
        let data = {
            activeStatus: !value
        }
        var res = expensePaymentTypeService.changeStatus(id, data)
        if (res) {
            toast.success(res.message);
            let data1 = {
                limit: this.state.limit,
                page: this.state.selectedPage
            }
            this.getPaymentTypeList(data1)
        }
    }

    inputChangeHandler = async (e) => {
        this.setState({ search: e.target.value })
        let data = {
            limit: this.state.limit,
            page: this.state.selectedPage,
            search: e.target.value
        }
        var res = await expensePaymentTypeService.getSearchList(this.companyId, data)
        if (res) {
            this.setState({ paymentTypeList: res.result, totalCount: res.count })
        } else {
            this.setState({ paymentTypeList: [], totalCount: "" })
        }
    }

    render() {
        return (
            <div>
                <PaymentTypesPage
                    state={this.state}
                    selectedPaymentTypeHandler={this.selectedPaymentTypeHandler}
                    deletePaymentTypeHandler={this.deletePaymentTypeHandler}
                    pageClickHandler={this.pageClickHandler}
                    statusHandler={this.statusHandler}
                    handleItemChange={this.handleItemChange}
                    inputChangeHandler={this.inputChangeHandler}
                />
            </div>
        )
    }
}
export default PaymentTypes;