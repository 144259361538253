import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
class CreditCardsPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  redirect(e) {
    $(".close").click();
    $(".itemisationExpenseModal").close();
  }
  render() {
    return (
      <div>
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">T</div>
            <div className="right">
              <h6 className="heading">Credit Cards</h6>
              <p className="text">Manage Credit Cards</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="p-padding">
          <div className="admin-tabs">
            <ul id="nav-tab123" role="tablist" className="nav nav-tabs px-0" >
              <li className="active">
                <button className="nav-item nav-link active"
                  id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true"
                >Personal Cards</button>
              </li>
              <li>
                <button className="nav-item nav-link" id="nav-company-tab" data-toggle="tab" href="#nav-company" role="tab" aria-controls="nav-company" aria-selected="false">Company Cards</button>
              </li>
            </ul>
          </div>
        </div>
        <div classNmae="p-padding pt-0">
          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              <div className="table-container">
                <div className="form-holder">
                  <div className="float-right">
                    <button className="btn" data-toggle="modal" data-target="#addCardModel">Add Card</button>
                  </div>
                  <h6 className="sub-heading pt-2 mb-4">Personal Cards</h6>
                  <div className="clearfix"></div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped table-selected">
                    <thead>
                      <tr>
                        <th>Brand</th>
                        <th>Card Number</th>
                        <th>Issuer</th>
                        <th>Name</th>
                        <th>Expiry</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr>
                        <td>Amax</td>
                        <td>37XXXXXXXXXXXXX92</td>
                        <td>American Express</td>
                        <td>Jhon B</td>
                        <td>Oct 2024  </td>
                        <td>
                          <button type="button" className="btn btn-nothing hover-v"><i className="material-icons fs-20">edit</i><span className="hover-name right-top">Edit</span></button>
                        </td>
                        <td>
                          <button type="button" className="btn btn-nothing hover-v"><i className="material-icons">delete</i><span className="hover-name right-top">Remove</span></button>
                        </td>
                      </tr>  */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-company" role="tabpanel" aria-labelledby="nav-company-tab">
              <div className="table-container">
                <div className="form-holder">
                  <h6 className="sub-heading mb-0">Company Credit Cards</h6>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped table-selected">
                    <thead>
                      <tr>
                        <th>Brand</th>
                        <th>Card Number</th>
                        <th>Issuer</th>
                        <th>Name</th>
                        <th>Expiry</th>
                        <th>Card Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr>
                        <td><NavLink to="#" data-toggle="modal" data-target="#addCardModel">Amax</NavLink></td>
                        <td><NavLink to="#" data-toggle="modal" data-target="#addCardModel">37XXXXXXXXXXXXX92</NavLink></td>
                        <td><NavLink to="#" data-toggle="modal" data-target="#addCardModel">American Express</NavLink></td>
                        <td><NavLink to="#" data-toggle="modal" data-target="#addCardModel">Jhon B</NavLink></td>
                        <td><NavLink to="#" data-toggle="modal" data-target="#addCardModel">Oct 2024 </NavLink></td>
                        <td><NavLink to="#" data-toggle="modal" data-target="#addCardModel">Ghost card </NavLink></td>
                      </tr>
                      <tr>
                        <td>Amax</td>
                        <td>37XXXXXXXXXXXXX92</td>
                        <td>American Express</td>
                        <td>Jhon B</td>
                        <td>Oct 2024  </td>
                        <td>Ghost card</td>
                      </tr>  */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="addCardModel"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addCardLable"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <h5 className="modal-title" id="addCardLable">
                  Add Card
                </h5>
              </div>
              <form>
                <div className="modal-middle pm-30">
                  <div className="form-group custom labelup">
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"
                      >
                        VISA
                              </button>
                      <div className="dropdown-menu">
                        <button
                          type="button"
                          className="dropdown-item"
                        >
                          VISA
                              </button>
                        <button
                          type="button"
                          className="dropdown-item"
                        >
                          VISA
                              </button>
                      </div>
                    </div>
                    <label>Card Type</label>
                  </div>
                  <div className="form-group custom ">
                    <input className="form-control" placeholder="&nbsp;" />
                    <label>Card Number</label>
                  </div>
                  <div className="form-group custom">
                    <input className="form-control" placeholder="&nbsp;" />
                    <label>Issuer</label>
                  </div>
                  <div className="form-group custom">
                    <input className="form-control" placeholder="&nbsp;" />
                    <label>Name on the Card</label>
                  </div>
                  <div className="form-group custom">
                    <input className="form-control" placeholder="&nbsp;" />
                    <label>Expiry Date</label>
                  </div>
                  <div className="modal-last btn-group-margin">
                    <button type="submit" className="btn" data-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CreditCardsPage;
