import React, { Component } from 'react';
import { minutesToHoursAndMinutes } from "src/utils/util";
import Select from 'react-select';
import { getValue } from 'src/_components/common/lodsh';
import Moment from 'react-moment';

export default class trainCard extends Component {

    state = {
        quotas: [
            {
                label: 'General',
                value: 'GN'
            },
            {
                label: 'Ladies',
                value: 'LDS'
            }
        ]
    }
    render() {

        const { item } = this.props;
        return (
            <div className="devision">
                <div className="div-pad cursor-pointer">
                    {/* add class blu when its open */}
                    <div className="abs-right blu mt-3">
                        <i className="material-icons">arrow_drop_down</i>
                        {/* <i className="material-icons">arrow_drop_up</i> */}
                    </div>
                    <div className="details vendor">
                        <div className="name">
                            <div className="bus-image">
                                <img
                                    src="/assets/images/booking/Indian_Railway.png"
                                    alt=""
                                    width="100"
                                />
                            </div>
                            <div className="bus-info train-padding">
                                <p>
                                    <b>{item.train_from.stationName} - {item.train_to.stationName}</b>
                                </p>
                                <p className="class">
                                    <span className="green-text"> {item.train_from.stationCode} {" "}
                                    - {item.train_to.stationCode} </span>
                                </p>
                                <Moment format="DD MMM YYYY hh:mm a" >
                                    {getValue(this.props, "item.train_start_timestamp")}
                                </Moment>
                                <br />
                                {this.props.bookType} -
                                {this.props.tripType}
                            </div>
                            <div className="clearfix" />
                        </div>
                    </div>
                    <div className="details bus-timing">
                        {/* <p className="text-danger">Non-Refundable</p> */}
                        <div className="btn-group-margin">
                            {
                                item.classes && item.classes.length ? (
                                    item.classes.map((info, index) => {
                                        return (
                                            <button
                                                key={index}
                                                // className="btn mb-2"
                                                className={this.props.trainDetails[item.train_code].seatClass == info.coach_type ? "btn btn-active mb-2" : "btn mb-2"}
                                                onClick={e => this.props.handleClassClick(e, info.coach_type, item.train_code, item)}
                                            >
                                                {info.coach_type}
                                            </button>
                                        )
                                    })
                                ) : null
                            }
                        </div>
                    </div>
                    <div className="details bus-price">
                        <div className="mr train-padding">
                            <div
                                className={
                                    this.props.train_uniq_id !== getValue(item, "train_uniq_id")
                                        ? "flight-price mb-0  "
                                        : "flight-price mb-0  added"
                                }
                            >
                                <div className="cash p-3">
                                    <span>
                                        <h5>{item.train_package_details.currency} {item.train_package_details.price}</h5>
                                    </span>
                                </div>
                                <div
                                    className="d-button"
                                    onClick={e => this.props.addToCart(item)}
                                >
                                    <i className="material-icons">chevron_right
                                    </i>{" "} <span className="d-inline-block">
                                        {(this.props.trainAdding === getValue(item, "train_uniq_id")) ?
                                            "Adding" : (this.props.trainAdding === '' && this.props.train_uniq_id === getValue(item, "train_uniq_id")) ?
                                                "Added" : "Add"}
                                        <br /></span>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="clearfix" />
                </div>
            </div>
        )
    }
}
