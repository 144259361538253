import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import {
  PolicyStep1Page,
  PolicyStep2Page,
  ExpenseTypePage,
  ExpenseType3Page,
  ExpenseCategoryPage,
  ExpenseTypeStep1Page,
  ExpenseTypeStep2Page,
  ExpenseTypeStep3Page,
  ExpenseTypeStep4Page,
  ExpenseTypeLimitsPage,
  ItemizationWizardPage
} from "../../../index";
class ExMainPage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/administration/expense/ex/types") {
      this.props.history.push("/administration/expense/ex/types/list");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/administration/expense/ex/types") {
      this.props.history.push("/administration/expense/ex/types/list");
    }
  }
  render() {
    return (
      <div className="ExpenseTypeHomePage">
        <Switch>
          <Route
            path="/administration/expense/ex/types/list"
            component={ExpenseTypePage}
          />
          <Route
          exact={true}
            path="/administration/expense/ex/types/expense-types-3"
            component={ExpenseType3Page}
          />
          <Route
            path="/administration/expense/ex/types/expense-types-3/:id"
            component={ExpenseType3Page}
          />
          <Route
            exact={true}
            path="/administration/expense/ex/types/expense-types-step1"
            component={ExpenseTypeStep1Page}
          />
          <Route
            path="/administration/expense/ex/types/expense-types-step1/:id"
            component={ExpenseTypeStep1Page}
          />
          <Route
            exact
            path="/administration/expense/ex/types/expense-types-step2"
            component={ExpenseTypeStep2Page}
          />
          <Route
            path="/administration/expense/ex/types/expense-types-step2/:id"
            component={ExpenseTypeStep2Page}
          />
          <Route
            path="/administration/expense/ex/types/expense-types-step3"
            component={ExpenseTypeStep3Page}
          />
          <Route
            path="/administration/expense/ex/types/expense-types-step4"
            component={ExpenseTypeStep4Page}
          />
        </Switch>
      </div>
    );
  }
}
export default ExMainPage;
