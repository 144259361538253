import { config } from "../env";
import { http } from "../_helpers";

//------------list Policies------------------

const getPolicies = (companyId, limit, page) => {
  return http
    .get(
      config.apiExpense +
        `policies?companyId=${companyId}&limit=${limit}&page=${page}`
    )
    .then(data => data);
};

const createPolicy = data => {
  return http.post(config.apiExpense + `policie`, data).then(data => data);
};

const getCustomPolicy = (policyId, companyId) => {
  return http
    .get(config.apiExpense + `policie/${policyId}?companyId=${companyId}`)
    .then(data => data);
};

const searchPolicy = (searchKeyword, companyId, limit, page) => {
  return http
    .get(
      config.apiExpense +
        `policie/search/${searchKeyword}?companyId=${companyId}&limit=${limit}&page=${page}`
    )
    .then(data => data);
};

const changeStatus = (companyId, data) => {
  return http
    .patch(
      config.apiExpense + `policie/active-status?companyId=${companyId}`,
      data
    )
    .then(data => data);
};
const deletePolicy = (companyId, data) => {
  return http
    .patch(
      config.apiExpense + `policie/delete?companyId=${companyId}`,
      data
    )
    .then(data => data);
};

const listFormsPLSF = (
  page,
  limit,
  serviceModuleId,
  formHeaderId,
  companyId
) => {
  // `forms?page=${page}&limit=${limit}&serviceModuleId=${serviceModuleId}&formHeaderId=${formHeaderId}&companyId=${companyId}`
  return http
    .get(
      config.apiUrlCompany + `form-headers/forms?formHeaderName=ExpenseAllocationForm&companyId=${companyId}`

    )
    .then(data => data);
};

// * Modifications by Om, used in expense policies to get allocation forms and expense report forms
const getExpenseAllocationForms = ( page,limit,serviceModuleId,formHeaderId,companyId) => {
  return http
  .get(
    config.apiUrlCompany + `form-headers/forms?formHeaderName=report-allocation&companyId=${companyId}`

  )
  .then(data => data);
}

const getExpenseReportForms = (page,limit,serviceModuleId,formHeaderId,companyId) => {
  return http
  .get(
    config.apiUrlCompany + `form-headers/forms?formHeaderName=report-header&companyId=${companyId}`

  )
  .then(data => data);
}

const getPolicyById = (id, companyId) => {
  return http.get(config.apiExpense + `policie/${id}?companyId=${companyId}`)
    .then(res => res)
}

const updatePolicy = (payload) => {
  return http.patch(config.apiExpense + `policie`, payload)
    .then(res => res)
}


// * end of om's modifications

const workFlow = () => {
  return http
    .get(config.apiExpense + `workflow/general`)
    .then(data => data);
};
const parentList = companyId => {
  return http
    .get(
      config.apiUrlCompany + `list-management?companyId=${companyId}&itemCode=GRP001`
    )
    .then(data => data);
};

const getGroups = companyId => {
  return http.get(config.apiUrlCompany + `list-management?companyId=${companyId}&itemCode=GRP001`)
    .then(res => res)
}

// ---------------------------------------------------------------------------------------

// --------------------------------Exceptions---------------------------------------------

const getExceptions = (companyId, limit, page) => {
  return http
    .get(
      config.apiExpense +
        `exceptions?companyId=${companyId}&limit=${limit}&page=${page}`
    )
    .then(data => data);
};
const getSingleException = (exceptionId, companyId) => {
  return http
    .get(
      config.apiExpense + `exception/${exceptionId}?companyId=${companyId}`
    )
    .then(data => data);
};

const createException = data => {
  return http
    .post(config.apiExpense + `exception`, data)
    .then(data => data);
};

const modifyException = data => {
  return http
    .patch(config.apiExpense + `exception`, data)
    .then(data => data);
};
const searchException = (searchKeyword, companyId, limit, page) => {
  return http
    .get(
      config.apiExpense +
        `exception/search/${searchKeyword}?companyId=${companyId}&limit=${limit}&page=${page}`
    )
    .then(data => data);
};
const deleteException = (companyId, data) => {
  return http
    .patch(
      config.apiExpense + `exception/delete?companyId=${companyId}`,
      data
    )
    .then(data => data);
};
// ---------------------------------------------------------------------------------------

export const expensePolicies = {
  getPolicies,
  searchPolicy,
  createPolicy,
  getCustomPolicy,
  changeStatus,
  deletePolicy,
  listFormsPLSF,
  getExpenseAllocationForms,
  getExpenseReportForms,
  workFlow,
  parentList,
  getGroups,
  getPolicyById,
  updatePolicy,
  // ---- Exceptions -----
  getExceptions,
  getSingleException,
  createException,
  deleteException,
  modifyException,
  searchException
};
