import React, { Component } from 'react';
import BookingHomeCardPage from '../home-cards';
import BookingMenuPage from '../../components/booking-menu';
import TrainBookingPageNew from './TrainBooking';


import { NavLink } from 'react-router-dom';
import TrainBookingPage from './train-booking';
class TrainPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    } 

    render() {
        return (
            <div className="p-padding">
                <div className="row">
                    <div className="offset-xl-1 col-xl-10">
                        <BookingMenuPage />
                        {/* <TrainBookingPage /> */}
                        {/* {localStorage.getItem("440a28") === "5dc5197e1382a2538aa3a8ca"?<TrainBookingPageNew />: <TrainBookingPage />} */}
                        {localStorage.getItem("440a28") === "5dc5197e1382a2538aa3a8ca"?<TrainBookingPageNew />:<TrainBookingPage />}
                        <BookingHomeCardPage />

                    </div>
                </div>
            </div>
        )
    }
}
export default TrainPage
