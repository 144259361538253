import React from "react";
import AsyncSelect from "react-select";
import PropTypes from "prop-types";

const FlightDiscountsPage = props => {
  const {
    selectedAirline,
    editDiscountCodeDetailId,
    allAirlines,
    OnAirlineSelectHandler,
    editDiscountCodeDetails,
    OnDiscountInputHandler,
    OnSubmitHandler,
    OnCancelBtnHandler
  } = props; 
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">R</div>
          <div className="right">
            <h6 className="heading">Air Discounts</h6>
          </div>
          <div className="clearfix" />
        </div>
      </div>
      <div className="p-padding">
        <div className="form-holder">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group custom labelup">
                <label>Airline <span className="text-danger">*</span></label>
                <AsyncSelect
                  value={selectedAirline || editDiscountCodeDetailId}
                  options={allAirlines}
                  loadOptions={allAirlines}
                  onChange={OnAirlineSelectHandler}
                  placeholder="Search And Select"
                />
              </div>
              <div className="form-group custom">
                <input
                  className="form-control"
                  name="discountCode"
                  value={editDiscountCodeDetails.discountCode || ""}
                  placeholder="&nbsp;"
                  onChange={OnDiscountInputHandler}
                />
                <label>Discount Code <span className="text-danger">*</span></label>
              </div>
              <div>
                <div className="btn-group-margin">
                  <button className="btn" onClick={OnSubmitHandler}>
                    Save
                  </button>
                  <button className="btn" onClick={OnCancelBtnHandler}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
FlightDiscountsPage.propTypes = {
  OnAirlineSelectHandler: PropTypes.func,
  OnDiscountInputHandler: PropTypes.func,
  OnSubmitHandler: PropTypes.func,
  OnCancelBtnHandler: PropTypes.func,
  editDiscountCodeDetails: PropTypes.object,
  allAirlines: PropTypes.array
};
export default FlightDiscountsPage;
