import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import Menu from "../menu";
import Loader from "src/_components/common/Loader";
const RuleCastPage = props => {
  const {
    displayedRules,
    itemsCountPerPage,
    activePage,
    selectedRule,
    totalItemsCount
  } = props.allStateItems;

  const {
    handlePageChange,
    handleStatusChange,
    onSearchHandler,
    toggleRuleSelect,
    removeRuleHandler,
    handleLimitChange
  } = props;

  let activeStyle = { background: "#377DFF", color: '#fff' };
  let isItemSelected = !!selectedRule.length;
  let removeButtonStyle = ["btn  "];
  if (isItemSelected) {
    removeButtonStyle.push("disabled");
  }

  return (
    <div>
      <div className="admin-tabs">
        {/* <Menu /> */}
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">Travel Policy - Rules</h6>
              <p className="text">Set all the travel policy rules here.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-sm-9">
                  <div className="btn-group-margin">
                    <NavLink
                      to="/administration/travel/add-rule-class"
                      className="btn"
                    >
                      Add Class
                    </NavLink>
                    <button
                      type="button"
                      onClick={e => removeRuleHandler(selectedRule)}
                      disabled={isItemSelected ? false : true}
                      className="btn"
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="search-custom">
                    <input
                      onChange={e => onSearchHandler(e)}
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Search"
                    />
                    <span>
                      <i className="material-icons">search</i>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>Travel Class Name</th>
                    {/* <th>Travel Config</th> */}
                    {/* <th>property config</th> */}
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedRules && displayedRules.length === 0 ? (
                    <tr>
                      <td colSpan="3" className="text-center">No Records Found</td>
                    </tr>
                  ) : null}

                  {
                    props.allStateItems.isLoading ? (
                      <tr>
                        <td colSpan="3" className="text-center"><Loader /></td>
                      </tr>
                    )
                      : (
                        displayedRules &&
                        displayedRules.map(rule => {
                          return (
                            <tr
                              key={rule.id}
                              className={
                                selectedRule.some(i => i.id === rule.id)
                                  ? "active-tr"
                                  : ""
                              }
                            >
                              <td onClick={e => toggleRuleSelect(rule)}>
                                {rule.className}
                              </td>
                              {/* <td>{rule.travelConfig}</td> */}
                              {/* <td>{rule.propertyConfig}</td> */}
                              <td>
                                <NavLink
                                  to={
                                    "/administration/travel/add-rule-class/" +
                                    rule.id
                                  }
                                  className="btn btn-nothing hover-v"
                                >
                                  <i className="material-icons fs-20">
                                    edit
                              </i>
                                  <span className="hover-name right-top">Edit</span>
                                </NavLink>
                              </td>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    onChange={e => handleStatusChange(rule)}
                                    value={rule.activeStatus}
                                    checked={rule.activeStatus}
                                  />
                                  <span className="slider round" />
                                </label>
                              </td>
                            </tr>
                          );
                        })
                      )
                  }

                </tbody>
              </table>
            </div>
            {displayedRules && displayedRules.length > 0 ? (
              <div className="table-footer">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            {itemsCountPerPage}
                          </button>
                          <div className="dropdown-menu">
                            <button
                              onClick={e => handleLimitChange(10)}
                              className="dropdown-item"
                            >
                              10
                            </button>
                            <button
                              onClick={e => handleLimitChange(20)}
                              className="dropdown-item"
                            >
                              20
                            </button>
                            <button
                              onClick={e => handleLimitChange(30)}
                              className="dropdown-item"
                            >
                              30
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RuleCastPage;
