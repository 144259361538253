import React, { Component } from "react";
import FullDetailsPage from "./_components/full-details";
import cryptr from "../../../../_helpers/crypty";
import { bookingServices } from "../../../../_services/booking.service";
import { cartServices, masterService } from "src/_services";
import Moment from "react-moment";
import InfiniteScroll from "react-infinite-scroll-component";
import FlightList from "./_components/FlightList";
import FilterFlight from "./_components/FilterFlight";
import SortHeadFlight from "./_components/sortHeadFlight";
import ComboFlightList from "./_components/comboFlightList";
import $ from "jquery";
import { toast } from "react-toastify";
import FlightLoader from "./_components/FlightLoader";
import FlightNoResults from "./_components/NoResults";
import moment from "moment";
import { checkIsArray } from "src/utils/util";
import _ from "lodash";
class FlightMultiPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    let item = JSON.parse(cryptr.decrypt(localStorage["1UC43"]));
    this.currency = item.currencyCode;
    this.req = JSON.parse(cryptr.decrypt(this.props.match.params.data));
    this.currentItem = this.req[0]["codeFrom"] + "_" + this.req[0]["codeTo"];
    this.state = {
      value: {
        start: "00:00",
        end: "23:00"
      },
      valueLand: {
        min: "0:00",
        max: "23:00"
      },
      from: this.req[0]["codeFrom"],
      to: this.req[0]["codeTo"],
      cartCount: 0,
      loadingUniqueId: "",
      isOpenPolicy: false,
      storePolicyCart: [],
      reasons: "",
      listOfCurrency: [],
      cardList: [],
      sortStop: "",
      sortDate: "",
      sortPrice: "",
      sortFlight: "",
      loading: true,
      hasMore: true,
      flightSearchIntl: true,
      baggageInfo: []
    };
  }
  typeOfView = 1;
  airdetails = [];
  sortList = [
    { type: "sortPrice" },
    { type: "sortStop" },
    { type: "sortDate" },
    { type: "sortFlight" }
  ];
  changeWay = (type, detail, arrow) => {
    this.typeOfView = type;
    this.setState({ ...this.state });
  };
  closeFilter() {
    $("#filter-card").removeClass("show");
  }
  componentDidMount() {
    sessionStorage.removeItem("modify");
    this.userId = JSON.parse(cryptr.decrypt(localStorage["440a24"]));
    this.getCardList();
    this.getCurrencyCode();
    this.getCartDetail();
    if (this.req && Array.isArray(this.req) && this.req.length) {
      this.airdetails = this.req.map(airdetail => {
        let departureDate = new Date(airdetail.departureDate);
        let modifiedDepartureDate = departureDate.setTime(
          departureDate.getTime() + 1000 * 60
        );
        return {
          ...airdetail,
          departureDate: moment(modifiedDepartureDate)
            .local()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        };
      });
    }
    this.getFlightList();
  }
  componentWillReceiveProps(nextProps) {
    let modify = sessionStorage.getItem("modify");
    if (modify !== undefined && modify !== null && modify !== "") {
      this.req = JSON.parse(cryptr.decrypt(this.props.match.params.data));
      this.req = JSON.parse(
        cryptr.decrypt(sessionStorage.getItem("multicitySearch"))
      );
      if (this.req && Array.isArray(this.req) && this.req.length) {
        this.airdetails = this.req.map(airdetail => {
          let departureDate = new Date(airdetail.departureDate);
          let modifiedDepartureDate = departureDate.setTime(
            departureDate.getTime() + 1000 * 60
          );
          return {
            ...airdetail,
            departureDate: moment(modifiedDepartureDate)
              .local()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          };
        });
      }
      this.url = this.props.match.params.data;
      this.getCardList();
      this.getFlightList(1);
      this.getCartDetail();
      sessionStorage.removeItem("modify");
      sessionStorage.removeItem("req");
      sessionStorage.removeItem("multicitySearch");
    }
  }

  getCurrencyCode = async () => {
    var companyId = localStorage["440a28"];
    var resp = await masterService.getGroups(companyId, "CUR001");
    if (resp) {
      this.setState({
        listOfCurrency: resp.result
      });
    }
  };
  changeCurrency = e => {
    this.currency = e.value;
    this.getFlightList(1);
  };
  handleGetReason = e => {
    this.setState({
      reasons: e.target.value
    });
  };
  selectedItem = [];
  uniqueId = [];

  addToCart = _.debounce(async (e, trip, uniqueId) => {
    if (this.state.violationsId && this.state.isOpenPolicy) {
      if (this.state.violationsId === "") {
        return toast.error("Violations is required");
      } else if (this.state.reasons === "") {
        return toast.error("Reasons is required");
      }
    }
    var req = [];
    if (trip === "combo") {
      req = {
        type: "flight",
        tripType: "multi",
        TRIP_ID: sessionStorage.getItem("TRIPID"),
        flight_fare_id: e.flight_fare_id,
        classType: this.req[0].classType,
        adults: 1,
        airdetails: this.airdetails
      };
    } else {
      req = {
        type: "flight",
        tripType: "multi",
        TRIP_ID: sessionStorage.getItem("TRIPID"),
        flight_fare_id: e.flight_fare_id,
        classType: this.req[0].classType,
        adults: 1,
        tripFlight: this.currentItem,
        airdetails: this.airdetails
      };
    }
    var remove = {
      type: "flight",
      TRIP_ID: sessionStorage.getItem("TRIPID"),
      flight_fare_id: e.flight_fare_id
    };
    const int = this.selectedItem.indexOf(e.flight_fare_id);
    if (int === -1) {
      // to hide below two line get multi select
      if (e.out_of_policy) {
        this.setState({
          isOpenPolicy: true,
          storePolicyCart: {
            e: e,
            tripType: trip,
            uniqueId: uniqueId
          }
        });
        if (!this.state.isOpenPolicy) {
          $("#openOutofPolicy").click();
          return;
        } else {
          this.setState({
            isOpenPolicy: false,
            storePolicyCart: {},
            reasons: "",
            violationsId: ""
          });
        }
      }
      if (this.state.reasons !== "") {
        req.reasons = this.state.reasons;
        req.violationsId = this.state.violationsId;
        $(".close").click();
      }
      this.setState({ loadingUniqueId: uniqueId });
      this.selectedItem = [];
      this.uniqueId = [];
      sessionStorage.setItem("tripType", "multi");
      let assume = await this.addRemove(1, req);
      if (assume) {
        this.selectedItem.push(e.flight_fare_id);
        this.uniqueId.push(uniqueId);
      }
    } else {
      sessionStorage.removeItem("tripType");
      let assume = await this.addRemove(2, remove);
      if (assume) {
        this.selectedItem.splice(int, 1);
        this.uniqueId.splice(int, 1);
      }
    }
  }, 700);

  cancelPolicy = () => {
    this.setState({
      isOpenPolicy: false,
      storePolicyCart: {},
      reasons: "",
      violationsId: ""
    });
  };
  handleChangeReasonId = e => {
    this.setState({
      violationsId: e.target.value
    });
  };
  changeMode = (key, item) => {
    this.listOfFlights[key].choosed_flight_fare_id = item;
    this.setState({ ...this.state });
  };
  addRemove = async (type, req) => {
    var resp = [];
    if (type === 1) {
      var resp = await cartServices.addCartUserData(req);
      this.setState({ loadingUniqueId: "" });
      if (resp) {
        this.getCardList();
        this.getCartDetail();
        return true;
      } else {
        return false;
      }
    } else {
      var resp = await cartServices.removeCartUserData(req);
      this.setState({ loadingUniqueId: "" });
      if (resp) {
        this.getCardList();
        this.getCartDetail();
        return true;
      } else {
        return false;
      }
    }
  };
  cartCount = 0;
  getCartDetail = async () => {
    let tripID = sessionStorage.getItem("TRIPID");
    var resp = await cartServices.getCartListDetail(tripID);
    if (resp) {
      // this.selectedItem = resp;
      if (resp.data.flightData) {
        let assume = resp.data.flightData;
        await assume.forEach((item, key) => {
          if (item.fareContent && item.fareContent.fare) {
            const index = this.selectedItem.indexOf(
              item.fareContent.fare.flight_fare_id
            );
            if (index === -1) {
              this.selectedItem.push(item.fareContent.fare.flight_fare_id);
              this.uniqueId.push(item.fareContent.UNIQUE_ID);
            }
          }
        });
      }
      let count = resp.data.flightData.length + resp.data.hotelData.length;
      this.setState({ cartCount: count });
    }
  };
  selectedItem = [];
  getCardList = async () => {
    let tripID = sessionStorage.getItem("TRIPID");
    var resp = await cartServices.getCartUserList(tripID);
    if (resp) {
      this.setState({ cardList: resp });
    }
  };
  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " m " : " m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  page = 1;
  limit = 10;
  listOfFlights = [];
  airLineList = [];
  airPortList = [];
  allItem = [];
  count = 0;
  stop = "";
  preferredAirline = [];
  layoverTime = "";
  preferredAirport = [];
  fromTime = "";
  toTime = "";
  comboFlightList = [];
  getFlightList = async type => {
    if (type === 1) {
      this.filterSet = true;
      this.listOfFlights = [];
      this.comboFlightList = [];
      this.page = 1;
      this.limit = 10;
      this.count = 0;
      this.setState({
        loading: true,
        hasMore: true
      });
    }
    var req = {
      airdetails: [...this.airdetails],
      adults: "1",
      page: this.page,
      limit: this.limit,
      stop:
        this.stop === 0
          ? "0"
          : this.stop === ""
            ? ""
            : JSON.stringify(this.stop),
      layover:
        this.layoverTime === 0
          ? "0"
          : this.layoverTime === ""
            ? ""
            : JSON.stringify(this.layoverTime),
      preferred_airline: this.preferredAirline,
      refundable: this.refundable,
      classType: this.req[0].classType ? this.req[0].classType : "Economy",
      preferred_airport: this.preferredAirport,
      fromTime: this.fromTime,
      toTime: this.toTime,
      currency: this.currency,
      sort: {
        stop: this.state.sortStop,
        date: this.state.sortDate,
        price: this.state.sortPrice,
        flight: this.state.sortFlight
      }
    };
    var resp = await bookingServices.getMultiCityFlight(req);
    if (resp) {
      this.setState({
        flightSearchIntl:
          resp.flight_searchType && resp.flight_searchType.isIntl
            ? resp.flight_searchType.isIntl
            : false
      });
      this.airLineList = resp.flight_airline_names;
      this.airPortList = resp.flight_airport;
      if (resp.fareContent && Object.keys(resp.fareContent).length > 0) {
        this.req.forEach((list, key) => {
          resp.fareContent[list.codeFrom + "_" + list.codeTo].data.forEach(
            (list, index) => {
              list.priceList = [];
              if (list.fare) {
                let result = this.customizeResult("fare", list, "Retail Fare");
                list.priceList.push(result);
              }
              if (list.CORP) {
                let result = this.customizeResult(
                  "CORP",
                  list,
                  "Corporate Fare"
                );
                list.priceList.push(result);
              }
              list.choosed_flight_fare_id = list.priceList && list.priceList[0];
            }
          );
          if (this.allItem[list.codeFrom + "_" + list.codeTo] === undefined) {
            this.allItem[list.codeFrom + "_" + list.codeTo] =
              resp.fareContent[list.codeFrom + "_" + list.codeTo].data;
          } else {
            this.allItem[list.codeFrom + "_" + list.codeTo] = this.allItem[
              list.codeFrom + "_" + list.codeTo
            ].concat(resp.fareContent[list.codeFrom + "_" + list.codeTo].data);
          }
          list.count =
            resp.fareContent[list.codeFrom + "_" + list.codeTo].count;
        });
        this.count = resp.count;
        this.chooseMulti(this.currentItem, this.req[0]);
      } else {
        resp.comboFlight &&
          resp.comboFlight.data &&
          resp.comboFlight.data.length &&
          resp.comboFlight.data.forEach((item, key) => {
            item.data.forEach(list => {
              list.priceList = [];
              if (list.fare) {
                let result = this.customizeResult("fare", list, "Retail Fare");
                list.priceList.push(result);
              }
              if (list.CORP) {
                let result = this.customizeResult(
                  "CORP",
                  list,
                  "Corporate Fare"
                );
                list.priceList.push(result);
              }
              list.choosed_flight_fare_id = list.priceList && list.priceList[0];
            });
          });
        if (this.comboFlightList.length > 0) {
          this.comboFlightList = this.comboFlightList.concat(
            resp.comboFlight.data
          );
          this.count = resp.count;
        } else {
          this.count = resp.count;
          this.comboFlightList = resp.comboFlight.data;
        }
      }
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  };
  customizeResult = (type, list, typeName) => {
    var price = [];
    price = {
      flight_fare_id: list[type].flight_fare_id,
      flight_total_price: list[type].flight_total_price,
      flight_layoverTime: list[type].flight_layoverTime,
      arriveTime: list[type].arrivin_day,
      airLine: list[type].flight_airline,
      journeyTime: list[type].flight_onward_total_duration,
      type: typeName,
      violations: list.violations,
      out_of_policy: list.out_of_policy,
      fareInfo: {
        totalOverAllPrice: list[type].flight_total_price,
        flightAdultFare: list[type].flight_base_price,
        serviceFee: list[type].flight_service_fee
          ? Number(list[type].flight_service_fee)
          : 0,
        gst: list[type].flight_total_tax,
        taxBreakUp: [
          {
            // totalOverAllPrice: list[type].flight_total_price,
            // flight_base_price: list[type] && list[type].flight_base_price ? Number(list[type].flight_base_price) : 0,
            flight_agency_transaction_fee:
              list[type].flight_agency_transaction_fee,
            flight_convinience_fee: list[type].flight_convinience_fee,
            flight_agency_commission: list[type].flight_agency_commission,
            flight_cute_fee: list[type].flight_cute_fee,
            flight_service_fee: list[type].flight_service_fee
              ? Number(list[type].flight_service_fee)
              : 0,
            flight_gst: list[type].flight_gst
              ? Number(list[type].flight_gst)
              : 0,
            flight_other_charges: list[type].flight_other_charges,
            // flight_total_tax: list[type].flight_total_tax ? Number(list[type].flight_total_tax) : 0,
            flight_congestion_fee: list[type].flight_congestion_fee,
            flight_fuel_surcharge: list[type].flight_fuel_surcharge,
            flight_agency_service_fee: list[type].flight_agency_service_fee,
            flight_air_dev_fee: list[type].flight_air_dev_fee,
            flight_airport_development_fee:
              list[type].flight_airport_development_fee
          }
        ]
      }
    };
    return price;
  };
  fetchMoreData = () => {
    if (this.listOfFlights.length >= this.count) {
      this.setState({ hasMore: false });
      return;
    }
    this.page = this.page + 1;
    this.getFlightList();
  };
  fetchMoreDataCombo = () => {
    if (this.comboFlightList.length >= this.count) {
      this.setState({ hasMore: false });
      return;
    }
    this.page = this.page + 1;
    this.getFlightList();
  };

  chooseMulti = (e, set) => {
    if (this.currentItem !== e || this.count === 0) {
      this.count = set.count;
      if (
        this.count >
        (this.allItem[this.currentItem] &&
          this.allItem[this.currentItem].length)
      ) {
        this.setState({ hasMore: true });
      }
    }
    this.currentItem = e;
    this.listOfFlights = this.allItem[this.currentItem];
    this.setState({
      from: set.codeFrom,
      to: set.codeTo
    });
  };
  detailId = "";
  arrowId = "";
  viewDetail = async (id, arrow, e) => {
    this.detailId === arrow ? (this.detailId = "") : (this.detailId = arrow);
    this.arrowId === arrow ? (this.arrowId = "") : (this.arrowId = arrow);
    this.setState({ baggageInfo: [] });
    var req = [];
    if (e === "combo") {
      req = {
        type: "flight",
        tripType: "multi",
        TRIP_ID: sessionStorage.getItem("TRIPID"),
        classType:localStorage.getItem("classType"),//added by sethu
        flight_fare_id: e.flight_fare_id,
        adults: 1,
        airdetails: this.req
      };
    } else {
      req = {
        type: "flight",
        tripType: "multi",
        TRIP_ID: sessionStorage.getItem("TRIPID"),
        classType:localStorage.getItem("classType"),//added by sethu
        flight_fare_id: e.flight_fare_id,
        adults: 1,
        tripFlight: this.currentItem,
        airdetails: this.req
      };
    }
    var resp = await bookingServices.getBaggage(req);
    if (resp) {
      this.setState({ baggageInfo: resp });
    }
  };
  sortItem = categoryName => {
    if (this.state[categoryName] === "") {
      this.state[categoryName] = "asc";
    } else {
      this.state[categoryName] === "asc"
        ? (this.state[categoryName] = "desc")
        : (this.state[categoryName] = "asc");
    }
    this.sortList.forEach(list => {
      if (list.type !== categoryName) {
        this.state[list.type] = "";
      }
    });
    this.setState({ ...this.state });
    this.getFlightList(1);
  };
  stopFilter = e => {
    if (this.stop === e) {
      this.stop = "";
      this.getFlightList(1);
    } else {
      this.stop = e;
      this.getFlightList(1);
    }
  };
  layoverTimeFilter = e => {
    if (this.layoverTime === e) {
      this.layoverTime = "";
      this.getFlightList(1);
    } else {
      this.layoverTime = e;
      this.getFlightList(1);
    }
  };
  filterSet = false;
  selectedAirLine = [];
  getAirline = (e, airline) => {
    const int = this.preferredAirline.indexOf(airline);
    if (int === -1) {
      this.preferredAirline.push(airline);
      this.selectedAirLine.push({ code: e, airLine: airline });
    } else {
      this.preferredAirline.splice(int, 1);
      this.selectedAirLine.splice(int, 1);
    }
    this.getFlightList(1);
  };
  getAirPort = (e, airline) => {
    const int = this.preferredAirport.indexOf(airline);
    if (int === -1) {
      this.preferredAirport.push(airline);
    } else {
      this.preferredAirport.splice(int, 1);
    }
    this.getFlightList(1);
  };
  selectedOption = false;
  enableConnect = () => {
    this.selectedOption = !this.selectedOption;
    if (this.selectedOption === false) {
      this.preferredAirport = [];
      this.getFlightList(1);
    }
    this.setState({ ...this.state });
  };
  storeValue = e => {
    this.filterSet = true;
    this.setState({
      value: e
    });
  };
  completeRange = e => {
    this.fromTime = this.state.value.start;
    this.toTime = this.state.value.end;
    if (this.state.value.start == "0:00") {
      this.fromTime = "0:00";
    } else if (this.state.value.end == "23:00") {
      this.toTime = "23:00";
    }
    if (this.state.value.start) {
      let split = this.state.value.start.split(":");
      if (split[0] <= 9) {
        var time =
          ("0" + split[0]).slice(-2) + ":" + ("0" + split[1]).slice(-2);
        this.fromTime = time;
      }
    }
    if (this.state.value.end) {
      let split = this.state.value.end.split(":");
      if (split[0] <= 9) {
        var time =
          ("0" + split[0]).slice(-2) + ":" + ("0" + split[1]).slice(-2);
        this.toTime = time;
      }
    }
    this.getFlightList(1);
  };
  selectedOptionRefund = false;
  refundable = "";
  onlyRefund = () => {
    this.filterSet = true;
    this.selectedOptionRefund = !this.selectedOptionRefund;
    this.refundable = this.selectedOptionRefund;
    this.getFlightList(1);
  };
  render() {
    var dataId = (localStorage.getItem("440a28"));
    const { hasMore, loading, baggageInfo } = this.state;
    return (
      <div>
        <button
          data-target="#addServiceModal"
          data-toggle="modal"
          className="add-button text-white hover-v position-fixed"
        >
          +<span className="hover-name bottom fs-12">Add Service</span>
        </button>
        <FullDetailsPage
          multiReq={this.req}
          cardList={this.state.cardList}
          type="multi"
          currency={this.currency}
          cartCount={this.state.cartCount}
          typeOfService={"flight"}
        />
        <div className="p-padding top-48">
          {/* flight heading division starts */}
          {!loading && this.count !== 0 && (
            <div>
              {/* if not combo flight display this division */}
              {this.comboFlightList &&
                this.comboFlightList.length === 0 &&
                !this.state.flightSearchIntl ? (
                  <div>
                    <div className="summary-all">
                      {checkIsArray(this.req) &&
                        this.req.map((item, key) => {
                          return (
                            <div
                              key={key}
                              onClick={e =>
                                this.chooseMulti(
                                  `${item.codeFrom}_${item.codeTo}`,
                                  item
                                )
                              }
                              className={
                                this.currentItem ===
                                  `${item.codeFrom}_${item.codeTo}`
                                  ? "summary multi-half active"
                                  : "summary multi-half "
                              }
                            >
                              <div className="d-inline-block text-center">
                                <p>
                                  <i className="material-icons">flight</i>
                                </p>
                              </div>
                              <div className="d-inline-block">
                                <p className="title">
                                  {item.codeFrom}-{item.codeTo} -
                                <Moment format="MMM DD, YYYY">
                                    {item.departureDate}
                                  </Moment>
                                </p>
                                <p className="label">
                                  {item.count}{" "}
                                  <span className="text-lowercase">
                                    {item.classType}
                                  </span>{" "}
                                  flights
                              </p>
                              </div>
                            </div>
                          );
                        })}
                      <div className="clearfix" style={{ clear: "both" }}></div>
                    </div>
                    <div className="clearfix" style={{ clear: "both" }}></div>
                  </div>
                ) : (
                  <div className="summary active">
                    <div className="d-inline-block text-center">
                      <p>
                        <i className="material-icons">flight</i>
                      </p>
                    </div>
                    <div className="d-inline-block">
                      {checkIsArray(this.req) &&
                        this.req.map((item, key) => {
                          return (
                            <span className="title" key={key + "e"}>
                              {item.cityNameFrom} to {item.cityNameTo} -{" "}
                              <Moment format="MMM DD, YYYY">
                                {item.departureDate}
                              </Moment>{" "}
                              {key < this.req.length - 1 && "-"}{" "}
                            </span>
                          );
                        })}
                      <p className="label">{this.count} flights found</p>
                    </div>
                  </div>
                )}
              <div className="clearfix" style={{ clear: "both" }} />
            </div>
          )}
          <div className="row">
            <div
              className={
                loading && !this.filterSet ? "col-lg-12" : "col-xl-10 col-lg-9"
              }
            >
              {loading && <FlightLoader />}
              {/* header division part starts */}
              {!loading && this.count !== 0 && (
                <div>
                  <div className="clearfix" />
                  <div className="devision-container heading">
                    <div className="devision">
                      <div className="new-devision-container">
                        <SortHeadFlight
                          sortItem={this.sortItem}
                          feildName={"sortFlight"}
                          name="Airline"
                          sortType={this.sortFlight}
                          className="details airline"
                        />
                        <div className="details departure">
                          Departure <span>
                            <i className="material-icons sort">
                              arrow_drop_down
                            </i>
                            <i className="material-icons sort">
                              arrow_drop_up
                            </i>
                          </span>
                        </div>
                        <div className="details arrival">
                          Arrival <span>
                            <i className="material-icons sort">
                              arrow_drop_down
                            </i>
                            <i className="material-icons sort">
                              arrow_drop_up
                            </i>
                          </span>
                        </div>

                        <SortHeadFlight
                          sortItem={this.sortItem}
                          feildName={"sortDate"}
                          name="Duration"
                          sortType={this.sortDate}
                          className="details durations"
                        />
                        <SortHeadFlight
                          sortItem={this.sortItem}
                          feildName={"sortStop"}
                          name="Stops"
                          sortType={this.sortStop}
                          className="details stops"
                        />
                        <SortHeadFlight
                          sortItem={this.sortItem}
                          feildName={"sortPrice"}
                          name="Price"
                          sortType={this.sortPrice}
                          className="details price text-right"
                        />
                        <div className="clearfix" />
                      </div>
                    </div>
                  </div>
                  <div className={dataId === "5dc5197e1382a2538aa3a8ca" ? "d-block mb-2" : "d-none"} style={{ background: "#FFF4CB" }}>
                    <div style={{ padding: "6px 10px" }}>
                      <p className="float-left mb-0">
                        <i className="material-icons fs-14" style={{ color: "#AB810A" }}>
                          info
                          </i>
                      </p>
                      <p className="float-left mb-0" style={{ paddingTop: "1px", marginLeft: "10px", color: "#AB810A", fontWeight: "600" }} >Price may differ at the time of booking</p>
                      <p className="clearfix mb-0"></p>
                    </div>
                  </div>
                  {checkIsArray(this.listOfFlights) &&
                    this.listOfFlights.length !== 0 && (
                      <InfiniteScroll
                        dataLength={this.listOfFlights.length}
                        next={this.fetchMoreData}
                        hasMore={hasMore}
                        loader={
                          <p style={{ textAlign: "center" }}>Loading...</p>
                        }
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>No More Search Results Available</b>
                          </p>
                        }
                      >
                        {checkIsArray(this.listOfFlights) &&
                          this.listOfFlights.map((i, index) => (
                            <FlightList
                              key={index}
                              currency={this.currency}
                              cancelPolicy={this.cancelPolicy}
                              state={this.state}
                              handleGetReason={this.handleGetReason}
                              keys={index}
                              changeMode={this.changeMode}
                              items={i}
                              uniqueId={this.uniqueId}
                              airport={this.airPortList}
                              addToCart={this.addToCart}
                              detailId={this.detailId}
                              viewDetail={this.viewDetail}
                              baggageInfo={baggageInfo}
                              changeWay={this.changeWay}
                              selectedItem={this.selectedItem}
                              type={this.typeOfView}
                              arrowId={this.arrowId}
                              getTimes={this.secondsToHms}
                              from={this.state.from}
                              to={this.state.to}
                              handleChangeReasonId={this.handleChangeReasonId}
                            />
                          ))}
                      </InfiniteScroll>
                    )}
                  {checkIsArray(this.comboFlightList) &&
                    this.comboFlightList.length !== 0 && (
                      <InfiniteScroll
                        dataLength={this.comboFlightList.length}
                        next={this.fetchMoreDataCombo}
                        hasMore={hasMore}
                        loader={
                          <p style={{ textAlign: "center" }}>Loading...</p>
                        }
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>No More Search Results Available</b>
                          </p>
                        }
                      >
                        <div id="accordion">
                          {checkIsArray(this.comboFlightList) &&
                            this.comboFlightList.map((i, index) => (
                              <ComboFlightList
                                key={index}
                                keys={index}
                                req={this.req}
                                items={i}
                                state={this.state}
                                currency={this.currency}
                                uniqueId={this.uniqueId}
                                airport={this.airPortList}
                                detailId={this.detailId}
                                viewDetail={this.viewDetail}
                                changeWay={this.changeWay}
                                type={this.typeOfView}
                                arrowId={this.arrowId}
                                getTimes={this.secondsToHms}
                                addToCart={this.addToCart}
                                selectedItem={this.selectedItem}
                                from={this.req.from}
                                to={this.req.to}
                                baggageInfo={baggageInfo}
                                cancelPolicy={this.cancelPolicy}
                                handleGetReason={this.handleGetReason}
                                handleChangeReasonId={this.handleChangeReasonId}
                                tripType="combo"
                              />
                            ))}
                        </div>
                      </InfiniteScroll>
                    )}
                </div>
              )}
              {!loading && this.count === 0 && this.filterSet && (
                <FlightNoResults
                  msg={
                    !loading && this.count === 0 && this.filterSet
                      ? "No flight found that matches your filter criteria"
                      : "There are no flights found in this route for the selected date of journey. Please try a different route or date"
                  }
                />
              )}
            </div>

            {((!loading && this.listOfFlights !== 0 && this.count !== 0) ||
              this.filterSet) && (
                <div className="col-xl-2 col-lg-3">
                  <FilterFlight
                    closeFilter={this.closeFilter}
                    type1={this.typeTime}
                    stop={this.stop}
                    stopFilter={this.stopFilter}
                    layoverTime={this.layoverTime}
                    layoverTimeFilter={this.layoverTimeFilter}
                    takeland={this.takeland}
                    preferredAirline={this.preferredAirline}
                    selectedOptionRefund={this.selectedOptionRefund}
                    airLineList={this.airLineList}
                    airportList={this.airPortList}
                    value={this.state.value}
                    valueLand={this.state.valueLand}
                    getAirline={this.getAirline}
                    onlyRefund={this.onlyRefund}
                    enableConnect={this.enableConnect}
                    handelAirport={this.getAirPort}
                    preferredAirport={this.preferredAirport}
                    selectedAirLine={this.selectedAirLine}
                    selectedOption={this.selectedOption}
                    from={this.state.from}
                    listOfCurrency={this.state.listOfCurrency}
                    to={this.state.to}
                    changeCurrency={this.changeCurrency}
                    completeRange={this.completeRange}
                    storeValue={this.storeValue}
                    currency={this.currency}
                  />
                </div>
              )}
          </div>
        </div>

        <div
          className="modal fade"
          id="photogalaryModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="photogalaryLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-middle">
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner photo-galary">
                    <div className="carousel-item active">
                      <img
                        className="d-block w-100"
                        src="/assets/images/booking/cabin_7.png"
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/assets/images/booking/cabin_6.png"
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/assets/images/booking/cabin_4.png"
                        alt="Third slide"
                      />
                    </div>
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Next</span>
                  </a>
                  <div className="cancel-btn">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
                <div className="bg-light pm-15">
                  <p className="mb-1">
                    <b>A regionally inspired multicourse meal</b>
                  </p>
                  <p className="mb-0">
                    Choose from our regionally inspired menu and taste the vivid
                    flavours of your destination, paired with your choice of
                    complimentary drinks
                  </p>
                </div>
                <div className="pm-15">
                  <div className="row p-2">
                    <div className="col-md-3 px-2">
                      <div className="cabin_images">
                        <img
                          className="img-fluid"
                          src="/assets/images/booking/cabin_7.png"
                          alt="cabin-image"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 px-2">
                      <div className="cabin_images">
                        <img
                          className="img-fluid"
                          src="/assets/images/booking/cabin_6.png"
                          alt="cabin-image"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 px-2">
                      <div className="cabin_images">
                        <img
                          className="img-fluid"
                          src="/assets/images/booking/cabin_5.png"
                          alt="cabin-image"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 px-2">
                      <div className="cabin_images">
                        <img
                          className="img-fluid"
                          src="/assets/images/booking/cabin_7.png"
                          alt="cabin-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FlightMultiPage;
