import React from "react";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
const CompanyProfilePage = props => {
  const { companyDetails } = props;
  if (companyDetails) {
    // console.log(companyDetails);
  }

  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">B</div>
          <div className="right">
            <h6 className="heading">
              {companyDetails && companyDetails.companyName}
            </h6>
            <p className="text">Bangalore</p>
          </div>
        </div>
      </div>
      {/* <div className="inner-header pb-padding light-shadow">
                <div className="row">
                    <div className="col-md-9">
                        <div className="company-logo">
                            <img src="/assets/images/logo.png" alt="" />
                        </div>
                    </div>
                    <div className="col-md-3 options">
                        <NavLink to="/administration/company/edit-company-profile" className="btn   sm-padding">Edit</NavLink>
                    </div>
                </div>
            </div> */}
      <div className="p-padding">
        <div className="profile-card mr-5">
          <div className="details">
            <NavLink
              to="/administration/company/profile/edit"
              className="btn btn-nothing float-right hover-v mr-2"
            >
              <i className="material-icons fs-20">
                edit
              </i>
              <span className="hover-name right-top">Edit</span>
            </NavLink>
            <h5 className="sub-heading mb-4">Basic Details</h5>
            <div className="">
              <div className="shape-name mb-5 lg trans">
                <div className="left icon">
                  <i className="material-icons">call</i>
                </div>
                <div className="right">
                  <p className="text">Company Phone</p>
                  {companyDetails && (
                    <h6 className="heading">
                      {companyDetails.contactInfo
                        ? companyDetails.contactInfo["phone"]
                        : "-"}
                    </h6>
                  )}
                </div>
              </div>
              <div className="shape-name mb-5 lg trans">
                <div className="left icon">
                  <i className="material-icons">email</i>
                </div>
                <div className="right">
                  <p className="text">Company Email</p>
                  {companyDetails && (
                    <h6 className="heading">
                      {companyDetails.contactInfo
                        ? companyDetails.contactInfo["email"]
                        : "-"}
                    </h6>
                  )}
                </div>
              </div>
              <div className="shape-name lg trans">
                <div className="left icon">
                  <i className="material-icons">
                    receipt
                  </i>
                </div>
                <div className="right">
                  <p className="text">Billing Type</p>
                  {companyDetails && (
                    <h6 className="heading">
                      {companyDetails.billingCycleId
                        ? companyDetails.billingCycleId.billingType
                        : "-"}
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="profile-card">
          <div className="details">
            <h6 className="value">Defaults</h6>
            <div className="row">
              <div className="col-md-3">
                <div className="shape-name margin-bottom lg bg-light-blue">
                  <div className="left icon">
                    <i className="material-icons">delete</i>
                  </div>
                  <div className="right">
                    <p className="text">Decimal Separator</p>
                    {companyDetails && (
                      <h6 className="heading">
                        {companyDetails.decimalSeparator
                          ? companyDetails.decimalSeparator.decimalSeparator
                          : "-"}
                      </h6>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="shape-name margin-bottom lg bg-light-blue">
                  <div className="left icon">
                    <i className="material-icons">money</i>
                  </div>
                  <div className="right">
                    <p className="text">Default Currency</p>
                    {companyDetails && (
                      <h6 className="heading">
                        {companyDetails.countryId
                          ? companyDetails.countryId.currency
                          : "-"}
                      </h6>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="shape-name margin-bottom lg bg-light-blue">
                  <div className="left">1</div>
                  <div className="right">
                    <p className="text">Fiscal year start date</p>
                    <h6 className="heading">
                      <Moment format="MMM DD, YYYY">
                        {companyDetails ? companyDetails.fiscalYearDate : "-"}
                      </Moment>{" "}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="shape-name margin-bottom lg bg-light-blue">
                  <div className="left icon">
                    <i className="material-icons">calendar_today</i>
                  </div>
                  <div className="right">
                    <p className="text">Date Format</p>
                    {companyDetails && (
                      <h6 className="heading">
                        {companyDetails.dateFormat
                          ? companyDetails.dateFormat.dateFormat
                          : "-"}
                      </h6>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="shape-name lg bg-light-blue">
                  <div className="left">A</div>
                  <div className="right">
                    <p className="text">Default Language</p>
                    {companyDetails && (
                      <h6 className="heading">
                        {companyDetails.defaultLanguage
                          ? companyDetails.defaultLanguage.languageName
                          : "-"}
                      </h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div >
  );
};
export default CompanyProfilePage;
