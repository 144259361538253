/* eslint-disable default-case */
import React, { Component } from "react";
import { Select, Input } from "src/_components/common/_forms";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate } from "react-day-picker/moment";
import DynamicFormFields from "src/_components/common/dynamicFields";
import "react-day-picker/lib/style.css";
import AsyncSelect from "react-select/lib/Async";
export default class DefaultField extends Component {
  renderField = type => {
    switch (type) {
      case DynamicFormFields.DATA_TYPE_TEXT:
        return (
          <div className="form-group custom">
            <input
              type="text"
              className="form-control"
              placeholder="&nbsp;"
              value={this.props.value ? this.props.value : ""}
              onChange={e => this.props.defaultValueChangeHandler(e, type)}
            />
            <label>{type} <span className="text-danger">*</span></label>
          </div>
        );

      case DynamicFormFields.DATA_TYPE_EMAIL:
        return (
          <div className="form-group custom">
            <input
              type="email"
              className="form-control"
              placeholder="&nbsp;"
              value={this.props.value}
              onChange={e => this.props.defaultValueChangeHandler(e, type)}
            />
            <label>{type} <span className="text-danger">*</span></label>
          </div>
        );
      case DynamicFormFields.DATA_TYPE_INTEGER:
        return (
          <div className="form-group custom">
            <input
              type="number"
              className="form-control"
              placeholder="&nbsp;"
              value={this.props.value}
              onChange={e => this.props.defaultValueChangeHandler(e, type)}
            />
            <label>{type} <span className="text-danger">*</span></label>
          </div>
        );
      case DynamicFormFields.DATA_TYPE_AMOUNT:
        return (
          <>
          <div className="form-group custom">
            <input
              type="number"
              className="form-control"
              placeholder=""
              value={this.props.value}
              onChange={e => this.props.defaultValueChangeHandler(e, type)}
            />
            <label>{type} <span className="text-danger">*</span></label>
          </div>
        
          </>
        );
      case DynamicFormFields.DATA_TYPE_CHECKBOX:
        return (
          <div className="form-group">
            <label className="switch">
              <input
                type="checkbox"
                className="form-control"
                value={this.props.value}
                checked={this.props.value ? this.props.value : false}
                onChange={e =>
                  this.props.defaultValueChangeHandler(
                    e,
                    this.props.value,
                    type
                  )
                }
              />
              <span className="slider round" />
            </label>
          </div>
        );

      case DynamicFormFields.DATA_TYPE_BOOLEAN:
        return (
          <div className="form-group">
            <label className="switch">
              <input
                type="checkbox"
                className="form-control"
                value={this.props.value}
                checked={this.props.value ? this.props.value : false}
                onChange={e =>
                  this.props.defaultValueChangeHandler(
                    e,
                    this.props.value,
                    type
                  )
                }
              />
              <span className="slider round" />
            </label>
          </div>
        );

      case DynamicFormFields.DATA_TYPE_CONNECTED_LIST:
        return (
          <div className="form-group custom  textarea">
            <Select
              placeholder={this.props.selectPlaceholder || "Select a group"}
              options={
                this.props.connectedListData
                  ? this.props.connectedListData.listManagementData
                  : []
              }
              keyName={"itemName"}
              controlFunc={this.props.defaultSelectHandler}
            />
          </div>
        );

      case DynamicFormFields.DATA_TYPE_SYSTEM_LIST:
        return (
          <div className="mb-3">
            <Select
              placeholder={this.props.selectPlaceholder || "Select a group"}
              options={
                this.props.connectedListData
                  ? this.props.connectedListData.listManagementData
                  : []
              }
              keyName={"itemName"}
              controlFunc={this.props.defaultSelectHandler}
            />
          </div>
        );

      case DynamicFormFields.DATA_TYPE_DATE:
        return (
          <div className="form-group custom labelup">
            <label>{type} <span className="text-danger">*</span></label>
            <DayPickerInput
              format="DD MMM YYYY"
              formatDate={formatDate}
              dayPickerProps={{ disabledDays: { after: new Date() } }}
              onDayChange={e => this.props.defaultValueChangeHandler(e, type)}
              placeholder={this.props.value ? this.props.value : "Select Date"}
            />
          </div>
        );
      
        case DynamicFormFields.DATA_TYPE_SEARCH:
          return (
            <div className="form-group custom labelup">
              <label>{type} <span className="text-danger">*</span></label>
              <AsyncSelect
                    noOptionsMessage={({inputValue}) => inputValue ? 'No results.' : "Start typing to search..."}
                    placeholder={type}
                    loadOptions={this.props.loadOptions}
                    value={this.props.value ? this.props.value : ""}
                    onChange={(event) => this.props.defaultValueChangeHandler(event, type)}
                  />
            </div>
          );
    }
  };
  render() {
    return <div>{this.renderField(this.props.type)}</div>;
  }
}
