import React from "react";

const importAttendeeModel = props => {
  return (
    <div
      className="modal fade"
      id="importAttendeeModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="importAttendeeLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-head">
            <h6 className="modal-subtitle">New</h6>
            <h5 className="modal-title" id="importAttendeeLabel">
              Import Attendee - Upload
            </h5>
          </div>
          <div className="modal-middle pm-30">
            <div className="upload-input mb-3 text-center">
              <input type="file" />
              <div className="py-4 check-box">
              <i className="material-icons">cloud_upload</i>
                <div>Drag and Drop to Upload</div>
              </div>
            </div>
            <div className="text-center pb-5">
              <p className="lighter-p">
                The Imported Attendees will be added to this expense and to you
                Favorites.
              </p>
              <button type="button" className="btn btn-border-primary">
                Download Template
              </button>
            </div>
            <div className="modal-last btn-group-margin">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn"
                data-toggle="modal"
                data-target="#importAttPreviewModal"
                data-dismiss="modal"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default importAttendeeModel;
