import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
class BudgetDashboardPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.state = {
            chartData: {
                labels: ['Airfare', 'Hotel', 'Car', 'Train', 'Bus'],
                datasets: [
                    {
                        label: 'Budget',
                        data: [
                            8100.00, 4000.00, 2500.00, 3100.00, 4600.00
                        ],
                        backgroundColor: [
                            '#377DFF',
                            '#377DFF',
                            '#377DFF',
                            '#377DFF',
                            '#377DFF'
                        ],
                        borderWidth: 0
                    },
                    {
                        label: 'Actual',
                        data: [
                            7000.00, 2200.00, 2600.00, 2100.00, 2600.00
                        ],
                        backgroundColor: [
                            '#ff7f72',
                            '#ff7f72',
                            '#ff7f72',
                            '#ff7f72',
                            '#ff7f72'
                        ],
                        borderWidth: 0
                    }
                ]
            }
        }
    }
    render() {
        const path = "url(/assets/images/user_1.png)";
        const profImg = {
            backgroundImage: path
        };
        let data = this.props;
        return (
            <div>
                <div className="p-padding">
                    <div className="white-card">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="h-100 p-padding">
                                    <Bar
                                        data={this.state.chartData}
                                        options={{
                                            maintainAspectRatio: false
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 border-left">
                                <div className="audit-step p-padding pb-0">
                                    <button className="s1 active">
                                        Quarterly
                                    </button>
                                    <button className="s1">
                                        Service Based
                                    </button>
                                </div>
                                <div className="table-container mx-0">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-selected">
                                            <thead>
                                                <tr>
                                                    <th>DESCRIPTION</th>
                                                    <th>BUDGET</th>
                                                    <th>ACTUAL</th>
                                                    <th>VARIABLE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p><span className="coloured__dot rounded-circle bg-warning mr-2"></span>Flight</p>
                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                    </td>
                                                    <td>USD 10,000.00</td>
                                                    <td>USD 8,000.00</td>
                                                    <td>USD 10,000.00</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><span className="coloured__dot rounded-circle bg-danger mr-2"></span>Bus</p>
                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                    </td>
                                                    <td>USD 10,000.00</td>
                                                    <td>USD 8,000.00</td>
                                                    <td>USD 10,000.00</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><span className="coloured__dot rounded-circle bg-success mr-2"></span>Car</p>
                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                    </td>
                                                    <td>USD 10,000.00</td>
                                                    <td>USD 8,000.00</td>
                                                    <td>USD 10,000.00</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><span className="coloured__dot rounded-circle bg-primary mr-2"></span>Hotel</p>
                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                    </td>
                                                    <td>USD 10,000.00</td>
                                                    <td>USD 8,000.00</td>
                                                    <td>USD 10,000.00</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="white-card">
                                <div className="px-padding py-3">
                                    <h6 className="sub-heading mb-0">Trips Completed</h6>
                                </div>
                                <div className="table-container mx-0">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-selected mb-0">
                                            <thead>
                                                <tr>
                                                    <th>EMPLOYEE NAME</th>
                                                    <th>BUDGET (USD)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Ravi G</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 10,000.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Ashwani Kumar</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 8,000.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Ravi G</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 5,400.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Vishwa Reddy</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 8,000.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Shwetha</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 7,000.00
                                            </td>
                                                </tr>
                                                <tr className="border-bottom">
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Madhuri Dixit</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 4,000.00
                                            </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-nothing text-primary py-3">View All</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="white-card">
                                <div className="px-padding py-3">
                                    <h6 className="sub-heading mb-0">Trips in Process</h6>
                                </div>
                                <div className="table-container mx-0">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-selected mb-0">
                                            <thead>
                                                <tr>
                                                    <th>EMPLOYEE NAME</th>
                                                    <th>BUDGET (USD)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Ravi G</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 10,000.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Ashwani Kumar</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 8,000.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Ravi G</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 5,400.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Vishwa Reddy</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 8,000.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Shwetha</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 7,000.00
                                            </td>
                                                </tr>
                                                <tr className="border-bottom">
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Madhuri Dixit</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 4,000.00
                                            </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-nothing text-primary py-3">View All</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="white-card">
                                <div className="px-padding py-3">
                                    <h6 className="sub-heading mb-0">Trips Forecast</h6>
                                </div>
                                <div className="table-container mx-0">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-selected mb-0">
                                            <thead>
                                                <tr>
                                                    <th>EMPLOYEE NAME</th>
                                                    <th>BUDGET (USD)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Ravi G</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 10,000.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Ashwani Kumar</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 8,000.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Ravi G</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 5,400.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Vishwa Reddy</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 8,000.00
                                            </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Shwetha</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 7,000.00
                                            </td>
                                                </tr>
                                                <tr className="border-bottom">
                                                    <td>
                                                        <div className="shape-name">
                                                            <div className="left img"
                                                                style={profImg}></div>
                                                            <div className="right">
                                                                <h6 className="heading pt-2">Madhuri Dixit</h6>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        USD 4,000.00
                                            </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-nothing text-primary py-3">View All</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        )
    }
}
export default BudgetDashboardPage