import React from "react";
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBWyyK5gkujBpxYNim55MUoSfo6cDWJM6w&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `200px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        position: null,
        onMarkerMounted: ref => {
          refs.marker = ref;
        },
        onPositionChanged: () => {
          const position = refs.marker.getPosition();
          this.props.getLocation({
            lat: parseFloat(position.lat().toFixed(5)),
            long: parseFloat(position.lng().toFixed(5))
          });
        }
      });
    }
  }),

  withScriptjs, withGoogleMap)((props) => (
    <GoogleMap
      defaultOptions={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: true,
        zoomControl: true,
      }}
      defaultZoom={12}
      defaultCenter={props.center}
    >
      {props.isMarkerShown && (
        <Marker
          position={{ lat: props.lat, lng: props.lng }}
          draggable={true}
          ref={props.onMarkerMounted}
          onPositionChanged={props.onPositionChanged}
        />
      )}
    </GoogleMap>
  ));

class MyMapComponentWrapper extends React.PureComponent {
  state = {
    isMarkerShown: true,
  };
  render() {
    const center = {
      lat: parseFloat(this.props.latitude),
      lng: parseFloat(this.props.longitude)
    }
    return (
      <div>
        <MyMapComponent
          getLocation={this.props.getLocation}
          lat={this.props.latitude}
          lng={this.props.longitude}
          center={center}
          isMarkerShown={this.state.isMarkerShown}
        />
      </div>
    );
  }
}

export default MyMapComponentWrapper;
