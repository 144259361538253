import React from "react";
import Pagination from "react-js-pagination";
import Select from "react-select";

const ExceptionPage = props => {
  const {
    exceptionsData,
    handlePageChange,
    handleItemChange,
    toggleExceptionSelect,
    deleteException,
    handleEditableBy,
    formNameHandler,
    createException,
    getSingleException,
    modifyException,
    onSearchHandler,
    validator,
    ModifyValidator
  } = props;



  let isItemSelected = exceptionsData.selectedException.length;
  let removeButtonStyle = ["btn  "];
  if (isItemSelected) {
    removeButtonStyle.push("disabled");
  }
  const checkArray = (data) => {
    return data && Array.isArray(data) && data.length > 0;
  }
  let exceptionsDataAre =
    exceptionsData.exceptions &&
    exceptionsData.exceptions.map(exception => {
      return (
        <tr
          key={exception.id}
          className={
            exceptionsData.selectedException.some(i => i.id === exception.id)
              ? "active-tr"
              : null
          }
        >
          <td onClick={toggleExceptionSelect.bind(this, exception)}>
            {exception.exceptionCode}
          </td>
          <td onClick={toggleExceptionSelect.bind(this, exception)}>
            {exception.exceptionLevel}
          </td>
          <td onClick={toggleExceptionSelect.bind(this, exception)}>
            {exception.editableObj && exception.editableObj.result.map(el => (
              <>{el.itemName} </>
            ))}
          </td>
          <td>{exception.message}</td>
        </tr>
      );
    });

  let editableBy = checkArray(exceptionsData.parentList) ? exceptionsData.parentList.map(el => {
    return {
      value: el.id,
      label: el.itemName
    };
  }) : [];

  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">E</div>
            <div className="right">
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h6 className="heading">Exception</h6>
              <p className="text">Use Exception to manage violation and messages.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-9">
                  <div className="btn-group-margin">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#newExceptions">
                      New
                    </button>
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#modiyExceptions"
                      onClick={getSingleException}
                      disabled={
                        isItemSelected &&
                          (exceptionsDataAre && exceptionsDataAre)
                          ? false
                          : true
                      }>
                      Modify
                    </button>
                    <button
                      type="button"
                      className="btn"
                      onClick={e =>
                        deleteException(exceptionsData.selectedException)
                      }
                      disabled={
                        isItemSelected &&
                          (exceptionsDataAre && exceptionsDataAre)
                          ? false
                          : true
                      }>
                      Remove
                    </button>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="search-custom">
                    <input
                      onChange={e => onSearchHandler(e)}
                      type="text"
                      name="searchInput"
                      className="form-control"
                      placeholder="Search"
                    />
                    <span><i className="material-icons">search</i></span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Level</th>
                    <th>Editable By</th>
                    <th>Message</th>
                  </tr>
                </thead>
                
                <tbody>
                  {exceptionsDataAre &&
                    exceptionsData.exceptions.length !== 0 ? (
                      exceptionsDataAre
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Exceptions found</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
            {exceptionsDataAre ? (
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown">
                            {exceptionsData.itemPerPage}
                          </button>
                          <div className="dropdown-menu">
                            <button
                              onClick={handleItemChange.bind(this, 10)}
                              className="dropdown-item">
                              10
                            </button>
                            <button
                              onClick={handleItemChange.bind(this, 20)}
                              className="dropdown-item">
                              20
                            </button>
                            <button
                              onClick={handleItemChange.bind(this, 30)}
                              className="dropdown-item">
                              30
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={exceptionsData.activePage}
                      itemsCountPerPage={exceptionsData.itemPerPage}
                      totalItemsCount={exceptionsData.totalItemsCount}
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* Add New exceptions */}
        <div
          className="modal fade"
          id="newExceptions"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddTemplateModalLabel"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <button
                  type="button"
                  className="close text-white d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5 className="modal-title" id="AddTemplateModalLabel">
                  New Exceptions
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom">
                  <input
                    className="form-control"
                    placeholder="&nbsp;"
                    type="text"
                    name="exceptionCode"
                    onChange={formNameHandler}
                    value={exceptionsData.exceptionCode || ""}
                  />
                  <label>Exception Code <span className="text-danger">*</span></label>
                  <div className="help-block">{validator.message(
                    "exceptionCode",
                    exceptionsData.exceptionCode,
                    "required|numeric"
                  )}</div>
                </div>
                <div className="form-group custom">
                  <input
                    className="form-control"
                    placeholder="&nbsp;"
                    type="text"
                    name="exceptionLevel"
                    onChange={formNameHandler}
                    value={exceptionsData.exceptionLevel || ""}
                  />
                  <label>Exception Level <span className="text-danger">*</span></label>
                  <div className="help-block">{validator.message(
                    "exceptionCode",
                    exceptionsData.exceptionLevel,
                    "required|numeric|min:001|max:999"
                  )}</div>
                </div>
                <div className="form-group custom labelup textarea">
                  <label>Editable By</label>
                  <Select
                    isMulti
                    options={editableBy}
                    name="editableBy"
                    value={props.editableId}
                    onChange={handleEditableBy}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Search Groups"
                  />
                  <div className="help-block">{validator.message(
                    "exceptionCode",
                    exceptionsData.editableBy,
                    "required"
                  )}</div>
                </div>
                <div className="form-group custom textarea">
                  <textarea
                    rows="2"
                    data-gramm_editor="false"
                    spellCheck="false"
                    className="form-control"
                    placeholder="&nbsp;"
                    type="text"
                    name="message"
                    onChange={formNameHandler}
                    value={exceptionsData.message || ""}
                  />
                  <label>Message <span className="text-danger">*</span></label>
                  <div className="help-block">{validator.message(
                    "exceptionCode",
                    exceptionsData.message,
                    "required"
                  )}</div>
                </div>
                <div className="modal-last btn-group-margin">

                  {!props.isClickSubmit ?
                    <>
                      <button
                        type="button"
                        className="btn"
                        onClick={createException}>
                        {" "}
                        Save
                  </button>
                  <button
                    type="button"
                    className="btn close"
                    onClick={props.onModalCancel}
                    data-dismiss="modal">
                    {" "}
                    Cancel
                  </button>
                    </>
                    :
                    <button
                      type="button"
                      className="btn"
                    >
                      {" "}
                      Please Wait...
                </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------------------Modify exceptions -------------------------------- */}
        <div
          className="modal fade"
          id="modiyExceptions"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddTemplateModalLabel"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <button
                  type="button"
                  className="close text-white d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5 className="modal-title" id="AddTemplateModalLabel">
                  Modify Exceptions
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <form>
                  <div className="form-group custom">
                    <input
                      className="form-control"
                      placeholder="&nbsp;"
                      type="text"
                      name="exceptionCode"
                      onChange={formNameHandler}
                      value={exceptionsData.exceptionCode || ""}
                    />
                    <label>Exception Code <span className="text-danger">*</span></label>
                    <div className="help-block">
                      {ModifyValidator.message(
                        "exceptionCode",
                        exceptionsData.exceptionCode,
                        "required|numeric"
                      )}
                    </div>
                  </div>
                  <div className="form-group custom">
                    <input
                      className="form-control"
                      placeholder="&nbsp;"
                      type="text"
                      name="exceptionLevel"
                      onChange={formNameHandler}
                      value={exceptionsData.exceptionLevel || ""}
                    />
                    <label>Exception Level <span className="text-danger">*</span></label>
                    <div className="help-block">
                      {ModifyValidator.message(
                        "exceptionCode",
                        exceptionsData.exceptionLevel,
                        "required|numeric|min:001|max:999"
                      )}
                    </div>
                  </div>
                  <div className="form-group custom labelup textarea">
                    <label>Editable By</label>
                    <Select
                      isMulti
                      name="editableBy"
                      value={props.editableId}
                      options={editableBy}
                      onChange={handleEditableBy}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Search Group"
                    />
                  </div>
                  {ModifyValidator.message(
                    "exceptionCode",
                    exceptionsData.editableBy,
                    "required"
                  )}
                  <div className="form-group custom textarea">
                    <textarea
                      rows="2"
                      data-gramm_editor="false"
                      spellCheck="false"
                      className="form-control"
                      placeholder="&nbsp;"
                      type="text"
                      name="message"
                      onChange={formNameHandler}
                      value={exceptionsData.message || ""}
                    />
                    <label>Message <span className="text-danger">*</span></label>
                    <div className="help-block">{ModifyValidator.message(
                      "exceptionCode",
                      exceptionsData.message,
                      "required"
                    )}
                    </div>
                  </div>
                </form>{" "}
                <div className="modal-last btn-group-margin">
                  <button
                    type="button"
                    className="btn"
                    onClick={modifyException}>
                    {" "}
                    Modify and Save
                  </button>
                  <button
                    type="button"
                    className="btn close"
                    onClick={props.onModalCancel}
                    data-dismiss="modal">
                    {" "}
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
export default ExceptionPage;
