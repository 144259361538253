import React, { Component } from "react";
import MidOfficeHistoryPage from "./mid-office-history-html";
import { midOfficeHistoryService } from "../../../../../../_services";
import { toast } from "react-toastify";

class MidOfficeHistory extends Component {
  state = {
    page: 1,
    search: '',
    limit: 10,
    totalCount: null,
    flightData: [],
    companyId: localStorage["440a28"],
    bookingType: '',
    totalCount: null,
    pageItemsList: [{ items: 10 }, { items: 20 }, { items: 30 }, { items: 40 }],
    isLoading: true,
    clientList: [],
    clientName: 'Filter by Company',
    btnActive: false,
    typeName: 'FLIGHT',
    fromDate: null,
    toDate: null,
    downloadHistoryLink: '',
    allTypeData: []
  }
  componentDidMount = () => {
    this.getCommonData("FLIGHT");
    this.getClientList();
  }
  getCommonData = (type) => {
    this.setState({ allTypeData: [], bookingType: type });
    let data = {
      bookingType: type,
      search: this.state.search,
      page: this.state.page,
      limit: this.state.limit,
      companyId: this.state.companyId
    }
    this.getData(data);
  }
  getData = async (data) => {
    this.setState({ isLoading: true });
    let res = await midOfficeHistoryService.getCommonData(data);
    if (res) {
      this.setState({ allTypeData: res.result, totalCount: res.count, isLoading: false });
    }
    else {
      this.setState({ allTypeData: [], totalCount: 0, isLoading: false })
    }
  }
  searchHandler = (e) => {
    if (e.target.value !== '') {
      let data = {
        bookingType: this.state.bookingType,
        search: e.target.value,
        page: this.state.page,
        limit: this.state.limit,
        companyId: this.state.companyId
      }
      this.getData(data);
    }
    else {
      let data = {
        bookingType: this.state.bookingType,
        search: this.state.search,
        page: this.state.page,
        limit: this.state.limit,
        companyId: this.state.companyId
      }
      this.getData(data);
    }
  }
  handleItemChange = limit => {
    this.setState({ limit: limit.items, page: 1 });
    let data = {
      bookingType: this.state.bookingType,
      search: this.state.search,
      page: this.state.page,
      limit: limit.items,
      companyId: this.state.companyId
    }
    this.getData(data);
  };
  handlePageChange = (pageNumber) => {
    this.setState({
      page: pageNumber,
    })
    let data = {
      bookingType: this.state.bookingType,
      search: this.state.search,
      page: pageNumber,
      limit: this.state.limit,
      companyId: this.state.companyId
    }
    this.getData(data);
  }
  getClientList = async () => {
    let res = await midOfficeHistoryService.getClientList();
    if (res) {
      this.setState({ clientList: res.result });
    }
  }
  typeList = [{ type: 'FLIGHT' }, { type: 'HOTEL' }, { type: 'CAR' }, { type: 'BUS' }, { type: 'TRAIN' }];
  changeType = (val) => {
    this.setState({ typeName: val.type });
    this.getCommonData(val.type);
  }
  handleDayChange = (day) => {
    this.setState({ fromDate: day });
  }
  handleDayChange2 = (day) => {
    this.setState({ toDate: day });
  }
  getDownloadHistory = async () => {
    let data = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      bookingType: this.state.bookingType,
      companyId: this.state.companyId
    }
    if (data.fromDate && data.toDate) {
      let res = await midOfficeHistoryService.getDownloadHistory(data);
      if (res) {
        this.setState({ downloadHistoryLink: res.result });
      }
      else {
        toast.warning('Selected Date is invalid!!!');
      }
    }
    else {
      toast.warning("Select correct From and To date to download the history");
    }

  }
  render() {
    return (
      <div>
        <MidOfficeHistoryPage
          typeList={this.typeList}
          state={this.state}
          getDownloadHistory={this.getDownloadHistory}
          handlePageChange={this.handlePageChange}
          handleItemChange={this.handleItemChange}
          handleDayChange={this.handleDayChange}
          handleDayChange2={this.handleDayChange2}
          changeType={this.changeType}
          searchHandler={this.searchHandler}
        />
      </div>
    );
  }
}
export default MidOfficeHistory;
