import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import Loader from "src/_components/common/Loader";
import { Input, Select } from "../../../../../../common/_forms";
const ExpenseCategoryPage = props => {
  const {
    addSubmit,
    state,
    validator,
    handleChange,
    loading,
    loading2,
    listExpenseCategory,
    handleSearch,
    itemsPerPage,
    activePage,
    count,
    pageItemsList,
    handleItemChange,
    handlePageChange,
    choosedItem,
    chooseEdit,
    handleStatusChange,
    removeItem,
    editSubmit,
    getData,
    close
  } = props;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">@</div>
            <div className="right">
              <h6 className="heading">Category</h6>
              <p className="text">Manage Expense Category associated with each expense type for reporting purpose.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-9">
                  <div className="btn-group-margin">
                    <button
                      type="button"
                      data-target="#CreatUserModal"
                      data-toggle="modal"
                      className="btn"
                    >
                      New
                    </button>
                    {choosedItem === "" ? (
                      <button
                        type="button"
                        className="btn"
                        disabled
                      >
                        Modify
                      </button>
                    ) : (
                        <button
                          type="button"
                          onClick={getData}
                          data-target="#updateUserModal"
                          data-toggle="modal"
                          className="btn"
                        >
                          Modify
                      </button>
                      )}
                    {choosedItem === "" ? (
                      <button
                        type="button"
                        className="btn"
                        disabled
                      >
                        Remove
                      </button>
                    ) : (
                        <button
                          type="button"
                          onClick={removeItem}
                          className="btn"
                        >
                          Remove
                      </button>
                      )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="search-custom">
                    <input
                      type="text"
                      name="search"
                      onChange={handleSearch}
                      className="form-control"
                      placeholder="Search"
                    />
                    <span>
                      <i className="material-icons">search</i>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>Expense Category</th>
                    <th>
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {listExpenseCategory && listExpenseCategory.length === 0 && (
                    <tr>
                      <td colSpan="2" className="text-center">No Records Found</td>
                    </tr>
                  )} */}

                  {loading2 ?
                    <tr className="bg-white">
                      <td colSpan="2" className="text-center"><Loader /></td>
                    </tr>
                    
                  :
                    (listExpenseCategory && listExpenseCategory.length !== 0 ?
                      listExpenseCategory.map((items, key) => {
                        return (
                          <tr
                            key={key}
                            className={
                              choosedItem === items.id
                                ? "active-tr"
                                : ""
                            }
                          >
                            <td
                              className="cursor-pointer"
                              onClick={e => chooseEdit(items.id)}
                            >
                              {items.expenseCategoryName}
                            </td>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={e => handleStatusChange(items)}
                                  value={items.activeStatus}
                                  checked={items.activeStatus}
                                />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                        );
                      })
                      :
                      <tr>
                        <td colSpan="2" className="text-center">No Records Found</td>
                      </tr>
                    )
                  }


                </tbody>
              </table>
            </div>
            {count !== 0 && (
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        {pageItemsList && (
                          <Select
                            placeholder={itemsPerPage}
                            keyName={"items"}
                            options={pageItemsList}
                            controlFunc={e => handleItemChange(e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={count}
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="updateUserModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="CreateUserModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <form autoComplete="off" id="create-form" onSubmit={editSubmit}>
              <div className="modal-head">
                <h5 className="modal-title" id="CreateUserModalLabel">
                  Update Category Name
                </h5>
                <button
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {/* <button
                  type="button"
                  onClick={close}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom">
                  <Input
                    label="Category Name"
                    type="text"
                    name="expenseCategoryName"
                    className="bg-white"
                    error={validator.message(
                      "expenseCategoryName",
                      state.expenseCategoryName,
                      "required"
                    )}
                    value={state.expenseCategoryName}
                    controlFunc={handleChange}
                  />
                </div>
                {loading === false ? (
                  <div className="modal-last btn-group-margin">
                    <button
                      type="submit"
                      className="btn"
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      onClick={close}
                      className="btn"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                    <div className="modal-last">
                      <button
                        type="button"
                        className="btn"
                      >
                        Please Wait...
                    </button>
                    </div>
                  )}
              </div>

            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="CreatUserModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="CreateUserModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <form autoComplete="off" id="create-form" onSubmit={addSubmit}>
              <div className="modal-head">
                <h5 className="modal-title" id="CreateUserModalLabel">
                  Create Category Name
                </h5>
                <button
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {/* <button
                  type="button"
                  onClick={close}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom">
                  <Input
                    label="Category Name"
                    type="text"
                    name="expenseCategoryName"
                    className="bg-white"
                    error={validator.message(
                      "expenseCategoryName",
                      state.expenseCategoryName,
                      "required"
                    )}
                    value={state.expenseCategoryName}
                    controlFunc={handleChange}
                  />
                </div>
                {loading === false ? (
                  <div className="modal-last btn-group-margin">
                    <button
                      type="submit"
                      className="btn"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={close}
                      className="btn"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                    <div className="modal-last">
                      <button
                        type="button"
                        className="btn"
                      >
                        Please Wait...
                    </button>
                    </div>
                  )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpenseCategoryPage;
