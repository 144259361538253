import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

class ChangePasswordModal extends React.PureComponent {
  PasswordResetSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password field is required")
      .min(8),
    adminPassword: Yup.string()
      .required("Confirm Password field is required")
      .min(4),
    confirmPassword: Yup.string()
      .required("Admin Password field is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match")
  });
  handleSubmit = async (values, { setSubmitting }) => {
    await this.props.handleChangePassword(values);
    setSubmitting(false);
  };
  render() {
    return (
      <div
        className="modal fade"
        id="changePasswordModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="changePasswordModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="changePasswordModalLabel">
                Change Password
              </h5>
            </div>

            <Formik
              validationSchema={this.PasswordResetSchema}
              initialValues={{
                password: "",
                confirmPassword: "",
                adminPassword: ""
              }}
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, isSubmitting }) => (
                <Form>
                  <div className="modal-middle pm-30">
                    <p>Change password for {this.props.selectedEmail}</p>
                    <div className="form-group custom">
                      <Field
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder="&nbsp;"
                      />
                      <label>New Password</label>
                      <p className="text-danger"><ErrorMessage className="text-danger" name="password" /></p>
                    </div>
                    <div className="form-group custom">
                      <Field
                        name="confirmPassword"
                        type="password"
                        className="form-control"
                        placeholder="&nbsp;"
                      />
                      <label>Confirm Password</label>
                      <p className="text-danger"><ErrorMessage name="confirmPassword" /></p>
                    </div>
                    {/* <div className="form-group">
                                            <label className="checkbox-container">
                                                Signout from all devices (Recommended)
                    <input type="checkbox" />
                                                <span className="checkmark" />
                                            </label>
                                        </div> */}
                    <div className="form-group custom">
                      <Field
                        name="adminPassword"
                        type="password"
                        className="form-control"
                        placeholder="&nbsp;"
                      />
                      <label>Admin Password</label>
                      <p className="text-danger"><ErrorMessage name="adminPassword" /></p>
                    </div>
                    <div className="modal-last btn-group-margin">
                      <button
                        disabled={isSubmitting}
                        onSubmit={handleSubmit}
                        type="submit"
                        className="btn"
                      >
                        Ok
                      </button>
                      <button
                        type="submit"
                        className="btn close"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePasswordModal;
