import { getValue } from "src/_components/common/lodsh";
const TrainAmount = props => (
    <div>
        <div className="details to">
            <div className={(getValue(props.request, "train_booking_price") === '' && props.isSubmitted) ? "details-margin not-valid" : "details-margin"}
            >
                <div className="d-table d-table__middle new-thing">
                    <label className="text-left m-label" htmlFor="search2">
                        <p className="label">Amount</p>
                        <h6 className="place">
                            <input class="form-control" name="train_booking_price"
                            onChange={props.handleAmount}
                            placeholder="&nbsp;" value={getValue(props.request, "train_booking_price")} />

                        </h6>
                        <p className="bottom">
                        </p>
                    </label>
                </div>
            </div>
        </div>
    </div>
)
export default TrainAmount;