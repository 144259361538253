import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class Menu extends Component {
    render() {
        return (
            <div>
                {/* <ul className="nav nav-tabs">
                    <li><NavLink to="/administration/expense/delegates" className="show">Delegates</NavLink></li>
                </ul> */}
            </div>
        )
    }
}
export default Menu