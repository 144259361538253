import React from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Select } from "../../../../../common/_forms";

const PaymentTypesPage = props => {
  let { state } = props;
  let tableRow = state.paymentTypeList.length > 0 ? state.paymentTypeList.map((data, index) => <tr key={index} className={state.selectedId === data.id ? "active-tr" : ""}>
    <td onClick={() => props.selectedPaymentTypeHandler(data)}>{data.paymentType}</td>
    <td onClick={() => props.selectedPaymentTypeHandler(data)}>{data.paymentTypeCode}</td>
    <td >{data.appliesTo.map((data, index) =>
      <span className="mr-2">{data}</span>
    )}</td>
    <td>{data.description}</td>
    <td>
      <label className="switch">
        <input type="checkbox" onChange={() => props.statusHandler(data.id, data.activeStatus)} checked={data.activeStatus} />
        <span className="slider round" />
      </label>
    </td>
  </tr>) : <tr><td colSpan="5" className="text-center">No data found</td></tr>

  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">P</div>
            <div className="right">
              <h6 className="heading">Payment Types</h6>
              <p className="text">Add/Modify Payment Types</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <div className="table-container">
              <form className="pt-0">
                <div className="row">
                  <div className="col-md-9">
                    <div className="btn-group-margin">
                      <NavLink
                        to="/administration/expense/add-payment-types"
                        className="btn"
                      >
                        New
                      </NavLink>
                      {state.selectedId === "" ? <button
                        className="btn"
                        disabled={true}
                      >
                        Modify
                      </button> : <NavLink
                          className="btn"
                          to={state.selectedId !== "" ? `/administration/expense/add-payment-types/${state.selectedId}` : "#"}

                          disabled={state.selectedId === "" ? true : false}
                        >
                          Modify
                      </NavLink>}
                      <button
                        type="button"
                        className="btn"
                        disabled={state.selectedId === "" ? true : false}
                        onClick={props.deletePaymentTypeHandler}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="search-custom">
                      <input
                        type="text"
                        name=""
                        className="form-control"
                        placeholder="Search"
                        onChange={props.inputChangeHandler}
                      />
                      <span><i className="material-icons">search</i></span>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-striped table-selected">
                  <thead>
                    <tr>
                      <th>Payment Type</th>
                      <th>payment Type Code</th>
                      <th>Applies To</th>
                      <th>Description</th>
                      <th>status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableRow}
                  </tbody>
                </table>
              </div>
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <Select
                            placeholder={state.limit}
                            keyName={"items"}
                            options={state.pageItemsList}
                            controlFunc={e => props.handleItemChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    {state.totalCount && (
                      <Pagination
                        activePage={state.selectedPage}
                        itemsCountPerPage={state.limit}
                        totalItemsCount={state.totalCount}
                        pageRangeDisplayed={3}
                        onChange={props.pageClickHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="EditFormModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="EditFormModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="EditFormModalLabel">
                Add Delegate
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="search-custom p-3">
              <input
                type="text"
                name=""
                className="form-control"
                placeholder="Search"
              />
              <span className="p-3">
              <i className="material-icons">search</i>
              </span>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-striped table-movable">
                    <tbody>
                      <tr>
                        <td>Chnadler Bing</td>
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td>Ross Geller</td>
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td>Joey Tribiani</td>
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td>Pheobe Buffay</td>
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td>Monika Geller</td>
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td>Rachel Green</td>
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td>Pheobe Buffay</td>
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td>Monika Geller</td>
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td>Rachel Green</td>
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-border-success lg-padding"
              >
                ADD
              </button>
              <button
                type="button"
                className="btn btn-border-basic lg-padding"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="EditFormModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="EditFormModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="EditFormModalLabel">
                Settings
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="inner-header px-2">
                <div className="shape-header">
                  <div className="left">M</div>
                  <div className="right">
                    <h6 className="heading">Mia</h6>
                    <p className="text">mia@me.com</p>
                  </div>
                </div>
              </div>
              <div className="audit-step ">
                <button type="button" className="s1 btn-default active">
                  Expense
                </button>
                <button type="button" className="s1 btn-default">
                  Booking
                </button>
                <button type="button" className="s1 btn-default">
                  Report
                </button>
                <button type="button" className="s1 btn-default">
                  Approvel
                </button>
              </div>
              <div className="step-1 d-none">
                <div className="container">
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped table-movable border-0">
                        <tbody>
                          <tr>
                            <td>Can Submit Requests</td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" checked />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can View Receipts</td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="step-2 ">
                <div className="container">
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped table-movable border-0">
                        <tbody>
                          <tr>
                            <td>Can Prepare</td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" checked />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can Travel</td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="step-3 d-none">
                <div className="container">
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped table-movable border-0">
                        <tbody>
                          <tr>
                            <td>Can Submit Reports</td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" checked />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can View Receipts</td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can Use Reporting</td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="step-4 d-none">
                <div className="container">
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped table-movable border-0">
                        <tbody>
                          <tr>
                            <td>Can Approve</td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" checked />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can Approve temporary </td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can Preview for Approver</td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Receives Approve Emails</td>
                            <td>
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                Save
              </button>
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentTypesPage;
