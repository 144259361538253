import React, { Component } from "react";
import AddUpdateLocationPage from "./add-update-location-html";
import SimpleReactValidator from "simple-react-validator";
import { masterService, companyService } from "../../../../../../_services";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../../_constants/alert.constants";
class AddUpdateLocation extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: "smooth" });
    var companyId = localStorage["440a28"];
    this.state = {
      latitude: 13.0273,
      longitude: 77.57765,
      locationName: "",
      legalName: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
      state: "",
      phoneNumber: "",
      alternativePhone: "",
      gstNumber: "",
      taxPercentage: "",
      faxNumber: "",
      email: "",
      coordinates: [],
      id: "",
      companyId: companyId
    };
  }
  isEdit = false;
  componentDidMount() {
    this.getCountry();
    this.countryName = "Country";
    if (this.props.match.params.id !== undefined) {
      this.isEdit = true;
      this.getLocationDetails(this.props.match.params.id);
    } else {
      this.getMyLocation();
    }
  }
  getDetails = [];
  getLocationDetails = id => {
    companyService.getCompanyLocationDetails(id, this.state.companyId).then(
      data => {
        if (data != null) {
          this.getDetails = data.result;
          this.changeCountry(this.getDetails.countryObj, "country");
          this.getState(this.getDetails.country);
          this.changeState(this.getDetails.stateObj, "state");
          this.getCity(this.getDetails.state);
          this.changeCity(this.getDetails.cityObj, "city");
          this.getAddress(
            this.getDetails.coordinates[0],
            this.getDetails.coordinates[1]
          );
          this.setState({
            latitude: this.getDetails.coordinates[0],
            longitude: this.getDetails.coordinates[1],
            locationName: this.getDetails.locationName,
            legalName: this.getDetails.legalName,
            addressLine1: this.getDetails.addressLine1,
            addressLine2: this.getDetails.addressLine2,
            country: this.getDetails.country,
            state: this.getDetails.state,
            phoneNumber: this.getDetails.phoneNumber,
            alternativePhone: this.getDetails.alternativePhone,
            gstNumber: this.getDetails.gstNumber,
            taxPercentage: this.getDetails.taxPercentage,
            // faxNumber: this.getDetails.faxNumber,
            email: this.getDetails.email,
            coordinates: this.getDetails.coordinates,
            companyId: this.getDetails.companyId,
            id: this.getDetails.id
          });
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  };
  country0Name = "";
  countries = [];
  getCountry = () => {
    masterService.getCountries().then(
      data => {
        if (data != null) {
          this.countries = data.result;
          this.forceUpdate();
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  };
  stateName = "State/Province";
  stateList = [];
  getState = id => {
    masterService.getState(id).then(
      data => {
        if (data != null) {
          this.stateList = data.result;
          this.forceUpdate();
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  };
  getMyLocation = e => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        position => {
          this.getLocation({
            lat: parseFloat(position.coords.latitude.toFixed(5)),
            long: parseFloat(position.coords.longitude.toFixed(5))
          });
        },
        error => {
          this.setState({
            latitude: "err-latitude",
            longitude: "err-longitude"
          });
        }
      );
    } else {
      this.setState({ latitude: 13.02727, longitude: 77.57767 });
    }
  };
  getLocation = e => {
    this.setState({
      latitude: e.lat,
      longitude: e.long,
      coordinates: [e.lat, e.long]
    });
    this.getAddress(e.lat, e.long);
    this.forceUpdate();
  };
  getAddress(lat, lng) {
    masterService.getAddress(lat, lng).then(response => {
      // console.log()
      // this.setState({
      //     addressLine1:response.results[0].formatted_address
      // })
    });
  }
  handleChange = e => {
    var value = e.target.value;
    var name = e.target.name;
    this.state[name] = value;
    this.forceUpdate();
  };
  loading = false;
  submitForm = e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.loading = true;
      var req = {
        addressLine1: this.state.addressLine1,
        addressLine2: this.state.addressLine1,
        alternativePhone: parseInt(this.state.alternativePhone),
        city: this.state.city,
        companyId: this.state.companyId,
        coordinates: this.state.coordinates,
        country: this.state.country,
        email: this.state.email,
        faxNumber: this.state.faxNumber,
        gstNumber: this.state.gstNumber,
        latitude: this.state.latitude,
        legalName: this.state.legalName,
        locationName: this.state.locationName,
        longitude: this.state.longitude,
        phoneNumber: parseInt(this.state.phoneNumber),
        state: this.state.state,
        taxPercentage: parseInt(this.state.taxPercentage)
      };
      companyService.createCompanyLoction(req).then(
        data => {
          this.loading = false;
          toast.success(alertConstants.CREATED_SUCCESSFULLY);
          this.props.history.push("/administration/company/location/list");
        },
        error => {
          this.loading = false;
          if (error.statusCode === 422) {
            let errors = error.message;
            Object.keys(errors).map(key => {
              return toast.error(errors[key]);
            });
          } else {
            toast.error(error.message);
          }
        }
      );
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };
  updateForm = e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.loading = true;
      companyService.updateCompanyLoction(this.state.id, this.state).then(
        data => {
          this.loading = false;
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
          this.props.history.push("/administration/company/location/list");
        },
        error => {
          this.loading = false;
          if (error.statusCode === 422) {
            let errors = error.message;
            Object.keys(errors).map(key => {
              return toast.error(errors[key]);
            });
          } else {
            toast.error(error.message);
          }
        }
      );
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };
  changeCountry = (val, feild) => {
    this.countryName = val.countryName;
    this.state[feild] = val.id;
    this.getState(val.id);
    this.forceUpdate();
  };
  changeState = (val, feild) => {
    this.stateName = val.name;
    this.state[feild] = val.id;
    this.getCity(val.id);
    this.forceUpdate();
  };
  changeCity = (val, feild) => {
    this.cityName = val.cityName;
    this.state[feild] = val.id;
    this.forceUpdate();
  };
  goBack = () => {
    this.props.history.push("/administration/company/company-location");
  };
  cityList = [];
  cityName = "City";
  getCity = id => {
    masterService.getCity(id).then(
      data => {
        if (data != null) {
          this.cityList = data.result;
          this.forceUpdate();
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  };
  render() {
    return (
      <div>
        <AddUpdateLocationPage
          latitude={this.state.latitude}
          longitude={this.state.longitude}
          getLocation={this.getLocation}
          state={this.state}
          error={this.validator}
          handleChange={this.handleChange}
          countries={this.countries}
          countryName={this.countryName}
          stateList={this.stateList}
          stateName={this.stateName}
          changeCountry={this.changeCountry}
          cityList={this.cityList}
          cityName={this.cityName}
          changeState={this.changeState}
          isEdit={this.isEdit}
          changeCity={this.changeCity}
          submitForm={e => this.submitForm(e)}
          updateForm={e => this.updateForm(e)}
          goBack={this.goBack}
          loading={this.loading}
        />
      </div>
    );
  }
}
export default AddUpdateLocation;
