import {http} from '../_helpers';
import { config } from '../env';


 const getPersonalInfo = (id) => {
  let url = `${config.apiUrlCompany}user-personal-info?id=${id}`;
  try{
    return http.get(url).then(res => {
      return (res && res.result) ? res.result : ""
    });
  }catch(err){

  }
}

 const updatePersonalInfo = (id, payload) => {
  let url = `${config.apiUrlCompany}user-personal-info/update?id=${id}`;
  try{
    return http.patch(url, payload).then(res => res)
  }catch(err){

  }

}

 const createPersonalInfo = (payload) => {
  let url = `${config.apiUrlCompany}user-personal-info`;
  try{
    return http.post(url, payload).then(res => res)
  }catch(err){

  }
}

const getDialCodes = () => {
  let url = `${config.apiUrlCompany}dial-code`;
  try{
    return http.get(url).then(res => {
      return (res && res.result) ? res.result : ""
    });
  }catch(err){

  }
}


export const personalInfoService = {
  getPersonalInfo,
  updatePersonalInfo,
  createPersonalInfo,
  getDialCodes
}

