import React from "react";
import TimeRangeSlider from 'react-time-range-slider';
import { MultiSelect } from "../../../../common/_forms";
import MultiSelectAirport from "./AirportFIlter";
import Select from 'react-select';

const FilterFlight = props => {
  const {
    type1,
    closeFilter,
    handelAirport,
    preferredAirline,
    stop,
    stopFilter,
    layoverTime,
    layoverTimeFilter,
    airLineList,
    getAirline,
    airportList,
    from,
    completeRange,
    storeValue,
    value,
    selectedAirLine,
    selectedOption,
    enableConnect,
    onlyRefund,
    selectedOptionRefund,
    changeCurrency,
    currency,
    preferredAirport,
    listOfCurrency, 
  } = props; 
  let customCurrency = []
  listOfCurrency.forEach(item => {
    customCurrency.push({value:item.itemCode,label:item.itemName})
  })
  return (
    <div>
      <div id="filter-card" className="filter-card">
        <button
          id="filter-toggle"
          className="filter-toggle"
          onClick={e => closeFilter()}
        >
          <i className="material-icons">close</i>
        </button>
        <h6 className="heading grey">Filter</h6>
        <div className="filter border-b">
          {/* <h6 className="heading">Currency</h6> */}
          <div className="type-panel-out">
            <div className="inner-body">
              <div className="form-group custom labelup mb-0">
                <label>Currency</label> 
                {customCurrency && (
                    <Select
                      isSearchable={true} 
                      options={customCurrency}
                      value={{label:currency}}
                      onChange={e => changeCurrency(e, "currency")}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="type-panel filter px-0 border-b">
            <div className="heading">
              <div className="right">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={onlyRefund}
                    checked={selectedOptionRefund}
                  />
                  <span className="slider round" />
                </label>
              </div>
              <span className="mr-2">Refundable</span>
            </div>
          </div>
          <div className="filter border-b">
            <h6>Stops</h6>
            <div>
              {layoverTime === "" && (
                <div className="square-box-c" onClick={e => stopFilter(0)}>
                  <button
                    type="button"
                    className={stop === 0 ? "sqaure-box active" : "sqaure-box"}
                  >
                    <p className="number">0</p>
                    <p className="label">STOPS</p>
                  </button>
                </div>
              )}
              <div className="square-box-c" onClick={e => stopFilter(1)}>
                <button
                  type="button"
                  className={stop === 1 ? "sqaure-box active" : "sqaure-box"}
                >
                  <p className="number">1</p>
                  <p className="label">STOPS</p>
                </button>
              </div>
              <div className="square-box-c" onClick={e => stopFilter(2)}>
                <button
                  type="button"
                  className={stop === 2 ? "sqaure-box active" : "sqaure-box"}
                >
                  <p className="number">1+</p>
                  <p className="label">STOPS</p>
                </button>
              </div>
              <div className="clearfix" />
            </div>
            {stop !== 0 && <h6>Layovers</h6>}
            {stop !== 0 && (
              <div>
                <div
                  className="square-box-c"
                  onClick={e => layoverTimeFilter(1)}
                >
                  <button
                    type="button"
                    className={
                      layoverTime === 1 ? "sqaure-box active" : "sqaure-box"
                    }
                  >
                    <p className="number">1+</p>
                    <p className="label">HR</p>
                  </button>
                </div>
                <div
                  className="square-box-c"
                  onClick={e => layoverTimeFilter(2)}
                >
                  <button
                    type="button"
                    className={
                      layoverTime === 2 ? "sqaure-box active" : "sqaure-box"
                    }
                  >
                    <p className="number">2+</p>
                    <p className="label">HR</p>
                  </button>
                </div>
                <div
                  className="square-box-c"
                  onClick={e => layoverTimeFilter(3)}
                >
                  <button
                    type="button"
                    className={
                      layoverTime === 3 ? "sqaure-box active" : "sqaure-box"
                    }
                  >
                    <p className="number">3+</p>
                    <p className="label">HR</p>
                  </button>
                </div>
                <div className="clearfix" />
              </div>
            )}
          </div>
          <div className="type-panel">
            <h6 className="heading filter">Flight Departure Time</h6>
          </div>
          <div> 
            <div className="filter grey border-b">
              <h6>Take off at {from}</h6>
              <div className="filter-time">
                <p className="left-time">{value.start}</p>
                <p className="right-time">{value.end}</p>
                <TimeRangeSlider
                  disabled={false}
                  format={24}
                  maxValue={"23:00"}
                  minValue={"00:00"}
                  name={"time_range"}
                  onChangeComplete={value => completeRange(value)}
                  onChange={e => storeValue(e)}
                  step={15}
                  value={value} />
              </div>
            </div>

          </div>
          <div className="type-panel">
            <div className="inner-body filter-airlines bg-white">
              <MultiSelect
                placeholder="Search Flight Name"
                perfered={preferredAirline}
                selected={selectedAirLine}
                list={airLineList}
                getAirline={getAirline}
              />
            </div>
          </div>
          {stop !== 0 && (
            <div className="type-panel">
              <div className="heading">
                <div className="right">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={enableConnect}
                      checked={selectedOption}
                    />
                    <span className="slider round" />
                  </label>
                </div>
                <span className="mr-2">Connecting Airport</span>
              </div>
              <div className="inner-body over-scroll">
                {selectedOption && (
                  <MultiSelectAirport
                    list={airportList}
                    preferredAirport={preferredAirport}
                    handelAirport={handelAirport}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterFlight;
