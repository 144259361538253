import React from "react";

const cardTransactionRow = props => {
  return props.dataList.map((data, index) => {
    return (
      <tr key={index}>
        <td colSpan="7" className="text-center">No Records Found</td>
        {/* <td>
          <p className="td-padding d-inline-block v-align-top">
            <label className="checkbox-container small mt-1">
              <input
                type="checkbox"
                checked={props.checked[data.firstName] === true}
                onChange={e => props.toggleRow(data.firstName)}
              />
              <span className="checkmark" />
            </label>
          </p>
          <div className="d-inline-block">
            <div className="receipt-circle">
              <img src="/assets/images/booking/bill.jpg" alt="" />
            </div>
          </div>
        </td>
        <td>
          <p className="td-padding">{data.firstName}</p>
        </td>
        <td>
          <p className="td-padding">{data.lastName}</p>
        </td>
        <td>
          <p className="td-padding">Four Seasons</p>
        </td>
        <td>
          <p className="td-padding">5/11/2019</p>
        </td>
        <td>
          <p className="td-padding">INR 56.00</p>
        </td> */}
      </tr>
    );
  });
};

export default cardTransactionRow;
