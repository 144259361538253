import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const AddTravelDiscountPage = props => {
  const {
    travelCategories,
    travelCategoryId,
    getDiscountCodesByCategoryHandler,
    travelCategoryName,
    ConfigId,
    discounts
  } = props;
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">E</div>
            <div className="right">
              <h6 className="heading">Deal Codes</h6>
              <p className="text">
                Use the travel discount feature to manage discounts for GDS and
                Vendors.
              </p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="tabs-container">
            <div className="left">
              <div className="heading">
                <h6 className="sub-heading">Items</h6>
              </div>
              <div className="scroll">
                {travelCategories.length < 0 ? (
                  <div>No Result Found</div>
                ) : (
                    <ul>
                      {travelCategories &&
                        travelCategories.map((value, key) => (
                          <li
                            key={value.id}
                            //  className="selected"
                            className={
                              travelCategoryId === value.id ? "selected" : ""
                            }
                          >
                            <button
                              className="btn btn-nothing"
                              onClick={() =>
                                getDiscountCodesByCategoryHandler(
                                  value.id,
                                  value.travelCategoryName
                                )
                              }
                            >
                              {" "}
                              {value.travelCategoryName} Discounts
                          </button>
                          </li>
                        ))}
                    </ul>
                  )}
              </div>
              )}
            </div>
            {travelCategoryName ? (
              <div className="right">
                <div className="heading">
                  <NavLink
                    to={`/administration/travel/add-air-discounts/${
                      ConfigId
                      }`}
                    className="btn btn-border-primary sm-padding"
                  >
                    Add
                  </NavLink>
                  <div>
                    <h6 className="sub-heading pt-3">
                      {travelCategoryName}
                    </h6>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="scroll">
                  {discounts.length <= 0 ? (
                    <div className="right_trip">
                      <div className="scroll-content">
                        <h6 className="label-heading p-4">
                          <b>No discount coupon available</b>
                        </h6>
                      </div>
                    </div>
                  ) : (
                      <div>
                        {discounts &&
                          discounts.map((item, key) => (
                            <div key={item.id} className="right_trip">
                              <div className="scroll-content">
                                <div className="row">
                                  <div className="col-4">
                                    <p className="label">vendor</p>
                                    <h6 className="label-heading">
                                      <b>{item.vendorName}</b>
                                    </h6>
                                  </div>
                                  <div className="col-4">
                                    {/* <p className="label"></p> */}
                                    <h6 className="label-heading">
                                      <b />
                                    </h6>
                                  </div>
                                  <div className="col-4">
                                    <p className="label">Discount Code</p>
                                    <h6 className="label-heading">
                                      <b>{item.discountCode}</b>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="validity">
                                <div className="btn-group-margin text-right">
                                  <NavLink
                                    to={`/administration/travel/edit-air-discounts/${
                                      ConfigId
                                      }/${item.id}/${item.discountCode}/${
                                      item.vendorName
                                      }/${item.vendor}`}
                                    className="btn float-none"
                                  >
                                    Edit
                                  </NavLink>
                                  <button
                                    className="btn float-none"
                                    type="button"
                                    onClick={e => props.deleteTraveDiscount(item.id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                                <div className="clearfix" />
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                </div>
              </div>
            ) : (
                <div className="right">
                  <div className="d-table d-table__middle">
                    <div>Please select the travel category
                    </div>
                  </div>
                </div>
              )}
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </div>
  );
};

AddTravelDiscountPage.propTypes = {
  getDiscountCodesByCategoryHandler: PropTypes.func,
  travelCategories: PropTypes.array,
  travelCategoryId: PropTypes.string,
  discounts: PropTypes.array,
  ConfigId: PropTypes.string
};
export default AddTravelDiscountPage;
