import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { getWorkflowListBasedOnType, getWorkflowListMyApproval } from 'src/_services/v2Service';
import { Select } from 'src/_components/common/_forms';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import Loader from 'src/_components/common/Loader';
import { getValue } from 'src/_components/common/lodsh';
import { COMMON_CONSTANT } from 'src/_components/common/constants/COMMON_CONSTANTS';
import { getFirstLastName } from 'src/utils/util';
import TRIPMENU from './TRIPMENU';
class TripsApprovalPage extends Component {
  constructor(props) {
    super(props)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.state = {
      loading: false,
      listData: [],
      search: "",
      count: 0,
      page: 1,
      limit: 10,
      isChecked: false
    }
  }
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
  componentDidMount() {
    this.getList()
  }
  getList = async () => {
    const { search, page, limit } = this.state;
    this.setState({ loading: true, count: 0, listData: [] });
    var resp = await getWorkflowListBasedOnType(search, page, limit, "trip", sessionStorage.getItem("isCheckedTrip"));
    this.setState({
      loading: false,
      count: getValue(resp, COMMON_CONSTANT.COUNT, 0),
      listData: getValue(resp, COMMON_CONSTANT.RESULT, [])
    });
  }
  onSearchTextChangeHandler = async (event) => {
    await this.setState({ search: event.target.value });
    this.getList();
  }
  handleRejectModalClick = comments => {
    if (comments) {
      this.setState({
        selectedRejectionComment: comments
      });
    }
  };
  handlePageChange = (page) => {
    this.setState({ page: page }, () => {
      this.getList()
    })
  }
  handleItemChange = (limit) => {
    this.setState({ page: 1 })
    this.setState({ limit: limit.items }, () => {
      this.getList()
    })
  }
  handleSearch = (e) => {
    this.setState({
      search: e.target.value
    }, () => {
      this.getList()
    })
  }
  checkBox = async () => {
    this.setState({page:1})
    if (sessionStorage.getItem("isCheckedTrip") === "true") {
      sessionStorage.setItem("isCheckedTrip", "false")
    } else if (sessionStorage.getItem("isCheckedTrip") === "false") {
      sessionStorage.setItem("isCheckedTrip", "true")
    }
    let page;
    if(this.state.page >= 1){
       page = 1
    }
    const { search, limit } = this.state;
    this.setState({ loading: true, count: 0, listData: [] });
    var resp = await getWorkflowListBasedOnType(search, page, limit, "trip", sessionStorage.getItem("isCheckedTrip"));
    this.setState({
      loading: false,
      count: getValue(resp, COMMON_CONSTANT.COUNT, 0),
      listData: getValue(resp, COMMON_CONSTANT.RESULT, [])
    });
  }
  getUpdatedTripName = (list) => {
    let updatedTripName = getValue(list, COMMON_CONSTANT.TRAVEL_ENTRY, []).find(item => item.columnName == "ITINERARY_NAME");
    return getValue(updatedTripName, COMMON_CONSTANT.FIELDVALUE, "");
  }
  render() {
    const { listData, loading, page, limit, count } = this.state;
    return (
      <div>
        <div className="p-padding">
          <TRIPMENU />
          <div className="white-card">
            <div className="form-holder">
              <div className="">
                <div className="table-container main-box ">
                  <form className="py-0 px-4">
                    <div className="row">
                      <div className="col-md-2">
                      </div>
                      <div className="col-md-2 offset-5">

                        <div className="form-group">
                          <div className="switch-right">
                            <div className="right">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="form-control"
                                  checked={(sessionStorage.getItem("isCheckedTrip") === "true") ? true : false}
                                  value={sessionStorage.getItem("isCheckedTrip")}
                                  onClick={this.checkBox}
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                            <p className="mr-5">Pending for my approval : </p>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-3">
                        <div className="search-custom">
                          <input onChange={this.onSearchTextChangeHandler} type="text" name="" className="form-control" placeholder="Search" />
                          <span><i className="material-icons">search</i></span>
                        </div>
                      </div>

                    </div>
                  </form>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>REQUEST DETAILS</th>
                          <th>REQUEST DATE</th>
                          <th>TRAVEL DATE</th>
                          <th>REQUESTED BY</th>
                          <th>SERVICE Requested</th>
                          <th>ESTIMATED TRIP COST</th>
                          <th className="text-right">STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listData.length === 0 &&
                          <tr>
                            {(loading) ?
                              <td colSpan="7">
                                <Loader />
                              </td> :
                              <td colSpan="7" className="text-center">No Records Found</td>
                            }
                          </tr>
                        }
                        {listData && listData.map((item, key) => {
                          let workFlowStepStatus = getValue(item, "current.STATUS") ? getValue(item, "current.STATUS", "") : getValue(item, "intialStatus", "")
                          const { TRIP_DATA } = item
                          return (
                            <tr key={key}>
                              <td>
                                <NavLink to={"/trips-approve/trip-travel-request/" + item.id} >
                                  <p>{getValue(item, "current.TRIP_ID")}</p>
                                </NavLink>
                                <p>
                                  {this.getUpdatedTripName(item) || getValue(TRIP_DATA, "itenary.TRAVEL_DATE.TRIP_NAME")}
                                </p>
                              </td>
                              <td>
                                <Moment format="DD MMM YYYY hh:mm a">
                                  {getValue(item, "createdAt", '-')}
                                </Moment>
                              </td>
                              <td>
                                {getValue(TRIP_DATA, "itenary.TRAVEL_DATE.TRAVEL_FROM",)}  - {getValue(TRIP_DATA, "itenary.TRAVEL_DATE.TRAVEL_TO",)}
                              </td>
                              <td className="text-primary"><button className="btn btn-nothing text-primary">
                                <p>{getValue(item, "name", '')}</p>
                              </button>
                               <p style={{color:"gray"}}>  {getValue(item,"empId","")}</p>
                              </td>
                              <td>
                                {getValue(TRIP_DATA, "itenary.data.flightData", []).length !== 0 &&
                                  <i className="material-icons mr-2">
                                    flight
                                                                    </i>
                                }
                                {getValue(TRIP_DATA, "itenary.data.hotelData", []).length !== 0 &&
                                  <i className="material-icons">
                                    hotel
                                                                    </i>
                                }
                                {getValue(TRIP_DATA, "itenary.data.carData", []).length !== 0 &&
                                  <i className="material-icons">
                                    directions_car
                                  </i>
                                }
                                {getValue(TRIP_DATA, "itenary.data.trainData", []).length !== 0 && (
                                  <i className="material-icons">train</i>
                                )}
                              </td>
                              <td className="font-weight-bold">
                                {getValue(TRIP_DATA, "itenary.BASE_CURRENCY")}  &nbsp;
                              <NumberFormat value={(TRIP_DATA.itenary && TRIP_DATA.itenary.TRIP_COST) ? parseInt(TRIP_DATA.itenary.TRIP_COST).toFixed(2) : ""} displayType={'text'} thousandSeparator={true} />
                              </td>
                              {/* <td>
                                {workFlowStepStatus && workFlowStepStatus ? (
                                  <div>
                                    {workFlowStepStatus === "Reject" ? (
                                      <div
                                        className="cursor-pointer"
                                        onClick={e =>
                                          this.handleRejectModalClick(
                                            item.RESPONSE.comments
                                          )
                                        }
                                        data-target="#RejectStatusCommentModal"
                                        data-toggle="modal"
                                      >
                                        <button className="btn btn-nothing text-danger text-left">
                                          <i className="material-icons fs-14 align-middle">
                                            thumb_down
                                          </i>
                                          {workFlowStepStatus}
                                        </button>
                                        <p className="text-danger fs-12 top__text w-100px">
                                          <i className="material-icons fs-18 mr-1">
                                            error
                                          </i>
                                          {workFlowStepStatus}
                                        </p>
                                      </div>
                                    ) : workFlowStepStatus === "Approved" ? (
                                      <button className="btn btn-nothing text-success">
                                        <i className="material-icons fs-14 align-middle">
                                          thumb_up
                                        </i>{" "}
                                        {workFlowStepStatus}
                                      </button>
                                    ) : (
                                          item.current.STATUS
                                        )}
                                  </div>
                                ) : workFlowStepStatus &&
                                  item.current?.currentOrderNo !== undefined ? (
                                      item.info?.steps[item.current?.currentOrderNo]
                                        .intialStatus
                                    ) : (item.data && item.data.additionalStatus) ?
                                      (
                                        <div
                                          className="cursor-pointer"
                                          onClick={e =>
                                            this.handleRejectModalClick(
                                              item.data.additionalStatus.comments
                                            )
                                          }
                                          data-target="#RejectStatusCommentModal"
                                          data-toggle="modal"
                                        >
                                          <button className="btn btn-nothing text-danger text-left">
                                            <i className="material-icons fs-14 align-middle">
                                              thumb_down
                                          </i>{" "}
                                            {item.data.additionalStatus.statusType}
                                          </button>
                                          <p className="text-danger fs-12 top__text w-100px">
                                            <i className="material-icons fs-18 mr-1">
                                              error
                                          </i>
                                            {item.data.additionalStatus.comments}
                                          </p>
                                        </div>
                                      )
                                      : (item.TRAVEL_DATE ? item.TRAVEL_DATE.STATUS : "No Status")}
                              </td>*/}
                              <td>
                                {workFlowStepStatus && workFlowStepStatus ?
                                  <div>
                                    {(workFlowStepStatus === "Approved")
                                      ?
                                      <button className="btn btn-nothing text-success"><i className="material-icons fs-12">thumb_up</i> {workFlowStepStatus}</button>
                                      : (workFlowStepStatus === "Pending Submission" || workFlowStepStatus === "Pending Approval") ?
                                        <span>{workFlowStepStatus}</span>
                                        : (workFlowStepStatus === "Issued")
                                          ? <button className="btn btn-nothing text-success-light"><i className="material-icons fs-12">
                                            check_circle
                                                            </i> {workFlowStepStatus}</button>
                                          : (workFlowStepStatus === "Reject") ?
                                            <button className="btn btn-nothing text-danger"><i className="material-icons fs-12">thumb_down</i> {workFlowStepStatus}</button>
                                            :
                                            <span>{workFlowStepStatus}</span>
                                    }
                                  </div>
                                  : <span>{}</span>
                                }
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  {listData.length !== 0 &&
                    <div className="table-footer">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="page-count">
                            <div className="text">Items</div>
                            <div className="count">
                              {this.pageItemsList && (
                                <Select
                                  placeholder={limit}
                                  keyName={"items"}
                                  options={this.pageItemsList}
                                  controlFunc={e => this.handleItemChange(e)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <Pagination
                            activePage={page}
                            itemsCountPerPage={limit}
                            totalItemsCount={count}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="RejectStatusCommentModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="RejectStatusCommentModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="RejectStatusCommentModalLabel">
                  Approver Comment
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <p>{this.state.selectedRejectionComment}</p>
              </div>
              <div className="modal-last btn-group-margin pm-30 pt-0">
                <button
                  type="button"
                  onClick={this.handleCancelRejectModal}
                  className="btn close"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default TripsApprovalPage
