import React, { Component } from 'react';
import ItemizationStep1Page from './itemization-step1-html';
import SimpleReactValidator from 'simple-react-validator';
import { expenseServices } from '../../../../../_services';
import { toast } from 'react-toastify';
import { alertConstants } from '../../../../../_constants/alert.constants'; 

class ItemizationStep1 extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.state = {
            itemizationName: '',
            secondaryExpenseTypeLable: '',
            companyId: localStorage['440a28'], 
        }
        this.validator = new SimpleReactValidator()
    }
    componentDidMount() {
        if (this.props.match.params.id !== undefined) {
            this.isEdit = true;
            this.getItemizationById(this.props.match.params.id)
        } 
    }
    getDetails = []
    getItemizationById = async (id) => {
        var resp = await expenseServices.getItemById(id, this.state.companyId)
        if (resp) {
            this.getDetails = resp.result
            this.setState({
                itemizationName: this.getDetails.itemizationName,
                secondaryExpenseTypeLable: this.getDetails.secondaryExpenseTypeLable, 
            })
        }
    }
    isEdit = false;
    addSubmit = async (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            var resp = await expenseServices.createItemization(this.state)
            if (resp) {
                toast.success(alertConstants.CREATED_SUCCESSFULLY)
                var id = resp.result.id
                this.props.history.push('/administration/expense/ex/itemization/itemization-step2/'+id)
            }
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }
    updateSubmit = async (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            var resp = await expenseServices.updateItemization(this.props.match.params.id, this.state)
            if (resp) {
                toast.success(alertConstants.UPDATED_SUCCESSFULLY)
                this.props.history.push('/administration/expense/ex/itemization/itemization-step2/'+this.props.match.params.id)
            }
        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }
    close = () => {
        this.props.history.push('/administration/expense/ex/itemization/wizard')
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    render() {
        return (
            <div>
                <ItemizationStep1Page
                    addSubmit={this.addSubmit}
                    state={this.state}
                    handleChange={this.handleChange}
                    error={this.validator}
                    close={this.close}
                    isEdit={this.isEdit}
                    updateSubmit={this.updateSubmit}
                />
            </div>
        )
    }
}
export default ItemizationStep1;