import { http } from "../_helpers";
import { config } from "../env";

export const travelDiscountServices = {
  getAllTravelDiscounts,
  getDiscountCodes,
  getFilterCompanyData,
  getTravelCategories,
  getAllAirlines,
  createTravelDiscount,
  activeInactiveStatus,
  UpdateTravelDiscount,
  createNewCompany,
  deleteSelectedBranch,
  createCopyOfSelectedBranch,
  updateCompanyId,
  deleteTravelDiscount,
  getAllWithoutLimitTravelDiscounts,
  getAllCompanyGroups
};

function getAllCompanyGroups(companyId){
  return http.get(config.apiUrlCompany +`list-management?companyId=${companyId}&itemCode=GRP001`).then(data => {
    return data;
  });
}

function getAllTravelDiscounts(companyId, currentPage, limit) {
  return http.get(config.apiUrlTravel +`travel-discount/get-all?companyId=${companyId}&page=${currentPage}&limit=${limit}`)
    .then(data => {
      return data;
    });
}
function getAllWithoutLimitTravelDiscounts(companyId) {
  return http.get(config.apiUrlTravel +`travel-discount/get-all?companyId=${companyId}`)
    .then(data => {
      return data;
    });
}

function getDiscountCodes(travelCategoryId, id) {
  return http.get(config.apiUrlTravel +`travel-discount/get-discount-codes?travelCategoryId=${travelCategoryId}&id=${id}`)
    .then(data => {
      return data;
    });
}

function getFilterCompanyData(companyId, search) {
  return http.get(config.apiUrlTravel +`travel-discount/get-all?companyId=${companyId}&search=${search}`)
    .then(data => {
      return data;
    });
}

function getTravelCategories() {
  return http.get(config.apiUrlTravel + `travel-discounts/get-travel-categories`)
    .then(data => {
      return data;
    });
}

function getAllAirlines() {
  return http.get(config.apiUrlTravel + `airline/search?search=`)
    .then(data => {
      return data;
    });
}

function createTravelDiscount(configId, data){
  return http.post(config.apiUrlTravel + `travel-discount/add-discount/${configId}` , data)
  .then(data => {
    return data;
  });
}

function activeInactiveStatus(configId, data){
  return http.patch(config.apiUrlTravel + `travel-discount/change-status/${configId}`, data)
  .then(data => {
    return data;
  });
}

function UpdateTravelDiscount(configId, data){
  return http.patch(config.apiUrlTravel + `travel-discount/update-discount/${configId}` , data)
  .then(data => {
    return data;
  });
}

function createNewCompany(data){
  return http.post(config.apiUrlTravel + `travel-discount/add-config` , data)
  .then(data => {
    return data;
  });
}

function deleteSelectedBranch(id) {
  return http.patch(config.apiUrlTravel + `travel-discount/remove-config/${id}`)
  .then(data => {
    return data;
  });
}

function createCopyOfSelectedBranch(configId) {
  return http.post(config.apiUrlTravel + `travel-discount/add-duplicate-config`, configId)
  .then(data => {
    return data;
  });
}

function updateCompanyId(id , data){
  return http.patch(config.apiUrlTravel + `travel-config/edit-config?id=${id}` ,data)
  .then(data => {
    return data;
  });
}

function deleteTravelDiscount(configId , discountId){
  return http.patch(config.apiUrlTravel + `travel-discount/remove-discount?id=${configId}&discountId=${discountId}`)
  .then(data => {
    return data;
  });
}