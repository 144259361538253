const travelFieldConstants = {
  DATA_TYPE_LIST_MANAGEMENT: 'LIST-MANAGEMENT',
  DATA_TYPE_TEXT: 'TEXT',
  DATA_TYPE_NUMBER: 'NUMBER',
  DATA_TYPE_NUMBER_OF_DAYS: 'NUMBER-OF-DAYS',
  DATA_TYPE_NUMBER_OF_HOURS: 'NUMBER-OF-HOURS',
  DATA_TYPE_NUMBER_OF_MINUTES: 'NUMBER-OF-MINUTES',
  DATA_TYPE_AMOUNT: 'AMOUNT',
  DATA_TYPE_DATE: 'DATE',
  DATA_TYPE_SEARCH: 'SEARCH',
  DATA_TYPE_CONDITIONAL_DATE: 'CONDITIONAL-DATE',
  DATA_TYPE_CONDITIONAL_NUMBER: 'CONDITIONAL-NUMBER',
  DATA_TYPE_CONDITIONAL_DROP_DOWN: 'CONDITIONAL-DROP-DOWN',
  DATA_TYPE_AMOUNT_PERCENTAGE_DROP_DOWN: 'AMOUNT-PERCENTAGE-DROP-DOWN',
  DATA_TYPE_CHEAPEST_DROPDOWN: 'CHEAPEST-DROP-DOWN',
  DATA_TYPE_REFUNDABLE_NON_REFUNDABLE_DROP_DOWN: 'REFUNDABLE-NON-REFUNDABLE-DROP-DOWN',
  DATA_TYPE_MILE_KM_DROP_DOWN: 'MILE-KM-DROP-DOWN',
  DATA_TYPE_DIFFERENT_LIST: 'DIFFERENT-LIST',
  DATA_TYPE_SUPER_ADMIN_LIST: 'SUPER-ADMIN-LIST',
  AIRPORT_CODE: 'AIRPORT-CODE',
  COUNTRY_CODE: 'COUNTRY',
  STATE_CODE: 'STATE',
  CITY_CODE: 'CITY',
  LOCATION_CODE: 'LOCATION',
  CURRENCY: 'CURRENCY'
};

export default travelFieldConstants;
