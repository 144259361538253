import {http} from '../_helpers';
import { config } from '../env';
import travelFieldConstants from 'src/_components/common/travelFieldConstants';

const getConditionsByTemplateCategory = (companyId, categoryName) => {
  let url = `${config.apiUrlTravel}travel/cmpy-conditions-by-category/${categoryName}?companyId=${companyId}`;
  return http.get(url)
    .then(res => res);
}

const getTravelRulesByTemplateCategory = (travelCategoryId) => {
  let url = `${config.apiUrlTravel}travel/travel-category-rule/client?travelCategoryId=${travelCategoryId}`;
  return http.get(url)
    .then(res => res);
}


const activeStatusTravelRule = (companyId, payload) => {
  let url = `${config.apiUrlTravel}travel-rule-template/active-inactive?companyId=${companyId}`;
  return http.put(url, payload)
    .then(res => res)
}

const deleteTravelRules = (payload) => {
  let url = `${config.apiUrlTravel}travel-rule-template/${payload.id}`;
  return http.delete(url, payload)
    .then(res => res)
}

const searchTravelRule = (companyId, searchInput, limit, page) => {
  let url = `${config.apiUrlTravel}travel/rule/search/${searchInput}?companyId=${companyId}&limit=${limit}&page=${page}`;
  return http.get(url)
    .then(res => res)
}

const createTravelRuleTemplate = (payload) => {
  let url = `${config.apiUrlTravel}travel-rule-template`;
  return http.post(url, payload)
    .then(res => res)
}

const updateTravelRuleTemplate = (id,payload) => {
  let url = `${config.apiUrlTravel}travel-rule-template/${id}`;
  return http.patch(url, payload)
    .then(res => res)
}

const getTemplateCategories = () => {
  let url = `${config.apiUrlTravel}travel/travel-category`;
  return http.get(url).then(res => res);
}

const getRuleTemplatesByCategories = (companyId, travelCategoryId,page,limit,search) => {
  let url = `${config.apiUrlTravel}travel-rule-template?companyId=${companyId}&travelCategoryId=${travelCategoryId}&page=${page}&limit=${limit}&search=${search}`
  return http.get(url).then(res => res)
}

const differentListSearch = (listName, searchTerm, companyId) => {
  let url = '';
  switch(listName){
    case travelFieldConstants.AIRPORT_CODE :
      url =  `${config.apiUrlSuperAdmin}airports?search=${searchTerm}`;
      break;
    case travelFieldConstants.COUNTRY_CODE :
      url =  `${config.apiUrlSuperAdmin}countries?search=${searchTerm}`;
      break;
    case travelFieldConstants.STATE_CODE :
      url =  `${config.apiUrlSuperAdmin}states?search=${searchTerm}`;
      break;
    case travelFieldConstants.CITY_CODE :
      url =  `${config.apiUrlSuperAdmin}cities?search=${searchTerm}`;
      break;
    case travelFieldConstants.LOCATION_CODE :
      url =  `${config.apiUrlSuperAdmin}stations?search=${searchTerm}`;
      break;
    case travelFieldConstants.CURRENCY:
      url = `${config.apiExpense}currency-administation?companyId=${companyId}`;
      break;
    default:
      return null;
  }
  return http.get(url).then(res => res);
}

const differentListSearchPolicy = (listName, searchTerm, companyId) => {
  let url = '';
  url =  `${config.apiUrlTravel}differentList?type=${listName}&search=${searchTerm}`;
  // url =  `${config.apiUrlTravel}differentList?type=${listName}`;
  return http.get(url).then(res => res);
}


export const travelRuleTemplateServices = {
  getTravelRulesByTemplateCategory,
  getConditionsByTemplateCategory,
  activeStatusTravelRule,
  deleteTravelRules,
  searchTravelRule,
  createTravelRuleTemplate,
  updateTravelRuleTemplate,
  getTemplateCategories,
  getRuleTemplatesByCategories,
  differentListSearch,
  differentListSearchPolicy
}