import React from "react";

const favouriteAttendeeList = props => {
  return (
    <div
      className="modal fade"
      id="addAttendeeModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addAttendeeLable"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-head">
            <h6 className="modal-subtitle">New</h6>
            <h5 className="modal-title" id="addAttendeeLable">
              Add Attendee
            </h5>
          </div>
          <div className="modal-middle">
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th className="modal-px-30 border-0">ATTENDEE NAME</th>
                    <th className="modal-px-30 border-0">ATTENDEE TITLE</th>
                    <th className="modal-px-30 border-0">COMPANY</th>
                    <th className="modal-px-30 border-0">ATTENDEE TYPE</th>
                  </tr>
                </thead>
                <tbody>
                  {props.favouriteEmployeeList.map((detail, index) => {
                    return (
                      <tr
                        className={
                          props.addFavouriteEmployee === detail.name
                            ? "active-tr"
                            : ""
                        }
                        key={index}
                        onClick={e => props.onFavoriteHandler(detail.name)}
                      >
                        <td className="modal-px-30">{detail.name}</td>
                        <td className="modal-px-30">Employee</td>
                        <td className="modal-px-30">This Company</td>
                        <td className="modal-px-30">This Employee</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-last btn-group-margin pm-30">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn">
                continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default favouriteAttendeeList;
