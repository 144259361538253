import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
const RequestPolicyPage = props => {
  let data = props;
  const {
    expensePolicyData,
    handlePageChange,
    handleItemChange,
    toggleExpensePolicySelect,
    deleteSelectedExpencePolicy,
    handleConnectedListStatus,
    onSearchHandler,
    validator
  } = props;

  let activeStyle = { background: "#377DFF" };
  let isItemSelected = expensePolicyData.selectedExpensePolicy.length;
  let removeButtonStyle = ["btn  "];
  if (isItemSelected) {
    removeButtonStyle.push("disabled");
  }
  // ---------------------- List expense Policies -------------------------
  const listExpensePolicies =
    expensePolicyData.expensePolicies &&
    expensePolicyData.expensePolicies.map(ep => {
      return (
        <tr
          key={ep.id}
          className={
            expensePolicyData.selectedExpensePolicy.some(i => i.id === ep.id)
              ? "active-tr"
              : null
          }
        >
          <td onClick={toggleExpensePolicySelect.bind(this, ep)}>
            {ep.policyName}
          </td>
          <td>
            {/* TODO show all the editable by groups */}
            {ep.editableObj.map(el => {
              return (
                <span className="mr-2"> {el.itemName} </span>
              )
            })}
          </td>
          <td>
            {ep.appliesObj.map(el => {
              return (
                <span className="mr-2"> {el.itemName} </span>
              )
            })}
          </td>
          <td>
            <div className="btn-group-margin">
              <NavLink
                to={`/administration/travel/request/edit-policy/${ep.id}`}
                className="btn btn-nothing hover-v"
              >
                <i className="material-icons fs-20">
                  edit
                            </i>
                <span className="hover-name right-top">Edit</span>
              </NavLink>
            </div>
          </td>
          <td>
            <p className="td-padding">
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={handleConnectedListStatus.bind(this, ep)}
                  checked={ep.activeStatus}
                  value={ep.activeStatus}
                />
                <span className="slider round" />
              </label>
            </p>
          </td>
        </tr>
      );
    });

  return (
    <div>
      
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">E</div>
            <div className="right">
              <h6 className="heading">Policies</h6>
              <p className="text">Use Policies tool to define the required Request policies.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-7">
                  <div className="btn-group-margin">
                    <NavLink
                      to="/administration/travel/request/policy-step1"
                      className="btn"
                    >
                      New
                    </NavLink>
                    <button
                      type="button"
                      className="btn"
                      onClick={e =>
                        deleteSelectedExpencePolicy(
                          expensePolicyData.selectedExpensePolicy
                        )
                      }
                      disabled={isItemSelected ? false : true}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>Policy Name</th>
                    <th>Editable By</th>
                    <th>Applies To</th>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>{listExpensePolicies}</tbody>
              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          {expensePolicyData.itemPerPage}
                        </button>
                        <div className="dropdown-menu">
                          <button
                            onClick={handleItemChange.bind(this, 10)}
                            className="dropdown-item"
                          >
                            10
                          </button>
                          <button
                            onClick={handleItemChange.bind(this, 20)}
                            className="dropdown-item"
                          >
                            20
                          </button>
                          <button
                            onClick={handleItemChange.bind(this, 30)}
                            className="dropdown-item"
                          >
                            30
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  <Pagination
                    activePage={expensePolicyData.activePage}
                    itemsCountPerPage={expensePolicyData.itemPerPage}
                    totalItemsCount={expensePolicyData.totalItemsCount}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RequestPolicyPage;
