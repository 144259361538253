import React, { Component } from "react";
import { Input, Select } from "src/_components/common/_forms";
import BasicProfile from "../booking/home/BasicProfile";
import SimpleReactValidator from 'simple-react-validator';
import $ from 'jquery';
import moment from 'moment';
import { personalInfoService } from 'src/_services/personalInfoService';
import { toast } from 'react-toastify';
import { alertConstants } from 'src/_constants';
import Loader from "src/_components/common/Loader";
import { checkObjectHasProperties } from "src/utils/util";

class BasicProfilePage extends Component {

  state = {
    titleOptions : [
      {
      label: "Mr",
      value: 1
      },
      {
      label: "Ms",
      value: 2
      },{
      label: 'Mrs',
      value: 3
      }
    ],
    genderOptions: [
      {
      label: "Male",
      value: 1
      },
      {
      label: "Female",
      value: 2
      },
      {
      label: "Others",
      value: 3
      }
    ],
    dialCodeOptions: [],
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    mobile: "",
    selectedTitle: "",
    selectedGender: "",
    selectedDialCode: "",
    selectedDOB: "",
    personalInfo: 1,

    loadingPersonalInfo: false,

    }
    validator = new SimpleReactValidator();

    componentDidMount() {
    let personalInfo = localStorage.getItem('personalInfo');
    if(personalInfo != null){
      // this.setState({personalInfo});
      this.getDialCodes();
      let cpiId = localStorage.getItem('cpiId');
      this.setState({cpiId});
      this.getPersonalInfo(cpiId);
      }
    }
  getDialCodes = async () => {
    let res = await personalInfoService.getDialCodes();
    if(res) {
      this.setState({
        dialCodeOptions: res.map(item => {
          return {
            label: item.countryName + ' - ' + item.dialCode,
            value: item.dialCode
          }
        })
      })
    }
  }

  getPersonalInfo = async (cpiId) => {
      this.setState({
        loadingPersonalInfo: true
      })
    let result = await personalInfoService.getPersonalInfo(cpiId);
    if(result){
        this.setState({loadingPersonalInfo: false});
        let dob = "";
        if(result.dob){

          dob = new Date(result.dob);
        }
        console.log(dob)
        this.setState({
          firstName: result.firstName,
          lastName: result.lastName,
          middleName: result.middleName,
          email: result.email,
          selectedTitle: result.title,
          selectedGender: result.gender,
          mobile: result.mobile,
          selectedDialCode: this.state.dialCodeOptions.find(item => item.value == result.dialCode),
          selectedDOB:dob

        });
    }else{
      this.setState({loadingPersonalInfo: false})
    }

  }

  handleTitleSelect = (selectedTitle) => {
  this.setState({selectedTitle});

  }
  handleGenderSelect = (selectedGender) => {
  this.setState({selectedGender});
  }

  handleDialSelect = (selectedDialCode) => {
  this.setState({selectedDialCode})
  }

  handleInputChange = (e) => {
  this.setState({[e.target.name] : e.target.value});
  }

  handleDateChange = (selectedDate) => {
    this.setState({
      selectedDOB: selectedDate
    })
  }

  handleModalCancel = () => {
  $('.close').click();
  }

  handleSubmit = async (e, type) => {
  // * Type 1 for create and 2 is for update
  let payload = {};
  if(this.validator.allValid()){
    let dob = moment(this.state.selectedDOB).format('YYYY-MM-DD');
    console.log(dob);

     let payload = {
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			middleName: this.state.middleName,
			title: this.state.selectedTitle,
			gender: this.state.selectedGender,
			dialCode: this.state.selectedDialCode.value,
			mobile: this.state.mobile,
      email: this.state.email,
      dob:dob
    }
    // console.log('--- payload ', payload);
    switch(type){
    case 0:

      try {
      let res = await personalInfoService.createPersonalInfo(payload);
      if(res){
				localStorage.setItem("personalInfo",2);
				toast.success(alertConstants.UPDATED_SUCCESSFULLY);
				this.setState({personalInfo: 2});
        $('.close').click();
      }
		}catch(err){

          }
      break;

			case 1:
				try {
					let res = await personalInfoService.updatePersonalInfo(this.state.cpiId,payload);
					if(res){
						// localStorage.setItem("personalInfo",2);
						// this.setState({personalInfo: 2})
						toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        $('.close').click();
      }

      }catch(err){

      }

    }
  }else{
    this.validator.showMessages();
    this.forceUpdate();
  }
  }

  render() {
    const path = "url(/assets/images/jenni.jpg)";
    const profImg = {
      backgroundImage: path
    };
    return (
      <div>
        {
          this.state.loadingPersonalInfo ? (
            <Loader />
          )
          : (

          <>

        <div className="p-padding profile-bg-image fadeIn animated">
          <div className="profile-card mb-3">
            <div className="details">
              <div>
                <button
                  data-target="#BasicProfileEditModal"
                  data-toggle="modal"
                  className="btn float-right"

                >
                  Edit
                </button>
                <div class="w-75">
                  <h6 className="sub-heading fs-16 mb-1 mt-2">Personal Information</h6>
                  <p className="mb-5"><b>Important Note: </b>Please make sure your first, middle and last name updated here are identical to those on the photo identificaton that you will be presenting at the airport during checkin. Due to increased airport security, you may be turned away at the gate if the name on your identification does not match the name on the ticket.</p>
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="shape-name mb-4">
                    <div className="right ml-0">
                      <p className="text op-less">
                        Title
                      </p>
                      <h6 className="heading fw-sbold">
                        {
                          this.state.selectedTitle && this.state.selectedTitle.label
                        }
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="mb-dashed"></div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="shape-name mb-4">
                    <div className="right ml-0">
                      <p className="text op-less">
                        Gender
                      </p>
                      <h6 className="heading fw-sbold">
                        {
                          this.state.selectedGender && this.state.selectedGender.label
                        }
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="mb-dashed"></div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="shape-name mb-4">
                    <div className="right ml-0">
                      <p className="text op-less">
                        First Name
                      </p>
                      <h6 className="heading fw-sbold">
                        {
                          this.state.firstName
                        }
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="mb-dashed"></div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="shape-name mb-4">
                    <div className="right ml-0">
                      <p className="text op-less">
                        Middle Name
                      </p>
                      <h6 className="heading fw-sbold">
                        {
                          this.state.middleName
                        }
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="mb-dashed"></div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="shape-name mb-4">
                    <div className="right ml-0">
                      <p className="text op-less">
                        Last Name
                      </p>
                      <h6 className="heading fw-sbold">
                        {
                          this.state.lastName
                        }
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="mb-dashed"></div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="shape-name mb-4">
                    <div className="right ml-0">
                      <p className="text op-less">
                        Email Id
                      </p>
                      <h6 className="heading fw-sbold">
                        {
                          this.state.email
                        }
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="mb-dashed"></div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="shape-name mb-4">
                    <div className="right ml-0">
                      <p className="text op-less">
                        Phone Number
                      </p>
                      <h6 className="heading fw-sbold">
                        {
                          this.state.mobile
                        }
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="mb-dashed"></div>
                </div>
                                <div className="col-lg-4 col-md-6">
                  <div className="shape-name mb-4">
                    <div className="right ml-0">
                      <p className="text op-less">
                        Dial Code
                      </p>
                      <h6 className="heading fw-sbold">
                        {
                          this.state.selectedDialCode && this.state.selectedDialCode.value
                        }
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="mb-dashed"></div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="shape-name mb-4">
                    <div className="right ml-0">
                      <p className="text op-less">
                        Date Of Birth
                      </p>
                      <h6 className="heading fw-sbold">
                        {
                          moment(this.state.selectedDOB, 'YYYY-MM-DD').format('DD MMM YYYY')
                        }
                      </h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="mb-dashed"></div>
                </div>
              </div>
            </div>
          </div>

       </div>
        <BasicProfile
          {...this.state}
          validator={this.validator}
          handleModalCancel={this.handleModalCancel}
          handleDialSelect={this.handleDialSelect}
          handleGenderSelect={this.handleGenderSelect}
          handleTitleSelect={this.handleTitleSelect}
          handleInputChange={this.handleInputChange}
          handleDateChange={this.handleDateChange}
          handleSubmit={this.handleSubmit}
          isCancellable
        />
        <button className="d-none profileBtn" data-target="#BasicProfileEditModal" data-keyboard="false" data-toggle="modal" >
        </button>
        </>
                   )         }
      </div>
    );
  }
}

export default BasicProfilePage;
