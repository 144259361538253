import { observable, action, reaction } from "mobx";

class ServiceStore { 
  @observable currentService;
  @action getServiceType(data) {  
    this.currentService = data;
  } 
}

export default new ServiceStore();
