import React, { Component } from 'react';
import AddRuleClassPage  from './add-class-rules-html';
import {travelRuleClassService} from '../../../../../../_services/travel-rule-class.service';
import {toast } from 'react-toastify';
import {alertConstants} from '../../../../../../_constants/alert.constants';
import SimpleReactValidator from 'simple-react-validator'



class AddRuleClass extends Component {

  state = {
    travelClassName: '',
    companyId: localStorage['440a28'],
    // companyId: "5c2c891c30566e1f00b24552",
    travelConfig: 'default',
    propertyConfig: 'default',
    newItem: {},
    editRoleId:''
  };
  validator = new SimpleReactValidator();
  componentDidMount(){
    window.scrollTo({top: 0, behavior: 'smooth'})
    if(this.props.match.params.id){
      this.setState({editRoleId: this.props.match.params.id});
      this.populateRuleClassField(this.props.match.params.id)
      // if edit then populated the classname field

    }

  }

  populateRuleClassField =  (ruleClassId) => {
    travelRuleClassService.getRuleClassById(this.state.companyId, ruleClassId)
    .then(res => {
      this.setState({
        travelClassName: res.result.className
      })

    }).catch(err => {
      console.log('some error occured ', err);
    })
  }

  createTravelClass = (item) => {
    if(this.state.editRoleId){
      travelRuleClassService.updateRuleClass(  this.state.companyId, this.state.editRoleId, item)
        .then(res => {
          this.props.history.push('/administration/travel/rule-class');
          toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        })


    }else {

      travelRuleClassService.createRuleClass(item)
      .then(res => {
        this.props.history.push('/administration/travel/rule-class');
        toast.success(alertConstants.CREATED_SUCCESSFULLY)

      }, (err) => {
        console.log("Some error occured ", err);


      })
    }

  }

  onSaveHandler = (e) => {
      e.preventDefault();
      let newItem = {
          className: this.state.travelClassName,
          companyId: this.state.companyId,
          travelConfig: this.state.travelConfig,
          propertyConfig: this.state.propertyConfig
      }
      if (this.validator.allValid()) {
        this.createTravelClass(newItem);
      } else {
        this.validator.showMessages();
        // rerender to show messages for the first time
        this.forceUpdate();
      }

  }

  onChangeHandler = (e) => {
      this.setState({
          [e.target.name]: e.target.value
      });

  }



  render() {
    return(
      <div>
        <AddRuleClassPage
          onChangeHandler={this.onChangeHandler}
          onSaveHandler={this.onSaveHandler}
          state={this.state}
          error={this.validator}

        />
      </div>
    )
  }
}
export default AddRuleClass;