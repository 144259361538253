import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
class IssueAdvancePage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <div>
                <div className="p-padding">
                    <ul className="expense-tabs">
                        <li>
                            <NavLink to="/cash-issued/cashadvance">
                                Cash Advance
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="/cash-issued/issue-advance">
                                Issue Advance
                            </NavLink>
                        </li>
                    </ul>
                    <div className="white-card">
                        <div className="form-holder">
                            <div className="table-container">
                                <form>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group custom">
                                                <input type="text" name="" className="form-control" placeholder="Search" />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group custom">
                                                <div className="dropdown">
                                                    <button type="button" className="btn bg-white dropdown-toggle btn-block" data-toggle="dropdown">
                                                        All Groups</button>
                                                    <div className="dropdown-menu">
                                                        <button className="dropdown-item">User</button>
                                                        <button className="dropdown-item">Admin User</button>
                                                        <button className="dropdown-item">Manager</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" className=" set--height">
                                                <i className="material-icons">search</i>
                                            </button>
                                        </div>

                                        <div className="col-md-2 offset-2">
                                            <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                                    This Week
                                                    </button>
                                                <div className="dropdown-menu">
                                                    <button className="dropdown-item">This Month</button>
                                                    <button className="dropdown-item">This Week</button>
                                                    <button className="dropdown-item">Today</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="search-custom">
                                                <input type="text" name="" className="form-control" placeholder="Search" />
                                                <span><i className="material-icons">search</i></span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Employee Name</th>
                                                <th>Email Address</th>
                                                <th>Oldest Cash Advance Date</th>
                                                <th>Cash Advance Balance</th>
                                                <th className="text-right">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><NavLink to="/cash-issued/issue-approver"><p className="td-padding">Amber Porter</p></NavLink></td>
                                                <td><p className="td-padding">amber@otherweb.com</p></td>
                                                <td><p className="td-padding">18/12/2017</p></td>
                                                <td><p className="td-padding">$500.00</p></td>
                                                <td className="text-right"><p className="td-padding"><button className="btn btn-badge sm-padding">Details</button></p></td>
                                            </tr>
                                            <tr>
                                                <td><p className="td-padding">Amber Porter</p></td>
                                                <td><p className="td-padding">amber@otherweb.com</p></td>
                                                <td><p className="td-padding">18/12/2017</p></td>
                                                <td><p className="td-padding">$500.00</p></td>
                                                <td className="text-right"><p className="td-padding"><button className="btn btn-badge sm-padding">Details</button></p></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default IssueAdvancePage