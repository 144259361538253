import React from "react";

const ExpenseTypes = props => {
  return (
    <div
      className="modal fade"
      id="itemisationExpenseAddModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="itemisationExpenseLable"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-head">
            <h6 className="modal-subtitle">New</h6>
            <h5 className="modal-title" id="itemisationExpenseLable">
              {" "}
              Expense Type
            </h5>
          </div>
          <div className="modal-middle pm-30">
            <div className="search-custom form-group">
              <input
                type="text"
                onChange={props.onSearchExpenseType}
                name=""
                className="form-control"
                placeholder="Search"
              />
              <span>
                <i className="material-icons">search</i>
              </span>
            </div>
            <div className="mt-3 mb-5">
              {Object.keys(props.expenseTypes).length > 0 &&
                Object.keys(props.expenseTypes).map((categoryName, index) => {
                  return (
                    <div key={index} className="mb-3">
                      <h6 className="sub-heading mb-2">{categoryName}</h6>
                      <ul className="modal-lists custom-ul">
                        {props.expenseTypes[categoryName].map(
                          (expense, index) => {
                            if(expense.activeStatus !== false){
                            return (
                              <li
                                className={
                                  props.selectedExenseType === expense.id
                                    ? "active"
                                    : null
                                }
                                key={index}
                                onClick={e =>
                                  props.onSelectExpenseType(
                                    expense.expenseTypeName,
                                    expense.id,
                                    expense.systemExpenseTypeId
                                  )
                                }
                              >
                                {expense.expenseTypeName}
                              </li>
                            );
                          }
                          }
                        )}
                      </ul>
                    </div>
                  );
                })}
              {Object.keys(props.expenseTypes).length === 0
                ? "No Records Found"
                : null}
            </div>
            <div className="modal-last btn-group-margin">
              <button type="button" className="btn" data-dismiss="modal">
                Cancel
              </button>
              <button
                onClick={props.onRedirectHandler}
                disabled={props.selectedExenseType !== "" ? false : true}
                data-dismiss="modal"
                type="button"
                className="btn"
              >
                continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseTypes;
