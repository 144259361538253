import React, { Component } from "react";
import { cartServices, masterService } from "src/_services";
import { Select, Input } from "src/_components/common/_forms";
import SimpleReactValidator from "simple-react-validator";
import $ from 'jquery';
import Moment from "react-moment";
// import { GenerateForm } from "./generateForm";
import { toast } from "react-toastify";
import { alertConstants } from "src/_constants";

import 'moment/locale/en-gb';
import { config } from 'src/env'

class ExpensePage extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator();
    this.cashValidator = new SimpleReactValidator();
    this.today = new Date()
    this.state = {
      companyId: localStorage["440a28"],
      selectedOptionPolicy: null,
      selectedDay: null,
      policyId: '',
      expenseTypeId: '',
      formHeaderId: '',
      formId: '',
      cashAdvanceDateOfIssue: '',
      expenseAmount: '',
      expenseCurrencyType: '',
      fieldData: [],
      overAllTotal: 0

    }
    this.loading = true;
  }
  componentDidMount() {
    if (sessionStorage['32AWE'] === undefined) {
      this.props.history.push('/booking/home');
    } else {
      this.getPolicies()
      this.getExpenseType()
      this.getCurrency()
      this.getWholeData()
      this.getDeatail()
    }
  }
  cartDetails = [];
  getDeatail = async () => {
    let tripID = sessionStorage['32AWE'];
    var resp = await cartServices.getCartUserList(tripID);
    if (resp) {
      this.cartDetails = resp;
      this.forceUpdate();
    }
  };
  listFeilds = []
  reqForm = [];
  getFormsFeilds = async () => {
    var resp = await cartServices.getFormsFeilds(this.state.companyId, 'request-cashAdvance')
    if (resp) {
      this.listFeilds = resp.result;
      this.setState({
        formHeaderId: this.listFeilds[0].formHeaderId,
        formId: this.listFeilds[0].formId
      })
      this.listFeilds.forEach(item => {
        var req = {
          fieldId: item.id,
          fieldValue: "",
          columnName: item.columnName,
          labelName: item.labelName,
          type: item.dataType,
          required: item.isMandatory,
          parentId: (item.multiSelectParentField) ? item.multiSelectParentField : '',
          isDepended: (item.childColumnName) ? true : false,
          connectedListData: (item.connectedListData) ? item.connectedListData : []
        }
        this.reqForm.push(req);
      })
      this.getFormGenerate()
      this.getWholeData()
    }
  }
  getFormGenerate = () => {
    this.setState({
      fieldData: [this.reqForm]
    })
  }
  baseCurrency = ''
  expenseDetails = []
  tempItner = []
  getWholeData = async () => {
    this.loading = true;
    var resp = await cartServices.getDataExpenseSub().catch(e => {
      this.loading = false;
      this.forceUpdate()
    })
    if (resp) {
      this.loading = false;
      this.expenseDetails = resp.result;
      this.baseCurrency = resp.baseCurrency;
      if (this.expenseDetails.travelFromDate) {
        this.maxDate = this.convertDate(this.expenseDetails.travelFromDate)
      }
      this.expenseDetails.cashAdvanceFormData[0] && this.expenseDetails.cashAdvanceFormData[0].fieldData.forEach(item => {
        var req = {
          fieldId: item.id,
          fieldValue: "",
          columnName: item.columnName,
          labelName: item.fieldDetails.labelName,
          type: item.fieldDetails.dataType,
          required: item.fieldDetails.isMandatory,
          parentId: (item.fieldDetails.multiSelectParentField) ? item.fieldDetails.multiSelectParentField : '',
          isDepended: (item.fieldDetails.childColumnName) ? true : false,
          connectedListData: (item.fieldDetails.connectedListData) ? item.fieldDetails.connectedListData : []
        }
        this.reqForm.push(req);
      })
      this.getFormGenerate()
      // this.handleDayClick(this.expenseDetails.cashAdvanceDateOfIssue)
      if (this.expenseDetails.cashAdvanceDateOfIssue) {
        var temp = new Date(this.expenseDetails.cashAdvanceDateOfIssue)
        var date = temp.getDate() + '/' + (temp.getMonth() + 1) + '/' + temp.getFullYear();
        this.state.cashAdvanceDateOfIssue = temp;
        this.state.selectedDay = date
      }
      if (this.expenseDetails.cashAdvanceFormData) {
        for (var i = 0; i < this.expenseDetails.cashAdvanceFormData.length; i++) {
          if (this.expenseDetails.cashAdvanceFormData.length > this.state.fieldData.length) {
            await this.handleAddExpense()
          }
          this.expenseDetails.cashAdvanceFormData[i].fieldData.map((item, key) => {
            if (this.state.fieldData[i][key]) {
              this.state.fieldData[i][key]['fieldId'] = item.fieldId
              this.state.fieldData[i][key]['fieldValue'] = item.fieldValue
              this.state.fieldData[i][key]['columnName'] = item.columnName
              this.state.fieldData[i][key]['isDepended'] = item.isDepended
              this.state.fieldData[i][key]['id'] = item.id
              if (item.isDepended) {
                return this.getListData(this.state.companyId, item.fieldValue, item.fieldId)
              }
            }
          })
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.forceUpdate()
      }
      this.getAllInfo()
      this.forceUpdate()
    }
  }
  overAllTotal = 0
  getAllInfo() {
    var newItem = 0
    this.expenseDetails.perDiemDetails.map((list, key) => {
      if (list) {
        newItem += list.actualCurrencyAmount
      }
    })
    this.setState({
      overAllTotal: newItem
    })
  }
  getListData = (companyId, parentId, child) => {
    masterService.getConnectedListData(this.state.companyId, parentId).then(resp => {
      if (resp) {
        this.tempItner = resp
        this.forceUpdate()
        this.state.fieldData.forEach(list => {
          list.forEach(item => {
            if (item.parentId === child) {
              item.connectedListData['listManagementData'] = this.tempItner;
              this.forceUpdate()
            }
          })
        })
      }
    })
  }
  convertDate(date) {
    var from = date.split("/")
    var f = new Date(from[2], from[1] - 1, from[0])
    return f;
  }
  listCurrency = []
  currencyName = "Currency"
  getCurrency = async () => {
    var resp = await masterService.getGroups(this.state.companyId, "CUR001");
    if (resp) {
      this.listCurrency = resp.result;
    }
  }
  listExpenseType = []
  getExpenseType = async () => {
    var resp = await cartServices.getCartExpenseType(this.state.companyId)
    if (resp) {
      this.listExpenseType = resp;
    }
    this.forceUpdate()
  }
  listPolicies = []
  getPolicies = async () => {
    var resp = await cartServices.getCartPolicy(this.state.companyId)
    if (resp) {
      this.listPolicies = resp;
    }
    this.forceUpdate()
  }
  policyName = 'Policy Type';
  expenseName = 'Expense Type'
  handleSelect = (e, name) => {
    if (name === 'policyId') {
      this.policyName = e.policyName;

      this.setState({
        [name]: e.id
      })
    } else if (name === 'expenseTypeId') {
      this.expenseName = e.expenseTypeName;

      this.setState({
        [name]: e.id
      })
    } else if (name === 'expenseCurrencyType') {
      this.currencyName = e.itemName;
      this.setState({
        [name]: e.itemCode
      })
      this.getPriceConvert(e.itemCode)
    }
  }
  currentRate = ''
  getPriceConvert = (e) => {
    // fetch(config.curencyLive+`latest?base=${e}&symbols=${this.baseCurrency}`)
    // .then(response =>{response.text().then(text => {  
    //   var data = JSON.parse(text) 
    //   if(data.rates){ 
    //     this.currentRate = data.rates[this.baseCurrency] 
    //     this.forceUpdate()
    //   }
    // })}
    // )
    // .catch(e=>{});
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  loading = false;
  loadingEx = false;
  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.loadingEx = true;
      this.forceUpdate()
      var temp = []
      if (this.expenseDetails.additionalExpense) {
        this.expenseDetails.additionalExpense.forEach(item => {
          temp.push(item)
        })
      }
      var req = {
        policyId: this.state.policyId,
        expenseTypeId: this.state.expenseTypeId,
        actualExpenseAmount: this.state.expenseAmount,
        actualExpenseType: this.state.expenseCurrencyType,
        baseExpenseAmount: this.state.expenseAmount * this.currentRate,
        baseExpenseType: this.baseCurrency
      }
      temp.push(req)
      var set = {
        additionalExpense: temp
      }
      var resp = await cartServices.addCartAddition(this.expenseDetails.id, set).catch(e => {
        this.loadingEx = false
        this.forceUpdate()
      })
      if (resp) {
        this.loadingEx = false;
        this.cancel()
        this.getWholeData()
        this.forceUpdate()
        $('.close').click();
      }
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }
  cancel = () => {
    this.setState({
      policyId: '',
      expenseTypeId: '',
      expenseAmount: '',
      expenseCurrencyType: '',
      expenseAmount: ''
    })
    this.policyName = 'Policy Type';
    this.expenseName = 'Expense Type';
    this.currencyName = "Currency";
    this.forceUpdate()
  }
  handleAddExpense = () => {
    var temp = []
    this.expenseDetails.cashAdvanceFormData[0] && this.expenseDetails.cashAdvanceFormData[0].fieldData.forEach(item => {
      var req = {
        fieldId: item.id,
        fieldValue: "",
        columnName: item.columnName,
        labelName: item.labelName,
        type: item.dataType,
        required: item.isMandatory,
        parentId: (item.multiSelectParentField) ? item.multiSelectParentField : '',
        isDepended: (item.childColumnName) ? true : false,
        connectedListData: (item.connectedListData) ? item.connectedListData : []
      }
      temp.push(req);
    })
    var toastId = null
    if (this.state.fieldData.length > 6) {
      if (!toast.isActive(toastId)) {
        toastId = toast.error('You reached the max limit');
      }
    } else {
      this.setState({
        fieldData: this.state.fieldData.concat([temp])
      });
    }
  }
  handleRemoveExpense = (idx) => {
    this.setState({
      fieldData: this.state.fieldData.filter((s, sidx) => idx !== sidx)
    });
  };
  handleNameChange = async (stateKey, fieldKey, e, all) => {
    const newShareholders = this.state.fieldData[stateKey].map((fieldData, sidx) => {
      if (fieldKey !== sidx) return fieldData;
      return { ...fieldData, fieldValue: e.target.value };
    });
    this.state.fieldData[stateKey] = newShareholders;
    this.setState(
      { ...this.state }
    )
    if (all.isDepended) {
      var resp = await masterService.getConnectedListData(this.state.companyId, e.target.value)
      if (resp) {
        this.state.fieldData[stateKey].forEach(item => {
          if (all.fieldId == item.parentId) {
            item.fieldValue = '';
            item.connectedListData.listManagementData = resp;
          }
        })
      }
    }
    this.forceUpdate()
  };
  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    this.setState({
      selectedDay: modifiers.selected ? undefined : day,
      cashAdvanceDateOfIssue: modifiers.selected ? undefined : day,
    });
    this.forceUpdate();
  };
  submitCashAdvance = async () => {
    var toastId = null
    if (this.cashValidator.allValid()) {
      this.loading = true;
      // var fieldData = []; 
      var cashAdvanceFormData = [];
      this.state.fieldData.forEach(item => {
        var simple = [];
        item.forEach(text => {
          var assume = {
            fieldId: text.fieldId,
            fieldValue: text.fieldValue,
            columnName: text.columnName,
            isDepended: text.isDepended
          }
          simple.push(assume)
        })
        // fieldData.push(simple);
        var items = {
          formHeaderId: this.state.formHeaderId,
          formId: this.state.formId,
          fieldData: simple
        }
        cashAdvanceFormData.push(items)
      })
      var req = {
        cashAdvanceDateOfIssue: this.state.cashAdvanceDateOfIssue,
        cashAdvanceFormData: cashAdvanceFormData
      }
      var resp = await cartServices.addExpenseService(req, this.expenseDetails.id).catch(e => {
        this.loading = false;
        this.forceUpdate()
      })
      if (resp) {
        this.loading = false;
        if (!toast.isActive(toastId)) {
          toastId = toast.success(alertConstants.UPDATED_SUCCESSFULLY)
        }
        this.forceUpdate()
      }
    } else {
      this.cashValidator.showMessages()
      this.forceUpdate()
    }
  }
  render() {
    return (
      <div>
        <div className="p-padding top-48">
          {this.loading &&
            <div className="text-center p-5 m-5">
              <div className="mt-5 form-holder pt-3">
                <div className="loader-css"></div>
                <h5 className="fw-sbold pb-5">Please wait ...</h5>
              </div>
            </div>
          }
          {!this.loading &&
            <div className="main-box">
              <div className="bg-light heading">
                <h6>Expense</h6>
              </div>
              <div className="p-padding">
                <div className="row">
                  <div className="col-md-9">
                    <h6 className="side-title">Per Diem</h6>
                    <div className="box-shadow">
                      <div className="table-responsive">
                        <table className="table ">
                          <thead>
                            <tr>
                              <th className="border-0">Journey</th>
                              <th className="border-0">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.expenseDetails &&
                              <tr>
                                <td className="border-0">{this.expenseDetails.noOfDaysJourney} Days</td>

                                <td className="border-0">
                                  {this.expenseDetails.perDiemDetails[0] && this.expenseDetails.perDiemDetails[0].actualCurrencyType}
                                  &nbsp; {this.state.overAllTotal}

                                </td>
                              </tr>}
                          </tbody>
                        </table>
                      </div>
                      <div id="demo" className="collapse fade-in">
                        <div className="table-responsive">
                          <table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th>Expense Type</th>
                                <th>Location</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.expenseDetails.perDiemDetails &&
                                this.expenseDetails.perDiemDetails.map((list, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{list.expenseType}</td>
                                      <td>{list.location}</td>
                                      <td>

                                        <Moment format="DD MMM YYYY">
                                          {this.convertDate(list.date)}
                                        </Moment>
                                      </td>
                                      <td>{list.actualCurrencyType} {list.actualCurrencyAmount}</td>
                                      <td>{list.baseCurrencyType} {list.baseCurrencyAmount}</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-nothing bg-light form-control collapsed text-primary btn-no-radius"
                        data-toggle="collapse"
                        data-target="#demo"
                      >
                        <span className="more">Show More</span>
                        <span className="less">Show Less</span>
                      </button>
                    </div>
                    {this.expenseDetails.additionalExpense && (this.expenseDetails.additionalExpense.length !== 0) &&
                      <h6 className="side-title mt-3">Additional Expense</h6>}
                    {this.expenseDetails.additionalExpense && (this.expenseDetails.additionalExpense.length !== 0) &&
                      <div className="box-shadow">
                        <div className="table-responsive">
                          <table className="table ">
                            <thead>
                              <tr>
                                <th className="border-0">Expense type</th>
                                <th className="border-0">Policy</th>
                                <th className="border-0">Amount</th>
                                <th className="border-0">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.expenseDetails.additionalExpense && this.expenseDetails.additionalExpense.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td className="border-0">{item.expenseDetails && item.expenseDetails.expenseTypeName}</td>
                                    <td className="border-0">{item.policyDetails && item.policyDetails.policyName}</td>
                                    <td className="border-0">{item.baseExpenseType} {item.baseExpenseAmount && item.baseExpenseAmount.toFixed(2)}</td>
                                    <td className="border-0">{item.actualExpenseType} {item.actualExpenseAmount && item.actualExpenseAmount.toFixed(2)}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    }
                    <h6 className="side-title">Cash Advance</h6>
                    <div className="box-shadow">
                      <div className="table-container mx-0">
                        <form>
                          <div className="row">
                            <div className="col-md-3">
                              <Moment format="DD MMM YYYY">
                                {this.state.cashAdvanceDateOfIssue}
                              </Moment>
                            </div>
                          </div>
                        </form>
                        <div className="table-responsive">
                          <table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th className="border-0">Required</th>
                                {this.expenseDetails.cashAdvanceFormData &&
                                  this.expenseDetails.cashAdvanceFormData[0] &&
                                  this.expenseDetails.cashAdvanceFormData[0].fieldData.map((list, item) => {
                                    return (
                                      <th key={item} className="border-0">{list.columnName}</th>
                                    )
                                  })}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.fieldData.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td>Additional</td>
                                    {item.map((list, index) => {
                                      return (
                                        <td key={index}>
                                          {(list.connectedListData) &&
                                            list.connectedListData.listManagementData ? list.connectedListData.listManagementData.map((main, ind) => {
                                              if (main.id == list.fieldValue) {
                                                return (
                                                  <span key={ind}>{main.itemName}</span>
                                                )
                                              }
                                            }) : list.fieldValue
                                          }
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="box-shadow">
                      <h6 className=" side-title border-bottom p-3">
                        Expense Details
                    </h6>
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th className="border-0">CURRENCY</th>
                              <th className="border-0">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="bg-light">
                              <td className="border-0">Per Diem</td>
                              <td className="border-0">{this.baseCurrency} {this.state.overAllTotal}</td>
                            </tr>
                            <tr className="bg-light">
                              <td className="border-0">Business Expense</td>
                              <td className="border-0">{this.baseCurrency} {this.expenseDetails.totalBusinessExpense ? this.expenseDetails.totalBusinessExpense.toFixed(2) : 0}</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="bg-primary text-white">
                              <td className="border-0">TOTAL</td>
                              <td className="border-0 text-right">{this.baseCurrency} {(parseInt(this.state.overAllTotal) + parseInt(this.expenseDetails.totalBusinessExpense)).toFixed(2)}</td>

                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div >

        <div
          className="modal fade"
          id="addExpenseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addExpenseLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="addExpenseLabel">
                  Add Expense
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom labelup">
                  <label>Expense Policy</label>
                  {this.listPolicies &&

                    <Select
                      placeholder={this.policyName}
                      keyName={"policyName"}
                      options={this.listPolicies}
                      controlFunc={e => this.handleSelect(e, "policyId")}
                      error={this.validator.message(
                        "Policy",
                        this.state.policyId,
                        "required"
                      )}
                    />
                  }
                </div>
                <div className="form-group custom labelup">
                  <label>Expense Type</label>
                  {this.listExpenseType &&

                    <Select
                      placeholder={this.expenseName}
                      keyName={"expenseTypeName"}
                      options={this.listExpenseType}
                      controlFunc={e => this.handleSelect(e, "expenseTypeId")}
                      error={this.validator.message(
                        "Expense Type",
                        this.state.expenseTypeId,
                        "required"
                      )}
                    />
                  }
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-4 px-0">
                      <div className="form-group custom labelup">
                        <label>Amount</label>
                        {this.listCurrency &&

                          <Select
                            placeholder={this.currencyName}
                            keyName={"itemName"}
                            options={this.listCurrency}
                            controlFunc={e => this.handleSelect(e, "expenseCurrencyType")}
                            error={this.validator.message(
                              "Currency Type",
                              this.state.expenseCurrencyType,
                              "required"
                            )}
                          />
                        }
                      </div>
                    </div>
                    <div className="col-md-8 pr-0">
                      <div className="form-group custom">
                        <Input

                          label="Amount"
                          type="text"
                          name="expenseAmount"
                          error={this.validator.message(
                            "expenseAmount",
                            this.state.expenseAmount,
                            "required"
                          )}
                          value={this.state.expenseAmount}
                          controlFunc={e => this.handleChange(e, "expenseAmount")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {!this.loadingEx &&
                  <div className="modal-last btn-group-margin">
                    <button type="button" onClick={e => this.handleSubmit(e)} className="btn  ">
                      Add expense
</button>
                    <button
                      type="button"
                      className="btn btn-badge close"
                      data-dismiss="modal"
                      onClick={e => this.cancel()}
                    >
                      Cancel
</button>
                  </div>
                }
                {this.loadingEx &&
                  <div className="modal-last btn-group-margin">
                    <button
                      type="button"
                      className="btn btn-badge"
                      data-dismiss="modal"
                    >
                      Please Wait...
</button>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="editCashModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editCashLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="editCashLabel">
                  Edit Cash
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom labelup">
                  <label>Mode</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-border-basic dropdown-toggle btn-block bg-light"
                      data-toggle="dropdown"
                    >
                      Cash
                    </button>
                    <div className="dropdown-menu">
                      <button className="dropdown-item">1</button>
                      <button className="dropdown-item">2</button>
                    </div>
                  </div>
                </div>
                <div className="form-group custom">
                  <input
                    type="text"
                    placeholder="&nbsp;"
                    className="form-control"
                  />
                  <label>Date</label>
                  <span className="icon-custom">
                    <i className="material-icons">calendar_today</i>
                  </span>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 px-0">
                      <div className="form-group custom labelup">
                        <label>Currency</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn btn-border-basic dropdown-toggle btn-block"
                            data-toggle="dropdown"
                          >
                            USD
                          </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">1</button>
                            <button className="dropdown-item">2</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0">
                      <div className="form-group custom">
                        <input
                          type="text"
                          placeholder="&nbsp;"
                          className="form-control"
                        />
                        <label>Amount</label>
                      </div>
                    </div>
                    <div className="col-md-1 px-0 pr-0">
                      <p className="text-center pt-2">or</p>
                    </div>
                    <div className="col-md-4 px-0 pr-0">
                      <div className="form-group custom">
                        <input
                          type="text"
                          placeholder="&nbsp;"
                          className="form-control"
                        />
                        <label>Percentage</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group custom textarea">
                  <textarea
                    data-gramm_editor="false"
                    spellCheck="false"
                    rows="2"
                    className="form-control"
                    placeholder="&nbsp;"
                  />
                  <label>Comment</label>
                </div>
                <div className="form-group">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round" />
                  </label>
                  <span>Required</span>
                </div>
                <div className="modal-last btn-group-margin">
                  <button type="button" className="btn  ">
                    Add expense
                  </button>
                  <button
                    type="button"
                    className="btn btn-badge"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="removeCashModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="removeCashLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-head">
                <h5 className="modal-title" id="removeCashLabel">
                  Remove Cash
                </h5>
              </div>
              <div className="modal-middle">
                <div className="row total-amount-info border py-3 modal-px-30">
                  <div className="col-md-4">
                    <p className="font-weight-bold">Total Amount</p>
                    <p>USD 5,000.00</p>
                  </div>
                  <div className="col-md-4">
                    <p className="font-weight-bold">Unassigned Amount</p>
                    <p>USD 2,000.00</p>
                  </div>
                  <div className="col-md-4">
                    <p className="font-weight-bold">Percentage</p>
                    <p>40%</p>
                  </div>
                </div>
                <div className="py-3 border-bottom modal-px-30">
                  <p className="mb-4 font-weight-bold lighter-p">
                    Corporate Card
                  </p>
                  <div className="row">
                    <div className="col-md-3 pr-0">
                      <div className="form-group custom labelup mb-1">
                        <label>Currency</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn btn-border-basic dropdown-toggle btn-block"
                            data-toggle="dropdown"
                          >
                            USD
                          </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">1</button>
                            <button className="dropdown-item">2</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0">
                      <div className="form-group custom labelup mb-1">
                        <input
                          type="text"
                          placeholder="2,000.00"
                          className="form-control"
                        />
                        <label>Amount</label>
                      </div>
                    </div>
                    <div className="col-md-1 px-0 pr-0">
                      <p className="text-center pt-2">or</p>
                    </div>
                    <div className="col-md-4 px-0 pr-3">
                      <div className="form-group custom labelup mb-1">
                        <input
                          type="text"
                          placeholder="40%"
                          className="form-control"
                        />
                        <label>Percentage</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-3 border-bottom modal-px-30">
                  <p className="mb-4 font-weight-bold lighter-p">
                    Corporate Card
                  </p>
                  <div className="row">
                    <div className="col-md-3 pr-0">
                      <div className="form-group custom labelup mb-1">
                        <label>Currency</label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn btn-border-basic dropdown-toggle btn-block"
                            data-toggle="dropdown"
                          >
                            USD
                          </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">1</button>
                            <button className="dropdown-item">2</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pr-0">
                      <div className="form-group custom labelup mb-1">
                        <input
                          type="text"
                          placeholder="1,000.00"
                          className="form-control"
                        />
                        <label>Amount</label>
                      </div>
                    </div>
                    <div className="col-md-1 px-0 pr-0">
                      <p className="text-center pt-2">or</p>
                    </div>
                    <div className="col-md-4 px-0 pr-3">
                      <div className="form-group custom labelup mb-1">
                        <input
                          type="text"
                          placeholder="20%"
                          className="form-control"
                        />
                        <label>Percentage</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-last btn-group-margin pm-30 ">
                <button type="button" className="btn  ">
                  Add expense
                </button>
                <button
                  type="button"
                  className="btn btn-badge"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
export default ExpensePage;
