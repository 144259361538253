import React from "react";
import Menu from "../menu";
import { NavLink } from "react-router-dom";
import Loader from "src/_components/common/Loader";
const HotelListPage = props => {
  const { allHotels, hotelContractCityId, newCityId, removeHotel, removeButton, isLoading } = props.state;
  localStorage.setItem('hotelsData', JSON.stringify(allHotels));
  return (
    <div>
      <div className="admin-tabs">
        <Menu />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">Hotels</h6>
              <p className="text">List of all the hotels with contract for the selected City.</p>
            </div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-10">
                  <div className="btn-group-margin">
                    <NavLink
                      to={`/administration/travel/hotel-info/${-1}/${hotelContractCityId}/${newCityId}`}
                      className="btn  "
                    >
                      Add
                    </NavLink>
                    <button
                      type="button"
                      className="btn  "
                      disabled={!removeButton}
                      onClick={props.removeHotelHandler}
                    >Remove
                    </button>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        City
                      </button>
                      <div className="dropdown-menu">
                        <button className="dropdown-item">City 1</button>
                        <button className="dropdown-item">City 2</button>
                        <button className="dropdown-item">City 3</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="search-custom">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Search"
                      value={props.search}
                      onChange={(e) => props.onSearchTextChangeHandler(e)}
                    />
                    <span>
                      <i className="material-icons align-middle">
                        search
                      </i>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="px-padding pt-3">
              <div className="row">
                {(isLoading) ?
                  <div className="mx-auto text-center">
                    <Loader />
                  </div>
                  :
                  allHotels && allHotels.map((hotel, index) => {
                    return (
                      <div key={index}
                        className="col-md-4 mb-4"
                        onClick={e => props.onDeleteHandler(hotel.id)}
                      >
                        <div className="hotel-info" style={removeHotel === hotel.id ? { "border": "1px solid red" } : {}}>
                          <div className="hotel-img">
                            <img src={hotel.photos[0]} alt="" />
                          </div>
                          <div className="hotel-detail pm-15" style={{ "height": "80px", overflowY: "auto" }}>
                            <h6>
                              <NavLink
                                to={`/administration/travel/hotel-info/${index}/${hotel.hotelContractCityId}/${hotel.cityId}`}
                                className="btn btn-nothing"
                              ><b>{hotel.hotelName}</b></NavLink>

                              <span className="unmuted-p"> /&nbsp;&nbsp;{hotel.contractType}</span>
                            </h6>
                            <p className="lighter-p unmuted-p mb-0">
                              {hotel.hotelDescription}
                            </p>
                          </div>
                          <div className="border-top pm-15 lighter-p" style={{ "height": "100px", overflowY: "auto" }}>
                            <span className="mr-2">
                              <i className="material-icons">location_on</i>
                            </span>
                            <span className="unmuted-p">
                              {hotel.address}
                            </span>
                          </div>
                          <div className="hotel-cost">
                            <div className="py-2 px-3 text-right bg-white">
                              <p className="text-right mb-0 font-weight-bold">
                                150 &nbsp; {hotel.currency.value}
                              </p>
                              <p className="unmuted-p mb-0 lighter-p">Per Night</p>
                            </div>
                            <p className="bg-light ml-3 px-3 room-count">{hotel.totalRooms} Rooms</p>
                          </div>
                        </div>

                      </div>

                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HotelListPage;
