import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Select from "../../common/_forms/select";
import DefaultField from "../administrator/company/forms/modify-form-fields2/components/DefaultField";
import Sel from "react-select";
import DatePicker from "react-datepicker";
import ReactSelect from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import { select } from "@storybook/addon-knobs";

const CustomReportPageHtml = props => {
  const {
    state,
    checkboxHandler,
    cancelAll,
    postData,
    selectFilterHandler,
    filterData,
    addFilterUI,
    removeFilter,
    startDateHandler,
    endDateHandler,
    reportNameSet,
    reportDescriptionSet,
    defaultValueChangeHandler,
    defaultSelectHandler,
    checkAllHandler,
    handleChange,
    handleChange1

  } = props;

  return (
    <div>
      <div className="page-header">
        <h6 className="heading">Create- Custom Report</h6>
      </div>
      <div className="page-content">
        <div className="p-padding">
          <div className="bg-white p-padding create-report">
            <div className="row">
              <div className="col-md-8">
                <div className="heading">
                  <h5 className="mb-0">Build your custom report</h5>
                  <p>Select the fields data you need in your report</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="heading">
                  <div className="form-group text-right">
                    <span className="mr-4">Select All/ Deselect All</span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="form-control"
                        // checked={this.props.value ? this.props.value : false}
                        onChange={e => checkAllHandler(e)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {(() => {
                if (state.reportType === "EXPENSE")
                  return (
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Report Header</b></h6>
                          <ul>
                            {state.reportHeaderList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "report-header",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "report-header"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Report Entry</b></h6>
                          <ul>
                            {state.reportEntryList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "report-entry",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "report-entry"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Report Itemization</b></h6>
                          <ul>
                            {state.reportItemizationList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "report-itemization",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "report-itemization"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Report Allocation</b></h6>
                          <ul>
                            {state.reportAllocationList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "report-allocation",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "report-allocation"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Report Attendee</b></h6>
                          <ul>
                            {state.reportAttendeeList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "report-attendee",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "report-attendee"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Employee Form</b></h6>
                          <ul>
                            {state.employeeFormList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "employee-form",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "employee-form"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                if (state.reportType === "TRAVEL")
                  return (
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Request Header</b></h6>
                          <ul>
                            {state.requestHeaderList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "request-header",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "request-header"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Request Allocation</b></h6>
                          <ul>
                            {state.requestAllocationList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "request-allocation",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "request-allocation"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Request Cash Advance</b></h6>
                          <ul>
                            {state.requestCashAdvanceList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "request-cashAdvance",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "request-cashAdvance"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Request Entry</b></h6>
                          <ul>
                            {state.requestEntryList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "request-entry",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "request-entry"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Employee Form</b></h6>
                          <ul>
                            {state.employeeFormList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "employee-form",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "employee-form"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                if (state.reportType === "CASH_ADVANCE")
                  return (
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Cash Advance</b></h6>
                          <ul>
                            {state.cashAdvanceList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "cash-advance",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "cash-advance"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card mb-4">
                          <h6 className="sub-heading"><b>Employee Form</b></h6>
                          <ul>
                            {state.employeeFormList.map((item, key) => {
                              return (
                                <li key={key}>
                                  <label className="checkbox-container mt-2 d-inline-block">
                                    <input
                                      type="checkbox"
                                      value={item.columnName}
                                      onClick={e =>
                                        checkboxHandler(
                                          e,
                                          "employee-form",
                                          item.dataType
                                        )
                                      }
                                      checked={state.selectedCheckBoxArray.includes(
                                        item.columnName + "employee-form"
                                      )}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="d-inline-block">
                                    {item.columnName}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
              })()}
            </div>
            <button
              className="btn mt-4"
              data-toggle="modal"
              data-target="#SaveTemplateModal"
              onClick={e => filterData(e)}
            >
              Continue
            </button>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="SaveTemplateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="SaveTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content border-0">
            <div className="modal-head">
              <h6 className="modal-subtitle">Action</h6>
              <h5
                className="modal-title text-capitalize"
                id="SaveTemplateModalLabel"
              >
                Save Template
              </h5>
              <button className="close d-none" data-dismiss="modal" />
            </div>
            <form>
              <div className="modal-middle pm-30">
                <div className="form-group custom">
                  <input
                    className="form-control"
                    value={state.reportName}
                    placeholder="&nbsp;"
                    onChange={e => reportNameSet(e)}
                  />
                  <label>Report Name <span className="text-danger">*</span></label>
                  <div className="help-block">
                    {props.validator.message(
                      "Report Name",
                      state.reportName,
                      "required"
                    )}
                  </div>
                </div>
                <div className="form-group custom">
                  <input
                    className="form-control"
                    value={state.reportDescription}
                    placeholder="&nbsp;"
                    onChange={e => reportDescriptionSet(e)}
                  />
                  <label>Report Description <span className="text-danger">*</span></label>
                  <div className="help-block">
                    {props.validator.message(
                      "Report Description",
                      state.reportDescription,
                      "required"
                    )}
                  </div>
                </div>

                {/* Added by sethu */}
                <div>
                  <ReactSelect
                    options={state.StatusArray}
                    onChange={handleChange}
                  />
                </div>
                {/* End */}
                      <br></br>
                <h6 className="mb-3">Report date Range</h6>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group custom">
                    {/* <select value="select">
                      <option value="Report Date">Reported Date</option>
                      {selectedOption === "Verified"?<option value="Verified Date">Verified Date</option>:""}
                    </select> */}
                    {state.selectedOption === "Verified"?
                      <ReactSelect
                      options={state.VerifiedArray}
                      onChange={handleChange1}
                      />:<ReactSelect
                      options={state.ReportArray}
                      onChange={handleChange1}
                      />}
                    </div>
                  </div>


                  <div className="col-md-4">
                    <div className="form-group custom">
                      <DatePicker
                        dateFormat="dd/MMMM/yyyy"
                        selected={state.startDate}
                        className="form-control"
                        onChange={e => startDateHandler(e)}
                        placeholderText="From"
                        maxDate={state.endDate}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group custom">
                      <DatePicker
                        dateFormat="dd/MMMM/yyyy"
                        className="form-control"
                        selected={state.endDate}
                        onChange={e => endDateHandler(e)}
                        placeholderText="To"
                        // maxDate={new Date()}
                        minDate={state.startDate}
                      />
                    </div>
                  </div>
                </div>
                {/* <button
                  className="btn mb-3"
                  onClick={e => addFilterUI(e)}
                  disabled={!state.remainingDataForFilter.length}
                >
                  + Add Filters
                </button>
                */}
                {state.UiFilterData.map((item, idx) => {
                  return (
                    <div className="input-group inner-dropdown" key={idx}>
                      <div className="input-group-prepend mr-2 adjust-dropdown">
                        {/* <button className="btn btn-nothing dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">First Name</button>
                                                        <div className="dropdown-menu">
                                                            <button className=" dropdown-item">Action</button>
                                                            <button className=" dropdown-item">Action</button>
                                                        </div> */}
                        {
                          <Select
                            options={state.remainingDataForFilter}
                            placeholder={
                              item.columnName ? item.columnName : "option"
                            }
                            keyName={"columnName"}
                            controlFunc={e => selectFilterHandler(e, item.id)}
                          />
                        }
                      </div>

                      <DefaultField
                        type={item.dataType}
                        defaultValueChangeHandler={e =>
                          defaultValueChangeHandler(e, item.id, item.dataType)
                        }
                        defaultSelectHandler={e =>
                          defaultSelectHandler(e, item.id, item.dataType)
                        }
                        value={item.match.eq[0]}
                      />
                      <button
                        className="btn ml-2 mb-25"
                        onClick={e => removeFilter(e, item)}
                      >
                        x
                      </button>
                    </div>
                  );
                })}
                <div className="modal-last btn-group-margin">
                  <button
                    className="btn"
                    data-dismiss="modal"
                    onClick={cancelAll}
                  >
                    Cancel
                  </button>
                  <button className="btn" onClick={e => postData(e)}>
                    Run
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomReportPageHtml;
