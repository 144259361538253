import React from "react";
import uuid from "uuid";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import moment from "moment";
import _ from "lodash";
import DynamicFieldsType from "../../../../common/dynamicFields";
import AsyncSelect from "react-select/lib/Async";
import SelectSearch from "src/_components/common/_forms/SelectSearch";
import { getValue, setValue } from "src/_components/common/lodsh";

const expenseCreateForm = props => {
  return props.policyForm.map((form, index) => {
    let formFieldName = form.fieldName;
    let userPermission = props.userPermission;
    let permissions = form.fieldRolePermissions && form.fieldRolePermissions;
    let newPermissionArray =
      permissions && permissions.map(permsList => permsList.roleId.roleKey);
    let permissionIndex =
      newPermissionArray && newPermissionArray.indexOf(userPermission);
    let accessPermission = 2;
    if (permissionIndex >= 0) {
      accessPermission = parseInt(
        permissions && permissions[permissionIndex].permissionId
      );
    } else {
      accessPermission = 2;
    }
    switch (form.dataType) {
      case DynamicFieldsType.DATA_TYPE_CONNECTED_LIST:
        let NewformFieldName = form.columnName;
        if (accessPermission > 0 && form.activeStatus) {
          if (form.fieldLevel > 1) {
            let selectedDropDown = "";
            formFieldName = _.toUpper(NewformFieldName);
            let originalField = _.startCase(_.camelCase(form.fieldName));
            return (
              <div key={index} className="form-group custom labelup">
                <label>
                  {form.labelName}
                  {form.isMandatory && <span className="text-danger">*</span>}
                </label>
                <div className="dropdown" key={uuid.v4()}>
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                    disabled={accessPermission === 1}
                  >
                    {props[formFieldName] &&
                      props[formFieldName].forEach(element => {
                        if (props[originalField] === element.id) {
                          selectedDropDown = element.itemName;
                        }
                      })}
                    {selectedDropDown ? selectedDropDown : "Select an option"}
                  </button>
                  <div className="dropdown-menu">
                    {props[formFieldName] &&
                      props[formFieldName].map(opt => {
                        return (
                          <button
                            type="button"
                            onClick={e =>
                              props.onConnectedListkHandler(
                                originalField,
                                form.multiSelectParentField,
                                opt.id,
                                form.fieldLevel
                              )
                            }
                            className="dropdown-item"
                            key={opt.id}
                          >
                            {opt.itemName}
                          </button>
                        );
                      })}
                  </div>
                  {form.isMandatory ? (
                    <div className="help-block">
                      {props.validator.message(
                        form.labelName,
                        props[originalField],
                        "required"
                      )}
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              </div>
            );
          } else {
            let selectedDropDown = "";
            return (
              <div key={index} className="form-group custom labelup">
                <label>
                  {form.labelName}
                  {form.isMandatory && <span className="text-danger">*</span>}
                </label>
                <div className="dropdown" key={uuid.v4()}>
                  {form.connectedListData &&
                    getValue(form,"connectedListData.listManagementData",[]).forEach(
                      element => {
                        if (props[formFieldName] === element.id) {
                          selectedDropDown = element.itemName;
                        }
                      }
                    )}
                  {}
                  {form.connectedListData && form.connectedListId &&

                    <SelectSearch
                      options={form.connectedListData.listManagementData}
                      keyName={"itemName"}
                      refId={form.connectedListId}
                      assignKey={index}
                      page={form.page}
                      form={form}
                      handleSearch={props.handleSearchManage}
                      count={form.connectedListDataCount}
                      handleScroll={props.handleScroll}
                      onClickHandler={props.onClickHandler}
                      loading={getValue(props.loading,[`${index}`],false)}
                      value={getValue(props.search,[`${index}`],"")}
                      placeholder={selectedDropDown ? selectedDropDown : "Select an option"}
                      disabled={
                        accessPermission === 1 ||
                        !(props.isEdit || form.columnName !== "GROUP_NAME")
                      }
                    />
                  }

                  </div>
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName],
                      "required"
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          }
        }
        break;
      case DynamicFieldsType.DATA_TYPE_TEXT:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="form-group custom">
              <input
                type="text"
                readOnly={accessPermission === 1}
                placeholder="&nbsp;"
                name={form.fieldName}
                maxLength={form.maxLength || 10000}
                onChange={e => props.onChangeHandler(e, form.fieldName)}
                value={props[form.fieldName] ? props[form.fieldName] : ""}
                className="form-control"
              />
              <label>
                {form.labelName}
                {form.isMandatory && <span className="text-danger">*</span>}
              </label>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName],
                    `required`
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }
        break;
      //old-code
      // case DynamicFieldsType.DATA_TYPE_DATE:
      //   if (accessPermission > 0 && form.activeStatus) {
      //     return (
      //       <div key={index} className="form-group custom labelup">
      //         <label>
      //           {form.labelName}
      //           {form.isMandatory && <span className="text-danger">*</span>}
      //         </label>
      //         <DayPickerInput
      //           formatDate={formatDate}
      //           inputProps={{ disabled: accessPermission === 1 }}
      //           dayPickerProps={{
      //             locale: "en-gb",
      //             localeUtils: MomentLocaleUtils,
      //             disabledDays: { after: new Date(), before: new Date() }
      //           }}
      //           selectedDays={
      //             props[form.fieldName]
      //                moment(new Date(props[form.fieldName]), "DD MMM YYYY")?
      //               : new Date()
      //           }
      //           format="DD MMM YYYY"
      //           placeholder={"DD MMM YYYY"}
      //           onDayChange={e => props.onDatePickerHandler(e, form.fieldName)}
      //         />
      //         {form.isMandatory ? (
      //           <div className="help-block">
      //             {props.validator.message(
      //               form.labelName,
      //               props[form.fieldName],
      //               "required"
      //             )}
      //           </div>
      //         ) : (
      //           ""
      //         )}
      //       </div>
      //     );
      //   }
      //   break;

      //newcode by sethu
      case DynamicFieldsType.DATA_TYPE_DATE:
        if (form.columnName === "REPORT_DATE") {
          if (accessPermission > 0 && form.activeStatus) {
            return (
              <div key={index} className="form-group custom labelup">
                <label>
                  {form.labelName}
                  {form.isMandatory && <span className="text-danger">*</span>}
                </label>
                <DayPickerInput
                  formatDate={formatDate}
                  inputProps={{ disabled: accessPermission === 1 }}
                  dayPickerProps={{
                    locale: "en-gb",
                    localeUtils: MomentLocaleUtils,
                    disabledDays: { after: new Date(), before: new Date() }
                  }}
                  selectedDays={
                    props[form.fieldName]
                      ? moment(new Date(props[form.fieldName]), "DD MMM YYYY")
                      : new Date()
                  }
                  format="DD MMM YYYY"
                  placeholder={"DD MMM YYYY"}
                  onDayChange={e => props.onDatePickerHandler(e, form.fieldName)}
                />
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName],
                      "required"
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          }
        } else {
          if (accessPermission > 0 && form.activeStatus) {
            return (
              <div key={index} className="form-group custom labelup">
                <label>
                  {form.labelName}
                  {form.isMandatory && <span className="text-danger">*</span>}
                </label>
                <DayPickerInput
                  formatDate={formatDate}
                  inputProps={{ disabled: accessPermission === 1 }}
                  dayPickerProps={{
                    locale: "en-gb",
                    localeUtils: MomentLocaleUtils,
                    // disabledDays: { after: new Date() }
                  }}
                  selectedDays={
                    props[form.fieldName]
                      ? moment(new Date(props[form.fieldName]), "DD MMM YYYY")
                      : new Date()
                  }
                  format="DD MMM YYYY"
                  placeholder={"DD MMM YYYY"}
                  onDayChange={e => props.onDatePickerHandler(e, form.fieldName)}
                />
                {form.isMandatory ? (
                  <div className="help-block">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName],
                      "required"
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          }
        }
        break;
      //new code ends here

      case DynamicFieldsType.DATA_TYPE_TEXTAREA:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="form-group custom textarea">
              <textarea
                data-gramm_editor="false"
                spellCheck="false"
                rows="3"
                name={form.fieldName}
                readOnly={accessPermission === 1}
                className="form-control"
                maxLength={form.maxLength || 10000}
                onChange={e => props.onChangeHandler(e, form.fieldName)}
                value={props[form.fieldName] ? props[form.fieldName] : ""}
                placeholder="&nbsp;"
              />
              <label>
                {form.labelName}{" "}
                {form.isMandatory && <span className="text-danger">*</span>}{" "}
              </label>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName],
                    `required`
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }
        break;
      case DynamicFieldsType.DATA_TYPE_CHECKBOX:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="col-md-12">
              <div className="form-group">
                <label className="switch">
                  <input
                    type="checkbox"
                    disabled={accessPermission === 1}
                    value={
                      props[form.fieldName] ? props[form.fieldName] : false
                    }
                    checked={
                      props[form.fieldName] ? props[form.fieldName] : false
                    }
                    onChange={e => props.onCheckboxHandler(form.fieldName)}
                  />
                  <span className="slider round" />
                </label>
                <span>
                  {form.labelName}
                  {form.isMandatory && <span className="text-danger">*</span>}
                </span>
              </div>
              <div className="help-block">{props.error[form.fieldName]}</div>
            </div>
          );
        }
        break;
      case DynamicFieldsType.DATA_TYPE_BOOLEAN:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="col-md-12">
              <div className="form-group">
                <label className="switch">
                  <input
                    disabled={accessPermission === 1}
                    type="checkbox"
                    value={
                      props[form.fieldName] ? props[form.fieldName] : false
                    }
                    checked={
                      props[form.fieldName] ? props[form.fieldName] : false
                    }
                    onChange={e => props.onCheckboxHandler(form.fieldName)}
                  />
                  <span className="slider round" />
                </label>
                <span>
                  {form.labelName}
                  {form.isMandatory && <span className="text-danger">*</span>}
                </span>
              </div>
              <div className="help-block">{props.error[form.fieldName]}</div>
            </div>
          );
        }
        break;
      case DynamicFieldsType.DATA_TYPE_INTEGER:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="form-group custom">
              <input
                type="text"
                placeholder="&nbsp;"
                name={form.fieldName}
                readOnly={accessPermission === 1}
                maxLength={form.maxLength || 10000}
                onChange={e => props.onIntegerHandler(e, form.fieldName)}
                value={props[form.fieldName] ? props[form.fieldName] : ""}
                className="form-control"
              />
              <label>
                {form.labelName}
                {form.isMandatory && <span className="text-danger">*</span>}
              </label>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName],
                    `required`
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }
        break;
      case DynamicFieldsType.DATA_TYPE_AMOUNT:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="form-group custom">
              <input
                type="text"
                placeholder="&nbsp;"
                name={form.fieldName}
                readOnly={accessPermission === 1}
                maxLength={form.maxLength || 10000}
                onChange={e => props.onIntegerHandler(e, form.fieldName)}
                value={props[form.fieldName] ? props[form.fieldName] : ""}
                className="form-control"
              />
              <label>
                {form.labelName}
                {form.isMandatory && <span className="text-danger">*</span>}
              </label>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName],
                    `required`
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }
        break;
      case DynamicFieldsType.DATA_TYPE_EMAIL:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index} className="form-group custom">
              <input
                type="text"
                placeholder="&nbsp;"
                name={form.fieldName}
                readOnly={accessPermission === 1}
                maxLength={form.maxLength || 10000}
                onChange={e => props.onChangeHandler(e, form.fieldName)}
                value={props[form.fieldName] ? props[form.fieldName] : ""}
                className="form-control"
              />
              <label>
                {form.labelName}
                {form.isMandatory && <span className="text-danger">*</span>}
              </label>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName],
                    `required|email`
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }
        break;
      case DynamicFieldsType.DATA_TYPE_SEARCH:
        if (accessPermission > 0 && form.activeStatus) {
          return (
            <div key={index}>
              <div className="form-group  labelup custom">
                <label>{form.labelName}</label>
                <AsyncSelect
                  noOptionsMessage={({ inputValue }) =>
                    inputValue ? "No results." : "Start typing to search..."
                  }
                  placeholder={"Search and select"}
                  loadOptions={props.loadOptions}
                  value={
                    props[form.fieldName]
                      ? props[form.fieldName]
                      : form.labelName
                  }
                  onChange={event =>
                    props.onLocationChangekHandler(event, form.fieldName)
                  }
                />
              </div>
              {form.isMandatory ? (
                <div className="help-block">
                  {props.validator.message(
                    form.labelName,
                    props[form.fieldName]?.value,
                    `required`
                  )}
                </div>
              ) : (
                  ""
                )}
            </div>
          );
        }
        break;
      case DynamicFieldsType.DATA_TYPE_SYSTEM_LIST:
        if (accessPermission > 0 && form.activeStatus) {
          let selectedDropDown = "";
          if (form.columnName === "PAYMENT_TYPE") {
            return (
              <div key={index} className="form-group custom labelup">
                <label>
                  {form.labelName}
                  {form.isMandatory && <span className="text-danger">*</span>}
                </label>
                <div className="dropdown" key={uuid.v4()}>
                  <button
                    disabled={
                      accessPermission === 1 ||
                      (props.pageName === "itemisation" &&
                        (form.columnName === "CURRENCY" ||
                          form.columnName === "PAYMENT_TYPE"))
                    }
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    {form.paymentDetails &&
                      form.paymentDetails.forEach(element => {
                        if (props[formFieldName] === element.id) {
                          selectedDropDown = element.description;
                        }
                      })}
                    {selectedDropDown ? selectedDropDown : "Select an option"}
                  </button>
                  <div className="dropdown-menu">
                    {form.paymentDetails &&
                      form.paymentDetails.map(opt => {
                        return (
                          <button
                            type="button"
                            onClick={e =>
                              props.onClickHandler(
                                form.fieldName,
                                form.id,
                                opt.id,
                                form.fieldLevel
                              )
                            }
                            className="dropdown-item"
                            key={opt.id}
                          >
                            {opt.description}
                          </button>
                        );
                      })}
                  </div>
                  {form.isMandatory ? (
                    <div className="help-block">
                      {props.validator.message(
                        form.labelName,
                        props[form.fieldName],
                        "required"
                      )}
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              </div>
            );
          } else {
            return (
              <div key={index} className="form-group custom labelup">
                <label>
                  {form.labelName}
                  {form.isMandatory && <span className="text-danger">*</span>}
                </label>
                <div className="dropdown" key={uuid.v4()}>
                  <button
                    disabled={
                      accessPermission === 1 ||
                      (props.pageName === "itemisation" &&
                        (form.columnName === "CURRENCY" ||
                          form.columnName === "PAYMENT_TYPE"))
                    }
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    {form.connectedListData &&
                      getValue(form,"connectedListData.listManagementData",[]).forEach(
                        element => {
                          if (props[formFieldName] === element.id) {
                            selectedDropDown = element.itemName;
                          }
                        }
                      )}
                    {selectedDropDown ? selectedDropDown : "Select an option"}
                  </button>
                  <div className="dropdown-menu">
                    {form.connectedListData &&
                      form.connectedListData.listManagementData.map(opt => {
                        return (
                          <button
                            type="button"
                            onClick={e =>
                              props.onClickHandler(
                                form.fieldName,
                                form.id,
                                opt.id,
                                form.fieldLevel
                              )
                            }
                            className="dropdown-item"
                            key={opt.id}
                          >
                            {opt.itemName}
                          </button>
                        );
                      })}
                  </div>
                  {form.isMandatory ? (
                    <div className="help-block">
                      {props.validator.message(
                        form.labelName,
                        props[form.fieldName],
                        "required"
                      )}
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              </div>
            );
          }
        }
        break;
      default:
        return null;
    }
  });
};

export default expenseCreateForm;
