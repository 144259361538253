function getName(RElement) {
  return RElement?.displayName || RElement?.name;
}

export const getReactElementDisplayName = RElement => {
  let displayName = getName(RElement);
  RElement = RElement.type;
  displayName = displayName || getName(RElement);
  RElement = RElement.render;
  displayName = displayName || getName(RElement);
  // console.log({ element: RElement, RElement, displayName });
  return displayName || "NoDisplayName";
};
