import React from "react";

const newAttendeeList = props => {
  return (
    <div
      className="modal fade"
      id="importAttPreviewModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="importAttPreviewLable"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-head">
            <h6 className="modal-subtitle">New</h6>
            <h5 className="modal-title" id="importAttPreviewLable">
              Import Attendee - Preview
            </h5>
          </div>
          <div className="modal-middle">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="modal-px-30 border-0">ATTENDEE NAME</th>
                    <th className="modal-px-30 border-0">ATTENDEE TITLE</th>
                    <th className="modal-px-30 border-0">COMPANY</th>
                    <th className="modal-px-30 border-0">ATTENDEE TYPE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-primary text-white">
                    <td className="modal-px-30">Nicolas Jordan</td>
                    <td className="modal-px-30">Employee</td>
                    <td className="modal-px-30">This Company</td>
                    <td className="modal-px-30">This Employee</td>
                  </tr>
                  <tr>
                    <td className="modal-px-30 text-primary">Rebecca Lawson</td>
                    <td className="modal-px-30">Employee</td>
                    <td className="modal-px-30">This Company</td>
                    <td className="modal-px-30">This Employee</td>
                  </tr>
                  <tr>
                    <td className="modal-px-30 text-primary">Nicolas Hayes</td>
                    <td className="modal-px-30">Employee</td>
                    <td className="modal-px-30">This Company</td>
                    <td className="modal-px-30">This Employee</td>
                  </tr>
                  <tr>
                    <td className="modal-px-30 text-primary">Roy Diaz</td>
                    <td className="modal-px-30">Employee</td>
                    <td className="modal-px-30">This Company</td>
                    <td className="modal-px-30">This Employee</td>
                  </tr>
                  <tr>
                    <td className="modal-px-30 text-primary">Aaron Barrett</td>
                    <td className="modal-px-30">Employee</td>
                    <td className="modal-px-30">This Company</td>
                    <td className="modal-px-30">This Employee</td>
                  </tr>
                  <tr>
                    <td className="modal-px-30 text-primary">
                      Christine Woodkin
                    </td>
                    <td className="modal-px-30">Employee</td>
                    <td className="modal-px-30">This Company</td>
                    <td className="modal-px-30">This Employee</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="modal-last btn-group-margin pm-30">
              <button
                type="button"
                className="btn btn-badge"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn  "
                data-toggle="modal"
                data-target="#importAttDoneModal"
                data-dismiss="modal"
              >
                continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default newAttendeeList;
