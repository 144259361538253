import React from "react";
const MultiSelectAirport = props => (
  <div>
    {props.list &&
      Object.keys(props.list).map(key => { 
        return (
          <div key={key}>
            <label className="checkbox-container small">
              <input
                type="checkbox"
                onChange={e => props.handelAirport("dummy", key)}
                checked={
                  props.preferredAirport.indexOf(key) === -1 ? false : true
                }
              />
              <span className="checkmark mt-1" />
              <span>
                {" "}
                {props.list[key]["n"]}({key}){" "}
              </span>
            </label>
          </div>
        );
      })}
  </div>
);

export default MultiSelectAirport;
