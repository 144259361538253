import React from 'react';
import Pagination from "react-js-pagination";
const UnusedTicketPage = (props) => {
    let data = props;
    return (
        <div>
            <div className="inner-header">
                <div className="shape-header">
                    <div className="left">L</div>
                    <div className="right">
                        <h6 className="heading">Unused Tickets</h6>
                        <p className="text">Lorem Ipsum is simply dummy</p>
                    </div>
                </div>
            </div>
            <div className="py-padding">
                <div className="table-container">
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="btn-group-margin">
                                    <div className="dropdown">
                                        <button type="button" className="btn dropdown-toggle"
                                            data-toggle="dropdown">
                                            Action
                                            </button>
                                        <div className="dropdown-menu">
                                            <button className="dropdown-item">Make Inactive</button>
                                            <button className="dropdown-item">Delete</button>
                                            <button className="dropdown-item">Edit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 text-right">
                                <div className="btn-group-margin">
                                    <button type="button" data-target="#UploadTicketsModal" data-toggle="modal" className="btn btn-upload">
                                    <i className="material-icons">cloud_upload</i></button>
                                    <button className="btn btn-upload"><i className="material-icons">cloud_download</i></button>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="search-custom">
                                    <input type="text" name="" className="form-control" placeholder="Search" />
                                    <span><i className="material-icons">search</i></span>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Carrier</th>
                                    <th>Ticket #</th>
                                    <th>User</th>
                                    <th>Locator</th>
                                    <th>Purchase date</th>
                                    <th>expiry date</th>
                                    <th>credit</th>
                                    <th>remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Air India</td>
                                    <td>028449994</td>
                                    <td>Joe Holmes</td>
                                    <td>NOT123</td>
                                    <td>01/04/1990</td>
                                    <td>01/04/1990</td>
                                    <td>USD 100.00</td>
                                    <td>NIL</td>
                                </tr>
                                <tr>
                                    <td>Air India</td>
                                    <td>028449994</td>
                                    <td>Joe Holmes</td>
                                    <td>NOT123</td>
                                    <td>01/04/1990</td>
                                    <td>01/04/1990</td>
                                    <td>USD 100.00</td>
                                    <td>NIL</td>
                                </tr>
                                <tr>
                                    <td>Air India</td>
                                    <td>028449994</td>
                                    <td>Joe Holmes</td>
                                    <td>NOT123</td>
                                    <td>01/04/1990</td>
                                    <td>01/04/1990</td>
                                    <td>USD 100.00</td>
                                    <td>NIL</td>
                                </tr>
                                <tr>
                                    <td>Air India</td>
                                    <td>028449994</td>
                                    <td>Joe Holmes</td>
                                    <td>NOT123</td>
                                    <td>01/04/1990</td>
                                    <td>01/04/1990</td>
                                    <td>USD 100.00</td>
                                    <td>NIL</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-footer">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="page-count">
                                    <div className="text">Items</div>
                                    <div className="count">
                                        <div className="dropdown">
                                            <button type="button" className="btn dropdown-toggle"
                                                data-toggle="dropdown">
                                                10
                                            </button>
                                            <div className="dropdown-menu">
                                                <button className="dropdown-item">10</button>
                                                <button className="dropdown-item">20</button>
                                                <button className="dropdown-item">30</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <Pagination
                                    activePage={data.active}
                                    itemsCountPerPage={10}
                                    totalItemsCount={10}
                                    pageRangeDisplayed={3}
                                    onChange={data.handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="UploadTicketsModal" tabIndex="-1" role="dialog"
                aria-labelledby="UploadTicketsLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="UploadTicketsLabel">Import Unused Tickets</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="upload-input mb-3">
                                <input type="file" />
                                <div className="text-center py-2">
                                    <p><i className="far fa-file"></i></p>
                                    <div>Upload Profile Picture</div>
                                </div>
                            </div>
                            <div className="file-output">
                                <span className="file-close">&times;</span><span>File Name</span>
                            </div>
                            <p className="text-center">This popup allows you to upload unused tickets from spreadsheet.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-border-success lg-padding">Save</button>
                            <button type="button" className="btn btn-border-basic lg-padding"
                                data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UnusedTicketPage;