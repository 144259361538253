import React from 'react';
import ApproverPage from './approver-html';
import { approverService } from "../../../../../_services";
import cryptr from "../../../../../_helpers/crypty";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import $ from 'jquery';
import { checkValidityBeforeSubmit } from "src/utils/util";
import { masterService } from "src/_services";

class AdditionalApprover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      level: "",
      amount: "",
      limit: 10,
      activePage: 1,
      itemPerPage: 10,
      totalItemsCount: 0,
      companyId: localStorage["440a28"],
      approverData: [],
      searchInput: "",
      selectedApprover: [],
      isClickSubmit: false,
      searchUserList: [],
      searchedUsers: [],
      selectedApproverD: {
        name: '',
        id: ''
      },
      isSearchUserListOpen: false,
      listOfCurrency: [],
      currency: ""
    }
  }

  validator = new SimpleReactValidator();
  ModifyValidator = new SimpleReactValidator();
  componentDidMount() {
    this.getApproverData();
    this.getCurrencyCode();
  }


  getCurrencyCode = async () => {
    var companyId = localStorage["440a28"];
    var resp = await masterService.getGroups(companyId, "CUR001");
    if (resp) {
      this.setState({
        listOfCurrency: resp.result
      })
    }
  };
  changeCurrency = (e) => {
    let currencyArray = []
    var ApproverCurrency = this.state.listOfCurrency.filter((data, i) => {
      if (data.itemCode == e.label) {
        return currencyArray.push({
          label: data.itemCode,
          value: data.id
        })
      }
    })
    this.setState({
      currency: currencyArray[0]
    })
  };
  // -------------------create Approvers ----------------------
  createApprover = async () => {
    if (this.validator.allValid()) {
      let data = {
        companyId: this.state.companyId,
        approverId: this.state.selectedApproverD.id,
        level: Number(this.state.level),
        amount: Number(this.state.amount),
        currency: this.state.currency
      };
      if (checkValidityBeforeSubmit(data)) {
        this.setState({ isClickSubmit: true });
        try {
          let response = await approverService.createApprover(data);
          if (response) {
            $('.close').click()
            this.getApproverData();
            toast.success(response.message);
            this.setState({
              approverData: [],
              currency: '',
              level: '',
              amount: '',
              selectedApproverD: "",
              isClickSubmit: false
            });
          } else {
            this.setState({ isClickSubmit: false });
          }
        } catch (error) {
        }
        $('.close').click();
      } else {
        toast.warn('All the fields are mandatory');
      }


    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };
  // ------------------------------------------------------------
  //------Get Approver Data ----------
  getApproverData = async () => {
    if (!this.state.searchInput) {
      try {
        const response = await approverService.getApproverList(
          this.state.companyId,
          this.state.limit,
          this.state.activePage
        );
        if (response && response.result) {
          this.setState({
            approverData: response.result,
            totalItemsCount: response.count
          });
        } else {
          this.setState({
            approverData: [],
            totalItemsCount: 0
          });
        }

      } catch (error) {
      }
    } else {
      try {
        const searchResponse = await approverService.searchException(
          this.state.searchInput,
          this.state.companyId,
          this.state.itemPerPage,
          this.state.activePage
        );
        if (searchResponse.result) {
          this.setState({
            exceptions: searchResponse.result,
            totalItemsCount: searchResponse.count
          });
        } else {
          this.setState({
            exceptions: []
          });
        }
      } catch (error) {
      }
    }
  }


  // ----------------------------Search Hanlder-------------------------
  onSearchHandler = e => {
    let searchInput = e.target.value;
    this.setState(
      {
        searchInput
      },
      async () => {
        if (!searchInput.length) {
          this.setState({ itemsCountPerPage: 10 }, () => {
            this.getApproverData();
          });
        } else {
          try {
            const searchResult = await approverService.searchApproverList(
              this.state.searchInput,
              this.state.companyId,
              this.state.itemPerPage,
              this.state.activePage
            );
            if (searchResult.result) {
              this.setState({
                approverData: searchResult.result,
                totalItemsCount: searchResult.count
              });
            } else {
              this.setState({
                exceptions: []
              });
            }
          } catch (error) {
          }
        }
      }
    );
  };

  // -------------------------------------------------------------------
  // -------------------Delete Approver ----------------------
  deleteApprover = async approver => {
    let id = approver[0].id;
    let data = {
      id: id
    };
    try {
      let response = await approverService.deleteApprover(this.state.companyId, data);
      if (response) {
        this.setState({ selectedApprover: [] })
        toast.success(response.message);
        this.getApproverData();
      }
    } catch (error) {
    }
  };

  // ------------------------------------------------------------
  // ---------------------modify Approver---------------------------
  modifyApprover = async () => {
    if (this.ModifyValidator.allValid()) {
      let data = {
        id: this.state.selectedApprover[0].id,
        companyId: this.state.companyId,
        approverId: this.state.selectedApproverD.id,
        level: Number(this.state.level),
        amount: Number(this.state.amount),
        // currency: this.state.currency,

      };
      if (checkValidityBeforeSubmit(data)) {
        this.setState({ isClickSubmit: true });
        try {
          let response = await approverService.modifyApprover(data);
          if (response) {
            toast.success(response.message);
            this.setState({
              approverData: [],
              level: null,
              amount: null,
              isClickSubmit: false
            });
            $('.close').click();
            this.getApproverData();
          } else this.setState({ isClickSubmit: false });

        } catch (err) {
          this.setState({ isClickSubmit: false });
        }
      } else {
        toast.warn('Kindly fill all the fields');
      }


    } else {
      this.ModifyValidator.showMessages();
      // rerender to show messages for the first time
      // this.forceUpdate();
      this.state(...this.state)
    }
  };
  // ------------------------------------------------------------

  // --------------Pagination--------------------------------

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, () => {
      this.getApproverData();
    });
  };
  handleItemChange = limit => {
    this.setState({ itemPerPage: limit }, () => {
      this.getApproverData();
    });
  };
  // ------------------------------------------------------------

  // -----------------on click select Policies-----------------------

  toggleApproverSelect = approver => {
    let id = approver.id;
    let itemSelected = [...this.state.selectedApprover];
    let indexSelected = itemSelected.indexOf(approver);
    if (indexSelected === -1) {
      itemSelected = [approver];
    } else {
      itemSelected = [];
    }
    this.setState({ selectedApprover: itemSelected });
  };
  // ------------------------------------------------------------

  // -----------------Form Handlers-----------------------
  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleEditableBy = editable => {
    this.setState({ editableId: editable });
  };
  // ------------------------------------------------------------

  handleModalCancel = () => {
    this.setState({
      selectedApprover: [],
      level: null,
      name: null,
      amount: null
    });
  }

  selectChangeApprover = (user) => {
    let selectedApproverD = {
      name: user.name,
      id: user.id
    }
    this.setState({
      selectedApproverD,
      isSearchUserListOpen: false
    });
  }


  handleChangeApprover = async (e) => {
    let searchTerm = e.target.value;
    this.setState({
      selectedApproverD: {
        name: e.target.value
      }
    });
    if (searchTerm != '') {
      // * if the user is typing show the matched search users.
      this.setState({
        isSearchUserListOpen: true,
        selectedApproverD: {
          name: e.target.value
        }
      });
      let approver = {
        "isApprover": true
      }
      let res = await approverService.changeApprover(this.state.companyId, searchTerm, approver);
      if (res && res.count) {
        this.convertUserListToNames(res.result);
      }
    } else {
      this.setState({ searchUserList: [], searchedUsers: [] });
    }
  }

  convertUserListToNames = (users) => {
    let searchedUsers = users.map(user => {
      let cleanUser = {
        id: user.id
      }
      if (user.fieldData.length) {
        let firstName = (user.fieldData.find(field => field.columnName == "EMPLOYEE_FIRST_NAME")).fieldValue;
        let middleName = (user.fieldData.find(field => field.columnName == "MIDDLE_NAME")).fieldValue;
        let lastName = (user.fieldData.find(field => field.columnName == "EMPLOYEE_LAST_NAME")).fieldValue;

        cleanUser.name = firstName + ' ' + middleName + ' ' + lastName;
      } else {
        cleanUser.name = user.email
      }
      return cleanUser;
    });
    this.setState({
      searchedUsers
    });
  }



  // ---------------get Approver--------------------------


  getSingleApprover = async () => {
    let approverId = this.state.selectedApprover[0].id;
    try {
      let approverSingle = await approverService.getSingleApprover(
        approverId,
        this.state.companyId
      );
      if (approverSingle) {
        this.setState({
          level: approverSingle.result.level,
          amount: approverSingle.result.amount,
        })
        this.setState(prevState => ({
          selectedApproverD: {
            ...prevState.selectedApproverD, name: approverSingle.result.name
          }

        }))
      }
    } catch (error) {
    }
  };

  // ------------------------------------------------------------

  render() {


    return (
      <div>
        <ApproverPage
          {...this.state}
          validator={this.validator}
          ModifyValidator={this.ModifyValidator}
          approversData={this.state}
          onSearchHandler={this.onSearchHandler}
          deleteApprover={this.deleteApprover}
          modifyApproverData={this.modifyApprover}
          handlePageChange={this.handlePageChange}
          handleItemChange={this.handleItemChange}
          toggleApproverSelect={this.toggleApproverSelect}
          formNameHandler={this.formNameHandler}
          onModalCancel={this.handleModalCancel}
          createApprover={this.createApprover}
          handleChangeApprover={this.handleChangeApprover}
          selectChangeApprover={this.selectChangeApprover}
          getSingleApprover={this.getSingleApprover}
          getCurrencyCode={this.getCurrencyCode}
          changeCurrency={this.changeCurrency}

        />
      </div>
    );
  }
}

export default AdditionalApprover;