import React, { Component } from "react";
import CompanyProfilePage from "./company-profile-html";
import { companyService } from "../../../../../../_services";
import { toast } from "react-toastify";
import $ from 'jquery';

class CompanyProfile extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.companyId = localStorage.getItem("440a28");
    $("#sidebar").removeClass("is--extended");
  }

  state = {
    companyDetails: ""
  };

  componentDidMount() {
    this.getProfileDetail(this.companyId);
  }
  getProfileDetail(id) {
    companyService.companyProfile(id).then(
      data => {
        if (data != null) {

          this.setState({
            companyDetails: data.result
          });
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  }
  render() {
    return (
      <div>
        <CompanyProfilePage companyDetails={this.state.companyDetails} />
      </div>
    );
  }
}
export default CompanyProfile;
