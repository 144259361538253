import React, { Component } from "react";
import ExpenseTypeStep2Page from "./expense-types-step2-html";
import { expenseServices } from "src/_services";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { alertConstants } from "src/_constants";
class ExpenseTypeStep2 extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      companyId: localStorage["440a28"],
      expenseEntries: [],
      itemizedEntries: [],
      attendeeEntries: [],
      selectedExpenseEntry: "default",
      selectedItemEntry: "default",
      selectedAttendee: "default",
      allSetPolicies: [],
      assignedStatus: "",
      combinedPOliciesToBeSet: [],
      edit: false,
      loading:true,
      expenseTypeId: this.props.match.params.id
    };
  }

  selectedPolicyId = "";
  expenseTypeId = ""; //to be received from the step 1
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
  activePage = 1;
  itemsPerPage = 10;
  payload = []; //main request data to be sent
  listPolicies = []; //for storing all the policies
  count = 0; //for storing the count of all the policies

  handleExpenseTypeChange = ev => {
    const { key, oldValue, newValue } = ev;
    // console.log("Changed");
    if (key === "extypeId") {
      this.setState({ expenseTypeId: newValue });
    }
  }; 
  componentDidMount = async () => { 
    await this.setState({
      expenseTypeId: this.props.match.params.id
    }) 
    // this.getExpenseTypePolicy();
    await this.getExpenseEntryData();
    await this.getItemizedEntryData();
    await this.getAttendeeFormData();
    await this.getPolicies();
    if (this.props.match.params.id !== undefined) { 
      this.setState({
          edit: true,
          expenseTypeId:this.props.match.params.id
        }, () => {
          this.getExpenseTypePolicy();
        }
      );
    }else{
      toast.info('Invalid Entry')
      this.props.history.push('/administration/expense/ex/types/list')
    }
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleExpenseTypeChange);
  }

  // * Get all expense type policies previously set
  getExpenseTypePolicy = async () => {
    var res = await expenseServices.getExpenseTypePolicy(
      this.state.companyId,
      this.state.expenseTypeId
    );
    if (res) { 
      this.setState({
        allSetPolicies: res.result
      }); 
    }
  };

  // * Get all Expense Entry Dropdown Data
  getExpenseEntryData = async () => {
    var res = await expenseServices.getExpenseEntryData(this.state.companyId);
    if (res) {
      await this.setState({ expenseEntries: res.result });
    }
  };

  // * Get all Item Entry Dropdown Data
  getItemizedEntryData = async () => {
    var res = await expenseServices.getItemizedEntryData(this.state.companyId);
    if (res) {
      await this.setState({
        itemizedEntries: res.result
      });
    }
  };
  // * Get all atendee Dropdown Data
  getAttendeeFormData = async () => {
    var res = await expenseServices.getAttendeeFormData(this.state.companyId);
    if (res) {
      await this.setState({
        attendeeEntries: res.result
      });
    }
  };

  // * Get all the policies
  getPolicies = async () => {
    this.setState({loading:true})
    var item = await expenseServices.getPolicies(
      this.activePage,
      this.itemsPerPage,
      this.state.companyId
    );
    if (item) { 
      this.listPolicies = item.result; 
      this.count = item.count;
    } else {
      this.listPolicies = [];
      this.count = 0;
    }
    this.setState({loading:false})  
  };

  // * handle item change pagination
  handleItemChange = limit => {
    this.activePage = 1;
    this.itemsPerPage = limit["items"];
    this.getPolicies();
  };

  handlePageChange = pageNumber => {
    this.activePage = pageNumber;
    this.getPolicies();
  };

  // createExpenseTypePolicy = async data => {
  //   let newStatus = !data.activeStatus;
  //   let payload = {
  //     id: data.id,
  //     activeStatus: newStatus
  //   };
  //   var resp = await expenseServices.createExpenseTypePolicy(data.id, payload);
  //   toast.success(resp.message);
  //   this.getPolicies();
  // };

  // * handler  when dropdown is clicked and now is also used for submit
  handleExpenseEntryHandler = selectedOption => {
    this.setState({
      selectedExpenseEntry: selectedOption.value
    }); 
  };

  handleItemEntryHandler = selectedOption => {
    this.setState({
      selectedItemEntry: selectedOption.value
    });
  };

  handleAttendeeHandler = selectedOption => {
    this.setState({
      selectedAttendee : selectedOption.value
    });
  }

  editExpenseTypePolicy = async (expenseTypePolicyId, payload) => {
    payload = Object.assign(payload, {
      companyId: this.state.companyId,
      expenseTypeId: this.state.expenseTypeId
    }); 
    let allFieldsOK = true;
    for (var key in payload) {
      if (payload[key] === "" || payload[key] === undefined) {
        // toast.warn("please fill all the fields");
        allFieldsOK = false;
      }
    } 
    if (allFieldsOK) {
      var res = await expenseServices.editExpenseTypePolicy(
        expenseTypePolicyId,
        payload
      ); 
      if(res){
        toast.success(alertConstants.UPDATED_SUCCESSFULLY); 
      } 
    } 
  };

  createExpenseTypePolicy = async payload => { 
    payload = Object.assign(payload, {
      companyId: this.state.companyId,
      expenseTypeId: this.state.expenseTypeId
    }); 
    let warn = false; 
    for (var key in payload) {
      if ((!warn && payload[key] === "") || payload[key] == null) {
        warn = true;
      }
    }
    if (warn) {
      toast.warn("please fill all the fields");  
    } else {
      var res = await expenseServices.createExpenseTypePolicy(payload);
      toast.success(alertConstants.CREATED_SUCCESSFULLY);
      // * bug fix when creating and toggling on and off
      this.getExpenseTypePolicy() 
    }
  };

  render() {
    return (
      <div>
        <ExpenseTypeStep2Page
          allState={this.state}
          listPolicies={this.listPolicies}
          expenseEntries={this.state.expenseEntries}
          itemizedEntries={this.state.itemizedEntries}
          attendeeEntries={this.state.attendeeEntries}
          pageItemsList={this.pageItemsList}
          activePage={this.activePage}
          itemsPerPage={this.itemsPerPage}
          count={this.count}
          loading={this.state.loading}
          onCreateExpenseTypePolicy={this.createExpenseTypePolicy}
          onEditExpenseTypePolicy={this.editExpenseTypePolicy}
          handleItemChange={this.handleItemChange}
          handlePageChange={this.handlePageChange}
          handleSelectChange={this.handleSelectChange}
          handleExpenseEntryHandler={this.handleExpenseEntryHandler}
          handleItemEntryHandler={this.handleItemEntryHandler}
          handleAttendeeHandler={this.handleAttendeeHandler}
          togglePolicyAssign={this.togglePolicyAssign}
          expenseTypeId={this.state.expenseTypeId}
        />
      </div>
    );
  }
}
export default withRouter(ExpenseTypeStep2);
