const customData = [
   {
      "label": "Local",
      "value": 0,
      "initAttr": {
         "vechileType": {
            "required": true,
            "visiblity": true
         },
         "tripType": {
            "required": true,
            "visiblity": true
         },
         "fromLocation": {
            "required": true,
            "visiblity": true
         },
         "toLocation": {
            "required": true,
            "visiblity": true
         },
         "pickUpDate": {
            "required": true,
            "visiblity": true
         },
         "returnDate": {
            "required": true,
            "visiblity": true,
            "contitions":""
         },
         "currency": {
            "required": true,
            "visiblity": false
         },
         "amount": {
            "required": true,
            "visiblity": false
         },
      }
   },
   {
      "label": "Out Station",
      "value": 1,
      "initAttr": {
         "vechileType": {
            "required": true,
            "visiblity": true
         },
         "tripType": {
            "required": true,
            "visiblity": false
         },
         "fromLocation": {
            "required": true,
            "visiblity": true
         },
         "toLocation": {
            "required": true,
            "visiblity": true
         },
         "pickUpDate": {
            "required": true,
            "visiblity": true
         },
         "returnDate": {
            "required": true,
            "visiblity": true
         },
         "currency": {
            "required": true,
            "visiblity": false
         },
         "amount": {
            "required": true,
            "visiblity": false
         },
      }
   },
   {
      "label": "Airport",
      "value": 2,
      "initAttr": {
         "vechileType": {
            "required": true,
            "visiblity": false
         },
         "tripType": {
            "required": true,
            "visiblity": true
         },
         "fromLocation": {
            "required": true,
            "visiblity": true
         },
         "toLocation": {
            "required": true,
            "visiblity": false
         },
         "pickUpDate": {
            "required": true,
            "visiblity": true
         },
         "returnDate": {
            "required": true,
            "visiblity": false
         },
         "currency": {
            "required": true,
            "visiblity": false
         },
         "amount": {
            "required": true,
            "visiblity": false
         },
      }
   }
];
export default customData;