import React, { Component } from "react";
import CurrencyModalList from "./components/currencyModalList";
import { toast } from "react-toastify";
import $ from "jquery";
import ReimbursementCurrencyList from "./components/currencyList";
import { expenseReimbursementServise } from "src/_services";

class Reimbursement extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      currencyList: [],
      companyCurrencyList: [],
      addCurrency: [],
      activeCurrency: "",
      removeCurrency: "",
      removeButton: false,
      companyId: localStorage["440a28"]
    };
  }

  componentDidMount() {
    this.getCompanyCurrencyList();
    this.getAllCurrencyList();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState !== this.state;
  }

  getAllCurrencyList = async () => {
    let data = {
      companyId: this.state.companyId,
      limit: 10,
      page: 1
    };
    var res = await expenseReimbursementServise.getAllCurrencyList(data);
    if (res) {
      this.setState({ currencyList: res.result });
    }
  };

  getCompanyCurrencyList = async () => {
    let data = {
      companyId: this.state.companyId,
      limit: 10,
      page: 1
    };
    var res = await expenseReimbursementServise.getCompanyCurrencyList(data);
    if (res) {
      this.setState({ companyCurrencyList: res.result });
    } else {
      this.setState({ companyCurrencyList: [] });
    }
  };

  onStatusChangeHandler = async (currencyName, activeStatus) => {
    let data = {
      activeStatus: !activeStatus
    };
    var res = await expenseReimbursementServise.onStatusChangeHandler(
      currencyName,
      data
    );
    if (res) {
      this.getCompanyCurrencyList();
      toast.success(res.message);
    }
  };

  removeCurrencyHandler = async () => {
    let deleteId = this.state.removeCurrency;
    var res = await expenseReimbursementServise.removeCurrencyHandler(deleteId);
    if (res) {
      this.getCompanyCurrencyList();
      this.setState({ removeCurrency: "", removeButton: false });
      toast.success(res.message);
    }
  };

  addCompanyCurrency = async props => {
    let currencyId = this.state.addCurrency.filter(function(str) {
      return /\S/.test(str);
    });
    let data = {
      companyId: this.state.companyId,
      currencyId: currencyId
    };
    if (currencyId.length > 0) {
      let header = { "Content-Type": "application/json" };
      var res = await expenseReimbursementServise.addCompanyCurrency(
        data,
        header
      );
      if (res) {
        this.setState({ addCurrency: [] });
        this.getCompanyCurrencyList();
        toast.success(res.message);
      }
      $(".close").click();
    }
  };

  searchCurrencyList = async (e) => {
    // let url = config.apiUrlCompany;
    this.setState({ activeCurrency: e.target.value });
    let companyId = this.state.companyId;
    let data = {
      search: e.target.value
    };
    // * Bug fix

    // * End of bug fix
    var res = await expenseReimbursementServise.searchCurrencyList( companyId, data)
    if (res) {
      this.setState({ currencyList: res.result });
    } else {
      this.setState({ currencyList: [] });
    }
  };

  onAddCurrencyHandler = currencyName => {
    let addCurrencies = this.state.addCurrency;
    let inArray = addCurrencies.indexOf(currencyName);
    if (inArray === -1) {
      addCurrencies.push(currencyName);
    } else {
      addCurrencies = addCurrencies.filter(item => item !== currencyName);
    }
    this.setState({ addCurrency: addCurrencies });
  };

  onDeleteHandler = currency => {
    let removeCurrency = this.state.removeCurrency;
    if (removeCurrency === currency) {
      this.setState({ removeCurrency: "", removeButton: false });
    } else {
      this.setState({ removeCurrency: currency, removeButton: true });
    }
  };

  render() {
    return (
      <div>
        <div className="admin-tabs">
          <div className="inner-header">
            <div className="shape-header">
              <div className="left">R</div>
              <div className="right">
                <h6 className="heading">Reimbursement Currencies</h6>
                <p className="text">Use Reimbursement Currencies to define a list of currencies used for employee reimbursement.</p>
              </div>
            </div>
          </div>
          <div className="py-padding">
            <div className="table-container">
              <form>
                <div className="row">
                  <div className="col-md-9">
                    <div className="btn-group-margin">
                      <button
                        type="button"
                        className="btn"
                        data-toggle="modal"
                        data-target="#EditFormModal"
                      >
                        Add Currency
                      </button>
                      <button
                        type="button"
                        className="btn"
                        data-toggle="modal"
                        disabled={!this.state.removeButton}
                        onClick={this.removeCurrencyHandler}
                        data-target="#AddFormModal"
                      >
                        Remove Currency
                      </button>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="search-custom">
                      <input
                        type="text"
                        name="activeCurrency"
                        onChange={this.searchActiveCurrency}
                        className="form-control"
                        placeholder="Search"
                      />
                      <span>
                        <i  className="material-icons">
                        search
                        </i>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <ReimbursementCurrencyList
                  onStatusChangeHandler={this.onStatusChangeHandler}
                  onDeleteHandler={this.onDeleteHandler}
                  companyCurrencyList={this.state.companyCurrencyList}
                  removeCurrency={this.state.removeCurrency}
                />
              </div>
            </div>
          </div>
        </div>
        <CurrencyModalList
          currencyList={this.state.currencyList}
          onAddCurrencyHandler={this.onAddCurrencyHandler}
          addCompanyCurrency={this.addCompanyCurrency}
          searchCurrencyList={this.searchCurrencyList}
          addCurrency={this.state.addCurrency}
        />
      </div>
    );
  }
}
export default Reimbursement;
