import React, { Component, useRef } from 'react';
import NewWindow from 'react-new-window';
import ReactToPrint from 'react-to-print';
import NumberFormat from 'react-number-format';
import { getValue } from './common/lodsh';
const InvoicePage = (props) => {
  const componentRef = useRef();

  const getFieldData = (columnName) => {
    if (props.invoiceInfo && props.invoiceInfo.userDetails && props.invoiceInfo.userDetails.length) {
      let matchedField = props.invoiceInfo.userDetails.find(item => item.columnName == columnName);
      if (matchedField != null) {
        switch (matchedField.dataType) {
          case "connected_list":
            return matchedField.connectedFieldValue;
          case "different_list":
            return matchedField.differentListValue;

          default:
            return matchedField.fieldValue ? matchedField.fieldValue : "-";
        }
      } else {
        return "-";
      }


    }
  }

  return (
    <NewWindow>
      <div id="root" style={{ backgroundColor: '#fff', padding: "0 15px" }}>
        <ReactToPrint
          trigger={() => <button onClick={e => NewWindow.print()}>Print this out!</button>}
          content={() => componentRef.current}
        />
        {props.invoiceInfo.length === 0 &&
          <div style={{ height: '100vh', textAlign: 'center', marginTop: '220px' }}>
            <h5>Your invoice is being processed.<br /> Please allow us some time and check again.</h5>
          </div>
        }
        {props.invoiceInfo.length !== 0 &&
          <div ref={componentRef}>
            {props.invoiceInfo && (props.invoiceInfo.bookingType === "FLIGHT") &&
              <div>
                <ul style={{ width: '100%', borderBottom: '2px solid rgba(0, 0, 0, .2)', padding: '20px 0px' }}>
                  <li style={{ listStyle: 'none', display: 'inline-block', width: '33%', textAlign: 'left' }}>
                    <img src="http://dev.curiotechnologies.in/assets/images/left-menu-logo1.png" style={{ height: '60px', width: 'auto' }} alt="logo" />
                  </li>
                  <li style={{ listStyle: 'none', display: 'inline-block', width: '33%', textAlign: 'left' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '14px' }}>Invoice</p>
                  </li>
                  <li style={{ listStyle: 'none', display: 'inline-block', width: '33%', textAlign: 'left' }}>
                    <fieldset style={{ border: '1px solid rgba(0, 0, 0, .2)', position: 'relative', width: '100%' }}>
                      <legend style={{ position: 'absolute', top: '-16px', fontSize: '14px', fontWeight: 500, left: '10px', backgroundColor: '#fff', width: 'auto', padding: '0px 5px' }}>Your invoice number</legend>
                      <h5 style={{ padding: '15px 15px 10px 15px', fontSize: '14px', fontWeight: 500 }}>
                        {props.invoiceInfo.invoiceNumber}
                      </h5>
                    </fieldset>
                  </li>
                </ul>
                <div style={{ width: '100%' }}>
                  <table style={{ width: '49.7%', display: 'inline-block', verticalAlign: 'text-top' }}>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }}>Traveller </th>
                      <td style={{ fontWeight: 500, fontSize: '14px' }}>
                      {getValue(props.invoiceInfo, "contact_detail.title", "")}{" "}
                        {getValue(props.invoiceInfo, "contact_detail.first_name", "")}{" "}
                        {getValue(props.invoiceInfo, "contact_detail.last_name", "")}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }}>Company </th>
                      <td style={{ fontWeight: 500, fontSize: '14px' }}>
                        -
                  </td>
                    </tr>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }}>Details </th>
                      <td style={{ opacity: 0.5 }}>-
                    </td>
                    </tr>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }}>GST</th>
                      <td style={{ fontWeight: 500, fontSize: '14px' }}>
                        {props.invoiceInfo.curioGstNumber}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }}>Request Number</th>
                      <td style={{ fontWeight: 500, fontSize: '14px' }}>-</td>
                    </tr>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }}>PNR</th>
                      <td style={{ fontWeight: 500, fontSize: '14px' }}>
                        {props.invoiceInfo.pnrData.pnr}
                      </td>
                    </tr>
                  </table>
                  <table style={{ width: '49.7%', display: 'inline-block', verticalAlign: 'text-top' }}>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }}>Legal Name </th>
                      <td style={{ fontWeight: 500, fontSize: '14px' }}>
                        {props.invoiceInfo.legalName}
                        {/* Govan Travels */}
                  </td>
                    </tr>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }}>Invoice Date </th>
                      <td style={{ fontWeight: 500, fontSize: '14px' }}>
                        {props.invoiceInfo.invoiceDate}

                      </td>
                    </tr>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }} >Sac code</th>
                      <td style={{ fontWeight: 500, fontSize: '14px' }}>
                        {props.invoiceInfo.sacCode}
                        {/* 9985 */}
                  </td>
                    </tr>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }}>GST Number</th>
                      <td style={{ fontWeight: 500, fontSize: '14px' }}>
                        {props.invoiceInfo.curioGstNumber}
                        {/* 07AAACD2281K2Z0 */}
                  </td>
                    </tr>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }}>GDS Ref</th>
                      <td style={{ fontWeight: 500, fontSize: '14px' }}>
                        {props.invoiceInfo.gdsRef}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ padding: '10px', textTransform: 'uppercase', margin: '0px 20px', fontSize: '14px' }}> Ref No</th>
                      <td style={{ fontWeight: 500, fontSize: '14px' }}>-</td>
                    </tr>
                  </table>
                </div>
              </div>
            }
            <div style={{ marginTop: '30px' }}>
              {/* hotel info starts here */}
              {props.invoiceInfo && (props.invoiceInfo.bookingType === "HOTEL") && props.invoiceInfo.data &&
                <div>
                  <ul style={{
                    width: '100%',
                    //   borderBottom: '2px solid rgba(0, 0, 0, .2)',
                    padding: '20px 0px'
                  }}>
                    <li style={{
                      listStyle: 'none',
                      display: 'inline-block',
                      width: '33%',
                      textAlign: 'left'
                    }}>
                      <img src="http://dev.curiotechnologies.in/assets/images/left-menu-logo1.png" style={{
                        height: '60px',
                        width: 'auto'
                      }} alt="logo" />

                    </li>
                    <li style={{
                      listStyle: 'none',
                      display: 'inline-block',
                      width: '33%',
                      textAlign: 'left'
                    }}>
                      <p style={{
                        fontWeight: 'bold',
                        fontSize: '14px'
                      }}>Invoice </p>
                    </li>
                    <li style={{
                      listStyle: 'none',
                      display: 'inline-block',
                      width: '33%',
                      textAlign: 'left'
                    }}>
                      <fieldset style={{
                        border: '1px solid rgba(0, 0, 0, .2)',
                        position: 'relative',
                        width: '100%'
                      }}>
                        <legend style={{
                          position: 'absolute',
                          top: '-16px',
                          fontSize: '14px',
                          fontWeight: 500,
                          left: '10px',
                          backgroundColor: '#fff',
                          width: 'auto',
                          padding: '0px 5px'
                        }}>Invoice  Number</legend>
                        <h5 style={{
                          padding: '15px 15px 10px 15px',
                          fontSize: '14px',
                          fontWeight: 500
                        }}>{props.invoiceInfo.pnrData.bookingReferenceNumber}</h5>
                      </fieldset>
                    </li>
                  </ul>
                  <div style={{ width: '100%', borderBottom: '1px solid rgba(0, 0, 0, .2)' }}>
                    <table style={{
                      width: '49.7%',
                      display: 'inline-block',
                      verticalAlign: 'text-top'
                    }}>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Hotel Information </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}></td>
                      </tr>
                    </table>
                    <table style={{
                      width: '49.7%',
                      display: 'inline-block',
                      verticalAlign: 'text-top'
                    }}>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Traveller Information</th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}></td>
                      </tr>
                    </table>
                  </div>
                  <div style={{ width: '100%' }}>
                    <table style={{
                      width: '49.7%',
                      display: 'inline-block',
                      verticalAlign: 'text-top'
                    }}>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Booking Ref# </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>-</td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Hotel Pincode </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>{props.invoiceInfo.pnrData.hotelPinCode}
                        </td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Hotel Phone </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>{props.invoiceInfo.pnrData.hotelPhone}
                        </td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Contact Person</th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>
                          {props.invoiceInfo.pnrData.contactPerson}</td>
                      </tr>
                    </table>
                    <table style={{
                      width: '49.7%',
                      display: 'inline-block',
                      verticalAlign: 'text-top'
                    }}>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Guest Name </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>{props.invoiceInfo.travellerName}
                        </td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>BOOKING DATE </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>{props.invoiceInfo.bookingDate}
                        </td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}> Ref #</th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>-</td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Status</th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>{props.invoiceInfo.pnrStatus}</td>
                      </tr>
                    </table>
                  </div>

                  <h6 style={{
                    fontWeight: 'bold',
                    fontSize: '14px'
                  }}>Hotel Information</h6>
                  <table style={{
                    width: '100%'
                  }}>
                    <thead>
                      <tr>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Hotel</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Check In</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px',
                          color: "green"
                        }}>DURATION</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Check out</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2" style={{
                          padding: '10px',
                          fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                        }}>
                          <b style={{ display: "inline-block", marginBottom: "4px" }}>{props.invoiceInfo.data.hotel_name}</b><br />
                          {props.invoiceInfo.data.hotel_address}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          fontSize: '14px', border: '1px solid rgb(222, 226, 230)', opacity: "0.5"
                        }}>
                          {props.invoiceInfo.data.hotel_from}
                        </td>
                        <td rowspan="2" style={{
                          "fontWeight": "bold", padding: '10px',
                          fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                        }}>
                          {props.invoiceInfo.data.trip_duration}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          fontSize: '14px', border: '1px solid rgb(222, 226, 230)', opacity: "0.5"
                        }}>
                          {props.invoiceInfo.data.hotel_to}
                        </td>
                      </tr>

                    </tbody>
                  </table>

                  {/* <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Aditional Information</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    <thead>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Breakfast</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Lunch</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Pre-Payment</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Refundability</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Room Type</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Guests</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Per Night</th>
                    </thead>
                    < tbody>
                      <tr>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Included
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Not Included
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Required
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Non-Refundable
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Twin
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          2
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          100 USD
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Tax Breakup</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    < tbody>
                      <tr>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Tax <br />
                          {props.invoiceInfo.baseCurrency} &nbsp;
                          <NumberFormat value={props.invoiceInfo.pnrData.totalTax.toFixed(2)} displayType={'text'} thousandSeparator={true} />

                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Total Fare <br />
                          {props.invoiceInfo.baseCurrency} &nbsp;
                          <NumberFormat value={props.invoiceInfo.pnrData.totalFare.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Cancellation Policy</h5>
                  <ul className="list-style" dangerouslySetInnerHTML={{ __html: props.invoiceInfo.data.blockDetails.hotel_policies }}></ul>

                </div>

                // <div>
                //   <h6 style={{
                //     fontWeight: 'bold',
                //     fontSize: '14px'
                //   }}>Contact Info</h6>
                //   <div>
                //     <p style={{ marginBottom: '4px', fontSize: '14px' }}>Name  : {props.invoiceInfo.pnrData.contactPerson} </p>
                //     <p style={{ fontSize: '14px' }}>Mobile : {props.invoiceInfo.pnrData.hotelPhone} </p>
                //   </div>
                //   <h6 style={{
                //     fontWeight: 'bold',
                //     fontSize: '14px'
                //   }}>Hotel Info</h6>
                //   <table style={{
                //     width: '100%'
                //   }}>
                //     <tbody>
                //       <tr>
                //         <th style={{
                //           textTransform: 'uppercase',
                //           fontWeight: 500,
                //           padding: '20px 10px',
                //           backgroundColor: '#f1f1f2',
                //           border: '1px solid rgb(222, 226, 230)',
                //           fontSize: '14px'
                //         }}>Hotel Name</th>
                //         <th style={{
                //           textTransform: 'uppercase',
                //           fontWeight: 500,
                //           padding: '20px 10px',
                //           backgroundColor: '#f1f1f2',
                //           border: '1px solid rgb(222, 226, 230)',
                //           fontSize: '14px'
                //         }}>Check In</th>
                //         <th style={{
                //           textTransform: 'uppercase',
                //           fontWeight: 500,
                //           padding: '20px 10px',
                //           backgroundColor: '#f1f1f2',
                //           border: '1px solid rgb(222, 226, 230)',
                //           fontSize: '14px'
                //         }}>Check out</th>
                //         <th style={{
                //           textTransform: 'uppercase',
                //           fontWeight: 500,
                //           padding: '20px 10px',
                //           backgroundColor: '#f1f1f2',
                //           border: '1px solid rgb(222, 226, 230)',
                //           fontSize: '14px',
                //           color: "green"
                //         }}>DURATION</th>
                //         <th style={{
                //           textTransform: 'uppercase',
                //           fontWeight: 500,
                //           padding: '20px 10px',
                //           backgroundColor: '#f1f1f2',
                //           border: '1px solid rgb(222, 226, 230)',
                //           fontSize: '14px'
                //         }}>Address</th>
                //       </tr>
                //       <tr>
                //         <td style={{
                //           "fontWeight": "bold", padding: '10px',
                //           fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                //         }}>{props.invoiceInfo.data.hotel_name}</td>
                //         <td style={{
                //           "fontWeight": "bold", padding: '10px',
                //           fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                //         }}>{props.invoiceInfo.data.hotel_from}</td>
                //         <td style={{
                //           "fontWeight": "bold", padding: '10px',
                //           fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                //         }}>{props.invoiceInfo.data.hotel_to}</td>
                //         <td style={{
                //           "fontWeight": "bold", padding: '10px',
                //           fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                //         }}>{props.invoiceInfo.data.trip_duration}</td>
                //         <td style={{
                //           "fontWeight": "bold", padding: '10px',
                //           fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                //         }}>{props.invoiceInfo.data.hotel_address}</td>
                //       </tr>
                //     </tbody>
                //   </table>
                //   <div style={{ marginTop: '30px' }}>
                //     <h6 style={{
                //       fontWeight: 'bold',
                //       fontSize: '14px'
                //     }}>Room Info</h6>
                //   </div>
                //   <table style={{
                //     width: '100%'
                //   }}>
                //     <tbody>
                //       <tr>
                //         <th style={{
                //           textTransform: 'uppercase',
                //           fontWeight: 500,
                //           padding: '20px 10px',
                //           backgroundColor: '#f1f1f2',
                //           border: '1px solid rgb(222, 226, 230)',
                //           fontSize: '14px'
                //         }}>Room Type</th>
                //         <th style={{
                //           textTransform: 'uppercase',
                //           fontWeight: 500,
                //           padding: '20px 10px',
                //           backgroundColor: '#f1f1f2',
                //           border: '1px solid rgb(222, 226, 230)',
                //           fontSize: '14px'
                //         }}>No of rooms</th>
                //         <th style={{
                //           textTransform: 'uppercase',
                //           fontWeight: 500,
                //           padding: '20px 10px',
                //           backgroundColor: '#f1f1f2',
                //           border: '1px solid rgb(222, 226, 230)',
                //           fontSize: '14px'
                //         }}>Persons</th>
                //       </tr>
                //       {props.invoiceInfo.data.blockDetails && props.invoiceInfo.data.blockDetails.hotel_room_info.map((list, key) => {
                //         if (list.roomId === props.invoiceInfo.data.roomId)
                //           return (
                //             <tr key={key}>
                //               <td style={{
                //                 "fontWeight": "bold", padding: '10px',
                //                 fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                //               }}>{list.hotel_room_name}</td>
                //               <td style={{
                //                 "fontWeight": "bold", padding: '10px',
                //                 fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                //               }}>1</td>
                //               <td style={{
                //                 "fontWeight": "bold", padding: '10px',
                //                 fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                //               }}>1</td>
                //             </tr>
                //           )
                //       })}
                //     </tbody>
                //   </table>
                //   <div style={{ marginTop: '30px' }}>
                //     <h6 style={{
                //       fontWeight: 'bold',
                //       fontSize: '14px'
                //     }}>Hotel policy</h6>
                //   </div>
                //   <ul className="list-style" dangerouslySetInnerHTML={{ __html: props.invoiceInfo.data.blockDetails.hotel_policies }}></ul>
                // </div>
              }
              {/* flight info starts here */}
              {props.invoiceInfo && (props.invoiceInfo.bookingType === "FLIGHT") && props.invoiceInfo.data &&
                <div>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Your Itinerary</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    <thead>
                      <tr>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Carrier</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Departing</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Arrival</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Class</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2" style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          <img src={props.invoiceInfo.data.fareContent.fare.flight_image} alt="flight-logo" style={{ height: '50px', width: 'auto', textAlign: 'center' }} />
                          <p>{props.invoiceInfo.data.fareContent.fare.flight_airline}</p>
                        </td>
                        <td style={{
                          padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {props.invoiceInfo.data.fareContent.fare.flight_depature_date_utc} &nbsp; - &nbsp;
                              {props.invoiceInfo.data.fareContent.fare.flight_depature_time}
                        </td>
                        <td style={{
                          padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {props.invoiceInfo.data.fareContent.fare.flight_arrival_date_utc} &nbsp; - &nbsp;
                              {props.invoiceInfo.data.fareContent.fare.flight_arrival_time}
                        </td>
                        <td rowspan="2" style={{
                          "fontWeight": "bold", "color": "#0000ff", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {getValue(props.invoiceInfo,"pnrData.cabintype"," - ")}
                        </td>
                      </tr>
                      <tr>
                        <td style={{
                          "fontWeight": "700", padding: '10px', border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {/* {props.invoiceInfo.flight_airport[props.invoiceInfo.data.flight_from].n}  */}
                          ({props.invoiceInfo.data.flight_from}),<br /> IN at Terminal {props.invoiceInfo.data.fareContent.fare.flight_departure_terminal} </td>
                        <td style={{
                          "fontWeight": "700", padding: '10px', border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {/* {props.invoiceInfo.flight_airport[props.invoiceInfo.data.flight_to].n} */}
                          ({props.invoiceInfo.data.flight_to}),<br /> IN at Terminal {props.invoiceInfo.data.fareContent.fare.flight_arrival_terminal} </td>
                      </tr>
                      {/* <tr>
                        <td style={{
                          "fontWeight": "700", padding: '10px', border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px', backgroundColor: "#f1f1f2"
                        }}>Total Amount
                           </td>
                        <td colspan="3" style={{
                          "fontWeight": "700", padding: '10px', border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          1860.00 INR<br />
                          <span style={{ color: "#0000ff" }} > One Thousand Eight Hundred Sixty INR Only</span></td>
                      </tr> */}
                    </tbody>
                  </table>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Traveler Details</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    <thead>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Employee ID</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Cost Center </th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Department</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Chargeable</th>
                    </thead>
                    < tbody>
                      <tr>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {getFieldData('EMP_ID')}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {getFieldData('COST_CENTER')}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {getFieldData('DEPARTMENT')}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          No
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Aditional Information</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    <thead>
                    <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 20px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Sector</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Meal</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Baggage</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Hand Baggage</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Flight Equipment</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Class</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Seat</th>
                    </thead>
                    < tbody>
                      <tr>
                      <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                              {getValue(props.invoiceInfo,"data.fareContent.content[0].flight_from_city")}{" - "}{getValue(props.invoiceInfo,"data.fareContent.content[0].flight_to_city")}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          -
                          {/* <NumberFormat value={props.invoiceInfo.data.fareContent.fare.flight_original_adult_base_fare && props.invoiceInfo.data.fareContent.fare.flight_original_adult_base_fare.toFixed(2)} displayType={'text'} thousandSeparator={true} /> */}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                           cabin_baggage : {getValue(props.invoiceInfo,"data.baggageInfo[0].cabin_baggage","-")}{" , "}
                          check_in_baggage : {getValue(props.invoiceInfo,"data.baggageInfo[0].check_in_baggae","-")}
                          {/* <NumberFormat value={props.invoiceInfo.data.fareContent.fare.flight_total_tax && props.invoiceInfo.data.fareContent.fare.flight_total_tax.toFixed(2)} displayType={'text'} thousandSeparator={true} /> */}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          -
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {props.invoiceInfo?.data?.fareContent?.content && props.invoiceInfo.data.fareContent.content.map((ele1, key) => {
                            return (key + 1 !== props.invoiceInfo.data.fareContent.content.length) ? ele1?.flight_eqiupment + "/" : ele1?.flight_eqiupment
                          })}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {getValue(props.invoiceInfo,"data.fareContent.content[0].classType"," - ")}

                          {/* <NumberFormat value={props.invoiceInfo.data.fareContent.fare.flight_total_price && props.invoiceInfo.data.fareContent.fare.flight_total_price.toFixed(2)} displayType={'text'} thousandSeparator={true} /> */}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Fare Details</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    <thead>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Info</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Air Fare</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Air Tax & Surcharge</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Total Fare</th>
                    </thead>
                    < tbody>
                      <tr>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {props.invoiceInfo.pnrData.airLineName} &nbsp;
  ({props.invoiceInfo.pnrData.travellingFrom} -
  {props.invoiceInfo.pnrData.travellingTo})
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {props.invoiceInfo.baseCurrency} &nbsp;
                          <NumberFormat value={props.invoiceInfo.data.fareContent.fare.flight_base_price && Number.parseFloat(props.invoiceInfo.data.fareContent.fare.flight_base_price).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {props.invoiceInfo.baseCurrency}&nbsp;
                          <NumberFormat value={props.invoiceInfo.data.fareContent.fare.flight_total_tax && props.invoiceInfo.data.fareContent.fare.flight_total_tax.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {props.invoiceInfo.baseCurrency}&nbsp;
                          <NumberFormat value={props.invoiceInfo.data.fareContent.fare.flight_total_price && props.invoiceInfo.data.fareContent.fare.flight_total_price.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </td>
                      </tr>
                    </tbody>
                  </table>




                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Fare Charges</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    <thead>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Info</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Cancellation Charges</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Date Change Charges</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Note</th>
                    </thead>
                    < tbody>
                      <tr>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {props.invoiceInfo.pnrData.airLineName} &nbsp;
                      ({props.invoiceInfo.pnrData.travellingFrom} -
                      {props.invoiceInfo.pnrData.travellingTo})
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}> &nbsp;
                        {!props.invoiceInfo.data.fareCharges ?"": props.invoiceInfo.baseCurrency}

                         &nbsp;
                          {getValue(props.invoiceInfo,"data.fareCharges.ADT.BD.C","-")}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}> &nbsp;
                        {!props.invoiceInfo.data.fareCharges ?"": props.invoiceInfo.baseCurrency}

                        &nbsp;
                         {getValue(props.invoiceInfo,"data.fareCharges.ADT.BD.A","-")}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {getValue(props.invoiceInfo,"data.fareCharges.note","-")}
                        </td>
                      </tr>
                    </tbody>
                  </table>




                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Tax Airline Breakup</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    < tbody>
                      {props.invoiceInfo?.updatedPrice?.taxBreakup && props.invoiceInfo?.updatedPrice?.taxBreakup.map((list, key) => {
                        return (
                          <tr key={key}>
                            <td style={{
                              "fontWeight": "bold", padding: '10px',
                              border: '1px solid rgb(222, 226, 230)',
                              fontSize: '14px', backgroundColor: "#f1f1f2"
                            }}>
                              {list.flieldName}
                            </td>
                            <td style={{
                              "fontWeight": "bold", padding: '10px',
                              border: '1px solid rgb(222, 226, 230)',
                              fontSize: '14px'
                            }}>
                              {props.invoiceInfo.baseCurrency}&nbsp;
                          <NumberFormat value={Number.parseFloat(list.fieldValue).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Totals</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    < tbody>
                      <tr>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px', backgroundColor: "#f1f1f2"
                        }}>
                          {props.invoiceInfo.updatedPrice.airLine} -
                          {props.invoiceInfo.updatedPrice.sector}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Base Fare :
                          {props.invoiceInfo.baseCurrency}&nbsp;
                          <NumberFormat value={props?.invoiceInfo?.updatedPrice?.baseFare && Number.parseFloat(props.invoiceInfo.updatedPrice.baseFare).toFixed(2)} displayType={'text'} thousandSeparator={true} />

                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Tax :
                         {props.invoiceInfo.baseCurrency}&nbsp;
                          <NumberFormat value={props.invoiceInfo?.updatedPrice?.totalTax?.toFixed(2)} displayType={'text'} thousandSeparator={true} />

                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Total :  {props.invoiceInfo.baseCurrency}&nbsp;
                          <NumberFormat value={props?.invoiceInfo?.updatedPrice?.totalFare && Number.parseFloat(props.invoiceInfo.updatedPrice.totalFare).toFixed(2)} displayType={'text'} thousandSeparator={true} />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Rules & Conditions</h5>
                  <ul style={{ paddingLeft: "10px" }}>
                    <li>- Use your airline PNR for all your communication for this  booking with the airline
                    </li>  <li>
                      - All the times indicated are the local time at the relevant airport and we recommend you check-in 2 hours prior to departure
</li>  <li>
                      - All the passengers including children and infants, must present valid photo identification at check-in
</li>  <li>
                      - Carry a printout of this e-ticket and present it to the airline counter at the time of check-in</li>
                    <li>
                      - Kindly check airline website for baggage information</li>
                  </ul>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}> Reasons </h5>
                  <p>
                    {(props.invoiceInfo?.pnrData?.reason) ?
                      props.invoiceInfo?.pnrData?.reason : "-"}
                  </p>
                </div>
              }
            </div>

            <img src="http://dev.curiotechnologies.in/assets/images/left-menu-logo1.png" alt="sign" style={{ height: '50px', width: 'auto', padding: '10px', marginTop: '30px' }} />
            <div style={{ marginTop: '10px' }}>
              <div style={{ float: 'left', padding: '10px' }}>
                <p style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '6px' }}>For Curio Technologies(P) Ltd.</p>
                {/* <p style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '6px' }}>Govan Travels Prop. Dalmia Bharat s & Ind.</p> */}

                {/* <p style={{ "opacity": "0.5" }}>MM Square, #5M - 671,1st & 2nd Floor, 5th Main,<br /> OMBR Layout,B-Channasandra,
                    Bangalore(KA)-560043</p> */}
                {/* <p style={{ "opacity": "0.5" }}>MM Square, #5M - 671,1st & 2nd Floor, 5th Main,<br /> OMBR Layout,B-Channasandra,Bangalore(KA)-560043</p> */}
                <p style={{ "opacity": "0.5" }}>{getValue(props.invoiceInfo, "contact_detail.address", "")}</p>
                {/* <p style={{ "opacity": "0.5" }}>12th Floor Hansalaya Building 15<br />Barakhama Road, New Delhi - 11001</p> */}
              </div>
            </div>

            <div style={{ float: 'right', padding: '10px' }}>
              {/* <p style={{ fontSize: '14px', "textAlign": "right", fontWeight: 'bold', marginBottom: '6px' }}>Support: +91 1001191888, 23329432</p>
              <p style={{ "opacity": "0.5" }}>mahesh@nangia.gmail.com</p> */}
              <p style={{ fontSize: '14px', "textAlign": "right", fontWeight: 'bold', marginBottom: '6px' }}>Support: +91-80-41122009</p>
                <p style={{ "opacity": "0.5" }}>info@curiotechnologies.com</p>

            </div>
            <div style={{ "clear": "both" }}></div>
          </div>
        }
      </div>

    </NewWindow>
  )
}

export default InvoicePage;
