import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import ExpenseReportPolicy from "./components/expenseReport/expenseReportPolicy";
import { expenseReport } from "../../../_services/expenseReport";
import Loader from "./../../../_components/common/Loader";
import { companyService, getConnectedList } from 'src/_services'
import { toast } from "react-toastify";
import cryptr from "../../../_helpers/crypty";
import { getValue, setValue } from "src/_components/common/lodsh";
import $ from 'jquery';

class NewExpenseReportPage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      policyId: "",
      policyTypes: [],
      companyId: localStorage["440a28"],
      selectedPolicy: "",
      policyForm: [],
      formFields: [],
      error: [],
      isLoading: false,
      validationStatus: true,
      submitButtonStatus: false,
      fieldsObject: [],
      userId: "",
      connectedListData: [],
      auditRuleValidationError: [],
      userPermission: "employee-role",
      expenseForm: [],
      //addded for connected list search
      search: [],
      loading: [],
      already: false
    };
  }
  componentWillUnmount() {
    this.state = {
      search: [],
      loading: []
    }
  }
  componentDidMount() {
    this.getPoliciesTypes();
    localStorage.removeItem("expenseEntryId");
    localStorage.removeItem("expenseType");
    localStorage.removeItem("expenseTypeName");
    localStorage.removeItem("itemSelectedExenseTypeName");
    localStorage.removeItem("extypeId");
    localStorage.removeItem("remainigItemise");
    localStorage.removeItem("currency");
    let currentUser = JSON.parse(
      cryptr.decrypt(localStorage.getItem("440a24"))
    );
    this.setState({ userId: currentUser["id"] });
  }

  componentWillUnmount() {
    sessionStorage.removeItem("tripId");
  }


  getPoliciesTypes = async () => {
    let response = await expenseReport.getPoliciesTypes(this.state.companyId);
    if (response) {
      this.setState({ policyTypes: response });
    }
  };

  onCancelHandler = () => {
    this.props.history.push("./home");
  };

  getPolicyFormHandler = async (policyType, policyId) => {
    localStorage.setItem("policyId", policyId);
    this.setState({
      policyId: policyId,
      selectedPolicy: policyType,
      isLoading: true,
      auditRuleValidationError: []
    });
    let response = await expenseReport.getExpenseTypes(
      this.state.companyId,
      policyId
    );
    if (response &&
      getValue(response, "headerForm.result", [])) {
      (getValue(response, "headerForm.result", [])).forEach(async (item, i) => {
        if (item.connectedListId) {
          if (item.dataType === "connected_list") {
            let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
            item.page = 1;
            item.connectedListDataCount = getValue(resp, "count", 0);
            item.connectedListData.listManagementData = resp.result;
            item.search = ''
          }
          this.setState({ expenseForm: response.result })
        }
      })
    }
    if (
      response &&
      response.headerForm &&
      !Array.isArray(response.headerForm)
    ) {
      let newConnectedListDate = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.headerForm.result, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== "";
      });
      dynamicFeilds.forEach(fields => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField
            ? fields.multiSelectParentField
            : "",
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.defaultValue ? fields.defaultValue : ""
        });
      });
      this.setState({
        connectedListData: newConnectedListDate,
        policyForm: response.headerForm.result,
        isLoading: false
      });
      this.createDropdownForMultiLblConnectedList(newConnectedListDate);
      this.setStateOfFormsAndFields(
        response.headerForm.result,
        newConnectedListDate
      );
    } else {
      this.setState({
        formFields: [],
        fieldsObject: [],
        policyForm: [],
        isLoading: false
      });
    }
  };
//g
  getDataFromCopyDown = async (formField, newConnectedListDate) => {
    let copyDownObject = {
      companyId: this.state.companyId,
      userId: this.state.userId,
      fieldId: [formField.id]
    };
    let response = await expenseReport.postGetcopyDownData(copyDownObject);
    if (response && response.result) {
      if (+formField.fieldLevel === 1) {
        let newFieldLevel = formField.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter(
          listData =>
            listData.multiSelectParentField === formField.id &&
            listData.fieldLevel === newFieldLevel
        );
        if (existConnected.length > 0) {
          let companyId = this.state.companyId;
          let responseMult = await expenseReport.getConnectedListData(
            companyId,
            response.result[0].fieldValue
          );
          let childName = existConnected[0].columnName;
          if (responseMult) {
            this.setState({ [childName]: responseMult });
          }
        }
      } else if (formField.fieldLevel >= 2) {
        let newFieldLevel = formField.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter(
          listData =>
            listData.multiSelectParentField ===
            formField.multiSelectParentField &&
            listData.fieldLevel === newFieldLevel
        );
        if (
          existConnected.length > 0 &&
          formField.defaultValue !== undefined &&
          formField.defaultValue !== ""
        ) {
          let companyId = this.state.companyId;
          let responseMult = await expenseReport.getConnectedListData(
            companyId,
            response.result[0].fieldValue
          );
          let childName = existConnected[0].columnName;
          if (responseMult) {
            this.setState({ [childName]: responseMult });
          }
        }
      }
      return response.result[0].fieldValue;
    } else {
      return "";
    }
  };

  //check if any field has default value and that one is parent then check for child field if he has then call api for child
  checkDefaultValueHasChild = async (currentField, newConnectedListDate) => {
    if (+currentField.fieldLevel === 1) {
      let newFieldLevel = currentField.fieldLevel + 1;
      let existConnected = newConnectedListDate.filter(
        listData =>
          listData.multiSelectParentField === currentField.id &&
          listData.fieldLevel === newFieldLevel
      );
      if (existConnected.length > 0) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(
          companyId,
          currentField.defaultValue
        );
        let childName = existConnected[0].columnName;
        if (response) {
          this.setState({ [childName]: response });
        }
      }
    } else if (currentField.fieldLevel >= 2) {
      let newFieldLevel = currentField.fieldLevel + 1;
      let existConnected = newConnectedListDate.filter(
        listData =>
          listData.multiSelectParentField ===
          currentField.multiSelectParentField &&
          listData.fieldLevel === newFieldLevel
      );
      if (
        existConnected.length > 0 &&
        currentField.defaultValue !== undefined &&
        currentField.defaultValue !== ""
      ) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(
          companyId,
          currentField.defaultValue
        );
        let childName = existConnected[0].columnName;
        if (response) {
          this.setState({ [childName]: response });
        }
      }
    }
  };
  setStateOfFormsAndFields = async (formAndFields, newConnectedListDate) => {
    let formFields = [];
    let fieldsObjects = [];
    let fieldArrayValues = [];
    fieldArrayValues = formAndFields.map(async formField => {
      formFields.push(formField.fieldName);
      fieldsObjects.push(formField);
      let fieldName = formField.fieldName;
      if (formField.dataType === "date") {
        let copyDown = formField.isCopyDown
          ? await this.getDataFromCopyDown(formField, newConnectedListDate)
          : formField.isDefaultValue
            ? moment(formField.defaultValue).format("DD MMM YYYY")
            : "";
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      } else if (formField.dataType === "checkbox") {
        let copyDown = formField.isCopyDown
          ? await this.getDataFromCopyDown(formField, newConnectedListDate)
          : formField.isDefaultValue
            ? formField.defaultValue
            : "";
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      } else {
        let copyDown;
        if (formField.isCopyDown)
          copyDown = await this.getDataFromCopyDown(
            formField,
            newConnectedListDate
          );
        else if (formField.isDefaultValue)
          copyDown = formField.isDefaultValue ? formField.defaultValue : "";
        else copyDown = "";
        formField.fieldLevel >= 1 &&
          !formField.isCopyDown &&
          !formField.isCopyDown &&
          formField.isDefaultValue &&
          this.checkDefaultValueHasChild(formField, newConnectedListDate);
        let fieldValues = { key: fieldName, value: copyDown };
        return fieldValues;
      }
    });
    let fieldValues = await Promise.all(fieldArrayValues);
    var fieldValueObj = fieldValues.reduce(
      (obj, item) => ((obj[item.key] = item.value), obj),
      {}
    );
    this.setState({
      formFields: formFields,
      fieldsObject: fieldsObjects,
      isLoading: false,
      ...fieldValueObj
    });
  };

  createDropdownForMultiLblConnectedList = newConnectedListDate => {
    newConnectedListDate &&
      newConnectedListDate.forEach(async fields => {
        if (fields.fieldLevel === 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField === fields.id &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 2) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField ===
              fields.multiSelectParentField &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }
      });
  };
  onClickHandler = async (fieldName, conId, fieldId, fieldLevel, assignKey) => {
    sessionStorage.setItem("formSubmission", "pending")

    this.state.search[assignKey] = '';
    this.setState({ search: this.state.search })

    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    sessionStorage.setItem("formSubmission", "pending")
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onChangeHandler = (event, key) => {
    sessionStorage.setItem("formSubmission", "pending")
    this.setState({ [key]: event.target.value });
  };
  onDatePickerHandler = (value, fieldName) => {
    sessionStorage.setItem("formSubmission", "pending")
    if (!isNaN(value)) {
      this.setState({
        [fieldName]: moment(new Date(value)).format("DD MMM YYYY")
      });
    }
  };

  onIntegerHandler = (event, fieldName) => {
    sessionStorage.setItem("formSubmission", "pending")
    if (!isNaN(event.target.value)) {
      this.setState({ [fieldName]: +event.target.value });
    }
  };

  onCheckboxHandler = fieldName => {
    sessionStorage.setItem("formSubmission", "pending")
    this.setState({ [fieldName]: !this.state[fieldName] });
  };
  onLocationChangekHandler = (event, fieldName) => {
    sessionStorage.setItem("formSubmission", "pending")
    this.setState({ [fieldName]: event });
  };

  loadOptions = async newValue => {
    return expenseReport.getAllLocationsName(newValue).then(data => {
      return data.result.map(list => {
        return {
          label: list.name + "," + list.countryId.name,
          value: list.id
        };
      });
    });
  };

  onSubmitHandler = async event => {
    this.setState({ submitButtonStatus: true });
    event.preventDefault();
    if (this.validator.allValid()) {
      let fieldsObjects = this.state.fieldsObject;
      let index = 0;
      for (const key of this.state.formFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          fieldsObjects[index].fieldValue = this.state[key]
            ? this.state[key].value
            : "";
          fieldsObjects[index].searchName = this.state[key]
            ? this.state[key].label
            : "";
        } else if (
          fieldsObjects[index].fieldLevel >= 2 &&
          fieldsObjects[index].dataType === "connected_list"
        ) {
          if(this.state[key]){
          let response = await expenseReport.getConnectedListDataForSpecific(
            this.state[key]
          );
          if (response && response.itemName) {
            fieldsObjects[index].fieldItemName = response.itemName;
          }
          fieldsObjects[index].fieldValue = this.state[key];
          fieldsObjects[index].connectedListData = {};
          fieldsObjects[
            index
          ].connectedListData.listManagementData = this.state[
            fieldsObjects[index].columnName
            ];
          }
        } else if (fieldsObjects[index].fieldLevel < 2 && fieldsObjects[index].dataType === "connected_list") {
          if(this.state[key]){
          let response = await expenseReport.getConnectedListDataForSpecific(
            this.state[key]
          );
          if (response && response.itemName) {
            fieldsObjects[index].fieldItemName = response.itemName;
          }
          fieldsObjects[index].fieldValue = this.state[key];

        }
      }
        else {
          fieldsObjects[index].fieldValue = this.state[key];
        }
        index++;
      }
      fieldsObjects.forEach((item) => {
        if (item.dataType === "connected_list") {
          if (item.fieldValue) {
            let data = getValue(item, "connectedListData.listManagementData", []).filter(resp => { return resp.id === item.fieldValue });
            setValue(item, "connectedListData.listManagementData", data)
          }
        }
      })


      let policyForm = [...this.state.policyForm];
      let response;
      let tripTheExpense = {};
      let tripId = sessionStorage.getItem("tripId");
      if (tripId !== "" && tripId !== undefined && tripId !== null) {
        let createExpensePolicy = {
          companyId: this.state.companyId,
          requestId: sessionStorage.getItem("tripId"),
          policyId: this.state.policyId,
          headerForm: fieldsObjects,
          formHeaderId: policyForm[0].formHeaderId
        };
        let companyId = this.state.companyId;
        response = await expenseReport.createExpensePolicy(createExpensePolicy);
        if (response) {
          let tripResponse = await expenseReport.getCreateExpenseTripForTravel(
            companyId,
            tripId
          );
          if (tripResponse) {
            sessionStorage.setItem("formSubmission", "completed")
            tripTheExpense.message = tripResponse.message;
          }
        }
      } else {
        let createExpensePolicy = {
          companyId: this.state.companyId,
          policyId: this.state.policyId,
          headerForm: fieldsObjects,
          formHeaderId: policyForm[0].formHeaderId
        };
        response = await expenseReport.createExpensePolicy(createExpensePolicy);
        tripTheExpense.message = "Created successfully";
      }
      if (
        response.result &&
        response.result.errorMessages &&
        response.result.errorMessages.errorMessages
      ) {
        this.setState({
          submitButtonStatus: false,
          auditRuleValidationError: response.result.errorMessages.errorMessages
        });
      } else {
        if (
          response.result &&
          response.result.expenseReportHeaderObj &&
          response.result.expenseReportHeaderObj.id &&
          tripTheExpense &&
          tripTheExpense.message
        ) {
          sessionStorage.setItem("formSubmission", "completed")
          toast.success(tripTheExpense.message);
          localStorage.setItem(
            "headerId",
            response.result.expenseReportHeaderObj.id
          );
          this.props.history.push("./details");
        } else {
          this.setState({ submitButtonStatus: false });
        }
      }
    } else {
      this.setState({ submitButtonStatus: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  //Newly added for connected list



  handleScroll = async (e, id, assignKey, page, count, totalLength, search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    setValue(this.state, "clientHeight", e.target.scrollHeight)
    if (bottom) {
      this.setState({
        already: true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
      })
      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {
          let mergeOld = getValue(this.state.policyForm[assignKey], "connectedListData.listManagementData", []).concat(resp.result)
          setValue(this.state.policyForm[assignKey], "connectedListData.listManagementData", mergeOld)
          setValue(this.state.policyForm[assignKey], "page", page + 1)
          this.setState({
            ...this.state
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }
    }
  }
  handleSearchManage = async (search, id, assignKey) => {

    this.state.loading[assignKey] = true;
    this.state.search[assignKey] = search;
    this.setState({ search: this.state.search }, () => {
      $(`#valueConnect${assignKey}`).focus();
    })

    let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
    if (resp) {
      setValue(this.state.policyForm[assignKey], "connectedListData.listManagementData", resp.result)
    } else {
      setValue(this.state.policyForm[assignKey], "connectedListData.listManagementData", [])
    }
    setValue(this.state.policyForm[assignKey], "search", search)

    this.state.loading[assignKey] = false;
    this.setState({
      ...this.state
    })
    $(`#valueConnect${assignKey}`).focus();
    $(`#valueConnect${assignKey}`).attr('value', search);
    $(`#connect${assignKey}`).addClass("show")

  }


  render() {

    return (
      <div>
        <div className="p-padding">
          <div className="white-card p-padding">
            <div className="mb-4">
              <h6 className="muted-p text-uppercase mb-0">New</h6>
              <h5 className="sub-heading " id="expenseReportLable">
                Create Expense Report
              </h5>
            </div>
            <div className="">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group custom labelup">
                    <label>Policy</label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle btn-block"
                        data-toggle="dropdown"
                      >
                        {this.state.selectedPolicy
                          ? this.state.selectedPolicy
                          : "Select policy"}
                      </button>

                      <div className="dropdown-menu">
                        {this.state.policyTypes.map((policy, index) => {
                          return (
                            <button
                              key={index}
                              onClick={e =>
                                this.getPolicyFormHandler(
                                  policy.policyName,
                                  policy.id
                                )
                              }
                              className="dropdown-item"
                            >
                              {policy.policyName}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {/**create expense report form dynamically */}

                  {this.state.isLoading ? (
                    <Loader />
                  ) : (
                      <ExpenseReportPolicy
                        {...this.state}
                        policyForm={this.state.policyForm}
                        validator={this.validator}
                        onChangeHandler={this.onChangeHandler}
                        onClickHandler={this.onClickHandler}
                        onDatePickerHandler={this.onDatePickerHandler}
                        onCheckboxHandler={this.onCheckboxHandler}
                        onIntegerHandler={this.onIntegerHandler}
                        onConnectedListkHandler={this.onConnectedListkHandler}
                        userPermission={this.state.userPermission}
                        onLocationChangekHandler={this.onLocationChangekHandler}
                        loadOptions={this.loadOptions}
                        handleScroll={this.handleScroll}
                        handleSearchManage={this.handleSearchManage}
                      />
                    )}
                </div>
              </div>
              {this.state.auditRuleValidationError &&
                this.state.auditRuleValidationError.map(
                  (validationError, index) => (
                    <p className="text-danger" key={index}>
                      {validationError}
                    </p>
                  )
                )}
              <div className="btn-group-margin mt-3">
                <button
                  onClick={this.onCancelHandler}
                  type="button"
                  className="btn"
                >
                  Cancel
                </button>
                {!this.state.submitButtonStatus ? (
                  <button
                    onClick={this.onSubmitHandler}
                    disabled={this.state.policyForm.length <= 0}
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                  >
                    continue
                  </button>
                ) : (
                    <button type="button" className="btn" data-dismiss="modal">
                      Please Wait...
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NewExpenseReportPage;

