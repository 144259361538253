import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class AuditRulesMenu extends Component {
  render() {
    return (
      <div>
        <div className="audit-step">
          <NavLink
            className="s1"
            to={`/administration/company/audit-rules/step1/${this.props.auditRule_id ? this.props.auditRule_id : ''}`}
          >
            <span>1</span>
            Audit Rule
          </NavLink>
          {this.props.auditRule_id !== '' && this.props.auditRule_id !== null && this.props.auditRule_id !== undefined ?
            <>
              <NavLink
                className="s1"
                to={`/administration/company/audit-rules/step2/${this.props.auditRule_id ? this.props.auditRule_id : ''}`}
              >
                <span>2</span>
                Conditions
              </NavLink>
              {this.props.gotoNextMenu ?
                <NavLink
                  className="s1"
                  to={`/administration/company/audit-rules/step3/${this.props.auditRule_id ? this.props.auditRule_id : ''}`}
                >
                  <span>3</span>
                  Exception
                </NavLink>
                :
                <NavLink className="s1" to="#">
                  <span>3</span> Exception
                </NavLink>
              }
            </>
            :
            <>
              <NavLink
                className="s1"
                to="#"
              >
                <span>2</span>
                Conditions
              </NavLink>
              <NavLink
                className="s1"
                to="#"
              >
                <span>3</span>
                Exception
              </NavLink>
            </>
          }
        </div>
      </div>
    );
  }
}
export default AuditRulesMenu;
