import React, { Component } from 'react';
import AuthorisedApproversListPage  from './authorised-approvers-list-html';
import { workflowService } from 'src/_services/workflow.service';
import SimpleReactValidator from 'simple-react-validator';
import { getDataWithActiveStatus } from 'src/utils/util';
import { userService } from 'src/_services';
import { toast } from 'react-toastify';
import { alertConstants } from 'src/_constants';
import $ from 'jquery';
class AuthorisedApproversList extends Component {

  state = {
    companyId: localStorage["440a28"],
    allRootGroups: [],
    renderedGroups: [], 
    allCurrencies: [],
    currenyType:"Choose currency",
    authorized_approver_group:'',
    approver_name:'',
    authorized_approver_type:'AA',
    authorized_approver_level:'',
    approver_can_approv_exception:false,
    currency:'',
    amount:'',
    typing:'', 
    loadingUser:false,
    userErr:false,
    formLoading:false,
    loading:false,
    userList:[],
    listAll:[],
    count:0,
    page:1,
    limit:10,
    activeItem:'',
    id:''
  }
  validator = new SimpleReactValidator();

  componentDidMount = async () =>  {
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.getGroupAuthList()
    await this.getAllCurrencies();
    await this.getAllRootGroups(); 
  }

  getAllRootGroups = async () => { 
      let res = await workflowService.getAllRootGroups(this.state.companyId); 
      if(res != undefined && res?.result?.length){
        this.setState({
          // * from server
          allRootGroups: res.result, 
          // * for UI
          renderedGroups: res.result 
        })
      } 
  }

  getAllCurrencies = async () => {  
      let res = await workflowService.getAllCurrencies(this.state.companyId);
      if(res != undefined && res?.result?.length){
        this.setState({
          allCurrencies: res.result
        });
      }  
  } 

  handleSaveSubmit = async (e) => {
    e.preventDefault() 
    if(!this.state.approver_name){
      this.setState({userErr:true})
      return
    }
    if(this.validator.allValid()){ 
      this.setState({formLoading:true})
      let req = {
        authorized_approver_group:this.state.authorized_approver_group,
        approver_name:this.state.approver_name,
        authorized_approver_type:this.state.authorized_approver_type,
        authorized_approver_level:this.state.authorized_approver_level,
        approver_can_approv_exception:this.state.approver_can_approv_exception,
        currency:this.state.currency,
        amount:this.state.amount,
        companyId:this.state.companyId
      }
      let resp = await workflowService.saveAuthApprove(req)
      this.setState({formLoading:false})
      if(resp){
        toast.success(alertConstants.CREATED_SUCCESSFULLY)
        this.reset();
        this.getGroupAuthList();
      } 
    }else{ 
      this.validator.showMessages()
      this.setState({...this.state})
    }
  }

  handleUpdateSubmit = async (e) => {
    e.preventDefault() 
    if(!this.state.approver_name){
      this.setState({userErr:true})
      return
    }
    if(this.validator.allValid()){ 
      this.setState({formLoading:true})
      let req = {
        authorized_approver_group:this.state.authorized_approver_group,
        approver_name:this.state.approver_name,
        authorized_approver_type:this.state.authorized_approver_type,
        authorized_approver_level:this.state.authorized_approver_level,
        approver_can_approv_exception:this.state.approver_can_approv_exception,
        currency:this.state.currency,
        amount:this.state.amount,
        companyId:this.state.companyId, 
      }
      let resp = await workflowService.updateAuthApprove(req,this.state.id)
      this.setState({formLoading:false})
      if(resp){
        toast.success(alertConstants.UPDATED_SUCCESSFULLY)
        this.setState({authorized_approver_group:''})
        this.reset();
        this.getGroupAuthList();
      } 
    }else{ 
      this.validator.showMessages()
      this.setState({...this.state})
    }
  } 

  handleChange = (e) => { 
    this.setState({userList:[],[e.target.name]:e.target.value}) 
  }

  handleChangeCheck = () => {
    this.setState({approver_can_approv_exception:!this.state.approver_can_approv_exception})
  }

  chooseGroup = (e) => { 
    this.setState({
      authorized_approver_group:e
    })
  }

  removeGroup = () => {
    this.setState({authorized_approver_group:''})
    this.reset()
  }

  changeCurrency = (e) => { 
    this.setState({
      currenyType:e.currencyDetails.itemName,
      currency:e
    })
  } 

  getGroupAuthList = async () => {
    let req = {
      companyId:this.state.companyId,
      // groupId:this.state.authorized_approver_group.id,
      page:this.state.page,
      type:this.state.authorized_approver_type,
      limit:this.state.limit
    }
    this.setState({loading:true})
    let resp = await workflowService.getAuthList(req)
    this.setState({loading:false}) 
    if(resp){
      this.setState({listAll:resp.result,count:resp.count})
    }
  }

  searchUser = async (e) => {  
    this.setState({typing:e.target.value})
    if(e.target.value !== ''){ 
      this.setState({loadingUser:true})
      let resp = await userService.getUserList(e.target.value,this.state.companyId)
      if(resp){
        this.setState({userList:resp.result}) 
      }
      this.removeUser() 
      this.setState({loadingUser:false})
    }else{
      this.setState({userErr:true}) 
    }
  }

  choosedUser = (e) => { 
    this.setState({approver_name:e,userList:[],typing:e.email,userErr:false})
  }

  removeUser = () =>{
    this.setState({approver_name:''})
  }

  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];

  reset = () => {
    this.setState({
      currenyType:"Choose currency", 
      approver_name:'', 
      authorized_approver_level:'',
      authorized_approver_group:'',
      approver_can_approv_exception:false,
      currency:'',
      amount:'',
      typing:'', 
      loadingUser:false,
      userErr:false,
      formLoading:false,
      userList:[], 
      activeItem:''
    })
    $('.close').click()
  }

  handleItemChange = limit => {
    this.setState({limit:limit["items"],page:1},() => {this.getGroupAuthList()})  
  };
 
  handlePageChange = (pageNumber) => { 
    this.setState({page:pageNumber},() => {this.getGroupAuthList()})   
  }

  modifyItem = (value,key) => {
    if(key === this.state.activeItem){
      this.setState({
        activeItem:'',
        authorized_approver_group:''
      })
      this.reset()
    }else{
      this.setState({
        activeItem:key,
        authorized_approver_group:value.authorized_approver_group,
        authorized_approver_level:value.authorized_approver_level,
        approver_can_approv_exception:value.approver_can_approv_exception,
        approver_name:value.approver_name,
        currency:value.currency,
        currenyType:value.currency.currencyDetails.itemName,
        amount:value.amount,
        level:value.level,
        typing:value.approver_name.email,
        id:value.authorized_approver_id 
      })
    } 
  }

  changeListType = (type) => {
    this.setState({authorized_approver_type:type},() => {
      this.reset()
      this.getGroupAuthList()
    }) 
  }

  render() {
      return(
          <div>
              <AuthorisedApproversListPage 
                changeListType={this.changeListType}
                {...this.state}
                error = {this.validator}
                changeCurrency={this.changeCurrency}
                handleChange={this.handleChange}
                chooseGroup={this.chooseGroup}
                removeGroup={this.removeGroup}
                searchUser={this.searchUser}
                choosedUser={this.choosedUser}
                removeUser={this.removeUser}
                handleChangeCheck={this.handleChangeCheck}
                handleSaveSubmit={this.handleSaveSubmit}
                reset={this.reset}
                pageItemsList={this.pageItemsList}
                handlePageChange={this.handlePageChange}
                handleItemChange={this.handleItemChange}
                modifyItem={this.modifyItem}
                handleUpdateSubmit={this.handleUpdateSubmit}
              />
          </div>
      )
  }
}

export default AuthorisedApproversList;