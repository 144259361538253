import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { getSSOUserDetails, getSSOURL } from 'src/_services';
import { toast } from 'react-toastify';
import cryptr from 'src/_helpers/crypty';
import menuStore from "../../_store/menu.store"


@inject('authStore')
@inject('commonStore')
@observer
class SsoUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ssourl: ""
    }
    if (this.props.match.params.sso !== null && !localStorage.getItem("440a21")) {
      this.ssoMethod()
    }
    else{
      this.props.history.push('/')
    }
    var success = this.getUrlVars()['success']
    var token = this.getUrlVars()["token"];
    var ssoURL = this.getUrlVars()["ssoURL"]
    if (success === "true" && token && ssoURL) {
      localStorage.setItem('sso', ssoURL)
      localStorage.setItem(
        "440a24",
        cryptr.encrypt(JSON.stringify({ token: token }))
      );
      this.getUserDetails();
    }
  }
  getUrlVars() {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }
  getUserDetails = async () => {
    // replace updated user details based on token url
    let resp = await getSSOUserDetails()
    if (resp) {
      localStorage.setItem(
        "440a24",
        cryptr.encrypt(JSON.stringify(resp.result))
      );
      // currency
      localStorage.setItem("1UC43", cryptr.encrypt(JSON.stringify(resp.result.currency)));
      // token
      localStorage.setItem("440a21", JSON.stringify(resp.result.token));
      // company data
      localStorage.setItem("440a28", resp.result.companyId);
      localStorage.setItem('personalInfo', resp?.result?.userPersonalInfo);
      if (resp && resp.result && resp.result.cpiId) {
        localStorage.setItem('cpiId', resp.result.cpiId);
      } else { }
      // console.log('---- login data  ', data.result);
      menuStore.getMenuList();
      return resp;

      this.props.history.push('/')
    }
  }
  ssoMethod = async () => {
    let data = {
      relayState: window.location.origin,
      companyId: this.props.match.params.sso.toString()
    }
    let resp = await getSSOURL(data);
    if (resp && resp.result) {
      this.setState({
        ssourl: resp.result.ssoURL
      })
    } else if (resp.error) {
      toast.error(resp.error.message)
    }
    if (resp && resp.result !== undefined) {
      window.location.href = `${resp.result.ssoURL}`
    } else {
      setTimeout(() => {
        this.props.history.push('/login')
      }, 2000);
    }
  }

  render() {
    return (
      <div style={{ textAlign: "center", marginTop: "300px", fontSize: "30px" }}>
        Redirecting...
      </div>
    );
  }
}

export default SsoUrl;
