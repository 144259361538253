import { observable, action, reaction } from "mobx";
import userStore from "./user.store";
import { userService } from "../_services";
import CommonStore from "./common.store";
import { toast } from "react-toastify";
import menuStore from "./menu.store";

class AuthStore {
  @observable inProgress = false;
  @observable currentUser;
  @action login(data) {
    this.inProgress = true;
    CommonStore.errors = [];
    userService
      .login(data)
      .then(
        data => {
          sessionStorage.setItem("isCheckedTrip", "true");
          sessionStorage.setItem("isCheckedExpense", "true");
          sessionStorage.setItem("isCheckedCashAdvance", "true");
          localStorage.setItem('440a1', data.result.id);
          this.inProgress = false;
          CommonStore.setToken(data.token);
          this.pullUser(data.result);
          localStorage.setItem('personalInfo', data?.result?.userPersonalInfo);
          if (data && data.result && data.result.cpiId) {
            localStorage.setItem('cpiId', data.result.cpiId);
          }
          // console.log('---- login data  ', data.result);
          menuStore.getMenuList();
          return data;
        },
        error => {
          this.inProgress = false
          CommonStore.errors = error;
        }
      )
  }
  @action delecateLogin(req, status) {
    this.inProgress = true;
    userService.switchDelUser(req, status)
      .then(
        data => {
          if (data) {
            this.inProgress = false;
            CommonStore.setToken(data.token);
            this.pullUser(data.result);
            menuStore.getMenuList();
          }
        },
        error => {
          this.inProgress = false;
          CommonStore.errors = error;
        }
      )
  }
  @action logout() {
    // commonStore.setToken(undefined);
    this.forgetUser();
    return Promise.resolve();
  }

  @action pullUser(data) {
    this.loadingUser = true;
    this.currentUser = data;
  }

  @action forgetUser() {
    this.currentUser = undefined;
  }
}

export default new AuthStore();
