import React from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import ConfigRulesMenu from "../config-rules-menu";
import Select from 'react-select';

const CarConfigStep3Page = props => {
  const { form3Data, carId } = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">A</div>
          <div className="right">
            <h6 className="heading">Add Car Configuration</h6>
            <p className="text">This page is used to maintain mileage amount defined by the company policy.</p>
          </div>
        </div>
      </div>
      <div className="py-padding">
        <div className="table-container">
          <form>
            <ConfigRulesMenu />
            <div className="row">
              <div className="col-md-6">
                {/* <div className="btn-group-margin">
                  <button
                    type="button"
                    className="btn   sm-padding"
                    data-toggle="modal"
                    data-target="#AddTemplateModal"
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    className="btn   sm-padding"
                  >
                    Remove
                  </button>
                </div> */}
              </div>
              <div className="col-md-3 text-right" />
              <div className="col-md-3">
                <div className="search-custom">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    name="search"
                    autoComplete="off"
                  />
                  <span>
                    <i className="material-icons">search</i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Vehicle Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {form3Data && form3Data.vehicleType && form3Data.vehicleType.map((type) => {
                  return (
                    <tr key={type.id}>
                      <td>{type.vehicle}</td>
                      <td>
                        <label className="switch">
                          <input type="checkbox"
                            checked={type.vehicleStatus}
                            onChange={e =>
                              props.onStatusChangeHandler(
                                type.id,
                                type.vehicleStatus
                              )
                            } />
                          <span className="slider round" />
                        </label>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="p-padding">
            <div className="btn-group-margin">
              <NavLink
                //to="/administration/expense/audit-rules/step2"
                to={`/administration/expense/car-config/config-step4/`}
                onClick={props.onSubmitHandler}
                className="btn btn-border-success lg-padding"
              >
                Next
            </NavLink>
              <NavLink
                to="/administration/expense/car-config/car-configuration"
                className="btn btn-border-basic lg-padding"
              >
                Cancel
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CarConfigStep3Page;
