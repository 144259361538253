import React from "react";
import VechileType from "./VechileType";
import TripType from "./TripType";
import FromSearchInput from "./FromSearch";
import ToSearchInput from "./ToSearch";
import { getValue } from "src/_components/common/lodsh";
import CabDatePicker from "./CabDatePicker";
import CabCurrency from "./CabCurrency";
import CabAmount from "./CabAmount";
import CabForm from "./CabForm";

const CabSearchInput = props => (
    <div>
        {getValue(props, "permission.vechileType.visiblity", false) && props.requestType !== 2 &&
            <VechileType {...props} />
        }
        {getValue(props, "permission.tripType.visiblity", false) && props.requestType !== 2 &&
            <TripType {...props} />
        }
        {getValue(props, "permission.fromLocation.visiblity", false) && props.requestType !== 2 &&
            <FromSearchInput {...props} />
        }
        {getValue(props, "permission.toLocation.visiblity", false) && props.requestType !== 2 &&
            <ToSearchInput {...props} />
        }
        {getValue(props, "permission.pickUpDate.visiblity", false) && props.requestType !== 2 &&
            <CabDatePicker
                name="car_pickup_timestamp"
                isRequired={true}
                label="Pickup"
                {...props} />
        }
        {getValue(props, "permission.returnDate.visiblity", false) && props.requestType !== 2 && props.request.car_rental_type !== "2" &&
            <CabDatePicker
                isRequired={props.returnDateIsRequired}
                name="car_return_timestamp"
                label="Drop"
                carPickupDate={props.carPickupDate}
                {...props} />
        }
        {props.requestType === 0 && props.requestType !== 2 &&
            <CabCurrency
                name="car_booking_currency"
                {...props} />
        }
        {props.requestType === 0 && props.requestType !== 2 &&
            <CabAmount
                name="car_booking_price"
                {...props} />
        }

      {props.requestType === 2 &&
            <CabForm
                name="car_booking_price"
                {...props} />
        }
    </div>
)

export default CabSearchInput;
