import React from "react";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import NumberFormat from 'react-number-format';

const RoomListPage = props => {
  const {
    viewAll,
    viewRoomAll,
    duration,
    policy,
    rooms,
    selectedItem,
    addToCart,
    currency,
    previewImg,
    loadImg,
    isAddingToCart,
    loadingUnique,
  } = props;

  return (
    <ul className="hotel-page pad pt-0 minus-ml minus-mr">
      {rooms &&
        rooms.map((list, key) => {
          return (
            <li key={key}>
              {list.hotel_total_max_price !== 0 && (
                <div className="hotel-options">
                  <div className="select-hotel">
                    {/* <label className="checkbox-container">
                      <input
                        type="checkbox"
                        name="radio"
                        checked={
                          selectedItem.indexOf(
                            (list.roomId)
                          ) === -1
                            ? false
                            : true
                        }
                        onChange={e => addToCart(list)}
                      />
                      <span className="checkmark" />
                    </label> */}
                  </div>
                  {list.hotel_room_photos[0]&&list.hotel_room_photos[0].url_max300?(list.hotel_room_photos[0].url_max300 &&
                    <div className="image" data-toggle="modal" data-target={"#image-gallery" + key}>
                      <img onClick={e => loadImg(list.hotel_room_photos[0].url_max300 && list.hotel_room_photos[0].url_max300)} src={list.hotel_room_photos[0].url_max300 ? list.hotel_room_photos[0].url_max300 : <img
                        src="/assets/images/booking/hotel_banner.png"
                        alt=""
                      />} alt="" />
                      <h3 className="image__text"> +
                      {(list.hotel_room_photos) ? list.hotel_room_photos.length - 1 : <img
                          src="/assets/images/booking/hotel_banner.png"
                          alt=""
                        />} </h3>
                    </div>):
                    list.hotel_room_photos[0] &&
                    <div className="image" data-toggle="modal" data-target={"#image-gallery" + key}>
                      <img onClick={e => loadImg(list.hotel_room_photos[0] && list.hotel_room_photos[0])} src={list.hotel_room_photos[0] ? list.hotel_room_photos[0] : <img
                        src="/assets/images/booking/hotel_banner.png"
                        alt=""
                      />} alt="" />
                      <h3 className="image__text"> +
                      {(list.hotel_room_photos) ? list.hotel_room_photos.length - 1 : <img
                          src="/assets/images/booking/hotel_banner.png"
                          alt=""
                        />} </h3>
                    </div>
                  }
                  {(!list.hotel_room_photos[0] || list.hotel_room_photos[0] === "NA") &&
                    <div className="image">
                      <div className="hotel-no-img">
                        <div className="cen">
                          <i className="material-icons">
                            photo_size_select_actual
                        </i>
                          <p>No Image</p>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="hotel-details">
                    <div className="right book_room">
                      {/* <div className="flight-price text-left"> */}
                      <div className={
                        selectedItem.indexOf(list.roomId) === -1
                          ? "flight-price hotel-price text-left"
                          : "flight-price hotel-price added text-left"
                      }>
                        <div className="cash">
                          <span>
                            <h5>{list.hotel_room_rack_rate.currency}
                              &nbsp;
                              <NumberFormat value={list.hotel_total_price.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                            </h5>
                            <p>{duration} Night</p>
                          </span>
                        </div>
                        <div className="d-button" onClick={e => addToCart(list)}>
                          <i className="material-icons">
                            {
                              selectedItem.indexOf(list.roomId) === -1
                                ? "chevron_right"
                                : "done"
                            }
                          </i> {
                            (loadingUnique == list.roomId)
                              ? 'Adding..'
                              : (selectedItem.indexOf(list.roomId) === -1)
                                ? "Add"
                                : "Added to \n cart"}
                        </div>
                      </div>
                      <div className="clearfix" />
                      {(list.out_of_policy) &&
                        <p className="text-danger fs-12 hover-v"
                        >
                          <i className="material-icons fs-14 align-middle">
                            error
                            </i>{" "}
                          <span>Out of policy</span>
                          <div className="hover-name right-bottom out-of-p">
                            <p>This option breaks one or more company travel rules that prevent it from being selected.</p>
                            {list.violations && list.violations.map((rule, ruleKey) => {
                              return (
                                <p key={ruleKey}><i className="material-icons">
                                  warning
                                            </i> {rule.message}.</p>
                              )
                            })}
                          </div>
                        </p>
                      }
                      {/* <p className="text-danger mt-2"><i className="material-icons fs-14">error</i> Out Of Policy</p> */}
                      {/* <h6 className="title">
                        {" "}{list.hotel_room_rack_rate.currency}
                        &nbsp; {list.hotel_total_price.toFixed(2)}
                      </h6>
                      <p className="label">{duration} Night</p> */}
                    </div>
                    <NavLink to="#">
                      <h5 className="title">
                        {list.hotel_room_name}
                        <span className="small-text">{" "}{list.hotel_room_rack_rate.currency}
                          &nbsp;
                          <NumberFormat value={(list.hotel_total_price / duration).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                          &nbsp;  Per night
                        </span>
                      </h5>
                    </NavLink>
                    <div className="rules-extra">
                      <p>
                        <i className="material-icons fs-12">
                          person</i>{" "}
                        {list.hotel_room_max_occupancy} Max Person
                      </p>
                      {list.hotel_room_breakfast && (
                        <p>
                          <i className="material-icons fs-12">
                            fastfood</i> Breakfast
                        </p>
                      )}
                      {list.hotel_room_dinner_include && (
                        <p>
                          <i className="material-icons fs-12">
                            local_dining</i> Dinner
                        </p>
                      )}
                      {(list.hotel_room_refundable) ?
                        <p>
                          <i className="material-icons fs-12">
                            money</i> Refundable
                        </p> :
                        <p>
                          <i className="material-icons fs-12">
                            money</i> Non-Refundable
                      </p>
                      }
                    </div>
                    {/* <p className="desc">{list.hotel_room_descriptions}</p> */}
                    {/* <div className="location-map mt-1 mb-1">
                      <span>Free Wifi</span>
                      &nbsp;-&nbsp;
                      <span>Parking</span>
                      <button type="button">
                        <button onClick={e => viewAll()} className="link">
                          &nbsp; - View More
                        </button>
                      </button>
                    </div> */}
                    <p>{list.hotel_room_description}</p>
                    <div className="btn-group-margin mt-2">
                      <button
                        onClick={e => viewRoomAll(list)}
                        className="btn sm-padding"
                      >
                        View Facilities
                      </button>
                      <button
                        data-toggle="modal"
                        data-target={"#exampleModal" + key}
                        className="btn sm-padding"
                      >
                        Hotel Policy
                      </button>
                    </div>
                    {(list.hotel_room_refundable) &&
                      <p>
                        Free Cancellation before {" "}
                        <Moment format="DD MMM YYYY">
                          {list.hotel_refudable_untill}
                        </Moment>
                      </p>
                    }

                    <div className="clearfix" />
                  </div>
                  <div className="clearfix" />
                </div>
              )}
              <div
                className="modal fade"
                id={"exampleModal" + key}
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content border-0">
                    <div className="modal-head"><h5 className="modal-title" id="exampleModalLabel">Hotel Policy</h5></div>
                    <div className="modal-middle pm-30">

                      <ul className="list-style" dangerouslySetInnerHTML={{ __html: policy ? policy : "Additional information not available" }}></ul>
                    </div>
                    <div className="modal-last btn-group-margin pt-0 pm-30">
                      <button type="button" className="btn btn-badge" data-dismiss="modal">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal without-bg fade"
                id={"image-gallery" + key}
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-100 m-0" role="document">
                  <div className="modal-content border-0">
                    <div className="modal-header border-0">
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-middle bg-black">
                      <div className="gallery text-center my-3">
                        <img src={previewImg} className="" alt="..." />
                      </div>
                      <div className="pm-30 small-gallery ">
                        <div className="c-nav-l">
                          <i className="material-icons">
                            keyboard_arrow_left
                          </i>
                        </div>
                        <div className="c-nav-r">
                          <i className="material-icons">
                            keyboard_arrow_right
                          </i>
                        </div>
                        <div className="smae-line">
                          {list.hotel_room_photos &&
                            list.hotel_room_photos.map((item, key) => {
                              return (
                                <div className="gallery__items" key={key}>
                                  <div className="small__img">
                                    <img onClick={e => loadImg(item.url_max300)} src={item.url_max300} className="" alt="..." />
                                  </div>
                                </div>
                              )
                            })}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
    </ul>
  );
};
export default RoomListPage;
