import React from "react";
import { Fragment } from 'react';

const AmenitiesPage = props => {
  const { items, viewAllClose, index, hotelDescription, rules, loadingDetail } = props;

  return (
    <div className="inner-div">
      <h5 className="hotel_info_head text-white left">Hotel Information<span className="right" onClick={e => viewAllClose(index)}>
        &times;
    </span></h5>
      <div className="clearfix" />
      <div className="pm-30">
        <h6 className="sub-heading">Hotel Description</h6>
        <div className="mb-5">
          {(loadingDetail) ? "loading" :
            hotelDescription.hotel_description ? hotelDescription.hotel_description : "Additional information not available"
          }
        </div>
        <h6 className="sub-heading">Most Popular facilities</h6>
        <div className="mb-5">
          {/* <h5 className="mb-1 mt-1">
            General <i className="material-icons fs-14 mr-2">info</i>
          </h5> */}
          {items ? items &&
            items.map((list, key) => (
              <p className="half" key={key}>
                <i className="material-icons fs-12 mr-2 text-primary">
                  done
                </i>
                <span className="text-capitalize">{list.replace("_", " ")}</span>
              </p>
            )) : "Additional information not available"}
        </div>
        {rules ? rules && rules.length &&
          <>
            <h6 className="sub-heading">Hotel Rules</h6>
            {/* {
            rules.map((rule, index) => {
              return(
                <>
                <h5>{rule.name}</h5>
                <p className="half" key={index}>
                  <i className="material-icons fs-12 mr-2 text-primary">

                  </i>
                {rule.content}
                </p>
                </>
              )
            })
          } */}
            <div className="mb-3">
              <ul className="list-style" dangerouslySetInnerHTML={{ __html: rules }}></ul>
            </div>
          </> : "Additional information not available"
        }

      </div>
    </div>
  );
};
export default AmenitiesPage;
