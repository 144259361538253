import React, { Component } from 'react';
import HotelInfoPage from './hotel-info-html';
import { toast } from "react-toastify";
import $ from "jquery";
import SimpleReactValidator from "simple-react-validator";


import {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { contractServices } from '../../../../../../_services';
import { number } from '@storybook/addon-knobs';

class HotelInfo extends Component {

  state = {
    editIsTrue: false,
    hotelName: "",
    index: 0,
    cityName: "",
    companyId: localStorage["440a28"],
    itemCode: "CUR001",
    hotelsData: [],
    hotelContractCityId: "",
    cityId: "",
    address: "",
    currency: { label: "Select", value: '' },
    contractType: {},
    totalRooms: null,
    roomType: {},
    mealType: "",
    meals: {},
    personName: "",
    contactNumber: '',
    alternativeNumber: '',
    email: "",
    hotelDescription: "",
    currencyTypes: [],
    contractTypes: [],
    contractName: '',
    basicHotelData: [],
    room_types: [],
    meal_types: [],
    max_persons: [],
    fare_types: [],
    coordinates: [],
    latitude: 13.0265,
    longitude: 77.5269,
    location: "",
    browseAllImages: [],
    from: "",
    popularCity: [],
    activeInput: 0,
    locationFromRef: React.createRef(),
    cancelationPolicies: "",
    price: {
      Weekly: undefined,
      Weekend: undefined,
      Yearly: undefined,
      January: undefined,
      February: undefined,
      March: undefined,
      April: undefined,
      May: undefined,
      June: undefined,
      July: undefined,
      August: undefined,
      September: undefined,
      October: undefined,
      November: undefined,
      December: undefined,
    },
    photos: [],
    isSearchCountryCodeListOpen: false,
    selectedCountryCode: {
      country: '',
      dailCode: '',
      countryId: ''
    },
    countryCodeList: [],
    isSearchCountryCodeListOpen2: false,
    selectedCountryCode2: {
      country: '',
      dailCode: '',
      countryId: ''
    },
    countryCodeList2: [],
    validator: new SimpleReactValidator(),
    files: [],
    imagesPreviewUrls: [],
    uploading: false,
    deleteImage: false,
  }


  handleChangeCountryCode2 = async (e) => {
    let searchTerm = e.target.value;
    try {
      if (searchTerm != '') {
        // * if the user is typing show the matched search users.
        this.setState({
          isSearchCountryCodeListOpen2: true,
          selectedCountryCode2: {
            country: e.target.value
          }
        });

        let res = await contractServices.getCountryCodeList(searchTerm);

        if (res && res.count) {
          this.setState({
            countryCodeList2: res.result
          })

        }
      } else {
        this.setState({ countryCodeList2: [], selectedCountryCode2: {} });
      }

    } catch (err) {
      console.log('some error occurred.');
    }

  }
  selectChangeCountryCode2 = (country) => {
    let selectedCountryCode2 = {
      country: country.country,
      dailCode: country.dailCode,
      countryId: country.id
    }
    this.setState({
      selectedCountryCode2,
      isSearchCountryCodeListOpen2: false
    });
  }

  handleChangeCountryCode = async (e) => {
    let searchTerm = e.target.value;
    try {
      if (searchTerm != '') {
        // * if the user is typing show the matched search users.
        this.setState({
          isSearchCountryCodeListOpen: true,
          selectedCountryCode: {
            country: e.target.value
          }
        });

        let res = await contractServices.getCountryCodeList(searchTerm);
        if (res && res.count) {
          this.setState({
            countryCodeList: res.result
          })

        }
      } else {
        this.setState({ countryCodeList: [], selectedCountryCode: {} });
      }

    } catch (err) {
      console.log('some error occurred.');
    }

  }
  selectChangeCountryCode = (country) => {
    let selectedCountryCode = {
      country: country.country,
      dailCode: country.dailCode,
      countryId: country.id,
    }
    this.setState({
      selectedCountryCode,
      isSearchCountryCodeListOpen: false
    });
  }

  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    await this.setState({ indexHotel: parseInt(this.props.match.params.i) });
    await this.setState({ hotelContractCityId: this.props.match.params.hotelContractCityId, cityId: this.props.match.params.newCityId });
    await this.hotelsData();

    this.getCurrencyType();
    this.getHotelRoomData();
    this.getContractTypes();
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  getContractTypes = () => {
    let contractTypes = [{ label: "Monthly", value: "Monthly" }, { label: "Weekly", value: "Weekly" }, { label: "Yearly", value: "Yearly" }];
    this.setState({ contractTypes });
  }
  hotelsData = () => {
    let hotelsData = JSON.parse(localStorage.getItem('hotelsData'));
    let indexHotel1 = (this.state.indexHotel);
    if (indexHotel1 >= 0) {
      this.setState({
        hotelsData: hotelsData[indexHotel1],
        editIsTrue: true,
      })
      let hotel = this.state.hotelsData ? this.state.hotelsData : {};
      // let hotel = (hotelsData[this.state.index]) ? hotelsData[this.state.index] : {};

      this.setState({
        cityName: hotel.cityName,
        cityId: hotel.cityId,
        hotelContractCityId: hotel.hotelContractCityId,
        companyId: this.state.companyId,
        hotelName: hotel.hotelName,
        address: hotel.address,
        currency: {
          label: hotel.currency.itemName,
          value: hotel.currency.id
        },
        contractType: {
          label: hotel.contractType,
          value: hotel.contractType
        },
        totalRooms: hotel.totalRooms,
        roomType: {
          label: hotel.roomType.name,
          value: hotel.roomType.id
        },
        meals: {
          label: hotel.meals.name,
          value: hotel.meals.id
        },
        maxPersons: {
          label: hotel.maxPersons.name,
          value: hotel.maxPersons.id
        },
        fareType: {
          label: hotel.fareType.name,
          value: hotel.fareType.id
        },
        latitude: hotel.geoCoordinates.coordinates[0],
        longitude: hotel.geoCoordinates.coordinates[1],
        price: hotel.price,
        roomPolicy: hotel.roomPolicy,
        personName: hotel.personName,
        contactNumber: hotel.contactNumber,
        alternativeNumber: hotel.alternativeNumber,
        email: hotel.email,
        hotelDescription: hotel.hotelDescription,
        photos: hotel.photos,
        id: hotel.id,
        cancelationPolicies: hotel.cancelationPolicies,
        selectedCountryCode: {
          country: hotel.contactNumberCode.country, dailCode: hotel.contactNumberCode.dailCode, countryId: hotel.contactNumberCode.id
        },
        selectedCountryCode2: {
          country: hotel.alternativeNumberCode.country, dailCode: hotel.alternativeNumberCode.dailCode, countryId: hotel.alternativeNumberCode.id
        }
      })
    }
    else {
      this.setState({
        cityName: '',
        cityId: this.state.cityId,
        hotelContractCityId: this.state.hotelContractCityId,
        companyId: this.state.companyId,
        hotelName: '',
        address: '',
        currency: { label: "Select..", value: '' },
        contractType: { label: "Select..", value: '' },
        totalRooms: undefined,
        roomType: { label: "Select..", value: '' },
        meals: { label: "Select..", value: '' },
        maxPersons: { label: "Select..", value: '' },
        fareType: { label: "Select..", value: '' },
        price: {
          Weekly: undefined,
          Weekend: undefined,
          Yearly: undefined,
          January: undefined,
          February: undefined,
          March: undefined,
          April: undefined,
          May: undefined,
          June: undefined,
          July: undefined,
          August: undefined,
          September: undefined,
          October: undefined,
          November: undefined,
          December: undefined,
        },
        roomPolicy: '',
        personName: '',
        contactNumber: '',
        alternativeNumber: '',
        email: '',
        hotelDescription: '',
        photos: [],
        id: '',
        cancelationPolicies: "",
      })
    }
  }

  addNewHotel = async (e) => {
    e.preventDefault();
    let payLoad = {
      hotelContractCityId: this.state.hotelContractCityId,
      cityName: this.state.cityName,
      cityId: this.state.cityId,
      companyId: this.state.companyId,
      hotelName: this.state.hotelName,
      address: this.state.address,
      currency: this.state.currency.value,
      contractType: this.state.contractType.value,
      totalRooms: Number(this.state.totalRooms),
      roomType: this.state.roomType.value,
      meals: this.state.meals.value,
      maxPersons: this.state.maxPersons.value,
      fareType: this.state.fareType.value,
      price: this.state.price,
      roomPolicy: this.state.roomPolicy,
      "geoCoordinates": {
        "coordinates": [
          this.state.latitude,
          this.state.longitude
        ]
      },
      personName: this.state.personName,
      contactNumber: this.state.contactNumber,
      alternativeNumber: this.state.alternativeNumber,
      email: this.state.email,
      hotelDescription: this.state.hotelDescription,
      photos: this.state.photos,
      contactNumberCode: this.state.selectedCountryCode.countryId,
      alternativeNumberCode: this.state.selectedCountryCode2.countryId,
      cancelationPolicies: this.state.cancelationPolicies
    }
    if (this.state.validator.allValid()) {
      var resp = await contractServices.addNewHotel(payLoad);
      if (resp) {
        toast.success(resp.message);
        $(".close").click();
      }

    } else {
      this.state.validator.showMessages();
      this.forceUpdate();
    }

  };
  updateHotelData = async (e) => {
    e.preventDefault();
    let payLoad = {
      hotelContractCityId: this.state.hotelContractCityId,
      cityName: this.state.cityName,
      cityId: this.state.cityId,
      companyId: this.state.companyId,
      hotelName: this.state.hotelName,
      address: this.state.address,
      currency: this.state.currency.value,
      contractType: this.state.contractType.value,
      totalRooms: Number(this.state.totalRooms),
      roomType: this.state.roomType.value,
      meals: this.state.meals.value,
      maxPersons: this.state.maxPersons.value,
      fareType: this.state.fareType.value,
      price: this.state.price,
      roomPolicy: this.state.roomPolicy,
      "geoCoordinates": {
        "coordinates": [
          this.state.latitude,
          this.state.longitude
        ]
      },
      personName: this.state.personName,
      contactNumber: this.state.contactNumber,
      alternativeNumber: this.state.alternativeNumber,
      email: this.state.email,
      hotelDescription: this.state.hotelDescription,
      photos: this.state.photos,
      cancelationPolicies: this.state.cancelationPolicies,
      contactNumberCode: this.state.selectedCountryCode.countryId,
      alternativeNumberCode: this.state.selectedCountryCode2.countryId
    }
    if (this.state.validator.allValid()) {
      var resp = await contractServices.updateHoteldata(this.state.id, payLoad);
      if (resp) {
        toast.success(resp.message);
        $(".close").click();
      }

    } else {
      this.state.validator.showMessages();
      this.forceUpdate();
    }
  };

  showInput = e => {
    this.setState({ activeInput: e })
  };
  getCurrencyType() {
    contractServices.getCurrencyType(this.state.companyId, this.state.itemCode).then(
      data => {
        if (data != null) {
          this.setState({ currencyTypes: data.result })
        }
      },
      error => {
        if (error.statusCode === 422) {
          let errors = error.message;
          Object.keys(errors).map(key => {
            return toast.error(errors[key]);
          });
        } else {
          toast.error(error.message);
        }
      }
    );
  }
  getHotelRoomData = async () => {
    let res = await contractServices.getHotelRoomData();
    if (res) {
      this.setState({ basicHotelData: res.result })
      this.state.basicHotelData.forEach((item) => {
        if (item.type === "ROOM_TYPE") {
          this.state.room_types.push(item);
        }
        else if (item.type === "MEALS") {
          this.state.meal_types.push(item);
        }
        else if (item.type === "MAX_PERSONS") {
          this.state.max_persons.push(item);
        }
        else {
          this.state.fare_types.push(item);
        }
      })
    }
  }
  changeCurrencyType = (val) => {
    this.setState({ currency: val });
  };
  changeContractType = (val) => {
    this.setState({ contractType: val });
  };
  changeMealType = (val) => {
    this.setState({ meals: val });
  };
  changeNoofPeopleType = (val) => {
    this.setState({ maxPersons: val });
  };
  changeRoomType = (val) => {
    this.setState({ roomType: val });
  };
  changeFareType = (val) => {
    this.setState({ fareType: val });
  };
  getLocation = e => {
    this.setState({
      latitude: e.lat,
      longitude: e.long,
      coordinates: [e.lat, e.long]
    });
    this.getAddress(e.lat, e.long);
  };

  getAddress = async (lat, lng) => {
    const data = await contractServices.getAddress(lat, lng);
    if (data != null) {
      (data.results[0] && console.log(data.results[0].formatted_address))
      this.setState({
        location: data.results[0].formatted_address
      })
    }
  };
  handleChange = from => {
    this.setState({ from });
  };
  getCity = async e => {
    this.setState({
      from: e.name,
      latitude: e.lat,
      longitude: e.long,
      activeInput: 0
    });
  };
  handleSelect = from => {
    geocodeByAddress(from)
      .then(results => getLatLng(results[0]))
      .then(location => {
        this.setState({
          from: from,
          latitude: parseFloat((location.lat)),
          longitude: parseFloat((location.lng)),
          activeInput: 0,
        });
      })
      .catch(error => console.error("Error", error));
  };
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = e => {
    const locationFromPopup = this.state.locationFromRef.current;
    if (
      this.state.activeInput === 1 &&
      this.state.locationFromRef &&
      !locationFromPopup.contains(e.target)
    ) {
      this.setState({ activeInput: 0 })
      return;
    }
  };
  handleChange3 = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleChange4 = (e) => {
    this.setState({
      price: {
        ...this.state.price,
        [e.target.name]: Number(e.target.value)
      }
    })
  }
  onImageBrowse = (e) => {

    const files = Array.from(e.target.files)
    this.setState({ uploading: true })
    const formData = new FormData()

    files.forEach((file, i) => {
      formData.append(i, file)
    })
    this.uploadHotelImages(formData);
  }
  uploadHotelImages = async (payLoad) => {
    var resp = await contractServices.uploadHotelImages(payLoad);
    if (resp) {
      this.setState({ photos: resp.result });
      $(".close").click();
    }
  };
  deleteImage = () => {
    this.setState({ deleteImage: !this.state.deleteImage })
  }
  removeFromList = (i) => {
    let filetredPhotos = this.state.photos.filter((photo, index) => {
      if (index !== i) { return photo }
    })
    this.setState({ photos: filetredPhotos });

  }
  componentWillUnmount = () => {
    localStorage.removeItem('hotelsData');
  }

  render() {
    return (
      <div>
        <HotelInfoPage
          {...this.state}
          state={this.state}
          changeCurrencyType={this.changeCurrencyType}
          changeContractType={this.changeContractType}
          changeMealType={this.changeMealType}
          changeNoofPeopleType={this.changeNoofPeopleType}
          changeRoomType={this.changeRoomType}
          changeFareType={this.changeFareType}
          getLocation={this.getLocation}
          onImageBrowse={this.onImageBrowse}
          handleChange={this.handleChange}
          showInput={this.showInput}
          getCity={this.getCity}
          handleSelect={this.handleSelect}
          handleChange3={this.handleChange3}
          handleChange4={this.handleChange4}
          updateHotelData={this.updateHotelData}
          handleChangeCountryCode={this.handleChangeCountryCode}
          selectChangeCountryCode={this.selectChangeCountryCode}
          handleChangeCountryCode2={this.handleChangeCountryCode2}
          selectChangeCountryCode2={this.selectChangeCountryCode2}
          handleSubmit={this.handleSubmit}
          addNewHotel={this.addNewHotel}
          deleteImage={this.deleteImage}
          removeFromList={this.removeFromList}
        />

      </div>
    )
  }
}
export default HotelInfo;