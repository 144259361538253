import { observable, action } from 'mobx';
import { masterService } from '../_services';
import CommonStore from './common.store';
import { history } from "../_helpers";
import { toast } from 'react-toastify';
import cryptr from '../_helpers/crypty';
class MenuStore {
    @observable menuItems;
    @observable parentId;
    @observable customId;
    @observable subMenuItem = '';
    @observable homeCards = '';
    @action getMenuList() {
        var companyId = localStorage['440a28'];
        var userId = JSON.parse(cryptr.decrypt(localStorage['440a24']));
        localStorage.removeItem('619caa9c');
        // console.log("this is user ",userId)
        masterService.getMenu(userId.id,companyId)
            .then(
                data => {
                    if (data != null) { 
                        this.menuItems = data.result; 
                        if(data.result){
                            localStorage.setItem('619caa9c', cryptr.encrypt(JSON.stringify(this.menuItems)))
                            if(data.result.length !== 0){ 
                                let url = data.result[0].frontEndUrl;  
                                if(history.location.pathname === "/login"){
                                    history.push(url);  
                                }else{  
                                    window.location.href = url;  
                                }
                            }
                        }else{
                            toast.error('User don`t have any access');
                            history.push("/login");
                        }
                    }
                }
            )
    }
    @action getMenuFromStore(){ 
        if(localStorage['619caa9c']){
          let menu = JSON.parse(cryptr.decrypt(localStorage['619caa9c'])) 
          this.menuItems = menu;  
        }
    }
    @action getParent(data) {
        this.parentId = data;
    }
    @action tempStore(data) {
        this.menuItems = JSON.parse(cryptr.decrypt(data))
    }
    @action getCustom(data) {
        this.customId = data;
    }
    @action tempSubStore(data) {
        this.subMenuItem = JSON.parse(cryptr.decrypt(data))
    }
    @action storeHomeCards(data) {
        this.homeCards = data
    }
    @action subMenu(data) {
        this.subMenuItem = '';
        if (data.children !== undefined) {
            this.subMenuItem = data;
            localStorage.setItem('619caams', cryptr.encrypt(JSON.stringify(this.subMenuItem)))
        } else {
            localStorage.removeItem('619caams')
            this.subMenuItem = '';
        }
        // console.log(window.location.pathname)
    }
}

export default new MenuStore();
