import React from "react";
import uuid from "uuid";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import _ from "lodash";
import DynamicFieldsType from "../../../../common/dynamicFields";
import AsyncSelect from "react-select/lib/Async";
import SelectSearch from "src/_components/common/_forms/SelectSearchMulti";
import { getValue, setValue } from "src/_components/common/lodsh";

const allocationDynamicForm = props => {
  // console.log(props.allocationForm)
  if(props.allocationForm)
  return props && props.allocationForm
    ? (props.allocationForm.map((form, index) => {

      let formFieldName = form.fieldName;
      let userPermission = props.userPermission;
      let permissions =
        form.fieldRolePermissions && form.fieldRolePermissions;
      let newPermissionArray =
        permissions && permissions.map(permsList => permsList.roleId.roleKey);
      let permissionIndex =
        newPermissionArray && newPermissionArray.indexOf(userPermission);
      let accessPermission = 2;
      if (permissionIndex >= 0) {
        accessPermission = parseInt(
          permissions && permissions[permissionIndex].permissionId
        );
      } else {
        accessPermission = 2;
      }
      switch (form.dataType) {
        case DynamicFieldsType.DATA_TYPE_CONNECTED_LIST:
          let NewformFieldName = form.columnName;
          if (accessPermission > 0 && form.activeStatus) {
            if (form.fieldLevel > 1) {
              let selectedDropDown = "";
              formFieldName = _.toUpper(NewformFieldName);
              let originalField = _.startCase(_.camelCase(form.fieldName));
              return (
                <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                  <div className="form-group mb-0 custom labelup" key={uuid.v4()}>
                    <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle bg-white table-btn"
                        data-toggle="dropdown"
                        disabled={accessPermission === 1}
                      >
                        {props[formFieldName + props.rowIndex] &&
                          props[formFieldName + props.rowIndex].forEach(
                            element => {
                              if (
                                props[originalField + props.rowIndex] ===
                                element.id
                              ) {
                                selectedDropDown = element.itemName;
                              }
                            }
                          )}
                        {selectedDropDown ? selectedDropDown : "Select an option"}
                      </button>
                      <div className="dropdown-menu">
                        {props[formFieldName + props.rowIndex] &&
                          props[formFieldName + props.rowIndex].map(opt => {
                            return (
                              <button
                                type="button"
                                onClick={e =>
                                  props.onConnectedListkHandler(
                                    originalField + props.rowIndex,
                                    form.multiSelectParentField,
                                    opt.id,
                                    form.fieldLevel,
                                    props.rowIndex
                                  )
                                }
                                className="dropdown-item"
                                key={opt.id}
                              >
                                {opt.itemName}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {form.isMandatory ? (
                    <div className="text-danger fs-12">
                      {props.validator.message(
                        form.labelName,
                        props[form.fieldName + props.rowIndex],
                        "required|string"
                      )}
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              );
            } else {
              let selectedDropDown = "";
              return (

                <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                  <div className="form-group custom mb-0 labelup" key={uuid.v4()}>
                    <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                    <div className="dropdown" key={uuid.v4()}>
                      {form.connectedListData &&
                        (getValue(form,"connectedListData.listManagementData",[])).forEach(
                          element => {
                            if (props[formFieldName + props.rowIndex] === element.id) {
                              selectedDropDown = element.itemName;
                            }
                          }
                        )}
                      {}
                      {form.connectedListData && form.connectedListId &&

                        <SelectSearch
                          options={form.connectedListData.listManagementData}
                          keyName={"itemName"}
                          refId={form.connectedListId}
                          assignKey={`${props.rowIndex}-${index}`}
                          page={form.page}
                          form={form}
                          rowIndex={props.rowIndex}
                          handleSearch={props.handleSearchManage}
                          count={form.connectedListDataCount}
                          handleScroll={props.handleScroll}
                          onClickHandler={props.onClickHandler}

                          loading={getValue(props.loading,[`${props.rowIndex}-${index}`],false)}
                          value={getValue(props.search,[`${props.rowIndex}-${index}`],"")}

                          placeholder={selectedDropDown ? selectedDropDown : form.fieldItemName?form.fieldItemName: "Select an option"}
                          disabled={
                            accessPermission === 1 ||
                            !(props.isEdit || form.columnName !== "GROUP_NAME")
                          }
                        />
                      }

                    </div>
                  </div>
                  {form.isMandatory ? (
                    <div className="text-danger fs-12">
                      {props.validator.message(
                        form.labelName,
                        props[form.fieldName + props.rowIndex],
                        "required|string"
                      )}
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              );
            }
          } break;
        case DynamicFieldsType.DATA_TYPE_TEXT:
          if (accessPermission > 0 && form.activeStatus) {
            return (
              <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                <div className="form-group mb-0 custom">
                  <input
                    type="text"
                    placeholder="&nbsp;"
                    readOnly={accessPermission === 1}
                    name={form.fieldName + props.rowIndex}
                    onChange={e =>
                      props.onChangeHandler(
                        e,
                        form.fieldName + props.rowIndex
                      )
                    }
                    value={
                      props[form.fieldName + props.rowIndex]
                        ? props[form.fieldName + props.rowIndex]
                        : ""
                    }
                    className="form-control"
                  />
                  <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                </div>
                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName + props.rowIndex],
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          } break;
        case DynamicFieldsType.DATA_TYPE_DATE:
          if (accessPermission > 0 && form.activeStatus) {
            return (
              <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                <div className="form-group mb-0 custom labelup">
                  <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                  <DayPickerInput
                    format="DD MMM YYYY"
                    formatDate={formatDate}
                    inputProps={{ disabled: accessPermission === 1 }}
                    placeholder={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex]
                      : "Select Date"}
                    dayPickerProps={{
                      locale: "en-gb",
                      localeUtils: MomentLocaleUtils,
                      disabledDays: { after: new Date() }
                    }}
                    selectedDays={props[form.fieldName + props.rowIndex] ? props[form.fieldName + props.rowIndex] : new Date()}
                    onDayChange={e =>
                      props.onDatePickerHandler(
                        e,
                        form.fieldName + props.rowIndex
                      )
                    }
                  />
                </div>
                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName + props.rowIndex],
                      "required"
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          } break;
        case DynamicFieldsType.DATA_TYPE_TEXTAREA:
          if (accessPermission > 0 && form.activeStatus) {
            return (
              <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                <div className="form-group mb-0 custom">
                  <textarea
                    rows="3"
                    name={form.fieldName + props.rowIndex}
                    className="form-control"
                    readOnly={accessPermission === 1}
                    onChange={e =>
                      props.onChangeHandler(
                        e,
                        form.fieldName + props.rowIndex
                      )
                    }
                    value={
                      props[form.fieldName + props.rowIndex]
                        ? props[form.fieldName + props.rowIndex]
                        : ""
                    }
                    placeholder="&nbsp;"
                  />
                  <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                </div>
                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName + props.rowIndex],
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          } break;
        case DynamicFieldsType.DATA_TYPE_CHECKBOX:
          if (accessPermission > 0 && form.activeStatus) {
            return (
              <div className="col-md-4 my-3">
                <div key={form.id + props.rowIndex} className="">
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={accessPermission === 1}
                      value={
                        props[form.fieldName + props.rowIndex]
                          ? props[form.fieldName + props.rowIndex]
                          : false
                      }
                      checked={
                        props[form.fieldName + props.rowIndex]
                          ? props[form.fieldName + props.rowIndex]
                          : false
                      }
                      onChange={e =>
                        props.onCheckboxHandler(
                          form.fieldName + props.rowIndex
                        )
                      }
                    />
                    <span className="slider round" />
                  </label>
                  <span>{form.labelName}</span>
                </div>
                <div className="text-danger fs-12">
                  {props.error[form.fieldName + props.rowIndex]}
                </div>
              </div>
            );
          } break;
        case DynamicFieldsType.DATA_TYPE_BOOLEAN:
          if (accessPermission > 0 && form.activeStatus) {
            return (
              <div className="col-md-4 my-3">
                <div key={form.id + props.rowIndex}>
                  <label className="switch">
                    <input
                      disabled={accessPermission === 1}
                      type="checkbox"
                      value={
                        props[form.fieldName + props.rowIndex]
                          ? props[form.fieldName + props.rowIndex]
                          : false
                      }
                      checked={
                        props[form.fieldName + props.rowIndex]
                          ? props[form.fieldName + props.rowIndex]
                          : false
                      }
                      onChange={e =>
                        props.onCheckboxHandler(
                          form.fieldName + props.rowIndex
                        )
                      }
                    />
                    <span className="slider round" />
                  </label>
                  <span>{form.labelName}</span>
                  <div className="text-danger fs-12">
                    {props.error[form.fieldName + props.rowIndex]}
                  </div>
                </div>
              </div>
            );
          } break;
        case DynamicFieldsType.DATA_TYPE_INTEGER:
          if (accessPermission > 0 && form.activeStatus) {
            return (
              <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                <div className="form-group mb-0 custom">
                  <input
                    type="text"
                    placeholder="&nbsp;"
                    readOnly={accessPermission === 1}
                    name={form.fieldName + props.rowIndex}
                    onChange={e =>
                      props.onIntegerHandler(
                        e,
                        form.fieldName + props.rowIndex
                      )
                    }
                    value={
                      props[form.fieldName + props.rowIndex]
                        ? props[form.fieldName + props.rowIndex]
                        : ""
                    }
                    className="form-control"
                  />
                  <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                </div>
                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName + props.rowIndex],
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          } break;
        case DynamicFieldsType.DATA_TYPE_AMOUNT:
          if (accessPermission > 0 && form.activeStatus) {
            return (
              <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                <div className="form-group mb-0 custom">
                  <input
                    type="text"
                    placeholder="&nbsp;"
                    readOnly={accessPermission === 1}
                    name={form.fieldName + props.rowIndex}
                    onChange={e =>
                      props.onIntegerHandler(
                        e,
                        form.fieldName + props.rowIndex
                      )
                    }
                    value={
                      props[form.fieldName + props.rowIndex]
                        ? props[form.fieldName + props.rowIndex]
                        : ""
                    }
                    className="form-control"
                  />
                  <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                </div>
                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName + props.rowIndex],
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          } break;
        case DynamicFieldsType.DATA_TYPE_EMAIL:
          if (accessPermission > 0 && form.activeStatus) {
            return (
              <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                <div className="form-group mb-0 custom">
                  <input
                    type="text"
                    placeholder="&nbsp;"
                    readOnly={accessPermission === 1}
                    name={form.fieldName + props.rowIndex}
                    onChange={e =>
                      props.onChangeHandler(
                        e,
                        form.fieldName + props.rowIndex
                      )
                    }
                    value={
                      props[form.fieldName + props.rowIndex]
                        ? props[form.fieldName + props.rowIndex]
                        : ""
                    }
                    className="form-control"
                  />
                  <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                </div>
                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName],
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          } break;
        case DynamicFieldsType.DATA_TYPE_SEARCH:
          if (accessPermission > 0 && form.activeStatus) {
            return (
              <div key={form.id} className="col-md-4 my-3">
                <div className="form-group labelup custom mb-0">
                  <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                  <AsyncSelect
                    noOptionsMessage={({ inputValue }) =>
                      inputValue ? "No results." : "Start typing to search..."
                    }
                    placeholder={"Search and select"}
                    loadOptions={props.loadOptions}
                    value={
                      props[form.fieldName + props.rowIndex]
                        ? props[form.fieldName + props.rowIndex]
                        : form.labelName
                    }
                    onChange={event =>
                      props.onLocationChangekHandler(
                        event,
                        form.fieldName + props.rowIndex
                      )
                    }
                  />
                </div>
                {form.isMandatory ? (
                  <div className="text-danger fs-12">
                    {props.validator.message(
                      form.labelName,
                      props[form.fieldName + props.rowIndex]?.value,
                      `required`
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            );
          } break;

        case DynamicFieldsType.DATA_TYPE_SYSTEM_LIST:
          if (accessPermission > 0 && form.activeStatus) {
            let selectedDropDown = "";
            if (form.columnName === "PAYMENT_TYPE") {
              return (
                <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                  <div className="from-group mb-0 custom labelup" key={uuid.v4()}>
                    <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle bg-white table-btn"
                        data-toggle="dropdown"
                        disabled={accessPermission === 1}
                      >
                        {form.paymentDetails &&
                          form.paymentDetails.forEach(element => {
                            if (
                              props[form.fieldName + props.rowIndex] ===
                              element.id
                            ) {
                              selectedDropDown = element.description;
                            }
                          })}
                        {selectedDropDown ? selectedDropDown : "Select an option"}
                      </button>
                      <div className="dropdown-menu">
                        {form.paymentDetails &&
                          form.paymentDetails.map(opt => {
                            return (
                              <button
                                type="button"
                                onClick={e =>
                                  props.onClickHandler(
                                    form.fieldName + props.rowIndex,
                                    form.id,
                                    opt.id,
                                    form.fieldLevel,
                                    props.rowIndex
                                  )
                                }
                                className="dropdown-item"
                                key={opt.id}
                              >
                                {opt.description}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {form.isMandatory ? (
                    <div className="text-danger fs-12">
                      {props.validator.message(
                        form.labelName,
                        props[form.fieldName + props.rowIndex],
                        "required|string"
                      )}
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              );
            } else {
              return (
                <div key={form.id + props.rowIndex} className="col-md-4 my-3">
                  <div className="form-group mb-0 custom labelup" key={uuid.v4()}>
                    <label>{form.labelName} {form.isMandatory && <span className="text-danger">*</span>} </label>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle bg-white table-btn"
                        data-toggle="dropdown"
                        disabled={accessPermission === 1}
                      >
                        {form.connectedListData &&
                          (getValue(form,"connectedListData.listManagementData",[])).forEach(
                            element => {
                              if (
                                props[form.fieldName + props.rowIndex] ===
                                element.id
                              ) {
                                selectedDropDown = element.itemName;
                              }
                            }
                          )}
                        {selectedDropDown ? selectedDropDown : "Select an option"}
                      </button>
                      <div className="dropdown-menu">
                        {form.connectedListData &&
                          form.connectedListData.listManagementData.map(
                            opt => {
                              return (
                                <button
                                  type="button"
                                  onClick={e =>
                                    props.onClickHandler(
                                      form.fieldName + props.rowIndex,
                                      form.id,
                                      opt.id,
                                      form.fieldLevel,
                                      props.rowIndex
                                    )
                                  }
                                  className="dropdown-item"
                                  key={opt.id}
                                >
                                  {opt.itemName}
                                </button>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                  {form.isMandatory ? (
                    <div className="text-danger fs-12">
                      {props.validator.message(
                        form.labelName,
                        props[form.fieldName + props.rowIndex],
                        "required|string"
                      )}
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              );
            }
          } break;
        default:
          return null;
      }
    }))
    : "";
};

export default allocationDynamicForm;
