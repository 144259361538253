import React from "react";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import ConfigRulesMenu from "../config-rules-menu";
import Select from 'react-select';
import { Input } from 'src/_components/common/_forms';

const CarConfigStep4Page = props => {
  const { form4Data, handleChange, saveChanges, setCurrentEdit, formValidator4 } = props;
  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">A</div>
          <div className="right">
            <h6 className="heading">Add Car Configuration</h6>
            <p className="text">This page is used to maintain mileage amount defined by the company policy.</p>
          </div>
        </div>
      </div>
      <div className="py-padding">
        <div className="table-container">
          <form>
            <ConfigRulesMenu />
            <div className="row">
              <div className="col-md-6">
                {/* <div className="btn-group-margin">
                  <button
                    type="button"
                    className="btn   sm-padding"
                    data-toggle="modal"
                    data-target="#AddTemplateModal"
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    className="btn   sm-padding"
                  >
                    Remove
                  </button>
                </div> */}
              </div>
              <div className="col-md-4 text-right" />
              <div className="col-md-2">
                <div className="search-custom">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    name="search"
                    autoComplete="off"
                  />
                  <span>
                    <i className="material-icons">search</i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Vehical Type</th>
                  <th>Rate 1 TO {form4Data.distanceLimit1}</th>
                  <th>Rate {form4Data.distanceLimit1 + 1} TO {form4Data.distanceLimit2}</th>
                  <th>Rate {form4Data.distanceLimit2 + 1} TO {form4Data.distanceLimit3}</th>
                  <th>Rate Over {form4Data.distanceLimit3}</th>
                  <th>Personal Rate(Company car only)</th>
                  <th>Rate per passenger</th>
                  <th>Action</th>

                  {/* <th>Status</th> */}
                </tr>
              </thead>

              <tbody>
                {form4Data && form4Data.vehicleRates && form4Data.vehicleRates.map((vehicle, i) => {
                  return (
                    (vehicle.vehicleStatus) &&
                    <tr key={vehicle.vehicleId}>
                      <td>{vehicle.vehicle}</td>
                      <td>{form4Data.currency} {vehicle.field1} </td>
                      <td>{form4Data.currency} {vehicle.field2} </td>
                      <td>{form4Data.currency} {vehicle.field3} </td>
                      <td>{form4Data.currency} {vehicle.field4} </td>
                      <td>{form4Data.currency} {vehicle.personalRate}</td>
                      <td>{form4Data.currency} {vehicle.ratePerPassenger}</td>
                      <td>
                        <button type="button" data-toggle="modal"
                          data-target="#AddTemplateModal" className="btn"
                          onClick={(e) => setCurrentEdit(vehicle)}
                        >Edit</button></td>
                      <td></td>
                      <td>
                        {/* {vehicle.vehicleStatus ? "Active" : "Inactive"} */}
                        {/* <label className="switch">
                          <input type="checkbox"
                            checked={vehicle.vehicleStatus}
                          />
                          <span className="slider round" />
                        </label> */}
                      </td>
                    </tr>

                  )
                })}
                {/* <tr>
                  <td>SUA</td>
                  <td>01/01/2019</td>
                  <td>31/12/2019</td>
                  <td>0.40 USD</td>
                  <td>0.40 USD</td>
                  <td>0.40 USD</td>
                  <td>0.40 USD</td>
                  <td>0.40 USD</td>
                  <td>0.40 USD</td>
                </tr>
                */}
              </tbody>
            </table>
          </div>
          <div className="p-padding">
            <div className="btn-group-margin">
              <NavLink
                to="/administration/expense/car-config/car-configuration"
                className="btn btn-border-basic lg-padding"
              >
                Save
              </NavLink>
              <NavLink
                to="/administration/expense/car-config/car-configuration"
                className="btn btn-border-basic lg-padding"
              >
                Cancel
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddTemplateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title float-left" id="AddTemplateModalLabel">
                Edit Vehicle
                </h5>
              {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> */}
            </div>
            <div className="modal-middle pm-30">
              {/* <div className="form-group custom">
                <Input
                  label="Vehicle Type"
                  type="text"
                  name="vehicleType"
                  value={props.vehicleType}
                  controlFunc={(e) => handleChange(e)}
                />
              </div> */}
              <div className="form-group custom">
                <Input
                  label={`Rate 1 TO ${form4Data.distanceLimit1}`}
                  type="text"
                  name="field1"
                  value={props.state.field1}
                  controlFunc={(e) => handleChange(e)}
                  error={formValidator4.message('Field1', props.state.field1, 'required|numeric')}
                />
              </div>
              <div className="form-group custom">
                <Input
                  label={`Rate ${form4Data.distanceLimit1 + 1} TO ${form4Data.distanceLimit2}`}
                  type="text"
                  name="field2"
                  value={props.state.field2}
                  controlFunc={(e) => handleChange(e)}
                  error={formValidator4.message('Field2', props.state.field2, 'required|numeric')}
                />
              </div>
              <div className="form-group custom">
                <Input
                  label={`Rate ${form4Data.distanceLimit2 + 1} TO ${form4Data.distanceLimit3}`}
                  type="text"
                  name="field3"
                  value={props.state.field3}
                  controlFunc={(e) => handleChange(e)}
                  error={formValidator4.message('Field3', props.state.field3, 'required|numeric')}
                />
              </div>
              <div className="form-group custom">
                <Input
                  label={`Rate Over ${form4Data.distanceLimit3}`}
                  type="text"
                  name="field4"
                  value={props.state.field4}
                  controlFunc={(e) => handleChange(e)}
                  error={formValidator4.message('Field4', props.state.field4, 'required|numeric')}
                />
              </div>
              <div className="form-group custom">
                <Input
                  label={"Personal Rate (Company car only)"}
                  type="text"
                  name="personalRate"
                  value={props.state.personalRate}
                  controlFunc={(e) => handleChange(e)}
                  error={formValidator4.message('personalRate', props.state.personalRate, 'required|numeric')}
                />
              </div>
              <div className="form-group custom">
                <Input
                  label={"Rate per passenger"}
                  type="text"
                  name="ratePerPassenger"
                  value={props.state.ratePerPassenger}
                  controlFunc={(e) => handleChange(e)}
                  error={formValidator4.message('ratePerPassenger', props.state.ratePerPassenger, 'required|numeric')}
                />
              </div>


              <div className="modal-last btn-group-margin">
                <button
                  type="button"
                  className="btn btn-border-success lg-padding"
                  data-dismiss={formValidator4.allValid() ? "modal" : ''}
                  onClick={(e) => saveChanges(e)}
                >
                  Save
                  </button>
                <button
                  type="button"
                  className="btn btn-border-basic lg-padding"
                  data-dismiss="modal"
                >
                  Cancel
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
export default CarConfigStep4Page;
