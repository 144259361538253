import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { cartServices } from "src/_services";
import { withRouter } from 'react-router-dom';
import { toast } from "react-toastify";
import $ from 'jquery';
import NumberFormat from 'react-number-format';
import cryptr from "../../../../_helpers/crypty";

export const MenuCartSubPage = props => {
  const currentLocation = props.pathName ? props.pathName.split('/') : [];
  let currentPath = currentLocation.length > 0 && currentLocation[currentLocation.length - 1];
  const { cartList, currency, checkItem, next, submit, submitFinal, loading,isRequired } = props;
  let item = JSON.parse(cryptr.decrypt(localStorage['1UC43']))
  let currencyType = item.currencyCode;
  return (
    <div className="CheckoutPageTemp">
      <div className="full-details dark">
        <div className="left">
          <NavLink to="/booking/checkout/itinerary" className="details links">
            <span>
              <b>1</b> -
              {/* <i className="material-icons">
                shopping_basket
              </i> */}
            </span>
            <span>Itinerary</span>
          </NavLink>
          <NavLink
            to={(cartList.totalPrice === 0) ? "#" : "/booking/checkout/travel-request-details"}
            className="details links"
          >
            <span>
              <b>2</b> -
              {/* <i className="material-icons">
                assignment_turned_in
              </i> */}
            </span>
            <span>T<span className="hide-t mr-0">ravel</span> R<span className="hide-t">equest</span> D<span className="hide-t">etails</span></span>
          </NavLink>
          {props.allowToExpenseTab ?
            <NavLink
              to={(cartList.totalPrice === 0) ? "#" : "/booking/checkout/expense"}
              // onClick={e => checkItem()}
              className="details links"
            >
              <span>
                <b>3</b> -
              {/* <i className="material-icons">
                credit_card
              </i> */}
              </span>
              <span>Expense</span>
            </NavLink> :
            <button
              className="details links"
            >
              <span>
                <b>3</b> -
              </span>
              <span>Expense</span>
            </button>
          }
          {currentPath === 'expense' &&
            <button className="details links cart-10 border-left-0 not-subm trip-id-css bg-transparent">
              <p className="op-less mb-0 fs-12 text-left">Trip Id</p>
              <p className="op-less mb-0 text-left">{sessionStorage.TRIPID}</p>
            </button>
          }
        </div>
        <div className="right">
          <button className="details links cart-50 border-left-0 not-subm">
            <i className="material-icons align-sub fs-16 mr-2">shopping_cart</i>{" "}
            <span className="fw-sbold align-middle">{currencyType} {" "}
              <NumberFormat value={cartList && cartList.totalPrice && (cartList.totalPrice).toFixed(2)} displayType={'text'} thousandSeparator={true} />
              {/* {cartList && cartList.totalPrice && (cartList.totalPrice).toFixed(2)} */}
            </span>
          </button>
          {/* {(cartList.totalPrice !== 0) && currentPath == 'expense' ? */}
          { currentPath == 'expense' ?
            <button
              data-target="#submitModal"
              data-toggle="modal"
              className="details links cart-50 bg-success text-white border-left-0 border-right-0"
            >
              <span className="pt-0">Submit</span>
            </button>
            : props.nextButtonVisibility ?
              <NavLink className="details links cart-50 border-right-0 bg-primary text-white fw-sbold" to={next}>Next</NavLink> :
              <button className="details links cart-50 border-right-0 bg-primary text-white fw-sbold op-less-sm" disabled>Next</button>
          }
        </div>
        <div className="clearfix" />
      </div>
      <div
        className="modal fade"
        id="submitModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="submitModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close text-white d-none"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <div className="text-center pt-5">
                <img src="/assets/images/confirmation_icon.svg" alt="" />
                <h3 className=" mt-4 text-dark">Are You Sure?</h3>
                <p>By clicking on submit, you agree to the fare<br />rules and restrictions.</p>
              </div>
            </div>
            {!loading &&
              <div className="btn-group-margin text-center pb-5">
                <button
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  onClick={e => submitFinal()}
                  className="btn"
                >
                  Submit
                </button>
              </div>}
            {loading &&
              <div className="modal-footer text-center">
                <button
                  className="btn"
                >
                  Please Wait...
                </button>
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(MenuCartSubPage);
