import React from 'react';
import Menu from '../menu';
import { NavLink } from "react-router-dom";
const EmployeeRelateConfigPage = (props) => {
    let data = props;
    return (
        <div>
            <div className="admin-tabs">
                <Menu />
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">W</div>
                        <div className="right">
                            <h6 className="heading">Employee Related Configurations</h6>
                            <p className="text">Lorem Ipsum is simply dummy text.</p>
                        </div>
                    </div>
                </div>
                <div className="py-padding">
                    <div className="table-container">
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="btn-group-margin">
                                        <NavLink to="/administration/expense/add-employee-relate-config" className="btn   sm-padding">New</NavLink>
                                        <button type="button" className="btn   sm-padding disabled">Modify</button>
                                        <button type="button" className="btn   sm-padding disabled">Remove</button>
                                        <button type="button" className="btn   sm-padding" data-toggle="modal" data-target="#AddTemplateModal">Assign</button>
                                    </div>
                                </div>
                                <div className="col-md-2 offset-4">
                                    <div className="search-custom">
                                        <input type="text" name="" className="form-control" placeholder="Search" />
                                        <span><i className="material-icons">search</i></span>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Configuration Name</th>
                                        <th>Tax Data on User form</th>
                                        <th>Receipt status options</th>
                                        <th>Receipt status default options</th>
                                        <th>Only calculation domestic</th>
                                        <th>Extraction Factors</th>
                                        <th>Copy Company card location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Some config</td>
                                        <td>Read Only</td>
                                        <td>2-No reciept</td>
                                        <td>2-No reciept</td>
                                        <td>
                                            <label className="switch">
                                                <input type="checkbox" checked />
                                                <span className="slider round"></span>
                                            </label>
                                        </td>
                                        <td>Not Used</td>
                                        <td>
                                            <label className="switch">
                                                <input type="checkbox" checked />
                                                <span className="slider round"></span>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="AddTemplateModal" tabIndex="-1" role="dialog"
                aria-labelledby="AddTemplateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header pl-4">
                            <h5 className="modal-title" id="AddTemplateModalLabel">Tax Organizations for Configuration</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body py-0 px-3 border">
                            <div className="row">
                                <div className="col-md-6 border bg-light">
                                    <div className="btn-group-margin mt-3 ml-2">
                                        <button type="button" className="btn   sm-padding">New</button>
                                        <button type="button" className="btn   sm-padding disabled">Remove</button>
                                    </div>
                                    <div className="table-container mt-4">
                                        <div className="row">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Country</th>
                                                            <th>Organization</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="bg-white">
                                                            <td>
                                                                <button type="button" className="btn border padding-0" >United States</button>
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn border padding-0" >United States</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-4">
                                    <div className="form-group custom labelup">
                                        <label>Country</label>
                                        <div className="dropdown">
                                            <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                                United States</button>
                                            <div className="dropdown-menu">
                                                <button type="button" className="dropdown-item">Action 1</button>
                                                <button type="button" className="dropdown-item">Action 1</button>
                                                <button type="button" className="dropdown-item">Action 1</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group custom">
                                        <input type="text" placeholder="&nbsp;" className="form-control" />
                                        <label>Org Unit-1 Division</label>
                                    </div>
                                    <div className="form-group custom">
                                        <input type="text" placeholder="&nbsp;" className="form-control" />
                                        <label>Org Unit-2 Department</label>
                                    </div>
                                    <div className="form-group custom">
                                        <input type="text" placeholder="&nbsp;" className="form-control" />
                                        <label>Org Unit-3 Region</label>
                                    </div>
                                    <div className="form-group custom">
                                        <input type="text" placeholder="&nbsp;" className="form-control" />
                                        <label>Org Unit-4</label>
                                    </div>
                                    <div className="form-group custom">
                                        <input type="text" placeholder="&nbsp;" className="form-control" />
                                        <label>Org Unit-5</label>
                                    </div>
                                    <div className="btn-group-margin text-right">
                                        <button type="button" className="btn btn-border-success lg-padding">Assign</button>
                                        <button type="button" className="btn btn-border-basic lg-padding"
                                            data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default EmployeeRelateConfigPage;