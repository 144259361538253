import React from "react";
import Pagination from "react-js-pagination";
import DropdownButton from "./components/DropDownButton";
import Menu from "../Menu";
import { Input } from "../../../../../common/_forms";
import SelectNew from 'react-select';
import StaticMenu from "../staticMenu";
import Loader from "src/_components/common/Loader";
const FormsPage = props => {
  const {
    fullFormData,
    formNameHandler,
    handleSubmit,
    handlePageChange,
    handleItemChange,
    handleStatusChange,
    handleHeaderButtonClick,
    editFormName,
    validator,
    updateForm,
    currentModule,
    reset,
    changeModule,
    listService,
    selectModifyForm,
    duplicateFormHandler,
    handleGroupChange,
    handleGroupSubmit
  } = props;

  // * destructure from state
  const {
    allGroups,
    allGroupsList,
    loading,
    loadingCreate
  } = props.fullFormData;

  let allGroupsOptions = allGroupsList && allGroupsList.map(item => {
    return {
      value: item.id,
      label: item.itemName
    }
  })
  let activeStyle = ["active-tr cursor-pointer"];
  let isItemSelected =
    fullFormData.selectedFormHeader && fullFormData.selectedFormHeader.length;
  let removeButtonStyle = ["btn  "];
  if (isItemSelected) {
    removeButtonStyle.push("disabled");
    removeButtonStyle.push("daisabled");
  }

  // * get groups names from id
  const getGroupNamesFromId = (groupIds) => {
    let groupNames = groupIds.map(id => {

      const matchedname = allGroupsOptions.find(option => option.value == id);
      if (matchedname) {
        return matchedname.label
      }
    });
    if (groupNames.length) {

      return groupNames.join(' ');
    } else {
      return 'Global';
    }
  }

  const getSelectedOptions = (selectedForm) => {
    let res = '';
    if (selectedForm.length > 0) {

      res = allGroupsOptions.filter(item => allGroups.includes(item.value));
    }
    return res;
  }

  // * modifyForm shows the rows with the items

  let modifyForm =
    fullFormData.forms &&
    fullFormData.forms.map((form, index) => {
      return (
        <tr
          key={index}
          className={
            fullFormData.selectedForm.some(i => i.id === form.id)
              ? activeStyle
              : "cursor-pointer"
          }
          onClick={() => selectModifyForm(form)}
        >
          <td>
            <span className="add-minus" />
            {form.formName}
          </td>
          {
            fullFormData.isGroupRequired ? (
              <td>
                <div>
                  {form.formAccess ? getGroupNamesFromId(form.formAccess) : ''}{" "}{" "}
                  <button
                    className="btn btn-nothing hover-v ml-2 hover-opacity"
                    data-target="#modifyGroupModal"
                    data-toggle="modal"
                    data >
                    <i className="material-icons fs-18">
                      edit
          </i>
                    <span className="hover-name right-top">Edit</span>
                  </button></div>
              </td>

            ) : null
          }

          <td>
            <button
              onClick={editFormName.bind(this, form)}
              data-target="#editForm"
              data-toggle="modal"
              className="btn btn-nothing hover-v"
            >
              <i className="material-icons fs-20">
                edit
          </i>
              <span className="hover-name right-top">Edit</span>
            </button>
          </td>
          <td>
            <p className="td-padding">
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={e => handleStatusChange(form)}
                  checked={form.activeStatus}
                  value={form.activeStatus}
                />
                <span className="slider round" />
              </label>
              {/* <span>{form.activeStatus ? "Active" : "Inactive"}</span> */}
            </p>
          </td>
        </tr>
      );
    });
  return (
    <div>
      <div className="admin-tabs">
        <Menu
          changeModule={changeModule}
          currentModule={currentModule}
          listService={listService}
        />
      </div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">F</div>
          <div className="right">
            <h6 className="heading">Forms and Fields</h6>
            <p className="text">The Forms and Fields feature allows administrators to manage forms and fields used in Expense, Travel, and Request.</p>
          </div>
          <div className="clearfix" />
        </div>
      </div>
      <div className="p-padding pb-0">
        <div className="form-holder">
          <div className="row">
            <div className="col-4">
              <div className="form-group custom labelup mb-0">
                <label>Form Type</label>
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn dropdown-toggle btn-block"
                    data-toggle="dropdown"
                  >
                    {fullFormData.selectedFormHeader
                      ? fullFormData.selectedFormHeader
                      : "Select Form Type"}
                  </button>
                  <div className="dropdown-menu">
                    {fullFormData.formHeaderName
                      ? fullFormData.formHeaderName.map((formhead, key) => {
                        return (
                          <DropdownButton
                            handleHeaderButtonClick={handleHeaderButtonClick}
                            key={key}
                            id={formhead.id}
                            name={formhead.formHeaderName}
                          />
                        );
                      })
                      : "No Headers Found"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-padding">
        <div className="admin-tabs px-padding">
          <StaticMenu />
        </div>
        <div className="table-container">
          {fullFormData.selectedFormHeader && (
            <>
              <form>
                <div className="row">
                  <div className="col-sm-9">
                    <button
                      disabled={!fullFormData.selectedForm.length}
                      className="btn"
                      onClick={(e) => duplicateFormHandler(e)}

                    >
                      Duplicate
                  </button>
                  </div>
                  <div className="col-sm-3">
                    <div className="search-custom">
                      <input
                        type="text"
                        name=""
                        className="form-control"
                        placeholder="Search"
                        disabled={
                          fullFormData.selectedFormHeader ? false : true
                        }
                      />
                      <span>
                        <i className="material-icons">search</i>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive" display="none">
                <table className="table table-striped table-selected">
                  <thead>
                    <tr>
                      <th>Form Name</th>
                      {fullFormData.isGroupRequired ? <th>Access</th> : null}
                      <th>Action</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(!modifyForm) ?
                      <tr>
                        <td colSpan="4" className="text-center">
                          {(loading) ?
                            <Loader /> : "No records found!"}
                        </td>
                      </tr>
                      :
                      modifyForm
                    }
                  </tbody>
                </table>
              </div>
            </>
          )}
          <div className="clearfix"></div>
          <div className="pt-5">
            {(loading) ? <Loader /> : ""}
          </div>
          {(!loading) && fullFormData.forms && (
            <div className="table-footer">
              <div className="row">
                <div className="col-sm-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          {fullFormData.itemPerPage}
                        </button>
                        <div className="dropdown-menu">
                          <button
                            onClick={handleItemChange.bind(this, 10)}
                            className="dropdown-item"
                          >
                            10
                          </button>
                          <button
                            onClick={handleItemChange.bind(this, 20)}
                            className="dropdown-item"
                          >
                            20
                          </button>
                          <button
                            onClick={handleItemChange.bind(this, 30)}
                            className="dropdown-item"
                          >
                            30
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-9">
                  <Pagination
                    activePage={fullFormData.activePage}
                    itemsCountPerPage={fullFormData.itemsCountPerPage}
                    totalItemsCount={fullFormData.totalItemsCount}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modify Group Modal */}
      <div
        className="modal fade"
        id="modifyGroupModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <span data-dismiss="modal" className="close1"></span>
          <div className="modal-content border-0">
            <div className="modal-head">
              <h5 className="modal-title" id="AddTemplateModalLabel">
                Modify Groups
              </h5>
            </div>
            <form autoComplete="off">
              <div className="modal-middle pm-30">
                <div className="form-group custom textarea">
                  <SelectNew
                    value={fullFormData.selectedForm ? getSelectedOptions(fullFormData.selectedForm) : ""}
                    options={allGroupsOptions}
                    onChange={handleGroupChange}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
                <div className="modal-last">
                  {!loadingCreate ? (
                    <div className="btn-group-margin">
                      <button
                        type="submit"
                        className="btn"
                        onClick={handleGroupSubmit}
                        data-dismiss={
                          fullFormData.createdForm === true ? "modal" : ""
                        }
                      >
                        {" "}
                        Save
                        </button>
                      &nbsp;
                        <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                        onClick={e => reset()}
                      >
                        {" "}
                        Cancel
                        </button>
                    </div>
                  ) : (
                      <button
                        type="button"
                        className="btn"
                      >
                        {" "}
                        Please Wait...{" "}
                      </button>
                    )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* End of Modify Group Modal */}


      {/* --------------------------ADD FORM MODEL---------------------------------- */}

      <div
        className="modal fade"
        id="newForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AddTemplateModalLabel">
                New Form
              </h5>
              <button
                onClick={e => reset()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form autoComplete="off">
              <div className="modal-body">
                <div className="form-group custom">
                  <Input
                    label="Form Name"
                    type="text"
                    name="formName"
                    className="bg-white"
                    error={validator.message(
                      "formName",
                      fullFormData.formName,
                      "required"
                    )}
                    value={fullFormData.formName}
                    controlFunc={e => formNameHandler(e)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                {!loadingCreate ? (
                  <div>
                    <button
                      type="submit"
                      className="btn"
                      onClick={handleSubmit}
                      data-dismiss={
                        fullFormData.createdForm === true ? "modal" : ""
                      }
                    >
                      {" "}
                      Save
                      </button>
                    &nbsp;
                      <button
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                      onClick={e => reset()}
                    >
                      {" "}
                      Cancel
                      </button>
                  </div>
                ) : (
                    <button
                      type="button"
                      className="btn"
                    >
                      {" "}
                      Please Wait...{" "}
                    </button>
                  )}
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* --------------------------EDIT FORM MODEL---------------------------------- */}

      <div
        className="modal fade"
        id="editForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-head">
              <h5 className="modal-title" id="AddTemplateModalLabel">
                Edit Form
              </h5>
              <span className="close" data-dismiss="modal"></span>
            </div>
            <form>
              <div className="modal-middle pm-30">
                <div className="form-group custom labelup">
                  <Input
                    label="Form Name"
                    type="text"
                    name="formName"
                    className="bg-white"
                    error={validator.message(
                      "formName",
                      fullFormData.formName,
                      "required"
                    )}
                    value={fullFormData.formName}
                    controlFunc={e => formNameHandler(e)}
                  />
                </div>
                {(loadingCreate) ?
                  <div className="modal-last btn-group-margin">
                    <button type="button" className="btn"> Please wait... </button>
                  </div>
                  :
                  <div className="modal-last btn-group-margin">
                    <button type="button" className="btn" onClick={updateForm}> Update </button>
                    <button type="button" onClick={e => reset()} className="btn close" data-dismiss="modal" >
                      Cancel
                    </button>
                  </div>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormsPage;
