/* eslint-disable default-case */
import React, { Component } from "react";
import TravelPolicyPage from "./travel-policy-html";
import { travelPolicyService } from "../../../../../../_services/travel-travel-policy.services";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../../_constants/alert.constants";
import { travelRuleClassService } from "src/_services/travel-rule-class.service";
import { workflowService } from "src/_services/workflow.service";
import { travelRuleTemplateServices } from "src/_services/travel-rule-template.services";
import travelFieldConstants from "src/_components/common/travelFieldConstants";
import { executePromisesConcurrently } from "src/utils/util";
import { policyViolationService } from "src/_services/policy-violation.services";
import SimpleReactValidator from 'simple-react-validator';
import $ from 'jquery';


class TravelPolicy extends Component {
  state = {
    companyId: localStorage["440a28"],
    // companyId: "5c2c891c30566e1f00b24552",

    allRules: [],
    selectedRuleClass: "",
    selectedCategory: {},
    addTemplateModal: false,
    allTemplatesInSelectedCategory: [],
    selectedRuleTemplates: [],
    availableRuleTemplates: [],
    policiesByCategory: [],
    allCategories: [],
    isCustomCondition: true,
    ruleTemplateName: '',
    violationCode: '',
    allConditions: '',
    anyCondition: '',
    availableRulesinCategory: [],
    selectedCheckboxes: [],
    fieldsToBeRendered: [],
    previouslySetLLF: [],
    ruleTemplateId: '',
    policyViolationsByRuleClass: [],
    selectedPolicyViolation: {},
    isLoading: true,
    isLoadingPop:false,
  };
  policyViolationsResponse = [];
  validator = new SimpleReactValidator();

   componentDidMount = async () =>  {
    window.scrollTo({ top: 0, behavior: "smooth" });
    await this.getAllCategories();
    await this.getAllRules();

    if (this.state.selectedRuleClass.length > 0) {
      // try {
      //   this.getPoliciesByCategory(
      //     this.state.companyId,
      //     this.state.selectedCategory,
      //     this.state.selectedRuleClass.id
      //   );
      // } catch (error) {
      //   console.log(error);
      // }
    }
    // alert('')
  }

  // * Get all Violations based on rule class

  getAllViolations = async (selectedRuleClass = {}) => {
    if(Object.keys(selectedRuleClass).length){
      let res = await policyViolationService.getPolicyViolationByRuleClass(this.state.companyId, selectedRuleClass.id);
      if(res != null && res.result.length){
        this.policyViolationsResponse = res.result;
        this.setState({
          policyViolationsByRuleClass: this.policyViolationsResponse.map(item => ({
            value: item.id,
            label: item.code + ' - ' + item.type
          }))
        });
      }
    }
  }

  // * get all rule template conditions based on template category
  getAllRuleConditionsBySelectedCategory = async (selectedCategory) => {
     let res =  await travelRuleTemplateServices.getTravelRulesByTemplateCategory(selectedCategory.id);
     if(res != null && res.result.length){
       this.setState({
        availableRulesinCategory: res.result
       })
     }
  }

  // * Get all categories like hotel, itinerary, flight etc

  getAllCategories = async() => {
    let res  = await travelRuleTemplateServices.getTemplateCategories();
    if(res != null && res.result){
      this.setState({
        allCategories: res.result,
        selectedCategory: res.result[0]
      });

    }
  }

  // for toggling modal on and off
  toggleAddTemplateModal = () => {
    this.setState(prevState => ({
      addTemplateModal: !prevState.addTemplateModal
    }));
    this.getAllRuleTemplates();
  };

  getAllRuleTemplates = async () => {
    if(this.state.selectedCategory && this.state.selectedRuleClass){
      let res = await travelRuleTemplateServices.getRuleTemplatesByCategories(this.state.companyId, this.state.selectedCategory.id,1,100,"");
      if(res && res.result.length){
        this.setState({
          availableRuleTemplates: res.result
        })
      }
    }
  }

  // get all rules from rule classes and set the selectedRuleClass = first element in the result array
  getAllRules = async () => {
    const res = await travelRuleClassService.getAllRuleClass(
      this.state.companyId
    );
    if(res){
      this.setState({
        allRules: res.result,
        selectedRuleClass: res.result[0]
      });
      await this.categoryClickHandler(this.state.selectedCategory);
      await this.getAllViolations(this.state.selectedRuleClass);
      await this.getPreviouslySetLLF(this.state.companyId);
    }
  };

  // select rule class
  ruleClassDropdownHandler = async (id, name, ruleClass) => {
    await this.setState({
      selectedRuleClass: ruleClass,
      selectedCategory: this.state.allCategories[0]
    });
    this.categoryClickHandler(this.state.selectedCategory)
    this.getAllViolations(this.state.selectedRuleClass);
  };

  // * handler for selected categories and then makes an api call for getting all rule templates according to the category
  categoryClickHandler = async category => {
    this.setState({isLoading: true});
    this.setState({selectedCategory: category});
    this.showCustomConditions()
    let res = await travelPolicyService.getAllRuleTemplatesByCategory(this.state.companyId, category.id, this.state.selectedRuleClass.id);
    if(res && res.result){
      this.setState({
        isLoading: false,
        allTemplatesInSelectedCategory: res.result,
      });
    }else{
      this.setState({
        allTemplatesInSelectedCategory: [],
        isLoading: false
      });
    }
  };

  ruleTemplateSelectHandler = ruleTemplate => {
    let selectedRuleTemplates = [...this.state.selectedRuleTemplates];
    let selectedIndex = selectedRuleTemplates.indexOf(ruleTemplate);
    if (selectedIndex === -1) {
      ruleTemplate.ruleClass = this.state.selectedRuleClass.id;
      ruleTemplate.activeStatus = true;
      selectedRuleTemplates.push(ruleTemplate);
    } else {
      selectedRuleTemplates.splice(selectedIndex, 1);
    }
    this.setState({
      selectedRuleTemplates
    });
  };

  addRulePolicyHandler = async e => {
    e.preventDefault();
    let payload = {
      companyId: this.state.companyId,
      travelCategoryId: this.state.selectedCategory.id,
      ruleClassId: this.state.selectedRuleClass.id,
      ruleTemplateId: this.state.selectedRuleTemplates.map(template => template.id)
    }
    let res = await  travelPolicyService.addRulePolicy(payload);
    if(res){
      this.setState({
        addTemplateModal: false,
        selectedRuleTemplates: []
      });
      this.categoryClickHandler(this.state.selectedCategory);
    }
  };


  showCustomConditions = () => {
    this.setState({isCustomCondition: true});
  }

  hideCustomConditions = () => {
    this.setState({isCustomCondition: false});
  }

  matchPolicyViolation = (policyViolationId) => {
    let selectedPolicyViolation = this.state.policyViolationsByRuleClass.find(policyViolation => policyViolation.value == policyViolationId);
    if(selectedPolicyViolation != null){
      this.setState({selectedPolicyViolation});
    }
  }

  onEditRuleTemplate = async (ruleTemplate) => {
    this.cancelModalHandler()
    this.setState({
      isLoadingPop: true,
      ruleTemplateName: ruleTemplate.ruleName,
      violationCode: ruleTemplate.violationCode,
      message:ruleTemplate.message,
      allConditions: ruleTemplate.isAllTrue ? true : false,
      anyCondition: ruleTemplate.isAllTrue ? false : true,
      ruleTemplateId: ruleTemplate.id,
      isEdit:true
    });
    // * for setting the value of policy valution in the edit mode
    this.matchPolicyViolation(ruleTemplate.policyViolationId);
    let res = await travelPolicyService.getPreviouslySetRuleTemplateDetails(ruleTemplate.id);
    if(res != null){
      // console.log(' the result received is ', res);
      await this.setState({
        isLoadingPop: false,
        availableRulesinCategory: res.result
      });
    }else{
      this.setState({
        availableRulesinCategory: [],
        isLoadingPop: false
      })
    }
    let alreadySetConditions = ruleTemplate.travelCategoryRule.map(item => item.travelCategoryRuleId);
    if(this.state.availableRulesinCategory){
      let matchedConditions = this.state.availableRulesinCategory.filter(item => alreadySetConditions.includes(item.id));

      executePromisesConcurrently(matchedConditions, (condition) =>
         this.conditionCheckHandler(condition, ruleTemplate), {concurrency: 1}
      )
    }
  }

  allCheckedConditionsHandler = (value) => {
    let newValue = !value;
    this.setState({allConditions: newValue, anyCondition: value})
  }

  anyCheckedConditionsHandler = (value) => {
    let newValue = !value;
    this.setState({anyCondition: newValue, allConditions: value});
  }

  ruleTemplateNameHandler = (e) => {
    this.setState({ruleTemplateName: e.target.value})
  }

  violationPolicyHandler = (selectedPolicyViolation) => {
    this.setState({selectedPolicyViolation: selectedPolicyViolation})
  }

  messagePolicyHandler = (e) =>{
    this.setState({message:e.target.value})
  }

  // for changing date
  fieldDateChangeHandler = (e,rule, fieldItem,type) => {
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let matchedRule = this.state.fieldsToBeRendered.find(item => item == rule);
    let matchedCondition = matchedRule.ruleCondition.find(item => item == fieldItem);
    matchedCondition.selectedValue = e;
    this.setState({fieldsToBeRendered});
  }

  conditionCheckHandler = async (condition, ruleTemplate = {}) => {
    let selectedCheckboxes = [...this.state.selectedCheckboxes];
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let index = selectedCheckboxes.indexOf(condition.id);
    if(index === -1){
    selectedCheckboxes.push(condition.id);
    if(condition.ruleCondition && condition.ruleCondition.length){
      condition.ruleCondition.forEach(item =>{
        if(item.dataTypeDetail.conditionID === "DIFFERENT-LIST"){
          let foundDifferentList =  item;
          item.displayType=item?.dataTypeValueDetail?.[0]?.conditionID;
          // if(foundDifferentList != null){
          //   this.setState({
          //     differentListId: foundDifferentList?.dataTypeValueDetail?.[0]?._id,
          //     differentListName: foundDifferentList?.dataTypeValueDetail?.[0]?.conditionID
          //   })
          // }
        }
      })
      fieldsToBeRendered.push(condition);
    }
    }else {
      // if checkbox already selected, then remove it and remove the fields from fieldsToBeRendered
      fieldsToBeRendered =  fieldsToBeRendered.filter(field => field.id !== condition.id)
      selectedCheckboxes.splice(index, 1);
    }
    await new Promise(resolve => this.setState({
      selectedCheckboxes,
      fieldsToBeRendered
    }, resolve));

    if(this.state.isEdit){
      this.populateSetFields(this.state.fieldsToBeRendered, ruleTemplate);
    }
  };

  // * For setting up the fields in the edit mode
  populateSetFields = (fieldsToBeRendered, ruleTemplate) => {
    if(ruleTemplate && ruleTemplate.travelCategoryRule){
      ruleTemplate.travelCategoryRule.forEach(rule => {
        let matchedField = fieldsToBeRendered.find(item => item.id == rule.travelCategoryRuleId);
        if(matchedField){
          rule.ruleCondition.forEach(cond => {
            let matchedCondition = matchedField.ruleCondition.find(field => field._id == cond.dataType)
            if(matchedCondition){
              switch(matchedCondition.dataTypeDetail.conditionID){
                case travelFieldConstants.DATA_TYPE_CONDITIONAL_DROP_DOWN:
                case travelFieldConstants.DATA_TYPE_CHEAPEST_DROPDOWN:
                case travelFieldConstants.DATA_TYPE_REFUNDABLE_NON_REFUNDABLE_DROP_DOWN:
                case travelFieldConstants.DATA_TYPE_AMOUNT_PERCENTAGE_DROP_DOWN:
                case travelFieldConstants.DATA_TYPE_CONDITIONAL_NUMBER:
                case travelFieldConstants.DATA_TYPE_MILE_KM_DROP_DOWN:
                  matchedCondition.selectedDropdown = matchedCondition.dataTypeDetail.conditionList.find(condition => condition._id == cond.fieldValue[0]);
                  break;
                case travelFieldConstants.DATA_TYPE_CONDITIONAL_DATE:
                case travelFieldConstants.DATA_TYPE_LIST_MANAGEMENT:
                case travelFieldConstants.DATA_TYPE_SUPER_ADMIN_LIST:
                  matchedCondition.selectedDropdown = matchedCondition.dataTypeValueDetail.find(condition => condition.id == cond.fieldValue[0]);
                  break;
                case travelFieldConstants.DATA_TYPE_AMOUNT :
                case travelFieldConstants.DATA_TYPE_NUMBER_OF_DAYS :
                case travelFieldConstants.DATA_TYPE_NUMBER :
                case travelFieldConstants.DATA_TYPE_TEXT:
                case travelFieldConstants.DATA_TYPE_NUMBER_OF_HOURS:
                  matchedCondition.selectedValue = cond.fieldValue[0];
                  break;
                case travelFieldConstants.DATA_TYPE_DIFFERENT_LIST:
                  matchedCondition.selectedDropdown = []
                  cond.fieldValue.forEach(keyItem =>{
                      let itemOfList = rule.differentListValues.find(condition => condition.value === keyItem);
                      matchedCondition.selectedDropdown.push(itemOfList)
                  })
                  break;
              }
            }
          })
        }
      })
    }
    this.setState({fieldsToBeRendered});
  }

  // * For changing the field

  fieldChangeHandler = (e,  rule, fieldItem, type) => {
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let matchedRule = this.state.fieldsToBeRendered.find(item => item == rule);
    let matchedCondition = matchedRule.ruleCondition.find(item => item == fieldItem);
    matchedCondition.selectedValue = e.target.value;
    this.setState({fieldsToBeRendered});
  }

  // * For changing select box

  selectChangeHandler = (e, rule, fieldItem, type) => {
    // console.log('the value of e ', e , 'and the type is ', type, 'the rule is ', rule, ' and the fielditem is ',fieldItem );
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let matchedRule = this.state.fieldsToBeRendered.find(item => item == rule);
    let matchedCondition = matchedRule.ruleCondition.find(item => item == fieldItem);
    if(matchedCondition?.dataTypeDetail?.conditionID == travelFieldConstants.DATA_TYPE_LIST_MANAGEMENT){
      matchedCondition.selectedDropdown = {
        _id: e.id,
        itemName: e.itemName,
        itemCode: e.itemCode

      }
    }else{
      matchedCondition.selectedDropdown = e;
    }
    this.setState({fieldsToBeRendered});

  }

  loadOptions = async (newValue,type) => {
    return travelRuleTemplateServices.differentListSearchPolicy(type,newValue, this.state.companyId).then((data) => {
      return data.result.map(list => {
        return {
          label: list.name,
          value: list.id
        };
      });
    })
  }

  selectDifferentListChangeHandler = (e,rule,fieldItem, type) => {
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let matchedRule = this.state.fieldsToBeRendered.find(item => item == rule);
    let matchedCondition = matchedRule.ruleCondition.find(item => item == fieldItem);
    matchedCondition.selectedDropdown = e;
    this.setState({fieldsToBeRendered});
  }

  fieldValueChangeHandler = (e, rule, fieldItem, type) => {
    let fieldsToBeRendered = [...this.state.fieldsToBeRendered];
    let matchedRule = this.state.fieldsToBeRendered.find(item => item == rule);
    let matchedCondition = matchedRule.ruleCondition.find(item => item == fieldItem);
    matchedCondition.dataTypeValue = e.target.value;
    this.setState({fieldsToBeRendered});
  }

  cancelModalHandler =  () => {
    this.setState({
      selectedCheckboxes: [],
      fieldsToBeRendered: [],
      ruleTemplateCheckboxes: [],
      isEdit: false,
      violationCode: '',
      ruleTemplateName: '',
    })
  }


  getFieldValue = (condition) => {
    if(!condition){
      return [""]
    }else if(condition.selectedValue){
      return [condition.selectedValue]
    }else if(condition?.selectedDropdown?._id){
      return [condition?.selectedDropdown?._id]
    }else if(condition?.selectedDropdown?.id){
      return [condition?.selectedDropdown?.id]
    }else if(condition?.selectedDropdown){
      let values = []
      condition.selectedDropdown && condition.selectedDropdown.forEach((key) =>{
        values.push(key.value)
      })
      return values;
    }else{
      return ""
    }
  }


  saveRuleTemplateHandler = async (e) => {
    e.preventDefault();
    let travelCategoryRule = this.state.fieldsToBeRendered.map(item => {
      return {
        travelCategoryRuleId: item.id,
        ruleCondition: item.ruleCondition.map(condition => {
          return {
            dataType: condition._id,
            dataTypeKey: condition.dataTypeDetail.conditionID,
            fieldValue: this.getFieldValue(condition),
            selectedDropdown:(condition?.dataTypeDetail?.conditionID === "DIFFERENT-LIST")? condition.selectedDropdown:[],

            // fieldValue: condition.selectedValue ? [condition?.selectedValue] : [condition?.selectedDropdown._id]
            // fieldValue: condition.selectedDropdown ? (condition.dataTypeDetail.conditionID == travelFieldConstants.DATA_TYPE_DIFFERENT_LIST ? condition.selectedDropdown.value : [condition.selectedDropdown._id] )
            // : [condition.selectedValue]
          }
        })

      }
    })
    let newRuleTemplate = {
      companyId: this.state.companyId,
      travelCategoryId: this.state.selectedCategory.id,
      policyViolationId: this.state.selectedPolicyViolation.value,
      message:this.state.message,
      travelCategoryRule: travelCategoryRule
      // * Rest of the stuff are not required for the policy category
      // violationCode: this.state.violationCode,
      // ruleName: this.state.ruleTemplateName,
      // isAllTrue: this.state.anyCondition ? false : true,
      // conditionsId: this.state.selectedCheckboxes,
    };
    try {
      let res = await travelRuleTemplateServices.updateTravelRuleTemplate(this.state.ruleTemplateId, newRuleTemplate);
      if(res){
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        this.setState({
          ruleTemplateName: '',
          violationCode: '',
          selectedCheckboxes: [],
          fieldsToBeRendered: [],
          isEdit: false

        });
        // * Get updated rule templates
        this.categoryClickHandler(this.state.selectedCategory);

        // this.getRuleTemplatesByCategories();
        $('.close').click();
      }else{
        toast.warn(res);
      }

    }catch(err){
      console.log(err);
    }



  }

  getPreviouslySetLLF = async () => {
    try {
      let res = await travelPolicyService.getAllLowestLogicalFare(this.state.companyId);
      if(res != null && res.result.length){

        this.setState({
        previouslySetLLF: res.result
      })
      }else{
        this.setState({
          previouslySetLLF: []
        })
      }
    }catch(err){
      console.log(err)
    }
  }

  handleRuleTemplateStatusToggle = async (e,ruleTemplate) =>{
    let newStatus = !ruleTemplate.activeStatus;
    let payload = {
      id: ruleTemplate.id,
      activeStatus: newStatus,
      companyId:this.state.companyId
    }
    try {
      let res = await travelRuleTemplateServices.activeStatusTravelRule(this.state.companyId, payload);
      if(res){
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        this.categoryClickHandler(this.state.selectedCategory);
      }else{
        toast.warn(res)
      }
    }catch(err){
      console.log(err);
    }
  }
  handleLLFToggle = async (llf) => {
    llf.activeStatus = !llf.activeStatus;
    try {
      let res = await travelPolicyService.updateLowestLogicalFare(llf.id, llf);
      if(res){
        toast.success(res.message);
        this.conditionCheckHandler(this.state.selectedCategory);
      }else{
        // console.log(res)
      }
    }catch(err){
      console.log(err)
    }
  }


  render() {
    return (
      <div>
        <TravelPolicyPage
          {...this.state}
          ruleClassDropdownHandler={this.ruleClassDropdownHandler}
          categoryClickHandler={this.categoryClickHandler}
          toggleAddTemplateModal={this.toggleAddTemplateModal}
          ruleTemplateSelectHandler={this.ruleTemplateSelectHandler}
          addRulePolicyHandler={this.addRulePolicyHandler}
          showCustomConditions={this.showCustomConditions}
          onEditRuleTemplate={this.onEditRuleTemplate}
          ruleTemplateNameHandler={this.ruleTemplateNameHandler}
          violationCodeHandler={this.violationCodeHandler}
          allCheckedConditionsHandler={this.allCheckedConditionsHandler}
          anyCheckedConditionsHandler={this.anyCheckedConditionsHandler}
          conditionCheckHandler={this.conditionCheckHandler}
          populateSetFields={this.populateSetFields}
          fieldChangeHandler={this.fieldChangeHandler}
          selectChangeHandler={this.selectChangeHandler}
          loadOptions={this.loadOptions}
          selectDifferentListChangeHandler={this.selectDifferentListChangeHandler}
          fieldValueChangeHandler={this.fieldValueChangeHandler}
          hideCustomConditions={this.hideCustomConditions}
          messagePolicyHandler={this.messagePolicyHandler}
          violationPolicyHandler={this.violationPolicyHandler}
          cancelModalHandler={this.cancelModalHandler}
          saveRuleTemplateHandler={this.saveRuleTemplateHandler}
          onRuleTemplateStatusToggle={this.handleRuleTemplateStatusToggle}
          onLLFToggle={this.handleLLFToggle}
          fieldDateChangeHandler={this.fieldDateChangeHandler}
        />
      </div>
    );
  }
}
export default TravelPolicy;
