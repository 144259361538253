import React, { Component } from 'react';
import RuleCastPage  from './rule-class-html';
import SimpleReactvalidator from 'simple-react-validator';
import {travelRuleClassService} from '../../../../../../_services/travel-rule-class.service';
import { toast } from 'react-toastify';
import {alertConstants} from '../../../../../../_constants/alert.constants';
import Pagination from "react-js-pagination";

// import Pagination from 'react-js-pagination';

class RuleCast extends Component {

  state = {
    displayedRules: [],
    companyId: localStorage['440a28'],
    // companyId: "5c2c891c30566e1f00b24552",

    activePage: 1,
    itemsCountPerPage: 10,
    totalItemsCount: 0,
    selectedRule: [],
    searchInput: '',
    isLoading: true

  }
  componentDidMount(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.getAllRules();

  }

  getAllRules(){
    // If search box has no text, get all roles as usual
    if(!this.state.searchInput){

      travelRuleClassService.getAllRuleClass(this.state.companyId, this.state.itemsCountPerPage,this.state.activePage)
      .then(res => {
        if(res != undefined && res.result){


          this.setState({
            displayedRules: res.result,
            totalItemsCount: res.count,
            isLoading: false
          })
        }
        this.setState({ isLoading: false})

      }, err => {
        toast.error(alertConstants.ERROR);
        this.setState({isLoading: false})
      })
    }else{
      // if search box has text, then pagination is to be done on search results
      travelRuleClassService.searchRuleClass(this.state.companyId, this.state.searchInput, this.state.itemsCountPerPage, this.state.activePage)
      .then(res => {

        if(res != null && res.result){

          this.setState({
            displayedRules: res.result,
            totalItemsCount: res.count,
            isLoading: false

          });
        }else{
          this.setState({
            isLoading: false,
            displayedRules: []
          })
        }

      }, err => {
        console.log(err);

      })
    }
  }



// Pagination - change page on click on pagination controls
  handlePageChange = (pageNumber) => {
    this.setState({
      activePage: pageNumber
    }, () => {
      this.getAllRules();
    })

  }

  // Pagination -  Change limit of the number of data shown
  handleLimitChange = (limit) => {
    this.setState({
      itemsCountPerPage: limit
    }, () => {
      this.getAllRules();
    })

  }

  // Toggle status handler
  handleStatusChange = (rule) => {
    let newStatus = !rule.activeStatus;

    let payload = {
      companyId: this.state.companyId,
      id: rule.id,
      activeStatus: newStatus
    }

    travelRuleClassService.activeInactiveRuleClass( payload)
      .then(res => {

        toast.success(alertConstants.UPDATED_SUCCESSFULLY)
        this.getAllRules();

      }, err => {

        toast.error(alertConstants.ERROR);
      })

  }


// Handler for searching data. Search and Pagination are both implemented from backend
  onSearchHandler = (e) =>{
    let searchInput = e.target.value;
    this.setState({searchInput});
    if(!searchInput.length){
      // if there is no text in the search bar, get all roles as usual
      this.setState({
        itemsCountPerPage: 10
      }, () => {
        this.getAllRules();

      })
    }else{
      // if the search input has text, search and get the results
      travelRuleClassService.searchRuleClass(this.state.companyId, searchInput, this.state.itemsCountPerPage, this.state.activePage)
      .then(res => {

        if(res !== undefined && res.result){

          this.setState({
            displayedRules: res.result,
            totalItemsCount: res.count

          });
        }else{
          this.setState({
            displayedRules: []
          })
        }

      }, err => {
        console.log(err);

      })
    }

  }

  // Function for selecting and deselecting a rule class
  toggleRuleSelect = (rule) => {
    let selectedItem = [...this.state.selectedRule];
    let selectedIndex = selectedItem.indexOf(rule);
    if(selectedIndex == -1){
      selectedItem = [rule];
    }else {
      selectedItem = [];
    }
    this.setState({selectedRule: selectedItem});


  }

  // As the function name says, for deleting the rule class, Rule is being stored as an element of array incase a future feature includes multiselect
  removeRuleHandler = (rule) => {
    let ruleToBeDeleted = rule[0].id;
    travelRuleClassService.deleteRuleClass(this.state.companyId, ruleToBeDeleted)
      .then(res => {
        toast.success(alertConstants.DELETED_SUCCESSFULLY);
        this.setState({selectedRule:[]}, () => {

          this.getAllRules();
        });
      }, err => {
        console.log(err);
        toast.error(alertConstants.ERROR);

      })

  }

  render() {
    return(
      <div>
        <RuleCastPage
          allStateItems = {this.state}
          handleStatusChange={this.handleStatusChange}
          handleLimitChange={this.handleLimitChange}
          handlePageChange={this.handlePageChange}
          onSearchHandler = {this.onSearchHandler}
          toggleRuleSelect={this.toggleRuleSelect}
          removeRuleHandler = {this.removeRuleHandler}
        />
      </div>
    )
  }
}
export default RuleCast;