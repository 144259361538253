import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Header from '../common/header';
class PolicyComponent extends Component {
    constructor(props) {
        super(props);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    render() {
        return (
            <div>
                {/* <Header /> */}
                <div className="page-wrapper help-page">
                    <div className="bg-image bg-image2 ">
                    </div>
                    <div className="top__margin">
                        <div className="mark1 text-center mb-4">
                            {/* <div className="round-mark">
                                <i className="material-icons">lock</i>
                                <img src="/assets/images/help/padlock.svg" />
                            </div> */}
                            <h4 className="text-white pt-2 text-black">Privacy Policy</h4>
                            <p>Review your settings & the data Curio uses to personalize your experience.</p>
                        </div>
                        <div className="px-padding">
                            <div className="border-light mb-5">
                                {/* <div className="legal-heading bg-white rounded-top">
                                    <h4 className="text-primary mb-1">Privacy Policy</h4>
                                    <p className="mb-0">Privacy Policy for use on Curio</p>
                                </div> */}
                                {/* <div className="form-holder pt-3"> */}
                                <div className="form-holder rounded pm-30">
                                    <h5 className="sub-heading mb-0 py-3 font-weight-bold">Curio Privacy Policy</h5>
                                    <p>This Privacy Policy ("Policy") explains in general terms how Curio Tech Private Limited trading as CURIO, related bodies corporate and franchisees, and all their brands (collectively referred to in this document as "Curio Tech Pvt Ltd” and/ or “CURIO”, "we" "our" or "us") protects the privacy of your personal information. We are firmly committed to protecting the privacy and confidentiality of personal information and maintain various physical, electronic and procedural safeguards to protect personal information in our care.</p>
                                    <p>By providing personal information to us (or allowing another person to do so on your behalf), you agree that this Policy will apply to how we handle your personal information and you consent to us collecting, using and disclosing your personal information as detailed in this Policy. If you do not agree with any part of this Policy, you must not provide your personal information to us and this may affect the services we can provide to you.</p>
                                    <p>In this Policy, where we say “disclose”, this includes to transfer, share, send, or otherwise make available or accessible your personal information to another person or entity.</p>

                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">What is personal information?
                                    </h5>
                                    <p>Personal information is information which relates to a living individual who can be identified from that information, or from that information and other information in a person’s possession, including any expression of opinion, whether true or not, and whether recorded in material form or not, about an identified or reasonably identified individual, and any indication of intention in respect of an individual.</p>

                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">How do we collect personal information?</h5>
                                    <p>Your Company may have subscribed to the Curio Services either directly with Curio or indirectly via an authorized reseller of the Curio Services. This privacy statement does not change any terms of a business agreement with your Company or between your Company and a reseller, as applicable, but is simply intended to provide additional information to you regarding the Curio Services. Your Company is regarded as a data controller within the meaning of applicable data protection laws, and Curio's processing of such data for the Curio Services is under the direction of your Company, directly or indirectly as applicable. You should direct any questions about how data about you is used as part of the Curio Services to your Company.</p>
                                    <p>"Personal Data" means any information relating to you that is entered by or on behalf of your Company or its authorized users of the Curio Services into or derived from their use of the Curio Services. It also includes personal data supplied to or accessed by or on behalf of Curio to provide support for Curio Services. Not all of your Company's data within the Curio Services is Personal Data. As a general matter, Personal Data in the Curio Services includes the following categories of data:</p>

                                    <ul className="circled__lists">
                                        <li>Personal profile data, such as name, contact information, travel preferences, financial information and account numbers for related connected services</li>
                                        <li>Organizational information, such as employee identification, payroll, cost center, associated approvers information</li>
                                        <li>Expense and travel related data, such as expense information, including images of receipts, and travel itineraries</li>
                                        <li>Mobile data, such as mobile device information and, when enabled, location data</li>
                                        <li>Data for associated connected services to support travel needs, such as account, loyalty program or rewards numbers for taxi or rental cars, airlines or hotels</li>
                                    </ul>

                                    <p>The categories of information about you collected or derived within the Curio Services depend, in part, on the Curio Services to which your Company has subscribed and how the Curio Services are configured for your Company.</p>
                                    <p>You provide Personal Data directly when you enter it in Curio Services. In some cases, another user, such as an account administrator, may create an account on your behalf or may provide Personal Data as part of your use of the Curio Services at the direction or with permission of your Company.</p>
                                    <p>Curio Services may obtain Personal Data from other sources, as well, such as back office financial or human resources systems, or from travel agents and travel management companies that work with your Company. Curio Services also connect to third party partners and suppliers like airlines, hotel chains, restaurant chains or ride-sharing services. Each of these connected services may enable Personal Data about you to be transferred into the Curio Services. Generally, these connections are controlled by your Company. But, as described below, you can enable the connection of the Curio Services with certain other Curio or third party services you select that result in the collection of additional sources of Personal Data.</p>
                                    <p>When you use the Curio Services, some information like IP address, device or browser information, logs or click stream information, for example, is automatically collected about your usage and activity on the Curio Service to address technical support issues and understand how you use the Curio Services. Curio may use certain tracking technologies such as cookies, web beacons, or third party analytics tools to obtain such information.</p>

                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">
                                        What personal information do we collect?
                                    </h5>
                                    <p>Generally, the type of personal information we collect about you is the information that is needed to facilitate your queries about our products, travel arrangements and bookings and to arrange travel-related services and products on your behalf. For example, we may collect details such as your name, residential address, mailing address, telephone number, email address, credit/debit card details (including card type, card number, security number and expiry date), passport details, frequent flyer details, information about your dietary requirements and health issues (if any), and other details relevant to your travel arrangements or required by the relevant travel service providers (e.g. airlines, accommodation or tour providers). When you make contact with us for other purposes, we may also collect personal information about you in relation to those purposes. For example, we may collect your personal information so we can contact you about a competition you have entered (e.g. if you win) or to respond to an enquiry or feedback form you have sent to us. We also collect information that is required for use in the business activities of Curio Tech Pvt Ltd and our related entities, including for example, financial details necessary in order to process various transactions, and any other relevant personal information you may elect to provide to us.</p>
                                    <p>In some circumstances, we may collect personal information from you which is regarded as sensitive information. Sensitive information includes types of personal information such as your racial or ethnic origin, religious beliefs or affiliations, criminal record and health information. We will only collect sensitive information about you with your consent and where reasonably necessary for, or directly related to, one or more of our functions or activities (e.g. to make travel arrangements on your behalf). Where you provide sensitive information to us, you consent to us using that sensitive information for the purpose for which it was collected. For example, if you provide health information to us in connection with a travel insurance application you would like to make, you consent to us using and disclosing that health information in connection with arranging that travel insurance on your behalf.</p>

                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">
                                        When we act as agent
                                    </h5>
                                    <p>When we book products and services for you, we usually do so as agent for or on behalf of travel service providers. This means that we usually collect personal information about you both for our internal purposes (e.g. to process your booking), and on behalf of the parties for whom we act as agent, for their internal purposes (e.g. to provide the booked services). Accordingly, the consent you provide under this Policy to the collection and use of personal information by us, applies equally to the parties whose products and services we sell. For example, if you purchase a flight from us, then under this Policy you will have consented to your personal information being provided to the airline to enable your flight to be booked. We act as agent for or on behalf of many thousands of travel service providers, so it is not possible for us to set out in this Policy all of the travel service providers for whom we act nor exactly how each of these travel service providers will use your personal information. If you wish to contact us for further information, please refer to the “Feedback / Complaints / Contact” section below.</p>

                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">
                                        How do we use personal information?
                                    </h5>
                                    <p>Where you contact us in relation to a travel booking or query, the primary purpose for which we collect your personal information is generally to provide you with travel advice and/or to assist you with booking travel and/or travel related products and services. However, the purpose for collection may differ depending on the particular circumstances as disclosed in this Policy.</p>
                                    <p>By providing us, or otherwise allowing us to collect, your personal information, you consent to us using and disclosing your personal information for the purposes for which it was collected, and for related or ancillary purposes, such as any one or more of the following purposes:</p>
                                    <ul className="circled__lists">
                                        <li>Identification of fraud or error</li>
                                        <li>Regulatory reporting and compliance;</li>
                                        <li>Developing, improving and marketing our products and services and those of our related entities;</li>
                                        <li>Servicing our relationship with you by, among other things, providing updates on promotions and services we think may interest you;</li>
                                        <li>Involving you in market research, gauging customer satisfaction and seeking feedback regarding our relationship with you;</li>
                                        <li>To facilitate your participation in loyalty programs;</li>
                                        <li>To analyse trends in sales and travel destinations;</li>
                                        <li>For marketing activities including but not limited to mail-outs, emails, e-newsletters, SMS notifications and telephone calls;</li>
                                        <li>Internal accounting and administration; and</li>
                                        <li>Other purposes as permitted or required by law (e.g. to prevent a threat to life, health or safety).</li>                                 </ul>
                                    <p>We will only send e-newsletters or other promotional materials to you if you have opted in to receive them. You can subscribe to receive e-newsletters and other promotional materials by following the relevant links on our website.</p>
                                    <p>We are only allowed to store your personal information for as long as necessary having regard to the purpose for which it was collected or a related or ancillary purpose. We may therefore delete your personal information after a reasonable period of time and, if you have not used our services for some time, you may have to re-enter or re-supply your personal information to us.</p>

                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">
                                        Is personal information disclosed to third parties?
                                    </h5>
                                    <p>Yes. You also consent to the personal information about you which we collect or receive being processed, transferred and/or disclosed by us in the following circumstances:</p>
                                    <ul className="circled__lists">
                                        <li>In each of the circumstances set out in the previous section “How do we use personal information?”;</li>
                                        <li>As permitted or required by law;</li>
                                        <li>To various regulatory bodies and law enforcement officials and agencies, including to protect against fraud and for related security purposes;</li>
                                        <li>To third party suppliers of IT based solutions that assist us in providing products and services to you;</li>
                                        <li>To our related entities and brands (including companies based overseas);</li>
                                        <li>To travel service providers such as travel wholesalers, tour operators, airlines, hotels, car rental companies and other service providers for the purpose for which the information was collected or for a related purpose (for example to facilitate and process your travel arrangements);</li>
                                        <li>To comply with our legal obligations and any applicable customs/immigration requirements relating to your travel;</li>
                                        <li>To comply with a request from a qualified government department if we are satisfied that they have the authority to make such request;</li>
                                        <li>Where we suspect that unlawful activity has been or may be engaged in and the personal information is a necessary part of our investigation or reporting of the matter;</li>
                                        <li>To third parties who may involve you in market research for the purpose of servicing our relationship with you and improving the services we provide; and</li>
                                        <li>To third parties for the purpose of analysing trends in sales and travel destinations.</li>                                        
                                    </ul>
                                    <p>The third parties to whom we disclose your personal information may also be taken to have collected your personal information in their own right, for their internal use. For more information in relation to collection by travel service providers, see the heading above titled "When we act as agent".</p>
                                    <p>Where we engage travel service providers to perform services for us, those travel service providers may be required to handle your personal information. Where we disclose your personal information to such travel service providers, you agree that we will not be required to ensure their compliance with applicable privacy laws or otherwise be liable or accountable for how they handle your personal information.</p>
                                    <p>Other than the above, we will not disclose your personal information without your consent unless we reasonably believe that disclosure is necessary to lessen or prevent a threat to life, health or safety or for certain action to be undertaken by an enforcement body (e.g. prevention, detection, investigation, prosecution or punishment of criminal offences), disclosure is authorised or required by law or disclosure is otherwise permitted by applicable privacy laws.</p>
                                    <p>Companies to whom personal information is disclosed which are registered in the United States may be required to provide information under the USA PATRIOT Act H.R. 3162.</p>


                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">
                                    Trans-border data flows
                                    </h5>
                                    <p>When you provide, or otherwise allow us to collect, your personal information, you consent to your personal information being disclosed to certain overseas recipients, as set out below. Where we disclose your personal information to an overseas recipient, you acknowledge that the recipient may be located in a country with laws that do not protect personal information as well as Indian privacy laws do. We will use reasonable efforts to ensure the recipient’s compliance with Indian privacy laws; however, you acknowledge that we cannot control the privacy practices of the recipient and therefore will not be liable or accountable for how the recipient handles your personal information. We encourage you to review the privacy policies of any third party service provider whose services we arrange on your behalf. If you have any objections to your personal information being disclosed to an overseas recipient, please let us know.</p>
                                    <p>In providing our services to you, it may be necessary for us to disclose personal information to relevant overseas travel service providers. For instance, where you request travel to be booked with an overseas travel service provider, we will usually need to disclose your personal information to that provider for the purposes of arranging your booking with them. These travel service providers will in most cases receive your personal information in the country in which they will provide the services to you or in which their business is based. In providing our services to you, it may be necessary for us to disclose personal information to relevant overseas travel service providers. For instance, where you request travel to be booked with an overseas travel service provider, we will usually need to disclose your personal information to that provider for the purposes of arranging your booking with them. These travel service providers will in most cases receive your personal information in the country in which they will provide the services to you or in which their business is based.</p>
                                    <p>We may also disclose your personal information to our overseas related entities and to travel service providers and other third parties who perform services for us overseas. Generally, we will only disclose your personal information to these overseas recipients in connection with facilitation of your travel booking and/or to enable the performance of administrative and technical services by them on our behalf.</p>
                                    <p>We deal with many different service providers all over the world, so it is not possible for us to set out in this Policy all of the different countries to which we may send your personal information. However, if you have any specific questions about where or to whom your personal information will be sent, please refer to the “Feedback / Complaints / Contact” section below.</p>
                       
                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">Security of information</h5>
                                    <p>Curio Tech Pvt Ltd has implemented various physical, electronic and managerial security procedures in order to protect personal information from loss and misuse, and from unauthorized access, modification, disclosure and interference.</p>
                                    <p>Curio Tech Pvt Ltd regularly reviews security and encryption technologies and will strive to protect your personal information as fully as we protect our own confidential information.</p>
                                    
                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">Security and retention of personal data</h5>
                                    <p>Curio uses appropriate standard security technology and organizational measures to protect Personal Data from unauthorized disclosure and will retain Personal Data in active databases for varying lengths of time depending upon the specific Curio Services, type of data, and applicable law and instructions of the data controller.</p>

                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">Access to and correction of personal information</h5>
                                    <p>Subject to any exceptions set out in applicable privacy laws, we invite you to access any personal information we may hold about you. Where personal information we hold about you is not accurate, complete, up-to-date or relevant, you may ask us to correct that personal information, and we will respond to your request within a reasonable time. We reserve the right to confirm the identity of the person seeking access or correction to personal information before complying with such a request. If we deny access or correction, we will provide you with the reason for such denial. If you wish to access or seek correction of any personal information we hold about you, please refer to the “Feedback / Complaints / Contact” section below.</p>
                                    <p>You must always provide accurate information and you agree to update it whenever necessary. You also agree that, in the absence of any update, we can assume that the information submitted to us is correct.</p>

                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">Opt-out</h5>
                                    <p>Should you no longer wish to receive e-newsletters or other promotional material, participate in market research or receive other types of communication from us, please refer to the “contact section” and leave a message</p>

                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">
                                    Tracking technologies / cookies
                                    </h5>
                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">COOKIES</h5>
                                    <p>A cookie is a small text file that is stored on a user’s computer for record-keeping purposes. We may link the information we store in cookies to any personal information you submit while on our website. Cookies save data about individual visitors to the website, such as the visitor's name, password, username, screen preferences, the pages of a site viewed by the visitor, and the advertisements viewed or clicked by the visitor. At Curio Tech Pvt Ltd we use cookies to identify a visitor’s browser, to anonymously track visits or to enhance the experience of the website.</p>
                                    <p>We use both session ID cookies and persistent cookies. A session ID cookie expires when you close your browser. A persistent cookie remains on your hard drive for an extended period of time. When the visitor revisits the website, we may recognize the visitor by the cookie and customise the visitor's experience accordingly. You can remove persistent cookies by following directions provided in your Internet browser’s “help” file.</p>
                                    <p>If you reject cookies, you may still have access to our website, but your ability to use some areas of our website, such as contests or surveys, will be limited.</p>
                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">GOOGLE ANALYTICS</h5>
                                    <p>When you visit certain sections of the website, Google Analytics, a website analytics service provided by Google, Inc (“Google”), will place cookies on your computer’s browser. The information generated by the cookies about your use of the website is transmitted to Google. This information is then used for the purpose of compiling statistical reports to enable us to measure and analyse the number of visits to the site and its pages, the average time spent on the site and the pages viewed. We will not (and will not allow any third party to) use Google Analytics to track or to collect any personal data of visitors to our website. We will not associate any data gathered via use of cookies with any personal data from any source as part of our use of Google Analytics. For further information about Google Analytics please visit www.google.com/analytics and for details of Google Analytics’ Privacy Policy, please visit http://www.google.com/intl/en )ALL/privacypolicy.html.</p>
                                    <p>You may choose to opt-out of Google Analytics using the Google Analytics Opt-out Browser Add-on which you may access at https://tools.google.com/dlpage/gaoptout/.</p>

                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">3RD PARTY TRACKING</h5>
                                    <p>The use of cookies by our partners, affiliates, tracking utility company or service providers is not covered by our Privacy Policy. We do not have access or control over these cookies. We do not tie the information gathered to our customers’ or users’ personal identifiable information.</p>
                                  
                                    <h6 className="sub-heading py-3 mb-0 font-weight-bold">BEHAVIOURAL TARGETING / RE-TARGETING</h6>
                                    <p>We partner with Third Party ad networks to either display advertising on Our Site or to manage Our advertising on other sites. Our ad network partners use cookies and other technologies to collect non-personally identifiable information about your activities on this and other websites to provide you targeted advertising based upon your interests.</p>
                                  
                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">LINKED SITES</h5>
                                    <p>Our website and mobile app may contain links to third party sites over which we have no control. We are not responsible for the privacy practices or the content of such web sites. We encourage you to read the privacy policies of any linked sites you visit as their privacy policy and practices may differ from ours.</p>

                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">FEEDBACK / COMPLAINTS / CONTACT</h5>
                                    <p>If you have any enquiries, comments or complaints about this Policy or our handling of your personal information, please contact your consultant or email us at cariappa@curiotechnologies.com. We will respond to any complaints received as soon as practicable.</p>
                                    <h5 className="sub-heading py-3 mb-0 font-weight-bold">CHANGES TO OUR POLICY</h5>
                                    <p>From time to time it may be necessary for us to review and revise this Policy. We reserve the right to change our Policy at any time and for any reason. If we make a change to the Policy, the revised version will be posted on our website.</p>                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default PolicyComponent;