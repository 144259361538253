import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { userService } from "src/_services";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "src/_components/common/Loader";

import Moment from "react-moment";
class NotificationPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            limit: 10,
            page: 1,
            count: 0,
            listNotify: [],
            hasMore: true,
        };
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    componentDidMount() {
        this.getNotificationList()
    }
    getNotificationList = async () => {
        var resp = await userService.getAllNotificationList(this.state.limit, this.state.page, '')
        if (resp) {
            if (this.state.listNotify.length > 0) {
                this.setState({
                    listNotify: this.state.listNotify.concat(resp.result),
                })
            } else {
                this.setState({
                    listNotify: resp.result,
                    count: resp.count
                })
            }
        }
    }
    checkNotification = async (list) => {
        var type = list.navigateTo;
        (type === "Finace_Admin") ?
            this.goFinaceAdmin(list.userLink) :
            (type === "Approvals_Trips") ?
                this.goApproves(list.userLink) :
                (type === "Visa_Request") ?
                    this.goVisaRequest(list.userLink) :
                    (type === "Expense_Report") ?
                        this.goExpenseReport(list.userLink) :
                        (type === "Approvals_ExpenseReport") ?
                            this.goApprovesExpense(list.userLink) :
                            (type === "Approvals_CashAdvance") ?
                                this.goApprovesCash(list.userLink) :
                                (type === "My_Trips") ?
                                    this.goTrips(list.userLink) :
                                    (type === "Cash_Advance") ?
                                        this.goCashAdvance(list.userLink) :
                                        (type === "Cash_Admin") ?
                                            this.goCashAdmin(list.userLink) : this.load();
        var resp = await userService.readNotification(list.id)
        if (resp) {
            this.getNotificationList()
        }
    }
    goFinaceAdmin = () => {
        this.props.history.push('/finance-main/finance-admin')
    }
    goApproves = (userLink) => {
        this.props.history.push((userLink) ? `/trips-approve/trip-travel-request/${userLink}` : `/trips-approve/trips`)
    }
    goApprovesExpense = (userLink) => {
        this.props.history.push((userLink) ? `/expense/details/approval/${userLink}` : `/trips-approve/expense-reports`)
    }
    goApprovesCash = (userLink) => {
        this.props.history.push((userLink) ? `/trips-approve/cashadvance/${userLink}` : `/trips-approve/cashadvance`)
        // this.props.history.push('/trips-approve/cashadvance')
    }
    goExpenseReport = (userLink) => {
        this.props.history.push((userLink) ? `/expense/details/${userLink}` : `/expense/home`)
    }
    goCashAdmin = () => {
        this.props.history.push('/cash-issued/cashadvance')
    }
    goCashAdvance = (userLink) => {
        this.props.history.push('/cash-advance/list')
    }
    goVisaRequest = () => {
        this.props.history.push('/booking/visa')
    }
    goTrips = (userLink) => {
        this.props.history.push((userLink) ? `/booking/submit/trip-details/${userLink}` : `/my-trips/list`)
    }
    load() { }

    fetchMoreData = () => {
        if (this.state.listNotify.length >= this.state.count) {
            this.setState({ hasMore: false })
            return;
        }
        this.setState({
            page: this.state.page + 1
        })
        this.getNotificationList();
    }
    render() {
        let data = this.props;
        return (
            <div>
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">R</div>
                        <div className="right">
                            <h6 className="heading">Notification</h6>
                            <p className="text">All the notifications are shown here.</p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="p-padding">
                    <div className="white-card">
                        <ul className="notifi-ul">
                            <InfiniteScroll
                                dataLength={this.state.listNotify.length}
                                next={this.fetchMoreData}
                                hasMore={this.state.hasMore}
                                loader={
                                    <Loader />
                                }
                                endMessage={
                                    <li className="text-center">
                                        Yay! You have seen it all
                                    </li>
                                }
                            >
                                {this.state.listNotify.map((list, key) => {
                                    return (
                                        <li key={key} onClick={e => this.checkNotification(list)}
                                            className={(list.isReaded) ? "read"
                                                : "unread"}>
                                            <div className="shape-name">
                                                <div className="left l-grey l-grey-icon">
                                                    <i className="material-icons">
                                                        notifications_none
                                            </i>
                                                </div>
                                                <div className="right">
                                                    <p className="text">
                                                        {list.displayMessage} - <span className="link">Read more</span>
                                                        {/* {list.navigateTo} */}
                                                    </p>
                                                    <p className="lighter-p mb-0">
                                                        <Moment format="MMM DD YYYY, hh:mm a">
                                                            {list.sentAt}
                                                        </Moment>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                        </li>
                                    )
                                })}
                            </InfiniteScroll>

                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default NotificationPage
