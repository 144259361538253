import React, { Component } from "react";
import FullDetailsPage from "../flight/_components/full-details";
import cryptr from "../../../../_helpers/crypty";
import { bookingServices } from "../../../../_services/booking.service";
import InfiniteScroll from "react-infinite-scroll-component";
import HotelList from "./_components/HotelList";
import HotelFilterList from "./_components/HotelFilter";
import { masterService, cartServices } from "../../../../_services";
import $ from "jquery";
import SortHeadFlight from "../flight/_components/sortHeadFlight";
import HotelNoResults from "./_components/NoResults";
import HotelLoader from "./_components/HotelLoader";
import 'react-input-range/lib/css/index.css';
import HotelDetailsPage from "./hotel-details";

class HotelListPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.req = JSON.parse(cryptr.decrypt(this.props.match.params.data));
    let item = JSON.parse(cryptr.decrypt(localStorage['1UC43']))
    this.currency = item.currencyCode;
    this.state = {
      tags: [],
      suggestions: [],
      value: {
        min: 0,
        max: 0
      },
      minPrice: 0,
      maxPrice: 10000,

      tripId: sessionStorage.getItem('TRIPID'),
      rate: 0,
      loadingDetail: true,
      cardList: [],
      cartCount: 0,
      listOfCurrency: [],
      roomDetailActive: false,
      hotelDetailsView: []
    };
  }
  closeFilter() {
    $("#filter-card").removeClass("show");
  }
  toggleFilter() {
    $("#filter-toggle").toggleClass("active");
    $("#filter-card").toggleClass("show");
  }

  url = "";


  componentDidMount = async () => {
    // window.addEventListener('storage', this.storageHandler)
    this.loading = true;
    window.scrollTo({ top: 0, behavior: "smooth" });
    let tripId = sessionStorage.getItem('TRIPID');
    await this.setState({
      tripId
    })


    sessionStorage.setItem('TRIPID', this.state.tripId);
    // console.log('--- session ', sessionStorage.getItem('TRIPID'));
    this.url = this.props.match.params.data;
    this.getHotelList();
    this.findDayDif();
    this.getCurrencyCode();
    this.getCartDetail();
    this.userId = JSON.parse(cryptr.decrypt(localStorage["440a24"]));
    this.getCardList();

  }

  // componentWillUnmount() {
  //   window.removeEventListener('storage', this.storageHandler)
  // }

  componentWillUpdate() {
    if (sessionStorage.modify !== undefined && sessionStorage.modify) {
      this.req = JSON.parse(cryptr.decrypt(sessionStorage.req));
      this.url = this.props.match.params.data;
      this.listOfHotels = [];
      this.getHotelList();
      this.findDayDif();
      this.getCurrencyCode();
      this.getCardList();
      this.getCartDetail();
      sessionStorage.removeItem("modify");
      sessionStorage.removeItem("req");

    }
  }


  viewAllActive = [];
  viewAll = key => {
    this.viewAllActive[key] = !this.viewAllActive[key];
    this.setState({ ...this.state })
  };
  viewAllClose = () => {
    this.viewAllActive = [];
    this.setState({ ...this.state })
  };
  getCartDetail = async () => {
    let tripID = sessionStorage.getItem('TRIPID');
    var resp = await cartServices.getCartListDetail(tripID);
    if (resp) {
      let assume = resp.data.hotelData;
      assume.forEach((item, key) => {
        if (this.props.match.params.id === item.hotel_id) {
          const index = this.selectedItem.indexOf(
            item.roomId
          );
          if (index === -1) {
            this.selectedItem.push(item.roomId);
          }
        }
      });
      let count = resp.data.flightData.length + resp.data.hotelData.length;
      this.setState({ cartCount: count });
    }
  };
  hotelDetail = []
  getInfo = async (hotelId, hotelName, vendor) => {
    var id = hotelId;
    if (id != null) {
      id = String(id);
    }
    var name = hotelName;
    var duration = this.duration;
    var req = {
      checkin: this.req.fromDate,
      checkout: this.req.toDate,
      no_of_rooms: "1",
      hotel_id: id,
      trip_duration: duration,
      hotel_name: name,
      currency: this.currency,
      vendor: vendor || "",
      latitude: this.req.lat,
      longitude: this.req.lng
    };
    this.hotelDetail = []
    this.setState({ loadingDetail: true })
    var resp = await bookingServices.getHotelDetails(req)
    if (resp) {
      this.hotelDetail = resp;
    }
    this.setState({ loadingDetail: false })

  }
  getCardList = async () => {
    let tripID = sessionStorage.getItem('TRIPID');

    var resp = await cartServices.getCartUserList(tripID);
    if (resp) {
      this.setState({ cardList: resp })
    }
  };
  getCurrencyCode = async () => {
    var companyId = localStorage["440a28"];
    var resp = await masterService.getGroups(companyId, "CUR001");
    if (resp) {
      var arr = []
      resp.result.forEach(item => {
        arr.push({ label: item.itemName, value: item.itemCode })
      })
      this.setState({ listOfCurrency: arr })
    }
  };
  changeCurrency = e => {
    this.currency = e.value;
    this.getHotelList(1);
  };
  handleDelete = i => {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i)
    }, () => {
      this.getHotelList();
    });
  };

  handleAddition = tag => {
    this.filterSet = true;
    this.setState(state => ({ tags: [...state.tags, tag] }), () => {
      this.getHotelList(1);
    });
  };

  storeValue = e => {
    this.setState({
      value: e
    });
  };
  // storeValue2 = e => {
  //   this.setState({
  //     value2: e
  //   })
  // }
  filterSet = false;
  completeRange = e => {
    this.loading = true;
    this.getHotelList(2);
  };

  changeRating = e => {
    this.setState({ rate: e }, () => { this.getHotelList(1); });
  };
  page = 1;
  limit = 10;
  count = 0;
  allItem = [];
  listOfHotels = [];
  hasMore = true;
  priceSort = "";
  getHotelList = async (type) => {
    var e = this.req;
    if (type === 1 || type === 2) {
      this.filterSet = true;
      this.listOfHotels = [];
      this.page = 1;
      this.limit = 10;
      this.count = 0;
      this.hasMore = true;
    }
    this.setState({ ...this.state })
    let prefered_hotel = [];
    if (this.state.tags.length !== 0) {
      this.state.tags.forEach(item => {
        prefered_hotel.push(item.text)
      })
    }
    var req = {
      checkin: e.fromDate,
      checkout: e.toDate,
      no_of_rooms: e.rooms,
      latitude: e.lat,
      longitude: e.lng,
      page: this.page,
      limit: this.limit,
      search_text: e.from,
      currency: this.currency,
      priceRange: { ...this.state.value },
      prefered_hotel: prefered_hotel,
      rating: this.state.rate,
      price: this.priceSort,
      country: this.req.country,
      cityName: this.req.cityName,
      trip_duration: this.req.trip_duration
    };

    this.loading = true;
    let resp = await bookingServices.getHotelList(req)
      .catch(err => {
        this.loading = false;
      });
    sessionStorage.setItem('TRIPID', this.state.tripId);
    if (resp) {
      this.loading = false;
      this.count = resp.count;
      this.allItem = resp.result;

      if (this.listOfHotels.length > 0 && this.page !== 1) {
        this.listOfHotels = this.listOfHotels.concat(resp.result);
      } else {
        this.listOfHotels = resp.result;
      }
      if (resp.max_min_price) {
        if (type != 2) {
          this.setState({
            value: {
              ...this.state.value,
              max: Math.ceil(resp.max_min_price.max_price)
            }
          })
        }
        this.setState({
          maxPrice: Math.ceil(resp.max_min_price.max_price),
          minPrice: Math.ceil(resp.max_min_price.min_price)
        })
      }
    }
    this.setState({ ...this.state });
  };
  sortPriceItem = () => {
    if (this.priceSort === "") {
      this.priceSort = "asc";
    } else {
      this.priceSort === "asc"
        ? (this.priceSort = "desc")
        : (this.priceSort = "asc");
    }
    this.getHotelList(1)
  }
  fetchMoreData = () => {
    if (this.listOfHotels.length >= this.count) {
      this.hasMore = false;
      return;
    }
    this.page = this.page + 1;
    this.getHotelList();
  };
  duration = "1";

  findDayDif() {
    // The number of milliseconds in one day
    var ONE_DAY = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    var date1_ms = new Date(this.req.fromDate).getTime();
    var date2_ms = new Date(this.req.toDate).getTime();

    // Calculate the difference in milliseconds
    var difference_ms = Math.abs(date1_ms - date2_ms);

    // Convert back to days and return
    this.duration = Math.round(difference_ms / ONE_DAY);
  }

  enableRoom = (req) => {
    this.setState({
      roomDetailActive: !this.state.roomDetailActive,
      hotelDetailsView: req
    });

  }

  render() {
    return (
      <div>
        {this.count !== 0 && (
          <button
            data-target="#addServiceModal"
            data-toggle="modal"
            className="add-button text-white"
          >
            +
          </button>
        )}
        {!this.state.roomDetailActive ?
          <div>
            <FullDetailsPage
              from={this.req.from}
              checkin={this.req.fromDate}
              checkout={this.req.toDate}
              rooms={this.req.rooms}
              type="hotel"
              cartCount={this.state.cartCount}
              cardList={this.state.cardList}
              typeOfService="hotel"
            />
            <div className="p-padding top-48">
              <div className="clearfix" />
              {/* hotel search heading */}
              {!this.loading && this.count !== 0 && (
                <div className="summary active">
                  <div className="d-inline-block text-center">
                    <p>
                      <i className="material-icons">
                        hotel
                                    </i>
                    </p>
                  </div>
                  <div className="d-inline-block">
                    <p className="title">
                      Stay at {this.req.from}
                    </p>
                    <p className="label">{this.count} options found</p>
                  </div>
                </div>
              )}
              <div className="clearfix" style={{ clear: "both" }} />

              <div className="row">
                <div className={(this.loading && !this.filterSet) ? "col-lg-12" : "col-xl-10 col-lg-9 "}>
                  {this.loading && (
                    <HotelLoader msg={"Please wait while we search for the best hotel options <br /> based on your company policy."} />
                  )}
                  {/* on search empty display this */}
                  {!this.loading && this.count === 0 && !this.filterSet && (
                    <HotelNoResults msg={"There are no hotels found in this location for the selected date of journey. Please try a different location or date"} />
                  )}
                  {/* on filter empty display this */}
                  {!this.loading && this.count === 0 && this.filterSet && (
                    <HotelNoResults msg={" No Results Found, Try readjusting the filters or modifying search"} />
                  )}
                  {!this.loading && this.count !== 0 && (
                    <div>
                      <div className="devision-container heading">
                        <div className="devision">

                          <div className="details main hotel">
                            Hotel Name
                                                <span>
                              {/* <i className="material-icons sort">arrow_drop_down</i>
                                                    <i className="material-icons sort">arrow_drop_up</i> */}
                            </span>
                          </div>
                          <SortHeadFlight
                            sortItem={this.sortPriceItem}
                            feildName={"hotel"}
                            name="Hotel"
                            sortType={this.priceSort}
                            className="details price hotel text-right"
                          />
                          <div className="clearfix" />
                        </div>
                      </div>
                      {this.listOfHotels.length !== 0 && (
                        <InfiniteScroll
                          dataLength={this.listOfHotels.length}
                          next={this.fetchMoreData}
                          hasMore={this.hasMore}
                          loader={
                            <h4 style={{ textAlign: "center" }}>Loading...</h4>
                          }
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                        >
                          {this.listOfHotels.map((i, index) => (
                            <HotelList
                              key={index}
                              index={index}
                              currenyType={this.currenyType}
                              tripDuration={this.duration}
                              viewAllActive={this.viewAllActive}
                              viewAll={this.viewAll}
                              hotelDescription={this.hotelDetail}
                              viewAllClose={this.viewAllClose}
                              getInfo={this.getInfo}
                              hotelDetail={this.hotelDetail}
                              loadingDetail={this.state.loadingDetail}
                              items={i}
                              query={this.props.match.params.data}
                              enableRoom={this.enableRoom}
                            />
                          ))}
                        </InfiniteScroll>
                      )}
                    </div>
                  )}
                </div>
                {((!this.loading && this.count !== 0) || this.filterSet) && (
                  <HotelFilterList
                    toggleFilter={this.toggleFilter}
                    state={this.state}
                    handleDelete={this.handleDelete}
                    handleAddition={this.handleAddition}
                    currenyType={this.currency}
                    closeFilter={this.closeFilter}
                    storeValue={this.storeValue}
                    storeValue2={this.storeValue2}
                    completeRange={this.completeRange}
                    changeRating={this.changeRating}
                    listOfCurrency={this.state.listOfCurrency}
                    changeCurrency={this.changeCurrency}
                  />
                )}
              </div>
            </div>
          </div>
          :
          <HotelDetailsPage
            enableRoom={this.enableRoom}
            hotelDetailsView={this.state.hotelDetailsView}
          />}
      </div>
    );
  }
}

export default HotelListPage;
