import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ItineraryPage from "./itinerary";
import TravelRequestDetailsPage from "./travel-request-details";
import ExpensePage from "./expense";
import TravelDocumentPage from "./travel-document";
import TravelRequestDetailsSubmitPage from "./travel-request-details-submit";
import SubmitPage from "./submit";
import { cartServices } from "src/_services";
import { toast } from "react-toastify";
import $ from 'jquery';
class CheckoutManiPage extends Component {
  submitFinal = async () => {
    var resp = await cartServices.submitFinalRequest()
    if (resp) {
      sessionStorage.setItem('submitItem', true);
      toast.info("Request Submitted");
      this.props.history.push('/booking/checkout/itinerary')
      $('.close').click();
    }
  }
  checkItem = async () => {
    var resp = await cartServices.getCartDataExpense();
    if (resp) {
      // this.forceUpdate()
      // this.props.history.push("/booking/checkout/expense");
    }
  };
  render() {
    return (
      <div className="CheckoutPage">
        <Switch>
          <Route path="/booking/checkout/itinerary" component={ItineraryPage} />
          <Route
            path="/booking/checkout/travel-request-details"
            component={TravelRequestDetailsPage}
          />
          <Route path="/booking/checkout/expense" component={ExpensePage} />
          <Route
            path="/booking/checkout/travel-document"
            component={TravelDocumentPage}
          />
          <Route path="/booking/checkout/submit" component={SubmitPage} />
          <Route path="/booking/checkout/travel-request-submit" component={TravelRequestDetailsSubmitPage} />
          {/* <Route path="**" component={PageNotFound} /> */}
        </Switch>
      </div>
    );
  }
}
export default CheckoutManiPage;
