import React, { Component } from 'react';
import { Select } from 'src/_components/common/_forms';
import SkipStep from './SkipStep';
import ChangeApprover from './ChangeApprover';
import SendEmail from './SendEmail';
import { alertConstants, actionNameConstants } from 'src/_constants';
import GenerateException from './GenerateException';
import Reject from './Reject';
import SendBackToEmployee from './SendBackToEmployee';
import ApproverPage from './ApproverModel';
import { isModelType } from 'mobx-state-tree';
export default class ActionModal extends Component {

  getModalData = (modalType = '') => {

    switch (modalType) {
      case actionNameConstants.SKIP_STEP:
        return <SkipStep
          skipStepMessageHandler={this.props.skipStepMessageHandler}
          action_value={this.props.action_value}
        />
      case actionNameConstants.CHANGE_APPROVER:
        return <ChangeApprover
          handleChangeApprover={this.props.handleChangeApprover}
          action_value={this.props.action_value}
          searchedUsers={this.props.searchedUsers}
          selectedApprover={this.props.selectedApprover}
          selectChangeApprover={this.props.selectChangeApprover}
          isSearchUserListOpen={this.props.isSearchUserListOpen}

        />
      case actionNameConstants.SEND_EMAIL:
        return <SendEmail
          allEmailTemplatesOptions={this.props.allEmailTemplatesOptions}
          emailTemplateSelectHandler={this.props.emailTemplateSelectHandler}
          action_value={this.props.action_value}
          emailChangeHandler={this.props.emailChangeHandler}
          emailDefaultApprover={this.props.emailDefaultApprover}
          emailDefaultApproverHandler={this.props.emailDefaultApproverHandler}
          selectedEmailTemplate={this.props.selectedEmailTemplate}
        />
      case actionNameConstants.GENERATE_EXCEPTION:
        return <GenerateException
          allExceptions={this.props.allExceptions}
          handleExceptionSelection={this.props.handleExceptionSelection}
          handleExceptionVisibilty={this.props.handleExceptionVisibilty}
          selectedException={this.props.selectedException}
          selectedExceptionVisibilty={this.props.selectedExceptionVisibilty}
        />
      case actionNameConstants.REJECT:
        return <Reject
          action_value={this.props.action_value}
          rejectStepMessageHandler={this.props.rejectStepMessageHandler} />


      case actionNameConstants.SEND_BACK_TO_EMPLOYEE:
        return <SendBackToEmployee
          sendBackToEmployeeHandler={this.props.sendBackToEmployeeHandler}
          action_value={this.props.action_value}
        />

      case actionNameConstants.ADD_APPROVER:
        return <ApproverPage
          handleChangeApprover={this.props.handleChangeApprover}
          action_value={this.props.action_value}
          isSearchUserListOpen={this.props.isSearchUserListOpen}
          searchedUsers={this.props.searchedUsers}
          selectedApproverId={this.props.selectedApprover.id}
          workflow_id={this.props.workflow_id}
          action_value={this.props.action_value}
          handleApproveSubmission={this.props.handleApproveSubmission}
          selectedApprover={this.props.selectedApprover}
          selectChangeApprover={this.props.selectChangeApprover}
          allWorkflowSteps={this.props.allWorkflowSteps}
          selectedRule={this.props.selectedRule}
          approverData={this.props.approverData}
          approverChangeCheckbox={this.props.approverChangeCheckbox}
        />
      default:
        return null;
    }
  }
  render() {
    return (
      <div>
        <div
          className="modal fade"
          id="ActionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddTemplateModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content border-0">
              <div className="modal-head">
                <h5 className="modal-title" id="ChangeApproverLabel">
                  Edit Action
                </h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom labelup">
                  <label>Action Name</label>
                  <Select
                    placeholder={this.props.selectedAction?.master_name_value || "Select an action"}
                    options={this.props.allActionOptionsCondition}
                    keyName='master_name_value'
                    controlFunc={this.props.handleActionSelection}
                  />
                </div>
                {
                  this.getModalData(this.props.selectedAction?.master_name_value)
                }

                <div className=" modal-last btn-group-margin mt-4">
                  <button
                    type="button"
                    className="btn btn-border-success"
                    onClick={e => this.props.handleActionCreationForRule(e, this.props.selectedAction?.master_name_value)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-border-basic close"
                    data-dismiss="modal"
                    onClick={this.props.handleApprovalCancelClick}
                  >
                    Cancel
              </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
