import { Component } from "react";
class CarTaxSettingModal extends Component {
    render() {
        return (
            <div
                className="modal fade"
                id="carTaxSettingModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="carTaxSettingModalLable"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content border-0">
                        <div className="modal-head">
                            <h5 className="modal-title" id="carTaxSettingModalLable">
                                Car Tax Settings
              </h5>
                            <button
                                type="button"
                                className="close d-none"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form>
                            <div className="modal-middle pm-30 px-0">
                                <div className="table-container mx-0">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-selected">
                                            <thead>
                                                <tr>
                                                    <th width="70%"></th>
                                                    <th width="30%"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Car Setting 1
                            </td>
                                                    <td>
                                                        <div className="btn-group-margin">
                                                            <button type="button" className="btn btn-arrow primary">
                                                                <i className="material-icons">arrow_drop_up</i>
                                                            </button>
                                                            <button type="button" className="btn btn-arrow primary">
                                                                <i className="material-icons">arrow_drop_down</i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Car Setting 2
                            </td>
                                                    <td>
                                                        <div className="btn-group-margin">
                                                            <button type="button" className="btn btn-arrow primary">
                                                                <i className="material-icons">arrow_drop_up</i>
                                                            </button>
                                                            <button type="button" className="btn btn-arrow primary">
                                                                <i className="material-icons">arrow_drop_down</i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Car Setting 3
                            </td>
                                                    <td>
                                                        <div className="btn-group-margin">
                                                            <button type="button" className="btn btn-arrow primary">
                                                                <i className="material-icons">arrow_drop_up</i>
                                                            </button>
                                                            <button type="button" className="btn btn-arrow primary">
                                                                <i className="material-icons">arrow_drop_down</i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-last btn-group-margin px-4 mx-2 mt-3">
                                    <button type="submit" className="btn" data-dismiss="modal">
                                        Cancel
                    </button>
                                    <button
                                        type="button"
                                        className="btn"
                                    >
                                        Save
                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
};
export default CarTaxSettingModal;
