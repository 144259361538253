import React, { PureComponent as Component } from "react";
import { reject, pickAll } from "lodash/fp";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import $ from "jquery";
import { createLogger } from "src/_helpers";
import * as Yup from "yup";
import "src/_helpers/yup-phone";

const visaModalLogger = createLogger("VisaModal");

class VisaModal extends Component {
  static propTypes = {
    countries: PropTypes.array,
    onSubmit: PropTypes.func,
    modalRef: PropTypes.object
  };

  validationSchema = Yup.object().shape({
    Fullname: Yup.string().required(),
    Companyname: Yup.string().required(),
    Phone: Yup.string()
      .phone()
      .required(),
    Email: Yup.string()
      .email()
      .required(),
    consent: Yup.boolean().required(),
    currentPassportCountry: Yup.string().required(),
    travellingTo: Yup.string().required()
  });

  submitLogger = visaModalLogger.extend("submit");
  handleSubmit = async (values, { setSubmitting }) => {
    this.submitLogger("Submitting Form Object: %O", values);
    const result = await this.props.onSubmit(values);

    if (result ?.error) {
      const errorMessages = pickAll(
        Reflect.ownKeys(this.validationSchema),
        result ?.error ?.message
      );
      // Object.values(errorMessages).forEach(message => toast.error(message));
    } else {
      $(`#applyforvisaModal`).modal("hide");
    }

    setSubmitting(false);
  };

  render() {
    const { countries, modalRef } = this.props;
    return (
      <div
        ref={modalRef}
        className="modal fade"
        id="applyforvisaModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="applyforvisaLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-head">
              <h6 className="modal-subtitle">New</h6>
              <h5 className="modal-title" id="applyforvisaLabel">
                Apply For Visa
              </h5>
            </div>
            <Formik
              validationSchema={this.validationSchema}
              onSubmit={this.handleSubmit}
              initialValues={{
                Fullname: "",
                Companyname: "",
                Phone: "",
                Email: "",
                currentPassportCountry: countries[0] ?.itemName || "",
                travellingTo:
                  reject(
                    country => country.itemName === countries[0] ?.itemName,
                    countries
                  )[0] ?.itemName || ""
              }}
            >
              {({
                values,
                initialValues,
                isSubmitting,
                isValid,
                isValidating
              }) => (
                  <Form
                    // onSubmit={this.handleSubmit}
                    className="modal-middle pm-30"
                    name="visaForm"
                  >
                    <div className="form-group custom labelup">
                      <label>Current Passport Country</label>
                      <Field name="currentPassportCountry">
                        {({ field, form }) => (
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn btn-border-basic dropdown-toggle btn-block"
                              data-toggle="dropdown"
                            >
                              {field.value}
                            </button>
                            <div className="dropdown-menu">
                              {countries.map(country => (
                                <button
                                  type="button"
                                  className="dropdown-item"
                                  onClick={() =>
                                    form.setFieldValue(
                                      field.name,
                                      country.name
                                    )
                                  }
                                  key={country.id}
                                >
                                  {country.name}
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="form-group custom labelup">
                      <label>Travelling To</label>
                      <Field name="travellingTo">
                        {({ field, form }) => (
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn btn-border-basic dropdown-toggle btn-block"
                              data-toggle="dropdown"
                            >
                              {field.value}
                            </button>
                            <div className="dropdown-menu">
                              {reject(
                                country =>
                                  country.itemName ===
                                  values.currentPassportCountry,
                                countries
                              ).map(country => (
                                <button
                                  type="button"
                                  className="dropdown-item"
                                  key={country.id}
                                  onClick={() =>
                                    form.setFieldValue(
                                      field.name,
                                      country.name
                                    )
                                  }
                                >
                                  {country.name}
                                </button>
                              ))}
                            </div>
                          </div>

                        )}
                      </Field>
                    </div>
                    <div className="form-group custom">
                      <Field
                        type="text"
                        name="Fullname"
                        placeholder="&nbsp;"
                        className="form-control"
                      // onChange={this.checkData("fullName")}
                      />
                      <label>Full Name <span className="text-danger">*</span></label>
                      <div className="help-block">
                        <ErrorMessage name="Fullname" />
                      </div>


                    </div>
                    <div className="form-group custom ">
                      <Field
                        type="text"
                        name="Companyname"
                        placeholder="&nbsp;"
                        className="form-control"
                      />
                      <label>Company Name <span className="text-danger">*</span></label>
                      <div className="help-block">
                        <ErrorMessage name="Companyname" />
                      </div>
                    </div>
                    <div className="form-group custom">
                      <Field
                        type="text"
                        name="Phone"
                        placeholder="&nbsp;"
                        className="form-control"
                      />
                      <label>Phone <span className="text-danger">*</span></label>
                      <div className="help-block">
                        <ErrorMessage name="Phone" />
                      </div>
                    </div>
                    <div className="form-group custom">
                      <Field
                        type="text"
                        name="Email"
                        placeholder="&nbsp;"
                        className="form-control"
                        value={values.Email}
                      />
                      <label>Email <span className="text-danger">*</span></label>
                      <div className="help-block">
                        <ErrorMessage name="Email" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="switch">
                        <Field
                          type="checkbox"
                          name="consent"
                        // defaultChecked={values.consent}
                        />
                        <span className="slider round" />
                      </label>
                      <span>
                        I consent to share the above information to visa
                        Aggregators
                    </span>
                      <div className="help-block">
                        <ErrorMessage name="consent" />
                      </div>
                    </div>
                    <div className="modal-last btn-group-margin">
                      <button
                        type="reset"
                        className="btn btn-badge"
                        data-dismiss="modal"
                      >
                        Cancel
                    </button>
                      <button
                        type="submit"
                        className="btn  "
                        disabled={
                          !values.consent ||
                          !isValid ||
                          isSubmitting ||
                          isValidating
                        }
                      >
                        Submit
                        <i className="material-icons">arrow_right</i>
                      </button>
                    </div>

                  </Form>
                )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export { VisaModal };
