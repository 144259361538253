import React, { Component } from "react";
import FormsPage from "./forms-html";
import { companyAdmin } from "../../../../../../_services/companyAdmin";
import { masterService, expensePolicies } from "../../../../../../_services";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../../_constants";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";

class Forms extends Component {
  state = {
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,
    // Add form header
    formName: "",
    formId: "",
    formHeaderId: null,
    selectedFormHeaderName:null,
    companyId: localStorage["440a28"],
    formHeaderName: [],
    selectedFormHeader: "",
    serviceModuleId: localStorage.getItem("serviceModuleId"),
    formAccess: "",
    selectedForm: [],
    forms: [],
    formNames: [],
    selectedItem: [],
    formType: "",
    createdForm: null,
    allGroups: [],
    allGroupsList: [],
    isGroupRequired: false,
    loading:true,
    loadingCreate:false,
  };

  validator = new SimpleReactValidator();
  editValidator = new SimpleReactValidator();
  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  // --------------reset state---------------------'
  resetState = () => {
    this.setState({
      formName: "",
      pageType: ""
    });
  };
  // ---------------- creating forms -------------------------- 
  handleSubmit =  async event => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let data = {
        formName: this.state.formName,
        serviceModuleId: this.state.serviceModuleId,
        formHeaderId: this.state.formHeaderId,
        pageType: this.state.pageType,
        formAccess: this.state.formAccess
      }; 
      this.setState({loadingCreate:true})
      let resp = companyAdmin.createForms(data)
      if(resp){
        toast.success(alertConstants.SUCCESS);
        $(".close").click();
        this.setState({formName: "",pageType: ""});
        this.getForms();
      }
      this.setState({loadingCreate:false}) 
    } else {
      this.validator.showMessages();
      this.setState({...this.state})
    } 
  }; 
  editFormName = form => {
    this.setState({
      formId: form.id,
      formName: form.formName,
      pageType: form.pageType
    });
    this.pageTypeName = form.pageType;
  };
  updateForm = async () => {
    if (this.editValidator.allValid()) {
      let data = {
        formName: this.state.formName
      };
      this.setState({loadingCreate:true})
      let res = await companyAdmin.editFormDetail(this.state.formId, data)
      if(res){
        this.getForms();
        $(".close").click();
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
      }
      this.setState({loadingCreate:false}) 
    } else {
      this.editValidator.showMessages();
      this.setState({...this.state})
    }
  };

  // ---------------- get forms ------------------------------
  getForms = async (id = null) => {
    this.setState({loading:true})
    let res = await companyAdmin.listFormsPLSF(this.state.activePage,
      this.state.itemPerPage,
      this.state.serviceModuleId,
      this.state.formHeaderId,
      this.state.companyId)
    if(res) {
      this.setState({
          ...this.state,
          forms: res.result,
          formNames: res.result.map(item => item.formName),
          totalItemsCount: res.count
        });
    }
    this.setState({loading:false}) 
  };

  // -------------------change status ---------------------------

  handleStatusChange = async form => {
    let newStatus = !form.activeStatus;
    let payload = {
      companyId: this.state.companyId,
      activeStatus: newStatus
    };
    let res = await companyAdmin.changeFormStatus(form.id, payload)
    if(res){
      newStatus
          ? toast.info(alertConstants.ACTIVATED_SUCESSFULLY)
          : toast.info(alertConstants.DEACTIVATED_SUCCESSFULLY);
          let newItems = this.state.forms.map(forms => {
            if (forms.id === form.id) {
              forms.activeStatus = newStatus;
            }
            return forms;
          });
          this.setState({
            forms: newItems
          });
    } 
  };

  // ---------------- get forms headers -------------------
  getFormHeader = () => {
    companyAdmin.getFormHeader(this.state.serviceModuleId).then(res => {
      if(this.state.serviceModuleId === localStorage.serviceModule){
        var name = ''
        if(sessionStorage['F12V2'] !== undefined){
          res.result.forEach(item=>{
            if(item.id === sessionStorage['F12V2']){
              name = item.formHeaderName
            }
          })
        }
        this.setState(
          {
            formHeaderName: res.result && res.result,
            selectedFormHeader: (name)?name:res.result && res.result[0].formHeaderName,
            isGroupRequired: res.result && res.result[0].isGroupRequired,
            formHeaderId: (sessionStorage['F12V2'])? sessionStorage['F12V2'] : res.result && res.result[0].id
          },
          () => {
            if (res) {
              this.getForms();
            }
          }
        );
      }else{
        this.setState(
          {
            formHeaderName: res.result && res.result,
            selectedFormHeader: res.result && res.result[0].formHeaderName,
            formHeaderId: res.result && res.result[0].id,
            isGroupRequired: res.result && res.result[0].isGroupRequired
          },
          () => {
            if (res) {
              this.getForms();
            }
          }
        );
      }
    });
  };

  // --------------Pagination--------------------------------

  handlePageChange = pageNumber => {
    this.setState({selectedForm: [],});
    this.setState({ activePage: pageNumber }, () => {
      this.getForms();
    });
  };
  handleItemChange = limit => {
    this.setState({selectedForm: [],});
    this.setState({ itemPerPage: limit }, () => {
      this.getForms();
    });
  };

  // ---------------- Create form headers -------------------

  handleHeaderButtonClick = (id, name) => {
    if (this.state.formHeaderId !== id) {
      sessionStorage.setItem('F12V2',id)
      this.setState(
        {
          selectedFormHeader: name,
          formHeaderId: id
        },
        () => {
          this.getForms();
        }
      );
    }
  };

  // ---------------- ------------------- -------------------

  // * get all groups for populating access
  getAllGroups = async  () => {
    let res = await expensePolicies.getGroups(this.state.companyId); 
    if(res !== null && res !== undefined){
      this.setState({
        allGroupsList: res.result
      });
    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getService();
    this.getAllGroups();
  }

  getService = async () => {
    var resp = await masterService.getServiceModuleListFl();
    if (resp) {
      this.listService = resp.result;
      if (localStorage.serviceModule != undefined) {
        this.setState({
          serviceModuleId: localStorage.serviceModule
        });
        this.currentModule = localStorage.serviceModule;
      } else {
        this.setState({
          serviceModuleId: resp.result && resp.result[0].id
        });
        this.changeModule(resp.result && resp.result[0].id)
      }
      this.setState({...this.state},()=>{this.getFormHeader();}) 
    }
  };
  currentModule = "";
  changeModule = type => {
    sessionStorage.removeItem('F12V2')
    this.setState(
      {
        serviceModuleId: type
      },
      () => {
        this.currentModule = type;
        localStorage.setItem("serviceModule", type);
        this.getFormHeader();
      }
    );
  };
  reset = () => {
    this.setState({
      formName: ""
    });
    this.validator.hideMessages();
    this.editValidator.hideMessages(); 
    this.setState({
      formName: ""
    });
  };
  // * what is the following code  ? probably getting old list access group from a different api
  listAccessGroup = []
  getAccessGroup = async e => {
    var resp = await companyAdmin.getAccessGroupList(this.state.formHeaderId,this.state.companyId)
    if(resp){
      this.listAccessGroup = resp.result;
    }
  };
  groupName = "Choose any group";
  handleGroup = (e)=>{
    this.setState({
      formAccess:e
    })
  }
  // * handle select for modifyForm
  selectModifyForm = selectedForm => { 
    let allGroups = [];
    let previouslySelectedForm = [...this.state.selectedForm];
    // * Removed   check for  index == -1,
    let selectedIndex = previouslySelectedForm.findIndex(form => form.id == selectedForm.id); 
    if(selectedIndex === -1){
      previouslySelectedForm = [selectedForm];
      allGroups = selectedForm.formAccess; 
      this.setState({selectedForm: previouslySelectedForm, allGroups});
    }else{
      previouslySelectedForm = [selectedForm];
      allGroups = selectedForm.formAccess; 
      this.setState({selectedForm: [], allGroups});
    }
      
  } 
  // * Create duplicate of a form
  duplicateFormHandler = async (e) => {
    e.preventDefault(); 
    let payload = {
      companyId: this.state.companyId,
      formId: this.state.selectedForm[0].id,
    }; 
    let res = await companyAdmin.duplicateForm(payload)
    if(res){ 
      toast.success("DUPLICATE FORM CREATED SUCCESSFULLY");
      this.getForms();
    }  
  }
  // * End of duplicate of a form

  // * Handle group multi select
  handleGroupChange = async (selectedGroup) => { 
    let groups = [];
    selectedGroup.forEach(element => {
      groups.push(element.value);
    });
    await  this.setState({
      allGroups: groups
    });
  };

  // * update group
  handleGroupSubmit = async (e) => {
    e.preventDefault();
      let payload = {
        companyId: this.state.companyId,
        groupIds: this.state.allGroups
      }
      let res = await companyAdmin.updateGroups(this.state.selectedForm[0].id, payload);
      if(res){
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        $('.close1').click();
        this.getForms(); 
      } 
  }

  render() {
    return (
      <div>
        <FormsPage
          fullFormData={this.state}
          validator={this.validator}
          editValidator={this.editValidator}
          updateForm={this.updateForm}
          resetState={this.resetState}
          // event handlers
          handlePageChange={this.handlePageChange}
          handleStatusChange={this.handleStatusChange}
          editFormName={this.editFormName}
          handleItemChange={this.handleItemChange}
          pageRangeDisplayed={3}
          formNameHandler={this.formNameHandler}
          handleSubmit={this.handleSubmit}
          setFormType={this.setFormType}
          handleHeaderButtonClick={this.handleHeaderButtonClick}
          toggleFormSelect={this.toggleFormSelect}
          changeModule={this.changeModule} 
          listService={this.listService}
          currentModule={this.currentModule}
          reset={this.reset}
          handleGroup={this.handleGroup}
          groupName={this.groupName}
          selectModifyForm={this.selectModifyForm}
          duplicateFormHandler={this.duplicateFormHandler}
          handleGroupChange={this.handleGroupChange}
          modifyGroupModal={this.modifyGroupModal}
          handleGroupSubmit={this.handleGroupSubmit}
        />
      </div>
    );
  }
}

export default Forms;
