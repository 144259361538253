import React from "react";
import DataType from "./components/Datatype";
import { Input, Select } from "src/_components/common/_forms";
import SelectNew from "react-select";
import DefaultField from "./components/DefaultField";
const ModifyFormsFieldsPage2 = props => {
  let {
    modifyFields,
    formNameHandler,
    handleSubmit,
    redirect,
    validator,
    handleDataType,
    onisMandatory,
    handleRoleChange,
    defaultValueChangeHandler,
    defaultValueCheckHandler,
    defaultSelectHandler,
    handleCopyDownChange,
    selectHeader,
    selectField,
    selectMultilevel,
    onMultiLevelSelectHandler
  } = props;

  const { allRoles, roles } = props.modifyFields;
  const getSelectedRole = id => {
    if (allRoles.length) {
      let index = allRoles.findIndex(role => role.roleId == id);
      if (index != -1) {
        return allRoles[index]["permissionName"];
      } else {
      }
    }
  };

  return (
    <div className="p-padding">
      <div className="form-holder">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-5">
              <h6 className="sub-heading">Modify Fields - {modifyFields.fieldName}</h6>
              <div className="form-group custom">
                <Input
                  label="Field Label"
                  value={modifyFields.labelName || ""}
                  controlFunc={formNameHandler}
                  name="labelName"
                  type="text"
                  imp="true"
                  error={validator.message(
                    "Field Label",
                    modifyFields.labelName,
                    "required"
                  )}
                />
              </div>
              {modifyFields.customField  && !modifyFields.isFormField? (
                <div className="form-group custom labelup">
                  <label>Data Type</label>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      {modifyFields.dataType
                        ? modifyFields.dataType
                        : "Select Data type"}
                    </button>
                    <div className="dropdown-menu">
                      {modifyFields.dataTypes
                        ? modifyFields.dataTypes.map(dataType => {
                          return (
                            <DataType
                              handleDataType={handleDataType}
                              key={dataType.id}
                              id={dataType.id}
                              dataType={dataType.dataType}
                            />
                          );
                        })
                        : "No Data Type found"}
                    </div>
                    <div className="help-block">
                      {validator.message(
                        "Column Name",
                        modifyFields.dataType,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                  ""
                )}
              {modifyFields.dataType &&
                modifyFields.dataType !== "connected_list" ? (
                  <div className="form-group custom">
                    <input
                      className="form-control"
                      type="text"
                      name="maxLength"
                      placeholder="&nbsp;"
                      onChange={formNameHandler}
                      value={modifyFields.maxLength}
                    />

                    <label>MaxLength</label>
                  </div>
                ) : null}

              <div className="form-group">
                <div className="switch-right">
                  <div className="right">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={onisMandatory}
                        checked={modifyFields.isMandatory ? "checked" : null}
                        name="isMandatory"
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  <span>Mandatory</span>
                </div>
              </div>
              <div className="add-border">
              </div>
              {/* End of Hidden Personal Input */}

              {/* Default Values  */}

              <h6 className="sub-heading">Default Values</h6>
              <div className="form-group">
                <div className="switch-right">
                  <div className="right">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={defaultValueCheckHandler}
                        checked={modifyFields.isDefaultValue}
                        value={modifyFields.isDefaultValue}
                        name="isDefaultValue"
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  <span>Default Value</span>
                </div>
              </div>
              {modifyFields.isDefaultValue && (
                <div>
                  {(modifyFields.isMultiLevel) ? (
                    <div>
                      {
                        modifyFields.mlFlields.map((item, idx) => {
                          if (item.fieldLevel === 1) {
                            let placeholder = modifyFields.parentOptionsDisplay.find(list => item.defaultValue === list.value)
                            return (
                              <div className="form-group custom labelup">
                                <label>{item.fieldName}</label>
                                <SelectNew
                                  key={idx}
                                  options={modifyFields.parentOptionsDisplay}
                                  onChange={(e) => selectMultilevel(e, item)}
                                  placeholder={(placeholder) ? placeholder.label : "Select here"}
                                />
                              </div>
                            );
                          }
                          else {
                            let placeholder = modifyFields[item.columnName + item.fieldLevel] && modifyFields[item.columnName + item.fieldLevel].find(list => item.defaultValue === list.value)

                            return (
                              <div className="form-group custom labelup">
                                <label>{item.fieldName}</label>
                                <SelectNew
                                  key={idx}
                                  options={modifyFields[item.columnName + item.fieldLevel]}
                                  onChange={(e) => onMultiLevelSelectHandler(e, item.fieldName + item.fieldLevel, item.id, item.multiSelectParentField, item.fieldLevel, item.fieldName)}
                                  placeholder={(placeholder) ? placeholder.label : "Select here"}
                                />
                              </div>
                            )
                          }
                        })
                      }
                    </div>
                  ) : <DefaultField
                      type={modifyFields.dataType}
                      value={modifyFields.defaultValue}
                      selectPlaceholder={modifyFields.selectPlaceholder}
                      connectedListData={modifyFields.connectedListData}
                      defaultValueChangeHandler={defaultValueChangeHandler}
                      defaultSelectHandler={defaultSelectHandler}
                    />}

                </div>
              )}

              {/* End of default values  */}
              <h6 className="sub-heading">CopyDown</h6>

              {/* Dynamic Access Rights */}

              <div className="form-group custom labelup">
                <label>Default Value Type</label>
                <Select
                  options={[
                    { id: 1, label: "CopyDown" },
                    { id: 2, label: "None" }
                  ]}
                  placeholder={(modifyFields.copyDownStatus !== '') ? modifyFields.copyDownStatus : "Select an Option"}
                  keyName="label"
                  controlFunc={e => handleCopyDownChange(e)}
                />
              </div>
              {
                (modifyFields.copyDownStatus === "CopyDown") ?
                  <div className="form-group custom labelup">
                    <label>Copy Down Source</label>
                    <Select
                      options={modifyFields.listFormHeaders}
                      placeholder={(modifyFields.selectHeader.formHeaderName) ? modifyFields.selectHeader.formHeaderName : "Select an Option"}
                      keyName="formHeaderName"
                      controlFunc={e => selectHeader(e)}
                    />
                  </div> : ""
              }

              {
                (modifyFields.selectHeader !== "") ?
                  <div className="form-group custom labelup">
                    <label>Field</label>
                    <Select
                      options={modifyFields.listFormFields}
                      placeholder={(modifyFields.selectField.columnName) ? modifyFields.selectField.columnName : "Select an Option"}
                      keyName="columnName"
                      controlFunc={e => selectField(e)}
                    />
                  </div> : ""
              }

              <h6 className="sub-heading">Access Rights</h6>
{console.log(roles)}
              {roles &&
                roles.map(roleItem => {
                  return (
                    <div
                      className="form-group custom labelup"
                      key={roleItem.id}
                    >
                      <label>{roleItem.roleName}</label>

                      <Select
                        options={modifyFields.permissionCodes}
                        placeholder={
                          (allRoles.find(item => item.roleId === roleItem.id))
                            ? getSelectedRole(roleItem.id)
                            : "Select an Option"
                        }
                        keyName="permissionName"
                        controlFunc={e => handleRoleChange(e, roleItem)}
                      />
                    </div>
                  );
                })}


              {/* End of Dynamic Access rights */}
              <div className="btn-group-margin">
                <button className="btn">Save</button>
                <button type="button" className="btn" onClick={redirect}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ModifyFormsFieldsPage2;
