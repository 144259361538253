import React, { Component } from "react";
import FieldsPage from "./fields-html";
import { companyAdmin } from "../../../../../../_services/companyAdmin";
import { masterService } from "../../../../../../_services";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../../_constants";

class Fields extends Component {
  state = {
    activePage: 1,
    itemPerPage: 10,
    totalItemsCount: 0,
    serviceModuleId: localStorage.getItem("serviceModuleId"),
    companyId: localStorage["440a28"],
    formHeaderId: null,
    formHeaderName: [],
    selectedFormHeader: "",
    sort: "asc",
    fields: [],
    loading:true,
    // selectedItemId: [],
    selectedField: [],
    // fields:
    closeModal: false,
    //-------- create fields--------

    fieldName: "",
    labelName: "",
    dataType: "text",
    columnName: "",
    tableName: "",
    maxLength: 48,
    isPiiEncrpt: "",
    isPiiNotEncrypt: "",
    isNotPii: "",
    pathCheck: sessionStorage.setItem("redirectModify", "fields")
  };
  // ----------------------set from header session id-----------------

  setSession = () => {
    if (typeof Storage !== "undefined") {
      // Store
      sessionStorage.setItem("fieldsFormHeaderId", this.state.formHeaderId);
    } else {
      console.log("Sorry, your browser does not support Web Storage...");
    }
  };

  // --------------------------------------------------------

  // -----------------get fields-----------------------------

  getFormFields = async () => {
    let data = {
      page: this.state.activePage,
      limit: this.state.itemPerPage,
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      companyId: localStorage["440a28"],
      sort: "asc"
    };
    this.setState({loading:true})
    let res = await companyAdmin.getFormFields(data)
    if(res){
      this.setState({
        fields: res.result,
        totalItemsCount: res.count
      });
    } 
    this.setState({loading:false})
  };

  // ---------------------------------------------------------

  // -----------------create fields-----------------------------

  createFields = e => {
    e.preventDefault();
    let createData = {
      fieldName: this.state.fieldName,
      labelName: this.state.labelName,
      dataType: this.state.dataType,
      columnName: this.state.columnName,
      tableName: this.state.tableName,
      maxLength: this.state.maxLength,
      serviceModuleId: this.state.serviceModuleId,
      formHeaderId: this.state.formHeaderId,
      isPiiEncrpt: this.state.isPiiEncrpt,
      isPiiNotEncrypt: this.state.isPiiNotEncrypt,
      isNotPii: this.state.isNotPii
    };
    companyAdmin
      .createFields(createData)
      .then(res => {
        this.getFormFields();
        toast.success("created fields successfully");
        this.setState({
          fieldName: "",
          labelName: "",
          dataType: "text",
          columnName: "",
          tableName: "",
          maxLength: "",
          serviceModuleId: "",
          formHeaderId: "",
          isPiiEncrpt: "",
          isPiiNotEncrypt: "",
          isNotPii: ""
        });
      })

      .catch(e => toast.error(e, alertConstants.ERROR));
  };

  // ---------------------------------------------------------

  closeToggleHandler = () => {
    this.setState({
      ...this.state,
      closeModal: !this.state.closeModal
    });
  };

  // --------------Pagination--------------------------------

  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber }, () => {
      this.getFormFields();
    });
  };
  handleItemChange = limit => {
    this.setState({ itemPerPage: limit }, () => {
      this.getFormFields();
    });
  };
  // -------------------------------------------------------

  // ------------------Handle Form -------------------------------------------

  formNameHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  // ------------------------------------------------------------------
  // ---------------- get forms headers -------------------
  getFormHeader = () => {
    companyAdmin.getFormHeader(this.state.serviceModuleId).then(res => {
      if(this.state.serviceModuleId === localStorage.serviceModule){  
        var name = '' 
        if(sessionStorage['F12V2'] !== undefined){ 
          res.result.forEach(item=>{
            if(item.id === sessionStorage['F12V2']){ 
              name = item.formHeaderName  
            }
          })
        } 
        this.setState(
          {
            formHeaderName: res.result && res.result,
            selectedFormHeader: (name)?name:res.result && res.result[0].formHeaderName,
            isGroupRequired: res.result && res.result[0].isGroupRequired,  
            formHeaderId: (sessionStorage['F12V2'])? sessionStorage['F12V2'] : res.result && res.result[0].id
          },
          () => {
            if (res) { 
              this.getFormFields();
              this.setSession();
            }
          }
        );
      }else{
        this.setState(
          {
            formHeaderName: res.result && res.result,
            selectedFormHeader: res.result && res.result[0].formHeaderName,
            formHeaderId: res.result && res.result[0].id,
            isGroupRequired: res.result && res.result[0].isGroupRequired 
          },
          () => {
            if (res) {
              this.getFormFields();
              this.setSession();
            }
          }
        );
      }   
    });
  };
  // ---------------- Create form headers -------------------

  handleHeaderButtonClick = (id, name) => { 
    if (this.state.formHeaderId !== id) {
      sessionStorage.setItem('F12V2',id)
      this.setState(
        {
          selectedFormHeader: name,
          formHeaderId: id
        },
        () => {
          this.getFormFields();
          this.setSession();
        }
      );
    }
  };

  // ---------------- ------------------- -------------------

  // -----------------------------------------------------------------

  toggleFieldSelect = field => {
    let id = field.id;
    let itemSelected = [...this.state.selectedField];
    let indexSelected = itemSelected.indexOf(field);
    if (indexSelected === -1) {
      itemSelected = [field];
    } else {
      itemSelected = [];
    }
    this.setState({ selectedField: itemSelected });
  };

  // -----------------------------------------------------------------

  // -----------------------------------------------------------------

  statusChangeHandler = field => {
    let newStatus = !field[0].activeStatus;
    let status = {
      activeStatus: newStatus,
      companyId: this.state.companyId
    };

    companyAdmin.changeFieldStatus(field[0].id, status).then(
      res => {
        newStatus
          ? toast.success(alertConstants.ACTIVATED_SUCESSFULLY)
          : toast.error(alertConstants.DEACTIVATED_SUCCESSFULLY);
        let newItems = this.state.fields.map(fields => {
          if (fields.id === field[0].id) {
            fields.activeStatus = newStatus;
          }
          return fields;
        });
        this.setState({
          fields: newItems
        });
      },
      err => {
        toast.error(alertConstants.ERROR);
      }
    );
  };

  // -----------------------------------------------------------------

  // -------------------change status ---------------------------

  handleStatusChange = field => {
    let newStatus = !field.activeStatus;
    let status = {
      activeStatus: newStatus,
      companyId: this.state.companyId
    };
    companyAdmin.changeFieldStatus(field.id, status).then(
      res => {
        newStatus
          ? toast.success(alertConstants.UPDATED_SUCCESSFULLY)
          : toast.error(alertConstants.DEACTIVATED_SUCCESSFULLY);

        let newItems = this.state.fields.map(fields => {
          if (fields.id === field.id) {
            fields.activeStatus = newStatus;
          }
          return fields;
        });
        this.setState({
          fields: newItems
        });
      },
      err => {
        toast.error(err, alertConstants.ERROR);
      }
    );
  };

  //-------------------------------------------------------------

  // ---------------- ------ ------ -------------------------
  componentDidMount() {
    this.getService();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  getService = async () => {
    var resp = await masterService.getServiceModuleListFl();
    if (resp) {
      this.listService = resp.result;
      if (localStorage.serviceModule != undefined) {
        this.setState({
          serviceModuleId: localStorage.serviceModule
        });
        this.currentModule = localStorage.serviceModule;
      } else {
        this.setState({
          serviceModuleId: resp.result && resp.result[0].id
        });
        this.currentModule = resp.result && resp.result[0].id;
      }
      this.forceUpdate();
      this.getFormHeader();
    }
  };
  currentModule = "";
  changeModule = type => {
    sessionStorage.removeItem('F12V2')
    this.setState(
      {
        serviceModuleId: type
      },
      () => {
        this.currentModule = type;
        localStorage.setItem("serviceModule", type);
        this.getFormHeader();
      }
    );
  };
  render() {
    return (
      <div>
        <FieldsPage
          fields={this.state}
          loading={this.state.loading}
          handlePageChange={this.handlePageChange}
          handleItemChange={this.handleItemChange}
          handleStatusChange={this.handleStatusChange}
          closeToggleHandler={this.closeToggleHandler}
          closeModal={this.state.closeModal}
          createFields={this.createFields}
          formNameHandler={this.formNameHandler}
          rowClick={this.rowClick}
          toggleFieldSelect={this.toggleFieldSelect}
          statusChangeHandler={this.statusChangeHandler}
          handleHeaderButtonClick={this.handleHeaderButtonClick}
          changeModule={this.changeModule}
          listService={this.listService}
          currentModule={this.currentModule}
        />
      </div>
    );
  }
}

export default Fields;
