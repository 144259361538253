import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class Menu extends Component {
    render() {
        return (
            <ul className="nav nav-tabs">
                <li><NavLink to="/administration/travel/rule-class" className="show">Rule Class</NavLink></li>
                <li><NavLink to="/administration/travel/travel-policy">Travel Policy</NavLink></li>
                <li><NavLink to="/administration/travel/policy-violation">Policy Violations Reason</NavLink></li>
                <li><NavLink to="/administration/travel/rule-template">Travel Rule Template</NavLink></li>
                <li><NavLink to="/administration/travel/travel-vendor-exclusion">Travel Vendor Exclusion</NavLink>
                </li>
            </ul>
        )
    }
}
export default Menu