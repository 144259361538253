import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { taxNumberService } from "src/_services/taxNumber.service";
import { checkObjectHasProperties } from "src/utils/util";
import Loader from "src/_components/common/Loader";
import { toast } from "react-toastify";
import { alertConstants } from "src/_constants";
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { Input } from "src/_components/common/_forms";
import HotelTaxSettingModal from "./_components/hotelTaxSettingModal";
import TrainTaxSettingModal from "./_components/trainTaxSettingModal";
import CarTaxSettingModal from "./_components/carTaxSettingModal";
import BusTaxSettingModal from "./_components/busTaxSettingModal";
class TaxNumberPage extends Component {

  state = {
    companyId: localStorage["440a28"],
    isTaxDetailLoading: false,
    companyTaxDocument: "",
    selectedTaxNumber: "",
    countries: [],
    states: [],
    locations: [],
    services: [],

    selectedCountry: "",
    selectedState: "",
    selectedLocation: "",
    selectedService: "",
    zipCode: "",
    companyName: "",
    contactNumber: "",
    companyAddress: "",
    email: "",
    gstNumber: "",

    isEdit: false,
    settingsType: 1,
    selectedIdForEdit: ""




  }
  validator = new SimpleReactValidator();
  validator2 = new SimpleReactValidator();

  componentDidMount() {
    this.getAllTaxDetails();
    this.getAllCountries();
    this.getAllCategories();
  }

  getAllTaxDetails = async () => {
    this.setState({ isTaxDetailLoading: true });
    let result = await taxNumberService.getAllTaxDetails(this.state.companyId);
    if (checkObjectHasProperties(result)) {
      this.setState({
        isTaxDetailLoading: false,
        companyTaxDocument: result
      });

    } else {
      this.setState({ isTaxDetailLoading: false });

    }
  }

  getAllCountries = async () => {
    let response = await taxNumberService.getAllCountries();
    if (response && response.result && response.result.length) {
      this.setState({
        countries: response.result.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      })
    }
  }

  getAllCategories = async () => {
    let response = await taxNumberService.getAllCategories();
    if (response && response.result && response.result.length) {
      this.setState({
        services: response.result.map(item => {
          return {
            value: item.id,
            label: item.travelCategoryName,
          }
        })
      })
    }
  }

  handleStatusToggle = async (id, activeStatus, e) => {
    e.stopPropagation();
    let payload = {
      id,
      activeStatus
    }
    let result = await taxNumberService.toggleTaxNumberStatus(this.state.companyTaxDocument.id, payload);
    if (result) {
      this.getAllTaxDetails()
    } else {
      // alert('donezo')
    }

  }

  handleAddTaxModal = () => {
    this.setState({ isEdit: false });
    this.clearSetFields();
  }

  clearSetFields = () => {
    this.setState({
      companyName: "",
      selectedLocation: "",
      selectedCountry: "",
      selectedState: "",
      zipCode: "",
      contactNumber: "",
      gstNumber: "",
      companyAddress: "",
      email: "",
      selectedService: ""
    })
  }

  handleTaxNumberEdit = async (item, e) => {

    await this.getAllStates(item ?.country ?.id);
    await this.getAllLocations(item ?.state ?.id);

    this.setState({
      isEdit: true,
      selectedIdForEdit: item._id,
      companyName: item.companyName,
      selectedLocation: {
        label: item ?.locationDetails ?.name,
        value: item ?.locationDetails ?.id
      },
      selectedCountry: {
        label: item ?.country ?.name,
        value: item ?.country ?.id
      },
      selectedState: {
        label: item ?.state ?.name,
        value: item ?.state ?.id
      },
      zipCode: item.zipCode,
      contactNumber: item.gstContact,
      gstNumber: item.gstNumber,
      companyAddress: item.gstAddress,
      email: item.gstEmail,
      selectedService: item.serviceIds.map(item => {
        return {
          value: item.id,
          label: item.value
        }
      })




    })
  }

  handleTaxNumberSelect = (id) => {
    if (this.state.selectedTaxNumber === id) {
      this.setState({ selectedTaxNumber: "" })
    } else {
      this.setState({ selectedTaxNumber: id });
    }
  }

  handleTaxNumberDelete = async () => {
    if (this.state.selectedTaxNumber) {
      let payload = {
        taxIds: [this.state.selectedTaxNumber]
      }
      let response = await taxNumberService.deleteTaxNumber(this.state.companyTaxDocument.id, payload);
      if (response) {
        if (response.message === "Something went wrong") {
          toast.warn(response.message)
        } else {
          toast.success(alertConstants.DELETED_SUCCESSFULLY);
          this.getAllTaxDetails();
          this.setState({ selectedTaxNumber: "" })
        }
      }

    }
  }



  handleSettingsOrder = (type, index, settingsType) => {
    let flightSettings = [...this.state.companyTaxDocument.flightSettings];
    let hotelSettings = [...this.state.companyTaxDocument.hotelSettings]
    if (settingsType === 'flightSettings') {
      let temp = "";
      switch (type) {
        case 'up':
          temp = flightSettings[index];
          flightSettings[index] = flightSettings[index - 1];
          flightSettings[index - 1] = temp;
          break;
        case 'down':
          temp = flightSettings[index];
          flightSettings[index] = flightSettings[index + 1];
          flightSettings[index + 1] = temp;
          break;
        default:
          return;
      }

    } else if (settingsType === "hotelSettings") {
      let temp2 = "";
      switch (type) {
        case 'up':
          temp2 = hotelSettings[index];
          hotelSettings[index] = hotelSettings[index - 1];
          hotelSettings[index - 1] = temp2;
          break;
        case 'down':
          temp2 = hotelSettings[index];
          hotelSettings[index] = hotelSettings[index + 1];
          hotelSettings[index + 1] = temp2;
          break;
        default:
          return;
      }
    }
    this.setState({
      companyTaxDocument: {
        ...this.state.companyTaxDocument,
        flightSettings,
        hotelSettings
      }
    })
  }

  handleSettingsSubmit = async (settingsType, e) => {
    e.preventDefault();
    let payload = {
      companyId: this.state.companyId,
      flightSettings: this.state.companyTaxDocument.flightSettings,
      hotelSettings: this.state.companyTaxDocument.hotelSettings
    };
    let response = await taxNumberService.updateSettings(payload);
    if (response) {
      $('.close').click();
      toast.success(response.message);
      this.getAllTaxDetails();
    }


  }

  handleCountrySelect = (val, type) => {
    switch (type) {
      case 'add':
        this.setState({
          selectedCountry: val
        });
        this.getAllStates(val.value);
        this.setState({
          selectedState: "",
          selectedLocation: ""
        })
    }
  }

  getAllStates = async (id) => {
    let response = await taxNumberService.getAllStates(id);
    if (response && response.result && response.result.length) {
      this.setState({
        states: response.result.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      });
    }
  }

  handleStateSelect = (val, type) => {
    switch (type) {
      case 'add':
        this.setState({
          selectedState: val
        });
        this.getAllLocations(val.value);
        this.setState({ selectedLocation: "" });
    }
  }

  getAllLocations = async (id) => {
    let response = await taxNumberService.getAllLocations(id);
    if (response && response.result && response.result.length) {
      this.setState({
        locations: response.result.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      })
    }
  }

  handleLocationSelect = (val, type) => {
    switch (type) {
      case 'add':
        this.setState({ selectedLocation: val })
    }
  }

  handleServicesSelect = (val, type) => {
    switch (type) {
      case 'add':
        this.setState({
          selectedService: val
        });
        break;
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmitTaxNumber = async (e, type) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let payload = {
        companyId: this.state.companyId,
        companyName: this.state.companyName,
        locationName: this.state.selectedLocation.value,
        countryId: this.state.selectedCountry.value,
        stateId: this.state.selectedState.value,
        zipCode: this.state.zipCode,
        gstContact: this.state.contactNumber,
        serviceIds: this.state.selectedService.map(item => {
          return {
            id: item.value,
            value: item.label
          }
        }),
        gstNumber: this.state.gstNumber,
        gstAddress: this.state.companyAddress,
        gstEmail: this.state.email

      }
      if (type === 'add') {
        let addResponse = await taxNumberService.addTaxNumber(payload);
        if (addResponse) {
          $('.close').click();
          this.getAllTaxDetails();
        }
      } else {
        payload.id = this.state.selectedIdForEdit;
        let updateResponse = await taxNumberService.updateTaxNumber(payload, this.state.companyTaxDocument.id);
        if (updateResponse) {
          $('.close').click();
          this.getAllTaxDetails();
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }



  }

  getServiceIdDetails = (serviceIds) => {
    let res = serviceIds.map(item => item.value);
    return res.join(' ');
  }

  handleDefaultToggle = async (id, activeStatus, e) => {
    e.stopPropagation();
    let payload = {
      id,
      default: activeStatus
    }
    let result = await taxNumberService.toggleDefault(this.state.companyTaxDocument.id, payload);
    if (result) {
      this.getAllTaxDetails()
    } else {
      console.log('Some error occured');
    }
  }

  handleSettingsType = (settingsType) => {
    this.setState({settingsType})
  }

  render() {

    return (
      <div>

        <div className="">
          <div className="inner-header">
            <div className="shape-header">
              <div className="left">F</div>
              <div className="right">
                <h6 className="heading">Tax Numbers</h6>
                <p className="text">
                  Manage various GST number based on company policy.
              </p>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="py-padding">
            <div className="table-container">
              <form>
                <div className="row">
                  <div className="col-sm-9">
                    <div className="btn-group-margin">
                      <NavLink
                        to="#" data-toggle="modal" data-target="#addTaxModal"
                        onClick={this.handleAddTaxModal}
                        className="btn"
                      >
                        Add
                    </NavLink>
                      <button
                        type="button"
                        className="btn"
                        disabled={!this.state.selectedTaxNumber}
                        onClick={this.handleTaxNumberDelete}
                      >
                        Remove
                      </button>
                      <button onClick={e => this.handleSettingsType(1)} type="button" className="btn hover-v" data-target="#taxSettingModal" data-toggle="modal">
                        <i className="material-icons fs-20">flight</i>
                        <span className="hover-name bottom">Flight Settings</span>
                      </button>
                      <button onClick={e => this.handleSettingsType(2)} type="button" className="btn hover-v" data-target="#taxSettingModal" data-toggle="modal">
                        <i className="material-icons fs-20">hotel</i>
                        <span className="hover-name bottom">Hotel Settings</span>
                      </button>
                      {/* <button type="button" className="btn hover-v" data-target="#carTaxSettingModal" data-toggle="modal">
                        <i className="material-icons fs-20">directions_car</i>
                        <span className="hover-name bottom">Car Settings</span>
                      </button>
                      <button type="button" className="btn hover-v" data-target="#trainTaxSettingModal" data-toggle="modal">
                        <i className="material-icons fs-20">train</i>
                        <span className="hover-name bottom">Train Settings</span>
                      </button>
                      <button type="button" className="btn hover-v" data-target="#busTaxSettingModal" data-toggle="modal">
                        <i className="material-icons fs-20">directions_bus</i>
                        <span className="hover-name bottom">Bus Settings</span>
                      </button> */}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="search-custom">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        autoComplete="off"
                      />
                      <span>
                        <i className="material-icons">search</i>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-striped table-selected">
                  <thead>
                    <tr>
                      <th>Country</th>
                      <th>State</th>
                      <th>Company Name</th>
                      <th>Tax Number</th>
                      <th>Services</th>
                      <th>Action</th>
                      <th>Status</th>
                      <th>Default</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.isTaxDetailLoading &&
                      <tr>
                        <td colSpan="8">
                          <Loader />

                        </td>
                      </tr>
                    }
                    {
                      !this.state.isTaxDetailLoading &&
                      this.state.companyTaxDocument &&
                      this.state.companyTaxDocument.taxDetails &&
                      this.state.companyTaxDocument.taxDetails.length == 0 &&
                      <p>No Results Found.</p>
                    }

                    {!this.state.isTaxDetailLoading &&
                      this.state.companyTaxDocument &&
                      this.state.companyTaxDocument.taxDetails &&
                      this.state.companyTaxDocument.taxDetails.length &&
                      this.state.companyTaxDocument.taxDetails.map(item => {
                        return (

                          <tr className={this.state.selectedTaxNumber === item._id ? "active-tr" : ""}
                            onClick={e => this.handleTaxNumberSelect(item._id)}
                            key={item._id}
                          >
                            <td>{item ?.country ?.name}</td>
                            <td>{item ?.state ?.name}</td>
                            <td>{item ?.companyName}</td>
                            <td>{item ?.gstNumber}</td>
                            <td>{item && item.serviceIds && item.serviceIds.length && this.getServiceIdDetails(item.serviceIds)}
                            </td>
                            <td>
                              <button className="btn hover-v btn-nothing" data-toggle="modal" data-target="#addTaxModal" onClick={e => this.handleTaxNumberEdit(item, e)} >
                                <i className="material-icons">edit</i>
                                <span className="hover-name">Edit</span>
                              </button>
                            </td>
                            <td>
                              <label className="switch">
                                <input type="checkbox"
                                  value={item.activeStatus}
                                  checked={item.activeStatus}
                                  onChange={e => this.handleStatusToggle(item._id, item.activeStatus, e)}

                                />
                                <span className="slider round" />
                              </label>
                            </td>
                            <td>
                              <label className="switch">
                                <input type="checkbox"
                                  value={item.default}
                                  checked={item.default}
                                  onChange={e => this.handleDefaultToggle(item._id, item.default, e)}

                                />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                        )
                      })

                    }


                  </tbody>
                </table>
              </div>

              <div className="table-footer">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            10
                        </button>
                          <div className="dropdown-menu">
                            <button
                              type="button" className="dropdown-item">
                              10
                        </button>
                            <button
                              type="button" className="dropdown-item">
                              20
                        </button>
                            <button
                              type="button" className="dropdown-item">
                              30
                        </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-9">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="addTaxModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addTaxLable"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <h5 className="modal-title" id="addTaxLable">
                  {
                    this.state.isEdit ? "Add Tax" : "Edit tax"
                  }
                </h5>
                <button
                  type="button"
                  className="close d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form>
                <div className="modal-middle pm-30">
                  <div className="form-group custom labelup">
                    {
                      this.state.countries.length &&
                      <Select
                        value={this.state.selectedCountry}
                        options={this.state.countries}
                        onChange={val => this.handleCountrySelect(val, 'add')}
                      />
                    }
                    <div className="help-block">
                      {this.validator.message('Country', this.state.selectedCountry, 'required')}

                    </div>

                    <label>Country</label>
                  </div>
                  <div className="form-group custom labelup">
                    <Select
                      value={this.state.selectedState}
                      options={this.state.states}
                      onChange={val => this.handleStateSelect(val, 'add')}
                    />
                    <div className="help-block">
                      {this.validator.message('State', this.state.selectedState, 'required')}

                    </div>
                    <label>State</label>
                  </div>
                  <div className="form-group custom labelup">
                    <Select
                      value={this.state.selectedLocation}
                      options={this.state.locations}
                      onChange={val => this.handleLocationSelect(val, 'add')}
                    />
                    <div className="help-block">
                      {this.validator.message('Location', this.state.selectedLocation, 'required')}

                    </div>
                    <label>Location</label>
                  </div>
                  <div className="form-group custom ">
                    <Input
                      label="Zip Code"
                      type="text"
                      value={this.state.zipCode}
                      name="zipCode"
                      controlFunc={this.handleInputChange}
                      error={this.validator.message('Zip Code ', this.state.zipCode, 'required')}
                    />
                  </div>
                  <div className="form-group custom ">
                    <Input
                      label="Company Name"
                      type="text"
                      value={this.state.companyName}
                      name="companyName"
                      controlFunc={this.handleInputChange}
                      error={this.validator.message('Company Name', this.state.companyName, 'required')}
                    />
                  </div>

                  <div className="form-group custom ">
                    <Input
                      label="Company Contact Number"
                      type="text"
                      value={this.state.contactNumber}
                      name="contactNumber"
                      controlFunc={this.handleInputChange}
                      error={this.validator.message('Contact Number', this.state.contactNumber, 'required|numeric')}
                    />
                  </div>

                  <div className="form-group custom">
                    <Input
                      label="Tax Number"
                      type="text"
                      value={this.state.gstNumber}
                      name="gstNumber"
                      controlFunc={this.handleInputChange}
                      error={this.validator.message('Tax Number', this.state.gstNumber, 'required|size:15')}
                    />
                  </div>
                  <div className="form-group custom">
                    <Input
                      label="Address"
                      type="text"
                      value={this.state.companyAddress}
                      name="companyAddress"
                      controlFunc={this.handleInputChange}
                      error={this.validator.message('Address', this.state.companyAddress, 'required')}
                    />
                  </div>
                  <div className="form-group custom">
                    <Input
                      label="Email"
                      type="text"
                      value={this.state.email}
                      name="email"
                      controlFunc={this.handleInputChange}
                      error={this.validator.message('Email', this.state.email, 'required|email')}

                    />
                  </div>

                  <div className="form-group custom labelup">
                    <Select
                      value={this.state.selectedService}
                      options={this.state.services}
                      onChange={val => this.handleServicesSelect(val, 'add')}
                      isMulti

                    />
                    <div className="help-block">
                      {this.validator.message('Service', this.state.selectedService, 'required')}

                    </div>
                    <label>Services</label>
                  </div>

                  <div className="modal-last btn-group-margin">
                    <button type="submit" className="btn" data-dismiss="modal">
                      Cancel
                    </button>
                    {
                      this.state.isEdit ? (
                        <button
                          type="button"
                          className="btn"
                          onClick={e => this.handleSubmitTaxNumber(e, 'edit')}
                        >
                          Update
                        </button>
                      ) : (
                          <button
                            type="button"
                            className="btn"
                            onClick={e => this.handleSubmitTaxNumber(e, 'add')}
                          >
                            Save
                        </button>
                        )
                    }

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="taxSettingModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="taxSettingLable"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <h5 className="modal-title" id="taxSettingLable">
                  Tax Settings
              </h5>
                <button
                  type="button"
                  className="close d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form>
                <div className="modal-middle pm-30 px-0">
                  <div className="table-container mx-0">
                    <div className="table-responsive">
                      <table className="table table-striped table-selected">
                        <thead>
                          <tr>
                            <th width="70%"></th>
                            <th width="30%"></th>
                          </tr>
                        </thead>
                        <tbody>
                          { this.state.settingsType == 1 &&
                            this.state.companyTaxDocument &&
                            this.state.companyTaxDocument.flightSettings &&
                            this.state.companyTaxDocument.flightSettings.length &&
                            this.state.companyTaxDocument.flightSettings.map((flightSetting, index) => {
                              return (
                                <tr key={index + flightSetting}>
                                  <td>
                                    {flightSetting}
                                  </td>
                                  <td>
                                    <div className="btn-group-margin">

                                      <button disabled={!index} onClick={e => this.handleSettingsOrder('up', index, 'flightSettings')} type="button" className="btn btn-arrow primary">
                                        <i className="material-icons">arrow_drop_up</i>
                                      </button>

                                      <button onClick={e => this.handleSettingsOrder('down', index, 'flightSettings')} disabled={index == this.state.companyTaxDocument.flightSettings.length - 1} type="button" className="btn btn-arrow primary">
                                        <i className="material-icons">arrow_drop_down</i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }
                          { this.state.settingsType == 2 &&
                            this.state.companyTaxDocument &&
                            this.state.companyTaxDocument.hotelSettings &&
                            this.state.companyTaxDocument.hotelSettings.length &&
                            this.state.companyTaxDocument.hotelSettings.map((hotelSetting, index) => {
                              return (
                                <tr key={index + hotelSetting}>
                                  <td>
                                    {hotelSetting}
                                  </td>
                                  <td>
                                    <div className="btn-group-margin">

                                      <button disabled={!index} onClick={e => this.handleSettingsOrder('up', index, 'hotelSettings')} type="button" className="btn btn-arrow primary">
                                        <i className="material-icons">arrow_drop_up</i>
                                      </button>

                                      <button onClick={e => this.handleSettingsOrder('down', index, 'hotelSettings')} disabled={index == this.state.companyTaxDocument.hotelSettings.length - 1} type="button" className="btn btn-arrow primary">
                                        <i className="material-icons">arrow_drop_down</i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }


                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="modal-last btn-group-margin px-4 mx-2 mt-3">
                    <button type="submit" className="btn" data-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn"
                      onClick={e => this.handleSettingsSubmit("flightSettings", e)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <HotelTaxSettingModal />
        <TrainTaxSettingModal />
        <CarTaxSettingModal />
        <BusTaxSettingModal />
      </div>
    );
  }
};
export default TaxNumberPage;
