import React from 'react';


function RulesDropdown(props) {
  const {
    handleButtonClick,
    name,
    rule
  } = props;
  return (
    <button type="button" onClick={e => handleButtonClick(rule)} className="dropdown-item">
      {name}
    </button>
  )
}

export default RulesDropdown;
