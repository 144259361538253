import React from "react";
import Menu from "../menu";
const DelegatePage = props => {
  let data = props;
  return (
    <div>
      <div className="admin-tabs">
        <Menu />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">E</div>
            <div className="right">
              <h6 className="heading">Expense Delegates</h6>
              <p className="text">Lorem Ipsum is simply dummy text.</p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="dragndrop delegate">
            <div className="dnd border-0">
              <div className="set-width">
                <div className="container">
                  <div className="row mx-1 my-3">
                    <div classname="col">
                      <div className="search-custom">
                        <input
                          type="text"
                          className="form-control bg-light sm-padding"
                          placeholder="Search Name"
                        />
                        <span>
                        <i className="material-icons">search</i>
                        </span>
                      </div>
                    </div>
                    <div className="col px-0">
                      <div className="text-right">
                        <button
                          type="button"
                          className="btn   btn border-primary text-primary bg-white "
                          data-toggle="modal"
                          data-target="#EditFormModal2"
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn border-primary text-primary bg-white ml-3"
                          data-toggle="modal"
                          data-target="#EditFormModal"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="scroll">
                  <ul className="border">
                    <li>
                      <label>
                        <div className="float-left">
                          <label className="checkbox-container mt-3">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="float-left">
                          <h5 className="text-primary">Sophia</h5>
                          <h6>Sophia@me.com</h6>
                        </div>
                        <div className="clearfix" />
                      </label>
                    </li>
                    <li>
                      <label>
                        <div className="float-left">
                          <label className="checkbox-container mt-3">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="float-left">
                          <h5 className="text-primary">Sophia</h5>
                          <h6>Sophia@me.com</h6>
                        </div>
                        <div className="clearfix" />
                      </label>
                    </li>
                    <li>
                      <label>
                        <div className="float-left">
                          <label className="checkbox-container mt-3">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="float-left">
                          <h5 className="text-primary">Sophia</h5>
                          <h6>Sophia@me.com</h6>
                        </div>
                        <div className="clearfix" />
                      </label>
                    </li>
                    <li>
                      <label>
                        <div className="float-left">
                          <label className="checkbox-container mt-3">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="float-left">
                          <h5 className="text-primary">Sophia</h5>
                          <h6>Sophia@me.com</h6>
                        </div>
                        <div className="clearfix" />
                      </label>
                    </li>
                    <li>
                      <label>
                        <div className="float-left">
                          <label className="checkbox-container mt-3">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="float-left">
                          <h5 className="text-primary">Sophia</h5>
                          <h6>Sophia@me.com</h6>
                        </div>
                        <div className="clearfix" />
                      </label>
                    </li>
                    <li>
                      <label>
                        <div className="float-left">
                          <label className="checkbox-container mt-3">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="float-left">
                          <h5 className="text-primary">Sophia</h5>
                          <h6>Sophia@me.com</h6>
                        </div>
                        <div className="clearfix" />
                      </label>
                    </li>
                    <li>
                      <label>
                        <div className="float-left">
                          <label className="checkbox-container mt-3">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="float-left">
                          <h5 className="text-primary">Sophia</h5>
                          <h6>Sophia@me.com</h6>
                        </div>
                        <div className="clearfix" />
                      </label>
                    </li>
                    <li>
                      <label>
                        <div className="float-left">
                          <label className="checkbox-container mt-3">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="float-left">
                          <h5 className="text-primary">Sophia</h5>
                          <h6>Sophia@me.com</h6>
                        </div>
                        <div className="clearfix" />
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="set-width">
                <div className="container">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <h5>Ravi</h5>
                      <h6>Ravi@bigappcompany.in</h6>
                    </div>
                    <div className="col-md-8">
                      <div className="text-right">
                        <button
                          type="button"
                          className="btn border-primary text-primary bg-white "
                          data-toggle="modal"
                          data-target="#EditFormModal2"
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn border-primary text-primary bg-white ml-3"
                          data-toggle="modal"
                          data-target="#EditFormModal2"
                        >
                          Add{" "}
                        </button>
                        <button
                          type="button"
                          className="btn border-primary text-primary bg-white ml-3 "
                          data-toggle="modal"
                          data-target="#EditFormModal2"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="scroll">
                  <ul className="border ml-2">
                    <li>
                      <label>
                        <div className="float-left">
                          <label className="checkbox-container mt-3">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="float-left">
                          <h5 className="text-primary">Sophia</h5>
                          <h6>Sophia@me.com</h6>
                        </div>
                        <div className="clearfix" />
                      </label>
                    </li>
                    <li>
                      <label>
                        <div className="float-left">
                          <label className="checkbox-container mt-3">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="float-left">
                          <h5 className="text-primary">Sophia</h5>
                          <h6>Sophia@me.com</h6>
                        </div>
                        <div className="clearfix" />
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="EditFormModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="EditFormModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="EditFormModalLabel">
                Add Delegate
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="search-custom set-border">
              <input
                type="text"
                name=""
                className="add-border form-control bg-light"
                placeholder="Search"
              />
              <span>
              <i className="material-icons">search</i>
              </span>
            </div>
            <div className="modal-body p-0">
              <div className="tabs-container my-container">
                <div className="setwidth">
                  <div className="scroll">
                    <ul>
                      <li className="selected ">
                        <button className="btn btn-nothing">
                          <span className="pl-2">Chandler Bing</span>
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-nothing">
                          <span className="pl-2">Ross Geller</span>
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-nothing">
                          <span className="pl-2">Joey Tribiani</span>
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-nothing">
                          <span className="pl-2">Pheobe Buffay</span>
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-nothing">
                          <span className="pl-2">Monika Geller</span>
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-nothing">
                          <span className="pl-2">Rachel Green</span>
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-nothing">
                          <span className="pl-2">Rachel Green</span>
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-nothing">
                          <span className="pl-2">Rachel Green</span>
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-nothing">
                          <span className="pl-2">Rachel Green</span>
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-nothing">
                          <span className="pl-2">Ross Geller</span>
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-nothing">
                          <span className="pl-2">Joey Tribiani</span>
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-nothing">
                          <span className="pl-2">Pheobe Buffay</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-border-success lg-padding"
              >
                ADD
              </button>
              <button
                type="button"
                className="btn btn-border-basic lg-padding"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="EditFormModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="EditFormModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="EditFormModalLabel">
                Settings
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body py-0">
              <div className="inner-header px-2">
                <div className="shape-header">
                  <div className="left">M</div>
                  <div className="right">
                    <h6 className="heading">Mia</h6>
                    <p className="text">mia@me.com</p>
                  </div>
                </div>
              </div>
              <div className="audit-step py-padding ">
                <button type="button" className="s1 btn active">
                  Expense
                </button>
                <button type="button" className="s1 btn">
                  Booking
                </button>
                <button type="button" className="s1 btn">
                  Report
                </button>
                <button type="button" className="s1 btn">
                  Approvel
                </button>
              </div>
              <div className=" step step-1 ">
                <div className="container">
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped table-movable border-0">
                        <tbody>
                          <tr>
                            <td>Can Submit Requests</td>
                            <td className="text-right">
                              <label className="switch">
                                <input type="checkbox" checked />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can View Receipts</td>
                            <td className="text-right">
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" step step-2 d-none">
                <div className="container">
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped table-movable border-0">
                        <tbody>
                          <tr>
                            <td>Can Prepare</td>
                            <td className="text-right">
                              <label className="switch">
                                <input type="checkbox" checked />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can Travel</td>
                            <td className="text-right">
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" step step-3 d-none">
                <div className="container">
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped table-movable border-0">
                        <tbody>
                          <tr>
                            <td>Can Submit Reports</td>
                            <td className="text-right">
                              <label className="switch">
                                <input type="checkbox" checked />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can View Receipts</td>
                            <td className="text-right">
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can Use Reporting</td>
                            <td className="text-right">
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" step step-4 d-none">
                <div className="container">
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped table-movable border-0">
                        <tbody>
                          <tr>
                            <td>Can Approve</td>
                            <td className="text-right">
                              <label className="switch">
                                <input type="checkbox" checked />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can Approve temporary </td>
                            <td className="text-right">
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Can Preview for Approver</td>
                            <td className="text-right">
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Receives Approve Emails</td>
                            <td className="text-right">
                              <label className="switch">
                                <input type="checkbox" />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-border-success lg-padding"
                data-dismiss="modal"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-border-basic lg-padding"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DelegatePage;
