import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
class ConfigRulesMenu extends Component {
  state = {
    carId: ''
  }
  componentDidMount = () => {
    let carId = JSON.parse(localStorage.getItem("carId"));
    this.setState({ carId });
  }
  render() {
    return (
      <div>
        <div className="audit-step">
          <NavLink
            className="s1"
            to={`/administration/expense/car-config/config-step1/${JSON.parse(localStorage.getItem('carId'))}`}
          >
            <span>1</span>
            General
          </NavLink>
          <NavLink
            to="/administration/expense/car-config/config-step2"
            className="s1"
          >
            <span>2</span>
            Distance Limits
          </NavLink>
          <NavLink
            className="s1"
            to="/administration/expense/car-config/config-step3"
          >
            <span>3</span>
            Criteria
          </NavLink>
          <NavLink
            className="s1"
            to="/administration/expense/car-config/config-step4"
          >
            <span>4</span>
            Rates
          </NavLink>
        </div>

      </div>
    );
  }
}
export default withRouter(ConfigRulesMenu);
