import React from "react";
import Menu from "../menu";
import { NavLink } from "react-router-dom";
import { Input } from 'src/_components/common/_forms';
import Pagination from "react-js-pagination";
import { Select } from "../../../../../common/_forms";
import Loader from "src/_components/common/Loader";

const CarContractPage = props => {
  const {
    cityList,
    isSearchCityListOpen,
    selectedCity,
    cityCars,
    companyId,
    limit,
    page,
    removeCity,
    removeButton,
    isLoading
  } = props.state;

  const handleChangeCity = props.handleChangeCity;
  const selectChangeCity = props.selectChangeCity;
  const saveTheSelectedCity = props.saveTheSelectedCity;
  const validator = props.validator;
  return (
    <div>
      <div className="admin-tabs">
        <Menu />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">Car - Contracts</h6>
              <p className="text">Use Car Contracts to manage company owned or company contracted Cars.</p>
            </div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-9">
                  <div className="btn-group-margin">
                    <button
                      type="button"
                      className="btn "
                      data-toggle="modal"
                      data-target="#AddCityModal"
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      className="btn "
                      disabled={!removeButton}
                      onClick={props.removeCityHandler}
                    >
                      Remove
                    </button>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Action
                      </button>
                      <div className="dropdown-menu">
                        <button className="dropdown-item">Make Inactive</button>
                        <button className="dropdown-item">Delete</button>
                        <button className="dropdown-item">Edit</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="search-custom">
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Search"
                      value={props.search}
                      onChange={(e) => props.onSearchTextChangeHandler(e)}
                    />
                    <span>
                      <i className="material-icons align-middle">
                        search
                      </i>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>City name</th>
                    <th>Total cars</th>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (isLoading) ?
                      <tr>
                        <td colSpan="4">
                          <Loader />
                        </td>
                      </tr>
                      :
                      cityCars && cityCars.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className={
                              removeCity === item.id ? "active-tr" : ""
                            }
                          >
                            <td onClick={e => props.onDeleteHandler(item.id)}>
                              {item.cityName}
                            </td>
                            <td onClick={e => props.onDeleteHandler(item.id)}>
                              {item.carsCount}
                            </td>
                            <td>
                              <div className="btn-group-margin">
                                <NavLink
                                  to={`/administration/travel/car-list/${item.id}/${item.companyId}/${limit}/${page}/`}
                                  className="btn btn-nothing hover-v"
                                >
                                  <i className="material-icons fs-20">
                                    remove_red_eye
                                </i>
                                  <span className="hover-name right-top">View</span>
                                </NavLink>
                                {/* <button type="button" className="btn  sm-padding"
                              data-toggle="modal"
                              data-target="#AddCityModal"
                            >
                              Edit
                        </button> */}
                              </div>
                            </td>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item.activeStatus}
                                  onChange={e =>
                                    props.onStatusChangeHandler(
                                      item.id,
                                      item.activeStatus
                                    )
                                  }
                                />
                                <span className="slider round" />
                              </label>
                            </td>
                          </tr>
                        )
                      })}
                </tbody>
              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <div className="dropdown">
                        <Select
                          placeholder={props.limit}
                          keyName={"items"}
                          options={props.pageItemsList}
                          controlFunc={e => props.handleItemChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  {props.totalCount && (
                    <Pagination
                      activePage={props.selectedPage}
                      itemsCountPerPage={props.limit}
                      totalItemsCount={props.totalCount}
                      pageRangeDisplayed={3}
                      onChange={props.pageClickHandler}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="AddCityModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddCityLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <h5 className="modal-title float-left" id="AddCityLabel">
                  Add City
                </h5>
                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div className="modal-middle pm-30">
                <div className="form-group custom">
                  <Input
                    label="City Name"
                    type="text"
                    name="searchCity"
                    value={selectedCity.name}
                    controlFunc={e => handleChangeCity(e)}
                  />
                  {validator.message(
                    "City Name",
                    selectedCity.name,
                    "required"
                  )}
                </div>
                {isSearchCityListOpen && (
                  cityList.length ?
                    (<ul className="scroll-ul">
                      {cityList.map(city => {
                        return (
                          <li key={city.id} onClick={e => selectChangeCity(city)} >
                            {city.city}
                          </li>
                        )
                      })}
                    </ul>) :
                    (<div className="mb-4">

                    </div>))
                }
                <div className="modal-last btn-group-margin">
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-border-success lg-padding"
                    onClick={
                      (e) => saveTheSelectedCity(e)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-border-basic lg-padding"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CarContractPage;
