import React from 'react';
import Pagination from "react-js-pagination";
import { NavLink } from 'react-router-dom';
const GroupConfigPage = (props) => {
    let data = props;
    return (
        <div>
            <div className="admin-tabs">
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">R</div>
                        <div className="right">
                            <h6 className="heading">Group Configuration</h6>
                            <p className="text">Lorem Ipsum is simply dummy text.</p>
                        </div>
                    </div>
                </div>
                <div className="py-padding">
                    <div className="table-container">
                        <form>
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="btn-group-margin">
                                        <button type="button" data-toggle="modal" data-target="#AddTemplateModal" className="btn  ">New</button>
                                        <button type="button" className="btn   disabled">Modify</button>
                                        <button type="button" className="btn   disabled">Remove</button>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="search-custom">
                                        <input type="text" name="" className="form-control" placeholder="Search" />
                                        <span><i className="material-icons">search</i></span>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Group</th>
                                        <th>Path</th>
                                        <th>Policies</th>
                                        <th>Payment Types</th>
                                        <th>Attendee Types</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Group1</td>
                                        <td>Global</td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Group2</td>
                                        <td>Global</td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Group3</td>
                                        <td>Global</td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Group4</td>
                                        <td>Global</td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Group5</td>
                                        <td>Global</td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Group6</td>
                                        <td>Global</td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                        <td>
                                            <a href="#AddTemplateModal2" data-toggle="modal" className="text-primary">Modify</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-footer">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="page-count">
                                        <div className="text">Items</div>
                                        <div className="count">
                                            <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                                    10
                                            </button>
                                                <div className="dropdown-menu">
                                                    <button type="button" className="dropdown-item">10</button>
                                                    <button type="button" className="dropdown-item">20</button>
                                                    <button type="button" className="dropdown-item">30</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <Pagination
                                        activePage={data.active}
                                        itemsCountPerPage={10}
                                        totalItemsCount={10}
                                        pageRangeDisplayed={3}
                                        onChange={data.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="AddTemplateModal2"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="AddTemplateModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md"
                        role="document" >
                        <div className="modal-content">
                            <div className="modal-header pl-3">
                                <h5 className="modal-title" id="AddTemplateModalLabel">
                                    Group name - POLICY</h5>
                                <button
                                    type="button"
                                    className="close d-none"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Default</th>
                                                <th>Policy Name</th>
                                                <th>Is Active</th>
                                                <th>Is Inheritable</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label className="radio-container">
                                                        <input type="radio" name="radio" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>Policy 1</td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" checked />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label className="radio-container">
                                                        <input type="radio" name="radio" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>Policy 2</td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" checked />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label className="radio-container">
                                                        <input type="radio" name="radio" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>Policy 3</td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" checked />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label className="radio-container">
                                                        <input type="radio" name="radio" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>Policy 4</td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" checked />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label className="radio-container">
                                                        <input type="radio" name="radio" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>Policy 5</td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" checked />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label className="radio-container">
                                                        <input type="radio" name="radio" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>Policy 6</td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" checked />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label className="radio-container">
                                                        <input type="radio" name="radio" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>Policy 7</td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" checked />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label className="radio-container">
                                                        <input type="radio" name="radio" />
                                                        <span className="checkmark" />
                                                    </label>
                                                </td>
                                                <td>Policy 8</td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className="switch">
                                                        <input type="checkbox" checked />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button" className="btn btn-border-success lg-padding" > Save</button>
                                <button type="button" className="btn btn-border-basic lg-padding" data-dismiss="modal"> Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="AddTemplateModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="AddTemplateModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md"
                        role="document" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="AddTemplateModalLabel">
                                    Select Group</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="file-tree">
                                    <ul>
                                        <li>
                                            <button className="btn btn-nothing collapsed" data-toggle="collapse" data-target="#demo">
                                                <img src="/assets/images/folder.svg" alt="" />
                                                <span className="text">Global-1</span>
                                            </button>
                                            <ul id="demo" className="collapse">
                                                <li>
                                                    <button className="btn btn-nothing collapsed" data-toggle="collapse" data-target="#demo1">
                                                        <img src="/assets/images/subfolder.svg" alt="" />
                                                        <span className="text">Default</span>
                                                    </button>
                                                    <ul id="demo1" className="collapse">
                                                        <li>
                                                            <button className="btn btn-nothing collapsed" data-toggle="collapse" data-target="#demo2">
                                                                <img src="/assets/images/subfolder.svg" alt="" />
                                                                <span className="text">Default</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="btn btn-nothing collapsed" data-toggle="collapse" data-target="#demo3">
                                                                <img src="/assets/images/subfolder.svg" alt="" />
                                                                <span className="text">Default</span>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <button className="btn btn-nothing collapsed" data-toggle="collapse" data-target="#demo4">
                                                        <img src="/assets/images/subfolder.svg" alt="" />
                                                        <span className="text">Default</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <button className="btn btn-nothing collapsed" data-toggle="collapse" data-target="#demo5">
                                                <img src="/assets/images/subfolder.svg" alt="" />
                                                <span className="text">Default</span>
                                            </button>
                                            <ul id="demo5" className="collapse">
                                                <li>
                                                    <button className="btn btn-nothing collapsed" data-toggle="collapse" data-target="#demo6">
                                                        <img src="/assets/images/subfolder.svg" alt="" />
                                                        <span className="text">Default</span>
                                                    </button>
                                                    <ul id="demo6" className="collapse">
                                                        <li>
                                                            <button className="btn btn-nothing collapsed" data-toggle="collapse" data-target="#demo7">
                                                                <img src="/assets/images/subfolder.svg" alt="" />
                                                                <span className="text">Default</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="btn btn-nothing collapsed" data-toggle="collapse" data-target="#demo8">
                                                                <img src="/assets/images/subfolder.svg" alt="" />
                                                                <span className="text">Default</span>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <button className="btn btn-nothing collapsed" data-toggle="collapse" data-target="#demo9">
                                                        <img src="/assets/images/subfolder.svg" alt="" />
                                                        <span className="text">Default</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button" className="btn btn-border-success lg-padding" > Save</button>
                                <button type="button" className="btn btn-border-basic lg-padding" data-dismiss="modal"> Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default GroupConfigPage;