import React, { Component } from "react";
import UserManagementPage from "./user-management-html";
import { companyService } from "../../../../../_services";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../_constants/alert.constants";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";

class UserManagement extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.validator = new SimpleReactValidator();
    this.state = {
      searchInput: "",
      loading: true,
      groupName: "",
      groupId: "",
      allGroups: [],
      companyId: localStorage["440a28"],
      formHeaderId: "",
      formId: "",
      serviceModuleId: "",
      userList: [],
      page: 1,
      limit: 10,
      search: "",
      count: 0,
      selectedUserId: "",
      selectedEmail: ""
    };
  }
  componentDidMount() {
    this.getFormByCompany();
    this.getGroups();
  }
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
  getGroups = async () => {
    let allGroups = await companyService.getGroups(this.state.companyId);
    if (allGroups !== null && allGroups !== undefined) {
      this.setState({
        allGroups: allGroups.result
      });
    }
  };
  getFormByCompany = async () => {
    let formDetails = await companyService.getFormDetails(this.state.companyId);
    if (formDetails != null && formDetails !== "") {
      this.setState(
        {
          formHeaderId: formDetails.result[0]["formHeaderId"],
          formId: formDetails.result[0]["id"],
          serviceModuleId: formDetails.result[0]["serviceModuleId"]
        },
        () => {
          var req = {
            companyId: localStorage["440a28"],
            formId: this.state.formId,
            page: this.activePage,
            sort: "asc",
            limit: this.itemsPerPage
          };
          this.getListUser();
        }
      );
    }
  };
  getListUser = async () => {
    const { search, limit, page, companyId } = this.state;
    this.setState({ loading: true });
    let req = {
      search: search,
      limit: limit,
      page: page,
      companyId: companyId,
      sort: "asc",
      isMin: true
    };
    let resp = await companyService.getListUser(req);
    if (resp) {
      this.setState({
        count: resp.count,
        userList: resp.result.userData
      });
    } else {
      this.setState({
        count: 0,
        userList: []
      });
    }
    this.setState({ loading: false });
  };
  handleStatusChange = async data => {
    let newStatus = !data.activeStatus;
    let payload = {
      id: data.id,
      activeStatus: newStatus
    };
    let resp = await companyService.activeInactiveUserManage(data.id, payload);
    if (resp) {
      toast.success(alertConstants.UPDATED_SUCCESSFULLY);
      this.getListUser();
    }
  };
  sortBy = e => {
    let arrayCopy = this.companyList;
    arrayCopy.sort(this.compareBy(e));
    this.companyList = arrayCopy;
    this.forceUpdate();
  };
  column = "";
  isDesc = "";
  compareBy(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    return function(a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      } else if (a[property] > b[property]) {
        return 1 * direction;
      } else {
        return 0;
      }
    };
  }
  handleItemChange = limit => {
    this.setState(
      {
        page: 1,
        count: 0,
        limit: limit["items"]
      },
      () => {
        this.getListUser();
      }
    );
  };
  handlePageChange = pageNumber => {
    this.setState(
      {
        page: pageNumber
      },
      () => {
        this.getListUser();
      }
    );
  };
  addGroupHandler = seclectedGroup => {
    this.setState({
      groupName: seclectedGroup.itemName,
      groupId: seclectedGroup.id
    });
  };
  groupSubmitHandler = async e => {
    e.preventDefault();
    let payload = {
      groupId: this.state.groupId,
      companyId: this.state.companyId,
      isFormGroup: true,
      formHeaderId: this.state.formHeaderId,
      serviceModuleId: this.state.serviceModuleId
    };
    if (this.validator.allValid()) {
      $(".close").click();
      localStorage.setItem("groupId", payload.groupId);
      localStorage.setItem("groupName", this.state.groupName);
      localStorage.setItem("isFormGroup", payload.isFormGroup);
      localStorage.setItem("formHeaderId", payload.formHeaderId);
      localStorage.setItem("serviceModuleId", payload.serviceModuleId);
      let res = await companyService.getFormData(payload);
      res.count
        ? this.props.history.push("/administration/company/management/add-user")
        : toast.warn(`Forms with ${this.state.groupName} not found`);
    } else {
      this.validator.showMessages();
      this.setState({ ...this.state });
    }
  };
  searchHandler = async e => {
    this.setState(
      {
        search: e.target.value,
        page: 1,
        limit: 10
      },
      () => {
        this.getListUser();
      }
    );
  };
  handleChangePasswordClick = userData => {
    this.setState({
      selectedUserId: userData.id,
      selectedEmail: userData.email
    });
  };
  handleChangePassword = async value => {
    let payload = {
      ...value,
      userId: this.state.selectedUserId
    };
    let response = await companyService.passwordReset(payload);
    if (response) {
      $(".close").click();
      toast.success(response.message);
      this.setState({ selectedUserId: "", selectedEmail: "" });
    }
  };
  render() {
    return (
      <UserManagementPage
        state={this.state}
        handleStatusChange={this.handleStatusChange}
        sortBy={this.sortBy}
        isDesc={this.isDesc}
        column={this.column}
        handleItemChange={this.handleItemChange}
        pageItemsList={this.pageItemsList}
        handlePageChange={this.handlePageChange}
        searchHandler={this.searchHandler}
        validator={this.validator}
        addGroupHandler={this.addGroupHandler}
        groupSubmitHandler={this.groupSubmitHandler}
        handleChangePassword={this.handleChangePassword}
        handleChangePasswordClick={this.handleChangePasswordClick}
      />
    );
  }
}
export default UserManagement;
