import React from "react";
import Menu from "../menu";
const HourlyAllowancePage = props => {
  return (
    <div>
      <div className="admin-tabs">
        <Menu />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">H</div>
            <div className="right">
              <h6 className="heading">Hourly Allowance</h6>
              <p className="text">A Hourly Allowance to create configuration mapped to employees.</p>
            </div>
          </div>
        </div>
        <div className="p-padding">
          <div className="form-holder">
            <div className="form-group mb-1">
              <label className="switch">
                <input type="checkbox" checked={props.status} onChange={props.onStatusChangeHandler} />
                <span className="slider round"></span>
              </label>
              <span>Enable Hourly Allowance Configuration</span>
            </div>
            <div className="table-container min-height">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="btn-group-margin">
                    {props.editAllowanceConfig.length>0?
                      <button
                        type="button"
                        className="btn   sm-padding"
                        onClick={props.onEditAddClickHandler}
                      >
                        Add
                    </button>
                    :  <button
                    type="button"
                    className="btn   sm-padding"
                    onClick={props.onAddClickHandler}
                  >
                    Add
                </button>}
                {props.editAllowanceConfig.length>0?
                      <button
                        onClick={props.onRemoveHandler}
                        type="button"
                        disabled={!props.selectedRow.length>0}
                        className="btn   sm-padding"
                      >
                        Remove
                    </button>
                    :
                    <button
                    onClick={props.onEditRemoveHandler}
                    type="button"
                    disabled={!props.selectedRow.length>0}
                    className="btn   sm-padding"
                  >
                    Remove
                </button>
                }
                    <button
                        type="button"
                        className="btn   sm-padding"
                        onClick={props.onSubmitHandler}
                      >
                        {props.editAllowanceConfig.length>0?'Update':'Submit'}
                    </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>From</th>
                      <th>To</th>
                      <th>Rate %</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {props.arrayCount.map((index)=>
                    <tr key={index} 
                     >
                      <td>
                      <label className="checkbox-container m-0 d-inline-block v-align-top">
                        <input type="checkbox"
                        onChange={(e)=>props.onSelectHandler(index)}
                         checked={props.selectedRow.includes(index)} />
                         <span className="checkmark"></span>
                         </label>
                      </td>
                      <td>
                        <div className="form-group custom mb-0">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn dropdown-toggle"
                              data-toggle="dropdown"
                            >
                             {props['from'+index]?props['from'+index]:'0'}
                            </button>
                            <div className="dropdown-menu">
                            {props['fromdropdown'+index]&&props['fromdropdown'+index].map((list)=>{
                              return(
                                <button
                                key={list}
                                type="button"
                                className="dropdown-item"
                                onClick={(e)=>props.onDropdownSelectHandler('from'+index,list,index)}
                              >{list}
                              </button>
                              )
                            })
                            }
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="form-group custom mb-0">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn dropdown-toggle"
                              data-toggle="dropdown"
                            >
                             {props['to'+index]?props['to'+index]:'0'}
                            </button>
                            <div className="dropdown-menu">
                            {props['todropdown'+index]&&props['todropdown'+index].map((list)=>{
                              return(
                                <button
                                key={list}
                                type="button"
                                onClick={(e)=>props.onDropdownToSelectHandler('to'+index,list,index)}
                                className="dropdown-item"
                              >{list}
                              </button>
                              )
                            })
                            }
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="form-group custom mb-0 w-25 percen">
                          <input className="form-control"
                          onChange={(e)=>props.onRateChangeHandler('rate'+index,e)}
                           value= {props['rate'+index]?props['rate'+index]:''} placeholder="&nbsp;" type="text" />
                          <span className="abs-p">%</span>
                                <small style={{ color: "red" }}>
                          {props.validator.message(
                            "rate",
                            props['rate'+index],
                            "required"
                          )}
                        </small>
                        </div>
                      </td>
                     
                    </tr>
                  )}   
                  </tbody>
                </table>
              </div>
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    {/* <Pagination
                        activePage={selectedPage}
                        itemsCountPerPage={dataPerPage}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={3}
                        onChange={pageClickHandler}
                      /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HourlyAllowancePage;
