import React from 'react';

const expenseSubmitConfirmModal =(props)=>{
    return(
      <div
      className="modal fade"
      id="submitExpenseEntry"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="submitExpenseEntry"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <button className="btn d-none close" data-dismiss="modal" aria-label="Close" ></button>
            <div className="text-center pt-5">
              <img src="/assets/images/confirmation_icon.svg" alt="" />
              <h3 className=" mt-4 text-dark">Are You Sure?</h3>
              <p>By clicking on submit, you agree to the <br />expense policy and restrictions.</p>
            </div>
          </div>
          <div className="btn-group-margin text-center pb-5">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
            >
              No
            </button>
            {
              !props.isSubmitClick ?
                <button
                 onClick={props.onExpenseReportSubmitHandler}
                 className="btn"
                 data-dismiss="modal"
                  >Submit</button>
                : <button className="btn" >Please Wait...</button>
            }
          </div>
        </div>
      </div>
    </div>
    )
}

export default expenseSubmitConfirmModal;
