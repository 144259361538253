import React from 'react';
import Pagination from "react-js-pagination";
import { NavLink } from 'react-router-dom';
import { cashadvanceService } from 'src/_services/cashadvance.service';
import _ from 'lodash';
import ExpenseApprovalList from './cashAdvanceComponent/expenseApprovalList';
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';
import AsyncSelect from "react-select/lib/Async";
import $ from 'jquery';
import { expenseReport } from 'src/_services/expenseReport';
import moment from 'moment';
import Loader from 'src/_components/common/Loader';
import TRIPMENU from './TRIPMENU';
import { getWorkflowListBasedOnType } from 'src/_services/v2Service';
import { getValue } from 'src/_components/common/lodsh';


class ExpenseReportsV2Page extends React.PureComponent {
  constructor(props) {
    super(props)
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.validator = new SimpleReactValidator();
    this.state = {
      selectedCashAdvanceId: '',
      rejectionReason: '',
      search: '',
      selectedAction: '',
      actionSelectedName: '',
      actionFrom: '',
      userPermission: 'employee-role',
      pageNumber: 1,
      limit: 10,
      totalCount: 0,
      isSubmit: false,
      formValidaionStatus: true,
      isLoading: false,
      expenseApproval: [],
      cashAdvanceEditFormData: [],
      selectedApproval: [],
      companyId: localStorage["440a28"],
      pageLimits: [10, 20, 30],
      pageName: 'expenseReport',
      pageType: 'expense'
    }
  }
  componentDidMount() {
    this.getexpenseApprovalFlow();
  }
  getexpenseApprovalFlow = async () => {
    // this.setState({ isLoading: true });
    let response = await getWorkflowListBasedOnType(this.state.search, this.state.pageNumber, this.state.limit, this.state.pageType, sessionStorage.getItem("isCheckedExpense"));
    this.setState({
      totalCount: getValue(response, "count", 0),
      // isLoading: false,
      expenseApproval: getValue(response, "result", [])
      
    })
  }
  onCashAdvanceApprovalEditClickHandler = async (cashAdvanceId) => {
    this.setState({ selectedCashAdvanceId: cashAdvanceId });
    let response = await cashadvanceService.editCashAdvanceApproval(cashAdvanceId);
    if (response) {
      response.result.CASH_DATA = response.result.CASH_DATA.data;
      this.setState({ cashAdvanceEditFormData: response.result });
      if (response && response.result && response.result.CASH_DATA?.data) {
        let dynamicFeilds = [];
        dynamicFeilds = _.filter(response.result.CASH_DATA.data, function (result) {
          return result.fieldLevel !== undefined && result.fieldLevel !== '';
        });
        let newConnectedListDate = dynamicFeilds.map((fields) =>
          ({
            columnName: fields.columnName,
            id: fields.id,
            multiSelectParentField: fields.multiSelectParentField ? fields.multiSelectParentField : '',
            fieldName: fields.fieldName,
            fieldLevel: fields.fieldLevel,
            fieldValue: fields.fieldValue
          })
        )
        this.setState({
          connectedListData: newConnectedListDate,
          editCashAdvance: response.result.CASH_DATA.data
        });
        this.createDropdownForMultiLblConnectedList(newConnectedListDate);
        this.setTheStateOfFormsAndFields(response.result.CASH_DATA.data);
      }
    }
  }
  setTheStateOfFormsAndFields = (formsAndFields) => {
    let formFields = [], fieldsObjects = [];
    formsAndFields.map(formField => {
      let fieldName = formField.fieldName;
      if (formField.dataType === "date") {
        let todayDate = formField.fieldValue;
        this.setState({ [fieldName]: todayDate });
      } else if (formField.dataType === "checkbox") {
        this.setState({ [fieldName]: formField.fieldValue });
      } else {
        this.setState({ [fieldName]: formField.fieldValue });
      }
      formFields.push(formField.fieldName);
      fieldsObjects.push({ formField });
    });
    this.setState({ formFields: formFields, fieldsObject: fieldsObjects, isEditLoading: false });
  }
  createDropdownForMultiLblConnectedList = (newConnectedListDate) => {
    newConnectedListDate.forEach(async (fields) => {
      if (fields.fieldLevel === 1) {
        let newFieldLevel = fields.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.id && listData.fieldLevel === newFieldLevel);
        if (existConnected.length > 0 && fields.fieldValue !== undefined && fields.fieldValue !== '') {
          let companyId = this.state.companyId;
          let response = await expenseReport.getConnectedListData(companyId, fields.fieldValue);
          let childName = existConnected[0].columnName;
          if (response) {
            this.setState({ [childName]: response });
          }
        }
      } else if (fields.fieldLevel >= 1) {
        let newFieldLevel = fields.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.multiSelectParentField && listData.fieldLevel === newFieldLevel);
        if (existConnected.length > 0 && fields.fieldValue !== undefined && fields.fieldValue !== '') {
          let companyId = this.state.companyId;
          let response = await expenseReport.getConnectedListData(companyId, fields.fieldValue);
          let childName = existConnected[0].columnName;
          if (response) {
            this.setState({ [childName]: response });
          }
        }
      }
    });
  }
  onClickCancelHandler = () => {
    $('.close').click();
  }

  onClickHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter((listData) => listData.multiSelectParentField === conId && listData.fieldLevel === newFieldLevel);
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(companyId, fieldId);
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter((listData) => listData.multiSelectParentField === conId && listData.fieldLevel === newFieldLevel);
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(companyId, fieldId);
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };

  onChangeHandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };

  onDatePickerHandler = (value, fieldName) => {
    this.setState({ [fieldName]: moment(new Date(value)).format("DD MMM YYYY") });
  };

  onCheckboxHandler = fieldName => {
    this.setState({ [fieldName]: !this.state[fieldName] });
  };

  onIntegerHandler = (event, fieldName) => {
    this.setState({ [fieldName]: parseInt(event.target.value) });
  };

  onLocationChangekHandler = (event, fieldName) => {
    this.setState({ selectedApproval: event });
  }

  loadOptions = async (newValue) => {
    return expenseReport.getAllLocationsName(newValue).then((data) => {
      return data.result.map(list => {
        return {
          label: list.name + ',' + list.countryId.name,
          value: list.id
        };
      });
    })
  }

  onApprovalClickHandler = async () => {
    this.setState({ isSubmit: true });
    if (this.validator.allValid()) {
      let fieldsObjects = this.state.fieldsObject;
      let index = 0;
      let cashAdvanceSubmitArray = [];
      for (const key of this.state.formFields) {
        if (fieldsObjects.columnName === "LOCATION_NAME") {
          let selectedLocation = [];
          selectedLocation = this.state['search' + key] && this.state['search' + key].find((list) => this.state[key] === list.value);
          if (selectedLocation && selectedLocation.value) {
            fieldsObjects[index].formField.fieldValue = selectedLocation.value;
          } else {
            fieldsObjects[index].formField.fieldValue = "";
          }

        } else {
          fieldsObjects[index].formField.fieldValue = this.state[key];
        }
        cashAdvanceSubmitArray[index] = fieldsObjects[index].formField;
        index++;
      }
      let updateEntryForm = {
        companyId: this.state.companyId,
        data: cashAdvanceSubmitArray
      };
      let selectedCashAdvanceId = this.state.selectedCashAdvanceId;
      let response;
      let cashAdvanceApprovalData = {
        data: {
          APPROVE: {
            comment: ""
          }
        }
      }
      try {
        response = await Promise.all([cashadvanceService.updateCashAdvanceForm(updateEntryForm, selectedCashAdvanceId), cashadvanceService.approveCashAdvanceApproval(cashAdvanceApprovalData, selectedCashAdvanceId)]);
      } catch (e) {
        toast.error('Approval Failed Please Try Again')
      }
      if (response) {
        $(".close").click();
        this.setState({ cashAdvanceEditFormData: [], isSubmit: false });
        this.getCashAdvanceApprovalFlow();
        toast.success(response[1].message);
      }
    } else {
      this.validator.showMessages();
      this.setState({ updateValidationStatus: true, isSubmit: false });
      this.forceUpdate();
      toast.error('Please Validate all the mandatory fields');
    }
  }

  onRejectionChangeHandler = (event) => {
    this.setState({ rejectionReason: event.target.value })
  }

  onCashAdvanceSelectActionHandler = async (action, cashAdvanceId, actionSelectedName, actionFrom) => {
    $(".close").click();
    $(".open").click();
    await this.setState({ rejectionReason: '', selectedCashAdvanceId: '', selectedApproval: '' })
    this.setState({
      selectedAction: action,
      selectedCashAdvanceId: cashAdvanceId,
      actionSelectedName: actionSelectedName,
      actionFrom: actionFrom
    });
  }

  //get cash advanceData on basis of action
  getCashAdvanceActionData = () => {
    let action = this.state.selectedAction;
    let cashAdvanceApprovalData;
    let rejectionReason = this.state.rejectionReason;
    if (action === 'APPROVE') {
      cashAdvanceApprovalData = {
        data: {
          APPROVE: {
            comment: rejectionReason
          }
        }
      }
    } else if (action === 'SEND_BACK_EMPLOYEE') {
      cashAdvanceApprovalData = {
        data: {
          SEND_BACK_EMPLOYEE: {
            comment: rejectionReason
          }
        }
      }
    } else if (action === 'REJECT') {
      cashAdvanceApprovalData = {
        data: {
          REJECT: {
            comment: rejectionReason
          }
        }
      }
    } else {
      cashAdvanceApprovalData = {
        data: {
          ADD_NEW_STEP: {
            userId: this.state.selectedApproval.values
          }
        }
      }
    }
    return cashAdvanceApprovalData;
  }
  //cash Advance dynamic button click handlig ACTION handler
  onCashAdvanceSubmitActionHandler = async () => {
    this.setState({ isSubmit: true });
    let action = this.state.selectedAction;
    let selectedCashAdvanceId = this.state.selectedCashAdvanceId
    let cashAdvanceApprovalData = this.getCashAdvanceActionData();
    let response;
    if (action !== 'APPROVE') {
      if (this.state.rejectionReason.length > 5) {
        response = await cashadvanceService.approveCashAdvanceApproval(cashAdvanceApprovalData, selectedCashAdvanceId);
        if (response) {
          $('.close').click();
          this.setState({ rejectionReason: '', selectedCashAdvanceId: '', selectedApproval: '' })
          toast.success(response.message);
          this.getCashAdvanceApprovalFlow();
        }
      } else {
        this.setState({ formValidaionStatus: false })
      }
    } else {
      response = await cashadvanceService.approveCashAdvanceApproval(cashAdvanceApprovalData, selectedCashAdvanceId);
      if (response) {
        $('.close').click();
        this.setState({ rejectionReason: '', selectedCashAdvanceId: '', selectedApproval: '', isSubmit: false });
        toast.success(response.message);
        this.getCashAdvanceApprovalFlow();
      } else {
        this.setState({ rejectionReason: '', selectedCashAdvanceId: '', selectedApproval: '', isSubmit: false });
      }
    }
  }
  onApprovalSelectHandler = (event) => {
    this.setState({ selectedApproval: event });
  }

  onApprovalSearchHandler = async (newValue) => {
    return cashadvanceService.getApprovalUserList(this.state.companyId, newValue).then((data) => {
      return data.result.map(list => {
        return {
          label: list.name + ',' + list.countryId.name,
          value: list.id
        };
      });
    })
  }
  onReasonChangeHandler = (event) => {
    let message = event.target.value;
    this.setState({ rejectionReason: event.target.value, formValidaionStatus: message.length > 1 });
  }

  onPageChangeHandler = async (value) => {
    await this.setState({ pageNumber: value });
    this.getexpenseApprovalFlow();
  }

  onLimitChangeHandler = async (limit) => {
    await this.setState({ limit: limit, pageNumber: 1, search: '' });
    this.getexpenseApprovalFlow();
  }

  onSearchTextChangeHandler = async (event) => {
    await this.setState({ search: event.target.value });
    this.getexpenseApprovalFlow();
  }
  onExpenseClickHandler = async (data) => {
    // this.props.history.push(`../expense/details/approval/${data.id}`);
  }
  checkBox = async () => {
    this.setState({page:1})
    if (sessionStorage.getItem("isCheckedExpense") === "true") {
      sessionStorage.setItem("isCheckedExpense", "false")
    } else if (sessionStorage.getItem("isCheckedExpense") === "false") {
      sessionStorage.setItem("isCheckedExpense", "true")
    }
    let pageNumber;
    if(this.state.pageNumber >= 1){
      pageNumber = 1
    }
    const { search, limit,pageType } = this.state;
    this.setState({ isLoading: true, count: 0, expenseApproval: [] });
    var resp = await getWorkflowListBasedOnType(search, pageNumber, limit, pageType, sessionStorage.getItem("isCheckedExpense"));
    this.setState({
      totalCount: getValue(resp, "count", 0),
      isLoading: false,
      expenseApproval: getValue(resp, "result", [])
    });
  }
  render() {
    let data = this.props;
    return (
      <div>
        <div className="p-padding">
          <TRIPMENU />
          <div className="white-card">
            <div className="form-holder">
              {!this.state.isLoading ?
                <div className="table-container">
                  <form className="py-0 px-4">
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <div className="dropdown">
                            <div className="dropdown-menu">
                              <button className="dropdown-item">Expense Entry Form</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 offset-5">
                        <div className="form-group">
                          <div className="switch-right">
                            <div className="right">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="form-control"
                                  checked={(sessionStorage.getItem("isCheckedExpense") === "true") ? true : false}
                                  value={sessionStorage.getItem("isCheckedExpense")}
                                  onClick={this.checkBox}
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                            <p className="mr-5">Pending for my approval : </p>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-3">
                        <div className="search-custom">
                          <input onChange={this.onSearchTextChangeHandler} type="text" name="" className="form-control" placeholder="Search" />
                          <span><i className="material-icons">search</i></span>
                        </div>
                      </div>
                    </div>
                  </form>

                  <ExpenseApprovalList
                    pageName={this.state.pageName}
                    expenseApproval={this.state.expenseApproval}
                    onExpenseClickHandler={this.onExpenseClickHandler}
                  />
                  <div className="table-footer">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="page-count">
                          <div className="text">Items</div>
                          <div className="count">
                            <div className="dropdown">
                              <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                                {this.state.limit}
                              </button>
                              <div className="dropdown-menu">
                                {this.state.pageLimits.map((list, index) =>
                                  <button key={index} onClick={() => this.onLimitChangeHandler(list)} type="button" className="dropdown-item">{list}</button>
                                )
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <Pagination
                          activePage={this.state.pageNumber}
                          itemsCountPerPage={this.state.limit}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={5}
                          onChange={this.onPageChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                : <Loader />
              }
            </div>
          </div>
        </div>


        <div
          className="modal fade"
          id="cancelcashAdvanceModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="cancelcashAdvancLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">New</h6>
                <h5 className="modal-title" id="cancelcashAdvancLabel">
                  Reason for Rejection
                        </h5>
              </div>
              <div className="modal-middle pm-30 pb-0">
                <div className="form-group mb-0 custom labelup textarea">
                  <textarea rows="2" className="form-control "
                    data-gramm_editor="false"
                    spellCheck="false"
                    onChange={this.onRejectionChangeHandler}
                    placeholder="Reason for Rejection" />
                  <label>Reason</label>
                </div>

              </div>
              <div className="modal-last btn-group-margin pm-30">
                <button
                  type="button"
                  className="btn btn-badge"
                  data-dismiss="modal"
                >
                  Cancel
                        </button>
                <button onClick={this.onCashAdvanceSubmitActionHandler} className="btn  ">
                  Submit
                                    <i className="material-icons">arrow_drop_down</i>
                </button>
              </div>
            </div>
          </div>
        </div>


        <div
          className="modal fade"
          id="submitModalRequest"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="submitModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <span className="close" data-dismiss="modal" aria-label="Close"></span>
                <h5 className="modal-title">{this.state.actionSelectedName}</h5>
              </div>
              <div className="modal-middle pm-30">
                <div className="row">
                  <div className="col-md-12">
                    {(this.state.selectedAction === "ADD_NEW_STEP") &&
                      <div className="">
                        <div className="form-group custom">
                          <AsyncSelect
                            noOptionsMessage={({ inputValue }) => inputValue ? 'No results.' : "Start typing to search..."}
                            placeholder="Search user"
                            loadOptions={this.onApprovalSearchHandler}
                            value={this.state.selectedApproval && this.state.selectedApproval}
                            onChange={(event) => this.onApprovalSelectHandler(event)}
                          />
                          <span className="searc-icon-abs"><i className="material-icons">search</i></span>
                        </div>
                        <div>

                        </div>
                      </div>
                    }
                    <div>
                      {((this.state.selectedAction === "SEND_BACK_EMPLOYEE") ||
                        (this.state.selectedAction === "REJECT")) &&
                        <div className="form-group custom textarea">
                          <textarea
                            className="form-control"
                            name="rejectReason"
                            value={this.state.rejectionReason}
                            placeholder="Message"
                            onChange={this.onReasonChangeHandler}
                          />
                          <label>Message</label>
                          <div className="help-block">
                            {!this.state.formValidaionStatus ? 'Please enter valid comments' : ''}
                          </div>
                        </div>
                      }
                      {(this.state.selectedAction === "APPROVE") &&
                        <div className="btn-group-margin text-center">
                          <h3>Are you Sure ?</h3>
                          <p> This action cannot be undone</p>
                          <div className="clear-fix"></div>
                          {
                            !this.state.isSubmit ? this.state.actionFrom === 'modal' ?
                              <button onClick={this.onApprovalClickHandler} className="btn" >Yes</button>
                              : <button onClick={this.onCashAdvanceSubmitActionHandler} className="btn" >Yes</button>
                              :
                              <button className="btn" >Please Wait...</button>
                          }
                          <button className="btn" data-dismiss="modal" aria-label="Close" >No</button>
                        </div>
                      }
                      {(this.state.selectedAction !== "APPROVE") &&
                        <div className="modal-last btn-group-margin pm-30">
                          <button
                            type="button"
                            className="btn btn-badge"
                            data-dismiss="modal"
                          >
                            Cancel
                                                    </button>
                          <button onClick={this.onCashAdvanceSubmitActionHandler} className="btn  ">
                            Submit
                                                        <i className="material-icons">arrow_right</i>
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <button className="btn" data-toggle="modal" data-target="#cashAdvanceStepsModal">Click</button> */}

        <div className="modal fade" id="cashAdvanceStepsModal" tabIndex="-1"
          role="dialog" aria-labelledby="cashAdvancStepsLabel" aria-hidden="true" >
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-head">
                <h6 className="modal-subtitle">New</h6>
                <h5 className="modal-title" id="cashAdvancStepsLabel">
                  Cash Advance
                                </h5>
              </div>
              <div className="modal-middle pt-3">
                <ul className="expense-tabs px-padding nav nav-tabs navtabs-custom" id="nav-tab123" role="tablist">
                  <li >
                    <a href="#" className="nav-item nav-link active"
                      id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true"
                    >Request Details</a>
                  </li>
                  <li>
                    <a href="#" className="nav-item nav-link" id="nav-request1-tab" data-toggle="tab" href="#nav-request1" role="tab" aria-controls="nav-request1" aria-selected="false">Request 1</a>
                  </li>
                  <li>
                    <a href="#" className="nav-item nav-link " id="nav-request2-tab" data-toggle="tab" href="#nav-request2" role="tab" aria-controls="nav-request2" aria-selected="false">Request 2</a>
                  </li>
                </ul>
              </div>

              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div className="px-padding pt-3">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Cash Advance ID</p>
                            <p className="font-weight-bold mb-0">CURIO9392098</p>
                          </td>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1"></p>
                            <p className="font-weight-bold mb-0"></p>
                          </td>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Request ID</p>
                            <p className="font-weight-bold mb-0">CURIO028999</p>
                          </td>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Request Date</p>
                            <p className="font-weight-bold mb-0">26/04/2019</p>
                          </td>

                        </tr>
                        <tr>
                          <td className="pl-0">
                            <p className="lighter-p mb-1 ">Employee Name</p>
                            <p className="font-weight-bold mb-0">Benjamin Brewer</p>
                          </td>
                          <td className="pl-0">
                            <p className="lighter-p mb-1">Employee Code</p>
                            <p className="font-weight-bold mb-0">CURIO293</p>
                          </td>
                          <td className="pl-0">
                            <p className="lighter-p mb-1">Designation</p>
                            <p className="font-weight-bold mb-0">CURIO293</p>
                          </td>
                          <td className="pl-0">
                            <p className="lighter-p mb-1">Grade</p>
                            <p className="font-weight-bold mb-0">Mard</p></td>
                          <td className="pl-0">
                            <p className="lighter-p mb-1">Department</p>
                            <p className="font-weight-bold mb-0">Marketing (MAR)</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-0">
                            <p className="lighter-p mb-1">Cost Center</p>
                            <p className="font-weight-bold mb-0">Marketing</p>
                          </td>
                          <td className="pl-0">
                            <p className="lighter-p mb-1">Comment</p>
                            <p className="font-weight-bold mb-0">-</p>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="py-3 border-top border-bottom bg-light modal-px-30">
                    <div className="row">
                      <div className="col-md-3 border-right">
                        <div className="float-left pt-1">
                          <div className="round__icon bg-danger text-white">
                            <span><i className="material-icons fs-20">subdirectory_arrow_left</i></span>
                          </div>
                        </div>
                        <div className="float-left ml-3">
                          <p className="mb-0">Requested Amount</p>
                          <p className="font-weight-bold mb-0">USD 1,499.00</p>
                        </div>
                      </div>
                      <div className="col-md-3 border-right">
                        <div className="float-left pt-1">
                          <div className="round__icon bg-success text-white">
                            <span><i className="material-icons fs-20">check</i></span>
                          </div>
                        </div>
                        <div className="float-left ml-3">
                          <p className="mb-0">Approved Amount</p>
                          <p className="font-weight-bold mb-0">USD 1,499.00</p>
                        </div>
                      </div>
                      <div className="col-md-3 border-right">
                        <div className="float-left pt-1">
                          <div className="round__icon bg-primary text-white">
                            <span><i className="material-icons fs-20">attach_money</i></span>
                          </div>
                        </div>
                        <div className="float-left ml-3">
                          <p className="mb-0">Issued amount</p>
                          <p className="font-weight-bold mb-0">USD 1,699.00</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="float-left pt-1">
                          <div className="round__icon bg-primary text-white">
                            <span><i className="material-icons fs-20">attach_money</i></span>
                          </div>
                        </div>
                        <div className="float-left ml-3">
                          <p className="mb-0">Remaining</p>
                          <p className="font-weight-bold mb-0">USD 0.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-last btn-group-margin pm-30">
                    <button className="btn" data-toggle="modal" >Next</button>
                    <button type="button" className="btn" data-dismiss="modal">
                      Close
                                        </button>
                  </div>
                </div>
                <div className="tab-pane fade" id="nav-request1" role="tabpanel" aria-labelledby="nav-request1-tab">
                  <div className="modal-px-30 py-3 bg-light border-bottom">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Cash Advance Name</p>
                            <p className="font-weight-bold mb-0">USD 1,499.00</p>
                          </td>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Amount Requested</p>
                            <p className="font-weight-bold mb-0">USD 1,499.00</p>
                          </td>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Amount Approved</p>
                            <p className="font-weight-bold mb-0">USD 1,499.00</p>
                          </td>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Perpuse</p>
                            <p className="font-weight-bold mb-0">Business Meeting</p>
                          </td>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Status</p>
                            <p className="font-weight-bold mb-0">Pending</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pm-30">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Amount Issued</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Exchange Rate</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Mode</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Voucher Number</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Issue Date</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Comment</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="upload-input p-0 text-center">
                          <input type="file" />
                          <div className="py-3 check-box">
                            <i className="material-icons">cloud_upload</i>
                            <div>Upload Voucher</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-last btn-group-margin pm-30 pt-0">
                    <button className="btn" data-toggle="modal" >Reject</button>
                    <button className="btn" data-toggle="modal" >Issue</button>
                    <button type="button" className="btn" data-dismiss="modal">
                      Close
                                        </button>
                  </div>
                </div>
                <div className="tab-pane fade" id="nav-request2" role="tabpanel" aria-labelledby="nav-request2-tab">
                  <div className="modal-px-30 py-3 bg-light border-bottom">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Cash Advance Name</p>
                            <p className="font-weight-bold mb-0">USD 1,499.00</p>
                          </td>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Amount Requested</p>
                            <p className="font-weight-bold mb-0">USD 1,499.00</p>
                          </td>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Amount Approved</p>
                            <p className="font-weight-bold mb-0">USD 1,499.00</p>
                          </td>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Perpuse</p>
                            <p className="font-weight-bold mb-0">Business Meeting</p>
                          </td>
                          <td className="border-0 pl-0">
                            <p className="lighter-p mb-1">Status</p>
                            <p className="font-weight-bold mb-0">Pending</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pm-30">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Amount Issued</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Exchange Rate</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Mode</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Voucher Number</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Issue Date</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group custom">
                          <input className="form-control" placeholder="&nbsp;" />
                          <label>Comment</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="upload-input p-0 text-center">
                          <input type="file" />
                          <div className="py-3 check-box">
                            <i className="material-icons">cloud_upload</i>
                            <div>Upload Voucher</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-last btn-group-margin pm-30 pt-0">
                    <button className="btn" data-toggle="modal" >Reject</button>
                    <button className="btn" data-toggle="modal" >Issue</button>
                    <button type="button" className="btn" data-dismiss="modal">
                      Close
                                        </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div >
    )
  }
}
export default ExpenseReportsV2Page;
