import React from "react";
import { getValue, findOneValue } from "src/_components/common/lodsh";
import { Select } from "src/_components/common/_forms";

const TripType = props => (
  <div>
    <div className="details to">
      <div className={(getValue(props.request,"car_ride_type") === '' && props.isSubmitted)?"details-margin not-valid":"details-margin"}>
        <div className="d-table d-table__middle new-dropdown">
          <label className="text-left m-label" htmlFor="search2">
            <p className="label">Trip type</p>
            <Select
              options={props.request.car_rental_type === "0" ? props.tripTypes : props.request.car_rental_type === "1"
              ? props.tripTypesOutStation : props.request.car_rental_type === "2" ? props.tripTypesAirport:props.tripTypes}
              placeholder={getValue(props.request, "car_ride_type") !== '' ? findOneValue(props.tripTypes, getValue(props.request, "car_ride_type"),"value","label") : "Select One"}
              controlFunc={(e) => props.handleSelectedValue(e.value, "car_ride_type")}
              keyName="label"
            />
          </label>
        </div>
      </div>
    </div>
  </div>

)
export default TripType;
