import { observable, action, runInAction, computed, reaction, toJS } from 'mobx';
import { masterService } from '../_services';
import CommonStore from './common.store';
import cryptr from '../_helpers/crypty';
import { hotelService } from '../_services/hotel.service';
import { toast } from 'react-toastify';
class TravelStore {
  @observable travelHotel;
  @observable hotelSearchResults = [];
  @observable loadingHotels = "pending";
  @observable individualHotelData = {};


  @computed get hotelsCount() {
    return this.hotelSearchResults.length;
  }
  @action storeHotel(data) {
    // hotel booking
    this.travelHotel = data;
    localStorage.setItem('hotelData', JSON.stringify(this.travelHotel));
  }

  @action getHotels() {
    this.hotelSearchResults = [];
    let data = JSON.parse(localStorage.getItem('hotelData'));
    if (data) {
      this.travelHotel = data;
    }
    let payload = {
      checkin: this.travelHotel.checkinDate,
      checkout: this.travelHotel.checkoutDate,
      no_of_rooms: this.travelHotel.selectedTotalRooms,
      search_text: this.travelHotel.selectedLocation,
      latitude: this.travelHotel.latitude.toString(),
      longitude: this.travelHotel.longitude.toString()
    }
    hotelService.getAllHotels(payload)
      .then(res => {

        // console.log(res);

        if (res.result.length > 0) {
          this.hotelSearchResults = res.result;

          this.loadingHotels = "done";
          // console.log(this.hotelSearchResults);

        } else {
          console.log("No Records Found");
        }

      }, err => {

        console.log(err);

      })
  }

  @action getIndividualHotelData(hotelId, hotelName, trip_duration) {
    hotelService.getIndividualData(hotelId, hotelName, trip_duration)
      .then(
        action('hotelDetailsFetchSuccess', hotelDetails => {
          this.individualHotelData = hotelDetails.result;
          this.hotelDetailsLoading = false;
          // console.log(toJS(this.individualHotelData));
        }),
        action('hotelDetailsFetchError', error => {
          if (error.statusCode === 422) {
            let errors = error.message
            Object.keys(errors).map(key => {
              return (
                toast.error(errors[key])
              );
            })
          } else {
            toast.error(error.message);
          }
        })
      )
  }

}

export default new TravelStore();
