import React, { Component } from "react";
import LeftSidebar from "./components/addExpenseType/leftSidebar";
import { inject, observer } from "mobx-react";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import { expenseReport } from "../../../_services/expenseReport";
import ExpenseCreateForm from "./components/addExpenseType/expenseCreateForm";
import { toast } from "react-toastify";
import _ from "lodash";
import CryptrDetail from "cryptr";
import Loader from "./../../../_components/common/Loader";
import DynamicFields from "src/_components/common/dynamicFields";
import ColumnNames from "src/_components/common/columnNames";
import { mileageService } from "../../../_services/mileage.service";
import { getValue, setValue } from "src/_components/common/lodsh";
import { getConnectedList } from "src/_services";
import $ from "jquery"

@inject("expenseStore")
@observer
class CreateItemisation extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      companyId: localStorage["440a28"],
      expenseForm: [],
      error: [],
      formFields: [],
      fieldsObject: [],
      expenseEntryList: [],
      auditRuleValidationError: [],
      totoalExpense: 0,
      cashAdvanceTotalAmount: 0,
      totalRequestAmount: 0,
      totalExpenseAmount: 0,
      validationStatus: false,
      isSubmitClick: false,
      userPermission: "employee-role",
      connectedListData: [],
      isLoading: true,
      pageName: "itemisation",
      remainigItemiseAmount: 0,
      currency: "",
      closeDropdown: false,
      taxAmount: 0,
      search : [],
      loading:[]
    };
  }

  componentWillUnmount(){
    this.state = {
     search : [],
     loading:[]
   }
 }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getAllCreatedExpenses();
    const detailCryptr = new CryptrDetail("expense@Details");
    const remainigItemiseAmount = detailCryptr.decrypt(
      localStorage.getItem("remainigItemise")
    );
    console.log(remainigItemiseAmount)
    const currency = detailCryptr.decrypt(localStorage.getItem("currency"));
    this.setState({
      remainigItemiseAmount: remainigItemiseAmount,
      currency: currency
    });
    let expenseEntryId = localStorage.getItem("expenseEntryId");
    if (
      expenseEntryId !== undefined &&
      expenseEntryId !== "" &&
      expenseEntryId !== null
    ) {
      let expenseEntryId = localStorage.getItem("expenseEntryId");
      console.log(expenseEntryId)
      if (
        expenseEntryId !== undefined &&
        expenseEntryId !== "" &&
        expenseEntryId !== null
      ) {
        this.onSelectExpenstEntryList(expenseEntryId);
      }
    }
  }

  getAllCreatedExpenses = async () => {
    let headerId = localStorage.getItem("headerId");
    if (headerId) {
      let response = await expenseReport.getAllCreatedExpenseOldAPI(
        this.state.companyId,
        headerId
      );
      if (response) {
        this.setState({
          reportHeader: response.headerObj.headerForm,
          expenseEntryList: response.expenseReportEntryObj,
          totoalExpense: response.expenseReportEntryObj.length,
          totalExpenseAmount: response.totalAmount,
          userBaseCurrency: response.baseCurrency,
          cashAdvanceTotalAmount: response.cashAdvanceTotalAmount,
          totalRequestAmount: response.requestAmount
        });
        let validationStatus = true;
        response.expenseReportEntryObj &&
          response.expenseReportEntryObj.forEach((list, index) => {
            validationStatus = !list.validation && validationStatus;
          });
        this.setState({ validationStatus: validationStatus });
      }
    }
  };

  onSelectExpenstEntryList = async expenseEntryId => {
    this.props.expenseStore.setSelectExpenstEntryList(expenseEntryId);
    let response = await expenseReport.getExpenseEntryDetailsOldAPI(expenseEntryId);
    if (response && response.entryForm) {
      let expenseFields = response.entryForm.map(fields => fields.columnName);
      let currencyIndex = expenseFields.indexOf("CURRENCY");
      let paymentIndex = expenseFields.indexOf("PAYMENT_TYPE");
      if (currencyIndex >= 0 && paymentIndex >= 0) {
        this.setState({
          itmCurrencyType: response.entryForm[currencyIndex].prevFieldId
        });
        this.setState({
          itmPaymentType: response.entryForm[paymentIndex].prevFieldId
        });
        this.getAddExpenseFormField();
      } else {
        this.getAddExpenseFormField();
      }
    }
  };
  getAddExpenseFormField = async () => {
    let itemSelectedExenseType = localStorage.getItem("itemSelectedExenseType");
    let response = await expenseReport.getAddExpenseFormField(
      this.state.companyId,
      itemSelectedExenseType
    );
    console.log(response)
    if (
      response &&
      response.itemizationFields &&
      getValue(response,"itemizationFields.result",[])
    ){
      await (getValue(response,"itemizationFields.result",[])).forEach( async( item) =>  {
        if(item.dataType === "connected_list"){
        if(item.connectedListId){
          let resp = await getConnectedList(this.state.companyId,'',item.connectedListId,1,10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp,"count",0);
          item.connectedListData.listManagementData = resp.result;
          item.search=''
        }
        this.setState({ Form: response.result})
      }
      })

    }
    if (
      response &&
      response.itemizationFields &&
      response.itemizationFields.result
    ) {
      let newConnectedListDate = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.itemizationFields.result, function(
        result
      ) {
        return result.fieldLevel !== undefined && result.fieldLevel !== "";
      });
      dynamicFeilds.forEach(fields => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField
            ? fields.multiSelectParentField
            : "",
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.defaultValue ? fields.defaultValue : ""
        });
      });
      this.setState({
        connectedListData: newConnectedListDate,
        expenseForm: response.itemizationFields.result
      });
      this.createDropdownForMultiLblConnectedList(newConnectedListDate);
      this.setTheStateOfFormsAndFields(response.itemizationFields.result);
    } else {
      toast.success("No Data Found");
      this.setState({ isLoading: false });
    }
  };

  setTheStateOfFormsAndFields = formAndFields => {
    let formFields = [];
    let fieldsObjects = [];
    formAndFields.map(async formField => {
      let fieldName = formField.fieldName;
      formFields.push(formField.fieldName);
      fieldsObjects.push(formField);
      if (
        formField.columnName === "CURRENCY" ||
        formField.columnName === "PAYMENT_TYPE"
      ) {
        formField.columnName === "PAYMENT_TYPE"
          ? this.setState({ [fieldName]: this.state.itmPaymentType })
          : this.setState({ [fieldName]: this.state.itmCurrencyType });
      } else if (formField.dataType === "date") {
        if (formField.isDefaultValue) {
          this.setState({
            [fieldName]: moment(formField.defaultValue).format("DD MMM YYYY")
          });
        } else {
          this.setState({ [fieldName]: "" });
        }
      } else if (formField.dataType === "checkbox") {
        if (formField.isDefaultValue) {
          this.setState({ [fieldName]: formField.defaultValue });
        } else {
          this.setState({ [fieldName]: false });
        }
      } else {
        if (formField.isDefaultValue) {
          this.setState({ [fieldName]: formField.defaultValue });
        } else {
          this.setState({ [fieldName]: "" });
        }
      }
    });
    this.setState({
      isLoading: false,
      formFields: formFields,
      fieldsObject: fieldsObjects
    });
  };

  createDropdownForMultiLblConnectedList = newConnectedListDate => {
    newConnectedListDate &&
      newConnectedListDate.forEach(async fields => {
        if (fields.fieldLevel === 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField === fields.id &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 2) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField ===
                fields.multiSelectParentField &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }
      });
  };
  amountGreaterThanZeroValidation = amount => {
    return amount > 0 ? true : false;
  };
  onSubmitHandler = async () => {
    if (this.validator.allValid()) {
      this.setState({ isSubmitClick: true });
      let fieldsObjects = [...this.state.fieldsObject];
      let index = 0;
      let amountValidation = true;
      for (const key of this.state.formFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          fieldsObjects[index].fieldValue = this.state[key]
            ? this.state[key].value
            : "";
          fieldsObjects[index].searchName = this.state[key]
            ? this.state[key].label
            : "";
        } else if (
          fieldsObjects[index].fieldLevel >= 2 &&
          fieldsObjects[index].dataType === "connected_list"
        ) {
          fieldsObjects[index].fieldValue = this.state[key];
          fieldsObjects[index].connectedListData = {};
          fieldsObjects[
            index
          ].connectedListData.listManagementData = this.state[
            fieldsObjects[index].columnName
          ];
        } else if (
          (fieldsObjects[index].dataType === DynamicFields.DATA_TYPE_INTEGER ||
            fieldsObjects[index].dataType === DynamicFields.DATA_TYPE_AMOUNT) &&
          ColumnNames.AMOUNT === fieldsObjects[index].columnName
        ) {
          amountValidation =
            amountValidation &&
            this.amountGreaterThanZeroValidation(this.state[key]);
          fieldsObjects[index].fieldValue = this.state[key];
        } else {
          fieldsObjects[index].fieldValue = this.state[key];
        }
        index++;
      }
      let expenseEntryId = localStorage.getItem("expenseEntryId");
      let itemSelectedExenseType = localStorage.getItem(
        "itemSelectedExenseType"
      );
      let createExpensePolicy = {
        entryId: expenseEntryId,
        policyId: localStorage.getItem("policyId"),
        expenseType: itemSelectedExenseType,
        itemiseAmount: this.state.Amount,
        itemizationForm: fieldsObjects,
        taxAmount: this.state.taxAmount
      };
      if (amountValidation) {
        let response = await expenseReport.createItemizationExpenseType(
          createExpensePolicy
        );
        if (response) {
          if (response.result !== "error") {
            if (
              response.result &&
              response.result.errorMessages &&
              response.result.errorMessages.errorMessages
            ) {
              this.setState({
                submitButtonStatus: false,
                auditRuleValidationError:
                  response.result.errorMessages.errorMessages,
                isSubmitClick: false
              });
            } else {
              this.setState({ isSubmitClick: false });
              localStorage.removeItem("itemSelectedExenseType");
              toast.success(response.message);
              this.props.history.push("./details");
            }
          } else {
            this.setState({ isSubmitClick: false });
            toast.error(response.message);
          }
        }
      } else {
        toast.error("Amount should be greater than zero");
        this.setState({ isSubmitClick: false });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  onCancelClickHandler = () => {
    this.props.history.push("./details");
  };

  onClickHandler = async (fieldName, conId, fieldId, fieldLevel,assignKey) => {

    this.state.search[assignKey] = '';
    this.setState({search:this.state.search})


    this.enableCgstTOrIgstField(fieldName, fieldId);
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.showCgstIgstExchangeAmountRate(fieldName, fieldId);
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };

  onChangeHandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };
  onDatePickerHandler = (value, fieldName) => {
    if (!isNaN(value)) {
      this.setState({
        [fieldName]: moment(new Date(value)).format("DD MMM YYYY")
      });
    }
  };
  onCheckboxHandler = fieldName => {
    this.setState({ [fieldName]: !this.state[fieldName] });
  };
  onIntegerHandler = (event, fieldName) => {
    if (!isNaN(event.target.value)) {
      this.setState({ [fieldName]: +event.target.value });
      this.showCgstIgstExchangeAmountRate(fieldName);
    }
  };

  onLocationChangekHandler = async (event, fieldName) => {
    let response = await mileageService.getGstAvailable(event.value);
    if (response) {
      this.setState({ displayTaxFields: true });
    } else {
      this.setState({
        displayCSGST: false,
        displayIGST: false,
        displayTaxFields: false
      });
    }
    this.setState({ [fieldName]: event });
  };

  loadOptions = async newValue => {
    return expenseReport.getAllLocationsName(newValue).then(data => {
      return data.result.map(list => {
        return {
          label: list.name + "," + list.countryId.name,
          value: list.id
        };
      });
    });
  };

  //tax calculation start here
  enableCgstTOrIgstField = (fieldName, fieldId, entryForm = []) => {
    let fieldsObject = [...this.state.fieldsObject];
    fieldsObject = fieldsObject.length ? fieldsObject : entryForm;
    let fields = fieldsObject.find(field => field.fieldName === fieldName);
    if (fields && fields.columnName === ColumnNames.TAX_TYPES) {
      let selectedTaxType = fields.connectedListData.listManagementData.find(
        listData => listData.id === fieldId
      );
      if (selectedTaxType && selectedTaxType.itemName === ColumnNames.IGST) {
        this.setState({ displayCSGST: false, displayIGST: true });
      } else if (
        selectedTaxType &&
        selectedTaxType.itemName === "CGST & SGST/UGST"
      ) {
        this.setState({ displayCSGST: true, displayIGST: false });
      }
    }
  };

  showCgstIgstExchangeAmountRate = _.debounce((fieldName, fieldId) => {
    let fieldsObject = [...this.state.fieldsObject];
    let fields = fieldsObject.find(
      field => field.fieldName === fieldName && this.state.displayTaxFields
    );
    if (fields && fields.columnName === ColumnNames.TAX_RATE) {
      let taxRate = this.state[ColumnNames.TAX_RATE];
      let amountFields = fieldsObject.find(
        field => field.columnName === ColumnNames.AMOUNT
      );
      const amount = this.state[amountFields.fieldName];
      let rate = taxRate.find(rate => rate.id === fieldId);
      this.setPercentageOfCgstIgst(
        rate ? rate.itemName : 0,
        amount ? amount : 0
      );
    } else if (
      fields &&
      fields.columnName === ColumnNames.AMOUNT &&
      this.state.displayTaxFields
    ) {
      let fields = fieldsObject.find(
        field => field.columnName === ColumnNames.TAX_RATE
      );
      let amountFields = fieldsObject.find(
        field => field.columnName === ColumnNames.AMOUNT
      );
      let taxRate = this.state[ColumnNames.TAX_RATE];
      if (taxRate) {
        const amount = this.state[amountFields.fieldName];
        const taxFieldId = this.state[fields.fieldName];
        let rate =
          taxRate &&
          taxRate.length &&
          taxRate.find(rate => rate.id === taxFieldId);
        this.setPercentageOfCgstIgst(
          rate ? rate.itemName : 0,
          amount ? amount : 0
        );
      }
    }
  }, 250);

  setPercentageOfCgstIgst = async (rate, amount) => {
    if (rate > 0 && amount > 0) {
      let response = await mileageService.getTotalTaxPercentage(rate, amount);
      if (response) {
        let fieldsObject = [...this.state.fieldsObject];
        const igst = fieldsObject.find(
          field => field.columnName === ColumnNames.IGST
        );
        const cgst = fieldsObject.find(
          field => field.columnName === ColumnNames.CGST
        );
        const sgst = fieldsObject.find(
          field => field.columnName === ColumnNames.SGST_UTGST
        );
        if (this.state.displayCSGST) {
          this.setState({
            [cgst.fieldName]: response / 2,
            [sgst.fieldName]: response / 2,
            [igst.fieldName]: 0,
            taxAmount: response
          });
        } else {
          this.setState({
            [igst.fieldName]: response,
            [cgst.fieldName]: 0,
            [sgst.fieldName]: 0,
            taxAmount: response
          });
        }
      }
    }
  };
  //tax calculation end here

  handleScroll = async (e, id, assignKey, page, count, totalLength,search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    setValue(this.state,"clientHeight",e.target.scrollHeight)
    if (bottom) {
      this.setState({
        already:true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight-25)
      })
      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {
          let mergeOld = getValue(this.state.expenseForm[assignKey],"connectedListData.listManagementData",[]).concat(resp.result)
          setValue(this.state.expenseForm[assignKey],"connectedListData.listManagementData",mergeOld)
          setValue(this.state.expenseForm[assignKey],"page",page+1)
          this.setState({
            ...this.state
          })
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight-25)
        }
      }
    }
  }
  handleSearchManage = async (search,id,assignKey) => {

    this.state.loading[assignKey] = true;
    this.state.search[assignKey] = search;
    this.setState({search:this.state.search}, () => {
      $(`#valueConnect${assignKey}`).focus();
    })

    let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
        if (resp) {
          setValue(this.state.expenseForm[assignKey],"connectedListData.listManagementData",resp.result)
        }else{
          setValue(this.state.expenseForm[assignKey],"connectedListData.listManagementData",[])
        }
        setValue(this.state.expenseForm[assignKey],"search",search)

        this.state.loading[assignKey] = false;
        this.setState({
        ...this.state
      })
      $(`#valueConnect${assignKey}`).focus();
      $(`#valueConnect${assignKey}`).attr('value', search);
      $(`#connect${assignKey}`).addClass("show")

}

  render() {
    return (
      <div>
        <LeftSidebar
          expenseEntryList={this.state.expenseEntryList}
          selectExpenstEntryList={localStorage.getItem("expenseEntryId")}
          totoalExpense={this.state.totoalExpense}
          cashAdvanceTotalAmount={this.state.cashAdvanceTotalAmount}
          userBaseCurrency={this.state.userBaseCurrency}
          totalExpenseAmount={this.state.totalExpenseAmount}
          totalRequestAmount={this.state.totalRequestAmount}
          validationStatus={this.state.validationStatus}
        />
        <div className="expense-right  p-padding">
          <div className="white-card p-padding">
            <h5 className="sub-heading mb-0" id="expenseTypeLabel">
              Itemisation Expense Type (
              {localStorage.getItem("itemSelectedExenseTypeName")})
            </h5>
            <div className="row">
              <div className="col-lg-4">
                <div className="amount-show total-amount mb-3">
                  <h6>Remaining Amount</h6>
                  <h5>
                    {this.state.currency}&nbsp;
                    {new Intl.NumberFormat("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(this.state.remainigItemiseAmount)}
                  </h5>
                </div>
              </div>
            </div>
            <div className="modal-middle ">
              {!this.state.isLoading ? (
                <>
                  <div className="row">
                    <ExpenseCreateForm
                      {...this.state}
                      validator={this.validator}
                      policyForm={this.state.expenseForm}
                      onClickHandler={this.onClickHandler}
                      onChangeHandler={this.onChangeHandler}
                      onDatePickerHandler={this.onDatePickerHandler}
                      onCheckboxHandler={this.onCheckboxHandler}
                      onIntegerHandler={this.onIntegerHandler}
                      userPermission={this.state.userPermission}
                      onConnectedListkHandler={this.onConnectedListkHandler}
                      onLocationChangekHandler={this.onLocationChangekHandler}
                      loadOptions={this.loadOptions}
                      handleScroll={this.handleScroll}
                      handleSearchManage={this.handleSearchManage}
                    />
                  </div>
                  {this.state.auditRuleValidationError &&
                    this.state.auditRuleValidationError.map(
                      (validationError, index) => (
                        <p className="text-danger" key={index}>
                          {validationError}
                        </p>
                      )
                    )}
                  <div className="btn-group-margin">
                    <button
                      onClick={this.onCancelClickHandler}
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    {!this.state.isSubmitClick ? (
                      <button
                        disabled={
                          this.state.expenseForm &&
                          this.state.expenseForm.length <= 0
                        }
                        onClick={this.onSubmitHandler}
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        Please Wait...
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CreateItemisation;
