import React from 'react';
import { NavLink } from "react-router-dom";
import $ from 'jquery';

class CompanyNote extends React.Component {
    state = {
        currentNote: {}
    }
    render() {
        let { companyNote, getCurrentNote } = this.props;
        if (companyNote && companyNote.length) {
            companyNote = companyNote.filter(note => note.activeStatus != false);
        }
        let count = 0;
        const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return (
            <div className="col-12">
                <div className="promo-card white comp-note">
                    <h6 className="heading pt-2">Company Notes</h6>
                    {companyNote.map(function (note, i) {
                        count += 1;
                        return (
                            (count <= 3) ? (
                                <div className="shape-name" key={i}>
                                    <div className="right-icon">
                                        <button type="button" data-toggle="modal" data-target="#companyNoteModal" className="btn btn-nothing"
                                            onClick={(e) => getCurrentNote(note)}>
                                            <i className="material-icons">zoom_in</i></button>
                                    </div>
                                    <div className="left date">
                                        <span>{new Date(note.createdAt).getDate()}</span>
                                        <span>{month[new Date(note.createdAt).getMonth()]}</span>
                                    </div>
                                    <div className="right">
                                        <p className="mb-0 text-primary">
                                            <span className={i === 0 ? "new-style" : "d-none"}> New</span> <span className="cursor-pointer" data-toggle="modal" data-target="#companyNoteModal"
                                                onClick={(e) => getCurrentNote(note)}>
                                                {note.heading}</span>
                                        </p>
                                        <p dangerouslySetInnerHTML={{ __html: note.message }} className="text fw-normal op-less text-grey limit-text">
                                            {/* {note.message.replace(/<[^>]*>?/gm, '')} */}
                                        </p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>) : ''
                        )

                    })}
                    {
                        companyNote && companyNote.length > 3 ? (<NavLink to={`/trips-approve/company-note/`} className="btn btn-nothing text-primary">See All</NavLink>) : null
                    }

                </div>


            </div>
        )
    }


}

export default CompanyNote;