import React, { Component } from "react";
import ExpenseTypeStep1Page from "./expense-types-step1-html";
import { expenseServices } from "../../../../../../../_services";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { alertConstants } from "../../../../../../../_constants/alert.constants";

class ExpenseTypeStep1 extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.validator = new SimpleReactValidator();
    this.state = {
      companyId: localStorage["440a28"],
      expenseTypeName: "",
      hasThisParentCategory: "",
      availableFor: "",
      itemization: "",
      systemExpenseTypes: [], 
      systemExpenseType: {

      }
      // itemizationWizard: string
    };
  }
  isEdit = false;
  editContent = false;
  availableFor = [
    { id: 0, name: "Both Entry and Itemization" },
    { id: 1, name: "Only for Entry" },
    { id: 2, name: "Only for Itemization" }
  ];
  itemisation = [
    { id: 0, name: "Required" },
    { id: 1, name: "Optional" },
    { id: 2, name: "Not allowed" }
  ];
  itemWizard = []; 

  componentDidMount = async () => {
    this.getCategory();
    this.getItemZation();
    await this.getTravelCategory();
    if (this.props.match.params.id !== undefined) {
      this.isEdit = true; 
      this.getExpenseDetail(this.props.match.params.id);
    }
  }

  getTravelCategory = async () => {
    try {
      let res = await expenseServices.getSytemExpense();
      if(res){
        this.setState({
          systemExpenseTypes: res.result
        });
      }

    }catch(err){
      console.log('Some error occured');
    }
  }
  getExpenseDetail = async e => {
    var resp = await expenseServices.getExpenseTypesDetail(
      e,
      this.state.companyId
    );
    if (resp) {
      var req = resp.result;
      this.state["expenseTypeName"] = req.expenseTypeName;
      this.state["availableFor"] = req.availableFor;
      this.state["itemization"] = req.itemization;
      if(req.systemExpenseTypeId){
        this.state['systemExpenseType'] = this.state.systemExpenseTypes.find(type => type.id == req.systemExpenseTypeId);
      }
      if (req.hasThisParentCategory) {
        this.handleChange(req.hasThisParentCategory, "hasThisParentCategory",1);
      }
      // if (req.itemizationWizard) {
      //   this.handleChange(req.itemizationWizard, "itemizationWizard");
      // }
      this.setState({
        ...this.state
      });
    }
  };

  listItemzations = [];
  getItemZation = async () => {
    var resp = await expenseServices.getItemizations(this.state.companyId);
    if (resp) this.listItemzations = resp.result;
  };

  listCategory = [];
  getCategory = async () => {
    var resp = await expenseServices.getExpensesCategory(this.state.companyId);
    if (resp) this.listCategory = resp.result;
  };

  itemizationName = "Itemization Wizard";
  handleChange = (e, name,type) => {
    this.editContent = (type === 1) ? false:true
    this.setState({
      [name]: e.id, 
    });
    if (name === "hasThisParentCategory") {
      this.categoryName = e.expenseCategoryName;
    } 
  };

  clickRadio = (value, name) => { 
    this.editContent = true;
    if(name==="availableFor"){
      this.setState({
        itemization: "",
        [name]: value, 
      });
    }else{
      this.setState({
        [name]: value, 
      });
    } 
  };
  handleChangeText = e => {
    this.editContent = true;
    this.setState({
      [e.target.name]: e.target.value, 
    });
  };
  categoryName = "Category Name";
  addSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let payload = {
        ...this.state,
        systemExpenseTypeId: this.state.systemExpenseType.id
      }
      delete payload.systemExpenseTypes;
      delete payload.systemExpenseType;

      var resp = await expenseServices.createExpenseTypes(payload);
      if (resp) {
        toast.success(alertConstants.CREATED_SUCCESSFULLY);
        localStorage.setItem("extypeId", resp.result.id);
        this.props.history.push(
          `/administration/expense/ex/types/expense-types-step2/${resp.result.id}`
      ); 
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };


  updateSubmit = async e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let payload = {
        ...this.state,
        systemExpenseTypeId: this.state.systemExpenseType.id
      };
      delete payload.systemExpenseTypes;
      delete payload.systemExpenseType;
      var resp = await expenseServices.updateExpenseTypes(
        this.props.match.params.id,
        payload
      );
      if (resp) {
        toast.success(alertConstants.UPDATED_SUCCESSFULLY);
        this.props.history.push(
          "/administration/expense/ex/types/expense-types-step2/" +
            this.props.match.params.id
        );
        localStorage.setItem("extypeId", this.props.match.params.id);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleSystemExpenseType = (systemExpenseType) => { 
    this.editContent = true;
    this.setState({systemExpenseType})
  }
  render() {
    return (
      <div>
        <ExpenseTypeStep1Page
          state={this.state}
          listCategory={this.listCategory}
          categoryName={this.categoryName}
          clickRadio={this.clickRadio}
          handleChange={this.handleChange}
          availableFor={this.availableFor}
          itemisation={this.itemisation}
          // systemExpenseTypes={this.systemExpenseTypes}
          error={this.validator}
          addSubmit={this.addSubmit}
          handleChangeText={this.handleChangeText}
          handleSystemExpenseType={this.handleSystemExpenseType}

          itemWizard={this.itemWizard}
          itemizationName={this.itemizationName}
          listItemzations={this.listItemzations}
          isEdit={this.isEdit}
          editContent={this.editContent}
          updateSubmit={this.updateSubmit}
          expenseTypeId={this.props.match.params.id}
        />
      </div>
    );
  }
}
export default ExpenseTypeStep1;
