import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import { Input, Select } from "../../../../../../common/_forms";
const ExpenseTypeLimitsPage = props => {
  const {
    addSubmit,
    state,
    validator,
    handleChange,
    loading,
    listExpenseCategory,
    handleSearch,
    itemsPerPage,
    activePage,
    count,
    pageItemsList,
    handleItemChange,
    handlePageChange,
    choosedItem,
    chooseEdit,
    handleStatusChange,
    removeItem,
    editSubmit,
    getData,
    close,
    getListExpenseType,
    listCountries,
    countryName,
    handleSelectChange,
    expenseName,
    appliesName,
    changeTypes,
    error,
    currencyName,
    handleInputChange
  } = props;

  const {
    allExpenseTypeLimits,
    allExpenseType,
    allAppliesToList,
    allCurrencyCode,
    blockingAmount,
    warningAmount,
    receiptRequiredAmount
  } = props.state;

  const matchedAppliesTo = item => {
    if (allAppliesToList) {
      let matched = allAppliesToList.find(
        group => group.id === item.appliesToGroup
      );
      //  console.log(matched);
      if (matched) {
        return matched.itemName;
      }
    } else return;
  };

  const matchedCurrencyCode = item => {
    if (allCurrencyCode) {
      let matched = allCurrencyCode.find(
        currency => currency.id === item.currencyCode
      );
      // console.log(matched);
      if (matched) {
        return matched.itemCode;
      }
    } else return;
  };

  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">@</div>
            <div className="right">
              <h6 className="heading">Expense Types</h6>
              <p className="text">Manage expenses that an employee can incur that appears when an employee first creates an expense report.</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-9">
                  <div className="btn-group-margin">
                    <button
                      type="button"
                      data-target="#CreatUserModal"
                      data-toggle="modal"
                      className="btn"
                    >
                      New
                    </button>
                    {choosedItem === "" ? (
                      <button
                        type="button"
                        className="btn"
                        disabled
                      >
                        Modify
                      </button>
                    ) : (
                        <button
                          type="button"
                          onClick={getData}
                          data-target="#updateUserModal"
                          data-toggle="modal"
                          className="btn"
                        >
                          Modify
                      </button>
                      )}
                    {choosedItem === "" ? (
                      <button
                        type="button"
                        className="btn"
                        disabled
                      >
                        Remove
                      </button>
                    ) : (
                        <button
                          type="button"
                          onClick={removeItem}
                          className="btn"
                        >
                          Remove
                      </button>
                      )}
                  </div>
                </div>
                {/* <div className="col-md-2">
                  <div className="search-custom">
                    <input
                      type="text"
                      name="search"
                      onChange={handleSearch}
                      className="form-control"
                      placeholder="Search"
                    />
                    <span>
                      <i className="material-icons">search</i>
                    </span>
                  </div>
                </div> */}
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>Expense Type</th>
                    <th>Applies To</th>
                    <th>Currency Code </th>
                    <th>Warn over <br />this amount</th>
                    <th>Block over<br /> this amount</th>
                    <th>Receipt Required<br /> Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allExpenseTypeLimits && allExpenseTypeLimits.length === 0 && (
                    <tr>
                      <td colSpan="7" className="text-center">No Records Found</td>
                    </tr>
                  )}
                  {allExpenseTypeLimits &&
                    allExpenseTypeLimits.map((item, key) => {
                      return (
                        <tr
                          key={key}
                          className={
                            choosedItem === item.id
                              ? "active-tr"
                              : ""
                          }
                        >
                          <td
                            className="cursor-pointer"
                            onClick={e => chooseEdit(item.id)}
                          >
                            {item.expenseTypeId ?.expenseTypeName}
                          </td>
                          <td>{matchedAppliesTo(item)}</td>
                          <td>{matchedCurrencyCode(item)} </td>
                          <td>{item.warningAmount}</td>
                          <td>{item.blockingAmount}</td>
                          <td>{item.receiptRequiredAmount}</td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={e => handleStatusChange(item)}
                                value={item.activeStatus}
                                checked={item.activeStatus}
                              />
                              <span className="slider round" />
                            </label>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {count !== 0 && (
              <div className="table-footer">
                <div className="row">
                  <div className="col-md-3">
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        {pageItemsList && (
                          <Select
                            placeholder={itemsPerPage}
                            keyName={"items"}
                            options={pageItemsList}
                            controlFunc={e => handleItemChange(e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={count}
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*  Edit user modal */}
      <div
        className="modal fade"
        id="updateUserModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="CreateUserModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <form autoComplete="off" id="create-form" onSubmit={editSubmit}>
              <div className="modal-head">
                <h5 className="modal-title" id="CreateUserModalLabel">
                  Update Type Limits
                </h5>
                {/* <button
                  type="button"
                  onClick={close}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div className="modal-middle pm-30">
                <form>
                  <div className="form-group custom labelup">
                    <label>Expense Type</label>
                    {allExpenseType && (
                      <Select
                        placeholder={expenseName}
                        options={allExpenseType}
                        keyName={"expenseTypeName"}
                        controlFunc={e => props.changeTypes(e, "expenseType")}
                        error={error.message(
                          "expenseTypeName",
                          state.expenseTypeId,
                          "required"
                        )}
                      />
                    )}
                  </div>
                  <div className="form-group custom labelup">
                    <label>Applies To</label>
                    {allAppliesToList && (
                      <Select
                        placeholder={appliesName}
                        options={allAppliesToList}
                        keyName={"itemName"}
                        controlFunc={e => props.changeTypes(e, "appliesTo")}
                        error={error.message(
                          "applies to",
                          state.appliesToGroup,
                          "required"
                        )}
                      />
                    )}
                  </div>
                  <div className="form-group custom labelup">
                    <label>Currency Code</label>
                    {allCurrencyCode && (
                      <Select
                        placeholder={currencyName}
                        keyName={"itemName"}
                        options={allCurrencyCode}
                        controlFunc={e => props.changeTypes(e, "currency")}
                        error={error.message(
                          "currency code",
                          state.currencyCode,
                          "required"
                        )}
                      />
                    )}
                  </div>
                  <div className="form-group custom labelup">
                    <label>Warn Over This Item</label>
                    <Input
                      name="warningAmount"
                      type="input"
                      value={warningAmount}
                      controlFunc={e =>
                        props.handleInputChange(e, "warningAmount")
                      }
                      placeholder={warningAmount}
                      label="Warn Over This Item"
                      error={error.message(
                        "warning Amount ",
                        warningAmount,
                        "required|integer"
                      )}
                    />
                  </div>
                  <div className="form-group custom labelup">
                    <label>Block Over This Item</label>
                    <Input
                      name="blockingAmount"
                      type="input"
                      value={blockingAmount}
                      controlFunc={e =>
                        props.handleInputChange(e, "blockingAmount")
                      }
                      placeholder={blockingAmount}
                      label="Block Over This Item"
                      error={error.message(
                        "Block Amount ",
                        blockingAmount,
                        "required|integer"
                      )}
                    />
                  </div>

                  <div className="form-group custom labelup">
                    <label>Receipt Required Amount</label>
                    <Input
                      name="receiptRequiredAmount"
                      type="input"
                      value={receiptRequiredAmount}
                      controlFunc={e =>
                        props.handleInputChange(e, "receiptRequiredAmount")
                      }
                      placeholder={receiptRequiredAmount}
                      label="Received Required Amount"
                      error={error.message(
                        "Receipt Required  Amount ",
                        receiptRequiredAmount,
                        "required|integer"
                      )}
                    />
                  </div>
                </form>
                {loading === false ? (
                  <div className="modal-last btn-group-margin">
                    <button
                      type="submit"
                      className="btn btn-border-success lg-padding"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={close}
                      className="btn btn-border-basic lg-padding"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                    <div className="modal-last">
                      <button
                        type="button"
                        className="btn btn-border-success lg-padding"
                      >
                        Please Wait...
                    </button>
                    </div>
                  )}
              </div>

            </form>
          </div>
        </div>
      </div>

      {/* End of update user modal */}

      {/* Start of Create User Modal */}

      <div
        className="modal fade"
        id="CreatUserModal"
        tabIndex="-2"
        role="dialog"
        aria-labelledby="CreateUserModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <form autoComplete="off" id="create-form" onSubmit={addSubmit}>
              <div className="modal-head">
                <h5 className="modal-title" id="CreateUserModalLabel">
                  Create Type Limits
                </h5>
                {/* <button
                  type="button"
                  onClick={close}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div className="modal-middle pm-30">
                <form>
                  <div className="form-group custom labelup">
                    <label>Expense Type</label>
                    {allExpenseType && (
                      <Select
                        placeholder={expenseName}
                        options={allExpenseType}
                        keyName={"expenseTypeName"}
                        controlFunc={e => props.changeTypes(e, "expenseType")}
                        error={error.message(
                          "expenseTypeName",
                          state.expenseTypeId,
                          "required"
                        )}
                      />
                    )}
                  </div>
                  <div className="form-group custom labelup">
                    <label>Applies To</label>
                    {allAppliesToList && (
                      <Select
                        placeholder={appliesName}
                        options={allAppliesToList}
                        keyName={"itemName"}
                        controlFunc={e => props.changeTypes(e, "appliesTo")}
                        error={error.message(
                          "applies to",
                          state.appliesToGroup,
                          "required"
                        )}
                      />
                    )}
                  </div>
                  <div className="form-group custom labelup">
                    <label>Currency Code</label>
                    {allCurrencyCode && (
                      <Select
                        placeholder={currencyName}
                        keyName={"itemName"}
                        options={allCurrencyCode}
                        controlFunc={e => props.changeTypes(e, "currency")}
                        error={error.message(
                          "currency code",
                          state.currencyCode,
                          "required"
                        )}
                      />
                    )}
                  </div>
                  <div className="form-group custom labelup">
                    <label>Warn Over This Item</label>
                    <Input
                      name="warningAmount"
                      type="input"
                      value={warningAmount}
                      controlFunc={e =>
                        props.handleInputChange(e, "warningAmount")
                      }
                      placeholder={warningAmount}
                      label="Warn Over This Item"
                      error={error.message(
                        "warning Amount ",
                        warningAmount,
                        "required|integer"
                      )}
                    />
                  </div>
                  <div className="form-group custom labelup">
                    <label>Block Over This Item</label>
                    <Input
                      name="blockingAmount"
                      type="input"
                      value={blockingAmount}
                      controlFunc={e =>
                        props.handleInputChange(e, "blockingAmount")
                      }
                      placeholder={blockingAmount}
                      label="Block Over This Item"
                      error={error.message(
                        "Block Amount ",
                        blockingAmount,
                        "required|integer"
                      )}
                    />
                  </div>

                  <div className="form-group custom labelup">
                    <label>Receipt Required Amount</label>
                    <Input
                      name="receiptRequiredAmount"
                      type="input"
                      value={receiptRequiredAmount}
                      controlFunc={e =>
                        props.handleInputChange(e, "receiptRequiredAmount")
                      }
                      placeholder={receiptRequiredAmount}
                      label="Receipt Required Amount"
                      error={error.message(
                        "Receipt Required  Amount ",
                        receiptRequiredAmount,
                        "required|integer"
                      )}
                    />
                  </div>
                </form>
                {loading === false ? (
                  <div className="modal-last btn-group-margin">
                    <button
                      type="submit"
                      className="btn btn-border-success lg-padding"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={close}
                      className="btn btn-border-basic lg-padding"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                    <div className="modal-last">
                      <button
                        type="button"
                        className="btn btn-border-success lg-padding"
                      >
                        Please Wait...
                    </button>
                    </div>
                  )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpenseTypeLimitsPage;
