import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import RemindMainPage from "./remind";
import AddEditTemplatePage from "./add-edit-template/add-edit-template";
class EmailMainPage extends Component {
  constructor(props) {
    super(props);
    if (props.location.pathname === "/administration/company/email") {
      this.props.history.push("/administration/company/email/travel");
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (this.props.location.pathname === "/administration/company/email") {
      this.props.history.push("/administration/company/email/travel");
    }
  }
  render() {
    return (
      <div className="ExpenseTypeHomePage">
        <Switch>
          <Route
            path="/administration/company/email/travel"
            component={RemindMainPage}
          />
          <Route
            exact={true}
            path="/administration/company/email/add-edit-template/:serviceModuleId/:id"
            component={AddEditTemplatePage}
          />
        </Switch>
      </div>
    );
  }
}
export default EmailMainPage;
