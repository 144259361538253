import React from "react";
import Menu from "../menu";
import Pagination from "react-js-pagination";
import TableRow from "./Table-row";
import { Input } from "../../../../../common/_forms";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import uuid from "uuid";
import Loader from "src/_components/common/Loader";

const ConfigurationPage = props => {
  const selectHandler = props => (
    <div className="dropdown" key={uuid.v4()}>
      <button
        type="button"
        className="btn dropdown-toggle"
        data-toggle="dropdown"
      >
        {props.placeholder}
      </button>
      <div className="dropdown-menu">
        {props.options.map((opt, index) => {
          return (
            <button
              type="button"
              onClick={e => props.controlFunc(opt)}
              className="dropdown-item"
              key={index}
            >
              {opt[props.keyName]}
            </button>
          );
        })}
      </div>
      <div className="help-block">{props.error}</div>
    </div>
  );
  let tableRows =
    props.configList.length > 0 ? (
      props.configList.map((data, index) => (
        <TableRow
          {...props}
          key={index}
          data={data}
          editHandler={props.editHandler}
          editableByList={props.editableByList}
          configId={props.configId}
          editConfigName={props.editConfigName}
          editEditableBy={props.editEditableBy}
          editEditableByLabel={props.editEditableByLabel}
          inputChangeHandler={props.inputChangeHandler}
          getConfigList={props.getConfigList}
          changeConfigEditableBy={props.changeConfigEditableBy}
          selectedDeleteHandler={props.selectedDeleteHandler}
          editConfigSelect={props.editConfigSelect}
          selectedConfigId={props.selectedConfigId}
          submitHandler={props.submitHandler}
          statusHandler={props.statusHandler}
          enableTypeRate={props.enableTypeRate}
          validator={props.validator}
          allowanceCalculationTypeHandler={
            props.allowanceCalculationTypeHandler
          }
          onOneWayChangeTripHandler={props.onOneWayChangeTripHandler}
          onRoundWayChangeTripHandler={props.onRoundWayChangeTripHandler}
          onRoundReturnChangeTripHandler={props.onRoundReturnChangeTripHandler}
          onMultiCityChangeTripHandler={props.onMultiCityChangeTripHandler}
          onCurrencyChangeHandler={props.onCurrencyChangeHandler}
          onAllowanceChangeHandler={props.onAllowanceChangeHandler}
          onIntegerHandler={props.onIntegerHandler}
          onIntegerHandler1={props.onIntegerHandler1}
          onIntegerHandler2={props.onIntegerHandler2}
        />
      ))
    ) : (
        <tr>
          <td colSpan={6} className="text-center">
            No data found
        </td>
        </tr>
      );
  return (
    <div>
      <div className="admin-tabs">
        <Menu />
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">C</div>
            <div className="right">
              <h6 className="heading">Configuration</h6>
              <p className="text">
                A Travel Allowance to create configuration mapped to employees.
              </p>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-9">
                  <div className="btn-group-margin">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#AddTemplateModal"
                      style={{ display: "inline-block" }}
                      onClick={() => props.resetForm()}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      className="btn"
                      onClick={() => props.deletehandler()}
                      disabled={
                        props.selectedConfigId.length > 0 ? false : true
                      }
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="search-custom">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      name="search"
                      autoComplete="off"
                      onChange={e => props.inputChangeHandler(e)}
                    />
                    <span>
                      <i className="material-icons">search</i>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped table-selected">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Editable by</th>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {props.isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={4}>
                        <Loader />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                    <tbody>{tableRows}</tbody>
                  )}
              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  {props.totalCount > 0 && (
                    <div className="page-count">
                      <div className="text">Items</div>
                      <div className="count">
                        {selectHandler({
                          placeholder: props.dataPerPage,
                          keyName: "items",
                          options: props.pageItemsList,
                          controlFunc: props.handleItemChange
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-9">
                  {props.totalCount > 0 && (
                    <Pagination
                      activePage={props.selectedPage}
                      itemsCountPerPage={props.dataPerPage}
                      totalItemsCount={props.totalCount}
                      pageRangeDisplayed={5}
                      onChange={props.pageClickHandler}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddTemplateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddTemplateModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content border-0">
            <div className="modal-head">
              <button
                type="button"
                className="close text-white d-none"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 className="modal-title" id="AddTemplateModalLabel">
                New Configuration
              </h5>
            </div>
            <form onSubmit={props.addConfigHandler}>
              <div className="modal-middle pm-30">
                <div className="modal-scroll pt-3">
                  <div className="mb-4">
                    <div className="form-group custom mb-0">
                      <Input
                        label={"Configuration Name"}
                        name="configName"
                        type="text"
                        imp="true"
                        controlFunc={e => props.inputChangeHandler(e)}
                        value={props.configName}
                        className="form-control"
                      />
                    </div>
                    <p className="text-danger fs-12">
                      {props.submitted && props.configName.length <= 0
                        ? "The Configuration Name field is required."
                        : ""}
                    </p>
                  </div>
                  <div className="mb-4">
                    <div className="form-group custom labelup mb-0 textarea">
                      <label>
                        Editable By <span className="text-danger">*</span>
                      </label>
                      <Select
                        components={makeAnimated()}
                        name="editableBy"
                        isMulti
                        value={props.editableBy}
                        options={props.editableByList}
                        onChange={props.changeEditableBy}
                        required
                      />
                    </div>
                    <p className="text-danger fs-12">
                      {props.submitted && props.editableBy.length <= 0
                        ? "The Editable by field is required."
                        : ""}
                    </p>
                  </div>
                  {/* <div className="mb-4">
                    <div className="form-group custom mb-0 labelup">
                      <label>
                        Allowance Calculation Type{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        value={props.allowanceSelectedType}
                        name="allowanceSelectedType"
                        options={props.allowanceCalculationType}
                        onChange={props.allowanceCalculationTypeHandler}
                        required
                      />
                      <p className="text-danger fs-12">
                        {props.submitted &&
                        props.allowanceSelectedType.length <= 0
                          ? "The Allowance Calculation Type field is required."
                          : ""}
                      </p>
                    </div>
                  </div> */}
                  {/* <h6 className="sub-heading mb-4">For One Way Trips</h6>
                  <div className="form-group custom labelup">
                    <label>Start On <span className="text-danger">*</span></label>
                    <Select
                      value={props.oneWayStartSelected}
                      name="oneWayStartSelected"
                      options={props.tripStartEndTypes}
                      onChange={props.onOneWayChangeTripHandler}
                    />
                    <p className="text-danger fs-12">
                      {props.submitted && props.oneWayStartSelected.length <= 0 ? 'The Start On field is required.' : ''}
                    </p>
                  </div> */}
                  <h6 className="sub-heading mb-4">For Round Way Trips</h6>
                  <div className="form-group custom labelup">
                    <label>
                      OnWard <span className="text-danger">*</span>
                    </label>
                    <Select
                      value={props.onWardStartSelected}
                      name="onWardStartSelected"
                      options={props.tripStartEndTypes}
                      onChange={props.onRoundWayChangeTripHandler}
                    />
                    <p className="text-danger fs-12">
                      {props.submitted && props.onWardStartSelected.length <= 0
                        ? "The OnWard field is required."
                        : ""}
                    </p>
                  </div>

                  <div className="form-group custom labelup">
                    <label>
                      Return <span className="text-danger">*</span>
                    </label>
                    <Select
                      value={props.onWardReturnSelected}
                      name="onWardReturnSelected"
                      options={props.tripStartEndTypes}
                      onChange={props.onRoundReturnChangeTripHandler}
                    />
                    <p className="text-danger fs-12">
                      {props.submitted && props.onWardReturnSelected.length <= 0
                        ? "The Return field is required."
                        : ""}
                    </p>
                  </div>
                  <h6 className="sub-heading mb-4">For Multi City Trips</h6>
                  <div className="form-group custom labelup">
                    <label>
                      Start On <span className="text-danger">*</span>
                    </label>
                    <Select
                      value={props.multiCityStartSelected}
                      name="multiCityStartSelected"
                      options={props.tripStartEndTypes}
                      onChange={props.onMultiCityChangeTripHandler}
                    />
                    <p className="text-danger fs-12">
                      {props.submitted &&
                        props.multiCityStartSelected.length <= 0
                        ? "The Start On field is required."
                        : ""}
                    </p>
                  </div>

                  <div className="form-group custom labelup">
                    <label>
                      Allowance Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="allowanceType"
                      value={props.allowanceType}
                      options={props.allowanceTypeList}
                      onChange={props.onAllowanceChangeHandler}
                    />

                    <p className="text-danger fs-12">
                      {props.submitted && props.allowanceType.length <= 0
                        ? "The Allowance Type field is required."
                        : ""}
                    </p>
                  </div>
                  <div className="form-group custom labelup">
                    <label>
                      Currency <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="currency"
                      value={props.selectedCurrency}
                      options={props.currenciesList}
                      onChange={props.onCurrencyChangeHandler}
                    />

                    <p className="text-danger fs-12">
                      {props.submitted && props.selectedCurrency.length <= 0
                        ? "The currency field is required."
                        : ""}
                    </p>
                  </div>

                  <div className="form-group custom">
                    <Input
                      label={"Standard Rate"}
                      name="maxLimit"
                      type="text"
                      imp="true"
                      controlFunc={e => props.inputChangeHandler(e)}
                      value={props.maxLimit}
                      className="form-control"
                    />
                    <p className="text-danger fs-12">
                      {props.submitted && props.maxLimit.length <= 0
                        ? "The Amount field is required."
                        : ""}
                    </p>
                  </div>
                  <div className="form-group">
                    <label className="switch">
                      <input 
                        type="checkbox"
                        value={props.guestActive}
                        checked={props.guestActive}
                        onChange={e => props.enableTypeRate("guestActive",props.guestActive)}
                      />
                      <span className="slider round" />
                    </label>
                    <span>Enable Guest House Rate</span>
                  </div>
                  {props.guestActive && 
                    <div className="form-group custom">
                      <Input
                        label={"Guest House Rate"}
                        name="maxGuestLimit"
                        type="text"
                        imp="false"
                        className="form-control"
                        controlFunc={e => props.inputChangeHandler(e)}
                        value={props.maxGuestLimit}
                      />
                    </div>}
                  <div className="form-group">
                    <label className="switch">
                    <input 
                        type="checkbox"
                        value={props.personalActive}
                        checked={props.personalActive}
                        onChange={e => props.enableTypeRate("personalActive",props.personalActive)}
                      />
                      <span className="slider round" />
                    </label>
                    <span>Enable Stay at Friends Rate</span>
                  </div>
                  {props.personalActive && 
                  <div className="form-group custom mb-0">
                    <Input
                      label={"Stay At Friends Rate"}
                      name="maxPersonalLimit"
                      type="text"
                      imp="false"
                      className="form-control"
                      controlFunc={e => props.inputChangeHandler(e)}
                      value={props.maxPersonalLimit}
                    />
                  </div>}
                </div>
              </div>
              <div className="modal-last pm-30 pt-0 btn-group-margin">
                <button type="submit" className="btn" disabled={props.loading}>
                  {props.loading === true ? "Please Wait" : "Save"}
                </button>
                <button
                  type="button"
                  className="btn close"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfigurationPage;
