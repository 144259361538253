import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "../../../page-not-found/page-not-found-html";
import LocationHomePage from "./location";
import ProfileHomePage from "./profile";
import ListManagementPage from "./list-management/list-management";
import ImportFileUpload from "./import-export/import";
import ExportPage from "./import-export/export-html";
import HistoryLogsPage from "./import-export/history-logs-html";
import ErrorObjectPage from "./import-export/error-object-html";
import TaxNumberPage from "./tax-number/tax-number-html";
import PermissionHomePage from "./permission-administration/permission-admin";
import ManagementHomePage from "./user-management";
import EmailMainPage from "./email-administration/manage";
import ReminderMainPage from "./email-reminder/main";
import FFMainPage from "./forms/manage";
import CompanyNoteMain from "./company-note/main";
import WorkflowsHomePage from "./workflows/workflows";
import ExceptionPage from "./exception/exception";
import AdditionalApprover from "./additional-approver/additionalApprover";
//import MidOfficeHistoryPage from "./mid-office/mid-office-history/mid-office-history";
import AuditRulesHomePage from "./audit-rules/audit";
//import HistoryDetailsPage from "./mid-office/history-details/details";
import { inject } from "mobx-react";

import MidOfficeHistoryHomePage from './mid-office-history/history';
@inject('menuStore')
class CompanyHomePage extends Component {
  constructor(props) {
    super(props);
    this.props.menuStore.menuItems && this.props.menuStore.menuItems.forEach(list => {
      if (this.props.menuStore.parentId === list.id) {
        if (props.location.pathname === list.children[0].frontEndUrl) {
          this.props.history.push(list.children[0].children[0].frontEndUrl)
        } else {
          this.props.history.push(props.location.pathname)
        }
      }
    })
  }
  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/administration/company/profile"
            component={ProfileHomePage}
          />
          <Route
            path="/administration/company/workflows"
            component={WorkflowsHomePage}
          />
          <Route
            path="/administration/company/exception"
            component={ExceptionPage}
          />
          <Route
            path="/administration/company/approver"
            component={AdditionalApprover}
          />
          <Route
            path="/administration/company/audit-rules"
            component={AuditRulesHomePage}
          />
          <Route
            path="/administration/company/mid-office-history"
            component={MidOfficeHistoryHomePage}
          />
          <Route
            path="/administration/company/location"
            component={LocationHomePage}
          />
          <Route
            path="/administration/company/management/"
            component={ManagementHomePage}
          />
          <Route
            path="/administration/company/permission"
            component={PermissionHomePage}
          />
          <Route
            path="/administration/company/email"
            component={EmailMainPage}
          />
          <Route
            path="/administration/company/reminder"
            component={ReminderMainPage}
          />
          <Route path="/administration/company/admin-forms" component={FFMainPage} />
          <Route
            path="/administration/company/list-management"
            component={ListManagementPage}
          />
          <Route
            path="/administration/company/import"
            component={ImportFileUpload}
          />
          <Route
            path="/administration/company/export"
            component={ExportPage}
          />
          <Route
            path="/administration/company/history-log"
            component={HistoryLogsPage}
          />
          <Route
            path="/administration/company/error-object"
            component={ErrorObjectPage}
          />
          <Route
            path="/administration/company/tax-number"
            component={TaxNumberPage}
          />
          <Route
            path="/administration/company/company-note"
            component={CompanyNoteMain}
          />

          <Route path="*" component={PageNotFound} />
          
        </Switch>
      </div>
    );
  }
}
export default CompanyHomePage;
