import React, { Component } from "react";
class TrainSummary extends Component {
    render() {
        return (
            <div className="custom-card">
                <div>
                    <div className="filter-card pb-2 mb-4">
                        <h6 className="heading grey">Advanced Settings</h6>
                        <div className="hotel-filter-panel">
                            <h6 className="heading ">Price Range</h6>
                            <div className="inner-content" />
                        </div>
                        <div className="hotel-filter-panel">
                            <h6 className="heading ">Type</h6>
                            <div className="inner-content">
                                <div className="form-group custom bg-white mb-0">
                                    <div className="dropdown">
                                        <button
                                            type="button"
                                            className="btn dropdown-toggle"
                                            data-toggle="dropdown"
                                        >
                                            Express Only
                              </button>
                                        <div className="dropdown-menu">
                                            <button type="button" className="dropdown-item">
                                                GLobal
                                </button>
                                            <button type="button" className="dropdown-item">
                                                Lorem Ipsum
                                </button>
                                            <button type="button" className="dropdown-item">
                                                Lorem Ipsum
                                </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hotel-filter-panel">
                            <div className="inner-content">
                                <button className="btn btn-border-primary bg-white btn-block apply-filter">
                                    Apply
                          </button>
                            </div>
                        </div>
                        <div />
                    </div>
                    <div className="filter-card px-3 pt-1 pb-4 mb-4">
                        <div className="type-panel-out">
                            <h6 className="heading">Currency</h6>
                            <div className="inner-body">
                                <div className="form-group custom bg-white mb-0">
                                    <div className="dropdown">
                                        <button
                                            type="button"
                                            className="btn dropdown-toggle"
                                            data-toggle="dropdown"
                                        >
                                            USD
                            </button>
                                        <div className="dropdown-menu">
                                            <button type="button" className="dropdown-item">
                                                USD
                              </button>
                                            <button type="button" className="dropdown-item">
                                                USD
                              </button>
                                            <button type="button" className="dropdown-item">
                                                USD
                              </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrainSummary;
