import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { userService } from 'src/_services';
import { toast } from "react-toastify";

class CompanyNotePage extends Component {
  constructor(props) {
    super(props)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.state = {
      companyId: localStorage["440a28"],
      note: []
    }
  }
  componentDidMount() {
    this.getNote()
  }
  getNote = async () => {
    let resp = await userService.getCompanyNote(this.state.companyId);
    if (resp) {
      this.setState({ note: resp });
    }
  }
  onStatusChangeHandler = async (id, status) => {
    let payLoad = {
      "activeStatus": status
    };
    let resp = await userService.updateCompanyNoteStatus(id, payLoad);
    if (resp) {
      toast.success(resp.message);
      this.getNote();
    }
  }
  render() {
    return (
      <div>
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">C</div>
            <div className="right">
              <h6 className="heading">Company Note
              </h6>
              <p className="text">Reach out to your employees with important updates and policy changes.</p>
            </div>
          </div>
          <div className="py-padding">
            <div className="table-container mx-0">
              <form>
                <div className="row">
                  <div className="col-md-9 col-sm-8">
                    <div className="btn-group-margin">
                      <NavLink
                        to={`/administration/company/company-note/update/${"new"}`}
                        className="btn"
                      >
                        New
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4">
                    <div className="search-custom">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Search"
                      />
                      <span>
                        <i className="material-icons">search</i>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table table-striped table-selected">
                  <thead>
                    <tr>
                      <th>Heading</th>
                      <th>Message</th>
                      <th>Action</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.note.length > 0 && this.state.note.map((item) => {
                      return (
                        <tr>
                          <td>
                            {item.heading}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: item.message }}>
                            {/* {item.message.replace(/<[^>]*>?/gm, '')} */}
                          </td>
                          <td>
                            <NavLink
                              to={
                                `/administration/company/company-note/update/${item.id}`
                              }
                              className="btn btn-nothing hover-v"
                            >
                              <i className="material-icons fs-20">
                                edit
                            </i>
                              <span className="hover-name right-top">Edit</span>
                            </NavLink>
                          </td>
                          <td>
                            <label className="switch">
                              <input type="checkbox"
                                checked={item.activeStatus}
                                onChange={e =>
                                  this.onStatusChangeHandler(item.id, item.activeStatus)
                                } />
                              <span className="slider round" />
                            </label>
                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </table>
              </div>
              <div className="table-footer">
                <div className="row">
                  <div className="col-sm-3">
                    {/* {props.totalCount && (
                      <div className="page-count">
                        <div className="text">Items</div>
                        <div className="count">
                          <div className="dropdown" >
                            <Select
                              placeholder={props.dataPerPage}
                              keyName={"items"}
                              options={props.pageItemsList}
                              controlFunc={e => props.handleItemChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                  <div className="col-sm-9">
                    {/* {props.totalCount && (
                      <Pagination
                        activePage={props.activePage}
                        itemsCountPerPage={props.dataPerPage}
                        totalItemsCount={props.totalCount}
                        pageRangeDisplayed={3}
                        onChange={props.pageClickHandler}
                      />
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default CompanyNotePage;
