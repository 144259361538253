import React from "react";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import SortHead from "../../../common/sort";
import { Select } from "../../../../../common/_forms";

const CompanyLocationPage = props => {
  const {
    companyList,
    isDesc,
    sortBy,
    pageItemsList,
    column,
    handleItemChange,
    handleStatusChange,
    itemsPerPage,
    count,
    activePage,
    handlePageChange,
    handleSearch,
    loading
  } = props;
  // console.log(count);

  return (
    <div>
      <div className="inner-header">
        <div className="shape-header">
          <div className="left">L</div>
          <div className="right">
            <h6 className="heading">Manage Location</h6>
            <p className="text">
              The Locations tool is used by the administrator to add company
              locations.
            </p>
          </div>
        </div>
      </div>
      <div className="py-padding">
        <div className="table-container">
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="btn-group-margin">
                  <NavLink
                    to="/administration/company/location/add"
                    className="btn"
                  >
                    Add Location
                  </NavLink>
                </div>
              </div>
              {/* <div className="col-md-4 text-right">
                <div className="btn-group-margin">
                  <button className="btn btn-upload"><i className="material-icons">cloud_upload</i></button>
                  <button className="btn btn-upload"><i className="material-icons">cloud_download</i></button>
                </div>
              </div> */}
              <div className="offset-3 col-md-3">
                <div className="search-custom">
                  <input
                    type="text"
                    name=""
                    onChange={handleSearch}
                    className="form-control"
                    placeholder="Search"
                  />
                  <span>
                    <i className="material-icons">search</i>
                  </span>
                </div>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <SortHead
                    name="Locations"
                    feild="name"
                    column={column}
                    isDesc={isDesc}
                    sortBy={sortBy}
                  />
                  <SortHead
                    name="Legal Name"
                    feild="legalName"
                    column={column}
                    isDesc={isDesc}
                    sortBy={sortBy}
                  />
                  <SortHead
                    name="Address"
                    width="30%"
                    feild="addressLine1"
                    column={column}
                    isDesc={isDesc}
                    sortBy={sortBy}
                  />
                  <th>State</th>
                  <th>Country</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading === true && (
                  <tr>
                    <td colSpan="7">Loading</td>
                  </tr>
                )}
                {loading === false && count === 0 && (
                  <tr>
                    <td colSpan="7" className="text-center">No Records Found</td>
                  </tr>
                )}
                {companyList &&
                  companyList.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <p>{item.locationName}</p>
                          <p className="link">
                            {item.cityObj && item.cityObj.cityName}
                          </p>
                        </td>
                        <td>{item.legalName}</td>
                        <td>{item.addressLine1}</td>
                        <td>{item.stateObj && item.stateObj.name}</td>
                        <td>
                          {item.countryObj && item.countryObj.countryName}
                        </td>
                        <td>
                          <NavLink
                            to={
                              "/administration/company/location/update/" +
                              item.id
                            }
                            className="btn btn-nothing hover-v"
                          >
                            <i className="material-icons fs-20">
                              edit
                            </i>
                            <span className="hover-name right-top">Edit</span>
                          </NavLink>
                        </td>
                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={e => handleStatusChange(item)}
                              value={item.activeStatus}
                              checked={item.activeStatus}
                            />
                            <span className="slider round" />
                          </label>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {count !== 0 && (
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      {pageItemsList && (
                        <Select
                          placeholder={itemsPerPage}
                          keyName={"items"}
                          options={pageItemsList}
                          controlFunc={e => handleItemChange(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={count}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CompanyLocationPage;
