import React from 'react';
import Menu from '../menu';
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
const EmployeeRelatedConfigPage = (props) => {
    let data = props;
    return (
        <div>
            <div className="admin-tabs">
                <Menu />
                <div className="inner-header">
                    <div className="shape-header">
                        <div className="left">E</div>
                        <div className="right">
                            <h6 className="heading">Employee Related Information</h6>
                            <p className="text">Lorem Ipsum is simply dummy text.</p>
                        </div>
                    </div>
                </div>
                <div className="py-padding">
                    <div className="table-container">
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="btn-group-margin">
                                        <button type="button" className="btn   sm-padding" data-toggle="modal" data-target="#AddTemplateModal">New</button>
                                        <NavLink to="/administration/expense/config-step-1" className="btn   sm-padding ">Modify</NavLink>
                                        <button type="button" className="btn   sm-padding disabled">Remove</button>
                                    </div>
                                </div>
                                <div className="col-md-4 text-right">
                                    <div className="btn-group-margin">
                                        <button className="btn btn-upload"><i className="material-icons">cloud_download</i></button>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="search-custom">
                                        <input type="text" name="" className="form-control" placeholder="Search" />
                                        <span><i className="material-icons">search</i></span>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Country</th>
                                        <th>Configuration</th>
                                        <th>Organization</th>
                                        <th>Configuration Set</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td>qubic_city_a</td>
                                        <td>City A</td>
                                        <td>Government</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td>qubic_city_a</td>
                                        <td>City A</td>
                                        <td>Government</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td>qubic_city_a</td>
                                        <td>City A</td>
                                        <td>Government</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td>qubic_city_a</td>
                                        <td>City A</td>
                                        <td>Government</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td>qubic_city_a</td>
                                        <td>City A</td>
                                        <td>Government</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td>qubic_city_a</td>
                                        <td>City A</td>
                                        <td>Government</td>
                                    </tr>
                                    <tr>
                                        <td>Qubic city a</td>
                                        <td>qubic_city_a</td>
                                        <td>City A</td>
                                        <td>Government</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-footer">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="page-count">
                                        <div className="text">Items</div>
                                        <div className="text">
                                            <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle"
                                                    data-toggle="dropdown">
                                                    10
                                            </button>
                                                <div className="dropdown-menu">
                                                    <button type="button" className="dropdown-item">10</button>
                                                    <button type="button" className="dropdown-item">20</button>
                                                    <button type="button" className="dropdown-item">30</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <Pagination
                                        activePage={data.active}
                                        itemsCountPerPage={10}
                                        totalItemsCount={10}
                                        pageRangeDisplayed={3}
                                        onChange={data.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="AddTemplateModal" tabIndex="-1" role="dialog"
                aria-labelledby="AddTemplateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="AddTemplateModalLabel">Modify Employee Configuration</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group custom labelup">
                                <label>Editable by Group(s)</label>
                                <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                        &nbsp;
                                    </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group custom labelup">
                                <label>Country</label>
                                <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                        &nbsp;
                                    </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group custom labelup">
                                <label>User Select from this Set</label>
                                <div className="dropdown">
                                    <button type="button" className="btn btn-border-basic dropdown-toggle btn-block" data-toggle="dropdown">
                                        Text Box
                                    </button>
                                    <div className="dropdown-menu">
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                        <button type="button" className="dropdown-item">Action 1</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-border-success lg-padding">Save</button>
                            <button type="button" className="btn btn-border-basic lg-padding"
                                data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default EmployeeRelatedConfigPage;