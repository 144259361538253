import React, { Component } from "react";
import { Route } from "react-router-dom";
import {
  CarConfigPage, CarConfigStep1Page, CarConfigStep2Page, CarConfigStep3Page, CarConfigStep4Page
} from "./index";
class CarConfigMainPage extends Component {
  constructor(props) {
    super(props);
    if (
      props.location.pathname === "/administration/expense/car-config"
    ) {
      this.props.history.push(
        "/administration/expense/car-config/car-configuration"
      );
    } else {
      this.props.history.push(props.location.pathname);
    }
  }
  componentDidUpdate() {
    if (
      this.props.location.pathname ===
      "/administration/expense/car-config"
    ) {
      this.props.history.push(
        "/administration/expense/car-config/car-configuration"
      );
    }
  }
  render() {
    return (
      <div className="CarConfigMainPage">
        <Route
          path="/administration/expense/car-config/car-configuration"
          component={CarConfigPage}
        />
        <Route
          path="/administration/expense/car-config/config-step1/:carId"
          component={CarConfigStep1Page}
        />
        <Route
          path="/administration/expense/car-config/config-step2/"
          component={CarConfigStep2Page}
        />
        <Route
          path="/administration/expense/car-config/config-step3/"
          component={CarConfigStep3Page}
        />
        <Route
          path="/administration/expense/car-config/config-step4/"
          component={CarConfigStep4Page}
        />
      </div>
    );
  }
}
export default CarConfigMainPage;
