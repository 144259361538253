import React from 'react';

const TextArea = (props) => (
  <div className="form-group custom textarea">
    <textarea className="form-control"
      data-gramm_editor="false"
      spellCheck="false"
      name={props.name}
      value={props.value}
      onChange={e => props.controlFunc(e)}
      placeholder="&nbsp;"
    />
    <label>{props.label} {(props.imp === "true") && <span className="text-danger">*</span>}</label>
    <div className="help-block">{props.error}</div>
  </div>
);

export default TextArea;