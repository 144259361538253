import React, { Component } from "react";
import { Select } from "src/_components/common/_forms";
class TaxConfigMainPage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="admin-tabs">
          <div className="inner-header">
            <div className="shape-header">
              <div className="left">T</div>
              <div className="right">
                <h6 className="heading">Tax Authorities</h6>
                <p className="text">Manage tax slab of various countries.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="py-padding">
          <div className="table-container">
            <form>
              <div className="row">
                <div className="col-md-5">
                  <div className="btn-group-margin">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#editModal"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Expense Type</th>
                    <th>Tax Type</th>
                    <th>Claim</th>
                    <th>Reclaim Code</th>
                    <th>Tax Code</th>
                    <th>Actions</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Expense Type 1</td>
                    <td>Tax Type 1</td>
                    <td>Claim 1</td>
                    <td>213123213</td>
                    <td>213123213</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-nothing hover-v mr-2"
                        data-toggle="modal"
                        data-target="#editModal"
                      >
                        <i className="material-icons fs-20">
                          edit
                        </i>
                        <span className="hover-name right-top">Edit</span>
                      </button>
                    </td>
                    <td>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-footer">
              <div className="row">
                <div className="col-md-3">
                  <div className="page-count">
                    <div className="text">Items</div>
                    <div className="count">
                      <Select
                        placeholder="10"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  {/* <Pagination
                      activePage={state.page}
                      itemsCountPerPage={state.limit}
                      totalItemsCount={state.count}
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                    /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="editModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editModalLabelLable"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="modal-head">
                <h5 className="modal-title" id="editModalLabelLable">
                  Add/Edit Tax Authorities
                </h5>
                <button
                  type="button"
                  className="close d-none"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form>
                <div className="modal-middle pm-30">
                  <div className="form-group custom labelup">
                    <label>Expense Type</label>
                    <Select
                      placeholder="Select"
                    />
                  </div>
                  <div className="form-group custom labelup">
                    <label>Tax Type</label>
                    <Select
                      placeholder="Select"
                    />
                  </div>
                  <div className="form-group custom">
                    <input type="text" className="form-control" placeholder="&nbsp;" />
                    <label>Claim</label>
                  </div>
                  <div className="form-group custom">
                    <input type="text" className="form-control" placeholder="&nbsp;" />
                    <label>Reclaim Code</label>
                  </div>
                  <div className="form-group custom">
                    <input type="text" className="form-control" placeholder="&nbsp;" />
                    <label>Tax Code</label>
                  </div>
                  <div className="modal-last btn-group-margin">
                    <button type="submit" className="btn" data-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TaxConfigMainPage;
