import { http } from "../_helpers";
import { config } from "../env";

const getAllDifferentRoute = (from, to) => {
  return http
    .get(`${config.apiExpense}google-distance?origin=${from}&destination=${to}`)
    .then(data => {
      return data ? data.result : "";
    });
};

const getTotalMileageAmount = (vehicleId, distance, currency) => {
  return http
    .get(
      `${config.apiExpense}milage-calculation?vehicleId=${vehicleId}&kilometers=${distance}&currency=${currency}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

const getGstAvailable = cityId => {
  return http
    .get(`${config.apiExpense}tax-type?cityId=${cityId}`)
    .then(data => {
      return data ? data.result : "";
    });
};

const getTotalTaxPercentage = (taxRate, totalAmount) => {
  return http
    .get(
      `${config.apiExpense}tax-rate?taxRate=${taxRate}&totalAmount=${totalAmount}`
    )
    .then(data => {
      return data ? data.result : "";
    });
};

export const mileageService = {
  getAllDifferentRoute,
  getTotalMileageAmount,
  getTotalTaxPercentage,
  getGstAvailable
};
