import React from "react";
import moment from 'moment';

const LatestFlight = props => {
    const { item } = props;

    // * function not needed
    function parseDate(str) {
        var mdy = str.split('/');
        // year,month,date
        return new Date(mdy[2], mdy[1] - 1, mdy[0]);
    }
    // * function below not required
    function datediff(first, second) {
        // Take the difference between the dates and divide by milliseconds per day.
        // Round to nearest whole number to deal with DST.
        return Math.round((first - second) / (1000 * 60 * 60 * 24));
    }

    function differenceInDays(departureDate, currentDate){
      let departureDateInMoment = moment(departureDate, 'DD/MM/YYYY');
      let currentDateInMoment = moment(currentDate, 'DD/MM/YYYY');
      if(departureDateInMoment && currentDateInMoment){

        return departureDateInMoment.diff(currentDateInMoment, 'days') + 1;
      }else{
        return '---';
      }
    }
    // const noOfDays = datediff(parseDate(item ?.fareContent ?.fare ?.flight_depature_date), new Date());
    const noOfDays = differenceInDays(item?.fareContent?.fare?.flight_depature_date, new Date())
    return (
        <div key={props.keyIndex} className={(props.keyIndex === 0) ? "carousel-item active" : "carousel-item"}>
            <div className="carousel-innerdiv">
                <div className="p-3">
                    <h6 className="mb-2">Up Next</h6>
                    <div className="py-2">
                        <div className="float-left">
                            <div className="first-circle">
                                <img src={(item ?.fareContent ?.fare ?.flight_image) ? item ?.fareContent ?.fare ?.flight_image : "/assets/images/vistara.jpg"} />
                            </div>
                            <div className="second-circle bg-white">
                                <div className="main-center">
                                    {(noOfDays !== 0) ?
                                        <div className="line">
                                            <h5 className="text-black text-center mb-0">{noOfDays}</h5>
                                            <p className="text-black text-center mb-0">
                                                {(noOfDays === 1) ?
                                                    "Day"
                                                    :
                                                    "Days"
                                                }
                                            </p>
                                        </div>
                                        :
                                        <div className="line">
                                            <h5 className="text-black text-center mb-0">Today</h5>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="float-left">
                            <div className="shape-name">
                                <div className="right ml-3">
                                    <span className="text mb-1"><i className="material-icons fs-14 align-sub">flight</i> Depart</span>
                                    <h6 className="heading bg-white">
                                        {item.flight_airport[item.fareContent ?.fare ?.from] ?.c}
                                    </h6>
                                    <span className="text">{item.fareContent ?.fare ?.flight_depature_date_utc} - {item.fareContent ?.fare ?.flight_depature_time} {item.fareContent ?.fare ?.flight_departure_terminal ? ('| Terminal '  + item.fareContent ?.fare ?.flight_departure_terminal ) : ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix" />
                    </div>
                </div>
                <div className="bg-table p-3">
                    <div className="float-left">
                        <h6 className="mb-0">Coming Up</h6>
                        <p className="text-muted fs-12">Take a look at whats's next</p>
                    </div>
                    <div className="float-right">
                        {item ?.fareContent ?.content ?.map((list, key) => {
                            return (
                                <div key={key} className="small-second-round">
                                    <img src={list.flight_image} />
                                </div>
                            )
                        })}
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>

        </div>

    );
};
export default LatestFlight;
