import React from 'react';
import Select from 'react-select';

export default function GenerateException(props) {

  const options =[
    {value: 1, label: 'User'},
    {value: 2, label: 'Approver'},
    {value: 3, label: 'Processor'},
    {value: 4, label: 'User And Approver'},
    {value: 5, label: 'User, Approver and Processor'},
  ];

  let activeStyle = { background: "#377DFF", color: '#fff' };
  return (
    <div>

                    {/* <div className="form-group custom labelup">
                      <label>Exception Visibility</label>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn btn-border-basic dropdown-toggle btn-block"
                          data-toggle="dropdown"
                        >
                          Traveler, Approver and Expense Processor
                        </button>
                        <div className="dropdown-menu">
                          <button type="button" className="dropdown-item">User</button>
                          <button className="dropdown-item ">Approver</button>
                          <button className="dropdown-item ">Processor</button>
                          <button className="dropdown-item ">User And Approver</button>
                          <button className="dropdown-item ">User, Approver and Processor</button>
                        </div>
                      </div>
                    </div> */}
                    <div>
                      <Select
                        placeholder={props.selectedExceptionVisibilty?.label ||  "Exception Visibility"}
                        options={options}
                        name="handleExceptionVisibilty"
                        onChange={props.handleExceptionVisibilty}

                      />
                    </div>



      <div className="table-container m-0 ">
                <form className="modal-px-30 py-0">
                  <div className="row">
                    {/* <div className="col-md-9">
                      <div className="btn-group-margin">
                        <button type="button" className="btn  ">Save
                      </button>
                        <button className="btn   disabled">
                          Cancel
                        </button>
                        <button className="btn   disabled">
                          Remove
                        </button>
                      </div>
                    </div> */}
                    {/* <div className="col-md-3">
                      <div className="search-custom mb-0">
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="Search"
                        />
                        <span>
                          <i className="material-icons">search</i>
                        </span>
                      </div>
                    </div> */}
                  </div>
                </form>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead className="text-uppercase">
                      <tr>
                        <th>Code</th>
                        <th>Level</th>
                        {/* <th>Product Code</th> */}
                        <th>Editable By</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        props.allExceptions.length ?  (props.allExceptions.map((exception) => {
                          return (
                          <tr key={exception.id} style={props.selectedException.some(i => i.id == exception.id) ? activeStyle : null} onClick={e => props.handleExceptionSelection(exception)}>
                              <td>
                                {exception.exceptionCode}
                              </td>
                              <td>
                                {exception.exceptionLevel}

                              </td>
                              <td>
                                {
                                  exception?.editableBy?.length ? (exception.editableObj.result.map((item, idx) => {
                                    return (
                                      <span key={item.id}>{item.itemName + ' '}</span>
                                    )
                                  }))
                                  :
                                  <span>Global</span>
                                }

                              </td>
                              <td>
                                {exception.message}

                              </td>
                          </tr>
                          )
                        }))
                        :
                        null
                      }


                    </tbody>
                  </table>
                </div>
              </div>

    </div>
  )
}
