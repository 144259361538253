import React, { Component } from "react";
import ExpenseType3Page from "./expense-types-3-html";
import { expenseServices } from "src/_services";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { alertConstants } from "src/_constants";
class ExpenseType3 extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      companyId: localStorage["440a28"],
      expenseEntries: [],
      itemizedEntries: [],
      requestEntries:[],
      selectedExpenseEntry: "default",
      selectedItemEntry: "default",
      selectedRequestEntry : "default",
      allSetPolicies: [],
      assignedStatus: "",
      combinedPOliciesToBeSet: [],
      edit: false,
      loading:true,
      expenseTypeId: this.props.match.params.id
    };
  }

  selectedPolicyId = "";
  expenseTypeId = ""; //to be received from the step 1
  pageItemsList = [{ items: 10 }, { items: 20 }, { items: 30 }];
  activePage = 1;
  itemsPerPage = 10;
  payload = []; //main request data to be sent
  listPolicies = []; //for storing all the policies
  count = 0; //for storing the count of all the policies

  handleExpenseTypeChange = ev => {
    const { key, oldValue, newValue } = ev;
    if (key === "extypeId") {
      this.setState({ expenseTypeId: newValue });
    }
  };

  componentDidMount = async () =>  { 
    await this.getPolicies();
    await this.getRequestEntryData();
    if (this.props.match.params.id !== undefined) {
      this.setState(
        {
          edit: true
        },
        () => {
          this.getExpenseTypePolicy();
        }
      );
    }
  }

  componentWillUnmount() {
    // window.removeEventListener("storage", this.handleExpenseTypeChange);
  }

  // * Get all expense type policies previously set
  getExpenseTypePolicy = async () => { 
    var res = await expenseServices.getRequestExpenseTypePolicy(
      this.state.companyId,
      this.state.expenseTypeId
    );
    if (res) {
      this.setState({
        allSetPolicies: res.result
      });
    } 
  };
  
  getRequestEntryData = async () => {
    let resp = await expenseServices.getRequestEntryData(this.state.companyId);
    if(resp) {
      this.setState({
        requestEntries: resp.result
      });
    }
  }

  // * Get all the policies
  getPolicies = async () => {
    this.setState({loading:true})
    var item = await expenseServices.getRequestExpensePolicy(
      this.activePage,
      this.itemsPerPage,
      this.state.companyId
    );
    if (item) {
      this.listPolicies = item.result; 
      this.count = item.count;
    } else {
      this.listPolicies = [];
      this.count = 0;
    }
    this.setState({loading:false}) 
  };

  // * handle item change pagination
  handleItemChange = limit => {
    this.activePage = 1;
    this.itemsPerPage = limit["items"];
    this.getPolicies();
  };

  handlePageChange = pageNumber => {
    this.activePage = pageNumber;
    this.getPolicies();
  };

  // * handler  when dropdown is clicked and now is also used for submit
 

  handleRequestEntryHandler = selectedOption => {
    this.setState({
      selectedRequestEntry : selectedOption.value
    });
  }

  editExpenseTypePolicy = async (expenseTypePolicyId, payload) => { 
    payload = Object.assign(payload, {
      companyId: this.state.companyId,
      expenseTypeId: this.state.expenseTypeId
    });
    
    let allFieldsOK = true;
    for (var key in payload) {
      if (payload[key] === "" || payload[key] === undefined) {
        allFieldsOK = false;
      }
    } 
    if (allFieldsOK) {
      var res = await expenseServices.editRequestExpenseTypePolicy(
        expenseTypePolicyId,
        payload
      );
      toast.success(alertConstants.UPDATED_SUCCESSFULLY);
    }
  };

  createExpenseTypePolicy = async payload => {
    payload = Object.assign(payload, {
      companyId: this.state.companyId,
      expenseTypeId: this.state.expenseTypeId
    }); 
    let warn = false;
    for (var key in payload) {
      if ((!warn && payload[key] === "") || payload[key] == null) {
        warn = true;
      }
    }
    if (warn) {
      toast.warn("please fill all the fields");
      // this.forceUpdate();
    } else {
      var res = await expenseServices.createRequestExpenseTypePolicy(payload);
      toast.success(alertConstants.CREATED_SUCCESSFULLY);
      // * bug fix when creating and toggling on and off
      this.getExpenseTypePolicy()
      // this.forceUpdate();
    }
  };

  render() {
    return (
      <div>
        <ExpenseType3Page
          allState={this.state}
          listPolicies={this.listPolicies}
          requestEntries={this.state.requestEntries}
          pageItemsList={this.pageItemsList}
          activePage={this.activePage}
          itemsPerPage={this.itemsPerPage}
          count={this.count}
          onCreateExpenseTypePolicy={this.createExpenseTypePolicy}
          onEditExpenseTypePolicy={this.editExpenseTypePolicy}
          handleItemChange={this.handleItemChange}
          handlePageChange={this.handlePageChange}
          handleSelectChange={this.handleSelectChange}
          handleRequestEntryHandler={this.handleRequestEntryHandler}
          togglePolicyAssign={this.togglePolicyAssign}
          expenseTypeId={this.state.expenseTypeId}
        />
      </div>
    );
  }
}
export default withRouter(ExpenseType3);
