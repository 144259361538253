import React from 'react';
import Select from 'react-select';
import MyMapComponentWrapper from "../../../../../common/map-picker";
import PlacesAutocomplete from "react-places-autocomplete";
import { Input } from 'src/_components/common/_forms';
import { history } from '../../../../../../_helpers';

const HotelInfoPage = (props) => {
  const { state } = props;
  let currencyOptions = [];
  // let contractOptions = [];
  let mealOptions = [];
  let noOfPeopleOptions = [];
  let roomTypeOptions = [];
  let fareTypeOptions = [];

  props.currencyTypes && props.currencyTypes.forEach((list) => {
    currencyOptions.push({ label: list.itemName, value: list.id })
  });
  // props.contractTypes && props.contractTypes.forEach((list) => {
  //   contractOptions.push({ label: list.name, value: list.name })
  // });
  props.meal_types && props.meal_types.forEach((list) => {
    mealOptions.push({ label: list.name, value: list.id })
  });
  props.max_persons && props.max_persons.forEach((list) => {
    noOfPeopleOptions.push({ label: list.name, value: list.id })
  });

  props.room_types && props.room_types.forEach((list) => {
    roomTypeOptions.push({ label: list.name, value: list.id })
  });

  props.fare_types && props.fare_types.forEach((list) => {
    fareTypeOptions.push({ label: list.name, value: list.id })
  });
  return (
    <div>
      <div className="admin-tabs">
        <div className="inner-header">
          <div className="shape-header">
            <div className="left">R</div>
            <div className="right">
              <h6 className="heading">Modify Property Specify Hotel Discount</h6>
              <p className="text">Add/Modify Hotel information</p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="p-padding">
            <div className="form-holder">
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group custom">
                    <input type="text" placeholder="&nbsp;" className="form-control"
                      name="cityName"
                      value={state.cityName}
                      onChange={(e) => props.handleChange3(e)} />
                    {props.validator.message(
                      "City Name",
                      state.cityName,
                      "required"
                    )}
                    <label>City</label>
                  </div>
                  <div className="form-group custom">
                    <input type="text" placeholder="&nbsp;" className="form-control"
                      name="hotelName"
                      value={state.hotelName}
                      onChange={(e) => props.handleChange3(e)}
                    />
                    {props.validator.message(
                      "Hotel Name",
                      state.hotelName,
                      "required"
                    )}
                    <label>Hotel Name</label>
                  </div>
                  <div className="form-group custom textarea mb-2">
                    <textarea type="text" row="4" placeholder="&nbsp;" className="form-control"
                      name="address"
                      value={state.address}
                      data-gramm_editor="false"
                      spellCheck="false"
                      onChange={(e) => props.handleChange3(e)}
                    />
                    <label>Address</label>
                  </div>
                  {props.validator.message(
                    "Address",
                    state.address,
                    "required"
                  )}
                  <div className="form-group custom labelup mt-4">
                    <label>Currency</label>
                    {state.currencyTypes && (
                      <Select
                        value={state.currency || ''}
                        options={currencyOptions}
                        onChange={(e) => props.changeCurrencyType(e)}
                      />

                    )}
                    <label>Hotel Name</label>
                  </div>
                  <div className="form-group custom textarea mb-2">
                    <textarea type="text" row="4" placeholder="&nbsp;" className="form-control"
                      name="address"
                      value={state.address}
                      data-gramm_editor="false"
                      spellcheck="false"
                      onChange={(e) => props.handleChange3(e)}
                    />
                    <label>Address</label>
                  </div>
                  {props.validator.message(
                    "Address",
                    state.address,
                    "required"
                  )}
                  <div className="form-group custom labelup mt-4">
                    <label>Currency</label>
                    {state.currencyTypes && (
                      <Select
                        value={state.currency || ''}
                        options={currencyOptions}
                        onChange={(e) => props.changeCurrencyType(e)}
                      />
                    )}
                    {props.validator.message(
                      "Currency",
                      state.currency,
                      "required"
                    )}
                  </div>
                  <div className="form-group custom textarea mb-2">
                    <textarea type="text" row="4" placeholder="&nbsp;" className="form-control"
                      name="cancelationPolicies"
                      data-gramm_editor="false"
                      spellcheck="false"
                      value={state.cancelationPolicies}
                      onChange={(e) => props.handleChange3(e)} />
                    <label>Cancellation Policies</label>
                  </div>
                  {props.validator.message(
                    "Cancellation Policy",
                    state.cancelationPolicies,
                    "required|string"
                  )}

                  <h5 className="sub-heading mt-4">Vender Details</h5>
                  <div className="form-group custom">
                    <input type="text" placeholder="&nbsp;" className="form-control"
                      name="personName"
                      value={state.personName}
                      onChange={(e) => props.handleChange3(e)} />
                    {props.validator.message(
                      "Name",
                      state.personName,
                      "required|alpha_space"
                    )}
                    <label>Person Name</label>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group custom mb-0">
                        <Input
                          label="Code"
                          type="text"
                          name="searchCountryCode"
                          value={props.selectedCountryCode.dailCode}
                          placeholder={props.selectedCountryCode.dailCode}
                          controlFunc={e => props.handleChangeCountryCode(e)}
                        />
                        {props.validator.message(
                          "code",
                          props.selectedCountryCode.dailCode,
                          "required"
                        )}
                      </div>
                      {props.isSearchCountryCodeListOpen && (
                        props.countryCodeList.length ?
                          (<ul className="scroll-ul">
                            {props.countryCodeList.map(country => {
                              return (
                                <li key={country.id} onClick={e => props.selectChangeCountryCode(country)} >
                                  {country.dailCode + ' ' + country.country}
                                </li>
                              )
                            })}
                          </ul>) :
                          (<div className="mb-4">

                          </div>))
                      }

                    </div>
                    <div className="col-md-9">
                      <div className="form-group custom minus-ml">
                        <input type="text" placeholder="&nbsp;" className="form-control"
                          name="contactNumber"
                          value={state.contactNumber}
                          onChange={(e) => props.handleChange3(e)}
                        />
                        {props.validator.message(
                          "Contact Number",
                          state.contactNumber,
                          "required|phone"
                        )}
                        <label>Contact Number</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group custom mb-0">
                        <Input
                          label="Code"
                          type="text"
                          name="searchCountryCode"
                          value={props.selectedCountryCode2.dailCode}
                          placeholder={props.selectedCountryCode2.dailCode}
                          controlFunc={e => props.handleChangeCountryCode2(e)}
                        />
                        {props.validator.message(
                          "code",
                          props.selectedCountryCode2.dailCode,
                          "required"
                        )}
                      </div>
                      {props.isSearchCountryCodeListOpen2 && (
                        props.countryCodeList2.length ?
                          (<ul className="scroll-ul">
                            {props.countryCodeList2.map(country => {
                              return (
                                <li key={country.id} onClick={e => props.selectChangeCountryCode2(country)} >
                                  {country.dailCode + ' ' + country.country}
                                </li>
                              )
                            })}
                          </ul>) :
                          (<div className="mb-4">

                          </div>))
                      }
                    </div>
                    <div className="col-md-9">
                      <div className="form-group custom minus-ml">
                        <input type="text" placeholder="&nbsp;" className="form-control"
                          name="alternativeNumber"
                          value={state.alternativeNumber}
                          onChange={(e) => props.handleChange3(e)} />
                        {props.validator.message(
                          "Alternative Number",
                          state.alternativeNumber,
                          "required|phone"
                        )}
                        <label>Alternative Number</label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group custom">
                    <input type="text" placeholder="&nbsp;" className="form-control"
                      name="email"
                      value={state.email}
                      onChange={(e) => props.handleChange3(e)} />
                    {props.validator.message(
                      "Email",
                      state.email,
                      "required|email"
                    )}
                    <label>Email</label>
                  </div>

                  <h5 className="sub-heading">Additional Details</h5>
                  <div className="form-group custom textarea mb-2">
                    <textarea type="text" row="4" placeholder="&nbsp;" className="form-control"
                      name="hotelDescription"
                      data-gramm_editor="false"
                      spellcheck="false"
                      value={state.hotelDescription}
                      onChange={(e) => props.handleChange3(e)} />
                    <label>Hotel Description</label>
                  </div>
                  {props.validator.message(
                    "Description",
                    state.hotelDescription,
                    "required"
                  )}
                  <div className="upload-input  form-group text-center mt-4">
                    <div className="upload">
                      <input type="file" type="file" accept='image/*' multiple
                        onChange={(e) => props.onImageBrowse(e)} />
                      Upload Photo
                    </div>
                  </div>
                  <div className="small-img mb-4">
                    {props.photos.length > 0 ? props.photos.map((photo, i) => {
                      return (
                        <div key={i} className="small-icon mb-3" style={{ "position": "relative" }}>
                          {(state.deleteImage) ? <i className="material-icons" style={{ "position": "absolute", "top": 0, "right": 0, "backgroundColor": "#fff", "color": "red", "cursor": "pointer" }}
                            onClick={(e) => props.removeFromList(i)}
                          >close</i> : ""}
                          <img src={photo} alt="" onClick={(e) => props.deleteImage(e)} style={(state.deleteImage) ? { border: '1px solid red' } : {}} />
                        </div>
                      )
                    }) : ""}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group custom labelup">
                        <label>Contract Type</label>
                        {state.contractTypes && (
                          <Select
                            value={state.contractType || ''}
                            options={props.contractTypes}
                            onChange={(e) => props.changeContractType(e)}
                          />
                        )}
                        {props.validator.message(
                          "Contract Type",
                          state.contractType,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group custom minus-ml">
                        <input type="text" placeholder="&nbsp;" className="form-control"
                          name="totalRooms"
                          value={state.totalRooms}
                          onChange={(e) => props.handleChange3(e)} />
                        {props.validator.message(
                          "Total rooms",
                          state.totalRooms,
                          "required"
                        )}
                        <label>Total Rooms</label>
                      </div>
                    </div>
                  </div>
                  <div className="room-info">
                    <div className="pm-15 bg-light">
                      <div className="row">
                        <div className="col-md-3"><p className="mb-0">ROOM TYPE</p></div>
                        <div className="col-md-3"><p className="minus-ml mb-0">MEALS</p></div>
                        <div className="col-md-3"><p className="minus-ml mb-0">MAX PERSON</p></div>
                        <div className="col-md-3"><p className="minus-ml mb-0">FARE TYPE</p></div>
                      </div>
                    </div>
                    <div className="pm-15 bg-white">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group custom ">
                            {roomTypeOptions && (
                              <Select
                                value={props.roomType || ''}
                                options={roomTypeOptions}
                                onChange={(e) => props.changeRoomType(e)}
                              />
                            )}
                            {props.validator.message(
                              "Room type",
                              props.roomType,
                              "required"
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group custom ">
                            {mealOptions && (
                              <Select
                                value={props.meals || ''}
                                options={mealOptions}
                                onChange={(e) => props.changeMealType(e)}
                              />
                            )}
                            {props.validator.message(
                              "Meal type",
                              props.meals,
                              "required"
                            )}

                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group custom ">
                            {noOfPeopleOptions && (
                              <Select
                                value={props.maxPersons || ''}
                                options={noOfPeopleOptions}
                                onChange={(e) => props.changeNoofPeopleType(e)}
                              />
                            )}
                            {props.validator.message(
                              "maxPersons",
                              props.maxPersons,
                              "required"
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group custom ">
                            {fareTypeOptions && (
                              <Select
                                value={props.fareType || ''}
                                options={fareTypeOptions}
                                onChange={(e) => props.changeFareType(e)}
                              />
                            )}
                            {props.validator.message(
                              "Fare type",
                              props.fareType,
                              "required"
                            )}
                          </div>
                        </div>
                        {
                          (state.contractType.value === 'Weekly' || state.contractType === 'Weekly') ?
                            <div className="row mx-1 mt-3">
                              <div className="col-md-6">
                                <div className="form-group custom labelup ">
                                  <input type="text" className="form-control"
                                    name="Weekly"
                                    placeholder="Max Price"
                                    value={state.price.Weekly}
                                    onChange={(e) => props.handleChange4(e)}
                                  />
                                  <label>Weekly Price</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group custom labelup minus-ml">
                                  <input type="text" placeholder="Max Price" className="form-control"
                                    name="Weekend"
                                    value={state.price.Weekend}
                                    onChange={(e) => props.handleChange4(e)}
                                  />
                                  <label>Weekend Price</label>
                                </div>
                              </div>
                            </div>
                            : (state.contractType.value === 'Yearly' || state.contractType === 'Yearly') ?
                              <div className="col-md-6">
                                <div className="form-group custom labelup">
                                  <input type="text" placeholder="Max Price" className="form-control"
                                    name="Yearly"
                                    value={state.price.Yearly}
                                    onChange={(e) => props.handleChange4(e)}
                                  />
                                  <label>Yearly Price</label>
                                </div>
                              </div>
                              :
                              <div className="row mx-1">
                                <div className="col-md-4">
                                  <div className="form-group custom labelup">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="January"
                                      value={state.price.January}
                                      onChange={(e) => props.handleChange4(e)}
                                    />
                                    <label>Jan Price</label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group custom labelup minus-ml">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="February"
                                      value={state.price.February}
                                      onChange={(e) => props.handleChange4(e)}
                                    />
                                    <label>Feb Price</label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group custom labelup minus-ml">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="March"
                                      value={state.price.March}
                                      onChange={(e) => props.handleChange4(e)}
                                    />
                                    <label>Mar Price</label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group custom labelup">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="April"
                                      value={state.price.April}
                                      onChange={(e) => props.handleChange4(e)}
                                    />
                                    <label>Apl Price</label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group custom labelup minus-ml">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="May"
                                      value={state.price.May}
                                      onChange={(e) => props.handleChange4(e)}
                                    />
                                    <label>May Price</label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group custom labelup minus-ml">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="June"
                                      value={state.price.June}
                                      onChange={(e) => props.handleChange4(e)}
                                    />
                                    <label>June Price</label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group custom labelup">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="July"
                                      value={state.price.July}
                                      onChange={(e) => props.handleChange4(e)}
                                    />
                                    <label>July Price</label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group custom labelup minus-ml">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="August"
                                      value={state.price.August}
                                      onChange={(e) => props.handleChange4(e)}
                                    />
                                    <label>Aug Price</label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group custom labelup minus-ml">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="September"
                                      value={state.price.September}
                                      onChange={(e) => props.handleChange4(e)}
                                    />
                                    <label>Sept Price</label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group custom labelup">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="October"
                                      value={state.price.October}
                                      onChange={(e) => props.handleChange4(e)} />
                                    <label>Oct Price</label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group custom labelup minus-ml">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="November"
                                      value={state.price.November}
                                      onChange={(e) => props.handleChange4(e)}
                                    />
                                    <label>Nov Price</label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group custom labelup minus-ml">
                                    <input type="text" placeholder="Max Price" className="form-control"
                                      name="December"
                                      value={state.price.December}
                                      onChange={(e) => props.handleChange4(e)}
                                    />
                                    <label>Dec Price</label>
                                  </div>
                                </div>
                              </div>

                        }
                        <div className="col-12">
                          <div className="form-group mb-0 custom textarea mt-3">
                            <textarea type="text" row="4" placeholder="&nbsp;" className="form-control"
                              name="roomPolicy"
                              data-gramm_editor="false"
                              spellcheck="false"
                              value={state.roomPolicy}
                              onChange={(e) => props.handleChange3(e)} />
                            <label>Room Policy</label>
                          </div>
                          {props.validator.message(
                            "Room policy",
                            state.roomPolicy,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-nothing text-primary mb-3">+ Add Room</button>
                  <h5 className="sub-heading">Get Geo Locations</h5>
                  <div className="geo-location bg-white">
                    <div className="book-details" style={{ "backgroundColor": "", "position": "absolute" }}>
                      <div
                        className="details from full-hotel show-popup"
                        ref={props.locationFromRef}
                      >
                        <div
                          className="details-margin"
                          onClick={e => props.showInput(1)}
                        >
                        </div>
                        <div className="popup" style={{ "width": "300px" }}>
                          <PlacesAutocomplete
                            value={props.from}
                            onChange={props.handleChange}
                            onSelect={props.handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading
                            }) => (
                                <div>
                                  <div className="typ">
                                    <input
                                      {...getInputProps({
                                        placeholder: "Search Hotel location ...",
                                        className: ""
                                      })}
                                      id="search1"
                                    />
                                    <div className="pin">
                                      <i className="material-icons">
                                        location_on</i>
                                    </div>
                                  </div>
                                  {suggestions.length === 0 && (
                                    <ul className="scroll">
                                      {props.popularCity.length !== 0 ? props.popularCity.map((items, key) => {
                                        return (
                                          <li key={key}>
                                            <button
                                              type="button"
                                              onClick={e => props.getCity(items)}
                                            >
                                              {items.name}
                                            </button>
                                          </li>
                                        );
                                      }) : ""}
                                    </ul>
                                  )}
                                  <ul className="scroll autocomplete-dropdown-container">
                                    {loading && (
                                      <li>
                                        <button>Loading...</button>
                                      </li>
                                    )}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer"
                                        }
                                        : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer"
                                        };
                                      return (
                                        <li
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style
                                          })}
                                        >
                                          <button>
                                            {suggestion.description}
                                          </button>
                                          <div className="pin">
                                            <i className="material-icons">
                                              location_on</i>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              )}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                    </div>
                    <MyMapComponentWrapper
                      getLocation={props.getLocation}
                      latitude={props.latitude}
                      longitude={props.longitude}

                    />
                    <div className="values">
                      <div className="row">
                        <div className="col-md-6">
                          <label>Latitude</label>
                          <h6>{props.latitude}</h6>
                        </div>
                        <div className="col-md-6">
                          <label>Longitude</label>
                          <h6>{props.longitude}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-0 custom textarea">
                      <textarea type="text" rows="1" placeholder="&nbsp;" className="form-control"
                        data-gramm_editor="false"
                        spellcheck="false"
                        value={props.location} />
                      <label>Accurate location</label>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
                {/* <GoogleMapPointer
                lat={props.latitude}
                long={props.longitude}
              /> */}
              </div>
              <div className="col-12">
                <div className="btn-group-margin">
                  <button type="submit" className="btn btn-border-success lg-padding"
                    onClick={(props.validator.allValid()) ? history.goBack : ''}>Save</button>
                  <button type="button" className="btn btn-border-basic lg-padding" onClick={history.goBack}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
export default HotelInfoPage;